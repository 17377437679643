import styled from 'styled-components'
import theme from 'lib/styles/theme'

export const HelpCardHeader = styled.div`
  display: flex;
  width: 100%;
`

export const Title = styled.div`
  width: 95%;
  font-size: 1.25rem;
  font-weight: 700;
`

export const HelpIcon = styled.div`
  width: 5%;
  flex-direction: row-reverse;
  cursor: pointer;
  top: 6px;
  right: 0;
  display: flex;
  align-items: center;

  color: ${theme.colors.gray[500]};
  font-size: 1rem;

  & > i {
    color: ${theme.colors.gray[400]};
    margin-left: 0.25rem;
  }
`

export const Price = styled.span`
  color: ${theme.colors.primary[500]};
  ${({ margin = true }) => margin && `margin-left: 5px;`}
`

export const InfoWrap = styled.div`
  margin: 0.3rem 0 1rem 0;
  padding: 0.875rem 1.125rem 0.45rem 1.125rem;
  background-color: ${({ background }) => background};
  border-radius: ${theme.borderRadius[2]};
`

export const BuildingPriceInfo = styled.div`
  display: flex;
  font-size: 0.9rem;
  margin: 0.7rem 0;
  ${({ fontColor }) => fontColor && `color : ${fontColor};`}

  & > div:nth-of-type(1) {
    width: 50%;
  }

  & > div:nth-of-type(2) {
    width: 50%;
    text-align: right;
  }
`

export const Hr = styled.hr`
  margin: 0.65rem 0;
  height: 1px;
  border: 0px;
  border-color: ${theme.colors.gray[200]};
  background-color: ${theme.colors.gray[200]};
`

export const CustomUl = styled.ul`
  li {
    list-style-type: disc;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
`
