import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { getReferral } from 'lib/api/notev2/reward'
import { getPlan } from 'lib/api/notev2/paymentApi'
import { setIsVisibleBottomNav } from 'modules/notev2'
import moment from 'moment'

const NoteAccountInviteContainer = ({ children }) => {
  const dispatch = useDispatch()
  const [onRequestGetPlan, loadingPlan, dataGetPlan] = useAxios(getPlan) //요금제 정보
  const [isChargedMember, setIsChargedMember] = useState(false)
  const [isPlanLoading, setIsPlanLoading] = useState(false)
  const [onRequestRefer, loadingRefer, dataRefer] =
    useAxios(getReferral) //초대 정보 불러오기
  const [referCode, setReferCode] = useState('')
  const [invitedList, setInvitedList] = useState([])
  const [rewardedCount, setRewardedCount] = useState(0)
  const [expectedRewardedCount, setExpectedRewardedCount] = useState(0)

  /**
   * 유료회원인지 확인
   */
  useEffect(() => {
    dispatch(setIsVisibleBottomNav(true))

    onRequestGetPlan()
  }, [])

  useEffect(() => {
    if (!dataGetPlan) {
      setIsChargedMember(false)
      return
    }

    setIsPlanLoading(true)
    if (!dataGetPlan?.result) {
      setIsChargedMember(false)
      return
    }

    const diff = moment(
      dataGetPlan.result.next_payment_date,
      'YYYYMMDDHHmmss',
    ).diff(moment(), 'milliseconds')

    //요금제 기간 다 되어도 return
    if (diff < 0) {
      setIsChargedMember(false)
      return
    }

    //등본 남은 개수 없어도 return
    if (dataGetPlan.result.remain_count < 0) {
      setIsChargedMember(false)
      return
    }

    if (dataGetPlan.result) {
      setIsChargedMember(true)
      onRequestRefer() //유료 회원이면 초대 정보 호출
    }
  }, [dataGetPlan])

  /**
   * 초대 정보 호출 성공
   */
  useEffect(() => {
    if (!dataRefer) return
    if (!dataRefer?.referral?.referral_code) {
      message.error('초대 코드를 가져올 수 없습니다.')
      setReferCode('')
      return
    }
    setReferCode(dataRefer?.referral?.referral_code)
    setInvitedList(dataRefer?.referral?.invited_list)
    setRewardedCount(dataRefer?.referral?.rewarded_count)
    setExpectedRewardedCount(dataRefer?.referral?.expected_reward_count)
  }, [dataRefer])

  return children({
    isChargedMember,
    isPlanLoading,
    referCode,
    invitedList,
    rewardedCount,
    expectedRewardedCount,
    loadingRefer,
  })
}

export default NoteAccountInviteContainer
