import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { forwardRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const RadioButton = (
  {
    id,
    label,
    disabled = false,
    key = null,
    shrink,
    fit,
    size,
    ...rest
  },
  ref,
) => {
  const { watch } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll

  const [border, setBorder] = useState(false)
  return (
    <Wrapper key={key}>
      <Radio
        ref={ref}
        onClick={() => setBorder(false)}
        onFocus={() => setBorder(true)}
        onBlur={() => setBorder(false)}
        focus
        type="radio"
        id={id}
        disabled={isDisabled || disabled}
        {...rest}
      />
      <Label
        size={size}
        fit={fit}
        shrink={shrink}
        border={border}
        disabled={isDisabled || disabled}
      >
        {label}
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`

const Label = styled.span`
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: 0.25s ease;
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  min-width: 80px;
  border: 1px solid transparent;
  ${({ size }) => size == 'small' && `font-size: 13px;`};
  ${({ shrink }) => shrink && `flex-shrink: 0;`};
  ${({ fit }) => fit && `min-width: unset;`};

  &:hover {
    background-color: mix(#fff, ${theme.colors.blue[400]}, 84%);
  }
  &:before {
    display: flex;
    flex-shrink: 0;
    content: '';
    background-color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 6px;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 2px ${theme.colors.gray[200]};
    ${({ disabled }) =>
      disabled && `background-color: ${theme.colors.gray[200]};`};
  }
`

const Radio = styled.input`
  position: absolute;
  left: -9999px;
  &:checked + span {
    color: ${theme.colors.blue[400]};
    font-weight: 700;
    background-color: mix(#fff, ${theme.colors.blue[400]}, 84%);
    &:before {
      box-shadow: inset 0 0 0 6px ${theme.colors.blue[400]};
    }
  }
`
export default forwardRef(RadioButton)
