import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import {
  Block,
  BlockTitle,
  ButtonCard,
  Info,
  InfoTitle,
  InfoWrap,
} from 'components/jmapnotev2/style/NotePaymentStyle'
import moment from 'moment'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import { useSelector } from 'react-redux'

/**
 * 왼쪽 요금제 정보 블럭
 * 일반 회원(올인원 X)
 */
const NotePaymentInfoBlockCommon = ({
  plan: {
    price,
    plan_name,
    is_free,
    deunggibu_cnt,
    has_billingkey,
    is_expected_cancel,
    card_info,
    has_error,
    next_payment_date,
    default_count,
  },
  hideButtons,
  onClickChangePayment,
  handleChangeCard,
}) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { hasPlan } = useNoteHasPlan()
  return (
    <Block>
      <Floating>
        <strong>
          {price}
          <span className="small">월</span>
        </strong>
      </Floating>
      <BlockTitle small>{plan_name}</BlockTitle>
      <InfoWrap hideButtons={hideButtons}>
        {!is_free && (
          <Info>
            <InfoTitle>다음 결제 예정일</InfoTitle>
            <span>
              {next_payment_date ? (
                moment(next_payment_date, 'YYYYMMDDHHmmss').format(
                  'YYYY.MM.DD',
                )
              ) : (
                <>&ndash;</>
              )}
            </span>
            {is_expected_cancel && <FailMsg>해지 예정</FailMsg>}
          </Info>
        )}
        <Info>
          <InfoTitle>등기부등본</InfoTitle>
          {deunggibu_cnt ? (
            <span>기본 {default_count}개</span>
          ) : (
            <>&ndash;</>
          )}
        </Info>
        {!loginInfo?.other_groups.includes('tester') && (
          <Info>
            <InfoTitle>자동결제</InfoTitle>
            {has_billingkey && (
              <>
                <span>
                  {card_info?.issuerName} {card_info?.number?.slice(-4)}
                </span>
                <ButtonCard onClick={handleChangeCard} color="blue">
                  카드 변경
                </ButtonCard>
              </>
            )}
            {!has_billingkey &&
              (is_expected_cancel ? (
                '미등록'
              ) : (
                <ButtonCard onClick={onClickChangePayment} color="blue">
                  카드 등록
                </ButtonCard>
              ))}
            {has_error && <FailMsg>결제 실패</FailMsg>}
          </Info>
        )}
      </InfoWrap>

      {!loginInfo?.other_groups.includes('tester') && (
        <ButtonWrapper hideButtons={hideButtons}>
          <Button color="black" onClick={onClickChangePayment}>
            {is_expected_cancel
              ? '다시 시작하기'
              : `요금제 ${hasPlan ? '변경' : '구매'}하기`}
          </Button>
        </ButtonWrapper>
      )}
    </Block>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  text-align: right;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid ${theme.colors.gray[200]};

  ${({ hideButtons }) => hideButtons && `display: none;`}

  & > button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`

const Floating = styled.div`
  position: absolute;
  top: 24px;
  right: 30px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.gray[600]};
  line-height: 140%;

  strong {
    font-size: 24px;
    line-height: 100%;
    font-weight: 600;
    color: ${theme.colors.gray[900]};
    margin-right: 10px;

    letter-spacing: -0.05em;
  }

  .small {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
  }
`

const FailMsg = styled.div`
  color: ${theme.colors.tint.danger};
`

export default NotePaymentInfoBlockCommon
