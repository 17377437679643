import MapContext from 'contexts/map'
import { useContext, useEffect, useRef, useState } from 'react'
import MapRoadView from './MapRoadView'
import ReactDOM from 'react-dom'
import MapWalker from 'lib/utils/mapWalker'
import { message } from 'antd'
import MapRoadViewContainer, { MiniMap } from './MapRoadViewContainer'
import { ReactComponent as Icon } from 'assets/icon/map-control/roadview.svg'
import { ReactComponent as IconActive } from 'assets/icon/map-control/roadview-active.svg'
import { LayerGroupItem } from './MapControlStyle'
import useIsMobile from 'lib/hooks/useIsMobile'

/**
 * 로드뷰 레이어
 */
const MapLayerRoadView = () => {
  const isMobile = useIsMobile()
  const { kakao } = window
  const {
    state: { map },
  } = useContext(MapContext)

  const [isAppearRoad, setIsAppearRoad] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const roadviewRef = useRef(null)

  /**
   * 로드뷰 도로 색칠 활성화 시 클릭 이벤트
   * @param {*} mouseEvent
   */
  function addRoadViewClickEvt(mouseEvent) {
    const position = mouseEvent.latLng

    const roadviewContainer = roadviewRef.current //로드뷰를 표시할 div
    const roadviewObj = new kakao.maps.Roadview(roadviewContainer) //로드뷰 객체
    const roadviewClient = new kakao.maps.RoadviewClient() //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체

    roadviewClient.getNearestPanoId(position, 50, function (panoId) {
      if (panoId) {
        roadviewObj.setPanoId(panoId, position)

        kakao.maps.event.addListener(roadviewObj, 'init', function () {
          initMiniMap(roadviewObj, position)
        })
      } else {
        setIsVisible(false)
        message.info('로드뷰를 지원하지 않는 필지입니다.')
      }
    })

    setIsVisible(true)
  }

  useEffect(() => {
    if (!kakao?.maps?.event || Object.keys(kakao?.maps).length == 0)
      return

    if (isAppearRoad) {
      //미니맵 지도 클릭하면 클릭한 위치 로드뷰를 보여줌(로드뷰가 불가한 지역이라면 가장 가까운 곳으로 강제 이동시킨다)
      kakao.maps.event.addListener(map, 'click', addRoadViewClickEvt)
    } else {
      //로드뷰 도로 색칠 활성화 해제 시 이벤트 제거
      if (kakao.maps.event.removeListener) {
        console.log(
          'kakao.maps.event.removeListener',
          typeof kakao.maps,
          typeof kakao.maps.event,
          typeof kakao.maps.event.removeListener,
        )
        kakao.maps.event.removeListener(
          map,
          'click',
          addRoadViewClickEvt,
        )
      }
    }

    return () => {
      //언마운트시 이벤트 제거
      kakao.maps.event.removeListener(map, 'click', addRoadViewClickEvt)
    }
  }, [isAppearRoad])

  /**
   * 로드뷰 레이어 클릭
   */
  const appearRoad = () => {
    if (isAppearRoad) {
      //로드뷰 도로 영역 색칠 걷어내기
      map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW)
      setIsAppearRoad(false)
    } else {
      //로드뷰 도로 영역 색칠
      map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW)
      setIsAppearRoad(true)
    }
  }

  /**
   * 로드뷰 미니맵 초기화
   */
  const initMiniMap = (roadviewObj, position) => {
    const miniMapContainer = document.getElementById(
      'roadview-miniMap-layer',
    )

    const options = {
      center: position,
      level: 2,
    }

    //지도 생성 및 객체 리턴
    const miniMap = new kakao.maps.Map(miniMapContainer, options)

    //지도 레벨 제한
    miniMap.setMinLevel(2)
    miniMap.setMaxLevel(2)

    //로드뷰 도로 영역 색칠
    miniMap.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW)

    setMiniMapWalker(roadviewObj, miniMap, position)
  }

  // 로드뷰의 방향 세팅
  const getRoadviewCenterPoint = (roadview) => {
    // 로드뷰의 좌표와 clickPosition의 좌표를 비교하여 0~360도 사이의 각도를 계산한다
    let roadviewPosition = roadview.getPosition()
    let direction =
      Math.round(
        (Math.atan2(
          roadviewPosition.getLng() - clickPosition?.centerpoint?.[1],
          roadviewPosition.getLat() - clickPosition?.centerpoint?.[0],
        ) *
          180) /
          Math.PI +
          540,
      ) % 360

    // 계산한 각도로 동동이 방향(pan)을 돌려준다
    let viewpointWithPanoId = roadview.getViewpointWithPanoId()
    roadview.setViewpoint({
      ...viewpointWithPanoId,
      pan: direction,
    })
  }
  /**
   * 미니 맵 위에 동동이 올리기
   * @param {*} roadview 로드뷰 객체
   * @param {*} miniMap 미니맵 객체
   * @param {*} mapCenter 맵 센터 포인트
   * @returns void
   */
  const setMiniMapWalker = (roadview, miniMap, mapCenter) => {
    let mapWalker = null

    // 로드뷰가 초기화 되었을때 map walker를 생성한다.
    mapWalker = new MapWalker(mapCenter)
    mapWalker.setMap(miniMap)

    // 로드뷰를 상,하,좌,우,줌인,줌아웃을 할 경우 발생한다.
    // 로드뷰를 조작할때 발생하는 값을 받아 map walker의 상태를 변경해 준다.
    kakao.maps.event.addListener(
      roadview,
      'viewpoint_changed',
      function () {
        // 이벤트가 발생할 때마다 로드뷰의 viewpoint값을 읽어, map walker에 반영
        const viewpoint = roadview.getViewpoint()
        mapWalker.setAngle(viewpoint.pan)
      },
    )

    // 로드뷰내의 화살표나 점프를 하였을 경우 발생한다.
    // position값이 바뀔 때마다 map walker의 상태를 변경해 준다.
    kakao.maps.event.addListener(
      roadview,
      'position_changed',
      function () {
        const position = roadview.getPosition()
        mapWalker.setPosition(position)
        miniMap.setCenter(position)
      },
    )

    //미니맵 지도 클릭하면 클릭한 위치 로드뷰를 보여줌(로드뷰가 불가한 지역이라면 가장 가까운 곳으로 강제 이동시킨다)
    kakao.maps.event.addListener(
      miniMap,
      'click',
      function (mouseEvent) {
        const position = mouseEvent.latLng
        const roadviewClient = new kakao.maps.RoadviewClient()

        roadviewClient.getNearestPanoId(
          position,
          50,
          function (panoId) {
            if (panoId) {
              roadview.setPanoId(panoId, position)
            } else {
              dispatch(
                setRoadView({ isVisible: false, isSupport: false }),
              )
            }
          },
        )

        mapWalker.setPosition(position)
        miniMap.setCenter(position)
      },
    )
  }
  const roadviewRoot = document.getElementById('roadview-root')

  return (
    <>
      <LayerGroupItem active={isAppearRoad} onClick={appearRoad}>
        {isAppearRoad ? <IconActive /> : <Icon />}
        <span>로드뷰</span>
      </LayerGroupItem>
      {ReactDOM.createPortal(
        <MapRoadView
          isVisible={isVisible}
          onClick={() => setIsVisible(false)} //닫기임
        >
          <div id="roadview" ref={roadviewRef} />
          <MiniMap id="roadview-miniMap-layer" />
        </MapRoadView>,
        roadviewRoot,
      )}
      <MapRoadViewContainer />
    </>
  )
}

export default MapLayerRoadView
