import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import MapLayerSelectGongsi from './MapLayerSelectGongsi'
import MapLayerDongBoundary from './MapLayerDongBoundary'
import MapLayerHousingSite from './MapLayerHousingSite'
import MapLayerEmptyLand from './MapLayerEmptyLand'
import MapLyaerMountain from './MapLyaerMountain'
import MapLayerSchool from './MapLayerSchool'

const MapLayerSelect = ({ visible }) => {
  return (
    <Wrapper visible={visible}>
      <MapLayerSelectGongsi />
      <MapLayerDongBoundary />
      <MapLayerHousingSite />
      <MapLayerEmptyLand />
      <MapLyaerMountain />
      <MapLayerSchool />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: -133px;
  right: 74px;
  max-height: calc(100vh - 150px);
  background-color: transparent;
  z-index: 99999;
  align-items: end;

  ${media.mediumS`
    position: fixed;
    bottom: ${theme.base.bottomMenuHeight + 10}px;
  `}

  @media screen and (max-height: 800px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`
export default MapLayerSelect
