import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const SummarySizeReviewNoti = () => {
  const legalPlanning = useSelector(
    (state) => state.summaryOther.legalPlanning,
  )
  return (
    legalPlanning?.others &&
    legalPlanning?.others?.length > 0 &&
    legalPlanning?.others?.filter(
      (str) => str.includes('관리구역').length > 0,
    ) && (
      <Block>
        이 주소는 개발밀도관리구역, 중점경관관리구역이에요. 반드시
        도시계획조례를 확인해 주세요.
      </Block>
    )
  )
}
const Block = styled.div`
  border-radius: 12px;
  border: 1px solid ${theme.colors.red[400]};
  background-color: ${theme.colors.red[50]};
  padding: 16px 20px;
  margin: 0 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.tint.danger};
  line-height: 150%;
`

export default SummarySizeReviewNoti
