import { useState } from 'react'
import NoteFilterWrap from './NoteFilterWrap'
import NoteFilterType from './NoteFilterType'
import NoteFilterPrice from './NoteFilterPrice'
import NoteFilterArea from './NoteFilterArea'
import NoteFilterExtra from './NoteFilterExtra'

const NoteFilter = ({
  initialState,
  filterState,
  selectedChannel,
  setSelectedChannel,

  selectedStatus,
  setSelectedStatus,

  propertyTypes,
  selectedPropertyTypes,
  setSelectedPropertyTypes,

  contractTypes,
  selectedContractTypes,
  setSelectedContractTypes,

  salePrice,
  selectedSalePrice,
  setSelectedSaleprice,

  rent,
  selectedRent,
  setSelectedRent,

  setSelectedArea,
  setSelectedMoveDate,

  floorCnt,
  selectedFloorCnt,
  setSelectedFloorCnt,

  extraOptions,
  selectedExtraOptions,
  setSelectedExtraOptions,

  onClose,
  onReset,
}) => {
  // 기본 탭 설정
  const [currentTab, setCurrentTab] = useState('type')

  const isDirty =
    JSON.stringify(initialState) !== JSON.stringify(filterState)

  return (
    <NoteFilterWrap
      onClose={onClose}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      onReset={onReset}
      isDirty={isDirty}
    >
      {currentTab === 'type' && (
        <NoteFilterType
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          propertyTypes={propertyTypes}
          selectedPropertyTypes={selectedPropertyTypes}
          setSelectedPropertyTypes={setSelectedPropertyTypes}
          contractTypes={contractTypes}
          selectedContractTypes={selectedContractTypes}
          setSelectedContractTypes={setSelectedContractTypes}
        />
      )}

      {currentTab === 'price' && (
        <NoteFilterPrice
          salePrice={salePrice}
          selectedSalePrice={selectedSalePrice}
          setSelectedSaleprice={setSelectedSaleprice}
          rent={rent}
          selectedRent={selectedRent}
          setSelectedRent={setSelectedRent}
        />
      )}

      {currentTab === 'area' && (
        <NoteFilterArea setSelectedArea={setSelectedArea} />
      )}
      {currentTab === 'extra' && (
        <NoteFilterExtra
          setSelectedMoveDate={setSelectedMoveDate}
          floorCnt={floorCnt}
          selectedFloorCnt={selectedFloorCnt}
          setSelectedFloorCnt={setSelectedFloorCnt}
          extraOptions={extraOptions}
          selectedExtraOptions={selectedExtraOptions}
          setSelectedExtraOptions={setSelectedExtraOptions}
        />
      )}
    </NoteFilterWrap>
  )
}

export default NoteFilter
