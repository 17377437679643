import FixedLayer from 'components/common/FixedLayer/FixedLayer'
import useChannelPermission from 'containers/note/channel/hooks/useChannelPermission'
import theme from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useCallback } from 'react'
import { List } from 'components/design'
import { ListItem } from 'components/design'
import { ListItemLabel } from 'components/design'
import SettingsHead from 'components/jmapnote/settings/SettingsHead'
import ChannelRemove from 'components/jmapnote/channel/ChannelRemove'
import { ListItemDesc } from 'components/design'
import { StyledIcon } from 'components/summary/SummaryOwner/SummaryOwnerInfoGraph'
import { useSelector } from 'react-redux'
import NoteUploadExcelContainer from 'components/jmapnote/noteList/NoteUploadExcelContainer'
import Scrollbars from 'react-custom-scrollbars-2'
import InviteHead from 'components/jmapnote/invite/InviteHead'
import InviteContainer from 'containers/note/invite/InviteContainer'
import InviteNoMemberImg from 'assets/images/invite-nomember.png'
import NoteInviteRewardModal from './NoteInviteReward/NoteInviteRewardModal'
import { getPlan } from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import moment from 'moment'
import NoteInviteRewardUpgradeModal from './NoteInviteReward/NoteInviteRewardUpgradeModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NoteSetting = () => {
  const [layerVisible, setLayerVisible] = useState(false)
  const [inviteVisible, setInviteVisible] = useState(false)
  const [inviteRewardVisible, setInviteRewardVisible] = useState(false)
  const [inviteRewardNoMemberVisible, setInviteRewardNoMemberVisible] =
    useState(false)
  const permission = useChannelPermission()
  const [onRequestGetPlan, loadingPlan, dataGetPlan] = useAxios(getPlan)
  const [isChargedMember, setIsChargedMember] = useState(false)
  const [isPlanLoading, setIsPlanLoading] = useState(false)

  const history = useHistory()

  const selectedChannelInfo = useSelector(
    (state) => state.channel.selectedChannelInfo,
  )
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  useEffect(() => {
    onRequestGetPlan()
  }, [])

  useEffect(() => {
    if (!dataGetPlan) {
      setIsChargedMember(false)
      return
    }

    setIsPlanLoading(true)
    if (!dataGetPlan?.result) {
      setIsChargedMember(false)
      return
    }

    if (dataGetPlan.result?.payment_name == '프리') {
      setIsChargedMember(false)
      return
    }

    const diff = moment(
      dataGetPlan.result.next_payment_date,
      'YYYYMMDDHHmmss',
    ).diff(moment(), 'milliseconds')

    //요금제 기간 다 되어도 return
    if (diff < 0) {
      setIsChargedMember(false)
      return
    }

    //등본 남은 개수 없어도 return
    if (dataGetPlan.result.remain_count < 0) {
      setIsChargedMember(false)
      return
    }

    if (dataGetPlan.result) {
      setIsChargedMember(true)
    }
  }, [dataGetPlan])

  const onClickSettings = () => {
    setLayerVisible(true)
  }

  const onCloseLayer = () => {
    setLayerVisible(false)
  }

  const goToInvitepage = () => {
    if (!channelInfo && !selectedChannelInfo) {
      return
    }
    setInviteVisible(true)
  }

  const onCloseInviteSetting = () => {
    setInviteVisible(false)
  }

  const onClickInvite = () => {
    setInviteRewardVisible(true)
  }

  const onClickInviteNoMember = () => {
    setInviteRewardNoMemberVisible(true)
  }

  return (
    <Wrapper>
      <SettingText color="darkGray" circle onClick={onClickSettings}>
        <i className="fas fa-cog"></i>
        채널 설정
      </SettingText>

      {isPlanLoading && (
        <StyledImg
          src={InviteNoMemberImg}
          onClick={
            isChargedMember ? onClickInvite : onClickInviteNoMember
          }
        />
      )}

      <NoteInviteRewardUpgradeModal
        visible={inviteRewardNoMemberVisible}
        onClose={() => setInviteRewardNoMemberVisible(false)}
      />

      <NoteInviteRewardModal
        visible={inviteRewardVisible}
        onClose={() => setInviteRewardVisible(false)}
      />

      <FixedLayer visible={layerVisible} onClose={onCloseLayer}>
        <ScrollbarsWrapper>
          <Scrollbars autoHide>
            <Title>설정</Title>
            <SettingsHead />

            {permission === 'owner' && (
              <List title="채널 관리">
                <ListItem
                  prepend={
                    <StyledIcon type="user" className="fad fa-users" />
                  }
                  chevron
                  onClick={goToInvitepage}
                >
                  <ListItemLabel>초대 관리</ListItemLabel>
                </ListItem>
              </List>
            )}
            <List title="부가 기능">
              <ListItem>
                <NoteUploadExcelContainer
                  onUploadComplete={onCloseLayer}
                />
              </ListItem>
            </List>
            <List title="기타">
              <ListItem chevron>
                <a
                  href="https://forms.gle/mp6oU3PDaJqDfM2N7"
                  target="_blank"
                >
                  <ListItemLabel>불편신고</ListItemLabel>
                  <ListItemDesc small>
                    사용자의 경험 개선을 위한 아이디어 또는 의견을
                    들려주세요.
                  </ListItemDesc>
                </a>
              </ListItem>
              <ChannelRemove
                channel_srl={
                  selectedChannelInfo?.channel_srl ||
                  channelInfo?.channel_srl
                }
              />
            </List>
          </Scrollbars>
        </ScrollbarsWrapper>
      </FixedLayer>

      {/* 초대관리 */}
      <FixedLayer
        visible={inviteVisible}
        onClose={onCloseInviteSetting}
        hasDimmer={false}
        outSideClickClose
      >
        <Scrollbars autoHide>
          <Title>초대 관리</Title>
          <InviteHead />
          {inviteVisible && (
            <InviteContainer
              channel_srl={
                selectedChannelInfo?.channel_srl ||
                channelInfo?.channel_srl
              }
            />
          )}
        </Scrollbars>
      </FixedLayer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 15px 0 20px;
`

const SettingText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-bottom: 15px;
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  cursor: pointer;

  i {
    font-size: 19px;
    color: ${theme.colors.gray[600]};
  }
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
`

const ScrollbarsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 45px;
`

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 15px;
  cursor: pointer;
`

export default NoteSetting
