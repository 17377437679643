import { ActiveCellStyle } from 'components/jmapnotev2/style/NoteGridStyle'
import { CustomSingleCellStyle } from 'components/jmapnotev2/style/NoteStyle'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import theme from 'lib/styles/theme'
import { addSelectAllKeyEvent } from 'lib/utils/notev2/gridUtil'
import { isPressEditKey } from 'lib/utils/notev2/suppressKey'
import { useRef, useState } from 'react'
import styled from 'styled-components'

const CustomMemoCell = ({ value, node }) => {
  const { isEditable, setIsEditable, onDoubleClickSingleCell } =
    useCustomSingleCell(node?.data?.status)

  const [onRequestModifyNote, isErrorModifyNote] = useModifyNote()

  const [key, setKey] = useState('')

  const ref = useRef(null)

  /**
   * focus가 잡힌 상태에서 수정(스페이스바, 엔터, 문자열 키, 숫자열 키 등) key 감지시 수정중 상태로
   */
  const onKeyDownSingleCell = (e) => {
    if (node?.data?.status == 2) return

    if (isPressEditKey(e)) {
      setKey(e.key)
      if (!isEditable) setIsEditable(true)
    }
  }

  const onBlur = (e) => {
    onRequestModifyNote({
      data: node.data,
      updateData: { memo: e.target.value },
      currentRowNode: node,
    })

    setIsEditable(false)
  }

  const onKeyDonwTextarea = (e) => {
    addSelectAllKeyEvent(e)

    if (e.key == 'Escape') {
      e.preventDefault()
      setIsEditable(false)
    }
  }

  if (node.data.note_srl == '신규') return <></>

  return (
    <Wrapper>
      {isEditable ? (
        key == 'Enter' ? (
          <StyledTextarea
            type="text"
            defaultValue={value}
            onBlur={onBlur}
            onKeyDown={onKeyDonwTextarea}
            tabIndex={0}
            ref={ref}
            onFocus={(e) => {
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length,
              )
            }}
            autoFocus
          />
        ) : (
          <StyledTextarea
            type="text"
            defaultValue={value}
            onBlur={onBlur}
            onKeyDown={onKeyDonwTextarea}
            tabIndex={0}
            ref={ref}
            onFocus={(e) => {
              e.target.select()
            }}
            autoFocus
          />
        )
      ) : (
        <View
          tabIndex={0}
          onDoubleClick={onDoubleClickSingleCell}
          onKeyDown={onKeyDownSingleCell}
        >
          <Ellipsis>{value}</Ellipsis>
        </View>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
`

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  resize: none;
  overflow: auto;
  outline: none;
  border: 1px solid ${theme.colors.blue[500]};
  font-size: 14px;
  background-color: transparent;
  ${ActiveCellStyle}

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${theme.colors.gray[300]};
    border: 3px solid transparent;
    min-height: 50px;
  }
`

const View = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${CustomSingleCellStyle}
  display: flex;
  align-items: center;
  padding: 0 6px;
`

const Ellipsis = styled.div`
  text-align: left;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 120%;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: auto;
`

export default CustomMemoCell
