import { useEffect, useState } from 'react'
import { Skeleton } from 'antd'

import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useChannelUsers from './hooks/useChannelUsers'

import { Card } from 'components/design'
import { CardBody } from 'components/design'
import InviteList from 'components/jmapnote/invite/InviteList'

const InviteContainer = ({ channel_srl }) => {
  const { channelSrl } = useNoteMatch()

  /**
   * 채널 유저 정보 조회 hook 선언
   */
  const { _getChannelUsers, loading, data, channelUsers } =
    useChannelUsers(channel_srl ?? channelSrl)

  useEffect(() => {
    _getChannelUsers()
  }, [_getChannelUsers])

  if (loading)
    return (
      <Card noMargin>
        <CardBody>
          <Skeleton />
        </CardBody>
      </Card>
    )
  if (!data) return <></>

  return (
    <>
      <InviteList users={channelUsers} />
    </>
  )
}

export default InviteContainer
