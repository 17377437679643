import { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import useChannels from 'containers/note/channel/hooks/useChannels'
import { saveChannel } from 'lib/api/note/channelApi'
import { Modal } from 'components/design'
import { Input } from 'components/design'
import { useSelector } from 'react-redux'
import useAxios from 'lib/hooks/useAxios'
import { useInput } from 'lib/hooks/useInput'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useChannelInfo from 'containers/note/channel/hooks/useChannelInfo'
import { resetSpecificOpenedMarker } from 'modules/note'
import { useDispatch } from 'react-redux'

const ChannelSave = ({ visible, setVisible }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [name, bindName, resetName] = useInput('')
  const [description, bindDescription, resetDescription] = useInput('')

  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const [onRequest, loading, data, error, reset] = useAxios(saveChannel)
  const { _getChannels, data: channelData } = useChannels()
  const { _getChannel } = useChannelInfo()
  const notemode = localStorage.getItem('notemode')

  const [newChannelSrl, setNewChannelSrl] = useState(null)

  // 초기화
  useEffect(() => {
    if (!visible) {
      resetName()
      resetDescription()
    }
  }, [visible])

  useEffect(() => {
    if (!data) return
    _getChannels()
    setVisible(false)

    _getChannel({ channelSrl: data.channel_srl })
    setNewChannelSrl(data.channel_srl)
    dispatch(resetSpecificOpenedMarker()) //매물 마커 강조 초기화
  }, [data])

  useEffect(() => {
    if (!channelData || !channelData?.result) return
    if (!newChannelSrl) return

    const newFolderSrl = channelData.result.find(
      ({ channel_srl }) => channel_srl == newChannelSrl,
    )?.folder_srl
    if (notemode == 'card') {
      history.push(`/note/${newChannelSrl}/${newFolderSrl}`)
    } else {
      history.push(`/note/v2/notes/${newChannelSrl}`)
    }
  }, [channelData])

  const onOk = async () => {
    await onRequest({
      memberSrl: loginInfo?.member_srl,
      name,
      description,
    })
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title="채널 생성"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Label>채널명</Label>
      <Input expand {...bindName} />

      <Label>채널 설명</Label>
      <Input expand {...bindDescription} />
    </Modal>
  )
}

const Label = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 700;
`

export default ChannelSave
