import styled from 'styled-components'
import Button from './Button'

const Icon = styled.svg`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`

const ButtonCheckbox = ({ children, checked, expand, onClick }) => {
  return (
    <Button
      color={checked ? 'primary' : null}
      plain
      expand={expand}
      onClick={onClick}
    >
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </Icon>
      {children}
    </Button>
  )
}

export default ButtonCheckbox
