import NoteDashboardQuickLinkList from 'components/jmapnotev2/NoteDashboard/NoteDashboardQuickLinkList'
import styled from 'styled-components'
import naverSrc from 'assets/images/logo/naver-map.png'
import lawSrc from 'assets/images/logo/law.png'
import govSrc from 'assets/images/logo/gov24.png'
import landSrc from 'assets/images/logo/land.png'
import rebSrc from 'assets/images/logo/reb.png'
import roadSrc from 'assets/images/logo/road.png'
import ilsaSrc from 'assets/images/logo/14.png'
import kbSrc from 'assets/images/logo/kb.png'
import daumSrc from 'assets/images/logo/daum.png'
import theme from 'lib/styles/theme'
import NoteDashboardBlock from 'components/jmapnotev2/NoteDashboard/NoteDashboardBlock'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'

/**
 * 매물노트 대시보드
 * 퀵링크
 */
const NoteDashboardQuickLinkContainer = () => {
  const { isAllInOne } = useIsAllInOne()
  const platforms = [
    {
      icon: naverSrc,
      text: '네이버부동산',
      src: 'https://land.naver.com/',
    },
  ]

  //TODO: 여기에 사용자 지정 링크 api 더하기
  const custom = [
    {
      icon: lawSrc,
      text: '인터넷등기소',
      src: 'http://www.iros.go.kr/',
    },
    {
      icon: govSrc,
      text: '정부24',
      src: 'https://www.gov.kr/',
    },
    {
      icon: landSrc,
      text: '토지이음',
      src: 'https://www.eum.go.kr/',
    },
    {
      icon: rebSrc,
      text: '부동산공시가격알리미',
      src: 'https://www.realtyprice.kr/notice/main/mainBody.htm',
    },
    {
      icon: roadSrc,
      text: '부동산거래관리시스템',
      src: 'https://rtms.molit.go.kr/',
    },
    {
      icon: ilsaSrc,
      text: '일사편리',
      src: 'https://www.kras.go.kr/',
    },
    {
      icon: kbSrc,
      text: 'KB 부동산',
      src: 'https://kbland.kr/',
    },
    {
      icon: daumSrc,
      text: '다음 부동산',
      src: 'https://realty.daum.net/',
    },
  ]

  const onClickMore = () => {
    alert('TODO!')
  }
  if (!isAllInOne) return

  return (
    <NoteDashboardBlock title="퀵링크">
      <NoteDashboardQuickLinkList data={platforms} />

      <DividerWrapper>
        <Divider />
      </DividerWrapper>
      <NoteDashboardQuickLinkList
        data={custom}
        hasMore
        onClickMore={onClickMore}
      />
    </NoteDashboardBlock>
  )
}

const DividerWrapper = styled.div`
  margin: 0 -20px;
  width: 100%;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 18px 0;
  background-color: ${theme.colors.gray[100]};
`

export default NoteDashboardQuickLinkContainer
