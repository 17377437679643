import theme from 'lib/styles/theme'

const { default: styled } = require('styled-components')

const StyledDialogHeader = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.875rem 1.25rem;

  font-weight: 700;

  ${({ border }) =>
    border &&
    `
    border-bottom: 1px solid ${theme.colors.gray[100]};
    // margin-bottom: 1.25rem;
  `}
`

const DropdownHeader = ({ children, border }) => {
  return (
    <StyledDialogHeader border={border}>{children}</StyledDialogHeader>
  )
}

export default DropdownHeader
