import { buildingPurposeColors, numberWithCommas } from 'utils/index'
import { Tag } from 'components/design'
import { getCustomPurpose } from 'lib/utils'
import { MetaItem } from './SummaryHeaderMeta'

/**
 * summary 최상단 태그 - 공동주택
 */
const SummaryHeaderMetaApt = ({ building, land }) => {
  const getPropertyTypeColor = () => {
    return getCustomPurpose(building)
      ? typeof buildingPurposeColors[getCustomPurpose(building)] !==
        'undefined'
        ? buildingPurposeColors[getCustomPurpose(building)]
        : null
      : null
  }

  return (
    <>
      {building?.apt?.ho_count || building?.household ? (
        <MetaItem>
          <Tag
            size="small"
            label={
              building?.apt?.dong_count
                ? `${building?.apt?.dong_count}동`
                : '세대 수'
            }
            labelTooltip="동"
            tooltip="세대 수"
          >
            {numberWithCommas(
              building?.apt?.ho_count || building?.household || '',
            )}
            세대
          </Tag>
        </MetaItem>
      ) : null}
      <MetaItem>
        <Tag
          size="small"
          label={getCustomPurpose(building) || '건물없음'}
          color={getPropertyTypeColor()}
          labelTooltip="건물 타입"
          tooltip="준공년도"
        >
          {building?.result?.[0]?.approval_date?.substring(0, 4) || (
            <>&ndash;</>
          )}
          년
        </Tag>
      </MetaItem>
    </>
  )
}

export default SummaryHeaderMetaApt
