import { useEffect, useCallback, useContext } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useSelector } from 'react-redux'

import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import Container from 'components/base/Container/Container'

import FavoriteList from 'components/favorite/FavoriteList'
import './Favorite.scss'
import useAxios from 'lib/hooks/useAxios'
import { getFavoriteList } from 'lib/api/favoriteApi'
import HeaderBackAbsolute from 'components/common/Header/HeaderBackAbsolute'

const Favorite = () => {
  const { state, actions } = useContext(MapContext)
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const [onRequest, , data] = useAxios(getFavoriteList)
  /**
   * @METHOD: 즐겨찾기 목록 조회
   */
  useEffect(() => {
    if (loginInfo.member_type) {
      getFavorites()
    }
  }, [loginInfo])

  /**
   * @METHOD: 즐겨찾기 목록 업데이트
   */
  useEffect(() => {
    if (state.needToUpdateFavorites) {
      getFavorites()
    }
    // 초기화
    return () => {
      actions.setNeedToUpdateFavorites(false)
    }
  }, [state.needToUpdateFavorites])

  /**
   * @METHOD: 폴더와 즐겨찾기 목록 불러오기
   */
  const getFavorites = useCallback(() => {
    // 초기화
    actions.setFavoriteDatas([])

    onRequest({ _rx_csrf_token: loginInfo.csrf_token })
  }, [loginInfo])

  useEffect(() => {
    if (!data) return

    const result = data.result

    if (result.favorites) {
      // 각 즐겨찾기에 대한 매물목록 조회 활성화 상태값 세팅
      actions.setFavoriteDatas(result.favorites)
    }
  }, [data])

  return (
    <>
      <HeaderBackAbsolute title={'저장함'} />
      <Container white>
        <div className="favorite-page">
          <DndProvider
            backend={
              window.innerWidth > theme.bp.medium
                ? HTML5Backend
                : TouchBackend
            }
          >
            <FavoriteList />
          </DndProvider>
        </div>
      </Container>
    </>
  )
}

export default Favorite
