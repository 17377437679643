import { AdButton } from './ButtonStyle'
import useAdRegister from 'lib/hooks/notev2/useAdRegister'

/**
 * 매물 3뎁스 광고 목록 버튼
 * 재광고 버튼
 * @param adSrl
 */
const RecycleForNoteButton = ({ adSrl, callbackFn }) => {
  const { preregister, loading, paymentModal } = useAdRegister(
    adSrl,
    recycleCallback,
  )

  /**
   * 재광고
   */
  const onClickRecycle = (adSrl) => {
    preregister({
      recycle: true,
    })
  }

  function recycleCallback() {
    callbackFn()
  }

  return (
    <>
      <AdButton
        expand
        color="blue"
        size={'small'}
        onClick={() => onClickRecycle(adSrl)}
        forNote
      >
        다시 광고 등록
      </AdButton>
      {loading}
      {paymentModal}
    </>
  )
}

export default RecycleForNoteButton
