import styled from 'styled-components'
import theme from 'lib/styles/theme'
import NoteContactItemBase from 'components/jmapnote/contact/NoteContactItemBase'

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.75rem;
  height: 1.75rem;
  border-radius: 100%;

  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
  border: 1px solid ${theme.colors.gray[300]};

  transition: 0.2s ease;

  ${({ actived, color }) =>
    actived &&
    `
    color: white;
    background-color: ${
      color == 'blue'
        ? theme.colors.blue[400]
        : theme.colors.primary[400]
    };
    border-color: ${
      color == 'blue'
        ? theme.colors.blue[400]
        : theme.colors.primary[400]
    };
  `}
`

const NoteFormContactSelectItem = ({ item, actived, onChange }) => {
  return (
    <NoteContactItemBase
      alias={item?.alias}
      numbers={item?.numbers}
      append={
        <StyledIcon
          color={
            localStorage.getItem('notemode') == 'sheet'
              ? 'blue'
              : 'primary'
          }
          actived={actived}
          onClick={() => onChange(item)}
        >
          <i className="far fa-check"></i>
        </StyledIcon>
      }
      actived={actived}
      onClick={() => onChange(item)}
    />
  )
}

export default NoteFormContactSelectItem
