import styled from 'styled-components'
import AppContainer from './AppContainer'
import { SectionWrapper, SubTitle, Title } from './LandingStyle'
import ImgSrc from 'assets/images/note/landing/atonce.png'
import theme, { media } from 'lib/styles/theme'
import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

const LandingAtOnce = ({}, ref) => {
  const isAddedEvent = useRef(false)
  const targetRef = useRef(null)

  const [imgTransform, setImgTransform] = useState(
    `translate3d(0px, 0px, 0px) scale(2)`,
  )
  const [imgOpacity, setImgOpacity] = useState(0.2)

  useEffect(() => {
    /**
     * 해당 영역이 보일때만 스크롤 이벤트 실행 그 이외에는 삭제
     */
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (isAddedEvent.current) return
          addScrollEvent()
          isAddedEvent.current = true
        } else {
          removeScrollEvent()
          isAddedEvent.current = false
        }
      })
    })

    observer.observe(targetRef.current)
  }, [])

  /**
   * jquery식 offset top 얻기
   * @param {*} el
   * @returns
   */
  function getOffsetTop(el) {
    if (!el.getClientRects().length) {
      return 0
    }

    let rect = el.getBoundingClientRect()

    return rect.top + ref.current.scrollTop
  }

  /**
   * 스크롤 이벤트 추가
   */
  const addScrollEvent = () => {
    if (!ref?.current) return
    ref.current.addEventListener('scroll', scrollFn)
  }

  /**
   * 스크롤 이벤트 삭제
   */
  const removeScrollEvent = () => {
    if (!ref?.current) return
    ref.current.removeEventListener('scroll', scrollFn)
  }

  /**
   * 스크롤에 따라 이미지 scale, opacity 제어 함수
   */
  function scrollFn() {
    if (!ref?.current) return
    const scroll = ref.current.scrollTop

    const targetPos = getOffsetTop(targetRef.current)

    const criteria = targetPos - 1000
    const scale = convertScale(
      scroll < criteria ? criteria : scroll,
      criteria,
      targetPos,
      4,
      0.6,
    )
    const opacity = convertScale(
      scroll < criteria ? criteria : scroll,
      criteria,
      targetPos,
      0.2,
      1,
    )

    setImgTransform(
      `translate3d(0px, 0px, 0px) scale(${scale < 1 ? 1 : scale})`,
    )

    setImgOpacity(opacity)
  }

  /**
   * 숫자를 다른 범위의 값으로 변환하기
   * @param {*} num target
   * @param {*} in_min 원본 최소값
   * @param {*} in_max 원본 최대값
   * @param {*} out_min 결과 최소값
   * @param {*} out_max 결과 최대값
   * @returns
   */
  const convertScale = useCallback(
    (num, in_min, in_max, out_min, out_max) => {
      return (
        ((num - in_min) * (out_max - out_min)) / (in_max - in_min) +
        out_min
      )
    },
    [],
  )

  return (
    <Wrapper ref={targetRef}>
      <AppContainer>
        <Title center="Y">
          매물! 중심으로
          <br />
          흩어져 있는 모든 자료를 한곳에서
        </Title>
        <StyledSubTitle center="Y">
          <span>사진, 문서, 고객, 일정, 광고, 계약</span> 모두 <br />
          한번에 관리할 수 있어요
        </StyledSubTitle>
        <ImageWrapper transform={imgTransform} opacity={imgOpacity}>
          <img src={ImgSrc} />
        </ImageWrapper>
        <Bubble type="1"></Bubble>
      </AppContainer>
    </Wrapper>
  )
}

const Wrapper = styled(SectionWrapper)`
  word-break: keep-all;
  overflow: hidden;
`

const StyledSubTitle = styled(SubTitle)`
  span {
    font-weight: 700;
    background: linear-gradient(
      89.91deg,
      #0d4b96 -2.48%,
      rgba(114, 13, 150, 0.58) 96.51%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
`

const ImageWrapper = styled.div`
  margin-top: 150px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform: ${({ transform }) => transform && transform};
  opacity: ${({ opacity }) => opacity && opacity};

  img {
    max-width: 100%;
  }

  ${media.mediumS`
        margin-top: 50px;
    `}
`

const Bubble = styled.div`
  position: absolute;
  width: 752px;
  height: 752px;

  ${({ type }) => {
    switch (type) {
      case '1':
        return `
        right: 0;
        bottom: 0;
        background: radial-gradient(
        38.65% 38.65% at 50% 50%,
        rgba(240, 206, 249, 0.22) 0%,
        rgba(240, 206, 249, 0) 100%
        );
        
        @media (max-width: ${theme.bp.mediumS}px) {
          position: absolute;
          width: 70%;
          height: 200px;
          top: 0;
          right: 0;
          bottom: initial;
        }
        `
      case '2':
        return `
        right: -300px;
        top: -300px;
        background: radial-gradient(
        38.65% 38.65% at 50% 50%,
        rgba(240, 206, 249, 0.22) 0%,
        rgba(240, 206, 249, 0) 100%
        );
        
        @media (max-width: ${theme.bp.mediumS}px) {
          position: absolute;
          width: 70%;
          height: 200px;
          top: initial;
          right: initial;
          left: 0;
          bottom: 0;
        }
        `
    }
  }};
`

export default forwardRef(LandingAtOnce)
