import { useCallback, useState, useEffect, useRef } from 'react'
import useAxios from '../useAxios'
import { saveNote } from 'lib/api/note/noteApi'
import { useDispatch } from 'react-redux'
import {
  setNoteListInfo,
  setResetNotePropertyFilter,
} from 'modules/notev2'
import { useSelector } from 'react-redux'
import { getPropertyFilter } from 'lib/api/notev2/filterApi'

/**
 * 시트 전용
 * 유효하지 않은 주소 신규 저장 전용 hook
 * address가 생성되지 않기 때문에 바로 노트만 생성해주는 api 호출
 * 유효한 주소는 useSaveAddressForSheet hook 사용
 */
function useSaveNotes(mode) {
  const { count } = useSelector((state) => state.notev2.noteListInfo)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [onRequest, , data] = useAxios(saveNote)
  const [noteSrl, setNoteSrl] = useState(null)
  const [isError, setIsError] = useState(false)
  const [currentChannel, setCurrentChannel] = useState()
  const [callbackFn, setCallbackFn] = useState(null)
  const currentRowNodeData = useRef({})

  const [onRequestFilter, , dataFtiler, ,] = useAxios(getPropertyFilter)
  const dispatch = useDispatch()

  /**
   * 노트 저장
   * @param channel_srl 채널 srl
   * @param address_srl 주소 srl
   * @param form 주소 정보
   * @param rowNodeData 그리드 node data
   */
  const onSaveNote = useCallback(
    ({
      channel_srl,
      folder_srl,
      address_srl,
      form,
      rowNodeData,
      callbackFn,
    }) => {
      if (channelInfo?.member_type == 'readonly') return

      currentRowNodeData.current = rowNodeData
      setCurrentChannel(channel_srl)
      //노트 저장 api
      onRequest({
        channelSrl: channel_srl,
        addressSrl: address_srl,
        folder_srl: mode == 'card' ? folder_srl : null,
        form,
      })
      if (callbackFn) {
        setCallbackFn(() => callbackFn)
      }
    },
    [],
  )

  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.error('save note 에러')
      setIsError(true)
      return
    }

    //매물 개수 count +1
    dispatch(setNoteListInfo({ count: count + 1 }))

    //필터 refresh
    onRequestFilter({
      channel_srl: data.result.channel_srl || currentChannel,
    })

    setNoteSrl(data.result)
  }, [data])

  useEffect(() => {
    if (!dataFtiler) {
      setCallbackFn(null)
      return
    }

    dispatch(
      setResetNotePropertyFilter(dataFtiler.result?.property || {}),
    )
    if (callbackFn) {
      callbackFn(noteSrl)
      setCallbackFn(null)
    }
  }, [dataFtiler])

  return [onSaveNote, noteSrl, isError, currentRowNodeData.current]
}

export default useSaveNotes
