import { Tab, TabItem } from 'components/design'
import theme from 'lib/styles/theme'
import { useState } from 'react'
import styled from 'styled-components'

const NotePaymentRule = () => {
  const [activeTab, setActiveTab] = useState(1)

  const onChangeTab = (value) => {
    setActiveTab(value)
  }
  return (
    <Wrapper>
      <Section>
        <Title>서비스 이용 규정</Title>
        <Content>
          <Tab size="large" noContainer>
            <TabItem
              active={activeTab === 1}
              onClick={() => onChangeTab(1)}
            >
              이용
            </TabItem>
            <TabItem
              active={activeTab === 2}
              onClick={() => onChangeTab(2)}
            >
              이용 중지
            </TabItem>
            <TabItem
              active={activeTab === 3}
              onClick={() => onChangeTab(3)}
            >
              환불 규정
            </TabItem>
          </Tab>
          <List>
            {activeTab == 1 && (
              <>
                <li>모든 가격 표시는 VAT 포함 가격입니다.</li>
                <li>서비스의 이용 기간은 1개월&#40;30일&#41;입니다.</li>
                <li>
                  등기부등본 발급 횟수 소진 시, 추가 등기부등본
                  다운로드권을 구매하여 사용 가능합니다.
                </li>
                <li>
                  남은 등기부등본 다운로드권은 다음달로 이월되어 사용
                  가능합니다.
                </li>
                <li>
                  차감되었던 등기부등본은 발급 실패시 자동으로
                  환급됩니다.
                </li>
              </>
            )}
            {activeTab == 2 && (
              <>
                {/* <li>
                  요금 미납시 5일동안 24시간 간격으로 자동결제가
                  요청되며 최종 결제에 실패하면 자동으로 프리요금제로
                  전환됩니다.
                </li> */}
                <li>
                  프리요금제로 전환된 경우, 등기부등본 추가 구매는
                  불가합니다.
                </li>
                <li>
                  요금제가 비활성화된 경우, 등기부등본 다운로드권 추가
                  구매는 불가합니다.
                </li>
                <li>
                  유료 요금제에 재가입하면 남은 등기부등본 다운로드권은
                  이어서 사용이 가능합니다.
                </li>
              </>
            )}
            {activeTab == 3 && (
              <>
                <li>
                  요금제에 포함된 등기부등본 발급권을 소진하거나,
                  결제일로부터 7일이 초과한 경우 환불은 불가합니다.
                </li>
                <li>
                  유료 요금제를 취소한 후에도 이미 결제한 유료 요금제의
                  이용기간이 남아 있다면 그동안 유료 서비스를 이용할 수
                  있습니다.
                </li>
                <li>
                  이메일(cs@jootek.com) 또는 카카오톡 문의하기를 통해
                  환불 신청을 할 수 있습니다.
                </li>
                <li>환불시 결제한 수단으로 환불됩니다.</li>
                <li>환불은 영업일 기준 3~5일 정도 소요됩니다.</li>
                <li>
                  결제 취소로 인한 환불은 등기부등본 발급 내역이 없는
                  고객에 한하여 결제일로부터 7일 이내 결제 취소 요청시
                  100% 환불이 가능합니다.
                </li>
                <li>
                  추가 등기부등본 다운로드권 구매 후 등기부등본 발급
                  내역이 없는 고객에 한하여 결제일로부터 7일 이내 결제
                  취소 요청시 100% 환불이 가능합니다.
                </li>
                <li>
                  주소지의 중복 또는 오기입으로 인한 등기부등본 발급은
                  환불 불가합니다.
                </li>
              </>
            )}
          </List>
        </Content>
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 60px;
  padding-bottom: 100px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`

const Content = styled.div``

const List = styled.ul`
  min-height: 140px;
  margin-top: 12px;
  padding-left: 20px;
  li {
    color: ${theme.colors.gray[600]};
    list-style-type: disc;
    line-height: 160%;
    font-size: 16px;

    &::marker {
      font-size: 14px;
    }
  }
`

export default NotePaymentRule
