import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

const NoteDropSelectorItem = ({
  name,
  value,
  onClick,
  focus,
  setCurrentFocus,
  i,
  onClose,
  expand,
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (name == value) setCurrentFocus(i)
  }, [])

  useEffect(() => {
    if (focus) {
      ref.current.focus()
    }
  }, [focus])

  const onKeyDown = (e) => {
    if (e.key == 'Escape') {
      onClose()
    }
    if (e.key == 'Enter' && document.activeElement == ref.current) {
      onClick(name)
    }
  }

  return (
    <Item
      tabIndex={0}
      ref={ref}
      active={name == value}
      onClick={() => {
        onClick(name)
      }}
      onKeyDown={onKeyDown}
      expand={expand}
    >
      {name}
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  align-items: center;
  width: calc(33.3% - 3px);
  height: 32px;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;

  ${({ active }) =>
    active
      ? `
    background-color: ${theme.colors.noteSheet.dropdown.primary};
    font-weight: 700;
    
  `
      : `
      &:focus, &:focus-within {
        background-color: ${theme.colors.noteSheet.dropdown.hover};
      }
  `}

  ${({ expand }) =>
    expand &&
    `
      width: 100%;
  `}

  &:hover {
    background-color: ${theme.colors.noteSheet.dropdown.hover};
    font-weight: 700;
  }

  &:focus,
  &:focus-within {
    outline: none;
  }

  ${media.mediumS`
    width: calc(50% - 4px);
  `}
`

export default React.memo(NoteDropSelectorItem)
