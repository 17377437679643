import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { SheetButton } from 'components/jmapnotev2/style/NoteStyle'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ReactComponent as OpenIcon } from 'assets/icon/note/open.svg'
import OpenButton from '../OpenButton'

const CustomDescCell = ({ data, value }) => {
  const params = useParams()
  const history = useHistory()

  const onClickOpenDetail = () => {
    const channelSrl = params?.channelSrl ?? data?.channel_srl
    history.push({
      pathname: `/note/v2/notes/${channelSrl}/${
        data?.address_srl || '0'
      }/${data?.note_srl}`,
    })
  }

  return (
    <View tabIndex={0}>
      <ContentWrapper>
        <Ellipsis>
          {value.fee && value.fee != '0원' && (
            <>
              <strong>관리비: </strong> {value.fee} &#124;&nbsp;
            </>
          )}
          {value.move_date && (
            <>
              <strong>입주가능일: </strong> {value.move_date}
              &#124;&nbsp;
            </>
          )}
          {value.data}
        </Ellipsis>

        {data?.note_srl && data?.note_srl != '신규' && (
          <OpenDetailButton>
            <OpenButton size="medium" onClick={onClickOpenDetail} />
          </OpenDetailButton>
        )}
      </ContentWrapper>
    </View>
  )
}

const View = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const Ellipsis = styled.div`
  width: 100%;
  text-align: left;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 120%;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: auto;
  font-size: 14px;
  font-weight: 500;
`
const OpenDetailButton = styled.div`
  position: absolute;
  right: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 1px;
  position: relative;
  width: 100%;
  height: 100%;
  article {
    font-size: 11px;
  }
  &:hover {
    ${OpenDetailButton} {
      opacity: 1;
    }
  }
`
const OpenText = styled.span`
  margin-left: 4px;
`

export default CustomDescCell
