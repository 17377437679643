import theme from 'lib/styles/theme'
import styled from 'styled-components'

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;

  ${({ narrow }) =>
    narrow &&
    `
    margin-bottom: 10px;
  `}
`

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`

export const Desc = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  margin-bottom: 28px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
`

export const SelectItem = styled.div`
  padding: 6px 12px;
  border-radius: 999px;
  border: 1px solid ${theme.colors.gray[200]};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.blue[400]};
    color: ${theme.colors.base.white};
  `}
`
