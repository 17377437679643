import BaseContext from 'contexts/baseContext'
import MapContext from 'contexts/map'
import theme, { media } from 'lib/styles/theme'
import { useCallback, useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

const ToggleAreaFormat = () => {
  const {
    state: { isScrolled },
  } = useContext(BaseContext)

  const {
    state: { isSquareMeter },
    actions,
  } = useContext(MapContext)

  const [cookies, setCookie, removeCookie] = useCookies([
    'clickPosition',
  ])
  const { path } = useRouteMatch()

  // 평-제곱미터 토글
  const toggleSquareMeter = useCallback(() => {
    actions.setIsSquareMeter((prev) => {
      // 쿠키저장
      setCookie('isSquareMeter', !prev ? 'squareMeter' : 'pyeong', {
        path: '/',
      })
      removeCookie('isSquareMeter', { path: '/map' })
      return !prev
    })
  })
  return (
    <ToggleWrapper isScrolled={isScrolled && path !== '/'}>
      <AreaToggle onClick={toggleSquareMeter}>
        <MobileToggle isSquareMeter={isSquareMeter}>
          <i className="far fa-sync-alt"></i>
          <ToggleText>{isSquareMeter ? '㎡' : '평'}</ToggleText>
        </MobileToggle>
      </AreaToggle>
    </ToggleWrapper>
  )
}

const AreaToggle = styled.div`
  display: flex;
  align-items: center;
  \ & > span {
    font-size: 0.875rem;

    &:first-child {
      margin-right: 0.5rem;
    }
    &:last-child {
      margin-left: 0.5rem;
    }

    &.active {
      color: ${theme.colors.primary[400]};
      font-weight: 700;
    }
  }
`

const MobileToggle = styled.div`
  width: 42px;

  & > i {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    transform: ${({ isSquareMeter }) =>
      isSquareMeter ? `rotate(180deg)` : `rotate(0deg)`};
    transition: all 0.35s;

    ${media.smallPc`
      font-size: 15px;
    `};
  }
`

const ToggleText = styled.span`
  font-weight: 700;
  font-size: 0.975rem !important;
  margin-left: 8px;

  ${media.smallPc`
    font-size: 0.9rem !important;
    margin-left: 7px;
  `}
`

const ToggleWrapper = styled.div`
  display: flex;
  height: 30px;
  justify-content: flex-end;
  margin-right: 0.5rem;
  z-index: 5;
  cursor: pointer;
  border-radius: 18px;
  padding: 3px 7px 3px 14px;
  border: 1px solid ${theme.colors.gray[300]};

  ${media.mediumS`
    flex: none;
    margin-right: 0.45rem;
    ${({ isScrolled }) => isScrolled && `margin-right: 0;`}
  `}
`

export default ToggleAreaFormat
