import theme from 'lib/styles/theme'
import CustomModal from './CustomModal'
import styled from 'styled-components'
import WelcomeImage from 'assets/images/features/welcomemodal.png'
import { useSelector } from 'react-redux'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setIsEndSignModalVisible } from 'modules/inviteReward'
import NoteInviteRewardNoti from './NoteInviteRewardNoti'

const NoteInviteJoinWelcomeModal = ({ visible, onClose }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname == '/home') return
    dispatch(setIsEndSignModalVisible(false))
  }, [location])

  const onOk = () => {
    history.push('/note/v2/payment/plan')
  }

  return (
    <CustomModal
      color="white"
      visible={visible}
      onCancel={onClose}
      width={500}
      cancelText={'다음에 하기'}
      onOk={onOk}
    >
      <Title>{loginInfo?.user_name}님 환영합니다!🎉</Title>
      <ImgWrapper>
        <img src={WelcomeImage} width={160} height={138} />
      </ImgWrapper>
      <Desc>
        원하시는 플랜을 선택하고 카드 등록을 마치면 등기열람권 4개를
        받으실 수 있습니다. 계속 진행하시겠어요?
      </Desc>
      <ListWrapper>
        <ListTitle>안내</ListTitle>
        <NoteInviteRewardNoti gray="Y" />
      </ListWrapper>
    </CustomModal>
  )
}

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;

  span {
    color: #a4d1f4;
    font-weight: 700;
  }
`
const ImgWrapper = styled.div`
  margin-top: 28px;
  text-align: center;
  width: 100%;
`

const Desc = styled.div`
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  padding: 0 10px;
  word-break: keep-all;
`

const ListWrapper = styled.div`
  border-radius: 12px;
  padding: 16px 22px;
  background-color: ${theme.colors.gray[50]};
  margin-top: 16px;
`

const ListTitle = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  color: ${theme.colors.gray[600]};
`

export default NoteInviteJoinWelcomeModal
