import { useCallback, useContext, useState } from 'react'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import moment from 'moment'

import CalendarContext from 'contexts/calendarContext'
import NoteCalendarBadge from './NoteCalendarBadge'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'

const NoteCalendarDay = ({
  day,
  date,
  dateFormat,
  currentDateMonth,
  month,
}) => {
  const {
    state: { schedules, currentDate },
    actions: { setCurrentDate },
  } = useContext(CalendarContext)

  const [timeLoading, setTimeLoading] = useState(false)

  const formatedItemDate = dateFormat.clone().format('YYYY-MM-DD')
  const formatedCurrentDate = currentDate.clone().format('YYYY-MM-DD')

  const isSelected = useCallback(() => {
    return formatedItemDate === formatedCurrentDate
  }, [formatedItemDate, formatedCurrentDate])

  const isToday = useCallback(() => {
    const formatedToday = moment().format('YYYY-MM-DD')
    return formatedToday === formatedItemDate
  }, [formatedItemDate])

  const isDefaultMonth = () => {
    if (currentDateMonth !== month) return true
    return false
  }

  const isSunday = () => {
    if (day === 0) return true
    return false
  }
  const isSaturday = () => {
    if (day === 6) return true
    return false
  }

  const findCurrentSchedules = () =>
    schedules.filter((e) => e.date === formatedItemDate)

  const onClickItem = useCallback(() => {
    setCurrentDate(dateFormat.clone())
  }, [dateFormat])

  const addSchedule = () => {
    setTimeLoading(true)
    setTimeout(() => {
      document
        .getElementById('note_dashboard_calendar_add_button')
        .click()
      setTimeLoading(false)
    }, 200)
  }

  return (
    <>
      {timeLoading && <FullScreenLoading />}
      <StyledDayItem
        isDefaultMonth={isDefaultMonth()}
        isSunday={isSunday()}
        isSaturday={isSaturday()}
        onClick={onClickItem}
        selected={isSelected()}
        onDoubleClick={addSchedule}
      >
        <StyledDate isToday={isToday()} selected={isSelected()}>
          {date}
        </StyledDate>
        <ScheduleWrap>
          {findCurrentSchedules()
            .slice(0, 4)
            .map((tagItem, i) => {
              return (
                <NoteCalendarBadge
                  key={`dashboard_schedule_badge_${i}`}
                  type={tagItem.type}
                />
              )
            })}
          {findCurrentSchedules().length > 4 && (
            <AddedItem>+{findCurrentSchedules().length - 4}</AddedItem>
          )}
        </ScheduleWrap>
      </StyledDayItem>
    </>
  )
}

const StyledDayItem = styled.div`
  flex-basis: 14.285714%;
  max-width: 14.285714%;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 4px;
  min-height: 94px;
  color: ${theme.colors.gray[900]};
  font-size: 12px;
  overflow: hidden;

  transition: 0.2s ease;

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }

  ${({ isSunday }) =>
    isSunday &&
    `
    color: ${theme.colors.tint.danger};
    `}

  ${({ isSaturday }) =>
    isSaturday &&
    `
    color: ${theme.colors.blue[600]};
    `}

  ${({ isDefaultMonth }) =>
    isDefaultMonth &&
    `
      color: ${theme.colors.gray[400]};
    `}
    
  ${({ selected }) =>
    selected &&
    `
    background-color: ${theme.colors.gray[50]};
    `}
`

const StyledDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  text-align: center;
  line-height: 100%;
  font-weight: 500;

  ${({ isToday }) =>
    isToday &&
    `
      background-color: ${theme.colors.gray[200]};
    `}
  ${({ selected }) =>
    selected &&
    `
      color: ${theme.colors.blue[600]};
      background-color: ${theme.colors.blue[50]};
      font-weight: 700;
    `}
`

const ScheduleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  flex: 1;
  width: 100%;
  max-width: 100%;
  margin-top: 1px;
`

const AddedItem = styled.div`
  color: ${theme.colors.gray[750]};
  font-size: 10px;
  font-weight: 600;
  line-height: 130%;
  margin-left: 4px;
`

export default NoteCalendarDay
