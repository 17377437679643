import styled from 'styled-components'
import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import { useHistory } from 'react-router'
import { Tooltip } from 'antd'
import { getAddressName } from 'lib/utils'
import { useDispatch } from 'react-redux'
import { setNoteListSearchParams } from 'modules/notev2'
import { setChannelInfo } from 'modules/channel'
import { useSelector } from 'react-redux'

const StyledIcon = styled.i`
  margin-left: 0.5rem;
  margin-right: 0 !important;
`

const NoteApplyButton = ({ small, note, land, onClick }) => {
  const history = useHistory()
  const channels = useSelector((state) => state.channel.channels)
  const dispatch = useDispatch()
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )
  const onClickBadge = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!note?.channel_srl) {
      return
    }
    // 주소 이름 포맷
    const landName = getAddressName(land, land.road_name)

    if (!channels || channels.length == 0) return

    const targetChannel = channels.find(
      ({ channel_srl }) => channel_srl == note?.channel_srl,
    )

    const moveChannel = targetChannel || channels[0]

    dispatch(setChannelInfo(moveChannel)) //현재 채널 세팅
    dispatch(
      setNoteListSearchParams({
        q: landName,
      }),
    ) //필터 초기화

    // 시트모드로 이동 + 주소명으로 검색한 경우로 세팅
    history.push({
      pathname: `/note/v2/notes/${note?.channel_srl}/${currentAddressId}`,
    })
  }

  if (note?.address_srl > 0) {
    return (
      <NoteApplyButtonWrapper>
        {note?.address_srl > 0 ? (
          <NoteLink onClick={(e) => onClickBadge(e)}>
            <span>{note?.count}개의 매물 저장됨</span>
            <i className="fal fa-chevron-right"></i>
          </NoteLink>
        ) : (
          ''
        )}

        <Button
          color="primary"
          size={small ? 'small' : 'large'}
          onClick={onClick}
        >
          <span>매물노트에 추가</span>
          <StyledIcon className="far fa-plus" />
        </Button>
      </NoteApplyButtonWrapper>
    )
  }

  return (
    <Button
      color="primary"
      size={small ? 'small' : 'large'}
      plain={!note?.count}
      onClick={onClick}
      expand
    >
      <span>매물노트에 추가</span>
      <StyledIcon className="far fa-plus" />
    </Button>
  )
}

const NoteApplyButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  color: ${theme.colors.primary[400]};
  background-color: ${theme.colors.primary[50]};
  border-radius: ${theme.borderRadius[2]};
`

const NoteLink = styled.a`
  flex: 1;
  color: ${theme.colors.primary[400]};
  font-weight: 600;
  padding-left: 1rem;
  cursor: pointer;

  & > i {
    margin-left: 0.5rem;
  }
`

const StyledBadge = styled.div`
  position: absolute;
  top: -0.25rem;
  right: 0;

  display: flex;

  min-width: 1.25rem;
  height: 1.25rem;
  padding: 0 0.25rem;

  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  line-height: calc(1.25rem - 4px);

  background-color: ${theme.colors.primary[400]};
  border: 2px solid white;
  border-radius: 2rem;
`

export default NoteApplyButton
