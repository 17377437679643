import axios from 'axios'

/**
 * 매물 광고 목록 가져오기
 */
export const getAdListings = async (
  {
    _rx_csrf_token,
    plan_srl,
    sort_index,
    sort_type,
    filters,
    search_keyword,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/list',
    {
      _rx_csrf_token,
      plan_srl,
      sort_index,
      sort_type,
      filters,
      search_keyword,
    },
    options,
  )

/**
 * 매물 광고 상태 필터 가져오기
 */
export const getAdFilters = async (
  { _rx_csrf_token, plan_srl, is_dashboard },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/filters',
    {
      _rx_csrf_token,
      plan_srl,
      is_dashboard,
    },
    options,
  )

/**
 * 개별 매물 select
 * @returns
 */
export const getAd = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/view',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
 * 업무 알림 목록 가져오기
 *
 * @returns
 */
export const getNotifications = async (
  { _rx_csrf_token, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/notifications',
    {
      _rx_csrf_token,
      plan_srl,
    },
    options,
  )
