import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDetailTools from './NoteDetailTools'
import { useFormContext } from 'react-hook-form'
import { colors, getColors } from 'lib/utils/propertyType'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 3뎁스 헤더(닫기, 로드뷰, 매물 이동, 삭제 등...)
 * @param onCloseLayer 닫기 버튼(레이어 닫기)
 * @param searchFn 작업 후 검색
 * @param onCloseTools 매물 이동, 닫기, 삭제 완료 후 닫기 callback
 */
const NoteDetailFormHeaderBar = ({
  onCloseLayer,
  searchFn,
  onCloseTools,
}) => {
  const isSharePage = useRouteMatch('/share/:noteSrl/:encryptedKey')
  const { watch } = useFormContext()

  const propertyType = watch('property_type')

  return (
    <Header color={getColors(propertyType)}>
      <i
        onClick={(e) => {
          e.stopPropagation()
          onCloseLayer()
        }}
        className="fal fa-times"
      ></i>
      {isSharePage?.isExact && <Share>공유된 매물노트</Share>}
      <NoteDetailTools searchFn={searchFn} onClose={onCloseTools} />
    </Header>
  )
}
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  padding: 16px 20px 16px 20px;
  right: 0;
  z-index: 1;
  height: 50px;
  color: ${theme.colors.base.white};

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 16px;
    margin-left: -10px;
    border-radius: 6px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  ${({ color }) =>
    color &&
    `
    background-color: color-mix(in srgb, ${color}, #000000 20%);
  `}
`

const Share = styled.div`
  position: absolute;
  left: 50px;
  font-size: 16px;
  font-weight: 600;
`

export default NoteDetailFormHeaderBar
