import MapContext from 'contexts/map'
import { getBdsDetail } from 'lib/api/bds/bdsApi'
import useBdsDetailTab from 'lib/hooks/bds/useBdsDetailTab'
import useAxios from 'lib/hooks/useAxios'
import { useContext, useEffect, useState } from 'react'
import {
  useParams,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 주택 부동산
 * 상세화면 로직
 */
const BdsDetailContainer = ({ children }) => {
  const { state } = useContext(MapContext)
  const { map } = state
  const { kakao } = window
  const params = useParams()
  const [onRequest, , dataResult] = useAxios(getBdsDetail)
  const [data, setData] = useState(null)
  const [activeTab, setActiveTab] = useState(1)
  const bdsDetail = useRouteMatch({
    path: ['/bds/:id'],
  })

  const { tabs } = useBdsDetailTab()

  /**
   * 상세 호출
   */
  useEffect(() => {
    if (!params?.id) return
    if (Object.keys(map).length == 0 || !kakao) return
    onRequest({ ad_srl: params?.id })
  }, [params?.id, map])

  /**
   * 상세 호출 결과
   */
  useEffect(() => {
    if (!dataResult) return
    setData(dataResult?.result || null)
    if (dataResult?.result?.latitude && dataResult?.result?.longitude) {
      //목록 선택시 중심점으로 이동
      kakao.maps.load(() => {
        if (bdsDetail.isExact) {
          //레이어만큼 밀어야함(지도를 가린 부분)
          const mapProjection = map.getProjection()
          const latlng = new kakao.maps.LatLng(
            dataResult.result.latitude,
            dataResult.result.longitude,
          )
          const currOffset = mapProjection.pointFromCoords(latlng) // 지도 좌표에 해당하는 위치 좌표
          const resizableLayerWidth = 380
          const addPixelX = Number(resizableLayerWidth)

          // 카카오 pixel 포인트 생성
          const point = new kakao.maps.Point(
            currOffset.x - addPixelX / 2,
            currOffset.y,
          )

          //pixel을 좌표로 변환
          const realPoint = mapProjection.coordsFromPoint(point)

          //  moveCenter([realPoint.getLat(), realPoint.getLng()])
          map.setCenter(
            new kakao.maps.LatLng(
              realPoint.getLat(),
              realPoint.getLng(),
            ),
          )
        } else {
          map.setCenter(
            new kakao.maps.LatLng(
              dataResult.result.latitude,
              dataResult.result.longitude,
            ),
          )
        }
      })
    }
  }, [dataResult])

  const onClickTab = (val) => {
    setActiveTab(val)
  }

  return children({
    tabs,
    data,
    activeTab,
    onClickTab,
  })
}

export default BdsDetailContainer
