import NoteListHeaderSearch from 'components/jmapnotev2/NoteList/NoteListHeaderSearch'
import styled from 'styled-components'
import useIsMobile from 'lib/hooks/useIsMobile'
import React from 'react'
import { media } from 'lib/styles/theme'

const NoteAdListFetures = ({
  bindInput,
  resetInput,
  onSearch,
  input,
}) => {
  const isMobile = useIsMobile()
  return (
    <Wrapper>
      <NoteListHeaderSearch
        search={input}
        onSearch={() => {
          onSearch(null, true)
        }}
        noMaxWidth={isMobile}
        fullWidth={isMobile}
        bindSearch={bindInput}
        clearSearch={() => {
          resetInput()
          onSearch({
            isResetWithInput: true,
          })
        }}
        size="large"
        searchType="enter"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  padding: 0 40px;

  ${media.mediumS`
    padding: 8px 12px;
    margin-bottom: 0;
  `}
`

export default React.memo(NoteAdListFetures)
