import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 왼쪽 요금제 정보 블럭
 * 올인원 요금제 직원
 * 어떤 올인원 플랜을 사용중인지 표시하는 풍선
 */
const NotePaymentInfoBlockAllInOneMemberDesc = ({ ceoCompanyName }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  return (
    <AllInOneUse>
      <AllInOneUseTitle>
        {ceoCompanyName}의 올인원 이용 중
      </AllInOneUseTitle>
      <AllInOneUseDesc>
        올인원 플랜을 이용 중인 동안에는 {loginInfo?.user_name || ''}
        님이 이용하시던 베이직 플랜 요금이 발생하지 않습니다.
        등기부등본은 개인별로 구매하여 사용해야 합니다.
      </AllInOneUseDesc>
    </AllInOneUse>
  )
}

const AllInOneUse = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px 24px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.blue[200]};
  background-color: ${theme.colors.blue[50]};
`

const AllInOneUseTitle = styled.div`
  color: ${theme.colors.blue[400]};
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;

  &::before {
    content: '\f06a';
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
  }
`

const AllInOneUseDesc = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
`

export default NotePaymentInfoBlockAllInOneMemberDesc
