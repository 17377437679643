import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import { feeOptions } from '../../optionsFee'
import { useEffect, useState } from 'react'
import InputText from '../../elements/InputText'
import { assign, map, mapKeys, orderBy, union, unionBy } from 'lodash'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비 세부내역타입
 */
const NoteAdColumnFeeDetailType = ({ title }) => {
  const { onUpdate } = useAdUpdate()
  const {
    formState: { errors },
    watch,
  } = useFormContext()
  const list = feeOptions['정액관리비세부내역']

  const [convertedDatas, setConvertedDatas] = useState([])

  const fixedFeeDetails =
    watch('administrationCostInfo.fixedFeeDetails') || []
  const chargeCodeType = watch('administrationCostInfo.chargeCodeType')
  const customEtcInputContent = watch(
    'administrationCostInfo.customEtcInputContent',
  )

  useEffect(() => {
    if (chargeCodeType == '01' || chargeCodeType == '04') {
      if (fixedFeeDetails.length < Object.keys(list).length) {
        //값이 없을 때
        if (
          fixedFeeDetails?.filter(
            ({ detailCodeType }) => detailCodeType == '99',
          ).length > 0
        ) {
          //기타관리비 내용만 입력 했을때
          setConvertedDatas(
            setSpecificObjectValues(
              Object.entries(list).map(([key, value]) => {
                return {
                  detailCodeType: key,
                  amountCodeType: '',
                  amount: 0,
                  etcInputContent: '',
                }
              }),
              Object.assign(
                {},
                { amountCodeType: '', amount: 0 },
                fixedFeeDetails.find(
                  ({ detailCodeType }) => detailCodeType == '99',
                ),
              ),
            ),
          )
        } else {
          setConvertedDatas(
            Object.entries(list).map(([key, value]) => {
              return {
                detailCodeType: key,
                amountCodeType: '',
                amount: 0,
                etcInputContent: '',
              }
            }),
          )
        }
      } else {
        setConvertedDatas(fixedFeeDetails)
      }
    }
  }, [customEtcInputContent, chargeCodeType])

  /**
   * input 입력시
   */
  const onChangeInput = ({ codeType, value }) => {
    let newData = convertedDatas.find(
      ({ detailCodeType }) => detailCodeType == codeType,
    )
    newData.amount = value

    const result = setSpecificObjectValues(convertedDatas, newData)
    setConvertedDatas(result)
    onUpdate('administrationCostInfo.fixedFeeDetails', result)
  }

  /**
   * 라디오 change
   */
  const onChangeRadio = ({ codeType, value, amount }) => {
    let newData = convertedDatas.find(
      ({ detailCodeType }) => detailCodeType == codeType,
    )

    newData.amountCodeType = value

    if (value == '02' && codeType != '01') {
      newData.amount = !amount || amount == 0 ? null : amount
    } else {
      newData.amount = newData.amount ? newData.amount : 0
    }

    const result = setSpecificObjectValues(convertedDatas, newData)
    setConvertedDatas(result)
    onUpdate('administrationCostInfo.fixedFeeDetails', result)
  }

  /**
   * object array merge
   * @param {*} origin 원래 데이터
   * @param {*} newData 업데이트할 데이터
   * @returns
   */
  const setSpecificObjectValues = (origin, newData) => {
    return map(
      assign(
        mapKeys(origin, (v) => v.detailCodeType),
        mapKeys([newData], (v) => v.detailCodeType),
      ),
    )
  }

  return (
    <>
      {orderBy(convertedDatas, 'detailCodeType').map(
        ({ detailCodeType, amountCodeType, amount }, i) => {
          return (
            <ColumnSet key={i}>
              <ColumnTitle required>{list[detailCodeType]}</ColumnTitle>
              <ColumnVal>
                <div>
                  <InputText
                    key={`note-ad-administration-fixed-fee-amount-${detailCodeType}${i}`}
                    id={`note-ad-administration-fixed-fee-amount-${detailCodeType}`}
                    width={InputRemoveAppendWidth}
                    type="number"
                    disabled={
                      detailCodeType != '01' && amountCodeType == '02'
                    }
                    onBlur={(e) => {
                      onChangeInput({
                        codeType: detailCodeType,
                        value: e.target.value.replaceAll(',', ''),
                      })
                    }}
                    value={amount || ''}
                    fixAppendWidth
                    append={'원'}
                  />
                </div>
                <RadioButton
                  size="small"
                  label="정액"
                  value="01"
                  name={`adm_fee_amount_code_type${i}`}
                  id={`note-ad-administration-fixed-fee-amount-code-type-${detailCodeType}`}
                  onChange={(e) => {
                    onChangeRadio({
                      codeType: detailCodeType,
                      value: e.target.value,
                      amount: amount,
                    })
                  }}
                  checked={amountCodeType == '01'}
                />
                <RadioButton
                  size="small"
                  label="실비"
                  value="02"
                  name={`adm_fee_amount_code_type${i}`}
                  onChange={(e) => {
                    onChangeRadio({
                      codeType: detailCodeType,
                      value: e.target.value,
                    })
                  }}
                  checked={amountCodeType == '02'}
                />
                <ValidateWarn>
                  <div>
                    {
                      errors?.administrationCostInfo?.fixedFeeDetails?.[
                        detailCodeType
                      ]?.message
                    }
                  </div>
                  <div>
                    {
                      errors?.administrationCostInfo?.fixedFeeDetails
                        ?.amountCodeType?.[detailCodeType]?.message
                    }
                  </div>
                  <div>
                    {
                      errors?.administrationCostInfo?.fixedFeeDetails
                        ?.amount?.[detailCodeType]?.message
                    }
                  </div>
                </ValidateWarn>
              </ColumnVal>
            </ColumnSet>
          )
        },
      )}
    </>
  )
}

export default NoteAdColumnFeeDetailType
