import { AdButton } from './ButtonStyle'

/**
 * 광고 목록 버튼
 * 재광고 요청
 * @param adSrl
 * @param callbackFn
 */
const RecycleButton = ({ adSrl, callbackFn }) => {
  return (
    <AdButton type="submit" forForm expand size="xlarge" color="blue">
      다시 광고 등록
    </AdButton>
  )
}

export default RecycleButton
