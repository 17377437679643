import NoteFilterTab from './NoteFilterTab'

const NoteFilterWrap = ({ children, currentTab, setCurrentTab }) => {
  return (
    <>
      <NoteFilterTab
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {children}
    </>
  )
}

export default NoteFilterWrap
