import { useSelector } from 'react-redux'
import { AccountInput } from './NoteAccount'
import { useFormContext } from 'react-hook-form'

/**
 * 계정관리 폼 - 대표자 성명
 */
const NoteAccountCompanyCeo = ({ disabled }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { register } = useFormContext()

  return disabled ? (
    <span>{loginInfo?.company_ceo}</span>
  ) : (
    <AccountInput
      disabled={disabled}
      name="company_ceo"
      defaultValue={loginInfo?.company_ceo}
      design={'box'}
      {...register('company_ceo')}
    />
  )
}

export default NoteAccountCompanyCeo
