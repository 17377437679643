import React, { useCallback, useEffect, useState } from 'react'
import theme from 'lib/styles/theme'
import NoteListFilterNav from 'pages/jmapnotev2/NoteList/NoteListFilterNav'
import { useDispatch } from 'react-redux'
import { setNoteListIsShowMap } from 'modules/notev2'

const NoteListResizeContainer = ({ children }) => {
  const [width, setWidth] = useState(
    `calc(320px + ${localStorage.getItem('twoDepthWidth')}px)`,
  )
  const [buttonWidth, setButtonWidth] = useState(
    `calc(320px + ${localStorage.getItem('twoDepthWidth')}px)`,
  )
  const [isResizable, setIsResizealbe] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setIsResizealbe(true)
  }, [])

  /**
   * resize 가능하게 변경
   */
  const setResizable = useCallback(() => {
    setIsResizealbe(true)

    if (window.innerWidth >= 1700) {
      setWidth(
        `calc(320px + ${localStorage.getItem('twoDepthWidth')}px)`,
      )
      setButtonWidth(
        `calc(320px + ${localStorage.getItem('twoDepthWidth')}px)`,
      )
    } else {
      if (window.innerWidth >= theme.bp.mediumM) {
        setWidth(`${(window.innerWidth - 320) / 2}px`)
        setButtonWidth(`${(window.innerWidth - 320) / 2 + 320}px`)
      } else {
        setWidth(`${(window.innerWidth - 256) / 2}px`)
        setButtonWidth(`${(window.innerWidth - 256) / 2 + 256}px`)
      }
    }
  }, [])
  /**
   * resize 불가능하게 변경
   */
  const resetResizable = useCallback(() => {
    setIsResizealbe(false)
  }, [])

  const resetLayer = useCallback(() => {
    if (window.innerWidth >= theme.bp.mediumM) {
      setWidth(`${window.innerWidth - 256 - 64}px`)
      setButtonWidth(`${window.innerWidth - 256 - 64}px`)
    } else {
      setWidth(`${window.innerWidth - 256}px`)
      setButtonWidth(`${window.innerWidth - 256}px`)
    }
    setIsResizealbe(false)
  }, [])

  const CustomHandle = (props) => (
    <div
      style={{
        position: 'absolute',
        top: '200px',
        left: '-14px',
      }}
      className={'SomeCustomHandle'}
      {...props}
    />
  )

  /**
   * 전체 너비로 조정되면 resize false로 바꿈
   * @param {*} currWidth
   */
  const onResizeStop = useCallback((currWidth) => {
    const browserWidth = window.innerWidth
    const fullWidth =
      browserWidth - theme.base.navWidth - theme.base.note.filterWidth

    if (currWidth == fullWidth) {
      resetResizable()
      dispatch(setNoteListIsShowMap(false))
    }
  }, [])

  return children({
    setButtonWidth,
    width,
    setWidth,
    isResizable,
    setResizable,
    resetLayer,
    onResizeStop,
    FilterNav: ({ searchFnWithParam }) => (
      <NoteListFilterNav
        visible={isResizable}
        setIsResizealbe={setIsResizealbe}
        width={width}
        buttonWidth={buttonWidth}
        searchFnWithParam={searchFnWithParam}
      />
    ),
  })
}

export default React.memo(NoteListResizeContainer)
