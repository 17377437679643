import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 가격정보 - 분양금액
 */
const NoteAdColumnPresaleBasePrice = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const isaleType = watch('isale.isaleType')
  const tradeType = watch('trade.type')
  const isalePrice = watch('isale.isalePrice')

  return (
    <ColumnSet half>
      {/* 필수 - 분양구분:일반분양, 거래구분:매매 */}
      <ColumnTitle required={isaleType == 1 && tradeType == 'A1'}>
        분양금액
      </ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="number"
            defaultValue={isalePrice}
            {...register('isale.isalePrice')}
            onBlur={(e) => {
              onUpdate(
                'isale.isalePrice',
                e.target.value.replaceAll(',', ''),
              )
            }}
            append={'만원'}
          />
        </div>
        <ValidateWarn>
          {errors?.isale?.isalePrice?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnPresaleBasePrice
