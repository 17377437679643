import HeaderContainer from './HeaderContainer'

const Header = ({
  children,
  fixed,
  absolute,
  card,
  border,
  borderHidden,
  prepend,
  append,
  expand,
  inverse,
  topSpace,
  noTopHeader,
}) => {
  const props = {
    children,
    fixed,
    absolute,
    card,
    border,
    borderHidden,
    prepend,
    append,
    expand,
    inverse,
    topSpace,
    noTopHeader,
  }
  return <HeaderContainer {...props} />
}

export default Header
