import theme from 'lib/styles/theme'
import CustomModal from './CustomModal'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import NoteInviteRewardNoti from './NoteInviteRewardNoti'

const NoteInviteRewardUpgradeModal = ({ visible, onClose }) => {
  const history = useHistory()

  const onOk = () => {
    history.push('/note/v2/payment/plan')
  }

  return (
    <CustomModal
      noCloseButton
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
    >
      <Title>열람권 이벤트 🎉</Title>
      <Desc>
        유료 플랜 회원에 한해서 열람권 이벤트를 진행 중 이에요. 플랜을
        업그레이드하러 웹페이지로 이동할까요?
      </Desc>
      <ListWrapper>
        <ListTitle>안내</ListTitle>
        <NoteInviteRewardNoti gray="Y" />
      </ListWrapper>
    </CustomModal>
  )
}

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 24px;
`

const Desc = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
`

const ListTitle = styled.div`
  font-weight: 600;
  margin-bottom: 14px;
`

const ListWrapper = styled.div`
  background-color: ${theme.colors.gray[100]};
  color: ${theme.colors.gray[600]};
  border-radius: 12px;
  padding: 20px 16px;
`

export default NoteInviteRewardUpgradeModal
