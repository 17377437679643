import FeatureFirst from 'assets/images/features/home-feature-1-bg.png'
import FeatureSecond from 'assets/images/features/home-feature-2-bg.png'
import FeatureDongho from 'assets/images/features/dongho_banner.png'
import styled from 'styled-components'
import { Pagination } from 'swiper'
import HomeFeatureItem from './HomeFeatureItem'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'

import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/pagination/pagination.scss' // Pagination module
import HomeDonghoBannerModal from './HomeDonghoBannerModal'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import moment from 'moment'

const HomeFeatureBasicSlide = () => {
  const [donghoModalVisible, setDonghoModalVisible] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies([
    'isVisibleDonghoWelcomeModal',
  ])

  useEffect(() => {
    if (cookies?.isVisibleDonghoWelcomeModal == 'N') return

    setDonghoModalVisible(true)
  }, [])

  /**
   * 동호관리 배너 클릭
   */
  const onClickDongho = () => {
    setDonghoModalVisible(true)
  }

  const onCloseDonghoModal = () => {
    const expireDate = new Date(moment().add(30, 'days'))
    setCookie('isVisibleDonghoWelcomeModal', 'N', {
      expires: expireDate,
    })
    setDonghoModalVisible(false)
  }

  return (
    <>
      <StyledSwiper pagination={true} modules={[Pagination]}>
        <SwiperSlide>
          <HomeFeatureItem
            onClick={onClickDongho}
            background={FeatureDongho}
          ></HomeFeatureItem>
        </SwiperSlide>
        <SwiperSlide>
          <HomeFeatureItem
            color="#618DFF"
            title="간편한 실거래가 확인"
            text="관심 부동산의 실거래 기록과 주변 유사거래 정보를 제공합니다."
            background={FeatureFirst}
          ></HomeFeatureItem>
        </SwiperSlide>
        <SwiperSlide>
          <HomeFeatureItem
            color="#8B6FFF"
            title="빅데이터 추정가"
            text="사고 싶은 땅, 팔고 싶은 건물의 추정가치를 지금 받아보세요."
            background={FeatureSecond}
          ></HomeFeatureItem>
        </SwiperSlide>
      </StyledSwiper>
      <HomeDonghoBannerModal
        visible={donghoModalVisible}
        onCancel={onCloseDonghoModal}
      />
    </>
  )
}

const StyledSwiper = styled(Swiper)`
  margin-bottom: 24px;
  border-radius: 12px;
  overflow: hidden;

  & > div > div {
    border-radius: 12px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: auto;
  }
`

const StyledSwiperSlide = styled(SwiperSlide)`
  cursor: pointer;
`

export default HomeFeatureBasicSlide
