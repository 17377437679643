import ContactContainer from 'containers/note/contact/ContactContainer'
import NoteHeader from 'components/jmapnote/noteBase/NoteHeader'

import ContactHeader from 'components/jmapnote/contact/ContactHeader'
import NoteLayout from 'components/jmapnote/noteBase/NoteLayout'
import useIsMobile from 'lib/hooks/useIsMobile'
import SEOHead from 'components/common/SEOHead'

const ContactPage = () => {
  const isMobile = useIsMobile()
  return (
    <NoteLayout white>
      <SEOHead />
      {isMobile && <ContactHeader />}
      <ContactContainer />
    </NoteLayout>
  )
}

export default ContactPage
