import { Tooltip } from 'antd'
import BaseNavItem from './BaseNavItem'
import NoteLinkContainer from 'containers/note/NoteLinkContainer'
import { ReactComponent as Icon } from 'assets/icon/nav/note.svg'

const BaseNavNote = () => {
  return (
    <>
      <NoteLinkContainer>
        {({ popup, active, onClick }) => (
          <>
            <Tooltip title="매물노트">
              <BaseNavItem
                active={active}
                onClick={onClick}
                as="div"
                icon={<Icon />}
                text="매물노트"
              />
            </Tooltip>
            {popup}
          </>
        )}
      </NoteLinkContainer>
    </>
  )
}

export default BaseNavNote
