import { ReactComponent as StationIcon } from 'assets/icon/amenities/station.svg'
import { ReactComponent as IcIcon } from 'assets/icon/amenities/ic.svg'
import { ReactComponent as TrainIcon } from 'assets/icon/amenities/train.svg'
import { ReactComponent as BusIcon } from 'assets/icon/amenities/bus.svg'
import { ReactComponent as AirPlaneIcon } from 'assets/icon/amenities/airplane.svg'
import { ReactComponent as HospitalIcon } from 'assets/icon/amenities/hospital.svg'
import { ReactComponent as PharmacyIcon } from 'assets/icon/amenities/pharmacy.svg'
import { ReactComponent as CvsIcon } from 'assets/icon/amenities/cvs.svg'
import { ReactComponent as CuIcon } from 'assets/icon/amenities/cu.svg'
import { ReactComponent as Gs25Icon } from 'assets/icon/amenities/gs25.svg'
import { ReactComponent as SevenElevenIcon } from 'assets/icon/amenities/7eleven.svg'
import { ReactComponent as EmartIcon } from 'assets/icon/amenities/emart24.svg'
import { ReactComponent as MiniStopIcon } from 'assets/icon/amenities/ministop.svg'
import { ReactComponent as ParkIcon } from 'assets/icon/amenities/park.svg'
import { ReactComponent as ShoppingIcon } from 'assets/icon/amenities/shopping.svg'
import { ReactComponent as MarketIcon } from 'assets/icon/amenities/market.svg'
import { ReactComponent as CafeIcon } from 'assets/icon/amenities/cafe.svg'
import { ReactComponent as RestaurantIcon } from 'assets/icon/amenities/restaurant.svg'
import { ReactComponent as CctvIcon } from 'assets/icon/amenities/cctv.svg'
import { ReactComponent as FireIcon } from 'assets/icon/amenities/fire.svg'
import { ReactComponent as SafeIcon } from 'assets/icon/amenities/safe.svg'

import StationIconSrc from 'assets/icon/amenities/station.svg'
import IcIconSrc from 'assets/icon/amenities/ic.svg'
import TrainIconSrc from 'assets/icon/amenities/train.svg'
import BusIconSrc from 'assets/icon/amenities/bus.svg'
import AirPlaneIconSrc from 'assets/icon/amenities/airplane.svg'
import HospitalIconSrc from 'assets/icon/amenities/hospital.svg'
import PharmacyIconSrc from 'assets/icon/amenities/pharmacy.svg'
import CvsIconSrc from 'assets/icon/amenities/cvs.svg'
import CuIconSrc from 'assets/icon/amenities/cu.svg'
import Gs25IconSrc from 'assets/icon/amenities/gs25.svg'
import SevenElevenIconSrc from 'assets/icon/amenities/7eleven.svg'
import EmartIconSrc from 'assets/icon/amenities/emart24.svg'
import MiniStopIconSrc from 'assets/icon/amenities/ministop.svg'
import ParkIconSrc from 'assets/icon/amenities/park.svg'
import ShoppingIconSrc from 'assets/icon/amenities/shopping.svg'
import MarketIconSrc from 'assets/icon/amenities/market.svg'
import CafeIconSrc from 'assets/icon/amenities/cafe.svg'
import RestaurantIconSrc from 'assets/icon/amenities/restaurant.svg'
import CctvIconSrc from 'assets/icon/amenities/cctv.svg'
import FireIconSrc from 'assets/icon/amenities/fire.svg'
import SafeIconSrc from 'assets/icon/amenities/safe.svg'

export const Icons = (value) => {
  const result = {
    transportation: {
      지하철: <StationIcon />,
      IC: <IcIcon />,
      KTX: <TrainIcon />,
      SRT: <TrainIcon />,
      버스: <BusIcon />,
      공항: <AirPlaneIcon />,
    },
    hospital: {
      의원: <HospitalIcon />,
      병원: <HospitalIcon />,
      종합병원: <HospitalIcon />,
      약국: <PharmacyIcon />,
    },
    shop: {
      편의점: <CvsIcon />,
      cu: <CuIcon />,
      gs25: <Gs25Icon />,
      세븐일레븐: <SevenElevenIcon />,
      emart24: <EmartIcon />,
      ministop: <MiniStopIcon />,
      공원: <ParkIcon />,
      대형마트: <ShoppingIcon />,
      시장: <MarketIcon />,
      대규모점포: <ShoppingIcon />,
    },
    restaurant: {
      카페: <CafeIcon />,
      default: <RestaurantIcon />,
    },
    safety: {
      cctv: <CctvIcon />,
      소방: <FireIcon />,
      경찰: <SafeIcon />,
    },
  }

  return result?.[[value]] || null
}

export const IconsSrc = (value) => {
  const result = {
    transportation: {
      지하철: StationIconSrc,
      IC: IcIconSrc,
      KTX: TrainIconSrc,
      SRT: TrainIconSrc,
      버스: BusIconSrc,
      공항: AirPlaneIconSrc,
    },
    hospital: {
      의원: HospitalIconSrc,
      병원: HospitalIconSrc,
      종합병원: HospitalIconSrc,
      약국: PharmacyIconSrc,
    },
    shop: {
      편의점: CvsIconSrc,
      cu: CuIconSrc,
      gs25: Gs25IconSrc,
      세븐일레븐: SevenElevenIconSrc,
      emart24: EmartIconSrc,
      ministop: MiniStopIconSrc,
      공원: ParkIconSrc,
      대형마트: ShoppingIconSrc,
      시장: MarketIconSrc,
      대규모점포: ShoppingIconSrc,
    },
    restaurant: {
      카페: CafeIconSrc,
      default: RestaurantIconSrc,
    },
    safety: {
      cctv: CctvIconSrc,
      소방: FireIconSrc,
      경찰: SafeIconSrc,
    },
  }

  return result?.[[value]] || null
}
