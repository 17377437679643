import {
  ActiveCellStyle,
  StyledSelectView,
} from 'components/jmapnotev2/style/NoteGridStyle'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import NoteContractTypeSelector from './NoteContractTypeSelector'
import useModifyAd from 'lib/hooks/notev2/useModifyAd'
import useRoveFocus from 'components/jmapnotev2/elements/useRoveFocusPropertySelector'

/**
 * 광고관리 그리드
 * 구분
 */
const CustomContractTypeCell = ({ node, data }) => {
  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    isReversePosition,
  } = useCustomSingleCell(node?.data?.status, node?.rowIndex, 300)

  const [onModify] = useModifyAd()

  const selectRef = useRef(null)
  const viewRef = useRef(null)

  useEffect(() => {
    //수정모드가 변경되면 방향키 제어를 위해 그리드 데이터의 state도 업데이트 해준다
    updateGridDatas({
      currentRowNode: node,
      newData: {
        ...node.data,
        state: {
          ...data.state,
          isEditingPropertyType: isEditable,
        },
      },
    })

    //수정 모드가 되면 자동 포커스
    if (isEditable) {
      selectRef.current?.focus()
    }
  }, [isEditable])

  /**
   * selectbox change
   */
  const onChange = useCallback(
    (val) => {
      onModify({
        updateData: { contract_type: val },
        data: node.data,
        currentRowNode: node,
      })
      //수정모드 해제
      setIsEditable(false)
      viewRef.current.focus()
    },
    [node],
  )

  const onClose = () => {
    setIsEditable(false)
    viewRef.current?.focus()
  }

  if (data.ad_srl == '신규') return <></>
  return (
    <Wrapper tabIndex={-1}>
      <StyledSelectView
        ref={viewRef}
        tabIndex={0}
        onDoubleClick={onDoubleClickSingleCell}
        onKeyDown={onKeyDownSingleCell}
        onClick={() => setIsEditable(false)}
      >
        {data.contract_type}
      </StyledSelectView>
      {isEditable && (
        <SelectorWrapper>
          {data.contract_type}

          <SelectorBlockWrapper reverse={isReversePosition ? 'Y' : 'N'}>
            <NoteContractTypeSelector
              visible={true}
              setIsVisible={setIsEditable}
              value={data.contract_type}
              onChange={onChange}
              onClose={onClose}
            />
          </SelectorBlockWrapper>
        </SelectorWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const SelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SelectorBlockWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 2px;

  ${({ reverse }) =>
    reverse == 'Y' &&
    `
    top: -320px;
  `}
`

export default React.memo(CustomContractTypeCell)
