import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledFormDivider = styled.div`
  border-bottom: 0.625rem solid ${theme.colors.gray[100]};
  /* margin-top: 1.875rem; */
  margin-bottom: 1.875rem;

  ${({ thin }) =>
    thin &&
    `
    border-bottom-width: 1px;
  `}

  ${({ noBorder }) =>
    noBorder &&
    `
    border-bottom: none;
    margin-top: 0;
  `}

  ${({ small }) =>
    small &&
    `
    margin-bottom: 1rem;
  `}
`

const FormDivider = ({ thin, noBorder, small }) => {
  return (
    <StyledFormDivider thin={thin} noBorder={noBorder} small={small} />
  )
}

export default FormDivider
