import styled from 'styled-components'
import NoteAdColumnPropertyType from '../columns/basic/NoteAdColumnPropertyType'
import { Table, Title } from './NoteAdFormStyle'
import theme from 'lib/styles/theme'

/**
 * 광고관리 매물등록 테이블
 * property_type 없을 때
 */
const NoteAdFormOnlyPropertyType = () => {
  return (
    <Wrapper>
      <Title>1. 기본정보</Title>
      <Table lastcount={2}>
        <NoteAdColumnPropertyType />
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const Desc = styled.div`
  display: flex;
  margin-left: 4px;
  margin-bottom: 6px;
  color: ${theme.colors.tint.danger};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;

  &::before {
    content: '·';
    font-size: 30px;
    margin-right: 7px;
  }
`

export default NoteAdFormOnlyPropertyType
