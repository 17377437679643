import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import MapContext from 'contexts/map'
import Map from './Map'
import MapPolygon from './MapPolygon'
import MapRPMarkerContainer from './MapRPMarkerContainer'
import MapAuctionMarkerContainer from './MapAuctionMarkerContainer'
import MapDebug from './MapDebug'
import MapControl from './MapControl/MapControl'
import MapClickContainer from './MapClickContainer'
import BaseContext from 'contexts/baseContext'
import MapLocPositionMarker from './MapLocPositionMarker'
import 'components/map/MapLayer/MapLayer.scss'
import MapFavoriteMarkerContainer from './MapFavoriteMarkerContainer'
import { useLocation } from 'react-router-dom'
import { setIsMapError } from 'modules/map'
import { baseCenterPoint } from 'Constants'
import MapSpecificFavoriteMarkerContainer from './MapSpecificFavoriteMarkerContainer'

/**
 * 카카오 지도 설정 및 지도 폴리곤 등을 셋팅
 */
const MapContainer = ({ hide = false }) => {
  const { kakao } = window
  const { state, actions } = useContext(MapContext)
  const { map } = state
  const [init, setInit] = useState(false)
  const dispatch = useDispatch()

  const loginInfo = useSelector((state) => state.auth.loginInfo)

  // 매물 폴리곤
  const [notePolygon, setNotePolygon] = useState(null)
  const location = useLocation()

  const [cookies, setCookie, removeCookie] = useCookies([
    'clickPosition',
  ])

  const [locPosition, setLocPosition] = useState(null)
  const {
    state: { isExternal, isFold },
  } = useContext(BaseContext)

  // 현재 위치 관련
  let watchID = ''

  // 지도 Initialize
  useEffect(() => {
    if (
      window.navigator.userAgent.indexOf('Yeti') !== -1 ||
      window.location.hostname.indexOf('nomap') !== -1
    ) {
      return
    }
    // console.log('MapContainer Init');

    const kakaoMapLoaded = () => {
      // console.log('kakaoMapLoaded');

      let center = new kakao.maps.LatLng(
        baseCenterPoint[0],
        baseCenterPoint[1],
      )
      const level = 2
      kakaoMapInit({ center, level })
    }

    const kakaoMapInit = ({ center, level }) => {
      // console.log('kakaoMapInit');
      let container = document.getElementById('map')
      if (!container) return
      let options = {
        center,
        level: level,
      }

      // 지도 선언
      let map = new window.kakao.maps.Map(container, options)
      actions.setMap(map)

      // @METHOD: 최초 mount 시 viewState 초기화
      const SetDefaultViewState = () => {
        const level = map.getLevel()
        // 지도 영역정보를 얻어옵니다
        const bounds = map.getBounds()
        // 영역정보의 남서쪽 정보를 얻어옵니다
        const swLatlng = bounds.getSouthWest()
        // 영역정보의 북동쪽 정보를 얻어옵니다
        const neLatlng = bounds.getNorthEast()
        actions.setViewState({
          level: level,
          swLatlng: swLatlng,
          neLatlng: neLatlng,
        })
      }
      SetDefaultViewState()

      /**
       * @INFO: 지도에 대한 이벤트 리스닝과 viewState의
       * 갱신이 여러곳에서 이루어지고 있는 것이 확인됨.
       * MapContainer에서 viewState 갱신을 통합하여 관리하고
       * dragend, zoom_change, bound_change 등의 리스너
       * 중복을 해소하기 위해 '중점 좌표 또는 확대 수준 변경 시'
       * 발생되는 idle 이벤트를 리스닝하도록 함.
       */
      kakao.maps.event.addListener(map, 'idle', SetDefaultViewState)

      // 척도 및 copyright의 위치를 오른쪽 아래로 설정한다.
      map.setCopyrightPosition(kakao.maps.CopyrightPosition.BOTTOMRIGHT)

      // centerpoint 현재 위치 자동 이동 여부 판단
      if (isMoveCurrentLocation(location.pathname)) {
        getMyPosition(map)
      }

      setInit(true)

      // 현재 위치 추적하기
      watchID = navigator.geolocation.watchPosition((position) => {
        const center = new kakao.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude,
        )
        setLocPosition(position)
      })
    }

    if (!window.kakao || !window.kakao.maps) {
      dispatch(setIsMapError(true))
    } else {
      // 카카오 지도 설정
      window.kakao?.maps?.load(kakaoMapLoaded)

      removeCookie('clickPosition', { path: '/map' })
    }

    return () => {
      navigator.geolocation.clearWatch(watchID)
    }
  }, [])

  const isMoveCurrentLocation = (pathname) => {
    //summary address_id 포맷 - 숫자만 추출
    const regexNum = /[^0-9]/g
    const urlParamNumber = pathname.replace(regexNum, '')

    //주택단지 포맷 - 10자리숫자_영어1자리
    const regexHc = /^(?:\d{10})([_\/\.])\w{1}$/
    const urlParam = pathname.replace('/housingComplex/', '')

    if (
      typeof Number(urlParamNumber) != 'number' ||
      (!pathname.includes('share') &&
        urlParamNumber.length !== 5 &&
        urlParamNumber.length !== 19 &&
        !regexHc.test(urlParam))
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (isFold) {
      // 지도를 표시하는 div 크기를 변경한 이후 지도가 정상적으로 표출되지 않을 수도 있습니다
      // 크기를 변경한 이후에는 반드시  map.relayout 함수를 호출해야 합니다
      // window의 resize 이벤트에 의한 크기변경은 map.relayout 함수가 자동으로 호출됩니다
      map.relayout()
    }
  }, [isFold])

  const getMyPosition = useCallback(
    (map) => {
      // 쿠키값을 우선 적용
      if (cookies.clickPosition?.lat && cookies.clickPosition?.long) {
        const center = new kakao.maps.LatLng(
          cookies.clickPosition.lat,
          cookies.clickPosition.long,
        )
        map.setCenter(center)
        return
      }

      // HTML5의 geolocation으로 사용할 수 있는지 확인합니다
      if ('geolocation' in navigator) {
        // GeoLocation을 이용해서 접속 위치를 얻어옵니다
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude // 위도
            const lon = position.coords.longitude // 경도

            //대한민국, 해외 일 때 분리
            if (
              lat >= 33.1137 &&
              lat <= 39.3394 &&
              lon >= 124.6149 &&
              lon <= 131.8726
            ) {
              const center = new kakao.maps.LatLng(lat, lon) // 마커가 표시될 위치를 geolocation으로 얻어온 좌표로 생성합니다

              map.setCenter(center)

              let message = '<div style="padding:5px;"></div>' // 인포윈도우에 표시될 내용입니다
              // 마커와 인포윈도우를 표시합니다
              // displayMarker(center, message, map)

              // 현재위치 마커 세팅
              setLocPosition(position)
              // 지도 중심점 이동
              map.setCenter(new kakao.maps.LatLng(lat, lon))
            } else {
              //해외일 때는 centerpoint 만 이동시켜준다.
              const center = new kakao.maps.LatLng(
                baseCenterPoint[0],
                baseCenterPoint[1],
              )

              map.setCenter(center)
            }
          },
          (error) => {
            console.log(error)
          },
          { timeout: 3000 },
        )
      } else {
        console.log('GPS를 지원하지 않습니다')
      }
    },
    [cookies],
  )

  const resetNotePolygon = useCallback(() => {
    setNotePolygon(null)
  }, [])

  return (
    <Map
      visible
      shift={isExternal?.params?.id}
      append={
        <>
          {/* 지도가 정상적으로 선언되면 자식 요소를 렌더링합니다. */}
          {init && (
            <>
              <MapControl />
              <MapClickContainer
                map={map}
                cookies={cookies}
                setCookie={setCookie}
              />
              {process.env.NODE_ENV === 'development' && <MapDebug />}
            </>
          )}

          {/* <MapHelpContainer /> */}
        </>
      }
    >
      {init && (
        <>
          {state.layerState?.favorite &&
            loginInfo?.member_type === 'realestate' && (
              <MapFavoriteMarkerContainer
                hide={hide}
                polygon={notePolygon}
                setPolygon={setNotePolygon}
                resetPolygon={resetNotePolygon}
              />
            )}
          {state.layerState?.favorite &&
            loginInfo?.member_type === 'realestate' && (
              <MapSpecificFavoriteMarkerContainer
                polygon={notePolygon}
                setPolygon={setNotePolygon}
                resetPolygon={resetNotePolygon}
              />
            )}
          {state.layerState?.realPriceActive && (
            <MapRPMarkerContainer
              hide={hide}
              resetNotePolygon={resetNotePolygon}
            />
          )}
          {state.layerState?.realPriceActive && (
            <MapAuctionMarkerContainer hide={hide} />
          )}
          <MapLocPositionMarker
            coords={locPosition?.coords || null}
            map={map}
            kakao={kakao}
          />
          <MapPolygon />
        </>
      )}
    </Map>
  )
}

export default React.memo(MapContainer)
