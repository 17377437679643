import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import Checkbox from '../../elements/Checkbox'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 개인정보 수집 및 이용 동의
 */
const NoteAdColumnVerificationAgree = ({ isDisabled }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  const personalAgree = watch('verification.oagree')

  return (
    <ColumnSet>
      <ColumnTitle required>개인정보 수집 및 이용 동의</ColumnTitle>
      <ColumnVal gap={12}>
        <Checkbox
          id="verification.oagree"
          color="blue"
          value="Y"
          checked={personalAgree || false}
          {...register('verification.oagree')}
          onChange={(e) => {
            onUpdate('verification.oagree', e)
          }}
          disabled={isDisabled}
        >
          개인 정보 수집 이용 및 제3자 제공에 소유주가 모두
          동의하였습니다.
        </Checkbox>
        <ValidateWarn>
          {errors?.verification?.oagree?.message}
        </ValidateWarn>
        <Block>
          <BlockTitle>
            개인정보 수집 및 이용에 대한 동의(필수)
          </BlockTitle>
          <BlockTitleSub>
            개인정보 수집 및 이용에 대한 동의(필수)소유자명, 소유자와의
            관계, 소재지, 거래유형. 가격, 소유자 휴대전화번호는 부동산
            매물 확인 및 홍보, 허위매물 등록 방지, 매물 등록에
            따른안내문자 발송을 위해 수집되며, 개인정보 보유 및 이용기간
            달성 후 지체없이 파기합니다.
          </BlockTitleSub>
          <List>
            <li>
              주택 개인정보 제공에 대한 동의 (필수)
              <ListItemDetail>제공 받는 자 : (주)주택</ListItemDetail>
              <ListItemDetail>
                제공 목적 : (주)주택 사이트에 매물정보 홍보, 허위매물
                등록 방지
              </ListItemDetail>
              <ListItemDetail>
                제공 항목 : 소유자명, 소유자와의 관계, 소재지, 거래유형,
                가격, 소유자 휴대전화번호
              </ListItemDetail>
              <ListItemDetail>
                제공받는 자의 개인정보 보유 및 이용 기간 : 30일 까지
              </ListItemDetail>
            </li>
            <li>
              주택-텐컴즈 개인정보 제공에 대한 동의 (필수)
              <ListItemDetail>제공 받는 자 : (주)텐컴즈</ListItemDetail>
              <ListItemDetail>
                제공 목적 : 네이버파이낸셜 광고 등록 대행, (주)텐컴즈
                사이트에 매물정보 홍보, 허위매물 등록 방지
              </ListItemDetail>
              <ListItemDetail>
                제공 항목 : 소유자명, 소유자와의 관계, 소재지, 거래유형,
                가격, 소유자 휴대전화번호
              </ListItemDetail>
              <ListItemDetail>
                제공받는 자의 개인정보 보유 및 이용 기간: 제공 후 30일간
                보관
              </ListItemDetail>
            </li>
            <li>
              텐컴즈-네이버파이낸셜 개인정보 제공에 대한 동의 (필수)
              <ListItemDetail>
                제공 받는 자 : 네이버파이낸셜
              </ListItemDetail>
              <ListItemDetail>
                제공 목적 : 네이버 부동산에 매물정보 홍보, 네이버
                회원정보에 저장된 개인정보(이름, 휴대전화번호)와의 일치
                여부 확인을 통한 허위매물 등록 방지, 부동산 매물 등록 후
                안내 문자 발송
              </ListItemDetail>
              <ListItemDetail>
                제공 항목 : 소유자명, 소유자와의 관계, 소재지, 거래유형,
                가격, 소유자 휴대전화번호
              </ListItemDetail>
              <ListItemDetail>
                제공받는 자의 개인정보 보유 및 이용 기간: 제공 후 60일간
                보관
              </ListItemDetail>
            </li>
            <li>
              네이버파이낸셜-한국부동산원 개인정보 제공에 대한
              동의(필수)
              <ListItemDetail>
                제공받는 자: 한국부동산원 - 제공 목적: 허위매물 등록
                방지
                <br />
                <i className="fal fa-info-circle"></i> 한국부동산원법에
                따라 시장동향과 관련 통계의 조사·관리를 위한 목적으로도
                활용될 수 있음
              </ListItemDetail>
              <ListItemDetail>
                제공 항목: 소재지, 거래유형, 가격
              </ListItemDetail>
              <ListItemDetail>
                제공받는 자의 개인정보 보유 및 이용 기간: 제공 후 1년간
                보관
              </ListItemDetail>
            </li>
            <li>
              네이버파이낸셜-한국인터넷자율정책기구 개인정보 제공에 대한
              동의(필수)
              <ListItemDetail>
                제공받는 자: 한국인터넷자율정책기구
              </ListItemDetail>
              <ListItemDetail>
                제공 목적: 허위매물 여부 확인
              </ListItemDetail>
              <ListItemDetail>
                제공 항목: 소재지, 거래유형, 가격
              </ListItemDetail>
              <ListItemDetail>
                제공받는 자의 개인정보 보유 및 이용 기간: 제공 후 1년간
                보관
              </ListItemDetail>
            </li>
            <li>
              정보주체는 본 개인정보 수집 및 이용과 개인정보 제공에 대한
              동의를 거부할 수 있으며, 거부하실 경우 매물 확인 및 홍보
              업무가 제한됩니다.
            </li>
          </List>
        </Block>
      </ColumnVal>
    </ColumnSet>
  )
}

const Block = styled.div`
  height: 300px;
  overflow-y: auto;
  padding: 10px 14px;
  width: 100%;
  background-color: ${theme.colors.gray[100]};
`

export const BlockTitle = styled.div`
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 16px;
`

export const BlockTitleSub = styled.div`
  margin-bottom: 14px;
  font-size: 14px;
  color: ${theme.colors.gray[600]};
  font-weight: 500;
  line-height: 150%;
`

export const List = styled.ul`
  margin-left: 20px;

  li {
    font-weight: 600;
    margin-bottom: 14px;

    &::before {
      content: '\f00c';
      ${({ multiple }) => multiple && `content: '\f00c'`};
      font-family: 'Font Awesome 5 Pro';
      margin-right: 4px;
      font-size: 12px;
      color: ${theme.colors.blue[400]};
    }
  }
`

export const ListItemDetail = styled.div`
  margin-left: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${theme.colors.gray[600]};

  i {
    font-size: 12px;
    color: ${theme.colors.blue[600]};
    margin-left: 10px;
  }

  &::before {
    content: '·';
    margin-right: 6px;
  }
`

export default NoteAdColumnVerificationAgree
