import { createContext, useState } from 'react'

const NoteContext = createContext()

export const NoteProvider = ({ children }) => {
  // const [selectedChannel, setSelectedChannel] = useState(null);
  // const [channels, setChannels] = useState([])
  const [folders, setFolders] = useState([])
  const [addresses, setAddresses] = useState([])
  const [notes, setNotes] = useState([])
  const [noteSearchInput, setNoteSearchInput] = useState('')
  const [noteSearchResults, setNoteSearchResults] = useState([])
  const [noteForm, setNoteForm] = useState({})
  const [defaultValue, setDefaultValue] = useState(null)
  const [contacts, setContacts] = useState([])
  const [noteFormChannelSrl, setNoteFormChannelSrl] = useState('')
  const [noteFormFolderSrl, setNoteFormFolderSrl] = useState('')
  const [noteFormAddressSrl, setNoteFormAddressSrl] = useState('')

  const value = {
    state: {
      // selectedChannel,
      // channels,
      folders,
      addresses,
      notes,
      noteSearchInput,
      noteSearchResults,
      noteForm,
      defaultValue,
      contacts,

      noteFormChannelSrl,
      noteFormFolderSrl,
      noteFormAddressSrl,
    },
    actions: {
      // setSelectedChannel,
      // setChannels,
      setFolders,
      setAddresses,
      setNotes,
      setNoteSearchInput,
      setNoteSearchResults,
      setNoteForm,
      setDefaultValue,
      setContacts,

      setNoteFormChannelSrl,
      setNoteFormFolderSrl,
      setNoteFormAddressSrl,
    },
  }

  return (
    <NoteContext.Provider value={value}>
      {children}
    </NoteContext.Provider>
  )
}

export default NoteContext
