export default function useRoadName(
  params = {
    road_name: '',
    sigungu: '',
    ilbangu: '',
    eupmyeondong: '',
    road_num1: '',
    road_num2: '',
  },
) {
  if (!params) return ''

  const {
    road_name,
    sigungu,
    ilbangu,
    eupmyeondong,
    road_num1,
    road_num2,
  } = params
  if (!road_name || !road_num1) return ''

  return `
    ${sigungu} ${ilbangu} ${
    eupmyeondong.slice(-1) === '읍' || eupmyeondong.slice(-1) === '면'
      ? eupmyeondong
      : ''
  } ${road_name} ${road_num1}${road_num2 ? `-${road_num2}` : ''}
  `
}
