import { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Modal } from 'components/design'
import { Tag } from 'components/design'
import SummaryLobSelectItem from './SummaryLobSelectItem'

const SummaryLobSelect = ({
  buildingList,
  selectedBuilding,
  selectedBuildingId,
  setSelectedBuildingId,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const onClickItem = (item) => {
    setSelectedBuildingId(item.id)
    setModalVisible(false)
  }

  const getBuildingName = (item) => {
    if (item?.is_sub_building === 0) {
      return (
        <>
          {item?.building_name
            ? item?.building_name
            : item?.purpose_code_name}{' '}
          <span className="strong">
            {item?.dong_name ? item?.dong_name : ''}
          </span>
        </>
      )
    } else {
      return (
        <>
          {item?.other_purpose
            ? item?.other_purpose
            : item?.purpose_code_name}
        </>
      )
    }
  }

  return (
    <>
      <StyledSelectWrap>
        <StyledSelect onClick={() => setModalVisible(true)}>
          <TagWrap>
            {selectedBuilding?.isTotal ? (
              <Tag color="black">총괄</Tag>
            ) : (
              <Tag
                color={
                  selectedBuilding?.is_sub_building === 0
                    ? 'black'
                    : 'default'
                }
              >
                {selectedBuilding?.is_sub_building === 0 ? '주' : '부'}
                {buildingList
                  .filter((e) => !e.isTotal)
                  .sort((a, b) => a.is_sub_building - b.is_sub_building)
                  .findIndex(
                    (e) => e.id === selectedBuildingId && !e.isTotal,
                  ) + 1}
              </Tag>
            )}
          </TagWrap>

          <SelectText>{getBuildingName(selectedBuilding)}</SelectText>

          <SelectIcon
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </SelectIcon>
        </StyledSelect>
      </StyledSelectWrap>

      <Modal
        visible={modalVisible}
        title="건물 선택"
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <StyledList>
          {/* 총괄 */}
          {buildingList
            .filter((e) => e.isTotal)
            .map((item, index) => {
              return (
                <SummaryLobSelectItem
                  item={item}
                  key={index}
                  count={index}
                  active={selectedBuilding.id === item.id}
                  onClick={() => onClickItem(item)}
                  getBuildingName={getBuildingName}
                />
              )
            })}

          {/* 주 */}
          {buildingList
            .filter((e) => e.is_sub_building === 0 && !e.isTotal)
            .map((item, index) => {
              return (
                <SummaryLobSelectItem
                  item={item}
                  key={index}
                  count={index}
                  active={selectedBuilding.id === item.id}
                  onClick={() => onClickItem(item)}
                  getBuildingName={getBuildingName}
                />
              )
            })}

          {/* 부 */}
          {buildingList
            .filter((e) => e.is_sub_building !== 0 && !e.isTotal)
            .map((item, index) => {
              return (
                <SummaryLobSelectItem
                  item={item}
                  key={index}
                  count={
                    index +
                    buildingList.filter((e) => e.is_sub_building === 0)
                      .length
                  }
                  active={selectedBuilding.id === item.id}
                  onClick={() => onClickItem(item)}
                  getBuildingName={getBuildingName}
                />
              )
            })}
        </StyledList>
      </Modal>
    </>
  )
}

const StyledSelectWrap = styled.div`
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 2; */
  width: 100%;
  /* width: ${theme.base.externalWidth - 1}px; */
`
const StyledSelect = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-top: -0.875rem;

  border-radius: ${theme.borderRadius[2]};
  border: 1px solid ${theme.colors.gray[200]};

  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 700;

  .app-tag {
    margin: -6px 0;
  }

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
`
const TagWrap = styled.div`
  margin-right: 0.5rem;
`
const SelectText = styled.div`
  flex: 1;
`

const SelectIcon = styled.svg`
  width: 1.25rem;
  height: 1.25rem;
`

const StyledList = styled.div`
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* margin: 0 -1.25rem; */
`

export default SummaryLobSelect
