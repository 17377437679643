import Axios from 'axios'

export const getBuildingArea = async (payload, options) =>
  await Axios.get(
    '/map/api/getBuildingArea',
    {
      params: payload,
    },
    options,
  )

export const getBuildingChart = async (payload, options) =>
  await Axios.get(
    '/map/api/getBuildingChart',
    {
      params: payload,
    },
    options,
  )
