import styled from 'styled-components'
import logo from 'assets/images/logo/jmap-footer-logo.png'
import theme from 'lib/styles/theme'
import { getJootekServiceURL } from 'utils'
import moment from 'moment'

/**
 * footer
 */
const BaseFooter = () => {
  const thisYear = moment().format('YYYY')

  return (
    <StyledFooter>
      <StyledLogo src={logo} alt="JOOTEK 지도" />

      <StyledFooterText>
        (주)주택 지도의 컨텐츠는 참고용으로 제공되는 정보이며, 정확성을
        보증하지 않습니다. 이 정보의 이용으로 발생하는 손실은 이용자의
        책임입니다.
      </StyledFooterText>

      <StyledFooterText>
        <strong>출처:</strong> 국가공간정보포털, 국토교통부 실거래가
        공개시스템, 건축행정시스템 세움터, 지방행정 인허가 데이터개방,
        공공데이터 포털, 학구도안내서비스, 학교알리미, 한국도로공사
        고속도로 공공데이터포털, 도로명주소DB
      </StyledFooterText>

      <StyledMenu>
        <StyledMenuLink href={getJootekServiceURL('/terms')}>
          이용약관
        </StyledMenuLink>
        <StyledMenuDivider />
        <StyledMenuLink
          href={getJootekServiceURL('/privacy')}
          className="strong"
        >
          개인정보취급방침
        </StyledMenuLink>
        <StyledMenuDivider />
        <StyledMenuLink
          href="https://forms.gle/mp6oU3PDaJqDfM2N7"
          target="_blank"
        >
          불편신고
        </StyledMenuLink>
      </StyledMenu>
      <StyledFooterText>
        주식회사 주택 | 대표 강인승
        <br /> 대전광역시 서구 만년로68번길 15-20 대전콘텐츠기업지원센터
        506호
        <br />
        사업자등록번호 218-81-24451
        <br />
        통신판매업신고 2020-대전유성-0039
        <br />
        고객센터 010-7313-3330
      </StyledFooterText>
      <StyledFooterText>© {thisYear} JOOTEK INC.</StyledFooterText>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  padding: 20px;
  padding-bottom: 60px;

  color: ${theme.colors.gray[500]};
  font-size: 12px;
  line-height: 1.5;
  background-color: ${theme.colors.gray[100]};
`
const StyledFooterText = styled.div`
  margin-bottom: 24px;
`

const StyledLogo = styled.img`
  user-select: none;
  /* width: 68px; */
  height: 14px;
  margin-bottom: 14px;
`

const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

const StyledMenuLink = styled.a`
  color: ${theme.colors.gray[500]};
  font-size: 12px;
  font-weight: 700;

  &.strong {
    color: ${theme.colors.gray[600]};
  }

  &:hover {
    color: ${theme.colors.gray[600]};
  }
`

const StyledMenuDivider = styled.div`
  margin: 0 8px;
  height: 12px;
  border-right: 1px solid ${theme.colors.gray[300]};
`

export default BaseFooter
