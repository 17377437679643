import React, {
  Suspense,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { useSelector } from 'react-redux'
import moment from 'moment'
import CustomAddressCell from 'components/jmapnotev2/customCell/List/CustomAddressCell'
import {
  suppressEnter,
  suppressArrow,
  suppressTextCursorControl,
} from 'lib/utils/notev2/suppressKey'
import { suppressKeyboardEvent } from 'lib/utils/notev2/gridFocus'
import CustomPriceCell from 'components/jmapnotev2/customCell/List/CustomPriceCell'
import CustomContractTypeCell from 'components/jmapnotev2/customCell/List/CustomContractTypeCell'
import useGridEvent from 'lib/hooks/useGridEvent'
import {
  additionalCellWidth,
  cellPrependLabelColorClass,
  getGridFontSize,
  resetGridDataAddresses,
  rowClassRules,
} from 'lib/utils/notev2/gridUtil'
import CustomContactCell from 'components/jmapnotev2/customCell/List/CustomContactCell'
import CustomAreaCell from 'components/jmapnotev2/customCell/List/CustomAreaCellCommon'
import CustomAreaCellContract from 'components/jmapnotev2/customCell/List/CustomAreaCellContract'
import CustomPropertyTypeCell from 'components/jmapnotev2/customCell/List/CustomPropertyTypeCell'
import CustomGongbuDocCell from 'components/jmapnotev2/customCell/List/CustomGongbuDocCell'
import CustomImageCell from 'components/jmapnotev2/customCell/List/CustomImageCell'
import { columnCentered } from './NoteGridOptions'
import NoteGridNewLineRenderer from './NoteGridNewLineRenderer'
import StylingHeaderComponent from './StylingHeaderComponent'
import {
  GridStyleWrapper,
  GridWrapper,
  StyledGrid,
} from '../style/NoteGridStyle'
import CustomDirectionCell from '../customCell/List/CustomDirectionCell'
import CustomLandTypeCell from '../customCell/List/CustomLandTypeCell'
import CustomMemoCell from '../customCell/List/CustomMemoCell'
import CustomDescCell from '../customCell/List/CustomDescCell'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import Skeleton from 'components/common/Loading/Skeleton'
import { eachSmallRowHeight } from 'lib/utils/notev2/note2Constants'
import priceFormat from 'lib/utils/priceFormat'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import { getMaxRowHeight } from 'lib/utils/notev2/gridData'
import CustomContractDocCell from '../customCell/List/CustomContractDocCell'
import CustomAdCell from '../customCell/List/CustomAdCell'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'

const NoteListGrid = (
  { isLoading, gridRowData, onSwipeStart, onSwipeMove, onSwipeEnd },
  ref,
) => {
  const { isAllInOne } = useIsAllInOne()
  const threeStepState = useSelector(
    (state) => state.onBoarding.threeStepState,
  )
  const fourStepState = useSelector(
    (state) => state.onBoarding.fourStepState,
  )

  const { gridRef, resizeLayerRef } = ref
  const wrapperRef = useRef(null)

  const gridFontSize = useSelector((state) => state.notev2.gridFontSize)

  let newLineCount = 0

  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const [gridColumnApi, setGridColumnApi] = useState(null)
  const gridColumnApiStateRef = useRef()
  gridColumnApiStateRef.current = gridColumnApi

  //주소 검색중인지 확인하기 위한 state
  const [isSearching, setIsSearching] = useState(false)
  const isSearchingStateRef = useRef()
  isSearchingStateRef.current = isSearching

  const [isFocusPriceItem, setIsFocusPriceItem] = useState(false)
  const isFocusPriceItemStateRef = useRef()
  isFocusPriceItemStateRef.current = isFocusPriceItem
  const fiveStepState = useSelector(
    (state) => state.onBoarding.fiveStepState,
  )

  const {
    onCellFocused,
    onNavigateToNextCell,
    onTabToNextCell,
    onCellValueChanged,
    onRowClickEvent,
  } = useGridEvent({ resizeLayerRef })

  const newLineClickTime = useRef(null)

  const prevNoteSrl = useRef(null)

  /**
   * row가 선택 되었을때 이벤트
   */
  const onRowSelected = useCallback(
    (params) => {
      if (!params.node.selected) return

      prevNoteSrl.current = params.node.data.note_srl
      //  gridRef.current.api.onRowHeightChanged()
    },
    [prevNoteSrl.current, gridRef.current],
  )

  const onGridReady = useCallback((params) => {
    setGridColumnApi(params.columnApi)
  }, [])

  /**
   * 현재 수정중인 가격 셀이 있으면 바깥을 클릭했을 떄 row height 조정 및 셀 초기화
   */
  useOnClickOutside(wrapperRef, () => {
    if (fourStepState || threeStepState) return //온보딩상태일때 가격셀 안닫히게하기
    if (!gridRef?.current || !gridRef?.current?.api) return

    //현재 수정중인 가격 셀이 없으면 아래 초기화 for loop 로직 타지 않음

    //수정중인 가격 셀
    const isEditingPriceCell = gridRef.current.api
      .getRenderedNodes()
      .filter(({ data }) => data.state.isContractTypeShow)

    if (isEditingPriceCell.length == 0) return

    //그리드 구분, 가격 컬럼 모두 보여주기 모드 해제 로직
    setTimeout(() => {
      if (gridRef.current) {
        gridRef.current.api.forEachNode(function (rowNode, a, b) {
          const data = rowNode.data

          //수정중이 아니면 바꿀 필요 없음,
          if (!data.state.isContractTypeShow) return

          //가격 셀은 최대 높이가 60을 넘어갈 수 없으므로 60을 넘어가면 아래 로직을 타지 않음
          const rowHeight = getMaxRowHeight({
            contract_type: rowNode.data.contract_type,
            contacts: rowNode.data.contacts,
          })
          if (rowHeight >= 60) return

          //row height 변경된 구분 개수만큼 설정
          rowNode.setData({
            ...data,
            rowHeight: rowHeight,
            state: {
              ...data.state,
              isContractTypeShow: false,
            },
          })

          rowNode.setRowHeight(rowHeight)
        })
        gridRef.current.api.onRowHeightChanged()
      }
    }, 210)
  })

  const LazyAddressRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomAddressCell,
    }),
  )

  const LazyPropertyTypeRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomPropertyTypeCell,
    }),
  )

  const LazyContractTypeRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomContractTypeCell,
    }),
  )

  const LazyPriceRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomPriceCell,
    }),
  )

  const LazyAreaRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomAreaCell,
    }),
  )

  const LazyContactRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomContactCell,
    }),
  )

  const LazyLandTypeRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomLandTypeCell,
    }),
  )

  const LazyDirectionRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomDirectionCell,
    }),
  )

  const LazyMemoRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomMemoCell,
    }),
  )

  const LazyImageRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomImageCell,
    }),
  )

  const defaultCoumn = [
    { field: 'rowHeight', hide: true },
    { field: 'isContractTypeShow', hide: true },
    {
      field: 'ad',
      hide: !isAllInOne,
      suppressMovable: true,
      headerName: '광고',
      cellClass: 'custom-multiple-cell',
      cellRenderer: (params) => (
        <CustomAdCell
          data={params.data}
          gridfontsize={getGridFontSize(gridFontSize)}
        />
      ),
      width: 82,
      ...columnCentered,
    },
    {
      field: 'regdate',
      suppressMovable: true,
      headerName: '등록일',
      cellClass: 'custom-class-regdate-cell ',
      headerComponent: () => (
        <StylingHeaderComponent
          top="등록일"
          name="등록일"
          orderWay="simple"
          sortable
        />
      ),
      valueGetter: (params) =>
        params.data.regdate == '신규'
          ? '신규'
          : moment(params.data.regdate).format('YY.MM.DD'),
      unSortIcon: true,
      width: 80,
      ...columnCentered,
    },
    {
      field: 'display_address',
      suppressMovable: true,
      cellClass: 'custom-class-eupmyeondong-cell ',
      headerName: '읍면동',
      suppressMovable: true,
      cellClassRules: cellPrependLabelColorClass,
      cellRenderer: (params) => (
        <Suspense fallback={<Skeleton expand />}>
          <LazyAddressRenderer
            ref={gridRef}
            gridColumnApi={gridColumnApiStateRef.current}
            value={params.value}
            data={params.data}
            node={params.node}
            setIsSearching={setIsSearching}
            clearIsSearchingAddr={() => setIsSearching(false)}
            gridfontsize={getGridFontSize(gridFontSize)}
          />
        </Suspense>
      ),
      suppressKeyboardEvent: (params) => {
        const isSearchingNow = isSearchingStateRef.current
        //주소 검색중 enter, 오,왼 방향키 눌렀을 때 cell이 수정 모드로 변하는것 막기
        if (
          suppressEnter(params) ||
          suppressArrow(params) ||
          suppressTextCursorControl(params)
        ) {
          const targetNode = params.event?.target
          if (
            targetNode &&
            (targetNode.tagName == 'INPUT' ||
              targetNode.tagName == 'UL' ||
              targetNode.tagName == 'LI')
          ) {
            return true
          }
        }

        // esc -> 검색 취소
        if (params.event.key == 'Escape') {
          if (params.data.note_srl == '신규' && isSearchingNow) {
            resetGridDataAddresses(params.node, true)
          } else {
            gridRef.current.api.setFocusedCell(
              params.node.rowIndex,
              'addr',
            )
          }
        }

        return false
      },
      width: 71,
      resizable: false,
      editable: false,
      colSpan: () => 2,
      ...columnCentered,
    },
    {
      fontsize: '20px',
      field: 'display_address',
      suppressMovable: true,
      cellClass: 'custom-no-padding-cell custom-display-address-cell ',
      suppressMovable: true,
      headerName: '주소',
      headerComponent: () => (
        <StylingHeaderComponent
          top="주소"
          name="주소"
          orderWay="simple"
          sortable
        />
      ),
      unSortIcon: true,
      resizable: true,
      editable: false,
      width: 300,
    },
    {
      field: 'property_type',
      suppressMovable: true,
      cellClass:
        'custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell custom-no-padding-cell ',
      headerComponent: () => (
        <StylingHeaderComponent
          top="종류"
          name="종류"
          orderWay="simple"
          sortable
        />
      ),
      width: 80,
      unSortIcon: true,
      resizable: true,
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyPropertyTypeRenderer
              data={params.data}
              node={params.node}
            />
          </Suspense>
        )
      },
      ...columnCentered,
    },
    {
      field: 'contract_type',
      suppressMovable: true,
      headerName: '구분',
      headerComponent: () => (
        <StylingHeaderComponent
          top="구분"
          name="구분"
          orderWay="select"
          sortable
        />
      ),
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyContractTypeRenderer
              note_srl={params.data?.note_srl}
              contract_type={params.data?.contract_type}
              sale_price={params.data?.sale_price}
              jeonse_deposit={params.data?.jeonse_deposit}
              rent={params.data?.rent}
              deposit={params.data?.deposit}
              loan={params.data?.loan}
              isContractTypeShow={
                params.data?.state?.isContractTypeShow || false
              }
              property_type={params.data?.property_type}
              presale_base_price={
                params.data?.extra_vars?.presale_base_price
              }
              params={params}
            />
          </Suspense>
        )
      },
      cellClass: 'custom-no-padding-cell custom-contract-type-cell ',
      unSortIcon: true,
      resizable: true,
      width: 64,
      ...columnCentered,
    },
    {
      field: 'note_srl',
      suppressMovable: true,
      headerComponent: () => (
        <StylingHeaderComponent
          top="가격"
          name="가격"
          orderWay="select"
          sortable
        />
      ),
      cellClass:
        'custom-multiple-cell custom-class-price-cell custom-event-suppress-side-arrow-keys custom-event-suppress-text-cursor-keys custom-event-suppress-enter-to-next ',
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyPriceRenderer
              isContractTypeShow={params.data.state.isContractTypeShow}
              node={params.node}
              setIsFocusPriceItem={setIsFocusPriceItem}
              contract_type={params.data.contract_type}
              sale_price={params.data.sale_price}
              jeonse_deposit={params.data.jeonse_deposit}
              deposit={params.data.deposit}
              rent={params.data.rent}
              property_type={params.data?.property_type}
              presale_base_price={
                params.data?.extra_vars?.presale_base_price
              }
              api={params.api}
            />
          </Suspense>
        )
      },
      unSortIcon: true,
      width: 150,
      resizable: true,
      ...columnCentered,
    },
    {
      field: 'note_srl',
      suppressMovable: true,
      cellClass:
        'custom-multiple-cell custom-class-area-cell custom-event-suppress-side-arrow-keys custom-event-suppress-text-cursor-keys custom-event-suppress-enter-to-next ',
      headerComponent: () => (
        <StylingHeaderComponent
          top="면적"
          bottom="공급/전용"
          name="면적"
          orderWay="select"
          sortable
        />
      ),
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyAreaRenderer
              node={params.node}
              property_type={params.data.property_type}
              contract_area={params.data.contract_area}
              dong={params.data.dong}
              ho={params.data.ho}
              total_floor_area={params.data.total_floor_area}
              land_area={params.data.land_area}
              contractInput={
                <CustomAreaCellContract
                  node={params.node}
                  exclusive_area={params.data.exclusive_area}
                  property_type={params.data.property_type}
                />
              }
            />
          </Suspense>
        )
      },
      unSortIcon: true,
      resizable: true,
      width: 160,
      ...columnCentered,
    },
    {
      field: 'direction',
      suppressMovable: true,
      valueGetter: (params) => {
        if (
          params.data.property_type == '토지' ||
          params.data.invalid_addr_name
        ) {
          return params.data.land_type
        } else {
          return params.data.direction
        }
      },
      headerComponent: () => (
        <StylingHeaderComponent
          top="방향"
          bottom="(지목)"
          name="방향"
          orderWay="simple"
          sortable
        />
      ),
      width: 70,
      resizable: true,
      cellEditor: 'agSelectCellEditor',
      cellClass:
        'custom-cell-overflow-visible custom-class-direction-cell custom-event-suppress-arrow-keys custom-multiple-cell custom-no-padding-cell ',
      cellRenderer: (params) => {
        if (
          params.data.property_type == '토지' ||
          params.data.invalid_addr_name
        ) {
          return (
            <Suspense fallback={<Skeleton />}>
              <LazyLandTypeRenderer node={params.node} />
            </Suspense>
          )
        } else {
          return (
            <Suspense fallback={<Skeleton />}>
              <LazyDirectionRenderer node={params.node} />
            </Suspense>
          )
        }
      },
      ...columnCentered,
    },
    {
      field: 'contacts',
      suppressMovable: true,
      headerName: '연락처',
      width: 178,
      cellClass:
        'custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell custom-event-suppress-text-cursor-keys ',
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyContactRenderer
              value={params.value}
              node={params.node}
              gridApi={params.api}
              contacts={params.data.contacts}
              noteSrl={params.data.note_srl}
            />
          </Suspense>
        )
      },
      ...columnCentered,
    },
    {
      field: '계약서',
      suppressMovable: true,
      headerName: '계약서',
      cellClass: 'custom-multiple-cell',
      cellRenderer: (params) => (
        <CustomContractDocCell
          data={params.data}
          gridfontsize={getGridFontSize(gridFontSize)}
        />
      ),
      width: 96,
      ...columnCentered,
    },
    {
      field: '공부문서',
      suppressMovable: true,
      headerName: '공부문서',
      cellRenderer: (params) => (
        <CustomGongbuDocCell
          data={params.data}
          gridfontsize={getGridFontSize(gridFontSize)}
        />
      ),
      width: 84,
      ...columnCentered,
    },
    {
      field: 'image_cnt',
      suppressMovable: true,
      headerName: '사진',
      cellClass: '',
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyImageRenderer
              value={params.value}
              data={params.data}
              gridfontsize={getGridFontSize(gridFontSize)}
            />
          </Suspense>
        )
      },
      width: 75,
      ...columnCentered,
    },
    {
      field: 'memo',
      suppressMovable: true,
      headerName: '메모',
      wrapText: true,
      cellClass:
        'custom-cell-overflow-visible custom-multiple-cell custom-cell-value-multiline custom-event-suppress-side-arrow-keys ',
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyMemoRenderer value={params.value} node={params.node} />
          </Suspense>
        )
      },
      width: 230,
      resizable: true,
      suppressKeyboardEvent: (params) => {
        if (
          suppressArrow(params) ||
          suppressTextCursorControl(params)
        ) {
          const targetNode = params.event?.target
          if (targetNode && targetNode.tagName == 'TEXTAREA') {
            return true
          }
        }

        if (params.event.key == 'Escape') {
          gridRef.current.api.setFocusedCell(
            params.node.rowIndex,
            'memo',
          )
        }
        return false
      },
      ...columnCentered,
    },
    {
      field: 'desc',
      suppressMovable: true,
      headerName: '상세정보',
      wrapText: true,
      cellClass: 'custom-cell-value-multiline ',
      valueGetter: ({ data }) => {
        //관리비
        const feeData = data?.fee || data.extra_vars?.fee
        const foregiftData = data?.foregift || data.extra_vars?.foregift
        const fee = feeData
          ? `관리비: ${priceFormat(feeData * 10000)}`
          : ''
        const foregift = foregiftData
          ? `권리금: ${priceFormat(foregiftData * 10000)}`
          : ''

        //전세대출 가능여부
        const lending =
          data.lending && data.lending === 'Y' ? `전세대출` : ''

        const lending_insurance =
          data.lending_insurance && data.lending_insurance === 'Y'
            ? `보증보험`
            : ''

        const lending_mss =
          data.lending_mss && data.lending_mss === 'Y'
            ? '중소기업 청년대출'
            : ''
        //지원사항
        const parking = data.parking == 'Y' ? '주차가능' : ''
        const elevator = data.elevator == 'Y' ? '엘리베이터' : ''
        const animal = data.animal == 'Y' ? '반려동물' : ''
        const balcony = data.balcony == 'Y' ? '베란다' : ''
        const optionsArr = [parking, elevator, animal, balcony]
        const support =
          optionsArr.filter((item) => item).length > 0
            ? optionsArr.filter((item) => item).toString()
            : ''
        //입주가능일
        const move_date = data.move_date
          ? `입주가능일 : ${data.move_date}`
          : ''
        // 거래지분
        const contract_share = data.contract_share ?? ''

        return {
          data: [
            lending,
            lending_insurance,
            lending_mss,
            support,
            contract_share,
            foregift,
          ]
            .filter((item) => item)
            .join(' | '),
          fee: priceFormat(feeData * 10000),
          move_date: data.move_date,
        }
      },
      cellRenderer: (params) => {
        return (
          <CustomDescCell data={params.data} value={params.value} />
        )
      },
      width: 341,
      ...columnCentered,
    },
    {
      field: 'note_srl',
      suppressMovable: true,
      headerName: '매물번호',
      cellClass: 'custom-class-note-srl-cell ',
      width: 98,
      ...columnCentered,
    },
    {
      field: 'regdate',
      hide: true,
      suppressMovable: true,
      headerName: '등록일',
      cellClass: 'custom-class-regdate-m-cell ',
      valueGetter: (params) =>
        params.data.regdate == '신규'
          ? '신규'
          : moment(params.data.regdate).format('YY.MM.DD'),
      unSortIcon: true,
      width: 80,
      ...columnCentered,
    },
  ]

  //컬럼
  const [columnDefs, setColumnDefs] = useState(defaultCoumn)

  //그리드 옵션
  const gridOptions = {
    onCellFocused: (e) =>
      onCellFocused(
        e,
        setIsFocusPriceItem,
        isSearchingStateRef.current,
        setIsSearching,
        prevNoteSrl.current,
      ),
    tabToNextCell: (e) =>
      onTabToNextCell(e, isSearchingStateRef.current),
    navigateToNextCell: onNavigateToNextCell,
    onRowSelected: onRowSelected,
    onCellValueChanged: onCellValueChanged,
    rowClassRules: rowClassRules,
  }

  //모바일 - pc의 dynamic 컬럼 설정
  useEffect(() => {
    /**
     * 폰트 사이즈 변경에 따라 그리드 셀 폭 조절
     */
    const addWidth = additionalCellWidth(gridFontSize)

    const addedWidthColumn = columnDefs.map((item) => {
      return Object.assign({}, item, {
        width: item.width + addWidth,
      })
    })

    setColumnDefs(addedWidthColumn)
  }, [gridFontSize])

  /**
   * 컬럼별 rowHeight 설정하기
   */
  const getRowHeight = useCallback(
    (params) => {
      return params.data.rowHeight
    },
    [ref, gridRowData],
  )

  /**
   * 신규 row 추가
   */
  const onClickNewLineAdd = useCallback(() => {
    if (channelInfo?.member_type == 'readonly') return
    const now = moment()

    //빨리 클릭해 여러줄이 생성되는것 방지
    const diff = now.diff(
      moment(newLineClickTime.current),
      'millisecond',
    )

    if (diff < 700) {
      return
    }

    /**
     * 신규 row data
     * @returns new rowdata
     */
    const createNewRowData = () => {
      const newData = {
        address_srl: '',
        address_id: '',
        regdate: '신규',
        note_srl: `신규`,
        row_key: `new${newLineCount}`,
        area1: '',
        area2: '',
        addr: '',
        rowHeight: eachSmallRowHeight * 2,
        contract_type: '',
        property_type: '',
        eupmyeondong: '',
        address_doro: '',
        dong: '',
        ho: '',
        contacts: [],
        land_type: '',
        direction: '',
        state: {
          isContractTypeShow: false,
          //isEditingContact: false,
          isEditingPropertyType: false,
          isEditingDirection: false,
        },
      }

      newLineCount++
      return newData
    }

    const addedRow = gridRef.current.api.applyTransaction({
      add: [
        Object.assign({}, createNewRowData(), {
          channel_srl: channelInfo.channel_srl,
        }),
      ],
      addIndex: 1,
    })

    addedRow.add[0].setSelected(true)

    gridRef.current.api.setFocusedCell(1, 'display_address')

    newLineClickTime.current = moment()
  }, [channelInfo, newLineClickTime])

  /**
   * 전체 너비 사용 row 조건
   */
  const isFullWidthRow = useCallback((params) => {
    return params.rowNode.data.row_key == 'addNewLine'
  }, [])

  const fullWidthCellRenderer = (e) => {
    return (
      <NoteGridNewLineRenderer
        isNote
        onClickNewLineAdd={onClickNewLineAdd}
      />
    )
  }

  return (
    <>
      <GridStyleWrapper
        id="note-sheet-resize-layer"
        threestepstate={threeStepState.toString()}
        fourstepstate={fourStepState.toString()}
        fivestepstate={fiveStepState.toString()}
        minusheight="92"
        onSwipeStart={onSwipeStart}
        onSwipeMove={(e, ev) => onSwipeMove(e, ev, true)}
        onSwipeEnd={(e) => onSwipeEnd(e, true)}
        gridfontsize={getGridFontSize(gridFontSize)}
      >
        <GridWrapper ref={wrapperRef} className="ag-theme-alpine">
          <StyledGrid
            className="note-list-grid prevent-left-scroll"
            ref={gridRef}
            rowData={gridRowData}
            defaultColDef={{ suppressKeyboardEvent }}
            columnDefs={columnDefs}
            getRowHeight={getRowHeight}
            isFullWidthRow={isFullWidthRow}
            fullWidthCellRenderer={fullWidthCellRenderer}
            gridOptions={gridOptions}
            getRowId={(params) => params.data.row_key} //unique해야 그리드 업데이트가 일어남.....................
            onGridReady={onGridReady}
            rowSelection={'single'}
            rowBuffer={2}
            onRowClicked={onRowClickEvent}
            loadingOverlayComponent={FullScreenLoading}
            headerHeight={44 + additionalCellWidth(gridFontSize)}
            // overlayLoadingTemplate={
            //   '<span class="ag-overlay-loading-center">로딩중...</span>'
            // }
            overlayNoRowsTemplate={'<span></span>'}
          />
        </GridWrapper>
      </GridStyleWrapper>
    </>
  )
}

export default React.memo(forwardRef(NoteListGrid))
