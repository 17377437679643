import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import SummaryPyUnitGraphTooltip from './SummaryPyUnitGraphTooltip'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import priceFormat from 'lib/utils/priceFormat'

/**
 * 평단가 비교 그래프
 */
const SummaryPyUnitGraph = ({ data, sidoData, sigunguData }) => {
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  const summaryInfo = useSelector((state) => state.summary.summaryInfo)

  const tickFormatter = (value) => `${priceFormat(value * 10000)}`

  function CustomizedLabel(props) {
    return (
      <text
        style={{
          fontSize: '12px',
          fontWeight: 500,
          fill: props.fill,
        }}
      >
        <tspan
          textAnchor="middle"
          x={props.viewBox.width + props.viewBox.x + 16}
          y={props.viewBox.y + 3}
        >
          {props.value.slice(0, 3)}
        </tspan>
        <tspan
          textAnchor="middle"
          x={props.viewBox.width + props.viewBox.x + 16}
          dy={15}
        >
          {props.value.slice(3).trim()}
        </tspan>
      </text>
    )
  }

  function CustomizedTick(props) {
    const { x, y, payload } = props

    const length = payload.value.length

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          fill={theme.colors.gray[800]}
          style={{ fontSize: '12px', fontWeight: 600 }}
        >
          <tspan textAnchor="middle" x="0">
            {payload.value.slice(0, 6)}
          </tspan>
          <tspan textAnchor="middle" x="0" dy={15}>
            {payload.value.slice(6, 12)}
          </tspan>
          <tspan textAnchor="middle" x="0" dy={15}>
            {payload.value.slice(12, 18)}
            {length > 18 && '...'}
          </tspan>
        </text>
      </g>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={270}>
      <BarChart
        width={730}
        height={250}
        data={data}
        margin={{
          top: 20,
          right: 42,
          left: -8,
          bottom: 30,
        }}
      >
        <ReferenceLine
          strokeDasharray="3 3"
          y={sigunguData.avg}
          stroke={theme.colors.blue[500]}
          label={
            <CustomizedLabel
              value={sigunguData.name}
              fill={theme.colors.blue[500]}
            />
          }
        />
        <ReferenceLine
          strokeDasharray="3 3"
          y={sidoData.avg}
          stroke={theme.colors.purple[500]}
          label={
            <CustomizedLabel
              value={sidoData.name}
              fill={theme.colors.purple[500]}
            />
          }
        />
        <CartesianGrid
          vertical={false}
          stroke={theme.colors.gray[100]}
        />
        <XAxis
          tickLine={false}
          axisLine={false}
          interval={0}
          dataKey="apt_name"
          tick={<CustomizedTick />}
        />
        <YAxis
          tick={{ fill: theme.colors.gray[600], fontSize: '12px' }}
          tickLine={false}
          axisLine={false}
          tickCount={5}
          minTickGap={4}
          dataKey="price"
          tickFormatter={tickFormatter}
        />
        <Tooltip
          position={{ y: 0 }}
          isAnimationActive={false}
          cursor={false}
          contentStyle={{
            backgroundColor: 'white',
            borderColor: theme.colors.gray[200],
            borderRadius: '8px',
            boxShadow: theme.shadow['card-2'],
            padding: '0.5rem 1rem',
          }}
          content={<SummaryPyUnitGraphTooltip />}
        />
        <Bar dataKey="price" barSize={33} radius={[6, 6, 0, 0]}>
          {data.map((entry, index) => {
            if (
              entry?.address_id == currentAddressId ||
              summaryInfo?.sub_addresses.includes(entry?.address_id)
            )
              return (
                <Cell key={index} fill={theme.colors.primary[600]} />
              )
            else
              return (
                <Cell key={index} fill={theme.colors.primary[300]} />
              )
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SummaryPyUnitGraph
