import { Button } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

export const Block = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  border-radius: 20px;
  padding: 30px;
  background-color: ${theme.colors.gray[50]};

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.base.white};
    border: 2px solid ${theme.colors.blue[400]};
  `}

  ${media.smallPc`
    padding: 24px;
  `}

  ${media.mediumM`
    padding: 30px;
  `}

  ${media.mediumS`
    width: 100%;
    padding: 28px 26px; 
    background-color: ${theme.colors.base.white};
  `}
`

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 38px;
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
  border-bottom: 1px solid ${theme.colors.gray[200]};

  ${media.smallPc`
    font-size: 20px;
    margin-bottom: 30px;
    padding-bottom: 30px;
  `}

  ${media.mediumS`
    margin-bottom: 24px;
  `}
`

export const CurrentPlanButton = styled.div`
  position: absolute;
  width: 76px;
  height: 26px;
  left: 30px;
  top: -10px;
  padding: 6px;
  background-color: ${theme.colors.blue[900]};
  color: ${theme.colors.base.white};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 28px;

  ${media.smallPc`
    margin-bottom: 24px;
  `}

  ${media.mediumS`
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  `}

  svg {
    margin-left: 4px;
    width: 57px;
    height: 57px;

    ${media.mediumM`
      position: absolute;
      width: 70px;
      left: 180px;
      top: 12px;
    `}

    ${media.mediumS`
      width: 44px;
      left: 20px;
      top: 11px;
      right: 20px;
    `}
  }
`

export const Price = styled.div`
  font-size: 36px;
  font-weight: 800;
  color: ${theme.colors.blue[600]};
  line-height: 100%;

  span {
    font-size: 16px;
    margin-left: 4px;
  }

  &::after {
    ${({ text }) =>
      text ? `content: '${text}'` : `content: '/ 월별'`};
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    color: ${theme.colors.gray[600]};

    ${media.smallPc`
      font-size: 14px;
    `}
  }

  ${media.smallPc`
    font-size: 28px;
  `}

  ${media.mediumS`
    font-size: 28px;
  `}
`

export const StartButton = styled(Button)`
  font-size: 16px;
  margin-bottom: 40px;
  ${({ nomargin }) => nomargin && `margin-bottom: 0 !important`};

  ${media.smallPc`
    margin-bottom: 30px;
  `}

  ${media.mediumS`
    top: 24px;
    right: 20px;
    font-size: 14px;
  `}
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  color: ${theme.colors.gray[600]};
`

export const ListItem = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;

  ${({ bold }) =>
    bold == 'Y' &&
    `
    font-weight: 700;
    color: ${theme.colors.gray[900]};

    &::after {
      content: '월 1건 무료, 추가 800원/건';
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      margin-top: 4px;
      margin-left: 18px;
    }
  `}

  ${media.mediumS`
    font-size: 14px;
  `}

  &::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-size: 12px;
    color: ${theme.colors.blue[400]};
    margin-right: 10px;
  }
`

export const ScheduledCancelText = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  margin-left: 4px;
  color: ${theme.colors.tint.danger};

  &::before {
    content: '*';
    margin-right: 1px;
  }
`
