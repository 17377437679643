import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 총 주차대수
 */
const NoteAdColumnTotalParkingCount = ({ required = false }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const value = watch('article.parking')

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>총 주차대수</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            width={InputRemoveAppendWidth}
            type="number"
            append={'대'}
            defaultValue={value}
            {...register('article.parking')}
            onBlur={(e) => {
              onUpdate(
                'article.parking',
                e.target.value.replaceAll(',', ''),
              )
            }}
          />
        </div>
        <ValidateWarn>{errors?.article?.parking?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnTotalParkingCount
