import styled from 'styled-components'
import { Tag } from 'components/design'
import { ReactComponent as NewIcon } from 'assets/icon/note/ad/new.svg'
import { bp } from '../breakPoints'

/**
 * 광고 메인 공지사항
 */
const NoteAdNoticeFlat = () => {
  const isFullWidth = window.innerWidth <= bp[4]
  return (
    <Wrapper full={isFullWidth}>
      <StyledTag color="primary" size="small">
        공지
      </StyledTag>
      <Title>제목</Title>
      <NewIcon />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 2px;
  margin-bottom: 2px;

  ${({ full }) =>
    full &&
    `
    width: 100%;
  `}
`

const StyledTag = styled(Tag)`
  flex-shrink: 0;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
`

export default NoteAdNoticeFlat
