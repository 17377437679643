import { useContext } from 'react'
import styled from 'styled-components'
import CardContext from './context/CardContext'

const CardFooter = (props) => {
  const context = useContext(CardContext) || null
  const sizeState = context?.state?.sizeState || 'medium'

  return <StyledCardFooter size={sizeState} {...props} />
}

const StyledCardFooter = styled.div`
  padding: 0 1.25rem 1.25rem;
  margin-top: -0.25rem;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          padding: 0 1rem;
        `
      case 'medium':
        return `
          padding: 0 1.25rem;
        `
      case 'large':
        return `
          font-size: 1.25rem;
          padding: 0 1.5rem 1.875rem;
        `
    }
  }}
`

export default CardFooter
