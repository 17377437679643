import React, { useEffect, useRef, useState } from 'react'
import { Steps } from 'intro.js-react'
import { useSelector } from 'react-redux'

const OnBoarding = ({
  onComplete,
  enabled,
  steps,
  onChange,
  onExit,
  options,
  setSteps,
}) => {
  const [initialStep, setInitialStep] = useState(0)
  const onBoardingStart = useSelector(
    (state) => state.onBoarding.onBoardingStart,
  )
  const AddressCellState = useSelector(
    (state) => state.onBoarding.AddressCellState,
  )
  const noteListInfo = useSelector((state) => state.notev2.noteListInfo)

  const threeDepthChannelSrl = useSelector(
    (state) => state.onBoarding.threeDepthChannelSrl,
  )

  const threeDepthLoaded = useSelector(
    (state) => state.onBoarding.threeDepthLoaded,
  )

  const threeDepthStart = useSelector(
    (state) => state.onBoarding.threeDepthStart,
  )

  const gongbuStepState = useSelector(
    (state) => state.onBoarding.gongbuStepState,
  )
  const contractStepState = useSelector(
    (state) => state.onBoarding.contractStepState,
  )
  //웰컴 모달 시작하기 누르면 제일처음 실행되는 온보딩
  useEffect(() => {
    if (!onBoardingStart) return
    if (noteListInfo.count == 0) {
      setSteps([
        {
          element: '.add-wrapper',
          title: '새로운 매물을 등록해볼까요?',
          intro: `+신규 매물 주소 입력을 클릭하여 <span class='color'>주소를 추가</span>하세요.`,
          tooltipClass: 'first-tooltip',
          highlightClass: 'first-highlightClass',
        },
      ])
    }
  }, [onBoardingStart])

  //웰컴 모달 시작하기 누르면 제일처음 실행되는 온보딩~3뎁스 열리기전까지 실행되는 온보딩
  useEffect(() => {
    if (!AddressCellState) return
    setSteps([
      {
        element: '.adress-wrapper-input',
        intro: '이제 이 주소를 검색해볼게요',
        tooltipClass: 'address-tooltip',
        highlightClass: 'address-highlightClass',
      },
      {
        element: '.custom-cell-prepend-label-color',
        intro: '다른 정보는 자동으로 채워졌어요',
        tooltipClass: 'full-information-tooltip',
        highlightClass: 'full-information-highlightClass',
      },
      {
        element: '.custom-class-price-cell',
        intro: '가격을 입력해볼게요!',
        tooltipClass: 'price-tooltip',
        highlightClass: 'price-highlightClass',
      },
      {
        element: '.adress-wrapper-input',
        title: '주소가 검색되었어요',
        intro: `<span class="color">열기 버튼</span>을 눌러 더 상세한 정보를<br/> 확인해볼까요?`,
        tooltipClass: 'open-button-tooltip',
        highlightClass: 'open-highlightClass',
      },
      {
        element: '.detail-wrapper',
        intro: '열기버튼을 눌러 더 상세한 정보를<br/>확인해볼까요?',
        tooltipClass: 'first-tooltip',
        highlightClass: 'open-highlightClass',
      },
    ])
  }, [AddressCellState])

  //3뎁스 열리고 실행되는 온보딩~3뎁스 등기부 탭 열리기 전까지 실행되는 온보딩
  useEffect(() => {
    const time = setTimeout(() => {
      if (
        threeDepthChannelSrl != 0 &&
        threeDepthLoaded &&
        threeDepthStart
      ) {
        setSteps([
          {
            element: '.layout-wrapper',
            title: '대장정보의 내용이 채워졌어요',
            intro: '나머지 부분만 직접 채워주세요.',
            position: 'right',
            highlightClass: 'threedepth-hightlight-class',
            tooltipClass: 'threedepth-tooltip',
          },
          {
            element: '.tab-class',
            title: '매물정보 외에 다른 것도 확인해볼까요?',
            intro: `<span class="color">공부문서 탭</span>으로 이동해볼게요.`,
            position: 'right',
            tooltipClass: 'gongbu-tooltip',
            highlightClass: 'gongbu-hightlight-class',
          },
        ])
      }
    }, 1000)
  }, [threeDepthChannelSrl, threeDepthLoaded, threeDepthStart])

  //3뎁스 등기부 탭 열리고 실행되는 온보딩~ 3뎁스 계약서 탭 열리기 전까지 실행되는 온보딩
  useEffect(() => {
    if (gongbuStepState) {
      setSteps([
        {
          element: '.gongbu-class',
          title: '바로 등기부등본, 토지이용계획원 등을 발급 받아보세요',
          intro: `<span class="color">공부문서 발급내역</span>도 여기서 바로<br/> 확인해보세요.`,
          position: 'right',
          tooltipClass: 'gongbu-next-tooltip',
          highlightClass: 'gongbu-next-hightlight-class',
          buttonClass: 'dongbutton',
        },
        {
          element: '.tab-class',
          intro: '계약서를 작성해볼게요',
          position: 'right',
          tooltipClass: 'contract-tooltip',
          highlightClass: 'contract-tab-hightlight-class',
          buttonClass: 'dongbutton',
        },
      ])
    }
  }, [gongbuStepState])
  //3뎁스 계약서 탭 열린 후 실행되는 온보딩
  useEffect(() => {
    if (contractStepState) {
      setSteps([
        {
          element: '.contract-class',
          title: '기입한 매물정보로 계약서도 작성할수 있어요!',
          intro: `지금까지 새로워진 매물노트 서비스 소개였어요. <span class="color">계약서를 작성하시려면 계약서 작성 버튼을</span> 눌러주세요.<button class='last-button'>소개끝내기</button>`,
          position: 'right',
          tooltipClass: 'sheet-last-tooltip',
          highlightClass: 'sheet-last-hightlight-class',

          buttonClass: 'dongbutton',
        },
        {
          element: '.contract-class',
          title: 'null',
          intro: `지금까지 새로워진 매물노트 서비스 소개였어요. <span class="color">계약서를 작성하시려면 계약서 작성 버튼을</span> 눌러주세요.<button class='last-button'>소개끝내기</button>`,
        },
      ])
    }
  }, [contractStepState])

  return (
    <Steps
      //제일 마지막 요소가 끝나면 실행되는 함수
      onComplete={onComplete}
      //특정요소가 실행될때 실행되는 함수
      onChange={onChange}
      //온보딩 실행 여부
      enabled={enabled}
      //온보딩요소
      steps={steps}
      //온보딩 실행 순서
      initialStep={initialStep}
      //온보딩이 없어지면 실행되는 함수
      onExit={onExit}
      options={options}
    ></Steps>
  )
}

export default OnBoarding
