import theme, { media } from 'lib/styles/theme'
import { SectionWrapper, Title } from './LandingStyle'
import { ReactComponent as FreeImg } from 'assets/images/note/landing/free.svg'
import AppContainer from './AppContainer'
import styled from 'styled-components'
import { Button } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import LandingPriceWarning from './LandingPriceWarning'
import { forwardRef } from 'react'

const LandingPrice = ({ onClickStart }, ref) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  return (
    <Wrapper ref={ref}>
      <AppContainer>
        <TitleWrapper>
          <SmallTitle>요금제</SmallTitle>
          <Title>
            번거로운 작업은 매물노트에 맡기고
            <br />
            사업에만 집중하세요
          </Title>
        </TitleWrapper>
        <Content>
          <Block>
            <BlockTitle>프리</BlockTitle>
            <PriceWrapper>
              <Price>월 0원</Price>
            </PriceWrapper>
            <List>
              <ListItem>매물노트 이용</ListItem>
              <ListItem>계약서 작성</ListItem>
              <ListItem>확인설명서 작성</ListItem>
            </List>
            <StartButton
              size={isMobile || isTablet ? 'large' : 'xlarge'}
              color="blue"
              onClick={onClickStart}
            >
              시작하기
            </StartButton>
          </Block>
          <Block>
            <BlockTitle mp>베이직</BlockTitle>
            <PriceWrapper>
              <Percent>45%</Percent>
              <Prices>
                <Price>월 4,900원</Price>
                <Discount>8,900원</Discount>
                <FreeImg />
              </Prices>
            </PriceWrapper>
            <List>
              <ListItem bold="Y">등기부등본 발급권</ListItem>
              <ListItem>매물노트 이용</ListItem>
              <ListItem>계약서 작성</ListItem>
              <ListItem>확인설명서 작성</ListItem>
              <ListItem>동호관리 플러스단지 1개</ListItem>
            </List>
            <StartButton
              size={isMobile || isTablet ? 'large' : 'xlarge'}
              color="blue"
              onClick={onClickStart}
            >
              30일 무료 체험
            </StartButton>
          </Block>
          <Block>
            <BlockTitle>올인원</BlockTitle>
            <PriceWrapper>
              <Price>Coming Soon</Price>
            </PriceWrapper>
            <List>
              <ListItem>네이버 광고</ListItem>
              <ListItem bold="Y">등기부등본 발급권</ListItem>
              <ListItem>매물노트 이용</ListItem>
              <ListItem>계약서 작성</ListItem>
              <ListItem>확인설명서 작성</ListItem>
              <ListItem>동호관리 플러스단지 5개</ListItem>
            </List>
            <StartButton
              size={isMobile || isTablet ? 'large' : 'xlarge'}
              color="blue"
              disabled
            >
              출시 예정
            </StartButton>
          </Block>
        </Content>
      </AppContainer>
      <LandingPriceWarning />
    </Wrapper>
  )
}

const Wrapper = styled(SectionWrapper)`
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 60px;
  padding-bottom: 550px;
  margin-bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(130, 136, 217, 0.5) 0%,
    rgba(142, 209, 230, 0.5) 100%
  );

  ${media.mediumS`
    padding-bottom: 650px;
  `}
`

const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
`

const SmallTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: ${theme.colors.blue[700]};
  margin-bottom: 25px;

  ${media.mediumS`
    font-size: 18px;
    margin-bottom: 12px;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-top: 100px;

  ${media.mediumM`
    margin-top: 70px;
  `}

  ${media.mediumS`
    margin-top: 40px;
    gap: 24px;
  `}
`

const Block = styled.div`
  position: relative;
  width: 90%;
  max-width: 800px;
  border-radius: 20px;
  padding: 30px;
  background-color: ${theme.colors.base.white};

  ${media.mediumS`
    padding: 28px 26px; 
  `}
`

const BlockTitle = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;

  ${media.mediumM`
    font-size: 22px;
    margin-bottom: 30px;
  `}

  ${media.mediumS`
    ${({ mp }) =>
      mp &&
      `
        margin-left: 50px;  
    `}
  `}
`

const PriceWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 28px;

  ${media.mediumS`
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
  `}

  svg {
    margin-left: 4px;
    width: 57px;
    height: 57px;

    ${media.mediumM`
      position: absolute;
      width: 70px;
      left: 90px;
      top: 12px;
    `}

    ${media.mediumS`
      width: 44px;
      left: 20px;
      top: 11px;
      right: 20px;
    `}
  }
`

const Price = styled.div`
  font-size: 36px;
  font-weight: 600;
  color: ${theme.colors.blue[500]};
  line-height: 100%;

  ${media.mediumM`
    font-size: 32px;
  `}

  ${media.mediumS`
    font-size: 28px;
  `}
`

const Prices = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    flex-shrink: 0;
  }

  ${media.mediumS`
    gap: 10px;
  `}
`

const List = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px 30px;
`

const ListItem = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  color: ${theme.colors.gray[600]};

  ${({ bold }) =>
    bold == 'Y' &&
    `
    font-weight: 700;

    &::after {
      content: '월 1건 무료, 추가 800원/건';
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      margin-top: 4px;
      margin-left: 18px;
    }
  `}

  ${media.mediumS`
    font-size: 14px;
  `}

  &::before {
    content: '·';
    font-weight: 900;
    margin-right: 12px;
  }
`

const StartButton = styled(Button)`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 182px;
  font-size: 16px;

  ${media.mediumM`
      width: 120px;
  `}

  ${media.mediumS`
    width: auto;
    top: 24px;
    right: 20px;
    font-size: 14px;
  `}

  &:disabled {
    background-color: ${theme.colors.gray[400]};
    border: none;
    color: ${theme.colors.base.white};
  }
`

const Percent = styled.div`
  font-size: 36px;
  color: ${theme.colors.tint.danger};
  font-weight: 600;
  line-height: 100%;

  ${media.mediumM`
    font-size: 32px;
  `}

  ${media.mediumS`
    font-size: 26px;
  `}
`

const Discount = styled.div`
  font-size: 22px;
  line-height: 100%;
  color: ${theme.colors.gray[400]};
  font-weight: 500;
  text-decoration: line-through;

  ${media.mediumS`
    font-weight: 400;
  `}
`

export default forwardRef(LandingPrice)
