import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as MapIcon } from 'assets/icon/map-fill-white.svg'

/**
 * 매물노트에서 지도 summary로 이동
 * @param {*} param0
 * @returns
 */
const GoToMap = () => {
  const history = useHistory()

  const { watch } = useFormContext()

  const address_id = watch('address_id')

  const goToMap = () => {
    if (!address_id) return
    history.push(`/${address_id}`)
  }

  return (
    address_id && (
      <Wrapper onClick={goToMap}>
        <MapIcon />
      </Wrapper>
    )
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 6px;

  svg {
    margin: 5px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`

export default GoToMap
