import styled from 'styled-components'
import NotePaymentAuthHeader from './NotePaymentAuthHeader'
import NotePaymentAuthButtons from './NotePaymentAuthButtons'
import NotePaymentAuthContainer from 'containers/notev2/NotePaymentAuthContainer'
import NotePaymentAuthForm from './NotePaymentAuthForm'

/**
 * 본인인증이 필요해요! 페이지 내용물
 * @param onClose 이전 화면으로 가기 func
 * @param prevButton 첫 화면 이전화면으로 가기 버튼 visible
 * @param paying 올인원 결제중 여부
 * @param callPaymentModal 결제 function
 * @param month 결제 월 수
 * @param price 결제 금액
 * @returns
 */
const NotePaymentAuthContent = ({
  onClose,
  callPaymentModal,
  prevButton = true,
  paying,
  widthLimit,
  month,
  price,
}) => {
  return (
    <NotePaymentAuthContainer>
      {({ isStart, startAuth, cancelAuth }) => {
        return (
          <Wrapper>
            {isStart ? (
              <>
                {/* 본인인증 시작하기 한 상황 - form */}
                <NotePaymentAuthForm
                  month={month}
                  price={price}
                  callPaymentModal={callPaymentModal}
                  cancelAuth={cancelAuth}
                  paying={paying}
                  widthLimit={widthLimit}
                  onClose={onClose}
                />
              </>
            ) : (
              //초기 진입
              <>
                <NotePaymentAuthHeader />
                <NotePaymentAuthButtons
                  onClose={onClose}
                  startAuth={startAuth}
                  prevButton={prevButton}
                />
              </>
            )}
          </Wrapper>
        )
      }}
    </NotePaymentAuthContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
`

export default NotePaymentAuthContent
