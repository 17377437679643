import AppContainer from './AppContainer'
import { SectionWrapper, SubTitle, Title } from './LandingStyle'
import styled, { keyframes } from 'styled-components'
import LogoSrc from 'assets/images/note/landing/together-logo.png'
import Together1ImgSrc from 'assets/images/note/landing/together-1.png'
import Together2ImgSrc from 'assets/images/note/landing/together-2.png'
import Together3ImgSrc from 'assets/images/note/landing/together-3.png'
import Together4ImgSrc from 'assets/images/note/landing/together-4.png'
import Together5ImgSrc from 'assets/images/note/landing/together-5.png'
import Together6ImgSrc from 'assets/images/note/landing/together-6.png'
import Together7ImgSrc from 'assets/images/note/landing/together-7.png'
import Together8ImgSrc from 'assets/images/note/landing/together-8.png'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useRef } from 'react'

const LandingCharacter2 = () => {
  const willAnimatesRef = useRef([])
  const wrapperRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            willAnimatesRef.current.forEach((elem) => {
              elem.classList.add('animate')
            })
          }
        })
      },
      {
        threshold: 0.2,
      },
    )

    observer.observe(wrapperRef.current)
  }, [])

  return (
    <SectionWrapper ref={wrapperRef}>
      <AppContainer>
        <LogoImg src={LogoSrc} />
        <Title>
          사진, 일정, 고객
          <br />
          모두 매물을 기준으로 연동
        </Title>
        <Content>
          <ContentLeft>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[0] = el)}
              order="1"
            >
              <ContentText order="1">
                <span>매물</span>을 기준으로
                <br />
                <span>직관적으로 연결</span>
              </ContentText>
              <ContentImg order="1" src={Together1ImgSrc} />
            </ContentWrapper>
          </ContentLeft>
          <ContentRight>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[1] = el)}
              order="2"
            >
              <ContentText order="2">
                <span>사진</span>
                <br />
                현장에서
                <br />
                찍고, 업로드
              </ContentText>
              <ContentImg order="2" src={Together2ImgSrc} />
            </ContentWrapper>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[2] = el)}
              order="3"
            >
              <ContentText order="3">
                <span>일정</span>
                <br />
                갱신일, 만기예정일
                <br />
                놓치지 않게 알림
              </ContentText>
              <ContentImg3 type="1" src={Together3ImgSrc} />
              <ContentImg3 type="2" src={Together4ImgSrc} />
              <ContentImg3 type="3" src={Together5ImgSrc} />
            </ContentWrapper>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[3] = el)}
              order="4"
            >
              <ContentText order="4">
                <span>고객</span>
                <br />
                매물을 중심으로
                <br />
                정렬
              </ContentText>
              <ContentSubText>
                <span>빌게이츠</span>님이 <span>집주인</span>으로 등록된
                매물 목록이에요
              </ContentSubText>
              <ContentImg4 type="1" src={Together6ImgSrc} />
              <ContentImg4 type="2" src={Together7ImgSrc} />
              <ContentImg4 type="3" src={Together8ImgSrc} />
            </ContentWrapper>
          </ContentRight>
        </Content>
      </AppContainer>
    </SectionWrapper>
  )
}

const floatAnimation = keyframes`
 	from {
		opacity: 0;
		transform: translateY(10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`

const LogoImg = styled.img`
  width: 142px;
  height: 54px;
  object-fit: contain;

  ${media.mediumS`
      width: 120px;
      height: auto;
      object-fit: contain;           
  `}
`

const Content = styled.div`
  display: flex;
  gap: 26px;
  width: 100%;
  margin-top: 80px;

  ${media.mediumM`
			flex-direction: column;
  `}

  ${media.mediumS`
			margin-top: 40px;
  `}
`

const ContentLeft = styled.div`
  width: 35%;

  ${media.mediumM`
      width: 100%;
  `}
`

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  ${media.mediumM`
    gap: 26px;
  `}
`

const ContentWrapper = styled.div`
  border-radius: 24px;
  font-weight: 700;
  color: ${theme.colors.base.white};
  overflow: hidden;
  padding: 45px 40px;
  opacity: 0;

  ${media.mediumS`
      padding: 30px 26px;     
  `}

  &.animate {
    opacity: 1;
    animation: ${floatAnimation} 0.8s ease 0.1s 1 normal backwards;
  }

  ${({ order }) => {
    switch (order) {
      case '1':
        return `
          position: relative;
          background: linear-gradient(
            113.78deg,
            #b85757 0%,
            #f8dec6 99.82%
          );
          box-shadow: inset -32px -12px 72px rgba(0, 0, 0, 0.02);
          height: 100%;

          @media (max-width: ${theme.bp.mediumM}px) {
              width: 100%;
              height: 360px;
              overflow: hidden; 
          }

        `
      case '2':
        return `
          position: relative;
          height: 262px;
          animation-delay: 0.3s !important;
          background: linear-gradient(
            83.7deg,
            #fad103 2.59%,
            #f45959 43.64%,
            #ee2bff 74.51%,
            #4c5cff 101.84%,
            #5564e3 101.85%
          );
          box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.04);

          @media (max-width: ${theme.bp.mediumS}px) {
            height: 350px;
          };
        `
      case '3':
        return `
          overflow: hidden;
          position: relative;
          height: 388px;
          animation-delay: 0.5s !important;
          background: linear-gradient(
            159.44deg,
            #ffeded 5.46%,
            rgba(228, 187, 204, 0.757) 47.83%,
            rgba(197, 140, 192, 0.7) 83.82%
          );
          
          @media (max-width: ${theme.bp.mediumS}px) {
            height: 350px;
          };
        `
      case '4':
        return `
          position: relative;
          height: 320px;
          animation-delay: 0.7s !important;
          background: linear-gradient(
            105.1deg,
            rgba(255, 220, 194, 0.3) 3.6%,
            #fff8b5 100%
          );
          box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.04);

          @media (max-width: ${theme.bp.mediumM}px) {
            height: 350px;
          };

        `
    }
  }};
`

const ContentText = styled.div`
  font-size: 28px;
  line-height: 140%;

  ${media.mediumM`
    font-size: 26px;
  `}

  ${media.mediumS`
    font-size: 20px;
  `}

  ${({ order }) => {
    switch (order) {
      case '1':
        return `
						& > span {
							&:first-child {
								text-decoration: underline;
								text-underline-position: under;
							}
						}
        `
      case '2':
        return `
          span {
							color: #483219;
						}
        `
      case '3':
        return `
						color: #bf8084;
						span {
							color: #6d393c;
						}
        `
      case '4':
        return `
          color: #8d96a1;
          span {
            color: ${theme.colors.base.black};
          }
        `
    }
  }};

  ${media.mediumM`
      word-break: keep-all;
  `}

  ${media.mediumS`
      font-size: 20px;
  `}
`

const ContentSubText = styled.div`
  color: ${theme.colors.base.black};
  position: absolute;
  top: 35px;
  right: 62px;
  font-size: 16px;
  font-weight: 500;

  ${media.mediumS`
    display: none;
  `}

  span {
    font-weight: 700;
  }
`

const ContentImg = styled.img`
  ${({ order, type }) => {
    switch (order) {
      case '1':
        return `
						position: absolute;
						right: 0;
						bottom: 10%;
						max-width: 90%;
						height: auto !important;
            
            @media (max-width: ${theme.bp.mediumM}px) {
							top: 40px;
							bottom: initial;
							width: 50%;
            }
            
            @media (max-width: ${theme.bp.mediumS}px) {
							top: 30%;
							right: 0;
							width: 100%;
            }
        `
      case '2':
        return `
						max-width: 390px;
						position: absolute;
            top: 20px;
						right: 42px;
						object-fit: contain !important;
            
            @media (max-width: ${theme.bp.mediumS}px) {
              right: initial;
							bottom: -46px;
              top: initial;
              left: 26px;
            }
        `
    }
  }};
`

const ContentImg3 = styled.img`
  position: absolute;
  right: 52px;
  bottom: -20px;
  object-fit: contain !important;
  height: auto !important;

  ${({ type }) => {
    switch (type) {
      case '1':
        return `
          max-width: 314px;
          object-fit: contain;
          bottom: 0;
          right: 38px;
          filter: drop-shadow(
            0px 0px 15px rgba(0, 0, 0, 0.1)
          );

          @media (max-width: ${theme.bp.mediumM}px) {
            max-width: 270px;
            right: 20px;
          }

          @media (max-width: ${theme.bp.mediumS}px) {
              bottom: initial;
              top: 40%;
              right: initial;
              left: 20px;
          }
        `
      case '2':
        return `
          max-width: 387px;
          object-fit: contain;
          left: 18%;
          bottom: 62px;
        `
      case '3':
        return `
          max-width: 387px;
          object-fit: contain;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);

          @media (max-width: ${theme.bp.mediumS}px) {
            display: none;
            left: initial;
            transform: none;
          }
        `
    }
  }};
`

const ContentImg4 = styled.img`
  position: absolute;
  right: 52px;
  bottom: -20px;
  object-fit: contain !important;
  height: auto !important;

  ${({ type }) => {
    switch (type) {
      case '1':
        return `
          max-width: 348px;
          right: 33px;
          top: 78px;
          filter: drop-shadow(
            0px 0px 15px rgba(0, 0, 0, 0.1)
          );

          @media (max-width: ${theme.bp.mediumM}px) {
            max-width: 300px;
          }

          @media (max-width: ${theme.bp.mediumS}px) {
            max-width: 300px;
            right: 10px;
            left: initial;
            top: initial;
            bottom: 130px;
          }
        `
      case '2':
        return `
          max-width: 348px;
          right: 33px;
          bottom: 11px;
          filter: drop-shadow(
            0px 0px 15px rgba(0, 0, 0, 0.1)
          );

          @media (max-width: ${theme.bp.mediumM}px) {
            max-width: 300px;
          }

          @media (max-width: ${theme.bp.mediumS}px) {
            right: 10px;
            left: initial;
            bottom: 30px;
          }
        `
      case '3':
        return `
          max-width: 332px;
          height: auto;
          object-fit: contain;
          left: 60px;
          bottom: 0;

          @media (max-width: ${theme.bp.mediumS}px) {
            max-width: 280px;
            left: 30px;
          }
        `
    }
  }};
`

export default LandingCharacter2
