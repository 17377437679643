import MapFilterBuildingSelector from 'components/map/MapFilter/MapFilterBuildingSelector'
import MapFilterBuilding from 'components/map/MapFilter/MapFilterBuilding'
import useMapFilter from './hooks/useMapFilter'
import MapContext from 'contexts/map'
import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

const MapFilterBuildingContainer = () => {
  const {
    state: { layerState },
    actions,
  } = useContext(MapContext)
  const { filterState } = useMapFilter()

  const filterOptions = useSelector((state) => state.filter.options)
  /**
   * 건물 종류 클릭 이벤트
   */
  const togglePropertyType = (changeType) => {
    actions.setFilterState((prev) => {
      let value = { ...prev }
      let prevPropertyType = prev.building.property_type

      if (!layerState.building) {
        // 필터 변경 시 건물 레이어 활성화
        actions.setLayerState((prev) => {
          return {
            ...prev,
            building: true,
          }
        })
      }

      // 전체 선택 시 초기화
      if (changeType === 'all') {
        value.building.property_type = []
        return value
      }

      // 이미 배열에 있을 경우 제거하고 없다면 추가
      if (prevPropertyType.find((e) => e === changeType)) {
        // 배열 초기화
        value.building.property_type = []

        /**
         * map을 쓰면 빈 리턴도 배열 안에 undefined를 추가하기 때문에
         * foreach를 사용하여 수동으로 push해줌.
         */
        prevPropertyType.forEach((item) => {
          if (item === changeType) return
          return value.building.property_type.push(item)
        })
      } else {
        value.building.property_type.push(changeType)
      }

      return value
    })
  }

  return (
    <>
      <MapFilterBuildingSelector
        options={filterOptions.building_type}
        value={filterState.building.property_type || []}
        togglePropertyType={togglePropertyType}
      />
      <MapFilterBuilding />
    </>
  )
}

export default MapFilterBuildingContainer
