import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'

const NoteDetailGongbuIssuanceNoti = () => {
  return (
    <>
      <DisplayText data-tooltip-id="note-detail-gongbu-noti-tooltip">
        <i className="fas fa-question-circle" />
        <span>주소 검색 결과가 없나요?</span>
      </DisplayText>
      <Tooltip
        opacity={1}
        id="note-detail-gongbu-noti-tooltip"
        border={`1px solid ${theme.colors.gray[300]}`}
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.03)`,
          zIndex: 9999,
          padding: '18px 16px',
          width: '340px',
          opacity: 1,
        }}
      >
        <Title>
          주소 검색 결과가 없나요?
          <br />
          이렇게도 검색해보세요!
        </Title>
        <Example>예) 101동 10층 1001호</Example>
        <List>
          <ListItem>
            <Tag color="gray">검색단어1</Tag> 101동
          </ListItem>
          <ListItem>
            <Tag color="gray">검색단어3</Tag> 10층
          </ListItem>
          <ListItem>
            <Tag color="gray">검색단어3</Tag> 1001호
          </ListItem>
        </List>
      </Tooltip>
    </>
  )
}

const DisplayText = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${theme.colors.gray[400]};
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  cursor: pointer;
  z-index: 999;
`

const Title = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};
`

const Example = styled.div`
  margin-bottom: 12px;
  padding: 7px 12px;
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const ListItem = styled.div`
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};
`

const Tag = styled.div`
  padding: 3px 4px;
  background-color: ${theme.colors.gray[100]};
  font-size: 11px;
  font-weight: 700;
  line-height: 150%;
  color: ${theme.colors.gray[600]};
`

export default NoteDetailGongbuIssuanceNoti
