import { useCallback, useEffect, useState } from 'react'
import { Button } from 'components/design'
import { Form } from 'components/design'
import { FormItem } from 'components/design'
import { Select } from 'components/design'
import { Input } from 'components/design'

import { saveChannelUser } from 'lib/api/note/channelApi'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useChannelUsers from './hooks/useChannelUsers'
import { message } from 'antd'
import useAxios from 'lib/hooks/useAxios'

const InviteSaveContainer = ({ onClose }) => {
  const { channelSrl } = useNoteMatch()
  const [phoneNumber, setPhoneNumber] = useState('') // 전화번호 입력값
  const [memberType, setMemberType] = useState('readwrite') // 권한 설정

  const [onRequest, loading, data, error, reset] =
    useAxios(saveChannelUser)
  const { _getChannelUsers } = useChannelUsers(channelSrl)

  useEffect(() => {
    if (!data) return

    console.log('save channelUser Result: ', data)

    _getChannelUsers()
    message.info(`초대 메시지가 전송되었습니다.`)
    onClose()
  }, [data])

  // useEffect(() => {
  //   if (!error) return

  //   notification.error({ message: error })
  // }, [error])

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      await onRequest({ channelSrl, phoneNumber, memberType })
    },
    [onRequest, phoneNumber, memberType],
  )

  return (
    <>
      <Form onSubmit={onSubmit}>
        <FormItem label="전화번호" noContainer>
          <Input
            type="tel"
            expand
            name="phone_number"
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="전화번호 입력"
          />
        </FormItem>

        <FormItem label="권한" noContainer>
          <Select
            expand
            value={memberType}
            onChange={(e) => setMemberType(e.target.value)}
          >
            <option value="readwrite">읽기, 쓰기</option>
            <option value="readonly">읽기만 가능</option>
            <option value="manager">관리</option>
          </Select>
        </FormItem>
      </Form>

      <Button type="submit" expand color="primary" onClick={onSubmit}>
        초대하기
      </Button>
    </>
  )
}

export default InviteSaveContainer
