import { useParams, useHistory } from 'react-router-dom'
import { Button } from 'components/design'
import { notification } from 'antd'
import { useSelector } from 'react-redux'

const SummarySizeReviewButton = ({ buildingType }) => {
  const params = useParams()
  const history = useHistory()

  const isCallSizeReview = useSelector(
    (state) => state.summary.isCallSizeReview,
  )

  const linkTo = () => `/${params?.id}/sizeReview/${buildingType}`

  return (
    <Button
      type="button"
      size="xlarge"
      color="primary"
      onClick={() => {
        history.push(linkTo())
      }}
      disabled={
        !isCallSizeReview &&
        params?.external &&
        params?.external === 'sizeReview'
      }
      expand
    >
      {'신축 수익률 계산하기'}
    </Button>
  )
}

export default SummarySizeReviewButton
