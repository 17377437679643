import HeaderIcon from 'components/common/Header/HeaderIcon'
import { setSidemenuVisible } from 'modules/layout'
import { useDispatch } from 'react-redux'

const BaseHeaderNav = () => {
  const dispatch = useDispatch()

  const onOpen = () => {
    dispatch(setSidemenuVisible(true))
  }

  return (
    <>
      <HeaderIcon onClick={onOpen}>
        <i className="far fa-bars" />
      </HeaderIcon>
    </>
  )
}

export default BaseHeaderNav
