import {
  MainWrapper,
  MaxWidthWrapper,
} from 'components/jmapnotev2/style/NoteStyle'
import { useEffect } from 'react'
import { getPayment } from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import NotePaymentResult from './NotePaymentResult'
import { Skeleton } from 'antd'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const NotePaymentSuccess = () => {
  const [onRequestGetPayment, loadingPaymnet, payment] =
    useAxios(getPayment)

  const location = useLocation()

  useEffect(() => {
    const index = location?.pathname?.indexOf(
      '/',
      '/note/v2/payment/success'.length - 1,
    )
    const paymentSrl = location.pathname.substr(index + 1)

    onRequestGetPayment({
      payment_srl: paymentSrl,
    })
  }, [])

  return (
    <MainWrapper gray>
      <InnerWrapper>
        {loadingPaymnet ? (
          <Skeleton />
        ) : payment && payment.result ? (
          <NotePaymentResult isSuccess result={payment.result} />
        ) : (
          <NotePaymentResult isError />
        )}
      </InnerWrapper>
    </MainWrapper>
  )
}

const InnerWrapper = styled(MaxWidthWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 0;
  text-align: center;
  background-color: ${theme.colors.base.white};

  .ant-spin-text {
    margin-top: 10px;
  }
`

export default NotePaymentSuccess
