import SettingsContainer from 'containers/note/settings/SettingsContainer'
import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import useChannelPermission from 'containers/note/channel/hooks/useChannelPermission'

const SettingsPage = () => {
  const permission = useChannelPermission()

  if (permission === 'readonly') return null

  return (
    <ExternalLayer shift title="설정" hasAdditionalHeader>
      <SettingsContainer />
    </ExternalLayer>
  )
}

export default SettingsPage
