import styled from 'styled-components'

const StyledButtonIcon = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-left: 0.75rem;
  margin-right: -0.25rem;

  & > i {
    font-size: 1.25rem;
  }
  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  ${({ size }) =>
    size === 'small' &&
    `
    & > i {
      font-size: 1rem;
    }
    & > svg {
      width: 1rem;
      height: 1rem;
    }
  `}

  ${({ size }) =>
    size === 'xs' &&
    `
    & > i {
      font-size: 0.875rem;
    }
    & > svg {
      width: 0.875rem;
      height: 0.875rem;
    }
  `}
`

const ButtonIcon = ({ children, size }) => (
  <StyledButtonIcon size={size}>{children}</StyledButtonIcon>
)

export default ButtonIcon
