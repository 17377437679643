import { useCallback, useEffect, useState } from 'react'

import { Modal } from 'components/design'
import NoteApplyButton from 'components/jmapnote/noteApply/NoteApplyButton'
import { saveAddress } from 'lib/api/note/addressApi'
import NoteApplyForm from 'components/jmapnote/noteApply/NoteApplyForm'
import NoteApplySave from 'components/jmapnote/noteApply/NoteApplySave'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import moment from 'moment'
import { getSummary } from 'lib/api/summaryApi'
import { setIsOpenNoteExternal, setSummaryInfo } from 'modules/summary'
import Log from 'lib/utils/Log'
import useAxios from 'lib/hooks/useAxios'
import FirstUserPopup from 'components/jmapnote/channel/FirstUserPopup'

const NoteApplyContainer = ({ small, note, land }) => {
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )
  const [channelSrl, setChannelSrl] = useState('')
  const [folderSrl, setFolderSrl] = useState('')
  const [addressSrl, setAddressSrl] = useState('')
  const [propertyType, setPropertyType] = useState('')

  const [modalVisible, setModalVisible] = useState(false)
  const [saveVisible, setSaveVisible] = useState(false)

  const [onRequest, loading, data, error, reset] = useAxios(saveAddress)

  const channels = useSelector((state) => state.channel.channels)

  const [firstUserPopupVisible, setFirstUserPopupVisibie] =
    useState(false)

  useEffect(() => {
    if (modalVisible) {
      initDefaultValue()
    }
  }, [modalVisible])

  const initDefaultValue = () => {
    const recentChannelSrl = localStorage.getItem('recentChannelSrl')
    const recentFolderSrl = localStorage.getItem('recentFolderSrl')

    if (recentChannelSrl) setChannelSrl(recentChannelSrl)
    if (recentFolderSrl) setFolderSrl(recentFolderSrl)
  }

  useEffect(() => {
    if (!data) return

    // console.log('test ', data)

    setAddressSrl(data?.address_srl)
    setModalVisible(false)
    setSaveVisible(true)
    setPropertyType(data?.result?.property_type)

    dispatch(setIsOpenNoteExternal(true))
  }, [data])

  const onCancel = useCallback(() => {
    setModalVisible(false)
    setChannelSrl('')
    setFolderSrl('')
  })

  // const isSubmitDisabled = useCallback(() => {
  //   return channelSrl && folderSrl ? false : true
  // }, [channelSrl, folderSrl])

  const onClickButton = useCallback((e) => {
    // 사용자가 생성한 채널이 없을 경우 채널 생성 팝업 출력
    if (channels.length === 0) {
      setFirstUserPopupVisibie(true)
      return
    }

    showAddModal()
  })

  const showAddModal = () => {
    setModalVisible(true)
  }

  const getRequestParams = useCallback(() => {
    return {
      channelSrl,
      folderSrl,
      addressId: currentAddressId,
    }
  }, [channelSrl, folderSrl, currentAddressId])

  const handleSaveAddress = async () => {
    if (!channelSrl) {
      message.info('채널을 선택해 주세요.')
      return
    }
    await onRequest(getRequestParams())
  }

  const dispatch = useDispatch()
  const [onRequestSummary, , summaryData, ,] = useAxios(getSummary)

  /** update summary data */
  useEffect(() => {
    if (!summaryData || summaryData?.message !== 'success') return

    Log.success('getSummary', summaryData.result)

    dispatch(setSummaryInfo(summaryData.result))
  }, [summaryData])

  return (
    <>
      <Modal
        visible={modalVisible}
        title="매물노트에 추가"
        // expandedSubmitButton
        // okButtonProps={{
        //   disabled: isSubmitDisabled(),
        // }}
        onCancel={() => onCancel()}
        onOk={() => {
          handleSaveAddress()

          if (data) {
            message.success('매물노트에 해당 주소가 추가되었습니다.')
          }
        }}
      >
        <NoteApplyForm
          channelSrl={channelSrl}
          folderSrl={folderSrl}
          setChannelSrl={setChannelSrl}
          setFolderSrl={setFolderSrl}
        />
      </Modal>

      <NoteApplyButton
        small={small}
        note={note}
        land={land}
        onClick={(e) => onClickButton(e)}
      />

      {saveVisible && (
        <NoteApplySave
          channelSrl={channelSrl}
          folderSrl={folderSrl}
          addressSrl={addressSrl}
          propertyType={propertyType}
          onClose={() => {
            setSaveVisible(false)

            dispatch(setIsOpenNoteExternal(false))
            // favorite?.count 값 변경으로 인해 summary 조회 요청
            onRequestSummary({
              address_id: currentAddressId,
              min_year: moment().get('year') - 9,
            })
          }}
        />
      )}
      <FirstUserPopup
        visible={firstUserPopupVisible}
        setVisible={setFirstUserPopupVisibie}
        onSuccess={showAddModal}
        onClose={() => setFirstUserPopupVisibie(false)}
      />
    </>
  )
}

export default NoteApplyContainer
