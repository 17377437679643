import axios from 'axios'

/**
 * 무작위 초대 id 요청
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getReferral = async ({ _rx_csrf_token }, options) =>
  await axios.post(
    '/jmapnote/api/referral',
    {
      _rx_csrf_token,
    },
    options,
  )

/**
 * 초대 코드로 초대자 정보 가져오기
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getReferralByCode = async (
  { _rx_csrf_token, referral_code },
  options,
) =>
  await axios.post(
    `/jmapnote/api/getReferralByCode`,
    {
      _rx_csrf_token,
      referral_code,
    },
    options,
  )

/**
 * 로그인한 경우 referral_code insert
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const insertReferralMatch = async (
  { _rx_csrf_token, referral_code },
  options,
) =>
  await axios.post(
    `/jmapnote/api/referral/match`,
    {
      _rx_csrf_token,
      referral_code,
    },
    options,
  )
