import theme, { media } from 'lib/styles/theme'
import { throttle } from 'lodash'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import NoteAdFormMobileHeader from '../NoteAdFormMobileHeader'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteAdFormFixedTitle from './NoteAdFormFixedTitle'

const NoteAdFormTitle = ({}, scrollRef) => {
  const ref = useRef(null)
  const isMobile = useIsMobile()
  const { watch } = useFormContext()

  const [isSticky, setIsSticky] = useState(false)

  const creator_name = watch('creator_name')
  const status = watch('status')

  const history = useHistory()

  const onClickGoBack = () => {
    history.goBack()
  }

  function getIsSticky(scrollTarget) {
    const header = ref?.current
    const scrollTop = scrollTarget.scrollTop

    if (!header?.clientHeight) return

    if (scrollTop >= header.clientHeight) {
      !isSticky && setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }

  useEffect(() => {
    if (!scrollRef?.current?.view) return
    scrollRef.current.view.addEventListener(
      'scroll',
      throttle(() => getIsSticky(scrollRef.current.view), 100),
    )
  }, [scrollRef?.current])

  if (isMobile)
    return (
      <NoteAdFormMobileHeader>
        <NoteAdFormFixedTitle
          onClickGoBack={onClickGoBack}
          status={status}
        />
      </NoteAdFormMobileHeader>
    )
  else
    return (
      <Wrapper sticky={isSticky} ref={ref}>
        <NoteAdFormFixedTitle
          onClickGoBack={onClickGoBack}
          status={status}
        />
        <Writer>
          <span>작성자</span>
          <span>{creator_name}</span>
        </Writer>
      </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding-top: 12px;
  padding-bottom: 16px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: -40px;
  margin-right: -40px;
  background: ${theme.colors.base.white};
  z-index: 1;

  ${({ sticky }) =>
    sticky &&
    `
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  `}

  ${media.mediumM`
    padding-top: 0;
    ${({ sticky }) =>
      sticky &&
      `
        padding-top: 14px;
        padding-bottom: 14px;
  `}
  `}
`

const Writer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    font-size: 14px;
    line-height: 150%;
    &:first-child {
      font-weight: 500;
    }
    &:last-child {
      font-weight: 600;
      color: ${theme.colors.gray[600]};
    }
  }
`
export default forwardRef(NoteAdFormTitle)
