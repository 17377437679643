import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import theme from 'lib/styles/theme'

const StyledMarker = styled.div`
  color: ${theme.colors.primary[400]};
  font-size: 1.25rem;

  /* ${({ isCenter }) =>
    isCenter &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;

    background-color: ${rgba(theme.colors.primary[400], 0.3)};
    border: 1px solid ${rgba(theme.colors.primary[500], 0.4)};
    border-radius: 100%;
  `} */
`

const CenterIcon = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${theme.colors.primary[400]};
  border: 3px solid ${theme.colors.base.white};
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 16px;
`

const MarkerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;

  color: ${({ color }) => color};
  font-size: 0.75rem;

  border-radius: 100%;
  background-color: ${theme.colors.base.white};
  border: 1px solid ${({ color }) => color};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);

  ${({ isSelected, color }) =>
    isSelected &&
    `
    color: white;
    background-color: ${color};
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.6);
  `}

  svg {
    width: 1rem;
    height: 1rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: -1px;
    margin-left: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: ${({ color }) => color} transparent transparent
      transparent;
  }
`

const MarkerIconInner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  border: 2px solid white;
  background-color: ${({ color }) => rgba(color, 0.1)};
  border-radius: 100%;

  ${({ isSelected, color }) =>
    isSelected &&
    `
    border-color: ${color};
    background-color: ${color};
  `}
`

const getMarkerColor = (propertyType) => {
  switch (propertyType) {
    case '토지':
      return '#e0a40c'
    case '단독':
      return '#ee553d'
    default:
      return '#247bfc'
  }
}

const getMarkerIcon = (propertyType) => {
  switch (propertyType) {
    case '토지':
      return <i className="far fa-expand"></i>
    case '단독':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
      )
    default:
      return <i className="far fa-store-alt"></i>
  }
}

const SummaryNearbyMarker = ({
  map,
  position,
  propertyType,
  isCenter,
  isSelected,
  onClickItem,
}) => {
  const { kakao } = window
  const markerRef = useRef()

  useEffect(() => {
    let payload = {
      map,
      clickable: false,
      position,
      content: markerRef.current,
      zIndex: isSelected ? 3 : isCenter ? 2 : 1,
    }
    const marker = new kakao.maps.CustomOverlay(payload)
    // 마커를 맵에 그림
    marker.setMap(map)

    const drawingCircle = new kakao.maps.Circle({
      strokeWeight: 1, // 선의 두께입니다
      strokeColor: theme.colors.primary[500], // 선의 색깔입니다
      strokeOpacity: 0.4, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
      strokeStyle: 'solid', // 선의 스타일입니다
      fillColor: theme.colors.primary[400], // 채우기 색깔입니다
      fillOpacity: 0.2, // 채우기 불투명도입니다
    })

    const circleOptions = {
      center: position,
      radius: 500,
    }

    if (isCenter) {
      drawingCircle.setOptions(circleOptions)
      drawingCircle.setMap(map)
    }

    return () => {
      marker.setMap(null)
      drawingCircle.setMap(null)
    }
  }, [map])

  return (
    <StyledMarker ref={markerRef} isCenter={isCenter}>
      {isCenter ? (
        <CenterIcon />
      ) : (
        <MarkerIcon
          isSelected={isSelected}
          color={getMarkerColor(propertyType)}
        >
          <MarkerIconInner
            isSelected={isSelected}
            color={getMarkerColor(propertyType)}
          >
            {getMarkerIcon(propertyType)}
          </MarkerIconInner>
        </MarkerIcon>
      )}
    </StyledMarker>
  )
}

export default SummaryNearbyMarker
