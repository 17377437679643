import { getMyDeunggibuList } from 'lib/api/notev2/dashboard/dashboard'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useMemo, useState } from 'react'

/**
 * 매물노트 - 대시보드
 * 등기부등본
 */
const NoteDashboardDeunggibuContainer = ({ children }) => {
  const [onRequest, , data] = useAxios(getMyDeunggibuList)
  const [list, setList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const count = 8
  const limit = 8

  useEffect(() => {
    onRequest()
  }, [])

  // limit 만큼만 목록을 출력하도록 합니다.
  const getLimitedData = (data, currentPage) => {
    return data.slice(
      currentPage * count - count < 0 ? 0 : currentPage * count - count,
      currentPage === 0 ? limit : currentPage * limit,
    )
  }

  const limitedData = useMemo(
    () => getLimitedData(list, currentPage),
    [list, currentPage],
  )

  useEffect(() => {
    if (!data) return
    setList(data?.result || [])
  }, [data])

  return children({
    limitedData,
    list,
    currentPage,
    setCurrentPage,
    count,
  })
}

export default NoteDashboardDeunggibuContainer
