import theme from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * <Star className="fas fa-star" />
 */
export const Star = styled.i`
  font-size: 12px;
  color: ${theme.colors.yellow[400]};
`
