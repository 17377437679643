import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import RadioButton from '../../elements/RadioButton'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 층 노출 집주인 동의여부
 * 아파트, 오피스텔, 분양권, 방
 */
const NoteAdColumnFloorAgree = ({ half = true }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const floorType = watch('article.floorType')
  const { onUpdate } = useAdUpdate()

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>층 노출 집주인 동의여부</ColumnTitle>
      <ColumnVal gap="0 12">
        <RadioButton
          size="small"
          label="동의 (층 노출)"
          checked={floorType == 1}
          value={1}
          name="floor_type_agree"
          {...register('article.floorType')}
          onChange={(e) => {
            onUpdate('article.floorType', e.target.value)
          }}
        />
        <RadioButton
          size="small"
          value={2}
          checked={floorType == 2}
          label="미동의 (고/중/저 노출)"
          name="floor_type_agree"
          {...register('article.floorType')}
          onChange={(e) => {
            onUpdate('article.floorType', e.target.value)
          }}
        />
        <ValidateWarn>
          {errors?.article?.floorType?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFloorAgree
