import PaymentMemberBanner from 'assets/images/features/member-banner.png'
import HomeFeatureItem from './HomeFeatureItem'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NoteInviteRewardModal from 'components/jmapnotev2/NoteInviteReward/NoteInviteRewardModal'
import moment from 'moment'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

const HomeFeatureInviteReward = () => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const { hasPlan } = useNoteHasPlan()
  const [isChargedMember, setIsChargedMember] = useState(false)
  const [isPlanLoading, setIsPlanLoading] = useState(false)
  const [inviteRewardVisible, setInviteRewardVisible] = useState(false)

  useEffect(() => {
    if (!hasPlan) {
      setIsChargedMember(false)
      return
    }

    setIsPlanLoading(true)

    if (planInfo?.payment_name == '프리') {
      setIsChargedMember(false)
      return
    }

    const diff = moment(
      planInfo.next_payment_date,
      'YYYYMMDDHHmmss',
    ).diff(moment(), 'milliseconds')

    //요금제 기간 다 되어도 return
    if (diff < 0) {
      setIsChargedMember(false)
      return
    }

    //등본 남은 개수 없어도 return
    if (planInfo.remain_count < 0) {
      setIsChargedMember(false)
      return
    }

    if (hasPlan) {
      setIsChargedMember(true)
    }
  }, [hasPlan])

  const onClickInvite = () => {
    setInviteRewardVisible(true)
  }

  return (
    <>
      {isPlanLoading && isChargedMember && (
        <HomeFeatureItem
          background={PaymentMemberBanner}
          onClick={onClickInvite}
        />
      )}

      <NoteInviteRewardModal
        visible={inviteRewardVisible}
        onClose={() => setInviteRewardVisible(false)}
      />
    </>
  )
}

export default HomeFeatureInviteReward
