import React from 'react'
import LoginForm from 'components/login/LoginForm'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginVisible } from 'modules/auth'
import RewardAuthModalContainer from './RewardAuthModalContainer'

const AuthModalContainer = () => {
  const loginVisible = useSelector((state) => state.auth.loginVisible)
  const dispatch = useDispatch()

  return (
    <RewardAuthModalContainer>
      {({ loginCallback, isEndRewardSign }) =>
        !isEndRewardSign && (
          <LoginForm
            visible={loginVisible}
            onCancel={() => dispatch(setLoginVisible(false))}
            callbackFn={loginCallback}
          />
        )
      }
    </RewardAuthModalContainer>
  )
}

export default React.memo(AuthModalContainer)
