import { Button, Card, CardBody, Empty } from 'components/design'

const ErrorView = ({ error, fetchData, msg }) => {
  return (
    <Card noMargin>
      <CardBody>
        <Empty>
          {msg ? msg : '일시적인 오류입니다. 새로고침 해주세요.'}
          {fetchData && (
            <Button onClick={() => fetchData()}>새로고침</Button>
          )}
        </Empty>
      </CardBody>
    </Card>
  )
}

export default ErrorView
