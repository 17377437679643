import theme from 'lib/styles/theme'
import styled from 'styled-components'
import Icon from 'assets/images/jmapnote-icon.png'
import useIsMobile from 'lib/hooks/useIsMobile'

const StyledBanner = styled.a`
  display: flex;
  align-items: center;
  /* padding: 1rem 1.25rem; */
  padding-left: 1.5rem;
  padding-right: 0.5rem;

  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 700;

  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[100]};
  background-color: ${theme.colors.gray[50]};

  &:hover {
    border-color: ${theme.colors.gray[200]};
    background-color: ${theme.colors.gray[50]};
  }
`

const StyledBody = styled.div`
  flex: 1;
`

const StyledSubtitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 6px;
`

const StyledTitle = styled.div`
  & > span {
    margin-right: 0.5rem;
  }
`

const StyledImg = styled.img`
  flex: 0 0 auto;
  width: 82px;
  height: 82px;
`

const HomeNoteBanner = () => {
  const isMobile = useIsMobile()

  return (
    <StyledBanner
      href="https://map.jootek.com"
      target={isMobile ? '_self' : ''}
    >
      <StyledBody>
        <StyledSubtitle>부동산 전문가라면?</StyledSubtitle>
        <StyledTitle>
          <span>엑셀보다 쉬운 매물노트</span>
        </StyledTitle>
      </StyledBody>

      <StyledImg src={Icon} alt="주택 매물노트" />
    </StyledBanner>
  )
}

export default HomeNoteBanner
