import { forwardRef } from 'react'
import { TabProvider } from './context/TabContext'
import TabContainer from './TabContainer'

const Tab = (
  {
    inverse,
    size,
    border = true,
    children,
    className,
    fixed,
    noContainer,
  },
  ref,
) => {
  return (
    <TabProvider>
      <TabContainer
        ref={ref}
        className={className}
        inverse={inverse}
        size={size}
        border={border}
        fixed={fixed}
        noContainer={noContainer}
      >
        {children}
      </TabContainer>
    </TabProvider>
  )
}

export default forwardRef(Tab)
