import styled from 'styled-components'
import { useCallback, useRef } from 'react'
import { onlyUnique } from 'utils'
import priceFormat from 'lib/utils/priceFormat'
import {
  CustomSingleCellInputWrapper,
  CustomSingleCellStyle,
} from 'components/jmapnotev2/style/NoteStyle'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import {
  addEndCursorKeyEvent,
  addSelectAllKeyEvent,
  addStartCursorKeyEvent,
} from 'lib/utils/notev2/gridUtil'
import theme from 'lib/styles/theme'
import { NumericFormat } from 'react-number-format'
import { ActiveCellStyle } from 'components/jmapnotev2/style/NoteGridStyle'
import useModifyAd from 'lib/hooks/notev2/useModifyAd'

/**
 * 광고관리 그리드
 * 가격 입력칸
 */
const CustomPriceCellItem = ({
  name,
  value: pValue,
  node,
  oriContractType,
  contractType,
  isPresale,
}) => {
  const [onModify] = useModifyAd()
  const wrapperRef = useRef(null)

  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
  } = useCustomSingleCell(node?.data?.status)

  const onBlur = useCallback(
    (value) => {
      const contract_type = oriContractType || ''

      if (value && value != 0) {
        const filteredContractType = contract_type
          .split(',')
          .concat(contractType)
          .filter((item) => item)

        const newData = {
          [name.replace('jeonse_', '')]: String(value).replaceAll(
            ',',
            '',
          ),
        }

        onModify({
          data: node.data,
          updateData: newData,
          currentRowNode: node,
        })
      } else {
        //값이 없을때는 삭제
        const filteredContractType = contract_type
          .split(',')
          .filter((item) => item != contractType)

        const newData = {
          contract_type: filteredContractType
            .filter(onlyUnique)
            .toString(),
          [name]: 0,
        }
        onModify({
          data: node.data,
          updateData: newData,
          currentRowNode: node,
        })
      }

      setIsEditable(false)
    },
    [pValue, node.data, contractType, oriContractType],
  )

  const onKeyDownInput = (e) => {
    addSelectAllKeyEvent(e)
    addStartCursorKeyEvent(e)
    addEndCursorKeyEvent(e)
  }

  return (
    <Wrapper
      ref={wrapperRef}
      tabIndex={0}
      onDoubleClick={onDoubleClickSingleCell}
      onKeyDown={onKeyDownSingleCell}
      fullheight={name == 'deposit' || name == 'rent'}
      isDeposit={name == 'deposit'}
      name={name}
    >
      {isEditable ? (
        <CustomSingleCellInputWrapper>
          <PriceListItem
            autoFocus={true}
            type="text"
            name={name}
            onBlur={(e) => onBlur(e.target.value)}
            defaultValue={pValue}
            thousandSeparator={true}
            inputMode="decimal"
            onKeyDown={onKeyDownInput}
            onFocus={(e) => {
              if (e.target?.value == 0) {
                e.target?.select()
              }
            }}
          />
          <PriceTag isVisible>만</PriceTag>
        </CustomSingleCellInputWrapper>
      ) : (
        <PriceView isDeposit={name == 'deposit'}>
          {priceFormat(
            String(pValue).replaceAll(',', '') * 10000,
            true,
          ) == '0원'
            ? ''
            : priceFormat(
                String(pValue).replaceAll(',', '') * 10000,
                true,
              )}
        </PriceView>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  opacity: 0;
  bottom: 0;
  background-color: transparent;
  line-height: 100%;
  z-index: -1;
  text-align: center;
  display: flex;
  position: relative;
  opacity: 1;
  z-index: 1;

  ${({ fullheight }) =>
    fullheight &&
    ` 
      position: relative; 
      width: calc(50% - 2.5px); 
      height: 100%; 
      text-align: left;
    `};

  ${({ isDeposit }) => isDeposit && 'justify-content: flex-end;'};

  ${CustomSingleCellStyle};
`

const PriceView = styled.div`
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isDeposit }) => isDeposit && 'width: auto;'}
`

const PriceListItem = styled(NumericFormat)`
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 15px;
  font-size: 14px;
  line-height: 100%;
  background-color: transparent;

  ${CustomSingleCellStyle}
  ${ActiveCellStyle}
`

const PriceTag = styled.div`
  display: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  z-index: 1;
  line-height: 100%;
  ${({ isVisible }) => isVisible && `display: block;`}
`

export default CustomPriceCellItem
