import { useState } from 'react'
import theme from 'lib/styles/theme'
import { Empty } from 'components/design'
import PagingWithNumber from 'components/design/Paging/PagingWithNumber'
import NoteAdNoticeListContainer from 'containers/notev2/NoteAdNoticeListContainer'
import moment from 'moment'
import styled from 'styled-components'
import { ReactComponent as EmptyIcon } from 'assets/icon/note/ad/nodata.svg'

/**
 * 공지사항 페이지
 * 목록
 */
const NoteNoticeList = () => {
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <NoteAdNoticeListContainer>
      {({ listData }) => {
        return (
          <>
            {listData.length == 0 && (
              <Empty icon={<EmptyIcon />}>공지사항이 없습니다.</Empty>
            )}
            <List>
              {listData.length > 0 &&
                listData.map(({ variables }, i) => {
                  return (
                    <ListItem key={i}>
                      <ListTitleWrapper>
                        {variables.is_notice == 'Y' && <Tag>공지</Tag>}
                        <ListTitle>{variables.title}</ListTitle>
                        {moment().diff(
                          moment(variables.regdate, 'YYYYMMDDHHmmss'),
                          'day',
                        ) < 10 && <NewBadge />}
                      </ListTitleWrapper>
                      <Date>
                        {variables.regdate &&
                          moment(
                            variables.regdate,
                            'YYYYMMDDHHmmss',
                          ).format('YY.MM.DD')}
                      </Date>
                    </ListItem>
                  )
                })}
            </List>

            {listData.length > 0 && (
              <PagingWithNumber
                data={listData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPage={5}
              />
            )}
          </>
        )
      }}
    </NoteAdNoticeListContainer>
  )
}

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px;
`

const ListTitleWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

const ListTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
`

const NewBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: -6px;
  background-color: ${theme.colors.tint.danger};
  border-radius: 50%;

  &::after {
    content: 'N';
    color: ${theme.colors.base.white};
    font-size: 10px;
    font-weight: 700;
  }
`

const Date = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`

const Tag = styled.div`
  display: flex;
  min-width: 55px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  color: ${theme.colors.primary[400]};
  background-color: ${theme.colors.primary[100]};
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
`

export default NoteNoticeList
