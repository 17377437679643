import { message } from 'antd'
import NoteInviteJoinOrLoginWelcomeModal from 'components/jmapnotev2/NoteInviteReward/NoteInviteJoinOrLoginWelcomeModal'
import LoginForm from 'components/login/LoginForm'
import SignupAgree from 'components/login/SignupAgree'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import {
  setInviteeInfo,
  setIsEndSignModalVisible,
} from 'modules/inviteReward'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const RewardAuthModalContainer = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const [type, setType] = useState(null) //login || signupRealestate || signupBasic || firstSelect
  const [isVisible, setIsVisible] = useState(true)
  const [isEndRewardSign, setIsEndRewardSign] = useState(false) //초대 리워드 회원가입 or 로그인 완료 여부
  const { hasPlan } = useNoteHasPlan()

  /**
   * 초대 리워드로 회원가입이나 로그인이 끝나면
   * 1. 환영합니다 모달 보여줌
   * 2. redux에 저장 해 두었던 초대자 정보 삭제
   */
  useEffect(() => {
    if (!isEndRewardSign) return

    //중개사 회원만 리워드 모달, 요금제 있으면 무시
    if (
      loginInfo.member_srl &&
      loginInfo?.member_type == 'realestate' &&
      !hasPlan
    ) {
      dispatch(setIsEndSignModalVisible(true)) //로그인 or 회원가입 완료 환영 모달
    }

    if (hasPlan) {
      message.info('이미 요금제를 구독중입니다.')
    }

    dispatch(setInviteeInfo(null)) //초대자 정보 reset
  }, [isEndRewardSign])

  const onClose = () => {
    history.replace('/home')
    setIsVisible(false)
  }

  const onClickType = (value) => {
    setType(value)
  }

  const onCloseAuthModal = () => {
    setType(null)
  }

  /**
   * 초대 코드로 로그인 or 회원가입 후 로그인 완료 flag
   */
  const loginCallback = () => {
    setIsEndRewardSign(true)
  }

  const AuthModal = {
    login: (
      <LoginForm
        visible={true}
        onCancel={onCloseAuthModal}
        callbackFn={loginCallback}
      />
    ),
    signupRealestate: (
      <SignupAgree
        visible={true}
        onCancel={onCloseAuthModal}
        loginCallback={loginCallback}
        memberType="realestate"
      />
    ),
    firstSelect: (
      <NoteInviteJoinOrLoginWelcomeModal
        visible={isVisible}
        onClose={onClose}
        onClickType={onClickType}
      />
    ),
  }

  return children({
    type,
    isEndRewardSign,
    loginCallback,
    authModal: AuthModal[type],
    setType,
  })
}
export default RewardAuthModalContainer
