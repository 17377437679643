import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 연면적
 */
const NoteAdColumnTotalFloorArea = ({ required = false }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == adSrl &&
    adFormControl?.disabledColumns.includes('article.spc1')

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>연면적</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="text"
            {...register('article.spc1')}
            onBlur={(e) => {
              onUpdate('article.spc1', e.target.value)
            }}
            append={'㎡'}
            disabled={isDisabled}
          />
        </div>
        <ValidateWarn>{errors?.article?.spc1?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnTotalFloorArea
