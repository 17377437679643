import styled from 'styled-components'
import SummaryAmenitiesListItem from './SummaryAmenitiesListItem'
import React, { useCallback, useEffect, useState } from 'react'
import { getDistance } from 'lib/utils/summary'
import SummaryAmenitiesListItemEmergency from './SummaryAmenitiesListItemEmergency'
import SirenIcon from 'assets/icon/amenities/siren.svg'

const SummaryAmenitiesList = ({
  data,
  activeTab,
  iconCollection,
  iconSrcCollection,
  prevMarker,
  setPrevMarker,
  activeValue,
  onClickSetActiveValue,
  resetActiveValue,
}) => {
  const moveforward = (arr) => {
    return activeTab == 'hospital'
      ? arr
          .filter((x) => x?.has_emergency == 'Y')
          .concat(arr.filter((x) => x?.has_emergency != 'Y'))
      : arr
  }

  const getIconKey = useCallback(
    (mainClass, subClass) => {
      if (activeTab == 'restaurant') {
        if (subClass != '카페') {
          return 'default'
        } else {
          return subClass
        }
      } else {
        return mainClass
      }
    },
    [activeTab],
  )

  return (
    <Wrapper>
      {moveforward(data).map((item, i) =>
        activeTab == 'hospital' && item?.has_emergency === 'Y' ? (
          <SummaryAmenitiesListItemEmergency
            key={i}
            name={item.name}
            distance={getDistance(item.distance)}
            activeValue={activeValue}
            resetActiveValue={resetActiveValue}
            onClickSetActiveValue={onClickSetActiveValue}
            prevMarker={prevMarker}
            setPrevMarker={setPrevMarker}
            centerpoint={item.centerpoint}
            iconSrc={SirenIcon}
          />
        ) : (
          <SummaryAmenitiesListItem
            key={i}
            icon={
              iconCollection?.[
                [getIconKey(item.main_class, item.sub_class)]
              ] || null
            }
            iconSrc={
              iconSrcCollection?.[
                [getIconKey(item.main_class, item.sub_class)]
              ] || null
            }
            name={item.name}
            distance={getDistance(item.distance)}
            prevMarker={prevMarker}
            setPrevMarker={setPrevMarker}
            centerpoint={item.centerpoint}
            activeValue={activeValue}
            resetActiveValue={resetActiveValue}
            onClickSetActiveValue={onClickSetActiveValue}
          />
        ),
      )}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  margin-top: 10px;
  margin: 10px -10px 0 -10px;
`

export default React.memo(SummaryAmenitiesList)
