import styled from 'styled-components'
import danjiImgSrc from 'assets/images/note/dongho/danji.png'
import { Button } from 'components/design'
import { setVisibleAddModal } from 'modules/noteDongho'
import { useDispatch } from 'react-redux'
import ReactDOM from 'react-dom'
import theme, { media } from 'lib/styles/theme'
import backgroundSrc from 'assets/images/note/dongho/dongho_blur.png'

/**
 * 선택한 단지가 없을 때(=추가한 단지가 없을 때)
 */
const NoteDonghoNoSelectDanji = () => {
  const dispatch = useDispatch()
  const root = document.getElementById('modal')

  /**
   * 단지 추가 모달 열기
   */
  const onClickAdd = () => {
    dispatch(setVisibleAddModal(true))
  }

  return ReactDOM.createPortal(
    <Wrapper>
      <Background>
        <BackgroundImg src={backgroundSrc} />
      </Background>
      <img src={danjiImgSrc} width={139} height={149} />
      <Title>
        <>
          관심있는 단지를
          <br />
          즐겨찾기 해보세요.
        </>
      </Title>
      <SubTitle>
        호별 실거래가, 점유형태, 계약 만기 등의
        <br />
        정보를 통해 효율적인 임장 활동이 가능해요
      </SubTitle>
      <Button color="blue" size="large" onClick={onClickAdd}>
        즐겨찾는 단지 등록하기
      </Button>
    </Wrapper>,
    root,
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: ${theme.base.navWidth + 1}px;
  padding-right: ${theme.base.navWidth + 1}px;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${media.mediumM`
    left: 0;
    top: 0px;
    padding-right: 0;
    margin-top: ${theme.base.headerHeight}px;
    height: calc(100% - ${
      theme.base.headerHeight + theme.base.bottomMenuHeight - 2
    }px);
  `}
`

const Title = styled.div`
  margin-top: 28px;
  font-size: 28px;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: inherit;
  background-color: ${theme.colors.base.white};

  ${media.mediumM`
    height: calc(100% - 2px);
  `}
`

const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
`

const SubTitle = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: ${theme.colors.gray[600]};
`

export default NoteDonghoNoSelectDanji
