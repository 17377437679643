import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 전용면적
 */
const NoteAdColumnExclusiveArea = ({ columnKey, required }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabledSpc2 =
    columnKey == 'article.spc2' &&
    adFormControl?.adSrl == adSrl &&
    adFormControl?.disabledColumns.includes('article.spc2')

  const isDisabledSpc4 =
    columnKey == 'article.spc4' &&
    adFormControl?.adSrl == adSrl &&
    adFormControl?.disabledColumns.includes('article.spc4')

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>전용면적</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="text"
            {...register(columnKey)}
            onBlur={(e) => {
              onUpdate(columnKey, e.target.value)
            }}
            append={'㎡'}
            disabled={isDisabledSpc2 || isDisabledSpc4}
          />
        </div>

        {columnKey == 'article.spc2' && (
          <ValidateWarn>{errors?.article?.spc2?.message}</ValidateWarn>
        )}
        {columnKey == 'article.spc4' && (
          <ValidateWarn>{errors?.article?.spc4?.message}</ValidateWarn>
        )}
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnExclusiveArea
