import NotePaymentAllInOneInduceModal from 'components/jmapnotev2/NotePayment/NotePaymentAllInOneInduceModal'
import NotePaymentAuth from 'components/jmapnotev2/NotePayment/NotePaymentAuth'
import { allInOnePrice } from 'lib/utils/notev2/util'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 올인원 결제, 본인인증 관련 로직 모음
 * @param callPaymentModal 결제 모달 열기
 * @returns { isStartAuth : boolean, }
 */
const NotePaymentAllInOneConatiner = ({
  children,
  callPaymentModal,
}) => {
  const [isStartAuth, setIsStartAuth] = useState(false)
  const [visibleInduceModal, setVisibleInduceModal] = useState(false) // 본인인증, 결제 유도 모달 (대표, 본인인증 해야 올인원 구매 가능해요)
  const [clickedMonth, setClickedMonth] = useState(null)

  const routerHistory = useHistory()

  /**
   * 올인원 본인인증은 화면만 전환되기 때문에 popstate로 뒤로가기 제어 추가함
   */
  useEffect(() => {
    if (!isStartAuth) return

    history.pushState(null, '', location.href)
    window.addEventListener('popstate', () => cancelAuth(false))

    return () =>
      window.removeEventListener('popstate', () => cancelAuth(false))
  }, [isStartAuth])

  /**
   * 올인원 시작하기
   * @param month 개월
   */
  const onClickPaymentAllInOne = (month) => {
    setClickedMonth(month)

    //무조건 본인인증 (안내 모달 보여줌)
    setVisibleInduceModal(true)
  }

  /**
   * 올인원 본인인증 시작
   */
  const startAuth = () => {
    if (visibleInduceModal) {
      setVisibleInduceModal(false)
    }
    setIsStartAuth(true)
  }

  /**
   * 올인원 본인인증 취소(이전 화면으로)
   */
  const cancelAuth = (goBack = true) => {
    setIsStartAuth(false)
    goBack && routerHistory.go(-1)
  }

  /**
   * 대표 아닌 사람 등 대상 본인인증 유도 모달 닫기
   */
  const onCloseInduceModal = () => {
    setVisibleInduceModal(false)
  }

  return children({
    // 본인인증 시작 여부
    isStartAuth,
    onClickPaymentAllInOne,
    // inviteModal: (
    //   <NotePaymentAllInOneInviteModal
    //     onCancel={onCloseInviteModal}
    //     visible={visibleInviteModal}
    //     callbackFn={resetEndUpdatePlan}
    //   />
    // ),
    induceModal: (
      <NotePaymentAllInOneInduceModal
        onOk={startAuth}
        onCancel={onCloseInduceModal}
        visible={visibleInduceModal}
      />
    ),
    //isStartAuth 에 따라 보여지는 화면(본인인증)
    auth: (
      <NotePaymentAuth
        onClose={cancelAuth}
        month={clickedMonth}
        price={allInOnePrice[[clickedMonth]]}
        callPaymentModal={callPaymentModal}
      />
    ),
  })
}

export default NotePaymentAllInOneConatiner
