import React from 'react'
import styled from 'styled-components'
import {
  InfoContent,
  InfoSection,
  InfoTitle,
} from './NoteInviteRewardPayInfo'
import moment from 'moment'
import theme from 'lib/styles/theme'

const NoteInviteRewardList = ({ invitedList }) => {
  return (
    <InfoSection>
      <InfoTitle>초대한 친구</InfoTitle>
      <InfoContent>
        {invitedList && invitedList.length > 0 ? (
          <ul>
            {invitedList?.map(
              ({ profile_img, email_address, regdate, status }, i) => {
                return (
                  <React.Fragment key={i}>
                    <StyledLi>
                      {profile_img ? (
                        <ProfileImg src={profile_img.src} />
                      ) : (
                        <ProfileImg as="div" />
                      )}
                      <Content>
                        <Email>{email_address}</Email>
                        <div>
                          <Date>
                            {moment(regdate, 'YYYYMMDDHHmmss').format(
                              'YYYY.MM.DD',
                            )}
                          </Date>
                          <Status>{status}</Status>
                        </div>
                      </Content>
                    </StyledLi>
                  </React.Fragment>
                )
              },
            )}
          </ul>
        ) : (
          <EmptyText>초대한 친구가 없습니다.</EmptyText>
        )}
      </InfoContent>
    </InfoSection>
  )
}

const StyledLi = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  img {
    margin-right: 8px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 32px;
    height: 32px;
    border-radius: 12px;
  }
`

const Email = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
`

const Date = styled.span`
  font-size: 9px;
  font-weight: 500;
  line-height: 100%;
  margin-right: 3px;
`

const Status = styled.span`
  font-size: 9px;
  font-weight: 500;
  line-height: 100%;
`

const ProfileImg = styled.img`
  width: 32px;
  height: 32px;
  background-color: ${theme.colors.gray[200]};
  margin-right: 4px;
  object-fit: contain;
  border-radius: 12px;
`

const EmptyText = styled.div`
  font-size: 12px;
`

export default NoteInviteRewardList
