import MapContext from 'contexts/map'
import { useContext, useState } from 'react'

export default function useMapFilter(callBackFn) {
  // context 선언
  const {
    state: { filterState, layerState },
    actions: { setFilterState, setLayerState },
  } = useContext(MapContext)

  // 기본 탭 설정
  const [currentTab, setCurrentTab] = useState('land')

  const handleEndSlider =
    (type) => (render, handle, value, un, percent) => {
      setFilterState((prevState) => {
        let newState = { ...prevState }
        newState.real_prices[type] = value
        return newState
      })
      callBackFn && callBackFn()
    }

  const handleEndSliderLayer =
    (layerType, type) => (render, handle, value, un, percent) => {
      setFilterState((prevState) => {
        let newState = { ...prevState }
        newState[layerType][type] = value
        return newState
      })

      // 필터 변경 시 실거래가 레이어 활성화
      setLayerState((prev) => {
        return {
          ...prev,
          [layerType]: true,
        }
      })
      callBackFn && callBackFn()
    }

  return {
    filterState,
    currentTab,
    setFilterState,
    setCurrentTab,
    handleEndSlider,
    handleEndSliderLayer,
  }
}
