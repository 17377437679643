import { useFormContext } from 'react-hook-form'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import Checkbox from '../../elements/Checkbox'
import { options } from '../../options'
import React from 'react'
import useSave from '../../useSave'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 시설정보 생활시설
 */
const NoteAdColumnLivingFacility = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const { onChangeCheckbox } = useSave()

  const life = watch('facilities.life') || []

  return (
    <ColumnSet>
      <ColumnTitle>생활시설</ColumnTitle>
      <ColumnVal gap="20px 40">
        {options['생활시설'].map((item, i) => {
          return (
            <React.Fragment key={item}>
              <Checkbox
                id={`facilities_life${i}`}
                color="blue"
                value={item}
                {...register('facilities.life')}
                onChange={(checked, e) => {
                  onChangeCheckbox({
                    originValue: life,
                    key: 'facilities.life',
                    value: e,
                  })
                }}
              >
                {item}
              </Checkbox>
            </React.Fragment>
          )
        })}
        <ValidateWarn>{errors?.facilities?.life?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnLivingFacility
