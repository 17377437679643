import NotePropertySelector from 'components/jmapnotev2/elements/NotePropertySelector'
import {
  ActiveCellStyle,
  StyledSelectView,
} from 'components/jmapnotev2/style/NoteGridStyle'
import { getPropertyFilter } from 'lib/api/notev2/filterApi'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import useAxios from 'lib/hooks/useAxios'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { setNotePropertyFilter } from 'modules/notev2'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const CustomPropertyTypeCell = ({ node, data }) => {
  const noteOptions = useSelector((state) => state.notev2.noteOptions)

  const options = useRef(null)
  const [onRequestModifyNote, , dataModify] = useModifyNote()
  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    isReversePosition,
  } = useCustomSingleCell(node?.data?.status, node?.rowIndex, 300)

  const [onRequestFilter, , dataFilter, ,] = useAxios(getPropertyFilter)

  const dispatch = useDispatch()

  const selectRef = useRef(null)
  const viewRef = useRef(null)

  useEffect(() => {
    //수정모드가 변경되면 방향키 제어를 위해 그리드 데이터의 state도 업데이트 해준다
    updateGridDatas({
      currentRowNode: node,
      newData: {
        ...node.data,
        state: {
          ...data.state,
          isEditingPropertyType: isEditable,
        },
      },
    })

    //수정 모드가 되면 자동 포커스
    if (isEditable) {
      selectRef.current?.focus()
    }
  }, [isEditable])

  useEffect(() => {
    if (!noteOptions) return

    options.current = getSimpleSelectboxOptions(noteOptions.note_types)
  }, [noteOptions])

  useEffect(() => {
    if (options.current.current && options.current.length > 0) {
      const index = options.current.findIndex(
        ({ value }) => value == data.property_type,
      )
    }
  }, [options.current])
  const threeStepState = useSelector(
    (state) => state.onBoarding.threeStepState,
  )
  /**
   * selectbox change
   */
  const onChange = useCallback(
    (name) => {
      //종류 데이터 업데이트
      onRequestModifyNote({
        data: node.data,
        updateData: { property_type: name },
        currentRowNode: node,
      })

      //수정모드 해제
      setIsEditable(false)
      viewRef.current.focus()
    },
    [node],
  )

  useEffect(() => {
    if (!dataModify) return
    //왼쪽 필터 refresh
    onRequestFilter({ channel_srl: data.channel_srl })
  }, [dataModify])

  /**
   * 필터 refresh 결과
   */
  useEffect(() => {
    if (!dataFilter || !dataFilter.result) return

    dispatch(setNotePropertyFilter(dataFilter.result?.property || {}))
  }, [dataFilter])

  const onClose = () => {
    setIsEditable(false)
    viewRef.current?.focus()
  }

  const getShortName = () => {
    if (data.property_type == '오피스텔 분양권') {
      return '분양권(오)'
    } else if (data.property_type == '아파트 분양권') {
      return '분양권(아)'
    }
    return data.property_type
  }
  if (data.note_srl == '신규') return <></>

  return (
    <Wrapper tabIndex={-1}>
      <StyledSelectView
        ref={viewRef}
        tabIndex={0}
        onDoubleClick={onDoubleClickSingleCell}
        onKeyDown={onKeyDownSingleCell}
        onClick={() => setIsEditable(false)}
      >
        {getShortName()}
      </StyledSelectView>
      {isEditable && (
        <SelectorWrapper>
          {data.property_type}

          <SelectorBlockWrapper reverse={isReversePosition ? 'Y' : 'N'}>
            <NotePropertySelector
              visible={true}
              setIsVisible={setIsEditable}
              value={data.property_type}
              onChange={onChange}
              onClose={onClose}
            />
          </SelectorBlockWrapper>
        </SelectorWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${({ threeStepState }) =>
    threeStepState == true &&
    `
  padding:0;
  border: 5px solid transparent;
  background-image: linear-gradient(#fff, #fff),linear-gradient(to right, #00d8f8, #5a87ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
   `}
`

const SelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${ActiveCellStyle}
`

const SelectorBlockWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: -43px;
  top: 2px;

  ${({ reverse }) =>
    reverse == 'Y' &&
    `
    top: -320px;
  `}
`

export default React.memo(CustomPropertyTypeCell)
