import { getAdListingByNoteSrl } from 'lib/api/notev2/ad'
import useAxios from 'lib/hooks/useAxios'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { ReactComponent as NaverIcon } from 'assets/icon/note/ad/naver-id.svg'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
import CompleteButton from '../NoteAd/list/actionButtons/CompleteButton'
import EndButton from '../NoteAd/list/actionButtons/EndButton'
import DeleteButton from '../NoteAd/list/actionButtons/DeleteButton'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import NoteDetailAdListViewButton from './NoteDetailAdListViewButton'
import NormalButton from '../NoteAd/list/actionButtons/NormalButton'
import RecycleForNoteButton from '../NoteAd/list/actionButtons/RecycleForNoteButton'
import NewWriteButton from '../NoteAd/list/actionButtons/NewWriteButton'
import { Empty } from 'components/design'

const NoteDetailAdList = () => {
  const { watch } = useFormContext()
  const { isBoss } = useIsAllInOne()
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [onRequest, , data] = useAxios(getAdListingByNoteSrl)
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const noteSrl = watch('note_srl')

  const statusColor = {
    작성중: theme.colors.blue[400],
    승인대기: theme.colors.blue[400],
    승인거절: theme.colors.tint.danger,
    전송중: theme.colors.blue[400],
    대기중: theme.colors.blue[400],
    검증중: theme.colors.blue[400],
    검증취소: theme.colors.tint.danger,
    검증1차실패: theme.colors.tint.danger,
    검증최종실패: theme.colors.tint.danger,
    광고중: '#31B85E',
    종료예정: theme.colors.gray[400],
    광고종료: theme.colors.gray[400],
    신고매물: theme.colors.tint.danger,
    전송실패: theme.colors.tint.danger,
    동일주소매물: theme.colors.tint.danger,
  }

  useEffect(() => {
    setIsLoading(true)
    search()
  }, [])

  useEffect(() => {
    if (!data) {
      setList([])
      return
    }

    setIsLoading(false)
    setList(data?.result || [])
  }, [data])

  /**
   * 검색
   */
  const search = () => {
    onRequest({
      plan_srl: planInfo.allinone_plan_srl,
      note_srl: noteSrl,
    })
  }

  const callBackFn = () => {
    search()
  }

  /**
   * 대표용 버튼 형태 얻기
   * @returns object
   */
  const getButtonStatusBoss = (adSrl, status, contract_type) => {
    const result = {
      작성중: (
        <>
          <NoteDetailAdListViewButton adSrl={adSrl} expand />
          <DeleteButton adSrl={adSrl} forNote />
        </>
      ),
      승인대기: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      승인거절: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      전송중: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      대기중: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      전송실패: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      검증중: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      검증취소: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      검증1차실패: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      검증최종실패: <NoteDetailAdListViewButton adSrl={adSrl} expand />,
      광고중: (
        <>
          <CompleteButton
            adSrl={adSrl}
            forNote
            callbackFn={callBackFn}
          />
          <EndButton adSrl={adSrl} forNote callbackFn={callBackFn} />
          <NoteDetailAdListViewButton adSrl={adSrl} />
        </>
      ),
      종료예정: (
        <>
          <CompleteButton
            adSrl={adSrl}
            forNote
            callbackFn={callBackFn}
          />
          <EndButton adSrl={adSrl} forNote callbackFn={callBackFn} />
          <NoteDetailAdListViewButton adSrl={adSrl} />
        </>
      ),
      광고종료: (
        <>
          <RecycleForNoteButton adSrl={adSrl} callbackFn={callBackFn} />
          <NewWriteButton />
          <DeleteButton adSrl={adSrl} callbackFn={callBackFn} forNote />
          <NoteDetailAdListViewButton adSrl={adSrl} forNote />
        </>
      ),
      신고매물: (
        <>
          <NormalButton
            type="신고매물"
            adSrl={adSrl}
            callbackFn={callBackFn}
            forNote
          />
          <EndButton
            type="신고매물"
            adSrl={adSrl}
            callbackFn={callBackFn}
            forNote
          />
          <CompleteButton
            type="신고매물"
            adSrl={adSrl}
            callbackFn={callBackFn}
            forNote
          />
          <NoteDetailAdListViewButton adSrl={adSrl} forNote />
        </>
      ),
      동일주소매물: (
        <>
          <NormalButton
            type="동일주소매물"
            adSrl={adSrl}
            callbackFn={callBackFn}
            forNote
          />
          <EndButton
            type="동일주소매물"
            adSrl={adSrl}
            callbackFn={callBackFn}
            forNote
          />
          <CompleteButton
            type="동일주소매물"
            contractType={contract_type}
            adSrl={adSrl}
            callbackFn={callBackFn}
            forNote
          />
          <NoteDetailAdListViewButton adSrl={adSrl} forNote />
        </>
      ),
    }

    return result?.[status] || null
  }

  /**
   * 직원용 버튼 형태 얻기
   * submit - 일반 전송(preregisterAd)
   * @returns object
   */
  const getButtonStatusStaff = (adSrl, status) => {
    const result = {
      작성중: (
        <>
          <NoteDetailAdListViewButton adSrl={adSrl} expand />
          <DeleteButton adSrl={adSrl} callbackFn={callBackFn} forNote />
        </>
      ),
      승인대기: (
        <>
          <NoteDetailAdListViewButton adSrl={adSrl} expand />
          <DeleteButton adSrl={adSrl} callbackFn={callBackFn} forNote />
        </>
      ),
      승인거절: (
        <>
          <NoteDetailAdListViewButton adSrl={adSrl} expand />
          <DeleteButton adSrl={adSrl} callbackFn={callBackFn} forNote />
        </>
      ),
    }

    return result?.[status] || null
  }

  if (isLoading) return <Skeleton active />
  return (
    <Wrapper>
      {list.length == 0 && <Empty>작성된 광고가 없습니다.</Empty>}
      {list.map(
        (
          {
            ad_srl,
            status,
            creator_name,
            verification_type,
            nuid,
            contract_type,
            first_price,
          },
          i,
        ) => {
          return (
            <Item key={i}>
              <TitleWrapper>
                <Title gray={!nuid}>매물번호</Title>
                {nuid && (
                  <AdNumber>
                    <NaverIcon /> {nuid}
                  </AdNumber>
                )}
              </TitleWrapper>
              <Block>
                <BlockItem>
                  <BlockTitle>광고상태</BlockTitle>
                  <BlockValue
                    color={
                      statusColor[status] || theme.colors.blue[400]
                    }
                  >
                    {status}
                  </BlockValue>
                </BlockItem>
                <BlockItem>
                  <BlockTitle>멤버</BlockTitle>
                  <BlockValue>{creator_name}</BlockValue>
                </BlockItem>
                <BlockItem>
                  <BlockTitle>검증방식</BlockTitle>
                  <BlockValue>{verification_type}</BlockValue>
                </BlockItem>
              </Block>
              <Buttons>
                {isBoss
                  ? getButtonStatusBoss(
                      ad_srl,
                      status,
                      contract_type,
                      first_price,
                    )
                  : getButtonStatusStaff(ad_srl, status)}
              </Buttons>
            </Item>
          )
        },
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 16px;
  align-items: flex-start;

  ${media.mediumS` 
    gap: 12px;
  `}
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: calc(50% - 10px);
  padding: 20px 16px;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 8px;

  ${media.mediumS` 
    width: 100%;
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
`

const Title = styled.div`
  color: ${theme.colors.gray[700]};

  ${({ gray }) =>
    gray &&
    `
    color: ${theme.colors.gray[400]};
  `}
`

const AdNumber = styled.div`
  color: #00a32a;
  text-decoration: underline;
  text-underline-position: under;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 14px;

  background-color: ${theme.colors.gray[100]};
`

const BlockItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const BlockTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`

const BlockValue = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;

  ${({ color }) =>
    color &&
    `
    color: ${color};
`}
`

const Buttons = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;

  /* one item */
  &:has(> button:last-child:nth-child(1)) {
    button {
      width: 100%;
    }
  }

  /* two items */
  &:has(> button:last-child:nth-child(2)) {
    button {
      width: calc(50% - 3px);
    }
  }

  /* three items */
  &:has(> button:last-child:nth-child(3)) {
    button {
      padding: 0;
      width: calc(calc(100% / 3) - 6px);
    }
  }

  &:has(> button:last-child:nth-child(4)) {
    button {
      padding: 0;
      width: calc(calc(100% / 3) - 6px);

      &:last-child {
        width: 100%;
      }
    }
  }
`

export default NoteDetailAdList
