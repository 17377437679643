import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import CustomNumberOverlay from './CustomNumberOverlay'
import styled from 'styled-components'
import { rgba } from 'polished'
import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'

/**
 * 매물 개수 뱃지
 */
const MapFavoriteNumberMarkerItem = ({ item }) => {
  const markerRef = useRef(null)
  const [payload, setPayload] = useState(null)

  const { state } = useContext(MapContext)
  const { viewState, map } = state

  useEffect(() => {
    const originPayload = getPayload()
    setPayload(originPayload)
  }, [item])

  const onInit = useCallback((marker) => {
    marker.setZIndex(1)
  }, [])

  const getPayload = useCallback(() => {
    const position = [item.latitude, item.longitude]

    return {
      position: new kakao.maps.LatLng(...position),
      yAnchor: 1,
    }
  }, [item])

  const onClick = () => {
    if (
      !map ||
      Object.keys(map).length == 0 ||
      !viewState?.level ||
      !payload.position
    )
      return

    map.setLevel(viewState.level - 2)
    map.panTo(payload.position)
  }

  return (
    <CustomNumberOverlay
      overlayRef={markerRef}
      payload={payload}
      onInit={onInit}
      onClick={onClick}
      draggable
    >
      <div ref={markerRef}>
        <Circle>{item?.count}</Circle>
      </div>
    </CustomNumberOverlay>
  )
}

const Circle = styled.div`
  cursor: pointer;
  pointer-events: all;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 7px;
  background-color: ${theme.colors.gray[700]};
  border: 1px solid ${theme.colors.gray[500]};
  border-radius: 1rem;
  box-shadow: 0px 4px 16px ${rgba('black', 0.3)};
  color: ${theme.colors.gray[200]};
  text-align: left;
  transition: none;
  z-index: 1;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: ${theme.colors.gray[500]} transparent transparent
      transparent;
  }
`

export default MapFavoriteNumberMarkerItem
