import { useSelector } from 'react-redux'
import {
  Block,
  BlockTitle,
  CurrentPlanButton,
  List,
  ListItem,
  Price,
  PriceWrapper,
  StartButton,
} from './NotePaymentPlanListStyle'
import NotePaymentCancelToFreeContainer from 'containers/notev2/NotePaymentCancelToFreeContainer'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

const NotePaymentPlanListFree = () => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const { hasPlan } = useNoteHasPlan()

  const isExpectedCancel = planInfo?.is_expected_cancel
  const paymentName = planInfo?.payment_name
  return (
    <>
      <NotePaymentCancelToFreeContainer>
        {({ onClickCancelPlan, modal, loading }) => {
          return (
            <>
              <Block active={paymentName == '프리'}>
                <BlockTitle>
                  프리
                  {paymentName == '프리' && (
                    <CurrentPlanButton>현재 요금제</CurrentPlanButton>
                  )}
                </BlockTitle>
                <PriceWrapper>
                  <Price>
                    0<span>원</span>
                  </Price>
                </PriceWrapper>
                {hasPlan && !isExpectedCancel && (
                  <StartButton
                    expand
                    size="large"
                    color={'blue'}
                    onClick={() => onClickCancelPlan()}
                  >
                    프리 요금제로 변경하기
                  </StartButton>
                )}
                <List>
                  <ListItem>매물노트 이용</ListItem>
                  <ListItem>계약서 작성</ListItem>
                  <ListItem>확인설명서 작성</ListItem>
                </List>
              </Block>

              {loading && <FullScreenLoading />}

              {/* 해지 모달 .. */}
              {modal.cancel}
              {modal.cancelSchedule}
              {modal.cancelDone}
              {modal.cancelScheduleDone}
              {modal.eventCancel}
              {modal.payFailCancel}
              {modal.payFailCancelDone}
              {modal.cancelAllInOneDone}
            </>
          )
        }}
      </NotePaymentCancelToFreeContainer>
    </>
  )
}

export default NotePaymentPlanListFree
