import { Checkbox } from 'components/design'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  InnerTableContent,
  InnerTableBlock,
  innerSubHeaderWidth,
  subHeaderWidth,
  StyledTable,
  SkipCover,
  subHeaderWidthSm,
  headerWidth,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import {
  getOnlyRemoveCommaNumber,
  handleFocusNumber,
} from 'lib/utils/notev2/util'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsBasic8 = ({ onUpdate }) => {
  const { watch } = useFormContext()

  const d_acquisition_tax = watch('d_acquisition_tax')
  const d_countryside_tax = watch('d_countryside_tax')
  const d_local_education_tax = watch('d_local_education_tax')
  const d_descript_type = watch('d_descript_type')
  const d_property_type = watch('d_property_type')

  const [isAcquisitionSkip, setIsAcquisitionSkip] = useState(false)
  const [isCountrysideSkip, setIsCountrysideSkip] = useState(false)
  const [isLocalEducationSkip, setIsLocalEducationSkip] =
    useState(false)

  const isRent = d_property_type.includes('임대')

  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑨'
    } else {
      return '⑦'
    }
  }

  useUpdateEffect(() => {
    if (!isRent) {
      setIsAcquisitionSkip(false)
      setIsCountrysideSkip(false)
      setIsLocalEducationSkip(false)
    }
  }, [isRent])

  useEffect(() => {
    if (d_acquisition_tax == '임대차생략') {
      setIsAcquisitionSkip(true)
    }
  }, [d_acquisition_tax])

  useEffect(() => {
    if (d_countryside_tax == '임대차생략') {
      setIsCountrysideSkip(true)
    }
  }, [d_countryside_tax])

  useEffect(() => {
    if (d_local_education_tax == '임대차생략') {
      setIsLocalEducationSkip(true)
    }
  }, [d_local_education_tax])

  return (
    <StyledTable sectionspace>
      <Row height={30} rowspan={2} noborderbottom>
        <TableHeader width={innerSubHeaderWidth}>
          {getNumber()} 취득 시 부담할
          <br />
          조세의 종류 및 세율
        </TableHeader>
        <RowSpanInner
          rowspanheightpercent={60}
          rowspan={2}
          minuswidth={innerSubHeaderWidth}
        >
          <InnerTableBlock widthpercent={30} noborderbottom>
            <TableSubHeader width={subHeaderWidthSm}>
              취득세
            </TableSubHeader>
            <InnerTableContent
              checkboxes
              headerwidth={subHeaderWidthSm}
            >
              {isRent && isAcquisitionSkip ? (
                <SkipCover width={220}>임대차생략</SkipCover>
              ) : (
                <ColorInput
                  defaultValue={
                    d_acquisition_tax > 0 ? d_acquisition_tax : null
                  }
                  onBlur={(e) =>
                    onUpdate(
                      'd_acquisition_tax',
                      getOnlyRemoveCommaNumber(e.target.value),
                    )
                  }
                  onClick={handleFocusNumber}
                  append="%"
                  fullWidth
                />
              )}
              {isRent && (
                <StyledCheckbox
                  color="blue"
                  size="sm"
                  checked={isAcquisitionSkip}
                  onChange={(e) => {
                    if (isAcquisitionSkip) {
                      onUpdate('d_acquisition_tax', '')
                    } else {
                      onUpdate('d_acquisition_tax', '임대차생략')
                    }
                    setIsAcquisitionSkip((prev) => !prev)
                  }}
                >
                  임대차생략
                </StyledCheckbox>
              )}
            </InnerTableContent>
          </InnerTableBlock>
          <InnerTableBlock widthpercent={35} noborderbottom>
            <TableSubHeader width={headerWidth}>
              농어촌특별세
            </TableSubHeader>
            <InnerTableContent checkboxes headerwidth={headerWidth}>
              {isRent && isCountrysideSkip ? (
                <SkipCover width={220}>임대차생략</SkipCover>
              ) : (
                <ColorInput
                  defaultValue={
                    d_countryside_tax > 0 ? d_countryside_tax : null
                  }
                  onBlur={(e) =>
                    onUpdate(
                      'd_countryside_tax',
                      getOnlyRemoveCommaNumber(e.target.value),
                    )
                  }
                  onClick={handleFocusNumber}
                  append="%"
                  fullWidth
                />
              )}
              {isRent && (
                <StyledCheckbox
                  color="blue"
                  size="sm"
                  checked={isCountrysideSkip}
                  onChange={(e) => {
                    if (isCountrysideSkip) {
                      onUpdate('d_countryside_tax', '')
                    } else {
                      onUpdate('d_countryside_tax', '임대차생략')
                    }
                    setIsCountrysideSkip((prev) => !prev)
                  }}
                >
                  임대차생략
                </StyledCheckbox>
              )}
            </InnerTableContent>
          </InnerTableBlock>
          <InnerTableBlock
            widthpercent={35}
            noborderbottom
            noborderright
          >
            <TableSubHeader width={subHeaderWidthSm}>
              지방교육세
            </TableSubHeader>
            <InnerTableContent
              checkboxes
              headerwidth={subHeaderWidthSm}
            >
              {isRent && isLocalEducationSkip ? (
                <SkipCover width={220}>임대차생략</SkipCover>
              ) : (
                <ColorInput
                  defaultValue={
                    d_local_education_tax > 0
                      ? d_local_education_tax
                      : null
                  }
                  onBlur={(e) =>
                    onUpdate(
                      'd_local_education_tax',
                      getOnlyRemoveCommaNumber(e.target.value),
                    )
                  }
                  onClick={handleFocusNumber}
                  append="%"
                  fullWidth
                />
              )}
              {isRent && (
                <StyledCheckbox
                  color="blue"
                  size="sm"
                  checked={isLocalEducationSkip}
                  onChange={(e) => {
                    if (isLocalEducationSkip) {
                      onUpdate('d_local_education_tax', '')
                    } else {
                      onUpdate('d_local_education_tax', '임대차생략')
                    }
                    setIsLocalEducationSkip((prev) => !prev)
                  }}
                >
                  임대차생략
                </StyledCheckbox>
              )}
            </InnerTableContent>
          </InnerTableBlock>
        </RowSpanInner>
        <RowSpanInner
          rowspanheightpercent={40}
          rowspan={2}
          minuswidth={innerSubHeaderWidth}
          noborderbottom
        >
          <InnerTableContent>
            <Text>
              ※ 재산세와 종합부동산세는 6월 1일 기준 대상 불건 소유자가
              납세의무를 부담
            </Text>
          </InnerTableContent>
        </RowSpanInner>
      </Row>
    </StyledTable>
  )
}

const Text = styled.div`
  font-size: 14px;
`

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px;
`

export default NoteDocsBasic8
