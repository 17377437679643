import theme, { media } from 'lib/styles/theme'
import {
  initPropertyParams,
  setNoteListSearchParams,
} from 'modules/notev2'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const NoteListHeaderTitle = ({
  flip,
  subtitle,
  title,
  resetSearch,
  searchFnWithParam,
}) => {
  const notePropertyFilter = useSelector(
    (state) => state.notev2.notePropertyFilter,
  )
  const dispatch = useDispatch()
  const { q } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )
  /**
   * 대분류 클릭
   */
  const onClickAllFilter = useCallback(
    (label) => {
      if (notePropertyFilter[label]) {
        const target = notePropertyFilter[label]
        const firstKey = Object.keys(target)[0]

        if (!notePropertyFilter[label]?.[firstKey]) return

        const selectAllParam = {
          [label]: [notePropertyFilter[label][firstKey]?.code],
        }
        const newData = Object.assign(
          {},
          initPropertyParams(),
          selectAllParam,
        )

        resetSearch()

        dispatch(
          setNoteListSearchParams({
            q: '',
            property_types: newData,
          }),
        )

        searchFnWithParam({
          q: '',
          propertyFilter: newData,
        })
      }
    },
    [notePropertyFilter],
  )

  return (
    <>
      {title == '모든 매물' ? (
        <Breadcrumb>
          <BreadcrumbTitle>
            {q ? <>&#8220;{q}&#8221;에 대한 검색결과</> : '모든매물'}
          </BreadcrumbTitle>
        </Breadcrumb>
      ) : (
        <Breadcrumb direction={flip ? 'row' : 'column'}>
          <BreadcrumbTitle
            haschild={subtitle && subtitle != '전체' ? 'Y' : 'N'}
            onClick={() => {
              onClickAllFilter(title)
            }}
          >
            {title}
          </BreadcrumbTitle>
          {subtitle && subtitle != '전체' && (
            <BreadcrumbSubTitle>{subtitle}</BreadcrumbSubTitle>
          )}
        </Breadcrumb>
      )}
    </>
  )
}

const Breadcrumb = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  font-size: 28px;
  line-height: 100%;

  ${media.smallPc`
    flex-direction: column;
    align-items: flex-start;
  `}
`

const BreadcrumbTitle = styled.div`
  cursor: pointer;
  flex-shrink: 0;
  font-weight: 700;
  ${({ haschild }) =>
    haschild == 'Y' &&
    `
      color: ${theme.colors.gray[600]};  
      font-weight: 500;
      &::after {
        content: '\f105';
        font-family: 'Font Awesome 5 Pro';
        margin: 0 8px;
        color: ${theme.colors.gray[600]};
        font-weight: 400;
      }
  `}

  ${media.smallPc`
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
    flex-shrink: 0;

    ${({ haschild }) =>
      haschild == 'Y' &&
      `
        font-size: 14px;
        font-weight: 400;
    `}
  `}

  ${media.mediumS`
    font-size: 18px;
  `}
`

const BreadcrumbSubTitle = styled.div`
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 370px;

  ${media.smallPc`
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 215px;
  `}

  ${media.mediumS`
    font-size: 18px;
  `}
`

const BreadcrumbAllInOne = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  color: ${theme.colors.blue[900]}80;
`

export default NoteListHeaderTitle
