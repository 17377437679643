import theme, { media } from 'lib/styles/theme'
import { NumericFormat, PatternFormat } from 'react-number-format'
import styled from 'styled-components'
import NoteInputDate from 'components/jmapnotev2/elements/NoteInputDate'
import { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

/**
 * 광고관리용 날짜 input
 * @param {*} param0
 * @param {*} ref
 * @returns
 */
const InputDate = (
  {
    width,
    height,
    prepend,
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    datasetKey = '',
    continuing = false,
    tail = false,
    halfwidth = false,
    angularright = false,
    angularleft = false,
    type = 'number',
    format,
    defaultValue,
    selected,
    halfwidthWithGap,
    readOnly,
    white,
    expireDate,
    disabled,
    ...rest
  },
  ref,
) => {
  const { watch } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    disabled ||
    (adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll)

  return (
    <Wrapper
      tabIndex={-1}
      continuing={continuing}
      tail={tail ? 1 : 0}
      halfwidth={halfwidth}
      halfwidthWithGap={halfwidthWithGap}
      angularright={angularright}
      angularleft={angularleft}
      readonly={readOnly}
      white={white ? 'Y' : 'N'}
      disabled={isDisabled || disabled}
    >
      <NoteInputDate
        {...rest}
        ref={ref}
        width={width}
        height={height}
        prepend={prepend}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        datasetKey={datasetKey}
        tail={tail}
        defaultValue={defaultValue}
        selected={selected}
        readOnly={readOnly}
        expireDate={expireDate}
        disabled={isDisabled || disabled}
        white
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  position: relative;
  display: inline-flex;
  align-items: center;
  appearance: none;
  box-sizing: border-box;

  ${({ continuing }) =>
    continuing &&
    `
      width: 60%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `};
  ${({ angularright }) =>
    angularright &&
    `
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  `};

  ${({ angularleft }) =>
    angularleft &&
    `
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  `};

  ${({ halfwidth }) => halfwidth && `width: 50%;`};
  ${({ halfwidthWithGap }) =>
    halfwidthWithGap && `width: calc(50% - 4px);`};

  ${({ tail }) =>
    tail &&
    `
      width: 40%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    `};

  ${({ mb }) => mb && `margin-bottom: 12px;`};
  ${({ white }) =>
    white && `background-color: ${theme.colors.base.white}`};

  &:has(input.disabled-input) {
    background-color: ${theme.colors.gray[100]};
  }

  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${theme.colors.gray[100]};
  `}
`

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  font-weight: 500;
  border-radius: 0;
  background-color: transparent;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border: none;
  box-shadow: none;

  ${({ width }) =>
    width &&
    `
    width:${width}px;
    `}

  ${({ height }) =>
    height &&
    `
    height:${height}px;
    `}
  color: ${theme.colors.gray[900]};

  &::placeholder {
    font-weight: 400;
    color: ${theme.colors.gray[400]};
  }

  ${media.mediumS`
    width: 100%;
    padding: 0 4px 0 6px;
  `}
`

export default forwardRef(InputDate)
