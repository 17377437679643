import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Item } from 'react-photoswipe-gallery'

const NoteDashboardPictureItem = ({ item }) => {
  return (
    <StyledPhotoItem>
      <Item
        original={item.url}
        thumbnail={item.thumbnail}
        width={item.width}
        height={item.height}
      >
        {({ ref, open }) => (
          <img ref={ref} onClick={open} src={item.url} />
        )}
      </Item>
    </StyledPhotoItem>
  )
}

const StyledDeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;

  cursor: pointer;
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0.25rem;

  border-radius: 100%;
  outline: none;
  border: 1px solid ${theme.colors.gray[200]};
  box-shadow: ${theme.shadow['card-1']};
  background-color: white;

  transition: 0.2s ease;

  &:hover {
    color: ${theme.colors.tint.danger};
    border-color: ${theme.colors.tint.danger};
  }
`

const StyledPhotoItem = styled.div`
  flex-basis: 50%;
  max-width: calc(50% - 2px);
  height: 184px;
  padding: 1px;
  position: relative;
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    ${StyledDeleteButton} {
      opacity: 1;
    }
  }
`

export default NoteDashboardPictureItem
