import theme from 'lib/styles/theme'
import Select from 'react-select'
import styled from 'styled-components'

const SelectBox = ({
  options,
  isSearchable = false,
  isLoading = false,
  placeholder = '',
  onChange,
  minWidth,
  noOptionsMessage = '',
  value,
  mr,
  noborder,
}) => {
  return (
    <StyledSelect
      className="basic-single"
      classNamePrefix="select"
      options={options}
      isSearchable={isSearchable}
      isLoading={isLoading}
      onChange={onChange}
      placeholder={placeholder}
      minWidth={minWidth}
      noOptionsMessage={() => noOptionsMessage}
      //  defaultValue={defaultValue}
      value={value}
      mr={mr}
      components={{
        IndicatorSeparator: () => null,
      }}
      noborder={noborder}
    />
  )
}

const StyledSelect = styled(Select)`
  min-width: 110px;
  font-size: 14px;

  .select__control {
    height: 34px;
    min-height: 34px;
    border: 1px solid ${theme.colors.gray[200]};
    ${({ noborder }) => noborder && `border: none;`};
  }
  .select__value-container {
    height: 32px;
  }
  .select__indicator {
    position: relative;
    width: 14px;
    height: 14px;
    background-color: ${theme.colors.gray[200]};
    border-radius: 3px;
    margin-right: 8px;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 14px;
      height: 14px;
      fill: ${theme.colors.gray[700]};
    }
  }

  .select__input-container {
    height: 100%;
    padding: 0;
  }

  .select__menu-list {
    max-height: 200px;
  }

  .select__placeholder,
  .select__value-container {
    // border-color: ${theme.colors.gray[200]};
  }

  .select__option--is-focused {
    background-color: ${theme.colors.blue[100]};
  }
  .select__option--is-selected {
    background-color: ${theme.colors.blue[400]};
  }
`

export default SelectBox
