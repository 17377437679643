import { useState } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

const FilterItem = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1.2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${theme.colors.gray[100]};

  ${media.mediumS`
    margin-top: 0;
  `}

  ${({ noBorder }) => noBorder && 'border-bottom: none;'}

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  &.hide {
    padding-bottom: 0;
  }
`

const FilterItemTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
`

const FilterItemTitleKey = styled.div`
  font-weight: 600;
`

const FilterItemTitleValue = styled.div`
  flex: 1;

  color: ${theme.colors.primary[500]};
  text-align: right;
  font-size: 0.9rem;
`

const Collapse = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${theme.colors.gray[600]};
`

const MapFilterItem = ({
  title,
  titleValue,
  isLayer = false,
  children,
  noBorder = false,
}) => {
  const [isVisible, setIsVisible] = useState(true)
  return (
    <FilterItem className={!isVisible && 'hide'} noBorder={noBorder}>
      {isLayer && (
        <Collapse onClick={() => setIsVisible((checked) => !checked)}>
          <i className="far fa-chevron-down"></i>
        </Collapse>
      )}
      <FilterItemTitle>
        <FilterItemTitleKey>{title}</FilterItemTitleKey>

        <FilterItemTitleValue>{titleValue}</FilterItemTitleValue>
      </FilterItemTitle>

      {isVisible && <div>{children}</div>}
    </FilterItem>
  )
}

export default MapFilterItem
