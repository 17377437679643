import { useState, useRef } from 'react'
import axios from 'axios'
import { message } from 'antd'

import { ListItemDesc, Tag } from 'components/design'
import { ListItemLabel } from 'components/design'
import { ListItemDivider } from 'components/design'

import NoteUploadExcel from './NoteUploadExcel'
import NoteUploadExcelWrap from './NoteUploadExcelWrap'
import NoteUploadExcelInput from './NoteUploadExcelInput'
import { excelDownloadUrl, excelDownloadUrlVer2 } from 'Constants'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const NoteUploadExcelContainer = ({ onUploadComplete }) => {
  const [tableData, setTableData] = useState([])
  const [tableVisible, setTableVisible] = useState(false)
  const [fileName, setFileName] = useState('')
  const [errorView, setErrorView] = useState('')

  const inputRef = useRef()

  const onSubmit = (files) => {
    if (!files || files.length === 0) return

    // 초기화
    setTableData([])

    // 파라미터 세팅
    const formData = new FormData()
    formData.append('fileToUpload', files?.[0])

    // API 요청
    axios
      .post('/jmapnote/api/note/readFile', formData)
      .then((resp) => {
        if (resp.data.error) {
          message.error(resp.data.message)
          setErrorView(resp.data.message)
          return
        }

        // console.log('resp: ', resp)
        if (resp.data?.result) {
          const result = resp.data.result
          setTableData(result)
          setTableVisible(true)
          onUploadComplete && onUploadComplete()
        }
      })
      .catch((error) => {
        console.log(error)
        message.error('서버 오류')
      })
    reset()
  }

  const reset = () => {
    inputRef.current.value = ''
    setErrorView('')
  }

  const onClose = () => {
    setTableVisible(false)
    setTableData([])
    setFileName('')
    reset()
  }

  const onChangeFile = (value) => {
    onSubmit(value)
  }

  return (
    <>
      <ListItemLabel>엑셀 파일 업로드</ListItemLabel>
      <ListItemDesc>
        매물노트를 대량으로 생성할 수 있도록 엑셀 양식을 제공합니다.
        양식에 맞게 작성해 주세요.
      </ListItemDesc>
      <DownloadWrapper>
        <a href={excelDownloadUrlVer2}>
          <i className="fad fa-download"></i>현행 양식 다운로드
        </a>
        <a href={excelDownloadUrl}>
          <i className="fad fa-download"></i>이전 양식 다운로드
        </a>
      </DownloadWrapper>
      <NoteUploadExcelInput
        fileName={fileName}
        setFileName={setFileName}
        onChange={onChangeFile}
        inputRef={inputRef}
      />

      <ListItemDivider xs />
      {errorView && <Tag color="red">{errorView}</Tag>}

      {tableVisible && (
        <NoteUploadExcelWrap onClose={onClose}>
          <NoteUploadExcel
            onClose={onClose}
            tableData={tableData}
            setTableData={setTableData}
          />
        </NoteUploadExcelWrap>
      )}
    </>
  )
}

const DownloadWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 8px 0;

  a {
    //text-decoration: underline;
    font-size: 14px;
    color: ${theme.colors.blue[400]};
    font-weight: 500;

    &:first-child {
      font-weight: 700;
      color: ${theme.colors.blue[600]};

      &:hover {
        color: ${theme.colors.blue[800]};
      }
    }

    &:hover {
      color: ${theme.colors.blue[700]};
    }
  }

  i {
    margin-right: 4px;
  }
`

export default NoteUploadExcelContainer
