import { getOptions } from 'lib/api/note/noteApi'
import useAxios from 'lib/hooks/useAxios'
import { setNoteOptions } from 'modules/notev2'
import useFolders from 'pages/jmapnote/noteList/hooks/useFolders'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const NoteListContainer = ({ children }) => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const dispatch = useDispatch()

  const noteOptions = useSelector((state) => state.notev2.noteOptions)
  const [onRequestGetOption, , dataOptions] = useAxios(getOptions)

  //옵션은 처음 한번만 호출
  useEffect(() => {
    if (!noteOptions || Object.keys(noteOptions).length == 0)
      onRequestGetOption()
  }, [])

  useEffect(() => {
    if (!dataOptions || !dataOptions?.options) return

    dispatch(setNoteOptions(dataOptions.options))
  }, [dataOptions])

  const {
    _getFolders,
    loading: _getFoldersLoading,
    folders,
  } = useFolders()

  useEffect(() => {
    _getFolders()
  }, [channelInfo?.channel_srl])

  return (
    <>
      {children({
        folders,
        _getFoldersLoading,
      })}
    </>
  )
}

export default NoteListContainer
