import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import MapContext from 'contexts/map'
import { getNotes } from 'lib/api/note/noteApi'
import useAxios from 'lib/hooks/useAxios'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import MapSpecificFavoriteMarkerCustomOverlay from './MapSpecificFavoriteMarkerCustomOverlay'
import theme from 'lib/styles/theme'
import { rgba } from 'polished'

const MapSpecificFavoriteMarkerItem = ({
  item,
  resetMarkerFlag = false,
  setResetMarkerFlag,
}) => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map, viewState } = state

  const dispatch = useDispatch()
  const openedMarker = useSelector((state) => state.note.openedMarker)
  const favoriteData = item
  const [onRequest, loading, data, error, reset, source] =
    useAxios(getNotes)
  const [notes, setNotes] = useState([])

  const history = useHistory()

  const markerRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [payload, setPayload] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const path = `/note/${favoriteData.channel_srl}/${favoriteData.folder_srl}/${favoriteData.address_srl}`
  const isOpened = useMemo(
    () =>
      openedMarker && item && openedMarker.includes(item.address_srl),
    [item, openedMarker],
  )

  const match = useRouteMatch({
    path,
  })

  useEffect(() => {
    return () => {
      setVisible(false)
    }
  }, [])

  useEffect(() => {
    if (!match && viewState.level > 1 && !visible) {
      setIsExpanded(false)
      return
    }
    setIsExpanded(true)
  }, [history.location, viewState, visible])

  useEffect(() => {
    const originPayload = getPayload()
    setPayload(originPayload)
  }, [item, isExpanded])

  const getPayload = useCallback(() => {
    const position = [item.latitude, item.longitude]

    return {
      position: new kakao.maps.LatLng(...position),
      yAnchor: 1,
    }
  }, [item])

  const onInit = useCallback(
    (marker) => {
      if (isExpanded) {
        marker.setZIndex(2)
      } else {
        marker.setZIndex(1)
      }
    },
    [isExpanded],
  )

  useEffect(() => {
    if (data?.message !== 'success') return

    setNotes(data.result)
  }, [data])

  // 매물이 없는 주소는 표시하지 않는다. 필터의 적용 대상이 주소가 아닌 매물이기 때문.
  if (item?.note_count === 0) return null

  return (
    <>
      <MapSpecificFavoriteMarkerCustomOverlay
        overlayRef={markerRef}
        payload={payload}
        onInit={onInit}
        addressSrl={item.address_srl}
        ZIndex={isOpened ? 10000 : 9999}
        draggable
        resetMarkerFlag={resetMarkerFlag}
        setResetMarkerFlag={setResetMarkerFlag}
      >
        <div ref={markerRef}>
          <Center />
          <Circle />
          <Circle type={2} />
        </div>
      </MapSpecificFavoriteMarkerCustomOverlay>
    </>
  )
}

const Center = styled.div`
  position: relative;
  z-index: 1000;
`

const zoomAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
  }
  20% {
    opacity: 0.9;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
`

const Circle = styled.div`
  background: ${rgba(theme.colors.blue[500], 0.5)};
  border-radius: 50%;
  height: 150px;
  width: 150px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${zoomAnimation} 1.5s linear infinite;
  z-index: 99;

  ${({ type }) => {
    switch (type) {
      case 2:
        return `
          animation-delay: 1s;
          z-index: 100;
        `
    }
  }}
`

export default React.memo(MapSpecificFavoriteMarkerItem)
