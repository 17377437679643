import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import {
  Button,
  Empty,
  List,
  ListItem,
  ListItemDesc,
  ListItemLabel,
} from 'components/design'
import axios from 'axios'
import NoteSearchAddressModal from '../elements/NoteSearchAddressModal'
import { AccountInput } from './NoteAccount'
import { media } from 'lib/styles/theme'

/**
 * 계정관리 회사 소재지
 * @returns
 */
const NoteAccountAddress = ({
  disabled,
  setSearchedAddrCode,
  setRealtorMessage,
  setIsValidRealtorNumber,
}) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const [query, setQuery] = useState('')
  const [isEndSearch, setIsEndSearch] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [searchModalVisible, setSearchModalVisible] = useState(false)
  const { register, setValue } = useFormContext()

  /**
   * 주소 검색 api
   */
  const searchAddress = () => {
    axios
      .get(`/map/api/searchAddress?query=${query}`)
      .then((response) => {
        const result = response.data.documents
        setIsEndSearch(true)
        setSearchResults(result)
      })
  }

  const handleCancel = () => {
    setSearchModalVisible(false)
  }

  /**
   * 주소 검색 - 선택 동작
   * @param {*} item
   */
  const onClickItem = (item) => {
    setValue('company_address_num', item.road_address?.zone_no || '') //우편번호
    setValue(
      'company_address_road',
      item.road_address?.address_name || '',
    ) //도로명
    setValue('company_address_name', item.address?.address_name || '') //지번
    setValue(
      'company_address_extra',
      `(${item.address?.region_3depth_name}, ${item.road_address?.building_name})` ||
        '',
    ) //부가정보

    setSearchedAddrCode({
      b_code: item.address?.b_code,
      h_code: item.address?.h_code,
    }) //중개업 등록번호 검색을 위해 저장할 b_code, h_code

    setRealtorMessage('')
    setIsValidRealtorNumber(false)
  }

  return disabled ? (
    <div>
      <div>{loginInfo?.company_address_raw?.[0]}</div>
      <div>{loginInfo?.company_address_raw?.[1]}</div>
      <div>{loginInfo?.company_address_raw?.[2]}</div>
    </div>
  ) : (
    <>
      <AddressWrapper>
        <AddressItem>
          <FirstAddressWrapper>
            <AccountInput
              readOnly
              name="company_address_num"
              defaultValue={loginInfo?.company_address_raw?.[0] ?? ''}
              design={'box'}
              {...register('company_address_num')}
              small="Y"
            />
            {!disabled && (
              <StyledButton
                onClick={() => setSearchModalVisible(true)}
                color="blue"
              >
                검색
              </StyledButton>
            )}
          </FirstAddressWrapper>
        </AddressItem>
        <AddressItem>
          <AccountInput
            expand
            readOnly
            name="company_address_road"
            defaultValue={loginInfo?.company_address_raw?.[1] ?? ''}
            design={'box'}
            {...register('company_address_road')}
          />
        </AddressItem>

        <AddressItem>
          <AccountInput
            expand
            readOnly
            name="company_address_name"
            defaultValue={loginInfo?.company_address_raw?.[2] ?? ''}
            design={'box'}
            {...register('company_address_name')}
          />
        </AddressItem>
      </AddressWrapper>

      <NoteSearchAddressModal
        visible={searchModalVisible}
        searchAddress={searchAddress}
        handleCancel={handleCancel}
        query={query}
        setQuery={setQuery}
        list={
          isEndSearch && searchResults.length == 0 ? (
            <Empty>검색결과가 없습니다.</Empty>
          ) : (
            searchResults &&
            searchResults.length > 0 && (
              <List noContainer>
                {searchResults.map((item, i) => (
                  <ListItem
                    key={i}
                    onClick={() => {
                      onClickItem(item)
                      handleCancel()
                    }}
                    noContainer
                    dense
                  >
                    <ListItemLabel>{item.address_name}</ListItemLabel>
                    {item.road_address && (
                      <ListItemDesc>
                        {item.road_address.road_name}{' '}
                        {item.road_address.main_building_no}{' '}
                        {item.road_address?.building_name &&
                          `(${item.road_address?.building_name})`}
                      </ListItemDesc>
                    )}
                  </ListItem>
                ))}
              </List>
            )
          )
        }
      />
    </>
  )
}

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const AddressItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledButton = styled(Button)`
  flex-shrink: 0;
  height: 34px;
`

const FirstAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;

  ${media.mediumS`
    input {
      width: 70%;
    }
  `}
`

export default NoteAccountAddress
