import { getDeunggibuList } from 'lib/api/notev2/contractApi'
import { getPlan } from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NoteNoPlanPopup from './NoteNoPlanPopup'
import { onlyUnique } from 'utils'
import { useSelector } from 'react-redux'

const NotePlanNotiContainer = ({
  children,
  channel_srl,
  contract_srl,
  note_srl,
  isNoplanPopup,
  goToDeunggibuIssuance,
}) => {
  const [onRequestDeunggi, , dataDeunggi] = useAxios(getDeunggibuList)
  const [onRequestGetPlan, , dataGetPlan] = useAxios(getPlan)

  const [deunggiData, setDeunggiData] = useState([])
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const history = useHistory()

  const loginInfo = useSelector((state) => state.auth.loginInfo)

  // 요금제 관련 상태
  // no-plan: 등기부등본 구입을 안한경우
  // no-use: 등기부등본 구입은 했으나 아직 발급받지 않은 경우
  // buy-deunggi: 등기부등본 구입한 횟수를 모두 소진한 경우
  // buy-plan :요금제 기간이 끝나거나 횟수소진 + 기간끝남
  const [relatedToPaymentType, setRelatedToPaymentType] = useState('')

  useEffect(() => {
    if (!channel_srl || !note_srl) return

    onRequestDeunggi({
      channel_srl,
      note_srl,
      doc_types: ['등기부등본'],
      status: ['complete'],
    })
  }, [])

  useEffect(() => {
    if (!dataDeunggi) return

    setDeunggiData(dataDeunggi?.result || [])
    onRequestGetPlan()
  }, [dataDeunggi])

  useEffect(() => {
    if (!dataGetPlan) return

    const result = dataGetPlan.result

    if (!result) {
      //요금제 구입 하지 않은 경우
      setRelatedToPaymentType('no-plan')

      if (isNoplanPopup && isFisrtAccess()) {
        setIsPopupVisible(true)
      }
    } else {
      const status = result.status
      if (status == 1) {
        //요금제가 있음(정상)
        //해당 등기부 등본 발급을 안했다면
        if (deunggiData.length == 0) {
          setRelatedToPaymentType('no-use')

          if (isNoplanPopup && isFisrtAccess()) {
            setIsPopupVisible(true)
          }
        }
      } else if (status == 2) {
        //등기부 개수를 다씀 -> 추가 충전 안내(등기부등본 없을때만 보여줌)
        if (deunggiData.length == 0) {
          setRelatedToPaymentType('buy-deunggi')
        }
      } else if (status == 3) {
        //요금제 유효기간 만료 -> 요금제 구매 안내(등기부등본 없을때만 보여줌)
        if (deunggiData.length == 0) {
          setRelatedToPaymentType('buy-plan')
        }
      } else if (status == 4) {
        //요금제 유효기간 만료 + 등기부 개수 다씀 -> 요금제 구매 안내(등기부등본 없을때만 보여줌)
        if (deunggiData.length == 0) {
          setRelatedToPaymentType('buy-plan')
        }
      }
    }

    return () => {
      setRelatedToPaymentType('')
    }
  }, [dataGetPlan])

  /**
   * 요금제 페이지 이동
   */
  const showPlan = () => {
    history.push('/note/v2/payment/plan')
  }

  /**
   * 요금제 갱신(대시보드) 페이지 이동
   */
  const showRenew = () => {
    history.push('/note/v2/payment')
  }
  /**
   * 추가 충전 페이지 이동
   */
  const showAddDeunggi = () => {
    history.push('/note/v2/payment/addDeunggi')
  }

  useEffect(() => {
    if (!contract_srl) {
      return
    }
    settingRecentSrls(contract_srl)
  }, [contract_srl])

  const isFisrtAccess = () => {
    if (!contract_srl) return true

    const parsedData = JSON.parse(
      localStorage.getItem('recentContractSrls') || '[]',
    )

    if (parsedData.includes(contract_srl)) {
      return false
    }

    settingRecentSrls(contract_srl)
    return true
  }

  /**
   * localstorage에 접속했던 계약서 srl 저장
   * @param {*} contract_srl
   */
  const settingRecentSrls = (contract_srl) => {
    const localStorageContractSrls =
      localStorage.getItem('recentContractSrls') || '[]'
    const parsedList = JSON.parse(localStorageContractSrls)
    localStorage.setItem(
      'recentContractSrls',
      JSON.stringify(
        [...parsedList, ...[contract_srl]].filter(onlyUnique),
      ),
    )
  }

  return children({
    relatedToPaymentType,
    showPlan,
    showRenew,
    showAddDeunggi,
    noPlanPopup: (
      <NoteNoPlanPopup
        title={
          relatedToPaymentType == 'no-plan'
            ? '요금제를 구매해보시겠어요?'
            : '등기부등본을 발급하시겠어요?'
        }
        visible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        onOk={
          relatedToPaymentType == 'no-plan'
            ? showPlan
            : goToDeunggibuIssuance
        }
      />
    ),
  })
}

export default NotePlanNotiContainer
