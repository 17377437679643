import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import CustomContactCellItem from './CustomContactCellItem'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import {
  eachSmallRowHeight,
  newContactObj,
} from 'lib/utils/notev2/note2Constants'
import { CustomSingleCellStyle } from 'components/jmapnotev2/style/NoteStyle'
import { isPressEditKey } from 'lib/utils/notev2/suppressKey'
import { getMaxRowHeight } from 'lib/utils/notev2/gridData'

const CustomContactCell = ({
  gridApi,
  value: rowContactData,
  node,
  contacts,
  noteSrl,
}) => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [contactList, setContactList] = useState([])

  const noteContacts = useSelector((state) => state.notev2.noteContacts)
  const [isReversePosition, setIsReversePosition] = useState(false) //선택 레이어가 위쪽으로 포지셔닝 하는지 여부

  useEffect(() => {
    setContactList(
      noteContacts
        ?.filter(({ numbers }) => numbers.length > 0)
        .map((item) => {
          return {
            contact_srl: item.contact_srl,
            number: item.numbers[0]?.number?.replaceAll('-', ''),
            oriValue: item,
            alias: item.alias,
            oriNumber: item.numbers[0]?.number || '',
            value: `${item.alias}, ${
              item.numbers[0]?.number
            } ${item.numbers[0]?.number?.replaceAll('-', '')}`,
            label: (
              <div>
                <SelectLabelName>{item.alias}</SelectLabelName>
                {item.numbers[0]?.number}
              </div>
            ),
          }
        }),
    )
  }, [noteContacts])

  /**
   * 추가 버튼 클릭
   */
  const onClickAddContact = useCallback(() => {
    if (node.data.status == 2) return

    updateGridDatas({
      currentRowNode: node,
      newData: {
        contacts:
          contacts && contacts.length > 0
            ? [...contacts, newContactObj]
            : [newContactObj],
      },
    })

    const nextHeight = node.data.contacts.length * eachSmallRowHeight

    if (nextHeight > node.rowHeight) {
      node.setRowHeight(node.rowHeight + eachSmallRowHeight)
      gridApi.onRowHeightChanged()
    }
  }, [node, contacts, node.data.status])

  /**
   * 연락처 dropdown 열렸을 때 가려짐 여부를 판단하여 가려지지 않게 위로 띄움
   */
  const calcReverse = () => {
    const rowIndex = node?.rowIndex
    const limitHeight = 200
    if (!rowIndex) return

    const target = Array.from(
      document.getElementsByClassName('ag-row'),
    ).find((elem) => {
      return elem.getAttribute('row-index') == rowIndex
    })
    const topPos = target.getBoundingClientRect().top
    const rowHeight = target.clientHeight
    const pos = topPos + rowHeight
    const diff = window.innerHeight - pos

    if (diff < limitHeight) {
      setIsReversePosition(true)
    }
  }

  /**
   * 연락처 데이터가 하나도 없을 때 더블클릭하면 -> 수정모드 = 연락처 하나 추가
   */
  const onDoubleClickCell = useCallback(() => {
    if (channelInfo?.member_type == 'readonly') return
    if (contacts.length == 0) {
      onClickAddContact()
    }
    calcReverse()
  }, [contacts])

  const onKeyDownCell = useCallback(
    (e) => {
      if (channelInfo?.member_type == 'readonly') return
      if (isPressEditKey(e) && contacts.length == 0) {
        onClickAddContact()
      }
      calcReverse()
    },
    [contacts],
  )

  /**
   *  새로운 행 제거
   */
  const removeNewLine = () => {
    if (!rowContactData || rowContactData.length < 1) return

    const notNewLineData = rowContactData.filter(
      (item) => !item?.isNewContact,
    )

    updateGridDatas({
      currentRowNode: node,
      newData: {
        contacts: notNewLineData,
      },
    })

    const maxHeight = getMaxRowHeight({
      contract_type: node.data.contract_type,
    })
    const willChangeHeight =
      notNewLineData.length * eachSmallRowHeight + 1

    if (willChangeHeight >= maxHeight) {
      node.setRowHeight(willChangeHeight)
      gridApi.onRowHeightChanged()
    }
  }

  if (node.data.note_srl == '신규') return <></>

  return (
    <Wrapper
      onDoubleClick={onDoubleClickCell}
      onKeyDown={onKeyDownCell}
      rowHeight={node.rowHeight}
      tabIndex={contacts && contacts.length > 0 ? -1 : 0}
    >
      <ContactList>
        {rowContactData &&
          [...rowContactData]
            ?.sort((a, b) => a.type - b.type)
            .map((item, i) => {
              return (
                <CustomContactCellItem
                  key={i}
                  type={item.type}
                  item={item}
                  numbers={item.numbers[0].number}
                  node={node}
                  //    data={data}
                  noteContactSrl={item.note_contact_srl}
                  contactSrl={item.contact_srl}
                  alias={item.alias}
                  contactList={contactList}
                  rowContactData={rowContactData}
                  contacts={contacts}
                  noteSrl={noteSrl}
                  removeNewLine={removeNewLine}
                  isNewContact={item.isNewContact}
                  isReversePosition={isReversePosition}
                  calcReverse={calcReverse}
                  i={i}
                />
              )
            })}
      </ContactList>
      {channelInfo?.member_type != 'readonly' && (
        <AddContact
          disabled={node.data.status == 2}
          onClick={onClickAddContact}
        >
          <i className="fa fa-plus"></i>
        </AddContact>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: none;
  ${CustomSingleCellStyle}
`

const ContactList = styled.div`
  display: flex;
  min-width: 88%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

const AddContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  cursor: pointer;
  z-index: 1;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: ${theme.colors.gray[200]};
    border-radius: 3px;
    font-size: 8px;
    margin-right: 6px;
  }

  ${({ disabled }) => disabled && `cursor: default;`}
`

const SelectLabelName = styled.span`
  margin-right: 6px;
  padding-right: 6px;
  border-right: 1px solid ${theme.colors.gray[300]};
  font-weight: 600;
`

export default React.memo(CustomContactCell)
