import { useFormContext } from 'react-hook-form'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
} from '../../form/NoteAdFormStyle'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 소재지
 */
const NoteAdColumnAddress = ({ half = false }) => {
  const { watch } = useFormContext()

  const address = watch('address')

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>소재지</ColumnTitle>
      <ColumnVal>{address}</ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnAddress
