import { Checkbox } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  TableContent,
  InnerTable,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  innerSubHeaderWidth,
  StyledTable,
  subHeaderWidth,
  RadioButtonsWrap,
  RowSpan,
  AmongSpan,
  StyledRadioButtonsWrap,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { media } from 'lib/styles/theme'
import { handleFocusNumber } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsDetail10 = ({ onUpdate }) => {
  const { watch } = useFormContext()

  const d_is_sudopason = watch('d_is_sudopason')
  const d_sudopasonspot = watch('d_sudopasonspot')
  const d_sudoyongsulyang = watch('d_sudoyongsulyang')
  const d_sudoyongsulyang_spot = watch('d_sudoyongsulyang_spot')
  const d_is_electricity_supply = watch('d_is_electricity_supply')
  const d_electricity_replaced = watch('d_electricity_replaced')
  const d_gas_system = watch('d_gas_system')
  const d_gas_etc = watch('d_gas_etc')
  const d_has_firedetector = watch('d_has_firedetector')
  const d_firedetector_cnt = watch('d_firedetector_cnt')
  const d_heating_system = watch('d_heating_system')
  const d_heating_jagdong = watch('d_heating_jagdong')
  const d_heating_yeonhan = watch('d_heating_yeonhan')
  const d_heating_unknown = watch('d_heating_unknown')
  const d_heating_type = watch('d_heating_type')
  const d_heating_type_etc = watch('d_heating_type_etc')
  const d_heating_suseon = watch('d_heating_suseon')
  const d_lift = watch('d_lift')
  const d_lift_status = watch('d_lift_status')
  const d_baesu = watch('d_baesu')
  const d_baesu_suseon = watch('d_baesu_suseon')
  const d_etc_siseolmul = watch('d_etc_siseolmul')

  const d_descript_type = watch('d_descript_type')
  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑪'
    } else {
      return '⑨'
    }
  }
  return (
    <>
      <StyledTable>
        <Row height={31} rowspan={14} noborderbottom>
          <TableHeader width={headerWidth}>
            {getNumber()} 내부·
            <br />
            외부 시설물의
            <br /> 상태
            <br />
            &#40;건축물&#41;
          </TableHeader>
          <RowSpanInner
            rowspanheightpercent={15}
            rowspan={2}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>수도</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={50}>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    파손여부
                  </TableSubHeader>
                  <InnerTableContent
                    headerwidth={innerSubHeaderWidth}
                    checkboxes
                  >
                    <StyledRadioButtonsWrap>
                      <RadioButton
                        label="없음"
                        checked={
                          d_is_sudopason == '없음' ||
                          d_is_sudopason == ''
                        }
                        name="d_is_sudopason"
                        onChange={(e) =>
                          onUpdate('d_is_sudopason', '없음')
                        }
                      />
                      <RadioButton
                        label="있음"
                        checked={d_is_sudopason == '있음'}
                        name="d_is_sudopason"
                        onChange={(e) =>
                          onUpdate('d_is_sudopason', '있음')
                        }
                      />
                    </StyledRadioButtonsWrap>
                    <AmongSpan>&#40; 위치: </AmongSpan>
                    <ColorInput
                      type="text"
                      defaultValue={d_sudopasonspot}
                      disabled={d_is_sudopason != '있음'}
                      onBlur={(e) =>
                        onUpdate('d_sudopasonspot', e.target.value)
                      }
                      fullWidth
                    />
                    &#41;
                  </InnerTableContent>
                </Row>
                <Row heightpercent={50} noborderbottom>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    용수량
                  </TableSubHeader>
                  <InnerTableContent
                    headerwidth={innerSubHeaderWidth}
                    checkboxes
                  >
                    <StyledRadioButtonsWrap>
                      <RadioButton
                        label="정상"
                        checked={
                          d_sudoyongsulyang == '정상' ||
                          d_sudoyongsulyang == ''
                        }
                        name="d_sudoyongsulyang"
                        onChange={(e) =>
                          onUpdate('d_sudoyongsulyang', '정상')
                        }
                      />
                      <RadioButton
                        label="부족함"
                        checked={d_sudoyongsulyang == '부족함'}
                        name="d_sudoyongsulyang"
                        onChange={(e) =>
                          onUpdate('d_sudoyongsulyang', '부족함')
                        }
                      />
                    </StyledRadioButtonsWrap>
                    <AmongSpan>&#40; 위치: </AmongSpan>
                    <ColorInput
                      type="text"
                      defaultValue={d_sudoyongsulyang_spot}
                      disabled={d_sudoyongsulyang != '부족함'}
                      onBlur={(e) =>
                        onUpdate(
                          'd_sudoyongsulyang_spot',
                          e.target.value,
                        )
                      }
                      fullWidth
                    />
                    &#41;
                  </InnerTableContent>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={7}
            rowspan={2}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>전기</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={100} noborderbottom>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    공급상태
                  </TableSubHeader>
                  <InnerTableContent
                    headerwidth={innerSubHeaderWidth}
                    checkboxes
                  >
                    <StyledRadioButtonsWrap>
                      <RadioButton
                        label="정상"
                        checked={
                          d_is_electricity_supply == '정상' ||
                          d_is_electricity_supply == ''
                        }
                        name="d_is_electricity_supply"
                        onChange={(e) =>
                          onUpdate('d_is_electricity_supply', '정상')
                        }
                      />
                      <RadioButton
                        label="교체 필요"
                        checked={d_is_electricity_supply == '교체 필요'}
                        name="d_is_electricity_supply"
                        onChange={(e) =>
                          onUpdate(
                            'd_is_electricity_supply',
                            '교체 필요',
                          )
                        }
                      />
                    </StyledRadioButtonsWrap>
                    <AmongSpan width={90}>&#40; 교체할 부분:</AmongSpan>
                    <ColorInput
                      type="text"
                      defaultValue={
                        d_is_electricity_supply === '교체 필요' &&
                        d_electricity_replaced == ''
                          ? '도시가스'
                          : d_electricity_replaced
                      }
                      disabled={d_is_electricity_supply != '교체 필요'}
                      onBlur={(e) =>
                        onUpdate(
                          'd_electricity_replaced',
                          e.target.value,
                        )
                      }
                      fullWidth
                    />
                    &#41;
                  </InnerTableContent>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={9}
            rowspan={2}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>
              가스
              <br />
              &#40;취사용&#41;
            </TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={100} noborderbottom>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    공급방식
                  </TableSubHeader>
                  <InnerTableContent
                    headerwidth={innerSubHeaderWidth}
                    checkboxes
                  >
                    <StyledRadioButtonsWrap>
                      <RadioButton
                        label="도시가스"
                        checked={
                          d_gas_system == '도시가스' ||
                          d_gas_system == ''
                        }
                        name="d_gas_system"
                        onChange={(e) =>
                          onUpdate('d_gas_system', '도시가스')
                        }
                      />
                      <RadioButton
                        label={<Text>그 밖의 방식</Text>}
                        checked={d_gas_system == '그 밖의 방식'}
                        name="d_gas_system"
                        onChange={(e) =>
                          onUpdate('d_gas_system', '그 밖의 방식')
                        }
                      />
                    </StyledRadioButtonsWrap>
                    <ColorInput
                      type="text"
                      defaultValue={d_gas_etc}
                      disabled={d_gas_system != '그 밖의 방식'}
                      onBlur={(e) =>
                        onUpdate('d_gas_etc', e.target.value)
                      }
                      fullWidth
                    />
                  </InnerTableContent>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={21}
            rowspan={2}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>소방</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={100} noborderbottom>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    단독경보형
                    <br />
                    감지기
                  </TableSubHeader>
                  <TableContent
                    minuswidth={innerSubHeaderWidth}
                    noborderright
                    nopadding
                  >
                    <InnerTableBlock
                      widthpercent={60}
                      noborderbottom
                      noborderright
                    >
                      <InnerTableContent>
                        <StyledRadioButtonsWrap>
                          <RadioButton
                            label="없음"
                            checked={
                              d_has_firedetector == '없음' ||
                              d_has_firedetector == ''
                            }
                            name="d_has_firedetector"
                            onChange={(e) =>
                              onUpdate('d_has_firedetector', '없음')
                            }
                          />
                          <RadioButton
                            label="있음"
                            checked={d_has_firedetector == '있음'}
                            name="d_has_firedetector"
                            onChange={(e) =>
                              onUpdate('d_has_firedetector', '있음')
                            }
                          />
                        </StyledRadioButtonsWrap>
                        <Text>&#40; 수량: &nbsp;</Text>
                        <ColorInput
                          width={120}
                          type="text"
                          defaultValue={d_firedetector_cnt}
                          disabled={d_has_firedetector != '있음'}
                          onBlur={(e) =>
                            onUpdate(
                              'd_firedetector_cnt',
                              e.target.value,
                            )
                          }
                          append="개"
                          onClick={handleFocusNumber}
                        />
                        &#41;
                      </InnerTableContent>
                    </InnerTableBlock>
                    <InnerTableBlockDesc
                      widthpercent={40}
                      noborderbottom
                      noborderright
                    >
                      「소방시설 설치 및 관리에 관한 법률」 제10조 및
                      같은 법 시행령 제10조에 따른 주택용 소방시설로서
                      아파트(주택으로 사용하는 층수가 5개층 이상인
                      주택을 말한다)를 제외한 주택의 경우만 적습니다.
                    </InnerTableBlockDesc>
                  </TableContent>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={22}
            rowspan={2}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>
              난방방식 및<br />
              연료공급
            </TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={64}>
                  <InnerTableBlock widthpercent={42} noborderbottom>
                    <TableSubHeader width={innerSubHeaderWidth}>
                      공급방식
                    </TableSubHeader>
                    <InnerTableContent>
                      <StyledRadioButtonsWrap>
                        <RadioButton
                          label="중앙공급"
                          checked={
                            d_heating_system == '중앙공급' ||
                            d_heating_system == ''
                          }
                          name="d_heating_system"
                          onChange={(e) =>
                            onUpdate('d_heating_system', '중앙공급')
                          }
                        />
                        <RadioButton
                          label="개별공급"
                          checked={d_heating_system == '개별공급'}
                          name="d_heating_system"
                          onChange={(e) =>
                            onUpdate('d_heating_system', '개별공급')
                          }
                        />
                      </StyledRadioButtonsWrap>
                    </InnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock
                    widthpercent={60}
                    noborderbottom
                    noborderright
                  >
                    <TableSubHeader width={subHeaderWidth}>
                      시설작동
                    </TableSubHeader>
                    <HeatingWrapper>
                      <HeatingInputWrapper>
                        <RadioButton
                          label="정상"
                          checked={
                            d_heating_jagdong == '정상' ||
                            d_heating_jagdong == ''
                          }
                          name="d_heating_jagdong"
                          onChange={(e) =>
                            onUpdate('d_heating_jagdong', '정상')
                          }
                        />
                        <RadioButton
                          label="수선 필요"
                          checked={d_heating_jagdong == '수선 필요'}
                          name="d_heating_jagdong"
                          onChange={(e) =>
                            onUpdate('d_heating_jagdong', '수선 필요')
                          }
                        />
                        <ColorInput
                          width={150}
                          type="text"
                          defaultValue={d_heating_suseon}
                          disabled={d_heating_jagdong != '수선 필요'}
                          onBlur={(e) =>
                            onUpdate('d_heating_suseon', e.target.value)
                          }
                        />
                      </HeatingInputWrapper>
                      <HeatingInputWrapper sm>
                        ※ 개별 공급인 경우 사용연한: &nbsp;
                        <ColorInput
                          width={80}
                          type="text"
                          defaultValue={d_heating_yeonhan}
                          onBlur={(e) =>
                            onUpdate(
                              'd_heating_yeonhan',
                              e.target.value,
                            )
                          }
                        />
                        <CheckboxWrappr>
                          <Checkbox
                            color="blue"
                            checked={
                              d_heating_unknown == 'Y' ? true : false
                            }
                            onChange={(e) => {
                              onUpdate(
                                'd_heating_unknown',
                                e ? 'Y' : 'N',
                              )
                            }}
                          >
                            확인불가
                          </Checkbox>
                        </CheckboxWrappr>
                      </HeatingInputWrapper>
                    </HeatingWrapper>
                  </InnerTableBlock>
                </Row>
                <Row heightpercent={36} noborderbottom>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    종류
                  </TableSubHeader>
                  <InnerTableContent
                    headerwidth={innerSubHeaderWidth}
                    checkboxes
                  >
                    <RadioButton
                      label="도시가스"
                      checked={
                        d_heating_type == '도시가스' ||
                        d_heating_type == ''
                      }
                      name="d_heating_type"
                      onChange={(e) =>
                        onUpdate('d_heating_type', '도시가스')
                      }
                    />
                    <RadioButton
                      label="기름"
                      checked={d_heating_type == '기름'}
                      name="d_heating_type"
                      onChange={(e) =>
                        onUpdate('d_heating_type', '기름')
                      }
                    />
                    <RadioButton
                      label="프로판가스"
                      checked={d_heating_type == '프로판가스'}
                      name="d_heating_type"
                      onChange={(e) =>
                        onUpdate('d_heating_type', '프로판가스')
                      }
                    />
                    <RadioButton
                      label="연탄"
                      checked={d_heating_type == '연탄'}
                      name="d_heating_type"
                      onChange={(e) =>
                        onUpdate('d_heating_type', '연탄')
                      }
                    />
                    <RadioButton
                      label="그 밖의 종류"
                      checked={d_heating_type == '그 밖의 종류'}
                      name="d_heating_type"
                      onChange={(e) =>
                        onUpdate('d_heating_type', '그 밖의 종류')
                      }
                    />
                    <ColorInput
                      width={165}
                      type="text"
                      defaultValue={d_heating_type_etc}
                      disabled={d_heating_type != '그 밖의 종류'}
                      onBlur={(e) =>
                        onUpdate('d_heating_type_etc', e.target.value)
                      }
                    />
                  </InnerTableContent>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={8}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>승강기</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTableContent>
                <RadioButton
                  label="있음"
                  checked={
                    d_lift == '있음' || d_lift == '' || d_lift > 0
                  }
                  name="d_lift"
                  onChange={(e) => onUpdate('d_lift', '있음')}
                />
                <SubCheckBoxes>
                  <RadioButton
                    label="양호"
                    checked={d_lift_status == '양호' || d_lift > 0}
                    name="d_lift_status"
                    onChange={(e) => onUpdate('d_lift_status', '양호')}
                  />
                  <RadioButton
                    label="불량"
                    checked={d_lift_status == '불량'}
                    name="d_lift_status"
                    onChange={(e) => onUpdate('d_lift_status', '불량')}
                  />
                </SubCheckBoxes>
                <RadioButton
                  label="없음"
                  checked={d_lift == '없음'}
                  name="d_lift"
                  onChange={(e) => onUpdate('d_lift', '없음')}
                />
              </InnerTableContent>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={8}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>배수</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTableContent checkboxes fullWidth>
                <StyledRadioButtonsWrap>
                  <RadioButton
                    label="정상"
                    checked={d_baesu == '정상' || d_baesu == ''}
                    name="d_baesu"
                    onChange={(e) => onUpdate('d_baesu', '정상')}
                  />
                  <RadioButton
                    label="수선 필요"
                    checked={d_baesu == '수선 필요'}
                    name="d_baesu"
                    onChange={(e) => onUpdate('d_baesu', '수선 필요')}
                  />
                </StyledRadioButtonsWrap>
                <ColorInput
                  type="text"
                  defaultValue={d_baesu_suseon}
                  disabled={d_baesu != '수선 필요'}
                  onBlur={(e) =>
                    onUpdate('d_baesu_suseon', e.target.value)
                  }
                  fullWidth
                />
              </InnerTableContent>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={10}
            minuswidth={headerWidth}
            noborderbottom
          >
            <TableSubHeader width={headerWidth}>
              그 밖의
              <br />
              시설물
            </TableSubHeader>
            <TableContent fullwidth noborderright>
              <ColorInput
                type="text"
                defaultValue={d_etc_siseolmul}
                onBlur={(e) =>
                  onUpdate('d_etc_siseolmul', e.target.value)
                }
                fullWidth
              />
            </TableContent>
          </RowSpanInner>
        </Row>
      </StyledTable>
    </>
  )
}

const InnerTableBlockDesc = styled(InnerTableBlock)`
  font-size: 12px;
  align-items: center;
  padding-right: 10px;
`

const HeatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`

const HeatingInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50%;

  & > label {
    margin-right: 10px;
  }

  ${media.mediumS`
    align-items: normal;
    word-break: keep-all;
    width: 97%;
  `}
`

const CheckboxWrappr = styled.div`
  margin-left: 10px;
`

const SubCheckBoxes = styled.div`
  display: flex;
  align-items: center;
  margin-right: 38px;
  margin-left: -16px;

  &::before {
    content: '(';
  }
  &::after {
    content: ')';
  }

  & > label {
    margin-right: 0;
    span {
      min-width: unset;
    }
  }
`

const Text = styled.div`
  flex-shrink: 0;
`

export default NoteDocsDetail10
