import styled from 'styled-components'

const StyledSelect = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin: -0.25rem;
`

const NoteFilterSelect = ({ children }) => {
  return <StyledSelect>{children}</StyledSelect>
}

export default NoteFilterSelect
