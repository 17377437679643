import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { reregisterListing } from 'lib/api/notev2/adSubmit'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import useAdValidate from 'lib/hooks/notev2/useAdValidate'

/**
 * 광고 목록 버튼
 * 다시 광고 등록
 * @param adSrl
 * @param callbackFn 삭제 후 목록 재조회
 * @param setUpdatedAdSrl 재조회를 위해 저장하는 ad_srl
 * @param forForm 폼에서 사용하는지 여부
 */
const ReRegisterButton = ({
  adSrl,
  callbackFn,
  setUpdatedAdSrl,
  forForm,
}) => {
  const { setError, setFocus } = useFormContext()

  const planInfo = useSelector((state) => state.auth.planInfo)

  const { validate } = useAdValidate()
  const [onRequestReRegister, , dataReRegister] = useAxios(
    reregisterListing,
    {
      resetError: true,
    },
  ) // 재전송하기(다시 광고 등록)

  const onClickReRegister = (adSrl) => {
    onRequestReRegister({
      ad_srl: adSrl,
      plan_srl: planInfo.allinone_plan_srl,
    })
    setUpdatedAdSrl && setUpdatedAdSrl(adSrl)
  }

  useEffect(() => {
    if (!dataReRegister) return
    if (
      dataReRegister?.result?.errors &&
      dataReRegister?.result?.errors.length > 0
    ) {
      const validationError = dataReRegister?.result?.errors
      //에러 세팅
      validate(validationError)
      return
    }

    message.success('재전송이 완료되었습니다.')
    callbackFn && callbackFn()
  }, [dataReRegister])

  return (
    <AdButton
      expand
      forForm
      size={forForm ? 'xlarge' : 'small'}
      color="blue"
      border={!forForm}
      blueBorderLight={!forForm}
      onClick={() => onClickReRegister(adSrl)}
    >
      다시 광고 등록
    </AdButton>
  )
}

export default ReRegisterButton
