import {
  HelpCardHeader,
  Title,
  Price,
  HelpIcon,
  InfoWrap,
  BuildingPriceInfo,
  Hr,
} from '../SummarySizeReview/SummarySizeReviewStyle'

import { priceFormatMilion } from 'lib/utils/priceFormat'
import { Card, CardHeader, Toggle } from 'components/design'
import { CardBody } from 'components/design'
import theme from 'lib/styles/theme'
import useIsMobile from 'lib/hooks/useIsMobile'
import styled from 'styled-components'

const SummaryNohuToggle = ({
  isMine,
  setIsMine,
  isRentalAll,
  setIsRentalAll,
}) => {
  const isMobile = useIsMobile()

  return (
    <Card>
      <CardHeader>
        <Title>비용 포함 옵션을 선택해보세요</Title>
      </CardHeader>
      <CardBody>
        <ToggleWrap>
          <ToggleTitle>건물 소유 여부</ToggleTitle>
          <ToggleText isActive={!isMine}>비소유</ToggleText>
          <Toggle
            size="xs"
            checked={isMine}
            onChange={() => setIsMine(!isMine)}
          ></Toggle>
          <ToggleText isActive={isMine}>소유</ToggleText>
        </ToggleWrap>
        <ToggleWrap>
          <ToggleTitle>임대 방법</ToggleTitle>
          <ToggleText isActive={isRentalAll}>전체</ToggleText>
          <Toggle
            size="xs"
            checked={!isRentalAll}
            onChange={() => setIsRentalAll(!isRentalAll)}
          ></Toggle>
          <ToggleText isActive={!isRentalAll}>증축 부위만</ToggleText>
        </ToggleWrap>
      </CardBody>
    </Card>
  )
}

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;

  &:nth-of-type(1) {
    margin-bottom: 12px;
  }
`

const ToggleTitle = styled.div`
  width: 120px;
  font-weight: 600;
  font-size: 15px;
`

const ToggleText = styled.span`
  font-size: 14px;
  color: ${theme.colors.gray[600]};

  ${({ isActive }) =>
    isActive &&
    `
      color: ${theme.colors.primary[500]};
      font-weight: 600;
    `};

  &:nth-of-type(1) {
    margin-right: 8px;
  }
  &:nth-last-of-type(1) {
    margin-left: 8px;
  }
`

export default SummaryNohuToggle
