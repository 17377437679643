import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const InviteAuthContainer = ({ children, setType }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const inviteeInfo = useSelector(
    (state) => state.inviteReward.inviteeInfo,
  )
  const history = useHistory()

  useEffect(() => {
    //초대자 정보 없으면 home으로 보냄
    if (!inviteeInfo) {
      history.replace('/home')
    }

    if (loginInfo?.member_srl && loginInfo?.member_srl != 0) {
      //로그인한 사람은 home으로 보냄
      history.replace('/home')
    } else {
      //로그인 안 한 사람은 이벤트 회원가입 or 로그인 모달
      setType('firstSelect')
    }
  }, [])

  return children
}

export default InviteAuthContainer
