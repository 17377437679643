import theme from 'lib/styles/theme'
import { setSelectedDong } from 'modules/noteDongho'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

const NoteDonghoHeaderDongs = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const dongs = useSelector(
    (state) => state.noteDongho.selectedDanjiDongs,
  )
  const selectedDong = useSelector(
    (state) => state.noteDongho.selectedDong,
  )

  const [showMore, setShowMore] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const ref = useRef(null)

  const onClickOpen = () => {
    setShowMore(!showMore)
  }

  /**
   * 펼치기/접기 판단
   */
  useLayoutEffect(() => {
    if (!ref?.current || ref?.current.scrollHeight == 38) {
      setShowLink(false)
      return
    }
    const element = ref?.current
    const offsetHeight = element.offsetHeight
    const scrollHeight = element.scrollHeight

    if (offsetHeight < scrollHeight) {
      setShowLink(true)
    } else {
      setShowLink(false)
    }
  }, [ref?.current, dongs])

  /**
   * 동 클릭
   */
  const onClickDong = (dong) => {
    if (dong) {
      history.replace(`/note/v2/dongho/${params?.id}/${dong}`)
    } else {
      dispatch(setSelectedDong(null))
      history.replace(`/note/v2/dongho/${params?.id}`)
    }
  }

  const sortedDongs = [...dongs].sort(function (a, b) {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  })

  const sortedStoreDongs = [
    ...sortedDongs.filter(
      (elem) => !elem.includes('상가') && !elem.includes('근린'),
    ),
    ...sortedDongs.filter(
      (elem) => elem.includes('상가') || elem.includes('근린'),
    ),
  ]

  return (
    <Wrapper>
      <List ref={ref} className={showMore ? '' : 'container'}>
        <ListItem
          onClick={() => onClickDong(null)}
          active={!selectedDong}
        >
          전체
        </ListItem>
        {sortedStoreDongs.map((dong, i) => {
          return (
            <ListItem
              active={selectedDong == dong}
              onClick={() => onClickDong(dong)}
              key={i}
            >
              {dong}
            </ListItem>
          )
        })}
      </List>
      {showLink &&
        (showMore ? (
          <More onClick={onClickOpen} className="fal fa-angle-up" />
        ) : (
          <More onClick={onClickOpen} className="fal fa-angle-down" />
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 18px;
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &.container {
    height: 37px;
    overflow-y: hidden;
  }
`

const ListItem = styled.div`
  padding: 8px 12px;
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.gray[100]};
  }

  ${({ active }) =>
    active &&
    `
        background-color: ${theme.colors.blue[400]};
        color: ${theme.colors.base.white};
        
        &:hover {
            background-color: ${theme.colors.blue[500]};
        }
  `}
`

const More = styled.i`
  font-size: 36px;
  color: ${theme.colors.gray[600]};
  width: 60px;
  line-height: 100%;
  cursor: pointer;
`

export default NoteDonghoHeaderDongs
