import { TabItem } from 'components/design'
import { useContext, useEffect } from 'react'
import useMutationObserve from './useMutationObserve'
import { useSelector } from 'react-redux'
import useIsMobile from 'lib/hooks/useIsMobile'
import BaseContext from 'contexts/baseContext'

/**
 * summary 공동 주택 탭
 * @returns
 */
const SummaryTabApt = ({ tabs }) => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const { observeSection, activeIdx } = useMutationObserve(
    tabs,
    'summary-tab-active-section',
    true,
  )

  const {
    state: { isScrolled },
  } = useContext(BaseContext)

  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile) {
      if (isScrolled) observeSection()
    } else {
      observeSection()
    }
  }, [isScrolled])

  return (
    <>
      {Array.from(tabs).map((tab, index) => {
        if (
          summaryInfo?.dongho_list &&
          Object.keys(summaryInfo?.dongho_list).length == 0 &&
          tab?.name == '전유부'
        )
          return

        return (
          <TabItem
            key={index}
            noContainer
            active={activeIdx == index}
            expand
            onClick={tab?.onMoveToElement}
          >
            {tab?.name}
          </TabItem>
        )
      })}
    </>
  )
}

export default SummaryTabApt
