import { useEffect, useState, useContext } from 'react'
import { Route, Switch } from 'react-router'
import { useDispatch } from 'react-redux'
import Container from 'components/base/Container/Container'
import ErrorView from 'components/common/ErrorView/ErrorView'
import NotFound from './NotFound'
import SummaryContainer from 'containers/summary/SummaryContainer'
import MapContext from 'contexts/map'
import SummaryBuilding from 'components/summary/SummaryBuilding/SummaryBuilding'
import SummaryExternal from './summary/SummaryExternal'
import SummaryMapContainer from 'containers/summary/SummaryMapContainer'
import SEOHead from 'components/common/SEOHead'
import BaseFooter from 'components/base/BaseFooter/BaseFooter'
import SummaryHeaderLoading from 'components/summary/header/SummaryHeaderLoading'
import SummaryHeaderContainer from 'containers/summary/SummaryHeaderContainer'
import { setIsCombination } from 'modules/summary'
import SummaryPricesContainer from 'containers/summary/SummaryPricesContainer'
import SummaryTab from 'components/summary/SummaryTab/SummaryTab'
import SummaryLandLegalContainer from 'containers/summary/SummaryLandLegalContainer'
import SummaryLobContainer from 'containers/summary/SummaryLobContainer'
import SummarySizeReviewContainer from 'containers/summary/SummarySizeReviewContainer'
import SummaryGovLandPriceContainer from 'containers/summary/SummaryGovLandPriceContainer'
import SummaryProjectContainer from 'containers/summary/SummaryProjectContainer'
import SummaryJeonyouAndMultiHousingContainer from 'containers/summary/SummaryJeonyouAndMultiHousingContainer'
import { useSelector } from 'react-redux'
import SummaryTabContainer from 'containers/summary/SummaryTabContainer'
import SummaryMasterContainer from 'containers/summary/SummaryMasterContainer'
import SummarySchoolAmenityContainer from 'containers/summary/SummarySchoolAmenityContainer'
import useSummaryTab from 'lib/hooks/useSummaryTab'
import SummaryTopHeader from 'components/summary/header/SummaryTopHeader'

const NewSummaryPage = ({ match }) => {
  const addressId = match?.params?.id
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const { actions } = useContext(MapContext)
  const isApt = useSelector((state) => state.summary.isApt)

  useEffect(() => {
    //수익성 분석 합필 토글 해제
    dispatch(setIsCombination(false))
    actions.setCombinationPolygons([])
    actions.setCombinationStandardPolygons([])
  }, [])

  const { aptTabs, basicTabs } = useSummaryTab()

  if (!addressId || addressId.length !== 19) {
    return <NotFound />
  }

  return (
    <>
      <SEOHead />
      <SummaryMapContainer match={match} />
      <SummaryContainer addressId={addressId}>
        {({ summaryInfo, loading, error }) => {
          if (loading)
            return (
              <Container
                external={match?.params?.external}
                swipeable
                onChange={(e) => setIsOpen(e)}
                header={<SummaryHeaderLoading />}
              ></Container>
            )

          if (error)
            return (
              <Container
                external={match?.params?.external}
                swipeable
                onChange={(e) => setIsOpen(e)}
              >
                <ErrorView error={error} />
              </Container>
            )

          if (!summaryInfo)
            return (
              <Container
                external={match?.params?.external}
                swipeable
                onChange={(e) => setIsOpen(e)}
              >
                <ErrorView msg="주소 정보를 찾을 수 없습니다." />
              </Container>
            )

          return (
            <>
              <Container
                external={match?.params?.external}
                swipeable
                onChange={(e) => setIsOpen(e)}
                header={<SummaryHeaderContainer isOpen={isOpen} />}
                topSlot={
                  <SummaryTab aptTabs={aptTabs} basicTabs={basicTabs} />
                }
              >
                <SummaryTopHeader />
                <SummaryPricesContainer
                  ref={{
                    apt: aptTabs[0].element,
                  }}
                />
                {!isApt && (
                  <SummaryLandLegalContainer
                    ref={{
                      apt: null,
                      basicLand: basicTabs[0].element,
                      basicLegal: basicTabs[1].element,
                    }}
                  />
                )}
                <SummaryBuilding
                  ref={{
                    apt: null,
                    basic: basicTabs[2].element,
                  }}
                />
                <SummarySizeReviewContainer />
                <SummaryLobContainer />
                <SummaryJeonyouAndMultiHousingContainer
                  ref={{
                    apt: aptTabs[1].element,
                  }}
                />
                <SummaryGovLandPriceContainer />
                <SummarySchoolAmenityContainer
                  aptTabs={aptTabs}
                  basicTabs={basicTabs}
                />

                {isApt && (
                  <SummaryLandLegalContainer
                    ref={{
                      apt: aptTabs[4].element,
                    }}
                  />
                )}
                <SummaryMasterContainer />
                <SummaryProjectContainer />
                <BaseFooter />
              </Container>

              <Switch>
                <Route
                  path="/:id/:external/:filterId?"
                  component={SummaryExternal}
                  exact
                />
              </Switch>
            </>
          )
        }}
      </SummaryContainer>
    </>
  )
}

export default NewSummaryPage
