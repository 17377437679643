import { Checkbox } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  TableContent,
  InnerTable,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  subHeaderWidth,
  subHeaderWidthSm,
  innerSubHeaderWidth,
  StyledTable,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import {
  getOnlyRemoveCommaNumber,
  handleFocusNumber,
} from 'lib/utils/notev2/util'
import { useCallback, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { dateFormat, numberWithCommas } from 'utils'

const NoteDocsBasic1 = ({ onUpdate }) => {
  const { watch, setValue } = useFormContext()

  const address = watch('address')
  const d_descript_type = watch('d_descript_type')
  const d_land_type = watch('d_land_type')
  const d_address = watch('d_address')
  const d_land_area = watch('d_land_area')
  const d_exclusive_area = watch('d_exclusive_area')
  const d_land_type_actual = watch('d_land_type_actual')
  const d_land_share = watch('d_land_share')
  const d_ccd = watch('d_ccd')
  const d_building_type = watch('d_building_type')
  const d_purpose_code_name = watch('d_purpose_code_name')
  const d_building_type_actual = watch('d_building_type_actual')
  const d_struct_type = watch('d_struct_type')
  const d_direction = watch('d_direction')
  const d_direction_set = watch('d_direction_set')
  const d_is_earthquake_res = watch('d_is_earthquake_res')
  const d_earthquake_res = watch('d_earthquake_res')
  const d_is_violation_building = watch('d_is_violation_building')
  const d_violation_building = watch('d_violation_building')
  const daejigwon_ratio = watch('daejigwon_ratio')

  const isBuilding = ['주거용 건축물', '비주거용 건축물'].includes(
    d_descript_type,
  )

  /**
   * 위반건축물 여부 '적법'(또는 Y)이면 위반내용에 '해당없음' 세팅
   */
  useEffect(() => {
    if (
      d_is_violation_building == '정상' ||
      d_is_violation_building == 'N' ||
      !d_is_violation_building
    ) {
      setValue('d_violation_building', '해당없음')
    }
    if (
      d_is_violation_building == '위반' ||
      d_is_violation_building == 'Y'
    ) {
      setValue('d_violation_building', '')
    }
  }, [d_is_violation_building])

  const getRowSpan = useCallback(() => {
    if (isBuilding) {
      return 10
    } else {
      return 3
    }
  }, [d_descript_type])

  const getEachRowHeight = useCallback(() => {
    if (isBuilding) {
      return [28, 72]
    } else {
      return [100]
    }
  }, [d_descript_type])

  const eachRowHeight = useMemo(
    () => getEachRowHeight(),
    [d_descript_type],
  )

  return (
    <StyledTable>
      <Row height={31} rowspan={getRowSpan()} noborderbottom>
        <TableHeader width={headerWidth}>
          &#9312; 대상물건의
          <br />
          표시
        </TableHeader>
        <RowSpanInner
          rowspanheightpercent={eachRowHeight[0]}
          rowspan={2}
          minuswidth={headerWidth}
          noborderbottom={!isBuilding}
        >
          <TableSubHeader width={headerWidth}>토지</TableSubHeader>
          <TableContent fullwidth nopadding noborderright>
            <InnerTable>
              <Row heightpercent={35}>
                <TableSubHeader width={innerSubHeaderWidth}>
                  소재지
                </TableSubHeader>
                <TableContent
                  minuswidth={innerSubHeaderWidth}
                  noborderright
                >
                  <ColorInput
                    type="text"
                    fullWidth
                    defaultValue={d_address ? d_address : address}
                    onBlur={(e) =>
                      onUpdate('d_address', e.target.value)
                    }
                  />
                </TableContent>
              </Row>
              <Row heightpercent={65} noborderbottom>
                <InnerTableBlock widthpercent={45} noborderbottom>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    면적&#40;㎡&#41;
                  </TableSubHeader>
                  <InnerTableContent headerwidth={innerSubHeaderWidth}>
                    <ColorInput
                      type="number"
                      defaultValue={
                        d_land_area > 0
                          ? numberWithCommas(
                              Math.round(d_land_area * 100) / 100,
                            )
                          : null
                      }
                      onClick={handleFocusNumber}
                      onBlur={(e) =>
                        onUpdate(
                          'd_land_area',
                          getOnlyRemoveCommaNumber(e.target.value),
                        )
                      }
                      fullWidth
                    />
                  </InnerTableContent>
                </InnerTableBlock>
                <InnerTableBlock
                  widthpercent={55}
                  noborderright
                  noborderbottom
                >
                  <TableSubHeader width={subHeaderWidthSm}>
                    지목
                  </TableSubHeader>
                  <Row rowspan={2} heightpercent={100} noborderbottom>
                    <RowSpanInner fullwidth rowspan={2}>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        공부상 지목
                      </TableSubHeader>
                      <InnerTableContent
                        headerwidth={innerSubHeaderWidth}
                      >
                        <ColorInput
                          type="text"
                          defaultValue={d_land_type}
                          onBlur={(e) =>
                            onUpdate('d_land_type', e.target.value)
                          }
                          fullWidth
                        />
                      </InnerTableContent>
                    </RowSpanInner>
                    <RowSpanInner noborderbottom fullwidth rowspan={2}>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        실제 이용 상태
                      </TableSubHeader>
                      <InnerTableContent
                        headerwidth={innerSubHeaderWidth}
                      >
                        <ColorInput
                          type="text"
                          defaultValue={d_land_type_actual}
                          onBlur={(e) =>
                            onUpdate(
                              'd_land_type_actual',
                              e.target.value,
                            )
                          }
                          fullWidth
                        />
                      </InnerTableContent>
                    </RowSpanInner>
                  </Row>
                </InnerTableBlock>
              </Row>
            </InnerTable>
          </TableContent>
        </RowSpanInner>
        {['주거용 건축물', '비주거용 건축물'].includes(
          d_descript_type,
        ) && (
          <RowSpanInner
            rowspanheightpercent={eachRowHeight[1]}
            rowspan={2}
            minuswidth={headerWidth}
            noborderbottom
          >
            <TableSubHeader width={headerWidth}>건축물</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={15} noborderbottom>
                  <InnerTableBlock
                    width={`calc(45% + ${subHeaderWidthSm}px)`}
                  >
                    <TableSubHeader width={innerSubHeaderWidth}>
                      전용면적&#40;㎡&#41;
                    </TableSubHeader>
                    <InnerTableContent
                      headerwidth={innerSubHeaderWidth}
                    >
                      <ColorInput
                        onClick={handleFocusNumber}
                        type="number"
                        key={
                          d_exclusive_area > 0
                            ? numberWithCommas(
                                Math.round(d_exclusive_area * 100) /
                                  100,
                              )
                            : ''
                        }
                        defaultValue={
                          d_exclusive_area > 0
                            ? numberWithCommas(
                                Math.round(d_exclusive_area * 100) /
                                  100,
                              )
                            : null
                        }
                        onBlur={(e) =>
                          onUpdate(
                            'd_exclusive_area',
                            getOnlyRemoveCommaNumber(e.target.value),
                          )
                        }
                        fullWidth
                      />
                    </InnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock
                    width={`calc(55% - ${subHeaderWidthSm}px)`}
                    noborderright
                  >
                    <TableSubHeader width={innerSubHeaderWidth}>
                      대지지분&#40;㎡&#41;
                    </TableSubHeader>
                    <InnerTableContent headerwidth={subHeaderWidth}>
                      <ColorInput
                        type="text"
                        onClick={handleFocusNumber}
                        defaultValue={
                          d_land_share && d_land_share !== ''
                            ? d_land_share
                            : daejigwon_ratio
                        }
                        onBlur={(e) =>
                          onUpdate('d_land_share', e.target.value)
                        }
                        fullWidth
                      />
                    </InnerTableContent>
                  </InnerTableBlock>
                </Row>
                <Row heightpercent={28}>
                  <InnerTableBlock widthpercent={45} noborderbottom>
                    <TableSubHeader width={innerSubHeaderWidth}>
                      준공년도
                      <br />
                      &#40;중개축년도&#41;
                    </TableSubHeader>
                    <InnerTableContent
                      headerwidth={innerSubHeaderWidth}
                    >
                      <ColorInput
                        type="text"
                        defaultValue={
                          d_ccd
                            ? dateFormat(d_ccd)?.substring(0, 4)
                            : ''
                        }
                        onBlur={(e) =>
                          onUpdate('d_ccd', e.target.value)
                        }
                        fullWidth
                      />
                    </InnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock
                    widthpercent={55}
                    noborderright
                    noborderbottom
                  >
                    <TableSubHeader width={subHeaderWidthSm}>
                      용도
                    </TableSubHeader>
                    <Row rowspan={2} heightpercent={100} noborderbottom>
                      <RowSpanInner fullwidth rowspan={2}>
                        <TableSubHeader width={innerSubHeaderWidth}>
                          건축물대장상 용도
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <ColorInput
                            type="text"
                            defaultValue={d_building_type}
                            onBlur={(e) =>
                              onUpdate(
                                'd_building_type',
                                e.target.value,
                              )
                            }
                            fullWidth
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                      <RowSpanInner
                        noborderbottom
                        fullwidth
                        rowspan={2}
                      >
                        <TableSubHeader width={innerSubHeaderWidth}>
                          실제 용도
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <ColorInput
                            type="text"
                            defaultValue={d_building_type_actual}
                            onBlur={(e) =>
                              onUpdate(
                                'd_building_type_actual',
                                e.target.value,
                              )
                            }
                            fullWidth
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                    </Row>
                  </InnerTableBlock>
                </Row>
                <Row heightpercent={15} noborderbottom>
                  <InnerTableBlock widthpercent={45}>
                    <TableSubHeader width={innerSubHeaderWidth}>
                      구조
                    </TableSubHeader>
                    <InnerTableContent
                      headerwidth={innerSubHeaderWidth}
                    >
                      <ColorInput
                        type="text"
                        defaultValue={d_struct_type}
                        onBlur={(e) =>
                          onUpdate('d_struct_type', e.target.value)
                        }
                        fullWidth
                      />
                    </InnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock widthpercent={55} noborderright>
                    <TableSubHeader
                      width={innerSubHeaderWidth + subHeaderWidthSm}
                    >
                      방향
                    </TableSubHeader>
                    <InnerTableContent
                      spacebetween
                      headerwidth={subHeaderWidth}
                    >
                      <ColorInput
                        width={100}
                        type="text"
                        defaultValue={d_direction}
                        onBlur={(e) =>
                          onUpdate('d_direction', e.target.value)
                        }
                        fullWidth
                      />
                      <DirectionSet>
                        &#40;기준 :
                        <ColorInput
                          width={80}
                          type="text"
                          defaultValue={d_direction_set}
                          onBlur={(e) =>
                            onUpdate('d_direction_set', e.target.value)
                          }
                        />
                        &#41;
                      </DirectionSet>
                    </InnerTableContent>
                  </InnerTableBlock>
                </Row>
                <Row heightpercent={20} noborderbottom>
                  <InnerTableBlock widthpercent={45}>
                    <TableSubHeader width={innerSubHeaderWidth}>
                      내진설계
                      <br />
                      적용여부
                    </TableSubHeader>
                    <InnerTableContent
                      checkboxes
                      headerwidth={innerSubHeaderWidth}
                    >
                      <RadioButton
                        label="적용"
                        color="blue"
                        checked={
                          d_is_earthquake_res == '1' ||
                          d_is_earthquake_res == 'Y'
                            ? true
                            : false
                        }
                        name="d_is_earthquake_res"
                        onChange={(e) =>
                          onUpdate('d_is_earthquake_res', e ? 'Y' : 'N')
                        }
                      />

                      <RadioButton
                        label="미적용"
                        color="blue"
                        checked={
                          d_is_earthquake_res == '0' ||
                          d_is_earthquake_res == 'N'
                            ? true
                            : false
                        }
                        name="d_is_earthquake_res"
                        onChange={(e) =>
                          onUpdate('d_is_earthquake_res', e ? 'N' : 'Y')
                        }
                      />
                    </InnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock widthpercent={55} noborderright>
                    <TableSubHeader
                      width={innerSubHeaderWidth + subHeaderWidthSm}
                    >
                      내진능력
                    </TableSubHeader>
                    <InnerTableContent headerwidth={subHeaderWidth}>
                      <ColorInput
                        type="text"
                        defaultValue={d_earthquake_res}
                        onBlur={(e) =>
                          onUpdate('d_earthquake_res', e.target.value)
                        }
                        fullWidth
                      />
                    </InnerTableContent>
                  </InnerTableBlock>
                </Row>
                <Row heightpercent={22} noborderbottom>
                  <InnerTableBlock widthpercent={45} noborderbottom>
                    <TableSubHeader width={innerSubHeaderWidth}>
                      건축물대장상
                      <br />
                      위반건축물 여부
                    </TableSubHeader>
                    <InnerTableContent
                      checkboxes
                      headerwidth={innerSubHeaderWidth}
                    >
                      <RadioButton
                        label="위반"
                        checked={d_is_violation_building == 'Y'}
                        name="is_violation_building"
                        onChange={(e) =>
                          onUpdate('d_is_violation_building', 'Y')
                        }
                      />
                      <RadioButton
                        label="적법"
                        checked={
                          d_is_violation_building == 'N' ||
                          !d_is_violation_building
                        }
                        name="is_violation_building"
                        onChange={(e) =>
                          onUpdate('d_is_violation_building', 'N')
                        }
                      />
                    </InnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock
                    widthpercent={55}
                    noborderright
                    noborderbottom
                  >
                    <TableSubHeader width={innerSubHeaderWidth}>
                      위반내용
                    </TableSubHeader>
                    <InnerTableContent headerwidth={subHeaderWidth}>
                      <ColorInput
                        type="text"
                        defaultValue={d_violation_building}
                        onBlur={(e) =>
                          onUpdate(
                            'd_violation_building',
                            e.target.value,
                          )
                        }
                        fullWidth
                      />
                    </InnerTableContent>
                  </InnerTableBlock>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
        )}
      </Row>
    </StyledTable>
  )
}

const DirectionSet = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  input {
    margin: 0 10px;
  }
`

export default NoteDocsBasic1
