import styled from 'styled-components'
import { Section, SubTitle, Title } from './BdsFilterStyle'
import Slider from './Slider'
import {
  initBdsFilter,
  setDepositPrice,
  setRentPrice,
  setSalePrice,
} from 'modules/bds'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'
import priceFormat from 'lib/utils/priceFormat'
import theme from 'lib/styles/theme'
import React, { useCallback } from 'react'

//매매가 범위
const saleArr = [
  0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000,
  30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 200000,
  300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000,
]

//보증금 범위
const depositArr = [
  0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000,
  4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000, 30000, 40000, 50000,
  60000, 70000, 80000, 90000, 100000,
]

//월세 범위
const rentArr = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
  110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
]

const BdsContentFilterPrices = () => {
  const selectedFilter = useSelector(
    (state) => state.bds.selectedFilter,
  )

  const dispatch = useDispatch()

  /**
   * 보증금 저장
   * @param {*} render
   */
  const handleEndSaleSlider = useCallback((render) => {
    dispatch(setSalePrice(render))
  }, [])

  /**
   * 보증금 저장
   * @param {*} render
   */
  const handleEndDepositSlider = useCallback((render) => {
    dispatch(setDepositPrice(render))
  }, [])

  /**
   * 월세 저장
   * @param {*} render
   */
  const handleEndRentSlider = useCallback((render) => {
    dispatch(setRentPrice(render))
  }, [])

  return (
    <Section>
      <Title>가격</Title>
      <Group>
        <SubTitle>매매가</SubTitle>
        <Slider
          range={{ min: 0, max: saleArr.length - 1 }}
          start={[
            selectedFilter?.price?.sale_price[0],
            selectedFilter?.price?.sale_price[1],
          ]}
          step={1}
          //  connect
          format={{
            to: function (value) {
              return saleArr[Math.round(value)]
            },
            from: function (value) {
              return saleArr.indexOf(Number(value))
            },
          }}
          connect
          behaviour="snap"
          pips={{
            density: 100 / 3,
            mode: 'positions',
            values: [0, 100 / 3 + 1, (100 / 3) * 2, 100],
            format: {
              to: function (value) {
                if (value == 0) return 0
                else if (value == saleArr.length - 1) return '전체'
                return priceFormat(saleArr[Math.round(value)] * 10000)
              },
            },
          }}
          onEnd={handleEndSaleSlider}
        />
        <Value>
          {isEqual(
            initBdsFilter.price.sale_price,
            selectedFilter?.price?.sale_price,
          ) ? (
            '매매가 전체'
          ) : (
            <>
              {priceFormat(
                selectedFilter?.price?.sale_price[0] * 10000,
              )}
              ~
              {priceFormat(
                selectedFilter?.price?.sale_price[1] * 10000,
              )}
            </>
          )}
        </Value>
      </Group>
      <Group>
        <SubTitle>보증금</SubTitle>
        <Slider
          range={{ min: 0, max: depositArr.length - 1 }}
          start={[
            selectedFilter?.price?.deposit[0],
            selectedFilter?.price?.deposit[1],
          ]}
          step={1}
          //  connect
          format={{
            to: function (value) {
              return depositArr[Math.round(value)]
            },
            from: function (value) {
              return depositArr.indexOf(Number(value))
            },
          }}
          connect
          behaviour="snap"
          pips={{
            density: 100 / 3,
            mode: 'positions',
            values: [0, 100 / 3 + 1, (100 / 3) * 2, 100],
            format: {
              to: function (value) {
                if (value == 0) return 0
                else if (value == depositArr.length - 1) return '전체'
                return priceFormat(
                  depositArr[Math.round(value)] * 10000,
                )
              },
            },
          }}
          onEnd={handleEndDepositSlider}
        />
        <Value>
          {isEqual(
            initBdsFilter.price.deposit,
            selectedFilter?.price?.deposit,
          ) ? (
            '보증금 전체'
          ) : (
            <>
              {priceFormat(selectedFilter?.price?.deposit[0] * 10000)}~
              {priceFormat(selectedFilter?.price?.deposit[1] * 10000)}
            </>
          )}
        </Value>
      </Group>
      <Group>
        <SubTitle>월세</SubTitle>
        <Slider
          range={{ min: 0, max: rentArr.length - 1 }}
          start={[
            selectedFilter?.price?.rent[0],
            selectedFilter?.price?.rent[1],
          ]}
          step={1}
          //  connect
          format={{
            to: function (value) {
              return rentArr[Math.round(value)]
            },
            from: function (value) {
              return rentArr.indexOf(Number(value))
            },
          }}
          onEnd={handleEndRentSlider}
          connect
          behaviour="snap"
          pips={{
            density: 100 / 3,
            mode: 'positions',
            values: [0, 100 / 3 + 1, (100 / 3) * 2, 100],
            format: {
              to: function (value) {
                if (value == 0) return 0
                else if (value == rentArr.length - 1) return '전체'
                return priceFormat(rentArr[Math.round(value)] * 10000)
              },
            },
          }}
        />
        <Value>
          {isEqual(
            initBdsFilter.price.rent,
            selectedFilter?.price?.rent,
          ) ? (
            '월세 전체'
          ) : (
            <>
              {priceFormat(selectedFilter?.price?.rent[0] * 10000)}~
              {priceFormat(selectedFilter?.price?.rent[1] * 10000)}
            </>
          )}
        </Value>
      </Group>
    </Section>
  )
}

const Group = styled.div`
  position: relative;

  .noUi-tooltip {
    display: none;
    font-size: 12px;
    padding: 2px;
  }

  .noUi-active .noUi-tooltip {
    display: block;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Value = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[600]};
`

export default React.memo(BdsContentFilterPrices)
