import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'components/design'
import SummaryLobSelect from 'components/summary/SummaryLob/SummaryLobSelect'
import SummaryLobInfo from 'components/summary/SummaryLob/SummaryLobInfo'
import styled from 'styled-components'
import SummaryLobInfoFeatures from './SummaryLobInfoFeatures'
import SummaryLobInfoTotal from './SummaryLobInfoTotal'

const SummaryLobBuildingInfo = ({
  buildingList,
  selectedBuildingData,
  selectedBuildingId,
  setSelectedBuildingId,
  timeline,
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>건축물대장</CardTitle>
      </CardHeader>
      <CardBody>
        <Wrapper>
          <SummaryLobSelect
            buildingList={buildingList}
            selectedBuilding={selectedBuildingData?.building}
            selectedBuildingId={selectedBuildingId}
            setSelectedBuildingId={setSelectedBuildingId}
          />
          {selectedBuildingData?.building?.isTotal ? (
            <SummaryLobInfoTotal
              data={selectedBuildingData?.building}
              features={
                selectedBuildingData?.features ? (
                  <SummaryLobInfoFeatures
                    data={selectedBuildingData?.features}
                  />
                ) : null
              }
            />
          ) : (
            <SummaryLobInfo
              data={selectedBuildingData?.building}
              features={
                selectedBuildingData?.features ? (
                  <SummaryLobInfoFeatures
                    data={selectedBuildingData?.features}
                  />
                ) : null
              }
            />
          )}
        </Wrapper>
        {timeline}
      </CardBody>
    </Card>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
`

export default SummaryLobBuildingInfo
