import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Dropdown, Menu } from 'antd'

const StyledMenuButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  padding: 0;
  margin-right: -0.5rem;

  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;

  color: ${theme.colors.gray[600]};
  font-size: 1.5rem;

  ${({ top }) =>
    top &&
    `
    align-self: flex-start;
  `}

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover {
    color: ${theme.colors.gray[700]};
  }
`

const ListItemMenu = ({ menus, top }) => {
  return (
    <Dropdown menu={menus} trigger={['click']}>
      <StyledMenuButton top={top} onClick={(e) => e.preventDefault()}>
        <i className="fal fa-ellipsis-v"></i>
      </StyledMenuButton>
    </Dropdown>
  )
}

ListItemMenu.Item = Menu.Item

export default ListItemMenu
