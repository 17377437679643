import ReactDOM from 'react-dom'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

const StyledWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  width: calc(100% - ${theme.base.navWidth}px);
  height: 100%;
  left: ${theme.base.navWidth}px;
  /* padding-top: 60px; */
  background-color: white;

  ${media.mediumS`
    z-index: 99;
    width: 100%;
    left: 0;
  `}
`

const NoteUploadExcelWrap = ({ children }) => {
  const root = document.getElementById('root')
  const template = <StyledWrap>{children}</StyledWrap>

  return ReactDOM.createPortal(template, root)
}

export default NoteUploadExcelWrap
