import theme from 'lib/styles/theme'
import {
  suppressArrowGrid,
  suppressSideArrowGrid,
  suppressTextCursorControlGrid,
} from './suppressKey'

const colType = {
  contract_type: 4,
  price: 5,
}

const colTypeMobile = {
  contract_type: 3,
  price: 4,
}

/**
 * 저장 후 다음으로 입력할 셀에 포커스 이동
 */
export const focusNextCell = (
  gridApi,
  gridColumnApi,
  rowKey,
  colName,
) => {
  if (!colName) return

  const currentRowNode = gridApi.getRowNode(rowKey)
  const colIndex =
    window.innerWidth <= theme.bp.mediumS
      ? colTypeMobile[colName]
      : colType[colName]

  const col = gridColumnApi.getAllDisplayedColumns()[colIndex]
  gridApi.setFocusedCell(currentRowNode.rowIndex, col)
}

/**
 * 포커싱할 input 타입 html 요소(element) 얻기
 * @param {*} el
 * @returns
 */
const getAllFocusableElementsOf = (el) => {
  return Array.from(
    el.querySelectorAll(
      'div, [tabindex = "0"],[tabindex]:not([tabindex="-1"])',
    ),
  ).filter((focusableEl) => {
    return focusableEl.tabIndex !== -1
  })
}

/**
 * event path 얻기
 * @param {*} event
 * @returns
 */
const getEventPath = (event) => {
  const path = []
  let currentTarget = event.target
  while (currentTarget) {
    path.push(currentTarget)
    currentTarget = currentTarget.parentElement
  }
  return path
}

const GRID_CELL_CLASSNAME = 'ag-cell'

/**
 * 셀 키보드 이벤트 제어 함수
 * 이 함수에서는
 * 1. 사용자가 탭을 앞으로 치는지 뒤로 치는지 캡처하고 외부에서 탭을 누를 경우 키보드 이벤트를 억제함
 */
export const suppressKeyboardEvent = ({ event, colDef, data }) => {
  const field = colDef.field
  const { key, shiftKey } = event
  const path = getEventPath(event)
  const isTabForward = key === 'Tab' && shiftKey === false
  const isTabBackward = key === 'Tab' && shiftKey === true
  const cellClass = colDef?.cellClass
  const isEnterToNext =
    cellClass &&
    cellClass.includes('custom-event-suppress-enter-to-next') &&
    key === 'Enter'
  let suppressEvent = false

  const isNotAreaCell =
    cellClass && !cellClass.includes('custom-class-area-cell')

  const isNotPriceCell =
    cellClass && !cellClass.includes('custom-class-price-cell')

  const isAdContractTypeCell =
    cellClass && cellClass.includes('contract_type_ad')

  //가격, 면적, 상세주소, 종류 탭에서만 동작
  if (
    isNotPriceCell &&
    isNotAreaCell &&
    field != 'contacts' &&
    field != 'property_type' &&
    field != 'direction' &&
    !isAdContractTypeCell
  ) {
    return false
  }

  //방향키, 탭키(9) 제어
  if (cellClass) {
    if (cellClass.includes('custom-event-suppress-arrow-keys')) {
      if (
        (field == 'direction' && data.state?.isEditingDirection) ||
        field == 'contacts' ||
        isAdContractTypeCell ||
        (field == 'property_type' && data.state?.isEditingPropertyType)
      ) {
        if (
          suppressArrowGrid(event) ||
          event.keyCode == 9 ||
          event.key == 'Tab'
        ) {
          return true
        }
      }
    }

    //사이드 방향키만 제어
    if (cellClass.includes('custom-event-suppress-side-arrow-keys')) {
      if (suppressSideArrowGrid(event)) {
        //input에 포커스 되었을때만 제어
        const targetNode = event.target
        if (targetNode && targetNode.tagName == 'INPUT') {
          return true
        }
      }
    }

    //커서를 컨트롤하는 키 제어
    if (cellClass.includes('custom-event-suppress-text-cursor-keys')) {
      if (suppressTextCursorControlGrid(event)) {
        //input에 포커스 되었을때만 제어
        const targetNode = event.target

        if (
          targetNode &&
          (targetNode.tagName == 'INPUT' ||
            targetNode.tagName == 'TEXTAREA')
        ) {
          return true
        }
      }
    }
  }

  // 탭 동작 gogo
  if (isEnterToNext || isTabForward || isTabBackward) {
    const eGridCell = path.find((el) => {
      if (el.classList === undefined) return false

      return el.classList.contains(GRID_CELL_CLASSNAME)
    })

    if (!eGridCell) {
      return suppressEvent
    }
    const focusableChildrenElements =
      getAllFocusableElementsOf(eGridCell)

    const lastCellChildEl =
      focusableChildrenElements[focusableChildrenElements.length - 1]
    const firstCellChildEl = focusableChildrenElements[0]

    // 탭 앞으로 이동 이벤트
    //현재 포커스가 있는 요소가 마지막 자식이 아닌 경우 키보드 이벤트 억제
    if (
      (isTabForward || isEnterToNext) &&
      focusableChildrenElements.length > 0
    ) {
      const isPriceCell =
        cellClass && cellClass.includes('custom-class-price-cell')
      const isAreaCell =
        cellClass && cellClass.includes('custom-class-area-cell')

      const isLastChildFocused =
        lastCellChildEl && document.activeElement === lastCellChildEl

      if (isEnterToNext) {
        //현재 cell내의 focus된 element의 index를 찾는다
        const focusedIndex = focusableChildrenElements.findIndex(
          (elem) => {
            return elem.isEqualNode(
              isPriceCell
                ? document.activeElement.parentNode.parentNode
                : document.activeElement,
            )
          },
        )

        if (
          focusedIndex != null &&
          focusedIndex != undefined &&
          focusableChildrenElements[focusedIndex + 1]
        ) {
          //같은 셀 내에 다음 포커스 항목이 있는 경우 이동
          focusableChildrenElements[focusedIndex + 1].focus()
        } else if (
          focusedIndex ==
          focusableChildrenElements.length - 1
        ) {
          //같은 셀 내의 마지막 포커스 element인 경우 다음 칸의 focus 가능한 곳을 찾아서 focus 시켜준다

          if (isPriceCell) {
            //가격셀인 경우, 면적 셀의 첫번째 focus 항목에 focus 시켜준다
            const currentRowNodeTarget = eGridCell.closest('.ag-row')
            currentRowNodeTarget
              .querySelector('.custom-class-area-cell')
              .querySelector('[tabindex = "0"]')
              .focus()
          } else if (isAreaCell) {
            //면적셀인 경우 방향 셀 focus
            const currentRowNodeTarget = eGridCell.closest('.ag-row')
            currentRowNodeTarget
              .querySelector('.custom-class-direction-cell')
              .querySelector('[tabindex = "0"]')
              .focus()
          }
        }
        suppressEvent = true
      } else if (!isLastChildFocused) {
        suppressEvent = true
      }
    }
    //탭 뒤로 이벤트(shift + tab)
    //첫번째 자식이 아닌 경우 키보드 이벤트 억제
    else if (isTabBackward && focusableChildrenElements.length > 0) {
      const cellHasFocusedChildren =
        eGridCell.contains(document.activeElement) &&
        eGridCell !== document.activeElement
      // 셀에 포커스된 자식이 없는 경우 수동으로 마지막 자식 요소로 포커스 설정
      if (!cellHasFocusedChildren) {
        lastCellChildEl.focus()

        // Cancel keyboard press, so that it doesn't focus on the last child and then pass through the keyboard press to
        // move to the 2nd last child element
        event.preventDefault()
      }
      const isFirstChildFocused =
        firstCellChildEl && document.activeElement === firstCellChildEl
      if (!isFirstChildFocused) {
        suppressEvent = true
      }
    }
  }
  return suppressEvent
}

/**
 * 셀에 focus 할 input 요소가 있는지 체크하고 첫번째걸로 포커스 지정
 * @param {*} eGridCell
 * @param {*} api
 * @returns
 */
export const checkInputAndMoveFocus = (eGridCell, api) => {
  let suppressEvent = false

  if (!eGridCell) {
    return suppressEvent
  }

  const focusableChildrenElements = getAllFocusableElementsOf(eGridCell)

  if (focusableChildrenElements.length > 0) {
    const firstCellChildEl = focusableChildrenElements[0]
    firstCellChildEl.focus()
  }

  return suppressEvent
}
