import { useCallback, useEffect, useState } from 'react'
import { Modal } from 'components/design'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { modifyFolder, removeFolder } from 'lib/api/note/folderApi'
import useFolders from 'pages/jmapnote/noteList/hooks/useFolders'
import NoteFolderForm from './FolderForm'
import { ModalFooter } from 'components/design'
import { Button } from 'components/design'
import { notification } from 'antd'
import { Confirm } from 'components/design'
import useAxios from 'lib/hooks/useAxios'

const RemoveConfirmContent = () => (
  <>
    폴더를 삭제하면 하위의 매물 정보들이 모두 삭제됩니다.
    <br />
    그래도 삭제하시겠습니까?
  </>
)

const FolderModify = ({ visible, setVisible, folderInfo }) => {
  const { channelSrl } = useNoteMatch()
  const [folderSrl, setFolderSrl] = useState('')
  const [name, setName] = useState('')
  const [icon, setIcon] = useState('far fa-folder')

  const [_modifyFolder, loading, data, error] = useAxios(modifyFolder)
  const [_removeFolder] = useAxios(removeFolder)
  const { _getFolders, data: _getFolderData, folders } = useFolders()

  useEffect(() => {
    if (visible) {
      if (folderInfo?.folder_srl) setFolderSrl(folderInfo?.folder_srl)
      if (folderInfo?.name) setName(folderInfo?.name)
      if (folderInfo?.icon) setIcon(folderInfo?.icon)
    } else {
      setFolderSrl('')
      setName('')
      setIcon('far fa-folder')
    }
  }, [visible, folderInfo])

  useEffect(() => {
    if (data && !error) {
      setVisible(false)
    }
  }, [data, error])

  const onCancel = useCallback(() => {
    setVisible(false)
  })

  const onOk = useCallback(async () => {
    await _modifyFolder({
      channelSrl,
      folderSrl,
      name,
      icon: icon,
    })

    await _getFolders()
  }, [_modifyFolder, channelSrl, folderSrl, name, icon])

  const onClickRemoveVisible = async () => {
    await _getFolders()

    if (folders?.length <= 1) {
      notification.error({
        message: '최소 한 개 이상의 폴더를 유지해야 합니다.',
        getContainer: () => document.getElementById('modal'),
      })
      return
    }

    Confirm.error({
      title: '삭제하시겠습니까?',
      content: <RemoveConfirmContent />,
      okText: '삭제',
      okButtonProps: { color: 'danger' },
      onOk: () => onRemove(),
    })
  }

  const onRemove = useCallback(async () => {
    if (!folderSrl) {
      return
    }

    await _removeFolder({
      channelSrl,
      folderSrl,
    })
    setVisible(false)
    await _getFolders()
  }, [_removeFolder, channelSrl, folderSrl])

  return (
    <>
      <Modal
        visible={visible}
        title="폴더 수정"
        onCancel={onCancel}
        onOk={onOk}
        footer={
          <ModalFooter
            prepend={
              <Button
                color="danger"
                border
                onClick={() => onClickRemoveVisible()}
              >
                삭제
              </Button>
            }
          >
            <Button onClick={() => onCancel()}>취소</Button>

            <Button color="black" onClick={() => onOk()}>
              저장
            </Button>
          </ModalFooter>
        }
      >
        <NoteFolderForm
          name={name}
          setName={setName}
          icon={icon}
          setIcon={setIcon}
        />
      </Modal>
    </>
  )
}

export default FolderModify
