import styled from 'styled-components'

import Header from 'components/common/Header/Header'

import BaseHeaderNav from 'components/base/BaseHeader/BaseHeaderNav'
import BaseHeaderNoty from 'components/base/BaseHeader/BaseHeaderNoty'
import HeaderDivider from 'components/common/Header/HeaderDivider'

import logoImage from 'assets/images/logo/jnote-logo.png'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'

const StyledLogo = styled.img`
  position: relative;
  display: block;
  /* width: 14px; */
  height: 19px;
  margin-top: -1px;
  margin-right: 0.75rem;
`

const NoteHeader = ({
  title,
  children,
  border,
  card,
  onBack,
  isMap,
}) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  return (
    <Header border={border} fixed borderHidden>
      <StyledLogo src={logoImage} alt="JOOTEK" />

      {children}
      <HeaderDivider />

      {(isMobile || isTablet) && (
        <>
          {!isMap && <BaseHeaderNoty />}
          <BaseHeaderNav />
        </>
      )}
    </Header>
  )
}

export default NoteHeader
