import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { ReactComponent as ListIconActive } from 'assets/icon/note/menu/list-active.svg'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 매물노트 - 대시보드
 * 등기부등본 테이블
 */
const NoteDashboardDeunggibuTable = ({ data }) => {
  const history = useHistory()

  // 매물 - 공부문서 탭으로 이동
  const onClickItem = (channel_srl, address_srl, note_srl) => {
    history.push({
      pathname: `/note/v2/notes/${channel_srl}/${
        address_srl || 0
      }/${note_srl}`,
      state: {
        activeTab: 3,
      },
    })
  }

  return (
    <Wrapper>
      <Table>
        <thead>
          <tr>
            <th>발급일시</th>
            <th>문서명</th>
            <th>종류</th>
            <th>매물번호</th>
          </tr>
        </thead>
        <tbody>
          {data.map(
            (
              {
                regdate,
                sub_type,
                title,
                is_valid,
                note_srl,
                doc_srl,
                channel_srl,
                address_srl,
              },
              i,
            ) => {
              return (
                <tr key={`dashboard_deunggi_${i}`}>
                  <td>
                    {regdate
                      ? moment(regdate, 'YYYYMMDDHHmmss').format(
                          'YY.MM.DD HH:mm',
                        )
                      : ''}
                  </td>
                  <td className="address">
                    [{sub_type}] {title}
                  </td>
                  <td>{is_valid}</td>
                  <td>
                    <NoteWrapper>
                      <NoteLink
                        onClick={() =>
                          onClickItem(
                            channel_srl,
                            address_srl,
                            note_srl,
                          )
                        }
                      >
                        <ListIconActive />
                        {note_srl}
                      </NoteLink>
                    </NoteWrapper>
                  </td>
                </tr>
              )
            },
          )}
        </tbody>
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;

  thead {
    background-color: ${theme.colors.gray[100]};
    height: 38px;
  }

  tr {
    height: 35px;
  }

  th {
    font-size: 13px;
  }

  td {
    white-space: nowrap;
    font-size: 12px;

    &.address {
      padding: 0 40px;
      text-align: left;
    }
  }

  th,
  tr > td {
    padding: 0 16px;
    &:first-child {
      width: 100px;
      max-width: 100px;
    }

    &:nth-child(3) {
      width: 100px;
      max-width: 100px;
    }
    &:nth-child(4) {
      position: relative;
      width: 140px;
      max-width: 140px;
    }
  }
`

const NoteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`

const NoteLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
  text-decoration: underline;
  text-underline-position: under;
  color: #9355e2;
  cursor: pointer;

  svg {
    width: 13px;
    height: 13px;
    margin-right: 4px;
  }
`

const DocButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.blue[50]};
  cursor: pointer;
  i {
    font-size: 15px;
    color: ${theme.colors.blue[400]};
  }
`

export default NoteDashboardDeunggibuTable
