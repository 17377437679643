import { Empty, Modal } from 'components/design'
import { useFormContext } from 'react-hook-form'
import CheckoutPage from '../NotePayment/Checkout'

/**
 * 전세 피해 계정 전용 등기부 1건 발급 모달
 * @param {*} param0
 * @returns
 */
const NoteDetailGongbuEmergencyModal = ({
  visible,
  onClose,
  paymentResult,
  emergencyAmount,
  emergencyCount,
  uniqueAddress,
  subType,
  validOnly,
  customAddress,
  customDongHo,
}) => {
  const { watch } = useFormContext()
  const note_srl = watch('note_srl')
  const channel_srl = watch('channel_srl')
  const address_srl = watch('address_srl')

  return (
    <Modal
      title="결제 주문서"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      {paymentResult ? (
        <CheckoutPage
          amount={emergencyAmount}
          count={emergencyCount}
          customerKey={paymentResult.customer_key}
          customerName={paymentResult.customer_name}
          orderId={paymentResult.order_id}
          customerEmail={paymentResult.customer_email}
          orderName={`등기부등본`}
          type="등기부등본"
          isEmergency={true}
          emergencyInfo={{
            note_srl: note_srl,
            channel_srl: channel_srl,
            address_srl: address_srl,
            sub_type: subType,
            valid_only: validOnly,
            address: customAddress,
            detail: customDongHo,
            unique_no: uniqueAddress?.UniqueNo || null,
          }}
        />
      ) : (
        <Empty>결제 정보가 없습니다. 다시 시도해주세요</Empty>
      )}
    </Modal>
  )
}

export default NoteDetailGongbuEmergencyModal
