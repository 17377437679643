import { useEffect, useState } from 'react'
import { Button } from 'components/design'
import { Input } from 'components/design'
import { Textarea } from 'components/design'
import { Modal } from 'components/design'
import { ModalFooter } from 'components/design'
import { Form } from 'components/design'
import { FormItem } from 'components/design'

import NoteContactFormNumber from './NoteContactFormNumber'

const NoteContactForm = ({
  defaultValue,
  title,
  visible,
  setVisible,
  onSubmit,
  onRemove,
  inputValue,
  setInputValue,
}) => {
  const [type, setType] = useState('')
  const [alias, setAlias] = useState('')
  const [numbers, setNumbers] = useState([
    {
      number: '',
      label: '',
    },
  ])
  const [memo, setMemo] = useState('')

  useEffect(() => {
    if (!defaultValue) {
      setType('')
      setAlias('')
      setNumbers([
        {
          number: inputValue ? inputValue : '',
          label: '휴대전화',
        },
      ])
      setMemo('')
      return
    }

    setType(defaultValue.type)
    setAlias(defaultValue.alias)
    setNumbers(defaultValue.numbers)

    setMemo(defaultValue.memo)
  }, [visible, defaultValue, inputValue])

  const onCancel = () => {
    setVisible(false)
  }

  const onOk = () => {
    onSubmit({
      type,
      alias:
        alias == '' ? `임시${Math.floor(Math.random() * 1000)}` : alias,
      numbers: JSON.stringify(numbers),
      memo,
    })
    if (inputValue) {
      setInputValue('')
    }
  }

  const onChangeNumber = ({ index, number, label }) => {
    setNumbers((prev) => {
      const changeValue = [...prev]
      changeValue[index] = {
        number,
        label,
      }
      return changeValue
    })
  }

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      onOk={onOk}
      footer={
        <ModalFooter
          prepend={
            defaultValue ? (
              <Button color="danger" border onClick={() => onRemove()}>
                삭제
              </Button>
            ) : null
          }
        >
          <Button color="gray" onClick={() => onCancel()}>
            취소
          </Button>

          <Button color="blue" onClick={() => onOk()}>
            저장
          </Button>
        </ModalFooter>
      }
    >
      <Form>
        <FormItem label="이름" isRequired noContainer>
          <Input
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
            placeholder="이름"
            expand
          />
        </FormItem>

        <FormItem label="번호" isRequired noContainer>
          {numbers?.map((item, i) => (
            <NoteContactFormNumber
              inputValue={inputValue}
              key={i}
              item={item}
              index={i}
              setNumbers={setNumbers}
              onChange={onChangeNumber}
            />
          ))}

          <Button
            expand
            onClick={() => setNumbers((prev) => [...prev, ''])}
          >
            번호 추가
          </Button>
        </FormItem>

        <FormItem label="메모" noContainer noMargin>
          <Textarea
            border="blue"
            value={memo || ''}
            onChange={(e) => setMemo(e.target.value)}
          />
        </FormItem>
      </Form>
    </Modal>
  )
}

export default NoteContactForm
