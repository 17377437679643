import theme from 'lib/styles/theme'
import { ReactComponent as Icon } from 'assets/icon/note_guide.svg'
import styled from 'styled-components'

const NoteDashboardGuide = () => {
  return (
    <Wrapper href="https://jootek.com/guide/category/9506">
      매물노트 이용 가이드 바로가기
      <Angle>
        <i className="far fa-angle-right"></i>
      </Angle>
      <Icon />
    </Wrapper>
  )
}

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding: 24px;
  background-color: #6048f0;
  color: ${theme.colors.base.white};
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.base.white};
  }

  svg {
    position: absolute;
    top: 14px;
    right: 24px;
  }
`

const Angle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  color: #6048f0;
  font-size: 14px;
  border-radius: 50%;
  background-color: ${theme.colors.base.white};
`

export default NoteDashboardGuide
