import styled from 'styled-components'
import theme from 'lib/styles/theme'

const NoteDashboardNotiMore = ({
  visible = true,
  onClick = null,
  text,
}) => {
  if (!visible) return null

  return (
    <StyledMore expand onClick={onClick}>
      {text}
    </StyledMore>
  )
}

const StyledMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${theme.colors.blue[400]};
  height: 40px;
  text-align: center;
  cursor: pointer;
`

export default NoteDashboardNotiMore
