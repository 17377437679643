import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

const StyledSearchInput = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 10px 16px;

  color: ${theme.colors.gray[900]};
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  background-color: ${theme.colors.gray[100]};
  border: none;
  outline: none;
  appearance: none;
  border-radius: 6px;

  &::placeholder {
    color: ${theme.colors.gray[400]};
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  ${media.mediumS`
    padding: 10px 16px;
  `}
`

const SearchBackspace = styled.button`
  position: absolute;
  top: 0;
  right: 8px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5rem 1.25rem 0.5rem 0;

  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
  font-weight: 700;

  background: none;
  appearance: none;

  & > svg {
    width: 20px;
    height: 1.25rem;
  }
`

const SearchInput = ({
  searchInput,
  setSearchInput,
  onSearch,
  onBlur,
  onClear,
  autoFocus,
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (!autoFocus) return
    inputRef.current.focus()
  }, [])

  // 엔터키 입력
  const onKeyUp = (e) => {
    if (e.keyCode == 13) {
      // if (searchInput === '') return
      onSearch(searchInput)
      inputRef.current.blur()
    }
  }

  // 인풋 밸류가 변경될 때마다 inputValue state를 set
  const onChange = (e) => {
    setSearchInput(e.target.value)
  }

  const onClickBackspace = () => {
    inputRef.current.value = ''
    setSearchInput('')
    if (onClear) onClear()
  }

  return (
    <>
      <StyledSearchInput
        type="search"
        placeholder="주소∙장소명으로 검색해보세요"
        onKeyUp={onKeyUp}
        onChange={onChange}
        onBlur={onBlur}
        value={searchInput}
        ref={inputRef}
      />

      {searchInput && (
        <SearchBackspace
          className="search-backspace"
          onClick={onClickBackspace}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </SearchBackspace>
      )}
    </>
  )
}

export default SearchInput
