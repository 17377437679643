import { message } from 'antd'
import { uploadImage } from 'lib/api/note/imageApi'
import { getListing } from 'lib/api/notev2/listApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import imageCompression from 'browser-image-compression'
import { useSelector } from 'react-redux'

const NotePhotoUploadContainer = ({ children, setLoading }) => {
  const { watch, setValue } = useFormContext()
  const images = watch('images')
  const noteSrlByImage = watch('note_srl')
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  //const { channelSrl, folderSrl, addressSrl, noteSrl } = useNoteMatch()
  const channel_srl = watch('channel_srl')
  const address_srl = watch('address_srl')
  const note_srl = watch('note_srl')
  const [imageUploadStack, setImageUploadStack] = useState([])
  const [imageUploadStackMax, setImageUploadStackMax] = useState(0)
  const [onRequestInfo, , dataInfo] = useAxios(getListing)

  // @METHOD: imageUploadStack의 변화를 감지하여 스택이 max에 도달했을 경우 form에 setValue함
  useEffect(() => {
    if (imageUploadStack.length === 0) return
    if (imageUploadStackMax === 0) return

    // 이미지 업로드 스택이 max에 도달하지 않았을 경우 중단
    if (imageUploadStack.length !== imageUploadStackMax) return

    // 스택이 max에 도달할 경우 setValue
    setValue('images', [...images, ...imageUploadStack])

    // state를 초기화
    setImageUploadStack([])
    setImageUploadStackMax(0)

    // 로딩 완료
    setLoading(false)
  }, [imageUploadStack, imageUploadStackMax])

  // @METHOD: file input change를 감지하여 업로드 요청 함수 실행
  const onChangeFileInput = async (e) => {
    const files = e.target.files

    // 업로드한 파일이 없을 경우 실행하지 않음
    if (files?.length === 0) return

    // 로딩 시작
    setLoading(true)

    // 스택을 초기화
    setImageUploadStack([])
    // 스택의 최대 길이를 파일 갯수만큼 지정 (최대 길이에 도달하는 시점에 setValue)
    setImageUploadStackMax(files.length)

    /**
     * @INFO: 첨부한 파일 갯수만큼 요청
     * 성능 최적화를 위해 요청이 비동기적으로 실행되길 바라기 때문에 재귀함수를 사용하지 않음.
     * 업로드 작업의 완료는 위 useEffect에서 진행함.
     */
    //   for (let i = 0; i < files.length; i++) {
    onRequestUpload(files)
    //  }
  }

  let fileIndex = 0

  const onRequestUpload = async (files, pNoteSrl, pIndex) => {
    const index = pIndex ? pIndex : fileIndex

    if (fileIndex >= files.length) return
    const formData = new FormData()
    formData.append('_rx_csrf_token', loginInfo?.csrf_token)
    formData.append('channel_srl', channel_srl)
    //  formData.append('folder_srl', folderSrl)
    formData.append('address_srl', address_srl)
    if (noteSrlByImage) {
      formData.append('note_srl', noteSrlByImage)
    } else {
      formData.append('note_srl', pNoteSrl ? pNoteSrl : note_srl)
    }

    const file = files[index]
    if (
      ![
        'image/gif',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/bmp',
        'image/webp',
      ].includes(file.type)
    ) {
      message.info(
        '지원하지 않는 파일 형식이 포함되어 있습니다. 지원 파일 형식: gif, jpg, jpeg, png, bmp, webp',
      )
      setLoading(false)
      return
    }

    const options = {
      maxSizeMB: 1, // 이미지 최대 용량
      maxWidthOrHeight: 1920, // 최대 넓이(혹은 높이)
      useWebWorker: true,
      fileType: 'image/jpeg',
      initialQuality: 0.75,
    }

    let compressedFile

    /** 라이브러리 오류 발생 */
    try {
      compressedFile = await imageCompression(file, options)
    } catch (e) {
      message.error(`이미지 파일 압축 중 오류가 발생했습니다.`)
      setLoading(false)
      return
    }

    /** 압축한 파일 첨부 중 오류 발생 */
    try {
      formData.append(
        'fileToUpload',
        new File([compressedFile], compressedFile?.name || ''),
      )
    } catch (e) {
      message.error('파일 첨부 작업 중 오류가 발생했습니다.')
      setLoading(false)
      return
    }

    /** 업로드 api 호출 중 오류 발생 */
    try {
      const { data } = await uploadImage(formData)

      if (data?.error) {
        message.error(data?.message)
        return
      }

      setValue('note_srl', data.note_srl)
      fileIndex += 1
      onRequestUpload(files, data.note_srl, fileIndex)

      /**
       * @INFO: react-hook-form의 setValue가 watch된 값에 대한
       * 불변성을 지켜줄 수 없는 관계로 useState의 prev를 이용하여
       * 이전 저장된 값들에 대한 안정성을 보장하고, 모든 업로드 작업이
       * 완료된 이후에 setValue가 작동할 수 있도록 저장소를 분리한다.
       */
      setImageUploadStack((prev) => [
        ...prev,
        {
          file_srl: data.file_srl,
          thumbnail: data.thumbnail,
          url: data.url,
          note_srl: data.note_srl,
        },
      ])
    } catch (e) {
      message.error('파일 업로드 중 오류가 발생했습니다.')
      setLoading(false)
      return
    }

    onRequestInfo({
      channel_srl,
      note_srl,
    })
  }

  useEffect(() => {
    if (!dataInfo || !dataInfo?.result) return

    setValue('image_cnt', dataInfo.result.image_cnt)
  }, [dataInfo])

  return children({ onChangeFileInput, onRequestUpload })
}

export default NotePhotoUploadContainer
