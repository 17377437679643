import NoteDocsListContainer from 'containers/notev2/NoteDocsListContainer'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDocsGrid from './NoteDocsGrid'
import NoteLeftSide from './NoteLeftSide'
import {
  CommonPadding,
  Header,
  SearchInput,
  Title,
} from '../style/NoteStyle'
import NoteDocsSearchContainer from 'containers/notev2/NoteDocsSearchContainer'
import NoteLayerSwipeContainer from 'containers/notev2/NoteLayerSwipeContainer'
import Swipe from 'react-easy-swipe'
import NoteMainWrapper from '../style/NoteMainWrapper'
import NoteGridFontSizeController from '../NoteList/NoteGridFontSizeController'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteListMobile from '../NoteList/NoteListMobile'
import NoteDocsListMobile from './NoteDocsListMobile'

const NoteDocsList = ({ match }) => {
  const isMobile = useIsMobile()

  return (
    <NoteLayerSwipeContainer>
      {({
        listLayerRef,
        onSwipeStartFilter,
        onSwipeMoveFilter,
        onSwipeEndFilter,
        onSwipeStartList,
        onSwipeMoveList,
        onSwipeEndList,
        closeFilter,
      }) => (
        <Wrapper>
          <NoteDocsSearchContainer>
            {({ bindSearch, search, filters, setFilters }) => (
              <>
                <NoteLeftSide
                  filters={filters}
                  setFilters={setFilters}
                  onSwipeStart={onSwipeStartFilter}
                  onSwipeMove={onSwipeMoveFilter}
                  onSwipeEnd={onSwipeEndFilter}
                />
                <NoteDocsListContainer
                  q={search}
                  filters={filters}
                  url={match?.url}
                  closeFilter={closeFilter}
                >
                  {({ gridRowData, onSearch }) => (
                    <Swipe
                      onSwipeStart={onSwipeStartList}
                      onSwipeMove={onSwipeMoveList}
                      onSwipeEnd={onSwipeEndList}
                    >
                      <NoteMainWrapper
                        isSwipeable
                        ref={listLayerRef}
                        column
                      >
                        {!isMobile && (
                          <StyledHeader>
                            <Title>계약관리</Title>
                          </StyledHeader>
                        )}
                        <HeaderWrap>
                          <InputWrap>
                            <SearchIcon className="fas fa-search"></SearchIcon>
                            <SearchInput
                              fullwidth={isMobile}
                              onKeyDown={onSearch}
                              type="text"
                              placeholder="검색"
                              {...bindSearch}
                            />
                          </InputWrap>
                          {!isMobile && (
                            <FontSizeWrapper>
                              <NoteGridFontSizeController endpos />
                            </FontSizeWrapper>
                          )}
                        </HeaderWrap>

                        {isMobile ? (
                          <NoteDocsListMobile
                            data={gridRowData}
                            onSwipeStart={onSwipeStartList}
                            onSwipeMove={onSwipeMoveList}
                            onSwipeEnd={onSwipeEndList}
                          />
                        ) : (
                          <NoteDocsGrid gridRowData={gridRowData} />
                        )}
                      </NoteMainWrapper>
                    </Swipe>
                  )}
                </NoteDocsListContainer>
              </>
            )}
          </NoteDocsSearchContainer>
        </Wrapper>
      )}
    </NoteLayerSwipeContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const StyledHeader = styled(Header)`
  ${CommonPadding};
  padding-bottom: 54px;

  ${media.mediumS`
    padding-bottom: 20px;
    padding-right: 20px; 
    padding-left: 20px;
  `}
`

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 34px;
`

const SearchIcon = styled.i`
  color: ${theme.colors.gray[700]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`

const HeaderWrap = styled.div`
  width: 100%;
  max-width: 1615px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
  padding-left: 40px;
  padding-right: 40px;

  ${media.mediumS`
    margin-bottom: 0;
    padding: 8px 12px;
  `}
`

const FontSizeWrapper = styled.div`
  ${media.mediumS`
    position: absolute;
    right: 20px;
    top: calc(${theme.base.topHeaderHeight}px + 18px);
  `}
`

export default NoteDocsList
