import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import { Modal } from 'components/design'
import { setRoadView } from 'modules/summary'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const SummaryBuildingRoadView = () => {
  const dispatch = useDispatch()
  const { isSupport } = useSelector((state) => state.summary.roadView)

  return (
    <Wrapper>
      <Tooltip title="로드뷰">
        <RoadViewButton
          onClick={() => dispatch(setRoadView({ isVisible: true }))}
        >
          <i className="fal fa-webcam"></i>
        </RoadViewButton>
      </Tooltip>

      <Modal
        title="알림"
        visible={!isSupport}
        onOk={() => dispatch(setRoadView({ isSupport: true }))}
        onCancel={() => dispatch(setRoadView({ isSupport: true }))}
        okText="확인"
        width={450}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div>해당 필지는 로드뷰를 지원하지 않습니다.</div>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

export const RoadViewButton = styled.button`
  cursor: pointer;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  color: ${theme.colors.gray[900]};
  font-size: 18px;
  border-radius: 100%;
  border: 1px solid ${theme.colors.gray[200]};
  appearance: none;
  outline: none;

  transition: 0.2s ease;
  background-color: ${theme.colors.base.white};

  &:hover {
    color: ${theme.colors.primary[400]};
    border-color: ${theme.colors.gray[300]};
  }
`

export default SummaryBuildingRoadView
