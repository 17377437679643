import { useState, useMemo, useEffect } from 'react'
import SummaryLobBuildingInfo from 'components/summary/SummaryLob/SummaryLobBuildingInfo'
import SummaryLobTimeLine from 'components/summary/SummaryLob/SummaryLobTimeLine'
import { useSelector } from 'react-redux'
import SummaryFloor from 'components/summary/SummaryFloor/SummaryFloor'

/**
 * 건축물 대장, 층별 정보
 *
 *
 * 데이터를 같이 써서 한번에 넣음
 *
 * * 렌더링 여부
 * * 공동주택: O
 * * 공동주택 아님 : O
 */
const SummaryLobContainer = () => {
  const { building, building_group } = useSelector(
    (state) => state.summary.summaryInfo,
  )

  const [buildingList, setBuildingList] = useState([])
  const [buildingFeatures, setBuildingFeatures] = useState({})
  const [buildingFloors, setBuildingFloors] = useState([])
  const [selectedBuildingId, setSelectedBuildingId] = useState('')

  /**
   * @METHOD: context에 담긴 건축물 정보를 새로 바인딩
   */
  useEffect(() => {
    if (building?.result?.length > 0) {
      const formattedBuildingGroup = building_group.map((item) => {
        return {
          ...item,
          isTotal: true,
        }
      }) //총괄 표제부인걸 표시해야함
      setBuildingList([...formattedBuildingGroup, ...building.result])
      if (building_group && building_group.length > 0) {
        //총괄표제부 데이터 있으면 그것을 먼저 선택
        setSelectedBuildingId(building_group[0].id)
      } else {
        setSelectedBuildingId(building.result[0].id)
      }
    }

    if (building?.features) {
      setBuildingFeatures(building.features)
    }

    if (building?.floors) {
      setBuildingFloors(building.floors)
    }

    return () => {
      setBuildingList([])
      setBuildingFeatures({})
      setBuildingFloors([])
      setSelectedBuildingId('')
    }
  }, [building])

  const getSelectedBuilding = () => {
    return {
      building: buildingList.find((e) => e.id === selectedBuildingId),
      features: buildingFeatures[selectedBuildingId],
      floors: buildingFloors.filter(
        (e) => e.building_id === selectedBuildingId,
      ),
    }
  }

  const selectedBuildingData = useMemo(
    () => getSelectedBuilding(),
    [
      buildingList,
      buildingFeatures,
      buildingFloors,
      selectedBuildingId,
    ],
  )

  return (
    selectedBuildingData?.building && (
      <>
        <SummaryLobBuildingInfo
          buildingList={buildingList}
          selectedBuildingData={selectedBuildingData}
          selectedBuildingId={selectedBuildingId}
          setSelectedBuildingId={setSelectedBuildingId}
          timeline={
            <SummaryLobTimeLine
              selectedBuilding={selectedBuildingData?.building}
            />
          }
        />
        {selectedBuildingData?.floors &&
          selectedBuildingData?.floors.length > 0 && (
            <SummaryFloor
              selectedBuildingFloors={selectedBuildingData?.floors}
            />
          )}
      </>
    )
  )
}

export default SummaryLobContainer
