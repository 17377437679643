import { convertNoteToNaver, getOptions } from 'lib/api/notev2/adForm'
import useAxios from '../useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import NoteDetailDonghoRequiredModal from 'components/jmapnotev2/detail/NoteDetailDonghoRequiredModal'
import NoteDetailHoRequiredModal from 'components/jmapnotev2/detail/NoteDetailHoRequiredModal'
import NoteDetailAddressRequiredModal from 'components/jmapnotev2/detail/NoteDetailAddressRequiredModal'

/**
 * 광고 작성
 * 네이버 매물 형태로 convert
 */
function useAdConvert() {
  const history = useHistory()
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [isVisibleDonghoModal, setIsVisibleDonghoModal] =
    useState(false)
  const [isVisibleHoModal, setIsVisibleHoModal] = useState(false)
  const [isVisibleAddressModal, setIsVisibleAddressModal] =
    useState(false)
  const [onRequest, , dataConvert, errorConvert] = useAxios(
    convertNoteToNaver,
    {
      resetError: true,
    },
  )

  /**
   * 네이버 매물 등록하기
   */
  const onClickAdLink = ({
    address_srl,
    property_type,
    channel_srl,
    note_srl,
    ho,
  }) => {
    if (!address_srl) {
      setIsVisibleAddressModal(true)
      return
    }
    if (
      [
        '아파트',
        '오피스텔',
        '아파트 분양권',
        '오피스텔 분양권',
        '상가',
        '사무실',
        '다세대주택',
      ].includes(property_type) &&
      !ho
    ) {
      setIsVisibleDonghoModal(true)
      return
    }

    if (
      ['원룸', '1.5룸', '투룸', '쓰리룸+'].includes(property_type) &&
      !ho
    ) {
      setIsVisibleHoModal(true)
      return
    }

    onRequest({
      channel_srl,
      note_srl,
      address_srl,
      plan_srl: planInfo.allinone_plan_srl,
    })
  }

  useEffect(() => {
    if (!dataConvert) return
    if (!dataConvert?.result) {
      message.error('광고 데이터 변환에 실패했습니다.')
      return
    }

    const adSrl = dataConvert.result.ad_srl

    history.push({
      pathname: `/note/v2/ad/link/${adSrl}`,
      state: {
        ...dataConvert.result,
      },
    })
  }, [dataConvert])

  useEffect(() => {
    if (!errorConvert) return
    message.error('매물 데이터 변환 중 오류가 발생했습니다.')
  }, [errorConvert])

  /**
   * 동, 호 모달 닫기
   */
  const onCloseDonghoModal = () => {
    setIsVisibleDonghoModal(false)
  }

  /**
   *  호 모달 닫기
   */
  const onCloseHoModal = () => {
    setIsVisibleHoModal(false)
  }

  /**
   *  주소 확인 모달 닫기
   */
  const onCloseAddressModal = () => {
    setIsVisibleAddressModal(false)
  }
  /**
   * 광고 이력 보러가기
   */
  const onClickGoAdList = ({ address_doro, address_jibun }) => {
    history.push(
      `/note/v2/ad?search_keyword=${address_doro || address_jibun}`,
    )
  }

  return {
    onClickAdLink,
    onClickGoAdList,
    donghoRequiredModal: (
      <NoteDetailDonghoRequiredModal
        visible={isVisibleDonghoModal}
        onOk={onCloseDonghoModal}
        onCancel={onCloseDonghoModal}
      />
    ),
    hoRequiredModal: (
      <NoteDetailHoRequiredModal
        visible={isVisibleHoModal}
        onOk={onCloseHoModal}
        onCancel={onCloseHoModal}
      />
    ),
    addressRequiredModal: (
      <NoteDetailAddressRequiredModal
        visible={isVisibleAddressModal}
        onOk={onCloseAddressModal}
        onCancel={onCloseAddressModal}
      />
    ),
  }
}

export default useAdConvert
