import { useEffect, useMemo, useState } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { message } from 'antd'
import {
  requestBillingInfo,
  updatePlan,
} from 'lib/api/notev2/paymentApi'
import { useSelector } from 'react-redux'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

/**
 * 요금제 리스트 페이지의
 * 모든 요금제 결제 로직 관리
 */
const NotePaymentBillingContainer = ({
  children,
  setAmount,
  setMonth,
  setAllinoneMemberCount,
  setType,
  setBillingResult,
  setIsOpenWarningModal,
  setIsDirectPayment,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const { hasPlan } = useNoteHasPlan()

  const [onRequestBillingInfo, , dataBillingInfo, errorBillingInfo] =
    useAxios(requestBillingInfo, {
      resetError: true,
    })

  const [
    onRequestUpdatePlan,
    loadingUpdate,
    updateResult,
    errorUpdatePlan,
  ] = useAxios(updatePlan, {
    resetError: true,
  }) //요금제 업데이트

  const [isLoading, setIsLoading] = useState(false) //결제중일땐 로딩창 true

  const { isAllInOne } = useIsAllInOne()

  /**
   * 결제하기(요금제 선택 페이지에서 결제는 모두 이 함수 사용!!)
   * @param plan 플랜명
   * @param amount 금액
   * @param month 월(올인원)
   * @param allinoneMemberCount 올인원 결제 사용 인원수
   */
  const onClickPayment = (plan, amount, month, allinoneMemberCount) => {
    const additionalParams = {
      allinone_member_count: allinoneMemberCount,
    }

    //현재 플랜이 없는 경우 - 생성, 있으면 요금제 변경
    if (
      !hasPlan ||
      ['베이직', '프리', '프리미엄(베타)'].includes(
        planInfo?.payment_name,
      ) ||
      isAllInOne
    ) {
      let param = getParams(
        plan,
        amount,
        month,
        hasPlan ? '요금제변경' : '생성',
      )
      setType(plan)
      setAmount(amount)

      if (plan == '올인원') {
        setMonth(month)
        setAllinoneMemberCount(allinoneMemberCount)
        param = Object.assign({}, param, additionalParams)
      }

      if (planInfo?.has_billingkey) {
        // 기존에 등록한 카드가 있으면 '카드등록' 단계 스킵하고 요금제 변경하는 API 호출
        if (plan == '올인원') {
          //올인원인 경우, 청구서를 이미 보여줬기 때문에 바로 결제
          setIsDirectPayment(true)
        } else {
          // 수정하시겠습니까? 모달창 띄움
          setIsOpenWarningModal(true)
        }
      } else {
        // 카드 등록 후 결제하는 프로세스 API 호출
        onRequestBillingInfo(param)
        setIsLoading(true)
      }
    } else {
      message.error('오류가 발생했습니다. 관리자에게 문의해주세요.')
    }
  }

  /**
   * 결제 파라미터 생성
   * @param {*} type  결제 타입 - 생성, 요금제 변경
   * @returns
   */
  const getParams = (plan, amount, month, type) => {
    return {
      order_name: plan,
      amount: amount,
      month: month,
      type: type,
    }
  }

  useEffect(() => {
    if (!dataBillingInfo) {
      setIsLoading(false)
      return
    }
    setBillingResult(dataBillingInfo?.result)
    setIsLoading(false)
  }, [dataBillingInfo])

  useEffect(() => {
    if (!errorBillingInfo) return
    setIsLoading(false)
  }, [errorBillingInfo])

  return children({
    onClickPayment,
    isLoading,
  })
}

export default NotePaymentBillingContainer
