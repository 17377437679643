import { useCallback, useEffect, useRef, useState } from 'react'
import {
  List,
  ListItem,
  ListItemDesc,
  ListItemLabel,
} from 'components/design'
import styled from 'styled-components'
import useAxios from 'lib/hooks/useAxios'
import { getValidAddresses } from 'lib/api/notev2/listApi'
import NoteSearchAddressModal from '../elements/NoteSearchAddressModal'
import useSaveAddressForSheet from 'lib/hooks/notev2/useSaveAddressForSheet'
import useSaveNotes from 'lib/hooks/notev2/useSaveNotes'
import { useSelector } from 'react-redux'

/**
 * 매물 노트 모바일 주소 검색 모달
 */
const NoteListMobileAddressSearchModal = ({
  mode,
  folderSrl,
  visible,
  setVisible,
  onClickItem,
  defaultValue,
  callbackSearch,
}) => {
  const [searchResults, setSearchResults] = useState([])
  const inputRef = useRef(null)
  const [query, setQuery] = useState(defaultValue || '')
  const [onRequestValid, , dataValidAddr] = useAxios(getValidAddresses)
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const { onSaveAddressForSheet: onSaveAddressForSheetOneAddr } =
    useSaveAddressForSheet(mode)
  const [
    onSaveNoteInvalidAddr,
    noteSrlInvalidAddr,
    inErrorInvalidAddr,
    currentRowNodeDataInvalidAddr,
  ] = useSaveNotes(mode)

  //주소 검색 input 포커스
  useEffect(() => {
    if (visible) {
      inputRef.current.focus()
    }
  }, [visible])

  useEffect(() => {
    if (!query) resetInput('')
  }, [query])

  useEffect(() => {
    if (visible) setQuery(defaultValue?.trim())
  }, [visible])

  // @METHOD: 주소 검색
  const searchAddress = () => {
    if (query == defaultValue) {
      setVisible(false)
      return
    }
    onRequestValid({ address: query })
  }

  /**
   * 유효한 주소 데이터 얻기
   */
  const getValidAddressSaveDatas = useCallback((data, dong, ho) => {
    const updateData = Object.assign({}, data, {
      dong: dong,
      ho: ho,
      invalid_addr_name: '',
      address_doro: data.doro_address,
      display_address: data.display_address,
      address_jibun: data.full_address,
    })

    return updateData
  }, [])

  useEffect(() => {
    if (!dataValidAddr || !dataValidAddr.result) return

    if (dataValidAddr.result.length == 1) {
      //주소 검증 성공(단일) => saveNote
      const validData = dataValidAddr.result[0]
      onSaveAddressForSheetOneAddr({
        channel_srl: channelInfo.channel_srl,
        address_id: validData.address_id,
        validAddressInfo: validData,
        callbackFn: callbackSearch,
        folder_srl: folderSrl,
      })

      setVisible(false)
    } else if (dataValidAddr.result.length > 1) {
      //주소 검증 성공 (여러개) => dropdown 생성

      setSearchResults(dataValidAddr.result)
    } else if (dataValidAddr.result.length == 0) {
      //주소 검증 실패

      onSaveNoteInvalidAddr({
        channel_srl: channelInfo.channel_srl,
        folder_srl: folderSrl,
        form: {
          invalid_addr_name: query,
        },
        callbackFn: callbackSearch,
      })
      setVisible(false)
    }
  }, [dataValidAddr])

  const resetInput = (msg) => {
    setSearchResults([])
  }

  const handleCancel = () => {
    setVisible(false)
    setQuery('')
    setSearchResults([])
  }

  /**
   * 주소 저장후 address_srl 세팅
   */

  return (
    <NoteSearchAddressModal
      visible={visible}
      searchAddress={searchAddress}
      handleCancel={handleCancel}
      data={searchResults}
      ref={inputRef}
      query={query}
      setQuery={setQuery}
      list={
        searchResults &&
        searchResults.length > 0 && (
          <List noContainer>
            {searchResults.map((item, i) => (
              <ListItem
                key={i}
                onClick={() => {
                  onClickItem(item, query)
                  handleCancel()
                }}
                noContainer
                dense
              >
                <ListItemLabel>
                  {item.full_address}
                  {item.doro_partial && (
                    <>
                      &#40;{item.doro_partial}
                      &#41;
                    </>
                  )}
                </ListItemLabel>
                {item.doro_extra && (
                  <ListItemDesc>{item.doro_extra}</ListItemDesc>
                )}
              </ListItem>
            ))}
          </List>
        )
      }
    />
  )
}

export default NoteListMobileAddressSearchModal
