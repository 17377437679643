import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { mix, rgba } from 'polished'
import { forwardRef, useContext } from 'react'
import styled from 'styled-components'
import { getComputedArea } from 'utils'

const RealPriceMarker = (
  {
    active,
    isDot,
    type,
    price,
    date,
    propertyType,
    aptName,
    exclusiveArea,
  },
  ref,
) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const colorCodes = {
    red: '#ee553d',
    pink: '#e84cb9',
    orange: '#e0a40c',
    green: '#19C35C',
    cyan: '#40a4ff',
    blue: '#247bfc',
    purple: '#7c42f8',
    black: theme.colors.gray[900],
  }

  const colors = {
    단독: colorCodes['red'],
    다가구: colorCodes['blue'],
    연립다세대: colorCodes['blue'],
    상업업무용: colorCodes['pink'],
    아파트: colorCodes['cyan'],
    오피스텔: colorCodes['purple'],
    공장창고: theme.colors.gray[700],
    토지: colorCodes['orange'],
    분양권: colorCodes['green'],
  }
  const getColor = (type) => {
    return colors[type] || theme.colors.gray[600]
  }

  const icons = {
    단독: 'home',
    다가구: 'hotel',
    연립다세대: 'hotel',
    아파트: 'building',
    오피스텔: 'building',
    상업업무용: 'store',
    공장창고: 'industry-alt',
    토지: 'expand',
    분양권: 'ballot-check',
  }
  const getIcon = (type) => {
    return icons[type] || 'location'
  }

  if (isDot && !active)
    return (
      <div ref={ref}>
        <DotWrapper>
          <Dot color={getColor(type)} />
        </DotWrapper>
      </div>
    )

  return (
    <div ref={ref}>
      <Marker
        wide={propertyType == '아파트' && aptName}
        color={getColor(type)}
        className={active ? 'active' : null}
      >
        <PropertyType color={getColor(type)}>
          {type === '상업업무용' ? '상가' : type}
        </PropertyType>

        <Icon color={getColor(type)}>
          <i className={`far fa-${getIcon(type)}`} />
        </Icon>

        {propertyType == '아파트' && aptName ? (
          <Body>
            <BuildingName>{aptName}</BuildingName>
            <PriceWrapper>
              <Price>{price}</Price>
              <Area>
                {getComputedArea(exclusiveArea, isSquareMeter)}
              </Area>
            </PriceWrapper>
          </Body>
        ) : (
          <Body>
            <Price>{price}</Price>
            <Date>{date}</Date>
          </Body>
        )}
      </Marker>
    </div>
  )
}

const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 90px;
  height: 38px;
`
const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
  border: 1px solid
    ${({ color }) => {
      return mix(0.5, color, theme.colors.gray[900])
    }};
`

const PropertyType = styled.span`
  pointer-events: none;

  position: absolute;
  top: -1.75rem;
  padding: 2px 6px;
  border-radius: ${theme.borderRadius[1]};

  color: white;
  font-size: 12px;
  font-weight: 700;
  background-color: ${({ color }) => color};

  opacity: 0;
  transform: translateY(4px);

  transition: 0.2s ease;
`

const Icon = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 3px;

  color: ${({ color }) => color};
  background-color: ${({ color }) => {
    return mix(0.1, color, '#ffffff')
  }};

  border-radius: 100%;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  & > i {
    font-size: 1rem;
  }
`

const Body = styled.div`
  flex: 1;
  padding-left: 0.25rem;
  padding-right: 0.75rem;
`
const Price = styled.div`
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
`
const Date = styled.div`
  color: ${theme.colors.gray[600]};
`
const Area = styled.div`
  margin-left: 3px;
  color: ${theme.colors.gray[600]};
  font-weight: 500;
  line-height: 130%;
`

const Marker = styled.div`
  cursor: pointer;
  pointer-events: all;
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 4px 16px ${rgba('black', 0.3)};
  padding: 0;
  color: ${theme.colors.gray[900]};
  font-size: 10px;
  text-align: left;
  border: 1px solid ${({ color }) => color};
  box-shadow: 0px 6px 16px
    ${({ color }) => {
      const shadowColor = mix(0.5, color, theme.colors.gray[900])

      return rgba(shadowColor, 0.3)
    }};
  transition: none;

  ${({ wide }) => !wide && `width: 90px;`}

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    // left: 18px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ color }) => color} transparent transparent
      transparent;
  }

  &:hover,
  &.active {
    color: white;
    background-color: ${({ color }) => color};
    ${Date}, ${Area} {
      color: ${rgba('white', 0.6)};
    }
    ${PropertyType} {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const BuildingName = styled.div`
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

export default forwardRef(RealPriceMarker)
