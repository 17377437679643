import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { MaxWidthWrapper } from 'components/jmapnotev2/style/NoteStyle'
import NoteMainWrapper from 'components/jmapnotev2/style/NoteMainWrapper'
import NotePaymentPersonNumberSelectButton from './NotePaymentPersonNumberSelectButton'
import NotePaymentPersonNumberSelectContainer from 'containers/notev2/NotePaymentPersonNumberSelectContainer'
import NotePaymentPersonNumberSelectInput from './NotePaymentPersonNumberSelectInput'
import NotePaymentPersonNumberConfirmModal from './NotePaymentPersonNumberConfirmModal'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'

/**
 * 올인원 결제 - 멤버 초대 페이지
 * @param search 멤버 search
 * @param onCancel 현재 페이지 닫기
 * @returns
 */
const NotePaymentPersonNumberSelect = ({ search, onCancel }) => {
  return (
    <NoteMainWrapper gray single singleM>
      <Scrollbars autoHide>
        <StyledMaxWidthWrapper>
          <Wrapper>
            <NotePaymentPersonNumberSelectContainer
              search={search}
              onCancel={onCancel}
            >
              {({
                loading,
                input,
                errorNumbers,
                isAllValidated,
                confirmModalVisible,
                personCount,
                finalAmount,
                finalPerAmount,
                finalPersonCount,
                removeInput,
                handleTextValueChange,
                addInput,
                onBlurInput,
                month,
                perAmount,
                onConfirmModalClose,
                onConfirmModalOk,
                onClickPaymentButton,
              }) => {
                return (
                  <>
                    {loading && <FullScreenLoading highLevel />}
                    <NotePaymentPersonNumberSelectInput
                      perAmount={perAmount}
                      month={month}
                      handleTextValueChange={handleTextValueChange}
                      addInput={addInput}
                      removeInput={removeInput}
                      onBlurInput={onBlurInput}
                      input={input}
                      errorNumbers={errorNumbers}
                    />
                    <NotePaymentPersonNumberSelectButton
                      onClickPayment={onClickPaymentButton}
                      month={month}
                      sumPerson={finalPersonCount}
                      isAllValidated={isAllValidated}
                      confirmModalVisible={confirmModalVisible}
                      finalAmount={finalAmount}
                    />
                    {confirmModalVisible && (
                      <NotePaymentPersonNumberConfirmModal
                        visible={confirmModalVisible}
                        onOk={onConfirmModalOk}
                        onCancel={onConfirmModalClose}
                        sum={finalAmount}
                        perAmount={finalPerAmount}
                        sumPerson={finalPersonCount}
                      />
                    )}
                  </>
                )
              }}
            </NotePaymentPersonNumberSelectContainer>
          </Wrapper>
        </StyledMaxWidthWrapper>
      </Scrollbars>
    </NoteMainWrapper>
  )
}

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  display: flex;
  background-color: ${theme.colors.base.white};
  padding: 60px 40px 100px 40px;
  margin: 0 auto;
  height: auto;
  min-height: 100%;

  ${media.smallPc`
    width: 100%;
    padding: 60px 20px 100px 20px;
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
`

export default NotePaymentPersonNumberSelect
