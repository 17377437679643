import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 월세액
 */
const NoteAdColumnRent = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const tradeType = watch('trade.type')
  const price = watch('trade.price2')

  if (tradeType == 'A1') return

  return (
    <ColumnSet half>
      <ColumnTitle required>월세액</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            width={InputRemoveAppendWidth}
            type="number"
            fixAppendWidth
            defaultValue={price}
            {...register('trade.price2')}
            onBlur={(e) => {
              onUpdate(
                'trade.price2',
                e.target.value.replaceAll(',', ''),
              )
            }}
            append={'만원'}
          />
        </div>
        <ValidateWarn>{errors?.trade?.price2?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnRent
