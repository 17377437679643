import { useEffect, useState } from 'react'

/**
 * @name useIsIOSApp
 * @description userAgent를 이용하여 IOS 앱인지 감지
 * @returns true / false
 */
export default function useIsIOSApp() {
  const [isIOS, setisIOS] = useState(false)

  useEffect(() => {
    const ios = navigator.userAgent.includes('com.jootek.mobile')

    setisIOS(ios)
  }, [])

  return isIOS
}
