import styled from 'styled-components'

const StyledHeaderDivider = styled.div`
  flex: 1;
`

const HeaderDivider = () => {
  return <StyledHeaderDivider />
}

export default HeaderDivider
