import { forwardRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useAxios from 'lib/hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { getNote } from 'lib/api/note/noteApi'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { getMaxRowHeight } from 'lib/utils/notev2/gridData'
import useIsMobile from 'lib/hooks/useIsMobile'
import usePreventLeave from 'lib/hooks/usePreventLeave'

const defaultValues = {
  contact_srls: [
    {
      contract_srl: '',
      member_srl: '',
      type: '구분없음',
      numbers: [
        {
          number: '',
          label: '휴대전화',
        },
      ],
      alias: '',
      memo: '',
    },
  ],
}

const NoteDetailContainer = ({ children }, ref) => {
  const [visible, setVisible] = useState(false)
  const [onRequestNote, , dataNote] = useAxios(getNote)
  const isMobile = useIsMobile()

  const params = useParams()
  const history = useHistory()

  const { enablePrevent, disablePrevent } = usePreventLeave()

  const methods = useForm({
    defaultValues: Object.assign({}, defaultValues),
  })

  useEffect(() => {
    setVisible(true)
    onRequestNote({
      channelSrl: params.channelSrl,
      noteSrl: params.noteSrl,
      addressSrl: params.addressSrl,
    })
    enablePrevent()

    return () => {
      disablePrevent()
    }
  }, [])

  useEffect(() => {
    if (!dataNote) return
    methods.reset(
      Object.assign(
        {},
        dataNote?.result?.extra_vars,
        dataNote.result,
        defaultValues,
        {
          contact_srls: dataNote?.result?.contacts
            ? JSON.stringify(dataNote.result.contacts)
            : {},
        },
      ),
    )
  }, [dataNote])

  const callGetNote = () => {
    onRequestNote({
      channelSrl: params.channelSrl,
      noteSrl: params.noteSrl,
      addressSrl: params.addressSrl,
    })
  }

  const onClose = (methods, mode) => {
    if (mode == 'card') {
      history.replace(`/note/${params.channelSrl}/${params.folderSrl}`)
      return
    }
    if (!methods) {
      history.replace(`/note/v2/notes/${params.channelSrl}`)
      return
    }

    if (ref.current && ref.current?.props && ref.current?.api) {
      const getAllRows = (gridApi) => {
        let rowData = []
        gridApi.forEachNode((node) => rowData.push(node.data))
        return rowData
      }

      const gridApi = ref.current.api
      const rowData = getAllRows(gridApi) //  ref.current?.api?.getRenderedNodes()

      const index = rowData.findIndex(
        ({ note_srl }) => note_srl == params.noteSrl,
      )

      if (index >= 0) {
        const currentRowNode = gridApi.getDisplayedRowAtIndex(index)

        //연락처 데이터 - 3뎁스에서 빈 칸으로 입력한 것은 없애고 그리드에 바인딩
        const validContacts = methods
          ?.getValues('contacts')
          ?.filter(({ numbers }) => numbers[0]?.number)
        const validContactSrls = JSON.stringify(validContacts)
        const data = Object.assign({}, methods.getValues(), {
          contacts: validContacts,
          contact_srls: validContactSrls,
        })

        //연락처, 가격 구분 등 늘어난 높이만큼 계산해서 적용
        const maxHeight = getMaxRowHeight({
          contract_type: methods.getValues('contract_type'),
          contacts: validContacts,
        })

        currentRowNode.setRowHeight(maxHeight)
        gridApi.onRowHeightChanged()
        updateGridDatas({
          currentRowNode,
          newData: data,
        })
      }
    }

    if (isMobile) {
      history.replace(`/note/v2/notes/${params.channelSrl}`)
    } else {
      history.push(`/note/v2/notes/${params.channelSrl}`)
    }
  }

  return children({
    visible,
    setVisible,
    methods,
    onClose,
    callGetNote,
  })
}
export default forwardRef(NoteDetailContainer)
