import BaseHeaderNav from 'components/base/BaseHeader/BaseHeaderNav'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import NoteDetailNoteConverter from '../detail/NoteDetailNoteConverter'
import { useSelector } from 'react-redux'

const NoteAdFormMobileHeader = ({ children }) => {
  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )
  const height = useSelector((state) => state.notice.height)

  return (
    <MobileChannelWrapper
      isnoticebarvisible={isNoticeBarVisible ? 'Y' : 'N'}
      noticebarheight={height}
    >
      {children}
      <IconWrapper>
        <NoteDetailNoteConverter color="black" />
        <BaseHeaderNav />
      </IconWrapper>
    </MobileChannelWrapper>
  )
}

const MobileChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  position: fixed;
  left: 0;
  width: 100%;
  height: ${theme.base.headerHeight}px;
  background-color: ${theme.colors.base.white};
  margin-bottom: 0px;
  padding: 20px;
  z-index: 98;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  ${({ isnoticebarvisible, noticebarheight }) =>
    isnoticebarvisible == 'Y'
      ? `top: ${noticebarheight}px !important;`
      : `top: 0;`};
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export default NoteAdFormMobileHeader
