import { Button, Empty } from 'components/design'
import {
  Row,
  SelectBottomHr,
  SelectLabel,
  SelectWrapper,
  Table,
  TableHeader,
  TableValue,
  TableWrapper,
} from 'components/jmapnotev2/style/NoteLayerStyle'
import {
  getBuildingLandRegister,
  getContract,
} from 'lib/api/notev2/contractApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'
import SelectBox from 'components/jmapnotev2/elements/SelectBox'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { InfoBlock } from 'components/jmapnotev2/style/NoteDocsStyle'
import { getBuildingLandByNoteSrl } from 'lib/api/notev2/docs'

const NoteDocsLayerBuildingAndLand = ({ type, noteSrl, dong }) => {
  const [onRequest, , data] = useAxios(getBuildingLandRegister)
  const [onRequestForNote, , dataForNote] = useAxios(
    getBuildingLandByNoteSrl,
  )

  const [land, setLand] = useState({})

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedData, setSelectedData] = useState(null)
  const [selectList, setSelectList] = useState([])
  const [contractDong, setContractDong] = useState('')

  //계약서 데이터
  const [onRequestContract, loadingContract, dataContract] =
    useAxios(getContract)

  const match = useRouteMatch([
    '/note/v2/docs/:channelSrl/:noteSrl/edit/:contractSrl?',
    '/note/v2/notes/:channelSrl/:addressSrl/:noteSrl',
  ])

  const history = useHistory()

  /**
   * 토지, 건축물대장 가져오기
   * 계약서 정보 가져오기
   */
  useEffect(() => {
    if (type == 'docs') {
      const channel_srl = match.params.channelSrl
      const contract_srl = match.params.contractSrl

      onRequest({
        channel_srl,
        contract_srl,
      })

      onRequestContract({
        channel_srl: match.params.channelSrl,
        contract_srl: match.params.contractSrl,
      })
    } else if (type == 'note') {
      onRequestForNote({
        note_srl: noteSrl,
      })
    }
  }, [])

  /**
   * 집합 건물인 경우, select-box의 디폴트값으로 해당되는 '동' 세팅
   */
  useEffect(() => {
    if (!dataContract?.result) return

    if (dataContract.result.dong) {
      setContractDong(dataContract.result.dong)
    }
  }, [dataContract])

  /**
   * 토지 데이터 세팅
   */
  useEffect(() => {
    if (!data?.result) return

    if (data?.result?.land) {
      setLand(data?.result?.land)
    }
  }, [data])

  /**
   * 집합건물 건축물대장 열람시 첫 번째 건물이 아니라 매물이 위치한 동을 기본 선택
   */
  useEffect(() => {
    if (!data?.result || contractDong === '') return

    const building = data.result?.building?.find((item, index) => {
      if (item.dong_name === contractDong) {
        setSelectedIndex(index)
        return item
      }
    })
    setSelectedData(building)
  }, [data, contractDong])

  useEffect(() => {
    if (!dataForNote?.result) return

    if (dataForNote?.result?.land) {
      setLand(dataForNote?.result?.land)
    }

    if (dong) {
      const building = dataForNote.result?.building?.find(
        (item, index) => {
          if (item.dong_name === dong) {
            setSelectedIndex(index)
            return item
          }
        },
      )
      setSelectedData(building)
    }
  }, [dataForNote])

  /**
   * 건축물대장 - 건물 선택
   */
  useEffect(() => {
    if (!dataForNote?.result) return
    setBuilding(dataForNote, selectedIndex)
  }, [dataForNote, selectedIndex])

  /**
   * 건축물대장 - 건물 선택
   */
  useEffect(() => {
    if (!data?.result) return
    setBuilding(data, selectedIndex)
  }, [data, selectedIndex])

  const setBuilding = (data, selectedIndex) => {
    const options = data.result.building?.map(
      (
        {
          id,
          is_sub_building,
          building_name,
          custom_purpose,
          dong_name,
          update_date,
        },
        index,
      ) => {
        const title = (
          <SelectLabel>
            <div>
              {is_sub_building == 0
                ? `[주${index + 1}]`
                : `[부${index + 1}]`}{' '}
              {building_name ?? ''}
              {custom_purpose === '아파트' ? '' : custom_purpose}{' '}
              {dong_name ?? ''}
            </div>
            <span>
              데이터 기준일{' '}
              {update_date
                ? moment(update_date, 'YYYYMMDDHHmmss')?.format(
                    'YYYY.MM.DD',
                  )
                : ''}
            </span>
          </SelectLabel>
        )

        return {
          label: title,
          value: id,
          index: index,
        }
      },
    )

    setSelectList(options)
    setSelectedData(
      data.result.building?.find((item, i) => i == selectedIndex),
    )
  }

  return (
    <Wrapper>
      <InfoBlock mb color="gray">
        하단의 토지·건축물대장은 공개된 건축 데이터를 기반으로
        제작되었습니다. 현재 시점의 토지·건축물대장을 확인하시려면
        세움터를 이용해주세요.
      </InfoBlock>
      <TableWrapper>
        <HeaderWrapper>
          <span>건축물대장</span>
          <Button
            color="blue"
            onClick={() => {
              window.open(
                'https://www.eais.go.kr/moct/bci/aaa02/BCIAAA02L01',
              )
            }}
            target="_blank"
            plain
          >
            세움터에서 확인하기
          </Button>
        </HeaderWrapper>
        {selectedData ? (
          <>
            <SelectWrapper>
              <SelectBox
                size="large"
                noborder
                value={selectList.filter(
                  ({ index }) => index == selectedIndex,
                )}
                minWidth={150}
                options={selectList}
                onChange={(e) => {
                  const index = selectList.findIndex(
                    ({ value }) => value == e.value,
                  )
                  setSelectedIndex(index)
                }}
              />
            </SelectWrapper>
            <SelectBottomHr />
          </>
        ) : (
          <Empty>건축물대장 정보가 없습니다.</Empty>
        )}

        {selectedData && (
          <Table>
            <Row>
              <TableHeader>건물이름</TableHeader>
              <TableValue>
                {selectedData?.building_name ? (
                  selectedData?.building_name
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>주 용도</TableHeader>
              <TableValue>
                {selectedData?.purpose_code_name ? (
                  selectedData?.purpose_code_name
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>기타 용도</TableHeader>
              <TableValue>
                {selectedData?.other_purpose ? (
                  selectedData?.other_purpose
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>주 구조</TableHeader>
              <TableValue>
                {selectedData?.structure_code_name ? (
                  selectedData?.structure_code_name
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>지붕 구조</TableHeader>
              <TableValue>
                {selectedData?.roof_code_name ? (
                  selectedData?.roof_code_name
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>높이</TableHeader>
              <TableValue>
                {selectedData?.building_height ? (
                  Math.round(selectedData?.building_height * 100) /
                    100 +
                  'm'
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>층수</TableHeader>
              <TableValue>
                {selectedData?.floor_count ? (
                  `지상 ${selectedData?.floor_count}층`
                ) : (
                  <span>&ndash;</span>
                )}
                {selectedData?.basement_count
                  ? '/' + `지하 ${selectedData?.basement_count}층`
                  : ''}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>허가일</TableHeader>
              <TableValue>
                {selectedData?.permission_date ? (
                  moment(
                    selectedData?.permission_date,
                    'YYYYMMDDHHmmss',
                  )?.format('YYYY.MM.DD')
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>착공일</TableHeader>
              <TableValue>
                {selectedData?.begin_construction_date ? (
                  moment(
                    selectedData?.begin_construction_date,
                    'YYYYMMDDHHmmss',
                  )?.format('YYYY.MM.DD')
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>사용승인일</TableHeader>
              <TableValue>
                {selectedData?.approval_date ? (
                  moment(
                    selectedData?.approval_date,
                    'YYYYMMDDHHmmss',
                  )?.format('YYYY.MM.DD')
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>대지면적</TableHeader>
              <TableValue>
                {selectedData?.land_area ? (
                  numberWithCommas(
                    Math.round(selectedData?.land_area * 100) / 100,
                  ) + '㎡'
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>건축면적</TableHeader>
              <TableValue>
                {selectedData?.building_area ? (
                  numberWithCommas(
                    Math.round(selectedData?.building_area * 100) / 100,
                  ) +
                  '㎡' +
                  (selectedData?.coverage_ratio &&
                  selectedData?.coverage_ratio !== '0.000'
                    ? ` (건폐율 ${selectedData?.coverage_ratio}%)`
                    : '')
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>연면적</TableHeader>
              <TableValue>
                {selectedData?.total_floor_area ? (
                  numberWithCommas(
                    Math.round(selectedData?.total_floor_area * 100) /
                      100,
                  ) + '㎡'
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>용적률 산정 연면적</TableHeader>
              <TableValue>
                {selectedData?.total_floor_area_2 ? (
                  numberWithCommas(
                    Math.round(selectedData?.total_floor_area_2 * 100) /
                      100,
                  ) +
                  '㎡' +
                  (selectedData?.floor_area_ratio &&
                  selectedData?.floor_area_ratio !== '0.000'
                    ? ` (용적률 ${selectedData?.floor_area_ratio}%)`
                    : '')
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>세대 수</TableHeader>
              <TableValue>
                {selectedData?.household_count
                  ? `${selectedData.household_count}세대`
                  : ''}
                {selectedData?.unit_count
                  ? `${selectedData?.unit_count}가구 `
                  : ''}
                {selectedData?.room_count
                  ? `${selectedData?.room_count}호수 `
                  : ''}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>승강기 수</TableHeader>
              <TableValue>
                {selectedData?.passenger_elevator ? (
                  `${selectedData?.passenger_elevator}대`
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>부속 건축물 면적</TableHeader>
              <TableValue>
                {selectedData?.sub_building_area ? (
                  numberWithCommas(
                    Math.round(selectedData?.sub_building_area * 100) /
                      100,
                  ) + '㎡'
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>부속 건축물 수</TableHeader>
              <TableValue>
                {selectedData?.sub_building_count ? (
                  `${selectedData?.sub_building_count}개`
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>주차 대수</TableHeader>
              <TableValue>
                {selectedData?.out_self_park_count
                  ? `옥외자주식 ${selectedData?.out_self_park_count}대 `
                  : ''}
                {selectedData?.in_self_park_count
                  ? `옥내자주식 ${selectedData?.in_self_park_count}대 `
                  : ''}
                {selectedData?.out_mech_park_count
                  ? `옥외기계식 ${selectedData?.out_mech_park_count}대 `
                  : ''}
                {selectedData?.in_mech_park_count
                  ? `옥내기계식 ${selectedData?.in_mech_park_count}대 `
                  : ''}
                {!selectedData?.out_self_park_count &&
                  !selectedData?.in_self_park_count &&
                  !selectedData?.out_mech_park_count &&
                  !selectedData?.in_mech_park_count && (
                    <span>&ndash;</span>
                  )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>내진설계여부</TableHeader>
              <TableValue>
                {selectedData?.is_earthquake_res > 0 ? (
                  selectedData?.earthquake_res
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>오수정화시설 형식</TableHeader>
              <TableValue>
                {selectedData?.sewage_purif ? (
                  selectedData?.sewage_purif
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row
              noborderbottom={
                selectedData?.builder_num || selectedData?.designer_num
                  ? false
                  : true
              }
            >
              <TableHeader>위반건축물여부</TableHeader>
              <TableValue>
                {selectedData?.is_violation_building ? '위반' : '정상'}
              </TableValue>
            </Row>
            {selectedData?.designer_num &&
              selectedData.designer_srl && (
                <Row
                  noborderbottom={
                    selectedData?.builder_num ? false : true
                  }
                >
                  <TableHeader>설계사</TableHeader>
                  <TableValue>
                    <Link
                      href={`${window.location.origin}/folio/${selectedData.designer_srl}`}
                      target="_blank"
                    >
                      {selectedData.designer_name}
                      <i className="far fa-external-link"></i>
                    </Link>
                  </TableValue>
                </Row>
              )}
            {selectedData?.builder_num && selectedData.builder_srl && (
              <Row noborderbottom>
                <TableHeader>시공사</TableHeader>
                <TableValue>
                  <Link
                    href={`${window.location.origin}/folio/${selectedData.builder_srl}`}
                    target="_blank"
                  >
                    {selectedData.builder_name}
                    <i className="far fa-external-link"></i>
                  </Link>
                </TableValue>
              </Row>
            )}
          </Table>
        )}
      </TableWrapper>

      <Divider thick noSideMargin={30}></Divider>

      <TableWrapper>
        <HeaderWrapper>
          <span>토지대장</span>
          {land?.summary?.id && (
            <Button
              color="blue"
              onClick={() =>
                history.push(`/${land?.summary?.id}/legal`)
              }
              plain
            >
              지도에서 확인하기
            </Button>
          )}
        </HeaderWrapper>
        {land?.summary && (
          <Table hasMargin>
            <Row>
              <TableHeader>토지소재</TableHeader>
              <TableValue>
                {land?.summary?.sido} {land?.summary?.sigungu}{' '}
                {land?.summary?.eupmyeondong}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>지번</TableHeader>
              <TableValue>
                {land?.summary?.num1}{' '}
                {land?.summary?.num2 ? `-${land?.summary?.num2}` : ''}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>지목</TableHeader>
              <TableValue>
                {land?.summary?.land_type ? (
                  land?.summary?.land_type
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row noborderbottom>
              <TableHeader>면적</TableHeader>
              <TableValue>
                {land?.summary?.land_area ? (
                  numberWithCommas(
                    Math.round(land?.summary?.land_area * 100) / 100,
                  ) + '㎡'
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
          </Table>
        )}

        {land?.ownership && contractDong === '' && (
          <Table hasMargin>
            <Row>
              <TableHeader>소유구분</TableHeader>
              <TableValue>
                {land?.ownership?.owner_type ? (
                  land?.ownership?.owner_type
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>거주지 구분</TableHeader>
              <TableValue>
                {land?.ownership?.residence ? (
                  land?.ownership?.residence
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row>
              <TableHeader>소유권 변동 원인</TableHeader>
              <TableValue>
                {land?.ownership?.change_reason ? (
                  land?.ownership?.change_reason
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
            <Row noborderbottom>
              <TableHeader>소유권 변동 일자</TableHeader>
              <TableValue>
                {land?.ownership?.change_date ? (
                  land?.ownership?.change_date
                ) : (
                  <span>&ndash;</span>
                )}
              </TableValue>
            </Row>
          </Table>
        )}

        {land?.history && (
          <Table>
            <Row>
              <TableHeader half>개별공시지가 기준일</TableHeader>
              <TableValue half textAlign="center" gray>
                개별공시지가 (원/㎡)
              </TableValue>
            </Row>
            {land?.history.map((item, i) => {
              return (
                <Row key={i}>
                  <TableHeader half white>
                    {item?.data_date}
                  </TableHeader>
                  <TableValue half textAlign="center">
                    {item?.unit_price ? (
                      numberWithCommas(item?.unit_price)
                    ) : (
                      <span>&ndash;</span>
                    )}
                  </TableValue>
                </Row>
              )
            })}
          </Table>
        )}
      </TableWrapper>
    </Wrapper>
  )
}

const Link = styled.a`
  & > i {
    padding-left: 5px;
  }
`

const Wrapper = styled.div``

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }
`

const Date = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`

const Divider = styled.div`
  border-bottom: 1px solid ${theme.colors.gray[100]};
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  ${({ thick }) =>
    thick &&
    `
    border-bottom: 10px solid ${theme.colors.gray[100]};
  `}

  ${({ noSideMargin }) =>
    noSideMargin &&
    `
    margin-left: -${noSideMargin}px;
    margin-right: -${noSideMargin}px;
  `}

  ${({ noBorder }) =>
    noBorder &&
    `
    border-bottom: none;
    margin-top: 0;
  `}
`
export default NoteDocsLayerBuildingAndLand
