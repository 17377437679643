import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { MaxWidthWrapper } from 'components/jmapnotev2/style/NoteStyle'
import NoteMainWrapper from 'components/jmapnotev2/style/NoteMainWrapper'
import useIsMobile from 'lib/hooks/useIsMobile'
import MobileChannelHeader from 'components/jmapnotev2/channel/MobileChannelHeader'
import NoteLayerSwipeContainer from 'containers/notev2/NoteLayerSwipeContainer'
import Swipe from 'react-easy-swipe'
import NoteDongho from 'components/jmapnotev2/dongho/NoteDongho'
import NoteLeftSide from 'components/jmapnotev2/dongho/NoteLeftSide'
import NoteDonghoMobileTopHeader from 'components/jmapnotev2/dongho/NoteDonghoMobileTopHeader'
import NoteDonghoGoMapButton from 'components/jmapnotev2/dongho/NoteDonghoGoMapButton'
import useIsTablet from 'lib/hooks/useIsTablet'

/**
 * 동호관리 페이지
 */
const NoteDonghoPage = () => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  return (
    <>
      {(isMobile || isTablet) && (
        <MobileChannelHeader
          channel={false}
          customMobileChannel={<NoteDonghoMobileTopHeader />}
          addedIcon={<NoteDonghoGoMapButton background={false} />}
        />
      )}
      <NoteLayerSwipeContainer>
        {({
          listLayerRef,
          onSwipeStartFilter,
          onSwipeMoveFilter,
          onSwipeEndFilter,
          onSwipeStartList,
          onSwipeMoveList,
          onSwipeEndList,
          closeFilter,
        }) => (
          <Wrapper>
            <NoteLeftSide
              onSwipeStart={onSwipeStartFilter}
              onSwipeMove={onSwipeMoveFilter}
              onSwipeEnd={onSwipeEndFilter}
            />
            <Swipe
              onSwipeStart={(e) => onSwipeStartList(e, true)}
              onSwipeMove={(e, ev) =>
                onSwipeMoveList(e, ev, false, true)
              }
              //onSwipeMove={onSwipeMoveList}
              onSwipeEnd={onSwipeEndList}
            >
              <NoteMainWrapper
                earlyMobile
                isSwipeable
                ref={listLayerRef}
              >
                <StyledMaxWidthWrapper full>
                  <NoteDongho />
                </StyledMaxWidthWrapper>
              </NoteMainWrapper>
            </Swipe>
          </Wrapper>
        )}
      </NoteLayerSwipeContainer>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  padding: 0;
  margin: 0 auto;
  min-height: 100%;
  height: 100%;

  ${media.smallPc`
    width: 100%;
  `}
`
export default NoteDonghoPage
