import NoteDashboardDonghoContainer from 'containers/notev2/NoteDashboardDonghoContainer'
import NoteDashboardBlock from './NoteDashboardBlock'
import NoteDashboardRpAptSelectList from './NoteDashboardRpAptSelectList'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import SummaryAptGraphContainer from 'containers/summary/SummaryAptGraphContainer'
import SummaryAptHeader from 'components/summary/SummaryApt/SummaryAptHeader'
import SummaryAptRPTableContainer from 'containers/summary/SummaryAptRPTableContainer'
import { Empty } from 'components/design'

const NoteDashboardDongho = () => {
  return (
    <NoteDashboardDonghoContainer>
      {({
        apts,
        onClickApt,
        selectedApt,
        selectedArea,
        setSelectedArea,
        areaTypes,
        chartData,
        loadingChart,
        isEmpthPurchaseData,
      }) => {
        return (
          <NoteDashboardBlock title="동호관리">
            {apts.length == 0 ? (
              <Empty>없음(임시</Empty>
            ) : (
              <>
                <NoteDashboardRpAptSelectList
                  apts={apts}
                  onClickApt={onClickApt}
                  selectedApt={selectedApt}
                />
                <ContentWrapper>
                  <Section border>
                    <SummaryAptHeader
                      hasTitle={false}
                      selectedArea={selectedArea}
                      setSelectedArea={setSelectedArea}
                      areaTypes={areaTypes}
                    />
                    {!loadingChart && !isEmpthPurchaseData && (
                      <SummaryAptGraphContainer chartData={chartData} />
                    )}
                  </Section>
                  <Divider />
                  <Section>
                    {!loadingChart && isEmpthPurchaseData && (
                      <Empty>매매/전세 데이터가 없습니다.</Empty>
                    )}
                    {!loadingChart && (
                      <SummaryAptRPTableContainer
                        data={chartData}
                        selectedArea={selectedArea}
                      />
                    )}
                  </Section>
                  {/* {loading && <Skeleton active />} */}
                </ContentWrapper>
              </>
            )}
          </NoteDashboardBlock>
        )
      }}
    </NoteDashboardDonghoContainer>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Section = styled.div`
  width: calc(50% - 20.5px);
  min-width: 360px;
  flex: 1;
  ${media.mediumS`
      width: 100%;
      min-width: 100%;
  `}

  & > div {
    &:first-child {
    }

    &:nth-child(2) {
      margin-right: -14px;

      ${media.mediumS`
          margin-right: 0;
      `}
    }
    ${media.mediumS`
        margin: 0 -20px;  
      `}
  }

  .rp-table-title {
    ${media.mediumS`
      align-items: flex-start;
      flex-direction: column;
      padding: 0;
      gap: 12px;
      margin-top: 12px;

      & > div {
        &:nth-child(2) {
          width: 100%;
          text-align: right;
        }
      }
    `}
  }
`

const Divider = styled.div`
  width: 1px;
  height: 356px;
  margin: 0 20px;
  background-color: ${theme.colors.gray[200]};
  ${media.mediumS`
    display: none;
  `}
`

export default NoteDashboardDongho
