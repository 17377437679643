import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 52px;
  line-height: 100%;
  font-weight: 800;
  line-height: 135%;
  word-break: keep-all;

  ${media.mediumM`
      line-height: 130%;
      font-size: 46px;            
  `}

  ${media.mediumS`
    font-size: 24px;
    `}
    
    ${({ center }) =>
    center == 'Y' &&
    `
        text-align: center;
    `}
`

export const SubTitle = styled.p`
  margin-top: 20px;
  font-size: 22px;
  font-weight: 600;
  color: ${theme.colors.gray[500]};
  line-height: 1.5;
  word-break: keep-all;

  ${({ center }) =>
    center == 'Y' &&
    `
        text-align: center;
    `}

  ${media.mediumS`
      font-size: 16px;
    `}
`

export const SectionWrapper = styled.section`
  margin: 300px 0;

  ${({ size }) => size == 'small' && `margin: 200px 0`};

  ${media.mediumS`
    margin: 200px 0;
    ${({ size }) => size == 'small' && `margin: 100px 0`};
  `}
`

export const WindowWrapper = styled.div`
  position: relative;
  width: 100%;
  box-shadow: ${theme.shadow['button-1']};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  ${media.mediumM`
      border-radius: 20px;
			overflow: hidden;
    `}

  &-img-wrapper {
    margin: 30px 40px;
  }

  &-phone {
    position: absolute;
    width: 280px;
    height: 530px;
    background-color: ${theme.colors.base.white};
    bottom: -35px;
    right: 0;
    border-radius: 16px;
    border: 5px solid ${theme.colors.gray[900]};
    overflow: hidden;

    video {
      position: absolute;
      transform: translateY(-29px);
    }
  }

  video,
  img {
    width: 100%;
  }

  video {
    height: auto;
  }
`

export const WindowBar = styled.div`
  position: relative;

  img {
    width: 100%;
  }
`
