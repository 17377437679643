import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNotes } from 'lib/api/note/noteApi'
import { setNotes } from 'modules/note'
import useAxios from 'lib/hooks/useAxios'

export default function useNotes() {
  // const params = useNoteMatch()

  const notes = useSelector((state) => state.note.notes)
  const noteSearchQuery = useSelector(
    (state) => state.note.noteSearchQuery,
  )
  const dispatch = useDispatch()

  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const [onRequest, loading, data, error, reset, _getNotesSource] =
    useAxios(getNotes)

  const _getNotes = useCallback(
    async (
      { channelSrl, folderSrl, addressSrl, query },
      cancelToken,
    ) => {
      const requestParams = {
        channel_srl: channelSrl,
        folder_srl: folderSrl,
        mode: 'card',
        _rx_csrf_token: loginInfo.csrf_token,
      }

      if (addressSrl) requestParams.address_srl = addressSrl
      if (query) requestParams.search = query

      // await clear() // 요청 전 상태 초기화
      await onRequest(requestParams, cancelToken)
    },
    [onRequest],
  )

  useEffect(() => {
    if (!data?.result) return
    dispatch(setNotes(data.result))
  }, [data])

  const clear = async () => {
    await reset()
    await dispatch(setNotes([]))
  }

  return {
    _getNotes,
    loading,
    notes,
    noteSearchQuery,
    error,
    reset,
    _getNotesSource,
  }
}
