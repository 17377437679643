import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

const NoteDonghoDongTitle = ({ selectedDanji, dong }) => {
  return (
    <Wrapper>
      <Dong>{dong}</Dong>
      {selectedDanji?.households?.[dong] && (
        <Household>
          {numberWithCommas(selectedDanji.households[dong])}세대
        </Household>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;

  ${media.mediumM`
    display: none;
  `}
`

const Dong = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
`

const Household = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`
export default NoteDonghoDongTitle
