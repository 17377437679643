import { useFormContext } from 'react-hook-form'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import Checkbox from '../../elements/Checkbox'
import { options } from '../../options'
import React from 'react'
import useSave from '../../useSave'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 시설정보 냉방시설
 */
const NoteAdColumnAirConditioningFacility = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onChangeCheckbox } = useSave()

  const aircon = watch('facilities.aircon') || []

  return (
    <ColumnSet>
      <ColumnTitle>냉방시설</ColumnTitle>
      <ColumnVal gap="20px 40">
        {options['냉방종류'].map((item, i) => {
          return (
            <React.Fragment key={item}>
              <Checkbox
                id={`facilities_aircon${i}`}
                color="blue"
                value={item}
                {...register('facilities.aircon')}
                onChange={(checked, e) => {
                  onChangeCheckbox({
                    originValue: aircon,
                    key: 'facilities.aircon',
                    value: e,
                  })
                }}
              >
                {item}
              </Checkbox>
            </React.Fragment>
          )
        })}
        <ValidateWarn>
          {errors?.facilities?.aircon?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnAirConditioningFacility
