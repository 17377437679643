import { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginVisible } from 'modules/auth'
import { message } from 'antd'
import { useCookies } from 'react-cookie'
import moment from 'moment'

const NoteDonghoBannerContainer = ({
  children,
  onClose = () => {},
}) => {
  const history = useHistory()
  const match = useRouteMatch({ path: '/favorute' })

  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const channels = useSelector((state) => state.channel.channels)
  const dispatch = useDispatch()
  const [cookies, setCookie, removeCookie] = useCookies([
    'isVisibleDonghoWelcomeModal',
  ])

  const onClick = useCallback(() => {
    // 로그인 상태가 아닐 때 로그인창 출력
    if (!loginInfo?.member_srl) {
      dispatch(setLoginVisible(true))
      onClose()
      return
    }

    if (loginInfo?.member_type == 'client') {
      message.info('부동산 전문가 회원만 접근 가능한 메뉴입니다.')
      return
    }
    const expireDate = new Date(moment().add(30, 'days'))
    setCookie('isVisibleDonghoWelcomeModal', 'N', {
      expires: expireDate,
    })
    history.push(`note/v2/dongho`)
  }, [channels, loginInfo])

  return children({ active: match, onClick })
}

export default NoteDonghoBannerContainer
