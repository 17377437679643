import styled from 'styled-components'
import HomeFavoriteItem from './HomeFavoriteItem'
import { Card } from 'components/design'
import { CardHeader } from 'components/design'
import { CardTitle } from 'components/design'

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const HomeFavorite = ({ isLoggedIn, recentFavorites }) => {
  if (!isLoggedIn) return null

  return (
    <Card round overflowHidden>
      <CardHeader>
        <CardTitle>
          {recentFavorites?.[0]?.address_srl
            ? '최근 추가한 매물 주소'
            : '최근 추가한 즐겨찾기'}
        </CardTitle>
      </CardHeader>

      <List>
        {recentFavorites?.map((item, i) => {
          return <HomeFavoriteItem item={item} key={i} />
        })}
      </List>
    </Card>
  )
}

export default HomeFavorite
