import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-search-params-polyfill'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import ReactDOM from 'react-dom/client'
// import './styles/base.scss'
import App from './App.jsx'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import store from 'modules'

import { HelmetProvider } from 'react-helmet-async'

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Provider>,
)

serviceWorker.unregister()
