import theme from 'lib/styles/theme'
import styled from 'styled-components'

const CheckButton = ({ checked, onChange }) => {
  return (
    <Button checked={checked} onClick={onChange}>
      전체
    </Button>
  )
}

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 30px;
  border-radius: 30px;
  background-color: ${theme.colors.gray[100]};
  color: ${theme.colors.gray[500]};
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  cursor: pointer;

  ${({ checked }) =>
    checked &&
    `
    border: none;
    color: ${theme.colors.base.white};
    background-color: ${theme.colors.primary[400]};
  `}

  &::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    padding-right: 4px;
    font-size: 12px;
    line-height: 100%;
  }
`

export default CheckButton
