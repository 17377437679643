import { ListItem } from 'components/design'
import { ListItemIcon } from 'components/design'
import { ListItemLabel } from 'components/design'
import styled from 'styled-components'
import ContractType from '../noteList/ContractType'
import PropertyType from '../noteList/PropertyType'

const AddressName = styled.span`
  line-height: 21px;
`

const CalendarNoteSelectItem = ({
  item,
  onClickNoteItem,
  outline,
  onRemove,
}) => {
  const onClickItem = () => {
    if (!onClickNoteItem) return
    onClickNoteItem(item)
  }

  return (
    <ListItem
      prepend={
        <PropertyType
          // thumbnail={item?.image?.thumbnail}
          propertyType={item?.property_type}
        />
      }
      onClick={onClickItem}
      hover
      outline={outline}
      append={
        onRemove && (
          <ListItemIcon onClick={onRemove}>
            <i className="far fa-minus-circle"></i>
          </ListItemIcon>
        )
      }
    >
      <ListItemLabel subtitle>
        <AddressName>
          {item?.building_name} {item?.dong && item?.dong}{' '}
          {item?.ho && item?.ho}
        </AddressName>
      </ListItemLabel>
      <ListItemLabel small>
        {item?.contract_type ? (
          <ContractType item={item} />
        ) : (
          '가격정보 없음'
        )}
      </ListItemLabel>
    </ListItem>
  )
}

export default CalendarNoteSelectItem
