import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import { Tag } from 'components/design'

import { getComputedArea } from 'utils'
import { ListItem } from 'components/design'
import MapContext from 'contexts/map'
import { useContext } from 'react'

const StyledListItem = styled.li`
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  & > a {
    display: flex;
    align-items: center;
    padding: 11px 1rem 0.875rem 1.25rem;

    color: ${theme.colors.gray[900]};
    font-size: 0.875rem;
    font-weight: 700;

    border: 1px solid ${theme.colors.gray[200]};
    border-radius: ${theme.borderRadius[2]};
    box-shadow: ${theme.shadow.card};

    transition: 0.2s ease;

    &:hover {
      transform: translateY(-4px);
      box-shadow: ${theme.shadow['card-2']};
    }
  }
`

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  font-weight: 700;
`
const ItemBody = styled.div`
  flex: 1;
`
const ItemIcon = styled.svg`
  flex: 0 0 auto;

  width: 24px;
  height: 24px;

  color: ${theme.colors.gray[500]};
`

const ItemAddress = styled.div`
  margin-right: 0.5rem;
`
const ItemBadge = styled.div`
  min-width: 18px;
  height: 18px;
  padding-left: 4px;
  padding-right: 4px;

  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 18px;

  background-color: ${theme.colors.tint.primary};
  border-radius: 100%;
`

const ItemSub = styled.div`
  display: flex;
  align-items: center;
`
const ItemTag = styled.div`
  margin-right: 0.5rem;
`

const ItemPrice = styled.div`
  font-size: 0.75rem;
  margin-right: 0.5rem;
`
const ItemDate = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 400;
`

const HomeAddressCard = ({
  to,
  address,
  noteCount,
  propertyType,
  totalFloorArea,
  roadName,
}) => {
  const history = useHistory()
  const { state } = useContext(MapContext)

  const getPropertyTypeName = (propertyType) => {
    if (!propertyType) return '연'
    if (propertyType.includes('상업업무용')) return '상업'
    return propertyType
  }

  const getPropertyTypeColor = (propertyType) => {
    switch (propertyType) {
      case '상업업무용':
        return 'pink'
      case '상가·사무실':
        return 'pink'
      case '토지':
        return 'orange'
      case '다가구주택':
        return 'blue'
      case '다세대주택':
        return 'blue'
      case '단독주택':
        return 'red'
      case '아파트':
        return 'cyan'
      case '오피스텔':
        return 'purple'
      case '공장':
        return 'black'
    }
  }

  const onClick = (to) => {
    history.push(to)
  }

  return (
    <ListItem onClick={() => onClick(to)} chevron>
      <ItemBody>
        <ItemTitle>
          <ItemAddress>{address}</ItemAddress>
          {noteCount > 0 && <ItemBadge>{noteCount}</ItemBadge>}
        </ItemTitle>

        <ItemSub>
          {propertyType && (
            <ItemTag>
              {totalFloorArea ? (
                <Tag
                  size="small"
                  color={getPropertyTypeColor(propertyType)}
                  label={getPropertyTypeName(propertyType)}
                  tooltip="연면적"
                >
                  {totalFloorArea &&
                    getComputedArea(
                      totalFloorArea,
                      state.isSquareMeter,
                    )}
                </Tag>
              ) : (
                <Tag
                  size="small"
                  color={getPropertyTypeColor(propertyType)}
                >
                  {getPropertyTypeName(propertyType)}
                </Tag>
              )}
            </ItemTag>
          )}

          {/* <ItemPrice>{price}</ItemPrice> */}
          <ItemDate>{roadName}</ItemDate>
        </ItemSub>
      </ItemBody>
    </ListItem>
  )
}

export default HomeAddressCard
