import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { AccountInput } from './NoteAccountForm'
import { useSelector } from 'react-redux'

/**
 * 계정관리 폼 - 사무실 전화번호
 */
const NoteAccountRealtorCompanyPhone = ({ canEdit, disabled }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { watch, register } = useFormContext()
  const realtor_position = watch('realtor_position')
  const { isBoss } = useIsAllInOne()

  return disabled ? (
    <>
      {loginInfo?.company_phone.length > 0 &&
      loginInfo?.company_phone.filter((item) => item).length > 0 ? (
        <>
          {loginInfo?.company_phone?.[0]}&ndash;
          {loginInfo?.company_phone?.[1]}&ndash;
          {loginInfo?.company_phone?.[2]}
        </>
      ) : (
        <>&ndash;</>
      )}
    </>
  ) : (
    <InputWrap>
      <AccountInput
        small="Y"
        defaultValue={loginInfo?.company_phone?.[0]}
        design={'box'}
        {...register('company_phone1')}
      />
      <AccountInput
        small="Y"
        defaultValue={loginInfo?.company_phone?.[1]}
        design={'box'}
        {...register('company_phone2')}
      />
      <AccountInput
        small="Y"
        defaultValue={loginInfo?.company_phone?.[2]}
        design={'box'}
        {...register('company_phone3')}
      />
    </InputWrap>
  )
}

const InputWrap = styled.div`
  display: flex;
  gap: 5px;
  max-width: 260px;

  & input {
    width: 82px !important;
  }
`

export default NoteAccountRealtorCompanyPhone
