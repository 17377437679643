import NoteDocsEditTab from './NoteDocsEditTab'
import NoteDocsContract from './Contract/NoteDocsContract'
import useQueryString from 'lib/hooks/useQueryString'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import NoteDocsConsent from './NoteDocsConsent'
import { FormProvider } from 'react-hook-form'
import NoteDocsEditContainer from 'containers/notev2/NoteDocsEditContainer'
import NoteDocsSaveContainer from 'containers/notev2/NoteDocsSaveContainer'
import Scrollbars from 'react-custom-scrollbars-2'
import NoteDocsDescription from './Description/NoteDocsDescription'
import { Button } from 'components/design'
import NoteDocsLayer from './Layer/NoteDocsLayer'
import { ContractWrapper, MainWrapper } from '../style/NoteStyle'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import NoteMainWrapper from '../style/NoteMainWrapper'

const NoteDocsEdit = () => {
  const { tab = 'default' } = useQueryString()

  return (
    <StyledMainWrapper single singleM column>
      <ContentWrapper>
        <NoteDocsEditTab tab={tab} />

        <NoteDocsEditContainer>
          {({
            methods,
            loading,
            isBuildingGongbuLoading,
            isPropertyContractBind,
            isLayerVisible,
            setIsLayerVisible,
            onClickPrint,
          }) => (
            <>
              <OpenButton
                color="blue"
                onClick={() => setIsLayerVisible(true)}
              >
                <i className="far fa-angle-double-left"></i>
              </OpenButton>
              <TabArea>
                {loading ? (
                  <FullScreenLoading />
                ) : (
                  <FormProvider {...methods}>
                    <NoteDocsSaveContainer>
                      {({
                        onUpdate,
                        onUpdateCheckbox,
                        onUpdateMultiple,
                      }) => (
                        <ScrollbarWrapper>
                          <Scrollbars autoHide>
                            <ContractWrapper>
                              <PrintButton
                                size="large"
                                color="blue"
                                onClick={() => onClickPrint(tab)}
                              >
                                인쇄하기
                              </PrintButton>
                              {tab === 'default' && (
                                <NoteDocsContract
                                  onUpdate={onUpdate}
                                  isBuildingGongbuLoading={
                                    isBuildingGongbuLoading
                                  }
                                  isPropertyContractBind={
                                    isPropertyContractBind
                                  }
                                />
                              )}
                              {tab === 'description' && (
                                <NoteDocsDescription
                                  onUpdateMultiple={onUpdateMultiple}
                                  onUpdate={onUpdate}
                                  onUpdateCheckbox={onUpdateCheckbox}
                                  isBuildingGongbuLoading={
                                    isBuildingGongbuLoading
                                  }
                                />
                              )}
                              {tab === 'consent' && (
                                <NoteDocsConsent onUpdate={onUpdate} />
                              )}
                            </ContractWrapper>
                          </Scrollbars>
                        </ScrollbarWrapper>
                      )}
                    </NoteDocsSaveContainer>
                    <NoteDocsLayer
                      visible={isLayerVisible}
                      onClose={() => setIsLayerVisible(false)}
                    />
                  </FormProvider>
                )}
              </TabArea>
            </>
          )}
        </NoteDocsEditContainer>
      </ContentWrapper>
    </StyledMainWrapper>
  )
}

const StyledMainWrapper = styled(NoteMainWrapper)`
  ${media.mediumS`
    width: 100%;
    padding-top: 0 !important;
  `}
`

const ScrollbarWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 40px;
  padding-right: 0;

  ${media.mediumS`
    padding: 0 0 0 20px;
  `}
`

const TabArea = styled.div`
  position: relative;
  z-index: 1;
  background-color: white;
  width: 100%;
  height: 100%;

  ${media.smallPc`
      margin-bottom: ${theme.base.bottomMenuHeight}px;
  `}
`

const OpenButton = styled(Button)`
  position: absolute;
  top: 18px;
  right: 50px;
  z-index: 2;
  box-shadow: 3px 5px 5px rgb(0 0 0 / 10%);

  ${media.mediumS`
    right: 20px;
    top: 60px;
  `}
`

const PrintButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`

export default NoteDocsEdit
