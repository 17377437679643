import { useContext, useEffect } from 'react'
import Axios from 'axios'
import MapContext from 'contexts/map'

import { numberWithCommas } from 'utils'
import useAxios from 'lib/hooks/useAxios'
import { getRppAvg } from 'lib/api/layerApi'
import { useDispatch, useSelector } from 'react-redux'
import { setMapClickDisabled } from 'modules/map'
import priceFormat from 'lib/utils/priceFormat'

const MapLayerRPPAvg = ({ dongNames, setDongNames }) => {
  const dispatch = useDispatch()
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { layerState, viewState, isSquareMeter, map } = state
  const [onRequest, , data, , ,] = useAxios(getRppAvg)

  const mapClickDisabled = useSelector(
    (state) => state.map.mapClickDisabled,
  )

  // 동이름 배열
  let tempDongNames = []

  useEffect(() => {
    if (Object.keys(viewState).length === 0) {
      return
    }

    if (viewState.level <= 4) {
      // 기존 오버레이 삭제
      dongNames.map((dongName) => {
        dongName?.setMap(null)
      })
      return
    }
    if (layerState.realPriceActive) {
      if (viewState.level > 11) {
        return
      }
      // 요청 파라미터 세팅

      const payload = {
        swlat: viewState.swLatlng.getLat(),
        swlng: viewState.swLatlng.getLng(),
        nelat: viewState.neLatlng.getLat(),
        nelng: viewState.neLatlng.getLng(),
        level: viewState.level,
      }

      onRequest(payload, null, false)
    } else {
      // 기존 오버레이 삭제
      dongNames.map((name) => {
        name?.setMap(null)
      })
    }
  }, [layerState, viewState, isSquareMeter, map])

  useEffect(() => {
    if (viewState.level <= 4) {
      if (mapClickDisabled) dispatch(setMapClickDisabled(false))
    } else {
      if (!mapClickDisabled) {
        dispatch(setMapClickDisabled(true))
      }
    }
  }, [viewState.level])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result || data.result?.length === 0) return

    const dongs = data.result

    dongs.map((dong) => {
      // 지역이름을 폴리곤 위에 표시합니다
      const dongNameOverlay = new kakao.maps.CustomOverlay({
        map: map, // 커스텀오버레이를 표시할 지도입니다
        content: `
          <div class="dongOverlay rpp">
            ${dong.dong_name}<br/>
            <span class="price">${
              isSquareMeter
                ? priceFormat(Math.round(dong.laup_mean) * 10000)
                : priceFormat(
                    Math.round(dong.laup_mean * 3.305) * 10000,
                  )
            }원</span>
            <br/>
          </div>
        `, // 커스텀오버레이에 표시할 내용입니다
        xAnchor: 0,
        yAnchor: 0,
        // 커스텀오버레이를 표시할 위치입니다. 위치는 다각형의 마지막 좌표로 설정합니다
        position: new kakao.maps.LatLng(
          dong.centerpoint?.coordinates?.[0],
          dong.centerpoint?.coordinates?.[1],
        ),
      })
      tempDongNames.push(dongNameOverlay)
    })

    setDongNames(tempDongNames)

    return () => {
      // 기존 오버레이 삭제
      dongNames.map((dongName) => {
        dongName?.setMap(null)
      })
    }
  }, [data])

  // 오버레이 세팅 및 초기화
  useEffect(() => {
    dongNames.map((dongName) => {
      dongName?.setMap(map)
    })

    return () => {
      // 기존 오버레이 삭제
      dongNames.map((dongName) => {
        dongName?.setMap(null)
      })
    }
  }, [dongNames])

  return null
}

export default MapLayerRPPAvg
