import theme from 'lib/styles/theme'
import styled from 'styled-components'
import LoadingImageSrc from 'assets/images/note/ad/processing.gif'
import ReactDOM from 'react-dom'
import { Button } from 'components/design'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NoteAdProcessing = () => {
  const modalRoot = document.getElementById('modal')
  const history = useHistory()

  const onClickOK = () => {
    history.replace('/note/v2/ad')
  }

  return ReactDOM.createPortal(
    <Wrapper>
      <Dimmer />
      <Content>
        <img src={LoadingImageSrc} />
        <TitleWrapper>
          <Title>
            처리 중 입니다.
            <br /> 잠시만 기다려 주세요
          </Title>
          <SubTitle>
            전송까지 <span>최대 5분</span>정도 소요될 수 있습니다.
          </SubTitle>
        </TitleWrapper>
        <Button color="blue" onClick={onClickOK}>
          확인
        </Button>
      </Content>
    </Wrapper>,
    modalRoot,
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 44px 52px 36px 52px;
  box-shadow: ${theme.shadow['button-1']};
  z-index: 99999999;
  background-color: ${theme.colors.base.white};

  img {
    width: 80px;
  }
`

const TitleWrapper = styled.div`
  text-align: center;
`

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
`

const SubTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  span {
    color: ${theme.colors.blue[600]};
  }
`

const Dimmer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.5);
`

export default NoteAdProcessing
