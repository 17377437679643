import { getInformations } from 'lib/api/notev2/ad'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 광고 메인 공지사항 목록
 */
const NoteAdNoticeListContainer = ({ children }) => {
  const [onRequest, , data] = useAxios(getInformations)
  const [listData, setListData] = useState([])
  const planInfo = useSelector((state) => state.auth.planInfo)

  useEffect(() => {
    onRequest({ plan_srl: planInfo.allinone_plan_srl })
  }, [])

  useEffect(() => {
    if (!data) return
    if (!data?.result) {
      setListData([])
      return
    }

    setListData(data.result)
  }, [data])

  return children({ listData })
}

export default NoteAdNoticeListContainer
