import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputRemoveAppendWidth,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 소유자 전화번호 ophone
 */
const NoteAdColumnOwnerTel = ({
  required = true,
  half = true,
  text,
  isDisabled,
}) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()

  return (
    <ColumnSet half={half}>
      <ColumnTitle required={required}>소유자 전화번호</ColumnTitle>
      <ColumnVal direction="column">
        <div>
          <InputText
            width={InputWidth}
            type="text"
            {...register('verification.ophone')}
            onBlur={(e) => {
              onUpdate('verification.ophone', e.target.value)
            }}
            disabled={isDisabled}
          />
        </div>
        <ValidateWarn>
          {errors?.verification?.ophone?.message}
        </ValidateWarn>
        <FormDesc>
          {text ? (
            <li>{text}</li>
          ) : (
            <>
              <li>숫자만 적어주세요.</li>
              <li>
                네이버 회원 정보에 등록된 소유자 전화번호를 입력해
                주세요.
              </li>
            </>
          )}
        </FormDesc>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnOwnerTel
