import { Modal } from 'components/design'
import NotePaymentInvoiceLayout from 'containers/notev2/NotePaymentInvoiceLayout'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 올인원 멤버 삭제 모달
 */
const NotePaymentAllInOneMemberDelete = ({
  visible,
  onCancel,
  onOk,
  invoice,
  totalPrice,
  card,
}) => {
  if (!visible) return null

  return (
    <Modal
      visible={visible}
      hiddenHeader
      onOk={onOk}
      onCancel={onCancel}
      okText="멤버 삭제"
      okButtonProps={{ color: 'danger' }}
      expand
    >
      <Header>정말로 멤버를 삭제하실건가요?</Header>
      <Desc>
        멤버를 삭제하시면, 영업일 기준 2일 이내 결제하신 카드로
        환불됩니다.
      </Desc>
      <NotePaymentInvoiceLayout
        totalPrice={totalPrice}
        data={invoice}
        visiblePeriod
        isRefund
        card={card}
      />
    </Modal>
  )
}

const Header = styled.div`
  margin-top: 24px;
  color: ${theme.colors.gray[750]};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`

const Desc = styled.div`
  margin-bottom: 20px;
  color: ${theme.colors.gray[600]};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`

export default NotePaymentAllInOneMemberDelete
