import { Confirm } from 'components/design'
import { removeCalendar } from 'lib/api/note/calendarApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import useGetCalendars from './useGetCalendars'

export default function useCalendarRemove() {
  const [onRequest, loading, data] = useAxios(removeCalendar)
  const { _getCalendars } = useGetCalendars()

  const handleRemoveCalendar = ({ channelSrl, calendarSrl }) => {
    Confirm.error({
      title: '정말로 삭제하시겠습니까?',
      okText: '삭제',
      cancelText: '취소',
      onOk: () => onRequest({ channelSrl, calendarSrl }),
    })
  }

  useEffect(() => {
    if (data?.message !== 'success') return

    _getCalendars()
  }, [data])

  return { handleRemoveCalendar }
}
