import { useContext, useState, useEffect } from 'react'
import { getComputedArea } from 'utils'
import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { DotIcon } from 'components/design'
import { ReactComponent as FloorArea } from 'assets/icon/floor-area.svg'
import { ReactComponent as GroupSquare } from 'assets/icon/group-square.svg'
import styled from 'styled-components'

const SummaryHcCharacterTabType = ({ data }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const purposeArr = [
    '단독주택',
    '다가구주택',
    '다세대주택',
    '상가·사무실',
    '연립주택',
    '아파트',
    '오피스텔',
    '공장',
    '종교시설',
    '창고',
    '기타',
  ]

  const [chartData, setChartData] = useState([])
  const [allPercentData, setAllPercentData] = useState([])

  const totalValue = data?.building_purpose
    ? Object.values(data?.building_purpose)
        .map((item) => item)
        .reduce((partialSum, a) => partialSum + a, 0)
    : 0

  useEffect(() => {
    if (
      !data?.building_purpose ||
      Object.keys(data?.building_purpose).length === 0
    )
      return

    const result = replaceData({ ...data.building_purpose })

    setChartData(result)

    setAllPercentData(
      Object.entries(result).map((item) => {
        return getPercent(item[1], totalValue)
      }),
    )
  }, [data])

  const replaceData = (obj) => {
    const copyObj = Object.assign({}, obj)

    Object.keys(copyObj).forEach((purpose) => {
      if (!purposeArr.includes(purpose)) {
        replaceObjKey(copyObj, purpose, '기타', copyObj[purpose])
      }
    })

    const result = Object.fromEntries(
      Object.entries(copyObj).sort(([, a], [, b]) => b - a),
    )

    return result
  }

  const replaceObjKey = (obj, oldKey, newKey, value) => {
    obj[newKey] = (obj[newKey] ? obj[newKey] : 0) + value
    delete obj[oldKey]

    return obj
  }

  const getPercent = (value, totalValue) => {
    if (!value) return 0

    const percent = (value / totalValue) * 100
    return percent.toFixed(1)
  }

  const getColors = (type) => {
    const colors = {
      단독주택: theme.colors.red[400],
      다가구주택: theme.colors.purple[600],
      다세대주택: theme.colors.primary[500],
      '상가·사무실': theme.colors.sub.blue,
      연립주택: theme.colors.gray[800],
      아파트: theme.colors.sub.orange,
      오피스텔: theme.colors.blue[300],
      공장: theme.colors.gray[700],
      종교시설: theme.colors.sub.pink,
      창고: theme.colors.purple[100],
      기타: theme.colors.gray[400],
    }

    return colors ? colors[type] : colors['기타']
  }

  const getPercentWidth = (value) => {
    if (!value) return 0

    const smallerCount = allPercentData
      .map(Number)
      .filter((arrayValue) => arrayValue < 20).length

    const minusCount = smallerCount * 4

    const result =
      Number(value) < 20
        ? Number(value) + 4
        : Number(value) - minusCount
    return result
  }

  return (
    <>
      <Wrapper>
        <DotIcon
          title="평균 대지면적"
          icon={<i className="fal fa-square"></i>}
          content={getComputedArea(data?.land_area_mean, isSquareMeter)}
        />
        <DotIcon
          title="평균 건축면적"
          icon={<i className="fal fa-expand"></i>}
          content={getComputedArea(
            data?.building_area_mean,
            isSquareMeter,
          )}
        />
        <DotIcon
          title="평균 연면적"
          icon={<i className="fal fa-bring-forward"></i>}
          content={getComputedArea(
            data?.total_floor_area_mean,
            isSquareMeter,
          )}
        />
        <DotIcon
          title="평균 층수"
          icon={<i className="fal fa-layer-group"></i>}
          content={`${data?.floor_count_mean}층`}
        />
        <DotIcon
          dot={false}
          title="평균 건폐율"
          icon={<GroupSquare />}
          content={`${
            data?.coverage_ratio_mean
              ? `${Math.floor(Number(data?.coverage_ratio_mean))}%`
              : '-'
          }`}
        />
        <DotIcon
          title="평균 용적률"
          icon={<FloorArea />}
          content={`${
            data?.floor_area_ratio_mean
              ? `${Math.floor(Number(data?.floor_area_ratio_mean))}%`
              : '-'
          }`}
        />
      </Wrapper>
      {chartData && Object.keys(chartData).length > 0 && (
        <BarWrapper>
          <PercentBar>
            {Object.entries(chartData).map((item, idx, ori) => {
              return (
                <PercentItem
                  key={idx}
                  color={getColors(item[0])}
                  width={getPercentWidth(
                    getPercent(item[1], totalValue),
                  )}
                >
                  {getPercent(item[1], totalValue) > 6 &&
                    `${getPercent(item[1], totalValue)}%`}
                </PercentItem>
              )
            })}
          </PercentBar>
          <Legend>
            {Object.entries(chartData).map((item, idx) => {
              return (
                <LegendWrapper key={idx}>
                  <Color color={getColors(item[0])}></Color>
                  <Title>{item[0]}</Title>
                </LegendWrapper>
              )
            })}
          </Legend>
        </BarWrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  & > li {
    display: flex;
    flex-basis: 33%;
    margin-bottom: 0.75rem;
  }
`

const PercentBar = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;

  & > div:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  & > div:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
`

const PercentItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width && width}%;
  margin-right: 2px;
  background-color: ${({ color }) => color && color};
  color: ${theme.colors.base.white};
  font-size: 0.75rem;
`

const BarWrapper = styled.section`
  margin-top: 2rem;
  width: 100%;
`

const Legend = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  flex-wrap: wrap;
`

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Color = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ color }) => color && color};
  margin-right: 7px;
  border-radius: 4px;
`

const Title = styled.span`
  margin-right: 1rem;
  font-size: 0.813rem;
`

export default SummaryHcCharacterTabType
