import { useCallback } from 'react'
import NoteApplyFolders from './NoteApplyFolders'
import NoteApplyChannels from './NoteApplyChannels'
import { FormItemRow } from 'components/design'
import { FormItemCol } from 'components/design'

const NoteApplyForm = ({
  channelSrl,
  folderSrl,
  setChannelSrl,
  setFolderSrl,
}) => {
  const onChangeChannel = useCallback((value) => {
    setChannelSrl(value)
    setFolderSrl('')
  })

  const onChangeFolder = useCallback((value) => {
    setFolderSrl(value)
  })

  return (
    <FormItemRow>
      <FormItemCol
        label="채널을 선택해주세요."
        labelProps={{ small: true }}
        noContainer
      >
        <NoteApplyChannels
          channelSrl={channelSrl}
          onChange={onChangeChannel}
        />
      </FormItemCol>

      {channelSrl && (
        <FormItemCol
          label="폴더를 선택해주세요."
          labelProps={{ small: true }}
          noContainer
          noMargin
        >
          <NoteApplyFolders
            channelSrl={channelSrl}
            folderSrl={folderSrl}
            onChange={onChangeFolder}
          />
        </FormItemCol>
      )}
    </FormItemRow>
  )
}

export default NoteApplyForm
