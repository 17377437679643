import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

const NoteGridDescription = () => {
  const noteListInfo = useSelector((state) => state.notev2.noteListInfo)

  return (
    <Explain onClick={(e) => e.stopPropagation()}>
      <>매물 {numberWithCommas(noteListInfo.count || 0)}개</>
    </Explain>
  )
}

const Explain = styled.div`
  color: ${theme.colors.gray[400]};
  font-size: 14px;
  font-weight: 400;
`

export default NoteGridDescription
