import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'

const MapHelpMenu = ({ visible, onClose }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const thisYear = moment().format('YYYY')

  const ref = useRef()
  useOnClickOutside(ref, (e) => {
    onClose()
  })

  return (
    visible && (
      <Wrapper ref={ref}>
        {loginInfo?.member_type === 'realestate' && (
          <MenuItem href="https://pf.kakao.com/_XTUzb" target="_blank">
            <i className="fas fa-comment"></i>
            <span>카카오톡 문의하기</span>
          </MenuItem>
        )}
        <MenuItem
          href="https://forms.gle/mp6oU3PDaJqDfM2N7"
          target="_blank"
        >
          <i className="fas fa-comment-alt-exclamation"></i>
          <span>불편신고</span>
        </MenuItem>
        <MenuItem href="//jootek.com/about_map">
          <i className="fas fa-book-reader"></i>
          <span>JOOTEK 지도</span>
        </MenuItem>
        <MenuItem href="//jootek.com/about">
          <i className="fas fa-info-circle"></i>
          <span>JOOTEK 소개</span>
        </MenuItem>
        <MenuItem href="https://map.jootek.com">
          <i className="fas fa-question-circle"></i>
          <span>매물노트 소개</span>
        </MenuItem>
        <MenuItem href="https://jootek.com/guide/category/9506">
          <i className="fas fa-file-signature"></i>
          <span>매물노트 가이드</span>
        </MenuItem>

        <Copyright>© {thisYear} JOOTEK INC.</Copyright>
      </Wrapper>
    )
  )
}

const MenuItem = styled.a`
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;

  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
  font-weight: 500;

  transition: 0.2s ease;

  & > i {
    color: ${theme.colors.gray[400]};
    margin-right: 0.625rem;
    transition: 0.2s ease;
  }

  &:hover {
    color: ${theme.colors.gray[700]};
    background-color: ${theme.colors.gray[50]};

    & > i {
      color: ${theme.colors.gray[500]};
    }
  }
`

const Copyright = styled.div`
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-bottom: 0.25rem;
  color: ${theme.colors.gray[400]};
  font-size: 0.75rem;
`

const Wrapper = styled.div`
  position: fixed;
  left: 5rem;
  bottom: 20px;
  z-index: 20;

  ${media.mediumS`
    right: 1rem;
    bottom: calc(${theme.base.bottomMenuHeight}px + 42px + 1.5rem);
  `}

  width: 176px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: ${theme.colors.base.white};
  border-radius: ${theme.borderRadius[3]};
  box-shadow: ${theme.shadow['card-2']};
`

export default MapHelpMenu
