import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useFormContext } from 'react-hook-form'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 의뢰인명
 */
const NoteAdColumnClientName = ({ isDisabled }) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  return (
    <ColumnSet>
      <ColumnTitle>의뢰인명</ColumnTitle>
      <ColumnVal>
        <Wrapper>
          <div>
            <InputText
              width={InputRemoveAppendWidth}
              type="text"
              {...register('verification.name')}
              onBlur={(e) => {
                onUpdate('verification.name', e.target.value)
              }}
              disabled={isDisabled}
            />
          </div>
          <FormDesc multiple>
            <li>띄어쓰기 없이 작성해 주세요.</li>
            <li>
              소유주가 사람이 아닌 경우 (법원/종가 등) 의뢰인명에는 사람
              이름을 소유자관계는 대표 직원 종원 등으로 입력해야 합니다.
            </li>
          </FormDesc>
        </Wrapper>

        <ValidateWarn>
          {errors?.verification?.name?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default NoteAdColumnClientName
