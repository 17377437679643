import MapContext from 'contexts/map'
import { useContext } from 'react'
import useMapCalcFormula from './useMapCalcFormula'
import { message } from 'antd'
import { useSelector } from 'react-redux'

/**
 * 학군, 교통 클릭 이벤트
 * @returns
 */
export default function useSummarySchoolAmemities() {
  const { kakao } = window
  const {
    state: { map },
  } = useContext(MapContext)

  const { drawMarker, getVisibleBounds, getZoomLevelPolyAndOverlay } =
    useMapCalcFormula()

  const polygonCenterPoint = useSelector(
    (state) => state.summary.centerPoint,
  )

  const onClick = ({
    prevMarker,
    setPrevMarker,
    targetCenterPoint,
    iconSrc,
    activeValue,
    name,
    resetActiveValue,
  }) => {
    if (!polygonCenterPoint) return

    if (activeValue == name) {
      //같은 것 클릭 했을 때 마커 지우고 centerpoint 폴리곤으로 reset
      resetInitial(prevMarker, resetActiveValue)
      return
    }

    //이전 마커 제거
    if (prevMarker) {
      prevMarker.setMap(null)
    }

    if (!targetCenterPoint) {
      message.info('위치를 찾을 수 없습니다.')
      return
    }

    const polyCenter = new kakao.maps.LatLng(
      polygonCenterPoint.coordinates[0],
      polygonCenterPoint.coordinates[1],
    ) //폴리곤의 center를 카카오 지도 lat, lng 타입으로
    const coord = targetCenterPoint.coordinates

    const itemCenter = new kakao.maps.LatLng(coord[0], coord[1]) // 마커 그릴 center

    const visibleBounds = getVisibleBounds() //옆에 summary 등 가려진 부분을 빼고 보이는 지도 부분을 계산

    const hasPos = visibleBounds.contain(itemCenter) //마커의 centerpoint가 현재 맵 크기에 있는지 확인

    const zoom = getZoomLevelPolyAndOverlay(
      polygonCenterPoint.coordinates,
      targetCenterPoint.coordinates,
    ) // 폴리곤과 마커 사이의 거리를 계산하여 적당한 zoom을 계산한다

    const x = (polyCenter.getLat() + coord[0]) / 2
    const y = (polyCenter.getLng() + coord[1]) / 2 //두 centerpoint의 중간 지점 쯤

    if (hasPos == false) {
      //지도 내 마커 없음
      drawMarker({
        itemCenter,
        setPrevState: setPrevMarker,
        iconSrc,
      })

      map.setCenter(new kakao.maps.LatLng(x, y))
      map.setLevel(zoom)
    } else {
      drawMarker({
        itemCenter,
        setPrevState: setPrevMarker,
        iconSrc,
      })

      const mapLevel = map.getLevel()
      if (zoom < mapLevel) {
        map.setCenter(new kakao.maps.LatLng(x, y))
        map.setLevel(zoom)
      } else if (zoom >= mapLevel) {
        map.setCenter(new kakao.maps.LatLng(x, y))
      }
    }
  }

  /**
   * 클릭 상태 초기화
   * @param {*} prevMarker  클릭했던 마커
   * @param {*} resetActiveValue active reset 함수
   */
  const resetInitial = (prevMarker, resetActiveValue) => {
    const polyCenter = new kakao.maps.LatLng(
      polygonCenterPoint.coordinates[0],
      polygonCenterPoint.coordinates[1],
    )
    map.setCenter(polyCenter) //폴리곤 center로 이동
    map.setLevel(1)
    prevMarker.setMap(null) //마커 삭제
    resetActiveValue && resetActiveValue() //active 해제
  }

  return { onClick }
}
