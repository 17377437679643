import { Modal } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDonghoHoDetailHeader from './NoteDonghoHoDetailHeader'
import NoteDonghoHoDetailMemo from './NoteDonghoHoDetailMemo'
import SummaryRpDetailTable from 'components/summary/SummaryRpDetail/SummaryRpDetailTable'
import { ReactComponent as EmptyIcon } from 'assets/icon/note/ad/nodata.svg'
import NoteDonghoHoNotes from './NoteDonghoHoNotes'
import NoteDonghoHoDetailButtons from './NoteDonghoHoDetailButtons'
import { useSelector } from 'react-redux'
import NoteDonghoNoSupport from '../NoteDonghoNoSupport'
import NoteDonghoDetailInfo from './NoteDonghoDetailInfo'
import useIsMobile from 'lib/hooks/useIsMobile'

/**
 * 동호관리
 * 호실정보 모달
 * @param data 호 정보 api에서 받은 데이터
 */
const NoteDonghoHoDetailModal = ({ visible, onCancel, data }) => {
  const isMobile = useIsMobile()
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )

  return (
    <Modal
      hiddenHeader
      visible={visible}
      onCancel={onCancel}
      footer={null}
      fullheight={isMobile}
    >
      {visible && (
        <>
          <NoteDonghoHoDetailHeader data={data} onClose={onCancel} />
          <NoteDonghoDetailInfo data={data} />
          <TopWrapper>
            {data?.notes?.length > 0 ? (
              // 매물이 있는 경우
              <NoteDonghoHoNotes
                addressId={data.address_id}
                data={data.notes}
                ads={data.ads}
              />
            ) : data?.ads?.length > 0 ? (
              // 광고만 있는 경우
              <NoteDonghoHoNotes
                addressId={data.address_id}
                data={data.ads}
                type="ad"
              />
            ) : null}
            {data?.notes?.length == 0 && (
              <>
                {/* 매물이 없는 경우 메모와 버튼 */}
                <NoteDonghoHoDetailMemo
                  id={data?.id}
                  type="insert"
                  dongName={data?.dong_name}
                  hoName={data?.ho_name}
                  memo={data?.memo}
                />
                <NoteDonghoHoDetailButtons data={data} ads={data.ads} />
              </>
            )}
          </TopWrapper>

          <BottomWrapper>
            <BottomTitle>실거래가 이력</BottomTitle>
            {selectedDanji?.is_paid == 'Y' ? (
              <>
                {/* 지도 실거래 상세랑 같은거임 */}
                {data?.real_price.length > 0 ? (
                  <SummaryRpDetailTable
                    noContainer
                    data={data.real_price}
                  />
                ) : (
                  <Empty icon={<EmptyIcon />}>
                    실거래 정보가 없습니다.
                  </Empty>
                )}
              </>
            ) : (
              <NoteDonghoNoSupport />
            )}
          </BottomWrapper>
        </>
      )}
    </Modal>
  )
}

const TopWrapper = styled.div`
  position: relative;
  margin: 0 -20px;
  border-bottom: 8px solid ${theme.colors.gray[200]};
`

const BottomWrapper = styled.div`
  margin-top: 20px;

  ${media.mediumS`
    margin: 20px -7px 0 -7px;
    width: calc(100% + 14px);
  `}
`

const BottomTitle = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
  color: ${theme.colors.gray[800]};
`

const Date = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 600;

  span {
    color: ${theme.colors.gray[400]};
    font-size: 12px;
    font-weight: 500;
  }
`

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  margin-top: -4px;
  color: #cad0d7;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
`

export default NoteDonghoHoDetailModal
