import { Dropdown, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { Confirm, Modal } from 'components/design'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as EllipsisIcon } from 'assets/icon/ellipsis.svg'
import theme from 'lib/styles/theme'
import { deleteContract } from 'lib/api/notev2/contractApi'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NoteDocsLayerControl = ({ onClose }) => {
  const [
    onRequestDeleteContract,
    ,
    dataDeleteContract,
    errorDelete,
    onReset,
  ] = useAxios(deleteContract, { noErrorMessage: true })

  const match = useRouteMatch(
    '/note/v2/docs/:channelSrl/:addressSrl?/edit/:contractSrl?',
  )
  const { watch, getValues } = useFormContext()

  const channelSrl = watch('channel_srl')
  const contractSrl = getValues('contract_srl')

  const history = useHistory()

  /**
   * 삭제 시 에러메시지 표시
   */
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState('')

  const onClickMenu = (e) => {
    switch (e.key) {
      case '1':
        // url에도 없고 form context에도 없는 경우
        if (
          (!match?.params?.channelSrl && !channelSrl) ||
          (!match?.params?.contractSrl && !contractSrl)
        ) {
          message.error('잘못된 접근입니다')
          return
        }

        Confirm.info({
          title: '정말로 계약서를 삭제하시겠습니까?',
          content:
            '계약서를 삭제하면 다시는 되돌릴 수 없으며, 이 계약서와 연결된 확인설명서도 모두 삭제됩니다. 이에 대한 모든 책임은 채널 주인 본인에게 있습니다.',
          onOk: () =>
            onRequestDeleteContract({
              channel_srl: channelSrl || match?.params?.channelSrl,
              contract_srl: contractSrl || match?.params?.channelSrl,
            }),
          okButtonProps: { color: 'danger' },
          okText: '삭제',
        })
        break
    }
  }

  useEffect(() => {
    if (!dataDeleteContract) return

    if (dataDeleteContract.error == 0) {
      message.success('계약서가 삭제되었습니다')

      //삭제 후 목록으로 redirect
      history.replace(`/note/v2/docs/${channelSrl}`)
    }
  }, [dataDeleteContract])

  /**
   * 매물 삭제 시 에러메시지 표시
   */
  useEffect(() => {
    if (!errorDelete) return

    setIsModalVisible(true)
    setDeleteMessage(errorDelete)
    onReset()
  }, [errorDelete])

  const onOk = () => {
    setIsModalVisible(false)
    setDeleteMessage('')
  }

  const items = [
    {
      label: '삭제',
      icon: <DeleteOutlined />,
      key: '1',
      danger: true,
    },
  ]

  const menuProps = {
    items,
    onClick: onClickMenu,
  }

  return (
    <Wrapper>
      <Dropdown menu={menuProps} trigger={['click']}>
        <EllipsisIcon onClick={(e) => e.preventDefault()} />
      </Dropdown>
      <Modal
        title="알림"
        visible={isModalVisible}
        onOk={onOk}
        onCancel={() => setIsModalVisible(false)}
        okText="확인"
        cancelText="취소"
        okButtonProps={{ color: 'blue' }}
      >
        <span>{deleteMessage}</span>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  z-index: 1;
  position: absolute;
  top: 16px;
  right: 20px;

  svg {
    height: 100%;
    margin: 0 5px;
  }

  &:hover {
    background-color: ${theme.colors.gray[300]};
    border-radius: 6px;
  }
`

export default NoteDocsLayerControl
