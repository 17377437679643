import { NavLink } from 'react-router-dom'
import TopHeaderItem from './TopHeaderItem'

const TopHeaderSearch = () => {
  return (
    <TopHeaderItem
      isLink={true}
      to="/search"
      as={NavLink}
      icon={<i className="far fa-search search-icon" />}
    />
  )
}

export default TopHeaderSearch
