import { Dropdown, message } from 'antd'
import ChannelSelect from 'components/jmapnotev2/channel/ChannelSelect'
import useChannelInfo from 'containers/notev2/hooks/useChannelInfo'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useNoteMarker } from 'lib/hooks/useNoteMarker'
import theme, { media } from 'lib/styles/theme'
import {
  initPropertyParams,
  setNoteListSearchParams,
} from 'modules/notev2'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

const items = [
  {
    key: '아파트',
    label: '아파트',
  },
  {
    key: '오피스텔',
    label: '오피스텔',
  },
  {
    key: '분양권',
    label: '분양권',
  },
  {
    key: '빌라/다가구',
    label: '빌라/다가구',
  },
  {
    key: '상가/사무실',
    label: '상가/사무실',
  },
  {
    key: '단독',
    label: '단독',
  },
  {
    key: '공장',
    label: '공장',
  },
  {
    key: '창고',
    label: '창고',
  },
  {
    key: '토지',
    label: '토지',
  },
  {
    key: '기타',
    label: '기타',
  },
]

const NoteListFilterNav = ({
  width,
  visible,
  searchFnWithParam,
  buttonWidth,
}) => {
  const channels = useSelector((state) => state.channel.channels)
  const noteMarkers = useSelector((state) => state.note.noteMarkers)
  const noteMarkersCount = useSelector(
    (state) => state.note.noteMarkersCount,
  )
  const { property_types } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const propertyFilters = useSelector(
    (state) => state.notev2.notePropertyFilter,
  )

  const { searchNoteMarkers } = useNoteMarker()
  const [selectedNoteFilter, setSelectedNoteFilter] = useState('')
  const ref = useRef(null)
  const [isVisible, setIsVisible] = useState(visible)
  const { _getChannel, channelResult } = useChannelInfo()

  const history = useHistory()

  useEffect(() => {
    setIsVisible(visible)
  }, [visible])

  useEffect(() => {
    if (!ref.current) return

    if (isMobile) {
      // setIsVisible(true)
    } else {
      if (!visible) return
      const wrapperWidth = window
        .getComputedStyle(ref.current)
        .getPropertyValue('width')
        .replace('px', '')

      if (wrapperWidth < 190) {
        if (isVisible) setIsVisible(false)
      } else {
        if (!isVisible) setIsVisible(true)
      }
    }
  }, [width, ref.current])

  useEffect(() => {
    if (!property_types || Object.keys(property_types).length == 0)
      return
    //매물리스트 필터 추가
    const filterEntries = Object.entries(property_types)
    const selectedFilterEntry =
      filterEntries.find((item) => item[1].length > 0) || []

    if (selectedFilterEntry && selectedFilterEntry.length > 0) {
      setSelectedNoteFilter(selectedFilterEntry[0])
    } else {
      setSelectedNoteFilter('')
    }
  }, [property_types])

  /**
   * 메뉴 클릭 동작
   */
  const onClick = (e) => {
    const label = e.key

    const target = propertyFilters[label]

    //대분류에 해당하는 데이터 없을 때
    if (!propertyFilters[label]) {
      dispatch(
        setNoteListSearchParams({
          property_types: Object.assign({}, initPropertyParams(), {
            [label]: [['empty']],
          }),
        }),
      )
      return
    }

    if (propertyFilters[label]) {
      const firstKey = Object.keys(target)[0]

      if (!propertyFilters[label]?.[firstKey]) return

      const selectAllParam = {
        [label]: [propertyFilters[label][firstKey]?.code],
      }
      const newData = Object.assign(
        {},
        initPropertyParams(),
        selectAllParam,
      )

      dispatch(
        setNoteListSearchParams({
          property_types: newData,
        }),
      )

      searchFnWithParam({
        propertyFilter: newData,
      })

      setTimeout(() => {
        searchNoteMarkers(label)
      })
    }
  }

  const onChangeChannel = (channelSrl, folderSrl) => {
    const targetChannel = channels.find(
      ({ channel_srl: channels_srl }) => {
        return channels_srl == channelSrl
      },
    )
    if (!targetChannel) {
      message.error('잘못된 요청입니다.')
      return
    }

    _getChannel({ channelSrl })

    localStorage.setItem('recentFolderSrl', folderSrl) //카드모드 호환 위해 채널 바꿀 때 기본 폴더로 설정
  }

  useEffect(() => {
    if (!channelResult) {
      return
    }

    if (!channelResult?.channel_srl) {
      message.error('잘못된 요청입니다.')
      return
    }

    localStorage.setItem('recentChannelSrl', channelResult.channel_srl)
    history.push(`/note/v2/notes/${channelResult.channel_srl}`)
  }, [channelResult])
  return (
    <BottomNavWrapper
      buttonWidth={buttonWidth}
      visible={isVisible ? 'Y' : 'N'}
      ref={ref}
    >
      <NavWrapper>
        <Nav>
          <Filters>
            <ChannelSelect
              onChange={onChangeChannel}
              topPos
              hideCreate
              fixed={false}
            />
            {selectedNoteFilter && (
              <>
                <RightAngle className="far fa-angle-right"></RightAngle>
                <StyledDropdown
                  trigger={['click']}
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: ['3'],
                    onClick: onClick,
                  }}
                >
                  <DropdownLabel>{selectedNoteFilter}</DropdownLabel>
                </StyledDropdown>
              </>
            )}
          </Filters>
          {noteMarkers && (
            <Count>지도 내 매물 {noteMarkersCount}</Count>
          )}
        </Nav>
      </NavWrapper>
    </BottomNavWrapper>
  )
}

const BottomNavWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 13;
  opacity: 0;
  position: fixed;
  ${({ visible }) => visible == 'Y' && `opacity: 1;`};
  width: ${({ buttonWidth }) =>
    buttonWidth && `calc(100% - ${buttonWidth} )`};
  left: ${({ buttonWidth }) => buttonWidth && `${buttonWidth}`};
  bottom: 32px;

  ${media.mediumS`
    width: 100%;
    left: 0;
  `}
`

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Nav = styled.div`
  display: flex;
  background-color: ${theme.colors.base.white};
  border-radius: 20px;
  overflow: visible;
  line-height: 100%;
  font-weight: 600;
  span {
    font-size: 13px !important;
  }
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  line-height: 100%;

  .channel-select-label {
    display: flex;
    align-items: center;
    padding: 9px 9px 9px 18px;
    font-size: 11px;
    svg {
      display: none;
    }

    &::after {
      content: '\f107';
      margin-left: 8px;
      font-family: 'Font Awesome 5 Pro';
      color: ${theme.colors.gray[600]};
      font-weight: 400;
      line-height: 100%;
      font-size: 13px;
    }
  }
`

const RightAngle = styled.div`
  color: ${theme.colors.gray[400]};
`

const DropdownLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 9px 10px 9px;
  font-size: 11px;
  &::after {
    content: '\f107';
    margin-right: 8px;
    margin-left: 8px;
    font-family: 'Font Awesome 5 Pro';
    color: ${theme.colors.gray[600]};
    font-weight: 400;
    line-height: 100%;
    font-size: 13px;
  }
`

const Count = styled.div`
  background-color: ${theme.colors.gray[800]};
  color: ${theme.colors.base.white};
  padding: 11px 13px;
  font-size: 11px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`

const StyledDropdown = styled(Dropdown)`
  cursor: pointer;
  .ant-dropdown-menu-title-content {
    font-size: 12px !important;
  }
`

export default NoteListFilterNav
