export const normalFloorPatterns = [
  /^[0-9]+층$/,
  /^지하+[0-9]+층$/,
  /^지하+[0-9]{1,2}$/,
  /^B+[0-9]+층$/,
  /^B+[0-9]$/,
]

export const normalHoPatterns = {
  simpleHo: /^[0-9+\s]+호$/, //101호, 101 호
  stringAndHoAndString: /^[ㄱ-ㅣ가-힣+\s]+[0-9{1,4}]+호$/, //아파트102호, 제201호, 아파트 102호
  stringAndHo: /^[ㄱ-ㅣ가-힣+\s]+[0-9]{1,4}$/, // 아파트101, 메이플102, 아파트 101
  undergroundHo: /^[B|비|지하|지층]+[0-9]+호*$/, //B202(이건 층까지 비교 필요), 비101, 지하1 >>> +호
  undergroundHoOnlyString: ['지층', '지층호'], //층수를 구별할 수 있을 때 사용할 수 있다
  withHo: /^[0-9]{1,2}[층\s].[0-9]{1,4}호*$/, //호에 층이 붙어있는 경우
  withBar: /^[ㄱ-ㅣ가-힣a-zA-Z0-9]+[-]+[ㄱ-ㅣ가-힣a-zA-Z0-9]+호*$/, //103-1201호 등
  withBarSimple: /^[0-9]{1,4}[-]+[0-9]{1}호*$/, //102-1호
  withFloorAndManyLetter: /^[0-9]{1,2}층+[제]+[0-9{1,4}]+호*$/, //1층제123호
}

/**
 * 제외할 호 포맷
 */
export const excludeHoPatters = {
  onlyString: /^[ㄱ-ㅣ가-힣a-zA-Z]+$/,
  frontAddedEnglish: /^[a-zA-Z]+[0-9]+호*$/,
  withBarSimple: /^[0-9]{1,4}[-]+[0-9]{1}호*$/, //102-1호
}
