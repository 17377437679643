import { useEffect, useState } from 'react'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 계정관리
 * 올인원 멤버관리
 * 초대하기 로직
 */
const NoteAccountAllInOneMemberInviteContainer = ({ children }) => {
  const history = useHistory()
  const [isInviteModalVisible, setIsInviteModalVisible] =
    useState(false) //올인원 초대 모달

  const { isBoss } = useIsAllInOne()

  useEffect(() => {
    if (!isBoss) {
      history.replace('/note/v2/account')
    }
  }, [])

  /**
   * 목록 위 초대하기 버튼 클릭
   */
  const onClickInvite = () => {
    setIsInviteModalVisible(true)
  }

  /**
   * 초대 모달 닫기
   */
  const onCancelModal = () => {
    setIsInviteModalVisible(false)
  }

  return children({
    isInviteModalVisible,
    onClickInvite,
    onCancelModal,
  })
}

export default NoteAccountAllInOneMemberInviteContainer
