import theme from 'lib/styles/theme'
import { getArea, getPrice } from 'lib/utils/bds'
import moment from 'moment'
import styled from 'styled-components'

/**
 * 주택 부동산 상세
 * 최상단 요약 정보
 * @param data
 */
const BdsHeaderSummary = ({ data }) => {
  const area = getArea({
    property_type: data?.property_type,
    land_area: data?.land_area,
    exclusive_area: data?.exclusive_area,
    contract_area: data?.contract_area,
  })
  return (
    <Wrapper>
      <Regdate>
        등록{' '}
        {data?.regdate ? (
          moment(data.regdate, 'YYYYMMDDHHmmss').format('YY.MM.DD')
        ) : (
          <>&ndash;</>
        )}{' '}
      </Regdate>
      <InfoWrapper>
        <Building>
          {data?.building_name || data?.alias || data?.address_jibun}{' '}
          {data?.dong ? ` ${data.dong}동` : ''}
          {/* {data?.floor_count ? `·${data?.floor_count}` : ''} */}
        </Building>
        <Price>
          {data?.contract_type ? (
            <>
              <PriceTag type={data.contract_type}>
                {data.contract_type}
              </PriceTag>
              {getPrice({
                contract_type: data?.contract_type,
                sale_price: data?.sale_price,
                rent: data?.rent,
                deposit: data?.deposit,
                jeonse_deposit: data?.jeonse_deposit,
              })}
            </>
          ) : (
            '가격 정보 없음'
          )}
        </Price>
        <Desc>
          {data?.property_type && (
            <DescItem>{data.property_type}</DescItem>
          )}
          {area && <DescItem>{area}</DescItem>}
          {(data?.floor_count || data?.total_floor_count) && (
            <DescItem>
              {data?.floor_count || <>&ndash;</>}
              {data?.total_floor_count
                ? `/${data?.total_floor_count}`
                : ''}
            </DescItem>
          )}
          {data?.direction && <DescItem>{data.direction}</DescItem>}
        </Desc>
        {data?.memo && <Memo>{data.memo}</Memo>}
      </InfoWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 12px 12px 20px 12px;
`

const Regdate = styled.div`
  width: fit-content;
  margin-bottom: 8px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[500]};
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 4px;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Building = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
`

const PriceTag = styled.div`
  font-weight: 700;
  line-height: 130%;

  ${({ type }) => {
    switch (type) {
      case '매매':
        return `
          color: ${theme.colors.summary.rp.blue};
        `
      case '전세':
        return `
          color: #1C9C40;
        `
      case '월세':
        return `
            color: ${theme.colors.summary.rp.red};
        `
    }
  }}
`
const DescItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`

const Desc = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 12px;

  ${DescItem} {
    &:first-child {
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        display: inline-block;
        height: 11px;
        width: 1px;
        background-color: #e4e4e4;
        margin-left: 5px;
      }
    }
  }
`

const Memo = styled.div`
  width: 100%;
  padding: 6px 10px;
  background-color: #f7f8fa;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
  color: #484d56;
`

export default BdsHeaderSummary
