import { Segment } from 'components/design'
import { SegmentItem } from 'components/design'
import MapFilterItem from 'components/map/MapFilter/MapFilterItem'
import NoteFilterSelect from './NoteFilterSelect'
import NoteFilterSelectOption from './NoteFilterSelectOption'
import styled from 'styled-components'
import { getColors } from 'lib/utils/propertyType'
import { Checkbox } from 'components/design'
import { Select } from 'components/design'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const StyledCheckboxWrap = styled.div`
  flex-basis: 50%;
  max-width: 50%;
  padding: 4px 4px 16px;
`
const NoteFilterType = ({
  selectedChannel,
  setSelectedChannel,

  selectedStatus,
  setSelectedStatus,

  propertyTypes,
  selectedPropertyTypes,
  setSelectedPropertyTypes,

  contractTypes,
  selectedContractTypes,
  setSelectedContractTypes,
}) => {
  const channels = useSelector((state) => state.channel.channels)
  const transpilePropertyTypeText = (value) => {
    if (value == '다가구주택') return '다가구'
    return value
  }

  const isNoteListUrl = useRouteMatch('/note/v2/notes/:channelSrl')

  return (
    <>
      {isNoteListUrl?.isExact ? null : (
        <MapFilterItem title="채널">
          <NoteFilterSelect>
            <Select
              disabled={false}
              value={selectedChannel ? selectedChannel : '전체'}
              onChange={(e) => setSelectedChannel(e.target.value)}
            >
              <option value="">모든 채널</option>
              {channels.map((item, i) => (
                <option key={i} value={item.channel_srl}>
                  {item.nick_name}님의 {item.name}
                </option>
              ))}
            </Select>
          </NoteFilterSelect>
        </MapFilterItem>
      )}
      <MapFilterItem title="매물상태">
        <Segment size="small">
          <SegmentItem
            active={selectedStatus === 0}
            onClick={() => setSelectedStatus(0)}
          >
            전체
          </SegmentItem>
          <SegmentItem
            active={selectedStatus === 1}
            onClick={() => setSelectedStatus(1)}
          >
            중개가능
          </SegmentItem>
          <SegmentItem
            active={selectedStatus === 2}
            onClick={() => setSelectedStatus(2)}
          >
            중개완료
          </SegmentItem>
        </Segment>
      </MapFilterItem>
      <MapFilterItem title="매물유형">
        <NoteFilterSelect>
          {propertyTypes.map((item, i) => {
            const text = transpilePropertyTypeText(item)

            return (
              <NoteFilterSelectOption
                key={i}
                value={item}
                group={selectedPropertyTypes}
                color={getColors(item)}
                onChangeGroup={(value) =>
                  setSelectedPropertyTypes(value)
                }
              >
                {text}
              </NoteFilterSelectOption>
            )
          })}
        </NoteFilterSelect>
      </MapFilterItem>
      <MapFilterItem title="거래유형">
        <NoteFilterSelect>
          {contractTypes.map((item, i) => (
            <StyledCheckboxWrap key={i}>
              <Checkbox
                value={item}
                multiValues={selectedContractTypes}
                onMultipleChange={(value) =>
                  setSelectedContractTypes(value)
                }
              >
                {item}
              </Checkbox>
            </StyledCheckboxWrap>
          ))}
        </NoteFilterSelect>
      </MapFilterItem>
    </>
  )
}

export default NoteFilterType
