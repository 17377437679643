import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  TableContent,
  InnerTable,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  innerSubHeaderWidth,
  StyledTable,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { media } from 'lib/styles/theme'
import { handleFocusNumber } from 'lib/utils/notev2/util'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsBasic4 = ({ onUpdate }) => {
  const { watch, setValue } = useFormContext()

  const d_descript_type = watch('d_descript_type')
  const d_paved_road = watch('d_paved_road')
  const d_access = watch('d_access')
  const d_bus_name = watch('d_bus_name')
  const d_bus_way = watch('d_bus_way')
  const d_bus_time = watch('d_bus_time')
  const d_subway_name = watch('d_subway_name')
  const d_subway_way = watch('d_subway_way')
  const d_subway_time = watch('d_subway_time')
  const d_parking = watch('d_parking')
  const d_parking_other = watch('d_parking_other')
  const d_elementary_name = watch('d_elementary_name')
  const d_elementary_way = watch('d_elementary_way')
  const d_elementary_time = watch('d_elementary_time')
  const d_middle_name = watch('d_middle_name')
  const d_middle_way = watch('d_middle_way')
  const d_middle_time = watch('d_middle_time')
  const d_high_name = watch('d_high_name')
  const d_high_way = watch('d_high_way')
  const d_high_time = watch('d_high_time')
  const d_store_name = watch('d_store_name')
  const d_store_way = watch('d_store_way')
  const d_store_time = watch('d_store_time')
  const d_medical_name = watch('d_medical_name')
  const d_medical_way = watch('d_medical_way')
  const d_medical_time = watch('d_medical_time')
  const d_road_touch_width = watch('d_road_touch_width')
  const d_road_touch_height = watch('d_road_touch_height')

  const isBuilding = ['주거용 건축물', '비주거용 건축물'].includes(
    d_descript_type,
  )

  const getRowSpan = useCallback(() => {
    if (d_descript_type == '주거용 건축물') {
      return 11
    } else if (d_descript_type == '비주거용 건축물') {
      return 6
    } else if (d_descript_type == '토지') {
      return 4
    }
  }, [d_descript_type])

  const getEachRowHeight = useCallback(() => {
    if (d_descript_type == '주거용 건축물') {
      return [12, 22, 10, 33, 23]
    } else if (d_descript_type == '비주거용 건축물') {
      return [30, 40, 30]
    } else if (d_descript_type == '토지') {
      return [40, 60]
    }
  }, [d_descript_type])

  const eachRowHeight = useMemo(
    () => getEachRowHeight(),
    [d_descript_type],
  )

  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑤'
    } else {
      return '④'
    }
  }

  return (
    <>
      <StyledTable>
        <Row height={30} rowspan={getRowSpan()} noborderbottom>
          <TableHeader width={headerWidth}>
            {getNumber()} 입지조건
          </TableHeader>
          <RowSpanInner
            rowspanheightpercent={eachRowHeight[0]}
            minuswidth={headerWidth}
          >
            <InnerTableBlock widthpercent={57.3} noborderbottom>
              <TableSubHeader width={headerWidth}>
                도로와의
                <br />
                관계
              </TableSubHeader>
              <InnerTableContent checkboxes headerwidth={headerWidth}>
                &#40;&nbsp;
                <ColorInput
                  width={65}
                  onClick={handleFocusNumber}
                  type="text"
                  defaultValue={d_road_touch_width}
                  onBlur={(e) =>
                    onUpdate('d_road_touch_width', e.target.value)
                  }
                  append="m"
                />
                &nbsp; x &nbsp;
                <ColorInput
                  width={70}
                  onClick={handleFocusNumber}
                  type="text"
                  defaultValue={d_road_touch_height}
                  onBlur={(e) =>
                    onUpdate('d_road_touch_height', e.target.value)
                  }
                  append="m &#41;"
                />
                <Text> &nbsp;도로에 접함&nbsp;&nbsp;&nbsp;</Text>
                <RadioButton
                  label="포장"
                  checked={d_paved_road == '포장'}
                  name="d_paved_road"
                  onChange={(e) => onUpdate('d_paved_road', '포장')}
                />
                <RadioButton
                  label="비포장"
                  checked={d_paved_road == '비포장'}
                  name="d_paved_road"
                  onChange={(e) => onUpdate('d_paved_road', '비포장')}
                />
              </InnerTableContent>
            </InnerTableBlock>
            <InnerTableBlock
              widthpercent={35}
              noborderbottom
              noborderright
            >
              <TableSubHeader width={headerWidth}>
                접근성
              </TableSubHeader>
              <InnerTableContent checkboxes headerwidth={headerWidth}>
                <RadioButton
                  label="용이함"
                  checked={d_access == '용이함' || d_access == ''}
                  name="d_access"
                  onChange={(e) => onUpdate('d_access', '용이함')}
                />
                <RadioButton
                  label="불편함"
                  checked={d_access == '불편함'}
                  name="d_access"
                  onChange={(e) => onUpdate('d_access', '불편함')}
                />
              </InnerTableContent>
            </InnerTableBlock>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={eachRowHeight[1]}
            rowspan={2}
            minuswidth={headerWidth}
            noborderbottom={!isBuilding}
          >
            <TableSubHeader width={headerWidth}>
              대중교통
            </TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={50}>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    버스
                  </TableSubHeader>
                  <TableContent
                    minuswidth={innerSubHeaderWidth}
                    noborderright
                    spacebetween
                  >
                    <NameWrapper>
                      <ColorInput
                        width={220}
                        type="text"
                        defaultValue={d_bus_name}
                        onBlur={(e) =>
                          onUpdate('d_bus_name', e.target.value)
                        }
                      />
                      <InputName>정류장,</InputName>
                    </NameWrapper>
                    <Times>
                      <span>소요시간:</span>
                      <RadioButton
                        label="도보"
                        checked={d_bus_way == '도보'}
                        name="d_bus_way"
                        onChange={(e) => onUpdate('d_bus_way', '도보')}
                      />
                      <RadioButton
                        label="차량"
                        checked={d_bus_way == '차량'}
                        name="d_bus_way"
                        onChange={(e) => onUpdate('d_bus_way', '차량')}
                      />
                      <Time>
                        약
                        <ColorInput
                          width={80}
                          type="text"
                          defaultValue={d_bus_time}
                          onBlur={(e) =>
                            onUpdate('d_bus_time', e.target.value)
                          }
                          onClick={handleFocusNumber}
                          append="분"
                        />
                      </Time>
                    </Times>
                  </TableContent>
                </Row>
                <Row heightpercent={50} noborderbottom>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    지하철
                  </TableSubHeader>
                  <TableContent
                    minuswidth={innerSubHeaderWidth}
                    noborderright
                    spacebetween
                  >
                    <NameWrapper>
                      <ColorInput
                        width={220}
                        type="text"
                        defaultValue={d_subway_name}
                        onBlur={(e) =>
                          onUpdate('d_subway_name', e.target.value)
                        }
                      />
                      <InputName>역,</InputName>
                    </NameWrapper>
                    <Times>
                      <span>소요시간:</span>
                      <RadioButton
                        label="도보"
                        checked={d_subway_way == '도보'}
                        name="d_subway_way"
                        onChange={(e) =>
                          onUpdate('d_subway_way', '도보')
                        }
                      />
                      <RadioButton
                        label="차량"
                        checked={d_subway_way == '차량'}
                        name="d_subway_way"
                        onChange={(e) =>
                          onUpdate('d_subway_way', '차량')
                        }
                      />
                      <Time>
                        약
                        <ColorInput
                          width={80}
                          onClick={handleFocusNumber}
                          type="text"
                          defaultValue={d_subway_time}
                          onBlur={(e) =>
                            onUpdate('d_subway_time', e.target.value)
                          }
                          append="분"
                        />
                      </Time>
                    </Times>
                  </TableContent>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
          {isBuilding && (
            <RowSpanInner
              rowspanheightpercent={eachRowHeight[2]}
              minuswidth={headerWidth}
              noborderbottom={d_descript_type != '주거용 건축물'}
            >
              <TableSubHeader width={headerWidth}>
                주차장
              </TableSubHeader>
              <TableContent fullwidth nopadding noborderright>
                <InnerTableContent checkboxes fullWidth>
                  <RadioButtons width={460}>
                    <RadioButton
                      label="없음"
                      checked={d_parking == '없음'}
                      name="d_parking"
                      onChange={(e) => onUpdate('d_parking', '없음')}
                    />
                    <RadioButton
                      label="전용주차시설"
                      checked={d_parking == '전용주차시설'}
                      name="d_parking"
                      onChange={(e) =>
                        onUpdate('d_parking', '전용주차시설')
                      }
                    />
                    <RadioButton
                      label="공동주차시설"
                      checked={d_parking == '공동주차시설'}
                      name="d_parking"
                      onChange={(e) =>
                        onUpdate('d_parking', '공동주차시설')
                      }
                    />
                    <RadioButton
                      label="그 밖의 주차시설"
                      checked={d_parking == '그 밖의 주차시설'}
                      name="d_parking"
                      onChange={(e) =>
                        onUpdate('d_parking', '그 밖의 주차시설')
                      }
                    />
                  </RadioButtons>
                  <ColorInput
                    type="text"
                    disabled={d_parking != '그 밖의 주차시설'}
                    defaultValue={d_parking_other}
                    onBlur={(e) =>
                      onUpdate('d_parking_other', e.target.value)
                    }
                    fullWidth
                  />
                </InnerTableContent>
              </TableContent>
            </RowSpanInner>
          )}

          {d_descript_type == '주거용 건축물' && (
            <>
              <RowSpanInner
                rowspanheightpercent={eachRowHeight[3]}
                rowspan={3}
                minuswidth={headerWidth}
              >
                <TableSubHeader width={headerWidth}>
                  교육시설
                </TableSubHeader>
                <TableContent fullwidth nopadding noborderright>
                  <InnerTable>
                    <Row heightpercent={100} noborderbottom>
                      <InnerTableBlock noborderbottom noborderright>
                        <Row
                          rowspan={3}
                          heightpercent={100}
                          noborderbottom
                        >
                          <RowSpanInner fullwidth rowspan={3}>
                            <TableSubHeader width={innerSubHeaderWidth}>
                              초등학교
                            </TableSubHeader>
                            <InnerTableContent
                              headerwidth={innerSubHeaderWidth}
                              spacebetween
                            >
                              <NameWrapper>
                                <ColorInput
                                  width={220}
                                  type="text"
                                  defaultValue={d_elementary_name}
                                  onBlur={(e) =>
                                    onUpdate(
                                      'd_elementary_name',
                                      e.target.value,
                                    )
                                  }
                                />
                                <InputName>학교,</InputName>
                              </NameWrapper>
                              <Times>
                                <span>소요시간:</span>
                                <RadioButton
                                  label="도보"
                                  checked={d_elementary_way == '도보'}
                                  name="d_elementary_way"
                                  onChange={(e) =>
                                    onUpdate('d_elementary_way', '도보')
                                  }
                                />
                                <RadioButton
                                  label="차량"
                                  checked={d_elementary_way == '차량'}
                                  name="d_elementary_way"
                                  onChange={(e) =>
                                    onUpdate('d_elementary_way', '차량')
                                  }
                                />
                                <Time>
                                  약
                                  <ColorInput
                                    width={80}
                                    type="text"
                                    defaultValue={d_elementary_time}
                                    onBlur={(e) =>
                                      onUpdate(
                                        'd_elementary_time',
                                        e.target.value,
                                      )
                                    }
                                    onClick={handleFocusNumber}
                                    append="분"
                                  />
                                </Time>
                              </Times>
                            </InnerTableContent>
                          </RowSpanInner>
                          <RowSpanInner fullwidth rowspan={3}>
                            <TableSubHeader width={innerSubHeaderWidth}>
                              중학교
                            </TableSubHeader>
                            <InnerTableContent
                              headerwidth={innerSubHeaderWidth}
                              spacebetween
                            >
                              <NameWrapper>
                                <ColorInput
                                  width={220}
                                  type="text"
                                  defaultValue={d_middle_name}
                                  onBlur={(e) =>
                                    onUpdate(
                                      'd_middle_name',
                                      e.target.value,
                                    )
                                  }
                                />
                                <InputName>학교,</InputName>
                              </NameWrapper>
                              <Times>
                                <span>소요시간:</span>
                                <RadioButton
                                  label="도보"
                                  checked={d_middle_way == '도보'}
                                  name="d_middle_way"
                                  onChange={(e) =>
                                    onUpdate('d_middle_way', '도보')
                                  }
                                />
                                <RadioButton
                                  label="차량"
                                  checked={d_middle_way == '차량'}
                                  name="d_middle_way"
                                  onChange={(e) =>
                                    onUpdate('d_middle_way', '차량')
                                  }
                                />
                                <Time>
                                  약
                                  <ColorInput
                                    width={80}
                                    type="text"
                                    defaultValue={d_middle_time}
                                    onBlur={(e) =>
                                      onUpdate(
                                        'd_middle_time',
                                        e.target.value,
                                      )
                                    }
                                    append="분"
                                    onClick={handleFocusNumber}
                                  />
                                </Time>
                              </Times>
                            </InnerTableContent>
                          </RowSpanInner>
                          <RowSpanInner
                            noborderbottom
                            fullwidth
                            rowspan={3}
                          >
                            <TableSubHeader width={innerSubHeaderWidth}>
                              고등학교
                            </TableSubHeader>
                            <InnerTableContent
                              headerwidth={innerSubHeaderWidth}
                              spacebetween
                            >
                              <NameWrapper>
                                <ColorInput
                                  width={220}
                                  type="text"
                                  defaultValue={d_high_name}
                                  onBlur={(e) =>
                                    onUpdate(
                                      'd_high_name',
                                      e.target.value,
                                    )
                                  }
                                />
                                <InputName>학교,</InputName>
                              </NameWrapper>
                              <Times>
                                <span>소요시간:</span>
                                <RadioButton
                                  label="도보"
                                  checked={d_high_way == '도보'}
                                  name="d_high_way"
                                  onChange={(e) =>
                                    onUpdate('d_high_way', '도보')
                                  }
                                />
                                <RadioButton
                                  label="차량"
                                  checked={d_high_way == '차량'}
                                  name="d_high_way"
                                  onChange={(e) =>
                                    onUpdate('d_high_way', '차량')
                                  }
                                />
                                <Time>
                                  약
                                  <ColorInput
                                    width={80}
                                    type="text"
                                    defaultValue={d_high_time}
                                    onBlur={(e) =>
                                      onUpdate(
                                        'd_high_time',
                                        e.target.value,
                                      )
                                    }
                                    append="분"
                                    onClick={handleFocusNumber}
                                  />
                                </Time>
                              </Times>
                            </InnerTableContent>
                          </RowSpanInner>
                        </Row>
                      </InnerTableBlock>
                    </Row>
                  </InnerTable>
                </TableContent>
              </RowSpanInner>
              <RowSpanInner
                rowspanheightpercent={eachRowHeight[4]}
                rowspan={2}
                noborderbottom
                minuswidth={headerWidth}
              >
                <TableSubHeader width={headerWidth}>
                  판매 및<br />
                  의료시설
                </TableSubHeader>
                <TableContent fullwidth nopadding noborderright>
                  <InnerTable>
                    <Row heightpercent={58}>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        백화점 및<br />
                        할인매장
                      </TableSubHeader>
                      <TableContent
                        minuswidth={innerSubHeaderWidth}
                        noborderright
                        spacebetween
                      >
                        <ColorInput
                          width={220}
                          type="text"
                          defaultValue={d_store_name}
                          onBlur={(e) =>
                            onUpdate('d_store_name', e.target.value)
                          }
                        />
                        <Times>
                          <span>소요시간:</span>
                          <RadioButton
                            label="도보"
                            checked={d_store_way == '도보'}
                            name="d_store_way"
                            onChange={(e) =>
                              onUpdate('d_store_way', '도보')
                            }
                          />
                          <RadioButton
                            label="차량"
                            checked={d_store_way == '차량'}
                            name="d_store_way"
                            onChange={(e) =>
                              onUpdate('d_store_way', '차량')
                            }
                          />
                          <Time>
                            약
                            <ColorInput
                              width={80}
                              type="text"
                              defaultValue={d_store_time}
                              onBlur={(e) =>
                                onUpdate('d_store_time', e.target.value)
                              }
                              append="분"
                              onClick={handleFocusNumber}
                            />
                          </Time>
                        </Times>
                      </TableContent>
                    </Row>
                    <Row heightpercent={42} noborderbottom>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        종합의료시설
                      </TableSubHeader>
                      <TableContent
                        minuswidth={innerSubHeaderWidth}
                        noborderright
                        spacebetween
                      >
                        <ColorInput
                          width={220}
                          type="text"
                          defaultValue={d_medical_name}
                          onBlur={(e) =>
                            onUpdate('d_medical_name', e.target.value)
                          }
                        />
                        <Times>
                          <span>소요시간:</span>
                          <RadioButton
                            label="도보"
                            checked={d_medical_way == '도보'}
                            name="d_medical_way"
                            onChange={(e) =>
                              onUpdate('d_medical_way', '도보')
                            }
                          />
                          <RadioButton
                            label="차량"
                            checked={d_medical_way == '차량'}
                            name="d_medical_way"
                            onChange={(e) =>
                              onUpdate('d_medical_way', '차량')
                            }
                          />
                          <Time>
                            약
                            <ColorInput
                              width={80}
                              type="text"
                              defaultValue={d_medical_time}
                              onBlur={(e) =>
                                onUpdate(
                                  'd_medical_time',
                                  e.target.value,
                                )
                              }
                              append="분"
                              onClick={handleFocusNumber}
                            />
                          </Time>
                        </Times>
                      </TableContent>
                    </Row>
                  </InnerTable>
                </TableContent>
              </RowSpanInner>
            </>
          )}
        </Row>
      </StyledTable>
    </>
  )
}

const Times = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;

  & > span {
    flex-shrink: 0;
    margin-right: 10px;
  }

  & > label {
    margin: 0 10px;
    flex-shrink: 0;

    ${media.mediumS`
      margin: 0;
    `}
  }

  input {
    margin: 0 10px;
  }
`

const Time = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;

  ${media.mediumS`
    margin-left: 0;
  `}
`

const InputName = styled.div`
  margin-left: 8px;
  flex-shrink: 0;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`

const RadioButtons = styled.div`
  display: flex;
  align-items: center;
  min-width: 439px;
  label {
    flex-shrink: 0;
  }
`

const Text = styled.div`
  ${media.mediumS`
    flex-shrink: 0;
  `}
`

export default NoteDocsBasic4
