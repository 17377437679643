import { TabItem } from 'components/design'
import { useContext, useEffect } from 'react'
import useMutationObserve from './useMutationObserve'
import BaseContext from 'contexts/baseContext'
import useIsMobile from 'lib/hooks/useIsMobile'

/**
 * summary 공동 주택 아닌 탭
 */
const SummaryTabNotApt = ({ tabs }) => {
  const { observeSection, activeIdx } = useMutationObserve(
    tabs,
    'summary-tab-active-section',
    true,
  )

  const {
    state: { isScrolled },
  } = useContext(BaseContext)

  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile) {
      if (isScrolled) observeSection()
    } else {
      observeSection()
    }
  }, [isScrolled])

  return (
    <>
      {Array.from(tabs).map((tab, index) => {
        if (tab.element?.current)
          return (
            <TabItem
              key={index}
              noContainer
              expand
              active={activeIdx == index}
              onClick={tab?.onMoveToElement}
            >
              {tab?.name}
            </TabItem>
          )
      })}
    </>
  )
}

export default SummaryTabNotApt
