import { Route, Switch } from 'react-router'
import NoteListHeader from 'components/jmapnote/noteList/NoteListHeader'
import NoteListContainer from 'containers/note/noteList/NoteListContainer'
import ChannelHeader from 'components/jmapnote/channel/ChannelHeader'
import SettingsPage from '../settings/SettingsPage'
import SettingsInvitePage from '../settings/SettingsInvitePage'
import SettingsFolderPage from '../settings/SettingsFolderPage'
import SettingsExcelPage from '../settings/SettingsExcelPage'
import NoteLayout from 'components/jmapnote/noteBase/NoteLayout'
import { useSelector } from 'react-redux'
import { WarnText } from 'components/jmapnotev2/style/NoteStyle'
import NoteListNew from 'components/jmapnote/noteList/NoteListNew'

const NoteListPage = ({ match }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )
  const height = useSelector((state) => state.notice.height)

  return (
    <NoteLayout>
      <NoteListContainer params={match?.params}>
        {({ folders, _getFoldersLoading }) => (
          <div
            isNoticeBarVisible={isNoticeBarVisible}
            noticeBarHeight={height}
          >
            <>
              <ChannelHeader />
              {/* brokertest 계정 경고문 */}
              {loginInfo?.member_srl == '9006' && (
                <WarnText card>
                  공용 테스트 아이디로 접속하셨습니다. 개인정보를
                  입력하실 경우 다른 사용자에게 노출될 수 있으니,
                  주의하여 입력해 주시기 바랍니다.
                </WarnText>
              )}
              <NoteListHeader
                _getFoldersLoading={_getFoldersLoading}
                folders={folders}
              />

              <NoteListNew
                path={match.path}
                hasFolder={folders?.length > 1}
              />
            </>
            <Switch>
              <Route
                path={`${match.path}/settings/excel`}
                component={SettingsExcelPage}
              />
              <Route
                path={`${match.path}/settings/invite`}
                component={SettingsInvitePage}
              />
              <Route
                path={`${match.path}/settings/folder`}
                component={SettingsFolderPage}
              />
              <Route
                path={`${match.path}/settings`}
                component={SettingsPage}
              />
            </Switch>
          </div>
        )}
      </NoteListContainer>
    </NoteLayout>
  )
}

export default NoteListPage
