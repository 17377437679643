import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { feeOptions } from '../../optionsFee'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

const clearList = [
  'administrationCostInfo.chargeCodeType',
  'administrationCostInfo.chargeCriteriaCode',
  'administrationCostInfo.chargeInputContent',
  'administrationCostInfo.fixedFeeDetails',
  'administrationCostInfo.etcFeeDetails.detailCodeType',
  'administrationCostInfo.etcFeeDetails.directInputContent',
  'administrationCostInfo.etcFeeDetails.etcFeeAmount',
  'administrationCostInfo.etcFeeDetails.includeCodeTypes',
  'administrationCostInfo.unableCheckDetails.detailCodeType',
  'administrationCostInfo.etcFeeDetails.etcFeeAmount',
]

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비 부과 정보
 */
const NoteAdColumnFeeImposeType = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext()
  const list = feeOptions['부과타입']

  /**
   * 관리비 타입 변경시 에러 초기화
   */
  const onChangeType = (e) => {
    const value = e.target.value
    clearList.forEach((item) => {
      clearErrors(item)
    })
    onUpdate('administrationCostInfo.chargeCodeType', value)

    if (value == '01') {
      onUpdate('administrationCostInfo.chargeCriteriaCode', '')
      onUpdate('administrationCostInfo.fixedFeeDetails', [])
    } else if (value == '02') {
      onUpdate('administrationCostInfo.chargeCriteriaCode', '')
      onUpdate(
        'administrationCostInfo.etcFeeDetails.detailCodeType',
        '',
      )
      onUpdate(
        'administrationCostInfo.etcFeeDetails.directInputContent',
        '',
      )
      onUpdate('administrationCostInfo.etcFeeDetails.etcFeeAmount', 0)
      onUpdate(
        'administrationCostInfo.etcFeeDetails.includeCodeTypes',
        [],
      )
    } else if (value == '03') {
      onUpdate('administrationCostInfo.chargeCriteriaCode', '')
      onUpdate('administrationCostInfo.chargeInputContent', '')
      onUpdate(
        'administrationCostInfo.unableCheckDetails.detailCodeType',
        '',
      )
    } else if (value == '04') {
      onUpdate('administrationCostInfo.chargeCriteriaCode', '')
      onUpdate('administrationCostInfo.chargeInputContent', '')
      onUpdate(
        'administrationCostInfo.etcFeeDetails.includeCodeTypes',
        [],
      )
      onUpdate('administrationCostInfo.etcFeeDetails.etcFeeAmount', 0)
      //세부내역타입 정액관리비이지만 중개의뢰인이 세부내역 미고지한 경우로 업데이트
      onUpdate(
        'administrationCostInfo.etcFeeDetails.detailCodeType',
        '98',
      )
    }
  }

  return (
    <ColumnSet>
      <ColumnTitle required>부과 타입</ColumnTitle>
      <ColumnVal gap={12}>
        {Object.entries(list).map(([key, value], i) => {
          return (
            <React.Fragment key={i}>
              <RadioButton
                size="small"
                fit
                value={key}
                label={value}
                name="fee_impose_type"
                {...register('administrationCostInfo.chargeCodeType')}
                onChange={onChangeType}
              />
            </React.Fragment>
          )
        })}
        <ValidateWarn>
          {errors?.administrationCostInfo?.message}
          {errors?.administrationCostInfo?.chargeCodeType?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeeImposeType
