import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import RadioButton from '../../elements/RadioButton'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { options as listOptions } from '../../options'
import React from 'react'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 원룸 구조
 */
const NoteAdColumnRoomStructure = ({ required = false }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const duplex = watch('article.duplex')
  const oneroomType = watch('oneroomInfo.oneroomType')
  const shape = watch('article.shape')

  const list = listOptions['방거실형태']

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>원룸구조</ColumnTitle>
      <ColumnVal gap={16}>
        {list.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <RadioButton
                size="small"
                fit
                label={item}
                {...register('article.shape')}
                value={item}
                checked={item == oneroomType || item == shape}
                onChange={(e) => {
                  onUpdate('article.shape', e.target.value)

                  //원룸특징의 원룸구조도 업데이트
                  onUpdate('oneroomInfo.oneroomType', e.target.value)
                }}
                name="article_room_shape"
              />
            </React.Fragment>
          )
        })}
        <ValidateWarn>
          <div>{errors?.article?.shape?.message}</div>
          <div>{errors?.oneroomInfo?.oneroomType?.message}</div>
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const RadioWrapper = styled.div`
  display: flex;
`

export default NoteAdColumnRoomStructure
