import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import { useEffect, useMemo } from 'react'
import styled from 'styled-components'

const StyledButtonWrap = styled.div`
  /* max-width: 33.3333%;
  flex-basis: 33.3333%; */

  max-width: 50%;
  padding: 0.25rem;

  ${({ column }) => {
    return `
			flex-basis: ${100 / column}%;
			padding: ${column > 2 ? '0.15rem;' : '0.25rem;'};
		`
  }}
`

const ButtonText = styled.div`
  flex: 1;
  text-align: left;
  ${({ center }) => center && 'text-align: center;'}
  ${({ column }) => column > 2 && 'font-size: 13px; line-height: 120%;'}
`
const ButtonColor = styled.div`
  width: 12px;
  height: 12px;

  background-color: ${(props) => props.color};
  border-radius: ${theme.borderRadius[0]};
`

const NoteFilterSelectOption = ({
  value,
  children,
  onChangeGroup,
  color,
  column = 2,
  group,
  center = false,
}) => {
  const computeActive = (group) => {
    if (group.find((item) => item === value)) {
      return true
    }
    // 전체 선택 활성화
    if (group.length === 0 && value === 'all') {
      return true
    }

    return false
  }

  const active = useMemo(() => computeActive(group), [group])

  const onClick = () => {
    if (value === 'all') {
      onChangeGroup([])
      return
    }
    if (!active) {
      onChangeGroup([...group, value])
      return
    }

    const filteredGroup = group.filter((e) => e !== value)
    onChangeGroup(filteredGroup)
  }

  return (
    <StyledButtonWrap column={column}>
      <Button
        color={active ? 'primary' : 'gray'}
        plain={active}
        onClick={onClick}
        expand
      >
        <ButtonText center={center} column={column}>
          {children}
        </ButtonText>
        {color && <ButtonColor color={color} />}
      </Button>
    </StyledButtonWrap>
  )
}

export default NoteFilterSelectOption
