import styled from 'styled-components'
import SummaryFloorItem from './SummaryFloorItem'

const SummaryFloorList = ({ viewCnt, data }) => {
  return (
    <Wrapper viewcnt={viewCnt}>
      {data.length > 0 &&
        data.map((item, index) => {
          return <SummaryFloorItem item={item} key={index} />
        })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ viewcnt }) =>
    viewcnt &&
    `
    & > div {
      &:nth-child(n + ${viewcnt + 1}) {
        display: none;
      }
    }
  `}
`

export default SummaryFloorList
