import { LayerGroupItem } from '../MapControl/MapControlStyle'
import { ReactComponent as Icon } from 'assets/icon/map-control/land.svg'
import { ReactComponent as IconActive } from 'assets/icon/map-control/land-active.svg'
import MapFilterLand from './MapFilterLand'
import MapFilterFloating from './MapFilterFloating'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setOpenFilterType } from 'modules/map'
import MapContext, { initialFilterState } from 'contexts/map'
import { useContext, useMemo, useState } from 'react'
import { getLandLayer } from 'lib/api/layerApi'
import { useEffect } from 'react'
import theme from 'lib/styles/theme'
import useAxios from 'lib/hooks/useAxios'
import { isEqual, uniqBy } from 'lodash'

/**
 * 필터 토지
 */
const MapFilterGroupLand = () => {
  const { kakao } = window
  const {
    state: { layerState, filterState, viewState, map },
    actions: { setLayerState, setFilterState },
  } = useContext(MapContext)

  const openFilterType = useSelector(
    (state) => state.map.openFilterType,
  )
  const dispatch = useDispatch()

  const [onRequest, , data] = useAxios(getLandLayer)

  const [polygons, setPolygons] = useState([])
  const [isActive, setIsActive] = useState(false) //건물 레이어 active시 옆에 점 찍어주는 state

  /**
   * 필터 초기값
   */
  const initValues = {
    // 토지 레이어
    land_area: [0, 300], //대지면적
    land_type: [], //지목
    use_area: [], //용도지역
    // unit_prices: [0, 50], //개별공시지가
  }

  /**
   * 토지 폴리곤 조회
   */
  useEffect(() => {
    if (!viewState || Object.keys(viewState).length == 0) return
    if (!layerState.land) return
    if (viewState?.level > 2) {
      if (polygons.length > 0) resetPolygon()
      return
    }

    onRequest({
      swlat: viewState.swLatlng.getLat(),
      swlng: viewState.swLatlng.getLng(),
      nelat: viewState.neLatlng.getLat(),
      nelng: viewState.neLatlng.getLng(),
      level: viewState.level,
      ...filterState?.land,
    })
  }, [
    viewState,
    filterState.land.land_area,
    filterState.land.land_type,
    filterState.land.use_area.length,
    layerState.land,
  ])

  useEffect(() => {
    if (!data?.result || data.result?.length === 0) {
      // 기존 폴리곤 삭제
      resetPolygon()
      return
    }

    drawPolygons(data.result)
  }, [data])

  useEffect(() => {
    if (viewState?.level > 3) {
      resetPolygon()
    }
  }, [viewState?.level])

  /**
   * 그릴 폴리곤 만들고 세팅
   * @param {*} polyData
   */
  const drawPolygons = (polyData) => {
    const lands = polyData

    // 폴리곤 배열
    let tempPolygons = []
    lands.map((land) => {
      const shpaePoints = land.shape.coordinates

      const polygonPath = shpaePoints.map((part) => {
        return part.map((point) => {
          return new kakao.maps.LatLng(point[0], point[1])
        })
      })

      // 지도에 표시할 다각형을 생성합니다
      const landPolygon = new kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 1, // 선의 두께입니다
        strokeColor: theme.colors.yellow[500], // 선의 색깔입니다
        strokeOpacity: 0.5, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다
        fillColor: theme.colors.yellow[300], // 채우기 색깔입니다
        fillOpacity: 0.5, // 채우기 불투명도 입니다
        zIndex: 1,
      })

      // 하나씩 생성된 건물 폴리곤을 배열에 넣기
      tempPolygons.push(landPolygon)
    })

    setPolygons(tempPolygons)
  }

  /**
   * 폴리곤 그리기
   */
  useEffect(() => {
    if (polygons.length === 0) return
    polygons.map((polygon) => {
      polygon.setMap(map)
    })

    return () => {
      if (polygons.length === 0) return
      // 기존 폴리곤 삭제
      resetPolygon()
    }
  }, [polygons])

  /**
   * 레이어 on/off event
   */
  useEffect(() => {
    if (!layerState.land) {
      resetPolygon()
      setIsActive(false)
    } else {
      setIsActive(true)
    }
  }, [layerState.land])

  /**
   * 토지를 누르면 건물 필터 floating modal 띄우기
   */
  const onClick = () => {
    dispatch(
      setOpenFilterType(openFilterType == 'land' ? null : 'land'),
    )
  }

  /**
   * 건물 스위치 토글
   */
  const toggleLayer = () => {
    setLayerState((prev) => {
      const result = { ...prev }
      result['land'] = result['land'] ? false : true
      return result
    })
  }

  /**
   * 레이어가 열릴 때 실행될 함수
   * 토지 레이어가 켜진다
   */
  const onOpen = () => {
    if (layerState?.land) return
    setLayerState((prev) => {
      const result = { ...prev }
      result['land'] = true
      return result
    })
  }

  /**
   * 폴리곤 다 삭제
   */
  const resetPolygon = () => {
    polygons?.map((polygon) => {
      polygon.setMap(null)
    })
  }

  /**
   * 전체 선택 필터 리셋
   * TODO: initialState const인데 왜 덮어쓰기 되는지 확인 후 하드 코딩 제거
   */
  const resetFilter = () => {
    setFilterState((prev) => {
      return {
        ...prev,
        land: initValues,
      }
    })
  }

  const isButtonActive = useMemo(
    () => openFilterType == 'land',
    [openFilterType],
  )

  return (
    <>
      <LayerGroupItem
        noborder
        dotactive={isActive}
        onClick={onClick}
        active={isButtonActive}
      >
        {isButtonActive ? <IconActive /> : <Icon />}
        <span>토지</span>
      </LayerGroupItem>

      <MapFilterFloating
        onOpen={onOpen}
        isLayerOn={layerState?.land}
        toggleLayer={toggleLayer}
        title="토지"
        visible={isButtonActive}
        render={<MapFilterLand />}
        onChangeSelectAll={resetFilter}
        isSelectAll={isEqual(filterState.land, initValues)}
      />
    </>
  )
}

export default MapFilterGroupLand
