import React from 'react'
import Container from 'components/base/Container/Container'
import { Card } from 'components/design'
import { CardBody } from 'components/design'
import HomeHead from 'components/home/HomeHead'
import HomeFeatures from 'components/home/HomeFeatures'
import HomeRecentAddress from 'components/home/HomeRecentAddress'
import HomeFavorite from 'components/home/HomeFavorite'
import HomeContainer from 'containers/home/HomeContainer'
import SEOHead from 'components/common/SEOHead'
import HomeNoteBanner from 'components/home/HomeNoteBanner'
import BaseFooter from 'components/base/BaseFooter/BaseFooter'
import HomeMapVisibleButton from 'components/home/HomeMapVisibleButton'
import HomeQuickMenu from 'components/home/HomeQuickMenu'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import useIsMobile from 'lib/hooks/useIsMobile'

const HomePage = () => {
  const mapVisible = useSelector((state) => state.map.mapVisible)
  const isMobile = useIsMobile()

  if (isMobile && mapVisible) return <></>

  return (
    <>
      <SEOHead />
      <HomeContainer>
        {({ isLoggedIn, recentAddresses, recentFavorites }) => (
          <Wrapper>
            <Container noTopHeader absolute>
              <HomeHead />

              <HomeMapVisibleButton />

              <Card>
                <CardBody>
                  <HomeQuickMenu />
                  <HomeNoteBanner />
                </CardBody>
              </Card>

              <HomeFeatures />

              {recentAddresses?.length > 0 && (
                <HomeRecentAddress recentAddresses={recentAddresses} />
              )}

              {recentFavorites?.length > 0 && (
                <HomeFavorite
                  isLoggedIn={isLoggedIn}
                  recentFavorites={recentFavorites}
                />
              )}

              <BaseFooter />
            </Container>
          </Wrapper>
        )}
      </HomeContainer>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  z-index: 20;
`

export default React.memo(HomePage)
