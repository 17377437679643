import { Confirm } from 'components/design'
import { getDocsWritePath } from 'lib/utils/notev2/common'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

const NoteDetailWriteDocsContainer = ({
  children,
  afterPushClose,
  onClose,
}) => {
  const history = useHistory()
  const { watch } = useFormContext()

  const note_srl = watch('note_srl')
  const channel_srl = watch('channel_srl')
  const address_id = watch('address_id')
  const dong = watch('dong')
  const ho = watch('ho')
  const property_type = watch('property_type')
  const contract_type = watch('contract_type')
  const address_jibun = watch('address_jibun')
  const invalid_addr_name = watch('invalid_addr_name')
  const sale_price = watch('sale_price')
  const jeonse_deposit = watch('jeonse_deposit')
  const deposit = watch('deposit')
  const rent = watch('rent')
  const address_doro = watch('address_doro')
  const is_group_building = watch('is_group_building')

  // 분양권 관련 항목들
  const presale_base_price = watch('presale_base_price') * 10000 //분양금액
  const presale_option_price = watch('presale_option_price') * 10000 //옵션
  const presale_paid = watch('presale_paid') * 10000 //납부한 금액
  const presale_premium = watch('presale_premium') * 10000 //프리미엄
  const presale_to_pay = watch('presale_to_pay') * 10000 //납부 예정 금액
  const presale_jeongsan_price = watch('presale_jeongsan_price') * 10000 //정산 지불금

  const onClickDocsWrite = () => {
    if (afterPushClose) {
      onClose()
    }

    //address_id 있을때만 건축물대장 조회를 위한 파라미터 세팅
    if (address_id) {
      history.push({
        pathname: `/note/v2/docs/${channel_srl}/${note_srl}/edit`,
        state: getDocsWritePath({
          note_srl,
          channel_srl,
          address_id,
          dong,
          ho,
          property_type,
          contract_type,
          address_jibun,
          invalid_addr_name,
          address_doro,
          total_price: getTotalPrice(),
          rent_price: getRentPrice(),
          is_group_building,
          presale_base_price,
          presale_option_price,
          presale_paid,
          presale_premium,
          presale_to_pay,
          presale_jeongsan_price,
        }),
      })
    } else {
      //유효하지 않은 주소 입력했을때 계약서 작성 - 막음
      Confirm.info({
        title: '알림',
        content:
          '정확한 주소를 입력하시고 계약서 작성 서비스를 이용해 보세요!',
        okText: '확인',
        okButtonProps: {
          color: 'blue',
        },
      })
    }
  }

  const getTotalPrice = () => {
    if (contract_type == '매매') return sale_price * 10000
    else if (contract_type == '전세') return jeonse_deposit * 10000
    else if (contract_type == '월세') return deposit * 10000
    else return 0
  }

  const getRentPrice = () => {
    if (contract_type == '월세') return rent * 10000
    else return 0
  }

  return children({ onClickDocsWrite })
}

export default NoteDetailWriteDocsContainer
