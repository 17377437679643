import { useCallback } from 'react'
import { useHistory } from 'react-router'

import { List } from 'components/design'
import { ListItem } from 'components/design'
import { ListItemLabel } from 'components/design'

import SettingsHead from 'components/jmapnote/settings/SettingsHead'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'

import ChannelRemove from 'components/jmapnote/channel/ChannelRemove'
import useChannelPermission from '../channel/hooks/useChannelPermission'
import { ListItemDesc } from 'components/design'
import SettingsExcelIcon from 'components/jmapnote/settings/SettingsExcelIcon'
import styled from 'styled-components'

const StyledIcon = styled.i`
  font-size: 1.25rem;

  ${({ type }) => {
    if (type === 'user')
      return `
      color: #737cff;
    `
    if (type === 'folder')
      return `
      color: #ffb239;
    `
  }}
`

const SettingsContainer = () => {
  const history = useHistory()
  const { channelSrl, folderSrl } = useNoteMatch()
  // const channelInfo = useSelector((state) => state.channel.channelInfo)
  const permission = useChannelPermission()

  const goToInvitepage = useCallback(() => {
    history.push({
      pathname: `/note/${channelSrl}/${folderSrl}/settings/invite`,
    })
  })

  const goToSettingsFolderPage = useCallback(() => {
    history.push({
      pathname: `/note/${channelSrl}/${folderSrl}/settings/folder`,
    })
  })

  const goToSettingsExcelPage = () => {
    history.push(`/note/${channelSrl}/${folderSrl}/settings/excel`)
  }

  return (
    <>
      <SettingsHead />

      <List title="채널 관리">
        {permission === 'owner' && (
          <ListItem
            prepend={
              <StyledIcon type="user" className="fad fa-users" />
            }
            chevron
            onClick={goToInvitepage}
          >
            <ListItemLabel>초대 관리</ListItemLabel>
          </ListItem>
        )}

        <ListItem
          prepend={
            <StyledIcon type="folder" className="fad fa-folders" />
          }
          chevron
          onClick={goToSettingsFolderPage}
        >
          <ListItemLabel>폴더 관리</ListItemLabel>
        </ListItem>

        {/* {channelInfo.is_private !== 'Y' && <ChannelRemove />} */}
      </List>

      <List title="부가 기능">
        <ListItem
          prepend={<SettingsExcelIcon />}
          chevron
          onClick={goToSettingsExcelPage}
        >
          <ListItemLabel>엑셀 업로드</ListItemLabel>
          <ListItemDesc small>
            엑셀 파일을 업로드하여 한 번에 매물을 등록할 수 있습니다.
          </ListItemDesc>
        </ListItem>
      </List>
      {permission === 'owner' && (
        <List title="기타">
          <ListItem chevron>
            <a
              href="https://forms.gle/mp6oU3PDaJqDfM2N7"
              _target="blank"
            >
              <ListItemLabel>불편신고</ListItemLabel>
              <ListItemDesc small>
                사용자의 경험 개선을 위한 아이디어 또는 의견을
                들려주세요.
              </ListItemDesc>
            </a>
          </ListItem>
          <ChannelRemove />
        </List>
      )}
    </>
  )
}

export default SettingsContainer
