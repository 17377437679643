import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Skeleton, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { getNohuMasters, getNohuReview } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import SEOHead from 'components/common/SEOHead'
import SummarySizeReviewRent from 'components/summary/SummarySizeReview/SummarySizeReviewRent'
import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import SummaryNohuArea from 'components/summary/SummaryNohu/SummaryNohuArea'
import SummaryNohuCost from 'components/summary/SummaryNohu/SummaryNohuCost'
import SummaryNohu3DModel from 'components/summary/SummaryNohu/SummaryNohu3DModel'
import { Button, Confirm, Empty, Modal } from 'components/design'
import SummarySizeReviewFooter from 'components/summary/SummarySizeReview/SummarySizeReviewFooter'
import SummaryNohuToggle from 'components/summary/SummaryNohu/SummaryNohuToggle'
import { accessibleGroups, baseCenterPoint } from 'Constants'
import MapContext from 'contexts/map'
import SummaryNohuMasters from 'components/summary/SummaryNohu/SummaryNohuMasters'
import SummaryNohuRent from 'components/summary/SummaryNohu/SummaryNohuRent'
import { CustomUl } from 'components/summary/SummarySizeReview/SummarySizeReviewStyle'
import moment from 'moment'

const SkeletonWrap = styled.div`
  padding: 1rem;
`

const EmptyWrap = styled.div`
  margin: 3rem 0;
`

const SummaryNohu = () => {
  const history = useHistory()
  const [isInfoHide, setIsInfoHide] = useState(true)
  const [isMine, setIsMine] = useState(false)
  const [isRentalAll, setIsRentalAll] = useState(true)

  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  const { sub_addresses, building } = useSelector(
    (state) => state.summary.summaryInfo,
  )

  const {
    state: { map, viewState },
  } = useContext(MapContext)

  const [onRequest, loading, data] = useAxios(getNohuReview)
  const [onRequestMasters, loadingMasters, dataMasters] =
    useAxios(getNohuMasters)

  useEffect(() => {
    if (
      !loginInfo?.other_groups?.some((item) =>
        accessibleGroups.includes(item),
      )
    ) {
      confirmNohu('접근이 불가한 페이지입니다')
      return
    }

    onRequest({
      address_ids: sub_addresses.concat(currentAddressId).join(','),
    })
  }, [currentAddressId])

  const onClickClose = () => {
    history.replace(`/${currentAddressId}`)
  }

  useEffect(() => {
    if (!data || !data.result || Object.keys(data.result).length == 0)
      return

    if (data.result.status.code != '00') {
      confirmNohu(
        '선택하신 건물이 노후주택 대수선 분석 조건에 부합하지 않습니다. 다른 건물을 선택해 주세요. 이용에 불편을 드려 죄송합니다.',
      )
    }
  }, [data])

  const confirmNohu = (msg) => {
    Confirm.info({
      title: '노후주택 대수선 설계에 실패하였습니다.',
      content: msg,
      onOk: () => history.replace(`/${currentAddressId}`),
      onCancel: () => history.replace(`/${currentAddressId}`),
      cancelHide: true,
    })
  }

  return (
    <>
      <SEOHead />
      <ExternalLayer title="노후주택 대수선" onClose={onClickClose}>
        {loading ? (
          <SkeletonWrap>
            <Empty
              image={
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        color: theme.colors.gray[500],
                        fontSize: '2rem',
                        marginTop: '2.5rem',
                      }}
                      spin
                    />
                  }
                />
              }
              description={
                <span>
                  선택하신 토지의 대수선을 계획 중 입니다. <br />
                  최대 1분이 소요됩니다.
                </span>
              }
            />
            <br />
            <Skeleton active />
          </SkeletonWrap>
        ) : (
          <>
            {data &&
            data?.result?.buildings &&
            data.result.extend_yn ? (
              <>
                <SummaryNohu3DModel data={data} />
                <SummaryNohuArea
                  refresh={data.result?.refresh}
                  buildings={data.result?.buildings}
                  law_info={data.result?.law_info}
                />
                <SummaryNohuToggle
                  isMine={isMine}
                  setIsMine={setIsMine}
                  isRentalAll={isRentalAll}
                  setIsRentalAll={setIsRentalAll}
                />
                <SummaryNohuCost
                  isMine={isMine}
                  isRentalAll={isRentalAll}
                  cost={data.result?.buildings[0].cost_info}
                  investment={data.result?.buildings[0].investment_info}
                  setIsInfoHide={setIsInfoHide}
                />
                <SummaryNohuRent
                  data={data}
                  setIsInfoHide={setIsInfoHide}
                  type="nohu"
                  isRentalAll={isRentalAll}
                />
                <SummaryNohuMasters />
                <SummarySizeReviewFooter type="nohu" />
                <Modal
                  title="노후주택 대수선 계획"
                  visible={!isInfoHide}
                  onOk={() => {
                    setIsInfoHide(true)
                  }}
                  onCancel={() => {
                    setIsInfoHide(true)
                  }}
                  okText="확인"
                  cancelText="취소"
                  cancelButtonProps={{ style: { display: 'none' } }}
                  footer={null}
                >
                  <CustomUl>
                    <li>
                      부동산 매입 비용은 JOOTEK 추정가를 참고하였습니다.
                    </li>
                    <li>
                      임대료는 실거래가 데이터 및 자체 수집 데이터를
                      분석하여 계산하였습니다.
                    </li>
                    <li>
                      해당 수치들은 빅데이터 분석을 통한 통계적 추정
                      값이며 실제 거래금액과는 차이가 있을 수 있습니다.
                    </li>
                    <li>
                      참고용으로 제공되는 정보이므로 이를 이용함으로
                      발생하는 어떤 손실에 대해서도 (주)주택은 책임이
                      없습니다.
                    </li>
                  </CustomUl>
                </Modal>
              </>
            ) : (
              <EmptyWrap>
                <Empty>
                  노후주택 대수선 조건에 부합하지 않는 건물이에요
                  <br />
                  다른 건물로 시도해 주세요
                </Empty>
              </EmptyWrap>
            )}
          </>
        )}
      </ExternalLayer>
    </>
  )
}

export default SummaryNohu
