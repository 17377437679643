import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 세대당 주차 대수
 */
const NoteAdColumnParkingCount = ({ desc }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const value = watch('article.parkingPerHousehold')

  return (
    <ColumnSet half>
      <ColumnTitle>세대당 주차대수</ColumnTitle>
      <ColumnVal gap={10}>
        <div>
          <InputText
            width={InputRemoveAppendWidth}
            defaultValue={value}
            type="number"
            {...register('article.parkingPerHousehold')}
            onChange={(e) => {
              onUpdate(
                'article.parkingPerHousehold',
                e.target.value.replaceAll(',', ''),
              )
            }}
            append={'대'}
          />
        </div>
        <ValidateWarn>
          {errors?.article?.parkingPerHousehold?.message}
        </ValidateWarn>
        {desc && <Desc>* 소숫점 둘째자리까지 허용</Desc>}
      </ColumnVal>
    </ColumnSet>
  )
}

const Desc = styled.div`
  color: ${theme.colors.tint.danger};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
`

export default NoteAdColumnParkingCount
