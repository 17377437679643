import { useEffect, useState } from 'react'
import { Input } from 'components/design'
import styled from 'styled-components'
import { Button } from 'components/design'
import { Select } from 'components/design'

const StyledItem = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`

const StyledInputWrap = styled.div`
  flex: 1;
  margin-right: 0.5rem;
`
const NoteContactFormNumber = ({
  item,
  index,
  onChange,
  setNumbers,
  inputValue,
}) => {
  const [number, setNumber] = useState(null)
  const [label, setLabel] = useState(null)

  useEffect(() => {
    setNumber(item?.number)
    setLabel(getLabel(item?.label))
  }, [item])

  const getLabel = (label) => {
    var pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/

    if (pattern.test(label)) return label

    const result = {
      mobile: '휴대전화',
      home: '집',
      work: '직장',
      other: '기타',
    }

    return result[label] ?? '기타'
  }

  const onRemove = () => {
    setNumbers((prev) => prev.filter((e) => e.number !== number))
  }

  return (
    <StyledItem>
      {number !== null && (
        <StyledInputWrap>
          <Input
            type="tel"
            placeholder="번호"
            expand
            defaultValue={inputValue || number}
            onChange={(e) => {
              onChange({ index, number: e.target.value, label })
            }}
          />
        </StyledInputWrap>
      )}

      {label !== null && (
        <StyledInputWrap>
          <Select
            border="blue"
            value={label}
            placeholder="라벨"
            onChange={(e) =>
              onChange({ index, number, label: e.target.value })
            }
            expand
          >
            <option value="집">집</option>
            <option value="휴대전화">휴대전화</option>
            <option value="직장">직장</option>
            <option value="기타">기타</option>
          </Select>
        </StyledInputWrap>
      )}

      <Button size="small" circle onClick={() => onRemove()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M18 12H6"
          />
        </svg>
      </Button>
    </StyledItem>
  )
}

export default NoteContactFormNumber
