import { Modal } from 'components/design'
import NoteDonghoDanjiSearch from './danjiAdd/NoteDonghoDanjiSearch'
import NoteDonghoHasDanji from './danjiAdd/NoteDonghoHasDanji'
import { useSelector } from 'react-redux'

const NoteDonghoDanjiAddModal = ({ onCancel, searchRefresh }) => {
  const visibleAddModal = useSelector(
    (state) => state.noteDongho.visibleAddModal,
  )

  return (
    <Modal
      title="단지 설정"
      visible={visibleAddModal}
      onCancel={onCancel}
      okText="삭제"
      footer={null}
      overflowVisible
      fullheight
      flex
    >
      <NoteDonghoDanjiSearch
        closeModal={onCancel}
        searchRefresh={searchRefresh}
      />
      <NoteDonghoHasDanji searchRefresh={searchRefresh} />
    </Modal>
  )
}

export default NoteDonghoDanjiAddModal
