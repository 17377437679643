import theme, { media } from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import NoteMainWrapper from '../style/NoteMainWrapper'
import { MaxWidthWrapper } from '../style/NoteStyle'
import Scrollbars from 'react-custom-scrollbars-2'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import NotePaymentAllInOneCancelConfirmStep1 from './NotePaymentAllInOneCancelConfirmStep1'
import NotePaymentAllInOneCancelConfirmStep2 from './NotePaymentAllInOneCancelConfirmStep2'

/**
 * 올인원 전용 요금제 해지 confirm 모달
 * @param visible
 * @param onCanel 취소
 * @param onOk 취소 결제 호출
 * @param toFree 올인원 -> 프리인지
 * @param isRefundable 환불 가능 상태
 */
const NotePaymentAllInOneCancelConfirm = ({
  visible = false,
  onCancel,
  onOk,
  toFree,
  isRefundable,
}) => {
  const [step, setStep] = useState(1)

  /**
   * 뒤로가기 제어 추가함
   */
  useEffect(() => {
    if (!visible) return

    history.pushState(null, '', location.href)
    window.addEventListener('popstate', () => onCancel(false))

    return () =>
      window.removeEventListener('popstate', () => onCancel(false))
  }, [visible])

  return (
    <StyledNoteMainWrapper gray single singleM>
      <Scrollbars autoHide>
        <StyledMaxWidthWrapper>
          {step == 1 && (
            <NotePaymentAllInOneCancelConfirmStep1
              onCancel={onCancel}
              setStep={setStep}
              toFree={toFree}
            />
          )}
          {step == 2 && (
            <NotePaymentAllInOneCancelConfirmStep2
              toFree={toFree}
              cancelFn={onOk}
              isRefundable={isRefundable}
            />
          )}
        </StyledMaxWidthWrapper>
      </Scrollbars>
    </StyledNoteMainWrapper>
  )
}

const StyledNoteMainWrapper = styled(NoteMainWrapper)`
  height: 100%;
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.base.white};
  padding: 60px 40px 100px 40px;
  margin: 0 auto;
  height: auto;
  min-height: 100%;

  ${media.mediumM`
    width: 100%;
    padding: 60px 20px 100px 20px;
  `}
`

export default NotePaymentAllInOneCancelConfirm
