import { getPropertyFilter } from 'lib/api/notev2/filterApi'
import useAxios from 'lib/hooks/useAxios'
import { setResetNotePropertyFilter } from 'modules/notev2'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const NoteListPropertyFilterContainer = ({ children }) => {
  const [onRequest, , data, ,] = useAxios(getPropertyFilter)
  const [count, setCount] = useState()
  const dispatch = useDispatch()

  const match = useRouteMatch([
    '/note/v2/notes/:channelSrl',
    '/note/v2/notes/:channelSrl/:addressSrl/:noteSrl',
  ])

  const searchAndSetPropertyFilters = () => {
    if (!match?.params?.channelSrl) return
    onRequest({ channel_srl: match.params.channelSrl })
  }

  useEffect(() => {
    if (!data) return
    dispatch(setResetNotePropertyFilter(data.result?.property || {}))
    setCount(data.result?.total_count)
  }, [data])

  return children({
    searchAndSetPropertyFilters,
    filtersCount: count,
  })
}
export default NoteListPropertyFilterContainer
