import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledList = styled.ul`
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  padding: 0 0 1.25rem;
  margin: -0.5rem;
`

const SummaryProjectList = ({ children }) => {
  return <StyledList>{children}</StyledList>
}

export default SummaryProjectList
