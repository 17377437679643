import {
  Button,
  Input,
  ListItemDivider,
  Modal,
} from 'components/design'
import { forwardRef } from 'react'
import styled from 'styled-components'

/**
 * 주소 검색 모달
 * 매물 3뎁스 주소 편집, 계정관리에서 사용중
 * @returns
 */
const NoteSearchAddressModal = (
  { visible, searchAddress, handleCancel, query, setQuery, list },
  ref,
) => {
  return (
    <Modal
      visible={visible}
      title="주소 검색"
      onCancel={handleCancel}
      footer={null}
      hiddenClose
    >
      <SearchArea>
        <Input
          type="search"
          placeholder={'주소명으로 검색해보세요.'}
          ref={ref}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onEnter={searchAddress}
          color="blue"
        />
        <Button
          color={query ? 'blue' : 'gray'}
          onClick={searchAddress}
          disabled={!query}
          size="large"
        >
          <i className="fa fa-search" />
        </Button>
      </SearchArea>
      <>
        <ListItemDivider />
        {list}
      </>
    </Modal>
  )
}

const SearchArea = styled.div`
  display: flex;
  width: 100%;

  & > div {
    flex: 1;
    margin-right: 10px;
    input {
      width: 100%;
    }
  }
`
export default forwardRef(NoteSearchAddressModal)
