import {
  Card,
  CardBody,
  CardHeader,
  CardMore,
  CardTitle,
} from 'components/design'
import { useEffect, useMemo, useState } from 'react'
import CardFold from 'components/design/Card/CardFold'
import SummaryFloorList from './SummaryFloorList'
import useViewMore from 'lib/hooks/useViewMore'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'

/**
 * 층별 정보
 */
const SummaryFloor = ({ selectedBuildingFloors }) => {
  const {
    viewCnt,
    viewMore,
    isOverflow,
    setViewMore,
    updateViewOverFlow,
    onClickMore,
    onClickFold,
  } = useViewMore()

  useEffect(() => {
    if (!selectedBuildingFloors) return
    setViewMore(selectedBuildingFloors.length > 3)
  }, [selectedBuildingFloors])

  const computeFloors = (floors) => {
    let data = []

    if (floors.length === 0) return data

    floors.forEach((e, index) => {
      const result = {
        ...e,
      }

      // 현재 아이템이 첫번째일 경우 push만 함
      if (index === 0) {
        data.push([result])
        return
      }

      const beforeItemIndex = data.length - 1
      const beforeItem = data[beforeItemIndex][0]

      // 이전 아이템과 floor_number를 비교하여 동일할 경우 이전 아이템과 병합함
      if (
        e.floor_number?.toString() ===
        beforeItem.floor_number?.toString()
      ) {
        data[beforeItemIndex]?.push(result)

        return
      }

      data.push([result])
    })

    return data
  }

  const floors = useMemo(
    () => computeFloors(selectedBuildingFloors),
    [selectedBuildingFloors],
  )

  useUpdateEffect(() => {
    updateViewOverFlow(floors)
  }, [viewCnt])

  return (
    <Card>
      <CardHeader>
        <CardTitle>층별 정보</CardTitle>
      </CardHeader>

      <CardBody mb={viewMore ? 'Y' : 'N'}>
        <SummaryFloorList viewCnt={viewCnt} data={floors} />
        {viewMore && <CardMore onClick={onClickMore} />}
        {isOverflow && <CardFold onClick={onClickFold} />}
      </CardBody>
    </Card>
  )
}

export default SummaryFloor
