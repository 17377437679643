import theme from 'lib/styles/theme'
import priceFormat from 'lib/utils/priceFormat'
import moment from 'moment'
import styled from 'styled-components'

const SummaryMultiHousingListItem = ({ date, price }) => {
  return (
    <Item>
      <div>{date ? moment(date).format('YYYY.MM.DD') : ''}</div>
      <Price>
        <span>{priceFormat(price)}</span>
        <span>(원)</span>
      </Price>
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  line-height: 150%;
  padding: 8px 0;
  border-bottom: 1px solid ${theme.colors.gray[100]};
`

const Price = styled.div`
  display: flex;
  align-items: flex-end;

  span {
    &:last-child {
      margin-left: 1px;
      line-height: 150%;
      font-size: 11px;
      font-weight: 400;
      color: ${theme.colors.gray[600]};
    }
  }
`

export default SummaryMultiHousingListItem
