import { useCallback, useState, useEffect, useRef } from 'react'
import useAxios from '../useAxios'
import { modifyAddress } from 'lib/api/notev2/listApi'
import {
  resetAfterAddressChange,
  updateAfterAddressChange,
} from 'lib/utils/notev2/gridData'
import { useNoteMarker } from '../useNoteMarker'
import { useSelector } from 'react-redux'
import useSetActiveMarker from './useSetActiveMarker'
import { useDispatch } from 'react-redux'
import { resetSpecificOpenedMarker } from 'modules/note'

/**
 * 매물 주소 수정
 *
 * * onModifyAddress: 수정 함수
 * * data: 결과
 * * isError: 에러가 났는지 여부
 *
 */
function useModifyAddress() {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [onRequest, , data, isError, onReset] = useAxios(modifyAddress)
  // const [isError, setIsError] = useState(false)
  const [rowNode, setRowNode] = useState(null)
  const [callbackFn, setCallbackFn] = useState(null)
  const [isResetNoteGridData, setIsResetNoteGridData] = useState(false) //유효하지 않은 주소로 저장했으면 그리드 데이터를 다 초기화 시켜야함
  const [validAddress, setValidAddress] = useState(null)
  const [invalidAddr, setInvalidAddr] = useState('')
  const { searchNoteMarkers } = useNoteMarker()
  const { openAddedMarker } = useSetActiveMarker()
  const [noteSrl, setNoteSrl] = useState(null)

  const dispatch = useDispatch()

  /**
   * 노트 주소 수정
   * @param channel_srl 채널 srl
   * @param address_srl 주소 srl
   * @param address_id 주소 id
   */
  const onModifyAddress = useCallback(
    ({
      channel_srl,
      address_srl,
      address_id,
      note_srl,
      currentRowNode,
      invalid_addr_name,
      isInvalidAddr,
      callbackFn,
      validAddressInfo,
      dong,
      ho,
      input_addr,
    }) => {
      if (channelInfo?.member_type == 'readonly') return
      if (validAddressInfo) {
        setValidAddress(validAddressInfo)
      }
      if (currentRowNode) {
        setRowNode(currentRowNode)
      }
      if (callbackFn) {
        setCallbackFn(() => callbackFn)
      }

      //유효하지 않은 주소로 저장했는지
      if (isInvalidAddr) {
        setIsResetNoteGridData(true)
        setInvalidAddr(invalid_addr_name)
      } else {
        setIsResetNoteGridData(false)
        setInvalidAddr('')
      }

      if (note_srl) {
        setNoteSrl(note_srl)
      }

      //노트 저장 api
      onRequest({
        channel_srl,
        address_srl,
        address_id,
        note_srl,
        invalid_addr_name: invalid_addr_name,
        display_address: validAddressInfo?.display_address,
        dong: validAddressInfo?.dong ? validAddressInfo.dong : dong,
        ho: validAddressInfo?.ho ? validAddressInfo.ho : ho,
        exclusive_area: validAddressInfo?.exclusive_area,
        contract_area: validAddressInfo?.contract_area,
        property_type: validAddressInfo?.property_type,
        input_addr: input_addr,
      })
    },
    [],
  )

  useEffect(() => {
    if (isError) {
      //@ONLYTEST:
      console.log('modifyAddress 에러')

      setRowNode(null)
      setCallbackFn(null)
      onReset()
      return
    }
  }, [isError])

  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.log('modifyAddress 에러')

      setRowNode(null)
      setCallbackFn(null)
      onReset()
      return
    }

    //노트 마커 refresh
    searchNoteMarkers()

    if (rowNode) {
      if (isResetNoteGridData) {
        //유효하지 않은 주소 - 그리드데이터 초기화
        resetAfterAddressChange({
          currentRowNode: rowNode,
          display_address: invalidAddr,
          invalid_addr_name: invalidAddr,
        })

        //active 마커 초기화
        dispatch(resetSpecificOpenedMarker())
      } else {
        const updataData = Object.assign({}, validAddress, {
          type: 'update',
          dong: validAddress.dong,
          ho: validAddress.ho,
          invalid_addr_name: '',
          address_srl: data.result.address_srl,
          address_jibun: validAddress.full_address,
          property_type: validAddress.property_type,
          centerpoint:
            validAddress.latitude && validAddress.longitude
              ? [validAddress.latitude, validAddress.longitude]
              : null,
        })

        //그리드 데이터 업데이트
        updateAfterAddressChange({
          currentRowNode: rowNode,
          ...updataData,
        })

        setRowNode(null)
        setValidAddress(null)
        setIsResetNoteGridData(false)
        setInvalidAddr('')

        if (callbackFn) {
          callbackFn()
          setCallbackFn(null)
        }

        //방금 수정한 매물은 필터 상관없이 무조건 active
        openAddedMarker(
          Object.assign({}, data.result, { note_srl: noteSrl }),
        )
      }
    }
    setNoteSrl(null)
  }, [data])

  return [onModifyAddress, data, isError]
}

export default useModifyAddress
