import { LoadingOutlined } from '@ant-design/icons'
import { Skeleton, Spin } from 'antd'
import theme, { media } from 'lib/styles/theme'
import React from 'react'
import { Suspense } from 'react'
import styled from 'styled-components'

const NoteDonghoLegend = ({
  legend,
  activeTab,
  palette,
  anotherLegend,
}) => {
  const NoteDonghoLegendTab1 = React.lazy(() =>
    import('./NoteDonghoLegendTab1'),
  )
  const NoteDonghoLegendTab2 = React.lazy(() =>
    import('./NoteDonghoLegendTab2'),
  )

  const NoteDonghoLegendTab3 = React.lazy(() =>
    import('./NoteDonghoLegendTab3'),
  )

  const NoteDonghoLegendTab4 = React.lazy(() =>
    import('./NoteDonghoLegendTab4'),
  )

  return (
    <Suspense>
      <LegendWrapper>
        {activeTab == 1 && legend && (
          <NoteDonghoLegendTab1
            data={legend}
            anotherLegend={anotherLegend}
          />
        )}
        {activeTab == 2 && (
          <NoteDonghoLegendTab2
            palette={palette}
            anotherLegend={anotherLegend}
          />
        )}
        {activeTab == 3 && (
          <NoteDonghoLegendTab3
            palette={palette}
            anotherLegend={anotherLegend}
          />
        )}
        {activeTab == 4 && (
          <NoteDonghoLegendTab4
            palette={palette}
            anotherLegend={anotherLegend}
          />
        )}
      </LegendWrapper>
    </Suspense>
  )
}
const LegendWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  position: sticky;
  top: 0;
  background-color: ${theme.colors.base.white};
  z-index: 1;

  ${media.mediumM`
    position: initial;
  `}
`

export default NoteDonghoLegend
