import { Button } from 'components/design'
import { Help } from 'components/summary/style/SummaryStyle'
import styled from 'styled-components'

const SummaryLegalInfoLink = ({ law }) => {
  return (
    <Wrapper>
      <Help>
        <i className="fal fa-info-circle" />
        현재 시점의 자세한 사업지구 정보는 해당 지자체를 통해 확인하시기
        바랍니다.
      </Help>
      {law.url && (
        <a href={law.url} target="_blank">
          <Button expand color="gray">
            도시계획조례
          </Button>
        </a>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > div {
    margin-bottom: 10px;
  }
`

export default SummaryLegalInfoLink
