import { useEffect, useState } from 'react'
import { Modal } from 'components/design'

const ConfirmDialog = (props) => {
  const [visible, setVisible] = useState(false)
  const { onCancel, onOk, content } = props

  useEffect(() => {
    setVisible(true)
  }, [])

  const onClickCancel = () => {
    if (onCancel) onCancel()
    setVisible(false)
  }

  const onClickOk = () => {
    if (onOk) onOk()
    setVisible(false)
  }

  return (
    <Modal
      {...props}
      visible={visible}
      onCancel={onClickCancel}
      onOk={onClickOk}
      width={400}
      hiddenClose
    >
      {content}
    </Modal>
  )
}

export default ConfirmDialog
