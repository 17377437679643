import SummrayEstimatePrice from 'components/summary/SummaryEstimatePrice/SummrayEstimatePrice'
import { useSelector } from 'react-redux'
import { forwardRef } from 'react'
import SummaryRealPriceGraph from 'components/summary/SummaryRealPrice/SummaryRealPriceGraph'
import SummaryRealPrice from './SummaryRealPrice'
import SummaryAptPriceContainer from './SummaryAptPriceContainer'

/**
 * 추정가, 실거래가 등 가격
 *
 * * 렌더링 여부
 * * 공동주택: O
 * * 공동주택 아님 : O
 * * 공동 주택인지 판단하여 그에 맞는 컴포넌트 렌더링
 */
const SummaryPricesContainer = ({}, ref) => {
  const isApt = useSelector((state) => state.summary.isApt) // 공동 주택 여부
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  const { apt: aptTabRef } = ref

  //공동 주택 아님
  if (!isApt)
    return (
      <>
        <SummrayEstimatePrice
          prices={summaryInfo.prices}
          subAddresses={summaryInfo.sub_addresses}
        />
        <SummaryRealPriceGraph
          prices={summaryInfo.prices}
          landArea={summaryInfo.land.land_area}
        />
        <SummaryRealPrice
          table={summaryInfo.table}
          landArea={summaryInfo.land.land_area}
          currentAddressId={currentAddressId}
        />
      </>
    )

  //공동 주택임
  if (isApt)
    return (
      <div ref={aptTabRef}>
        <SummaryAptPriceContainer
          areaTypes={summaryInfo.area_types}
          building={summaryInfo.building}
          addressId={currentAddressId}
          visiblePy
          noTitle={false}
        />
      </div>
    )
}

export default forwardRef(SummaryPricesContainer)
