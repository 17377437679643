import MapContext from 'contexts/map'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import {
  Append,
  AreaInput,
  AreaItem,
  TextWrapper,
} from './CustomAreaCellCommon'
import styled from 'styled-components'
import {
  CustomSingleCellInputWrapper,
  CustomSingleCellStyle,
  PlaceHolder,
} from 'components/jmapnotev2/style/NoteStyle'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import theme from 'lib/styles/theme'
import { numberWithCommas } from 'utils'
import {
  addEndCursorKeyEvent,
  addSelectAllKeyEvent,
  addStartCursorKeyEvent,
} from 'lib/utils/notev2/gridUtil'
import useModifyAd from 'lib/hooks/notev2/useModifyAd'

/**
 * 그리드 셀 면적(공급)
 * @param type 광고면 ad
 */
const CustomAreaCellContract = ({ node, exclusive_area, type }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)
  const [onRequestModifyNote, isErrorModifyNote] = useModifyNote()
  const [onModifyAd] = useModifyAd()

  const [area2, setArea2] = useState(0)

  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
  } = useCustomSingleCell(node?.data?.status)

  useEffect(() => {
    setArea2(exclusive_area)
  }, [exclusive_area])

  /**
   * 매물종류에 따라 첫번째 면적값(공급면적 or 연면적 or 대지면적) 세팅
   */

  const onChangeArea2 = useCallback(
    (e) => {
      setArea2(
        isSquareMeter
          ? e.floatValue
          : Math.round(e.floatValue * 3.3057),
      )
    },
    [isSquareMeter],
  )

  const onBlurArea2 = useCallback(
    (e) => {
      const value = e.target.value
        .replaceAll(',', '')
        .replaceAll('평', '')
        .replaceAll('㎡', '')
      const valueParam = isSquareMeter
        ? value
        : Math.round((value || 0) * 3.3057 * 100) / 100

      const newData = { exclusive_area: valueParam }
      if (type == 'ad') {
        onModifyAd({
          data: node.data,
          updateData: newData,
          currentRowNode: node,
        })
      } else {
        onRequestModifyNote({
          data: node.data,
          updateData: newData,
          currentRowNode: node,
        })
      }
      setIsEditable(false)
    },
    [isSquareMeter, node.data],
  )

  const onKeyDownInput = (e) => {
    addSelectAllKeyEvent(e)
    addStartCursorKeyEvent(e)
    addEndCursorKeyEvent(e)
  }

  return (
    <>
      <div>/</div>
      <Wrapper
        tabIndex={0}
        onDoubleClick={onDoubleClickSingleCell}
        onKeyDown={onKeyDownSingleCell}
      >
        {isEditable ? (
          <CustomSingleCellInputWrapper>
            {/* 수정모드 */}
            <AreaInputContract
              tabIndex={0}
              onKeyDown={onKeyDownInput}
              placeholder={'전용'}
              type="text"
              name="exclusive_area"
              value={
                Number(area2)
                  ? isSquareMeter
                    ? Math.round(Number(area2))
                    : Math.round(Number(area2) / 3.3057)
                  : ''
              }
              onValueChange={onChangeArea2}
              onBlur={onBlurArea2}
              thousandSeparator={true}
              autoFocus={true}
            />
            <Append tabIndex={-1} ishalf={'N'}>
              {isSquareMeter ? '㎡' : '평'}
            </Append>
          </CustomSingleCellInputWrapper>
        ) : (
          <AreaView>
            {/* 조회모드 */}
            <TextWrapper>
              {Number(area2) ? (
                isSquareMeter ? (
                  numberWithCommas(Math.round(Number(area2)))
                ) : (
                  numberWithCommas(Math.round(Number(area2) / 3.3057))
                )
              ) : (
                <PlaceHolder>전용</PlaceHolder>
              )}
              {Number(area2) ? (isSquareMeter ? '㎡' : '평') : ''}
            </TextWrapper>
          </AreaView>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 50%;
  height: 100%;
  ${CustomSingleCellStyle}
`

const AreaView = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 5px;
  padding-left: 0;
`

const AreaInputContract = styled(AreaInput)`
  padding: 0px 18px 0px 5px;
`

export default CustomAreaCellContract
