import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import { feeOptions } from '../../optionsFee'
import React from 'react'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비 부과 정보 - 기타부과세부사유
 */
const NoteAdColumnFeeEtcTaxImposeReason = ({ type2 = false }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const list = feeOptions['기타부과세부내역']

  return (
    <ColumnSet>
      <ColumnTitle required>세부내역타입</ColumnTitle>
      <ColumnVal gap={12}>
        {type2 ? (
          <RadioButton
            size="small"
            fit
            label="정액관리비이지만 중개의뢰인이 세부내역 미고지한 경우"
            {...register(
              'administrationCostInfo.etcFeeDetails.detailCodeType',
            )}
            value={98}
            onChange={(e) => {
              onUpdate(
                'administrationCostInfo.etcFeeDetails.detailCodeType',
                e.target.value,
              )
            }}
            name="fee_direct_input_content"
          />
        ) : (
          Object.entries(list)
            .sort((a, b) => a[0] - b[0])
            .map(([key, value], i) => {
              return (
                <React.Fragment key={i}>
                  <RadioButton
                    size="small"
                    fit
                    label={value}
                    {...register(
                      'administrationCostInfo.etcFeeDetails.detailCodeType',
                    )}
                    value={key}
                    onChange={(e) => {
                      onUpdate(
                        'administrationCostInfo.etcFeeDetails.detailCodeType',
                        e.target.value,
                      )
                    }}
                    name="fee_direct_input_content"
                  />
                </React.Fragment>
              )
            })
        )}
        <ValidateWarn>
          {
            errors?.administrationCostInfo?.etcFeeDetails
              ?.detailCodeType?.message
          }
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeeEtcTaxImposeReason
