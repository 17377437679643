import styled from 'styled-components'

import theme from 'lib/styles/theme'
import SearchResultItem from './SearchResultItem'

const Wrap = styled.div`
  position: relative;
  height: calc(100% - ${theme.base.headerHeight});
  padding-top: 1.25rem;
`
const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const SearchResultList = ({ searchResults, onClickItem }) => {
  return (
    <Wrap>
      <StyledList>
        {searchResults.map((item, index) => (
          <SearchResultItem
            key={index}
            item={item}
            onClickItem={onClickItem}
          />
        ))}
      </StyledList>
    </Wrap>
  )
}

export default SearchResultList
