import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import NoteListGrid from './NoteListGrid'
import React, { forwardRef, useRef } from 'react'
import { Route } from 'react-router-dom'
import NoteDetail from 'pages/jmapnotev2/NoteDetail'
import { ContentWrapper, Header } from '../style/NoteStyle'
import NoteListHeader from './NoteListHeader'
import Swipe from 'react-easy-swipe'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteListMobile from './NoteListMobile'

const NoteList = (
  {
    setResizable,
    resetLayer,
    onSwipeStart,
    onSwipeMove,
    onSwipeEnd,
    isOpenDetailRouter,
    searchAndSetPropertyFilters,
    search,
    bindSearch,
    resetSearch,
    onSearch,
    searchFnWithParam,
    searchFn,
    gridRowData,
    isLoading,
    clearSearch,
  },
  ref,
) => {
  const { listLayerRef, gridRef } = ref
  const isMobile = useIsMobile()

  return (
    <Wrapper>
      <StyledContentWrapper>
        {isLoading && <FullScreenLoading />}
        <StyledSwipe
          onSwipeStart={onSwipeStart}
          onSwipeMove={onSwipeMove}
          onSwipeEnd={onSwipeEnd}
        >
          <StyledHeader>
            <NoteListHeader
              search={search}
              bindSearch={bindSearch}
              onSearch={onSearch}
              searchFnWithParam={searchFnWithParam}
              searchFn={searchFn}
              clearSearch={clearSearch}
              ref={{
                listLayerRef: listLayerRef,
              }}
              setResizable={setResizable}
              resetSearch={resetSearch}
              resetLayer={resetLayer}
            />
          </StyledHeader>
        </StyledSwipe>
        {isMobile ? (
          <NoteListMobile
            type="note"
            data={gridRowData}
            onSwipeStart={onSwipeStart}
            onSwipeMove={onSwipeMove}
            onSwipeEnd={onSwipeEnd}
          />
        ) : (
          <NoteListGrid
            isLoading={isLoading}
            gridRowData={gridRowData}
            ref={{ gridRef, resizeLayerRef: listLayerRef }}
            onSwipeStart={onSwipeStart}
            onSwipeMove={onSwipeMove}
            onSwipeEnd={onSwipeEnd}
          />
        )}
        <Route
          path="/note/v2/notes/:channelSrl/:addressSrl/:noteSrl"
          render={() => (
            <NoteDetail
              mode="sheet"
              ref={gridRef}
              searchFn={searchFn}
              isOpenDetailRouter={isOpenDetailRouter}
              searchAndSetPropertyFilters={searchAndSetPropertyFilters}
            />
          )}
          exact
        />
      </StyledContentWrapper>
    </Wrapper>
  )
}

const StyledSwipe = styled(Swipe)`
  ${media.mediumS`
      transition: max-height 0.2s cubic-bezier(0.16, 1, 0.3, 1);
      max-height: 200px;
  `}
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;

  ${media.mediumS`
    
    height: calc(100% + 400px);
  `}
`

const StyledHeader = styled(Header)`
  padding-bottom: 0;
`
export default React.memo(forwardRef(NoteList))
