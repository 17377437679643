import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledCardMore = styled.a`
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 1.25rem;

  color: ${theme.colors.gray[800]};
  font-size: 1rem;
  font-weight: 500;
  border-top: 1px solid ${theme.colors.gray[200]};

  transition: 0.2s ease;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
    color: ${theme.colors.gray[600]};
  }

  &:hover {
    color: ${theme.colors.gray[900]};
    background-color: ${theme.colors.gray[50]};
  }
`

const CardMoreText = styled.div`
  flex: 1;
`

const CardMoreNative = ({ href, target, children }) => (
  <StyledCardMore href={href} target={target}>
    <CardMoreText>{children}</CardMoreText>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </StyledCardMore>
)

export default CardMoreNative
