import Container from 'components/base/Container/Container'
import Header from 'components/common/Header/Header'
import HeaderBack from 'components/common/Header/HeaderBack'
import HeaderBackAbsolute from 'components/common/Header/HeaderBackAbsolute'
import HeaderDivider from 'components/common/Header/HeaderDivider'
import NotificationContainer from 'containers/notification/NotificationContainer'
import useIsMobile from 'lib/hooks/useIsMobile'
// import HeaderMore from 'components/common/Header/HeaderMore';

const NotificationPage = ({
  foldBtn = true,
  mobileHeader = true,
  isNote = false,
  listData,
}) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile && mobileHeader && <HeaderBackAbsolute title="알림" />}
      <Container white absolute={!isMobile} highest foldBtn={foldBtn}>
        {(isNote || !isMobile) && (
          <Header prepend={<HeaderBack />}>
            알림
            <HeaderDivider />
          </Header>
        )}
        <NotificationContainer listData={listData} />
      </Container>
    </>
  )
}

export default NotificationPage
