import { Tag } from 'components/design'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import theme, { media } from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { options } from '../../options'
import React, { useEffect, useState } from 'react'
import { numberWithCommas } from 'utils'
import { Skeleton } from 'antd'
import { Tooltip } from 'react-tooltip'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
} from '../../form/NoteAdFormStyle'
import NoteAdColumnVerificationTypeDescN from './NoteAdColumnVerificationTypeDescN'
import NoteAdColumnVerificationTypeDescT from './NoteAdColumnVerificationTypeDescT'
import NoteAdColumnVerificationTypeDescD from './NoteAdColumnVerificationTypeDescD'

const verificationList = [
  'verification.type',
  'verification.name',
  'verification.cphone',
  'verification.cNaverId',
  'verification.ownerType',
  'verification.oname',
  'verification.ophone',
  'verification.otelecom',
  'verification.osex',
  'verification.oagree',
  'verification.registerUrl',
  'verification.registerUniqueNo',
  'verification.noRgbkVrfcReqYn',
  'verification.areaByBdbkVrfcReqYn',
  'verification.confirmdocUrl',
  'verification.referenceFileUrl',
  'verification.rdate',
  'verification.empty',
  'verification.shoot',
]

const NoteAdColumnVerificationType = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext()

  const verificationType = watch('verification.type')
  const verification_site_price = watch('verification_site_price')

  const adOptions = useSelector((state) => state.noteAd.adOptions)

  const [data, setData] = useState(null)

  useEffect(() => {
    if (!adOptions) return
    setData(adOptions.verifications)
  }, [adOptions])

  const onClickType = (value) => {
    verificationList.forEach((item) => {
      clearErrors(item)
    })
    onUpdate('verification.type', value)
  }

  return (
    <ColumnSet>
      <ColumnTitle>검증 방식 선택</ColumnTitle>
      <ColumnVal gap={12}>
        {data ? (
          <Wrapper>
            {Object.values(data).map(
              ({ rank, name, price, is_owner, is_event }, i) => {
                const value = Object.keys(data)[i]
                return (
                  <Block
                    key={i}
                    active={verificationType == value}
                    {...register('verification.type')}
                    onClick={() => onClickType(value)}
                    name="verification_type"
                  >
                    <TitleWrapper>
                      <BadgeWrapper>
                        <Badge>{rank}순위</Badge>
                        {is_owner == 'Y' && (
                          <Badge primary>
                            <i className="fas fa-badge-check"></i>
                            <span>집주인</span>
                          </Badge>
                        )}
                      </BadgeWrapper>
                      <Title>
                        {name}
                        {value == 'N' && (
                          <NoteAdColumnVerificationTypeDescN
                            type={value}
                          />
                        )}
                        {value == 'T' && (
                          <NoteAdColumnVerificationTypeDescT
                            type={value}
                          />
                        )}

                        {value == 'D' && (
                          <NoteAdColumnVerificationTypeDescD
                            type={value}
                          />
                        )}
                        {/* {['N', 'T', 'D'].includes(value) && (
                          <NoteAdColumnVerificationTypeDesc
                            type={value}
                          />
                        )} */}
                      </Title>
                    </TitleWrapper>
                    {is_event == 'Y' && (
                      <Event>원룸임대현장이벤트 적용</Event>
                    )}
                    <PriceWrapper>
                      {value == 'S' && (
                        <Sale>{numberWithCommas(price)}</Sale>
                      )}
                      {value == 'S' ? (
                        <Price>
                          {numberWithCommas(verification_site_price)}
                        </Price>
                      ) : (
                        <Price>{numberWithCommas(price)}</Price>
                      )}
                    </PriceWrapper>
                  </Block>
                )
              },
            )}
            <FormDesc>
              <li>
                법인매물은 모바일1, 모바일2로 네이버 등록 불가 입니다.
              </li>
            </FormDesc>
          </Wrapper>
        ) : (
          <Skeleton active />
        )}
      </ColumnVal>
    </ColumnSet>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;

  ${media.smallPc`
    gap: 16px;
  `}
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
  position: relative;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.blue[400]};
  font-size: 18px;
  font-weight: 700;
  line-height: 130%;

  &::after {
    content: '원';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${theme.colors.gray[900]};
  }
`

const Badge = styled.div`
  width: fit-content;
  padding: 4.5px 4px;
  border-radius: 4px;
  background: ${theme.colors.gray[150]};
  color: ${theme.colors.gray[750]};
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;

  span {
    margin-left: 3px;
  }

  ${({ primary }) =>
    primary &&
    `
    background-color: ${theme.colors.primary[50]};
    color: ${theme.colors.primary[600]};
  `}
`

const Event = styled.div`
  position: absolute;
  bottom: 36px;
  right: 12px;
  color: ${theme.colors.gray[600]};
  text-align: right;
  font-size: 11px;
  font-weight: 600;
  line-height: 150%;
`

const Sale = styled.div`
  color: ${theme.colors.gray[400]};
  font-size: 10px;
  font-weight: 700;
  line-height: 130%;
  text-decoration: line-through;
  word-break: keep-all;

  &::after {
    content: '원';
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 39px;
  position: relative;
  width: calc(calc(100% / 6) - 13.5px);
  padding: 12px;
  border: 1px solid ${theme.colors.gray[200]};
  outline: 2px solid transparent;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    border: 1px solid transparent;
    outline: 2px solid ${theme.colors.blue[300]};
  }

  ${({ active }) =>
    active &&
    `
    border: 1px solid transparent;
    outline: 2px solid ${theme.colors.blue[400]};
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${theme.colors.gray[100]};

    &:hover {
      border: 1px solid ${theme.colors.gray[200]};
      outline: 2px solid transparent;
    }


    ${Title}, ${Price}, ${Badge}, ${Event}, ${Sale} {
      color: ${theme.colors.gray[300]};

      &::after {
        color: ${theme.colors.gray[300]};
      }
    }
  `}

  ${media.smallPc`
    width: calc(calc(100% / 3) - 13.5px);
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
`

export default NoteAdColumnVerificationType
