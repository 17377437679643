import { Segment } from 'components/design'
import { SegmentItem } from 'components/design'
import { Tab } from 'components/design'
import { TabItem } from 'components/design'

const SummaryAptRPTabItem = ({
  currentType,
  onChangeCurrentTab,
  value,
}) => {
  return (
    <SegmentItem
      active={currentType === value}
      onClick={() => onChangeCurrentTab(value)}
    >
      {value}
    </SegmentItem>
  )
}

const SummaryAptRPTab = ({ currentType, onChangeCurrentTab }) => {
  return (
    <Segment size="small">
      <SummaryAptRPTabItem
        currentType={currentType}
        onChangeCurrentTab={onChangeCurrentTab}
        value="전체"
      />
      <SummaryAptRPTabItem
        currentType={currentType}
        onChangeCurrentTab={onChangeCurrentTab}
        value="매매"
      />
      <SummaryAptRPTabItem
        currentType={currentType}
        onChangeCurrentTab={onChangeCurrentTab}
        value="전세"
      />
      <SummaryAptRPTabItem
        currentType={currentType}
        onChangeCurrentTab={onChangeCurrentTab}
        value="월세"
      />
    </Segment>
  )
}

export default SummaryAptRPTab
