import { Modal } from 'components/design'

/**
 * 올인원 결제하기 클릭했을 때 본인인증하러 가라는 모달
 */
const NotePaymentAllInOneInduceModal = ({
  visible,
  onCancel,
  onOk,
}) => {
  return (
    <Modal
      visible={visible}
      title="올인원은 대표 공인중개사만 신청이 가능해요."
      onOk={onOk}
      onCancel={onCancel}
      okText="본인인증 후 결제하기"
      cancelText="취소"
      okButtonProps={{ color: 'blue' }}
    >
      올인원은 네이버부동산 매물 전송 서비스로 대표 공인중개사의
      본인인증이 필요합니다.
    </Modal>
  )
}

export default NotePaymentAllInOneInduceModal
