import NoteAdHeader from './NoteAdHeader'
import NoteAdList from './list/NoteAdList'
import NoteAdNoti from './NoteAdNoti'
import { useSelector } from 'react-redux'
import { Button, Empty } from 'components/design'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setAuthModalVisible } from 'modules/noteAd'
import NoteAdStatus from './NoteAdStatus'
import NoteAdStatusContainer from 'containers/notev2/NoteAdStatusContainer'

/**
 * 광고관리 메인
 */
const NoteAd = ({ onSwipeStart, onSwipeMove, onSwipeEnd }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const dispatch = useDispatch()

  const onClickAuth = () => {
    dispatch(setAuthModalVisible(true))
  }

  return (
    <NoteAdStatusContainer>
      {({ noti }) => {
        return (
          <>
            {noti}
            {loginInfo?.validated ? (
              <NoteAdList
                onSwipeStart={onSwipeStart}
                onSwipeMove={onSwipeMove}
                onSwipeEnd={onSwipeEnd}
              />
            ) : (
              <EmptyWRapper>
                <Empty>
                  본인인증을 한 회원만 이용할 수 있습니다.
                  <Button onClick={onClickAuth} color="blue">
                    본인인증 하기
                  </Button>
                </Empty>
              </EmptyWRapper>
            )}
          </>
        )
      }}
    </NoteAdStatusContainer>
  )
}

const EmptyWRapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;

  button {
    margin-top: 24px;
  }
`

export default NoteAd
