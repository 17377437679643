import { Modal } from 'components/design'
import { useSelector } from 'react-redux'
import ReactDOM from 'react-dom'
import NotePaymentAuthContent from 'components/jmapnotev2/NotePayment/NotePaymentAuthContent'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setAuthModalVisible } from 'modules/noteAd'
import theme, { media } from 'lib/styles/theme'

const NoteAuthModal = () => {
  const authModalVisible = useSelector(
    (state) => state.noteAd.authModalVisible,
  )

  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(setAuthModalVisible(false))
  }

  /**
   * 본인인증 완료 후 동작
   */
  const escapeAuth = () => {
    onClose()
  }

  const root = document.getElementById('modal')

  return ReactDOM.createPortal(
    <Modal
      customSize
      width={700}
      visible={authModalVisible}
      onCancel={onClose}
      footer={null}
      hiddenClose
      hiddenHeader
    >
      <Wrapper>
        <CustomHeader>
          <CloseButton onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </CloseButton>
        </CustomHeader>
        <NotePaymentAuthContent
          escapeAuth={escapeAuth}
          onClose={onClose}
          prevButton={false}
        />
      </Wrapper>
    </Modal>,
    root,
  )
}

const Wrapper = styled.div`
  margin-bottom: 6px;
`

const CustomHeader = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 20px;
`

const CloseButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  text-align: right;
  color: ${theme.colors.gray[700]};
  font-size: 1rem;

  background-color: $color-gray-100;
  border-radius: 8px;

  transition: background-color 250ms;

  &:hover {
    background-color: $color-gray-200;
  }

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  ${media.mediumS`
    width:24px;
    height:24px;
`}
`

export default NoteAuthModal
