import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { eachSmallRowHeight } from './note2Constants'

/**
 * 주소 저장 후 주소 grid update 양식 통일
 * @returns
 */
export const updateAfterAddressChange = ({
  currentRowNode,
  address_srl,
  property_type,
  note_srl,
  eupmyeondong,
  doro_address,
  total_floor_area,
  land_area,
  type,
  display_address,
  invalid_addr_name = '',
  dong,
  ho,
  address_id,
  regdate,
  exclusive_area,
  contract_area,
  building_area,
  address_jibun,
  land_type,
  direction,
  centerpoint,
  extra_vars,
  is_group_building,
  latitude,
  longitude,
  isAd,
  ad_srl,
  status,
}) => {
  const data = {
    addr: `${display_address}`,
    address_jibun: address_jibun,
    address_doro: doro_address,
    eupmyeondong: eupmyeondong,
    display_address: display_address,
    invalid_addr_name: invalid_addr_name,
    address_id: address_id,
    centerpoint: centerpoint,
    is_group_building: is_group_building,
    latitude,
    longitude,
  }

  //매물 신규 추가 후, note_srl과 등록일자 업데이트
  const insertNoteAfterData = { note_srl: note_srl, regdate: regdate }

  //insert시만 insert후 result extra_vars 값을 파라미터에 추가
  const extraVarsData = getObjectData('extra_vars', extra_vars)

  //수정 전 데이터가 있으면, 빈 값으로 덮어씌우지 않게 하도록 한다
  const addressSrlData = getObjectData('address_srl', address_srl) // 주소 srl
  const landAreaData = getObjectData('land_area', land_area, true) // 대지면적
  const exclusiveAreaData = getObjectData(
    'exclusive_area',
    exclusive_area,
    true,
  ) // 전용면적
  const totalFloorAreaData = getObjectData(
    'total_floor_area',
    total_floor_area,
    true,
  ) //연면적
  const contractAreaData = getObjectData(
    'contract_area',
    contract_area,
    true,
  ) //계약,공급면적
  const buildingAreaData = getObjectData(
    'building_area',
    building_area,
    true,
  ) // 건축면적
  const landTypeData = getObjectData('land_type', land_type) // 지목
  const directionData = getObjectData('direction', direction) // 방향
  const propertyTypeData = getObjectData('property_type', property_type) // 종류
  const dongData = getObjectData('dong', dong) // 동
  const hoData = getObjectData('ho', ho) // 호
  //광고관리용 ad_srl
  const adSrl = isAd
    ? Object.assign(
        {},
        getObjectData('ad_srl', ad_srl),
        getObjectData('status', status),
      )
    : null

  let newData

  if (type == 'insert') {
    newData = Object.assign(
      {},
      data,
      insertNoteAfterData,
      addressSrlData,
      landAreaData,
      exclusiveAreaData,
      totalFloorAreaData,
      contractAreaData,
      buildingAreaData,
      landTypeData,
      directionData,
      propertyTypeData,
      dongData,
      hoData,
      extraVarsData,
      adSrl,
    )
  } else if (type == 'update') {
    newData = Object.assign(
      {},
      data,
      addressSrlData,
      landAreaData,
      exclusiveAreaData,
      totalFloorAreaData,
      contractAreaData,
      buildingAreaData,
      landTypeData,
      directionData,
      propertyTypeData,
      dongData,
      hoData,
    )
  }

  return updateGridDatas({
    currentRowNode,
    newData: newData,
  })
}

/**
 * 그리드 데이터 초기화시 데이터셋
 */

export const resetNoteData = {
  address_doro: null,
  address_id: null,
  address_jibun: null,
  address_srl: null,
  alias: null,
  centerpoint: [],
  eupmyeondong: '',
}

/**
 * 유효하지 않은 주소 grid update 양식 통일
 * @returns
 */
export const resetAfterAddressChange = ({
  currentRowNode,
  display_address,
  invalid_addr_name,
}) => {
  const data = {
    ...resetNoteData,
    display_address: display_address,
    invalid_addr_name: invalid_addr_name,
  }

  return updateGridDatas({
    currentRowNode,
    newData: data,
  })
}

/**
 * 유효하지 않은 주소 grid update 양식 통일
 * @returns
 */
export const resetAfterAddressChangeForAd = ({
  currentRowNode,
  display_address,
  invalid_addr_name,
  status,
  isAd,
  ad_srl,
}) => {
  const data = {
    ...resetNoteData,
    display_address: display_address,
    invalid_addr_name: invalid_addr_name,
    status,
    isAd,
    ad_srl,
  }
  console.log(data)

  return updateGridDatas({
    currentRowNode,
    newData: data,
  })
}

/**
 * 매매 전세 월세 개수 또는 연락처 개수중 max 값에 따라 row height return
 * @param {string} contract_type 구분(가격)
 * @param {contacts} contacts 연락처
 * @returns
 */
export const getMaxRowHeight = ({ contract_type, contacts }) => {
  const contractTypeLength = (contract_type || '').split(',').length
  const contactsLength = contacts?.length || 0
  const maxLength = Math.max.apply(Math, [
    contractTypeLength,
    contactsLength,
  ])

  return (
    (maxLength > 1
      ? maxLength * eachSmallRowHeight
      : 2 * eachSmallRowHeight) + 1
  )
}

/**
 * 데이터가 있으면 데이터 Object 형태 리턴, 아니면 빈 object
 * @param {string} key
 * @param {any} value
 * @param {boolean} round
 * @returns
 */
const getObjectData = (key, value, round = false) => {
  return value ? { [key]: round ? Math.round(value) : value } : {}
}
