import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useFormContext } from 'react-hook-form'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 사진촬영 여부
 */
const NoteAdColumnPicture = ({ isDisabled }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const shoot = watch('verification.shoot')

  return (
    <ColumnSet>
      <ColumnTitle required>사진촬영 여부</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          size="small"
          fit
          label="사진촬영 및 사진 노출"
          {...register('verification.shoot')}
          value={true}
          onChange={(e) => {
            onUpdate('verification.shoot', JSON.parse(e.target.value))
          }}
          checked={shoot == true}
          name="verification_shoot"
          disabled={isDisabled}
        />
        <RadioButton
          size="small"
          fit
          {...register('verification.shoot')}
          value={false}
          label="현장확인 및 체크리스트 작성"
          onChange={(e) => {
            onUpdate('verification.shoot', JSON.parse(e.target.value))
          }}
          checked={shoot == false}
          name="verification_shoot"
          disabled={isDisabled}
        />
        <ValidateWarn>
          {errors?.verification?.shoot?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnPicture
