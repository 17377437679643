import { ContentWrapper } from 'components/jmapnotev2/style/NoteStyle'
import React from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router'
import { ContractProvider } from 'contexts/contractContext'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import NoteDocsList from 'components/jmapnotev2/NoteDocs/NoteDocsList'
import NoteDocsEdit from 'components/jmapnotev2/NoteDocs/NoteDocsEdit'

const NoteDocsPage = () => {
  const match = useRouteMatch('/note/v2/docs/:channelSrl')
  return (
    <>
      <ContractProvider>
        <StyledContentWrapper isviewpage={!match?.isExact}>
          <Switch>
            <Route
              path="/note/v2/docs/:channelSrl"
              component={NoteDocsList}
              exact
            />
            <Route
              path={[
                '/note/v2/docs/:channelSrl/:noteSrl/edit/:contractSrl?',
              ]}
              component={NoteDocsEdit}
            />
          </Switch>
        </StyledContentWrapper>
      </ContractProvider>
    </>
  )
}

const StyledContentWrapper = styled(ContentWrapper)`
  ${({ isviewpage }) => isviewpage && `height: 100%;`}
`

export default React.memo(NoteDocsPage)
