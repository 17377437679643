import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비 부과 정보 - 세부 사유
 */
const NoteAdColumnFeeDetailReason = ({ required }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <ColumnSet>
      <ColumnTitle required={required}>기타부과세부사유</ColumnTitle>
      <ColumnVal>
        <InputText
          placeholder="기타 관리비 내용을 공백없이 최대 50자 이내로 작성해주세요."
          type="text"
          {...register(
            'administrationCostInfo.etcFeeDetails.directInputContent',
          )}
          onBlur={(e) => {
            onUpdate(
              'administrationCostInfo.etcFeeDetails.directInputContent',
              e.target.value,
            )
          }}
        />
        <ValidateWarn>
          {
            errors?.administrationCostInfo?.etcFeeDetails
              ?.directInputContent?.message
          }
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeeDetailReason
