import Select from 'react-select'
import {
  Row,
  RowSpanInner,
  SectionSpace,
  Table,
  TableContent,
  TableHeader,
  TableSpace,
  TableSubHeader,
  TextArea,
  Title,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { Checkbox } from 'components/design'
import theme from 'lib/styles/theme'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import { descript_type } from 'lib/utils/notev2/note2Constants'

const defaultEvidence = [
  '등기권리증',
  '등기사항증명서',
  '토지대장',
  '건축물대장',
  '지적도',
  '임야도',
  '토지이용계획증명서',
]

const addedEvidences = [
  '확정일자 부여현황',
  '전입세대확인서',
  '국세납세증명서',
  '지방세납세증명서',
]

const NoteDocsDescHeader = ({ onUpdate, onUpdateCheckbox }) => {
  const { watch } = useFormContext()

  const d_descript_type = watch('d_descript_type')
  const d_property_type = watch('d_property_type')
  const d_evidence = watch('d_evidence')
  const d_evidence_other = watch('d_evidence_other')
  const d_request_condition = watch('d_request_condition')

  const evidences =
    d_descript_type == '주거용 건축물'
      ? defaultEvidence.concat(addedEvidences)
      : defaultEvidence

  return (
    <Wrapper>
      <Preface>
        <div>■ 공인중개사법 시행규칙 &#91;별지 제20호서식&#93; </div>
        <div>&#60;개정 2024. 7. 2.&#62;</div>
      </Preface>
      <Title center>
        중개대상물 확인 · 설명서 &#91;
        {descript_type.findIndex((value) => value == d_descript_type) +
          1}
        &#93; &#40;
        <TypeSelect
          components={{
            IndicatorSeparator: () => null,
          }}
          classNamePrefix="select"
          placeholder="선택"
          value={
            d_descript_type
              ? { label: d_descript_type, value: d_descript_type }
              : ''
          }
          options={getSimpleSelectboxOptions(descript_type, false)}
          onChange={(e) => onUpdate('d_descript_type', e.value)}
          isSearchable={false}
        />
        &#41;
      </Title>

      <CheckboxWrapper column={d_descript_type == '주거용 건축물'}>
        {d_descript_type == '주거용 건축물' && (
          <>
            <ChecboxRow>
              &#40; 주택의 유형 :
              <Checkbox
                size="large"
                color="blue"
                checked={
                  d_property_type.includes('단독주택') ? true : false
                }
                onChange={(e) => {
                  onUpdateCheckbox({
                    oriData: d_property_type,
                    key: 'd_property_type',
                    value: '단독주택',
                  })
                }}
              >
                단독주택
              </Checkbox>
              <Checkbox
                size="large"
                color="blue"
                checked={
                  d_property_type.includes('공동주택') ? true : false
                }
                onChange={(e) => {
                  onUpdateCheckbox({
                    oriData: d_property_type,
                    key: 'd_property_type',
                    value: '공동주택',
                  })
                }}
              >
                공동주택
              </Checkbox>
              <Checkbox
                size="large"
                color="blue"
                checked={
                  d_property_type.includes('주거용 오피스텔')
                    ? true
                    : false
                }
                onChange={(e) => {
                  onUpdateCheckbox({
                    oriData: d_property_type,
                    key: 'd_property_type',
                    value: '주거용 오피스텔',
                  })
                }}
              >
                주거용 오피스텔
              </Checkbox>
              &#41;
            </ChecboxRow>
            <ChecboxRow>
              &#40; 거래 형태 :
              <Checkbox
                size="large"
                color="blue"
                checked={
                  d_property_type.includes('매매 · 교환') ? true : false
                }
                onChange={(e) => {
                  onUpdateCheckbox({
                    oriData: d_property_type,
                    key: 'd_property_type',
                    value: '매매 · 교환',
                  })
                }}
              >
                매매 · 교환
              </Checkbox>
              <Checkbox
                size="large"
                color="blue"
                checked={
                  d_property_type.includes('임대') ? true : false
                }
                onChange={(e) => {
                  onUpdateCheckbox({
                    oriData: d_property_type,
                    key: 'd_property_type',
                    value: '임대',
                  })
                }}
              >
                임대
              </Checkbox>
              &#41;
            </ChecboxRow>
          </>
        )}
        {d_descript_type == '비주거용 건축물' && (
          <>
            <Checkbox
              size="large"
              color="blue"
              checked={
                d_property_type.includes('업무용') ? true : false
              }
              onChange={(e) => {
                onUpdateCheckbox({
                  oriData: d_property_type,
                  key: 'd_property_type',
                  value: '업무용',
                })
              }}
            >
              업무용
            </Checkbox>
            <Checkbox
              size="large"
              color="blue"
              checked={
                d_property_type.includes('상업용') ? true : false
              }
              onChange={(e) => {
                onUpdateCheckbox({
                  oriData: d_property_type,
                  key: 'd_property_type',
                  value: '상업용',
                })
              }}
            >
              상업용
            </Checkbox>
            <Checkbox
              size="large"
              color="blue"
              checked={
                d_property_type.includes('공업용') ? true : false
              }
              onChange={(e) => {
                onUpdateCheckbox({
                  oriData: d_property_type,
                  key: 'd_property_type',
                  value: '공업용',
                })
              }}
            >
              공업용
            </Checkbox>
            <Checkbox
              size="large"
              color="blue"
              checked={
                d_property_type.includes('매매 · 교환') ? true : false
              }
              onChange={(e) => {
                onUpdateCheckbox({
                  oriData: d_property_type,
                  key: 'd_property_type',
                  value: '매매 · 교환',
                })
              }}
            >
              매매 · 교환
            </Checkbox>
            <Checkbox
              size="large"
              color="blue"
              checked={d_property_type.includes('임대') ? true : false}
              onChange={(e) => {
                onUpdateCheckbox({
                  oriData: d_property_type,
                  key: 'd_property_type',
                  value: '임대',
                })
              }}
            >
              임대
            </Checkbox>
            <Checkbox
              size="large"
              color="blue"
              checked={
                d_property_type.includes('그 밖의 경우') ? true : false
              }
              onChange={(e) => {
                onUpdateCheckbox({
                  oriData: d_property_type,
                  key: 'd_property_type',
                  value: '그 밖의 경우',
                })
              }}
            >
              그 밖의 경우
            </Checkbox>
          </>
        )}
      </CheckboxWrapper>

      <Description>
        ※ 자동으로 완성된 정보가 정확한지 반드시 확인해주세요.
        {/* TODO: [자동완성 삭제하기] */}
        <br />※ 계약서(확인설명서)의 자동완성 항목은 공인중개사의 편의를
        위해 제공되는 기능으로 (주)주택은 정확성이나 완전성을 보장하지
        않으며 이를 활용하는 모든 책임은 활용 당사자인 사용자에게
        있습니다.
      </Description>

      <StyledTable>
        <Row
          height={d_descript_type == '주거용 건축물' ? 76 : 54}
          rowspan={2}
          noborderbottom
        >
          <TableHeader>
            확인 · 설명
            <br />
            자료
          </TableHeader>
          <RowSpanInner rowspanheightpercent={52} rowspan={2}>
            <TableSubHeader width={143}>
              확인 · 설명
              <br />
              근거자료 등
            </TableSubHeader>
            <TableContent noborderright>
              <EvidenceWrap>
                <Evidence>
                  {evidences.map((eviItem, i) => {
                    return (
                      <Checkbox
                        key={`evidence_${i}`}
                        color="blue"
                        checked={d_evidence?.includes(eviItem)}
                        onChange={(e) => {
                          onUpdateCheckbox({
                            oriData: d_evidence,
                            key: 'd_evidence',
                            value: eviItem,
                          })
                        }}
                      >
                        {eviItem}
                      </Checkbox>
                    )
                  })}
                  <OtherEvidence>
                    <Checkbox
                      color="blue"
                      checked={d_evidence?.includes('그 밖의 자료')}
                      onChange={(e) => {
                        onUpdateCheckbox({
                          oriData: d_evidence,
                          key: 'd_evidence',
                          value: '그 밖의 자료',
                        })
                      }}
                    >
                      그 밖의 자료
                    </Checkbox>
                    &#40;
                    <ColorInput
                      disabled={!d_evidence?.includes('그 밖의 자료')}
                      type="text"
                      defaultValue={d_evidence_other}
                      onBlur={(e) =>
                        onUpdate('d_evidence_other', e.target.value)
                      }
                    />
                    &#41;
                  </OtherEvidence>
                </Evidence>
              </EvidenceWrap>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={48}
            rowspan={2}
            noborderbottom
          >
            <TableSubHeader width={143}>
              대상물건의 상태에
              <br />
              관한 자료요구 사항
            </TableSubHeader>
            <TableContent inheritwidth noborderright>
              <TextArea
                fullheight
                defaultValue={d_request_condition}
                onBlur={(e) =>
                  onUpdate('d_request_condition', e.target.value)
                }
              />
            </TableContent>
          </RowSpanInner>
        </Row>
      </StyledTable>
      <Table>
        <Row height={50}>
          <TableHeader>
            개업공인중개사의
            <br />
            확인 · 설명 의무
          </TableHeader>
          <TableContent noborderright>
            개업공인중개사는 중개대상물에 관한 권리를 취득하려는
            중개의뢰인에게 성실 · 정확하게 설명하고, 토지대장 등본,
            등기사항증명서등 설명의 근거자료를 제시해야합니다.
          </TableContent>
        </Row>
        <Row height={65} noborderbottom>
          <TableHeader>
            실제 거래가격
            <br />
            신고
          </TableHeader>
          <TableContent noborderright>
            「부동산 거래신고 등에 관한 법률」 제3조 및 같은 법 시행령
            별포 1 제1호마목에 따른 실제 거래가격은 매수인이 매수한
            부동산을 양도하는 경우 「소득세법」 제97조제1항 및
            제7항과같은 범 시행령 제163조제11항제2호에 따라 취득 당시의
            실제 거래가액으로 보아 양도차익이 계산될 수 있음을
            유의하시기 바랍니다.
          </TableContent>
        </Row>
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${SectionSpace}
`

const TypeSelect = styled(Select)`
  max-width: 300px;
  min-width: 140px;
  margin-right: 8px;
`
const Preface = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  & > div {
    &:last-child {
      color: ${theme.colors.blue[400]};
    }
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;

  & > div {
    &:first-child {
      margin-left: 14px;
    }
    margin-right: 14px;
  }

  ${({ column }) =>
    column &&
    `
    flex-direction: column;
  `}
`

const ChecboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 18px;
  line-height: 100%;
`

const EvidenceWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
`

const Evidence = styled.div`
  display: flex;
  padding-left: 5px;
  flex-wrap: wrap;

  & > div {
    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  input {
    margin: 0 10px;
  }
`

const StyledTable = styled(Table)`
  ${TableSpace}
`

const Description = styled.div`
  margin-bottom: 16px;
  font-weight: 400;
  line-height: 160%;
  color: ${theme.colors.tint.danger};
`

const OtherEvidence = styled.div`
  display: flex;
  align-items: center;
`

export default NoteDocsDescHeader
