import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NoteDonghoContractTypes = ({ selectedDanji, dong }) => {
  return (
    <Wrapper>
      <RowWrapper gray>
        <Item>월세</Item>
        <Item>월세추정</Item>
        <Item>전세</Item>
        <Item>전세추정</Item>
        <Item>자가추정</Item>
      </RowWrapper>
      <RowWrapper>
        <Item>{selectedDanji.state[dong]?.['월세'] || 0}세대</Item>
        <Item>{selectedDanji.state[dong]?.['월세추정'] || 0}세대</Item>
        <Item>{selectedDanji.state[dong]?.['전세'] || 0}세대</Item>
        <Item>{selectedDanji.state[dong]?.['전세추정'] || 0}세대</Item>
        <Item>{selectedDanji.state[dong]?.['자가추정'] || 0}세대</Item>
      </RowWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  text-align: center;
  border-bottom: 1px solid ${theme.colors.gray[100]};
`

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 37px;
  font-weight: 500;
  font-size: 13px;
  ${({ gray }) =>
    gray &&
    `
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[600]};
        font-weight: 600;
    `}
`

const Item = styled.div`
  width: calc(100% / 5);
`

export default NoteDonghoContractTypes
