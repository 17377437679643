import { NavLink } from 'react-router-dom'
import useIsMobile from 'lib/hooks/useIsMobile'
import BaseNavItem from './BaseNavItem'
import { ReactComponent as Icon } from 'assets/icon/nav/home.svg'
import { ReactComponent as ActiveIcon } from 'assets/icon/nav/home-active.svg'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setMapVisible } from 'modules/map'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import useIsTablet from 'lib/hooks/useIsTablet'

const BaseNavHome = () => {
  const isMobile = useIsMobile()
  const mapVisible = useSelector((state) => state.map.mapVisible)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const onClick = () => {
    history.push('/home')
    dispatch(setMapVisible(false))
  }

  return isMobile ? (
    <BaseNavItem
      as="div"
      exact
      icon={<Icon className="defaultIcon" />}
      activeIcon={<ActiveIcon className="activeIcon" />}
      text={isMobile ? '홈' : '지도 홈'}
      active={location.pathname == '/home' && !mapVisible}
      onClick={onClick}
    />
  ) : (
    <BaseNavItem
      as={NavLink}
      to="/home"
      exact
      icon={<Icon className="defaultIcon" />}
      activeIcon={<ActiveIcon className="activeIcon" />}
      text={isMobile ? '홈' : '지도 홈'}
    />
  )
}

export default BaseNavHome
