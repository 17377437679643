import { useCallback, useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import MapContext from 'contexts/map'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import SkyIcon from 'assets/images/map-type-sky.webp'
import DistrictIcon from 'assets/images/map-type-district.webp'
import AgeIcon from 'assets/images/map-age.webp'
import TerrianIcon from 'assets/images/map-terrian.webp'
import MapLayerBuildingAge from '../MapLayer/MapLayerBuildingAge'
import { rgba } from 'polished'

/**
 * 노후도, 지도 타입
 * 노후도, 지적도, 지형도, 항공뷰
 * @returns
 */
const MapType = () => {
  const { kakao } = window

  const { state, actions } = useContext(MapContext)
  const { map, layer } = state

  const [cookies, setCookie] = useCookies(['mapType'])

  useEffect(() => {
    if (!kakao || Object.keys(map).length === 0) return

    initMapType()
  }, [map, kakao])

  /**
   * layer 쿠키값으로 지도타입 세팅하기
   */
  const initMapType = () => {
    const storedMapType = cookies.layer

    if (!storedMapType) return

    actions.setLayer(cookies.layer)

    if (storedMapType.includes('USE_DISTRICT')) {
      map.addOverlayMapTypeId(kakao.maps.MapTypeId.USE_DISTRICT)
    }

    if (storedMapType.includes('HYBRID')) {
      map.setMapTypeId(kakao.maps.MapTypeId.HYBRID)
    }

    if (storedMapType.includes('TERRAIN')) {
      map.addOverlayMapTypeId(kakao.maps.MapTypeId.TERRAIN)
    }
  }

  /**
   * 지적도 클릭 이벤트
   */
  const onClickDistirictView = useCallback(() => {
    if (!map) return

    if (layer.includes('USE_DISTRICT')) {
      map.removeOverlayMapTypeId(kakao.maps.MapTypeId.USE_DISTRICT)
      actions.setLayer(layer.filter((str) => str != 'USE_DISTRICT'))
      setCookie(
        'layer',
        layer.filter((str) => str != 'USE_DISTRICT'),
        { path: '/' },
      )
    } else {
      map.addOverlayMapTypeId(kakao.maps.MapTypeId.USE_DISTRICT) // 지적편집도 지도타입
      actions.setLayer([...layer, ...['USE_DISTRICT']])
      setCookie('layer', [...layer, ...['USE_DISTRICT']], { path: '/' })
    }
  }, [map, kakao, layer])

  /**
   * 지형도 클릭 이벤트
   */
  const onClickTerrainView = useCallback(() => {
    if (!map) return

    if (layer.includes('TERRAIN')) {
      map.removeOverlayMapTypeId(kakao.maps.MapTypeId.TERRAIN)
      actions.setLayer(layer.filter((str) => str != 'TERRAIN'))
      setCookie(
        'layer',
        layer.filter((str) => str != 'TERRAIN'),
        { path: '/' },
      )
    } else {
      map.addOverlayMapTypeId(kakao.maps.MapTypeId.TERRAIN)
      actions.setLayer([...layer, ...['TERRAIN']])
      setCookie('layer', [...layer, ...['TERRAIN']], { path: '/' })
    }
  }, [map, kakao, layer])

  /**
   * 항공뷰 클릭 이벤트
   */
  const onClickSkyView = useCallback(() => {
    if (!map) return

    if (layer.includes('HYBRID')) {
      map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP)
      actions.setLayer(layer.filter((str) => str != 'HYBRID'))
      setCookie(
        'layer',
        layer.filter((str) => str != 'HYBRID'),
        { path: '/' },
      )
    } else {
      map.setMapTypeId(kakao.maps.MapTypeId.HYBRID)
      actions.setLayer([...layer, ...['HYBRID']])
      setCookie('layer', [...layer, ...['HYBRID']], { path: '/' })
    }
  }, [map, kakao, layer])

  const toggleLayerState = () => {
    actions.setLayerState((prev) => {
      const result = { ...prev }
      result['buildingAgeAcitve'] = result['buildingAgeAcitve']
        ? false
        : true
      return result
    })
  }

  return (
    <>
      <Wrapper>
        <Select
          active={state.layerState['buildingAgeAcitve']}
          onClick={toggleLayerState}
          expand
        >
          <img src={AgeIcon} />
          <span>노후도</span>
        </Select>
        <Select
          active={layer.includes('USE_DISTRICT')}
          onClick={() => onClickDistirictView()}
          expand
        >
          <img src={DistrictIcon} />
          <span>지적도</span>
        </Select>
        <Select
          active={layer.includes('TERRAIN')}
          onClick={() => onClickTerrainView()}
          expand
        >
          <img src={TerrianIcon} />
          <span>지형도</span>
        </Select>
        <Select
          active={layer.includes('HYBRID')}
          onClick={() => onClickSkyView()}
          expand
        >
          <img src={SkyIcon} />
          <span>항공뷰</span>
        </Select>
      </Wrapper>
      <MapLayerBuildingAge />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  gap: 14px;
  right: 10px;
  top: 10px;
  height: 43px;
  z-index: 13;
  border-radius: 6px;
  overflow: visible;

  ${media.mediumS`
    top: ${theme.base.topHeaderHeightM + 20}px;
  `}
`

const Select = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  position: relative;
  width: 75px;
  height: 60px;
  padding: 0;
  box-shadow: ${theme.shadow['float']};
  border-right: none !important;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${theme.colors.base.white};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  span {
    margin: auto 0;
    font-size: 12px;
    line-height: 100%;
    font-weight: 500;
  }

  img {
    width: 100%;
    height: 35px;
    border-radius: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 0;
  }

  span {
    line-height: 100%;
  }

  &:not(:last-child) {
    border-right: 1px solid ${theme.colors.gray[200]};
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.gray[800]};
    color: ${theme.colors.base.white};
    //border: 1px solid ${rgba(theme.colors.gray[800], 0.4)};
    outline :1px solid ${rgba(theme.colors.gray[800], 0.2)};
  `}

  ${media.mediumM`
    font-size: 13px;
    width: 80px;
    height: 60px;
    
    img {
      height: 35px;
    }
  `}

  ${media.mediumS`
    width: 60px;
    height: 50px;
    border-radius: 8px;
    span {
      font-size: 12px;
    }

    img {
      height: 30px;
    }
  `}
`

export default MapType
