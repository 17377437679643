import theme from 'lib/styles/theme'
import styled from 'styled-components'
import SummarySchoolTableItem from './SummarySchoolTableItem'
import { moreViewTableCss } from '../style/SummaryStyle'

/**
 * 학군 그래프에 해당하는 그래프(중, 고등)
 */
const SummarySchoolTable = ({
  data,
  activeTab,
  viewCnt,
  prevMarker,
  setPrevMarker,
  onClickSetActiveValue,
  resetActiveValue,
  activeValue,
}) => {
  return (
    <StyledTable viewcnt={viewCnt}>
      <thead>
        <tr>
          <th>학교명</th>
          <th className="center">학급당 학생 수</th>
          <th className="center">
            {activeTab == 'middle' ? '특목고 진학률' : '대학 진학률'}
          </th>
          <th className="right">거리</th>
        </tr>
      </thead>
      <StyledTbody>
        {data.map((item, index) => {
          return (
            <SummarySchoolTableItem
              item={item}
              key={index}
              prevMarker={prevMarker}
              setPrevMarker={setPrevMarker}
              onClickSetActiveValue={onClickSetActiveValue}
              resetActiveValue={resetActiveValue}
              activeValue={activeValue}
            />
          )
        })}
      </StyledTbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
  position: relative;
  margin-top: 40px;
  width: 100%;
  border-collapse: collapse;

  ${moreViewTableCss}

  th {
    padding: 8px;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    color: ${theme.colors.gray[600]};
    font-size: 14px;
    font-weight: 500;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  th,
  td {
    text-align: left;
    padding: 7px 4px;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    font-size: 13px;

    &:first-child {
      width: 110px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 90px;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }
`

const StyledTbody = styled.tbody`
  margin: 0 -10px;
`

export default SummarySchoolTable
