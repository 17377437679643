import { getDeunggibuList } from 'lib/api/notev2/contractApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Empty } from 'components/design'
import moment from 'moment'
import SelectBox from 'components/jmapnotev2/elements/SelectBox'
import { Skeleton } from 'antd'
import NoteDocsLayerDeunggibuLayout from './NoteDocsLayerDeunggibuLayout'
import {
  SelectBottomHr,
  SelectLabel,
  SelectWrapper,
} from 'components/jmapnotev2/style/NoteLayerStyle'
import { useFormContext } from 'react-hook-form'

const NoteDocsLayerDeunggibuCommon = ({
  targetData,
  goToDeunggibuIssuance,
  useTo,
}) => {
  const [onRequest, loadingDeunggi, data] = useAxios(getDeunggibuList)

  const { watch } = useFormContext()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedData, setSelectedData] = useState(null)
  const [selectList, setSelectList] = useState([])

  const channel_srl = watch('channel_srl')
  const note_srl = watch('note_srl')

  useEffect(() => {
    onRequest({
      channel_srl,
      note_srl,
      doc_types: ['등기부등본'],
      status: ['complete'],
    })
  }, [])

  useEffect(() => {
    if (!data) return

    if (data.result && data.result.length > 0) {
      const options = data.result
        .filter((item) => item?.extracted_data)
        .map((item) => {
          const splitTitleData =
            item.extracted_data?.title.split(']') || []

          return {
            label: (
              <SelectLabel>
                <div>
                  {splitTitleData.length > 1 ? (
                    <SelectSubTitle>
                      <span>{splitTitleData[0]}]</span>
                      <span>{splitTitleData[1]}</span>
                    </SelectSubTitle>
                  ) : (
                    item.extracted_data.title
                  )}
                </div>
                <span>
                  발급일{' '}
                  {item.extracted_data.date
                    ? moment(
                        item.extracted_data.date,
                        'YYYYMMDDHHmmss',
                      ).format('YYYY.MM.DD HH:mm')
                    : ''}
                </span>
              </SelectLabel>
            ),
            value: item.doc_srl,
          }
        })

      setSelectList(options)

      if (targetData) {
        const targetIdx = options.findIndex(
          ({ value }) => value == targetData.doc_srl,
        )

        setSelectedIndex(targetIdx)
      } else {
        setSelectedIndex(0)
      }
      setSelectedData(targetData || data.result[0] || null)
    }

    return () => {
      setSelectList([])
      setSelectedIndex(0)
      setSelectedData(null)
    }
  }, [data])

  useEffect(() => {
    if (!data) return

    setSelectedData(data.result.find((item, i) => i == selectedIndex))
  }, [selectedIndex])

  if (loadingDeunggi) {
    return <Skeleton />
  }

  return (
    <Wrapper>
      {selectedData ? (
        <>
          <NoteDocsLayerDeunggibuLayout
            data={selectedData}
            onClose={goToDeunggibuIssuance}
            useTo={useTo}
            append={
              <>
                <SelectWrapper>
                  <SelectBox
                    size="large"
                    noborder
                    value={selectList[selectedIndex]}
                    minWidth={150}
                    options={selectList}
                    onChange={(e) => {
                      const index = selectList.findIndex(
                        ({ value }) => value == e.value,
                      )
                      setSelectedIndex(index)
                    }}
                  />
                </SelectWrapper>
                <SelectBottomHr />
              </>
            }
          />
        </>
      ) : (
        <Empty>발급받은 등기부등본 데이터가 없습니다.</Empty>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Date = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`

const SelectSubTitle = styled.div`
  display: flex;
  flex-direction: column;

  span {
    &:first-child {
      margin-bottom: 6px;
    }
  }
`

export default NoteDocsLayerDeunggibuCommon
