import React from 'react'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { ReactComponent as Logo } from 'assets/icon/logo.svg'
import SideMenuBottom from './SideMenuBottom'
import SideMenuNote from './SideMenuNote'
import SideMenuBasic from './SideMenuBasic'
import { getFullUrl } from 'utils'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ScrollStyle } from 'components/jmapnotev2/style/NoteStyle'

const SideMenu = () => {
  const { isJMapNote } = useNoteMatch()
  const history = useHistory()
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)
  const onClickInMapRouter = (e, path) => {
    e.preventDefault()

    history.push(path)
  }
  return (
    <Wrapper
      isNoticeBarVisible={isNoticeBarVisible}
      noticeBarHeight={height}
    >
      <StyledNavLink to={getFullUrl('/map', true)}>
        <Logo onClick={(e) => onClickInMapRouter(e, '/home')} />
      </StyledNavLink>
      <ScrollWrapper>
        <MenuWrapper>
          <SideMenuBasic />
        </MenuWrapper>
        <SideMenuBottom />
      </ScrollWrapper>
    </Wrapper>
  )
}

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  width: 100%;
  padding-top: 20px;
  height: 100%;

  ${ScrollStyle}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: ${theme.base.noteSidebarWidth}px;
  z-index: 51;
  padding-top: 20px;
  flex-shrink: 0;
  background-color: ${theme.colors.base.white};
  box-shadow: 2px 0 5px -3px rgba(0, 0, 0, 0.1);
  ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible
      ? `top: ${noticeBarHeight}px;`
      : `top: 0px; 
    `}

  ${media.mediumM`
    display: none;
  `}
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledNavLink = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  cursor: pointer;
`

export default React.memo(SideMenu)
