import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Button } from 'components/design'
import { useDispatch } from 'react-redux'
import { setLoginVisible } from 'modules/auth'

const Wrapper = styled.div`
  margin: 0;
  padding: 20px 20px 24px 20px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
`

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 16px;
  color: ${theme.colors.gray[900]};

  & > strong {
    font-size: 36px;
    font-weight: 700;
    color: ${theme.colors.gray[900]};
  }
`

const Register = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: ${theme.colors.gray[500]};
  font-size: 14px;
  line-height: normal;
  span {
    margin-right: 6px;
  }
`

const StyledRegisterLink = styled.div`
  color: ${theme.colors.primary[500]};
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 3.5px;
`

const SideMenuProfileDefault = ({ onClose, setIsSignupVisible }) => {
  const dispatch = useDispatch()

  const onClickLogin = () => {
    dispatch(setLoginVisible(true))
    onClose()
  }

  const onClickSignin = () => {
    // onClose()
    setIsSignupVisible(true)
  }

  return (
    <Wrapper>
      <StyledTitle>
        집 짓기 가장 쉬운 방법
        <br />
        <strong>JOOTEK</strong>
      </StyledTitle>
      <Button color="primary" expand onClick={onClickLogin}>
        로그인
      </Button>
      <Register>
        <span>아직 회원이 아니신가요?</span>
        <StyledRegisterLink onClick={onClickSignin}>
          회원가입
        </StyledRegisterLink>
      </Register>
    </Wrapper>
  )
}

export default SideMenuProfileDefault
