import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import NoteAdListGrid from './NoteAdListGrid'
import { CommonPadding } from 'components/jmapnotev2/style/NoteStyle'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteAdListMobile from './NoteAdListMobile'

const NoteAdList = ({ onSwipeStart, onSwipeMove, onSwipeEnd }) => {
  const isMobile = useIsMobile()
  const selectedStatus = useSelector(
    (state) => state.noteAd.status.selectedStatus,
  ) //선택된 최상단 상태 값 필터

  return (
    <>
      {!isMobile && (
        <Title hasStatus={selectedStatus}>
          <span>광고 이력</span>
          {selectedStatus && (
            <>
              <Divider />
              <span>{selectedStatus}</span>
            </>
          )}
        </Title>
      )}
      <NoteAdListGrid
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
      />
    </>
  )
}

const Title = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 700;
  font-size: 28px;
  ${CommonPadding};

  & > span {
    ${({ hasStatus }) =>
      hasStatus &&
      `
        &:first-child {
          color: ${theme.colors.gray[500]};
        }
    `}
  }
`

const Divider = styled.div`
  width: 1px;
  height: 60%;
  margin-left: 12px;
  margin-right: 12px;
  background-color: ${theme.colors.gray[200]};
`

export default NoteAdList
