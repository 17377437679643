import {
  GridStyleWrapper,
  GridWrapper,
  StyledGrid,
} from '../style/NoteGridStyle'
import { useEffect, useRef, useState } from 'react'
import {
  columnAlignLeft,
  columnCentered,
} from '../NoteList/NoteGridOptions'
import priceFormat from 'lib/utils/priceFormat'
import StylingHeaderComponent from '../NoteList/StylingHeaderComponent'
import CustomDocsSellerBuyerCell from '../customCell/Docs/CustomDocsSellerBuyerCell'
import { getSimpleDateFormat } from 'lib/utils/notev2/util'
import CustomJungdogeumCell from '../customCell/Docs/CustomJungdogeumCell'
import moment from 'moment'
import { useSelector } from 'react-redux'
import CustomDocsAddressCell from '../customCell/Docs/CustomDocsAddressCell'
import { useHistory } from 'react-router-dom'
import {
  additionalCellWidth,
  cellPrependLabelColorClass,
  getGridFontSize,
  rowClassRules,
} from 'lib/utils/notev2/gridUtil'
import useIsMobile from 'lib/hooks/useIsMobile'
import theme from 'lib/styles/theme'

const NoteDocsGrid = ({ gridRowData }) => {
  const gridRef = useRef()
  const [gridColumnApi, setGridColumnApi] = useState(null)

  const history = useHistory()
  const isMobile = useIsMobile()
  const gridFontSize = useSelector((state) => state.notev2.gridFontSize)

  const onGridReady = (params) => {
    setGridColumnApi(params.columnApi)
  }

  const defaultCoumn = [
    {
      field: 'address',
      headerName: '주소',
      width: 300,
      sortable: true,
      unSortIcon: true,
      cellClassRules: cellPrependLabelColorClass,
      cellRenderer: (params) => (
        <CustomDocsAddressCell
          channel_srl={params.data.channel_srl}
          contract_srl={params.data.contract_srl}
          note_srl={params.data.note_srl}
          address={params.data.address}
        />
      ),
      ...columnAlignLeft,
    },
    {
      field: 'property_type',
      headerName: '종류',
      width: 100,
      sortable: true,
      unSortIcon: true,
      ...columnCentered,
    },
    {
      field: 'contract_type',
      headerName: '구분',
      width: 70,
      sortable: true,
      unSortIcon: true,
      ...columnCentered,
    },

    {
      field: 'price',
      headerName: '가격',
      width: 150,
      sortable: true,
      unSortIcon: true,
      valueGetter: (params) => {
        const contract_type = params.data.contract_type
        if (contract_type == '전세' || contract_type == '매매') {
          return params.data.total_price > 0
            ? priceFormat(params.data.total_price, true)
            : null
        } else if (contract_type == '월세') {
          return `${priceFormat(params.data.total_price, true) || ''}${
            params.data.total_price &&
            params.data.payment_schedule.rent_price
              ? '/'
              : ''
          }${
            priceFormat(
              params.data.payment_schedule.rent_price > 0
                ? params.data.payment_schedule.rent_price
                : null,
              true,
            ) || ''
          }`
        }
      },
      ...columnCentered,
    },
    {
      field: 'sign_date',
      headerName: '계약금',
      width: 90,
      cellClass: (params) => {
        return (
          params.data.sign_date &&
          moment(params.data.sign_date).add(1, 'days') < new Date() &&
          `horizontal-gradient`
        )
      },
      valueGetter: (params) => {
        return getSimpleDateFormat(params.data.sign_date)
      },
      ...columnCentered,
    },
    {
      field: 'jungdogeum1',
      headerName: '중도금1',
      cellClass: (params) => {
        const jungdogeum = params.data.payment_schedule?.jungdogeum

        return (
          jungdogeum &&
          jungdogeum.length > 0 &&
          (jungdogeum.length == 1 && jungdogeum != 'Invalid date'
            ? moment(jungdogeum[0].date).add(1, 'days') < new Date() &&
              `horizontal-gradient`
            : moment(jungdogeum[1].date).add(1, 'days') < new Date() &&
              `horizontal-gradient`)
        )
      },
      colSpan: () => 2,
      cellRenderer: (params) => (
        <CustomJungdogeumCell
          jungdogeum={params.data.payment_schedule?.jungdogeum}
        />
      ),
      width: 90,
      ...columnCentered,
    },
    {
      field: 'jungdogeum2',
      headerName: '중도금2',
      width: 90,
      ...columnCentered,
    },
    {
      field: 'jangeum',
      headerName: '잔금',
      cellClass: (params) => {
        const jangeum = params.data.payment_schedule?.jangeum

        return (
          jangeum &&
          jangeum.length > 0 &&
          jangeum != 'Invalid date' &&
          moment(jangeum[0].date).add(1, 'days') < new Date() &&
          `horizontal-gradient`
        )
      },
      valueGetter: (params) => {
        const jangeum = params.data.payment_schedule?.jangeum
        if (jangeum && jangeum.length > 0) {
          return jangeum[0].date
            ? getSimpleDateFormat(jangeum[0].date)
            : ''
        }
        return ''
      },
      width: 90,
      ...columnCentered,
    },

    {
      field: 'seller',
      headerComponent: () => (
        <StylingHeaderComponent top="매도인" bottom="임대인" />
      ),
      cellRenderer: (params) => (
        <CustomDocsSellerBuyerCell value={params.data.seller} />
      ),
      width: 180,
      ...columnCentered,
    },
    {
      field: 'buyer',
      headerComponent: () => (
        <StylingHeaderComponent top="매수인" bottom="임차인" />
      ),
      cellRenderer: (params) => (
        <CustomDocsSellerBuyerCell value={params.data.buyer} />
      ),
      width: 180,
      ...columnCentered,
    },
    {
      field: 'multiple',
      headerName: '공동 중개',
      width: 275,
      valueGetter: (params) => {
        const realestate = params.data.realestate

        if (realestate && realestate.length > 1) {
          const realestates = realestate
            .map(({ company_name }) => company_name)
            .filter((value) => value)
          return realestates.toString()
        }
      },
    },
  ]

  //컬럼
  const [columnDefs, setColumnDefs] = useState()

  useEffect(() => {
    if (isMobile) {
      //mobile
      const mobileColumn = defaultCoumn.map((column) => {
        if (column.headerName == '주소') {
          return Object.assign({}, column, {
            pinned: true,
            width: 165,
          })
        } else {
          return column
        }
      })

      setColumnDefs(mobileColumn)
    } else {
      //pc
      const pcColumn = defaultCoumn.map((column) => {
        if (column.headerName == '주소') {
          return Object.assign({}, column, {
            pinned: false,
            width: 300,
          })
        } else {
          return column
        }
      })

      /**
       * 폰트 사이즈 변경에 따라 그리드 셀 폭 조절
       */
      const addWidth = additionalCellWidth(gridFontSize)

      const addedWidthColumn = pcColumn.map((item) => {
        return Object.assign({}, item, {
          width: item.width + addWidth,
        })
      })

      setColumnDefs(addedWidthColumn)
    }
  }, [isMobile, gridFontSize])

  /**
   * row 더블클릭시 계약서 작성화면으로 이동
   */
  const onRowDoubleClicked = (params) => {
    if (!params?.data) return
    if (!params.data.contract_srl || !params.data.channel_srl) return

    history.push(
      `/note/v2/docs/${params.data.channel_srl}/${
        params.data.note_srl || 0
      }/edit/${params.data.contract_srl}`,
    )
  }

  const gridOptions = {
    onRowDoubleClicked: onRowDoubleClicked,
    rowClassRules: rowClassRules, //맨 앞에 매물 종류 색상
  }

  return (
    <GridStyleWrapper
      minusheight={180}
      gridfontsize={getGridFontSize(gridFontSize)}
    >
      <GridWrapper className="ag-theme-alpine">
        <StyledGrid
          className="ag-grid-docs prevent-left-scroll"
          ref={gridRef}
          rowData={gridRowData}
          getRowId={(params) => params.data.contract_srl}
          onGridReady={onGridReady}
          rowSelection={'single'}
          columnDefs={columnDefs}
          animateRows={true}
          headerHeight={44 + additionalCellWidth(gridFontSize)}
          scrollbarWidth={12}
          gridOptions={gridOptions}
          overlayNoRowsTemplate={`<span style="font-weight: 400; color: ${theme.colors.gray[600]};">계약서 데이터가 없습니다. 매물관리 메뉴에서 계약서를 작성해 보세요.</span>`}
        ></StyledGrid>
      </GridWrapper>
    </GridStyleWrapper>
  )
}

export default NoteDocsGrid
