import CustomModal from './CustomModal'
import NoteInviteReward from './NoteInviteReward'
import useAxios from 'lib/hooks/useAxios'
import { getReferral } from 'lib/api/notev2/reward'
import { useEffect, useState } from 'react'
import { message } from 'antd'

const NoteInviteRewardModal = ({ visible, onClose }) => {
  const [onRequest, loading, data] = useAxios(getReferral)
  const [referCode, setReferCode] = useState('')
  const [invitedList, setInvitedList] = useState([])
  const [rewardedCount, setRewardedCount] = useState(0)
  const [expectedRewardedCount, setExpectedRewardedCount] = useState(0)

  useEffect(() => {
    if (visible) onRequest()
  }, [visible])

  useEffect(() => {
    if (!data) return
    if (!data?.referral?.referral_code) {
      message.error('초대 코드를 가져올 수 없습니다.')
      setReferCode('')
      return
    }
    setReferCode(data?.referral?.referral_code)
    setInvitedList(data?.referral?.invited_list)
    setRewardedCount(data?.referral?.rewarded_count)
    setExpectedRewardedCount(data?.referral?.expected_reward_count)
  }, [data])

  return (
    <CustomModal
      color="#3a3a3a"
      cancelHide
      visible={visible}
      onCancel={onClose}
      footer={false}
    >
      <NoteInviteReward
        isLoading={loading}
        referCode={referCode}
        invitedList={invitedList}
        rewardedCount={rewardedCount}
        expectedRewardedCount={expectedRewardedCount}
      />
    </CustomModal>
  )
}

export default NoteInviteRewardModal
