import { useContext, useEffect, useState } from 'react'
import MapContext from 'contexts/map'
import { getDongBoundary } from 'lib/api/layerApi'
import useAxios from 'lib/hooks/useAxios'
import MapLayerIcon from './MapLayerIcon'

/**
 * 지도 레이어
 * 법정동 경계선
 */
const MapLayerDongBoundary = () => {
  const { kakao } = window
  const { state, actions } = useContext(MapContext)
  const { layerState, viewState, map } = state
  const [onRequest, , data, , ,] = useAxios(getDongBoundary)
  // 폴리곤 배열 선언
  const [polygons, setPolygons] = useState([])
  // 법정동 이름 배열 선언
  const [dongNames, setDongNames] = useState([])

  useEffect(() => {
    if (Object.keys(viewState).length === 0) {
      return
    }

    if (layerState.dongBoundary) {
      if (viewState.level > 11) {
        return
      }
      // 요청 파라미터 세팅
      const payload = {
        swlat: viewState.swLatlng.getLat(),
        swlng: viewState.swLatlng.getLng(),
        nelat: viewState.neLatlng.getLat(),
        nelng: viewState.neLatlng.getLng(),
        level: viewState.level,
      }

      onRequest(payload, null, false)
    } else {
      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
      // 기존 동이름 오버레이 삭제
      dongNames.map((name) => {
        name.setMap(null)
      })
    }
  }, [layerState, viewState, map])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result || data.result?.length === 0) {
      // 기존 폴리곤 삭제
      polygons?.map((polygon) => {
        polygon.setMap(null)
      })
      return
    }

    const dongs = data.result

    // 폴리곤 배열
    let tempPolygons = []
    // 동이름 배열
    let tempDongNames = []
    dongs?.map((dong) => {
      const shpaePoints = dong.shape?.coordinates

      // multipolygon 세팅
      shpaePoints.map((parts) => {
        const polygonPath = parts.map((part) => {
          return part.map((point) => {
            return new kakao.maps.LatLng(point[0], point[1])
          })
        })
        // 지도에 표시할 다각형을 생성합니다
        const polygon = new kakao.maps.Polygon({
          path: polygonPath, // 그려질 다각형의 좌표 배열입니다
          strokeWeight: 3, // 선의 두께입니다
          strokeColor: '#D61C4E', // 선의 색깔입니다
          strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
          fillColor: '#FEF9A7', // 채우기 색깔입니다
          fillOpacity: 0.3, // 채우기 불투명도 입니다
          zIndex: 1,
        })

        // 하나씩 생성된 폴리곤을 배열에 넣기
        tempPolygons.push(polygon)
      })

      // 지역이름을 폴리곤 위에 표시합니다
      const dongNameOverlay = new kakao.maps.CustomOverlay({
        map: map, // 커스텀오버레이를 표시할 지도입니다
        content: `<div class="dongOverlay">${dong.dong_name}</div>`, // 커스텀오버레이에 표시할 내용입니다
        xAnchor: 0,
        yAnchor: 0,
        // 커스텀오버레이를 표시할 위치입니다. 위치는 다각형의 마지막 좌표로 설정합니다
        position: new kakao.maps.LatLng(
          dong.centerpoint?.coordinates?.[0],
          dong.centerpoint?.coordinates?.[1],
        ),
      })
      tempDongNames.push(dongNameOverlay)
    })

    setPolygons(tempPolygons)
    setDongNames(tempDongNames)
  }, [data])

  // 폴리곤 세팅 및 초기화
  useEffect(() => {
    if (polygons.length === 0) return

    polygons.map((polygon) => {
      polygon.setMap(map)
    })

    return () => {
      if (polygons.length === 0) return

      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [polygons])

  // 동이름 오버레이 세팅 및 초기화
  useEffect(() => {
    if (dongNames.length === 0) return

    dongNames.map((dongName) => {
      dongName.setMap(map)
    })

    return () => {
      if (dongNames.length === 0) return

      // 기존 동이름 오버레이 삭제
      dongNames.map((dongName) => {
        dongName.setMap(null)
      })
    }
  }, [dongNames])

  const onClickLayer = () => {
    // 레이어 상태값 세팅
    actions.setLayerState((prev) => {
      if (!prev.dongBoundary) {
        // zoom레벨 5로 지정
        map.setLevel(5)
      }
      return {
        ...prev,
        dongBoundary: prev.dongBoundary ? false : true,
      }
    })
  }

  return (
    <MapLayerIcon
      type="dongBoundary"
      title="법정동 경계선"
      onChange={onClickLayer}
    />
  )
}

export default MapLayerDongBoundary
