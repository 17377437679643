import {
  Label,
  LabelIcon,
  Row,
  RowWrapper,
  Value,
} from 'components/summary/style/SummaryStyle'
import useFormatArea from 'lib/hooks/useFormatArea'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const SummaryLandInfo = ({ data }) => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const land = summaryInfo?.land
  const landArea = useFormatArea(land?.land_area)

  const [usearea, setUseArea] = useState(null) //용도지역
  const [roadTypes, setRoadTypes] = useState(null) //도로접면

  useEffect(() => {
    if (!data) return

    setUseArea(data.planning?.usearea)
    setRoadTypes(data.planning?.road?.types)
  }, [data])

  return (
    <RowWrapper topspace={34}>
      {landArea && (
        <Row>
          <LabelIcon className="fal fa-expand" />
          <Label>면적</Label>
          <Value>{landArea}</Value>
        </Row>
      )}
      {land?.land_type && (
        <Row>
          <LabelIcon className="fal fa-draw-square" />
          <Label>지목</Label>
          <Value>{land?.land_type}</Value>
        </Row>
      )}
      {usearea &&
        Object.keys(usearea).filter((e) => e != '지정되지않음').length >
          1 && (
          <Row
            height={
              usearea &&
              Object.keys(usearea).filter((e) => e != '지정되지않음')
                .length > 1
                ? 60
                : 22
            }
          >
            <UseAreaWrapper>
              {Object.keys(usearea)
                .filter((e) => e != '지정되지않음')
                .map((area, index, oriArr) => {
                  return (
                    <Row key={index}>
                      <LabelIcon className="fal fa-city" />
                      <Label>
                        용도지역
                        {oriArr.length > 1 ? index + 1 : ''}
                      </Label>
                      <Value>{area}</Value>
                    </Row>
                  )
                })}
            </UseAreaWrapper>
          </Row>
        )}
      {land?.land_height && (
        <Row>
          <LabelIcon className="fal fa-mountains" />
          <Label>지형 높이</Label>
          <Value>{land?.land_height}</Value>
        </Row>
      )}
      {land?.land_shape && (
        <Row>
          <LabelIcon className="fal fa-hexagon" />
          <Label>지형 형상</Label>
          <Value>{land?.land_shape}</Value>
        </Row>
      )}

      {land?.current_use && (
        <Row>
          <LabelIcon className="fal fa-home-alt" />
          <Label>이용 상황</Label>
          <Value>{land?.current_use}</Value>
        </Row>
      )}
      {land.road_touch && (
        <Row>
          <LabelIcon className="fal fa-road" />
          <Label>도로 접면</Label>
          <Value>
            {land.road_touch}
            {roadTypes?.length > 0 ? ` ${roadTypes?.join('\n')}` : ''}
          </Value>
        </Row>
      )}
      {summaryInfo?.owners?.data &&
        summaryInfo?.owners?.data.length > 0 && (
          <>
            <Row>
              <LabelIcon className="fal fa-user" />
              <Label>소유구분</Label>
              <Value>{summaryInfo?.owners?.data[0].owner_type}</Value>
            </Row>
            <Row>
              <LabelIcon className="fal fa-calendar" />
              <Label>소유권변동일자</Label>
              <Value>{summaryInfo?.owners?.data[0].change_date}</Value>
            </Row>
            <Row>
              <LabelIcon className="fal fa-question" />
              <Label>소유권변동원인</Label>
              <Value>
                {summaryInfo?.owners?.data[0].change_reason}
              </Value>
            </Row>
          </>
        )}
    </RowWrapper>
  )
}

const UseAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export default SummaryLandInfo
