import styled from 'styled-components'
import NoteDashboardNotiListItem from './NoteDashboardNotiListItem'

const NoteDashboardNotiList = ({ data }) => {
  return (
    <Wrapper>
      {data.map(({ text, regdate }, i) => (
        <NoteDashboardNotiListItem
          key={i}
          text={text}
          regdate={regdate}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export default NoteDashboardNotiList
