import NoteDashboardPictureContainer from 'containers/notev2/NoteDashboardPictureContainer'
import NoteDashboardBlock from './NoteDashboardBlock'
import NoteDashboardPictureDate from './NoteDashboardPictureDate'
import NoteDashboardPictures from './NoteDashboardPictures'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Empty } from 'components/design'

const NoteDashboardPicture = () => {
  return (
    <NoteDashboardPictureContainer>
      {({
        dates,
        selectedDate,
        selectedPictures,
        onClickPrev,
        onClickNext,
        onClickMore,
      }) => {
        return (
          <NoteDashboardBlock title="사진">
            <More onClick={onClickMore}>더보기</More>
            {selectedDate ? (
              <>
                <NoteDashboardPictureDate
                  date={selectedDate}
                  hasPrev={
                    dates.findIndex((date) => date == selectedDate) -
                      1 >=
                    0
                  }
                  hasNext={
                    dates.findIndex((date) => date == selectedDate) +
                      1 <
                    dates.length
                  }
                  onClickPrev={onClickPrev}
                  onClickNext={onClickNext}
                />
                <NoteDashboardPictures data={selectedPictures} />
              </>
            ) : (
              <Empty>등록된 사진이 없습니다.</Empty>
            )}
          </NoteDashboardBlock>
        )
      }}
    </NoteDashboardPictureContainer>
  )
}

const More = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  cursor: pointer;

  &::after {
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    margin-left: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: ${theme.colors.gray[500]};
  }
`

export default NoteDashboardPicture
