import { message } from 'antd'
import { Button, Modal } from 'components/design'
import theme from 'lib/styles/theme'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SignupTel from './SignupTel'
import useAxios from 'lib/hooks/useAxios'
import { procMemberInsert } from 'lib/api/auth/authApi'
import { Desc, Header } from './SignupAgree'
import SignupId from './SignupId'
import SignupPassword from './SignupPassword'
import SignupAddress from './SignupAddress'
import useUserLoader from 'containers/base/hooks/useUserLoader'

/**
 * 회원가입 폼 modal
 * @param {boolean} visible modal visible 여부
 * @param {function} onCancel 닫기 함수
 * @param {function} loginCallback 초대 리워드 회원가입 완료 여부 set state
 * @param {string} memberType 회원가입 타입
 * @returns
 */
const SignupForm = ({
  visible,
  onCancel,
  loginCallback,
  memberType,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm()
  const [tel, setTel] = useState(['010', '', ''])
  const [passwordNotMatch, setPasswordNotMatch] = useState(false) //전화번호-전화번호 확인 동일 여부
  const [isVerifiedTel, setIsVerifiedTel] = useState(false) //전화번호 유효성 체크되었는지
  const [emailMessage, setEmailMessage] = useState('') //이메일 유효성 체크 메시지
  const [isValidId, setIsValidId] = useState(false) //아이디 중복체크 완료 여부
  const [isSubmit, setIsSubmit] = useState(false)

  const fileRef = useRef(null)

  const [onRequestSignup, , dataSignup, errorSignup] = useAxios(
    procMemberInsert,
    {
      redirect: false,
      resetError: true,
    },
  )

  const { onRequest } = useUserLoader(false)

  const inviteeInfo = useSelector(
    (state) => state.inviteReward.inviteeInfo,
  )
  const password = watch('password')
  const company_address_num = watch('company_address_num')

  useEffect(() => {
    register('member_type') //부동산 중개사 가입
    register('realtor_position') //직위
    register('phone_number') //전화번호
    register('accept_agreement') //약관 동의 여부
    register('nick_name') //닉네임

    //초대 코드용 파라미터
    if (inviteeInfo && memberType == 'realestate') {
      //referral_code
      register('referral_code') //초대코드
      setValue('referral_code', inviteeInfo?.referral_code)
    }

    setValue('member_type', 486)
    setValue('accept_agreement', { 1: 'Y', 2: 'Y' })
    setValue('realtor_position', '대표')
  }, [])

  const onSubmit = (data) => {
    //여러번 클릭 방지
    if (isSubmit) return

    //아이디 중복 확인
    if (!isValidId) {
      message.warning('아이디 중복 확인을 해주세요.')
      return
    }
    //비밀번호 - 비밀번호 확인 매칭 확인
    if (passwordNotMatch) {
      message.warning(
        '비밀번호와 비밀번호 확인이 서로 일치하지 않습니다.',
      )
      return
    }

    //전화번호 입력 확인
    const emptyTelCnt = tel.filter((val) => !val)
    if (emptyTelCnt.length > 0) {
      message.warning('전화번호를 입력해 주세요.')
      return
    }

    //전화번호 인증 확인
    if (!isVerifiedTel) {
      message.warning('전화번호를 인증해 주세요.')
      return
    }

    //이메일 잘 적었는지 확인
    if (emailMessage) {
      message.warning('이메일을 확인해 주세요.')
      return
    }

    setValue('phone_number', tel.join(''))

    onRequestSignup(
      Object.assign({}, getValues(), data, {
        realtor_certificate: data.realtor_certificate?.[0],
        phone_number: tel.join(''),
        nick_name: getValues('nick_name'),
      }),
    )
  }

  /**
   * 가입 결과
   */
  useEffect(() => {
    if (!dataSignup) return

    if (dataSignup.error == 0) {
      setIsSubmit(true)
      message.info('회원가입이 완료되었습니다.')

      async function fetchAndSetUser() {
        await onRequest()
        //로그인 후 초대 리워드 대상자라면 카드 등록 유도 모달 띄움
        //그냥 햄버거에서 가입한거면 모달만 모두 닫기
        loginCallback && loginCallback()
      }

      fetchAndSetUser()
    } else {
      message.error('회원가입 중 오류가 발생했습니다.')
    }
  }, [dataSignup])

  /**
   * 패스워드 같은지 확인
   * @param {*} e
   * @returns
   */
  const confirmPassword = (e) => {
    if (!e.target.value) return

    if (password != e.target.value && !passwordNotMatch) {
      setPasswordNotMatch(true)
    } else if (passwordNotMatch && password == e.target.value) {
      setPasswordNotMatch(false)
    }
  }

  /**
   * 이메일 유효성 체크
   */
  const onChangeEmail = useCallback((e) => {
    const emailRegex =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/

    const emailCurrent = e.target.value

    if (!emailRegex.test(emailCurrent)) {
      setEmailMessage('올바른 이메일 형식이 아닙니다.')
    } else {
      setEmailMessage('')
    }
  }, [])

  /**
   * 이름 입력시 닉네임 자동 입력
   */
  const onBlurName = (e) => {
    setValue('nick_name', e.target.value)
  }

  const onClickUpload = () => {
    fileRef.current.click()
  }

  return (
    <>
      <Modal
        title=""
        visible={visible}
        onCancel={onCancel}
        width={450}
        footer={null}
      >
        <Header>회원가입</Header>
        <Desc>
          {memberType == 'individual' ? '일반회원' : '부동산 중개사'}
        </Desc>
        {inviteeInfo?.user_name && memberType == 'realestate' && (
          <RecommendsPerson>
            추천인: {inviteeInfo?.user_name}
          </RecommendsPerson>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <Item>
            <Title required="Y">아이디</Title>
            <SignupId
              setIsValidId={setIsValidId}
              register={register}
              errors={errors}
            />
          </Item>
          <Item>
            <Title required="Y">비밀번호</Title>
            <SignupPassword register={register} errors={errors} />
          </Item>
          <Item>
            <Title required="Y">비밀번호 확인</Title>
            <StyledInput type="password" onChange={confirmPassword} />
            {passwordNotMatch && (
              <PasswordDesc>
                비밀번호가 서로 일치하지 않습니다.
              </PasswordDesc>
            )}
          </Item>
          <Item>
            <Title required="Y">이름</Title>
            <StyledInput
              {...register('user_name', {
                required: true,
                onBlur: onBlurName,
                onChange: onBlurName,
              })}
              className={errors?.user_name && 'error'}
            />
          </Item>
          <Item>
            <Title required="Y">이메일 주소</Title>
            <StyledInput
              type="text"
              {...register('email_address', {
                required: true,
                onChange: onChangeEmail,
              })}
              className={errors?.email_address && 'error'}
            />
            <PasswordDesc>{emailMessage}</PasswordDesc>
          </Item>
          <Item>
            <Title required="Y">전화번호</Title>
            <SignupTel
              setTel={setTel}
              tel={tel}
              isVerifiedTel={isVerifiedTel}
              setIsVerifiedTel={setIsVerifiedTel}
            />
          </Item>

          {memberType == 'realestate' && (
            <>
              <Item>
                <Title>상호</Title>
                <StyledInput
                  {...register('company_name')}
                  className={errors?.company_name && 'error'}
                />
              </Item>
              <Item>
                <Title>회사 소재지</Title>
                <SignupAddress
                  setValue={setValue}
                  register={register}
                  errors={errors}
                />
              </Item>
              <Item>
                <Title>상세 주소</Title>
                <StyledInput
                  {...register('company_address_detail')}
                  className={errors?.company_address_detail && 'error'}
                />
              </Item>
              <Item>
                <Title>직위</Title>
                <StyledSelect
                  onChange={(e) => {
                    setValue('realtor_position', e.target.value)
                  }}
                >
                  <option value="대표">대표</option>
                  <option value="소속공인중개사">소속공인중개사</option>
                  <option value="중개보조원">중개보조원</option>
                </StyledSelect>
              </Item>
              <Item>
                <Title>중개업등록번호</Title>
                <StyledInput
                  {...register('realtor_number')}
                  className={errors?.realtor_number && 'error'}
                  disabled={!company_address_num}
                />
              </Item>
              <Item>
                <Title>공제증서</Title>
                <FlexWrapper>
                  <StyledInput
                    {...register('realtor_certificate')}
                    type="file"
                  />
                  <Button onClick={onClickUpload}>업로드</Button>
                </FlexWrapper>
              </Item>
            </>
          )}

          <Button
            disabled={isSubmit}
            color="primary"
            type="submit"
            expand
          >
            가입완료
          </Button>
        </form>
      </Modal>
    </>
  )
}

const RecommendsPerson = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.primary[400]};
  text-align: center;
`

const Item = styled.div`
  width: 100%;
  min-height: 66px;
  margin-bottom: 16px;
`

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.gray[700]};

  ${({ required }) =>
    required == 'Y' &&
    `
        &::after {
            content: '*';
            color: ${theme.colors.tint.danger};
            font-weight: 700;
            margin-left: 2px;
        }
    `};
`

export const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border: 1px solid ${theme.colors.gray[100]};
  outline: none;
  background-color: ${theme.colors.gray[100]};
  padding: 0 8px;

  &.error {
    border: 1px solid ${theme.colors.tint.danger};
  }

  &[type='file'] {
    display: inline-block;
    height: 40px;
    line-height: 40px;
  }

  ${({ tel }) =>
    tel == 'Y' &&
    `
        flex: 1;
    `};
`

export const StyledSelect = styled.select`
  border-radius: 6px;
  border: 1px solid ${theme.colors.gray[100]};
  outline: none;
  background-color: ${theme.colors.gray[100]};
  width: 35%;
  height: 40px;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    flex-shrink: 0;
  }
`

export const PasswordDesc = styled.div`
  font-size: 12px;
  color: ${theme.colors.tint.danger};
  margin-top: 4px;

  ${({ mb }) =>
    mb &&
    `
    margin-bottom: 6px;
  `}
`

export default SignupForm
