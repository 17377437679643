import { Modal, Tag } from 'components/design'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'
import TesterPaymentText from '../TesterPaymentText'

/**
 * 등기부 결제 전 금액 확인 모달
 */
const NotePaymentDeunggibuPaymentModal = ({
  visible,
  onOk,
  onCancel,
  count,
  amount,
}) => {
  const { hasPlan } = useNoteHasPlan()
  const planInfo = useSelector((state) => state.auth.planInfo)
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  return (
    <Modal
      title="아래와 같이 결제를 진행할까요?"
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText="결제하기"
      okButtonProps={{
        color: 'blue',
      }}
    >
      {hasPlan && (
        <Block>
          <BlockTitle>결제수단</BlockTitle>
          <Row>
            <Tag color="gray">등록 카드</Tag>
            <span>
              {planInfo?.extra_vars?.card?.issuerName}{' '}
              {planInfo?.extra_vars?.card?.number?.slice(-4)}
            </span>
          </Row>
        </Block>
      )}

      <Block gray>
        <Row border>
          <BlockTitle gray>등기부등본 {count}건</BlockTitle>
          <span>{numberWithCommas(amount)}원</span>
        </Row>
        <Row strong>
          <BlockTitle nomargin>최종 결제금액</BlockTitle>
          <span>{numberWithCommas(amount)}원</span>
        </Row>
      </Block>

      {loginInfo?.other_groups.includes('tester') && (
        <TesterPaymentText />
      )}
    </Modal>
  )
}

const Block = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid ${theme.colors.gray[150]};

  ${({ gray }) =>
    gray &&
    `
        margin-top: 12px;
        background-color: ${theme.colors.gray[100]};
        border: none;
  `}
`

const BlockTitle = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;

  ${({ gray }) =>
    gray &&
    `   
        color: ${theme.colors.gray[600]};
  `}

  ${({ nomargin }) =>
    nomargin &&
    `
        margin-bottom: 0;
  `}
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;

  ${({ border }) =>
    border &&
    `
      margin-bottom: 12px;
      border-bottom: 1px dashed ${theme.colors.gray[300]};
  `}

  ${({ strong }) =>
    strong &&
    `
      span {
        font-size: 18px;
      }
  `}
`

export default NotePaymentDeunggibuPaymentModal
