import { useFormContext } from 'react-hook-form'
import NoteAdColumnElevator from '../NoteAdColumnElevator'
import NoteAdColumnFeature from '../NoteAdColumnFeature'
import NoteAdColumnMainRoad from '../NoteAdColumnMainRoad'
import NoteAdColumnNewBuilding from '../NoteAdColumnNewBuilding'
import NoteAdColumnParking from '../NoteAdColumnParking'
import NoteAdColumnPet from '../NoteAdColumnPet'
import NoteAdColumnRoofTop from '../NoteAdColumnRoofTop'
import NoteAdColumnRoomStructure from '../NoteAdColumnRoomStructure'
import { ValidateWarn } from 'components/jmapnotev2/NoteAd/form/NoteAdFormStyle'
import {
  building,
  residential,
  room,
  store,
} from 'components/jmapnotev2/NoteAd/form/propertyTypes'

/**
 * 원룸 특징 컬럼 모음
 * 신축여부, 특징, 큰길가, 주차가능, 엘리베이터, 애완동물, 원룸구조, 옥탑
 * @param {*} param0
 * @returns
 */
const NoteAdColumnOneRoom = ({ parking = false }) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext()

  const propertyType = watch('article.category1')
  const tradeType = watch('trade.type')
  const exclusiveArea = watch('exclusive_area')
  const contractArea = watch('contract_area')
  const spc1 = watch('article.spc1')
  const spc2 = watch('article.spc2')
  const spc4 = watch('article.spc4')

  const getVisible = () => {
    if (
      [
        '아파트',
        '오피스텔',
        '아파트 분양권',
        '오피스텔 분양권',
      ].includes(propertyType) &&
      (exclusiveArea || contractArea)
    ) {
      return (
        exclusiveArea <= 50 || (!exclusiveArea && contractArea <= 50)
      )
    } else if (residential.concat(building).includes(propertyType)) {
      //건물 전체, 빌딩
      return spc4 <= 50
    } else if (room.concat(store).includes(propertyType)) {
      //주거형의 일부, 상가
      return spc2 <= 50 || (!spc2 && spc1 <= 50)
    }
  }

  //거래유형이 매매면 안보여줌
  if (tradeType == 'A1') return

  if (getVisible())
    return (
      <>
        {errors?.oneroomInfo?.oneroomFeature?.message && (
          <ValidateWarn>
            {errors?.oneroomInfo?.oneroomFeature?.message}
          </ValidateWarn>
        )}
        <NoteAdColumnNewBuilding />
        <NoteAdColumnFeature />
        <NoteAdColumnMainRoad />
        {parking && <NoteAdColumnParking required />}
        <NoteAdColumnElevator />
        <NoteAdColumnPet />
        <NoteAdColumnRoomStructure required />
        <NoteAdColumnRoofTop half={parking} />
      </>
    )
}

export default NoteAdColumnOneRoom
