import theme from 'lib/styles/theme'
import { orderBy } from 'lodash-es'
import { math, rgba } from 'polished'
import { useMemo } from 'react'
import styled from 'styled-components'
import { unitFormat } from 'utils'

const SummaryLrGraph = ({
  data,
  centerPoint,
  // limitDistance = 0.1
}) => {
  // const limitDistance = 0.025

  const icons = {
    elementary_school: 'fad fa-school',
    middle_school: 'fad fa-school',
    high_school: 'fad fa-school',
    bus_stop: 'fad fa-bus',
    ic: 'fad fa-dungeon',
    subway_station: 'fad fa-subway',
    hospital: 'fad fa-syringe',
    police: 'fad fa-siren',
    fire: 'fad fa-fire-extinguisher',
    cctv: 'fad fa-cctv',
    mart: 'fad fa-shopping-cart',
    park: 'fad fa-tree-alt',
    restaurant: 'fad fa-utensils',
  }

  const colors = {
    elementary_school: '#c079de',
    middle_school: '#c079de',
    high_school: '#c079de',
    ic: '#2b91ff',
    hospital: '#39ccb1',
    police: '#266aad',
    fire: '#d45063',
    cctv: '#e89d67',
    mart: '#ecc556',
    park: '#52ca7a',
    restaurant: '#62b0ff',
  }

  const limitDistance = 0.01

  const getMaxDistance = (data) => {
    const max = data.reduce((prev, current) => {
      if (prev > current.distance) return prev
      return current.distance
    }, 0)

    return Math.ceil(max / 1000) / 100
  }
  const maxDistance = useMemo(() => getMaxDistance(data), [data])

  return (
    <>
      <Wrapper>
        <MaxCircle>
          <Max>{Math.round(maxDistance * 100)}km</Max>
          <Circle>
            <Range>{limitDistance * 100}km</Range>

            {data.map((item, i) => (
              <Dot
                key={i}
                type={item.type}
                color={colors[item.type]}
                centerPoint={centerPoint?.coordinates}
                distance={item.distance}
                x={item.distanceX}
                y={item.distanceY}
                limitDistance={limitDistance * 100000}
                maxDistance={maxDistance * 100000}
              >
                {item.type === 'ic' ? (
                  'IC'
                ) : (
                  <i className={icons[item.type]}></i>
                )}

                {item.name && (
                  <OverlayInfo>
                    <div>{item.name}</div>
                    <OverlayDIstance>
                      {unitFormat(Math.round(item.distance))}
                    </OverlayDIstance>
                  </OverlayInfo>
                )}
              </Dot>
            ))}

            <Center />
            <CenterDot />
          </Circle>
        </MaxCircle>
      </Wrapper>

      <Help>
        이해를 돕기 위한 시각 자료로써 실제 위치와 상이할 수 있습니다.
      </Help>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  /* padding-bottom: 2rem; */
`

const MaxCircle = styled.div`
  position: relative;
  width: 90%;
  padding-bottom: 90%;
  border-radius: 100%;
  border: 1px dashed ${theme.colors.gray[300]};
`

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 74%;
  padding-bottom: 74%;
  border-radius: 100%;
  border: 1px solid ${rgba(theme.colors.primary[400], 0.1)};
  background-color: ${rgba(theme.colors.primary[400], 0.05)};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 68%;
    padding-bottom: 68%;
    border-radius: 100%;
    /* border: 1px solid ${theme.colors.gray[100]}; */
    background-color: ${rgba(theme.colors.primary[400], 0.05)};
  }
`

const Range = styled.div`
  position: absolute;
  top: 4%;
  right: 7%;
  z-index: 0;

  color: ${rgba(theme.colors.primary[500], 0.7)};
  font-size: 0.75rem;
`
const Max = styled.div`
  position: absolute;
  top: 5%;
  right: 9%;
  z-index: 0;

  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
`

const Center = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-color: ${rgba(theme.colors.primary[400], 0.1)};
  border-radius: 100%;
`

const CenterDot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100%;
  background-color: ${theme.colors.primary[400]};
  border: 3px solid white;
  box-shadow: ${rgba(theme.colors.primary[400], 1)} 0 0 0.5rem;
`

const OverlayInfo = styled.div`
  pointer-events: none;
  position: absolute;
  top: -0.25rem;
  left: 50%;
  transform: translate(-50%, -100%);

  background-color: white;
  border-radius: 1rem;
  padding: 4px 10px;
  box-shadow: ${theme.shadow['card-1']};
  border: 1px solid ${theme.colors.gray[300]};

  color: ${theme.colors.gray[900]};
  font-size: 12px;
  text-align: center;
  white-space: nowrap;

  transition: all.2s ease;
  opacity: 0;
  visibility: hidden;
`
const OverlayDIstance = styled.div`
  color: ${theme.colors.gray[600]};
`

const Dot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);

  ${({ x, y, distance, limitDistance, maxDistance }) => {
    const wrapperRatio = 1.36
    const zoom = 50 / 0.3 / limitDistance

    // 상하 반전
    y = y * -1

    // 첫번째 원: 100m
    if (distance <= 100) {
      return `
        left: ${x * zoom + 50}%;
        top: ${y * zoom + 50}%;
      `
    }

    // 두번째 원: 400m (3:1로 축소)
    if (distance <= 400) {
      let effectiveDistance = 100 + (distance - 100) / 3
      let effectiveRatio = effectiveDistance / distance
      return `
        left: ${x * effectiveRatio * zoom + 50}%;
        top: ${y * effectiveRatio * zoom + 50}%;
      `
    }

    // 세번째 원: 1km (6:1로 축소)
    if (distance <= 1000) {
      let effectiveDistance = 200 + (distance - 400) / 6
      let effectiveRatio = effectiveDistance / distance
      return `
        left: ${x * effectiveRatio * zoom + 50}%;
        top: ${y * effectiveRatio * zoom + 50}%;
      `
    }

    // 네번째 원: maxDistance (10~100:1로 축소)
    if (distance > 1000) {
      let effectiveDistance =
        300 + (distance - 1000) / ((maxDistance - limitDistance) / 100)
      let effectiveRatio =
        (effectiveDistance / distance) * (wrapperRatio / 1.333)
      return `
        left: ${x * effectiveRatio * zoom + 50}%;
        top: ${y * effectiveRatio * zoom + 50}%;
      `
    }
  }}

  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: ${theme.colors.gray[500]};
  border-radius: 100%;
  border: 2px solid white;

  color: white;
  font-size: 0.875rem;

  ${({ color }) => color && `background-color: ${color};`}

  &:hover {
    z-index: 3;
    ${OverlayInfo} {
      opacity: 1;
      visibility: visible;
    }
  }
`

const Help = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
`

export default SummaryLrGraph
