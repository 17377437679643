import RadioButton from 'components/design/Radio/Radio'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  InnerTableContent,
  StyledTable,
  headerWidth,
  RadioButtonsWrap,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsDetail14 = ({ onUpdate }) => {
  const { watch } = useFormContext()

  const d_hyeonjangannaeja = watch('d_hyeonjangannaeja')
  const d_junggaebojowon_sinbungoji = watch(
    'd_junggaebojowon_sinbungoji',
  )
  const d_descript_type = watch('d_descript_type')

  if (d_descript_type != '주거용 건축물') return

  return (
    <>
      <StyledTable>
        <Row rowspan={2} noborderbottom>
          <TableHeader width={headerWidth}>⑭ 현장안내</TableHeader>
          <RowSpanInner
            noborderbottom
            rowspan={1}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>
              현장안내자
            </TableSubHeader>
            <InnerTableContent
              headerwidth={headerWidth}
              checkboxes
              fullWidth
            >
              <StyledRadioButtonsWrap width={300}>
                <RadioButton
                  label="개업공인중개사"
                  checked={d_hyeonjangannaeja == '개업공인중개사'}
                  name="개업공인중개사"
                  onChange={(e) =>
                    onUpdate('d_hyeonjangannaeja', '개업공인중개사')
                  }
                />
                <RadioButton
                  label="소속공인중개사"
                  checked={d_hyeonjangannaeja == '소속공인중개사'}
                  name="d_hyeonjangannaeja"
                  onChange={(e) =>
                    onUpdate('d_hyeonjangannaeja', '소속공인중개사')
                  }
                />
                <RadioButton
                  label="중개보조원"
                  checked={d_hyeonjangannaeja == '중개보조원'}
                  name="d_hyeonjangannaeja"
                  onChange={(e) =>
                    onUpdate('d_hyeonjangannaeja', '중개보조원')
                  }
                />
                &#40;신분고지 여부 &#58;{' '}
                <RadioButton
                  label="예"
                  fit
                  checked={d_junggaebojowon_sinbungoji == 'Y'}
                  name="d_junggaebojowon_sinbungoji"
                  onChange={(e) =>
                    onUpdate('d_junggaebojowon_sinbungoji', 'Y')
                  }
                />
                <RadioButton
                  label="아니오"
                  fit
                  checked={d_junggaebojowon_sinbungoji == 'N'}
                  name="d_junggaebojowon_sinbungoji"
                  onChange={(e) =>
                    onUpdate('d_junggaebojowon_sinbungoji', 'N')
                  }
                />{' '}
                &#41;
                <RadioButton
                  label="해당없음"
                  checked={d_hyeonjangannaeja == '해당없음'}
                  name="d_hyeonjangannaeja"
                  onChange={(e) =>
                    onUpdate('d_hyeonjangannaeja', '해당없음')
                  }
                />
              </StyledRadioButtonsWrap>
            </InnerTableContent>
          </RowSpanInner>
        </Row>
      </StyledTable>
      <AddedDesc full>
        <div>
          <div>
            &#8251; &#34;중개보조원&#34;이란 공인중개사가 아닌
            사람으로서 개업공인중개사에 소속되어 중개대상물에 대한
            현장안내 및 일반서무 등 개업공인중개사의 중개업무와 관련된
            단순한 업무를 보조하는 사람을 말합니다.
          </div>
          <div>
            &#8251; 중개보조원은 「공인중개사법」 제18조의4에 따라
            현장안내 등 중개업무를 보조하는 경우 중개의뢰인에게 본인이
            중개보조원이라는 사실을 미리 알려야 합니다.
          </div>
        </div>
      </AddedDesc>
    </>
  )
}

const StyledRadioButtonsWrap = styled(RadioButtonsWrap)`
  align-items: center;
  flex-wrap: wrap;
`

const AddedDesc = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: -7px;
  margin-bottom: 16px;
  padding-top: 2px;
  padding-bottom: 3px;
  padding-left: 8px;
  border-top: 1px solid ${theme.colors.base.white};
  border-right: 2px solid ${theme.colors.gray[300]};
  border-left: 2px solid ${theme.colors.gray[300]};
  border-bottom: 2px solid ${theme.colors.gray[300]};
  z-index: 1;
  font-size: 14px;
  line-height: 130%;
`

export default NoteDocsDetail14
