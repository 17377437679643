import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { message } from 'antd'
import { Button } from 'components/design'
import axios from 'axios'

/**
 * 계정관리 - 프로필사진
 * @returns
 */
const NoteAccountProfile = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const inputRef = useRef()
  const [imageSrc, setImageSrc] = useState(
    loginInfo?.profile_image ?? '',
  )

  /**
   * 업로드 버튼 클릭 시 input type="file" 태그 클릭
   */
  const onClickUpload = () => {
    // console.log('inputRef', inputRef?.current)
    inputRef?.current?.click()
  }

  /**
   * 이미지 업로드
   * @param {*} e
   */
  const onChangeImageInput = (e) => {
    if (!e.target.files?.[0]) {
      return
    }

    // 파일 크기 제한 (10MB)
    if (e.target.files?.[0]?.size > 1048576 * 10) {
      message.error(
        '파일 크기가 허용되는 한도 10MB를 초과하여 파일을 업로드할 수 없습니다.',
      )
      return
    }

    const formData = new FormData()
    formData.append('member_srl', loginInfo.member_srl)
    formData.append('image', e.target.files?.[0])

    axios
      .post(
        '/jmembership/procJmembershipChangeProfileImage',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((resp) => {
        if (resp?.data) {
          setImageSrc(resp.data?.src ?? '')
        }
      })
  }

  return (
    <>
      <ProfileContainer hasimg={imageSrc ? 'Y' : 'N'}>
        {imageSrc && (
          <ImgWrap>
            <img src={imageSrc} />
          </ImgWrap>
        )}
        <Button onClick={onClickUpload} circle>
          <i className="far fa-pen"></i>
        </Button>
      </ProfileContainer>
      <InputFileWrap>
        <input
          ref={inputRef}
          type="file"
          style={{ display: 'none' }}
          name="profile_image"
          accept="image/*"
          onChange={onChangeImageInput}
        />
      </InputFileWrap>
    </>
  )
}

const ProfileContainer = styled.div`
  display: flex;
  align-items: flex-end;

  ${({ hasimg }) =>
    hasimg == 'Y' &&
    `
    button {
      margin-left: -15px;
    }  
  `}
`

const ImgWrap = styled.div`
  width: 140px;
  height: auto;
  border-radius: 12px;
  & > img {
    width: 100%;
  }
`
const InputFileWrap = styled.div`
  display: flex;
  width: 100%;
`

export default NoteAccountProfile
