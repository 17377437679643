import axios from 'axios'
import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Addr3dsahpe from './Addr3dshape'
import { Empty } from 'components/design'

/**
 * 건물 정보 3d 모델
 */
const SummaryBuilding3d = () => {
  const { state, actions } = useContext(MapContext)
  const { viewer3dDatas } = state
  const { building } = useSelector((state) => state.summary.summaryInfo)
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  const [isNotSupport3D, setIsNotSupport3D] = useState(false)

  /**
   * 3D모델링 데이터 세팅
   */
  useEffect(() => {
    // 초기화
    let mounted = true

    if (!viewer3dDatas[currentAddressId]) {
      axios
        .get(`/map/api/3d`, {
          params: {
            address_id: currentAddressId,
            limits: '15/1000',
          },
        })
        .then((response) => {
          if (
            response.data.total_size &&
            response.data.land_outline &&
            mounted
          ) {
            actions.setViewer3dDatas({
              ...state.viewer3dDatas,
              [currentAddressId]: response.data,
            })
          }
          if (mounted && response.data.message === 'TOO_LARGE') {
            setIsNotSupport3D(true)
          }
        })
    }

    return () => {
      // 초기화
      setIsNotSupport3D(false)
      mounted = false
    }
  }, [currentAddressId, building])
  return (
    <Model>
      {!isNotSupport3D &&
        viewer3dDatas?.[currentAddressId] &&
        Object.keys(viewer3dDatas[currentAddressId])?.length > 0 && (
          <Addr3dsahpe data={viewer3dDatas[currentAddressId]} />
        )}
      {isNotSupport3D && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={'3D모델링을 지원하지 않는 건물입니다.'}
        />
      )}
    </Model>
  )
}

const Model = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 20px;

  background-color: ${theme.colors.gray[50]};
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: $border-radius-2;
  overflow: hidden;

  & > canvas {
    display: block;
  }
`

export default SummaryBuilding3d
