import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import styled from 'styled-components'
import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/pagination/pagination.scss' // Pagination module
import theme from 'lib/styles/theme'

const NoteDashboardRpAptSelectList = ({
  apts,
  onClickApt,
  selectedApt,
}) => {
  return (
    <StyledSwiper slidesPerView={'auto'} spaceBetween={12}>
      {apts.map((item, i) => {
        return (
          <SwiperSlide
            key={`dashboard_apt_${i}`}
            onClick={() => onClickApt(item)}
            style={{
              width: 'auto',
            }}
          >
            <AptItem active={selectedApt?.apt_id == item.apt_id}>
              <Number active={selectedApt?.apt_id == item.apt_id}>
                {i + 1}
              </Number>
              {item.apt_name}
            </AptItem>
          </SwiperSlide>
        )
      })}
    </StyledSwiper>
  )
}

const StyledSwiper = styled(Swiper)`
  margin-bottom: 16px;
`

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.gray[200]};
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;

  ${({ active }) =>
    active &&
    `
      background-color: ${theme.colors.blue[400]};
      color: ${theme.colors.base.white};
  `}
`

const AptItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.gray[700]};
  background-color: ${theme.colors.gray[50]};
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.blue[50]};
    color: ${theme.colors.blue[500]};

    ${Number} {
      background-color: ${theme.colors.blue[400]};
      color: ${theme.colors.base.white};
    }
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.blue[50]};
    color: ${theme.colors.blue[500]};
  `}
`

export default NoteDashboardRpAptSelectList
