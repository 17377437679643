import { Title } from 'components/jmapnotev2/style/NoteDocsStyle'
import { ContractWrapper } from '../style/NoteStyle'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import theme from 'lib/styles/theme'
import RadioButton from 'components/design/Radio/Radio'
import ColorInput from '../elements/ColorInput'
import { useInput } from 'lib/hooks/useInput'
import { useFormContext } from 'react-hook-form'

/**
 * 개인정보동의서 작성폼
 * @returns
 */
const NoteDocsConsent = ({ onUpdate }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const { watch, register } = useFormContext()
  const consent_date = watch('consent_date')
  const consent_name = watch('consent_name')
  // const consent_collect = watch('consent_collect')
  // const consent_provision = watch('consent_provision')

  return (
    <Wrapper id="print-map-note-consent">
      <Title mb>개인정보 수집 및 이용 동의서</Title>
      <Row>
        {loginInfo.company_name || ''}(이하 ‘사무소'라고 합니다)는
        개인정보 보호 규정을 준수하여 귀하의 개인정보보호에 최선을
        다하고 있으며 개인정보보호법 등 관련 법령에 근거하여 고객관리,
        계약서 작성 등 각종 서비스의 제공을 위해 아래와 같은 개인정보를
        수집 및 처리하고자 합니다.
      </Row>
      <Row>
        다음의 내용을 자세히 읽어보시고 모든 내용을 이해하신 후 동의
        여부를 결정해주시기 바랍니다.
      </Row>
      <Row>
        <strong>제1조(개인정보 수집 및 이용 목적)</strong>
        이용자가 제공한 모든 정보는 다음의 목적을 위해 이용하며, 목적
        이외의 용도로는 사용되지 않습니다.
        <ul>
          <li>부동산 거래를 위한 매물</li>
          <li>부동산 거래를 위한 계약서 작성</li>
          <li>지속가능한 서비스 제공을 위한 고객관리</li>
        </ul>
      </Row>
      <Row>
        <strong>제2조(개인정보 수집 및 이용 항목)</strong>
        사무소는 개인정보 수집 목적을 위하여 다음과 같은 정보를
        수집합니다.
        <br />
        <ul>
          <li>이름, 주소, 연락처, 주민등록번호</li>
        </ul>
      </Row>
      <Row numberlist>
        <strong>제3조(개인정보 보유 및 이용 기간)</strong>
        <br />
        <ul>
          <li>
            수집한 개인정보는 개인정보의 수집 및 이용목적이 달성되면
            지체 없이 파기합니다.
          </li>
          <li>
            다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안
            보존합니다.
            <ul>
              <li>
                상법, 전자금융거래법 등 관계법령의 규정에 의하여 보존할
                필요가 있는 경우 관계법령에서 정한 일정기간 동안
                개인정보를 보관합니다.
              </li>
              <li>
                부동산 계약서는 공인중개사법 및 공인중개사법 시행령에
                따라 거래 기록 보존의 의무가 있으므로 계약일로부터 5년간
                보관합니다.
              </li>
            </ul>
          </li>
        </ul>
      </Row>
      <Row>
        <strong>제4조(개인정보 제 3자 제공 안내)</strong>
        <br />
        {loginInfo.company_name || ''}는 서비스 향상을 위해 아래와 같이
        개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시
        개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고
        있습니다. 개인정보 위탁처리 기관 및 업무 내용은 아래와 같습니다.
        <ul>
          <li>수탁업체: 매물관리 서비스 제공처 (주식회사 주택)</li>
          <li>위탁업무 내용: 부동산 매물관리 소프트웨어 제공</li>
          <li>
            개인정보의 보유 및 이용기간: 회원탈퇴 시 혹은 위탁계약 종료
            시까지
          </li>
        </ul>
      </Row>
      <Row>
        <strong>제5조(동의 거부 관리)</strong>
        <br />
        귀하는 본 안내에 따른 개인정보 수집·이용에 대하여 동의를
        거부하실 수 있으며, 이에 따라 제공되는 중개서비스가 제한될 수
        있으나 그에 대한 불이익은 없습니다.
      </Row>
      <Row>
        본인은 위의 동의서 내용을 충분히 숙지하였으며, 위와 같이
        개인정보를 수집·이용하는데 동의합니다.
      </Row>
      <Date>
        <ColorInput
          type="date"
          value={consent_date}
          selected={consent_date ? moment(consent_date).toDate() : ''}
          onChange={(e) =>
            onUpdate(
              'consent_date',
              e ? moment(e).format('YYYY-MM-DD') : '',
            )
          }
        />
      </Date>
      <Bottom>
        <Sign>
          개인정보 제공자 : &nbsp;
          <ColorInput
            type="text"
            defaultValue={consent_name}
            onBlur={(e) => onUpdate('consent_name', e.target.value)}
          />
          <span>( 서 명 )</span>
        </Sign>
      </Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 120px;
`

const Row = styled.div`
  margin-bottom: 16px;
  strong {
    margin-bottom: 6px;
  }

  ul {
    padding-inline-start: 20px;
  }

  li {
    list-style-type: disc;
  }

  ${({ numberlist }) =>
    numberlist &&
    `
    
    & > ul > li {
      list-style-type: desc;
    }
  `}
`

const Date = styled.div`
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Sign = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  span {
    margin-left: 10px;
    color: ${theme.colors.gray[400]};
    letter-spacing: 10px;
  }
`

const CheckboxWrap = styled.div`
  & > div {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

export default NoteDocsConsent
