import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import { Modal } from 'components/design'
import { Input } from 'components/design'
import { modifyChannel } from 'lib/api/note/channelApi'
import useChannels from 'containers/note/channel/hooks/useChannels'
import useAxios from 'lib/hooks/useAxios'
import { useDispatch } from 'react-redux'
import { setSelectedChannelInfo } from 'modules/channel'

const Label = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 700;
`

const InputWrap = styled.div``

const SettingsChannelModify = ({
  visible,
  setVisible,
  selectedChannelInfo,
}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const [onRequest, loading, data, error, reset] =
    useAxios(modifyChannel)

  const dispatch = useDispatch()
  const { _getChannels } = useChannels()

  useEffect(() => {
    setName(selectedChannelInfo?.name)
    setDescription(selectedChannelInfo?.description)
  }, [selectedChannelInfo])

  useEffect(() => {
    if (visible) {
      setName(selectedChannelInfo?.name)
      setDescription(selectedChannelInfo?.description)
    } else {
      setName('')
      setDescription('')
    }
  }, [selectedChannelInfo, visible])

  useEffect(() => {
    if (data?.result?.message === 'success') {
      setVisible(false)
      _getChannels()
      reset()
      dispatch(
        setSelectedChannelInfo(
          Object.assign({}, selectedChannelInfo, {
            name: name,
            description: description,
          }),
        ),
      )
    }
  }, [data])

  const onCancel = useCallback(() => {
    setVisible((prev) => !prev)
  }, [visible])

  const onOk = useCallback(async () => {
    await onRequest({
      channelSrl: selectedChannelInfo?.channel_srl,
      name,
      description,
    })
  }, [onRequest, data, selectedChannelInfo, name, description])

  return (
    <Modal
      visible={visible}
      title="채널 정보 수정"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Label>채널명</Label>
      <InputWrap>
        <Input
          expand
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </InputWrap>

      <Label>채널 설명</Label>
      <InputWrap>
        <Input
          expand
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </InputWrap>
    </Modal>
  )
}

export default SettingsChannelModify
