export const explainText = {
  신홍보확인서: (
    <>
      <li>
        <span>신홍보확인서 확인이란?</span>
        <div>
          · 신홍보확인서를 통해 등록한 매물의 진정성, 거래가능 여부 등을
          확인하는 매물검증방법
        </div>
      </li>
      <li>
        <span>
          의뢰인에게 매물 검증, 개인정보 제공, 매물 등록에 대한 동의를
          받아야 합니다.
        </span>
      </li>
      <li>
        <span>
          홍보확인서양식으로 제출하지 않고 공인중개사가 매물 등록시
          입력한 값으로 제출됩니다.
        </span>
      </li>
      <li>
        <span>
          매물의 소유자에 따라 아래와 같이 선택하여 등록합니다.
        </span>
        <div>
          · 소유자구분 &#58; 개인매물, 법인매물, 외국인매물, 위임장매물
          <br />
          <i className="fal fa-info-circle"></i> 개인매물 &#58; 의뢰인이
          소유자인 매물
          <br />
          <i className="fal fa-info-circle"></i> 외국인매물 &#58; 외국인
          소유자가 의뢰한 매물
          <br />
          <i className="fal fa-info-circle"></i> 법인매물 &#58;
          법인&#40;회사, 종교, 종친&#41; 매물
          <br />
          <i className="fal fa-info-circle"></i> 위임장매물 &#58;
          소유자가 의뢰인이 아닌 매물
          <br />
          · 위임장 첨부를 해야 하며 위임장은 날인&#40;서명시
          실패&#41;으로만 가능합니다.
          <br />
          · 매물 의뢰인은 공인중개업소가 될 수 없습니다.
          <br />· 위임자와 위임받는자 주소&#40;지번포함&#41;는 모두
          기재하여야 합니다.
        </div>
      </li>
      <li>
        <span>실명확인 검증 &#58;</span> 의뢰인의 이름과 핸드폰번호로
        네이버 회원정보를 통해 실명 확인
        <div>
          <i className="fal fa-info-circle"></i> 네이버아이디를 기재한
          경우에는 아이디와 이름으로 확인
          <br />
          · 의뢰인이 네이버 회원이 아닌 경우 신홍보확인서 등록불가.
          <br />· 소유주가 네이버 회원이 아닐경우 위임장 매물 또는
          모바일v2 검증이 가능합니다.
        </div>
      </li>
    </>
  ),
  홍보확인서: (
    <>
      <li className="nomargin">
        <span>
          홍보확인서는 매도인 또는 임대인 서명을 통해 매물의 거래 가능
          여부를 확인하는 검증 방식입니다.
        </span>
      </li>
      <li className="nomargin">
        <span>
          매물등록 완료 이후 매물정보가 기입된 홍보확인서 출력이
          가능합니다.
        </span>
      </li>
    </>
  ),
  '전화 확인': null,
  '모바일 확인': null,
  '모바일 확인2': null,
  현장확인: null,
}
