import theme from 'lib/styles/theme'
import styled from 'styled-components'
import SummaryRealPriceItem from './SummaryRealPriceItem'

const SummaryRealPriceTable = ({ dataSource }) => {
  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <tr>
            <th>유형</th>
            <th>거래일</th>
            <th className="price">가격</th>
            <th className="area">대지면적</th>
            <th className="area">
              {dataSource[0]?.exclusive_area ? '전용면적' : '연면적'}
            </th>
          </tr>
        </thead>

        <tbody>
          {dataSource.map((item, index) => {
            return <SummaryRealPriceItem item={item} key={index} />
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  padding: 0.875rem 1rem 0;
`

const StyledTable = styled.table`
  width: 100%;
  /* border-collapse: collapse; */

  th {
    padding: 0.5rem;
    border-bottom: 1px solid ${theme.colors.gray[100]};

    color: ${theme.colors.gray[600]};
    font-size: 0.875rem;
    font-weight: 500;
  }

  th,
  td {
    padding: 7px 0.5rem;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    text-align: center;
    font-size: 13px;
    &.date {
      color: ${theme.colors.gray[700]};
    }
    &.price {
      font-weight: 700;
      text-align: right;
    }
    &.area {
      text-align: center;
    }
  }
`

export default SummaryRealPriceTable
