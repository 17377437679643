import { createContext, useEffect, useState } from 'react'

const SegmentContext = createContext()

export const SegmentProvider = ({ size, children }) => {
  const [sizeState, setSizeState] = useState(size)

  // useEffect(() => {
  //   setSizeState(size)
  // }, [size])

  const value = {
    state: {
      sizeState,
    },
    actions: {
      setSizeState,
    },
  }

  return (
    <SegmentContext.Provider value={value}>
      {children}
    </SegmentContext.Provider>
  )
}

export default SegmentContext
