export const room = ['빌라/연립', '원룸', '다세대주택'] //주거형의 방 또는 일부
export const residential = ['주택', '다가구주택', '단독주택'] //주거형의 건물 전체
export const store = ['상가', '사무실', '상가점포', '지식산업센터'] //사무실, 상가, 아파트형공장
export const building = [
  '상가건물',
  '숙박/콘도',
  '빌딩/건물',
  '공장/창고',
  '고시원',
  '기타',
] //건물(빌딩,숙박,공장,창고)
