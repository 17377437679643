import { setIsInfoBubbleVisible } from 'modules/noteDongho'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 단지 추가 안내 버블 로직
 */
const NoteDonghoBubbleContainer = ({ children }) => {
  const isSelected = useRouteMatch('/note/v2/dongho/:id')
  const isInfoBubbleVisible = useSelector(
    (state) => state.noteDongho.isInfoBubbleVisible,
  )
  const [visibleBubble, setVisibleBubble] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isSelected) return
    setVisibleBubble(
      setIsInfoBubbleVisible(
        localStorage.getItem('noteDonghoBubbleVisible') == 'N'
          ? false
          : true,
      ),
    )
    dispatch(
      setIsInfoBubbleVisible(
        localStorage.getItem('noteDonghoBubbleVisible') == 'N'
          ? false
          : true,
      ),
    )
  }, [])

  /**
   * 스크롤 위에 있을 때만 보여줌
   */
  const onScroll = useCallback(
    (e) => {
      if (localStorage.getItem('noteDonghoBubbleVisible') == 'N') return
      if (e.target.scrollTop > 10 && isInfoBubbleVisible) {
        if (!document.getElementById('dongho-info-bubble')) return
        dispatch(setIsInfoBubbleVisible(false))
      } else if (!isInfoBubbleVisible && e.target.scrollTop <= 10) {
        if (document.getElementById('dongho-info-bubble')) return
        dispatch(setIsInfoBubbleVisible(true))
      }
    },
    [isInfoBubbleVisible],
  )

  /**
   * 버블 닫기
   */
  const onCloseBubble = useCallback(() => {
    dispatch(setIsInfoBubbleVisible(false))
    setVisibleBubble(false)

    localStorage.setItem('noteDonghoBubbleVisible', 'N')
  }, [])

  return children({
    visibleBubble,
    isInfoBubbleVisible,
    onCloseBubble,
    onScroll,
  })
}

export default NoteDonghoBubbleContainer
