import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import MapLayerListItem from './MapLayerListItem'

const MapLayerIcon = ({ type, title, onChange }) => {
  return (
    <MapLayerListItem type={type} onChange={onChange} renderless>
      {({ checked, handleChange }) => (
        <Item active={checked} onClick={handleChange}>
          {title}
        </Item>
      )}
    </MapLayerListItem>
  )
}

const Item = styled.div`
  width: max-content;
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.gray[800]};
  background-color: ${theme.colors.base.white};
  border-radius: 6px;
  box-shadow: ${theme.shadow['float']};

  ${({ active }) =>
    active &&
    `
      background-color: ${theme.colors.gray[800]};
      color: ${theme.colors.base.white};
  `}

  @media(hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${theme.colors.gray[800]};
      color: ${theme.colors.base.white};
    }
  }
`

export default MapLayerIcon
