import theme from 'lib/styles/theme'
import { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const ColorSelectBox = ({
  options,
  isSearchable = false,
  isLoading = false,
  placeholder = '선택',
  onChange,
  minWidth,
  noOptionsMessage = '',
  value,
  mr,
  width,
  fullheight,
  fullWidth,
  sidemargin,
}) => {
  return (
    <StyledSelect
      className="basic-single"
      classNamePrefix="select"
      options={options}
      isSearchable={isSearchable}
      isLoading={isLoading}
      onChange={onChange}
      placeholder={placeholder}
      minWidth={minWidth}
      noOptionsMessage={() => noOptionsMessage}
      //menuIsOpen={true}
      //  defaultValue={defaultValue}
      value={value}
      mr={mr}
      width={width}
      fullheight={fullheight}
      fullWidth={fullWidth}
      sidemargin={sidemargin}
    />
  )
}

const StyledSelect = styled(Select)`
  font-weight: 500;
  ${({ sidemargin }) => sidemargin && `margin: 0 8px;`}

  .select__control {
    ${({ width }) => width && `min-width: ${width}px;`}
    height: 24px;
    min-height: 24px;
    border: none;
    background-color: ${theme.colors.blue[50]};
    border-radius: 0;
    font-size: 15px !important;
  }
  .select__value-container {
    height: 22px;
  }

  .select__indicators {
    height: 100%;
  }

  .select__input-container {
    height: 100%;
    padding: 0;
  }

  .select__menu-list {
    /* Scroll Bar */
    &::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    max-height: 200px;
  }

  .select__value-container {
    padding: 0 0 0 8px;
  }

  .select__placeholder,
  .select__value-container {
    color: ${theme.colors.gray[900]};
    font-weight: 400;
    font-size: 15px;
    // border-color: ${theme.colors.gray[200]};
  }

  .select__indicator-separator {
    display: none;
  }

  .select__dropdown-indicator {
    padding: 0 6px;
  }

  .select__option {
    display: flex;
    align-items: center;
    min-height: 28px;
    padding: 0 6px;
    color: ${theme.colors.gray[900]};
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }

  .select__option--is-focused {
    background-color: ${theme.colors.blue[100]};
  }
  .select__option--is-selected {
    background-color: ${theme.colors.blue[400]};
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
      width: 100%;
    `}

  ${({ fullheight }) =>
    fullheight &&
    `
      height: 100%;

      .select__control {
        height: 100%;
      }
      
      .select__value-container {
        height: 100%;
      }

      .select__placeholder, .select__value-container {
        height: auto;
      }
    `}
`

export default ColorSelectBox
