import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Empty,
  List,
  ListItem,
  ListItemDesc,
  ListItemDivider,
  ListItemLabel,
  Tab,
  TabItem,
  Tag,
} from 'components/design'
import theme from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { groupBy } from 'lodash'

const SummaryHcMasters = ({ data }) => {
  const [activeTab, setActiveTab] = useState(1)
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    if (!data) return

    setFilterData(
      activeTab === 1
        ? groupBy([...data], 'type')['설계']
        : groupBy([...data], 'type')['시공'],
    )
  }, [data, activeTab])

  const onClickListItem = (id) => {
    // history.push({
    //   pathname: `/smartfolio/${id}`,
    //   state: { from: 'hc' },
    // })
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>단지 내 건축 전문가</CardTitle>
      </CardHeader>
      <CardBody>
        <Tab noContainer expand>
          <TabItem
            expand
            color="primary"
            active={activeTab === 1}
            onClick={() => setActiveTab(1)}
          >
            설계사
          </TabItem>
          <TabItem
            expand
            color="primary"
            active={activeTab === 2}
            onClick={() => setActiveTab(2)}
          >
            시공사
          </TabItem>
        </Tab>
        <List as="section" noMargin>
          {filterData?.length > 0 ? (
            filterData?.map((item, i) => (
              <ListItem
                key={i}
                onClick={() => onClickListItem(item.id)}
                hover
                noContainer
                chevron
              >
                <ListItemLabel as="hgroup">
                  <Type>{item.type}</Type>
                  <StyledTitle>{item.co_name}</StyledTitle>
                </ListItemLabel>
                <ListItemDesc as="p" small>
                  업력{' '}
                  {item.career_period &&
                    item.career_period.split(',')[1] -
                      item.career_period.split(',')[0]}
                  년차
                  {item.project_cnt
                    ? ` · ${item.project_cnt}개의 프로젝트`
                    : ''}
                </ListItemDesc>
                <ListItemDivider xs />
                <TagList>
                  <Tag marginRight color="gray">
                    {item.purpose}
                  </Tag>
                  {item.structure && (
                    <Tag marginRight color="gray">
                      {item.structure.split(',')[0]}
                    </Tag>
                  )}
                </TagList>
              </ListItem>
            ))
          ) : (
            <StyledEmpty>데이터가 없습니다.</StyledEmpty>
          )}
        </List>
      </CardBody>
    </Card>
  )
}

const StyledTitle = styled.h2`
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
`

const Type = styled.p`
  display: inline-block;
  color: ${theme.colors.primary[400]};
  font-size: 1rem;
  font-weight: 600;
  margin-right: 8px;
`

const TagList = styled.footer`
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;
`

const StyledEmpty = styled(Empty)`
  margin-top: 1.25rem;
`
export default SummaryHcMasters
