import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { message, Tooltip, Alert } from 'antd'
import { Modal, Select } from 'components/design'
import { copyNote } from 'lib/api/note/noteApi'
import useAxios from 'lib/hooks/useAxios'
import useChannels from 'containers/note/channel/hooks/useChannels'
import { getFolders } from 'lib/api/note/folderApi'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import FolderItem from 'components/jmapnote/addressList/FolderItem'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import theme from 'lib/styles/theme'

/**
 * 매물 복사
 * @param {*} param0
 * @returns
 */
const NoteDetailCopy = ({
  visible,
  setVisible,
  channelSrl,
  folderSrl,
  addressSrl,
  noteSrl,
  searchFn,
}) => {
  const mode = localStorage.getItem('notemode')
  const history = useHistory()
  const { channels } = useChannels()
  const [selectedChannelSrl, setselectedChannelSrl] =
    useState(channelSrl)
  const [selectedFolder, setSelectedFolder] = useState('')

  const [onRequest, loading, data, error, reset] = useAxios(copyNote)

  const [folders, setFolders] = useState([])
  const [onRequestGetFolder, , dataGetFolder, , ,] =
    useAxios(getFolders)

  useEffect(() => {
    if (!channelSrl) return
    setselectedChannelSrl(channelSrl)
  }, [channelSrl])

  /**
   * 채널 선택목록의 맨 첫번째 채널 기본값으로 세팅
   */
  useEffect(() => {
    if (!channels || !channels[0]) {
      return
    }
    setselectedChannelSrl(channels?.[0]?.channel_srl)
  }, [channels])

  useUpdateEffect(() => {
    if (!visible) {
      setselectedChannelSrl(channelSrl)
      setSelectedFolder('')
    } else {
      onRequestGetFolder({ channelSrl })
    }
  }, [visible])

  useEffect(() => {
    if (data?.error === 0) {
      setVisible(false) // 모달창 닫음
      setselectedChannelSrl(channelSrl)
      setSelectedFolder('')
      message.info('매물이 복사됐어요')

      //현재 채널에 복사한 경우는 시트 refresh
      if (selectedChannelSrl == channelSrl) {
        searchFn && searchFn()
      }
    }
  }, [data, error, channelSrl, folderSrl])

  const onCancel = useCallback(() => {
    setVisible(false)
    setselectedChannelSrl(channelSrl)
    setSelectedFolder('')
    onRequestGetFolder({ channelSrl })
  })

  const onOk = useCallback(async () => {
    if (
      mode == 'card' &&
      (!selectedFolder || !selectedFolder.folder_srl)
    ) {
      message.info('폴더를 선택해 주세요.')
      return
    }
    // 주소 폴더 복사 api호출
    await onRequest({
      toChannelSrl: selectedChannelSrl,
      fromChannelSrl: channelSrl,
      toFolderSrl: selectedFolder?.folder_srl,
      fromFolderSrl: folderSrl,
      addressSrl,
      noteSrl,
    })
  }, [
    onRequest,
    channelSrl,
    addressSrl,
    selectedChannelSrl,
    selectedFolder,
  ])

  const handleChange = (e) => {
    if (!e.target.value) {
      return
    }
    setselectedChannelSrl(e.target.value)
    onRequestGetFolder({ channelSrl: e.target.value })
  }

  useEffect(() => {
    if (!dataGetFolder || !dataGetFolder.result) return
    setFolders(dataGetFolder.result)
  }, [dataGetFolder])

  return (
    <Modal
      visible={visible}
      title="어디로 복사할까요?"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Alert
        message="다른 채널로 복사할 경우 계약서와 일정은 함께 복사되지 않아요"
        type="warning"
        showIcon
        style={{
          fontFamily: theme.font.family,
          wordBreak: 'keep-all',
        }}
      />
      <StyledChannels>
        <Select
          value={selectedChannelSrl}
          onChange={handleChange}
          expand
        >
          {channels?.map((item, i) => {
            return (
              <option key={i} value={item.channel_srl}>
                {item.name}
              </option>
            )
          })}
        </Select>
      </StyledChannels>
      {mode == 'card' && (
        <StyledFolders>
          {folders?.map((item, i) => {
            return (
              <Li key={i}>
                <Tooltip title={item.name}>
                  <FolderItem
                    item={item}
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                  />
                </Tooltip>
              </Li>
            )
          })}
        </StyledFolders>
      )}
    </Modal>
  )
}

const StyledChannels = styled.div`
  margin: 1rem 0;
`

const StyledFolders = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0.25rem;
  margin: -0.375rem;
`

const Li = styled.li`
  flex-basis: 20%;
  max-width: 20%;
  padding: 0.375rem;
`

export default NoteDetailCopy
