import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 지하 총 층수
 */
const NoteAdColumnUndergroundTotalFloorCount = ({
  required = true,
}) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>지하 총 층수</ColumnTitle>
      <ColumnVal direction="column">
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="text"
            {...register('article.floor2')}
            onBlur={(e) => {
              onUpdate('article.floor2', e.target.value)
            }}
            append={'층'}
          />
        </div>
        <ValidateWarn>{errors?.article?.floor2?.message}</ValidateWarn>
        <FormDesc>
          <li>지하인 경우 양수만 입력해주세요.</li>
        </FormDesc>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnUndergroundTotalFloorCount
