import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { setRoadView } from 'modules/summary'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const MapRoadView = ({ children, isVisible, onClick }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isVisible) return
    const preventGoBack = () => {
      onClick()
    }

    history.pushState(null, '', location.href)
    window.addEventListener('popstate', preventGoBack)

    return () => window.removeEventListener('popstate', preventGoBack)
  }, [isVisible])

  return (
    <StyledRoadView visible={isVisible}>
      <Header>
        <CloseButton onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </CloseButton>
        로드뷰
      </Header>
      {children}
    </StyledRoadView>
  )
}

const headerHeight = 60

const StyledRoadView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  #roadview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ${media.mediumM`
      height: 70%;
    `}
  }
`

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  width: 100%;
  height: ${headerHeight}px;
  padding-right: 1.25rem;

  color: ${theme.colors.base.white};
  font-size: 1rem;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.5);
`
const CloseButton = styled.button`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  width: ${headerHeight}px;
  height: ${headerHeight}px;
  padding: 0;

  background-color: transparent;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export default MapRoadView
