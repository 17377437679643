import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  adOptions: null,
  adFormControl: {
    adSrl: null,
    disabledColumns: [],
    isDisabledAll: false,
  },
  authModalVisible: false, //본인인증 공통 모달 visible
  notPermitRegiModalVisible: false, //동일매물, 신고매물 있을 시 광고 등록 불가 안내 모달 visible
  status: {
    datas: null, // 필터 데이터
    selectedStatus: null, // 선택한 필터
    updateStatusFromList: false, // 목록에서 네이버 광고 컬럼 버튼에 의해 업데이트가 일어났을 때 필터 count를 업데이트 하기 위한 flag
  },
  showIntroduceService: {
    status: false,
    regi: false,
  }, //환영 wrappr - 둘러보기 - 서비스 말풍선으로 소개
  listSortParams: {
    index: '',
    type: '',
  },
}

export const noteAdSlice = createSlice({
  name: 'noteAd',
  initialState,
  reducers: {
    setAdOptions(state, actions) {
      state.adOptions = actions.payload
    },
    setAdFormControl(state, actions) {
      state.adFormControl = {
        ...state.adFormControl,
        ...actions.payload,
      }
    },
    setAuthModalVisible(state, actions) {
      state.authModalVisible = actions.payload
    },
    setNotPermitRegiModalVisible(state, actions) {
      state.notPermitRegiModalVisible = actions.payload
    },
    setStatusDatas(state, actions) {
      state.status.datas = actions.payload
    },
    setSelectedStatus(state, actions) {
      state.status.selectedStatus = actions.payload
    },
    setUpdateStatusFromList(state, actions) {
      state.status.updateStatusFromList = actions.payload
    },
    setShowIntroduceService(state, actions) {
      state.showIntroduceService = {
        ...state.showIntroduceService,
        ...actions.payload,
      }
    },
    setListSortParams(state, actions) {
      state.listSortParams = {
        ...state.listSortParams,
        ...actions.payload,
      }
    },
  },
})

export const {
  setAdOptions,
  setAdFormControl,
  setAuthModalVisible,
  setNotPermitRegiModalVisible,
  setStatusDatas,
  setSelectedStatus,
  setUpdateStatusFromList,
  setShowIntroduceService,
  setListSortParams,
} = noteAdSlice.actions

export default noteAdSlice.reducer
