import { useDispatch } from 'react-redux'
import BaseNavItem from './BaseNavItem'
import { useSelector } from 'react-redux'
import { setMapVisible } from 'modules/map'
import { useHistory } from 'react-router-dom'
import useIsMobile from 'lib/hooks/useIsMobile'
import { NavLink } from 'react-router-dom'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { useEffect } from 'react'

const BaseNavSearch = () => {
  const mapVisible = useSelector((state) => state.map.mapVisible)
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = useIsMobile()
  const match = useRouteMatch('/:id') //summary url
  const location = useLocation()

  const onClick = () => {
    history.push('/home')
    dispatch(setMapVisible(true))
  }

  const isActive =
    (match?.isExact || location?.pathname == '/home') &&
    match?.url != '/notifications' &&
    match?.url != '/favorite'

  return isMobile ? (
    <BaseNavItem
      as="div"
      to="/home"
      icon={<i className="fal fa-map defaultIcon" />}
      activeIcon={<i className="fad fa-map activeIcon" />}
      text="지도"
      active={isActive && mapVisible}
      onClick={onClick}
    />
  ) : (
    <BaseNavItem
      as={NavLink}
      to="/search"
      icon={<i className="fal fa-search" />}
      text="검색"
    />
  )
}

export default BaseNavSearch
