import {
  MainWrapper,
  MaxWidthWrapper,
} from 'components/jmapnotev2/style/NoteStyle'
import styled from 'styled-components'
import { media } from 'lib/styles/theme'
import Scrollbars from 'react-custom-scrollbars-2'
import NoteDashboardContents from 'components/jmapnotev2/NoteDashboard/NoteDashboardContents'
import NoteDashboardWelcomeTitle from 'components/jmapnotev2/NoteDashboard/NoteDashboardWelcomeTitle'
import useIsMobile from 'lib/hooks/useIsMobile'
import MobileChannelHeader from 'components/jmapnotev2/channel/MobileChannelHeader'
import useIsTablet from 'lib/hooks/useIsTablet'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import NotFound from 'pages/NotFound'

/**
 * 대시보드
 */
const NoteDashboard = () => {
  const { hasPlan, isUseAllInOne } = useNoteHasPlan()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  //요금제가 있거나 올인원 플랜의 직원만 사용 가능
  if (!hasPlan && !isUseAllInOne) return <NotFound />
  return (
    <StyledMainWrapper full noside blue="Y">
      {(isMobile || isTablet) && (
        <MobileChannelHeader channel={false} />
      )}
      <Scrollbars
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            style={{ display: 'none' }}
            className="track-horizontal"
          />
        )}
      >
        <ScrollbarWrapper>
          <MaxWidthWrapper wide>
            <NoteDashboardWelcomeTitle />
            <NoteDashboardContents />
          </MaxWidthWrapper>
        </ScrollbarWrapper>
      </Scrollbars>
    </StyledMainWrapper>
  )
}

const ScrollbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${media.smallPc`
    padding: 0 40px;
  `}

  ${media.mediumS`
    padding: 0 20px;
  `}
`

const StyledMainWrapper = styled(MainWrapper)`
  ${media.mediumM`
    left: 0;
    padding-top: 26px;
  `}
`

export default NoteDashboard
