import React, { useCallback } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import theme from 'lib/styles/theme'

const SideNavItem = ({
  to,
  icon,
  activeIcon,
  label,
  onClick,
  as,
  active,
}) => {
  return (
    <StyledNavItem
      onClick={onClick}
      to={to}
      icon={icon}
      active={active}
      label={label}
      as={onClick ? 'div' : as}
    >
      {activeIcon}
      {icon}
      <Label>{label}</Label>
    </StyledNavItem>
  )
}

const StyledNavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  ${({ ishide }) => ishide == 'Y' && `display: none;`}

  .activeIcon {
    display: none;
  }
  .normalIcon {
    display: block;
  }

  cursor: pointer;

  ${({ border }) =>
    border == 'Y' &&
    `
      &::after {
        content: "";
        width: 60%;
        border-bottom: 1px solid ${theme.colors.gray[100]};
        padding-bottom: 20px;
      }
    `}

  svg {
    margin-bottom: 10px;
  }

  &.active {
    div {
      ${({ icon }) =>
        !icon &&
        `
            color: ${theme.colors.blue[500]};
            `}
      font-weight: 700;
    }
    .activeIcon {
      display: block;
    }
    .normalIcon {
      display: none;
    }
  }

  ${({ active }) =>
    active &&
    `
    div {
        ${({ icon }) =>
          !icon &&
          `
        color: ${theme.colors.blue[500]};
        `}
        font-weight: 700;
        }
        .activeIcon {
        display: block;
        }
        .normalIcon {
        display: none;
        }
  `}
`

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[900]};
`

export default SideNavItem
