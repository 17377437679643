import FavoriteLinkContainer from 'containers/favorite/FavoriteLinkContainer'
import HomeQuickMenuItem from './HomeQuickMenuItem'
import theme from 'lib/styles/theme'

const HomeFavoriteLink = ({ newBadge }) => {
  return (
    <FavoriteLinkContainer>
      {({ onClick }) => (
        <HomeQuickMenuItem
          color="#f7c307"
          icon={<i className="fad fa-star"></i>}
          onClick={onClick}
          newBadge={newBadge}
        >
          즐겨찾기
        </HomeQuickMenuItem>
      )}
    </FavoriteLinkContainer>
  )
}

export default HomeFavoriteLink
