import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import Checkbox from '../../elements/Checkbox'
import { useFormContext } from 'react-hook-form'
import moment from 'moment'
import InputDate from '../../elements/InputDate'
import useIsMobile from 'lib/hooks/useIsMobile'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { message } from 'antd'
import { Checkbox as CommonCheckbox } from 'components/design'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 상세정보 입주가능일
 */
const NoteAdColumnMoveDate = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const moveinNegotiable = watch('etcInfo.moveinNegotiable')
  const moveinYmd = watch('etcInfo.moveinYmd')
  const moveinTypeCode = watch('etcInfo.moveinTypeCode')

  const isMobile = useIsMobile()

  /**
   * 즉시 입주 클릭
   * @param {boolean} checked
   */
  const onClickNow = (checked) => {
    if (checked) {
      onUpdate('etcInfo.moveinYmd', 'NOW')
    } else {
      onUpdate('etcInfo.moveinYmd', '')
    }
  }

  /**
   * 초순,중순,하순으로 표기
   */
  const onChangeMoveinTypeCode = (checked, value, e) => {
    if (!moveinYmd || moveinYmd == 'NOW') {
      message.info('입주가능일을 먼저 선택해 주세요.')
      return
    }
    if (checked) {
      const date = moment(moveinYmd, 'YYYYMMDD').date()

      onUpdate('etcInfo.moveinTypeCode', getMoveinTypeCode(date))
    } else {
      onUpdate('etcInfo.moveinTypeCode', '')
    }
  }

  /**
   * 초순, 중순, 하순 얻기
   */
  const getMoveinTypeCode = (value) => {
    if (1 <= value && value <= 10) {
      return 'EARLY' //초순
    } else if (10 < value && value <= 20) {
      return 'MID' //중순
    } else if (value <= 31) {
      return 'LATE' //하순
    } else {
      return ''
    }
  }

  return (
    <ColumnSet>
      <ColumnTitle>입주가능일</ColumnTitle>
      <ColumnVal gap={12}>
        <DateWrapper>
          <InputDate
            type="date"
            {...register('etcInfo.moveinYmd')}
            onChange={(e) => {
              onUpdate(
                'etcInfo.moveinYmd',
                e ? moment(e).format('YYYYMMDD') : '',
              )
            }}
            placeholder="연도.월.일"
            expireDate
            fixAppendWidth
            value={
              moveinYmd && moveinYmd != 'NOW'
                ? moment(moveinYmd).format('YYYY.MM.DD')
                : ''
            }
          />
          <span>이후</span>
        </DateWrapper>
        <Checkbox
          id="moveinYmd"
          color="blue"
          checked={moveinYmd == 'NOW'}
          onChange={onClickNow}
        >
          즉시 입주
        </Checkbox>
        <Checkbox
          id="moveinNegotiable"
          color="blue"
          value="Y"
          checked={moveinNegotiable == 'Y'}
          {...register('etcInfo.moveinNegotiable')}
          onChange={(e) => {
            onUpdate('etcInfo.moveinNegotiable', e ? 'Y' : 'N')
          }}
        >
          협의 가능
        </Checkbox>
        <CommonCheckbox
          id="moveinTypeCode"
          color="blue"
          checked={moveinTypeCode}
          {...register('etcInfo.moveinTypeCode')}
          onChange={onChangeMoveinTypeCode}
        >
          초순, 중순, 하순으로 표기
        </CommonCheckbox>
        <ValidateWarn>
          <div>{errors?.etcInfo?.moveinYmd?.message}</div>
          <div>{errors?.etcInfo?.moveinNegotiable?.message}</div>
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  span {
    margin-left: 10px;
    margin-right: 2px;
    word-break: keep-all;
  }
`

export default NoteAdColumnMoveDate
