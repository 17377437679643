import { useCallback, useContext, useEffect } from 'react'
import { checkInputAndMoveFocus } from 'lib/utils/notev2/gridFocus'
import { showAllTypesPrice } from 'lib/utils/notev2/gridUtil'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import { getMaxRowHeight } from 'lib/utils/notev2/gridData'
import MapContext from 'contexts/map'
import { message } from 'antd'
import useIsMobile from './useIsMobile'
import { useSelector } from 'react-redux'
import theme from 'lib/styles/theme'
import { useDispatch } from 'react-redux'
import {
  resetClickedMarker,
  resetOpenedMarker,
  resetSpecificOpenedMarker,
  setSpecificOpenedMarker,
} from 'modules/note'

function useGridEvent({ resizeLayerRef }) {
  const [onRequestModifyNote] = useModifyNote()
  const isMobile = useIsMobile()

  const isShowMap = useSelector(
    (state) => state.notev2.noteListIsShowMap,
  )
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map, viewState } = state

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetSpecificOpenedMarker())
      dispatch(resetOpenedMarker())
      dispatch(resetClickedMarker())
    }
  }, [])

  /**
   * 그리드 셀 포커스 이벤트
   */
  const onCellFocused = (
    e,
    setIsFocusPriceItem,
    isSearching,
    setIsSearching,
    prevNoteSrl,
    isAd = false,
  ) => {
    const gridApi = e.api
    const currentRowNode = gridApi.getDisplayedRowAtIndex(e.rowIndex)
    const field = e.column.colId
    const cellClass = e.column?.colDef?.cellClass

    //주소 컬럼 아닌 경우
    if (field != 'addr') {
      if (isSearching) {
        setIsSearching(false)
      }
    }

    //주소 컬럼
    if (field == 'display_address') {
      const target = document.activeElement

      if (
        Array.from(target.classList).includes('ag-cell') ||
        target.getAttribute('tabindex') > -1
      ) {
        checkInputAndMoveFocus(target)
      }
    }

    currentRowNode.setSelected(true)

    //신규 행은 focus 이벤트 없음
    if (
      currentRowNode?.data?.note_srl == '신규' ||
      currentRowNode?.data?.ad_srl == '신규'
    )
      return

    if (field == 'contract_type') {
      if (isAd) {
        const target = document.activeElement
        checkInputAndMoveFocus(target)
      } else {
        //모바일은 touch 이벤트랑 구분이 안가서 다른곳에서 처리
        if (window.innerWidth > theme.bp.mediumS) {
          //가격 셀이 확장되지 않았을 때만 포커스 이동
          if (!currentRowNode?.data?.state?.isContractTypeShow) {
            const target = document.activeElement //지금 focus 되어 있는 셀
            const getSiblings = (t) =>
              [...t.parentElement.children].filter((e) => e != t) //jquery .siblings()
            const parentSilblings = getSiblings(target) //바로 옆 가격 셀을 찾기 위해 지금 focus 되어 있는 셀의 형제 node를 찾는다
            const currRowPriceCell = parentSilblings.find((elem) =>
              elem.classList.contains('custom-class-price-cell'),
            ) //class로 가격 셀 찾음

            checkInputAndMoveFocus(currRowPriceCell) //가격 셀의 첫번째 요소에 focus
            showAllTypesPrice(gridApi, currentRowNode) //가격 셀 펼침
          }
        }
      }
    }

    const isPriceCell =
      cellClass &&
      typeof cellClass === 'string' &&
      cellClass.includes('custom-class-price-cell')

    //가격 컬럼 - focus시 전체 항목 다 보여주고, 첫번쨰 custom single column에 focus
    if (isPriceCell) {
      if (isAd) {
        const target = document.activeElement
        checkInputAndMoveFocus(target)
      } else {
        //모바일은 touch 이벤트랑 구분이 안가서 다른곳에서 처리
        if (window.innerWidth > theme.bp.mediumS) {
          const target = document.activeElement
          checkInputAndMoveFocus(target)
          showAllTypesPrice(gridApi, currentRowNode)
        }
      }
    }

    //면적 컬럼 - focus시 첫번째 custom single column에 focus
    if (
      cellClass &&
      typeof cellClass === 'string' &&
      cellClass.includes('custom-class-area-cell')
    ) {
      const target = document.activeElement
      checkInputAndMoveFocus(target)
    }

    //방향/지목 컬럼
    if (field == 'direction') {
      const target = document.activeElement
      checkInputAndMoveFocus(target)
    }

    //주소 컬럼 - focus시 첫번째 custom single column에 focus
    if (field == 'addr') {
      const target = document.activeElement
      checkInputAndMoveFocus(target)
    }

    //종류 컬럼 - focus시 첫번째 custom single column에 focus
    if (field == 'property_type') {
      const target = document.activeElement
      checkInputAndMoveFocus(target)
    }

    //연락처 컬럼 - focus시 첫번째 custom single column에 focus
    if (field == 'contacts') {
      const target = document.activeElement
      checkInputAndMoveFocus(target)
    }

    //메모 컬럼 - focus시 첫번째 custom single column에 focus
    if (field == 'memo') {
      const target = document.activeElement
      checkInputAndMoveFocus(target)
    }

    //가격 컬럼이 아닌 경우, 가격 컬럼 입력한 크기만큼 rowheight 조정
    //구분, 가격 컬럼이라면 현재 셀은 닫기 처리 안함
    if (!isPriceCell || prevNoteSrl != currentRowNode.data.note_srl) {
      if (
        field == 'contract_type' &&
        currentRowNode?.data?.state?.isContractTypeShow
      )
        //구분 셀은 가격셀이 확장된 상태라면 닫지 않음(해당 컴포넌트에 구분 셀 항목 클릭 시 click 동작이 부여되어있기 때문)
        return

      //가격 컬럼을 같은 row상에서 여러번 클릭할 때 return(= 가격 컬럼이면서 이전 select 했던 note_srl과 같으면 return)
      if (
        isPriceCell &&
        (!prevNoteSrl || prevNoteSrl == currentRowNode.data.note_srl)
      )
        return

      //그리드 구분, 가격 컬럼 모두 보여주기 모드 해제 로직
      gridApi.forEachNode(function (rowNode) {
        const data = rowNode.data

        //가격셀에선 동작 X
        if (
          isPriceCell &&
          currentRowNode.data.note_srl == data.note_srl
        )
          return

        if (data?.state?.isContractTypeShow) {
          //row height 변경된 구분 개수만큼 설정
          rowNode.setData({
            ...data,
            rowHeight: rowHeight,
            state: {
              ...data.state,
              isContractTypeShow: false,
            },
          })

          const rowHeight = getMaxRowHeight({
            contract_type: data.contract_type,
            contacts: data.contacts,
          })
          rowNode.setRowHeight(rowHeight)
        }
      })

      gridApi.onRowHeightChanged()
      setIsFocusPriceItem && setIsFocusPriceItem(false)
    }
  }

  /**
   * 탭으로 다음 셀 가기 Override
   */
  const onTabToNextCell = useCallback((params, isSearching) => {
    //수정중이면
    if (params.editing) return
    //주소 검색 선택 전 이면
    if (isSearching) return

    if (params.nextCellPosition) {
      const result = {
        rowIndex: params.nextCellPosition.rowIndex,
        column: params.nextCellPosition.column,
        floating: params.nextCellPosition.floating,
      }
      return result
    }
  }, [])

  /**
   * 방향키로 다음 셀 카기 이벤트
   * @param {*} params
   * @returns
   */
  const onNavigateToNextCell = (params) => {
    if (params.editing) return

    if (params.nextCellPosition) {
      const result = {
        rowIndex: params.nextCellPosition.rowIndex,
        column: params.nextCellPosition.column,
        floating: params.nextCellPosition.floating,
      }
      return result
    }
  }

  const onCellValueChanged = (e) => {
    const field = e.colDef.field
    if (field == 'memo') {
      onRequestModifyNote({
        data: e.data,
        updateData: { [field]: e.newValue },
        currentRowNode: e.node,
      })
    }

    if (field == 'direction') {
      //토지나 임의의 주소인 경우 - 지목 저장
      if (e.data.property_type == '토지' || e.data.invalid_addr_name) {
        onRequestModifyNote({
          data: e.data,
          updateData: { land_type: e.data.direction, direction: '' },
          currentRowNode: e.node,
        })
      } else {
        onRequestModifyNote({
          data: e.data,
          updateData: { direction: e.newValue },
          currentRowNode: e.node,
        })
      }
    }
  }

  /**
   * 지도 중점 이동
   * @param {array} centerpoint
   */
  const moveCenter = (centerpoint) => {
    map.panTo(new kakao.maps.LatLng(...centerpoint))
  }

  /**
   * 지도 보기 상태일 때 center point 이동
   */
  const onRowClickEvent = useCallback(
    (e) => {
      if (!resizeLayerRef?.current) return
      if (!validateCondition(e)) return
      resetNoteMarkers({
        note_srl: e.data.note_srl,
        address_srl: e.data.address_srl,
        address_id: e.data.address_id,
        channel_srl: e.data.channel_srl,
      })
      findAddrAndMoveMap(e.data.latitude, e.data.longitude)
    },
    [map, resizeLayerRef?.current, isShowMap, isMobile],
  )

  /**
   * 열린 마커는 접고, 특수 상황 flag는 true로 만듬
   */
  const resetNoteMarkers = (data) => {
    dispatch(setSpecificOpenedMarker(data))
  }

  /**
   * 매물 클릭시 중심점 이동 조건 validate
   */
  const validateCondition = (e) => {
    if (isMobile) return false
    if (Object.keys(map).length == 0) return false
    if (!isShowMap) return false //지도 보기 상태일 때만 실행
    if (!e.data || e.data.note_srl == '신규') return false //신규는 그냥 return
    if (e?.event?.defaultPrevented) return false
    if (e?.event?.detail == 2) return false //더블 클릭 무시
    if (localStorage.getItem('currClickNoteRow') == e.data.note_srl)
      return false // 한 번 클릭했던 row는 무시
    if (!e?.data?.latitude || !e?.data?.longitude) {
      localStorage.setItem('currClickNoteRow', e.data.note_srl)
      message.info('지도에서 위치를 찾을 수 없는 매물입니다.')
      return false
    } else {
      localStorage.setItem('currClickNoteRow', '')
    }

    return true
  }
  /**
   * 마커 중심점 찾은 뒤 이동
   * @param {*} latitude
   * @param {*} longitude
   */
  const findAddrAndMoveMap = (latitude, longitude) => {
    const centerpoint = [latitude, longitude] // e.data.centerpoint

    //레이어만큼 밀어야함(지도를 가린 부분)
    const mapProjection = map.getProjection()
    const latlng = new kakao.maps.LatLng(centerpoint[0], centerpoint[1])
    const currOffset = mapProjection.pointFromCoords(latlng) // 지도 좌표에 해당하는 위치 좌표
    const resizableLayerWidth = window
      .getComputedStyle(resizeLayerRef.current.resizable)
      .getPropertyValue('width')
      .replace('px', '') //레이어 width
    const addPixelX = Number(resizableLayerWidth)

    // 카카오 pixel 포인트 생성
    const point = new kakao.maps.Point(
      currOffset.x - addPixelX / 2,
      currOffset.y,
    )

    //pixel을 좌표로 변환
    const realPoint = mapProjection.coordsFromPoint(point)

    moveCenter([realPoint.getLat(), realPoint.getLng()])
  }

  return {
    onCellFocused,
    onNavigateToNextCell,
    onTabToNextCell,
    onCellValueChanged,
    onRowClickEvent,
  }
}

export default useGridEvent
