import theme from 'lib/styles/theme'
import styled from 'styled-components'
import 'moment/locale/ko'
import NoteCalendarWeek from './NoteCalendarWeek'
import { Card } from 'components/design'

const NoteCalendar = ({ currentDate, viewMode }) => {
  const headerWeeks = ['일', '월', '화', '수', '목', '금', '토']

  const weeks = () => {
    const firstWeek = currentDate.clone().startOf('month').get('week')
    const _weeks = []

    for (let i = 0; i < 6; i++) {
      const weekFormat = currentDate
        .clone()
        .week(firstWeek)
        .add(i, 'week')
      _weeks.push({
        weekFormat,
        week: weekFormat.get('week'),
      })
    }

    return _weeks
  }

  if (viewMode !== 'calendar') return null
  return (
    <StyledCard>
      <>
        <StyledWeeks>
          {headerWeeks.map((item, i) => (
            <WeekCol key={i} isSunday={i === 0} isSaturday={i === 6}>
              {item}
            </WeekCol>
          ))}
        </StyledWeeks>
      </>
      {weeks().map((item, i) => (
        <NoteCalendarWeek
          key={i}
          week={item.week}
          weekFormat={item.weekFormat}
          currentDate={currentDate}
        />
      ))}
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  border-bottom: 1px solid ${theme.colors.gray[200]};
`

const StyledWeeks = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  background-color: ${theme.colors.gray[100]};
`
const WeekCol = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.gray[500]};
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;

  ${({ isSunday }) =>
    isSunday &&
    `
    color: ${theme.colors.tint.danger};
  `}
  ${({ isSaturday }) =>
    isSaturday &&
    `
    color: ${theme.colors.tint.secondary};
  `}
`

export default NoteCalendar
