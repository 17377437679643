import styled from 'styled-components'
import { Empty, Name, Title } from './NoteDonghoPlusDanji'
import { PropertyListItem } from '../NoteFilter'
import { Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import { Star } from './NoteDonghoStyle'
import React from 'react'

const NoteDonghoFavoriteDanji = ({ data, onClickDanji, loading }) => {
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )

  return (
    <>
      <Title>
        <div>즐겨찾는 단지</div>
      </Title>
      {loading && <Skeleton active />}
      {data &&
        (data.length > 0 ? (
          data.map((item, i) => {
            return (
              <React.Fragment key={`property-${i}`}>
                <PropertyListItem
                  data-id={item.id}
                  className="dongho-danji-item"
                  onClick={() => onClickDanji(item)}
                  hover
                  small
                  smallpadding
                  active={selectedDanji?.id == item.id}
                >
                  <Star className="fas fa-star" />
                  <Name>{item.apt_name}</Name>
                </PropertyListItem>
              </React.Fragment>
            )
          })
        ) : (
          <Empty>등록된 즐겨찾기 단지가 없어요</Empty>
        ))}
    </>
  )
}

const ScrollWrapper = styled.div`
  height: 100%;
`

export default NoteDonghoFavoriteDanji
