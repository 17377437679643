import { useEffect, useState } from 'react'

import { Form } from 'components/design'
import { FormItem } from 'components/design'
import { Modal } from 'components/design'
import { Select } from 'components/design'
import moment from 'moment'
import { Input } from 'components/design'
import { Checkbox } from 'components/design'
import styled from 'styled-components'
import { Textarea } from 'components/design'

import NoteScheduleContactContainer from 'containers/note/noteForm/NoteScheduleContactContainer'
import { message } from 'antd'

const StyledCustomForm = styled.div`
  display: flex;
  align-items: center;
`
const StyledInputWrap = styled.div`
  flex: 1;
  margin-right: 1rem;
`

const NoteScheduleForm = ({
  title,
  visible,
  setVisible,
  myContacts,
  defaultValue,
  onSubmit,
  sheet,
}) => {
  const [type, setType] = useState('')
  const [content, setContent] = useState('')
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [contactSrls, setContactSrls] = useState([])

  const [isCustomType, setIsCustomType] = useState(false)
  const [customTypeChecked, setCustomTypeChecked] = useState(false)

  const types = [
    '상담',
    '계약일',
    '입주가능',
    '입주예정',
    '중도금일',
    '잔금일',
    '계약만기',
    '매물방문',
    '기타',
    '직접입력',
  ]

  useEffect(() => {
    if (!defaultValue) {
      setType('')
      setContent('')
      setDate(moment().format('YYYY-MM-DD'))
      setContactSrls([])

      setIsCustomType(false)
      setCustomTypeChecked(false)
    }

    setType(defaultValue?.type || '')
    setContent(defaultValue?.content || '')
    setDate(defaultValue?.date || '')
    setContactSrls(defaultValue?.contacts || [])
  }, [visible])

  useEffect(() => {
    if (type === '직접입력') {
      setIsCustomType(true)
      setCustomTypeChecked(true)
      setType('')
      return
    }

    if (!type && !customTypeChecked) {
      setIsCustomType(false)
      return
    }

    // 정해진 타입과 일치하는지 확인
    const findTypes = types.find((e) => e === type)

    if (!findTypes) {
      setIsCustomType(true)
      return
    }

    setIsCustomType(false)
  }, [type])

  useEffect(() => {
    setCustomTypeChecked(isCustomType)
  }, [isCustomType])

  const onCancel = () => {
    setVisible(false)
  }

  const onOk = () => {
    if (!date) {
      message.warning('날짜를 입력해주세요.')
      return
    }
    onSubmit(
      {
        type,
        date: date,
        content,
        contact_srls: JSON.stringify(contactSrls),
      },
      onCancel,
    )
    setVisible(false)
  }

  const onChangeCustomTypeChecked = () => {
    setIsCustomType(false)
    setCustomTypeChecked(false)
    setType('')
  }

  const onChangeContacts = (values) => {
    setContactSrls(values)
  }

  return (
    <>
      <Modal
        visible={visible}
        title={title}
        onOk={onOk}
        onCancel={onCancel}
        okButtonProps={{
          color: sheet ? 'blue' : 'primary',
        }}
      >
        <Form>
          <FormItem label="날짜 선택" isRequired noContainer>
            <Input
              color={sheet ? 'blue' : 'primary'}
              type="date"
              value={
                moment(date, 'YYYY-MM-DD').isValid()
                  ? moment(date).format('YYYY-MM-DD')
                  : ''
              }
              onChange={(e) => setDate(e.target.value)}
              expand
            />
          </FormItem>

          <FormItem label="타입" isRequired noContainer>
            {isCustomType ? (
              <StyledCustomForm>
                <StyledInputWrap>
                  <Input
                    color={sheet ? 'blue' : 'primary'}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    placeholder="타입"
                    expand
                  />
                </StyledInputWrap>
                <Checkbox
                  checked={customTypeChecked}
                  onChange={onChangeCustomTypeChecked}
                >
                  직접입력
                </Checkbox>
              </StyledCustomForm>
            ) : (
              <Select
                border={sheet ? 'blue' : 'primary'}
                value={type}
                onChange={(e) => setType(e.target.value)}
                expand
              >
                <option value="">타입 선택</option>
                {types.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem label="메모" noContainer>
            <Textarea
              border={sheet ? 'blue' : 'primary'}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="일정에 대한 설명을 작성해보세요."
            />
          </FormItem>

          <NoteScheduleContactContainer
            defaultValue={{ contact_srls: contactSrls }}
            contact_srls={contactSrls}
            myContacts={myContacts}
            onChange={onChangeContacts}
          />
        </Form>
      </Modal>
    </>
  )
}

export default NoteScheduleForm
