import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 매물 소유자 구분 noRgbkVrfcReqYn
 */
const NoteAdColumnVerificationRegi = ({ isDisabled }) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <ColumnSet half>
      <ColumnTitle>미등기 검증 요청</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          size="small"
          fit
          label="검증요청"
          name="reg_verification"
          value="Y"
          {...register('verification.noRgbkVrfcReqYn')}
          onChange={(e) => {
            onUpdate('verification.noRgbkVrfcReqYn', e.target.value)
          }}
          disabled={isDisabled}
        />
        <RadioButton
          size="small"
          fit
          label="요청 안함"
          name="reg_verification"
          value="N"
          {...register('verification.noRgbkVrfcReqYn')}
          onChange={(e) => {
            onUpdate('verification.noRgbkVrfcReqYn', e.target.value)
          }}
          disabled={isDisabled}
        />

        <ValidateWarn>
          {errors?.verification?.noRgbkVrfcReqYn?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnVerificationRegi
