import { useCallback } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import { Input } from 'components/design'
import { rgba } from 'polished'

const Label = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 700;
`

const IconGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  /* padding-top: 1rem; */
  margin: -0.25rem;

  & > li {
    flex-basis: 20%;
    max-width: 20%;
    padding: 0.25rem;
  }
`

const IconGridItem = styled.a`
  position: relative;
  display: flex;
  padding-bottom: 100%;

  color: ${theme.colors.gray[600]};
  font-size: 1.25rem;

  border-radius: ${theme.borderRadius[1]};
  background-color: ${theme.colors.gray[50]};
  border: 1px solid ${theme.colors.gray[50]};

  &:hover {
    color: ${theme.colors.gray[700]};
    background-color: ${theme.colors.gray[100]};
    border-color: ${theme.colors.gray[100]};
  }

  & > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${({ active }) =>
    active &&
    `
    color: ${theme.colors.primary[400]};
    background-color: ${theme.colors.base.white};
    border-color: ${theme.colors.primary[400]};

    &:hover {
      color: ${theme.colors.primary[400]};
      background-color: ${rgba(theme.colors.primary[400], 0.1)};
      border-color: ${theme.colors.primary[400]};
    }
  `}
`

const FolderForm = ({ name, setName, icon, setIcon }) => {
  const icons = [
    'folder',
    'home',
    'building',
    'store',
    'store-alt',
    'city',
    'industry-alt',
    'garage-car',
    'car-building',
    'church',
    'coffee',
    'laptop-house',
    'archive',
    'bed',
    'bone',
    'box',
    'bus',
    'check',
  ]

  const onClickIconItem = useCallback((icon) => {
    setIcon('far fa-' + icon)
  })

  return (
    <>
      <Label>폴더 이름</Label>
      <Input
        expand
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Label>아이콘</Label>
      <IconGrid>
        {icons.map((item, i) => (
          <li key={i}>
            <IconGridItem
              active={icon === 'far fa-' + item}
              onClick={() => onClickIconItem(item)}
            >
              <i className={'far fa-' + item} />
            </IconGridItem>
          </li>
        ))}
      </IconGrid>
    </>
  )
}

export default FolderForm
