import { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import BaseContext from 'contexts/baseContext'
import { useEffect } from 'react'
import zIndex from 'lib/styles/zIndex'
import useIsTablet from 'lib/hooks/useIsTablet'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isLayerSmallThanWindow } from 'utils'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'

const HeaderContainer = ({
  children,
  fixed,
  absolute = false,
  card,
  border,
  borderHidden,
  prepend,
  append,
  expand,
  inverse = false,
  backgroundColor,
  color,
  topSpace = true,
  noTopHeader = false,
}) => {
  const {
    state: { isScrolled, headerInverse, isFold, isSwipe },
    actions: { setHeaderInverse, setHeaderAbsolute },
  } = useContext(BaseContext)

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)

  const [isOpenExternal, setIsOpenExternal] = useState(false)
  const isOpenNoteExternal = useSelector(
    (state) => state.summary.isOpenNoteExternal,
  )
  const { addressSrl } = useNoteMatch()

  const params = useParams()

  const isTablet = useIsTablet()

  useEffect(() => {
    if (fixed && isScrolled) {
      setHeaderInverse(false)
      return
    }

    setHeaderInverse(inverse)
    return () => {
      setHeaderInverse(false)
    }
  }, [absolute, isScrolled, inverse])

  useEffect(() => {
    setIsOpenExternal(
      params.external || addressSrl || params.addressSrl,
    )
  }, [params, addressSrl])

  const template = (
    <StyledHeader
      fixed={fixed}
      absolute={absolute}
      inverse={headerInverse}
      isScrolled={!borderHidden && fixed && isScrolled}
      card={card}
      border={!card && border}
      expand={expand}
      isTablet={isTablet}
      isFold={isFold}
      backgroundColor={backgroundColor}
      color={color}
      full={
        (isOpenExternal || isOpenNoteExternal) &&
        isLayerSmallThanWindow()
      }
      isNoticeBarVisible={isNoticeBarVisible}
      noticeBarHeight={height}
      topSpace={topSpace}
      isSwipe={isSwipe}
      noTopHeader={noTopHeader}
    >
      {prepend && <StyledPrependWrap>{prepend}</StyledPrependWrap>}

      {children && <StyledHeaderBody>{children}</StyledHeaderBody>}

      {append && <StyledAppendWrap>{append}</StyledAppendWrap>}
    </StyledHeader>
  )

  if (fixed) {
    const root = document.getElementById('root')
    return ReactDOM.createPortal(template, root)
  }

  return template
}

const StyledHeader = styled.header`
  user-select: none;
  display: flex;
  align-items: center;
  width: ${({ full }) => (full ? `50%` : `100%`)};
  max-width: ${theme.base.container}px;
  height: ${theme.base.headerHeight}px;
  left: ${theme.base.navWidth}px;
  z-index: ${zIndex.header};
  padding-left: 1.25rem;
  padding-right: 0.75rem;
  background-color: ${theme.colors.base.white};
  border-bottom: 1px solid rgba(0, 0, 0, 0);

  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;

  top: ${({ isNoticeBarVisible, topSpace, noticeBarHeight }) =>
    isNoticeBarVisible ? `${noticeBarHeight}px` : 0};

  ${media.mediumM`
    ${({ isNoticeBarVisible, topSpace, noticeBarHeight }) =>
      isNoticeBarVisible && topSpace && `top: ${noticeBarHeight}px;`}
       left: 0;
  `}

  ${media.mediumS`
    display: flex !important;
    left: 0 !important;
    max-width: 100%;
    width: 100%;
    transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    font-size: 1.125rem;
      
    top:  ${({
      isNoticeBarVisible,
      noticeBarHeight,
      noTopHeader,
      isSwipe,
    }) =>
      noTopHeader || isSwipe
        ? isNoticeBarVisible
          ? `${noticeBarHeight}px`
          : 0
        : isNoticeBarVisible
        ? `${noticeBarHeight}px`
        : `0`};


  `}



  ${({ expand }) =>
    expand &&
    `
    max-width: 100%;
  `}

  ${({ fixed }) =>
    fixed &&
    `

    position: fixed;
    left: ${theme.base.navWidth}px;
    z-index: ${zIndex.header};
  `}


  ${({ absolute }) =>
    absolute &&
    `
    position: absolute;
    top: 0;
    left: 0;
    z-index: 14;
  `}

  ${({ isFold }) =>
    isFold &&
    `
		display: none;
		`}


  ${({ inverse }) =>
    inverse &&
    `
    color: white;
    background-color: transparent;
    border-bottom-color: transparent;
  `}

  ${({ card }) =>
    card &&
    media.mediumS`
      top: ${({ isNoticeBarVisible, noticeBarHeight }) =>
        isNoticeBarVisible
          ? `calc(${noticeBarHeight}px + 1rem)`
          : `1rem`};
      left: 1rem !important;
      width: calc(100vw - (1rem * 2));
      height: ${theme.base.headerHeightM}px;
      border-radius: ${theme.borderRadius[2]};
      border-bottom: none;
      box-shadow: ${theme.shadow.mapObjectShadow};
  `}

${({ border }) =>
    border &&
    `
    border-bottom-color: ${theme.colors.gray[200]};
  `}

  ${({ isScrolled }) =>
    isScrolled &&
    `
    border-bottom-color: ${theme.colors.gray[200]};
  `}


  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor}
  `}

  ${({ color }) =>
    color &&
    `
    color: ${color}
  `}
`

const StyledMiddleContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
`

const StyledHeaderBody = styled(StyledMiddleContainer)`
  flex: 1;
`

const StyledPrependWrap = styled(StyledMiddleContainer)``
const StyledAppendWrap = styled(StyledMiddleContainer)`
  padding-right: 0.5rem;
`

export default HeaderContainer
