import { message } from 'antd'
import { Confirm } from 'components/design'
import { insertDonghoDanji, searchApt } from 'lib/api/notev2/dongho'
import useAxios from 'lib/hooks/useAxios'
import { setVisibleNotPermitPlusModal } from 'modules/noteDongho'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 동호관리
 * 단지 추가 모달 로직 모음
 * @param closeModal 모달 닫기
 * @param searchRefresh 검색한 주소 선택 후 왼쪽 리스트 조회
 */
const NoteDonghoDanjiSearchContainer = ({
  children,
  closeModal,
  searchRefresh,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const visibleNotPermitPlusModal = useSelector(
    (state) => state.noteDongho.visibleNotPermitPlusModal,
  )
  const [onRequest, , data, errorSearch] = useAxios(searchApt) //주소 검색
  const [onRequestInsert, , dataInsert] = useAxios(insertDonghoDanji) //단지 추가
  const [addrList, setAddrList] = useState([]) //주소 리스트
  const [loadingSearch, setLoadingSearch] = useState(false)

  useLayoutEffect(() => {
    if (!visibleNotPermitPlusModal) return
    Confirm.info({
      title: '알림',
      content: '플러스단지 서비스를 지원하지 않는 단지입니다.',
      cancelHide: true,
      okButtonProps: { color: 'blue' },
      onOk: () => {
        dispatch(setVisibleNotPermitPlusModal(false))
      },
      onCancel: () => {
        dispatch(setVisibleNotPermitPlusModal(false))
      },
    })
  }, [visibleNotPermitPlusModal])

  /**
   * 검색 버튼 or 엔터
   */
  const onSearch = (value, key) => {
    if (key) {
      if (key == 'Enter') {
        search(value)
      }
    } else {
      search(value)
    }
  }

  useEffect(() => {
    if (!data) return
    setLoadingSearch(false)
    setAddrList(data?.result || [])
  }, [data])

  useEffect(() => {
    if (!errorSearch) return
    setLoadingSearch(false)
  }, [errorSearch])

  /**
   * 검색 api
   * @param {*} query
   */
  const search = (query) => {
    setLoadingSearch(true)
    onRequest({
      query,
    })
  }

  /**
   * 주소 선택
   * @param {*} address_id
   * @param {*} apt_id
   * @param {*} apt_name
   */
  const onClickAddress = (address_id, apt_id, apt_name) => {
    onRequestInsert({
      address_id,
      apt_id,
    })
  }

  /**
   * 주소 선택(추가) 후
   */
  useEffect(() => {
    if (!dataInsert) return

    if (dataInsert.error == 0) {
      message.success('관심 단지에 추가되었습니다.')
      closeModal()
      setAddrList([])
      searchRefresh()
      if (dataInsert?.result?.[0]?.id) {
        history.replace(`/note/v2/dongho/${dataInsert.result[0].id}`)
        // getDanjiDetail({
        //   danji_id: dataInsert.result[0].id,
        //   type: tab[activeTab],
        // })
      }
    }
  }, [dataInsert])

  const resetSearch = () => {
    setAddrList([])
  }

  return children({
    onSearch,
    addrList,
    onClickAddress,
    resetSearch,
    loadingSearch,
  })
}

export default NoteDonghoDanjiSearchContainer
