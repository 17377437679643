import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoginInfo } from 'lib/api/auth/authApi'

import { setNeedToLogin, setLoginInfo, setPlanInfo } from 'modules/auth'
import { setRecentAddresses, setRecentFavorites } from 'modules/home'
import { setChannels } from 'modules/channel'
import { setNotifications } from 'modules/notification'
import Log from 'lib/utils/Log'
import { useCookies } from 'react-cookie'
import { setNeedToNoteRefresh } from 'modules/note'
import { flushSync } from 'react-dom'

export default function useUserLoader(immediate = true) {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const needToLogin = useSelector((state) => state.auth.needToLogin)

  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'recentAddresses',
  // ])

  const fetchData = async () => {
    setLoading(true)
    const res = await getLoginInfo()

    if (!res?.data) {
      setLoading(false)
      return
    }

    flushSync(() => {
      setData(res.data)
    })

    setLoading(false)
  }

  useEffect(() => {
    if (!immediate) return
    fetchData()
  }, [])

  useEffect(() => {
    if (!data) return
    Log.info('로그인 결과', data)

    // 브라우저에 저장
    if (data?.member_info?.member_srl > 0) {
      localStorage.setItem(
        'memberType',
        JSON.stringify(data.member_info.member_type),
      )
    }

    // if (!localStorage.getItem('notemode')) {
    //   localStorage.setItem('notemode', 'card')
    // }

    // 유저 정보가 있을 경우 store에 저장
    if (data?.member_info) {
      dispatch(setLoginInfo(data.member_info))
    }

    //요금제 정보가 있는 경우
    if (data?.plan) {
      dispatch(setPlanInfo(data.plan))
    }

    // 채널 정보가 있을 경우 store에 저장
    if (data?.channels) dispatch(setChannels(data.channels))

    // 최근 확인한 주소 정보가 있을 경우 store에 저장
    if (data?.recent_addresses) {
      dispatch(setRecentAddresses(data.recent_addresses))
      // setCookie(data.recent_addresses)
    }

    // 즐겨찾기 정보가 있을 경우 store에 저장
    if (data?.favorites) dispatch(setRecentFavorites(data.favorites))

    // 알림 정보가 있을 경우 store에 저장
    if (data?.notifications)
      dispatch(setNotifications(data.notifications))

    if (needToLogin) {
      dispatch(setNeedToNoteRefresh(true))
      dispatch(setNeedToLogin(false))
    }
  }, [data])

  const reset = () => {
    setLoading(false)
    setData(null)
    setError(null)
  }

  return { onRequest: fetchData, loading, data, error, reset }
}
