import { getAdjacentPolygons } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const SummaryLandCanvas = () => {
  const canvasRef = useRef(null) // 토지 2D canvas
  const [message, setMessage] = useState('')
  const [cannotDraw, setCannotDraw] = useState('')
  const [onRequest2d, , data2d] = useAxios(getAdjacentPolygons)

  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  // 토지 건축선 표시
  useEffect(() => {
    if (!currentAddressId) {
      return
    }

    onRequest2d({
      address_id: currentAddressId,
      aspect_ratio: 1.333,
      margin_ratio: 0.25,
    })
    // clear function
    return () => setMessage('')
  }, [currentAddressId])

  useEffect(() => {
    if (!data2d) return
    if (!canvasRef?.current) return
    if (
      data2d?.display_area.length == 0 &&
      data2d?.buildline.length == 0 &&
      data2d?.polygons.length == 0
    ) {
      setCannotDraw(true)
      return
    }

    window.addr2dshape(
      canvasRef.current,
      data2d?.display_area || [],
      data2d?.buildline || [],
      data2d?.polygons || [],
    )
  }, [data2d])

  return (
    !message &&
    !cannotDraw && (
      <CanvasWrapper>
        <canvas
          className="shape"
          ref={canvasRef}
          width="696"
          height="340"
        ></canvas>
      </CanvasWrapper>
    )
  )
}

const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 172px;
  border: 1px solid ${theme.colors.gray[300]};
  border-radius: ${theme.borderRadius[2]};

  & > canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
export default SummaryLandCanvas
