import styled from 'styled-components'
import BdsHeaderImages from './BdsHeaderImages'
import BdsHeaderSummary from './BdsHeaderSummary'

/**
 * 주택 부동산 상세
 * 최상단 헤더(사진, 요약 정보)
 */
const BdsDetailHeader = ({ data }) => {
  return (
    <Wrapper>
      <BdsHeaderImages images={data?.images} />
      <BdsHeaderSummary data={data} />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default BdsDetailHeader
