import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import SettingsFolderList from 'components/jmapnote/settings/SettingsFolderList'
import theme from 'lib/styles/theme'
import { setIsOpenNoteExternal } from 'modules/summary'
import { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useDispatch } from 'react-redux'
import useNoteMatch from '../hooks/useNoteMatch'

const SettingsFolderPage = ({ history }) => {
  const { channelSrl } = useNoteMatch()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsOpenNoteExternal(true))
  }, [])

  const onClose = () => {
    // history.replace(`/note/${channelSrl}/settings`)
  }

  const onBack = () => {
    dispatch(setIsOpenNoteExternal(false))
    history.go(-1)
  }

  return (
    <ExternalLayer
      title="폴더 관리"
      onBack={onBack}
      hiddenClose
      hasAdditionalHeader
      shift
    >
      <DndProvider
        backend={
          window.innerWidth > theme.bp.medium
            ? HTML5Backend
            : TouchBackend
        }
      >
        <SettingsFolderList />
      </DndProvider>
    </ExternalLayer>
  )
}

export default SettingsFolderPage
