import theme from 'lib/styles/theme'
import { resetFilter, setFilterSearchFlag } from 'modules/bds'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

const BdsContentFilterModalFooter = ({ onClose }) => {
  const dispatch = useDispatch()

  /**
   * 필터 초기화
   */
  const onReset = () => {
    dispatch(resetFilter())
  }

  /**
   * 필터 적용
   */
  const onOk = () => {
    dispatch(setFilterSearchFlag(true))
    onClose()
  }

  return (
    <Wrapper>
      <Reset onClick={onReset}>필터 초기화</Reset>
      <Ok onClick={onOk}>필터 적용</Ok>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 60px;
  cursor: pointer;
`

const Reset = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
  font-weight: 600;
  line-height: 130%;
  color: ${theme.colors.gray[600]};
  background-color: ${theme.colors.gray[100]};
  border-bottom-left-radius: 8px;
`

const Ok = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-weight: 700;
  line-height: 130%;
  color: ${theme.colors.base.white};
  background-color: ${theme.colors.blue[400]};
  border-bottom-right-radius: 8px;
`

export default BdsContentFilterModalFooter
