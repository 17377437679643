import BdsSearchContainer from 'containers/bds/BdsSearchContainer'
import theme, { media } from 'lib/styles/theme'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import BdsSearchList from './BdsSearchList'

/**
 *  검색 input
 * @param searchType keydown | enter
 */
const BdsSearch = ({ bindInput, input, clearSearch }) => {
  return (
    <BdsSearchContainer clearSearch={clearSearch}>
      {({
        onSearch,
        searchResults,
        onClickItem,
        visibleSearch,
        ref,
      }) => {
        return (
          <>
            <Wrapper>
              <InputWrap>
                <SearchIcon className="fa fa-search"></SearchIcon>
                <SearchInput
                  onKeyDown={onSearch}
                  type="text"
                  placeholder="주소 또는 단지명을 입력하세요"
                  {...bindInput}
                />
                {input && (
                  <InputClearButton
                    onClick={clearSearch}
                    className="fal fa-times"
                  />
                )}
              </InputWrap>
            </Wrapper>
            <BdsSearchList
              data={searchResults}
              onClickItem={onClickItem}
              visibleSearch={visibleSearch}
              ref={ref}
            />
          </>
        )
      }}
    </BdsSearchContainer>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  width: ${theme.base.container}px;
  height: 52px;
  background-color: ${theme.colors.base.white};
`

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: inherit;
`

const SearchIcon = styled.i`
  color: ${theme.colors.gray[700]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;

  ${media.mediumS`
    font-size: 14px;
  `}
`

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 36px;
  font-size: 16px;
  padding: 0 20px 0 44px;
  outline: none;
  border: none;
`

const InputClearButton = styled.i`
  color: ${theme.colors.gray[600]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  border-radius: 100%;
  background-color: ${theme.colors.gray[200]};
  width: 16px;
  height: 16px;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default BdsSearch
