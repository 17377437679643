import styled from 'styled-components'
import theme from 'lib/styles/theme'

const getToggleColor = (color) => {
  switch (color) {
    case 'orange':
      return theme.colors.sub.orange
    case 'blue':
      return theme.colors.sub.blue
    case 'red':
      return theme.colors.sub.red
    case 'purple':
      return theme.colors.sub.purple
    case 'pink':
      return theme.colors.sub.pink
    default:
      return theme.colors.primary[400]
  }
}

const getTranslateWidth = (size) => {
  switch (size) {
    case 'small':
      return '20px'
    case 'xs':
      return '14px'
    default:
      return '26px'
  }
}

const ToggleWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none;
      opacity: 0.5;
    `}
`
const StyledToggle = styled.div`
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 24px;
  padding: 2px;
  border-radius: 24px;
  background-color: ${theme.colors.gray[200]};
  transition: 0.2s ease-in-out;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `width: 40px;
                height: 20px;`
      case 'xs':
        return `width: 32px;
                height: 19px;`
    }
  }}

  ${({ checked, color }) =>
    checked &&
    `
    box-shadow: 0 0 0 24px ${getToggleColor(color)} inset;
  `}
`
const ToggleDot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['card-1']};
  transition: 0.2s ease-in-out;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return ` width: 16px;
                  height: 16px;`
      case 'xs':
        return `width: 15px;
                height: 15px;`
    }
  }}

  ${({ checked, size }) =>
    checked &&
    `
    transform: translateX(${getTranslateWidth(size)});
  `}
`

const Text = styled.div`
  margin-left: ${({ size }) => {
    switch (size) {
      case 'xs':
        return `0.3rem`
      default:
        return `0.5rem`
    }
  }};

  font-size: ${({ size }) => {
    switch (size) {
      case 'xs':
        return `0.813rem`
      default:
        return `0.875rem`
    }
  }};

  ${({ checked, size }) =>
    checked &&
    `
    color: ${size != 'xs' ? theme.colors.primary[400] : ''};
    font-weight: 700;
  `}
`

const Toggle = ({
  checked,
  setChecked,
  onChange,
  size,
  children,
  color,
  value,
  multiValues,
  onMultipleChange,
  disabled = false,
}) => {
  const onClickToggle = () => {
    if (setChecked) {
      setChecked(!checked)
      return
    }

    if (onChange) onChange(!checked)
    if (onMultipleChange) handleMultipleChange(multiValues, value)
  }

  const handleMultipleChange = (multiValues, value) => {
    if (!Array.isArray(multiValues)) return []

    const isChecked = multiValues.find((e) => e === value)

    if (isChecked) {
      const removedData = multiValues.filter((e) => e !== value)
      onMultipleChange([...removedData])

      return
    }

    onMultipleChange([...multiValues, value])
  }

  const autoChecked = () => {
    if (checked) return checked || false
    if (!multiValues) return checked || false

    return multiValues.find((e) => e === value)
  }

  return (
    <ToggleWrap disabled={disabled}>
      <StyledToggle
        checked={autoChecked()}
        onClick={onClickToggle}
        size={size}
        color={color}
      >
        <ToggleDot checked={autoChecked()} size={size} />
      </StyledToggle>

      {children && (
        <Text checked={autoChecked()} size={size}>
          {children}
        </Text>
      )}
    </ToggleWrap>
  )
}

export default Toggle
