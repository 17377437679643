import SummaryRpDetailTableItemJeonseContainer from './SummaryRpDetailTableItemJeonseContainer'
import SummaryRpDetailTableItemRentContainer from './SummaryRpDetailTableItemRentContainer'
import SummaryRpDetailTableItemSaleContainer from './SummaryRpDetailTableItemSaleContainer'

const SummaryRpDetailTableItemContainer = ({ data, oriData }) => {
  if (data.type == '매매') {
    return (
      <SummaryRpDetailTableItemSaleContainer
        data={data}
        oriData={oriData.filter(({ type }) => type == '매매')}
      />
    )
  } else if (data.type == '전세') {
    return (
      <SummaryRpDetailTableItemJeonseContainer
        data={data}
        oriData={oriData.filter(({ type }) => type == '전세')}
      />
    )
  } else if (data.type == '월세') {
    return (
      <SummaryRpDetailTableItemRentContainer
        data={data}
        oriData={oriData.filter(({ type }) => type == '월세')}
      />
    )
  }
}

export default SummaryRpDetailTableItemContainer
