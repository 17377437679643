import { createContext, useEffect, useState } from 'react'

const DropdownContext = createContext()

export const DropdownProvider = ({ visible, children }) => {
  const [visibleState, setVisibleState] = useState(false)

  useEffect(() => {
    setVisibleState(visible)
  }, [visible])

  const value = {
    state: {
      visibleState,
    },
    actions: {
      setVisibleState,
    },
  }

  return (
    <DropdownContext.Provider value={value}>
      {children}
    </DropdownContext.Provider>
  )
}

export default DropdownContext
