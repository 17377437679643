import moment from 'moment'
import { useRef } from 'react'

function usePriceCellTouch(callBackFn) {
  const touchStartTime = useRef(null)

  const onTouchStart = () => {
    touchStartTime.current = new Date()
  }

  const onTouchEnd = () => {
    const diff = moment(touchStartTime.current).diff(
      new Date(),
      'millisecond',
    )

    if (Math.abs(diff) > 100) return
    callBackFn()
  }

  return { onTouchStart, onTouchEnd }
}

export default usePriceCellTouch
