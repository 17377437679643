import NoteListItem from './NoteListItem'
import NoteListContainer from 'containers/note/newList/NoteListContainer'
import NoteListMobile from 'components/jmapnotev2/NoteList/NoteListMobile'

import { Route } from 'react-router-dom'
import NoteDetail from 'pages/jmapnotev2/NoteDetail'
import NoteListSearch from './NoteListSearch'
import NoteListMobileAdd from 'components/jmapnotev2/NoteList/NoteListMobileAdd'
import styled from 'styled-components'

const NoteListNew = ({ path, hasFolder }) => {
  return (
    <NoteListContainer>
      {({ noteSearchQuery, searchFn, list }) => {
        return (
          <>
            <ListHeader>
              <NoteListSearch
                onSearch={searchFn}
                query={noteSearchQuery}
              />
              <NoteListMobileAdd mode="card" search={searchFn} />
            </ListHeader>
            <NoteListMobile
              data={list}
              mode="card"
              hasFolder={hasFolder}
            />
            <Route
              path={`${path}/:noteSrl`}
              render={(e) => {
                if (e.match.params.noteSrl != 'settings') {
                  return <NoteDetail mode="card" searchFn={searchFn} />
                }
              }}
              exact
            />
          </>
        )
      }}
    </NoteListContainer>
  )
}

const ListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 10px 16px 10px 16px;
`

export default NoteListNew
