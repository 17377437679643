import { createContext, useState } from 'react'

const BaseContext = createContext()

export const BaseProvider = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [headerInverse, setHeaderInverse] = useState(false)
  const [isExternal, setIsExternal] = useState(false)
  const [layoutVisible, setLayoutVisible] = useState(true)
  const [notifications, setNotifications] = useState([])
  const [headerAbsolute, setHeaderAbsolute] = useState(false)
  const [isFold, setIsFold] = useState(false) // summary 패널 접기
  const [isSwipeable, setIsSwipeable] = useState(true)
  const [isSwipe, setIsSwipe] = useState(false)

  const value = {
    state: {
      isScrolled,
      headerInverse,
      isExternal,
      layoutVisible,
      notifications,
      headerAbsolute,
      isFold,
      isSwipeable,
      isSwipe,
    },
    actions: {
      setIsScrolled,
      setHeaderInverse,
      setIsExternal,
      setLayoutVisible,
      setNotifications,
      setHeaderAbsolute,
      setIsFold,
      setIsSwipeable,
      setIsSwipe,
    },
  }

  return (
    <BaseContext.Provider value={value}>
      {children}
    </BaseContext.Provider>
  )
}

export default BaseContext
