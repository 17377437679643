import { useSelector } from 'react-redux'
import { AccountInput } from './NoteAccount'
import { useFormContext } from 'react-hook-form'

/**
 * 계정관리 폼 - 상호명
 */
const NoteAccountCompanyName = ({ disabled }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { register } = useFormContext()

  return disabled ? (
    <span>{loginInfo?.company_name}</span>
  ) : (
    <AccountInput
      name="company_name"
      defaultValue={loginInfo?.company_name}
      design={'box'}
      {...register('company_name')}
    />
  )
}

export default NoteAccountCompanyName
