import { forwardRef, useContext } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import ListItemLabel from './ListItemLabel'
import ListItemDesc from './ListItemDesc'
import { rgba } from 'polished'

import PropTypes from 'prop-types'

import { CardContext } from 'components/design'

const StyledLi = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
  transition: 0.2s ease;

  ${({ size }) =>
    size === 'large' &&
    `
    padding-left: 1.5rem;
  `}

  ${({ small }) =>
    small &&
    `
    font-size: 0.875rem;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.6;
  `}

  ${({ noContainer }) =>
    noContainer &&
    `
    padding-left: 0;
  `}

  ${({ hover }) =>
    hover &&
    `
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.gray[50]};
    }
  `}
  
  ${({ lastChildNoBorder }) =>
    lastChildNoBorder &&
    `
      &:last-child > article {
        border-bottom: 0;
      }
  `}

  ${({ outline }) =>
    outline &&
    `
    border: 1px solid ${theme.colors.gray[200]};
    border-radius: ${theme.borderRadius[1]};
  `}

${({ grayCard }) =>
    grayCard &&
    `
    margin: 1rem 1.25rem;
    background-color: ${theme.colors.gray[100]};
    border-radius: ${theme.borderRadius[2]};
  `}

  ${({ actived, color }) =>
    actived &&
    `
      background-color: ${
        color && color == 'blue'
          ? `${rgba(theme.colors.blue[50], 0.3)}`
          : `${rgba(theme.colors.primary[50], 0.3)}`
      };
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 3px;
        height: 100%;
        background-color: ${
          color && color == 'blue'
            ? `${theme.colors.blue[400]};`
            : `${theme.colors.primary[400]};`
        };
      }
  `}

${({ actived, noContainer }) =>
    actived &&
    noContainer &&
    `
      color: ${theme.colors.primary[500]};
      background-color: ${rgba(theme.colors.primary[50], 0.3)};
      &::after {
        content: none;
      }
  `}
`

const StyledItemBorder = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 1.25rem;

  border-bottom: 1px solid ${theme.colors.gray[100]};

  ${({ noContainer }) =>
    noContainer &&
    `
    padding-right: 0;
  `}

  ${({ hiddenDivider }) =>
    hiddenDivider &&
    `
    border-bottom: none;
  `}
`

const StyledItem = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;

  padding-top: ${({ padding }) => padding};
  padding-bottom: ${({ padding }) => padding};

  ${({ dense }) =>
    dense &&
    `
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  `}

  ${({ noPadding }) =>
    noPadding &&
    `
    padding-top: 0;
    padding-bottom: 0;
  `}

  ${({ margin }) =>
    margin &&
    `
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1.25rem;
  `}

${({ margin }) =>
    typeof margin === 'string' &&
    `
    margin-bottom: ${margin};
  `}
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
const Prepend = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`
const Append = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`

const ChevronWrap = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`

const Chevron = styled.svg`
  color: ${theme.colors.gray[500]};
  width: 1.25rem;
  height: 1.25rem;

  transition: 0.2s ease;

  &:hover {
    color: ${theme.colors.gray[600]};
  }
`

const ListItem = forwardRef(
  (
    {
      className,
      label,
      description,
      children,
      append,
      prepend,
      chevron,
      onClick,
      noContainer,
      hiddenDivider,
      dense,
      margin,
      lastChildNoBorder,
      actived,
      hover = false,
      outline = false,
      as,
      disabled,
      small,
      noPadding,
      padding = '1.25rem',
      grayCard = false,
      color,
    },
    ref,
  ) => {
    const context = useContext(CardContext) || null

    const cardSize = context?.state?.sizeState || 'medium'

    return (
      <StyledLi
        ref={ref}
        className={className}
        noContainer={noContainer}
        hover={onClick ? true : hover}
        lastChildNoBorder={lastChildNoBorder}
        actived={actived}
        as={as}
        disabled={disabled}
        outline={outline}
        grayCard={grayCard}
        small={small}
        color={color}
        size={cardSize}
      >
        <StyledItemBorder
          noContainer={noContainer}
          hiddenDivider={hiddenDivider || outline || grayCard}
        >
          <StyledItem
            dense={dense}
            noPadding={noPadding}
            padding={padding}
            margin={margin}
            onClick={onClick}
          >
            {prepend && <Prepend>{prepend}</Prepend>}

            <Body>
              {label && <ListItemLabel>{label}</ListItemLabel>}
              {description && (
                <ListItemDesc>{description}</ListItemDesc>
              )}
              {children}
            </Body>

            {chevron && (
              <ChevronWrap>
                <Chevron
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </Chevron>
              </ChevronWrap>
            )}
          </StyledItem>
          {append && <Append>{append}</Append>}
        </StyledItemBorder>
      </StyledLi>
    )
  },
)

ListItem.propTypes = {
  hover: PropTypes.bool,
  outline: PropTypes.bool,
  grayCard: PropTypes.bool,
  padding: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default ListItem
