import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'
import { notification } from 'antd'
import { getAddress } from 'lib/utils'
import MapContext from 'contexts/map'
import { setCombinationAddressPolygon } from 'components/summary/SummarySizeReview/map/SummarySizeReviewPolygon'

const SummarySizeReviewMapClickContainer = ({ map }) => {
  const { kakao } = window
  const { state, actions } = useContext(MapContext)

  const mapClickDisabled = useSelector(
    (state) => state.map.mapClickDisabled,
  )

  const openNotification = (message) => {
    notification['info']({
      message: '알림',
      description: message,
    })
  }

  const onClickMap = (mouseEvent) => {
    if (mapClickDisabled) return
    if (kakao?.maps?.event?.isClickDisabled()) return

    const latlng = mouseEvent.latLng

    getAddress(latlng.getLat(), latlng.getLng())
      .then((res) => {
        const result = res.data.result
        if (result && Object.keys(result).length > 0) {
          // if (result.land_type) {
          //   if (
          //     result.land_type === '하천' ||
          //     result.land_type === '도로'
          //   ) {
          //     openNotification('도로와 하천은 선택할 수 없습니다.')
          //     return
          //   }
          // }

          //기존에 선택한 토지인지
          if (isStandard(state.polygons, result, kakao)) return

          if (!state.clickPosition) return

          const id = result.id

          //선택한 영역이면 선택 해제
          const activeCombinationIdArr = state.combinationPolygons.map(
            ({ id: combinationId }) => combinationId,
          )

          if (activeCombinationIdArr.includes(id)) {
            const clickPolygon = state.combinationPolygons.filter(
              ({ id: combinationId }) => id === combinationId,
            )

            clickPolygon[0].land.map((landItem) => {
              landItem.setMap(null)
            })

            clickPolygon[0].building.length > 0 &&
              clickPolygon[0].building.map((buildingItem) => {
                buildingItem.setMap(null)
              })

            actions.setCombinationPolygons(
              state.combinationPolygons.filter(
                ({ id: combinationId }) => id !== combinationId,
              ),
            )

            return
          }

          //부속토지를 선택했는지
          if (hasSub(result, kakao, state)) return

          if (activeCombinationIdArr.length > 2) {
            notification.warning({
              message: `최대 4개까지 선택 가능합니다.`,
            })
            return
          }

          setCombinationAddressPolygon(res, kakao, state, actions)
        } else if (result && Object.keys(result).length === 0) {
          openNotification('검색 결과가 없습니다.')
        } else if (res.data?.error === -2) {
          openNotification(res.data.message)
        }
      })
      .catch((e) => {
        console.error(e)
        openNotification(
          '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요.',
        )
      })
  }

  useEffect(() => {
    /**
     * @METHOD: 지도 클릭 이벤트
     */
    kakao.maps.event.addListener(map, 'click', onClickMap)

    return () => {
      kakao.maps.event.removeListener(map, 'click', onClickMap)
    }
  }, [
    mapClickDisabled,
    state.combinationStandardPolygons,
    state.combinationPolygons,
  ])

  return null
}

/**
 * @METHOD: 합필 토글시 클릭한 토지가 기준 토지인지 판단하는 함수
 */
const isStandard = (standard, clicked, kakao) => {
  if (!standard) return
  const standardLatLngArr = standard.map((item) => item.getPath())

  const clickLatng = clicked.shape.coordinates.map((part) => {
    return part.map(
      (point) => new kakao.maps.LatLng(point[0], point[1]),
    )
  })

  const result = standardLatLngArr.filter((standardLatLng) =>
    isEqual(standardLatLng, clickLatng),
  )

  return result.length > 0
}

//부속토지가 선택(활성화)되있는지
const hasSub = (clickResult, kakao, state) => {
  //부속토지 없으면 나감
  if (clickResult.sub_addresses.length < 1) return false

  //클릭한 토지의 부속토지 배열
  const subPolygonPath = clickResult.sub_addresses.map((poly) => {
    const shpae_points = poly.shape.coordinates

    return shpae_points.map((part) => {
      return part.map((point) => {
        return new kakao.maps.LatLng(point[0], point[1])
      })
    })
  })

  //이미 선택되어 있는 토지에 방금 클릭한 토지의 부속토지가 포함되어 있는지
  const hasSubArr = state.combinationPolygons.map((stateItems) =>
    stateItems.land.map((stateItem) => {
      return subPolygonPath.filter((subItem) => {
        return isEqual(subItem, stateItem.getPath()) === true
      })
    }),
  )

  //아래 로직은 Array Depth가 깊다보니 비어있는지 확인하는 과정
  if (hasSubArr.length === 0) return false

  if (hasSubArr[0].filter((aa) => !isEmpty(aa)) === 0) return false

  const getNotEmptyResult = hasSubArr
    .filter((rstItem) => {
      return !isEmpty(
        rstItem.filter((item) => {
          return !isEmpty(item)
        }),
      )
    })
    .filter((item) => {
      return !isEmpty(item)
    })

  return getNotEmptyResult.length > 0
}

export default SummarySizeReviewMapClickContainer
