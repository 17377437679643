import { getOptions } from 'lib/api/notev2/adForm'
import useAxios from '../useAxios'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setAdOptions } from 'modules/noteAd'

/**
 * 광고 폼에 사용되는 option 호출
 */
function useAdOptions() {
  const dispatch = useDispatch()
  const [onRequest, , data] = useAxios(getOptions)

  const getAdOptions = () => {
    onRequest()
  }

  useEffect(() => {
    if (!data || !data?.result) return

    dispatch(setAdOptions(data.result))
  }, [data])

  return { getAdOptions }
}

export default useAdOptions
