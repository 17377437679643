import theme from 'lib/styles/theme'
import styled from 'styled-components'
import SummaryGovLandPriceListItem from './SummaryGovLandPriceListItem'
import { moreViewBasicCss } from '../style/SummaryStyle'

const SummaryGovLandPriceList = ({ data, viewCnt }) => {
  return (
    <Wrapper>
      <Header>
        <div>개별공시지가기준일</div>
        <div>개별공시지가</div>
      </Header>
      <List viewcnt={viewCnt}>
        {[...data]
          .sort((a, b) => {
            return b.year - a.year
          })
          .map((item, i) => {
            if (item?.yet == true) return null
            return (
              <SummaryGovLandPriceListItem
                key={i}
                date={item.data_date}
                price={item.unit_price}
              />
            )
          })}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  font-size: 13px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 7px;
  border-bottom: 1px solid ${theme.colors.gray[100]};
  line-height: 180%;
  font-weight: 500;
  color: ${theme.colors.gray[600]};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  ${moreViewBasicCss}
`

export default SummaryGovLandPriceList
