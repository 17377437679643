import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { Tooltip } from 'antd'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import {
  CustomSingleCellInputWrapper,
  CustomSingleCellStyle,
} from 'components/jmapnotev2/style/NoteStyle'
import theme from 'lib/styles/theme'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import useAxios from 'lib/hooks/useAxios'
import { getContacts, saveContact } from 'lib/api/note/contactApi'
import { useDispatch } from 'react-redux'
import { setNoteContacts } from 'modules/notev2'
import CustomContactCellSelectItem from './CustomContactCellSelectItem'
import useIsMobile from 'lib/hooks/useIsMobile'

const CustomContactCellItem = ({
  noteContactSrl: prevNoteContactSrl,
  contactSrl: prevContactSrl,
  type,
  alias,
  numbers,
  node,
  contactList,
  rowContactData,
  contacts,
  noteSrl,
  removeNewLine,
  item,
  i,
  isNewContact,
  isReversePosition,
  calcReverse,
}) => {
  const noteContacts = useSelector((state) => state.notev2.noteContacts)
  const [onRequestModifyNote, isErrorModifyNote] = useModifyNote()

  const [isBlockSelectVisible, setIsBlockSelectVisible] =
    useState(false)
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)

  const selectBlockRef = useRef()
  useOnClickOutside(selectBlockRef, () => {
    setIsBlockSelectVisible(false)
  })
  const selectRef = useRef(null)
  const viewRef = useRef(null)

  const isMobile = useIsMobile()

  useOnClickOutside(viewRef, () => {
    if (isMobile) return
    setIsEditable(false)
  })
  const [onRequestGetContacts, , dataContacts] = useAxios(getContacts)
  const [onRequestSaveContact, , dataSaveContact] =
    useAxios(saveContact)
  const [addedContactSrl, setAddedContactSrl] = useState('')

  const dispatch = useDispatch()

  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    //   isReversePosition,
  } = useCustomSingleCell(node?.data?.status, node?.rowIndex)

  const [selectTypes, setSelectTypes] = useState([])
  const options = useSelector((state) => state.notev2.noteOptions)

  useEffect(() => {
    if (!options || Object.keys(options).length == 0) return
    const newData = options.phone_types?.map((item) => {
      return { label: item, value: item }
    }) || [{ label: '', value: '' }]

    setSelectTypes(newData)
  }, [options])

  useEffect(() => {
    //수정 모드가 되면 자동 포커스
    if (isEditable) {
      selectRef.current?.focus()
    }

    setIsSelectMenuOpen(isEditable)
  }, [isEditable])

  /**
   * 새 연락처 등록에 성공하면 매물에도 연락처 업데이트
   */
  useEffect(() => {
    if (!dataSaveContact) return

    setAddedContactSrl(dataSaveContact.result)
    onRequestGetContacts()
  }, [dataSaveContact])

  /**
   * 신규로 행이 추가되면 isNewContact= true로 넘어온다
   * 이때 강제 focus 지정
   */
  useEffect(() => {
    if (!rowContactData || rowContactData.length == 0) return

    if (isNewContact && noteSrl != '신규') {
      if (!numbers) {
        setIsEditable(true)
      }
    }
  }, [isNewContact])

  useEffect(() => {
    if (!dataContacts) return
    dispatch(setNoteContacts(dataContacts.result))

    const newData = dataContacts.result.filter(
      ({ contact_srl }) => contact_srl == addedContactSrl.contact_srl,
    )

    //신규 줄은 제거
    const willUpdateContatcts = node.data.contacts.filter(
      ({ isNewContact }) => !isNewContact,
    )

    const parseContactSrls = node.data.contact_srls
      ? JSON.parse(node.data.contact_srls)
      : []
    const srlsData = [...parseContactSrls, ...newData]

    onRequestModifyNote({
      data: node.data,
      updateData: {
        contacts: [...willUpdateContatcts, ...newData],
        contact_srls: JSON.stringify(srlsData),
      },
    })

    updateGridDatas({
      currentRowNode: node,
      newData: {
        contacts: [...willUpdateContatcts, ...newData],
        contact_srls: JSON.stringify(srlsData),
      },
    })

    setAddedContactSrl('')
    viewRef.current?.focus()

    return () => {
      setAddedContactSrl('')
    }
  }, [dataContacts])

  /**
   * 앞에 붙는 블럭 옵션 모음
   */
  const getTypeColor = useCallback(
    (type) => {
      const result = {
        집주인: {
          label: '주',
          color: '#DDE3FF',
        },
        세입자: {
          label: '세',
          color: '#FFECDB',
        },
        매수인: {
          label: '매',
          color: '#BBE3C4',
        },
        대리인: {
          label: '대',
          color: '#FFD1F5',
        },
      }

      const disalbeColor = theme.colors.gray[200]

      return result[type]
        ? Object.assign(
            {},
            result[type],
            node.data.status == 2 ? { color: disalbeColor } : {},
          )
        : {
            label: '?',
            color: node.data.status == 2 ? disalbeColor : '#FFD1D1',
          }
    },
    [node.data.status],
  )

  /**
   * 타입 블록모양 클릭시 선택할 수 있는 리스트
   */
  const onClickTypeBlock = useCallback(() => {
    calcReverse()
    setIsBlockSelectVisible((prev) => !prev)
  }, [])

  /**
   * 연락처 타입 선택
   */
  const onClickTypeItem = useCallback(
    (type) => {
      const oriContactsData = node.data.contacts

      const newData = oriContactsData.map((item) => {
        if (item.contact_srl == prevContactSrl) {
          return {
            ...item,
            type: type,
          }
        }

        return item
      })

      //라벨을 먼저 선택했을때는 그리드 데이터만 업데이트
      if (prevContactSrl) {
        onRequestModifyNote({
          data: node.data,
          updateData: {
            contacts: newData,
            contact_srls: JSON.stringify(newData),
          },
        })
      }

      updateGridDatas({
        currentRowNode: node,
        newData: {
          contacts: newData,
          contact_srls: JSON.stringify(newData),
        },
      })
    },
    [node.data, prevContactSrl, prevNoteContactSrl],
  )

  const contactItemHeight = useMemo(() => {
    if (node.data.contacts.length == 1) {
      return 'calc(100% + 2px)'
    } else {
      return `calc(${100 / node.data.contacts.length}% + ${
        2 / node.data.contacts.length
      }px);`
    }
  }, [node.data])

  return (
    <Wrapper contactItemHeight={contactItemHeight}>
      <ContactView
        tabIndex={0}
        onDoubleClick={onDoubleClickSingleCell}
        onKeyDown={onKeyDownSingleCell}
        ref={viewRef}
      >
        <Tooltip
          title={`${type}/${alias}`}
          overlayInnerStyle={{
            fontSize: '10px',
            lineHeight: '100%',
            minHeight: 'unset',
            cursor: 'pointer',
          }}
        >
          <TypeBlock
            onClick={onClickTypeBlock}
            blockcolor={getTypeColor(type).color}
          >
            {getTypeColor(type).label}
            <TypeBlockList
              ref={selectBlockRef}
              visible={isBlockSelectVisible}
              reverse={isReversePosition ? 'Y' : 'N'}
            >
              {selectTypes?.map((item, i) => {
                return (
                  <TypeBlockListItemWrapper
                    key={i}
                    onClick={() => onClickTypeItem(item.label)}
                  >
                    <TypeBlockListItem
                      backgroundcolor={getTypeColor(item.label).color}
                    >
                      {item.label}
                    </TypeBlockListItem>
                  </TypeBlockListItemWrapper>
                )
              })}
            </TypeBlockList>
          </TypeBlock>
        </Tooltip>
        {numbers}
      </ContactView>
      {isEditable && (
        <CustomSingleCellInputWrapper>
          <CustomContactCellSelectItem
            isReversePosition={isReversePosition}
            options={contactList}
            item={item}
            contacts={contacts}
            i={i}
            contactList={contactList}
            contactItemHeight={contactItemHeight}
            ref={viewRef}
            node={node}
            setIsEditable={setIsEditable}
            prevNoteContactSrl={prevNoteContactSrl}
            removeNewLine={removeNewLine}
            isSelectMenuOpen={isSelectMenuOpen}
            setIsSelectMenuOpen={setIsSelectMenuOpen}
            onRequestSaveContact={onRequestSaveContact}
            type={type}
            prevContactSrl={prevContactSrl}
          />
        </CustomSingleCellInputWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(CustomSingleCellInputWrapper)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${({ contactItemHeight }) => contactItemHeight};
  border: none;
`

const ContactView = styled.div`
  display: flex;
  position: absolute;
  line-height: 100%;
  top: 0;
  width: 95%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  padding: 0 5px;
  overflow: visible;
  ${CustomSingleCellStyle}
`

export const TypeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(var(--ag-font-size) + 3px);
  height: calc(var(--ag-font-size) + 3px);
  margin-right: 4px;
  padding: 2px;
  border-radius: 2px;
  background-color: ${({ blockcolor }) => blockcolor};
  line-height: 100%;
  font-size: calc(var(--ag-font-size) - 1px);
  cursor: pointer;
`

const TypeBlockList = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: -64px;
  background-color: ${theme.colors.base.white};
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 4px;
  box-shadow: ${theme.shadow['card-2']};

  ${({ visible }) =>
    visible &&
    `
      display: flex;
      flex-direction: column;
    `};

  ${({ reverse }) =>
    reverse == 'Y' &&
    `
      top: -120px;
    `};
`

const TypeBlockListItemWrapper = styled.div`
  padding: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.blue[50]};
  }
`

const TypeBlockListItem = styled.div`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  padding: 4px;
  font-size: calc(var(--ag-font-size) - 1px);
  border-radius: 4px;
`

export default React.memo(CustomContactCellItem)
