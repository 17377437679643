import { message } from 'antd'
import { getBdsList } from 'lib/api/bds/bdsApi'
import useAxios from 'lib/hooks/useAxios'
import { setRealtorInfo } from 'modules/bds'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const BdsDetailRealestateContainer = ({ children }) => {
  const dispatch = useDispatch()
  const list = useSelector((state) => state.bds.list)
  const [onRequest, , data] = useAxios(getBdsList)

  /**
   * 문의하기(전화)
   */
  const onClickTel = (e, phone_number) => {
    if (!phone_number) {
      e.preventDefault()
      message.info('등록된 전화번호가 없습니다.')
      return
    }
  }

  /**
   * 모아보기
   */
  const onClickGather = (realtorNumber, realtor) => {
    if (!realtorNumber) {
      message.info('유효하지 않은 공인중개사입니다.')
    }
    dispatch(
      setRealtorInfo(
        Object.assign({}, { realtorNumber: realtorNumber }, realtor),
      ),
    )
  }

  return children({
    onClickTel,
    onClickGather,
  })
}

export default BdsDetailRealestateContainer
