import { message } from 'antd'
import MapContext from 'contexts/map'
import { useContext, useEffect, useState } from 'react'

const MapFilterZoomNoti = ({ children }) => {
  const {
    state: { viewState, layerState },
  } = useContext(MapContext)
  const [isNotied, setIsNotied] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    if (!viewState?.level) return
    if (
      !layerState.building &&
      !layerState.land &&
      !layerState.buildingAgeAcitve
    )
      return

    if (viewState.level > 2) {
      if (isNotied) return

      messageApi.open({
        type: 'info',
        content:
          '노후도 및 건물, 토지 필터를 확인하시려면 지도를 확대해주세요.',
        className: 'ant-custom-zoom-noti',
        duration: 5,
        style: {
          marginTop: '47vh',
          marginLeft: '5vw',
        },
      })
      setIsNotied(true)
    } else {
      messageApi.destroy()
      if (!isNotied) return
      setIsNotied(false)
    }
  }, [viewState?.level])

  return <>{contextHolder}</>
}

export default MapFilterZoomNoti
