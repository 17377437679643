import { useCallback, useState, useEffect } from 'react'
import useAxios from '../useAxios'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { createNoteWithNaver } from 'lib/api/notev2/ad'
import { message } from 'antd'
import usePrivateChannel from './usePrivateChannel'

/**
 * 광고관리 그리드
 * note와 ad 한꺼번에 생성
 * 주소, 종류, 거래구분, 가격이 모두 채워지면 호출
 *
 */
function useCreateAdWithNote() {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const { isReadOnlyChannel, privateChannel } = usePrivateChannel()

  //TODO: 업데이트 api
  const [onRequest, , data, error] = useAxios(createNoteWithNaver, {
    resetError: true,
  })
  const [isError, setIsError] = useState(false)
  const [rowNode, setRowNode] = useState(null)

  /**
   * 생성
   * @param channel_srl 채널 srl
   * @param address_srl 주소 srl
   * @param form 주소 정보
   */
  const onCreate = useCallback((updateData, node) => {
    if (isReadOnlyChannel) return

    setRowNode(node)

    const priceParam = getPriceParam(updateData)
    onRequest({
      plan_srl: planInfo.allinone_plan_srl,
      channel_srl: privateChannel?.channel_srl,
      ad_srl: updateData.ad_srl,
      address_id: updateData.address_id,
      display_address: updateData.display_address,
      dong: updateData.dong,
      ho: updateData.ho,
      property_type: updateData.property_type,
      contract_area: updateData.contract_area,
      exclusive_area: updateData.exclusive_area,
      contract_type: updateData.contract_type,
      ...priceParam,
    })
  }, [])

  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.error('modify note 에러')
      setIsError(true)
      return
    }

    if (rowNode) {
      //grid
      //already_created : 광고 생성됨을 Y로 변경
      updateGridDatas({
        newData: Object.assign(
          {},
          { already_created: 'Y' },
          {
            last_update: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
        ),
        currentRowNode: rowNode,
      })
    }
  }, [data])

  useEffect(() => {
    if (!error) return
    //message.error('광고 변환 중 오류가 발생했습니다.')
  }, [error])

  /**
   * 가격 파라미터 생성
   * @param {*} updateData
   */
  const getPriceParam = (updateData) => {
    const type = updateData.contract_type
    if (type == '매매') {
      return { sale_price: updateData.sale_price }
    } else if (type == '전세') {
      return { deposit: updateData.deposit }
    } else if (type == '월세') {
      return { deposit: updateData.deposit, rent: updateData.rent }
    } else {
      return {}
    }
  }

  return [onCreate]
}

export default useCreateAdWithNote
