import { Modal } from 'components/design'
import BdsContentFilterModalFooter from './BdsContentFilterModalFooter'
import BdsContentFilterContractType from './BdsContentFilterContractType'
import BdsContentFilterPropertyType from './BdsContentFilterPropertyType'
import styled from 'styled-components'
import BdsContentFilterPrices from './BdsContentFilterPrices'
import BdsContentFilterArea from './BdsContentFilterArea'

/**
 * 주택부동산
 * 필터 모달
 */
const BdsContentFilterModal = ({ visible, onCancel }) => {
  return (
    <Modal
      flex
      fullheight
      title="상세 필터"
      visible={visible}
      //onOk={onOk}
      onCancel={onCancel}
      width={420}
      footer={<BdsContentFilterModalFooter onClose={onCancel} />}
    >
      <Wrapper>
        <BdsContentFilterContractType />
        <BdsContentFilterPropertyType />
        <BdsContentFilterPrices />
        <BdsContentFilterArea />
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
`

export default BdsContentFilterModal
