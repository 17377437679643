import theme from 'lib/styles/theme'
import { getSimpleDateFormat } from 'lib/utils/notev2/util'
import styled from 'styled-components'

const CustomJungdogeumCell = ({ jungdogeum }) => {
  return (
    <Wrapper>
      <Block
        border={jungdogeum && jungdogeum.length > 1}
        fullwidth={jungdogeum && jungdogeum.length == 1}
      >
        {jungdogeum &&
          jungdogeum.length > 0 &&
          (getSimpleDateFormat(jungdogeum[0].date) || <>&ndash;</>)}
      </Block>
      {jungdogeum && jungdogeum.length > 1 && (
        <Block>
          {getSimpleDateFormat(jungdogeum[1].date) || <>&ndash;</>}
        </Block>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`

const Block = styled.div`
  width: 50%;
  ${({ border }) =>
    border && `border-right: 1px solid ${theme.colors.gray[200]};`}
  ${({ fullwidth }) => fullwidth && `width: 100%;`}
`

export default CustomJungdogeumCell
