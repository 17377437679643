import styled from 'styled-components'
import NoteDetailContents from './NoteDetailContents'
import React, { Suspense, useMemo, useRef } from 'react'
import { Skeleton } from 'antd'
import theme from 'lib/styles/theme'
import Scrollbars from 'react-custom-scrollbars-2'

const NoteDetailForm = ({ afterPushClose, onClose, callGetNote }) => {
  const NoteDetailFormHeader = useMemo(
    () => React.lazy(() => import('./NoteDetailFormHeader')),
    [],
  )

  const scrollRef = useRef(null)

  return (
    <ScrollbarsWrapper>
      <Scrollbars ref={scrollRef} autoHide>
        <Suspense
          fallback={
            <SkeletonWrapper>
              <Skeleton active />
            </SkeletonWrapper>
          }
        >
          <NoteDetailFormHeader />
        </Suspense>
        <NoteDetailContents
          callGetNote={callGetNote}
          afterPushClose={afterPushClose}
          onClose={onClose}
          ref={scrollRef}
        />
      </Scrollbars>
    </ScrollbarsWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 50px;
`

const SkeletonWrapper = styled.div`
  padding: 18px 30px;
  background-color: ${theme.colors.gray[50]};
`

const ScrollbarsWrapper = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  z-index: 3;
  position: relative;
  background-color: ${theme.colors.base.white};
`

export default NoteDetailForm
