import styled from 'styled-components'
import NoteDonghoHoDetailFavorite from './NoteDonghoHoDetailFavorite'
import theme, { media } from 'lib/styles/theme'

/**
 * 호
 */
const NoteDonghoHoDetailHeader = ({ data, onClose }) => {
  return (
    <Header>
      <Dongho>
        {data?.dong_name} {data?.ho_name}
      </Dongho>
      <NoteDonghoHoDetailFavorite
        dongName={data?.dong_name}
        hoName={data?.ho_name}
        id={data?.id}
        isFavorite={data?.is_favorite}
        favoriteId={data?.favorite_id}
      />
      <CloseButton onClick={onClose}>
        <i className="fal fa-times"></i>
      </CloseButton>
    </Header>
  )
}
const Header = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 24px;
  margin-bottom: 16px;

  ${media.mediumS`
  `}
`

const Dongho = styled.div`
  font-size: 22px;
  line-height: 130%;
  font-weight: 700;
`

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 18px;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: ${theme.colors.gray[100]};
  font-size: 20px;
  cursor: pointer;
  color: ${theme.colors.gray[600]};

  ${media.mediumS`
    right: 14px;
  `}
`

export default NoteDonghoHoDetailHeader
