import styled from 'styled-components'
import NoteDashboardQuickLinkListItem from './NoteDashboardQuickLinkListItem'
import theme from 'lib/styles/theme'

/**
 * 매물노트 -대시보드
 * 퀵링크 리스트
 */
const NoteDashboardQuickLinkList = ({ data, hasMore, onClickMore }) => {
  return (
    <Wrapper>
      {data.map(({ icon, text, src }, i) => (
        <NoteDashboardQuickLinkListItem
          key={i}
          icon={icon}
          text={text}
          src={src}
        />
      ))}
      {/* {hasMore && (
        <More onClick={onClickMore}>
          <i className="fa fa-plus"></i>
        </More>
      )} */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-right: -20px;
  width: 100%;
`

const More = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 20px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  background-color: ${theme.colors.gray[200]};
`

export default NoteDashboardQuickLinkList
