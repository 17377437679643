import { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import { notification } from 'antd'
import { Confirm } from 'components/design'

import { Button } from 'components/design'
import { ListItem } from 'components/design'
import { ListItemLabel } from 'components/design'
import { ListItemDesc } from 'components/design'
import { ListItemDivider } from 'components/design'

import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { removeChannel } from 'lib/api/note/channelApi'
import useChannelPermission from 'containers/note/channel/hooks/useChannelPermission'
import useAxios from 'lib/hooks/useAxios'
import { useSelector } from 'react-redux'

const NoteChannelRemove = ({ channel_srl }) => {
  const history = useHistory()
  const { channelSrl } = useNoteMatch()
  const permission = useChannelPermission()

  const channels = useSelector((state) => state.channel.channels)

  const [_removeChannel, loading, data, error, reset] =
    useAxios(removeChannel)

  useEffect(() => {
    if (!data) return

    if (data?.message === 'success') {
      localStorage.removeItem('recentChannelSrl')
      localStorage.removeItem('recentFolderSrl')

      notification.success({ message: '채널이 삭제되었습니다.' })
    }

    if (channels && channels.length > 0 && channels[0].channel_srl) {
      history.replace(`/note/v2/notes/${channels[0].channel_srl}`)
    }
    window.location.reload()
    // 삭제 후 사용자의 기본 채널과 폴더로 redirect
    // TODO: ChannelSelectContainer 채널 목록 갱신해야 함
    // if (data?.url_after_remove) {
    //   const redirectUrl = data?.url_after_remove.replace('map/', '')
    //   history.push(redirectUrl)
    // }
  }, [data])

  const onRemoveChannel = useCallback(() => {
    _removeChannel({
      channelSrl: channel_srl || channelSrl,
    })
  }, [_removeChannel, channelSrl])

  const onClickChannelRemove = useCallback(() => {
    Confirm.error({
      title: '정말로 채널을 삭제하시겠습니까?',
      content:
        '채널을 삭제하면 다시는 되돌릴 수 없으며, 이에 대한 모든 책임은 채널 주인 본인에게 있습니다.',
      okText: '삭제',
      cancelText: '취소',
      onOk: () => onRemoveChannel(),
    })
  })

  if (permission !== 'owner') return null

  return (
    <>
      <ListItem>
        <ListItemLabel>채널 삭제</ListItemLabel>
        <ListItemDesc>
          채널을 삭제하면 매물 정보가 모두 삭제되며, 복구할 수 없습니다.
        </ListItemDesc>

        <ListItemDivider />
        <Button
          size="small"
          color="danger"
          border
          onClick={onClickChannelRemove}
        >
          채널 삭제하기
        </Button>
      </ListItem>
    </>
  )
}

export default NoteChannelRemove
