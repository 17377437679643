import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'components/design'
import SummaryMaster from 'components/summary/SummaryMaster/SummaryMaster'
import SummaryMasterModal from 'components/summary/SummaryMaster/SummaryMasterModal'
import theme from 'lib/styles/theme'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 추천 건축 전문가
 
 * 렌더링 여부
 * 공동주택: X
 * 일반: O
 */
const SummaryMasterContainer = () => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const isApt = useSelector((state) => state.summary.isApt)

  const onClickIcon = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    !isApt &&
    summaryInfo.master &&
    summaryInfo.master.length > 0 && (
      <Card>
        <CardHeader>
          <CardTitle>
            추천 건축 전문가
            <Help onClick={onClickIcon}>
              <i className="fa fa-info-circle" />
            </Help>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Wrapper>
            <SummaryMaster data={summaryInfo.master?.[0]} />
          </Wrapper>
        </CardBody>
        <SummaryMasterModal
          visible={isModalVisible}
          closeModal={closeModal}
        />
      </Card>
    )
  )
}
const Help = styled.div`
  display: inline-flex;
  margin-left: 8px;
  font-size: 16px;
  color: ${theme.colors.gray[400]};
  cursor: pointer;
`

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

export default SummaryMasterContainer
