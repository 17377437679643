import useAxios from '../useAxios'
import { useEffect, useRef, useState } from 'react'
import { getGongbuList } from 'lib/api/notev2/contractApi'
import moment from 'moment'

/**
 * 등기부 조회 반복 호출
 * @parma noteSrl
 * @parma channelSrl
 * @parma endCallbackFn 반복 조회 끝나고 호출할 callback 함수
 * @parma limitDate 조회 기간 설정
 */
function useDeunggiRepeatedCall({
  noteSrl,
  channelSrl,
  endCallbackFn,
  limitDate = null,
}) {
  const [onRequestGonbuList, loadingGoungbu, data] =
    useAxios(getGongbuList)

  const [docs, setDocs] = useState([])

  const [hasPendingDoc, setHasPendingDoc] = useState(false)
  const listCallTimerRef = useRef(null)
  const limitCallCount = useRef(0)

  const limitCount = 10

  useEffect(() => {
    if (!channelSrl || !noteSrl) return
    search()

    return () => {
      if (listCallTimerRef.current) {
        limitCallCount.current = 0
        clearInterval(listCallTimerRef.current)
      }
    }
  }, [channelSrl, noteSrl])

  useEffect(() => {
    if (!data) {
      clearRefreshTimer()
      return
    }
    const result = data.result

    if (limitDate) {
      //발급 받은지 limitDate 이상
      const nowDate = new Date()
      const limitData = result.filter(({ regdate }) => {
        return (
          moment(regdate, 'YYYYMMDDHHmmss').diff(nowDate, 'day') >= -90
        )
      })
      setDocs(limitData)
    } else {
      setDocs(result)
    }

    if (result.length < 1) {
      clearRefreshTimer()
    }

    //complete 상태가 아닌 문서 있는지 확인 후 상태 변경
    if (result.length > 0) {
      const pendingDatas = result.filter(({ status }) =>
        isWorking(status),
      )
      if (pendingDatas.length > 0) {
        // 5번 try후 종료
        if (limitCallCount.current == limitCount) {
          setHasPendingDoc(false)
        } else {
          setHasPendingDoc(true)
        }
      } else {
        endCallbackFn && endCallbackFn()
        clearRefreshTimer()
      }
    }
  }, [data])

  //pending 상태가 없어질때까지 5초마다 call
  useEffect(() => {
    if (!hasPendingDoc) {
      if (listCallTimerRef.current) {
        limitCallCount.current = 0
        clearInterval(listCallTimerRef.current)
      }
      return
    }

    listCallTimerRef.current = setInterval(() => {
      search()
      limitCallCount.current = limitCallCount.current + 1
    }, 5000)
  }, [hasPendingDoc])

  const search = () => {
    if (!channelSrl || !noteSrl) return
    onRequestGonbuList({ channel_srl: channelSrl, note_srl: noteSrl })
  }

  /**
   * 5초마다 조회 타이머 clear
   */
  const clearRefreshTimer = () => {
    if (listCallTimerRef.current) {
      limitCallCount.current = 0
      clearInterval(listCallTimerRef.current)
      setHasPendingDoc(false)
    }
  }

  const isWorking = (value) =>
    ['pending', 'working', 'extracting'].includes(value)

  return {
    docs,
    setHasPendingDoc,
    searchGongbu: search,
    loadingGoungbu,
  }
}
export default useDeunggiRepeatedCall
