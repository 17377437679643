import { useEffect } from 'react'
import useAxios from '../useAxios'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  setActiveTab,
  setHasAddedList,
  setSelectedDanji,
  setSelectedDanjiDongs,
  setSelectedDong,
} from 'modules/noteDongho'
import { getDonghoDanji } from 'lib/api/notev2/dongho'

/**
 * 동호관리
 * 단지의 상세 정보를 호출하고 redux state 초기화 및 세팅
 */
function useGetSelectedDanji() {
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  const activeTab = useSelector((state) => state.noteDongho.activeTab)
  const [onRequestGetDetail, , dataGetDetail] = useAxios(getDonghoDanji)
  const dispatch = useDispatch()

  /**
   * 단지 상세 호출
   */
  const getDanjiDetail = ({ danji_id, type }) => {
    if (activeTab != 1) {
      dispatch(setActiveTab(1))
    }

    dispatch(setSelectedDong(null)) //선택되어있던 동 초기화

    if (danji_id != selectedDanji?.id) {
      //같은 단지 선택하지 않았을때만 동 배열 초기화
      dispatch(setSelectedDanjiDongs([])) //동 array 초기화
    }

    onRequestGetDetail({
      danji_id,
      type,
    })
  }

  /**
   * 단지 상세 호출 결과
   */
  useEffect(() => {
    if (!dataGetDetail) return
    dispatch(setSelectedDanji(dataGetDetail?.result || null))
    dispatch(setHasAddedList(true))
  }, [dataGetDetail?.result])

  return { getDanjiDetail }
}

export default useGetSelectedDanji
