import { useContext } from 'react'
import Slider from 'components/common/Slider/Slider'
import MapContext from 'contexts/map'
import MapFilterItem from './MapFilterItem'
import useMapFilter from 'containers/map/hooks/useMapFilter'

const MapFilterAuction = () => {
  const { state } = useContext(MapContext)
  const { viewState } = state

  const { filterState, handleEndSlider } = useMapFilter()

  return (
    <>
      {viewState.level <= 4 && (
        <>
          <MapFilterItem
            title="경매 감정가"
            titleValue={
              filterState.real_prices.appraisal_price.toString() ===
              '0,50'
                ? '전체'
                : filterState.real_prices.appraisal_price
                    .map((value, index) => {
                      if (index === 0 && value === 0) return ''
                      if (index === 1 && value === 50) return ''

                      return value + '억'
                    })
                    .toString()
                    .replace(',', ' ~ ')
            }
          >
            <Slider
              range={{ min: 0, max: 50 }}
              start={filterState.real_prices.appraisal_price}
              step={5}
              margin={5}
              connect
              behaviour="snap"
              pips={{
                mode: 'positions',
                values: [0, 50, 100],
                density: 10,
                format: {
                  to: (value) => {
                    if (value === 0) return '1억 이하'
                    if (value === 50) return '50억 이상'
                    return value + '억'
                  },
                },
              }}
              onEnd={handleEndSlider('appraisal_price')}
            />
          </MapFilterItem>
          <MapFilterItem
            title="경매 낙찰가"
            titleValue={
              filterState.real_prices.winning_bid.toString() === '0,50'
                ? '전체'
                : filterState.real_prices.winning_bid
                    .map((value, index) => {
                      if (index === 0 && value === 0) return ''
                      if (index === 1 && value === 20) return ''

                      return value + '억'
                    })
                    .toString()
                    .replace(',', ' ~ ')
            }
          >
            <Slider
              range={{ min: 0, max: 50 }}
              start={filterState.real_prices.winning_bid}
              step={5}
              margin={5}
              connect
              behaviour="snap"
              pips={{
                mode: 'positions',
                values: [0, 50, 100],
                density: 20,
                format: {
                  to: (value) => {
                    if (value === 0) return '1억 이하'
                    if (value === 50) return '50억 이상'
                    return value + '억'
                  },
                },
              }}
              onEnd={handleEndSlider('winning_bid')}
            />
          </MapFilterItem>
        </>
      )}
    </>
  )
}

export default MapFilterAuction
