import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ReactComponent as MapIcon } from 'assets/icon/map-fill.svg'
import { message } from 'antd'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NoteDonghoGoMapButton = ({ background }) => {
  const history = useHistory()
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )

  const onClickMap = () => {
    if (!selectedDanji?.address_id) {
      message.error('주소를 찾을 수 없습니다.')
      return
    }

    history.push(`/${selectedDanji.address_id}`)
  }
  return (
    <MapButton onClick={onClickMap} background={background}>
      <MapIcon width={16} height={16} fill="#6B7684" />
    </MapButton>
  )
}

const MapButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
  cursor: pointer;

  &:hover {
    svg {
      fill: ${theme.colors.blue[400]};
    }
  }

  ${({ background }) =>
    !background &&
    `
        background-color: transparent;
        margin-right: 12px;
        width: auto;
        height: auto;
  `}
`

export default NoteDonghoGoMapButton
