import { Button } from 'components/design'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
} from '../../form/NoteAdFormStyle'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import NoteDetailGongbuIssuanceModal from 'components/jmapnotev2/detail/NoteDetailGongbuIssuanceModal'
import { useCallback, useEffect, useState } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { requestDeunggibu } from 'lib/api/notev2/contractApi'
import moment from 'moment'
import useDeunggiRepeatedCall from 'lib/hooks/notev2/useDeunggiRepeatedCall'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'
import { message } from 'antd'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 등기부등본파일 registerUrl
 * PDF 파일 URL
혹은
사진 파일(PNG, GIF, JPG / 10m MAX)
 */
const NoteAdColumnDeunggiFile = ({ isDisabled: pIsDisabled }) => {
  const [onRequestDeunggibu, , dataDeunggibu] = useAxios(
    requestDeunggibu,
    { resetError: true },
  )
  const { watch } = useFormContext()

  const { onUpdate } = useAdUpdate()

  const adSrl = watch('ad_srl')
  const address_jibun = watch('address_jibun')
  const note_srl = watch('note_srl')
  const channel_srl = watch('channel_srl')
  const dong = watch('article.dongNm')
  const ho = watch('article.ho')
  const registerUrl = watch('verification.registerUrl')
  const verificationTypeCode = watch('verification.type')

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const isDisabled =
    pIsDisabled ||
    (adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll) //읽기 전용 여부

  const { docs, setHasPendingDoc, searchGongbu } =
    useDeunggiRepeatedCall({
      endCallbackFn: null,
      noteSrl: note_srl,
      channelSrl: channel_srl,
      limitDate: 90,
    })

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [validOnly, setValidOnly] = useState(true)
  const [subType, setSubType] = useState('building')
  const [uniqueAddress, setUniqueAddress] = useState(null)
  const [selectedItem, setSelectedItem] = useState([]) //선택한 등기부등본(복수)

  const dongho =
    !dong && !ho
      ? ''
      : `${dong ? `${dong}동` : ''}${ho ? ` ${ho}호` : ''}` //수정 불가

  useEffect(() => {
    searchGongbu()
  }, [])

  /**
   * 등기부 발급 버튼
   */
  const onClickNew = () => {
    setIsModalVisible(true)
    //조회 멈춤
    setHasPendingDoc(false)
  }

  /**
   * 등기부 블럭 클릭 동작
   * @param {*} docSrl
   */
  const onClickBlock = (docSrl, status) => {
    if (status != 'complete') {
      message.warning('발급 완료된 등기부등본만 선택할 수 있습니다.')
      return
    }

    if (selectedItem.includes(docSrl)) {
      const filteredItem = selectedItem.filter((item) => item != docSrl)
      onUpdate('verification.registerUrl', filteredItem)
      setSelectedItem(filteredItem)
    } else {
      const concatItem = [...selectedItem, ...[docSrl]]
      onUpdate('verification.registerUrl', concatItem)
      setSelectedItem(concatItem)
    }
  }

  const onClickDeunggibuIssuance = () => {
    //setValue('verification.registerUrl', 등기부 파일 pdf?)
    onRequestDeunggibu({
      channel_srl,
      note_srl,
      sub_type: subType,
      valid_only: validOnly,
      address: address_jibun,
      detail: dongho,
      unique_no: uniqueAddress?.UniqueNo || null,
    })

    setIsModalVisible(false)
  }

  useEffect(() => {
    if (!dataDeunggibu) return

    setUniqueAddress(null)

    //목록 재조회
    searchGongbu()
  }, [dataDeunggibu])

  /**
   * 등기부등본 - 등기사항증명서 종류 radio onchange
   */
  const onChangeValidOnly = useCallback(
    (value) => {
      setValidOnly(value)
    },
    [validOnly],
  )

  /**
   * 등기부등본 - 부동산 구분 radio onchange
   */
  const onChangeSubType = useCallback(
    (value) => {
      setSubType(value)
    },
    [subType],
  )

  const isWorking = (value) =>
    ['pending', 'working', 'extracting'].includes(value)

  const getStatusKor = (value) => {
    return isWorking(value) ? '발급중' : '발급실패'
  }

  return (
    <ColumnSet>
      {/* 모바일확인2인 경우만 필수 사항 */}
      <ColumnTitle required={verificationTypeCode == 'O'}>
        등기부등본파일
      </ColumnTitle>
      <ColumnVal>
        {!isDisabled && (
          <TopWrapper>
            <ButtonWrapper>
              <Button color="gray" size="small" onClick={searchGongbu}>
                <i className="far fa-redo-alt"></i>
              </Button>
              <Button color="blue" size="small" onClick={onClickNew}>
                등기부등본 발급받기
              </Button>
            </ButtonWrapper>
          </TopWrapper>
        )}
        {/* TODO: 발급받은 등본 */}
        <BlockWrapper>
          {isDisabled && docs.length == 0 && (
            <NoData>발급받은 등기부등본이 없습니다.</NoData>
          )}
          {docs.map((item, i) => {
            return (
              <Block
                key={i}
                disabled={isDisabled}
                selected={
                  registerUrl.length > 0
                    ? registerUrl.map(Number).includes(item.doc_srl)
                    : false
                }
                onClick={
                  isDisabled
                    ? null
                    : () => onClickBlock(item.doc_srl, item.status)
                }
              >
                <Title>
                  <span>
                    {item.doc_type != '토지이용계획원' && item.sub_type}{' '}
                    {item.doc_type}
                  </span>
                  {(isWorking(item.status) ||
                    ['error'].includes(item.status)) && (
                    <Status
                      status={
                        isWorking(item.status) ? 'working' : 'error'
                      }
                    >
                      <ErrorStatusMsg>{item.error}</ErrorStatusMsg>
                      {getStatusKor(item.status)}
                      {getStatusKor(item.status) == '발급중' && (
                        <LoadingDots>
                          <span className="dot-one"> .</span>
                          <span className="dot-two"> .</span>
                          <span className="dot-three"> .</span>
                        </LoadingDots>
                      )}
                    </Status>
                  )}
                </Title>
                <Date>
                  발급일&nbsp;
                  {item.regdate &&
                    moment(item.regdate, 'YYYYMMDDHHmmss').format(
                      'YYYY.MM.DD HH:mm',
                    )}
                </Date>
                <Address>{item.address}</Address>
                <Desc>{item.is_valid}</Desc>
              </Block>
            )
          })}
        </BlockWrapper>
        <NoteDetailGongbuIssuanceModal
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          onOk={onClickDeunggibuIssuance}
          customAddress={address_jibun}
          validOnly={validOnly}
          onChangeValidOnly={onChangeValidOnly}
          subType={subType}
          onChangeSubType={onChangeSubType}
          customDongHo={dongho}
          setUniqueAddress={setUniqueAddress}
          uniqueAddress={uniqueAddress}
          addrReadonly
        />
      </ColumnVal>
    </ColumnSet>
  )
}

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  text-align: right;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`

const BlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`

const Block = styled.div`
  position: relative;
  min-width: 348px;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid ${theme.colors.gray[200]};
  cursor: pointer;

  &:hover {
    border: 2px solid ${theme.colors.blue[300]};
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
      &:hover {
      border: 2px solid ${theme.colors.gray[200]};
    }
  `};

  ${({ selected }) =>
    selected &&
    `
    border: 2px solid ${theme.colors.blue[400]};
  `};
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
`

const Date = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 12px;
  color: ${theme.colors.blue[400]};
`

const Address = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
`

const Desc = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
`

const Status = styled.span`
  display: flex;
  align-items: center;

  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
  ${({ status }) => {
    switch (status) {
      case 'working':
        return `color: ${theme.colors.primary[400]};`
      case 'error':
        return `color: ${theme.colors.tint.danger};`
    }
  }}
`

const ErrorStatusMsg = styled.div`
  position: absolute;
  color: ${theme.colors.gray[600]};
  right: 12px;
  bottom: 12px;
  font-size: 12px;
`

const LoadingDots = styled.div`
  span[class^='dot-'] {
    opacity: 0;
  }
  .dot-one {
    animation: dot-one 1.8s infinite linear;
  }
  .dot-two {
    animation: dot-two 1.8s infinite linear;
  }
  .dot-three {
    animation: dot-three 1.8s infinite linear;
  }

  @keyframes dot-one {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot-two {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot-three {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`

const NoData = styled.div`
  font-size: 14px;
  color: ${theme.colors.gray[700]};
`

export default NoteAdColumnDeunggiFile
