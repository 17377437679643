import { Card } from 'components/design'
import { CardBody } from 'components/design'
import { Button } from 'components/design'
import { useSelector } from 'react-redux'
import HomeFeatureInviteReward from './HomeFeatureInviteReward'
import HomeFeatureNonMemberSlide from './HomeFeatureNonMemberSlide'
import HomeFeatureBasicSlide from './HomeFeatureBasicSlide'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

const HomeFeatures = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const planInfo = useSelector((state) => state.auth.planInfo)
  const { hasPlan } = useNoteHasPlan()

  return (
    <Card noMargin>
      <CardBody>
        <HomeFeatureBasicSlide />
        {!loginInfo?.member_srl && <HomeFeatureNonMemberSlide />}
        {loginInfo?.member_srl && hasPlan ? (
          <HomeFeatureInviteReward />
        ) : null}
        <a href="//jootek.com/about_map">
          <Button color="primary" size="large" expand>
            더 자세한 정보
          </Button>
        </a>
      </CardBody>
    </Card>
  )
}

export default HomeFeatures
