import { Empty, Tag } from 'components/design'
import styled from 'styled-components'
import { ReactComponent as NewIcon } from 'assets/icon/note/ad/new.svg'
import theme, { media } from 'lib/styles/theme'
import NoteAdNoticeListContainer from 'containers/notev2/NoteAdNoticeListContainer'
import { ReactComponent as EmptyIcon } from 'assets/icon/note/ad/nodata.svg'
import moment from 'moment'

/**
 * 광고 메인 공지사항 목록
 */
const NoteAdNoticeList = () => {
  return (
    <NoteAdNoticeListContainer>
      {({ listData }) => {
        return (
          <Wrapper>
            {listData.length == 0 && (
              <Empty icon={<EmptyIcon />}>공지사항이 없습니다.</Empty>
            )}
            {listData.length > 0 &&
              listData.slice(0, 3).map(({ variables }, i) => {
                return (
                  <Item key={i}>
                    <Title>
                      {variables.is_notice == 'Y' && (
                        <StyledTag size="small" color="primary">
                          공지
                        </StyledTag>
                      )}

                      <TitleText>{variables.title}</TitleText>
                      <NewIcon />
                    </Title>
                    {variables?.regdate && (
                      <Date>
                        {moment(
                          variables.regdate,
                          'YYYYMMDDHHmmss',
                        ).format('YY.MM.DD')}
                      </Date>
                    )}
                  </Item>
                )
              })}
          </Wrapper>
        )
      }}
    </NoteAdNoticeListContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 17px;
  width: 100%;
  margin-top: 26.5px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledTag = styled(Tag)`
  margin-right: 8px;
  flex-shrink: 0;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 4px;
  }
`

const TitleText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 100%;
  font-size: 14px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`

const Date = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${theme.colors.gray[600]};
  line-height: 100%;
  flex-shrink: 0;

  ${media.xxLarge`
    display: none;
  `}

  ${media.xLarge`
    display: block;
  `}

  ${media.mediumS`
    display: none;
  `}
`

export default NoteAdNoticeList
