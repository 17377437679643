import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 매매가
 */
const NoteAdColumnSalePrice = ({ required = false }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const tradeType = watch('trade.type')
  const price = watch('trade.price1')

  if (tradeType != 'A1' && tradeType != 'B3') return

  return (
    <ColumnSet>
      <ColumnTitle required={required}>매매가</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="number"
            defaultValue={price}
            {...register('trade.price1')}
            onBlur={(e) => {
              onUpdate(
                'trade.price1',
                e.target.value.replaceAll(',', ''),
              )
            }}
            append={'만원'}
          />
        </div>
        <ValidateWarn>
          <div>{errors?.isale?.message}</div>
          <div>{errors?.trade?.price1?.message}</div>
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnSalePrice
