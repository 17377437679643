import axios from 'axios'

/**
 * 동호 주소 검색
 */
export const searchApt = async ({ _rx_csrf_token, query }, options) =>
  await axios.post(
    '/jmapnote/api/dongho/search',
    {
      _rx_csrf_token,
      query,
    },
    options,
  )

/**
 * 동호 주소 검색
 */
export const insertDonghoDanji = async (
  {
    _rx_csrf_token,
    address_id,
    apt_id,
    address_jibun,
    address_doro,
    property_type,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/insert',
    {
      _rx_csrf_token,
      address_id,
      apt_id,
      address_jibun,
      address_doro,
      property_type,
    },
    options,
  )

/**
 * 동호단지 왼쪽의 필터용 목록
 */
export const getDonghoDanjis = async (
  { _rx_csrf_token, page, count },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/list',
    {
      _rx_csrf_token,
      page,
      count,
    },
    options,
  )

/**
 * 동호단지 정보 가져오기
 */
export const getDonghoDanji = async (
  { _rx_csrf_token, danji_id, type },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/view',
    {
      _rx_csrf_token,
      danji_id,
      type,
    },
    options,
  )

/**
 * 동호단지 정보 업데이트
 */
export const updateDonghoDanji = async (
  { _rx_csrf_token, danji_id, is_paid, expiration_date, alias },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/plus',
    {
      _rx_csrf_token,
      danji_id,
      is_paid,
      expiration_date,
      alias,
    },
    options,
  )

/**
 * 동호단지 삭제

 */
export const deleteDonghoDanji = async (
  { _rx_csrf_token, danji_id, address_id },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/delete',
    {
      _rx_csrf_token,
      danji_id,
      address_id,
    },
    options,
  )

/**
 * 호실 정보 가져오기
 */
export const getHoDetail = async (
  { _rx_csrf_token, building_dongho_id, jdongho_danji_id },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/ho',
    {
      _rx_csrf_token,
      building_dongho_id,
      jdongho_danji_id,
    },
    options,
  )

/**
 * 호실 즐겨찾기
 */
export const insertHoFavorite = async (
  {
    _rx_csrf_token,
    building_dongho_id,
    jdongho_danji_id,
    dong_name,
    ho_name,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/ho/insertFavorite',
    {
      _rx_csrf_token,
      building_dongho_id,
      jdongho_danji_id,
      dong_name,
      ho_name,
    },
    options,
  )

/**
 * 호실 즐겨찾기 해제
 */
export const deleteFavorite = async (
  {
    _rx_csrf_token,
    favorite_id,
    building_dongho_id,
    jdongho_danji_id,
    dong_name,
    ho_name,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/ho/deleteFavorite',
    {
      _rx_csrf_token,
      favorite_id,
      building_dongho_id,
      jdongho_danji_id,
      dong_name,
      ho_name,
    },
    options,
  )

/**
 * 메모 생성 (insert)
 */
export const insertHoMemo = async (
  {
    _rx_csrf_token,
    building_dongho_id,
    jdongho_danji_id,
    dong_name,
    ho_name,
    contents,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/dongho/ho/insertMemo',
    {
      _rx_csrf_token,
      building_dongho_id,
      jdongho_danji_id,
      dong_name,
      ho_name,
      contents,
    },
    options,
  )
