import theme from 'lib/styles/theme'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  CartesianAxis,
  ResponsiveContainer,
  Line,
} from 'recharts'

import priceFormat from 'lib/utils/priceFormat'
import CustomizedLegend from '../SummaryGraph/CustomizedLegend'
import SummaryAptGraphTooltip from './SummaryAptGraphTooltip'
import SummaryAptGraphCountTooltip from './SummaryAptGraphCountTooltip'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { groupBy } from 'lodash-es'

const SummaryAptGraph = ({ selectedDate = '전체', data = [] }) => {
  const [validShowXaxisArr, setValidShowXaxisArr] = useState([])

  const dataLabels = {
    sale: '매매',
    charter: '전세',
    saleCount: '매매 거래량',
    chartCount: '전세 거래량',
  }
  const dataColors = {
    sale: '#4378FF',
    charter: '#27a356',
  }

  const tooltipFormatter = (value, name, props) => {
    const unit = 10000

    // 만단위로 반올림
    const mathedPrice = Math.round(value / unit) * unit
    const formattedPrice = priceFormat(mathedPrice)

    return [formattedPrice, dataLabels[name]]
  }

  const thickFormatter = (value) => {
    if (value < 100) {
      return '0원'
    }

    if (value < 10000000) {
      const thounsand = value / 10000
      return thounsand.toFixed(0) + '만'
    }

    if (value < 100000000) {
      const thounsand = value / 10000000
      return thounsand.toFixed(1) + '천'
    }
    return Math.ceil((value / 100000000) * 10) / 10 + '억'
  }

  function CustomizedTick(props) {
    const { x, y, payload } = props

    const value = payload.value

    if (validShowXaxisArr.length <= 8) {
      if (!validShowXaxisArr.includes(value)) return null
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={8}
            fill={theme.colors.gray[600]}
            style={{ fontSize: '12px' }}
          >
            <tspan textAnchor="middle" x="0">
              {value.slice(0, 4)}
            </tspan>
          </text>
        </g>
      )
    } else {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={8}
            fill={theme.colors.gray[600]}
            style={{ fontSize: '12px' }}
          >
            <tspan textAnchor="middle" x="0">
              {value.slice(0, 4)}
            </tspan>
          </text>
        </g>
      )
    }
  }

  useEffect(() => {
    const yearGroupped = groupBy(
      data.map((item) => {
        return {
          ...item,
          name: item.name.slice(0, 4),
          oriName: item.name,
        }
      }),
      'name',
    )

    setValidShowXaxisArr(
      Object.values(yearGroupped)
        .map((arr) => arr[0])
        .map(({ oriName }) => oriName),
    )
  }, [setValidShowXaxisArr])

  if (data.length === 0) return null

  return (
    <>
      <ResponsiveContainer width="100%" height={170}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 0,
            left: 0,
          }}
          syncId="anyId"
        >
          <CartesianGrid
            height={170}
            vertical={false}
            stroke={theme.colors.gray[100]}
          />
          <XAxis
            hide
            dataKey="name"
            // tick={{ fill: theme.colors.gray[600], fontSize: '12px' }}
            // tickLine={false}
            // axisLine={false}
            // height={60}
            // tickSize={12}
          />

          <YAxis
            tickFormatter={thickFormatter}
            tick={{ fill: theme.colors.gray[600], fontSize: '12px' }}
            tickLine={false}
            axisLine={false}
            tickCount={5}
            minTickGap={4}
            scale={'linear'}
            domain={['dataMin', 'dataMax']}
          />

          {/* <YAxis
            dataKey="sale"
            type="category"
            orientation="right"
            yAxisId={1}
          /> */}

          <CartesianAxis />

          <Line
            type="linear"
            dataKey="sale"
            stroke={dataColors['sale']}
            strokeWidth={3}
            dot={false}
            activeDot={true}
            connectNulls
            isAnimationActive={false}
          />

          <Line
            type="linear"
            dataKey="charter"
            stroke={dataColors['charter']}
            strokeWidth={3}
            dot={false}
            activeDot={true}
            connectNulls
            isAnimationActive={false}
          />

          <Bar
            hide={true}
            dataKey="charter"
            connectNulls
            isAnimationActive={false}
          />

          <Tooltip
            position={{ y: 0 }}
            isAnimationActive={false}
            // offset={20}
            cursor={{
              stroke: theme.colors.primary[400],
              strokeWidth: 1,
            }}
            formatter={tooltipFormatter}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: theme.colors.gray[200],
              borderRadius: '8px',
              boxShadow: theme.shadow['card-2'],
              padding: '0.5rem 1rem',
            }}
            content={
              <SummaryAptGraphTooltip
                tooltipFormatter={tooltipFormatter}
              />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>

      {/* 거래량 그래프 */}
      <ResponsiveContainer width="100%" height={150}>
        <ComposedChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            bottom: 20,
            left: 0,
          }}
          syncId="anyId"
        >
          <Legend
            payload={[
              {
                value: '매매',
                type: 'line',
                id: 'sale',
                color: dataColors['sale'],
              },
              {
                value: '전세',
                type: 'line',
                id: 'charter',
                color: dataColors['charter'],
              },
            ]}
            // iconType="square"
            // iconSize={10}
            content={<CustomizedLegend marginLeft />}
          />

          <CartesianGrid height={280} vertical={false} stroke={null} />
          <CartesianAxis />
          <XAxis
            dataKey="name"
            //     tickFormatter={xAsixFormatter}
            //  tick={{ fill: theme.colors.gray[600], fontSize: '12px' }}
            tickLine={false}
            axisLine={{
              stroke: theme.colors.gray[400],
              strokeWidth: 1,
            }}
            height={60}
            tickSize={12}
            interval={validShowXaxisArr.length <= 8 ? 0 : 'preserveEnd'}
            tick={<CustomizedTick />}
          />

          <YAxis
            label={{
              value: '거래량',
              fill: theme.colors.gray[600],
              fontSize: '12px',
              position: 'insideRight',
            }}
            // hide={true}
            // tickFormatter={thickFormatter}
            tick={false}
            tickLine={false}
            axisLine={false}
            tickCount={1}
            padding={{ top: 10 }}
          />

          <Bar
            dataKey="saleCount"
            fill={dataColors['sale']}
            isAnimationActive={false}
            stackId="bar"
          />
          <Bar
            dataKey="charterCount"
            fill={dataColors['charter']}
            isAnimationActive={false}
            stackId="bar"
          />

          <Tooltip
            position={{ y: 0 }}
            isAnimationActive={false}
            cursor={{
              stroke: theme.colors.primary[400],
              strokeWidth: 1,
            }}
            formatter={tooltipFormatter}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: theme.colors.gray[200],
              borderRadius: '8px',
              boxShadow: theme.shadow['card-2'],
              padding: '0.5rem 1rem',
            }}
            content={<SummaryAptGraphCountTooltip />}
          />
        </ComposedChart>
      </ResponsiveContainer>

      <Divider />
    </>
  )
}

const Divider = styled.div`
  margin-left: 1.25rem;
  border-bottom: 1px solid ${theme.colors.gray[100]};
  /* margin-bottom: 2rem; */
`

export default SummaryAptGraph
