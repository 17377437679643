import { ReactComponent as AptIcon } from 'assets/icon/note/filter/apt.svg'
import { ReactComponent as FactoryIcon } from 'assets/icon/note/filter/factory.svg'
import { ReactComponent as HouseIcon } from 'assets/icon/note/filter/house.svg'
import { ReactComponent as LandIcon } from 'assets/icon/note/filter/land.svg'
import { ReactComponent as OfficetelIcon } from 'assets/icon/note/filter/officetel.svg'
import { ReactComponent as ParcelIcon } from 'assets/icon/note/filter/parcel.svg'
import { ReactComponent as StoreIcon } from 'assets/icon/note/filter/store.svg'
import { ReactComponent as VillaIcon } from 'assets/icon/note/filter/villa.svg'
import { ReactComponent as StorageIcon } from 'assets/icon/note/filter/storage.svg'
import { ReactComponent as ExtraIcon } from 'assets/icon/note/filter/extra.svg'

const propertyList = [
  {
    label: '아파트',
    icon: <AptIcon />,
  },
  {
    label: '오피스텔',
    icon: <OfficetelIcon />,
  },
  {
    label: '분양권',
    icon: <ParcelIcon />,
  },
  {
    label: '빌라/다가구',
    icon: <VillaIcon />,
  },
  {
    label: '상가/사무실',
    icon: <StoreIcon />,
  },
  {
    label: '단독',
    icon: <HouseIcon />,
  },
  {
    label: '공장',
    icon: <FactoryIcon />,
  },
  {
    label: '창고',
    icon: <StorageIcon />,
  },
  {
    label: '토지',
    icon: <LandIcon />,
  },
  {
    label: '기타',
    icon: <ExtraIcon />,
    border:'true'
  },
]

export const propertyIconList = [
  {
    label: '아파트',
    icon: <AptIcon />,
  },
  {
    label: '오피스텔',
    icon: <OfficetelIcon />,
  },
  {
    label: '분양권',
    icon: <ParcelIcon />,
  },
  {
    label: '다가구주택',
    icon: <VillaIcon />,
  },
  {
    label: '상가',
    icon: <StoreIcon />,
  },
  {
    label: '상가건물',
    icon: <StoreIcon />,
  },
  {
    label: '사무실',
    icon: <StoreIcon />,
  },
  {
    label: '단독주택',
    icon: <HouseIcon />,
  },
  {
    label: '공장',
    icon: <StorageIcon />,
  },
  {
    label: '창고',
    icon: <FactoryIcon />,
  },
  {
    label: '토지',
    icon: <LandIcon />,
  },
  {
    label: '원룸',
    icon: <VillaIcon />,
  },
  {
    label: '투룸',
    icon: <VillaIcon />,
  },
  {
    label: '쓰리룸+',
    icon: <VillaIcon />,
  },
]

export default propertyList
