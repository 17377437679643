import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import theme, { media } from 'lib/styles/theme'
import { setNoteListSearchParams } from 'modules/notev2'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const gubunList = ['매매', '전세', '월세']

const priceList = [
  '매매가 높은순',
  '매매가 낮은순',
  '전세 높은순',
  '전세 낮은순',
  '월세 높은순',
  '월세 낮은순',
]
const areaList = [
  '연면적 높은순',
  '연면적 낮은순',
  '공급면적 높은순',
  '공급면적 낮은순',
  '전용면적 높은순',
  '전용면적 낮은순',
]

const orderTypes = ['asc', 'desc']

const StylingHeaderComponent = ({
  top,
  bottom,
  sortable,
  name,
  orderWay,
}) => {
  const { sort } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )
  const dispatch = useDispatch()

  const [orderType, setOrderType] = useState('')
  const [selectList, setSelectList] = useState([])
  const [isVisibleSelect, setIsVisibleSelect] = useState(false)

  const selectRef = useRef()
  useOnClickOutside(selectRef, () => setIsVisibleSelect(false))

  useEffect(() => {
    if (orderWay == 'select') {
      if (name == '가격') {
        setSelectList(priceList)
      } else if (name == '면적') {
        setSelectList(areaList)
      } else if (name == '구분') {
        setSelectList(gubunList)
      }
    }
  }, [])

  useEffect(() => {
    if (sort.index != name) setOrderType('')
  }, [sort])

  const onClickSort = () => {
    let type
    const currIdx = orderTypes.findIndex((value) => value == orderType)

    if (currIdx == 1) {
      type = orderTypes[0]
      setOrderType(orderTypes[0])
    } else {
      type = orderTypes[currIdx + 1]
      setOrderType(orderTypes[currIdx + 1])
    }

    dispatch(
      setNoteListSearchParams({
        sort: {
          index: name,
          type: type,
        },
      }),
    )
  }

  /**
   * 그리드 헤더 스타일 overflow를 커스텀 할 수 없어서 스크립트로 강제 포지셔닝 함
   * @param {*} e
   */
  const onClickOpenSelect = useCallback(
    (e) => {
      if (!selectRef || !selectRef?.current) return
      selectRef.current.style.left = `${e.clientX}px`
      selectRef.current.style.top = `${e.clientY}px`
      setIsVisibleSelect(true)
    },
    [selectRef?.current],
  )

  const onClickSelectSort = (e, type) => {
    e.stopPropagation()
    setIsVisibleSelect(false)
    setOrderType(type)
    dispatch(
      setNoteListSearchParams({
        sort: {
          index: name,
          type: type,
        },
      }),
    )
  }

  return (
    <StyledColumn
      onClick={orderWay == 'simple' ? onClickSort : onClickOpenSelect}
    >
      <HeaderWrapper name={name}>
        <TitleWrapper>
          {top}
          <br />
          <span>{bottom}</span>
        </TitleWrapper>
        {sortable &&
          (orderWay == 'select' ? (
            orderType ? (
              orderType.includes('높은순') ? (
                <i className="fas fa-caret-down"></i>
              ) : (
                <i className="fas fa-caret-up"></i>
              )
            ) : (
              <i className="fas fa-sort"></i>
            )
          ) : orderType ? (
            orderType == 'asc' ? (
              <i className="fas fa-caret-up"></i>
            ) : (
              <i className="fas fa-caret-down"></i>
            )
          ) : (
            <i className="fas fa-sort"></i>
          ))}
      </HeaderWrapper>
      {orderWay == 'select' && selectList.length > 0 && (
        <List ref={selectRef} visible={isVisibleSelect}>
          {selectList.map((item, i) => {
            return (
              <ListItem
                key={`sort-${name}${i}`}
                onClick={(e) => onClickSelectSort(e, item)}
              >
                {item}
              </ListItem>
            )
          })}
        </List>
      )}
    </StyledColumn>
  )
}

const StyledColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: visible;
  width: 100%;
  cursor: pointer;

  span {
    color: ${theme.colors.gray[600]};
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
  }

  i {
    color: ${theme.colors.gray[400]};
    margin-left: 4px;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  ${media.mediumS`
  ${({ name }) => name == '주소' && `margin-left: 30px;`}
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const List = styled.div`
  display: none;
  flex-direction: column;
  position: fixed;
  z-index: 101;
  left: 65%;
  top: 0;
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['card-2']};
  font-size: 12px;
  ${({ visible }) => visible && `display: flex;`}
`

const ListItem = styled.div`
  padding: 8px 12px;

  &:hover {
    background-color: ${theme.colors.blue[50]};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray[200]};
  }
`

export default StylingHeaderComponent
