import { useContext, useEffect } from 'react'
import MapContext from 'contexts/map'
import {
  funcCookieClickPosition,
  funcMakePolygon,
  funcSetActionsClickposition,
  getAddressName,
  setBuildingPolygons,
} from 'lib/utils/index'

const MapPolygon = () => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map } = state

  /**
   * 클릭 감지를 일시적으로 중단하기 위한 함수
   */
  ;(() => {
    let clickDisabled = false
    kakao.maps.event.isClickDisabled = () => {
      return clickDisabled
    }
    kakao.maps.event.disableClick = () => {
      clickDisabled = true
      setTimeout(() => {
        clickDisabled = false
      }, 1000)
    }
  })()

  /**
   * 지도에 다격형 표시 및 다격형 관련 이벤트 설정
   */
  useEffect(() => {
    const { polygons, buildingPolygons } = state
    // 토지 폴리곤 지도 위에 표시
    if (polygons.length === 0) return

    // 지도에 다각형을 표시합니다
    polygons?.map((polygon) => {
      polygon.setMap(map)
    })

    // 빌딩 폴리곤들 지도 위에 표시
    buildingPolygons.map((buildingPolygon) => {
      buildingPolygon.setMap(map)
      return true
    })

    // 폴리곤 초기화
    return () => {
      if (polygons.length === 0) return

      polygons.map((polygon) => {
        polygon.setMap(null)
      })
      buildingPolygons.map((buildingPolygon) => {
        buildingPolygon.setMap(null)
        return true
      })
    }
  }, [state.polygons, state.buildingPolygons])

  return <></>
}

/**
 * 클릭한 지점의 토지 정보 세팅하기
 * - 대구 등의 지역은 카카오 폴리곤 세팅
 * - ContextAPI 설정 및 history 설정
 *
 * @param {*} response 클릭한 지점의 address 정보
 * @param {*} kakao
 * @param {*} actions
 */
export const setAddressPolygon = (
  response,
  kakao,
  state,
  actions,
  setCookie,
) => {
  const result = response?.data?.result || response.result

  // 대구 등 어긋나는 토지 폴리곤 체크
  // if (
  //   result.id.startsWith('27') ||
  //   result.id.startsWith('28710') ||
  //   result.id.startsWith('47230') ||
  //   result.id.startsWith('47940')
  // ) {
  //   // 토지 centerpoint의 위도경도를 카카오맵 좌표로 변환 (클릭위치 아님, getAddress에서 반환한 결과 사용해야)
  //   const coords = new kakao.maps.LatLng(
  //     result.centerpoint.coordinates[0],
  //     result.centerpoint.coordinates[1],
  //   ).toCoords()
  //   // console.log('coords: ', coords)

  //   // 쿼리스트링
  //   const querystring = require('query-string')
  //   let params = querystring.stringify({
  //     inputCoordSystem: 'WCONGNAMUL',
  //     outputCoordSystem: 'WCONGNAMUL',
  //     x: coords.getX(),
  //     y: coords.getY(),
  //     output: 'JSON',
  //   })

  // kakao.API?.request({
  //   url: `https://map.kakao.com/etc/areaAddressInfo.json?${params}`,
  //   success: function (response) {
  //     console.log(response)
  //   },
  //   fail: function (error) {
  //     console.log(error)
  //   },
  // })

  // 변환한 좌표를 사용하여 JSONP 요청 실행
  //   const jsonp = require('jsonp')
  //   jsonp(
  //     `https://map.kakao.com/etc/areaAddressInfo.json?${params}`,
  //     null,
  //     (err, data) => {
  //       if (err) {
  //         console.error(err.message)
  //       } else {
  //         // 돌아온 Polygon을 지도에 뿌려준다.
  //         // 카카오맵 좌표로 나오는데, 그대로 쓸 수 있으면 그대로 쓰고 아니면 위도경도로 바꿔서 써도 된다.
  //         console.log(data)

  //         if (data.shape !== '') {
  //           const address = getAddressName(result)
  //           const centerpoint = new kakao.maps.Coords(
  //             data.x,
  //             data.y,
  //           ).toLatLng()
  //           // 클릭한 지점 Context API에 셋팅
  //           actions.setClickPosition({
  //             address_id: result.id,
  //             address_name: address,
  //             land_type_code: result.land_type_code,
  //             land_type: result.land_type,
  //             land_area: result.land_area,
  //             land_price: result.land_price,
  //             unit_price: result.unit_price,
  //             multiplier: result.multiplier,
  //             data_date: result.data_date,
  //             centerpoint: [centerpoint.getLat(), centerpoint.getLng()],
  //             shape: data.shape.coordinates,
  //             e_land_price: result.e_land_price,
  //             e_building_price: result.building_price,
  //             dcr: result.dcr,
  //             version: result.version,
  //             land_ratio: result.land_ratio,
  //           })

  //           // 토지 폴리곤 세팅
  //           const landPolygons = funcMakePolygon(
  //             { ...result, ...data },
  //             kakao,
  //             true,
  //           )
  //           actions.setPolygons(landPolygons)

  //           // 건물 폴리곤은 우리 DB에 있는 거 표시
  //           setBuildingPolygons(result, kakao, actions)

  //           if (setCookie) {
  //             // 쿠키 설정
  //             funcCookieClickPosition(
  //               setCookie,
  //               result,
  //               address,
  //               state.viewState.level,
  //             )
  //           }
  //         }
  //       }
  //     },
  //   )
  // } else {
  const address = getAddressName(result)

  // Context API에 셋팅
  funcSetActionsClickposition(result, address, actions)

  if (setCookie) {
    // 쿠키 설정
    funcCookieClickPosition(
      setCookie,
      result,
      address,
      state.viewState.level,
    )
  }

  // 토지 폴리곤 세팅
  const landPolygons = funcMakePolygon(result, kakao)
  actions.setPolygons(landPolygons)

  // 부속 토지 다각형을 생성합니다
  result.sub_addresses?.map((sub) => {
    sub.shape?.coordinates?.map((part) => {
      return part.map((point) => {
        return new kakao.maps.Coords(point[0], point[1]).toLatLng()
      })
    })
  })

  // 건물 폴리곤 배열 셋팅
  setBuildingPolygons(result, kakao, actions)
  // }
}

export default MapPolygon
