import axios from 'axios'

/**
 * 네이버 매물 전송 버튼 클릭시 모달창 띄우기 전 최종 검증 + 결제금액 확인

 */
export const preregisterAd = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/preregister',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
   * 네이버 매물 전송 버튼 클릭시 모달창 띄우기 후
  | amount_verification | int | 검증 비용
   */
export const registerAd = async (
  { _rx_csrf_token, ad_srl, amount_verification, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/register',
    {
      _rx_csrf_token,
      ad_srl,
      amount_verification,
      plan_srl,
    },
    options,
  )

/**
 * 매물 재검증 요청
 */
export const nretryVerification = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/naver/retry',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
 * 매물 재광고(검증최종실패, 광고종료)
 */
export const recycleListing = async (
  {
    _rx_csrf_token,
    ad_srl,
    plan_srl,
    amount_verification,
    point_balance,
    total_price,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/recycle',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
      amount_verification,
      point_balance,
      total_price,
    },
    options,
  )

/**
 * 매물 검증 중 등록취소 요청 (검증취소)
 */
export const ncancelVerification = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/naver/cancel',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
 * 매물정보 수정 요청
 */
export const nupdateListing = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/naver/update',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
 * 대표 - 승인거절처리
 * @param ad_srl
 */
export const rejectApproval = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/rejectApproval',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
 * 직원 - 승인요청
 * @param ad_srl
 */
export const approvalRequest = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/approvalRequest',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
 * 대표 - 재전송하기(다시 광고 등록)
 * @param ad_srl
 */
export const reregisterListing = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/reregister',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
 * 노출종료 요청
 * '광고중', '종료예정', '신고매물', '동일주소매물' 상태일 때만 가능
 * @param ad_srl
 * @param type 거래완료, 노출종료
 */
export const endAdListing = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/end',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )

/**
 * 거래완료 요청
 * '광고중', '종료예정', '신고매물', '동일주소매물' 상태일 때만 가능
 * @param ad_srl
 * @param type 거래완료, 정상매물
 */
export const completeAdListing = async (
  { _rx_csrf_token, ad_srl, type, plan_srl, price1, price2, date },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/complete',
    {
      _rx_csrf_token,
      ad_srl,
      type,
      plan_srl,
      price1,
      price2,
      date,
    },
    options,
  )

/**
 * 광고 삭제
 * @param ad_srl
 */
export const deleteAd = async (
  { _rx_csrf_token, ad_srl, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/delete',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
    },
    options,
  )
