import theme from 'lib/styles/theme'
import styled from 'styled-components'

const StyledIcon = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2rem;

  color: white;
  font-size: 1.25rem;
  font-weight: 700;
  background-color: ${theme.colors.primary[700]};

  border-radius: 100%; */

  color: ${theme.colors.primary[400]};
  font-size: 1.75rem;
`

const SettingsExcelIcon = () => {
  return (
    <StyledIcon>
      <i className="fad fa-file-excel"></i>
    </StyledIcon>
  )
}

export default SettingsExcelIcon
