import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mapVisible: true,
  mapClickDisabled: false,
  openFilterType: null,
  isMapError: false,
  mapMode: 'rp',
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapVisible(state, action) {
      state.mapVisible = action.payload
    },
    setMapClickDisabled(state, action) {
      state.mapClickDisabled = action.payload
    },
    setOpenFilterType(state, action) {
      state.openFilterType = action.payload
    },
    setIsMapError(state, actions) {
      state.isMapError = actions.payload
    },
    setMapMode(state, actions) {
      state.mapMode = actions.payload
    },
  },
})

export const {
  setMapVisible,
  setMapClickDisabled,
  setOpenFilterType,
  setIsMapError,
  setMapMode,
} = mapSlice.actions

export default mapSlice.reducer
