import { Card } from 'components/design'
import { CardHeader } from 'components/design'
import { CardBody } from 'components/design'
import { CardTitle } from 'components/design'
import SummaryBannerContainer from 'containers/summary/SummaryBannerContainer'
import SummaryProjects from 'components/summary/SummaryProjects/SummaryProjects'
import SummaryProjectButton from 'components/summary/SummaryProjects/SummaryProjectButton'
import { useSelector } from 'react-redux'

/**
 * 추천 건축모델
 *
 * * 렌더링 여부
 * * 공동주택: X
 * * 공동주택 아님 : O
 */
const SummaryProjectContainer = () => {
  const isApt = useSelector((state) => state.summary.isApt)
  return (
    !isApt && (
      <Card>
        <CardHeader>
          <CardTitle>추천 건축모델</CardTitle>
        </CardHeader>
        <CardBody>
          <SummaryProjects />
          <SummaryProjectButton />
          <SummaryBannerContainer />
        </CardBody>
      </Card>
    )
  )
}

export default SummaryProjectContainer
