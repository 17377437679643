import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 건물정보 공동 주택 아닌 경우 (n년, n세대 등..)
 * @param {*} param0
 * @returns
 */
const SummaryBuildingInfo = () => {
  const { building } = useSelector((state) => state.summary.summaryInfo)

  const data = building?.result
  const dongCnt = building?.dongCnt
  const household = building?.household

  return (
    <>
      {data && (
        <HeadWrap>
          <Head>
            <HeadTitle>
              <span>
                {data[0]?.building_name || data[0]?.purpose_code_name}
              </span>
            </HeadTitle>

            <HeadSub>
              <span className="small">
                {data[0]?.approval_date
                  ? `${data[0]?.approval_date?.substring(0, 4)}년`
                  : ''}
              </span>
              {dongCnt ? (
                <>
                  {dongCnt > 1 && (
                    <span className="cnt">&nbsp;· 총 {dongCnt}동</span>
                  )}
                  {dongCnt <= 1 && data[0]?.floor_count && (
                    <span className="cnt">
                      &nbsp;· {data[0]?.floor_count}층
                    </span>
                  )}
                </>
              ) : null}
              {household && household > 1 ? (
                <span className="cnt">
                  &nbsp;· {numberWithCommas(household)}
                  세대
                </span>
              ) : null}
            </HeadSub>
          </Head>
        </HeadWrap>
      )}
    </>
  )
}

const Head = styled.div`
  flex: 1;
`

const HeadWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`

const HeadTitle = styled.div`
  display: flex;
  align-items: center;

  color: ${theme.colors.gray[900]};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.8;

  & > img {
    margin-right: 0.25rem;
  }
`

const HeadSub = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
`

export default SummaryBuildingInfo
