import { Card } from 'components/design'
import { CardBody } from 'components/design'
import { Modal } from 'components/design'
import { Tag } from 'components/design'
import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { useState } from 'react'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { billionFormate, numberWithCommas } from 'utils'

const SummrayEstimatePrice = ({ prices, subAddresses }) => {
  const { state } = useContext(MapContext)

  const [visible, setVisible] = useState(false)

  const landArea = prices?.estimate_price?.land_area || null

  if (!prices?.estimate_price?.estimate_price) return null

  return (
    <>
      <Card noMargin>
        <CardBody>
          <Wrapper>
            <Label>
              <LabelTitle>추정가</LabelTitle>
              <StyledSpan>
                {prices.estimate_price?.estimate_type ===
                'land+building'
                  ? '(토지+건물)'
                  : '(토지)'}
              </StyledSpan>
              <HelpIcon onClick={() => setVisible(true)}>
                <i className="fas fa-info-circle" />
              </HelpIcon>
            </Label>

            <Subtitle>
              {subAddresses.length > 0 && subAddresses.length < 10
                ? subAddresses.length +
                  1 +
                  '개의 필지가 포함된 가격이에요'
                : ''}
            </Subtitle>

            <PriceWrap>
              약{' '}
              <strong>
                {billionFormate(
                  prices.estimate_price.estimate_price * 10000,
                )}
                {'원'}
              </strong>
            </PriceWrap>

            <TagWrap>
              <Tag color="primary" size="large" circle>
                {state.isSquareMeter
                  ? '㎡당 ' +
                    numberWithCommas(
                      billionFormate(
                        Math.round(
                          (prices.estimate_price.estimate_price *
                            10000) /
                            (landArea || 1),
                        ),
                      ).replace('원', ''),
                    ) +
                    '원'
                  : '평당 ' +
                    numberWithCommas(
                      billionFormate(
                        Math.round(
                          ((prices.estimate_price.estimate_price *
                            10000) /
                            (landArea || 1)) *
                            3.305,
                        ),
                      ).replace('원', ''),
                    ) +
                    '원'}
              </Tag>
            </TagWrap>
          </Wrapper>
        </CardBody>
        <Divider />
      </Card>

      <Modal
        title="JOOTEK 추정가"
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        okText="확인"
        cancelText="취소"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        <div>
          JOOTEK 추정가는 인근 공시지가 및 실거래가, 건물 규모 및 예상
          건축비용, 최근 부동산 시세, 규제 정보 등의 빅데이터를 JOOTEK
          고유의 방법으로 분석하여 산출한 것입니다.
          <br />
          <br />
          JOOTEK 추정가는 정식 감정평가액이 아니며, 급변하는 시장 상황
          및 매수자, 매도자의 조건에 따라 실제 거래가격과 다를 수
          있습니다. 참고용으로 제공되는 정보이므로 이를 이용함으로
          발생하는 어떤 손실에 대해서도 (주)주택은 책임이 없습니다.
          <br />
          <br />
          현저하게 잘못된 데이터를 발견하시면 (주)주택으로 알려주시기
          바랍니다. 소중한 피드백은 더 나은 추정가를 산출하는 데
          활용하겠습니다.
        </div>
      </Modal>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  /* padding-bottom: 0.5rem; */
  /* margin-bottom: 1rem; */
`

const Label = styled.div`
  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.8;
  margin-bottom: 0.25rem;
`

const LabelTitle = styled.span`
  font-size: 1.25rem;
  margin-right: 3px;
`

const Subtitle = styled.div`
  color: ${theme.colors.gray[500]};
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const StyledSpan = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  color: ${theme.colors.gray[500]};
`

const PriceWrap = styled.div`
  color: ${theme.colors.primary[500]};
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0.375rem;

  & > strong {
    /* color: ${theme.colors.primary[400]}; */
    font-weight: 700;
  }
`

const Sub = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
`

const HelpIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 0;
  display: flex;
  align-items: center;

  color: ${theme.colors.gray[500]};
  font-size: 1rem;

  & > i {
    color: ${theme.colors.gray[400]};
    margin-left: 0.25rem;
  }
`

const TagWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const Divider = styled.div`
  margin-left: 1.25rem;
  padding-bottom: 0.25rem;
  border-top: 1px solid ${theme.colors.gray[100]};
`

export default SummrayEstimatePrice
