import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import SummaryJeonyoubuContainer from './SummaryJeonyoubuContainer'
import SummaryJeonyoubuDongho from 'components/summary/SummaryJeonyoubu/SummaryJeonyoubuDongho'
import SummaryMultiHousingAptContainer from './SummaryMultiHousingAptContainer'
import SummaryMultiHousingContainer from './SummaryMultiHousingContainer'
import { getCustomPurpose } from 'lib/utils'

/** 
 * 전유부

 * * 렌더링 여부
 * * 공동주택: O
 * * 공동주택 아님 : X
 */
const SummaryJeonyouAndMultiHousingContainer = ({}, ref) => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const [dongList, setDongList] = useState([])
  const [hoList, sethoList] = useState([])
  const [selectedDong, setSelectedDong] = useState('')
  const [selectedHo, setSelectedHo] = useState('')
  const { apt: aptTabRef } = ref

  /**
   * 호 목록이 있는지 여부
   */
  const isHoEmpty = useMemo(() => {
    return hoList.length == 0
  }, [hoList])

  useEffect(() => {
    if (!summaryInfo?.dongho_list) {
      setDongList([])
      sethoList([])
      return
    }

    //동 목록 바인딩
    setDongList(Object.keys(summaryInfo?.dongho_list))
  }, [])

  useEffect(() => {
    if (dongList.length == 0) return

    //동 목록에서 첫 번 째 것 선택
    setSelectedDong(dongList[0])
  }, [dongList])

  //동 선택했을 때
  useEffect(() => {
    if (dongList.length == 0) return
    const dong = selectedDong == '구분없음' ? '' : selectedDong
    const hos = summaryInfo?.dongho_list[dong]

    if (hos && hos.length > 0) {
      sethoList(hos)
    } else {
      sethoList(['동을 먼저 선택해주세요'])
    }
  }, [selectedDong])

  useEffect(() => {
    if (isHoEmpty) return
    //호 목록에서 첫 번 째 것 선택
    setSelectedHo(hoList[0])
  }, [hoList])

  /**
   * 동 click 이벤트
   * @param {*} value
   */
  const onClickDong = (value) => {
    setSelectedDong(value)
  }

  /**
   * 호 click 이벤트
   * @param {string} value
   * @returns
   */
  const onClickHo = (value, callbackFn) => {
    if (value == '동을 먼저 선택해주세요') return

    setSelectedHo(value)
    callbackFn && callbackFn()
  }

  const isApt = useSelector((state) => state.summary.isApt) // 공동 주택 여부
  return (
    <div ref={aptTabRef}>
      <SummaryJeonyoubuContainer
        dongList={dongList}
        hoList={hoList}
        onClickHo={onClickHo}
        onClickDong={onClickDong}
        selectedHo={selectedHo}
        selectedDong={selectedDong}
        isHoEmpty={isHoEmpty}
      />
      {isApt ? (
        <SummaryMultiHousingAptContainer
          selectedDong={selectedDong}
          selectedHo={selectedHo}
          dongHos={
            dongList.length > 0 && (
              <SummaryJeonyoubuDongho
                dongList={dongList}
                hoList={hoList}
                selectedDong={selectedDong}
                selectedHo={selectedHo}
                onClickDong={onClickDong}
                onClickHo={onClickHo}
              />
            )
          }
        />
      ) : (
        getCustomPurpose(summaryInfo?.building) && (
          <SummaryMultiHousingContainer />
        )
      )}
    </div>
  )
}

export default forwardRef(SummaryJeonyouAndMultiHousingContainer)
