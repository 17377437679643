import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import React from 'react'
import { options } from '../../options'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 매물 소유자 구분
 */
const NoteAdColumnVerificationOwner = ({ required, isDisabled }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  const list = options['매물 소유자']

  return (
    <ColumnSet>
      <ColumnTitle required={required}>매물 소유자 구분</ColumnTitle>
      <ColumnVal gap={12}>
        {Object.entries(list).map(([key, value], i) => {
          return (
            <React.Fragment key={i}>
              <RadioButton
                size="small"
                fit
                label={value}
                {...register('verification.ownerType')}
                value={key}
                onChange={(e) => {
                  onUpdate('verification.ownerType', e.target.value)
                }}
                name="verification_owner_type"
                disabled={isDisabled}
              />
            </React.Fragment>
          )
        })}

        <ValidateWarn>
          {errors?.verification?.ownerType?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnVerificationOwner
