import { useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { modifyAccount } from 'lib/api/notev2/account'
import useAxios from 'lib/hooks/useAxios'

/**
 * 계정관리 - 공제증서
 * @returns
 */
const NoteAccountCertificate = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const inputRef2 = useRef()

  const [onRequestModify, , dataModify, errorModify] =
    useAxios(modifyAccount)

  // 회원정보 폼
  const { setValue } = useFormContext()

  const onChangeFileInput = (e) => {
    //setValue('realtor_certificate', e.target.files)

    const formData = new FormData()
    formData.append('realtor_certificate', e.target.files?.[0])

    onRequestModify(Object.fromEntries(formData))
  }

  return (
    <>
      {loginInfo?.realtor_certificate &&
        loginInfo?.realtor_certificate['src'] && (
          <File
            href={loginInfo?.realtor_certificate['src']}
            target="_blank"
          >
            {loginInfo?.realtor_certificate['name'] || '업로드한 파일'}
          </File>
        )}
      {/* <Button
                        onclick={onClickUpload2}
                        color="blue"
                        plain
                      >
                        공제증서 업로드
                      </Button> */}
      <InputFileWrap>
        <input
          ref={inputRef2}
          type="file"
          name="realtor_certificate"
          // style={{ display: 'none' }}
          onChange={onChangeFileInput}
        />
      </InputFileWrap>
    </>
  )
}

const InputFileWrap = styled.div`
  display: flex;
  width: 100%;
`

const File = styled.a`
  margin-right: 10px;
  flex-shrink: 0;
  width: fit-content;
  text-decoration: underline;
`

export default NoteAccountCertificate
