import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedChannelInfo } from 'modules/channel'

import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useChannels from './hooks/useChannels'
import useChannelInfo from './hooks/useChannelInfo'

import Axios from 'axios'

const ChannelContainer = ({ children }) => {
  const { channelSrl, folderSrl } = useNoteMatch()
  const dispatch = useDispatch()
  // 새로운 요청이 발생하면 이전에 발생한 모든 요청을 취소
  const source = Axios.CancelToken.source()

  const { _getChannels, channels } = useChannels()
  const { _getChannel } = useChannelInfo()

  /**
   * @description 매물노트 최초 접속 시 채널 목록을 새롭게 갱신하도록 함.
   * (채널 초대를 수락한 이후 채널목록을 반영하기 위함)
   */
  useEffect(() => {
    _getChannels(source.token)
    return () => {
      source.cancel()
    }
  }, [])

  /**
   * @description 채널 권한 검증을 위한 채널 정보 불러오기
   */
  useEffect(() => {
    if (!channelSrl || channelSrl === 'undefined') return
    _getChannel({ channelSrl }, source.token)
    return () => {
      source.cancel()
    }
  }, [channelSrl])

  /**
   * @description 선택된 채널 정보를 찾음
   */
  useEffect(() => {
    const findChannelInfo = channels?.find(
      (e) => channelSrl === e?.channel_srl?.toString(),
    )

    if (!findChannelInfo) return
    dispatch(setSelectedChannelInfo(findChannelInfo))
  }, [channelSrl, channels])

  // if (loading)
  //   return (
  //     <Container white>
  //       <Card noMargin>
  //         <CardBody>
  //           <Skeleton />
  //         </CardBody>
  //       </Card>
  //     </Container>
  //   )

  // if (!channelInfo)
  //   return <ErrorView error="채널 정보를 찾을 수 없습니다." />

  return children({ channelSrl, folderSrl })
}

export default ChannelContainer
