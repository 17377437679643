import axios from 'axios'
import { API_HOST } from '../../Constants'
import theme from 'lib/styles/theme'
import { buildingPurposeShortcuts } from 'utils'

/**
 * 지번 주소 포맷
 * @param {*} result
 * @returns
 */
export const getAddressName = (
  result,
  roadName,
  exceptSigungu = false,
) => {
  let address = ''
  if (!result) return ''

  if (!hasLandAddress(result.num1, result.num2))
    return `${result.sido ? formatSido(result.sido) : ''} ${roadName}`

  address = exceptSigungu
    ? `${result.eupmyeondong} ${result.ri} ${
        2 === result.is_mountain ? '산' : ''
      }${result.num1 > 0 ? result.num1 : ''}${
        result.num2 ? `-${result.num2}` : ''
      }`
    : `${result.sido ? formatSido(result.sido) : ''} ${
        result.sigungu ? result.sigungu : ''
      } ${result.ilbangu ? result.ilbangu : ''} ${
        result.eupmyeondong
      } ${result.ri} ${2 === result.is_mountain ? '산' : ''}${
        result.num1 > 0 ? result.num1 : ''
      }${result.num2 ? `-${result.num2}` : ''}`

  return address
}

export const hasLandAddress = (num1, num2) => {
  const sum = num1 + num2
  return Number.isInteger(sum) && sum > 0
}

export const getPrimaryBuilding = (buildingInfo) => {
  let largest_building = null
  buildingInfo?.result?.map((item) => {
    if (
      !largest_building ||
      parseInt(item.total_floor_area) >
        parseInt(largest_building.total_floor_area)
    ) {
      largest_building = item
    }
  })

  return largest_building
}

export const getPropertyTypeName = (buildingInfo) => {
  const primary_building = getPrimaryBuilding(buildingInfo)

  return primary_building
    ? typeof buildingPurposeShortcuts[
        primary_building.purpose_code_name
      ] !== 'undefined'
      ? buildingPurposeShortcuts[primary_building.purpose_code_name]
      : primary_building.purpose_code_name
    : null
}

/**
 * 건물 타입 리턴
 * @param {*} building
 * @returns
 */
export const getCustomPurpose = (building) => {
  if (!building?.result || building?.result.length == 0) return ''

  const purpose = building?.result?.[0]?.custom_purpose

  return typeof buildingPurposeShortcuts[purpose] !== 'undefined'
    ? buildingPurposeShortcuts[purpose]
    : purpose
}

/**
 * 지번 주소 간략화
 * - 시도와 일반구를 뺀다.
 * - 세종시는 시도를 빼지 않는다.
 * @param {String} address
 */
export const smplifyJibunName = (address, isHeader = false) => {
  if (!address || address.length === 0) return

  const splited = address.split(' ')

  // 첫 번째 요소가 시도가 아닌 경우 return
  if (!sidoList.includes(splited[0])) {
    return address
  }
  // 시도 빼기
  if (!isHeader && splited[0] === '세종') {
    return address
  }
  splited.reverse().pop()
  splited.reverse()

  // sigungu에서 '시'로 끝나는 주소 빼기
  if (splited[0].charAt(splited[0].length - 1) === '시') {
    splited.reverse().pop()
    splited.reverse()
  }

  // 일반구 빼기
  if (ilbanguList.includes(splited[0])) {
    splited.splice(1, 1)
  }
  return splited.join(' ')
}

/**
 * 마커용 지번 주소 간략화
 * @param {String} address
 */
export const smplifyJibunNameMarker = (address) => {
  const splited = address.split(' ')
  // 읍|면|동|로 index 추출
  const index = splited
    .map((val, i) => {
      if (
        val.endsWith('읍') ||
        val.endsWith('동') ||
        val.endsWith('가') ||
        val.endsWith('로') // ex) 서울 종로구 세종로 100
      ) {
        return i
      }
      // '면'인 경우
      if (val.endsWith('면')) {
        // '리'가 있으면 '리'부터 보여주고
        if (splited[i + 1].endsWith('리')) {
          return i + 1
        } else {
          // '리'가 없으면 '면'부터 보여주도록
          return i
        }
      }
    })
    .filter((val) => val)[0]

  if (splited[index]) {
    return splited.slice(index).join(' ')
  } else {
    return address
  }
}

/**
 * 도로명 주소 간략화
 * - 도로명 건물번호부터 표시
 * @param {String} address
 * @return {String}
 */
export const smplifyRoadName = (address) => {
  if (!address) {
    return address
  }
  const splited = address.split(' ')
  // 도로명 건물번호 index 추출
  const index = splited
    .map((val, i) => {
      if (val.endsWith('로') || val.endsWith('길')) {
        return i
      }
    })
    .filter((val) => val)[0]

  if (splited[index]) {
    return splited.slice(index).join(' ')
  } else {
    return address
  }
}

// 일반구가 있는 도시 이름 목록
export const ilbanguList = [
  '수원시',
  '고양시',
  '용인시',
  '창원시',
  '성남시',
  '청주시',
  '전주시',
  '천안시',
  '안산시',
  '안양시',
  '포항시',
]

export const formatSido = (sido) => {
  let result = ''
  switch (sido) {
    case '강원':
      result = '강원'
      break
    case '강원도':
      result = '강원'
      break
    case '경기':
      result = '경기'
      break
    case '경기도':
      result = '경기'
      break
    case '경남':
      result = '경남'
      break
    case '경북':
      result = '경북'
      break
    case '경상남도':
      result = '경남'
      break
    case '경상북도':
      result = '경북'
      break
    case '광주':
      result = '광주'
      break
    case '광주광역시':
      result = '광주'
      break
    case '대구':
      result = '대구'
      break
    case '대구광역시':
      result = '대구'
      break
    case '대구시':
      result = '대구'
      break
    case '대전':
      result = '대전'
      break
    case '대전광역시':
      result = '대전'
      break
    case '대전시':
      result = '대전'
      break
    case '부산':
      result = '부산'
      break
    case '부산광역시':
      result = '부산'
      break
    case '부산시':
      result = '부산'
      break
    case '서울':
      result = '서울'
      break
    case '서울시':
      result = '서울'
      break
    case '서울특별시':
      result = '서울'
      break
    case '세종':
      result = '세종'
      break
    case '세종시':
      result = '세종'
      break
    case '세종특별자치시':
      result = '세종'
      break
    case '울산':
      result = '울산'
      break
    case '울산광역시':
      result = '울산'
      break
    case '울산시':
      result = '울산'
      break
    case '인천':
      result = '인천'
      break
    case '인천광역시':
      result = '인천'
      break
    case '인천시':
      result = '인천'
      break
    case '전남':
      result = '전남'
      break
    case '전라남도':
      result = '전남'
      break
    case '전라북도':
      result = '전북'
      break
    case '전북':
      result = '전북'
      break
    case '제주':
      result = '제주'
      break
    case '제주시':
      result = '제주'
      break
    case '제주특별자치도':
      result = '제주'
      break
    case '충남':
      result = '충남'
      break
    case '충북':
      result = '충북'
      break
    case '충청남도':
      result = '충남'
      break
    case '충청북도':
      result = '충북'
      break
  }
  return result
}

export const sidoList = [
  '강원',
  '경기',
  '경남',
  '경북',
  '광주',
  '대구',
  '대전',
  '부산',
  '서울',
  '세종',
  '울산',
  '인천',
  '전남',
  '전북',
  '제주',
  '충남',
  '충북',
]

export const getAddress = (lat, lng) => {
  return axios.get(`${API_HOST}/map/api/getAddress`, {
    params: {
      latitude: lat,
      longitude: lng,
    },
  })
}

// click한 토지 정보를 MapContext에 셋팅
export const funcSetActionsClickposition = (
  result,
  address,
  actions,
) => {
  actions.setClickPosition({
    address_id: result.id,
    address_name: address,
    land_type_code: result.land_type_code,
    land_type: result.land_type,
    land_area: result.land_area,
    land_price: result.land_price,
    unit_price: result.unit_price,
    multiplier: result.multiplier,
    data_date: result.data_date,
    centerpoint: result.centerpoint.coordinates,
    e_land_price: result.e_land_price,
    e_building_price: result.building_price,
    dcr: result.dcr,
    version: result.version,
    land_ratio: result.land_ratio,
    estimate_price2: result.estimate_price2,
  })
}

// 쿠키 설정 - clickPosition
export const funcCookieClickPosition = (setCookie, result) => {
  setCookie(
    'clickPosition',
    {
      address_id: result.id,
      lat: result.centerpoint.coordinates[0],
      long: result.centerpoint.coordinates[1],
    },
    { path: '/' },
  )
}

// 토지 폴리곤을 생성
export const funcMakePolygon = (
  result,
  kakao,
  isKakao = false,
  strokeColor,
) => {
  // 부속토지를 포함할 토지 폴리곤 배열 선언
  const landPolygons = []

  if (
    result.shape?.coordinates &&
    result.shape?.type !== 'MultiPolygon'
  ) {
    const color = strokeColor
      ? strokeColor
      : theme.colors.polygonStrokeColor
    // 주 토지 폴리곤 세팅
    const polygonPath = result.shape.coordinates.map((part) => {
      return part.map((point) => {
        if (!isKakao) {
          return new kakao.maps.LatLng(point[0], point[1])
        } else {
          return new kakao.maps.Coords(point[0], point[1]).toLatLng()
        }
      })
    })
    const landPolygon = new kakao.maps.Polygon({
      path: polygonPath, // 그려질 다각형의 좌표 배열입니다
      strokeWeight: 3, // 선의 두께입니다
      strokeColor: color, // 선의 색깔입니다
      strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      strokeStyle: 'dashed', // 선의 스타일입니다
      fillColor: color, // 채우기 색깔입니다
      fillOpacity: 0.3, // 채우기 불투명도 입니다
      zIndex: 2,
    })

    landPolygons.push(landPolygon)
  }

  // 멀티폴리곤인 경우
  if (result.shape?.type === 'MultiPolygon') {
    const shpae_points = result.shape.coordinates[0]

    const polygonPath = shpae_points.map((part) => {
      return part.map((point) => {
        return new kakao.maps.LatLng(point[0], point[1])
      })
    })

    // 지도에 표시할 다각형을 생성합니다
    const polygon = new kakao.maps.Polygon({
      path: polygonPath, // 그려질 다각형의 좌표 배열입니다
      strokeWeight: 3, // 선의 두께입니다
      strokeColor: theme.colors.polygonStrokeColor, // 선의 색깔입니다
      strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      strokeStyle: 'dashed', // 선의 스타일입니다
      fillColor: theme.colors.polygonStrokeColor, // 채우기 색깔입니다
      fillOpacity: 0.3, // 채우기 불투명도 입니다
      zIndex: 2,
    })

    // 하나씩 생성된 건물 폴리곤을 건물 폴리곤 배열에 넣기
    landPolygons.push(polygon)
  }

  // 부속지번
  if (result.sub_addresses) {
    // 부속 토지 폴리곤 세팅
    const subPolygonShapes = [...result.sub_addresses]

    subPolygonShapes.map((poly) => {
      const shpae_points = poly.shape.coordinates

      const polygonPath = shpae_points.map((part) => {
        return part.map((point) => {
          return new kakao.maps.LatLng(point[0], point[1])
        })
      })

      // 지도에 표시할 다각형을 생성합니다
      const subPolygon = new kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 3, // 선의 두께입니다
        strokeColor: 'pink', // 선의 색깔입니다
        strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'dashed', // 선의 스타일입니다
        fillColor: 'pink', // 채우기 색깔입니다
        fillOpacity: 0.3, // 채우기 불투명도 입니다
        zIndex: 2,
      })

      // 하나씩 생성된 건물 폴리곤을 건물 폴리곤 배열에 넣기
      landPolygons.push(subPolygon)
    })
  }

  return landPolygons
}

// 건물 폴리곤들을 지도 위에 표시하는 함수
export const setBuildingPolygons = (result, kakao, actions) => {
  const color = theme.colors.buildingPolygonColor

  // 건물 폴리곤 배열 선언
  const buildingPolygons = []

  // 해당 토지 내 건물 폴리곤 데이터가 있으면 셋팅
  if (result.buildings) {
    const buildings = result.buildings

    // 하나의 토지의 여러 개의 건물이 있음
    buildings.map((building) => {
      const building_shpae_points = building.shape.coordinates

      const buildingPolygonPath = building_shpae_points.map((part) => {
        return part.map((point) => {
          return new kakao.maps.LatLng(point[0], point[1])
        })
      })

      // 지도에 표시할 다각형을 생성합니다
      const buildingPolygon = new kakao.maps.Polygon({
        path: buildingPolygonPath, // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 1, // 선의 두께입니다
        strokeColor: color, // 선의 색깔입니다
        strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다
        fillColor: color, // 채우기 색깔입니다
        fillOpacity: 0.5, // 채우기 불투명도 입니다
        zIndex: 1,
      })

      // 하나씩 생성된 건물 폴리곤을 건물 폴리곤 배열에 넣기
      buildingPolygons.push(buildingPolygon)

      // Expected to return a value in arrow function  array-callback-return 경고메시지 때문에 return
      return true
    })

    // 전역변수에 건물 폴리곤 배열 셋팅
    actions.setBuildingPolygons(buildingPolygons)
  } else {
    // 폴리곤 데이터가 없으면
    actions.setBuildingPolygons([])
  }
}

//합필 건물 폴리곤 생성
export const setCombinationBuildingPolygons = (
  result,
  kakao,
  actions,
) => {
  const color = theme.colors.buildingPolygonColor

  // 건물 폴리곤 배열 선언
  const buildingPolygons = []

  // 하나의 토지의 여러 개의 건물이 있음
  result.buildings.map((building) => {
    const building_shpae_points = building.shape.coordinates

    const buildingPolygonPath = building_shpae_points.map((part) => {
      return part.map((point) => {
        return new kakao.maps.LatLng(point[0], point[1])
      })
    })

    // 지도에 표시할 다각형을 생성합니다
    const buildingPolygon = new kakao.maps.Polygon({
      path: buildingPolygonPath, // 그려질 다각형의 좌표 배열입니다
      strokeWeight: 1, // 선의 두께입니다
      strokeColor: color, // 선의 색깔입니다
      strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      strokeStyle: 'solid', // 선의 스타일입니다
      fillColor: color, // 채우기 색깔입니다
      fillOpacity: 0.5, // 채우기 불투명도 입니다
      zIndex: 1,
    })

    // 하나씩 생성된 건물 폴리곤을 건물 폴리곤 배열에 넣기
    buildingPolygons.push(buildingPolygon)

    // Expected to return a value in arrow function  array-callback-return 경고메시지 때문에 return
    return true
  })

  return buildingPolygons
}

/**
 * @param point : 타겟 마커 포인트
 * @param vs
 * 마커가 폴리곤 안에 있는지 확인하는 함수
 */
export const isMarkerInsidePolygon = (point, vs) => {
  const x = point[0],
    y = point[1]

  let inside = false
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1]
    const xj = vs[j][0],
      yj = vs[j][1]

    const intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}
