import React, {
  Suspense,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import NoteDetailTab from './NoteDetailTab'
import NoteDetailContract from './NoteDetailContract'
import NoteDetailGongbu from './NoteDetailGongbu'
import Scrollbars from 'react-custom-scrollbars-2'
import NoteDetailPhoto from './NoteDetailPhoto'
import NoteDetailWriteDocsContainer from 'containers/notev2/NoteDetailWriteDocsContainer'
import { LayerWrapper } from '../style/NoteStyle'
import { useLocation } from 'react-router-dom'
import { Skeleton } from 'antd'
import styled from 'styled-components'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  setGongbuStepState,
  setOnBoardingStart,
  setOnBoardingState,
  setThreeDepthLoaded,
  setThreeDepthStart,
} from 'modules/onBoarding'
import OnBoarding from '../OnBoarding/OnBoarding'
import NoteDetailAd from './NoteDetailAd'
import useDetailTab from 'lib/hooks/notev2/useDetailTab'
import NoteDetailCalendar from './NoteDetailCalendar'

const NoteDetailContents = (
  { onClose, afterPushClose, callGetNote },
  ref,
) => {
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState(1)
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const { tabs } = useDetailTab()

  //온보딩 관련함수
  const [stepsEnabled, setStepsEnalbed] = useState(false)

  const [steps, setSteps] = useState([])

  const threeDepthChannelSrl = useSelector(
    (state) => state.onBoarding.threeDepthChannelSrl,
  )

  const threeDepthLoaded = useSelector(
    (state) => state.onBoarding.threeDepthLoaded,
  )

  const threeDepthStart = useSelector(
    (state) => state.onBoarding.threeDepthStart,
  )
  //
  useEffect(() => {
    if (
      (location?.state && location?.state?.activeTab) ||
      query.get('activeTab')
    ) {
      const tab = location?.state?.activeTab || query.get('activeTab')
      setActiveTab(tab)
    }
  }, [])

  const onChangeTab = useCallback((tabIndex) => {
    setActiveTab(tabIndex)
  }, [])

  const NoteDetailInfo = useMemo(
    () => React.lazy(() => import('./NoteDetailInfo')),
    [],
  )

  //온보딩 관련 함수

  //3뎁스 input에 포커스 된 1초후 온보딩실행시킴
  useEffect(() => {
    const time = setTimeout(() => {
      if (
        threeDepthChannelSrl != 0 &&
        threeDepthLoaded &&
        threeDepthStart
      ) {
        setStepsEnalbed(true)
      }
    }, 1000)
  }, [
    location,
    threeDepthChannelSrl,
    threeDepthLoaded,
    threeDepthStart,
  ])

  const onExit = () => {
    setStepsEnalbed(false)
    dispatch(setOnBoardingStart(false))
    dispatch(setThreeDepthLoaded(false))
    dispatch(setThreeDepthStart(false))
  }
  //

  return (
    <Wrapper>
      <NoteDetailTab
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
        ref={ref}
      />
      <OnBoarding
        enabled={stepsEnabled}
        steps={steps}
        setSteps={setSteps}
        onExit={onExit}
        options={{
          hidePrev: true,
          nextLabel: '확인',
          prevLabel: '이전',
          doneLabel: '확인',
          buttonClass: 'customButton',
          showBullets: false,
        }}
        onComplete={() => {
          onChangeTab(3)
          //탭이동시키는 함수
          const delay = setTimeout(() => {
            setStepsEnalbed(false)
            dispatch(setGongbuStepState(true))
          }, 100)
        }}
      ></OnBoarding>

      <StyledLayerWrapper>
        {activeTab == 1 && (
          <Suspense fallback={<Skeleton active />}>
            <NoteDetailInfo tabs={tabs} />
          </Suspense>
        )}
        {activeTab == 2 && <NoteDetailPhoto />}

        {activeTab == 3 && (
          <NoteDetailGongbu ref={ref} onChangeTab={onChangeTab} />
        )}
        <NoteDetailWriteDocsContainer
          afterPushClose={afterPushClose}
          onClose={onClose}
        >
          {({ onClickDocsWrite }) => (
            <>
              {activeTab == 4 && (
                <NoteDetailContract
                  onClickDocsWrite={onClickDocsWrite}
                />
              )}
            </>
          )}
        </NoteDetailWriteDocsContainer>
        {activeTab == 5 && <NoteDetailAd />}
        {activeTab == 6 && (
          <NoteDetailCalendar callGetNote={callGetNote} />
        )}
      </StyledLayerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  z-index: 1;
  position: relative;
  flex: 1;
`

const StyledLayerWrapper = styled(LayerWrapper)`
  z-index: -1;
`

export default forwardRef(NoteDetailContents)
