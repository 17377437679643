/**
 * 요금제 결제하기 버튼 보이는지에 대한 조건
 * @param {*} name 플랜 정보
 * @param {*} name 비교할 플랜명
 * @returns
 */
export const getVisibleStartButton = (planInfo, name) => {
  return (
    planInfo?.payment_name != name ||
    (planInfo?.payment_name == name && planInfo?.is_expected_cancel)
  )
}
