import { useEffect, useState, useContext } from 'react'
import MapContext from 'contexts/map'
import {
  billionFormate,
  dateFormat,
  numberWithCommas,
} from 'utils/index'
import RealPriceTable from './RealPriceTable'
import { useSelector } from 'react-redux'
import { Empty } from 'components/design'

const SummaryTableRealPrice = () => {
  const { state } = useContext(MapContext)
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )
  const { table, land } = useSelector(
    (state) => state.summary.summaryInfo,
  )

  const [tableData, setTableData] = useState([]) // 실거래가
  const [columns, setColumns] = useState([]) // 실거래가

  // 최근 년도
  let max = new Date().getFullYear()

  useEffect(() => {
    if (!table?.real_price) return

    // 실거래가 데이블 데이터 셋팅
    let dataForTable = []

    // @INFO: 배열인지 확인
    // if (Array.isArray(table?.real_price)) {

    // addresses테이블의 대지면적을 초기값으로 저장
    let landArea = land?.land_area
    dataForTable = table?.real_price?.map((item, index) => {
      if (item.land_area) {
        // 실거래가 테이블의 land_area가 있는 경우 그 값을 세팅
        landArea = item.land_area
      }
      let unit_price = 0

      if (landArea > 0) {
        unit_price = Math.round(item.price / landArea)
      }

      // 연립다세대/오피스텔/아파트인 경우에는 전용면적으로 나눕니다.
      if (
        '아파트' === item.property_type ||
        '연립다세대' === item.property_type ||
        '오피스텔' === item.property_type
      ) {
        unit_price = Math.round(item.price / item.exclusive_area)
      }

      return {
        key: index,
        type: item.property_type.includes('상업업무용')
          ? '상가'
          : item.property_type,
        year: dateFormat(item.contract_date).substring(2),
        unit_price: state.isSquareMeter
          ? billionFormate(Math.round(unit_price / 10000) * 10000)
          : billionFormate(
              Math.round((unit_price * 3.305) / 10000) * 10000,
            ),
        price: billionFormate(item.price),
        land_area: item.land_area,
        total_floor_area: item.total_floor_area,
        exclusive_area: item.exclusive_area,
        land_of_use_area: item.land_of_use_area,
      }
    })
    // }

    setTableData(dataForTable)
  }, [table, currentAddressId, max, state.isSquareMeter])

  useEffect(() => {
    if (table?.real_price?.length === 0) return

    setColumns([
      {
        title: '유형',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
      },
      {
        title: '거래년월',
        dataIndex: 'year',
        key: 'year',
        align: 'center',
        width: 82,
      },
      {
        title: `${state.isSquareMeter ? '㎡당' : '평당'}`,
        dataIndex: 'unit_price',
        key: 'unit_price',
        className: 'padding_right',
      },
      {
        title: '총액',
        dataIndex: 'price',
        key: 'price',
        className: 'padding_right',
      },
      {
        title: `${
          table?.real_price?.[0]?.land_area ? '대지면적' : '대지권면적'
        }`,
        dataIndex: 'area',
        className: 'padding_right',
        render: (text, row, index) => {
          if (row.land_area) {
            return state.isSquareMeter
              ? numberWithCommas(Math.round(row.land_area)) + '㎡'
              : numberWithCommas(Math.round(row.land_area / 3.305)) +
                  '평'
          } else {
            return (
              (state.isSquareMeter
                ? numberWithCommas(Math.round(row.land_of_use_area)) +
                  '㎡'
                : numberWithCommas(
                    Math.round(row.land_of_use_area / 3.305),
                  ) + '평') || '-'
            )
          }
        },
      },
      {
        title: `${
          table?.real_price?.[0]?.exclusive_area ? '전용면적' : '연면적'
        }`,
        dataIndex: 'area',
        className: 'padding_right',
        render: (text, row, index) => {
          if (row.total_floor_area) {
            return state.isSquareMeter
              ? numberWithCommas(Math.round(row.total_floor_area)) +
                  '㎡'
              : numberWithCommas(
                  Math.round(row.total_floor_area / 3.305),
                ) + '평'
          } else {
            return (
              (state.isSquareMeter
                ? numberWithCommas(Math.round(row.exclusive_area)) +
                  '㎡'
                : numberWithCommas(
                    Math.round(row.exclusive_area / 3.305),
                  ) + '평') || '-'
            )
          }
        },
      },
    ])
  }, [table])

  return (
    <>
      {tableData.length > 0 ? (
        <RealPriceTable columns={columns} dataSource={tableData} />
      ) : (
        <Empty>확인 가능한 실거래 내역이 없습니다.</Empty>
      )}
    </>
  )
}

export default SummaryTableRealPrice
