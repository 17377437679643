import styled from 'styled-components'
import NoteDocsContractTitle from './NoteDocsContractTitle'
import NoteDocsContractDisplay from './NoteDocsContractDisplay'
import NoteDocsContractContent from './NoteDocsContractContent'
import NoteDocsContractRaws from './NoteDocsContractRaws'
import NoteDocsSpecial from './NoteDocsContractSpecial'
import NoteDocsSign from './NoteDocsContractSign'
import { BottomBlock } from 'components/jmapnotev2/style/NoteStyle'
import NoteDocsContact from './NoteDocsContractContact'
import NoteDocsContractContainer from 'containers/notev2/NoteDocsContractContainer'
import { Skeleton } from 'antd'
import theme from 'lib/styles/theme'
import NoteDocsContractPreSale from './NoteDocsContractPreSale'
import NoteDocsContractDisplayPreSale from './NoteDocsContractDisplayPreSale'

/**
 * 부동산 계약서 작성폼
 * @return
 */
const NoteDocsContract = ({
  onUpdate,
  isBuildingGongbuLoading,
  isPropertyContractBind,
}) => {
  return (
    <NoteDocsContractContainer
      isPropertyContractBind={isPropertyContractBind}
    >
      {({
        contract_type,
        property_type,
        onRequestSpec,
        isLoadingSpec,
        indicationSpec,
        mainArticlesSpec,
        special_conditions,
      }) => (
        <div id="print-map-note-contract">
          <NoteDocsContractTitle
            onUpdate={onUpdate}
            onRequestSpec={onRequestSpec}
          />
          <Description danger="Y">
            ※ 자동으로 완성된 정보가 정확한지 반드시 확인해주세요.
            {/* TODO: [자동완성 삭제하기] */}
            <br />※ 계약서(확인설명서)의 자동완성 항목은 공인중개사의
            편의를 위해 제공되는 기능으로 (주)주택은 정확성이나 완전성을
            보장하지 않으며 이를 활용하는 모든 책임은 활용 당사자인
            사용자에게 있습니다.
          </Description>
          <Description>
            ※ 매도인과 매수인 쌍방은 아래 표시 부동산에 관하여 다음 계약
            내용과 같이 {contract_type === '매매' ? '매매' : '임대차'}
            계약을 체결한다.
          </Description>
          {isLoadingSpec ? (
            <Skeleton />
          ) : (
            <>
              {!property_type?.includes('분양권') && (
                <>
                  <NoteDocsContractDisplay
                    onUpdate={onUpdate}
                    isBuildingGongbuLoading={isBuildingGongbuLoading}
                    indicationSpec={indicationSpec}
                  />
                  <NoteDocsContractContent
                    onUpdate={onUpdate}
                    mainArticlesSpec={mainArticlesSpec}
                  />
                  <NoteDocsContractRaws
                    onUpdate={onUpdate}
                    mainArticlesSpec={mainArticlesSpec}
                  />
                </>
              )}
              {property_type?.includes('분양권') && (
                <>
                  <NoteDocsContractDisplayPreSale
                    onUpdate={onUpdate}
                    isBuildingGongbuLoading={isBuildingGongbuLoading}
                    indicationSpec={indicationSpec}
                  />
                  <NoteDocsContractPreSale
                    onUpdate={onUpdate}
                    mainArticlesSpec={mainArticlesSpec}
                  />
                </>
              )}

              <NoteDocsSpecial
                onUpdate={onUpdate}
                special_conditions={special_conditions}
              />
              <NoteDocsSign onUpdate={onUpdate} />
              <NoteDocsContact onUpdate={onUpdate} />
            </>
          )}

          <BottomBlock />
        </div>
      )}
    </NoteDocsContractContainer>
  )
}

const Description = styled.div`
  margin-bottom: 16px;
  font-weight: 400;
  line-height: 160%;
  ${({ danger }) =>
    danger == 'Y' && `color: ${theme.colors.tint.danger};`};
`

export default NoteDocsContract
