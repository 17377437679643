import { message } from 'antd'
import { Button } from 'components/design'
import { rejectApproval } from 'lib/api/notev2/adSubmit'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import DeleteButton from './list/actionButtons/DeleteButton'
import ReRegisterButton from './list/actionButtons/ReRegisterButton'
import RetryButton from './list/actionButtons/RetryButton'
import CancelButton from './list/actionButtons/CancelButton'
import CompleteButton from './list/actionButtons/CompleteButton'
import EndButton from './list/actionButtons/EndButton'
import UpdateButton from './list/actionButtons/UpdateButton'
import NormalButton from './list/actionButtons/NormalButton'
import RecycleButton from './list/actionButtons/RecycleButton'
import RejectButton from './list/actionButtons/RejectButton'

/**
 * 광고 폼 최하단 submit 버튼
 * @returns
 */
const NoteAdSubmitButton = ({ isInsert }) => {
  const { watch } = useFormContext()
  const { isBoss } = useIsAllInOne()
  const history = useHistory()
  const planInfo = useSelector((state) => state.auth.planInfo)

  const status = watch('status')
  const adSrl = watch('ad_srl')
  const contractType = watch('contract_type')
  const price1 = watch('trade.price1')
  const price2 = watch('trade.price2')

  const redirectToList = () => {
    history.replace('/note/v2/ad')
  }

  /**
   * 대표용 버튼 형태 얻기
   * button Type
   *  - submit - 일반 전송(NoteAdEditContainer - preregister 호출)
   *  - TODO: button - 각 동작 정의하기
   * @returns object
   */
  const getButtonStatusBoss = () => {
    const result = {
      작성중: (
        <ButtonWrapper>
          <Button type="submit" color="blue" size="xlarge">
            광고 등록
          </Button>
          <DeleteButton
            adSrl={adSrl}
            callbackFn={redirectToList}
            forForm
          />
        </ButtonWrapper>
      ),
      승인대기: (
        <ButtonWrapper>
          <Button type="submit" color="blue" size="xlarge">
            광고 등록
          </Button>
          <RejectButton adSrl={adSrl} callbackFn={redirectToList} />
        </ButtonWrapper>
      ),
      승인거절: null,
      대기중: null, //버튼 대신 처리중 팝업창 보여줌
      전송실패: (
        <ButtonWrapper>
          <ReRegisterButton
            adSrl={adSrl}
            callbackFn={redirectToList}
            forForm
          />
        </ButtonWrapper>
      ),
      검증중: null,
      검증취소: (
        <ButtonWrapper>
          <RecycleButton />
          <DeleteButton
            adSrl={adSrl}
            callbackFn={redirectToList}
            forForm
          />
        </ButtonWrapper>
      ),
      검증1차실패: (
        <ButtonWrapper>
          <RetryButton adSrl={adSrl} callbackFn={redirectToList} />
          <CancelButton
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
        </ButtonWrapper>
      ),
      검증최종실패: (
        <ButtonWrapper>
          <RecycleButton />
        </ButtonWrapper>
      ),
      광고중: (
        <ButtonWrapper>
          <UpdateButton
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
          <CompleteButton
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
          <EndButton
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
        </ButtonWrapper>
      ),
      종료예정: (
        <ButtonWrapper>
          <UpdateButton
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
          <CompleteButton
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
          <EndButton
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
        </ButtonWrapper>
      ),
      광고종료: (
        <ButtonWrapper>
          <RecycleButton />
          <DeleteButton
            adSrl={adSrl}
            callbackFn={redirectToList}
            forForm
          />
        </ButtonWrapper>
      ),
      신고매물: (
        <ButtonWrapper>
          <NormalButton
            type="신고매물"
            forForm
            adSrl={adSrl}
            callbackFn={redirectToList}
          />
          <CompleteButton
            type="신고매물"
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
          <EndButton
            type="신고매물"
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
        </ButtonWrapper>
      ),
      동일주소매물: (
        <ButtonWrapper>
          <NormalButton
            forForm
            adSrl={adSrl}
            callbackFn={redirectToList}
          />
          <CompleteButton
            type="동일주소매물"
            contractType={contractType}
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
          <EndButton
            type="동일주소매물"
            adSrl={adSrl}
            forForm
            callbackFn={redirectToList}
          />
        </ButtonWrapper>
      ),
    }

    return result?.[status] || null
  }

  /**
   * 직원용 버튼 형태 얻기
   * submit - 일반 전송(preregisterAd)
   * @returns object
   */
  const getButtonStatusStaff = () => {
    const result = {
      작성중: (
        <ButtonWrapper>
          <Button type="submit" color="blue" size="xlarge">
            대표에게 매물 전송
          </Button>
          <DeleteButton
            adSrl={adSrl}
            callbackFn={redirectToList}
            forForm
          />
        </ButtonWrapper>
      ),
      승인대기: (
        <ButtonWrapper>
          <DeleteButton
            adSrl={adSrl}
            callbackFn={redirectToList}
            forForm
          />
        </ButtonWrapper>
      ),
      승인거절: (
        <ButtonWrapper>
          <Button type="submit" color="blue" size="xlarge">
            대표에게 재승인 요청
          </Button>
          <DeleteButton
            adSrl={adSrl}
            callbackFn={redirectToList}
            forForm
          />
        </ButtonWrapper>
      ),
      광고종료: (
        <ButtonWrapper>
          <DeleteButton
            adSrl={adSrl}
            callbackFn={redirectToList}
            forForm
          />
        </ButtonWrapper>
      ),
    }

    return result?.[status] || null
  }

  return (
    <Wrapper>
      {isInsert ? (
        // 광고 등록 페이지
        <Button type="submit" color="blue" size="xlarge">
          {isBoss ? <>광고 등록하기</> : <>대표에게 매물 전송</>}
        </Button>
      ) : isBoss ? (
        getButtonStatusBoss()
      ) : (
        getButtonStatusStaff()
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  text-align: center;
  button {
    width: 236px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`

export default NoteAdSubmitButton
