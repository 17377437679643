import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

export const InputWidth = 147
export const InputAppendWidth = 30
export const InputRemoveAppendWidth = InputWidth - InputAppendWidth

export const Title = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;

  ${({ attachDesc }) =>
    attachDesc &&
    `
    margin-bottom: 8px;
  `}
`

export const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 80px;

  ${media.mediumM`
    margin-bottom: 60px;
    overflow-x: auto;
  `}

  ${media.mediumS`
    margin-bottom: 38px;
  `}
`

export const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${theme.colors.gray[200]};
  min-width: 728px;

  //마지막 자식이 반반짜리 컬럼 일 때는 마지막 2개는 border를 표시하지 않음
  ${({ lastcount = 1 }) =>
    lastcount && lastcount == 1
      ? `
        & > div {
          &:not(:last-child) {
            border-bottom: 1px solid ${theme.colors.gray[200]};
          }
        }
    `
      : `
        & > div {
          &:not(:nth-last-child(-n+2)) {
            border-bottom: 1px solid ${theme.colors.gray[200]};
          }
        }
    `}

  ${media.mediumM`
    & > div {
        &:not(:last-child) {
          border-bottom: 1px solid ${theme.colors.gray[200]};
        }
      }
  `}
`

/**
 * 테이블 컬럼 세트 이름-값
 * @param half 한 row에 두 개 넣을 때 사용 (넓이 50%)
 */
export const ColumnSet = styled.div`
  position: static;
  display: flex;
  width: 100%;
  min-height: 54px;

  ${({ half }) =>
    half &&
    `
        width: 50%;
  `};

  ${media.mediumM`
    ${({ half }) =>
      half &&
      `
          width: 100%;
    `};
  `}
`

export const ColumnTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  width: 138px;
  padding: 0 14px;
  background-color: ${theme.colors.gray[100]};
  border-right: 1px solid ${theme.colors.gray[200]};
  font-size: 15px;
  line-height: 110%;

  ${({ required }) =>
    required &&
    `
    &::after {
      content: '*';
      margin-left: 3px;
      font-size: 15px;
      color: ${theme.colors.tint.danger};
    }
  `};

  ${media.mediumS`
    padding: 6px 8px;
  `}
`

export const ColumnVal = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 138px);
  padding: 10px 14px;
  font-size: 14px;

  input[type='radio']:not(:checked) + span {
    color: ${theme.colors.gray[600]};
  }

  ${({ direction }) =>
    direction == 'column' &&
    `
        flex-direction: column;
        align-items: flex-start;
  `};

  ${({ gap }) =>
    gap &&
    `
        gap: ${gap}px;
  `};

  ${({ nopadding }) =>
    nopadding &&
    `
    padding-left: 0;
    padding-right: 0;
  `}

  ${media.mediumS`
    width: 100%;
  `}
`

export const FormDesc = styled.ul`
  margin-top: 7px;
  list-style-type: disc;

  ${({ nomargin }) => nomargin && `margin: 0;`};

  li {
    line-height: 130%;
    font-size: 12px;
    color: ${theme.colors.gray[600]};

    &::before {
      content: '\f05a';
      ${({ multiple }) => multiple && `content: '\f00c'`};
      font-family: 'Font Awesome 5 Pro';
      margin-right: 4px;
      color: ${theme.colors.blue[400]};
    }
  }
`

export const ValidateWarn = styled.div`
  display: none;

  &:not(:has(div))&:not(:empty) {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    color: ${theme.colors.tint.danger};
    margin-top: 4px;
    width: 100%;
  }

  &:has(> div:not(:empty)) {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    color: ${theme.colors.tint.danger};
    margin-top: 4px;
    width: 100%;
  }
`
