import { deleteFavorite, insertHoFavorite } from 'lib/api/notev2/dongho'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import theme from 'lib/styles/theme'
import {
  setFavoriteIds,
  setSetSelectedDanjiDongsDot,
} from 'modules/noteDongho'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 호
 */
const NoteDonghoHoDetailFavorite = ({
  dongName,
  hoName,
  id,
  isFavorite,
  favoriteId: pFavoriteId,
}) => {
  const favoriteIds = useSelector(
    (state) => state.noteDongho.favoriteIds,
  )
  const dispatch = useDispatch()
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  const selectedDanjiDongsDot = useSelector(
    (state) => state.noteDongho.selectedDanjiDongsDot,
  )
  const selectedDong = useSelector(
    (state) => state.noteDongho.selectedDong,
  )
  const [onRequest, , data] = useAxios(insertHoFavorite)
  const [onRequestDelete, , dataDelete] = useAxios(deleteFavorite)
  const [isActive, setIsActive] = useState(isFavorite)
  const [favoriteId, setFavoriteId] = useState(null)

  useEffect(() => {
    setFavoriteId(pFavoriteId)
  }, [pFavoriteId])

  /**
   * 초기값
   */
  useUpdateEffect(() => {
    setIsActive(isFavorite)
  }, [isFavorite])

  /**
   * 즐겨찾기 설정
   */
  const onClickAddFavorite = () => {
    setIsActive((prev) => (prev == 'Y' ? 'N' : 'Y'))

    if (isActive == 'Y') {
      onRequestDelete({
        favorite_id: favoriteId,
        building_dongho_id: id,
        jdongho_danji_id: selectedDanji.id,
        dong_name: dongName,
        ho_name: hoName,
      })
    } else {
      onRequest({
        building_dongho_id: id,
        jdongho_danji_id: selectedDanji.id,
        dong_name: dongName,
        ho_name: hoName,
      })
    }
  }

  /**
   * 블럭도 업데이트
   */
  const updateBlock = (value) => {
    const targetId = `block_${dongName}_${hoName}`
    if (value == 'Y') {
      document.getElementById(targetId).classList.add('block-favorite')
      dispatch(setFavoriteIds([...favoriteIds, ...[targetId]]))
      updateDongState('add')
    } else {
      document
        .getElementById(targetId)
        .classList.remove('block-favorite')
      dispatch(
        setFavoriteIds(favoriteIds.filter((item) => item != targetId)),
      )
      updateDongState('minus')
    }
  }

  /**
   * 동 드롭다운 점 표시 업데이트
   */
  const updateDongState = (type) => {
    if (type == 'add') {
      const copySelectedDanjiDongsDot = { ...selectedDanjiDongsDot }
      copySelectedDanjiDongsDot[selectedDong] =
        copySelectedDanjiDongsDot[selectedDong] + 1
      dispatch(setSetSelectedDanjiDongsDot(copySelectedDanjiDongsDot))
    } else if (type == 'minus') {
      const copySelectedDanjiDongsDot = { ...selectedDanjiDongsDot }
      copySelectedDanjiDongsDot[selectedDong] =
        copySelectedDanjiDongsDot[selectedDong] - 1
      dispatch(setSetSelectedDanjiDongsDot(copySelectedDanjiDongsDot))
    }
  }

  useEffect(() => {
    if (!data) return
    if (data?.error == 0) {
      updateBlock('Y')
      setFavoriteId(data?.favorite_id)
    }
  }, [data])

  useEffect(() => {
    if (!dataDelete) return
    if (dataDelete?.error == 0) {
      updateBlock('N')
    }
  }, [dataDelete])

  return (
    <Favorite onClick={onClickAddFavorite} active={isActive == 'Y'}>
      <i className={`${isActive == 'Y' ? 'fas' : 'far'} fa-star`}></i>
    </Favorite>
  )
}

const Favorite = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: ${theme.colors.gray[500]};
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    i {
      color: ${theme.colors.yellow[400]};
    }
  `}
`

export default NoteDonghoHoDetailFavorite
