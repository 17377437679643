import styled from 'styled-components'
import theme from 'lib/styles/theme'


const GrayLine=styled.div`
width: 100%;
min-height: 12px;
background-color: #F2F4F6;
`

const SideMenuLine = () => {


  return (
  <>
    <GrayLine/>
  </>
  )
}

export default SideMenuLine
