import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { forwardRef } from 'react'

const CheckboxWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &:hover {
    .checkbox {
      border-color: ${({ color }) =>
        color == 'blue'
          ? theme.colors.blue[400]
          : theme.colors.primary[400]};
    }
  }
`
const StyledCheckbox = styled.div`
  cursor: pointer;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: ${theme.borderRadius[1]};
  border: 2px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.base.white};

  transition: 0.2s ease-in-out;

  &[disabled] {
    background-color: ${theme.colors.gray[200]};
  }

  svg {
    color: white;
    width: 1rem;
    height: 1rem;
  }

  ${({ size }) => {
    if (!size) return

    switch (size) {
      case 'small':
        return `
          width: 16px;
          height: 16px;
        `
      case 'large':
        return `
          width: 24px;
          height: 24px;
        `
    }
  }}

  ${({ checked, color, disabled }) => {
    if (!checked || disabled) return

    switch (color) {
      case 'blue':
        return `
          color: ${theme.colors.blue[400]};
          background-color: ${theme.colors.blue[400]};
        `
      default:
        return `
          color: ${theme.colors.primary[400]};
          background-color: ${theme.colors.primary[400]};
        `
    }
  }}
`

const Text = styled.div`
  margin-left: 8px;
  font-size: 14px;

  ${({ size }) => {
    if (!size) return

    switch (size) {
      case 'small':
        return `
          margin-left: 4px;
          font-size: 10px;
        `
      case 'large':
        return `
          font-size: 18px;
        `
    }
  }}

  ${({ checked, color, disabled }) => {
    if (!checked || disabled) return

    switch (color) {
      case 'blue':
        return `
          color: ${theme.colors.blue[400]};
          border-color: ${theme.colors.blue[400]};
          font-weight: 700;
        `
      default:
        return `
          border-color: ${theme.colors.primary[400]};
          font-weight: 700;
        `
    }
  }}
`

const Checkbox = (
  {
    checked,
    setChecked,
    onChange,
    children,
    value,
    multiValues,
    onMultipleChange,
    customMode,
    color,
    size,
    readOnly,
    className,
  },
  ref,
) => {
  const onClickCheckbox = () => {
    if (setChecked) {
      setChecked(!checked)
      return
    }

    if (onChange) onChange(!checked)

    if (onMultipleChange) handleMultipleChange(multiValues, value)
  }

  const handleMultipleChange = (multiValues, value) => {
    if (!Array.isArray(multiValues)) return []

    const isChecked = multiValues.find((e) => e === value)

    if (isChecked) {
      const removedData = multiValues.filter((e) => e !== value)
      onMultipleChange([...removedData])

      return
    }

    onMultipleChange([...multiValues, value])
  }

  const autoChecked = () => {
    if (checked) return checked || false
    if (!multiValues) return checked || false

    return multiValues.find((e) => e === value)
  }

  if (customMode) {
    return children({ autoChecked, value, onClickCheckbox })
  }

  const keyPress = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.preventDefault()

      if (setChecked) {
        setChecked(!checked)
        return
      }

      if (onChange) onChange(!checked)

      if (onMultipleChange) handleMultipleChange(multiValues, value)
    }
  }

  return (
    <CheckboxWrap
      className={className}
      color={color}
      onClick={
        readOnly
          ? () => {
              return
            }
          : onClickCheckbox
      }
    >
      <StyledCheckbox
        onKeyDown={keyPress}
        tabIndex={0}
        className="checkbox"
        color={color}
        size={size}
        checked={autoChecked()}
        disabled={readOnly}
      >
        {autoChecked() && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
      </StyledCheckbox>

      {children && (
        <Text
          color={color}
          size={size}
          checked={autoChecked()}
          disabled={readOnly}
        >
          {children}
        </Text>
      )}
    </CheckboxWrap>
  )
}

export default forwardRef(Checkbox)
