import { createContext, useEffect, useState } from 'react'

const nowYear = new Date().getFullYear()

const MapContext = createContext({
  // 초기화
  state: {
    map: {},
    viewState: {
      level: 0, // 줌 레벨
      swLatlng: {}, // 남서쪽 위경도 객체
      neLatlng: {}, // 북동쪽 위경도 객체
      isMeasuring: false,
    },
    clickPosition: {
      address_id: '', // 토지 코드
      address_name: '', // 전체 지번 주소
      land_type_code: '',
      land_type: '',
      land_area: '',
      land_price: '',
      unit_price: '',
      multiplier: '',
      data_date: '',
      centerpoint: [],
      shape: [],
    },
    filterState: {},
    layerState: {},
    polygons: [],
    buildingPolygons: [],

    loginInfo: {
      member_srl: '',
      profile_image: {},
      user_id: '',
      user_name: '',
    },
    needToUpdateFavorites: false, // 즐겨찾기 삭제 후 즐겨찾기 목록 조회 이벤트 발생시키기 위함
    isSquareMeter: false, // 평, 제곱미터 토글(제곱미터 단위면 true)
    isTotal: true, // 총액, (제곱미터당 or 평당) 토글
    layer: '',
    summaryData: {},
    sideMenuVisible: false,
    viewer3dDatas: {},
    isScrollTop: false,
    isShowAddnote: false,

    folderDatas: null,

    isFavoritesTwoDepth: false,
    favoriteDatas: [],
    favorite: {},
    visibleNotes: [],
    isOpenSaveModal: false,
    recentAddresses: [],
    homeFavorites: [],
    validationMessage: '',
    combinationStandardPolygons: [],
    combinationPolygons: [],
  },
  actions: {
    setMap: () => {},
    setViewState: () => {},
    setClickPosition: () => {},
    setFilterState: () => {},
    setLayerState: () => {},
    setPolygons: () => {},
    setLoginInfo: () => {},
    setNeedToUpdateFavorites: () => {},
    setIsSquareMeter: () => {},
    setBuildingPolygons: () => {},
    setSummaryData: () => {},
    setSideMenuVisible: () => {},
    setViewer3dDatas: () => {},
    setIsTotal: () => {},
    setIsScrollTop: () => {},
    setLayer: () => {},
    setFolderDatas: () => {},
    setIsFavoritesTwoDepth: () => {},
    setFavoriteDatas: () => {},
    setFavorite: () => {},
    setIsShowAddnote: () => {},
    setVisibleNotes: () => {},
    setIsOpenSaveModal: () => {},
    setRecentAddresses: () => {},
    setHomeFavorites: () => {},
    setValidationMessage: () => {},
    setCombinationStandardPolygons: () => {},
    setCombinationPolygons: () => {},
  },
})

export const initialFilterState = {
  land: {
    // 토지 레이어
    land_area: [0, 300], //대지면적
    land_type: [], //지목
    use_area: [], //용도지역
    // unit_prices: [0, 50], //개별공시지가
  },
  building: {
    // 건물 레이어
    property_type: [], //건물종류
    construction_year: [nowYear - 41, nowYear], //건축연도
    total_floor_area: [0, 360], //연면적
    floor_number: [1, 7], //층수
    //TODO: 건물에 개별주택가격 추가 예정.
  },
  real_prices: {
    // 실거래가
    price_property_type: [], //건물종류
    real_price: [0, 50], //총액
    real_lunit_price: [0, 50], //토지 면적당 단가
    real_bunit_price: [0, 50], //건물 면적당 단가
    land_type: [], //지목
    // 경매
    auction_date: [2006, nowYear],
    appraisal_price: [0, 50], // 감정가
    winning_bid: [0, 50], // 낙찰가
    contract_date: [2006, nowYear],
  },
}

export const initialLayerState = {
  realPriceActive: true, //실거래가, 경매
  emptyLandActive: false, // 건축가능땅
  priceActive: false, //공시지가
  mountainActive: false, //건축가능산
  buildingAgeAcitve: false, //노후도
  housingComplexActive: false, //단지
  dongBoundary: false, //법정동 경계선
  housingSite: false, //개발사업지구
  school: false, //학교
  favorite: true, //매물마커
  building: false, //건물
  land: false, //토지
}

const MapProvider = ({ children }) => {
  const [map, setMap] = useState({})
  const [viewState, setViewState] = useState({})
  const [clickPosition, setClickPosition] = useState({})
  // 필터 상태값
  const [filterState, setFilterState] = useState(initialFilterState)
  const [layerState, setLayerState] = useState(initialLayerState)
  const [polygons, setPolygons] = useState([]) // 클릭한 지점의 토지 다각형을 생성
  const [buildingPolygons, setBuildingPolygons] = useState([]) // 클릭한 지점의 토지의 건물 다각형을 생성

  const [loginInfo, setLoginInfo] = useState({
    csrf_token: '',
    device_token: '',
    member_srl: 0,
    member_type: null,
    profile_image: null,
    user_id: null,
    user_name: null,
  })
  const [needToUpdateFavorites, setNeedToUpdateFavorites] =
    useState(false)
  const [isSquareMeter, setIsSquareMeter] = useState(false) // 제곱미터·평당가 (제곱미터 단위면 true)
  const [isTotal, setIsTotal] = useState(true) // 총액·평당 (총액이면 true)
  const [layer, setLayer] = useState('') // 지도 위 레이어 ('': 일반, USE_DISTRICT: 지적편집도, SKYVIEW: 위성)

  const [summaryData, setSummaryData] = useState({})
  const [sideMenuVisible, setSideMenuVisible] = useState(false)

  const [viewer3dDatas, setViewer3dDatas] = useState({}) // labgl에 넘겨줄 데이터 캐싱처리

  // 2뎁스용
  const [isFavoritesTwoDepth, setIsFavoritesTwoDepth] = useState(false)

  const [favoriteDatas, setFavoriteDatas] = useState([]) // 즐겨찾기 목록 데이터
  const [favorite, setFavorite] = useState({}) // 선택된 즐겨찾기 데이터

  // 모바일용
  const [isScrollTop, setIsScrollTop] = useState(false)
  const [isShowAddnote, setIsShowAddnote] = useState(false) // 모바일용 매물노트 추가 컴포넌트 활성화여부

  // 매물관리용
  const [folderDatas, setFolderDatas] = useState() // 폴더 목록
  // 정렬된 즐겨찾기가 isVisible: true 인 경우의 address_srl을 담을 변수
  const [visibleNotes, setVisibleNotes] = useState([])

  // 등록/수정 컴포넌트가 열린 상태에서 다른 컴포넌트 열려고 했을 때, 메시지 활성화 여부
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false)

  // 최근 클릭했던 주소 목록
  const [recentAddresses, setRecentAddresses] = useState([])

  // Home에서 보여줄 회원의 즐겨찾기 목록
  const [homeFavorites, setHomeFavorites] = useState([])
  // 입력값 검증 실패 메시지를 담을 변수
  const [validationMessage, setValidationMessage] = useState('')

  const [combinationPolygons, setCombinationPolygons] = useState([])
  const [combinationStandardPolygons, setCombinationStandardPolygons] =
    useState([])

  useEffect(() => {
    if (!layerState.housingComplexActive) return

    setPolygons([])
    setBuildingPolygons([])
  }, [layerState.housingComplexActive])

  const value = {
    state: {
      map,
      viewState,
      clickPosition,
      filterState,
      initialFilterState,
      layerState,
      polygons,
      loginInfo,
      needToUpdateFavorites,
      isSquareMeter,
      buildingPolygons,
      summaryData,
      sideMenuVisible,
      viewer3dDatas,
      isTotal,
      isScrollTop,
      layer,
      folderDatas,
      isFavoritesTwoDepth,
      isShowAddnote,
      favoriteDatas,
      favorite,
      visibleNotes,
      isOpenSaveModal,
      recentAddresses,
      homeFavorites,
      validationMessage,
      combinationPolygons,
      combinationStandardPolygons,
    },
    actions: {
      setMap,
      setViewState,
      setClickPosition,
      setFilterState,
      setLayerState,
      setPolygons,
      setLoginInfo,
      setNeedToUpdateFavorites,
      setIsSquareMeter,
      setBuildingPolygons,
      setSummaryData,
      setSideMenuVisible,
      setViewer3dDatas,
      setIsTotal,
      setIsScrollTop,
      setLayer,
      setFolderDatas,
      setIsFavoritesTwoDepth,
      setIsShowAddnote,
      setFavoriteDatas,
      setFavorite,
      setVisibleNotes,
      setIsOpenSaveModal,
      setRecentAddresses,
      setHomeFavorites,
      setValidationMessage,
      setCombinationStandardPolygons,
      setCombinationPolygons,
    },
  }

  return (
    <MapContext.Provider value={value}>{children}</MapContext.Provider>
  )
}

const { Consumer: MapConsumer } = MapContext

export { MapProvider, MapConsumer }

export default MapContext
