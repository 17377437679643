import theme from 'lib/styles/theme'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import CustomizedLegend from '../SummaryGraph/CustomizedLegend'
import { useSelector } from 'react-redux'
import SummarMultiHousingGraphTooltip from './SummarMultiHousingGraphTooltip'

const SummaryMultiHousingGraph = ({ data }) => {
  const isApt = useSelector((state) => state.summary.isApt) // 공동 주택 여부

  const thickFormatter = (value) => {
    if (isNaN(value)) return null
    if (value < 100) {
      return '0원'
    }

    if (value < 10000000) {
      const thounsand = value / 10000
      return thounsand.toFixed(0) + '만'
    }

    if (value < 100000000) {
      const thounsand = value / 10000000
      return thounsand.toFixed(1) + '천'
    }
    return Math.ceil((value / 100000000) * 10) / 10 + '억'
  }

  const tickFormatter = (value) => value.slice(0, 4)

  return (
    <ResponsiveContainer width="100%" height={290}>
      <LineChart
        width={500}
        height={300}
        data={[...data].reverse()}
        margin={{
          top: 30,
          right: 30,
          left: -20,
          bottom: 0,
        }}
      >
        <CartesianGrid
          vertical={false}
          stroke={theme.colors.gray[100]}
        />
        <XAxis
          dataKey="price_date"
          xAxisId={0}
          tick={{ fill: theme.colors.gray[500], fontSize: '12px' }}
          tickLine={false}
          axisLine={false}
          height={60}
          tickSize={12}
          tickFormatter={tickFormatter}
        />

        <YAxis
          tickFormatter={thickFormatter}
          tick={{ fill: theme.colors.gray[600], fontSize: '12px' }}
          tickLine={false}
          axisLine={false}
          tickCount={5}
          minTickGap={4}
          scale={'linear'}
          domain={['dataMin', 'dataMax']}
          dataKey="price"
        />

        <Tooltip
          position={{ y: 0 }}
          isAnimationActive={false}
          cursor={{
            stroke: theme.colors.blue[400],
            strokeWidth: 1,
          }}
          contentStyle={{
            backgroundColor: 'white',
            borderColor: theme.colors.gray[200],
            borderRadius: '8px',
            boxShadow: theme.shadow['card-2'],
            padding: '0.5rem 1rem',
          }}
          content={<SummarMultiHousingGraphTooltip />}
        />

        <Legend
          payload={[
            {
              value: isApt ? '공동주택공시가격' : '개별주택가격',
              color: '#4378FF',
            },
          ]}
          wrapperStyle={{
            left: 8,
          }}
          content={<CustomizedLegend />}
        />
        <Line
          type="linear"
          dataKey="price"
          stroke="#4378FF"
          strokeWidth={3}
          connectNulls
          dot={null}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default SummaryMultiHousingGraph
