import SummaryAptRPTab from 'components/summary/SummaryApt/SummaryAptRPTab'
import { CardHeader, Empty } from 'components/design'
import styled from 'styled-components'
import PagingWithNumber from 'components/design/Paging/PagingWithNumber'
import SummaryAptRpTable from './SummaryAptRpTable'
import SummaryAptRpTableLegend from './SummaryAptRpTableLegend'
import SummaryRpTableTitle from './SummaryRpTableTitle'
import { useSelector } from 'react-redux'
import useAxios from 'lib/hooks/useAxios'
import { getRealPriceByDongHo } from 'lib/api/summaryApi'
import { useEffect, useState } from 'react'
import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import { Skeleton } from 'antd'
import SummaryRpDetailTable from '../SummaryRpDetail/SummaryRpDetailTable'
import { getCustomPurpose } from 'lib/utils'

const SummaryAptRP = ({
  currentType,
  onChangeCurrentTab,
  originData,
  data,
  currentPage,
  setCurrentPage,
  selectedArea,
  addressId,
}) => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)

  const [onRequest, loading, priceDongHoData] = useAxios(
    getRealPriceByDongHo,
  )
  const [rpData, setRpData] = useState([])
  const [title, setTitle] = useState('')
  const [visible, setVisible] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  useEffect(() => {
    if (!priceDongHoData || !priceDongHoData.result) {
      setRpData([])
      setTitle('')
      return
    }
    setRpData(priceDongHoData.result)
    setTableTitle(priceDongHoData)
  }, [priceDongHoData])

  /**
   * 테이블 타이틀 지정
   * @param {*} resultData
   */
  const setTableTitle = (resultData) => {
    if (resultData.result.length > 0) {
      const result = resultData.result[0]
      setTitle(
        `${result.dong_name || ''}${
          result?.floor_number ? ` ${result.floor_number}층 ` : ' '
        }${result.ho_name || ''}`,
      )
    } else {
      setTitle('')
    }
  }

  /**
   * 클릭시 동,호 실거래 데이터 호출
   * @param {*} price_id
   * @param {*} type
   */
  const onClickItem = (price_id, type) => {
    if (getCustomPurpose(summaryInfo?.building) != '아파트') return

    setActiveItem(price_id)
    setVisible(true)
    onRequest({
      address_id: addressId,
      price_id,
      type,
    })
  }

  /**
   * 레이어 닫기
   */
  const onCloseLayer = () => {
    setVisible(false)
    setActiveItem('')
  }

  useEffect(() => {
    if (!visible) return
    const preventGoBack = () => {
      setVisible(false)
    }

    history.pushState(null, '', location.href)
    window.addEventListener('popstate', preventGoBack)

    return () => window.removeEventListener('popstate', preventGoBack)
  }, [visible])

  return (
    <>
      <CardHeader
        subtitle={
          <SummaryAptRPTab
            currentType={currentType}
            onChangeCurrentTab={onChangeCurrentTab}
          />
        }
      >
        <SummaryRpTableTitle selectedArea={selectedArea} />
      </CardHeader>
      <SummaryAptRpTable
        data={data}
        onClickItem={onClickItem}
        activeItem={activeItem}
        clickable={selectedArea?.purpose_type != '상가'}
      />
      <SummaryAptRpTableLegend />
      {data.length > 0 && (
        <PagingWrapper>
          <PagingWithNumber
            data={originData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={5}
          />
        </PagingWrapper>
      )}
      {visible && (
        <ExternalLayer
          //    noTopHeader={isMobile}
          title="실거래가 상세"
          onClose={onCloseLayer}
        >
          {loading && (
            <SkeletonWrap>
              <Skeleton active />
            </SkeletonWrap>
          )}
          {!loading && (!rpData || rpData.length == 0) && (
            <Prepare>
              <i className="fas fa-flask-potion"></i> 데이터 준비중
              입니다.
            </Prepare>
          )}
          {rpData && rpData.length > 0 && (
            <SummaryRpDetailTable title={title} data={rpData} />
          )}
        </ExternalLayer>
      )}
    </>
  )
}

const PagingWrapper = styled.div`
  margin-bottom: 24px;
`

const SkeletonWrap = styled.div`
  padding: 20px;
`

const Prepare = styled.div`
  padding: 20px;
  i {
    margin-right: 4px;
  }
`

export default SummaryAptRP
