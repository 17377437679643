import styled from 'styled-components'
import { useMemo } from 'react'
import { showAllTypesPrice } from 'lib/utils/notev2/gridUtil'
import { checkInputAndMoveFocus } from 'lib/utils/notev2/gridFocus'
import usePriceCellTouch from 'components/jmapnotev2/customCell/List/usePriceCellTouch'
import CustomPriceCellItem from './CustomPriceCellItem'
import { Tooltip } from 'antd'
import theme from 'lib/styles/theme'
import { ActiveCellStyle } from 'components/jmapnotev2/style/NoteGridStyle'

/**
 * 광고관리 그리드
 * 가격
 */
const CustomPriceCell = ({ node, api }) => {
  const { onTouchStart, onTouchEnd } = usePriceCellTouch(() => {
    checkInputAndMoveFocus(this)
    showAllTypesPrice(api, node)
  })

  const contract_type = node.data.contract_type

  const isPresale = useMemo(
    () =>
      ['아파트 분양권', '오피스텔 분양권'].includes(
        node.data.property_type,
      ),
    [node.data.property_type],
  )

  if (node.data.ad_srl == '신규') return <></>

  return (
    <Wrapper
      tabIndex={-1}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      {!contract_type && (
        <TooltipWrapper tabIndex={0}>
          <Tooltip title="구분을 선택하시면 가격을 입력할 수 있어요.">
            <SelectInfo>구분 선택 후 입력</SelectInfo>
          </Tooltip>
        </TooltipWrapper>
      )}
      {(contract_type == '매매' || isPresale) && (
        <CustomPriceCellItem
          name="sale_price"
          contractType="매매"
          value={node.data.sale_price}
          oriContractType={contract_type}
          node={node}
          property_type={node.data.property_type}
        />
      )}
      {contract_type == '전세' && (
        <CustomPriceCellItem
          name="jeonse_deposit"
          contractType="전세"
          value={node.data.deposit}
          oriContractType={contract_type}
          node={node}
        />
      )}
      {(contract_type == '월세' || contract_type == '단기임대') && (
        <RentWrap tabIndex={-1}>
          <CustomPriceCellItem
            name="deposit"
            contractType="월세"
            value={node.data.deposit}
            oriContractType={contract_type}
            node={node}
          />
          <Slash tabIndex={-1}>/</Slash>
          <CustomPriceCellItem
            name="rent"
            contractType="월세"
            value={node.data.rent}
            oriContractType={contract_type}
            node={node}
          />
        </RentWrap>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  position: relative;
  z-index: 1;
  ${({ isVisible }) => isVisible && `opacity: 1;`}
`

const RentWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`

const Slash = styled.div`
  width: 5px;
  z-index: 999;
`

const SelectInfo = styled.div`
  z-index: 22;
  color: ${theme.colors.gray[300]};
  font-weight: 400;
`

const TooltipWrapper = styled.div`
  width: 100%;
  outline: none;
  &:focus {
    ${ActiveCellStyle}
  }
`

export default CustomPriceCell
