import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { ReactComponent as DeunggiIcon } from 'assets/icon/note/payment/deunggi.svg'
import { useSelector } from 'react-redux'
import NotePaymentDeunggibuRefundModal from './NotePaymentDeunggibuRefundModal'
import { Button } from 'components/design'
import { useState } from 'react'
import { accessibleStaffGroups } from 'Constants'

/**
 * 결제관리
 * 자신의 등기부 등본 사용량
 */
const NotePaymentInfoMiniBlockAllInOneMember = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { usage, remain_count } = useSelector(
    (state) => state.auth.planInfo,
  )

  const [isVisibleRefundModal, setIsVisibleRefundModal] =
    useState(false)

  const onClickRefund = () => {
    setIsVisibleRefundModal(true)
  }

  const onCloseRefundModal = () => {
    setIsVisibleRefundModal(false)
  }

  return (
    <Wrapper>
      <Block>
        <Title>
          <DeunggiIcon />
          등기부등본
        </Title>
        {loginInfo?.other_groups?.some((item) =>
          accessibleStaffGroups.includes(item),
        ) && (
          <StyledButton
            color="gray"
            size="small"
            onClick={onClickRefund}
          >
            환불하기
          </StyledButton>
        )}
        <Count>
          {remain_count || 0}
          <span>개 남음</span>
        </Count>
        <Bottom>
          <BottomTitle>이 달 사용량</BottomTitle>
          <BottomValue>{usage || 0}개</BottomValue>
        </Bottom>
      </Block>
      <NotePaymentDeunggibuRefundModal
        onCancel={onCloseRefundModal}
        visible={isVisibleRefundModal}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;

  ${media.large`
    flex-direction: column;
  `}

  ${media.mediumM`
    flex-direction: row;
  `}

  ${media.mediumS`
    flex-direction: column;
  `}
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${theme.colors.gray[200]};

  ${media.smallPc`
    padding: 20px 12px;
  `}
`

const Title = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: 33px;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed ${theme.colors.base.black}1A;
  gap: 4px;
  font-size: 22px;
  font-weight: 600;
  line-height: 100%;

  span {
    font-size: 14px;
    color: ${theme.colors.gray[600]};
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ mb }) => mb && `margin-bottom: 10px;`};
`

const BottomTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  line-height: 100%;
  font-weight: 400;
`

const BottomValue = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
`

const StyledButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 20px;
`

export default NotePaymentInfoMiniBlockAllInOneMember
