import theme from 'lib/styles/theme'
import styled from 'styled-components'

const SummaryLegalCurrentUse = ({ planning }) => {
  const others = planning.others
    .map((item) => {
      return item
    })
    .filter((str) =>
      ['택지개발예정지구', '택지개발지구', '지구단위계획구역'].includes(
        str,
      ),
    )[0]

  return (
    others && (
      <Title>
        <>
          이곳은
          <span>
            &nbsp;
            {others}
          </span>
          이에요
        </>
        <br />
        {planning.development && '고시정보를 확인하세요'}
      </Title>
    )
  )
}

const Title = styled.div`
  margin-bottom: 24px;
  word-break: keep-all;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  & > span {
    color: ${theme.colors.primary[500]};
  }
`
export default SummaryLegalCurrentUse
