import { createContext, useEffect, useState } from 'react'

const CardContext = createContext()

export const CardProvider = ({ size, children }) => {
  const [sizeState, setSizeState] = useState(size)

  const value = {
    state: {
      sizeState,
    },
    actions: {
      setSizeState,
    },
  }

  useEffect(() => {
    setSizeState(size)
  }, [size])

  return (
    <CardContext.Provider value={value}>
      {children}
    </CardContext.Provider>
  )
}

export default CardContext
