import { Button, Modal } from 'components/design'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 결제 취소 confirm 모달
 * * 결제일로부터 7일 이내 이면서 등기부등본 사용 내역이 없는 경우 = 결제 취소로 인한 환불 가능
 * @returns
 */
const NotePaymentCancelConfirm = ({
  visible = false,
  onOk,
  onCancel,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  return (
    <Modal
      title={'결제를 취소 하시겠어요?'}
      visible={visible}
      width={450}
      hiddenClose
      footer={
        visible ? (
          <ButtonWrapper>
            <Button onClick={onCancel} color="gray">
              {planInfo?.payment_name} 계속 유지
            </Button>
            <Button onClick={onOk} color="danger">
              결제 취소
            </Button>
          </ButtonWrapper>
        ) : null
      }
    >
      {visible && (
        <>
          결제 취소는 결제일 기준 7일까지 가능합니다. 결제 취소시 즉시
          프리 요금제로 변경되며, 남은 등기부등본은 사용이 제한됩니다.
          <br />
          <br />
          또한 친구 초대 이벤트 링크를 공유한 경우 더이상 이벤트를 통해
          친구를 초대할 수 없으며, 이벤트로 등기열람권을 지급받을 수
          없습니다.
          <br />
          <br />
          결제한 수단으로 환불되며, 신용카드로 결제한 경우 환불은 영업일
          기준 3~5일 정도 소요됩니다.
        </>
      )}
    </Modal>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 0 16px 24px 20px;
`

export default NotePaymentCancelConfirm
