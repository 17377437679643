import { useCallback, useEffect, useState } from 'react'

import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { modifyChannelUser } from 'lib/api/note/channelApi'

import { Select } from 'components/design'
import useAxios from 'lib/hooks/useAxios'

const InviteModifyType = ({
  memberSrl,
  originMemberType,
  channelSrl,
}) => {
  const [memberType, setMemberType] = useState('readonly')
  const [onRequest, loading, data, error, reset] =
    useAxios(modifyChannelUser)

  useEffect(() => {
    setMemberType(originMemberType)
  }, [originMemberType])

  const handleChange = useCallback(
    async (e) => {
      setMemberType(e.target.value)
      onRequest({ channelSrl, memberSrl, memberType: e.target.value })
    },
    [onRequest, channelSrl, memberSrl],
  )

  useEffect(() => {
    if (error) {
      setMemberType(originMemberType)
    }
  }, [error])

  return (
    <Select
      small
      color="primary"
      value={memberType}
      onChange={handleChange}
      style={{ minWidth: '110px' }}
    >
      <option value="manager">관리</option>
      <option value="readwrite">읽기, 쓰기</option>
      <option value="readonly">읽기만 가능</option>
    </Select>
  )
}

export default InviteModifyType
