import { List } from 'components/design'
import InviteListItem from './InviteListItem'

const InviteList = ({ users, channel_srl }) => {
  return (
    <>
      <List title="초대된 사용자">
        {users?.map(
          (item, i) =>
            item.status === 2 && <InviteListItem item={item} key={i} />,
        )}
      </List>

      <List title="초대 수락 대기중">
        {users?.map(
          (item, i) =>
            item.status === 1 && <InviteListItem item={item} key={i} />,
        )}
      </List>
    </>
  )
}

export default InviteList
