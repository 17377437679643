import SummaryLegalInfoHeader from 'components/summary/SummaryLegal/SummaryLegalInfoHeader'
import { Help } from 'components/summary/SummaryLegal/SummaryLegalCourtInfoContainer'
import { Card, CardHeader, CardBody } from 'components/design'
import {
  Label,
  Value,
  StyledList,
  Icon,
} from 'components/summary/SummaryLegal/SummaryLegalStyle'

const getAreaText = (type) => {
  const result = {
    제1종전용주거지역: [
      '타운하우스 및 단독주택 단지가 조성된',
      '주거환경의 보호가 필요한 지역이에요',
    ],
    제2종전용주거지역: [
      '연립·다세대주택 중심의',
      '주거환경을 위한 보호가 필요한 지역이에요',
    ],
    제1종일반주거지역: [
      '단독주택 위주의 편리한 주거환경을',
      '조성하기 위한 지역이에요',
    ],
    제2종일반주거지역: [
      '공동주택 위주의 편리한 주거환경을',
      '조성하기 위한 지역이에요',
    ],
    제3종일반주거지역: [
      '편리한 주거환경의',
      '중층 및 고층주택을 짓기 적합해요',
    ],
    중심상업지역: [
      '도심·부도심의 업무기능 확충을 위해',
      '산업이 밀집된 지역이에요',
    ],
    일반상업지역: [
      '교통 및 일반적인 업무,',
      '편의시설 접근성이 좋은 지역이에요',
    ],
    근린상업지역: [
      '근린주택가의 편의를 위해',
      '일용품 및 서비스 공급을 담당하는 지역이에요',
    ],
    유통상업지역: [
      '판매·운수·창고 등 대형 유통시설같은',
      '유통기능 증진을 위한 지역이에요',
    ],
    전용공업지역: [
      '철도·공항터미널의 접근이 용이하며',
      '중화학·공해성 공장지대의 지역이에요',
    ],
    일반공업지역: [
      '환경을 저해하지 않는 공업시설을 수용하는',
      '도시 외곽 또는 근교지역이에요',
    ],
    준공업지역: [
      '환경오염이 적은 공장을 수용하고,',
      '주거·상업·업무시설을 지을 수 있어요',
    ],
    보전녹지지역: [
      '학교·의료시설 등을 지을 수 있고,',
      '자연환경 보전이 필요한 지역이에요',
    ],
    생산관리지역: [
      '농업, 임업, 어업 등의 생산을 위해',
      '관리가 필요한 지역이에요',
    ],
    계획관리지역: [
      '도시지역으로 이용·개발을 하기 위해',
      '계획·관리가 필요한 지역이에요',
    ],
    농림지역: ['농림업의 진흥과 산림을', '보전하기 위한 지역이에요'],
    자연환경보전지역: [
      '자연·생태계·문화재를 보전하고,',
      '수산자원 보호·육성을 위한 지역이에요',
    ],
    준주거지역: [
      '상업·업무기능이 더해진',
      '주거환경의 보호가 필요한 지역이에요',
    ],
    생산녹지지역: [
      '농업적 생산이 우선되며',
      '단독주택과 근린시설을 제한적으로 지을 수 있어요',
    ],
    자연녹지지역: [
      '자연환경 보전이 필요하지만',
      '제한적인 개발이 허용된 지역이에요',
    ],
    보전관리지역: [
      '자연·녹지공간 등을 위한 관리가 필요하여',
      '개발이 어려운 지역이에요',
    ],
  }

  return result[type] ? result[type] : ''
}

export const getAreaMainTitle = (usearea) => {
  if (!usearea) return ''

  const result = getAreaText(
    typeof usearea === 'string' ? usearea : Object.keys(usearea)[0],
  )

  return result ? (
    <>
      {result[0]}
      <br /> {result[1]}
    </>
  ) : (
    ''
  )
}

const SummaryLegalAreaInfoContainer = ({ data, land }) => {
  return (
    <Card>
      <CardHeader>
        <SummaryLegalInfoHeader
          smallTitle="국토의 계획 및 이용에 관한 법률"
          mainTitle={getAreaMainTitle(data?.planning?.usearea)}
        />
      </CardHeader>
      {data.planning && (
        <CardBody>
          <StyledList>
            {data?.planning?.usearea &&
              Object.keys(data?.planning?.usearea)
                .filter((e) => e != '지정되지않음')
                .map((area, index, oriArr) => {
                  return (
                    <li key={index}>
                      <Label>
                        <Icon>
                          <i className="fal fa-city"></i>
                        </Icon>
                        용도지역
                        {oriArr.length > 1 ? index + 1 : ''}
                      </Label>
                      <Value>
                        {area}
                        {/* {data?.planning?.usearea[area] &&
                          data?.planning?.usearea[area] !== '0.00' &&
                          `(${data?.planning?.usearea[area]}%)`} */}
                      </Value>
                    </li>
                  )
                })}

            {land?.land_type && (
              <li>
                <Label>
                  <Icon>
                    <i className="fal fa-draw-square"></i>
                  </Icon>
                  지목
                </Label>
                <Value>{land?.land_type}</Value>
              </li>
            )}
          </StyledList>

          <Help>
            <i className="fal fa-info-circle" />
            현재 시점의 자세한 사업지구 정보는 해당 지자체를 통해
            확인하시기 바랍니다.
          </Help>
        </CardBody>
      )}
    </Card>
  )
}

export default SummaryLegalAreaInfoContainer
