import theme, { media } from 'lib/styles/theme'
import { allInOnePriceAPerson } from 'lib/utils/notev2/util'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 올인원 멤버 초대
 * 번호 입력 칸
 */
const NotePaymentPersonNumberSelectInput = ({
  perAmount,
  month,
  handleTextValueChange,
  addInput,
  removeInput,
  onBlurInput,
  input,
  errorNumbers,
}) => {
  return (
    <Wrapper>
      <Title>
        올인원을 사용할
        <br />
        멤버는 몇 명인가요?
      </Title>
      <SubTitle>
        멤버 추가 시 인당{' '}
        {numberWithCommas(allInOnePriceAPerson[month])}원({month}개월)이
        적용됩니다.
      </SubTitle>
      <Block>
        *단, 이번 달 일할 계산 적용시{' '}
        <strong>인당 {numberWithCommas(perAmount)}원 결제</strong>{' '}
        <br />
        같은 사무소에 소속된 ‘소속중개사’와 ‘중개보조원’만 추가
        가능합니다.
      </Block>
      <InputWrapper>
        {Object.keys(input).map((key, index, oriArr) => {
          return (
            <InputItem key={index}>
              <TypingWrapper>
                <NumberCircle>{index + 1}</NumberCircle>
                <Input
                  onBlur={onBlurInput}
                  placeholder="전화번호 입력"
                  type="text"
                  key={index}
                  onChange={handleTextValueChange}
                  value={input[key]}
                  name={key}
                />
                <ErrorText>
                  {errorNumbers.find(
                    ({ number }) => input[key] == number,
                  ) &&
                    errorNumbers.find(
                      ({ number }) => input[key] == number,
                    ).message}
                </ErrorText>
              </TypingWrapper>
              <PlusMinusWrapper>
                <PlusMinus onClick={addInput}>
                  <i className="fal fa-plus"></i>
                </PlusMinus>
                {oriArr.length != 1 && (
                  <PlusMinus minus onClick={() => removeInput(key)}>
                    <i className="fal fa-minus"></i>
                  </PlusMinus>
                )}
              </PlusMinusWrapper>
            </InputItem>
          )
        })}
      </InputWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 32px;
  text-align: center;
`

const Title = styled.div`
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 700;
  line-height: 130%;

  ${media.mediumM`
    font-size: 36px;
  `}

  ${media.mediumS`
    font-size: 30px;
  `}
`

const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[600]};
  ${media.mediumS`
    font-size: 16px;
  `}
`

const Block = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
  padding: 16px 28px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[300]};
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  strong {
    color: ${theme.colors.gray[900]};
  }

  ${media.mediumS`
    padding: 10px 14px;
  `}
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InputItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid ${theme.colors.gray[100]};
`

const Input = styled.input`
  outline: none;
  border: none;
  height: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  color: ${theme.colors.gray[750]};
  background-color: ${theme.colors.base.white};

  ${({ isCeo }) =>
    isCeo &&
    `
    width: 112px;
  `}
`

const NumberCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 14px;
  background-color: ${theme.colors.gray[100]};
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
`

const Tag = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  color: ${theme.colors.blue[400]};
  background-color: ${theme.colors.blue[50]};
  border-radius: 999px;
  padding: 2px 4px;
`

const PlusMinusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const PlusMinus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: ${theme.colors.blue[400]};
  color: ${theme.colors.base.white};
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;

  ${({ minus }) =>
    minus &&
    `
    background-color: ${theme.colors.blue[50]};
    color: ${theme.colors.blue[400]};
  `}
`

const TypingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ErrorText = styled.div`
  position: absolute;
  right: 75px;
  bottom: 0;
  color: ${theme.colors.red[400]};
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
`

export default NotePaymentPersonNumberSelectInput
