import { Button } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as ExcelIcon } from 'assets/icon/note/excel.svg'

/**
 * 매물관리 엑셀 다운
 */
const NoteListExcelDown = () => {
  const baseURL = `https://${window.location.host}/jmapnote/api/note/exportFile?channel_srl=`

  const isMobile = useIsMobile()

  const [title, setTitle] = useState('') //필터 filter
  const [subTitle, setSubTitle] = useState('') // 서브 필터 subfilter

  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const { property_types } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )
  const notePropertyFilter = useSelector(
    (state) => state.notev2.notePropertyFilter,
  )

  useEffect(() => {
    const filterEntries = Object.entries(property_types)
    const selectedFilterEntry = filterEntries.find(
      (item) => item[1].length > 0,
    )
    if (selectedFilterEntry) {
      //타이틀
      setTitle(selectedFilterEntry[0])

      if (!selectedFilterEntry[0]) return

      //서브타이틀
      if (!notePropertyFilter[selectedFilterEntry[0]]) {
        return
      }

      const matchSubtitle = Object.entries(
        notePropertyFilter[selectedFilterEntry[0]],
      ).find((item) => {
        return (
          JSON.stringify(item[1].code) ==
          JSON.stringify(selectedFilterEntry[1][0])
        )
      })
      setSubTitle(matchSubtitle ? matchSubtitle[1]?.code : '')
    } else {
      setTitle('')
      setSubTitle('')
    }
  }, [property_types])

  const onClickDown = () => {
    if (!channelInfo?.channel_srl) return

    const channelSrl = channelInfo.channel_srl
    window.location.href = `${baseURL}${channelSrl}&filter=${title}&subfilter=${subTitle}`
  }

  return (
    <StyledButton
      color="default"
      plain
      size="small"
      onClick={onClickDown}
    >
      <ExcelIcon />
      {!isMobile && '엑셀 다운'}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  padding: 0 10px;
`

export default NoteListExcelDown
