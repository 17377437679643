import { forwardRef } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const Select = forwardRef((props, ref) => {
  const { value, disabled, expand, color, label, border } = props

  return (
    <SelectWrap
      disabled={disabled}
      expand={expand}
      color={color}
      ref={ref}
      border={border}
    >
      {label && <Label>{value ? label : ''}</Label>}
      <FlexContainer expand={expand}>
        <StyledSelect {...props} />
        <Chevron className="far fa-chevron-down"></Chevron>
      </FlexContainer>
    </SelectWrap>
  )
})

const Label = styled.div`
  flex: 0 0 auto;
  height: calc((0.75rem * 1.2) + 0.25rem);
  padding-top: 0.25rem;
  color: ${theme.colors.gray[500]};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
`

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const SelectWrap = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  flex-direction: column;

  width: auto;
  /* flex-wrap: wrap; */

  border-bottom: 1px solid ${theme.colors.gray[200]};
  /* border-radius: ${theme.borderRadius[1]}; */

  transition: 0.2s ease;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    border-bottom: 2px solid ${theme.colors.primary[400]};

    ${({ border }) =>
      border == 'blue' &&
      `
      border-bottom: 2px solid ${theme.colors.blue[400]};
    `}
    opacity: 0;
    transition: 0.2s ease;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: no-drop;
    opacity: 0.7;
  `}

  ${({ expand }) =>
    expand &&
    `
    display: flex;
    width: 100%;
  `}

  &:focus-within {
    outline: none !important;
    background-color: white;
    &::after {
      opacity: 1;
    }
  }
  &:hover {
    border-bottom-color: ${theme.colors.gray[300]};
  }

  ${({ color }) => {
    switch (color) {
      case 'primary':
        return `  
        background-color: ${theme.colors.primary[50]};
        border-color: ${theme.colors.primary[50]};

        i {
          color: ${theme.colors.primary[300]};
        }
        
        &:focus-within {
          background-color: white;
        }

        &:hover {
          border-bottom-color: ${theme.colors[color][300]};
        }
      `
      case 'blue':
        return `
          border-radius: 6px;
          background-color: #F4F6FB;
          border-color: ${theme.colors.blue[50]};

          i {
            color: ${theme.colors.blue[300]};
          }
          
          &:focus-within {
            background-color: white;
          }

          &:hover {
            border-bottom-color: ${theme.colors[color][300]};
          }

        `
    }
  }}
`

const Chevron = styled.i`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 12px;

  display: flex;
  align-items: center;
  height: 100%;

  color: ${theme.colors.gray[500]};
  font-size: 0.875rem;

  transition: 250ms ease;
`

const StyledSelect = styled.select`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 41px;
  min-width: 122px;

  padding: 0;
  padding-left: 2px;
  padding-right: 36px;
  outline: none !important;
  border: none;
  background-color: transparent;
  border-radius: ${theme.borderRadius[1]};

  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 500;

  appearance: none;
  border: none;
  outline: none;

  transition: 0.2s ease;

  &::-ms-expand {
    display: none;
  }

  ${({ small }) =>
    small &&
    `
    padding-left: 0.75rem;
    height: 32px;
  `}

  ${({ color }) => {
    switch (color) {
      case 'primary':
        return `  
          padding-left: 12px;
          color: ${theme.colors.primary[500]};
          font-weight: 500;
          padding-left : 14px;
      `
      case 'blue':
        return `
          padding-left: 12px;
          font-size: 14px;
        `
    }
  }}
`

export default Select
