import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import InviteHead from 'components/jmapnote/invite/InviteHead'
import InviteContainer from 'containers/note/invite/InviteContainer'

const SettingsInvitePage = ({ history }) => {
  // const { channelSrl } = useNoteMatch()

  // const onClose = () => {
  //   history.replace(`/note/${channelSrl}/settings`)
  // }

  const onBack = () => {
    history.go(-1)
  }

  return (
    <ExternalLayer
      title="초대 관리"
      onBack={onBack}
      hiddenClose
      hasAdditionalHeader
      shift
    >
      <InviteHead />
      <InviteContainer />
    </ExternalLayer>
  )
}

export default SettingsInvitePage
