import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  channels: [],
  channelInfo: null,
  channelUsers: [],
  selectedChannelInfo: null,
}

export const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setChannels(state, actions) {
      state.channels = actions.payload
    },
    setChannelInfo(state, actions) {
      state.channelInfo = actions.payload
    },
    setChannelUsers(state, actions) {
      state.channelUsers = actions.payload
    },
    setSelectedChannelInfo(state, actions) {
      state.selectedChannelInfo = actions.payload
    },
  },
})

export const {
  setChannels,
  setChannelInfo,
  setChannelUsers,
  setSelectedChannelInfo,
} = channelSlice.actions

export default channelSlice.reducer
