import { useContext, useEffect, useMemo, useState } from 'react'

import MapContext from 'contexts/map'
import useWindowSize from 'lib/hooks/useWindowSize'

import MapRPMarkerItem from './MapRPMarkerItem'
import './MapRPMarkerItem.scss'
import MapLayerRPPAvg from './MapLayer/MapLayerRPPAvg'
import { filterParams } from 'utils'
import { getMarkers } from 'lib/api/layerApi'
import useAxios from 'lib/hooks/useAxios'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'

/**
 * 실거래가 레이어
 * @returns MapRPMarkerItem 실거래가 마커 컴포넌트
 */
const MapRPMarkerContainer = ({ resetNotePolygon, hide }) => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { viewState, filterState } = state
  const [onRequest, , data, , ,] = useAxios(getMarkers)
  const [overlays, setOverlays] = useState([]) // 마커 배열

  const size = useWindowSize()

  // 화면상에 표현할 마커의 최대 수 (나누는 수가 높을수록 작아짐)
  const limit = (size.width * size.height) / 200000

  const centerPoint = useSelector((state) => state.summary.centerPoint)
  const isSummaryPage = useRouteMatch({
    path: ['/:id'],
  })

  const { isJMapNote } = useNoteMatch()
  const isNoteListUrl = useRouteMatch('/note/v2/notes/:channelSrl')

  /**
   * @METHOD: 최초 마운트 시와 좌표 정보가 담긴 viewState의
   * 변화를 감지하여 마커를 그리는 로직.
   */
  useEffect(() => {
    //매물관리 페이지 아니면 매물노트에서는 마커 get api 호출하지 않음
    if (
      localStorage.getItem('notemode') != 'card' &&
      isJMapNote &&
      !isNoteListUrl?.isExact
    )
      return

    //summary 페이지인 경우 summary 데이터가 바인딩 된 후 마커 로드(중복 방지)
    if (isSummaryPage && isSummaryPage?.isExact) {
      if (isSummaryPage.url != '/home' && !centerPoint) {
        return
      }
    }
    // 줌 레벨이 4 '초과'일 경우 오버레이를 초기화하고 중단
    if (viewState?.level > 4) {
      setOverlays([])
      // console.log('마커 중단');
      return
    }
    // 실거래가 전용 필터 파라미터 포맷
    let real_price = []
    if (
      filterState.real_price &&
      (filterState.real_prices.real_price[0] !== 0 ||
        filterState.real_prices.real_price[1] !== 50)
    ) {
      real_price = filterState.real_prices.real_price.map((price) => {
        return parseInt(price) * 100000000
      })
    }
    let real_lunit_price = []
    if (
      filterState.real_prices.real_lunit_price[0] !== 0 ||
      filterState.real_prices.real_lunit_price[1] !== 50
    ) {
      real_lunit_price = filterState.real_prices.real_lunit_price.map(
        (price) => {
          return parseInt(price) * 1000000
        },
      )
    }
    let real_bunit_price = []
    if (
      filterState.real_prices.real_bunit_price[0] !== 0 ||
      filterState.real_prices.real_bunit_price[1] !== 50
    ) {
      real_bunit_price = filterState.real_prices.real_bunit_price.map(
        (price) => {
          return parseInt(price) * 1000000
        },
      )
    }

    const payload = {
      swlat: viewState.swLatlng.getLat(),
      swlng: viewState.swLatlng.getLng(),
      nelat: viewState.neLatlng.getLat(),
      nelng: viewState.neLatlng.getLng(),
      level: viewState.level,

      // common filter options
      ...filterParams(filterState),
      real_price,
      real_lunit_price,
      real_bunit_price,
    }

    onRequest(payload, null, false)
  }, [
    viewState,
    filterState?.real_prices?.appraisal_price,
    filterState?.real_prices?.auction_date,
    filterState?.real_prices?.contract_date,
    filterState?.real_prices?.land_type,
    filterState?.real_prices?.price_property_type?.length,
    filterState?.real_prices?.real_bunit_price,
    filterState?.real_prices?.real_lunit_price,
    filterState?.real_prices?.real_price,
    filterState?.real_prices?.winning_bid,
    isNoteListUrl?.isExact,
  ])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result) return

    const newOverlays = data.result

    // 기존 데이터에 덮어씌운다.
    setOverlays(newOverlays)
  }, [data])

  /**
   * @INFO: axios 요청은 항상 비동기이기 때문에
   * 아래의 초기화 코드가 가장 먼저 발생합니다.
   */
  useEffect(() => {
    setOverlays([])
  }, [
    filterState?.real_prices?.appraisal_price,
    filterState?.real_prices?.auction_date,
    filterState?.real_prices?.contract_date,
    filterState?.real_prices?.land_type,
    filterState?.real_prices?.price_property_type,
    filterState?.real_prices?.real_bunit_price,
    filterState?.real_prices?.real_lunit_price,
    filterState?.real_prices?.real_price,
    filterState?.real_prices?.winning_bid,
  ])

  // @METHOD: fetchData를 참조하여 마커 데이터 매핑
  const computedMarkers = (items) => {
    // 배열을 시간순으로 정렬 (오름차순)
    const sortedItems = items.sort((a, b) => {
      const dateA = parseInt(a.contract_date)
      const dateB = parseInt(b.contract_date)
      return dateA < dateB ? 1 : -1
    })
    let contents = []
    let marker_count = 0

    sortedItems.map((item, idx) => {
      let marker_type = 'customoverlay'
      let nearby_marker_exists = false
      let nearby_distance = 0.0007

      if (state.viewState.level === 2) {
        nearby_distance = 0.0004
      } else if (state.viewState.level === 1) {
        nearby_distance = 0.0002
      }

      // 마커와 점을 포함한 모든 데이터의 개수를 제한합니다
      if (viewState?.level >= 3 && idx > limit * 4) return

      // 마커 갯수를 초과하면 나머지는 점으로 표시합니다
      if (marker_count > limit) {
        marker_type = 'dotoverlay'
      }

      // 근거리에 다른 마커가 이미 있으면 점으로 표시합니다
      contents.map((item2, idx2) => {
        if (
          item2.marker_type === 'customoverlay' &&
          item2.lat > item.latitude - nearby_distance &&
          item2.lat < item.latitude + nearby_distance &&
          item2.lon > item.longitude - nearby_distance &&
          item2.lon < item.longitude + nearby_distance
        ) {
          nearby_marker_exists = true
        }
      })

      // 커스텀 오버레이가 표시될 위치입니다
      let overlayPosition = new kakao.maps.LatLng(
        item.latitude,
        item.longitude,
      )

      if (nearby_marker_exists) {
        marker_type = 'dotoverlay'
        // overlayPosition = new kakao.maps.LatLng(
        //   item.latitude,
        //   item.longitude,
        // );
      }

      contents.push({
        id: item.id,
        position: overlayPosition,
        marker_type: marker_type,
        lat: item.latitude,
        lon: item.longitude,
        property_type: item.property_type,
        contract_date: item.contract_date,
        price: item.price,
        land_area: item.land_area,
        apt_name: item.apt_name,
        exclusive_area: item.exclusive_area,
      })
    })

    // 최신이 위로 가도록 reverse()
    return contents.reverse()
  }
  // 필지 단위 실거래가 레이어를 저장할 배열
  const markers = useMemo(() => computedMarkers(overlays), [overlays])
  // 동단위 실거래가 레이어를 저장할 배열
  const [dongNames, setDongNames] = useState([])

  if (hide) return null

  return (
    <>
      {markers.map((item) => (
        <MapRPMarkerItem
          item={item}
          key={item.id}
          resetNotePolygon={resetNotePolygon}
        />
      ))}
      <MapLayerRPPAvg
        dongNames={dongNames}
        setDongNames={setDongNames}
      />
    </>
  )
}

export default MapRPMarkerContainer
