import { Button } from 'components/design'
import NoteMainWrapper from '../style/NoteMainWrapper'
import { MaxWidthWrapper } from '../style/NoteStyle'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'
import theme, { media } from 'lib/styles/theme'
import {
  Block,
  BlockItem,
  ButtonWrapper,
} from './NotePaymentAllInOneCancelConfirmStep1'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 올인원 전용
 * 올인원 해지 완료 안내
 * @returns
 */
const NotePaymentAllInOneCancelDone = ({ endDone }) => {
  const history = useHistory()

  const goPaymentList = () => {
    endDone()
  }

  const goPaymentMain = () => {
    history.push(`/note/v2/payment`)
  }

  return (
    <StyledNoteMainWrapper gray single singleM>
      <Scrollbars autoHide>
        <StyledMaxWidthWrapper>
          <Wrapper>
            <Title>
              올인원 해지 신청이
              <br />
              완료되었습니다.
              <SubTitle>
                보다 나은 서비스로 다시 만나뵐 수 있기를 바랍니다
              </SubTitle>
            </Title>
            <Block>
              <BlockItem>
                모든 네이버 매물의 <span>광고 게시가 중단</span>{' '}
                되었습니다.
              </BlockItem>
              <BlockItem>
                검증 중이던 매물이 <span>검증 취소</span>되었습니다.
              </BlockItem>
              <BlockItem>
                <span>올인원을 다시 시작</span>하면 이전 이용 내역을{' '}
                <span>그대로 이어서 사용</span>하실 수 있습니다.
              </BlockItem>
            </Block>
            <ButtonWrapper>
              <Button
                color="gray"
                size="xlarge"
                onClick={goPaymentList}
              >
                올인원 다시 시작
              </Button>
              <Button
                color="blue"
                size="xlarge"
                onClick={goPaymentMain}
              >
                확인
              </Button>
            </ButtonWrapper>
          </Wrapper>
        </StyledMaxWidthWrapper>
      </Scrollbars>
    </StyledNoteMainWrapper>
  )
}

const StyledNoteMainWrapper = styled(NoteMainWrapper)`
  height: 100%;
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.base.white};
  padding: 60px 40px 100px 40px;
  margin: 0 auto;
  height: auto;
  min-height: 100%;

  ${media.mediumM`
    width: 100%;
    padding: 60px 20px 100px 20px;
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 510px;
`

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  color: ${theme.colors.gray[750]};
`

const SubTitle = styled.div`
  margin-top: 6px;
  color: ${theme.colors.gray[600]};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
`

export default NotePaymentAllInOneCancelDone
