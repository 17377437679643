import { useCallback, useContext, useState } from 'react'
import MapContext from 'contexts/map'

import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

const ButtonWrap = styled.div`
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: ${theme.shadow['float']};

  @media screen and (max-height: 800px) {
    display: none;
  }

  ${media.mediumS`
    display: none;
  `}
`

const Button = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;

  color: ${theme.colors.base.white};
  font-size: 1rem;
  outline: none;
  border: none;
  appearance: none;
  background-color: ${theme.colors.gray[800]};

  transition: 0.2s ease;

  &:hover {
    background-color: ${theme.colors.gray[800]};
  }
  &:active {
    background-color: ${theme.colors.gray[700]};
  }

  &:first-child {
    /* border-radius: 8px 8px 0 0; */
    border-bottom: 1px solid ${theme.colors.gray[800]};
  }
  &:last-child {
    /* border-radius: 0 0 8px 8px; */
  }
`

const ButtonIcon = styled.svg`
  display: block;
  width: 24px;
  height: 24px;
`

const MapControlZoom = () => {
  const { state } = useContext(MapContext)
  const { map } = state

  const setZoomIn = (zoom) => {
    const level = map.getLevel()
    const levelLimit = 6

    let newLevel = level

    if (zoom === 'in' && level !== 1) {
      // 레벨이 최소값인 1이 아닐 때에만 확대 가능
      newLevel = level - 1
    } else if (zoom === 'out' && level < levelLimit) {
      // 최대 6레벨까지만 축소 가능
      newLevel = level + 1
    }

    map.setLevel(newLevel, { animate: true })
  }

  const onClickZoomIn = () => {
    setZoomIn('in')
  }
  const onClickZoomOut = () => {
    setZoomIn('out')
  }

  return (
    <ButtonWrap>
      <Button onClick={onClickZoomIn}>
        <ButtonIcon
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </ButtonIcon>
      </Button>
      <Button onClick={onClickZoomOut}>
        <ButtonIcon
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M18 12H6"
          />
        </ButtonIcon>
      </Button>
    </ButtonWrap>
  )
}

export default MapControlZoom
