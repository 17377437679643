import { getMyImagesByDate } from 'lib/api/notev2/dashboard/dashboard'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NoteDashboardPictureContainer = ({ children }) => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const channels = useSelector((state) => state.channel.channels)
  const history = useHistory()
  const [onRequest, , data] = useAxios(getMyImagesByDate)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedPictures, setSelectedPictures] = useState([])

  const [dates, setDates] = useState([])
  const [resultObj, setResultObj] = useState(null)

  useEffect(() => {
    onRequest()
  }, [])

  useEffect(() => {
    if (!data) return
    if (!data?.result) return

    const result = data.result
    const datesArr = Object.keys(result)

    //날짜 오름차순 정렬이라서 마지막게 제일 최근 것
    setDates(datesArr)
    setResultObj(result)
    setSelectedDate(datesArr[datesArr.length - 1])
    setSelectedPictures(result[[datesArr[datesArr.length - 1]]])
  }, [data])

  /**
   * 날짜 이전 버튼
   */
  const onClickPrev = () => {
    const currIdx = dates.findIndex((date) => date == selectedDate)
    setSelectedPictures(Object.values(resultObj)[currIdx - 1])
    setSelectedDate(dates[currIdx - 1])
  }

  /**
   * 날짜 다음 버튼
   */
  const onClickNext = () => {
    const currIdx = dates.findIndex((date) => date == selectedDate)
    setSelectedPictures(Object.values(resultObj)[currIdx + 1])
    setSelectedDate(dates[currIdx + 1])
  }

  const onClickMore = () => {
    const myChannels = channels.filter(
      (e) => e.member_srl == loginInfo.member_srl,
    )
    const privateChannel = channels.find((e) => e.is_private == 'Y')
    const channelSrl =
      channelInfo?.channel_srl ||
      privateChannel?.channel_srl ||
      myChannels?.[0]?.channel_srl ||
      ''
    history.push(`/note/v2/pictures/${channelSrl}`)
  }

  return children({
    dates,
    selectedDate,
    selectedPictures,
    onClickPrev,
    onClickNext,
    onClickMore,
  })
}

export default NoteDashboardPictureContainer
