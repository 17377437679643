import { useCallback, useState, useEffect } from 'react'
import useAxios from '../useAxios'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { simpleUpdateAd } from 'lib/api/notev2/ad'
import { message } from 'antd'
import useCreateAdWithNote from './useCreateAdWithNote'
import usePrivateChannel from './usePrivateChannel'

/**
 * 광고관리 그리드
 * 매물을 수정한다
 * @returns 매물 수정 함수
 *
 * * onModify({ params, updateData, rowNode }): 기존 그리드 데이터 전체, 업데이트할 데이터
 *   -> rowNode 데이터가 있으면, 저장 요청의 응답이 성공이면 그리드 업데이트까지 한번에 같이한다
 * * isError: 에러가 났는지 여부
 *
 */
function useModifyAd() {
  const planInfo = useSelector((state) => state.auth.planInfo)
  //TODO: 업데이트 api
  const [onRequest, , data, error] = useAxios(simpleUpdateAd, {
    resetError: true,
  })
  const [onCreate] = useCreateAdWithNote()
  const [isError, setIsError] = useState(false)
  const [rowNode, setRowNode] = useState(null)
  const [newData, setNewData] = useState(null)
  const [mergedData, setMergedData] = useState(null)
  const { isReadOnlyChannel } = usePrivateChannel()

  /**
   * 노트 저장
   * @param channel_srl 채널 srl
   * @param address_srl 주소 srl
   * @param form 주소 정보
   */
  const onModify = useCallback(
    ({ data, updateData, currentRowNode }) => {
      if (isReadOnlyChannel) return

      if (currentRowNode) {
        setRowNode(currentRowNode)
        setNewData(updateData)
      }

      setMergedData(Object.assign({}, data, updateData))

      //노트 저장 api
      onRequest({
        plan_srl: planInfo.allinone_plan_srl,
        ...data,
        ...updateData,
      })
    },
    [],
  )

  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.error('modify note 에러')
      setIsError(true)
      return
    }

    if (rowNode) {
      //grid
      updateGridDatas({
        newData: Object.assign({}, newData, {
          last_update: moment().format('YYYY-MM-DD HH:mm:ss'),
        }),
        currentRowNode: rowNode,
      })
    }

    //광고용 값이 다 채워졌으면 광고, 노트 동시 생성
    fillCompleteCreate()
  }, [data])

  useEffect(() => {
    if (!error) return
    message.error('저장 중 오류가 발생했습니다.')
  }, [error])

  /**
   * 가격 구분 별 key 얻기
   * @return {boolean} 가격 채워졌는지 여부
   */
  const getPriceHas = (data, type) => {
    if (type == '매매') {
      return data?.sale_price
    } else if (type == '전세') {
      return data?.deposit
    } else if (type == '월세') {
      return data?.deposit && data?.rent
    } else {
      return false
    }
  }

  /**
   * 광고용 값이 다 채워졌으면 광고, 노트 동시 생성
   * 주소, 종류, 거래구분, 가격이 모두 채워지면 호출
   */
  const fillCompleteCreate = () => {
    if (mergedData) {
      if (mergedData.already_created == 'Y') return
      const isFillComplete =
        mergedData?.address_id &&
        mergedData?.property_type &&
        mergedData?.contract_type &&
        getPriceHas(mergedData, mergedData?.contract_type)

      if (isFillComplete) {
        onCreate(mergedData, rowNode)
      }
    }
  }

  //return [onModify, isError, data]
  return [onModify]
}

export default useModifyAd
