import { useContext, useEffect, useRef } from 'react'
import MapContext from 'contexts/map'
import { billionFormate, dateFormatForMarker } from 'utils'

import classnames from 'classnames'

const MapAuctionMarkerItem = ({ item }) => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map } = state
  const markerRef = useRef()

  const isSelected = item.id === state.clickPosition.address_id

  let marker = {}

  useEffect(() => {
    let position = {
      latitude: item.lat,
      longitude: item.lon,
    }

    if (item.marker_type === 'customoverlay') {
      position = {
        // latitude: item.lat + 0.00002,
        latitude: item.lat,
        // longitude: item.lon + 0.00009,
        longitude: item.lon,
      }
    }

    let content = markerRef.current
    let payload = {
      map: map,
      clickable: true,
      position: new kakao.maps.LatLng(
        position.latitude,
        position.longitude,
      ),
      content: content,
      yAnchor: 1,
    }
    marker = new kakao.maps.CustomOverlay(payload)

    // 타입이 dotoverlay일 경우 마커 자체를 지운 후 다시 그림
    // 클릭한 마커와 현재 마커가 동일할 경우 다시 그림
    if (item.marker_type === 'dotoverlay' && isSelected) {
      marker.setMap(null)
    }

    // 마커를 맵에 그림
    marker.setMap(map)

    if (isSelected) {
      // 선택된 마커일 경우 z-index를 높임
      marker.setZIndex(4)
    } else if (item.marker_type === 'customoverlay') {
      // 마커 타입이 커스텀오버레이일 경우 z-index를 높임
      marker.setZIndex(3)
    } else {
      marker.setZIndex(2)
    }

    return () => {
      // unmount될 때 지도상에서 제거
      marker.setMap(null)
    }
  }, [])

  return (
    <div>
      {/* 타입이 dotoverlay이며 선택되지 않은 마커일 경우 */}
      {item.marker_type === 'dotoverlay' && !isSelected ? (
        <div className="map-marker-dot-wrapper" ref={markerRef}>
          <div className={classnames('map-marker-dot')}></div>
        </div>
      ) : (
        <div
          className={classnames(
            'map-marker-item',
            isSelected && 'active',
            'map-marker-item--gray',
            'auction',
          )}
          ref={markerRef}
        >
          <span className="map-marker-item__property auction">
            경매-{item.purpose && item.purpose}
            <br />
            {/* {item.breakdown && item.breakdown} */}
            최소가{' '}
            {item.min_selling_price &&
              billionFormate(item.min_selling_price)}
          </span>

          <div className="map-marker-item-icon">
            <i className="far fa-gavel"></i>
          </div>

          <div className="map-marker-item-body">
            <div className="map-marker-item__head">
              {item.price && (
                <span className="map-marker-item__price">
                  {billionFormate(item.price)}
                </span>
              )}
            </div>

            {item.sale_deadline && (
              <div className="map-marker-item__date">
                {dateFormatForMarker(item.sale_deadline)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default MapAuctionMarkerItem
