import { Modal } from 'components/design'

const SummaryMasterModal = ({ visible, closeModal }) => {
  return (
    <Modal
      visible={visible}
      title="추천 건축 전문가"
      cancelHide
      onCancel={closeModal}
      onOk={closeModal}
    >
      JOOTEK에 입점한 업체 중에서 신뢰할 수 있는 전문가들을 엄선하여
      소개드려요.
    </Modal>
  )
}

export default SummaryMasterModal
