import { Button, Empty, Tag } from 'components/design'
import { LabelWithTitle } from './NoteDetailStyle'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import NoteDetailGongbuIssuanceListContainer from 'containers/notev2/NoteDetailGongbuIssuanceListContainer'
import { Skeleton } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'

/**
 * 매물 3뎁스 공부문서 탭
 * 목록
 */
const NoteDetailGongbuIssuanceList = ({
  docs,
  searchGongbu,
  loadingGoungbu,
  scrollRef,
}) => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  return (
    <NoteDetailGongbuIssuanceListContainer
      searchGongbu={searchGongbu}
      scrollRef={scrollRef}
    >
      {({
        isWorking,
        getStatusKor,
        onClickReadDoc,
        onClickDelete,
        readDoc,
      }) => {
        return (
          <>
            <LabelWithTitle>
              <TitleInner>발급 내역</TitleInner>
              <Tag color="gray" size="xs">
                {docs?.length}개
              </Tag>
            </LabelWithTitle>
            {loadingGoungbu ? (
              <Skeleton />
            ) : docs && docs.length > 0 ? (
              docs?.map((item, i) => {
                return (
                  <ListItem ispending={isWorking(item.status)} key={i}>
                    <Title>
                      <>
                        {item.doc_type != '토지이용계획원' &&
                          item.sub_type}{' '}
                        {item.doc_type}
                      </>
                      {(isWorking(item.status) ||
                        ['error'].includes(item.status)) && (
                        <Status
                          status={
                            isWorking(item.status) ? 'working' : 'error'
                          }
                        >
                          <ErrorStatusMsg>{item.error}</ErrorStatusMsg>
                          {getStatusKor(item.status)}
                          {getStatusKor(item.status) == '발급중' && (
                            <LoadingDots>
                              <span className="dot-one"> .</span>
                              <span className="dot-two"> .</span>
                              <span className="dot-three"> .</span>
                            </LoadingDots>
                          )}
                        </Status>
                      )}
                    </Title>
                    {item.status == 'complete' && (
                      <RegDate>
                        발급일&nbsp;
                        {item.regdate &&
                          moment(item.regdate, 'YYYYMMDDHHmmss').format(
                            'YYYY.MM.DD HH:mm',
                          )}
                      </RegDate>
                    )}
                    <Address>{item.title}</Address>
                    {item.doc_type == '등기부등본' && (
                      <Malso>{item.is_valid}</Malso>
                    )}

                    <ListButtonWrapper>
                      {item.status == 'complete' && (
                        <Button
                          size="small"
                          color="blue"
                          onClick={() =>
                            onClickReadDoc(item, item.doc_type)
                          }
                        >
                          열람
                        </Button>
                      )}
                      {item.url && (
                        <Button size="small" color="default">
                          <DownloadLink
                            href={item.url}
                            download={item.title}
                          >
                            PDF 다운로드
                          </DownloadLink>
                        </Button>
                      )}
                      {channelInfo?.member_type != 'readonly' && (
                        <Button
                          onClick={() =>
                            onClickDelete({
                              channel_srl: item.channel_srl,
                              note_srl: item.note_srl,
                              doc_srl: item.doc_srl,
                            })
                          }
                          size="small"
                          color="danger"
                          plain
                        >
                          삭제
                        </Button>
                      )}
                    </ListButtonWrapper>
                  </ListItem>
                )
              })
            ) : (
              <Empty>공부문서 발급내역이 없습니다</Empty>
            )}
            {/* 열람 */}
            {readDoc}
          </>
        )
      }}
    </NoteDetailGongbuIssuanceListContainer>
  )
}

const Status = styled.span`
  display: flex;
  align-items: center;

  margin-left: 16px;
  font-weight: 500;
  ${({ status }) => {
    switch (status) {
      case 'working':
        return `color: ${theme.colors.primary[400]};`
      case 'error':
        return `color: ${theme.colors.tint.danger};`
    }
  }}
`

const ListButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 9px;
  width: 100%;
  margin-top: 20px;
`

const Malso = styled.span`
  font-weight: 400;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
`

const RegDate = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.blue[400]};
`

const DownloadLink = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
`

const LoadingDots = styled.div`
  span[class^='dot-'] {
    opacity: 0;
  }
  .dot-one {
    animation: dot-one 1.8s infinite linear;
  }
  .dot-two {
    animation: dot-two 1.8s infinite linear;
  }
  .dot-three {
    animation: dot-three 1.8s infinite linear;
  }

  @keyframes dot-one {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot-two {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot-three {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`

const ErrorStatusMsg = styled.div`
  position: absolute;
  color: ${theme.colors.gray[600]};
  left: 12px;
  bottom: 20px;
  font-size: 12px;
`

const TitleInner = styled.div`
  margin-right: 8px;
`

export const ListItem = styled.div`
  position: relative;
  width: 348px;
  border: 1px solid ${theme.colors.gray[200]};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  ${({ ispending }) =>
    ispending &&
    `
    ${Title} {
      color: ${theme.colors.gray[600]};
    }
    ${Address} {
      color: ${theme.colors.gray[400]};
    }
  `}

  ${media.mediumS`
    width: 100%;
  `}
`

export const Title = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 16px;
`

const Address = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
`

export default NoteDetailGongbuIssuanceList
