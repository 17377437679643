import { useContext } from 'react'
import { getComputedArea, numberWithCommas } from 'utils/index'
import MapContext from 'contexts/map'
import {
  Label,
  LabelIcon,
  Row,
  RowWrapper,
  Value,
} from 'components/summary/style/SummaryStyle'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const SummaryLobInfoTotal = ({ data, features }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  return (
    <RowWrapper>
      {data.building_name && (
        <Row>
          <LabelIcon className="fal fa-house" />
          <Label>건물이름</Label>
          <Value>{data.building_name}</Value>
        </Row>
      )}
      {data.purpose_code_name && (
        <Row>
          <LabelIcon className="fal fa-house-user" />
          <Label>주용도</Label>
          <Value>{data.purpose_code_name}</Value>
        </Row>
      )}
      {data.other_purpose && (
        <Row>
          <LabelIcon className="fal fa-home-lg-alt" />
          <Label>기타용도</Label>
          <Value>{data.other_purpose}</Value>
        </Row>
      )}
      {data.land_area && (
        <Row>
          <LabelIcon className="fal fa-expand" />
          <Label>대지면적</Label>
          <Value>
            {getComputedArea(data.land_area, isSquareMeter)}
          </Value>
        </Row>
      )}
      {data.building_area && (
        <Row>
          <LabelIcon className="fal fa-expand" />
          <Label>건축면적</Label>
          <Value>
            {getComputedArea(data.building_area, isSquareMeter)}
          </Value>
        </Row>
      )}
      {data.total_floor_area && (
        <Row>
          <LabelIcon className="fal fa-clone" />
          <Label>연면적</Label>
          <Value>
            {getComputedArea(data.total_floor_area, isSquareMeter)}
          </Value>
        </Row>
      )}
      {data?.total_floor_area_2 > 0 && (
        <Row>
          <LabelIcon className="fal fa-clone" />
          <Label>용적률산정연면적</Label>
          <Value>
            <Value>
              {isSquareMeter
                ? `${numberWithCommas(
                    Math.round(data.total_floor_area_2),
                  )}㎡`
                : `${numberWithCommas(
                    Math.round(data.total_floor_area_2 / 3.305),
                  )}평`}
            </Value>
          </Value>
        </Row>
      )}
      <Row height={30}>
        <LabelIcon className="fal fa-users" />
        <Label>세대수</Label>
        <Value>
          <HouseHoldWrapper>
            <HouseHold>
              <HouseHoldTitle>세대</HouseHoldTitle>
              <HouseHoldValue>
                {data.household_count ? (
                  numberWithCommas(data.household_count)
                ) : (
                  <span>&ndash;</span>
                )}
              </HouseHoldValue>
            </HouseHold>
            <HouseHold>
              <HouseHoldTitle>가구</HouseHoldTitle>
              <HouseHoldValue>
                {data.unit_count ? (
                  numberWithCommas(data.unit_count)
                ) : (
                  <span>&ndash;</span>
                )}
              </HouseHoldValue>
            </HouseHold>
            <HouseHold>
              <HouseHoldTitle>호수</HouseHoldTitle>
              <HouseHoldValue>
                {data.room_count ? (
                  numberWithCommas(data.room_count)
                ) : (
                  <span>&ndash;</span>
                )}
              </HouseHoldValue>
            </HouseHold>
          </HouseHoldWrapper>
        </Value>
      </Row>
      {data.extra_land_count && (
        <Row>
          <LabelIcon className="fal fa-expand" />
          <Label>외필지수</Label>
          <Value>{data.extra_land_count}</Value>
        </Row>
      )}
      {features}
    </RowWrapper>
  )
}

const HouseHoldWrapper = styled.div`
  display: flex;
  gap: 20px;
`

const HouseHold = styled.div`
  display: flex;
  flex-direction: column;
`

const HouseHoldTitle = styled.div`
  color: ${theme.colors.gray[700]};
  font-size: 12px;
`

const HouseHoldValue = styled.div`
  line-height: 120%;
`

export default SummaryLobInfoTotal
