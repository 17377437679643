import { useEffect, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import FirstUserPopup from 'components/jmapnote/channel/FirstUserPopup'
import { useDispatch } from 'react-redux'
import { message } from 'antd'
import { useRouteMatch } from 'react-router'
import { setNoteContacts, setNoteOptions } from 'modules/notev2'
import { getOptions } from 'lib/api/note/noteApi'
import { getContacts } from 'lib/api/note/contactApi'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'

const NoteContainer = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [firstUserPopupVisible, setFirstUserPopupVisibie] =
    useState(false) // 채널 없을때 팝업 띄울지 여부

  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const channels = useSelector((state) => state.channel.channels)

  const [onRequestGetOption, , dataOptions] = useAxios(getOptions)
  const [onRequestGetContacts, , dataContacts] = useAxios(getContacts)

  const match = useRouteMatch({
    path: [
      '/note/v2/:pageType/:channelSrl?',
      '/note/v2/ad/:adPage/:noteSrl',
      '/note/v2/payment',
      '/note/v2/calendar',
      '/note/v2/customer',
      '/note/v2/pictures',
      '/note/v2/pictures/:channelSrl',
    ],
  })

  const matchAdPage = useRouteMatch({
    path: ['/note/v2/ad/:adPage?/:noteSrl?'],
  })

  const match2Depth = useRouteMatch({
    path: ['/note/v2/docs/:channelSrl/:addressSrl/edit/:contractSrl?'],
  })

  useEffect(() => {
    onRequestGetOption()
    onRequestGetContacts()
  }, [])

  useEffect(() => {
    if (!dataOptions || !dataOptions.options) return

    dispatch(setNoteOptions(dataOptions.options))
  }, [dataOptions])

  useEffect(() => {
    if (match?.params?.pageType == 'payment') {
      return
    }

    if (match2Depth) {
      return
    }

    localStorage.setItem('notemode', 'sheet')

    handleRedirect({
      channelSrl: match?.params?.channelSrl,
      pageType: match?.params?.pageType,
    })
  }, [])

  useUpdateEffect(() => {
    if (!match?.params?.pageType) return
    handleRedirect({
      channelSrl: match?.params?.channelSrl,
      pageType: match?.params?.pageType,
    })
  }, [match?.params?.pageType])

  useEffect(() => {
    if (!dataContacts || !dataContacts.result) return

    dispatch(setNoteContacts(dataContacts.result))
  }, [dataContacts])

  /**
   * @description 비로그인 상태에서 접근 또는 부동산 중개업자가 아닐 경우 지도 메인으로 replace 시킴.
   *
   */
  useEffect(() => {
    if (
      loginInfo &&
      (!loginInfo?.member_srl ||
        loginInfo?.member_type !== 'realestate')
    ) {
      history.replace('/')
      return
    }
  }, [loginInfo])

  /**
   *  @description 중개사로 로그인 했지만, 폴더와 채널 srl이 없을 경우 기본 폴더와 채널로 redirect 시킨다
   *  @exampleUrl /map/note
   */
  const handleRedirect = ({ channelSrl, pageType }) => {
    // 사용자가 생성한 채널이 없을 경우 채널 생성 팝업 출력
    if (channels.length === 0) {
      setFirstUserPopupVisibie(true)
      return
    }

    const myChannels = channels.filter(({ member_srl }) => {
      return member_srl == loginInfo.member_srl
    })

    if (myChannels.length === 0) {
      setFirstUserPopupVisibie(true)
      return
    }

    //url이 매치되지 않은 경우 dashboard로 리턴
    if (!match) {
      if (channels[0] && channels[0]?.channel_srl) {
        history.push(`/note/v2/notes/${channels[0]?.channel_srl}`)
      } else {
        return
      }
      localStorage.setItem('notemode', 'sheet')
      return
    }

    const recentChannelSrl = localStorage.getItem('recentChannelSrl')
    /**
     * 1. 파라미터의 channel srl 검증
     * 2. 파라미터 없으면 localstorage에 저장된 channel srl 검증
     * 3. 파라미터도 없고  localstorage에도 없으면 자신 채널들의 첫번째걸로 세팅 + localstorage에도 저장
     */

    //검증 안하는 페이지
    if (
      ['payment', 'account', 'calendar', 'customer', 'dongho'].includes(
        pageType,
      )
    )
      return

    //TODO: 네이버 광고 등록은 매물 등록이 선행되어야 하므로 매물이 존재하는지 검증해야함
    if (pageType == 'ad') {
      if (
        matchAdPage?.params?.adPage == 'link' &&
        !matchAdPage?.params?.noteSrl
      ) {
        message.info(
          '잘못된 접근입니다. 광고관리 메인 페이지로 이동합니다.',
        )
        history.push(`/note/v2/ad`)
        return
      }
      return
    }

    if (channelSrl) {
      //주소에 채널이 붙은 경우
      //파라미터로 넘어온 채널 유효성 검증
      const authorizedChannelSrl = channels.filter(
        ({ channel_srl }) => channel_srl === parseInt(channelSrl),
      )[0]?.channel_srl

      if (!authorizedChannelSrl) {
        //파라미터 채널이 내 채널이 아닌 경우, 채널 목록에서 첫번째 채널로 이동함
        const myChannel = channels?.filter(
          ({ member_srl }) =>
            member_srl === parseInt(loginInfo.member_srl),
        )[0]

        if (
          myChannel &&
          Object.keys(myChannel).length > 0 &&
          myChannel?.channel_srl
        ) {
          history.push({
            pathname: `/note/v2/${pageType || 'notes'}/${
              myChannel.channel_srl
            }`,
          })
          localStorage.setItem(
            'recentChannelSrl',
            myChannel.channel_srl,
          )
        } else {
          return
        }
      }
    } else if (recentChannelSrl && recentChannelSrl !== 'undefined') {
      //주소에 채널이 안붙은 경우
      // 최근 접속한 채널/폴더 정보가 있을 경우 유효성 체크 후 이동시킵니다.
      const authorizedChannelSrl = channels.filter(
        ({ channel_srl }) => channel_srl === parseInt(recentChannelSrl),
      )[0]?.channel_srl

      if (!authorizedChannelSrl) {
        //최근 접속한 채널이 자신 채널 리스트에 없는 경우 - 첫 번째 채널로 이동시킴
        history.push({
          pathname: `${history.location.pathname.replace(
            /\/\s*$/,
            '',
          )}/${channels[0].channel_srl}`,
        })
        return
      }
      if (pageType == 'pictures' || pageType == 'notes') {
        history.push({
          pathname: `${history.location.pathname.replace(
            /\/\s*$/,
            '',
          )}/${authorizedChannelSrl}`,
        })
      }
    } else {
      /**
       * 최근 접속한 채널/폴더 정보가 없을 경우 채널 목록에서
       * 로그인한 회원의 member_srl값과 동일한 채널의 폴더 목록으로 이동
       */
      const myChannel = channels?.filter(
        ({ member_srl }) =>
          member_srl === parseInt(loginInfo.member_srl),
      )[0]

      if (!myChannel.channel_srl) {
        return
      }

      if (pageType == 'pictures' || pageType == 'notes') {
        history.push({
          pathname: `${history.location.pathname.replace(
            /\/\s*$/,
            '',
          )}/${myChannel.channel_srl}`,
        })
      }
    }
  }

  /**
   * @param {string} channelSrl
   * @param {string} pageType
   * @description 인자로 받은 channelSrl, pageType을 바탕으로
   * 링크를 생성하고 이동시킵니다.
   */
  const moveToPageWithChannel = (channelSrl) => {
    if (!channelSrl) {
      message.error(
        '일시적인 문제가 발생했습니다. 채널 변경을 시도하거나 관리자에게 문의하십시오.',
      )
      return
    }

    //모드 변경
    localStorage.setItem('notemode', 'sheet')

    //최근 접속 채널 세팅
    localStorage.setItem('recentChannelSrl', channelSrl)

    //유효한 링크로 이동
    const pageType = match?.params?.pageType

    //결제관리, 계정관리 - 채널 생성 후 redirect 막음
    if (pageType == 'payment' || pageType == 'account') {
      return
    } else {
      history.push({
        pathname: `/note/v2/${match?.params?.pageType}/${channelSrl}`,
        state: location?.state,
      })
    }
  }

  return children({
    popup: (
      <FirstUserPopup
        visible={firstUserPopupVisible}
        setVisible={setFirstUserPopupVisibie}
        onSuccess={moveToPageWithChannel}
      />
    ),
  })
}

export default withRouter(NoteContainer)
