import FixedLayer from 'components/common/FixedLayer/FixedLayer'
import NoteDetailForm from 'components/jmapnotev2/detail/NoteDetailForm'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import MapFavoriteNoteDetailContainer from 'containers/map/MapFavoriteNoteDetailContainer'
import { Skeleton, Space } from 'antd'
import styled from 'styled-components'
import NoteDetailFormHeaderBar from 'components/jmapnotev2/detail/NoteDetailFormHeaderBar'

const MapFavoriteNoteDetail = ({
  visible,
  data,
  onClose,
  hasDimmer = false,
}) => {
  return (
    <MapFavoriteNoteDetailContainer
      visible={visible}
      params={data}
      onClose={onClose}
    >
      {({ methods, loading, onCloseLayer }) => (
        <FormProvider {...methods}>
          <FixedLayer
            grayheader
            visible={visible}
            onClose={onCloseLayer}
            hasDimmer={hasDimmer}
            outSideClickClose={true}
            customHeader={
              <NoteDetailFormHeaderBar
                onCloseLayer={onCloseLayer}
                onCloseTools={onClose}
              />
            }
          >
            {loading ? (
              <SkeletonWrap>
                <Skeleton active />
              </SkeletonWrap>
            ) : (
              visible && (
                <NoteDetailForm
                  type="marker"
                  afterPushClose={true}
                  onClose={onClose}
                />
              )
            )}
          </FixedLayer>
        </FormProvider>
      )}
    </MapFavoriteNoteDetailContainer>
  )
}

const SkeletonWrap = styled.div`
  padding: 50px 20px;
`

export default React.memo(MapFavoriteNoteDetail)
