import { useContext, useEffect } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { getCalendars } from 'lib/api/note/calendarApi'
import CalendarContext from 'contexts/calendarContext'

export default function useGetCalendars() {
  const {
    actions: { setSchedules },
  } = useContext(CalendarContext)

  const [onRequest, loading, data, error, reset, source] = useAxios(
    getCalendars,
  )
  // const { channelSrl } = useNoteMatch()

  const _getCalendars = async (cancelToken) => {
    await onRequest({ channelSrl: null }, cancelToken)
  }

  useEffect(() => {
    if (!data?.result) return
    // console.log(data.result)
    setSchedules(data.result)
  }, [data])

  return {
    _getCalendars,
    _getCalendarsLoading: loading,
    _getCalendarsData: data,
    _getCalendarsError: error,
    _getCalendarsSource: source,
  }
}
