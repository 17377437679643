import { smplifyRoadName } from 'lib/utils'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import HomeAddressCard from './HomeAddressCard'

const HomeFavoriteItem = ({ item }) => {
  const [link, setLink] = useState('/favorite')
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  useEffect(() => {
    if (loginInfo.member_type === 'realestate') {
      setLink(
        `/note/${item.channel_srl}/${item.folder_srl}/${item.address_srl}`,
      )
    }
  }, [loginInfo, item])

  return (
    <HomeAddressCard
      to={link}
      address={item.address_jibun}
      roadName={
        item.address_doro
          ? smplifyRoadName(item.address_doro)
          : '도로명주소 없음'
      }
      // noteCount={item.note_count}
      propertyType={item.property_type}
      totalFloorArea={item.total_floor_area}
      price="13.5억"
      date="'16.10"
    />
  )
}

export default HomeFavoriteItem
