import theme, { media } from 'lib/styles/theme'
import styled, { css } from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
  min-height: 34px;
  border-bottom: 1px solid ${theme.colors.gray[100]};
  margin: 0 -20px 16px -20px;
  padding: 0 20px 16px 20px;
  width: calc(100% + 40px);

  ${({ vertical }) => vertical && `flex-direction: row; gap: 8px;`};
  ${({ bottom }) => bottom && `margin-bottom:${bottom}px;`}
  ${({ center }) => center && 'align-items: center;'}
  ${({ noborder }) => noborder && `border-bottom: none;`}

  ${media.mediumS`
    flex-direction: column;
    margin-bottom: 20px;

    ${({ mobilerow }) =>
      mobilerow &&
      `
      flex-direction: row;
      gap: 8px;
      margin-bottom: 0px;
    `}
  `}
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[600]};

  ${({ overflowwidth }) => overflowwidth && `margin-right: 25px;`};
  ${({ halfright }) => halfright && `min-width: 80px;`};
  ${({ width }) => width && `width: ${width}px;`};
  ${({ checkbox }) =>
    checkbox &&
    `
      align-items:flex-start;
      line-height:155%;
  `}

  ${media.mediumS`
    margin-bottom: 4px;
    width: unset;
  `}
`

export const Data = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ relative }) => relative && 'position:relative;'}

  ${({ autoWidth }) => autoWidth && `width: auto;`};
  ${({ direction }) =>
    direction == 'column' &&
    `flex-direction: column; align-items: flex-start;`}

  ${media.mediumS`
    width: 100%;
    flex-shrink: 0;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  gap: 8px;
  width: 100%;
  ${({ wrap }) => wrap == 'Y' && `flex-wrap: wrap;`}
  ${({ direction }) =>
    direction == 'column' && `flex-direction: column;`}

  ${media.mediumS`
      width: 100%;
  `}
`

export const LabelWithTitle = styled(Label)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: auto;
`

export const FormFocusStyle = css`
  &:focus,
  &:focus-within {
    border: 1px solid ${theme.colors.blue[400]};
    outline: none;
    border-radius: 4px;
  }
`

export const Block = styled.div`
  position: relative;
  padding: 15px;
  background-color: ${theme.colors.gray[50]};
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
`
