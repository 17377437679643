import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledFormItem = styled.div`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: 2rem;

  ${({ border }) =>
    border &&
    `
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${theme.colors.gray[200]};
  `}

  ${({ noContainer }) =>
    noContainer &&
    `
    padding-left: 0;
    padding-right: 0;
  `}
  ${({ noMargin }) =>
    noMargin &&
    `
    padding-bottom: 0;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  `}
`
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  line-height: 1;
`
const Label = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-right: 0.25rem;

  ${({ small }) =>
    small &&
    `
    color: ${theme.colors.gray[600]};
    font-weight: 400;
  `}
`
const IsRequired = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${theme.colors.primary[400]};

  ${({ color }) =>
    color == 'blue' &&
    `
    color: ${theme.colors.blue[400]};
  `}
`
const Append = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Body = styled.div``

const FormItem = ({
  label,
  isRequired,
  children,
  border,
  noContainer,
  noMargin,
  labelProps,
  append,
  disabled,
}) => {
  return (
    <StyledFormItem
      noContainer={noContainer}
      border={border}
      noMargin={noMargin}
      disabled={disabled}
    >
      {label && (
        <Title>
          <Label small={labelProps?.small}>{label}</Label>
          {isRequired && <IsRequired color="blue">(필수)</IsRequired>}
          {append && <Append>{append}</Append>}
        </Title>
      )}

      <Body>{children}</Body>
    </StyledFormItem>
  )
}

export default FormItem
