import BaseContext from 'contexts/baseContext'
import useIsMobile from 'lib/hooks/useIsMobile'
import useMoveScroll from 'lib/hooks/useMoveScroll'
import { useContext, useEffect, useLayoutEffect } from 'react'

/**
 * 더보기 동작
 */
export default function useSummaryTab() {
  const {
    state: { isScrolled, isSwipeable },
    actions: { setIsScrolled, setIsSwipe },
  } = useContext(BaseContext)

  const isMobile = useIsMobile()

  //공동 주택용 탭
  const aptTabs = {
    0: useMoveScroll('실거래가'),
    1: useMoveScroll('전유부'),
    2: useMoveScroll('학군'),
    3: useMoveScroll('교통'),
    4: useMoveScroll('규제'),
    length: 5,
  }

  //공동 주택 아닌 기본 탭
  const basicTabs = {
    0: useMoveScroll('토지'),
    1: useMoveScroll('규제'),
    2: useMoveScroll('건물'),
    3: useMoveScroll('학군'),
    4: useMoveScroll('교통'),
    length: 5,
  }

  /**
   * 아파트 탭이 viewport에 들어왔는지 감지하여 class 부여
   */
  useLayoutEffect(() => {
    if (!aptTabs[0].element.current) return
    observeApt()
  }, [aptTabs])

  /**
   * 그냥 탭이 viewport에 들어왔는지 감지하여 class 부여
   */
  useLayoutEffect(() => {
    if (!basicTabs[0].element.current) return
    observeBasic()
  }, [basicTabs])

  useLayoutEffect(() => {
    if (!isMobile || !isScrolled) return
    if (!basicTabs[0].element.current) return
    observeBasic()

    if (!aptTabs[0].element.current) return
    observeApt()
  }, [isScrolled])

  const observeBasic = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('summary-tab-active-section')
          } else {
            entry.target.classList.remove('summary-tab-active-section')
          }
        })
      },
      {
        rootMargin: '-117px 0px 0px 0px',
        threshold: 0.3,
      },
    )

    Array.from(basicTabs).forEach((item) => {
      const target = item.element.current
      if (!target) return
      observer.observe(target)
    })
  }

  const observeApt = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('summary-tab-active-section')
          } else {
            entry.target.classList.remove('summary-tab-active-section')
          }
        })
      },
      {
        rootMargin: '-117px 0px 0px 0px',
        threshold: 0.3,
      },
    )

    Array.from(aptTabs).forEach((item) => {
      const target = item.element.current
      observer.observe(target)
    })
  }

  return { aptTabs, basicTabs }
}
