import { useSelector } from 'react-redux'
import { forwardRef, useEffect, useState } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { getLocationSchool } from 'lib/api/summaryApi'
import {
  Card,
  CardBody,
  CardHeader,
  CardMore,
  CardTitle,
  Empty,
} from 'components/design'
import SummarySchoolTab from 'components/summary/SummarySchool/SummarySchoolTab'
import SummarySchoolGraph from 'components/summary/SummarySchool/SummarySchoolGraph'
import SummarySchoolList from 'components/summary/SummarySchool/SummarySchoolList'
import SummarySchoolTable from 'components/summary/SummarySchool/SummarySchoolTable'
import CardFold from 'components/design/Card/CardFold'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { Skeleton } from 'antd'
import useViewMore from 'lib/hooks/useViewMore'
import SummarySchoolDistrictList from 'components/summary/SummarySchool/SummarySchoolDistrictList'

/**
 * 학군, 보육시설
 * 공통 데이터 공급 컨테이너
 *
 *
 * * 렌더링 여부
 * * 공동주택: O
 * * 공동주택 아님 : O
 */
const SummarySchoolContainer = (
  {
    prevMarker,
    setPrevMarker,
    activeValue,
    onClickSetActiveValue,
    resetActiveValue,
    noTitle,
    addressId,
    latitude,
    longitude,
    isApt,
  },
  ref,
) => {
  const { apt: aptTabRef, basic: basicRef } = ref

  const [onRequest, loading, data] = useAxios(getLocationSchool)

  const [activeTab, setActiveTab] = useState('daycare') // 탭
  const [currentData, setCurrentData] = useState([]) //탭에 해당하는 데이터

  const [middleAvg, setMiddleAvg] = useState(null) //시군구 평균 - 중학교
  const [highAvg, setHighAvg] = useState(null) //시군구 평균 - 고등학교

  const [isDistrict, setIsDistrict] = useState(false)

  useEffect(() => {
    return () => {
      if (prevMarker) {
        setPrevMarker(null)
        prevMarker.setMap(null)
      }
    }
  }, [addressId])

  const {
    viewCnt,
    viewMore,
    isOverflow,
    setViewMore,
    updateViewOverFlow,
    onClickMore,
    onClickFold,
    resetViewCnt,
  } = useViewMore(4)

  useEffect(() => {
    if (!addressId) return

    onRequest({
      address_id: addressId,
      latitude: latitude,
      longitude: longitude,
    })
  }, [addressId])

  useEffect(() => {
    if (!data || !data.result) return
    setMiddleAvg(data.result.middle_admission_avg)
    setHighAvg(data.result.high_admission_avg)

    const result = data.result[[activeTab]]
    setCurrentData(result)
    setViewMore(result.length > 4)

    //중, 고등학교 진학률이 모두 0인 경우, 배정을 보여줌
    if (['middle', 'high'].includes(activeTab)) {
      if (result.length == 0) return
      const isZero =
        result[0].admission_rate == 0 &&
        result
          .map(({ admission_rate }) => admission_rate)
          .every((val, i, arr) => val === arr[0])
      isZero && setIsDistrict(true)
    }
  }, [data, activeTab])

  useUpdateEffect(() => {
    updateViewOverFlow(currentData)
  }, [viewCnt])

  useUpdateEffect(() => {
    resetViewCnt()
  }, [activeTab])

  /**
   * 탭 클릭
   * @parma val 탭 key
   */
  const onChangeTab = (val) => {
    setActiveTab(val)
  }

  return (
    <div ref={isApt ? aptTabRef : basicRef}>
      <Card>
        {!noTitle && (
          <CardHeader>
            <CardTitle>학군·보육시설</CardTitle>
          </CardHeader>
        )}
        <CardBody>
          <SummarySchoolTab
            activeTab={activeTab}
            onChangeTab={onChangeTab}
          />
          {loading && <Skeleton style={{ marginTop: '20px' }} active />}
          {!loading && currentData.length == 0 && (
            <Empty mt>데이터가 없습니다.</Empty>
          )}
          {currentData &&
            currentData.length > 0 &&
            (['middle', 'high'].includes(activeTab) ? (
              isDistrict ? (
                <SummarySchoolDistrictList data={currentData} />
              ) : (
                <>
                  <SummarySchoolGraph
                    activeTab={activeTab}
                    data={currentData}
                    avg={activeTab == 'middle' ? middleAvg : highAvg}
                  />
                  <SummarySchoolTable
                    prevMarker={prevMarker}
                    setPrevMarker={setPrevMarker}
                    viewCnt={viewCnt}
                    activeTab={activeTab}
                    data={currentData}
                    activeValue={activeValue}
                    onClickSetActiveValue={onClickSetActiveValue}
                    resetActiveValue={resetActiveValue}
                  />
                  {viewMore && <CardMore onClick={onClickMore} />}
                  {isOverflow && <CardFold onClick={onClickFold} />}
                </>
              )
            ) : (
              <SummarySchoolList
                prevMarker={prevMarker}
                setPrevMarker={setPrevMarker}
                data={currentData}
                activeValue={activeValue}
                onClickSetActiveValue={onClickSetActiveValue}
                resetActiveValue={resetActiveValue}
              />
            ))}
        </CardBody>
      </Card>
    </div>
  )
}

export default forwardRef(SummarySchoolContainer)
