import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * 공부문서 신규 문서 발급 버튼
 */
const NoteDetailGongbuIssuanceButtons = ({
  buttonRef,
  onClickDeunggibu,
  onClickLandUsePlan,
}) => {
  return (
    <>
      <ButtonsWrapper>
        <Button color="blue" onClick={onClickDeunggibu}>
          등기부등본
        </Button>
        <ColorButton
          ref={buttonRef}
          color="blue"
          onClick={onClickLandUsePlan}
        >
          토지이용계획원
        </ColorButton>
        <a href="https://www.gov.kr//" target="_blank">
          <Button color="default">토지/건축물대장 보러가기</Button>
        </a>
      </ButtonsWrapper>
    </>
  )
}

const ColorButton = styled(Button)`
  &:disabled {
    background-color: ${theme.colors.gray[300]};
    border-color: ${theme.colors.gray[300]};
  }
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  gap: 8px 13px;
`
export default NoteDetailGongbuIssuanceButtons
