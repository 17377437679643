import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { ReactComponent as AppleLogo } from 'assets/images/note/landing/apple-logo.svg'
import { ReactComponent as GooglePlayLogo } from 'assets/images/note/landing/google-play.svg'
import { Button } from 'components/design'

const LandingFooter = ({ setCustomAccount, setIsVisibleLogin }) => {
  /**
   * 테스트 계정으로 시작하기
   */
  const onClickLogin = () => {
    setIsVisibleLogin(true)
    setCustomAccount({ id: 'brokertest', pw: 'guest1jootek#$' })
  }

  /**
   * 매물노트 가이드 보기
   */
  const onClickGuide = () => {
    window.open('https://jootek.com/guide/category/9506', '_blank')
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Text>
            <span>중개업무를 위해 태어난</span>
            <ColorText1> 새로운 OS</ColorText1>
            <br />
            <span>
              <ColorText2>매물노트</ColorText2>를
            </span>
            <span> 지금 경험하세요</span>
          </Text>
          <MarketButtons>
            <a href="https://apps.apple.com/us/app/jootek/id1542583047">
              <AppleLogo />
              App Store
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.jootek.app">
              <GooglePlayLogo />
              Google Play
            </a>
          </MarketButtons>
          <StartButtons>
            <Button size="xlarge" color="blue" onClick={onClickLogin}>
              테스트 계정으로 시작하기
            </Button>
            <Button onClick={onClickGuide} size="xlarge">
              매물노트 가이드 보기
            </Button>
          </StartButtons>
        </Content>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  border-top-right-radius: 60px;
  border-top-left-radius: 60px;
  height: 630px;
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0f0f0f;
  border-top-right-radius: 60px;
  border-top-left-radius: 60px;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Text = styled.h2`
  font-size: 54px;
  font-weight: 700;
  line-height: 150%;
  color: ${theme.colors.base.white};
  width: max-content;
  margin-bottom: 60px;
  text-align: center;

  ${media.mediumM`
    display: flex;
    flex-direction: column;
    font-size: 40px;

    br {
    display: none;
    }            
  `}

  ${media.mediumS`
    font-size: 32px;
  `}
`

const ColorText1 = styled.span`
  background: linear-gradient(91.93deg, #04cc00 0%, #12c2cd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`

const ColorText2 = styled.span`
  background: linear-gradient(94.47deg, #4c7cf9 12.35%, #00fff0 45.05%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`

const MarketButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${media.mediumS`
    flex-direction: column;
    `}

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #242a30;
    border-radius: 48px;
    color: ${theme.colors.base.white};
    text-decoration: none;
    margin-left: 8px;
    height: 60px;
    width: 210px;

    ${media.mediumS`
      height: 42px;
    `}

    svg {
      margin-right: 8px;
    }
  }
`

const StartButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 50px;

  button {
    width: 313px;
    height: 60px;
    border-radius: 30px;
    &:last-child {
      color: ${theme.colors.blue[400]};
    }

    ${media.mediumS`
    width: 260px; 
    height: 48px;
  `}
  }
`

export default LandingFooter
