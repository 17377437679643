import Header from 'components/common/Header/Header'
import HeaderDivider from 'components/common/Header/HeaderDivider'
import BaseHeaderNoty from 'components/base/BaseHeader/BaseHeaderNoty'
import BaseHeaderNav from 'components/base/BaseHeader/BaseHeaderNav'

const CalendarHeader = () => {
  return (
    <Header noTopHeader fixed>
      일정관리
      <HeaderDivider />
      <BaseHeaderNoty />
      <BaseHeaderNav />
    </Header>
  )
}

export default CalendarHeader
