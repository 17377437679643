import { useCallback, useEffect } from 'react'

import { Confirm } from 'components/design'
import { useHistory } from 'react-router'
import useAddresses from 'pages/jmapnote/noteList/hooks/useAddresses'
import useAxios from 'lib/hooks/useAxios'
import { saveNote } from 'lib/api/note/noteApi'

const NoteApplySave = ({
  channelSrl,
  folderSrl,
  addressSrl,
  propertyType,
  onClose,
}) => {
  const history = useHistory()
  const { _getAddresses, loading } = useAddresses()
  const [onRequest, , data, error, reset] = useAxios(saveNote)

  useEffect(() => {
    _getAddresses({
      channelSrl,
      folderSrl,
      addressSrl,
    })
    onRequest({
      channelSrl,
      folderSrl,
      addressSrl,
      propertyType,
      form: data,
    })
  }, [channelSrl, folderSrl, addressSrl])

  useEffect(() => {
    if (!data || !data.result) return

    history.push(
      `/note/v2/notes/${channelSrl}/${addressSrl}/${data.result}`,
    )
  }, [data])

  return <></>
}

export default NoteApplySave
