import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledTimeline = styled.ul`
  list-style-type: none;
  margin-bottom: 24px;

  & > li {
    position: relative;
    padding: 0 0 16px 16px;
    margin-left: 8px;

    color: ${theme.colors.gray[600]};
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 1px solid ${theme.colors.gray[200]};
    }
    &::after {
      content: '';
      position: absolute;
      top: 16px;
      left: 0;
      transform: translate(-50%, 0);

      width: 9px;
      height: 9px;
      border-radius: 100%;
      background-color: ${theme.colors.gray[500]};
    }

    &:first-child {
      &::after {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);

        width: 13px;
        height: 13px;
        background-color: ${theme.colors.base.white};
        border: 2px solid ${theme.colors.gray[900]};
      }
      &::before {
        top: 50%;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
`
const TimelineLabel = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[900]};
  font-weight: 700;
  margin-bottom: 4px;
`

const Detail = styled.span`
  color: ${theme.colors.gray[600]};
`

const SummaryLegalHistory = ({ move }) => {
  return (
    <StyledTimeline>
      {move &&
        move.map((item, index) => {
          return (
            <li key={index}>
              <TimelineLabel>{item.move_date}</TimelineLabel>

              <span>{item.move_cause}</span>

              <Detail>{item.detail ? ` (${item.detail})` : ''}</Detail>
            </li>
          )
        })}
    </StyledTimeline>
  )
}

export default SummaryLegalHistory
