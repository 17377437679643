import HeaderBack from 'components/common/Header/HeaderBack'
import useIsMobile from 'lib/hooks/useIsMobile'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

const NoteAdFormFixedTitle = ({ onClickGoBack, status }) => {
  const isMobile = useIsMobile()
  return (
    <TitleWrapper>
      {isMobile ? (
        <HeaderBack onClose={onClickGoBack} />
      ) : (
        <BackButton onClick={onClickGoBack}>
          <i className="far fa-angle-left"></i>
        </BackButton>
      )}
      <Title>매물 등록</Title>
      <Divider />
      <Title>{status}</Title>
    </TitleWrapper>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 42px;

  ${media.mediumM`
    height: 38px;
  `}

  ${media.mediumS`
    gap: 8px;
  `}
`

const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: ${theme.colors.gray[100]};
  border-radius: 50%;
  cursor: pointer;

  i {
    color: ${theme.colors.gray[400]};
    font-size: 20px;
  }

  ${media.mediumM`
    width: 32px;
    height: 32px;
  `}
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;

  ${media.mediumM`
    line-height: 100%;
    font-size: 24px;
  `}

  ${media.mediumS`
    font-size: 18px;
  `}
`
const Divider = styled.div`
  width: 1px;
  height: 50%;
  background-color: ${theme.colors.gray[300]};
`

export default NoteAdFormFixedTitle
