import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { nretryVerification } from 'lib/api/notev2/adSubmit'
import { useSelector } from 'react-redux'

/**
 * 광고 목록 버튼
 * 다시 검증 요청
 * @param adSrl
 * @param callbackFn
 */
const RetryButton = ({ adSrl, callbackFn }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const [onRequestRetry, , dataRetry] = useAxios(nretryVerification, {
    resetError: true,
  }) //재검증 요청
  /**
   * 검증요청 버튼 (재검증요청)
   */
  const onClickRetry = (adSrl) => {
    onRequestRetry({
      ad_srl: adSrl,
      plan_srl: planInfo.allinone_plan_srl,
    })
  }

  /**
   * 검증요청 완료
   */
  useEffect(() => {
    if (!dataRetry) return
    if (dataRetry?.result?.errors?.length == 0) {
      message.success('재검증 요청이 완료되었습니다.')
      callbackFn && callbackFn()
    } else if (dataRetry?.result?.errors?.length > 0) {
      message.error('요청중 오류가 발생했습니다.')
    } else {
      message.error('알 수 없는 오류가 발생했습니다.')
    }
  }, [dataRetry])

  return (
    <AdButton
      forForm
      expand
      size="xlarge"
      color="blue"
      onClick={() => onClickRetry(adSrl)}
    >
      다시검증요청
    </AdButton>
  )
}

export default RetryButton
