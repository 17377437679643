import { saveCalendar } from 'lib/api/note/calendarApi'
import useAxios from 'lib/hooks/useAxios'

export default function useCalendarSave() {
  // const { channelSrl } = useNoteMatch()
  const [onRequest, loading, data, error, reset] =
    useAxios(saveCalendar)

  const _saveCalendar = (payload) => {
    onRequest(payload)
  }

  return {
    _saveCalendar,
    _saveLoading: loading,
    _saveData: data,
    _saveError: error,
    _saveReset: reset,
  }
}
