import { useContext } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import BaseContext from 'contexts/baseContext'
import { ReactComponent as CustomerIconActive } from 'assets/icon/note/menu/mobile/customer-active.svg'
import { ReactComponent as CustomerIcon } from 'assets/icon/note/menu/mobile/customer.svg'
import { ReactComponent as ListIconActive } from 'assets/icon/note/menu/mobile/list-active.svg'
import { ReactComponent as ListIcon } from 'assets/icon/note/menu/mobile/list.svg'
import { ReactComponent as DocIconActive } from 'assets/icon/note/menu/mobile/doc-active.svg'
import { ReactComponent as DocIcon } from 'assets/icon/note/menu/mobile/doc.svg'
import { ReactComponent as PicturesIconActive } from 'assets/icon/note/menu/mobile/pictures-active.svg'
import { ReactComponent as PicturesIcon } from 'assets/icon/note/menu/mobile/pictures.svg'
import { ReactComponent as DonghoIcon } from 'assets/icon/note/menu/mobile/dongho.svg'
import { ReactComponent as DonghoIconActive } from 'assets/icon/note/menu/mobile/dongho-active.svg'
import { ReactComponent as AdIcon } from 'assets/icon/note/menu/mobile/ad.svg'
import { ReactComponent as AdIconActive } from 'assets/icon/note/menu/mobile/ad-active.svg'
import NoteBottomMenuItem from './NoteBottomMenuItem'
import { useRouteMatch, useHistory } from 'react-router-dom'
import BaseNavMore from 'components/base/BaseNav/BaseNavMore'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'

const NoteBottomMenu = () => {
  const channels = useSelector((state) => state.channel.channels)
  const { isAllInOne } = useIsAllInOne()
  const isVisibleBottomNav = useSelector(
    (state) => state.notev2.isVisibleBottomNav,
  )
  const isMobile = useIsMobile()

  const {
    actions: { setIsFold },
  } = useContext(BaseContext)

  const match = useRouteMatch({
    path: ['/note/v2/:pageType/:channelSrl?'],
  })

  const getNoteUrlSheet = (page, channel = true) => {
    if (!channel) return `/note/v2/${page}`
    // localStorage에 저장된 최근 접속한 채널srl과 folderSrl을 불러옵니다.
    const recentChannelSrl = localStorage.getItem('recentChannelSrl')

    // 최근 접속한 채널/폴더 정보가 있을 경우 유효성 체크 후 이동시킵니다.
    if (recentChannelSrl && recentChannelSrl !== 'undefined') {
      const authorizedChannelSrl = channels.filter(
        ({ channel_srl }) => channel_srl === parseInt(recentChannelSrl),
      )[0]?.channel_srl

      // 채널 목록에 최근에 접속한 채널과 폴더 srl 값이 있는 경우
      if (authorizedChannelSrl) {
        return `/note/v2/${page}/${authorizedChannelSrl}`
      }
    }
  }

  const navLinkIsActive = (match, location) => {
    if (match?.exact) true

    return false
  }

  return (
    <StyledBottomMenu
      visible={isVisibleBottomNav}
      onClick={() => setIsFold(false)}
    >
      <NoteBottomMenuItem
        to={{ pathname: getNoteUrlSheet('notes') }}
        isActive={navLinkIsActive}
        icon={<ListIcon className="normalIcon" />}
        activeIcon={<ListIconActive className="activeIcon" />}
        text="매물관리"
      />
      <NoteBottomMenuItem
        to={{ pathname: getNoteUrlSheet('docs') }}
        isActive={navLinkIsActive}
        icon={<DocIcon className="normalIcon" />}
        activeIcon={<DocIconActive className="activeIcon" />}
        text="계약관리"
      />
      {isAllInOne && (
        <NoteBottomMenuItem
          to={{
            pathname: getNoteUrlSheet('ad', false),
          }}
          isActive={navLinkIsActive}
          icon={<AdIcon className="normalIcon" />}
          activeIcon={<AdIconActive className="activeIcon" />}
          text="광고관리"
          exact
        />
      )}
      <NoteBottomMenuItem
        to={{ pathname: '/note/v2/dongho' }}
        isActive={navLinkIsActive}
        icon={<DonghoIcon className="normalIcon" />}
        activeIcon={<DonghoIconActive className="activeIcon" />}
        text="동호관리"
        exact
      />

      <NoteBottomMenuItem
        to={{
          pathname: getNoteUrlSheet('pictures'),
        }}
        isActive={navLinkIsActive}
        icon={<PicturesIcon className="normalIcon" />}
        activeIcon={<PicturesIconActive className="activeIcon" />}
        text="사진관리"
        exact
      />

      {(!isAllInOne || (isAllInOne && !isMobile)) && (
        <NoteBottomMenuItem
          to={{ pathname: '/note/v2/customer' }}
          isActive={navLinkIsActive}
          icon={<CustomerIcon className="normalIcon" />}
          activeIcon={<CustomerIconActive className="activeIcon" />}
          text="고객관리"
          exact
        />
      )}

      {!isMobile && <BaseNavMore />}
    </StyledBottomMenu>
  )
}

const StyledBottomMenu = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 71;
  align-items: center;
  width: ${theme.base.container}px;
  height: ${theme.base.bottomMenuHeight}px;
  height: calc(
    ${theme.base.bottomMenuHeight}px + env(safe-area-inset-bottom)
  );
  padding-bottom: env(safe-area-inset-bottom);
  background-color: white;
  border-top: 1px solid ${theme.colors.gray[200]};
  transition: bottom 0.3s;

  ${media.mediumM`
    width: 100%;
    display: flex;

    ${({ visible }) =>
      visible
        ? `bottom: 0;`
        : `bottom: -${theme.base.bottomMenuHeight}px`}
  `}
`

export default NoteBottomMenu
