import { useFormContext } from 'react-hook-form'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import Checkbox from '../../elements/Checkbox'
import { options } from '../../options'
import React from 'react'
import useSave from '../../useSave'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 시설정보 보안시설
 */
const NoteAdColumnSecureFacility = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onChangeCheckbox } = useSave()

  const security = watch('facilities.security') || []

  return (
    <ColumnSet>
      <ColumnTitle>보안시설</ColumnTitle>
      <ColumnVal gap="20px 40">
        {options['보안시설'].map((item, i) => {
          return (
            <React.Fragment key={item}>
              <Checkbox
                id={`facilities_security${i}`}
                color="blue"
                value={item}
                {...register('facilities.security')}
                onChange={(checked, e) => {
                  onChangeCheckbox({
                    originValue: security,
                    key: 'facilities.security',
                    value: e,
                  })
                }}
              >
                {item}
              </Checkbox>
            </React.Fragment>
          )
        })}
        <ValidateWarn>
          {errors?.facilities?.security?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnSecureFacility
