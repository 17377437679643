import React, { useRef } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { Button, Checkbox } from 'components/design'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'

/**
 * 위임장 양식
 */
const NoteAdVerificationFile = () => {
  const targetRef = useRef(null)
  const { watch } = useFormContext()

  const address = watch('address')

  /**
   * pdf 만들기
   * canvas로 변환한 후 pdf로 변환함
   */
  const makePdf = () => {
    html2canvas(targetRef.current, {
      scale: 2,
    }).then((canvas) => {
      const imgWidth = 208
      const pageHeight = 295
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      let heightLeft = imgHeight
      let position = 0
      heightLeft -= pageHeight
      const doc = new jsPDF('p', 'mm')

      doc.addImage(
        canvas,
        'PNG',
        0,
        position,
        imgWidth,
        imgHeight,
        '',
        'FAST',
      )
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        doc.addPage()

        doc.addImage(
          canvas,
          'PNG',
          0,
          position,
          imgWidth,
          imgHeight,
          '',
          'FAST',
        )
        heightLeft -= pageHeight
      }
      doc.save(`위임장 양식_${address}.pdf`)
    })
  }

  const onClick = async (e) => {
    e.preventDefault()
    makePdf()
  }

  return (
    <>
      <Button color="blue" size="small" onClick={onClick}>
        위임장 양식 다운로드
      </Button>
      <Paper ref={targetRef}>
        <Title>위임장 (2023~)</Title>

        <TableTitle>위임자</TableTitle>
        <Table>
          <TableRow small>
            <TableLabel>성명</TableLabel>
            <TableContent right border>
              (인)
            </TableContent>
            <TableLabel>주민등록번호</TableLabel>
            <TableContent>
              <SNumberWrapper>
                <SNumber first />
                <SNumber>******</SNumber>
              </SNumberWrapper>
            </TableContent>
          </TableRow>
          <TableRow small>
            <TableLabel>주소</TableLabel>
            <TableContent></TableContent>
          </TableRow>
          <TableRow>
            <TableLabel>
              위임받는자
              <br />
              와의관계
            </TableLabel>
            <TableContent border></TableContent>
            <TableLabel>신청일</TableLabel>
            <TableContent></TableContent>
          </TableRow>
        </Table>
        <TableTitle>위임내용</TableTitle>
        <Table>
          <TableRow small>
            <TableLabel>매물소재지</TableLabel>
            <TableContent right></TableContent>
          </TableRow>
          <TableRow small>
            <TableLabel>위임기간</TableLabel>
            <TableContent>
              <DateWrapper>
                <DateArea first />
                <DateArea />
              </DateWrapper>
            </TableContent>
          </TableRow>
          <TableRow>
            <TableLabel>위임사항</TableLabel>
            <TableContent></TableContent>
          </TableRow>
        </Table>
        <TableTitle>위임받는자</TableTitle>
        <Table>
          <TableRow small>
            <TableLabel>성명</TableLabel>
            <TableContent right border>
              (인)
            </TableContent>
            <TableLabel>주민등록번호</TableLabel>
            <TableContent>
              <SNumberWrapper>
                <SNumber first />
                <SNumber>******</SNumber>
              </SNumberWrapper>
            </TableContent>
          </TableRow>
          <TableRow small>
            <TableLabel>주소</TableLabel>
            <TableContent></TableContent>
          </TableRow>
        </Table>
        <Desc>
          <DescItem>
            <DescTitle>
              &lt;개인정보 수집 및 이용에 대한 동의(필수)&gt;
            </DescTitle>
            &ndash; 개인정보의 수집 목적 : 부동산 매물 확인 및 홍보,
            권한 위임에 대한 확인, 허위매물 등록 방지
            <br />
            &ndash; 수집하는 개인정보 항목 : 위임자 정보(성명,
            주민등록번호 앞 7자리, 주소, 위임받는 자와의 관계), 위임받는
            자 정보(성명, 주민등록번호 앞7자리, 주소) <br />
            &ndash; 보유기간 : 60일간 보관 후 파기
          </DescItem>
          <DescItem>
            <DescTitle>
              &lt;텐컴즈 개인정보 제공에 대한 동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는자 : (주)텐컴즈
            <br />
            &ndash; 제공 목적 : 매물정보 홍보, 권한 위임에 대한 확인,
            허위매물 등록 방지
            <br />
            &ndash; 제공하는 정보 : 위임자 정보(성명, 주민등록번호 앞
            7자리, 주소, 위임받는 자와의 관계), 위임받는 자 정보(성명,
            주민등록번호 앞7자리, 주소) <br />
            &ndash; 보유기간 : 30일 까지
          </DescItem>
          <DescItem>
            <DescTitle>
              &lt;텐컴즈 &ndash; 네이버 개인정보 제공에 대한
              동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는 자: 네이버 <br />
            &ndash; 제공 목적: 네이버 부동산에 매물정보 홍보, 허위매물
            등록 방지
            <br />
            &ndash; 제공 항목 소유자명, 소유자와의 관계, 소재지,
            거래유형, 가격 -제공받는 자의 개인정보 보유 및 이용 기간
            제공 후 60일간 보관
          </DescItem>
          <DescItem>
            <DescTitle>
              &lt;네이버- 한국부동산원 계인정보 제공에 대한
              동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는자: 한국부동산원 &ndash;제공 목적: 허위매물
            등록 방지
            <br />
            * 한국부동산원법에 따라 시장동향과 관련 통계의 조사 관리를
            위한 목적으로도 활용될 수 있음
            <br />
            &ndash; 제공항목: 소재지,거래유형,가격 <br />
            &ndash; 제공받는 자의 개인정보 보유 및 이용 기간 제공 후
            1년간 보관
          </DescItem>
          <DescItem>
            <DescTitle>
              &lt;네이버- 한국인터넷자율정책기구 개인정보 제공에 대한
              동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는 자:한국인터넷자율정책기구
            <br />
            &ndash; 제공 목적: 허위매물 여부확인
            <br />
            &ndash; 제공항목 소재지, 거래유형,가격
            <br />
            &ndash; 제공받는 자의 개인정보 보유 및 이용기간: 제공 후
            1년간 보관
          </DescItem>
        </Desc>
        <Agree>
          개인정보 수집 및 이용, (주)텐컴즈 및 네이버파이낸셜로의
          개인정보 제공에 대하여 모두
          <AgreeCheckboxes>
            <Checkbox size="small">동의</Checkbox>
            <Checkbox size="small">미동의</Checkbox>
          </AgreeCheckboxes>
        </Agree>
      </Paper>
    </>
  )
}

const Paper = styled.div`
  position: fixed;
  z-index: -100;
  width: 595px;
  padding: 4px 50px 0 50px;
  background-color: white;
`

const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 22px;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
`

const TableTitle = styled.div`
  margin-top: 14px;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
`

const Table = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  border: 1px solid ${theme.colors.gray[600]};
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid ${theme.colors.gray[600]};
  overflow: hidden;

  ${({ small }) =>
    small &&
    `
    height: 18px;
  `}

  &:last-child {
    border-bottom: none;
  }
`

const TableLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: inherit;
  font-size: 10px;
  font-weight: 400;
  line-height: 100%;
  background-color: ${theme.colors.gray[100]};
  border-right: 1px solid ${theme.colors.gray[600]};
  flex-shrink: 0;

  span {
    font-size: 9px;
    color: ${theme.colors.gray[400]};
  }
`

const TableContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 0 8px;
  font-size: 10px;
  border: 3px solid ${theme.colors.base.white};
  background-color: ${theme.colors.blue[50]};

  ${({ border }) =>
    border &&
    `
    border-right: 1px solid ${theme.colors.gray[600]};
  `}

  ${({ right }) =>
    right &&
    `
    justify-content: flex-end;
  `}
`

const Desc = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 160%;
`

const DescTitle = styled.div`
  font-weight: 500;
`

const DescItem = styled.div`
  margin-bottom: 8px;
`

const Agree = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
`

const AgreeCheckboxes = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;

  .checkbox {
    width: 12px;
    height: 12px;
    border-radius: 0;
    border: 1px solid ${theme.colors.gray[600]};
  }
`

const SNumber = styled.div`
  width: 45%;

  ${({ first }) =>
    first
      ? `
    position: relative;
    &::after {
      content: '-';
      position: absolute;
      right: 0;
    }
  `
      : `padding-left: 6px;`}
`

const SNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const DateArea = styled.div`
  width: 45%;

  ${({ first }) =>
    first
      ? `
    position: relative;
    &::after {
      content: '~';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  `
      : ``}
`

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export default NoteAdVerificationFile
