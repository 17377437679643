import moment from 'moment'

export const getSelectedValue = (value, array) => {
  if (!value) return ''
  const index = array.findIndex((item) => {
    return item.label == value
  })

  return array[index]
}

/**
 * string array를 넘기면 react-select option 포맷으로 바꿔줌
 * @param {Array} array 바꿀 array
 * @param {boolean} hasSelect 선택 포함 여부
 * @returns
 */
export const getSimpleSelectboxOptions = (array, hasSelect = true) => {
  const choiceOption = [{ value: '', label: '선택' }]

  if (!array) return choiceOption

  const option = array.map((value) => {
    return { label: value, value: value }
  })

  return hasSelect ? [...choiceOption, ...option] : option
}

/**
 * string array를 넘기면 react-select option 포맷으로 바꿔줌
 * object 쌍 형태용
 * @param {*} kvObject 바꿀 obj
 * @param {boolean} hasSelect 선택 포함 여부
 * @returns
 */
export const getSimpleSelectboxOptionsForObject = (
  kvObject,
  hasSelect = true,
) => {
  const choiceOption = [{ value: '', label: '선택' }]

  if (!kvObject) return choiceOption

  const option = Object.entries(kvObject).map(([key, value]) => {
    return {
      label: value,
      value: key,
    }
  })

  return hasSelect ? [...choiceOption, ...option] : option
}

export const getOnlyRemoveCommaNumber = (value) => {
  return String(value).replaceAll(',', '')
}

export const handleFocusNumber = (e) => {
  if (e.target?.value == 0) {
    e.target?.select()
  }
}

export const getSimpleDateFormat = (value) => {
  return value && value != 'Invalid date'
    ? moment(value).format('YY.MM.DD')
    : ''
}

/**
 * 유효하지 않은 주소 포맷
 * @param {*} param0
 * @returns
 */
export const getInvalidAddrName = ({ invalid_addr_name, dong, ho }) => {
  return `${invalid_addr_name?.trim() || ''}${
    dong ? getDongText(dong) : ''
  }${ho ? getHoText(ho) : ''}`?.trim()
}

/**
 * 보여주기 전용 유효한 주소 포맷
 * @param {*} param0
 * @returns
 */
export const getDisplayAddressFormat = ({
  eupmyeondong,
  display_address,
  dong,
  ho,
}) => {
  return `${eupmyeondong || ''}${
    display_address ? ` ${display_address}` : ''
  }${dong ? getDongText(dong) : ''}${ho ? getHoText(ho) : ''}`
}

/**
 * 1. 뒤에 붙은 동 제거
 * 2. 동 구분없음이면 빈 값으로 리턴
 * @param {*} value
 * @returns
 */
const getDongText = (value) => {
  const dongEndRegx = /^.*.동$/
  const filteredValue = dongEndRegx.test(value)
    ? ` ${value}`
    : ` ${value}동`
  const removeText = filteredValue.replace('구분없음동', '')

  return removeText
}

const getHoText = (value) => {
  const hoEndRegx = /^.*.호$/

  return hoEndRegx.test(value) ? ` ${value}` : ` ${value}호`
}

/**
 * 매매, 전/월세에 따라 테이블 타이틀 리턴
 * @param {string} contractType 매매, 전세, 월세
 * @param {string} type 매도인 or 매수인
 */
export const getContactHeaderName = (contractType, type) => {
  if (type == '매도인') {
    if (contractType != '매매') {
      return '임대인'
    }

    return type
  } else if (type == '매수인') {
    if (contractType != '매매') {
      return '임차인'
    }

    return type
  }
}

/**
 * 잔금 계산
 * @param {*} p_total_price  총액
 * @param {*} jungdogeum 중도금
 * @param {*} loan 융자금
 * @param {*} contract_price 계약금
 * @param {*} contract_type 계약타입
 * @returns
 */
export const getCalcedJanguem = (
  p_total_price,
  jungdogeum,
  loan,
  contract_price,
  contract_type,
) => {
  const hasjungdogeum =
    jungdogeum.filter((item) => item.price)?.length > 0
  const jungdogeumAll = hasjungdogeum
    ? jungdogeum
        .map(({ price }) => Number(price) || 0)
        .reduce((a, b) => a + b)
    : 0

  //월세, 전세인 경우 잔금(보증금 = 계약금 + 중도금 + 잔금)
  const calcValue =
    (p_total_price || 0) - (Number(contract_price) || 0) - jungdogeumAll

  //매매 잔금 계산 (매매대금 = 계약금 + 융자금 + 중도금 + 잔금)
  const calcValueSale = calcValue - (loan || 0)

  return contract_type === '매매' ? calcValueSale : calcValue
}

export const getVatRatio = (value) => {
  const result = {
    '일반(10%)': 0.1,
    '간이(4%)': 0.04,
  }

  return result[value] || 0
}

export const allInOnePrice = {
  1: 19900,
  6: 99900,
}

export const allInOnePriceAPerson = {
  1: 9900,
  6: 59400,
}

// 배열 쪼개기
function chunkAtEnd(value = '', n = 1) {
  const result = []

  for (let end = value.length; end > 0; end -= n) {
    result.push(value.substring(Math.max(0, end - n), end))
  }

  return result
}

/**
 * 금액을 한글로 변환
 */
export const convertToKoreanNumber = (num) => {
  const numberUnits = [
    '',
    '일',
    '이',
    '삼',
    '사',
    '오',
    '육',
    '칠',
    '팔',
    '구',
  ]
  const tenUnits = ['', '십', '백', '천']
  const thousandUnits = ['', '만', '억', '조', '경', '해']

  const result = chunkAtEnd(String(num), 4)
    .reduce((acc, item, index) => {
      if (!Number(item)) {
        return acc
      }

      let numberUnit = ''

      const zeroNum = item.padStart(4, '0')

      for (let i = 0; i < 4; i++) {
        const number = Number(zeroNum[i])

        if (number) {
          const unit = tenUnits[3 - i]

          numberUnit += `${
            unit && number === 1 ? '' : numberUnits[number]
          }${unit}`
        }
      }

      const thousandUnit = thousandUnits[index] ?? ''

      return `${numberUnit + thousandUnit}${acc}`
    }, '')
    .trim()

  return result
}

/**
 * object value로 key 얻기
 * @param {*} object
 * @param {*} value
 * @returns
 */
export function getKeyByValueInclude(object, value) {
  return Object.keys(object).find((key) => object[key].includes(value))
}
