import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import ReactDOM from 'react-dom'
import { useRef } from 'react'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import { useSelector } from 'react-redux'
import useIsMobile from 'lib/hooks/useIsMobile'

const FixedLayer = ({
  visible,
  onClose,
  children,
  hasDimmer = true,
  outSideClickClose = false,
  grayheader = false,
  customHeader,
}) => {
  const root = document.getElementById('fixed-layer')

  const isMobile = useIsMobile()

  const ref = useRef()
  useOnClickOutside(ref, (e) => {
    if (
      e?.target &&
      (e.target.closest('.modal-dialog') ||
        e.target.closest('.modal-dialog-dimmer') ||
        e.target.closest('.fade') ||
        e.target.closest('.ant-dropdown') ||
        e.target.closest('#roadview-root')) //로드뷰 parent
    )
      return //모달 열린 상태 or react-images-viewer(사진 크게보기)라면 3뎁스 레이어 바깥 클릭해도 닫히지 않도록함

    if (outSideClickClose) onClose()
  })

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )
  const height = useSelector((state) => state.notice.height)

  return ReactDOM.createPortal(
    <>
      <Wrapper
        isMobile={isMobile}
        ref={ref}
        visible={visible}
        isNoticeBarVisible={isNoticeBarVisible}
        noticeBarHeight={height}
      >
        {customHeader ? (
          customHeader
        ) : (
          <Header grayheader={grayheader ? 'Y' : 'N'}>
            <svg
              onClick={(e) => {
                e.stopPropagation()
                onClose()
              }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.75 4.5L4.3269 19.5M4.3269 4.5L18.75 19.5"
                stroke="#6B7684"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Header>
        )}
        {children}
      </Wrapper>
      {hasDimmer && <Dimmer visible={visible} onClick={onClose} />}
    </>,
    root,
  )
}

const Wrapper = styled.div`
  position: fixed;
  right: -100%;
  width: 570px;
  height: 100%;
  z-index: 99;
  right: -570px;
  transition: right 0.25s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['button-1']};

  ${({ visible }) => (visible ? `right: 0;` : `right:-570px`)}

  ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible
      ? `top: ${noticeBarHeight}px !important; ;`
      : `top: 0;
  `}

  ${media.mediumS`
    width: 100%;
  `}

  transition: right 0.2s ease;
`

const Header = styled.div`
  width: 100%;
  position: absolute;
  padding-top: 16px;
  padding-left: 20px;
  right: 0;
  z-index: 1;
  height: 45px;
  background-color: ${({ grayheader }) =>
    grayheader == 'Y'
      ? theme.colors.gray[50]
      : theme.colors.base.white};

  svg {
    cursor: pointer;
  }
`

const Dimmer = styled.div`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 51;

  ${({ visible }) => visible && `display: block;`}
`

export default FixedLayer
