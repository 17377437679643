import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import { options } from '../../options'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 시설정보 난방연료
 */
const NoteAdColumnHeatingFuel = ({ half }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const list = options['난방연료']
  const fuel = watch('facilities.fuel')

  return (
    <ColumnSet half={half}>
      <ColumnTitle>난방연료</ColumnTitle>
      <ColumnVal>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: fuel || '선택',
            value: fuel,
          }}
          isSearchable
          options={getSimpleSelectboxOptions(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('facilities.fuel')}
          onChange={(e) => {
            onUpdate('facilities.fuel', e.value)
          }}
        />
        <ValidateWarn>{errors?.facilities?.fuel?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnHeatingFuel
