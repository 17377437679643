import { getSpec } from 'lib/api/notev2/contractApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

const NoteDocsContractContainer = ({
  isPropertyContractBind,
  children,
}) => {
  const [onRequestSpec, loadingSpec, dataSpec] = useAxios(getSpec)
  const { watch } = useFormContext()

  const contract_type = watch('contract_type')
  const property_type = watch('property_type')

  useEffect(() => {
    //매물종류와 계약서 타입이 세팅 완료된 후에 양식 불러옴
    if (!isPropertyContractBind) return

    onRequestSpec({ property_type, contract_type })
    //
  }, [isPropertyContractBind])

  return children({
    isLoadingSpec: loadingSpec,
    onRequestSpec,
    contract_type,
    property_type,
    indicationSpec: dataSpec?.result?.indication?.fields || [], //부동산의 표시 컬럼
    mainArticlesSpec: dataSpec?.result?.main_articles || [], //조항
    special_conditions: dataSpec?.result?.special_conditions, //특약
  })
}

export default NoteDocsContractContainer
