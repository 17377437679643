import { useContext } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import BaseContext from 'contexts/baseContext'
import useIsMobile from 'lib/hooks/useIsMobile'

const FoldWrap = styled.div`
  position: fixed;
  top: 47%;
  left: ${({ shift }) =>
    shift
      ? `${theme.base.container + theme.base.noteSidebarWidth}px`
      : `${theme.base.container}px`};

  z-index: 13;
  cursor: pointer;

  ${media.smallPc`
		left: ${({ isMobile }) =>
      isMobile ? theme.base.container + 22 : theme.base.container}px;
		${({ isFold }) =>
      isFold &&
      `
			left: 0;
		`}
	`}

  ${({ isFold }) =>
    isFold &&
    `
		left: 0;
	`}
`
const Button = styled.div`
  width: 22px;
  height: 49px;
  vertical-align: middle;
  background-color: ${theme.colors.gray[100]};
  box-shadow: 3px 5px 5px rgb(0 0 0 / 10%);
  border-radius: 0 5px 5px 0;
  & > i {
    line-height: 49px;
    padding-left: 0.35rem;
    font-size: 0.85rem;
  }
`

const FoldBtn = ({ shift }) => {
  const {
    state: { isFold },
    actions: { setIsFold },
  } = useContext(BaseContext)

  const isMobile = useIsMobile()

  return (
    <FoldWrap isMobile={isMobile} isFold={isFold} shift={shift}>
      <Button onClick={() => setIsFold((prev) => !prev)}>
        {isFold && <i className="far fa-chevron-right"></i>}
        {!isFold && <i className="far fa-chevron-left"></i>}
      </Button>
    </FoldWrap>
  )
}

export default FoldBtn
