import { Checkbox } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  TableContent,
  InnerTable,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  innerSubHeaderWidth,
  TableSubHeaderFullWidth,
  StyledTable,
  TextArea,
  RadioButtonsWrap,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import theme from 'lib/styles/theme'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsBasic2 = ({ onUpdate }) => {
  const { watch, setValue } = useFormContext()

  const d_descript_type = watch('d_descript_type')
  const d_land_right_owner = watch('d_land_right_owner')
  const d_building_right_owner = watch('d_building_right_owner')
  const d_land_right_other = watch('d_land_right_other')
  const d_building_right_other = watch('d_building_right_other')
  const d_minganrent_deunglog = watch('d_minganrent_deunglog')
  const d_minganrent_other = watch('d_minganrent_other')
  const d_minganrent_period = watch('d_minganrent_period')
  const d_minganrent_date = watch('d_minganrent_date')
  const d_minganrent_mideunglog = watch('d_minganrent_mideunglog')
  const d_renewal_right = watch('d_renewal_right')
  const d_is_submit_dagagu = watch('d_is_submit_dagagu')

  const isNonResidentailBuilding = d_descript_type == '비주거용 건축물'

  const getRowSpan = useCallback(() => {
    if (d_descript_type == '비주거용 건축물') {
      return 17
    } else {
      return 10
    }
  }, [d_descript_type])

  const getEachRowHeight = useCallback(() => {
    if (d_descript_type == '비주거용 건축물') {
      return [60, 25, 15]
    } else {
      return [100]
    }
  }, [d_descript_type])

  const eachRowHeight = useMemo(
    () => getEachRowHeight(),
    [d_descript_type],
  )

  return (
    <>
      <Description>
        * 그 밖에 추가적인 소유권 정보를 확인해 주세요.
      </Description>
      <StyledTable>
        <Row height={20} rowspan={getRowSpan()} noborderbottom>
          <TableHeader width={headerWidth}>② 권리관계</TableHeader>
          <RowSpanInner
            rowspanheightpercent={eachRowHeight[0]}
            minuswidth={headerWidth}
            noborderbottom={d_descript_type == '토지'}
          >
            <TableSubHeader width={headerWidth}>
              등기부
              <br />
              기재사항
            </TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={12}>
                  <InnerTableBlock widthpercent={50} noborderbottom>
                    <TableSubHeaderFullWidth>
                      소유권에 관한 사항
                    </TableSubHeaderFullWidth>
                  </InnerTableBlock>
                  <InnerTableBlock widthpercent={50} noborderbottom>
                    <TableSubHeaderFullWidth>
                      소유권 외의 권리사항
                    </TableSubHeaderFullWidth>
                  </InnerTableBlock>
                </Row>
                <Row heightpercent={88} noborderbottom>
                  <InnerTableBlock widthpercent={50} noborderbottom>
                    <Row rowspan={2} heightpercent={100} noborderbottom>
                      <RowSpanInner
                        noborderbottom={!isNonResidentailBuilding}
                        fullwidth
                        rowspan={2}
                      >
                        <TableSubHeader width={innerSubHeaderWidth}>
                          토지
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <TextArea
                            fullwidth
                            fullheight
                            defaultValue={d_land_right_owner}
                            onBlur={(e) =>
                              onUpdate(
                                'd_land_right_owner',
                                e.target.value,
                              )
                            }
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                      <RowSpanInner
                        noborderbottom
                        fullwidth
                        rowspan={2}
                      >
                        <TableSubHeader width={innerSubHeaderWidth}>
                          건축물
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <TextArea
                            fullwidth
                            fullheight
                            defaultValue={d_building_right_owner}
                            onBlur={(e) =>
                              onUpdate(
                                'd_building_right_owner',
                                e.target.value,
                              )
                            }
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                    </Row>
                  </InnerTableBlock>
                  <InnerTableBlock
                    widthpercent={50}
                    noborderright
                    noborderbottom
                  >
                    <Row rowspan={2} heightpercent={100} noborderbottom>
                      <RowSpanInner
                        noborderbottom={!isNonResidentailBuilding}
                        fullwidth
                        rowspan={2}
                      >
                        <TableSubHeader width={innerSubHeaderWidth}>
                          토지
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <TextArea
                            fullwidth
                            fullheight
                            defaultValue={d_land_right_other}
                            onBlur={(e) =>
                              onUpdate(
                                'd_land_right_other',
                                e.target.value,
                              )
                            }
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                      <RowSpanInner
                        noborderbottom
                        fullwidth
                        rowspan={2}
                      >
                        <TableSubHeader width={innerSubHeaderWidth}>
                          건축물
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <TextArea
                            fullwidth
                            fullheight
                            defaultValue={d_building_right_other}
                            onBlur={(e) =>
                              onUpdate(
                                'd_building_right_other',
                                e.target.value,
                              )
                            }
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                    </Row>
                  </InnerTableBlock>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
          {isNonResidentailBuilding && (
            <>
              <RowSpanInner
                rowspanheightpercent={eachRowHeight[1]}
                rowspan={2}
                minuswidth={headerWidth}
              >
                <StyledTableSubHeader
                  width={headerWidth + innerSubHeaderWidth}
                >
                  <TableSubHeader width={headerWidth}>
                    민간 임대
                    <br />
                    등록 여부
                  </TableSubHeader>
                  <StlyedTableSubHeaderVertical>
                    <TableSubHeaderCustomHeight height={66}>
                      등록
                    </TableSubHeaderCustomHeight>
                    <TableSubHeaderCustomHeight height={34}>
                      미등록
                    </TableSubHeaderCustomHeight>
                  </StlyedTableSubHeaderVertical>
                </StyledTableSubHeader>
                <TableContent fullwidth nopadding noborderright>
                  <InnerTable>
                    <Row heightpercent={33}>
                      <InnerTableContent checkboxes>
                        <RadioButtonsWrap width={460}>
                          <RadioButton
                            label="장기일반민간임대주택"
                            checked={
                              d_minganrent_deunglog ==
                              '장기일반민간임대주택'
                            }
                            name="d_minganrent_deunglog"
                            onChange={(e) =>
                              onUpdate(
                                'd_minganrent_deunglog',
                                '장기일반민간임대주택',
                              )
                            }
                          />
                          <RadioButton
                            label="공공지원민간임대주택"
                            checked={
                              d_minganrent_deunglog ==
                              '공공지원민간임대주택'
                            }
                            name="d_minganrent_deunglog"
                            onChange={(e) =>
                              onUpdate(
                                'd_minganrent_deunglog',
                                '공공지원민간임대주택',
                              )
                            }
                          />
                          <RadioButton
                            label="그 밖의 유형"
                            checked={
                              d_minganrent_deunglog == '그 밖의 유형'
                            }
                            name="d_minganrent_deunglog"
                            onChange={(e) =>
                              onUpdate(
                                'd_minganrent_deunglog',
                                '그 밖의 유형',
                              )
                            }
                          />
                        </RadioButtonsWrap>
                        <ColorInput
                          type="text"
                          defaultValue={d_minganrent_other}
                          disabled={
                            d_minganrent_deunglog != '그 밖의 유형'
                          }
                          onBlur={(e) =>
                            onUpdate(
                              'd_minganrent_other',
                              e.target.value,
                            )
                          }
                          fullWidth
                        />
                      </InnerTableContent>
                    </Row>
                    <Row heightpercent={33} noborderbottom>
                      <InnerTableBlock widthpercent={50} noborderbottom>
                        <Row heightpercent={100}>
                          <TableSubHeader width={innerSubHeaderWidth}>
                            임대의무기간
                          </TableSubHeader>
                          <InnerTableContent
                            headerwidth={innerSubHeaderWidth}
                          >
                            <ColorInput
                              type="text"
                              defaultValue={d_minganrent_period}
                              onBlur={(e) =>
                                onUpdate(
                                  'd_minganrent_period',
                                  e.target.value,
                                )
                              }
                              fullWidth
                            />
                          </InnerTableContent>
                        </Row>
                      </InnerTableBlock>
                      <InnerTableBlock
                        widthpercent={50}
                        noborderright
                        noborderbottom
                      >
                        <Row heightpercent={100}>
                          <TableSubHeader width={innerSubHeaderWidth}>
                            임대개시일
                          </TableSubHeader>
                          <InnerTableContent
                            headerwidth={innerSubHeaderWidth}
                          >
                            <ColorInput
                              type="text"
                              defaultValue={d_minganrent_date}
                              onBlur={(e) =>
                                onUpdate(
                                  'd_minganrent_date',
                                  e.target.value,
                                )
                              }
                              fullWidth
                            />
                          </InnerTableContent>
                        </Row>
                      </InnerTableBlock>
                    </Row>
                    <Row heightpercent={36} noborderbottom>
                      <InnerTableContent pl checkboxes>
                        <Checkbox
                          color="blue"
                          checked={
                            d_minganrent_mideunglog == 'Y'
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            onUpdate(
                              'd_minganrent_mideunglog',
                              e ? 'Y' : 'N',
                            )
                          }
                        >
                          해당사항 없음
                        </Checkbox>
                      </InnerTableContent>
                    </Row>
                  </InnerTable>
                </TableContent>
              </RowSpanInner>
              <RowSpanInner
                rowspanheightpercent={eachRowHeight[2]}
                minuswidth={headerWidth}
                noborderbottom
              >
                <TableSubHeader
                  width={headerWidth + innerSubHeaderWidth}
                >
                  계약갱신 요구권 행사 여부
                </TableSubHeader>
                <TableContent fullwidth nopadding noborderright>
                  <InnerTableContent checkboxes>
                    <RadioButton
                      label="확인&#40;확인서류 첨부&#41;"
                      checked={d_renewal_right == '확인'}
                      name="d_renewal_right"
                      onChange={(e) =>
                        onUpdate('d_renewal_right', '확인')
                      }
                    />
                    <RadioButton
                      label="미확인"
                      checked={d_renewal_right == '미확인'}
                      name="d_renewal_right"
                      onChange={(e) =>
                        onUpdate('d_renewal_right', '미확인')
                      }
                    />
                    <RadioButton
                      label="해당없음"
                      checked={d_renewal_right == '해당없음'}
                      name="d_renewal_right"
                      onChange={(e) =>
                        onUpdate('d_renewal_right', '해당없음')
                      }
                    />
                  </InnerTableContent>
                </TableContent>
              </RowSpanInner>
            </>
          )}
        </Row>
      </StyledTable>
    </>
  )
}

const TableSubHeaderCustomHeight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ height }) => `height: ${height}%`}
`

const StyledTableSubHeader = styled(TableSubHeader)`
  padding: 0;
  justify-content: flex-start;
`

const StlyedTableSubHeaderVertical = styled(TableSubHeader)`
  padding: 0;
  flex-direction: column;
  flex: 1;
  border-right: none;

  & > div {
    width: 100%;
    &:first-child {
      border-bottom: 1px solid ${theme.colors.gray[200]};
    }
  }
`

const Description = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 130%;
  font-weight: 500;
  color: ${theme.colors.tint.danger};
`

export default NoteDocsBasic2
