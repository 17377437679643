import { useFormContext } from 'react-hook-form'
import InputDate from '../../elements/InputDate'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { Button, Checkbox } from 'components/design'
import styled from 'styled-components'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import moment from 'moment'
import { useSelector } from 'react-redux'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증방식 - 현장확인 희망일
 */
const NoteAdColumnSceneDate = ({ isDisabled: pIsDisabled, half }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    pIsDisabled ||
    (adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll) //읽기 전용 여부

  const rdate = watch('verification.rdate')

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>현장확인 희망일</ColumnTitle>
      <ColumnVal gap={12}>
        <DateWrapper>
          <InputDate
            type="date"
            {...register('verification.rdate')}
            onChange={(e) => {
              onUpdate(
                'verification.rdate',
                e ? moment(e).format('YYYYMMDD') : '',
              )
            }}
            placeholder="연도.월.일"
            expireDate
            fixAppendWidth
            value={
              rdate && rdate != 'NOW'
                ? moment(rdate).format('YYYY.MM.DD')
                : ''
            }
            disabled={isDisabled}
          />
        </DateWrapper>
        {/* {!isDisabled && (
          <Button
            color="blue"
            size="small"
            onClick={() => alert('모름')}
          >
            가능 여부 조회
          </Button>
        )} */}

        <ValidateWarn>
          {errors?.verification?.rdate?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  span {
    margin-left: 10px;
    margin-right: 2px;
    word-break: keep-all;
  }
`

export default NoteAdColumnSceneDate
