import { Modal } from 'components/design'
import { useEffect } from 'react'

const NoteNoPlanPopup = ({ title, visible, onClose, onOk }) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      okButtonProps={{ color: 'blue' }}
      onOk={onOk}
      okText="네, 발급할래요"
      cancelText="아니요"
    >
      계약서와 확인설명서를 자동으로 채워드려요.
    </Modal>
  )
}

export default NoteNoPlanPopup
