import {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react'
import {
  funcMakePolygon,
  funcSetActionsClickposition,
  getAddress,
  getAddressName,
  setBuildingPolygons,
} from 'lib/utils/index'
import { useHistory } from 'react-router-dom'
import SearchContext from 'contexts/searchContext'
import MapContext from 'contexts/map'
import SearchResultList from 'components/search/SearchResultList'
import Container from 'components/base/Container/Container'
import SearchHeader from 'components/search/SearchHeader'
import SearchInput from 'components/search/SearchInput'
import SEOHead from 'components/common/SEOHead'
import {
  Button,
  List,
  ListItem,
  CardHeader,
  Empty,
} from 'components/design'
import { uniq } from 'lodash-es'
import styled from 'styled-components'
import { notification, Skeleton } from 'antd'
import theme, { media } from 'lib/styles/theme'
import HeaderBackAbsolute from 'components/common/Header/HeaderBackAbsolute'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useDispatch } from 'react-redux'
import { setOpenFilterType } from 'modules/map'
import axios from 'axios'

const SearchContainer = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const { kakao } = window

  const [isSearched, setIsSearched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [recentSearchKeywords, setRecentSearchKeywords] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchInput, setSearchInput] = useState('')

  const {
    state: { address, places, message },
    actions: { setSearchVisible, setAddress, setPlaces },
  } = useContext(SearchContext)

  const {
    state: { map },
    actions,
  } = useContext(MapContext)

  useEffect(() => {
    getRecentKeywords()
  }, [])

  useEffect(() => {
    if (isSearched) setIsSearched(false)
  }, [searchInput])

  useEffect(() => {
    if (isMobile) {
      dispatch(setOpenFilterType(null))
    }
  }, [isMobile])

  const onSearch = (value) => {
    setIsLoading(true)
    setIsSearched(false)

    if (!value) {
      resultClear()
      return
    }

    saveRecentKeyword(value)
    searchAddress(value)
    setSearchKeyword(value)
  }

  const onClose = () => {
    if (!searchInput) {
      setSearchInput('')
      resultClear()
      setSearchVisible(false)
      history.go(-1)
      return
    }

    setSearchInput('')
    onClear()
  }

  const resultClear = () => {
    setAddress([])
    setPlaces([])
  }

  const onClear = () => {
    setIsSearched(false)
    resultClear()
  }

  const getRecentKeywords = () => {
    const recentSearchQueries =
      localStorage.getItem('recentSearchQueries') || '[]'
    const parsedList = JSON.parse(recentSearchQueries)

    const computedList = uniq([...parsedList].reverse())

    setRecentSearchKeywords(computedList)

    return computedList
  }

  const saveRecentKeyword = (value) => {
    // console.log('최근 검색어 저장')
    const recentSearchQueries = getRecentKeywords()

    localStorage.setItem(
      'recentSearchQueries',
      JSON.stringify([...recentSearchQueries.reverse(), value]),
    )

    getRecentKeywords()
  }

  // @METHOD: 주소 검색
  const searchAddress = (value) => {
    // map이 로드 안되어 있으면 return
    if (Object.keys(map).length === 0) return

    resultClear()

    const geocoder = new kakao.maps.services.Geocoder()

    //주소 검색 임시 api 세팅
    axios
      .get(`/map/api/searchAddress?query=${value}`)
      .then((response) => {
        const status = response.status
        const result = response.data.documents
        // 정상적으로 검색이 완료됐으면
        if (status === 200 && result) {
          if (result.length > 0) {
            // 카카오 장소 검색
            searchPlaces(value, result)
          } else {
            // 우리 db에 등록된 주소가 없다면 카카오의 '키워드로 장소검색하고 목록으로 표출하기' 라이브러리를 사용합니다.
            searchPlaces(value)
          }
        } else if (status !== 200) {
          openNotification(
            '검색 결과 중 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
          )
        }
      })

    // geocoder.addressSearch(value, (result, status) => {
    //   // 정상적으로 검색이 완료됐으면
    //   if (status === kakao.maps.services.Status.OK) {
    //     // 카카오 장소 검색
    //     searchPlaces(value, result)
    //   } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
    //     // 우리 db에 등록된 주소가 없다면 카카오의 '키워드로 장소검색하고 목록으로 표출하기' 라이브러리를 사용합니다.
    //     searchPlaces(value)
    //   } else if (status === kakao.maps.services.Status.ERROR) {
    //     openNotification(
    //       '검색 결과 중 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
    //     )
    //   }
    // })
  }

  // @METHOD: 키워드 검색
  const searchPlaces = (keyword, result = null) => {
    // console.log('searchPlaces: ', keyword)
    let places = new kakao.maps.services.Places()

    if (!keyword.replace(/^\s+|\s+$/g, '')) {
      alert('키워드를 입력해주세요!')
      return false
    }

    //주소 검색 임시 api 세팅
    axios
      .get(`/map/api/searchKeyword?query=${keyword}`)
      .then((response) => {
        const status = response.status
        const data = response.data.documents

        if (status === 200 && data && data.length > 0) {
          if (result) setAddress(result)
          else setPlaces(data)
        } else if (
          status === 200 &&
          data &&
          data.length == 0 &&
          result
        ) {
          //ZERO_RESULT
          // DB 주소 검색
          const latlng = new kakao.maps.LatLng(
            result[0]?.y,
            result[0]?.x,
          )
          // setClickPosition
          getAddress(latlng.getLat(), latlng.getLng()).then(
            (response) => {
              if (response?.data.result && response.data.result.shape) {
                // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                map.setCenter(latlng)

                // zoom level 확대합니다.
                map.setLevel(2)

                const result = response.data.result
                const address = getAddressName(result)

                // Context API에 셋팅
                funcSetActionsClickposition(result, address, actions)

                // 토지 폴리곤 세팅
                const landPolygons = funcMakePolygon(result, kakao)
                actions.setPolygons(landPolygons)

                // 건물 폴리곤 배열 셋팅
                setBuildingPolygons(result, kakao, actions)

                // 주소 검색창에 full 주소 세팅
                // setSearchInput(address);

                setSearchVisible(false)

                // history 설정
                history.push(`/${result.id}`)
              }
            },
          )
          return
        } else if (status !== 200) {
          openNotification(
            '검색 결과 중 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
          )
          return
        }

        setIsLoading(false)
        setIsSearched(true)
      })

    // places.keywordSearch(keyword, (data, status, pagination) => {
    //   if (status === kakao.maps.services.Status.OK) {
    //     // setPlaces(result || data)
    //     if (result) setAddress(result)
    //     else setPlaces(data)
    //   } else if (
    //     status === kakao.maps.services.Status.ZERO_RESULT &&
    //     result
    //   ) {
    //     // DB 주소 검색
    //     const latlng = new kakao.maps.LatLng(result[0]?.y, result[0]?.x)
    //     // setClickPosition
    //     getAddress(latlng.getLat(), latlng.getLng()).then(
    //       (response) => {
    //         if (response?.data.result && response.data.result.shape) {
    //           // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
    //           map.setCenter(latlng)

    //           // zoom level 확대합니다.
    //           map.setLevel(2)

    //           const result = response.data.result
    //           const address = getAddressName(result)

    //           // Context API에 셋팅
    //           funcSetActionsClickposition(result, address, actions)

    //           // 토지 폴리곤 세팅
    //           const landPolygons = funcMakePolygon(result, kakao)
    //           actions.setPolygons(landPolygons)

    //           // 건물 폴리곤 배열 셋팅
    //           setBuildingPolygons(result, kakao, actions)

    //           // 주소 검색창에 full 주소 세팅
    //           // setSearchInput(address);

    //           setSearchVisible(false)

    //           // history 설정
    //           history.push(`/${result.id}`)
    //           console.log('1')
    //         }
    //       },
    //     )
    //     return
    //   } else if (status === kakao.maps.services.Status.ERROR) {
    //     openNotification(
    //       '검색 결과 중 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
    //     )
    //     return
    //   }

    //   setIsLoading(false)
    //   setIsSearched(true)
    // })
  }

  const goToSummary = async (lat, lng) => {
    const res = await getAddress(lat, lng)

    if (res.data.result && res.data.result.shape) {
      // map이 로드 안되어 있으면 return
      if (Object.keys(map).length === 0) return
      // zoom level 확대합니다.
      map.setLevel(2)
      history.push(`/${res.data.result.id}`)
    } else {
      openNotification('결과가 없습니다.')
    }
  }

  // 장소 클릭 시 해당 토지로 이동
  const onClickItem = useCallback(
    ({ latitude, longitude, isNote = false }) => {
      // 매물 추가 버튼을 클릭한 경우라면 이동하지 않음
      if (isNote) return

      setSearchInput('')
      goToSummary(latitude, longitude)
      onClose()
    },
  )

  const computedSearchResult = (address, places) => {
    const transpiledAddress = address.map((item) => {
      const result = {
        title: item.address_name,
        subTitle: item?.road_address?.address_name,
        x: item.x,
        y: item.y,
      }

      return result
    })

    const transpiledPlaces = places.map((item) => {
      const result = {
        title: item.place_name,
        subTitle: item.address_name,
        x: item.x,
        y: item.y,
      }

      return result
    })

    return [...transpiledAddress, ...transpiledPlaces]
  }
  const searchResults = useMemo(
    () => computedSearchResult(address, places),
    [address, places],
  )

  const openNotification = (message) => {
    notification['info']({
      message: '알림',
      description: message,
    })
  }

  const onClickRecentQuery = (query) => {
    resultClear()
    setSearchInput(query)
    setSearchKeyword(query)
    onSearch(query)
  }

  // 최근 검색어 삭제
  const deleteRecentSearch = (item) => {
    // console.log('item: ', item)
    // 로컬스토리지에서 삭제
    localStorage.removeItem('recentSearchQueries')

    const filteredArray = recentSearchKeywords.filter(
      (word) => item !== word,
    )
    setRecentSearchKeywords(filteredArray)

    localStorage.setItem(
      'recentSearchQueries',
      JSON.stringify([...filteredArray]),
    )
  }

  return (
    <>
      <SEOHead />
      {isMobile && <HeaderBackAbsolute onClose={onClose} />}

      <SearchHeader onClose={onClose}>
        <SearchInput
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          onSearch={onSearch}
          onClear={onClear}
          autoFocus
        />
      </SearchHeader>

      <Container white>
        {!isLoading ? (
          isSearched ? (
            <>
              {searchResults.length === 0 && searchKeyword && (
                <>
                  <SearchHeaderSpace />
                  <Empty>
                    <span>
                      <strong>'{searchInput}'</strong>에 대한
                      <br /> 주소 검색 결과가 없습니다.
                    </span>
                  </Empty>
                </>
              )}

              {searchResults.length > 0 && (
                <SearchResultList
                  searchResults={searchResults}
                  onClickItem={onClickItem}
                />
              )}
            </>
          ) : (
            recentSearchKeywords.length > 0 && (
              <>
                <CardHeader>최근 검색어</CardHeader>
                <List>
                  {recentSearchKeywords.map((item, i) => (
                    <ListItem
                      key={i}
                      dense
                      hover
                      onClick={() => onClickRecentQuery(item)}
                      append={
                        <div onClick={() => deleteRecentSearch(item)}>
                          <Button size="small">삭제</Button>
                        </div>
                      }
                    >
                      {item}
                    </ListItem>
                  ))}
                </List>
              </>
            )
          )
        ) : (
          <SkeletonWrap>
            <Skeleton active />
          </SkeletonWrap>
        )}
      </Container>
    </>
  )
}

const SearchHeaderSpace = styled.div`
  height: ${theme.base.headerHeightM}px;

  ${media.mediumS`
    height: ${theme.base.navWidth}px;
  `}
`

const SkeletonWrap = styled.div`
  padding: 1rem;
`

export default SearchContainer
