import { useContext, useEffect } from 'react'
import useUserLoader from './hooks/useUserLoader'
import AuthModalContainer from 'containers/auth/AuthModalContainer'
import MapContext from 'contexts/map'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import RewardEndSignModalContainer from 'containers/auth/RewardEndSignModalContainer'

/**
 * @name: Core
 * @description: 각종 store 선언과 GlobalStyle을 제외하고
 * 가장 먼저 실행되는 로직을 담고 있습니다.
 * 현재는 user정보를 불러온 후에 children을 렌더링하도록
 * 하는 작업을 진행합니다.
 * @WARN: 아래에 지도 관련 로직들은 이곳에 담는 것을 지양
 * 하길 바랍니다. Core는 서비스를 렌더링하기 전 선행되어야
 * 하는 로직들을 수행하기 때문에 불필요한 코드는 삼가해야
 * 합니다.
 */
const Core = ({ children }) => {
  const { loading, data } = useUserLoader()
  const [cookies] = useCookies(['clickPosition'])
  const { kakao } = window
  const { state, actions } = useContext(MapContext)
  const { map } = state

  useEffect(() => {
    if (Object.keys(map).length === 0 || !cookies) return

    // 가격 단위 토글
    if (cookies.isTotal) {
      actions.setIsTotal(cookies.isTotal === 'unit' ? false : true)
    }
    if (cookies.isSquareMeter) {
      actions.setIsSquareMeter(
        cookies.isSquareMeter === 'squareMeter' ? true : false,
      )
    }
  }, [kakao, map, cookies])

  if (loading) return null
  if (!data) return null

  return (
    <>
      {children}
      <AuthModalContainer />
      <RewardEndSignModalContainer />
    </>
  )
}

export default Core
