import styled from 'styled-components'
import { Section, Title } from './BdsDetailStyle'
import NoProfileImg from 'assets/images/note/bds/person.png'
import theme from 'lib/styles/theme'
import { Button } from 'components/design'
import BdsDetailRealestateContainer from 'containers/bds/BdsDetailRealestateContainer'

const BdsDetailRealestate = ({ data, gather, realtorMode }) => {
  return (
    <BdsDetailRealestateContainer>
      {({ onClickTel, onClickGather }) => {
        return (
          <Section>
            <Title>공인중개사</Title>
            <Wrapper>
              <ProfileImg src={NoProfileImg} />
              <Info>
                <InfoTitle>
                  {data.realtor?.rname || <>상호명 없음</>}
                </InfoTitle>
                <InfoRow>
                  <InfoRowTitle>대 표</InfoRowTitle>
                  <InfoRowValue>
                    {data.realtor?.name || <>&ndash;</>}
                  </InfoRowValue>
                </InfoRow>
                <InfoRow>
                  <InfoRowTitle>주 소</InfoRowTitle>
                  <InfoRowValue>
                    {data.realtor?.addr || <>&ndash;</>}
                  </InfoRowValue>
                </InfoRow>
                <InfoRow>
                  <InfoRowTitle>등록번호</InfoRowTitle>
                  <InfoRowValue>
                    {data.realtor?.estbRgstNo || <>&ndash;</>}
                  </InfoRowValue>
                </InfoRow>
              </Info>
            </Wrapper>
            <ButtonWrapper>
              <TelButton
                expand
                color="gray"
                onClick={(e) =>
                  onClickTel(e, data.extra_vars?.phone_number)
                }
                href={`tel:${data.extra_vars?.phone_number}`}
              >
                문의하기
              </TelButton>
              {!realtorMode && (
                <Button
                  expand
                  color="blue"
                  onClick={() =>
                    onClickGather(data?.realtor_number, data?.realtor)
                  }
                >
                  이 중개사 매물 모아보기
                </Button>
              )}
            </ButtonWrapper>
          </Section>
        )
      }}
    </BdsDetailRealestateContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const ProfileImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`

const Info = styled.div``

const InfoTitle = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 120%;
`

const InfoRow = styled.div`
  display: flex;
  align-items: center;
`

const InfoRowTitle = styled.div`
  width: 53px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[600]};
`

const InfoRowValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`

const TelButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
  color: ${theme.colors.gray[700]};
  font-size: 14px;
  font-weight: 700;
  line-height: 180%;

  &:hover {
    color: ${theme.colors.gray[700]};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 24px;
`

export default BdsDetailRealestate
