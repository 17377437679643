import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDonghoHeaderDongListMobile from './NoteDonghoHeaderDongListMobile'
import NoteDonghoHeaderDongSelectorMobile from './NoteDonghoHeaderDongSelectorMobile'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 동호관리 모바일
 * 동 선택
 */
const NoteDonghoHeaderDongsMobile = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const params = useParams()
  const history = useHistory()
  const dongs = useSelector(
    (state) => state.noteDongho.selectedDanjiDongs,
  )
  const selectedDong = useSelector(
    (state) => state.noteDongho.selectedDong,
  )

  const currentIdx = useMemo(
    () => dongs.findIndex((dong) => dong == selectedDong),
    [dongs, selectedDong],
  )

  /**
   * 동 드롭다운 열기
   */
  const onOpen = () => {
    setDropdownVisible(true)
  }

  /**
   * 동 드롭다운 닫기
   */
  const onClose = () => {
    setDropdownVisible(false)
  }

  /**
   * 이전
   */
  const onClickPrev = (e) => {
    if (currentIdx == 0) return

    history.replace(
      `/note/v2/dongho/${params?.id}/${dongs[currentIdx - 1]}`,
    )
  }

  /**
   * 다음
   */
  const onClickNext = (e) => {
    if (currentIdx == dongs.length - 1) return

    history.replace(
      `/note/v2/dongho/${params?.id}/${dongs[currentIdx + 1]}`,
    )
  }

  return (
    <Wrapper>
      <Arrow
        disabled={currentIdx == 0}
        onClick={onClickPrev}
        className="fal fa-angle-left"
      />
      <NoteDonghoHeaderDongSelectorMobile openDropdown={onOpen} />
      <Arrow
        disabled={currentIdx == dongs.length - 1}
        className="fal fa-angle-right"
        onClick={onClickNext}
      />
      <NoteDonghoHeaderDongListMobile
        visible={dropdownVisible}
        onClose={onClose}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 12px;
`

const Arrow = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 36px;
  color: ${theme.colors.gray[600]};

  ${({ disabled }) =>
    disabled &&
    `
    color: ${theme.colors.gray[300]};
  `}
`

export default NoteDonghoHeaderDongsMobile
