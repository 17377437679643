import { Button } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'
import { media } from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * 올인원 결제 - 본인인증 폼 버튼
 */
const NotePaymentAuthFormButtons = ({ onClose }) => {
  const isMobile = useIsMobile()
  return (
    <Wrapper>
      <Button
        color="blue"
        size={isMobile ? 'medium' : 'xlarge'}
        type="submit"
      >
        다음
      </Button>
      <Button
        color="blue"
        size={isMobile ? 'medium' : 'xlarge'}
        plain
        onClick={onClose}
      >
        이전 화면으로 가기
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  margin-top: 32px;

  button {
    min-width: 200px;

    ${media.mediumM`
      min-width: unset;
    `}
  }

  ${media.mediumS`
    gap: 12px;
    margin-top: 40px;
  `}
`

export default NotePaymentAuthFormButtons
