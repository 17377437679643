import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAddress, getLocationRequirements } from 'lib/api/summaryApi'
import { getComputedData } from 'lib/utils/lr'
import useAxios from 'lib/hooks/useAxios'
import SummaryLr from 'components/summary/SummaryLr/SummaryLr'

const SummaryHcLr = ({ data }) => {
  const tabs = ['학교·교통', '의료·안전', '쇼핑·편의']
  const [activedTab, setActivedTab] = useState(0) // 0: 학교/교통, 1: 의료/안전, 2:쇼핑/편의

  const centerPoint = useSelector((state) => state.summary.centerPoint)

  const [onRequestRequirements, loadingRequirements, dataRequirements] =
    useAxios(getLocationRequirements)

  const [onRequestAddress, , dataAddress, , ,] = useAxios(getAddress)

  useEffect(() => {
    if (!data) return
    setActivedTab(0)

    onRequestAddress(
      {
        latitude: data?.centerpoint?.coordinates?.[0],
        longitude: data?.centerpoint?.coordinates?.[1],
      },
      null,
      false,
    )
  }, [data])

  useEffect(() => {
    if (!data || !dataAddress || !dataAddress.result) return

    onRequestRequirements(
      {
        activedTab,
        latitude: data?.centerpoint?.coordinates?.[0],
        longitude: data?.centerpoint?.coordinates?.[1],
        address_id: dataAddress.result.id,
      },
      null,
      false,
    )
  }, [dataAddress, activedTab])

  const computedData = useMemo(
    () => getComputedData(dataRequirements, centerPoint),
    [dataRequirements, centerPoint],
  )

  return (
    <SummaryLr
      tabs={tabs}
      activedTab={activedTab}
      setActivedTab={setActivedTab}
      centerPoint={centerPoint}
      data={computedData}
      originData={dataRequirements?.result || null}
      loading={loadingRequirements}
      limitDistance={dataRequirements?.result?.limit_distance || 0.1}
    />
  )
}

export default SummaryHcLr
