import {
  Dropdown,
  DropdownDialog,
  DropdownHeader,
} from 'components/design'
import ReactDOM from 'react-dom'
import NoteList from '../noteList/NoteList'

const AddressViewMobile = ({
  visible,
  notes,
  channelSrl,
  folderSrl,
  addressSrl,
  onClose,
  setIsVisibleNoteDetail,
  setMobileData,
  Markerclick,
}) => {
  const root = document.getElementById('modal')

  return ReactDOM.createPortal(
    <Dropdown
      visible={visible}
      onClose={onClose}
      overlay={
        <DropdownDialog
          // height={500}
          header={<DropdownHeader>매물 선택</DropdownHeader>}
        >
          <NoteList
            Markerclick={Markerclick}
            setMobileData={setMobileData}
            setIsVisibleNoteDetail={setIsVisibleNoteDetail}
            mapVisible
            notes={notes}
            channelSrl={channelSrl}
            folderSrl={folderSrl}
            addressSrl={addressSrl}
            onClick={onClose}
          />
        </DropdownDialog>
      }
    ></Dropdown>,
    root,
  )
}

export default AddressViewMobile
