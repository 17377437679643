import { Tab, TabItem } from 'components/design'
import useMutationObserve from 'components/summary/SummaryTab/useMutationObserve'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import theme, { media } from 'lib/styles/theme'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

const NoteDetailTab = ({ activeTab, onChangeTab, tabs }, ref) => {
  const { isAllInOne } = useIsAllInOne()
  const isSharePage = useRouteMatch('/share/:noteSrl/:encryptedKey')
  const tabRef = useRef(null)
  const { observeSection, activeIdx } = useMutationObserve(
    tabs,
    'note-tab-active-section',
    false,
  )

  const [visiblePositionTab, setVisiblePositionTab] = useState(false)

  useEffect(() => {
    if (!tabs || Object.keys(tabs).length == 0) return
    observeSection()
  }, [tabs])

  /**
   * 탭이 sticky 되었는지
   */
  useEffect(() => {
    if (!ref?.current?.view || !tabRef?.current) return
    const el = tabRef?.current

    ref?.current?.view.addEventListener('scroll', () => {
      const stickyTop = ref?.current?.view.getBoundingClientRect().top
      const currentTop = el.getBoundingClientRect().top
      setVisiblePositionTab(currentTop === stickyTop)
      el.classList.toggle('isSticky', currentTop === stickyTop)
    })
  }, [ref?.current?.resizable, tabRef?.current])

  return (
    <StyledTab noContainer ref={tabRef}>
      {activeTab == 1 && visiblePositionTab ? (
        Array.from(tabs).map((tab, index) => {
          return (
            <TabItem
              key={index}
              noContainer
              expand
              active={activeIdx == index}
              onClick={tab?.onMoveToElement}
            >
              {tab?.name}
            </TabItem>
          )
        })
      ) : (
        <>
          <TabItem
            expand
            active={activeTab == 1}
            onClick={() => onChangeTab(1)}
          >
            기본정보
          </TabItem>
          <TabItem
            expand
            active={activeTab == 2}
            onClick={() => onChangeTab(2)}
          >
            매물사진
          </TabItem>
          {!isSharePage?.isExact && (
            <>
              {' '}
              <TabItem
                expand
                active={activeTab == 3}
                onClick={() => onChangeTab(3)}
              >
                공부문서
              </TabItem>
              <TabItem
                expand
                active={activeTab == 4}
                onClick={() => onChangeTab(4)}
              >
                계약서
              </TabItem>
              <TabItem
                expand
                active={activeTab == 6}
                onClick={() => onChangeTab(6)}
              >
                일정
              </TabItem>
              {isAllInOne && (
                <TabItem
                  expand
                  active={activeTab === 5}
                  onClick={() => onChangeTab(5)}
                >
                  광고관리
                </TabItem>
              )}
            </>
          )}
        </>
      )}
    </StyledTab>
  )
}

const StyledTab = styled(Tab)`
  position: sticky;
  top: 0;
  background-color: ${theme.colors.base.white};

  &.isSticky {
    display: flex;
    gap: 8px;
    height: 50px;
    padding: 8.5px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;

    & > div {
      margin: 0;
      flex: unset;
      padding: 8px 10px;
      border-radius: 6px;

      &.active {
        background-color: ${theme.colors.blue[50]};
        color: ${theme.colors.blue[600]};
        border-bottom: none;
      }

      &:hover {
        background-color: ${theme.colors.blue[50]};
        color: ${theme.colors.blue[600]};
      }
    }

    ${media.mediumS`
        padding: 8.5px 4px;
        gap: 0px;
        width: 100%;
        overflow: hidden;
    `}
  }
`

export default forwardRef(NoteDetailTab)
