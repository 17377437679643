import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import { getJootekServiceURL } from 'utils'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  padding: 20px;
`

const ProfileSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ProfileMemberType = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.primary[500]};
`

const ProfileMemberName = styled.span`
  color: ${theme.colors.gray[900]};
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`

const ProfileNamePlus = styled.span`
  font-size: 18px;
  margin: 0;
`

const ProfileBody = styled.div`
  margin-left: 14px;
`

const ProfileImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 2px solid ${theme.colors.primary[400]};
  border-radius: 100%;

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
`

const ProfileSetting = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  &::after {
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    margin-left: 4px;
  }
`

const ProfileWrapper = styled.div`
  display: flex;
`

const SideMenuProfile = ({ onClose }) => {
  const memberType =
    JSON.parse(localStorage.getItem('memberType')) || ''
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const history = useHistory()

  if (!loginInfo?.member_srl) return null

  const isRealestate =
    loginInfo?.member_type === 'realestate' &&
    memberType &&
    memberType === 'realestate'

  const onClick = () => {
    if (isRealestate) {
      history.push('/note/v2/account')
      onClose()
    } else {
      window.location.href = getJootekServiceURL('/index/member_info')
    }
  }

  return (
    <Wrapper>
      <ProfileSummary>
        <ProfileWrapper>
          <ProfileImg>
            {loginInfo.profile_image ? (
              <img
                src={loginInfo.profile_image}
                alt={loginInfo.user_name}
              />
            ) : (
              <img src="/layouts/jootek/assets/images/ic_profile_default.png" />
            )}
          </ProfileImg>
          <ProfileBody>
            <ProfileMemberType>
              {loginInfo.member_type_text}
            </ProfileMemberType>
            <ProfileMemberName>{loginInfo.user_name}</ProfileMemberName>
            <ProfileNamePlus>님</ProfileNamePlus>
          </ProfileBody>
        </ProfileWrapper>
        <ProfileSetting onClick={onClick}>
          {isRealestate ? '계정관리' : '회원정보'}
        </ProfileSetting>
      </ProfileSummary>
    </Wrapper>
  )
}

export default SideMenuProfile
