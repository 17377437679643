import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import React from 'react'
import NoteDonghoHoDetailModal from './hoDetail/NoteDonghoHoDetailModal'
import NoteDonghoHoDetailContainer from 'containers/notev2/NoteDonghoHoDetailContainer'
import NoteDonghoDongTitle from './NoteDonghoDongTitle'
import NoteDonghoContractTypes from './NoteDonghoContractTypes'
import { useSelector } from 'react-redux'
import NoteDonghoLegend from './NoteDonghoLegend'
import { media } from 'lib/styles/theme'
import NoteDonghoBlockSection from './NoteDonghoBlockSection'

/**
 * 동호관리 블럭 wrapper
 */
const NoteDonghoDrawArea = ({
  dongList,
  groundData,
  undergroundData,
  sectionSizes,
  matchHoColors,
  activeTab,
  selectedDanji,
  onMouseOver,
  onMouseOut,
  legend,
  palette,
  anotherLegend,
}) => {
  const selectedDong = useSelector(
    (state) => state.noteDongho.selectedDong,
  )

  return (
    <ScrollbarWrapper>
      <Scrollbars autoHide>
        <NoteDonghoLegend
          anotherLegend={anotherLegend}
          legend={legend}
          activeTab={activeTab}
          palette={palette}
        />
        <NoteDonghoHoDetailContainer>
          {({ isVisibleHoDetail, onClickHo, onCloseHo, hoData }) => {
            return (
              <>
                <Wrapper>
                  {(selectedDong ? [...[selectedDong]] : [...dongList])
                    .sort(
                      (a, b) =>
                        a.replace(/동\s*$/, '') -
                        b.replace(/동\s*$/, ''),
                    )
                    .map((dong, i) => {
                      if (
                        (!groundData?.[dong] ||
                          Object.keys(groundData?.[dong])?.length ==
                            0) &&
                        !undergroundData?.[dong]
                      )
                        return null

                      return (
                        <Section
                          key={i}
                          width={sectionSizes[dong]?.width || 0}
                        >
                          <NoteDonghoDongTitle
                            selectedDanji={selectedDanji}
                            dong={dong}
                          />
                          {(activeTab == 3 || activeTab == 4) &&
                            selectedDanji?.state &&
                            selectedDanji?.state.length > 0 && (
                              <NoteDonghoContractTypes
                                dong={dong}
                                selectedDanji={selectedDanji}
                              />
                            )}
                          <NoteDonghoBlockSection
                            activeTab={activeTab}
                            undergroundData={undergroundData}
                            groundData={groundData}
                            dong={dong}
                            matchHoColors={matchHoColors}
                            onMouseOver={onMouseOver}
                            onMouseOut={onMouseOut}
                            onClick={onClickHo}
                            height={
                              Math.abs(sectionSizes[dong]?.height) || 0
                            }
                          />
                        </Section>
                      )
                    })}
                </Wrapper>
                <NoteDonghoHoDetailModal
                  visible={isVisibleHoDetail}
                  onCancel={onCloseHo}
                  data={hoData}
                />
              </>
            )
          }}
        </NoteDonghoHoDetailContainer>
      </Scrollbars>
    </ScrollbarWrapper>
  )
}

const ScrollbarWrapper = styled.div`
  width: 100%;
  flex: 1;
  height: calc(100% + 200px);
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px 70px;
  position: relative;
  width: 100%;
  height: inherit;
  padding: 50px 45px;

  ${media.smallPc`
    padding: 40px 24px;
  `}

  ${media.mediumM`
    padding: 0;
  `}
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 130px;

  ${({ width }) =>
    width &&
    `
    width: ${width}px;
    position: relative;
    max-width: 100%;
  `}

  ${media.mediumM`
    width: 100%;
  `}
`

export default React.memo(NoteDonghoDrawArea)
