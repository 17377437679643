import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { numberWithCommas } from 'utils'
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Tooltip,
} from 'recharts'
import { ListItem, List, Empty } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const SummaryOwnerInfoGraph = ({
  colors,
  CustomTooltip,
  getPercent,
}) => {
  const { owners } = useSelector((state) => state.summary.summaryInfo)

  const [data, setData] = useState([])

  useEffect(() => {
    setData(
      Object.entries(owners.owner_type_count_values)
        .map(([name, value]) => ({
          name,
          value: getPercentWidth(value),
          realValue: value,
        }))
        .sort((a, b) => b.value - a.value),
    )
  }, [])

  const getPercentWidth = (value) => {
    if (!value) return 0

    const array = Object.entries(owners.owner_type_count_values)
      .map(([name, value]) => ({
        name,
        value: getPercent(value, owners.total_count),
      }))
      .map(({ value }) => value)

    const smallerCount = array
      .map(Number)
      .filter((arrayValue) => arrayValue < 5).length

    const minusCount = smallerCount * 1

    const result =
      Number(getPercent(value, owners.total_count)) < 5
        ? Number(getPercent(value, owners.total_count)) + 1
        : Number(getPercent(value, owners.total_count)) - minusCount

    return result
  }

  return data.length > 0 ? (
    <>
      {owners.total_count > 10 ? (
        <figure>
          <ResponsiveContainer width="100%" height={220}>
            <PieChart>
              <Pie
                dataKey="value"
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={70}
                paddingAngle={0}
                startAngle={90}
                endAngle={-450}
                animationBegin={100}
                animationDuration={1200}
                stroke={null}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </figure>
      ) : (
        <PercentBar>
          {data.map((item, index, ori) => {
            return (
              <PercentItem
                key={index}
                color={colors[index]}
                width={item.value}
              />
            )
          })}
        </PercentBar>
      )}

      <List noContainer noMargin>
        {data.map((item, index) => (
          <ListItem
            hiddenDivider
            noContainer
            key={index}
            prepend={<StyledIcon color={colors[index]} />}
            padding="0.5rem"
            append={
              <Percentage active={index === 0}>
                {getPercent(item.realValue, owners.total_count)}%
              </Percentage>
            }
          >
            <div>
              <LabelName>{item.name}</LabelName>
              <LabelValue>
                {numberWithCommas(item.realValue)}명
              </LabelValue>
            </div>
          </ListItem>
        ))}
      </List>
    </>
  ) : (
    <EmptyWrapper>
      <Empty>소유자 구분 데이터가 없습니다.</Empty>
    </EmptyWrapper>
  )
}

export const StyledIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;

  ${({ color }) =>
    color &&
    `
    background-color: ${color};
  `}
`

export const Percentage = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
`

export const LabelName = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
`

export const LabelValue = styled.span`
  margin-left: 1.1rem;
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 400;
`

export const EmptyWrapper = styled.div`
  margin-top: 1rem;
`

export const PercentBar = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
  margin: 2.5rem 0 2rem 0;

  & > div:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  & > div:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
`

export const PercentItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width && width}%;
  margin-right: 2px;
  background-color: ${({ color }) => color && color};
  color: ${theme.colors.base.white};
  font-size: 0.75rem;
`

export default SummaryOwnerInfoGraph
