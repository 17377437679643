import { CardMore, CardTitle } from 'components/design'
import theme from 'lib/styles/theme'
import SummaryLobFolioButton from '../SummaryLob/SummaryLobFolioButton'
import styled from 'styled-components'
import { dateFormat } from 'utils'
import useViewMore from 'lib/hooks/useViewMore'
import { useEffect } from 'react'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import CardFold from 'components/design/Card/CardFold'
import { moreViewListCss } from '../style/SummaryStyle'

const SummaryLandOwnerTimeLine = ({ data }) => {
  const {
    viewCnt,
    viewMore,
    isOverflow,
    setViewMore,
    updateViewOverFlow,
    onClickMore,
    onClickFold,
  } = useViewMore()

  useEffect(() => {
    if (!data || data.length == 0) return

    setViewMore(data.length > 3)
  }, [data])

  useUpdateEffect(() => {
    updateViewOverFlow(data)
  }, [viewCnt])

  return (
    <Wrapper>
      <HeaderWrapper>
        <CardTitle>소유자</CardTitle>
      </HeaderWrapper>
      <StyledTimeline viewcnt={viewCnt}>
        {data.map((item, index) => (
          <li key={index}>
            <TimelineLabel>
              {dateFormat(item.change_date)}
            </TimelineLabel>
            {item.change_reason}
            <StyledTagUl>
              <StyledTagLi>
                <SummaryLobFolioButton label="소유구분">
                  <SpanWrapper>
                    {item.owner_type && <span>{item.owner_type}</span>}
                    {item.share && (
                      <span>{(item.share * 100).toFixed(2)}%</span>
                    )}
                    {item.residence && <span>{item.residence}</span>}
                  </SpanWrapper>
                </SummaryLobFolioButton>
              </StyledTagLi>
            </StyledTagUl>
          </li>
        ))}
      </StyledTimeline>
      {viewMore && <CardMore onClick={onClickMore} />}
      {isOverflow && <CardFold onClick={onClickFold} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 24px;
`

const StyledTimeline = styled.ul`
  list-style-type: none;
  ${moreViewListCss}
  & > li {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0 8px 16px;
    margin-left: 8px;

    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 1px solid ${theme.colors.gray[200]};
    }
    &::after {
      content: '';
      position: absolute;
      top: 15px;
      left: -4px;

      width: 9px;
      height: 9px;
      border-radius: 100%;
      background-color: ${theme.colors.gray[500]};
    }

    &:first-child {
      &::after {
        left: -6px;
        width: 13px;
        height: 13px;
        background-color: ${theme.colors.base.white};
        border: 2px solid ${theme.colors.gray[900]};
      }
      &::before {
        top: 21px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
`
const TimelineLabel = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[900]};
  font-weight: 700;
  margin-right: 16px;
  font-variant-numeric: tabular-nums;
`

const StyledTagUl = styled.div`
  width: 100%;
  margin-top: 4px;
`
const StyledTagLi = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  cursor: pointer;

  & > * {
    margin-bottom: 8px;
  }
`

const SpanWrapper = styled.div`
  & > span {
    &:not(:last-child) {
      &::after {
        content: '·';
        display: inline-block;
        margin: 0 4px;
      }
    }
  }
`

const HeaderWrapper = styled.div`
  padding-top: 24px;
  margin-bottom: 10px;
  border-top: 1px solid ${theme.colors.gray[100]};
`

export default SummaryLandOwnerTimeLine
