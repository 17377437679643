import theme, { media } from 'lib/styles/theme'
import { allInOnePrice } from 'lib/utils/notev2/util'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'
import DefaultProfileSrc from 'images/ic_profile_default.png'
import moment from 'moment'

const NoteDashboardProfileInfo = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const planInfo = useSelector((state) => state.auth.planInfo)

  const price = {
    프리: 0,
    베이직: 4900,
    올인원1: allInOnePrice[1],
    올인원6: allInOnePrice[6],
  }
  return (
    <Wrapper>
      <ProfileImg
        src={
          loginInfo?.profile_image
            ? loginInfo?.profile_image
            : DefaultProfileSrc
        }
      />
      <div>
        <Name>
          {loginInfo.user_name}
          <Position>{loginInfo.realtor_position}</Position>
        </Name>
        <Company>{loginInfo.company_name}</Company>
        <Plan>
          <PlanName>{planInfo?.payment_name || '프리'} 요금제</PlanName>
          <PlanDate>
            {planInfo?.next_payment_date ? (
              `${moment(
                planInfo.next_payment_date,
                'YYYYMMDDHHmmss',
              ).format('YYYY.MM.DD')} 결제 예정`
            ) : (
              <></>
            )}
          </PlanDate>
        </Plan>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  max-width: 55%;

  ${media.smallPc`
    max-width: unset;
  `}

  ${media.mediumM`
    gap: 18px;
  `}
`

const ProfileImg = styled.img`
  width: 90px;
  height: 90px;
  object-fit: contain;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  ${media.smallPc`
    margin: 0;
  `}
`

const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;

  ${media.mediumS`
    text-align: center;
    font-size: 16px;
  `}
`

const Plan = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 33px;
  font-size: 14px;
  line-height: 100%;

  ${media.smallPc`
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
  `}

  ${media.mediumM`
    flex-direction: row;
  `}
`

const PlanName = styled.div`
  flex-shrink: 0;
  font-weight: 700;
  color: ${theme.colors.blue[500]};
`

const PlanDate = styled.div`
  flex-shrink: 0;
  color: ${theme.colors.gray[600]};
  font-weight: 400;
`

const Position = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
`

const Company = styled.div`
  margin-top: 4px;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
`

export default NoteDashboardProfileInfo
