import theme from 'lib/styles/theme'
import priceFormat from 'lib/utils/priceFormat'
import { useMemo } from 'react'
import styled from 'styled-components'

const NoteListMobilePrice = ({ data, type }) => {
  const priceData = (
    contract_type,
    sale_price,
    jeonse_deposit,
    deposit,
    rent,
    loan,
  ) => {
    const addedNumber = type == 'doc' ? 1 : 10000
    if (!contract_type) return {}

    const contractArr = contract_type.split(',')

    return contractArr
      .filter((item) => ['매매', '전세', '월세'].includes(item))
      .map((type) => {
        if (type == '매매') {
          return {
            type: '매매',
            price: priceFormat(sale_price * addedNumber),
          }
        } else if (type == '전세') {
          return {
            type: '전세',
            price: priceFormat(jeonse_deposit * addedNumber),
          }
        } else if (type == '월세') {
          return {
            type: '월세',
            price: `${priceFormat(deposit * addedNumber)}/${priceFormat(
              rent * addedNumber,
            )}`,
          }
        } else {
          return { type: '', price: 0 }
        }
      })
  }

  const priceDatas = useMemo(
    () =>
      priceData(
        data.contract_type,
        data.sale_price,
        data.jeonse_deposit,
        data.deposit,
        data.rent,
        data.loan,
      ),
    [
      data.contract_type,
      data.sale_price,
      data.jeonse_deposit,
      data.deposit,
      data.rent,
      data.loan,
    ],
  )

  return (
    <Wrapper>
      <Icon className="fas fa-thumbtack" />
      {Object.keys(priceDatas).length > 0
        ? priceDatas.map(({ type, price }, i) => {
            return (
              <Item key={i} hasnext={priceDatas.length > i}>
                {type} {price}
              </Item>
            )
          })
        : '가격 정보 없음'}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 100%;

  ${({ area }) => area && `margin-top: 6px;`}
`

export const Icon = styled.i`
  font-size: 11px;
  color: ${theme.colors.gray[400]};
  margin-right: 4px;
`

export const Item = styled.div`
  font-size: 12px;
  font-weight: 600;
  ${({ hasnext }) =>
    hasnext &&
    `
      &:not(:last-child) {
        &::after {
          content: '·';
          margin: 0 4px;
          color: ${theme.colors.gray[750]};
        }
      }
    `}
`

export default NoteListMobilePrice
