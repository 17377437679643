import { getContactList } from 'lib/api/note/contactApi'
import useAxios from 'lib/hooks/useAxios'
import { useInput } from 'lib/hooks/useInput'
import { setNoteContacts } from 'modules/notev2'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const NoteCustomerContainer = ({ children }) => {
  const dispatch = useDispatch()
  const [list, setList] = useState([])
  const [onRequest, , data] = useAxios(getContactList)
  const [onRequestSave, , dataSave] = useAxios(getContactList)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [orderBy, setOrderBy] = useState('최근 등록순')
  const [search, bindSearch] = useInput('')
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [rowRecord, setRowRecord] = useState(null)

  useEffect(() => {
    onRequest({ orderBy, q: search })
  }, [orderBy])

  useEffect(() => {
    if (!data) return
    setList(data?.result)
  }, [data])

  useEffect(() => {
    if (!dataSave) return

    setList(dataSave?.result || [])
    dispatch(setNoteContacts(dataSave?.result || []))
  }, [dataSave])

  /**
   * 검색
   * @param {*} e
   */
  const onSearch = (e) => {
    if (e.key == 'Enter') {
      onRequest({ orderBy, q: search })
    }
  }

  const saveCallbackFn = () => {
    onRequestSave({ orderBy, q: search })
  }

  const onClickRow = (value) => {
    setRowRecord(value)

    setTimeout(() => {
      setEditModalVisible(true)
    })
  }

  const searchRequest = () => {
    onRequest({ orderBy, q: search })
  }

  return children({
    onSearch,
    bindSearch,
    list,
    orderBy,
    setOrderBy,
    isModalVisible,
    setIsModalVisible,
    saveCallbackFn,
    isEditModalVisible,
    setEditModalVisible,
    onClickRow,
    rowRecord,
    searchRequest,
  })
}

export default NoteCustomerContainer
