import { useContext, useEffect } from 'react'
import { message } from 'antd'
import MapContext from 'contexts/map'

import { setAddressPolygon } from 'components/map/MapPolygon'
import { useDispatch } from 'react-redux'
import { setCenterPoint } from 'modules/summary'
import { getAddress } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import { useLocation } from 'react-router-dom'

/**
 * @description Summary 페이지에서 지도에 마커, 폴리곤을 출력하거나
 * centerPoint를 이동시키는 등의 지도 관련 작업을 분리한 컨테이너입니다.
 */
const SummaryMapContainer = ({ match }) => {
  const location = useLocation()
  const addressId = match?.params?.id
  const dispatch = useDispatch()
  const [onRequest, , data, , ,] = useAxios(getAddress)

  const { state, actions } = useContext(MapContext)
  const { map, clickPosition } = state
  const { kakao } = window

  /**
   * @description 주소에 address_id 포함되어 있으면 이에 해당되는 폴리곤 및 map center 세팅
   */
  useEffect(() => {
    // 지도가 로드되지 않으면 리턴
    if (Object.keys(map).length === 0) return

    // 주소값이 없거나 잘못되면 없으면 return
    if (!addressId || addressId.length !== 19) {
      return
    }

    //맵을 클릭해서 요청한 getAddress api 결과값이 있을 때
    if (location.state?.address_data?.result) {
      makeSummaryMapData(location.state.address_data)
    } else {
      onRequest(
        {
          address_id: addressId,
        },
        null,
        false,
      )
    }

    return () => {
      if (Object.keys(clickPosition).length > 0) {
        // 클릭한 폴리곤이 있을 때
        actions.setClickPosition({})
        actions.setPolygons([])
        actions.setBuildingPolygons([])
      }
    }
  }, [map, kakao, addressId])

  useEffect(() => {
    if (!data?.result || data?.message !== 'success') return

    if (!data.result || Object.keys(data.result).length === 0) {
      message.error('검색 결과가 없습니다.')
      return
    }

    makeSummaryMapData(data)
  }, [data])

  const makeSummaryMapData = (data) => {
    if (data.result.centerpoint) {
      dispatch(setCenterPoint(data.result.centerpoint))
    }

    setAddressPolygon({ data }, kakao, state, actions)

    if (data.result.land_area > 0.01 && data.result.land_area < 500) {
      map.setLevel(1)
    }

    // 중점이동
    map.panTo(
      new kakao.maps.LatLng(
        data.result.centerpoint.coordinates[0],
        data.result.centerpoint.coordinates[1],
      ),
    )
  }

  return null
}

export default SummaryMapContainer
