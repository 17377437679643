import styled from 'styled-components'
import HomeRecentAddressItem from './HomeRecentAddressItem'
import { CardHeader } from 'components/design'
import { Card } from 'components/design'
import { CardTitle } from 'components/design'

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const HomeRecentAddress = ({ recentAddresses }) => {
  if (recentAddresses.length === 0) return <></>

  return (
    <Card round overflowHidden>
      <CardHeader>
        <CardTitle>최근 확인한 주소</CardTitle>
      </CardHeader>

      <List>
        {recentAddresses.map((item, i) => (
          <HomeRecentAddressItem item={item} key={i} />
        ))}
      </List>
    </Card>
  )
}

export default HomeRecentAddress
