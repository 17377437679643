import { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

import { removeNote } from 'lib/api/note/noteApi'
import PropertyType from './PropertyType'
import ContractType from './ContractType'
import {
  ListItem,
  ListItemLabel,
  ListItemMenu,
  Tag,
} from 'components/design'
import { Confirm } from 'components/design'
import useNotes from 'pages/jmapnote/noteList/hooks/useNotes'
import { useContext } from 'react'
import MapContext from 'contexts/map'
import useAxios from 'lib/hooks/useAxios'
import useFormatArea from 'lib/hooks/useFormatArea'
import { propertyTypes } from 'lib/utils/propertyType'
import priceFormat from 'lib/utils/priceFormat'
import { numberWithCommas } from 'utils'
import useIsMobile from 'lib/hooks/useIsMobile'
import useAddresses from 'pages/jmapnote/noteList/hooks/useAddresses'

const NoteListItem = ({
  item,
  lastChildNoBorder,
  mapVisible,
  channelSrl,
  folderSrl,
  addressSrl,
  onClick,
  hiddenDivider,
  setIsVisibleNoteDetail,
  setMobileData,
  Markerclick,
  onRequest = () => {},
}) => {
  const isMobile = useIsMobile()

  const { kakao } = window
  const { state } = useContext(MapContext)
  const history = useHistory()

  const [_removeNote, _removeNoteLoading] = useAxios(removeNote)
  const { _getNotes } = useNotes()
  const { _getAddresses } = useAddresses()

  const getNoteLink = () => {
    let path = '/note'
    if (channelSrl) {
      path += `/${channelSrl}`
    } else {
      path += `/${item?.channel_srl}`
    }

    if (folderSrl) {
      path += `/${folderSrl}`
    } else {
      path += `/${item?.folder_srl}`
    }

    if (addressSrl) {
      path += `/${addressSrl}`
    } else {
      path += `/${item?.address_srl ?? 0}`
    }

    path += `/${item?.note_srl}`

    return path
  }
  const noteLink = getNoteLink()

  const noteLinkMatch = useRouteMatch({ path: [noteLink] })

  const onClickItem = (item) => {
    if (isMobile && Markerclick) {
      setMobileData(item)
      setIsVisibleNoteDetail(true)
    } else {
      history.push({
        pathname: noteLink,
        search: mapVisible ? '?back=true' : '',
      })
    }

    if (onClick) onClick()

    if (
      !window.kakao ||
      !window.kakao.maps ||
      Object.keys(window.kakao.maps).length === 0
    )
      return

    // 중점이동
    if (item.centerpoint) {
      const center = new kakao.maps.LatLng(
        item.centerpoint[0],
        item.centerpoint[1],
      )
      state.map.panTo(center)
      // state.map.setCenter(center)
    }
  }

  const onRemoveItem = async (props) => {
    Confirm.error({
      title: '매물을 삭제하시겠습니까?',
      okText: '삭제',
      cancelText: '취소',
      onOk: () => handleRemove(props),
    })
  }

  const handleRemove = async (props) => {
    await _removeNote(props)
    await _getAddresses({
      channelSrl: props?.channelSrl,
      folderSrl: props?.folderSrl,
    })
    await _getNotes({
      channelSrl: props?.channelSrl,
      folderSrl: props?.folderSrl,
    })
    // TODO: 지도 위 마커 안 보이도록
    // 삭제된 매물 2뎁스 대신 주소 정보 보이도록
    history.push(`/note/${props?.channelSrl}/${props?.folderSrl}`)
    // '이 주소에 등록된 매물' 컴포넌트에서 삭제된 상태 업데이트
    onRequest({
      channel_srl: props?.channelSrl,
      folder_srl: props?.folderSrl,
      address_srl: props?.addressSrl,
    })
  }

  const onClickMenu = ({ key }) => {
    // 수정
    if (key == 1) {
      history.push(`${noteLink}/modify`)
    } else if (key == 2) {
      onRemoveItem({
        channelSrl: item?.channel_srl,
        folderSrl: item?.folder_srl,
        addressSrl: item?.address_srl,
        noteSrl: item?.note_srl,
      })
    }
  }

  /**
   * property_type에 따른 면적값
   * - 토지: 대지면적
   * - 건물 전체 [상가건물, 단독주택, 다가구주택]: 연면적
   * - 방 [원룸, 1.5룸, ...]: 전용면적
   * @param {*} item
   * @return {null|number}
   */
  const converterArea = (item) => {
    // 유형이 없으면 반환
    if (!item?.property_type) {
      return null
    }
    // 결과값 선언
    let result
    // 토지 - 대지면적
    if (item.property_type === '토지') {
      result = item?.extra_vars?.land_area
    }
    // 상가건물, 단독주택, 다가구주택, 공장, 기타 - 연면적
    if (
      propertyTypes.building.includes(item.property_type) ||
      ['공장', '기타'].includes(item.property_type)
    ) {
      result = item?.extra_vars?.total_floor_area
    }
    // 원룸, 1.5룸, 투룸, 쓰리룸+, 빌라, 아파트, 오피스텔, 상가, 사무실 - 전용면적
    if (
      propertyTypes.residential.includes(item.property_type) ||
      propertyTypes.commercial.includes(item.property_type)
    ) {
      result = item?.exclusive_area
    }
    // 값 없으면 반환
    if (!result) {
      return null
    }
    // 평제곱미터
    if (!state.isSquareMeter) {
      result = result / 3.305
    }

    return result
  }

  const items = [
    {
      label: '수정',
      key: '1',
      icon: <EditOutlined />,
    },
    {
      label: '삭제',
      key: '2',
      icon: <DeleteOutlined />,
      danger: true,
    },
  ]

  const menuProps = {
    items,
    onClick: onClickMenu,
  }
  return (
    <>
      <ListItem
        dense
        lastChildNoBorder={lastChildNoBorder}
        hiddenDivider={hiddenDivider}
        actived={noteLinkMatch ? true : false}
        onClick={() => onClickItem(item)}
        prepend={<PropertyType propertyType={item?.property_type} />}
        append={
          mapVisible || (
            <ListItemMenu menus={menuProps} top></ListItemMenu>
          )
        }
      >
        <ListItemLabel subtitle>
          <AddressName>
            {item?.building_name} {item?.dong && item?.dong}{' '}
            {item?.ho && item?.ho}
            {converterArea(item) && converterArea(item) > 0
              ? `(${numberWithCommas(parseInt(converterArea(item)))}` +
                `${state.isSquareMeter ? '㎡' : '평'})`
              : ''}
          </AddressName>
        </ListItemLabel>
        <ListItemLabel small>
          {item?.contract_type ? (
            <ContractType item={item} />
          ) : (
            '계약정보 없음'
          )}
          {item?.property_type === '상가' &&
          item?.extra_vars?.foregift ? (
            <>
              <Tag size="small" marginLeft>
                권
              </Tag>
              {priceFormat(
                parseFloat(item?.extra_vars?.foregift) * 10000,
              )}
            </>
          ) : (
            ''
          )}
          {item?.status === 2 && (
            <TagWrapper>
              <Tag size="small">중개완료</Tag>
            </TagWrapper>
          )}
        </ListItemLabel>
      </ListItem>
    </>
  )
}

const AddressName = styled.span`
  line-height: 21px;
`

const TagWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 46px;
`

export default NoteListItem
