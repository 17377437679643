import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useContext } from 'react'
import BaseContext from 'contexts/baseContext'

const StyledLink = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  min-height: 36px;
  padding: 8px;

  margin-left: -0.75rem;
  margin-right: 0.25rem;

  color: ${theme.colors.gray[900]};
  font-size: 1.125rem;

  appearance: none;
  background: transparent;
  cursor: pointer;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover,
  &:active {
    color: ${theme.colors.gray[600]};
  }

  ${({ inverse }) =>
    inverse &&
    `
    color: white;

    &:hover,
    &:active {
      color: ${theme.colors.gray[100]};
    }
  `}
`

const HeaderBack = ({ onClose, inverse }) => {
  const history = useHistory()

  const {
    state: { headerInverse },
  } = useContext(BaseContext)

  const onClickBack = useCallback(() => {
    if (onClose) {
      onClose()
      return
    }

    history.go(-1)
  })

  return (
    <StyledLink
      inverse={headerInverse || inverse || false}
      onClick={onClickBack}
    >
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg> */}

      <i className="far fa-chevron-left"></i>
    </StyledLink>
  )
}

export default HeaderBack
