import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { ReactComponent as OpenIcon } from 'assets/icon/note/open.svg'
import { ReactComponent as OpenIconMobile } from 'assets/icon/note/open-blue.svg'
import {
  CustomSingleCellInputWrapper,
  CustomSingleCellStyle,
  SheetButton,
} from 'components/jmapnotev2/style/NoteStyle'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import { getValidAddresses } from 'lib/api/notev2/listApi'
import useAxios from 'lib/hooks/useAxios'
import { useInput } from 'lib/hooks/useInput'
import {
  addSelectAllKeyEvent,
  additionalCellWidth,
  gridFontSize,
  resetGridDataAddresses,
} from 'lib/utils/notev2/gridUtil'
import Spinner from 'components/common/Loading/Spinner'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import { getDisplayAddressFormat } from 'lib/utils/notev2/util'
import useIsMobile from 'lib/hooks/useIsMobile'
import { ActiveCellStyle } from 'components/jmapnotev2/style/NoteGridStyle'
import 'intro.js/introjs.css'
import { useSelector } from 'react-redux'
import OpenButton from 'components/jmapnotev2/customCell/OpenButton'
import CustomAddressCellList from 'components/jmapnotev2/customCell/List/CustomAddressCellList'
import useTempCreateAd from 'lib/hooks/notev2/useTempCreateAd'
import { focusNextCell } from 'lib/utils/notev2/gridFocus'
import CustomAdAddressCellListContainer from 'containers/notev2/CustomAdAddressCellListContainer'
import useModifyAdAddress from 'lib/hooks/notev2/useModifyAdAddress'
import { message } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const getEditAddressFormat = ({
  display_address,
  eupmyeondong,
  dong,
  ho,
}) => {
  const addr = `${eupmyeondong ? `${eupmyeondong} ` : ''}${
    display_address ? `${display_address}` : ''
  }`?.trim()

  const dongVal = `${dong ? ` ${dong.trim()}` : ''}`

  const hoVal = `${ho ? ` ${ho.trim()}` : ''}`

  return `${addr}${dongVal}${hoVal}`?.trim()
}

const CustomAddressCell = (
  {
    gridColumnApi,
    clearIsSearchingAddr,
    value,
    data,
    node,
    gridfontsize,
  },
  gridRef,
) => {
  const history = useHistory()
  const [addressList, setAddressList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [onRequestValid, , dataValidAddr] = useAxios(getValidAddresses)
  const gridFontSize = useSelector((state) => state.notev2.gridFontSize)

  const isMobile = useIsMobile()
  const viewRef = useRef(null)
  const inputRef = useRef(null)

  const { onSaveTempAd, onSaveTempAdInvalid } = useTempCreateAd()
  const [onModify] = useModifyAdAddress()

  const alreadyCreated = data.already_created == 'Y'

  const prevValue = useRef(
    getDisplayAddressFormat({
      eupmyeondong: data.eupmyeondong,
      display_address: value,
      dong: data.dong || data.dong_name,
      ho: data.ho || data.ho_name,
    }),
  )

  const [addressInput, bindAddressInput, , setInput] = useInput(
    getEditAddressFormat({
      eupmyeondong: data.eupmyeondong,
      address_jibun: data.address_jibun,
      display_address: value,
      dong: data.dong || data.dong_name,
      ho: data.ho || data.ho_name,
    }),
  )

  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    isReversePosition,
  } = useCustomSingleCell(data?.status, node?.rowIndex)

  /**
   * 조회 상태로 초기화
   */

  const resetCellState = useCallback(() => {
    setIsEditable(false)
    setAddressList(null)
    clearIsSearchingAddr()
  }, [])

  useEffect(() => {
    prevValue.current = getDisplayAddressFormat({
      eupmyeondong: data.eupmyeondong,
      display_address: value,
      dong: data.dong || data.dong_name,
      ho: data.ho || data.ho_name,
    })
    setInput(
      getEditAddressFormat({
        eupmyeondong: data.eupmyeondong,
        address_jibun: data.address_jibun,
        display_address: value,
        dong: data.dong || data.dong_name,
        ho: data.ho || data.ho_name,
      }),
    )
  }, [
    data.dong || data.dong_name,
    data.ho || data.ho_name,
    data.address_jibun,
    data.eupmyeondong,
  ])

  useEffect(() => {
    if (data.ad_srl == '신규') {
      setIsEditable(true)
      inputRef.current?.focus()
      setAddressList(null)
      clearIsSearchingAddr()
    } else {
      resetCellState()
    }
  }, [])

  const onClickOpenDetail = useCallback(
    (e) => {
      e.preventDefault()

      if (!data.ad_srl) {
        message.error('유효하지 않은 요청입니다')
        return
      }
      history.push(`/note/v2/ad/${data.ad_srl}`)
    },
    [data],
  )

  const onKeyDownInput = useCallback(
    (e) => {
      addSelectAllKeyEvent(e)
      if (e.key == 'Enter' || e.key == 'Tab') {
        //값 변경 안되었으면 return

        if (prevValue.current == addressInput) {
          setIsEditable(false)
          setTimeout(() => {
            viewRef?.current?.focus()
          })
          return
        }

        setIsLoading(true)
        onRequestValid({
          address: addressInput,
        })
      } else if (e.key == 'Escape') {
        //esc 누르면 모두 초기화(조회 상태로)
        resetCellState()
        if (data.ad_srl == '신규') {
          setInput('')
        }
      } else if (e.keyCode == 9) {
        //tab
        setIsEditable(false)
      }
    },
    [prevValue?.current, addressInput, viewRef?.current, data.ad_srl],
  )

  /**
   * 바깥 클릭하면 모두 초기화(조회 상태로)
   */
  useOnClickOutside(inputRef, () => {
    setIsEditable(false)
  })

  /**
   * 주소 유효성 검증 후 추가 로직
   * - 검증 성공(단일) => saveAddress + saveNote
   * - 검증 성공 (여러개) => dropdown 생성
   * - 검증 실패 => saveNote
   */
  useEffect(() => {
    if (!dataValidAddr || !dataValidAddr?.result) return
    if (!data.row_key) return
    if (!gridRef.current) return

    const targetNodeData = gridRef.current.api.getRowNode(data.row_key)

    if (!targetNodeData || !targetNodeData.data) return

    setIsLoading(false)

    //추가 모드
    if (targetNodeData.data.ad_srl == '신규') {
      if (dataValidAddr.result.length > 1) {
        //주소 검증 성공 (여러개) => dropdown 생성
        const validData = dataValidAddr.result
        setAddressList(validData)
      } else {
        resetGridDataAddresses(targetNodeData)

        //TODO: 주소 검증 성공(단일)
        const validData = dataValidAddr?.result[0]

        //temp insert
        if (validData) {
          //유효한 주소
          onSaveTempAd({
            address_id: validData?.address_id || '',
            validAddressInfo: validData,
            currentRowNode: targetNodeData,
            callbackFn: () =>
              focusNextCell(
                gridRef.current.api,
                gridColumnApi,
                data.row_key,
                'contract_type',
              ),
          })
          //현재값과 비교할 이전값 세팅(input)
          const inputValue = getEditAddressFormat({
            eupmyeondong: validData?.eupmyeondong || '',
            display_address: validData?.display_address || '',
            address_jibun: validData?.address_jibun || '',
            dong: validData?.dong || '',
            ho: validData?.ho || '',
          })
          prevValue.current = inputValue
          setInput(inputValue)
        } else {
          //유효하지 않은 주소
          onSaveTempAdInvalid({
            input: addressInput,
            currentRowNode: targetNodeData,
          })

          //현재값과 비교할 이전값 세팅(input)
          resetGridDataAddresses(targetNodeData)
          prevValue.current = addressInput
        }

        //수정모드 해제
        setIsEditable(false)
      }
    } else if (targetNodeData.data.ad_srl) {
      if (!data.row_key) {
        return
      }

      if (dataValidAddr.result.length > 1) {
        //주소 검증 성공 (여러개) => dropdown 생성
        const validData = dataValidAddr.result
        setAddressList(validData)
      } else {
        const validData = dataValidAddr.result[0]

        if (validData) {
          //유효한 주소
          //주소 검증 성공(단일)
          onModify({
            ad_srl: targetNodeData.data?.ad_srl,
            validData: validData,
            currentRowNode: targetNodeData,
          })

          //수정모드 해제
          setIsEditable(false)

          //현재값과 비교할 이전값 세팅(input)
          const inputValue = getEditAddressFormat({
            eupmyeondong: validData?.eupmyeondong,
            display_address: validData?.display_address,
            address_jibun: validData?.address_jibun,
            dong: validData.dong,
            ho: validData.ho,
          })
          prevValue.current = inputValue
          setInput(inputValue)
        } else {
          //유효하지 않은 주소
          onModify({
            ad_srl: targetNodeData.data?.ad_srl,
            validData: null,
            currentRowNode: targetNodeData,
            invalidAddr: addressInput,
          })
        }
      }
    }
  }, [dataValidAddr])

  return (
    <Wrapper
      tabIndex={-1}
      className="adress-wrapper-input"
      full={!alreadyCreated}
    >
      {isMobile ? (
        <MobileOpenButtonWrapper className="open-class"></MobileOpenButtonWrapper>
      ) : (
        <OpenButtonWrapper className="open-class">
          <OpenIcon />
          <OpenText>열기</OpenText>
        </OpenButtonWrapper>
      )}
      {isLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {isEditable && !alreadyCreated ? (
        <CustomSingleCellInputWrapper>
          <StyledInput
            placeholder={isMobile ? '주소를 입력하세요' : ''}
            className="custom-address-cell-input"
            ref={inputRef}
            onFocus={(e) => {
              e.target.select()
            }}
            tabIndex={0}
            type="text"
            autoFocus
            value={addressInput}
            onBlur={() => {
              if (!addressInput) {
                setIsEditable(false)
              }
            }}
            onKeyDown={onKeyDownInput}
            gridfontsize={gridfontsize}
            {...bindAddressInput}
          />
        </CustomSingleCellInputWrapper>
      ) : (
        <>
          {/* 조회모드 */}
          <AddressWrapper
            padding={isMobile ? 'Y' : 'N'}
            tabIndex={0}
            onDoubleClick={onDoubleClickSingleCell}
            onKeyDown={onKeyDownSingleCell}
            ref={viewRef}
            full={!alreadyCreated}
          >
            <Addresses mobile={isMobile ? 'Y' : 'N'}>
              {data.eupmyeondong && !isMobile && alreadyCreated && (
                <Eupmyeondong
                  addwidth={additionalCellWidth(gridFontSize)} // 폰트 사이즈 변경에 따라 그리드 셀 폭 조절(읍면동은 그리드의 셀이 아니라 따로 조정해야함)
                >
                  {data.eupmyeondong}
                </Eupmyeondong>
              )}
              <Address padding={isMobile ? 'N' : 'Y'}>
                <div>
                  {data.eupmyeondong && isMobile && (
                    <>{data.eupmyeondong}</>
                  )}
                  {getDisplayAddressFormat({
                    display_address: value,
                    dong: data.dong || data.dong_name,
                    ho: data.ho || data.ho_name,
                  })}
                  {data.building_name && (
                    <Name>&nbsp;({data.building_name})</Name>
                  )}
                </div>
              </Address>
            </Addresses>
            {!alreadyCreated && (
              <RequiredDesc>
                {isMobile ? (
                  <i className="fas fa-exclamation-triangle"></i>
                ) : (
                  '유효한 주소, 종류, 구분, 가격을 입력해야 광고를 등록할 수 있어요'
                )}
              </RequiredDesc>
            )}
          </AddressWrapper>
        </>
      )}
      {addressList && (
        <CustomAdAddressCellListContainer
          resetCellState={resetCellState}
          ref={{
            gridRef: gridRef,
            prevValue: prevValue,
          }}
          setInput={setInput}
          gridColumnApi={gridColumnApi}
          rowData={data}
          node={node}
          addresses={addressList}
          addressInput={addressInput}
        >
          {({ onSaveAddress }) => (
            <CustomAddressCellList
              addresses={addressList}
              onSaveAddress={onSaveAddress}
              setIsEditable={setIsEditable}
              isReversePosition={isReversePosition}
              escapeFn={() => {
                setAddressList(null)
                clearIsSearchingAddr()
                inputRef.current?.focus()
              }}
            />
          )}
        </CustomAdAddressCellListContainer>
      )}
      {data.ad_srl && data.ad_srl != '신규' && alreadyCreated && (
        <OpenDetailButton
          onClick={onClickOpenDetail}
          className={isMobile ? 'open-class' : null}
        >
          {isMobile ? (
            data.deunggibu_counts && data.deunggibu_counts > 0 ? (
              <OpenIconMobile />
            ) : (
              <OpenButton size="medium" visibleText={false} />
            )
          ) : (
            <OpenButton text="열기" size="medium" />
          )}
        </OpenDetailButton>
      )}
    </Wrapper>
  )
}

const MobileOpenButtonWrapper = styled.div`
  left: 120px;
  position: absolute;
  width: 40px;
  height: 20px;
`

const OpenButtonWrapper = styled.div`
  opacity: 0;
  left: 300px;
  position: absolute;
`

const EllipsisStyle = css`
  white-space: initial;
  line-height: 120%;
  width: 100%;
  text-align: left;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const OpenDetailButton = styled.div`
  position: absolute;
  right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s;
`

const OpenText = styled.span`
  margin-left: 4px;
`

const CompleteTag = styled(SheetButton)`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  transition: opacity 0.1s;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;

  ${({ full }) =>
    full &&
    `
    
    width: calc(100% + 92px);
    margin-left: -94px;
    background-color: ${theme.colors.base.white};
    z-index: 1;
  `}

  .ag-row-selected & {
    background-color: ${theme.colors.noteSheet.primary};
  }

  ${media.mediumS`
    ${({ full }) =>
      full &&
      `
      width: 100%;
      margin-left: 15px;
    `}
  `}

  &:hover {
    ${OpenDetailButton} {
      opacity: 1;
    }
    ${CompleteTag} {
      opacity: 0;
    }
  }
`

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  border: none;
  outline: none;
  overflow: hidden;

  ${CustomSingleCellStyle}

  ${({ padding }) => padding == 'Y' && `padding-left: 14px;`}
  
  ${({ full }) =>
    full &&
    `
      padding: 0;
  `}
`

const Addresses = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  ${media.mediumS`
    display: block;
    height: auto;
    ${EllipsisStyle}
  `}
`

const Address = styled.div`
  ${({ padding }) => padding == 'Y' && `padding-left: 8px;`}
`

const Eupmyeondong = styled.div`
  border-right: 1px solid ${theme.colors.gray[900]}1A;
  width: ${({ addwidth }) => `${70 + addwidth}px`};
  text-align: center;
  margin-left: -8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledInput = styled.input`
  width: 100%;
  height: calc(100% - 2px);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 0;
  outline: none;

  ${media.mediumS`
    text-indent: 4px;
  `}

  ${ActiveCellStyle}

  font-size: ${({ gridfontsize }) =>
    gridfontsize || gridFontSize['m']}px;
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  border: none;

  & > div {
    z-index: 9999;
  }
`
const Name = styled.span`
  color: ${theme.colors.gray[500]};
`

const RequiredDesc = styled.div`
  padding: 2px 4px;
  font-weight: 600;
  line-height: 100%;
  background-color: ${theme.colors.red[400]};
  font-size: 9px;
  color: ${theme.colors.base.white};
  border-radius: 2px;
  user-select: none;

  ${media.mediumS`
    background: transparent;
    color: ${theme.colors.red[400]};
    font-size: 12px;
    padding-right: 8px;
    padding-left: 0px;
  `}
`

export default React.memo(forwardRef(CustomAddressCell))
