import styled from 'styled-components'
import theme from 'lib/styles/theme'

export const Icon = styled.div`
  flex: 0 0 auto;
  min-width: 20px;
  margin-right: 0.75rem;

  color: ${theme.colors.gray[700]};
  font-size: 1rem;
`
export const Label = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 9rem;
  color: ${theme.colors.gray[600]};
  font-weight: 400;
  margin-right: 1.5rem;
`
export const Value = styled.div`
  flex: 1;
  color: ${theme.colors.gray[900]};
  white-space: pre-line;
  /* font-weight: 700; */
`
export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    font-size: 0.875rem;

    &:last-child {
      border-bottom: none;
    }
  }
`
