import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { numberWithCommas } from 'utils'
import theme from 'lib/styles/theme'
import SummaryAptAreaTab from 'components/summary/SummaryApt/SummaryAptAreaTab'
import SelectArea from 'components/summary/SummaryChart/SelectArea'
import { CardBody } from 'components/design'
import styled from 'styled-components'

const SummaryAptHeader = ({
  children,
  selectedArea,
  setSelectedArea,
  areaTypes,
  noTitle,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  //세대수가 가장 많은 면적 내림차순 해서 최상위 3개
  const [maxAreas, setMaxAreas] = useState([])

  useEffect(() => {
    if (!areaTypes || areaTypes.length === 0) return

    const maxHouseCountsArr = [...areaTypes]
      .sort(
        (a, b) => parseFloat(b.house_count) - parseFloat(a.house_count),
      )
      .slice(0, 3)

    setMaxAreas(maxHouseCountsArr)
  }, [areaTypes])

  return (
    <Wrapper border={!noTitle}>
      {!noTitle && (
        <CardBody>
          <Title>실거래가</Title>
        </CardBody>
      )}
      {/* 평형정보 있을때만 렌더링 */}
      {areaTypes && areaTypes.length > 0 && (
        <TabWrapper>
          <TabInner>
            <SummaryAptAreaTab
              maxAreas={maxAreas}
              selectedArea={selectedArea}
              setSelectedArea={setSelectedArea}
              setIsModalVisible={setIsModalVisible}
            />
            <SelectArea
              visible={isModalVisible}
              setVisible={setIsModalVisible}
              areaTypes={areaTypes}
              selectedArea={selectedArea}
              setSelectedArea={setSelectedArea}
              maxAreas={maxAreas}
              setMaxAreas={setMaxAreas}
            />
          </TabInner>

          <ButtonWrapper>{children}</ButtonWrapper>
        </TabWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 1rem;
  ${({ border }) =>
    border &&
    `
    border-top: 4px solid ${theme.colors.gray[200]};
  `}
`

const TabWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  padding-right: -1.25rem;
  margin-top: -0.5rem;
`

const TabInner = styled.div`
  flex: 1;
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
`

const Sub = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  /* margin-bottom: 0.875rem; */
`

const ButtonWrapper = styled.div`
  flex: 0 0 auto;
`

const Divider = styled.div`
  margin-left: 1.25rem;
  padding-bottom: 0.875rem;
  border-top: 1px solid ${theme.colors.gray[100]};
`

export default SummaryAptHeader
