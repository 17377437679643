import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import {
  CommonPadding,
  MainWrapper,
  SearchInput,
  SwipeableMainWrapper,
} from 'components/jmapnotev2/style/NoteStyle'
import NoteLeftSide from './NoteLeftSide'
import NotePicturesContainer from 'containers/notev2/NotePicturesContainer'
import { Header, Title } from '../style/NoteStyle'
import SelectBox from '../elements/SelectBox'
import { Skeleton } from 'antd'
import { Empty } from 'components/design'
import NotePicture from './NotePicture'
import NoteLayerSwipeContainer from 'containers/notev2/NoteLayerSwipeContainer'
import Swipe from 'react-easy-swipe'
import NoteMainWrapper from '../style/NoteMainWrapper'
import useIsMobile from 'lib/hooks/useIsMobile'

const NotePictures = () => {
  const isMobile = useIsMobile()
  return (
    <NoteLayerSwipeContainer>
      {({
        listLayerRef,
        onSwipeStartFilter,
        onSwipeMoveFilter,
        onSwipeEndFilter,
        onSwipeStartList,
        onSwipeMoveList,
        onSwipeEndList,
      }) => (
        <NotePicturesContainer>
          {({
            bindSearch,
            onSearch,
            propertyTypes,
            setPropertyTypes,
            orderBy,
            setOrderBy,
            groupImages,
            loading,
          }) => (
            <>
              <NoteLeftSide
                onSwipeStart={onSwipeStartFilter}
                onSwipeMove={onSwipeMoveFilter}
                onSwipeEnd={onSwipeEndFilter}
                propertyTypes={propertyTypes}
                setPropertyTypes={setPropertyTypes}
              />

              <NoteMainWrapper ref={listLayerRef} column swipeable>
                <Wrapper
                  onSwipeStart={onSwipeStartList}
                  onSwipeMove={onSwipeMoveList}
                  onSwipeEnd={onSwipeEndList}
                >
                  {!isMobile && (
                    <Header>
                      <Title>사진관리</Title>
                    </Header>
                  )}
                  <InputWrap>
                    <SearchIcon className="fas fa-search"></SearchIcon>
                    <SearchInput
                      onKeyDown={onSearch}
                      type="text"
                      placeholder="검색"
                      {...bindSearch}
                    />
                    <SelectBox
                      noborder
                      minWidth={120}
                      value={{ value: orderBy, label: orderBy }}
                      options={[
                        {
                          label: '최근 등록순',
                          value: '최근 등록순',
                        },
                        {
                          label: '오래된 등록순',
                          value: '오래된 등록순',
                        },
                      ]}
                      onChange={(e) => setOrderBy(e.value)}
                    />
                  </InputWrap>
                </Wrapper>
                <ImagesWrapper>
                  {loading ? (
                    <SkeletonWrapper>
                      <Skeleton />
                    </SkeletonWrapper>
                  ) : groupImages &&
                    Object.keys(groupImages).length > 0 ? (
                    <NotePicture
                      data={groupImages}
                      onSwipeStart={onSwipeStartList}
                      onSwipeMove={onSwipeMoveList}
                      onSwipeEnd={onSwipeEndList}
                    />
                  ) : (
                    <Empty>사진 데이터가 없습니다.</Empty>
                  )}
                </ImagesWrapper>
              </NoteMainWrapper>
            </>
          )}
        </NotePicturesContainer>
      )}
    </NoteLayerSwipeContainer>
  )
}

const Wrapper = styled(Swipe)`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${CommonPadding};
  padding-bottom: 0;

  ${media.mediumS`
    padding: 10px 12px;
    width: 65%;
  `}
`

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 34px;
  margin-bottom: 26px;
  gap: 8px;
`

const SearchIcon = styled.i`
  color: ${theme.colors.gray[700]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`

const ImagesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${media.smallPc`
    height: calc(100% - ${theme.base.bottomMenuHeight}px);
  `}
`

const SkeletonWrapper = styled.div`
  padding: 0 40px;

  ${media.mediumS`
    padding-right: 20px; 
    padding-left: 20px;
  `}
`
export default NotePictures
