import { forwardRef, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import TabContext from './context/TabContext'

const StyledTab = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.25rem;

  ${({ border }) =>
    border && `border-bottom: 1px solid ${theme.colors.gray[100]};`}

  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return 40
      case 'medium':
        return 48
      case 'large':
        return 50
      case 'expand':
        return 55
    }
  }}px;

  ${({ noContainer }) => noContainer && `padding: 0;`}

  ${({ fixed }) =>
    fixed &&
    `
      position: fixed;
      z-index: 100;
      background: white;
      width: 100%;
  `}
`

const TabContainer = (
  { children, inverse, size, border, className, fixed, noContainer },
  ref,
) => {
  const [init, setInit] = useState(false)
  const {
    state: { inverseState, sizeState },
    actions: { setInverseState, setSizeState },
  } = useContext(TabContext)

  useEffect(() => {
    setInit(false)
    if (inverse) setInverseState(inverse)
    if (size) setSizeState(size)
    setInit(true)
  }, [inverse, size])

  if (!init) return null

  return (
    <StyledTab
      ref={ref}
      className={className}
      inverse={inverseState}
      size={sizeState}
      border={border}
      fixed={fixed}
      noContainer={noContainer}
    >
      {children}
    </StyledTab>
  )
}

export default forwardRef(TabContainer)
