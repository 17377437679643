import NoteDetailFormModifyControl from './NoteDetailFormModifyControl'
import GoToMap from './GoToMap'
import Roadview from './Roadview'
import styled from 'styled-components'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 매물 3뎁스 헤더
 * 로드뷰, 지도 이동, 삭제, 복사, 이동
 * @param onClose callback 닫기
 * @param searchFn callback 검색(refresh)
 */
const NoteDetailTools = ({ onClose, searchFn }) => {
  const isSharePage = useRouteMatch('/share/:noteSrl/:encryptedKey')
  return (
    <Wrapper>
      {/* 로드뷰 보기 */}
      <Roadview />
      {/* 지도 summary로 이동 */}
      <GoToMap />
      {/* 매물노트 수정/삭제/복사 등 */}
      {!isSharePage?.isExact && (
        <NoteDetailFormModifyControl
          onClose={onClose}
          searchFn={searchFn}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export default NoteDetailTools
