import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import {
  Row,
  Table,
  TableHeader,
  TableValue,
  TableTitle,
} from 'components/jmapnotev2/style/NoteLayerStyle'
import { Button } from 'components/design'

const NoteDocsLayerLandUseLayout = ({ data, useTo, append }) => {
  if (!data || !data?.extracted_data) return

  return (
    <div>
      {useTo == 'contract' && (
        <TableTitle simple="Y">토지이용계획원</TableTitle>
      )}
      {append}
      {data.extracted_data.URL && (
        <LinkButton
          color="blue"
          onClick={() => {
            window.open(data.extracted_data.URL)
          }}
          target="_blank"
          plain
          useto={useTo}
        >
          토지이음에서 확인하기
        </LinkButton>
      )}

      <StyledTable>
        <Row>
          <TableHeader>소재지</TableHeader>
          <TableValue>{data.extracted_data['소재지'] || ''}</TableValue>
        </Row>
        <Row>
          <RowHalf>
            <TableHeader half>지목</TableHeader>
            <TableValue half borderright>
              {data.extracted_data['지목'] || ''}
            </TableValue>
          </RowHalf>
          <RowHalf>
            <TableHeader sm>면적</TableHeader>
            <TableValue>{data.extracted_data['면적'] || ''}</TableValue>
          </RowHalf>
        </Row>
        <Row>
          <TableHeader>개별공시지가(㎡당)</TableHeader>
          <TableValue>
            {data.extracted_data['개별공시지가(㎡당)'] || ''}
          </TableValue>
        </Row>
      </StyledTable>
      <Title>지역지구등 지정여부</Title>
      <StyledTable>
        <Row borderbottom>
          <TableHeader>
            「국토의 계획 및 이용에 관한 법률」 에 따른 지역ㆍ지구등
          </TableHeader>
          <TableValue>
            {data.extracted_data[
              '「국토의 계획 및 이용에 관한 법률」 에 따른 지역ㆍ지구등'
            ] &&
              data.extracted_data[
                '「국토의 계획 및 이용에 관한 법률」 에 따른 지역ㆍ지구등'
              ].map(({ text }, i, oriVal) => {
                return (
                  <CommaSpan
                    key={'plan' + i}
                    dot={oriVal[i + 1] ? true : false}
                  >
                    {text}
                  </CommaSpan>
                )
              })}
          </TableValue>
        </Row>
        <Row>
          <TableHeader>다른 법령 등에 따른 지역ㆍ지구등</TableHeader>
          <TableValue>
            {data.extracted_data['다른 법령 등에 따른 지역ㆍ지구등'] &&
              data.extracted_data[
                '다른 법령 등에 따른 지역ㆍ지구등'
              ].map(({ text }, i, oriVal) => {
                return (
                  <DotSpan
                    key={'other' + i}
                    dot={oriVal[i + 1] ? true : false}
                  >
                    {text}
                  </DotSpan>
                )
              })}
          </TableValue>
        </Row>
        <Row>
          <TableHeader>
            「토지이용규제 기본법 시행령」제9조 제4항 각 호에 해당되는
            사항
          </TableHeader>
          <TableValue>
            {data.extracted_data[
              '「토지이용규제 기본법 시행령」제9조 제4항 각 호에 해당되는 사항'
            ] &&
              data.extracted_data[
                '「토지이용규제 기본법 시행령」제9조 제4항 각 호에 해당되는 사항'
              ].map(({ text }, i, oriVal) => {
                return (
                  <CommaSpan
                    key={'plan' + i}
                    dot={oriVal[i + 1] ? true : false}
                  >
                    {text}
                  </CommaSpan>
                )
              })}
          </TableValue>
        </Row>
      </StyledTable>

      <Title>확인도면</Title>
      {data.extracted_data['확인도면'] && (
        <DomeonImg
          src={data.extracted_data['확인도면']}
          alt="확인도면"
        />
      )}

      <Table>
        <Row noborderbottom>
          <TableHeader>행위제한내용</TableHeader>
          <TableValue>
            {data.extracted_data['행위제한내용'] &&
              Object.keys(
                data.extracted_data['행위제한내용'].length > 0,
              ) &&
              Object.keys(data.extracted_data['행위제한내용']).map(
                (title) => {
                  return (
                    <LimitContents>
                      <LimitContentsTitle>{title}</LimitContentsTitle>
                      <LimitContentsValue>
                        {data.extracted_data['행위제한내용'][title] &&
                          data.extracted_data['행위제한내용'][
                            title
                          ].map((text, i) => {
                            return <li key={`limit${i}`}>{text}</li>
                          })}
                      </LimitContentsValue>
                    </LimitContents>
                  )
                },
              )}
          </TableValue>
        </Row>
      </Table>
    </div>
  )
}

const StyledTable = styled(Table)`
  margin-bottom: 40px;

  & > div {
    &:last-child {
      border-bottom: none;
    }
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 12px;
`

const LimitContents = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  ${media.mediumS`
    width: 100%;
    word-break: break-word;
  `}
`

const LimitContentsTitle = styled.div`
  color: red;
  font-weight: 500;
  margin-bottom: 4px;
`

const LimitContentsValue = styled.ul`
  padding-inline-start: 10px;
  li {
    list-style-type: disc;
    margin: 0 9px;
  }
`

const RowHalf = styled.div`
  display: flex;
  width: 50%;
`

const RowSpan = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.gray[50]};
`

const DotSpan = styled.span`
  &::after {
    ${({ dot }) => (dot ? "content: '·'" : "content: ''")};
    margin: 0 3px;
  }
`
const CommaSpan = styled.span`
  &::after {
    ${({ dot }) => (dot ? "content: ','" : "content: ''")};
    margin-right: 3px;
  }
`

const RowSpanHeader = styled(TableHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;
  font-weight: 400;
  line-height: 130%;
  text-align: center;
  width: 80px;
  min-width: unset;
  border-right: none;
`

const LinkButton = styled(Button)`
  position: absolute;
  right: 30px;
  top: 46px;
  ${({ useto }) => useto == 'note' && `top: 33px`};

  ${media.mediumS`
    top: 14px;
    right: 20px;
  `}
`

const RowSpanWrapper = styled.div`
  width: calc(100% - 80px);
  border-left: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.base.white};
`

const Date = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.gray[600]};
  line-height: 100%;
  margin-top: 12px;
`

const DomeonImg = styled.img`
  width: 100%;
  max-width: 400px;
  margin-bottom: 40px;

  ${media.mediumS`
    max-width: 360px;
  `}
`

export default NoteDocsLayerLandUseLayout
