import { Spin } from 'antd'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import zIndex from 'lib/styles/zIndex'

const FullScreenLoading = ({ highLevel }) => {
  return ReactDOM.createPortal(
    <Wrapper high={highLevel ? 'Y' : 'N'}>
      <Spin size="large" />
    </Wrapper>,
    document.getElementById('modal'),
  )
}

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loading};
  background-color: #00000026;

  ${({ high }) =>
    high == 'Y' &&
    `
    z-index: ${zIndex.loadingHigh};
  `}
`
export default FullScreenLoading
