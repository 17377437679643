import styled, { css, keyframes } from 'styled-components'
import CustomPriceCellItem from './CustomPriceCellItem'
import { useEffect, useMemo } from 'react'
import {
  getPricePerHeight,
  showAllTypesPrice,
} from 'lib/utils/notev2/gridUtil'
import usePriceCellTouch from './usePriceCellTouch'
import { checkInputAndMoveFocus } from 'lib/utils/notev2/gridFocus'
import { useSelector } from 'react-redux'

const CustomPriceCell = ({
  setIsFocusPriceItem,
  node,
  isContractTypeShow,
  contract_type,
  sale_price,
  jeonse_deposit,
  deposit,
  rent,
  property_type,
  presale_base_price,
  api,
}) => {
  const perHeight = useMemo(
    () => getPricePerHeight(contract_type, isContractTypeShow),
    [contract_type, isContractTypeShow],
  )
  const { onTouchStart, onTouchEnd } = usePriceCellTouch(() => {
    checkInputAndMoveFocus(this)
    showAllTypesPrice(api, node)
  })

  const fourStepState = useSelector(
    (state) => state.onBoarding.fourStepState,
  )

  const fiveStepState = useSelector(
    (state) => state.onBoarding.fiveStepState,
  )

  useEffect(() => {
    if (fourStepState && !fiveStepState) {
      showAllTypesPrice(api, node)
      //가격셀 분할된셀 보이게함
    }
  }, [fourStepState, fiveStepState])

  const isPresale = useMemo(
    () => ['아파트 분양권', '오피스텔 분양권'].includes(property_type),
    [property_type],
  )

  if (node.data.note_srl == '신규') return <></>

  return (
    <Wrapper
      isVisible={isContractTypeShow || contract_type || fourStepState}
      tabIndex={-1}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      {/* 가짜로 타이핑효과 만들어줌 */}
      {fourStepState ? (
        <CellWrapper>
          <SaleCellWrapper></SaleCellWrapper>
          <PriceCellWrapper>
            <PriceWrapper fourStepState={fourStepState}>
              20,000
            </PriceWrapper>
            <PriceTail>만</PriceTail>
          </PriceCellWrapper>
          <RentCellWrapper
            style={{
              width: '100%',
              height: '33%',
              borderTop: '1px solid lightgray',
            }}
          ></RentCellWrapper>
        </CellWrapper>
      ) : (
        // <div>aaaaaaaaaa</div>
        <>
          <CustomPriceCellItem
            name={isPresale ? 'presale_base_price' : 'sale_price'}
            contractType="매매"
            value={isPresale ? presale_base_price : sale_price}
            isEditing={isContractTypeShow}
            oriContractType={contract_type}
            node={node}
            setIsFocusPriceItem={setIsFocusPriceItem}
            nextValueHas={
              isContractTypeShow ||
              (jeonse_deposit && jeonse_deposit != 0) ||
              (deposit && deposit != 0) ||
              (rent && rent != 0)
            }
            property_type={property_type}
            presale_base_price={presale_base_price}
          />
          <CustomPriceCellItem
            name="jeonse_deposit"
            contractType="전세"
            value={fourStepState ? 20000 : jeonse_deposit}
            isEditing={isContractTypeShow}
            oriContractType={contract_type}
            node={node}
            setIsFocusPriceItem={setIsFocusPriceItem}
            nextValueHas={
              isContractTypeShow ||
              (contract_type.includes('월세') &&
                ((deposit && deposit != 0) || (rent && rent != 0)))
            }
          />
          <RentWrap
            perHeight={perHeight}
            isVisible={
              isContractTypeShow ||
              (contract_type.includes('월세') &&
                ((deposit && deposit != 0) || (rent && rent != 0)))
            }
            tabIndex={-1}
          >
            <CustomPriceCellItem
              name="deposit"
              contractType="월세"
              value={deposit}
              isEditing={isContractTypeShow}
              oriContractType={contract_type}
              node={node}
              setIsFocusPriceItem={setIsFocusPriceItem}
              nextValueHas={false}
            />
            <Slash tabIndex={-1}>/</Slash>
            <CustomPriceCellItem
              name="rent"
              contractType="월세"
              value={rent}
              isEditing={isContractTypeShow}
              oriContractType={contract_type}
              node={node}
              setIsFocusPriceItem={setIsFocusPriceItem}
              nextValueHas={false}
            />
          </RentWrap>
        </>
      )}
    </Wrapper>
  )
}

const SaleCellWrapper = styled.div`
  width: 100%;
  height: 33%;
  borderbottom: 1px solid lightgray;
`
const RentCellWrapper = styled.div`
  width: 100%;
  height: 33%;
  borderbottom: 1px solid lightgray;
`

const CellWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const PriceCellWrapper = styled.div`
  width: 100%;
  height: 34%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const PriceTail = styled.div``
const cursor = keyframes`

50%{ border-color: transparent; }
`

const typing = keyframes`
from{ width: 0; }
to{
  width:60px;
}

`

const PriceWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  ${({ fourStepState }) =>
    fourStepState == true &&
    css`
      opacity: 1;

      width: 60px;
      overflow: hidden;
      white-space: nowrap;
      border-right: 0.05em solid black;
      animation: ${typing} 2s steps(22),
        ${cursor} 0.5s steps(1) infinite;
    `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: relative;
  z-index: 1;
  ${({ isVisible }) => isVisible && `opacity: 1;`}
`

const RentWrap = styled.div`
  align-items: center;
  width: 100%;
  height: 33%;
  height: ${({ perHeight }) => perHeight};
  position: relative;
  display: none;
  ${({ isVisible }) => isVisible && `display: flex;`}
`

const Slash = styled.div`
  width: 5px;
  z-index: 999;
`

export default CustomPriceCell
