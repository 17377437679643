import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { getAddressName, hasLandAddress } from 'lib/utils'
import useRoadName from './hooks/useRoadName'
import { Card } from 'components/design'
import SummaryHeaderClose from './SummaryHeaderClose'
import SummaryHeaderMeta from './SummaryHeaderMeta'
import HeaderButtonGroup from './HeaderButtonGroup'
import { useContext, useMemo } from 'react'
import MapContext from 'contexts/map'
import useQueryString from 'lib/hooks/useQueryString'
import { useHistory } from 'react-router'
import useIsMobile from 'lib/hooks/useIsMobile'
import { Skeleton } from 'antd'
import HeaderButtonShare from './HeaderButtonShare'
import HeaderButtonFavorite from './HeaderButtonFavorite'
import SummaryBuildingRoadView from '../SummaryBuilding/SummaryBuildingRoadView'
import { getDisplayAddress } from 'utils'

const SummaryHeader = ({ land, building, isOpen }) => {
  const isMobile = useIsMobile()
  const history = useHistory()
  const query = useQueryString()

  const {
    state: { clickPosition },
    actions,
  } = useContext(MapContext)

  const buildingName = useMemo(
    () =>
      building?.apt?.apt_name || building?.result?.[0]?.building_name,
    [building],
  ) //건물명

  const roadName = useRoadName(land) //도로명

  /**
   * 도로명 주소 규칙
   * 건물명이 있으면 도로명 주소 뒤에 지번 주소를 붙여준다
   * ex) 서구 도안동로 183 (도안동 1362)
   */
  const getRoadName = () => {
    if (buildingName) {
      //건물명 있음
      return `${roadName} (${getAddressName(land, roadName, true)})`
    } else {
      //건물명 없음
      return roadName
    }
  }

  /**
   * 모바일 일 때 닫기
   */
  const onClickClose = () => {
    if (Object.keys(clickPosition).length > 0) {
      // 클릭한 폴리곤이 있을 때
      actions.setClickPosition({})
      actions.setPolygons([])
      actions.setBuildingPolygons([])
    }

    // back 쿼리가 있을 경우 close의 동작 방식이 뒤로가기로 바뀜.
    if (query?.back) {
      history.go(-1)
      return
    }

    history.push('/home')
  }

  if (!land)
    return (
      <StyledHeader border>
        <StyledHeaderBody>
          <Skeleton />
        </StyledHeaderBody>
      </StyledHeader>
    )

  return (
    <StyledHeader border>
      <StyledHeaderBody>
        <Title>
          {getDisplayAddress(land, building, roadName)}
          {isMobile && <HeaderButtonFavorite />}
          {isMobile && <HeaderButtonShare />}
        </Title>
        {!hasLandAddress(land.num1, land.num2) ? (
          <RoadName>(우) {land.zip_code}</RoadName>
        ) : roadName ? (
          <RoadName>
            {getRoadName()}
            {land?.zip_code && (
              <>
                <br />
                (우) {land.zip_code}
              </>
            )}
          </RoadName>
        ) : (
          <RoadName>
            도로명주소 없음
            {land?.zip_code && (
              <>
                <br />
                (우) {land.zip_code}
              </>
            )}
          </RoadName>
        )}
        <SummaryBuildingRoadView />
        <SummaryHeaderMeta land={land} building={building} />
        {isMobile && isOpen && <HeaderButtonGroup land={land} />}
        {!isMobile && <HeaderButtonGroup land={land} />}
      </StyledHeaderBody>

      {isMobile && !isOpen && (
        <SummaryHeaderClose onClick={onClickClose} />
      )}
    </StyledHeader>
  )
}

export const StyledHeader = styled(Card)`
  position: relative;
  display: flex;
  color: ${theme.colors.gray[900]};
  padding: 20px 20px 28px;

  ${media.mediumS`
    padding-top: 20px;
  `}
`

export const StyledHeaderBody = styled.div`
  flex: 1;
`

const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 6px;
  width: 90%;
`

const RoadName = styled.div`
  color: ${theme.colors.gray[500]};
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;

  ${media.mediumS`
    margin-bottom: 12px;
  `}
`

export default SummaryHeader
