import { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { setIsVisibleBottomNav } from 'modules/notev2'
import { useEffect } from 'react'
import NotePaymentDenggibu from './NotePaymentDenggibu'
import {
  Block,
  ContentTitle,
} from 'components/jmapnotev2/style/NotePaymentStyle'
import NotePaymentList from './NotePaymentList'
import NotePaymentRule from './NotePaymentRule'
import NotePaymentInfo from './NotePaymentInfo'
import { useDispatch, useSelector } from 'react-redux'
import NotePaymentPayFail from './NotePaymentPayFail'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

/**
 * 결제관리 메인
 * 일반 유저(프리, 베이직)
 */
const NotePaymentContent = () => {
  const dispatch = useDispatch()
  const planInfo = useSelector((state) => state.auth.planInfo)

  const { hasPlan } = useNoteHasPlan()

  useEffect(() => {
    dispatch(setIsVisibleBottomNav(true))
  }, [])

  return (
    <>
      <ContentTitle>요금제</ContentTitle>
      {planInfo?.error && <NotePaymentPayFail />}
      <HalfBlockWrapper>
        <NotePaymentInfo />
        {hasPlan && <NotePaymentDenggibu />}
      </HalfBlockWrapper>

      <ContentTitle>결제내역</ContentTitle>
      <Block nopadding>
        <NotePaymentList />
      </Block>
      <NotePaymentRule />
    </>
  )
}

const HalfBlockWrapper = styled.div`
  display: flex;
  gap: 34px;
  width: 100%;
  margin-bottom: 44px;

  ${media.mediumM`
    flex-direction: column;
    gap: 30px;
  `}
`
export default NotePaymentContent
