import { Skeleton } from 'antd'
import {
  Card,
  CardBody,
  CardHeader,
  List,
  ListItemDivider,
  Empty,
} from 'components/design'
import NoteListItem from './NoteListItem'

const NoteListCard = ({ children }) => {
  return (
    <Card size="large" noMargin>
      <CardHeader>이 주소에 등록된 매물</CardHeader>

      {children}
    </Card>
  )
}

const NoteList = ({
  loading,
  notes,
  mapVisible,
  channelSrl,
  folderSrl,
  addressSrl,
  onClick,
  append,
  onRequest,
  setIsVisibleNoteDetail,
  setMobileData,
  Markerclick,
}) => {
  if (loading)
    return (
      <NoteListCard>
        <CardBody>
          <Skeleton />
        </CardBody>
      </NoteListCard>
    )
  if (!notes || notes.length === 0)
    return (
      <NoteListCard>
        <CardBody>
          <Empty>매물이 없습니다. 매물을 추가해보세요.</Empty>
        </CardBody>
      </NoteListCard>
    )

  return (
    <NoteListCard>
      <ListItemDivider />
      <List>
        {notes?.length > 0 &&
          notes.map((item, i) => {
            return (
              <NoteListItem
                Markerclick={Markerclick}
                setMobileData={setMobileData}
                setIsVisibleNoteDetail={setIsVisibleNoteDetail}
                mapVisible={mapVisible}
                item={item}
                key={i}
                channelSrl={channelSrl}
                folderSrl={folderSrl}
                addressSrl={addressSrl}
                onClick={onClick}
                onRequest={onRequest}
              />
            )
          })}
      </List>

      {append}
    </NoteListCard>
  )
}

export default NoteList
