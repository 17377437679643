import { message } from 'antd'
import useContacts from 'containers/note/contact/hooks/useContacts'
import { saveContact } from 'lib/api/note/contactApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import NoteContactForm from './NoteContactForm'

const NoteContactSave = ({
  visible,
  setVisible,
  callbackFn,
  cardList,
  setCardList,
  inputValue,
  setInputValue,
}) => {
  const [onRequest, loading, data, error, reset] = useAxios(
    saveContact,
    { resetError: true },
  )
  const { _getContacts } = useContacts()

  const handleSubmit = (data) => {
    onRequest({
      ...data,
    })
  }

  useEffect(() => {
    if (!data) return

    if (data && cardList) {
      setCardList((prev) => [...prev, data.result]) //추가한연락처 바로 카드리스트에 추가시키기
    }

    if (data?.message === 'success') {
      setVisible(false)
      _getContacts()
    }
    callbackFn && callbackFn()
  }, [data])

  return (
    <NoteContactForm
      setInputValue={setInputValue}
      inputValue={inputValue}
      title="연락처 추가"
      visible={visible}
      setVisible={setVisible}
      onSubmit={handleSubmit}
    />
  )
}

export default NoteContactSave
