import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { useSelector } from 'react-redux'
import { ncancelVerification } from 'lib/api/notev2/adSubmit'
import { Modal } from 'components/design'
import { numberWithCommas } from 'utils'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'

/**
 * 광고 목록 버튼
 * 검증 요청 취소
 * @param adSrl
 * @param callbackFn 삭제 후 목록 재조회
 * @param setUpdatedAdSrl 재조회를 위해 저장하는 ad_srl
 * @param forForm 폼에서 사용하는지 여부
 */
const CancelButton = ({
  adSrl,
  callbackFn,
  setUpdatedAdSrl,
  forForm,
}) => {
  const isInForm = useRouteMatch('/note/v2/ad/:id')
  const [cancelAmount, setCancelAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [cancelEndModalVisible, setCancelEndModalVisible] =
    useState(false)
  const planInfo = useSelector((state) => state.auth.planInfo)

  const [onRequestCancel, , dataCancel, errorCancel] = useAxios(
    ncancelVerification,
    {
      resetError: true,
    },
  ) //검증 취소

  /**
   * 검증 취소 버튼
   */
  const onClickCancel = () => {
    setConfirmModalVisible(true)
  }

  /**
   * 검증 취소 완료
   */
  useEffect(() => {
    if (!dataCancel) return
    setLoading(false)

    if (dataCancel?.error == 0) {
      setCancelAmount(dataCancel.result?.refundAmount || 0)
      message.success('검증 취소가 완료되었습니다.')
      setConfirmModalVisible(false)
      setCancelEndModalVisible(true)
    } else if (dataCancel?.result == 'error') {
      message.error(
        dataCancel?.result?.errormsg ||
          '광고 전송에 필요한 항목 검증에 실패하였습니다.',
      )
    } else {
      message.error('요청중 오류가 발생했습니다.')
    }
  }, [dataCancel])

  useEffect(() => {
    if (!errorCancel) return
    setLoading(false)
  }, [errorCancel])

  /**
   * 검증 요청 취소 확인 모달 닫기
   */
  const onCloseConfirmModal = () => {
    setConfirmModalVisible(false)
  }

  /**
   * 검증 취소 결과 확인 모달 닫기
   */
  const onCloseEndModal = () => {
    callbackFn && callbackFn()
    setCancelEndModalVisible(false)
  }

  /**
   * 검증 요청 취소 호출
   */
  const cancel = () => {
    setLoading(true)
    onRequestCancel({
      ad_srl: adSrl,
      plan_srl: planInfo.allinone_plan_srl,
    })
    setUpdatedAdSrl && setUpdatedAdSrl(adSrl)
  }

  return (
    <>
      <AdButton
        expand
        color="danger"
        size={forForm ? 'xlarge' : 'small'}
        redborder={!forForm}
        onClick={() => onClickCancel(adSrl)}
        forForm={forForm}
      >
        검증요청취소
      </AdButton>
      <Modal
        title="검증 요청을 취소하시겠어요?"
        visible={confirmModalVisible}
        onCancel={onCloseConfirmModal}
        okText="검증 요청 취소"
        cancelText="닫기"
        okButtonProps={{ color: 'danger' }}
        onOk={cancel}
      >
        사용자의 요청으로 검증 요청이 취소됩니다. 검증에 소요된 비용은
        환불되지 않을 수도 있습니다.
      </Modal>
      <Modal
        title="검증 비용 환불"
        visible={cancelEndModalVisible}
        onOk={onCloseEndModal}
        onCancel={onCloseEndModal}
        okText="확인"
        cancelHide
        okButtonProps={{ color: 'blue' }}
      >
        검증 비용 {numberWithCommas(cancelAmount)}원이 환불되었습니다.
        자세한 환불 내역은 [결제관리]-[결제내역]에서 확인해주세요.
      </Modal>
      {loading && <FullScreenLoading highLevel />}
    </>
  )
}

export default CancelButton
