import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { rejectApproval } from 'lib/api/notev2/adSubmit'
import { useSelector } from 'react-redux'

/**
 * 광고 목록 버튼
 * 승인 거절
 * @param adSrl
 * @param callbackFn
 */
const RejectButton = ({ adSrl, callbackFn }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const [onRequestReject, , dataReject] = useAxios(rejectApproval, {
    resetError: true,
  }) //승인거절

  const onClickReject = () => {
    onRequestReject({
      ad_srl: adSrl,
      plan_srl: planInfo.allinone_plan_srl,
    })
  }

  useEffect(() => {
    if (!dataReject) return

    if (dataReject?.message == 'success') {
      message.success('승인이 거절되었습니다.')
      callbackFn()
    } else {
      message.error('승인 거절 요청 중 오류가 발생했습니다.')
    }
  }, [dataReject])

  return (
    <AdButton
      forForm
      type="button"
      color="danger"
      size="xlarge"
      onClick={() => onClickReject(adSrl)}
    >
      승인 거절
    </AdButton>
  )
}

export default RejectButton
