import { useContext } from 'react'
import styled from 'styled-components'
import CardContext from './context/CardContext'

const CardBody = (props) => {
  const context = useContext(CardContext) || null

  const sizeState = context?.state?.sizeState || 'medium'

  return <StyledCardBody size={sizeState} {...props} />
}

const StyledCardBody = styled.section`
  padding: 1.25rem 1.25rem 1.75rem;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          padding: 1rem 1rem 1.25rem;
        `
      case 'medium':
        return `
          padding: 1.25rem 1.25rem 1.75rem;
        `
      case 'large':
        return `
          padding: 1.5rem 1.5rem 1.875rem;
        `
    }
  }}
`

export default CardBody
