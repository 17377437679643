import useAdConvert from 'lib/hooks/notev2/useAdConvert'
import { AdButton } from './ButtonStyle'
import useAdRegister from 'lib/hooks/notev2/useAdRegister'
import { useFormContext } from 'react-hook-form'

/**
 * 매물 3뎁스 광고 목록 버튼
 * 광고 종료 시 광고 새롭게 등록 버튼
 */
const NewWriteButton = () => {
  const { watch } = useFormContext()

  const address_srl = watch('address_srl')
  const property_type = watch('property_type')
  const channel_srl = watch('channel_srl')
  const note_srl = watch('note_srl')
  const ho = watch('ho')

  const { onClickAdLink } = useAdConvert()

  return (
    <>
      <AdButton
        expand
        color="gray"
        size={'small'}
        onClick={() =>
          onClickAdLink({
            address_srl,
            property_type,
            channel_srl,
            note_srl,
            ho,
          })
        }
        forNote
      >
        신규 등록
      </AdButton>
    </>
  )
}

export default NewWriteButton
