import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { options } from '../../options'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 현관종류
 */
const NoteAdColumnDoor = ({ required = false }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const doorType = watch('article.doorType') || ''

  const list = options['현관종류']

  const onChangeSelect = (e) => {
    onUpdate('article.doorType', e.value)
  }

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>현관종류</ColumnTitle>
      <ColumnVal gap={15}>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: doorType || '선택',
            value: doorType,
          }}
          isSearchable
          options={getSimpleSelectboxOptions(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('article.doorType')}
          onChange={onChangeSelect}
        />

        <ValidateWarn>
          {errors?.article?.doorType?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnDoor
