import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import BlueTextBox from '../../elements/BlueTextBox'
import { useState } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 상세정보 상세설명
 */
const NoteAdColumnDetail = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const desc = watch('etcInfo.desc')

  const [inputCount, setInputCount] = useState(desc?.length || 0)

  const onTextareaHandler = (e) => {
    setInputCount(e.target.value.length)
  }

  return (
    <ColumnSet>
      <ColumnTitle>상세설명</ColumnTitle>
      <ColumnVal>
        <TextareaWrapper>
          <BlueTextBox
            as="textarea"
            placeholder="상세 페이지에 나오는 매물 설명 문구에요. 줄바꿈을 포함하여 1,000자 이내로 작성해주세요."
            {...register('etcInfo.desc')}
            onBlur={(e) => {
              onUpdate('etcInfo.desc', e.target.value)
            }}
            height={192}
            padding="10px 14px"
            onChange={onTextareaHandler}
            maxLength="1000"
          ></BlueTextBox>
          <Count>{inputCount} / 1000</Count>
        </TextareaWrapper>
        <FormDesc>
          <li>
            한글, 영어, 숫자, ㎡ % / 이외에 특수문자는 입력할 수
            없습니다.
          </li>
        </FormDesc>
        <ValidateWarn>{errors?.etcInfo?.desc?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const Count = styled.div`
  margin-left: 6px;
  font-size: 12px;
  color: ${theme.colors.blue[900]};
`

const TextareaWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
`

export default NoteAdColumnDetail
