import { getSummary } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import Log from 'lib/utils/Log'
import {
  setCurrentAddressId,
  setIsApt,
  setSummaryInfo,
} from 'modules/summary'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/**
 * 1뎁스 기본 정보 불러옴
 */
const SummaryContainer = ({ children, addressId }) => {
  const history = useHistory()
  // const addressId = match?.params?.id
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const dispatch = useDispatch()

  const [onRequest, loading, data, error, reset] = useAxios(getSummary)

  /**
   * @description fetchData
   */
  useEffect(() => {
    onRequest(
      {
        address_id: addressId,
      },
      null,
      false,
    )

    dispatch(setCurrentAddressId(addressId))
  }, [onRequest, addressId])

  /**
   * @description SetData
   */
  useEffect(() => {
    if (!data || data?.message !== 'success') return

    Log.success('getSummary', data.result)
    dispatch(setSummaryInfo(data.result))

    //공동주택 여부 세팅
    const custom_purpose =
      data.result?.building?.result?.[0]?.custom_purpose
    const is_group_building =
      data.result?.building?.result?.[0]?.is_group_building

    dispatch(
      setIsApt(
        ['아파트', '오피스텔', '연립주택', '다세대주택'].includes(
          custom_purpose,
        ) ||
          (is_group_building === 2 &&
            ['상가·사무실', '숙박'].includes(custom_purpose)),
      ),
    )
  }, [data])

  /**
   * error 발생 시 /map 으로 리다이렉트
   */
  useEffect(() => {
    if (error) {
      // message.error(
      //   '일시적인 문제가 발생했습니다. 관리자에게 문의하십시오.',
      // )
      history.push(`/`)
    }
  }, [error])

  return children({ summaryInfo, loading, error })
}

export default SummaryContainer
