import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 대지지분
 */
const NoteAdColumnLandShare = ({ half = true }) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == adSrl &&
    adFormControl?.disabledColumns.includes('article.spc3')

  return (
    <ColumnSet half={half}>
      <ColumnTitle>대지지분</ColumnTitle>
      <ColumnVal>
        <InputText
          width={InputWidth}
          type="text"
          {...register('article.spc3')}
          onBlur={(e) => {
            onUpdate('article.spc3', e.target.value)
          }}
          disabled={isDisabled}
        />
        <ValidateWarn>{errors?.article?.spc3?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnLandShare
