import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { CardTitle } from 'components/design'

export const SmallTitle = styled.div`
  color: ${theme.colors.gray[500]};
  font-weight: 500;
  font-size: 0.92rem;
  margin-bottom: 0.75rem;
`

export const MainTitle = styled.div`
  word-break: keep-all;
  & > span {
    color: ${theme.colors.primary[500]};
  }
`
const SummaryLegalInfoHeader = ({ smallTitle, mainTitle }) => {
  return mainTitle ? (
    <CardTitle>
      <SmallTitle>{smallTitle}</SmallTitle>
      <MainTitle>{mainTitle}</MainTitle>
    </CardTitle>
  ) : (
    smallTitle
  )
}

export default SummaryLegalInfoHeader
