import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Tag } from 'components/design'

const CustomTooltip = ({
  active,
  payload,
  label,
  getLabelPrepend,
  tooltipFormatter,
  landArea,
  prices,
}) => {
  const { state } = useContext(MapContext)

  const getAreaPrice = (value) => {
    const pricePer =
      value / (prices.estimate_price?.land_area || landArea || 1)

    return state.isSquareMeter
      ? tooltipFormatter(pricePer)
      : tooltipFormatter(pricePer * 3.305)
  }

  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledTooltipLabel>{label}년</StyledTooltipLabel>
        {payload.map((item, i) => {
          return (
            <StyledTooltipItem key={i}>
              <StyledTooltipIcon color={item.color} />
              <span>
                {getLabelPrepend()}
                {/* 가격 */}
                {item.name !== 'addressHistory' &&
                  tooltipFormatter(item.value)}
                {item.name === 'addressHistory' &&
                  !item.payload?.yet &&
                  tooltipFormatter(item.value)}
                {item.name === 'addressHistory' &&
                  item.payload?.yet &&
                  '미공시'}
                {/* 평당가 */}
                {item.name === 'addressHistory' && state.isTotal && (
                  <MutedValue>
                    {' '}
                    ({state.isSquareMeter ? '㎡당' : '평당'}
                    {/* 면적이 합필/분필 되는 경우, 최신 면적으로 나누게 되어 틀린 단가를 보여주기 때문에 아래와 같이 unit_price를 표시한다. */}
                    {state.isSquareMeter
                      ? tooltipFormatter(
                          item.payload.addressHistoryUnit,
                        )
                      : tooltipFormatter(
                          item.payload.addressHistoryUnit * 3.305,
                        )}
                    )
                  </MutedValue>
                )}
                {item.name === 'purchase' && state.isTotal && (
                  <MutedValue>
                    {' '}
                    ({state.isSquareMeter ? '㎡당' : '평당'}{' '}
                    {getAreaPrice(item.value)})
                    {/* 지분 거래 표시하기 */}
                    {item.payload?.is_share === 1 && (
                      <Tag size="xs" color="danger">
                        지분
                      </Tag>
                    )}
                  </MutedValue>
                )}
                {item.name === 'estimatePrice' && state.isTotal && (
                  <MutedValue>
                    {' '}
                    ({state.isSquareMeter ? '㎡당' : '평당'}{' '}
                    {getAreaPrice(item.value)})
                  </MutedValue>
                )}
              </span>
            </StyledTooltipItem>
          )
        })}
      </StyledTooltip>
    )
  }

  return null
}

const MutedValue = styled.span`
  color: ${theme.colors.gray[500]};
  & > div {
    margin-left: 3px;
  }
`

const StyledTooltip = styled.div`
  padding: 0.5rem 0.75rem;
  background-color: white;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: ${theme.borderRadius[1]};
  box-shadow: ${theme.shadow['card-2']};
`

const StyledTooltipLabel = styled.div`
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const StyledTooltipItem = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const StyledTooltipIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: ${theme.colors.primary[400]};
  background-color: ${({ color }) =>
    color || theme.colors.primary[400]};
`

export default CustomTooltip
