import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDashboardBlock from './NoteDashboardBlock'
import NoteDashboardAdStatusContainer from 'containers/notev2/NoteDashboardAdStatusContainer'
import { Button, Empty } from 'components/design'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import { useDispatch } from 'react-redux'
import { setAuthModalVisible } from 'modules/noteAd'
import { useSelector } from 'react-redux'

/**
 * 매물노트 - 대시보드
 * 광고 상태판
 */
const NoteDashboardAdStatus = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const dispatch = useDispatch()

  const { isAllInOne } = useIsAllInOne()

  const onClickAuth = () => {
    dispatch(setAuthModalVisible(true))
  }

  if (!isAllInOne) return

  return (
    <NoteDashboardAdStatusContainer>
      {({
        showStatus,
        status,
        icons,
        colors,
        onClickStatus,
        onClickAdPage,
      }) => {
        return (
          <NoteDashboardBlock title="광고관리">
            <StyledButton
              size="small"
              color="blue"
              onClick={onClickAdPage}
            >
              광고관리로 이동
            </StyledButton>
            <Wrapper>
              {loginInfo?.validated ? (
                <>
                  {status &&
                    showStatus.map((item, i) => {
                      return (
                        <Block
                          key={`dashboard-ad-status-${i}`}
                          onClick={() => onClickStatus(item)}
                        >
                          <Icon color={colors[item]}>
                            {icons[item]}
                          </Icon>
                          <Text grow>
                            <Title>{item}</Title>
                            <Count>{status[item]}</Count>
                          </Text>
                        </Block>
                      )
                    })}
                </>
              ) : (
                <Empty>
                  본인인증을 한 회원만 이용할 수 있습니다.
                  <Button onClick={onClickAuth} color="blue">
                    본인인증 하기
                  </Button>
                </Empty>
              )}
            </Wrapper>
          </NoteDashboardBlock>
        )
      }}
    </NoteDashboardAdStatusContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  ${media.xxLarge`
    flex-wrap: wrap;
  `}
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  max-width: 44px;
  max-height: 44px;
  border-radius: 50%;
  flex-shrink: 0;

  ${({ color }) => {
    switch (color) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary[50]};
        `
      case 'blue':
        return `
          background-color: ${theme.colors.blue[50]};
        `
      case 'blue-plain':
        return `
          background-color: ${theme.colors.blue[50]};
        `
      case 'gray':
        return `
          background-color: ${theme.colors.gray[150]};
          svg {
             fill: ${theme.colors.gray[800]};
          }
        `
      case 'red':
        return `
          background-color: ${theme.colors.red[50]};
        `
    }
  }}
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  ${({ hover }) => (hover ? `` : `flex-grow: 1;`)};

  ${media.xxLarge`
    gap: 6px;
  `}
`
const Block = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  width: calc(calc(100% / 8) - 8.6px);
  padding: 26px 16px;
  position: relative;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 2px 5px 0px #f2f3f8;
  background-color: ${theme.colors.base.white};
  border: 1px solid #edf2f7;
  cursor: pointer;
  transition: 0.3s;

  ${media.xxLarge`
    padding: 16px;
    width: calc(calc(100% / 4) - 8.6px);
  `}

  ${media.mediumM`
    width: calc(calc(100% / 2) - 8.6px);
  `}
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  flex-shrink: 0;
  word-break: keep-all;

  ${({ black }) =>
    black &&
    `
      color: ${theme.colors.gray[900]};
    `}
`

const Count = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  font-variant-numeric: tabular-nums;

  &::after {
    content: '\f105';
    position: absolute;
    right: 0;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 100;
    font-size: 20px;
    color: #d9d9d9e6 !important;
  }

  ${({ red }) =>
    red &&
    `
      color: ${theme.colors.tint.danger};
  `}

  ${({ active }) =>
    active &&
    `
      &::after {
        color: ${theme.colors.gray[900]} !important;
      }
  `}
`

const StyledButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 16px;
`

export default NoteDashboardAdStatus
