import HeaderBack from 'components/common/Header/HeaderBack'
import BaseHeaderNav from 'components/base/BaseHeader/BaseHeaderNav'
import ChannelSelect from './ChannelSelect'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import NoteDetailNoteConverter from '../detail/NoteDetailNoteConverter'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  setIsVisibleBottomNav,
  setIsVisibleHeaderChannel,
  setNotePropertyFilter,
} from 'modules/notev2'
import { useSelector } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import { ReactComponent as Pencil } from 'assets/icon/pencil.svg'
import { Input, Modal } from 'components/design'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom'
import { modifyAddress } from 'lib/api/note/addressApi'
import useAxios from 'lib/hooks/useAxios'
import { getPropertyFilter } from 'lib/api/notev2/filterApi'
import { media } from 'lib/styles/theme'

const MobileChannelHeader = ({
  onChange,
  channel = true,
  customMobileChannel,
  addedIcon,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const onClose = () => {
    dispatch(setIsVisibleBottomNav(true))
    dispatch(setIsVisibleHeaderChannel(true))
    history.go(-1)
  }
  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )
  const height = useSelector((state) => state.notice.height)

  const [editModalVisible, setEditModalVisible] = useState(false)

  const isVisibleHeaderChannel = useSelector(
    (state) => state.notev2.isVisibleHeaderChannel,
  )
  const headerContent = useSelector(
    (state) => state.notev2.headerContent,
  )
  const headerSubContent = useSelector(
    (state) => state.notev2.headerSubContent,
  )
  const selectedAddressStore = useSelector(
    (state) => state.notev2.selectedAddressStore,
  )

  const onCancel = () => {
    setEditModalVisible(false)
  }
  const [nameInputValue, setNameInputValue] = useState('')

  const [onRequest] = useAxios(modifyAddress)
  const [onRequestFilter, , dataFilter, ,] = useAxios(getPropertyFilter)
  const match = useRouteMatch('/note/v2/notes/:channelSrl')
  const docsPage = useRouteMatch('/note/v2/docs/:channelSrl')
  const picturePage = useRouteMatch('/note/v2/pictures/:channelSrl')

  const { q } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )

  const onOk = useCallback(async () => {
    if (!selectedAddressStore?.code?.[0]) {
      return
    }
    // 주소 별명(alias) 수정 api 요청
    await onRequest({
      channelSrl: match?.params?.channelSrl,
      addressId: selectedAddressStore?.code?.[0],
      alias: nameInputValue,
    })

    //시트 refresh
    //    onClickAllFilter(title)

    //왼쪽 필터 refresh
    onRequestFilter({ channel_srl: match?.params?.channelSrl })

    setEditModalVisible(false)
  }, [match, selectedAddressStore, nameInputValue])
  const onEnter = (e) => {
    if (e.charCode == 13) {
      onOk()
    }
  }

  const editClick = () => {
    const strs = headerSubContent?.split(' ')
    strs.shift()
    const result = strs.join(' ')
    setNameInputValue(result)

    setEditModalVisible(true)
  }

  const textComma = (text, length, lastText) => {
    if (length == '' || length == null) {
      length = 7
    }
    if (lastText == '' || lastText == null) {
      lastText = '...'
    }
    if (text.length > length) {
      text = text.substr(0, length) + lastText
    }
    return text
  }

  useEffect(() => {
    if (!dataFilter || !dataFilter.result) return
    dispatch(setNotePropertyFilter(dataFilter.result?.property || {}))
  }, [dataFilter])

  return (
    <MobileChannelWrapper
      isnoticebarvisible={isNoticeBarVisible ? 'Y' : 'N'}
      noticebarheight={height}
    >
      <HeaderBack onClose={onClose} />
      {customMobileChannel && customMobileChannel}
      {docsPage || picturePage
        ? channel &&
          (isVisibleHeaderChannel ? (
            <ChannelSelect onChange={onChange} />
          ) : (
            <HeaderContentWrapper nopadding>
              {docsPage && '계약관리'}
              {picturePage && '사진관리'}
            </HeaderContentWrapper>
          ))
        : isVisibleHeaderChannel
        ? channel && <ChannelSelect onChange={onChange} />
        : channel && (
            <HeaderContentWrapper>
              {headerContent == '모든매물' ? (
                q ? (
                  <p>&#8220;{textComma(q)}&#8221;에 대한 검색결과</p>
                ) : (
                  '모든매물'
                )
              ) : headerSubContent == '' ||
                headerSubContent == '전체' ? (
                <p> {headerContent}</p>
              ) : (
                <p>
                  {textComma(headerSubContent, 14)}{' '}
                  {['아파트', '오피스텔'].includes(headerContent) && (
                    <Pencil
                      width={16}
                      height={16}
                      onClick={editClick}
                    />
                  )}
                </p>
              )}
            </HeaderContentWrapper>
          )}
      <IconWrapper>
        {addedIcon}
        <NoteDetailNoteConverter color="black" />
        <BaseHeaderNav />
      </IconWrapper>

      <Modal
        visible={editModalVisible}
        title="단지명 편집"
        onCancel={onCancel}
        onOk={onOk}
      >
        <Input
          onKeyPress={onEnter}
          value={nameInputValue}
          onChange={(e) => {
            setNameInputValue(e?.target?.value)
          }}
          expand
        />
      </Modal>
    </MobileChannelWrapper>
  )
}

const MobileChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  position: fixed;
  left: 0;
  width: 100%;
  height: ${theme.base.headerHeight}px;
  background-color: ${theme.colors.base.white};
  margin-bottom: 0px;
  padding: 20px;
  z-index: 98;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  ${({ isnoticebarvisible, noticebarheight }) =>
    isnoticebarvisible == 'Y'
      ? `top: ${noticebarheight}px !important;`
      : `top: 0;`};
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const HeaderContentWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 0.25rem 0.5rem 0.25rem 0;
  color: ${theme.colors.gray[900]};
  font-size: 1.125rem;
  font-weight: 700;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;

  ${({ nopadding }) =>
    nopadding &&
    `
    padding: 0;
  `}

  ${media.mediumM`
    svg {
      margin-left: 8px;
      position:relative;
      top:2px;
    }
  `}
`

export default MobileChannelHeader
