import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Row,
  Table,
  TableContent,
  TableSubHeader,
  TableHeader,
  TableTitle,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import styled from 'styled-components'
import ColorSelectBox from 'components/jmapnotev2/elements/ColorSelectBox'
import { useSelector } from 'react-redux'
import {
  getOnlyRemoveCommaNumber,
  getSelectedValue,
  getSimpleSelectboxOptions,
} from 'lib/utils/notev2/util'
import Spinner from 'components/common/Loading/Spinner'
import { numberWithCommas } from 'utils'
import theme from 'lib/styles/theme'

/**
 * 분양권 - 1. 매매 물건의 표시
 * @param {*} param0
 * @returns
 */
const NoteDocsContractDisplayPreSale = ({
  onUpdate,
  isBuildingGongbuLoading,
  indicationSpec,
}) => {
  const { watch } = useFormContext()
  const [landTypes, setLandTypes] = useState([])
  const [structures, setStructures] = useState([])
  //const [purposes, setPurposes] = useState([])
  const options = useSelector((state) => state.notev2.noteOptions)
  const property_type = watch('property_type')

  const address = watch('address')
  const presale_area = watch('presale_area')
  const exclusive_area = watch('exclusive_area')
  const daejigwon_area = watch('daejigwon_area')
  const exclusive_type = watch('exclusive_type')

  const indications = useMemo(
    () => indicationSpec.map((item) => item.name),
    [indicationSpec],
  )

  return (
    <Wrapper>
      <TableTitle>1. 매매 물건의 표시</TableTitle>
      <Table>
        {isBuildingGongbuLoading && (
          <LoadingWrapper>
            <StyledSpinner />
            <Dimmer />
          </LoadingWrapper>
        )}
        <Row>
          <TableHeader>소재지</TableHeader>
          <TableContent basic noborderright>
            <ColorInput
              fullWidth
              type="text"
              defaultValue={address}
              onBlur={(e) => onUpdate('address', e.target.value)}
              minWidth={300}
            />
          </TableContent>
        </Row>
        <Row
          noborderbottom={
            !(
              indications.includes('rent_part') ||
              indications.includes('rent_area')
            )
          }
        >
          <TableHeader>분양면적</TableHeader>
          <TableContent>
            <AreaData expand>
              {indications.includes('presale_area') && (
                <>
                  <TableContent width={130} noborderright>
                    <ColorInput
                      type="number"
                      key={presale_area || ''}
                      defaultValue={
                        presale_area && presale_area > 0
                          ? numberWithCommas(
                              Math.round(
                                parseFloat(presale_area) * 1000,
                              ) / 1000,
                            )
                          : ''
                      }
                      onBlur={(e) =>
                        onUpdate(
                          'presale_area',
                          getOnlyRemoveCommaNumber(e.target.value),
                        )
                      }
                      append={'㎡'}
                    />
                  </TableContent>
                </>
              )}
              {indications.includes('exclusive_area') && (
                <RightArea>
                  <TableSubHeader width={75}>
                    {'전용면적'}
                  </TableSubHeader>
                  <TableContent width={130} noborderright>
                    <ColorInput
                      type="number"
                      key={exclusive_area || ''}
                      defaultValue={
                        exclusive_area && exclusive_area > 0
                          ? numberWithCommas(
                              Math.round(
                                parseFloat(exclusive_area) * 1000,
                              ) / 1000,
                            )
                          : ''
                      }
                      onBlur={(e) =>
                        onUpdate(
                          'exclusive_area',
                          getOnlyRemoveCommaNumber(e.target.value),
                        )
                      }
                      append={'㎡'}
                    />
                  </TableContent>
                </RightArea>
              )}
              {indications.includes('daejigwon_area') && (
                <>
                  <TableSubHeader width={200}>
                    (대지권 목적인 토지표시)면적
                  </TableSubHeader>
                  <TableContent width={130} noborderright>
                    <ColorInput
                      type="number"
                      key={daejigwon_area || ''}
                      defaultValue={
                        daejigwon_area && daejigwon_area > 0
                          ? numberWithCommas(
                              Math.round(
                                parseFloat(daejigwon_area) * 1000,
                              ) / 1000,
                            )
                          : ''
                      }
                      onBlur={(e) =>
                        onUpdate(
                          'daejigwon_area',
                          getOnlyRemoveCommaNumber(e.target.value),
                        )
                      }
                      append={'㎡'}
                    />
                  </TableContent>
                </>
              )}
            </AreaData>
            {indications.includes('exclusive_type') && (
              <>
                <TableSubHeader width={75}>TYPE</TableSubHeader>
                <TableContent width={130} noborderright>
                  <ColorInput
                    fullWidth
                    type="text"
                    defaultValue={exclusive_type}
                    onBlur={(e) =>
                      onUpdate('exclusive_type', e.target.value)
                    }
                  />
                </TableContent>
              </>
            )}
          </TableContent>
        </Row>
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Dimmer = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 50;
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
`

const LandData = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const BuildingData = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100% - 195px);
`

const AreaData = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 1px solid ${theme.colors.gray[200]};

  ${({ expand }) => expand && `width: calc(100% - 180px)`};
`

const RightArea = styled.div`
  width: 195px;
  display: flex;
  align-items: center;
  height: 100%;
`

export default NoteDocsContractDisplayPreSale
