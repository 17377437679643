import { Button } from 'components/design'
import NoteAccountAllInOneMemberInviteContainer from 'containers/notev2/NoteAccountAllInOneMemberInviteContainer'
import NotePaymentPersonNumberSelect from '../NotePayment/NotePaymentPersonNumberSelect'
import styled from 'styled-components'
import { media } from 'lib/styles/theme'

/**
 * 계정관리
 * 올인원 멤버 관리 탭
 * 초대하기
 * @param search 초대 후 검색 함수
 */
const NoteAccountAllInOneMeberInvite = ({ search }) => {
  return (
    <NoteAccountAllInOneMemberInviteContainer>
      {({ isInviteModalVisible, onClickInvite, onCancelModal }) => {
        return (
          <>
            {isInviteModalVisible && (
              <NotePaymentPersonNumberSelect
                search={search}
                onCancel={onCancelModal}
              />
            )}
            <StyledButton color="black" onClick={onClickInvite}>
              초대하기
            </StyledButton>
          </>
        )
      }}
    </NoteAccountAllInOneMemberInviteContainer>
  )
}

const StyledButton = styled(Button)`
  ${media.mediumS`
    margin-right: 20px;
  `}
`

export default NoteAccountAllInOneMeberInvite
