import React, { Suspense, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import ChannelSelect from '../channel/ChannelSelect'
import NoteSetting from '../NoteSetting'
import Spinner from 'components/common/Loading/Spinner'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useChannelInfo from 'containers/notev2/hooks/useChannelInfo'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { FilterScrollWrapper, MobileCover } from '../style/NoteStyle'
import MobileChannelHeader from '../channel/MobileChannelHeader'
import useIsMobile from 'lib/hooks/useIsMobile'
import Swipe from 'react-easy-swipe'
import NoteFilterWrapper from '../style/NoteFilterWrapper'

const NoteLeftSide = ({
  filters,
  setFilters,
  onSwipeStart,
  onSwipeMove,
  onSwipeEnd,
}) => {
  const dispatch = useDispatch()
  const channels = useSelector((state) => state.channel.channels)
  const history = useHistory()
  const { q, property_types } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )
  const isMobile = useIsMobile()

  const { _getChannel, channelResult } = useChannelInfo()

  const onChannelChange = (channelSrl, folderSrl) => {
    const targetChannel = channels.find(
      ({ channel_srl: channels_srl }) => {
        return channels_srl == channelSrl
      },
    )
    if (!targetChannel) {
      message.error('잘못된 요청입니다.')
      return
    }

    _getChannel({ channelSrl })
    localStorage.setItem('recentFolderSrl', folderSrl) //카드모드 호환 위해 채널 바꿀 때 기본 폴더로 설정
  }

  useEffect(() => {
    if (!channelResult) {
      return
    }

    if (!channelResult?.channel_srl) {
      message.error('잘못된 요청입니다.')
      return
    }

    localStorage.setItem('recentChannelSrl', channelResult.channel_srl)
    history.push(`/note/v2/docs/${channelResult.channel_srl}`)
  }, [channelResult])

  const NoteDocsFilter = useMemo(
    () => React.lazy(() => import('./NoteDocsFilter')),
    [],
  )

  return (
    <>
      {isMobile && <MobileChannelHeader onChange={onChannelChange} />}
      <Wrapper
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
      >
        <NoteFilterWrapper>
          <Scrollbars autoHide>
            <FilterScrollWrapper>
              {!isMobile && (
                <ChannelWrapper>
                  <ChannelSelect fixed onChange={onChannelChange} />
                </ChannelWrapper>
              )}
              <Suspense
                fallback={
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                }
              >
                <NoteDocsFilter
                  filters={filters}
                  setFilters={setFilters}
                />
              </Suspense>
              <NoteSetting />
            </FilterScrollWrapper>
          </Scrollbars>
        </NoteFilterWrapper>
        <MobileCover className="m-cover" />
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Swipe)`
  width: 100%;
  height: 100%;
`
const ChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
  text-align: center;
`
export default React.memo(NoteLeftSide)
