import { useContext, useEffect, useMemo, useState } from 'react'

import MapContext from 'contexts/map'
import useWindowSize from 'lib/hooks/useWindowSize'

import './MapRPMarkerItem.scss'
import MapAuctionMarkerItem from './MapAuctionMarkerItem'
import { getAuctionMarkers } from 'lib/api/layerApi'
import useAxios from 'lib/hooks/useAxios'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'

/**
 * 경매 레이어
 * @returns
 */
const MapAuctionMarkerContainer = ({ hide }) => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { viewState, filterState, layerState, map } = state
  const [onRequest, , data, , ,] = useAxios(getAuctionMarkers)
  const [overlays, setOverlays] = useState([]) // 마커 배열
  // 폴리곤 배열 선언
  const [polygons, setPolygons] = useState([])

  const size = useWindowSize()
  const limit = (size.width * size.height) / 50000

  const centerPoint = useSelector((state) => state.summary.centerPoint)
  const isSummaryPage = useRouteMatch({
    path: ['/:id'],
  })

  const { isJMapNote } = useNoteMatch()
  const isNoteListUrl = useRouteMatch('/note/v2/notes/:channelSrl')

  /**
   * @METHOD: 최초 마운트 시와 좌표 정보가 담긴 viewState의
   * 변화를 감지하여 마커를 그리는 로직.
   */
  useEffect(() => {
    // console.log('MapMarkers Mounted');
    if (
      localStorage.getItem('notemode') != 'card' &&
      isJMapNote &&
      !isNoteListUrl?.isExact
    )
      return

    //summary 페이지인 경우 summary 데이터가 바인딩 된 후 마커 로드(중복 방지)
    if (isSummaryPage && isSummaryPage?.isExact) {
      if (isSummaryPage.url != '/home' && !centerPoint) {
        return
      }
    }

    // 줌 레벨이 4 '초과'일 경우 오버레이를 초기화하고 중단
    if (viewState?.level > 4) {
      setOverlays([])
      // console.log('마커 중단');
      return
    }

    if (layerState.realPriceActive) {
      if (viewState.level > 11) {
        return
      }
      // 경매 파라미터 포맷
      let appraisal_price = []
      if (
        filterState.real_prices.appraisal_price[0] !== 0 ||
        filterState.real_prices.appraisal_price[1] !== 50
      ) {
        appraisal_price = filterState.real_prices.appraisal_price.map(
          (price) => {
            return parseInt(price) * 100000000
          },
        )
      }
      let winning_bid = []
      if (
        filterState.real_prices.winning_bid[0] !== 0 ||
        filterState.real_prices.winning_bid[1] !== 50
      ) {
        winning_bid = filterState.real_prices.winning_bid.map(
          (price) => {
            return parseInt(price) * 1000000
          },
        )
      }

      const payload = {
        swlat: viewState.swLatlng.getLat(),
        swlng: viewState.swLatlng.getLng(),
        nelat: viewState.neLatlng.getLat(),
        nelng: viewState.neLatlng.getLng(),
        level: viewState.level,
        // 경매 파라미터 세팅
        auction_date: filterState.real_prices.auction_date,
        appraisal_price,
        winning_bid,
        winning_bid_rate: filterState.real_prices.winning_bid_rate,
      }
      onRequest(payload, null, false)
    } else {
      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [
    viewState,
    layerState,
    map,
    filterState.real_prices.auction_date,
    filterState.real_prices.appraisal_price,
    filterState.real_prices.winning_bid,
    filterState.real_prices.winning_bid_rate,
    isNoteListUrl?.isExact,
  ])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result || data.result?.length === 0) return

    const newOverlays = data.result

    // 기존 데이터에 덮어씌운다.
    setOverlays(newOverlays)

    // 폴리곤 배열
    let tempPolygons = []

    newOverlays.map((auctions) => {
      const shpaePoints = auctions?.shape?.coordinates

      shpaePoints?.map((parts) => {
        const polygonPath = parts.map((part) => {
          return part.map((point) => {
            return new kakao.maps.LatLng(point[0], point[1])
          })
        })

        // 지도에 표시할 다각형을 생성합니다
        const polygon = new kakao.maps.Polygon({
          path: polygonPath, // 그려질 다각형의 좌표 배열입니다
          strokeWeight: 1, // 선의 두께입니다
          strokeColor: 'black', // 선의 색깔입니다
          strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
          fillColor: 'black', // 채우기 색깔입니다
          fillOpacity: 0.5, // 채우기 불투명도 입니다
          zIndex: 1,
        })
        // 하나씩 생성된 건물 폴리곤을 배열에 넣기
        tempPolygons.push(polygon)
      })
      setPolygons(tempPolygons)
    })
  }, [data])

  // 폴리곤 세팅 및 초기화
  useEffect(() => {
    if (polygons.length === 0) return

    polygons.map((polygon) => {
      polygon.setMap(map)
    })

    return () => {
      if (polygons.length === 0) return

      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [polygons])

  /**
   * @INFO: axios 요청은 항상 비동기이기 때문에
   * 아래의 초기화 코드가 가장 먼저 발생합니다.
   */
  useEffect(() => {
    setOverlays([])
    setPolygons([])
  }, [layerState, filterState])

  // @METHOD: fetchData를 참조하여 마커 데이터 매핑
  const computedMarkers = (items) => {
    // 배열을 시간순으로 정렬 (오름차순)
    const sortedItems = items.sort((a, b) => {
      const dateA = parseInt(a.contract_date)
      const dateB = parseInt(b.contract_date)
      return dateA < dateB ? 1 : -1
    })
    let contents = []
    let marker_count = 0

    sortedItems.map((item, idx) => {
      let marker_type = 'customoverlay'
      let nearby_marker_exists = false
      let nearby_distance = 0.0007

      if (state.viewState.level === 2) {
        nearby_distance = 0.0004
      } else if (state.viewState.level === 1) {
        nearby_distance = 0.0002
      }

      // 마커와 점을 포함한 모든 데이터의 개수를 제한합니다
      if (idx > limit * 4) return

      // 마커 갯수를 초과하면 나머지는 점으로 표시합니다
      if (marker_count > limit) {
        marker_type = 'dotoverlay'
      }

      // 근거리에 다른 마커가 이미 있으면 점으로 표시합니다
      contents.map((item2, idx2) => {
        if (
          item2.marker_type === 'customoverlay' &&
          item2.lat >
            item.centerpoint.coordinates[0] - nearby_distance &&
          item2.lat <
            item.centerpoint.coordinates[0] + nearby_distance &&
          item2.lon >
            item.centerpoint.coordinates[1] - nearby_distance &&
          item2.lon < item.centerpoint.coordinates[1] + nearby_distance
        ) {
          nearby_marker_exists = true
        }
      })

      // 커스텀 오버레이가 표시될 위치입니다
      let overlayPosition = new kakao.maps.LatLng(
        item.centerpoint.coordinates[0],
        item.centerpoint.coordinates[1],
      )

      if (nearby_marker_exists) {
        marker_type = 'dotoverlay'
        // overlayPosition = new kakao.maps.LatLng(
        //   item.latitude,
        //   item.longitude,
        // );
      }

      contents.push({
        id: item.id,
        position: overlayPosition,
        marker_type: marker_type,
        lat: item.centerpoint.coordinates[0],
        lon: item.centerpoint.coordinates[1],
        case_number: item.case_number, // 사건번호
        article_number: item.article_number, // 물건번호
        purpose: item.purpose, // 용도
        sale_deadline: item.sale_deadline, // 매각기일
        price: item.price, // 감정평가액
        min_selling_price: item.min_selling_price, // 최저매각가격
        breakdown: item.breakdown, // 내역
      })
    })

    // 최신이 위로 가도록 reverse()
    return contents.reverse()
  }

  const markers = useMemo(() => computedMarkers(overlays), [overlays])

  if (hide) return null

  return (
    <>
      {markers.map((item, i) => (
        <MapAuctionMarkerItem item={item} key={i} />
      ))}
    </>
  )
}

export default MapAuctionMarkerContainer
