import useIsMobile from 'lib/hooks/useIsMobile'
import theme from 'lib/styles/theme'
import {
  setIsVisibleBottomNav,
  setIsVisibleHeaderChannel,
} from 'modules/notev2'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

const NoteLayerSwipeContainer = ({ children }) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [isScrollingGrid, setIsScrollingGrid] = useState(false)
  const match = useRouteMatch('/note/v2/:pageType')

  const filterWidth = theme.base.note.filterWidth
  const listLayerRef = useRef(null)
  const isOpenDetailRouter = useRef(null)
  const moveDistance = useRef(null)
  const viewScrollRef = useRef(null)
  let startXList = useRef(null)
  let startXFilter = useRef(null)

  const openFilter = () => {
    const elem = listLayerRef.current?.resizable
      ? listLayerRef.current.resizable
      : listLayerRef.current
    elem.style.left = `${filterWidth}px`
    elem.classList.remove('flip')
    elem.classList.remove('moving')
    elem.classList.remove('active')
  }

  const onBoardingStart = useSelector(
    (state) => state.onBoarding.onBoardingStart,
  )

  useEffect(() => {
    if (!isMobile) return
    if (onBoardingStart) {
      const elem = listLayerRef.current?.resizable
        ? listLayerRef.current.resizable
        : listLayerRef.current

      elem.style.willChange = 'auto'

      elem.style.left = '0'
      elem.style.zIndex = 50
    }
  }, [onBoardingStart])

  //히스토리 추가
  const addHistroy = useCallback(() => {
    if (match?.params?.pageType == 'dongho') {
      history.pushState(
        { type: 'filterOpen' },
        document.title,
        location.href,
      )
    } else {
      history.pushState(
        { type: 'filterOpen' },
        document.title,
        `/map/note/v2/${match?.params?.pageType || 'notes'}`,
      )
    }
    dispatch(setIsVisibleBottomNav(false))
    dispatch(setIsVisibleHeaderChannel(false))
  }, [])

  useEffect(() => {
    dispatch(setIsVisibleBottomNav(true))
    dispatch(setIsVisibleHeaderChannel(true))
  }, [])

  /**
   * 2뎁스가 닫힌 상태를 감지하여 닫혔을 때 redux update 호출
   */
  useLayoutEffect(() => {
    const target = listLayerRef.current?.resizable
      ? listLayerRef.current.resizable
      : listLayerRef.current

    target.addEventListener('transitionend', function () {
      if (this.style.left == '256px') {
        //routerHistory.go(-1)
        moveDistance.current = null
        dispatch(setIsVisibleBottomNav(true))
        dispatch(setIsVisibleHeaderChannel(true))
      }
    })
  }, [])

  useEffect(() => {
    if (!isMobile) return
    const goBack = (e) => {
      //history.go(1)
      if (isOpenDetailRouter.current) {
        isOpenDetailRouter.current = false
        return
      }
      openFilter()
    }

    window.addEventListener('popstate', goBack)
    return () => window.removeEventListener('popstate', goBack)
  }, [isMobile])

  const onSwipeStartFilter = useCallback(
    (e) => {
      if (!isMobile) return

      const elem = listLayerRef.current?.resizable
        ? listLayerRef.current.resizable
        : listLayerRef.current

      elem.classList.remove('animation')
      elem.style.willChange = 'left'

      startXFilter.current = e.changedTouches[0].clientX

      if (
        Array.from(e.target.classList).includes(
          'note-left-filter-pannel',
        )
      )
        return

      const elemLeft = window
        .getComputedStyle(elem, null)
        .getPropertyValue('left')
        .replace('px', '')

      if (elemLeft >= filterWidth - 5) return

      elem.classList.add('active', 'moving')
    },
    [isMobile, listLayerRef.current, startXFilter.current],
  )

  const onSwipeMoveFilter = useCallback(
    (e, ev) => {
      if (!isMobile) return

      const deltaX =
        (ev.changedTouches[0].clientX || 0) -
        (startXFilter.current || 0) //움직인 거리
      const movePos = deltaX > 256 ? 256 : deltaX

      //왼쪽으로 swipe
      if (movePos < -15) {
        const elem = listLayerRef.current?.resizable
          ? listLayerRef.current.resizable
          : listLayerRef.current

        const pos = filterWidth + Number(e.x)
        const applyPos = pos < 0 ? 0 : pos

        elem.style.left = `${applyPos}px`
        moveDistance.current = applyPos
        //setMoveDistance(applyPos)
        elem.classList.add('active', 'moving')
      }
    },
    [
      isMobile,
      listLayerRef.current,
      moveDistance.current,
      startXFilter.current,
    ],
  )

  const onSwipeEndFilter = useCallback(() => {
    if (!isMobile) return
    if (moveDistance.current == null) {
      return
    }

    const elem = listLayerRef.current?.resizable
      ? listLayerRef.current.resizable
      : listLayerRef.current
    elem.style.willChange = 'auto'
    const elemLeft = elem.style.left.replace('px', '')

    if (elemLeft == filterWidth) return

    elem.classList.add('animation')
    elem.classList.add('end-open')

    elem.classList.add('flip')
    elem.style.left = `0`
    elem.classList.remove('moving')
    //setMoveDistance(null)
    moveDistance.current = null

    //history
    addHistroy()
  }, [isMobile, listLayerRef.current, moveDistance.current])

  const onSwipeStartList = useCallback(
    (e, isGrid) => {
      if (!isMobile) return

      startXList.current = e.changedTouches[0].clientX

      const elem = listLayerRef.current?.resizable
        ? listLayerRef.current.resizable
        : listLayerRef.current
      elem.classList.remove('animation')

      elem.style.willChange = 'left'

      //      elem.classList.add('active', 'moving')
      let gridScrollLeft

      //그리드와 그리드 아닌것을 분리
      //스크롤 시작점을 가져와서 스크롤중인지 세팅
      if (isGrid) {
        if (
          !document.getElementsByClassName('ag-root') ||
          document.getElementsByClassName('ag-root').length == 0
        )
          return

        gridScrollLeft = document.getElementsByClassName(
          'ag-center-cols-viewport',
        )[0].scrollLeft
      } else {
        if (
          !document.getElementsByClassName('prevent-left-scroll') ||
          document.getElementsByClassName('prevent-left-scroll')
            .length == 0
        )
          return

        gridScrollLeft =
          document.getElementsByClassName(
            'ag-center-cols-viewport',
          )?.[0]?.scrollLeft ||
          e.target.closest('.prevent-left-scroll')?.scrollLeft
      }

      if (gridScrollLeft > 0) {
        setIsScrollingGrid(true)
      } else if (gridScrollLeft == 0) {
        setIsScrollingGrid(false)
      }
    },
    [isMobile],
  )

  const onSwipeMoveList = useCallback(
    (e, ev, isGrid = false, isPreventZero) => {
      //스크롤중인 그리드를 왼쪽 끝으로 계속 스크롤할때 필터 나오는것 방지
      if (!isMobile) return

      let scrollTarget
      if (isPreventZero) {
        scrollTarget = ev.target.closest('.prevent-left-scroll')
      } else {
        scrollTarget = document.getElementsByClassName(
          'prevent-left-scroll',
        )?.[0]
      }

      const elem = listLayerRef.current?.resizable
        ? listLayerRef.current.resizable
        : listLayerRef.current

      //세로 스크롤 움직임이 있으면 필터 열기 동작 무시 (10픽셀 이상 움직)
      if (e.y > 10 || e.y < -10) {
        if (
          !isPreventZero &&
          (!document.getElementsByClassName('ag-body-viewport') ||
            document.getElementsByClassName('ag-body-viewport')
              .length == 0)
        )
          return

        //레이어를 움직이고 있으면 y축 헤더 변경 하지않음
        if (
          elem.style.left.replace('px', '') > 0 &&
          elem.style.left.replace('px', '') < 256
        )
          return

        return
      }

      //헤더를 움직일때는 그리드 가로 스크롤이 0이 아니어도 동작
      if (
        !ev.target.closest('.note-list-swipe-header') &&
        isScrollingGrid &&
        !ev.target.closest('.ag-pinned-left-cols-container')
      )
        return

      const deltaX =
        (ev.changedTouches[0].clientX || 0) - (startXList.current || 0) //움직인 거리
      const movePos = deltaX > 256 ? 256 : deltaX

      const sensitivityX = isGrid ? movePos > 15 : movePos > 15 //그리드인 경우 swipe 감도 조건 둔하게

      if (sensitivityX) {
        //그리드를 swipe 하는 경우, scroll이 왼쪽 끝에 있을때만 동작함
        if (
          (isGrid || isPreventZero) &&
          scrollTarget &&
          scrollTarget.scrollLeft != 0
        ) {
          return
        }

        const pos = Number(e.x)
        const applyPos = pos > filterWidth ? filterWidth : pos

        elem.classList.remove('flip')
        elem.style.left = `${applyPos}px`

        moveDistance.current = applyPos
        //setMoveDistance(applyPos)
        elem.classList.add('active', 'moving')
      }
    },
    [
      isMobile,
      isScrollingGrid,
      moveDistance.current,
      listLayerRef.current,
      startXList.current,
    ],
  )

  const onSwipeEndList = useCallback(() => {
    if (!isMobile) return
    if (moveDistance.current == null) return
    const elem = listLayerRef.current?.resizable
      ? listLayerRef.current.resizable
      : listLayerRef.current

    const elemLeft = elem.style.left.replace('px', '')

    if (elemLeft == 0) return

    elem.classList.add('animation')
    elem.style.left = `${filterWidth}px`
    elem.classList.remove('end-open', 'flip', 'moving')
    elem.style.willChange = 'auto'
    elem.classList.remove('active')

    //  routerHistory.go(-1)

    // setTimeout(() => {
    //moveDistance.current = null
    //   routerHistory.go(-1)
    //   dispatch(setIsVisibleBottomNav(true))
    //   dispatch(setIsVisibleHeaderChannel(true))
    // }, 150)
  }, [isMobile, moveDistance.current])

  const closeFilter = (isAddHistory = true) => {
    //if (!isMobile) return
    const elem = listLayerRef.current?.resizable
      ? listLayerRef.current.resizable
      : listLayerRef.current

    isAddHistory && addHistroy()
    elem.classList.add('flip', 'active')
    elem.classList.remove('moving')
  }

  return children({
    onSwipeStartFilter,
    onSwipeMoveFilter,
    onSwipeEndFilter,
    onSwipeStartList,
    onSwipeMoveList,
    onSwipeEndList,
    listLayerRef,
    closeFilter,
    isOpenDetailRouter,
    viewScrollRef,
  })
}

export default React.memo(NoteLayerSwipeContainer)
