import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import ImgSrc from 'assets/images/deunggi.png'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/pagination/pagination.scss' // Pagination module
import NoteDashboardDeunggiBanner from './NoteDashboardDeunggiBanner'
import NoteDashboardDonghoBanner from './NoteDashboardDonghoBanner'
import { useState } from 'react'

/**
 * 매물노트 - 대시보드
 * 최상단 등기부 배너
 */
const NoteDashboardAd = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const onChangeSlide = (e) => {
    setCurrentPage(e.activeIndex + 1)
  }

  return (
    <StyledSwiper
      onSlideChange={onChangeSlide}
      pagination={true}
      modules={[Pagination]}
    >
      <SwiperSlide>
        <NoteDashboardDeunggiBanner />
      </SwiperSlide>
      <SwiperSlide>
        <NoteDashboardDonghoBanner />
      </SwiperSlide>
      {/* <Pages>{currentPage}/2</Pages> */}
    </StyledSwiper>
  )
}

const StyledSwiper = styled(Swiper)`
  position: relative;
  width: calc(50% - 15px);
  .swiper-pagination-bullets {
    z-index: 1;
    padding-left: 36px;
    bottom: 16px;
    text-align: left;
    .swiper-pagination-bullet {
      background-color: ${theme.colors.base.white} !important;
    }
  }

  ${media.mediumM`
    width: 100%;
    height: 190px;
  `}

  ${media.mediumS`
    height: 72px;
    
    .swiper-pagination-bullets {
      bottom: 5px;
      text-align: right;
      padding-right: 16px;
    }
  `}
`

const Pages = styled.div`
  position: absolute;
  bottom: 20px;
  left: 45px;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.base.white};
  z-index: 1;
`

export default NoteDashboardAd
