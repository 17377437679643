import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const NoteAdColumnVerificationContainer = ({ children }) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext()

  const adSrl = watch('ad_srl')

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const isDisabled =
    adFormControl?.adSrl == adSrl &&
    adFormControl?.disabledColumns.includes('verification')

  return children({ isDisabled })
}

export default NoteAdColumnVerificationContainer
