import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  recentAddresses: [],
  recentFavorites: [],
  previousPath: '',
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setRecentAddresses(state, action) {
      state.recentAddresses = action.payload
    },
    setRecentFavorites(state, action) {
      state.recentFavorites = action.payload
    },
    setPreviousPath(state, action) {
      state.previousPath = action.payload
    },
  },
})

export const {
  setRecentAddresses,
  setRecentFavorites,
  setPreviousPath,
} = homeSlice.actions

export default homeSlice.reducer
