import theme from 'lib/styles/theme'
import styled from 'styled-components'

const Skeleton = ({ expand }) => {
  return <Wrapper expand={expand}></Wrapper>
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${theme.colors.gray[50]};
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin-left: -1px;
  margin-top: -1px;
  border-top: 1px solid ${theme.colors.gray[900]}1A !important;
  border-bottom: 1px solid ${theme.colors.gray[900]}1A !important;
  border-left: 1px solid ${theme.colors.gray[900]}1A !important;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0, rgba(255, 255, 255, 0)),
      color-stop(20%, rgba(255, 255, 255, 0.2)),
      color-stop(60%, rgba(255, 255, 255, 0.75)),
      to(rgba(255, 255, 255, 0))
    );
    background-image: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.75) 60%,
      rgba(255, 255, 255, 0)
    );
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.75) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 1.3s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
`

export default Skeleton
