import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { options } from '../../options'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 용도(오피스텔)
 */
const NoteAdColumnUseage = ({ columnKey, required }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const usage = watch(columnKey)

  const list = options['부동산정의용도']

  const value = options['부동산정의용도'].includes(usage) ? usage : ''

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>용도</ColumnTitle>
      <ColumnVal gap={15}>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: value || '선택',
            value: value,
          }}
          options={getSimpleSelectboxOptions(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register(columnKey)}
          onChange={(e) => {
            onUpdate(columnKey, e.value)
          }}
        />
        {columnKey == 'article.usage' && (
          <ValidateWarn>{errors?.article?.usage?.message}</ValidateWarn>
        )}
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnUseage
