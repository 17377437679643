import { useDispatch, useSelector } from 'react-redux'
import theme from 'lib/styles/theme'
import { setRoadView } from 'modules/housingComplex'
import SummaryHcHeaderMeta from 'components/summary/header/SummaryHcHeaderMeta'
import {
  StyledHeader,
  StyledHeaderBody,
} from 'components/summary/header/SummaryHeader'
import { Empty } from 'components/design'
import { Skeleton, Tooltip } from 'antd'
import styled from 'styled-components'
import { RoadViewButton } from '../SummaryBuilding/SummaryBuildingRoadView'

const SummaryHcHeader = ({ loading, data }) => {
  const selectedName = useSelector(
    (state) => state.housingComplex.selectedName,
  )

  const dispatch = useDispatch()

  if (loading)
    return (
      <SkeletonWrap>
        <Skeleton active />
      </SkeletonWrap>
    )

  return data ? (
    <StyledHeader border>
      <StyledHeaderBody>
        <Header>
          <HeaderTitle>
            <Address>{data?.address_name}</Address>
            <Title>{selectedName}</Title>
          </HeaderTitle>
          <Tooltip title="로드뷰">
            <RoadViewButton
              onClick={() => dispatch(setRoadView({ isVisible: true }))}
            >
              <i className="fal fa-webcam"></i>
            </RoadViewButton>
          </Tooltip>
        </Header>
        <SummaryHcHeaderMeta
          building_cnt={data?.building_cnt}
          empty_land_cnt={data?.empty_land_cnt}
          area={data?.area}
        />
      </StyledHeaderBody>
    </StyledHeader>
  ) : (
    <Empty>데이터가 없습니다.</Empty>
  )
}

const SkeletonWrap = styled.div`
  background-color: ${theme.colors.base.white};
  padding: 1.25rem;
  height: 125px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderTitle = styled.div`
  flex: 1;
`

const Address = styled.div`
  color: ${theme.colors.gray[500]};
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 0.1rem;
`

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 1rem;
  width: 90%;
`

export default SummaryHcHeader
