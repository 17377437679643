import { message, Tooltip } from 'antd'
import axios from 'axios'
import { getAddress, getAddressName } from 'lib/utils'
import { useFormContext } from 'react-hook-form'

const NoteUploadExcelAddress = ({
  value,
  row,
  index,
  tableData,
  setTableData,
}) => {
  const { register, watch, setValue } = useFormContext()
  const addresssId = watch(`address-id-${index + 1}`)

  const getAddressId = (address) =>
    new Promise((resolve, reject) => {
      const geocoder = new kakao.maps.services.Geocoder()

      //주소 검색 임시 api 세팅
      axios
        .get(`/map/api/searchAddress?query=${address}`)
        .then(async (response) => {
          const status = response.status
          const result = response.data.documents

          // 정상적으로 검색이 완료됐으면
          if (status === 200 && result) {
            if (result.length > 0) {
              // 카카오 장소 검색
              // 정상적으로 검색이 완료됐으면
              const latlng = new kakao.maps.LatLng(
                result?.[0].y,
                result?.[0].x,
              )

              const { data } = await getAddress(
                latlng.getLat(),
                latlng.getLng(),
              )

              if (!data?.result) {
                message.error(
                  '아직 지원하지 않는 주소입니다. 관리자에게 문의해주세요.',
                )
                reject()
              }

              resolve(data?.result?.id)
            }
          } else if (status !== 200) {
            message.error('예기치 못한 문제가 발생했습니다.')
            reject()
          }
        })

      // geocoder.addressSearch(address, async (result, status) => {
      //   console.log('status: ', status, 'result: ', result)

      //   if (status !== kakao.maps.services.Status.OK) {
      //     message.error('예기치 못한 문제가 발생했습니다.')
      //     reject()
      //   }

      //   // 정상적으로 검색이 완료됐으면
      //   const latlng = new kakao.maps.LatLng(
      //     result?.[0].y,
      //     result?.[0].x,
      //   )

      //   const { data } = await getAddress(
      //     latlng.getLat(),
      //     latlng.getLng(),
      //   )

      //   if (!data?.result) {
      //     console.log(
      //       '아직 지원하지 않는 주소입니다. 관리자에게 문의해주세요.',
      //     )
      //     message.error(
      //       '아직 지원하지 않는 주소입니다. 관리자에게 문의해주세요.',
      //     )
      //     reject()
      //   }

      //   console.log('result: ', data?.result)

      //   resolve(data?.result?.id)
      // })
    })

  const onChangeInvalidAddress = ({ index, addressId }) => {
    new daum.Postcode({
      oncomplete: async function (data) {
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
        // 예제를 참고하여 다양한 활용법을 확인해 보세요.

        const changeAddress = data.jibunAddress || data.autoJibunAddress

        const result = await getAddressId(changeAddress)

        setValue(`address-${index + 1}`, changeAddress)
        register(`address_id-${index + 1}`, result)
        setValue(`address_id-${index + 1}`, result)

        setTableData((prev) => {
          const clonedTables = [...prev]

          clonedTables[index].address = changeAddress
          clonedTables[index].address_id = result

          return clonedTables
        })
      },
    }).open()
  }

  return (
    <>
      {!row?.address_id || row?.address_id === '' ? (
        <div
          className="invalid"
          onClick={() =>
            onChangeInvalidAddress({
              index,
              addressId: row?.address_id,
            })
          }
        >
          <Tooltip title="유효하지 않은 주소입니다.">{value}</Tooltip>
        </div>
      ) : (
        value
      )}

      <input
        type="hidden"
        defaultValue={value}
        name={`address-${index + 1}`}
        className={row?.address_id === '' ? 'invalid' : ''}
        //  {...register(`address-${index + 1}`, value)}
      />
    </>
  )
}

export default NoteUploadExcelAddress
