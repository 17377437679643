import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import HeaderBack from 'components/common/Header/HeaderBack'
import ExternalLayerContext from './context/ExternalLayerContext'
import HeaderMore from 'components/common/Header/HeaderMore'

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${theme.base.headerHeight}px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: ${theme.colors.base.white};
  border-bottom: 1px solid ${theme.colors.base.white};

  transition: 0.2s ease;

  ${({ inverse }) =>
    inverse &&
    `
    color: white;
    background-color: transparent;
    border-bottom-color: transparent;
  `}

  ${({ isScrolled }) =>
    isScrolled &&
    `
    border-bottom-color: ${theme.colors.gray[200]};
  `}
`

export const HeaderDivider = styled.div`
  flex: 1;
`

const Title = styled.div`
  color: ${theme.colors.gray[900]};
  font-size: 1.125rem;
  font-weight: 700;
  margin-right: 1rem;

  ${({ inverse }) =>
    inverse &&
    `
    color: white;
  `}
`

const CloseButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-left: 1rem;

  background-color: ${theme.colors.gray[100]};
  border-radius: 6px;

  transition: 0.2s ease;

  & > svg {
    color: ${theme.colors.gray[700]};
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: ${theme.colors.gray[700]};
    background-color: ${theme.colors.gray[200]};
  }
  &:active {
    color: ${theme.colors.gray[800]};
    background-color: ${theme.colors.gray[300]};
  }
`

const ExternalLayerHeader = ({
  title,
  children,
  onClose,
  onBack,
  hiddenClose,
  inverse,
  headerMenu,
  onClickMenu,
  append,
}) => {
  const history = useHistory()
  const {
    state: { isScrolled },
  } = useContext(ExternalLayerContext)

  const onClickClose = () => {
    if (onClose) {
      onClose()
      return
    }
    history.go(-1)
  }

  return (
    <StyledHeader
      className="external-layer-header"
      inverse={!isScrolled && inverse}
      isScrolled={isScrolled}
    >
      {onBack && (
        <HeaderBack onClose={onBack} inverse={!isScrolled && inverse} />
      )}

      {title && <Title inverse={!isScrolled && inverse}>{title}</Title>}

      {children}

      <HeaderDivider />

      {append}

      {headerMenu ? (
        <HeaderMore
          inverse={!isScrolled && inverse}
          onClickMenu={onClickMenu}
          menus={headerMenu}
        ></HeaderMore>
      ) : null}

      {!hiddenClose && (
        <CloseButton
          isScrolled={isScrolled}
          inverse={inverse}
          onClick={onClickClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </CloseButton>
      )}
    </StyledHeader>
  )
}

export default ExternalLayerHeader
