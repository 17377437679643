import { useContext } from 'react'
import MapContext from 'contexts/map'
import {
  billionFormate,
  numberWithCommas,
  getJootekServiceURL,
} from 'utils/index'

import { Card } from 'components/design'
import { CardHeader } from 'components/design'
import { CardBody } from 'components/design'

import { Button } from 'components/design'

import { CardTitle } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'
import SummaryProjectItem from '../SummaryProjects/SummaryProjectItem'
import styled from 'styled-components'

const SummaryHcProjects = ({ data }) => {
  const isMobile = useIsMobile()

  return (
    <Card>
      <CardHeader>
        <CardTitle>추천 프로젝트</CardTitle>
      </CardHeader>
      <CardBody>
        <StyledList>
          {data &&
            data.map((project, idx) => {
              return (
                <SummaryProjectItem
                  key={idx}
                  item={project}
                ></SummaryProjectItem>
              )
            })}
        </StyledList>

        <a
          href={getJootekServiceURL('/projects')}
          target={isMobile ? '_self' : '_blank'}
        >
          <Button size="xlarge" color="primary" plain expand>
            <span>JOOTEK 추천 건축모델</span>
          </Button>
        </a>
      </CardBody>
    </Card>
  )
}

const StyledList = styled.ul`
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  padding: 0 0 1.25rem;
  margin: -0.5rem;
`

export default SummaryHcProjects
