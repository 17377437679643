import theme from 'lib/styles/theme'
import styled from 'styled-components'

const HomeQuickMenuItem = ({
  icon,
  children,
  color,
  onClick,
  as,
  to,
  href,
  target,
  newBadge,
  preparing,
}) => {
  return (
    <Item
      preparing={preparing}
      as={as}
      to={to}
      href={href}
      target={target}
      onClick={onClick}
    >
      <Icon preparing={preparing} color={color}>
        {newBadge && <Badge>NEW</Badge>}
        {preparing && <Badge preparing="true">준비중</Badge>}
        {icon}
      </Icon>
      <span>{children}</span>
    </Item>
  )
}

const Item = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  background-color: transparent;
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  font-weight: 600;

  cursor: pointer;

  &:hover {
    color: inherit;
  }

  ${({ preparing }) =>
    preparing &&
    `
    pointer-events: none;
    user-select: none;
  `}
`

const Icon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-bottom: 6px;

  color: ${({ color }) => color || theme.colors.primary[400]};
  font-size: 1.75rem;

  border-radius: 100%;
  background-color: ${theme.colors.gray[100]};

  &:hover {
    background-color: ${theme.colors.gray[200]};
  }

  svg {
    fill: ${({ color }) => color && color};
    width: 1.75rem;
    height: 1.75rem;
    path {
      ${({ color }) => color && `fill: inherit;`}
    }
  }

  ${({ preparing }) =>
    preparing &&
    `
    color: ${theme.colors.gray[600]};
    svg {
      fill: ${theme.colors.gray[600]};
    }
  `}
`

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 1.125rem;
  transform: translateX(100%);

  color: white;
  font-size: 12px;
  font-weight: 600;

  padding: 2px 6px;
  border-radius: 2rem;
  background-color: ${theme.colors.blue[400]};

  ${({ preparing }) =>
    preparing &&
    `
    background-color: ${theme.colors.gray[600]};
  `}
`

export default HomeQuickMenuItem
