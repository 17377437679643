import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select } from 'components/design'

import { getChannels } from 'lib/api/note/channelApi'
import { useSelector } from 'react-redux'

const ButtonWrap = styled.div`
  /* margin-bottom: 1.75rem; */
`

const NoteApplyChannels = ({ channelSrl, onChange }) => {
  const [channels, setChannels] = useState([])
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getChannels({
        csrfToken: loginInfo?.csrf_token,
      })

      if (!data) {
        setChannels([])
        return
      }

      // console.log(data);

      setChannels(data?.result)
    }

    fetchData()
  }, [])

  const handleChange = useCallback((e) => {
    onChange(e.target.value)
  })

  return (
    <ButtonWrap>
      <Select expand value={channelSrl} onChange={handleChange}>
        <option value="">채널 선택</option>
        {channels?.map((item, i) => (
          <option key={i} value={item.channel_srl}>
            {item.name}
          </option>
        ))}
      </Select>
    </ButtonWrap>
  )
}

export default NoteApplyChannels
