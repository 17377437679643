import styled from 'styled-components'

const StyledButtonGroup = styled.div`
  display: flex;

  & > button,
  & > a {
    flex: 0 0 auto;
    position: relative;
    margin-left: -1px;
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &:hover {
      z-index: 1;
    }
    &:focus {
      z-index: 2;
    }
  }
`

const ButtonGroup = ({ children }) => {
  return <StyledButtonGroup>{children}</StyledButtonGroup>
}

export default ButtonGroup
