import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { completeAdListing } from 'lib/api/notev2/adSubmit'
import { useSelector } from 'react-redux'
import { Confirm } from 'components/design'

/**
 * 광고 목록 버튼
 * 정상매물
 * @param adSrl
 * @param callbackFn
 * @param setUpdatedAdSrl 재조회를 위해 저장하는 ad_srl
 * @param forForm 폼에서 사용하는지 여부
 * @param forNote 매물 3뎁스에서 사용하는지 여부
 * @param type 동일주소매물 or 신고매물
 */
const NormalButton = ({
  adSrl,
  callbackFn,
  setUpdatedAdSrl,
  forForm,
  forNote,
  type,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const [onRequestComplete, , dataComplete] = useAxios(
    completeAdListing,
    {
      resetError: true,
    },
  ) // 거래완료

  /**
   * 동일주소매물, 신고매물일 때 알림 내용
   */
  const getConfirmText = () => {
    const result = {
      동일주소매물: `매물번호 ${adSrl}의 네이버 광고가 그대로 노출 유지됩니다.`,
      신고매물: `매물번호 ${adSrl}가 정상 매물임을 확인하여 노출 재개를 요청합니다. 매물 검증 센터의 검증 결과 허위 매물로 판정될 경우, 패널티가 부과됩니다.`,
    }

    return result[type]
  }

  const onClickComplete = (adSrl) => {
    Confirm.info({
      title: type ? '이 매물을 정상매물 처리할까요?' : '알림',
      content: type
        ? getConfirmText()
        : '이 매물을 정상매물 처리 하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      okButtonProps: { color: 'blue' },
      onOk: () => {
        onRequestComplete({
          ad_srl: adSrl,
          plan_srl: planInfo.allinone_plan_srl,
          type: '정상매물',
        })
        setUpdatedAdSrl && setUpdatedAdSrl(adSrl)
      },
    })
  }

  useEffect(() => {
    if (!dataComplete) return
    if (dataComplete?.result?.errormsg) {
      message.error(
        dataComplete?.result?.errormsg || '요청이 실패하였습니다.',
      )
    } else if (
      dataComplete?.result?.errors &&
      dataComplete?.result?.errors.length > 0
    ) {
      message.error('광고 전송에 필요한 항목 검증에 실패하였습니다.')
      return
    }

    message.success('거래완료 처리되었습니다.')
    callbackFn && callbackFn()
  }, [dataComplete])

  return (
    <AdButton
      forForm={forForm}
      size={forForm ? 'xlarge' : 'small'}
      expand
      color={forForm ? 'blue' : 'default'}
      forNote={forNote}
      onClick={() => onClickComplete(adSrl)}
    >
      정상매물
    </AdButton>
  )
}

export default NormalButton
