import NoteAdColumnClientName from './NoteAdColumnClientName'
import NoteAdColumnDeunggiOwner from './NoteAdColumnDeunggiOwner'
import NoteAdColumnNaverId from './NoteAdColumnNaverId'
import NoteAdColumnOwnerTel from './NoteAdColumnOwnerTel'
import NoteAdColumnVerificationAgree from './NoteAdColumnVerificationAgree'
import NoteAdColumnVerificationArea from './NoteAdColumnVerificationArea'
import NoteAdColumnVerificationFile from './NoteAdColumnVerificationFile'
import NoteAdColumnVerificationOwner from './NoteAdColumnVerificationOwner'
import NoteAdColumnVerificationRef from './NoteAdColumnVerificationRef'
import NoteAdColumnVerificationRegi from './NoteAdColumnVerificationRegi'
import NoteAdColumnVerificationType from './NoteAdColumnVerificationType'
import { explainText } from './explainText'
import NoteAdColumnSellerName from './NoteAdColumnSellerName'
import NoteAdColumnSellerTel from './NoteAdColumnSellerTel'
import NoteAdColumnMobileCarrier from './NoteAdColumnMobileCarrier'
import NoteAdColumnSex from './NoteAdColumnSex'
import NoteAdColumnDeunggiFile from './NoteAdColumnDeunggiFile'
import NoteAdColumnSceneDate from './NoteAdColumnSceneDate'
import NoteAdColumnGongsil from './NoteAdColumnGongsil'
import NoteAdColumnPicture from './NoteAdColumnPicture'
import NoteAdColumnConfirmAndAgree from './NoteAdColumnConfirmAndAgree'
import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import { options } from '../../options'
import NoteAdColumnVerificationContainer from 'containers/notev2/NoteAdColumnVerificationContainer'
import NoteAdColumnPromotionFile from './NoteAdColumnPromotionFile'

const NoteAdColumnVerification = () => {
  const { watch } = useFormContext()

  const verificationTypeCode = watch('verification.type')
  const ownerType = watch('verification.ownerType')

  const verificationType = useMemo(
    () => options['검증방식'][verificationTypeCode],
    [verificationTypeCode],
  )

  return (
    <NoteAdColumnVerificationContainer>
      {({ isDisabled }) => {
        return (
          <>
            <NoteAdColumnVerificationType
              isDisabled={isDisabled}
              text={explainText[verificationType]}
            />
            {verificationType == '신홍보확인서' && (
              <>
                <NoteAdColumnVerificationOwner
                  isDisabled={isDisabled}
                  required
                />
                <NoteAdColumnSellerName
                  isDisabled={isDisabled}
                  text="한글 + 영어 혼용 불가합니다. 예) 김Daehee, Kim 대희"
                />
                <NoteAdColumnSellerTel isDisabled={isDisabled} />
                {/* 법인매물, 외국인매물 일 때 보여줌 */}
                {ownerType != 0 && (
                  <NoteAdColumnDeunggiOwner
                    isDisabled={isDisabled}
                    text="띄어쓰기 없이 작성해 주세요."
                  />
                )}
                <NoteAdColumnNaverId
                  isDisabled={isDisabled}
                  half={ownerType != 0}
                />
                <NoteAdColumnDeunggiFile isDisabled={isDisabled} />
                <NoteAdColumnVerificationRef isDisabled={isDisabled} />
                <NoteAdColumnVerificationRegi isDisabled={isDisabled} />
                <NoteAdColumnVerificationArea isDisabled={isDisabled} />
                <NoteAdColumnVerificationFile isDisabled={isDisabled} />
                <NoteAdColumnVerificationAgree
                  isDisabled={isDisabled}
                />
              </>
            )}
            {verificationType == '홍보확인서' && (
              <>
                <NoteAdColumnVerificationOwner
                  isDisabled={isDisabled}
                />
                <NoteAdColumnSellerName
                  isDisabled={isDisabled}
                  text="한글 + 영어 혼용 불가합니다. 예) 김Daehee, Kim 대희"
                />
                <NoteAdColumnSellerTel isDisabled={isDisabled} />
                <NoteAdColumnDeunggiOwner
                  isDisabled={isDisabled}
                  text="띄어쓰기 없이 작성해 주세요."
                />
                <NoteAdColumnOwnerTel
                  isDisabled={isDisabled}
                  required
                  text="입력시 소유주에게 매물 노출개시 내용이 고지됩니다."
                />
                <NoteAdColumnPromotionFile />
                <NoteAdColumnVerificationAgree
                  isDisabled={isDisabled}
                />
              </>
            )}
            {verificationType == '전화확인' && (
              <>
                <NoteAdColumnSellerName isDisabled={isDisabled} />
                <NoteAdColumnSellerTel isDisabled={isDisabled} />
                <NoteAdColumnNaverId isDisabled={isDisabled} />
                <NoteAdColumnDeunggiOwner
                  isDisabled={isDisabled}
                  half={false}
                  text="띄어쓰기 없이 작성해 주세요."
                />
                <NoteAdColumnVerificationAgree
                  isDisabled={isDisabled}
                />
              </>
            )}
            {verificationType == '모바일확인' && (
              <>
                <NoteAdColumnSellerName isDisabled={isDisabled} />
                <NoteAdColumnSellerTel isDisabled={isDisabled} />
                <NoteAdColumnDeunggiOwner
                  isDisabled={isDisabled}
                  text="띄어쓰기 없이 작성해 주세요."
                />
                <NoteAdColumnOwnerTel
                  isDisabled={isDisabled}
                  text="숫자만 적어주세요."
                />
                <NoteAdColumnNaverId isDisabled={isDisabled} />
                <NoteAdColumnDeunggiFile isDisabled={isDisabled} />
                <NoteAdColumnVerificationFile isDisabled={isDisabled} />
                <NoteAdColumnVerificationAgree
                  isDisabled={isDisabled}
                />
              </>
            )}
            {verificationType == '집주인확인(모바일확인 V2)' && (
              <>
                <NoteAdColumnSellerName isDisabled={isDisabled} />
                <NoteAdColumnSellerTel isDisabled={isDisabled} />
                <NoteAdColumnDeunggiOwner
                  isDisabled={isDisabled}
                  text="띄어쓰기 없이 작성해 주세요."
                />
                <NoteAdColumnOwnerTel
                  isDisabled={isDisabled}
                  text="숫자만 적어주세요."
                />
                <NoteAdColumnNaverId isDisabled={isDisabled} />
                <NoteAdColumnMobileCarrier isDisabled={isDisabled} />
                <NoteAdColumnSex isDisabled={isDisabled} />
                <NoteAdColumnDeunggiFile isDisabled={isDisabled} />
                <NoteAdColumnVerificationAgree
                  isDisabled={isDisabled}
                />
              </>
            )}
            {verificationType == '현장확인' && (
              <>
                <NoteAdColumnDeunggiOwner
                  half
                  isDisabled={isDisabled}
                  text="한글만 입력해 주세요."
                />
                <NoteAdColumnSceneDate half isDisabled={isDisabled} />
                <NoteAdColumnGongsil isDisabled={isDisabled} />
                <NoteAdColumnPicture isDisabled={isDisabled} />
                <NoteAdColumnConfirmAndAgree isDisabled={isDisabled} />
                <NoteAdColumnVerificationAgree
                  isDisabled={isDisabled}
                />
              </>
            )}
          </>
        )
      }}
    </NoteAdColumnVerificationContainer>
  )
}

export default NoteAdColumnVerification
