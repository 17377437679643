import { Button } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BackgroundImg from 'assets/images/note/landing/header.webp'
import axios from 'axios'
import MapContext from 'contexts/map'
import { useInput } from 'lib/hooks/useInput'
import SearchContext from 'contexts/searchContext'
import {
  funcMakePolygon,
  funcSetActionsClickposition,
  getAddress,
  getAddressName,
  setBuildingPolygons,
} from 'lib/utils'
import useIsMobile from 'lib/hooks/useIsMobile'
import LandingHeadBar from './LandingHeadBar'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import { notification } from 'antd'

const LandingHead = ({ onClickStart }, ref) => {
  const history = useHistory()
  const buttonRef = useRef(null)
  const wrapperRef = useRef(null)
  const [isFloat, setIsFloat] = useState(false)
  const [search, bindSearch] = useInput('')
  const [innerHeight, setInnerHeight] = useState(0)

  const { priceRef, parentRef } = ref

  const isMobile = useIsMobile()

  const addressRef = useRef(null)
  useOnClickOutside(addressRef, (e) => {
    setAddress([])
    setPlaces([])
  })

  const {
    state: { map },
    actions,
  } = useContext(MapContext)

  const {
    state: { address, places, message },
    actions: { setSearchVisible, setAddress, setPlaces },
  } = useContext(SearchContext)

  useEffect(() => {
    if (!isMobile) return
    if (typeof window !== 'undefined') {
      setInnerHeight(window.innerHeight)
    }
  }, [isMobile])

  /**
   * 지금 시작하기 버튼 float 제어
   */
  useEffect(() => {
    if (!wrapperRef?.current) return
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          //지금 바로 시작하기 버튼 화면에서 안보일 때
          setIsFloat(true)
        } else {
          setIsFloat(false)
        }
      })
    })

    observer.observe(wrapperRef.current)
  }, [wrapperRef])

  /**
   * 지금 시작하기 버튼 요금제 섹션에 가면 버튼 숨기기
   */
  useEffect(() => {
    if (!priceRef?.current) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsFloat(false)
          } else {
            if (parentRef?.current && parentRef?.current.scrollTop == 0)
              return
            setIsFloat(true)
          }
        })
      },
      {
        threshold: 0.5,
      },
    )

    observer.observe(priceRef.current)
  }, [priceRef])

  /**
   * 알림
   * @param {string} message
   */
  const openNotification = (message) => {
    notification['info']({
      message: '알림',
      description: message,
    })
  }

  const onKeyDown = (e) => {
    if (e.key == 'Enter') {
      onSearch()
    }
  }

  const resultClear = () => {
    setAddress([])
    setPlaces([])
  }

  /**
   * 검색
   */
  const onSearch = () => {
    // map이 로드 안되어 있으면 return
    if (Object.keys(map).length === 0) return

    resultClear()

    //주소 검색 임시 api 세팅
    axios
      .get(`/map/api/searchAddress?query=${search}`)
      .then((response) => {
        const status = response.status
        const result = response.data.documents
        // 정상적으로 검색이 완료됐으면
        if (status === 200 && result) {
          if (result.length > 0) {
            // 카카오 장소 검색
            searchPlaces(search, result)
          } else {
            // 우리 db에 등록된 주소가 없다면 카카오의 '키워드로 장소검색하고 목록으로 표출하기' 라이브러리를 사용합니다.
            searchPlaces(search)
          }
        } else if (status !== 200) {
          openNotification(
            '검색 결과 중 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
          )
        }
      })
  }

  const computedSearchResult = (address, places) => {
    const transpiledAddress = address.map((item) => {
      const result = {
        title: item.address_name,
        subTitle: item?.road_address?.address_name,
        x: item.x,
        y: item.y,
      }

      return result
    })

    const transpiledPlaces = places.map((item) => {
      const result = {
        title: item.place_name,
        subTitle: item.address_name,
        x: item.x,
        y: item.y,
      }

      return result
    })

    return [...transpiledAddress, ...transpiledPlaces]
  }
  const searchResults = useMemo(
    () => computedSearchResult(address, places),
    [address, places],
  )

  // @METHOD: 키워드 검색
  const searchPlaces = (keyword, result = null) => {
    let places = new kakao.maps.services.Places()

    if (!keyword.replace(/^\s+|\s+$/g, '')) {
      alert('키워드를 입력해주세요!')
      return false
    }
    //주소 검색 임시 api 세팅
    axios
      .get(`/map/api/searchKeyword?query=${keyword}`)
      .then((response) => {
        const status = response.status
        const data = response.data.documents

        if (status === 200 && data && data.length > 0) {
          if (result) setAddress(result)
          else setPlaces(data)
        } else if (
          status === 200 &&
          data &&
          data.length == 0 &&
          result
        ) {
          //ZERO_RESULT
          // DB 주소 검색
          const latlng = new kakao.maps.LatLng(
            result[0]?.y,
            result[0]?.x,
          )
          // setClickPosition
          getAddress(latlng.getLat(), latlng.getLng()).then(
            (response) => {
              if (response?.data.result && response.data.result.shape) {
                // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                map.setCenter(latlng)

                // zoom level 확대합니다.
                map.setLevel(2)

                const result = response.data.result
                const address = getAddressName(result)

                // Context API에 셋팅
                funcSetActionsClickposition(result, address, actions)

                // 토지 폴리곤 세팅
                const landPolygons = funcMakePolygon(result, kakao)
                actions.setPolygons(landPolygons)

                // 건물 폴리곤 배열 셋팅
                setBuildingPolygons(result, kakao, actions)

                // 주소 검색창에 full 주소 세팅
                // setSearchInput(address);

                setSearchVisible(false)

                // history 설정
                history.push(`/${result.id}`)
              }
            },
          )
          return
        } else if (status !== 200) {
          openNotification(
            '검색 결과 중 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
          )
          return
        }
      })
  }

  // 장소 클릭 시 해당 토지로 이동
  const onClickItem = useCallback(({ latitude, longitude }) => {
    goToSummary(latitude, longitude)
    resultClear()
  })

  const goToSummary = async (lat, lng) => {
    const res = await getAddress(lat, lng)

    if (res.data.result && res.data.result.shape) {
      // map이 로드 안되어 있으면 return
      if (Object.keys(map).length === 0) return
      // zoom level 확대합니다.
      map.setLevel(2)
      history.push(`/${res.data.result.id}`)
    } else {
      openNotification('결과가 없습니다.')
    }
  }

  /**
   * 주택 지도로 이동하기
   */
  const onClickMoveButton = () => {
    history.push('/home')
  }

  return (
    <>
      <LandingHeadBar />
      <Wrapper ref={wrapperRef} height={innerHeight}>
        <img src={BackgroundImg} />
        <Dimmer />
        <TitleWrapper>
          <Title>
            <span>스마트한 중개업의 시작,</span>
            <span>매물노트</span>
          </Title>
          <SubTitle>
            지도와 매물이 한눈에
            <br />
            매물노트에서 확인하세요.
          </SubTitle>
          <InputWrapper>
            <Input
              {...bindSearch}
              onKeyDown={onKeyDown}
              placeholder="주소∙장소명으로 검색해보세요"
            />
            <SearchButton
              circle
              round
              color="primary"
              onClick={onSearch}
            >
              <i className="far fa-search"></i>
            </SearchButton>
            {searchResults && searchResults.length > 0 && (
              <AddressList ref={addressRef}>
                {searchResults.map((item, i) => {
                  return (
                    <AddressListItem
                      key={i}
                      onClick={() =>
                        onClickItem({
                          latitude: item.y,
                          longitude: item.x,
                        })
                      }
                    >
                      <AddressesTitle>{item.title}</AddressesTitle>
                      {item.subTitle && (
                        <AddressesSubTitle>
                          {item.subTitle}
                        </AddressesSubTitle>
                      )}
                    </AddressListItem>
                  )
                })}
              </AddressList>
            )}
          </InputWrapper>
        </TitleWrapper>

        <StyledButton
          float={isFloat}
          ref={buttonRef}
          size={isMobile ? 'large' : 'xlarge'}
          onClick={onClickStart}
        >
          <i className="fas fa-hand-point-up"></i> 지금 시작하기
        </StyledButton>
        <FooterButton onClick={onClickMoveButton}>
          주택 지도로 이동하기
          <i className="fas fa-arrow-right"></i>
        </FooterButton>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 10000;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.mediumS`
    margin-bottom: 120px;
    ${({ height }) =>
      height &&
      `
      height: ${height}px;
    `}
  `}
`

const StyledButton = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 144px;
  background: linear-gradient(92.74deg, #36cc68 0%, #2e4172 100%);
  box-shadow: 0px 12px 24px rgba(63, 152, 239, 0.2);
  border-radius: 48px;
  color: ${theme.colors.base.white};
  width: 200px;
  border: none;
  z-index: 1000;

  i {
    margin-right: 10px;
    font-size: 20px;
    color: ${theme.colors.base.white};
  }

  ${({ float }) =>
    float &&
    `
      position: fixed;
      bottom: 70px;
  `}

  ${media.mediumS`
    width: 150px;
    bottom: 130px;

    
    ${({ float }) =>
      float &&
      `
        position: fixed;
        bottom: 40px;
    `}
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 26%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  color: ${theme.colors.base.white};
  text-align: center;
  z-index: 10000;

  ${media.mediumM`
    top: 25%;
  `}

  ${media.mediumS`
    top: 17%;
  `}
`

const Title = styled.h1`
  display: flex;
  font-size: 60px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 36px;
  color: ${theme.colors.base.white};

  & > span:last-child {
    margin-left: 10px;
  }

  ${media.mediumM`
    font-size: 46px;
    flex-direction: column;
  `}

  ${media.mediumS`
    font-size: 30px;
    line-height: 140%;
    margin-bottom: 30px;

    & > span:last-child {
      margin-left: 0;
    }
  `}
`

const SubTitle = styled.p`
  margin-bottom: 48px;
  font-size: 22px;
  font-weight: 600;
  line-height: 160%;

  ${media.mediumM`
    font-weight: 500;
    font-size: 20px;
  `}

  ${media.mediumS`
    margin-bottom: 34px;
    font-size: 18px;
  `}
`

const InputWrapper = styled.div`
  display: flex;
  width: 508px;
  height: 56px;
  padding: 3px 4px 4px 20px;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  border-radius: 50px;
  border: 1px solid #e5e8eb;

  ${media.mediumS`
    max-width: 340px;
    height: 48px;
  `}
`

const Input = styled.input`
  font-size: 18px;
  width: 100%;
  height: 100%;
  background: none;
  border: 0;
  padding: 0;
  outline: none;

  &::placeholder {
    color: ${theme.colors.gray[400]};
    font-size: 14px;
  }
`

const SearchButton = styled(Button)`
  width: 42px;

  ${media.mediumS`
    width: 40px;
    height: 36px;
  `}
`

const AddressList = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  width: inherit;
  height: auto;
  max-height: 240px;
  text-align: left;
  border-radius: 8px;
  background-color: ${theme.colors.base.white};
  z-index: 1;
  overflow: auto;
  box-shadow: ${theme.shadow['card-1']};
  ${media.mediumM`
    max-width: 100%;
  `};
`

const AddressListItem = styled.div`
  position: relative;
  padding: 8px 12px 8px 42px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.gray[100]};
  }

  &::before {
    content: '\f002';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    color: ${theme.colors.gray[500]};
    font-family: 'Font Awesome 5 Pro';
  }
`

const AddressesTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.gray[800]};
`
const AddressesSubTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
`

const Dimmer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(25, 31, 40, 0.25);
`

const FooterButton = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  border-top: 1px solid rgb(223, 229, 235);
  width: 100%;
  height: 72px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: ${theme.colors.base.white};
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  i {
    margin-left: 8px;
  }
`

export default forwardRef(LandingHead)
