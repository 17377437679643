import {
  getDeunggibuList,
  requestLandUsePlan,
} from 'lib/api/notev2/contractApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import NoteDocsLayerLandUseLayout from './NoteDocsLayerLandUseLayout'
import { Button, Empty } from 'components/design'
import { useFormContext } from 'react-hook-form'
import { Alert, Spin } from 'antd'
import { useInterval } from 'lib/hooks/useInterval'
import theme from 'lib/styles/theme'
import SelectBox from 'components/jmapnotev2/elements/SelectBox'
import moment from 'moment'
import {
  SelectBottomHr,
  SelectLabel,
  SelectWrapper,
} from 'components/jmapnotev2/style/NoteLayerStyle'

const NoteDocsLayerLandUse = () => {
  const [onRequest, , data] = useAxios(getDeunggibuList)
  const [onRequestLandUsePlan, , dataLandUsePlan] =
    useAxios(requestLandUsePlan)

  const match = useRouteMatch(
    '/note/v2/docs/:channelSrl/:noteSrl/edit/:contractSrl?',
  )
  const [selectedData, setSelectedData] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectList, setSelectList] = useState([])

  const { setValue, getValues, watch } = useFormContext()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!match || !match.params) return
    const channel_srl = match.params.channelSrl
    const note_srl = match.params.noteSrl

    onRequest({
      channel_srl,
      note_srl,
      doc_types: ['토지이용계획원'],
      status: ['complete'],
    })
  }, [])

  useEffect(() => {
    if (!data || !data?.result[0]) return

    if (data.result && data.result.length > 0) {
      const options = data.result
        .filter((item) => item?.extracted_data)
        .map((item) => {
          return {
            label: (
              <SelectLabel>
                <div>{item.extracted_data?.['소재지']}</div>
                <span>
                  발급일{' '}
                  {moment(item.extracted_data?.date).format(
                    'YYYY.MM.DD HH:mm',
                  )}
                </span>
              </SelectLabel>
            ),
            value: item.doc_srl,
          }
        })

      setSelectList(options)

      const hasExtractedData = data.result.filter(
        ({ extracted_data }) => extracted_data,
      )
      setSelectedData(hasExtractedData[0] || null)

      if (
        hasExtractedData[0]?.status === 'complete' ||
        hasExtractedData[0]?.status === 'error'
      ) {
        setLoading(false)
      }
    }
  }, [data])

  useEffect(() => {
    if (!data) return

    setSelectedData(data.result.find((item, i) => i == selectedIndex))
  }, [selectedIndex])

  // 토지이용계획원 발급 신청
  const onClickLandUsePlan = () => {
    if (!match || !match.params) return
    const channel_srl = match.params.channelSrl
    const note_srl = match.params.noteSrl

    onRequestLandUsePlan({
      channel_srl,
      note_srl,
      sub_type: '토지',
      address: getValues('address_jibun'),
      address_id: getValues('address_id'),
    })
  }

  // 3뎁스에서 발급 요청 후 발급 중이라는 메시지 띄움
  useEffect(() => {
    if (!dataLandUsePlan?.doc_srl) {
      return
    }
    // 발급 중이라는 메시지 띄움
    setLoading(true)
  }, [dataLandUsePlan])

  /**
   * 5초마다 한 번씩 목록 조회
   * - 발급 완료된 토지이용계획원이 있으면 요청 안함
   */
  useInterval(() => {
    // 발급 완료된 토지이용계획원이 있으면 요청 안함
    if (
      selectedData &&
      (selectedData.status === 'complete' ||
        selectedData.status === 'error')
    )
      return

    if (!match || !match.params) return
    const channel_srl = match.params.channelSrl
    const note_srl = match.params.noteSrl

    if (!channel_srl || !note_srl) return

    // 목록 조회
    onRequest({
      channel_srl,
      note_srl,
      doc_types: ['토지이용계획원'],
      status: ['complete'],
    })
  }, 5000)

  const container = (
    <Alert
      message="토지이용계획원"
      description="토지이용계획원을 발급 중입니다. 잠시만 기다려 주세요."
      type="info"
    />
  )

  return (
    <Wrapper>
      {!selectedData && (
        <ButtonWrapper>
          <Button color="blue" onClick={onClickLandUsePlan}>
            토지이용계획원 발급
          </Button>
        </ButtonWrapper>
      )}
      {selectedData ? (
        <NoteDocsLayerLandUseLayout
          data={selectedData}
          useTo="contract"
          append={
            <>
              <SelectWrapper>
                <SelectBox
                  size="large"
                  noborder
                  value={selectList[selectedIndex]}
                  minWidth={150}
                  options={selectList}
                  onChange={(e) => {
                    const index = selectList.findIndex(
                      ({ value }) => value == e.value,
                    )
                    setSelectedIndex(index)
                  }}
                />
              </SelectWrapper>
              <SelectBottomHr />
            </>
          }
        />
      ) : (
        <Empty>발급받은 토지이용계획원 데이터가 없습니다.</Empty>
      )}

      {loading && (
        <div>
          <Spin spinning={loading} delay={1000}>
            {container}
          </Spin>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`

export default NoteDocsLayerLandUse
