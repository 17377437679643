import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import HeaderButton from './HeaderButton'
import useRemoveFavorite from './hooks/useRemoveFavorite'
import useSaveFavorite from './hooks/useSaveFavorite'
import { setLoginVisible } from 'modules/auth'
import HeaderIcon from 'components/common/Header/HeaderIcon'
import { Star } from 'components/jmapnotev2/dongho/NoteDonghoStyle'
import useAxios from 'lib/hooks/useAxios'
import {
  deleteDonghoDanji,
  insertDonghoDanji,
} from 'lib/api/notev2/dongho'
import { setSummaryInfo } from 'modules/summary'
import { Confirm } from 'components/design'
import { getAddressName } from 'lib/utils'
import useRoadName from './hooks/useRoadName'

const HeaderButtonFavorite = () => {
  const dispatch = useDispatch()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )
  const { _saveFavorite } = useSaveFavorite()
  const { _removeFavorite } = useRemoveFavorite()

  const [onRequestAddDanji, , dataAddDanji] = useAxios(
    insertDonghoDanji,
    { resetError: false },
  )
  const [onRequestDelDanji, , dataDelDanji] = useAxios(
    deleteDonghoDanji,
    { resetError: false },
  )
  const formatedRoadName = useRoadName(summaryInfo?.land)

  const customPurpose =
    summaryInfo?.building?.result?.[0]?.custom_purpose
  const hasFavorite =
    loginInfo?.member_type === 'realestate' && customPurpose == '아파트'
      ? summaryInfo?.jdongho_danjis?.length > 0
      : summaryInfo?.favorite

  const onClick = useCallback(() => {
    // 로그인 상태가 아닐 때 로그인창 출력

    if (!loginInfo?.member_srl) {
      dispatch(setLoginVisible(true))
      return
    }

    if (loginInfo?.member_type === 'realestate') {
      if (customPurpose == '아파트') {
        //아파트일때만 동호단지 즐겨찾기로 저장
        if (hasFavorite) {
          const danjiLength = summaryInfo?.jdongho_danjis.length
          if (danjiLength > 1) {
            Confirm.warn({
              title: '해당 주소에는 여러개의 단지가 저장되어있어요',
              content: `즐겨찾기를 삭제하시면 동호관리에 저장되어있는 ${danjiLength}개의 단지가 모두 삭제돼요. 계속하시겠어요?`,
              onOk: () => {
                deleteRealestateDanji()
              },
              okButtonProps: { color: 'danger' },
            })
          } else {
            const danji_id = summaryInfo?.jdongho_danjis?.[0]?.id
            if (!danji_id) {
              message.error('잘못된 요청입니다.')
              return
            }
            onRequestDelDanji({
              danji_id,
              address_id: currentAddressId,
            })
          }
        } else {
          const params = makeInsertParams()
          onRequestAddDanji(params)
        }
      } else {
        //아파트가 아닐때는 파라미터를 추가하여 저장
        if (hasFavorite) {
          //삭제
          onRequestDelDanji({ address_id: currentAddressId })
        } else {
          //추가
          const params = makeInsertParams()

          onRequestAddDanji(params)
        }
      }
    } else {
      if (hasFavorite) {
        _removeFavorite()
      } else {
        _saveFavorite()
      }
    }
  }, [loginInfo, summaryInfo, currentAddressId])

  /**
   * 중개사 즐찾 추가시 사용할 파라미터
   */
  const makeInsertParams = () => {
    const params = {
      address_id: currentAddressId,
    }

    // 지번주소 세팅
    params.address_jibun = getAddressName(summaryInfo.land)
    // 도로명주소 세팅
    params.address_doro = formatedRoadName
    // 거래 유형 세팅
    let property_type = ''

    if (summaryInfo.building?.result?.[0]) {
      property_type = building?.result?.[0]?.custom_purpose || ''
    }
    params.property_type = property_type

    return params
  }

  const deleteRealestateDanji = () => {
    summaryInfo?.jdongho_danjis.map(({ id }) => {
      onRequestDelDanji({ danji_id: id })
    })
  }

  /**
   * 중개사 - 즐찾 삭제 후
   */
  useEffect(() => {
    if (!dataDelDanji) return
    if (dataDelDanji?.error == 0) {
      if (customPurpose == '아파트') {
        const deleteTarget = dataDelDanji.result
        dispatch(
          setSummaryInfo({
            ...summaryInfo,
            jdongho_danjis: summaryInfo.jdongho_danjis.filter(
              ({ id }) => {
                return id != deleteTarget
              },
            ),
          }),
        )
      } else {
        dispatch(
          setSummaryInfo({
            ...summaryInfo,
            favorite: null,
          }),
        )
      }
    }
  }, [dataDelDanji])

  /**
   * 중개사 - 즐찾 추가 후
   */
  useEffect(() => {
    if (!dataAddDanji) return
    if (dataAddDanji?.error == 0) {
      if (customPurpose == '아파트') {
        dispatch(
          setSummaryInfo({
            ...summaryInfo,
            jdongho_danjis: dataAddDanji.result,
          }),
        )
      } else {
        dispatch(
          setSummaryInfo({
            ...summaryInfo,
            favorite: {
              favorite_id: dataAddDanji.favorite_id,
            },
          }),
        )
      }
    }
  }, [dataAddDanji])

  return (
    <HeaderIcon gray onClick={onClick}>
      {hasFavorite ? (
        <Star className="fas fa-star" />
      ) : (
        <i className="far fa-star" />
      )}
    </HeaderIcon>
  )
}

export default HeaderButtonFavorite
