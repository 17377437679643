import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import EditButton from 'components/jmapnotev2/elements/EditButton'
import SelectBox from 'components/jmapnotev2/elements/SelectBox'
import {
  Row,
  RowSpanInner,
  Table,
  TableContent,
  TableHeader,
  TableSubHeader,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import theme from 'lib/styles/theme'
import {
  getContactHeaderName,
  getSimpleSelectboxOptions,
} from 'lib/utils/notev2/util'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import Select from 'react-select'
import { ScrollStyle } from 'components/jmapnotev2/style/NoteStyle'
import { setBuyerTempRrn, setSellerTempRrn } from 'modules/notev2'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const NoteDocsContractContact = ({ onUpdate }) => {
  const { watch, setValue } = useFormContext()
  const docsTempRrn = useSelector((state) => state.notev2.docsTempRrn)
  const dispatch = useDispatch()

  const seller = watch('seller')
  const buyer = watch('buyer')
  const realestate = watch('realestate')
  const contract_type = watch('contract_type')

  const [sellers, setSellers] = useState([])
  const [buyers, setBuyers] = useState([])
  const [realestates, setRealestates] = useState([])

  const contactInitValue = {
    addr: '',
    rrn: '',
    number: '',
    number_type: '주민등록번호',
    name: '',
    agent_addr: '',
    agent_rrn: '',
    agent_number: '',
    agent_number_type: '주민등록번호',
    agent_name: '',
  }

  const contactInitValue2 = {
    addr: '',
    rrn: '',
    number: '',
    name: '',
    agent_addr: '',
    agent_rrn: '',
    agent_number: '',
    agent_name: '',
  }

  const realestateInitValue = {
    addr: '',
    id: '',
    company_name: '',
    number: '',
    name: '',
    sosog_name: '',
  }

  useEffect(() => {
    if (!seller || seller.length == 0) {
      setSellers([contactInitValue])
    } else {
      setSellers(seller)
    }

    if (!buyer || buyer.length == 0) {
      setBuyers([contactInitValue2])
    } else {
      setBuyers(buyer)
    }
  }, [])

  useEffect(() => {
    if (!realestate || realestate.length <= 1) {
      setRealestates([
        realestate?.[0] || realestateInitValue,
        realestateInitValue,
      ])
    } else {
      setRealestates(realestate)
    }
  }, [realestate])

  useEffect(() => {
    if (seller && seller.length > 0) {
      setSellers(seller)
    }
  }, [seller])

  /**
   * input 전화번호 형식으로 변경
   * @param {*} e
   * @param {*} index
   * @param {*} type
   * @param {*} isAgent
   */
  const handleInputChange = (e, index, type, isAgent = false) => {
    const { name, value } = e.target
    let list

    if (type == 'sellers') {
      list = [...sellers]
    } else if (type == 'buyers') {
      list = [...buyers]
    } else if (type == 'realestates') {
      list = [...realestates]
    }

    const replaceValue = value
      .replace(/[^0-9]/g, '')
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        '$1-$2-$3',
      )
      .replace('--', '-')

    const data = isAgent
      ? {
          agent_number: replaceValue,
        }
      : { number: replaceValue }

    list[index] = {
      ...list[index],
      ...data,
    }

    if (type == 'sellers') {
      setSellers(list)
    } else if (type == 'buyers') {
      setBuyers(list)
    } else if (type == 'realestates') {
      setRealestates(list)
    }
  }

  const onBlurSellerBuyer = useCallback(
    ({ type, key, value, index, syncKey }) => {
      const newData = type == 'seller' ? [...sellers] : [...buyers]

      newData[index][key] = value
      onUpdate(type, newData)

      if (index == 0 && syncKey) {
        syncUpdateDescriptionInfo(syncKey, value)
      }
    },
    [buyer, buyers, seller, sellers],
  )

  const onBlurRealestates = useCallback(
    ({ key, value, index, syncKey }) => {
      const newData = [...realestates]

      newData[index][key] = value
      onUpdate('realestate', newData)

      if (index == 0 && syncKey) {
        //개업공인
        syncUpdateDescriptionInfo(syncKey, value)
      }
    },
    [realestate, realestates],
  )

  const addSellers = useCallback(() => {
    setSellers([...sellers, ...[contactInitValue]])
  }, [sellers])

  const addBuyers = useCallback(() => {
    setBuyers([...buyers, ...[contactInitValue2]])
  }, [buyers])

  const addRealestates = useCallback(() => {
    setRealestates([...realestates, ...[realestateInitValue]])
  }, [realestates])

  const deleteSellers = useCallback(
    (i) => {
      const result = sellers.filter((item, idx) => idx != i)
      setSellers(result)
      onUpdate('seller', result)
    },
    [sellers],
  )

  const deleteBuyers = useCallback(
    (i) => {
      const result = buyers.filter((item, idx) => idx != i)
      setBuyers(result)
      onUpdate('buyer', result)
    },
    [buyers],
  )

  const deleteRealestates = useCallback(
    (i) => {
      const result = realestates.filter((item, idx) => idx != i)
      setRealestates(result)
      onUpdate('realestate', result)
    },
    [realestates],
  )

  /**
   * 매도인, 매수인, 중개사를 저장하면 확인설명서에도 저장
   * @param {*} param0
   */
  const syncUpdateDescriptionInfo = (key, value) => {
    onUpdate(key, value)
  }

  const sliceNumber = (number_type, val) => {
    if (number_type == '주민등록번호') return val.slice(0, 6)
    else return val
  }

  const onBlurRrn2 = ({ type, value, index }) => {
    if (type == 'seller') {
      dispatch(
        setSellerTempRrn([
          ...docsTempRrn.seller.filter((item) => item.index != index),
          ...[{ index: index, value: value }],
        ]),
      )
    } else if (type == 'buyer') {
      dispatch(
        setBuyerTempRrn([
          ...docsTempRrn.buyer.filter((item) => item.index != index),
          ...[{ index: index, value: value }],
        ]),
      )
    }
  }

  return (
    <Wrapper>
      <Description>
        * 주민등록번호 뒷자리는 서버에 저장되지 않으므로 인쇄 전에
        입력해 주세요.
      </Description>
      {/* 매도인 - 대리인 */}
      {sellers.map(
        (
          {
            addr,
            rrn,
            rrn2,
            number,
            number_type,
            name,
            agent_addr,
            agent_rrn,
            agent_rrn2,
            agent_number,
            agent_number_type,
            agent_name,
          },
          i,
        ) => {
          return (
            <StyledTable key={'seller' + i}>
              <Row rowspan={2}>
                <TableHeader>
                  {getContactHeaderName(contract_type, '매도인')}
                </TableHeader>
                <RowSpanInner rowspan={2}>
                  <TableSubHeader width={143}>주소</TableSubHeader>
                  <TableContent noborderright>
                    <ColorInput
                      fullWidth
                      type="text"
                      defaultValue={addr || ''}
                      key={addr + 'seller' + 'addr' + i}
                      onBlur={(e) => {
                        onBlurSellerBuyer({
                          type: 'seller',
                          key: 'addr',
                          value: e.target.value,
                          index: i,
                          syncKey: 'd_maedoin_address',
                        })
                      }}
                    />
                  </TableContent>
                </RowSpanInner>
                <RowSpanInner rowspan={2} noborderbottom>
                  <TableSubHeader width={143}>
                    <NumberTypeSelect
                      type="seller"
                      column="number_type"
                      index={i}
                      value={number_type}
                      onChange={onBlurSellerBuyer}
                    />
                  </TableSubHeader>
                  <TableContent
                    className={`maedoin-rrn-${i}`}
                    width={230}
                  >
                    <ColorInput
                      type="text"
                      defaultValue={sliceNumber(number_type, rrn) || ''}
                      key={
                        sliceNumber(number_type, rrn) +
                        'seller' +
                        'rrn1' +
                        i
                      }
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'seller',
                          key: 'rrn',
                          value: e.target.value,
                          index: i,
                          syncKey: 'd_maedoin_birth',
                        })
                      }
                    />
                    {(!number_type ||
                      number_type == '주민등록번호') && <>&#45;</>}
                    <ColorInput
                      type="text"
                      defaultValue={
                        rrn2 ||
                        docsTempRrn.seller?.find(
                          (item) => item.index == i,
                        )?.value ||
                        ''
                      }
                      key={rrn2 + 'seller' + 'rrn2' + i}
                      visibleDisplay={
                        number_type && number_type != '주민등록번호'
                          ? 'none'
                          : ''
                      }
                      //주민번호 뒷자리 임시 저장해서 확인설명서에 보이도록 함
                      onBlur={(e) => {
                        onBlurRrn2({
                          type: 'seller',
                          value: e.target.value,
                          index: i,
                        })
                      }}
                    />
                  </TableContent>
                  <TableSubHeader>전화</TableSubHeader>
                  <TableContent width={230}>
                    <ColorInput
                      type="text"
                      value={number}
                      key={'seller' + 'number' + i}
                      onChange={(e) =>
                        handleInputChange(e, i, 'sellers')
                      }
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'seller',
                          key: 'number',
                          value: e.target.value,
                          index: i,
                          syncKey: 'd_maedoin_number',
                        })
                      }
                    />
                  </TableContent>
                  <TableSubHeader>성명</TableSubHeader>
                  <TableContent width={230} noborderright>
                    <ColorInput
                      type="text"
                      key={name + 'seller' + 'name' + i}
                      defaultValue={name || ''}
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'seller',
                          key: 'name',
                          value: e.target.value,
                          index: i,
                          syncKey: 'd_maedoin_name',
                        })
                      }
                      fullWidth
                    />
                  </TableContent>
                </RowSpanInner>
              </Row>
              <Row rowspan={2} noborderbottom>
                <TableHeader>대리인</TableHeader>
                <RowSpanInner rowspan={2}>
                  <TableSubHeader width={143}>주소</TableSubHeader>
                  <TableContent noborderright>
                    <ColorInput
                      fullWidth
                      type="text"
                      key={agent_addr + 'seller' + 'agent_addr' + i}
                      defaultValue={agent_addr || ''}
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'seller',
                          key: 'agent_addr',
                          value: e.target.value,
                          index: i,
                        })
                      }
                    />
                  </TableContent>
                </RowSpanInner>
                <RowSpanInner noborderbottom rowspan={2}>
                  <TableSubHeader width={143}>
                    <NumberTypeSelect
                      type="seller"
                      column="agent_number_type"
                      index={i}
                      value={agent_number_type}
                      onChange={onBlurSellerBuyer}
                    />
                  </TableSubHeader>
                  <TableContent
                    className={`maedoin-agent-rrn-${i}`}
                    width={230}
                  >
                    <ColorInput
                      type="text"
                      key={
                        sliceNumber(agent_number_type, agent_rrn) +
                        'seller' +
                        'agent_rrn1' +
                        i
                      }
                      defaultValue={
                        sliceNumber(agent_number_type, agent_rrn) || ''
                      }
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'seller',
                          key: 'agent_rrn',
                          value: e.target.value,
                          index: i,
                        })
                      }
                    />
                    {(!agent_number_type ||
                      agent_number_type == '주민등록번호') && (
                      <>&#45;</>
                    )}
                    <ColorInput
                      type="text"
                      key={
                        agent_rrn.slice(6) + 'seller' + 'agent_rrn2' + i
                      }
                      defaultValue={agent_rrn2 || ''}
                      visibleDisplay={
                        agent_number_type &&
                        agent_number_type != '주민등록번호'
                          ? 'none'
                          : ''
                      }
                    />
                  </TableContent>
                  <TableSubHeader>전화</TableSubHeader>
                  <TableContent width={230}>
                    <ColorInput
                      type="text"
                      key={number + 'sellers' + 'agent_number' + i}
                      value={agent_number}
                      onChange={(e) =>
                        handleInputChange(e, i, 'sellers', true)
                      }
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'seller',
                          key: 'agent_number',
                          value: e.target.value,
                          index: i,
                        })
                      }
                    />
                  </TableContent>
                  <TableSubHeader>성명</TableSubHeader>
                  <TableContent width={230} noborderright>
                    <ColorInput
                      type="text"
                      key={agent_name + 'seller' + 'agent_name' + i}
                      defaultValue={agent_name || ''}
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'seller',
                          key: 'agent_name',
                          value: e.target.value,
                          index: i,
                        })
                      }
                      fullWidth
                    />
                  </TableContent>
                </RowSpanInner>
              </Row>
              {sellers.length - 1 == i && (
                <StyledEditButton
                  onClick={addSellers}
                  type="add"
                  size="small"
                />
              )}
              {sellers.length > 1 && (
                <StyledEditButton
                  onClick={() => deleteSellers(i)}
                  type="minus"
                  size="small"
                  alone={sellers.length - 1 != i}
                />
              )}
            </StyledTable>
          )
        },
      )}
      {/* 매수인 - 대리인 */}
      {buyers.map(
        (
          {
            addr,
            rrn,
            rrn2,
            number,
            number_type,
            name,
            agent_addr,
            agent_rrn,
            agent_rrn2,
            agent_number,
            agent_number_type,
            agent_name,
          },
          i,
        ) => {
          return (
            <StyledTable key={'buyer' + i}>
              <Row rowspan={2}>
                <TableHeader>
                  {getContactHeaderName(contract_type, '매수인')}
                </TableHeader>
                <RowSpanInner rowspan={2}>
                  <TableSubHeader width={143}>주소</TableSubHeader>
                  <TableContent noborderright>
                    <ColorInput
                      fullWidth
                      type="text"
                      key={addr + 'buyer' + 'addr' + i}
                      defaultValue={addr || ''}
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'buyer',
                          key: 'addr',
                          value: e.target.value,
                          index: i,
                          syncKey: 'd_maesuin_address',
                        })
                      }
                    />
                  </TableContent>
                </RowSpanInner>
                <RowSpanInner rowspan={2} noborderbottom>
                  <TableSubHeader width={143}>
                    <NumberTypeSelect
                      type="buyer"
                      column="number_type"
                      index={i}
                      value={number_type}
                      onChange={onBlurSellerBuyer}
                    />
                  </TableSubHeader>
                  <TableContent
                    className={`maesuin-rrn-${i}`}
                    width={230}
                  >
                    <ColorInput
                      type="text"
                      key={
                        sliceNumber(number_type, rrn) +
                        'buyer' +
                        'rrn1' +
                        i
                      }
                      defaultValue={sliceNumber(number_type, rrn) || ''}
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'buyer',
                          key: 'rrn',
                          value: e.target.value,
                          index: i,
                          syncKey: 'd_maesuin_birth',
                        })
                      }
                    />
                    {(!number_type ||
                      number_type == '주민등록번호') && <>&#45;</>}
                    <ColorInput
                      type="text"
                      key={rrn.slice(6) + 'buyer' + 'rrn2' + i}
                      defaultValue={
                        rrn2 ||
                        docsTempRrn.buyer?.find(
                          (item) => item.index == i,
                        )?.value ||
                        ''
                      }
                      visibleDisplay={
                        number_type && number_type != '주민등록번호'
                          ? 'none'
                          : ''
                      }
                      //주민번호 뒷자리 임시 저장해서 확인설명서에 보이도록 함
                      onBlur={(e) => {
                        onBlurRrn2({
                          type: 'buyer',
                          value: e.target.value,
                          index: i,
                        })
                      }}
                    />
                  </TableContent>
                  <TableSubHeader>전화</TableSubHeader>
                  <TableContent width={230}>
                    <ColorInput
                      type="text"
                      value={number}
                      key={'buyer' + 'number' + i}
                      onChange={(e) =>
                        handleInputChange(e, i, 'buyers')
                      }
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'buyer',
                          key: 'number',
                          value: e.target.value,
                          index: i,
                          syncKey: 'd_maesuin_number',
                        })
                      }
                    />
                  </TableContent>
                  <TableSubHeader>성명</TableSubHeader>
                  <TableContent width={230} noborderright>
                    <ColorInput
                      type="text"
                      key={name + 'buyer' + 'name' + i}
                      defaultValue={name || ''}
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'buyer',
                          key: 'name',
                          value: e.target.value,
                          index: i,
                          syncKey: 'd_maesuin_name',
                        })
                      }
                      fullWidth
                    />
                  </TableContent>
                </RowSpanInner>
              </Row>
              <Row rowspan={2} noborderbottom>
                <TableHeader>대리인</TableHeader>
                <RowSpanInner rowspan={2}>
                  <TableSubHeader width={143}>주소</TableSubHeader>
                  <TableContent noborderright>
                    <ColorInput
                      fullWidth
                      type="text"
                      key={agent_addr + 'buyer' + 'agent_addr' + i}
                      defaultValue={agent_addr || ''}
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'buyer',
                          key: 'agent_addr',
                          value: e.target.value,
                          index: i,
                        })
                      }
                    />
                  </TableContent>
                </RowSpanInner>
                <RowSpanInner noborderbottom rowspan={2}>
                  <TableSubHeader width={143}>
                    <NumberTypeSelect
                      type="buyer"
                      column="agent_number_type"
                      index={i}
                      value={agent_number_type}
                      onChange={onBlurSellerBuyer}
                    />
                  </TableSubHeader>
                  <TableContent
                    className={`maesuin-agent-rrn-${i}`}
                    width={230}
                  >
                    <ColorInput
                      type="text"
                      key={
                        sliceNumber(agent_number_type, agent_rrn) +
                        'buyer' +
                        'agent_rrn1' +
                        i
                      }
                      defaultValue={
                        sliceNumber(agent_number_type, agent_rrn) || ''
                      }
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'buyer',
                          key: 'agent_rrn',
                          value: e.target.value,
                          index: i,
                        })
                      }
                    />
                    {(!agent_number_type ||
                      agent_number_type == '주민등록번호') && (
                      <>&#45;</>
                    )}
                    <ColorInput
                      type="text"
                      key={
                        agent_rrn.slice(6) + 'buyer' + 'agent_rrn2' + i
                      }
                      defaultValue={agent_rrn2 || ''}
                      visibleDisplay={
                        agent_number_type &&
                        agent_number_type != '주민등록번호'
                          ? 'none'
                          : ''
                      }
                    />
                  </TableContent>
                  <TableSubHeader>전화</TableSubHeader>
                  <TableContent width={230}>
                    <ColorInput
                      type="text"
                      value={agent_number}
                      key={number + 'buyer' + 'agent_number' + i}
                      onChange={(e) =>
                        handleInputChange(e, i, 'buyers', true)
                      }
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'buyer',
                          key: 'agent_number',
                          value: e.target.value,
                          index: i,
                        })
                      }
                    />
                  </TableContent>
                  <TableSubHeader>성명</TableSubHeader>
                  <TableContent width={230} noborderright>
                    <ColorInput
                      type="text"
                      key={agent_name + 'buyer' + 'agent_name' + i}
                      defaultValue={agent_name || ''}
                      onBlur={(e) =>
                        onBlurSellerBuyer({
                          type: 'buyer',
                          key: 'agent_name',
                          value: e.target.value,
                          index: i,
                        })
                      }
                      fullWidth
                    />
                  </TableContent>
                </RowSpanInner>
              </Row>
              {buyers.length - 1 == i && (
                <StyledEditButton
                  onClick={addBuyers}
                  type="add"
                  size="small"
                />
              )}
              {buyers.length > 1 && (
                <StyledEditButton
                  onClick={() => deleteBuyers(i)}
                  type="minus"
                  size="small"
                  alone={buyers.length - 1 != i}
                />
              )}
            </StyledTable>
          )
        },
      )}
      {/* 개업공인중개사 */}
      {realestates &&
        realestates.length > 0 &&
        realestates.map(
          ({ addr, id, company_name, number, name, sosog_name }, i) => {
            return (
              <StyledTable key={'realestates' + i}>
                <Row rowspan={3} noborderbottom>
                  <TableHeader>
                    {i === 0 ? (
                      '개업'
                    ) : (
                      <>
                        개업(공동)
                        <br />
                      </>
                    )}
                    공인중개사
                  </TableHeader>
                  <RowSpanInner rowspan={3}>
                    <TableSubHeader width={143}>
                      사무소소재지
                    </TableSubHeader>
                    <TableContent noborderright>
                      <ColorInput
                        fullWidth
                        type="text"
                        key={addr + 'realestates' + 'addr' + i}
                        defaultValue={addr || ''}
                        onBlur={(e) =>
                          onBlurRealestates({
                            key: 'addr',
                            value: e.target.value,
                            index: i,
                            syncKey: 'd_junggae_address1',
                          })
                        }
                      />
                    </TableContent>
                  </RowSpanInner>
                  <RowSpanInner rowspan={3}>
                    <TableSubHeader width={143}>
                      사무소명칭
                    </TableSubHeader>
                    <TableContent>
                      <ColorInput
                        type="text"
                        fullWidth
                        key={company_name + 'realestates' + 'cn' + i}
                        defaultValue={company_name || ''}
                        onBlur={(e) =>
                          onBlurRealestates({
                            key: 'company_name',
                            value: e.target.value,
                            index: i,
                            syncKey: 'd_junggae_company_name1',
                          })
                        }
                      />
                    </TableContent>
                    <TableSubHeader width={143}>
                      대표자성명
                    </TableSubHeader>
                    <TableContent width={410} spacebetween>
                      <ColorInput
                        type="text"
                        key={name + 'realestates' + 'name' + i}
                        defaultValue={name || ''}
                        width={120}
                        onBlur={(e) =>
                          onBlurRealestates({
                            key: 'name',
                            value: e.target.value,
                            index: i,
                            syncKey: 'd_junggae_name1',
                          })
                        }
                      />
                      <Text>자필서명란</Text>
                    </TableContent>
                  </RowSpanInner>
                  <RowSpanInner rowspan={3} noborderbottom>
                    <TableSubHeader width={95}>전화번호</TableSubHeader>
                    <TableContent width={275}>
                      <ColorInput
                        type="text"
                        key={'realestates' + 'number' + i}
                        value={number}
                        onChange={(e) =>
                          handleInputChange(e, i, 'realestates')
                        }
                        onBlur={(e) =>
                          onBlurRealestates({
                            key: 'number',
                            value: e.target.value,
                            index: i,
                            syncKey: 'd_junggae_number1',
                          })
                        }
                      />
                    </TableContent>

                    <TableSubHeader width={95}>등록번호</TableSubHeader>
                    <TableContent width={275}>
                      <ColorInput
                        type="text"
                        key={id + 'realestates' + 'id' + i}
                        defaultValue={id || ''}
                        onBlur={(e) =>
                          onBlurRealestates({
                            key: 'id',
                            value: e.target.value,
                            index: i,
                            syncKey: 'd_junggae_deunglognum1',
                          })
                        }
                      />
                    </TableContent>
                    <TableSubHeader width={143}>
                      소속공인중개사
                    </TableSubHeader>
                    <TableContent width={320} spacebetween>
                      <ColorInput
                        type="text"
                        width={120}
                        key={
                          sosog_name + 'realestates' + 'sosog_name' + i
                        }
                        defaultValue={sosog_name || ''}
                        onBlur={(e) =>
                          onBlurRealestates({
                            key: 'sosog_name',
                            value: e.target.value,
                            index: i,
                            syncKey: 'd_junggae_sosog1',
                          })
                        }
                      />
                      <Text>자필서명란</Text>
                    </TableContent>
                  </RowSpanInner>
                </Row>
                {realestates.length - 1 == i && (
                  <StyledEditButton
                    onClick={addRealestates}
                    type="add"
                    size="small"
                  />
                )}
                {realestates.length > 2 && i != 0 && (
                  <StyledEditButton
                    onClick={() => deleteRealestates(i)}
                    type="minus"
                    size="small"
                    alone={realestates.length - 1 != i}
                  />
                )}
              </StyledTable>
            )
          },
        )}
    </Wrapper>
  )
}

const NumberTypeSelect = ({ column, value, type, index, onChange }) => {
  const options = [
    '주민등록번호',
    '법인등록번호',
    '외국인등록번호',
    '기타단체등록번호',
    '재외국민주민번호',
    '사업자등록번호',
    '종중등록번호',
    '종교단체등록번호',
    '여권번호',
    '고유등록번호',
  ]

  return (
    <StyledSelect
      value={{
        label: value || '주민등록번호',
        value: value || '주민등록번호',
      }}
      options={getSimpleSelectboxOptions(options)}
      placeholder="선택"
      className="basic-single"
      classNamePrefix="select"
      components={{
        IndicatorSeparator: () => null,
      }}
      onChange={(e) => {
        onChange({
          type: type,
          key: column,
          value: e.value,
          index: index,
        })
      }}
    />
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const StyledEditButton = styled(EditButton)`
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
  right: ${({ type }) => (type == 'add' ? `-32px;` : `-54px`)};
  ${({ alone }) => alone && `right: -32px`};
`

const StyledTable = styled(Table)`
  margin-bottom: 6px;
`

const Description = styled.span`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: ${theme.colors.blue[700]};
  margin-bottom: 4px;
`

const StyledSelect = styled(Select)`
  font-size: 15px;

  .select__control {
    height: 100%;
    min-height: 100%;
    border: none;
    background-color: transparent;
  }
  .select__value-container {
    height: 100%;
  }
  .select__indicator {
    position: relative;
    width: 14px;
    height: 14px;
    background-color: ${theme.colors.gray[200]};
    border-radius: 3px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 14px;
      height: 14px;
      fill: ${theme.colors.gray[700]};
    }
  }

  .select__input-container {
    height: 100%;
    padding: 0;
  }

  .select__menu {
    width: max-content;
  }

  .select__menu-list {
    max-height: 200px;
    ${ScrollStyle}
  }

  .select__placeholder,
  .select__value-container {
    // border-color: ${theme.colors.gray[200]};
  }

  .select__option--is-focused {
    background-color: ${theme.colors.blue[100]};
  }
  .select__option--is-selected {
    background-color: ${theme.colors.blue[400]};
  }
`

const Text = styled.div`
  flex-shrink: 0;
`

export default NoteDocsContractContact
