import { createContext, useState } from 'react'

const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
  const [searchVisible, setSearchVisible] = useState(false)

  const [address, setAddress] = useState([])
  const [places, setPlaces] = useState([])

  const [message, setMessage] = useState('')

  const value = {
    state: {
      searchVisible,
      address,
      places,
      message,
    },
    actions: {
      setSearchVisible,
      setAddress,
      setPlaces,
      setMessage,
    },
  }

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  )
}

export default SearchContext
