import MapControlPosition from './MapControlPosition'
import MapControlZoom from './MapControlZoom'
import styled from 'styled-components'
import { media } from 'lib/styles/theme'

const MapControl = () => {
  return (
    <>
      <BottomWrapper>
        <MapControlPosition />
        <MapControlZoom />
      </BottomWrapper>
    </>
  )
}

const BottomWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  right: 10px;
  z-index: 13;

  @media screen and (max-height: 580px) {
    top: initial;
    bottom: 25px;
  }
`

export default MapControl
