import { useRef } from 'react'
import ReactDOM from 'react-dom/client'
import ConfirmDialog from './ConfirmDialog'

const ConfirmOrigin = (props) => {
  const div = document.createElement('div')
  document.body.appendChild(div)

  ReactDOM.createRoot(div).render(<ConfirmDialog {...props} />)
}

// Modal의 Props와 동일
export default function Confirm(props) {
  return ConfirmOrigin(props)
}

Confirm.info = (props) => {
  return ConfirmOrigin({
    ...props,
  })
}

Confirm.success = (props) => {
  return ConfirmOrigin({
    // okButtonProps: { color: 'danger' },
    ...props,
  })
}

Confirm.warn = (props) => {
  return ConfirmOrigin({
    okButtonProps: { color: 'warn' },
    ...props,
  })
}

Confirm.error = (props) => {
  return ConfirmOrigin({
    okButtonProps: { color: 'danger' },
    ...props,
  })
}
