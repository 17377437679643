import TopHeaderNoty from 'components/base/TopHeader/TopHeaderNoty'
import TopHeaderProfile from 'components/base/TopHeader/TopHeaderProfile'
import TopHeaderSearch from 'components/base/TopHeader/TopHeaderSearch'
import Header from 'components/common/Header/Header'
import HeaderBack from 'components/common/Header/HeaderBack'
import HeaderCenterTitle from 'components/common/Header/HeaderCenterTitle'
import ToggleAreaFormat from 'components/jmapnote/noteForm/ToggleAreaFormat'
import BaseContext from 'contexts/baseContext'
import MapContext from 'contexts/map'
import useIsMobile from 'lib/hooks/useIsMobile'
import useQueryString from 'lib/hooks/useQueryString'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import HeaderButtonFavorite from './HeaderButtonFavorite'
import HeaderButtonShare from './HeaderButtonShare'
import useIsTablet from 'lib/hooks/useIsTablet'
import useRoadName from './hooks/useRoadName'
import { getDisplayAddress } from 'utils'
import styled from 'styled-components'

const SummaryTopHeader = () => {
  const {
    state: { clickPosition, isSquareMeter },
    actions,
  } = useContext(MapContext)

  const {
    state: { isScrolled },
  } = useContext(BaseContext)

  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const history = useHistory()
  const { back } = useQueryString()

  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const location = useLocation()

  const onClose = () => {
    if (
      location.state?.from &&
      location.state?.from === 'housingComplex'
    ) {
      history.go(-1)
    }

    if (!isMobile && Object.keys(clickPosition).length > 0) {
      // 클릭한 폴리곤이 있을 때
      actions.setClickPosition({})
      actions.setPolygons([])
      actions.setBuildingPolygons([])
    }

    // back 쿼리가 있을 경우 close의 동작 방식이 뒤로가기로 바뀜.
    if (back) {
      history.go(-1)
      return
    }

    if (isMobile) {
      history.go(-1)
    } else {
      history.push('/home')
    }
  }

  const roadName = useRoadName(summaryInfo?.land) //도로명

  return (
    <Header
      fixed
      append={
        <>
          {!isMobile && <HeaderButtonFavorite />}
          {!isMobile && <HeaderButtonShare />}

          {isMobile && (
            <>
              <ToggleAreaFormat
                isSquareMeter={isSquareMeter}
                setIsSquareMeter={actions.setIsSquareMeter}
                isMobile={true}
              />
              {!isScrolled && (
                <IconWrapper>
                  <TopHeaderSearch />
                  <TopHeaderNoty />
                  <TopHeaderProfile />
                </IconWrapper>
              )}
            </>
          )}
        </>
      }
    >
      <HeaderBack onClose={onClose} />
      <HeaderCenterTitle hideBeforeScroll>
        {summaryInfo && summaryInfo.land
          ? getDisplayAddress(
              summaryInfo.land,
              summaryInfo?.building,
              roadName,
            )
          : ''}
      </HeaderCenterTitle>
    </Header>
  )
}
const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  & > li:last-child {
    & > div,
    & > li {
      padding-right: 0;
      padding-left: 10px;
    }
  }
`
export default SummaryTopHeader
