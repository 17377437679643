import styled from 'styled-components'
import {
  MainWrapper,
  MaxWidthWrapper,
} from 'components/jmapnotev2/style/NoteStyle'
import theme, { media } from 'lib/styles/theme'
import Scrollbars from 'react-custom-scrollbars-2'
import NoteNoticeList from 'components/jmapnotev2/NoteNotice/NoteNoticeList'

const NoteNoticePage = () => {
  return (
    <StyledMainWrapper noside gray="Y">
      <Scrollbars
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            style={{ display: 'none' }}
            className="track-horizontal"
          />
        )}
      >
        <ScrollbarWrapper gray>
          <StyledMaxWidthWrapper>
            <Wrapper>
              <Title>공지사항</Title>
              <NoteNoticeList />
            </Wrapper>
          </StyledMaxWidthWrapper>
        </ScrollbarWrapper>
      </Scrollbars>
    </StyledMainWrapper>
  )
}

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  background-color: ${theme.colors.base.white};
  padding: 40px 50px;

  ${media.smallPc`
    width: 100%;
    max-width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `}
`

const StyledMainWrapper = styled(MainWrapper)`
  ${media.mediumM`
    width: 100%;
    left: 0;
    justify-content: center;
    padding-bottom: 20px;
  `};
`

const ScrollbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${media.smallPc`
    padding: 0 40px;
  `}

  ${media.mediumS`
    padding: 0 20px;
  `}
`

const Title = styled.div`
  font-size: 28px;
  line-height: 150%;
  font-weight: 600;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

export default NoteNoticePage
