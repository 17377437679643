import BaseHeaderNav from 'components/base/BaseHeader/BaseHeaderNav'
import BaseHeaderNoty from 'components/base/BaseHeader/BaseHeaderNoty'
import Header from 'components/common/Header/Header'
import HeaderDivider from 'components/common/Header/HeaderDivider'

const ContactHeader = () => {
  return (
    <Header noTopHeader fixed border>
      고객관리
      <HeaderDivider />
      <BaseHeaderNoty />
      <BaseHeaderNav />
    </Header>
  )
}

export default ContactHeader
