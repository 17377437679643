import { message } from 'antd'
import { deletePlanUser } from 'lib/api/notev2/account'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { getPlanUserRefundInvoice } from 'lib/api/notev2/paymentApi'

/**
 * 멤버관리
 * 멤버 삭제하기
 */
const NoteAccountAllInOneMemberDeleteContainer = ({
  children,
  search,
}) => {
  const initialDeleteInfo = {
    member_srl: null,
    plan_srl: null,
  }

  const [onRequestDeleteInvoice, , dataDeleteInvoice] = useAxios(
    getPlanUserRefundInvoice,
  )
  const [onRequestDelete, , dataDelete, errorDelete] =
    useAxios(deletePlanUser)
  const [deleteInfo, setDeleteInfo] = useState(initialDeleteInfo)

  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState(false)
  const [invoice, setInvoice] = useState(null)
  const [totalPrice, setTotalPrice] = useState(null)
  const [card, setCard] = useState(null)
  const [loading, setLodaing] = useState(false)

  /**
   * 멤버 삭제 버튼 클릭
   */
  const onOpenDeleteModal = ({ plan_srl, member_srl }) => {
    setDeleteInfo({ plan_srl, member_srl })

    //환불 영수증 정보 불러오기
    onRequestDeleteInvoice({
      plan_srl,
      delete_member_srl: member_srl,
    })
    //setIsDeleteModalVisible(true)
  }

  useEffect(() => {
    if (!dataDeleteInvoice) return

    setIsDeleteModalVisible(true)
    setTotalPrice(dataDeleteInvoice?.total_price)
    setInvoice(dataDeleteInvoice?.invoice)
    setCard(dataDeleteInvoice?.card?.card)
  }, [dataDeleteInvoice])

  /**
   * 멤버 삭제 모달 닫기
   */
  const onCancelDeleteModal = () => {
    setDeleteInfo(initialDeleteInfo)
    setIsDeleteModalVisible(false)
  }

  /**
   * 멤버 삭제 모달에서 확인 클릭
   */
  const onDelete = () => {
    setLodaing(true)
    const param = Object.assign({}, deleteInfo, {
      amount: totalPrice,
    })
    onRequestDelete(param)
  }

  /**
   * 멤버 삭제 완료
   */
  useEffect(() => {
    if (!dataDelete || dataDelete?.error != 0) {
      setLodaing(false)
      return
    }

    message.success('멤버가 삭제되었습니다.')
    setLodaing(false)
    setIsDeleteModalVisible(false)
    search() //목록 재검색
  }, [dataDelete])

  useEffect(() => {
    if (!errorDelete) return
    setLodaing(false)
  }, [errorDelete])

  return children({
    loading,
    card,
    isDeleteModalVisible,
    invoice,
    totalPrice,
    onOpenDeleteModal,
    onCancelDeleteModal,
    onDelete,
  })
}

export default NoteAccountAllInOneMemberDeleteContainer
