import styled from 'styled-components'
import { removeCalendar, saveCalendar } from 'lib/api/note/calendarApi'
import useAxios from 'lib/hooks/useAxios'
import { useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import { message } from 'antd'
import ScheduleTab from 'components/jmapnote/noteForm/Tabs/ScheduleTab'

const NoteDetailCalendar = ({ callGetNote }) => {
  const [onRequest, , data] = useAxios(saveCalendar)
  const [onRequestRemove, , dataRemove] = useAxios(removeCalendar)

  const { watch } = useFormContext()

  const channel_srl = watch('channel_srl')
  const folder_srl = watch('folder_srl')
  const address_srl = watch('address_srl')
  const note_srl = watch('note_srl')
  const schedules = watch('calendars')

  /**
   * 일정 저장
   * @param {*} data
   */
  const onSubmit = (data) => {
    onRequest({
      channelSrl: channel_srl,
      folderSrl: folder_srl,
      addressSrl: address_srl,
      noteSrl: note_srl,
      type: data.type,
      date: data.date,
      content: data.content,
      contact_srls: data.contact_srls,
    })
  }

  useEffect(() => {
    if (!data || !data?.result) return

    callGetNote()
    message.success('일정이 저장되었습니다.')
  }, [data])

  /**
   * 일정 삭제
   * @param {*} calendarSrl
   */
  const onRemove = (calendarSrl) => {
    onRequestRemove({
      channelSrl: channel_srl,
      calendarSrl,
    })
  }

  useEffect(() => {
    if (!dataRemove || !dataRemove?.result) return

    callGetNote()
    message.success('일정이 삭제되었습니다.')
  }, [dataRemove])

  return (
    <Wrapper>
      <Title>일정</Title>
      <ScheduleTab
        onRemove={onRemove}
        schedules={schedules}
        onSubmit={onSubmit}
        onRefresh={callGetNote}
        sheet
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Title = styled.div`
  font-weight: 500;
  line-height: 100%;
`

export default NoteDetailCalendar
