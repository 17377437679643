import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import NoteGridNewLineRenderer from 'components/jmapnotev2/NoteList/NoteGridNewLineRenderer'
import moment from 'moment'
import CustomDirectionCell from 'components/jmapnotev2/customCell/List/CustomDirectionCell'
import CustomAreaCellCommon from 'components/jmapnotev2/customCell/List/CustomAreaCellCommon'
import CustomAreaCellContract from 'components/jmapnotev2/customCell/List/CustomAreaCellContract'
import CustomPropertyTypeCell from 'components/jmapnotev2/NoteAd/list/customCell/CustomPropertyTypeCell'
import CustomContractTypeCell from 'components/jmapnotev2/NoteAd/list/customCell/CustomContractTypeCell'
import CustomPriceCell from 'components/jmapnotev2/NoteAd/list/customCell/CustomPriceCell'
import {
  suppressArrow,
  suppressEnter,
  suppressTextCursorControl,
} from 'lib/utils/notev2/suppressKey'
import { columnCentered } from 'components/jmapnotev2/NoteList/NoteGridOptions'
import Skeleton from 'components/common/Loading/Skeleton'
import {
  cellPrependLabelColorClass,
  getGridFontSize,
  resetGridDataAddresses,
  rowClassRules,
} from 'lib/utils/notev2/gridUtil'
import CustomAddressCell from 'components/jmapnotev2/NoteAd/list/customCell/CustomAddressCell'
import CustomAdButtonsCell from 'components/jmapnotev2/NoteAd/list/customCell/CustomAdButtonsCell'
import CustomNoteSrlCell from 'components/jmapnotev2/NoteAd/list/customCell/CustomNoteSrlCell'
import { options } from 'components/jmapnotev2/NoteAd/options'
import CustomAdsSrlCell from 'components/jmapnotev2/NoteAd/list/customCell/CustomAdsSrlCell'
import CustomStatusCell from 'components/jmapnotev2/NoteAd/list/customCell/CustomStatusCell'
import useGridEvent from 'lib/hooks/useGridEvent'
import useIsMobile from 'lib/hooks/useIsMobile'
import StylingHeaderComponent from './StylingHeaderComponent'

/**
 * 광고 목록 container
 * @param {reference} loadTargetRef 무한스크롤 ref
 * @param {*} param0
 * @returns
 */
const NoteAdListGridContainer = ({
  children,
  tableData,
  replaceSearch,
  input,
}) => {
  const isMobile = useIsMobile()
  const newLineClickTime = useRef(null)
  const gridRef = useRef(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)
  const gridColumnApiStateRef = useRef()
  gridColumnApiStateRef.current = gridColumnApi

  //주소 검색중인지 확인하기 위한 state
  const [isSearching, setIsSearching] = useState(false)
  const isSearchingStateRef = useRef()
  isSearchingStateRef.current = isSearching

  const { onCellFocused } = useGridEvent({
    resizeLayerRef: null,
  })

  let newLineCount = 0

  const onGridReady = useCallback((params) => {
    setGridColumnApi(params.columnApi)
  }, [])

  const defaultCoumn = [
    {
      field: 'status',
      headerComponent: () => (
        <StylingHeaderComponent top="광고상태" name="상태" sortable />
      ),
      width: 100,
      unSortIcon: true,
      cellRenderer: (params) => {
        return (
          <CustomStatusCell node={params.node} value={params.value} />
        )
      },
      ...columnCentered,
    },
    {
      field: 'display_address',
      suppressMovable: true,
      cellClassRules: cellPrependLabelColorClass,
      headerComponent: () => (
        <StylingHeaderComponent top="읍면동" name="읍면동" sortable />
      ),
      suppressMovable: true,
      cellRenderer: (params) => (
        <Suspense fallback={<Skeleton expand />}>
          <LazyAddressRenderer
            ref={gridRef}
            gridColumnApi={gridColumnApiStateRef.current}
            value={params.value}
            data={params.data}
            node={params.node}
            setIsSearching={setIsSearching}
            clearIsSearchingAddr={() => setIsSearching(false)}
            gridfontsize={getGridFontSize('m')}
          />
        </Suspense>
      ),
      suppressKeyboardEvent: (params) => {
        const isSearchingNow = isSearchingStateRef.current
        //주소 검색중 enter, 오,왼 방향키 눌렀을 때 cell이 수정 모드로 변하는것 막기
        if (
          suppressEnter(params) ||
          suppressArrow(params) ||
          suppressTextCursorControl(params)
        ) {
          const targetNode = params.event?.target
          if (
            targetNode &&
            (targetNode.tagName == 'INPUT' ||
              targetNode.tagName == 'UL' ||
              targetNode.tagName == 'LI')
          ) {
            return true
          }
        }

        // esc -> 검색 취소
        if (params.event.key == 'Escape') {
          if (params.data.note_srl == '신규' && isSearchingNow) {
            resetGridDataAddresses(params.node, true)
          } else {
            gridRef.current.api.setFocusedCell(
              params.node.rowIndex,
              'addr',
            )
          }
        }

        return false
      },
      width: 71,
      resizable: false,
      editable: false,
      colSpan: () => 2,
      ...columnCentered,
    },
    {
      fontsize: '20px',
      field: 'address',
      suppressMovable: true,
      cellClass: 'custom-no-padding-cell custom-display-address-cell ',
      suppressMovable: true,
      headerName: '주소',
      resizable: true,
      editable: false,
      width: 300,
    },
    {
      field: 'property_type',
      suppressMovable: true,
      cellClass:
        'custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell custom-no-padding-cell ',
      headerComponent: () => (
        <StylingHeaderComponent top="종류" name="종류" sortable />
      ),
      width: 86,
      unSortIcon: true,
      resizable: true,
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyPropertyTypeRenderer
              data={params.data}
              node={params.node}
            />
          </Suspense>
        )
      },
      ...columnCentered,
    },
    {
      field: 'contract_type',
      suppressMovable: true,
      cellClass:
        'custom-no-padding-cell custom-contract-type-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell contract_type_ad',
      headerComponent: () => (
        <StylingHeaderComponent top="구분" name="구분" sortable />
      ),
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyContractTypeRenderer
              data={params.data}
              node={params.node}
            />
          </Suspense>
        )
      },
      unSortIcon: true,
      resizable: true,
      width: 64,
      ...columnCentered,
    },
    {
      field: 'ad_srl',
      suppressMovable: true,
      headerComponent: () => (
        <StylingHeaderComponent top="가격" name="가격" sortable />
      ),
      cellClass:
        'custom-multiple-cell custom-class-price-cell custom-event-suppress-side-arrow-keys custom-event-suppress-text-cursor-keys custom-event-suppress-enter-to-next ',
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyPriceRenderer node={params.node} api={params.api} />
          </Suspense>
        )
      },
      unSortIcon: true,
      width: 150,
      resizable: true,
      ...columnCentered,
    },
    {
      field: 'ad_srl',
      suppressMovable: true,
      cellClass:
        'custom-multiple-cell custom-class-area-cell custom-event-suppress-side-arrow-keys custom-event-suppress-text-cursor-keys custom-event-suppress-enter-to-next ',
      headerComponent: () => (
        <StylingHeaderComponent
          top="면적"
          bottom="공급/전용"
          name="면적"
        />
      ),
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyAreaRenderer
              node={params.node}
              property_type={params.data.property_type}
              contract_area={params.data.contract_area}
              dong={params.data.dong}
              ho={params.data.ho}
              total_floor_area={params.data.total_floor_area}
              land_area={params.data.land_area}
              type="ad"
              contractInput={
                <CustomAreaCellContract
                  type="ad"
                  node={params.node}
                  exclusive_area={params.data.exclusive_area}
                  property_type={params.data.property_type}
                />
              }
            />
          </Suspense>
        )
      },
      unSortIcon: true,
      resizable: true,
      width: 160,
      ...columnCentered,
    },
    {
      field: 'direction',
      suppressMovable: true,
      headerName: '방향',
      width: 70,
      resizable: true,
      cellEditor: 'agSelectCellEditor',
      cellClass:
        'custom-cell-overflow-visible custom-class-direction-cell custom-event-suppress-arrow-keys custom-multiple-cell custom-no-padding-cell ',
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyDirectionRenderer node={params.node} type="ad" />
          </Suspense>
        )
      },
      ...columnCentered,
    },
    {
      field: 'note_srl',
      suppressMovable: true,
      headerComponent: () => (
        <StylingHeaderComponent
          top="노트번호"
          name="매물번호"
          sortable
        />
      ),
      cellRenderer: (params) => {
        return <CustomNoteSrlCell node={params.node} />
      },
      width: 85,
    },
    {
      field: 'note_srl',
      suppressMovable: true,
      headerName: '광고번호',
      cellRenderer: (params) => {
        return <CustomAdsSrlCell node={params.node} />
      },
      width: 130,
    },
    {
      field: 'naver_button',
      suppressMovable: true,
      headerName: '광고 설정',
      cellRenderer: (params) => {
        return (
          <Suspense fallback={<Skeleton />}>
            <LazyAdButtonsRenderer
              data={params.data}
              node={params.node}
              gridRef={gridRef}
              replaceSearch={replaceSearch}
              input={input}
            />
          </Suspense>
        )
      },
      unSortIcon: true,
      resizable: true,
      width: 266,
    },
    {
      field: 'registration_date',
      headerName: '등록기간',
      width: 105,
      unSortIcon: true,
      wrapText: true,
      cellStyle: {
        lineHeight: '110%',
        ...columnCentered.cellStyle,
      },
    },
    {
      field: 'verification_type',
      valueFormatter: (params) => {
        return getVeriKor(params.value)
      },
      headerName: '검증방식',
      width: 100,
      unSortIcon: true,
      wrapText: true,
      cellStyle: { lineHeight: '110%', ...columnCentered.cellStyle },
    },
    {
      field: 'creator_name',
      headerName: '등록인',
      width: 100,
      unSortIcon: true,
      wrapText: true,
      cellStyle: { lineHeight: '110%', ...columnCentered.cellStyle },
    },
  ]

  const [columnDefs, setColumnDefs] = useState(defaultCoumn)

  /**
   * 검증방식 한글로 얻기
   */
  const getVeriKor = (value) => {
    return options['검증방식']?.[value] || ''
  }

  /**
   * 신규 row 추가
   */
  const onClickNewLineAdd = useCallback(() => {
    const now = moment()

    //빨리 클릭해 여러줄이 생성되는것 방지
    const diff = now.diff(
      moment(newLineClickTime.current),
      'millisecond',
    )

    if (diff < 700) {
      return
    }

    /**
     * 신규 row data
     * @returns new rowdata
     */
    const createNewRowData = () => {
      const newData = {
        address_srl: '',
        address_id: '',
        regdate: '신규',
        ad_srl: `신규`,
        row_key: `new${newLineCount}`,
        area1: '',
        area2: '',
        addr: '',
        rowHeight: 40,
        contract_type: '',
        property_type: '',
        eupmyeondong: '',
        address_doro: '',
        dong: '',
        ho: '',
        contacts: [],
        land_type: '',
        direction: '',
      }

      newLineCount++
      return newData
    }

    const addedRow = gridRef.current.api.applyTransaction({
      add: [createNewRowData()],
      addIndex: 1,
    })

    addedRow.add[0].setSelected(true)

    gridRef.current.api.setFocusedCell(1, 'display_address')

    newLineClickTime.current = moment()
  }, [])

  /**
   * 컬럼별 rowHeight 설정하기
   */
  const getRowHeight = useCallback(
    (params) => {
      return params.data.rowHeight
    },
    [tableData],
  )

  /**
   * 전체 너비 사용 row 조건
   */
  const isFullWidthRow = useCallback((params) => {
    return params.rowNode.data.row_key == 'addNewLine'
  }, [])

  const fullWidthCellRenderer = (e) => {
    return (
      <NoteGridNewLineRenderer
        text="신규 광고 주소 입력"
        onClickNewLineAdd={onClickNewLineAdd}
      />
    )
  }

  const LazyAddressRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomAddressCell,
    }),
  )

  const LazyPropertyTypeRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomPropertyTypeCell,
    }),
  )

  const LazyContractTypeRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomContractTypeCell,
    }),
  )

  const LazyPriceRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomPriceCell,
    }),
  )

  const LazyAreaRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomAreaCellCommon,
    }),
  )

  const LazyDirectionRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomDirectionCell,
    }),
  )

  const LazyAdButtonsRenderer = React.lazy(() =>
    Promise.resolve({
      default: CustomAdButtonsCell,
    }),
  )

  /**
   * 그리드 옵션
   */
  const gridOptions = {
    rowClassRules: rowClassRules,
    onCellFocused: (e) =>
      onCellFocused(
        e,
        null,
        isSearchingStateRef.current,
        setIsSearching,
        null,
        true,
      ),
  }

  return children({
    getRowHeight,
    isFullWidthRow,
    fullWidthCellRenderer,
    gridRef,
    columnDefs,
    onGridReady,
    gridOptions,
  })
}

export default React.memo(NoteAdListGridContainer)
