import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import SummaryLegalCurrentUse from './SummaryLegalCurrentUse'
import SummaryLegalInfo from './SummaryLegalInfo'
import SummaryLegalInfoLink from './SummaryLegalInfoLink'
import SummaryLegalHistoryInfo from './SummaryLegalHistoryInfo'
import SummaryLegalDevInfo from './SummaryLegalDevInfo'
import SummaryLegalRawTags from './SummaryLegalRawTags'
import { StyledCardTitle } from '../style/SummaryStyle'
import { Skeleton } from 'antd'
import { forwardRef } from 'react'

const SummaryLegal = ({ data, loading }, ref) => {
  return (
    <div ref={ref}>
      <Card>
        <CardHeader>
          <CardTitle>규제 정보</CardTitle>
        </CardHeader>
        <CardBody>
          {loading && <Skeleton active />}
          {data && (
            <>
              {data.planning?.others && (
                <SummaryLegalCurrentUse planning={data.planning} />
              )}
              {data.court && <SummaryLegalInfo court={data.court} />}
              {data.law && <SummaryLegalInfoLink law={data.law} />}

              {(data.move || data.luris) && (
                <>
                  <Hr />
                  <StyledCardTitle>토지 이동(변동)사유</StyledCardTitle>
                  <SummaryLegalHistoryInfo
                    move={data.move}
                    luris={data.luris}
                  />
                </>
              )}

              {data.planning?.development && (
                <>
                  <Hr />
                  <StyledCardTitle>고시정보</StyledCardTitle>
                  <SummaryLegalDevInfo
                    development={data.planning.development}
                  />
                </>
              )}

              {data.planning?.others && (
                <>
                  <Hr />
                  <StyledCardTitle>기타 법률</StyledCardTitle>
                  <SummaryLegalRawTags others={data.planning?.others} />
                </>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

const Hr = styled.div`
  background: ${theme.colors.gray[100]};
  height: 1px;
  border: 0;
  margin: 24px 0;
`

export default forwardRef(SummaryLegal)
