import theme, { media } from 'lib/styles/theme'
import moment from 'moment'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 올인원 결제 확인창 레이아웃
 */
const NotePaymentInvoiceLayout = ({
  totalPrice,
  card,
  data,
  visiblePeriod,
  isRefund,
  additionalText,
}) => {
  return (
    <>
      {data.map(({ period, item_name, amount }, i) => {
        return (
          <Wrapper key={i} border={i != data.length - 1}>
            <Price>
              <div>{item_name}</div>
              <PriceValue>{numberWithCommas(amount)}원</PriceValue>
            </Price>
            <Date>
              {visiblePeriod && period && (
                <>
                  제공기간&nbsp;&#58;&nbsp;
                  {period}
                </>
              )}
            </Date>
          </Wrapper>
        )
      })}
      {additionalText && (
        <AdditionalText>{additionalText}</AdditionalText>
      )}
      {card && (
        <Card highMargin={!additionalText}>
          {card?.issuerName}
          {card?.number}
        </Card>
      )}
      <Price border={!card} total>
        <div>최종 {isRefund ? '환불금액' : '결제금액'}</div>
        <div>{numberWithCommas(totalPrice)}원</div>
      </Price>
    </>
  )
}

const Wrapper = styled.div`
  margin-bottom: 12px;
  ${({ border }) =>
    border &&
    `
      border-bottom: 1px dashed ${theme.colors.gray[400]};
  `}
`

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;

  ${({ total }) =>
    total &&
    `
    font-weight: 700;
  `}

  ${({ border }) =>
    border &&
    `
    padding-top: 18px;
    border-top: 1px solid ${theme.colors.gray[200]};
  `}

  ${media.mediumS`
    font-size: 14px;
  `}
`

const Date = styled.div`
  margin-bottom: 12px;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
`

const Card = styled.div`
  padding: 12px 20px;
  margin-top: 12px;
  margin-bottom: 30px;
  border: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.gray[100]};
  font-weight: 500;
  line-height: 130%;
  border-radius: 6px;

  ${({ highMargin }) =>
    highMargin &&
    `
      margin-top: 20px;
  `}

  ${media.mediumS`
    font-size: 14px;
  `}
`

const AdditionalText = styled.div`
  padding-top: 12px;
  color: ${theme.colors.gray[600]};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  border-top: 1px dashed ${theme.colors.gray[400]};
`

const PriceValue = styled.div`
  flex-shrink: 0;
`

export default NotePaymentInvoiceLayout
