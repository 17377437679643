import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { ReactComponent as MapIcon } from 'assets/icon/map-active.svg'
import { useHistory } from 'react-router-dom'
import SideMenuNote from './SideMenuNote'
import { ReactComponent as FavoriteIconActive } from 'assets/icon/favorite-active.svg'
import NoteLinkContainer from 'containers/note/NoteLinkContainer'
import { ReactComponent as NoteIconActive } from 'assets/icon/note-active.svg'
import SideMenuNoteAccountAndPay from './SideMenuNoteAccountAndPay'
import useIsMobile from 'lib/hooks/useIsMobile'

const MenuWrapper = styled.div`
  width: 100%;
  min-height: 434.69px;
  padding: 28px 20px 0 20px;
`

const IconWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    margin-left: 12px;
    font-weight: 500;
    line-height: 180%;
  }
`

const TittleWrapper = styled.div`
  line-height: 150%;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`

const ContentWrapper = styled.div`
  margin-bottom: 38px;
`

const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 11.5px 0;
  width: 50%;
`

const SideMenuJootekContent = ({ onClose }) => {
  const channels = useSelector((state) => state.channel.channels)
  const isMobile = useIsMobile()
  const memberType =
    JSON.parse(localStorage.getItem('memberType')) || ''

  const history = useHistory()

  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const movePage = (path) => {
    history.push(`/${path}`)
    onClose()
  }

  return (
    <MenuWrapper>
      <ContentWrapper>
        <TittleWrapper>지도</TittleWrapper>
        <IconWrapper>
          <MenuItem
            onClick={() =>
              movePage(loginInfo?.member_srl ? 'home' : '')
            }
          >
            <MapIcon />
            <span>{isMobile ? '지도' : '지도 홈'}</span>
          </MenuItem>
          {memberType && memberType === 'realestate' && (
            <NoteLinkContainer>
              {({ popup, active, onClick }) => (
                <>
                  <MenuItem
                    onClick={() => {
                      onClick()
                      if (channels?.length > 0) {
                        onClose()
                      }
                    }}
                  >
                    <NoteIconActive width={24} height={24} />
                    <span>매물노트</span>
                  </MenuItem>
                  {popup}
                </>
              )}
            </NoteLinkContainer>
          )}
          {((!memberType && loginInfo?.member_type !== 'realestate') ||
            !memberType ||
            memberType !== 'realestate') && (
            <MenuItem onClick={() => movePage('favorite')}>
              <FavoriteIconActive />
              <span>즐겨찾기</span>
            </MenuItem>
          )}
        </IconWrapper>
      </ContentWrapper>
      {loginInfo?.member_type === 'realestate' &&
      memberType &&
      memberType === 'realestate' ? (
        <ContentWrapper>
          <TittleWrapper>매물노트</TittleWrapper>
          <SideMenuNote type="hamburger" onClose={onClose} />
          <SideMenuNoteAccountAndPay onClose={onClose} />
        </ContentWrapper>
      ) : null}
    </MenuWrapper>
  )
}

export default SideMenuJootekContent
