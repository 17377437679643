import { message } from 'antd'
import {
  insertPlanUsers,
  validatePlanUsers,
} from 'lib/api/notev2/account'
import { calcAllinoneMemberCount } from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useCalcCostByDay from './hooks/useCalcCostByDay'
import { max } from 'lodash'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'

const NotePaymentPersonNumberSelectContainer = ({
  children,
  search,
  onCancel,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const month = planInfo?.months

  const [onRequestValidate, , dataValidate] = useAxios(
    validatePlanUsers,
    {
      resetError: true,
    },
  ) //올인원 멤버 번호로 검증
  const [onRequestPay, , dataPay] = useAxios(insertPlanUsers, {
    resetError: true,
  }) //올인원 멤버 추가(최종)

  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState({ input1: '' }) //전화번호 입력값
  const [errorNumbers, setErrorNumbers] = useState([]) //유효하지 않은 전화번호
  const [isAllValidated, setIsAllValidated] = useState(false) //전화번호가 모두 유효한지(모두 유효해야 결제로 넘어갈 수 있도록 함)
  const [finalAmount, setFinalAmount] = useState(0) //validate 결과에서 받아온 총 결제 금액
  const [finalPerAmount, setFinalPerAmount] = useState(0) //validate 결과에서 받아온 총 인당 금액
  const [finalPersonCount, setFinalPersonCount] = useState(0)

  const [confirmModalVisible, setConfirmModalVisible] = useState(false) //결제 전 영수증

  const personCount = Object.values(input).filter((item) => item).length //인원수

  const { amount: perAmount } = useCalcCostByDay(true, 'insert') //일할 계산 금액

  /**
   * 뒤로가기 눌렀을 때
   */
  const goBack = () => {
    onCancel()
  }

  /**
   * 뒤로가기 제어 추가함
   */
  useEffect(() => {
    history.pushState(null, '', location.href)
    window.addEventListener('popstate', goBack)

    return () => window.removeEventListener('popstate', goBack)
  }, [])

  /**
   * 전화번호 입력 event
   */
  const handleTextValueChange = (e) => {
    setIsAllValidated(false)
    const { name, value } = e.target
    setInput((input) => {
      return { ...input, [name]: value }
    })
  }

  /**
   * input 입력칸 추가
   */
  const addInput = () => {
    const onlyNumberInputs = Object.fromEntries(
      Object.entries(input).map(([key, value]) => {
        return [key.replace(/\D/g, ''), value]
      }),
    )

    const maxNumber = max(Object.keys(onlyNumberInputs).map(Number)) + 1

    setInput((prev) =>
      Object.assign({}, prev, {
        [`input${maxNumber}`]: '',
      }),
    )
  }

  /**
   * input 입력칸 삭제
   */
  const removeInput = (key) => {
    const copyInput = { ...input }

    //오류 전화번호에 있었다면 삭제
    if (errorNumbers.map(({ number }) => number).includes(input[key])) {
      setErrorNumbers((prev) =>
        prev.filter(({ number }) => number != input[key]),
      )
    }

    delete copyInput[key]

    if (
      Object.keys(copyInput).length == 0 ||
      Object.values(copyInput).filter((item) => item).length == 0
    ) {
      setIsAllValidated(false)
    }

    setInput(copyInput)
  }

  /**
   * input blur 이벤트 일어날 때 마다 멤버 검증, 가격 계산
   */
  const onBlurInput = () => {
    const numbers = Object.values(input).filter((item) => item)
    if (numbers.length == 0) {
      message.info('초대할 전화번호를 입력해주세요.')
      return
    }

    //번호 검증
    onRequestValidate({
      numbers,
    })
  }

  /**
   * 번호 검증 결과
   */
  useEffect(() => {
    if (!dataValidate) return

    if (
      !dataValidate.error_number ||
      dataValidate.error_number.length == 0
    ) {
      setIsAllValidated(true)
      setFinalAmount(dataValidate.total_price)
      setFinalPerAmount(dataValidate.daily_amount)
      setFinalPersonCount(dataValidate.additional_people)
    } else {
      setErrorNumbers(dataValidate.error_number)
      setIsAllValidated(false)
    }
  }, [dataValidate])

  /**
   * n개월/n명 결제하기 버튼 클릭
   */
  const onClickPaymentButton = () => {
    setConfirmModalVisible(true)

    onBlurInput()
  }

  /**
   * 결제 모달 결제하기
   */
  const onConfirmModalOk = () => {
    setLoading(true)
    const numbers = Object.values(input).filter((item) => item)
    onRequestPay({
      numbers,
      amount: finalAmount,
    })
  }

  /**
   * 초대 완료
   */
  useEffect(() => {
    if (!dataPay || dataPay?.error != 0) {
      setLoading(false)
      return
    }

    setLoading(false)
    search()
    message.success('멤버 초대가 완료되었습니다.')
    onCancel()
    onConfirmModalClose()
  }, [dataPay])

  /**
   * 결제 모달 닫기
   */
  const onConfirmModalClose = () => {
    setConfirmModalVisible(false)
  }

  /**
   * 삭제하면서 검증되지 않은 번호가 0개일 때 에러 초기화하고 다시 계산
   */
  useUpdateEffect(() => {
    if (errorNumbers.length == 0) {
      onBlurInput()
    }
  }, [errorNumbers])

  return children({
    loading,
    errorNumbers,
    input,
    isAllValidated,
    confirmModalVisible,
    personCount,
    month,
    finalAmount,
    finalPerAmount,
    finalPersonCount,
    handleTextValueChange,
    addInput,
    removeInput,
    onBlurInput,
    perAmount,
    onConfirmModalOk,
    onConfirmModalClose,
    onClickPaymentButton,
  })
}

export default NotePaymentPersonNumberSelectContainer
