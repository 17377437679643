import { Button, Checkbox, Confirm } from 'components/design'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import ColorSelectBox from 'components/jmapnotev2/elements/ColorSelectBox'
import { TextArea } from 'components/jmapnotev2/style/NoteDocsStyle'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import reactStringReplace from 'react-string-replace'
import { getOnlyRemoveCommaNumber } from 'lib/utils/notev2/util'
import { unionBy } from 'lodash'
import { useSelector } from 'react-redux'

const NoteDocsContractRaws = ({ onUpdate, mainArticlesSpec }) => {
  const { watch, register, setValue } = useFormContext()
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const mainArticles = useMemo(
    () => mainArticlesSpec.filter(({ num }) => num != 1),
    [mainArticlesSpec],
  )

  const raws = watch('raws') || []
  const gyobu_date = watch('gyobu_date')
  const vat = watch('vat')
  const realestate_reward = watch('realestate_reward')
  const assignment_date = watch('assignment_date')
  const duration_day = watch('duration_day')
  const duration_month = watch('duration_month')
  const included_firstday = watch('included_firstday')
  const contract_type = watch('contract_type')

  useEffect(() => {
    const textAreaArticles = mainArticlesSpec.filter(
      ({ isEditable }) => isEditable,
    )
    const defaultRaws = textAreaArticles.map(({ num, text, title }) => {
      return {
        num,
        text: `[${title}] ${text}`,
      }
    })
    const unionArr = unionBy(raws, defaultRaws, 'num')

    setValue('raws', unionArr)
  }, [])

  const getData = useCallback(
    (key) => {
      const result = {
        assignment_date: assignment_date,
        duration_day: duration_day,
        duration_month: duration_month,
        included_firstday: included_firstday,
        gyobu_date: gyobu_date,
      }

      return result[key] ? result[key] : ''
    },
    [
      assignment_date,
      duration_day,
      duration_month,
      included_firstday,
      gyobu_date,
    ],
  )

  /**
   * 임대차 기간 더하기 빼기 로직
   */
  const onClickDurationDayCalc = ({ type, unit }) => {
    const amount = type == 'add' ? 1 : -1

    //날짜가 입력되어 있을 때는 입력값, 날짜가 입력이 안되어 있으면 인도일 기준으로, 둘 다 아니면 오늘 기
    const calcDate =
      duration_day || assignment_date
        ? moment(duration_day || assignment_date).add(amount, unit)
        : moment().add(amount, unit)
    const result = calcDate.format('YYYY-MM-DD')

    onUpdate('duration_day', result)

    //인도일이 있는 경우 개월 수 계산
    if (assignment_date) {
      const diff = moment(result).diff(assignment_date, 'month')
      onUpdate('duration_month', diff)
    }
  }

  /**
   * 수정 불가능 특약 포맷
   * 전체 텍스트가 아닌 input 같은 자잘한 것이 낑겨 있음
   */
  const formatRawText = useCallback(
    (inputs, text, num, title) => {
      if (inputs && inputs?.length > 0) {
        let temp = `[${title}] ${text}`
        let result

        inputs.forEach(({ replace, type, name }) => {
          result = reactStringReplace(temp, replace, (match, i) => {
            if (type == 'date') {
              const data = getData(name)

              if (name == 'duration_day' && contract_type != '매매') {
                //월, 전세일 때 임대차 기간 계산
                //임대차 일때는 월, 년 단위 계산하는 버튼 추가
                return (
                  <DurationDayWrapper key={'raw-duration' + num + i}>
                    <ColorInput
                      key={name}
                      type={type}
                      value={data}
                      selected={data ? moment(data).toDate() : ''}
                      onChange={(e) =>
                        onUpdate(
                          name,
                          e ? moment(e).format('YYYY-MM-DD') : '',
                        )
                      }
                    />
                    <DurationDayButtons>
                      <Button
                        size="small"
                        color="blue"
                        onClick={() =>
                          onClickDurationDayCalc({
                            type: 'add',
                            unit: 'year',
                          })
                        }
                      >
                        &#43;1년
                      </Button>
                      <Button
                        size="small"
                        color="blue"
                        onClick={() =>
                          onClickDurationDayCalc({
                            type: 'minus',
                            unit: 'year',
                          })
                        }
                      >
                        &ndash;1년
                      </Button>
                      <Button
                        size="small"
                        color="blue"
                        onClick={() =>
                          onClickDurationDayCalc({
                            type: 'add',
                            unit: 'month',
                          })
                        }
                      >
                        &#43;1개월
                      </Button>
                      <Button
                        size="small"
                        color="blue"
                        onClick={() =>
                          onClickDurationDayCalc({
                            type: 'minus',
                            unit: 'month',
                          })
                        }
                      >
                        &ndash;1개월
                      </Button>
                      <Button
                        size="small"
                        color="blue"
                        onClick={() =>
                          onClickDurationDayCalc({
                            type: 'add',
                            unit: 'day',
                          })
                        }
                      >
                        &#43;1일
                      </Button>
                      <Button
                        size="small"
                        color="blue"
                        onClick={() =>
                          onClickDurationDayCalc({
                            type: 'minus',
                            unit: 'day',
                          })
                        }
                      >
                        &ndash;1일
                      </Button>
                    </DurationDayButtons>
                  </DurationDayWrapper>
                )
              } else {
                return (
                  <ColorInput
                    key={'raw-input' + num + name}
                    type={type}
                    value={data}
                    selected={data ? moment(data).toDate() : ''}
                    onChange={(e) =>
                      onUpdate(
                        name,
                        e ? moment(e).format('YYYY-MM-DD') : '',
                      )
                    }
                  />
                )
              }
            } else if (type == 'number') {
              return (
                <ColorInput
                  key={getData(name)}
                  width={40}
                  className="customMargin"
                  type="text"
                  defaultValue={getData(name)}
                  onBlur={(e) => {
                    onUpdate(name, e.target.value)
                  }}
                />
              )
            }
          })
          temp = result
        })

        if (num == 9) {
          return <div key={'raw-div' + num}>{result}</div>
        }

        /**
         * 2조인 경우 특수한 style 적용
         */
        if (num == 2 && contract_type != '매매') {
          return (
            <div className={num == 2 && 'specific-two'}>{result}</div>
          )
        }
        return result
      } else {
        return `[${title}] ${text}`
      }
    },
    [
      assignment_date,
      duration_day,
      duration_month,
      included_firstday,
      gyobu_date,
    ],
  )

  /**
   * 특약 저장
   */
  const onBlurTextArea = useCallback(
    ({ key, value }) => {
      const nums = raws.map(({ num }) => num)

      if (nums.includes(key)) {
        const newData = raws.map((item) => {
          if (item.num == key) {
            item.text = value
            return item
          }

          return item
        })
        onUpdate('raws', newData)
      } else {
        onUpdate('raws', [...raws, ...[{ num: key, text: value }]])
      }
    },
    [raws],
  )

  /**
   *  특약 초기화
   */
  const onClickResetTextArea = useCallback(
    ({ key, value, title }) => {
      //reset
      const newData = raws.map((item) => {
        if (item.num == key) {
          item.text = `[${title}] ${value}`
          return item
        }

        return item
      })

      onUpdate('raws', newData)
    },
    [raws],
  )

  /**
   * 수정 가능(textarea) 특약
   */
  const getRawData = useCallback(
    (key, title, text) => {
      const target = raws?.find(({ num }) => num == key) || ''

      const titleText =
        text != target?.text || target?.text.includes(`[${title}]`)
          ? ''
          : `[${title}] `

      return `${titleText}${target?.text}` || ''
    },
    [raws],
  )
  /**
   * 초기화 클릭 시 사용자에게 확인 받기
   */
  const onClickReset = useCallback(({ num, text, title }) => {
    Confirm.info({
      title: `알림`,
      content: `제 ${num}조 내용을 기본값으로 초기화할까요?`,
      onOk: () =>
        onClickResetTextArea({ key: num, value: text, title: title }),
      okButtonProps: { color: 'blue' },
    })
  })

  return (
    <Wrapper>
      {raws &&
        raws.length > 0 &&
        mainArticles.map(
          ({ num, text, isEditable, inputs, title }, i) => {
            return (
              <Raws
                mb={num == 2 && contract_type != '매매'}
                key={'raw' + num + i}
              >
                <Jo>제{num}조</Jo>
                {isEditable ? (
                  <TextAreaWrapper>
                    <TextArea
                      onKeyDown={(e) => {
                        if (e.key == 'F5') {
                          onBlurTextArea({
                            key: num,
                            value: e.target.value,
                          })
                        }
                      }}
                      key={
                        getRawData(num, title, text) ||
                        `[${title}] ${text}`
                      }
                      defaultValue={
                        getRawData(num, title, text) ||
                        `[${title}] ${text}`
                      }
                      height={68}
                      onBlur={(e) =>
                        onBlurTextArea({
                          key: num,
                          value: e.target.value,
                        })
                      }
                    />
                    {channelInfo?.member_type != 'readonly' && (
                      <Button
                        onClick={() =>
                          onClickReset({ num, text, title })
                        }
                        plain
                        color="blue"
                      >
                        초기화
                      </Button>
                    )}
                  </TextAreaWrapper>
                ) : (
                  <Content num={num} key={'raw-content' + title + i}>
                    {formatRawText(inputs, text, num, title)}
                  </Content>
                )}
              </Raws>
            )
          },
        )}
      <Raws mt>
        <Jo></Jo>
        <Content>
          {contract_type == '매매' && (
            <ContentRow>
              교부일자:
              <ColorInput
                type="date"
                value={gyobu_date}
                selected={gyobu_date ? moment(gyobu_date).toDate() : ''}
                onChange={(e) =>
                  onUpdate(
                    'gyobu_date',
                    e ? moment(e).format('YYYY-MM-DD') : '',
                  )
                }
              />
            </ContentRow>
          )}
          <ContentRow>
            중개보수는&nbsp;
            <ColorInput
              append={'원'}
              type="number"
              textAlign="right"
              key={realestate_reward}
              defaultValue={realestate_reward || ''}
              onBlur={(e) =>
                onUpdate(
                  'realestate_reward',
                  getOnlyRemoveCommaNumber(e.target.value),
                )
              }
            />
            으로 한다.
            <span>&#40; 부가가치세</span>&nbsp;
            <ColorSelectBox
              width={80}
              value={vat && [{ label: vat, value: vat }]}
              options={[
                { label: '선택', value: '' },
                { label: '별도', value: '별도' },
                { label: '포함', value: '포함' },
              ]}
              onChange={(e) => onUpdate('vat', e.value)}
            />
            &#41;
          </ContentRow>
        </Content>
      </Raws>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

export const Raws = styled.div`
  display: flex;
  line-height: 160%;
  margin-bottom: 8px;
  .customMargin {
    margin: 0 10px;
  }
  ${({ mt }) => mt && `margin-top: 4px;`}
  ${({ mb }) => mb && `margin-bottom: 34px;`}
  & > div {
    margin: 0 10px;
  }
`

export const Jo = styled.div`
  flex-shrink: 0;
  min-width: 50px;
  margin-right: 8px;
  letter-spacing: 4px;
`

export const RawsTitle = styled.div`
  flex-shrink: 0;
  margin-right: 6px;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  line-height: 130%;

  //2조 특수 스타일 적용
  .specific-two {
    display: inline;
    word-break: keep-all;

    //달력만 top을 조금 위로 땡겨 align center인 것처럼 보이도록 함
    & > div {
      display: inline-flex;
      &:not(:nth-of-type(3)) {
        top: -5px;
      }
    }
  }

  ${({ num }) =>
    num == 9 &&
    `
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `}
`

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 4px;
`

const TextAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  button {
    margin-left: 10px;
    flex-shrink: 0;
  }
`

const DurationDayWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const DurationDayButtons = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  margin-top: 4px;
  position: absolute;
  width: max-content;
  top: 24px;

  button {
    height: 24px;
  }
`

export default NoteDocsContractRaws
