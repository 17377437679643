import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDonghoHeaderInfo from './NoteDonghoHeaderInfo'
import NoteDonghoHeaderDongs from './NoteDonghoHeaderDongs'
import NoteDonghoHeaderTab from './NoteDonghoHeaderTab'
import NoteDonghoHeaderContainer from 'containers/notev2/NoteDonghoHeaderContainer'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteDonghoHeaderDongsMobile from './NoteDonghoHeaderDongsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'

/**
 * 동호관리 메인
 * 상단 아파트 정보
 */
const NoteDonghoHeader = () => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  return (
    <NoteDonghoHeaderContainer>
      {({ onClickTab }) => {
        return (
          <Wrapper>
            <TopHeader>
              <NoteDonghoHeaderInfo />
              <NoteDonghoHeaderTab onClickTab={onClickTab} />
            </TopHeader>
            {isMobile || isTablet ? (
              <NoteDonghoHeaderDongsMobile />
            ) : (
              <NoteDonghoHeaderDongs />
            )}
          </Wrapper>
        )
      }}
    </NoteDonghoHeaderContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 45px;
  padding-bottom: 10px;
  border-bottom: 8px solid ${theme.colors.gray[100]};

  ${media.smallPc`
    padding: 20px 24px 14px 24px;
  `}

  ${media.mediumM`
    padding: 10px 12px;
  `}
`

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${media.smallPc`
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  `}

  ${media.mediumM`
    display: block;
    margin: -6px -12px 0 -12px;
    width: calc(100% + 24px);
  `}
`

const SkeletonWrapper = styled.div`
  margin-bottom: 12px;
`

export default NoteDonghoHeader
