import { getPlanUsers } from 'lib/api/notev2/account'
import useAxios from 'lib/hooks/useAxios'
import { useInput } from 'lib/hooks/useInput'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 올인원 멤버 관리
 * 목록 이벤트 제어
 * 검색
 */
const NoteAccountAllInOneMembersListContainer = ({ children }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [onRequestSearch, , dataSearch] = useAxios(getPlanUsers)
  const [data, setData] = useState([])
  const [searchInput, bindSearchInput] = useInput('') //검색창

  /**
   *  멤버 검색
   */
  const search = () => {
    onRequestSearch({
      plan_srl: planInfo.plan_srl,
    })
  }

  useEffect(() => {
    search()
  }, [])

  useEffect(() => {
    if (!dataSearch || !dataSearch?.result) return

    setData(dataSearch.result)
  }, [dataSearch])

  return children({
    data,
    search,
    bindSearchInput,
  })
}

export default NoteAccountAllInOneMembersListContainer
