import TopHeaderProfile from 'components/base/TopHeader/TopHeaderProfile'
import theme, { media } from 'lib/styles/theme'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as Logo } from 'assets/icon/logo-white.svg'

const LandingHeadBar = () => {
  const history = useHistory()

  return (
    <Wrapper>
      <LogoWrapper onClick={() => history.push('/home')}>
        <Logo />
        <span>매물노트</span>
      </LogoWrapper>

      <TopHeaderProfile />
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${theme.base.headerHeight}px;
  padding: 0 40px;
  background-color: transparent;
  z-index: 10000000;

  i {
    color: ${theme.colors.base.white};
  }

  ${media.mediumS`
    padding: 0 20px;
    height: ${theme.base.headerHeightM}px;
  `}
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  color: ${theme.colors.base.white};
  cursor: pointer;

  ${media.mediumS`
    font-size: 18px;
  `}

  svg {
    margin-right: 10px;

    ${media.mediumS`
      width: 28px;
      height: 28px;
    `}
  }
`

export default LandingHeadBar
