import { useSelector } from 'react-redux'
import { AccountInput } from './NoteAccount'
import { useFormContext } from 'react-hook-form'

/**
 * 계정관리 폼 - 사업자등록번호
 */
const NoteAccountCompanyNumber = ({ disabled }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { register } = useFormContext()

  return disabled ? (
    <span>{loginInfo?.company_number}</span>
  ) : (
    <AccountInput
      name="company_number"
      defaultValue={loginInfo?.company_number}
      design={'box'}
      disabled={disabled}
      {...register('company_number')}
    />
  )
}

export default NoteAccountCompanyNumber
