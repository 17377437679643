import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import { Modal, Select } from 'components/design'
import { moveNote } from 'lib/api/note/noteApi'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useAxios from 'lib/hooks/useAxios'
import useAddresses from 'pages/jmapnote/noteList/hooks/useAddresses'
import useChannels from 'containers/note/channel/hooks/useChannels'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { useHistory } from 'react-router-dom'

/**
 * 시트모드 전용 매물 이동
 * @param {*} param0
 * @returns
 */
const NoteDetailMove = ({
  visible,
  setVisible,
  addressSrl,
  noteSrl,
  searchFn,
}) => {
  const history = useHistory()
  const { channelSrl, folderSrl } = useNoteMatch()

  const { channels } = useChannels()
  const { _getAddresses } = useAddresses()
  const [selectedChannelSrl, setselectedChannelSrl] = useState('')

  const [onRequest, loading, data, error, reset] = useAxios(moveNote)

  /**
   * 채널 선택목록의 맨 첫번째 채널 기본값으로 세팅
   */
  useEffect(() => {
    if (!channels || !channels[0]) {
      return
    }

    const filteredChannel = channels?.filter(
      (item) => item.channel_srl != channelSrl,
    )

    setselectedChannelSrl(filteredChannel?.[0]?.channel_srl)
  }, [channels])

  useEffect(() => {
    if (data && !error) {
      _getAddresses({ channelSrl, folderSrl }) // 주소목록갱신
      setVisible(false) // 모달창 닫음
    }
  }, [data, error, channelSrl, folderSrl])

  const onCancel = () => {
    setVisible(false)
  }

  const onOk = useCallback(async () => {
    // 매물 이동 api호출
    await onRequest({
      toChannelSrl: selectedChannelSrl,
      fromChannelSrl: channelSrl,
      addressSrl,
      noteSrl,
    })
    message.info('매물이 이동됐어요')
    history.replace(`/note/v2/notes/${channelSrl}`)
    searchFn && searchFn()
  }, [onRequest, channelSrl, addressSrl, noteSrl, selectedChannelSrl])

  const handleChange = (e) => {
    if (!e.target.value) {
      return
    }
    setselectedChannelSrl(e.target.value)
  }

  return (
    <Modal
      visible={visible}
      title="어디로 이동할까요?"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Select
        value={selectedChannelSrl}
        color="primary"
        onChange={handleChange}
        expand
      >
        {channels?.map((item, i) => {
          // 현재 채널은 옵션 목록에서 제외
          if (item.channel_srl == channelSrl) {
            return
          }
          return (
            <option key={i} value={item.channel_srl}>
              {item.name}
            </option>
          )
        })}
      </Select>
    </Modal>
  )
}

export default NoteDetailMove
