import { ReactComponent as OpenIcon } from 'assets/icon/note/open.svg'
import theme from 'lib/styles/theme'
import { gridFontSize } from 'lib/utils/notev2/gridUtil'
import styled from 'styled-components'

const OpenButton = ({
  size,
  visibleText = true,
  onClick,
  absolute,
  className,
  searchIcon,
  text = '열기',
}) => {
  return (
    <SheetButton
      size={size}
      onClick={onClick}
      absolute={absolute}
      className={className}
    >
      {searchIcon ? <i className="fal fa-search"></i> : <OpenIcon />}
      {visibleText && <OpenText>{text}</OpenText>}
    </SheetButton>
  )
}

const SheetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.gray[400]};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  border-radius: 4px;
  background-color: #fbfbfb;
  padding: 4px 7px;
  color: ${theme.colors.gray[600]};
  cursor: pointer;
  font-size: ${({ gridfontsize }) =>
    (gridfontsize || gridFontSize['m']) - 1}px;
  &:hover {
    filter: brightness(97%);
  }
  &:active {
    filter: brightness(90%);
  }

  &:disabled {
    color: ${theme.colors.gray[400]};
    border: 1px solid ${theme.colors.gray[300]};
  }
  ${({ size }) => size == 'medium' && `padding: 6px 7px;`}

  ${({ defaultcursor, disabled }) =>
    (defaultcursor || disabled) && `cursor: default;`}

    ${({ absolute }) =>
    absolute &&
    `
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
    `}
`

const OpenText = styled.span`
  margin-left: 4px;
`

export default OpenButton
