import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 세대(가구)수
 */
const NoteAdColumnHousehold = ({ required = false }) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>세대(가구)수</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            width={InputRemoveAppendWidth}
            type="text"
            {...register('article.household')}
            onBlur={(e) => {
              onUpdate('article.household', e.target.value)
            }}
            append={'세대'}
            fixAppendWidth
          />
        </div>
        <ValidateWarn>
          {errors?.article?.household?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnHousehold
