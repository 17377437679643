export default function usePreventLeave() {
  function listener(e) {
    if (
      Array.from(document.activeElement.classList).includes(
        'note-detail-input',
      )
    ) {
      e.preventDefault()
      e.returnValue = ''
    } else {
      return null
    }
  }

  function enablePrevent() {
    window.addEventListener('beforeunload', listener)
  }

  function disablePrevent() {
    window.removeEventListener('beforeunload', listener)
  }

  return { enablePrevent, disablePrevent }
}
