import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import priceFormat from 'lib/utils/priceFormat'
import { ReactComponent as NoteIcon } from 'assets/icon/note/ad/note-srl.svg'
import { ReactComponent as NaverIcon } from 'assets/icon/note/ad/naver-logo-simple.svg'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { message } from 'antd'

/**
 * 매물 정보 블럭
 * @param data 매물일 때는 매물 정보, 광고일때는 광고 정보
 * @param matchAd 매물일때만 사용하는 광고 정보
 * @param type ad | '' 광고전용인지 아닌지
 */
const NoteDonghoHoDetailNoteInfo = ({ data, matchAd, type }) => {
  const history = useHistory()
  const getPrice = () => {
    const contractType = data.contract_type

    if (type == 'ad') {
      if (contractType == '월세') {
        return `${priceFormat(data.price * 10000)}/${priceFormat(
          data.price2 * 10000,
        )}`
      } else {
        return priceFormat(data.price * 10000)
      }
    } else {
      if (contractType == '매매') {
        return priceFormat(data.sale_price * 10000)
      } else if (contractType == '전세') {
        return priceFormat(data.jeonse_deposit * 10000)
      } else if (contractType == '월세') {
        return `${priceFormat(data.deposit * 10000)}/${priceFormat(
          data.rent * 10000,
        )}`
      }
    }
  }

  const onClickGoNote = () => {
    if (!data.channel_srl || !data.address_srl || !data.note_srl) {
      message.error('잘못된 요청입니다.')
      return
    }
    history.push(
      `/note/v2/notes/${data.channel_srl}/${data.address_srl}/${data.note_srl}`,
    )
  }

  return (
    <Block nopadding={type == 'ad'}>
      <Date>
        접수
        <span>
          {data.regdate ? (
            moment(data.regdate, 'YYYYMMDDHHmmss').format('YYYY.MM.DD')
          ) : (
            <>&ndash;</>
          )}
        </span>
      </Date>
      <BlockItem>
        <BlockTitle>매물정보</BlockTitle>
        <BlockValue>
          <ContractType type={data.contract_type}>
            {data.contract_type}
          </ContractType>
          <Price>{getPrice()}</Price>
        </BlockValue>
      </BlockItem>
      <BlockItem>
        <BlockTitle>입주가능일</BlockTitle>
        <BlockValue>
          {data.moveinYmd || data.move_date ? (
            moment(
              data.moveinYmd || data.move_date,
              'YYYY-MM-DD',
            ).format('YY.MM.DD')
          ) : (
            <>&ndash;</>
          )}
        </BlockValue>
      </BlockItem>
      <BlockItem>
        <BlockTitle>매물번호</BlockTitle>
        <BlockValue>
          <Ids>
            {type != 'ad' && (
              <IdsItem pointer onClick={onClickGoNote}>
                <NoteIcon /> {data.note_srl}
              </IdsItem>
            )}
            {type == 'ad' ? (
              <IdsItem naver>
                <NaverIcon fill="#03BC63" />
                {data.nuid || <>&ndash;</>}
              </IdsItem>
            ) : (
              matchAd &&
              Object.keys(matchAd).length > 0 && (
                <IdsItem naver>
                  <NaverIcon fill="#03BC63" />
                  {matchAd.nuid || <>&ndash;</>}
                </IdsItem>
              )
            )}
          </Ids>
        </BlockValue>
      </BlockItem>
      {matchAd && Object.keys(matchAd).length > 0 && (
        <BlockItem>
          <BlockTitle>광고상태</BlockTitle>
          <BlockValue>{matchAd.status}</BlockValue>
        </BlockItem>
      )}
      {type != 'ad' &&
        (data.contacts.length > 0 ? (
          <CallPhone>
            {data.contacts
              .slice(0, 2)
              .map(({ type, alias, numbers }, i, oriArr) => {
                return (
                  <CallPhoneItem
                    href={numbers ? `tel:${numbers[0].number}` : ''}
                    key={i}
                    width={100 / oriArr.length}
                  >
                    {type} {alias} <i className="fas fa-phone"></i>
                  </CallPhoneItem>
                )
              })}
          </CallPhone>
        ) : (
          <CallPhone>
            <CallPhoneItem width={100}>
              등록된 연락처가 없습니다.
            </CallPhoneItem>
          </CallPhone>
        ))}
    </Block>
  )
}

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  padding: 12px 16px 64px 16px;
  margin: 0 20px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};

  ${({ nopadding }) =>
    nopadding &&
    `
      padding: 12px 16px 16px 16px;
  `}

  ${media.mediumS`
      margin-left: 14px;
      margin-right: 14px;
  `}
`

const BlockItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 120%;
`

const BlockTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 500;
`

const BlockValue = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 600;
`

const ContractType = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;

  ${({ type }) => {
    switch (type) {
      case '매매':
        return `
        color: ${theme.colors.summary.rp.blue};
        `
      case '전세':
        return `
        color: ${theme.colors.summary.rp.primary};
        `
      case '월세':
        return `
        color: ${theme.colors.summary.rp.red};
        `
    }
  }};
`

const Price = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`

const CallPhoneItem = styled.a`
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  background-color: ${theme.colors.gray[100]};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.gray[150]};
    color: ${theme.colors.gray[600]};
  }

  i {
    font-size: 11px;
  }

  ${({ width }) =>
    width &&
    `
    width: ${width}%;
   `}
`
const CallPhone = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 44px;
  border-radius: 0 0 8px 8px;

  ${CallPhoneItem} {
    &:nth-last-child(n + 2):first-child,
    &:nth-last-child(n + 2):first-child ~ li {
      &:first-child {
        border-right: 1px solid ${theme.colors.gray[200]};
      }
    }
  }
`

const Ids = styled.div`
  display: flex;
  gap: 6px;
`

const IdsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  text-decoration: underline;
  text-underline-position: under;
  color: #9355e2;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;

  ${({ naver }) =>
    naver &&
    `
    color: #00A32A;
  `}
  ${({ pointer }) =>
    pointer &&
    `
    cursor: pointer;
  `}
`
const Date = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;

  span {
    color: ${theme.colors.gray[400]};
    font-size: 12px;
    font-weight: 500;
  }
`

export default NoteDonghoHoDetailNoteInfo
