import React from 'react'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { Button } from 'components/design'

const ContactSync = ({}) => {
  return (
    <a href="jootekApp://getDeviceContacts">
      <Button className="contact-sync" plain expand>
        동기화
      </Button>
    </a>
  )
}

const StyledContainer = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 2rem 1rem;
  /* margin-bottom: 1rem; */

  text-align: center;
  border: 1px dashed ${theme.colors.gray[200]};
  border-radius: ${theme.borderRadius[1]};

  & > label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > i {
      color: ${theme.colors.gray[500]};
      font-size: 2rem;
      margin-bottom: 0.75rem;
    }
  }

  &:hover {
    border-color: ${theme.colors.primary[400]};
    background-color: ${theme.colors.primary[50]};

    i {
      color: ${theme.colors.primary[400]};
    }
  }
`

export default ContactSync
