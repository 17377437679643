import { setPropertyType } from 'modules/bds'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { SelectItem } from './BdsFilterStyle'
import { propertyFilterItem } from 'lib/utils/bds'
import { isEqual, sortBy } from 'lodash'
import React, { useCallback } from 'react'

/**
 * 주택부동산
 * 필터 모달 - 매물유형 아이템 선택
 */
const BdsContentFilterPropertyTypeItems = ({ type }) => {
  const dispatch = useDispatch()
  const selectedFilter = useSelector(
    (state) => state.bds.selectedFilter,
  )

  const isAllActive = (type) => {
    return isEqual(
      sortBy(selectedFilter.propertyType[type]),
      sortBy(propertyFilterItem[type]),
    )
  }

  /**
   * 각 필터 클릭
   * @param {*} type
   * @param {*} val
   */
  const onClick = useCallback(
    (type, val) => {
      const propertyTypes = selectedFilter.propertyType[type]

      if (isAllActive(type)) {
        const updateVal = {
          [type]: [val],
        }
        dispatch(setPropertyType(updateVal))
      } else {
        if (propertyTypes.includes(val)) {
          const updateVal = {
            [type]: propertyTypes.filter((item) => item != val),
          }
          dispatch(setPropertyType(updateVal))
        } else {
          const updateVal = {
            [type]: [...new Set([...propertyTypes, ...[val]])],
          }
          dispatch(setPropertyType(updateVal))
        }
      }
    },
    [selectedFilter?.propertyType[type]],
  )

  /**
   * 전체 선택
   */
  const onClickAll = useCallback(() => {
    if (
      selectedFilter.propertyType[type].length ==
      propertyFilterItem[type].length
    ) {
      const updateVal = {
        [type]: [],
      }
      dispatch(setPropertyType(updateVal))
    } else {
      const updateVal = {
        [type]: propertyFilterItem[type],
      }
      dispatch(setPropertyType(updateVal))
    }
  }, [selectedFilter?.propertyType[type]])

  return (
    <>
      <SelectItem onClick={onClickAll} active={isAllActive(type)}>
        전체
      </SelectItem>
      {propertyFilterItem[type].map((item, i) => {
        return (
          <SelectItem
            active={
              !isAllActive(type) &&
              selectedFilter.propertyType[type].includes(item)
            }
            onClick={() => onClick(type, item)}
            key={`property_item_${type}_${i}`}
          >
            {item}
          </SelectItem>
        )
      })}
    </>
  )
}

export default React.memo(BdsContentFilterPropertyTypeItems)
