import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDonghoHasDanjiContainer from 'containers/notev2/NoteDonghoHasDanjiContainer'
import React, { Suspense, useMemo } from 'react'
import { Skeleton } from 'antd'

const NoteDonghoHasDanji = ({ searchRefresh }) => {
  const NoteDonghoHasDanjiList = useMemo(
    () => React.lazy(() => import('./NoteDonghoHasDanjiList')),
    [],
  )

  return (
    <Wrapper>
      <NoteDonghoHasDanjiContainer searchRefresh={searchRefresh}>
        {({
          list,
          plusDanjiCount,
          onClickAddPlus,
          onClickDelete,
          loadTargetRef,
        }) => {
          return (
            <>
              <TitleWrapper>
                <Title>관심 단지 및 지역</Title>
                <PlusCount>플러스단지 {plusDanjiCount}/1</PlusCount>
              </TitleWrapper>

              <Suspense fallback={<Skeleton active />}>
                <NoteDonghoHasDanjiList
                  ref={loadTargetRef}
                  data={list}
                  onClickDelete={onClickDelete}
                  onClickAddPlus={onClickAddPlus}
                />
              </Suspense>
            </>
          )
        }}
      </NoteDonghoHasDanjiContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  flex-grow: 1;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`

const Title = styled.div`
  color: ${theme.colors.gray[800]};
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
`

const PlusCount = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
`

export default NoteDonghoHasDanji
