import {
  excludeHoPatters,
  normalFloorPatterns,
  normalHoPatterns,
} from 'components/jmapnotev2/dongho/donghoRegx'
import useDonghoColors from 'lib/hooks/notev2/useDonghoColors'
import useIsMobile from 'lib/hooks/useIsMobile'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import theme from 'lib/styles/theme'
import {
  blockColors,
  coloringPurposes,
  exclusiveColorLevel,
  exclusiveLevel,
  purposeColors,
} from 'lib/utils/notev2/dongho'
import {
  debounce,
  difference,
  flatten,
  groupBy,
  inRange,
  max,
  maxBy,
  min,
  minBy,
  pickBy,
  range,
  sortBy,
  uniq,
} from 'lodash'
import {
  setBlockSize,
  setCenteredDongs,
  setSelectedDanjiDongs,
  setSetSelectedDanjiDongsDot,
} from 'modules/noteDongho'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import tinygradient from 'tinygradient'

/**
 * 동호관리
 * 블럭 그리기
 */
const NoteDonghoBlocksContainer = ({ children }) => {
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  const selectedDong = useSelector(
    (state) => state.noteDongho.selectedDong,
  )
  const activeTab = useSelector((state) => state.noteDongho.activeTab)
  const [undergroundData, setUndergroundData] = useState(null) // 지상 동호 데이터
  const [groundData, setGroundData] = useState(null) // 지하 동호 데이터
  const [dongs, setDongs] = useState(null) //동
  const [legend, setLegend] = useState(null) //범례
  const [blockData, setBlockData] = useState(null) //네모 블럭 데이터(색 X)
  const [normalizationData, setNormalizationData] = useState(null) //정규화 완료 데이터(1차 가공)
  const [sectionSizes, setSectionSizes] = useState(null) //블럭 개수에 따른 섹션의 넓이, 높이
  const [minMaxRoomAndFloor, setMinMaxRoomAndFloor] = useState(null) //최소, 최대 호수, 층수
  const [matchHoColors, setMatchHoColors] = useState(null) //블럭 색상 match object
  const [isFourLineNotExist, setIsFourLineNotExist] = useState(false)
  const [anotherLegend, setAnotherLegend] = useState(null)

  const { getColors } = useDonghoColors()

  useEffect(() => {
    if (!minMaxRoomAndFloor) return
    const resizeFn = debounce(calcBlockSize, 200)
    if (!isMobile) {
      window.removeEventListener('resize', resizeFn)
      return
    }

    window.addEventListener('resize', resizeFn)

    calcBlockSize()
    return () => window.removeEventListener('resize', resizeFn)
  }, [isMobile, minMaxRoomAndFloor])

  useEffect(() => {
    if (!selectedDanji?.id || !selectedDanji?.dongs) return

    const dongsData = getDongsData(selectedDanji.dongs)

    const dongArr = Object.keys(dongsData)
    //1차 가공
    setDongs(dongArr) //동
    dispatch(setSelectedDanjiDongs(dongArr))
    getHasCustomDongs(dongsData)

    const formattedData = makeFloorAndHoInFormat({
      data: dongsData,
      dongs: dongArr,
    }) //층, 호를 같은 포맷으로 정규화

    setNormalizationData(formattedData) //정규화(1차 가공) 완료 데이터

    return () => {
      reset()
    }
  }, [selectedDanji?.id])

  /**
   * 모든 데이터 리셋
   */
  const reset = useCallback(() => {
    setLegend(null)
    setBlockData(null)
    setNormalizationData(null)
    setSectionSizes(null)
    setMinMaxRoomAndFloor(null)
    setDongs(null)
    setGroundData(null)
    setUndergroundData(null)
  }, [])

  /**
   * 동 데이터 필터링
   */
  const getDongsData = useCallback((pDongs) => {
    return Object.fromEntries(
      Object.entries(pDongs).filter(([dong, donghos]) => {
        return (
          dong &&
          donghos.length > 0 &&
          donghos.filter(({ ho_name }) => ho_name).length > 0 &&
          donghos.filter(
            ({ ho_name }) =>
              ho_name &&
              (excludeHoPatters.onlyString.test(ho_name) ||
                excludeHoPatters.frontAddedEnglish.test(ho_name) ||
                excludeHoPatters.withBarSimple.test(ho_name)),
          ).length != donghos.length //호실이 모두 한글이름인 동은 그리지 않는다
        )
      }),
    )
  }, [])

  /**
   * 모바일 - 동 드롭다운 dot 표시
   */
  const getHasCustomDongs = (data) => {
    const result = Object.entries(data).map(([dong, donghos]) => {
      return [
        dong,
        donghos?.filter(
          ({ has_memo, have_notes, is_favorite, have_ads }) =>
            has_memo == 'Y' ||
            have_notes == 'Y' ||
            is_favorite == 'Y' ||
            have_ads == 'Y',
        ).length,
      ]
    })

    dispatch(setSetSelectedDanjiDongsDot(Object.fromEntries(result)))
  }

  /**
   * 정규화(1차 가공) 후 블럭 그리기
   */
  useEffect(() => {
    if (!normalizationData || !dongs) return

    getAnotherLegend(normalizationData) // 아파트외 범례 미리 만들기
    makeBlockData(dongs, normalizationData)
  }, [normalizationData])

  /**
   * 블럭 만든 후 유동 범례 만들기
   */
  useEffect(() => {
    if (!blockData) return

    makeFlexibleLegend({ data: blockData })
  }, [blockData])

  /**
   * 탭이 바뀔 때 유동 범례 만들기
   */
  useUpdateEffect(() => {
    makeFlexibleLegend({ data: blockData })
  }, [activeTab])

  /**
   * 범례 만든것으로 블럭 색상 object 만들기
   */
  useEffect(() => {
    if (!legend) return

    makeColoringData()
  }, [legend])

  /**
   * 탭이 3이 아닌 경우만 유동적 범례 데이터 만들기
   * 탭이 3이면 바로 블럭 색상 object 만들러감
   * @param data
   */
  const makeFlexibleLegend = useCallback(
    ({ data }) => {
      if (activeTab == 1) {
        //탭1은 특수 로직 적용
        makeLegendForArea()
      } else {
        if (activeTab != 3) {
          makeLegend({
            data,
          })
        } else {
          makeColoringData()
        }
      }
    },
    [activeTab, blockData],
  )

  /**
   * 매칭되는 블럭 색상 object 만들기
   */
  const makeColoringData = useCallback(() => {
    const coloringCompletedData = coloringBlocks({
      data: blockData,
    })

    setMatchHoColors(coloringCompletedData)
  }, [blockData, legend, activeTab, blockColors])
  /**
   * 한 층의 호실 수 에 따라 가로로 한 번에 보일 수 있는 넓이 설정
   */
  useEffect(() => {
    if (!minMaxRoomAndFloor) return
    //pc

    const sizes = Object.fromEntries(
      Object.entries(minMaxRoomAndFloor).map(([dong, values]) => {
        //개별 동에 4호가 있는지
        const hasNumberFourHo =
          Object.values(blockData[dong])
            .flat()
            .filter(({ ho_name }) => {
              return (
                isNumeric(ho_name) &&
                (String(ho_name)?.padStart(2, '0') == '04' ||
                  String(ho_name).substr(-2) == '04')
              )
            }).length > 0

        //지하 있으면 지하 높이도 추가
        const hasUnderground = values.minFloor < 0
        const undergroundHeight = hasUnderground
          ? Math.abs(values.minFloor) * 30 + Number(values.maxFloor) * 5
          : 0

        return [
          dong,
          {
            width:
              Number(Math.abs(values.maxRoom)) * 60 +
              Number(Math.abs(values.maxRoom)) * 5 -
              //4호가 있는 동의 라인은 블럭 1개만큼 뺀다. 대신 최대 호실이 4를 넘어야 함
              (hasNumberFourHo == false &&
              isFourLineNotExist &&
              values.maxRoom > 4
                ? 62
                : 0) + //4호가 있는 동은 4호 블럭만큼 뺌
              1,
            height:
              Number(Math.abs(values.maxFloor)) * 30 +
              Number(Math.abs(values.maxFloor)) * 5 +
              undergroundHeight +
              8,
          },
        ]
      }),
    )

    setSectionSizes(sizes)
  }, [minMaxRoomAndFloor])

  /**
   * 층, 호 사전 정의한 포맷으로 데이터 만들기
   * @param dongs 동 array
   */
  const makeFloorAndHoInFormat = useCallback(({ data, dongs }) => {
    const result = dongs.map((dong) => {
      //호가 문자열인 경우, 영문자 + 숫자인 경우 무시
      const hoDatas = data[dong].filter(
        ({ ho_name }) =>
          !excludeHoPatters.onlyString.test(ho_name) &&
          !excludeHoPatters.frontAddedEnglish.test(ho_name),
      )

      return {
        [dong]: hoDatas.map((item) => {
          let isUnderAllFloor = false //전체를 채울지(지하)

          const ho = makeHo(
            item.floor_name,
            item.floor_number,
            item.ho_name,
          ) //호 포맷하기
          const floor = makeFloor(
            item.floor_name,
            item.floor_number,
            ho,
            item.purpose,
          ) //층 포맷하기

          return {
            ...item,
            ho_name: ho,
            original_ho_name: item.ho_name,
            floor_name: floor,
            isUnderAllFloor: isUnderAllFloor,
          }
        }),
      }
    })

    return Object.assign({}, ...result)
  }, [])

  /**
   * 블럭 데이터 만들기
   * @param {*} dongs 동 array
   * @param {*} normalizationData 1차 가공 끝난 데이터
   * @returns
   */
  const makeBlockData = useCallback((dongs, normalizationData) => {
    const minMaxRoom = getMinMaxRooms(dongs, normalizationData) //최소, 최대 호실
    const minMaxFloor = getMinMaxFloors(dongs, normalizationData) //최소, 최대 층(지하가 없으면 최소는 1)
    //2차 가공 - 최소,최대 층수와 호실수를 가지고 모든 블럭 만들기
    const allBlockData = makeAllBlockData({
      dongs,
      minMaxRoom,
      minMaxFloor,
      normalizationData,
    })

    //3차 가공 - 데이터가 있는 호실을 체크하여 빈 칸 여부 세팅하기
    const withEmptyBlockData = settingEmptyBlock({
      dongs,
      allBlockData,
      data: normalizationData,
    })

    //4차 가공 - 없는 호실 라인 제거
    const removeNotExistHoData = removeNotExistHo({
      dongs,
      minMaxRoom,
      blockData: withEmptyBlockData,
      originalData: normalizationData,
    })

    //5차 가공 - 필로티 표기
    const addedPilotiData = settingPiloti({
      data: removeNotExistHoData,
      minMaxRoom,
      minMaxFloor,
    })

    settingFinalData(addedPilotiData)

    setBlockData(addedPilotiData)

    setMinMaxRoomAndFloor(
      Object.fromEntries(
        Object.entries(minMaxRoom).map(([dong, roomMinMaxs]) => {
          return [
            dong,
            {
              minRoom: roomMinMaxs.min,
              maxRoom: roomMinMaxs.max,
              minFloor: minMaxFloor[dong].min,
              maxFloor: minMaxFloor[dong].max,
            },
          ]
        }),
      ),
    )
  }, [])

  /**
   * 최종 지상, 지하 데이터 세팅
   * @param data 최종 데이터
   */
  const settingFinalData = useCallback((data) => {
    //지상 동호
    const ground = Object.entries(data).map(([dong, donghos]) => {
      return [dong, pickBy(donghos, (value, key) => key > 0)]
    })

    //지하 동호
    const underground = Object.entries(data).map(([dong, donghos]) => {
      return [dong, pickBy(donghos, (value, key) => key < 0)]
    })

    //지하 한 번 더sort
    const sortedUnderground = underground.map(([dong, donghos]) => {
      return [
        dong,
        Object.fromEntries(
          Object.entries(donghos).map(([floor, hos]) => {
            const copyHos = [...hos].map((copyItem) => {
              return {
                ...copyItem,
                slice_ho_name: copyItem?.slice_ho_name?.padStart(
                  2,
                  '0',
                ),
              }
            })

            const sorted = sortBy(copyHos, 'slice_ho_name')

            const result = sorted.map((hoItem) => {
              return {
                ...hoItem,
                ho_name: copyHos.find(
                  ({ ho_name }) => ho_name == hoItem.ho_name,
                ).ho_name,
              }
            })

            return [floor, result]
          }),
        ),
      ]
    })

    //어느 동에도 지하 층은 없음
    const isNothingUnderground =
      Object.values(Object.fromEntries(underground)).filter(
        (item) => Object.keys(item).length > 0,
      ).length == 0

    //지하층이 있는 동만 데이터 세팅
    const filterdUndergroundData = Object.fromEntries(
      Object.entries(Object.fromEntries(sortedUnderground)).map(
        ([dong, donghos]) => {
          return [
            dong,
            Object.keys(donghos).length > 0 ? donghos : null,
          ]
        },
      ),
    )

    //지하
    setUndergroundData(
      isNothingUnderground ? null : filterdUndergroundData,
    )
    //지상
    setGroundData(Object.fromEntries(ground))

    console.log(
      '최종',
      Object.fromEntries(ground),
      isNothingUnderground ? null : filterdUndergroundData,
    )
  }, [])

  /**
   * 아파트외 범레 만들기
   */
  const getAnotherLegend = (data) => {
    const anotherPurpose = Object.values(data)
      .flat()
      .filter(({ purpose }) =>
        Object.keys(purposeColors).includes(purpose),
      )
      .map(({ purpose }) => purpose)

    // 공급면적을 컬러링 할 수 없으면 기타로 표시, legend도 추가
    const supplyAreas = selectedDanji.types.map(
      ({ pyeong_name }) => pyeong_name,
    )
    const filteredNoMatching = Object.values(data)
      .flat()
      .filter(
        ({ n_supply_area, n_supply_name }) =>
          !supplyAreas.includes(n_supply_area) &&
          !supplyAreas.includes(n_supply_name),
      )

    const exceptLegend = ['기타']
    let result

    if (filteredNoMatching.length > 0) {
      result = [...anotherPurpose, ...exceptLegend]
    } else {
      result = anotherPurpose
    }

    setAnotherLegend(uniq(result))
  }

  /**
   * 블럭 색칠하기
   * @param data
   * @returns
   */
  const coloringBlocks = useCallback(
    ({ data }) => {
      const result = Object.entries(data).map(([dong, donghos]) => {
        const coloringData = Object.fromEntries(
          Object.entries(donghos).map(([floor, hos]) => {
            return [
              floor,
              Object.fromEntries(
                hos.map((hoItem) => {
                  let matchColor

                  if (!coloringPurposes.includes(hoItem?.purpose)) {
                    matchColor =
                      purposeColors?.[hoItem?.purpose] ||
                      purposeColors['기타']
                  } else {
                    if (activeTab == 1) {
                      //평형정보
                      matchColor =
                        legend.find(
                          ({ value }) => value == hoItem?.n_supply_name,
                        )?.color || ''
                    } else if (activeTab == 2) {
                      //공시가격
                      const goal = hoItem.price

                      matchColor = goal
                        ? legend.find(
                            ({ value }) =>
                              value == closest(legend, goal),
                          )?.color || ''
                        : ''
                    } else if (activeTab == 3) {
                      //점유형태
                      const goal = hoItem.lease_type
                      const tabColors = blockColors[3]

                      matchColor =
                        tabColors[goal] || tabColors['알수없음']
                    } else if (activeTab == 4) {
                      //계약만기
                      const goal = Number(hoItem.lease_end_date)
                      const tabColors = blockColors[4]

                      matchColor = hoItem.lease_end_date
                        ? legend.find(
                            ({ value }) =>
                              value == closest(legend, goal),
                          )?.color || ''
                        : tabColors[tabColors.length - 1] //데이터 없으면 알 수 없음
                    }
                  }

                  return [hoItem.ho_name, matchColor]
                }),
              ),
            ]
          }),
        )

        return [dong, coloringData]
      })

      return Object.fromEntries(result)
    },
    [blockColors, legend, activeTab, blockData],
  )
  /**
   * 동 별 한 층 당 최대 호실 수 구하기
   * 한 층에 몇 호 까지 있는지를 구하는 것.(마지막 호실 number)
   * @param dongs 동 array
   * @param data 1차 가공 끝난 데이터
   */
  const getMinMaxRooms = useCallback((dongs, data) => {
    const maxRoomCountByDong = dongs.map((dong) => {
      const grouppedByFloor = groupBy(data[dong], 'floor_name')

      const minHoByFloor = Object.values(grouppedByFloor)
        .map((rooms) => {
          return (
            minBy(
              rooms.filter(({ ho_name }) => isNumeric(ho_name)),
              'ho_name',
            )?.ho_name?.substr(-2) || null
          )
        })
        .filter((item) => item) //

      //지하만 있는 경우를 구분하여 계산
      const onlyUnderground =
        Object.keys(grouppedByFloor).filter((floor) => floor > 0)
          .length == 0

      const maxHoByFloor = Object.values(grouppedByFloor)
        .map((rooms) => {
          return (
            maxBy(
              rooms.filter(({ ho_name, floor_name }) => {
                return (
                  (onlyUnderground ? true : floor_name > 0) &&
                  isNumeric(ho_name)
                )
              }),
              'ho_name',
            )
              ?.ho_name?.padStart(2, '0')
              .substr(-2) || null
          )
        })
        .filter((item) => item)
      const minRoom = min(minHoByFloor)
      const maxRoom = max(maxHoByFloor)

      return {
        [dong]: {
          min: minRoom,
          max: maxRoom,
        },
      }
    })

    return Object.assign({}, ...maxRoomCountByDong)
  }, [])

  /**
   * 동 별 최소/최대 층수 구하기
   * @param dongs 동 array
   * @param data 1차 가공 끝난 데이터
   */
  const getMinMaxFloors = useCallback((dongs, data) => {
    const result = dongs.map((dong) => {
      const grouppedByFloor = groupBy(data[dong], 'floor_name')
      const floors = Object.keys(grouppedByFloor).map(Number)

      return {
        [dong]: {
          min: min(floors) < 0 ? min(floors) : 1,
          max: max(floors),
        },
      }
    })

    return Object.assign({}, ...result)
  }, [])

  /**
   * 최소,최대 층 수와 호실 수를 가지고 모든 블럭 만들기
   * @param dongs 동
   * @param minMaxRoom 최소/최대 호실
   * @param minMaxFloor 최소/최대 층
   */
  const makeAllBlockData = useCallback(
    ({ dongs, minMaxRoom, minMaxFloor, normalizationData }) => {
      const result = dongs.map((dong) => {
        const floors = minMaxFloor[dong]
        const rooms = minMaxRoom[dong]

        return {
          [dong]: makeBlockDataWithRange({
            floors,
            rooms,
            normalizationData,
            dong,
          }),
        }
      })

      return Object.assign({}, ...result)
    },
    [],
  )
  /**
   * 동 별로 모든 블럭 만들기
   * @param floors 동별 층
   * @param rooms 동별 방
   */
  const makeBlockDataWithRange = useCallback(
    ({ floors, rooms, normalizationData, dong }) => {
      const result = range(floors.min, Number(floors.max) + 1).map(
        (floor) => {
          //0층은 없다
          if (floor == 0) return null
          return {
            [floor]: range(rooms.min, Number(rooms.max) + 1).map(
              (room) => {
                if (floor < 0) {
                  //지하일 때 층이랑 호가 매칭이 안되면... 호를 그대로 표시
                  //ex) 층: 지하1층, 호실: 7201호
                  let undergroundHoName
                  const originFloorMatchData = normalizationData[
                    dong
                  ].filter(({ floor_name }) => floor_name == floor)

                  const hoNameFormat =
                    String(Math.abs(floor)) +
                    String(room).padStart(2, '0')

                  const matchHos = originFloorMatchData.filter(
                    ({ ho_name }) => {
                      return ho_name == hoNameFormat
                    },
                  )
                  if (matchHos.length > 0) {
                    //호 이름이 정상적임(층+호)
                    undergroundHoName = hoNameFormat
                  } else {
                    //호 이름이 비정상임
                    undergroundHoName =
                      originFloorMatchData.find(({ ho_name }) => {
                        return (
                          String(ho_name).padStart(2, '0').substr(-2) ==
                          String(room).padStart(2, '0')
                        )
                      })?.ho_name || hoNameFormat
                  }

                  return {
                    ho_name: undergroundHoName,
                  }
                }

                return {
                  ho_name:
                    String(floor) + String(room).padStart(2, '0'),
                }
              },
            ),
          }
        },
      )
      return Object.assign({}, ...result)
    },
    [],
  )

  /**
   * 실제 데이터와 모든 블럭 데이터를 비교.
   * 데이터가 있는 호실을 체크하여 빈 칸 여부 세팅하기
   * @param dongs 동 array
   * @param allBlockData 모든 블럭
   * @param data 1차 가공 끝난 데이터
   */
  const settingEmptyBlock = useCallback(
    ({ dongs, allBlockData, data }) => {
      const result = dongs.map((dong) => {
        const fullData = allBlockData[dong]
        const validData = data[dong]

        const grouppedByFloor = groupBy(validData, 'floor_name')

        return {
          [dong]: fillEmptyRooms({ fullData, grouppedByFloor }),
        }
      })

      return Object.assign({}, ...result)
    },
    [],
  )
  /**
   * 동 별로 빈 블럭 여부 세팅하기
   * @param fullData 동,호 전체 데이터(임시로 만든 전체 블럭 데이터)
   * @param grouppedByFloor 아파트 층별로 묶은 데이터(실제 데이터)
   */
  const fillEmptyRooms = useCallback(
    ({ fullData, grouppedByFloor }) => {
      const result = Object.entries(fullData).map(([floor, allHos]) => {
        const validFloorRooms = grouppedByFloor[floor]

        //층이 없으면.. 모두 empty
        const validFloorHos =
          validFloorRooms?.map(({ ho_name }) =>
            String(ho_name).trim(),
          ) || []

        const matchHo = allHos.map((ho) => {
          const building_dongho_id =
            validFloorRooms?.find(
              ({ ho_name }) => ho_name.trim() == ho.ho_name,
            )?.building_dongho_id || null

          const target = validFloorRooms?.find(
            ({ ho_name }) => ho_name.trim() == ho.ho_name,
          )

          const addColumns = {
            purpose: target?.purpose,
            n_supply_name:
              target?.n_supply_name || target?.n_supply_area,
            price: target?.price,
            lease_type: target?.lease_type,
            lease_end_date: target?.lease_end_date,
            have_ads: target?.have_ads,
            have_notes: target?.have_notes,
            has_memo: target?.has_memo,
            is_favorite: target?.is_favorite,
            original_ho_name: target?.original_ho_name,
            exclusive_area: target?.exclusive_area,
          }

          //비었는지 여부 - 지하는 뒤 두 글자를 잘라서도 판단해야 함
          const is_empty =
            floor < 0
              ? !validFloorHos.includes(ho.ho_name) &&
                !validFloorHos.includes(String(ho.ho_name).substr(-2))
              : !validFloorHos.includes(ho.ho_name)

          return {
            ...ho,
            is_empty,
            building_dongho_id,
            ...addColumns,
          }
        })

        return {
          [floor]: matchHo,
        }
      })

      const objResult = Object.assign({}, ...result)
      return objResult
    },
    [],
  )

  /**
   * 존재하지 않는 호실 제거
   * @param dongs 동 array
   * @param minMaxRoom 최소/최대 호실
   * @param blockData 빈 칸 여부 까지 세팅된 데이터
   * @param originalData 원본 동/호 데이터
   */
  const removeNotExistHo = useCallback(
    ({ dongs, minMaxRoom, blockData, originalData }) => {
      const result = dongs.map((dong) => {
        const grouppedByFloor = groupBy(
          originalData[dong],
          'floor_name',
        ) //동으로 층별 group by
        const minHo = String(minMaxRoom[dong].min)
        const maxHo = String(minMaxRoom[dong].max)
        const removeHos = getNotExistHo({
          dong,
          grouppedByFloor,
          minHo,
          maxHo,
        })

        const dongBlocks = blockData[dong]
        if (
          (!setIsFourLineNotExist && removeHos.includes(4)) ||
          removeHos.includes('04')
        ) {
          setIsFourLineNotExist(true)
        }
        return {
          [dong]: removeHoByDong({ data: dongBlocks, removeHos }),
        }
      })

      return Object.assign({}, ...result)
    },
    [],
  )

  /**
   * 존재하지 않는 호실 찾기
   * @param grouppedByFloor 그룹핑 된 층(블럭으로 만든 데이터 말고 원래 데이터로 만든 것)
   * @param minHo 최소 호실 숫자 뒷자리
   * @param maxHo 최대 호실 숫자 뒷자리
   * @returns removeHos 숫자에 0 붙인 2자리 string array
   */
  const getNotExistHo = useCallback(
    ({ minHo, maxHo, grouppedByFloor }) => {
      const allRangeRooms = range(minHo, maxHo)
      const validRooms = uniq(
        Object.values(grouppedByFloor)
          .flat()
          .map(({ ho_name }) => ho_name?.substr(-2) || null),
      ).map(Number)
      const removeHos = difference(allRangeRooms, validRooms).map(
        (number) => {
          return String(number).padStart(2, '0')
        },
      )
      return removeHos
    },
    [],
  )
  /**
   * 동 별 존재하지 않는 호실 제거
   * @param data 동 블럭 데이터
   * @param removeHos 동 별 삭제할 호실 데이터 arr
   */
  const removeHoByDong = useCallback(({ data, removeHos }) => {
    const result = Object.entries(data).map(([floor, values]) => {
      const removedHo = values.filter(({ ho_name }) => {
        return !removeHos.includes(String(ho_name).substr(-2))
      })
      return {
        [floor]: removedHo,
      }
    })

    return Object.assign({}, ...result)
  }, [])

  /**
   * 필로티 여부 세팅
   * @param data
   */
  const settingPiloti = useCallback(({ data }) => {
    const result = Object.entries(data).map(([dong, donghos]) => {
      const groupBylastHo = groupBy(
        Object.entries(donghos)
          .map(([floor, hos]) => {
            return hos.map((item) => {
              return {
                ...item,
                floor: floor,
                slice_ho_name: String(item.ho_name).slice(-2),
              }
            })
          })
          .flat(),
        'slice_ho_name',
      )

      const addedPilotiState = Object.values(groupBylastHo).map(
        (lineArr) => {
          let pilotiIdxs = []

          return lineArr.map((hoItem, i) => {
            let isPiloti = false
            let isNothing = false

            //필로티
            if (i == 0) {
              //맨 아래층, 현재 호가 비었으면 필로티
              if (hoItem.is_empty) {
                isPiloti = true
                pilotiIdxs.push(i)
              }
            } else {
              if (
                groupBylastHo[i - 1]?.is_empty &&
                groupBylastHo[i + 1] &&
                !groupBylastHo[i + 1]?.is_empty
              ) {
                //이전층이 비었고 위 층이 막혀있으면 필로티
                isPiloti = true
                pilotiIdxs.push(i)
              } else if (
                pilotiIdxs.includes(i - 1) &&
                hoItem.is_empty
              ) {
                //이전층이 필로티이고 현재 호가 비었으면 플로티
                isPiloti = true
                pilotiIdxs.push(i)
              } else {
                isPiloti = false
              }
            }

            //위층에 호가 아예 없으면 블럭 흰색(없음)으로 표시, 지하 제외
            if (hoItem.floor > 0 && hoItem.is_empty) {
              let upstairs = []

              for (let j = i; j < lineArr.length - i + 1 + i; j++) {
                if (
                  lineArr[j] &&
                  !lineArr[j].is_empty &&
                  lineArr[j].floor > 0
                ) {
                  upstairs.push(j)
                }
              }
              if (upstairs.length == 0) {
                isNothing = true
              }
            }

            return {
              ...hoItem,
              is_piloti: isPiloti,
              is_nothing: isNothing,
            }
          })
        },
      )

      const flattenArr = flatten(addedPilotiState)
      const sortedArr = sortBy(flattenArr, function (obj) {
        return parseInt(obj.ho_name, 10)
      })
      return [dong, groupBy(sortedArr, 'floor')]
    })

    return Object.fromEntries(result)
  }, [])

  function isNumeric(str) {
    if (typeof str != 'string') return false // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ) // ...and ensure strings of whitespace fail
  }

  /**
   * 1.
   * 층 데이터를 그대로 사용할 수 있을지 판단
   * 가능한 포맷: [숫자]층, 지하[숫자]층, 지하[숫자], B[숫자]층
   * > 층을 참고할 수 있으면 층 데이터 유지
   * > 층 데이터가 없는 것은 호의 숫자를 잘라서 확인
   * @param floor_name
   * @param ho_name
   * @param purpose
   * @returns {string|number} floor
   */
  const makeFloor = useCallback(
    (floor_name, floor_number, ho_name, purpose) => {
      const isMatch = normalFloorPatterns.find((regx) => {
        return regx.test(floor_name)
      })

      const hoInsteadOfFloor = /^[0-9]+호$/

      if (isMatch) {
        const formattedFloor = floor_name
          .replace(/층\s*$/, '')
          .replace(/^지하/, '-')
        if (formattedFloor != floor_number) {
          return floor_number
        } else {
          return formattedFloor
        }
      } else if (floor_name == '지하' || floor_name == '지층') {
        return '-1'
      } else if (hoInsteadOfFloor.test(floor_name)) {
        return floor_name.replace('호', '층').replace(/층\s*$/, '')
      } else {
        //  층 데이터가 정상적이지 않으면 호 앞글자를 뗀다
        //숫자인 경우만...
        if (isNumeric(ho_name)) {
          const length = String(ho_name).length

          if (length == 3) {
            //101호 => 1층
            return ho_name.substr(0, 1)
          } else if (length == 4) {
            //1001호 => 10층
            return ho_name.substr(0, 2)
          }
        } else {
          console.log('헐', floor_name, ho_name)
          return 'exception'
        }
      }
    },
    [],
  )

  /**
   * 2.
   * 호 포맷
   * 정 쓸 수 없는 데이터는 그냥 포기
   * @param ho_name
   * @param floor_name
   * * TODO: 포맷 추가
   */
  const makeHo = useCallback((floor_name, floor_number, ho_name) => {
    const {
      simpleHo,
      stringAndHoAndString,
      stringAndHo,
      undergroundHo,
      undergroundHoOnlyString,
      withHo,
      withBar,
      withBarSimple,
      withFloorAndManyLetter,
    } = normalHoPatterns

    let ho

    //호
    if (simpleHo.test(ho_name)) {
      //정상적인 포맷
      ho = ho_name
    } else if (withBarSimple.test(ho_name)) {
      //102-1호 같은건 사전 차단
      console.log('헐...102-1호 같은거 있음', ho)
      ho = 'exception'
    } else if (withFloorAndManyLetter.test(ho_name)) {
      //n층제nnn호
      const replaceLetterRegx = /^[0-9]{1,2}층+제/
      ho = ho_name.replace(replaceLetterRegx, '')
    } else if (
      stringAndHoAndString.test(ho_name) ||
      stringAndHo.test(ho_name) ||
      undergroundHo.test(ho_name)
    ) {
      //문자열이 붙은 경우
      if (ho_name.startsWith('B')) {
        if (floor_name?.startsWith('지하')) {
          const startNumberIdx = findindex(ho_name)
          const sliceHo = ho_name.slice(startNumberIdx)
          if (sliceHo.length < 3) {
            ho =
              String(Math.abs(floor_number)) +
              String(sliceHo).padStart(2, '0')
          } else {
            ho = ho_name.slice(startNumberIdx)
          }
        } else {
          //호 문자열 앞에 B가 붙었는데 층이 지하인지 알 수 없는 경우 무시
          return null
        }
      } else if (floor_name == '지하') {
        //층이 '지하' 문자열인 경우 지하 1층으로 처리
        const startNumberIdx = findindex(ho_name)
        const sliceHo = ho_name.slice(startNumberIdx)
        const stringRegx = /[^0-9]/g
        const hoOnlyNumber = sliceHo.replace(stringRegx, '')
        if (hoOnlyNumber.length < 3) {
          ho = '1' + String(hoOnlyNumber).padStart(2, '0')
        } else {
          ho = '1' + ho_name.slice(startNumberIdx)
        }
      } else {
        const startNumberIdx = findindex(ho_name)
        ho = ho_name.slice(startNumberIdx)
      }
    } else if (withBar.test(ho_name)) {
      //막대기가 붙은 경우
      ho = ho_name.split('-')[1]
    } else if (undergroundHoOnlyString.includes(ho_name)) {
      //지층인 경우
      //층 컬럼이 지하 몇 층이라는 것이 분명하면 지하 전체로 볼 것
      if (/^지하+[0-9]+층*$/.test(floor_name)) {
        ho = ''
        isUnderAllFloor = true
      }
    } else if (
      withHo.test(ho_name) &&
      ho_name.indexOf(floor_name) > -1 &&
      ho_name.startsWith(floor_name)
    ) {
      //호에 층수가 붙어있을때
      const replaceHo = ho_name.replace(floor_name, '')
      const stringRegx = /[^0-9]/g
      const hoOnlyNumber = replaceHo.replace(stringRegx, '')

      if (hoOnlyNumber.length > 2) {
        //호가 3자리 이상이면 101호, 2001호 이런 형태일테니 그냥 층만 없애줌
        ho = ho_name.replace(floor_name, '')
      } else {
        //1자리, 2자리인 경우는 1호, 23호 이런식이기 때문에 치환해줌
        ho =
          String(Math.abs(floor_number)) +
          String(hoOnlyNumber).padStart(2, '0')
      }
    } else {
      //그 외의 호 포맷은 다 무시한다
      console.log('헐.....', ho)
      ho = 'exception'
    }

    return ho.replace(/호\s*$/, '')
  }, [])

  /**
   * 탭1(평형정보)의
   * 범례 구하기
   * @param data 최종 데이터(지상, 지하 나누기 전)
   */
  const makeLegendForArea = useCallback(() => {
    //전용면적 별 기준 컬러 구하기
    const legendWithColor = selectedDanji.types.map(
      ({ pyeong_name, exclusive_area }) => {
        let colorLevel

        const levelRange = Object.entries(exclusiveLevel)

        for (let i = 0; i < levelRange.length; i++) {
          const level = levelRange[i][0]
          const range = levelRange[i][1]
          if (inRange(Number(exclusive_area), range[0], range[1])) {
            colorLevel = level
            break
          }
          if (Number(exclusive_area) >= range[0]) {
            colorLevel = level
          }
        }

        return {
          value: pyeong_name,
          color: exclusiveColorLevel[colorLevel],
        }
      },
    )

    //기준 컬러로 그라데이션 팔레트 구하기.
    //흰색부터 같은 전용면적 개수만큼 그라데이션 함
    const groupbyColor = groupBy(legendWithColor, 'color')

    const randomColorRange = Object.entries(groupbyColor).map(
      ([hex, arr]) => {
        let divideLength
        //전용면적 구분이 몇 개 없을 때에는 색 연한색으로 중화
        if (arr.length < 4) {
          divideLength = arr.length + 5
        } else {
          divideLength = arr.length + 1
        }

        const randomArr = tinygradient(['#ffffff', hex]).rgb(
          divideLength,
        ) //흰색부터 그라데이션 array

        const randomArrToHex = randomArr.map((item) => {
          return item.toHexString()
        }) //hex로 변환
        randomArrToHex.shift() //흰색 제거

        return [hex, randomArrToHex]
      },
    )

    const randomColorRangeMap = Object.fromEntries(randomColorRange)

    //그라데이션 순서를 맞추기 위해 공급면적으로 정렬
    const sortedResult = sortLegend(legendWithColor)

    //그라데이션 컬러를 순서대로 매칭
    const matchColorMap = Object.entries(randomColorRangeMap).map(
      ([baseColor, randomsArr]) => {
        const filterColors = sortedResult.filter(
          ({ color }) => color == baseColor,
        )

        const colorResultArr = filterColors.map(({ value }, i) => {
          return {
            value,
            color: randomsArr[i],
          }
        })
        return colorResultArr
      },
    )

    //매칭하느라 순서 엉켜서 다시 공급면적으로 정렬
    const matchColorSort = sortLegend(matchColorMap.flat())

    setLegend(matchColorSort)
  }, [activeTab, getColors])

  const sortLegend = (data) => {
    //평형만 뽑기(정렬을 위해)
    const extractNumber = data.map((item) => {
      return item.value
    })
    //숫자+문자 정렬을 도와줌
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    })
    const sorted = extractNumber.sort(collator.compare)

    return sorted.map((areaVal) => {
      return {
        value: areaVal,
        color: data.find(({ value }) => value == areaVal).color,
      }
    })
  }

  /**
   * 범례 구하기
   * @param data 최종 데이터(지상, 지하 나누기 전)
   */
  const makeLegend = useCallback(
    ({ data }) => {
      //탭 평형정보 일 때 n_supply_name이 범례임
      const groupByKey = {
        2: 'price',
        4: 'lease_end_date',
      }
      const flattenData = Object.values(data)
        .map((item) => {
          return Object.values(item)
        })
        .flat()
        .flat()

      const groupBySupplyName = groupBy(
        flattenData,
        groupByKey[activeTab],
      )

      //탭에 따라 그룹 지을 수 있는 기준 데이터가 하나도 없는 단지인 경우 나가기
      if (
        Object.keys(groupBySupplyName).filter(
          (item) => item && item != 'undefined' && item != 'null',
        ).length == 0
      ) {
        setLegend([])
        return
      }

      //컬러링 1차
      const legendWithColor = getColors({
        data: Object.keys(groupBySupplyName),
      })

      setLegend(legendWithColor)
    },
    [activeTab, getColors],
  )
  /**
   * 숫자 index 위치 구하기
   * @returns
   */
  function findindex(str) {
    var num = /\d/
    var nums = str.match(num)
    return str.indexOf(nums)
  }

  /**
   * 기준 배열에서 가까운 값 찾기
   * @param {*} criteria 기준 배열
   * @param {*} goal 값
   */
  function closest(criteria, goal) {
    return criteria
      .map(({ value }) => value)
      .reduce(function (prev, curr) {
        return Math.abs(curr - goal) <= Math.abs(prev - goal)
          ? curr
          : prev
      })
  }

  /**
   * 터치 디바이스인지 여부
   */
  function isTouchDevice() {
    return window.matchMedia('(pointer: coarse)').matches
  }

  /**
   * hover target이 block인지 여부
   */
  const isBlock = useCallback((target) => {
    return (
      target.childNodes?.[0]?.classList &&
      target.childNodes[0].classList.contains('block-tooltip-target')
    )
  }, [])

  /**
   * block hover event
   */
  const onMouseOver = useCallback((e) => {
    if (isTouchDevice()) return

    const blockTarget = e.target

    if (blockTarget && blockTarget.classList.contains('dongho-block')) {
      if (!isBlock(blockTarget)) return

      const currentTarget = e.currentTarget
      const offsetTop = blockTarget.offsetTop
      const offsetLeft = blockTarget.offsetLeft
      const offsetWidth = blockTarget.offsetWidth
      const targetWidth = currentTarget.offsetWidth
      let offsetRight = targetWidth - offsetLeft - offsetWidth

      if (offsetRight <= 9) {
        //오른쪽 넘어가는 경우
        blockTarget.childNodes[0].classList.add('right')
      }
      if (offsetLeft <= 45) {
        //왼쪽 넘어가는 경우
        blockTarget.childNodes[0].classList.add('left')
      }

      if (offsetTop <= 90) {
        //아래 방향 툴팁
        blockTarget.childNodes[0].classList.add('bottom')
      }
      blockTarget.childNodes[0].classList.add('visible')
    }
  }, [])

  /**
   * block mouseout event
   */
  const onMouseOut = useCallback((e) => {
    if (isTouchDevice()) return

    const blockTarget = e.target
    if (blockTarget && blockTarget.classList.contains('dongho-block')) {
      if (!isBlock(blockTarget)) return

      blockTarget.childNodes[0].classList.remove('visible', 'bottom')
    }
  }, [])

  /**
   * 반응형 뷰포트 별 블럭 넓이 지정
   */
  const calcBlockSize = useCallback(() => {
    const windowWidth = window.innerWidth
    if (windowWidth > theme.bp.mediumS) {
      //Samsung Galaxy Z Fold 를 최대로
      dispatch(setBlockSize(60))
      return
    }

    //나누기 할 수치
    let divideVal

    if (windowWidth <= 414) {
      divideVal = 6.9642
    } else if (windowWidth <= 500) {
      divideVal = 8.0754
    } else {
      divideVal = 10.1403
    }

    const calcedSize = windowWidth / divideVal

    dispatch(setBlockSize(calcedSize))

    //블럭 개수를 가지고 중앙정렬 여부를 세팅
    calcDongIsCentered(windowWidth, calcedSize)
  }, [minMaxRoomAndFloor])

  /**
   * 동을 중앙 정렬 해야 하는지 여부
   */
  const calcDongIsCentered = useCallback(
    (windowWidth, perSize) => {
      const calced = Object.entries(minMaxRoomAndFloor).map(
        ([dong, minMax]) => {
          const calced = perSize * Number(minMax.maxRoom)
          const isCentered = windowWidth > calced

          return [dong, isCentered]
        },
      )

      dispatch(setCenteredDongs(Object.fromEntries(calced)))
    },
    [minMaxRoomAndFloor],
  )

  return children({
    dongList: dongs,
    groundData,
    undergroundData,
    legend,
    anotherLegend,
    sectionSizes,
    activeTab,
    matchHoColors,
    selectedDanji,
    palette: blockColors[activeTab],
    allDataReady:
      dongs && dongs.length > 0 && groundData && sectionSizes,
    onMouseOver,
    onMouseOut,
  })
}

export default React.memo(NoteDonghoBlocksContainer)
