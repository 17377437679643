import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAddressName } from 'lib/utils'

import { saveFavorite } from 'lib/api/favoriteApi'

import useRoadName from './useRoadName'
import { setSummaryInfo } from 'modules/summary'
import Log from 'lib/utils/Log'
import useAxios from 'lib/hooks/useAxios'

export default function useSaveFavorite() {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )
  const formatedRoadName = useRoadName(summaryInfo?.land)

  const [onRequest, loading, data, error, reset] =
    useAxios(saveFavorite)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!data) return

    Log.success('useSaveFavorite', data)

    if (data?.message !== 'success') {
      return
    }

    dispatch(
      setSummaryInfo({
        ...summaryInfo,
        favorite: {
          favorite_id: data.favorite_id,
        },
      }),
    )
  }, [data])

  const _saveFavorite = useCallback(() => {
    const params = {
      address_id: currentAddressId,
    }

    // 지번주소 세팅
    params.address_jibun = getAddressName(summaryInfo.land)
    // 도로명주소 세팅
    params.address_doro = formatedRoadName
    // 거래 유형 세팅
    let property_type = ''

    if (summaryInfo.building?.result?.[0]) {
      property_type = building?.result?.[0]?.custom_purpose || ''
    }
    params.property_type = property_type

    onRequest(params)
  }, [
    onRequest,
    currentAddressId,
    summaryInfo?.land,
    summaryInfo?.prices,
    formatedRoadName,
  ])

  return { _saveFavorite }
}
