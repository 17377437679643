import styled from 'styled-components'
import theme from 'lib/styles/theme'

const NoteCalendarNav = ({ currentDate, setCurrentDate }) => {
  const formatedDate = currentDate?.clone().format('YYYY년 M월')

  const handleChangeDate = (type) => {
    const changeDate = currentDate?.clone()

    if (type === 'prev') {
      changeDate.subtract(1, 'month')
    } else {
      changeDate.add(1, 'month')
    }

    setCurrentDate(changeDate)
  }

  return (
    <Wrapper card={localStorage.getItem('notemode') != 'sheet'}>
      <ChevronButton onClick={() => handleChangeDate('prev')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </ChevronButton>
      <Title>{formatedDate}</Title>
      <ChevronButton onClick={() => handleChangeDate('next')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </ChevronButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
  line-height: 100%;

  ${({ card }) =>
    card &&
    `
    padding-top: 16px;
  `}
`

const ChevronButton = styled.a`
  display: flex;
  color: ${theme.colors.gray[900]};
  cursor: pointer;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: ${theme.colors.blue[400]};
  }
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`

export default NoteCalendarNav
