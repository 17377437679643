import { useCallback, useEffect } from 'react'
import { getChannelUsers } from 'lib/api/note/channelApi'
import { useDispatch, useSelector } from 'react-redux'
import { setChannelUsers } from 'modules/channel'
import useAxios from 'lib/hooks/useAxios'

export default function useChannelUsers(channelSrl) {
  const channelUsers = useSelector(
    (state) => state.channel.channelUsers,
  )
  const dispatch = useDispatch()

  const [onRequest, loading, data, error, reset] =
    useAxios(getChannelUsers)

  const _getChannelUsers = useCallback(() => {
    onRequest({ channelSrl })
  }, [onRequest, channelSrl])

  useEffect(() => {
    if (!data?.result) return
    dispatch(setChannelUsers(data.result))
  }, [data])

  return { _getChannelUsers, loading, data, channelUsers }
}
