import theme, { media } from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as NaverIcon } from 'assets/icon/note/ad/naver-logo.svg'
import { ReactComponent as DeunggiIcon } from 'assets/icon/note/payment/deunggi.svg'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'

const NoteDashboardProfilePayment = () => {
  const { isAllInOne } = useIsAllInOne()
  const planInfo = useSelector((state) => state.auth.planInfo)
  return (
    <Wrapper>
      <Block>
        <BlockTitle>
          <DeunggiIcon width={14} height={14} />
          등기부등본
        </BlockTitle>
        <BlockCount>
          {planInfo?.remain_count || <>&ndash;</>}
          <span>개</span>
        </BlockCount>
      </Block>
      {isAllInOne && (
        <Block>
          <BlockTitle>
            <NaverIcon width={14} height={14} />
            네이버광고
          </BlockTitle>
          <BlockCount>
            {planInfo?.ad_total_count || <>&ndash;</>}
            <span>개</span>
          </BlockCount>
        </Block>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 126px;
  height: 112px;
  padding: 20px 16px;
  border-radius: 8px;
  background: #f8f9fa;

  ${media.smallPc`
    width: 50%;
    gap: 16px;
  `}

  ${media.mediumM`
    flex-direction: column;
    gap: 16px;
  `}
`

const BlockTitle = styled.div`
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;

  ${media.smallPc`
    align-items: center;
  `}
`

const BlockCount = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-align: right;

  span {
    margin-left: 4px;
    color: ${theme.colors.gray[600]};
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
  }

  ${media.mediumS`
    font-size: 18px;
  `}
`

export default NoteDashboardProfilePayment
