import styled from 'styled-components'
import { Tag } from 'components/design'

const tagColors = {
  상담: 'green',
  계약일: 'orange',
  입주가능: 'blue',
  입주예정: 'blue',
  중도금일: 'purple',
  잔금일: 'pink',
  계약만기: 'red',
  매물방문: 'green',
  기타: '',
}

const NoteCalendarBadge = ({ type }) => {
  return (
    <StyledTag size="xs" color={tagColors[type] || 'blue'}>
      {type}
    </StyledTag>
  )
}

const StyledTag = styled(Tag)`
  width: 100%;
  justify-content: center;
  font-size: 11px;
  word-break: keep-all;
`

export default NoteCalendarBadge
