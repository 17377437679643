import theme from 'lib/styles/theme'
import priceFormat from 'lib/utils/priceFormat'
import styled from 'styled-components'

const SummarMultiHousingGraphTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledTooltipLabel>
          {payload[0].payload.price_date}
        </StyledTooltipLabel>
        <StyledTooltipItem>
          <span>{priceFormat(payload[0]?.value)}</span>
        </StyledTooltipItem>
      </StyledTooltip>
    )
  }

  return null
}

const StyledTooltip = styled.div`
  padding: 0.5rem 0.75rem;
  background-color: white;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: ${theme.borderRadius[1]};
  box-shadow: ${theme.shadow['card-2']};
`

const StyledTooltipLabel = styled.div`
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 4px;
`

const StyledTooltipItem = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

export default SummarMultiHousingGraphTooltip
