import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import MapWalker from 'lib/utils/mapWalker'
import { MiniMap } from 'components/map/MapControl/MapRoadViewContainer'
import MapRoadView from 'components/map/MapControl/MapRoadView'
import { message, notification } from 'antd'

const NoteRoadViewContainer = ({ isVisible, centerpoint, onClose }) => {
  const { kakao } = window
  const roadviewRef = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isVisible) return
    if (!centerpoint || centerpoint.length === 0) return

    const roadviewContainer = roadviewRef.current //로드뷰를 표시할 div
    const roadviewObj = new kakao.maps.Roadview(roadviewContainer) //로드뷰 객체
    const roadviewClient = new kakao.maps.RoadviewClient() //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체
    const position = new kakao.maps.LatLng(
      centerpoint[0],
      centerpoint[1],
    )

    // 특정 위치의 좌표와 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄운다.
    roadviewClient.getNearestPanoId(position, 100, function (panoId) {
      if (panoId) {
        roadviewObj.setPanoId(panoId, position) //panoId와 중심좌표를 통해 로드뷰 실행
      } else {
        onClose()
        notification.info({
          message: '알림',
          description: '로드뷰가 지원되지 않는 위치입니다.',
        })
      }
    })

    kakao.maps.event.addListener(roadviewObj, 'init', function () {
      getRoadviewCenterPoint(roadviewObj)
      initMiniMap(roadviewObj)
    })
  }, [isVisible, roadviewRef])

  // 로드뷰의 방향 세팅
  const getRoadviewCenterPoint = (roadview) => {
    // 로드뷰의 좌표와 clickPosition의 좌표를 비교하여 0~360도 사이의 각도를 계산한다
    let roadviewPosition = roadview.getPosition()
    let direction =
      Math.round(
        (Math.atan2(
          roadviewPosition.getLng() - centerpoint[1],
          roadviewPosition.getLat() - centerpoint[0],
        ) *
          180) /
          Math.PI +
          540,
      ) % 360

    // 계산한 각도로 동동이 방향(pan)을 돌려준다
    let viewpointWithPanoId = roadview.getViewpointWithPanoId()
    roadview.setViewpoint({
      ...viewpointWithPanoId,
      pan: direction,
    })
  }

  /**
   * 로드뷰 미니맵 초기화
   */
  const initMiniMap = (roadviewObj) => {
    const mapCenter = new kakao.maps.LatLng(
      centerpoint[0],
      centerpoint[1],
    )

    const miniMapContainer = document.getElementById(
      'roadview-miniMap-note',
    )

    const options = {
      center: mapCenter,
      level: 2,
    }

    //지도 생성 및 객체 리턴
    const miniMap = new kakao.maps.Map(miniMapContainer, options)

    //지도 레벨 제한
    miniMap.setMinLevel(2)
    miniMap.setMaxLevel(2)

    //로드뷰 도로 영역 색칠
    miniMap.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW)

    setMiniMapWalker(roadviewObj, miniMap, mapCenter)
  }

  const setInMap = (map, polygons, customOverlays) => {
    if (polygons.length === 0) return

    polygons?.map((polygon) => {
      polygon.setMap(map)
    })

    if (customOverlays.length === 0) return

    customOverlays.map((customOverlay) => {
      customOverlay.setMap(map)
    })
  }

  /**
   * 맵 위에 동동이 올리기
   * @param {*} roadview 로드뷰 객체
   * @param {*} miniMap 미니맵 객체
   * @param {*} mapCenter 맵 센터 포인트
   * @returns void
   */
  const setMiniMapWalker = (roadview, miniMap, mapCenter) => {
    let mapWalker = null

    // 로드뷰가 초기화 되었을때 map walker를 생성한다.
    mapWalker = new MapWalker(mapCenter)
    mapWalker.setMap(miniMap)

    // 로드뷰를 상,하,좌,우,줌인,줌아웃을 할 경우 발생한다.
    // 로드뷰를 조작할때 발생하는 값을 받아 map walker의 상태를 변경해 준다.
    kakao.maps.event.addListener(
      roadview,
      'viewpoint_changed',
      function () {
        // 이벤트가 발생할 때마다 로드뷰의 viewpoint값을 읽어, map walker에 반영
        const viewpoint = roadview.getViewpoint()
        mapWalker.setAngle(viewpoint.pan)
      },
    )

    // 로드뷰내의 화살표나 점프를 하였을 경우 발생한다.
    // position값이 바뀔 때마다 map walker의 상태를 변경해 준다.
    kakao.maps.event.addListener(
      roadview,
      'position_changed',
      function () {
        const position = roadview.getPosition()
        mapWalker.setPosition(position)
        miniMap.setCenter(position)
      },
    )

    //미니맵 지도 클릭하면 클릭한 위치 로드뷰를 보여줌(로드뷰가 불가한 지역이라면 가장 가까운 곳으로 강제 이동시킨다)
    kakao.maps.event.addListener(
      miniMap,
      'click',
      function (mouseEvent) {
        const position = mouseEvent.latLng
        const roadviewClient = new kakao.maps.RoadviewClient()

        roadviewClient.getNearestPanoId(
          position,
          50,
          function (panoId) {
            if (panoId) {
              roadview.setPanoId(panoId, position)
            } else {
              message.info(
                '로드뷰를 지원하지 않는 위치에요. 미니맵에서 다른 곳을 클릭해 주세요.',
              )
            }
          },
        )

        mapWalker.setPosition(position)
        miniMap.setCenter(position)
      },
    )
  }

  const roadviewRoot = document.getElementById('roadview-root')

  return ReactDOM.createPortal(
    <MapRoadView isVisible={isVisible} onClick={onClose}>
      <div id="roadview" ref={roadviewRef} />
      <MiniMap id="roadview-miniMap-note" />
    </MapRoadView>,
    roadviewRoot,
  )
}

export default NoteRoadViewContainer
