import { Tag } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import priceFormat from 'lib/utils/priceFormat'
import moment from 'moment'
import styled from 'styled-components'
import { ReactComponent as UpIcon } from 'assets/icon/up.svg'
import { ReactComponent as DownIcon } from 'assets/icon/down.svg'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const SummaryRpDetailTableItem = ({
  date,
  type,
  price,
  tag,
  rent_price,
  diff,
  previousDiff,
}) => {
  const isDongho = useRouteMatch('/note/v2/dongho/:id?/:dong?')

  const getPriceFormat = (value) => {
    return (
      <>
        {value == 0 ? <></> : value > 0 ? <UpIcon /> : <DownIcon />}
        {priceFormat(Math.abs(value))}
      </>
    )
  }

  return (
    <Item mobilestyle={isDongho}>
      <BasicInfo mobilestyle={isDongho}>
        <Date>
          {date ? moment(date, 'YYYYMMDD').format('YY.MM.DD') : null}
        </Date>
        <Type mobilestyle={isDongho} type={type}>
          {type}
        </Type>
        <Price mobilestyle={isDongho}>
          <div>
            <div>
              <span>{priceFormat(price)}</span>
              <span>
                {rent_price && <>&#47;{priceFormat(rent_price)}</>}
              </span>
            </div>
            {previousDiff &&
              Object.keys(previousDiff).length > 0 &&
              (previousDiff.deposit != 0 || previousDiff.rent != 0 ? (
                <Diff type={type}>
                  {previousDiff.deposit == 0 ? (
                    <>&ndash;</>
                  ) : (
                    getPriceFormat(previousDiff.deposit)
                  )}
                  &nbsp;&#47;&nbsp;
                  {previousDiff.rent == 0 ? (
                    <>&ndash;</>
                  ) : (
                    getPriceFormat(previousDiff.rent)
                  )}
                </Diff>
              ) : null)}
            {diff ? <Diff>{getPriceFormat(diff)}</Diff> : null}
          </div>
          <TagWrapper mobilestyle={isDongho}>{tag}</TagWrapper>
        </Price>
      </BasicInfo>
    </Item>
  )
}

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 50px;
  border-top: 1px solid ${theme.colors.gray[100]};
  font-size: 14px;

  ${({ multiline }) =>
    multiline &&
    `
    align-items: flex-start;
  `}

  &:last-child {
    border-bottom: 1px solid ${theme.colors.gray[100]};
  }

  ${media.mediumS`
    ${({ mobilestyle }) =>
      mobilestyle &&
      `
      padding: 8px 0;
    `}
    `}
`

export const BasicInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

export const Date = styled.div`
  margin-right: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${theme.colors.gray[600]};
`

export const Type = styled.div`
  margin-right: 6px;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
  flex-shrink: 0;
  ${({ type }) => {
    switch (type) {
      case '매매':
        return `
            color: ${theme.colors.summary.rp.blue};
          `
      case '전세':
        return `
            color: ${theme.colors.summary.rp.primary};
          `
      case '월세':
        return `
            color: ${theme.colors.summary.rp.red};
          `
    }
  }};

  ${media.mediumS`
    ${({ mobilestyle }) =>
      mobilestyle &&
      `
      align-self: center;
    `}
  `}
`

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  font-weight: 600;
  word-break: keep-all;
  font-size: 14px;
  line-height: 130%;

  ${media.mediumS`
    ${({ mobilestyle }) =>
      mobilestyle &&
      `
      gap: 0px;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      flex-wrap: wrap;
    `}
  `}
`

const TagWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;

  ${media.mediumS`
    ${({ mobilestyle }) =>
      mobilestyle &&
      `
      position: relative;
      top: initial;
      transform: none;
      align-self: center;
      right: initial;
    `}
  `}
`

export const Office = styled.div`
  display: flex;
  align-items: center;
  margin-left: 78px;
  font-size: 14px;
  font-weight: 600;

  ${({ mt }) => mt && `margin-top: 4px;`}
`

export const Diff = styled.div`
  margin-top: 2px;
  justify-content: flex-end;
  font-size: 12px;
  text-align: right;
  font-weight: 500;
  line-height: 130%;
  color: #596371;

  ${({ ml }) =>
    ml &&
    `
    margin-left: 10px;
  `};

  ${({ type }) =>
    type == '월세' &&
    `
    display: inline-flex;
    font-size: 13px;
    align-items: center;
  `}
`

export default SummaryRpDetailTableItem
