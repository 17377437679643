import React, { useCallback } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import theme from 'lib/styles/theme'

const NoteSideMenuItem = ({
  toCard,
  toSheet,
  onlySheet,
  icon,
  activeIcon,
  label,
  type,
  onClose,
}) => {
  const onClickItem = useCallback(() => {
    if (type == 'hamburger') {
      onClose()
    }
    if (label == '대시보드') {
      //대시보드로 들어왔을 경우 시트모드로 강제 변경
      localStorage.setItem('notemode', 'sheet')
    }
  }, [])

  return (
    <StyledNavItem
      type={type}
      border={type != 'hamburger' && label == '대시보드' ? 'Y' : 'N'}
      to={localStorage.getItem('notemode') == 'card' ? toCard : toSheet}
      onClick={onClickItem}
      ishide={
        localStorage.getItem('notemode') == 'card' && onlySheet
          ? 'Y'
          : 'N'
      }
      icon={icon}
    >
      {activeIcon}
      {icon}
      <Label type={type}>{label}</Label>
    </StyledNavItem>
  )
}

const StyledNavItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  ${({ ishide }) => ishide == 'Y' && `display: none;`}

  .activeIcon {
    display: none;
  }
  .normalIcon {
    display: block;
  }

  ${({ border }) =>
    border == 'Y' &&
    `
      &::after {
        content: "";
        width: 60%;
        border-bottom: 1px solid ${theme.colors.gray[100]};
        padding-bottom: 20px;
      }
    `}

  svg ,img {
    margin-bottom: 10px;
  }

  img {
    width: 24px;
    height: 24px;
  }

  &.active {
    div {
      ${({ icon }) =>
        !icon &&
        `
      color: ${theme.colors.blue[500]};
    `}
      font-weight: 700;
    }
    .activeIcon {
      display: block;
    }
    .normalIcon {
      display: none;
    }
  }

  ${({ type }) =>
    type == 'hamburger' &&
    `
      flex-direction: row;
      width: 50%;
      margin-bottom: 0;
      padding: 11.5px 0;

      svg, img {
        margin-bottom: 0 !important;

        &.activeIcon {
          display: block !important;
        }
        &.normalIcon {
          display: none !important;
        }
      }
    `}
`

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[900]};
  ${({ type }) =>
    type == 'hamburger' &&
    `
        margin-left: 12px;
        font-size: 16px;
        font-weight: 500;
        line-height: 180%;
    `}
`

export default React.memo(NoteSideMenuItem)
