import styled from 'styled-components'
import theme from 'lib/styles/theme'
import SummaryLegalInfoHeader from 'components/summary/SummaryLegal/SummaryLegalInfoHeader'
import SummaryLegalCourtInfo from 'components/summary/SummaryLegal/SummaryLegalCourtInfo'
import { Card, CardHeader, CardBody, Button } from 'components/design'
import { numberWithCommas } from 'utils'

export const StyledLink = styled.a`
  margin-top: 8px;
  &:last-child {
    display: block;
  }
`

export const Help = styled.div`
  margin-top: 1.2rem;
  margin-left: 0.25rem;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 1rem;'}
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 400;
  i {
    margin-right: 0.25rem;
  }
`

const SummaryLegalCourtInfoContainer = ({
  data,
  land,
  setBuildingAreaHide,
  setFloorAreaHide,
}) => {
  const getCourtMainTitle = () => {
    if (!data?.planning?.usearea) return ''
    const result = getCourtText(Object.keys(data?.planning?.usearea)[0])
    return result ? result : ''
  }

  const getCourtText = (type) => {
    const commText = `최대 ${formatArea(
      land.land_area,
      data.court.usearea[1],
      type,
    )}평의 `

    const result = {
      제1종전용주거지역:
        '쾌적한 주거환경을 위한 단독주택을 짓기 적합해요',
      제2종전용주거지역: '공동주택을 짓기 적합해요',
      제1종일반주거지역: '저층주택을 짓기 적합해요',
      제2종일반주거지역: '중층주택을 짓기 적합해요',
      제3종일반주거지역: '고층주택을 짓기 적합해요',
      중심상업지역:
        '대규모 상업·업무 및 편의시설을 짓기 좋은 지역이에요',
      일반상업지역:
        '소규모 상업·업무 및 편의시설을 짓기 좋은 지역이에요',
      근린상업지역:
        '근린주택가를 위한 운동·복지·편의시설을 짓기 적합해요',
      유통상업지역:
        '판매·운수·창고 등(의) 대형 유통시설을 짓기 적합해요',
      전용공업지역: '대형 중화학·공해성 공업 시설을 짓기 적합해요',
      일반공업지역:
        '환경을 저해하지 않는 일반 공업시설을 짓기 적합해요',
      준공업지역: '경공업 및 주거·상업·업무시설을 지을 수 있어요',
      보전녹지지역:
        '자연환경 보호를 위해 제한적인 시설을 지을 수 있어요',
      생산관리지역:
        '농림업·어업 등의 생산을 위한 관리 시설을 지을 수 있어요',
      계획관리지역: '단독주택·근생시설을 허가받은 후 지을 수 있어요',
      농림지역: '농가주택·농림업 생산 관련 시설을 지을 수 있어요',
      자연환경보전지역: '농가주택·소매점 등을 지을 수 있어요',
      준주거지역: '오피스텔, 상업시설 등을 짓기 적합해요',
      생산녹지지역: '농업적 생산이 우선되는 시설을 지을 수 있어요',
      자연녹지지역:
        '자연환경을 해치지 않는 제한적인 시설을 지을 수 있어요',
      보전관리지역:
        '자연환경·녹지공간 등을 위한 관리시설을 지을 수 있어요',
    }

    return result[type] ? commText + result[type] : ''
  }

  const formatArea = (area, useArea, type) => {
    const squareMeter = Math.round((area * 100) / 3.305) / 100
    const calcArea = Math.floor(squareMeter * (useArea * 0.01))

    return type == '자연환경보전지역'
      ? calcArea > 302.4
        ? 302.4
        : numberWithCommas(calcArea)
      : numberWithCommas(calcArea)
  }

  return (
    <Card>
      <CardHeader>
        <SummaryLegalInfoHeader
          smallTitle="국토의 계획 및 이용에 관한 법률"
          mainTitle={getCourtMainTitle()}
        />
      </CardHeader>
      {data.planning && (
        <CardBody>
          <SummaryLegalCourtInfo
            court={data.court}
            setBuildingAreaHide={setBuildingAreaHide}
            setFloorAreaHide={setFloorAreaHide}
          />
          {data.law?.url && (
            <StyledLink href={data.law.url} target="_blank">
              <Help marginBottom={true}>
                <i className="fal fa-info-circle" />
                현재 시점의 자세한 사업지구 정보는 해당 지자체를 통해
                확인하시기 바랍니다.
              </Help>
              <Button expand color="gray">
                도시계획조례
              </Button>
            </StyledLink>
          )}
        </CardBody>
      )}
    </Card>
  )
}

export default SummaryLegalCourtInfoContainer
