import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { message } from 'antd'
import NotePlanNoti from 'components/jmapnotev2/NotePlanNoti'
import NoteDocsLayerDeunggibuCommon from './NoteDocsLayerDeunggibuCommon'

const NoteDocsLayerDeunggibu = () => {
  const history = useHistory()

  const match = useRouteMatch(
    '/note/v2/docs/:channelSrl/:noteSrl/edit/:contractSrl?',
  )

  /**
   * 등기부 발급하러 가기
   */
  const goToDeunggibuIssuance = () => {
    const channel_srl = match.params?.channelSrl || ''
    const note_srl = match.params?.noteSrl || ''
    const address_srl = match.params?.addressSrl || ''

    if (!channel_srl || !note_srl) {
      message.error('채널 또는 매물정보가 잘못되었습니다.')
      return
    }

    history.push({
      pathname: `/note/v2/notes/${channel_srl}/${
        address_srl || '0'
      }/${note_srl}`,
      state: {
        activeTab: 3,
      },
    })
  }

  return (
    <Wrapper>
      <NotePlanNoti
        contract_srl={match.params?.contractSrl || ''}
        channel_srl={match.params?.channelSrl || ''}
        note_srl={match.params?.noteSrl || ''}
        goToDeunggibuIssuance={goToDeunggibuIssuance}
        isNoplanPopup
      />
      <NoteDocsLayerDeunggibuCommon
        goToDeunggibuIssuance={goToDeunggibuIssuance}
        useTo="contract"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export default NoteDocsLayerDeunggibu
