import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useContext } from 'react'
import BaseContext from 'contexts/baseContext'
import { useDispatch } from 'react-redux'
import { setRealtorInfo } from 'modules/bds'

const BdsHeaderBack = ({ refreshSearch }) => {
  const dispatch = useDispatch()

  const {
    state: { headerInverse },
  } = useContext(BaseContext)

  const onClickBack = () => {
    dispatch(setRealtorInfo(null))
    setTimeout(() => {
      refreshSearch()
    }, 1)
  }

  return (
    <StyledLink onClick={onClickBack}>
      <i className="far fa-chevron-left"></i>
    </StyledLink>
  )
}

const StyledLink = styled.button`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: ${theme.base.navWidth}px;
  width: ${theme.base.container}px;
  height: ${theme.base.headerHeight}px;
  padding-left: 16px;
  font-size: 18px;
  color: ${theme.colors.gray[600]};
  background-color: ${theme.colors.base.white};
  border-bottom: 1px solid ${theme.colors.gray[100]};
  cursor: pointer;
`

export default BdsHeaderBack
