import { useSelector } from 'react-redux'
import styled from 'styled-components'

const NotePaymentTitle = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  return <Title>{loginInfo.user_name}님 환영합니다!</Title>
}

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 36px;
`

export default NotePaymentTitle
