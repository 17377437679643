import NotePaymentAllInOneCancelConfirm from 'components/jmapnotev2/NotePayment/NotePaymentAllInOneCancelConfirm'
import NotePaymentAllInOneCancelDone from 'components/jmapnotev2/NotePayment/NotePaymentAllInOneCancelDone'
import NotePaymentCancelConfirm from 'components/jmapnotev2/NotePayment/NotePaymentCancelConfirm'
import NotePaymentCancelDone from 'components/jmapnotev2/NotePayment/NotePaymentCancelDone'
import NotePaymentCancelScheduleConfirm from 'components/jmapnotev2/NotePayment/NotePaymentCancelScheduleConfirm'
import NotePaymentCancelScheduleDone from 'components/jmapnotev2/NotePayment/NotePaymentCancelScheduleDone'
import NotePaymentEventCancelConfirm from 'components/jmapnotev2/NotePayment/NotePaymentEventCancelConfirm'
import NotePaymentFailCancelConfirm from 'components/jmapnotev2/NotePayment/NotePaymentFailCancelConfirm'
import NotePaymentFailCancelDoneConfirm from 'components/jmapnotev2/NotePayment/NotePaymentFailCancelDoneConfirm'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import {
  cancelPlan,
  immediateCancelPlan,
} from 'lib/api/notev2/paymentApi'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 취소 관련 로직 모음
 * (프리 요금제로 변경)
 * @param {*} param0
 * @returns
 */
const NotePaymentCancelToFreeContainer = ({ children }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const { isAllInOne } = useIsAllInOne()

  const [loading, setLoading] = useState(false) //로딩

  const { onRequest: onRequestUserLoader } = useUserLoader(false) //사용자 정보 로딩하는 hook

  const [isCancelModalVisible, setIsCancelModalVisible] =
    useState(false) // 결제 취소 모달 visible

  const [isEventCancelModalVisible, setIsEventCancelModalVisible] =
    useState(false) //이벤트 요금제 취소 모달 visible

  const [
    isCancelScheduleModalVisible,
    setIsCancelScheduleModalVisible,
  ] = useState(false) // 요금제 해지 예정 여부 확인 모달 visible

  const [isCancelDoneModalVisible, setIsCancelDoneModalVisible] =
    useState(false) // 결제 취소 완료 모달 visible

  const cancelDoneInitValue = {
    amount: '',
    paymentName: '',
  } //취소 완료 초기화 value

  const [cancelDoneResult, setCancelDoneResult] = useState(
    cancelDoneInitValue,
  ) //결제 취소 완료 result obj

  const [
    isCancelScheduleDoneModalVisible,
    setIsCancelScheduleDoneModalVisible,
  ] = useState(false) // 요금제 해지 예정 완료 모달 visible

  const [isPayFailCancelModalVisible, setIsPayFailCancelModalVisible] =
    useState(false) // 결제 실패 시 취소 모달 visible

  const [
    isPayFailCancelDoneModalVisible,
    setIsPayFailCancelDoneModalVisible,
  ] = useState(false) // 결제 실패 시 취소 완료 모달 visible

  const [isVisibleAllInOneCancelDone, setIsVisibleAllInOneCancelDone] =
    useState(false)

  const [
    onRequestCancelPlan,
    loadingCancel,
    cancelResult,
    errorCancelPlan,
  ] = useAxios(cancelPlan, {
    resetError: true,
  }) //해지 신청 API

  const [
    onRequestCancelPlanAllInOne,
    loadingCancelAllInOne,
    cancelResultAllInOne,
    errorCancelPlanAllInOne,
  ] = useAxios(cancelPlan, {
    resetError: true,
  }) //해지 신청 API - 올인원

  const [
    onRequestUpdatePlanPayFail,
    ,
    updateResultPayFail,
    errorUpdatePlanFayFail,
  ] = useAxios(immediateCancelPlan, {
    resetError: true,
  }) //프리 요금제로 즉시 변경 (결제 실패시 프리 요금제로 변경할 때 사용)

  /**
   * 결제 취소 api 호출
   */
  const requestCancel = () => {
    onRequestCancelPlan({
      plan_srl: planInfo?.plan_srl,
    })
  }

  /**
   * 결제 취소 결과
   */
  useEffect(() => {
    if (!cancelResult || !cancelResult?.result) return
    if (planInfo?.event_in_use) {
      setIsEventCancelModalVisible(false)
      //사용자 정보 갱신
      onRequestUserLoader()
      return
    }

    if (cancelResult?.error == 0) {
      //성공
      if (planInfo.is_refundable) {
        setIsCancelModalVisible(false)
        setIsCancelDoneModalVisible(true)
        setCancelDoneResult({
          amount: cancelResult.result.price,
          paymentName: cancelResult.result.payment_name,
        })
      } else {
        setIsCancelScheduleModalVisible(false)
        setIsCancelScheduleDoneModalVisible(true)
      }

      //사용자 정보 갱신
      onRequestUserLoader()
    }
    //

    return () => {
      setCancelDoneResult(cancelDoneInitValue)
    }
  }, [cancelResult])

  /**
   * 결제 취소 실패
   */
  useEffect(() => {
    if (!errorCancelPlan) return

    setLoading(false)
  }, [errorCancelPlan])

  /**
   * 결제 취소 api 호출 올인원
   */
  const requestCancelAllInOne = () => {
    setLoading(true)
    onRequestCancelPlanAllInOne({
      plan_srl: planInfo?.plan_srl,
    })
  }
  /**
   * 결제 취소 결과
   */
  useEffect(() => {
    if (!cancelResultAllInOne || !cancelResultAllInOne?.result) {
      setLoading(false)
      return
    }

    if (cancelResultAllInOne?.error == 0) {
      //성공
      if (planInfo.is_refundable) {
        setIsCancelModalVisible(false)
      } else {
        setIsCancelScheduleModalVisible(false)
      }

      setIsVisibleAllInOneCancelDone(true)

      //사용자 정보 갱신
      onRequestUserLoader()
      setLoading(false)
    }

    return () => {
      setCancelDoneResult(cancelDoneInitValue)
    }
  }, [cancelResultAllInOne])

  /**
   * 올인원 취소 실패
   */
  useEffect(() => {
    if (!errorCancelPlanAllInOne) return

    setLoading(false)
  }, [errorCancelPlanAllInOne])

  /**
   * 올인원 취소 완료 후 닫기
   */
  const endDoneAllInOne = () => {
    setIsVisibleAllInOneCancelDone(false)
  }

  /**
   * 결제 실패시 취소 api 호출
   */
  const requestPayFailCancel = () => {
    onRequestUpdatePlanPayFail({
      plan_srl: planInfo?.plan_srl,
    })
    setLoading(true)
  }

  useEffect(() => {
    if (!errorUpdatePlanFayFail) return

    setLoading(false)
  }, [errorUpdatePlanFayFail])

  /**
   * 결제 실패시 취소 api 결과
   */
  useEffect(() => {
    if (!updateResultPayFail || updateResultPayFail?.error != 0) {
      setLoading(false)
      return
    }

    if (planInfo?.event_in_use) {
      setIsEventCancelModalVisible(false)
      setIsPayFailCancelModalVisible(false)
      //사용자 정보 갱신
      onRequestUserLoader()
      setLoading(false)
      return
    }

    if (updateResultPayFail?.error == 0) {
      //성공
      setIsPayFailCancelDoneModalVisible(true)
      setIsPayFailCancelModalVisible(false)
      setLoading(false)

      //사용자 정보 갱신
      onRequestUserLoader()
    }
  }, [updateResultPayFail])

  /**
   * 결제 취소 모달 닫기
   */
  const onCloseCancelModal = (isDone) => {
    isDone
      ? setIsCancelDoneModalVisible(false)
      : setIsCancelModalVisible(false)
  }

  /**
   * 요금제 해지 예정 여부 확인 모달 닫기
   */
  const onCloseCancelScehduleModal = (isDone) => {
    isDone
      ? setIsCancelScheduleDoneModalVisible(false)
      : setIsCancelScheduleModalVisible(false)
  }

  /**
   * 무료 요금제 취소 모달 닫기
   */
  const onCloseEventCancelModal = () => {
    setIsEventCancelModalVisible(false)
  }

  /**
   * 결제 실패 시 취소 모달 닫기
   */
  const onClosePayFailCancelModal = (isDone) => {
    isDone
      ? setIsPayFailCancelDoneModalVisible(false)
      : setIsPayFailCancelModalVisible(false)
  }

  /**
   * 요금제 해지 신청 API를 호출합니다.
   * is_refundable 값이 true이면 '결제를 취소 하시겠어요?' 팝업창을
   * false이면 '요금제 해지 여부 확인' 팝업창을 표시합니다.
   * 두 가지의 경우 모두 cancelPlan API를 호출합니다.
   */
  const onClickCancelPlan = () => {
    if (planInfo?.event_in_use) {
      setIsEventCancelModalVisible(true)
      return
    }

    //결제 실패시 결제 실패 후 요금제 취소 호출
    if (planInfo?.error) {
      setIsPayFailCancelModalVisible(true)
    } else {
      if (planInfo.is_refundable) {
        //결제 취소 루트
        setIsCancelModalVisible(true)
      } else {
        //요금제 해지 루트
        setIsCancelScheduleModalVisible(true)
      }
    }
  }

  {
    /* 요금 해지 관련 알림이라 닫기 버튼, 모달 바깥을 클릭해도 닫기 기능은 없음 */
  }
  return children({
    loading,
    onClickCancelPlan,
    modal: {
      // 결제 취소 모달
      cancel: isAllInOne ? (
        isCancelModalVisible ? (
          <NotePaymentAllInOneCancelConfirm
            toFree
            isRefundable={true}
            visible={isCancelModalVisible}
            onOk={requestCancelAllInOne}
            onCancel={() => onCloseCancelModal(false)}
          />
        ) : null
      ) : (
        <NotePaymentCancelConfirm
          visible={isCancelModalVisible}
          onOk={requestCancel}
          onCancel={() => onCloseCancelModal(false)}
        />
      ),
      //해지 예정 모달
      cancelSchedule: isAllInOne ? (
        isCancelScheduleModalVisible ? (
          <NotePaymentAllInOneCancelConfirm
            toFree
            isRefundable={false}
            visible={isCancelScheduleModalVisible}
            onOk={requestCancelAllInOne}
            onCancel={() => onCloseCancelScehduleModal(false)}
          />
        ) : null
      ) : (
        <NotePaymentCancelScheduleConfirm
          visible={isCancelScheduleModalVisible}
          onOk={requestCancel}
          onCancel={() => onCloseCancelScehduleModal(false)}
        />
      ),
      //결제 취소 완료 모달
      cancelDone: (
        <NotePaymentCancelDone
          isAllInOne={isAllInOne}
          visible={isCancelDoneModalVisible}
          onOk={() => onCloseCancelModal(true)}
          onCancel={() => onCloseCancelModal(true)}
          amount={cancelDoneResult.amount}
          paymentName={cancelDoneResult.paymentName}
        />
      ),
      //해지 예정 완료 모달
      cancelScheduleDone: (
        <NotePaymentCancelScheduleDone
          visible={isCancelScheduleDoneModalVisible}
          onOk={() => onCloseCancelScehduleModal(true)}
          onCancel={() => onCloseCancelScehduleModal(true)}
        />
      ),
      //올인원 취소 완료 페이지
      cancelAllInOneDone: isVisibleAllInOneCancelDone ? (
        <NotePaymentAllInOneCancelDone endDone={endDoneAllInOne} />
      ) : null,
      //이벤트 요금제 결제 취소 모달
      eventCancel: (
        <NotePaymentEventCancelConfirm
          visible={isEventCancelModalVisible}
          onOk={requestCancel}
          onCancel={onCloseEventCancelModal}
        />
      ),
      //결제 실패시 프리 요금제 변경 모달
      payFailCancel: (
        <NotePaymentFailCancelConfirm
          visible={isPayFailCancelModalVisible}
          onOk={requestPayFailCancel}
          onCancel={() => onClosePayFailCancelModal(false)}
        />
      ),
      //결제 실패시 프리 요금제 변경 완료 모달
      payFailCancelDone: (
        <NotePaymentFailCancelDoneConfirm
          visible={isPayFailCancelDoneModalVisible}
          onOk={() => onClosePayFailCancelModal(true)}
          onCancel={() => onClosePayFailCancelModal(true)}
        />
      ),
    },
  })
}

export default NotePaymentCancelToFreeContainer
