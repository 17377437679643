import { useState, useCallback, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import theme, { media } from 'lib/styles/theme'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useQueryString from 'lib/hooks/useQueryString'
import NoteBottomMenuItem from './NoteBottmMenuItem'
import { useSelector } from 'react-redux'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import BaseContext from 'contexts/baseContext'
import { setSidemenuVisible } from 'modules/layout'
import { useDispatch } from 'react-redux'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

const StyledBottomMenu = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 71;

  display: flex;
  align-items: center;
  width: ${theme.base.container}px;
  height: ${theme.base.bottomMenuHeight}px;
  height: calc(
    ${theme.base.bottomMenuHeight}px + env(safe-area-inset-bottom)
  );
  padding-bottom: env(safe-area-inset-bottom);

  background-color: white;
  border-top: 1px solid ${theme.colors.gray[200]};

  ${media.mediumM`
    width: 100%;
  `}

  @media (min-width: ${theme.bp.mediumM - 1}px) {
    display: none;
  }
`

const NoteBottomMenu = () => {
  const { hasPlan } = useNoteHasPlan()
  const { channelSrl, folderSrl } = useNoteMatch()
  const channels = useSelector((state) => state.channel.channels)
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const dispatch = useDispatch()

  const {
    actions: { setIsFold },
  } = useContext(BaseContext)

  const location = useLocation()
  const [recentFolderSrl, setRecentFolderSrl] = useState('')

  useEffect(() => {
    const value = localStorage.getItem('recentFolderSrl')
    if (!value) return
    setRecentFolderSrl(value)
  }, [folderSrl])

  const getNoteIndexPathname = useCallback(() => {
    let path = '/note'

    // console.log('recentFolderSrl: ', recentFolderSrl)
    if (channelSrl) path += `/${channelSrl}`
    if (folderSrl) {
      path += `/${folderSrl}`
    } else {
      const channelFolderSrl = channels.find(
        (o) => o.channel_srl.toString() === channelSrl.toString(),
      )?.folder_srl
      // console.log(channelFolderSrl)
      if (channelFolderSrl) path += `/${channelFolderSrl}`
    }
    // if (!folderSrl && recentFolderSrl) path += `/${recentFolderSrl}`

    return path
  }, [channelSrl, folderSrl, channels, recentFolderSrl])

  const navLinkIsActive = (match, location) => {
    if (!match) return false
    return true
  }

  const root = document.getElementById('root')

  return ReactDOM.createPortal(
    <StyledBottomMenu onClick={() => setIsFold(false)}>
      <NoteBottomMenuItem
        to="/home"
        icon="arrow-left"
        text="지도 홈"
        exact
        isActive={() => false}
      />

      <NoteBottomMenuItem
        to={{ pathname: getNoteIndexPathname() }}
        exact={!folderSrl}
        isActive={navLinkIsActive}
        icon="th-list"
        text="매물목록"
      />

      {!hasPlan && (
        <NoteBottomMenuItem
          to={{
            pathname: `/note/${channelSrl}/calendar`,
          }}
          isActive={navLinkIsActive}
          icon="calendar-check"
          text="일정관리"
        />
      )}

      <NoteBottomMenuItem
        to={{
          pathname: `/note/${channelSrl}/contact`,
        }}
        isActive={navLinkIsActive}
        icon="address-book"
        text="고객관리"
      />
      {!isMobile && (
        <NoteBottomMenuItem
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          }
          as="div"
          text="더보기"
          onClick={() => dispatch(setSidemenuVisible(true))}
          isLink={false}
        />
      )}
    </StyledBottomMenu>,
    root,
  )
}

export default NoteBottomMenu
