import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { ReactComponent as Icon } from 'assets/icon/note/ad/noti.svg'
import NoteAdNotificationList from './NoteAdNotificationList'
import NoteAdNotificationSlideContainer from 'containers/notev2/NoteAdNotificationSlideContainer'

/**
 * 광고 메인 광고 상태 알림
 * @param 작은 화면에서 목록 접기
 */
const NoteAdNotification = ({ isFlip }) => {
  return (
    <NoteAdNotificationSlideContainer>
      {({ isSlideVisible, ref, openNotiSlide }) => {
        return (
          <Wrapper>
            <TitleWrapper>
              <Title>
                <Icon />
                업무 알림
              </Title>
              <More onClick={openNotiSlide}>더보기</More>
            </TitleWrapper>
            {!isFlip && (
              <NoteAdNotificationList
                isSlideVisible={isSlideVisible}
                ref={ref}
              />
            )}
          </Wrapper>
        )
      }}
    </NoteAdNotificationSlideContainer>
  )
}

const Wrapper = styled.div`
  width: 67%;
  padding: 20px 24px;
  border-radius: 12px;
  background-color: ${theme.colors.base.white};

  ${media.xLarge`
    width: 100%;
  `}
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
`

export const More = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  cursor: pointer;

  &::after {
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    margin-left: 4px;
  }
`

export default NoteAdNotification
