import styled from 'styled-components'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import theme, { media } from 'lib/styles/theme'

/**
 * 공통 필터 wrapper
 * @param earlyMobile 태블릿 사이즈에서 모바일 레이아웃 시작
 */
const NoteFilterWrapper = ({ earlyMobile, children, column }, ref) => {
  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )
  const height = useSelector((state) => state.notice.height)

  return (
    <Wrapper
      earlyMobile={earlyMobile}
      isnoticebarvisible={isNoticeBarVisible ? 'Y' : 'N'}
      noticebarheight={height}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  left: ${theme.base.navWidth}px;
  flex-shrink: 0;
  width: ${theme.base.note.filterWidth}px;
  box-shadow: 6px 0 5px -2px rgba(0, 0, 0, 0.1);
  padding-right: 0;
  background-color: ${theme.colors.gray[50]};
  height: 100%;
  z-index: 16;
  ${({ isnoticebarvisible, noticebarheight }) =>
    isnoticebarvisible == 'Y'
      ? `top: ${noticebarheight}px !important;`
      : `top: 0;`};

  ${media.mediumM`
    left: 0;
    padding-bottom: ${theme.base.navWidth}px;

    ${({ earlyMobile }) =>
      earlyMobile &&
      `
      padding-top: 60px;
    `}
  `}

  ${media.mediumS`
    padding-top: 60px;
  `}
`

export default forwardRef(NoteFilterWrapper)
