import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { getJootekServiceURL } from 'utils'

/**
 * 건축물 대장 타임라인 - 주택 폴리오로 연결 링크
 * @param {*} disabled srl 없을 때 disabled
 * @param {*} label label
 * @param {*} srl 주택 폴리오 srl
 * @returns
 */
const SummaryLobFolioButton = ({ disabled, label, children, srl }) => {
  const onClick = () => {
    if (!srl) return
    window.location.href = getJootekServiceURL(`/folio/${srl}`)
  }

  return (
    <Wrapper
      disabled={disabled}
      onClick={onClick}
      srl={srl}
      as={!srl && 'div'}
    >
      <Label>{label}</Label>
      <Children>{children}</Children>

      {!disabled && srl && <i className="fal fa-chevron-right"></i>}
    </Wrapper>
  )
}

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  padding: 5px 12px;
  color: ${theme.colors.gray[800]};
  background-color: ${theme.colors.gray[100]};
  border: 1px solid ${theme.colors.gray[100]};
  border-radius: ${theme.borderRadius[1]};
  transition: 0.2s ease;
  cursor: default;

  i {
    color: ${theme.colors.gray[600]};
  }

  &[disabled] {
    color: ${theme.colors.gray[700]};
  }

  ${({ disabled, srl }) =>
    !disabled &&
    srl &&
    `
      cursor: pointer;  
      &:hover {
        background-color: white;
        border-color: ${theme.colors.primary[400]};
        & > i {
          color: ${theme.colors.primary[400]};
        }
      }
    `}
`

const Children = styled.div`
  flex: 1;
  padding-right: 16px;
`

const Label = styled.div`
  font-weight: 700;
  margin-right: 8px;
`

export default SummaryLobFolioButton
