import BaseContext from 'contexts/baseContext'
import theme from 'lib/styles/theme'
import { useContext } from 'react'
import styled from 'styled-components'

const HeaderCenterTitle = ({ hideBeforeScroll, children }) => {
  const {
    state: { isScrolled },
  } = useContext(BaseContext)

  return (
    <StyledTitle
      hideBeforeScroll={hideBeforeScroll}
      isScrolled={isScrolled}
    >
      {children}
    </StyledTitle>
  )
}

const StyledTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 10%;
  width: 80%;
  transform: translateY(-50%);

  color: ${theme.colors.gray[800]};
  font-size: 1rem;
  text-align: center;

  ${({ hideBeforeScroll, isScrolled }) => {
    if (hideBeforeScroll && !isScrolled)
      return `
      opacity: 0;
    `
    return `
      opacity: 1;
    `
  }}
`

export default HeaderCenterTitle
