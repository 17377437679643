import React, { useCallback, useEffect, useState } from 'react'
import { Title } from 'components/jmapnotev2/style/NoteDocsStyle'
import {
  getSimpleSelectboxOptions,
  getVatRatio,
} from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import styled from 'styled-components'
import useAxios from 'lib/hooks/useAxios'
import { getBrokerRate } from 'lib/api/notev2/contractApi'
import useEditContract from 'lib/hooks/notev2/useEditContract'

const propertyList = [
  '아파트',
  '오피스텔',
  // '원룸',
  '사무실',
  '상가',
  '상가건물',
  '주택',
  '다가구주택',
  '다세대주택',
  // '다중주택',
  //  '연립주택',
  // '주상복합',
  '건물',
  '아파트분양권',
  '오피스텔분양권',
  '공장',
  '창고',
  '토지',
  '직접입력',
]

const NoteDocsContractTitle = ({ onUpdate, onRequestSpec }) => {
  const { watch, setValue, getValues } = useFormContext()
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [contractTypes, setContractTypes] = useState([])
  const options = useSelector((state) => state.notev2.noteOptions)

  const [onRequestCalc, , dataCalc] = useAxios(getBrokerRate)
  const [onEditContract, , data] = useEditContract()

  const channel_srl = watch('channel_srl')
  const contract_srl = watch('contract_srl')
  const total_price = watch('total_price')
  const property_type = watch('property_type')
  const contract_type = watch('contract_type')
  const d_junggaebosu_vat_gani = watch('d_junggaebosu_vat_gani')
  const d_junggaebosu_vat_ratio1 = watch('d_junggaebosu_vat_ratio1')
  const d_silbi = watch('d_silbi')

  useEffect(() => {
    if (!options || Object.keys(options).length == 0) return

    setContractTypes(options.contract_types)
  }, [options])

  const getSelectedValue = useCallback((value, array) => {
    if (!value) return ''

    return [array[array.findIndex((item) => item == value)]].map(
      (item) => {
        return { label: item, value: item }
      },
    )
  }, [])

  /**
   * select box 변경 시
   */
  const onChangeSelectbox = useCallback(
    (key, value) => {
      if (key == 'property_type') {
        onRequestSpec({
          property_type: value,
          contract_type,
        })

        setValue(key, value)

        //중개보수 계산
        onRequestCalc({
          channel_srl,
          contract_srl,
          total_price,
          property_type: value,
          contract_type,
        })
      } else if (key == 'contract_type') {
        onRequestSpec({
          property_type,
          contract_type: value,
        })

        setValue(key, value)

        //중개보수 계산
        onRequestCalc({
          channel_srl,
          contract_srl,
          total_price,
          property_type,
          contract_type: value,
        })
      }
    },
    [
      property_type,
      contract_type,
      contract_srl,
      channel_srl,
      total_price,
    ],
  )

  /**
   * 중개보수 계산 결과값
   */
  useEffect(() => {
    if (!dataCalc || !dataCalc?.result) return

    /**
   * total = d_junggaebosu // 왼쪽 중개보수
      base_amount = d_junggaebosu_cal // 오른쪽 중개보수
      percent = d_junggaebosu_cal_ratio // 요율
  */
    const result = dataCalc.result

    updateData({
      d_junggaebosu: result.total,
      d_junggaebosu_cal: result.base_amount,
      d_junggaebosu_cal_ratio: result.percent,
    })
  }, [dataCalc])

  /**
   *
   * @param {*} d_junggaebosu 왼쪽 중개보수
   * @param {*} d_junggaebosu_cal 오른쪽 중개보수
   * @param {*} d_junggaebosu_cal_ratio 요율
   */
  const updateData = ({
    d_junggaebosu,
    d_junggaebosu_cal,
    d_junggaebosu_cal_ratio,
  }) => {
    const vatRatio = d_junggaebosu_vat_gani
      ? d_junggaebosu_vat_gani / 100
      : getVatRatio(d_junggaebosu_vat_ratio1) //부가세 비율(간이에 임시로 작성했으면 그 값을 사용)
    const vat = d_junggaebosu * vatRatio //부가세
    const sum = Number(d_junggaebosu) + Number(vat) + Number(d_silbi)

    onEditContract({
      data: getValues(),
      updateData: {
        d_junggaebosu, // 왼쪽 중개보수
        d_junggaebosu_cal, // 오른쪽 중개보수
        d_junggaebosu_cal_ratio, //요율
        d_junggaebosu_vat: vat, //부가세
        d_total_price: sum, //계
        realestate_reward: d_junggaebosu, //계약서 하단 중개보수는 __ 로 한다
        property_type,
        contract_type,
      },
    })

    setValue('d_junggaebosu', d_junggaebosu) // 왼쪽 중개보수
    setValue('d_junggaebosu_cal', d_junggaebosu_cal) // 오른쪽 중개보수
    setValue('d_junggaebosu_cal_ratio', d_junggaebosu_cal_ratio) //요율
    setValue('d_junggaebosu_vat', vat) //부가세
    setValue('d_total_price', sum) //계
    setValue('realestate_reward', d_junggaebosu) //계약서 하단 중개보수는 __ 로 한다
  }

  return (
    <Title mb>
      부 동 산 &#40;
      <TypeSelect
        classNamePrefix="select"
        components={{
          IndicatorSeparator: () => null,
        }}
        isSearchable={false}
        value={getSelectedValue(property_type, propertyList)}
        options={getSimpleSelectboxOptions(propertyList, false)}
        onChange={(e) => onChangeSelectbox('property_type', e.value)}
        isDisabled={channelInfo?.member_type == 'readonly'}
      />
      &#41;
      <TypeSelect
        classNamePrefix="select"
        components={{
          IndicatorSeparator: () => null,
        }}
        isSearchable={false}
        value={getSelectedValue(contract_type, contractTypes)}
        options={getSimpleSelectboxOptions(contractTypes, false)}
        onChange={(e) => onChangeSelectbox('contract_type', e.value)}
        small
        isDisabled={channelInfo?.member_type == 'readonly'}
      />
      계 약 서
    </Title>
  )
}

const TypeSelect = styled(Select)`
  min-width: 150px;
  margin-right: 12px;
  ${({ small }) =>
    small &&
    `width: 125px; 
    min-width: unset;
  margin-left: 8px;`}

  &:disabled {
    .select__control {
      background-color: transparent !important;
    }
  }
`

export default React.memo(NoteDocsContractTitle)
