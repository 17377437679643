import {
  StyledSelect,
  StyledSelectView,
} from 'components/jmapnotev2/style/NoteGridStyle'
import { getPropertyFilter } from 'lib/api/notev2/filterApi'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import useAxios from 'lib/hooks/useAxios'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { setNotePropertyFilter } from 'modules/notev2'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const CustomLandTypeCell = ({ node }) => {
  const noteOptions = useSelector((state) => state.notev2.noteOptions)
  const [options, setOptions] = useState()
  const [defalutIndex, setDefaultIndex] = useState(null)
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)
  const [onRequestModifyNote] = useModifyNote()
  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    isReversePosition,
  } = useCustomSingleCell(node?.data?.status, node?.rowIndex)

  const [onRequestFilter, , dataFilter, ,] = useAxios(getPropertyFilter)

  const dispatch = useDispatch()

  const selectRef = useRef(null)
  const viewRef = useRef(null)

  useEffect(() => {
    //수정모드가 변경되면 방향키 제어를 위해 그리드 데이터의 state도 업데이트 해준다
    updateGridDatas({
      currentRowNode: node,
      newData: {
        ...node.data,
        state: {
          ...node.data.state,
          isEditingDirection: isEditable,
        },
      },
    })

    //수정 모드가 되면 자동 포커스
    if (isEditable) {
      selectRef.current?.focus()
    }
    setIsSelectMenuOpen(isEditable)
  }, [isEditable])

  useEffect(() => {
    if (!noteOptions) return

    setOptions(getSimpleSelectboxOptions(noteOptions.land_types))
  }, [noteOptions])

  useEffect(() => {
    if (options && options.length > 0) {
      const index = options.findIndex(
        ({ value }) => value == node.data.land_type,
      )

      setDefaultIndex(index)
    }
  }, [options, node.data.land_type])

  /**
   * selectbox change
   */
  const onChange = useCallback(
    (e) => {
      //종류 데이터 업데이트
      onRequestModifyNote({
        data: node.data,
        updateData: { land_type: e.value },
        currentRowNode: node,
      })

      //select value 변경
      const index = options.findIndex(({ value }) => value == e.value)
      setDefaultIndex(index)

      //수정모드 해제
      setIsEditable(false)

      //왼쪽 필터 refresh
      onRequestFilter({ channel_srl: node.data.channel_srl })
    },
    [node, options],
  )

  /**
   * 필터 refresh 결과
   */
  useEffect(() => {
    if (!dataFilter || !dataFilter.result) return

    dispatch(setNotePropertyFilter(dataFilter.result?.property || {}))
  }, [dataFilter])

  /**
   * 탭, 엔터, esc 키 동작
   */
  const onKeyDownSelect = useCallback(
    (e) => {
      if (e.keyCode == '27' || e.keyCode == '13' || e.keyCode == '9') {
        setIsEditable(false)
        viewRef.current?.focus()
      }
    },
    [isEditable, viewRef],
  )

  if (node.data.note_srl == '신규') return <></>

  return (
    <Wrapper tabIndex={-1}>
      <StyledSelectView
        ref={viewRef}
        tabIndex={0}
        onDoubleClick={onDoubleClickSingleCell}
        onKeyDown={onKeyDownSingleCell}
        onClick={() => setIsEditable(false)}
      >
        {node.data.land_type}
      </StyledSelectView>
      {isEditable && (
        <LandTypeSelect
          menuShouldScrollIntoView={false}
          ref={selectRef}
          className="basic-single"
          classNamePrefix="select"
          placeholder="선택"
          options={options}
          value={options[defalutIndex]}
          isSearchable={false}
          menuIsOpen={isSelectMenuOpen}
          onMenuOpen={() => setIsSelectMenuOpen(true)}
          onMenuClose={() => {
            setIsSelectMenuOpen(false)
            setIsEditable(false)
          }}
          onChange={onChange}
          onKeyDown={onKeyDownSelect}
          reverse={isReversePosition ? 'Y' : 'N'}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const LandTypeSelect = styled(StyledSelect)`
  ${({ reverse }) =>
    reverse == 'Y' &&
    `
    .select__menu {
      top: -205px;
    }
  `}
`

export default CustomLandTypeCell
