import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useRouteMatch } from 'react-router'

const NoteWelcomeContainer = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const noteListPageMatch = useRouteMatch('/note/v2/notes/:channelSrl')

  useEffect(() => {
    if (
      noteListPageMatch?.isExact &&
      (!localStorage.getItem('isSheetWelcome') ||
        localStorage.getItem('isSheetWelcome') == 'N')
    ) {
      setIsVisible(true)
      localStorage.setItem('isSheetWelcome', 'Y')
    }
  }, [noteListPageMatch?.isExact])

  return children({ isVisible, setIsVisible })
}

export default withRouter(NoteWelcomeContainer)
