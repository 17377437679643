import { PasswordDesc, StyledInput } from './SignupForm'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useAxios from 'lib/hooks/useAxios'
import { checkDupId } from 'lib/api/auth/authApi'

/**
 * 회원가입 아이디
 * @param setIsValidId 아이디 유효성 검증 여부
 * @params register, errors, setValue - useForm 내장 함수
 * @returns
 */
const SignupId = ({ setIsValidId, register, errors }) => {
  const [onRequestCheckDupId, , dataCheckDupId] = useAxios(checkDupId)
  const [dupIdMsg, setDupIdMsg] = useState('') //아이디 중복체크 실패 메시지
  /**
   * 아이디 중복 체크
   * @param {*} e
   */
  const onBlurId = (e) => {
    onRequestCheckDupId({
      user_id: e.target.value,
    })
  }

  /**
   * 아이디 관련 메시지 세팅
   */
  useEffect(() => {
    if (!dataCheckDupId) return

    if (dataCheckDupId?.message == 'success') {
      setIsValidId(true)
      setDupIdMsg('')
    } else {
      setIsValidId(false)
      setDupIdMsg(dataCheckDupId?.message)
    }
  }, [dataCheckDupId])

  return (
    <>
      <StyledInput
        className={errors?.user_id && 'error'}
        {...register('user_id', {
          required: true,
          onBlur: onBlurId,
        })}
      />
      {dupIdMsg && <PasswordDesc>{dupIdMsg}</PasswordDesc>}
    </>
  )
}

export default SignupId
