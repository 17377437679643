import RadioButton from 'components/design/Radio/Radio'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { media } from 'lib/styles/theme'
import { useLayoutEffect } from 'react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

/**
 * 계정관리 폼 - 직위
 */
const NoteAccountRealtorPosition = ({ canEdit, disabled }) => {
  const { watch, setValue } = useFormContext()
  const realtor_position = watch('realtor_position')
  const { isBoss } = useIsAllInOne()

  return disabled ? (
    <span>{realtor_position}</span>
  ) : (
    <RadioWrapper>
      <RadioButton
        size="small"
        label="대표"
        name="realtor_position"
        onChange={() => {
          setValue('realtor_position', '대표')
        }}
        checked={realtor_position == '대표'}
        disabled={!canEdit && (disabled || !isBoss)}
      />
      <RadioButton
        size="small"
        label="소속중개사"
        name="realtor_position"
        onChange={() => {
          setValue('realtor_position', '소속중개사')
        }}
        checked={realtor_position == '소속중개사'}
        disabled={disabled}
      />
      <RadioButton
        size="small"
        label="중개보조원"
        name="realtor_position"
        onChange={() => {
          setValue('realtor_position', '중개보조원')
        }}
        checked={realtor_position == '중개보조원'}
        disabled={disabled}
      />
      <RadioButton
        size="small"
        label="일반 직원"
        name="realtor_position"
        onChange={() => {
          setValue('realtor_position', '일반 직원')
        }}
        checked={realtor_position == '일반 직원'}
        disabled={disabled}
      />
    </RadioWrapper>
  )
}

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.mediumS`
    justify-content: flex-start;
    gap: 2px 12px ;
  `}
`

export default NoteAccountRealtorPosition
