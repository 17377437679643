import { TableTitle } from 'components/jmapnotev2/style/NoteDocsStyle'

import NoteDocsPay13 from './NoteDocsPay13'
import NoteDocsPayInfo from './NoteDocsPayInfo'

const NoteDocsPay = ({ onUpdate }) => {
  return (
    <>
      <TableTitle>III. 중개보수 등에 관한 사항</TableTitle>
      <NoteDocsPay13 onUpdate={onUpdate} />
      <NoteDocsPayInfo onUpdate={onUpdate} />
    </>
  )
}

export default NoteDocsPay
