import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledItem = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;

  color: ${theme.colors.gray[900]};

  transition: 0.2s ease;

  &::after {
    content: '';
    position: absolute;
    left: 1.25rem;
    bottom: 0;
    width: calc(100% - 1.25rem);
    border-bottom: 1px solid ${theme.colors.gray[100]};
  }

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
`

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
`
const SubTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
`

const Body = styled.div`
  flex: 1;
  padding: 0.875rem 1.25rem;
`

const SearchResultItem = ({ item, onClickItem }) => {
  const handleClickItem = () => {
    onClickItem({
      latitude: item.y,
      longitude: item.x,
      isNote: false,
    })
  }

  return (
    <StyledItem>
      <Body onClick={handleClickItem}>
        <Title>{item.title}</Title>
        {item.subTitle && <SubTitle>{item.subTitle}</SubTitle>}
      </Body>
      {/* {loginInfo?.member_type === 'realestate' && (
        <NoteApplyContainer small favorite={0} />
      )} */}
    </StyledItem>
  )
}
export default SearchResultItem
