import { useEffect } from 'react'
import { loadTossPayments } from '@tosspayments/payment-sdk'
const clientKeyByDev = 'test_ck_EP59LybZ8BdlRj0MPKYV6GYo7pRe'
const clientKeyByProcduction = 'live_ck_Z1aOwX7K8meja0vd74Q8yQxzvNPG'

export default function BillingPage({ customerKey, setBillingResult }) {
  useEffect(() => {
    ;(async () => {
      const clientKey =
        process.env.NODE_ENV === 'development'
          ? clientKeyByDev
          : clientKeyByProcduction
      // ------ 클라이언트 키로 객체 초기화 ------
      loadTossPayments(clientKey).then((tossPayments) => {
        // ------ 카드 등록창 호출 ------
        tossPayments
          .requestBillingAuth('카드', {
            // 결제수단 파라미터 (자동결제는 카드만 지원합니다.)
            // 결제 정보 파라미터
            // 더 많은 결제 정보 파라미터는 결제창 Javascript SDK에서 확인하세요.
            // https://docs.tosspayments.com/reference/js-sdk#requestbillingauth카드-결제-정보
            customerKey: customerKey, // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
            successUrl: `${window.location.origin}/jmapnote/api/billing/success`,
            failUrl: `${window.location.origin}/jmapnote/api/payment/fail`,
          })
          // ------ 결제창을 띄울 수 없는 에러 처리 ------
          // 메서드 실행에 실패해서 reject 된 에러를 처리하는 블록입니다.
          // 결제창에서 발생할 수 있는 에러를 확인하세요.
          // https://docs.tosspayments.com/reference/error-codes#결제창공통-sdk-에러
          .catch(function (error) {
            if (error.code === 'USER_CANCEL') {
              // 결제 고객이 결제창을 닫았을 때 에러 처리
              setBillingResult && setBillingResult(null)
            }
          })
      })
    })()
  }, [])

  return <></>
}
