import { useDispatch, useSelector } from 'react-redux'
import TopHeaderItem from 'components/base/TopHeader/TopHeaderItem'
import { setSidemenuVisible } from 'modules/layout'

const TopHeaderProfile = () => {
  const dispatch = useDispatch()
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const onClick = () => {
    dispatch(setSidemenuVisible(true))
  }

  return (
    <TopHeaderItem
      onClick={onClick}
      isLink={false}
      icon={<i className="far fa-bars"></i>}
    />
  )
}

export default TopHeaderProfile
