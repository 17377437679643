import MapContext from 'contexts/map'
import { contractFilterItem, propertyFilterItem } from 'lib/utils/bds'
import { isEqual } from 'lodash'
import { initBdsFilter, setSelectedFilter } from 'modules/bds'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getFormatedArea } from 'utils'

const BdsContentFilterContainer = ({ children }) => {
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false) //모달 visible
  const selectedFilter = useSelector(
    (state) => state.bds.selectedFilter,
  )
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  useEffect(() => {
    const localFilter = localStorage.getItem('bdsFilter')
    if (!localFilter) {
      localStorage.setItem('bdsFilter', JSON.stringify(selectedFilter))
    } else {
      dispatch(setSelectedFilter(JSON.parse(localFilter)))
    }
  }, [])

  /**
   * 필터 모달 열기
   */
  const onClickFilter = () => {
    setModalVisible(true)
  }

  /**
   * 필터 모달 닫기
   */
  const onCancel = () => {
    setModalVisible(false)
  }

  function arraysEqual(a, b) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  /**
   * 선택된 필터 텍스트 가져오기
   * @param {*} key
   * @returns
   */
  const getSelectedText = (key) => {
    if (key == '거래') {
      const selectedLength = selectedFilter['contractType'].length
      const allLength = contractFilterItem.length
      return isEqual(selectedLength, allLength)
        ? '전체'
        : selectedFilter['contractType'].toString()
    } else if (key == '매물') {
      const selectedLength = Object.values(
        selectedFilter['propertyType'],
      ).flat().length
      const allLength = Object.values(propertyFilterItem).flat().length
      return isEqual(selectedLength, allLength)
        ? '전체'
        : Object.values(selectedFilter['propertyType'])
            .flat()
            .toString()
    } else if (key == '면적') {
      return selectedFilter.area.length == 0
        ? '전체'
        : selectedFilter.area
            .map((value, index) => {
              return getFormatedArea(value, isSquareMeter)
            })
            .toString()
            .replace(',', ' ~ ')
    } else if (key == '가격') {
      return arraysEqual(
        selectedFilter.price.deposit,
        initBdsFilter.price.deposit,
      ) &&
        arraysEqual(
          selectedFilter.price.sale_price,
          initBdsFilter.price.sale_price,
        ) &&
        arraysEqual(selectedFilter.price.rent, initBdsFilter.price.rent)
        ? '전체'
        : `${
            arraysEqual(
              selectedFilter.price.sale_price,
              initBdsFilter.price.sale_price,
            )
              ? ''
              : '매매가'
          }${
            arraysEqual(
              selectedFilter.price.deposit,
              initBdsFilter.price.deposit,
            )
              ? ''
              : ', 전세가'
          }${
            arraysEqual(
              selectedFilter.price.rent,
              initBdsFilter.price.rent,
            )
              ? ''
              : ', 월세'
          }`
    }
  }

  return children({
    modalVisible,
    onClickFilter,
    onCancel,
    getSelectedText,
  })
}

export default BdsContentFilterContainer
