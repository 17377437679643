import theme from 'lib/styles/theme'
import { useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getCustomPurpose } from 'lib/utils'

const SummaryAptRpItem = ({
  date,
  contract_type,
  renewal_right_used,
  type,
  price,
  area_type,
  floor,
  canceled_date,
  price_id,
  activeItem,
  onClickItem,
  clickable,
}) => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const getBadge = () => {
    if (canceled_date) {
      return '취'
    } else if (contract_type == '직거래') {
      //직거래
      return '직'
    }
    if (
      (!renewal_right_used || renewal_right_used == 'T') &&
      (contract_type == '갱신' || contract_type == '갱신요구권')
    ) {
      //갱신
      return '갱'
    }
  }

  const badgeData = useMemo(
    () => getBadge(),
    [contract_type, renewal_right_used, canceled_date],
  )

  const isCanceled = canceled_date

  const onClick = (price_id, type) => {
    if (!clickable) return
    onClickItem(price_id, type)
  }

  return (
    <StyledTr
      clickable={
        clickable && getCustomPurpose(summaryInfo?.building) == '아파트'
      }
      active={activeItem == price_id}
      canceled={isCanceled ? 'Y' : 'N'}
      onClick={() => onClick(price_id, type)}
    >
      <td className="date">
        {moment(date, 'YYYY.MM.DD').format('YY.MM.DD')}
      </td>
      <td className="price">
        <PriceWrapper>
          {badgeData || isCanceled ? (
            <Badge type={badgeData ? badgeData : '취'}>
              {badgeData}
            </Badge>
          ) : (
            <EmptySpace />
          )}
          <Type type={type}>{type}</Type>
          <Price>{price}</Price>
        </PriceWrapper>
      </td>
      <td className="center">{area_type}</td>
      <td className="center">{floor}</td>
    </StyledTr>
  )
}

const StyledTr = styled.tr`
  position: relative;

  ${({ clickable }) =>
    clickable &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.gray[100]};
      }  
  `}

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.primary[50]};
  `}

  ${({ canceled }) =>
    canceled == 'Y' &&
    `
     td {
        color: ${theme.colors.gray[400]} !important;
    }
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 1px;
        height: 1px;
        border: 0;
        border-top: 1px solid ${theme.colors.gray[400]};
    }
  `}

  td {
    line-height: 130%;
  }
`

const Type = styled.div`
  font-size: 11px;
  font-weight: 700;
  margin-right: 4px;
  line-height: 100%;
  ${({ type }) => {
    switch (type) {
      case '매매':
        return `
            color: ${theme.colors.summary.rp.blue};
          `
      case '전세':
        return `
            color: ${theme.colors.summary.rp.primary};
          `
      case '월세':
        return `
            color: ${theme.colors.summary.rp.red};
          `
    }
  }};
`

const Price = styled.div`
  font-size: 14px;
  font-weight: 700;
`

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;
  margin-right: 4px;

  ${({ type }) => {
    switch (type) {
      case '직':
        return `
            color: ${theme.colors.summary.rp.direct.color};
            background-color: ${theme.colors.summary.rp.direct.background};
        `
      case '갱':
        return `
            color: ${theme.colors.summary.rp.renewal.color};
            background-color: ${theme.colors.summary.rp.renewal.background};
        `
      case '취':
        return `
            color: ${theme.colors.summary.rp.cancel.color};
            background-color: ${theme.colors.summary.rp.cancel.background};
        `
    }
  }};
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

const EmptySpace = styled.div`
  width: 24px;
`

export default SummaryAptRpItem
