import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as ReportIcon } from 'assets/icon/note/ad/filters/active/report.svg'
import { ReactComponent as SameIcon } from 'assets/icon/note/ad/filters/active/same.svg'
import theme, { media } from 'lib/styles/theme'
import { Button } from 'components/design'
import { useDispatch } from 'react-redux'
import { setSelectedStatus } from 'modules/noteAd'

const NoteAdStatusNoti = ({ data }) => {
  const root = document.getElementById('modal')
  const dispatch = useDispatch()
  const [noties, setNoties] = useState([])

  useEffect(() => {
    if (!data) return
    setNoties(
      data.map((item) => {
        return {
          value: item,
          visible: true,
        }
      }),
    )
  }, [data])

  /**
   * 매물보기 클릭
   * @param {*} type
   */
  const onClickView = (type) => {
    dispatch(setSelectedStatus(type))
    onClose(type)
  }

  /**
   * noti 닫기
   * @param {*} type
   */
  const onClose = (type) => {
    setNoties((prev) =>
      prev.map((item) => {
        if (item.value == type) {
          return {
            ...item,
            ...{ visible: !item.visible },
          }
        } else {
          return item
        }
      }),
    )
  }

  return ReactDOM.createPortal(
    <Wrapper>
      {noties.length > 0 &&
        noties.map((item, i) => {
          if (!item.visible)
            return <Block key={i} order={i} empty></Block>
          return (
            <Block key={i} order={i}>
              <TitleWrapper>
                {item.value == '신고매물' ? (
                  <ReportIcon />
                ) : (
                  <SameIcon />
                )}
                <span>
                  {item.value == '신고매물'
                    ? '허위 매물 신고가 접수되었어요'
                    : '동일 주소 거래 완료가 있어요'}
                </span>
              </TitleWrapper>
              <Buttons>
                <Button
                  size="small"
                  color="default"
                  onClick={() => onClickView(item.value)}
                >
                  매물보기
                </Button>
                <Button
                  size="small"
                  color="blue"
                  onClick={() => onClose(item.value)}
                >
                  닫기
                </Button>
              </Buttons>
            </Block>
          )
        })}
    </Wrapper>,
    root,
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
  z-index: 15;
`

const appearFirst = keyframes`
	0% { opacity: 0; transform:translate3d(0,-100px,0) }
	100% { opacity: 1; transform:translate3d(0,10px,0) }
`

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  max-width: 80vw;
  padding: 8px 16px;
  background: rgba(25, 31, 40, 0.9);
  border-radius: 12px;
  animation: ${appearFirst} 0.2s;
  animation-fill-mode: both;

  ${({ order }) =>
    order == 1 &&
    `
    margin-top: 5px;
    animation-delay: 0.25s;
  `};

  ${({ empty }) =>
    empty &&
    `
      display:none;
  `}

  ${media.mediumS`
    max-width: 95vw;
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  color: ${theme.colors.base.white};

  ${media.mediumS`
    gap: 6px;
    font-size: 12px;
    
    svg {
      width: 16px;
    }
  `}
`

const Buttons = styled.div`
  display: flex;
  gap: 4px;
`

export default NoteAdStatusNoti
