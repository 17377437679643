import { message } from 'antd'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

const NoteDonghoMobileTopHeader = () => {
  const isVisibleHeaderChannel = useSelector(
    (state) => state.notev2.isVisibleHeaderChannel,
  )
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )

  return (
    <Wrapper>
      <AptName>
        {isVisibleHeaderChannel
          ? '동호관리'
          : selectedDanji?.extra_vars?.apt_name}
      </AptName>
      <Address>
        {isVisibleHeaderChannel
          ? ''
          : selectedDanji.extra_vars?.road_address ||
            selectedDanji.extra_vars?.address}
      </Address>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-left: -6px;
`

const AptName = styled.div`
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 17px;
  font-weight: 700;
  line-height: 100%;
`

const Address = styled.div`
  font-size: 10px;
  color: ${theme.colors.gray[800]};
  font-weight: 400;
`

export default NoteDonghoMobileTopHeader
