import { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginVisible } from 'modules/auth'

const DonghoQuickLinkContainer = ({ children, onClose = () => {} }) => {
  const history = useHistory()
  const match = useRouteMatch({ path: '/note/v2/dongho' })

  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const channels = useSelector((state) => state.channel.channels)
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    // 로그인 상태가 아닐 때 로그인창 출력
    if (!loginInfo?.member_srl) {
      dispatch(setLoginVisible(true))
      onClose()
      return
    }
    history.push(`/note/v2/dongho`)
  }, [channels, loginInfo])

  return children({ active: match, onClick })
}

export default DonghoQuickLinkContainer
