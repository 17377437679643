import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import { useEffect } from 'react'

const NoteAdColumnVerificationTypeDescD = ({ type }) => {
  return (
    <>
      <Info
        className="fas fa-question-circle"
        data-tooltip-id="verification-tooltip-d"
      />
      <Tooltip
        id="verification-tooltip-d"
        border={`1px solid ${theme.colors.gray[300]}`}
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.03)`,
          zIndex: 9999,
          padding: '18px 16px',
          width: '340px',
        }}
      >
        <>
          <Title>홍보확인서</Title>
          <Desc>
            <Item>
              홍보확인서는 매도인 또는 임대인 서명을 통해 매물의 거래
              가능 여부를 확인하는 검증 방식입니다.
            </Item>
            <Item>
              매물등록 완료 이후 매물정보가 기입된 홍보확인서 출력이
              가능합니다.
            </Item>
          </Desc>
        </>
      </Tooltip>
    </>
  )
}

const Info = styled.i`
  margin-left: 2px;
  color: ${theme.colors.gray[400]};
  z-index: 999;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};
`

const Item = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};

  &::before {
    content: '\f00c';
    ${({ multiple }) => multiple && `content: '\f00c'`};
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
    color: ${theme.colors.blue[400]};
  }
`

const Desc = styled.div`
  margin-top: 10px;

  ${({ nomargin }) => nomargin && `margin: 0;`};
`
export default NoteAdColumnVerificationTypeDescD
