import { useRef, useState } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

import { Button } from 'components/design'
import { Dropdown } from 'components/design'
import { DropdownDialog } from 'components/design'
import { DropdownHeader } from 'components/design'
import ChannelSave from './ChannelSave'
import ChannelSelectList from './ChannelSelectList'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import useIsMobile from 'lib/hooks/useIsMobile'

const StyledSelectWrap = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  /* align-items: center; */
  height: 100%;
`
const StyledSelectButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 0.25rem 0.5rem 0.25rem 0;

  color: ${theme.colors.gray[900]};
  font-size: 1.125rem;
  font-weight: 700;

  background-color: transparent;
  /* border-radius: ${theme.borderRadius[1]}; */
  border: none;
  outline: none;
  appearance: none;

  & > svg {
    width: 18px;
    height: 18px;
    margin-left: 0.25rem;
    transition: 0.2s ease;
  }

  ${({ active }) =>
    active &&
    `
    border-color: ${theme.colors.gray[400]};
    & > svg {
      transform: rotate(180deg);
    }
  `}

  ${media.mediumS`
    font-size: 1.125rem;
  `}
`

const SelectedChannelName = styled.div`
  /* flex: 1; */
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
`

const StyledTagWrap = styled.div`
  align-self: center;
  white-space: nowrap;
`

const ChannelSelect = ({
  channelSrl,
  selectedChannel,
  myChannels,
  invitedChannels,
  onChange,
  loginInfo,
}) => {
  const [saveVisible, setSaveVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const isMobile = useIsMobile()

  const toggleDropdown = () => {
    setVisible((prev) => !prev)
  }

  const onChangeChannel = (channelSrl, folderSrl) => {
    setVisible(false)
    onChange(channelSrl, folderSrl)
  }

  const ref = useRef()
  useOnClickOutside(ref, () => {
    if (!isMobile) {
      setVisible(false)
    }
  })

  return (
    <>
      <StyledSelectWrap ref={ref}>
        <Dropdown
          visible={visible}
          onClose={() => setVisible(false)}
          overlay={
            <DropdownDialog
              width={240}
              height={400}
              header={<DropdownHeader>채널 선택</DropdownHeader>}
              footer={
                <Button
                  expand
                  color="primary"
                  plain
                  onClick={() => setSaveVisible(true)}
                >
                  채널 생성하기
                </Button>
              }
            >
              {myChannels.length > 0 && (
                <ChannelSelectList
                  title="내 채널"
                  channels={myChannels}
                  selectedChannelSrl={channelSrl}
                  onChange={onChangeChannel}
                  loginInfo={loginInfo}
                />
              )}

              {invitedChannels.length > 0 && (
                <ChannelSelectList
                  title="초대된 채널"
                  channels={invitedChannels}
                  selectedChannelSrl={channelSrl}
                  onChange={onChangeChannel}
                />
              )}
            </DropdownDialog>
          }
        >
          <StyledSelectButton onClick={toggleDropdown} active={visible}>
            <SelectedChannelName>
              {selectedChannel?.name}
            </SelectedChannelName>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </StyledSelectButton>
        </Dropdown>

        <ChannelSave
          visible={saveVisible}
          setVisible={setSaveVisible}
        />

        {/* {!mapMode &&
          selectedChannel?.is_private === 'Y' &&
          selectedChannel?.member_srl === loginInfo.member_srl && (
            <StyledTagWrap>
              <Tag>개인채널</Tag>
            </StyledTagWrap>
          )} */}
      </StyledSelectWrap>
    </>
  )
}

export default ChannelSelect
