import { Tooltip } from 'antd'
import Axios from 'axios'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import useCalendarSave from 'components/jmapnote/calendar/hooks/useCalendarSave'
import NoteCalendarForm from 'components/jmapnote/calendar/NoteCalendarForm'
import useGetCalendars from 'components/jmapnote/calendar/hooks/useGetCalendars'
import theme, { media } from 'lib/styles/theme'
import FloatingButton from 'components/design/Button/FloatingButton'
import useIsMobile from 'lib/hooks/useIsMobile'

const NoteCalendarSaveButton = ({ currentDate, position }) => {
  const [visible, setVisible] = useState(false)
  const isMobile = useIsMobile()

  const onClickButton = () => {
    setVisible(true)
  }
  const { _getCalendars } = useGetCalendars()
  const { _saveCalendar, _saveData } = useCalendarSave()

  useEffect(() => {
    const source = Axios.CancelToken.source()
    if (_saveData?.message !== 'success') return
    setVisible(false)
    _getCalendars(source.token)

    return () => {
      source.cancel()
    }
  }, [_saveData])

  const handleSubmit = (values) => {
    _saveCalendar({
      ...values,
      contact_srls: JSON.stringify(values.contact_srls),
    })
  }

  return (
    <>
      <Tooltip title={isMobile ? '' : '선택한 날짜에 일정 추가'}>
        {position == 'bottom' ? (
          <StyledFloatingButton
            color="blue"
            onClick={onClickButton}
            id="note_dashboard_calendar_add_button"
          >
            <i className="far fa-plus"></i>
          </StyledFloatingButton>
        ) : (
          <StyledButton
            color="blue"
            onClick={onClickButton}
            id="note_dashboard_calendar_add_button"
          >
            <i className="far fa-plus"></i>
          </StyledButton>
        )}
      </Tooltip>
      <NoteCalendarForm
        title={
          <ModifyTitle>
            <div>일정 추가하기</div>
            <ModifyTitleDate>
              {currentDate.format('YYYY')}년 {currentDate.format('MM')}
              월 {currentDate.format('DD')}일
            </ModifyTitleDate>
          </ModifyTitle>
        }
        visible={visible}
        setVisible={setVisible}
        currentDate={currentDate}
        onSubmit={handleSubmit}
      />
    </>
  )
}

const StyledFloatingButton = styled(FloatingButton)`
  right: 25px;
  left: initial;
`

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 34px;
  height: 34px;
  top: 20px;
  right: 20px;
  background-color: ${theme.colors.blue[400]};
  color: ${theme.colors.base.white};
  border-radius: 50%;
  cursor: pointer;

  ${media.mediumS`
    width: 22px;
    height: 22px;
    top: 16px;
    right: 14px;
    font-size: 12px;
  `}
`

const ModifyTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
`

const ModifyTitleDate = styled.div`
  margin-top: 6px;
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`

export default NoteCalendarSaveButton
