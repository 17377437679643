import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDonghoPlusDanji from './NoteDonghoPlusDanji'
import NoteDonghoFavoriteDanji from './NoteDonghoFavoriteDanji'
import NoteDonghoDanjiListContainer from 'containers/notev2/NoteDonghoDanjiListContainer'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import NoteDonghoLeftSideHeader from './NoteDonghoLeftSideHeader'
import { Skeleton } from 'antd'

const NoteDonghoDanjiList = () => {
  const { hasPlan } = useNoteHasPlan()

  return (
    <NoteDonghoDanjiListContainer>
      {({
        paidList,
        normalList,
        onClickDanji,
        searchRefresh,
        loading,
        loadTargetRef,
      }) => {
        return (
          <>
            <NoteDonghoLeftSideHeader
              hideAddButton={
                !loading &&
                paidList.length == 0 &&
                normalList.length == 0
              }
              searchRefresh={searchRefresh}
            />
            <Wrapper>
              {loading && (
                <SkeletonWrapper>
                  <Skeleton active />
                </SkeletonWrapper>
              )}
              {paidList.length == 0 && normalList.length == 0 ? null : (
                <>
                  {hasPlan && (
                    <Section border>
                      <NoteDonghoPlusDanji
                        loading={loading}
                        data={paidList}
                        onClickDanji={onClickDanji}
                      />
                    </Section>
                  )}
                  <Section>
                    <NoteDonghoFavoriteDanji
                      data={normalList}
                      loading={loading}
                      onClickDanji={onClickDanji}
                    />
                    <div
                      ref={loadTargetRef}
                      style={{ height: '10px' }}
                    ></div>
                  </Section>
                </>
              )}
            </Wrapper>
          </>
        )
      }}
    </NoteDonghoDanjiListContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 54px);
`

const SkeletonWrapper = styled.div`
  margin-top: 20px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  ${({ grow }) =>
    grow &&
    `
    flex-grow: 1;
  `}
  ${({ border }) =>
    border &&
    `
        border-bottom: 1px solid ${theme.colors.gray[200]};
        margin-top: 28px;
        padding-bottom: 16px;
        margin-bottom: 16px;
    `};
`

export default NoteDonghoDanjiList
