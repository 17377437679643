import { useCallback, useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  resetAfterAddressChange,
  resetAfterAddressChangeForAd,
  updateAfterAddressChange,
} from 'lib/utils/notev2/gridData'
import moment from 'moment'
import { insertTempAd } from 'lib/api/notev2/ad'
import useAxios from '../useAxios'

/**
 * 광고관리 시트용 주소 저장
 * @returns 주소 저장 함수, 에러 여부 반환
 */
function useTempCreateAd() {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [onRequest, , data, error] = useAxios(insertTempAd)
  const [isError, setIsError] = useState(false)
  const [rowNode, setRowNode] = useState(null)
  const [validAddress, setValidAddress] = useState(null)
  const [callbackFn, setCallbackFn] = useState(null)
  const [invalidInput, setInvalidInput] = useState(null)

  /**
   * 주소 저장
   * @param address_id 주소 id
   * @param validAddressInfo 유효한 주소 getValidAddresses api 리턴 값
   * @param currentRowNode 그리드 현재 row node 값
   * @param callbackFn 콜백함수
   */
  const onSaveTempAd = useCallback(
    ({ address_id, validAddressInfo, currentRowNode, callbackFn }) => {
      if (channelInfo?.member_type == 'readonly') return
      if (validAddressInfo) {
        setValidAddress(validAddressInfo)
      }

      if (currentRowNode) {
        setRowNode(currentRowNode)
      }

      if (callbackFn) {
        setCallbackFn(() => callbackFn)
      }

      setInvalidInput(null)
      onRequest({
        plan_srl: planInfo.allinone_plan_srl,
        address_id,
        display_address: validAddressInfo?.display_address || '',
        dong: validAddressInfo?.dong ? validAddressInfo.dong : '',
        ho: validAddressInfo?.ho ? validAddressInfo.ho : '',
        property_type: validAddressInfo?.property_type,
        contract_area: validAddressInfo?.contract_area,
        exclusive_area: validAddressInfo?.exclusive_area,
        land_area: validAddressInfo?.land_area,
        total_floor_area: validAddressInfo?.total_floor_area,
        building_area: validAddressInfo?.building_area,
      })
    },
    [],
  )

  /**
   * 주소 저장(이상한 주소)
   * @param input 입력한 값
   * @param currentRowNode 그리드 현재 row node 값
   * @param callbackFn 콜백함수
   */
  const onSaveTempAdInvalid = useCallback(
    ({ input, currentRowNode, callbackFn }) => {
      if (channelInfo?.member_type == 'readonly') return

      if (currentRowNode) {
        setRowNode(currentRowNode)
      }

      if (callbackFn) {
        setCallbackFn(() => callbackFn)
      }

      setValidAddress(null)
      setInvalidInput(input)

      onRequest({
        plan_srl: planInfo.allinone_plan_srl,
        address_id: null,
        display_address: input,
      })
    },
    [],
  )

  /**
   * 에러 발생 시
   */
  useEffect(() => {
    if (error) {
      //@ONLYTEST:
      console.error('save temp 에러')
      setRowNode(null)
      setCallbackFn(null)
      setIsError(true)
    }
  }, [error])

  /**
   * 주소 저장 결과
   * 주소 저장 결과로 address_srl과 주소 정보(result)를 얻을 수 있다
   */
  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.error('save temp 에러')
      setRowNode(null)
      setCallbackFn(null)
      setIsError(true)

      return
    }

    //매물 개수 count +1
    //dispatch(setNoteListInfo({ count: count + 1 }))

    if (rowNode) {
      //그리드 데이터 업데이트 데이터 생성

      if (validAddress) {
        const updataData = Object.assign({}, validAddress, {
          type: 'insert',
          regdate: moment().format('YYYY-MM-DD HH:mm:ss'),
          dong: validAddress?.dong || '',
          ho: validAddress?.ho || '',
          land_type: validAddress?.land_type || '',
          address_jibun: validAddress?.full_address || '',
          //  address_srl: data.result.address_srl,
          ad_srl: data?.ad_srl || null,
          status: '작성중',
        })

        //그리드 데이터 업데이트
        updateAfterAddressChange({
          currentRowNode: rowNode,
          ...updataData,
          isAd: true,
        })
      } else {
        resetAfterAddressChangeForAd({
          currentRowNode: rowNode,
          display_address: invalidInput,
          invalid_addr_name: invalidInput,
          status: '작성중',
          isAd: true,
          ad_srl: data?.ad_srl || null,
        })
      }

      //작업 끝났으니 state들 초기화 하기
      setRowNode(null)
      setValidAddress(null)
      setInvalidInput(null)

      if (callbackFn) {
        callbackFn()
        setCallbackFn(null)
      }
    }
  }, [data])

  return {
    onSaveTempAd,
    onSaveTempAdInvalid,
    isError,
  }
}

export default useTempCreateAd
