import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import HeaderBack from 'components/common/Header/HeaderBack'
import { createPortal } from 'react-dom'
import zIndex from 'lib/styles/zIndex'
import { useContext } from 'react'
import BaseContext from 'contexts/baseContext'
import { useSelector } from 'react-redux'
import useIsMobile from 'lib/hooks/useIsMobile'

const SearchHeader = ({ onClose, children }) => {
  const root = document.getElementById('root')
  const {
    state: { isFold },
  } = useContext(BaseContext)
  const isMobile = useIsMobile()

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)

  return createPortal(
    <Wrapper
      isFold={isFold}
      isNoticeBarVisible={isNoticeBarVisible}
      noticeBarHeight={height}
    >
      {!isMobile && (
        <Top>
          <HeaderBack onClose={onClose} />
        </Top>
      )}
      <Bottom>{children}</Bottom>
    </Wrapper>,
    root,
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible ? `${noticeBarHeight}px` : 0};
  z-index: ${zIndex.header};
  width: ${theme.base.container}px;
  background-color: white;
  left: ${theme.base.navWidth}px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  ${media.mediumM`
  top: ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible ? `${noticeBarHeight}px` : `0`};
    left: 0;
  `}

  ${media.mediumS`
    width: 100%;
    top: 64px;
  `}

	${({ isFold }) =>
    isFold &&
    `
			display: none;
	`}
`
const Top = styled.div`
  display: flex;
  align-items: center;
  height: ${theme.base.headerHeight}px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding-left: 44px;
  padding-right: 18px;

  ${media.mediumS`
    position: fixed;
    left: 44px;
    padding-left: 0;
    width: calc(100% - 40px);
  `}
`

export default SearchHeader
