import { useSelector } from 'react-redux'
import SummaryProjectItem from './SummaryProjectItem'
import SummaryProjectList from './SummaryProjectList'

const SummaryProjects = () => {
  const { projects } = useSelector((state) => state.summary.summaryInfo)

  return (
    <SummaryProjectList>
      {projects &&
        projects.map((project, idx) => {
          return (
            <SummaryProjectItem
              key={idx}
              item={project}
            ></SummaryProjectItem>
          )
        })}
    </SummaryProjectList>
  )
}

export default SummaryProjects
