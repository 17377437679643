import { useFormContext } from 'react-hook-form'
import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputWidth,
} from '../../form/NoteAdFormStyle'
import { Button, Modal } from 'components/design'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import NoteAdPromotionFile from './NoteAdPromotionFile'
import { message } from 'antd'
import { uploadFiles } from 'lib/api/notev2/adForm'
import useAxios from 'lib/hooks/useAxios'
import styled from 'styled-components'
import NoteAdColumnVerificationFileItem from './NoteAdColumnVerificationFileItem'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 홍보확인서 confirmdocUrl
 */
const NoteAdColumnPromotionFile = ({ isDisabled: pIsDisabled }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const { watch, register, setValue } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )
  const [onRequest, , data] = useAxios(uploadFiles)
  const inputRef = useRef()
  const { ref, ...rest } = register('verification.confirmdocUrl')
  const adSrl = watch('ad_srl')
  const referenceFiles = watch('confirmdocFiles')

  const [fileList, setFileList] = useState([])
  const isDisabled =
    pIsDisabled ||
    (adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll) //읽기 전용 여부

  useEffect(() => {
    if (!referenceFiles) return

    setFileList(referenceFiles)
  }, [referenceFiles])
  /**
   * 업로드 버튼 클릭 시 input type="file" 태그 클릭
   */
  const onClickUpload = () => {
    inputRef?.current?.click()
  }
  /**
   * 파일 업로드
   * @param {*} e
   */
  const onChangeInput = (e) => {
    if (!e.target.files?.[0]) {
      return
    }

    if (e.target.files?.[0]?.size > 1048576 * 10) {
      message.error(
        '파일 크기가 허용되는 한도 10MB를 초과하여 파일을 업로드할 수 없습니다.',
      )
      return
    }

    const uploadFiles = Array.from(e.target.files)

    uploadFiles.forEach((item, i) => {
      if (
        [
          'application/pdf',
          'image/png',
          'image/jpeg',
          'image/gif',
        ].includes(item.type)
      ) {
        //이미지 파일 처리
        onRequest({
          ad_srl: adSrl,
          fileToUpload: e.target.files[i],
          type: 'confirmdoc',
          plan_srl: planInfo.allinone_plan_srl,
        })
      } else {
        message.info('지원하지 않는 파일 형식입니다.')
        return
      }
    })
  }

  useEffect(() => {
    if (!data) return

    setFileList((prev) => [
      ...prev,
      ...[
        {
          ad_srl: data.ad_srl,
          url: data.url,
          thumbnail: data.thumbnail,
          source_filename: data.source_filename,
          mime_type: data.mime_type,
          uploaded_filename: data.uploaded_filename,
          file_srl: data.file_srl,
        },
      ],
    ])
  }, [data])

  return (
    <>
      <ColumnSet>
        <ColumnTitle>홍보확인서</ColumnTitle>
        <ColumnVal gap="0 10">
          <Wrapper>
            <FileWrapper>
              {fileList.map(
                (
                  {
                    file_srl,
                    thumbnail,
                    source_filename,
                    mime_type,
                    uploaded_filename,
                  },
                  i,
                ) => {
                  return (
                    <NoteAdColumnVerificationFileItem
                      key={i}
                      file_srl={file_srl}
                      thumbnail={thumbnail}
                      source_filename={source_filename}
                      mime_type={mime_type}
                      uploaded_filename={uploaded_filename}
                    />
                  )
                },
              )}
            </FileWrapper>
            <input
              multiple
              style={{ display: 'none' }}
              type="file"
              name="profile_image"
              accept="image/jpeg, image/gif, image/png, application/pdf"
              {...rest}
              onChange={onChangeInput}
              ref={(e) => {
                ref(e)
                inputRef.current = e // you can still assign to ref
              }}
            />
            {!isDisabled && (
              <Buttons>
                <NoteAdPromotionFile />
                <Button
                  color="blue"
                  size="small"
                  onClick={onClickUpload}
                >
                  홍보확인서 등록하기
                </Button>
              </Buttons>
            )}
          </Wrapper>
          <FormDesc>
            <li>
              전자홍보확인서를 등록하지 않으시면 홍보확인서를 이미지
              저장하여 의뢰인의 서명을 받아 검증센터에 팩스로 전송하셔야
              합니다.
            </li>
          </FormDesc>
        </ColumnVal>
      </ColumnSet>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Buttons = styled.div`
  display: flex;
  gap: 4px;
`

export default NoteAdColumnPromotionFile
