import MapContext from 'contexts/map'
import { useContext } from 'react'
import theme from 'lib/styles/theme'

/**
 * 런타임, useAxios에서 에러 발생시 로그 insert
 * 실서버에서만 함
 * @returns
 */
export default function useMapCalcFormula() {
  const { kakao } = window
  const {
    state: { map },
  } = useContext(MapContext)

  /**
   * 마커 그리기
   * @param itemCenter 그릴 마커 center
   * @param setPrevState 이전 마커 세팅 (지우기 용) function
   * @param iconSrc 그릴 아이콘
   */
  const drawMarker = ({ itemCenter, setPrevState, iconSrc }) => {
    // 리액트 컴포넌트 안올라감 html으로 그려야 올라감
    const markerContent = `<img class='blink-center' src='${iconSrc}' /><div class="blink-circle blink-circle--1"></div><div class="blink-circle blink-circle--2"></div><div class="blink-circle blink-circle--3"></div>`

    const marker = new kakao.maps.CustomOverlay({
      position: itemCenter,
      zIndex: 1111,
      content: markerContent,
    })
    marker.setMap(map)

    setPrevState && setPrevState(marker) //이전 마커 세팅 (지우기 용)
  }

  /**
   * 현재 줌 레벨에서 가로 1px당 x좌표(경도) 변화 계산
   */
  const getXPerPixel = () => {
    const bounds = map.getBounds()
    const mapRange =
      bounds.getSouthWest().getLng() - bounds.getNorthEast().getLng()
    const pixels = document.getElementById('map').clientWidth
    return mapRange / pixels
  }

  /**
   * 보이는 지도 경계 가져오기 (콘텐츠 레이어에 가린 부분 제외)
   */
  const getVisibleBounds = () => {
    const coveredWidth = theme.base.container + theme.base.navWidth //왼쪽 가린부분
    const actualBounds = map.getBounds()
    const xDiff = coveredWidth * getXPerPixel()
    const sw = new kakao.maps.LatLng(
      actualBounds.getSouthWest().getLat(),
      actualBounds.getSouthWest().getLng() + xDiff,
    )
    const ne = actualBounds.getNorthEast()
    return new kakao.maps.LatLngBounds(sw, ne)
  }

  /**
   * 오버레이와 폴리곤 사이의 추천 줌 레벨 얻기
   * @param {*} pos 오버레이의 centerpoint coord
   * @returns
   */
  const getZoomLevelPolyAndOverlay = (coord, pos) => {
    //  const coord = centerPoint.coordinates //클릭한 폴리곤
    const polyCenter = new kakao.maps.LatLng(coord[0], coord[1])
    const width = Math.abs(polyCenter.getLng() - pos[1])
    const height = Math.abs(polyCenter.getLat() - pos[0])

    const mapBounds = getVisibleBounds()

    const mapWidth =
      mapBounds.getNorthEast().getLng() -
      mapBounds.getSouthWest().getLng()

    const mapHeight =
      mapBounds.getNorthEast().getLat() -
      mapBounds.getSouthWest().getLat()

    const currentRatio = Math.max(width / mapWidth, height / mapHeight)
    const targetRatio = 0.5
    const factor = Math.round(
      Math.log(targetRatio / currentRatio) / Math.log(2),
    )

    const zoom = map.getLevel() - factor

    return Math.max(Math.min(zoom, 15), 1)
  }

  return { drawMarker, getVisibleBounds, getZoomLevelPolyAndOverlay }
}
