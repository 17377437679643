import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import SummaryHeaderMetaApt from './SummaryHeaderMetaApt'
import SummaryHeaderMetaBasic from './SummaryHeaderMetaBasic'
import SummaryHeaderMetaUseArea from './SummaryHeaderMetaUseArea'
import SummaryHeaderMetaOwner from './SummaryHeaderMetaOwner'

/**
 * summary 최상단 태그들
 * @returns
 */
const SummaryHeaderMeta = ({ building, land }) => {
  const isApt = useSelector((state) => state.summary.isApt)

  return (
    <Meta>
      {/* 용도지역 */}
      <SummaryHeaderMetaUseArea building={building} land={land} />
      {/* 공동 주택 여부에 따라 다르게 보여줌*/}
      {isApt ? (
        <SummaryHeaderMetaApt building={building} land={land} />
      ) : (
        <SummaryHeaderMetaBasic building={building} land={land} />
      )}
      <SummaryHeaderMetaOwner />
    </Meta>
  )
}

const Meta = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 8px;
`

export const MetaItem = styled.span`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 400;
`

export default SummaryHeaderMeta
