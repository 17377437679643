import {
  getReferralByCode,
  insertReferralMatch,
} from 'lib/api/notev2/reward'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import useAxios from 'lib/hooks/useAxios'
import {
  setInviteeInfo,
  setIsEndSignModalVisible,
} from 'modules/inviteReward'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'

const LandingContainer = ({ children }) => {
  const [onRequest, , data] = useAxios(getReferralByCode)
  const wrapperRef = useRef(null)
  const priceRef = useRef(null)
  const params = useParams()
  const history = useHistory()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const inviteeInfo = useSelector(
    (state) => state.inviteReward.inviteeInfo,
  )
  const channels = useSelector((state) => state.channel.channels)
  const dispatch = useDispatch()
  const { hasPlan } = useNoteHasPlan()

  // 로그인 컴포넌트 열고 닫기
  const [isVisibleLogin, setIsVisibleLogin] = useState(false)

  //중개사 테스트 계정
  const [customAccount, setCustomAccount] = useState({ id: '', pw: '' })

  const [onRequestInsertRefer, , dataInsertRefer, errorInsertRefer] =
    useAxios(insertReferralMatch)

  useEffect(() => {
    //파라미터 초대 코드 없으면 초대자 정보 api 호출 안함
    if (!params?.referralCode) return

    onRequest({
      referral_code: params.referralCode,
    })
  }, [])

  useEffect(() => {
    if (!dataInsertRefer) return
  }, [dataInsertRefer])

  useEffect(() => {
    if (!errorInsertRefer) return

    dispatch(setInviteeInfo(null))
  }, [errorInsertRefer])

  useEffect(() => {
    if (!data) return

    //유효하지 않은 초대코드 - 초대자 정보 없음. 리워드 없음.
    if (!data.result) {
      dispatch(setInviteeInfo(null))
      return
    }

    dispatch(setInviteeInfo(data.result))

    //요금제 있으면 리워드 대상자 아님
    if (hasPlan) return

    //로그인 상태 and 초대 받은 내역이 없다면 초대 코드 insert
    if (loginInfo?.member_srl && loginInfo?.has_referral_match != 'Y') {
      onRequestInsertRefer({
        referral_code: data.result.referral_code,
      })
    }
  }, [data])

  /**
   * 지금 시작하기 float 버튼, 요금제 섹션 시작하기 버튼
   */
  const onClickStart = () => {
    //건축주 회원은 초대 리워드 X
    if (
      loginInfo.member_srl &&
      loginInfo?.member_type !== 'realestate'
    ) {
      history.push('/home')
      return
    }
    if (inviteeInfo) {
      if (loginInfo?.member_srl) {
        history.push('/home')

        if (loginInfo?.has_referral_match == 'N') {
          dispatch(setIsEndSignModalVisible(true))
        }
      } else {
        history.push('/inviteAuth')
      }
    } else if (loginInfo?.member_srl) {
      // 모바일 앱은 요금제 화면 접근금지라서 home으로 보냄
      if (
        navigator.userAgent.match(
          /(com\.jootek\.mobile|rhymixpush|RXPUSH)/,
        )
      ) {
        history.push('/home')
      } else {
        // 중개사가 클릭시
        if (hasPlan) {
          // 요금제 있으면 매물 노트 메인으로
          const recentChannelSrl =
            localStorage.getItem('recentChannelSrl')

          if (recentChannelSrl && recentChannelSrl !== 'undefined') {
            // 최근 접속한 채널/폴더 정보가 있을 경우 유효성 체크 후 이동시킵니다.
            const authorizedChannelSrl = channels.filter(
              ({ channel_srl }) =>
                channel_srl === parseInt(recentChannelSrl),
            )[0]?.channel_srl
            history.push(`/note/v2/notes/${authorizedChannelSrl}`)
          } else {
            ///최근 접속 채널 없으면 첫 번 째 걸로 이동
            const myChannel = channels?.filter(
              ({ member_srl }) =>
                member_srl === parseInt(loginInfo.member_srl),
            )[0]

            const channelSrl = myChannel?.channel_srl

            history.push(`/note/v2/notes/${channelSrl}`)
          }
        } else {
          //요금제 없으면 요금제 화면으로 이동
          history.push('/note/v2/payment/plan')
        }
      }
    } else {
      setIsVisibleLogin(true)
    }
  }

  return children({
    wrapperRef,
    priceRef,
    onClickStart,
    customAccount,
    setCustomAccount,
    isVisibleLogin,
    setIsVisibleLogin,
    loginInfo,
  })
}
export default LandingContainer
