import SummaryAptGraph from 'components/summary/SummaryApt/SummaryAptGraph'
import { groupBy, sortBy } from 'lodash-es'
import { useMemo } from 'react'

const SummaryAptGraphContainer = ({ selectedDate, chartData }) => {
  const getTranspiledList = (chartData) => {
    const reversedData = chartData.reverse()

    return reversedData.map((o) => {
      const year = o.contract_date?.slice(0, 4)
      const month = o.contract_date?.slice(4, 6)

      let price = null

      if (o.type === '매매') {
        price = o.price
      } else if (o.type === '전세') {
        price = o.deposit
      }

      return {
        date: year + '-' + month,
        type: o.type,
        price: o.price || null,
        deposit: o.deposit || null,
      }
    })
  }

  const getAverage = (values) => {
    const total = values.reduce(
      (sum, currentValue) => sum + currentValue,
      0,
    )

    return total / values.length
  }

  const getChartData = (chartData) => {
    const transpiledList = getTranspiledList(chartData)

    const grouped = groupBy(transpiledList, (o) => o.date)

    const mappedData = Object.entries(grouped).map(([date, values]) => {
      const saleList = values.filter((e) => e.type === '매매')
      const charterList = values.filter((e) => e.type === '전세')

      const saleAverage = getAverage(saleList.map((e) => e.price))
      const charterAverage = getAverage(
        charterList.map((e) => e.deposit),
      )

      return {
        name: date,
        sale: saleAverage || null,
        charter: charterAverage || null,
        saleCount: saleList.length,
        charterCount: charterList.length,
      }
    })

    const sortedData = sortBy(mappedData, (o) => o.name)

    return sortedData
  }

  const computedChartData = useMemo(
    () => getChartData(chartData),
    [chartData],
  )

  return (
    <SummaryAptGraph
      selectedDate={selectedDate}
      data={computedChartData}
    />
  )
}

export default SummaryAptGraphContainer
