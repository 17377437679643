import { ReactComponent as Pencil } from 'assets/icon/pencil.svg'
import { Button, Input, Modal } from 'components/design'
import { getPropertyFilter } from 'lib/api/notev2/filterApi'
import useAxios from 'lib/hooks/useAxios'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import { setNotePropertyFilter } from 'modules/notev2'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { modifyAddress } from 'lib/api/note/addressApi'

const NoteListHeaderTitleEdit = ({
  title,
  subtitle,
  selectedAddress,
}) => {
  const isShowMap = useSelector(
    (state) => state.notev2.noteListIsShowMap,
  )
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const match = useRouteMatch('/note/v2/notes/:channelSrl')

  const dispatch = useDispatch()
  // 단지명 편집 모달창 활성화
  const [visible, setVisible] = useState(false)

  const [onRequestFilter, , dataFilter, ,] = useAxios(getPropertyFilter)
  // 단지명 편집
  const [onRequest, loading, data, error, reset] =
    useAxios(modifyAddress)
  // 단지명 input값
  const [nameInputValue, setNameInputValue] = useState(subtitle ?? '')

  /**
   * 필터 refresh 결과
   */
  useEffect(() => {
    if (!dataFilter || !dataFilter.result) return

    dispatch(setNotePropertyFilter(dataFilter.result?.property || {}))
  }, [dataFilter])

  const onOk = useCallback(async () => {
    if (!selectedAddress?.code?.[0]) {
      return
    }
    // 주소 별명(alias) 수정 api 요청
    await onRequest({
      channelSrl: match?.params?.channelSrl,
      addressId: selectedAddress?.code?.[0],
      alias: nameInputValue || selectedAddress?.building_name || '',
    })

    //시트 refresh
    //    onClickAllFilter(title)

    //왼쪽 필터 refresh
    onRequestFilter({ channel_srl: match?.params?.channelSrl })

    setVisible(false)
  }, [match, selectedAddress, title, nameInputValue])

  /**
   * 단지명 편집 input 디폴트값 세팅
   */
  useEffect(() => {
    if (!subtitle) return

    // 필터에 단지명은 '읍면동+alias'라서 맨 처음 단어 '읍면동'을 삭제해야 한다.
    const strs = subtitle?.split(' ')
    // 맨 처음 단어 삭제
    strs.shift()
    // 다시 조인
    const result = strs.join(' ')
    setNameInputValue(result)
  }, [subtitle])

  const onCancel = () => {
    setVisible(false)
  }

  const onEnter = (e) => {
    if (e.charCode == 13) {
      onOk()
    }
  }

  return (
    <>
      {subtitle &&
        subtitle != '전체' &&
        ['아파트', '오피스텔'].includes(title) && (
          <>
            {isMobile || isTablet ? (
              <Pencil
                width={16}
                height={16}
                onClick={() => setVisible(true)}
              />
            ) : (
              <Button
                size="small"
                color="primary"
                plain
                onClick={() => setVisible(true)}
              >
                {isShowMap ? (
                  <i className="fa fa-pencil"></i>
                ) : (
                  '단지명 편집'
                )}
              </Button>
            )}
          </>
        )}

      <Modal
        visible={visible}
        title="단지명 편집"
        onCancel={onCancel}
        onOk={onOk}
      >
        <Input
          onKeyPress={onEnter}
          value={nameInputValue}
          placeholder={selectedAddress?.building_name || ''}
          onChange={(e) => setNameInputValue(e?.target?.value)}
          expand
        />
      </Modal>
    </>
  )
}

export default NoteListHeaderTitleEdit
