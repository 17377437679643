import { useSelector } from 'react-redux'
import { AccountInput } from './NoteAccount'
import { useFormContext } from 'react-hook-form'

/**
 * 계정관리 폼 - 상세주소
 */
const NoteAccountAddressDetail = ({ disabled }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { register } = useFormContext()

  return disabled ? (
    <span>{loginInfo?.company_address_raw?.[3] || ''}</span>
  ) : (
    <AccountInput
      expand
      disabled={disabled}
      name="company_address_detail"
      defaultValue={loginInfo?.company_address_raw?.[3] ?? ''}
      design={'box'}
      {...register('company_address_detail')}
    />
  )
}

export default NoteAccountAddressDetail
