import { Modal } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * 매물 특징 가이드 모달
 */
const NoteAdCharacterGuide = ({ onCancel, visible }) => {
  return (
    <Modal
      title="삭제되는 매물 특징 예시"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width={570}
      customSize
    >
      <Section>
        <Title number="01">중개업소 or 전화번호 노출</Title>
        <List>
          <Item>
            풀옵션·세련된인테리어·안정된구조 홍길동부동산 111-1111
          </Item>
          <Item>o 명품아파트 o 내곡단지 o 저희 홍길동과 함께 o</Item>
          <Item>
            홍길동_부산외대인근 공기좋고 전망좋은 한동짜리아파트에요
          </Item>
          <Item>홍길동타운 1등 부동산 2등은 하지 않겠습니다</Item>
          <Item>오피스텔 입구 홍길동부동산</Item>
          <Item>전망트임 아주깔끔 확장형 굿타임 1111 111</Item>
        </List>
      </Section>
      <Section>
        <Title number="02">
          단지내부동산, 전문부동산 등 중개업소 소개
        </Title>
        <List>
          <Item>
            남향 수리깨끗 신일학군 경의선역세권 홍길동전문업소
          </Item>
          <Item>
            한강조망최상_확장 및에어컨2대설치_단지내업소입니다
          </Item>
          <Item>확장형 즉시입주가 해모로전문 1111 1111</Item>
          <Item>단지내 10년차 전문부동산입니다. 전화주세요</Item>
          <Item>
            실사진 수유역2분 풀옵션 신축 깔끔 보증금 조절가 원룸전문
          </Item>
        </List>
      </Section>
      <Section>
        <Title number="03">
          특수문자, 의미없는 자음, 모음, 알파벳 사용 불가
        </Title>
        <List>
          <Item>
            HHHH 52 저렴한 전세 채광 완전 최고 집상태 깔끔 HHHH
          </Item>
          <Item>로얄층 ○ 즉시입주가능 ○ 확장 ○ 신축 첫 입주</Item>
          <Item>확장 □□ 에어컨 □□ 신혼강추</Item>
          <Item>--급급..저렴전세 아주깨끗 구조 좋은 22평형 융무--</Item>
          <Item>단지내조경,,,,,,정남향,,,,,,</Item>
        </List>
      </Section>
      <Section>
        <Title number="04">전화연결 유도 문구 포함</Title>
        <List>
          <Item>
            시세보다저렴한 금매물건입니다. 연락주시면
            친절상담해드립니다.
          </Item>
          <Item>
            급 급매물 입니다. 정남향 확장형 에어컨까지 전화주세요.
          </Item>
          <Item>올확장 올수리 최고의전망 전화주세요</Item>
          <Item>
            성북동대로변 가격조정불가, 자세한 사항은 전화주세요
          </Item>
          <Item>입주가능 로얄층 전화주시면 자세한 상담가능합니다</Item>
        </List>
      </Section>
      <Section>
        <Title number="05">부동산 위치안내</Title>
        <List>
          <Item>1층 입점부동산의 실제물건입니다.</Item>
          <Item>
            저희 홍길동 부동산은 태영아파트 후문 111동과 102동 사이
            중앙상
          </Item>
          <Item>워커힐전문-워커힐상가 1층 홍길동부동산입니다</Item>
          <Item>
            단지입구부동산 넓은거실과 앞베란다있음 안방욕실드레스룸있음
          </Item>
          <Item>단지내상가 1층에 있습니다.</Item>
        </List>
      </Section>
      <Section>
        <Title number="06">
          기타 (블로그, 중개료 할인, 그냥 중개료 수수료 문구 전부,
          홈페이지, 담당자 이름, 독점매물, 매물다량보유, 단독물건 등)
        </Title>
        <List>
          <Item>
            네이버 검색,, 마곡지구 사랑방,, 마곡밸리, 마곡수명산파크
            모든것
          </Item>
          <Item>
            수수료 없음 신축빌라 급매전문 추천매물다량보유 EL시설
          </Item>
          <Item>| 효성입점 | 14평단기 | 전망 | 홍길동실장</Item>
          <Item>귀한매물,전용21평,확장,www.xxx.co.kr</Item>
          <Item>중개수수료 50퍼센트인하 물건다량보유</Item>
        </List>
      </Section>
      <Section>
        <Title number="07">
          ‘평’, ‘평형’, ‘형’, ‘py’ 또는 ‘평’과 ‘형’ 단위를 혼용하여
          사용한 경우
        </Title>
        <List>
          <Item>전망좋은 103동 39평형 귀한매물</Item>
          <Item>
            멋진 올수리 거실 가득한 남산전망까지 78py 저렴한 월세와
            랜트가능
          </Item>
          <Item>
            시세보다저렴 신혼인기평형 전세안고 급급매 순수주거지역
            정보도
          </Item>
          <Item>
            대형평수 방3개 욕실2개 베란다3개 주인사정상 급매처분합니다
          </Item>
          <Item>
            25-남향-역가까운 선호도 높은단지-융자없어요{' '}
            <Desc>평형을 암시하는 숫자 삭제</Desc>
          </Item>
        </List>
      </Section>
      <Section>
        <Title number="07">
          매물 특징에서 벗어난 한자나 영어, 일어
        </Title>
        <List>
          <Item>無無無無無 시세대비 저렴 無無無無無</Item>
        </List>
      </Section>
      <Section>
        <Title number="07">기타 삭제</Title>
        <List>
          <Item>단독매물도 단독주택에 관한 사항이 아니면 삭제</Item>
          <Item>남향이며 입주가능합니다 대출상담해드립니다</Item>
          <Item>
            신길동 중심축 삼성중앙타워 계약시 그곳사과즙 1박스 증정
            <Desc>경품 증정 및 홍보성 문구 삭제</Desc>
          </Item>
          <Item>
            양도세비과세 5년간 특별분양부동산수수료는 없어요
            <Desc>수수료 관련사항 삭제</Desc>
          </Item>
          <Item>
            HGD완벽한 포스의 사무실을 찾는다면
            <Desc>HGD가 홍길동공인중개사를 의미하므로 삭제</Desc>
          </Item>
        </List>
      </Section>
    </Modal>
  )
}

const Title = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
  font-size: 16px;
  font-weight: 700;

  &::before {
    content: ${({ number }) => `"${number}"`};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-color: ${theme.colors.blue[400]};
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.base.white};
  }
`

const Section = styled.div`
  margin-bottom: 24px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Item = styled.div`
  padding-left: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[600]};

  &::before {
    content: '\f00c';
    ${({ multiple }) => multiple && `content: '\f00c'`};
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
    font-size: 12px;
    color: ${theme.colors.blue[400]};
  }
`

const Desc = styled.div`
  padding-left: 16px;
  font-size: 12px;
  font-weight: 500;

  &::before {
    content: '\f05a';
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
    font-weight: 400;
    color: ${theme.colors.blue[400]};
  }
`

export default NoteAdCharacterGuide
