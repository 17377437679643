import { useEffect } from 'react'
import { modifyContact, removeContact } from 'lib/api/note/contactApi'
import NoteContactForm from './NoteContactForm'
import { message } from 'antd'
import useContacts from 'containers/note/contact/hooks/useContacts'
import { Confirm } from 'components/design'
import useAxios from 'lib/hooks/useAxios'

const NoteContactModify = ({
  defaultValue,
  visible,
  setVisible,
  callbackFn,
  removeCallbackFn,
}) => {
  const [onRequest, loading, data, error, reset] =
    useAxios(modifyContact)
  const [_removeContact, , _removeContactData] = useAxios(removeContact)
  const { _getContacts } = useContacts()

  const handleSubmit = (data) => {
    // console.log(data)
    onRequest({ contactSrl: defaultValue?.contact_srl, ...data })
  }

  useEffect(() => {
    if (!data) return
    if (data?.message === 'success') {
      setVisible(false)
      _getContacts()
    }
    callbackFn && callbackFn()
    // console.log(data)
  }, [data])

  const handleRemove = () => {
    Confirm.error({
      title: '정말로 삭제하시겠습니까?',
      okText: '삭제',
      cancelText: '취소',
      onOk: () =>
        _removeContact({ contactSrl: defaultValue?.contact_srl }),
    })
  }

  useEffect(() => {
    if (_removeContactData?.message !== 'success') return

    setVisible(false)
    _getContacts()
    removeCallbackFn && removeCallbackFn()
  }, [_removeContactData])

  useEffect(() => {
    // console.log(error)
    if (error) {
      message.error(error)
    }
  }, [error])

  return (
    <NoteContactForm
      title="연락처 수정"
      defaultValue={defaultValue}
      visible={visible}
      setVisible={setVisible}
      onSubmit={handleSubmit}
      onRemove={handleRemove}
    />
  )
}

export default NoteContactModify
