import { useDispatch } from 'react-redux'
import { setSelectedAptArea } from 'modules/summary'
import { Tab, TabItem } from 'components/design'
import styled from 'styled-components'

const SummaryAptAreaTab = ({
  selectedArea,
  setSelectedArea,
  setIsModalVisible,
  maxAreas,
}) => {
  const dispatch = useDispatch()

  return (
    <Tab size="expand">
      {maxAreas.map((item, index) => {
        return (
          <CustomTabItem
            key={index}
            expand={maxAreas.length > 1}
            onClick={() => {
              setSelectedArea(item)
              dispatch(setSelectedAptArea(item))
            }}
            active={
              selectedArea &&
              selectedArea.supply_area_py === item.supply_area_py &&
              selectedArea.purpose === item.purpose
            }
          >
            <TabTitle>{item.supply_area_py}평</TabTitle>
          </CustomTabItem>
        )
      })}
      {maxAreas.length > 1 && (
        <TabItem onClick={() => setIsModalVisible(true)}>
          <MoreTabItem>
            다른 평형
            <i className="fa fa-angle-right"></i>
          </MoreTabItem>
        </TabItem>
      )}
    </Tab>
  )
}

const MoreTabItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;

  i {
    padding-left: 0.5rem;
  }
`

const TabTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CustomTabItem = styled(TabItem)`
  width: 23%;

  div,
  span {
    ${({ textcolor }) => textcolor && `color : ${textcolor}`};
  }
`

export default SummaryAptAreaTab
