import theme from 'lib/styles/theme'
import CustomModal from './CustomModal'
import styled from 'styled-components'
import InviteImg from 'assets/images/invite-welcome.png'
import { Button } from 'components/design'
import NoteInviteRewardNoti from './NoteInviteRewardNoti'

const NoteInviteJoinOrLoginWelcomeModal = ({
  visible,
  onClose,
  onClickType,
}) => {
  /**
   * 회원가입하기
   */
  const onClickSignup = () => {
    onClickType('signupRealestate')
  }

  /**
   * 로그인하기
   */
  const onClickLogin = () => {
    onClickType('login')
  }

  return (
    <CustomModal
      color="#061C56"
      cancelHide
      visible={visible}
      onCancel={onClose}
      footer={false}
      width={500}
      padding="medium"
    >
      <Title>
        지금 첫 가입하고,
        <br />
        <span>등기열람권 4개</span>를 받아보세요!
      </Title>
      <ImgWrapper>
        <img src={InviteImg} />
      </ImgWrapper>
      <ButtonWrapper>
        <Button onClick={onClickSignup} expand>
          회원가입하기
        </Button>
        <Button onClick={onClickLogin} expand>
          로그인하기
        </Button>
      </ButtonWrapper>
      <ListWrapper>
        <ListTitle>안내</ListTitle>
        <NoteInviteRewardNoti />
      </ListWrapper>
    </CustomModal>
  )
}

const Title = styled.div`
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: ${theme.colors.base.white};
  line-height: 140%;

  span {
    color: #a4d1f4;
    font-weight: 700;
  }
`
const ImgWrapper = styled.div`
  margin-top: 28px;
  text-align: center;
  width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 63px;
`

const ListWrapper = styled.div`
  color: ${theme.colors.base.white};
  border-radius: 12px;
  padding-top: 20px;
`

const ListTitle = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`

export default NoteInviteJoinOrLoginWelcomeModal
