import MapContext from 'contexts/map'
import { setSpecificOpenedMarker } from 'modules/note'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'

function useSetActiveMarker() {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map } = state

  const dispatch = useDispatch()

  const openAddedMarker = (markerData) => {
    dispatch(
      setSpecificOpenedMarker({
        note_srl: markerData.note_srl,
        address_srl: markerData.address_srl,
        address_id: markerData.address_id,
        channel_srl: markerData.channel_srl,
      }),
    )

    const findAddrAndMoveMap = () => {
      const centerpoint = [markerData.latitude, markerData.longitude] // e.data.centerpoint

      //레이어만큼 밀어야함(지도를 가린 부분)
      const mapProjection = map.getProjection()
      const latlng = new kakao.maps.LatLng(
        centerpoint[0],
        centerpoint[1],
      )
      const currOffset = mapProjection.pointFromCoords(latlng) // 지도 좌표에 해당하는 위치 좌표
      const resizableLayerWidth = window
        .getComputedStyle(
          document.getElementById('note-sheet-resize-layer'),
        )
        .getPropertyValue('width')
        .replace('px', '') //레이어 width
      const addPixelX = Number(resizableLayerWidth)

      // 카카오 pixel 포인트 생성
      const point = new kakao.maps.Point(
        currOffset.x - addPixelX / 2,
        currOffset.y,
      )

      //pixel을 좌표로 변환
      const realPoint = mapProjection.coordsFromPoint(point)

      map.panTo(
        new kakao.maps.LatLng(realPoint.getLat(), realPoint.getLng()),
      )
    }

    findAddrAndMoveMap()
  }

  return { openAddedMarker }
}

export default useSetActiveMarker
