import SummaryLegalInfoHeader from 'components/summary/SummaryLegal/SummaryLegalInfoHeader'
import { getAreaMainTitle } from 'components/summary/SummaryLegal/SummaryLegalAreaInfoContainer'
import { Help } from 'components/summary/SummaryLegal/SummaryLegalCourtInfoContainer'
import {
  Icon,
  Label,
  StyledList,
  Value,
} from 'components/summary/SummaryLegal/SummaryLegalStyle'
import { Card, CardBody, CardHeader } from 'components/design'

const SummaryHcLegal = ({ data }) => {
  return (
    <Card>
      <CardHeader>
        <SummaryLegalInfoHeader
          smallTitle="국토의 계획 및 이용에 관한 법률"
          mainTitle={getAreaMainTitle(data?.zone_use1)}
        />
      </CardHeader>
      <CardBody>
        <StyledList>
          <li>
            <Label>
              <Icon>
                <i className="fal fa-city"></i>
              </Icon>
              용도지역
            </Label>
            <Value>
              {data?.zone_use1}({data?.zone_use1_ratio}%)
            </Value>
          </li>
          {/* <li>
            <Label>
              <Icon>
                <i className="fal fa-object-group"></i>
              </Icon>
              용도지역 기준 최대
            </Label>
            <Value>
              건폐율{Math.floor(data?.coverage_ratio_mean)}% / 용적률
              {Math.floor(data?.floor_area_ratio_mean)}%
            </Value>
          </li> */}
        </StyledList>

        <Help>
          <i className="fal fa-info-circle" />
          현재 시점의 자세한 사업지구 정보는 해당 지자체를 통해
          확인하시기 바랍니다.
        </Help>
      </CardBody>
    </Card>
  )
}

export default SummaryHcLegal
