import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 매도/임대 의뢰인명
 */
const NoteAdColumnSellerName = ({ text, isDisabled }) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const ownerType = watch('verification.ownerType')
  const verificationType = watch('verification.type')
  const tradeType = watch('trade.type')

  if (verificationType == 'O' || verificationType == 'M') return null

  const getTitle = () => {
    if (verificationType == 'D') {
      return '의뢰인명'
    } else if (verificationType == 'N') {
      if (ownerType == '0') {
        return '등기부 소유자명'
      } else {
        return '의뢰인명'
      }
    } else if (verificationType == 'T') {
      //전화확인
      if (tradeType == 'A1') {
        //매매
        return '매도인명'
      } else {
        //매매 외
        return '임대 의뢰인명'
      }
    } else {
      return '매도자명'
    }
  }

  return (
    <ColumnSet half>
      <ColumnTitle required>{getTitle()}</ColumnTitle>
      <ColumnVal direction="column">
        <div>
          <InputText
            width={InputWidth}
            type="text"
            {...register('verification.name')}
            onBlur={(e) => {
              onUpdate('verification.name', e.target.value)
            }}
            disabled={isDisabled}
          />
        </div>
        <FormDesc>
          <li>{text ? text : '한글만 입력해 주세요.'}</li>
          {verificationType == 'N' && ownerType != '0' && (
            <li>법인명 불가, 사람 이름을 적어 주세요</li>
          )}
        </FormDesc>
        <ValidateWarn>
          {errors?.verification?.name?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnSellerName
