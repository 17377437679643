import { CardTitle } from 'components/design'
import theme from 'lib/styles/theme'
import styled, { css } from 'styled-components'

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: ${({ topspace }) => topspace && `${topspace}px`};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 22px;

  ${({ primary }) =>
    primary == 'Y' &&
    `
      i, div {
        color: ${theme.colors.primary[500]};
      }
  `}

  ${({ height }) => height && `height: ${height}px`};
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[600]};
  font-weight: 400;
  min-width: 110px;
  font-size: 14px;
`

export const LabelIcon = styled.i`
  min-width: 20px;
  color: ${theme.colors.gray[700]};
  font-size: 16px;
`

export const Value = styled.div`
  flex: 1;
  color: ${theme.colors.gray[900]};
  font-size: 14px;
  font-weight: 400;

  ${({ parking }) =>
    parking &&
    `
    & > span {
      &:has(+ span) {
        &::after {
          content: ', ';
        }
      }
    }
  `}
`

export const Help = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 400;
  i {
    margin-right: 0.25rem;
  }
`

export const StyledCardTitle = styled(CardTitle)`
  margin-bottom: 20px;
`

export const moreViewTableCss = css`
  ${({ viewcnt }) =>
    viewcnt &&
    `
    tbody > tr {
      &:nth-child(n + ${viewcnt + 1}) {
        display: none;
      }
    }
  `}
`

export const moreViewBasicCss = css`
  ${({ viewcnt }) =>
    viewcnt &&
    `
    &> div {
      &:nth-child(n + ${viewcnt + 1}) {
        display: none;
      }
    }
  `}
`

export const moreViewListCss = css`
  ${({ viewcnt }) =>
    viewcnt &&
    `
    & > li {
      &:nth-child(n + ${viewcnt + 1}) {
        display: none;
      }
    }
  `}
`
