import { useState, useContext } from 'react'
import {
  billionFormate,
  numberWithCommas,
  getFormatedAreaSecondDigit,
} from 'utils'
import { priceFormatMilion } from 'lib/utils/priceFormat'
import MapContext from 'contexts/map'
import { Card, CardHeader } from 'components/design'
import { CardBody } from 'components/design'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import {
  HelpCardHeader,
  Title,
  Price,
  HelpIcon,
  InfoWrap,
  Hr,
} from '../SummarySizeReview/SummarySizeReviewStyle'

const MonthlyPriceWrap = styled.div`
  display: flex;
  width: 100%;
  color: ${theme.colors.blue[400]};

  & > div {
    width: 20%;
    text-align: center;
    ${({ strong }) => strong && `font-weight: 700;`}
  }
`

const MonthlyPriceHeader = styled(MonthlyPriceWrap)`
  font-size: 0.93rem;
`

const MonthlyPriceItem = styled(MonthlyPriceWrap)`
  font-size: 0.83rem;
  margin: 0.7rem 0;
`

const SummaryNohuRent = ({
  data,
  setIsInfoHide,
  type,
  isRentalAll,
}) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const totalRent = data.result.buildings[0]?.unit_groups
    .filter((item) => {
      if (isRentalAll) {
        return item
      } else if (!isRentalAll) {
        return item.extend_type == 'extend'
      }
    })
    .map(({ unit_count, rent }) => {
      return unit_count * rent * 12
    })
    .reduce((a, b) => a + b)

  return (
    <Card>
      <CardHeader>
        <HelpCardHeader>
          <Title>
            지금 건축하시면 매년 <br />
            월세
            <Price>
              총&nbsp;
              {numberWithCommas(totalRent) + '만'}원
            </Price>
            을 받을 수 있어요
          </Title>
          <HelpIcon onClick={() => setIsInfoHide(false)}>
            <i className="fas fa-info-circle"></i>
          </HelpIcon>
        </HelpCardHeader>
      </CardHeader>
      <CardBody>
        <InfoWrap background={theme.colors.blue[50]}>
          <MonthlyPriceHeader strong={true}>
            <div>분류</div>
            <div>임대면적</div>
            <div>세대</div>
            <div>보증금</div>
            <div>월세</div>
          </MonthlyPriceHeader>
          <Hr />
          {data.result.buildings[0].unit_groups.map(
            (
              {
                name,
                use_area,
                unit_count,
                deposit,
                rent,
                extend_type,
              },
              index,
            ) => {
              if (!isRentalAll && extend_type == 'original') {
                return
              } else {
                return (
                  <MonthlyPriceItem key={index}>
                    <div>{name}</div>
                    <div>
                      {getFormatedAreaSecondDigit(
                        use_area,
                        isSquareMeter,
                      )}
                    </div>
                    <div>
                      {unit_count && unit_count > 0
                        ? unit_count + '세대'
                        : '-'}
                    </div>
                    <div>
                      {numberWithCommas(
                        billionFormate(deposit * 10000),
                      ) == '0원'
                        ? '0'
                        : numberWithCommas(
                            billionFormate(deposit * 10000),
                          )}
                      원
                    </div>
                    <div>{rent ? `${rent}만원` : '-'}</div>
                  </MonthlyPriceItem>
                )
              }
            },
          )}
        </InfoWrap>
      </CardBody>
    </Card>
  )
}

export default SummaryNohuRent
