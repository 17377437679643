import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 지번 노출 집주인 동의여부
 */
const NoteAdColumnJibunAgree = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const jibunAddrExpsYn = watch(`article.jibunAddrExpsYn`)
  const category2 = watch(`article.category2`)

  if (!['단독', '전원주택', '한옥주택'].includes(category2)) return
  return (
    <ColumnSet half>
      <ColumnTitle required>지번 노출 집주인 동의여부</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          size="small"
          label="예"
          name="jibun_agree"
          value="Y"
          checked={jibunAddrExpsYn === 'Y'}
          {...register('article.jibunAddrExpsYn')}
          onChange={(e) => {
            onUpdate('article.jibunAddrExpsYn', e.target.value)
          }}
        />
        <RadioButton
          size="small"
          label="아니오"
          name="jibun_agree"
          value="N"
          {...register('article.jibunAddrExpsYn')}
          checked={jibunAddrExpsYn === 'N'}
          onChange={(e) => {
            onUpdate('article.jibunAddrExpsYn', e.target.value)
          }}
        />
        <ValidateWarn>
          {errors?.article?.jibunAddrExpsYn?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnJibunAgree
