import SummrayEstimatePrice from 'components/summary/SummaryEstimatePrice/SummrayEstimatePrice'
import { Section, Title } from './BdsDetailStyle'
import SummaryRealPriceGraph from 'components/summary/SummaryRealPrice/SummaryRealPriceGraph'
import SummaryRealPrice from 'containers/summary/SummaryRealPrice'
import SummaryAptPriceContainer from 'containers/summary/SummaryAptPriceContainer'
import React, { useMemo } from 'react'

const BdsDetailRp = ({ data, tabs }) => {
  const isApt = useMemo(
    () =>
      ['아파트', '오피스텔', '연립주택', '다세대주택'].includes(
        data?.property_type || '',
      ),
    [data?.property_type],
  )

  return (
    <Section ref={tabs[2].element} nopadding noborder>
      <Title padding>실거래가</Title>
      {isApt ? (
        <>
          <SummaryAptPriceContainer
            areaTypes={data.area_types}
            addressId={data.address_id}
            visiblePy={false}
            noTitle={true}
          />
        </>
      ) : (
        <>
          {data?.prices && (
            <SummrayEstimatePrice
              prices={data?.prices}
              subAddresses={data?.sub_addresses}
            />
          )}
          {data?.prices && (
            <SummaryRealPriceGraph
              prices={data.prices}
              landArea={data.land_area}
            />
          )}
          <SummaryRealPrice
            table={data.table}
            landArea={data.land_area}
            currentAddressId={data.address_id}
          />
        </>
      )}
    </Section>
  )
}

export default React.memo(BdsDetailRp)
