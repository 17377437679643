import { createContext, useState } from 'react'
import moment from 'moment'
import 'moment/locale/ko'

const CalendarContext = createContext()

export const CalendarProvider = ({ children }) => {
  const [schedules, setSchedules] = useState([])
  const [currentDate, setCurrentDate] = useState(moment())
  const [viewMode, setViewMode] = useState('calendar')

  const value = {
    state: {
      schedules,
      currentDate,
      viewMode,
    },
    actions: {
      setSchedules,
      setCurrentDate,
      setViewMode,
    },
  }

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  )
}

export default CalendarContext
