import React from 'react'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
} from '../../form/NoteAdFormStyle'
import { options } from '../../options'
import { useFormContext } from 'react-hook-form'
import useIsMobile from 'lib/hooks/useIsMobile'
import RadioButton from '../../elements/RadioButton'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 중개업소 정보 연락처 노출방식
 */
const NoteAdColumnContact = () => {
  const { register, watch } = useFormContext()
  const { onUpdate } = useAdUpdate()
  const list = options['연락처 노출 선택']

  const isMobile = useIsMobile()
  const exps = watch('realtor.exps')

  return (
    <ColumnSet>
      <ColumnTitle required>연락처 노출방식</ColumnTitle>
      <ColumnVal gap={isMobile ? 12 : 40}>
        {Object.entries(list).map(([key, value], i) => {
          return (
            <React.Fragment key={value}>
              <RadioButton
                fit
                label={value}
                name="realtor_exps"
                value={key}
                {...register('realtor.exps')}
                checked={exps === key}
                onChange={(e) => {
                  onUpdate('realtor.exps', e.target.value)
                }}
              />
            </React.Fragment>
          )
        })}
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnContact
