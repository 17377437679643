import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { PropertyListItem } from '../NoteFilter'
import { Skeleton } from 'antd'
import { useSelector } from 'react-redux'

const NoteDonghoPlusDanji = ({ data, onClickDanji, loading }) => {
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )

  return (
    <>
      <Title>
        <div>플러스단지</div>
        {/* @PLUSDANJI:  */}
        <div>{data.length}/1</div>
      </Title>
      {/* @PLUSDANJI:  */}
      <>
        {loading && <Skeleton active />}
        {data && data.length > 0 ? (
          data.map((item, i) => {
            return (
              <PropertyListItem
                data-id={item.id}
                className="dongho-danji-item"
                hover
                key={i}
                small
                smallpadding
                onClick={() => onClickDanji(item)}
                active={selectedDanji?.id == item.id}
                //onClick={(e) => onClickFilter(type)}
              >
                <Number active={selectedDanji?.id == item.id}>
                  {i + 1}
                </Number>
                <Name paid>{item.apt_name}</Name>
              </PropertyListItem>
            )
          })
        ) : (
          <Empty>등록된 플러스 단지가 없어요</Empty>
        )}
      </>
    </>
  )
}

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: ${theme.colors.gray[400]};
`

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${theme.colors.gray[200]};
  color: ${theme.colors.gray[600]};
  font-size: 10px;
  font-weight: 700;
  line-height: 180%;
  margin-right: 6px;

  ${({ active }) =>
    active &&
    `
        background-color: ${theme.colors.blue[400]};
        color: ${theme.colors.base.white};
    `}
`

export const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ paid }) => !paid && `margin-left: 6px;`}
`

export const Empty = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #c2c9d1;
`

export default NoteDonghoPlusDanji
