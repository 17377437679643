import theme from 'lib/styles/theme'
import { ReactComponent as FilterIcon } from 'assets/icon/bds/filter.svg'
import styled from 'styled-components'
import BdsContentFilterModal from './BdsContentFilterModal'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { FreeMode } from 'swiper'
import 'swiper/swiper.scss' // core Swiper
import BdsContentFilterContainer from 'containers/bds/BdsContentFilterContainer'

const selectedFilterTitles = {
  거래: 'contractType',
  매물: 'propertyType',
  가격: 'price',
  면적: 'area',
}

/**
 * 주택부동산
 * 필터 영역
 */
const BdsContentFilter = () => {
  return (
    <BdsContentFilterContainer>
      {({ modalVisible, onClickFilter, onCancel, getSelectedText }) => {
        return (
          <>
            <Wrapper onClick={onClickFilter}>
              <FilterButton>
                <FilterIcon />
              </FilterButton>
              <StyledSwiper
                slidesPerView="auto"
                modules={[FreeMode]}
                freeMode
              >
                {Object.entries(selectedFilterTitles).map(
                  ([key, value], i) => {
                    return (
                      <StyledSwiperSlide key={`selected_filter_${i}`}>
                        <FilterTitle>{key}</FilterTitle>
                        <FilterContent>
                          {getSelectedText(key)}
                        </FilterContent>
                      </StyledSwiperSlide>
                    )
                  },
                )}
              </StyledSwiper>
            </Wrapper>
            <BdsContentFilterModal
              visible={modalVisible}
              onCancel={onCancel}
            />
          </>
        )
      }}
    </BdsContentFilterContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  cursor: pointer;
`

const StyledSwiper = styled(Swiper)`
  margin-left: 0;
  margin-right: 0;
`

const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  gap: 4px;
  padding: 8px 10px;
  position: relative;
  width: auto !important;
  max-width: 150px;
  overflow: hidden;
  border-radius: 999px;
  border: 1px solid ${theme.colors.gray[200]};
  &:not(:last-child) {
    margin-right: 8px;
  }
`

const FilterTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  flex-shrink: 0;
`

const FilterContent = styled.div`
  color: #484d56;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  flex-shrink: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 86%;
`

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 12px;
  background-color: ${theme.colors.blue[400]};
  box-shadow: 0px 5px 14px 0px rgba(97, 141, 255, 0.47);
  flex-shrink: 0;
  cursor: pointer;
`

export default BdsContentFilter
