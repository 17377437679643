import {
  HelpCardHeader,
  Title,
  Price,
  HelpIcon,
  InfoWrap,
  BuildingPriceInfo,
  Hr,
} from './SummarySizeReviewStyle'
import { priceFormatMilion } from 'lib/utils/priceFormat'
import { Card, CardHeader, CardBody } from 'components/design'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const BuildingPriceSumWrap = styled.div`
  color: ${theme.colors.primary[900]};

  div {
    font-size: 1.05rem;
  }
`

const SummarySizeReviewRevenue = ({ data, setIsInfoHide }) => {
  return (
    <Card>
      <CardHeader>
        <HelpCardHeader>
          <Title>
            지금 건축하시면
            <Price>
              약&nbsp;
              {data.result.buildings[0].investment_info.revenue_rate}%
            </Price>
            의 <br />
            수익률을 예상할 수 있어요
          </Title>
          <HelpIcon onClick={() => setIsInfoHide(false)}>
            <i className="fas fa-info-circle"></i>
          </HelpIcon>
        </HelpCardHeader>
      </CardHeader>
      <CardBody>
        <InfoWrap background={theme.colors.primary[50]}>
          <BuildingPriceInfo fontColor={theme.colors.primary[700]}>
            <div>사업비용</div>
            <div>
              <strong>
                {priceFormatMilion(
                  (data.result.buildings[0].investment_info
                    .construct_cost +
                    data.result.buildings[0].investment_info
                      .purchase_price) *
                    10000,
                )}
                원
              </strong>
            </div>
          </BuildingPriceInfo>
          <BuildingPriceInfo fontColor={theme.colors.primary[700]}>
            <div>실투자금</div>
            <div>
              <strong>
                {priceFormatMilion(
                  (data.result.buildings[0].investment_info
                    .construct_cost +
                    data.result.buildings[0].investment_info
                      .purchase_price -
                    data.result.buildings[0].investment_info
                      .deposit_sum) *
                    10000,
                )}
                원
              </strong>
            </div>
          </BuildingPriceInfo>
          {/* <BuildingPriceInfo fontColor={theme.colors.primary[700]}>
            <div>임대 보증금 수익</div>
            <div>
              <strong>
                {priceFormatMilion(
                  data.result.buildings[0].investment_info.deposit_sum,
                  true,
                )}
              </strong>
              원
            </div>
          </BuildingPriceInfo>*/}
          <BuildingPriceInfo fontColor={theme.colors.primary[700]}>
            <div>임대 월세 수익</div>
            <div>
              <strong>
                {priceFormatMilion(
                  data.result.buildings[0].investment_info
                    .yearly_rent_sum * 10000,
                )}
                원
              </strong>
            </div>
          </BuildingPriceInfo>
          <Hr />
          <BuildingPriceSumWrap>
            <BuildingPriceInfo fontColor={theme.colors.primary[700]}>
              <div>
                <strong>연 임대 수익률</strong>
              </div>
              <div>
                <strong>
                  {
                    data.result.buildings[0].investment_info
                      .revenue_rate
                  }
                  %
                </strong>
              </div>
            </BuildingPriceInfo>
          </BuildingPriceSumWrap>
        </InfoWrap>
      </CardBody>
    </Card>
  )
}

export default SummarySizeReviewRevenue
