import theme, { media } from 'lib/styles/theme'
import { rgba } from 'polished'
import styled from 'styled-components'
import useIsTablet from 'lib/hooks/useIsTablet'
import { useContext } from 'react'
import BaseContext from 'contexts/baseContext'

const Map = ({ visible, shift, children, append }) => {
  const {
    state: { isFold },
  } = useContext(BaseContext)
  const isTablet = useIsTablet()

  return (
    <Wrapper
      visible={visible}
      shift={shift}
      isTablet={isTablet}
      isFold={isFold}
    >
      <MapBox>
        <div id="map">{children}</div>
        {append}
      </MapBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: ${theme.base.container}px;
  margin: 0;
  bottom: 0;

  user-select: none;
  -webkit-touch-callout: none;
  -webkit-touch-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  ${({ isTablet }) =>
    isTablet &&
    `
		padding-left: ${theme.base.container}px;
  `}

  ${({ isFold }) =>
    isFold &&
    `
		padding-left: 0;
`}

  ${media.mediumM`
    bottom: ${theme.base.bottomMenuHeight}px;
  `}

  ${media.mediumS`
    padding-left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 12;
    top: 0;
  `}

  ${({ shift }) =>
    shift &&
    `
    padding-left: ${
      theme.base.navWidth +
      theme.base.container +
      theme.base.externalWidth -
      theme.base.noteSidebarWidth
    }px;
  `}

  ${({ visible }) =>
    !visible &&
    `
    display: none;
  `}
`
const MapBox = styled.div`
  position: relative;
  height: 100%;

  #map {
    width: 100%;
    height: 100%;
  }
  #map > div {
    cursor: pointer !important;
  }

  #mapWrapper {
    width: 100%;
    height: 300px;
    z-index: 1;
  }

  #container {
    overflow: hidden;
    height: 300px;
    position: relative;
    &.view_roadview {
      #mapWrapper {
        width: 50%;
      }
    }
  }
  #close {
    position: absolute;
    padding: 4px;
    top: 5px;
    left: 5px;
    cursor: pointer;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #c8c8c8;
    box-shadow: 0px 1px #888;

    .img {
      display: block;
      background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/rv_close.png')
        no-repeat;
      width: 14px;
      height: 14px;
    }
  }
`

export default Map
