import { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { setIsVisibleBottomNav } from 'modules/notev2'
import { useEffect } from 'react'
import NotePaymentRule from './NotePaymentRule'
import NotePaymentInfo from './NotePaymentInfo'
import { useDispatch, useSelector } from 'react-redux'
import NotePaymentPayFail from './NotePaymentPayFail'
import NotePaymentDeunggibuPriceList from './NotePaymentDeunggibuPriceList'
import { Block, ContentTitle } from '../style/NotePaymentStyle'
import NotePaymentList from './NotePaymentList'

/**
 * 결제관리 메인
 * 올인원 요금제
 */
const NotePaymentContentAllInOne = () => {
  const dispatch = useDispatch()
  const planInfo = useSelector((state) => state.auth.planInfo)

  useEffect(() => {
    dispatch(setIsVisibleBottomNav(true))
  }, [])

  return (
    <>
      {planInfo?.error && <NotePaymentPayFail />}
      <HalfBlockWrapper>
        <NotePaymentInfo />
        <NotePaymentDeunggibuPriceList />
      </HalfBlockWrapper>
      <ContentTitle>결제내역</ContentTitle>
      <Block nopadding>
        <NotePaymentList />
      </Block>
      <NotePaymentRule />
    </>
  )
}

const HalfBlockWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 34px;
  width: 100%;
  margin-bottom: 44px;

  ${media.mediumM`
    flex-direction: column;
    gap: 30px;
  `}
`
export default NotePaymentContentAllInOne
