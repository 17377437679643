import theme from 'lib/styles/theme'
import { useState } from 'react'
import styled from 'styled-components'
import NoteRoadViewContainer from './NoteRoadViewContainer'
import { useFormContext } from 'react-hook-form'
import { ReactComponent as RoadviewIcon } from 'assets/icon/roadview.svg'

const Roadview = () => {
  const [isVisible, setIsVisible] = useState(false)

  const { watch } = useFormContext()
  const latitude = watch('latitude')
  const longitude = watch('longitude')
  const address_id = watch('address_id')

  return (
    address_id && (
      <Wrapper
        className="note-detail-roadview-icon"
        onClick={() => setIsVisible(true)}
      >
        <RoadviewIcon />

        <NoteRoadViewContainer
          isVisible={isVisible}
          onClose={(e) => {
            e && e.stopPropagation()
            setIsVisible(false)
          }}
          centerpoint={[latitude, longitude]}
        />
      </Wrapper>
    )
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 6px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`

export default Roadview
