import styled from 'styled-components'
import NoteAdColumnPropertyType from '../columns/basic/NoteAdColumnPropertyType'
import { Table, TableWrapper, Title } from './NoteAdFormStyle'
import NoteAdColumnAddress from '../columns/basic/NoteAdColumnAddress'
import NoteAdColumnBuildingUse from '../columns/basic/NoteAdColumnBuildingUse'
import NoteAdColumnFloor from '../columns/basic/NoteAdColumnFloor'
import NoteAdColumnTotalFloor from '../columns/basic/NoteAdColumnTotalFloor'
import NoteAdColumnFloorAgree from '../columns/basic/NoteAdColumnFloorAgree'
import NoteAdColumnFloorMultiple from '../columns/basic/NoteAdColumnFloorMultiple'
import NoteAdColumnRoomCount from '../columns/basic/NoteAdColumnRoomCount'
import NoteAdColumnBathroomCount from '../columns/basic/NoteAdColumnBathroomCount'
import NoteAdColumnDirection from '../columns/basic/NoteAdColumnDirection'
import NoteAdColumnDoor from '../columns/basic/NoteAdColumnDoor'
import NoteAdColumnParking from '../columns/basic/NoteAdColumnParking'
import NoteAdColumnTotalParkingCount from '../columns/basic/NoteAdColumnTotalParkingCount'
import NoteAdColumnParkingCount from '../columns/basic/NoteAdColumnParkingCount'
import NoteAdColumnContractType from '../columns/price/NoteAdColumnContractType'
import NoteAdColumnSalePrice from '../columns/price/NoteAdColumnSalePrice'
import NoteAdColumnDeposit from '../columns/price/NoteAdColumnDeposit'
import NoteAdColumnRent from '../columns/price/NoteAdColumnRent'
import NoteAdColumnConvertJeonse from '../columns/price/NoteAdColumnConvertJeonse'
import NoteAdColumnLoan from '../columns/price/NoteAdColumnLoan'
import NoteAdColumnAlreadyDeposit from '../columns/price/NoteAdColumnAlreadyDeposit'
import NoteAdColumnAlreadyRent from '../columns/price/NoteAdColumnAlreadyRent'
import NoteAdColumnFee from '../columns/fee/NoteAdColumnFee'
import NoteAdColumnCotractDateInfo from '../columns/price/NoteAdColumnCotractDateInfo'
import NoteAdColumnLivingFacility from '../columns/facility/NoteAdColumnLivingFacility'
import NoteAdColumnSecureFacility from '../columns/facility/NoteAdColumnSecureFacility'
import NoteAdColumnEtcFacility from '../columns/facility/NoteAdColumnEtcFacility'
import NoteAdColumnMoveDate from '../columns/detail/NoteAdColumnMoveDate'
import NoteAdColumnCharacter from '../columns/detail/NoteAdColumnCharacter'
import NoteAdColumnDetail from '../columns/detail/NoteAdColumnDetail'
import NoteAdColumnPictures from '../columns/detail/NoteAdColumnPictures'
import theme from 'lib/styles/theme'
import NoteAdColumnStoreInfo from '../columns/store/NoteAdColumnStoreInfo'
import NoteAdColumnContact from '../columns/store/NoteAdColumnContact'
import NoteAdColumnAreas from '../columns/basic/NoteAdColumnAreas'
import NoteAdColumnVerification from '../columns/verification/NoteAdColumnVerification'
import NoteAdColumnOneRoom from '../columns/basic/oneroom/NoteAdColumnOneRoom'
import { useFormContext } from 'react-hook-form'
import NoteAdPriceDesc from '../columns/price/NoteAdPriceDesc'
import NoteAdStoreDesc from '../columns/store/NoteAdStoreDesc'

/**
 * 광고관리 매물등록 테이블
 * 아파트
 */
const NoteAdFormApt = () => {
  const { watch } = useFormContext()
  const exclusiveArea = watch('exclusive_area')
  const contractArea = watch('contract_area')

  return (
    <Wrapper>
      <Title>1. 기본정보</Title>
      <TableWrapper>
        <Table lastcount={2}>
          <NoteAdColumnPropertyType />
          <NoteAdColumnAddress />
          <NoteAdColumnBuildingUse half={false} required />
          <NoteAdColumnAreas required />
          <NoteAdColumnFloor required />
          <NoteAdColumnTotalFloor required />
          <NoteAdColumnFloorAgree />
          <NoteAdColumnFloorMultiple />
          {(exclusiveArea <= 50 ||
            (!exclusiveArea && contractArea <= 50)) && (
            <NoteAdColumnOneRoom />
          )}
          <NoteAdColumnRoomCount required />
          <NoteAdColumnBathroomCount />
          <NoteAdColumnDirection required />
          <NoteAdColumnDoor />
          <NoteAdColumnParking half={false} valueType="text" />
          <NoteAdColumnTotalParkingCount />
          <NoteAdColumnParkingCount />
        </Table>
      </TableWrapper>
      <Title attachDesc>2. 거래정보</Title>
      <NoteAdPriceDesc />
      <TableWrapper>
        <Table lastcount={2}>
          <NoteAdColumnContractType />
          <NoteAdColumnSalePrice required />
          <NoteAdColumnDeposit />
          <NoteAdColumnRent />
          <NoteAdColumnConvertJeonse />
          <NoteAdColumnLoan />
          <NoteAdColumnAlreadyDeposit />
          <NoteAdColumnAlreadyRent />
        </Table>
      </TableWrapper>
      <Title>3. 관리비 부과 정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnFee />
        </Table>
      </TableWrapper>
      <Title>4. 시설정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnLivingFacility />
          <NoteAdColumnSecureFacility />
          <NoteAdColumnEtcFacility />
        </Table>
      </TableWrapper>
      <Title>5. 상세정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnMoveDate />
          <NoteAdColumnCotractDateInfo />
          <NoteAdColumnCharacter />
          <NoteAdColumnDetail />
          {/* TODO: 이미지 업로드 api 결과값 받아서 update 처리 해야 함 */}
          <NoteAdColumnPictures />
        </Table>
      </TableWrapper>
      <Title attachDesc>6. 중개업소 정보</Title>
      <NoteAdStoreDesc />
      <TableWrapper>
        <Table>
          <NoteAdColumnStoreInfo />
          <NoteAdColumnContact />
        </Table>
      </TableWrapper>
      <Title>7. 검증 방식</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnVerification />
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;

  ${TableWrapper} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Desc = styled.div`
  display: flex;
  margin-left: 4px;
  margin-bottom: 6px;
  color: ${theme.colors.tint.danger};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;

  &::before {
    content: '·';
    font-size: 30px;
    margin-right: 7px;
  }
`

export default NoteAdFormApt
