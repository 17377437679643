import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getChannels } from 'lib/api/note/channelApi'
import { setChannels } from 'modules/channel'
import useAxios from 'lib/hooks/useAxios'

export default function useChannels() {
  const channels = useSelector((state) => state.channel.channels)
  const dispatch = useDispatch()

  const [onRequest, loading, data, error, reset, source] =
    useAxios(getChannels) //getChannels: 채널 조회인데 채널이 없으면 생성 후 조회

  const _getChannels = useCallback(
    async (cancelToken) => {
      await onRequest({}, cancelToken)
    },
    [onRequest],
  )

  useEffect(() => {
    if (!data?.result) return

    dispatch(setChannels(data.result))
  }, [data])

  return {
    _getChannels,
    loading,
    data,
    channels,
    reset,
    _getChannelsSource: source,
  }
}
