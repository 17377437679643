import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본 정보 - 추천 용도
 */
const NoteAdColumnRecommendPurpose = () => {
  const { onUpdate } = useAdUpdate()

  const { register, setValue, watch } = useFormContext()

  return (
    <ColumnSet half>
      <ColumnTitle>추천 용도</ColumnTitle>
      <ColumnVal>
        <InputText
          type="text"
          {...register('article.rusage')}
          onBlur={(e) => {
            onUpdate('article.rusage', e.target.value)
          }}
        />
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnRecommendPurpose
