import NoteDashboardNotiContainer from 'containers/notev2/NoteDashboardNotiContainer'
import NoteDashboardBlock from './NoteDashboardBlock'
import NoteDashboardNotiList from './NoteDashboardNotiList'
import NoteDashboardNotiMore from './NoteDashboardNotiMore'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Empty } from 'components/design'

const NoteDashboardNoti = () => {
  return (
    <NoteDashboardNotiContainer>
      {({ data, onClickMore }) => {
        return (
          <NoteDashboardBlock title="알림">
            <More onClick={onClickMore}>더보기</More>
            {data && data.length > 0 ? (
              <NoteDashboardNotiList data={data} />
            ) : (
              <Empty>알림이 없습니다.</Empty>
            )}
          </NoteDashboardBlock>
        )
      }}
    </NoteDashboardNotiContainer>
  )
}

const More = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  cursor: pointer;

  &::after {
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    margin-left: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: ${theme.colors.gray[500]};
  }
`

export default NoteDashboardNoti
