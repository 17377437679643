import { Button } from 'components/design'
import { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { getFullUrl } from 'utils'

const ErrorFallbackComponent = ({ error, resetErrorBoundary }) => {
  const onClick = () => {
    window.open('https://pf.kakao.com/_XTUzb', '_blank')
  }
  const onClickBack = () => {
    window.location.href = getFullUrl('/map/home', true)
  }

  return (
    <Wrapper>
      <div>
        죄송합니다. 오류가 발생했습니다. <br />
        F5를 눌러 다시 시도하거나 아래 카카오톡 문의하기로 문의해
        주세요.
      </div>
      <ButtonWraper>
        <Button color="primary" plain onClick={onClickBack}>
          <i className="far fa-arrow-alt-circle-left"></i>
          <span>지도 홈으로 돌아가기</span>
        </Button>
        <Button onClick={onClick}>
          <i className="fas fa-comment"></i>
          <span>카카오톡 문의하기</span>
        </Button>
      </ButtonWraper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  margin-top: 60px;

  div {
    text-align: center;
    padding: 0 24px;
    word-break: keep-all;
  }

  button {
    margin-top: 40px;

    ${media.mediumS`
      margin-top: 0;

      &:first-child {
         margin-top: 30px;
      }
    `}
  }
`

const ButtonWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  ${media.mediumS`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export default ErrorFallbackComponent
