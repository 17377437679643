import { Tag } from 'components/design'
import theme from 'lib/styles/theme'
import {
  colorGroup,
  noteSheetColor,
} from 'lib/utils/notev2/note2Constants'
import styled from 'styled-components'
import moment from 'moment'
import NoteListMobilePrice from './NoteListMobilePrice'
import NoteListMobileArea from './NoteListMobileArea'
import React, { forwardRef } from 'react'
import {
  getDisplayAddressFormat,
  getInvalidAddrName,
  getKeyByValueInclude,
} from 'lib/utils/notev2/util'
import NoteListMobileItemMore from './NoteListMobileItemMore'
import { adStatuscolors } from '../NoteAd/list/customCell/CustomStatusCell'
import { options } from '../NoteAd/options'
import NoteAdListMobileItemMore from './NoteAdListMobileItemMore'

/**
 * 시트 모바일 모드 각 요소
 * @param {*} param0
 * @returns
 */
const NoteListMobileItem = ({
  input,
  type,
  item,
  mode,
  style,
  onClick,
  onClickPicture,
  onClickPhone,
  moveDetailTab,
  replaceSearch,
}) => {
  const onClickFn = (e) => {
    if (type == 'note') {
      onClick(
        e,
        item.channel_srl,
        item.address_srl,
        item.note_srl,
        item.folder_srl,
        item.latitude,
        item.longitude,
      )
    } else if (type == 'ad') {
      if (!item?.ad_srl) return
      onClick(item.ad_srl)
    } else if (type == 'doc') {
      onClick(e, item.channel_srl, item.contract_srl, item.note_srl)
    }
  }
  return (
    <Item
      completed={type == 'note' && item.status == 2}
      style={style}
      onClick={onClickFn}
      color={getKeyByValueInclude(colorGroup, item.property_type)}
    >
      {/* 광고관리 상태값 */}
      {type == 'ad' && (
        <AdWrapper>
          <Status
            color={
              adStatuscolors[item?.status] || theme.colors.gray[600]
            }
          >
            {item?.status}
          </Status>
          <AdDate>{item?.registration_date}</AdDate>
        </AdWrapper>
      )}
      {/* 광고관리 제외 매물종류, 등록일 */}
      {(type == 'note' || type == 'doc') && (
        <TopLabel>
          {type == 'note' && item.status == 2 && (
            <CompleteTag>거래완료</CompleteTag>
          )}
          <StyledTag
            size="xs"
            color={getKeyByValueInclude(colorGroup, item.property_type)}
          >
            {item.property_type}
          </StyledTag>
          {type == 'note' && (
            <Regdate>
              {item.regdate
                ? moment(item.regdate).format('YYYY.MM.DD')
                : ''}
            </Regdate>
          )}
        </TopLabel>
      )}
      {/* 주소 */}
      <Address mb={type == 'doc'}>
        {type == 'ad' && (
          <StyledTag
            size="xs"
            color={getKeyByValueInclude(colorGroup, item.property_type)}
          >
            {item.property_type}
          </StyledTag>
        )}
        <AddressVal>
          {type == 'doc' && (item?.address || '주소 정보 없음')}
          {type == 'note' &&
            (item?.address_srl ? (
              <>
                {item.eupmyeondong}{' '}
                {getDisplayAddressFormat({
                  display_address: item.display_address,
                  dong: item.dong,
                  ho: item.ho,
                })}
              </>
            ) : (
              <>
                {getInvalidAddrName({
                  invalid_addr_name: item.invalid_addr_name,
                  dong: item.dong,
                  ho: item.ho,
                })}
              </>
            ))}
          {type == 'ad' && (
            <>
              {item.eupmyeondong}{' '}
              {getDisplayAddressFormat({
                display_address: item.display_address,
                dong: item.dong || item.dong_name,
                ho: item.ho || item.ho_name,
              })}
            </>
          )}
        </AddressVal>
      </Address>
      {/* 별칭(건물명) */}
      {type != 'doc' && (
        <Alias>{item.building_name || <>&ndash;</>}</Alias>
      )}
      {/* 가격 */}
      <NoteListMobilePrice data={item} type={type} />
      {/* 계약관리용 중도금일, 잔금일 */}
      {type == 'doc' && (
        <DocPriceWrapper>
          <PinIcon className="fas fa-thumbtack" />
          {item?.payment_schedule?.jungdogeum?.[0]?.date ||
          item?.payment_schedule?.jangeum?.[0]?.date ? (
            <DocPrice>
              {item?.payment_schedule?.jungdogeum?.[0]?.date && (
                <div>
                  중도금일{' '}
                  {moment(
                    item?.payment_schedule?.jungdogeum?.[0]?.date,
                  ).format('YYYY.MM.DD')}
                </div>
              )}
              {item?.payment_schedule?.jungdogeum?.[0]?.date &&
                item?.payment_schedule?.jangeum?.[0]?.date && (
                  <div>·</div>
                )}
              {item?.payment_schedule?.jangeum?.[0]?.date && (
                <div>
                  잔금일{' '}
                  {moment(
                    item?.payment_schedule?.jangeum?.[0]?.date,
                  ).format('YYYY.MM.DD')}
                </div>
              )}
            </DocPrice>
          ) : (
            <DocPrice>중도금일 · 잔금일 정보 없음</DocPrice>
          )}
        </DocPriceWrapper>
      )}
      {/* 면적 */}
      {type != 'doc' && <NoteListMobileArea data={item} />}
      {/* 광고관리용 검증방식 */}
      {type == 'ad' && (
        <VerificationType>
          <PinIcon className="fas fa-thumbtack" />
          <VerificationTitle>검증방식</VerificationTitle>
          <div>
            {options['검증방식']?.[item?.verification_type] || (
              <>&ndash;</>
            )}
          </div>
        </VerificationType>
      )}
      {/* 더보기 */}
      {type == 'note' && (
        <>
          <IconWrapper>
            <Icon
              onClick={(e) =>
                onClickPicture(
                  e,
                  item.channel_srl,
                  item.address_srl,
                  item.note_srl,
                  item.folder_srl,
                )
              }
            >
              <i className="fas fa-camera"></i>
            </Icon>
            <Icon
              disabled={item.contacts?.length == 0}
              onClick={(e) => onClickPhone(e, item.contacts)}
            >
              <i className="fas fa-phone"></i>
            </Icon>
          </IconWrapper>
          <NoteListMobileItemMore
            moveDetailTab={moveDetailTab}
            channel_srl={item.channel_srl}
            address_srl={item.address_srl}
            folder_srl={item.folder_srl}
            note_srl={item.note_srl}
            regdate={item.recent_contract_regdate}
            deunggibu={item.deunggibu_counts}
            picture={item.image_cnt}
          />
        </>
      )}
      {type == 'ad' && (
        <NoteAdListMobileItemMore
          data={item}
          input={input}
          replaceSearch={replaceSearch}
        />
      )}
    </Item>
  )
}

const Regdate = styled.div``
const StyledTag = styled(Tag)`
  flex-shrink: 0;
  font-size: 10px;
  padding: 2px;
`

const Alias = styled.div`
  margin-bottom: 14px;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Item = styled.div`
  position: relative;
  width: 100%;
  max-height: 170px;
  padding: 16px 20px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.base.white};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.colors.gray[50]};
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: ${({ color }) =>
      color ? noteSheetColor[color].line : theme.colors.gray[600]};

    ${({ completed }) =>
      completed &&
      `
        background-color: ${theme.colors.gray[400]};
    `}
  }

  ${StyledTag} {
    ${({ completed }) =>
      completed &&
      `
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
    `}
  }

  ${Alias}, ${Regdate} {
    ${({ completed }) =>
      completed &&
      `
        color: ${theme.colors.gray[400]};
    `}
  }

  ${({ completed }) =>
    completed &&
    `
      color: ${theme.colors.gray[400]} !important;
    `}
`

const TopLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 6px;
`

const Address = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;

  ${({ mb }) =>
    mb &&
    `
    margin-bottom: 14px;
  `}
`

const AddressVal = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const IconWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  background-color: ${theme.colors.gray[100]};
  color: ${theme.colors.gray[600]};
  z-index: 1;

  ${({ disabled }) =>
    disabled &&
    `
        color: ${theme.colors.gray[300]};
  `}
`

const AdWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
`

const Status = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;

  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}
`

const AdDate = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`

const VerificationType = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
`
const VerificationTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-weight: 500;
  line-height: 100%;
`

const PinIcon = styled.div`
  color: ${theme.colors.gray[400]};
  font-size: 11px;
`

const DocPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
`

const DocPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
`

const CompleteTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 16px;
  background-color: ${theme.colors.gray[600]};
  color: ${theme.colors.base.white};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;
`

export default React.memo(NoteListMobileItem)
