import theme from 'lib/styles/theme'
import { rgba } from 'polished'
import { forwardRef } from 'react'
import { Tooltip } from 'recharts'
import styled from 'styled-components'

const SummaryAptGraphCountTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledTooltipLabel>
          거래량
          {/* {label.replace('-', '.')} */}
        </StyledTooltipLabel>
        {payload.map((item, i) => (
          <StyledTooltipItem key={i}>
            <StyledTooltipIcon color={item.color} />
            <span>{item.value}건</span>
          </StyledTooltipItem>
        ))}
      </StyledTooltip>
    )
  }

  return null
}

const StyledTooltip = styled.div`
  padding: 0.25rem 0.5rem;
  background-color: ${rgba(theme.colors.gray[200], 0.7)};
  backdrop-filter: blur(18px);
  border-radius: ${theme.borderRadius[1]};
  /* border: 1px solid ${theme.colors.gray[200]}; */
  /* box-shadow: ${theme.shadow['card-2']}; */
`

const StyledTooltipLabel = styled.div`
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 700;
`

const StyledTooltipItem = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  &:not(:last-child) {
    /* margin-bottom: 2px; */
  }
`

const StyledTooltipIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: ${theme.colors.primary[400]};
  background-color: ${({ color }) =>
    color || theme.colors.primary[400]};
`

export default SummaryAptGraphCountTooltip
