import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import theme from 'lib/styles/theme'
import { API_HOST } from 'Constants'

import { Modal } from 'components/design'
import { Button } from 'components/design'
import { Input } from 'components/design'
import { getJootekServiceURL } from 'utils'
import { message } from 'antd'
import { useSelector } from 'react-redux'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import { useHistory, useLocation } from 'react-router-dom'
import SignupSelectType from './SignupSelectType'

const LoginForm = ({
  visible,
  onCancel,
  callbackFn,
  redirectUrl = null,
  customAccount,
}) => {
  const location = useLocation()
  const history = useHistory()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const inviteeInfo = useSelector(
    (state) => state.inviteReward.inviteeInfo,
  )
  const { onRequest } = useUserLoader(false)

  const { register, handleSubmit, watch, setValue } = useForm()
  const user_id = watch('user_id')
  const password = watch('password')

  const [isSignupVisible, setIsSignupVisible] = useState(false)

  /**
   * 로그인 양식 제출
   */
  const onSubmit = useCallback(
    (data) => {
      let formData = new FormData()

      // 입력값을 DB형식에 맞게 FormData에 세팅
      Object.keys(data).map((key) => {
        formData.append(key, data[key])
      })

      // 항상 로그인 유지
      formData.append('keep_signed', 'Y')

      // CSRF 토큰 세팅
      formData.append('_rx_csrf_token', loginInfo?.csrf_token)
      // 모바일 앱용 디바이스 토큰 세팅
      formData.append('device_token', loginInfo?.device_token)

      //초대 코드용 파라미터
      if (inviteeInfo) {
        //referral_code
        formData.append('referral_code', inviteeInfo?.referral_code)
      }

      axios
        .post(`${API_HOST}/index/login`, formData)
        .then(async (response) => {
          if (response.data && response.data.message === 'success') {
            // 로그인정보 불러오기
            await onRequest()

            // 로그인 창 닫기
            onCancel()

            //리워드 url에서 로그인 후 건축주 회원이면 /home으로 replace
            if (
              loginInfo.member_srl &&
              loginInfo?.member_type !== 'realestate' &&
              location?.pathname == '/inviteAuth'
            ) {
              history.replace('/home')
            }

            if (redirectUrl) {
              history.push(redirectUrl)
            }

            if (inviteeInfo) {
              callbackFn && callbackFn()
            }
          } else if (response.data && response.data.error === -1) {
            message.error(response.data.message)
          }
        })
    },
    [loginInfo, inviteeInfo, redirectUrl],
  )

  const onClickSignin = () => {
    onCancel()
    setIsSignupVisible(true)
  }

  useEffect(() => {
    if (customAccount?.id) {
      setValue('user_id', customAccount?.id)
    }
    if (customAccount?.pw) {
      setValue('password', customAccount?.pw)
    }
  }, [customAccount])

  return (
    <>
      <Modal
        title="로그인"
        visible={visible}
        onCancel={onCancel}
        width={450}
        footer={null}
      >
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <input
            type="hidden"
            name="mid"
            defaultValue="index"
            {...register('mid')}
          />
          <input
            type="hidden"
            name="ruleset"
            defaultValue="@login"
            {...register('ruleset')}
          />
          <input
            type="hidden"
            name="act"
            defaultValue="procMemberLogin"
            {...register('act')}
          />

          <FormItem>
            {/* <FormItemLabel htmlFor="login_form_user_id">
            아이디
          </FormItemLabel> */}

            <Input
              id="id"
              type="text"
              name="user_id"
              {...register('user_id')}
              label="아이디"
              watchedValue={user_id}
              design="box"
              autoCapitalize="off"
              autoCorrect="off"
              expand
            />
          </FormItem>

          <FormItem>
            {/* <FormItemLabel htmlFor="login_form_password">
            비밀번호
          </FormItemLabel> */}
            <Input
              id="password"
              type="password"
              name="password"
              {...register('password')}
              label="비밀번호"
              watchedValue={password}
              design="box"
              expand
            />
          </FormItem>

          <FindPasswordWrap>
            <FindPassword
              href={getJootekServiceURL('/index/dispMemberFindAccount')}
            >
              비밀번호 찾기
            </FindPassword>
          </FindPasswordWrap>

          <LoginButtonWrap>
            <Button type="submit" color="primary" size="xlarge" expand>
              로그인
            </Button>
          </LoginButtonWrap>

          <RegisterWrap>
            <span>계정이 없으신가요? </span>
            <RegisterLink onClick={onClickSignin}>
              회원가입
            </RegisterLink>
          </RegisterWrap>
        </StyledForm>
      </Modal>
      <SignupSelectType
        visible={isSignupVisible}
        onCancel={() => setIsSignupVisible(false)}
      />
    </>
  )
}

const StyledForm = styled.form``

const FormItem = styled.div`
  margin-bottom: 1rem;
`

const FindPasswordWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const FindPassword = styled.a`
  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
`

const LoginButtonWrap = styled.div`
  margin-bottom: 2rem;
`

const RegisterWrap = styled.div`
  padding-top: 2rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid ${theme.colors.gray[100]};

  color: ${theme.colors.gray[600]};
  text-align: center;
`

const RegisterLink = styled.div`
  display: inline-block;
  color: ${theme.colors.primary[400]};
  font-size: 0.875rem;
  font-weight: 700;
  margin-left: 2px;
`

export default LoginForm
