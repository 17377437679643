import styled from 'styled-components'

const AppContainer = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
  margin: 0 auto;
  zoom: 1;
`

export default AppContainer
