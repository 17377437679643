import styled from 'styled-components'
import {
  Row,
  Table,
  TableHeader,
  TableValue,
  TableWrapper,
  TableTitle,
} from 'components/jmapnotev2/style/NoteLayerStyle'
import { Button } from 'components/design'
import NoteDeunggiExpireNoti from 'components/jmapnotev2/NoteDeunggiExpireNoti'

/**
 * 등기부등본 공통 테이블 레이아웃
 * @param {any} data 데이터
 * @param {function} onClose 닫기 함수
 * @param {JSX.Element} append 맨 위에 붙일 컴포넌트
 * @param {string} useTo 사용처 - note: 매물 3뎁스, contract - 계약서
 * @returns
 */
const NoteDocsLayerDeunggibuLayout = ({
  data,
  onClose,
  append,
  useTo,
}) => {
  return (
    <>
      {useTo == 'contract' && (
        <NotiWrapper>
          <NoteDeunggiExpireNoti
            data={data}
            goToDeunggibuIssuance={onClose}
          />
        </NotiWrapper>
      )}

      {append}

      <TableWrapper>
        {data.extracted_data?.pending && (
          <Info>
            {data.extracted_data?.pending?.map((item, i) => {
              return <p key={i}>{item}</p>
            })}
          </Info>
        )}
        <Title>
          표제부
          <ButtonWrap>
            {data.url && (
              <Button color="blue">
                <a href={data.url} download={data.title}>
                  PDF로 보기
                </a>
              </Button>
            )}
          </ButtonWrap>
        </Title>
        {data.extracted_data?.pyojebu &&
          data.extracted_data?.pyojebu.length > 0 &&
          data.extracted_data?.pyojebu?.map((pyojebu, i) => {
            return (
              <Table
                key={'pyojebu' + i}
                mb={i != data.extracted_data?.pyojebu.length - 1}
              >
                <Row>
                  <TableHeader>접수</TableHeader>
                  <TableValue>{pyojebu?.date || ''}</TableValue>
                </Row>
                <Row>
                  <TableHeader>
                    소재지번 및<br />
                    건물 번호
                  </TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: pyojebu?.address || '',
                    }}
                  ></TableValue>
                </Row>
                <Row>
                  <TableHeader>건물내역</TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: pyojebu?.building || '',
                    }}
                  ></TableValue>
                </Row>
                <Row noborderbottom>
                  <TableHeader>
                    등기원인 및<br />
                    기타사항
                  </TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: pyojebu?.other || '',
                    }}
                  ></TableValue>
                </Row>
              </Table>
            )
          })}
      </TableWrapper>

      {data.sub_type === '집합건물' && (
        <TableWrapper>
          <TableTitle>대지권의 목적인 토지의 표시</TableTitle>
          {data.extracted_data?.daejikwon &&
            Object.keys(data.extracted_data?.kapgu).map((item, i) => {
              return (
                <Table mb key={'daejikwon' + i}>
                  <Row>
                    <TableHeader>표시번호</TableHeader>
                    <TableValue>
                      {data.extracted_data.daejikwon[item]?.rank}
                    </TableValue>
                  </Row>
                  <Row>
                    <TableHeader>유형</TableHeader>
                    <TableValue>
                      {data.extracted_data.daejikwon[item]?.type}
                    </TableValue>
                  </Row>
                  <Row>
                    <TableHeader>지분</TableHeader>
                    <TableValue>
                      {data.extracted_data.daejikwon[item]?.ratio}
                    </TableValue>
                  </Row>
                  <Row noborderbottom>
                    <TableHeader>
                      등기원인 및<br />
                      기타사항
                    </TableHeader>
                    <TableValue
                      dangerouslySetInnerHTML={{
                        __html:
                          data.extracted_data.daejikwon[item]?.other,
                      }}
                    ></TableValue>
                  </Row>
                </Table>
              )
            })}

          {(!data.extracted_data?.daejikwon ||
            data.extracted_data?.daejikwon?.length === 0) && (
            <Table mb key={'daejikwon1'}>
              <Row>
                <TableHeader wide>기록사항 없음</TableHeader>
              </Row>
              <Row noborderbottom>
                <TableValue textAlign="center" wide>
                  -- 이 하 여 백 --
                </TableValue>
              </Row>
            </Table>
          )}
        </TableWrapper>
      )}

      <TableWrapper>
        <TableTitle>갑구(소유권에 관한 사항)</TableTitle>
        {data.extracted_data?.kapgu &&
          Object.keys(data.extracted_data.kapgu).map((item, i) => {
            return (
              <Table mb key={'kapgu' + i}>
                <Row>
                  <TableHeader>순위번호</TableHeader>
                  <TableValue>
                    {data.extracted_data.kapgu[item]?.rank}
                  </TableValue>
                </Row>
                <Row>
                  <TableHeader>접수날짜</TableHeader>
                  <TableValue>
                    {data.extracted_data.kapgu[item]?.date}
                  </TableValue>
                </Row>
                <Row>
                  <TableHeader>등기목적</TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: data.extracted_data.kapgu[item]?.title,
                    }}
                  ></TableValue>
                </Row>
                <Row>
                  <TableHeader>접수</TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: data.extracted_data.kapgu[item]?.regnum,
                    }}
                  ></TableValue>
                </Row>
                <Row>
                  <TableHeader>등기원인</TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: data.extracted_data.kapgu[item]?.cause,
                    }}
                  ></TableValue>
                </Row>
                <Row noborderbottom>
                  <TableHeader>소유자</TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: data.extracted_data.kapgu[item]?.detail,
                    }}
                  ></TableValue>
                </Row>
              </Table>
            )
          })}

        {(!data.extracted_data?.kapgu ||
          data.extracted_data.kapgu?.length === 0) && (
          <Table mb key={'kapgu1'}>
            <Row>
              <TableHeader wide>기록사항 없음</TableHeader>
            </Row>
            <Row noborderbottom>
              <TableValue textAlign="center" wide>
                -- 이 하 여 백 --
              </TableValue>
            </Row>
          </Table>
        )}
      </TableWrapper>
      <TableWrapper>
        <TableTitle>을구(소유권 이외의 권리에 관한 사항)</TableTitle>
        {data.extracted_data?.eulgu &&
          Object.keys(data.extracted_data.eulgu)?.map((item, i) => {
            return (
              <Table mb key={'eulgu' + i}>
                <Row>
                  <TableHeader>순위번호</TableHeader>
                  <TableValue>
                    {data.extracted_data.eulgu[item]?.rank}
                  </TableValue>
                </Row>
                <Row>
                  <TableHeader>등기목적</TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: data.extracted_data.eulgu[item]?.title,
                    }}
                  ></TableValue>
                </Row>
                <Row>
                  <TableHeader>접수</TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: data.extracted_data.eulgu[item]?.regnum,
                    }}
                  ></TableValue>
                </Row>
                <Row>
                  <TableHeader>등기원인</TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: data.extracted_data.eulgu[item]?.cause,
                    }}
                  ></TableValue>
                </Row>
                <Row noborderbottom>
                  <TableHeader>
                    권리자
                    <br />
                    및<br />
                    기타사항
                  </TableHeader>
                  <TableValue
                    dangerouslySetInnerHTML={{
                      __html: data.extracted_data.eulgu[item]?.detail,
                    }}
                  ></TableValue>
                </Row>
              </Table>
            )
          })}
        {(!data.extracted_data?.eulgu ||
          data.extracted_data.eulgu?.length === 0) && (
          <Table mb key={'eulgu1'}>
            <Row>
              <TableHeader wide>기록사항 없음</TableHeader>
            </Row>
            <Row noborderbottom>
              <TableValue textAlign="center" wide>
                -- 이 하 여 백 --
              </TableValue>
            </Row>
          </Table>
        )}
      </TableWrapper>
    </>
  )
}

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  & > button {
    width: 120px;
    & > a {
      color: inherit;
    }
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 12px;
`

const Info = styled.div`
  font-size: 13px;
`

const NotiWrapper = styled.div`
  margin-bottom: 40px;
`

export default NoteDocsLayerDeunggibuLayout
