import { useContext, useEffect, useRef } from 'react'
import { useHistory, matchPath } from 'react-router-dom'
import MapContext from 'contexts/map'
import { billionFormate, dateFormatForMarker } from 'utils'

import RealPriceMarker from './RealPriceMarker'
import BaseContext from 'contexts/baseContext'

import { useDispatch } from 'react-redux'
import { setIsCallSizeReview } from 'modules/summary'

const MapRPMarkerItem = ({ item, resetNotePolygon }) => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map } = state
  const history = useHistory()
  const markerRef = useRef()
  const dispatch = useDispatch()
  const {
    actions: { setIsFold },
  } = useContext(BaseContext)

  const isSelected = item.id === state.clickPosition.address_id

  let marker = {}
  // 커스텀 오버레이의 드래그 시작좌표
  let startX, startY, clickEnabled

  useEffect(() => {
    let position = {
      latitude: item.lat,
      longitude: item.lon,
    }

    if (item.marker_type === 'customoverlay') {
      position = {
        // latitude: item.lat + 0.00002,
        latitude: item.lat,
        // longitude: item.lon + 0.00009,
        longitude: item.lon,
      }
    }

    let content = markerRef.current
    let payload = {
      map: map,
      // clickable: item.marker_type === 'customoverlay', // clickable: true면 지도 드래그 안 됨
      clickable: false,
      position: new kakao.maps.LatLng(
        position.latitude,
        position.longitude,
      ),
      content: content,
      yAnchor: 1,
    }
    marker = new kakao.maps.CustomOverlay(payload)

    // 타입이 dotoverlay일 경우 마커 자체를 지운 후 다시 그림
    // 클릭한 마커와 현재 마커가 동일할 경우 다시 그림
    if (item.marker_type === 'dotoverlay' && isSelected) {
      marker.setMap(null)
    }

    // 마커를 맵에 그림
    marker.setMap(map)

    if (isSelected) {
      // 선택된 마커일 경우 z-index를 높임
      marker.setZIndex(3)
    } else if (item.marker_type === 'customoverlay') {
      // 마커 타입이 커스텀오버레이일 경우 z-index를 높임
      marker.setZIndex(2)
    } else {
      marker.setZIndex(1)
    }

    // 커스텀 오버레이에 mousedown이벤트를 등록합니다
    addEventHandle(content, 'mousedown', onMouseDown)
    addEventHandle(content, 'mouseup', onMouseUp)
    addEventHandle(content, 'touchstart', onMouseDown)
    addEventHandle(content, 'touchend', onMouseUp)
    addEventHandle(content, 'click', onClick)

    // 커스텀 오버레이에 mousedown 했을 때 호출되는 핸들러 입니다
    function onMouseDown(e) {
      // 클릭이 지도로 전달되지 않도록 일시적으로 이벤트를 마비시킨다
      kakao.maps.event.disableClick()

      // mousedown된 좌표를 설정합니다
      startX = e.clientX
      startY = e.clientY
    }

    return () => {
      // unmount될 때 지도상에서 제거
      marker.setMap(null)
    }
  }, [])

  // mouseup 했을 때 호출되는 핸들러 입니다
  function onMouseUp(e) {
    // mouseUp했을 때의 좌표와 mouseUp했을 때의 좌표가 동일하면 summary요청 발생
    if (startX === e.clientX && startY === e.clientY) {
      clickEnabled = true
    } else {
      clickEnabled = false
    }
  }

  // click 핸들러 (방금 드래그한 경우에는 클릭으로 취급하지 않아야 함)
  function onClick(e) {
    e.stopPropagation()
    if (clickEnabled) {
      onClickMarker()
    }
  }

  // target node에 이벤트 핸들러를 등록하는 함수힙니다
  function addEventHandle(target, type, callback) {
    if (target.addEventListener) {
      target.addEventListener(type, callback)
    } else {
      target.attachEvent('on' + type, callback)
    }
  }

  // target node에 등록된 이벤트 핸들러를 제거하는 함수힙니다
  function removeEventHandle(target, type, callback) {
    if (target.removeEventListener) {
      target.removeEventListener(type, callback)
    } else {
      target.detachEvent('on' + type, callback)
    }
  }

  //--------------------------------------------------------------------------------------------
  const onClickMarker = async () => {
    /**
     * @WARN: matchPath를 사용할 때는 함수 안에서 선언하세요.
     * matchPath를 이용해 취득한 데이터는 useHistory와 다르게
     * 바인딩된 state가 아닙니다.
     */
    const match = matchPath(history.location.pathname, {
      path: '/:id/:external',
      exact: true,
    })

    //매물 폴리곤 삭제
    resetNotePolygon()
    // Summary 패널 활성화
    setIsFold(false)
    //수익성 분석 api call 활성화
    dispatch(setIsCallSizeReview(true))

    history.push(
      `/${item.id}/${
        match?.params?.external ? match.params.external : ''
      }${match?.params?.filterId ? `/${match.params.filterId}` : ''}`,
    )
  }

  return (
    <div>
      <RealPriceMarker
        propertyType={item.property_type}
        aptName={item.apt_name}
        ref={markerRef}
        active={isSelected}
        isDot={item.marker_type === 'dotoverlay'}
        type={item.property_type}
        exclusiveArea={item.exclusive_area}
        price={
          <>
            {state.isTotal && billionFormate(item.price)}
            {!state.isTotal &&
              state.isSquareMeter &&
              billionFormate(item.price / item.land_area)}
            {!state.isTotal &&
              !state.isSquareMeter &&
              billionFormate((item.price / item.land_area) * 3.305)}
          </>
        }
        date={dateFormatForMarker(item.contract_date)}
      />
    </div>
  )
}

export default MapRPMarkerItem
