import { feeOptions } from 'components/jmapnotev2/NoteAd/optionsFee'
import theme from 'lib/styles/theme'
import priceFormat from 'lib/utils/priceFormat'
import { sortBy } from 'lodash'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 주택부동산 상세
 * 관리비 모달
 * 정액관리비
 */
const BdsDetailFeeModalType1 = ({ data }) => {
  const list = feeOptions['정액관리비세부내역']
  const standard = feeOptions['부과기준']

  return (
    <>
      <Sum>
        <div>관리비 합계</div>
        <SumPrice>
          {numberWithCommas(
            data?.fixedFeeDetails
              .map(({ amount }) => amount || 0)
              .reduce((a, b) => a + b),
          )}
          원
        </SumPrice>
      </Sum>
      <Table>
        <TableItem>
          <TableTitle>부과방식</TableTitle>
          <TableValue>
            {feeOptions['부과타입']?.[data?.chargeCodeType]}
          </TableValue>
        </TableItem>
        <TableItem>
          <TableTitle>부과기준</TableTitle>
          <TableValue>{standard[data?.chargeCriteriaCode]}</TableValue>
        </TableItem>
        {sortBy(data?.fixedFeeDetails, 'detailCodeType').map(
          ({ detailCodeType, amountCodeType, amount }) => {
            return (
              <TableItem>
                <TableTitle>{list[detailCodeType]}</TableTitle>
                <TableValue>
                  {amountCodeType == '02'
                    ? '실비'
                    : `${numberWithCommas(amount)}원` || <>&ndash;</>}
                </TableValue>
              </TableItem>
            )
          },
        )}
      </Table>
    </>
  )
}

export const Sum = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #f6f9fe;
  margin-bottom: 20px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 180%;
`

export const SumPrice = styled.div`
  color: ${theme.colors.blue[700]};
  font-weight: 700;
`

export const Table = styled.div`
  border: 1px solid ${theme.colors.gray[100]};
  border-radius: 4px;
  font-size: 14px;
`

export const TableItem = styled.div`
  display: flex;
  height: 42px;
  border-bottom: 1px solid ${theme.colors.gray[100]};

  &:last-child {
    border-bottom: none;
  }
`

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  width: 110px;
  padding-left: 12px;
  background-color: ${theme.colors.gray[50]};
  border-right: 1px solid ${theme.colors.gray[100]};
  color: ${theme.colors.gray[600]};
  font-weight: 600;
  line-height: 150%;
`

export const TableValue = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-weight: 500;
  line-height: 150%;
`

export default BdsDetailFeeModalType1
