import styled from 'styled-components'
import AppContainer from './AppContainer'
import { media } from 'lib/styles/theme'
import { SectionWrapper } from './LandingStyle'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash-es'

const LandingBigText2 = ({}, ref) => {
  const isAddedEvent = useRef(false)
  const targetRef = useRef(null)

  const [imgOpacity, setImgOpacity] = useState(0.2)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (isAddedEvent.current) return
          addScrollEvent()
          isAddedEvent.current = true
        }
      })
    })

    observer.observe(targetRef.current)
  }, [])

  const addScrollEvent = () => {
    window.addEventListener(
      'scroll',
      throttle(() => {
        if (!ref?.current) return
        const scroll = ref.current.scrollTop

        const targetPos = getOffsetTop(targetRef.current)

        const criteria = targetPos - 1000
        const opacity = convertScale(
          scroll < criteria ? criteria : scroll,
          criteria,
          targetPos,
          0.4,
          1.5,
        )
        setImgOpacity(opacity)
      }),
      500,
    )
  }

  /**
   * jquery식 offset top 얻기
   * @param {*} el
   * @returns
   */
  function getOffsetTop(el) {
    if (!el.getClientRects().length) {
      return 0
    }

    let rect = el.getBoundingClientRect()

    return rect.top + ref.current.scrollTop
  }

  /**
   * 숫자를 다른 범위의 값으로 변환하기
   * @param {*} num target
   * @param {*} in_min 원본 최소값
   * @param {*} in_max 원본 최대값
   * @param {*} out_min 결과 최소값
   * @param {*} out_max 결과 최대값
   * @returns
   */
  const convertScale = (num, in_min, in_max, out_min, out_max) => {
    return (
      ((num - in_min) * (out_max - out_min)) / (in_max - in_min) +
      out_min
    )
  }
  return (
    <Wrapper ref={targetRef} opacity={imgOpacity}>
      <AppContainer>
        <Content>
          부동산 중개업을 위해 고안한
          <br />
          충격적인 편리함
        </Content>
      </AppContainer>
    </Wrapper>
  )
}

const Wrapper = styled(SectionWrapper)`
  display: flex;
  align-items: center;
  height: 600px;
  opacity: 0.2;

  ${({ opacity }) =>
    opacity &&
    `
    opacity: ${opacity};
  `}

  ${media.mediumS`
		height: 400px;
    margin-top: 0;
  `}
`

const Content = styled.div`
  font-weight: 800;
  font-size: 80px;
  line-height: 140%;
  background: linear-gradient(91.11deg, #1958f9 0%, #fb60bd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  word-break: keep-all;

  ${media.mediumM`
    font-size: 63px;
  `}

  ${media.mediumS`
    font-size: 30px;
    line-height: 130%;
  `}
`

export default forwardRef(LandingBigText2)
