import axios from 'axios'

export const getMemberInfo = async ({ _rx_csrf_token }, options) =>
  await axios.post(
    '/jmapnote/getMemberInfo',
    {
      _rx_csrf_token,
    },
    options,
  )

/**
 * 회원정보 수정
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const modifyAccount = async (formData, options) =>
  await axios.post('/jmapnote/modifyAccount', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

/**
 * 전화번호 인증코드 전송
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const sendVerificationSMS = async (
  { _rx_csrf_token, phone_country, phone_number },
  options,
) =>
  await axios.post(
    '/member/procMemberSendVerificationSMS',
    {
      _rx_csrf_token,
      phone_country,
      phone_number,
    },
    options,
  )

/**
 * 올인원 멤버 목록 가져오기
 * @param {*} plan_srl
 * @param {*} options
 * @returns
 */
export const getPlanUsers = async (
  { _rx_csrf_token, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/planuser/list',
    {
      _rx_csrf_token,
      plan_srl,
    },
    options,
  )

/**
 * 올인원 멤버 추가 전 검증
 * @param {array} numbers
 * @param {*} options
 * @returns
 */
export const validatePlanUsers = async (
  { _rx_csrf_token, numbers },
  options,
) =>
  await axios.post(
    '/jmapnote/api/planuser/validate',
    {
      _rx_csrf_token,
      numbers,
    },
    options,
  )

/**
 * 올인원 멤버 추가
 * @param {array} numbers
 * @param {number} amount
 * @param {*} options
 * @returns
 */
export const insertPlanUsers = async (
  { _rx_csrf_token, numbers, amount },
  options,
) =>
  await axios.post(
    '/jmapnote/api/planuser/insert',
    {
      _rx_csrf_token,
      numbers,
      amount,
    },
    options,
  )

/**
 * 올인원 멤버 삭제
 * @param {*} plan_srl
 * @param {*} member_srl
 * @param {*} amount
 * @param {*} options
 * @returns
 */
export const deletePlanUser = async (
  { _rx_csrf_token, plan_srl, member_srl, amount },
  options,
) =>
  await axios.post(
    '/jmapnote/api/planuser/delete',
    {
      _rx_csrf_token,
      plan_srl,
      member_srl,
      amount,
    },
    options,
  )
