import { SelectBox } from 'components/design'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import styled from 'styled-components'

const SummaryJeonyoubuDongho = ({
  dongList,
  hoList,
  selectedDong,
  selectedHo,
  onClickDong,
  onClickHo,
}) => {
  return (
    <Wrapper>
      <SelectBox
        value={{
          label: selectedDong || '선택',
          value: selectedDong,
        }}
        options={getSimpleSelectboxOptions(
          dongList.map((item) => {
            if (!item) return '구분없음'
            return item
          }),
        )}
        placeholder="선택"
        className="basic-single"
        classNamePrefix="select"
        onChange={(e) => onClickDong(e.value)}
      />
      <SelectBox
        value={{
          label: selectedHo || '선택',
          value: selectedHo,
        }}
        options={getSimpleSelectboxOptions(hoList)}
        placeholder="선택"
        className="basic-single"
        classNamePrefix="select"
        onChange={(e) => onClickHo(e.value)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
`

export default SummaryJeonyoubuDongho
