import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useEffect } from 'react'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 등기부 소유자명 oname
 */
const NoteAdColumnDeunggiOwner = ({
  text,
  half = true,
  isDisabled,
}) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>등기부 소유자명</ColumnTitle>
      <ColumnVal direction="column">
        <div>
          <InputText
            width={InputRemoveAppendWidth}
            type="text"
            {...register('verification.oname')}
            onBlur={(e) => {
              onUpdate('verification.oname', e.target.value)
            }}
            disabled={isDisabled}
          />
        </div>
        <ValidateWarn>
          {errors?.verification?.oname?.message}
        </ValidateWarn>
        <FormDesc>
          <li>
            {text
              ? text
              : '한글 + 영어 혼용 불가합니다. 예) 김Daehee, Kim 대희'}
          </li>
        </FormDesc>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnDeunggiOwner
