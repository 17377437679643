import axios from 'axios'
import MapContext from 'contexts/map'
import { useContext } from 'react'

/**
 * 런타임, useAxios에서 에러 발생시 로그 insert
 * 실서버에서만 함
 * @returns
 */
export default function useLog() {
  const { state } = useContext(MapContext)

  const loggingError = (error, info) => {
    if (process.env.NODE_ENV === 'development') return

    let action = ''

    if (error?.data?.rx_error_location) {
      action = error.data.rx_error_location
    }

    // useAxios Error Boundary 안에 있지 않아서 일반 axios로 호출
    axios
      .post(`/map/api/insertLog`, {
        _rx_csrf_token: state?.loginInfo?.csrf_token,
        screen: window.location.pathname,
        action: action,
        message: error.message || error?.data?.message || '',
        call_stack: JSON.stringify(info),
      })
      .then((response) => {})
  }

  return { loggingError }
}
