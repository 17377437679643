import { useContext, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setRoadView } from 'modules/summary'
import MapContext from 'contexts/map'
import MapRoadView from './MapRoadView'
import styled from 'styled-components'
import MapWalker from 'lib/utils/mapWalker'
import { media } from 'lib/styles/theme'

/**
 * summary 로드뷰 로직
 */
const MapRoadViewContainer = () => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map, clickPosition, polygons, buildingPolygons } = state
  const roadviewRef = useRef(null)

  const dispatch = useDispatch()

  const { isVisible } = useSelector((state) => state.summary.roadView)

  useEffect(() => {
    if (!isVisible) return

    if (!clickPosition.centerpoint) {
      return
    }
    const roadviewContainer = roadviewRef.current //로드뷰를 표시할 div
    const roadviewObj = new kakao.maps.Roadview(roadviewContainer) //로드뷰 객체
    const roadviewClient = new kakao.maps.RoadviewClient() //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체
    const position = new kakao.maps.LatLng(
      clickPosition?.centerpoint?.[0],
      clickPosition?.centerpoint?.[1],
    )

    // 특정 위치의 좌표와 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄운다.
    roadviewClient.getNearestPanoId(position, 50, function (panoId) {
      if (panoId) {
        roadviewObj.setPanoId(panoId, position) //panoId와 중심좌표를 통해 로드뷰 실행
      } else {
        dispatch(setRoadView({ isVisible: false, isSupport: false }))
      }
    })

    kakao.maps.event.addListener(roadviewObj, 'init', function () {
      getRoadviewCenterPoint(roadviewObj)
      initMiniMap(roadviewObj)
    })

    return () => {
      if (polygons.length === 0) return

      //원래 맵에는 다시 polygon 세팅
      polygons?.map((polygon) => {
        polygon.setMap(map)
      })

      buildingPolygons.map((buildingPolygon) => {
        buildingPolygon.setMap(map)
        return true
      })
    }
  }, [isVisible, clickPosition, roadviewRef])

  // 로드뷰의 방향 세팅
  const getRoadviewCenterPoint = (roadview) => {
    // 로드뷰의 좌표와 clickPosition의 좌표를 비교하여 0~360도 사이의 각도를 계산한다
    let roadviewPosition = roadview.getPosition()
    let direction =
      Math.round(
        (Math.atan2(
          roadviewPosition.getLng() - clickPosition?.centerpoint?.[1],
          roadviewPosition.getLat() - clickPosition?.centerpoint?.[0],
        ) *
          180) /
          Math.PI +
          540,
      ) % 360

    // 계산한 각도로 동동이 방향(pan)을 돌려준다
    let viewpointWithPanoId = roadview.getViewpointWithPanoId()
    roadview.setViewpoint({
      ...viewpointWithPanoId,
      pan: direction,
    })
  }

  /**
   * 로드뷰 미니맵 초기화
   */
  const initMiniMap = (roadviewObj) => {
    const mapCenter = new kakao.maps.LatLng(
      clickPosition?.centerpoint?.[0],
      clickPosition?.centerpoint?.[1],
    )

    const miniMapContainer = document.getElementById('roadview-miniMap')

    const options = {
      center: mapCenter,
      level: 2,
    }

    //지도 생성 및 객체 리턴
    const miniMap = new kakao.maps.Map(miniMapContainer, options)

    //지도 레벨 제한
    miniMap.setMinLevel(2)
    miniMap.setMaxLevel(2)

    //로드뷰 도로 영역 색칠
    miniMap.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW)

    setMiniMapPolygons(miniMap)
    setMiniMapWalker(roadviewObj, miniMap, mapCenter)
  }

  /**
   * 로드뷰 미니맵 폴리곤 세팅
   * @param {*} miniMap 미니맵 객체
   * @returns void
   */
  const setMiniMapPolygons = (miniMap) => {
    if (polygons.length === 0) return

    //토지
    polygons?.map((polygon) => {
      polygon.setMap(miniMap)
    })

    //건물
    buildingPolygons.map((buildingPolygon) => {
      buildingPolygon.setMap(miniMap)
      return true
    })
  }

  /**
   * 맵 위에 동동이 올리기
   * @param {*} roadview 로드뷰 객체
   * @param {*} miniMap 미니맵 객체
   * @param {*} mapCenter 맵 센터 포인트
   * @returns void
   */
  const setMiniMapWalker = (roadview, miniMap, mapCenter) => {
    let mapWalker = null

    // 로드뷰가 초기화 되었을때 map walker를 생성한다.
    mapWalker = new MapWalker(mapCenter)
    mapWalker.setMap(miniMap)

    // 로드뷰를 상,하,좌,우,줌인,줌아웃을 할 경우 발생한다.
    // 로드뷰를 조작할때 발생하는 값을 받아 map walker의 상태를 변경해 준다.
    kakao.maps.event.addListener(
      roadview,
      'viewpoint_changed',
      function () {
        // 이벤트가 발생할 때마다 로드뷰의 viewpoint값을 읽어, map walker에 반영
        const viewpoint = roadview.getViewpoint()
        mapWalker.setAngle(viewpoint.pan)
      },
    )

    // 로드뷰내의 화살표나 점프를 하였을 경우 발생한다.
    // position값이 바뀔 때마다 map walker의 상태를 변경해 준다.
    kakao.maps.event.addListener(
      roadview,
      'position_changed',
      function () {
        const position = roadview.getPosition()
        mapWalker.setPosition(position)
        miniMap.setCenter(position)
      },
    )

    //미니맵 지도 클릭하면 클릭한 위치 로드뷰를 보여줌(로드뷰가 불가한 지역이라면 가장 가까운 곳으로 강제 이동시킨다)
    kakao.maps.event.addListener(
      miniMap,
      'click',
      function (mouseEvent) {
        const position = mouseEvent.latLng
        const roadviewClient = new kakao.maps.RoadviewClient()

        roadviewClient.getNearestPanoId(
          position,
          50,
          function (panoId) {
            if (panoId) {
              roadview.setPanoId(panoId, position)
            } else {
              dispatch(
                setRoadView({ isVisible: false, isSupport: false }),
              )
            }
          },
        )

        mapWalker.setPosition(position)
        miniMap.setCenter(position)
      },
    )
  }

  const roadviewRoot = document.getElementById('roadview-root')

  return (
    isVisible &&
    ReactDOM.createPortal(
      <MapRoadView
        isVisible={isVisible}
        onClick={() => dispatch(setRoadView({ isVisible: false }))}
      >
        <div id="roadview" ref={roadviewRef} />
        <MiniMap id="roadview-miniMap" />
      </MapRoadView>,
      roadviewRoot,
    )
  )
}

export const MiniMap = styled.div`
  width: 550px;
  height: 25%;
  left: 0;
  bottom: 0;
  position: absolute;

  ${media.smallPc`
    width: 40%;
    height: 22%;
  `}

  ${media.mediumM`
    width: 100%;
    height: 30%;
  `}

  @media screen and (max-height: 800px) {
    width: 100%;
    height: 30%;
  }

  .map_wrap {
    overflow: hidden;
    height: 330px;
  }

  /* 지도위에 로드뷰의 위치와 각도를 표시하기 위한 map walker 아이콘의 스타일 */
  .MapWalker {
    position: absolute;
    margin: -26px 0 0 -51px;
  }
  .MapWalker .figure {
    position: absolute;
    width: 25px;
    left: 38px;
    top: -2px;
    height: 39px;
    background: url(https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png) -298px -114px
      no-repeat;
  }
  .MapWalker .angleBack {
    width: 102px;
    height: 52px;
    background: url(https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png) -834px -2px
      no-repeat;
  }
  .MapWalker.m0 .figure {
    background-position: -298px -114px;
  }
  .MapWalker.m1 .figure {
    background-position: -335px -114px;
  }
  .MapWalker.m2 .figure {
    background-position: -372px -114px;
  }
  .MapWalker.m3 .figure {
    background-position: -409px -114px;
  }
  .MapWalker.m4 .figure {
    background-position: -446px -114px;
  }
  .MapWalker.m5 .figure {
    background-position: -483px -114px;
  }
  .MapWalker.m6 .figure {
    background-position: -520px -114px;
  }
  .MapWalker.m7 .figure {
    background-position: -557px -114px;
  }
  .MapWalker.m8 .figure {
    background-position: -2px -114px;
  }
  .MapWalker.m9 .figure {
    background-position: -39px -114px;
  }
  .MapWalker.m10 .figure {
    background-position: -76px -114px;
  }
  .MapWalker.m11 .figure {
    background-position: -113px -114px;
  }
  .MapWalker.m12 .figure {
    background-position: -150px -114px;
  }
  .MapWalker.m13 .figure {
    background-position: -187px -114px;
  }
  .MapWalker.m14 .figure {
    background-position: -224px -114px;
  }
  .MapWalker.m15 .figure {
    background-position: -261px -114px;
  }
  .MapWalker.m0 .angleBack {
    background-position: -834px -2px;
  }
  .MapWalker.m1 .angleBack {
    background-position: -938px -2px;
  }
  .MapWalker.m2 .angleBack {
    background-position: -1042px -2px;
  }
  .MapWalker.m3 .angleBack {
    background-position: -1146px -2px;
  }
  .MapWalker.m4 .angleBack {
    background-position: -1250px -2px;
  }
  .MapWalker.m5 .angleBack {
    background-position: -1354px -2px;
  }
  .MapWalker.m6 .angleBack {
    background-position: -1458px -2px;
  }
  .MapWalker.m7 .angleBack {
    background-position: -1562px -2px;
  }
  .MapWalker.m8 .angleBack {
    background-position: -2px -2px;
  }
  .MapWalker.m9 .angleBack {
    background-position: -106px -2px;
  }
  .MapWalker.m10 .angleBack {
    background-position: -210px -2px;
  }
  .MapWalker.m11 .angleBack {
    background-position: -314px -2px;
  }
  .MapWalker.m12 .angleBack {
    background-position: -418px -2px;
  }
  .MapWalker.m13 .angleBack {
    background-position: -522px -2px;
  }
  .MapWalker.m14 .angleBack {
    background-position: -626px -2px;
  }
  .MapWalker.m15 .angleBack {
    background-position: -730px -2px;
  }
`

export default MapRoadViewContainer
