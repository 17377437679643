import {
  InnerTableBlock,
  InnerTableContent,
  Row,
  RowSpanInner,
  Table,
  TableContent,
  TableHeader,
  TableSubHeader,
  headerWidth,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { getContactHeaderName } from 'lib/utils/notev2/util'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const NoteDocsPayInfo = ({ onUpdate }) => {
  const { watch } = useFormContext()
  const docsTempRrn = useSelector((state) => state.notev2.docsTempRrn)

  const seller = watch('seller')
  const buyer = watch('buyer')
  const realestate = watch('realestate')
  const contract_type = watch('contract_type')

  const [sellers, setSellers] = useState([])
  const [buyers, setBuyers] = useState([])
  const [realestates, setRealestates] = useState([])

  const contactInitValue = {
    addr: '',
    rrn: '',
    number: '',
    name: '',
    agent_addr: '',
    agent_rrn: '',
    agent_number: '',
    agent_name: '',
  }

  const contactInitValue2 = {
    addr: '',
    rrn: '',
    number: '',
    name: '',
    agent_addr: '',
    agent_rrn: '',
    agent_number: '',
    agent_name: '',
  }

  const realestateInitValue = {
    addr: '',
    id: '',
    company_name: '',
    number: '',
    name: '',
    sosog_name: '',
  }

  useEffect(() => {
    if (!seller || seller.length == 0) {
      setSellers([contactInitValue])
    } else {
      setSellers(seller)
    }

    if (!buyer || buyer.length == 0) {
      setBuyers([contactInitValue2])
    } else {
      setBuyers(buyer)
    }
    if (!realestate || realestate.length <= 1) {
      setRealestates([realestate[0], realestateInitValue])
    } else {
      setRealestates(realestate)
    }
  }, [])

  useEffect(() => {
    if (seller && seller.length > 0) {
      setSellers(seller)
    }
  }, [seller])

  useEffect(() => {
    if (buyer && buyer.length > 0) {
      setBuyers(buyer)
    }
  }, [buyer])

  function phoneFomatter(num, type) {
    var formatNum = ''

    if (num.length == 11) {
      if (type == 0) {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3')
      } else {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
      }
    } else if (num.length == 8) {
      formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2')
    } else {
      if (num.indexOf('02') == 0) {
        if (type == 0) {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3')
        } else {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
        }
      } else {
        if (type == 0) {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3')
        } else {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        }
      }
    }

    return formatNum
  }

  function rrnFormater(num, temp_rrn2) {
    if (num) {
      return `${num.slice(0, 6)}-${temp_rrn2}`
    } else {
      return ''
    }
  }
  return (
    <Wrapper>
      {/* 매도인 - 대리인 */}
      {sellers.map(({ addr, rrn, number, number_type, name }, i) => {
        return (
          <StyledTable key={'seller' + i}>
            <Row rowspan={2} noborderbottom>
              <TableHeader>
                {getContactHeaderName(contract_type, '매도인')}
              </TableHeader>
              <RowSpanInner rowspan={2}>
                <TableSubHeader width={143}>주소</TableSubHeader>
                <StyledTableContent noborderright>
                  {addr}
                </StyledTableContent>
              </RowSpanInner>
              <RowSpanInner rowspan={2} noborderbottom>
                <TableSubHeader width={143}>
                  {number_type}
                </TableSubHeader>
                <StyledTableContent width={210}>
                  {number_type == '주민등록번호'
                    ? rrnFormater(
                        rrn,
                        docsTempRrn?.seller?.find(
                          (item) => item.index == i,
                        )?.value || '',
                      )
                    : rrn}
                </StyledTableContent>
                <TableSubHeader width={60}>전화</TableSubHeader>
                <StyledTableContent width={202}>
                  {phoneFomatter(number)}
                </StyledTableContent>
                <TableSubHeader width={60}>성명</TableSubHeader>
                <StyledTableContent width={240} noborderright>
                  {name}
                </StyledTableContent>
              </RowSpanInner>
            </Row>
          </StyledTable>
        )
      })}
      {/* 매수인 - 대리인 */}
      {buyers.map(({ addr, rrn, number, number_type, name }, i) => {
        return (
          <StyledTable key={'buyer' + i}>
            <Row rowspan={2} noborderbottom>
              <TableHeader>
                {getContactHeaderName(contract_type, '매수인')}
              </TableHeader>
              <RowSpanInner rowspan={2}>
                <TableSubHeader width={143}>주소</TableSubHeader>
                <StyledTableContent noborderright>
                  {addr}
                </StyledTableContent>
              </RowSpanInner>
              <RowSpanInner rowspan={2} noborderbottom>
                <TableSubHeader width={143}>
                  {number_type}
                </TableSubHeader>
                <StyledTableContent width={210}>
                  {number_type == '주민등록번호'
                    ? rrnFormater(
                        rrn,
                        docsTempRrn?.buyer?.find(
                          (item) => item.index == i,
                        )?.value || '',
                      )
                    : rrn}
                </StyledTableContent>
                <TableSubHeader width={60}>전화</TableSubHeader>
                <StyledTableContent width={202}>
                  {phoneFomatter(number)}
                </StyledTableContent>
                <TableSubHeader width={60}>성명</TableSubHeader>
                <StyledTableContent width={120} noborderright>
                  {name}
                </StyledTableContent>
              </RowSpanInner>
            </Row>
          </StyledTable>
        )
      })}
      {/* 개업공인중개사 */}
      {realestates.map(
        ({ addr, id, company_name, number, name, sosog_name }, i) => {
          return (
            <StyledTable key={'realestates' + i}>
              <Row height={35} rowspan={4} noborderbottom>
                <TableHeader>
                  개업
                  <br />
                  공인중개사
                </TableHeader>
                <RowSpanInner
                  rowspanheightpercent={100 / 3}
                  rowspan={3}
                >
                  <InnerTableBlock widthpercent={50} noborderbottom>
                    <TableSubHeader width={143}>
                      등록번호
                    </TableSubHeader>
                    <StyledInnerTableContent checkboxes>
                      {id}
                    </StyledInnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock
                    widthpercent={50}
                    noborderbottom
                    noborderright
                  >
                    <TableSubHeader width={143}>
                      성명 &#40;대표자&#41;
                    </TableSubHeader>
                    <StyledInnerTableContent checkboxes>
                      {name}
                    </StyledInnerTableContent>
                  </InnerTableBlock>
                </RowSpanInner>
                <RowSpanInner
                  rowspanheightpercent={100 / 3}
                  rowspan={3}
                >
                  <InnerTableBlock widthpercent={50} noborderbottom>
                    <TableSubHeader width={143}>
                      사무소 명칭
                    </TableSubHeader>
                    <StyledInnerTableContent checkboxes>
                      {company_name}
                    </StyledInnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock
                    widthpercent={50}
                    noborderbottom
                    noborderright
                  >
                    <TableSubHeader width={143}>
                      소속 공인중개사
                    </TableSubHeader>
                    <StyledInnerTableContent checkboxes>
                      {sosog_name}
                    </StyledInnerTableContent>
                  </InnerTableBlock>
                </RowSpanInner>
                <RowSpanInner
                  rowspanheightpercent={100 / 3}
                  rowspan={3}
                  noborderbottom
                >
                  <InnerTableBlock widthpercent={50} noborderbottom>
                    <TableSubHeader width={143}>
                      사무소 소재지
                    </TableSubHeader>
                    <StyledInnerTableContent checkboxes>
                      {addr}
                    </StyledInnerTableContent>
                  </InnerTableBlock>
                  <InnerTableBlock
                    widthpercent={50}
                    noborderbottom
                    noborderright
                  >
                    <TableSubHeader width={143}>
                      전화번호
                    </TableSubHeader>
                    <StyledInnerTableContent checkboxes>
                      {phoneFomatter(number)}
                    </StyledInnerTableContent>
                  </InnerTableBlock>
                </RowSpanInner>
              </Row>
            </StyledTable>
          )
        },
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const StyledTable = styled(Table)`
  margin-bottom: 6px;
`

const StyledTableContent = styled(TableContent)`
  padding-left: 8px;
`

const StyledInnerTableContent = styled(InnerTableContent)`
  padding-left: 8px;
`

export default NoteDocsPayInfo
