import { useEffect, useContext, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import classnames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars-2'

import BaseContext from 'contexts/baseContext'

import ExternalLayerHeader, {
  HeaderDivider,
} from './ExternalLayerHeader'
import ExternalLayerContext from './context/ExternalLayerContext'
import useIsTablet from 'lib/hooks/useIsTablet'
import { isLayerSmallThanWindow } from 'utils'
import { useSelector } from 'react-redux'

const StyledExternalLayer = styled.div`
  position: fixed;
  width: ${({ full }) =>
    full ? `50%` : `${theme.base.externalWidth}px`};
  ${({ customWidth }) => customWidth && `width: ${customWidth}px`};
  height: 100%;
  background-color: white;
  left: ${({ full, shift }) =>
    full
      ? `50%`
      : `${theme.base.noteSidebarWidth + theme.base.container}px`};

  z-index: 19;
  border-left: 1px solid ${theme.colors.gray[200]};

  overflow: hidden;

  top: ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible ? `${noticeBarHeight}px` : `0`};
  padding-bottom: ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible ? `${noticeBarHeight}px` : `0`};
  ${media.mediumM`
    top: ${({ isNoticeBarVisible, noticeBarHeight }) =>
      isNoticeBarVisible ? `${noticeBarHeight}px` : `0`};
    left: ${({ full }) => (full ? `50%` : `${theme.base.container}px`)};
    padding-bottom:  ${({ isNoticeBarVisible, noticeBarHeight }) =>
      isNoticeBarVisible
        ? `${theme.base.bottomMenuHeight + noticeBarHeight}px`
        : `${theme.base.bottomMenuHeight}px`};
  `}

  ${media.mediumS`
    left: 0;
    width: 100%;
    border-left: none;
    z-index: 51;
    padding-bottom:  ${({ isNoticeBarVisible, noticeBarHeight }) =>
      isNoticeBarVisible
        ? `${theme.base.bottomMenuHeight + noticeBarHeight}px`
        : `${theme.base.bottomMenuHeight}px`};
  `}

  ${({ isTablet, full }) =>
    isTablet &&
    `
    left: ${full ? `50%` : `${theme.base.container}px`};
    
    padding-bottom: ${theme.base.bottomMenuHeight}px;
	`}

  ${({ backgroundColor }) =>
    backgroundColor === 'gray' &&
    `
    background-color: ${theme.colors.gray[100]};
  `}

  top: ${({ noTopHeader, isNoticeBarVisible, noticeBarHeight }) =>
    noTopHeader &&
    (isNoticeBarVisible ? `${noticeBarHeight}px` : 0)} !important;
`

const HeaderWrap = styled.div`
  position: relative;

  ${({ full }) =>
    full &&
    `
    position: absolute;
    z-index: 10;
    width: 100%;
  `}
`

const ExternalLayerContainer = ({
  title,
  padding,
  children,
  onClose,
  customHeader,
  withCard,
  onBack,
  hiddenClose,
  full,
  backgroundColor,
  noTopHeader,
  hasAdditionalHeader,
  shift,
  customWidth,
  scrollId,
}) => {
  const {
    actions: { setIsExternal },
  } = useContext(BaseContext)
  const {
    state: { isScrolled },
    actions: { setIsScrolled },
  } = useContext(ExternalLayerContext)

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)

  const isTablet = useIsTablet()

  const scrollRef = useRef(null)

  useEffect(() => {
    setIsExternal(true)

    return () => {
      setIsExternal(false)
      setIsScrolled(false)
    }
  }, [])

  const handleScroll = (e) => {
    if (scrollRef?.current?.getScrollTop() <= 0) {
      setIsScrolled(false)
      return
    }

    setIsScrolled(true)
  }

  const root = document.getElementById('root')

  return ReactDOM.createPortal(
    <StyledExternalLayer
      backgroundColor={backgroundColor}
      isTablet={isTablet}
      full={isLayerSmallThanWindow()}
      isNoticeBarVisible={isNoticeBarVisible}
      noticeBarHeight={height}
      noTopHeader={noTopHeader}
      shift={shift}
      customWidth={customWidth}
    >
      <HeaderWrap full={full}>
        {customHeader ? (
          customHeader
        ) : (
          <ExternalLayerHeader
            title={title}
            onClose={onClose}
            onBack={onBack}
            hiddenClose={hiddenClose}
            inverse={full}
          >
            <HeaderDivider />
          </ExternalLayerHeader>
        )}
      </HeaderWrap>

      <ExternalLayerContainerWrapper
        withCard={withCard}
        full={full}
        hasAdditionalHeader={hasAdditionalHeader}
      >
        <Scrollbars
          ref={scrollRef}
          onScroll={handleScroll}
          autoHide
          // autoHeight
          style={{ height: '100%' }}
          id={scrollId}
        >
          <ExternalLayerBody
            isNoticeBarVisible={isNoticeBarVisible}
            noticeBarHeight={height}
            padding={padding}
          >
            {children}
          </ExternalLayerBody>
        </Scrollbars>
      </ExternalLayerContainerWrapper>
    </StyledExternalLayer>,
    root,
  )
}

const ExternalLayerContainerWrapper = styled.div`
  height: calc(100% - ${theme.base.topHeaderHeight}px);

  ${media.mediumS`
    ${({ hasAdditionalHeader }) =>
      hasAdditionalHeader &&
      `
      height: calc(100% - ${theme.base.topHeaderHeight}px - ${theme.base.topHeaderHeight}px);
    `}
  `}

  ${({ withCard }) =>
    withCard &&
    `
    background-color: $color-gray-100;
  `}

  ${({ full }) =>
    full &&
    `
    height: 100%;
  `}
`

const ExternalLayerBody = styled.div`
  ${({ padding }) =>
    padding &&
    `
    padding: 1.25rem;
  `}
`

export default ExternalLayerContainer
