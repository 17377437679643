import { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { rgba } from 'polished'
import theme from 'lib/styles/theme'
import { getJootekServiceURL } from 'utils'

/**
 * 건물정보 설계, 시공 블럭
 */
const SummaryBuildingArchitect = ({ building }) => {
  const history = useHistory()

  const colors = {
    설계: '#618DFF',
    시공: '#EF925D',
  }

  const getArchitectData = (building) => {
    window.building = building
    const timeline = building?.result?.[0]?.timeline?.find(
      ({ folios }) => folios.length > 0,
    )

    const mainArchitect = timeline?.folios?.find(
      ({ category }) => category === '설계',
    )

    const mainConstructor = timeline?.folios?.find(
      ({ category }) => category === '시공',
    )

    return [mainArchitect, mainConstructor]
  }

  const architectData = useMemo(
    () => getArchitectData(building),
    [building],
  )

  if (!architectData) return null

  return (
    <Wrapper>
      {architectData?.map((item, index) => {
        if (!item || !item?.company_name) return null
        return (
          <BlockWrap key={index}>
            <Block
              color={colors[item?.category]}
              disabled={!item?.jootekfolio_srl}
              href={
                item?.jootekfolio_srl
                  ? getJootekServiceURL(
                      `/folio/${item?.jootekfolio_srl}`,
                    )
                  : null
              }
            >
              {item.category == '시공' ? (
                <Icon className="fas fa-digging" />
              ) : (
                <Icon className="fas fa-ruler-combined" />
              )}
              <Type>{item.category}</Type>
              <Title>
                <CompanyName>
                  {item.company_name ? item.company_name : ''}
                </CompanyName>

                {item?.jootekfolio_srl && (
                  <i className="far fa-chevron-right"></i>
                )}
              </Title>
            </Block>
          </BlockWrap>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  display: flex;
  align-items: flex-start;
  gap: 22px;
  overflow: hidden;
`

const BlockWrap = styled.li`
  max-width: 50%;
  flex-basis: 50%;
`

const Icon = styled.i`
  font-size: 2rem;
  margin-bottom: 1rem;
`

const Type = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 1.8;
`
const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.8;
  display: flex;
  align-items: center;

  & > i {
    flex: 0 0;
    color: ${rgba('black', 0.5)};
    margin-left: 0.5rem;
  }
`
const CompanyName = styled.div`
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Block = styled.a`
  cursor: pointer;
  display: block;
  padding: 1rem 1rem 0.75rem;
  margin-top: 16px;

  color: ${theme.colors.gray[900]};
  background-color: ${({ color }) => rgba(color, 0.1)};
  border-radius: ${theme.borderRadius[2]};
  transition: 0.2s ease;

  &[disabled] {
    cursor: not-allowed;
    color: ${theme.colors.gray[900]};
  }

  & > ${Icon} {
    color: ${({ color }) => color}BF;
  }

  ${Type} {
    color: ${({ color }) => color};
  }

  &:hover {
    color: ${theme.colors.gray[900]};
    background-color: ${({ color }) => rgba(color, 0.2)};

    ${Title} > i {
      color: ${({ color }) => color};
    }
  }
`

export default SummaryBuildingArchitect
