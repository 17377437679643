import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

export const AdButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;

  ${({ forForm }) =>
    !forForm &&
    `
  height: 22px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;`}

  ${({ blueBorderLight }) =>
    blueBorderLight &&
    `
      border: 1px solid ${theme.colors.blue[200]};
      color: ${theme.colors.blue[500]};
      font-weight: 500;
    `};
  ${({ redborder }) =>
    redborder &&
    `
      background-color: ${theme.colors.base.white};
      border: 1px solid ${theme.colors.gray[200]};
      color: ${theme.colors.tint.danger};
      font-weight: 500;
    `};

  ${({ forNote }) =>
    forNote &&
    `height: 30px;
    `}
`
