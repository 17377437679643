import { media } from 'lib/styles/theme'
import priceFormat, { priceFormatMilion } from 'lib/utils/priceFormat'
import moment from 'moment'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const NoteDonghoDetailInfo = ({ data }) => {
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  const supply = data?.n_supply_name || data?.j_supply_name
  const exclusive = data?.exclusive_area
  const price = data?.price
  const leaseType = data?.lease_type
  const leaseEndDate = data?.lease_end_date

  return (
    <Wrapper>
      <ItemWrapper>
        <Item>
          <Title>평형</Title>
          <Value>
            <span>
              {supply}
              {supply && exclusive && <Slash>&#47;</Slash>}
            </span>
            <span>
              {exclusive && `${Math.trunc(exclusive * 100) / 100}㎡`}
            </span>
          </Value>
        </Item>
        {price && (
          <>
            <Divider />
            <Item>
              <Title>공시가격</Title>
              <Value>{priceFormatMilion(price || 0)}원</Value>
            </Item>
          </>
        )}
      </ItemWrapper>
      {selectedDanji.is_paid == 'Y' && data?.notes?.length == 0 && (
        <ItemWrapper>
          <Item>
            <Title>점유형태</Title>
            <Value>{leaseType}</Value>
          </Item>
          <Divider />
          <Item>
            <Title>계약만기</Title>
            <Value>
              {leaseEndDate ? (
                `~${moment(leaseEndDate).format('YY.MM')}`
              ) : (
                <>&ndash;</>
              )}
            </Value>
          </Item>
        </ItemWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #f6f8ff;
  font-size: 14px;

  ${media.mediumS`
    font-size: 13px;
    padding: 12px;
    margin: 0 -7px 16px -7px;
    width: calc(100% + 14px);
  `}
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
`

const Title = styled.div`
  flex-shrink: 0;
  font-weight: 600;
  line-height: 100%;
  color: #8692b3;
`

const Value = styled.div`
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: #303f61;

  ${media.mediumS`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
`

const Slash = styled.i`
  margin: 0 4px 0 2px;
`

const ItemWrapper = styled.div`
  display: flex;
`

const Divider = styled.div`
  background-color: #d8ddf0;
  width: 1px;
  margin: 0 10px;
  border-top: 2px solid #f6f8ff;
  border-bottom: 2px solid #f6f8ff;
`

export default NoteDonghoDetailInfo
