import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { forwardRef } from 'react'
import { Item } from 'react-photoswipe-gallery'

const NotePictureSection = ({ data, date, style }, ref) => {
  return (
    <Wrapper style={style}>
      <ImageBlock ref={ref} key={`noteimages-${date}`}>
        <ImageDate>{date}</ImageDate>
        <StyledPhotoGird>
          {data &&
            data.map((item, j) => (
              <StyledPhotoItem key={`noteimage-${j}`}>
                <ImageWrapper>
                  <Item
                    original={item.url}
                    thumbnail={item.thumbnail}
                    width={item.width}
                    height={item.height}
                  >
                    {({ ref, open }) => (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={item.thumbnail}
                        ref={ref}
                        onClick={open}
                      />
                    )}
                  </Item>
                </ImageWrapper>
              </StyledPhotoItem>
            ))}
        </StyledPhotoGird>
      </ImageBlock>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 40px;

  ${media.mediumS`
    padding-left: 20px;
    padding-right: 20px;
  `}
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:hover {
    background-color: ${theme.colors.gray[300]};
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledPhotoGird = styled.div`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  margin: -1px;
`

const StyledPhotoItem = styled.div`
  position: relative;
  flex-basis: calc(100% / 6);
  width: calc(100% / 6);
  padding: 1px;

  ${media.smallPc`
    flex-basis: calc(100% / 4);
    width: calc(100% / 4);
  `}

  ${media.mediumM`
    flex-basis: calc(100% / 2);
    width: calc(100% / 2);
  `}

  @media (min-width: ${theme.bp.wide}px) {
    flex-basis: calc(100% / 7);
    width: calc(100% / 8);
  }

  @media (min-width: ${theme.bp.xWide}px) {
    flex-basis: calc(100% / 8);
    width: calc(100% / 8);
  }

  &::after {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`
const ImageDate = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
`

const ImageBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 40px;
`

export default forwardRef(NotePictureSection)
