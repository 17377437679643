import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Button } from 'components/design'
import { HiOutlineChevronDown } from 'react-icons/hi'

const CardMore = ({ className, visible = true, onClick = null }) => {
  if (!visible) return null

  return (
    <StyledMore expand className={className} onClick={onClick}>
      더보기
      <HiOutlineChevronDown />
    </StyledMore>
  )
}

const StyledMore = styled(Button)`
  margin-top: 16px;
  font-weight: 500;

  & > svg {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    transform: 0.2s ease;
    color: ${theme.colors.gray[500]};
  }

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
`

const Empty = styled.div`
  padding-bottom: 2rem;
`

export default CardMore
