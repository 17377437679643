import { getImagesInChannel } from 'lib/api/note/imageApi'
import useAxios from 'lib/hooks/useAxios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { initPropertyParams } from 'modules/notev2'
import { useInput } from 'lib/hooks/useInput'

const NotePicturesContainer = ({ children }) => {
  const [search, bindSearch] = useInput('')
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [onRequest, loading, data, , ,] = useAxios(getImagesInChannel)
  const [groupImages, setGroupImages] = useState(null)
  const [orderBy, setOrderBy] = useState('최근 등록순')

  const [propertyTypes, setPropertyTypes] = useState(
    initPropertyParams(),
  )

  useEffect(() => {
    if (!channelInfo) return

    // 사진 목록 조회
    onRequest({
      filters: propertyTypes,
      channel_srl: channelInfo?.channel_srl,
      orderBy: orderBy,
    })
  }, [channelInfo, propertyTypes, orderBy])

  useEffect(() => {
    if (!data) return
    const result = data?.result

    setGroupImages(result)
  }, [data])

  /**
   * 검색 엔터키
   */
  const onSearch = useCallback(
    (e) => {
      if (e.keyCode == '13') {
        onRequest({
          channel_srl: channelInfo.channel_srl,
          filters: propertyTypes,
          q: search,
          orderBy: orderBy,
        })
      }
    },
    [channelInfo?.channel_srl, search, orderBy],
  )

  return children({
    propertyTypes,
    setPropertyTypes,
    bindSearch,
    onSearch,
    orderBy,
    setOrderBy,
    groupImages,
    loading,
  })
}

export default NotePicturesContainer
