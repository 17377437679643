import useGetSelectedDanji from 'lib/hooks/notev2/useGetSelectedDanji'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import { tab } from 'lib/utils/notev2/dongho'
import {
  setBlockSize,
  setFavoriteIds,
  setMemoIds,
  setSelectedDong,
} from 'modules/noteDongho'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 동호관ㄹ
 * 초기, 단지 선택 라우팅 관련
 */
const NoteDonghoContainer = ({ children }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const activeTab = useSelector((state) => state.noteDongho.activeTab)
  const selectedDanjiDongs = useSelector(
    (state) => state.noteDongho.selectedDanjiDongs,
  )
  const { getDanjiDetail } = useGetSelectedDanji() //단지 상세 호출

  /**
   * 단지 선택 시 조회
   */
  useEffect(() => {
    if (!params?.id) return
    resetSelected()
    getDanjiDetail({
      danji_id: params.id,
      type: tab[activeTab],
    })
  }, [params?.id])

  /**
   * url에 동 붙어있으면 그 동으로 이동
   */
  useEffect(() => {
    if (!params?.dong) return
    if (!selectedDanjiDongs.includes(params.dong)) return

    dispatch(setSelectedDong(params.dong))
  }, [params?.dong, selectedDanjiDongs])

  /**
   * 모바일 레이아웃에서는 단지가 바뀌면 맨 처음 동을 선택해야함
   */
  useEffect(() => {
    if (selectedDanjiDongs.length == 0) return
    if (isMobile || isTablet) {
      const firstDong = selectedDanjiDongs[0]
      dispatch(setSelectedDong(firstDong))
      history.replace(`/note/v2/dongho/${params.id}/${firstDong}`)
    }
  }, [selectedDanjiDongs])

  /**
   * 수동으로 추가한 즐찾, 메모 초기화
   */
  const resetSelected = () => {
    dispatch(setFavoriteIds([]))
    dispatch(setMemoIds([]))
  }

  return children({})
}

export default NoteDonghoContainer
