import { List } from 'components/design'
import NoteContactListItem from './NoteContactListItem'
import ErrorView from 'components/common/ErrorView/ErrorView'

const NoteContactList = ({ data }) => {
  if (!data || data.length === 0)
    return <ErrorView msg="연락처가 없습니다." />

  return (
    <List>
      {data &&
        data.map((item, i) => (
          <NoteContactListItem item={item} key={i} />
        ))}
    </List>
  )
}

export default NoteContactList
