import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { deleteImage } from 'lib/api/note/imageApi'
import { useFormContext } from 'react-hook-form'
import { message } from 'antd'
import useAxios from 'lib/hooks/useAxios'
import { getListing } from 'lib/api/notev2/listApi'
import { useEffect } from 'react'
import { Item } from 'react-photoswipe-gallery'

const NoteFormPhotoItem = ({ item }) => {
  const { watch, setValue } = useFormContext()
  const channel_srl = watch('channel_srl')
  const address_srl = watch('address_srl')
  const note_srl = watch('note_srl')
  const images = watch('images')
  const [onRequestInfo, , dataInfo] = useAxios(getListing)

  const onClickDeleteImage = async (fileSrl) => {
    const { data } = await deleteImage({
      channel_srl: channel_srl,
      address_srl: address_srl,
      note_srl: note_srl === 'save' ? item.note_srl : note_srl,
      file_srl: fileSrl,
    })

    /**
     * @INFO: 이미지 삭제 작업이 정상적으로 진행되지 않았더라도
     * API가 일단 실행되면 client-side 데이터는 미리 삭제한다.
     * (for stateless)
     */
    const filteredImages = images.filter(
      (e) => e.file_srl.toString() !== fileSrl.toString(),
    )
    setValue('images', [...filteredImages])
    onRequestInfo({
      channel_srl,
      note_srl,
    })
    if (data?.error) {
      message.error(data?.message)
      return
    }
  }

  useEffect(() => {
    if (!dataInfo || !dataInfo?.result) return

    setValue('image_cnt', dataInfo.result.image_cnt)
  }, [dataInfo])

  return (
    <StyledPhotoItem>
      <Item
        original={item.url}
        thumbnail={item.thumbnail}
        width={item.width}
        height={item.height}
      >
        {({ ref, open }) => (
          <img ref={ref} onClick={open} src={item.url} />
        )}
      </Item>
      <StyledDeleteButton
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          onClickDeleteImage(item.file_srl)
        }}
      >
        <i className="fal fa-trash-alt"></i>
      </StyledDeleteButton>
    </StyledPhotoItem>
  )
}

const StyledDeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;

  cursor: pointer;
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0.25rem;

  border-radius: 100%;
  outline: none;
  border: 1px solid ${theme.colors.gray[200]};
  box-shadow: ${theme.shadow['card-1']};
  background-color: white;

  transition: 0.2s ease;

  &:hover {
    color: ${theme.colors.tint.danger};
    border-color: ${theme.colors.tint.danger};
  }
`

const StyledPhotoItem = styled.div`
  flex-basis: 33.33333%;
  max-width: calc(33.33333% - 2px);
  padding: 1px;
  position: relative;
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    ${StyledDeleteButton} {
      opacity: 1;
    }
  }
`

export default NoteFormPhotoItem
