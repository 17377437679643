import NoteDashboardProfileInfo from 'components/jmapnotev2/NoteDashboard/NoteDashboardProfileInfo'
import NoteDashboardProfilePayment from 'components/jmapnotev2/NoteDashboard/NoteDashboardProfilePayment'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * 매물노트 - 대시보드
 * 최상단 프로필 블럭
 */
const NoteDashboardProfileContainer = () => {
  return (
    <Block>
      <NoteDashboardProfileInfo />
      <NoteDashboardProfilePayment />
    </Block>
  )
}

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 37px 20px;
  box-shadow: 0px 1px 2px 0px #edf0f7;
  border-radius: 12px;
  flex: 1;
  background-color: ${theme.colors.base.white};

  ${media.smallPc`
    width: 50%;
    gap: 22px;
    flex-direction: column;
  `}

  ${media.mediumM`
    width: 100%;
    padding: 20px 16px;
  `}

  ${media.mediumS`
    width: calc(100% + 40px);
    margin: 0 -20px;
    border-radius: 0;
  `}
`

export default NoteDashboardProfileContainer
