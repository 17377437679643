import { useContext } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import { useHistory } from 'react-router-dom'
import { HiOutlineChevronRight } from 'react-icons/hi'

const SearchWrap = styled.div`
  cursor: text;
  position: absolute;
  bottom: -1.5rem;
  display: flex;
  align-items: center;
  width: calc(100% - 2.5rem);
  height: 52px;
  padding-left: 1rem;
  padding-right: 7px;

  /* border: 1px solid ${theme.colors.gray[200]}; */
  border-radius: 52px;
  background-color: ${theme.colors.base.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 0, rgba(0, 0, 0, 0.1) 0 4px 16px;

  transition: 0.2s ease;

  &:hover {
    /* border-color: ${theme.colors.gray[900]}; */
    box-shadow: rgba(0, 0, 0, 0.05) 0 1px 0,
      rgba(0, 0, 0, 0.15) 0 6px 24px;
  }
`

const SearchIcon = styled.svg`
  color: ${theme.colors.gray[600]};
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.875rem;
`

const SearchInput = styled.div`
  flex: 1;
  color: ${theme.colors.gray[500]};
  font-size: 15px;
  font-weight: 400;
`

const SearchButton = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;

  color: white;
  background-color: ${theme.colors.primary[400]};
  border-radius: 100%;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const Search = () => {
  const history = useHistory()
  const onClickSearch = () => {
    history.push('/search')
  }

  return (
    <SearchWrap onClick={onClickSearch}>
      <SearchIcon
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </SearchIcon>

      <SearchInput>주소∙장소명으로 검색</SearchInput>
      <SearchButton>
        <HiOutlineChevronRight />
      </SearchButton>
    </SearchWrap>
  )
}

export default Search
