import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { ReactComponent as EmptyIcon } from 'assets/images/empty.svg'

const Empty = ({ children, className, mt, icon }) => {
  return (
    <Wrapper className={className} mt={mt}>
      {icon ? icon : <EmptyIcon width="32" height="32" />}
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 130px;
  padding: 0.3rem 1rem;

  color: ${theme.colors.gray[500]};
  text-align: center;
  word-break: keep-all;
  font-size: 0.92rem;
  font-weight: 400;

  & > svg {
    margin-bottom: 1rem;
  }

  strong {
    color: ${theme.colors.primary[500]};
    margin-right: 2px;
  }

  ${({ mt }) => mt && `margin-top: 20px;`}
`

export default Empty
