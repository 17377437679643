import { LabelWithTitle } from './NoteDetailStyle'
import NoteDetailGongbuIssuanceContainer from 'containers/notev2/NoteDetailGongbuIssuanceContainer'
import styled from 'styled-components'
import NoteDetailGongbuIssuanceList from './NoteDetailGongbuIssuanceList'
import { forwardRef } from 'react'
import NoteDetailGongbuIssuanceButtons from './NoteDetailGongbuIssuanceButtons'

/**
 * 매물 3뎁스 공부문서
 */
const NoteDetailGongbuIssuance = ({ scrollRef }) => {
  return (
    <NoteDetailGongbuIssuanceContainer>
      {({
        docs,
        searchGongbu,
        loadingGoungbu,
        buttonRef,
        onClickDeunggibu,
        onClickLandUsePlan,
        issuanceModal,
        noPlanModal,
        emergencyModal,
      }) => {
        return (
          <>
            <LabelWithTitle>
              <Label>신규 문서 발급</Label>
            </LabelWithTitle>
            <NoteDetailGongbuIssuanceButtons
              buttonRef={buttonRef}
              onClickDeunggibu={onClickDeunggibu}
              onClickLandUsePlan={onClickLandUsePlan}
            />
            {issuanceModal}
            {noPlanModal}
            {emergencyModal}
            <NoteDetailGongbuIssuanceList
              scrollRef={scrollRef}
              docs={docs}
              searchGongbu={searchGongbu}
              loadingGoungbu={loadingGoungbu}
            />
          </>
        )
      }}
    </NoteDetailGongbuIssuanceContainer>
  )
}

const Label = styled.div`
  margin-right: 8px;
  margin-bottom: 1px;
`
export default NoteDetailGongbuIssuance
