import { useContext, useCallback } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { Tooltip } from 'antd'

import BaseContext from 'contexts/baseContext'
import MapContext from 'contexts/map'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'

const MapControlUnit = () => {
  const {
    state: { isExternal },
  } = useContext(BaseContext)
  const { state, actions } = useContext(MapContext)
  const [cookies, setCookie, removeCookie] = useCookies([
    'clickPosition',
  ])

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )
  const height = useSelector((state) => state.notice.height)

  // 총액-평당 토글
  const toggleUnitTotal = useCallback(() => {
    actions.setIsTotal((prev) => {
      // 쿠키저장
      setCookie('isTotal', !prev ? 'total' : 'unit', { path: '/' })
      removeCookie('isTotal', { path: '/map' })
      return !prev
    })
  }, [])

  // 평-제곱미터 토글
  const toggleSquareMeter = useCallback(() => {
    actions.setIsSquareMeter((prev) => {
      // 쿠키저장
      setCookie('isSquareMeter', !prev ? 'squareMeter' : 'pyeong', {
        path: '/',
      })
      removeCookie('isSquareMeter', { path: '/map' })
      return !prev
    })
  })

  return (
    <ButtonWrap
      isExternal={isExternal}
      isNoticeBarVisible={isNoticeBarVisible}
      noticeBarHeight={height}
    >
      <Tooltip
        title="실거래 금액 기준"
        placement="right"
        color={theme.colors.gray[900]}
      >
        <Button onClick={toggleUnitTotal}>
          <ButtonItem className={state.isTotal && 'active'}>
            총액
          </ButtonItem>
          <ButtonItem className={state.isTotal || 'active'}>
            {state.isSquareMeter ? '㎡당' : '평당'}
          </ButtonItem>
        </Button>
      </Tooltip>
    </ButtonWrap>
  )
}

const ButtonWrap = styled.div`
  margin-top: 8px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 51px;

  padding: 0 4px;

  color: ${theme.colors.gray[900]};
  font-size: 14px;
  font-weight: 700;
  outline: none;
  appearance: none;

  background-color: ${theme.colors.base.white};
  border-radius: 4px;
  box-shadow: ${theme.shadow.float};

  transition: 0.2s ease;
  padding-top: 1px;
`

const ButtonItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  width: 100%;
  padding: 2px 4px;
  border-radius: ${theme.borderRadius[0]};
  height: 20px;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  &.active {
    color: ${theme.colors.gray[50]};
    background-color: ${theme.colors.gray[800]};
  }
`

export default MapControlUnit
