import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledLabel = styled.div`
  display: block;
  width: 100%;
  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;

  ${({ hover }) =>
    hover &&
    `
    cursor: pointer;
  `}
  ${({ small }) =>
    small &&
    `
    font-size: 0.875rem;
  `}
    ${({ large }) =>
    large &&
    `
    font-size: 1.25rem;
  `}
    ${({ subtitle }) =>
    subtitle &&
    `
    color: ${theme.colors.gray[600]};
    font-size: 13px;
    font-weight: 700;
    // margin-bottom: 0.25rem;
  `}
    ${({ middle }) =>
    middle &&
    `
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  `};
`

const ListItemLabel = ({
  small,
  large,
  subtitle,
  middle,
  children,
  onClick,
  as,
}) => {
  return (
    <StyledLabel
      small={small}
      large={large}
      subtitle={subtitle}
      middle={middle}
      hover={onClick ? true : false}
      onClick={onClick}
      as={as}
    >
      {children}
    </StyledLabel>
  )
}

ListItemLabel.propTypes = {
  small: PropTypes.bool,
  large: PropTypes.bool,
  subtitle: PropTypes.bool,
  middle: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func,
}

export default ListItemLabel
