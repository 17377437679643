import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import {
  List,
  ListItemDetail,
  BlockTitleSub,
  BlockTitle,
} from './NoteAdColumnVerificationAgree'
import Checkbox from '../../elements/Checkbox'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useFormContext } from 'react-hook-form'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증방식 - 확인사항과 이용약관 동의
 */
const NoteAdColumnConfirmAndAgree = ({ isDisabled }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext()

  const isAgree = watch('verification.agree_confirm')

  return (
    <ColumnSet>
      <ColumnTitle required>확인사항과 이용약관 동의</ColumnTitle>
      <ColumnVal gap={12}>
        <Checkbox
          id="verification.agree_confirm"
          color="blue"
          value="Y"
          checked={isAgree == 'Y'}
          {...register('verification.agree_confirm')}
          onChange={(e) => {
            setValue('verification.agree_confirm', e ? 'Y' : 'N')
          }}
          disabled={isDisabled}
        >
          본 중개업소는 위의 확인사항과 이용약관에 대해서 충분히
          확인하고 동의하였습니다.
        </Checkbox>
        <Block>
          <BlockTitle>현장확인서비스 확인사항 안내</BlockTitle>
          <BlockTitleSub>
            <ListItemDetail>
              현장확인서비스는 매물 위치와 내부 사진이 공개 됩니다.
              따라서 사전에 의뢰인 및 거주자에게 사전에 서비스 내용을
              충분히 고지하셔야 합니다.
            </ListItemDetail>
            <ListItemDetail>
              의뢰인 및 거주자의 동의 없이 현장확인매물을 등록하시는
              경우 현장확인과정이 진행되지 않습니다.
            </ListItemDetail>
            <ListItemDetail>
              현장 확인시 중개업소 담당자님께서는 현장에 나오셔야
              합니다. 공인중개사님의 사유 등으로 매물등록이 취소될 경우
              당사 환급 규정에 의해 현장 출동비를 차감하고 환급하여
              드립니다.
            </ListItemDetail>
            <ListItemDetail>
              현장 확인 시 거주자가 현장에 없는 경우, 전화로 내부 사진
              촬영 동의 및 매물 홍보 확인을 진행하게 됩니다. 통화가
              완료된 후 매물 노출이 가능하오니 이점 양해 부탁 드립니다.
            </ListItemDetail>
          </BlockTitleSub>
          <br />
          <BlockTitle>현장확인서비스 이용약관</BlockTitle>
          <List>
            <li>
              현장확인서비스의 주요 내용
              <ListItemDetail>
                현장확인서비스는 매물 현장에 직접 방문하여 거래가능
                여부를 확인하고 매물 사진을 촬영하여 등록하는
                서비스입니다.
              </ListItemDetail>
              <ListItemDetail>
                매물홍보확인을 위해 현장방문, 사진촬영, 사진등록 등
                현장확인 상품 관련 업무를 제3자를 통해 진행할 수
                있습니다.
              </ListItemDetail>
              <ListItemDetail>
                현장확인서비스의 특성상 매물 의뢰인 및 거주자로부터 현장
                방문, 사진촬영, 촬영 사진의 서비스 노출 등에 대해서 서면
                또는 녹취 등의 방법으로 별도 동의를 받으며, 만약, 매물
                의뢰인 및 거주자가 동의하지 않는 경우 현장확인서비스의
                제공이 제한될 수 있습니다.
              </ListItemDetail>
            </li>
            <li>
              중개업소의 의무사항
              <ListItemDetail>
                중개업소는 사전에 매물 의뢰인과 거주자로부터
                현장확인서비스를 통한 매물 노출에 대해서 충분히
                고지하여야 합니다.
              </ListItemDetail>
              <ListItemDetail>
                매물 의뢰인과 거주자에게 현장확인서비스 이용을 충분히
                고지하지 않아 발생하는 문제에 대해서는 중개업소가 그
                책임을 부담하며, 문제 발생시 필요에 따라 현장확인서비스
                제공을 중단할 수 있습니다.
              </ListItemDetail>
              <ListItemDetail>
                중개업소는 현장확인 업무를 진행하는 제3자가 현장확인
                업무 처리 관련하여 요구하는 사항을 성실히 이행하며, 이를
                이행하지 않아 발생하는 문제에 대해서는 중개업소가 그
                책임을 부담합니다.
              </ListItemDetail>
            </li>
            <li>
              현장확인서비스의 환불 기준
              <ListItemDetail>
                현장확인서비스는 취소 단계에 따라 투입된 운영비 만큼을
                공제한 후에 잔여금액을 환불해드립니다.
              </ListItemDetail>
            </li>
          </List>
        </Block>
      </ColumnVal>
    </ColumnSet>
  )
}

const Block = styled.div`
  padding: 10px 14px;
  width: 100%;
  height: 300px;
  overflow-y: auto;
  background-color: ${theme.colors.gray[100]};
`

export default NoteAdColumnConfirmAndAgree
