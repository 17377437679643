import { useSelector } from 'react-redux'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'

const NoteAdHeader = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { isBoss } = useIsAllInOne()

  return (
    <Wrapper>
      <Tag color="blue" size="large">
        {isBoss ? '대표중개사' : loginInfo.realtor_position}
      </Tag>
      <Title>{loginInfo?.user_name}님 환영합니다</Title>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 32px;

  ${media.mediumS`
    margin-top: 0;
  `}
`

const Tag = styled.div`
  margin-bottom: 6px;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  color: ${theme.colors.blue[400]};
  background-color: ${theme.colors.blue[50]};
  border-radius: 999px;
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`

export default NoteAdHeader
