import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Link } from 'react-router-dom'

import { Button, Empty } from 'components/design'

import Container from 'components/base/Container/Container'

const StyledNotFound = styled.div`
  min-height: calc(100vh - ${theme.size.headerHeight});
  padding: 4rem 1.25rem;
  background-color: ${theme.colors.base.white};
`

const NotFound = () => (
  <>
    <Container noTopHeader>
      <StyledNotFound>
        <Empty>
          페이지를 찾을 수 없습니다.
          <br />
          {location.href}
          <br />
          <br />
          <Link to="/">
            <Button color="black">홈으로</Button>
          </Link>
        </Empty>
      </StyledNotFound>
    </Container>
  </>
)

export default NotFound
