import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useAxios from 'lib/hooks/useAxios'
import {
  getBaseDataForContract,
  getBuildingDj,
  getContract,
} from 'lib/api/notev2/contractApi'
import { useRouteMatch } from 'react-router-dom'
import { contractDefaultValue } from 'lib/utils/notev2/note2Constants'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import useEditContract from 'lib/hooks/notev2/useEditContract'
import { message } from 'antd'
import { useSelector } from 'react-redux'
import { onlyUnique } from 'utils'
import useIsMobile from 'lib/hooks/useIsMobile'
import { resetTempRrn } from 'modules/notev2'
import { useDispatch } from 'react-redux'

const NoteDocsEditContainer = ({ children }) => {
  const dispatch = useDispatch()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  //계약서 데이터
  const [onRequestContract, loadingContract, dataContract] =
    useAxios(getContract)
  //건축물대장
  const [onGetBuildingDj, , dataBuildingDj] = useAxios(getBuildingDj)
  // 공부문서
  const [onRequestGongbu, loadingGongbu, dataGongbu] = useAxios(
    getBaseDataForContract,
  )
  //계약서 저장(신규 생성)
  const [onEditContract, , dataEditContract] = useEditContract()

  const [isLoading, setIsLoading] = useState(true) //수정시 계약서 로딩
  const [isBuildingGongbuLoading, setIsBuildingGongbuLoading] =
    useState(false)
  const [isPropertyContractBind, setIsPropertyContractBind] =
    useState(false)
  const isMobile = useIsMobile()
  const [isLayerVisible, setIsLayerVisible] = useState(false)

  useEffect(() => {
    setIsLayerVisible(!isMobile)
  }, [isMobile])

  const history = useHistory()

  const location = useLocation()
  const match = useRouteMatch(
    '/note/v2/docs/:channelSrl/:noteSrl/edit/:contractSrl?',
  )
  const [contractSrl, setContractSrl] = useState(
    match.params?.contractSrl || null,
  )

  const methods = useForm({
    defaultValues: Object.assign({}, contractDefaultValue),
  })

  useEffect(() => {
    //계약서 데이터있으면 계약서 조회후 공부문서 조회
    if (match.params?.contractSrl) {
      //   settingRecentSrls(match.params.contractSrl)

      onRequestContract({
        channel_srl: match.params.channelSrl,
        contract_srl: match.params.contractSrl,
      })
    }

    //매물에서 계약서 작성으로 넘어온 경우, 공부문서 데이터와 건축물대장 데이터 세팅해줌
    //일단 기본값(주소, 매물 타입, 계약서 종류)등을 세팅하고 양식부터 가져와야하기 때문에 기본값부터 세팅후 완료되면 건축물대장부터 호출
    if (location?.state?.isSearchDocDatas == true) {
      const {
        channel_srl,
        note_srl,
        address_doro, //유효한 주소는 집합건물 여부에 따라 주소를 다르게 보여주므로 지번, 도로명 주소 모두 필요
        address_jibun,
        property_type,
        contract_type,
        total_price,
        rent_price,
        sub_type,
        dong,
        ho,
        presale_base_price,
        presale_option_price,
        presale_paid,
        presale_premium,
        presale_to_pay,
        presale_jeongsan_price,
      } = location?.state

      methods.reset(
        Object.assign({}, methods.getValues(), {
          address_doro,
          address_jibun,
          channel_srl,
          note_srl,
          total_price,
          sub_type,
          dong,
          ho,
          property_type:
            property_type || contractDefaultValue.property_type,
          contract_type:
            contract_type || contractDefaultValue.contract_type,
          payment_schedule: {
            ...methods.getValues().payment_schedule,
            rent_price,
            presale_base_price,
            presale_option_price,
            presale_paid,
            presale_premium,
            presale_to_pay,
            presale_jeongsan_price,
          },
          presale_base_price,
          presale_option_price,
          presale_paid,
          presale_premium,
          presale_to_pay,
          presale_jeongsan_price,
        }),
      )

      setIsLoading(false)
      //계약서 타입, 매물 종류 세팅 -> 계약서 폼 백엔드에서 불러오기위한 flag를 완료로 설정
      setIsPropertyContractBind(true)
      setIsBuildingGongbuLoading(true)
    } else if (location?.state?.isSearchDocDatas == false) {
      //유효하지 않은 주소 계약서 세팅

      //넘어온 데이터 세팅(매물유형, 주소, 거래정보)
      const {
        channel_srl,
        note_srl,
        address,
        property_type,
        contract_type,
        total_price,
        rent_price,
        address_jibun,
      } = location?.state

      const data = Object.assign({}, methods.getValues(), {
        address,
        channel_srl,
        note_srl,
        total_price,
        address_jibun,
        property_type:
          property_type || contractDefaultValue.property_type,
        contract_type:
          contract_type || contractDefaultValue.contract_type,
        payment_schedule: {
          ...methods.getValues().payment_schedule,
          rent_price,
        },
      })
      methods.reset(data)

      //계약서 생성
      onEditContract({
        data: data,
      })

      setIsPropertyContractBind(true)
    }

    return () => {
      dispatch(resetTempRrn())
    }
  }, [])

  /**
   * 건축물 대장 조회
   */
  useEffect(() => {
    if (
      !isBuildingGongbuLoading ||
      !isPropertyContractBind ||
      !location?.state
    )
      return

    const {
      channel_srl,
      note_srl,
      sub_type,
      address,
      address_id,
      dong,
      ho,
    } = location?.state

    onGetBuildingDj({
      address,
      channel_srl,
      note_srl,
      sub_type,
      address_id,
      dong,
      ho,
    })
  }, [isBuildingGongbuLoading])

  //건축물대장 데이터 세팅 -> 공부문서 데이터 세팅
  useEffect(() => {
    if (!dataBuildingDj) return

    const buildingData = {
      struct_type: dataBuildingDj?.result?.d_struct_type || '',
      land_area: dataBuildingDj?.result?.d_land_area || '',
      building_area: dataBuildingDj?.result?.building_area || '',
      building_property_type:
        dataBuildingDj?.result?.d_building_type || '',
      land_type: dataBuildingDj?.result?.d_land_type || '',
    }

    const result = Object.assign(
      {},
      methods.getValues(),
      buildingData,
      dataBuildingDj?.result,
      {
        realestate: [
          {
            addr: dataBuildingDj?.result?.d_junggae_address1 || '',
            company_name:
              dataBuildingDj?.result?.d_junggae_company_name1 || '',
            id: loginInfo?.realtor_number || '',
            name: dataBuildingDj?.result?.d_junggae_name1 || '',
            number: dataBuildingDj?.result?.d_junggae_number1 || '',
          },
        ],
      },
    )

    methods.reset(result)

    const channel_srl = methods.getValues('channel_srl')
    const note_srl = methods.getValues('note_srl')

    onRequestGongbu({
      channel_srl,
      note_srl,
    })
  }, [dataBuildingDj])

  /**
   * 공부문서 데이터 조회 완료, 세팅
   */
  useEffect(() => {
    if (!dataGongbu) return

    if (dataGongbu?.result?.length != 0) {
      const gonbuDatas = {
        daejigwon_ratio: dataGongbu.result?.daejigwon_ratio,
      }
      // 공부문서에 값이 없으면 키 값 삭제 - 건축물대장 정보를 덮어쓰기 때문
      if (!dataGongbu?.result?.building_area) {
        delete dataGongbu.result.building_area
      }

      const subType = methods.getValues('sub_type')
      const jibun = methods.getValues('address_jibun')
      const doro = methods.getValues('address_doro')
      const aptName = methods.getValues('apt_name')
      const dong =
        methods.getValues('dong_name') || methods.getValues('dong')
      const ho = methods.getValues('ho') || ''

      let addressFormat = {}

      if (methods.getValues('address')) {
        //유효하지 않은 주소의 경우 address key로 invalid_addr_name 값을 넘기기 때문에 그대로 address key로 주소 바인딩
        addressFormat = { address: methods.getValues('address') }
      } else {
        if (subType == '집합') {
          //집합건물인 경우 주소 포맷 = 도로명 + 건물명 + 동 + 호
          let address

          if (aptName) {
            address = `${doro} ${aptName}${dong ? ` ${dong}` : ''}${
              ho ? ` ${ho}` : ''
            }`
          } else {
            //건물명 없으면 기본 주소 포맷 (지번 + 동 + 호)
            address = `${jibun}${dong ? ` ${dong}` : ''}${
              ho ? ` ${ho}` : ''
            }`
          }

          addressFormat = {
            address: address,
          }
        } else {
          //집합건물 아닌 경우는 기본 주소 포맷(지번 + 동 + 호)
          addressFormat = {
            address: `${jibun}${dong ? ` ${dong}` : ''}${
              ho ? ` ${ho}` : ''
            }`,
          }
        }
      }

      const gongbu = dataGongbu.result

      const intersection = Object.keys(methods.getValues()).filter(
        function (e) {
          return Object.keys(gongbu).indexOf(e) > -1
        },
      )

      const hasValueValidData = intersection.map((key) => {
        return {
          [key]: gongbu[key] || methods.getValues()[key],
        }
      })

      const result = Object.assign(
        {},
        methods.getValues(),
        gongbu,
        Object.assign({}, ...hasValueValidData),
        addressFormat,
      )

      methods.reset(result)
    }

    //계약서 등록
    onEditContract({
      data: methods.getValues(),
    })

    setIsBuildingGongbuLoading(false)
  }, [dataGongbu])

  /**
   * 계약서 신규 작성 완료
   * - contract_srl을 받아 url 변경
   */
  useEffect(() => {
    if (!dataEditContract) return

    const addResultContractSrl = dataEditContract.contract_srl
    const result = Object.assign({}, methods.getValues(), {
      contract_srl: addResultContractSrl,
    })

    history.replace(`${location.pathname}/${addResultContractSrl}`)

    methods.reset(result)
    setIsLoading(false)
  }, [dataEditContract])

  /**
   * 계약서 데이터 조회 완료(기존)
   */
  useEffect(() => {
    if (!dataContract) return

    setIsLoading(false)

    const result = Object.assign(
      {},
      methods.getValues(),
      dataContract.result,
      { channel_srl: match.params.channelSrl || null },
      { note_srl: match.params.noteSrl || null },
    )

    //계약서 타입, 매물 종류 세팅 -> 계약서 폼 백엔드에서 불러오기위한 flag를 완료로 설정
    setIsPropertyContractBind(true)

    methods.reset(result)
  }, [dataContract])

  /**
   * 계약서 인쇄
   */
  const onClickPrint = (tab) => {
    if (
      !match?.params?.channelSrl ||
      !match?.params?.contractSrl ||
      !tab
    ) {
      message.error('잘못된 정보입니다.')
      return
    }

    window.open(
      `/jmapnote/api/print/${match.params.channelSrl}/${match.params.contractSrl}/${tab}`,
    )
  }

  // /**
  //  * localstorage에 접속했던 계약서 srl 저장
  //  * @param {*} contract_srl
  //  */
  // const settingRecentSrls = (contract_srl) => {
  //   const localStorageContractSrls =
  //     localStorage.getItem('recentContractSrls') || '[]'
  //   const parsedList = JSON.parse(localStorageContractSrls)
  //   localStorage.setItem(
  //     'recentContractSrls',
  //     JSON.stringify(
  //       [...parsedList, ...[contract_srl]].filter(onlyUnique),
  //     ),
  //   )
  // }

  return children({
    methods,
    loading: isLoading,
    contractSrl,
    setContractSrl,
    isBuildingGongbuLoading,
    isPropertyContractBind,
    isLayerVisible,
    setIsLayerVisible,
    onClickPrint,
  })
}
export default NoteDocsEditContainer
