import theme from 'lib/styles/theme'
import styled from 'styled-components'

export const Title = styled.div`
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  line-height: 170%;
  ${({ padding }) =>
    padding &&
    `
    padding-top: 24px;
    padding-left: 24px;
  `}
  ${({ nomargin }) =>
    nomargin &&
    `
    margin-bottom: 0;
  `}
`

export const Section = styled.div`
  padding: 24px 20px;
  border-bottom: 8px solid ${theme.colors.gray[100]};
  background-color: ${theme.colors.base.white};
  width: 100%;

  ${({ nopadding }) => nopadding && `padding: 0;`}
  ${({ noborder }) =>
    noborder &&
    `
    border-bottom: 0;
    padding-bottom: 0;
  `}
`
