import { Table } from 'antd'
import { Button } from 'components/design'
import NoteContactSave from 'components/jmapnote/contact/NoteContactSave'
import {
  Header,
  Title,
  MaxWidthWrapper,
  SearchInput,
} from 'components/jmapnotev2/style/NoteStyle'
import NoteCustomerContainer from 'containers/notev2/NoteCustomerContainer'
import theme, { media } from 'lib/styles/theme'
import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import SelectBox from '../elements/SelectBox'
import NoteCustomerFile from './NoteCustomerFile'
import NoteContactModify from 'components/jmapnote/contact/NoteContactModify'
import MobileChannelHeader from '../channel/MobileChannelHeader'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteMainWrapper from '../style/NoteMainWrapper'
import { useSelector } from 'react-redux'

const NoteCustomer = () => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const isMobile = useIsMobile()
  const columns = [
    {
      title: '이름',
      dataIndex: 'alias',
      key: 'alias',
      render: (text, record, index) => {
        return <NameCell>{text}</NameCell>
      },
    },
    {
      title: '연락처',
      dataIndex: 'numbers',
      key: 'numbers',
      render: (text, record, index) => {
        return <ContactCell>{text?.[0]?.number}</ContactCell>
      },
    },
    {
      title: '연결된 매물',
      dataIndex: 'notes',
      key: 'notes',
      render: (notes) => {
        const masters = notes
          .filter((note) => note.type === '집주인')
          ?.map((item) => item.note_srl)
        const tenant = notes
          .filter((note) => note.type === '세입자')
          ?.map((item) => item.note_srl)

        return (
          <NoteItemWrap>
            {masters.length > 0 && (
              <span>
                집주인 <strong>{masters.length}</strong>
                {/* [{masters.toString()}] */}
              </span>
            )}
            {tenant.length > 0 && (
              <span>
                세입자 <strong>{tenant.length}</strong>
                {/*  [{tenant.toString()}] */}
              </span>
            )}
          </NoteItemWrap>
        )
      },
      width: '25%',
    },
    {
      title: '메모',
      dataIndex: 'memo',
      key: 'memo',
      width: '35%',
      render: (text, record, index) => {
        return <MemoCell>{text}</MemoCell>
      },
    },
  ]

  return (
    <>
      {isMobile && <MobileChannelHeader channel={false} />}
      <NoteMainWrapper singleM single gray>
        <Scrollbars autoHide>
          <StyledMaxWidthWrapper>
            <Wrapper>
              {/* <NoteMode /> */}
              <Header>
                <Title>고객관리</Title>
              </Header>

              <NoteCustomerContainer>
                {({
                  onSearch,
                  list,
                  bindSearch,
                  orderBy,
                  setOrderBy,
                  isModalVisible,
                  setIsModalVisible,
                  saveCallbackFn,
                  isEditModalVisible,
                  setEditModalVisible,
                  onClickRow,
                  rowRecord,
                  searchRequest,
                }) => (
                  <>
                    <SearchWrap>
                      <InputWrap>
                        <SearchIcon className="fas fa-search"></SearchIcon>
                        <StyledInput
                          type="text"
                          placeholder="이름으로 검색"
                          onKeyDown={onSearch}
                          {...bindSearch}
                        />
                        <SelectBox
                          minWidth={120}
                          value={{ value: orderBy, label: orderBy }}
                          options={[
                            {
                              label: '이름순',
                              value: '이름순',
                            },
                            {
                              label: '최근 등록순',
                              value: '최근 등록순',
                            },
                          ]}
                          onChange={(e) => setOrderBy(e.value)}
                        />
                      </InputWrap>
                      {channelInfo?.member_type != 'readonly' && (
                        <Button
                          color="blue"
                          size={isMobile ? 'small' : 'medium'}
                          onClick={() => setIsModalVisible(true)}
                        >
                          {isMobile ? (
                            <i className="fa fa-plus"></i>
                          ) : (
                            '연락처 추가하기'
                          )}
                        </Button>
                      )}
                    </SearchWrap>

                    <StyledTable
                      scroll={{ x: 'max-content' }}
                      onRow={(record) => {
                        return {
                          onClick: () => onClickRow(record),
                        }
                      }}
                      columns={columns}
                      dataSource={list}
                    />

                    <NoteContactSave
                      visible={isModalVisible}
                      setVisible={setIsModalVisible}
                      callbackFn={saveCallbackFn}
                    />

                    <NoteContactModify
                      title="연락처 수정"
                      defaultValue={rowRecord}
                      visible={isEditModalVisible}
                      setVisible={setEditModalVisible}
                      callbackFn={saveCallbackFn}
                      removeCallbackFn={searchRequest}
                    />
                  </>
                )}
              </NoteCustomerContainer>
              {channelInfo?.member_type != 'readonly' && (
                <NoteCustomerFile />
              )}
              {channelInfo?.member_type == 'readonly' && (
                <>
                  <br />
                  <br />
                </>
              )}

              <InfoTitle>유의사항</InfoTitle>
              <InfoDesc>
                · 계약서에 고객 정보가 작성된 고객인 경우, 고객관리에서
                연락처가 변경되어도 계약서 상의 정보는 변경되지
                않습니다.
              </InfoDesc>
            </Wrapper>
          </StyledMaxWidthWrapper>
        </Scrollbars>
      </NoteMainWrapper>
    </>
  )
}

const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 26px;
  button {
    flex-shrink: 0;
  }

  ${media.smallPc`
    margin-bottom: 12px;
  `}
`

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: 100%;
  height: 34px;
`
const StyledInput = styled(SearchInput)`
  width: 50%;
  height: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  padding-left: 32px;
`

const Wrapper = styled.div`
  background-color: ${theme.colors.base.white};
  padding: 0 40px;
  ${media.mediumS`
    padding: 0 20px calc(${theme.base.bottomMenuHeight}px + 30px) 20px;
  `}
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  margin: 0 auto;
  height: 100%;
  background-color: ${theme.colors.base.white};
  ${media.smallPc`
    width: 100%;
  `}

  ${media.mediumS`
    padding: 30px 0;
  `}
`

const StyledTable = styled(Table)`
  border: 1px solid ${theme.colors.gray[200]};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  ${media.smallPc`
    &.ant-table-wrapper {
      max-width: none !important;
      margin-right: -20px;
    }
  `}

  .ant-table-cell-row-hover {
    background: ${theme.colors.blue[50]} !important;
    cursor: pointer;
  }

  .ant-table-thead {
    th {
      background-color: ${theme.colors.base.white} !important;
    }
  }

  .ant-table-cell {
    text-align: center !important;
    padding: 0 !important;
    height: 34px;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
  }

  .ant-pagination {
    justify-content: center;

    li {
      border-radius: 50%;
      overflow: hidden;
      &.ant-pagination-item-active {
        border: none;
        background-color: ${theme.colors.gray[200]};
      }
    }
  }
`

const SearchIcon = styled.i`
  color: ${theme.colors.gray[700]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`

const InfoTitle = styled.div`
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 600;
`

const InfoDesc = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  color: ${theme.colors.gray[600]};
`

const NoteItemWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  min-width: 160px;
`

const NameCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 120px;
  text-align: center;
`

const ContactCell = styled.div`
  min-width: 160px;
  text-align: center;
`

const MemoCell = styled.div`
  min-width: 200px;
  text-align: center;
`

export default NoteCustomer
