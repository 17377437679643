import HomePage from 'pages/HomePage'
import NoteDetail from 'pages/jmapnotev2/NoteDetail'

const NoteSharePage = () => {
  return (
    <>
      <HomePage />
      <NoteDetail />
    </>
  )
}

export default NoteSharePage
