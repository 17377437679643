import styled from 'styled-components'
import theme from 'lib/styles/theme'
import HomeSearch from 'components/home/HomeSearch'
import { rgba } from 'polished'

const StyledHomeHead = styled.div`
  position: relative;
  padding: 4.2rem 1.25rem;
  border-bottom: 1.5rem solid white;
  /* background-color: #3a94fd; */
  background-color: ${theme.colors.blue[400]};
`

const HomeHeadTitle = styled.h1`
  color: ${rgba('white', 0.7)};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2;
  padding-left: 1rem;
  margin: 0;

  & > strong {
    color: white;
    font-weight: 600;
  }
`

const HomeHead = () => {
  return (
    <StyledHomeHead>
      <HomeHeadTitle>
        건축과 투자를 위한
        <br />
        <strong>전국 부동산 정보를 한눈에</strong>
      </HomeHeadTitle>

      <HomeSearch />
    </StyledHomeHead>
  )
}

export default HomeHead
