import { Button } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDonghoDanjiAddModal from './NoteDonghoDanjiAddModal'
import { Star } from './NoteDonghoStyle'
import {
  setIsInfoBubbleVisible,
  setVisibleAddModal,
} from 'modules/noteDongho'
import { useDispatch } from 'react-redux'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import NoteDonghoSpeechBubble from './NoteDonghoSpeechBubble'
import { Tooltip } from 'react-tooltip'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 동호관리
 * 왼쪽 헤더
 * @param searchRefresh 왼쪽 리스트 refresh
 * @param hideAddButton 단지 추가/삭제 버튼 숨기기
 */
const NoteDonghoLeftSideHeader = ({ searchRefresh, hideAddButton }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const dispatch = useDispatch()

  const onClickAdd = () => {
    dispatch(setVisibleAddModal(true))
  }

  const onClose = () => {
    dispatch(setVisibleAddModal(false))
  }

  return (
    <>
      {!isMobile && !isTablet && (
        <Wrapper>
          <Title>동호관리</Title>
        </Wrapper>
      )}
      {hideAddButton ? (
        <EmptyDanji onClick={onClickAdd}>
          <div>
            즐겨찾는 단지를
            <br />
            등록해 보세요!
          </div>
          <AddButton gray size="small">
            <Star className="fas fa-star" /> 단지 추가
          </AddButton>
        </EmptyDanji>
      ) : (
        <>
          <StyledButton expand color="gray" onClick={onClickAdd}>
            단지 추가/삭제
          </StyledButton>
        </>
      )}
      <NoteDonghoDanjiAddModal
        searchRefresh={searchRefresh}
        onCancel={onClose}
      />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  ${media.mediumM`
    margin-top: 0;
    margin-bottom: 0;
  `}
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
`

const StyledButton = styled(Button)`
  height: 34px;
  color: ${theme.colors.gray[600]};

  svg {
    margin-left: 8px;
  }
`

const EmptyDanji = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  margin-top: 29px;
  padding: 20px 16px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 700;
  border-radius: 12px;
  border: 1px dashed #d3d8de;
  color: ${theme.colors.gray[900]};
  cursor: pointer;
`

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 7px 0;
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${theme.colors.gray[600]};
  i {
    margin-right: 4px;
  }
`
export default NoteDonghoLeftSideHeader
