import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const SummaryNearbyButton = () => {
  const params = useParams()

  const linkTo = () => {
    if (params?.external && params.external === 'nearby') {
      return `/${params?.id}`
    } else {
      return `/${params?.id}/nearby`
    }
  }

  return (
    <Wrapper>
      <StyledLink to={linkTo()}>
        주변 유사 거래 보기 <i className="far fa-angle-right"></i>
      </StyledLink>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 20px;
`

const StyledLink = styled(Link)`
  color: ${theme.colors.gray[500]};
  font-size: 14px;
  text-decoration: underline;
  text-underline-position: under;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
    color: ${theme.colors.gray[700]};
  }

  i {
    margin-left: 4px;
  }
`

export default SummaryNearbyButton
