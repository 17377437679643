import MapLayerSelect from 'components/map/MapLayer/MapLayerSelect'
import MapContext from 'contexts/map'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { setOpenFilterType } from 'modules/map'
import { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/**
 * 지도 레이어 container
 */
const MapLayerSelectContainer = () => {
  const {
    state: { layerState },
    actions: { setLayerState },
  } = useContext(MapContext)

  const openFilterType = useSelector(
    (state) => state.map.openFilterType,
  )

  const dispatch = useDispatch()

  // 로컬스토리지에 저장된 레이어 불러와서 세팅
  useEffect(() => {
    const storedLayerState = localStorage.getItem('layerState')

    if (!storedLayerState) return
    const parsedData = JSON.parse(storedLayerState)
    setLayerState(parsedData)
  }, [])

  //레이어 선택 할 때 마다 로컬스토리지에 세팅
  useUpdateEffect(() => {
    localStorage.setItem('layerState', JSON.stringify(layerState))
  }, [layerState])

  return (
    <MapLayerSelect
      visible={openFilterType === 'layer'}
      onClose={() => dispatch(setOpenFilterType(null))}
    />
  )
}

export default MapLayerSelectContainer
