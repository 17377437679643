import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { Button } from 'components/design'
import ErrorView from 'components/common/ErrorView/ErrorView'
import { FormItem } from 'components/design'
import { Modal } from 'components/design'
import { Select } from 'components/design'
import theme from 'lib/styles/theme'
import useFolders from 'pages/jmapnote/noteList/hooks/useFolders'
import useNotes from 'pages/jmapnote/noteList/hooks/useNotes'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import CalendarNoteSelectItem from './CalendarNoteSelectItem'

const StyledNoteList = styled.div`
  position: relative;
  margin: 0 -1.25rem;
`

const CalendarNoteSelect = ({
  channelSrl,
  setChannelSrl,
  folderSrl,
  setFolderSrl,
  addressSrl,
  setAddressSrl,
  noteSrl,
  setNoteSrl,
  setNoteData,
}) => {
  const [visible, setVisible] = useState(false)

  const { _getFolders, folders } = useFolders()
  const { _getNotes, notes, loading } = useNotes()

  useEffect(() => {
    if (!channelSrl || channelSrl === 'undefined') return
    _getFolders({ channelSrl })

    // 채널을 변경하면 선택된 폴더를 초기화
    setFolderSrl('')
  }, [channelSrl])

  useEffect(() => {
    if (!folderSrl) return
    _getNotes({ channelSrl, folderSrl })
  }, [folderSrl])

  const toggleDropdown = () => {
    setVisible((prev) => !prev)
  }

  const onClickNoteItem = (item) => {
    // console.log(item)
    setNoteData(item)
    setAddressSrl(item.address_srl)
    setNoteSrl(item.note_srl)
    setVisible(false)
  }

  return (
    <>
      <Button color="blue" plain expand onClick={toggleDropdown}>
        {noteSrl ? '다른 매물 연동하기' : '매물 연동하기'}
      </Button>

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title="매물 연동하기"
      >
        <FormItem label="폴더 선택" isRequired noContainer>
          <Select
            border="blue"
            value={folderSrl}
            onChange={(e) => setFolderSrl(e.target.value)}
            expand
          >
            <option value="">폴더 선택</option>
            {folders.map((item, i) => (
              <option key={i} value={item.folder_srl}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormItem>

        {folderSrl && (
          <FormItem label="매물 선택" noContainer>
            <Spin
              spinning={folderSrl && loading}
              indicator={
                <LoadingOutlined
                  style={{
                    color: theme.colors.primary[400],
                    fontSize: 24,
                  }}
                  spin
                />
              }
            >
              {folderSrl && (
                <StyledNoteList>
                  {notes.length > 0 ? (
                    notes.map((item, i) => (
                      <CalendarNoteSelectItem
                        key={item.note_srl}
                        item={item}
                        onClickNoteItem={onClickNoteItem}
                      />
                    ))
                  ) : (
                    <ErrorView msg="매물이 없습니다." />
                  )}
                </StyledNoteList>
              )}
            </Spin>
          </FormItem>
        )}
      </Modal>
    </>
  )
}

export default CalendarNoteSelect
