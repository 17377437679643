import moment from 'moment'
import {
  Help,
  Label,
  LabelIcon,
  Row,
  RowWrapper,
  Value,
} from 'components/summary/style/SummaryStyle'
import styled from 'styled-components'

const SummaryLegalInfo = ({ court }) => {
  const courtdata = court.zone || court.usearea

  return (
    court.usearea &&
    court.usearea.length > 0 && (
      <StyledRowWrapper>
        <Row>
          <LabelIcon className="fal fa-object-group" />
          <Label>법정 건폐율</Label>
          <Value>
            {courtdata[0] && `${Math.round(courtdata[0])}%`}
          </Value>
        </Row>
        <Row>
          <LabelIcon className="fal fa-layer-group" />
          <Label>법정 용적률</Label>
          <Value>
            {courtdata[1] && `${Math.round(courtdata[1])}%`}
          </Value>
        </Row>
        {courtdata[2] && (
          <>
            <Row>
              <LabelIcon className="fal fa-calendar-alt" />
              <Label>최종 유지 일자</Label>
              <Value>{moment(courtdata[2]).format('YYYY.MM.DD')}</Value>
            </Row>
            <Help>
              <i className="fal fa-info-circle" />
              택지지구단위 기준 단위 입니다.
            </Help>
          </>
        )}
      </StyledRowWrapper>
    )
  )
}

const StyledRowWrapper = styled(RowWrapper)`
  margin-bottom: 24px;
`

export default SummaryLegalInfo
