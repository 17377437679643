import NotePlanNotiContainer from 'containers/notev2/NotePlanNotiContainer'
import { InfoBlock } from './style/NoteDocsStyle'
import styled from 'styled-components'
import { Button } from 'components/design'
import { useSelector } from 'react-redux'

const NotePlanNoti = ({
  contract_srl,
  channel_srl,
  note_srl,
  goToDeunggibuIssuance,
  isNoplanPopup = false,
}) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  return (
    <NotePlanNotiContainer
      channel_srl={channel_srl}
      contract_srl={contract_srl}
      note_srl={note_srl}
      isNoplanPopup={isNoplanPopup}
      goToDeunggibuIssuance={goToDeunggibuIssuance}
    >
      {({
        relatedToPaymentType,
        showPlan,
        showRenew,
        showAddDeunggi,
        noPlanPopup,
      }) => {
        if (relatedToPaymentType == 'no-plan') {
          /** 요금제 없는 경우는 무조건 여기 */
          return (
            <>
              <InfoBlock color="blue">
                요금제를 구매해보시겠어요? 매물노트가 등기부등본 내용을
                대신 적어드려요. 중개사님은 확인만 하세요!
              </InfoBlock>
              <ButtonWrapper>
                <Button color="blue" plain onClick={showPlan}>
                  요금제 보기
                </Button>
              </ButtonWrapper>
              {isNoplanPopup && noPlanPopup}
            </>
          )
        } else {
          /* 요금제는 정상이지만 해당 매물 등기부 없을때 */
          if (relatedToPaymentType == 'no-use') {
            return (
              <>
                <InfoBlock color="blue">
                  등기부등본을 발급 받아보시겠어요? 매물노트가
                  등기부등본 내용을 대신 적어드려요. 중개사님은 확인만
                  하세요!
                </InfoBlock>
                <ButtonWrapper>
                  <Button color="blue" onClick={goToDeunggibuIssuance}>
                    등기부등본 발급하기
                  </Button>
                </ButtonWrapper>
                {isNoplanPopup && noPlanPopup}
              </>
            )
          } else if (relatedToPaymentType == 'buy-deunggi') {
            /* 충전 횟수 부족(등기부등본 없을때만 보여줌) */
            return (
              <>
                <InfoBlock color="blue">
                  구입하신 다운로드권을 모두 사용하셨어요. <br />
                  등기부등본 발급을 위해서는 추가 구입이 필요해요.
                </InfoBlock>
                <ButtonWrapper>
                  <Button color="blue" plain onClick={showPlan}>
                    요금제 보기
                  </Button>
                  <Button color="blue" onClick={showAddDeunggi}>
                    추가 구입하기
                  </Button>
                </ButtonWrapper>
              </>
            )
          } else if (relatedToPaymentType == 'buy-plan') {
            /* 유효기간 만료(등기부등본 없을때만 보여줌) */
            return (
              <>
                <InfoBlock color="blue">
                  요금제 유효기간이 만료되었어요. 등기부등본 발급을
                  위해서는 요금제 갱신이 필요해요
                </InfoBlock>
                <ButtonWrapper>
                  <Button color="blue" plain onClick={showRenew}>
                    요금제 갱신하러 가기
                  </Button>
                </ButtonWrapper>
              </>
            )
          }
        }
      }}
    </NotePlanNotiContainer>
  )
}

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
  margin-top: 20px;

  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

export default NotePlanNoti
