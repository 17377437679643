import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import moment from 'moment'
import { useContext } from 'react'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

const SummaryGovLandPriceListItem = ({ date, price }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const formattedDate = date ? moment(date).format('YYYY.MM.DD') : ''

  function priceFormat(number) {
    if (!number) return '0'

    const unitWords = ['원', '만', '억', '조', '경']
    const splitUnit = 10000
    const splitCount = unitWords.length
    let resultArray = []
    let resultString = ''

    for (let i = 0; i < splitCount; i++) {
      let unitResult =
        (number % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i)
      unitResult = Math.floor(unitResult)
      if (unitResult > 0) {
        resultArray[i] = unitResult
      }
    }

    for (let i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue

      //1억 이하는 천원 단위를 소수점으로 표시
      if (number < 100000000) {
        const thousand = resultString.slice(0, 1)
        resultString = `${String(numberWithCommas(resultArray[i]))}${
          thousand && `.${thousand}`
        }${unitWords[i]} `
      } else {
        resultString =
          String(numberWithCommas(resultArray[i])) +
          unitWords[i] +
          `${resultString ? ` ${resultString}` : ''}`
      }
    }

    return resultString
  }

  const getComputedPrice = (price, isSquareMeter) => {
    const parsedArea = parseInt(price)

    if (parsedArea === 0) return

    if (isSquareMeter) return priceFormat(Math.round(parsedArea))
    return priceFormat(Math.round(parsedArea * 3.305))
  }

  return (
    <Item>
      <div>{formattedDate}</div>
      <Price>
        {getComputedPrice(price, isSquareMeter)}
        <span>(원/{isSquareMeter ? '㎡' : '평'})</span>
      </Price>
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  line-height: 150%;
  padding: 8px 0;
  border-bottom: 1px solid ${theme.colors.gray[100]};
`

const Price = styled.div`
  display: flex;
  align-items: flex-end;

  span {
    &:last-child {
      margin-left: 1px;
      line-height: 150%;
      font-size: 11px;
      font-weight: 400;
      color: ${theme.colors.gray[600]};
    }
  }
`

export default SummaryGovLandPriceListItem
