import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { getFullUrl } from 'utils'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import { setAuthModalVisible } from 'modules/noteAd'
import useUserLoader from 'containers/base/hooks/useUserLoader'

const NoteAccountContainer = ({ children }) => {
  const dispatch = useDispatch()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const planInfo = useSelector((state) => state.auth.planInfo)
  const authModalVisible = useSelector(
    (state) => state.noteAd.authModalVisible,
  ) //본인인증 모달

  const [isValidRealtorNumber, setIsValidRealtorNumber] =
    useState(false) //중개업 등록번호 유효 검증 완료 여부
  const [searchedAddrCode, setSearchedAddrCode] = useState(null) // 중개업 등록번호 검색을 위한 주소 코드 { b_code, h_code }
  const [realtorMessage, setRealtorMessage] = useState('') //중개업 등록번호  유효성 체크 메시지

  const [isLoadingModify, setIsLoadingModify] = useState(false)

  const { onRequest: refreshUserInfo } = useUserLoader(false) //사용자 정보 최초 갱신

  // 회원정보 폼
  const methods = useForm()
  const { register, watch, handleSubmit, reset, setValue } = methods

  const sigungu_id = watch('sigungu_id')

  /**
   * 최초 로딩시 검증 여부 세팅
   */
  useEffect(() => {
    if (loginInfo?.validated) {
      setIsValidRealtorNumber(true)
    }
  }, [planInfo])

  useEffect(() => {
    reset({
      ...loginInfo,
      company_phone1: loginInfo?.company_phone?.[0] || '',
      company_phone2: loginInfo?.company_phone?.[1] || '',
      company_phone3: loginInfo?.company_phone?.[2] || '',
    })
  }, [loginInfo])

  /**
   * 회원정보 수정
   */
  const onSubmit = (data) => {
    dispatch(setAuthModalVisible(true))
    return
  }

  // useUpdateEffect(() => {
  //   if (authModalVisible) return
  //   refreshUserInfo()
  // }, [authModalVisible])

  const onClickMyPage = () => {
    window.location = getFullUrl('/index/dispMemberModifyInfo')
  }

  return children({
    isValidRealtorNumber,
    searchedAddrCode,
    realtorMessage,
    sigungu_id,
    loadingSpinner: isLoadingModify && <FullScreenLoading />,
    setIsValidRealtorNumber,
    setSearchedAddrCode,
    setRealtorMessage,
    onClickMyPage,
    onSubmit: handleSubmit(onSubmit),
    methods,
    register,
  })
}

export default NoteAccountContainer
