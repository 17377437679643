import { useContext } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import TabContext from './context/TabContext'
import { rgba } from 'polished'

const StyledTabItem = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 2px;
  margin-right: 1rem;
  border-bottom: 3px solid transparent;

  color: ${theme.colors.gray[500]};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  word-break: keep-all;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${theme.colors.gray[700]};
  }

  &.active {
    font-weight: 700;

    ${({ color }) =>
      color === 'primary'
        ? `color: ${theme.colors.primary[400]}; border-bottom-color: ${theme.colors.primary[400]};`
        : `color: ${theme.colors.gray[900]}; border-bottom-color: ${theme.colors.gray[900]};`};
  }

  ${({ nocontainer }) => nocontainer && `margin-right: 0;`};

  ${({ inverse }) =>
    inverse &&
    `
    color: ${rgba(theme.colors.base.white, 0.6)};

    &:hover {
      color: ${rgba(theme.colors.base.white, 0.6)};
    }
    &.active {
      color: ${theme.colors.base.white};
      border-bottom-color: ${theme.colors.base.white};
    }
  `}

  ${({ expand }) =>
    expand &&
    `
    flex: 1;
    justify-content: center;
    text-align: center;
  `}
`

const TabItem = ({
  active,
  onClick,
  children,
  expand,
  color = 'default',
  as,
  noContainer,
}) => {
  const {
    state: { inverseState, sizeState },
  } = useContext(TabContext)

  return (
    <StyledTabItem
      className={active && 'active'}
      inverse={inverseState}
      size={sizeState}
      expand={expand}
      onClick={onClick}
      color={color}
      as={as}
      nocontainer={noContainer}
    >
      {children}
    </StyledTabItem>
  )
}

export default TabItem
