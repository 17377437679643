import styled from 'styled-components'
import NoteDetailAdList from './NoteDetailAdList'

const NoteDetailAd = () => {
  return (
    <Wrapper>
      <Title>광고 관리</Title>
      <NoteDetailAdList />
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Title = styled.div`
  font-weight: 500;
  line-height: 100%;
`

export default NoteDetailAd
