import { useInput } from 'lib/hooks/useInput'
import { useState } from 'react'

/**
 * 계약관리
 * 선택한 필터
 */
const NoteDocsSearchContainer = ({ children }) => {
  const [search, bindSearch] = useInput('')
  //선택한 필터. 목록 조회시에도 써서 최상단에 정의
  const [filters, setFilters] = useState({
    contract_type: '',
    property_type: '',
  })

  return children({
    search,
    bindSearch,
    filters,
    setFilters,
  })
}

export default NoteDocsSearchContainer
