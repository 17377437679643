import theme from 'lib/styles/theme'
import styled from 'styled-components'
import React, { useCallback, useRef, useState } from 'react'
import Swipe from 'react-easy-swipe'
import { AutoSizer } from 'react-virtualized'
import { FixedSizeList as List } from 'react-window'
import NoteListMobileItem from 'components/jmapnotev2/NoteList/NoteListMobileItem'

/**
 * 광고목록 모바일 버전
 */
const NoteAdListMobile = ({
  data,
  input,
  onSwipeStart,
  onSwipeMove,
  onSwipeEnd,
  onClick,
  replaceSearch,
}) => {
  const sliceData = data.slice(1)

  const listRef = useRef({})

  /**
   * 리스트 아이템
   * @param {*} param0
   * @returns
   */
  const Row = ({ index, style }) => {
    const item = sliceData[index]

    return (
      <NoteListMobileItem
        type="ad"
        item={item}
        key={index}
        style={style}
        onClick={onClick}
        replaceSearch={replaceSearch}
        input={input}
      />
    )
  }

  return (
    <>
      <ScrollWrapper
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
      >
        <AutoSizer
          className="autosizer"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {({ height, width }) => (
            <List
              itemCount={sliceData.length}
              itemSize={148}
              ref={listRef}
              itemData={sliceData}
              width={width}
              height={height}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </ScrollWrapper>
    </>
  )
}

const ScrollWrapper = styled(Swipe)`
  width: 100%;
  height: calc(100svh - 58px);
  border-top: 1px solid ${theme.colors.gray[100]};
`

export default React.memo(NoteAdListMobile)
