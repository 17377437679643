import { useFormContext } from 'react-hook-form'
import { AccountInput } from './NoteAccount'
import { useSelector } from 'react-redux'

/**
 * 계정관리 폼 - 이름
 */
const NoteAccountUserName = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { register } = useFormContext()

  return <span>{loginInfo?.user_name}</span>
}

export default NoteAccountUserName
