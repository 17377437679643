import theme, { media } from 'lib/styles/theme'
import styled, { css } from 'styled-components'
import { gridFontSize } from 'lib/utils/notev2/gridUtil'

export const MaxWidthWrapper = styled.div`
  max-width: 1180px;
  width: 1180px;
  padding-top: 60px;
  padding-bottom: 60px;

  ${({ full }) =>
    full &&
    `
    max-width: none;
    width: 100%;
  `}
  ${({ wide }) =>
    wide &&
    `
    max-width: 1520px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  `}

  ${media.smallPc`
    width: 100%;
    max-width: 100%;
    
    ${({ wide }) =>
      wide &&
      `
        padding-left: 0px !important;
        padding-right: 0px !important;
    `}
  `};
`

export const LayerWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 36px 20px;
  padding-bottom: 100px;
  z-index: 1;

  ${({ fullheight }) =>
    fullheight &&
    `
    height: 100%;
  `}

  ${media.mediumS`
    padding: 24px 20px;
  `}
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;

  ${media.mediumS`
    font-size: 18px;
    font-weight: 500;
    line-height: 180%;
  `}
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 54px;

  ${media.mediumS`
    padding-bottom: 20px;
  `}
`

export const CustomSingleCellStyle = css`
  outline: none;
  border-radius: 0;
  border: 1px solid transparent;

  &:focus {
    border-color: ${theme.colors.blue[500]};
    background-color: ${theme.colors.noteSheet.primary};
  }
`

export const PlaceHolder = styled.div`
  color: ${theme.colors.gray[300]};
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
`

export const CustomSingleCellInputWrapper = styled.div`
  position: relative;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin: -1px;
  border: none;
  /* overflow: visible; */
  ${({ ishalf }) =>
    ishalf == 'Y' &&
    `
    width: calc(50% + 2px);
    position: initial;
  `}
`

export const SheetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.gray[400]};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  border-radius: 4px;
  background-color: #fbfbfb;
  padding: 4px 7px;
  color: ${theme.colors.gray[600]};
  cursor: pointer;
  font-size: ${({ gridfontsize }) =>
    (gridfontsize || gridFontSize['m']) - 1}px;
  &:hover {
    filter: brightness(97%);
  }
  &:active {
    filter: brightness(90%);
  }

  &:disabled {
    color: ${theme.colors.gray[400]};
    border: 1px solid ${theme.colors.gray[300]};
  }
  ${({ size }) => size == 'medium' && `padding: 6px 7px;`}

  ${({ defaultcursor, disabled }) =>
    (defaultcursor || disabled) && `cursor: default;`}
`

export const Description = styled.div`
  font-size: 13px;
  line-height: 130%;
  font-weight: 400;
  color: ${theme.colors.tint.danger};
  ${({ color }) =>
    color == 'black' && `color: ${theme.colors.gray[900]};`}
`

export const BottomBlock = styled.div`
  width: 100%;
  height: 200px;
`

export const ContractWrapper = styled.div`
  position: relative;
  font-size: 15px;
  height: 100%;
  min-width: 900px;
  max-width: 1040px;
  margin-top: 30px;
`

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: 0;
  background-color: ${theme.colors.gray[200]};
`

export const FilterScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  padding-top: 27px;

  ${media.mediumS`
    height: auto;
    margin-bottom: 20px;
  `}
`

export const MainWrapperStyle = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed !important;
  width: calc(
    100% - ${theme.base.note.filterWidth}px - ${theme.base.navWidth}px
  );
  left: ${theme.base.note.filterWidth + theme.base.navWidth}px;
  z-index: 14;

  top: 0;
  //z-index: 100;
  height: 100%;
  background-color: ${theme.colors.base.white};
  ${({ column }) =>
    column &&
    `
    flex-direction: column;
    justify-content: flex-start;  
  `};

  ${({ gray }) =>
    gray == 'Y' && `background-color: ${theme.colors.gray[50]};`};
  ${({ blue }) =>
    blue == 'Y' && `background-color: ${theme.allinone.background};`};
  ${({ noside }) =>
    noside &&
    `
      left: ${theme.base.navWidth}px;
      width: calc(100% - ${theme.base.navWidth}px);
  `}
  ${({ padding }) => padding && `padding: 24px 40px;`};
  ${({ alignLeft }) => alignLeft && `justify-content: flex-start;`};
  ${({ nobackground }) =>
    nobackground && `background-color: transparent;`};

  ${media.mediumM`
    left: ${theme.base.note.filterWidth}px;
    width: calc(100% - ${theme.base.note.filterWidth}px);
    height: calc(100% - ${theme.base.bottomMenuHeight}px) !important;
    justify-content: flex-start;
    
    ${({ earlyMobile }) =>
      earlyMobile &&
      `
      padding-top: ${theme.base.topHeaderHeightM}px;
    `}

    ${({ noside }) =>
      noside &&
      `
        left: ${theme.base.navWidth}px;
        width: 100%;
    `}
  `}

  ${media.mediumS`
    padding-top: ${theme.base.topHeaderHeightM}px;
  `}
`

export const MainWrapper = styled.div`
  ${MainWrapperStyle}
`

export const SearchInput = styled.input`
  width: 360px;
  height: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  padding-left: 36px;
  outline: none;
  font-size: 14px;

  ${({ fullwidth }) => fullwidth && `width: 100%;`};

  ${({ small }) => small && `width: 250px;`};

  &:focus {
    outline: 1px solid ${theme.colors.blue[400]};
  }
`

const mediumS = theme.bp.mediumS
const mediumM = theme.bp.mediumM

export const SwipeableLeftStyle = css`
  @media (min-width: ${mediumS}px) and (max-width: ${mediumM}px) {
    left: ${theme.base.note.filterWidth}px !important;
  }

  @media screen and (min-width: ${theme.bp.mediumM}px) {
    left: ${theme.base.navWidth +
    theme.base.note.filterWidth}px !important;
  }
`

export const SwipeableMainWrapper = css`
  ${media.mediumS`
      height: 100% !important;
      box-shadow: -5px 0 17px -6px rgba(0,0,0,0.25);
  //   transition: all 0.3s cubic-bezier(0, 0.55, 0.45, 1);
      width: 100% !important;
      min-width: unset !important;
      max-width: none !important;

      .ag-root-wrapper {
        pointer-events: none;
        user-select: none;
      }

      &.end-open {

        .ag-root {
          transition: padding 0.2s cubic-bezier(0.33, 1, 0.68, 1);
          padding: 0 !important;
        }
      }

      
      &.active {
        z-index: 50;

        .ag-header-row {
          border-radius: 0;
          border-right: none;
        }

        .ag-root-wrapper {
          pointer-events: auto;
          user-select: all;
        }

        +.m-cover {
          opacity: 0;
          
        }
      }

      &.moving {
        pointer-events: none;
        user-select: none;

        .ag-root-wrapper {
          pointer-events: none;
          user-select: none;
        }
      }
      
      &.animation {
        transition: left 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      }

      &.flip {
        left: 0 !important;
      }

  `}
`

export const MobileCover = styled.div`
  display: none;

  ${media.mediumS`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 15;
    opacity: 0.03;
    transition: opacity 0.8s;
`}
`

export const ScrollStyle = css`
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${theme.colors.gray[300]};
    border: 3px solid transparent;
    min-height: 50px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
`

export const CommonPadding = css`
  padding: 32px 40px;
`

export const WarnText = styled.div`
  font-size: 14px;
  padding: 10px 14px;
  margin: 0 40px;
  margin-bottom: 34px;
  color: ${theme.colors.tint.danger};
  background-color: ${theme.colors.red[50]};
  border: 1px solid ${theme.colors.red[200]};
  border-radius: 8px;
  font-weight: 700;

  ${({ card }) =>
    card &&
    `
    margin: 10px 20px 0 20px;
  `}

  ${media.mediumS`
    margin: 0 10px 10px 10px;
      
    ${({ card }) =>
      card &&
      `
      margin: 10px 20px 0 20px;
    `}

  `}
`
