import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기전세(보증)금
 */
const NoteAdColumnAlreadyDeposit = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const aprice1 = watch('trade.aprice1')

  return (
    <ColumnSet half>
      <ColumnTitle>기전세(보증)금</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            width={InputRemoveAppendWidth}
            type="number"
            defaultValue={aprice1}
            fixAppendWidth
            {...register('trade.aprice1')}
            onBlur={(e) => {
              onUpdate(
                'trade.aprice1',
                e.target.value.replaceAll(',', ''),
              )
            }}
            append={'만원'}
          />
        </div>
        <ValidateWarn>{errors?.trade?.aprice1?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnAlreadyDeposit
