import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth'
import channelReducer from './channel'
import homeReducer from './home'
import notificationReducer from './notification'
import noteReducer from './note'
import noteV2Reducer from './notev2'
import noteAdReducer from './noteAd'
import summaryReducer from './summary'
import mapReducer from './map'
import contactReducer from './contact'
import noticeReducer from './notice'
import housingComplexReducer from './housingComplex'
import layoutReducer from './layout'
import onBoardingReducer from './onBoarding'
import inviteRewardReducer from './inviteReward'
import summaryOtherReducer from './summaryOther'
import filterReducer from './filter'
import noteDonghoReducer from './noteDongho'
import bdsReducer from './bds'

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  reducer: {
    auth: authReducer,
    home: homeReducer,
    notification: notificationReducer,
    channel: channelReducer,
    note: noteReducer,
    notev2: noteV2Reducer,
    noteAd: noteAdReducer,
    summary: summaryReducer,
    map: mapReducer,
    contact: contactReducer,
    notice: noticeReducer,
    housingComplex: housingComplexReducer,
    layout: layoutReducer,
    onBoarding: onBoardingReducer,
    inviteReward: inviteRewardReducer,
    summaryOther: summaryOtherReducer,
    filter: filterReducer,
    noteDongho: noteDonghoReducer,
    bds: bdsReducer,
  },
})
