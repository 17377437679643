import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { deleteAd } from 'lib/api/notev2/adSubmit'
import { useSelector } from 'react-redux'
import { Confirm } from 'components/design'

/**
 * 광고 목록 버튼
 * 광고 삭제
 * @param adSrl
 * @param callbackFn
 * @param setUpdatedAdSrl 재조회를 위해 저장하는 ad_srl
 * @param setDeletedAdSrl 재조회를 위해 저장하는 ad_srl
 * @param forForm 사용처 = form
 */
const DeleteButton = ({
  adSrl,
  callbackFn,
  setUpdatedAdSrl,
  setDeletedAdSrl,
  forForm,
  forNote,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [onRequestDelete, , dataDelete] = useAxios(deleteAd, {
    resetError: true,
  }) //광고 삭제

  const onClickDelete = (adSrl) => {
    Confirm.warn({
      title: '알림',
      content: '광고를 삭제하시겠습니까?',
      onOk: () =>
        onRequestDelete({
          ad_srl: adSrl,
          plan_srl: planInfo.allinone_plan_srl,
        }),
      okButtonProps: { color: 'danger' },
    })

    if (!forForm) {
      setUpdatedAdSrl && setUpdatedAdSrl(adSrl)
      setDeletedAdSrl && setDeletedAdSrl(adSrl)
    }
  }

  useEffect(() => {
    if (!dataDelete) return

    if (
      dataDelete?.result?.errors &&
      dataDelete?.result?.errors.length > 0
    ) {
      message.error('광고 전송에 필요한 항목 검증에 실패하였습니다.')
      return
    }

    message.success('광고가 삭제되었습니다.')
    callbackFn && callbackFn()
  }, [dataDelete])

  return (
    <AdButton
      expand
      color={forForm ? 'danger' : 'default'}
      size={forForm ? 'xlarge' : 'small'}
      redborder={!forForm}
      border={!forForm}
      forForm={forForm}
      onClick={() => onClickDelete(adSrl)}
      forNote={forNote}
    >
      삭제
    </AdButton>
  )
}

export default DeleteButton
