import styled from 'styled-components'
import { Section, Title } from './BdsDetailStyle'

/**
 * 부동산 2뎁스
 * 매물 소개 섹션
 */
const BdsDetailIntroduce = ({ data, tabs }) => {
  return (
    <Section ref={tabs[1].element}>
      <Title>매물 소개</Title>
      <Content>{data?.desc || '매물소개가 없습니다.'}</Content>
    </Section>
  )
}

const Content = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

export default BdsDetailIntroduce
