import Axios from 'axios'

export const getSummary = async (payload, options) => {
  return await Axios.get(
    '/map/api/summary',
    {
      params: {
        ...payload,
      },
    },
    options,
  )
}
export const getAddress = async (payload, options) =>
  await Axios.get(
    '/map/api/getAddress',
    {
      params: {
        ...payload,
      },
    },
    options,
  )

export const getLocationRequirements = async (payload, options) =>
  await Axios.get(
    `/map/api/getLocationRequirements${payload.activedTab + 1}`,
    {
      params: {
        ...payload,
      },
    },
    options,
  )

export const procMemberLogout = async (payload, options) =>
  await Axios.post(
    `/index/procMemberLogout`,
    {
      ...payload,
    },
    options,
  )

export const getSizeReview = async (payload, options) =>
  await Axios.get(`/map/api/getSizeReview`, {
    params: {
      ...payload,
    },
    ...options,
  })

export const getNohuReview = async (payload, options) =>
  await Axios.get(`/map/api/getNohuReview`, {
    params: {
      ...payload,
    },
    ...options,
  })

export const getNohuMasters = async (payload, options) =>
  await Axios.get(`/map/api/getNohuMasters`, {
    params: {
      ...payload,
    },
    ...options,
  })

export const get3DModel = async (payload, options) =>
  await Axios.get(`/map/api/3d`, {
    params: {
      ...payload,
    },
    ...options,
  })

export const getNearBy = async (payload, options) =>
  await Axios.get(`/map/api/getNearby`, {
    params: {
      ...payload,
    },
    ...options,
  })

export const getAdjacentPolygons = async (payload, options) =>
  await Axios.get(`/map/api/getAdjacentPolygons`, {
    params: {
      ...payload,
    },
    ...options,
  })

export const getSummaryLegal = async (payload, options) =>
  await Axios.get(`/map/api/getSummaryLegal`, {
    params: {
      ...payload,
    },
    ...options,
  })

/**
 * 공동주택 공시가격
 */
export const getHousingPrice = async (payload, options) =>
  await Axios.get(`/map/api/getHousingPrice`, {
    params: {
      ...payload,
    },
    ...options,
  })

/**
 * 학군, 보육 시설
 */
export const getLocationSchool = async (payload, options) =>
  await Axios.get(`/map/api/location/school`, {
    params: {
      ...payload,
    },
    ...options,
  })

/**
 * 교통, 편의시설
 */
export const getLocationAmenities = async (payload, options) =>
  await Axios.get(`/map/api/location/amenities`, {
    params: {
      ...payload,
    },
    ...options,
  })

/**
 * 아파트 면적별 단가 비교 가져오기
 */
export const getAptStat = async (payload, options) =>
  await Axios.get(`/map/api/getAptStat`, {
    params: {
      ...payload,
    },
    ...options,
  })

/**
 * 동호수별 실거래가 가져오기
 */
export const getRealPriceByDongHo = async (payload, options) =>
  await Axios.get(`/map/api/getRealPriceByDongHo`, {
    params: {
      ...payload,
    },
    ...options,
  })

/**
 * 전유부 가져오기
 */
export const getBuildingHo = async (payload, options) =>
  await Axios.get(`/map/api/getBuildingHo`, {
    params: {
      ...payload,
    },
    ...options,
  })
