import MapContext from 'contexts/map'
import { useContext } from 'react'
import { NumericFormat } from 'react-number-format'

/**
 * @name useFormatArea
 * @description react-number-format을 활용하여 isSquareMeter 여부에 따라
 * 평 또는 제곱미터 단위로 면적을 표기하고 콤마 등을 추가하여 반환합니다.
 * @param {number} area
 * @returns
 */
export default function useFormatArea(area = 0) {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const parsedArea = parseFloat(area)

  if (area === 0) return null

  if (isSquareMeter) {
    return (
      <>
        <NumericFormat
          value={Math.round(parsedArea * 100) / 100}
          displayType="text"
          thousandSeparator
          suffix="㎡"
        />
      </>
    )
  }

  return (
    <>
      <NumericFormat
        value={Math.round((parsedArea * 100) / 3.3057) / 100}
        displayType="text"
        thousandSeparator
        suffix="평"
      />
    </>
  )
}
