import { useCallback, useState, useEffect } from 'react'
import useAxios from '../useAxios'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { modifyAddress } from 'lib/api/notev2/listApi'
import moment from 'moment'
import { useSelector } from 'react-redux'

/**
 * 매물을 수정한다
 * @returns 매물 수정 함수
 *
 * * onModifyNote({ params, updateData, rowNode }): 기존 그리드 데이터 전체, 업데이트할 데이터
 *   -> rowNode 데이터가 있으면, 저장 요청의 응답이 성공이면 그리드 업데이트까지 한번에 같이한다
 * * isError: 에러가 났는지 여부
 *
 */
function useModifyNote() {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [onRequest, , data] = useAxios(modifyAddress)
  const [isError, setIsError] = useState(false)
  const [rowNode, setRowNode] = useState(null)
  const [newData, setNewData] = useState(null)
  const [isPresale, setIsPresale] = useState(false)

  /**
   * 노트 저장
   * @param channel_srl 채널 srl
   * @param address_srl 주소 srl
   * @param form 주소 정보
   */
  const onModifyNote = useCallback(
    ({ data, updateData, currentRowNode, presale }) => {
      if (channelInfo?.member_type == 'readonly') return
      if (currentRowNode) {
        setRowNode(currentRowNode)
        setNewData(updateData)
      }
      if (presale) {
        setIsPresale(true)
      }
      //노트 저장 api
      onRequest({
        channel_srl: data.channel_srl,
        address_srl: data.address_srl,
        address_id: data.address_id,
        note_srl: data.note_srl,
        dong: data?.dong ? data.dong : '',
        ho: data?.ho ? data.ho : '',
        form: updateData,
      })
    },
    [],
  )

  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.error('modify note 에러')
      setIsPresale(false)
      setIsError(true)
      return
    }
    setIsPresale(false)
    if (rowNode) {
      //grid
      if (isPresale) {
        //시트 - 분양권 매매 저장
        const copyData = { ...newData }
        const resultData = Object.assign({}, copyData, {
          extra_vars: {
            presale_base_price: copyData.presale_base_price,
          },
        })
        updateGridDatas({
          newData: Object.assign({}, resultData, {
            last_update: moment().format('YYYY-MM-DD HH:mm:ss'),
          }),
          currentRowNode: rowNode,
        })
      } else {
        updateGridDatas({
          newData: Object.assign({}, newData, {
            last_update: moment().format('YYYY-MM-DD HH:mm:ss'),
          }),
          currentRowNode: rowNode,
        })
      }
    }
  }, [data])

  return [onModifyNote, isError, data]
}

export default useModifyNote
