import { useSelector } from 'react-redux'
import {
  Block,
  BlockTitle,
  CurrentPlanButton,
  List,
  ListItem,
  Price,
  PriceWrapper,
  ScheduledCancelText,
  StartButton,
} from './NotePaymentPlanListStyle'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import NotePaymentCancelAllInOneToBasicContainer from 'containers/notev2/NotePaymentCancelAllInOneToBasicContainer'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import { getVisibleStartButton } from 'lib/utils/notev2/payment'
import { message } from 'antd'

const NotePaymentPlanListBasic = ({ onClickPayment }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const { isAllInOne } = useIsAllInOne()

  const isExpectedCancel = planInfo?.is_expected_cancel
  const paymentName = planInfo?.payment_name
  const isBasicPlan = paymentName == '베이직'
  const hasCard = planInfo?.has_billingkey || planInfo?.extra_vars?.card

  /**
   * 베이직 결제
   */
  const paymentBasicPlan = () => {
    onClickPayment('베이직', 4900)
  }

  /**
   * 베이직 결제 버튼
   * @param {*} onClickCancelPlan 올인원 -> 베이직 해지 function
   */
  const onClickPaymentButton = (onClickCancelPlan) => {
    if (isAllInOne) {
      if (planInfo?.is_expected_cancel) {
        message.info(
          '올인원 플랜 해지 예정 상태에서는 베이직 플랜으로 변경 하실 수 없습니다.',
        )
        return
      }
      onClickCancelPlan()
    } else {
      paymentBasicPlan()
    }
  }

  return (
    <Block active={isBasicPlan}>
      <BlockTitle>
        베이직
        {planInfo?.event_in_use && <FreeBadge>첫달무료</FreeBadge>}
        {isBasicPlan && (
          <CurrentPlanButton>현재 요금제</CurrentPlanButton>
        )}
        {isBasicPlan && isExpectedCancel && (
          <ScheduledCancelText>
            요금제를 해지했습니다.
          </ScheduledCancelText>
        )}
      </BlockTitle>
      <PriceWrapper>
        <Discount>
          <DiscountPrice>8,900원</DiscountPrice>
          <Percent>45%</Percent>
        </Discount>
        <Prices>
          <Price>
            4,900<span>원</span>
          </Price>
        </Prices>
      </PriceWrapper>

      <NotePaymentCancelAllInOneToBasicContainer>
        {({
          onClickCancelPlan,
          cancelConfirmModal,
          cancelDoneModal,
          loading,
        }) => {
          return (
            <>
              {getVisibleStartButton(planInfo, '베이직') ? (
                <StartButton
                  size="large"
                  expand
                  color={isBasicPlan ? 'default' : 'blue'}
                  onClick={() =>
                    onClickPaymentButton(onClickCancelPlan)
                  }
                >
                  {isBasicPlan && isExpectedCancel
                    ? '다시 시작하기'
                    : hasCard
                    ? '시작하기'
                    : '30일 무료 체험'}
                </StartButton>
              ) : (
                isBasicPlan && (
                  <StartButton
                    expand
                    size="large"
                    color="darkGray"
                    disabled
                  >
                    현재 이용 중
                  </StartButton>
                )
              )}
              {cancelConfirmModal}
              {cancelDoneModal}
              {loading}
            </>
          )
        }}
      </NotePaymentCancelAllInOneToBasicContainer>

      <List>
        <ListItem bold="Y">등기부등본 발급 가능</ListItem>
        <ListItem>매물노트 이용</ListItem>
        <ListItem>계약서 작성</ListItem>
        <ListItem>확인설명서 작성</ListItem>
        <ListItem>동호관리 플러스단지 1개</ListItem>
      </List>
    </Block>
  )
}

const FreeBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 65px;
  height: 30px;
  border-radius: 999px;
  background-color: ${theme.colors.red[500]};
  color: ${theme.colors.base.white};
  font-size: 14px;
  font-weight: 800;
  line-height: 100%;
`

const Discount = styled.div`
  display: flex;
`

const DiscountPrice = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  color: ${theme.colors.gray[400]};
  text-decoration: line-through;

  ${media.mediumS`
    font-weight: 400;
  `}
`

const Percent = styled.div`
  color: ${theme.colors.tint.danger};
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  margin-left: 4px;
`

const Prices = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    flex-shrink: 0;
  }

  ${media.mediumS`
    gap: 10px;
  `}
`

export default NotePaymentPlanListBasic
