import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { useContext } from 'react'
import BaseContext from 'contexts/baseContext'
import { useSelector } from 'react-redux'

const StyledLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  min-height: 36px;
  padding: 8px;

  margin-left: -0.75rem;
  margin-right: 0.25rem;

  color: ${theme.colors.gray[900]};
  font-size: 1.125rem;

  appearance: none;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover,
  &:active {
    color: ${theme.colors.gray[600]};
  }

  ${({ inverse }) =>
    inverse &&
    `
    color: white;

    &:hover,
    &:active {
      color: ${theme.colors.gray[100]};
    }
  `}
`

const HeaderBackWrap = styled.div`
  position: absolute;
  z-index: 50;
  top: ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible ? `${noticeBarHeight}px` : 0};
  left: ${theme.base.navWidth}px;
  width: ${theme.base.container}px;
  background-color: ${theme.colors.base.white};
  height: ${theme.base.navWidth}px;
  display: flex;
  align-items: center;
  padding-left: 1.25rem;

  ${media.mediumM`
    width: 100%;
    max-width: unset;
    left: 0;
    z-index: 1;
  `}

  ${media.small`
    left: 0;
  `}
`

const Title = styled.div`
  color: ${theme.colors.gray[900]};
  font-weight: 700;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  display: ${({ visible }) => (visible ? 'block' : 'none')};

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
        font-size: 1rem;
        `
      case 'medium':
        return `
        font-size: 1.125rem;
        `
    }
  }};
`

const HeaderBackAbsolute = ({
  onClose,
  inverse,
  title,
  titleSize = 'medium',
  titleVisible = true,
}) => {
  const history = useHistory()

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)

  const {
    state: { headerInverse },
  } = useContext(BaseContext)

  const onClickBack = useCallback(() => {
    if (onClose) {
      onClose()
      return
    }

    history.go(-1)
  })

  return (
    <HeaderBackWrap
      isNoticeBarVisible={isNoticeBarVisible}
      noticeBarHeight={height}
    >
      <StyledLink
        inverse={headerInverse || inverse || false}
        onClick={onClickBack}
      >
        <i className="far fa-chevron-left"></i>
      </StyledLink>
      <Title visible={titleVisible} size={titleSize}>
        {title}
      </Title>
    </HeaderBackWrap>
  )
}

export default HeaderBackAbsolute
