import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import { setLoginVisible } from 'modules/auth'
import Log from 'lib/utils/Log'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import FirstUserPopup from 'components/jmapnote/channel/FirstUserPopup'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

/**
 * @name: NoteLinkContainer
 * @description 매물노트로 이동하는 기능을 수행하는 renderless component입니다.
 * - 매물노트로 이동하는 링크 구현 시 사용됩니다.
 * - 비로그인 상태에서는 로그인 팝업을 출력합니다.
 * - 로그인 상태일 경우 매물노트에 액세스 가능한 부동산 중개인 유저에게만 아이콘,
 * 또는 버튼을 출력하도록 합니다.
 * - 최근 접근한 채널 및 폴더 정보를 기반으로 매물노트 링크를 생성하여 이동시킵니다.
 */
const NoteLinkContainer = ({ children }) => {
  const { hasPlan } = useNoteHasPlan()
  const history = useHistory()
  const [firstUserPopupVisible, setFirstUserPopupVisibie] =
    useState(false)
  const { isJMapNote } = useNoteMatch()
  /**
   * channels 최신 정보 조회를 위해
   */

  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const channels = useSelector((state) => state.channel.channels)
  const dispatch = useDispatch()

  const memberType =
    JSON.parse(localStorage.getItem('memberType')) || ''

  const onClick = useCallback(() => {
    // 로그인 상태가 아닐 때 로그인창 출력
    if (!loginInfo?.member_srl) {
      dispatch(setLoginVisible(true))
      return
    }

    Log.info('channels', channels)

    // 사용자가 생성한 채널이 없을 경우 채널 생성 팝업 출력
    if (channels.length === 0) {
      setFirstUserPopupVisibie(true)
      return
    }

    if (hasPlan) {
      //요금제가 있으면 대시보드로 이동
      history.push('/note/v2/dashboard')
    } else {
      if (localStorage.getItem('notemode') == 'card') {
        //카드모드
        // localStorage에 저장된 최근 접속한 채널srl과 folderSrl을 불러옵니다.
        const recentChannelSrl =
          localStorage.getItem('recentChannelSrl')
        const recentFolderSrl = localStorage.getItem('recentFolderSrl')

        // 최근 접속한 채널/폴더 정보가 있을 경우 유효성 체크 후 이동시킵니다.
        if (
          recentChannelSrl &&
          recentFolderSrl &&
          recentChannelSrl !== 'undefined' &&
          recentFolderSrl !== 'undefined'
        ) {
          const authorizedChannelSrl = channels.filter(
            ({ channel_srl }) =>
              channel_srl === parseInt(recentChannelSrl),
          )[0]?.channel_srl

          // 채널 목록에 최근에 접속한 채널과 폴더 srl 값이 있는 경우
          if (authorizedChannelSrl) {
            moveToFirstChannel(
              authorizedChannelSrl,
              recentFolderSrl,
              'card',
            )
            return
          }
        }

        /**
         * 최근 접속한 채널/폴더 정보가 없을 경우 채널 목록에서
         * 로그인한 회원의 member_srl값과 동일한 채널의 폴더 목록으로 이동
         */
        const myChannel = channels?.filter(
          ({ member_srl }) =>
            member_srl === parseInt(loginInfo.member_srl),
        )

        if (myChannel.length == 0) {
          setFirstUserPopupVisibie(true)
          return
        }

        moveToFirstChannel(
          myChannel[0].channel_srl,
          myChannel[0].folder_srl,
          'card',
        )
      } else {
        //시트모드
        // localStorage에 저장된 최근 접속한 채널srl과 folderSrl을 불러옵니다.
        const recentChannelSrl =
          localStorage.getItem('recentChannelSrl')

        // 최근 접속한 채널/폴더 정보가 있을 경우 유효성 체크 후 이동시킵니다.
        if (recentChannelSrl && recentChannelSrl !== 'undefined') {
          const authorizedChannelSrl = channels.filter(
            ({ channel_srl }) =>
              channel_srl === parseInt(recentChannelSrl),
          )[0]?.channel_srl

          // 채널 목록에 최근에 접속한 채널과 폴더 srl 값이 있는 경우
          if (authorizedChannelSrl) {
            moveToFirstChannel(authorizedChannelSrl, null, 'sheet')
            return
          }
        }

        /**
         * 최근 접속한 채널/폴더 정보가 없을 경우 채널 목록에서
         * 로그인한 회원의 member_srl값과 동일한 채널의 폴더 목록으로 이동
         */
        const myChannel = channels?.[0]

        moveToFirstChannel(myChannel?.channel_srl, null, 'sheet')
      }
    }
  }, [channels, loginInfo])

  /**
   * @param {string} channelSrl
   * @param {string} folderSrl
   * @description 인자로 받은 channelSrl, folderSrl을 바탕으로
   * 링크를 생성하고 이동시킵니다.
   */
  const moveToFirstChannel = (channelSrl, folderSrl, mode) => {
    if (!channelSrl || (mode == 'card' && !folderSrl)) {
      message.error(
        '일시적인 문제가 발생했습니다. 관리자에게 문의하십시오.',
      )
      return
    }

    if (mode == 'card') {
      history.push(`/note/${channelSrl}/${folderSrl}`)
    } else {
      history.push(`/note/v2/notes/${channelSrl}`)
    }
  }

  // 부동산 중개사 회원이 아닐 경우  표시하지 않음
  if (!memberType && loginInfo?.member_type !== 'realestate')
    return null

  return children({
    popup: (
      <FirstUserPopup
        visible={firstUserPopupVisible}
        setVisible={setFirstUserPopupVisibie}
        onSuccess={moveToFirstChannel}
      />
    ),
    active: isJMapNote,
    onClick,
  })
}

export default NoteLinkContainer
