import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { rgba } from 'polished'
import { Tag } from 'components/design'
import { getIcons, getTagColors } from 'lib/utils/propertyType'

const Thumbnail = styled.div`
  width: 52px;
  height: 52px;

  background-color: ${theme.colors.gray[200]};
  border-radius: ${theme.borderRadius[1]};

  background-size: cover;
  background-position: center;

  ${({ thumbnail }) =>
    thumbnail &&
    `
    background-image: url(${thumbnail});
  `}
`

const PropertyType = ({ thumbnail, propertyType }) => {
  const colors = {
    red: '#d14b50',
    blue: '#4c5cbb',
    cyan: '#56b7eb',
    purple: '#7f5dec',
    pink: '#da60bf',
    black: theme.colors.gray[700],
    orange: '#da9e50',
    green: '#27a074',
  }

  const transpilePropertyType = (propertyType) => {
    switch (propertyType) {
      case '다가구주택':
        return '다가구'
      case '다세대주택':
        return '다세대'
      default:
        return propertyType
    }
  }

  // if (thumbnail) return <Thumbnail thumbnail={thumbnail}></Thumbnail>

  return (
    <Block color={colors[getTagColors(propertyType) || 'blue']}>
      <i className={`fad ${getIcons(propertyType)}`} />
      <span>{transpilePropertyType(propertyType)}</span>
    </Block>
  )
}

const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 44px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;

  ${({ color }) => `
    color: ${color};
  `}

  i {
    font-size: 24px;
    margin-bottom: 6px;
  }
`

export default PropertyType
