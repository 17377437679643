import HomeQuickMenuItem from './HomeQuickMenuItem'
import { ReactComponent as DonghoIconActive } from 'assets/icon/note/menu/dongho-active.svg'
import DonghoQuickLinkContainer from 'containers/notev2/DonghoQuickLinkContainer'

const HomeDonghoLink = () => {
  return (
    <DonghoQuickLinkContainer>
      {({ onClick }) => (
        <HomeQuickMenuItem
          icon={<DonghoIconActive />}
          onClick={onClick}
          newBadge
        >
          동호관리
        </HomeQuickMenuItem>
      )}
    </DonghoQuickLinkContainer>
  )
}

export default HomeDonghoLink
