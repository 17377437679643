import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { completeAdListing } from 'lib/api/notev2/adSubmit'
import { useSelector } from 'react-redux'
import { Confirm, Modal } from 'components/design'
import CompleteArgs from '../../CompleteArgs'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import NoteInput from 'components/jmapnotev2/elements/NoteInput'
import moment from 'moment'

/**
 * 광고 목록 버튼
 * 거래완료
 * @param adSrl
 * @param callbackFn 삭제 후 목록 재조회
 * @param setUpdatedAdSrl 재조회를 위해 저장하는 ad_srl
 * @param forForm 폼에서 사용하는지 여부
 * @param forNote 매물 3뎁스에서 사용하는지 여부
 * @param type 동일주소매물 or 신고매물
 * @param contractType 거래유형
 */
const CompleteButton = ({
  adSrl,
  callbackFn,
  setUpdatedAdSrl,
  forForm,
  forNote,
  expand = false,
  type,
  contractType,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const [contractDate, setContractDate] = useState(null)
  const [price1, setPrice1] = useState(null)
  const [price2, setPrice2] = useState(null)

  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState(false)

  const [onRequestComplete, , dataComplete] = useAxios(
    completeAdListing,
    {
      resetError: true,
    },
  ) // 거래완료

  /**
   * 동일주소매물, 신고매물일 때 알림 내용
   */
  const getConfirmText = () => {
    const result = {
      동일주소매물: (
        <CompleteArgs
          contractType={contractType}
          contractDate={contractDate}
          setContractDate={setContractDate}
          price1={price1}
          setPrice1={setPrice1}
          price2={price2}
          setPrice2={setPrice2}
        />
      ),
      신고매물: `신고 사유가 거래완료일 때만 거래완료 처리해주세요. 48시간 이내 처리시 경고/패널티가 없습니다.`,
    }

    return result[type]
  }

  const onOk = () => {
    let sameParams = {}

    if (type == '동일주소매물') {
      if (!price1) {
        message.error('금액을 입력해주세요.')
        return
      }
      if (contractType == '월세' && !price2) {
        message.error('월세를 입력해주세요.')
        return
      }
      if (!contractDate) {
        message.error('거래일자를 입력해주세요.')
        return
      }

      if (contractType == '월세') {
        sameParams = {
          price1: price1.replaceAll(',', ''),
          price2: price2.replaceAll(',', ''),
          date: contractDate,
        }
      } else {
        sameParams = {
          price1: price1.replaceAll(',', ''),
          date: contractDate,
        }
      }
    }

    onRequestComplete({
      ad_srl: adSrl,
      plan_srl: planInfo.allinone_plan_srl,
      type: '거래완료',
      ...sameParams,
    })
    setUpdatedAdSrl && setUpdatedAdSrl(adSrl)
  }

  const onClickComplete = () => {
    setIsConfirmModalVisible(true)
  }

  useEffect(() => {
    if (!dataComplete) return
    if (dataComplete?.result?.errormsg) {
      message.error(
        dataComplete?.result?.errormsg || '요청이 실패하였습니다.',
      )
    } else if (dataComplete?.result == 'error') {
      message.error(
        dataComplete?.result?.errormsg ||
          '광고 전송에 필요한 항목 검증에 실패하였습니다.',
      )
      return
    } else if (dataComplete?.result?.result == 'success') {
      message.success('거래완료 처리되었습니다.')
      setIsConfirmModalVisible(false)
      callbackFn && callbackFn()
    } else {
      message.error('요청중 오류가 발생했습니다.')
      return
    }
  }, [dataComplete])

  return (
    <>
      <AdButton
        color={forForm ? 'black' : 'blue'}
        expand={expand}
        forForm={forForm}
        size={forForm ? 'xlarge' : 'small'}
        onClick={() => onClickComplete(adSrl)}
        forNote={forNote}
      >
        거래완료
      </AdButton>
      <Modal
        overflowVisible
        title={type ? '이 매물을 거래완료 처리할까요?' : '알림'}
        visible={isConfirmModalVisible}
        onOk={onOk}
        onCancel={() => setIsConfirmModalVisible(false)}
        okText="거래완료"
        cancelText="취소"
        okButtonProps={{ color: 'blue' }}
      >
        {type
          ? getConfirmText()
          : '이 매물을 거래완료 처리 하시겠습니까?'}
      </Modal>
    </>
  )
}

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 14px;
  background-color: ${theme.colors.gray[100]};
`

const BlockItem = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const BlockTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  flex-shrink: 0;
`

const BlockContent = styled.div``

const InputWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export default CompleteButton
