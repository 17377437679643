import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { moreViewBasicCss } from '../style/SummaryStyle'
import SummaryMultiHousingListItem from './SummaryMultiHousingListItem'
import useViewMore from 'lib/hooks/useViewMore'
import { CardMore } from 'components/design'
import CardFold from 'components/design/Card/CardFold'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const SummaryMultiHousingList = ({ data }) => {
  const isApt = useSelector((state) => state.summary.isApt)
  const {
    viewCnt,
    viewMore,
    isOverflow,
    setViewMore,
    updateViewOverFlow,
    onClickMore,
    onClickFold,
  } = useViewMore()

  useEffect(() => {
    if (!data) return
    setViewMore(data.length > 3)
  }, [data])

  useUpdateEffect(() => {
    updateViewOverFlow(data)
  }, [viewCnt])

  return (
    <>
      <Wrapper>
        <Header>
          <div>가격기준일</div>
          <div>{isApt ? '공동주택공시가격' : '개별주택가격'}</div>
        </Header>
        <List viewcnt={viewCnt}>
          {data.map((item, i) => {
            return (
              <SummaryMultiHousingListItem
                key={i}
                date={item.price_date}
                price={item.price}
              />
            )
          })}
        </List>
      </Wrapper>
      {viewMore && <CardMore onClick={onClickMore} />}
      {isOverflow && <CardFold onClick={onClickFold} />}
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  font-size: 13px;
  margin-top: 40px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 7px;
  border-bottom: 1px solid ${theme.colors.gray[100]};
  line-height: 180%;
  font-weight: 500;
  color: ${theme.colors.gray[600]};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  ${moreViewBasicCss}
`

export default SummaryMultiHousingList
