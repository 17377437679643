import { useContext } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import MapContext from 'contexts/map'
import MapSearchAddressId from './MapSearchAddressId'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'

const StyledMapDebug = styled.div`
  position: absolute;
  bottom: 40px;
  left: 100px;
  z-index: 1;

  width: 380px;
  padding: 0.5rem;

  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  user-select: text;

  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: saturate(180%) blur(5px);
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 1px, ${theme.shadow.card};
  border-radius: ${theme.borderRadius[2]};
`

const MapDebug = () => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const { state } = useContext(MapContext)
  const { viewState, clickPosition } = state

  return (
    <>
      {!isMobile && (
        <StyledMapDebug>
          {viewState.level && (
            <p>
              viewState level : {viewState.level}
              <br />
              {viewState.swLatlng.toString()}
              {viewState.neLatlng.toString()}
              <br />
              {viewState.isMeasuring ? '거리재기 중' : ''}
            </p>
          )}
          {clickPosition && clickPosition.centerpoint && (
            <>
              <div>
                <strong>clickPosition</strong>
              </div>
              <p>
                토지코드: {clickPosition.address_id}, 지목:{' '}
                {clickPosition.land_type} <br />
                {clickPosition?.centerpoint?.[0]},{' '}
                {clickPosition?.centerpoint?.[1]} <br />
                {clickPosition.address_name}
              </p>
            </>
          )}
          {clickPosition && (
            <>
              <p>
                e_land_price: {clickPosition.e_land_price},{' '}
                e_building_price: {clickPosition.e_building_price}
              </p>
              <p>
                version: {clickPosition.version}/{', '}land_ratio:
                {clickPosition.land_ratio}
              </p>
              {clickPosition?.estimate_price2 && (
                <p>estimate_price2: {clickPosition.estimate_price2}</p>
              )}
            </>
          )}
          {/* {isTablet && '테블릿pc'}
          {window.innerWidth} */}
          <MapSearchAddressId />
        </StyledMapDebug>
      )}
    </>
  )
}

export default MapDebug
