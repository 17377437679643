import { useSelector } from 'react-redux'
import useAxios from 'lib/hooks/useAxios'
import { getAptStat } from 'lib/api/summaryApi'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Empty,
} from 'components/design'
import { useContext, useEffect, useState } from 'react'
import SummaryPyUnitGraph from 'components/summary/SummaryPyUnit/SummaryPyUnitGraph'
import { Skeleton } from 'antd'
import MapContext from 'contexts/map'
import { getCustomPurpose, getPropertyTypeName } from 'lib/utils'

/**
 * 공동주택 평단가 비교
 *
 * * 렌더링 여부
 * * 공동주택: O (연립 다세대는 제외)
 * * 공동주택 아님 : X
 */
const SummaryPyUnitPriceContainer = ({ selectedArea }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)
  const [onRequest, loading, data] = useAxios(getAptStat)
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)

  const [chartData, setChartData] = useState([])
  const [sidoData, setSidoData] = useState(null)
  const [sigunguData, setSigunguData] = useState(null)

  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  useEffect(() => {
    if (!selectedArea || Object.keys(selectedArea).length == 0) return
    if (!currentAddressId) return
    onRequest({
      address_id: currentAddressId,
      supply_area_py: selectedArea.supply_area_py,
    })
  }, [selectedArea])

  useEffect(() => {
    if (!data || !data?.result) return

    setChartData(data.result.data)
    setSidoData({
      avg: data.result.sido_avg,
      name: data.result.sido_name,
    })
    setSigunguData({
      avg: data.result.sigungu_avg,
      name: data.result.sigungu_name,
    })
  }, [data])

  return (
    getCustomPurpose(summaryInfo?.building) == '아파트' && (
      <Card>
        <CardHeader>
          <CardTitle>
            {isSquareMeter
              ? `${(selectedArea?.supply_area_py * 3.305).toFixed(2)}㎡`
              : `${selectedArea?.supply_area_py}평`}
            대 아파트 평단가 비교
          </CardTitle>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Skeleton active />
          ) : chartData.length == 0 ? (
            <Empty>데이터가 없습니다</Empty>
          ) : (
            <SummaryPyUnitGraph
              data={chartData}
              sidoData={sidoData}
              sigunguData={sigunguData}
            />
          )}
        </CardBody>
      </Card>
    )
  )
}

export default SummaryPyUnitPriceContainer
