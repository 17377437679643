import Header from 'components/common/Header/Header'
import SEOHead from 'components/common/SEOHead'
import CalendarHeader from 'components/jmapnote/calendar/CalendarHeader'
import NoteCalendar from 'components/jmapnote/calendar/NoteCalendar'
import NoteCalendarHeader from 'components/jmapnote/calendar/NoteCalendarHeader'
import NoteCalendarSaveButton from 'components/jmapnote/calendar/NoteCalendarSaveButton'
import NoteCalendarScheduleDay from 'components/jmapnote/calendar/NoteCalendarScheduleDay'
import NoteHeader from 'components/jmapnote/noteBase/NoteHeader'
import NoteLayout from 'components/jmapnote/noteBase/NoteLayout'
import CalendarContainer from 'containers/note/calendar/CalendarContainer'
import { CalendarProvider } from 'contexts/calendarContext'
import useIsMobile from 'lib/hooks/useIsMobile'

const CalendarPage = () => {
  const isMobile = useIsMobile()

  return (
    <NoteLayout>
      <SEOHead />
      <CalendarProvider>
        {isMobile && <CalendarHeader />}
        <CalendarContainer>
          {({ currentDate, setCurrentDate, viewMode }) => {
            return (
              <>
                <NoteCalendarHeader
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                />
                <NoteCalendar
                  currentDate={currentDate}
                  viewMode={viewMode}
                />
                <NoteCalendarScheduleDay type="basic" />
                <NoteCalendarSaveButton
                  position="bottom"
                  currentDate={currentDate}
                />
              </>
            )
          }}
        </CalendarContainer>
      </CalendarProvider>
    </NoteLayout>
  )
}

export default CalendarPage
