import { useCallback, useState, useEffect } from 'react'
import useAxios from '../useAxios'
import { editContract } from 'lib/api/notev2/contractApi'
import { useSelector } from 'react-redux'

/**
 * 계약서 수정
 * 저장 시 모든 데이터가 필요하므로, 업데이트시 현재 데이터 + 수정 할 데이터를 합쳐서 전송함
 */
function useEditContract() {
  const [onRequest, , data] = useAxios(editContract)
  const [isError, setIsError] = useState(false)
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  /**
   * @param data 현재 데이터
   * @param updateData 업데이트할 데이터
   */
  const onEditContract = useCallback(({ data, updateData }) => {
    if (channelInfo?.member_type == 'readonly') return //읽기 권한 수정 불가
    //계약서 저장 api
    onRequest({
      channel_srl: data.channel_srl,
      contract_srl: data.contract_srl,
      note_srl: data.note_srl,
      form: Object.assign({}, data, updateData),
    })
  }, [])

  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      console.error('edit contract 에러')
      setIsError(true)
      return
    }
  }, [data])

  return [onEditContract, isError, data]
}

export default useEditContract
