import {
  HelpCardHeader,
  Title,
  Price,
  HelpIcon,
  InfoWrap,
  BuildingPriceInfo,
  Hr,
} from '../SummarySizeReview/SummarySizeReviewStyle'
import priceFormat from 'lib/utils/priceFormat'
import { Button, Card, CardHeader } from 'components/design'
import { CardBody } from 'components/design'
import theme from 'lib/styles/theme'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const SummaryNohuCost = ({
  cost,
  investment,
  setIsInfoHide,
  isMine,
  isRentalAll,
}) => {
  const history = useHistory()
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  //보증금
  const depositSum = isRentalAll
    ? investment.deposit_sum
    : investment.part_deposit_sum

  //월세수익
  const rentSum = isRentalAll
    ? investment.yearly_rent_sum
    : investment.part_yearly_rent_sum

  //실투자금: 자신의 건물이면 토지 매입비용은 제거
  const realInvestment =
    cost.extend_cost + (isMine ? 0 : cost.purchase_price) - depositSum

  //수익율
  const rateOfReturn =
    ((rentSum / realInvestment) * 100).toFixed(2) < 0
      ? 100 + '%'
      : `약 ${((rentSum / realInvestment) * 100).toFixed(2)}%`

  const gotoSizeReview = () => {
    //수익성분석 상가주택으로
    history.push({
      pathname: `/${currentAddressId}/sizeReview/0`,
      state: { fromNohu: true },
    })
  }

  return (
    <Card>
      <CardHeader>
        <HelpCardHeader>
          <Title>
            지금 건축하시면
            <Price>{rateOfReturn}</Price>
            의<br />
            수익률을 예상할 수 있어요
          </Title>
          <HelpIcon onClick={() => setIsInfoHide(false)}>
            <i className="fas fa-info-circle"></i>
          </HelpIcon>
        </HelpCardHeader>
      </CardHeader>
      <CardBody>
        <InfoWrap background={theme.colors.gray[50]}>
          <BuildingPriceInfo>
            <div>예상 증축 비용</div>
            <div>
              <strong>{priceFormat(cost.extend_cost * 10000)}원</strong>
            </div>
          </BuildingPriceInfo>
          {!isMine && (
            <BuildingPriceInfo>
              <div>예상 토지 매입비용</div>
              <div>
                <strong>
                  {priceFormat(cost.purchase_price * 10000)}원
                </strong>
              </div>
            </BuildingPriceInfo>
          )}
          <BuildingPriceInfo>
            {/* 전체 임대, 증축 부위만 임대에 따라 값이 바뀜 */}
            <div>보증금</div>
            <div>
              <strong>-{priceFormat(depositSum * 10000)}원</strong>
            </div>
          </BuildingPriceInfo>
          <Hr />
          <BuildingPriceInfo>
            <div>실투자금</div>
            <div>
              <strong>
                {realInvestment < 0
                  ? '없음'
                  : priceFormat(realInvestment * 10000) + '원'}
              </strong>
            </div>
          </BuildingPriceInfo>
          <BuildingPriceInfo>
            {/* 전체 임대, 증축 부위만 임대에 따라 값이 바뀜 */}
            <div>월세수익</div>
            <div>
              <strong>{priceFormat(rentSum * 10000)}원</strong>
            </div>
          </BuildingPriceInfo>
          <Hr />
          <BuildingPriceInfo>
            <div>연 수익율</div>
            <div>
              <strong>{rateOfReturn}</strong>
            </div>
          </BuildingPriceInfo>
        </InfoWrap>
        <Description>
          <i className="fas fa-info-circle"></i> 연수익율 = 월세수익 /
          실투자금
        </Description>
        <Button color="gray" onClick={() => gotoSizeReview()} expand>
          신축 수익률과 비교하기
        </Button>
      </CardBody>
    </Card>
  )
}

const Description = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 13px;
  margin-bottom: 8px;

  i {
    color: ${theme.colors.gray[400]};
  }
`
export default SummaryNohuCost
