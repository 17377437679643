import { Checkbox } from 'components/design'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  InnerTableContent,
  InnerTableBlock,
  innerSubHeaderWidth,
  StyledTable,
  SkipCover,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import {
  getOnlyRemoveCommaNumber,
  handleFocusNumber,
} from 'lib/utils/notev2/util'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsBasic7 = ({ onUpdate }) => {
  const { watch } = useFormContext()

  const total_price = watch('total_price')
  const d_descript_type = watch('d_descript_type')
  const d_estimated_amount = watch('d_estimated_amount')
  const d_unit_price = watch('d_unit_price')
  const d_building_price = watch('d_building_price')
  const d_property_type = watch('d_property_type')

  const isRent = useMemo(
    () => d_property_type.includes('임대'),
    [d_property_type],
  )

  const [isEstimatedAmountSkip, setIsEstimatedAmountSkip] =
    useState(false)
  const [isUnitPriceSkip, setIsUnitPriceSkip] = useState(false)
  const [isBuildingPriceSkip, setIsBuildingPriceSkip] = useState(false)

  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑧'
    } else {
      return '⑥'
    }
  }

  useUpdateEffect(() => {
    if (!isRent) {
      setIsEstimatedAmountSkip(false)
      setIsUnitPriceSkip(false)
      setIsBuildingPriceSkip(false)
    }
  }, [isRent])

  useEffect(() => {
    if (!d_estimated_amount && total_price) {
      onUpdate('d_estimated_amount', total_price)
    }
    if (d_estimated_amount == '임대차생략') {
      setIsEstimatedAmountSkip(true)
    }
  }, [d_estimated_amount])

  useEffect(() => {
    if (d_unit_price == '임대차생략') {
      setIsUnitPriceSkip(true)
    }
  }, [d_unit_price])

  useEffect(() => {
    if (d_building_price == '임대차생략') {
      setIsBuildingPriceSkip(true)
    }
  }, [d_building_price])

  return (
    <StyledTable>
      <Row height={37} rowspan={2} noborderbottom>
        <TableHeader width={innerSubHeaderWidth}>
          {getNumber()} 거래예정금액 등
        </TableHeader>
        <RowSpanInner
          rowspanheightpercent={44}
          rowspan={2}
          minuswidth={innerSubHeaderWidth}
        >
          <InnerTableBlock widthpercent={100} noborderbottom>
            <TableSubHeader width={innerSubHeaderWidth}>
              거래예정금액
            </TableSubHeader>
            <InnerTableContent
              checkboxes
              headerwidth={innerSubHeaderWidth}
            >
              {isRent && isEstimatedAmountSkip ? (
                <SkipCover width={220}>임대차생략</SkipCover>
              ) : (
                <ColorInput
                  width={220}
                  defaultValue={
                    d_estimated_amount > 0
                      ? d_estimated_amount
                      : total_price > 0
                      ? total_price
                      : null
                  }
                  onClick={handleFocusNumber}
                  onBlur={(e) =>
                    onUpdate(
                      'd_estimated_amount',
                      getOnlyRemoveCommaNumber(e.target.value),
                    )
                  }
                  append="원"
                  fullWidth
                />
              )}
              {isRent && (
                <StyledCheckbox
                  color="blue"
                  checked={isEstimatedAmountSkip}
                  onChange={(e) => {
                    if (isEstimatedAmountSkip) {
                      onUpdate('d_estimated_amount', '')
                    } else {
                      onUpdate('d_estimated_amount', '임대차생략')
                    }
                    setIsEstimatedAmountSkip((prev) => !prev)
                  }}
                >
                  임대차생략
                </StyledCheckbox>
              )}
            </InnerTableContent>
          </InnerTableBlock>
        </RowSpanInner>
        <RowSpanInner
          rowspanheightpercent={56}
          rowspan={2}
          minuswidth={innerSubHeaderWidth}
          noborderbottom
        >
          <InnerTableBlock widthpercent={50} noborderbottom>
            <TableSubHeader width={innerSubHeaderWidth}>
              개별공시지가
              <br />
              &#40;㎡당&#41;
            </TableSubHeader>
            <InnerTableContent
              checkboxes
              headerwidth={innerSubHeaderWidth}
            >
              {isRent && isUnitPriceSkip ? (
                <SkipCover width={220}>임대차생략</SkipCover>
              ) : (
                <ColorInput
                  defaultValue={d_unit_price}
                  onClick={handleFocusNumber}
                  append="원"
                  onBlur={(e) =>
                    onUpdate(
                      'd_unit_price',
                      getOnlyRemoveCommaNumber(e.target.value),
                    )
                  }
                  fullWidth
                />
              )}
              {isRent && (
                <StyledCheckbox
                  color="blue"
                  checked={isUnitPriceSkip}
                  onChange={(e) => {
                    if (isUnitPriceSkip) {
                      onUpdate('d_unit_price', '')
                    } else {
                      onUpdate('d_unit_price', '임대차생략')
                    }
                    setIsUnitPriceSkip((prev) => !prev)
                  }}
                >
                  임대차생략
                </StyledCheckbox>
              )}
            </InnerTableContent>
          </InnerTableBlock>
          <InnerTableBlock
            widthpercent={50}
            noborderbottom
            noborderright
          >
            <TableSubHeader width={innerSubHeaderWidth}>
              건물&#40;주택&#41;
              <br />
              공시가격
            </TableSubHeader>
            <InnerTableContent
              checkboxes
              headerwidth={innerSubHeaderWidth}
            >
              {isRent && isBuildingPriceSkip ? (
                <SkipCover width={220}>임대차생략</SkipCover>
              ) : (
                <ColorInput
                  defaultValue={
                    d_building_price > 0 ? d_building_price : null
                  }
                  onClick={handleFocusNumber}
                  append="원"
                  onBlur={(e) =>
                    onUpdate(
                      'd_building_price',
                      getOnlyRemoveCommaNumber(e.target.value),
                    )
                  }
                  fullWidth
                />
              )}
              {isRent && (
                <StyledCheckbox
                  color="blue"
                  checked={isBuildingPriceSkip}
                  onChange={(e) => {
                    if (isBuildingPriceSkip) {
                      onUpdate('d_building_price', '')
                    } else {
                      onUpdate('d_building_price', '임대차생략')
                    }
                    setIsBuildingPriceSkip((prev) => !prev)
                  }}
                >
                  임대차생략
                </StyledCheckbox>
              )}
            </InnerTableContent>
          </InnerTableBlock>
        </RowSpanInner>
      </Row>
    </StyledTable>
  )
}

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px;
`

export default NoteDocsBasic7
