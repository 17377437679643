import { useEffect, useState, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import MapContext from 'contexts/map'
import { setIsCombination } from 'modules/summary'
import { getIsServiceSizeReview } from 'lib/utils/summary'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { getAddress } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import {
  SegmentItem,
  CardHeader,
  CardTitle,
  CardBody,
  Segment,
  Toggle,
  Modal,
  Card,
  Empty,
  Button,
} from 'components/design'
import SummarySizeReviewPolygon, {
  setCombinationStandardPolygon,
} from 'components/summary/SummarySizeReview/map/SummarySizeReviewPolygon'
import SummarySizeReviewMapClickContainer from 'components/summary/SummarySizeReview/map/SummarySizeReviewMapClickContainer'
import SummarySizeReviewButton from 'components/summary/SummarySizeReview/SummarySizeReviewButton'
import { CustomUl } from 'components/summary/SummarySizeReview/SummarySizeReviewStyle'
import { Spin, Tooltip, notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import DagaguImg from 'assets/images/sizereview-dagagu.png'
import JumpoImg from 'assets/images/sizereview-jumpo.png'
import SanggaImg from 'assets/images/sizereview-sangga.png'

/**
 * 수익성 분석
 *
 * * 렌더링 여부
 * * 공동주택: X
 * * 공동주택 아님 : O
 */
const SummarySizeReviewContainer = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { kakao } = window
  const { state, actions } = useContext(MapContext)

  const [activedTab, setActivedTab] = useState(
    Number(params?.filterId) || 1,
  ) // 0: 다가구주택, 1: 상가주택, 2:상가

  const { land } = useSelector((state) => state.summary.summaryInfo)
  const { building } = useSelector((state) => state.summary.summaryInfo)
  const centerPoint = useSelector((state) => state.summary.centerPoint)
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  // 공동주택
  const isApt = useSelector((state) => state.summary.isApt)
  const isCallSizeReview = useSelector(
    (state) => state.summary.isCallSizeReview,
  )

  const isCombination = useSelector(
    (state) => state.summary.isCombination,
  )

  // 수익성 분석 안내 문구창
  const [isInfoHide, setIsInfoHide] = useState(true)
  const [isSizeReview, setIsSizeReview] = useState(false)

  const [notSupportMsg, setNotSupportMsg] = useState('')

  const [isCombinationMapHide, setIsCombinationMapHide] = useState(true)
  const [sizeReviewMap, setSizeReviewMap] = useState() // 지도 param용
  const [isLoadingMap, setIsLoadingMap] = useState(false) // 주변 유사거래 지도 로딩 완료 여부
  const mapSizeReview = useRef(null) // 지도 target

  const [onRequest, , data, , ,] = useAxios(getAddress)

  const tabs = ['다가구주택', '상가주택', '상가']
  const images = [DagaguImg, JumpoImg, SanggaImg]

  useEffect(() => {
    const { sido, land_area, zone_use1, zone_use2 } = land

    setIsSizeReview(
      getIsServiceSizeReview({
        buildingInfo: building,
        sido: sido,
        land_area: land_area,
        zone: [zone_use1, zone_use2],
      }),
    )
  }, [])

  useUpdateEffect(() => {
    !isCombinationMapHide && setMapItems()
  }, [isCombinationMapHide])

  const setMapItems = async () => {
    await handleSizeReviewMap()
    onRequest(
      {
        address_id: currentAddressId,
      },
      null,
      false,
    )
  }

  useUpdateEffect(() => {
    if (!data || !data?.result || data?.message !== 'success') return

    actions.setCombinationPolygons([])
    actions.setCombinationStandardPolygons([])
    setCombinationStandardPolygon(data?.result, kakao, state, actions)
  }, [data])

  const handleSizeReviewMap = async () => {
    await kakao.maps.load(() => {
      let center = new kakao.maps.LatLng(
        centerPoint?.coordinates?.[0],
        centerPoint?.coordinates?.[1],
      )

      let el = mapSizeReview.current
      if (!el) return

      let mapData = new kakao.maps.Map(el, {
        center: center,
        level: 1,
      })

      mapData.setCursor('pointer')
      mapData.setDraggable(false)
      mapData.setZoomable(false)

      setSizeReviewMap(mapData)

      setIsLoadingMap(true)
    })
  }

  useUpdateEffect(() => {
    history.push(`/${params?.id}`)
    isCombination && handelActiveCombination()
  }, [isCombination])

  const handelActiveCombination = () => {
    setIsCombinationMapHide(false)
    actions.setCombinationPolygons([])
    actions.setCombinationStandardPolygons([])
  }

  const onClickSizeReview = () => {
    if (state.combinationPolygons.length < 1) {
      notification.warning({
        message: '합필할 토지를 선택 해 주세요',
      })
      return
    }

    history.push(`/${params?.id}/sizeReview/${activedTab}`)
    setIsCombinationMapHide(true)
  }

  return (
    !isApt &&
    (isSizeReview.isService ? (
      <>
        <Card>
          <CardHeader>
            <CardTitle>수익성 분석</CardTitle>
            <Toggle
              disabled={
                !isCallSizeReview &&
                params?.external &&
                params?.external === 'sizeReview'
              }
              checked={isCombination}
              onChange={() => {
                dispatch(setIsCombination(!isCombination))
              }}
            >
              합필
            </Toggle>
          </CardHeader>
          <CardBody>
            <SegmentWrap
              disabled={
                !isCallSizeReview &&
                params?.external &&
                params?.external === 'sizeReview'
              }
            >
              <SelectBlockWrapper>
                {tabs.map((item, i) => (
                  <SelectBlock
                    key={i}
                    active={activedTab === i}
                    onClick={() => setActivedTab(i)}
                  >
                    <img src={images[i]} />
                    <span> {item}</span>
                  </SelectBlock>
                ))}
              </SelectBlockWrapper>
            </SegmentWrap>
            <SummarySizeReviewButton buildingType={activedTab} />
          </CardBody>
        </Card>
        <Modal
          customSize={true}
          width={650}
          title={
            <ModalTitle>
              토지 합필
              <Tooltip title="지도에서 합필할 토지를 선택 해 주세요.">
                <i className="fas fa-info-circle"></i>
              </Tooltip>
            </ModalTitle>
          }
          visible={!isCombinationMapHide}
          onCancel={() => {
            dispatch(setIsCombination(false))
            setIsCombinationMapHide(true)
            actions.setCombinationPolygons([])
            actions.setCombinationStandardPolygons([])
          }}
          footer={null}
        >
          <MapWrap>
            <MapContent id="sizeReviewMap" ref={mapSizeReview}>
              {sizeReviewMap && (
                <SummarySizeReviewPolygon
                  id="sizeReviewMapPolygons"
                  target={mapSizeReview}
                  map={sizeReviewMap}
                />
              )}
            </MapContent>
            {sizeReviewMap && (
              <SummarySizeReviewMapClickContainer
                map={sizeReviewMap}
              ></SummarySizeReviewMapClickContainer>
            )}

            {!isLoadingMap && (
              <SpinWrap>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        color: theme.colors.gray[500],
                        fontSize: '2rem',
                      }}
                      spin
                    />
                  }
                />
              </SpinWrap>
            )}
          </MapWrap>
          <Button
            type="button"
            size="xlarge"
            color="primary"
            onClick={onClickSizeReview}
            expand
          >
            신축 수익률 계산하기
          </Button>
        </Modal>
      </>
    ) : (
      <Card>
        <CardHeader>
          <CardTitle>수익성 분석</CardTitle>
          <HelpIcon onClick={() => setIsInfoHide(false)}>
            <i className="fas fa-info-circle"></i>
          </HelpIcon>
        </CardHeader>
        <CardBody>
          <Empty>
            서비스하는 토지가 아닙니다. <br />
            {isSizeReview.msg}
          </Empty>
          <Modal
            title="서비스 제공 토지"
            visible={!isInfoHide}
            onOk={() => setIsInfoHide(true)}
            onCancel={() => setIsInfoHide(true)}
            okText="확인"
            cancelText="취소"
            cancelButtonProps={{ style: { display: 'none' } }}
            footer={null}
          >
            <CustomUl>
              <li>
                현재 서비스를 제공하는 지역은 서울 및 수도권 지역,
                광역시, 세종특별자치시 입니다.
              </li>
              <li>
                토지 면적이 50㎡ 이상 1000㎡ 이하인 토지에만 서비스를
                제공하고 있습니다.
              </li>
              <li>
                토지의 용도명이 제1종일반주거지역, 제1종전용주거지역,
                제2종일반주거지역, 제2종전용주거지역, 제3종일반주거지역
                인 경우에만 서비스를 제공하고 있습니다.
              </li>
              <li>
                건물이 지어진 토지인 경우, 건물 준공년도가 20년 이상
                지난 경우에만 서비스를 제공하고 있습니다.
              </li>
            </CustomUl>
          </Modal>
        </CardBody>
      </Card>
    ))
  )
}

const SpinWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: ${theme.colors.gray[200]};
`

const SegmentWrap = styled.div`
  margin-bottom: 1rem;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`

const HelpIcon = styled.div`
  width: 15%;
  flex-direction: row-reverse;
  cursor: pointer;
  top: 6px;
  right: 0;
  display: flex;
  align-items: center;

  color: ${theme.colors.gray[500]};
  font-size: 1rem;

  & > i {
    color: ${theme.colors.gray[400]};
    margin-left: 0.25rem;
  }
`

const ModalTitle = styled.div`
  & > i {
    color: ${theme.colors.gray[400]};
    margin-left: 0.5rem;
  }
`

const MapWrap = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  height: 400px;
  overflow: hidden;
`

const MapContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 1px solid ${theme.colors.gray[300]};
  border-bottom: 1px solid ${theme.colors.gray[300]};

  div {
    cursor: pointer;
  }
`

const SelectBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const SelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  min-width: 110px;
  background-color: ${theme.colors.gray[50]};
  border-radius: 8px;
  padding: 12px;
  border: 2px solid transparent;

  ${({ active }) =>
    active &&
    `
    border: 2px solid ${theme.colors.primary[500]};
    background-color: ${theme.colors.base.white};
  `};

  &:hover {
    border: 2px solid ${theme.colors.primary[500]};
  }

  img {
    width: auto;
    height: 56px;
  }

  span {
    line-height: 180%;
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.gray[600]};
  }
`

export default SummarySizeReviewContainer
