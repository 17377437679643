import Container from 'components/base/Container/Container'
import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NotePaymentInaccessible = () => {
  const onClick = () => {
    location.href = '/map/home'
  }

  return (
    <Container noTopHeader white>
      <Wrapper>
        <StyledIcon className="fal fa-info-circle"></StyledIcon>
        <span>결제관리 메뉴는 pc에서 확인 가능합니다.</span>
        <Button color="black" onClick={onClick}>
          홈으로
        </Button>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
  padding-top: 60px;

  span {
    text-align: center;
  }

  button {
    margin-top: 10px;
  }
`

const StyledIcon = styled.i`
  color: ${theme.colors.gray[800]};
  font-size: 50px;
`

export default NotePaymentInaccessible
