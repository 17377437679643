import { Button, Modal } from 'components/design'
import FavoriteImgSrc from 'assets/images/note/dongho/banner_favorite.png'
import HoImgSrc from 'assets/images/note/dongho/banner_ho.png'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDonghoBannerContainer from 'containers/notev2/NoteDonghoBannerContainer'

const HomeDonghoBannerModal = ({ visible, onCancel }) => {
  return (
    <Modal
      hiddenHeader
      visible={visible}
      onCancel={onCancel}
      width={360}
      footer={null}
    >
      <Header>
        <CloseButton onClick={onCancel}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </CloseButton>
      </Header>
      <Wrapper>
        <div>
          <Title>
            아파트를 <span>즐겨찾기</span>하고
          </Title>
          <Img src={FavoriteImgSrc} />
        </div>
        <div>
          <Title>다양한 호실 정보를 확인하세요!</Title>
          <Img src={HoImgSrc} />
        </div>
      </Wrapper>
      <NoteDonghoBannerContainer>
        {({ onClick }) => (
          <StyledButton
            expand
            size="large"
            color="blue"
            onClick={onClick}
          >
            동호관리로 이동하기
          </StyledButton>
        )}
      </NoteDonghoBannerContainer>
    </Modal>
  )
}

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;

  span {
    color: ${theme.colors.blue[400]};
  }
`
const Img = styled.img`
  width: 100%;
  height: auto;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
  margin-top: -14px;
`

const CloseButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 2;

  color: $color-gray-700;
  font-size: 1rem;

  background-color: $color-gray-100;
  border-radius: 8px;

  transition: background-color 250ms;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-200;
  }

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  ${media.mediumS`
    width:24px;
    height:24px;
`}
`

const Header = styled.div`
  width: 100%;
  height: 50px;
  padding-top: 20px;
  text-align: right;
`

const StyledButton = styled(Button)`
  margin-top: 24px;
`

export default HomeDonghoBannerModal
