import React, { Suspense } from 'react'
import styled from 'styled-components'
import Spinner from 'components/common/Loading/Spinner'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Swipe from 'react-easy-swipe'
import NoteFilterWrapper from 'components/jmapnotev2/style/NoteFilterWrapper'
import {
  FilterScrollWrapper,
  MobileCover,
} from 'components/jmapnotev2/style/NoteStyle'
import NoteAdFilter from './NoteAdFilter'
import NoteLeftSideChannel from './NoteLeftSideChannel'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteAdAllInOneMemberSetting from './NoteAdAllInOneMemberSetting'

const NoteLeftSide = ({ onSwipeStart, onSwipeMove, onSwipeEnd }) => {
  const isMobile = useIsMobile()

  return (
    <>
      <Wrapper
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
      >
        <NoteFilterWrapper>
          <Scrollbars autoHide>
            <FilterScrollWrapper>
              {!isMobile && <NoteLeftSideChannel />}
              <Suspense
                fallback={
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                }
              >
                <NoteAdFilter />
              </Suspense>
              <NoteAdAllInOneMemberSetting />
            </FilterScrollWrapper>
          </Scrollbars>
        </NoteFilterWrapper>
        <MobileCover className="m-cover" />
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Swipe)`
  width: 100%;
  height: 100%;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
  text-align: center;
`
export default React.memo(NoteLeftSide)
