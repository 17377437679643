import { Modal } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 올인원 멤버 초대
 * 번호 검증 완료 후 최종 결제 창
 */
const NotePaymentPersonNumberConfirmModal = ({
  visible,
  onOk,
  onCancel,
  sum,
  sumPerson,
  perAmount,
}) => {
  return (
    <Modal
      title="아래와 같이 결제가 진행됩니다. "
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="결제하기"
      okButtonProps={{ color: 'blue' }}
      cancelText="취소"
    >
      <Block>
        <Row>
          <RowTitle>인당 초대 금액</RowTitle>
          <RowValue>{numberWithCommas(perAmount)}원</RowValue>
        </Row>
        <Row>
          <RowTitle>추가 멤버</RowTitle>
          <RowValue blue>{sumPerson}명</RowValue>
        </Row>
        <TotalPrice>
          <TotalPriceTitle>최종 결제 금액</TotalPriceTitle>
          <TotalPriceValue>{numberWithCommas(sum)}원</TotalPriceValue>
        </TotalPrice>
      </Block>
    </Modal>
  )
}

const Block = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
`

const RowTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
`

const RowValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;

  ${({ blue }) =>
    blue &&
    `
    color: ${theme.colors.blue[400]};
  `}
`

const TotalPrice = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 11px;
  padding-top: 12px;
  border-top: 1px dashed ${theme.colors.gray[300]};
`

const TotalPriceTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
`

const TotalPriceValue = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;
`
export default NotePaymentPersonNumberConfirmModal
