import { Button, Modal } from 'components/design'
import theme from 'lib/styles/theme'
import moment from 'moment'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 요금제 해지(환불 예정) confirm 모달
 * 결제일로부터 7일 초과 이거나 등기부등본 사용 내역이 있는 경우 = 환불 불가 BUT 다음 결제일까지 사용 가능
 * @returns
 */
const NotePaymentCancelScheduleConfirm = ({
  visible = false,
  onCancel,
  onOk,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  return (
    <Modal
      title="요금제를 해지하시겠어요?"
      visible={visible}
      width={450}
      hiddenClose
      footer={
        visible ? (
          <ButtonWrapper>
            <Button onClick={onCancel} color="gray">
              {planInfo?.payment_name} 계속 유지
            </Button>
            <Button onClick={onOk} color="danger">
              {planInfo?.payment_name} 요금제 해지
            </Button>
          </ButtonWrapper>
        ) : null
      }
    >
      {visible && (
        <>
          <Emphasis bold>
            지금 해지하시면 환불이 불가합니다. 환불 규정을 꼭 확인해
            주세요.
          </Emphasis>
          <br />
          <br />
          요금제를 해지하면{' '}
          {moment(planInfo.next_payment_date, 'YYYYMMDDHHmmss').format(
            'YYYY년 MM월 DD일',
          )}
          까지만 사용하실 수 있으며, 다음 결제일에 더 이상 요금이
          청구되지 않습니다.
          <br />
          <br />
          이용 기간이 만료되면, 남은 등기부등본은 사용이 제한됩니다.
          <br />
          <br />
          <Emphasis>정말 요금제를 해지하시겠어요?</Emphasis>
        </>
      )}
    </Modal>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 0 16px 24px 20px;
`

const Emphasis = styled.span`
  color: ${theme.colors.tint.danger};
  ${({ bold }) => bold && `font-weight: 700;`}
`

export default NotePaymentCancelScheduleConfirm
