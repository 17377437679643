import styled from 'styled-components'
import NoteDashboardAd from './NoteDashboardAd'
import NoteDashboardProfileContainer from 'containers/notev2/NoteDashboardProfileContainer'
import NoteDashboardQuickLinkContainer from 'containers/notev2/NoteDashboardQuickLinkContainer'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import theme, { media } from 'lib/styles/theme'
import NoteDashboardDeunggibu from './NoteDashboardDeunggibu'
import NoteDashboardPicture from './NoteDashboardPicture'
import NoteDashboardDongho from './NoteDashboardDongho'
import NoteDashboardCalendar from './NoteDashboardCalendar'
import NoteDashboardNoti from './NoteDashboardNoti'
import NoteDashboardAdStatus from './NoteDashboardAdStatus'
import NoteDashboardGuide from './NoteDashboardGuide'

const NoteDashboardContents = () => {
  return (
    <Wrapper>
      <TopWrapper>
        <NoteDashboardProfileContainer />
        <NoteDashboardAd />
      </TopWrapper>

      <NoteDashboardAdStatus />
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 576: 1, [theme.bp.large]: 2 }}
      >
        <Masonry
          className="note-dashboard-masonry"
          gutter="20px"
          style={{ width: '100%' }}
        >
          <NoteDashboardCalendar />
          <NoteDashboardGuide />
          <NoteDashboardDeunggibu />
          <>
            <NoteDashboardNoti />
            <NoteDashboardPicture />
            <NoteDashboardQuickLinkContainer />
          </>
        </Masonry>
      </ResponsiveMasonry>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-bottom: 150px;

  .note-dashboard-masonry {
    & > div {
      &:nth-child(1) {
        flex: 1.9 1 0% !important;

        ${media.smallPc` 
          flex: 1 1 0% !important;
        `}
      }
    }
  }
`

const TopWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;

  ${media.mediumM`
    flex-direction: column;
  `}
`

export default NoteDashboardContents
