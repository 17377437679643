import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NoteDonghoNoSupport = () => {
  return (
    <Wrapper>
      <Title>
        실거래가 이력은
        <br />
        <PlusDanji>플러스 단지</PlusDanji>에 한해 제공 됩니다.
      </Title>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 18px 12px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  background: ${theme.colors.base.white};
  text-align: center;
`

const PlusDanji = styled.span`
  padding: 3px 4px;
  margin-right: 2px;
  background-color: ${theme.colors.blue[50]};
  color: ${theme.colors.blue[500]};
  border-radius: 3px;
`

const Title = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
`

const Desc = styled.div`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  color: #c3c9d0;
`

export default NoteDonghoNoSupport
