import { getAdFilters } from 'lib/api/notev2/adList'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { useDispatch } from 'react-redux'
import {
  setSelectedStatus,
  setStatusDatas,
  setUpdateStatusFromList,
} from 'modules/noteAd'
import { notification } from 'antd'
import { Button } from 'components/design'
import { ReactComponent as ReportActiveIcon } from 'assets/icon/note/ad/filters/active/report.svg'
import NoteAdStatusNoti from 'components/jmapnotev2/NoteAd/NoteAdStatusNoti'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 광고 상태 container
 * 동일주소거래, 신고 매물 있을 시 알려줌
 */
const NoteAdStatusContainer = ({ children }) => {
  const [onRequestStatus, , dataStatus, errorStatus] =
    useAxios(getAdFilters)
  const location = useLocation()
  const [notiArr, setNotiArr] = useState(0)

  const dispatch = useDispatch()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const planInfo = useSelector((state) => state.auth.planInfo)
  const updateStatusFromList = useSelector(
    (state) => state.noteAd.status.updateStatusFromList,
  ) //status update flag

  const authModalVisible = useSelector(
    (state) => state.noteAd.authModalVisible,
  ) //본인인증 모달

  const search = () => {
    onRequestStatus({
      plan_srl: planInfo.allinone_plan_srl,
    })
  }

  useEffect(() => {
    //필터 조회
    if (location?.state?.activeStatus) {
      dispatch(setSelectedStatus(location?.state?.activeStatus))
    }

    search()

    return () => {
      //필터 초기화
      dispatch(setSelectedStatus(null))
    }
  }, [])

  useEffect(() => {
    if (!dataStatus) return
    if (!dataStatus?.result) {
      dispatch(setStatusDatas(null))
      return
    }

    //ant-notification-notice-content

    //동일 주소 매물 noti
    if (dataStatus.result['동일주소매물'] > 0) {
      // notification.info({
      //   className: 'note-ad-status-notification',
      //   style: {
      //     display: 'flex',
      //     width: '800px',
      //     maxWidth: '80vw',
      //   },
      //   duration: '9999999999',
      //   message: `알림`,
      //   closeIcon: null,
      //   icon: <ReportActiveIcon width={20} height={20} />,
      //   description: (
      //     <>
      //       <Button>ㅇㅇ</Button>
      //     </>
      //   ),
      //   placement: 'top',
      // })
    }

    //신고 매물 noti
    if (dataStatus.result['신고매물'] > 0) {
    }

    const arr = [
      dataStatus.result['동일주소매물'] > 0 ? '동일주소매물' : null,
      dataStatus.result['신고매물'] > 0 ? '신고매물' : null,
    ]
    setNotiArr(arr.filter((item) => item))

    dispatch(setStatusDatas(dataStatus.result))
    dispatch(setUpdateStatusFromList(false))
  }, [dataStatus])

  useUpdateEffect(() => {
    if (!updateStatusFromList) return
    search()
  }, [updateStatusFromList])

  useEffect(() => {
    if (!errorStatus) return
  }, [errorStatus])

  /**
   * 본인인증 후 callback
   */
  useUpdateEffect(() => {
    if (authModalVisible || !loginInfo?.validated) return
    search()
  }, [authModalVisible, loginInfo?.validated])

  return children({
    noti: <NoteAdStatusNoti data={notiArr} />,
  })
}

export default NoteAdStatusContainer
