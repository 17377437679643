import { useState } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

import { Button } from 'components/design'

import SettingsChannelModify from './SettingsChannelModify'
import { useSelector } from 'react-redux'
import useChannelPermission from 'containers/note/channel/hooks/useChannelPermission'

const StyledHeadWrap = styled.div`
  padding: 1.25rem;

  ${media.mediumS`
    padding-top: 0
  `}
`
const StyledHead = styled.div`
  display: flex;
  padding: 1rem 1.25rem 1rem 1rem;
  background-color: ${theme.colors.gray[50]};
  border-radius: ${theme.borderRadius[1]};
`
const Body = styled.div`
  flex: 1;
  padding-right: 1.25rem;
`

const ChannelName = styled.h1`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 0.5rem;
`
const ChannelSrlText = styled.span`
  color: ${theme.colors.gray[600]};
  font-weight: 500;
`
const Description = styled.p`
  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
`
const ButtonWrap = styled.div`
  margin-top: 1rem;
`

const SettingsHead = () => {
  // const channelInfo = useSelector((state) => state.channel.channelInfo)
  const permission = useChannelPermission()

  const selectedChannelInfo = useSelector(
    (state) => state.channel.selectedChannelInfo,
  )
  const [visible, setVisible] = useState(false)

  if (!selectedChannelInfo) return <></>

  return (
    <>
      <StyledHeadWrap>
        <StyledHead>
          <Body>
            <ChannelName>{selectedChannelInfo?.name}</ChannelName>
            <Description>
              {selectedChannelInfo?.description ||
                '채널 설명이 없습니다.'}
            </Description>
          </Body>

          {permission === 'owner' && (
            <ButtonWrap>
              <Button
                color="black"
                size="small"
                onClick={() => setVisible(true)}
              >
                수정
              </Button>
            </ButtonWrap>
          )}
        </StyledHead>
      </StyledHeadWrap>

      {permission === 'owner' && (
        <SettingsChannelModify
          visible={visible}
          setVisible={setVisible}
          selectedChannelInfo={selectedChannelInfo}
        />
      )}
    </>
  )
}

export default SettingsHead
