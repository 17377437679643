import { InfoBlock } from './style/NoteDocsStyle'
import styled from 'styled-components'
import { Button } from 'components/design'
import moment from 'moment'
import { useMemo } from 'react'

const NoteDeunggiExpireNoti = ({ data, goToDeunggibuIssuance }) => {
  /**
   * 등기부 발급 후 1일 지나면 유효 경고 표시
   */
  const isShowExpiredWarn = useMemo(() => {
    if (!data || !data.regdate) {
      return false
    }

    const diff = Math.abs(
      moment(data.regdate, 'YYYYMMDDHHmmss').diff(moment(), 'days'),
    )

    return diff > 1 ? true : false
  }, [data])

  return (
    isShowExpiredWarn && (
      <>
        <InfoBlock color="red">
          기존에 발급 받은 등기부등본은 발급 당시의 최신 등기부등본으로
          현재와 다를 수 있습니다. 최신 정보를 위해서는 재발급이 필요할
          수 있습니다.
        </InfoBlock>
        <ButtonWrapper>
          <Button color="blue" plain onClick={goToDeunggibuIssuance}>
            등기부등본 발급하기
          </Button>
        </ButtonWrapper>
      </>
    )
  )
}

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 20px;

  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

export default NoteDeunggiExpireNoti
