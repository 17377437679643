import { Tooltip } from 'antd'
import { NavLink } from 'react-router-dom'
import BaseNavItem from './BaseNavItem'
import FavoriteLinkContainer from 'containers/favorite/FavoriteLinkContainer'

const BaseNavFavorite = () => {
  return (
    <FavoriteLinkContainer>
      {({ active, onClick }) => (
        <Tooltip title="즐겨찾기">
          <BaseNavItem
            as={NavLink}
            to="/favorite"
            icon={<i className="fal fa-star"></i>}
            text="즐겨찾기"
          />
        </Tooltip>
      )}
    </FavoriteLinkContainer>
  )
}

export default BaseNavFavorite
