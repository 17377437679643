import LandingAd from 'components/landing/LandingAd'
import LandingAtOnce from 'components/landing/LandingAtOnce'
import LandingBanner from 'components/landing/LandingBanner'
import LandingBigText from 'components/landing/LandingBigText'
import LandingBigText2 from 'components/landing/LandingBigText2'
import LandingCharacter1 from 'components/landing/LandingCharacter1'
import LandingCharacter2 from 'components/landing/LandingCharacter2'
import LandingContract from 'components/landing/LandingContract'
import LandingDongho from 'components/landing/LandingDongho'
import LandingFooter from 'components/landing/LandingFooter'
import LandingHead from 'components/landing/LandingHead'
import LandingMainFooter from 'components/landing/LandingMainFooter'
import LandingPrice from 'components/landing/LandingPrice'
import LandingStep from 'components/landing/LandingStep'
import LoginForm from 'components/login/LoginForm'
import LandingContainer from 'containers/landing/LandingContainer'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const Landing = () => {
  return (
    <LandingContainer>
      {({
        wrapperRef,
        priceRef,
        onClickStart,
        isVisibleLogin,
        setIsVisibleLogin,
        customAccount,
        setCustomAccount,
        loginInfo,
      }) => (
        <Wrapper ref={wrapperRef}>
          <LandingHead
            onClickStart={onClickStart}
            ref={{ priceRef, parentRef: wrapperRef }}
          />
          <LandingStep ref={wrapperRef} />
          <LandingBigText />
          <LandingAtOnce ref={wrapperRef} />
          <LandingCharacter1 />
          <LandingCharacter2 />
          <LandingContract />
          <LandingAd />
          <LandingDongho />
          <LandingBigText2 ref={wrapperRef} />
          <LandingPrice ref={priceRef} onClickStart={onClickStart} />
          {!loginInfo.member_srl && (
            <LandingBanner onClickStart={onClickStart} />
          )}
          <LandingFooter
            setCustomAccount={setCustomAccount}
            setIsVisibleLogin={setIsVisibleLogin}
          />
          <LandingMainFooter />
          {/* 로그인 컴포넌트 모달 */}
          <LoginForm
            visible={isVisibleLogin}
            redirectUrl={'/home'}
            onCancel={() => setIsVisibleLogin(false)}
            customAccount={customAccount}
          />
        </Wrapper>
      )}
    </LandingContainer>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: ${theme.colors.base.white};
  overflow: auto;
`

export default Landing
