import { AutoSizer } from 'react-virtualized'
import NotePictureSection from './NotePictureSection'
import { useContext, useEffect, useRef } from 'react'
import { VariableSizeList as List } from 'react-window'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Gallery } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import MapContext from 'contexts/map'
import {
  isDonghoAreaUse,
  isFloorAreaUse,
  isLandAreaUse,
} from 'lib/utils/notev2/note2Constants'
import priceFormat from 'lib/utils/priceFormat'
import Swipe from 'react-easy-swipe'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { message } from 'antd'

const NotePicture = ({
  data,
  onSwipeStart,
  onSwipeMove,
  onSwipeEnd,
}) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  // References
  const listRef = useRef({})
  const rowHeights = useRef({})
  const history = useHistory()

  function getRowHeight(index) {
    return rowHeights.current[index] + 8 || 86
  }

  function Row({ index, style, data: rowData }) {
    const rowRef = useRef({})

    useEffect(() => {
      if (Object.keys(listRef.current).length == 0) return

      if (rowRef.current) {
        setTimeout(() => {
          setRowHeight(index, rowRef.current?.clientHeight)
        }, 0)
      }
    }, [rowRef])

    return (
      <NotePictureSection
        ref={rowRef}
        style={style}
        data={data[rowData[index]]}
        i={index}
        date={rowData[index]}
      />
    )
  }

  function setRowHeight(index, size) {
    if (
      !listRef ||
      !listRef.current ||
      Object.keys(listRef.current).length == 0
    )
      return

    listRef.current.resetAfterIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size || 0 }
  }

  const getArea = ({
    property_type,
    total_floor_area,
    land_area,
    exclusive_area,
    contract_area,
    building_area,
    dong,
    ho,
  }) => {
    const unit = isSquareMeter ? '㎡' : '평'

    if (isDonghoAreaUse.includes(property_type)) {
      const formatExclusive = isSquareMeter
        ? parseFloat(exclusive_area)
          ? exclusive_area
          : ''
        : parseFloat((exclusive_area / 3.3057)?.toFixed(2)) || ''
      const formatContract = isSquareMeter
        ? parseFloat(contract_area)
        : parseFloat((contract_area / 3.3057)?.toFixed(2)) || ''

      return formatContract
        ? `${formatContract || ''}${formatContract ? unit : ''}/${
            formatExclusive || ''
          }${formatExclusive ? unit : ''}`
        : `전용 ${formatExclusive || ''}${formatExclusive ? unit : ''}`
    } else if (isFloorAreaUse.includes(property_type)) {
      if (
        (property_type == '다가구주택' ||
          property_type == '단독주택') &&
        (dong || ho)
      ) {
        const formatExclusive = isSquareMeter
          ? parseFloat(exclusive_area) || ''
          : parseFloat((exclusive_area / 3.3057)?.toFixed(2)) || ''
        const formatContract = isSquareMeter
          ? parseFloat(contract_area) || ''
          : parseFloat((contract_area / 3.3057)?.toFixed(2)) || ''

        return formatContract
          ? `${formatContract || ''}${formatContract ? unit : ''}/${
              formatExclusive || ''
            }${formatExclusive ? unit : ''}`
          : `전용 ${formatExclusive || ''}${
              formatExclusive ? unit : ''
            }`
      } else {
        const formatTotalFloor = isSquareMeter
          ? parseFloat(total_floor_area) || ''
          : parseFloat((total_floor_area / 3.3057)?.toFixed(2)) || ''
        const formatBuilding = isSquareMeter
          ? parseFloat(building_area) || ''
          : parseFloat((building_area / 3.3057)?.toFixed(2)) || ''

        return formatTotalFloor
          ? `${formatTotalFloor}${
              formatTotalFloor ? unit : ''
            }/${formatBuilding}${formatBuilding ? unit : ''}`
          : formatBuilding
          ? `건물 ${formatBuilding}${formatBuilding ? unit : ''}`
          : ''
      }
    } else if (isLandAreaUse.includes(property_type)) {
      const formatLand = isSquareMeter
        ? parseFloat(land_area) || ''
        : parseFloat((land_area / 3.3057)?.toFixed(2)) || ''
      const formatBuilding = isSquareMeter
        ? parseFloat(building_area) || ''
        : parseFloat((building_area / 3.3057)?.toFixed(2)) || ''

      if (property_type == '기타') {
        return `${formatLand}${
          formatLand ? unit : ''
        }/${formatBuilding}${formatBuilding ? unit : ''}`
      } else {
        return `${formatLand}${formatLand ? unit : ''}`
      }
    } else {
      const formatTotalFloor = isSquareMeter
        ? parseFloat(total_floor_area) || ''
        : parseFloat((total_floor_area / 3.3057)?.toFixed(2)) || ''
      return `${formatTotalFloor}${formatTotalFloor ? unit : ''}`
    }
  }

  const uiElements = [
    {
      name: 'sidebar',
      order: 9,
      //isButton: false,
      appendTo: document.getElementById('fixed-layer'),
      onInit: (el, pswpInstance) => {
        const datas = Object.values(data).flat()

        pswpInstance.on('change', () => {
          const currIndex = pswpInstance.currIndex
          const info = datas[currIndex]

          const infoWrapper = document.createElement('div')
          const subTitle = document.createElement('div')
          //subTitle.innerText = info.address_doro
          const area = getArea({
            property_type: info.property_type,
            total_floor_area: info.total_floor_area,
            land_area: info.land_area,
            exclusive_area: info.exclusive_area,
            contract_area: info.contract_area,
            building_area: info.building_area,
            dong: info.dong,
            ho: info.ho,
          })

          const price = `${
            info.contract_type?.includes('매매')
              ? `매매: ${priceFormat(info.sale_price * 10000)}${
                  info.contract_type?.includes('전세') ||
                  info.contract_type?.includes('월세')
                    ? ' · '
                    : ''
                }`
              : ''
          }
          ${
            info.contract_type?.includes('전세')
              ? `전세: ${priceFormat(info.jeonse_deposit * 10000)}${
                  info.contract_type?.includes('월세') ? ' · ' : ''
                }`
              : ''
          }
          ${
            info.contract_type?.includes('월세')
              ? `보증금: ${priceFormat(
                  info.deposit_value * 10000,
                )}/월세: ${priceFormat(info.rent_value)}`
              : ''
          }`

          subTitle.innerHTML = `${
            info.property_type ? `${info.property_type} · ` : ''
          }${area ? `${area}${price ? ' · ' : ''}` : ''}${
            price ? `${price}` : ''
          }`
          subTitle.className = 'pswp__sidebar-subtitle'
          infoWrapper.appendChild(subTitle)

          const title = document.createElement('div')
          title.innerText = `${
            info?.address_srl
              ? `${info.address_jibun}${
                  info.dong ? ` ${info.dong}` : ''
                }${info.ho ? ` ${info.ho}` : ''}`
              : info.extra_vars.invalid_addr_name
          }`
          title.className = 'pswp__sidebar-title'
          title.setAttribute('data-notesrl', info.note_srl)
          title.setAttribute('data-addresssrl', info.address_srl)
          title.setAttribute('data-channelsrl', info.channel_srl)

          infoWrapper.appendChild(title)

          const memo = document.createElement('div')
          memo.innerText = `${info.memo || ''}`
          memo.className = 'pswp__sidebar-memo'
          infoWrapper.appendChild(memo)

          el.innerHTML = infoWrapper.innerHTML

          setTimeout(() => {
            const elem = document.getElementsByClassName(
              'pswp__sidebar-title',
            )
            if (!elem) {
              message.error('매물을 찾을 수 없습니다.')
              return
            }

            const target = elem[0]

            const handleClick = function (e) {
              const note_srl = target.getAttribute('data-notesrl')
              const address_srl = target.getAttribute('data-addresssrl')
              const channel_srl = target.getAttribute('data-channelsrl')

              if (!note_srl || !channel_srl) {
                message.error('매물을 찾을 수 없습니다.')
                return
              }

              history.push(
                `/note/v2/notes/${channel_srl}/${address_srl}/${note_srl}`,
              )
            }
            target.addEventListener('click', handleClick, false)
          }, 0)
        })
      },
    },
  ]

  return (
    <Wrapper
      onSwipeStart={onSwipeStart}
      onSwipeMove={onSwipeMove}
      onSwipeEnd={onSwipeEnd}
    >
      <Gallery
        withCaption
        uiElements={uiElements}
        options={{
          history: false,
          barsSize: { top: 0, bottom: 0 },
          clickToCloseNonZoomable: false,
          closeOnScroll: false,
          closeOnVerticalDrag: false,
          captionEl: false,
          shareEl: false,
          closeEl: false,
          showHideAnimationType: 'fade',
          mainClass: 'pswp-note-picture',
        }}
      >
        <AutoSizer
          className="autosizer"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {({ height, width }) => (
            <StyledList
              className="List"
              itemCount={Object.keys(data).length}
              itemSize={getRowHeight}
              ref={listRef}
              itemData={Object.keys(data)}
              width={width}
              height={height}
            >
              {Row}
            </StyledList>
          )}
        </AutoSizer>
      </Gallery>
    </Wrapper>
  )
}

const Wrapper = styled(Swipe)`
  width: 100%;
  height: 100%;
`

const StyledList = styled(List)`
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${theme.colors.gray[300]};
    border: 3px solid transparent;
    min-height: 50px;
  }
`

export default NotePicture
