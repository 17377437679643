import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import axios from 'axios'
import { message, Table } from 'antd'
import { Button, Empty, Select } from 'components/design'
import styled from 'styled-components'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useFolders from 'pages/jmapnote/noteList/hooks/useFolders'
import { useHistory } from 'react-router'
import theme, { media } from 'lib/styles/theme'
import NoteUploadExcelAddress from './NoteUploadExcelAddress'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from 'components/common/Header/Header'
import HeaderBack from 'components/common/Header/HeaderBack'

const StyledFormContainer = styled.div`
  table th,
  td {
    width: 75px;
  }
  input {
    border: none;
    width: 100%;
    min-width: 50px;
  }
  .hide {
    display: none;
  }
  .invalid {
    border: 1px solid red;
    color: red;
  }
`

const StyledContainer = styled.div`
  padding: 1.25rem;
`

const StyledSelectLabel = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 500;
`
const StyledSelectWrap = styled.div`
  width: 200px;
  margin: 0.5rem 1rem 0.5rem 0;
`

const StyledInputGroup = styled.div`
  display: flex;
  align-items: center;
  ${({ notemode }) =>
    notemode == 'sheet' &&
    `
    justify-content: flex-end;
  `}
`

const NoteUploadExcel = ({ tableData, setTableData, onClose }) => {
  const history = useHistory()
  const location = useLocation()
  const { channelSrl } = useNoteMatch()
  const methods = useForm()
  const { register, handleSubmit, reset, setValue, watch } = methods
  const folderSrl = watch('folderSrl')
  const { _getFolders, loading, data, folders } = useFolders()
  const tableHeight = window.innerHeight - theme.base.headerHeight - 260

  const selectedChannelInfo = useSelector(
    (state) => state.channel.selectedChannelInfo,
  )

  // 채널일련번호: 카드/시트 모드 호환성 유지
  const [compatibleChannelSrl, setCompatibleChannelSrl] = useState(
    channelSrl || selectedChannelInfo?.channel_srl || '',
  )

  useEffect(() => {
    if (
      !channelSrl ||
      channelSrl === 'undefined' ||
      !selectedChannelInfo?.channel_srl
    )
      return
    _getFolders({
      channelSrl: channelSrl || selectedChannelInfo.channel_srl,
    })
  }, [_getFolders, channelSrl, selectedChannelInfo?.channel_srl])

  useEffect(() => {
    if (!selectedChannelInfo?.channel_srl) return
    _getFolders({
      channelSrl: selectedChannelInfo.channel_srl,
    })
  }, [selectedChannelInfo?.channel_srl])

  useEffect(() => {
    register('folderSrl')
  }, [register])

  useEffect(() => {
    if (!folders || folders.length == 0) return

    setValue('folderSrl', folders[0].folder_srl)
  }, [folders])

  const onSubmit = (data) => {
    if (!data) return
    if (!data.folderSrl) {
      message.error('폴더를 선택해 주세요.')
      return
    }
    if (!compatibleChannelSrl) {
      message.error('유효하지 않은 채널입니다.')
      return
    }

    const keys = Object.keys(data)
    // 단순히 row의 총 개수를 구하기 위함
    const rows = keys.filter((val) => val.startsWith('address'))

    // note들을 저장할 변수
    const notes = []

    // 하나의 온전한 note 만들기
    rows.map((val, i) => {
      // i번째 note의 key값들
      const noteKeys = keys.filter((val) => val.endsWith(`-${i + 1}`))
      // i번째 note의 value값들
      const note = noteKeys.map((val) => {
        let temp = {}
        temp[val.split('-')[0]] = data[val]
        return temp
      })

      // 배열에 객체 형태로 저장
      if (note.length > 0) {
        notes.push(Object.assign(...note))
      }
    })

    // API 요청
    axios
      .post('/jmapnote/api/note/autoCreate', {
        data: JSON.stringify({
          channelSrl: compatibleChannelSrl,
          folderSrl: data.folderSrl,
          notes: notes, //.filter((val) => val.address_id !== ''),
        }),
      })
      .then((resp) => {
        //console.log('resp: ', resp)
        if (resp.data.error != 0) {
          message.error(resp.data.message)
          return
        }

        if (!resp.data.result) return

        const results = resp.data.result
        results.map((result) => {
          if (result.error) {
            message.error(result.message)
          }
        })

        // 선택된 채널-폴더로 이동
        const notemode = localStorage.getItem('notemode')
        if (notemode == 'card') {
          history.push(`/note/${channelSrl}/${data.folderSrl}`)
        } else {
          onClose()
          //history.push(`/note/v2/notes/${compatibleChannelSrl}`)
        }
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
        message.error('서버 오류')
      })
    reset(data)
  }

  // 테이블 컬럼 동적 생성
  const getColumns1 = () => {
    return keyColumnList1.map((val) => {
      // 넓이 조정
      let width = 'auto'
      if (
        [
          'move_date',
          'building_name',
          'fee_include',
          'default_options',
          'room_options',
          //'memo',
          //'office_name',
        ].includes(val.name)
      ) {
        width = 120
      } else if (
        [
          'landlord_number',
          'tenant_number',
          //'memo',
          //'office_name',
        ].includes(val.name)
      ) {
        width = 140
      }

      return {
        title: val.title,
        dataIndex: val.name,
        key: val.name,
        width: width,
        render: (value, row, index) => {
          return (
            <>
              <div>{value}</div>
              <input
                type="hidden"
                defaultValue={value}
                name={`${val.name}-${index + 1}`}
                {...register(`${val.name}-${index + 1}`)}
              />
            </>
          )
        },
      }
    })
  }

  const getColumns2 = () => {
    return keyColumnList2.map((val) => {
      // 넓이 조정
      let width = 'auto'

      return {
        title: val.title,
        dataIndex: val.name,
        key: val.name,
        width: width,
        render: (value, row, index) => {
          return (
            <>
              <div>{value}</div>
              <input
                type="hidden"
                defaultValue={value}
                name={`${val.name}-${index + 1}`}
                {...register(`${val.name}-${index + 1}`)}
              />
            </>
          )
        },
      }
    })
  }

  const getColumns3 = () => {
    return keyColumnList3.map((val) => {
      // 넓이 조정
      let width = 'auto'
      if (['receipt_date', 'office_name'].includes(val.name)) {
        width = 120
      } else if ('memo' == val.name) {
        width = 250
      }

      return {
        title: val.title,
        dataIndex: val.name,
        key: val.name,
        width: width,
        render: (value, row, index) => {
          return (
            <>
              <div>{value}</div>
              <input
                type="hidden"
                defaultValue={value}
                name={`${val.name}-${index + 1}`}
                {...register(`${val.name}-${index + 1}`)}
              />
            </>
          )
        },
      }
    })
  }

  // 주소 컬럼 설정
  const customColumns = [
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      width: 220,
      render: (value, row, index) => {
        return (
          <NoteUploadExcelAddress
            value={value}
            row={row}
            index={index}
            tableData={tableData}
            setTableData={setTableData}
          />
        )
      },
    },
  ]

  //주소 ~ 세입자번호
  const keyColumnList1 = [
    // { title: '주소', key: 'A', name: 'address' },
    { title: '동', key: 'dong', name: 'dong' },
    { title: '호', key: 'ho', name: 'ho' },
    { title: '매물유형', key: 'property_type', name: 'property_type' },
    { title: '거래유형', key: 'contract_type', name: 'contract_type' },
    { title: '매매가', key: 'sale_price', name: 'sale_price' },
    { title: '보증금', key: 'deposit', name: 'deposit' },
    { title: '월세', key: 'rent', name: 'rent' },
    { title: '입주일', key: 'move_date', name: 'move_date' },
    { title: '건물명', key: 'building_name', name: 'building_name' },
    { title: '공급면적', key: 'contract_area', name: 'contract_area' },
    {
      title: '전용면적',
      key: 'exclusive_area',
      name: 'exclusive_area',
    },
    { title: '대지면적', key: 'land_area', name: 'land_area' },
    { title: '건축면적', key: 'building_area', name: 'building_area' },
    {
      title: '연면적',
      key: 'total_floor_area',
      name: 'total_floor_area',
    },
    { title: '대지권', key: 'daejikwon', name: 'daejikwon' },
    { title: '해당층수', key: 'floor_count', name: 'floor_count' },
    {
      title: '전체층수',
      key: 'total_floor_count',
      name: 'total_floor_count',
    },
    { title: '기타유형', key: 'floor_types', name: 'floor_types' },
    { title: '방향', key: 'direction', name: 'direction' },
    {
      title: '방향기준',
      key: 'direction_criteria',
      name: 'direction_criteria',
    },
    { title: '지목', key: 'land_type', name: 'land_type' },
    { title: '방개수', key: 'room_count', name: 'room_count' },
    {
      title: '욕실개수',
      key: 'bathroom_count',
      name: 'bathroom_count',
    },
    { title: '난방종류', key: 'heating_type', name: 'heating_type' },
    { title: '현관종류', key: 'door_type', name: 'door_type' },
    { title: '세대수', key: 'households', name: 'households' },
    { title: '주차대수', key: 'parking_count', name: 'parking_count' },
    { title: '관리비', key: 'fee', name: 'fee' },
    { title: '관리항목', key: 'fee_include', name: 'fee_include' },
    {
      title: '지원사항',
      key: 'default_options',
      name: 'default_options',
    },
    { title: '추가옵션', key: 'room_options', name: 'room_options' },
    // 연락처 정보 표시
    {
      title: '집주인이름',
      key: 'landlord_name',
      name: 'landlord_name',
    },
    {
      title: '집주인번호',
      key: 'landlord_number',
      name: 'landlord_number',
    },
    { title: '세입자이름', key: 'tenant_name', name: 'tenant_name' },
    {
      title: '세입자번호',
      key: 'tenant_number',
      name: 'tenant_number',
    },
  ]

  //금리 ~ 전세대출 가능여부
  const keyColumnList2 = [
    { title: '융자', key: 'loan', name: 'loan' },
    { title: '금리', key: 'interest_rate', name: 'interest_rate' },
    { title: '실투자액', key: 'investment', name: 'investment' },
    { title: '지분', key: 'contract_share', name: 'contract_share' },
    { title: '전세대출 가능여부', key: 'lending', name: 'lending' },
  ]

  //접수일 ~ 메모
  const keyColumnList3 = [
    { title: '접수일', key: 'receipt_date', name: 'receipt_date' },
    {
      title: '받은 공인중개사사무소',
      key: 'office_name',
      name: 'office_name',
    },
    {
      title: '현관 비밀번호',
      key: 'entrance_pwd',
      name: 'entrance_pwd',
    },
    { title: '세대 비밀번호', key: 'room_pwd', name: 'room_pwd' },
    { title: '메모', key: 'memo', name: 'memo' },
  ]

  // 기존 세입자 헤더 그룹핑
  const hideColumns = [
    {
      title: '기존 세입자',
      children: [
        {
          title: '거래유형',
          dataIndex: 'lessee_contract_type',
          key: 'lessee_contract_type',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`lessee_contract_type-${index + 1}`}
                  {...register(`lessee_contract_type-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '보증금',
          dataIndex: 'lessee_deposit',
          key: 'lessee_deposit',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`lessee_deposit-${index + 1}`}
                  {...register(`lessee_deposit-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '월세',
          dataIndex: 'lessee_rent',
          key: 'lessee_rent',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`lessee_rent-${index + 1}`}
                  {...register(`lessee_rent-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '계약 만기일',
          dataIndex: 'expire_date',
          key: 'expire_date',
          width: 100,
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`expire_date-${index + 1}`}
                  {...register(`expire_date-${index + 1}`)}
                />
              </>
            )
          },
        },
      ],
    },
  ]

  // 분양권 헤더 그룹핑
  const bunyangColumns = [
    {
      title: '분양권',
      children: [
        {
          title: '구분',
          dataIndex: 'presale_type',
          key: 'presale_type',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`presale_type-${index + 1}`}
                  {...register(`presale_type-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '타입',
          dataIndex: 'presale_unit_type',
          key: 'presale_unit_type',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`presale_unit_type-${index + 1}`}
                  {...register(`presale_unit_type-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '분양금액',
          dataIndex: 'presale_base_price',
          key: 'presale_base_price',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`presale_base_price-${index + 1}`}
                  {...register(`presale_base_price-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '옵션금액',
          dataIndex: 'presale_option_price',
          key: 'presale_option_price',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`presale_option_price-${index + 1}`}
                  {...register(`presale_option_price-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '납부한 금액',
          dataIndex: 'presale_paid',
          key: 'presale_paid',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`presale_paid-${index + 1}`}
                  {...register(`presale_paid-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '프리미엄',
          dataIndex: 'presale_premium',
          key: 'presale_premium',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`presale_premium-${index + 1}`}
                  {...register(`presale_premium-${index + 1}`)}
                />
              </>
            )
          },
        },
      ],
    },
  ]

  // 상가 헤더 그룹핑
  const sanggaColumns = [
    {
      title: '상가',
      children: [
        {
          title: '권리금',
          dataIndex: 'foregift',
          key: 'foregift',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`foregift-${index + 1}`}
                  {...register(`foregift-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '현재업종',
          dataIndex: 'current_business',
          key: 'current_business',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`current_business-${index + 1}`}
                  {...register(`current_business-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '추천업종',
          dataIndex: 'recommend_business',
          key: 'recommend_business',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`recommend_business-${index + 1}`}
                  {...register(`recommend_business-${index + 1}`)}
                />
              </>
            )
          },
        },
        {
          title: '기피업종',
          dataIndex: 'avoid_business',
          key: 'avoid_business',
          render: (value, row, index) => {
            return (
              <>
                <div>{value}</div>
                <input
                  type="hidden"
                  defaultValue={value}
                  name={`avoid_business-${index + 1}`}
                  {...register(`avoid_business-${index + 1}`)}
                />
              </>
            )
          },
        },
      ],
    },
  ]

  const hiddenAddressIdColumn = [
    {
      title: 'address_id',
      dataIndex: 'address_id',
      key: 'address_id',
      className: 'hide',
      width: '0px',
      render: (value, row, index) => {
        return (
          <input
            type="hidden"
            defaultValue={value}
            name={`address_id-${index + 1}`}
            {...register(`address_id-${index + 1}`)}
          />
        )
      },
    },
  ]

  return (
    <StyledFormContainer>
      {tableData?.length > 0 && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Header
              prepend={<HeaderBack onClose={onClose} />}
              append={
                <Button
                  notemode={localStorage.getItem('notemode')}
                  type="submit"
                  color="primary"
                >
                  완료
                </Button>
              }
              expand
              border
            >
              엑셀 파일 업로드
            </Header>
            <StyledContainer className="excel-footer">
              {localStorage.getItem('notemode') == 'card' && (
                <StyledSelectLabel>
                  매물노트를 저장할 폴더를 선택해 주세요.
                </StyledSelectLabel>
              )}
              <StyledInputGroup
                notemode={localStorage.getItem('notemode')}
              >
                {localStorage.getItem('notemode') == 'card' && (
                  <StyledSelectWrap>
                    <Select
                      value={folderSrl}
                      expand
                      onChange={(e) =>
                        setValue('folderSrl', e.target.value)
                      }
                    >
                      <option value="">폴더 선택</option>
                      {folders.map((folder) => (
                        <option
                          value={folder.folder_srl}
                          key={folder.folder_srl}
                        >
                          {folder.name}
                        </option>
                      ))}
                    </Select>
                  </StyledSelectWrap>
                )}
              </StyledInputGroup>
            </StyledContainer>

            <TableWrapper>
              <Table
                columns={[
                  ...customColumns,
                  ...getColumns1(),
                  ...hideColumns,
                  ...getColumns2(),
                  ...sanggaColumns,
                  ...bunyangColumns,
                  ...getColumns3(),
                  ...hiddenAddressIdColumn,
                ]}
                dataSource={tableData}
                size="small"
                scroll={{
                  x: 'calc(1500px + 50%)',
                  y: tableHeight,
                }}
                pagination={false}
                bordered
              />
            </TableWrapper>
          </form>
        </FormProvider>
      )}
      {tableData?.length === 0 && (
        <>
          <Header
            prepend={<HeaderBack onClose={onClose} />}
            expand
            border
          >
            엑셀 파일 업로드
          </Header>
          <Empty>결과가 없습니다. 필수값을 잘 입력하셨나요?</Empty>
        </>
      )}
    </StyledFormContainer>
  )
}

const TableWrapper = styled.div`
  padding-left: 20px;
`

export default NoteUploadExcel
