import theme from 'lib/styles/theme'
import styled from 'styled-components'
import Img2Src from 'assets/images/summary-banner-2.png'
import { getFullUrl } from 'utils'

const SummaryBannerHasBuilding = () => {
  return (
    <Block>
      <Title>
        건물 신축을 <br />
        고민 중이신가요?
      </Title>
      <Button href={getFullUrl('/estimate')}>
        건축 예상 비용 확인하기
        <i className="far fa-angle-double-right"></i>
      </Button>
      <img src={Img2Src} />
    </Block>
  )
}

export const Block = styled.div`
  position: relative;
  width: 100%;
  background-color: #e3fdff;
  margin-top: 20px;
  padding: 14px 20px;

  img {
    position: absolute;
    right: 22px;
    bottom: 15px;
    width: 112px;
    height: auto;
  }
`

export const Title = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 14px;
`

export const Button = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 150px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  color: ${theme.colors.base.white};
  background-color: #145e87;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;

  &:hover {
    color: ${theme.colors.base.white};
  }
`

export default SummaryBannerHasBuilding
