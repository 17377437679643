import { Confirm } from 'components/design'
import NoteDetailGongbuDoc from 'components/jmapnotev2/detail/NoteDetailGongbuDoc'
import { deleteDoc } from 'lib/api/notev2/contractApi'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { useCallback, useEffect, useState } from 'react'

/**
 * 매물 3뎁스 - 공부문서 탭
 * 공부문서 목록 로직
 */
const NoteDetailGongbuIssuanceListContainer = ({
  children,
  scrollRef,
  searchGongbu,
}) => {
  const [onRequestDeleteDoc, , dataDeleteDoc] = useAxios(deleteDoc)
  const [readDocTarget, setReadDocTarget] = useState(null)
  const [readDocTargetType, setReadDocTargetType] = useState('')

  const isWorking = (value) =>
    ['pending', 'working', 'extracting'].includes(value)

  const getStatusKor = (value) => {
    return isWorking(value) ? '발급중' : '발급실패'
  }

  useEffect(() => {
    return () => {
      if (scrollRef && scrollRef?.current && scrollRef?.current?.view) {
        scrollRef.current.view.style.overflow = 'scroll'
        scrollRef.current.view.style.marginRight = 0
      }
    }
  }, [])

  const onClickReadDoc = (item, docType) => {
    //
    if (scrollRef && scrollRef?.current && scrollRef?.current?.view) {
      scrollRef.current.view.scrollTop = 0
      scrollRef.current.view.style.overflow = 'hidden'
      scrollRef.current.view.style.marginRight = 0
    }

    setReadDocTarget(item)
    setReadDocTargetType(docType)
  }

  const onCloseDocLayer = () => {
    setReadDocTarget(null)
    setReadDocTargetType('')
    if (scrollRef && scrollRef?.current && scrollRef?.current?.view) {
      scrollRef.current.view.style.overflow = 'scroll'
      scrollRef.current.view.style.marginRight = 0
    }
  }

  /**
   * 공부문서 삭제
   */
  const onClickDelete = useCallback(
    ({ channel_srl, note_srl, doc_srl }) => {
      if (!channel_srl || !note_srl || !doc_srl) return

      Confirm.info({
        title: '알림',
        content: '공부문서를 삭제하시겠습니까?',
        onOk: () =>
          onRequestDeleteDoc({ channel_srl, note_srl, doc_srl }),
        okButtonProps: { color: 'danger' },
        okText: '삭제',
      })
    },
    [],
  )

  /**
   * 공부문서 삭제 결과
   */
  useEffect(() => {
    if (!dataDeleteDoc) return

    if (dataDeleteDoc.error == 0) {
      //목록 재조회
      searchGongbu()
    }
  }, [dataDeleteDoc])

  return children({
    isWorking,
    getStatusKor,
    onClickReadDoc,
    onClickDelete,
    //발급받은 문서 열람 layer
    readDoc: (
      <NoteDetailGongbuDoc
        data={readDocTarget}
        type={readDocTargetType}
        onClose={onCloseDocLayer}
      />
    ),
  })
}

export default NoteDetailGongbuIssuanceListContainer
