import styled from 'styled-components'
import {
  MainWrapper,
  MaxWidthWrapper,
} from 'components/jmapnotev2/style/NoteStyle'
import { BlockTitle } from 'components/jmapnotev2/style/NotePaymentStyle'
import theme, { media } from 'lib/styles/theme'
import NotePaymentInfo from './NotePaymentInfo'
import Scrollbars from 'react-custom-scrollbars-2'
import MobileChannelHeader from '../channel/MobileChannelHeader'
import useIsMobile from 'lib/hooks/useIsMobile'
import NotePaymentRule from './NotePaymentRule'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import { useSelector } from 'react-redux'
import NotePaymentDeunggibuPriceList from './NotePaymentDeunggibuPriceList'

/**
 * 등기부등본 구입하기 페이지
 */
const NotePaymentDeunggibu = () => {
  const isMobile = useIsMobile()
  const planInfo = useSelector((state) => state.auth.planInfo)

  const { onRequest } = useUserLoader(false) //사용자 정보 최초 갱신

  return (
    <>
      {isMobile && <MobileChannelHeader channel={false} />}
      <StyledMainWrapper noside gray>
        <Scrollbars autoHide>
          <StyledMaxWidthWrapper>
            <Wrapper>
              <Title>등기부등본 구입하기</Title>
              <BlockTitle>현재 이용 중인 요금제</BlockTitle>
              <BlockWrapper>
                <NotePaymentInfo
                  planInfo={planInfo}
                  hideButtons
                  marginBottom
                />
                <NotePaymentDeunggibuPriceList />
              </BlockWrapper>
              <NotePaymentRule />
            </Wrapper>
          </StyledMaxWidthWrapper>
        </Scrollbars>
      </StyledMainWrapper>
    </>
  )
}

const StyledMainWrapper = styled(MainWrapper)`
  ${media.mediumM`
    left: 0;
    width: 100%;
  `}
`

const Wrapper = styled.div`
  width: 100%;
  background-color: ${theme.colors.base.white};
  padding: 0 40px;
  padding-bottom: 40px;

  ${media.mediumS`
    background-color: ${theme.colors.blue[50]};
    padding: 30px 20px;
  `}
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  margin: 0 auto;
  height: 100%;
  background-color: ${theme.colors.base.white};
  ${media.smallPc`
    width: 100%;
    max-width: 100%;
  `}

  ${media.mediumS`
    padding: 0;
  `}
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 36px;
`

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default NotePaymentDeunggibu
