import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledFormItemCol = styled.div`
  flex-basis: 50%;
  max-width: 50%;
  padding: 0.5rem 0.75rem 0.75rem;

  ${({ expand }) =>
    expand &&
    `
    flex-basis: 100%;
    max-width: 100%;
  `}
`

const Label = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`

const Body = styled.div`
  ${({ middle }) =>
    middle &&
    `
    display: flex;
    align-items: center;
  `}
`

const Append = styled.div`
  color: ${theme.colors.gray[700]};
  font-size: 13px;
  margin-left: 0.5rem;
  margin-right: 0.25rem;
`

const FormItemCol = ({ children, label, expand, middle, append }) => (
  <StyledFormItemCol expand={expand}>
    {label && <Label>{label}</Label>}
    <Body middle={middle}>
      {children}
      {append && <Append>{append}</Append>}
    </Body>
  </StyledFormItemCol>
)

export default FormItemCol
