import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { SegmentProvider } from './SegmentContext'

const StyledSegment = styled.ul`
  list-style-type: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 2px;
  margin: 0;

  outline: none;
  border-radius: 10px;
  border: none;
  background-color: ${theme.colors.gray[100]};
  box-sizing: border-box;
  overflow: hidden;

  ${({ expand }) =>
    expand &&
    `
    width: 100%;
    & > li {
      padding-left: 0;
      padding-right: 0;
    }
  `}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          height: 32px;
        `
      case 'medium':
        return `
          height: 44px;
        `
    }
  }}
`

const Segment = ({ children, size = 'medium', expand }) => {
  return (
    <StyledSegment size={size} expand={expand}>
      <SegmentProvider size={size}>{children}</SegmentProvider>
    </StyledSegment>
  )
}

export default Segment
