import { useContext } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { notification } from 'antd'
import theme from 'lib/styles/theme'

import band from 'assets/images/ic_band.png'
import MapContext from 'contexts/map'

import SummaryShareButton from './SummaryShareButton'
import SummaryShareKakao from './SummaryShareKakao'

const StyledList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  padding: 0;
  margin: 0 -0.25rem 1rem;

  & > li {
    flex: 0 0 auto;
    padding: 0 0.25rem;
  }
`

const StyledUrl = styled.div`
  display: flex;
  height: 40px;

  border: 1px solid ${theme.colors.gray[200]};
  border-radius: ${theme.borderRadius[1]};

  overflow: hidden;
`

const UrlInput = styled.input`
  flex: 1;
  width: 100%;
  height: 40px;
  padding: 0 1rem;

  border: none;
  background: none;
  outline: none;

  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
`

const CopyUrlButton = styled.button`
  cursor: pointer;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1.5rem;

  border: none;
  border-left: 1px solid ${theme.colors.gray[200]};

  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 700;
  background-color: ${theme.colors.gray[100]};
`

const Share = ({ path = window.location.href }) => {
  const { state } = useContext(MapContext)
  const { clickPosition } = state

  const openNotification = () => {
    notification.warning({
      message: `복사되었습니다.`,
    })
  }

  return (
    <div className="share_container">
      <StyledList>
        <li>
          <SummaryShareKakao pathname={path} />
        </li>

        <li>
          <a href={`https://www.facebook.com/sharer.php?u=${path}`}>
            <SummaryShareButton color="#247bfc">
              <i className="fab fa-facebook-f"></i>
            </SummaryShareButton>
          </a>
        </li>

        <li>
          <a
            href={`https://twitter.com/intent/tweet?url=${path}&amp;text=주택지도`}
          >
            <SummaryShareButton color="#4cb4ff">
              <i className="fab fa-twitter"></i>
            </SummaryShareButton>
          </a>
        </li>

        {window.innerWidth > 768 && (
          <li>
            <a
              href={`http://www.band.us/plugin/share?body=주택지도%0A${path}`}
            >
              <SummaryShareButton color="#13c35a">
                <img src={band} alt="band" />
              </SummaryShareButton>
            </a>
          </li>
        )}

        {window.innerWidth <= 768 && (
          <li>
            <a href={`bandapp://create/post?text=주택지도%0A${path}`}>
              <SummaryShareButton color="#13c35a">
                <img src={band} alt="band" />
              </SummaryShareButton>
            </a>
          </li>
        )}
      </StyledList>

      <StyledUrl>
        <UrlInput type="text" defaultValue={path} />
        <CopyToClipboard text={path} onCopy={openNotification}>
          <CopyUrlButton>URL 복사</CopyUrlButton>
        </CopyToClipboard>
      </StyledUrl>
    </div>
  )
}

export default Share
