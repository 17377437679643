import PropTypes from 'prop-types'
import { forwardRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import theme from 'lib/styles/theme'
import { NumericFormat } from 'react-number-format'

const Input = forwardRef(
  (
    {
      type = 'text',
      id = '',
      name = '',
      label = '',
      className,
      value,
      defaultValue,
      required = false,
      placeholder = '',
      numberFormatProps, // inputMode: "decimal" 로 하면 모바일에서 숫자 캐패드 나옴
      expand,
      append,
      prepend,
      step,
      autoComplete,
      onChange,
      onBlur,
      onKeyPress,
      onEnter,
      color,
      /**
       * react-hook-form 사용 시 제어되지 않는 컴포넌트가 되기 때문에
       * value를 watch하여 label visible 여부를 결정하도록 함.
       */
      watchedValue,
      design = 'material', // material, box, outline, etc~~
      disabled,
    },
    ref,
  ) => {
    const inputProps = {
      type,
      id,
      name,
      className,
      value,
      defaultValue,
      required,
      placeholder,
      onChange,
      onBlur,
      step,
      autoComplete,
      label,
      onKeyPress,
      color,
      disabled,
    }

    const valueExist = () => {
      if (type === 'number') {
        const parsedValue = parseInt(value)
        const parsedWatchedValue = parseInt(watchedValue)

        if (parsedValue || parsedWatchedValue) return true
        return false
      }

      if (value || watchedValue) return true
      return false
    }

    const handleKeyPress = (e) => {
      if (onEnter && e.key === 'Enter') return onEnter(e)
      if (onKeyPress) onKeyPress(e)
    }

    // Label을 덮는 요소가 채워지는 타입이거나 placeholder가 입력된 경우
    // 라벨을 항상 보여주는지에 대한 여부
    const LabelAlwayVisible = () => {
      const types = ['date', 'month', 'week', 'time', 'datetime-local']
      if (placeholder) return true
      if (types.find((e) => e === type)) return true
      if (disabled) return true
      return false
    }

    return (
      <InputWrap
        bordercolor={color}
        expand={expand}
        labelExist={label ? true : false}
        design={design}
      >
        {prepend && <Prepend>{prepend}</Prepend>}
        {numberFormatProps ? (
          <NumericFormat
            placeholder={placeholder}
            {...numberFormatProps}
            disabled={disabled}
          />
        ) : (
          <input
            {...inputProps}
            value={value}
            onKeyPress={handleKeyPress}
            ref={ref}
            disabled={disabled}
          />
        )}
        {label && (
          <Label
            valueExist={LabelAlwayVisible() ? true : valueExist()}
            placeholderExist={placeholder ? true : false}
            design={design}
          >
            {label}
          </Label>
        )}

        {append && (
          <Append labelExist={label ? true : false}>{append}</Append>
        )}
      </InputWrap>
    )
  },
)

const LabelActiveCss = css`
  transform: translateY(
    ${({ design }) => (design === 'box' ? 12 : 24)}px
  );
  color: ${({ design }) =>
    design === 'box' ? theme.colors.gray[600] : theme.colors.gray[400]};
  font-size: 1rem;
  font-weight: 500;
  opacity: 1;
`

const Label = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 600;

  opacity: 0;
  transition: 0.2s ease;

  ${({ design }) =>
    design === 'box' &&
    `
    top: 0.25rem;
    left: 1rem;
  `}

  ${({ valueExist }) =>
    valueExist &&
    `
    opacity: 1;
  `}


  ${({ placeholderExist, valueExist }) =>
    !placeholderExist && !valueExist && LabelActiveCss}
`

const InputWrap = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;

  border-radius: 0;
  background-color: white;
  border: none;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  outline: none;
  appearance: none;
  box-sizing: border-box;

  transition: 0.2s ease;

  ${({ design }) =>
    design === 'box' &&
    `
    border: 2px solid transparent;
    background-color: ${theme.colors.gray[100]};
    border-radius: ${theme.borderRadius[1]};

    &::after {
      display: none;
    }
  `}

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    border-bottom: 2px solid
      ${({ bordercolor }) =>
        bordercolor == 'blue'
          ? `${theme.colors.blue[400]}`
          : `${theme.colors.primary[400]}`};

    opacity: 0;
    transition: 0.2s ease;
  }

  ${({ expand }) =>
    expand &&
    `
      display :flex;
      width: 100%;
  `}

  &:hover {
    border-color: ${theme.colors.gray[200]};
  }
  &:focus-within {
    outline: none !important;
    &::after {
      opacity: 1;
    }

    ${({ design, bordercolor }) =>
      design === 'box' &&
      `
      background-color: white;
      border-color: ${
        bordercolor == 'blue'
          ? theme.colors.blue[400]
          : theme.colors.primary[400]
      };
    `}
  }

  ${({ readonly }) =>
    readonly &&
    `
    &:focus {
    border: 1px solid ${theme.colors.gray[200]};
    background-color: ${theme.colors.gray[100]};
    box-shadow: none;
  }
  `}

  & > input {
    height: 40px;
    padding: 0;
    padding-right: 2px;

    color: ${theme.colors.gray[900]};
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0;
    background-color: transparent;

    box-sizing: border-box;
    appearance: none;
    outline: none !important;
    border: none;
    box-shadow: none;

    &:disabled {
      color: ${theme.colors.gray[600]};
      cursor: not-allowed;
    }
    &::placeholder {
      color: ${theme.colors.gray[400]};
      font-weight: 400;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &[type='date'] {
      position: relative;

      &::-webkit-calendar-picker-indicator {
        color: transparent;
        background: none;
        z-index: 1;
      }

      &::before {
        display: block;
        font-family: 'Font Awesome 5 Pro';
        content: '\f133';
        position: absolute;
        right: 6px;
        color: ${theme.colors.gray[900]};
      }
    }

    ${({ expand }) =>
      expand &&
      `
      width: 100%;
    `}

    ${({ labelExist }) =>
      labelExist &&
      `
      height: calc(40px + 1rem);
      padding-top: 1rem;
    `}

    ${({ design }) =>
      design === 'box' &&
      `
      border-radius: 4px;
      padding-left: 1rem;
    `}

    &:focus + ${Label} {
      opacity: 1;
      color: ${theme.colors.primary[500]};
      font-size: 0.75rem;
      font-weight: 600;
      transform: translateY(0);
    }
  }
`

const Append = styled.div`
  padding-left: 0.5rem;
  padding-right: 2px;
  /* padding-right: 0.625rem; */

  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;

  ${({ labelExist }) =>
    labelExist &&
    `
      padding-top: 1rem;
    `}
`

const Prepend = styled.div`
  margin-right: 8px;
`

Input.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'email',
    'date',
    'password',
    'month',
    'week',
    'time',
    'datetime-local',
    'search',
    'tel',
    'url',
  ]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  design: PropTypes.oneOf(['material', 'box']),
}

export default Input
