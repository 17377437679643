import useAxios from 'lib/hooks/useAxios'
import { useCallback, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { Button, Confirm, Empty, ListItem } from 'components/design'
import {
  copyContract,
  deleteContract,
  getContracts,
} from 'lib/api/notev2/contractApi'
import { LabelWithTitle } from './NoteDetailStyle'
import theme from 'lib/styles/theme'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ConfigProvider, Result, message } from 'antd'
import priceFormat from 'lib/utils/priceFormat'
import { useSelector } from 'react-redux'
import { accessibleGroups } from 'Constants'
import { Steps } from 'intro.js-react'
import { useDispatch } from 'react-redux'
import {
  setContractStepState,
  setOnBoardingStart,
  setOnBoardingState,
  setThreeDepthStart,
} from 'modules/onBoarding'
import OnBoarding from '../OnBoarding/OnBoarding'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'

const NoteDetailContract = ({ onClickDocsWrite }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const { watch } = useFormContext()
  const [onRequestContracts, , data] = useAxios(getContracts)
  const [onRequestCopyContract, , dataCopyContract] =
    useAxios(copyContract)
  const [contracts, setContract] = useState([])
  const [onRequestDeleteContract, , dataDeleteContract] =
    useAxios(deleteContract)
  const history = useHistory()
  const dispatch = useDispatch()

  //온보딩 관련 변수
  const [stepsEnabled, setStepsEnalbed] = useState(false)
  const [steps, setSteps] = useState([])

  const threeDepthChannelSrl = useSelector(
    (state) => state.onBoarding.threeDepthChannelSrl,
  )
  const threeDepthStart = useSelector(
    (state) => state.onBoarding.threeDepthStart,
  )

  const contractStepState = useSelector(
    (state) => state.onBoarding.contractStepState,
  )
  //
  const note_srl = watch('note_srl')
  const channel_srl = watch('channel_srl')
  const property_type = watch('property_type')

  useEffect(() => {
    if (!channel_srl && !note_srl) return
    onRequestContracts({ channel_srl, note_srl })
  }, [channel_srl, note_srl])

  useEffect(() => {
    if (!data || !data.result) return
    setContract(data.result)
  }, [data])

  /**
   * 계약서 삭제
   */
  const onClickDelete = useCallback(({ channel_srl, contract_srl }) => {
    if (!channel_srl || !contract_srl) return

    Confirm.info({
      title: '정말로 계약서를 삭제하시겠습니까?',
      content:
        '계약서를 삭제하면 다시는 되돌릴 수 없으며, 이 계약서와 연결된 확인설명서도 모두 삭제됩니다. 이에 대한 모든 책임은 채널 주인 본인에게 있습니다.',
      onOk: () =>
        onRequestDeleteContract({ channel_srl, contract_srl }),
      okButtonProps: { color: 'danger' },
      okText: '삭제',
    })
  }, [])

  /**
   * 계약서 삭제 결과
   */
  useEffect(() => {
    if (!dataDeleteContract) return

    if (dataDeleteContract.error == 0) {
      message.success('계약서가 삭제되었습니다')
      //목록 재조회
      onRequestContracts({ channel_srl, note_srl })
    }
  }, [dataDeleteContract])

  /**
   * 계약서 링크 이동
   * @params {*} contract_srl, channel_srl, note_srl, type,
   * @returns
   */
  const onClickLinkButton = ({
    contract_srl,
    channel_srl,
    note_srl,
    type,
  }) => {
    if (!contract_srl) {
      message.info('존재하지 않는 계약서입니다.')
      return
    }
    if (type == '계약서') {
      history.push(
        `/note/v2/docs/${channel_srl}/${note_srl}/edit/${contract_srl}`,
      )
    } else if (type == '확인설명서') {
      history.push(
        `/note/v2/docs/${channel_srl}/${note_srl}/edit/${contract_srl}?tab=description`,
      )
    } else if (type == '개인정보수집동의서') {
      history.push(
        `/note/v2/docs/${channel_srl}/${note_srl}/edit/${contract_srl}?tab=consent`,
      )
    }
  }

  /**
   * 계약서 사본생성
   */
  const onClickCopyContract = ({ contract_srl, channel_srl }) => {
    onRequestCopyContract({ contract_srl, channel_srl })
  }

  useEffect(() => {
    if (!dataCopyContract) return

    if (dataCopyContract.error == 0) {
      //목록 재조회
      onRequestContracts({ channel_srl, note_srl })
    }
  }, [dataCopyContract])

  //온보딩관련함수

  //리렌더링이 되면서 다시 다른 steps가 세팅된다
  useEffect(() => {
    if (threeDepthChannelSrl != 0 && contractStepState) {
      setStepsEnalbed(true)
    }
  }, [contractStepState])

  const onExit = () => {
    setStepsEnalbed(false)
    dispatch(setOnBoardingStart(false))
    dispatch(setContractStepState(false))
    dispatch(setOnBoardingState(false))
  }
  //

  return (
    <Wrapper>
      <OnBoarding
        enabled={stepsEnabled}
        steps={steps}
        onExit={onExit}
        setSteps={setSteps}
        onComplete={(e) => {
          dispatch(setThreeDepthStart(false))
        }}
        onChange={(e) => {
          if (e == 1) {
            dispatch(setThreeDepthStart(false))
            setStepsEnalbed(false)
            onClickDocsWrite()
          }
        }}
        options={{
          nextLabel: '계약서 작성',
          prevLabel: '소개 끝내기',
          showBullets: false,
          doneLabel: '계약서 작성',
          buttonClass: 'customButton',
        }}
      ></OnBoarding>
      <>
        {channelInfo?.member_type != 'readonly' && (
          <WriteWrapper className="contract-class">
            <LabelWithTitle>신규 계약서 작성</LabelWithTitle>
            <Button
              expand
              size="large"
              color="blue"
              onClick={onClickDocsWrite}
              disabled={property_type == '고시원'}
            >
              계약서 작성하기
            </Button>
          </WriteWrapper>
        )}

        <LabelWithTitle>계약서 내역</LabelWithTitle>
        {contracts && contracts.length > 0 ? (
          contracts?.map((item, i) => {
            if (!item) return
            return (
              <ListItem key={i}>
                <Title>{item.contract_type} 계약서</Title>
                <UpdateDate>
                  {item?.last_update ? (
                    <span>
                      최근 수정일{' '}
                      {moment(item.regdate, 'YYYYMMDDHHmmss').format(
                        'YYYY.MM.DD HH:mm',
                      )}
                    </span>
                  ) : (
                    ''
                  )}
                </UpdateDate>
                <ListInfoWrapper>
                  <SubTitle>임차인</SubTitle>
                  <Value>
                    {item?.buyer &&
                    Array.isArray(item?.buyer) &&
                    item?.buyer.length > 0 &&
                    item?.buyer[0]?.name ? (
                      item?.buyer[0]?.name
                    ) : (
                      <span>&ndash;</span>
                    )}
                  </Value>
                  <SubTitle>임대인</SubTitle>
                  <Value>
                    {item?.buyer &&
                    Array.isArray(item?.seller) &&
                    item?.seller.length > 0 &&
                    item?.seller[0]?.name ? (
                      item?.seller[0]?.name
                    ) : (
                      <span>&ndash;</span>
                    )}
                  </Value>
                </ListInfoWrapper>
                <ListInfoWrapper>
                  <SubTitle>계약일</SubTitle>
                  <Value>{item?.sign_date}</Value>
                  <SubTitle>잔금일</SubTitle>
                  <Value>
                    {item?.payment_schedule?.jangeum[0]?.date &&
                    Array.isArray(item?.payment_schedule?.jangeum) &&
                    item?.payment_schedule?.jangeum?.length > 0 ? (
                      item.payment_schedule.jangeum[0].date !=
                      'Invalid date' ? (
                        moment(
                          item.payment_schedule.jangeum[0].date,
                        ).format('YYYY.MM.DD')
                      ) : (
                        <span>&ndash;</span>
                      )
                    ) : (
                      <span>&ndash;</span>
                    )}
                  </Value>
                </ListInfoWrapper>
                <ListInfoWrapper>
                  <SubTitle>금액</SubTitle>
                  <Value>
                    {item?.total_price ? (
                      priceFormat(item?.total_price)
                    ) : (
                      <span>&ndash;</span>
                    )}
                  </Value>
                </ListInfoWrapper>
                <BottomButtonWrapper>
                  <Button
                    size="small"
                    color="blue"
                    onClick={() =>
                      onClickLinkButton({
                        contract_srl: item.contract_srl,
                        channel_srl: item.channel_srl,
                        note_srl: item.note_srl,
                        type: '계약서',
                      })
                    }
                  >
                    열람
                  </Button>
                  {channelInfo?.member_type != 'readonly' && (
                    <>
                      <Button
                        size="small"
                        plain
                        color="blue"
                        onClick={() =>
                          onClickCopyContract({
                            contract_srl: item.contract_srl,
                            channel_srl: item.channel_srl,
                          })
                        }
                      >
                        사본생성
                      </Button>
                      <Button
                        onClick={() =>
                          onClickDelete({
                            channel_srl: item.channel_srl,
                            contract_srl: item.contract_srl,
                          })
                        }
                        size="small"
                        plain
                        color="danger"
                      >
                        삭제
                      </Button>
                    </>
                  )}
                </BottomButtonWrapper>
              </ListItem>
            )
          })
        ) : (
          <Empty>작성한 계약서가 없습니다</Empty>
        )}
      </>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  font-size: 14px;
`

const WriteWrapper = styled.div`
  margin-bottom: 60px;
`

const SubTitle = styled.div`
  font-weight: 500;
  margin-right: 4px;
`

const Value = styled.div`
  font-weight: 400;
  &:not(:last-child) {
    margin-right: 8px;
  }
`

const ListInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.gray[600]};
  line-height: 100%;
  margin-bottom: 4px;
`

const BottomButtonWrapper = styled.div`
  border-top: 1px solid ${theme.colors.gray[200]};
  margin-top: 20px;
  padding-top: 8px;
  text-align: right;
  & > button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

const UpdateDate = styled.div`
  font-size: 12px;
  color: ${theme.colors.gray[400]};
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 16px;
`

const Title = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 8px;
`

export default NoteDetailContract
