function thousandSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default function priceFormat(number, startWithMillion) {
  if (!number) return startWithMillion ? '' : '0원'

  const unitWords = startWithMillion
    ? ['', '', '억', '조', '경']
    : ['원', '만', '억', '조', '경']
  const splitUnit = 10000
  const splitCount = unitWords.length
  let resultArray = []
  let resultString = ''

  for (let i = 0; i < splitCount; i++) {
    let unitResult =
      (number % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i)
    unitResult = Math.floor(unitResult)
    if (unitResult > 0) {
      resultArray[i] = unitResult
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue
    resultString =
      String(thousandSeparator(resultArray[i])) +
      unitWords[i] +
      `${resultString ? ` ${resultString}` : ''}`
  }

  return resultString
}

export function priceFormatMilion(number) {
  if (!number) return '0'

  const unitWords = ['원', '만', '억', '조', '경']
  const splitUnit = 10000
  const splitCount = unitWords.length
  let resultArray = []
  let resultString = ''

  const powNumber = number * Math.pow(1000, -2)
  const roundNumber = Math.round(powNumber)
  const resultNumber = roundNumber / Math.pow(1000, -2)

  for (let i = 0; i < splitCount; i++) {
    let unitResult =
      (resultNumber % Math.pow(splitUnit, i + 1)) /
      Math.pow(splitUnit, i)
    unitResult = Math.floor(unitResult)
    if (unitResult > 0) {
      resultArray[i] = unitResult
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue
    resultString =
      String(thousandSeparator(resultArray[i])) +
      unitWords[i] +
      `${resultString ? ` ${resultString}` : ''}`
  }

  return resultString
}
