import { Button } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

const NoteNoticeView = () => {
  /**
   * 목록으로
   */
  const onClickBackList = () => {
    //
  }
  return (
    <Wrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Tag blue>공지</Tag>
          <Title>매물노트 고객센터 운영 시간 안내</Title>
          <Date>24.01.01</Date>
        </TitleWrapper>
        <Content></Content>
        <FileWrapper>
          <BlockTitle>첨부파일</BlockTitle>
          <Files>
            <div>첨부파일명.jpg (123KB)</div>
          </Files>
        </FileWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <NextPrev>
          <BlockTitle>이전글</BlockTitle>
          <NextPrevTitle empty>이전글이 없습니다.</NextPrevTitle>
        </NextPrev>
        <NextPrev>
          <BlockTitle>다음글</BlockTitle>
          <NextPrevTitle>
            매물노트 고객센터 운영 시간 안내
          </NextPrevTitle>
        </NextPrev>
      </ContentWrapper>
      <Button color="gray" onClick={onClickBackList}>
        목록으로 돌아가기
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 50px;
  width: 100%;
  background-color: ${theme.colors.base.white};
  border-radius: 12px;

  ${media.mediumM`
        padding: 30px;
    `}

  ${media.mediumS`
        padding: 30px 20px;
    `}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
`

const Tag = styled.div`
  display: flex;
  min-width: 55px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  color: ${theme.colors.primary[400]};
  background-color: ${theme.colors.primary[100]};
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;

  ${({ blue }) =>
    blue &&
    `
        color: ${theme.colors.blue[400]};
        background-color: ${theme.colors.blue[100]};
    `}
`

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 4px;
`

const Date = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`

const Content = styled.div`
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
`

const FileWrapper = styled.div`
  display: flex;
`

const BlockTitle = styled.div`
  width: 90px;
  font-size: 16px;
  font-weight: 600;
  line-height: 180%;
`

const Files = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  color: ${theme.colors.gray[600]};
`

const NextPrev = styled.div`
  display: flex;
  align-items: center;
`

const NextPrevTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 180%;
  color: ${theme.colors.gray[600]};

  ${({ empty }) => empty && `color: ${theme.colors.gray[400]};`}
`

export default NoteNoticeView
