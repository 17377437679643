import { message } from 'antd'
import { Confirm } from 'components/design'
import {
  deleteDonghoDanji,
  getDonghoDanjis,
  updateDonghoDanji,
} from 'lib/api/notev2/dongho'
import useAxios from 'lib/hooks/useAxios'
import { assign, map, mapKeys, sortBy } from 'lodash'
import {
  resetSelectedDanji,
  setSelectedPlusDanji,
} from 'modules/noteDongho'
import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NoteDonghoHasDanjiContainer = ({ children, searchRefresh }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )

  const [page, setPage] = useState(1) //현재 페이지
  const [onRequest, , data] = useAxios(getDonghoDanjis, {
    resetError: true,
  }) //추가한 단지 리스트 검색
  const [onRequestAddPlus, , dataAddPlus] = useAxios(
    updateDonghoDanji,
    { resetError: true },
  ) //플러스 단지로 추가
  const [onRequestDelete, , dataDelete, errorDelete] = useAxios(
    deleteDonghoDanji,
    {
      resetError: true,
    },
  ) // 단지 삭제
  const [list, setList] = useState([])
  const [stopLoad, setStopLoad] = useState(false) //데이터 더 이상 없으면 로딩 멈춤(무한 스크롤)
  const [loadTargetRef, inView] = useInView({
    rootMargin: '-20px 0% 0% 0%',
  })
  const [deletedDanjiId, setDeletedDanjiId] = useState(null)

  const count = 5
  /**
   * 무한스크롤 observer ref 감지
   */
  useEffect(() => {
    if (stopLoad || list.length < count) return
    // inView가 true 일때만 실행한다.

    if (inView) {
      setPage((prev) => prev + 1)
      search(page + 1)
    }
  }, [inView])

  /**
   * 처음 접속 검색
   */
  useEffect(() => {
    search()
  }, [])

  /**
   * 추가한 단지 리스트 검색
   */
  const search = (pPage, reset) => {
    if (reset) {
      const targetIdx = list.findIndex(({ id }) => deletedDanjiId == id)
      const targetPage = Math.ceil(targetIdx / count) || 1

      onRequest({
        page: targetPage || pPage || page,
        count,
      })
    } else {
      onRequest({
        page: pPage || page,
        count,
      })
    }
  }

  /**
   * 검색 결과
   */
  useEffect(() => {
    if (!data) return
    if (data?.error != 0 || !data?.result) {
      setStopLoad(true)
      return
    }
    if (data.result.length == 0) {
      if (deletedDanjiId) {
        setList([])
      }
      setStopLoad(true)
      return
    }

    if (deletedDanjiId) {
      setList((prev) => prev.filter(({ id }) => id != deletedDanjiId))

      setDeletedDanjiId(null)
      return
    }

    setList((prev) => [...prev, ...(data?.result || [])])
  }, [data])

  /**
   * object array merge
   * @param {*} origin 원래 데이터
   * @param {*} newData 업데이트할 데이터
   * @returns
   */
  const setSpecificObjectValues = (origin, newData) => {
    return map(
      assign(
        mapKeys(origin, (v) => v.id),
        mapKeys(newData, (v) => v.id),
      ),
    ).reverse()
  }

  /**
   * 플러스단지 개수
   */
  const plusDanjiCount = useMemo(
    () =>
      list?.length > 0
        ? list.filter(({ is_paid }) => is_paid == 'Y').length
        : 0,
    [list],
  )

  /**
   * 플러스 단지로 변경
   */
  const onClickAddPlus = ({ danji_id, is_paid }) => {
    onRequestAddPlus({
      danji_id,
      is_paid,
    })
  }

  /**
   * 추가 결과
   */
  useEffect(() => {
    if (!dataAddPlus) return

    if (dataAddPlus?.error == 0) {
      message.success('플러스 단지로 등록 또는 해제가 완료되었습니다.')
      setStopLoad(false)
      setList([])
      setPage(1)
      search(1)
      searchRefresh() //메인화면 왼쪽 목록 새로고침

      if (selectedDanji.id == dataAddPlus.danji_id) {
        dispatch(setSelectedPlusDanji())
      }
    }
  }, [dataAddPlus])

  /**
   * 단지 삭제
   */
  const onClickDelete = ({ danji_id }) => {
    Confirm.warn({
      title: '알림',
      content: '단지를 삭제하시겠습니까?',
      onOk: () => {
        onRequestDelete({
          danji_id,
        })
        setDeletedDanjiId(danji_id)
      },
      okButtonProps: { color: 'danger' },
    })
  }

  /**
   * 삭제 결과
   */
  useEffect(() => {
    if (!dataDelete) return

    if (dataDelete?.error == 0) {
      message.success('단지가 삭제되었습니다.')
      if (deletedDanjiId == selectedDanji?.id) {
        dispatch(resetSelectedDanji())
      }
      search(1, true)
      searchRefresh() //메인화면 왼쪽 목록 새로고침
      setStopLoad(false)

      setTimeout(() => {
        const listItemTarget = document.getElementsByClassName(
          'dongho-danji-item',
        )
        if (listItemTarget && listItemTarget.length > 0) {
          listItemTarget[0].click()
        } else {
          history.push('/note/v2/dongho')
        }
      }, 150)
    }
  }, [dataDelete])

  /**
   * 삭제 실패시 삭제 id 초기화
   */
  useEffect(() => {
    if (!errorDelete) return
    setDeletedDanjiId(null)
  }, [errorDelete])

  return children({
    list,
    plusDanjiCount,
    onClickAddPlus,
    onClickDelete,
    loadTargetRef,
  })
}

export default NoteDonghoHasDanjiContainer
