import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { rgba } from 'polished'

const StyledCard = styled.div`
  position: relative;
  display: block;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  border-radius: ${theme.borderRadius[3]};

  ${({ color }) =>
    color &&
    `
    background-color: ${color};
  `}

  ${({ pointer }) =>
    pointer == 'Y' &&
    `
    cursor: pointer;
  `}
`

const Title = styled.div`
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  word-break: keep-all;
  margin-bottom: 10px;
`
const Text = styled.div`
  color: ${rgba('white', 0.7)};
  font-size: 0.875rem;
  font-weight: 500;
  word-break: keep-all;
  line-height: 1.5;
`

const StyledText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.5rem;
`

const StyledImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  user-select: none;
  pointer-events: none;
`

const HomeFeatureItem = ({
  color = theme.colors.primary[400],
  title,
  text,
  background,
  onClick,
}) => {
  return (
    <StyledCard
      color={color}
      onClick={onClick}
      pointer={onClick ? 'Y' : 'N'}
    >
      <StyledText>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </StyledText>

      <StyledImg src={background} alt={title} />
    </StyledCard>
  )
}

export default HomeFeatureItem
