import { useContext } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { numberWithCommas } from 'utils/index'

import useFormatArea from 'lib/hooks/useFormatArea'
import { Tag } from 'components/design'
import MapContext from 'contexts/map'

const SummaryHcHeaderMeta = ({
  building_cnt,
  empty_land_cnt,
  area,
}) => {
  const { state } = useContext(MapContext)
  const { isSquareMeter } = state

  const getComputedArea = () => {
    const parsedArea = parseInt(area)
    if (isSquareMeter)
      return numberWithCommas(Math.floor(parsedArea / 100) * 100)

    return numberWithCommas(Math.floor(parsedArea / 3.305 / 100) * 100)
  }

  return (
    <Meta>
      <MetaItem>
        <Tag label="건물" color="red" size="small" tooltip="건물 수">
          {building_cnt}채
        </Tag>
      </MetaItem>
      <MetaItem>
        <Tag
          size="small"
          color="orange"
          label="빈 땅"
          tooltip="주거나지 수"
        >
          {empty_land_cnt}필지
        </Tag>
      </MetaItem>
      <MetaItem>
        <Tag size="small" label="면적" tooltip="면적">
          약 {getComputedArea()}
          {isSquareMeter ? '㎡' : '평'}
        </Tag>
      </MetaItem>
    </Meta>
  )
}

const Meta = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const MetaItem = styled.span`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 400;
  padding-right: 8px;
`

export default SummaryHcHeaderMeta
