import React, { useCallback } from 'react'
import styled from 'styled-components'
import { SheetButton } from 'components/jmapnotev2/style/NoteStyle'
import { useHistory } from 'react-router-dom'
import { getDocsWritePath } from 'lib/utils/notev2/common'
import { message } from 'antd'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'

const CustomContractDocCell = ({ data, gridfontsize }) => {
  const history = useHistory()
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const getTotalPrice = () => {
    const contract_type = data.contract_type
    if (contract_type == '매매') return data.sale_price * 10000
    else if (contract_type == '전세') return data.jeonse_deposit * 10000
    else if (contract_type == '월세') return data.deposit * 10000
    else return 0
  }

  const getRentPrice = () => {
    const contract_type = data.contract_type
    if (contract_type == '월세') return data.rent * 10000
    else return 0
  }

  const onClickWrite = useCallback(() => {
    if (!data?.note_srl || !data?.channel_srl) {
      message.error('채널 또는 매물정보가 잘못되었습니다.')
      return
    }

    const {
      note_srl,
      channel_srl,
      address_id,
      dong,
      ho,
      property_type,
      contract_type,
      address_jibun,
      invalid_addr_name,
      is_group_building,
      address_doro,
    } = data

    //address_id 있을때만 건축물대장 조회를 위한 파라미터 세팅
    if (address_id) {
      history.push({
        pathname: `/note/v2/docs/${channel_srl}/${note_srl}/edit`,
        state: getDocsWritePath({
          note_srl,
          channel_srl,
          address_id,
          dong,
          ho,
          property_type,
          contract_type,
          address_jibun,
          invalid_addr_name,
          is_group_building,
          total_price: getTotalPrice(contract_type),
          rent_price: getRentPrice(contract_type),
          address_doro,
        }),
      })
    } else {
      //TODO: 유효하지 않은 주소 입력했을때 계약서 작성? - 주소만 세팅하고 다 빈값
      history.push({
        pathname: `/note/v2/docs/${channel_srl}/${note_srl}/edit`,
        state: getDocsWritePath({
          note_srl,
          channel_srl,
          address_id,
          dong,
          ho,
          property_type,
          contract_type,
          address_jibun,
          invalid_addr_name,
        }),
      })
    }
  }, [data])

  const onClickRecentContract = () => {
    const { note_srl, channel_srl, contract_srl } = data

    history.push(
      `/note/v2/docs/${channel_srl}/${note_srl}/edit/${contract_srl}`,
    )
  }

  if (!data.address_srl)
    return <SheetButton disabled>계약서 작성</SheetButton>

  return (
    <Wrapper tabIndex={0}>
      {data.recent_contract_regdate ? (
        <Date tabIndex={-1} onClick={onClickRecentContract}>
          {data.recent_contract_regdate.substring(2)}
        </Date>
      ) : (
        channelInfo?.member_type != 'readonly' && (
          <SheetButton
            tabIndex={-1}
            disabled={data.status == 2}
            onClick={onClickWrite}
            gridfontsize={gridfontsize}
          >
            계약서 작성
          </SheetButton>
        )
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &:focus-visible {
    outline: 1px solid ${theme.colors.blue[500]};
  }

  &:focus {
    border: 1px solid ${theme.colors.blue[500]};
  }
`

const Date = styled.div`
  color: ${theme.colors.blue[400]};
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`

export default React.memo(CustomContractDocCell)
