import { useCallback, useEffect, useState } from 'react'
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
} from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Tooltip } from 'antd'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useChannelPermission from 'containers/note/channel/hooks/useChannelPermission'
import useViewMore from 'lib/hooks/useViewMore'
import { CardMore } from 'components/design'
import CardFold from 'components/design/Card/CardFold'

const FolderList = ({ loading, folders }) => {
  const { channelSrl, folderSrl } = useNoteMatch()
  const location = useLocation()
  const history = useHistory()
  const permmission = useChannelPermission()

  const [folderList, setFolderList] = useState([])

  const { viewCnt, onClickMore, onClickFold, resetViewCnt } =
    useViewMore(5, 5)

  const goToSettingsFolderPage = useCallback(() => {
    history.push({
      pathname: `/note/${channelSrl}/${folderSrl}/settings/folder`,
    })
  })

  useEffect(() => {
    if (!folders || folders.length == 0) return
    if (!folderSrl) return

    /**
     * 선택 된 폴더 맨 앞으로 정렬
     */
    const moveforward = (arr) => {
      return arr
        .filter((x) => x.folder_srl == folderSrl)
        .concat(arr.filter((x) => x.folder_srl != folderSrl))
    }

    setFolderList(moveforward(folders))
    resetViewCnt()
  }, [folders])

  return (
    <>
      <Wrapper
        overflow={
          folders?.length >= 5 && viewCnt <= folders?.length ? 'Y' : 'N'
        }
      >
        {loading && (
          <>
            <Li>
              <FolderSkeleton />
            </Li>
            <Li>
              <FolderSkeleton />
            </Li>
          </>
        )}

        {!loading && (
          <>
            <FolderWrapper viewcnt={viewCnt}>
              {folderList?.map((item, i) => {
                return (
                  <Li key={i}>
                    <Tooltip title={item.name}>
                      <FolderItem
                        to={{
                          pathname: `/note/${item.channel_srl}/${item.folder_srl}`,
                          search: location.search,
                        }}
                      >
                        <div className="folder-item__icon">
                          {item?.icon ? (
                            <i className={'far fa-' + item.icon} />
                          ) : (
                            <i className="far fa-folder" />
                          )}
                        </div>

                        <div className="folder-item__text">
                          {item.name}
                        </div>
                      </FolderItem>
                    </Tooltip>
                  </Li>
                )
              })}
              {permmission !== 'readonly' && (
                <Li className="edit">
                  <FolderAddItem onClick={goToSettingsFolderPage}>
                    <div className="folder-item__icon">
                      <i className="far fa-pencil"></i>
                    </div>
                    <div className="folder-item__text">추가/수정</div>
                  </FolderAddItem>
                </Li>
              )}
              {folders?.length >= 5 && viewCnt <= folders?.length && (
                <CardMore onClick={onClickMore} />
              )}
              {folders?.length >= 5 && viewCnt > folders?.length && (
                <CardFold onClick={onClickFold} />
              )}
            </FolderWrapper>
          </>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  ${({ overflow }) =>
    overflow == 'Y' &&
    `
    margin-bottom: -4px;
  `}
`

const FolderWrapper = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0.25rem;
  margin: -2px;

  ${({ viewcnt }) =>
    viewcnt &&
    `
    & > li {
      &:not(.edit) {
        &:nth-child(n + ${viewcnt}) {
          display: none;
        }
      }
    }  
  `}
`

const FolderSkeleton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  &::after {
    content: '';
    display: block;
    width: 44px;
    height: 44px;

    background-color: ${theme.colors.gray[100]};
    border-radius: 12px;
  }
`

const Li = styled.li`
  flex-basis: 20%;
  max-width: 20%;
  padding: 6px 4px;
`

const FolderItem = styled(NavLink)`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: ${theme.colors.gray[700]};
  font-size: 0.75rem;
  text-align: center;
  line-height: 1;

  .folder-item__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 44px;
    height: 44px;

    color: ${theme.colors.gray[600]};
    background-color: ${theme.colors.gray[100]};
    /* border-radius: ${theme.borderRadius[1]}; */
    border-radius: 12px;

    transition: 0.2s ease;

    i {
      font-size: 1rem;
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .folder-item__text {
    margin-top: 8px;
    max-width: 70px;
    padding: 0 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover {
    color: ${theme.colors.gray[800]};

    .folder-item__icon {
      background-color: ${theme.colors.gray[200]};
    }
  }

  &.active {
    color: ${theme.colors.blue[400]};
    font-weight: 700;

    .folder-item__icon {
      color: ${theme.colors.base.white};
      background-color: ${theme.colors.blue[400]};
    }
  }
`

const FolderAddItem = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: transparent;
  color: ${theme.colors.blue[500]};
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;

  .folder-item__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    /* width: 34px;
    height: 34px; */
    width: 44px;
    height: 44px;

    color: ${theme.colors.blue[500]};
    background-color: ${theme.colors.blue[50]};
    /* border-radius: ${theme.borderRadius[1]}; */
    border-radius: 12px;

    transition: 0.2s ease;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
    i {
      font-size: 1rem;
    }
  }

  .folder-item__text {
    margin-top: 8px;
    white-space: nowrap;
  }

  &:hover {
    color: ${theme.colors.blue[500]};

    .folder-item__icon {
      background-color: ${theme.colors.blue[100]};
    }
  }
`

export default FolderList
