import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import AppContainer from './AppContainer'
import LogoSrc from 'assets/images/logo/jootek-logo_white.png'
import { getFullUrl } from 'utils'
import { ReactComponent as KaKaoIcon } from 'assets/icon/kakao.svg'
import moment from 'moment'

const LandingMainFooter = () => {
  return (
    <Wrapper>
      <AppContainer>
        <Top>
          <Logo src={LogoSrc} />
          <TopBody>
            <FooterItem>
              <FooterItemTitle>기업정보</FooterItemTitle>
              <FooterItemMenu>
                <FooterItemMenuItem>
                  <a href={getFullUrl('/about')}>회사소개</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a href={getFullUrl('/blog')}>블로그</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a href={getFullUrl('/guide')}>가이드</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a
                    href="https://jootek.notion.site/jootek/JOOTEK-Recruit-be3504b1797142fe9b23a0cfeba1510d"
                    target="_blank"
                  >
                    채용
                  </a>
                </FooterItemMenuItem>
              </FooterItemMenu>
            </FooterItem>
            <FooterItem>
              <FooterItemTitle>서비스</FooterItemTitle>
              <FooterItemMenu>
                <FooterItemMenuItem>
                  <a href={getFullUrl('/estimate')}>간편 가견적</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a href={getFullUrl('/projects')}>프로젝트</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a href={getFullUrl('/masters')}>전문가 찾기</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a href="/home">지도</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a href="https://builder.jootek.com/">월간 빌더</a>
                </FooterItemMenuItem>
              </FooterItemMenu>
            </FooterItem>
            <FooterItem>
              <FooterItemTitle>고객센터</FooterItemTitle>
              <FooterItemMenu>
                <FooterItemMenuItem>
                  <a href="mailto:biz@jootek.com">biz@jootek.com</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a href={getFullUrl('/terms')}>이용약관</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem>
                  <a href={getFullUrl('/privacy')}>개인정보취급방침</a>
                </FooterItemMenuItem>
                <FooterItemMenuItem isbutton="Y">
                  <a href="https://pf.kakao.com/_XTUzb">
                    <KaKaoIcon />
                    <span>카카오톡 문의하기</span>
                  </a>
                </FooterItemMenuItem>
              </FooterItemMenu>
            </FooterItem>
          </TopBody>
        </Top>
        <Bottom>
          <CopyRightWrapper>
            <CopyRight>
              &copy; {moment().format('YY')} JOOTEK INC.
              <br />
              <a href="/test/" rel="nofollow">
                &copy;
              </a>
            </CopyRight>
          </CopyRightWrapper>
          <Info>
            주식회사 주택&nbsp;|&nbsp;대표 강인승
            <span className="app-hide-in-mobile">|</span>&nbsp;
            <br className="app-show-in-mobile" />
            대전광역시 서구 만년로68번길 15-20 대전콘텐츠기업지원센터
            506호
            <br />
            사업자등록번호 218-81-24451
            <span className="app-hide-in-mobile">|</span>
            <br className="app-show-in-mobile" />
            통신판매업신고
            <a
              href="https://www.ftc.go.kr/www/bizCommList.do?key=232&amp;searchCnd=wrkr_no&amp;searchKrwd=2188124451"
              target="_blank"
            >
              2020-대전유성-0039
            </a>
            <br />
            고객센터 010-7313-3330 |&nbsp;
            <a href="https://pf.kakao.com/_XTUzb">카카오톡 문의하기</a>
          </Info>
        </Bottom>
      </AppContainer>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  width: 100%;
  padding: 48px 0;
  background-color: ${theme.colors.gray[900]};
`

const Logo = styled.img`
  width: 118px;
  height: auto;

  ${media.mediumM`
    margin-bottom: 48px;
  `}
`

const Top = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 48px;

  ${media.mediumM`
        display: block;
    `}
`

const TopBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  ${media.mediumM`
        justify-content: flex-start;
    `}
`

const FooterItem = styled.div`
  margin-right: 60px;

  &:last-child {
    margin-right: 0;
  }

  ${media.mediumM`
		flex-basis: 50%;
		margin-right: 0;
		margin-bottom: 16px;
		&:last-child {
			margin-bottom: 0;
		}
    `}
`

const FooterItemTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 16px;
`

const FooterItemMenu = styled.ul`
  list-style-type: none;
  padding: 0 32px 0 0;
  margin: 0;
`

const FooterItemMenuItem = styled.li`
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & > a {
    display: inline-block;
    color: ${theme.colors.gray[50]};
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    line-height: normal;
    transition: 0.2s ease;
    &:hover {
      text-decoration: underline;
    }
  }

  ${({ isbutton }) =>
    isbutton == 'Y' &&
    `
        a{
            display: inline-flex;
            align-items: center;
            line-height: 1;
            margin-top: 8px;
            white-space: nowrap;
            color: ${theme.colors.gray[400]};
            background-color: ${theme.colors.gray[800]};
            border-color: ${theme.colors.gray[800]};
            color: #B0B8C1;
            border-radius: 6px;
            padding: 4px 12px;
                
            &:hover, &:active {
                text-decoration: none;
                background-color: ${theme.colors.gray[700]};
                border-color: ${theme.colors.gray[700]};
            }
        }

        svg {
            margin-right: 8px;
            width: 16px;

            path {
                fill: #B0B8C1;
            }
        }
  `}
`

const Bottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 40px;
  border-top: 1px solid ${theme.colors.gray[800]};

  ${media.mediumM`
    flex-direction: column-reverse;
  `}
`

const CopyRightWrapper = styled.div`
  display: flex;
  align-items: top;
  margin-right: 60px;

  ${media.mediumM`
    flex-direction: column-reverse;
  `}
`

const CopyRight = styled.div`
  flex: 0 0 auto;
  color: ${theme.colors.gray[700]};
  font-size: 13px;
  font-weight: 700;
  line-height: 150%;
  margin-right: 24px;

  a {
    color: #292f38;
    text-decoration: none;
    opacity: 0.5;
  }
`

const Info = styled.div`
  max-width: 500px;
  flex: 0 0 auto;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 700;
  word-break: keep-all;

  ${media.mediumM`
    margin-bottom: 24px;
  `}

  a {
    color: ${theme.colors.gray[600]};
    text-decoration: none;
    transition: 0.2s ease;
    &:hover {
      color: ${theme.colors.gray[300]};
    }
  }

  .app-hide-in-mobile {
    ${media.mediumM`
        display: none;
    `}
  }

  .app-show-in-mobile {
    display: none;

    ${media.mediumM`
        display: block;
    `}
  }
`

export default LandingMainFooter
