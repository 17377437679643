import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { groupBy, sortBy } from 'lodash-es'
import moment from 'moment'
import CalendarContext from 'contexts/calendarContext'
import NoteCalendarScheduleItem from './NoteCalendarScheduleItem'

const NoteCalendarScheduleDay = ({ type }) => {
  const {
    state: { schedules, currentDate, viewMode },
  } = useContext(CalendarContext)

  const formatedCurrentDate = currentDate.clone().format('YYYY-MM-DD')

  const getComputedSchedules = (
    schedules,
    formatedCurrentDate,
    viewMode,
  ) => {
    const sortedSchedules = sortBy(schedules, 'date')
    const groupedSchedules = groupBy(sortedSchedules, 'date')
    const arrayedSchedules = Object.entries(groupedSchedules).map(
      (item) => {
        return {
          date: item[0],
          list: item[1],
        }
      },
    )
    const filteredSchedules = arrayedSchedules.filter((o) => {
      if (
        moment(o.date).get('month') !==
        moment(formatedCurrentDate).get('month')
      )
        return
      return o
    })
    // const filteredSchedules = arrayedSchedules.filter(
    //   (o) => o.date >= formatedCurrentDate,
    // )

    if (viewMode === 'calendar') {
      return filteredSchedules.filter(
        (o) => o.date === formatedCurrentDate,
      )
    }

    return filteredSchedules
  }

  const computedSchedules = useMemo(
    () =>
      getComputedSchedules(schedules, formatedCurrentDate, viewMode),
    [schedules, formatedCurrentDate, viewMode],
  )

  return (
    <StyledSchedule
      type={type}
      card={localStorage.getItem('notemode') != 'sheet'}
    >
      {computedSchedules.length === 0 && (
        <>
          <Title>
            <TitleDay isToday={moment(currentDate) === moment()}>
              {moment(currentDate).format('Do')}
            </TitleDay>
            <TitleWeek isToday={moment(currentDate) === moment()}>
              &#40;
              {moment(currentDate).format('dd')}&#41;
            </TitleWeek>
          </Title>
          <ListWrapEmpty>일정이 없습니다.</ListWrapEmpty>
        </>
      )}

      {computedSchedules.length > 0 &&
        computedSchedules.map((groupItem, i) => {
          const isToday = moment(groupItem.date) === moment()

          return (
            <React.Fragment key={i}>
              <Title>
                <TitleDay active={isToday}>
                  {moment(groupItem.date).format('Do')}
                </TitleDay>

                <TitleWeek active={isToday}>
                  &#40;
                  {moment(groupItem.date).format('dd')}&#41;
                </TitleWeek>
              </Title>

              <ListWrap>
                <ul>
                  {groupItem.list.map((item, i) => (
                    <NoteCalendarScheduleItem
                      key={item.calendar_srl}
                      currentDate={currentDate}
                      item={item}
                    />
                  ))}
                </ul>
              </ListWrap>
            </React.Fragment>
          )
        })}
    </StyledSchedule>
  )
}

const StyledSchedule = styled.div`
  display: flex;
  padding: 24px 0 10px 0;

  ${({ card }) =>
    card &&
    `
    padding: 16px;
  `}

  ${media.mediumS`
    padding: 20px 4px;
    flex-direction: column;
    gap: 12px;

    
    ${({ type }) =>
      type != 'dashboard' &&
      `
        padding-bottom: 100px;
    `}
  `}
`

const Title = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  font-weight: 500;
  padding-right: 20px;
`

const TitleWeek = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`
const TitleDay = styled.div`
  font-weight: 600;
  line-height: 100%;
  font-size: 14px;
`

const ListWrap = styled.div`
  width: calc(100% - 72px);
  flex: 1;
`
const ListWrapEmpty = styled.div`
  flex: 1;
  font-size: 14px;
  line-height: 100%;
`

export default NoteCalendarScheduleDay
