import { Modal } from 'components/design'

/**
 * 네이버 매물 등록 전 동/호 입력 필수 모달
 * @returns
 */
const NoteDetailDonghoRequiredModal = ({
  visible = false,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      title="이 건물의 동/호를 선택해주세요."
      visible={visible}
      onCancel={onCancel}
      width={450}
      onOk={onOk}
      okButtonProps={{ color: 'blue' }}
      cancelHide
      hiddenClose
    >
      집합건물은 동/호까지 선택되어야 네이버에 매물을 등록할 수 있어요.
      <br /> 동/호는 매물 등록을 위한 것으로 네이버 광고 화면에 노출되지
      않습니다.
    </Modal>
  )
}

export default NoteDetailDonghoRequiredModal
