import { useContext } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import BaseContext from 'contexts/baseContext'
import { rgba } from 'polished'

const StyledIcon = styled.button`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: 36px;
  min-height: 36px;
  padding: 8px;

  background: transparent;
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 0;

  &:last-child {
    margin-right: 0;
  }

  & > i,
  & > ion-icon {
    font-size: 1.125rem;
  }
  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover,
  &:active {
    color: ${theme.colors.gray[600]};
  }

  ${({ gray }) =>
    gray &&
    `
    color: ${theme.colors.gray[500]};
    &:hover,
    &:active {
      color: ${theme.colors.gray[600]};
    }
  `}

  ${({ inverse }) =>
    inverse &&
    `
    color: white;
    &:hover,
    &:active {
      color: ${rgba('white', 0.7)};
    }
  `}

  ${({ large }) =>
    large
      ? `
    & > i {
      font-size: 1.25rem;
    }
    & > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  `
      : ''}
`
const HeaderIcon = ({ onClick, children, inverse, gray, large }) => {
  const {
    state: { headerInverse },
  } = useContext(BaseContext)

  return (
    <StyledIcon
      gray={gray}
      large={large}
      inverse={headerInverse || inverse}
      onClick={onClick}
    >
      {children}
    </StyledIcon>
  )
}

export default HeaderIcon
