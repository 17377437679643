import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import MapContext from 'contexts/map'
import MapFavoriteMarkerItem from './MapFavoriteMarkerItem'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { useSelector } from 'react-redux'
import useIsMobile from 'lib/hooks/useIsMobile'
import theme from 'lib/styles/theme'
import useAxios from 'lib/hooks/useAxios'
import {
  resetClickedMarker,
  resetOpenedMarker,
  setOpenedMarkerAll,
  resetManuallyCloseMarker,
} from 'modules/note'
import { useDispatch } from 'react-redux'
import { getPolygon } from 'lib/api/note/noteApi'
import { useNoteMarker } from 'lib/hooks/useNoteMarker'
import MapFavoriteNumberMarkerItem from './MapFavoriteNumberMarkerItem'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import useWindowSize from 'lib/hooks/useWindowSize'

const MapFavoriteMarkerContainer = ({
  polygon,
  setPolygon,
  resetPolygon,
  hide,
}) => {
  const isMobile = useIsMobile()
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map, viewState, layerState } = state
  const [onRequestPolygon, , dataPolygon, , ,] = useAxios(getPolygon)
  const prevLevel = useRef(null)
  const { channelSrl, addressSrl } = useNoteMatch()

  const [overlays, setOverlays] = useState([]) // 마커 배열

  const viewAllChannelNotes = useSelector(
    (state) => state.note.viewAllChannelNotes,
  )
  const filterStates = useSelector((state) => state.note.filterStates)
  const noteMarkers = useSelector((state) => state.note.noteMarkers)
  const clickedMarker = useSelector((state) => state.note.clickedMarker)
  const specificOpenedMarker = useSelector(
    (state) => state.note.specificOpenedMarker,
  )
  const openedMarker = useSelector((state) => state.note.openedMarker)
  const manuallyCloseMarker = useSelector(
    (state) => state.note.manuallyCloseMarker,
  )
  const stateNotes = useSelector((state) => state.note.notes)
  const dispatch = useDispatch()

  const { searchNoteMarkers } = useNoteMarker()

  const size = useWindowSize()

  // 화면상에 표현할 마커의 최대 수 (나누는 수가 높을수록 작아짐)
  const limit = (size.width * size.height) / 200000

  const centerPoint = useSelector((state) => state.summary.centerPoint)
  const isSummaryPage = useRouteMatch({
    path: ['/:id'],
  })

  const { isJMapNote } = useNoteMatch()
  const isNoteListUrl = useRouteMatch('/note/v2/notes/:channelSrl')

  useEffect(() => {
    return () => {
      resetPolygon()
    }
  }, [channelSrl])

  /**
   * 매물 마커 api 조회
   */
  useEffect(() => {
    //매물관리 페이지 아니면 매물노트에서는 마커 get api 호출하지 않음
    if (
      localStorage.getItem('notemode') != 'card' &&
      isJMapNote &&
      !isNoteListUrl?.isExact
    )
      return

    //summary 페이지인 경우 summary 데이터가 바인딩 된 후 마커 로드(중복 방지)
    if (isSummaryPage && isSummaryPage?.isExact) {
      if (isSummaryPage.url != '/home' && !centerPoint) {
        return
      }
    }

    if (!specificOpenedMarker || layerState.favorite) {
      async function fetchNoteMarkers() {
        await searchNoteMarkers()
      }
      fetchNoteMarkers()
    } else {
      // 마커 초기화
      setOverlays([])
      // 기존 폴리곤 삭제
      polygon?.setMap(null)
    }
  }, [
    viewState,
    channelSrl,
    viewAllChannelNotes,
    stateNotes,
    filterStates,
    layerState.favorite,
    specificOpenedMarker,
    isNoteListUrl?.isExact,
  ])

  useEffect(() => {
    if (!noteMarkers) return

    // 기존 데이터에 덮어씌운다.
    setOverlays(noteMarkers)
  }, [noteMarkers])

  /**
   * 인접한 마커 구하기
   */
  const getAdjacentMarkers = (items) => {
    if (viewState.level < 4) {
      let contents = []
      let marker_count = 0

      items.map((item, idx) => {
        let marker_type = 'customoverlay'
        let nearby_marker_exists = false
        let nearby_distance = 0.0009

        if (state.viewState.level === 2) {
          nearby_distance = 0.0004
        } else if (state.viewState.level === 1) {
          nearby_distance = 0.0002
        }

        // 마커 갯수를 초과하면 나머지는 점으로 표시합니다
        if (marker_count > limit) {
          marker_type = 'dotoverlay'
        }

        // 근거리에 다른 마커가 이미 있으면 점으로 표시합니다
        contents.map((item2) => {
          if (
            item2.marker_type === 'customoverlay' &&
            item2.lat > item.latitude - nearby_distance &&
            item2.lat < item.latitude + nearby_distance &&
            item2.lon > item.longitude - nearby_distance &&
            item2.lon < item.longitude + nearby_distance
          ) {
            nearby_marker_exists = true
          }
        })

        if (nearby_marker_exists) {
          marker_type = 'dotoverlay'
        }

        contents.push({
          marker_type: marker_type,
          lat: item.latitude,
          lon: item.longitude,
          hide: false,
          ...item,
        })
      })

      return contents
    } else {
      return items
    }
  }

  useEffect(() => {
    if (!specificOpenedMarker) return
    resetPolygon()
  }, [specificOpenedMarker])

  //마커 메모이제이션
  const markers = useMemo(
    () => getAdjacentMarkers(overlays),
    [overlays],
  )

  useEffect(() => {
    //매물시트에서 클릭한 마커가 있으면 클릭한 마커 초기화
    if (specificOpenedMarker) {
      dispatch(
        resetOpenedMarker({
          except: [specificOpenedMarker.address_srl],
        }),
      )
      dispatch(
        resetClickedMarker({
          except: specificOpenedMarker.address_srl,
        }),
      )
      return
    }

    /**
     * 인접 마커 계산이 끝나고 나면, 열고 닫기를 유지할 마커 선정
     */
    if (viewState.level != 1) {
      if (prevLevel.current == 1 && viewState.level == 2) {
        //지도 레벨이 1이 아닌 경우, 마커 아무것도 열지 않음
        dispatch(resetOpenedMarker())
        dispatch(resetClickedMarker())
        dispatch(resetManuallyCloseMarker())
      } else {
        dispatch(resetOpenedMarker({ except: clickedMarker }))
        dispatch(resetManuallyCloseMarker())
      }
    } else {
      let openedMarkers

      if (markers.length > 10) {
        openedMarkers = []
      } else {
        openedMarkers = markers
          .filter(
            ({ marker_type, address_srl }) =>
              marker_type != 'dotoverlay' &&
              !manuallyCloseMarker.includes(address_srl),
          )
          .map(({ address_srl }) => address_srl)
      }
      //열린 마커 클릭한 마커에 세팅
      dispatch(setOpenedMarkerAll([...openedMarkers, ...clickedMarker]))
    }
  }, [markers])

  /**
   * 폴리곤 세팅
   */
  useEffect(() => {
    if (!polygon) return

    polygon.setMap(map)

    return () => {
      // 기존 폴리곤 삭제
      polygon?.setMap(null)
    }
  }, [polygon])

  /**
   * address_srl이 바뀔 때 DB에서 polygon 정보 불러와서 세팅하기
   */
  useEffect(() => {
    // 선택된 주소가 바뀌면 폴리곤 초기화
    setPolygon(null)

    if (!addressSrl || isMobile) return

    onRequestPolygon({
      address_srl: addressSrl,
    })
  }, [addressSrl])

  useEffect(() => {
    if (!dataPolygon || !dataPolygon.result) return
    handleSetPolygon(null, dataPolygon.result)
  }, [dataPolygon])

  /**
   * 주소 필지 폴리곤 세팅
   * @param {*} e
   * @param {*} item
   */
  const handleSetPolygon = (e = null, item) => {
    e?.stopPropagation()
    // 폴리곤 세팅
    const shape = item.shape?.coordinates || []
    const polygonPath = shape?.map((part) => {
      return part.map((point) => {
        return new kakao.maps.LatLng(point[0], point[1])
      })
    })
    // 지도에 표시할 다각형을 생성합니다
    const polygon = new kakao.maps.Polygon({
      path: polygonPath, // 그려질 다각형의 좌표 배열입니다
      strokeWeight: 1, // 선의 두께입니다
      strokeColor: theme.colors.buildingPolygonColor, // 선의 색깔입니다
      strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      strokeStyle: 'solid', // 선의 스타일입니다
      fillColor: theme.colors.buildingPolygonColor, // 채우기 색깔입니다
      fillOpacity: 0.5, // 채우기 불투명도 입니다
      zIndex: 1,
    })

    setPolygon(polygon)
  }

  if (hide) return null

  // 숫자 마커
  if (viewState.level >= 4)
    return (
      <>
        {markers.map((item, i) => (
          <React.Fragment key={i}>
            <MapFavoriteNumberMarkerItem item={item} />
          </React.Fragment>
        ))}
      </>
    )

  // 일반 매물 마커
  if (viewState.level < 4)
    return (
      <>
        {markers.map((item, i) => (
          <div
            onClick={(e) => handleSetPolygon(e, item)}
            key={item.note_srl}
          >
            <MapFavoriteMarkerItem
              item={item}
              resetPolygon={resetPolygon}
            />
          </div>
        ))}
      </>
    )
}

export default MapFavoriteMarkerContainer
