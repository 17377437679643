import styled from 'styled-components'
import theme from 'lib/styles/theme'

import ReactPriceTableItem from './RealPriceTableItem'
import { List } from 'components/design'

const StyledList = styled.ul`
  /* padding-top: 1rem; */
`

const RealPriceTable = ({ columns, dataSource, children }) => {
  return (
    <List>
      {dataSource.map((item, index) => {
        return <ReactPriceTableItem item={item} key={index} />
      })}
    </List>
  )
}

export default RealPriceTable
