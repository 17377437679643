import axios from 'axios'

/**
 * 네이버 매물 형식 변환
 */
export const convertNoteToNaver = async (
  { _rx_csrf_token, channel_srl, note_srl, plan_srl, address_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/convert',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
      plan_srl,
      address_srl,
    },
    options,
  )

/**
 * 개별 컬럼 저장
 */
export const updateAdListing = async (
  { _rx_csrf_token, ad_srl, update_packets, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/update',
    {
      _rx_csrf_token,
      ad_srl,
      plan_srl,
      update_packets,
    },
    options,
  )

/**
 * 폼 드롭다운 옵션
 */
export const getOptions = async ({ _rx_csrf_token }, options) =>
  await axios.post(
    '/jmapnote/api/ad/options',
    {
      _rx_csrf_token,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )

/**
 * 사진, 파일 업로드
 */
export const uploadFiles = async (
  { _rx_csrf_token, ad_srl, type, fileToUpload, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/ad/uploadAdFile',
    {
      _rx_csrf_token,
      ad_srl,
      type,
      fileToUpload,
      plan_srl,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )

/**
 * 사진 삭제
 */
export const deleteAdFile = async ({
  _rx_csrf_token,
  ad_srl,
  file_srl,
  type,
  plan_srl,
}) =>
  await axios.post('/jmapnote/api/ad/deleteAdFile', {
    _rx_csrf_token,
    ad_srl,
    file_srl,
    type,
    plan_srl,
  })

/**
 * 사진 순서 변경
 */
export const changeOrderAdImage = async ({
  _rx_csrf_token,
  ad_srl,
  file_srls,
  plan_srl,
}) =>
  await axios.post('/jmapnote/api/ad/changeOrderAdImage', {
    _rx_csrf_token,
    ad_srl,
    file_srls,
    plan_srl,
  })
