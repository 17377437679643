import { useEffect, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from 'Constants'
import { sortFolders } from 'lib/api/note/folderApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import useFolders from 'pages/jmapnote/noteList/hooks/useFolders'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { ListItem, ListItemLabel } from 'components/design'
import styled from 'styled-components'

const StyledListItem = styled(ListItem)`
  ${({ isDragging }) =>
    isDragging &&
    `
      opacity: 0.5;
      background-color:  ${theme.colors.primary[50]};
    `}
`

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;

  color: ${theme.colors.gray[600]};
  font-size: 1rem;
  background-color: ${theme.colors.gray[100]};
  border-radius: ${theme.borderRadius[1]};
`

const IconWrap = styled.div`
  display: flex;
  align-items: flex-end;
`

const EditIconWrap = styled.div`
  color: ${theme.colors.gray[500]};
  font-size: 0.95rem;
`

const ListItemHandle = styled.div`
  cursor: grab;
  flex: 0 0 auto;
  color: ${theme.colors.gray[400]};
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: ${theme.colors.gray[500]};
  }
  ${({ isDragging }) =>
    isDragging &&
    `
      opacity: 0.5;
      background-color: red;`}
`
const SettingsFolderListItem = ({
  item,
  index,
  setSelectedFolderItem,
  setVisible,
  moveItem,
}) => {
  const ref = useRef(null)

  const { _getFolders, folders } = useFolders()
  const { channelSrl } = useNoteMatch()
  const [onRequestSave, , dataSave] = useAxios(sortFolders)

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.noteFolder,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveItem(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.noteFolder,
    item: {
      folderSrl: item.folder_srl,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      onRequestSave({
        channelSrl: channelSrl,
        folderSrlArr: folders?.map(({ folder_srl }) => folder_srl),
      })
    },
  })

  useEffect(() => {
    if (!dataSave) return

    if (dataSave.message === 'success') _getFolders({ channelSrl })
  }, [dataSave])

  const onClickItem = (target) => {
    setSelectedFolderItem(target)
    setVisible(true)
  }

  preview(drop(ref))

  return (
    <StyledListItem
      ref={ref}
      isDragging={isDragging}
      key={item.folder_srl}
      prepend={
        <IconWrap>
          <ListItemHandle ref={drag} data-handler-id={handlerId}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </ListItemHandle>
          <StyledIcon>
            <i className={item.icon || 'far fa-folder'} />
          </StyledIcon>
        </IconWrap>
      }
      dense
      onClick={() => onClickItem(item)}
    >
      <ListItemLabel small>{item.name}</ListItemLabel>
    </StyledListItem>
  )
}

export default SettingsFolderListItem
