import axios from 'axios'

/**
 * 올인원 요금제 생성 시 채널 불러오기
 */
export const getChannelsForAD = async ({ _rx_csrf_token }) =>
  await axios.post('/jmapnote/api/channel/selectAD', {
    _rx_csrf_token,
  })

/**
 * 공지사항 가져오기
 */
export const getInformations = async ({ _rx_csrf_token, plan_srl }) =>
  await axios.post('/jmapnote/api/ad/information', {
    _rx_csrf_token,
    plan_srl,
  })

/**
 * 매물 3뎁스의 광고 관리 탭
 * 리스트 가져오기
 */
export const getAdListingByNoteSrl = async ({
  _rx_csrf_token,
  plan_srl,
  note_srl,
}) =>
  await axios.post('/jmapnote/api/ad/listForNote', {
    _rx_csrf_token,
    plan_srl,
    note_srl,
  })

/**
 * 광고 목록에서 주소 추가하기
 * 사용자가 임시로 입력한 광고매물 정보 저장
 */
export const insertTempAd = async ({
  _rx_csrf_token,
  plan_srl,
  address_id,
  display_address,
  dong,
  ho,
  contract_area,
  exclusive_area,
  land_area,
  total_floor_area,
  building_area,
  property_type,
}) =>
  await axios.post('/jmapnote/api/ad/tempAd', {
    _rx_csrf_token,
    plan_srl,
    address_id,
    display_address,
    dong,
    ho,
    contract_area,
    exclusive_area,
    land_area,
    total_floor_area,
    building_area,
    property_type,
  })

/**
 * 시트 광고 수정
 */
export const simpleUpdateAd = async ({
  _rx_csrf_token,
  ad_srl,
  plan_srl,
  address_id,
  display_address,
  dong,
  ho,
  property_type,
  contract_type,
  sale_price,
  deposit,
  rent,
  contract_area,
  exclusive_area,
  direction,
  eupmyeondong,
  jibun_num,
  full_address,
  doro_address,
}) =>
  await axios.post('/jmapnote/api/ad/simpleUpdate', {
    _rx_csrf_token,
    ad_srl,
    plan_srl,
    address_id,
    display_address,
    dong,
    ho,
    property_type,
    contract_type,
    sale_price,
    deposit,
    rent,
    contract_area,
    exclusive_area,
    direction,
    eupmyeondong,
    jibun_num,
    full_address,
    doro_address,
  })

/**
 * 광고관리에서 note와 ad 한꺼번에 생성
 * 주소, 종류, 거래구분, 가격이 모두 입력되었을때 작동시켜야함
 */
export const createNoteWithNaver = async ({
  _rx_csrf_token,
  ad_srl,
  plan_srl,
  address_id,
  channel_srl,
  display_address,
  dong,
  ho,
  property_type,
  contract_area,
  exclusive_area,
  contract_type,
  sale_price,
  deposit,
  rent,
}) =>
  await axios.post('/jmapnote/api/ad/create', {
    _rx_csrf_token,
    channel_srl,
    ad_srl,
    plan_srl,
    address_id,
    display_address,
    dong,
    ho,
    property_type,
    contract_area,
    exclusive_area,
    contract_type,
    sale_price,
    deposit,
    rent,
  })

/**
 * 올인원 초대 시 일할 계산
 * @param plan_srl
 * @param type insert 또는 delete
 */
export const calcCostByDay = async ({
  _rx_csrf_token,
  plan_srl,
  type,
}) =>
  await axios.post('/jmapnote/api/planuser/cost', {
    _rx_csrf_token,
    plan_srl,
    type,
  })
