import { uniq } from 'lodash-es'
import { useMemo } from 'react'

const ContactSearchContainer = ({
  children,
  searchQuery,
  contacts,
}) => {
  const getSearchResult = (searchQuery, contacts) => {
    // 검색 쿼리가 없을 경우 그대로 반환
    if (!searchQuery) return contacts

    let results = []

    // alias로 필터링
    const filteredContactsByAlias = contacts.filter(
      (e) => e.alias == searchQuery,
    )
    // number로 필터링
    const filteredContactsByNumber = contacts.filter((e) => {
      // numbers 내에 쿼리와 매칭되는 number가 있을 경우 true
      const numberMatched = e.numbers.find((numberItem) => {
        // 하이픈(-) 유무와 상관없이 검색 가능하도록 replace
        const formatedNumber = numberItem.number.replaceAll('-', '')
        const formatedSearchQuery = searchQuery.replaceAll('-', '')
        // 연락처 번호에 searchQuery와 동일한 문자가 '속해'있는지 검증
        return formatedNumber.includes(formatedSearchQuery)
      })
      if (!numberMatched) return false

      return true
    })

    // 이름으로 필터링한 결과가 상단에 위치하도록 하여 병합
    results = [...filteredContactsByAlias, ...filteredContactsByNumber]

    // 중복을 제거
    return uniq(results)
  }

  const searchResults = useMemo(
    () => getSearchResult(searchQuery, contacts),
    [searchQuery, contacts],
  )

  return children({ searchResults })
}

export default ContactSearchContainer
