import axios from 'axios'

export const getLoginInfo = async () =>
  await axios.get('/map/api/getLoginInfo')

/**
 * 회원가입
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const procMemberInsert = async (
  {
    _rx_csrf_token,
    member_srl,
    user_id,
    password,
    email_address,
    email_id,
    email_host,
    phone_number,
    phone_country,
    phone_type,
    user_name,
    nick_name,
    find_account_question,
    find_account_answer,
    homepage,
    blog,
    birthday,
    allow_mailing,
    allow_message,
    is_admin,
    denied,
    status,
    regdate,
    ipaddress,
    last_login,
    last_login_ipaddress,
    change_password_date,
    limit_date,
    description,
    extra_vars,
    company_name,
    realtor_number,
    realtor_position,
    company_address_num,
    company_address_road,
    company_address_name,
    company_address_detail,
    realtor_certificate,
    group_srl,
    accept_agreement,
    referral_code,
    success_return_url,
    member_type,

    // company_ceo,
    // company_number,
    // company_registration,
    // company_phone,
    // business_area,
    // business_years,
    // company_work_type,
    // company_work_area,
    // company_intro,
  },
  options,
) =>
  await axios.post(
    '/member/procMemberInsert',
    {
      _rx_csrf_token,
      member_srl,
      user_id,
      password,
      email_address,
      email_id,
      email_host,
      phone_number,
      phone_country,
      phone_type,
      user_name,
      nick_name,
      find_account_question,
      find_account_answer,
      homepage,
      blog,
      birthday,
      allow_mailing,
      allow_message,
      is_admin,
      denied,
      status,
      regdate,
      ipaddress,
      last_login,
      last_login_ipaddress,
      change_password_date,
      limit_date,
      description,
      extra_vars,
      company_name,
      realtor_number,
      realtor_position,
      company_address_num,
      company_address_road,
      company_address_name,
      company_address_detail,
      realtor_certificate,
      group_srl,
      accept_agreement,
      referral_code,
      success_return_url,
      member_type,
      // company_ceo,
      // company_number,
      // company_registration,
      // company_phone,
      // business_area,
      // business_years,
      // company_work_type,
      // company_work_area,
      // company_intro,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )

/**
 * 전화번호 인증 코드 인증 확인
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const confirmVerificationSMS = async (
  { _rx_csrf_token, code },
  options,
) =>
  await axios.post(
    '/member/procMemberConfirmVerificationSMS',
    {
      _rx_csrf_token,
      code,
    },
    options,
  )

/**
 * 중복 아이디 확인
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const checkDupId = async (
  { _rx_csrf_token, user_id },
  options,
) =>
  await axios.post(
    '/member/procMemberCheckValue',
    {
      _rx_csrf_token,
      name: 'user_id',
      value: user_id,
    },
    options,
  )

/**
 * 중개업등록번호 검색
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const searchRealtorNumber = async (
  { _rx_csrf_token, formData, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/account/searchRealtorNumber',
    {
      _rx_csrf_token,
      plan_srl,
      ...formData,
    },
    options,
  )
