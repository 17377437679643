import useFormatArea from 'lib/hooks/useFormatArea'
import styled from 'styled-components'

const SummaryJeonyouListItem = ({ division, floor, purpose, area }) => {
  const formatedArea = useFormatArea(area)

  return (
    <tr>
      <td className="center">{division}</td>
      <td className="center">{floor}</td>
      <td>{purpose}</td>
      <td className="right">{formatedArea}</td>
    </tr>
  )
}

const Item = styled.div``

export default SummaryJeonyouListItem
