import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  options: null,
}

export const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setOptions(state, actions) {
      state.options = actions.payload
    },
  },
})

export const { setOptions } = FilterSlice.actions

export default FilterSlice.reducer
