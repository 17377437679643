import { useSelector } from 'react-redux'
import {
  Block,
  BlockTitle,
  CurrentPlanButton,
  List,
  ListItem,
  Price,
  PriceWrapper,
  ScheduledCancelText,
  StartButton,
} from './NotePaymentPlanListStyle'
import NotePaymentAllInOneConatiner from 'containers/notev2/NotePaymentAllInOneConatiner'
import styled from 'styled-components'
import { accessibleGroups, accessibleStaffGroups } from 'Constants'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import { getVisibleStartButton } from 'lib/utils/notev2/payment'
import { allInOnePrice } from 'lib/utils/notev2/util'
import { numberWithCommas } from 'utils'

/**
 * 요금제 선택 페이지
 * 올인원
 * @param onClickPayment 결제하기 method
 */
const NotePaymentPlanListAllInOne = ({ onClickPayment }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { isAllInOne } = useIsAllInOne()

  const isExpectedCancel = planInfo?.is_expected_cancel
  const isExpectedCancelAllInOne = isAllInOne && isExpectedCancel

  return (
    <NotePaymentAllInOneConatiner callPaymentModal={onClickPayment}>
      {({ onClickPaymentAllInOne, isStartAuth, auth, induceModal }) => {
        return (
          <>
            <Block active={isAllInOne}>
              <BlockTitle>
                올인원
                {isAllInOne && (
                  <CurrentPlanButton>현재 요금제</CurrentPlanButton>
                )}
                {isExpectedCancelAllInOne && (
                  <ScheduledCancelText>
                    요금제를 해지했습니다.
                  </ScheduledCancelText>
                )}
              </BlockTitle>
              <PriceWrapper>
                {loginInfo?.other_groups?.some((item) =>
                  accessibleGroups.includes(item),
                ) ? (
                  <>
                    <Price text=" / 1개월">
                      {numberWithCommas(allInOnePrice[1])}
                      <span>원</span>
                    </Price>
                    <Price text=" / 6개월">
                      {numberWithCommas(allInOnePrice[6])}
                      <span>원</span>
                    </Price>
                  </>
                ) : (
                  <Price>Coming Soon</Price>
                )}
              </PriceWrapper>
              {loginInfo?.other_groups?.some((item) =>
                accessibleGroups.includes(item),
              ) && (
                <>
                  {getVisibleStartButton(planInfo, '올인원') && (
                    <AllInOneButtonWrapper>
                      <StartButton
                        size="large"
                        expand
                        color={isAllInOne ? 'default' : 'blue'}
                        onClick={() => onClickPaymentAllInOne(1)}
                        nomargin
                      >
                        {isExpectedCancelAllInOne
                          ? '다시 시작하기(1개월)'
                          : '1개월 자동 정기 결제'}
                      </StartButton>
                      <StartButton
                        size="large"
                        expand
                        color={isAllInOne ? 'default' : 'blue'}
                        onClick={() => onClickPaymentAllInOne(6)}
                      >
                        {isExpectedCancelAllInOne
                          ? '다시 시작하기(6개월)'
                          : '6개월 자동 정기 결제'}
                      </StartButton>
                    </AllInOneButtonWrapper>
                  )}
                </>
              )}
              <List>
                <ListItem bold="Y">등기부등본 발급 가능</ListItem>
                <ListItem bold="Y">네이버 광고</ListItem>
                <ListItem>매물노트 이용</ListItem>
                <ListItem>계약서 작성</ListItem>
                <ListItem>확인설명서 작성</ListItem>
                <ListItem>동호관리 플러스단지 5개</ListItem>
              </List>
            </Block>
            {induceModal}
            {/* {inviteModal} */}
            {/* 본인인증 화면 */}
            {isStartAuth && auth}
          </>
        )
      }}
    </NotePaymentAllInOneConatiner>
  )
}

const AllInOneButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export default NotePaymentPlanListAllInOne
