import { Button } from 'components/design'
import { requestBillingInfo } from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import BillingPage from './Billing'
import moment from 'moment'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'

const NotePaymentPayFail = ({ fixed }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [onRequestBillingInfo, , dataBillingInfo] =
    useAxios(requestBillingInfo)
  const [billingResult, setBillingResult] = useState(null)
  const [visible, setVisible] = useState(true)

  const { isAllInOne } = useIsAllInOne()

  /**
   * 결제수단 변경 카드 등록
   */
  const onClickChangeCard = () => {
    const param = {
      order_name: planInfo?.payment_name,
      amount: planInfo?.price,
      type: '카드변경',
    }

    if (isAllInOne) {
      //올인원 요금제일때 추가 파라미터 세팅
      onRequestBillingInfo(
        Object.assign({}, param, {
          channel_srl: planInfo?.channel_srl,
          month: planInfo?.months,
        }),
      )
    } else {
      onRequestBillingInfo(param)
    }
  }

  useEffect(() => {
    if (!dataBillingInfo) return

    setBillingResult(dataBillingInfo?.result)
  }, [dataBillingInfo])

  //pop 닫기
  const onClose = (e) => {
    setVisible(false)
    localStorage.setItem('paymentErrMsgClosed', 'true')
  }

  return (
    visible && (
      <Wrapper fixed={fixed}>
        <Pop fixed={fixed}>
          <Title>
            <i className="fas fa-exclamation-triangle"></i>
            [결제 실패] 요금제가{' '}
            {moment(
              moment(planInfo?.expected_cancel_date, 'YYYYMMDDHHmmss'),
            ).diff(new Date(), 'day')}
            일 후 해지됩니다.
          </Title>
          <Content>
            {planInfo?.error} JOOTEK에서 내일 다시 결제를 시도할
            예정입니다. {planInfo?.payment_name} 요금제의 혜택을 계속
            이용하실 수 있도록 결제수단을 업데이트 해주세요.
          </Content>
          <ButtonWrapper>
            <StyledButton onClick={onClickChangeCard}>
              결제수단 변경하기
            </StyledButton>
            {fixed && (
              <StyledButton color="blue" onClick={onClose}>
                닫기
              </StyledButton>
            )}
          </ButtonWrapper>
        </Pop>

        {billingResult && (
          <BillingPage
            customerKey={billingResult.customer_key}
            setBillingResult={setBillingResult}
          />
        )}
      </Wrapper>
    )
  )
}

const Content = styled.div`
  max-width: 78%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: ${theme.colors.base.white};
  word-break: keep-all;

  ${media.smallPc`
    max-width: 75%;
  `}

  ${media.mediumM`
    max-width: 100%;
  `}
`

const StyledButton = styled(Button)``

const Pop = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 6px;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 12px;
  background-color: ${theme.colors.gray[900]};
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;

  ${media.mediumM`
    position: relative;
    top: initial;
    transform: none;
    right: initial;
    margin-top: 16px;
  `}
`

const Wrapper = styled.div`
  ${({ fixed }) =>
    fixed &&
    `
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999999;
    width: 1080px;
    max-width: 100%;
    padding: 0 40px;

    ${Pop} {
        background-color: ${theme.colors.gray[900]}E0;
    }

  `}

  ${media.mediumS`
    ${({ fixed }) =>
      fixed &&
      `
        padding: 0 20px;
    `}
`}
`

const Title = styled.div`
  color: ${theme.colors.base.white};
  line-height: 130%;
  margin-bottom: 12px;
  i {
    color: ${theme.colors.tint.danger};
    margin-right: 12px;
  }
`

export default NotePaymentPayFail
