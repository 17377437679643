import styled from 'styled-components'
import SummaryRpDetailTableItemContainer from 'containers/summary/SummaryRpDetailTableItemContainer'
import { useEffect, useState } from 'react'

const SummaryRpDetailTable = ({ title, data, noContainer }) => {
  return (
    <Wrapper noContainer={noContainer}>
      {title && <Title>{title}</Title>}
      {data.map((item, i, oriData) => {
        return (
          <SummaryRpDetailTableItemContainer
            key={i}
            data={item}
            oriData={oriData}
          />
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  ${({ noContainer }) =>
    noContainer &&
    `
      padding: 0;
  `}
`

const Title = styled.div`
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
`

export default SummaryRpDetailTable
