import { Modal } from 'components/design'
import { useSelector } from 'react-redux'
import { numberWithCommas } from 'utils'

/**
 * 요금제를 변경하시겠어요? 모달
 * @param {bool} visible
 * @param {function} onOk 확인 이벤트
 * @param {function} onCancel 취소 이벤트
 * @param {object} currentPlanInfo 현재 플랜 정보
 * @param {string} type 요금제
 * @param {string|number} amount 가격
 * @returns
 */
const NotePaymentChangeModal = ({
  visible,
  onOk,
  onCancel,
  type,
  amount,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  return (
    <Modal
      title="요금제를 변경하시겠어요?"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="변경"
      cancelText="취소"
      width={450}
      okButtonProps={{ color: 'blue' }}
    >
      {planInfo?.has_billingkey
        ? `${type} 요금제로 변경하시면 ${numberWithCommas(
            amount,
          )}원이 결제될 거예요.`
        : ''}
    </Modal>
  )
}

export default NotePaymentChangeModal
