import { feeOptions } from 'components/jmapnotev2/NoteAd/optionsFee'
import {
  Sum,
  SumPrice,
  Table,
  TableItem,
  TableTitle,
  TableValue,
} from './BdsDetailFeeModalType1'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 주택부동산 상세
 * 관리비 모달
 * 기타부과
 */
const BdsDetailFeeModalType2 = ({ data }) => {
  const type = feeOptions['부과타입']
  const standard = feeOptions['기타부과세부내역']
  const includes = feeOptions['포함항목']

  return (
    <>
      <Sum>
        <div>관리비 합계</div>
        <SumPrice>
          {numberWithCommas(data?.etcFeeDetails?.etcFeeAmount)}원
        </SumPrice>
      </Sum>
      <Table>
        <TableItem>
          <TableTitle>부과방식</TableTitle>
          <TableValue>{type?.[data?.chargeCodeType]}</TableValue>
        </TableItem>
        <TableItem>
          <TableTitle>세부내역타입</TableTitle>
          <TableValue>
            {standard?.[data?.etcFeeDetails?.detailCodeType]}
          </TableValue>
        </TableItem>
        <TableItem>
          <TableTitle>포함항목</TableTitle>
          <TableValue>
            {data?.etcFeeDetails.includeCodeTypes.map((item, i) => {
              return (
                <span>
                  {includes?.[item]}
                  {i !=
                    data?.etcFeeDetails.includeCodeTypes.length - 1 &&
                    ', '}
                </span>
              )
            })}
          </TableValue>
        </TableItem>
      </Table>
    </>
  )
}

export default BdsDetailFeeModalType2
