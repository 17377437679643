import styled from 'styled-components'
import { Desc, Section, Title } from './BdsFilterStyle'
import theme from 'lib/styles/theme'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import BdsContentFilterPropertyTypeItems from './BdsContentFilterPropertyTypeItems'
import { propertyFilterItem } from 'lib/utils/bds'

/**
 * 주택부동산
 * 필터 모달 - 매물유형
 */
const BdsContentFilterPropertyType = () => {
  const [openedDropdown, setOpenedDropdown] = useState([])
  const selectedFilter = useSelector(
    (state) => state.bds.selectedFilter,
  )

  const getCount = useCallback(
    (type) => selectedFilter.propertyType[type].length,
    [selectedFilter.propertyType],
  )

  const count = {
    아파트: getCount('아파트'),
    빌라: getCount('빌라'),
    원룸: getCount('원룸'),
    상가: getCount('상가'),
  }

  const getCountText = (type) => {
    return count[type] == propertyFilterItem[type].length
      ? '전체'
      : count[type]
  }

  /**
   * 각 영역 on/off 토글
   * @param {*} type
   */
  const onClickToggle = (type) => {
    if (openedDropdown.includes(type)) {
      setOpenedDropdown(openedDropdown.filter((item) => item != type))
    } else {
      setOpenedDropdown([...openedDropdown, ...[type]])
    }
  }

  return (
    <Section>
      <Title>매물유형</Title>
      <StyledDesc>
        {Object.values(selectedFilter.propertyType)
          .flat()
          .map((item, i) => {
            return (
              <span key={`property_desc_${i}`}>
                {i != 0 && ', '}
                {item}
              </span>
            )
          })}
      </StyledDesc>
      <Group>
        <Dropdown onClick={() => onClickToggle('아파트')}>
          <DropdownTitle>
            아파트·오피스텔
            <Count>{getCountText('아파트')}</Count>
          </DropdownTitle>
          <Icon className="far fa-angle-down" />
        </Dropdown>
        {openedDropdown.includes('아파트') && (
          <DropdownItems>
            <BdsContentFilterPropertyTypeItems type="아파트" />
          </DropdownItems>
        )}
      </Group>
      <Group>
        <Dropdown onClick={() => onClickToggle('빌라')}>
          <DropdownTitle>
            빌라·주택
            <Count>{getCountText('빌라')}</Count>
          </DropdownTitle>
          <Icon className="far fa-angle-down" />
        </Dropdown>
        {openedDropdown.includes('빌라') && (
          <DropdownItems>
            <BdsContentFilterPropertyTypeItems type="빌라" />
          </DropdownItems>
        )}
      </Group>
      <Group>
        <Dropdown onClick={() => onClickToggle('원룸')}>
          <DropdownTitle>
            원룸·투룸
            <Count>{getCountText('원룸')}</Count>
          </DropdownTitle>
          <Icon className="far fa-angle-down" />
        </Dropdown>
        {openedDropdown.includes('원룸') && (
          <DropdownItems>
            <BdsContentFilterPropertyTypeItems type="원룸" />
          </DropdownItems>
        )}
      </Group>
      <Group>
        <Dropdown onClick={() => onClickToggle('상가')}>
          <DropdownTitle>
            상가·업무·공장·토지
            <Count>{getCountText('상가')}</Count>
          </DropdownTitle>
          <Icon className="far fa-angle-down" />
        </Dropdown>
        {openedDropdown.includes('상가') && (
          <DropdownItems>
            <BdsContentFilterPropertyTypeItems type="상가" />
          </DropdownItems>
        )}
      </Group>
    </Section>
  )
}

const Group = styled.div`
  margin-bottom: 6px;
`

const StyledDesc = styled(Desc)`
  margin-top: -10px;
  margin-bottom: 6px;
`

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
  padding: 12px 16px;
  cursor: pointer;
`

const DropdownTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
`

const Count = styled.div`
  color: ${theme.colors.blue[400]};
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
`

const Icon = styled.div`
  font-size: 18px;
  color: ${theme.colors.gray[600]};
`

const DropdownItems = styled.div`
  display: flex;
  align-items: center;
  gap: 8px 6px;
  flex-wrap: wrap;
  padding: 12px 12px 18px 12px;
`

export default BdsContentFilterPropertyType
