import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledCustomUploadInput = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 2rem 1rem;
  /* margin-bottom: 1rem; */

  text-align: center;
  border: 1px dashed ${theme.colors.gray[200]};
  border-radius: ${theme.borderRadius[1]};

  & > label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > i {
      color: ${theme.colors.gray[500]};
      font-size: 2rem;
      margin-bottom: 0.75rem;
    }
  }

  &:hover {
    border-color: ${theme.colors.blue[400]};
    background-color: ${theme.colors.blue[50]};

    i {
      color: ${theme.colors.blue[400]};
    }
  }

  input {
    display: none;
  }
`

const StyledInputTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`

const StyledFileName = styled.div`
  margin-top: 0.25rem;
  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
`

const NoteUploadExcelInput = ({
  fileName,
  setFileName,
  onChange,
  inputRef,
  accept = '.xlsx',
}) => {
  const onChangeFile = (value) => {
    if (!value || value.length === 0) {
      setFileName('')
      return
    }

    setFileName(value?.[0]?.name)
    onChange(value)
  }

  return (
    <StyledCustomUploadInput>
      <label>
        <i className="fas fa-file-excel" />
        <StyledInputTitle>
          {accept == '.vcf' ? '파일 업로드' : '엑셀 업로드 (.xlsx)'}
        </StyledInputTitle>

        {fileName && <StyledFileName>{fileName}</StyledFileName>}

        <input
          type="file"
          accept={accept}
          ref={inputRef}
          style={{ display: 'none' }}
          onChange={(e) => onChangeFile(e.target.files)}
        />
      </label>
    </StyledCustomUploadInput>
  )
}

export default NoteUploadExcelInput
