import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NoteInviteRewardPayInfo = ({
  rewardedCount,
  expectedRewardedCount,
}) => {
  return (
    <InfoSection>
      <InfoTitle>지급 내역</InfoTitle>
      <InfoContent mb>
        <span>지급된 등기열람권</span>
        <span>{rewardedCount}개</span>
      </InfoContent>
      <InfoContent>
        <span>지급 대기 등기열람권</span>
        <span>{expectedRewardedCount}개</span>
      </InfoContent>
    </InfoSection>
  )
}

export const InfoSection = styled.div`
  margin-bottom: 28px;
`

export const InfoTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.base.white};
  margin-bottom: 8px;
`

export const InfoContent = styled.div`
  padding: 10px;
  border-radius: 8px;
  color: ${theme.colors.base.white};
  background-color: #4c4c4c;
  max-height: 194px;
  overflow: auto;
  min-height: 40px;

  ${({ mb }) =>
    mb &&
    `
    margin-bottom: 8px;
  `}

  span {
    line-height: 100%;
    font-size: 12px;
    &:first-child {
      font-weight: 500;
      margin-right: 12px;
    }

    &:last-child {
      font-weight: 600;
    }
  }
`
export default NoteInviteRewardPayInfo
