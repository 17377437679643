import { message } from 'antd'
import { saveAddressForSheet } from 'lib/api/notev2/listApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

const NoteDonghoHoDetailButtons = ({ data, ads, absolute }) => {
  const history = useHistory()
  const [onRequest, , dataCreate] = useAxios(saveAddressForSheet)
  const channels = useSelector((state) => state.channel.channels)
  const channelSrl = channels.find(
    ({ is_private }) => is_private == 'Y',
  ).channel_srl

  /**
   * 매물 버튼 클릭
   */
  const onClickNote = () => {
    if (data?.note_srl) {
      //매물 있으면 그리로 이동
      history.push(
        `/note/v2/notes/${data.channel_srl}/${data.address_srl}/${data.note_srl}`,
      )
    } else {
      //매물 없으면 기본채널에 매물 생성후 3뎁스 열기
      if (!data.address_id || !channelSrl) {
        message.error('잘못된 요청입니다.')
        return
      }

      onRequest({
        address_id: data.address_id,
        channel_srl: channelSrl,
        dong: data.dong_name,
        ho: data.ho_name,
        exclusive_area: data.exclusive_area,
        contract_area: data.supply_area,
        display_address: data.display_address,
        property_type: data.property_type,
      })
    }
  }

  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate?.error == 0) {
      message.success('매물이 등록되었습니다.')

      const result = dataCreate?.result

      if (
        !result?.channel_srl &&
        !result?.address_srl &&
        !result?.note_srl
      )
        return

      history.push(
        `/note/v2/notes/${result?.channel_srl}/${result?.address_srl}/${result?.note_srl}`,
      )
    }
  }, [dataCreate])

  /**
   * 광고 버튼 클릭
   */
  const onClickAd = () => {
    if (ads?.length > 0) {
      //광고 있으면 광고관리 주소로 검색
      history.push(`/note/v2/ad?query=${ads[0].address}`)
    }
  }

  return (
    <Wrapper absolute={absolute}>
      <StyledButton
        color="blue"
        onClick={onClickNote}
        expand={ads?.length == 0}
      >
        매물 {data?.note_srl ? '상세보기' : '등록하기'}
      </StyledButton>
      {/* {ads?.length > 0 && (
        <StyledButton color="blue" onClick={onClickAd}>
          광고 이력보기
        </StyledButton>
      )} */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
  height: 44px;
  width: 100%;
  margin-top: 16px;

  ${({ absolute }) =>
    absolute &&
    `
    position: absolute; 
    bottom:0;
    left: 0;
  `}
`

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 50%;
  height: 100%;
  background-color: ${theme.colors.blue[400]};
  color: ${theme.colors.base.white};
  font-size: 14px;
  font-weight: 700;
  line-height: 180%;
  cursor: pointer;

  ${({ expand }) =>
    expand &&
    `
    width: 100%;
  `}

  &::after {
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    font-size: 18px;
    font-weight: 400;
  }
`

export default NoteDonghoHoDetailButtons
