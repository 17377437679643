import { SheetButton } from 'components/jmapnotev2/style/NoteStyle'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import { ReactComponent as OpenIcon } from 'assets/icon/note/open.svg'
import { media } from 'lib/styles/theme'
import useIsMobile from 'lib/hooks/useIsMobile'

const CustomDocsAddressCell = ({
  note_srl,
  channel_srl,
  contract_srl,
  address,
}) => {
  const history = useHistory()
  const isMobile = useIsMobile()
  const onClickOpenDetail = useCallback(
    (e) => {
      e.preventDefault()
      history.push(
        `/note/v2/docs/${channel_srl}/${
          note_srl || 0
        }/edit/${contract_srl}`,
      )
    },
    [channel_srl, contract_srl],
  )

  return (
    <Wrapper>
      {address}

      <OpenDetailButton onClick={onClickOpenDetail}>
        <SheetButton size="medium">
          <OpenIcon />
          {!isMobile && <OpenText>열기</OpenText>}
        </SheetButton>
      </OpenDetailButton>
    </Wrapper>
  )
}

const OpenDetailButton = styled.div`
  position: absolute;
  right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s;

  ${media.mediumS`
    opacity: 1;
  `}
`

const Wrapper = styled.div`
  padding: 0 4px;
  overflow: hidden;

  &:hover {
    ${OpenDetailButton} {
      opacity: 1;
    }
  }

  ${media.mediumS`
    padding: 0 8px 0 14px;
    width: 80%;
  `}
`

const OpenText = styled.span`
  margin-left: 4px;
`
export default CustomDocsAddressCell
