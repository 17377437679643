import theme from 'lib/styles/theme'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
} from 'recharts'
import styled from 'styled-components'
import SummarySchoolGraphTooltip from './SummarySchoolGraphTooltip'
import CustomizedLegend from '../SummaryGraph/CustomizedLegend'
import { useState } from 'react'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'

/**
 * 학군 그래프 (중, 고등)
 */
const SummarySchoolGraph = ({ data, activeTab, avg }) => {
  const [graphMargin, setGraphMargin] = useState(30)
  const [graphHeight, setGraphHeight] = useState(290)

  const [calcData, setCalcData] = useState([])

  useUpdateEffect(() => {
    if (!data) return

    const newData = data
      .slice(0, 4)
      .map((item) => {
        const replaceMiddle =
          item?.school_name
            ?.replace('사범대학부속여자중학교', '사대부여중')
            ?.replace('사범대학부속중학교', '사대부중')
            ?.replace('여자중학교', '여중')
            ?.replace('학교', '') || ''
        const replaceHigh =
          item?.school_name
            ?.replace('사범대학부속여자고등학교', '사대부여고')
            ?.replace('사범대학부속고등학교', '사대부고')
            ?.replace('여자고등학교', '여고')
            ?.replace('등학교', '') || ''

        return Object.assign({}, item, {
          admission_rate: item.admission_rate * 100,
          ori_school_name: item.school_name,
          school_name:
            activeTab == 'middle' ? replaceMiddle : replaceHigh,
        })
      })
      .sort((a, b) => Number(a.distance) < Number(b.distance))

    setCalcData(newData)

    const hasLongStr = newData
      .map(({ school_name }) => school_name.length)
      .some((el) => el > 10)

    if (hasLongStr) {
      setGraphMargin(40)
      setGraphHeight(300)
    } else {
      setGraphMargin(30)
      setGraphHeight(290)
    }
  }, [data])

  const tickFormatter = (value) => `${parseFloat(value.toFixed(2))}%`

  const tooltipFormatter = (value) => {
    return calcData.find(({ school_name }) => school_name == value)
      ?.ori_school_name
  }

  function CustomizedTick(props) {
    const { x, y, payload } = props

    const length = payload.value.length
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          fill={theme.colors.gray[600]}
          style={{ fontSize: '12px' }}
        >
          <tspan textAnchor="middle" x="0">
            {payload.value.slice(0, 5)}
          </tspan>
          <tspan textAnchor="middle" x="0" dy={15}>
            {payload.value.slice(5, 10)}
          </tspan>
          <tspan textAnchor="middle" x="0" dy={15}>
            {payload.value.slice(10, 15)}
            {length > 18 && '...'}
          </tspan>
        </text>
      </g>
    )
  }

  return (
    <Wrapper>
      <ResponsiveContainer width="100%" height={graphHeight}>
        <ComposedChart
          data={calcData}
          margin={{
            top: 20,
            right: 10,
            bottom: graphMargin,
            left: -12,
          }}
          syncId="anyId"
        >
          <CartesianGrid
            vertical={false}
            stroke={theme.colors.gray[100]}
          />
          <XAxis
            tickLine={false}
            axisLine={false}
            interval={0}
            dataKey="school_name"
            dy={5}
            tick={<CustomizedTick />}
          />
          <YAxis
            tick={{ fill: theme.colors.gray[600], fontSize: '12px' }}
            tickLine={false}
            axisLine={false}
            dataKey="admission_rate"
            tickFormatter={tickFormatter}
            scale={'linear'}
            domain={[
              (dataMin) => (dataMin - 5 < 0 ? 0 : dataMin - 5),
              'dataMax',
            ]}
          />
          <Legend
            wrapperStyle={{
              bottom: 0,
              left: 8,
            }}
            payload={[
              {
                value: '시군구 평균',
                color: theme.colors.blue[500],
              },
              {
                value:
                  activeTab == 'middle'
                    ? '특목고 진학률'
                    : '대학 진학률',
                color: theme.colors.primary[500],
              },
            ]}
            content={<CustomizedLegend />}
          />

          <Bar
            barSize={34}
            radius={[6, 6, 0, 0]}
            dataKey="admission_rate"
            fill={theme.colors.primary[500]}
          />

          <Tooltip
            position={{ y: 0 }}
            isAnimationActive={false}
            cursor={{
              stroke: theme.colors.primary[400],
              strokeWidth: 1,
            }}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: theme.colors.gray[200],
              borderRadius: '8px',
              boxShadow: theme.shadow['card-2'],
              padding: '0.5rem 1rem',
            }}
            content={
              <SummarySchoolGraphTooltip
                tooltipFormatter={tooltipFormatter}
              />
            }
          />
          <ReferenceLine
            isFront
            ifOverflow="extendDomain"
            strokeDasharray="3 3"
            y={avg * 100}
            stroke={theme.colors.blue[500]}
          />
          {/* <Line
            type="linear"
            dataKey="sale"
            stroke={theme.colors.blue[500]}
            strokeWidth={3}
            dot={false}
            activeDot={true}
            connectNulls
            isAnimationActive={false}
          /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .recharts-cartesian-axis-tick {
    font-size: 12px;
    font-family: ${theme.font.family};
  }
`

export default SummarySchoolGraph
