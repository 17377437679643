import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'

import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/pagination/pagination.scss' // Pagination module
import { Pagination } from 'swiper'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * 주택 부동산 상세
 * 최상단 이미지 슬라이드
 * @param images 이미지 array
 */
const BdsHeaderImages = ({ images }) => {
  return (
    images &&
    images.length > 0 && (
      <ImageWrapper>
        <StyledSwiper
          loop={true}
          modules={[Pagination]}
          pagination={{
            type: 'fraction',
          }}
          slidesPerView={1}
        >
          {images.map(({ url }, i) => {
            return (
              <SwiperSlide key={`bds_images_${i}`}>
                <img src={url} />
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
      </ImageWrapper>
    )
  )
}

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;

  img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
`

const StyledSwiper = styled(Swiper)`
  height: 100%;

  .swiper-pagination {
    left: 50%;
    transform: translateX(-50%);
    padding: 2px 6px;
    width: auto;
    border-radius: 999px;
    background: rgba(0, 0, 0, 0.5);
    color: ${theme.colors.base.white};
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
  }
`

export default BdsHeaderImages
