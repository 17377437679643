import { Button } from 'components/design'
import { FormDesc } from 'components/jmapnotev2/NoteAd/form/NoteAdFormStyle'
import { media } from 'lib/styles/theme'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

const NoteAdStoreDesc = () => {
  const history = useHistory()
  const goMyPage = () => {
    history.push(`/note/v2/account`)
  }
  return (
    <Wrapper>
      <StyledFormDesc>
        <li>
          등록된 매물은 매물 정보와 함께 중개업소 정보도 함께
          노출됩니다.
        </li>
        <li>
          중개업소 정보를 변경하시려면 우측 회원정보 변경하기 버튼을
          클릭하세요.
        </li>
      </StyledFormDesc>
      <StyledButton color="blue" plain size="small" onClick={goMyPage}>
        회원정보 변경하기
      </StyledButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`
const StyledFormDesc = styled(FormDesc)`
  margin-bottom: 16px;
`

const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0;

  ${media.mediumS`
    top: -32px;
    bottom: initial;
  `}
`

export default NoteAdStoreDesc
