import { message } from 'antd'
import NotePaymentChangeModal from 'components/jmapnotev2/NotePayment/NotePaymentChangeModal'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { updatePlan } from 'lib/api/notev2/paymentApi'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'

/**
 * 요금제 변경 로직 모음
 * @returns
 */
const NotePaymentChangeContainer = ({ children }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const [type, setType] = useState('') //요금제명
  const [amount, setAmount] = useState(0) //가격
  const [allinoneMemberCount, setAllinoneMemberCount] = useState(0) //올인원 - 채널
  const [month, setMonth] = useState(0) //올인원 - 개월수
  const [billingResult, setBillingResult] = useState(null) //토스 결제 창 호출을 위한 state

  const { onRequest: onRequestUserLoader, data: afterUserLoader } =
    useUserLoader(false) //사용자 정보 로딩하는 hook

  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false) // 요금제 변경 하시겠어요? 모달 visible
  const [isDirectPayment, setIsDirectPayment] = useState(false)

  const [
    onRequestUpdatePlan,
    loadingUpdate,
    updateResult,
    errorUpdatePlan,
  ] = useAxios(updatePlan, {
    resetError: true,
  }) //요금제 업데이트

  const [isPaymentUpdateLoading, setIsPaymentUpdateLoading] =
    useState(false) //결제 관련 동작 중 로딩 창

  const history = useHistory()

  useEffect(() => {
    if (!updateResult) {
      setIsPaymentUpdateLoading(false)
      return
    }

    message.success('요금제가 변경되었어요.')
    setIsPaymentUpdateLoading(false)
    //사용자 정보 갱신
    onRequestUserLoader()
  }, [updateResult])

  useEffect(() => {
    if (!errorUpdatePlan) return

    setIsPaymentUpdateLoading(false)
  }, [errorUpdatePlan])

  useEffect(() => {
    if (!afterUserLoader) return
    if (afterUserLoader?.error == 0) {
      if (month || allinoneMemberCount) {
        history.push('/note/v2/ad')
      }
    }
  }, [afterUserLoader])

  /**
   * 요금제 수정 ok 클릭 시
   * 요금제 변경 API 호출
   */
  const handleChangePlan = () => {
    onRequestUpdatePlan({
      order_name: type,
      type: '요금제변경',
      amount: amount,
      default_count: 1,
      plan_srl: planInfo?.plan_srl,
      month: month,
      allinone_member_count: allinoneMemberCount,
    })
    setIsPaymentUpdateLoading(true)
    setIsOpenWarningModal(false)
  }

  useUpdateEffect(() => {
    if (!isDirectPayment) return
    handleChangePlan()
  }, [isDirectPayment])

  return children({
    billingResult,
    setType,
    setAmount,
    setMonth,
    setAllinoneMemberCount,
    setIsOpenWarningModal,
    setBillingResult,
    setIsDirectPayment,
    loading: isPaymentUpdateLoading,
    //요금제를 변경하시겠어요? 모달
    paymentChangeModal: (
      <NotePaymentChangeModal
        visible={isOpenWarningModal}
        onOk={handleChangePlan}
        onCancel={() => setIsOpenWarningModal(false)}
        type={type}
        amount={amount}
      />
    ),
  })
}

export default NotePaymentChangeContainer
