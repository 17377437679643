import styled from 'styled-components'
import {
  MainWrapper,
  MaxWidthWrapper,
} from 'components/jmapnotev2/style/NoteStyle'
import { media } from 'lib/styles/theme'
import Scrollbars from 'react-custom-scrollbars-2'
import MobileChannelHeader from 'components/jmapnotev2/channel/MobileChannelHeader'
import useIsMobile from 'lib/hooks/useIsMobile'
import NotePaymentTitle from 'components/jmapnotev2/NotePayment/NotePaymentTitle'
import NotePaymentContent from 'components/jmapnotev2/NotePayment/NotePaymentContent'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import NotePaymentContentAllInOne from 'components/jmapnotev2/NotePayment/NotePaymentContentAllInOne'

/**
 * 결제관리 메인
 */
const NotePayment = () => {
  const isMobile = useIsMobile()
  const { onRequest } = useUserLoader(false) //유저 정보 최초 갱신
  const { isAllInOne } = useIsAllInOne()

  return (
    <>
      {isMobile && <MobileChannelHeader channel={false} />}
      <StyledMainWrapper noside gray>
        <Scrollbars
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />
          )}
        >
          <ScrollbarWrapper>
            <StyledMaxWidthWrapper>
              <NotePaymentTitle />
              {isAllInOne ? (
                // 올인원 요금제
                <NotePaymentContentAllInOne />
              ) : (
                // 기본
                <NotePaymentContent />
              )}
            </StyledMaxWidthWrapper>
          </ScrollbarWrapper>
        </Scrollbars>
      </StyledMainWrapper>
    </>
  )
}

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  ${media.smallPc`
    width: 100%;
    max-width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`

const ScrollbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${media.smallPc`
    padding: 0 40px;
  `}

  ${media.mediumS`
    padding: 0 20px;
  `}
`

const StyledMainWrapper = styled(MainWrapper)`
  ${media.mediumM`
    width: 100%;
    left: 0;
    justify-content: center;
    padding-bottom: 20px;
  `};
`

export default NotePayment
