const pushToData = (list, type, payload, centerPoint) => {
  if (!payload) return

  const itemCenterPoint = payload?.centerpoint
    ? JSON.parse(payload?.centerpoint)
    : null
  // 중점 좌표를 기준으로 한 상대좌표 계산
  const x = centerPoint?.[1] - itemCenterPoint?.coordinates?.[1] || 0 // lng
  const y = centerPoint?.[0] - itemCenterPoint?.coordinates?.[0] || 0 // lat

  const result = {
    type,
    ...payload,
  }

  if (type === 'elementary_school') result.name = payload.school_name
  if (type === 'middle_school') result.name = payload.school_name
  if (type === 'high_school') result.name = payload.school_name
  if (type === 'bus_stop')
    result.name = payload.station_name + ' 정류장'
  if (type === 'subway_station') result.name = payload.station_name

  list.push(result)
}

export const getComputedData = (data, centerPoint) => {
  const centerPointCoords = centerPoint?.coordinates
  const response = data?.result
  const school = response?.school
  let result = []

  // 학교
  pushToData(
    result,
    'elementary_school',
    school?.elementary,
    centerPointCoords,
  )
  pushToData(result, 'middle_school', school?.middle, centerPointCoords)
  pushToData(result, 'high_school', school?.high, centerPointCoords)

  // 교통
  pushToData(result, 'bus_stop', response?.bus_stop, centerPointCoords)
  // 지하철은 여러 개
  if (Array.isArray(response?.subway_station)) {
    response?.subway_station?.map((subway) => {
      pushToData(result, 'subway_station', subway, centerPointCoords)
    })
  }

  pushToData(result, 'ic', response?.ic, centerPointCoords)

  // 의료
  if (response?.hospital?.length > 0) {
    response.hospital.forEach((e) => {
      pushToData(result, 'hospital', e, centerPointCoords)
    })
  }

  // 안전
  pushToData(result, 'police', response?.police, centerPointCoords)
  pushToData(result, 'fire', response?.fire, centerPointCoords)
  pushToData(result, 'cctv', response?.cctv, centerPointCoords)

  // 쇼핑
  if (response?.mart?.length > 0) {
    response.mart.forEach((e) => {
      pushToData(result, 'mart', e, centerPointCoords)
    })
  }

  // 편의
  pushToData(
    result,
    'restaurant',
    response?.restaurant,
    centerPointCoords,
  )
  pushToData(result, 'park', response?.park, centerPointCoords)

  return result
}
