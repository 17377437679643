import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptionsForObject } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 건축물대장상 용도
 */
const NoteAdColumnBuildingUseDaejang = ({
  columnKey,
  required,
  half,
}) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const usage = watch(columnKey)

  const adOptions = useSelector((state) => state.noteAd.adOptions)
  const [list, setList] = useState(null)

  useEffect(() => {
    if (!adOptions) return
    setList(adOptions.usage_codes)
  }, [adOptions])

  return (
    <ColumnSet half={half}>
      <ColumnTitle required={required}>
        건축물대장상
        <br />
        용도
      </ColumnTitle>
      <ColumnVal gap={15}>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: list?.[usage] || '',
            value: usage,
          }}
          isSearchable
          options={getSimpleSelectboxOptionsForObject(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register(columnKey)}
          onChange={(e) => {
            onUpdate(columnKey, e.value)
          }}
        />
        {columnKey == 'article.lawUsageCode' && (
          <ValidateWarn>
            {errors?.article?.lawUsageCode?.message}
          </ValidateWarn>
        )}
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnBuildingUseDaejang
