import { Modal } from 'components/design'
import { useSelector } from 'react-redux'

/**
 * 결제 실패시 취소 완료 모달
 * @returns
 */
const NotePaymentFailCancelDoneConfirm = ({
  visible = false,
  onOk,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  return (
    <Modal
      title={`${planInfo?.payment_name} 요금제 해지가 정상 처리되었습니다.`}
      visible={visible}
      onOk={onOk}
      width={450}
      okButtonProps={{ color: 'blue' }}
      cancelHide
      hiddenClose
      footer={!visible && null}
    >
      {visible && (
        <>
          즉시 프리요금제로 요금제가 자동 전환되며, 남은 등기부등본은
          사용이 제한됩니다.
          <br />
          <br />
          또한 친구 초대 이벤트 링크를 공유한 경우 더이상 이벤트를 통해
          친구를 초대할 수 없으며, 이벤트로 등기열람권을 지급받을 수
          없습니다.
        </>
      )}
    </Modal>
  )
}

export default NotePaymentFailCancelDoneConfirm
