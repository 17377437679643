export const getDocsWritePath = ({
  note_srl,
  channel_srl,
  address_id,
  dong,
  ho,
  property_type,
  contract_type,
  address_jibun,
  invalid_addr_name,
  total_price,
  rent_price,
  address_doro,
  is_group_building,
  presale_base_price,
  presale_option_price,
  presale_paid,
  presale_premium,
  presale_to_pay,
  presale_jeongsan_price,
}) => {
  const isSubtype = is_group_building == 'Y'
  const contractType = contract_type?.split(',')

  if (address_id) {
    return {
      property_type: getPropertyType(property_type, contract_type),
      contract_type:
        contractType?.filter((item) => item).length > 0
          ? contractType.sort()[0]
          : '매매',
      isSearchDocDatas: true,
      address_id,
      channel_srl,
      note_srl,
      sub_type: isSubtype ? '집합' : '일반',
      dong: dong,
      ho: ho,
      address_doro,
      address_jibun,
      total_price,
      rent_price,
      address_jibun,
      presale_base_price,
      presale_option_price,
      presale_paid,
      presale_premium,
      presale_to_pay,
      presale_jeongsan_price,
    }
  } else {
    //TODO: 유효하지 않은 주소 입력했을때 계약서 작성? - 주소만 세팅하고 다 빈값
    return {
      //계약서에서 받아 1.부동산의 표시에 세팅해줄 항목들
      property_type: getPropertyType(property_type, contract_type),
      contract_type:
        contractType?.filter((item) => item).length > 0
          ? contractType.sort()[0]
          : '',
      channel_srl,
      note_srl,
      isSearchDocDatas: false,
      address: invalid_addr_name,
      total_price,
      rent_price,
      address_jibun,
    }
  }
}

const getPropertyType = (value, contract_type) => {
  if (['원룸', '1.5룸', '투룸', '쓰리룸+'].includes(value)) {
    if (contract_type == '매매') {
      return '다세대주택'
    } else {
      return '다가구주택'
    }
  } else if (value == '단독주택') {
    return '주택'
  } else if (value == '아파트 분양권') {
    return '아파트분양권'
  } else if (value == '오피스텔 분양권') {
    return '오피스텔분양권'
  } else if (['고시원', '지식산업센터', '기타'].includes(value)) {
    return '건물'
  } else {
    return value
  }
}
