import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import { Button } from 'components/design'
import NoteDonghoDanjiSearchContainer from 'containers/notev2/NoteDonghoDanjiSearchContainer'
import theme from 'lib/styles/theme'
import { useRef } from 'react'
import styled from 'styled-components'
import NoteDonghoDanjiSearchList from './NoteDonghoDanjiSearchList'

const NoteDonghoDanjiSearch = ({ closeModal, searchRefresh }) => {
  const ref = useRef()

  return (
    <NoteDonghoDanjiSearchContainer
      closeModal={closeModal}
      searchRefresh={searchRefresh}
    >
      {({
        onSearch,
        addrList,
        onClickAddress,
        resetSearch,
        loadingSearch,
      }) => {
        return (
          <>
            <Wrapper>
              <Title>
                관심있는 단지나
                <br />
                지역을 검색해 보세요.
              </Title>
              <InputWrapper>
                <Input
                  ref={ref}
                  type="text"
                  placeholder="주소 · 장소명으로 검색"
                  onKeyDown={(e) => onSearch(e.target.value, e.key)}
                />
                <StyledButton
                  onClick={() => onSearch(ref?.current?.value)}
                  color="blue"
                >
                  검색
                </StyledButton>

                <NoteDonghoDanjiSearchList
                  resetSearch={resetSearch}
                  addrList={addrList}
                  onClickAddress={onClickAddress}
                />
              </InputWrapper>
            </Wrapper>
            {loadingSearch && <FullScreenLoading highLevel />}
          </>
        )
      }}
    </NoteDonghoDanjiSearchContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 -20px;
  padding: 0 20px 20px 20px;
  border-bottom: 8px solid ${theme.colors.gray[200]};
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
`

const InputWrapper = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
  width: 100%;
`

const Input = styled.input`
  width: inherit;
  height: 38px;
  padding: 9px 12px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};

  &:focus {
    border: 1px solid ${theme.colors.blue[400]};
    outline: none;
  }
`

const StyledButton = styled(Button)`
  flex-shrink: 0;
`

export default NoteDonghoDanjiSearch
