import { Tag } from 'components/design'
import priceFormat from 'lib/utils/priceFormat'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const StyledContractType = styled.div`
  display: flex;
  flex-direction: column;
`

const ContractType = ({ item }) => {
  const getContractTypeColor = (contractType) => {
    switch (contractType) {
      case '전세':
        return 'orange'
      case '월세':
        return 'blue'
      case '매매':
        return 'red'
      case '단기임대':
        return 'purple'
      case '분양권':
        return 'pink'
    }
  }
  const getContractPrice = (contractType) => {
    switch (contractType) {
      case '전세':
        return priceFormat(item?.jeonse_deposit * 10000)
      case '월세':
        return (
          <>
            {priceFormat(item?.deposit * 10000)}
            {item?.rent
              ? `/${priceFormat(item?.rent * 10000)}`
              : undefined}
          </>
        )
      case '매매':
        return ['아파트 분양권', '오피스텔 분양권'].includes(
          item?.property_type,
        )
          ? `P ${priceFormat(
              item?.extra_vars?.presale_premium * 10000,
            )}`
          : priceFormat(item?.sale_price * 10000)
      case '단기임대':
        return priceFormat(item?.deposit * 10000)
      case '분양권':
        return priceFormat(item?.sale_price * 10000)
    }
  }

  return (
    <StyledContractType>
      {item?.contract_type?.split(',').map((contract_type, i) => {
        return (
          <div key={i}>
            <Tag
              color={getContractTypeColor(contract_type)}
              size="small"
              marginRight
            >
              {contract_type}
            </Tag>
            <span>{getContractPrice(contract_type)}</span>
          </div>
        )
      })}
    </StyledContractType>
  )
}

export default ContractType
