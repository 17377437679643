import styled from 'styled-components'
import theme from 'lib/styles/theme'

import { ListItem } from 'components/design'
import { ListItemDesc } from 'components/design'
import { ListItemLabel } from 'components/design'
import { ListItemThumbnail } from 'components/design'
import { Tag } from 'components/design'

const StyledQuote = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;

  color: ${theme.colors.primary[600]};
  font-size: 0.875rem;
  background-color: ${theme.colors.primary[50]};
  border-radius: ${theme.borderRadius[0]};
`
const StyledQuoteIcon = styled.span`
  color: ${theme.colors.primary[600]};
  font-size: 1rem;
  margin-right: 0.5rem;
`

const NoteContactItemBase = ({
  alias = '이름 없음',
  numbers = [],
  memo = '',
  type = '',
  onClick,
  children,
  append,
  chevron,
  actived,
  noContainer,
  className,
}) => {
  const tagColors = {
    집주인: 'blue',
    세입자: 'green',
    관심인: 'orange',
    기타: 'gray',
  }

  return (
    <>
      <ListItem
        color={
          localStorage.getItem('notemode') == 'sheet'
            ? 'blue'
            : 'primary'
        }
        className={className}
        prepend={<ListItemThumbnail />}
        onClick={onClick}
        append={append}
        chevron={chevron}
        actived={actived}
        dense
        noContainer={noContainer}
      >
        <ListItemLabel small>
          {type && (
            <Tag
              color={tagColors[type] || 'blue'}
              size="small"
              marginRight
            >
              {type}
            </Tag>
          )}
          <span>{alias}</span>
        </ListItemLabel>

        {Array.isArray(numbers) && numbers?.length > 0 && (
          <ListItemDesc small>
            {numbers.map((item, i) => (
              <span key={i}>
                {item?.number}
                {numbers.length - 1 !== i && <span>, </span>}
              </span>
            ))}
          </ListItemDesc>
        )}

        {memo && (
          <StyledQuote>
            <StyledQuoteIcon>
              <i className="fad fa-quote-left"></i>
            </StyledQuoteIcon>
            <span>메모: </span>
            <span>{memo}</span>
          </StyledQuote>
        )}

        {children}
      </ListItem>
    </>
  )
}

export default NoteContactItemBase
