import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import CustomAddressCellList from './CustomAddressCellList'
import theme, { media } from 'lib/styles/theme'
import { ReactComponent as OpenIcon } from 'assets/icon/note/open.svg'
import { ReactComponent as OpenIconMobile } from 'assets/icon/note/open-blue.svg'
import { ReactComponent as InvalidIcon } from 'assets/icon/note/address_invalid.svg'
import { useHistory, useParams } from 'react-router-dom'
import {
  CustomSingleCellInputWrapper,
  CustomSingleCellStyle,
  SheetButton,
} from 'components/jmapnotev2/style/NoteStyle'
import CustomAddressCellListContainer from 'containers/notev2/CustomAddressCellListContainer'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import { getValidAddresses } from 'lib/api/notev2/listApi'
import useAxios from 'lib/hooks/useAxios'
import { useInput } from 'lib/hooks/useInput'
import useSaveAddressForSheet from 'lib/hooks/notev2/useSaveAddressForSheet'
import {
  addSelectAllKeyEvent,
  additionalCellWidth,
  gridFontSize,
  resetGridDataAddresses,
  updateGridDatas,
} from 'lib/utils/notev2/gridUtil'
import { focusNextCell } from 'lib/utils/notev2/gridFocus'
import useSaveNotes from 'lib/hooks/notev2/useSaveNotes'
import { Tooltip, message } from 'antd'
import { Steps } from 'intro.js-react'

import moment from 'moment'
import useModifyAddress from 'lib/hooks/notev2/useModifyAddress'
import Spinner from 'components/common/Loading/Spinner'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import {
  getDisplayAddressFormat,
  getInvalidAddrName,
} from 'lib/utils/notev2/util'
import useIsMobile from 'lib/hooks/useIsMobile'
import { ActiveCellStyle } from 'components/jmapnotev2/style/NoteGridStyle'
import 'intro.js/introjs.css'
import { useSelector } from 'react-redux'
import axios from 'axios'
import {
  funcSetActionsClickposition,
  getAddress,
  getAddressName,
} from 'lib/utils'
import MapContext from 'contexts/map'
import { useDispatch } from 'react-redux'
import {
  setAddressCellState,
  setFiveStepState,
  setFourStepState,
  setOnBoardingStart,
  setOnBoardingState,
  setThreeDepthStart,
  setThreeStepState,
} from 'modules/onBoarding'
import OnBoarding from 'components/jmapnotev2/OnBoarding/OnBoarding'
import OpenButton from '../OpenButton'

const getEditAddressFormat = ({
  display_address,
  eupmyeondong,
  dong,
  ho,
}) => {
  const addr = `${eupmyeondong ? `${eupmyeondong} ` : ''}${
    display_address ? `${display_address}` : ''
  }`?.trim()

  const dongVal = `${dong ? ` ${dong.trim()}` : ''}`

  const hoVal = `${ho ? ` ${ho.trim()}` : ''}`

  return `${addr}${dongVal}${hoVal}`?.trim()
}

const CustomAddressCell = (
  {
    gridColumnApi,
    clearIsSearchingAddr,
    value,
    data,
    node,
    gridfontsize,
  },
  gridRef,
) => {
  const dispatch = useDispatch()
  //온보딩 관련 변수
  const [steps, setSteps] = useState([])
  const [stepsEnabled, setStepsEnalbed] = useState()

  const AddressCellState = useSelector(
    (state) => state.onBoarding.AddressCellState,
  )
  const threeStepState = useSelector(
    (state) => state.onBoarding.threeStepState,
  )
  const fourStepState = useSelector(
    (state) => state.onBoarding.fourStepState,
  )
  const fiveStepState = useSelector(
    (state) => state.onBoarding.fiveStepState,
  )

  const threeDepthChannelSrl = useSelector(
    (state) => state.onBoarding.threeDepthChannelSrl,
  )
  const threeDepthAddressSrl = useSelector(
    (state) => state.onBoarding.threeDepthAddressSrl,
  )
  const threeDepthNoteSrl = useSelector(
    (state) => state.onBoarding.threeDepthNoteSrl,
  )
  //
  const { kakao } = window

  const {
    state: { map },
    actions,
  } = useContext(MapContext)

  const history = useHistory()
  const params = useParams()
  const [addressList, setAddressList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [onRequestValid, , dataValidAddr] = useAxios(getValidAddresses)
  const gridFontSize = useSelector((state) => state.notev2.gridFontSize)

  const isMobile = useIsMobile()
  const viewRef = useRef(null)
  const inputRef = useRef(null)

  const { onSaveAddressForSheet: onSaveAddressForSheetOneAddr } =
    useSaveAddressForSheet()

  const [modifyAddressValidToInvalid, dataModifyAddressValidToInvalid] =
    useModifyAddress()
  const [onRequestModifyNote, isErrorModifyNote] = useModifyNote()
  const [
    onSaveNoteInvalidAddr,
    noteSrlInvalidAddr,
    inErrorInvalidAddr,
    currentRowNodeDataInvalidAddr,
  ] = useSaveNotes()

  const [modifyAddressOneAddr] = useModifyAddress()

  const prevValue = useRef(
    data.address_srl
      ? getDisplayAddressFormat({
          eupmyeondong: data.eupmyeondong,
          display_address: value,
          dong: data.dong,
          ho: data.ho,
        })
      : getInvalidAddrName({
          invalid_addr_name: data.invalid_addr_name,
          dong: data.dong,
          ho: data.ho,
        }),
  )

  const [addressInput, bindAddressInput, , setInput] = useInput(
    data.address_srl
      ? getEditAddressFormat({
          eupmyeondong: data.eupmyeondong,
          address_jibun: data.address_jibun,
          display_address: value,
          dong: data.dong,
          ho: data.ho,
        })
      : getInvalidAddrName({
          invalid_addr_name: data.invalid_addr_name,
          dong: data.dong,
          ho: data.ho,
        }),
  )

  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    isReversePosition,
  } = useCustomSingleCell(data?.status, node?.rowIndex)

  /**
   * 조회 상태로 초기화
   */

  const resetCellState = useCallback(() => {
    setIsEditable(false)
    setAddressList(null)
    clearIsSearchingAddr()
  }, [])

  useEffect(() => {
    prevValue.current = data.address_srl
      ? getDisplayAddressFormat({
          eupmyeondong: data.eupmyeondong,
          display_address: value,
          dong: data.dong,
          ho: data.ho,
        })
      : getInvalidAddrName({
          invalid_addr_name: data.invalid_addr_name,
          dong: data.dong,
          ho: data.ho,
        })
    setInput(
      data.address_srl
        ? getEditAddressFormat({
            eupmyeondong: data.eupmyeondong,
            address_jibun: data.address_jibun,
            display_address: value,
            dong: data.dong,
            ho: data.ho,
          })
        : getInvalidAddrName({
            invalid_addr_name: data.invalid_addr_name,
            dong: data.dong,
            ho: data.ho,
          }),
    )
  }, [
    data.dong,
    data.ho,
    data.address_jibun,
    data.eupmyeondong,
    data.invalid_addr_name,
  ])

  useEffect(() => {
    if (data.note_srl == '신규') {
      setIsEditable(true)
      inputRef.current?.focus()
      setAddressList(null)
      clearIsSearchingAddr()
    } else {
      resetCellState()
    }
  }, [])

  const onClickOpenDetail = useCallback(
    (e) => {
      e.preventDefault()
      const channelSrl = params?.channelSrl ?? data.channel_srl
      history.push({
        pathname: `/note/v2/notes/${channelSrl}/${
          data.address_srl || '0'
        }/${data.note_srl}`,
      })

      //열기버튼눌렸을때 로드뷰정보 세팅
      axios
        .get(`/map/api/searchAddress?query=${data.address_jibun}`)
        .then((response) => {
          const result = response.data.documents
          if (result[0]) {
            axios
              .get(`/map/api/searchKeyword?query=${data.address_jibun}`)
              .then((response) => {
                const latlng = new kakao.maps.LatLng(
                  result[0]?.y,
                  result[0]?.x,
                )

                getAddress(latlng.getLat(), latlng.getLng()).then(
                  (response) => {
                    const innerResult = response.data.result

                    const address = getAddressName(innerResult)
                    funcSetActionsClickposition(
                      innerResult,
                      address,
                      actions,
                    )
                  },
                )
              })
          } else {
            return
          }
        })
    },
    [params, data],
  )

  const onKeyDownInput = useCallback(
    (e, onboard, address) => {
      addSelectAllKeyEvent(e)
      if (e.key == 'Enter' || e.key == 'Tab' || onboard == true) {
        //값 변경 안되었으면 return
        if (onboard == true) {
          setIsLoading(true)
          onRequestValid({
            address: threeStepState ? address : addressInput,
          })
          //threeStepState상태에따라서 '덕명동 하우스토리 102동 301호'의 실제값이 서버에 요청된다
          //address는 '덕명동 하우스토리 102동 301호'이다
          return
        }

        if (prevValue.current == addressInput) {
          setIsEditable(false)
          setTimeout(() => {
            viewRef?.current?.focus()
          })
          return
        }

        setIsLoading(true)
        onRequestValid({
          address: threeStepState ? address : addressInput,
        })
      } else if (e.key == 'Escape') {
        //esc 누르면 모두 초기화(조회 상태로)
        resetCellState()
        if (data.note_srl == '신규') {
          setInput('')
        }
      } else if (e.keyCode == 9) {
        //tab
        setIsEditable(false)
      }
    },
    [
      prevValue?.current,
      addressInput,
      viewRef?.current,
      data.note_srl,
      threeStepState,
    ],
  )

  /**
   * 바깥 클릭하면 모두 초기화(조회 상태로)
   */
  useOnClickOutside(inputRef, () => {
    setIsEditable(false)
  })

  /**
   * 주소 유효성 검증 후 추가 로직
   * - 검증 성공(단일) => saveAddress + saveNote
   * - 검증 성공 (여러개) => dropdown 생성
   * - 검증 실패 => saveNote
   */
  useEffect(() => {
    if (!dataValidAddr || !dataValidAddr?.result) return
    if (!data.row_key) return
    if (!gridRef.current) return

    const targetNodeData = gridRef.current.api.getRowNode(data.row_key)

    if (!targetNodeData || !targetNodeData.data) return

    setIsLoading(false)

    //추가 모드
    if (targetNodeData.data.note_srl == '신규') {
      if (dataValidAddr.result.length == 1) {
        resetGridDataAddresses(targetNodeData)

        //주소 검증 성공(단일) => saveAddress + saveNote
        const validData = dataValidAddr.result[0]
        onSaveAddressForSheetOneAddr({
          channel_srl: data.channel_srl,
          address_id: validData.address_id,
          validAddressInfo: validData,
          currentRowNode: targetNodeData,
          callbackFn: () =>
            focusNextCell(
              gridRef.current.api,
              gridColumnApi,
              data.row_key,
              'contract_type',
            ),
        })

        //수정모드 해제
        setIsEditable(false)

        //현재값과 비교할 이전값 세팅(input)
        const inputValue = getEditAddressFormat({
          eupmyeondong: validData?.eupmyeondong,
          display_address: validData?.display_address,
          address_jibun: validData?.address_jibun || '',
          dong: validData.dong,
          ho: validData.ho,
        })
        prevValue.current = inputValue
        setInput(inputValue)
      } else if (dataValidAddr.result.length > 1) {
        //주소 검증 성공 (여러개) => dropdown 생성
        const validData = dataValidAddr.result
        setAddressList(validData)
      } else if (dataValidAddr.result.length == 0) {
        //TODO: 이상한 주소 입력시 빨간색으로
        //주소 검증 실패 => saveNote
        resetGridDataAddresses(targetNodeData)
        //모든 요청이 정상적으로 처리되었으니 다음 수정 셀로 이동
        focusNextCell(
          gridRef.current.api,
          gridColumnApi,
          data.row_key,
          'contract_type',
        )
        //db
        onSaveNoteInvalidAddr({
          channel_srl: data.channel_srl,
          form: {
            invalid_addr_name: addressInput,
          },
          rowNodeData: Object.assign({}, targetNodeData.data, {
            invalid_addr_name: addressInput,
          }),
        })
        //현재값과 비교할 이전값 세팅(input)
        prevValue.current = addressInput
      }
    } else if (targetNodeData.data.note_srl) {
      //수정모드
      if (!data.row_key) {
        return
      }

      if (dataValidAddr.result.length == 1) {
        const validData = dataValidAddr.result[0]

        //주소 검증 성공(단일) => saveNote
        modifyAddressOneAddr({
          address_id: validData.address_id,
          address_srl: targetNodeData.data.address_srl,
          channel_srl: targetNodeData.data.channel_srl,
          note_srl: targetNodeData.data.note_srl,
          currentRowNode: targetNodeData,
          validAddressInfo: validData,
          input_addr: addressInput,
        })

        //수정모드 해제
        setIsEditable(false)

        //현재값과 비교할 이전값 세팅(input)
        const inputValue = getEditAddressFormat({
          eupmyeondong: validData?.eupmyeondong,
          display_address: validData?.display_address,
          address_jibun: validData?.address_jibun,
          dong: validData.dong,
          ho: validData.ho,
        })
        prevValue.current = inputValue
        setInput(inputValue)
      } else if (dataValidAddr.result.length > 1) {
        //주소 검증 성공 (여러개) => dropdown 생성
        const validData = dataValidAddr.result
        setAddressList(validData)
      } else if (dataValidAddr.result.length == 0) {
        //주소 검증 실패
        if (targetNodeData.data.address_srl) {
          //유효한 주소 -> 유효하지 않은 주소
          //파라미터: address_id: null, invalid_addr_name key를 추가하여 넘김
          modifyAddressValidToInvalid({
            address_id: null,
            address_srl: targetNodeData.data.address_srl,
            channel_srl: targetNodeData.data.channel_srl,
            note_srl: targetNodeData.data.note_srl,
            currentRowNode: targetNodeData,
            invalid_addr_name: addressInput,
            isInvalidAddr: true,
            input_addr: addressInput,
          })
        } else {
          //유효하지 않은 주소 -> 유효하지 않은 주소
          //modify Note

          onRequestModifyNote({
            data: targetNodeData.data,
            updateData: {
              invalid_addr_name: addressInput,
            },
            currentRowNode: targetNodeData,
          })
        }

        //수정모드 해제
        setIsEditable(false)
        //가격셀로 이동
        focusNextCell(
          gridRef.current.api,
          gridColumnApi,
          data.row_key,
          'contract_type',
        )
        //현재값과 비교할 이전값 세팅(input)
        prevValue.current = addressInput
      }
    }
  }, [dataValidAddr])

  /**
   * 이상한 주소 저장 성공
   */
  useEffect(() => {
    if (!noteSrlInvalidAddr) return

    //그리드 저장
    updateGridDatas({
      gridApi: gridRef.current.api,
      currentRowKey: currentRowNodeDataInvalidAddr.row_key,
      newData: {
        //노트 srl과 주소 정보 업뎃
        addr: currentRowNodeDataInvalidAddr.invalid_addr_name,
        note_srl: noteSrlInvalidAddr,
        invalid_addr_name:
          currentRowNodeDataInvalidAddr.invalid_addr_name,
        regdate: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
    })
    focusNextCell(
      gridRef.current.api,
      gridColumnApi,
      data.row_key,
      'contract_type',
    )

    //수정모드 해제
    setIsEditable(false)
  }, [noteSrlInvalidAddr])

  /**
   * 이상한 주소 저장 실패
   */
  useEffect(() => {
    if (!inErrorInvalidAddr) return

    message.error('매물 저장에 실패했어요. 다시 시도해주세요.')
  }, [inErrorInvalidAddr])

  const onClickDocsButton = useCallback(() => {
    if (!data?.channel_srl || !data.note_srl) return

    const { channel_srl, note_srl, address_srl } = data

    if (!channel_srl || !note_srl) {
      message.error('채널 또는 매물정보가 잘못되었습니다.')
      return
    }

    history.push({
      pathname: `/note/v2/notes/${channel_srl}/${
        address_srl || '0'
      }/${note_srl}`,
      state: {
        activeTab: 3,
      },
    })
  }, [data])

  //온보딩 관련 함수
  useEffect(() => {
    if (threeStepState) {
      onKeyDownInput(true, true, '덕명동 하우스토리 102동 301호')
      //threeStepState가 true가되면 실제주소 요청함수 실행
    }
  }, [threeStepState])

  //NoteGridNewLineRender.jsx에서 바꾼 addresscellstate를 감지해서 steps를 세팅하고 시작시킨다
  useEffect(() => {
    if (AddressCellState) {
      setStepsEnalbed(true)
    }
  }, [AddressCellState])

  const onExit = () => {
    setStepsEnalbed(false)
    dispatch(setAddressCellState(false))
    dispatch(setOnBoardingStart(false))
    if (threeStepState) {
      dispatch(setThreeStepState(false))
    }
    if (fourStepState) {
      dispatch(setFourStepState(false))
    }
    if (fiveStepState) {
      dispatch(setFiveStepState(false))
    }
  }
  //
  return (
    <Wrapper tabIndex={-1} className="adress-wrapper-input">
      <OnBoarding
        enabled={stepsEnabled}
        steps={steps}
        setSteps={setSteps}
        onExit={onExit}
        setStepsEnalbed={setStepsEnalbed}
        //다음버튼을 눌렀을때 리덕스 state를 변경해서 다른컴포넌트에서 그 상태를 가져와 state에따라 애니메이션 설정함
        onChange={(e) => {
          if (e == 1) {
            dispatch(setAddressCellState(false))
            dispatch(setThreeStepState(true))
          } else if (e == 2) {
            dispatch(setFourStepState(true))
            dispatch(setThreeStepState(false))
          } else if (e == 3) {
            dispatch(setFiveStepState(true))
            dispatch(setFourStepState(false))
          } else if (e == 4) {
            dispatch(setFiveStepState(false))
            dispatch(setThreeDepthStart(true))
            setStepsEnalbed(false)
            //덕명동 하우스토리 102동 301호 주소로 만들어진 매물의 실제 3뎁스를 열리게함
            history.push({
              threeDepthNoteSrl: null,
              pathname: `/note/v2/notes/${threeDepthChannelSrl}/${threeDepthAddressSrl}/${threeDepthNoteSrl}`,
              state: {
                activeTab: 1,
              },
            })
          }
        }}
        options={{
          hidePrev: true,
          nextLabel: '확인',
          prevLabel: '이전',
          showBullets: false,
          buttonClass: 'customButton',
        }}
      />

      {isMobile ? (
        <MobileOpenButtonWrapper className="open-class"></MobileOpenButtonWrapper>
      ) : (
        <OpenButtonWrapper
          fiveStepState={fiveStepState}
          className="open-class"
        >
          <OpenIcon />
          <OpenText>열기</OpenText>
        </OpenButtonWrapper>
      )}
      {isLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {isEditable ? (
        <CustomSingleCellInputWrapper>
          <StyledInput
            placeholder={isMobile && '주소를 입력하세요'}
            className="custom-address-cell-input"
            ref={inputRef}
            onFocus={(e) => {
              e.target.select()
            }}
            tabIndex={0}
            type="text"
            autoFocus
            value={addressInput}
            onBlur={() => {
              if (!addressInput) {
                setIsEditable(false)
              }
            }}
            onKeyDown={onKeyDownInput}
            gridfontsize={gridfontsize}
            {...bindAddressInput}
          />
        </CustomSingleCellInputWrapper>
      ) : (
        <>
          {/* 조회모드 */}
          <AddressWrapper
            padding={isMobile ? 'Y' : 'N'}
            tabIndex={0}
            onDoubleClick={onDoubleClickSingleCell}
            onKeyDown={onKeyDownSingleCell}
            ref={viewRef}
          >
            {/* onboarding 타이핑효과 */}
            <OnboardingAddress
              stepsEnabled={stepsEnabled}
              AddressCellState={AddressCellState}
            >
              하우스토리 102동 301호
            </OnboardingAddress>
            {data.address_srl ? (
              <Addresses mobile={isMobile ? 'Y' : 'N'}>
                {data.eupmyeondong && !isMobile && (
                  <Eupmyeondong
                    addwidth={additionalCellWidth(gridFontSize)} // 폰트 사이즈 변경에 따라 그리드 셀 폭 조절(읍면동은 그리드의 셀이 아니라 따로 조정해야함)
                  >
                    {data.eupmyeondong}
                  </Eupmyeondong>
                )}
                <Address padding={isMobile ? 'N' : 'Y'}>
                  <div>
                    {data.eupmyeondong && isMobile && (
                      <>{data.eupmyeondong}</>
                    )}
                    {getDisplayAddressFormat({
                      display_address: value,
                      dong: data.dong,
                      ho: data.ho,
                    })}
                    {data.building_name && (
                      <Name>&nbsp;({data.building_name})</Name>
                    )}
                  </div>
                </Address>
              </Addresses>
            ) : (
              <InvalidAddressWrapper>
                <InvalidAddress>
                  {getInvalidAddrName({
                    invalid_addr_name: data.invalid_addr_name,
                    dong: data.dong,
                    ho: data.ho,
                  })}
                </InvalidAddress>
                <Tooltip title="유효하지 않은 주소입니다.">
                  {data.note_srl != '신규' && <InvalidIcon />}
                </Tooltip>
              </InvalidAddressWrapper>
            )}
            {data.deunggibu_counts > 0 && !isMobile && (
              <Badge onClick={onClickDocsButton}>
                {data.deunggibu_counts}
              </Badge>
            )}
          </AddressWrapper>
        </>
      )}
      {addressList && (
        <CustomAddressCellListContainer
          resetCellState={resetCellState}
          ref={{
            gridRef: gridRef,
            prevValue: prevValue,
          }}
          setInput={setInput}
          gridColumnApi={gridColumnApi}
          rowData={data}
          node={node}
          addresses={addressList}
          addressInput={addressInput}
        >
          {({ onSaveAddress }) => (
            <CustomAddressCellList
              addresses={addressList}
              onSaveAddress={onSaveAddress}
              setIsEditable={setIsEditable}
              isReversePosition={isReversePosition}
              escapeFn={() => {
                setAddressList(null)
                clearIsSearchingAddr()
                inputRef.current?.focus()
              }}
            />
          )}
        </CustomAddressCellListContainer>
      )}
      {data.status == '2' && !isMobile && (
        <CompleteTag defaultcursor={true}>중개완료</CompleteTag>
      )}
      {data.note_srl && data.note_srl != '신규' && (
        <OpenDetailButton
          fiveStepState={fiveStepState}
          onClick={onClickOpenDetail}
          className={isMobile ? 'open-class' : null}
        >
          {isMobile ? (
            data.deunggibu_counts && data.deunggibu_counts > 0 ? (
              <OpenIconMobile />
            ) : (
              <OpenButton size="medium" visibleText={false} />
            )
          ) : (
            <OpenButton size="medium" />
          )}
        </OpenDetailButton>
      )}
    </Wrapper>
  )
}

const MobileOpenButtonWrapper = styled.div`
  left: 120px;
  position: absolute;
  width: 40px;
  height: 20px;
`

const OpenButtonWrapper = styled.div`
  opacity: 0;
  left: 300px;
  position: absolute;
`

const cursor = keyframes`

50%{ border-color: transparent; }
`

const typing = keyframes`
from{ width: 0; }
to{
  width:150px;
}

`

const OnboardingAddress = styled.div`
  display: none;
  ${({ stepsEnabled }) => stepsEnabled == false && `display:none`}

  ${({ AddressCellState }) =>
    AddressCellState == true &&
    css`
      display: block;
      top: 0;
      bottom: 0;
      opacity: 1;
      line-height: 42px;
      position: absolute;
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      border-right: 0.05em solid black;
      animation: ${typing} 2s steps(22),
        ${cursor} 0.5s steps(1) infinite;
    `}
`

const EllipsisStyle = css`
  white-space: initial;
  line-height: 120%;
  width: 100%;
  text-align: left;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const OpenDetailButton = styled.div`
  position: absolute;
  right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s;

  ${({ fiveStepState }) =>
    fiveStepState && 'opacity:1; transition:all 1s'}
`

const OpenText = styled.span`
  margin-left: 4px;
`

const CompleteTag = styled(SheetButton)`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  transition: opacity 0.1s;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;

  &:hover {
    ${OpenDetailButton} {
      opacity: 1;
    }
    ${CompleteTag} {
      opacity: 0;
    }
  }
`

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  border: none;
  outline: none;
  overflow: hidden;

  ${CustomSingleCellStyle}

  ${({ padding }) => padding == 'Y' && `padding-left: 14px;`}
`

const Addresses = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  ${media.mediumS`
    display: block;
    height: auto;
    ${EllipsisStyle}
  `}
`

const Address = styled.div`
  ${({ padding }) => padding == 'Y' && `padding-left: 8px;`}
`

const Eupmyeondong = styled.div`
  border-right: 1px solid ${theme.colors.gray[900]}1A;
  width: ${({ addwidth }) => `${70 + addwidth}px`};
  text-align: center;
  margin-left: -8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledInput = styled.input`
  width: 100%;
  height: calc(100% - 2px);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 0;
  outline: none;

  ${media.mediumS`
    text-indent: 4px;
  `}

  ${ActiveCellStyle}

  font-size: ${({ gridfontsize }) =>
    gridfontsize || gridFontSize['m']}px;
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  border: none;

  & > div {
    z-index: 9999;
  }
`
const Name = styled.span`
  color: ${theme.colors.gray[500]};
`

const InvalidAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.tint.danger};
  width: 80%;
  svg {
    margin-left: 4px;
    flex-shrink: 0;
  }
`

const InvalidAddress = styled.div`
  ${media.mediumS`
    ${EllipsisStyle}
  `}
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  min-width: 16px;
  height: 16px;
  padding: 3.3px;
  text-align: center;
  background-color: ${theme.colors.blue[600]};
  font-size: 10px;
  line-height: 100%;
  border-radius: 4px;
  color: ${theme.colors.base.white};
  margin-left: 6px;
  font-weight: 400;
  cursor: pointer;

  ${media.mediumS`
    position: absolute;
    top: 50%;
    right: 44px;
    transform: translateY(-50%);
  `}
`

export default React.memo(forwardRef(CustomAddressCell))
