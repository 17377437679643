import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Row,
  Table,
  TableContent,
  TableHeader,
  TableTitle,
  RowSpanInner,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import styled from 'styled-components'
import ColorSelectBox from 'components/jmapnotev2/elements/ColorSelectBox'
import {
  getCalcedJanguem,
  getOnlyRemoveCommaNumber,
  getSelectedValue,
  getSimpleSelectboxOptions,
  getVatRatio,
  handleFocusNumber,
} from 'lib/utils/notev2/util'
import moment from 'moment'
import { isArray } from 'lodash'
import EditButton from 'components/jmapnotev2/elements/EditButton'
import { Jo, Raws, RawsTitle } from './NoteDocsContractRaws'
import { useDispatch } from 'react-redux'
import useEditContract from 'lib/hooks/notev2/useEditContract'
import { getBrokerRate } from 'lib/api/notev2/contractApi'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { useSelector } from 'react-redux'

const loan_conditions = [
  '현 상태에서 매수인이 승계함.',
  '매도인이 잔금지급일까지 말소한다.',
  '특약사항에 별도 명시한다.',
  '매수인이 인수한다.',
  '매수인이 인수하지 아니한다.',
]

const NoteDocsContractContent = ({ onUpdate, mainArticlesSpec }) => {
  const { watch, getValues, setValue } = useFormContext()
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const [onEditContract, , data] = useEditContract()
  const [onRequestCalc, , dataCalc] = useAxios(getBrokerRate)

  const contract_type = watch('contract_type')
  const total_price = watch('total_price')
  const vat = watch('vat')
  const payment_schedule = watch('payment_schedule')
  const assignment_date = watch('assignment_date')
  const channel_srl = watch('channel_srl')
  const contract_srl = watch('contract_srl')
  const d_junggaebosu = watch('d_junggaebosu') || 0
  const d_silbi = watch('d_silbi') || 0
  const d_junggaebosu_vat = watch('d_junggaebosu_vat') || 0
  const d_junggaebosu_vat_ratio1 = watch('d_junggaebosu_vat_ratio1')

  const [jungdogeumArr, setJungdogeumArr] = useState([])
  const [jungdogeumRowspan, setJungdogeumRowspan] = useState(2)
  const [jangeumArr, setJangeumArr] = useState([])

  useEffect(() => {
    const jungdogeum = payment_schedule['jungdogeum']
    const jangeum = payment_schedule['jangeum']

    if (!jungdogeum || !isArray(jungdogeum) || jungdogeum.length < 2) {
      if (isArray(jungdogeum) && jungdogeum.length == 1) {
        setJungdogeumArr([jungdogeum[0], { date: '', price: '' }])
      } else {
        setJungdogeumArr([
          { date: '', price: '' },
          { date: '', price: '' },
        ])
      }
    } else {
      setJungdogeumArr(jungdogeum)
      setJungdogeumRowspan(jungdogeum.length)
    }

    if (!jangeum || jangeum.length == 0) {
      //   const calcedJanguem = getCalcedJanguem()

      setJangeumArr([
        {
          date: '',
          price: getCalcedJanguem(
            total_price,
            payment_schedule['jungdogeum'],
            payment_schedule['loan'],
            payment_schedule['contract_price'],
            contract_type,
          ),
        },
      ])

      // onUpdate(
      //   'jangeum',
      //   [
      //     {
      //       date: '',
      //       price: getCalcedJanguem(
      //         total_price,
      //         payment_schedule['jungdogeum'],
      //         payment_schedule['loan'],
      //         payment_schedule['contract_price'],
      //         contract_type,
      //       ),
      //     },
      //   ],
      //   true,
      // )
    } else {
      setJangeumArr(jangeum)
    }
  }, [])

  const onChangeJungdogeum = useCallback(
    ({ key, value, index }) => {
      const newValue = [...jungdogeumArr]
      newValue[index][key] = value

      const result = newValue.filter(({ date, price }) => {
        return date || price
      })

      setJungdogeumArr(newValue)
      onUpdate('jungdogeum', result, true)
    },
    [jungdogeumArr],
  )

  const addJungdogeum = useCallback(() => {
    setJungdogeumArr([...jungdogeumArr, ...[{ date: '', price: '' }]])
    setJungdogeumRowspan((prev) => prev + 1)
  }, [jungdogeumArr, jungdogeumRowspan])

  const onChangeJangeum = ({ key, value }) => {
    const newValue = [...jangeumArr]
    newValue[0][key] = value

    const result = newValue.filter(({ date, price }) => {
      return date || price
    })

    setJangeumArr(newValue)
    onUpdate('jangeum', result, true)
  }

  const mainArticlesNum1 = useMemo(
    () => mainArticlesSpec.find(({ num }) => num == 1),
    [mainArticlesSpec],
  )

  /**
   * 매매대금(보증금) blur
   * @param {*} e
   * @returns
   */
  const onBlurTotalPrice = (e) => {
    if (channelInfo?.member_type == 'readonly') return //읽기 권한 수정 불가
    const value = getOnlyRemoveCommaNumber(e.target.value)

    onRequestCalc({
      channel_srl,
      contract_srl,
      total_price: value,
    })

    if (
      !total_price ||
      total_price == 0 ||
      getCalcedJanguem(
        e.target.value.replaceAll(',', ''),
        payment_schedule['jungdogeum'],
        payment_schedule['loan'],
        payment_schedule['contract_price'],
        contract_type,
      ) < 0
    ) {
      //계산할 잔금 없으면 해당 값만 업데이트
      onUpdate('total_price', value)
      return
    } else {
      //잔금 업데이트
      const janguem = getCalcedJanguem(
        e.target.value.replaceAll(',', ''),
        payment_schedule['jungdogeum'],
        payment_schedule['loan'],
        payment_schedule['contract_price'],
        contract_type,
      )

      onEditContract({
        data: getValues(),
        updateData: {
          total_price: value,
          payment_schedule: {
            ...getValues().payment_schedule,
            jangeum: [
              {
                date: jangeumArr[0]?.date || '',
                price: janguem,
              },
            ],
          },
        },
      })

      setValue('total_price', value)
      setJangeumArr([
        {
          date: jangeumArr[0]?.date || '',
          price: janguem,
        },
      ])

      setValue('payment_schedule', {
        ...getValues().payment_schedule,
        jangeum: [
          {
            date: jangeumArr[0]?.date || '',
            price: janguem,
          },
        ],
      })
    }
  }

  /**
   * 중개보수 계산 결과값
   */
  useEffect(() => {
    if (!dataCalc || !dataCalc?.result) return

    /**
       * total = d_junggaebosu // 왼쪽 중개보수
          base_amount = d_junggaebosu_cal // 오른쪽 중개보수
          percent = d_junggaebosu_cal_ratio // 요율
      */

    const result = dataCalc.result

    updateData({
      d_junggaebosu: result.total,
      d_junggaebosu_cal: result.base_amount,
      d_junggaebosu_cal_ratio: result.percent,
    })
  }, [dataCalc])

  /**
   *
   * @param {*} d_junggaebosu 왼쪽 중개보수
   * @param {*} d_junggaebosu_cal 오른쪽 중개보수
   * @param {*} d_junggaebosu_cal_ratio 요율
   */
  const updateData = ({
    d_junggaebosu,
    d_junggaebosu_cal,
    d_junggaebosu_cal_ratio,
  }) => {
    if (channelInfo?.member_type == 'readonly') return //읽기 권한 수정 불가

    onEditContract({
      data: getValues(),
      updateData: {
        d_junggaebosu,
        d_junggaebosu_cal,
        d_junggaebosu_cal_ratio,
        realestate_reward: d_junggaebosu, //계약서 하단 중개보수는 __ 로 한다
        d_junggaebosu_vat:
          d_junggaebosu * getVatRatio(d_junggaebosu_vat_ratio1),
      },
    })

    setValue('d_junggaebosu', d_junggaebosu)
    setValue('d_junggaebosu_cal', d_junggaebosu_cal)
    setValue('d_junggaebosu_cal_ratio', d_junggaebosu_cal_ratio)
    setValue('realestate_reward', d_junggaebosu)
    setValue(
      'd_junggaebosu_vat',
      d_junggaebosu * getVatRatio(d_junggaebosu_vat_ratio1),
    )
  }

  return (
    <Wrapper>
      <TableTitle>2. 계약내용</TableTitle>
      {mainArticlesNum1 && Object.keys(mainArticlesNum1).length > 0 && (
        <Raws>
          <Jo>제1조</Jo>
          <RawsTitle>&#91;{mainArticlesNum1.title}&#93;</RawsTitle>
          {mainArticlesNum1.text}
        </Raws>
      )}

      <Table>
        <Row>
          <TableHeader>
            {contract_type === '매매' ? '매매대금' : '보증금'}
          </TableHeader>
          <TableContent basic noborderright>
            <PriceInput
              wide
              input={
                <ColorInput
                  type="number"
                  width={220}
                  onClick={handleFocusNumber}
                  defaultValue={total_price > 0 ? total_price : ''}
                  textAlign="right"
                  onBlur={onBlurTotalPrice}
                />
              }
            />
          </TableContent>
        </Row>
        <Row>
          <TableHeader>계약금</TableHeader>
          <TableContent spacebetween noborderright>
            <PriceInput
              appendText="은 계약시에 지불하고 영수함."
              input={
                <ColorInput
                  type="number"
                  onClick={handleFocusNumber}
                  width={220}
                  defaultValue={
                    payment_schedule['contract_price'] > 0
                      ? payment_schedule['contract_price']
                      : ''
                  }
                  textAlign="right"
                  onBlur={(e) => {
                    onUpdate(
                      'contract_price',
                      getOnlyRemoveCommaNumber(e.target.value),
                      true,
                    )
                    if (
                      !total_price ||
                      total_price == 0 ||
                      getCalcedJanguem(
                        total_price,
                        payment_schedule['jungdogeum'],
                        payment_schedule['loan'],
                        e.target.value.replaceAll(',', ''),
                        contract_type,
                      ) < 0
                    )
                      return

                    setJangeumArr([
                      {
                        date: jangeumArr[0]?.date || '',
                        price: getCalcedJanguem(
                          total_price,
                          payment_schedule['jungdogeum'],
                          payment_schedule['loan'],
                          e.target.value.replaceAll(',', ''),
                          contract_type,
                        ),
                      },
                    ])
                    onUpdate(
                      'jangeum',
                      [
                        {
                          date: jangeumArr[0]?.date || '',
                          price: getCalcedJanguem(
                            total_price,
                            payment_schedule['jungdogeum'],
                            payment_schedule['loan'],
                            e.target.value.replaceAll(',', ''),
                            contract_type,
                          ),
                        },
                      ],
                      true,
                    )
                  }}
                />
              }
            />

            <Recipient>
              영수자 &#40;
              <ColorInput
                type="text"
                defaultValue={payment_schedule['receiver']}
                width={86}
                onBlur={(e) =>
                  onUpdate('receiver', e.target.value, true)
                }
              />
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="6.75"
                  stroke="#191F28"
                  strokeWidth="0.5"
                />
                <path
                  d="M10.679 1.958V9.163H9.777V1.958H10.679ZM3.694 5.06C3.694 6.039 4.42 6.721 5.377 6.721C6.356 6.721 7.082 6.039 7.082 5.06C7.082 4.103 6.356 3.421 5.377 3.421C4.42 3.421 3.694 4.103 3.694 5.06ZM7.962 5.06C7.962 6.49 6.851 7.502 5.377 7.502C3.914 7.502 2.814 6.49 2.814 5.06C2.814 3.641 3.914 2.64 5.377 2.64C6.851 2.64 7.962 3.641 7.962 5.06ZM5.234 10.857H10.976V11.594H4.332V8.426H5.234V10.857Z"
                  fill="black"
                />
              </svg>
              &#41;
            </Recipient>
          </TableContent>
        </Row>
        {contract_type == '매매' && (
          <>
            <Row>
              <TableHeader>융자금</TableHeader>
              <TableContent spacebetween expandright>
                <PriceInput
                  appendText="은"
                  textAlign="right"
                  input={
                    <ColorInput
                      type="number"
                      onClick={handleFocusNumber}
                      width={220}
                      defaultValue={
                        payment_schedule['loan'] > 0
                          ? payment_schedule['loan']
                          : ''
                      }
                      textAlign="right"
                      onBlur={(e) => {
                        onUpdate(
                          'loan',
                          getOnlyRemoveCommaNumber(e.target.value),
                          true,
                        )

                        if (
                          !total_price ||
                          total_price == 0 ||
                          getCalcedJanguem(
                            total_price,
                            payment_schedule['jungdogeum'],
                            e.target.value.replaceAll(',', ''),
                            payment_schedule['contract_price'],
                            contract_type,
                          ) < 0
                        )
                          return

                        setJangeumArr([
                          {
                            date: jangeumArr[0]?.date || '',
                            price: getCalcedJanguem(
                              total_price,
                              payment_schedule['jungdogeum'],
                              e.target.value.replaceAll(',', ''),
                              payment_schedule['contract_price'],
                              contract_type,
                            ),
                          },
                        ])
                        onUpdate(
                          'jangeum',
                          [
                            {
                              date: jangeumArr[0]?.date || '',
                              price: getCalcedJanguem(
                                total_price,
                                payment_schedule['jungdogeum'],
                                e.target.value.replaceAll(',', ''),
                                payment_schedule['contract_price'],
                                contract_type,
                              ),
                            },
                          ],
                          true,
                        )
                      }}
                    />
                  }
                />
                <ColorSelectBox
                  width={240}
                  isSearchable={false}
                  placeholder="선택"
                  classNamePrefix="select"
                  options={getSimpleSelectboxOptions(loan_conditions)}
                  value={getSelectedValue(
                    payment_schedule['loan_condition'],
                    getSimpleSelectboxOptions(loan_conditions),
                  )}
                  onChange={(e) =>
                    onUpdate('loan_condition', e.value, true)
                  }
                  fullheight
                />
              </TableContent>
            </Row>
            <Row>
              <TableHeader>임대보증금</TableHeader>
              <TableContent noborderright>
                <PriceInput
                  appendText="을 승계키로한다."
                  input={
                    <ColorInput
                      type="number"
                      onClick={handleFocusNumber}
                      width={220}
                      onBlur={(e) =>
                        onUpdate(
                          'deposit',
                          getOnlyRemoveCommaNumber(e.target.value),
                          true,
                        )
                      }
                      defaultValue={
                        payment_schedule['deposit'] > 0
                          ? payment_schedule['deposit']
                          : ''
                      }
                      textAlign="right"
                    />
                  }
                />
              </TableContent>
            </Row>
          </>
        )}
        <Row rowspan={jungdogeumRowspan}>
          <TableHeader>중도금</TableHeader>
          {jungdogeumArr.length > 0 &&
            jungdogeumArr.map(({ date, price }, i, oriArr) => {
              return (
                <RowSpanInner
                  rowspan={jungdogeumRowspan}
                  key={i}
                  noborderbottom={jungdogeumArr.length - 1 == i}
                >
                  <TableContent noborderright>
                    <PriceInput
                      wide
                      appendText="은"
                      input={
                        <ColorInput
                          type="number"
                          onClick={handleFocusNumber}
                          width={220}
                          defaultValue={price > 0 ? price : ''}
                          textAlign="right"
                          onBlur={(e) => {
                            onChangeJungdogeum({
                              key: 'price',
                              index: i,
                              value: String(e.target.value).replaceAll(
                                ',',
                                '',
                              ),
                            })

                            if (
                              !total_price ||
                              total_price == 0 ||
                              getCalcedJanguem(
                                total_price,
                                oriArr,
                                payment_schedule['loan'],
                                payment_schedule['contract_price'],
                                contract_type,
                              ) < 0
                            )
                              return

                            setJangeumArr([
                              {
                                date: jangeumArr[0]?.date || '',
                                price: getCalcedJanguem(
                                  total_price,
                                  oriArr,
                                  payment_schedule['loan'],
                                  payment_schedule['contract_price'],
                                  contract_type,
                                ),
                              },
                            ])
                            onUpdate(
                              'jangeum',
                              [
                                {
                                  date: jangeumArr[0]?.date || '',
                                  price: getCalcedJanguem(
                                    total_price,
                                    oriArr,
                                    payment_schedule['loan'],
                                    payment_schedule['contract_price'],
                                    contract_type,
                                  ),
                                },
                              ],
                              true,
                            )
                          }}
                        />
                      }
                    />
                    <DateWrap>
                      <ColorInput
                        type="date"
                        value={
                          (date != 'Invalid date' ? date : '') ||
                          moment().toDate()
                        }
                        selected={
                          (date != 'Invalid date' ? date : '')
                            ? moment(date).toDate()
                            : ''
                        }
                        onChange={(e) =>
                          onChangeJungdogeum({
                            key: 'date',
                            index: i,
                            value: e
                              ? moment(e).format('YYYY-MM-DD')
                              : '',
                          })
                        }
                      />
                      <span>에 지불한다.</span>
                    </DateWrap>
                  </TableContent>
                  {jungdogeumArr.length - 1 == i && (
                    <StyledEditButton
                      onClick={addJungdogeum}
                      type="add"
                      size="small"
                    />
                  )}
                </RowSpanInner>
              )
            })}
        </Row>
        <Row noborderbottom={contract_type != '월세'} rowspan={1}>
          <TableHeader>잔금</TableHeader>
          {jangeumArr.map(({ date, price }, i) => {
            if (i != 0) return
            return (
              <RowSpanInner
                rowspan={1}
                noborderbottom={jangeumArr.length - 1 == i}
                key={i}
              >
                <TableContent noborderright>
                  <PriceInput
                    wide
                    appendText="은"
                    input={
                      <ColorInput
                        type="number"
                        onClick={handleFocusNumber}
                        width={220}
                        key={price}
                        defaultValue={price > 0 ? price : ''}
                        textAlign="right"
                        onBlur={(e) =>
                          onChangeJangeum({
                            key: 'price',
                            value: String(e.target.value).replaceAll(
                              ',',
                              '',
                            ),
                          })
                        }
                      />
                    }
                  />
                  <DateWrap>
                    <ColorInput
                      type="date"
                      value={
                        (date != 'Invalid date' ? date : '') ||
                        moment().toDate()
                      }
                      selected={
                        (date && date != 'Invalid date' ? date : '')
                          ? moment(date).toDate()
                          : ''
                      }
                      onChange={(e) => {
                        if (!assignment_date) {
                          //인도일 값이 없는 경우 인도일을 잔금일로 세팅
                          onUpdate(
                            'assignment_date',
                            e ? moment(e).format('YYYY-MM-DD') : '',
                          )
                        }

                        onChangeJangeum({
                          key: 'date',
                          value: e
                            ? moment(e).format('YYYY-MM-DD')
                            : '',
                        })
                      }}
                    />
                    <span>에 지불한다.</span>
                  </DateWrap>
                </TableContent>
              </RowSpanInner>
            )
          })}
        </Row>
        {contract_type === '월세' && (
          <>
            <Row noborderbottom>
              <TableHeader>차임</TableHeader>
              <RentTableContent noborderright spacebetween>
                <div>
                  <PriceInput
                    wide
                    appendText="은"
                    input={
                      <ColorInput
                        type="number"
                        onClick={handleFocusNumber}
                        width={220}
                        defaultValue={
                          payment_schedule['rent_price'] > 0
                            ? payment_schedule['rent_price']
                            : ''
                        }
                        textAlign="right"
                        onBlur={(e) =>
                          onUpdate(
                            'rent_price',
                            getOnlyRemoveCommaNumber(e.target.value),
                            true,
                          )
                        }
                      />
                    }
                  />

                  <RentProvision>
                    매월
                    <ColorSelectBox
                      value={
                        payment_schedule['rent_maewol'] && {
                          label: payment_schedule['rent_maewol'],
                          item: payment_schedule['rent_maewol'],
                        }
                      }
                      options={[
                        ...[{ value: '', label: '선택' }],
                        ...[...Array(31).keys()].map((item) => {
                          return { label: item + 1, value: item + 1 }
                        }),
                      ]}
                      onChange={(e) =>
                        onUpdate('rent_maewol', e.value, true)
                      }
                      sidemargin
                    />
                    일
                    <ColorSelectBox
                      value={
                        payment_schedule['rent_type'] && {
                          label: payment_schedule['rent_type'],
                          item: payment_schedule['rent_type'],
                        }
                      }
                      options={[
                        { value: '', label: '선택' },
                        { label: '선불', value: '선불' },
                        { label: '후불', value: '후불' },
                      ]}
                      onChange={(e) =>
                        onUpdate('rent_type', e.value, true)
                      }
                      sidemargin
                    />
                    로 지급한다.
                  </RentProvision>
                </div>
                <div>
                  부가세&nbsp;
                  <ColorSelectBox
                    value={
                      vat && {
                        label: vat,
                        value: vat,
                      }
                    }
                    options={[
                      {
                        label: '선택',
                        value: '',
                      },
                      {
                        label: '별도',
                        value: '별도',
                      },
                      { label: '포함', value: '포함' },
                    ]}
                    onChange={(e) => onUpdate('vat', e.value)}
                  />
                </div>
              </RentTableContent>
            </Row>
          </>
        )}
      </Table>
    </Wrapper>
  )
}

const PriceInput = ({ input, wide, appendText }) => {
  return (
    <PriceInputWrapper>
      <WonPrice>
        {input}
        <span>원</span>
      </WonPrice>
      <span>{appendText}</span>
    </PriceInputWrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const PrependText = styled.span`
  margin-right: 114px;
  ${({ wide }) => wide && `margin-right: 164px;`}
`

const WonPrice = styled.div`
  display: flex;
  align-items: center;

  & input {
    font-variant-numeric: tabular-nums;
  }
  & > span {
    padding-left: 4px;
  }
`

const PriceInputWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledEditButton = styled(EditButton)`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`

const Recipient = styled.div`
  display: flex;
  align-items: center;
`

const DateWrap = styled.div`
  display: flex;
  align-items: center;

  input {
    margin: 0;
  }
`

const RentTableContent = styled(TableContent)`
  & > div {
    display: flex;
    align-items: center;
  }
`

const RentProvision = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

export default NoteDocsContractContent
