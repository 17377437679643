import MapContext from 'contexts/map'
import { setMapClickDisabled } from 'modules/map'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { useContext, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const MapFavoriteCustomOverlay = ({
  payload,
  children,
  draggable,
  overlayRef,
  onClick,
  onInit,
  addressSrl,
  ZIndex,
  resetMarkerFlag,
  setResetMarkerFlag,
}) => {
  const {
    state: { map },
  } = useContext(MapContext)
  const { kakao } = window

  const [marker, setMarker] = useState(null)
  const dispatch = useDispatch()
  const openedMarker = useSelector((state) => state.note.openedMarker)

  const { addressSrl: currentAddressSrl } = useNoteMatch()

  const createMarker = (payload) => {
    const mergedPayload = {
      ...payload,
      map,
      content: overlayRef.current,
    }
    // 마커 세팅
    const marker = new kakao.maps.CustomOverlay(mergedPayload)
    marker.setMap(map)
    return marker
  }

  const draggableHandler = () => {
    if (!draggable) return { clearDraggable: undefined }

    const content = overlayRef.current
    // 커스텀 오버레이의 드래그 시작좌표
    let startX, startY, clickEnabled

    // target node에 이벤트 핸들러를 등록하는 함수힙니다
    function addEventHandle(target, type, callback) {
      if (target.addEventListener) {
        target.addEventListener(type, callback)
      } else {
        target.attachEvent('on' + type, callback)
      }
    }

    // 커스텀 오버레이에 mousedown이벤트를 등록합니다
    addEventHandle(content, 'mousedown', onMouseDown)
    addEventHandle(content, 'mouseup', onMouseUp)
    addEventHandle(content, 'touchstart', onMouseDown)
    addEventHandle(content, 'touchend', onMouseUp)
    addEventHandle(content, 'click', handleOnClick)

    // 커스텀 오버레이에 mousedown 했을 때 호출되는 핸들러 입니다
    function onMouseDown(e) {
      // 클릭이 지도로 전달되지 않도록 일시적으로 이벤트를 마비시킨다
      kakao.maps.event.disableClick()
      dispatch(setMapClickDisabled(true))

      // mousedown된 좌표를 설정합니다
      startX = e.clientX
      startY = e.clientY
    }

    // mouseup 했을 때 호출되는 핸들러 입니다
    function onMouseUp(e) {
      // mouseUp했을 때의 좌표와 mouseUp했을 때의 좌표가 동일하면 summary요청 발생
      if (startX === e.clientX && startY === e.clientY) {
        clickEnabled = true
      } else {
        clickEnabled = false
      }
    }

    // click 핸들러 (방금 드래그한 경우에는 클릭으로 취급하지 않아야 함)
    function handleOnClick(e) {
      // e.stopPropagation()
      if (clickEnabled && onClick) {
        onClick()
      }

      setTimeout(() => {
        dispatch(setMapClickDisabled(false))
      }, 1000)
    }

    // target node에 등록된 이벤트 핸들러를 제거하는 함수힙니다
    function removeEventHandle(target, type, callback) {
      if (target.removeEventListener) {
        target.removeEventListener(type, callback)
      } else {
        target.detachEvent('on' + type, callback)
      }
    }

    const clearDraggable = () => {
      // 커스텀 오버레이에 mousedown이벤트를 등록합니다
      removeEventHandle(content, 'mousedown', onMouseDown)
      removeEventHandle(content, 'mouseup', onMouseUp)
      removeEventHandle(content, 'touchstart', onMouseDown)
      removeEventHandle(content, 'touchend', onMouseUp)
      removeEventHandle(content, 'click', handleOnClick)
    }

    return { clearDraggable }
  }

  useEffect(() => {
    const marker = createMarker(payload)
    setMarker(marker)
    if (onInit) onInit(marker)

    if (overlayRef.current.classList.contains('note-marker-bounce')) {
      marker.setZIndex(100000)
    } else {
      marker.setZIndex(ZIndex || 9999)
    }

    if (addressSrl == currentAddressSrl) marker.setZIndex(11)

    const { clearDraggable } = draggableHandler()

    return () => {
      // unmount될 때 지도상에서 제거
      setMarker(null)
      marker.setMap(null)
      if (clearDraggable) clearDraggable()
    }
  }, [payload, openedMarker])

  useEffect(() => {
    if (resetMarkerFlag) {
      if (marker) {
        marker.setMap(null)
        setMarker(null)
      }
      setResetMarkerFlag(false)
    }
  }, [resetMarkerFlag])

  return <div>{children}</div>
}

export default MapFavoriteCustomOverlay
