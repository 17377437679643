import { useMemo } from 'react'

import theme from 'lib/styles/theme'
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  CartesianAxis,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'

import priceFormat from 'lib/utils/priceFormat'
import CustomizedLegend from './CustomizedLegend'
import CustomTooltip from './CustomTooltip'
import styled from 'styled-components'

const SummaryGraph = ({
  data = [],
  isTotal,
  isSquareMeter,
  landArea,
  prices,
}) => {
  const getReferencePurchase = (data) => {
    const segment = {
      first: [],
      last: [],
    }

    data.forEach((e, index) => {
      if (!e.purchase) return

      // 가장 앞의 실거래 값
      if (segment.first.length === 0 && index < data.length - 1) {
        segment.first = [
          { x: data[0].name, y: e.purchase },
          { x: e.name, y: e.purchase },
        ]
      }

      // 가장 뒤의 실거래 값 (실거래 값이 있을 때마다 덮어씌움)
      segment.last = [
        { x: e.name, y: e.purchase },
        { x: data?.[data.length - 1]?.name || null, y: e.purchase },
      ]
    })

    return segment
  }
  const referencePurchase = useMemo(
    () => getReferencePurchase(data),
    [data],
  )

  const dataLabels = {
    purchase: '실거래가',
    addressHistory: '개별공시지가',
    estimatePrice: '추정가',
  }

  const getLabelPrepend = () => {
    if (isTotal) return null

    return isSquareMeter ? '㎡당 ' : '평당 '
  }

  const tooltipFormatter = (value, name, props) => {
    const unit = 10000
    if (!value || value < 0) {
      return ['정보 없음', dataLabels[name]]
    }
    // 만단위로 반올림
    const mathedPrice = Math.round(value / unit) * unit
    const formattedPrice = priceFormat(mathedPrice)

    return [formattedPrice, dataLabels[name]]
  }

  const thickFormatter = (value) => {
    if (isNaN(value)) return null
    if (value < 100) {
      return '0원'
    }

    if (value < 10000000) {
      const thounsand = value / 10000
      return thounsand.toFixed(0) + '만'
    }

    if (value < 100000000) {
      const thounsand = value / 10000000
      return thounsand.toFixed(1) + '천'
    }
    return Math.ceil((value / 100000000) * 10) / 10 + '억'
  }

  if (data.length === 0) return null

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height={280}
        // aspect={1 / 0.7}
      >
        <ComposedChart
          // width={400}
          // height={300}
          data={data}
          margin={{
            top: 20,
            right: 25,
            bottom: 20,
            left: 8,
          }}
        >
          <CartesianGrid
            height={280}
            vertical={false}
            stroke={null}
            // stroke={theme.colors.gray[100]}
          />
          <XAxis
            dataKey="name"
            xAxisId={0}
            tick={{ fill: theme.colors.gray[500], fontSize: '12px' }}
            tickLine={false}
            axisLine={false}
            height={60}
            tickSize={12}
            // padding={{ bottom: 10 }}
          />
          <XAxis
            dataKey="name"
            xAxisId={1}
            hide
            // height={60}
            // tickSize={12}
            // padding={{ bottom: 10 }}
          />

          <YAxis
            tickFormatter={thickFormatter}
            tick={{ fill: theme.colors.gray[500], fontSize: '12px' }}
            tickLine={false}
            axisLine={false}
          />
          <CartesianAxis />
          <Legend
            payload={[
              {
                value: '실거래가',
                type: 'line',
                id: 'purchase',
                color: '#4378FF',
              },
              {
                value: '개별공시지가',
                type: 'bar',
                id: 'addressHistory',
                color: theme.colors.gray[400],
              },
              {
                value: '추정가',
                type: 'bar',
                id: 'estimatePrice',
                color: theme.colors.primary[400],
              },
            ]}
            iconType="square"
            iconSize={10}
            content={<CustomizedLegend />}
          />

          <defs>
            <linearGradient
              id="colorPurchase"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="0%" stopColor="#4378FF" stopOpacity={0.5} />
              <stop offset="100%" stopColor="#4378FF" stopOpacity={0} />
            </linearGradient>
            <linearGradient
              id="colorAddressHistory"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={theme.colors.gray[400]}
                stopOpacity={0.5}
              />
              <stop
                offset="95%"
                stopColor={theme.colors.gray[400]}
                stopOpacity={0.1}
              />
            </linearGradient>

            <linearGradient
              id="colorEstimatePrice"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="0%" stopColor={theme.colors.primary[400]} />
              <stop offset="100%" stopColor="#36CCC3" />
            </linearGradient>
          </defs>

          <Area
            type="step"
            dataKey="addressHistory"
            stroke={theme.colors.gray[400]}
            strokeWidth={3}
            strokeOpacity={1}
            fillOpacity={1}
            fill="url(#colorAddressHistory)"
            xAxisId={1}
            activeDot={{
              stroke: 'white',
              strokeWidth: 2,
              r: 6,
              fill: theme.colors.gray[400],
            }}
            animationDuration={800}
          />

          <ReferenceLine
            segment={referencePurchase.first}
            stroke="#4378FF"
            strokeDasharray="3 3"
          />
          <ReferenceLine
            segment={referencePurchase.last}
            stroke="#4378FF"
            strokeDasharray="3 3"
          />

          <Bar
            dataKey="estimatePrice"
            barSize={20}
            opacity={1}
            xAxisId={0}
            radius={[4, 4, 0, 0]}
            fill="url(#colorEstimatePrice)"
            label={{
              position: 'top',
              offset: 10,
              fill: theme.colors.primary[400],
              fontSize: 12,
              formatter: thickFormatter,
            }}
            isAnimationActive={false}
            animationDuration={800}
            // stroke={theme.colors.primary[400]}
          />

          <Area
            type="basic"
            dataKey="purchase"
            stroke="#4378FF"
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorPurchase)"
            dot={{
              stroke: '#ffffff',
              strokeWidth: 2,
              r: 6,
              fill: '#4378FF',
            }}
            activeDot={{
              stroke: '#ffffff',
              strokeWidth: 2,
              r: 6,
              fill: '#4378FF',
            }}
            connectNulls
            isAnimationActive={false}
            animationDuration={800}
          />

          <Tooltip
            offset={20}
            cursor={{
              stroke: theme.colors.primary[400],
              strokeWidth: 1,
            }}
            formatter={tooltipFormatter}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: theme.colors.gray[200],
              borderRadius: '8px',
              boxShadow: theme.shadow['card-2'],
              padding: '0.5rem 1rem',
            }}
            content={
              <CustomTooltip
                getLabelPrepend={getLabelPrepend}
                tooltipFormatter={tooltipFormatter}
                landArea={landArea}
                prices={prices}
              />
            }
          />

          <ReferenceDot
            x={data[data.length - 1]?.name}
            y={data[data.length - 1]?.estimatePrice}
            r={4}
            fill="white"
            stroke={theme.colors.primary[400]}
            // strokeOpacity={0.2}
            strokeWidth={1}
            boxShadow={theme.shadow['card-2']}
            activeDot={{
              stroke: 'white',
              strokeWidth: 2,
              r: 6,
              fill: theme.colors.gray[600],
            }}
          />

          {/* <Line type="monotone" dataKey="uv" stroke="#ff7300" /> */}
          {/* <Scatter dataKey="cnt" fill="red" /> */}
        </ComposedChart>
      </ResponsiveContainer>

      {/* <Divider /> */}
    </>
  )

  // return (
  //   <ReactApexChart type="line" options={options} series={series} />
  // )
}

const Divider = styled.div`
  margin-left: 1.25rem;
  padding-bottom: 10px;
  border-top: 1px solid ${theme.colors.gray[100]};
`

export default SummaryGraph
