import moment from 'moment'
import {
  Date,
  Diff,
  Item,
  Office,
  Price,
  Type,
} from './SummaryRpDetailTableItem'
import { Tag } from 'components/design'
import priceFormat from 'lib/utils/priceFormat'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import { ReactComponent as UpIcon } from 'assets/icon/up.svg'
import { ReactComponent as DownIcon } from 'assets/icon/down.svg'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 매매 전용 실거래가 2뎁스
 */
const SummaryRpDetailTableItemSale = ({
  data,
  diff,
  isExceptionalData,
  date,
  tag,
  isJickGeoRae,
}) => {
  const isDongho = useRouteMatch('/note/v2/dongho/:id?/:dong?')
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    setIsOpened(false)
  }, [data])

  const onClickOpen = () => {
    setIsOpened(!isOpened)
  }

  const getPriceFormat = (value) => {
    return (
      <>
        {value == 0 ? <></> : value > 0 ? <UpIcon /> : <DownIcon />}
        {priceFormat(Math.abs(value))}
      </>
    )
  }

  if (isExceptionalData)
    return (
      <>
        <Item mobilestyle={isDongho} multiline>
          <BasicInfo>
            <Date>
              {moment(data.contract_date, 'YYYYMMDD').format(
                'YY.MM.DD',
              )}
            </Date>
            <Type type={data?.type}>{data?.type}</Type>
            <PriceWrapper mobilestyle={isDongho}>
              <PriceInfo>
                {data.contract_type == '직거래' && (
                  <Tag circle size="small" color="pink">
                    직
                  </Tag>
                )}
                <Price>
                  <PriceInfo>{priceFormat(data?.price)}</PriceInfo>
                  {diff ? <Diff>{getPriceFormat(diff)}</Diff> : null}
                </Price>
              </PriceInfo>
              {data.registration_date && (
                <Tag label="등기" size="small" color="gray">
                  {moment(data.registration_date, 'YYYYMMDD').format(
                    'YY.MM.DD',
                  )}
                </Tag>
              )}
            </PriceWrapper>
            {data?.office_loc && (
              <Open
                className={
                  isOpened ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
                }
                onClick={onClickOpen}
              />
            )}
          </BasicInfo>
        </Item>
        {isOpened && (
          <NextLineWrapper alignend={!data?.office_loc}>
            {data?.office_loc && (
              <Office>
                <OfficeTitle>중개사</OfficeTitle>
                <span>{data.office_loc}</span>
              </Office>
            )}
          </NextLineWrapper>
        )}
      </>
    )
  else
    return (
      <>
        <Item mobilestyle={isDongho}>
          <BasicInfo>
            <Date>
              {date
                ? moment(date, 'YYYYMMDD').format('YY.MM.DD')
                : null}
            </Date>
            <Type type={data?.type}>{data.type}</Type>
            <PriceWrapper>
              <PriceInfo>
                {isJickGeoRae && (
                  <Tag circle size="small" color="pink">
                    직
                  </Tag>
                )}
                <Price>
                  <PriceInfo>{priceFormat(data?.price)}</PriceInfo>
                  {diff ? <Diff>{getPriceFormat(diff)}</Diff> : null}
                </Price>
              </PriceInfo>
              <TagWrapper right={data?.office_loc ? 'Y' : 'N'}>
                {tag}
              </TagWrapper>
            </PriceWrapper>
            {data?.office_loc && (
              <Open
                className={
                  isOpened ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
                }
                onClick={onClickOpen}
              />
            )}
          </BasicInfo>
        </Item>
        {isOpened && (
          <NextLineWrapper alignend={!data?.office_loc}>
            {data?.office_loc && (
              <Office>
                <OfficeTitle>중개사</OfficeTitle>
                <span>{data.office_loc}</span>
              </Office>
            )}
          </NextLineWrapper>
        )}
      </>
    )
}

const NextLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px dashed ${theme.colors.gray[200]};

  ${({ alignend }) =>
    alignend &&
    `
    align-self: end;
  `}
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  ${media.mediumS`
    ${({ mobilestyle }) =>
      mobilestyle &&
      `
      flex-wrap: wrap;
      gap: 2px;
    `}
  `}
`

const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

const Open = styled.div`
  font-size: 14px;
  color: ${theme.colors.gray[600]};
  margin-left: 10px;
  cursor: pointer;
`

const TagWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;

  ${({ right }) =>
    right &&
    `
      right: 30px;
  `}

  ${media.mediumS`
    top: 0; 
    transform: none;
    right: 0;
  `}
`

const OfficeTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-weight: 500;
  margin-right: 6px;
`

const BasicInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

export default SummaryRpDetailTableItemSale
