import { Modal } from 'components/design'
import { useSelector } from 'react-redux'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { setNotPermitRegiModalVisible } from 'modules/noteAd'

const NoteAdNotPermitRegiModal = () => {
  const visible = useSelector(
    (state) => state.noteAd.notPermitRegiModalVisible,
  )

  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(setNotPermitRegiModalVisible(false))
  }

  const root = document.getElementById('modal')

  return ReactDOM.createPortal(
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={onClose}
      title="매물 등록 제한 안내"
      cancelHide
      okButtonProps={{
        color: 'blue',
      }}
    >
      신고매물, 동일주소매물이 존재하여 매물등록이 제한되었습니다.
      {/* <br />
      패널티 기간 : TODO */}
    </Modal>,
    root,
  )
}

export default NoteAdNotPermitRegiModal
