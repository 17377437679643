import { Button } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'
import { media } from 'lib/styles/theme'
import styled from 'styled-components'

const NotePaymentAuthButtons = ({ startAuth, onClose, prevButton }) => {
  const isMobile = useIsMobile()
  /**
   * 본인인증 하기 버튼 클릭
   */
  const onClickNext = () => {
    startAuth()
  }

  return (
    <Wrapper>
      <Button
        color="blue"
        onClick={onClickNext}
        size={isMobile ? 'medium' : 'xlarge'}
      >
        본인인증하기
      </Button>
      {prevButton && (
        <Button
          color="blue"
          onClick={onClose}
          plain
          size={isMobile ? 'medium' : 'xlarge'}
        >
          이전 화면으로 가기
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 24px;

  ${media.mediumS`
    gap: 12px;
    flex-direction: column;
  `}
`

export default NotePaymentAuthButtons
