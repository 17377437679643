import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  position: relative;
  margin-left: -1px;
  &:first-child {
    border-radius: 6px 0 0 6px;
  }
  &:last-child {
    border-radius: 0 6px 6px 0;
  }

  & > i,
  & > svg {
    color: ${theme.colors.gray[500]};
    font-size: 1rem;
    margin-right: 0.625rem;
  }

  & > svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  ${({ active }) =>
    active &&
    `
    & > i {
      color: ${theme.colors.primary[400]};
    }
  `}
`

const HeaderButton = ({ active, children, onClick }) => {
  return (
    <StyledButton active={active} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

export default HeaderButton
