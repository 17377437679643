import axios from 'axios'

// 나의 날짜별 사진 목록
export const getMyImagesByDate = async ({ _rx_csrf_token }) =>
  await axios.post('/jmapnote/api/dashboard/imageList', {
    _rx_csrf_token,
  })

// 나의 등기부 목록
export const getMyDeunggibuList = async ({
  _rx_csrf_token,
  count,
  page,
}) =>
  await axios.post('/jmapnote/api/dashboard/deunggibuList', {
    _rx_csrf_token,
    count,
    page,
  })

// 유료 동호단지 목록
export const getPlusDonghoDanjis = async ({
  _rx_csrf_token,
  count,
  page,
}) =>
  await axios.post('/jmapnote/api/dashboard/donghoList', {
    _rx_csrf_token,
    count,
    page,
  })
