import styled from 'styled-components'
import NoteAdColumnPropertyType from '../columns/basic/NoteAdColumnPropertyType'
import { Table, TableWrapper, Title } from './NoteAdFormStyle'
import NoteAdColumnAddress from '../columns/basic/NoteAdColumnAddress'
import NoteAdColumnDirection from '../columns/basic/NoteAdColumnDirection'
import NoteAdColumnContractType from '../columns/price/NoteAdColumnContractType'
import NoteAdColumnSalePrice from '../columns/price/NoteAdColumnSalePrice'
import NoteAdColumnDeposit from '../columns/price/NoteAdColumnDeposit'
import NoteAdColumnRent from '../columns/price/NoteAdColumnRent'
import NoteAdColumnConvertJeonse from '../columns/price/NoteAdColumnConvertJeonse'
import NoteAdColumnLoan from '../columns/price/NoteAdColumnLoan'
import NoteAdColumnAlreadyDeposit from '../columns/price/NoteAdColumnAlreadyDeposit'
import NoteAdColumnAlreadyRent from '../columns/price/NoteAdColumnAlreadyRent'
import NoteAdColumnCotractDateInfo from '../columns/price/NoteAdColumnCotractDateInfo'
import NoteAdColumnLivingFacility from '../columns/facility/NoteAdColumnLivingFacility'
import NoteAdColumnSecureFacility from '../columns/facility/NoteAdColumnSecureFacility'
import NoteAdColumnEtcFacility from '../columns/facility/NoteAdColumnEtcFacility'
import NoteAdColumnMoveDate from '../columns/detail/NoteAdColumnMoveDate'
import NoteAdColumnCharacter from '../columns/detail/NoteAdColumnCharacter'
import NoteAdColumnDetail from '../columns/detail/NoteAdColumnDetail'
import NoteAdColumnPictures from '../columns/detail/NoteAdColumnPictures'
import theme from 'lib/styles/theme'
import NoteAdColumnStoreInfo from '../columns/store/NoteAdColumnStoreInfo'
import NoteAdColumnContact from '../columns/store/NoteAdColumnContact'
import NoteAdColumnBuildingName from '../columns/basic/NoteAdColumnBuildingName'
import NoteAdColumnBuildingDate from '../columns/basic/NoteAdColumnBuildingDate'
import NoteAdColumnExclusiveArea from '../columns/basic/NoteAdColumnExclusiveArea'
import NoteAdColumnParkingForRoom from '../columns/basic/NoteAdColumnParkingForRoom'
import NoteAdColumnAirConditioningFacility from '../columns/facility/NoteAdColumnAirConditioningFacility'
import NoteAdColumnHeatingFuel from '../columns/facility/NoteAdColumnHeatingFuel'
import NoteAdColumnHeatingType from '../columns/facility/NoteAdColumnHeatingType'
import NoteAdColumnLandArea from '../columns/basic/NoteAdColumnLandArea'
import NoteAdColumnTotalFloorArea from '../columns/basic/NoteAdColumnTotalFloorArea'
import NoteAdColumnBuildingArea from '../columns/basic/NoteAdColumnBuildingArea'
import NoteAdColumnGroundTotalFloorCount from '../columns/basic/NoteAdColumnGroundTotalFloorCount'
import NoteAdColumnUndergroundTotalFloorCount from '../columns/basic/NoteAdColumnUndergroundTotalFloorCount'
import NoteAdColumnAddressDetail from '../columns/basic/NoteAdColumnAddressDetail'
import NoteAdColumnVerification from '../columns/verification/NoteAdColumnVerification'
import NoteAdColumnZoneUse from '../columns/basic/NoteAdColumnZoneUse'
import NoteAdColumnBuildingStructure from '../columns/basic/NoteAdColumnBuildingStructure'
import NoteAdColumnStoreCount from '../columns/basic/NoteAdColumnStoreCount'
import NoteAdColumElectricity from '../columns/basic/NoteAdColumElectricity'
import NoteAdColumnBuildingUseDaejang from '../columns/basic/NoteAdColumnBuildingUseDaejang'
import NoteAdColumnFee, {
  default as NoteAdColumnPriceFee,
} from '../columns/price/NoteAdColumnFee'
import NoteAdColumnFloorExposureForStore from '../columns/basic/NoteAdColumnFloorExposureForStore'
import NoteAdPriceDesc from '../columns/price/NoteAdPriceDesc'
import NoteAdStoreDesc from '../columns/store/NoteAdStoreDesc'
import NoteAdColumnFeeDetailType from '../columns/price/NoteAdColumnFeeDetailType'

/**
 * 광고관리 매물등록 테이블
 * 건물(빌딩, 숙박, 공장, 창고)
 */
const NoteAdFormBuilding = () => {
  return (
    <Wrapper>
      <Title>1. 기본정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnPropertyType />
          <NoteAdColumnAddress />
          <NoteAdColumnAddressDetail half />
          <NoteAdColumnBuildingUseDaejang
            half
            columnKey="article.lawUsageCode"
            required
          />
          <NoteAdColumnBuildingDate />
          <NoteAdColumnBuildingStructure />
          <NoteAdColumnZoneUse />
          <NoteAdColumnLandArea required columnKey="article.spc2" />
          <NoteAdColumnTotalFloorArea required />
          <NoteAdColumnBuildingArea />
          <NoteAdColumnExclusiveArea columnKey="article.spc4" />
          <NoteAdColumnGroundTotalFloorCount />
          <NoteAdColumnUndergroundTotalFloorCount />
          <NoteAdColumnFloorExposureForStore />
          <NoteAdColumnStoreCount />
          <NoteAdColumnPriceFee half />
          <NoteAdColumnDirection noOption />
          <NoteAdColumElectricity />
          <NoteAdColumnParkingForRoom />
        </Table>
      </TableWrapper>
      <Title attachDesc>2. 거래정보</Title>
      <NoteAdPriceDesc />
      <TableWrapper>
        <Table>
          <NoteAdColumnContractType />
          <NoteAdColumnSalePrice required />
          <NoteAdColumnDeposit />
          <NoteAdColumnRent />
          <NoteAdColumnConvertJeonse />
          <NoteAdColumnLoan />
          <NoteAdColumnAlreadyDeposit />
          <NoteAdColumnAlreadyRent />
        </Table>
      </TableWrapper>
      <Title>3. 시설정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnHeatingType half />
          <NoteAdColumnHeatingFuel half />
          <NoteAdColumnAirConditioningFacility />
          <NoteAdColumnSecureFacility />
          <NoteAdColumnEtcFacility />
        </Table>
      </TableWrapper>
      <Title>4. 상세정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnMoveDate />
          <NoteAdColumnCotractDateInfo />
          <NoteAdColumnCharacter />
          <NoteAdColumnDetail />
          <NoteAdColumnPictures />
        </Table>
      </TableWrapper>
      <Title attachDesc>5. 중개업소 정보</Title>
      <NoteAdStoreDesc />
      <TableWrapper>
        <Table>
          <NoteAdColumnStoreInfo />
          <NoteAdColumnContact />
        </Table>
      </TableWrapper>
      <Title>6. 검증 방식</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnVerification />
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const Desc = styled.div`
  display: flex;
  margin-left: 4px;
  margin-bottom: 6px;
  color: ${theme.colors.tint.danger};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;

  &::before {
    content: '·';
    font-size: 30px;
    margin-right: 7px;
  }
`

export default NoteAdFormBuilding
