import { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NotePaymentBillingContainer from 'containers/notev2/NotePaymentBillingContainer'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import NotePaymentPlanListFree from './NotePaymentPlanListFree'
import NotePaymentPlanListBasic from './NotePaymentPlanListBasic'
import NotePaymentPlanListAllInOne from './NotePaymentPlanListAllInOne'

/**
 * 요금제 변경 페이지 블럭 list
 * @param onClickCancelPlan 프리요금제로 변경
 * @returns
 */
const NotePaymentPlanList = ({
  setType,
  setAmount,
  setMonth,
  setAllinoneMemberCount,
  setIsOpenWarningModal,
  setBillingResult,
  setIsDirectPayment,
}) => {
  return (
    <NotePaymentBillingContainer
      setType={setType}
      setAmount={setAmount}
      setMonth={setMonth}
      setAllinoneMemberCount={setAllinoneMemberCount}
      setBillingResult={setBillingResult}
      setIsOpenWarningModal={setIsOpenWarningModal}
      setIsDirectPayment={setIsDirectPayment}
    >
      {({ onClickPayment, isLoading }) => {
        return (
          <Content>
            {isLoading && <FullScreenLoading />}
            <CardWrapper>
              <NotePaymentPlanListFree />
              <NotePaymentPlanListBasic
                onClickPayment={onClickPayment}
              />
              <NotePaymentPlanListAllInOne
                onClickPayment={onClickPayment}
              />
            </CardWrapper>
          </Content>
        )
      }}
    </NotePaymentBillingContainer>
  )
}

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${media.smallPc`
    flex-direction: column;
    gap: 12px;
  `}
`

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  ${media.smallPc`
    width: 100%;
    justify-content: center;
    gap: 12px;
  `}

  ${media.mediumM`
    flex-direction: column;
    gap: 12px;
  `}

  ${media.mediumS`
    flex-direction: column;
    gap: 12px;    
    align-items: center;
  `}
`

export default NotePaymentPlanList
