/**
 * 매물 광고 폼에 사용되는 불변성 옵션 목록
 */
export const options = {
  현관종류: ['계단식', '복도식', '복합식'],
  원룸형태: ['오픈형', '분리형'],
  난방방식: [
    '개별난방',
    '중앙난방',
    '개별냉난방',
    '중앙냉난방',
    '지역난방',
  ],
  난방연료: [
    '도시가스',
    '열병합',
    '기름',
    '전기',
    '심야전기',
    '태양열',
    'LPG',
    '지열',
  ],
  냉방종류: ['벽걸이에어컨', '스탠드에어컨', '천장에어컨'],
  생활시설: [
    '침대',
    '책상',
    '옷장',
    '붙박이장',
    '식탁',
    '소파',
    '신발장',
    '냉장고',
    '세탁기',
    '건조기',
    '샤워부스',
    '욕조',
    '비데',
    '싱크대',
    '식기세척기',
    '가스레인지',
    '인덕션레인지',
    '전자레인지',
    '가스오븐',
  ],
  보안시설: [
    '경비원',
    '비디오폰',
    '인터폰',
    '카드키',
    'CCTV',
    '사설경비',
    '현관보안',
    '방범창',
  ],
  기타시설: [
    '엘리베이터',
    '화재경보기',
    '베란다',
    '테라스',
    '마당',
    '무인택배함',
  ],
  '분양 구분': {
    1: '일반',
    2: '조합원',
  },
  '연락처 노출 선택': {
    A: '대표전화 + 휴대폰번호',
    R: '대표전화번호만',
    C: '휴대폰번호만',
  },
  융자여부: { 0: '없음', 1: '시세30%미만', 2: '시세30%이상' },
  검증방식: {
    N: '신홍보확인서',
    D: '홍보확인서',
    T: '전화확인',
    M: '모바일확인',
    O: '집주인확인(모바일확인 V2)',
    S: '현장확인',
  },
  '매물 소유자': {
    0: '개인매물',
    1: '법인매물',
    2: '외국인매물',
    3: '위임장매물',
  },
  소유자관계: [
    '직접입력',
    '본인',
    '아버지',
    '아들',
    '세입자',
    '대표',
    '직원',
    '종중원',
    '조합원',
    '분양받은자',
  ],
  이동통신사: {
    '01': 'SKT',
    '02': 'KT',
    '03': 'LGU',
    '04': 'SKT 알뜰폰',
    '05': 'KT 알뜰폰',
    '06': 'LGU 알뜰폰',
  },
  성별: {
    M: '남',
    F: '여',
  },
  부동산정의용도: ['주거용', '업무용', '겸용', '숙박시설'],
  방거실형태: ['오픈형', '분리형'],
  사용전력: {
    '25-': '25kw 이하',
    '50-': '25 ~ 50',
    '100-': '50 ~ 100',
    '1000-': '100 ~ 1000',
    '10000-': '1000 ~ 10000',
    '10000+': '10000kw 이상',
  },
  토지옵션: [
    '국토이용',
    '도시계획',
    '건축허가',
    '토지거래허가',
    '진입도로',
  ],
  //아파트용(거래정보) 관리비 포함 내역
  '관리비 포함 내역': ['전기료', '가스', '수도', '인터넷', 'TV'],
  '계약기간 구분': { 1: '이내', 2: '이후' },
  거래구분: {
    매매: 'A1',
    전세: 'B1',
    월세: 'B2',
    단기임대: 'B3',
  },
}
