import { Dropdown, message } from 'antd'
import theme from 'lib/styles/theme'
import React, { useState } from 'react'
import styled from 'styled-components'
import CustomAdButtonsCell from '../NoteAd/list/customCell/CustomAdButtonsCell'
import { Modal, Tag } from 'components/design'
import { getKeyByValueInclude } from 'lib/utils/notev2/util'
import { colorGroup } from 'lib/utils/notev2/note2Constants'
import { ReactComponent as NoteIcon } from 'assets/icon/note/ad/note-srl.svg'
import { ReactComponent as NaverIcon } from 'assets/icon/note/ad/naver-logo-simple.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const simplifyType = {
  등기부등본: '등기',
  토지이용계획원: '이용',
  '토지·건축물대장': '대장',
}
const NoteAdListMobileItemMore = ({ data, replaceSearch, input }) => {
  const history = useHistory()
  const [modalVisible, setModalVisible] = useState(false)

  const onCloseModal = (e) => {
    e.stopPropagation()
    setModalVisible(false)
  }

  const onClickNote = () => {
    if (!data?.channel_srl || !data?.note_srl) {
      message.error('잘못된 요청입니다')
      return
    }
    history.push(
      `/note/v2/notes/${data?.channel_srl}/${data?.address_srl || 0}/${
        data?.note_srl
      }`,
    )
  }

  const onClickNaver = () => {
    //history.push(``)
    alert('TODOODODODOD')
  }

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Icon
        onClick={(e) => {
          e.stopPropagation()
          setModalVisible(true)
        }}
        className="fas fa-ellipsis-v"
      ></Icon>
      <Modal
        footer={null}
        visible={modalVisible}
        onCancel={onCloseModal}
        title="광고설정"
      >
        <AddressWrapper>
          <Addresses>
            <StyledTag
              size="xs"
              color={getKeyByValueInclude(
                colorGroup,
                data.property_type,
              )}
            >
              {data.property_type}
            </StyledTag>
            <Address>
              {data.eupmyeondong} {data.display_address}
            </Address>
          </Addresses>
          <Alias>{data.building_name}</Alias>
        </AddressWrapper>
        <Block>
          <BlockTitle>매물번호</BlockTitle>
          <NoteNumber onClick={onClickNote}>
            {data.note_srl ? (
              <>
                <NoteIcon width={10} height={10} />
                {data.note_srl}
              </>
            ) : (
              <>&ndash;</>
            )}
          </NoteNumber>
        </Block>
        <Block>
          <BlockTitle>네이버 광고번호</BlockTitle>
          <NoteNumber type="naver">
            {data.nuid ? (
              <>
                <NaverIcon
                  onClick={onClickNaver}
                  fill="#03BC63"
                  width={10}
                  height={10}
                />
                <div className="adSrlValue">{data.nuid}</div>
              </>
            ) : (
              <>&ndash;</>
            )}
          </NoteNumber>
        </Block>
        <ButtonWrapper>
          <CustomAdButtonsCell
            data={data}
            input={input}
            replaceSearch={replaceSearch}
          />
        </ButtonWrapper>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

const Icon = styled.i`
  padding: 0 16px 10px 20px;
  font-size: 14px;
  color: ${theme.colors.gray[600]};
`

const StyledTag = styled(Tag)`
  flex-shrink: 0;
  font-size: 10px;
  padding: 2px;
`

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 13px;
`

const Addresses = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const Address = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
`

const Alias = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
`

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 34px;
  padding: 10px 14px;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: ${theme.colors.gray[100]};
`

const BlockTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
`

const NoteNumber = styled.div`
  display: flex;
  align-items: center;
  color: #9355e2;
  text-decoration: underline;
  text-underline-position: bottom;
  line-height: 100%;
  padding: 0 6px;
  font-size: 12px;

  &:first-child {
    border-bottom: 1px solid ${theme.colors.gray[200]};
  }

  ${({ type }) =>
    type == 'naver' &&
    `
      color: #00a32a;
      div {
        text-align: left;
      }
  `};

  ${({ active }) =>
    active &&
    `
      cursor: pointer;
  `};

  svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    flex-shrink: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  margin: 0 -6px;

  button {
    width: 33.3%;
    height: 30px;
    font-size: 12px;
  }
`

export default NoteAdListMobileItemMore
