import useIsMobile from 'lib/hooks/useIsMobile'
import theme, { media } from 'lib/styles/theme'
import { setHeight, setIsVisible } from 'modules/notice'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const NoticeBar = () => {
  const isVisible = useSelector((state) => state.notice.isVisible)
  const timestamp = useSelector((state) => state.notice.timestamp)
  const text = useSelector((state) => state.notice.text)
  const isMapError = useSelector((state) => state.map.isMapError)

  const isMobile = useIsMobile()
  const ref = useRef(null)

  const dispatch = useDispatch()

  //여기서 notice visible 조정
  useEffect(() => {
    if (!timestamp) return
    //notice를 끄려면 이 코드를 주석처리하세요(default : visible false)
    dispatch(
      setIsVisible(
        isMapError ||
          localStorage.getItem('lastNoticeTime') != timestamp,
      ),
    )
  }, [timestamp])

  useEffect(() => {
    isMapError && dispatch(setIsVisible(true))
  }, [isMapError])

  //여기서 notice visible 조정
  useEffect(() => {
    if (!isVisible || !ref) return

    dispatch(setHeight(ref.current.clientHeight))
  }, [timestamp, isVisible])

  const handleClose = (e) => {
    e.preventDefault()
    localStorage.setItem('lastNoticeTime', timestamp)
    dispatch(setIsVisible(false))
  }

  return (
    <Wrapper
      isMobile={isMobile}
      ref={ref}
      visible={isVisible}
      href="https://jootek.com/blog"
      target={isMobile ? '_self' : '_blank'}
    >
      <TextWrap>
        <Text>
          {isMapError
            ? '[알림] 카카오 서비스 장애로 인해 지도와 관련된 부분이 정상적으로 표시되지 않을 수 있습니다.'
            : text}
        </Text>
        <CloseIcon onClick={handleClose}>
          <i className="fa fa-times"></i>
        </CloseIcon>
      </TextWrap>
    </Wrapper>
  )
}

const Wrapper = styled.a`
  position: sticky;
  position: -webkit-sticky;
  display: ${({ visible }) => (visible ? `flex` : `none`)};
  align-items: center;
  justify-content: center;
  font-size: 0.95rem;
  background-color: ${theme.colors.primary[100]};
  color: ${theme.colors.primary[500]};
  text-align: center;
  cursor: pointer;
  z-index: 100;

  ${media.mediumM`
      position: fixed;
      width: 100%;
      padding-left: 0;
  `}

  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 0.85rem;
  `}

  &:hover {
    color: ${theme.colors.base.white};
  }
`

const TextWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`

const Text = styled.div`
  max-width: 75%;
  line-height: 1.25;
  word-break: keep-all;
`

const CloseIcon = styled.div`
  position: absolute;
  padding: 0 0.8rem 0 0.8rem;
  right: 0;
  z-index: 104;

  & > i {
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
  }
`

export default NoticeBar
