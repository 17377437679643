import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const BaseGoBack = () => {
  const history = useHistory()
  const previousPath = useSelector((state) => state.home.previousPath)

  const onClick = () => {
    if (!previousPath) history.push('/')
    else history.goBack()
  }

  return (
    <Wrapper onClick={onClick}>
      <i className="fal fa-angle-left" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-left: 1.5rem;
  margin-right: 0.5rem;

  & > i {
    color: ${theme.colors.gray[500]};
    font-size: 1.75rem;
  }
`
export default BaseGoBack
