import { useRef } from 'react'
import { Button } from 'components/design'
import NotePhotoUploadContainer from 'containers/notev2/NotePhotoUploadContainer'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const NoteFormUploadInput = ({ setLoading }) => {
  const isSharePage = useRouteMatch('/share/:noteSrl/:encryptedKey')
  const inputRef = useRef()
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const onClickUpload = () => {
    inputRef.current.click()
  }

  return (
    <NotePhotoUploadContainer setLoading={setLoading}>
      {({ onChangeFileInput }) => (
        <>
          {!isSharePage?.isExact &&
            channelInfo?.member_type != 'readonly' && (
              <Button color="blue" onClick={onClickUpload}>
                <i className="far fa-plus"></i>
                <span>사진 업로드</span>
              </Button>
            )}
          <input
            ref={inputRef}
            type="file"
            style={{ display: 'none' }}
            accept="image/gif, image/jpg, image/jpeg, image/png, image/bmp, image/webp"
            multiple
            onChange={onChangeFileInput}
          />
        </>
      )}
    </NotePhotoUploadContainer>
  )
}

export default NoteFormUploadInput
