import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 의뢰인 전화번호 cphone
 */
const NoteAdColumnSellerTel = ({ text, isDisabled }) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const ownerType = watch('verification.ownerType')
  const verificationType = watch('verification.type')
  const tradeType = watch('trade.type')

  if (verificationType == 'O' || verificationType == 'M') return null

  const getTitle = () => {
    if (verificationType == 'D') {
      return '의뢰인 전화번호'
    } else if (verificationType == 'N') {
      if (ownerType == '0') {
        return '소유자 전화번호'
      } else {
        return '의뢰인 전화번호'
      }
    } else if (verificationType == 'T') {
      //전화확인
      if (tradeType == 'A1') {
        //매매
        return '매도인 전화번호'
      } else {
        //매매 외
        return (
          <>
            임대 의뢰인
            <br />
            전화번호
          </>
        )
      }
    } else {
      return '매도자 전화번호'
    }
  }

  return (
    <ColumnSet half>
      <ColumnTitle required>{getTitle()}</ColumnTitle>
      <ColumnVal direction="column">
        <div>
          <InputText
            width={InputWidth}
            type="text"
            {...register('verification.cphone')}
            onBlur={(e) => {
              onUpdate('verification.cphone', e.target.value)
            }}
            disabled={isDisabled}
          />
        </div>
        <FormDesc>
          <li>{text ? text : '숫자만 적어주세요.'}</li>
          <li>
            070 및 모든 지역번호로 시작하는 번호를 유선번호로 인정
          </li>
        </FormDesc>

        <ValidateWarn>
          {errors?.verification?.cphone?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnSellerTel
