import FixedLayer from 'components/common/FixedLayer/FixedLayer'
import NoteDetailFormHeaderBar from 'components/jmapnotev2/detail/NoteDetailFormHeaderBar'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import NoteDetailContainer from 'containers/notev2/NoteDetailContainer'
import React, { Suspense, forwardRef, useEffect, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import useIsMobile from 'lib/hooks/useIsMobile'
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min'

const NoteDetail = (
  { searchFn, isOpenDetailRouter, searchAndSetPropertyFilters, mode },
  ref,
) => {
  const history = useHistory()
  const isSharePage = useRouteMatch('/share/:noteSrl/:encryptedKey')
  const isMobile = useIsMobile()
  const NoteDetailForm = useMemo(
    () =>
      React.lazy(() =>
        import('components/jmapnotev2/detail/NoteDetailForm'),
      ),
    [],
  )

  useEffect(() => {
    if (mode != 'sheet') return
    isOpenDetailRouter.current = true
  }, [])

  /**
   * 이동, 복사 삭제 완료 후 callback 닫기
   * @param {*} pOnClose
   * @param {*} methods
   */
  const onCloseTools = (pOnClose, methods) => {
    if (isSharePage?.isExact) {
      history.replace('/home')
    } else {
      pOnClose(methods, mode)
      searchFn && searchFn()
      if (mode == 'sheet') {
        searchAndSetPropertyFilters()
      }
    }
  }

  /**
   * 레이어 닫기
   * @param {*} pOnClose
   * @param {*} methods
   */
  const onLayerClose = (pOnClose, methods) => {
    if (isSharePage?.isExact) {
      history.replace('/home')
    } else {
      pOnClose(methods, mode)

      if (mode == 'sheet') {
        isOpenDetailRouter.current = false
        isMobile && searchFn()
      } else if (mode == 'card') {
        searchFn()
      }
    }
  }

  if (mode == 'sheet' && !ref) return

  return (
    <NoteDetailContainer ref={ref}>
      {({ methods, visible, onClose, callGetNote }) => (
        <FormProvider {...methods}>
          <FixedLayer
            onClose={() => onLayerClose(onClose, methods)}
            customHeader={
              <NoteDetailFormHeaderBar
                searchFn={searchFn}
                mode={mode}
                onCloseLayer={() => onLayerClose(onClose, methods)}
                onCloseTools={() => onCloseTools(onClose, methods)} //매물 이동, 닫기, 삭제 완료 후 닫기 callback
              />
            }
            visible={visible}
          >
            <Suspense fallback={<FullScreenLoading />}>
              <NoteDetailForm
                callGetNote={callGetNote}
                searchFn={searchFn}
                afterPushClose={false}
                onClose={() => onCloseTools(onClose)}
              />
            </Suspense>
          </FixedLayer>
        </FormProvider>
      )}
    </NoteDetailContainer>
  )
}

export default forwardRef(NoteDetail)
