import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { MaxWidthWrapper } from 'components/jmapnotev2/style/NoteStyle'
import NoteMainWrapper from 'components/jmapnotev2/style/NoteMainWrapper'
import NoteAd from 'components/jmapnotev2/NoteAd/NoteAd'
import useIsMobile from 'lib/hooks/useIsMobile'
import MobileChannelHeader from 'components/jmapnotev2/channel/MobileChannelHeader'
import NoteLayerSwipeContainer from 'containers/notev2/NoteLayerSwipeContainer'
import NoteLeftSide from 'components/jmapnotev2/NoteAd/NoteLeftSide'
import Swipe from 'react-easy-swipe'
import NoteLeftSideChannel from 'components/jmapnotev2/NoteAd/NoteLeftSideChannel'

const NoteAdPage = () => {
  const isMobile = useIsMobile()

  return (
    <>
      {isMobile && (
        <MobileChannelHeader
          channel={false}
          customMobileChannel={<NoteLeftSideChannel text="광고 이력" />}
        />
      )}
      <NoteLayerSwipeContainer>
        {({
          listLayerRef,
          onSwipeStartFilter,
          onSwipeMoveFilter,
          onSwipeEndFilter,
          onSwipeStartList,
          onSwipeMoveList,
          onSwipeEndList,
          closeFilter,
        }) => (
          <Wrapper>
            <NoteLeftSide
              onSwipeStart={onSwipeStartFilter}
              onSwipeMove={onSwipeMoveFilter}
              onSwipeEnd={onSwipeEndFilter}
            />
            <Swipe
              onSwipeStart={onSwipeStartList}
              onSwipeMove={onSwipeMoveList}
              onSwipeEnd={onSwipeEndList}
            >
              <NoteMainWrapper column isSwipeable ref={listLayerRef}>
                <NoteAd
                  onSwipeStart={onSwipeStartList}
                  onSwipeMove={onSwipeMoveList}
                  onSwipeEnd={onSwipeEndList}
                />
              </NoteMainWrapper>
            </Swipe>
          </Wrapper>
        )}
      </NoteLayerSwipeContainer>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  padding: 24px 20px 100px 20px;
  margin: 0 auto;
  min-height: 100%;
  background-color: ${theme.allinone.background};

  ${media.smallPc`
    width: 100%;
    padding: 24px 20px 100px 20px !important;
  `}
`

export default NoteAdPage
