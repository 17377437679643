import theme from 'lib/styles/theme'

export const bp = {
  1: 1420,
  2: 1240,
  4: 640,
  5: 440,
}

export const breakPoint = (px) => {
  const addedLeftWidth =
    theme.base.navWidth + theme.base.note.filterWidth

  const result = {
    [bp[1]]: `@media screen and (max-width: ${
      bp[1] + addedLeftWidth
    }px)`,
    [bp[2]]: `@media screen and (max-width: ${
      bp[2] + addedLeftWidth
    }px)`,
    [bp[4]]: `@media screen and (max-width: ${
      bp[4] + addedLeftWidth
    }px)`,
    [bp[5]]: `@media screen and (max-width: ${
      bp[5] + addedLeftWidth
    }px)`,
  }

  return result[px]
}
