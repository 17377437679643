import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 결제관리 메인
 * 올인원 플랜 등기부결제 포인트 합계 금액(대표자만)
 */
const NotePaymentDeunggibuPriceSum = ({ deunggibuPrice = 0 }) => {
  return (
    <Wrapper>
      <Sum>
        <span>최종 결제금액</span>
        <span>{numberWithCommas(deunggibuPrice)}원</span>
      </Sum>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 -30px;
`

const Sum = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;

  & > span:nth-child(2) {
    font-size: 18px;
  }
`

export default NotePaymentDeunggibuPriceSum
