import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;

  justify-content: center;
  align-items: center;

  color: ${({ iconColor }) => (iconColor ? iconColor : 'white')};
  background-color: ${({ color }) => color};
  border-radius: ${theme.borderRadius[1]};

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  & > img {
    width: 1.25rem;
    height: auto;
  }
  & > i {
    font-size: 1.5rem;
  }
`

const SummaryShareButton = ({
  color,
  iconColor,
  children,
  onClick,
}) => {
  return (
    <StyledButton color={color} iconColor={iconColor} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

export default SummaryShareButton
