import { Checkbox } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  TableContent,
  InnerTable,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  innerSubHeaderWidth,
  StyledTable,
  subHeaderWidth,
  RadioButtonsWrap,
  AmongSpan,
  StyledRadioButtonsWrap,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { handleFocusNumber } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsDetail11 = ({ onUpdate }) => {
  const { watch, register } = useFormContext()

  const d_byeogmyeon_gyunyeol = watch('d_byeogmyeon_gyunyeol')
  const d_byeogmyeon_gyunyeol_spot = watch('d_byeogmyeon_gyunyeol_spot')
  const d_byeogmyeon_nusu = watch('d_byeogmyeon_nusu')
  const d_byeogmyeon_nusu_spot = watch('d_byeogmyeon_nusu_spot')
  const d_badagmyeon = watch('d_badagmyeon')
  const d_badagmyeon_spot = watch('d_badagmyeon_spot')
  const d_dobae = watch('d_dobae')

  const d_descript_type = watch('d_descript_type')
  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑫'
    } else {
      return '⑩'
    }
  }
  return (
    <>
      <StyledTable sectionspace={d_descript_type == '비주거용 건축물'}>
        <Row height={33} rowspan={4} noborderbottom>
          <TableHeader width={headerWidth}>
            {getNumber()} 벽면·
            <br />
            바닥면 및
            <br />
            도배 상태
            <br />
          </TableHeader>
          <RowSpanInner
            rowspanheightpercent={50}
            rowspan={2}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>벽면</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={50}>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    균열
                  </TableSubHeader>
                  <InnerTableContent
                    headerwidth={innerSubHeaderWidth}
                    checkboxes
                  >
                    <StyledRadioButtonsWrap>
                      <RadioButton
                        label="없음"
                        checked={
                          d_byeogmyeon_gyunyeol == '없음' ||
                          d_byeogmyeon_gyunyeol == ''
                        }
                        name="d_byeogmyeon_gyunyeol"
                        onChange={(e) =>
                          onUpdate('d_byeogmyeon_gyunyeol', '없음')
                        }
                      />
                      <RadioButton
                        label="있음"
                        checked={d_byeogmyeon_gyunyeol == '있음'}
                        name="d_byeogmyeon_gyunyeol"
                        onChange={(e) =>
                          onUpdate('d_byeogmyeon_gyunyeol', '있음')
                        }
                      />
                    </StyledRadioButtonsWrap>
                    <AmongSpan>&#40; 위치: </AmongSpan>
                    <ColorInput
                      type="text"
                      defaultValue={d_byeogmyeon_gyunyeol_spot}
                      disabled={d_byeogmyeon_gyunyeol != '있음'}
                      onBlur={(e) =>
                        onUpdate(
                          'd_byeogmyeon_gyunyeol_spot',
                          e.target.value,
                        )
                      }
                      fullWidth
                    />
                    &#41;
                  </InnerTableContent>
                </Row>
                <Row heightpercent={50} noborderbottom>
                  <TableSubHeader width={innerSubHeaderWidth}>
                    누수
                  </TableSubHeader>
                  <InnerTableContent
                    headerwidth={innerSubHeaderWidth}
                    checkboxes
                  >
                    <StyledRadioButtonsWrap>
                      <RadioButton
                        label="없음"
                        checked={
                          d_byeogmyeon_nusu == '없음' ||
                          d_byeogmyeon_nusu == ''
                        }
                        name="d_byeogmyeon_nusu"
                        onChange={(e) =>
                          onUpdate('d_byeogmyeon_nusu', '없음')
                        }
                      />
                      <RadioButton
                        label="있음"
                        checked={d_byeogmyeon_nusu == '있음'}
                        name="d_byeogmyeon_nusu"
                        onChange={(e) =>
                          onUpdate('d_byeogmyeon_nusu', '있음')
                        }
                      />
                    </StyledRadioButtonsWrap>
                    <AmongSpan>&#40; 위치: </AmongSpan>
                    <ColorInput
                      type="text"
                      defaultValue={d_byeogmyeon_nusu_spot}
                      disabled={d_byeogmyeon_nusu != '있음'}
                      onBlur={(e) =>
                        onUpdate(
                          'd_byeogmyeon_nusu_spot',
                          e.target.value,
                        )
                      }
                      fullWidth
                    />
                    &#41;
                  </InnerTableContent>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={25}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth}>바닥면</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTableContent checkboxes fullWidth>
                <RadioButtonsWrap width={300}>
                  <RadioButton
                    label="깨끗함"
                    checked={
                      d_badagmyeon == '깨끗함' || d_badagmyeon == ''
                    }
                    name="d_badagmyeon"
                    onChange={(e) => onUpdate('d_badagmyeon', '깨끗함')}
                  />
                  <RadioButton
                    label="보통임"
                    checked={d_badagmyeon == '보통임'}
                    name="d_badagmyeon"
                    onChange={(e) => onUpdate('d_badagmyeon', '보통임')}
                  />
                  <RadioButton
                    label="수리 필요"
                    checked={d_badagmyeon == '수리 필요'}
                    name="d_badagmyeon"
                    onChange={(e) =>
                      onUpdate('d_badagmyeon', '수리 필요')
                    }
                  />
                </RadioButtonsWrap>
                <AmongSpan>&#40; 위치: </AmongSpan>
                <ColorInput
                  type="text"
                  defaultValue={d_badagmyeon_spot}
                  disabled={d_badagmyeon != '수리 필요'}
                  onBlur={(e) =>
                    onUpdate('d_badagmyeon_spot', e.target.value)
                  }
                  fullWidth
                />
                &#41;
              </InnerTableContent>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={25}
            minuswidth={headerWidth}
            noborderbottom
          >
            <TableSubHeader width={headerWidth}>도배</TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTableContent checkboxes>
                <RadioButtonsWrap width={300}>
                  <RadioButton
                    label="깨끗함"
                    checked={d_dobae == '깨끗함'}
                    name="d_dobae"
                    onChange={(e) => onUpdate('d_dobae', '깨끗함')}
                  />
                  <RadioButton
                    label="보통임"
                    checked={d_dobae == '보통임' || d_dobae == ''}
                    name="d_dobae"
                    onChange={(e) => onUpdate('d_dobae', '보통임')}
                  />
                  <RadioButton
                    label="도배 필요"
                    checked={d_dobae == '도배 필요'}
                    name="d_dobae"
                    onChange={(e) => onUpdate('d_dobae', '도배 필요')}
                  />
                </RadioButtonsWrap>
              </InnerTableContent>
            </TableContent>
          </RowSpanInner>
        </Row>
      </StyledTable>
    </>
  )
}

const InnerTableBlockDesc = styled(InnerTableBlock)`
  font-size: 12px;
  align-items: center;
  padding-right: 10px;
`

const HeatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`

const HeatingInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50%;

  & > label {
    margin-right: 10px;
  }
`

const CheckboxWrappr = styled.div`
  margin-left: 10px;
`

export default NoteDocsDetail11
