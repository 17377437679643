import theme from 'lib/styles/theme'
import { useEffect } from 'react'
import { matchPath, NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'

const StyledNavItem = styled(NavLink)`
  user-select: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 8px 2px;
  border-bottom: 3px solid transparent;

  color: ${theme.colors.gray[700]};
  font-size: 0.875rem;
  line-height: 1;

  transition: color 0.1s ease, background-color 0.2s ease;

  .activeIcon {
    display: none;
  }
  .normalIcon {
    display: block;
  }

  & > i {
    font-size: 1.11rem;
  }

  &:hover {
    color: ${theme.colors.gray[600]};
    background-color: ${theme.colors.gray[50]};
  }

  &.active {
    /* color: ${theme.colors.gray[900]}; */
    color: ${theme.colors.primary[400]};
    .activeIcon {
      display: block;
    }
    .normalIcon {
      display: none;
    }
  }
`

const StyledNavItemText = styled.span`
  color: ${theme.colors.gray[800]};
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;

  ${({ active }) =>
    active &&
    `
    color: ${theme.colors.primary[400]};
  `}
`

const NoteBottomMenuItem = ({
  to,
  exact,
  isActive,
  text,
  icon,
  activeIcon,
}) => {
  const history = useHistory()
  const active = matchPath(history.location.pathname, {
    path: to.pathname,
    exact: exact,
  })

  return (
    <StyledNavItem to={to} active={active}>
      {activeIcon}
      {icon}
      <StyledNavItemText active={active}>{text}</StyledNavItemText>
    </StyledNavItem>
  )
}

export default NoteBottomMenuItem
