import { useEffect, useContext, useRef, useState, useMemo } from 'react'

import BaseContext from 'contexts/baseContext'
import theme, { media } from 'lib/styles/theme'

import styled from 'styled-components'
import { CardBody } from 'components/design'
import { Skeleton } from 'antd'
import zIndex from 'lib/styles/zIndex'
import { useSelector } from 'react-redux'

const handleHeight = 30

const SwipeableContainer = ({
  external,
  children,
  header,
  onChange,
  offset = theme.base.summaryHeaderHeight,
  bottomMenu,
  topSlot,
}) => {
  // 스크롤 상태를 header 등의 외부 컴포넌트와 공유하기 위한 Global State
  const {
    state: { isScrolled, isSwipeable },
    actions: { setIsScrolled, setIsSwipe },
  } = useContext(BaseContext)

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)

  // 스와이프 대상이 펼쳐졌는지에 대한 여부
  const [active, setActive] = useState(false)
  // 스크롤 영역의 reference
  const scrollRef = useRef()

  // 스와이프 대상이 최대로 올라갈 수 있는 한계치
  // 화면의 높이에서 offset과 헤더 높이, 스와이프 핸들의
  // 높이(스와이프 핸들은 최대치일 때 헤더 뒤로 가려져야 함)를 제외한
  // 나머지를 구함
  const maxHeight =
    window.innerHeight -
    (offset + theme.base.headerHeight) +
    handleHeight

  // SwipeableContainer의 Y좌표는 값이 낮을수록 위로 올라가는(화면에서
  // 차지하는 비중이 높아지는) 형태이기 때문에 값을 음수로 변환해줌
  const maxTopLimit = maxHeight * -1

  // 스와이프 중 상태
  const [isSwiping, setIsSwiping] = useState(false)

  // 스와이프 시작 지점
  const [StartY, setStartY] = useState(0)
  // 스와이프 중일 때 마지막으로 변경된 Y좌표
  const [prevY, setPrevY] = useState(0)

  // 스와이프 방향 및 속도 (음수일 경우 위로 드래그 / 숫자가 높을수록 빠름)
  const [velocity, setVelocity] = useState(0)

  // 스와이프 대상의 Y좌표 (0은 초기값, maxTopLimit과 동일할 경우 최대치, 값은 항상 음수여야 하며, 값이 높을수록 위쪽에 위치함)
  const [swipePosition, setSwipePosition] = useState(0)

  // 스와이프 대상의 초기 카드 형태에 대한 여부
  const [expanded, setExpanded] = useState(false)

  // 스와이프 대상의 로딩 여부
  // false일 때 Body 영역과 children 요소들을 렌더링함
  // 기본적으로 스와이프 초기 상태에서 위로 올릴 때까지 로딩이 지속되며,
  // active 상태일 때 loading이 종료됨.
  const [loading, setLoading] = useState(true)

  // @METHOD: 최초 mount 시 아래에서 위로 올라오는 애니메이션 구현
  useEffect(() => {
    reset()

    return () => {
      reset()
      // unmount 시 isScrollTop 상태를 초기화
      setIsScrolled(false)
    }
  }, [])

  // ios 등에서 스와이프 대상이 펼쳐졌을 때 아래로 드래그하면 ios 특유의 가상 스크롤이
  // 발생하여 상단에 여백이 생기는 문제에 대응하기 위해 active 상태가 변화할 때마다
  // Scroll 위치를 초기화해야 함.
  useEffect(() => {
    scrollRef.current.scrollTop = 0
    setIsScrolled(false)
  }, [active])

  // @METHOD: 컴포넌트가 re-rendering될 때 상태를 초기화
  const reset = () => {
    setActive(false)
    setIsSwiping(false)
    setStartY(0)
    setPrevY(0)
    setVelocity(0)
    setSwipePosition(0)
    onChange(false)
    setLoading(false)
    setExpanded(false)
  }

  // @METHOD: 스와이프가 시작될 때 실행되는 함수
  const startSwiping = ({ velocity }) => {
    // 속도가 0일 경우 아직 스와이프가 시작된 것이 아니므로
    // 스와이프 중으로 간주하지 않음
    if (velocity === 0) return

    // 스와이프 대상이 화면에 꽉찼을 때 위로 드래그할 시
    // 스와이프 중으로 간주하지 않음
    if (swipePosition === maxTopLimit && velocity < 0) return
    setIsSwiping(true)
  }

  // @METHOD: 스와이프가 종료될 때 실행되는 함수
  const stopSwiping = () => {
    setIsSwiping(false)

    // swipe가 종료되고 스와이프 대상이 자동으로
    // 목표 지점까지 이동하는 과정에서 진행되는 애니메이션 도중
    // children이 렌더링되면 성능에 영향을 주기 때문에 애니메이션이
    // 종료되는 타이밍에 children을 렌더링할 수 있도록 시간차를 줌
    return setTimeout(() => {
      setLoading(false)
    }, 200)
  }

  // @METHOD: 스크롤에 따라 isScrollTop 상태를 변경
  const handleScroll = () => {
    if (scrollRef?.current?.scrollTop <= 0) return setIsScrolled(false)
    setIsScrolled(true)
  }

  // @METHOD: 스와이프 시작
  const onTouchStart = (e) => {
    // 스크롤이 1px이라도 내려간 상태일 경우 스와이프 동작 안함.
    if (isScrolled) return

    // 터치 시작지점 state 반영
    setStartY(e.targetTouches[0].clientY)
    setPrevY(e.targetTouches[0].clientY)
  }

  // @METHOD: 스와이프 중
  const onTouchMove = (e) => {
    // 스크롤이 1px이라도 내려간 상태일 경우 스와이프 동작 안함.
    if (isScrolled) return

    // 스와이프가 진행될 경우 카드형 헤더를 펼침
    setExpanded(true)

    // 스와이프 대상이 최대치보다 아래에 위치할 경우 active 상태를 종료함
    if (swipePosition > maxTopLimit) setActive(false)

    // 사용자의 포인터의 현재 위치
    const currentY = e.targetTouches[0].clientY

    // velocity 상태를 선언하기 전 private하게 미리 구한 다음
    // 예외처리가 모두 끝나고 나서 상태를 set해야함.
    const velocityOrigin = currentY - prevY

    // 카드를 올릴 때 로딩이 시작되도록 함
    if (!expanded && velocityOrigin < 0) {
      setLoading(true)
    }

    // 스와이프가 시작되었음을 알림
    startSwiping({ velocity: velocityOrigin })

    // 위로 스와이프할 경우 카드를 펼침
    if (velocityOrigin < 0) setExpanded(true)

    // 최근 터치 포인트 갱신
    setPrevY((prev) => prev + velocityOrigin)

    // 스와이프 대상의 위치 갱신
    setSwipePosition((prev) => {
      const result = prev + velocityOrigin

      if (result < maxTopLimit) return maxTopLimit
      if (result > 0) return 0
      return result
    })

    // 속도 및 방향 값 갱신
    setVelocity(velocityOrigin)
  }

  // @METHOD: 스와이프 중단
  const onTouchEnd = (e) => {
    // 스크롤이 1px이라도 내려간 상태일 경우 스와이프 동작 안함.
    if (isScrolled) return

    // console.log('touchEnd')
    stopSwiping()

    // 위로 빠르게 스와이프할 경우
    if (velocity >= 3) {
      setSwipePosition(0)
      onChange(false)
      setActive(false)
      setExpanded(false)
      return
    }
    // 아래로 빠르게 스와이프할 경우
    if (velocity <= -3) {
      setSwipePosition(maxTopLimit)
      onChange(true)
      setActive(true)
      return
    }

    // 최하단으로부터 200px 이내일 경우
    if (swipePosition <= 0 && swipePosition > -200) {
      setSwipePosition(0)
      onChange(false)
      setActive(false)
      setExpanded(false)
      return
    }

    setSwipePosition(maxTopLimit)
    onChange(true)
    setActive(true)
    setExpanded(true)
  }

  useEffect(() => {
    setIsSwipe(active)

    return () => {
      setIsSwipe(false)
    }
  }, [active])

  // swipePosition state를 기반으로 대상에 적용할 transition 생성
  const getSwipeTransform = (swipePosition) => {
    if (swipePosition < 1 && swipePosition !== 0) {
      return `${swipePosition - theme.base.summaryHeaderHeight}px`
    }
    return `-${theme.base.summaryHeaderHeight}px`
  }

  // useMemo로 swipePosition state가 변경될 때만 반영되도록 함
  const swipeTransform = useMemo(
    () => getSwipeTransform(swipePosition),
    [swipePosition],
  )

  // 헤더 영역 클릭 시 active되도록 함
  const onClickHeader = () => {
    if (active) return
    setSwipePosition(maxTopLimit)
    onChange(true)
    setActive(true)
    setExpanded(true)
  }

  const onMouseDown = (e) => {
    console.log(e)
  }

  return (
    <Wrapper
      active={active}
      style={{ transform: `translateY(${swipeTransform})` }}
      swipePosition={swipePosition}
      isSwiping={isSwiping}
      external={external}
      bottomMenu={bottomMenu}
      onTouchStart={(e) => isSwipeable && onTouchStart(e)}
      onTouchMove={(e) => isSwipeable && onTouchMove(e)}
      onTouchEnd={(e) => isSwipeable && onTouchEnd(e)}
      onMouseDown={(e) => isSwipeable && onMouseDown(e)}
      ref={scrollRef}
      onScroll={handleScroll}
      expanded={expanded}
      isNoticeBarVisible={isNoticeBarVisible}
      noticeBarHeight={height}
    >
      <HeaderWrap expanded={expanded} onClick={onClickHeader}>
        <Handle />
        {header}
      </HeaderWrap>

      {isSwiping ? '' : topSlot}

      {loading ? (
        <CardBody>
          <Skeleton />
        </CardBody>
      ) : (
        <Body expanded={expanded} isSwiping={isSwiping}>
          {children}
        </Body>
      )}
    </Wrapper>
  )
}

const HeaderWrap = styled.div`
  /* transition: 0.2s ease; */

  ${({ expanded }) =>
    !expanded &&
    `
    margin: 2rem 1rem 0;
    border-top-right-radius: ${theme.borderRadius[3]};
    border-top-left-radius: ${theme.borderRadius[3]};
    background-color: ${theme.colors.base.white};
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 1px, rgba(0, 0, 0, 0.25) 0 0 32px,
      rgba(0, 0, 0, 0.1) 0 -1px 0;
    overflow: hidden;
    
  `}
`

const Body = styled.div`
  opacity: 0;
  /* transition: 0.2s ease; */

  ${({ expanded }) => expanded && `opacity: 1;`}
  ${({ isSwiping }) => isSwiping && `pointer-events: none;`};
`

const Handle = styled.div`
  /* position: sticky;
  top: 0;
  z-index: 10; */
  cursor: grab;
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;
  margin-bottom: -1.25rem;
  background-color: white;

  &::before {
    content: '';
    width: 30px;
    height: 4px;

    border-radius: 4px;
    background-color: ${theme.colors.gray[300]};
  }
`

const Wrapper = styled.div`
  position: fixed;
  top: ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible ? `calc(100% + ${noticeBarHeight}px)` : `100%`};
  left: 0;
  z-index: 13;

  width: 100%;
  height: calc(100% - ${theme.base.headerHeight - handleHeight}px);
  min-height: calc(100% - ${theme.base.headerHeight - handleHeight}px);

  overflow: hidden;
  will-change: transform;

  padding-bottom: ${theme.base.bottomMenuHeight}px;

  ${({ external }) =>
    external &&
    `
    ${media.mediumS`
      display: none;
    `}
  `}

  ${({ active }) =>
    active
      ? `
        border-radius: 0 !important;
        overflow: auto;
      `
      : ''}
  
  ${({ expanded }) =>
    expanded
      ? `
        background-color: ${theme.colors.base.white};
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 1px, rgba(0, 0, 0, 0.25) 0 0 32px,
      rgba(0, 0, 0, 0.1) 0 -1px 0;
        border-radius: ${theme.borderRadius[3]} ${theme.borderRadius[3]} 0 0;
      `
      : ''}

  ${({ isSwiping }) =>
    isSwiping
      ? `
        z-index: ${zIndex.swipeableContainer};
        transition: initial;
      `
      : `transition: transform 0.4s cubic-bezier(0.22, 1, 0.36, 1);`}

  ${({ bottomMenu }) =>
    bottomMenu &&
    `
    `}
`

export default SwipeableContainer
