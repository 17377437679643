import { ExternalLayerProvider } from './context/ExternalLayerContext'
import ExternalLayerContainer from './ExternalLayerContainer'

const ExternalLayer = (props) => {
  return (
    <ExternalLayerProvider>
      <ExternalLayerContainer {...props} />
    </ExternalLayerProvider>
  )
}

export default ExternalLayer
