import { FormDesc } from 'components/jmapnotev2/NoteAd/form/NoteAdFormStyle'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NoteAdDescBottom = () => {
  return (
    <StyledFormDesc>
      <li>
        분양 홍보성 매물, 소유권 이전 신청사건, 등기부등본상 권리상태가
        경매로 표기된 매물, 공매, 국가소유 매물, 중복 매물 등은 등록이
        불가하며, 이러한 경우에는 경고 없이 삭제될 수 있습니다.
      </li>
      <li>매물 등록 후 노출되기까지는 검증기간이 1~3일 소요됩니다.</li>
      <li>
        검증 실패시, 검증에 소요된 비용은 환불되지 않을 수도 있습니다.
      </li>
      <li>
        대표자 외 직원은 대표자에게 매물 승인 요청을 받아야 합니다.
      </li>
      <li>
        대표자에게 매물 승인 요청이 발송된 이후 매물 등록 폼의 수정은
        불가합니다.
      </li>
      <li>본 서비스는 텐컴즈와 제휴하여 제공되는 서비스입니다.</li>
    </StyledFormDesc>
  )
}

const StyledFormDesc = styled(FormDesc)`
  padding: 16px;
  background: ${theme.colors.gray[50]};
  margin-top: 20px;
  border-radius: 8px;

  li {
    line-height: 150%;
  }
`

export default NoteAdDescBottom
