import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledFormItemRow = styled.div`
  margin-bottom: 1rem;
`
const RowInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem -0.75rem -0.75rem;
`

const FormItemRow = ({ children, className }) => (
  <StyledFormItemRow>
    <RowInner className={className}>{children}</RowInner>
  </StyledFormItemRow>
)

export default FormItemRow
