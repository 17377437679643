import { TextArea } from 'components/jmapnotev2/style/NoteDocsStyle'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsContractSpecial = ({ onUpdate, special_conditions }) => {
  const { watch, register, setValue } = useFormContext()
  const conditions = watch('conditions')
  const [conditionValue, setConditionValue] = useState(conditions)

  useEffect(() => {
    if (conditions) {
      return
    }
    if (!special_conditions || conditionValue) return
    let special = ''

    special_conditions.map((item) => {
      if (item.text) {
        special += item.text + '\n'
      }
    })
    setConditionValue(special)
    setValue('conditions', special)
  }, [conditionValue, special_conditions])

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>특약사항</Title>
      </TitleWrapper>
      <TextAreaWrapper>
        <TextArea
          height={320}
          value={conditionValue}
          onChange={(e) => setConditionValue(e.target.value)}
          onBlur={(e) => onUpdate('conditions', e.target.value)}
        />
      </TextAreaWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 10px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
`

const TextAreaWrapper = styled.div`
  margin: 0 2px;
`

export default NoteDocsContractSpecial
