import theme from 'lib/styles/theme'
import { NumericFormat, PatternFormat } from 'react-number-format'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { ko } from 'date-fns/esm/locale'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as CalendarIcon } from 'assets/icon/note/detail/calendar.svg'
import { forwardRef, useCallback, useRef, useState } from 'react'
import moment from 'moment'
import { Button } from 'components/design'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'

const ColorInput = ({
  placeholder,
  value,
  onChange,
  onBlur,
  onValueChange,
  datasetKey = '',
  append,
  thousandSeparator = true,
  type = 'number',
  format,
  defaultValue,
  selected,
  minWidth,
  width,
  fullWidth,
  onClick,
  disabled = false,
  className,
  textAlign = 'left',
  visibleDisplay,
  readOnly = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onChangeDate = useCallback((date, e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault()
    }
    setIsOpen(false)
    onChange && onChange(date)
  }, [])

  const onClickClearDate = useCallback((e) => {
    e.preventDefault()

    setIsOpen(false)

    function getParents(elem) {
      var parents = []
      while (
        elem.parentNode &&
        elem.parentNode.nodeName.toLowerCase() != 'body'
      ) {
        elem = elem.parentNode
        parents.push(elem)
      }
      return parents
    }
    const parents = getParents(e.target)

    const labelTag = parents.find((elem) => {
      return elem.tagName == 'LABEL'
    })

    if (!labelTag) return

    labelTag.querySelector('.react-datepicker__close-icon').click()
  }, [])

  return (
    <Wrapper
      minWidth={minWidth}
      width={width}
      hasprepend={append}
      type={type}
      fullWidth={fullWidth}
      className={className}
      visible={visibleDisplay}
    >
      {type == 'number' ? (
        format ? (
          <StyledInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            type="text"
            as={PatternFormat}
            defaultValue={defaultValue}
            data-key={datasetKey}
            inputMode="decimal"
            onValueChange={onValueChange}
            format={format}
            onClick={onClick}
            disabled={disabled}
            textalign={textAlign}
            readOnly={readOnly}
          />
        ) : (
          <StyledInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            type="text"
            as={NumericFormat}
            defaultValue={defaultValue}
            data-key={datasetKey}
            inputMode="decimal"
            thousandSeparator={thousandSeparator}
            onValueChange={onValueChange}
            format={format}
            onClick={onClick}
            disabled={disabled}
            textalign={textAlign}
            readOnly={readOnly}
          />
        )
      ) : type == 'date' ? (
        <CalendarWrapper>
          <DatePicker
            value={value}
            onBlur={onBlur}
            placeholderText={placeholder}
            type={type}
            defaultValue={defaultValue}
            data-key={datasetKey}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            shouldCloseOnSelect={true}
            onChange={onChangeDate}
            selected={selected}
            onClick={onClick}
            onClickOutside={() => setIsOpen(false)}
            customInput={<DateCustomInput setIsOpen={setIsOpen} />}
            disabled={disabled}
            isClearable
            open={isOpen}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          >
            <ResetCalendar
              size="xsmall"
              color="gray"
              onClick={onClickClearDate}
            >
              날짜 지우기
            </ResetCalendar>
          </DatePicker>
          <CalendarIcon />
        </CalendarWrapper>
      ) : value ? (
        <StyledInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          type={type}
          data-key={datasetKey}
          onClick={onClick}
          disabled={disabled}
          textalign={textAlign}
          readOnly={readOnly}
        />
      ) : (
        <StyledInput
          onBlur={onBlur}
          placeholder={placeholder}
          type={type}
          defaultValue={defaultValue}
          data-key={datasetKey}
          onClick={onClick}
          disabled={disabled}
          textalign={textAlign}
          readOnly={readOnly}
        />
      )}
      {append && <Append>{append}</Append>}
    </Wrapper>
  )
}

const DateCustomInput = forwardRef(
  ({ value, onClick, setIsOpen }, ref) => (
    <StyledButton
      className="example-custom-input"
      onClick={() => {
        setIsOpen(true)
        onClick()
      }}
      ref={ref}
    >
      {value ? (
        <>
          <strong>{moment(value).format('YYYY')}</strong>
          <span>년</span>
          <strong>{moment(value).format('MM')}</strong>
          <span>월</span>
          <strong>{moment(value).format('DD')}</strong>
          <span>일</span>
        </>
      ) : (
        <>
          <DateEmpty /> <span>년</span>
          <DateEmpty sm /> <span>월</span>
          <DateEmpty sm /> <span>일</span>
        </>
      )}
    </StyledButton>
  ),
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  position: relative;

  ${({ visible }) => visible == 'none' && `display: none;`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  ${({ width }) => width && `max-width: ${width}px;`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${({ type }) =>
    type == 'date' &&
    `
    display: inline-flex;
      margin: 0 10px;
      background-color: ${theme.colors.blue[50]}; 
      padding: 0 14px;
      height: 24px;
      max-width: 230px;
      
      .react-datepicker__input-container{
        display: flex;
        align-items: center;
      }

      &:focus,
      &:focus-within {
        box-shadow: 0 0 0 1.2px ${theme.colors.blue[400]};
      }
    `}
`

const StyledInput = styled.input`
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  padding: 4.5px 8px;
  font-weight: 500;
  font-size: 15px;
  border: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: ${theme.colors.blue[50]};
  outline: none;

  &:focus,
  &:focus-within {
    box-shadow: 0 0 0 1.2px ${theme.colors.blue[400]};
  }

  &::placeholder {
    color: ${theme.colors.gray[400]};
  }

  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${theme.colors.gray[100]};
      cursor: default;
  `}

  ${({ type }) =>
    type == 'date' &&
    `
      padding: 0;
      &:focus,
      &:focus-within {
        box-shadow: none;
      }
        
    `}

  ${({ textalign }) =>
    textalign == 'right' &&
    `
     text-align: right;
  `}
`

const CalendarWrapper = styled.label`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    cursor: pointer;
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 12px;
    margin-bottom: 6px;
    margin-top: -3px;

    & > div {
      margin: 0;
    }

    select {
      border: none;
      background-color: transparent;
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
    }
  }

  .react-datepicker__navigation {
    top: 6px;
  }

  .react-datepicker__close-icon {
    display: none;
  }

  .react-datepicker {
    border: 1px solid ${theme.colors.gray[300]};
  }

  .react-datepicker__header {
    background-color: ${theme.colors.gray[50]};
    border: 1px solid ${theme.colors.gray[200]};
    padding-top: 12px;
  }
`
const Append = styled.div`
  padding-left: 4px;
  color: ${theme.colors.gray[800]};
  font-weight: 400;
  white-space: nowrap;
`

const DateEmpty = styled.div`
  width: 39px;
  height: 100%;

  ${({ sm }) => sm && `width: 20px;`}
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 15px;

  strong {
    margin-right: 2px;
    font-weight: 500;
  }

  span {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
  background-color: transparent;
`

const ResetCalendar = styled(Button)`
  cursor: pointer;
  text-align: right;
  float: right;
  clear: both;
  margin-bottom: 10px;
  font-size: 12px;
  margin-right: -6px;
`

export default ColorInput
