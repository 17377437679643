import { useCallback, useContext, useState } from 'react'
import { Tooltip } from 'antd'
import { Modal } from 'components/design'
import { LoadingOutlined } from '@ant-design/icons'

import MapContext from 'contexts/map'

import styled from 'styled-components'
import theme from 'lib/styles/theme'

const GeolocationButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 14px;
  font-size: 16px;
  color: ${theme.colors.gray[700]};
  outline: none;
  border: none;
  appearance: none;
  border-radius: 100%;
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['float']};
`

/**
 * 현재 위치
 */
const MapControlPosition = () => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map } = state
  const [loading, setLoading] = useState(false) // 알림 메시지 띄우기
  const [message, setMessage] = useState('') // 알림 메시지 띄우기

  const onClick = useCallback(() => {
    // 로딩 중일 떄는 요청하지 않음 (중복요청 방지)
    if (loading) return
    if (Object.keys(map).length === 0) return

    // HTML5의 geolocation으로 사용할 수 있는지 확인합니다
    if ('geolocation' in navigator) {
      console.log('geolocation 요청 중')
      setLoading(true)

      let id = 999999
      // 옵션 지정
      const options = {
        enableHighAccuracy: true, // 고정밀 위치정보 여부
        timeout: 10000, // 위치 정보 요청의 응답을 대기할 최대 대기시간
        maximumAge: Infinity, // 위치 정보의 캐시 수명(수명이 끝나기 전까지는 이전에 반환한 위치 정보를 저장해뒀다가, 같은 요청을 또 받을 경우 그대로 반환합니다)
      }

      // GeoLocation을 이용해서 접속 위치를 얻어옵니다
      id = navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude // 위도
          const lon = position.coords.longitude // 경도

          // 마커가 표시될 위치를 geolocation으로 얻어온 좌표로 생성합니다
          const locPosition = new kakao.maps.LatLng(lat, lon)

          // 지도 중심좌표를 접속위치로 변경합니다
          map.setCenter(locPosition)

          /**
           * @INFO: 줌레벨을 동단위로 셋팅 (geolocation api는 GPS, IP, RFID, WiFi, MAC
           * address 등의 소스로부터 위치를 파악하는데, 반환값이 실제 기기의 위치를 보장하지 않기
           * 때문에 멀리서 보여주는 것입니다.)
           */
          // 줌 레벨이 5 이상일 때에만 경우 강제로 줌 레벨을 조정함.
          if (map.getLevel() > 5) {
            map.setLevel(3)
          }

          setLoading(false)
        },
        (error) => {
          console.log(error)
          setLoading(false)

          // setMessage('현재 위치 기능을 사용할 수 없습니다.')
          // clear
          if (id < 999999) {
            navigator.geolocation.clearWatch(id)
          }
        },
        options,
      )
    } else {
      // HTML5의 GeoLocation을 사용할 수 없을 때 메시지 띄움
      setMessage('현재 위치 기능을 허용해 주세요.')
    }
  }, [kakao, map])

  return (
    <>
      <Tooltip>
        <GeolocationButton onClick={onClick}>
          {loading ? (
            <LoadingOutlined />
          ) : (
            <i className="fad fa-location"></i>
          )}
        </GeolocationButton>
      </Tooltip>

      <Modal
        title="알림"
        visible={message.length > 0}
        onOk={() => setMessage('')}
        onCancel={() => setMessage('')}
        okText="확인"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {message}
      </Modal>
    </>
  )
}

export default MapControlPosition
