import { Modal } from 'components/design'
import { useSelector } from 'react-redux'

/**
 * 요금제가 없습니다 알림 모달
 * @param {*} param0
 * @returns
 */
const NoteDetailGongbuPreparingModal = ({ visible, onClose }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  return (
    <Modal
      title={'요금제가 없습니다'}
      visible={visible}
      onCancel={onClose}
      cancelHide
      okButtonProps={{ color: 'blue' }}
      onOk={onClose}
    >
      <>
        요금제를 구매해보시겠어요? 매물노트가 등기부등본 내용을 대신
        적어드려요. 중개사님은 확인만 하세요!
      </>
    </Modal>
  )
}

export default NoteDetailGongbuPreparingModal
