import { message } from 'antd'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import NotePaymentAllInOneCancelDone from 'components/jmapnotev2/NotePayment/NotePaymentAllInOneCancelDone'
import NotePaymentAllInOneCancelConfirm from 'components/jmapnotev2/NotePayment/NotePaymentAllInOneCancelConfirm'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import { updatePlan } from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * TODO:
 * 취소 관련 로직 모음
 * 올인원 -> 베이직 요금제로 변경
 * @param {*} param0
 * @returns
 */
const NotePaymentCancelAllInOneToBasicContainer = ({ children }) => {
  const { onRequest: updateUserInfo } = useUserLoader(false)
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [isVisibleDone, setIsVisibleDone] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [
    onRequestUpdatePlan,
    loadingUpdate,
    updateResult,
    errorUpdatePlan,
  ] = useAxios(updatePlan, {
    resetError: true,
  }) //요금제 업데이트

  const [
    isCancelScheduleModalVisible,
    setIsCancelScheduleModalVisible,
  ] = useState(false) // 요금제 해지 예정 여부 확인 모달 visible

  /**
   * 요금제 해지 신청 API를 호출합니다.
   */
  const onClickCancelPlan = () => {
    setIsCancelScheduleModalVisible(true)
  }

  /**
   * 요금제 취소 모달 닫기
   */
  const onCloseCancelScehduleModal = () => {
    setIsCancelScheduleModalVisible(false)
  }

  /**
   * 베이직으로 요금제 업데이트
   */
  const updateToBasicPlan = () => {
    setIsLoading(true)
    onRequestUpdatePlan({
      order_name: '베이직',
      type: '요금제변경',
      amount: 4900,
      default_count: 1,
      plan_srl: planInfo?.plan_srl,
    })
  }

  /**
   * 모달 확인
   */
  const onOk = () => {
    updateToBasicPlan()
    onCloseCancelScehduleModal()
  }

  useEffect(() => {
    if (!updateResult) return
    if (updateResult?.message != 'success') {
      message.error('요금제 변경 중 오류가 발생했습니다.')
      setIsLoading(false)
      return
    }
    setIsVisibleDone(true)
    updateUserInfo()
    setIsLoading(false)
  }, [updateResult])

  const endDone = () => {
    setIsVisibleDone(false)
  }

  {
    /* 요금 해지 관련 알림이라 닫기 버튼, 모달 바깥을 클릭해도 닫기 기능은 없음 */
  }
  return children({
    onClickCancelPlan,
    cancelConfirmModal: isCancelScheduleModalVisible ? (
      <NotePaymentAllInOneCancelConfirm
        toFree={false}
        visible={isCancelScheduleModalVisible}
        onOk={onOk}
        onCancel={onCloseCancelScehduleModal}
        isRefundable={planInfo?.is_refundable}
      />
    ) : null,
    cancelDoneModal: isVisibleDone ? (
      <NotePaymentAllInOneCancelDone endDone={endDone} />
    ) : null,
    loading: isLoading && <FullScreenLoading />,
  })
}

export default NotePaymentCancelAllInOneToBasicContainer
