import { useCallback, useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { List, Card, CardBody } from 'components/design'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import FolderModify from '../folder/FolderModify'
import FolderSave from '../folder/FolderSave'
import { Button } from 'components/design'
import SettingsFolderListItem from './SettingsFolderListItem'
import update from 'immutability-helper'
import { setFolders } from 'modules/note'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getFolders } from 'lib/api/note/folderApi'
import useAxios from 'lib/hooks/useAxios'

const SettingsFolderList = () => {
  const { channelSrl } = useNoteMatch()

  const [onRequest, loading, data, error, reset, source] =
    useAxios(getFolders)

  const dispatch = useDispatch()
  const folders = useSelector((state) => state.note.folders)

  const [visible, setVisible] = useState(false)
  const [saveFolderVisible, setsaveFolderVisible] = useState(false)
  const [selectedFolderItem, setSelectedFolderItem] = useState(null)

  useEffect(() => {
    if (!channelSrl || channelSrl === 'undefined') return
    onRequest({ channelSrl })
  }, [onRequest, channelSrl])

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const sortArr = update(folders, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, folders[dragIndex]],
        ],
      })

      dispatch(setFolders(sortArr))
    },
    [folders],
  )

  const renderItem = (item, index) => {
    return (
      <SettingsFolderListItem
        item={item}
        index={index}
        key={item.folder_srl}
        setSelectedFolderItem={setSelectedFolderItem}
        setVisible={setVisible}
        moveItem={moveItem}
      />
    )
  }

  if (loading)
    return (
      <Card noMargin>
        <CardBody>
          <Skeleton />
        </CardBody>
      </Card>
    )

  if (!data) return <></>

  return (
    <>
      <Card noMargin>
        <CardBody>
          <Button
            color="primary"
            plain
            expand
            onClick={() => setsaveFolderVisible(true)}
          >
            <ButtonText>
              <i className="fa fa-plus"></i>
              폴더 추가
            </ButtonText>
          </Button>
        </CardBody>
      </Card>
      <List>{folders?.map((item, i) => renderItem(item, i))}</List>
      <FolderModify
        visible={visible}
        setVisible={setVisible}
        folderInfo={selectedFolderItem}
      />

      <FolderSave
        visible={saveFolderVisible}
        setVisible={setsaveFolderVisible}
      />
    </>
  )
}

const ButtonText = styled.div`
  & > i {
    margin-right: 10px;
  }
`

export default SettingsFolderList
