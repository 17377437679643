import { useSelector } from 'react-redux'

/**
 * 올인원 요금제인지 확인
 */
function usePrivateChannel() {
  const channels = useSelector((state) => state.channel.channels)

  const privateChannel =
    channels.find(({ is_private }) => is_private == 'Y') || channels[0]

  return {
    isReadOnlyChannel: privateChannel?.member_type == 'readonly',
    privateChannel: privateChannel,
  }
}

export default usePrivateChannel
