import { useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { media } from 'lib/styles/theme'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import useIsMobile from 'lib/hooks/useIsMobile'

const StyledDialogWrap = styled.div`
  position: absolute;
  z-index: 10;

  ${({ fixed }) => fixed && `position: fixed;`};

  /* width: 100%; */
  max-width: 300px;
  margin-top: 0.25rem;

  ${({ topPos }) =>
    topPos &&
    `
      bottom: calc(100% + 4px);
  `}

  ${media.mediumS`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    /* padding-bottom: 55px; */
    

    background-color: rgba(0, 0, 0, 0.5);
  `}
`

const Innner = styled.div``

const DropdownDialogWrap = ({
  children,
  visible,
  onClose,
  topPos,
  fixed,
}) => {
  const isMobile = useIsMobile()
  const ref = useRef()
  useOnClickOutside(ref, (e) => {
    if (isMobile) {
      if (e.target && e.target.closest('#modal')) return
      onClose()
    }
  })

  const onClickBackground = (e) => {
    if (isMobile) {
      if (e.target === e.currentTarget) onClose()
    }
  }

  if (!visible) return null

  const template = (
    <StyledDialogWrap
      ref={ref}
      onClick={onClickBackground}
      topPos={topPos}
      fixed={fixed}
    >
      <Innner>{children}</Innner>
    </StyledDialogWrap>
  )

  if (isMobile) {
    const modalRoot = document.getElementById('modal')
    return ReactDOM.createPortal(template, modalRoot)
  }

  return template
}

export default DropdownDialogWrap
