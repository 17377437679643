import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import { Empty } from 'components/design'
import NoteAdListFetures from './NoteAdListFetures'
import NoteAdListGridContainer from 'containers/notev2/NoteAdListGridContainer'
import {
  GridStyleWrapper,
  GridWrapper,
  StyledGrid,
} from 'components/jmapnotev2/style/NoteGridStyle'
import {
  getGridFontSize,
  gridFontSize,
} from 'lib/utils/notev2/gridUtil'
import { suppressKeyboardEvent } from 'lib/utils/notev2/gridFocus'
import NoteAdListContainer from 'containers/notev2/NoteAdListContainer'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteAdListMobile from './NoteAdListMobile'

/**
 * 광고 리스트 그리드
 * @returns
 */
const NoteAdListGrid = ({ onSwipeStart, onSwipeMove, onSwipeEnd }) => {
  const isMobile = useIsMobile()
  return (
    <NoteAdListContainer>
      {({
        tableData,
        input,
        bindInput,
        resetSearch,
        loading,
        search,
        replaceSearch,
        onClickMobileList,
      }) => {
        return isMobile ? (
          <>
            <NoteAdListFetures
              bindInput={bindInput}
              resetInput={resetSearch}
              onSearch={search}
              input={input}
            />
            <NoteAdListMobile
              data={tableData}
              input={input}
              replaceSearch={replaceSearch}
              onClick={onClickMobileList}
              onSwipeStart={onSwipeStart}
              onSwipeMove={onSwipeMove}
              onSwipeEnd={onSwipeEnd}
            />
          </>
        ) : (
          <>
            <NoteAdListFetures
              bindInput={bindInput}
              resetInput={resetSearch}
              onSearch={search}
              input={input}
            />
            {loading && <FullScreenLoading />}
            <NoteAdListGridContainer
              tableData={tableData}
              replaceSearch={replaceSearch}
              input={input}
            >
              {({
                getRowHeight,
                isFullWidthRow,
                fullWidthCellRenderer,
                gridRef,
                columnDefs,
                onGridReady,
                gridOptions,
              }) => (
                <GridStyleWrapper
                  id="note-ad-sheet-resize-layer"
                  minusheight="92"
                  onSwipeStart={onSwipeStart}
                  onSwipeMove={(e, ev) => onSwipeMove(e, ev, true)}
                  onSwipeEnd={(e) => onSwipeEnd(e, true)}
                  gridfontsize={getGridFontSize(gridFontSize)}
                >
                  {tableData.length > 0 ? (
                    <>
                      <GridWrapper className="ag-theme-alpine">
                        <StyledGrid
                          className="ad-list-grid prevent-left-scroll"
                          ref={gridRef}
                          rowData={tableData}
                          defaultColDef={{ suppressKeyboardEvent }}
                          columnDefs={columnDefs}
                          getRowHeight={getRowHeight}
                          isFullWidthRow={isFullWidthRow}
                          fullWidthCellRenderer={fullWidthCellRenderer}
                          gridOptions={gridOptions}
                          getRowId={(params) => params.data.row_key} //unique해야 그리드 업데이트가 일어남, unique한 row를 그리기 위함.....................
                          onGridReady={onGridReady}
                          rowSelection={'single'}
                          //rowBuffer={2}
                          //  onRowClicked={onRowClickEvent}
                          loadingOverlayComponent={FullScreenLoading}
                          //headerHeight={44}
                          // overlayLoadingTemplate={
                          //   '<span class="ag-overlay-loading-center">로딩중...</span>'
                          // }
                          // overlayNoRowsTemplate={'<span></span>'}
                        />
                      </GridWrapper>
                    </>
                  ) : (
                    <>
                      <Empty>데이터 없음</Empty>
                    </>
                  )}
                </GridStyleWrapper>
              )}
            </NoteAdListGridContainer>
          </>
        )
      }}
    </NoteAdListContainer>

    // {/* <MapFavoriteNoteDetail
    //   data={noteData}
    //   visible={visibleNoteInfo}
    //   onClose={onCloseNoteInfo}
    //   hasDimmer
    // /> */}
  )
}

export default NoteAdListGrid
