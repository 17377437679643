import theme from 'lib/styles/theme'
import { forwardRef } from 'react'
import { Tooltip } from 'recharts'
import styled from 'styled-components'

const SummaryAptGraphTooltip = ({
  active,
  payload,
  label,
  tooltipFormatter,
}) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledTooltipLabel>
          {label.replace('-', '.')}
        </StyledTooltipLabel>
        {payload.map((item, i) => (
          <StyledTooltipItem key={i}>
            <StyledTooltipIcon color={item.color} />
            <span>{tooltipFormatter(item.value)}</span>
          </StyledTooltipItem>
        ))}
      </StyledTooltip>
    )
  }

  return null
}

const StyledTooltip = styled.div`
  padding: 0.5rem 0.75rem;
  background-color: white;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: ${theme.borderRadius[1]};
  box-shadow: ${theme.shadow['card-2']};
`

const StyledTooltipLabel = styled.div`
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const StyledTooltipItem = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const StyledTooltipIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: ${theme.colors.primary[400]};
  background-color: ${({ color }) =>
    color || theme.colors.primary[400]};
`

export default SummaryAptGraphTooltip
