import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useFormContext } from 'react-hook-form'

/**
 * 광고 관리용 저장 hook
 */
function useSave() {
  const { onUpdate } = useAdUpdate()

  /**
   * 체크박스
   * @param {*} key 저장 key (컬럼)
   * @param {*} originValue 원래 값
   * @param {*} value 변경될 값
   */
  const onChangeCheckbox = ({ key, originValue = [], value }) => {
    if (originValue.includes(value)) {
      onUpdate(
        key,
        originValue.filter((item) => item != value),
      )
    } else {
      onUpdate(key, [...originValue, ...[value]])
    }
  }

  return { onChangeCheckbox }
}

export default useSave
