// source: https://yeun.github.io/open-color/

const palette = {
  colors: {
    // tint
    tint: {
      primary: '#13D592',
      primaryDark: '#009F68',
      primaryLight: '#D7F9ED',

      secondary: '#425FF8',
      'secondary-100': '#D3DAFF',
      'secondary-050': '#E9ECFF',
    },

    base: {
      // base
      white: '#ffffff',
      black: '#000000',
    },

    gray: {
      '050': '#f8f9fa',
      100: '#f1f3f5',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#868e96',
      700: '#495057',
      800: '#343a40',
      900: '#212529',
    },
  },

  gray: [
    '#f8f9fa',
    '#f1f3f5',
    '#e9ecef',
    '#dee2e6',
    '#ced4da',
    '#adb5bd',
    '#868e96',
    '#495057',
    '#343a40',
    '#212529',
  ],
  cyan: [
    '#e3fafc',
    '#c5f6fa',
    '#99e9f2',
    '#66d9e8',
    '#3bc9db',
    '#22b8cf',
    '#15aabf',
    '#1098ad',
    '#0c8599',
    '#0b7285',
  ],
  useArea: {
    주거지역: 'gold',
    관리지역: 'orange',
    공공청사: 'cyan',
    상업지역: 'red',
    농림지역: 'green',
    공원: '#b1da71',
    공업지역: 'geekblue',
    자연환경보전지역: 'blue',
    주차장: 'volcano',
    녹지지역: 'green',
    학교: 'lime',
    시장: 'magenta',
  },
};

export default palette;
