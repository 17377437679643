import { useEffect, useState } from 'react'

/**
 * 올인원 결제 - 전단계 본인인증
 */
const NotePaymentAuthContainer = ({ children }) => {
  const [status, setStatus] = useState(null) // 본인인증 진행중 여부 -  1 : 진행중

  /**
   * 본인인증하기
   */
  const startAuth = () => {
    setStatus(1)
  }

  /**
   * 이전 화면으로
   */
  const cancelAuth = () => {
    setStatus(null)
  }

  return children({
    isStart: status == 1,
    startAuth,
    cancelAuth,
  })
}

export default NotePaymentAuthContainer
