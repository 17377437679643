import { message } from 'antd'
import { ActiveCellStyle } from 'components/jmapnotev2/style/NoteGridStyle'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import theme from 'lib/styles/theme'
import {
  addEndCursorKeyEvent,
  addSelectAllKeyEvent,
  addStartCursorKeyEvent,
  updateGridDatas,
} from 'lib/utils/notev2/gridUtil'
import { useState, useRef, useCallback, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import styled from 'styled-components'

const Input = (props) => {
  const alias =
    props.options.find(({ item }) => {
      return (
        item?.numbers[0]?.number == props.value ||
        item?.numbers[0]?.number.replaceAll('-', '') == props.value
      )
    })?.alias || ''

  return (
    <>
      <SelectLabelName visible={alias}>{alias}</SelectLabelName>
      <StyledInput {...props} isHidden={false} />
    </>
  )
}

const CustomContactCellSelectItem = (
  {
    options,
    item,
    contacts,
    i,
    contactList,
    contactItemHeight,
    node,
    setIsEditable,
    prevNoteContactSrl,
    removeNewLine,
    isSelectMenuOpen,
    setIsSelectMenuOpen,
    onRequestSaveContact,
    isReversePosition,
    prevContactSrl,
  },
  ref,
) => {
  const [onRequestModifyNote, isErrorModifyNote] = useModifyNote()
  const noteContacts = useSelector((state) => state.notev2.noteContacts)

  const [selectValue, setSelectValue] = useState(
    options?.findIndex(
      ({ contact_srl }) => contact_srl == item.contact_srl,
    ) > -1
      ? options[
          options?.findIndex(
            ({ contact_srl }) => contact_srl == item.contact_srl,
          )
        ]?.value
      : '',
  )
  const [inputValue, setInputValue] = useState(
    options?.findIndex(
      ({ contact_srl }) => contact_srl == item.contact_srl,
    ) > -1
      ? options[
          options?.findIndex(
            ({ contact_srl }) => contact_srl == item.contact_srl,
          )
        ]?.oriNumber || ''
      : '',
  )

  const prevValue = useRef(
    options?.findIndex(
      ({ contact_srl }) => contact_srl == item.contact_srl,
    ) > -1
      ? options[
          options?.findIndex(
            ({ contact_srl }) => contact_srl == item.contact_srl,
          )
        ]?.oriNumber || ''
      : '',
  )
  const selectRef = useRef(null)

  const onInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      setInputValue(inputValue)
    }
  }

  /**
   * 연락처 선택했을때 event
   * 조회와 저장시 key가 다른 특수한 경우기 때문에, 그리드 업뎃은 따로 해준다
   * 조회시: contacts  (Array)
   * 저장시: contact_srls  (string: JSON.stringify한 값)
   */
  const onChange = (e) => {
    if (e.oriNumber == prevValue.current) {
      setSelectValue(e)
      setInputValue(e ? e.oriNumber : '')
      setIsEditable(false)
      return
    }
    if (
      contacts
        .map(({ contact_srl }) => contact_srl)
        .includes(e.oriValue.contact_srl)
    ) {
      message.info('이미 등록되어있는 번호입니다')
      selectRef.current?.focus()
      return
    }

    const selectedValue = Object.assign({}, e.oriValue, {
      type: item.type,
    })

    const filteredContacts = prevNoteContactSrl
      ? contacts.map((item) => {
          if (item.note_contact_srl == prevNoteContactSrl) {
            return selectedValue
          } else {
            return item
          }
        })
      : prevContactSrl
      ? contacts.map((item) => {
          if (item.contact_srl == prevContactSrl) {
            return selectedValue
          } else {
            return item
          }
        })
      : contacts.map((item) => {
          if (!item?.numbers[0]?.number) {
            return selectedValue
          } else {
            return item
          }
        })

    const newData = filteredContacts

    onRequestModifyNote({
      data: node.data,
      updateData: {
        contacts: newData,
        contact_srls: JSON.stringify(newData),
      },
    })

    updateGridDatas({
      currentRowNode: node,
      newData: {
        contacts: newData,
        contact_srls: JSON.stringify(newData),
      },
    })

    const hasNumberContacts = noteContacts?.filter(
      ({ numbers }) => numbers.length > 0,
    )
    const index = hasNumberContacts.findIndex(
      ({ contact_srl }) => contact_srl == selectedValue.contact_srl,
    )

    ref.current?.focus()
    setIsEditable(false)

    setSelectValue(e)
    setInputValue(e ? e.oriNumber : '')
    prevValue.current = e.oriNumber
  }

  /**
   * 탭 키 동작 막기
   * selectbox에서 esc를 눌러 수정해제
   */
  const onKeyDownSelect = useCallback((e, a, b) => {
    addSelectAllKeyEvent(e)
    addStartCursorKeyEvent(e)
    addEndCursorKeyEvent(e)

    if (e.keyCode == '27') {
      //esc
      setIsEditable(false)
      ref.current?.focus()
      removeNewLine()
    }
    if (e.keyCode == '13') {
      //enter
      if (!e.target.value) return
      onBlurSelect(e)
      //selectRef.current?.blur()
    }
  }, [])

  function telValidator(args) {
    if (/^[0-9]{2,4}-[0-9]{3,4}-[0-9]{4}/.test(args)) {
      return true
    }

    return false
  }
  /**
   * selectbox blur
   * 매물노트 연락처에 없는 데이터면 추가해줌
   */
  const onBlurSelect = (e) => {
    setIsEditable(false)
    ref.current?.focus()
    if (
      contactList.filter(
        ({ number }) => number == e.target.value.replaceAll('-', ''),
      ).length > 0
    ) {
      return
    }

    if (
      contactList.filter(({ alias }) => alias == e.target.value)
        .length > 0
    ) {
      const filteredContacts = contacts.filter(
        ({ note_contact_srl }) =>
          note_contact_srl != item.note_contact_srl,
      )

      const data = contactList.filter(
        ({ alias }) => alias == e.target.value,
      )

      const selectedValue = Object.assign({}, data[0].oriValue, {
        type: item.type || '구분없음',
      })

      const copyData = [...contacts]
      copyData[i] = selectedValue

      const newData = copyData

      onRequestModifyNote({
        data: node.data,
        updateData: {
          contacts: newData,
          contact_srls: JSON.stringify(newData),
        },
      })

      updateGridDatas({
        currentRowNode: node,
        newData: {
          contacts: newData,
          contact_srls: JSON.stringify(newData),
        },
      })

      setSelectValue(data[0])
      setInputValue(data[0].oriNumber || '')
      return
    }
    let value = e.target.value
      .replace(/[^0-9]/g, '')
      .replace(
        /(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g,
        '$1-$2-$3',
      )

    if (!value) return
    if (!telValidator(value)) return

    const numbers = [{ number: value, label: '휴대전화' }]
    setInputValue(value)

    onRequestSaveContact({
      alias: `임시${Math.floor(Math.random() * 1000)}`,
      memo: '',
      numbers: JSON.stringify(numbers),
      type: item.type,
    })
    prevValue.current = value
  }

  return (
    <StyledSelect
      styles={{
        container: (styles) => ({
          ...styles,
          width: '100%',
          position: 'relative',
        }),
      }}
      contactItemHeight={contactItemHeight}
      className="basic-single"
      classNamePrefix="select"
      placeholder="이름, 전화번호로 검색"
      ref={selectRef}
      options={options}
      value={selectValue}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      onKeyDown={onKeyDownSelect}
      menuShouldScrollIntoView={false}
      controlShouldRenderValue={false}
      menuIsOpen={isSelectMenuOpen}
      onMenuOpen={() => setIsSelectMenuOpen(true)}
      onMenuClose={() => setIsSelectMenuOpen(false)}
      autoFocus={true}
      components={{
        Input,
        IndicatorSeparator: () => null,
      }}
      reverse={isReversePosition ? 'Y' : 'N'}
    />
  )
}

const StyledSelect = styled(Select)`
  position: absolute !important;
  min-width: 100%;
  width: max-content;
  height: calc(100% - 1px);
  top: 1px;
  z-index: 10;
  ${ActiveCellStyle}

  .select__control {
    height: 100%;
    min-height: 100%;
    border-radius: 0;
    box-shadow: 0 0 1px ${theme.colors.blue[500]};
    border-color: transparent;

    &:hover {
      border-color: transparent;
    }

    .select__indicators {
      height: 100%;
    }

    .select__input-container {
      height: 100%;
      padding: 0;
      margin: 0;
      line-height: 100%;
    }

    .select__placeholder {
      box-shadow: none !important;
      border-color: transparent;
      line-height: 100%;
    }

    .select__value-container {
      box-shadow: none !important;
      border-color: transparent;
    }

    .select__input {
      height: 100%;

      &:focus,
      &:focus-within {
        outline: none;
      }
    }
  }

  .select__menu {
    min-width: 100%;
    top: calc(100% + 2px);
    left: -1px;
    width: fit-content;
    margin-top: 2px;
    box-shadow: none;
    border-radius: 0;
    border-radius: ${theme.colors.noteSheet.dropdown.radius}px;

    ${({ reverse }) =>
      reverse == 'Y' &&
      `
        top : initial;
        bottom: 40px;
      `}
  }

  .select__menu-list {
    max-height: 200px;
    border-color: ${theme.colors.noteSheet.dropdown.border} !important;
    border-radius: ${theme.colors.noteSheet.dropdown.radius}px;
    box-shadow: ${theme.shadow['card-2']};
    padding: ${theme.colors.noteSheet.dropdown.padding}px;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .select__option {
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 10px;
      color: ${theme.colors.gray[900]};
      font-size: 14px;
      font-weight: 400;
      border-radius: ${theme.colors.noteSheet.dropdown.radius}px;
      line-height: 100%;
      cursor: pointer;
    }
    .select__option--is-focused {
      background-color: ${theme.colors.noteSheet.dropdown.hover};
    }
    .select__option--is-selected {
      background-color: ${theme.colors.noteSheet.dropdown.primary};
    }
  }
`

const StyledInput = styled(components.Input)`
  left: -10px;
  padding: 0;
  margin: 0;
`

const SelectLabelName = styled.div`
  margin-right: 6px;
  padding-right: 6px;
  border-right: 1px solid ${theme.colors.gray[300]};
  font-weight: 600;

  ${({ visible }) =>
    visible &&
    `
    border-right: 1px solid ${theme.colors.gray[300]};
    margin-right: 6px;
    padding-right: 6px;
  `}
`
export default forwardRef(CustomContactCellSelectItem)
