import theme from 'lib/styles/theme'
import styled from 'styled-components'
import React, { useCallback, useRef } from 'react'
import Swipe from 'react-easy-swipe'
import { AutoSizer } from 'react-virtualized'
import { FixedSizeList as List } from 'react-window'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import NoteListMobileItem from '../NoteList/NoteListMobileItem'
import { Empty } from 'components/design'

/**
 * 계약관리 목록 모바일 버전
 */
const NoteDocsListMobile = ({
  data,
  onSwipeStart,
  onSwipeMove,
  onSwipeEnd,
}) => {
  const formattedData = data.map((item) => {
    const totalPriceKey = {
      매매: 'sale_price',
      전세: 'jeonse_deposit',
      월세: 'deposit',
    }
    return {
      ...item,
      [totalPriceKey[item.contract_type]]: item.total_price,
      rent: item.payment_schedule.rent_price,
    }
  })

  const history = useHistory()
  const listRef = useRef({})

  /**
   * 클릭시 3뎁스로 이동
   * @param {*} channel_srl
   * @param {*} address_srl
   * @param {*} note_srl
   */
  const onClick = useCallback(
    (e, channel_srl, contract_srl, note_srl) => {
      e.stopPropagation()
      history.push(
        `/note/v2/docs/${channel_srl}/${note_srl}/edit/${contract_srl}`,
      )
    },
    [],
  )

  /**
   * 리스트 아이템
   * @param {*} param0
   * @returns
   */
  const Row = ({ index, style }) => {
    const item = formattedData[index]

    return (
      <NoteListMobileItem
        type="doc"
        item={item}
        key={index}
        style={style}
        onClick={onClick}
      />
    )
  }

  if (data?.length == 0) return <Empty>등록된 계약서가 없습니다.</Empty>

  return (
    <>
      <ScrollWrapper
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
      >
        <AutoSizer
          className="autosizer"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {({ height, width }) => (
            <List
              itemCount={formattedData.length}
              itemSize={122}
              ref={listRef}
              itemData={formattedData}
              width={width}
              height={height}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </ScrollWrapper>
    </>
  )
}

const ScrollWrapper = styled(Swipe)`
  width: 100%;
  height: calc(100svh - 112px);
  border-top: 1px solid ${theme.colors.gray[100]};
`

export default React.memo(NoteDocsListMobile)
