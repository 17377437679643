import NoteListPage from './NoteList/NoteListPage'
import NoteDocsPage from './NoteDocs/NoteDocsPage'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ChannelContainer from 'containers/notev2/ChannelContainer'
import NotFound from 'pages/NotFound'
import NotePaymentFailPage from 'components/jmapnotev2/NotePayment/NotePaymentFailPage'
import NotePayment from 'pages/jmapnotev2/NotePayment/NotePayment'
import NoteDashboard from 'pages/jmapnotev2/NoteDashboard./NoteDashboard'
import NotePaymentSuccess from 'components/jmapnotev2/NotePayment/NotePaymentSuccess'
import NotePaymentPlan from 'components/jmapnotev2/NotePayment/NotePaymentPlan'
import NotePaymentDeunggibu from 'components/jmapnotev2/NotePayment/NotePaymentDeunggibu'
import NotePictures from 'components/jmapnotev2/NotePictures/NotePictures'
import NoteCustomer from 'components/jmapnotev2/NoteCustomer/NoteCustomer'
import NoteCalendarPage from 'pages/jmapnotev2/NoteCalendar/NoteCalendarPage'
import { useSelector } from 'react-redux'
import NoteAccount from 'components/jmapnotev2/account/NoteAccount'
import NotePaymentInaccessible from './NotePayment/NotePaymentInaccessible'
import NoteAdPage from './NoteAd/NoteAdPage'
import NoteAdLink from 'components/jmapnotev2/NoteAd/NoteAdLink'
import NotePaymentContainer from 'containers/notev2/NotePaymentContainer'
import { accessibleStaffGroups } from 'Constants'
import NoteAdEdit from 'components/jmapnotev2/NoteAd/NoteAdEdit'
import NotePaymentFailContainer from 'containers/notev2/NotePaymentFailContainer'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import NoteNoticeViewPage from './NoteNotice/NoteNoticeViewPage'
import NoteNoticePage from './NoteNotice/NoteNoticePage'
import NoteDonghoPage from './NoteDongho/NoteDonghoPage'
import NoteMemberMng from './NoteMemberMng/NoteMemberMng'

const NotePageType = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { isAllInOne } = useIsAllInOne()

  return (
    <ChannelContainer>
      <NotePaymentFailContainer />

      <Switch>
        <Route path="/note/v2/dashboard" component={NoteDashboard} />
        <Route
          path={[
            '/note/v2/notes',
            '/note/v2/notes/:channelSrl/:addressId?',
          ]}
          component={NoteListPage}
        />
        <Route
          path="/note/v2/docs/:channelSrl"
          component={NoteDocsPage}
        />
        <Route
          path="/note/v2/pictures/:channelSrl"
          component={NotePictures}
        />
        <Route
          path="/note/v2/dongho/:id?/:dong?"
          component={NoteDonghoPage}
          exact
        />
        {(isAllInOne ||
          loginInfo?.other_groups?.some((item) =>
            accessibleStaffGroups.includes(item),
          )) && (
          <Route path="/note/v2/ad" component={NoteAdPage} exact />
        )}
        {(isAllInOne ||
          loginInfo?.other_groups?.some((item) =>
            accessibleStaffGroups.includes(item),
          )) && (
          <Route
            path="/note/v2/ad/link/:ad_srl"
            component={NoteAdLink}
          />
        )}
        {(isAllInOne ||
          loginInfo?.other_groups?.some((item) =>
            accessibleStaffGroups.includes(item),
          )) && (
          <Route
            path="/note/v2/ad/:ad_srl"
            component={NoteAdEdit}
            exact
          />
        )}
        <Route
          path="/note/v2/notice"
          exact
          component={NoteNoticePage}
        />
        <Route
          path="/note/v2/notice/:id"
          exact
          component={NoteNoticeViewPage}
        />
        <Route path="/note/v2/calendar" component={NoteCalendarPage} />
        <Route path="/note/v2/customer" component={NoteCustomer} />
        {loginInfo?.emergency != 'Y' && (
          <Route
            key="/note/v2/account"
            path="/note/v2/account"
            component={NoteAccount}
            exact
          />
        )}
        {/* 결제 관리 페이지는 앱이 아닌 곳, emergency(전세 피해) 회원이 아닌 경우만 라우팅 */}
        {navigator.userAgent.match(
          /(com\.jootek\.mobile|rhymixpush|RXPUSH)/,
        )
          ? // 앱에서 결제관리로 접속한 경우(푸쉬 알림으로 밖에 접속 못함) pc에서 확인해주세요 컴포넌트 보여줌
            loginInfo?.member_type == 'realestate' &&
            loginInfo.emergency != 'Y' && (
              <Route
                path="/note/v2/payment"
                key="/note/v2/payment"
                component={NotePaymentInaccessible}
                exact
              />
            )
          : loginInfo?.member_type == 'realestate' &&
            loginInfo.emergency != 'Y' && (
              <NotePaymentContainer>
                <Route
                  path="/note/v2/payment"
                  key="/note/v2/payment"
                  component={NotePayment}
                  exact
                />
                <Route
                  path="/note/v2/memberMng"
                  key="/note/v2/memberMng"
                  component={NoteMemberMng}
                  exact
                />
                {!loginInfo?.other_groups.includes('tester') && (
                  <Route
                    path="/note/v2/payment/plan"
                    key="/note/v2/payment/plan"
                    component={NotePaymentPlan}
                    exact
                  />
                )}
                <Route
                  path="/note/v2/payment/addDeunggi"
                  key="/note/v2/payment/addDeunggi"
                  component={NotePaymentDeunggibu}
                  exact
                />
                <Route
                  path="/note/v2/payment/success/:planSrl"
                  key="/note/v2/payment/success/:planSrl"
                  component={NotePaymentSuccess}
                  exact
                />
                <Route
                  path="/note/v2/payment/fail"
                  key="/note/v2/payment/fail"
                  component={NotePaymentFailPage}
                />
              </NotePaymentContainer>
            )}
        <Route path="*" component={NotFound} />
      </Switch>
    </ChannelContainer>
  )
}

export default React.memo(NotePageType)
