import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

export const LayerGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 47px;
  cursor: pointer;
  border-bottom: 1px solid ${theme.colors.gray[200]};

  ${({ noborder }) => noborder && `border-bottom: none;`}

  span {
    font-size: 11px;
    font-weight: 500;
    margin-top: 6px;
    line-height: 100%;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  i {
    &.fa-webcam {
      font-size: 17px;
      color: ${theme.colors.gray[600]};
    }
    font-size: 19px;
  }

  ${({ active }) =>
    active &&
    `
        background-color: ${theme.colors.gray[800]};
        color: ${theme.colors.base.white};
        i {
            &.fa-webcam {
                color: ${theme.colors.base.white};
            }
        }
    `};

  ${({ dotactive }) =>
    dotactive &&
    `
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      right: 8px;
      width: 5px; 
      height: 5px;
      background-color: ${theme.colors.tint.danger};
      border-radius: 50%;
    }
  `}

  ${media.mediumS`
    height: 48px;

    svg {
      width: 18px;
      height: 18px;
    }

    i {
      font-size: 16px;
    }

    span {
      margin-top: 5px;
      font-size: 11px;
    }
  `}
`
