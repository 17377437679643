import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { ReactComponent as UnitIcon } from 'assets/icon/note/detail/unit.svg'
import { useCookies } from 'react-cookie'

/**
 * 매물노트용 평, 제곱미터 토글
 * @param color 색상 black
 * @param size 사이즈 small
 */
const NoteDetailNoteConverter = ({ onClick, color, size }) => {
  const {
    state: { isSquareMeter },
    actions,
  } = useContext(MapContext)

  const [cookies, setCookie, removeCookie] = useCookies([
    'clickPosition',
  ])

  // 평-제곱미터 토글
  const toggleSquareMeter = useCallback(() => {
    actions.setIsSquareMeter((prev) => {
      // 쿠키저장
      setCookie('isSquareMeter', !prev ? 'squareMeter' : 'pyeong', {
        path: '/',
      })
      removeCookie('isSquareMeter', { path: '/map' })
      return !prev
    })
  })

  return (
    <UnitConverter
      size={size}
      backgroundcolor={color}
      onClick={() => {
        if (onClick) {
          onClick()
        } else {
          toggleSquareMeter()
        }
      }}
      className={`note-pym2-converter ${
        isSquareMeter ? ' rotate' : ''
      }`}
    >
      <UnitIcon />
      <UnitText backgroundcolor={color} size={size}>
        {isSquareMeter ? '㎡' : '평'}
      </UnitText>
    </UnitConverter>
  )
}

const UnitConverter = styled.div`
  display: flex;
  position: relative;
  user-select: none;
  cursor: pointer;

  & > svg {
    fill: ${theme.colors.blue[600]};

    ${({ backgroundcolor }) =>
      backgroundcolor == 'black' &&
      `
        filter: grayscale() brightness(65%);
        width: 28px;
        height: 28px;
    `}
    ${({ size }) =>
      size == 'small' &&
      `
        width: 22px;
        height: 22px;
    `}

    transition: transform 0.35s;
    transform: rotate(210deg);
  }

  &.rotate {
    & > svg {
      transition: transform 0.35s;
      transform: rotate(30deg);
    }
  }
`

const UnitText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-weight: 600;
  font-size: 9px;
  top: 1px;
  left: 0;
  width: 31px;
  height: 100%;
  color: ${theme.colors.blue[600]};

  ${({ backgroundcolor }) =>
    backgroundcolor == 'black' &&
    `
      color: ${theme.colors.gray[900]};
      width: 28px;
      top: 0.9px;
    `}

  ${({ size }) =>
    size == 'small' &&
    `
      width: 22px;
      top: 0;
      font-size: 5px;
    `}

  &.pd {
    margin-top: -1px;
  }
`

export default NoteDetailNoteConverter
