import { Button, Empty } from 'components/design'
import NoteAdNotificationListContainer from 'containers/notev2/NoteAdNotificationListContainer'
import theme, { media } from 'lib/styles/theme'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { ReactComponent as EmptyIcon } from 'assets/icon/note/ad/nodata.svg'
import NoteAdNotificationSlide from './NoteAdNotificationSlide'
import { forwardRef } from 'react'

/**
 * 광고 메인 광고 상태 알림 목록
 */
const NoteAdNotificationList = ({ isSlideVisible }, ref) => {
  const history = useHistory()

  const onClickButton = (url) => {
    history.push(url.replace('/map', ''))
  }

  return (
    <Wrapper>
      <NoteAdNotificationListContainer>
        {({ list }) => {
          const mediumM = theme.bp.mediumM
          const mediumS = theme.bp.mediumS
          const wide = theme.bp.wide

          return (
            <>
              {list.length == 0 ? (
                <Empty icon={<EmptyIcon />}>
                  업무 알림이 없습니다.
                </Empty>
              ) : (
                <StyledSwiper
                  grabCursor={true}
                  slidesPerView={1}
                  spaceBetween={15}
                  breakpoints={{
                    [mediumS]: {
                      slidesPerView: 1,
                    },
                    [mediumM]: {
                      slidesPerView: 2,
                    },
                    [wide]: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {list.map(
                    (
                      {
                        title,
                        address,
                        verification = null,
                        regdate,
                        buttons,
                        fail,
                      },
                      i,
                    ) => {
                      return (
                        <SwiperSlide key={i}>
                          <Title>{title || '제목없음'}</Title>
                          <Address>{address}</Address>
                          <Verification>
                            <span>검증방식</span>
                            <strong>
                              {verification || <>&ndash;</>}
                            </strong>
                          </Verification>
                          {fail && (
                            <Fail>
                              <span>실패사유</span>
                              <strong>{fail}</strong>
                            </Fail>
                          )}
                          <Time>{moment(regdate).fromNow()}</Time>
                          {buttons && (
                            <ButtonWrapper>
                              {buttons.map(
                                ({ name, type, url }, buttonIdx) => {
                                  return (
                                    <Button
                                      key={buttonIdx}
                                      size="small"
                                      onClick={() => onClickButton(url)}
                                      color={type}
                                    >
                                      {name}
                                    </Button>
                                  )
                                },
                              )}
                            </ButtonWrapper>
                          )}
                        </SwiperSlide>
                      )
                    },
                  )}
                </StyledSwiper>
              )}
              {/* 업무 알림 슬라이드 */}
              <NoteAdNotificationSlide
                ref={ref}
                listData={list}
                visible={isSlideVisible}
              />
            </>
          )
        }}
      </NoteAdNotificationListContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  margin-top: 18px;

  ${media.mediumM`
    flex-direction: column;
  `};
`

const StyledSwiper = styled(Swiper)`
  margin-right: -1px;
  width: 100%;
  position: relative;
  .swiper-slide {
    padding-right: 15px;
    border-right: 1px dashed ${theme.colors.base.black}1A;
  }
`

const Item = styled.div`
  width: 100%;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 6px;
`

const Address = styled.div`
  color: ${theme.colors.blue[400]};
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  margin-bottom: 2px;
`

const Verification = styled.div`
  margin-bottom: 2px;
  font-size: 12px;
  color: ${theme.colors.gray[600]};
  font-weight: 500;
  line-height: 130%;
  strong {
    margin-left: 4px;
  }
`

const Fail = styled.div`
  color: ${theme.colors.tint.danger};
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;

  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  strong {
    margin-left: 4px;
  }

  ${media.mediumM`
      margin-bottom: 12px;
  `}
`

const Time = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  color: ${theme.colors.gray[400]};
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 6px;
  ${media.mediumM`
    justify-content: space-between;
  `}
`

export default forwardRef(NoteAdNotificationList)
