import axios from 'axios'

export const getCalendars = async (
  { _rx_csrf_token, channelSrl = null },
  options,
) =>
  await axios.post(
    '/jmapnote/api/calendar/getCalendars',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
    },
    options,
  )

export const getCalendar = async (
  { _rx_csrf_token, calendarSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/calendar/getCalendar',
    {
      _rx_csrf_token,
      calendar_srl: calendarSrl,
    },
    options,
  )

export const saveCalendar = async (
  {
    _rx_csrf_token,
    channelSrl,
    folderSrl,
    addressSrl,
    noteSrl,
    type,
    date,
    content,
    contact_srls,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/calendar/save',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
      type,
      date,
      content,
      contact_srls,
    },
    options,
  )

export const modifyCalendar = async (
  {
    _rx_csrf_token,
    channelSrl,
    folderSrl,
    addressSrl,
    noteSrl,
    type,
    date,
    content,
    contact_srls,
    calendarSrl,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/calendar/modify',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
      calendar_srl: calendarSrl,
      type,
      date,
      content,
      contact_srls,
    },
    options,
  )

export const removeCalendar = async (
  { _rx_csrf_token, channelSrl, calendarSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/calendar/remove',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      calendar_srl: calendarSrl,
    },
    options,
  )
