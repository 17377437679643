import { useEffect, useState } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { calcCostByDay } from 'lib/api/notev2/ad'
import { useSelector } from 'react-redux'

/**
 * 일할계산 api 호출
 * 모달이 visible일 때 마다 호출
 */
export default function useCalcCostByDay(modalVisible, type) {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [amount, setAmount] = useState(0)
  const [onRequestCalcCost, , dataCalcCost] = useAxios(calcCostByDay)

  /**
   * 일할계산
   */
  useEffect(() => {
    if (!modalVisible) return
    onRequestCalcCost({
      plan_srl: planInfo.allinone_plan_srl,
      type,
    })
  }, [modalVisible])

  useEffect(() => {
    if (!dataCalcCost) return
    setAmount(dataCalcCost.total_price)
  }, [dataCalcCost])

  return { amount }
}
