import styled from 'styled-components'
import theme from 'lib/styles/theme'

export const adStatuscolors = {
  작성중: theme.colors.blue[400],
  전송중: theme.colors.gray[600],
  승인대기: theme.colors.gray[600],
  승인거절: theme.colors.red[400],
  검증중: theme.colors.blue[400],
  검증취소: theme.colors.red[400],
  검증1차실패: theme.colors.red[400],
  검증최종실패: theme.colors.red[400],
  광고중: theme.colors.primary[500],
  종료예정: theme.colors.gray[600],
  광고종료: theme.colors.gray[400],
  신고매물: theme.colors.red[400],
  동일주소매물: theme.colors.blue[400],
  전송실패: theme.colors.red[400],
}

/**
 * 광고관리 그리드
 * 광고상태
 */
const CustomStatusCell = ({ node, value }) => {
  if (node.data.ad_srl == '신규' || node.data.already_created != 'Y')
    return <></>

  return (
    <Wrapper
      tabIndex={-1}
      color={adStatuscolors[value] || theme.colors.gray[600]}
    >
      {value}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  position: relative;
  z-index: 1;
  ${({ isVisible }) => isVisible && `opacity: 1;`}
  ${({ color }) => color && `color: ${color};`}
`

export default CustomStatusCell
