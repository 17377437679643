import { useEffect, useState } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { useSelector } from 'react-redux'
import { getContracts } from 'lib/api/notev2/contractApi'
import { useLocation } from 'react-router-dom'

const NoteDocsListContainer = ({
  q = '',
  filters = {},
  children,
  closeFilter,
}) => {
  const location = useLocation()
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [gridRowData, setGridRowData] = useState([])

  const [onRequest, , data] = useAxios(getContracts)

  useEffect(() => {
    if (location?.state?.flipLayer) {
      closeFilter(false)
    }
  }, [location])

  const onSearch = (e) => {
    if (e.keyCode == '13') {
      onRequest({
        channel_srl: channelInfo?.channel_srl,
        q,
        filters,
      })
    }
  }

  useEffect(() => {
    if (!channelInfo) return

    onRequest({
      channel_srl: channelInfo?.channel_srl,
      q,
      filters,
    })
  }, [channelInfo, filters])

  useEffect(() => {
    if (!data) return

    setGridRowData(data.result)
  }, [data])

  return children({
    onSearch,
    gridRowData,
    setGridRowData,
  })
}

export default NoteDocsListContainer
