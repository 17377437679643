import Axios from 'axios'

export const uploadImage = async (formData, options) =>
  await Axios.post('/jmapnote/api/note/uploadImage', formData, options)

export const deleteImage = async (
  { channel_srl, folder_srl, address_srl, note_srl, file_srl },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/deleteImage',
    {
      channel_srl,
      folder_srl,
      address_srl,
      note_srl,
      file_srl,
    },
    options,
  )

export const getImagesInChannel = async (
  { channel_srl, filters, orderBy, q },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/image/list',
    {
      channel_srl,
      filters,
      orderBy,
      q,
    },
    options,
  )

export const getImagesFilters = async ({ channel_srl }, options) =>
  await Axios.post(
    '/jmapnote/api/image/filters',
    {
      channel_srl,
    },
    options,
  )
