import { useEffect, useState } from 'react'
import palette from 'lib/styles/palette'
import { buildingPurposeColors, getUseAreaFormat } from 'utils/index'
import { Tag } from 'components/design'
import { getCustomPurpose } from 'lib/utils'
import { MetaItem } from './SummaryHeaderMeta'

/**
 * summary 최상단 태그 - 용도지역
 */
const SummaryHeaderMetaUseArea = ({ building, land }) => {
  const [useAreaColor, setUseAreaColor] = useState('purple')
  const [formattedUseArea, setFormattedUseArea] = useState('') // 용도지역명 4글자로 포맷

  /**
   * 용도지역 컬러 셋팅
   */
  useEffect(() => {
    setUseAreaColor('purple')

    if (!land) return

    const palette_use_area = palette.useArea
    const keys = Object.keys(palette_use_area)
    const raw = land.zone_use1 || undefined

    keys.map((area) => {
      if (raw?.includes(area)) {
        setUseAreaColor(palette_use_area[area])
        return area
      }
      return area
    })
  }, [land])

  /**
   * 용도지역명 4글자 포맷
   */
  useEffect(() => {
    if (land && land.zone_use1) {
      const usearea = getUseAreaFormat(land.zone_use1)
      setFormattedUseArea(usearea)
    }
  }, [land])

  const getPropertyTypeColor = () => {
    return getCustomPurpose(building)
      ? typeof buildingPurposeColors[getCustomPurpose(building)] !==
        'undefined'
        ? buildingPurposeColors[getCustomPurpose(building)]
        : null
      : null
  }

  return (
    formattedUseArea !== '' && (
      <MetaItem>
        <Tag color={useAreaColor} size="small" tooltip="용도지역">
          {formattedUseArea}
        </Tag>
      </MetaItem>
    )
  )
}

export default SummaryHeaderMetaUseArea
