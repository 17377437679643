import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { options } from '../../options'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import SelectBox from '../../elements/SelectBox'
import Checkbox from '../../elements/Checkbox'
import React from 'react'
import useSave from '../../useSave'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 옵션
 */
const NoteAdColumnLandOption = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onChangeCheckbox } = useSave()

  const option = watch('article.option') || ''

  const list = options['토지옵션']

  return (
    <ColumnSet>
      <ColumnTitle>옵션</ColumnTitle>
      <ColumnVal gap={15}>
        {list.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <Checkbox
                id={`article_land_option${i}`}
                color="blue"
                value={item}
                {...register('article.option')}
                onChange={(checked, e) => {
                  onChangeCheckbox({
                    originValue: option,
                    key: 'article.option',
                    value: e,
                  })
                }}
              >
                {item}
              </Checkbox>
            </React.Fragment>
          )
        })}
        <ValidateWarn>{errors?.article?.option?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnLandOption
