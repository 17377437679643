import { useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { CSSTransition } from 'react-transition-group'
import { media } from 'lib/styles/theme'
import { ScrollStyle } from '../style/NoteStyle'
import { Button } from 'components/design'

const CustomModal = ({
  title,
  visible,
  onCancel,
  onOk,
  width = 340,
  children,
  color = 'white',
  noCloseButton = false,
  cancelHide = false,
  footer = true,
  padding = 'default',
  cancelText,
}) => {
  const modalRoot = document.getElementById('modal')

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 27 && visible) {
        onCancel()
      }
    },
    [visible],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [visible])

  return ReactDOM.createPortal(
    <CSSTransition in={visible} timeout={300} unmountOnExit>
      <ModalWrapper>
        <ModalContainer width={width}>
          <ModalDialog
            color={color}
            width={width}
            className="modal-dialog"
          >
            <ModalHeader padding={padding}>
              <ModalTitle>{title}</ModalTitle>
              {!noCloseButton && (
                <CloseButton color={color} onClick={onCancel}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </CloseButton>
              )}
            </ModalHeader>
            <ModalBody padding={padding}> {children}</ModalBody>
            {footer && (
              <ModalFooter>
                {/* cancleButton */}
                {!cancelHide && (
                  <Button color="gray" onClick={onCancel}>
                    {cancelText ? cancelText : '취소'}
                  </Button>
                )}

                {/* okButton */}
                <Button color="blue" onClick={onOk}>
                  확인
                </Button>
              </ModalFooter>
            )}
          </ModalDialog>
        </ModalContainer>
        <Dimmer
          className="modal-dialog-dimmer"
          onClick={onCancel}
        ></Dimmer>
      </ModalWrapper>
    </CSSTransition>,
    modalRoot,
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;

  &.enter {
    opacity: 0;

    .modal-dialog {
      transform: translateY(-1rem);
    }
  }

  &.enter-active {
    opacity: 1;
    transition: opacity 0.2s ease;
    .modal-dialog {
      transform: translateY(0);
      transition: transform 0.3s ease;
    }
  }

  &.exit {
    opacity: 1;
    .modal-dialog {
      transform: translateY(0);
    }
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 0.2s ease;

    .modal-dialog {
      transform: translateY(-1rem);
      transition: transform 0.3s ease;
    }
  }
`

const ModalContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  max-width: ${({ width }) => `${width + 32}px`};
  width: 100%;
  max-height: 100%;
  padding: 1rem;
`

const ModalDialog = styled.div`
  width: 100%;
  background-color: ${({ color }) => color};
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 30px;
  will-change: transform;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${theme.colors.gray[200]};

  padding: ${({ padding }) =>
    padding == 'medium' ? `24px 24px 0 24px` : `20px 16px 0 1.25rem`};
`

const ModalTitle = styled.div`
  flex: 1;
  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 700;
`

const CloseButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  color: ${theme.colors.base.white};
  font-size: 1rem;

  background-color: transparent;
  border-radius: 8px;

  transition: background-color 250ms;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  ${media.mediumS`
    width:24px;
    height:24px;

`}
`

const ModalBody = styled.div`
  padding: ${({ padding }) =>
    padding == 'medium' ? `0 35px 40px` : `0 16px 20px`};
  max-height: calc((100vh - 200px) - env(safe-area-inset-bottom));
  overflow-y: auto; // All other browsers
  overflow-y: overlay; // Chrome & Safari
  ${ScrollStyle}
  @media (max-height: $modal-expand-height) {
    max-height: calc((100vh - 200px) - env(safe-area-inset-bottom));
  }
`

const Dimmer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`
const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.4rem 1.25rem 0.95rem;

  & > button {
    margin-right: 0.75rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

export default CustomModal
