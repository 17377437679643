import { useState } from 'react'

import { Button } from 'components/design'
import { Card } from 'components/design'
import { CardBody } from 'components/design'
import { Modal } from 'components/design'

import { withRouter } from 'react-router-dom'
import InviteSaveContainer from 'containers/note/invite/InviteSaveContainer'

const InviteHead = () => {
  const [visible, setVisible] = useState(false)

  const onClose = () => {
    setVisible(false)
  }

  return (
    <Card noMargin>
      <CardBody>
        <Button
          color="primary"
          plain
          expand
          onClick={() => setVisible(true)}
        >
          초대하기
        </Button>
      </CardBody>
      <Modal
        title="초대하기"
        visible={visible}
        onCancel={onClose}
        width={450}
        footer={null}
      >
        <InviteSaveContainer onClose={onClose} />
      </Modal>
    </Card>
  )
}

export default withRouter(InviteHead)
