import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptionsForObject } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 건축물용도
 */
const NoteAdColumnBuildingUse = ({
  required = false,
  half = true,
  text,
  columnKey = 'article.principalUseCode',
}) => {
  const adOptions = useSelector((state) => state.noteAd.adOptions)
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const [list, setList] = useState(null)

  useEffect(() => {
    if (!adOptions) return
    setList(adOptions.principalUseCode)
  }, [adOptions])

  const val = list?.[watch(columnKey)]

  return (
    <ColumnSet half={half}>
      <ColumnTitle required={required}>
        {text ? text : '건축물용도'}
      </ColumnTitle>
      <ColumnVal gap={15}>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: val || '선택',
            value: val,
          }}
          isSearchable
          options={getSimpleSelectboxOptionsForObject(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register(columnKey)}
          onChange={(e) => {
            onUpdate(columnKey, e.value)
          }}
          isLoading={!list}
        />
        {columnKey == 'article.principalUseCode' && (
          <ValidateWarn>
            {errors?.article?.principalUseCode?.message}
          </ValidateWarn>
        )}
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnBuildingUse
