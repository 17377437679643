import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import Spinner from 'components/common/Loading/Spinner'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Swipe from 'react-easy-swipe'
import NoteFilterWrapper from 'components/jmapnotev2/style/NoteFilterWrapper'
import {
  FilterScrollWrapper,
  MobileCover,
} from 'components/jmapnotev2/style/NoteStyle'
import NoteDonghoDanjiList from './NoteDonghoDanjiList'
import { useDispatch } from 'react-redux'
import { setIsInfoBubbleVisible } from 'modules/noteDongho'
import { useSelector } from 'react-redux'
import NoteDonghoSpeechBubble from './NoteDonghoSpeechBubble'
import NoteDonghoBubbleContainer from 'containers/notev2/NoteDonghoBubbleContainer'

const NoteLeftSide = ({ onSwipeStart, onSwipeMove, onSwipeEnd }) => {
  return (
    <NoteDonghoBubbleContainer>
      {({
        visibleBubble,
        isInfoBubbleVisible,
        onCloseBubble,
        onScroll,
      }) => {
        return (
          <Wrapper
            onSwipeStart={onSwipeStart}
            onSwipeMove={onSwipeMove}
            onSwipeEnd={onSwipeEnd}
          >
            <NoteFilterWrapper earlyMobile>
              <Scrollbars onScroll={onScroll} autoHide>
                <FilterScrollWrapper>
                  <Suspense
                    fallback={
                      <SpinnerWrapper>
                        <Spinner />
                      </SpinnerWrapper>
                    }
                  >
                    <NoteDonghoDanjiList />
                  </Suspense>
                </FilterScrollWrapper>
              </Scrollbars>
            </NoteFilterWrapper>
            <MobileCover className="m-cover" />
            {visibleBubble && isInfoBubbleVisible && (
              <NoteDonghoSpeechBubble onClose={onCloseBubble} />
            )}
          </Wrapper>
        )
      }}
    </NoteDonghoBubbleContainer>
  )
}

const Wrapper = styled(Swipe)`
  width: 100%;
  height: 100%;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
  text-align: center;
`
export default React.memo(NoteLeftSide)
