import { Button } from 'components/design'
import { FormItem } from 'components/design'
import styled from 'styled-components'

import Image from 'assets/images/schedule-blurred-background.jpg'
import NoteScheduleItem from '../NoteSchedule/NoteScheduleItem'
import { useEffect, useState } from 'react'
import theme from 'lib/styles/theme'
import ErrorView from 'components/common/ErrorView/ErrorView'
import useContacts from 'containers/note/contact/hooks/useContacts'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import NoteScheduleForm from '../NoteSchedule/NoteScheduleForm'

const ScheduleTab = ({
  schedules = [],
  onSubmit,
  onRemove,
  isSave,
  onRefresh,
  sheet,
}) => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const channels = useSelector((state) => state.channel.channels)

  const { watch } = useFormContext()
  const channel_srl = watch('channel_srl')

  const readOnly =
    channelInfo?.member_type == 'readonly' ||
    channels.find(
      ({ channel_srl: myChannelSrl }) => myChannelSrl == channel_srl,
    )?.member_type == 'readonly'

  const [visible, setVisible] = useState(false)

  const { _getContacts, loading, contacts: myContacts } = useContacts()

  /**
   * @description 사용자 자신의 연락처 목록을 불러옴
   */
  useEffect(() => {
    if (isSave) return

    _getContacts()
  }, [_getContacts, isSave])

  if (isSave)
    return (
      <Wrapper>
        <Icon>!</Icon>
        <Text>
          매물에 <strong>일정을 등록</strong>하려면 <br />
          먼저 매물을 <strong>저장하세요</strong>
        </Text>
      </Wrapper>
    )

  return (
    <NoSaveWrapper sheet={sheet}>
      <FormItem noContainer={sheet}>
        {!readOnly && (
          <Button
            color={sheet ? 'blue' : 'primary'}
            size="large"
            plain
            expand
            onClick={() => setVisible(true)}
          >
            이 매물에 대한 일정 추가
          </Button>
        )}
        {!sheet && (
          <Info>
            <i className="fas fa-info-circle"></i>
            <span>
              매물 일정에 대한 수정사항은 매물을 저장하지 않아도
              반영됩니다.
            </span>
          </Info>
        )}
      </FormItem>

      <Divider />
      <ul>
        {schedules.map((item, key) => (
          <NoteScheduleItem
            readOnly={readOnly}
            sheet={sheet}
            item={item}
            key={key}
            myContacts={myContacts}
            onRemove={onRemove}
            onRefresh={onRefresh}
          />
        ))}
      </ul>

      {schedules.length === 0 && (
        <ErrorView msg="등록된 일정이 없습니다." />
      )}

      <NoteScheduleForm
        sheet={sheet}
        title="이 매물에 대한 일정 추가"
        visible={visible}
        myContacts={myContacts}
        setVisible={setVisible}
        onSubmit={onSubmit}
      />
    </NoSaveWrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;
  margin-top: -1.5rem;
  background-size: 100%;
  background-position: top;
  background-image: url('${Image}');
  text-align: center;
`

const NoSaveWrapper = styled.div`
  ${({ sheet }) =>
    sheet &&
    `
      margin-top: 12px;
    `}
`

const Icon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;

  width: 48px;
  height: 48px;
  color: #618dff;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #ceddfe;
  border-radius: 100%;
`
const Text = styled.div`
  font-size: 1.125rem;
  strong {
    font-weight: 700;
  }
`

const Info = styled.div`
  margin-top: 1rem;
  color: ${theme.colors.gray[500]};
  font-size: 12px;
  line-height: 0.875rem;
  vertical-align: middle;

  i {
    margin-right: 0.25rem;
    font-size: 0.875rem;
  }
`

const Divider = styled.div`
  margin-left: 1.25rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${theme.colors.gray[100]};
`

export default ScheduleTab
