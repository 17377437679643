import theme from 'lib/styles/theme'
import { forwardRef } from 'react'
import styled from 'styled-components'

const BaseNavItem = (
  {
    to,
    href,
    exact,
    onClick,
    target,
    as,
    icon,
    text,
    active,
    activeIcon,
    newBadge,
  },
  ref,
) => {
  return (
    <Li ref={ref}>
      <NavItem
        to={to}
        exact={exact}
        href={href}
        target={target}
        as={as}
        onClick={onClick}
        className={active && 'active'}
      >
        {newBadge && <NewBadge />}
        {icon}
        {activeIcon}
        <span>{text}</span>
      </NavItem>
    </Li>
  )
}

const Li = styled.li`
  flex: 1;
  height: 100%;
`

const NavItem = styled.a`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  height: 100%;
  padding: 8px 2px;
  border-bottom: 3px solid transparent;
  color: ${theme.colors.gray[900]};
  font-size: 13px;
  font-weight: 400;
  line-height: 1;

  cursor: pointer;

  &:hover {
    color: ${theme.colors.gray[900]};
    background-color: ${theme.colors.gray[50]};
  }

  & > i {
    color: ${theme.colors.gray[600]};
    font-size: 18px;
  }

  & > span {
    margin-top: 10px;
  }

  .defaultIcon {
    display: block;
  }

  .activeIcon {
    display: none;
  }

  &.active {
    color: ${theme.colors.primary[500]};
    font-weight: 600;

    & > i {
      &.defaultIcon {
        display: none;
      }

      &.activeIcon {
        display: block;
      }

      color: ${theme.colors.primary[400]};
    }
    & > svg {
      &.defaultIcon {
        display: none;
      }

      &.activeIcon {
        display: block;
      }

      fill: ${theme.colors.primary[400]};
    }
  }
`

const NewBadge = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.875rem;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${theme.colors.primary[400]};
`

export default forwardRef(BaseNavItem)
