import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import { getSimpleSelectboxOptionsForObject } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import InputDate from '../../elements/InputDate'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import SelectBox from '../../elements/SelectBox'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 건축물일자
 */
const NoteAdColumnBuildingDate = ({
  text = true,
  half = false,
  required = true,
}) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const adOptions = useSelector((state) => state.noteAd.adOptions)
  const [list, setList] = useState(null)

  useEffect(() => {
    if (!adOptions) return
    setList(adOptions.cdateType)
  }, [adOptions])

  const cdateType = watch('article.cdateType')
  const cdate = watch('article.cdate')

  return (
    <ColumnSet half={half}>
      <ColumnTitle required={required}>건축물일자</ColumnTitle>
      <ColumnVal gap={8}>
        {text && <>건축물 유형:&nbsp;</>}
        <SelectBox
          minWidth={InputRemoveAppendWidth}
          width={InputRemoveAppendWidth}
          value={{
            label: list?.[cdateType] || '선택',
            value: cdateType,
          }}
          isSearchable
          options={getSimpleSelectboxOptionsForObject(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('article.cdateType')}
          onChange={(e) => {
            onUpdate('article.cdateType', e.value)
          }}
          isLoading={!list}
        />
        <DateWrapper>
          <InputDate
            width={InputRemoveAppendWidth}
            type="date"
            {...register('article.cdate')}
            onChange={(e) => {
              onUpdate(
                'article.cdate',
                e ? moment(e).format('YYYY.MM.DD') : '',
              )
            }}
            placeholder="연도.월.일"
            expireDate
            fixAppendWidth
            value={cdate}
          />
        </DateWrapper>
        <ValidateWarn>
          <div>{errors?.article?.cdateType?.message}</div>
          <div>{errors?.article?.cdate?.message}</div>
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  span {
    margin-left: 10px;
    margin-right: 2px;
    word-break: keep-all;
  }
`

export default NoteAdColumnBuildingDate
