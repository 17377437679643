import theme, { media } from 'lib/styles/theme'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import { AgGridReact } from 'ag-grid-react'
import { noteSheetColor } from 'lib/utils/notev2/note2Constants'
import { CustomSingleCellStyle } from './NoteStyle'
import Swipe from 'react-easy-swipe'
import { gridFontSize } from 'lib/utils/notev2/gridUtil'

export const GridStyleWrapper = styled(Swipe)`
  position: relative;
  width: 100%;
  flex: 1;

  .ag-theme-alpine {
    font-weight: 500;
    line-height: 100%;

    --ag-font-family: Pretendard;
    --ag-font-size: ${({ gridfontsize }) =>
      gridfontsize || gridFontSize['m']}px;
    --ag-selected-row-background-color: ${theme.colors.noteSheet
      .primary};
    --ag-row-hover-color: transparent;
    --ag-foreground-color: ${theme.colors.gray[900]};
    --ag-alpine-active-color: ${theme.colors.blue[500]};
    --ag-control-panel-background-color: transparent;
    --ag-input-focus-border-color: ${theme.colors.blue[500]};
    --ag-border-radius: 0;
    --ag-card-shadow: none;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-separator-display: block;
    --ag-header-column-resize-handle-display: none;
    --ag-header-background-color: ${theme.colors.base.white};
    --ag-icon-font-family: 'Font Awesome 5 Pro';
    --ag-icon-font-code-none: '\f0dc';
    --ag-icon-font-code-none: '\f0dc';
    --ag-icon-font-code-asc: '\f0d8';
    --ag-icon-font-code-desc: '\f0d7';
    --ag-icon-font-code-small-down: '\f107';
    --ag-icon-font-code-small-up: '\f106';
    --ag-icon-size: 14px;
    //--ag-grid-size: 10px;
    --ag-borders-input: none;
    --ag-cell-horizontal-padding: 5px;
    --ag-odd-row-background-color: ${theme.colors.base.white};
    --ag-even-row-background-color: ${theme.colors.base.white};

    .ag-row-focus {
      z-index: 100;
    }

    .custom-row-complete {
      --ag-selected-row-background-color: ${theme.colors.gray[50]};
      --ag-row-hover-color: ${theme.colors.gray[50]};
      color: ${theme.colors.gray[400]};
    }

    .custom-multiple-cell {
      --ag-range-selection-border-color: transparent;
      border: none !important;
      border-right: 1px solid ${theme.colors.gray[900]}1A !important;
      padding: 0;
    }

    .custom-no-padding-cell {
      padding: 0;
    }

    .ag-picker-field-wrapper {
      border: none;
      border-radius: 0;
      background-color: transparent;
    }

    .ag-body-viewport, //세로 스크롤
    .ag-center-cols-viewport //가로 스크롤
    {
      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
    }
  }

  .ag-icon {
    font-weight: 600;
    color: ${theme.colors.gray[400]};
  }

  .ag-icon-small-down {
    margin-right: 5px;
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .ag-body-horizontal-scroll-viewport,
  .ag-body-vertical-scroll-viewport {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background-color: ${theme.colors.gray[300]};
      border: 3px solid transparent;
      min-height: 50px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 12px;
    }
  }

  .ag-body-vertical-scroll.ag-scrollbar-invisible {
    top: initial;
  }

  .ag-body-vertical-scroll {
    position: fixed;
    right: 0;
    height: calc(100% - 92px - 120px - 32px - 13px);

    ${media.mediumM`
      height: calc(100% - 78px - 48px - ${theme.base.headerHeight}px);
      top: initial;
      bottom: initial;
    `}
  }

  .ag-center-cols-container {
    .ag-row-focus {
      ${media.mediumS`
      ${({ threestepstate }) =>
        threestepstate == true &&
        `
        left: -295px !important;
    `}

    ${({ fourstepstate }) =>
      fourstepstate == true &&
      `
    left: -90px !important;
    `}
      
    ${({ fivestepstate }) =>
      fivestepstate == true &&
      `
        left: -90px !important;
        `}

      `}
    }
  }

  .ag-header-viewport {
    ${media.mediumS`
    ${({ threestepstate }) =>
      threestepstate == true &&
      `
      left: -295px !important;
      overflow:visible !important;
  `}

  ${({ fourstepstate }) =>
    fourstepstate == true &&
    `
  left: -90px !important;
  overflow:visible !important;

  `}

  ${({ fivestepstate }) =>
    fivestepstate == true &&
    `
left: -90px !important;
overflow:visible !important;

`}
    
    `}
  }

  .ag-grid-docs {
    .ag-center-cols-container {
      border-left: 1px solid ${theme.colors.gray[900]}1A;
    }
    .ag-body-vertical-scroll {
      height: calc(100% - 59px - 120px - 32px - 13px);
      ${media.smallPc`
        height: calc(
          100% - 59px - 120px - 32px - 13px
        );
        top: initial;
    `}
    }
  }

  .ag-header-custom-resize-header {
    &::after {
      ${media.mediumS`
        display: none !important;
      `}
    }
    .ag-header-cell-resize {
      ${media.mediumS`
        width: 20px;
        &::after {
          display: block;
          background-color: transparent;
          border-right: 1px solid ${theme.colors.gray[300]};
          border-left: 1px solid ${theme.colors.gray[300]};
          width: 5px;
          height: 80%;
          top: 50%;
          transform: translateY(-50%);
        }
      `}
    }
  }

  ////
  .ag-center-cols-viewport {
    //   overflow: hidden;
    overflow: visible;

    ${media.mediumS`
       overflow: hidden;
    `}
  }

  .ag-body {
    width: fit-content;

    ${media.mediumS`
      border-right: none;
    `}
  }

  .ag-body-clipper {
    ${media.mediumS`
      padding-right: 0px;
    `}
  }

  .ag-full-width-container {
    ${media.mediumS`
      border-bottom: 1px solid ${theme.colors.gray[900]}1A;
    `}
  }

  .ag-center-cols-container {
    border-right: 1px solid ${theme.colors.gray[900]}1A;
    border-bottom: 1px solid ${theme.colors.gray[900]}1A;

    ${media.mediumS`
      border-right: 0;
      border-bottom: 0;
    `}
  }

  .ag-root {
    padding-left: 40px;
    overflow-x: scroll;

    ${media.smallPc`
      padding-left: 24px;
    `}

    ${media.mediumS`
      padding-left: 20px;
    `}

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background-color: ${theme.colors.gray[300]};
      border: 3px solid transparent;
      min-height: 50px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 12px;
    }
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-header,
  .ag-header-viewport,
  .ag-header-cell-comp-wrapper,
  .ag-header-cell {
    overflow: visible;
  }

  .ag-header {
    border-bottom: none;
    ${media.mediumS`
      border-bottom: 1px solid ${theme.colors.gray[900]}1A;
    `}
  }

  .ag-header-container {
    background-color: ${theme.colors.base.white};
  }

  .ag-header-row {
    border: 1px solid ${theme.colors.gray[900]}1A;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    ${media.mediumS`
      border: none;
      border-top: 1px solid ${theme.colors.gray[900]}1A;
      border-left: none;
    `}
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    & > div {
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    ${media.mediumS`
      & > div {
        &:last-child {
          &::after {
            display: block;
          }
        }
      }
    `}
  }

  .ag-header-cell {
    font-size: ${({ gridfontsize }) =>
      (gridfontsize || gridFontSize['m']) + 1}px;
    font-weight: 500;
    line-height: 100%;
  }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: pre-wrap !important;
  }

  .ag-header-cell-label {
    justify-content: center;
  }

  .ag-row {
    background-color: ${theme.colors.base.white};
    border-top: 1px solid ${theme.colors.gray[900]}1A !important;
    border-bottom: none !important;

    & > div.ag-cell {
      &:first-child {
        padding-left: 7px;

        ${media.mediumS`
          padding-left: 0;
        `}
      }
    }

    & > div {
      &:last-child {
        border-right: none;
      }
    }

    &.ag-row-first {
      border-top: none !important;
      border-bottom: none !important;
      background-color: transparent;
    }

    &.ag-row-last {
      border-bottom: 1px solid ${theme.colors.gray[200]} !important;
    }
  }

  .ag-row-header {
    ${media.mediumS`
      border-left: none;
    `}
  }

  .ag-row-selected {
    z-index: 999999999;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-right: 1px solid ${theme.colors.gray[900]}1A;
    padding: 0 6px;

    &:focus,
    &::-moz-focus-inner {
      background-color: ${theme.colors.blue[50]};
    }
    &:has(input) {
      border: 1px solid ${theme.colors.blue[400]};
    }

    &.custom-class-price-cell {
      //강제 input 포커스가 잡히는 동작이 있기 때문에 가격셀은 항상 overflow: visible
      //view 모드에서는 wrapper에서 따로 hidden을 처리하기 때문에 넘칠 걱정 없음
      overflow: visible;
    }

    ${media.mediumS`
      &:last-child {
        border-right: 1px solid ${theme.colors.gray[900]}1A !important;;
      }
    `}
  }

  .ag-cell-focus {
    overflow: visible;
  }

  .ag-cell-edit-wrapper {
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 100% !important;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${theme.colors.gray[300]};
    border: 3px solid transparent;
    min-height: 50px;
  }

  /* 그리드 공통 모바일 style */
  ${media.mediumS`
    .ag-center-cols-viewport {
      overflow-x: auto;
    }

    .ag-body {
      width: initial;
    }

    .ag-header,
    .ag-header-viewport,
    .ag-header-cell-comp-wrapper,
    .ag-header-cell {
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background-color: ${theme.colors.gray[300]};
      border: 3px solid transparent;
      min-height: 50px;
    }
    
    .ag-row {
      &::after {
        display: none;
      }
    }

    .ag-pinned-left-cols-container {
      .ag-row {
        box-shadow: 10px 0 15px -8px rgba(0,0,0,0.1);
        z-index: 1;

        ${media.mediumS`
          &.ag-row-focus {
            z-index: 101;
          }
          &.ag-row-selected {
            z-index: 101;
          }
        `}
      }
    }

    .ag-pinned-left-header {
      border-top: 1px solid ${theme.colors.gray[900]}1A;
      border-left: 1px solid ${theme.colors.gray[900]}1A;
      border-top-left-radius: 12px;
      border-right: none;
      .ag-header-row-column {
        border-top: none;
        border-right: none;
        border-bottom: none;
      }
      .ag-header-cell {
        &::after {
          display: none;
        }
      }
      div {
        border-right: none;
        outline: none;
      }
    }
    
  `}
`

export const GridWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledGrid = styled(AgGridReact)`
  .custom-display-address-cell {
    display: none !important;
  }

  .custom-cell-value-multiline {
    display: flex;
    align-items: center;
    span {
      font-weight: 400;
      line-height: 100%;
      font-size: 12px;
    }
  }

  .custom-cell-overflow-visible {
    overflow: visible;
  }

  .horizontal-gradient {
    border-left: none;
    background: linear-gradient(
      to right,
      ${theme.colors.blue[50]},
      ${theme.colors.blue[200]}
    );
  }

  .ag-row {
    &.custom-cell-prepend-label-color {
      &::after {
        content: '';
        width: 6px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      &-cyan {
        &::after {
          background-color: ${noteSheetColor['cyan'].line};
        }
      }
      &-purple {
        &::after {
          background-color: ${noteSheetColor['purple'].line};
        }
      }
      &-pink {
        &::after {
          background-color: ${noteSheetColor['pink'].line};
        }
      }
      &-red {
        &::after {
          background-color: ${noteSheetColor['red'].line};
        }
      }
      &-orange {
        &::after {
          background-color: ${noteSheetColor['orange'].line};
        }
      }
      &-blue {
        &::after {
          background-color: ${noteSheetColor['blue'].line};
        }
      }
      &-green {
        &::after {
          background-color: ${noteSheetColor['green'].line};
        }
      }
      &-gray {
        &::after {
          background-color: ${noteSheetColor['gray'].line};
        }
      }
      &-disabled {
        &::after {
          background-color: ${theme.colors.gray[400]};
        }
      }
    }
  }

  ${media.mediumS`
    
    .custom-cell-prepend-label-color {
      border-radius: 0;
      overflow: visible;
      
      &::after {
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        height: calc(100% + 2px);
        border-left-width: 7px;
        border-left-style: solid;
      
      }
      
      &-cyan {
        &::after {
          border-left-color: ${noteSheetColor['cyan'].line};
        }
      }
      &-purple {
        &::after {
          border-left-color: ${noteSheetColor['purple'].line};
        }
      }
      &-pink {
        &::after {
          border-left-color: ${noteSheetColor['pink'].line};
        }
      }
      &-red {
        &::after {
          border-left-color: ${noteSheetColor['red'].line};
        }
      }
      &-orange {
        &::after {
          border-left-color: ${noteSheetColor['orange'].line};
        }
      }
      &-blue {
        &::after {
          border-left-color: ${noteSheetColor['blue'].line};
        }
      }
      &-green {
        &::after {
          border-left-color: ${noteSheetColor['green'].line};
        }
      }
      &-gray {
        &::after {
          border-left-color: ${noteSheetColor['gray'].line};
        }
      }
      &-disabled {
        &::after {
          border-left-color: ${theme.colors.gray[400]};
        }
      }
    }
  `}
`
export const ActiveCellStyle = css`
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-color: ${theme.colors.base.white} !important;
  border: 1px solid ${theme.colors.blue[500]};
  transform: translateX(-2px) translateY(-2px);
  /* box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.05),
    0px 8px 24px rgba(17, 17, 26, 0.05),
    0px 16px 56px rgba(17, 17, 26, 0.05); */
  box-shadow: rgba(13, 38, 76, 0.19) 0px 7px 20px;
`

export const StyledSelect = styled(Select)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;

  ${ActiveCellStyle}

  .select__control {
    height: 100%;
    min-height: 100%;
    border-radius: 0;
    padding: 0;
    border-color: transparent;

    &:hover {
      border-color: transparent;
    }
    .select__indicators {
      height: 100%;
    }

    .select__input-container {
      height: 100%;
      line-height: 12px;
      padding: 0;
      margin: 0;
    }

    .select__placeholder {
      box-shadow: none !important;
      border-color: transparent;
      padding: 0;
    }

    .select__value-container {
      height: 100%;
      box-shadow: none !important;
      border-color: transparent;
      padding: 0;
    }

    .select__value {
      line-height: 12px !important;

      .select__value-label {
        line-height: 12px;
      }
    }

    .select__input {
      height: 100%;

      &:focus,
      &:focus-within {
        outline: none;
      }
    }
  }

  .select__indicator {
    padding: 0;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    margin-top: 1px;
    min-width: calc(100% + 2px);
    width: fit-content;
    transform: translateX(-1px) translateY(1px);
    border: none;
    outline: none;
    box-shadow: none;
    top: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
  }

  .select__menu-list {
    outline: none;
    border-color: ${theme.colors.noteSheet.dropdown.border} !important;
    border-radius: ${theme.colors.noteSheet.dropdown.radius}px;
    box-shadow: ${theme.shadow['card-2']};
    padding: ${theme.colors.noteSheet.dropdown.padding}px;
    max-height: 200px;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .select__option {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      height: 36px;
      color: ${theme.colors.gray[900]};
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
      border-radius: ${theme.colors.noteSheet.dropdown.radius}px;
      cursor: pointer;
      padding: 8px 10px;
    }
    .select__option--is-focused {
      background-color: ${theme.colors.noteSheet.dropdown.hover};
    }
    .select__option--is-selected {
      background-color: ${theme.colors.noteSheet.dropdown.primary};
    }
  }
`

export const StyledSelectView = styled.div`
  ${CustomSingleCellStyle}

  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
  width: 100%;
  height: 100%;

  ${({ threestepstate }) =>
    threestepstate == true &&
    `
      padding:0;
      border-radius:10px;
      border: 5px solid transparent;
      background-image: linear-gradient(#fff, #fff),
      linear-gradient(to right, #00d8f8, #5a87ff);
      background-origin: border-box;
      background-clip: content-box, border-box;
    `}
`
