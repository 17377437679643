import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import theme from 'lib/styles/theme'

const FolderItem = ({
  item,
  selectedFolder,
  setSelectedFolder,
  isAll,
}) => {
  const [active, setActive] = useState(false)

  // 선택된 폴더 활성화 처리
  useEffect(() => {
    if (selectedFolder?.folder_srl !== item?.folder_srl) {
      setActive(false)
    } else {
      setActive(true)
    }
    return () => setActive(false)
  }, [selectedFolder])

  // 선택된 폴더 세팅
  const onClickFolder = (item) => {
    setSelectedFolder(item)
  }

  return isAll ? (
    <FolderItemWrap onClick={() => onClickFolder(item)} active={active}>
      <div className="folder-item__icon">
        <i className="fas fa-folders" />
      </div>
      <div className="folder-item__text">전체</div>
    </FolderItemWrap>
  ) : (
    <FolderItemWrap onClick={() => onClickFolder(item)} active={active}>
      <div className="folder-item__icon">
        {item?.icon ? (
          <i className={'far fa-' + item.icon} />
        ) : (
          <i className="far fa-folder" />
        )}
      </div>

      <div className="folder-item__text">{item.name}</div>
    </FolderItemWrap>
  )
}

const FolderItemWrap = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: ${theme.colors.gray[700]};
  font-size: 0.75rem;
  text-align: center;
  line-height: 1;

  .folder-item__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 44px;
    height: 44px;

    color: ${theme.colors.gray[600]};
    background-color: ${theme.colors.gray[100]};
    /* border-radius: ${theme.borderRadius[1]}; */
    border-radius: 100%;

    transition: 0.2s ease;

    ${({ active }) =>
      active &&
      `
      color: ${theme.colors.base.white};
      background-color: ${theme.colors.primary[400]};
    `}

    i {
      font-size: 1rem;
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .folder-item__text {
    margin-top: 8px;
    max-width: 70px;
    padding: 0 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover {
    color: ${theme.colors.gray[800]};

    .folder-item__icon {
      background-color: ${theme.colors.gray[200]};

      ${({ active }) =>
        active &&
        `
        color: ${theme.colors.base.white};
        background-color: ${theme.colors.primary[400]};
      `}
    }
  }
`

export default FolderItem
