import { Block, Button, Title } from './SummaryBannerHasBuilding'
import Img1Src from 'assets/images/summary-banner-1.png'
import { getFullUrl } from 'utils'

const SummaryBannerNotHasBuilding = () => {
  return (
    <Block>
      <Title>
        건축 전문가를 <br />
        찾으시나요?
      </Title>
      <Button href={getFullUrl('/folio')}>
        폴리오에서 건축 전문가 찾기
        <i className="far fa-angle-double-right"></i>
      </Button>
      <img src={Img1Src} />
    </Block>
  )
}

export default SummaryBannerNotHasBuilding
