import { useDispatch, useSelector } from 'react-redux'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import SideMenuProfileDefault from './SideMenuProfileDefault'
import SideMenuProfile from './SideMenuProfile'
import { setSidemenuVisible } from 'modules/layout'
import SideHeaderMenu from './SideHeaderMenu'
import SideMenuLine from './SideMenuLine'
import SideTabMenu from './SideTabMenu'
import SideCustomerServiceMenu from './SideCustomerServiceMenu'
import { useState } from 'react'
import SignupSelectType from 'components/login/SignupSelectType'

const SideProfileMenu = () => {
  const root = document.body
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const visible = useSelector((state) => state.layout.sidemenuVisible)
  const [isSignupVisible, setIsSignupVisible] = useState(false)
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(setSidemenuVisible(false))
  }

  return ReactDOM.createPortal(
    <div>
      <CSSTransition in={visible} timeout={10} unmountOnExit>
        {(state) => (
          <StyledDrawerWrap state={state}>
            <StyledDrawer state={state}>
              <SideHeaderMenu onClose={onClose} />
              {loginInfo?.member_srl ? (
                <SideMenuProfile onClose={onClose} />
              ) : (
                <SideMenuProfileDefault
                  onClose={onClose}
                  setIsSignupVisible={setIsSignupVisible}
                />
              )}
              <SideMenuLine />
              <SideTabMenu onClose={onClose} />
              <SideMenuLine />
              <SideCustomerServiceMenu />
              {/* <SideMenuNav onClose={onClose} /> */}
              {/* <SideMenuSubMenu /> */}
            </StyledDrawer>
            <StyledDrawerBg onClick={onClose} />
            <SignupSelectType
              visible={isSignupVisible}
              onCancel={() => setIsSignupVisible(false)}
            />
          </StyledDrawerWrap>
        )}
      </CSSTransition>
    </div>,
    root,
  )
}

const StyledDrawerWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.5);

  transition: 0.3s ease;

  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`

const StyledDrawerBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`

const StyledDrawer = styled.div`
  padding-bottom: 140px;
  width: 375px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 343px;
  min-height: 100%;

  background-color: white;
  transition: 0.3s ease;

  transform: translateX(
    ${({ state }) => (state === 'entered' ? '0%' : '100%')}
  );
`

export default SideProfileMenu
