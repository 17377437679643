import useFormatArea from 'lib/hooks/useFormatArea'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 건물 정보 - 면적 정보
 */
const SummaryBuildingLandInfo = () => {
  const { building } = useSelector((state) => state.summary.summaryInfo)

  // 건축면적 합계 - 평/제곱미터 토글 및 포맷 적용
  const buildingarea = useFormatArea(
    building?.result?.reduce(
      (acc, currentVal) => acc + parseFloat(currentVal?.building_area),
      0,
    ),
  )
  // 건폐율
  const coverageRatio =
    building?.result?.reduce(
      (acc, currentVal) => acc + parseFloat(currentVal?.coverage_ratio),
      0,
    ) * 100

  // 연면적 합계 - 평/제곱미터 토글 및 포맷 적용
  const totalFloorArea = useFormatArea(
    building?.result?.reduce(
      (acc, currentVal) =>
        acc + parseFloat(currentVal?.total_floor_area),
      0,
    ),
  )
  // 연면적
  const floorAreaRatio = building?.result?.reduce(
    (acc, currentVal) => acc + parseFloat(currentVal?.floor_area_ratio),
    0,
  )
  return (
    <>
      {building.result[0] && building.apt?.length === 0 && (
        <Content>
          <ContentItem>
            <ContentItemLabel>건축면적 (건폐율)</ContentItemLabel>
            <ContentItemValue>
              {buildingarea && buildingarea}
              {coverageRatio > 0 &&
                '(' + Math.floor(coverageRatio) / 100 + '%)'}
              {!buildingarea && coverageRatio <= 0 && <>&ndash;</>}
            </ContentItemValue>
          </ContentItem>
          <ContentItem>
            <ContentItemLabel>연면적 (용적률)</ContentItemLabel>
            <ContentItemValue>
              {totalFloorArea && totalFloorArea}
              {floorAreaRatio > 0 &&
                '(' + Math.floor(floorAreaRatio * 100) / 100 + '%)'}
              {!totalFloorArea && floorAreaRatio <= 0 && <>&ndash;</>}
            </ContentItemValue>
          </ContentItem>
        </Content>
      )}
    </>
  )
}

const Content = styled.div`
  display: flex;
`

const ContentItem = styled.div`
  max-width: 50%;
  flex-basis: 50%;

  &:last-child {
    padding-left: 0.75rem;
  }
`

const ContentItemLabel = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 2px;
`

const ContentItemValue = styled.div`
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.8;
`

export default SummaryBuildingLandInfo
