import { Tab } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import SummaryTabNotApt from './SummaryTabNotApt'
import { useSelector } from 'react-redux'
import SummaryTabApt from './SummaryTabApt'
import { useContext } from 'react'
import BaseContext from 'contexts/baseContext'
import useIsMobile from 'lib/hooks/useIsMobile'

const SummaryTab = ({ aptTabs, basicTabs, activeIdx }) => {
  const {
    state: { isFold },
  } = useContext(BaseContext)
  const isMobile = useIsMobile()

  const isApt = useSelector((state) => state.summary.isApt)

  if (isFold && !isMobile) return null

  return (
    <Wrapper>
      <Tab size="medium" noContainer>
        {isApt ? (
          <SummaryTabApt tabs={aptTabs} activeIdx={activeIdx} />
        ) : (
          <SummaryTabNotApt tabs={basicTabs} activeIdx={activeIdx} />
        )}
      </Tab>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 4px;
  background-color: ${theme.colors.base.white};

  ${media.mediumS`
    position: sticky;
    top: 30px;
    z-index: 2;
  `}
`

export default SummaryTab
