import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Divider } from 'antd'
import theme, { media } from 'lib/styles/theme'
import { FormProvider } from 'react-hook-form'
import { Button } from 'components/design'
import NoteAccountAddress from './NoteAccountAddress'
import NoteAccountRealtorNumber from './NoteAccountRealtorNumber'
import NoteAccountCertificate from './NoteAccountCertificate'
import NoteAccountProfile from './NoteAccountProfile'
import NoteAccountUserName from './NoteAccountUserName'
import NoteAccountAddressDetail from './NoteAccountAddressDetail'
import NoteAccountCompanyName from './NoteAccountCompanyName'
import NoteAccountRealtorPosition from './NoteAccountRealtorPosition'
import NoteAccountCompanyNumber from './NoteAccountCompanyNumber'
import NoteAccountContainer from 'containers/notev2/NoteAccountContainer'
import NoteAccountCompanyCeo from './NoteAccountCompanyCeo'
import NoteAccountRealtorCompanyPhone from './NoteAccountRealtorCompanyPhone'

/**
 * 계정관리 내 정보 폼
 * @returns
 */
const NoteAccountForm = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  return (
    <NoteAccountContainer>
      {({
        isValidRealtorNumber,
        searchedAddrCode,
        realtorMessage,
        setIsValidRealtorNumber,
        setSearchedAddrCode,
        setRealtorMessage,
        sigungu_id,
        onClickMyPage,
        onSubmit,
        methods,
        register,
        loadingSpinner,
      }) => {
        return (
          <FormProvider {...methods}>
            <form
              onSubmit={onSubmit}
              encType="multipart/form-data"
              method="POST"
            >
              <AccountItemRow>
                <AccountLabel>프로필 사진</AccountLabel>
                <NoteAccountProfile />
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>이름</AccountLabel>
                <NoteAccountUserName />
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>전화번호</AccountLabel>
                <span>{loginInfo?.phone_number}</span>
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>상호</AccountLabel>
                <NoteAccountCompanyName disabled />
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>대표자 성명</AccountLabel>
                <NoteAccountCompanyCeo disabled />
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>사업자등록번호</AccountLabel>
                <NoteAccountCompanyNumber disabled />
              </AccountItemRow>
              <AccountItemRow flexstart>
                <AccountLabel>회사 소재지</AccountLabel>
                <NoteAccountAddress
                  disabled
                  setSearchedAddrCode={setSearchedAddrCode}
                  setRealtorMessage={setRealtorMessage}
                  setIsValidRealtorNumber={setIsValidRealtorNumber}
                />
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>상세주소</AccountLabel>
                <NoteAccountAddressDetail disabled />
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>직위</AccountLabel>
                <NoteAccountRealtorPosition disabled />
              </AccountItemRow>
              <AccountItemRow flexstart>
                <AccountLabel>중개업등록번호</AccountLabel>
                <NoteAccountRealtorNumber
                  disabled
                  isValidRealtorNumber={isValidRealtorNumber}
                  setIsValidRealtorNumber={setIsValidRealtorNumber}
                  realtorMessage={realtorMessage}
                  setRealtorMessage={setRealtorMessage}
                  searchedAddrCode={searchedAddrCode}
                />
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>사무실 전화번호</AccountLabel>
                <NoteAccountRealtorCompanyPhone disabled />
              </AccountItemRow>
              <AccountItemRow>
                <AccountLabel>공제증서</AccountLabel>
                <NoteAccountCertificate />
              </AccountItemRow>
              <Divider style={{ marginTop: '36px' }}></Divider>
              <SaveButtonWrapper>
                <Button type="submit" color="blue">
                  본인인증
                </Button>
              </SaveButtonWrapper>
            </form>
            {loadingSpinner}
          </FormProvider>
        )
      }}
    </NoteAccountContainer>
  )
}

export const AccountLabel = styled.label`
  display: flex;
  align-items: center;
  min-width: 160px;
  font-size: 16px;
  font-weight: 500;

  ${({ required }) =>
    required == 'Y' &&
    `
    &::after {
      content: "*";
      margin-left: 4px;
      color: ${theme.colors.tint.danger};
    }
  `}
`

export const AccountItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${({ flexstart }) =>
    flexstart &&
    `
    align-items: flex-start;`}

  ${media.mediumS`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const AccountInput = styled.input`
  min-width: 253px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid ${theme.colors.gray[200]};
  outline: none;
  background-color: ${theme.colors.base.white};
  padding: 0 8px;
  font-size: 14px;

  ${({ expand }) => expand && `width: 100%;`};
  ${({ small }) => small == 'Y' && `min-width: unset;`};

  ${({ tel }) =>
    tel == 'Y' &&
    `
        flex: 1;
    `};
  &:read-only {
    background-color: ${theme.colors.gray[100]};
  }
`

const SaveButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 24px;
`

export default NoteAccountForm
