import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getLayerHousingComplexDetail } from 'lib/api/layerApi'
import useAxios from 'lib/hooks/useAxios'
import MapContext from 'contexts/map'
import SummaryHcCharacter from 'components/summary/SummaryHousingComplex/SummaryHcCharacter'
import SummaryHousingCompleteTopHeader from 'components/summary/header/SummaryHcTopHeader'
import SummaryHcProjects from 'components/summary/SummaryHousingComplex/SummaryHcProjects'
import SummaryHcMasters from 'components/summary/SummaryHousingComplex/SummaryHcMasters'
import SummaryHousingCompleteHeader from 'components/summary/header/SummaryHcHeader'
import SummaryHcLegal from 'components/summary/SummaryHousingComplex/SummaryHcLegal'
import SummaryHcPrice from 'components/summary/SummaryHousingComplex/SummaryHcPrice'
import SummaryHcLr from 'components/summary/SummaryHousingComplex/SummaryHcLr'
import Container from 'components/base/Container/Container'
import SEOHead from 'components/common/SEOHead'
import { setCenterPoint, setSelectedName } from 'modules/housingComplex'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'

const SummaryHousingComplex = ({ match }) => {
  const { kakao } = window
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const { state, actions } = useContext(MapContext)
  const { map } = state
  const danjiId = match.params.danjiId

  const history = useHistory()

  const engReg = /[^a-z|A-Z]/g
  const korReg = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g

  const [onRequest, loading, data, , ,] = useAxios(
    getLayerHousingComplexDetail,
  )

  useEffect(() => {
    if (!danjiId) return
    if (Object.keys(map).length === 0) return

    actions.setLayerState((prev) => {
      return {
        ...prev,
        housingComplexActive: true,
        realPriceActive: false,
      }
    })

    onRequest({ id: danjiId })
  }, [map, danjiId])

  useEffect(() => {
    if (!data || !data.result) return

    if (!data.result?.danji_code) {
      history.push('/')
      notification.info({
        message: '알림',
        description: '잘못된 요청입니다.',
      })
      return
    }

    const { centerpoint, danji_name } = data.result

    const locPosition = new kakao.maps.LatLng(
      centerpoint.coordinates[0],
      centerpoint.coordinates[1],
    )
    map.panTo(locPosition)

    dispatch(setCenterPoint(centerpoint))

    const daji_name = `${danji_name.replace(
      korReg,
      '',
    )} 전원주택단지 ${danji_name.replace(engReg, '')}`

    dispatch(setSelectedName(daji_name))
  }, [data])

  return (
    <>
      <SEOHead housingComplex={data?.result} />
      <Container
        swipeable
        onChange={(e) => setIsOpen(e)}
        header={
          <SummaryHousingCompleteHeader
            loading={loading}
            data={data?.result}
          />
        }
        topSlot={<SummaryHousingCompleteTopHeader isOpen={isOpen} />}
      >
        <SummaryHcPrice data={data?.result} id={danjiId} />
        <SummaryHcCharacter data={data?.result} loading={loading} />
        <SummaryHcLegal data={data?.result} />
        <SummaryHcLr data={data?.result} />
        <SummaryHcMasters data={data?.result?.masters} />
        <SummaryHcProjects data={data?.result?.projects} />
      </Container>
    </>
  )
}

export default SummaryHousingComplex
