import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { assign, map, mapKeys } from 'lodash'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기타관리비 내용
 */
const NoteAdColumnFeeEtc = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext()

  const fixedFeeDetails =
    watch('administrationCostInfo.fixedFeeDetails') || []

  const onBlurInput = (e) => {
    const etcData = fixedFeeDetails?.find(
      ({ detailCodeType }) => detailCodeType == 99,
    )

    const originData = etcData
      ? etcData
      : {
          detailCodeType: '99',
        }

    setValue(
      'administrationCostInfo.customEtcInputContent',
      e.target.value,
    )

    onUpdate(
      'administrationCostInfo.fixedFeeDetails',
      setSpecificObjectValues(
        fixedFeeDetails,
        Object.assign({}, originData, {
          etcInputContent: e.target.value,
        }),
      ).filter((item) => item),
    )
  }

  /**
   * object array merge
   * @param {*} origin 원래 데이터
   * @param {*} newData 업데이트할 데이터
   * @returns
   */
  const setSpecificObjectValues = (origin, newData) => {
    return map(
      assign(
        mapKeys(origin, (v) => v.detailCodeType),
        mapKeys([newData], (v) => v.detailCodeType),
      ),
    )
  }

  return (
    <ColumnSet>
      <ColumnTitle
        required={
          fixedFeeDetails.length > 0 &&
          fixedFeeDetails?.find(
            ({ detailCodeType }) => detailCodeType == 99,
          )?.amount > 0 &&
          fixedFeeDetails?.find(
            ({ detailCodeType }) => detailCodeType == 99,
          )?.amountCodeType == '01'
        }
      >
        기타관리비 내용
      </ColumnTitle>
      <ColumnVal>
        <InputText
          placeholder="공백없이 최대 50자 이내로 작성해주세요."
          type="text"
          onBlur={onBlurInput}
          defaultValue={
            (fixedFeeDetails.length > 0 &&
              fixedFeeDetails?.find(
                ({ detailCodeType }) => detailCodeType == '99',
              )?.etcInputContent) ||
            ''
          }
        />
        <ValidateWarn>
          {
            errors?.administrationCostInfo?.fixedFeeDetails
              ?.etcInputContent?.message
          }
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeeEtc
