import styled from 'styled-components'
import { message } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ReactComponent as NoteIcon } from 'assets/icon/note/ad/note-srl.svg'
import { ReactComponent as NaverIcon } from 'assets/icon/note/ad/naver-logo-simple.svg'
import { Tooltip } from 'react-tooltip'
import theme from 'lib/styles/theme'
import { useDispatch } from 'react-redux'
import { setMapMode } from 'modules/map'

/**
 * 광고관리 그리드
 * 노트번호
 */
const CustomAdsSrlCell = ({ node }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const activeLink =
    node.data.status == '광고중' || node.data.status == '종료예정'

  /**
   * 주택 부동산으로
   * @param {*} note_srl
   */
  const onClickBds = () => {
    if (!activeLink) return

    //  dispatch(setMapMode('bds'))
    history.push(`/bds/${node.data.ad_srl}`)
  }

  /**
   * 네이버로
   * @param {*} note_srl
   */
  const onClickNaver = () => {
    alert('네이버로 이동 TODO')
    //
  }

  const nuid = node.data?.nuid

  if (node.data.ad_srl == '신규') return <></>

  return (
    <Wrapper tabIndex={-1}>
      <NoteNumber onClick={onClickBds} active={activeLink}>
        <NoteIcon width={10} height={10} />
        {node.data.ad_srl}
      </NoteNumber>
      <NoteNumber type="naver">
        {nuid && (
          <>
            <NaverIcon
              onClick={onClickNaver}
              fill="#03BC63"
              width={10}
              height={10}
            />
            <div className="adSrlValue">{nuid}</div>
          </>
        )}
      </NoteNumber>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% + 12px);
  height: 100%;
  margin: 0 -6px;
  opacity: 1;
  position: relative;
  z-index: 1;
  font-size: 12px;
  ${({ isVisible }) => isVisible && `opacity: 1;`}
`

const NoteNumber = styled.div`
  display: flex;
  align-items: center;
  color: #9355e2;
  text-decoration: underline;
  text-underline-position: bottom;
  line-height: 100%;
  width: 100%;
  height: 50%;
  padding: 0 6px;

  &:first-child {
    border-bottom: 1px solid ${theme.colors.gray[200]};
  }

  ${({ type }) =>
    type == 'naver' &&
    `
      color: #00a32a;
      div {
        text-align: left;
      }
  `};

  ${({ active }) =>
    active &&
    `
      cursor: pointer;
  `};

  svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    flex-shrink: 0;
  }
`
export default CustomAdsSrlCell
