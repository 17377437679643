import NoteDonghoBlocksContainer from 'containers/notev2/NoteDonghoBlocksContainer'
import React, { Suspense } from 'react'
import NoteDonghoDrawArea from './NoteDonghoDrawArea'
import { Empty } from 'components/design'

/**
 * 동호관리 메인
 * 범례, 동 블럭
 */
const NoteDonghoContent = () => {
  return (
    <NoteDonghoBlocksContainer>
      {({
        dongList,
        groundData,
        undergroundData,
        sectionSizes,
        activeTab,
        allDataReady,
        matchHoColors,
        selectedDanji,
        onMouseOver,
        onMouseOut,
        legend,
        palette,
        anotherLegend,
      }) => {
        return (
          <>
            {dongList && dongList.length == 0 && (
              <Empty>데이터가 없습니다</Empty>
            )}
            {allDataReady && (
              <NoteDonghoDrawArea
                anotherLegend={anotherLegend}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                matchHoColors={matchHoColors}
                dongList={dongList}
                groundData={groundData}
                undergroundData={undergroundData}
                sectionSizes={sectionSizes}
                activeTab={activeTab}
                selectedDanji={selectedDanji}
                legend={legend}
                palette={palette}
              />
            )}
          </>
        )
      }}
    </NoteDonghoBlocksContainer>
  )
}

export default React.memo(NoteDonghoContent)
