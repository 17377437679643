import { Empty, List } from 'components/design'
import NotificationListItem from './NotificationListItem'
import { CardBody } from 'components/design'

const NotificationList = ({ notifications }) => {
  if (notifications.length === 0) {
    return (
      <CardBody>
        <Empty>알림이 없습니다.</Empty>
      </CardBody>
    )
  }

  return (
    <List>
      {notifications.map((item, i) => (
        <NotificationListItem item={item} key={i} />
      ))}
    </List>
  )
}

export default NotificationList
