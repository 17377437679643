import { useContext, useEffect, useState } from 'react'
import MapContext from 'contexts/map'

import MapLayerIcon from './MapLayerIcon'
import { getSchoolLayer } from 'lib/api/layerApi'
import useAxios from 'lib/hooks/useAxios'

/**
 * 지도 레이어 학교, 학군
 */
const MapLayerSchool = () => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const [onRequest, , data, , ,] = useAxios(getSchoolLayer)
  const { layerState, viewState, map } = state
  // 폴리곤 배열 선언
  const [polygons, setPolygons] = useState([])
  // 학군 마커 배열 선언
  const [markers, setMarkers] = useState([])
  // 학군이름 배열 선언
  const [dongNames, setDongNames] = useState([])

  useEffect(() => {
    if (Object.keys(viewState).length === 0) {
      return
    }

    if (viewState.level > 8) return

    if (layerState.school) {
      // 요청 파라미터 세팅
      const payload = {
        swlat: viewState.swLatlng.getLat(),
        swlng: viewState.swLatlng.getLng(),
        nelat: viewState.neLatlng.getLat(),
        nelng: viewState.neLatlng.getLng(),
        level: viewState.level,
      }
      onRequest(payload, null, false)
    } else {
      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
      // 기존 마커 삭제
      markers.map((marker) => {
        marker.setMap(null)
      })
      // 기존 학군이름 오버레이 삭제
      dongNames.map((name) => {
        name.setMap(null)
      })
    }
  }, [layerState, viewState, map])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result || data.result?.length === 0) {
      // 기존 폴리곤 삭제
      polygons?.map((polygon) => {
        polygon.setMap(null)
      })
      return
    }

    const result = data.result
    let tempMarkers = [] // 오버레이 배열

    result.map((school) => {
      // 학교 오버레이 생성
      const overlay = new kakao.maps.CustomOverlay({
        map: map, // 커스텀오버레이를 표시할 지도입니다
        content: `<div class="school">
					<i class="fas fa-school"></i>
					<div class="school_info" style="z-index: 11" onClick>
						<div class="title">
							<span class="kind">${school.established_kind}</span>
							<span class="name">${school.school_name}</span>
						</div>

						<div class="meta">
							<span class="label">학급당 학생수</span>  
							<span class="value">${Math.round(
                school.tot_student / school.tot_class,
              )}명</span>
						</div>
						
						<div class="meta">
							<span class="label">교사당 학생수</span>  
							<span class="value">${Math.round(
                school.tot_student / school.tot_teacher,
              )}명</span>
						</div>
					</div>
				</div>`, // 커스텀오버레이에 표시할 내용입니다
        clickable: true,
        xAnchor: 0,
        yAnchor: 0,
        // 커스텀오버레이를 표시할 위치입니다. 위치는 다각형의 마지막 좌표로 설정합니다
        position: new kakao.maps.LatLng(
          school.centerpoint.coordinates[0],
          school.centerpoint.coordinates[1],
        ),
      })
      // 실거래가/경매 마커보다 위에 쌓이도록
      overlay.setZIndex(10)

      // 하나씩 생성된 오버레이를 배열에 넣기
      tempMarkers.push(overlay)
    })
    setMarkers(tempMarkers)

    // 초등학교 학군 폴리곤
    if (data.district) {
      let tempPolygons = [] // 폴리곤 배열
      let tempDongNames = [] // 학군이름 배열
      const district = data.district

      district.map((school) => {
        // 학군 폴리곤 생성
        const shpaePoints = school.shape.coordinates
        // multipolygon 세팅
        shpaePoints.map((parts) => {
          const polygonPath = parts.map((part) => {
            return part.map((point) => {
              return new kakao.maps.LatLng(point[0], point[1])
            })
          })
          // 지도에 표시할 다각형을 생성합니다
          const polygon = new kakao.maps.Polygon({
            path: polygonPath, // 그려질 다각형의 좌표 배열입니다
            strokeWeight: 3, // 선의 두께입니다
            strokeColor: '#47d15e', // 선의 색깔입니다
            strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
            fillColor: '#47d15e', // 채우기 색깔입니다
            fillOpacity: 0.2, // 채우기 불투명도 입니다
            zIndex: 1,
          })

          // 하나씩 생성된 폴리곤을 배열에 넣기
          tempPolygons.push(polygon)
        })

        // 학군이름을 학군 폴리곤 위에 표시합니다
        const dongNameOverlay = new kakao.maps.CustomOverlay({
          map: map, // 커스텀오버레이를 표시할 지도입니다
          content: `<div class="dongOverlay">${school.district_name}</div>`, // 커스텀오버레이에 표시할 내용입니다
          xAnchor: 0,
          yAnchor: 0,
          // 커스텀오버레이를 표시할 위치입니다. 위치는 다각형의 마지막 좌표로 설정합니다
          position: new kakao.maps.LatLng(
            school.latitude,
            school.longitude,
          ),
        })
        tempDongNames.push(dongNameOverlay)
      })
      setPolygons(tempPolygons)
      setDongNames(tempDongNames)
    }
  }, [data])

  // 마커 세팅 및 초기화
  useEffect(() => {
    if (markers.length === 0) return

    markers.map((marker) => {
      marker.setMap(map)
    })

    return () => {
      if (markers.length === 0) return

      // 기존 마커 삭제
      markers.map((marker) => {
        marker.setMap(null)
      })
    }
  }, [markers])

  // 폴리곤 세팅 및 초기화
  useEffect(() => {
    if (polygons.length === 0) return

    polygons.map((polygon) => {
      polygon.setMap(map)
    })

    return () => {
      if (polygons.length === 0) return

      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [polygons])

  // 학군이름 오버레이 세팅 및 초기화
  useEffect(() => {
    if (dongNames.length === 0) return

    dongNames.map((dongName) => {
      dongName.setMap(map)
    })

    return () => {
      if (dongNames.length === 0) return

      // 기존 동이름 오버레이 삭제
      dongNames.map((dongName) => {
        dongName.setMap(null)
      })
    }
  }, [dongNames])

  return <MapLayerIcon type="school" title="학교·학군" />
}

export default MapLayerSchool
