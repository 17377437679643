import theme from 'lib/styles/theme'
import styled from 'styled-components'
import MapLayerRoadView from './MapLayerRoadView'
import MapLayer from './MapLayer'

/**
 * 레이어
 */
const MapLayerGroup = () => {
  return (
    <Wrapper top={60}>
      <MapLayerRoadView />
      <MapLayer />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 8px;
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['float']};
  border-radius: 4px;
  overflow: hidden;
`

export default MapLayerGroup
