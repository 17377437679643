import MapLayerIcon from './MapLayerIcon'
import { useContext, useEffect, useState } from 'react'
import { notification } from 'antd'
import MapContext from 'contexts/map'
import { billionFormate, filterParams } from 'utils'
import useAxios from 'lib/hooks/useAxios'
import { getLayerPrice } from 'lib/api/layerApi'

/**
 * 지도 레이어
 * 공시지가
 */
const MapLayerSelectGongsi = () => {
  const { kakao } = window
  const { state, actions } = useContext(MapContext)
  const { layerState, filterState, viewState, map } = state
  const [onRequest, , data, , ,] = useAxios(getLayerPrice)
  // 폴리곤 배열 선언
  const [polygons, setPolygons] = useState([])
  const [range, setRange] = useState([])

  const legends = [
    '#7256ff1a',
    '#7256ff49',
    '#7256ff6f',
    '#7256ffa0',
    '#7256ffca',
    '#7256FF',
  ]
  const legendPips = [
    billionFormate(range[0]),
    billionFormate(range[1]),
  ]

  let max = 0,
    min = 99999999999

  useEffect(() => {
    if (Object.keys(map).length === 0) return

    layerState.priceActive &&
      map.getLevel() > 2 &&
      openNotification('지도를 줌인해 주세요!')
  }, [layerState.priceActive])

  useEffect(() => {
    if (Object.keys(viewState).length === 0) {
      return
    }

    if (layerState.priceActive) {
      if (viewState.level > 3) {
        return
      }
      const payload = {
        swlat: viewState.swLatlng.getLat(),
        swlng: viewState.swLatlng.getLng(),
        nelat: viewState.neLatlng.getLat(),
        nelng: viewState.neLatlng.getLng(),
        level: viewState.level,
        ...filterParams(filterState),
      }
      onRequest(payload, null, false)
    } else {
      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
    return () => setRange([])
  }, [
    layerState.priceActive,
    filterState.land_area,
    filterState.land_type,
    filterState.use_area,
    viewState,
    map,
  ])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result || data.result?.length === 0) {
      // 기존 폴리곤 삭제
      polygons?.map((polygon) => {
        polygon.setMap(null)
      })
      return
    }

    const addrs = data.result

    // 최대 가격
    max = Math.max(max, data.max)

    // 최소 가격
    min = Math.min(min, data.min)

    setRange([min, max])
    let range = (max - min) / 5
    let points = legends
      .map((color, i) => {
        return { price: min + range * i, color: color }
      })
      .reverse()

    // 폴리곤 배열
    let tempPolygons = []

    addrs.map((addr) => {
      // 가격별 색깔 표시
      const price = addr.price

      let color = points
        .map((element) => {
          if (price >= element.price) {
            return element.color
          } else {
            return null
          }
        })
        .filter((item) => item !== null)[0]

      const shpaePoints = addr.shape.coordinates

      const polygonPath = shpaePoints.map((part) => {
        return part.map((point) => {
          return new kakao.maps.LatLng(point[0], point[1])
        })
      })

      // 지도에 표시할 다각형을 생성합니다
      const polygon = new kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 1, // 선의 두께입니다
        strokeColor: color, // 선의 색깔입니다
        strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다
        fillColor: color, // 채우기 색깔입니다
        fillOpacity: 0.8, // 채우기 불투명도 입니다
        zIndex: 1,
      })

      // 하나씩 생성된 건물 폴리곤을 건물 폴리곤 배열에 넣기
      tempPolygons.push(polygon)
    })

    setPolygons(tempPolygons)
  }, [data])

  useEffect(() => {
    if (polygons.length === 0) return

    polygons.map((polygon) => {
      polygon.setMap(map)
    })

    return () => {
      if (polygons.length === 0) return

      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [polygons])

  const openNotification = (message) => {
    notification['info']({
      message: '알림',
      description: message,
    })
  }

  return <MapLayerIcon type="priceActive" title="공시지가" />
}

export default MapLayerSelectGongsi
