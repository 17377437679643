import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'

const NoteAdAllInOneMemberSetting = () => {
  const { isOwner } = useIsAllInOne()
  const history = useHistory()

  const onClickSettings = () => {
    history.push('/note/v2/memberMng')
  }

  if (!isOwner) return null
  return (
    <Wrapper>
      <SettingText color="darkGray" circle onClick={onClickSettings}>
        <i className="fas fa-cog"></i>
        올인원 멤버 관리
      </SettingText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 10px 0 20px;
`

const SettingText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  border-top: 1px solid ${theme.colors.gray[200]};
  border-bottom: 1px solid ${theme.colors.gray[200]};
  cursor: pointer;

  i {
    font-size: 18px;
    color: ${theme.colors.gray[600]};
  }
`

export default NoteAdAllInOneMemberSetting
