import React, { useEffect, useState } from 'react'
import theme from 'lib/styles/theme'
import NotePropertyList from './NotePropertyList'
import { ReactComponent as AllIcon } from 'assets/icon/note/filter/all.svg'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { numberWithCommas } from 'utils'

const NoteFilter = ({
  allTitle,
  onClickPanel,
  onClickItem,
  resetItem,
  propertyTarget,
  propertyFilters,
  filtersCount,
}) => {
  const { q, property_types } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )
  const [title, setTitle] = useState('모든 매물')

  useEffect(() => {
    const filterEntries = Object.entries(property_types)
    const selectedFilterEntry = filterEntries.find(
      (item) => item[1].length > 0,
    )
    if (selectedFilterEntry) {
      setTitle(selectedFilterEntry[0])
    } else {
      setTitle('모든 매물')
    }
  }, [property_types, q])

  return (
    <Wrapper>
      <PropertyListItem
        all
        active={title == '모든 매물'}
        onClick={resetItem}
      >
        <AllIcon />
        <PropertyListLabel>
          {allTitle}
          {filtersCount ? (
            <PropertyListCount active={title == '모든 매물'}>
              {numberWithCommas(filtersCount)}
            </PropertyListCount>
          ) : (
            <></>
          )}
        </PropertyListLabel>
      </PropertyListItem>
      <CollapseWrapper>
        <NotePropertyList
          onClickPanel={onClickPanel}
          onClickItem={onClickItem}
          propertyTarget={propertyTarget}
          propertyFilters={propertyFilters}
        />
      </CollapseWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
`

const CollapseWrapper = styled.div`
  .subdivision-item {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 6px;
    }
  }
`

const PropertyListCount = styled.span`
  margin-left: 8px;
  padding: 2px 4px;
  background-color: ${theme.colors.gray[300]};
  border-radius: 2px;
  font-size: 8px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[900]};

  ${({ active }) =>
    active &&
    `
      background-color: ${theme.colors.base.white};
    `}
`

export const PropertyListLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`

export const PropertyListItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  ${({ all }) =>
    all &&
    `
    height: 36px;
    margin-bottom: 10px;
    font-weight: 700;
  `}

  ${({ small }) =>
    small &&
    `
    height: 32px;
    margin-bottom: 6px;
  `}

  ${({ smallpadding }) =>
    smallpadding &&
    `
    padding-left: 10px !important;
  `}

  ${({ padding }) =>
    padding &&
    `
    padding-left: 16px !important;
  `}


  ${({ active }) =>
    active &&
    `
      padding-left: 8px;
      background-color: #cce5ff;
      border-radius: 8px;
      font-weight: 600;
  `}

  ${({ hover }) =>
    hover &&
    `
    @media (hover: hover) {
      &:hover {
        padding-left: 8px;
        background-color: #cce5ff;
        border-radius: 8px;
        font-weight: 600;
      }
    }
  `}

  ${({ ellipsis }) =>
    ellipsis &&
    `
    & > span {
      white-space: initial;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      padding-right: 10px;
    }
  `}
`

export default React.memo(NoteFilter)
