import { Route, Switch, useLocation } from 'react-router-dom'
import HomePage from './pages/HomePage'
import NotFound from 'pages/NotFound'
import Favorite from 'pages/Favorite'
import SummaryPage from 'pages/SummaryPage'
import NotePage from 'pages/jmapnote/NotePage'
import NotificationPage from 'pages/NotificationPage'
import NoteChannelInvitePage from 'pages/jmapnote/NoteChannelInvitePage'
import NoteSharePage from 'pages/jmapnote/note/NoteSharePage'
import Search from 'pages/Search'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setPreviousPath } from 'modules/home'
import SummaryHousingComplex from 'pages/SummaryHousingComplex'
import NotePageV2 from 'pages/jmapnotev2/NotePage'
import Landing from 'pages/landing/Landing'
import InviteAuth from 'pages/auth/InviteAuth'
import BdsPage from 'pages/bds/BdsPage'

const Router = () => {
  const [currentPath, setCurrentPath] = useState(null)

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname)
      dispatch(setPreviousPath(currentPath))
    }
  }, [location.pathname])

  return (
    <Switch>
      <Route
        path={['/', '/invite/:referralCode']}
        component={Landing}
        exact
      />

      <Route path={['/home', '/myNote']} component={HomePage} />
      <Route path="/inviteAuth" component={InviteAuth} extact />
      <Route path="/notfound" component={NotFound} exact />

      <Route path="/favorite" component={Favorite} />
      <Route path="/note/v2" component={NotePageV2} />
      <Route path="/note" component={NotePage} />

      <Route
        path="/housingComplex/:danjiId"
        component={SummaryHousingComplex}
        exact
      />

      <Route
        path="/share/:noteSrl/:encryptedKey"
        component={NoteSharePage}
      />
      <Route path="/notifications" component={NotificationPage} />
      <Route path="/search" component={Search} />
      <Route
        path="/noteChannelInvite/:channelSrl"
        component={NoteChannelInvitePage}
      />

      <Route path="/bds" component={BdsPage} />

      <Route
        path={['/:id/:external/:filterId?', '/:id']}
        component={SummaryPage}
      />

      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Router
