import { buildingPurposeColors } from 'utils/index'
import { Tag } from 'components/design'
import { getCustomPurpose } from 'lib/utils'
import useFormatArea from 'lib/hooks/useFormatArea'
import { MetaItem } from './SummaryHeaderMeta'

/**
 * summary 최상단 태그 - 일반
 */
const SummaryHeaderMetaBasic = ({ building, land }) => {
  const getPropertyTypeColor = () => {
    return getCustomPurpose(building)
      ? typeof buildingPurposeColors[getCustomPurpose(building)] !==
        'undefined'
        ? buildingPurposeColors[getCustomPurpose(building)]
        : null
      : null
  }

  const landArea = useFormatArea(land.land_area)

  const totalFloorArea = useFormatArea(
    building.result.reduce(
      (acc, currentVal) =>
        acc + parseFloat(currentVal.total_floor_area),
      0,
    ),
  )

  return (
    <>
      {/* 지목 */}
      <MetaItem>
        <Tag
          size="small"
          label={land.land_type}
          labelTooltip="지목"
          tooltip="대지면적"
        >
          {landArea}
        </Tag>
      </MetaItem>

      {/* 건물 타입 */}
      <MetaItem>
        <Tag
          size="small"
          color={getPropertyTypeColor()}
          label={getCustomPurpose(building) || '건물없음'}
          labelTooltip="건물 타입"
          tooltip="연면적"
        >
          {/* 연면적 */}
          {building?.result.length > 0 && totalFloorArea}
        </Tag>
      </MetaItem>
    </>
  )
}

export default SummaryHeaderMetaBasic
