import styled from 'styled-components'
import { Desc, Section, SelectItem, Title } from './BdsFilterStyle'
import { useDispatch } from 'react-redux'
import { resetContractType, setContractType } from 'modules/bds'
import { useSelector } from 'react-redux'
import { contractFilterItem } from 'lib/utils/bds'
import { isEqual, sortBy } from 'lodash'

/**
 * 주택부동산
 * 필터 모달 - 거래유형
 */
const BdsContentFilterContractType = () => {
  const dispatch = useDispatch()
  const selectedFilter = useSelector(
    (state) => state.bds.selectedFilter,
  )

  const isActiveAll = isEqual(
    sortBy(selectedFilter.contractType),
    sortBy(contractFilterItem),
  )

  /**
   * 필터 클릭
   * @param {*} val
   */
  const onClick = (val) => {
    const contractType = selectedFilter.contractType
    if (isActiveAll) {
      dispatch(setContractType([val]))
    } else {
      if (contractType.includes(val)) {
        const updateVal = contractType.filter((item) => item != val)
        dispatch(setContractType(updateVal))
      } else {
        dispatch(
          setContractType([...new Set([...contractType, ...[val]])]),
        )
      }
    }
  }

  /**
   * 전체 클릭
   */
  const onClickAll = () => {
    dispatch(resetContractType())
  }

  return (
    <Section>
      <Title>
        거래유형
        <Desc>
          {selectedFilter?.contractType.map((item, i) => {
            return (
              <span key={`contract_desc_${i}`}>
                {i != 0 && ', '}
                {item}
              </span>
            )
          })}
        </Desc>
      </Title>
      <Select>
        <SelectItem active={isActiveAll} onClick={onClickAll}>
          전체
        </SelectItem>
        {contractFilterItem.map((item, i) => {
          return (
            <SelectItem
              key={`contract_item_${i}`}
              active={
                !isActiveAll &&
                selectedFilter?.contractType?.includes(item)
              }
              onClick={() => onClick(item)}
            >
              {item}
            </SelectItem>
          )
        })}
      </Select>
    </Section>
  )
}

const Select = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export default BdsContentFilterContractType
