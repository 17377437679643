import theme, { media } from 'lib/styles/theme'
import { NumericFormat } from 'react-number-format'
import styled from 'styled-components'
import { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const InputText = (
  {
    className,
    width,
    height,
    prepend,
    append,
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    onValueChange,
    datasetKey = '',
    continuing = false,
    tail = false,
    halfwidth = false,
    angularright = false,
    angularleft = false,
    thousandSeparator = true,
    type = 'number',
    format,
    defaultValue,
    renderer,
    halfwidthWithGap,
    readOnly,
    onKeyDown,
    white,
    disabled,
    fixAppendWidth,
    id,
  },
  ref,
) => {
  const { watch } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll

  const as = () => {
    if (type == 'number') {
      return NumericFormat
    } else {
      return 'input'
    }
  }

  return (
    <Wrapper
      ref={ref}
      tabIndex={-1}
      continuing={continuing}
      tail={tail ? 1 : 0}
      halfwidth={halfwidth}
      halfwidthWithGap={halfwidthWithGap}
      angularright={angularright}
      angularleft={angularleft}
      readonly={readOnly}
      white={white ? 'Y' : 'N'}
      disabled={isDisabled || disabled}
    >
      {prepend && (
        <Prepend angularleft={angularleft}>{prepend}</Prepend>
      )}
      {renderer ? (
        renderer
      ) : (
        <SingleWrapper tabIndex={-1} tail={tail ? 1 : 0}>
          {type == 'number' ? (
            <>
              <StyledInput
                id={id}
                className={className}
                width={width}
                height={height}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                type="text"
                tail={tail ? 1 : 0}
                as={as()}
                defaultValue={defaultValue}
                data-key={datasetKey}
                inputMode="decimal"
                thousandSeparator={thousandSeparator}
                onValueChange={onValueChange}
                format={format}
                hasprepend={prepend ? 'Y' : 'N'}
                readOnly={readOnly}
                append={append ? 'Y' : 'N'}
                onKeyDown={onKeyDown}
                disabled={isDisabled || disabled}
              />
            </>
          ) : (
            <>
              {value ? (
                <StyledInput
                  className={className}
                  width={width}
                  height={height}
                  value={value}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder={placeholder}
                  type={type}
                  tail={tail ? 1 : 0}
                  data-key={datasetKey}
                  hasprepend={prepend ? 'Y' : 'N'}
                  readOnly={readOnly}
                  disabled={isDisabled || disabled}
                  append={append ? 'Y' : 'N'}
                />
              ) : (
                <StyledInput
                  className={className}
                  width={width}
                  height={height}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder={placeholder}
                  type={type}
                  tail={tail ? 1 : 0}
                  defaultValue={defaultValue}
                  data-key={datasetKey}
                  hasprepend={prepend ? 'Y' : 'N'}
                  readOnly={readOnly}
                  disabled={isDisabled || disabled}
                  append={append ? 'Y' : 'N'}
                />
              )}
            </>
          )}
          {append && (
            <Append
              fix={fixAppendWidth}
              disabled={isDisabled || disabled}
            >
              {append}
            </Append>
          )}
        </SingleWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  appearance: none;
  box-sizing: border-box;

  ${({ continuing }) =>
    continuing &&
    `
      width: 60%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `};
  ${({ angularright }) =>
    angularright &&
    `
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  `};

  ${({ angularleft }) =>
    angularleft &&
    `
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  `};

  ${({ halfwidth }) => halfwidth && `width: 50%;`};
  ${({ halfwidthWithGap }) =>
    halfwidthWithGap && `width: calc(50% - 4px);`};

  ${({ tail }) =>
    tail &&
    `
      width: 40%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    `};

  ${({ mb }) => mb && `margin-bottom: 12px;`};
  ${({ white }) =>
    white && `background-color: ${theme.colors.base.white}`};

  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${theme.colors.gray[100]};
  `}
`

const Prepend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 73px;
  height: 100%;
  background-color: ${theme.colors.gray[100]};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  ${({ angularleft }) =>
    angularleft &&
    `
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  `}

  ${media.mediumS`
    font-size: 13px;
    min-width: 64px;
  `}
`

const Append = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  color: ${theme.colors.gray[400]};
  font-weight: 400;
  white-space: nowrap;

  ${({ fix }) =>
    fix &&
    `
    text-align: right;
    width: 30px;
    padding-left: 0;
  `}

  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${theme.colors.gray[100]};
  `}
  
  ${media.mediumS`
    padding-left: 0;
    padding-right: 4px;
    font-size: 13px;
  `}
`

const SingleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 73px);
  height: calc(100% - 2px);
  position: relative;

  ${({ hasprepend }) =>
    !hasprepend &&
    `
    width: 100%;
    `}

  ${({ tail }) =>
    tail &&
    `
    width: 100%;
    `}
`

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  font-weight: 500;
  border-radius: 0;
  background-color: transparent;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border: none;
  box-shadow: none;
  color: ${theme.colors.gray[900]};

  ${({ width }) =>
    width &&
    `
    width:${width}px;
    `}

  ${({ height }) =>
    height &&
    `
    height:${height}px;
    `}

  ${({ expand }) =>
    expand &&
    `
    width: 100%;
    `}

  &::placeholder {
    font-weight: 400;
    color: ${theme.colors.gray[400]};
  }

  &:disabled {
    background-color: ${theme.colors.gray[100]};
    ${Append} {
      background-color: ${theme.colors.gray[100]};
    }
  }

  ${media.mediumS`
    width: 100%;
    padding: 0 4px 0 6px;
  `}
`

export default forwardRef(InputText)
