import theme, { media } from 'lib/styles/theme'
import { NumericFormat, PatternFormat } from 'react-number-format'
import styled from 'styled-components'
import NoteInputDate from './NoteInputDate'
import { forwardRef } from 'react'
const NoteInput = (
  {
    className,
    width,
    height,
    prepend,
    append,
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    onValueChange,
    datasetKey = '',
    continuing = false,
    tail = false,
    halfwidth = false,
    angularright = false,
    angularleft = false,
    thousandSeparator = true,
    type = 'number',
    format,
    defaultValue,
    renderer,
    selected,
    halfwidthWithGap,
    readOnly,
    onKeyDown,
    white,
    expireDate,
    disabled,
    fixAppendWidth,
    withAppendWidth,
    maxLength,
  },
  ref,
) => {
  const as = () => {
    if (type == 'number') {
      return NumericFormat
    } else if (type == 'pattern') {
      return PatternFormat
    } else {
      return 'input'
    }
  }

  return (
    <Wrapper
      ref={ref}
      tabIndex={-1}
      continuing={continuing}
      tail={tail ? 1 : 0}
      halfwidth={halfwidth}
      halfwidthWithGap={halfwidthWithGap}
      angularright={angularright}
      angularleft={angularleft}
      readonly={readOnly}
      white={white ? 'Y' : 'N'}
      disabled={disabled}
      withwidth={withAppendWidth}
      fixappend={fixAppendWidth}
      maxLength={maxLength}
    >
      {prepend && (
        <Prepend angularleft={angularleft}>{prepend}</Prepend>
      )}
      {renderer ? (
        renderer
      ) : (
        <SingleWrapper
          white={white ? 'Y' : 'N'}
          tabIndex={-1}
          tail={tail ? 1 : 0}
        >
          {type == 'number' ? (
            <>
              <StyledInput
                prepend={prepend}
                className={'note-detail-input ' + className}
                width={width}
                height={height}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                type="text"
                tail={tail ? 1 : 0}
                as={as()}
                defaultValue={defaultValue}
                data-key={datasetKey}
                inputMode="decimal"
                thousandSeparator={thousandSeparator}
                onValueChange={onValueChange}
                format={format}
                hasprepend={prepend ? 'Y' : 'N'}
                readOnly={readOnly}
                append={append ? 'Y' : 'N'}
                onKeyDown={onKeyDown}
                disabled={disabled}
                white={white ? 'Y' : 'N'}
              />
            </>
          ) : type == 'date' ? (
            <NoteInputDate
              width={width}
              height={height}
              prepend={prepend}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              datasetKey={datasetKey}
              tail={tail}
              defaultValue={defaultValue}
              selected={selected}
              readOnly={readOnly}
              expireDate={expireDate}
              white={white ? 'Y' : 'N'}
            />
          ) : (
            <>
              {value ? (
                <StyledInput
                  className={'note-detail-input ' + className}
                  width={width}
                  height={height}
                  value={value}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder={placeholder}
                  type={type}
                  tail={tail ? 1 : 0}
                  data-key={datasetKey}
                  hasprepend={prepend ? 'Y' : 'N'}
                  readOnly={readOnly}
                  disabled={disabled}
                  append={append ? 'Y' : 'N'}
                />
              ) : (
                <StyledInput
                  className={'note-detail-input ' + className}
                  width={width}
                  height={height}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder={placeholder}
                  type={type}
                  tail={tail ? 1 : 0}
                  defaultValue={defaultValue}
                  data-key={datasetKey}
                  hasprepend={prepend ? 'Y' : 'N'}
                  readOnly={readOnly}
                  disabled={disabled}
                  append={append ? 'Y' : 'N'}
                />
              )}
            </>
          )}
          {append && <Append fix={fixAppendWidth}>{append}</Append>}
        </SingleWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #f4f6fb;
  border-radius: 8px;
  position: relative;
  display: inline-flex;
  align-items: center;
  appearance: none;
  box-sizing: border-box;

  ${({ withwidth, fixappend }) =>
    withwidth &&
    fixappend &&
    `
    width: ${withwidth + 30}px;
  `};

  ${({ continuing }) =>
    continuing &&
    `
      width: 60%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `};
  ${({ angularright }) =>
    angularright &&
    `
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  `};

  ${({ angularleft }) =>
    angularleft &&
    `
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  `};

  ${({ halfwidth }) => halfwidth && `width: 50%;`};
  ${({ halfwidthWithGap }) =>
    halfwidthWithGap && `width: calc(50% - 4px);`};

  ${({ tail }) =>
    tail &&
    `
      width: 40%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    `};

  ${({ mb }) => mb && `margin-bottom: 12px;`};
  ${({ white }) =>
    white == 'Y' && `background-color: ${theme.colors.base.white};`};

  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${theme.colors.gray[100]};
  `}
`

const Prepend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 73px;
  height: 100%;
  background-color: #dce1ef82;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: ${theme.colors.gray[600]};
  font-weight: 500;
  line-height: 100%;
  font-size: 14px;

  ${media.mediumS`
    font-size: 13px;
    min-width: 64px;
  `}
`

const Append = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  color: ${theme.colors.gray[400]};
  font-weight: 400;
  white-space: nowrap;

  ${({ fix }) =>
    fix &&
    `
    text-align: right;
    width: 30px;
    padding-left: 0;
  `}

  ${media.mediumS`
    padding-left: 0;
    padding-right: 4px;
    font-size: 13px;
  `}
`

const SingleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 73px);
  height: 100%;
  position: relative;
  background-color: #f4f6fb;
  border-radius: 6px;

  ${({ hasprepend }) =>
    !hasprepend &&
    `
    width: 100%;
    `};

  ${({ tail }) =>
    tail &&
    `
    width: 100%;
    `};

  ${({ white }) =>
    white == 'Y' && `background-color: ${theme.colors.base.white};`};
`

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  font-weight: 500;
  border-radius: 0;
  background-color: #f4f6fb;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 6px;

  ${({ width }) =>
    width &&
    `
    width:${width}px;
    `}

  ${({ height }) =>
    height &&
    `
    height:${height}px;
    `}

  ${({ white }) =>
    white == 'Y' && `background-color: ${theme.colors.base.white};`};

  ${({ prepend }) =>
    prepend &&
    `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    `}
  color: ${theme.colors.gray[900]};

  &::placeholder {
    font-weight: 400;
    color: ${theme.colors.gray[400]};
  }

  &:disabled {
    background-color: ${theme.colors.gray[100]};
    ${Append} {
      background-color: ${theme.colors.gray[100]};
    }
  }

  ${media.mediumS`
    width: 100%;
    padding: 0 4px 0 6px;
  `}
`

export default forwardRef(NoteInput)
