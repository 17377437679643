import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 엘리베이터
 */
const NoteAdColumnElevator = ({ half = true }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const elevator = watch(`oneroomInfo.oneroomFeature.elevator`)

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>엘리베이터</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          fit
          label="예"
          name="oneroom_elevator"
          value="Y"
          checked={elevator === 'Y'}
          {...register('oneroomInfo.oneroomFeature.elevator')}
          onChange={(e) => {
            onUpdate(
              'oneroomInfo.oneroomFeature.elevator',
              e.target.value,
            )
          }}
        />
        <RadioButton
          fit
          label="아니오"
          name="oneroom_elevator"
          value="N"
          {...register('oneroomInfo.oneroomFeature.elevator')}
          checked={elevator === 'N'}
          onChange={(e) => {
            onUpdate(
              'oneroomInfo.oneroomFeature.elevator',
              e.target.value,
            )
          }}
        />
        <ValidateWarn>
          {errors?.oneroomInfo?.oneroomFeature?.elevator?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnElevator
