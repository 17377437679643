import MapContext from 'contexts/map'
import { useContext } from 'react'
import useMapFilter from 'containers/map/hooks/useMapFilter'
import MapFilterPrice from 'components/map/MapFilter/MapFilterPrice'
import MapFilterAuction from 'components/map/MapFilter/MapFilterAuction'
import MapFilterBuildingSelector from 'components/map/MapFilter/MapFilterBuildingSelector'
import MapFilterLandType from 'components/map/MapFilter/MapFilterLandType'
import { useSelector } from 'react-redux'

const MapFilterRealPriceContainer = () => {
  const { actions } = useContext(MapContext)
  const { filterState } = useMapFilter()

  const filterOptions = useSelector((state) => state.filter.options)
  /**
   * 건물 종류 클릭 이벤트
   */
  const togglePropertyType = (changeType) => {
    actions.setFilterState((prev) => {
      let value = { ...prev }
      let prevPropertyType = prev.real_prices.price_property_type

      // 전체 선택 시 초기화
      if (changeType === 'all') {
        value.real_prices.price_property_type = []
        return value
      }

      // 이미 배열에 있을 경우 제거하고 없다면 추가
      if (prevPropertyType.find((e) => e === changeType)) {
        // 배열 초기화
        value.real_prices.price_property_type = []

        /**
         * map을 쓰면 빈 리턴도 배열 안에 undefined를 추가하기 때문에
         * foreach를 사용하여 수동으로 push해줌.
         */
        prevPropertyType.forEach((item) => {
          if (item === changeType) return
          return value.real_prices.price_property_type.push(item)
        })
      } else {
        value.real_prices.price_property_type.push(changeType)
      }

      return value
    })
  }

  const onChangeLandType = (value) => {
    return actions.setFilterState((prev) => {
      return {
        ...prev,
        real_prices: {
          ...prev.real_prices,
          land_type: value,
        },
      }
    })
  }

  return (
    <>
      <MapFilterPrice />
      <MapFilterAuction />
      <MapFilterLandType
        land_type={filterState?.real_prices?.land_type}
        onChangeLandType={onChangeLandType}
      />
      <MapFilterBuildingSelector
        title="실거래 종류"
        options={filterOptions.real_price_type}
        value={filterState?.real_prices?.price_property_type || []}
        togglePropertyType={togglePropertyType}
      />
    </>
  )
}

export default MapFilterRealPriceContainer
