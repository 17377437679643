import SummaryJeonyouList from 'components/summary/SummaryJeonyoubu/SummaryJeonyouList'
import SummaryJeonyoubuDongho from 'components/summary/SummaryJeonyoubu/SummaryJeonyoubuDongho'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'components/design'
import useAxios from 'lib/hooks/useAxios'
import { getBuildingHo } from 'lib/api/summaryApi'
import { Skeleton } from 'antd'

const SummaryJeonyoubuContainer = ({
  dongList,
  hoList,
  onClickDong,
  onClickHo,
  selectedDong,
  selectedHo,
  isHoEmpty,
}) => {
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )
  const [jeonyouData, setJeonyouData] = useState(null)
  const [gongyongData, setGongyongData] = useState(null)

  const [onRequest, loading, data] = useAxios(getBuildingHo)

  //호 선택했을 때
  useEffect(() => {
    if (dongList.length == 0) return
    if (!selectedHo) return

    setHoInfo(selectedHo)
  }, [selectedDong, selectedHo])

  /**
   * 호가 선택되었을 때 동작
   * @param {string} value
   */
  const setHoInfo = (value) => {
    onRequest({
      address_id: currentAddressId,
      dong_name: selectedDong == '구분없음' ? '' : selectedDong,
      ho_name: value,
    })
  }

  useEffect(() => {
    if (!data || !data?.result) return

    const jeonyou = data.result.filter(
      ({ excluded_shared_sep_code_name }) =>
        excluded_shared_sep_code_name == '전유',
    )
    const gongyong = data.result.filter(
      ({ excluded_shared_sep_code_name }) =>
        excluded_shared_sep_code_name == '공용',
    )

    setJeonyouData(jeonyou)
    setGongyongData(gongyong)
  }, [data])

  const onClickHoJeonyou = (value) => {
    onClickHo(value)
  }

  return (
    dongList.length != 0 &&
    !isHoEmpty && (
      <Card>
        <CardHeader>
          <CardTitle>전유부</CardTitle>
        </CardHeader>
        <CardBody>
          <>
            <SummaryJeonyoubuDongho
              dongList={dongList}
              hoList={hoList}
              selectedDong={selectedDong}
              selectedHo={selectedHo}
              onClickDong={onClickDong}
              onClickHo={onClickHoJeonyou}
            />
            {loading ? (
              <Skeleton style={{ marginTop: '20px' }} active />
            ) : (
              <>
                <SummaryJeonyouList
                  data={jeonyouData}
                  title="전유부분"
                  isSelected={selectedDong && selectedHo}
                />
                <SummaryJeonyouList
                  data={gongyongData}
                  title="공용부분"
                  isSelected={selectedDong && selectedHo}
                />
              </>
            )}
          </>
        </CardBody>
      </Card>
    )
  )
}

export default SummaryJeonyoubuContainer
