import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 상가명
 */
const NoteAdColumnStoreName = () => {
  const { onUpdate } = useAdUpdate()

  const { register, setValue, watch } = useFormContext()

  return (
    <ColumnSet>
      <ColumnTitle>상가명</ColumnTitle>
      <ColumnVal>
        <InputText
          type="text"
          {...register('article.buildNm')}
          onBlur={(e) => {
            onUpdate('article.buildNm', e.target.value)
          }}
        />
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnStoreName
