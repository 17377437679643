import { createSlice } from '@reduxjs/toolkit'
import propertyList from 'components/jmapnotev2/propertyData'

export const initPropertyParams = () => {
  const properties = propertyList.map(({ label }) => label)
  const result = {}
  properties.forEach((key) => (result[key] = []))
  return result
}

const initialState = {
  mode: localStorage.getItem('notemode')
    ? localStorage.getItem('notemode')
    : 'card',
  noteOptions: null,
  noteContacts: null,
  noteListInfo: {
    count: 0,
  },
  noteListSearchParams: {
    property_types: initPropertyParams(),
    q: '',
    sort: {
      index: '',
      type: '',
    },
  }, //매물관리 메뉴에서만 쓰는 조회 파라미터
  noteListIsShowMap: false,
  notePropertyFilter: {}, //property 조회 결과 목록
  contract: {
    total_price_info: {
      criteria: '',
      srl: '',
    },
  }, //계약서 중개보수 저장 변수
  isVisibleBottomNav: true,
  isVisibleHeaderChannel: true,
  headerContent: '모든매물',
  headerSubContent: '',
  selectedAddressStore: '',
  gridFontSize: localStorage.getItem('noteGridFontSize') || 'm',
  docsTempRrn: {
    //계약서, 확인설명서 주민번호 뒷자리를 기억해놓기 위한 temp state
    //각 배열에 index, value 2개의 key를 가진다
    seller: [],
    buyer: [],
  },
  refreshPayments: false, //결제내역 refresh flag
}

export const noteV2Slice = createSlice({
  name: 'notev2',
  initialState,
  reducers: {
    setNoteOptions(state, actions) {
      state.noteOptions = actions.payload
    },
    setNoteContacts(state, actions) {
      state.noteContacts = actions.payload
    },
    setNoteListInfo(state, actions) {
      state.noteListInfo = { ...state.noteListInfo, ...actions.payload }
    },
    setNoteListSearchParams(state, actions) {
      state.noteListSearchParams = {
        ...state.noteListSearchParams,
        ...actions.payload,
      }
    },
    setNotePropertyFilter(state, actions) {
      state.notePropertyFilter = {
        ...state.notePropertyFilter,
        ...actions.payload,
      }
    },
    setResetNotePropertyFilter(state, actions) {
      state.notePropertyFilter = actions.payload
    },
    setIsVisibleBottomNav(state, actions) {
      state.isVisibleBottomNav = actions.payload
    },
    setIsVisibleHeaderChannel(state, actions) {
      state.isVisibleHeaderChannel = actions.payload
    },
    setHeaderContent(state, actions) {
      state.headerContent = actions.payload
    },
    setHeaderSubContent(state, actions) {
      state.headerSubContent = actions.payload
    },
    setSelectedAddressStore(state, actions) {
      state.selectedAddressStore = actions.payload
    },
    setNoteListIsShowMap(state, actions) {
      state.noteListIsShowMap = actions.payload
    },
    setGridFontSize(state, actions) {
      state.gridFontSize = actions.payload
    },
    setSellerTempRrn(state, actions) {
      //매도인 주민번호 뒷자리
      state.docsTempRrn.seller = actions.payload
    },
    setBuyerTempRrn(state, actions) {
      //매수인 주민번호 뒷자리
      state.docsTempRrn.buyer = actions.payload
    },
    resetTempRrn(state, actions) {
      //temp 데이터 초기화
      state.docsTempRrn = {
        seller: [],
        buyer: [],
      }
    },
    setRefreshPayments(state, actions) {
      state.refreshPayments = actions.payload
    },
  },
})

export const {
  setNoteOptions,
  setNoteContacts,
  setNoteListInfo,
  setNoteListSearchParams,
  setNotePropertyFilter,
  setResetNotePropertyFilter,
  setIsVisibleBottomNav,
  setNoteListIsShowMap,
  setIsVisibleHeaderChannel,
  setHeaderContent,
  setHeaderSubContent,
  setSelectedAddressStore,
  setGridFontSize,
  setSellerTempRrn,
  setBuyerTempRrn,
  resetTempRrn,
  setRefreshPayments,
} = noteV2Slice.actions

export default noteV2Slice.reducer
