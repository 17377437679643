import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeTab: 1,
  selectedDanji: null,
  loadingDanjiDetail: false,
  selectedDanjiDongs: [],
  selectedDanjiDongsDot: [],
  selectedDong: null,
  visibleAddModal: false,
  visibleNotPermitPlusModal: false,
  hasAddedList: 'notReady',
  favoriteIds: [],
  memoIds: [],
  blockSize: 60,
  isSelectFirst: false,
  isInfoBubbleVisible: false,
  centeredDongs: [], //동 별 중앙 정렬 여부
}

export const NoteDonghoSlice = createSlice({
  name: 'noteDongho',
  initialState,
  reducers: {
    setActiveTab(state, actions) {
      state.activeTab = actions.payload
    },
    setSelectedDanji(state, actions) {
      state.selectedDanji = actions.payload
    },
    setLoadingDanjiDetail(state, actions) {
      state.loadingDanjiDetail = actions.payload
    },
    setSelectedDanjiDongs(state, actions) {
      state.selectedDanjiDongs = actions.payload
    },
    setSetSelectedDanjiDongsDot(state, actions) {
      state.selectedDanjiDongsDot = actions.payload
    },
    setSelectedDong(state, actions) {
      state.selectedDong = actions.payload
    },
    setVisibleAddModal(state, actions) {
      state.visibleAddModal = actions.payload
    },
    setHasAddedList(state, actions) {
      state.hasAddedList = actions.payload
    },
    setVisibleNotPermitPlusModal(state, actions) {
      state.visibleNotPermitPlusModal = actions.payload
    },
    setFavoriteIds(state, actions) {
      state.favoriteIds = actions.payload
    },
    setMemoIds(state, actions) {
      state.memoIds = actions.payload
    },
    setBlockSize(state, actions) {
      state.blockSize = actions.payload
    },
    resetSelectedDanji(state, actions) {
      state.selectedDong = null
      state.selectedDanjiDongs = []
      state.selectedDanji = null
      state.activeTab = 1
      //state.hasAddedList = 'notReady'
    },
    setIsSelectFirst(state, actions) {
      state.isSelectFirst = actions.payload
    },
    setIsInfoBubbleVisible(state, actions) {
      state.isInfoBubbleVisible = actions.payload
    },
    setCenteredDongs(state, actions) {
      state.centeredDongs = actions.payload
    },
    setSelectedPlusDanji(state, actions) {
      state.selectedDanji = Object.assign({}, state.selectedDanji, {
        is_paid: state.selectedDanji?.is_paid == 'Y' ? 'N' : 'Y',
      })
    },
  },
})

export const {
  setActiveTab,
  setSelectedDanji,
  setLoadingDanjiDetail,
  setSelectedDanjiDongs,
  setSetSelectedDanjiDongsDot,
  setSelectedDong,
  resetSelectedDanji,
  setVisibleAddModal,
  setHasAddedList,
  setVisibleNotPermitPlusModal,
  setFavoriteIds,
  setMemoIds,
  setBlockSize,
  setIsSelectFirst,
  setIsInfoBubbleVisible,
  setCenteredDongs,
  setSelectedPlusDanji,
} = NoteDonghoSlice.actions

export default NoteDonghoSlice.reducer
