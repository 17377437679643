import { useFormContext } from 'react-hook-form'
import { message } from 'antd'

function useAdValidate() {
  const {
    setError,
    setFocus,
    formState: { errors },
  } = useFormContext()

  const validate = (validationError) => {
    if (!validationError) return

    //에러 세팅
    validationError.forEach(({ message, field }) => {
      setError(field, { type: 'custom', message: message })
    })

    //맨 처음으로 보내주는 에러 input에 focus
    const firstError = validationError[0].field
    const firstErrorMsg = validationError[0].message

    if (firstError) {
      if (
        [
          'etcInfo.moveinYmd',
          'verification.rdate',
          'article.cdate',
        ].includes(firstError)
      ) {
        //달력 쓰는 데이터는 강제로 focus.. react-datepicker, react-hook-form 라이브러리가 서로 호환이 안되는듯
        //TODO: 이 코드 고치기
        const splitString = firstError.split('.')

        errors[splitString[0]][splitString[1]]?.ref?.input?.focus()
      } else if (firstError == 'isale') {
        //분양가 에러시 매매금액에 focus
        setFocus('trade.price1')
      } else if (firstError == 'administrationCostInfo') {
        //관리비 입력 안했을 때 부과정보에 focus
        setFocus('administrationCostInfo.chargeCodeType')
      } else if (
        firstError == 'article.category1' &&
        firstErrorMsg ==
          '원룸은 매매가 불가능합니다. 다른 매물유형을 선택해 주십시오.'
      ) {
        //거래정보에 focus
        setFocus('trade.type')
      } else if (firstError.includes('fixedFeeDetails.amount.')) {
        const detailCodeType = firstError.split('.')[3]

        const target = document.getElementById(
          `note-ad-administration-fixed-fee-amount-${detailCodeType}`,
        )

        if (target) {
          target.focus()
        }
      } else if (
        firstError.includes('fixedFeeDetails.amountCodeType.')
      ) {
        const detailCodeType = firstError.split('.')[3]
        const target = document.getElementById(
          `note-ad-administration-fixed-fee-amount-code-type-${detailCodeType}`,
        )

        if (target) {
          target.focus()
        }
      } else {
        setFocus(firstError)
      }

      //모든 에러를 표시할 위치 없음. focus 없는것을 대비해 message로 보여줌
      message.error(validationError[0].message)
    }

    //
  }

  return { validate }
}

export default useAdValidate
