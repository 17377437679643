import { landTypeList, useAreaList } from 'Constants'
import theme from 'lib/styles/theme'
import { getAddressName, getPropertyTypeName } from 'lib/utils'

/**
 * 레이어에 적용되는 필터 조건 설정
 * @param {*} filterState
 * @returns
 */
export const filterParams = (filterState) => {
  const nowYear = new Date().getFullYear()

  // 지목
  // let land_type = []
  // if (filterState.land_type) {
  //   land_type = filterState.land_type.map((item) => landTypeList[item])
  // }

  // 용도지역
  // let use_area = []
  // if (filterState.use_area) {
  //   use_area = filterState.use_area.map((item) => useAreaList[item])
  // }

  // 건물 유형
  const property_type = filterState.real_prices.price_property_type

  // 지목
  const land_type = filterState.real_prices.land_type

  // 대지면적
  // let land_area = []
  // if (
  //   filterState.land_area[0] !== 0 ||
  //   filterState.land_area[1] !== 300
  // ) {
  //   land_area = filterState.land_area
  //     .toString()
  //     .split(',')
  //     .map((area) => {
  //       return area
  //     })
  // }
  // 연면적
  // let total_floor_area = []
  // if (
  //   filterState.total_floor_area[0] !== 0 ||
  //   filterState.total_floor_area[1] !== 360
  // ) {
  //   total_floor_area = filterState.total_floor_area
  //     .toString()
  //     .split(',')
  //     .map((area) => {
  //       return area
  //     })
  // }
  // 층수
  // let floor_number = []
  // if (
  //   parseInt(filterState.floor_number[0]) !== 1 ||
  //   parseInt(filterState.floor_number[1]) !== 7
  // ) {
  //   floor_number = filterState.floor_number
  // }
  // 거래연도
  let contract_date = []
  if (
    filterState.real_prices.contract_date[0] !== 2006 ||
    filterState.real_prices.contract_date[1] !== nowYear
  ) {
    contract_date = filterState.real_prices.contract_date
  }
  // 건축연도
  // let construction_year = []
  // if (
  //   filterState.construction_year[0] !== nowYear - 41 ||
  //   filterState.construction_year[1] !== nowYear
  // ) {
  //   construction_year = filterState.construction_year
  // }
  return {
    land_type,
    //  use_area,
    price_property_type: property_type,
    //  land_area,
    //   total_floor_area,
    //   floor_number,
    contract_date,
    //  construction_year,
  }
}

/**
 * JOOTEK의 다른 서비스와 연동하는 링크의 base URL
 */
export const getJootekServiceURL = (path) => {
  return (
    '//' +
    (window.location.href.match(/jootek\.com/)
      ? 'jootek.com'
      : window.location.host) +
    path
  )
}

/**
 * JOOTEK FULL URL을 얻는 함수
 */
export const getFullUrl = (path, isMap) => {
  const baseUrl = isMap
    ? 'https://map.jootek.com'
    : 'https://jootek.com'

  const url = window.location.href.match(/jootek\.com/)
    ? baseUrl
    : '//' + window.location.host

  return url + path
}

/**
 * 용도지역명 4글자 포맷
 */
export const getUseAreaFormat = (usearea) => {
  const useAreaArray = [
    { 제1종전용주거지역: '1종전용' },
    { 제2종전용주거지역: '2종전용' },
    { 제1종일반주거지역: '1종일반' },
    { 제2종일반주거지역: '2종일반' },
    { 제3종일반주거지역: '3종일반' },
    { 준주거지역: '준주거' },
    { 중심상업지역: '중심상업' },
    { 일반상업지역: '일반상업' },
    { 근린상업지역: '근린상업' },
    { 유통상업지역: '유통상업' },
    { 보전관리지역: '보전관리' },
    { 생산관리지역: '생산관리' },
    { 계획관리지역: '계획관리' },
    { 전용공업지역: '전용공업' },
    { 일반공업지역: '일반공업' },
    { 준공업지역: '준공업' },
    { 보전녹지지역: '보전녹지' },
    { 생산녹지지역: '생산녹지' },
    { 자연녹지지역: '자연녹지' },
    { 농림지역: '농림지역' },
    { 자연환경보전지역: '환경보전' },
    { 개발제한구역: '개발제한' },
  ]

  return useAreaArray.map((value) => {
    const key = Object.keys(value)[0]
    if (key === usearea) {
      return value[key]
    }
  })
}

/**
 * 건물종류 목록
 */
export const getBuildingTypes = () => {
  return [
    { singlehome: '단독주택' },
    { multiunit: '다세대·다가구주택' },
    { commercial: '상가건물' },
    { special: '특수주택' },
  ]
}

/**
 * 주 건축물의 용도 파악
 */
export const buildingPurposeShortcuts = {
  제1종근린생활시설: '1종근생',
  제2종근린생활시설: '2종근생',
}

export const buildingPurposeColors = {
  단독주택: 'red',
  다가구주택: 'blue',
  다세대주택: 'blue',
  연립주택: 'blue',
  오피스텔: 'purple',
  공동주택: 'purple',
  상가: 'pink',
  근린생활: 'pink',
  '1종근생': 'pink',
  '2종근생': 'purple',
  아파트: 'cyan',
  토지: 'orange',
  공장: 'black',
}

/**
 * 건축구조 목록
 */
export const getStructureTypes = () => {
  return [
    { concrete: '철근콘크리트조' },
    { lightwood: '경량목구조' },
    { heavywood: '중량목구조' },
    { koreanwood: '한옥목구조' },
    { lightsteel: '경량철골조' },
    //{'brick': '벽돌조'},
    { alc: 'ALC조' },
  ]
}

/**
 * 주소 표시용 건물 타입
 */
export const getBuildingType = (value) => {
  const changeTypes = ['1종근생', '2종근생', '업무시설']
  const asIsTypes = [
    '아파트',
    '오피스텔',
    '다세대주택',
    '다가구주택',
    '단독주택',
  ]

  if (changeTypes.includes(value)) {
    const result = {
      '1종근생': '상가건물',
      '2종근생': '상가건물',
      업무시설: '사무실',
    }
    return result[value]
  } else if (asIsTypes.includes(value)) {
    return value
  } else {
    return ''
  }
}

/**
 * 메인 주소 규칙
 * 건물명 있음 - 건물명 보여줌
 * 건물명 없음 - 지번 주소(읍면동 부터 시작하는) + 건물타입
 * 건물 타입 조건: https://www.notion.so/jootek/dd2b5225b6ea487ea3c619994b10dc29
 */
export const getDisplayAddress = (land, building, roadName) => {
  if (!building) return ''

  const buildingName =
    building?.apt?.apt_name || building?.result?.[0]?.building_name
  let result

  if (buildingName) {
    //건물명 있음
    result = buildingName
  } else {
    //건물명 없음
    result = `${
      getAddressName(land, roadName, true) || clickPosition.address_name
    } ${getBuildingType(getPropertyTypeName(building))}`
  }

  return result
}

export const floorSeperationCode = (code) => {
  let value = ''
  switch (code) {
    default:
    case 0:
      value = '기타'
      break
    case 10:
      value = '지하'
      break
    case 20:
      value = ''
      break
    case 21:
      value = '복층하'
      break
    case 22:
      value = '복층상'
      break
    case 30:
      value = '옥탑'
      break
  }
  return value
}

export const numberWithCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return number
  }
}

/**
 * @METHOD: 단위 설정이 적용된 면적을 출력함
 * @param {Number} area
 * @param {Boolean} isSquareMeter
 */
export const getComputedArea = (area, isSquareMeter) => {
  const parsedArea = parseInt(area)

  if (parsedArea === 0) return

  if (isSquareMeter)
    return numberWithCommas(Math.round(parsedArea)) + '㎡'
  return numberWithCommas(Math.round(parsedArea / 3.305)) + '평'
}
// 메서드 명칭 개선
export const getFormatedArea = (area, isSquareMeter) =>
  getComputedArea(area, isSquareMeter)

export const getFormatedAreaSecondDigit = (area, isSquareMeter) => {
  if (isSquareMeter)
    return numberWithCommas(parseFloat(area.toFixed(2))) + '㎡'
  return numberWithCommas(parseFloat((area / 3.305).toFixed(2))) + '평'
}

export const getFormatedAreaRound = (area, isSquareMeter) => {
  if (isSquareMeter)
    return numberWithCommas(Math.round(parseFloat(area))) + '㎡'
  return numberWithCommas(Math.floor(parseFloat(area / 3.305))) + '평'
}

export const getForamtedAreaWithoutUnit = (area, isSquareMeter) => {
  if (area == 0) return ''

  return isSquareMeter
    ? numberWithCommas(area)
    : numberWithCommas((area / 3.305).toFixed(2))
}

/**
 * input 입력시 가격 comma 붙여서 return
 */
export const inputPriceFormat = (str) => {
  const comma = (str) => {
    str = String(str)
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
  }
  const uncomma = (str) => {
    str = String(str)
    return str.replace(/[^\d]+/g, '')
  }
  return comma(uncomma(str))
}

/**
 * 억 단위 표기
 * @param {Number} money
 */
export const billionFormate = (money) => {
  if (money <= 1000) {
    return Math.round(money) + '원'
  } else if (money < 10000) {
    return Math.round(money) + '원'
  } else if (money < 100000) {
    return (money / 10000).toFixed(2) + '만'
  } else if (money < 1000000) {
    return (money / 10000).toFixed(1) + '만'
  } else if (money < 100000000) {
    return Math.round(money / 10000) + '만'
  } else if (money < 1000000000) {
    return (money / 100000000).toFixed(2) + '억'
  } else if (money < 10000000000) {
    return (money / 100000000).toFixed(1) + '억'
  } else if (money < 1000000000000) {
    return Math.round(money / 100000000) + '억'
  } else if (money < 100000000 * 100000) {
    return (money / 100000000 / 10000).toFixed(2) + '조'
  } else if (money < 100000000 * 1000000) {
    return (money / 100000000 / 10000).toFixed(1) + '조'
  } else if (money < 100000000 * 100000000) {
    return Math.round(money / 100000000 / 10000) + '조'
  } else {
    return (money / 100000000 / 100000000).toFixed(2) + '경'
  }
}

export const billionFormatTenMillion = (money, blank) => {
  return parseFloat((money / 10000).toFixed(2)) + (blank ? ' 억' : '억')
}

export const dateFormat = (date) => {
  // return date.substring(0, 4) + '년 ' + date.substring(4, 6) + '월 ' + date.substring(6, 8) + '일';
  if (date?.length > 8) {
    return (
      date.substring(0, 4) +
      '.' +
      date.substring(5, 7) +
      '.' +
      date.substring(8)
    )
  } else if (date?.length === 8) {
    return (
      date.substring(0, 4) +
      '.' +
      date.substring(4, 6) +
      '.' +
      date.substring(6, 8)
    )
  }
  return date
}

export const dateFormatForMarker = (date) => {
  date = date.replace('-', '')
  return '’' + date.substring(2, 4) + '.' + date.substring(4, 6)
}

export const unitFormat = (value) => {
  if (value.toString().length > 3) {
    return Math.round((value / 1000) * 100) / 100 + '㎞'
  } else {
    return value + 'm'
  }
}

export const hospitalFormat = (name) => {
  // 공백 제거
  let str = name.replace(/(\s*)/g, '')

  // '재단' 앞부분 제거
  const index1 = str.indexOf('재단')
  if (index1 !== -1) {
    str = str.substring(index1 + 2)
  }

  // '법인' 앞부분 제거
  const index2 = str.indexOf('법인')
  if (index2 !== -1) {
    str = str.substring(index2 + 2)
  }

  // 괄호 포함해서 안의 글씨 삭제
  const leftIdx = str.indexOf('(')
  const rightIdx = str.indexOf(')')

  if (leftIdx !== -1 && rightIdx !== -1) {
    const firstStr = str.substring(0, leftIdx)
    const lastStr = str.substring(rightIdx + 1)
    str = firstStr + lastStr
  }

  return str
}

export const phoneNumberFormat = (value) => {
  if (value.length === 11) {
    return (
      value.substr(0, 3) +
      '-' +
      value.substr(3, 4) +
      '-' +
      value.substr(7, 4)
    )
  } else {
    return value
  }
}

export const apartmentNameFormat = (name) => {
  if (name.length > 6) {
    return name.substring(0, 6) + '...'
  } else {
    return name
  }
}

export const propertyTypeFormat = (type) => {
  if (!type) return ''

  if (type.includes('다가구')) {
    return '다가구주택'
  }
  if (type.includes('다세대')) {
    return '다세대주택'
  }
  if (type.includes('상업업무용')) {
    return '상가'
  }
  return type
}

export const moveCauseFormat = (value) => {
  if (!value) return ''

  if (value.includes('분할')) {
    return '분할'
  }
  if (value.includes('합병')) {
    return '합병'
  }
  return value
}

/**
 * 위도 차이를 미터로 환산
 *
 * 단순화된 공식이지만 한국의 위도 범위에서는 10만분의 1 이내로 정확함
 *
 * @param {float} lat1
 * @param {float} lat2
 * @return {float}
 */
export const lat2m = (lat1, lat2) => {
  let multiplier = 110996 + ((lat1 + lat2) / 2 - 38) * 18
  return (lat2 - lat1) * multiplier
}

/**
 * 경도 차이를 미터로 환산
 *
 * 위도에 따라 달라지므로 위도를 함께 넘겨주어야 함
 *
 * @param {float} lon1
 * @param {float} lon2
 * @param {float} lat
 * @return {float}
 */
export const lon2m = (lon1, lon2, lat) => {
  let radian = (lat * Math.PI) / 180
  let multiplier =
    111412.84 * Math.cos(radian) -
    93.5 * Math.cos(radian * 3) +
    0.118 * Math.cos(radian * 5)
  return (lon2 - lon1) * multiplier
}

/**
 * 두 점 사이의 거리 구하기
 * @param {float} lat1
 * @param {float} lon1
 * @param {float} lat2
 * @param {float} lon2
 * @return {float}
 */
export const getDistance = (lat1, lon1, lat2, lon2) => {
  let result = 0

  let x = lon2m(lon1, lon2, (lat1 + lat2) / 2)
  let y = lat2m(lat2, lat1)

  result = Math.sqrt(x * x + y * y)
  return result
}

/**
 * 레이어가 열렸을때 잘리는것 체크
 * 폴드 등 레이어 잘리는 레이아웃 보완 (반반으로 나눔)
 */
export const isLayerSmallThanWindow = () =>
  window.innerWidth < theme.base.container + theme.base.externalWidth
    ? true
    : false

/**
 * object를 key로 삭제
 * @param {*} param0
 * @param {*} key
 * @returns
 */
export const deleteProperty = ({ [key]: _, ...newObj }, key) => newObj

/**
 * array를 unique 하게 filtering
 * use: array.filter(onlyUnique);
 * @param {*} value
 * @param {*} index
 * @param {*} array
 * @returns
 */
export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index
}

/**
 * 다음 element focus
 * @param {*} e
 */
export const focusNextElement = ({
  e,
  focussableElements,
  type = 'forward',
}) => {
  if (document.activeElement) {
    const focussable = Array.prototype.filter.call(
      document.querySelectorAll(focussableElements),
      function (element) {
        return (
          element.offsetWidth > 0 ||
          element.offsetHeight > 0 ||
          element === document.activeElement
        )
      },
    )
    const index = focussable.indexOf(e.target)

    if (index > -1) {
      const nextElement =
        focussable[index + (type == 'forward' ? 1 : -1)] ||
        focussable[0]

      nextElement.focus()
    }
  }
}
