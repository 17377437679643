import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { floorSeperationCode, getFormatedArea } from 'utils'

const SummaryFloorItem = ({ item }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const getFloorNumber = (floorNumber, seperationCode) => {
    const parsedNumber = Math.abs(parseInt(floorNumber))
    let transpiledFloorNumber = parsedNumber + '층'

    /**
     * floorNumber가 숫자가 아닐 경우 원본 그대로 출력
     */
    if (isNaN(parsedNumber)) transpiledFloorNumber = floorNumber

    if (!seperationCode) return transpiledFloorNumber

    // seperationCode가 10일 경우 지하임
    if (seperationCode === 10) {
      return 'B' + transpiledFloorNumber
    }
    // seperationCode가 20일 경우 일반 지상층이므로 별도 처리 하지 않음
    if (seperationCode === 20) {
      return transpiledFloorNumber
    }
    // 그 밖의 경우 (복층상, 복층하, 옥탑 등)
    return floorSeperationCode(item[0].floor_seperation_code)
  }

  const area = useMemo(
    () =>
      getFormatedArea(
        item.reduce(
          (prev, next) =>
            Number(prev?.area || 0) + Number(next?.area || 0),
          0,
        ),
        isSquareMeter,
      ),
    [item, isSquareMeter],
  )

  return (
    <Item>
      <Info>
        <Floor>
          {getFloorNumber(
            item[0].floor_number,
            item[0].floor_seperation_code,
          )}
        </Floor>
        <Purpose>
          {item.map(({ purpose_code_name }, i) => {
            return (
              <PurposeText
                division={i != item.length - 1 ? 'Y' : 'N'}
                key={i}
              >
                {purpose_code_name}
              </PurposeText>
            )
          })}
        </Purpose>
      </Info>
      <Area>{area}</Area>
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  padding: 10px 0;
  font-size: 14px;
  border-bottom: 1px solid ${theme.colors.gray[100]};
`

const Info = styled.div`
  display: flex;
  gap: 13px;
`

const Area = styled.div`
  flex-shrink: 0;
  font-weight: 400;
  line-height: 150%;
  color: ${theme.colors.gray[500]};
`

const Floor = styled.div`
  flex-shrink: 0;
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 150%;
  color: ${theme.colors.gray[800]};
`

const Purpose = styled.div`
  font-weight: 400;
  line-height: 150%;
  color: ${theme.colors.gray[500]};
  padding-right: 24px;
  word-break: keep-all;
`

const PurposeText = styled.span`
  ${({ division }) =>
    division == 'Y' &&
    `
        &::after {
            content : "|";
            padding-left: 4px;
            padding-right: 4px;
        }
    `}
`

export default SummaryFloorItem
