import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

const NoteAdFormStatus = () => {
  const { watch } = useFormContext()

  const status = watch('status')
  const errorMsg = watch('error.message')

  if (
    [
      '승인거절',
      '전송실패',
      '검증취소',
      '검증1차실패',
      '검증최종실패',
      '신고매물',
      '동일주소매물',
    ].includes(status)
  )
    return (
      <Wrapper>
        <Title>
          <i className="fas fa-exclamation-circle"></i>
          {status}
        </Title>
        <Desc>{errorMsg || <>&ndash;</>}</Desc>
      </Wrapper>
    )
  else return <Empty />
}

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 14px 24px;
  margin-bottom: 34px;
  background-color: ${theme.colors.red[50]};
  border: 1px solid ${theme.colors.red[200]};
  border-radius: 8px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${theme.colors.tint.danger};
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`

const Desc = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`

const Empty = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 18px;
`

export default NoteAdFormStatus
