import { CheckButton, Toggle } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import { setOpenFilterType } from 'modules/map'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import { Scrollbars } from 'react-custom-scrollbars-2'
import useIsMobile from 'lib/hooks/useIsMobile'

const MapFilterFloating = ({
  visible,
  render,
  title,
  onOpen,
  isLayerOn,
  toggleLayer,
  isSelectAll,
  onChangeSelectAll,
}) => {
  const isMobile = useIsMobile()
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(setOpenFilterType(null))
  }

  //모바일에서 뒤로가기는 레이어 닫기임
  useEffect(() => {
    if (!isMobile) return
    if (!visible) return
    const preventGoBack = () => {
      onClose()
    }

    history.pushState(null, '', location.href)
    window.addEventListener('popstate', preventGoBack)

    return () => window.removeEventListener('popstate', preventGoBack)
  }, [visible])

  useEffect(() => {
    if (!onOpen) return
    if (!visible) return
    onOpen()
  }, [visible])

  if (!visible) return null

  return ReactDOM.createPortal(
    <Wrapper visible={visible}>
      <HeaderWrapper>
        <TitleWrapper>
          {title}
          <Toggle
            size="xs"
            checked={isLayerOn}
            onChange={toggleLayer}
          ></Toggle>
        </TitleWrapper>
        {!isMobile && (
          <CloseButton onClick={onClose}>
            <i className="fal fa-times"></i>
          </CloseButton>
        )}
      </HeaderWrapper>
      <CheckAllWrapper>
        전체 선택
        <CheckButton
          checked={isSelectAll}
          onChange={onChangeSelectAll}
        />
      </CheckAllWrapper>
      <InnerWrapper>{render}</InnerWrapper>
    </Wrapper>,
    document.getElementById('modal-dialog'),
  )
}

const Wrapper = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: calc(
    ${theme.base.headerHeight}px + 40px +
      (
        ${({ isNoticeBarVisible, noticeBarHeight }) =>
          isNoticeBarVisible ? `${noticeBarHeight}px` : `0px`}
      )
  );
  right: 90px;
  width: 400px;
  max-height: calc(100vh - 150px);
  padding: 0 20px;
  background-color: ${theme.colors.base.white};
  border-radius: 12px;
  box-shadow: ${theme.shadow['card-2']};
  z-index: 22;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${theme.colors.gray[300]};
    border: 3px solid transparent;
    min-height: 50px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  ${media.mediumS`
    width: 100%;
    height: calc(100% - ${
      theme.base.headerHeight + theme.base.bottomMenuHeight
    }px);
    max-height: none;
    top: ${theme.base.headerHeight}px;
    left: 0;
    right: initial;
    transform: none;
    padding-bottom: ${theme.base.bottomMenuHeight}px;
    border-radius: initial;
  `}

  @media screen and (max-height: 800px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const InnerWrapper = styled.div`
  width: 100%;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 48px;
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.gray[600]};
`

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const CheckAllWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid ${theme.colors.gray[100]};
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  color: ${theme.colors.gray[600]};
  font-size: 1rem;
  background-color: ${theme.colors.gray[100]};
  border-radius: 6px;
`

export default MapFilterFloating
