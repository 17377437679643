import { Modal } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * 광고 이미지 등록 가이드 모달
 */
const NoteAdPicturesGuide = ({ onCancel, visible }) => {
  return (
    <Modal
      title="이미지 등록 가이드"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width={570}
      customSize
    >
      <Section>
        <Title number="01">
          gif, jpg, jpeg, png 파일만 올리실 수 있습니다.
        </Title>
        <List>
          <Item>사진은 20장 까지만 등록이 가능합니다.</Item>
        </List>
      </Section>
      <Section>
        <Title number="02">
          매물 사진에는 매물 정보와 관련 없는 이미지 등록을 제한합니다.
        </Title>
        <List>
          <Item>
            매물과 전혀 관련 없는 이미지 (예: 캐릭터 이미지, 꽃, 사물
            등)
          </Item>
          <Item>음란성 이미지</Item>
          <Item>중개업소 간판, 인물 사진, 약도, 명함</Item>
          <Item>
            이미지 내에 상호, 전화번호, 홈페이지 주소 등 텍스트 기입
          </Item>
        </List>
      </Section>
      <Section>
        <Title number="03">
          최초 1회만 네이버 전송시 이미지가 올라갑니다.
        </Title>
        <List>
          <Item>
            2회 이상 수정 (등록) 시 이미지는 중복으로 표시됩니다.
          </Item>
        </List>
      </Section>
    </Modal>
  )
}

const Title = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
  font-size: 16px;
  font-weight: 700;

  &::before {
    content: ${({ number }) => `"${number}"`};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-color: ${theme.colors.blue[400]};
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.base.white};
  }
`

const Section = styled.div`
  margin-bottom: 24px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Item = styled.div`
  padding-left: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[600]};

  &::before {
    content: '\f00c';
    ${({ multiple }) => multiple && `content: '\f00c'`};
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
    font-size: 12px;
    color: ${theme.colors.blue[400]};
  }
`

const Desc = styled.div`
  padding-left: 16px;
  font-size: 12px;
  font-weight: 500;

  &::before {
    content: '\f05a';
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
    font-weight: 400;
    color: ${theme.colors.blue[400]};
  }
`

export default NoteAdPicturesGuide
