import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  InputWidth,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본 정보 - 면적(공급, 전용, 평형)
 */
const NoteAdColumnAreas = ({ required = false }) => {
  const { watch } = useFormContext()
  const propertyType = watch('article.category1')
  const contractArea = watch('contract_area')
  const exclusiveArea = watch('exclusive_area')
  const supplyPyeong = watch('supply_pyeong')
  const presaleUnitType = watch('presale_unit_type')

  return (
    <ColumnSet>
      <ColumnTitle required={required}>면적</ColumnTitle>
      <ColumnVal>
        <Wrapper>
          <InputWrapper>
            <Prepend>공급면적</Prepend>
            <InputText
              fixAppendWidth
              width={InputRemoveAppendWidth}
              type="text"
              placeholder="공급면적"
              defaultValue={contractArea}
              disabled
              append={'㎡'}
            />
          </InputWrapper>
          <InputWrapper>
            <Prepend>전용면적</Prepend>
            <InputText
              fixAppendWidth
              width={InputRemoveAppendWidth}
              type="text"
              placeholder="전용면적"
              defaultValue={exclusiveArea}
              disabled
              append={'㎡'}
            />
          </InputWrapper>
          {propertyType.includes('분양권') ? (
            <InputWrapper>
              <Prepend>타입</Prepend>
              <InputText
                width={InputWidth}
                type="text"
                placeholder="타입"
                defaultValue={presaleUnitType}
                disabled
              />
            </InputWrapper>
          ) : (
            <InputWrapper>
              <Prepend>평형</Prepend>
              <InputText
                width={InputWidth}
                type="text"
                placeholder="평형"
                defaultValue={supplyPyeong}
                disabled
              />
            </InputWrapper>
          )}
        </Wrapper>
      </ColumnVal>
    </ColumnSet>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`

const Prepend = styled.div`
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export default NoteAdColumnAreas
