import theme, { media } from 'lib/styles/theme'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as PencilIcon } from 'assets/icon/note/dongho/pencil.svg'
import styled from 'styled-components'
import { useInput } from 'lib/hooks/useInput'
import useAxios from 'lib/hooks/useAxios'
import { insertHoMemo } from 'lib/api/notev2/dongho'
import { useSelector } from 'react-redux'
import { modifyNote } from 'lib/api/note/noteApi'
import { ScrollStyle } from 'components/jmapnotev2/style/NoteStyle'
import { useDispatch } from 'react-redux'
import {
  setMemoIds,
  setSetSelectedDanjiDongsDot,
} from 'modules/noteDongho'

/**
 * 동호관리 호 상세
 * 메모
 * @param type insert | update
 * @param dongName
 * @param hoName
 */
const NoteDonghoHoDetailMemo = ({
  id,
  type,
  dongName,
  hoName,
  noteSrl,
  addressId,
  addressSrl,
  channelSrl,
  memo,
}) => {
  const memoIds = useSelector((state) => state.noteDongho.memoIds)
  const dispatch = useDispatch()
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  const selectedDanjiDongsDot = useSelector(
    (state) => state.noteDongho.selectedDanjiDongsDot,
  )
  const selectedDong = useSelector(
    (state) => state.noteDongho.selectedDong,
  )
  const [memoVal, bindMemoVal, , settingValue] = useInput('')
  const [onRequestInsert, , dataInsert] = useAxios(insertHoMemo)
  const [onRequesetModify, , dataModify] = useAxios(modifyNote)
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    settingValue(memo)
    if (memo) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [memo])

  /**
   * 메모 작성란 클릭
   */
  const onClick = () => {
    if (isOpen) return

    setIsOpen((prev) => !prev)
  }

  /**
   * 메모 blur (저장)
   */
  const saveMemo = (isKeydown, key) => {
    if (isKeydown) {
      if (key == 'Escape') {
        save()
        updateBlock()
      }
    } else {
      save()
    }
  }

  /**
   * 저장 동작
   */
  const save = () => {
    if (type == 'insert') {
      onRequestInsert({
        building_dongho_id: id,
        jdongho_danji_id: selectedDanji.id,
        dong_name: dongName,
        ho_name: hoName,
        contents: memoVal,
      })
      updateBlock(memoVal)
    } else if (type == 'update') {
      onRequesetModify({
        addressId,
        addressSrl,
        channelSrl,
        dong: dongName,
        ho: hoName,
        noteSrl,
        form: { memo: memoVal },
      })
    }
  }

  /**
   * 블럭도 업데이트
   */
  const updateBlock = () => {
    const targetId = `block_${dongName}_${hoName}`
    if (memoVal) {
      document.getElementById(targetId).classList.add('block-memo')
      dispatch(setMemoIds([...memoIds, ...[targetId]]))

      updateDongState('add')
    } else {
      document.getElementById(targetId).classList.remove('block-memo')
      dispatch(setMemoIds(memoIds.filter((item) => item != targetId)))

      updateDongState('minus')
    }
  }

  /**
   * 동 드롭다운 점 표시 업데이트
   */
  const updateDongState = (type) => {
    if (type == 'add') {
      const copySelectedDanjiDongsDot = { ...selectedDanjiDongsDot }
      copySelectedDanjiDongsDot[selectedDong] =
        copySelectedDanjiDongsDot[selectedDong] + 1
      dispatch(setSetSelectedDanjiDongsDot(copySelectedDanjiDongsDot))
    } else if (type == 'minus') {
      const copySelectedDanjiDongsDot = { ...selectedDanjiDongsDot }
      copySelectedDanjiDongsDot[selectedDong] =
        copySelectedDanjiDongsDot[selectedDong] - 1
      dispatch(setSetSelectedDanjiDongsDot(copySelectedDanjiDongsDot))
    }
  }

  return (
    <Wrapper open={isOpen} onClick={onClick}>
      {isOpen ? (
        <Textarea
          ref={ref}
          autoFocus={!memoVal}
          {...bindMemoVal}
          onBlur={() => saveMemo(false)}
          onKeyDown={(e) => saveMemo(true, e.key)}
        />
      ) : (
        <>
          <PencilIcon /> 메모 작성하기
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(100% - 40px);
  margin: 8px 20px 0 20px;
  border-radius: 8px;
  min-height: 136px;
  color: ${theme.colors.gray[400]};
  background-color: ${theme.colors.gray[50]};
  cursor: pointer;

  ${media.mediumS`
      width: calc(100% - 28px);
      margin-left: 14px;
      margin-right: 14px;
  `}
`

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  padding-bottom: 0;
  border-radius: 8px;
  background-color: ${theme.colors.gray[50]};
  outline: none;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  outline: none;
  border: none;
  min-height: 136px;
  border-bottom: 16px solid ${theme.colors.gray[50]};
  margin: 0;

  ${ScrollStyle}
`

export default NoteDonghoHoDetailMemo
