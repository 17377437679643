import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledDesc = styled.div`
  color: ${theme.colors.gray[700]};
  font-size: 0.875rem;
  font-weight: 400;
  word-break: keep-all;
  padding-top: 0.5rem;

  ${({ hover }) =>
    hover &&
    `
    cursor: pointer;
  `}

  ${({ small }) =>
    small &&
    `
    padding-top: 0.25rem;
    color: ${theme.colors.gray[500]};
    font-size: 13px;
  `}
  
  ${({ xs }) =>
    xs &&
    `
    padding-top: 0.25rem;
    color: ${theme.colors.gray[500]};
    font-size: 12px;
  `}
`

const ListItemDesc = ({
  children,
  small = false,
  xs = false,
  as,
  onClick,
}) => {
  return (
    <StyledDesc
      as={as}
      small={small}
      xs={xs}
      hover={onClick ? true : false}
      onClick={onClick}
    >
      {children}
    </StyledDesc>
  )
}

ListItemDesc.propTypes = {
  children: PropTypes.any,
  small: PropTypes.bool,
  xs: PropTypes.bool,
  as: PropTypes.any,
  onClick: PropTypes.func,
}

export default ListItemDesc
