import { Dropdown } from 'antd'
import theme from 'lib/styles/theme'
import React from 'react'
import styled from 'styled-components'

const simplifyType = {
  등기부등본: '등기',
  토지이용계획원: '이용',
  '토지·건축물대장': '대장',
}
const NoteListMobileItemMore = ({
  regdate,
  deunggibu,
  picture,
  folder_srl,
  channel_srl,
  address_srl,
  note_srl,
  moveDetailTab,
}) => {
  return (
    <Wrapper>
      <Dropdown
        menu={{
          items: [
            {
              label: (
                <Item
                  onClick={(e) =>
                    moveDetailTab({
                      e,
                      channel_srl,
                      address_srl,
                      note_srl,
                      folder_srl,
                      tab: 4,
                    })
                  }
                >
                  <Title>계약서</Title>
                  <Value>{regdate}</Value>
                </Item>
              ),
              key: '0',
            },
            {
              label: (
                <Item
                  onClick={(e) =>
                    moveDetailTab({
                      e,
                      channel_srl,
                      address_srl,
                      note_srl,
                      folder_srl,
                      tab: 3,
                    })
                  }
                >
                  <Title>등기발급</Title>
                  <Value>
                    {deunggibu &&
                      deunggibu?.map((item, i) => (
                        <React.Fragment key={i}>
                          {simplifyType[item.doc_type]}
                          {item.count}{' '}
                        </React.Fragment>
                      ))}
                  </Value>
                </Item>
              ),
              key: '1',
            },
            {
              label: (
                <Item
                  onClick={(e) =>
                    moveDetailTab({
                      e,
                      channel_srl,
                      address_srl,
                      note_srl,
                      folder_srl,
                      tab: 2,
                    })
                  }
                >
                  <Title>매물사진</Title>
                  {picture ? <Value>{picture}개</Value> : ''}
                </Item>
              ),
              key: '3',
            },
          ],
        }}
        trigger={['click']}
      >
        <Icon
          onClick={(e) => {
            e.stopPropagation()
          }}
          className="fas fa-ellipsis-v"
        ></Icon>
      </Dropdown>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const Title = styled.div`
  color: ${theme.colors.gray[800]};
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
`

const Value = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
  color: ${theme.colors.blue[400]};
  text-decoration: underline;
  text-underline-position: under;
`

const Icon = styled.i`
  padding: 0 16px 10px 20px;
  font-size: 14px;
  color: ${theme.colors.gray[600]};
`

export default NoteListMobileItemMore
