import styled from 'styled-components'
import { Badge } from './SummaryAptRpItem'

const SummaryAptRpTableLegend = () => {
  return (
    <Legend>
      <LegendItem>
        <StyledBadge type="직">직</StyledBadge>
        &#58; 직거래
      </LegendItem>
      <LegendItem>
        <StyledBadge type="갱">갱</StyledBadge>
        &#58; 갱신, 갱신요구권
      </LegendItem>
      <LegendItem>
        <StyledBadge type="취">취</StyledBadge>
        &#58; 거래취소
      </LegendItem>
    </Legend>
  )
}

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 10px;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 100%;
`

const StyledBadge = styled(Badge)`
  width: 16px;
  height: 16px;
  font-size: 10px;
`

export default SummaryAptRpTableLegend
