import React, { Suspense, useRef } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ScrollStyle } from '../style/NoteStyle'
import styled from 'styled-components'
import { media } from 'lib/styles/theme'
import useIsMobile from 'lib/hooks/useIsMobile'
/**
 * 동호관리
 * 블럭 섹션 나눈 것
 */
const NoteDonghoBlockSection = ({
  undergroundData,
  groundData,
  dong,
  matchHoColors,
  activeTab,
  onMouseOver,
  onMouseOut,
  onClick,
  height,
}) => {
  const isMobile = useIsMobile()
  const dragging = useRef(false)
  const scrollLeft = useRef(0)
  const clickPoint = useRef(0)
  const scrollContainerRef = useRef(null)

  const NoteDonghoBlocks = React.lazy(() =>
    import('./NoteDonghoBlocks'),
  )

  /**
   * 드래그 시작
   */
  const onMouseDown = (e) => {
    if (isMobile) return
    dragging.current = true

    if (scrollContainerRef.current) {
      clickPoint.current = e.pageX // 처음 클릭한 지점
      scrollLeft.current = scrollContainerRef.current.scrollLeft // 스크롤 움직인 거리
      scrollContainerRef.current.style.cursor = 'grabbing'
    }
  }

  /**
   * 드래그 동작
   * (마우스 down -> move)
   */
  const onMouseMove = (e) => {
    if (isMobile) return
    if (!dragging.current) return

    e.preventDefault()

    if (scrollContainerRef.current) {
      const walk = e.pageX - clickPoint.current

      scrollContainerRef.current.scrollLeft = scrollLeft.current - walk
    }
  }

  /**
   * 드래그 상태 해제
   */
  const removeDraggingState = () => {
    if (isMobile) return
    dragging.current = false
    scrollContainerRef.current.style.cursor = 'default'
  }

  /**
   * 호실 클릭
   */
  const onClickHo = (e) => {
    if (dragging.current) return
    onClick(e)
  }

  return (
    <BlockWrapper
      ref={scrollContainerRef}
      className="prevent-left-scroll"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseLeave={removeDraggingState}
      onMouseUp={removeDraggingState}
      onClick={onClickHo}
      height={height}
    >
      <Suspense
        fallback={
          <SpinWrapper>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
            />
          </SpinWrapper>
        }
      >
        <NoteDonghoBlocks
          data={groundData}
          activeTab={activeTab}
          dong={dong}
          matchHoColors={matchHoColors}
        />
        {undergroundData && undergroundData[dong] && (
          <NoteDonghoBlocks
            activeTab={activeTab}
            data={undergroundData}
            dong={dong}
            isUnderground
            matchHoColors={matchHoColors}
          />
        )}
      </Suspense>
    </BlockWrapper>
  )
}

const BlockWrapper = styled.div`
  margin-top: 28px;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  min-height: 80px;

  ${ScrollStyle}

  ${({ height }) =>
    height &&
    `
      height: ${height + 6}px;
  `}

  ${media.mediumM`
    padding: 0 12px 0 12px;
  `}
`

const SpinWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export default React.memo(NoteDonghoBlockSection)
