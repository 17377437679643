import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import React from 'react'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'
import { options } from '../../options'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 거래정보
 */
const NoteAdColumnContractType = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == adSrl &&
    adFormControl?.disabledColumns.includes('trade.type')

  const option = options['거래구분']

  return (
    <ColumnSet>
      <ColumnTitle required>거래정보</ColumnTitle>
      <ColumnVal>
        {Object.entries(option).map(([key, value]) => {
          return (
            <React.Fragment key={value}>
              <RadioButton
                size="small"
                label={key}
                name="trade_type"
                value={value}
                {...register('trade.type')}
                onChange={(e) => {
                  onUpdate('trade.type', e.target.value)
                }}
                disabled={isDisabled}
              />
            </React.Fragment>
          )
        })}
        <ValidateWarn>{errors?.trade?.type?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnContractType
