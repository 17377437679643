import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 건축구조
 */
const NoteAdColumnBuildingStructure = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const buildType = watch('article.buildType') || ''

  const adOptions = useSelector((state) => state.noteAd.adOptions)

  const [list, setList] = useState(null)

  useEffect(() => {
    if (!adOptions) return
    setList(adOptions.build_types)
  }, [adOptions])

  const onChangeSelect = (e) => {
    onUpdate('article.buildType', e.value)
  }
  return (
    <ColumnSet>
      <ColumnTitle>건축구조</ColumnTitle>
      <ColumnVal gap={15}>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: buildType || '선택',
            value: buildType,
          }}
          isSearchable
          options={getSimpleSelectboxOptions(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('article.buildType')}
          onChange={onChangeSelect}
        />
        <ValidateWarn>
          {errors?.article?.buildType?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnBuildingStructure
