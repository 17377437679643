import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import { List } from 'components/design'
import { ListItem } from 'components/design'
import NoteUploadExcelContainer from 'components/jmapnote/noteList/NoteUploadExcelContainer'

const SettingsExcelPage = ({ history }) => {
  const onBack = () => {
    history.go(-1)
  }

  return (
    <ExternalLayer
      title="엑셀 업로드"
      onBack={onBack}
      hiddenClose
      hasAdditionalHeader
      shift
    >
      <List>
        <ListItem>
          <NoteUploadExcelContainer />
        </ListItem>
      </List>
    </ExternalLayer>
  )
}

export default SettingsExcelPage
