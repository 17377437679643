import axios from 'axios'

/**
 *  결제위젯용 정보 요청
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getPaymentInfo = async (
  { _rx_csrf_token, order_name, amount, type, count },
  options,
) =>
  await axios.post(
    '/jmapnote/api/payment/request',
    {
      _rx_csrf_token,
      order_name,
      amount,
      type,
      count,
    },
    options,
  )

/**
 *  자동결제(빌링) 카드 등록용 정보 요청
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const requestBillingInfo = async (
  {
    _rx_csrf_token,
    order_name,
    amount,
    type,
    month,
    allinone_member_count,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/billing/request',
    {
      _rx_csrf_token,
      order_name,
      amount,
      type,
      allinone_member_count,
      months: month,
    },
    options,
  )

/**
 * 결제 목록 가져오기
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getPayments = async (
  { _rx_csrf_token, status },
  options,
) =>
  await axios.post(
    '/jmapnote/api/payment/list',
    {
      _rx_csrf_token,
      status,
    },
    options,
  )

/**
 * 결제정보 조회
 * @params contract_srl, channel_srl, status
 * @returns
 */
export const getPayment = async (
  { _rx_csrf_token, payment_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/payment/view',
    {
      _rx_csrf_token,
      payment_srl,
    },
    options,
  )

/**
 * 요금제 조회
 * @params contract_srl, channel_srl, status
 * @returns
 */
export const getPlan = async ({ _rx_csrf_token }, options) =>
  await axios.post(
    '/jmapnote/api/plan/view',
    {
      _rx_csrf_token,
    },
    options,
  )

/**
 * 요금제 변경
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const updatePlan = async (
  {
    _rx_csrf_token,
    plan_srl,
    order_name,
    amount,
    default_count,
    type,
    month,
    allinone_member_count,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/plan/update',
    {
      _rx_csrf_token,
      order_name,
      amount,
      type,
      default_count,
      plan_srl,
      months: month,
      allinone_member_count,
    },
    options,
  )

/**
 * 요금제 해지(환불) 신청
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const cancelPlan = async (
  { _rx_csrf_token, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/plan/cancel',
    {
      _rx_csrf_token,
      plan_srl,
    },
    options,
  )

/**
 * 결제 실패 시 프리 요금제로 변경
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const immediateCancelPlan = async (
  { _rx_csrf_token, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/plan/immediateCancel',
    {
      _rx_csrf_token,
      plan_srl,
    },
    options,
  )

/**
 *  카드로 등기부등본 발급권 구매
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const deunggibuPayWithCard = async (
  { _rx_csrf_token, order_name, amount, type, count },
  options,
) =>
  await axios.post(
    '/jmapnote/api/plan/pay/deunggibu',
    {
      _rx_csrf_token,
      order_name,
      amount,
      type,
      count,
    },
    options,
  )

/**
 *  환불 가능한 등기부 발급내역 조회
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getRefundableDeunggibuInfo = async (
  { _rx_csrf_token, plan_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/getRefundableDeunggibuInfo',
    {
      _rx_csrf_token,
      plan_srl,
    },
    options,
  )

/**
 *  등기부등본 발급권 환불
 * @param {*} param0
 * @param plan_srl | int
 * @param amount_refund | int | 환불할 금액
 * @param payment_srls | array | 환불할 결제내역 일련번호
 * @returns
 */
export const refundDeunggibu = async (
  { _rx_csrf_token, plan_srl, amount_refund, payment_srls },
  options,
) =>
  await axios.post(
    '/jmapnote/api/refundDeunggibu',
    {
      _rx_csrf_token,
      plan_srl,
      amount_refund,
      payment_srls,
    },
    options,
  )

/**
 * 올인원 요금제용 청구서
 */
export const getAllinoneInvoice = async ({ _rx_csrf_token, months }) =>
  await axios.post('/jmapnote/api/plan/allinone/invoice', {
    _rx_csrf_token,
    months,
  })

/**
 * 멤버 삭제 청구서
 */
export const getPlanUserRefundInvoice = async ({
  _rx_csrf_token,
  plan_srl,
  delete_member_srl,
}) =>
  await axios.post('/jmapnote/api/planuser/refundInfo', {
    _rx_csrf_token,
    plan_srl,
    delete_member_srl,
  })

/**
 * 환불 청구서
 */
export const getAllinoneRefundInvoice = async ({
  _rx_csrf_token,
  plan_srl,
  next_plan,
}) =>
  await axios.post('/jmapnote/api/plan/allinone/refundInfo', {
    _rx_csrf_token,
    plan_srl,
    next_plan,
  })
