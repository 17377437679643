import { useLayoutEffect } from 'react'
import useMoveScroll from '../useMoveScroll'

/**
 * 매물 3뎁스 탭
 */
export default function useDetailTab() {
  const tabs = {
    0: useMoveScroll('기본정보'),
    1: useMoveScroll('메모'),
    2: useMoveScroll('연락처'),
    3: useMoveScroll('건물정보'),
    4: useMoveScroll('관리비 및 옵션'),
    5: useMoveScroll('기타'),
    length: 6,
  }

  /**
   * 그냥 탭이 viewport에 들어왔는지 감지하여 class 부여
   */
  useLayoutEffect(() => {
    if (!tabs[0].element?.current) return
    observeBasic()
  }, [tabs])

  const observeBasic = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('note-tab-active-section')
          } else {
            entry.target.classList.remove('note-tab-active-section')
          }
        })
      },
      {
        rootMargin: '-50px 0px 0px 0px',
        threshold: 0.5,
      },
    )

    Array.from(tabs).forEach((item) => {
      const target = item.element.current
      if (!target) return
      observer.observe(target)
    })
  }

  return { tabs }
}
