import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledDescription = styled.div`
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 1rem;
`

const CardDescription = ({ children }) => {
  return <StyledDescription>{children}</StyledDescription>
}

export default CardDescription
