import MapContext from 'contexts/map'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useIsMobile from './useIsMobile'
import { setNoteMarkers, setNoteMarkersCount } from 'modules/note'
import { useSelector } from 'react-redux'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useAxios from './useAxios'
import { getNoteMarkers } from 'lib/api/layerApi'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

export function useNoteMarker() {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const { state } = useContext(MapContext)
  const { viewState } = state
  const [onRequest, loading, data, , ,] = useAxios(getNoteMarkers)

  const channelInfo = useSelector(
    (state) => state.channel.selectedChannelInfo,
  )
  const isShowMap = useSelector(
    (state) => state.notev2.noteListIsShowMap,
  )
  const { channelSrl } = useNoteMatch()
  const isNoteListUrl = useRouteMatch('/note/v2/notes/:channelSrl')

  const viewAllChannelNotes = useSelector(
    (state) => state.note.viewAllChannelNotes,
  )
  const filterStates = useSelector((state) => state.note.filterStates)

  const { isJMapNote } = useNoteMatch()

  const searchNoteMarkers = async (notePropertyTypes = '') => {
    if (viewState.level > 11) {
      return
    }
    if (
      !viewState.swLatlng ||
      !viewState.neLatlng ||
      !viewState.level
    ) {
      return
    }

    // 천만원 단위
    let salePrice = filterStates?.sale_price || 0
    if (filterStates.sale_price) {
      salePrice = filterStates.sale_price.map((item) => item * 1000)
    }

    const mode = localStorage.getItem('notemode')

    if (isJMapNote && !isShowMap && mode == 'sheet') {
      return
    }

    // 파라미터 세팅
    const payload = {
      swlat: viewState.swLatlng.getLat(),
      swlng: viewState.swLatlng.getLng(),
      nelat: viewState.neLatlng.getLat(),
      nelng: viewState.neLatlng.getLng(),
      level: viewState.level,
      channel_srl: channelSrl || channelInfo?.channel_srl,

      // 필터 파라미터
      status: filterStates.status,
      property_types: filterStates.property_types,
      contract_types: filterStates.contract_types.filter(
        (item) => item != '단기임대',
      ), //이전에 있었던 단기임대 완전히 무시
      sale_price: filterStates.sale_price,
      rent: filterStates.rent,
      area: filterStates.area,
      move_date: filterStates.move_date,
      floor_cnt: filterStates.floor_cnt,
      extra_options: filterStates.extra_options,
    }

    const filterChannelParam = isNoteListUrl?.isExact
      ? {}
      : { filter_channel: filterStates.filter_channel }

    // PC일 경우 무조건 전체 채널 마커들을 보여주도록 변경
    // 시트 지도 보기 모드 일 때는 채널 영향 받음
    if (!(isJMapNote && isShowMap) && !isMobile) {
      payload.channel_srl = null
    }
    if (viewAllChannelNotes) {
      payload.channel_srl = null
    }

    await onRequest(
      Object.assign(
        {},
        payload,
        {
          listing_properties: notePropertyTypes,
        },
        filterChannelParam,
      ),
    )
  }

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result) return

    const result = data.result
    if (viewState.level < 4) {
      dispatch(
        setNoteMarkers(
          result.filter(
            (item) => item.note_count && item.note_count > 0,
          ),
        ),
      )
    } else {
      dispatch(setNoteMarkers(result))
    }
    dispatch(setNoteMarkersCount(data?.total_count))
  }, [data])

  return { searchNoteMarkers }
}
