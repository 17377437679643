import { useContext, useEffect, useState } from 'react'
import MapContext from 'contexts/map'
import './MapLayer.scss'
import MapLayerIcon from './MapLayerIcon'
import useAxios from 'lib/hooks/useAxios'
import { getHousingSite } from 'lib/api/layerApi'

/**
 * 지도 레이어
 * 개발사업지구
 */
const MapLayerHousingSite = () => {
  const { kakao } = window
  const { state, actions } = useContext(MapContext)
  const { layerState, viewState, map } = state
  const [onRequest, loading, data, , ,] = useAxios(getHousingSite)
  // 폴리곤 배열 선언
  const [polygons, setPolygons] = useState([])
  // 사업지구명 배열 선언
  const [zoneNames, setZoneNames] = useState([])

  // 단계 코드별 색깔 지정
  const stepPalette = {
    RA: {
      color: '#f38787',
      name: '실시계획',
      polygonColor: '#ff6767',
      legendVisible: true,
    },
    RM: {
      color: '#f38787',
      name: '실시변경',
      polygonColor: '#ff6767',
      legendVisible: false,
    },
    SA: {
      color: '#679dff',
      name: '지구지정',
      polygonColor: '#4787ff',
      legendVisible: true,
    },
    SM: {
      color: '#679dff',
      name: '지구변경',
      polygonColor: '#4787ff',
      legendVisible: false,
    },
    DA: {
      color: '#ffc74e',
      name: '개발계획',
      polygonColor: '#ffc74e',
      legendVisible: true,
    },
    DM: {
      color: '#ffc74e',
      name: '개발변경',
      polygonColor: '#ffc74e',
      legendVisible: false,
    },
    PC: {
      color: '#3ab35e',
      name: '부분준공',
      polygonColor: '#3ab35e',
      legendVisible: true,
    },
    CP: {
      color: '#b5cbdf',
      name: '준공',
      polygonColor: '#8794a5',
      legendVisible: true,
    },
  }

  useEffect(() => {
    if (Object.keys(viewState).length === 0) {
      return
    }

    if (viewState.level > 8) return

    if (layerState.housingSite) {
      // 요청 파라미터 세팅
      const payload = {
        swlat: viewState.swLatlng.getLat(),
        swlng: viewState.swLatlng.getLng(),
        nelat: viewState.neLatlng.getLat(),
        nelng: viewState.neLatlng.getLng(),
      }
      onRequest(payload, null, false)
    } else {
      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
      // 기존 동이름 오버레이 삭제
      zoneNames.map((name) => {
        name.setMap(null)
      })
    }
  }, [layerState, viewState, map])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result || data.result?.length === 0) {
      // 기존 폴리곤 삭제
      polygons?.map((polygon) => {
        polygon.setMap(null)
      })
      return
    }

    const dongs = data.result

    // 폴리곤 배열
    let tempPolygons = []
    // 지구명 배열
    let tempZoneNames = []

    dongs.map((dong) => {
      const shpaePoints = dong.shape.coordinates

      shpaePoints.map((parts) => {
        const polygonPath = parts.map((part) => {
          return part.map((point) => {
            return new kakao.maps.LatLng(point[0], point[1])
          })
        })

        // 지도에 표시할 다각형을 생성합니다
        const polygon = new kakao.maps.Polygon({
          path: polygonPath, // 그려질 다각형의 좌표 배열입니다
          strokeWeight: 2, // 선의 두께입니다
          strokeColor:
            stepPalette[dong.step_code]?.polygonColor || 'black', // 선의 색깔입니다
          strokeOpacity: 0.7, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
          fillColor:
            stepPalette[dong.step_code]?.polygonColor || 'black', // 채우기 색깔입니다
          fillOpacity: 0.2, // 채우기 불투명도 입니다
          zIndex: 1,
        })
        // 하나씩 생성된 건물 폴리곤을 배열에 넣기
        tempPolygons.push(polygon)
      })

      if (viewState.level < 8) {
        // 지역이름을 폴리곤 위에 표시합니다
        const dongNameOverlay = new kakao.maps.CustomOverlay({
          map: map, // 커스텀오버레이를 표시할 지도입니다
          content: `<div class="housingSiteOverlay">${dong.zone_name}</div>`, // 커스텀오버레이에 표시할 내용입니다
          // clickable: true,
          xAnchor: 0,
          yAnchor: 0,
          // 커스텀오버레이를 표시할 위치입니다. 위치는 다각형의 마지막 좌표로 설정합니다
          position: new kakao.maps.LatLng(
            dong.centerpoint.coordinates[0],
            dong.centerpoint.coordinates[1],
          ),
        })
        tempZoneNames.push(dongNameOverlay)
      }
    })

    setPolygons(tempPolygons)
    setZoneNames(tempZoneNames)
  }, [data])

  // 폴리곤 세팅 및 초기화
  useEffect(() => {
    if (polygons.length === 0) return

    polygons.map((polygon) => {
      polygon.setMap(map)
    })

    return () => {
      if (polygons.length === 0) return

      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [polygons])

  // 동이름 오버레이 세팅 및 초기화
  useEffect(() => {
    if (zoneNames.length === 0) return

    zoneNames.map((dongName) => {
      dongName.setMap(map)
    })

    return () => {
      if (zoneNames.length === 0) return

      // 기존 동이름 오버레이 삭제
      zoneNames.map((dongName) => {
        dongName.setMap(null)
      })
    }
  }, [zoneNames])

  const onClickLayer = () => {
    actions.setLayerState((prev) => {
      if (!prev.housingSite) {
        // zoom레벨 5로 지정
        map.setLevel(5)
      }
      return {
        ...prev,
        housingSite: prev.housingSite ? false : true,
      }
    })
  }

  return (
    <>
      <MapLayerIcon
        type="housingSite"
        title="개발사업지구"
        onChange={onClickLayer}
      />
    </>
  )
}

export default MapLayerHousingSite
