import Axios from 'axios'

export const getNotes = async (
  payload = {
    _rx_csrf_token,
    channel_srl,
    folder_srl,
    address_srl,
    search,
  },
  options,
) => await Axios.post('/jmapnote/api/note/getNotes', payload, options)

export const getNoteWithKey = async (
  { _rx_csrf_token, noteSrl, encryptedKey },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/getNoteWithKey',
    {
      _rx_csrf_token,
      note_srl: noteSrl,
      encrypted_key: encryptedKey,
    },
    options,
  )

export const saveNote = async (
  {
    _rx_csrf_token,
    channelSrl,
    folderSrl,
    addressSrl,
    form,
    propertyType,
  },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/save',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      property_type: propertyType,
      ...form,
    },
    options,
  )

export const modifyNoteAll = async (
  { _rx_csrf_token, channelSrl, folderSrl, addressSrl, noteSrl, form },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/modify',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
      ...form,
    },
    options,
  )

export const modifyNote = async (
  {
    _rx_csrf_token,
    channelSrl,
    folderSrl,
    addressSrl,
    noteSrl,
    addressId,
    lastUpdate,
    dong,
    ho,
    form,
  },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/modify',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      address_id: addressId,
      note_srl: noteSrl,
      last_update: lastUpdate,
      dong,
      ho,
      specified: {
        ...form,
      },
    },
    options,
  )

export const removeNote = async (
  { _rx_csrf_token, channelSrl, folderSrl, addressSrl, noteSrl },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/remove',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
    },
    options,
  )

export const copyNote = async (
  {
    _rx_csrf_token,
    toChannelSrl,
    fromChannelSrl,
    toFolderSrl,
    fromFolderSrl,
    addressSrl,
    noteSrl,
  },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/copy',
    {
      _rx_csrf_token,
      to_channel_srl: toChannelSrl,
      from_channel_srl: fromChannelSrl,
      to_folder_srl: toFolderSrl,
      from_folder_srl: fromFolderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
    },
    options,
  )

export const moveNote = async (
  {
    _rx_csrf_token,
    toChannelSrl,
    fromChannelSrl,
    toFolderSrl,
    fromFolderSrl,
    addressSrl,
    noteSrl,
  },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/move',
    {
      _rx_csrf_token,
      to_channel_srl: toChannelSrl,
      from_channel_srl: fromChannelSrl,
      to_folder_srl: toFolderSrl,
      from_folder_srl: fromFolderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
    },
    options,
  )

export const sortNote = async (
  { _rx_csrf_token, channelSrl, folderSrl, addressSrl, noteSrl },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/sort',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
    },
    options,
  )

export const getOptions = async (payload, options) =>
  await Axios.get('/jmapnote/api/note/getOptions', options)

export const getNote = async (
  { _rx_csrf_token, channelSrl, folderSrl, addressSrl, noteSrl },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/getNote',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
    },
    options,
  )

export const getBuildingInfo = async (
  { _rx_csrf_token, channelSrl, folderSrl, addressSrl, dong, ho },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/note/getBuildingInfo',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      dong,
      ho,
    },
    options,
  )

export const getPolygon = async (
  { _rx_csrf_token, address_srl },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/address/getPolygon',
    {
      _rx_csrf_token,
      address_srl,
    },
    options,
  )

export const searchDeunggibu = async (
  { _rx_csrf_token, sub_type, address, dongho },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/doc/deunggibu/search',
    {
      _rx_csrf_token,
      sub_type,
      address,
      dongho,
    },
    options,
  )

/**
 * 주택부동산 노출 여부 업데이트
 */
export const bdsStateUpdate = async (
  { _rx_csrf_token, note_srl, address_srl },
  options,
) =>
  await Axios.post(
    '/jmapnote/api/realestate/convert',
    {
      _rx_csrf_token,
      note_srl,
      address_srl,
    },
    options,
  )
