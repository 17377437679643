import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper.scss' // core Swiper
import { getJootekServiceURL } from 'utils'

const SummaryMaster = ({ data }) => {
  return (
    <a href={`${getJootekServiceURL('/')}${data.user_id}`}>
      <Profile>
        <ProfileImg src={data.profile_image?.src} />
        <Info>
          <SubTitle>
            <span>{data.company_work_area}</span>
            <span>{data.career_year}년차</span>
            <span>프로젝트 {data.project_count}개</span>
          </SubTitle>
          <Title>{data.company_name}</Title>
          <Desc>{data.company_intro}</Desc>
        </Info>
      </Profile>
      {data.projects && (
        <StyledSwiper slidesPerView={'auto'} spaceBetween={12}>
          {data.projects.map((img, i) => {
            return (
              <SwiperSlide
                key={i}
                style={{
                  width: '100px',
                }}
              >
                <Img src={img} />
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
      )}
    </a>
  )
}

const StyledSwiper = styled(Swiper)`
  margin-top: 28px;
  overflow: visible;
`

const Profile = styled.div`
  display: flex;
  gap: 12px;
`

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 14px;
  border: 1px solid ${theme.colors.gray[200]};
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const SubTitle = styled.div`
  font-size: 12px;
  color: ${theme.colors.gray[600]};
  font-weight: 500;
  line-height: 160%;

  span {
    &:not(:last-child) {
      &::after {
        content: '·';
        margin: 0 2px;
      }
    }
  }
`

const Title = styled.div`
  margin: 4px 0;
  font-weight: 700;
  line-height: 160%;
`

const Desc = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.gray[500]};
  line-height: 160%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Img = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 14px;
`

export default SummaryMaster
