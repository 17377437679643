import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
} from '../../form/NoteAdFormStyle'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { Button } from 'components/design'
import { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { message } from 'antd'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import useAxios from 'lib/hooks/useAxios'
import { uploadFiles } from 'lib/api/notev2/adForm'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import update from 'immutability-helper'
import NoteAdColumnPicturesItem from './NoteAdColumnPicturesItem'
import NoteAdPicturesGuide from './NoteAdPicturesGuide'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 상세정보 매물사진
 */
const NoteAdColumnPictures = () => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const { watch } = useFormContext()

  const inputRef = useRef()

  const [fileList, setFileList] = useState([]) //업로드된 파일 목록
  const [deletedFileSrl, setDeletedFileSrl] = useState([]) //삭제된 file srl array (보여주기용)
  const [guideModalVisible, setGuideModalVisible] = useState(false) //이미지 등록 가이드 모달

  const [onRequest, , data] = useAxios(uploadFiles) //업로드 api

  const adSrl = watch('ad_srl')
  const photoList = watch('photoList')

  const isDisabled =
    adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll //읽기 전용 여부

  useEffect(() => {
    if (!photoList || photoList.length == 0) return
    setFileList(photoList)
  }, [photoList])

  /**
   * 업로드 버튼 클릭 시 input type="file" 태그 클릭
   */
  const onClickUpload = () => {
    inputRef?.current?.click()
  }
  /**
   * 파일 업로드
   * @param {*} e
   */
  const onChangeImageInput = (e) => {
    if (!e.target.files?.[0]) {
      return
    }

    if (e.target.files?.[0]?.size > 1048576 * 10) {
      message.error(
        '파일 크기가 허용되는 한도 10MB를 초과하여 파일을 업로드할 수 없습니다.',
      )
      return
    }

    const uploadFiles = Array.from(e.target.files)

    uploadFiles.forEach((item, i) => {
      onRequest({
        ad_srl: adSrl,
        fileToUpload: e.target.files[i],
        type: 'photo',
        plan_srl: planInfo.allinone_plan_srl,
      })
    })
  }

  /**
   * 파일 업로드 결과
   */
  useEffect(() => {
    if (!data) return

    setFileList((prev) => [
      ...prev,
      ...[
        {
          file_srl: data.file_srl,
          ad_srl: data.ad_srl,
          url: data.url,
          thumbnail: data.thumbnail,
        },
      ],
    ])
  }, [data])

  /**
   * 사진 순서 옮기기
   */
  const moveItem = useCallback((dragIndex, hoverIndex) => {
    setFileList((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  /**
   * 이미지 등록 가이드 모달 버튼
   */
  const onClickGuideModal = () => {
    setGuideModalVisible(true)
  }

  /**
   * 이미지 등록 가이드 모달 닫기
   */
  const onCancelGuideModal = () => {
    setGuideModalVisible(false)
  }

  return (
    <>
      <ColumnSet>
        <ColumnTitle>매물사진</ColumnTitle>
        <ColumnVal>
          <Headers>
            <Desc>
              <i className="fas fa-arrows-alt"></i>사진을 끌어서 순서를
              변경할 수 있습니다.
            </Desc>
            <ButtonWrapper>
              {!isDisabled && (
                <StyledButton
                  size="small"
                  color="blue"
                  onClick={onClickUpload}
                >
                  <i className="far fa-paperclip"></i>
                  사진 첨부 <span>하기</span>
                </StyledButton>
              )}
              <StyledButton
                size="small"
                color="gray"
                onClick={onClickGuideModal}
              >
                <HelpIcon className="fad fa-question-circle" />
                <span>이미지&nbsp;</span>등록 가이드
              </StyledButton>
            </ButtonWrapper>
            {isDisabled && fileList.length == 0 && (
              <NoData>등록된 매물 사진이 없습니다.</NoData>
            )}
          </Headers>
          <Pictures>
            <DndProvider
              options={{ enableMouseEvents: true }}
              backend={
                window.innerWidth > theme.bp.medium
                  ? HTML5Backend
                  : TouchBackend
              }
            >
              {fileList.map(({ thumbnail, url, file_srl }, i) => {
                return (
                  <NoteAdColumnPicturesItem
                    fileList={fileList}
                    moveItem={moveItem}
                    thumbnail={thumbnail}
                    url={url}
                    key={i}
                    index={i}
                    file_srl={file_srl}
                    deletedFileSrl={deletedFileSrl}
                    setDeletedFileSrl={setDeletedFileSrl}
                    setFileList={setFileList}
                  />
                )
              })}
            </DndProvider>
            {!isDisabled && (
              <PlusImg onClick={onClickUpload}>
                <i className="far fa-plus"></i>
              </PlusImg>
            )}
            <input
              multiple
              type="file"
              style={{ display: 'none' }}
              name="profile_image"
              accept="image/*"
              onChange={onChangeImageInput}
              ref={inputRef}
            />
          </Pictures>
          <FormDesc multiple>
            <li>
              네이버 부동산에서 대표 이미지 및 사진의 노출 순서는 네이버
              부동산 자체 AI 자동 검수를 통해서 정해지는 경우가
              있습니다.
            </li>
            <li>첫번째로 클릭한 사진이 대표 이미지로 등록됩니다.</li>
            <li>
              네이버 부동산에서 보유하고 있는 건물정보(사진)가 있다면
              자동으로 노출됩니다. 노출 항목에 대한 노출 여부는 선택할
              수 없으며 사진 제외도 불가합니다.
            </li>
          </FormDesc>
        </ColumnVal>
      </ColumnSet>
      <NoteAdPicturesGuide
        onCancel={onCancelGuideModal}
        visible={guideModalVisible}
      />
    </>
  )
}

const Headers = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  width: 100%;

  i {
    margin-right: 5px;
  }
`

const HelpIcon = styled.i`
  color: ${theme.colors.gray[500]};
`

const Pictures = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
`

const PlusImg = styled.div`
  position: relative;
  display: inline-block;
  width: calc(20% - 4px);
  cursor: pointer;
  background-color: ${theme.colors.gray[100]};
  height: 100px;

  div {
    display: inline-block;
    width: 100%;
  }

  ${media.mediumM`
    width: calc(25% - 9px);
  `}

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledButton = styled(Button)`
  flex-shrink: 0;
  span {
    margin: 0 !important;
  }

  ${media.mediumS`
    span {
      display: none;
    }
  `}
`

const NoData = styled.div`
  margin-bottom: 12px;
  font-size: 14px;
  color: ${theme.colors.gray[700]};
`

const Desc = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: inherit;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;

  i {
    font-weight: 400;
    color: ${theme.colors.blue[400]};
    font-size: 14px;
  }
`

export default NoteAdColumnPictures
