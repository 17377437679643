import { Fragment } from 'react'
import SummaryLobFolioButton from './SummaryLobFolioButton'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { dateFormat } from 'utils'
import { CardTitle } from 'components/design'

/**
 * 건축물 대장 타임라인
 * @param {*} selectedBuilding 선택된 건물 정보
 * @returns
 */
const SummaryLobTimeLine = ({ selectedBuilding }) => {
  return (
    selectedBuilding?.timeline && (
      <Wrapper>
        <TitleWrapper>
          <CardTitle>허가내역</CardTitle>
        </TitleWrapper>
        <StyledTimeline>
          {selectedBuilding.timeline.map((item, index) => (
            <li key={index}>
              <TimelineLabel>{dateFormat(item.date)}</TimelineLabel>
              {item.type}
              {item.folios &&
                item.folios.map((folio, i) => (
                  <Fragment key={i}>
                    <StyledTagUl key={i}>
                      <StyledTagLi>
                        {folio.company_num && (
                          <SummaryLobFolioButton
                            disabled={!folio.jootekfolio_srl}
                            label={folio.category}
                            srl={folio.jootekfolio_srl}
                          >
                            {folio.company_name}
                          </SummaryLobFolioButton>
                        )}
                      </StyledTagLi>
                    </StyledTagUl>
                  </Fragment>
                ))}
            </li>
          ))}
        </StyledTimeline>
      </Wrapper>
    )
  )
}

const Wrapper = styled.div`
  border-top: 1px solid ${theme.colors.gray[100]};
`

const StyledTimeline = styled.ul`
  list-style-type: none;

  & > li {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0 8px 16px;
    margin-left: 8px;

    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 1px solid ${theme.colors.gray[200]};
    }
    &::after {
      content: '';
      position: absolute;
      top: 15px;
      left: -4px;

      width: 9px;
      height: 9px;
      border-radius: 100%;
      background-color: ${theme.colors.gray[500]};
    }

    &:first-child {
      &::after {
        left: -6px;
        width: 13px;
        height: 13px;
        background-color: ${theme.colors.base.white};
        border: 2px solid ${theme.colors.gray[900]};
      }
      &::before {
        top: 21px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
`
const TimelineLabel = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  color: ${theme.colors.gray[900]};
  font-weight: 700;
  margin-right: 16px;
  font-variant-numeric: tabular-nums;
`

const StyledTagUl = styled.div`
  width: 100%;
  margin-top: 4px;
`
const StyledTagLi = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  cursor: pointer;

  & > * {
    margin-bottom: 8px;
  }
`

const TitleWrapper = styled.div`
  margin: 24px 0 18px 0;
`

export default SummaryLobTimeLine
