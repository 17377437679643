import { useEffect, useState } from 'react'
import SummaryAmenitiesContainer from './SummaryAmenitiesContainer'
import SummarySchoolContainer from './SummarySchoolContainer'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'

const SummarySchoolAmenityContainer = ({
  aptTabs,
  basicTabs,
  noTitle,
  addressId,
  latitude,
  longitude,
  isApt,
}) => {
  const [prevMarker, setPrevMarker] = useState(null)
  const [activeValue, setActiveValue] = useState(null)

  const summaryIsApt = useSelector((state) => state.summary.isApt)
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const location = useLocation()

  /**
   * 클릭한 것 active 상태 만들기
   */
  const onClickSetActiveValue = (value) => {
    setActiveValue(value)
  }

  /**
   * 클릭한 것 리셋
   */
  const resetActiveValue = () => {
    setActiveValue(null)
  }

  /**
   * 5초후 마커 제거
   */
  useEffect(() => {
    if (!prevMarker) return
    const timer = setTimeout(() => {
      if (prevMarker) prevMarker.setMap(null)
      setActiveValue(null)
    }, 5000)

    return () => {
      clearTimeout(timer)
      setActiveValue(null)
    }
  }, [prevMarker])

  return (
    <>
      <SummarySchoolContainer
        noTitle={noTitle}
        prevMarker={prevMarker}
        setPrevMarker={setPrevMarker}
        activeValue={activeValue}
        onClickSetActiveValue={onClickSetActiveValue}
        resetActiveValue={resetActiveValue}
        ref={{
          apt: aptTabs?.[2].element,
          basic: basicTabs?.[3].element,
        }}
        addressId={
          location.pathname.indexOf('bds') > -1
            ? addressId
            : summaryInfo?.land?.id
        }
        latitude={
          location.pathname.indexOf('bds') > -1
            ? latitude
            : summaryInfo?.land?.latitude
        }
        longitude={
          location.pathname.indexOf('bds') > -1
            ? longitude
            : summaryInfo?.land?.longitude
        }
        isApt={
          location.pathname.indexOf('bds') > -1 ? isApt : summaryIsApt
        }
      />
      <SummaryAmenitiesContainer
        noTitle={noTitle}
        prevMarker={prevMarker}
        setPrevMarker={setPrevMarker}
        activeValue={activeValue}
        onClickSetActiveValue={onClickSetActiveValue}
        resetActiveValue={resetActiveValue}
        ref={{
          apt: aptTabs?.[3].element,
          basic: basicTabs?.[4].element,
        }}
        addressId={
          location.pathname.indexOf('bds') > -1
            ? addressId
            : summaryInfo?.land?.id
        }
        latitude={
          location.pathname.indexOf('bds') > -1
            ? latitude
            : summaryInfo?.land?.latitude
        }
        longitude={
          location.pathname.indexOf('bds') > -1
            ? longitude
            : summaryInfo?.land?.longitude
        }
        isApt={
          location.pathname.indexOf('bds') > -1 ? isApt : summaryIsApt
        }
      />
    </>
  )
}

export default SummarySchoolAmenityContainer
