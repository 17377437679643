import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import theme from 'lib/styles/theme'
import { useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import { Star } from '../NoteDonghoStyle'
import { Tag } from 'components/design'
import { ScrollStyle } from 'components/jmapnotev2/style/NoteStyle'

const NoteDonghoDanjiSearchList = ({
  addrList,
  onClickAddress,
  resetSearch,
}) => {
  const ref = useRef(null)

  useOnClickOutside(ref, (e) => {
    resetSearch()
  })

  return (
    addrList &&
    addrList.length > 0 && (
      <List ref={ref}>
        {addrList.map(
          (
            {
              id,
              address_id,
              apt_name,
              address,
              jdongho_danji_id,
              is_paid,
              type,
            },
            i,
          ) => {
            return (
              <ListItem
                key={i}
                onClick={() => onClickAddress(address_id, id, apt_name)}
              >
                <Apt paid={is_paid}>
                  {is_paid == 'Y' && (
                    <StyledTag size="small" color="blue">
                      플러스
                    </StyledTag>
                  )}
                  {is_paid == 'N' && jdongho_danji_id && (
                    <Star className="fas fa-star" />
                  )}
                  {apt_name}
                </Apt>
                <Address>
                  <TypeTag color="gray" size="small">
                    {type}
                  </TypeTag>
                  {address || '주소 정보 없음'}
                </Address>
              </ListItem>
            )
          },
        )}
      </List>
    )
  )
}

const List = styled.div`
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  max-height: calc(64 * 5px);
  border: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.base.white};
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;
  overflow-y: auto;
  cursor: pointer;

  ${ScrollStyle}
`

const ListItem = styled.div`
  padding: 14px;
  border-bottom: 1px solid ${theme.colors.gray[200]};

  &:hover {
    background-color: ${theme.colors.blue[50]};
  }
`

const Apt = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;

  gap: ${({ paid }) => (paid ? `6px` : `4px`)};
`

const Address = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
`

const StyledTag = styled(Tag)`
  font-size: 10px;
  font-weight: 800;
  line-height: 120%;
`

const TypeTag = styled.div`
  padding: 3px;
  font-size: 10px;
  font-weight: 600;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  background-color: ${theme.colors.gray[100]};
`

export default NoteDonghoDanjiSearchList
