import { Button } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  TableContent,
  InnerTable,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  innerSubHeaderWidth,
  StyledTable,
  SectionSpace,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { Description } from 'components/jmapnotev2/style/NoteStyle'
import { getBrokerRate } from 'lib/api/notev2/contractApi'
import useEditContract from 'lib/hooks/notev2/useEditContract'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import {
  getOnlyRemoveCommaNumber,
  getVatRatio,
  handleFocusNumber,
} from 'lib/utils/notev2/util'
import moment from 'moment'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsPay13 = ({ onUpdate }) => {
  const { setValue, getValues, watch } = useFormContext()

  const [onEditContract, , data] = useEditContract()
  const [onRequestCalc, , dataCalc] = useAxios(getBrokerRate)

  const total_price = watch('total_price')
  const d_descript_type = watch('d_descript_type')
  const d_junggaebosu = watch('d_junggaebosu') || 0
  const d_junggaebosu_vat = watch('d_junggaebosu_vat') || 0
  const d_silbi = watch('d_silbi') || 0
  const d_total_price = watch('d_total_price')
  const d_junggaebosu_payment = watch('d_junggaebosu_payment')
  const d_junggaebosu_vat_ratio1 = watch('d_junggaebosu_vat_ratio1')
  const d_junggaebosu_vat_ratio2 = watch('d_junggaebosu_vat_ratio2')
  const d_junggaebosu_cal = watch('d_junggaebosu_cal') || total_price
  const d_junggaebosu_cal_ratio = watch('d_junggaebosu_cal_ratio')
  const d_silbi_cal = watch('d_silbi_cal')
  const contract_srl = watch('contract_srl')
  const channel_srl = watch('channel_srl')
  const gyobu_date = watch('gyobu_date')

  const d_junggaebosu_vat_gani = watch('d_junggaebosu_vat_gani')
  const d_junggaebosu_vat_gani2 = watch('d_junggaebosu_vat_gani2')

  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑮'
    } else if (d_descript_type == '비주거용 건축물') {
      return '⑪'
    } else if (d_descript_type == '토지') {
      return '⑨'
    }
  }

  /**
   * 왼쪽 중개보수 변경 시 부가세 세팅
   * @param {*} e
   */
  const onBlurJunggaebosu = (e) => {
    //부가세 계산
    const value = getOnlyRemoveCommaNumber(e.target.value)

    const getPercent = {
      '일반(10%)': 0.1,
      '간이(4%)': 0.04,
      간이: d_junggaebosu_vat_gani / 100,
    }

    const percent = getPercent[d_junggaebosu_vat_ratio1] || 0

    //부가세 지정 안했으면 계산 X
    if (!percent || percent == 0) {
      onUpdate('d_junggaebosu', value)
      const sum =
        Number(value) + Number(d_junggaebosu_vat) + Number(d_silbi) //계

      onUpdate('d_total_price', sum)
      return
    }

    const vat = value * percent
    const sum = Number(value) + Number(vat) + Number(d_silbi) //계

    //부가세, 계 업데이트
    onEditContract({
      data: getValues(),
      updateData: {
        d_total_price: sum, //계
        d_junggaebosu_vat: vat, //부가세(왼쪽)
      },
    })
    setValue('d_total_price', sum) //계
    setValue('d_junggaebosu_vat', vat) //부가세(왼쪽)

    //중개보수 값 업데이트
    onUpdate('d_junggaebosu', value)
  }

  /**
   * 부가가치세 라디오 클릭
   * 부가가치세와 계 값을 계산해서 동시 저장
   * @param {*} value
   * @param {*} percent
   */
  const onClickRatio = (value, percent) => {
    if (value == '간이') {
      //'간이'는 사용자 입력 값 옵션이므로 당장 비율 값을 알 수 없다. 그래서 '간이'라는 값만 해당 컬럼에 저장
      onUpdate('d_junggaebosu_vat_ratio1', value)
    } else {
      //라디오를 선택함과 동시에 값이 정해지기 때문에 중개보수에 비율을 바로 곱해서 저장
      const vat = d_junggaebosu * percent
      const sum = Number(d_junggaebosu) + Number(vat) + Number(d_silbi) //계

      onEditContract({
        data: getValues(),
        updateData: {
          d_total_price: sum, //계
          d_junggaebosu_vat_ratio1: value, //라디오 값(오른쪽)
          d_junggaebosu_vat: vat, //부가세(왼쪽)
          d_junggaebosu_vat_gani: '', //부가세 간이 사용자 입력 값 초기화
        },
      })

      setValue('d_total_price', sum) //계
      setValue('d_junggaebosu_vat_ratio1', value) //라디오 값(오른쪽)
      setValue('d_junggaebosu_vat', vat) //부가세(왼쪽)
      setValue('d_junggaebosu_vat_gani', '') //부가세 간이 사용자 입력 값 초기화
    }
  }

  //요율 blur
  const onBlurJunggaebosuCalRatio = (e) => {
    const value = getOnlyRemoveCommaNumber(e.target.value)

    //백엔드 중개보수 api 호출
    onRequestCalc({
      channel_srl,
      contract_srl,
      base_amount: d_junggaebosu_cal,
      rate: value,
      total_price: total_price,
    })
  }

  /**
   * 중개보수 계산 결과값
   */
  useEffect(() => {
    if (!dataCalc || !dataCalc?.result) return

    /**
     * total = d_junggaebosu // 왼쪽 중개보수
        base_amount = d_junggaebosu_cal // 오른쪽 중개보수
        percent = d_junggaebosu_cal_ratio // 요율
    */
    const result = dataCalc.result

    updateData({
      d_junggaebosu: result.total,
      d_junggaebosu_cal: result.base_amount,
      d_junggaebosu_cal_ratio: result.percent,
    })
  }, [dataCalc])

  /**
   *
   * @param {*} d_junggaebosu 왼쪽 중개보수
   * @param {*} d_junggaebosu_cal 오른쪽 중개보수
   * @param {*} d_junggaebosu_cal_ratio 요율
   */
  const updateData = ({
    d_junggaebosu,
    d_junggaebosu_cal,
    d_junggaebosu_cal_ratio,
  }) => {
    const vatRatio = d_junggaebosu_vat_gani
      ? d_junggaebosu_vat_gani / 100
      : getVatRatio(d_junggaebosu_vat_ratio1) //부가세 비율(간이에 임시로 작성했으면 그 값을 사용)
    const vat = d_junggaebosu * vatRatio //부가세
    const sum = Number(d_junggaebosu) + Number(vat) + Number(d_silbi)

    onEditContract({
      data: getValues(),
      updateData: {
        d_junggaebosu, // 왼쪽 중개보수
        d_junggaebosu_cal, // 오른쪽 중개보수
        d_junggaebosu_cal_ratio, //요율
        d_junggaebosu_vat: vat, //부가세
        d_total_price: sum, //계
        realestate_reward: d_junggaebosu, //계약서 하단 중개보수는 __ 로 한다
      },
    })

    setValue('d_junggaebosu', d_junggaebosu) // 왼쪽 중개보수
    setValue('d_junggaebosu_cal', d_junggaebosu_cal) // 오른쪽 중개보수
    setValue('d_junggaebosu_cal_ratio', d_junggaebosu_cal_ratio) //요율
    setValue('d_junggaebosu_vat', vat) //부가세
    setValue('d_total_price', sum) //계
    setValue('realestate_reward', d_junggaebosu) //계약서 하단 중개보수는 __ 로 한다
  }

  /**
   * 부가세 blur
   * 부가세와 계 값을 계산해서 동시 저장
   */
  const onBlurJunggaeVat = (e) => {
    const vat = getOnlyRemoveCommaNumber(e.target.value)
    const sum = Number(d_junggaebosu) + Number(vat) + Number(d_silbi)
    onEditContract({
      data: getValues(),
      updateData: {
        d_total_price: sum, //계
        d_junggaebosu_vat: vat, //부가세
      },
    })

    setValue('d_total_price', sum) //계
    setValue('d_junggaebosu_vat', vat) //부가세
  }

  /**
   * 실비 blur
   * 실비와 계 값을 계산해서 동시 저장
   */
  const onBlurJunggaeSilbi = (e) => {
    const silbi = getOnlyRemoveCommaNumber(e.target.value)
    const sum =
      Number(d_junggaebosu) + Number(d_junggaebosu_vat) + Number(silbi)
    onEditContract({
      data: getValues(),
      updateData: {
        d_total_price: sum, //계
        d_silbi: silbi,
      },
    })

    setValue('d_total_price', sum)
    setValue('d_silbi', silbi)
  }

  /**
   * 부가세 간이 사용자 설정
   */
  const onBlurCustomGani = (e) => {
    const percent = getOnlyRemoveCommaNumber(e.target.value)
    const vat = Math.round(d_junggaebosu * (percent / 100))
    const sum = Number(d_junggaebosu) + Number(vat) + Number(d_silbi) //계

    onEditContract({
      data: getValues(),
      updateData: {
        d_total_price: sum, //계
        d_junggaebosu_vat_gani: percent,
        d_junggaebosu_vat: vat,
      },
    })

    setValue('d_total_price', sum)
    setValue('d_junggaebosu_vat_gani', percent)
    setValue('d_junggaebosu_vat', vat)
  }

  return (
    <>
      <StyledTable>
        <Row height={64} rowspan={5} noborderbottom>
          <TableHeader width={headerWidth}>
            {getNumber()} 중개보수
            <br />및 실비의
            <br />
            금액과
            <br />
            산출내역
          </TableHeader>
          <RowSpanInner
            rowspanheightpercent={100}
            rowspan={5}
            minuswidth={headerWidth}
            noborderbottom
          >
            <TableContent fullwidth nopadding noborderright>
              <InnerTable>
                <Row heightpercent={100} noborderbottom>
                  <InnerTableBlock widthpercent={50} noborderbottom>
                    <Row rowspan={5} heightpercent={100} noborderbottom>
                      <RowSpanInner fullwidth rowspan={5}>
                        <TableSubHeader width={innerSubHeaderWidth}>
                          중개보수
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <ColorInput
                            defaultValue={
                              d_junggaebosu > 0 ? d_junggaebosu : null
                            }
                            key={d_junggaebosu}
                            onBlur={onBlurJunggaebosu}
                            append="원"
                            onClick={handleFocusNumber}
                            fullWidth
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                      <RowSpanInner fullwidth rowspan={5}>
                        <TableSubHeader width={innerSubHeaderWidth}>
                          부가세
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <ColorInput
                            defaultValue={
                              d_junggaebosu_vat > 0
                                ? d_junggaebosu_vat
                                : null
                            }
                            key={d_junggaebosu_vat}
                            onBlur={onBlurJunggaeVat}
                            append="원"
                            onClick={handleFocusNumber}
                            fullWidth
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                      <RowSpanInner fullwidth rowspan={5}>
                        <TableSubHeader width={innerSubHeaderWidth}>
                          실비
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <ColorInput
                            defaultValue={d_silbi > 0 ? d_silbi : null}
                            onBlur={onBlurJunggaeSilbi}
                            append="원"
                            onClick={handleFocusNumber}
                            fullWidth
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                      <RowSpanInner fullwidth rowspan={5}>
                        <TableSubHeader width={innerSubHeaderWidth}>
                          계
                        </TableSubHeader>
                        <InnerTableContent
                          headerwidth={innerSubHeaderWidth}
                        >
                          <ColorInput
                            defaultValue={
                              d_total_price > 0 ? d_total_price : null
                            }
                            key={d_total_price}
                            onBlur={(e) =>
                              onUpdate(
                                'd_total_price',
                                getOnlyRemoveCommaNumber(
                                  e.target.value,
                                ),
                              )
                            }
                            append="원"
                            onClick={handleFocusNumber}
                            fullWidth
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                      <RowSpanInner
                        noborderbottom
                        fullwidth
                        rowspan={5}
                      >
                        <TableSubHeader width={innerSubHeaderWidth}>
                          지급시기
                        </TableSubHeader>
                        <InnerTableContent
                          noborderbottom
                          headerwidth={innerSubHeaderWidth}
                        >
                          <ColorInput
                            type="text"
                            defaultValue={d_junggaebosu_payment}
                            onBlur={(e) =>
                              onUpdate(
                                'd_junggaebosu_payment',
                                e.target.value,
                              )
                            }
                            fullWidth
                          />
                        </InnerTableContent>
                      </RowSpanInner>
                    </Row>
                  </InnerTableBlock>
                  <InnerTableBlock
                    widthpercent={50}
                    noborderbottom
                    noborderright
                  >
                    <DetailsWrapper>
                      <DetailsTitle>&#60;산출내역&#62;</DetailsTitle>

                      <VatContainer>
                        <StyledButton size="small" color="gray">
                          <a
                            href="https://land.seoul.go.kr:444/land/broker/brokerageCommission.do#"
                            target="_blank"
                          >
                            중개보수 요율표 바로가기
                          </a>
                        </StyledButton>
                        <Vat>
                          <p>중개보수 부가가치세</p>
                          <VatWrapper>
                            <div>
                              <span>공인중개사</span>
                              <RadioButton
                                label="일반(10%)"
                                checked={
                                  d_junggaebosu_vat_ratio1 ==
                                  '일반(10%)'
                                }
                                name="d_junggaebosu_vat_ratio1"
                                onChange={() =>
                                  onClickRatio('일반(10%)', 0.1)
                                }
                              />
                              <RadioButton
                                label="간이(4%)"
                                checked={
                                  d_junggaebosu_vat_ratio1 == '간이(4%)'
                                }
                                name="d_junggaebosu_vat_ratio1"
                                onChange={() =>
                                  onClickRatio('간이(4%)', 0.04)
                                }
                              />
                              <CustomWrapper>
                                <RadioButton
                                  label="간이"
                                  checked={
                                    d_junggaebosu_vat_ratio1 == '간이'
                                  }
                                  name="d_junggaebosu_vat_ratio1"
                                  onChange={() => onClickRatio('간이')}
                                />
                                <ColorInput
                                  type="number"
                                  width={50}
                                  defaultValue={d_junggaebosu_vat_gani}
                                  key={d_junggaebosu_vat_gani}
                                  disabled={
                                    d_junggaebosu_vat_ratio1 != '간이'
                                  }
                                  onBlur={onBlurCustomGani}
                                />
                                %
                              </CustomWrapper>
                            </div>
                            <div>
                              <span>공동중개사</span>
                              <RadioButton
                                label="일반(10%)"
                                checked={
                                  d_junggaebosu_vat_ratio2 ==
                                  '일반(10%)'
                                }
                                name="d_junggaebosu_vat_ratio2"
                                onChange={(e) =>
                                  onUpdate(
                                    'd_junggaebosu_vat_ratio2',
                                    '일반(10%)',
                                  )
                                }
                              />
                              <RadioButton
                                label="간이(4%)"
                                checked={
                                  d_junggaebosu_vat_ratio2 == '간이(4%)'
                                }
                                name="d_junggaebosu_vat_ratio2"
                                onChange={(e) =>
                                  onUpdate(
                                    'd_junggaebosu_vat_ratio2',
                                    '간이(4%)',
                                  )
                                }
                              />
                              <CustomWrapper>
                                <RadioButton
                                  label="간이"
                                  checked={
                                    d_junggaebosu_vat_ratio2 == '간이'
                                  }
                                  name="d_junggaebosu_vat_ratio2"
                                  onChange={(e) =>
                                    onUpdate(
                                      'd_junggaebosu_vat_ratio2',
                                      '간이',
                                    )
                                  }
                                />
                                <ColorInput
                                  type="number"
                                  width={50}
                                  defaultValue={d_junggaebosu_vat_gani2}
                                  disabled={
                                    d_junggaebosu_vat_ratio2 != '간이'
                                  }
                                  onBlur={(e) => {
                                    onUpdate(
                                      'd_junggaebosu_vat_gani2',
                                      e.target.value,
                                    )
                                  }}
                                />
                                %
                              </CustomWrapper>
                            </div>
                          </VatWrapper>
                        </Vat>
                      </VatContainer>
                      <PriceWrapper>
                        <span>중개보수:</span>
                        <ColorInput
                          fullWidth
                          type="number"
                          key={d_junggaebosu_cal}
                          defaultValue={
                            d_junggaebosu_cal > 0
                              ? d_junggaebosu_cal
                              : null
                          }
                          readOnly={true}
                        />
                        X
                        <ColorInput
                          fullWidth
                          type="text"
                          key={d_junggaebosu_cal_ratio}
                          defaultValue={
                            d_junggaebosu_cal_ratio > 0
                              ? d_junggaebosu_cal_ratio
                              : null
                          }
                          onBlur={onBlurJunggaebosuCalRatio}
                        />
                        %
                      </PriceWrapper>
                      <PriceWrapper>
                        <span>실비내역:</span>
                        <ColorInput
                          fullWidth
                          type="text"
                          defaultValue={d_silbi_cal}
                          onBlur={(e) =>
                            onUpdate('d_silbi_cal', e.target.value)
                          }
                        />
                      </PriceWrapper>
                      {d_descript_type == '주거용 건축물' && (
                        <DetailsDescription color="black">
                          ※ 중개보수는 시ㆍ도 조례로 정한 요율한도에서
                          중개의뢰인과 개업공인중개사가 서로 협의하여
                          결정하며 부가가치세는 별도로 부과될 수 있습
                          니다.
                        </DetailsDescription>
                      )}

                      {d_descript_type == '토지' && (
                        <DetailsDescription color="black">
                          ※ 중개보수는 거래금액의 1천분의 9 이내에서
                          중개의뢰인 과 개업공인중개사가 서로 협의하여
                          결정하며, 부 가가치세는 별도로 부과될 수
                          있습니다.
                        </DetailsDescription>
                      )}
                    </DetailsWrapper>
                  </InnerTableBlock>
                </Row>
              </InnerTable>
            </TableContent>
          </RowSpanInner>
        </Row>
      </StyledTable>
      <Description color="black">
        「공인중개사법」 제25조제3항 및 제30조제5항에 따라 거래당사자는
        개업공인중개사로부터 위 중개대상물에 관한 확인ㆍ설명 및
        손해배상책임의 보장에 관한 설명을 듣고, 같은 법 시행령
        제21조제3항에 따른 본 확인ㆍ설명서와 같은 법 시행령
        제24조제2항에 따른 손해배상책임 보장 증명서류&#40;사본 또는
        전자문서&#41;를 수령합니다.
      </Description>
      <NowDate>
        <ColorInput
          type="date"
          value={gyobu_date}
          selected={gyobu_date ? moment(gyobu_date).toDate() : ''}
          onChange={(e) =>
            onUpdate(
              'gyobu_date',
              e ? moment(e).format('YYYY-MM-DD') : '',
            )
          }
        />
      </NowDate>
    </>
  )
}

const VatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`
const Vat = styled.div`
  border: 1px solid ${theme.colors.gray[300]};
  margin-bottom: 10px;
  & > p {
    text-align: center;
    border-bottom: 1px solid ${theme.colors.gray[300]};
  }
`

const VatWrapper = styled.div`
  display: flex;
  padding: 4px 8px;
`

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
`

const DetailsTitle = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & > span {
    margin-right: 10px;
    flex-shrink: 0;
  }
`

const DetailsDescription = styled(Description)`
  font-size: 12px;
  position: absolute;
  padding: 0 10px;
  bottom: 6px;
  left: 0;
  word-break: keep-all;
`

const NowDate = styled.div`
  width: 100%;
  text-align: right;
  letter-spacing: 1px;
  font-weight: 400;
  padding-right: 10px;
  padding-bottom: 8px;
  border-bottom: 2px solid ${theme.colors.gray[200]};
  font-size: 12px;
  ${SectionSpace}
`

const StyledButton = styled(Button)`
  position: absolute;
  top: 6px;
  right: 10px;

  a {
    &:hover {
      color: ${theme.colors.blue[400]};
    }
  }
`

const CustomWrapper = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-left: -20px;
  }
`

export default NoteDocsPay13
