import styled from 'styled-components'
import SummarySchoolDistrictListItem from './SummarySchoolDistrictListItem'

/**
 * 학군 데이터 모두 진학률 0일 때 리스트
 */
const SummarySchoolDistrictList = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item, i) => (
        <SummarySchoolDistrictListItem
          key={i}
          isDistrict={item.is_district}
          establishedKind={item.established_kind}
          classStudentCnt={item.class_student_cnt}
          schoolName={item.school_name}
          distance={item.distance}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 22px;
`

export default SummarySchoolDistrictList
