import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { feeOptions } from '../../optionsFee'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비 부과 기준
 */
const NoteAdColumnFeeImposeCriteria = () => {
  const { onUpdate } = useAdUpdate()
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext()
  const list = feeOptions['부과기준']

  const chargeCriteriaCode = watch(
    'administrationCostInfo.chargeCriteriaCode',
  )

  return (
    <ColumnSet>
      <ColumnTitle required={chargeCriteriaCode != '03'}>
        부과 기준
      </ColumnTitle>
      <ColumnVal gap={12}>
        {Object.entries(list).map(([key, value], i) => {
          return (
            <React.Fragment key={i}>
              <RadioButton
                size="small"
                fit
                label={value}
                {...register(
                  'administrationCostInfo.chargeCriteriaCode',
                )}
                value={key}
                onChange={(e) => {
                  onUpdate(
                    'administrationCostInfo.chargeCriteriaCode',
                    e.target.value,
                  )
                }}
                name="fee_charge_criteria_code"
              />
            </React.Fragment>
          )
        })}
        <ValidateWarn>
          {errors?.administrationCostInfo?.chargeCriteriaCode?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeeImposeCriteria
