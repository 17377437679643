import { useState } from 'react'
import { Modal } from 'components/design'
import SummaryShare from '../share/SummaryShare'
import HeaderButton from './HeaderButton'
import HeaderIcon from 'components/common/Header/HeaderIcon'

const HeaderButtonShare = () => {
  const [shareModal, setShareModal] = useState(false)

  return (
    <>
      <HeaderIcon gray onClick={() => setShareModal(true)}>
        <i className="far fa-share-alt"></i>
        {/* <span>공유</span> */}
      </HeaderIcon>

      <Modal
        title="공유"
        visible={shareModal}
        onOk={() => setShareModal(false)}
        onCancel={() => setShareModal(false)}
        okText="확인"
        cancelText="취소"
        footer={null}
        width={380}
      >
        <SummaryShare />
      </Modal>
    </>
  )
}

export default HeaderButtonShare
