import styled from 'styled-components'
import { ReactComponent as CardIcon } from 'assets/icon/note/mode/cardmode.svg'
import { ReactComponent as CardActiveIcon } from 'assets/icon/note/mode/cardmode-active.svg'
import { ReactComponent as SheetIcon } from 'assets/icon/note/mode/sheetmode.svg'
import { ReactComponent as SheetActiveIcon } from 'assets/icon/note/mode/sheetmode-active.svg'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import theme from 'lib/styles/theme'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useRef, useState } from 'react'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'

/**
 * 매물노트 모드 변경 (카드, 시트)
 */
const NoteMode = () => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const isMobile = useIsMobile()
  const history = useHistory()
  const ref = useRef()

  const [isMobilePopVisible, setIsMobilePopVisible] = useState(false)

  useOnClickOutside(ref, (e) => {
    setIsMobilePopVisible(false)
  })
  /**
   * 시트 모드 아이콘 클릭 event
   */
  const onclickSheetIcon = () => {
    if (localStorage.getItem('notemode') == 'sheet') return

    localStorage.setItem('notemode', 'sheet')

    const recentChannelSrl =
      localStorage.getItem('recentChannelSrl') ||
      channelInfo?.channel_srl

    history.push({
      pathname: `/note/v2/notes/${recentChannelSrl}`,
      // state: {
      //   pageType: 'list',
      // },
    })
  }

  /**
   * 카드 모드 아이콘 클릭 event
   */
  const onclickCardIcon = () => {
    if (localStorage.getItem('notemode') == 'card') return

    localStorage.setItem('notemode', 'card')

    const recentChannelSrl =
      localStorage.getItem('recentChannelSrl') ||
      channelInfo?.channel_srl
    const recentFolderSrl = localStorage.getItem('recentFolderSrl')

    if (!recentChannelSrl || !recentFolderSrl) {
      history.push('/note')
    } else {
      history.push(`/note/${recentChannelSrl}/${recentFolderSrl}`)
    }
  }

  const mode = localStorage.getItem('notemode')

  return (
    <>
      {isMobile ? (
        <Wrapper>
          {mode == 'card' ? (
            <CardActiveIcon
              onClick={() => setIsMobilePopVisible((prev) => !prev)}
            />
          ) : (
            <SheetActiveIcon
              onClick={() => setIsMobilePopVisible((prev) => !prev)}
            />
          )}

          {isMobilePopVisible && (
            <MobilePop ref={ref}>
              {mode == 'card' ? (
                <>
                  <SheetIcon onClick={onclickSheetIcon} />
                  <CardActiveIcon onClick={onclickCardIcon} />
                </>
              ) : (
                <>
                  <SheetActiveIcon onClick={onclickSheetIcon} />
                  <CardIcon onClick={onclickCardIcon} />
                </>
              )}
            </MobilePop>
          )}
        </Wrapper>
      ) : (
        <Wrapper>
          {mode == 'card' ? (
            <>
              <SheetIcon onClick={onclickSheetIcon} />
              <CardActiveIcon onClick={onclickCardIcon} />
            </>
          ) : (
            <>
              <SheetActiveIcon onClick={onclickSheetIcon} />
              <CardIcon onClick={onclickCardIcon} />
            </>
          )}
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  position: relative;

  svg {
    cursor: pointer;
  }
`

const MobilePop = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  top: 25px;
  left: 50%;
  z-index: 1;
  transform: translate(-50%);
  box-shadow: ${theme.shadow['button-2']};
  background-color: ${theme.colors.gray[100]};
  padding: 6px 10px;
  border-radius: 4px;
`

export default NoteMode
