import { message } from 'antd'
import { getAd } from 'lib/api/notev2/adList'
import useAdOptions from 'lib/hooks/notev2/useAdOptions'
import useAdRegister from 'lib/hooks/notev2/useAdRegister'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import useAxios from 'lib/hooks/useAxios'
import { setAdFormControl } from 'modules/noteAd'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

/**
 * 광고 수정 & viewer
 * @param {*} param0
 * @returns
 */

/**
 * 검증방식,법정동,지번주소,기타주소,거래구분,부동산유형, 사업자번호,전화번호,단지번호,평형번호,동번호,동명,호명,매도자명,매도자전화번호,확인요청일자,확인요청시간코드,(연)총면적,대지면적, 건축면적,공급면적,전용면적,예상면적
 * 중에 폼에 있는 것 만
 */
const editUnable = [
  'verification', //검증방식 모두 disabled
  'article.address4',
  'trade.type',
  'article.spc1',
  'article.spc2',
  'article.spc3',
  'article.spc4',
]

const NoteAdEditContainer = ({ children }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const dispatch = useDispatch()
  const methods = useForm()
  const { handleSubmit, reset, watch, clearErrors, setFocus } = methods
  const params = useParams()

  const formRef = useRef()

  const { getAdOptions } = useAdOptions()
  const { isBoss } = useIsAllInOne()
  const [onRequest, , data] = useAxios(getAd)

  const {
    preregister,
    approvalReq,
    validationError,
    paymentModal,
    loading,
    setLoading,
    isProcessing,
  } = useAdRegister(params?.ad_srl)

  const status = watch('status')

  /**
   * 매물 select api
   */
  useEffect(() => {
    if (!params?.ad_srl) {
      message.info('잘못된 접근입니다.')
      return
    }

    const ad_srl = params?.ad_srl
    getAdOptions()
    setLoading(true)
    onRequest({
      ad_srl,
      plan_srl: planInfo.allinone_plan_srl,
    })
  }, [])

  useEffect(() => {
    if (!data) {
      return
    }

    if (!data.result) {
      message.info('잘못된 접근입니다.')
      setLoading(false)
      return
    }

    setLoading(false)
    reset({
      ...data.result,
    })
  }, [data])

  const onSubmit = (pData) => {
    try {
      if (!pData?.verification?.oagree) {
        setFocus('verification.oagree')
        message.error('개인정보 수집 및 이용 동의를 확인해주세요.')
        return
      }

      if (
        pData?.verification?.type == 'S' &&
        pData?.verification?.agree_confirm != 'Y'
      ) {
        setFocus('verification.agree_confirm')
        message.error(
          '현장확인의 경우 확인사항과 이용 약관을 확인해주세요.',
        )
        return
      }

      if (isBoss) {
        //대표일 때
        if (
          [
            '작성중',
            '승인대기',
            '검증취소',
            '검증최종실패',
            '광고종료',
          ].includes(status)
        ) {
          //컬럼 검증 + 결제
          preregister({
            recycle: ['검증최종실패', '광고종료', '검증취소'].includes(
              status,
            ),
          })
        } else {
          message.error('잘못된 요청입니다.')
        }
      } else {
        //대표가 아닐때 대표에게 매물 전송
        approvalReq()
      }
    } catch (e) {
      message.error(
        '매물 전송 중 오류가 발생했습니다. 관리자에게 문의해 주세요.',
      )
      console.log('에러났음')
    }
  }

  useEffect(() => {
    if (!status) return

    if (isBoss) {
      if (
        [
          '대기중',
          '검증중',
          '전송중',
          '광고중',
          '동일주소매물',
          '신고매물',
          '광고종료',
          '종료예정',
        ].includes(status)
      ) {
        setLoading(true)
        //모든 항목 수정 불가 처리
        dispatch(
          setAdFormControl({
            adSrl: params?.ad_srl,
            isDisabledAll: true,
          }),
        )

        setLoading(false)
      } else if (['광고중', '종료예정'].includes(status)) {
        //수정 불가 항목 지정
        dispatch(
          setAdFormControl({
            adSrl: params?.ad_srl,
            disabledColumns: editUnable,
          }),
        )
      } else if (
        status == '작성중' ||
        status == '전송실패' ||
        status == '검증최종실패' ||
        status == '검증1차실패' ||
        status == '검증취소' ||
        status == '승인대기'
      ) {
        //항목 수정 가능
        dispatch(
          setAdFormControl({
            adSrl: params?.ad_srl,
            isDisabledAll: false,
          }),
        )
      }
    } else {
      if (
        status == '작성중' ||
        status == '전송실패' ||
        status == '승인거절'
      ) {
        //항목 수정 가능
        dispatch(
          setAdFormControl({
            adSrl: params?.ad_srl,
            isDisabledAll: false,
          }),
        )
      } else {
        //항목 수정 불가
        dispatch(
          setAdFormControl({
            adSrl: params?.ad_srl,
            isDisabledAll: true,
          }),
        )
      }
    }
    return () => {
      //reset
      dispatch(
        setAdFormControl({
          adSrl: null,
          disabledColumns: [],
          isDisabledAll: false,
        }),
      )
    }
  }, [status])

  return children({
    methods,
    validationError,
    formRef,
    status,
    loading,
    paymentModal,
    isProcessing,
    onSubmit: (e) => {
      clearErrors()
      handleSubmit(onSubmit)(e)
    },
  })
}

export default NoteAdEditContainer
