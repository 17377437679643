import styled from 'styled-components'
import { ReactComponent as KaKaoIcon } from 'assets/icon/kakao.svg'
import theme from 'lib/styles/theme'

const MenuWrapper = styled.div`
  height: 400px;
  padding: 28px 20px 0 20px;
`

const TitleWrapper = styled.div`
  line-height: 150%;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
      color: ${theme.colors.gray[900]};
    }
  }
  a:last-child {
    padding-bottom: 0;
  }
`

const KakaoWrapper = styled.a`
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
`
const SideCustomerServiceMenu = () => {
  return (
    <MenuWrapper>
      <TitleWrapper>고객센터</TitleWrapper>
      <ContentWrapper>
        <a href="/terms">이용약관</a>
        <a href="/privacy">개인정보취급방침</a>
        <KakaoWrapper href="https://pf.kakao.com/_XTUzb">
          <KaKaoIcon />
          <span>카카오톡 문의하기</span>
        </KakaoWrapper>
        <a href="https://forms.gle/mp6oU3PDaJqDfM2N7">불편신고</a>
        <a href="//jootek.com/about_map">JOOTEK 지도</a>
        <a href="//jootek.com/about">JOOTEK 소개</a>
        <a href="https://map.jootek.com">매물노트 소개</a>
        <a href="https://scrawny-polish-e36.notion.site/JOOTEK-b35bd226f4724445bae8f13f3d54695d">
          매물노트 가이드
        </a>
      </ContentWrapper>
    </MenuWrapper>
  )
}

export default SideCustomerServiceMenu
