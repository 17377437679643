import { useCallback, useEffect, useState } from 'react'
import { Modal } from 'components/design'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { saveFolder } from 'lib/api/note/folderApi'
import useFolders from 'pages/jmapnote/noteList/hooks/useFolders'
import NoteFolderForm from './FolderForm'
import useAxios from 'lib/hooks/useAxios'

/**
 * 폴더 추가 모달
 * @param {boolean} visible 모달 활성화 여부
 * @param {function} setVisible
 * @returns
 */
const FolderSave = ({ visible, setVisible }) => {
  const { channelSrl } = useNoteMatch()
  const [name, setName] = useState('')
  const [icon, setIcon] = useState('folder')

  const [onRequest, loading, data, error, reset] = useAxios(saveFolder)
  const { _getFolders } = useFolders()

  useEffect(() => {
    if (!visible) {
      setName('')
      setIcon('far fa-folder')
    }
  }, [visible])

  useEffect(() => {
    if (data && !error) {
      setVisible(false)
    }
  }, [data, error])

  const onCancel = useCallback(() => {
    setVisible(false)
  })

  const onOk = useCallback(async () => {
    await onRequest({
      channelSrl,
      name,
      icon: icon,
    })

    _getFolders()
  }, [onRequest, channelSrl, name, icon])

  return (
    <Modal
      visible={visible}
      title="폴더 추가"
      onCancel={onCancel}
      onOk={onOk}
    >
      <NoteFolderForm
        name={name}
        setName={setName}
        icon={icon}
        setIcon={setIcon}
      />
    </Modal>
  )
}

export default FolderSave
