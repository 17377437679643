import styled from 'styled-components'
import { ReactComponent as Icon } from 'assets/icon/note/shield.svg'
import theme, { media } from 'lib/styles/theme'

const NotePaymentAuthHeader = () => {
  return (
    <>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Title>
        <SubTitle>안전한 매물노트 사용을 위해</SubTitle>
        <TitleText>본인인증이 필요해요</TitleText>
      </Title>
      <Desc>
        본인인증 정보는 회원 정보로 자동 저장되며 변경할 수 없어요.
      </Desc>
    </>
  )
}

const Title = styled.div`
  margin-bottom: 30px;
  font-size: 42px;
  font-weight: 700;
  line-height: 150%;
  text-align: center;

  ${media.mediumS`
    width: 100%;  
    justify-content: center;
    word-break: keep-all;
    gap: 14px;
    font-size: 34px;
    margin-bottom: 20px;
  `}
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary[50]};
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-bottom: 16px;

  svg {
    text-align: center;
    ${media.mediumS`
      width: 42px;
      height: 42px;
    `}
  }
`

const TitleText = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`

const SubTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`

const Desc = styled.div`
  margin-bottom: 48px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${theme.colors.gray[600]};

  ${media.mediumS`
    margin-bottom: 40px;
  `}
`

export default NotePaymentAuthHeader
