import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useFormContext } from 'react-hook-form'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 거래정보 - 권리금
 */
const NoteAdColumnPremium = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const price = watch('trade.right')

  return (
    <ColumnSet>
      <ColumnTitle>권리금</ColumnTitle>
      <ColumnVal gap={10}>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="number"
            defaultValue={price}
            {...register('trade.right')}
            onBlur={(e) => {
              onUpdate(
                'trade.right',
                e.target.value.replaceAll(',', ''),
              )
            }}
            append={'만원'}
          />
        </div>
        <ValidateWarn>{errors?.trade?.right?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnPremium
