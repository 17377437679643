import theme from 'lib/styles/theme'
import moment from 'moment'
import styled from 'styled-components'

const NoteDashboardNotiListItem = ({ text, regdate }) => {
  return (
    <Wrapper>
      <Text>{text}</Text>
      <Date>
        {regdate &&
          moment(regdate, 'YYYY-MM-DD HH:mm:ss').format('YY-MM-DD')}
      </Date>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
`

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Date = styled.span`
  margin-left: 10px;
  flex-shrink: 0;
  color: ${theme.colors.gray[600]};
`

export default NoteDashboardNotiListItem
