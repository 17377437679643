import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { Button, Tab, TabItem } from 'components/design'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mediumS`
    margin-bottom: 16px;
  `}
`

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;
`

const Li = styled.li`
  padding: 0.25rem;
`

const NoteFilterTab = ({ currentTab, setCurrentTab }) => {
  return (
    <Wrapper>
      <Tab size="small" noContainer>
        <TabItem
          expand
          color="black"
          active={currentTab === 'type'}
          onClick={() => setCurrentTab('type')}
        >
          유형
        </TabItem>
        <TabItem
          color="black"
          expand
          active={currentTab === 'price'}
          onClick={() => setCurrentTab('price')}
        >
          가격
        </TabItem>
        <TabItem
          color="black"
          expand
          active={currentTab === 'area'}
          onClick={() => setCurrentTab('area')}
        >
          면적
        </TabItem>
        <TabItem
          color="black"
          expand
          active={currentTab === 'extra'}
          onClick={() => setCurrentTab('extra')}
        >
          기타
        </TabItem>
      </Tab>
    </Wrapper>
  )
}

const ResetButton = styled(Button)`
  margin-left: 15px;
  color: ${theme.colors.gray[500]};
  font-size: 12px;
  word-break: keep-all;
  font-weight: 500;

  i {
    margin-right: 4px;
    font-weight: 500;
  }

  ${media.mediumS`
    width: 100px;
    margin-left: 6px;
  `}
`

export default NoteFilterTab
