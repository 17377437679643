import { Tag } from 'components/design'
import SummaryRpDetailTableItem from 'components/summary/SummaryRpDetail/SummaryRpDetailTableItem'
import SummaryRpDetailTableItemSale from 'components/summary/SummaryRpDetail/SummaryRpDetailTableItemSale'
import theme from 'lib/styles/theme'
import { useMemo } from 'react'
import styled from 'styled-components'

const SummaryRpDetailTableItemSaleContainer = ({ data, oriData }) => {
  const diff = useMemo(() => {
    const filteredOriData = oriData.filter(
      ({ canceled_date }) => !canceled_date,
    )
    const currIndex = filteredOriData.findIndex(
      ({ id }) => data.id == id,
    )

    if (filteredOriData[currIndex + 1]) {
      return (
        Number(data?.price || 0) -
        Number(filteredOriData[currIndex + 1].price || 0)
      )
    } else {
      return ''
    }
  }, [data, oriData])

  const isExceptionalData =
    data.contract_date &&
    data.registration_date &&
    data.contract_date != data.registration_date //중개거래거나 직거래 면서 거래날짜와 등기일자가 다른날인 경우(빈 값 X)

  const getTags = () => {
    const contract_type = data.contract_type
    const contract_date = data.contract_date
    const registration_date = data.registration_date
    const canceled_date = data.canceled_date

    if (canceled_date) {
      return <CancelTag />
    }

    if (!contract_type || contract_type == '중개거래') {
      //중개거래인데 등기일자 없으면 태그 X
      if (!registration_date) {
        return null
      }
      //등기 일자 있을 때
      if (registration_date) {
        return <DeunggiTag />
      }
      //등기일자, 거래일자 같은 경우
      if (
        contract_date &&
        registration_date &&
        contract_date == registration_date
      ) {
        return <DeunggiTag />
      }
    }

    if (contract_type == '직거래') {
      if (
        contract_date &&
        registration_date &&
        contract_date == registration_date
      ) {
        return (
          <>
            {/* <JickGeoRaeTag /> */}
            <DeunggiTag />
          </>
        )
      }
      // else {
      //   return <JickGeoRaeTag />
      // }
    }
  }

  const DeunggiTag = () => (
    <Tag size="small" color="gray">
      등기
    </Tag>
  )
  const CancelTag = () => (
    <StyledCancelTag color="gray" plain size="small">
      거래취소
    </StyledCancelTag>
  )

  const getDate = () => {
    const contract_type = data.contract_type
    const contract_date = data.contract_date
    const registration_date = data.registration_date

    if (contract_type == '직거래') {
      return contract_date
    } else if (!contract_type || contract_type == '중개거래') {
      if (registration_date) {
        return registration_date
      } else {
        return contract_date
      }
    } else {
      return contract_date
    }
  }

  return (
    <SummaryRpDetailTableItemSale
      isExceptionalData={isExceptionalData}
      data={data}
      date={getDate()}
      diff={diff}
      tag={getTags()}
      isJickGeoRae={data?.contract_type == '직거래'}
    />
  )
}

const StyledCancelTag = styled(Tag)`
  background-color: ${theme.colors.summary.rp.cancel.background};
  color: ${theme.colors.summary.rp.cancel.color};
`

export default SummaryRpDetailTableItemSaleContainer
