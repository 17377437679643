import React, { useCallback, useEffect, useRef } from 'react'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const CustomAddressCellListItem = ({
  addressSaveFn,
  escapeFn,
  focus,
  validData,
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (focus) {
      // Move element into view when it is focused
      ref.current.focus()
    }
  }, [focus])

  const onKeyDown = useCallback((e) => {
    // 엔터 or 스페이스바
    if (e.key == 'Enter' || e.keyCode == '32') {
      addressSaveFn(validData)
    }
    if (e.key == 'Escape') {
      escapeFn && escapeFn()
    }
  }, [])

  const onClick = () => {
    addressSaveFn(validData)
  }

  return (
    <AdreessesItem
      tabIndex={0}
      role="button"
      ref={ref}
      onClick={onClick}
      onKeyDown={onKeyDown}
      // isActive={activeIdx == index}
    >
      {validData.full_address}
      {validData.doro_partial && (
        <>
          &#40;{validData.doro_partial}
          &#41;
        </>
      )}{' '}
      {validData.doro_extra && <>{validData.doro_extra}</>}
    </AdreessesItem>
  )
}

const AdreessesItem = styled.li`
  cursor: pointer;
  text-align: left;
  border: 1px solid transparent;
  white-space: initial;
  line-height: 170%;
  height: 34px;
  line-height: 140%;
  min-height: 36px;
  display: flex;
  align-items: center;
  height: auto;
  flex-shrink: 0;
  z-index: 999999999999;
  padding: 6px 10px;

  /* ${({ isActive }) => isActive && `background: blue;`} */
  &:focus,
  &:focus-within {
    outline: none;
    background-color: ${theme.colors.noteSheet.dropdown.hover};
    border-radius: 5px;
  }
  &:hover {
    background-color: ${theme.colors.noteSheet.dropdown.hover};
  }

  & > span {
    color: ${theme.colors.gray[800]};
    &::before {
      content: '(';
      margin-right: 1px;
    }
    &::after {
      content: ')';
      margin-left: 1px;
    }
  }
`
export default React.memo(CustomAddressCellListItem)
