import theme from 'lib/styles/theme'
import styled from 'styled-components'

const CustomizedLegend = ({ payload, marginLeft }) => {
  return (
    <StyledLegend margin={marginLeft ? 'Y' : 'N'}>
      {payload.map((item, i) => (
        <StyledLegendItem key={i}>
          <StyledLegendIcon color={item.color} />
          {item.value}
        </StyledLegendItem>
      ))}
    </StyledLegend>
  )
}

const StyledLegend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.gray[50]};
  border-radius: 8px;
  padding: 7px 0px;

  ${({ margin }) => margin == 'Y' && `margin-left: 20px;`};
`

const StyledLegendItem = styled.div`
  display: inline-flex;
  align-items: center;
  /* padding-top: 0.5rem; */

  color: ${theme.colors.gray[600]};
  font-size: 13px;

  &:not(:last-child) {
    margin-right: 0.75rem;
  }
`

const StyledLegendIcon = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 0.25rem;
  background-color: ${({ color }) => color && color};
  border-radius: 2px;
`

export default CustomizedLegend
