import { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import useEditContract from 'lib/hooks/notev2/useEditContract'
import { onlyUnique } from 'utils'
import { useSelector } from 'react-redux'

const NoteDocsSaveContainer = ({ children }) => {
  const [onEditContract, , data] = useEditContract()
  const { setValue, getValues } = useFormContext()
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const onUpdate = useCallback(
    (key, value, isPaymentSchedule = false, isExtraVars = false) => {
      if (channelInfo?.member_type == 'readonly') return //읽기 권한 수정 불가

      let newData = {}

      if (isPaymentSchedule) {
        newData = {
          payment_schedule: {
            ...getValues().payment_schedule,
            [key]: value,
          },
        }
      } else if (isExtraVars) {
        newData = {
          extra_vars: {
            ...getValues().extra_vars,
            [key]: value,
          },
        }
      } else {
        newData = {
          [key]: value,
        }
      }

      onEditContract({
        data: getValues(),
        updateData: newData,
      })

      if (isPaymentSchedule) {
        setValue('payment_schedule', {
          ...getValues().payment_schedule,
          [key]: value,
        })
      } else if (isExtraVars) {
        setValue('extra_vars', {
          ...getValues().extra_vars,
          [key]: value,
        })
      } else {
        setValue(key, value)
      }
    },
    [],
  )

  const onUpdateMultiple = useCallback((updateData) => {
    if (channelInfo?.member_type == 'readonly') return //읽기 권한 수정 불가

    onEditContract({
      data: getValues(),
      updateData: updateData,
    })

    Object.entries(updateData).map(([key, value]) => {
      setValue(key, value)
    })
  }, [])

  const onUpdateCheckbox = useCallback(({ oriData, key, value }) => {
    let newData = oriData?.length > 0 ? [...oriData] : []

    if (newData.includes(value)) {
      newData = newData.filter((item) => item != value)
    } else {
      newData = [...newData, ...[value]].filter(onlyUnique)
    }

    onUpdate(key, newData)
  }, [])

  useEffect(() => {
    if (!data || !data.contract_srl) return

    setValue('contract_srl', data.contract_srl)
  }, [data])

  return children({
    onUpdate,
    onUpdateCheckbox,
    onUpdateMultiple,
  })
}
export default NoteDocsSaveContainer
