import { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { get3DModel } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import MapContext from 'contexts/map'
import { CardBody } from 'components/design'
import { Empty } from 'antd'
import Addr3dshape from '../SummarySizeReview/Addr3dshape'

const BuildModelWrap = styled(CardBody)`
  padding-bottom: 0;
`
const Model = styled.div`
  user-select: none;
`

const SummaryNohu3DModel = ({ data }) => {
  const [isNotSupport3D, setIsNotSupport3D] = useState(false)

  const { state, actions } = useContext(MapContext)

  const [onRequest, loading, modelData] = useAxios(get3DModel)

  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  /**
   * 3D모델링 데이터 세팅
   */
  useEffect(() => {
    const payload = {
      address_id: currentAddressId,
      limits: '15/1000',
    }
    onRequest(payload)
  }, [])

  useEffect(() => {
    if (!modelData) return

    let mounted = true

    if (modelData.total_size && modelData.land_outline && mounted) {
      actions.setViewer3dDatas({
        ...state.viewer3dDatas,
        [currentAddressId]: modelData,
      })
    }
    if (mounted && modelData.message === 'TOO_LARGE') {
      setIsNotSupport3D(true)
    }

    return () => {
      // 초기화
      setIsNotSupport3D(false)
      mounted = false
    }
  }, [modelData])

  return (
    <>
      <BuildModelWrap size="small">
        <Model className="summary-building-model">
          {!isNotSupport3D &&
          data &&
          data.result &&
          data?.result?.buildings.length > 0 ? (
            <Addr3dshape data={data.result} type="nohu" />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                '선택하신 토지의 수익성 분석 중 오류가 발생하였습니다.'
              }
            />
          )}
          {isNotSupport3D && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'3D모델링을 지원하지 않는 건물입니다.'}
            />
          )}
        </Model>
      </BuildModelWrap>
    </>
  )
}

export default SummaryNohu3DModel
