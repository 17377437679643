import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { useForm } from 'react-hook-form'
import useAxios from 'lib/hooks/useAxios'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import { searchRealtorNumber } from 'lib/api/auth/authApi'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Confirm } from 'components/design'
import { numberWithCommas } from 'utils'
import NotePaymentAllinoneConfirm from './NotePaymentAllinoneConfirm'
import { getAllinoneInvoice } from 'lib/api/notev2/paymentApi'
import NotePaymentInvoiceLayout from './NotePaymentInvoiceLayout'

/**
 * 올인원 - 본인인증 폼 세팅, submit 로직
 * @param paying 올인원 결제중 여부
 * @param callPaymentModal 결제 function
 * @param month 결제 월 수
 * @param price 결제 금액
 * @param onClose 모달 닫기
 * @returns
 */
const NotePaymentAuthFormContainer = ({
  children,
  paying,
  callPaymentModal,
  month,
  price,
  onClose,
}) => {
  const history = useHistory()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const planInfo = useSelector((state) => state.auth.planInfo)
  const { onRequest: refreshUserInfo } = useUserLoader(false)

  const [isValidRealtorNumber, setIsValidRealtorNumber] =
    useState(false) //중개업 등록번호 유효 검증 완료 여부
  const [searchedAddrCode, setSearchedAddrCode] = useState(null) // 중개업 등록번호 검색을 위한 주소 코드 { b_code, h_code }
  const [realtorMessage, setRealtorMessage] = useState('') //중개업 등록번호  유효성 체크 메시지

  const [isLoadingModify, setIsLoadingModify] = useState(false)
  const [onRequestRealtor, , dataRealtor, errorRealtor] = useAxios(
    searchRealtorNumber,
    {
      resetError: true,
    },
  )
  const [onRequestInvoice, , dataInvoice] = useAxios(getAllinoneInvoice) //청구서 정보 가져오기

  // 회원정보 폼
  const methods = useForm()
  const { register, watch, handleSubmit, reset, setValue } = methods

  const realtor_position = watch('realtor_position')
  const company_address_num = watch('company_address_num')
  const company_number = watch('company_number')
  const company_name = watch('company_name')

  useEffect(() => {
    reset({
      ...loginInfo,
      company_phone1: loginInfo?.company_phone?.[0] || '',
      company_phone2: loginInfo?.company_phone?.[1] || '',
      company_phone3: loginInfo?.company_phone?.[2] || '',
      company_address_num: loginInfo?.company_address_raw?.[0] || '', //우편번호
      company_address_road: loginInfo?.company_address_raw?.[1] || '', //도로명
      company_address_name: loginInfo?.company_address_raw?.[2] || '', //지번
      company_address_detail: loginInfo?.company_address_raw?.[3] || '', //상세주소
      sigungu_id: loginInfo?.address_id.slice(0, 5),
    })
  }, [loginInfo])

  /**
   * 최초 로딩시 검증 여부 세팅
   */
  useEffect(() => {
    if (loginInfo?.validated) {
      setIsValidRealtorNumber(true)
    }
  }, [planInfo])

  /**
   * 회원정보 수정
   */
  const onSubmit = (data) => {
    if (!data?.realtor_number) {
      message.info('중개업등록번호를 입력해주세요.')
      return
    }
    if (paying && realtor_position != '대표') {
      message.error('대표만 올인원 플랜 신청이 가능합니다.')
      return
    }

    const formData = new FormData()
    formData.append('_rx_csrf_token', loginInfo?.csrf_token)
    //formData.append('phone_number', data?.phone_number)
    formData.append('company_name', data?.company_name)
    //formData.append('company_ceo', data?.company_ceo) // 대표자 성명
    formData.append('company_number', data?.company_number) // 사업자등록번호
    formData.append('realtor_number', data?.realtor_number) // 중개업등록번호
    // 회사소재지
    formData.append('company_address_num', data?.company_address_num) // 우편번호

    //괄호로 감싸져있는지
    const regxSt = /^\(/
    const regxEnd = /\)$/
    const company_address_name =
      regxSt.test(data?.company_address_name) &&
      regxEnd.test(data?.company_address_name)
        ? data?.company_address_name
        : `(${data?.company_address_name})`

    formData.append('company_address_name', company_address_name) // 지번
    formData.append('company_address_road', data?.company_address_road) // 도로명
    formData.append('company_ceo', data?.company_ceo) // 대표자 성명
    // 회사소재지 - 상세주소
    formData.append(
      'company_address_detail',
      data?.company_address_detail,
    )
    //주소 부가정보
    formData.append(
      'company_address_extra',
      data?.company_address_extra || '',
    )

    // 사무실 전화번호
    formData.append('company_phone1', data?.company_phone1)
    formData.append('company_phone2', data?.company_phone2)
    formData.append('company_phone3', data?.company_phone3)
    // 직위: 대표/소속중개사/중개보조원
    formData.append('realtor_position', data?.realtor_position)

    // 공제증명서
    //if (data.realtor_certificate && data.realtor_certificate[0]) {
    //  formData.append(
    //    'realtor_certificate',
    //    data.realtor_certificate?.[0],
    //  )
    //}

    //직위 선택 확인
    if (!realtor_position) {
      message.warning('직위를 선택해 주세요.')
      return
    }

    //상호 확인
    if (!company_name) {
      message.warning('상호를 입력해주세요.')
      return
    }

    //사업자 등록 번호 확인
    if (!company_number) {
      message.warning('사업자등록번호를 입력해주세요.')
      return
    }

    //회사 소재지
    if (!company_address_num) {
      message.warning('회사 소재지를 입력해주세요.')
      return
    }

    //중개업 등록번호 검증 했는지 확인
    // if (!isValidRealtorNumber) {
    //   message.warning('중개업등록번호로 본인인증을 해주세요.')
    //   return
    // }

    setIsLoadingModify(true)
    onRequestRealtor({
      formData: Object.fromEntries(formData),
      plan_srl: planInfo?.allinone_plan_srl,
    })
  }

  useEffect(() => {
    if (!dataRealtor || dataRealtor?.error < 0) {
      setIsLoadingModify(false)
      return
    }

    setIsLoadingModify(false)
    refreshUserInfo()
    message.success('본인인증 정보가 저장되었습니다.')

    if (paying) {
      //결제 청구서 정보 가져오기
      onRequestInvoice({
        months: month,
      })
    } else {
      onClose()
    }
  }, [dataRealtor])

  useEffect(() => {
    if (!dataInvoice) return
    if (!dataInvoice?.invoice || dataInvoice?.invoice.length == 0) {
      message.error('잘못된 요청입니다. 새로고침 후 다시 시도해주세요.')
      return
    }

    Confirm.info({
      title: '아래와 같이 결제가 진행됩니다.',
      content: (
        <NotePaymentInvoiceLayout
          visiblePeriod
          totalPrice={dataInvoice.total_price}
          data={dataInvoice.invoice}
          card={dataInvoice.card?.card}
        />
      ),
      okButtonProps: { color: 'blue' },
      onOk: () => {
        callPaymentModal('올인원', price, month, 1)
      },
    })
  }, [dataInvoice])

  useEffect(() => {
    if (!errorRealtor) return
    setIsLoadingModify(false)
  }, [errorRealtor])

  return children({
    onSubmit: handleSubmit(onSubmit),
    methods,
    setSearchedAddrCode,
    loadingSpinner: isLoadingModify && <FullScreenLoading />,
    relatorNumberOptions: {
      isValidRealtorNumber,
      setIsValidRealtorNumber,
      realtorMessage,
      setRealtorMessage,
      searchedAddrCode,
    },
  })
}

export default NotePaymentAuthFormContainer
