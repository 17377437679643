import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

/**
 * @name useQueryString
 * @description queryString을 불러올 때 반복되는 작업들을
 * 줄이기 위해 유틸리티화된 쿼리스트링 파싱 도구입니다.
 * @param {boolean} ignoreQueryPrefix
 * @returns query
 */
const useQueryString = (
  { ignoreQueryPrefix } = { ignoreQueryPrefix: true },
) => {
  const { search } = useLocation()
  const query = queryString.parse(search, {
    ignoreQueryPrefix,
  })

  return query
}

export default useQueryString
