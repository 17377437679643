import priceFormat from '../priceFormat'

export const propertyFilterItem = {
  아파트: [
    '아파트',
    '분양권(아)',
    '재건축',
    '오피스텔',
    '분양권(오)',
    '재개발',
  ],
  빌라: [
    '빌라/연립',
    '단독/다가구',
    '상가주택',
    '전원주택',
    '한옥주택',
  ],
  원룸: ['원룸', '투룸'],
  상가: [
    '상가점포',
    '사무실',
    '공장/창고',
    '지식산업센터',
    '빌딩/건물',
    '상가건물',
    '숙박/콘도',
    '토지',
  ],
}

export const contractFilterItem = ['매매', '전세', '월세']

/**
 * 가격
 * @param {*} type 거래 유형
 */
export const getPrice = ({
  contract_type,
  sale_price,
  rent,
  deposit,
  jeonse_deposit,
}) => {
  const result = {
    매매: priceFormat(sale_price * 10000),
    전세: priceFormat(jeonse_deposit * 10000),
    월세: `${priceFormat(deposit * 10000)}/${priceFormat(
      rent * 10000,
    )}`,
  }

  return result?.[contract_type] || ''
}

/**
 * 면적
 * 토지 제외 전용/공급(계약)
 */
export const getArea = ({
  property_type,
  exclusive_area,
  contract_area,
  land_area,
}) => {
  if (property_type == '토지') {
    return `${land_area ? `${land_area}㎡` : ''}`
  } else {
    return `${contract_area ? `${contract_area}㎡` : ''}${
      contract_area && exclusive_area ? '/' : ''
    }${exclusive_area ? `${exclusive_area}㎡` : ''}`
  }
}
