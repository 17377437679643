import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import {
  Block,
  BlockTitle,
} from 'components/jmapnotev2/style/NotePaymentStyle'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useState } from 'react'
import NotePaymentDeunggibuRefundModal from './NotePaymentDeunggibuRefundModal'
import { accessibleStaffGroups } from 'Constants'

/**
 * 결제관리
 * 오른쪽 등기부등본 블럭
 */
const NotePaymentDenggibu = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const planInfo = useSelector((state) => state.auth.planInfo)
  const history = useHistory()

  const [isVisibleRefundModal, setIsVisibleRefundModal] =
    useState(false)

  const onClickRefund = () => {
    setIsVisibleRefundModal(true)
  }

  const onCloseRefundModal = () => {
    setIsVisibleRefundModal(false)
  }

  /**
   * 등기부 추가 충전
   */
  const onClickAddDeunggibuPayment = () => {
    history.push('/note/v2/payment/addDeunggi')
  }

  return (
    <Block half>
      <div>
        <BlockTitle small>등기부등본</BlockTitle>
        <Reamin>
          <strong>{planInfo?.remain_count || 0}개</strong>
          남음
        </Reamin>
        {planInfo?.is_expected_cancel && (
          <ExpirationWarn>
            {planInfo?.error ? <>결제 실패로 </> : ''}
            {moment(
              planInfo.next_payment_date,
              'YYYYMMDDHHmmss',
            ).format('YYYY.MM.DD')}{' '}
            이후 남은 등기부등본 사용이 제한됩니다.
          </ExpirationWarn>
        )}
        <InfoWrap>
          <Info>
            <InfoTitle>이번달 사용량</InfoTitle>
            <span>{planInfo?.usage || 0}개</span>
          </Info>
        </InfoWrap>
      </div>
      <ButtonWrapper>
        {loginInfo?.other_groups?.some((item) =>
          accessibleStaffGroups.includes(item),
        ) && (
          <Button color="gray" onClick={onClickRefund}>
            환불하기
          </Button>
        )}
        <Button
          plain
          color="black"
          onClick={onClickAddDeunggibuPayment}
        >
          추가 구입하기
        </Button>
      </ButtonWrapper>
      <NotePaymentDeunggibuRefundModal
        onCancel={onCloseRefundModal}
        visible={isVisibleRefundModal}
      />
    </Block>
  )
}

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 8px;
  margin-bottom: 92px;
`

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
`

const InfoTitle = styled.div`
  width: 81px;
  color: ${theme.colors.gray[600]};
`

const HrWrapper = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 16px;
  margin-top: 40px;
`
const Reamin = styled.div`
  position: absolute;
  top: 24px;
  right: 30px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.gray[600]};
  line-height: 140%;

  strong {
    font-size: 24px;
    line-height: 100%;
    font-weight: 600;
    color: ${theme.colors.gray[900]};
    margin-right: 10px;
  }
`

const ExpirationWarn = styled.div`
  font-size: 14px;
  color: ${theme.colors.tint.danger};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  text-align: right;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid ${theme.colors.gray[200]};

  & > button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`

export default NotePaymentDenggibu
