import { Tab, TabItem } from 'components/design'

const SummarySchoolTab = ({ activeTab, onChangeTab }) => {
  return (
    <Tab size="medium" noContainer>
      <TabItem
        expand
        active={activeTab === 'daycare'}
        onClick={() => onChangeTab('daycare')}
      >
        어린이집
      </TabItem>
      <TabItem
        expand
        active={activeTab === 'kindergarden'}
        onClick={() => onChangeTab('kindergarden')}
      >
        유치원
      </TabItem>
      <TabItem
        expand
        active={activeTab === 'elementary'}
        onClick={() => onChangeTab('elementary')}
      >
        초등학교
      </TabItem>
      <TabItem
        expand
        active={activeTab === 'middle'}
        onClick={() => onChangeTab('middle')}
      >
        중학교
      </TabItem>
      <TabItem
        expand
        active={activeTab === 'high'}
        onClick={() => onChangeTab('high')}
      >
        고등학교
      </TabItem>
    </Tab>
  )
}

export default SummarySchoolTab
