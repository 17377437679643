import Axios from 'axios'

/**
 * 필터 선택 옵션 가져오기
 * @param {object} payload
 * @returns
 */
export const getOptions = async (payload) =>
  await Axios.get('/map/api/getOptions', {
    params: payload,
  })

/**
 * 실거래가 레이어
 * @param {object} payload
 * @returns
 */
export const getMarkers = async (payload) =>
  await Axios.get('/map/api/getMarkers', {
    params: payload,
  })
/**
 * 실거래가 평균 레이어
 * @param {object} payload
 * @returns
 */
export const getRppAvg = async (payload) =>
  await Axios.get('/map/api/getRppAvg', {
    params: payload,
  })
/**
 * 추정가 레이어
 * @param {object} payload
 * @returns
 */
export const getLayerEstimate = async (payload) =>
  await Axios.get('/map/api/getLayerEstimate', {
    params: payload,
  })
/**
 * 내 매물 레이어
 * @param {object} payload
 * @returns
 */
export const getNoteMarkers = async (payload) =>
  await Axios.post('/jmapnote/api/address/getNoteMarkers', {
    ...payload,
  })
/**
 * 내 매물 레이어 (단일)
 * @param {object} payload
 * @returns
 */
export const getNoteMarker = async (payload) =>
  await Axios.post('/jmapnote/api/note/marker', {
    ...payload,
  })
/**
 * 학교/학군 레이어
 * @param {object} payload
 * @returns
 */
export const getSchoolLayer = async (payload) =>
  await Axios.get('/map/api/getSchoolLayer', {
    params: payload,
  })
/**
 * 건축 가능한 산 레이어
 * @param {object} payload
 * @returns
 */
export const getLayMountain = async (payload) =>
  await Axios.get('/map/api/getLayMountain', {
    params: payload,
  })

/**
 * 개별공시지가 레이어
 * @param {object} payload
 * @returns
 */
export const getLayerPrice = async (payload) =>
  await Axios.get('/map/api/getLayerPrice', {
    params: payload,
  })
/**
 * 사업지구경계 레이어
 * @param {object} payload
 * @returns
 */
export const getHousingSite = async (payload) =>
  await Axios.get('/map/api/getHousingSite', {
    params: payload,
  })
/**
 * 건물을 지을 수 있는 땅 레이어
 * @param {object} payload
 * @returns
 */
export const getEmptyLand = async (payload) =>
  await Axios.get('/map/api/getEmptyLand', {
    params: payload,
  })
/**
 * 경매 레이어
 * @param {object} payload
 * @returns
 */
export const getAuctionMarkers = async (payload) =>
  await Axios.get('/map/api/getAuctionMarkers', {
    params: payload,
  })
/**
 * 건물 노후도 레이어
 * @param {object} payload
 * @returns
 */
export const getLayerBuildingAge = async (payload) =>
  await Axios.get('/map/api/getLayerBuildingAge', {
    params: payload,
  })
/**
 * 법정동 경계선 레이어
 * @param {object} payload
 * @returns
 */
export const getDongBoundary = async (payload) =>
  await Axios.get('/map/api/getDongBoundary', {
    params: payload,
  })
/**
 * 건물 레이어
 * @param {object} payload
 * @returns
 */
export const getLayerBuilding = async (payload) =>
  await Axios.get('/map/api/getLayerBuilding', {
    params: payload,
  })
/**
 * 토지 레이어
 * @param {object} payload
 * @returns
 */
export const getLandLayer = async (payload) =>
  await Axios.get('/map/api/getLandLayer', {
    params: payload,
  })

/**
 * 주택 단지 레이어
 * @param {object} payload
 * @returns
 */
export const getLayerHousingComplex = async (payload) =>
  await Axios.get('/map/api/getLayerHousingComplex', {
    params: payload,
  })

/**
 * 주택 단지 레이어
 * @param {object} payload
 * @returns
 */
export const getLayerHousingComplexDetail = async (payload) =>
  await Axios.get('/map/api/getLayerHousingComplexDetail', {
    params: payload,
  })

export const getLayerHousingComplexByCenterpoint = async (payload) =>
  await Axios.get('/map/api/getLayerHousingComplexByCenterpoint', {
    params: payload,
  })
