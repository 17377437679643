import axios from 'axios'

export const getListings = async (
  {
    _rx_csrf_token,
    channel_srl,
    folder_srl,
    filters,
    q,
    sort_index,
    sort_type,
    address_id,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/getListings',
    {
      _rx_csrf_token,
      channel_srl,
      folder_srl,
      filters,
      q,
      sort_index,
      sort_type,
      address_id,
    },
    options,
  )

export const getListing = async (
  { _rx_csrf_token, channel_srl, note_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/getListing',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
    },
    options,
  )

export const getValidAddresses = async (
  { _rx_csrf_token, address },
  options,
) =>
  await axios.post(
    '/jmapnote/getValidAddresses',
    {
      _rx_csrf_token,
      address,
    },
    options,
  )

export const saveAddressForSheet = async (
  {
    _rx_csrf_token,
    address_id,
    channel_srl,
    folder_srl,
    dong,
    ho,
    exclusive_area,
    contract_area,
    display_address,
    property_type,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/insertListing',
    {
      _rx_csrf_token,
      address_id,
      channel_srl,
      folder_srl,
      dong,
      ho,
      exclusive_area,
      contract_area,
      display_address,
      property_type,
    },
    options,
  )

export const modifyAddress = async (
  {
    _rx_csrf_token,
    address_id,
    channel_srl,
    address_srl,
    note_srl,
    display_address,
    dong,
    ho,
    invalid_addr_name,
    form,
    exclusive_area,
    contract_area,
    property_type,
    input_addr,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/updateListing',
    {
      _rx_csrf_token,
      address_id,
      channel_srl,
      address_srl,
      note_srl,
      display_address,
      dong,
      ho,
      exclusive_area,
      contract_area,
      invalid_addr_name,
      property_type,
      input_addr,
      ...form,
    },
    options,
  )
