import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import {
  getSimpleSelectboxOptions,
  getSimpleSelectboxOptionsForObject,
} from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import { options } from '../../options'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 사용 전력
 * 옵션
 * 빌딩
 */
const NoteAdColumElectricity = () => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const wattage = watch('article.wattage')

  const list = options['사용전력']

  return (
    <ColumnSet half>
      <ColumnTitle>사용전력</ColumnTitle>
      <ColumnVal gap={15}>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: list[wattage] || '선택',
            value: wattage,
          }}
          isSearchable
          options={getSimpleSelectboxOptionsForObject(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('article.wattage')}
          onChange={(e) => {
            onUpdate('article.wattage', e.value)
          }}
        />
        <ValidateWarn>{errors?.article?.wattage?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumElectricity
