import { useContext, useEffect, useState } from 'react'
import MapContext from 'contexts/map'
import { filterParams } from 'utils'
import { getLayMountain } from 'lib/api/layerApi'
import useAxios from 'lib/hooks/useAxios'
import MapLayerIcon from './MapLayerIcon'

/**
 * 지도 레이어
 * 건축 가능 산
 */
const MapLyaerMountain = () => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { layerState, filterState, viewState, map } = state
  const [onRequest, , data, , ,] = useAxios(getLayMountain)
  // 폴리곤 배열 선언
  const [polygons, setPolygons] = useState([])

  useEffect(() => {
    if (layerState.mountainActive) {
      if (!viewState || Object.keys(viewState).length == 0) {
        return
      }

      if (viewState.level > 11) {
        return
      }

      const payload = {
        swlat: viewState?.swLatlng?.getLat(),
        swlng: viewState?.swLatlng?.getLng(),
        nelat: viewState?.neLatlng?.getLat(),
        nelng: viewState?.neLatlng?.getLng(),
        ...filterParams(filterState),
      }

      onRequest(payload, null, false)
    } else {
      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [
    layerState,
    filterState.land_area,
    filterState.use_area,
    filterState.land_type,
    viewState,
    map,
  ])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result || data.result?.length === 0) {
      // 기존 폴리곤 삭제
      polygons?.map((polygon) => {
        polygon.setMap(null)
      })
      return
    }

    const emptyLands = data.result

    // 폴리곤 배열
    let tempPolygons = []

    emptyLands.map((emptyLand) => {
      const shpaePoints = emptyLand.shape.coordinates

      const polygonPath = shpaePoints.map((part) => {
        return part.map((point) => {
          return new kakao.maps.LatLng(point[0], point[1])
        })
      })

      // 지도에 표시할 다각형을 생성합니다
      const emptyLandgPolygon = new kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 1, // 선의 두께입니다
        strokeColor: '#009f68', // 선의 색깔입니다
        strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다
        fillColor: '#009f68', // 채우기 색깔입니다
        fillOpacity: 0.5, // 채우기 불투명도 입니다
        zIndex: 1,
      })

      // 하나씩 생성된 건물 폴리곤을 건물 폴리곤 배열에 넣기
      tempPolygons.push(emptyLandgPolygon)
    })

    setPolygons(tempPolygons)
  }, [data])

  useEffect(() => {
    if (polygons.length === 0) return

    polygons.map((polygon) => {
      polygon.setMap(map)
    })

    return () => {
      if (polygons.length === 0) return

      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [polygons])

  return <MapLayerIcon type="mountainActive" title="건축 가능 산" />
}

export default MapLyaerMountain
