import { Modal } from 'components/design'
import { feeOptions } from 'components/jmapnotev2/NoteAd/optionsFee'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import BdsDetailFeeModalType1 from './BdsDetailFeeModalType1'
import BdsDetailFeeModalType2 from './BdsDetailFeeModalType2'
import BdsDetailFeeModalType3 from './BdsDetailFeeModalType3'

/**
 * 주택부동산 상세
 * 관리비 모달
 */
const BdsDetailFeeModal = ({
  visible,
  onCancel,
  administrationCostInfo,
}) => {
  return (
    <Modal
      title="관리비 상세"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {/* 정액관리비 */}
      {administrationCostInfo?.chargeCodeType == '01' && (
        <>
          <BdsDetailFeeModalType1 data={administrationCostInfo} />
        </>
      )}
      {/* 기타부과 */}
      {administrationCostInfo?.chargeCodeType == '02' && (
        <>
          <BdsDetailFeeModalType2 data={administrationCostInfo} />
        </>
      )}
      {/* 확인불가 */}
      {administrationCostInfo?.chargeCodeType == '03' && (
        <>
          <BdsDetailFeeModalType3 data={administrationCostInfo} />
        </>
      )}
    </Modal>
  )
}
export default BdsDetailFeeModal
