import styled from 'styled-components'
import AppContainer from './AppContainer'
import { media } from 'lib/styles/theme'
import Step1Img from 'assets/images/note/landing/count-minute.png'
import { SectionWrapper } from './LandingStyle'

const LandingBigText = () => {
  return (
    <Wrapper>
      <AppContainer>
        <Content>
          <ImgWrapper>
            <img src={Step1Img} />
          </ImgWrapper>
          <TextWrapper>
            <div>
              부동산 전문가가 문서작업에 할애하는 시간
              <br />약 1.5시간/일
            </div>
          </TextWrapper>
        </Content>
      </AppContainer>
    </Wrapper>
  )
}

const Wrapper = styled(SectionWrapper)`
  position: relative;
  overflow: hidden;
`

const Content = styled.div`
  width: 100%;
  height: 700px;
`

const ImgWrapper = styled.div`
  position: absolute;
  width: 100vw;
  top: 50%;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 15%;

  ${media.mediumM`
    padding: 0 20px;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  font-weight: 600;
  font-size: 48px;
  line-height: 140%;
  background: linear-gradient(90deg, #ffb007 16.52%, #31b85e 81.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //background-clip: text;
  text-align: center;
  word-break: keep-all;

  ${media.smallPc`
    font-size: 38px;
  `}

  ${media.mediumS`
    font-size: 30px;
    font-weight: 700;
    line-height: 130%;
    padding: 0 20px;
  `}
`

export default LandingBigText
