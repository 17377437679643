import { ListItem, Modal } from 'components/design'
import { ListItemThumbnail } from 'components/design'
import { ListItemLabel } from 'components/design'
import { ListItemDesc } from 'components/design'
import moment from 'moment'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Button } from 'components/design'
import { ListItemDivider } from 'components/design'
import useInviteAccept from './hooks/useInviteAccept'
import { Tag } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useEffect, useState } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { updateNotifyRead } from 'lib/api/note/notification'

const StyledText = styled.div`
  & > strong {
    color: ${theme.colors.primary[500]};
  }
`

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;

  & > button {
    margin-right: 8px;
  }
`

const NotificationListItem = ({ item, onClick }) => {
  const {
    isInvite,
    isAccepted,
    handleChangeStatus,
    // getInviteChannelSrl,
  } = useInviteAccept(item?.target_url)

  const [tempReaded, setTempReaded] = useState(item?.readed)

  const [isShowAppModal, setIsShowAppModal] = useState(false)

  const [onRequest, , data] = useAxios(updateNotifyRead)

  const onClickItem = () => {
    const isApp = navigator.userAgent.match(
      /(com\.jootek\.mobile|rhymixpush|RXPUSH)/,
    )

    if (isApp && item?.target_url == '/map/note/v2/payment') {
      //앱에서는 결제관리로 이동하지 않고 알림 팝업
      setIsShowAppModal(true)
      onRequest({
        notify: item?.notify,
      })
    } else {
      window.location.href = item?.url.replace('amp;', '')
    }
  }

  useEffect(() => {
    if (!data || !data.result) return

    setTempReaded('Y')
  }, [data])

  const isDisabled =
    item?.readed === 'Y' || tempReaded === 'Y'
      ? true
      : isAccepted || false

  return (
    <>
      <ListItem
        prepend={
          <Thumbnail>
            {isDisabled || <Badge />}
            <ListItemThumbnail color={isDisabled ? 'gray' : null} />
          </Thumbnail>
        }
        disabled={isDisabled}
        onClick={!isInvite ? () => onClickItem() : null}
        // style={{ opacity: 0.5 }}
      >
        <ListItemLabel small>
          <StyledText
            dangerouslySetInnerHTML={{ __html: item?.text }}
          ></StyledText>
        </ListItemLabel>
        {item?.data?.content && (
          <ListItemContent>
            {item.data.content.split('\\n').map((line) => {
              return <>{line}</>
            })}
          </ListItemContent>
        )}
        <ListItemDesc small>
          {moment(item?.regdate).fromNow()}
        </ListItemDesc>

        {isInvite && item?.notify && (
          <>
            <ListItemDivider xs />

            {isAccepted ? (
              <Tag>초대 수락됨</Tag>
            ) : (
              <ButtonWrap>
                <Button
                  color="primary"
                  onClick={() => handleChangeStatus('2', item.notify)}
                >
                  수락
                </Button>
                <Button
                  onClick={() => handleChangeStatus('3', item.notify)}
                >
                  거절
                </Button>
              </ButtonWrap>
            )}
          </>
        )}
      </ListItem>

      <Modal
        title="알림"
        visible={isShowAppModal}
        onOk={() => setIsShowAppModal(false)}
        onCancel={() => setIsShowAppModal(false)}
        okText="확인"
        cancelText="취소"
      >
        <span>
          {item?.text} 요금제 관련 사항은 pc에서 확인해주세요.
        </span>
      </Modal>
    </>
  )
}

const Thumbnail = styled.div`
  position: relative;
`

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 10px;
  height: 10px;
  background-color: ${theme.colors.primary[400]};
  border: 2px solid white;
  border-radius: 100%;
`

const ListItemContent = styled.div`
  font-size: 14px;
  color: ${theme.colors.gray[700]};
  font-weight: 400;
  white-space: pre-wrap;
`

export default NotificationListItem
