import { useEffect, useRef } from 'react'
/**
 * 초기 마운트가 아닌 업데이트에서만 트리거 되는 Hook.
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */

const useUpdateEffect = (effect, dependencies = []) => {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effect()
    }
  }, dependencies)
}

export default useUpdateEffect
