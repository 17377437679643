import { Tab, TabItem } from 'components/design'
import useMutationObserve from 'components/summary/SummaryTab/useMutationObserve'
import theme from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const BdsDetailTab = ({ tabs }) => {
  const [highZIndex, setHighZIndex] = useState(false)
  const { observeSection, activeIdx } = useMutationObserve(
    tabs,
    'bds-tab-active-section',
    false,
  )

  useEffect(() => {
    observeSection()
  }, [])

  return (
    <StyledTab noContainer className="tab-class" size="large">
      {Array.from(tabs).map((tab, index) => {
        return (
          <TabItem
            key={index}
            noContainer
            expand
            active={activeIdx == index}
            onClick={tab?.onMoveToElement}
          >
            {tab?.name}
          </TabItem>
        )
      })}
    </StyledTab>
  )
}

const StyledTab = styled(Tab)`
  position: sticky;
  top: 0;
  padding-right: 6px;
  background-color: ${theme.colors.base.white};
  width: calc(100% - 8px);
  padding-left: 4px;
  z-index: 1;
`

export default BdsDetailTab
