import { useContext, useEffect, useState } from 'react'
import { billionFormate, getComputedArea } from 'utils'
import moment from 'moment'
import MapContext from 'contexts/map'
import { getHousingComplexRealpurchases } from 'lib/api/housingComplex'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import PagingWithNumber from 'components/design/Paging/PagingWithNumber'
import { CardTitle, Empty } from 'components/design'
import { Skeleton } from 'antd'
import styled from 'styled-components'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useHistory } from 'react-router-dom'

const SummaryHcPriceTable = ({ id, currentPage, setCurrentPage }) => {
  const { state } = useContext(MapContext)
  const { map, isSquareMeter, viewState, layerState } = state

  const history = useHistory()

  const isMobile = useIsMobile()

  const [polygon, setPolygon] = useState([])
  const [selectedIdx, setSelectedIdx] = useState()

  const [onRequest, loading, data, , ,] = useAxios(
    getHousingComplexRealpurchases,
  )

  useEffect(() => {
    onRequest({ id: id, page: currentPage })

    setSelectedIdx()

    if (!polygon || polygon.length == 0) return
    polygon?.setMap(null)
  }, [id, currentPage])

  useEffect(() => {
    if (!polygon || polygon.length == 0) return

    polygon.setMap(map)

    return () => {
      polygon?.setMap(null)
    }
  }, [polygon])

  useEffect(() => {
    if (!polygon || polygon.length == 0) return
    if (!layerState.housingComplexActive) polygon?.setMap(null)
  }, [layerState.housingComplexActive])

  const drawSinglePolygon = (
    polygonData,
    centerpoint,
    idx,
    panTo = true,
  ) => {
    if (viewState.level != 1 && panTo) map.setLevel(1)

    drawPolygon(polygonData)
    panTo && panToCenter(centerpoint)
    setSelectedIdx(idx)
  }

  const drawPolygon = (polygonData) => {
    const shape = polygonData?.coordinates || []
    const polygonPath = shape?.map((part) => {
      return part.map((point) => {
        return new kakao.maps.LatLng(point[0], point[1])
      })
    })

    const polygon = new kakao.maps.Polygon({
      path: polygonPath,
      strokeWeight: 2,
      strokeColor: theme.colors.purple[800],
      strokeStyle: 'solid',
      fillColor: theme.colors.purple[800],
      fillOpacity: 0.5,
      zIndex: 1,
    })

    setPolygon(polygon)
  }

  const panToCenter = (centerpoint) => {
    const locPosition = new kakao.maps.LatLng(
      centerpoint.coordinates[0],
      centerpoint.coordinates[1],
    )
    map.panTo(locPosition)
  }

  const linkToSummary = (address_id) => {
    history.push({
      pathname: `/${address_id}`,
      state: {
        from: 'housingComplex',
      },
    })
  }

  if (loading)
    <SkeletonWrap>
      <Skeleton active />
    </SkeletonWrap>

  return (
    <>
      <CardTitle>최근 실거래</CardTitle>
      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <th>유형</th>
              <th>거래일</th>
              <th className="price">가격</th>
              <th className="area">대지면적</th>
              <th className="area">연면적</th>
            </tr>
          </thead>

          <tbody>
            {data?.result && data?.result.length > 0 ? (
              data?.result.map(
                (
                  {
                    contract_date,
                    total_floor_area,
                    land_area,
                    price,
                    property_type,
                    shape,
                    centerpoint,
                    address_id,
                  },
                  i,
                ) => {
                  return (
                    <StyledTr
                      onClick={() =>
                        isMobile
                          ? linkToSummary(address_id)
                          : drawSinglePolygon(shape, centerpoint, i)
                      }
                      active={selectedIdx === i}
                      key={i}
                    >
                      <td>{property_type}</td>
                      <td>
                        {moment(contract_date).format('YY.MM.DD')}
                      </td>
                      <td>
                        <strong>{billionFormate(price)}</strong>
                      </td>
                      <td>
                        {land_area
                          ? getComputedArea(land_area, isSquareMeter)
                          : '-'}
                      </td>
                      <td>
                        {total_floor_area
                          ? getComputedArea(
                              total_floor_area,
                              isSquareMeter,
                            )
                          : '-'}
                      </td>
                    </StyledTr>
                  )
                },
              )
            ) : (
              <tr>
                <td colSpan={5}>
                  <Empty>데이터가 없습니다.</Empty>
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
        {data?.result && data?.total_count > 4 && (
          <PagingWithNumber
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={5}
            totalCount={data?.total_count}
          />
        )}
      </TableWrapper>
    </>
  )
}

const SkeletonWrap = styled.div`
  background-color: ${theme.colors.base.white};
  padding: 1.25rem;
  height: 240px;
`

const TableWrapper = styled.div`
  padding-top: 0.875rem;
`

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 1rem;

  th {
    padding: 0.5rem;
    border-bottom: 1px solid ${theme.colors.gray[100]};

    color: ${theme.colors.gray[600]};
    font-size: 0.875rem;
    font-weight: 500;
  }

  th,
  td {
    padding: 7px 0.5rem;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    text-align: center;
    font-size: 13px;
    &.date {
      color: ${theme.colors.gray[700]};
    }
    &.price {
      font-weight: 700;
    }
    &.area {
    }
  }
`

const StyledTr = styled.tr`
  ${({ active }) =>
    active && `background-color: ${theme.colors.gray[100]};`}

  &:hover {
    background-color: ${theme.colors.gray[100]};
    cursor: pointer;
  }
`

export default SummaryHcPriceTable
