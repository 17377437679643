import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'

const NotePaymentAllInOneCancelBubble = ({ type }) => {
  return (
    <>
      <Info
        className="fas fa-question-circle"
        data-tooltip-id="allinone-cancel-tooltip"
      />
      <Tooltip
        id="allinone-cancel-tooltip"
        border={`1px solid ${theme.colors.gray[300]}`}
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.03)`,
          zIndex: 9999,
          padding: '18px 16px',
          width: '340px',
        }}
      >
        <>
          <Title>
            서비스(등기부등본 발급, 네이버 광고 전송 등)를 이미
            이용하셨네요.
          </Title>
          <Desc>
            서비스를 이용하지 않고 결제일로부터 7일 이내에 해지요청을
            하실 시 환불이 가능합니다.
          </Desc>
        </>
      </Tooltip>
    </>
  )
}

const Info = styled.i`
  margin-left: 2px;
  color: ${theme.colors.gray[400]};
  z-index: 999;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};
`

const Desc = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${theme.colors.gray[600]};
`
export default NotePaymentAllInOneCancelBubble
