import { useSelector } from 'react-redux'

const HomeContainer = ({ children }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const recentAddresses = useSelector(
    (state) => state.home.recentAddresses,
  )
  const recentFavorites = useSelector(
    (state) => state.home.recentFavorites,
  )
  const isLoggedIn = loginInfo?.member_srl

  return children({ isLoggedIn, recentAddresses, recentFavorites })
}

export default HomeContainer
