import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { endAdListing } from 'lib/api/notev2/adSubmit'
import { useSelector } from 'react-redux'
import { Confirm } from 'components/design'

/**
 * 광고 목록 버튼
 * 노출종료
 * @param adSrl
 * @param callbackFn 삭제 후 목록 재조회
 * @param setUpdatedAdSrl 재조회를 위해 저장하는 ad_srl
 * @param forForm 폼에서 사용하는지 여부
 * @param forNote 매물 3뎁스에서 사용하는지 여부
 * @param type 동일주소매물 or 신고매물
 */
const EndButton = ({
  adSrl,
  callbackFn,
  setUpdatedAdSrl,
  forForm,
  forNote,
  type,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const [onRequestEnd, , dataEnd] = useAxios(endAdListing, {
    resetError: true,
  }) // 노출종료

  /**
   * 동일주소매물, 신고매물일 때 알림 내용
   */
  const getConfirmText = () => {
    const result = {
      동일주소매물: `매물번호 ${adSrl}의 네이버 광고가 노출 종료 됩니다.`,
      신고매물: `매물번호 ${adSrl}의 네이버 광고가 노출 종료되며, 노출 종료 후 경고가 부여됩니다.`,
    }

    return result[type]
  }

  const onClickEnd = (adSrl) => {
    Confirm.info({
      title: type ? '이 매물을 노출 종료할까요?' : '알림',
      content: type
        ? getConfirmText()
        : '이 매물을 노출종료 처리 하시겠습니까?',
      okText: '노출종료',
      cancelText: '취소',
      okButtonProps: { color: 'blue' },
      onOk: () => {
        onRequestEnd({
          ad_srl: adSrl,
          plan_srl: planInfo.allinone_plan_srl,
        })
        setUpdatedAdSrl && setUpdatedAdSrl(adSrl)
      },
    })
  }

  useEffect(() => {
    if (!dataEnd) return
    if (dataEnd?.result?.errormsg) {
      message.error(
        dataEnd?.result?.errormsg || '요청이 실패하였습니다.',
      )
    } else if (dataEnd?.result == 'error') {
      message.error(
        dataEnd?.result?.errormsg ||
          '광고 전송에 필요한 항목 검증에 실패하였습니다.',
      )
      return
    } else if (dataEnd?.result?.result == 'success') {
      message.success('노출종료 처리되었습니다.')
      callbackFn && callbackFn()
    } else {
      message.error('요청중 오류가 발생했습니다.')
      return
    }
  }, [dataEnd])

  return (
    <AdButton
      forForm={forForm}
      size={forForm ? 'xlarge' : 'small'}
      expand
      color="gray"
      onClick={() => onClickEnd(adSrl)}
      forNote={forNote}
    >
      노출종료
    </AdButton>
  )
}

export default EndButton
