import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  inviteeInfo: null,
  isEndSignModalVisible: false,
}

export const inviteRewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    setInviteeInfo(state, action) {
      state.inviteeInfo = action.payload
    },
    setIsEndSignModalVisible(state, action) {
      state.isEndSignModalVisible = action.payload
    },
  },
})

export const { setInviteeInfo, setIsEndSignModalVisible } =
  inviteRewardSlice.actions

export default inviteRewardSlice.reducer
