import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { CardProvider } from './context/CardContext'
import PropTypes from 'prop-types'

const Card = ({
  border = false,
  children,
  className,
  style,
  noMargin = false,
  margin = false,
  round = false,
  overflowHidden = false,
  size = 'medium',
  relative = false,
}) => {
  return (
    <CardProvider size={size}>
      <StyledCard
        className={className}
        style={style}
        border={border}
        noMargin={noMargin}
        margin={margin}
        round={round}
        overflowHidden={overflowHidden}
        relative={relative}
      >
        {children}
      </StyledCard>
    </CardProvider>
  )
}

const StyledCard = styled.article`
  background-color: ${theme.colors.base.white};
  border-bottom: 12px solid ${theme.colors.gray[100]};

  ${({ border }) =>
    border &&
    `
    border-bottom: 1px solid ${theme.colors.gray[200]};
    margin-bottom: 0;
  `}

  ${({ noMargin }) =>
    noMargin &&
    `
      border-bottom: none;
  `}

  
  ${({ relative }) =>
    relative &&
    `
      position: relative;
  `}

  ${({ margin }) =>
    margin &&
    `
    margin-bottom: ${margin};
  `}

  ${({ round }) =>
    round &&
    `
    margin: 1.25rem;
    border-radius: ${theme.borderRadius[3]};
    border-bottom: none;
  `}

  ${({ overflowHidden }) =>
    overflowHidden &&
    `
    overflow: hidden;
  `}
`

Card.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  noMargin: PropTypes.bool,
  margin: PropTypes.bool,
  round: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
}

export default Card
