import styled from 'styled-components'
import { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getFullUrl } from 'utils'
import theme from 'lib/styles/theme'
import { ReactComponent as MapIcon } from 'assets/icon/map.svg'
import { ReactComponent as MapIconActive } from 'assets/icon/map-active.svg'
import { ReactComponent as NoteIcon } from 'assets/icon/note.svg'
import { ReactComponent as NoteIconActive } from 'assets/icon/note-active.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icon/favorite.svg'
import { ReactComponent as FavoriteIconActive } from 'assets/icon/favorite-active.svg'
import NoteLinkContainer from 'containers/note/NoteLinkContainer'
import { message } from 'antd'
import SideMenuBasicItem from './SideMenuBasicItem'
import SideMenuNote from './SideMenuNote'

const SideMenuBasic = () => {
  const location = useLocation()
  const history = useHistory()

  const memberType =
    JSON.parse(localStorage.getItem('memberType')) || ''

  const channels = useSelector((state) => state.channel.channels)
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const onClickInMapRouter = (e, path) => {
    e.preventDefault()
    history.push(path)
  }

  const getNoteTo = () => {
    if (loginInfo?.member_srl) {
      if (localStorage.getItem('notemode') == 'card') {
        return getNoteUrlCard()
      } else {
        return getNoteUrlSheet()
      }
    } else {
      return '/note'
    }
  }

  const getNoteUrlCard = () => {
    // localStorage에 저장된 최근 접속한 채널srl과 folderSrl을 불러옵니다.
    const recentChannelSrl = localStorage.getItem('recentChannelSrl')
    const recentFolderSrl = localStorage.getItem('recentFolderSrl')

    // 최근 접속한 채널/폴더 정보가 있을 경우 유효성 체크 후 이동시킵니다.
    if (
      recentChannelSrl &&
      recentFolderSrl &&
      recentChannelSrl !== 'undefined' &&
      recentFolderSrl !== 'undefined'
    ) {
      const authorizedChannelSrl = channels.filter(
        ({ channel_srl }) => channel_srl === parseInt(recentChannelSrl),
      )[0]?.channel_srl

      // 채널 목록에 최근에 접속한 채널과 폴더 srl 값이 있는 경우
      if (authorizedChannelSrl) {
        return `/note/${authorizedChannelSrl}/${recentFolderSrl}`
      }
    }

    /**
     * 최근 접속한 채널/폴더 정보가 없을 경우 채널 목록에서
     * 로그인한 회원의 member_srl값과 동일한 채널의 폴더 목록으로 이동
     */
    const myChannel = channels?.filter(
      ({ member_srl }) => member_srl === parseInt(loginInfo.member_srl),
    )[0]

    const channelSrl = myChannel?.channel_srl
    const folderSrl = myChannel?.folder_srl

    if (!channelSrl || !folderSrl) {
      message.error(
        '일시적인 문제가 발생했습니다. 관리자에게 문의하십시오.',
      )
      return ''
    }

    return `/note/${channelSrl}/${folderSrl}`
  }

  const getNoteUrlSheet = () => {
    // localStorage에 저장된 최근 접속한 채널srl과 folderSrl을 불러옵니다.
    const recentChannelSrl = localStorage.getItem('recentChannelSrl')

    // 최근 접속한 채널/폴더 정보가 있을 경우 유효성 체크 후 이동시킵니다.
    if (recentChannelSrl && recentChannelSrl !== 'undefined') {
      const authorizedChannelSrl = channels.filter(
        ({ channel_srl }) => channel_srl === parseInt(recentChannelSrl),
      )[0]?.channel_srl

      // 채널 목록에 최근에 접속한 채널과 폴더 srl 값이 있는 경우
      if (authorizedChannelSrl) {
        return `/note/v2/notes/${authorizedChannelSrl}`
      }
    }

    /**
     * 최근 접속한 채널/폴더 정보가 없을 경우 채널 목록에서
     * 로그인한 회원의 member_srl값과 동일한 채널의 폴더 목록으로 이동
     */
    const myChannel = channels?.filter(
      ({ member_srl }) => member_srl === parseInt(loginInfo.member_srl),
    )[0]

    const channelSrl = myChannel?.channel_srl

    if (!channelSrl) {
      return ''
    }
    return `/note/v2/notes/${channelSrl}`
  }

  return (
    <>
      {/* 중개사 */}
      {((memberType && memberType === 'realestate') ||
        loginInfo?.member_type === 'realestate') && <SideMenuNote />}

      {/* 일반 */}
      {((!memberType && loginInfo?.member_type !== 'realestate') ||
        !memberType ||
        memberType !== 'realestate') && (
        <SideMenuBasicItem
          hasWrapper={false}
          to="/favorite"
          icon={<FavoriteIcon className="normalIcon" />}
          activeIcon={<FavoriteIconActive className="activeIcon" />}
          label={'즐겨찾기'}
        />
      )}
    </>
  )
}

export const MenuListItem = styled.li`
  position: relative;
  margin-left: -6px;
  margin-right: 20px;
  height: 100%;

  &:last-child {
    margin-right: 0;
  }

  & > a {
    display: flex;
    align-items: center;
    border-bottom: 3px solid transparent;
    padding: 6px 6px 3px 6px;
    height: 100%;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    transition: 0.2s ease;
    color: ${theme.colors.gray[900]};
    font-weight: 600;

    & > span {
      line-height: normal;
    }

    @media (max-width: 1323px) {
      padding: 6px 4px 3px 4px;
    }
  }

  ${({ hasChild }) =>
    hasChild &&
    `   
        &:hover {
          & > ul {
              opacity: 1;
              visibility: visible;
              user-select: initial;
              pointer-events: initial;
              transform: translateY(0);
          }
        }
      `}

  ${({ active }) =>
    active &&
    `   & > a{  
          color: ${theme.colors.primary[400]};
            font-weight: 700;
            border-bottom-color: ${theme.colors.primary[400]};

          & > i {
            color: ${theme.colors.primary[400]};
          }
          & > svg {
            fill: ${theme.colors.primary[400]};
          }
        } 
    `}
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  & > li:last-child {
    & > div,
    & > li {
      padding-right: 0;
      padding-left: 10px;
    }
  }
`

export default SideMenuBasic
