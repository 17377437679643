import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  summaryInfo: null,
  currentAddressId: '',
  centerPoint: null,
  isApt: false,
  selectedAptArea: null,
  isCallSizeReview: true,
  isCombination: false,
  isOpenNoteExternal: false,
  roadView: {
    isVisible: false,
    isSupport: true,
  },
}

export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    setSummaryInfo(state, actions) {
      state.summaryInfo = actions.payload
    },
    setCurrentAddressId(state, actions) {
      state.currentAddressId = actions.payload
    },
    setCenterPoint(state, actions) {
      state.centerPoint = actions.payload
    },
    setIsApt(state, actions) {
      state.isApt = actions.payload
    },
    setSelectedAptArea(state, actions) {
      state.selectedAptArea = actions.payload
    },
    setIsCallSizeReview(state, actions) {
      state.isCallSizeReview = actions.payload
    },
    setIsCombination(state, actions) {
      state.isCombination = actions.payload
    },
    setIsOpenNoteExternal(state, actions) {
      state.isOpenNoteExternal = actions.payload
    },
    setRoadView(state, actions) {
      state.roadView = { ...state.roadView, ...actions.payload }
    },
  },
})

export const {
  setSummaryInfo,
  setCurrentAddressId,
  setCenterPoint,
  setIsApt,
  setSelectedAptArea,
  setIsCallSizeReview,
  setIsCombination,
  setIsOpenNoteExternal,
  setRoadView,
} = summarySlice.actions

export default summarySlice.reducer
