import { useEffect, useState } from 'react'
import theme from 'lib/styles/theme'
import {
  Cell,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from 'recharts'
import styled from 'styled-components'
import { uniqBy } from 'lodash'

const SummaryHcCharacterTabTurnover = ({ data: originData }) => {
  const [chartData, setChartData] = useState([])
  const [position, setPosition] = useState(null)

  useEffect(() => {
    const parseData = Object.entries({ ...originData }).map(
      ([key, value]) => ({
        year: key,
        value,
      }),
    )

    const maxThreeDataArr = getMaxValues(parseData)
    const maxThreeYear = maxThreeDataArr.map(({ year }) => year)
    const maxThreeValue = maxThreeDataArr.map(({ value }) => value)

    const calcData = parseData.map((item, idx, oriArr) => {
      const cutYear = "'" + item.year.replace('년', '').slice(2, 4)
      const numberYear = Number(item.year.replace('년', ''))
      const value = Number((item.value[0] * 100).toFixed(1))

      return {
        year: cutYear,
        value: value,
        label: `${value}% / ${item.value[1]}건`,
        hasColor:
          maxThreeYear.includes(numberYear) &&
          maxThreeValue.includes(item.value[0]),
      }
    })

    setChartData(calcData.slice(calcData.length - 8, calcData.length))
  }, [])

  const getMaxValues = (data) => {
    const uniqueValueArr = uniqBy(
      [...data].map((item) => {
        return {
          year: Number(item.year.replace('년', '')),
          value: item.value[0],
        }
      }),
    )

    const uniqueMaxValueArr = [
      ...uniqueValueArr
        .reduce(
          (map, o) =>
            map.has(o.value) && map.get(o.value).year > o.year
              ? map
              : map.set(o.value, o),
          new Map(),
        )
        .values(),
    ]

    //가장 높은 3가지 값을 가져옴
    const maxThreeDataArr = uniqueMaxValueArr
      .sort((a, b) => b.value - a.value)
      .splice(0, 3)

    return maxThreeDataArr
  }

  const CustomTooltip = ({ active, payload, point }) => {
    if (
      active &&
      payload &&
      payload.length > 0 &&
      payload[0].payload.label
    ) {
      return (
        <CustomTooltipWrapper>
          {payload[0].payload.label}
        </CustomTooltipWrapper>
      )
    }

    return null
  }

  /**
   * 툴팁 hover 높이 조정
   */
  useEffect(() => {
    const tooltip = document.querySelector('.recharts-tooltip-wrapper')

    if (!tooltip) return

    const height = tooltip.getBoundingClientRect().height
    const width = tooltip.getBoundingClientRect().width
    const spaceForLittleTriangle = 10

    tooltip.style = `
      transform: translate(${position?.data.x}px, ${
      position?.data.y
    }px);
      pointer-events: none;  position: absolute;
      top: ${
        position?.data.y > 110 ? -(height + spaceForLittleTriangle) : 20
      }px;
      left: -${width / 2 - position?.data.width / 2}px;
      opacity: ${position?.show ? '1' : 0};
      transition: all 400ms ease 0s;
    `
  }, [position])

  return (
    <Wrapper>
      <ExplainText>
        거래회전율 = 한 해 매매 건수 / 단지 필지 수
      </ExplainText>
      <ResponsiveContainer id="barChart" width="100%" height={250}>
        {chartData.length > 0 && (
          <BarChart
            margin={{ left: -17 }}
            width="100%"
            height={250}
            data={chartData}
          >
            <CartesianGrid strokeDasharray="1" vertical={false} />
            <Tooltip
              cursor={false}
              position={{
                x: position?.data.x ?? 0,
                y: position?.data.y ?? 0,
              }}
              content={<CustomTooltip />}
              location={'edge'}
            />
            <XAxis tickLine={false} axisLine={false} dataKey="year" />
            <YAxis
              unit="%"
              domain={[0, 'dataMax']}
              allowDataOverflow={true}
              tickLine={false}
              axisLine={false}
            />
            <Bar
              dataKey="value"
              barSize={20}
              radius={[4, 4, 0, 0]}
              onMouseMove={(data) =>
                setPosition({ data: data, show: true })
              }
              onMouseLeave={(data) =>
                setPosition({ data: data, show: false })
              }
            >
              {chartData.map(({ hasColor }, idx) => {
                return (
                  <Cell
                    key={idx}
                    fill={
                      hasColor
                        ? theme.colors.primary[400]
                        : theme.colors.gray[400]
                    }
                  />
                )
              })}
            </Bar>
          </BarChart>
        )}
      </ResponsiveContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: 0.75rem;
  color: ${theme.colors.gray[500]};
`

const CustomTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 24px;
  min-width: 75px;
  background-color: ${theme.colors.base.white};
  color: ${theme.colors.gray[900]} !important;
  font-weight: 500;
  font-size: 0.625;
  border-radius: 4px;
  border: 1px solid ${theme.colors.gray[200]};
  box-shadow: rgb(0 0 0 / 5%) 0 1px 2px, rgb(0 0 0 / 5%) 0 6px 32px;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 0;
    border-width: 5px 5px 0px 5px;
    border-color: ${theme.colors.base.white} transparent;
    border-style: solid;
    position: absolute;
    bottom: -4px;
    right: 50%;
    transform: translateX(50%);
    box-shadow: rgb(0 0 0 / 5%) 0 1px 2px, rgb(0 0 0 / 5%) 0 6px 32px;
    z-index: 10;
  }
`

const ExplainText = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  margin: 1rem 0;
`

export default SummaryHcCharacterTabTurnover
