import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getChannel } from 'lib/api/note/channelApi'
import { setChannelInfo } from 'modules/channel'
import Log from 'lib/utils/Log'
import useAxios from 'lib/hooks/useAxios'

export default function useChannelInfo() {
  const dispatch = useDispatch()
  const [onRequest, loading, data, error, reset] = useAxios(getChannel)

  const _getChannel = useCallback(
    async ({ channelSrl }, cancelToken) => {
      await reset()
      await dispatch(setChannelInfo(null))
      await onRequest({ channelSrl }, cancelToken)
    },
    [onRequest],
  )

  /**
   * @WARN: 채널 정보를 불러올 수 없을 때는 result가 null이거나
   * error를 반환해야 합니다.
   */
  useEffect(() => {
    if (!data) return

    Log.success('useChannelInfo', data)

    if (!data?.result) {
      dispatch(setChannelInfo(null))
      return
    }

    dispatch(setChannelInfo(data.result))
  }, [data])

  return { _getChannel, loading }
}
