import {
  MainWrapperStyle,
  MobileCover,
  SwipeableLeftStyle,
  SwipeableMainWrapper,
} from 'components/jmapnotev2/style/NoteStyle'
import React, { useRef } from 'react'
import NoteLeftSide from 'components/jmapnotev2/NoteList/NoteLeftSide'
import NoteList from 'components/jmapnotev2/NoteList/NoteList'
import NoteListResizeContainer from 'containers/notev2/NoteListResizeContainer'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import NoteLayerSwipeContainer from 'containers/notev2/NoteLayerSwipeContainer'
import { withRouter } from 'react-router-dom'
import { Resizable } from 're-resizable'
import NoteListPropertyFilterContainer from 'containers/notev2/NoteListPropertyFilterContainer'
import { useSelector } from 'react-redux'
import NoteListContainer from 'containers/notev2/NoteListContainer'

const NoteListPage = () => {
  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )
  const height = useSelector((state) => state.notice.height)
  const isShowMap = useSelector(
    (state) => state.notev2.noteListIsShowMap,
  )

  const gridRef = useRef(null)

  return (
    <NoteLayerSwipeContainer>
      {/** 모바일 swipe container */}
      {({
        listLayerRef,
        onSwipeStartFilter,
        onSwipeMoveFilter,
        onSwipeEndFilter,
        onSwipeStartList,
        onSwipeMoveList,
        onSwipeEndList,
        isOpenDetailRouter,
      }) => (
        <>
          <NoteListResizeContainer>
            {/** 시트 레이어 크기 조정 container  */}
            {({
              setButtonWidth,
              width,
              setWidth,
              isResizable,
              setResizable,
              resetLayer,
              onResizeStop,
              FilterNav,
            }) => (
              <NoteListPropertyFilterContainer>
                {/** 필터 refresh 등 container  */}
                {({ searchAndSetPropertyFilters, filtersCount }) => (
                  <NoteListContainer
                    ref={gridRef}
                    searchAndSetPropertyFilters={
                      searchAndSetPropertyFilters
                    }
                  >
                    {/** 시트 목록 조회 container  */}
                    {({
                      search,
                      bindSearch,
                      resetSearch,
                      onSearch,
                      searchFn,
                      searchFnWithParam,
                      gridRowData,
                      isLoading,
                      clearSearch,
                    }) => (
                      <>
                        {/** 1뎁스 필터 */}
                        <NoteLeftSide
                          onSwipeStart={onSwipeStartFilter}
                          onSwipeMove={onSwipeMoveFilter}
                          onSwipeEnd={onSwipeEndFilter}
                          searchAndSetPropertyFilters={
                            searchAndSetPropertyFilters
                          }
                          searchFnWithParam={searchFnWithParam}
                          filtersCount={filtersCount}
                        />

                        {/** 2뎁스 레이어 크기 조정 wrapper */}
                        <StyleResizable
                          isnoticebarvisible={
                            isNoticeBarVisible ? 'Y' : 'N'
                          }
                          noticebarheight={height}
                          ref={listLayerRef}
                          enable={{
                            right: isResizable,
                          }}
                          size={{
                            width: localStorage.getItem('twoDepthWidth')
                              ? localStorage.getItem('twoDepthWidth')
                              : width,
                            height: '100%',
                          }}
                          onResizeStop={(e, direction, ref, d) => {
                            localStorage.setItem(
                              'twoDepthWidth',
                              ref.offsetWidth,
                            )
                            setButtonWidth(
                              `calc(${ref.offsetWidth}px + 320px)`,
                            )
                            setWidth(
                              `calc(${ref.offsetWidth}px + 320px)`,
                            )
                            onResizeStop(ref.offsetWidth)
                          }}
                          hidden={isShowMap}
                        >
                          {/** 시트 목록 */}
                          <NoteList
                            ref={{
                              listLayerRef: listLayerRef,
                              gridRef: gridRef,
                            }}
                            setResizable={setResizable}
                            resetLayer={resetLayer}
                            searchFnWithParam={searchFnWithParam}
                            onSwipeStart={(e) =>
                              onSwipeStartList(e, true)
                            }
                            onSwipeMove={onSwipeMoveList}
                            onSwipeEnd={onSwipeEndList}
                            isOpenDetailRouter={isOpenDetailRouter}
                            searchAndSetPropertyFilters={
                              searchAndSetPropertyFilters
                            }
                            search={search}
                            bindSearch={bindSearch}
                            resetSearch={resetSearch}
                            onSearch={onSearch}
                            searchFn={searchFn}
                            gridRowData={gridRowData}
                            isLoading={isLoading}
                            clearSearch={clearSearch}
                          />
                        </StyleResizable>
                        {/** 모바일 resize 덮개(검정) */}
                        <StyledMobileCover
                          className="m-cover"
                          hidden={isShowMap}
                        />
                        <FilterNav
                          searchFnWithParam={searchFnWithParam}
                        />
                      </>
                    )}
                  </NoteListContainer>
                )}
              </NoteListPropertyFilterContainer>
            )}
          </NoteListResizeContainer>
        </>
      )}
    </NoteLayerSwipeContainer>
  )
}

const StyledMobileCover = styled(MobileCover)`
  z-index: 14;
`

const StyleResizable = styled(Resizable)`
  ${MainWrapperStyle}
  max-width: calc( 100% - ${theme.base.note.filterWidth}px - ${theme
    .base.navWidth}px);
  min-width: 488px;

  box-shadow: 9px 0 10px -6px rgba(0, 0, 0, 0.25);

  ${media.smallPc`
    max-width: calc(100% - ${theme.base.note.filterWidth}px);
    min-width: calc(
      calc(100% - ${theme.base.note.filterWidth}px) / 2
    );
  `};

  ${({ isnoticebarvisible, noticebarheight }) =>
    isnoticebarvisible == 'Y'
      ? `top: ${noticebarheight}px !important; 
        height: calc(100% - ${noticebarheight}px) !important;`
      : `top: 0;`};

  ${SwipeableMainWrapper}

  ${SwipeableLeftStyle}

  ${media.mediumS`
      ${({ hidden }) =>
        hidden &&
        `
        display: none;
        
        +.m-cover {
          display: none;
        }
      `}
  `}
`

export default React.memo(withRouter(NoteListPage))
