import theme from 'lib/styles/theme'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import 'intro.js/introjs.css'
import { useDispatch } from 'react-redux'

import {
  setAddressCellState,
  setOnBoardingStart,
  setOnBoardingState,
} from 'modules/onBoarding'
import { useSelector } from 'react-redux'
import OnBoarding from '../OnBoarding/OnBoarding'

const NoteGridNewLineRenderer = ({
  isNote,
  onClickNewLineAdd,
  text = '신규 매물 주소 입력',
}) => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  //온보딩관련코드
  const onBoardingStart = useSelector(
    (state) => state.onBoarding.onBoardingStart,
  )
  //온보딩관련코드
  const [stepsEnabled, setStepsEnalbed] = useState()
  const dispatch = useDispatch()

  const noteListInfo = useSelector((state) => state.notev2.noteListInfo)
  //온보딩관련코드
  const [steps, setSteps] = useState([])

  //웰컴모달창에서 매물노트 시작하기 버튼누르면 온보딩이 시작하도록 설정
  useEffect(() => {
    if (onBoardingStart && noteListInfo.count == 0) {
      setStepsEnalbed(true)
    }
  }, [onBoardingStart, noteListInfo.count])
  //온보딩꺼지면 실행함수
  const onExit = () => {
    setStepsEnalbed(false)
    dispatch(setOnBoardingStart(false))
    dispatch(setAddressCellState(false))
  }

  return (
    <Wrapper
      note={isNote ? 'Y' : 'N'}
      readonly={channelInfo?.member_type == 'readonly' ? 'Y' : 'N'}
      onClick={onClickNewLineAdd}
    >
      <OnBoarding
        onComplete={() => {
          dispatch(setOnBoardingStart(false))
          onClickNewLineAdd() //밑줄 셀추가함수
          setTimeout(() => {
            dispatch(setAddressCellState(true))
            setStepsEnalbed(false)
          }, 1)
        }}
        enabled={stepsEnabled}
        steps={steps}
        setSteps={setSteps}
        onExit={onExit}
        options={{
          hidePrev: true,
          nextLabel: '확인',
          prevLabel: '이전',
          doneLabel: '확인',
          showBullets: false,
          buttonClass: 'customButton',
        }}
      />
      <AddWrapper className="add-wrapper">
        <i className="fas fa-plus"></i>
        {text}
      </AddWrapper>
    </Wrapper>
  )
}

const AddWrapper = styled.div`
  width: 440px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  color: #0038ff;
  background-color: ${theme.colors.base.white};
  font-size: 14px;
  font-weight: 600;
  // z-index: 9999999;
  cursor: pointer;
  border-left: 1px solid ${theme.colors.gray[900]}1A;

  .custom-multiple-cell {
    background-color: red;
  }

  ${({ note, readonly }) =>
    note == 'Y' &&
    readonly == 'Y' &&
    `color: ${theme.colors.gray[400]};
      cursor: default;
    `};
`
export default React.memo(NoteGridNewLineRenderer)
