import AppContainer from './AppContainer'
import { SectionWrapper, SubTitle, Title } from './LandingStyle'
import styled, { keyframes } from 'styled-components'
import LogoSrc from 'assets/images/note/landing/dongho-logo.png'
import DonghoBlockSrc from 'assets/images/note/landing/dongho-block.png'
import DonghoRpSrc from 'assets/images/note/landing/dongho-rp.png'
import DonghoDetailSrc from 'assets/images/note/landing/dongho-detail.png'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useRef } from 'react'

const LandingDongho = () => {
  const willAnimatesRef = useRef([])
  const wrapperRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            willAnimatesRef.current.forEach((elem) => {
              elem.classList.add('animate')
            })
          }
        })
      },
      {
        threshold: 0.2,
      },
    )

    observer.observe(wrapperRef.current)
  }, [])

  return (
    <SectionWrapper ref={wrapperRef}>
      <AppContainer>
        <TitleWrapper>
          <LogoImg src={LogoSrc} />
          <StyledTitle>
            단지 관리의 새로운 기준
            <NewBadge>New</NewBadge>
          </StyledTitle>
          <SubTitle>
            아파트 단지의 호실별 실거래가, 점유형태, 계약 만기 등의
            <br />
            정보를 통해 효율적인 임장 활동이 가능해요
          </SubTitle>
        </TitleWrapper>
        <Content>
          <ContentTop>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[0] = el)}
              order="1"
            >
              <ContentText order="1">
                <div>
                  <span>점유형태와 계약 만기 정보</span>
                </div>
                계약 만기가 다가오는 세대를
                <br />
                선점할 수 있는 기회를 놓치지 마세요.
              </ContentText>
              <ContentImg order="1" src={DonghoBlockSrc} />
            </ContentWrapper>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[1] = el)}
              order="2"
            >
              <ContentText order="2">
                <span>호실별 실거래가 내역</span>
                <br />
                우리 단지의 정확한 시세 파악은
                <br />
                단지 관리의 핵심 도구이죠.
              </ContentText>
              <ContentImg order="2" src={DonghoRpSrc} />
            </ContentWrapper>
          </ContentTop>
          <ContentWrapper
            ref={(el) => (willAnimatesRef.current[2] = el)}
            order="3"
          >
            <ContentText order="3">
              <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Group 39122">
                  <rect
                    id="Rectangle 4569"
                    width="45.9897"
                    height="45.9897"
                    rx="0.7"
                    fill="#B4AEE5"
                  />
                  <rect
                    id="Rectangle 4353"
                    x="2.87439"
                    y="31.6182"
                    width="14.3718"
                    height="11.4974"
                    rx="0.7"
                    fill="#F9FAFB"
                  />
                  <rect
                    id="Rectangle 4570"
                    x="20.1205"
                    y="31.6182"
                    width="22.9949"
                    height="11.4974"
                    rx="0.7"
                    fill="#F9FAFB"
                  />
                  <rect
                    id="Rectangle 4571"
                    x="2.87439"
                    y="14.3716"
                    width="14.3718"
                    height="14.3718"
                    rx="0.7"
                    fill="#F9FAFB"
                  />
                  <rect
                    id="Rectangle 4572"
                    x="20.1205"
                    y="14.3716"
                    width="22.9949"
                    height="14.3718"
                    rx="0.7"
                    fill="#F9FAFB"
                  />
                </g>
              </svg>
              <div>내 매물과 연동</div>
              내 매물의 위치를
              <br />
              아파트 템플릿에서
              <br />
              한 번에 확인하세요.
              <br />
            </ContentText>
            <ContentImg order="3" src={DonghoDetailSrc} />
          </ContentWrapper>
        </Content>
      </AppContainer>
    </SectionWrapper>
  )
}

const floatAnimation = keyframes`
 	from {
		opacity: 0;
		transform: translateY(10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`

const TitleWrapper = styled.div`
  position: relative;
  text-align: center;
`

const LogoImg = styled.img`
  width: 142px;
  height: 54px;
  object-fit: contain;

  ${media.mediumS`
        width: 120px;
        height: auto;
        object-fit: contain;           
    `}
`

const Content = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;

  ${media.mediumS`
      margin-top: 34px;        
  `}
`

const ContentTop = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  height: auto;

  ${media.mediumM`
        flex-direction: column;
        height: auto;   
    `}
`

const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  padding: 45px 40px;
  font-weight: 700;
  opacity: 0;
  min-height: 550px;

  ${media.mediumS`
      padding: 32px 28px;   
  `}

  &.animate {
    animation: ${floatAnimation} 0.8s ease 0.1s 1 normal backwards;
    opacity: 1;
  }

  ${({ order }) => {
    switch (order) {
      case '1':
        return `
            display: flex;
            flex-direction: column;
            width: 55%;
            background: linear-gradient(139deg, #F8EAFF 24.66%, #B9A0FF 88.99%);

            @media (max-width: ${theme.bp.mediumM}px) {
                width: 100%;
                height: 500px;  
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                height: auto;   
                min-height: 420px;  
            }
        `
      case '2':
        return `
            position: relative;
            background-color: #F5F5FB;
            width: 45%;
            animation-delay: 0.3s !important;

            
            @media (max-width: ${theme.bp.mediumM}px) {
                width: 100%;
                height: 580px;  
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                height: 400px;   
                min-height: unset;  
            }
        `
      case '3':
        return `
            position: relative;
            width: 100%;
            background: #EEECFE;
            animation-delay: 0.5s !important;
            min-height: 410px;

            svg {
                margin-bottom: 10px;

                @media (max-width: ${theme.bp.mediumM}px) {
                    width: 32px; 
                    margin-bottom: 0;
                }
                
                @media (max-width: ${theme.bp.mediumS}px) {
                    width: 28px; 
                    margin-bottom: -6px;
                }
            }

            @media (max-width: ${theme.bp.mediumM}px) {
                height: 590px;  
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                height: 420px;
                min-height: unset;  
            };
        `
    }
  }};
`

const ContentText = styled.div`
  font-size: 28px;
  line-height: 140%;

  ${media.mediumM`
    font-size: 26px;
  `}

  ${({ order }) => {
    switch (order) {
      case '1':
        return `
        color: #9D8AC0;
        font-weight: 700;

        span {
          font-weight: 800;
          background: linear-gradient(90deg, #C86FFF 0.1%, #2D42FF 137.12%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        `
      case '2':
        return `
            text-align: center;
            color: #C7C7C7;
            font-weight: 700;

            span {
              color: #4B4B4B;
              font-weight: 800;
            }
        `
      case '3':
        return `
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #B4AEE5;
            font-weight: 600;

            div {
              font-weight: 800;
              color: #585EDF;
            }

             @media (max-width: ${theme.bp.mediumM}px) {
              transform: none;
              top: initial;
            }
        `
    }
  }};

  ${media.mediumM`
      word-break: keep-all;
  `}

  ${media.mediumS`
      font-size: 20px;
  `}
`

const ContentImg = styled.img`
  ${({ order, type }) => {
    switch (order) {
      case '1':
        return `
            max-width: 90%;
            height: auto;
            position: absolute;
            right: 0;
            bottom: 0;
            max-height: 65%;
          `
      case '2':
        return `
            max-width: 403px;
            height: auto;
            max-height: 65%;
            object-fit: contain;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: ${theme.bp.mediumM}px) {
                max-width: 90%;
            }
        `
      case '3':
        return `
            max-width: 50%;
            height: auto;
            position: absolute;
            bottom: 0;
            right: 24px;

            @media (max-width: ${theme.bp.mediumM}px) {
                max-width: 75%;
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                max-width: 95%;
                left: 50%;
                transform: translateX(-50%);
            }
        `
    }
  }};
`

const NewBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 8px 10px;
  border-radius: 999px;
  background-color: #1f2e3d;
  color: ${theme.colors.base.white};
  font-size: 18px;
  margin-top: 10px;
`

const StyledTitle = styled(Title)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`

export default LandingDongho
