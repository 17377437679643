import { useMemo, useState } from 'react'
import NoteContactModify from './NoteContactModify'
import NoteContactItemBase from './NoteContactItemBase'
import { Tooltip } from 'antd'
import { Button, ListItemDivider } from 'components/design'

const NoteContactListItem = ({ item }) => {
  const [visible, setVisible] = useState(false)

  const onClickItem = () => {
    setVisible(true)
  }

  return (
    <>
      <NoteContactItemBase
        type={item?.type}
        alias={item?.alias}
        numbers={item?.numbers}
        onClick={onClickItem}
        append={
          item?.numbers?.length > 0 && (
            <>
              <ListItemDivider xs />
              <Tooltip title="전화하기">
                <a href={`tel:${item?.numbers[0]?.number}`}>
                  <Button circle>
                    <i className="fas fa-phone"></i>
                  </Button>
                </a>
              </Tooltip>
            </>
          )
        }
      />

      <NoteContactModify
        title="연락처 수정"
        defaultValue={item}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  )
}

export default NoteContactListItem
