import { createSlice } from '@reduxjs/toolkit'

const month = new Date().getMonth() + 1

const initialState = {
  folders: [],
  addresses: [],
  notes: [],
  note: null,
  noteSearchQuery: '',
  noteSort: '',
  viewAllChannelNotes: false,
  openedMarker: [],
  filterStates: {
    filter_channel: '',
    status: 0,
    property_types: [],
    contract_types: [],
    sale_price: [0, 200],
    rent: [0, 200],
    area: [0, 70],
    move_date: [month, month + 11],
    floor_cnt: '',
    extra_options: [],
  },
  needToNoteRefresh: false,
  noteMarkers: null,
  noteMarkersCount: 0,
  clickedMarker: [],
  manuallyCloseMarker: [],
  specificOpenedMarker: null,
}

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    setFolders(state, actions) {
      state.folders = actions.payload
    },
    setAddresses(state, actions) {
      state.addresses = actions.payload
    },
    setNotes(state, actions) {
      state.notes = actions.payload
    },
    setNote(state, actions) {
      state.note = actions.payload
    },
    setNoteSearchQuery(state, actions) {
      state.noteSearchQuery = actions.payload
    },
    setNoteSort(state, actions) {
      state.noteSort = actions.payload

      localStorage.setItem('noteListSort', actions.payload)
    },
    setViewAllChannelNotes(state, actions) {
      state.viewAllChannelNotes = actions.payload
    },

    setFilterChannel(state, actions) {
      state.filterStates.filter_channel = actions.payload
    },
    setFilterStatus(state, actions) {
      state.filterStates.status = actions.payload
    },
    setFilterPropertyTypes(state, actions) {
      state.filterStates.property_types = actions.payload
    },
    setFilterContractTypes(state, actions) {
      state.filterStates.contract_types = actions.payload
    },
    setFilterSalePrice(state, actions) {
      state.filterStates.sale_price = actions.payload
    },
    setFilterRent(state, actions) {
      state.filterStates.rent = actions.payload
    },
    setFilterArea(state, actions) {
      state.filterStates.area = actions.payload
    },
    setFilterMoveDate(state, actions) {
      state.filterStates.move_date = actions.payload
    },
    setFilterFloorCnt(state, actions) {
      state.filterStates.floor_cnt = actions.payload
    },
    setFilterExtraOptions(state, actions) {
      state.filterStates.extra_options = actions.payload
    },
    setNeedToNoteRefresh(state, actions) {
      state.needToNoteRefresh = actions.payload
    },
    setNoteMarkers(state, actions) {
      state.noteMarkers = actions.payload
    },
    setNoteMarkersCount(state, actions) {
      state.noteMarkersCount = actions.payload
    },
    setClickedMarker(state, actions) {
      state.clickedMarker = [
        ...new Set([...state.clickedMarker, actions.payload]),
      ]
    },
    resetClickedMarker(state, actions) {
      state.clickedMarker = actions.payload?.except
        ? [actions.payload?.except]
        : []
    },
    setOpenedMarkerAll(state, actions) {
      state.openedMarker = actions.payload
    },
    setOpenedMarker(state, actions) {
      state.openedMarker = [
        ...new Set([...state.openedMarker, actions.payload]),
      ]
    },
    removeOpenedMarker(state, actions) {
      state.openedMarker = [
        ...new Set(
          state.openedMarker.filter((e) => e !== actions.payload),
        ),
      ]
    },
    resetOpenedMarker(state, actions) {
      state.openedMarker = actions.payload?.except
        ? actions.payload?.except
        : []
    },
    removeClickedMarker(state, actions) {
      state.clickedMarker = [
        ...new Set(
          state.clickedMarker.filter((e) => e !== actions.payload),
        ),
      ]
    },
    setManuallyCloseMarker(state, actions) {
      state.manuallyCloseMarker = [
        ...new Set([...state.manuallyCloseMarker, actions.payload]),
      ]
    },
    resetManuallyCloseMarker(state, actions) {
      state.manuallyCloseMarker = []
    },
    removeManuallyCloseMarker(state, actions) {
      state.manuallyCloseMarker = [
        ...new Set(
          state.manuallyCloseMarker.filter(
            (e) => e !== actions.payload,
          ),
        ),
      ]
    },
    setSpecificOpenedMarker(state, actions) {
      state.specificOpenedMarker = actions.payload
    },
    resetSpecificOpenedMarker(state, actions) {
      state.specificOpenedMarker = null
    },
  },
})

export const {
  setFolders,
  setAddresses,
  setNotes,
  setNote,
  setNoteSearchQuery,
  setNoteSort,
  setViewAllChannelNotes,
  setFilterChannel,
  setFilterStatus,
  setFilterPropertyTypes,
  setFilterContractTypes,
  setFilterSalePrice,
  setFilterRent,
  setFilterArea,
  setFilterMoveDate,
  setFilterFloorCnt,
  setFilterExtraOptions,
  setNeedToNoteRefresh,
  setNoteMarkers,
  setNoteMarkersCount,
  setClickedMarker,
  setOpenedMarker,
  setOpenedMarkerAll,
  resetOpenedMarker,
  removeOpenedMarker,
  resetClickedMarker,
  removeClickedMarker,
  setManuallyCloseMarker,
  resetManuallyCloseMarker,
  removeManuallyCloseMarker,
  setSpecificOpenedMarker,
  resetSpecificOpenedMarker,
} = noteSlice.actions

export default noteSlice.reducer
