import theme, { media } from 'lib/styles/theme'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'
import { Button } from 'components/design'
import { useSelector } from 'react-redux'

const Dialog = ({
  children,
  visible,
  title,
  resetButton = false,
  onReset,
  closeButton = true,
  onClose,
  tab,
}) => {
  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)

  return ReactDOM.createPortal(
    <Wrapper
      visible={visible}
      isNoticeBarVisible={isNoticeBarVisible}
      noticeBarHeight={height}
    >
      <Container>
        <Header>
          <HeaderTitle>
            {title}
            {resetButton && (
              <ResetButton size="small" color="gray" onClick={onReset}>
                <i className="fa fa-undo fa-xs"></i>전체 초기화
              </ResetButton>
            )}
          </HeaderTitle>

          {closeButton && (
            <CloseButton onClick={onClose}>
              <i className="fal fa-times"></i>
            </CloseButton>
          )}
        </Header>
        {tab && tab}
        <Scrollbars
          autoHide
          width="100%"
          style={{
            height: `calc(100% - 62px)`,
          }}
          // autoHeight
          // autoHeightMin={200}
          // autoHeightMax={autoHeightMax()}
        >
          <Body tab={tab ? true : false}>{children}</Body>
        </Scrollbars>
      </Container>
    </Wrapper>,
    document.getElementById('modal-dialog'),
  )
}

const Wrapper = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  position: fixed;
  top: calc(
    ${theme.base.headerHeight}px + 20px +
      (
        ${({ isNoticeBarVisible, noticeBarHeight }) =>
          isNoticeBarVisible ? `${noticeBarHeight}px` : `0px`}
      )
  );
  right: 90px;
  z-index: 22;
  align-items: flex-end;
  width: 400px;
  height: 80vh;

  ${media.mediumS`
    width: 100%;
    padding: 0;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}

  @media screen and (max-height: 800px) {
    height: calc(95% - ${theme.base.bottomMenuHeight}px);
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 12px;
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['card-2']};

  ${media.mediumS`
    height: 98%;
    margin: 0 1rem;
  `}
`
const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 9px;
  height: ${theme.base.headerHeight}px;
`

const HeaderTitle = styled.div`
  flex: 1;
  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
  font-weight: 700;

  & > button {
    margin-left: 15px;
  }
`
const ResetButton = styled(Button)`
  color: ${theme.colors.gray[500]};
  font-size: 12px;
  font-weight: 500;

  i {
    margin-right: 4px;
    font-weight: 500;
  }
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  color: ${theme.colors.gray[600]};
  font-size: 1rem;
  background-color: ${theme.colors.gray[100]};
  border-radius: 6px;
`

const Body = styled.div`
  padding: ${({ tab }) =>
    tab ? `4rem 1.25rem 1.5rem` : `0 1.25rem 1.5rem`};

  ${media.mediumS`
    margin-top: 1rem;
  `};
`
export default Dialog
