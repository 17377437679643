import theme from 'lib/styles/theme'
import moment from 'moment'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 올인원 결제 확인창
 * @param month 개월
 * @param price 가격
 */
const NotePaymentAllinoneConfirm = ({ totalPrice, card, data }) => {
  return (
    <>
      {data.map(({ period, item_name, amount }, i) => {
        return (
          <Wrapper key={i} border={i != data.length - 1}>
            <Price>
              <div>{item_name}</div>
              <div>{numberWithCommas(amount)}원</div>
            </Price>
            <Date>
              제공기간&nbsp;&#58;&nbsp;
              {period}
            </Date>
          </Wrapper>
        )
      })}
      {card && (
        <Card>
          {card?.issuerName}
          {card?.number}
        </Card>
      )}
      <Price border={!card}>
        <div>최종 결제금액</div>
        <div>{numberWithCommas(totalPrice)}원</div>
      </Price>
    </>
  )
}

const Wrapper = styled.div`
  margin-bottom: 12px;
  ${({ border }) =>
    border &&
    `
      border-bottom: 1px dashed ${theme.colors.gray[400]};
  `}
`

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;

  ${({ border }) =>
    border &&
    `
    padding-top: 18px;
    border-top: 1px solid ${theme.colors.gray[200]};
  `}
`

const Date = styled.div`
  margin-bottom: 12px;
  color: ${theme.colors.gray[600]};
  font-size: 13px;
  font-weight: 600;
  line-height: 130%;
`

const Card = styled.div`
  padding: 12px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.gray[100]};
  font-weight: 500;
  line-height: 130%;
  border-radius: 6px;
`

export default NotePaymentAllinoneConfirm
