import { Tab, TabItem } from 'components/design'

const SummaryAmenitiesTab = ({ activeTab, onChangeTab }) => {
  return (
    <Tab size="medium" noContainer>
      <TabItem
        expand
        active={activeTab === 'transportation'}
        onClick={() => onChangeTab('transportation')}
      >
        교통
      </TabItem>
      <TabItem
        expand
        active={activeTab === 'hospital'}
        onClick={() => onChangeTab('hospital')}
      >
        의료
      </TabItem>
      <TabItem
        expand
        active={activeTab === 'shop'}
        onClick={() => onChangeTab('shop')}
      >
        생활
      </TabItem>
      <TabItem
        expand
        active={activeTab === 'restaurant'}
        onClick={() => onChangeTab('restaurant')}
      >
        음식점
      </TabItem>
      <TabItem
        expand
        active={activeTab === 'safety'}
        onClick={() => onChangeTab('safety')}
      >
        안전
      </TabItem>
    </Tab>
  )
}

export default SummaryAmenitiesTab
