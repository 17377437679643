import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  getFormatedArea,
  getFormatedAreaSecondDigit,
  getUseAreaFormat,
} from 'utils'
import { Tooltip } from 'antd'
import {
  HelpCardHeader,
  Title,
  Price,
  HelpIcon,
  CustomUl,
} from '../SummarySizeReview/SummarySizeReviewStyle'
import useIsMobile from 'lib/hooks/useIsMobile'
import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'
import {
  CardHeader,
  CardBody,
  Modal,
  Card,
  Button,
} from 'components/design'
import styled from 'styled-components'
import { getAddressName } from 'lib/utils'

const SummaryNohuArea = ({ refresh, buildings, law_info }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const totalFloorAreaDiff =
    buildings[0]?.after_total_floor_area -
    buildings[0]?.before_total_floor_area

  const [isSizeInfoHide, setIsSizeInfoHide] = useState(true)
  const [isTotalFloorAreaHide, setIsTotalFloorAreaHide] = useState(true)
  const [isRealTotalFloorAreaHide, setIsRealTotalFloorAreaHide] =
    useState(true)

  const getRepairKorean = (value) => {
    const result = {
      wall: '내력벽',
      stair: '주계단',
      bo: '보',
      roof_base: '지붕틀',
      pillar: '기둥',
    }

    return result[value] ? result[value] : ''
  }

  const onClickScan = () => {
    alert('서비스 준비중입니다')
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Title>
            {getFormatedArea(
              Math.round(parseFloat(totalFloorAreaDiff)),
              isSquareMeter,
            )}
            까지 증축할 수 있어요
          </Title>
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <td>구분</td>
                <td>BEFORE</td>
                <td>AFTER</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>건축면적</td>
                <td>
                  {getFormatedAreaSecondDigit(
                    buildings[0]?.before_construct_area,
                    isSquareMeter,
                  )}
                </td>
                <td>
                  {getFormatedAreaSecondDigit(
                    buildings[0]?.after_construct_area,
                    isSquareMeter,
                  )}
                </td>
              </tr>
              <tr>
                <td>연면적</td>
                <td>
                  {getFormatedAreaSecondDigit(
                    buildings[0]?.before_total_floor_area,
                    isSquareMeter,
                  )}
                </td>
                <td>
                  <div>
                    {getFormatedAreaSecondDigit(
                      buildings[0]?.after_total_floor_area,
                      isSquareMeter,
                    )}
                  </div>
                  <Diff>
                    (&#43;
                    {getFormatedAreaSecondDigit(
                      buildings[0]?.after_total_floor_area -
                        buildings[0]?.before_total_floor_area,
                      isSquareMeter,
                    )}
                    )
                  </Diff>
                </td>
              </tr>
              <tr>
                <td>건폐율</td>
                <td>{buildings[0]?.before_coverage_ratio}%</td>
                <td>{buildings[0]?.after_coverage_ratio}%</td>
              </tr>
              <tr>
                <td>용적률</td>
                <td>{buildings[0]?.before_total_floor_ratio}%</td>
                <td>
                  <div>{buildings[0]?.after_total_floor_ratio}%</div>
                  <Diff>
                    (&#43;
                    {(
                      buildings[0]?.after_total_floor_ratio -
                      buildings[0]?.before_total_floor_ratio
                    ).toFixed(2)}
                    %)
                  </Diff>
                </td>
              </tr>
              <tr>
                <td>층수</td>
                <td>{buildings[0]?.before_floor_count}층</td>
                <td>{buildings[0]?.after_floor_count}층</td>
              </tr>
              <tr>
                <td>주차대수</td>
                <td>{buildings[0]?.before_parking_count}대</td>
                <td>
                  <div>{buildings[0]?.after_parking_count}대</div>
                  <Diff>
                    {buildings[0]?.after_parking_count -
                      buildings[0]?.before_parking_count !=
                      0 &&
                      `(${
                        buildings[0]?.after_parking_count -
                          buildings[0]?.before_parking_count >
                        0
                          ? `+${
                              buildings[0]?.after_parking_count -
                              buildings[0]?.before_parking_count
                            }`
                          : buildings[0]?.after_parking_count -
                            buildings[0]?.before_parking_count
                      }대)`}
                  </Diff>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Title>
            유사 건물을 분석한 결과
            <br />
            동반될 수 있는 대수선 공사에요
          </Title>
          <HelpIcon onClick={() => setIsSizeInfoHide(false)}>
            <i className="fas fa-info-circle"></i>
          </HelpIcon>
        </CardHeader>
        <CardBody>
          <Recommend>
            <RecommendContents>
              {Object.entries(refresh).map((refreshItem, i) => {
                return (
                  <Items isActive={refreshItem[1]} key={i}>
                    <div>
                      {refreshItem[1] ? (
                        <i className="fas fa-hammer"></i>
                      ) : (
                        <i className="fal fa-hammer"></i>
                      )}
                    </div>
                    <div>{getRepairKorean(refreshItem[0])}</div>
                  </Items>
                )
              })}
            </RecommendContents>
          </Recommend>
          <ScanText>
            * 건물의 상태와 내부 구조에 따라 대수선 항목은 변경될 수
            있어요. 더 정확한 진단을 원하시면 Matterport를 사용한 3D
            스캔을 신청해 보세요
          </ScanText>
          <Button onClick={onClickScan} expand>
            3D 스캔 신청하기
          </Button>
        </CardBody>
      </Card>
      <Modal
        title="예상 건축 규모"
        visible={!isSizeInfoHide}
        onOk={() => setIsSizeInfoHide(true)}
        onCancel={() => setIsSizeInfoHide(true)}
        okText="확인"
        cancelText="취소"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        <CustomUl>
          <li>대수선 종류 : 내력벽, 주계단, 보, 지붕틀, 기둥</li>
          <li>
            주차대수는 「주차장법 시행령」을 기반으로 하되, 일부 지역은
            지자체별 「부설주차장 설치 기준」을 참고하였습니다.
          </li>
          <li>
            허용 건축물 및 건축물별 최대 층수는 「국토의 계획 및 이용에
            관한 법률 시행령」을 참고하였습니다.
          </li>
          <li>
            이는 서비스 개발 시점 현행 법규를 기준으로 개발되었으며,
            실제 허가 가능한 건축의 형태로 보장하는 것은 아닙니다. 실제
            해당 지자체의 법규 해석 및 상황에 따라 건축 가능한 규모 및
            건축 가능 여부가 달라질 수 있습니다.
          </li>
          <li>
            참고용으로 제공되는 정보이므로 이를 이용함으로 발생하는 어떤
            손실에 대해서도 (주)주택은 책임이 없습니다.
          </li>
        </CustomUl>
      </Modal>
      <Modal
        title="용적률산정연면적이란?"
        visible={!isTotalFloorAreaHide}
        onOk={() => setIsTotalFloorAreaHide(true)}
        onCancel={() => setIsTotalFloorAreaHide(true)}
        okText="확인"
        cancelText="취소"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        <CustomUl>
          <li>
            모든 층의 바닥 넓이의 합에서 용적률 산정시 제외되는 면적을
            뺀 나머지 면적을 의미합니다.
          </li>
          <li>
            용적률 산정시 제외되는 면적에는 지하층, 주차장시설,
            주민공동시설 등이 있습니다.
          </li>
        </CustomUl>
      </Modal>
      <Modal
        title="실제공사면적이란?"
        visible={!isRealTotalFloorAreaHide}
        onOk={() => setIsRealTotalFloorAreaHide(true)}
        onCancel={() => setIsRealTotalFloorAreaHide(true)}
        okText="확인"
        cancelText="취소"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        <CustomUl>
          <li>
            서비스 면적 (발코니 등)을 포함한 모든 층의 면적의 합을
            말합니다.
          </li>
          <li>
            서비스 면적은 용적률 산정시 제외되므로 실제공사면적은 법적
            용적률 면적보다 더 넓게 나올 수 있습니다.
          </li>
        </CustomUl>
      </Modal>
    </>
  )
}

const Table = styled.table`
  width: 100%;
  font-size: 14px;

  thead > tr {
    color: ${theme.colors.gray[600]};

    td {
      font-weight: 500;

      &:nth-of-type(1) {
        width: 32%;
      }

      &:not(:nth-of-type(1)) {
        width: 35%;
        color: ${theme.colors.gray[600]};
        font-weight: 600;
      }
    }
  }

  tbody td {
    font-weight: 600;

    &:nth-of-type(1) {
      font-weight: 400;
      color: ${theme.colors.gray[600]};
    }
  }

  td {
    padding: 6px 4px;
  }

  tr {
    border-bottom: 1px solid ${theme.colors.gray[100]};
  }
`

const Diff = styled.span`
  font-size: 13px;
  color: ${theme.colors.red[400]};
`

const Recommend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
`

const RecommendTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 8px;
`

const RecommendText = styled.div`
  width: 90%;
  font-size: 18px;
  word-break: keep-all;
`

const RecommendContents = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  padding: 6px 0;
  border-top: 1px solid ${theme.colors.gray[100]};
  border-bottom: 1px solid ${theme.colors.gray[100]};
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  color: ${theme.colors.gray[600]};

  ${({ isActive }) =>
    isActive &&
    `
      color: ${theme.colors.primary[500]};
      font-weight: 600;
    `};
`

const ScanText = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  color: ${theme.colors.gray[600]};
  line-height: 150%;
`

export default SummaryNohuArea
