import theme, { media } from 'lib/styles/theme'
import { MainWrapper, MaxWidthWrapper } from '../style/NoteStyle'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'
import MobileChannelHeader from '../channel/MobileChannelHeader'
import useIsMobile from 'lib/hooks/useIsMobile'
import NotePaymentRule from './NotePaymentRule'
import BillingPage from './Billing'
import NotePaymentNotice from './NotePaymentNotice'
import NotePaymentPlanList from './NotePaymentPlanList'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import NotePaymentChangeContainer from 'containers/notev2/NotePaymentChangeContainer'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import NotFound from 'pages/NotFound'

/**
 * 요금제 변경 페이지
 * @returns
 */
const NotePaymentPlan = () => {
  const isMobile = useIsMobile()

  const { isEmp } = useIsAllInOne()

  if (isEmp) return <NotFound />
  return (
    <>
      {isMobile && <MobileChannelHeader channel={false} />}
      <StyledMainWrapper noside gray>
        <Scrollbars autoHide>
          <StyledMaxWidthWrapper>
            <Wrapper>
              <Title>매물노트 요금제</Title>
              <SubTitle>
                <div>
                  작은 사무소부터 큰 법인까지, 모두가 필요에 맞는
                  요금제를 선택할 수 있도록 다양한 요금제를 준비했어요.
                </div>
              </SubTitle>
              <NotePaymentChangeContainer>
                {({
                  loading,
                  billingResult,
                  setType,
                  setAmount,
                  setMonth,
                  setAllinoneMemberCount,
                  setIsOpenWarningModal,
                  setBillingResult,
                  paymentChangeModal,
                  setIsDirectPayment,
                }) => {
                  return (
                    <>
                      <NotePaymentPlanList
                        setType={setType}
                        setAmount={setAmount}
                        setMonth={setMonth}
                        setAllinoneMemberCount={setAllinoneMemberCount}
                        setIsOpenWarningModal={setIsOpenWarningModal}
                        setBillingResult={setBillingResult}
                        setIsDirectPayment={setIsDirectPayment}
                      />
                      {/* 로딩 */}
                      {loading && <FullScreenLoading />}
                      {/* 토스 결제 모달  - billingResult 값이 있으면 나옴(api/billing/request 의 결과)  */}
                      {billingResult && (
                        <BillingPage
                          customerKey={billingResult.customer_key}
                          setBillingResult={setBillingResult}
                        />
                      )}
                      {/* 요금제 변경 하시겠어요 ? 모달 */}
                      {paymentChangeModal}
                    </>
                  )
                }}
              </NotePaymentChangeContainer>
              <NotePaymentNotice />
              <NotePaymentRule />
            </Wrapper>
          </StyledMaxWidthWrapper>
        </Scrollbars>
      </StyledMainWrapper>
    </>
  )
}

const StyledMainWrapper = styled(MainWrapper)`
  ${media.mediumM`
    left: 0;
    width: 100%;
  `}
`

const Wrapper = styled.div`
  width: 100%;
  background-color: ${theme.colors.base.white};
  padding: 0 40px;
  padding-bottom: 40px;

  ${media.mediumM`
    padding: 0 24px;
  `}

  ${media.mediumS`
    background-color: ${theme.colors.blue[50]};
    padding: 30px 20px;
  `}
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  margin: 0 auto;
  height: 100%;
  background-color: ${theme.colors.base.white};
  ${media.smallPc`
    width: 100%;
    max-width: 100%;
  `}

  ${media.mediumS`
    padding: 0;
  `}
`

const Title = styled.h1`
  font-size: 42px;
  font-weight: 700;
  line-height: 180%;
  margin-bottom: 4px;
  text-align: center;

  ${media.mediumM`
    font-size: 34px;
  `}

  ${media.mediumS`
    font-size: 28px;
  `}
`

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 80px;
  color: ${theme.colors.gray[600]};
  font-size: 28px;
  font-weight: 500;
  line-height: 180%;
  text-align: center;

  div {
    width: 90%;
    max-width: 700px;
    word-break: keep-all;
  }

  ${media.mediumM`
    font-size: 22px;
    margin-bottom: 60px;
  `}

  ${media.mediumS`
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 40px;
  `}
`

export default NotePaymentPlan
