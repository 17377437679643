import { useInput } from 'lib/hooks/useInput'
import { useDispatch } from 'react-redux'
import {
  initPropertyParams,
  setNoteListInfo,
  setNoteListSearchParams,
} from 'modules/notev2'
import { forwardRef, useEffect, useState } from 'react'
import { getListings } from 'lib/api/notev2/listApi'
import useAxios from 'lib/hooks/useAxios'
import { useSelector } from 'react-redux'
import { getMaxRowHeight } from 'lib/utils/notev2/gridData'
import moment from 'moment'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min'

const NoteListContainer = (
  { children, searchAndSetPropertyFilters },
  ref,
) => {
  const [search, bindSearch, resetSearch, setSearch] = useInput(q || '')
  const dispatch = useDispatch()
  const [onRequest, , data] = useAxios(getListings)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [count, setCount] = useState(null)
  const {
    property_types: searchParamPropertyTypes,
    sort,
    q,
  } = useSelector((state) => state.notev2.noteListSearchParams)
  const [gridRowData, setGridRowData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()
  const match = useRouteMatch('/note/v2/notes/:channelSrl/:addressId?')

  useEffect(() => {
    localStorage.removeItem('currClickNoteRow')

    // 언마운트 필터 초기화
    return () => {
      dispatch(
        setNoteListSearchParams({
          property_types: initPropertyParams(),
          q: '',
        }),
      )
    }
  }, [])

  useEffect(() => {
    if (!channelInfo || !channelInfo.channel_srl) return
    resetSearch()

    const adderss_id = match?.params?.addressId
    const isExact = match?.isExact
    const isFromSummary = adderss_id && isExact

    searchAndSetPropertyFilters()
    onRequest({
      channel_srl: channelInfo.channel_srl,
      filters: searchParamPropertyTypes,
      q: isFromSummary ? '' : q,
      sort_index: sort.index,
      sort_type: sort.type,
      address_id: isFromSummary ? adderss_id : null,
    })
  }, [channelInfo])

  useUpdateEffect(() => {
    if (!channelInfo || !channelInfo.channel_srl) return
    setIsLoading(true)

    onRequest({
      channel_srl: channelInfo.channel_srl,
      filters: searchParamPropertyTypes,
      q: q,
      sort_index: sort.index,
      sort_type: sort.type,
    })
  }, [sort])

  useEffect(() => {
    setSearch(q)
  }, [q])

  useEffect(() => {
    if (!data || !data.result) {
      if (data && !data.result) {
        setGridRowData([])
      }
      setIsLoading(false)
      return
    }

    const adderssId = match?.params?.addressId
    const isExact = match?.isExact
    const isFromSummary = adderssId && isExact

    if (isFromSummary && match?.params?.addressId) {
      history.replace(`/note/v2/notes/${channelInfo.channel_srl}`)
    }

    const calcResult = data.result.map((items, i) => {
      //평 제곱미터 토글용 데이터

      const addressConcatColumn = {
        addr: items.invalid_addr_name
          ? `${items.invalid_addr_name}`
          : `${items.display_address}`,
      }

      //그리드 row 식별 컬럼
      const rowKeyColumn = {
        row_key: 'note' + items.note_srl + moment().seconds() + i,
      }

      //그리드 api가 state update해도 반응이 없는 버그가 있어서 그냥 rowdata에 넣고 꺼내쓰기
      const customStateColumn = {
        state: {
          isContractTypeShow: false, //구분 컬럼에 키 반응이 일어났는가 -> 키 누르면 구분, 가격 컬럼이 나타나도록함
          isEditingPropertyType: false,
          isEditingDirection: false,
        },
      }

      //매매 전세 월세 개수 또는 연락처 개수중 max 값에 따라 row height 지정
      const rowHeightColumn = {
        rowHeight: getMaxRowHeight({
          contract_type: items.contract_type,
          contacts: items.contacts,
        }),
      }

      //연락처는 JSON.stringify 형태로 저장되어있음
      const contactColumn = {
        contact_srls: JSON.stringify(items.contacts),
      }

      return {
        ...items,
        ...rowKeyColumn,
        ...addressConcatColumn,
        //    ...formattedAreaColumns,
        ...customStateColumn,
        ...rowHeightColumn,
        ...contactColumn,
      }
    })

    //맨 위 신규 매물 주소 입력 row 추가
    const newItem = {
      row_key: 'addNewLine',
      note_srl: '신규',
      state: {
        isContractTypeShow: false,
        isEditingPropertyType: false,
        isEditingDirection: false,
      },
    }

    const rowData = [...[newItem], ...calcResult]

    //row data
    setGridRowData(rowData)

    //개수
    setCount(data.notes_cnt)
    if (data.notes_cnt == 0) {
      dispatch(
        setNoteListInfo({
          count: 0,
        }),
      )
    }

    setIsLoading(false)

    //그리드 스크롤 맨 위로
    if (ref && ref?.current) {
      if (gridRowData.length == 0) return
      ref.current.api.ensureIndexVisible(0, 'top')
    }
  }, [data])

  useEffect(() => {
    if (!data) return
    dispatch(
      setNoteListInfo({
        count: count || 0,
      }),
    )
  }, [data, count])

  /**
   * 검색(현재 state 상태로)
   */
  const searchFn = () => {
    onRequest({
      channel_srl: channelInfo?.channel_srl,
      filters: searchParamPropertyTypes,
      q: q,
      sort_index: sort.index,
      sort_type: sort.type,
    })
  }

  /**
   * 검색(파라미터로 검색)
   */
  const searchFnWithParam = ({ propertyFilter, q }) => {
    onRequest({
      channel_srl: channelInfo?.channel_srl,
      filters: propertyFilter || searchParamPropertyTypes,
      q: q,
      sort_index: sort.index,
      sort_type: sort.type,
    })
  }

  /**
   * input 검색 함수
   * @param {*} e
   */
  const onSearch = (e) => {
    if (e.keyCode == '13') {
      dispatch(
        setNoteListSearchParams({
          q: search,
        }),
      )
      searchFnWithParam({ propertyFilter: null, q: search })
    }
  }

  const clearSearch = () => {
    if (!search) return
    resetSearch()
    dispatch(
      setNoteListSearchParams({
        q: '',
      }),
    )
    searchFnWithParam({ q: '' })
  }

  return children({
    search,
    bindSearch,
    onSearch,
    searchFn,
    searchFnWithParam,
    resetSearch,
    gridRowData,
    isLoading,
    clearSearch,
  })
}

export default forwardRef(NoteListContainer)
