import styled from 'styled-components'
import theme from 'lib/styles/theme'

import { useDispatch } from 'react-redux'
import { setNoteSearchQuery } from 'modules/note'
import { useState } from 'react'

const NoteListSearch = ({ query, onSearch, onClear }) => {
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      dispatch(setNoteSearchQuery(e.target.value))
      onSearch(e.target.value)
    }
  }

  const handleClear = () => {
    dispatch(setNoteSearchQuery(''))
    setSearchValue('')
    //onClear()
  }

  return (
    <SearchWrap>
      <SearchIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </SearchIcon>

      <SearchInput
        placeholder="매물 검색"
        value={searchValue}
        onChange={(e) => handleChange(e)}
        onKeyPress={handleKeyPress}
      />

      {query && (
        <SearchRemove onClick={handleClear}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 18 18"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </SearchRemove>
      )}
    </SearchWrap>
  )
}

const SearchWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 0.875rem;
  border: 1px solid ${theme.colors.gray[100]};
  background-color: ${theme.colors.base.white};
  border-radius: ${theme.borderRadius[2]};

  &:focus-within {
    border-color: ${theme.colors.gray[500]};
  }
`

const SearchIcon = styled.div`
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  & > svg {
    width: 18px;
    height: 18px;
  }
`

const SearchInput = styled.input`
  flex: 1;
  width: 100%;
  height: 40px;
  padding-left: 10px;

  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;

  appearance: none;
  outline: none;
  border: none;
  font-size: 14px;

  &::placeholder {
    color: ${theme.colors.gray[800]};
  }
`

const SearchRemove = styled.button`
  display: flex;
  padding: 0;
  margin-right: 0.5rem;

  color: ${theme.colors.gray[900]};
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;

  & > svg {
    width: 1rem;
    height: 1rem;
  }
`
export default NoteListSearch
