import { useSelector } from 'react-redux'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import NoteDropSelectorItem from './NoteDropSelectorItem'
import useRoveFocus from './useRoveFocusPropertySelector'

const NotePropertySelector = ({
  visible,
  setIsVisible,
  value,
  onChange,
  onClose,
  closeCallbackFn,
  mobileFullWidth,
}) => {
  const [data, setData] = useState([])
  const options = useSelector((state) => state.notev2.noteOptions)
  const [focus, setCurrentFocus] = useRoveFocus(20)

  const ref = useRef(null)

  useEffect(() => {
    if (!options || Object.keys(options).length == 0) return

    setData(options.note_types)
  }, [options])

  useOnClickOutside(ref, () => {
    setIsVisible(false)
  })

  const onClickItem = useCallback((name) => {
    onChange(name)
    setIsVisible(false)
    closeCallbackFn && closeCallbackFn()
  }, [])

  return (
    <Wrapper
      ref={ref}
      visible={visible}
      mobilefullWidth={mobileFullWidth ? 'Y' : 'N'}
    >
      <List>
        {data.map((name, i) => {
          return (
            <NoteDropSelectorItem
              focus={focus === i}
              key={i}
              active={name == value}
              setCurrentFocus={setCurrentFocus}
              onClick={onClickItem}
              name={name}
              value={value}
              i={i}
              onClose={onClose}
            />
          )
        })}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  background-color: ${theme.colors.base.white};
  display: none;
  width: 400px;
  z-index: 1;
  border-radius: 10px;
  padding: 12px;
  box-shadow: ${theme.shadow['card-2']};
  ${({ visible }) => visible && `display: block;`}

  ${media.mediumS`
    height: 250px;
    overflow: scroll;
    width: 230px;
    ${({ mobilefullWidth }) => mobilefullWidth == 'Y' && `width: 100%;`}
  `}
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

export default React.memo(NotePropertySelector)
