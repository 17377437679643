import theme from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const PagingWithNumber = ({
  currentPage,
  setCurrentPage,
  data,
  perPage,
  totalCount,
}) => {
  const [pagesCountArr, setPagesCountArr] = useState([])
  const [currentGroup, setCurrentGroup] = useState([])

  useEffect(() => {
    const pagesCount = totalCount
      ? Array.from(Array(Math.ceil(totalCount / 4)).keys()).slice(1)
      : Array.from(
          Array(Math.ceil(data.length / perPage)).keys(),
          (x) => x + 1,
        )

    setPagesCountArr(pagesCount)
  }, [data, currentPage])

  useEffect(() => {
    if (pagesCountArr.length < 1) return

    let pageArr = []

    for (let i = 0; i < pagesCountArr.length; i += 5) {
      pageArr.push(pagesCountArr.slice(i, i + 5))
    }

    setCurrentGroup(pageArr[Math.floor((currentPage - 1) / 5)])
  }, [pagesCountArr])

  return (
    <PagingUl>
      <PagingLi
        arrow
        onClick={() => {
          setCurrentPage(1)
        }}
        active={false}
        disabled={currentPage === 1}
      >
        <i className="far fa-angle-double-left"></i>
      </PagingLi>
      <PagingLi
        arrow
        onClick={() => {
          setCurrentPage(currentPage - 1 < 1 ? 1 : currentPage - 1)
        }}
        active={false}
        disabled={currentPage === 1}
      >
        <i className="far fa-angle-left"></i>
      </PagingLi>
      <NumberWrapper>
        {currentGroup.map((pageNumber, index) => {
          return (
            <PagingLi
              onClick={() => {
                setCurrentPage(pageNumber)
              }}
              key={index}
              active={pageNumber === currentPage}
            >
              <span>{pageNumber}</span>
            </PagingLi>
          )
        })}
      </NumberWrapper>
      <PagingLi
        arrow
        onClick={() => {
          setCurrentPage(
            currentPage >= pagesCountArr.length
              ? pagesCountArr.length
              : currentPage + 1,
          )
        }}
        active={false}
        disabled={currentPage === pagesCountArr.length}
      >
        <i className="far fa-angle-right"></i>
      </PagingLi>
      <PagingLi
        arrow
        onClick={() => {
          setCurrentPage(pagesCountArr.length)
        }}
        active={false}
        disabled={currentPage === pagesCountArr.length}
      >
        <i className="far fa-angle-double-right"></i>
      </PagingLi>
    </PagingUl>
  )
}

const NumberWrapper = styled.ul`
  display: flex;
  justify-content: space-evenly;
  width: calc(${theme.base.container}px - 140px - 2rem);
`

const PagingUl = styled.ul`
  display: flex;
  justify-content: center;
`

const PagingLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 35px;
  cursor: pointer;
  border: none;
  font-size: 0.875rem;

  ${({ active }) =>
    active &&
    `color: ${theme.colors.primary[500]}; 
      font-weight: 500
  `}

  ${({ arrow, disabled }) =>
    arrow &&
    `
      background: ${theme.colors.gray[50]};
      border-radius: 100%;
      width: 35px;
      color: ${
        disabled ? theme.colors.gray[300] : theme.colors.gray[700]
      };
  `}
`

export default PagingWithNumber
