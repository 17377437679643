import React, { useCallback } from 'react'
import styled from 'styled-components'
import { SheetButton } from 'components/jmapnotev2/style/NoteStyle'
import { useHistory } from 'react-router-dom'
import { getDocsWritePath } from 'lib/utils/notev2/common'
import { message } from 'antd'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import useAdConvert from 'lib/hooks/notev2/useAdConvert'

const CustomAdCell = ({ data, gridfontsize }) => {
  const history = useHistory()

  const {
    onClickAdLink,
    onClickGoAdList,
    donghoRequiredModal,
    hoRequiredModal,
    addressRequiredModal,
  } = useAdConvert()

  if (!data.address_srl) return <>&ndash;</>

  return (
    <Wrapper tabIndex={0}>
      {data?.ad_listings?.length > 0 ? (
        <SheetButton
          tabIndex={-1}
          disabled={data.status == 2}
          onClick={() =>
            onClickGoAdList({
              address_doro: data.address_doro,
              address_jibun: data.address_jibun,
            })
          }
          gridfontsize={gridfontsize}
        >
          광고이력
        </SheetButton>
      ) : (
        <SheetButton
          tabIndex={-1}
          disabled={data.status == 2}
          onClick={() =>
            onClickAdLink({
              address_srl: data.address_srl,
              property_type: data.property_type,
              channel_srl: data.channel_srl,
              note_srl: data.note_srl,
              ho: data.ho,
            })
          }
          gridfontsize={gridfontsize}
        >
          광고등록
        </SheetButton>
      )}
      {donghoRequiredModal} {hoRequiredModal} {addressRequiredModal}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &:focus-visible {
    outline: 1px solid ${theme.colors.blue[500]};
  }

  &:focus {
    border: 1px solid ${theme.colors.blue[500]};
  }
`

const Date = styled.div`
  color: ${theme.colors.blue[400]};
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`

export default React.memo(CustomAdCell)
