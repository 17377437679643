import { setOpenFilterType } from 'modules/map'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import MapLayerSelectContainer from 'containers/map/MapLayerSelectContainer'
import { LayerGroupItem } from './MapControlStyle'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { useContext, useMemo, useState } from 'react'
import MapContext from 'contexts/map'
import { ReactComponent as Icon } from 'assets/icon/map-control/layer.svg'
import { ReactComponent as IconActive } from 'assets/icon/map-control/layer-active.svg'

const MapLayer = () => {
  const dispatch = useDispatch()
  const openFilterType = useSelector(
    (state) => state.map.openFilterType,
  )
  const {
    state: { layerState },
  } = useContext(MapContext)

  const [isActive, setIsActive] = useState(false)
  useUpdateEffect(() => {
    const {
      priceActive,
      dongBoundary,
      housingSite,
      emptylandActive,
      mountainActive,
      school,
    } = layerState

    const activeLength = [
      priceActive,
      dongBoundary,
      housingSite,
      emptylandActive,
      mountainActive,
      school,
    ].filter((status) => status)

    setIsActive(activeLength.length > 0)
  }, [layerState])

  const isButtonActive = useMemo(
    () => openFilterType == 'layer',
    [openFilterType],
  )

  return (
    <>
      <LayerGroupItem
        noborder
        dotactive={isActive}
        active={isButtonActive}
        onClick={() =>
          dispatch(setOpenFilterType(isButtonActive ? null : 'layer'))
        }
      >
        {isButtonActive ? <IconActive /> : <Icon />}
        <span>레이어</span>
      </LayerGroupItem>

      {/* 누르면 나오는 레이어 선택 리스트 */}
      <MapLayerSelectContainer />
    </>
  )
}

export default MapLayer
