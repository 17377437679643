import styled from 'styled-components'
import { MaxWidthWrapper } from 'components/jmapnotev2/style/NoteStyle'
import theme, { media } from 'lib/styles/theme'
import Scrollbars from 'react-custom-scrollbars-2'
import MobileChannelHeader from '../channel/MobileChannelHeader'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteMainWrapper from '../style/NoteMainWrapper'
import NoteAccountForm from './NoteAccountForm'
import NoteAccountInviteContainer from 'containers/notev2/NoteAccountInviteContainer'
import NoteInviteReward from '../NoteInviteReward/NoteInviteReward'
import { useEffect } from 'react'
import useUserLoader from 'containers/base/hooks/useUserLoader'

/**
 * 계정관리
 * @returns
 */
const NoteAccount = () => {
  const isMobile = useIsMobile()

  const { onRequest } = useUserLoader(false)

  useEffect(() => {
    onRequest()
  }, [])

  return (
    <>
      {isMobile && <MobileChannelHeader channel={false} />}
      <NoteMainWrapper singleM single gray>
        <Scrollbars
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />
          )}
        >
          <StyledMaxWidthWrapper>
            <Title>계정관리</Title>
            <Wrapper>
              <Division>
                <NoteAccountForm />
              </Division>
              <Division right>
                <NoteAccountInviteContainer>
                  {({
                    isChargedMember,
                    isPlanLoading,
                    referCode,
                    invitedList,
                    rewardedCount,
                    expectedRewardedCount,
                    loadingRefer,
                  }) => {
                    return (
                      <>
                        {isPlanLoading && isChargedMember && (
                          <NoteInviteRewardWrapper>
                            <NoteInviteReward
                              isLoading={loadingRefer}
                              referCode={referCode}
                              invitedList={invitedList}
                              rewardedCount={rewardedCount}
                              expectedRewardedCount={
                                expectedRewardedCount
                              }
                            />
                          </NoteInviteRewardWrapper>
                        )}
                      </>
                    )
                  }}
                </NoteAccountInviteContainer>
              </Division>
            </Wrapper>
          </StyledMaxWidthWrapper>
        </Scrollbars>
      </NoteMainWrapper>
    </>
  )
}
const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  margin: 0 auto;
  height: 100%;
  background-color: ${theme.colors.base.white};
  ${media.smallPc`
    width: 100%;
  `}

  ${media.mediumS`
    padding: 30px 0;
  `}
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  padding-left: 40px;
  margin-bottom: 36px;

  ${media.mediumS`
    padding-left: 20px;
    margin-bottom: 20px;
  `}
`

export const AccountLabel = styled.label`
  display: flex;
  align-items: center;
  min-width: 160px;
  font-size: 14px;
  font-weight: 500;

  ${({ required }) =>
    required == 'Y' &&
    `
    &::after {
      content: "*";
      margin-left: 4px;
      color: ${theme.colors.tint.danger};
    }
  `}
`

export const AccountItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ${({ flexstart }) =>
    flexstart &&
    `
    align-items: flex-start;`}

  ${media.mediumS`
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  `}
`

export const AccountInput = styled.input`
  min-width: 253px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid ${theme.colors.gray[200]};
  outline: none;
  background-color: ${theme.colors.base.white};
  padding: 0 8px;
  font-size: 14px;

  ${({ expand }) => expand && `width: 100%;`};
  ${({ small }) => small == 'Y' && `min-width: unset;`};

  ${({ tel }) =>
    tel == 'Y' &&
    `
        flex: 1;
    `};
  &:read-only {
    background-color: ${theme.colors.gray[100]};
  }

  ${media.mediumS`
     min-width: unset;
     max-width: 100%;
   `}
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${theme.colors.base.white};
  padding: 0 40px;

  ${media.mediumM`
    flex-direction: column;
  `}

  ${media.mediumS`
    padding: 0 20px calc(${theme.base.bottomMenuHeight}px + 30px) 20px;
  `}
`

const Division = styled.div`
  width: 50%;

  ${({ right }) =>
    right &&
    `
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 45%;
  `}

  ${media.mediumM`
    width: 100%;
  `}

  
  ${media.mediumS`
    width: 100%;

    ${({ right }) =>
      right
        ? `
      justify-content: flex-start;
    `
        : `
      padding-top: 20px;
    `}
  `}
`

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > button {
    margin-top: 12px;
  }
`
const InputWrap = styled.div`
  display: flex;
  gap: 5px;
  max-width: 260px;

  & input {
    width: 82px !important;
  }
`

const SaveButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 24px;
`

const NoteInviteRewardWrapper = styled.div`
  width: 300px;
  padding: 40px 16px;
  background-color: #3a3a3a;
  border-radius: 12px;

  @media (max-width: 960px) {
    margin-top: 40px;
  }
`

export default NoteAccount
