import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { FormProvider, useForm } from 'react-hook-form'
import { getNote } from 'lib/api/note/noteApi'
import NoteDocsLayerNoteDetailInfo from './NoteDocsLayerNoteDetailInfo'

const NoteDocsLayerNoteDetail = () => {
  const methods = useForm()

  const [onRequestNote, , dataNote] = useAxios(getNote)

  const match = useRouteMatch(
    '/note/v2/docs/:channelSrl/:noteSrl/edit/:contractSrl?',
  )

  useEffect(() => {
    onRequestNote({
      channelSrl: match.params.channelSrl,
      noteSrl: match.params.noteSrl,
    })
  }, [])

  useEffect(() => {
    if (!dataNote) return
    methods.reset(
      Object.assign(
        {},
        dataNote.result?.extra_vars || {},
        dataNote.result,
      ),
    )
  }, [dataNote])

  return (
    <>
      <FormProvider {...methods}>
        <NoteDocsLayerNoteDetailInfo />
      </FormProvider>
    </>
  )
}

export default NoteDocsLayerNoteDetail
