export const tab = {
  1: '평형정보',
  2: '공시가격',
  3: '점유형태',
  4: '계약만기',
}

export const blockColors = {
  1: [
    '#E9F1F4',
    '#D9EBF0',
    '#C6E4ED',
    '#B6DFE9',
    '#A6D9E6',
    '#BDE3DA',
    '#AFDED3',
    '#DDDEF4',
    '#CCCEF2',
    '#BDC1F1',
  ],
  2: [
    '#F4F9FF',
    '#EAF4FF',
    '#DCECFF',
    '#CEE5FF',
    '#C0DDFF',
    '#A5CEFF',
    '#89BFFF',
    '#74B4FF',
    '#B6BDFF',
    '#A4ACFC',
    '#929CF9',
    '#DE9CFD',
  ],
  3: {
    월세: '#FFB6A6',
    월세추정: '#FBEDE7',
    전세: '#7BC983',
    전세추정: '#7bc98359',
    자가추정: '#DCE8FF',
    알수없음: '#EFF2F6',
  },
  4: ['#955FFF', '#AD84FD', '#BDA2FF', '#CFBBFF', '#E5DCFF', '#EFF2F6'],
}

export const purposeColors = {
  오피스텔: '#7C42F8',
  사무실: '#E84CB9',
  상가: '#E84CB9',
  기타: '#87909E',
  숙박: '#1850DE',
}

export const exclusiveLevel = {
  1: [0, 45],
  2: [45, 60],
  3: [60, 75],
  4: [75, 85],
  5: [85, 132],
  6: [132],
}

export const exclusiveColorLevel = {
  1: '#FCE362',
  2: '#6DC686',
  3: '#76CBC6',
  4: '#72A5F0',
  5: '#F08282',
  6: '#907FF9',
}

export const coloringPurposes = [
  '아파트',
  '다세대주택',
  '연립주택',
  '오피스텔',
]
