import { useState, useEffect } from 'react'
import useAxios from '../useAxios'
import { modifyNote, modifyNoteAll } from 'lib/api/note/noteApi'
import { useSelector } from 'react-redux'

/**
 * 시트 모드 3뎁스 매물 수정시만 사용
 *
 */
function useModifyNoteDetailForm() {
  const [onRequest, , data] = useAxios(modifyNote, {
    resetError: true,
  })
  const [onRequestAll, , dataAll] = useAxios(modifyNoteAll, {
    resetError: true,
  })

  const [isError, setIsError] = useState(false)

  const channelInfo = useSelector((state) => state.channel.channelInfo)

  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.error('modify note 에러')
      setIsError(true)
      return
    }
  }, [data])

  const onModify = ({ data, updateData }) => {
    //노트 저장 api
    if (channelInfo?.member_type == 'readonly') return //채널 권한이 읽기 전용이면 return

    onRequest({
      channelSrl: data.channel_srl,
      addressSrl: data.address_srl,
      addressId: data.address_id,
      noteSrl: data.note_srl,
      dong: data?.dong ? data.dong : '',
      ho: data?.ho ? data.ho : '',
      lastUpdate: data?.last_update,
      form: updateData,
    })
  }

  const onModifyAll = ({ data, updateData }) => {
    //노트 저장 api
    if (channelInfo?.member_type == 'readonly') return //채널 권한이 읽기 전용이면 return

    onRequestAll({
      channelSrl: data.channel_srl,
      addressSrl: data.address_srl,
      addressId: data.address_id,
      noteSrl: data.note_srl,
      dong: data?.dong ? data.dong : '',
      ho: data?.ho ? data.ho : '',
      lastUpdate: data?.last_update,
      form: updateData,
    })
  }

  return [onModify, isError, onModifyAll]
}

export default useModifyNoteDetailForm
