import { getAdFilters } from 'lib/api/notev2/adList'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as AdIng } from 'assets/icon/note/ad/ad-ing.svg'
import { ReactComponent as ApprovalDelegationColor } from 'assets/icon/note/ad/approval-delegation-color.svg'
import { ReactComponent as Fail } from 'assets/icon/note/ad/fail.svg'
import { ReactComponent as FailCancel } from 'assets/icon/note/ad/fail_cancel.svg'
import { ReactComponent as Report } from 'assets/icon/note/ad/report.svg'
import { ReactComponent as WillEnd } from 'assets/icon/note/ad/will-end.svg'
import { ReactComponent as Same } from 'assets/icon/note/ad/same.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 매물노트 대시보드
 * 광고관리 상태판
 */
const NoteDashboardAdStatusContainer = ({ children }) => {
  const history = useHistory()
  const [status, setStatus] = useState(null)
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [onRequestStatus, , dataStatus, errorStatus] =
    useAxios(getAdFilters)
  const showStatus = [
    '승인대기',
    '광고중',
    '종료예정',
    '검증취소',
    '동일주소매물',
    '검증1차실패',
    '검증최종실패',
    '신고매물',
  ]

  const icons = {
    승인대기: <ApprovalDelegationColor width={22} height={22} />,
    광고중: <AdIng />,
    종료예정: <WillEnd />,
    검증취소: <FailCancel />,
    동일주소매물: <Same />,
    검증1차실패: <Fail />,
    검증최종실패: <Fail />,
    신고매물: <Report />,
  }

  const colors = {
    승인대기: 'blue',
    광고중: 'primary',
    종료예정: 'gray',
    검증취소: 'gray',
    동일주소매물: 'blue',
    검증1차실패: 'red',
    검증최종실패: 'red',
    신고매물: 'red',
  }

  useEffect(() => {
    onRequestStatus({
      is_dashboard: 'Y',
      plan_srl: planInfo.allinone_plan_srl,
    })
  }, [])

  useEffect(() => {
    if (!dataStatus) return
    if (!dataStatus?.result) {
      setStatus(null)
      return
    }

    // const arr = [
    //   dataStatus.result['동일주소매물'] > 0 ? '동일주소매물' : null,
    //   dataStatus.result['신고매물'] > 0 ? '신고매물' : null,
    // ]
    //setNotiArr(arr.filter((item) => item))

    setStatus(dataStatus?.result || null)
  }, [dataStatus])

  const onClickStatus = (val) => {
    history.push({
      pathname: '/note/v2/ad',
      state: {
        activeStatus: val,
      },
    })
  }

  const onClickAdPage = () => {
    history.push({
      pathname: '/note/v2/ad',
    })
  }

  return children({
    showStatus,
    status,
    icons,
    colors,
    onClickStatus,
    onClickAdPage,
  })
}

export default NoteDashboardAdStatusContainer
