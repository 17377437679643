import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 복층 여부
 */
const NoteAdColumnFloorMultiple = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const duplex = watch(`article.duplex`) || ''

  return (
    <ColumnSet half>
      <ColumnTitle>복층 여부</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          fit
          label="예"
          name="floor_duplex"
          value={true}
          checked={duplex && JSON.parse(duplex) === true}
          {...register('article.duplex')}
          onChange={(e) => {
            onUpdate('article.duplex', JSON.parse(e.target.value))
          }}
        />
        <RadioButton
          fit
          label="아니오"
          name="floor_duplex"
          value={false}
          {...register('article.duplex')}
          checked={
            !duplex ||
            JSON.parse(duplex) === false ||
            (typeof duplex == 'boolean' && duplex === false)
          }
          onChange={(e) => {
            onUpdate('article.duplex', JSON.parse(e.target.value))
          }}
        />
        <ValidateWarn>{errors?.article?.duplex?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFloorMultiple
