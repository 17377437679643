import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { NoteProvider } from 'contexts/noteContext'

import NoteChannelPage from 'pages/jmapnote/NoteChannelPage'
import NoteContainer from 'containers/note/NoteContainer'
import NoteBottomMenu from 'components/jmapnote/noteBase/NoteBottomMenu'
import SEOHead from 'components/common/SEOHead'
import { useDispatch } from 'react-redux'
import { setOpenFilterType } from 'modules/map'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteSideMenu from 'components/base/SideMenu/SideMenu'

const NotePage = ({ history }) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile) {
      dispatch(setOpenFilterType(null))
    }
  }, [isMobile])

  return (
    <>
      <SEOHead />
      <NoteContainer history={history}>
        {({ popup }) => {
          return (
            <>
              {/* <NoteSideMenu /> */}
              <NoteProvider>
                <Switch>
                  <Route
                    path="/note/:channelSrl/:folderSrl"
                    component={NoteChannelPage}
                  />
                </Switch>
              </NoteProvider>
              <NoteBottomMenu />
              {popup}
            </>
          )
        }}
      </NoteContainer>
    </>
  )
}

export default React.memo(NotePage)
