import { useContext, useEffect } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setIsCallSizeReview } from 'modules/summary'
import { setIsCombination } from 'modules/summary'
import BaseContext from 'contexts/baseContext'
import MapContext from 'contexts/map'
import {
  funcCookieClickPosition,
  getAddressName,
  isMarkerInsidePolygon,
} from 'lib/utils'
import { getAddress } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import { notification } from 'antd'

const MapClickContainer = ({ setCookie }) => {
  const { kakao } = window
  const { state, actions } = useContext(MapContext)
  const { map } = state
  const dispatch = useDispatch()
  const [onRequest, , data, , ,] = useAxios(getAddress)

  const mapClickDisabled = useSelector(
    (state) => state.map.mapClickDisabled,
  )

  const polygonPathArrWcong = useSelector(
    (state) => state.housingComplex.polygonPathArrWcong,
  )

  const {
    actions: { setIsFold },
  } = useContext(BaseContext)

  const history = useHistory()

  const openNotification = (message) => {
    notification['info']({
      message: '알림',
      description: message,
    })
  }

  const onClickMap = (mouseEvent) => {
    if (mapClickDisabled) return
    if (kakao?.maps?.event?.isClickDisabled()) return

    const latlng = mouseEvent.latLng

    if (polygonPathArrWcong) {
      if (isClickPositionInsidePolygon(latlng)) return
    }

    onRequest(
      {
        latitude: latlng.getLat(),
        longitude: latlng.getLng(),
      },
      null,
      false,
    )
  }

  /**
   * @param latlng 위,경도 카카오맵 객체
   * 클릭한 포지션이 주택단지 폴리곤에 있는지 확인
   */
  const isClickPositionInsidePolygon = (latlng) => {
    const clickCoords = latlng.toCoords()

    const clickPositionInPolygon = polygonPathArrWcong.filter(
      (polygonPath) => {
        return isMarkerInsidePolygon(
          [clickCoords.getX(), clickCoords.getY()],
          polygonPath,
        )
      },
    )

    return clickPositionInPolygon.length > 0
  }

  useEffect(() => {
    if (!data) return

    const result = data.result

    if (result && Object.keys(result).length > 0) {
      // if (result.land_type) {
      //   if (
      //     result.land_type === '하천' ||
      //     result.land_type === '도로'
      //   ) {
      //     openNotification('도로와 하천은 선택할 수 없습니다.')
      //     return
      //   }
      // }

      const address = getAddressName(result)

      funcCookieClickPosition(
        setCookie,
        result,
        address,
        state.viewState.level,
      )
      //setAddressPolygon(data, kakao, state, actions, setCookie)

      // Summary 패널 활성화
      setIsFold(false)

      // console.log(result);
      /**
       * @WARN: matchPath를 사용할 때는 함수 안에서 선언하세요.
       * matchPath를 이용해 취득한 데이터는 useHistory와 다르게
       * 바인딩된 state가 아닙니다.
       */
      const match = matchPath(history.location.pathname, {
        path: '/:id/:external',
        exact: true,
      })

      //주택단지 id format
      history.push({
        pathname: `/${result.id}/${
          match?.params?.external ? match.params.external : ''
        }${match?.params?.filterId ? `/${match.params.filterId}` : ''}`,
        state: {
          address_data: data,
        },
      })

      // 중점이동
      // map.panTo(
      //   new kakao.maps.LatLng(
      //     result.centerpoint.coordinates[0],
      //     result.centerpoint.coordinates[1],
      //   ),
      // )
    } else if (!result || Object.keys(result)?.length === 0) {
      openNotification('검색 결과가 없습니다.')
    } else if (result.data?.error === -2) {
      console.error(result.data.message)
      openNotification(
        '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요.',
      )
    }

    //수익성 분석 api call 활성화
    dispatch(setIsCallSizeReview(true))

    //수익성 분석 합필 토글 해제
    dispatch(setIsCombination(false))
    actions.setCombinationPolygons([])
    actions.setCombinationStandardPolygons([])
  }, [data])

  useEffect(() => {
    /**
     * @METHOD: 지도 클릭 이벤트
     */
    kakao.maps.event.addListener(map, 'click', onClickMap)

    return () => {
      kakao.maps.event.removeListener(map, 'click', onClickMap)
    }
  }, [mapClickDisabled, polygonPathArrWcong])

  return null
}

export default MapClickContainer
