import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  selectedName: '',
  polygonPathArrWcong: [],
  centerpoint: null,
  roadView: {
    isVisible: false,
    isSupport: true,
  },
}

export const housingComplexSlice = createSlice({
  name: 'housingComplex',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setSelectedName(state, action) {
      state.selectedName = action.payload
    },
    setpolygonPathArrWcong(state, action) {
      const dupobj = current(state.polygonPathArrWcong).filter(
        (ori_arr) =>
          JSON.stringify(ori_arr) === JSON.stringify(action.payload),
      )

      if (
        JSON.stringify(action.payload) != JSON.stringify(dupobj.flat())
      ) {
        state.polygonPathArrWcong = [
          ...state.polygonPathArrWcong,
          action.payload,
        ]
      }
    },
    resetPolygonPathArrWcong(state, action) {
      state.polygonPathArrWcong = []
    },
    setCenterPoint(state, action) {
      state.centerpoint = action.payload
    },
    setRoadView(state, actions) {
      state.roadView = { ...state.roadView, ...actions.payload }
    },
  },
})

export const {
  setData,
  setSelectedName,
  setpolygonPathArrWcong,
  resetPolygonPathArrWcong,
  setCenterPoint,
  setRoadView,
} = housingComplexSlice.actions

export default housingComplexSlice.reducer
