import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 계정관리 올인원 멤버 리스트 item
 */
const NoteAccountAllInOneMembersListItem = ({
  item,
  idx,
  onOpenDeleteModal,
}) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  return (
    <Tr>
      <NumberTd>{idx + 1}</NumberTd>
      <td>{item?.user_name}</td>
      <td>{item?.member_type}</td>
      <td>{item?.phone_number}</td>
      <td>{item?.period}</td>
      <DeleteTd>
        {item?.member_srl != loginInfo?.member_srl && (
          <EditText
            onClick={() =>
              onOpenDeleteModal({
                member_srl: item?.member_srl,
                plan_srl: item?.plan_srl,
              })
            }
          >
            삭제
          </EditText>
        )}
      </DeleteTd>
    </Tr>
  )
}

const EditText = styled.div`
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
  color: ${theme.colors.tint.danger};
`

const DeleteTd = styled.td`
  width: 60px;
`

const NumberTd = styled.td`
  color: ${theme.colors.gray[600]};
`

const Tr = styled.tr`
  &:nth-child(2n) {
    background-color: ${theme.colors.gray[50]};
  }
`

export default NoteAccountAllInOneMembersListItem
