import styled from 'styled-components'
import NoteDonghoHeader from './NoteDonghoHeader'
import { useSelector } from 'react-redux'
import NoteDonghoContent from './NoteDonghoContent'
import NoteDonghoNoSelectDanji from './NoteDonghoNoSelectDanji'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import NoteDonghoContainer from 'containers/notev2/NoteDonghoContainer'
import NoteDonghoSkeleton from './NoteDonghoSkeleton'

/**
 * 동호관리 메인(오른쪽)
 */
const NoteDongho = () => {
  const isSelected = useRouteMatch('/note/v2/dongho/:id')
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  const hasAddedList = useSelector(
    (state) => state.noteDongho.hasAddedList,
  )

  return (
    <NoteDonghoContainer>
      {() => {
        return (
          <>
            {isSelected ? (
              <Wrapper>
                {selectedDanji ? (
                  <NoteDonghoHeader />
                ) : (
                  <NoteDonghoSkeleton />
                )}
                {selectedDanji ? (
                  <NoteDonghoContent />
                ) : (
                  <NoteDonghoSkeleton />
                )}
              </Wrapper>
            ) : (
              <>
                {hasAddedList != 'notReady' && (
                  <NoteDonghoNoSelectDanji />
                )}
              </>
            )}
          </>
        )
      }}
    </NoteDonghoContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export default NoteDongho
