import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { rgba } from 'polished'
import { Tooltip } from 'antd'

const palette = {
  green: {
    color: theme.colors.primary[500],
    backgroundColor: theme.colors.primary[50],
  },

  blue: {
    color: theme.colors.tint.secondary,
    backgroundColor: theme.colors.tint['secondary-050'],
  },

  cyan: {
    color: '#40a4ff',
    backgroundColor: rgba('#40a4ff', 0.1),
  },

  orange: {
    color: '#e99b30',
    backgroundColor: '#ffedd3',
  },

  red: {
    color: '#e1363d',
    backgroundColor: '#ffe4e6',
  },

  purple: {
    color: '#7c42f8',
    backgroundColor: '#e9dfff',
  },

  pink: {
    color: '#d47ac0',
    backgroundColor: rgba('#d47ac0', 0.1),
  },

  black: {
    color: theme.colors.base.white,
    backgroundColor: theme.colors.gray[800],
  },

  gray: {
    color: theme.colors.gray[700],
    backgroundColor: theme.colors.gray[200],
    borderColor: theme.colors.gray[200],
  },

  default: {
    color: theme.colors.gray[800],
    backgroundColor: 'white',
    borderColor: theme.colors.gray[200],
  },
}

const getPaletteStyle = ({ color, backgroundColor, borderColor }) => {
  return {
    style: `
    color: ${color};
    background-color: ${backgroundColor};
    ${borderColor && `border: 1px solid ${borderColor}`}
  `,
    color,
    backgroundColor,
  }
}
export const getTagColorStyle = (tagColor) => {
  switch (tagColor) {
    case 'primary':
      return getPaletteStyle(palette.green)
    case 'secondary':
      return getPaletteStyle(palette.blue)
    case 'geekblue':
      return getPaletteStyle(palette.blue)
    case 'warn':
      return getPaletteStyle(palette.orange)
    case 'gold':
      return getPaletteStyle(palette.orange)
    case 'danger':
      return getPaletteStyle(palette.red)
    case 'default':
      return getPaletteStyle(palette.default)
    default:
      if (!palette[tagColor]) return
      return getPaletteStyle(palette?.[tagColor])
  }
}

const StyledTag = styled.article`
  display: inline-flex;
  align-items: center;

  color: ${theme.colors.gray[700]};
  font-size: 12px;
  font-weight: 700;
  line-height: 1;

  border-radius: 4px;
  background-color: ${theme.colors.gray[200]};

  ${({ circle }) =>
    circle &&
    `
    border-radius: 60px;
    overflow: hidden;
  `}

  ${({ border }) =>
    border &&
    `
    border: 1px solid transparent;
  `}

  ${({ marginRight }) =>
    marginRight &&
    `
    margin-right: 0.5rem;
  `}
  ${({ marginLeft }) =>
    marginLeft &&
    `
    margin-left: 0.5rem;
  `}

  ${({ color }) => {
    if (color)
      return `
      ${getTagColorStyle(color)?.style};
    `
  }}

  ${({ color, border }) => {
    if (color && border) {
      return `
        color: ${getTagColorStyle(color).color};
        border-color: ${getTagColorStyle(color).color};
        background-color: white;
      `
    } else if (border) {
      return `
        color: ${theme.colors.gray[900]};
        border-color: ${theme.colors.gray[900]};
        background-color: white;
      `
    }
  }}

  ${({ icon }) =>
    icon &&
    `
    flex-direction: column;
    width: 3.25rem;
    height: 3.25rem;
  `}
`

const TagInner = styled.div`
  padding: 5px 9px;

  ${({ isLabel, labelExist }) => {
    if (isLabel) {
      return `
        border-radius: 2px 0 0 2px;
      `
    } else if (labelExist) {
      return `
      border-radius: 0 5px 5px 0;
      `
    } else {
      return `
      border-radius: 5px;
      `
    }
  }}

  ${({ isLabel, color, border }) => {
    if (isLabel && color && border) {
      return `
        color: white;
        background-color: ${getTagColorStyle(color)?.color};
      `
    } else if (isLabel && color) {
      return `
        background-color: ${rgba(getTagColorStyle(color)?.color, 0.1)};
      `
    }

    if (isLabel && border) {
      return `
        color: white;
        background-color: ${theme.colors.gray[900]};
      `
    } else if (isLabel) {
      return `
        background-color: rgba(0, 0, 0, 0.1);
      `
    }

    if (!isLabel && color && border) {
      return `
        color: ${getTagColorStyle(color)?.color};
        background-color: white;
      `
    } else if (!isLabel && border) {
      return `
        color: ${theme.colors.gray[900]};
        background-color: white;
      `
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'xs':
        return `
        padding: 2px 4px;
        font-size: 10px;
      `
      case 'small':
        return `
        padding: 3px 5px;
      `
      case 'large':
        return `
        padding: 6px 12px;
        font-size: 14px;
      `
    }
  }}

${({ icon }) =>
    icon &&
    `
    flex: 1;
    display: flex;
    width: 3.25rem;
    justify-content: center;
    align-items: center;

    font-size: 1rem;

    & > svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  `}

  ${({ isLabelValue }) =>
    isLabelValue &&
    `
    font-weight: 500;
  `}

  ${({ iconExist }) =>
    iconExist &&
    `
    padding-left: 0;
    padding-right: 0;
  `}
`

const Tag = ({
  color = 'gray',
  size,
  children,
  label,
  labelTooltip,
  tooltip,
  icon,
  border,
  marginRight,
  marginLeft,
  circle,
  onClick,
  className,
}) => {
  return (
    <StyledTag
      color={color}
      icon={icon}
      border={border}
      marginRight={marginRight}
      marginLeft={marginLeft}
      circle={circle}
      onClick={onClick}
      className={className}
    >
      {label && (
        <Tooltip title={labelTooltip}>
          <TagInner
            color={color}
            size={size}
            isLabel
            icon={icon}
            border={border}
          >
            {label}
          </TagInner>
        </Tooltip>
      )}

      {children && (
        <Tooltip title={tooltip}>
          <TagInner
            size={size}
            isLabelValue={label}
            color={color}
            border={border}
            labelExist={label}
            iconExist={icon}
          >
            {children}
          </TagInner>
        </Tooltip>
      )}
    </StyledTag>
  )
}

export default Tag
