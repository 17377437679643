import { useCallback, useState, useEffect } from 'react'
import useAxios from '../useAxios'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { modifyAddress } from 'lib/api/notev2/listApi'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { simpleUpdateAd } from 'lib/api/notev2/ad'
import {
  resetAfterAddressChange,
  updateAfterAddressChange,
} from 'lib/utils/notev2/gridData'
import usePrivateChannel from './usePrivateChannel'
import useCreateAdWithNote from './useCreateAdWithNote'

/**
 * 광고관리 그리드
 * 매물을 수정한다
 * @returns 매물 수정 함수
 *
 * * onModify({ params, updateData, rowNode }): 기존 그리드 데이터 전체, 업데이트할 데이터
 *   -> rowNode 데이터가 있으면, 저장 요청의 응답이 성공이면 그리드 업데이트까지 한번에 같이한다
 * * isError: 에러가 났는지 여부
 *
 */
function useModifyAdAddress() {
  const planInfo = useSelector((state) => state.auth.planInfo)
  // 업데이트 api
  const [onRequest, , data] = useAxios(simpleUpdateAd)
  const [isError, setIsError] = useState(false)
  const [rowNode, setRowNode] = useState(null)
  const [isResetNoteGridData, setIsResetNoteGridData] = useState(false) //유효하지 않은 주소로 저장했으면 그리드 데이터를 다 초기화 시켜야함
  const [invalidAddr, setInvalidAddr] = useState('')
  const [validAddress, setValidAddress] = useState(null)
  const [callbackFn, setCallbackFn] = useState(null)
  const [onCreate] = useCreateAdWithNote()
  const [mergedData, setMergedData] = useState(null)

  const { isReadOnlyChannel } = usePrivateChannel()

  /**
   * 노트 저장
   * @param channel_srl 채널 srl
   * @param address_srl 주소 srl
   * @param form 주소 정보
   */
  const onModify = useCallback(
    ({
      ad_srl,
      validData,
      currentRowNode,
      invalidAddr,
      callbackFn,
    }) => {
      if (isReadOnlyChannel) return

      if (currentRowNode) {
        setRowNode(currentRowNode)
        setValidAddress(validData)
      }

      if (callbackFn) {
        setCallbackFn(() => callbackFn)
      }

      const resetParams = invalidAddr
        ? {
            display_address: null,
            address_id: null,
            dong: null,
            ho: null,
          }
        : {}
      //유효하지 않은 주소로 저장했는지
      if (invalidAddr) {
        setIsResetNoteGridData(true)
        setInvalidAddr(invalidAddr)
      } else {
        setIsResetNoteGridData(false)
        setInvalidAddr('')
      }

      setMergedData(Object.assign({}, currentRowNode?.data, validData))

      // //노트 저장 api
      onRequest({
        ad_srl: ad_srl,
        plan_srl: planInfo.allinone_plan_srl,
        ...validData,
        contract_type: currentRowNode?.data.contract_type,
        dong: validData?.dong
          ? validData.dong
          : currentRowNode?.data?.dong,
        ho: validData?.ho ? validData.ho : currentRowNode?.data?.ho,
        exclusive_area: validData?.exclusive_area
          ? validData.exclusive_area
          : currentRowNode?.data?.exclusive_area,
        contract_area: validData?.contract_area
          ? validData.contract_area
          : currentRowNode?.data?.contract_area,
        total_floor_area: validData?.total_floor_area
          ? validData.total_floor_area
          : currentRowNode?.data?.total_floor_area,
        land_area: validData?.land_area
          ? validData.land_area
          : currentRowNode?.data?.land_area,
        building_area: validData?.building_area
          ? validData.building_area
          : currentRowNode?.data?.building_area,

        ...resetParams,
      })
    },
    [],
  )

  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.error('modify note 에러')

      setCallbackFn(null)
      setRowNode(null)
      onReset()
      return
    }

    if (rowNode) {
      if (isResetNoteGridData) {
        //유효하지 않은 주소 - 그리드데이터 초기화
        resetAfterAddressChange({
          currentRowNode: rowNode,
          display_address: invalidAddr,
          invalid_addr_name: invalidAddr,
        })
      } else {
        const updataData = Object.assign({}, validAddress, {
          type: 'update',
          dong: validAddress.dong,
          ho: validAddress.ho,
          invalid_addr_name: '',
          address_jibun: validAddress.full_address,
          property_type: validAddress.property_type,
        })

        //그리드 데이터 업데이트
        updateAfterAddressChange({
          currentRowNode: rowNode,
          ...updataData,
        })

        setRowNode(null)
        setValidAddress(null)
        setIsResetNoteGridData(false)
        setInvalidAddr('')
      }
    }

    if (callbackFn) {
      callbackFn()
      setCallbackFn(null)
    }
    fillCompleteCreate()
  }, [data])

  /**
   * 가격 구분 별 key 얻기
   * @return {boolean} 가격 채워졌는지 여부
   */
  const getPriceHas = (data, type) => {
    if (type == '매매') {
      return data?.sale_price
    } else if (type == '전세') {
      return data?.deposit
    } else if (type == '월세') {
      return data?.deposit && data?.rent
    } else {
      return false
    }
  }

  /**
   * 광고용 값이 다 채워졌으면 광고, 노트 동시 생성
   * 주소, 종류, 거래구분, 가격이 모두 채워지면 호출
   */
  const fillCompleteCreate = () => {
    if (mergedData) {
      if (mergedData.already_created == 'Y') return
      const isFillComplete =
        mergedData?.address_id &&
        mergedData?.property_type &&
        mergedData?.contract_type &&
        getPriceHas(mergedData, mergedData?.contract_type)

      if (isFillComplete) {
        onCreate(mergedData, rowNode)
      }
    }
  }

  return [onModify]
}

export default useModifyAdAddress
