import styled from 'styled-components'

const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.25rem 1.25rem;

  &.full {
    padding: 0;
  }

  & > button {
    margin-right: 0.75rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

const Prepend = styled.div`
  flex: 1;
`

const ModalFooter = ({ children, prepend }) => {
  return (
    <StyledModalFooter className="app-modal-footer">
      {prepend && <Prepend>{prepend}</Prepend>}
      {children}
    </StyledModalFooter>
  )
}

export default ModalFooter
