import React, { forwardRef, useCallback, useEffect } from 'react'
import { Button } from 'components/design'
import styled from 'styled-components'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import { ReactComponent as MapIcon } from 'assets/icon/map-fill.svg'
import { useDispatch } from 'react-redux'
import { setNoteListIsShowMap } from 'modules/notev2'
import { useSelector } from 'react-redux'
import theme, { media } from 'lib/styles/theme'
import { useNoteMarker } from 'lib/hooks/useNoteMarker'

/**
 * 매물관리 시트펴기/접기
 */
const NoteMapMode = ({ setResizable, resetLayer }, ref) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isShowMap = useSelector(
    (state) => state.notev2.noteListIsShowMap,
  )
  const dispatch = useDispatch()

  const { searchNoteMarkers } = useNoteMarker()

  useEffect(() => {
    if (localStorage.getItem('twoDepthWidth')) {
      dispatch(setNoteListIsShowMap(true))
    } else {
      dispatch(setNoteListIsShowMap(false))
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      dispatch(setNoteListIsShowMap(false))
    }
  }, [isMobile])

  useEffect(() => {
    if (isShowMap) searchNoteMarkers()
  }, [isShowMap])

  const goBack = useCallback(() => {
    resetLayer()

    dispatch(setNoteListIsShowMap(false))
    setTimeout(() => {
      if (!ref?.current?.resizable) return
      ref.current.resizable.classList.add('active', 'flip')
    }, 10)
    window.removeEventListener('popstate', goBack)
  }, [])

  const onClick = useCallback(() => {
    if (isMobile) {
      window.addEventListener('popstate', goBack)
      history.pushState(null, document.title, `/map/note/v2/notes`)
    }

    if (!isShowMap) {
      if (window.innerWidth >= 1700) {
        localStorage.setItem('twoDepthWidth', 893)
      } else {
        localStorage.setItem('twoDepthWidth', window.innerWidth / 2)
      }
      setResizable()
      dispatch(setNoteListIsShowMap(true))
    } else {
      resetLayer()
      dispatch(setNoteListIsShowMap(false))
      localStorage.removeItem('twoDepthWidth')
    }
  }, [isShowMap, isMobile])

  return (
    <>
      <StyledButton
        plain
        color="primary"
        size={isTablet || isMobile ? 'small' : 'medium'}
        onClick={onClick}
      >
        {isShowMap ? (
          <i className="fad fa-angle-double-right"></i>
        ) : (
          <MapIcon fill={theme.colors.primary[400]} />
        )}
        {!isMobile && !isTablet && (
          <span>{isShowMap ? '시트펴기' : '지도보기'}</span>
        )}
      </StyledButton>
    </>
  )
}

const StyledButton = styled(Button)`
  margin-left: 16px;

  span {
    margin-left: 8px !important;
  }

  i {
    font-size: 16px;
  }

  ${media.mediumS`
    padding: 10px;
    margin-left: 0;
  `}
`
export default React.memo(forwardRef(NoteMapMode))
