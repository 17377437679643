import theme from 'lib/styles/theme'
import moment from 'moment'
import { useState } from 'react'
import styled from 'styled-components'
import NoteInput from '../elements/NoteInput'

const CompleteArgs = ({
  contractType,
  contractDate,
  setContractDate,
  price1,
  setPrice1,
  price2,
  setPrice2,
}) => {
  const onChangePrice1 = (e) => {
    setPrice1(e.target.value)
  }

  const onChangePrice2 = (e) => {
    setPrice2(e.target.value)
  }

  const onChangeDate = (e) => {
    setContractDate(moment(e).format('YYYYMMDD'))
  }

  return (
    <Block>
      <BlockItem>
        <BlockTitle>
          {contractType == '월세' ? '보증금/월세' : `${contractType}가`}
        </BlockTitle>
        <BlockContent>
          <InputWrapper>
            <NoteInput
              white
              width={contractType == '월세' ? 80 : 120}
              type="number"
              value={price1}
              append={'만원'}
              placeholder={contractType == '매매' ? '매매가' : '보증금'}
              onChange={onChangePrice1}
            />
            {contractType == '월세' && (
              <NoteInput
                white
                width={80}
                type="number"
                value={price2}
                append={'만원'}
                placeholder="월세"
                onChange={onChangePrice2}
              />
            )}
          </InputWrapper>
        </BlockContent>
      </BlockItem>
      <BlockItem>
        <BlockTitle>거래일자</BlockTitle>
        <BlockContent>
          <NoteInput
            type="date"
            key={'note-ad-complete-date'}
            defaultValue={contractDate}
            value={contractDate ? contractDate : ''}
            onChange={onChangeDate}
            placeholder="연도.월.일"
            selected={
              contractDate && contractDate != '00000000'
                ? moment(contractDate).toDate()
                : ''
            }
            readOnly={false}
            white
          />
        </BlockContent>
      </BlockItem>
    </Block>
  )
}

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 14px;
  background-color: ${theme.colors.gray[100]};
`

const BlockItem = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const BlockTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  flex-shrink: 0;
`

const BlockContent = styled.div``

const InputWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export default CompleteArgs
