import BdsDetailHeader from 'components/bds/detail/BdsDetailHeader'
import BdsDetailSection from 'components/bds/detail/BdsDetailSection'
import BdsDetailTab from 'components/bds/detail/BdsDetailTab'
import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import BdsDetailContainer from 'containers/bds/BdsDetailContainer'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const BdsDetailPage = () => {
  const history = useHistory()
  const onClose = () => {
    history.replace('/bds')
  }
  return (
    <ExternalLayer onClose={onClose} scrollId="bds_scroll">
      <BdsDetailContainer>
        {({ data, activeTab, onClickTab, tabs }) => {
          return (
            data && (
              <>
                <BdsDetailHeader data={data} />
                <BdsDetailTab
                  tabs={tabs}
                  activeTab={activeTab}
                  onClickTab={onClickTab}
                />
                <BdsDetailSection data={data} tabs={tabs} />
              </>
            )
          )
        }}
      </BdsDetailContainer>
    </ExternalLayer>
  )
}

export default BdsDetailPage
