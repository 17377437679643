import theme from 'lib/styles/theme'
import styled from 'styled-components'
import SummaryShareButton from 'components/summary/share/SummaryShareButton'
import band from 'assets/images/ic_band.png'
import SummaryShareKakao from 'components/summary/share/SummaryShareKakao'
import Spinner from 'components/common/Loading/Spinner'

const NoteInviteRewardShare = ({ referCode, isLoading }) => {
  const path = `https://${window.location.host}/map/invite/${referCode}`

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text)
      alert('클립보드에 링크가 복사되었습니다.')
    } catch (e) {
      alert('복사에 실패하였습니다')
    }
  }

  const onClickCopy = () => {
    handleCopyClipBoard(path)
  }

  const onFocusInput = (e) => {
    e.target.select()
  }

  return (
    <>
      <InputWrapper>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <StyledInput readOnly value={path} onFocus={onFocusInput} />
        )}

        <CopyButton onClick={onClickCopy}>URL 복사</CopyButton>
      </InputWrapper>
      <ShareButtons>
        {/* 카카오 */}
        <SummaryShareKakao pathname={path} type="invite" onlyWeb />
        {/* 페이스북 */}
        <a href={`https://www.facebook.com/sharer.php?u=${path}`}>
          <SummaryShareButton color="#247bfc">
            <i className="fab fa-facebook-f"></i>
          </SummaryShareButton>
        </a>
        {/* 트위터 */}
        <a
          href={`https://twitter.com/intent/tweet?url=${path}&amp;text=주택지도`}
        >
          <SummaryShareButton color="#4cb4ff">
            <i className="fab fa-twitter"></i>
          </SummaryShareButton>
        </a>
        {/* 밴드 */}
        <a
          href={
            window.innerWidth > 768
              ? `http://www.band.us/plugin/share?body=주택지도%0A${path}`
              : `bandapp://create/post?text=주택지도%0A${path}`
          }
        >
          <SummaryShareButton color="#13c35a">
            <img src={band} alt="band" />
          </SummaryShareButton>
        </a>
      </ShareButtons>
    </>
  )
}

const InputWrapper = styled.div`
  position: relative;
  height: 32px;
  overflow: hidden;
  border-radius: 8px;
`

const StyledInput = styled.input`
  width: 100%;
  height: calc(100% + 2px);
  padding-right: 67px;
  padding-left: 7px;
  background-color: ${theme.colors.base.white};
  border: none;
  font-size: 12px;
  &:focus {
    border: none;
    outline: none;
  }
`

const CopyButton = styled.button`
  position: absolute;
  width: 67px;
  height: 100%;
  right: 0;
  top: 0;
  background-color: ${theme.colors.gray[100]};
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
`

const ShareButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 40px;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 34px;
  background-color: rgb(0, 0, 0, 0.2);
  z-index: 11;
  position: inherit;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
  }
`

export default NoteInviteRewardShare
