import { useState } from 'react'
import SummaryHcCharacterTabBuildingAge from 'components/summary/SummaryHousingComplex/SummaryHcCharacterTabBuildingAge'
import SummaryHcCharacterTabTurnover from 'components/summary/SummaryHousingComplex/SummaryHcCharacterTabTurnover'
import SummaryHcCharacterTabType from 'components/summary/SummaryHousingComplex/SummaryHcCharacterTabType'
import { Skeleton } from 'antd'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Tab,
  TabItem,
} from 'components/design'

const SummaryHcCharacter = ({ data, loading }) => {
  const tabs = ['건물유형', '노후도', '거래회전률']
  const [activedTab, setActivedTab] = useState(0)

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          주택단지의
          <br />
          특징을 알아볼까요?
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Tab noContainer>
          {tabs.map((item, i) => (
            <TabItem
              key={i}
              color="primary"
              active={activedTab === i}
              onClick={() => setActivedTab(i)}
              expand
            >
              {item}
            </TabItem>
          ))}
        </Tab>
        {loading ? (
          <Skeleton />
        ) : (
          data && (
            <>
              {activedTab === 0 && (
                <SummaryHcCharacterTabType data={data} />
              )}
              {activedTab === 1 && (
                <SummaryHcCharacterTabBuildingAge
                  data={data?.building_age}
                  building_age_mean={data?.building_age_mean}
                />
              )}
              {activedTab === 2 && (
                <SummaryHcCharacterTabTurnover
                  data={data?.rpp_turnover}
                />
              )}
            </>
          )
        )}
      </CardBody>
    </Card>
  )
}

export default SummaryHcCharacter
