import styled from 'styled-components'
import { FormItem } from 'components/design'

import { List } from 'components/design'

import NoteFormContactSelect from './NoteFormContactSelect'
import { Button } from 'components/design'
import NoteFormContactItem from './NoteFormContactItem'
import { useState } from 'react'

const StyledLabel = styled.div`
  margin-left: 1.25rem;
  font-size: 14px;

  ${({ noContainer }) =>
    noContainer &&
    `
    margin-left: 0;
  `}
`

const StyledButtonWrap = styled.div`
  padding: 0 1.25rem;

  ${({ noContainer }) =>
    noContainer &&
    `
    padding: 0;
  `}
`

const NoteFormContact = ({
  contact_srls,
  myContacts,
  onChange,
  noContainer,
}) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <FormItem
        label={
          <StyledLabel noContainer={noContainer}>
            연락처 연동
          </StyledLabel>
        }
        noContainer
      >
        {Array.isArray(contact_srls) && contact_srls.length > 0 && (
          <List>
            {Array.isArray(contact_srls) &&
              contact_srls?.map((item, i) => (
                <NoteFormContactItem
                  myContacts={myContacts}
                  contacts={contact_srls || []}
                  contact_srls={contact_srls}
                  item={item}
                  srl={item?.contact_srl}
                  type={item?.type}
                  key={i}
                  noContainer={noContainer}
                  onChange={onChange}
                />
              ))}
          </List>
        )}

        <StyledButtonWrap noContainer={noContainer}>
          <Button
            color="blue"
            onClick={() => setVisible(true)}
            plain
            expand
          >
            <i className="fal fa-plus" />
            <span>연락처 불러오기</span>
          </Button>
        </StyledButtonWrap>
      </FormItem>

      <NoteFormContactSelect
        visible={visible}
        setVisible={setVisible}
        myContacts={myContacts} // 자신의 연락처 목록 주입
        contractSrls={contact_srls}
        onSubmit={onChange}
      />
    </>
  )
}

export default NoteFormContact
