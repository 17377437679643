import { Button } from 'components/design'
import { useEffect, useState } from 'react'
import NoteListMobileAddressSearchModal from './NoteListMobileAddressSearchModal'
import useSaveAddressForSheet from 'lib/hooks/notev2/useSaveAddressForSheet'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'

const NoteListMobileAdd = ({ mode, search }) => {
  const history = useHistory()
  const [visibleModal, setVisibleModal] = useState(false)
  const { onSaveAddressForSheet, data } = useSaveAddressForSheet(mode)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const { channelSrl, folderSrl, addressSrl } = useNoteMatch()

  /**
   * 주소 검색 모달 열기
   */
  const onClickButton = () => {
    setVisibleModal(true)
  }

  const onClickItem = (validData) => {
    onSaveAddressForSheet({
      channel_srl: channelInfo.channel_srl,
      address_id: validData.address_id,
      validAddressInfo: validData,
      folder_srl: mode == 'card' ? folderSrl : null,
      callbackFn: mode == 'card' ? cardCallbackFn : search,
    })
  }

  const cardCallbackFn = (noteSrl) => {
    search()

    if (!noteSrl) return
    history.push(`/note/${channelSrl}/${folderSrl}/${noteSrl}`)
  }

  return (
    <>
      <Button
        onClick={onClickButton}
        color="blue"
        size={mode == 'sheet' ? 'small' : ''}
      >
        <i className="fa fa-plus"></i>
      </Button>
      <NoteListMobileAddressSearchModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        onClickItem={onClickItem}
        defaultValue={''}
        mode={mode}
        folderSrl={folderSrl}
        callbackSearch={mode == 'card' ? cardCallbackFn : search}
      />
    </>
  )
}
export default NoteListMobileAdd
