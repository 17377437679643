import styled from 'styled-components'
import NoteAdColumnPropertyType from '../columns/basic/NoteAdColumnPropertyType'
import { Table, TableWrapper, Title } from './NoteAdFormStyle'
import NoteAdColumnAddress from '../columns/basic/NoteAdColumnAddress'
import NoteAdColumnContractType from '../columns/price/NoteAdColumnContractType'
import NoteAdColumnSalePrice from '../columns/price/NoteAdColumnSalePrice'
import NoteAdColumnDeposit from '../columns/price/NoteAdColumnDeposit'
import NoteAdColumnRent from '../columns/price/NoteAdColumnRent'
import NoteAdColumnConvertJeonse from '../columns/price/NoteAdColumnConvertJeonse'
import NoteAdColumnLoan from '../columns/price/NoteAdColumnLoan'
import NoteAdColumnAlreadyDeposit from '../columns/price/NoteAdColumnAlreadyDeposit'
import NoteAdColumnAlreadyRent from '../columns/price/NoteAdColumnAlreadyRent'
import NoteAdColumnCotractDateInfo from '../columns/price/NoteAdColumnCotractDateInfo'
import NoteAdColumnSecureFacility from '../columns/facility/NoteAdColumnSecureFacility'
import NoteAdColumnEtcFacility from '../columns/facility/NoteAdColumnEtcFacility'
import NoteAdColumnMoveDate from '../columns/detail/NoteAdColumnMoveDate'
import NoteAdColumnCharacter from '../columns/detail/NoteAdColumnCharacter'
import NoteAdColumnDetail from '../columns/detail/NoteAdColumnDetail'
import NoteAdColumnPictures from '../columns/detail/NoteAdColumnPictures'
import theme from 'lib/styles/theme'
import NoteAdColumnStoreInfo from '../columns/store/NoteAdColumnStoreInfo'
import NoteAdColumnContact from '../columns/store/NoteAdColumnContact'
import NoteAdColumnAirConditioningFacility from '../columns/facility/NoteAdColumnAirConditioningFacility'
import NoteAdColumnHeatingFuel from '../columns/facility/NoteAdColumnHeatingFuel'
import NoteAdColumnHeatingType from '../columns/facility/NoteAdColumnHeatingType'
import NoteAdColumnLandArea from '../columns/basic/NoteAdColumnLandArea'
import NoteAdColumnVerification from '../columns/verification/NoteAdColumnVerification'
import NoteAdColumnZoneUse from '../columns/basic/NoteAdColumnZoneUse'
import NoteAdColumnLandOption from '../columns/basic/NoteAdColumnLandOption'
import NoteAdColumnFee from '../columns/fee/NoteAdColumnFee'
import NoteAdColumnCurrentPurpose from '../columns/basic/NoteAdColumnCurrentPurpose'
import NoteAdColumnRecommendPurpose from '../columns/basic/NoteAdColumnRecommendPurpose'
import NoteAdPriceDesc from '../columns/price/NoteAdPriceDesc'
import NoteAdStoreDesc from '../columns/store/NoteAdStoreDesc'

/**
 * 광고관리 매물등록 테이블
 * 토지
 */
const NoteAdFormLand = () => {
  return (
    <Wrapper>
      <Title>1. 기본정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnPropertyType />
          <NoteAdColumnAddress />
          <NoteAdColumnZoneUse half />
          <NoteAdColumnLandArea required columnKey="article.spc1" />
          <NoteAdColumnCurrentPurpose />
          <NoteAdColumnRecommendPurpose />
          <NoteAdColumnLandOption />
        </Table>
      </TableWrapper>
      <Title attachDesc>2. 거래정보</Title>
      <NoteAdPriceDesc />
      <TableWrapper>
        <Table>
          <NoteAdColumnContractType />
          <NoteAdColumnSalePrice required />
          <NoteAdColumnDeposit />
          <NoteAdColumnRent />
          <NoteAdColumnConvertJeonse />
          <NoteAdColumnLoan />
          <NoteAdColumnCotractDateInfo desc />
        </Table>
      </TableWrapper>
      <Title>3. 상세정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnCharacter />
          <NoteAdColumnDetail />
          <NoteAdColumnPictures />
        </Table>
      </TableWrapper>
      <Title attachDesc>4. 중개업소 정보</Title>
      <NoteAdStoreDesc />
      <TableWrapper>
        <Table>
          <NoteAdColumnStoreInfo />
          <NoteAdColumnContact />
        </Table>
      </TableWrapper>
      <Title>5. 검증 방식</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnVerification />
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const Desc = styled.div`
  display: flex;
  margin-left: 4px;
  margin-bottom: 6px;
  color: ${theme.colors.tint.danger};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;

  &::before {
    content: '·';
    font-size: 30px;
    margin-right: 7px;
  }
`

export default NoteAdFormLand
