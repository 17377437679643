import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { Tooltip } from 'antd'
import useCalendarRemove from './hooks/uesCalendarRemove'
import CalendarModify from './CalendarModify'
import useGetCalendars from 'components/jmapnote/calendar/hooks/useGetCalendars'
import { useSelector } from 'react-redux'
import NoteCalendarForm from './NoteCalendarForm'
import moment from 'moment'
import { modifyCalendar } from 'lib/api/note/calendarApi'
import useAxios from 'lib/hooks/useAxios'

const NoteCalendarScheduleItem = ({ item, currentDate }) => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const history = useHistory()
  const { handleRemoveCalendar } = useCalendarRemove()
  const { _getCalendars } = useGetCalendars()

  const [visible, setVisible] = useState(false)
  const [modifyVisible, setModifyVisible] = useState(false)

  const [onRequest, loading, data, error] = useAxios(modifyCalendar)

  const tagColors = {
    상담: 'green',
    계약일: 'orange',
    입주가능: 'blue',
    입주예정: 'blue',
    중도금일: 'purple',
    잔금일: '#ca5db2',
    계약만기: 'red',
    매물방문: 'green',
    기타: 'blue',
  }

  const isClickable = () => {
    return item?.note_srl ? true : false
  }

  const onClickItem = () => {
    setVisible(true)
  }

  const onClickToNote = () => {
    if (!isClickable()) return
    history.push(
      `/note/v2/notes/${item.channel_srl}/${item?.address_srl}/${item?.note_srl}`,
    )
  }

  const onClickRemove = () => {
    handleRemoveCalendar({
      channelSrl: item.channel_srl,
      calendarSrl: item.calendar_srl,
    })
  }

  const onSuccessModify = () => {
    setModifyVisible(false)
    setVisible(false)
    _getCalendars()
  }

  /**
   * 일정 저장
   * @param {*} values
   */
  const handleSubmit = (values) => {
    onRequest({
      ...values,
      calendarSrl: item.calendar_srl,
      contact_srls: JSON.stringify(values.contact_srls),
    })
  }

  useEffect(() => {
    if (!data) return
    setVisible(false)
    _getCalendars()
  }, [data])

  return (
    <>
      <Block>
        <Title>
          <TitleLink
            card={localStorage.getItem('notemode') != 'sheet'}
            color={tagColors[item?.type] || 'blue'}
            onClick={onClickItem}
          >
            <Icon color={tagColors[item?.type] || 'blue'} />
            <span>{item?.type}</span>
          </TitleLink>
          <AddressInfo
            card={localStorage.getItem('notemode') != 'sheet'}
          >
            {isClickable() ? (
              <Address
                card={localStorage.getItem('notemode') != 'sheet'}
                onClick={onClickToNote}
              >
                {item?.building_name || item?.address_jibun || ''}{' '}
                {item?.dong} {item?.ho}
              </Address>
            ) : (
              <Address empty>연동 매물 없음</Address>
            )}
            <Memo card={localStorage.getItem('notemode') != 'sheet'}>
              {item.content}
            </Memo>
          </AddressInfo>
        </Title>
        {channelInfo?.member_type != 'readonly' && (
          <Tools>
            <Tooltip title="일정 수정">
              <SubButton onClick={() => setModifyVisible(true)}>
                <i className="far fa-edit"></i>
              </SubButton>
            </Tooltip>
            <Tooltip title="일정 삭제">
              <SubButton onClick={onClickRemove}>
                <i className="far fa-trash-alt"></i>
              </SubButton>
            </Tooltip>
          </Tools>
        )}
      </Block>
      <NoteCalendarForm
        title={
          <ModifyTitle>
            <div>일정 수정하기</div>
            <ModifyTitleDate>
              {moment(currentDate).format('YYYY')}년{' '}
              {moment(currentDate).format('MM')}월{' '}
              {moment(currentDate).format('DD')}일
            </ModifyTitleDate>
          </ModifyTitle>
        }
        visible={visible}
        setVisible={setVisible}
        currentDate={currentDate}
        onSubmit={handleSubmit}
        defaultValue={item}
      />
      <CalendarModify
        visible={modifyVisible}
        setVisible={setModifyVisible}
        defaultValue={item}
        calendarSrl={item?.calendar_srl}
        onSuccess={onSuccessModify}
      />
    </>
  )
}

const Block = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 12px;
`

const getTagColor = (color) => {
  if (!color) return theme.colors.gray[700]
  return theme.colors.sub[color] || color
}

const Title = styled.div`
  position: relative;
  margin-right: -0.5rem;
  display: flex;
  align-items: flex-start;
  width: 100%;

  ${media.mediumS`
    width: 100%;
    align-items: flex-start;
  `}
`

const TitleLink = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  width: 72px;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    text-decoration: underline;
  }

  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}

  ${({ card }) =>
    card &&
    `
      width: 70px;
    `}
`

const Icon = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: ${theme.borderRadius[1]};
  background-color: ${({ color }) => getTagColor(color)};
`

const Tools = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  right: 0;
  top: 0;
  i {
    line-height: 100%;
    font-size: 13px;
  }
`

const SubButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  &:not(:last-child) {
    margin-right: 0.25rem;
  }

  background-color: white;
  border: none;
  outline: none;
  appearance: none;

  color: ${theme.colors.gray[400]};
  font-size: 1rem;
`

const Address = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${({ empty }) =>
    empty &&
    `
      color: ${theme.colors.gray[400]}; 
       font-weight: 400;
    `}

  ${({ card }) =>
    card &&
    `
      max-width: 60%;
    `}

  ${media.mediumS`
      max-width: 55%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: keep-all;
  `}
`

const Memo = styled.div`
  max-width: 60%;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ card }) =>
    card &&
    `
    width: 100%;
    max-width: calc(100% - 70px);
    margin-left: 0;
    margin-top: 4px;
    text-overflow: ellipsis;
  `}

  ${media.mediumS`
      display: none;
  `}
`

const ModifyTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
`

const ModifyTitleDate = styled.div`
  margin-top: 6px;
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`

const AddressInfo = styled.div`
  display: flex;
  width: 100%;

  ${({ card }) =>
    card &&
    `
    flex-direction: column;
  `}
`

export default React.memo(NoteCalendarScheduleItem)
