import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import ChannelSelect from 'components/jmapnote/channel/ChannelSelect'

const ChannelSelectContainer = ({ onChange }) => {
  const history = useHistory()
  const { channelSrl } = useNoteMatch()

  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const channels = useSelector((state) => state.channel.channels)

  //useUserLoader()

  /**
   * 선택된 채널 정보
   * @param {String} channelSrl
   * @param {Array} channels
   * @returns
   */
  const getSelectedChannelInfo = (channelSrl, channels) => {
    return channels?.find(
      (e) => channelSrl === e?.channel_srl.toString(),
    )
  }
  const selectedChannelInfo = useMemo(
    () => getSelectedChannelInfo(channelSrl, channels),
    [channelSrl, channels],
  )

  /**
   * 내가 생성한 채널 목록
   * @param {Array} channels
   * @param {Object || null} loginInfo
   * @returns
   */
  const getMyChannels = (channels, loginInfo) => {
    return channels.filter((e) => e.member_srl === loginInfo.member_srl)
  }
  const myChannels = useMemo(
    () => getMyChannels(channels, loginInfo),
    [channels, loginInfo],
  )

  /**
   * 초대된 채널목록
   * @param {Array} channels
   * @param {Object || null} loginInfo
   * @returns
   */
  const getInvitedChannels = (channels, loginInfo) => {
    return channels.filter((e) => e.member_srl !== loginInfo.member_srl)
  }
  const invitedChannels = useMemo(
    () => getInvitedChannels(channels, loginInfo),
    [channels, loginInfo],
  )

  return (
    <ChannelSelect
      channelSrl={channelSrl}
      myChannels={myChannels}
      invitedChannels={invitedChannels}
      selectedChannel={selectedChannelInfo}
      onChange={onChange}
      loginInfo={loginInfo}
    />
  )
}

export default ChannelSelectContainer
