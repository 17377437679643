import theme, { media } from 'lib/styles/theme'
import styled, { css, keyframes } from 'styled-components'
import { ReactComponent as Icon } from 'assets/icon/note/ad/cancel.svg'

const NoteAdSpeechBubble = ({ direction, onClose, text }) => {
  return ['top', 'top-full', 'bottom'].includes(direction) ? (
    <BottomAndTop direction={direction} onClick={onClose}>
      {text}
      <Icon onClick={onClose} />
    </BottomAndTop>
  ) : (
    <LeftAndRight direction={direction} onClick={onClose}>
      {text}
      <Icon onClick={onClose} />
    </LeftAndRight>
  )
}

const floatUpdown = keyframes`
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-12px);}
	60% {transform: translateY(-5px);}
`

const floatSide = keyframes`
    0%, 20%, 50%, 80%, 100% {transform: translateX(0);}
	40% {transform: translateX(-12px);}
	60% {transform: translateX(-5px);}
`

const commonStyle = css`
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  background-color: ${theme.colors.gray[800]};
  border-radius: 15px;
  padding: 12px 16px;
  z-index: 100;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.3);
  color: ${theme.colors.base.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  ${media.mediumM`
    min-width: fit-content;
    `}
`

const BottomAndTop = styled.div`
  ${commonStyle}
  animation: ${floatUpdown} 2s ease infinite;

  ${media.mediumM`
    right: 0;
    left: initial;
    transform: none;
  `}

  ${({ direction }) => {
    switch (direction) {
      case 'top':
        return `
            top: 150%;
            width: max-content;
            &:after {
                top: -15px;
                right: 8px;
                border-top: 7px solid transparent;
                border-bottom: 9px solid ${theme.colors.gray[800]};
                border-right: 7px solid transparent;
                border-left: 7px solid transparent;
            }
        `
      case 'top-full':
        return `
            top: 150%;
            width: max-content;
            &:after {
                top: -15px;
                right: 8px;
                border-top: 7px solid transparent;
                border-bottom: 9px solid ${theme.colors.gray[800]};
                border-right: 7px solid transparent;
                border-left: 7px solid transparent;
            }
        `
      case 'bottom':
        return `
            top: -50%;
            left: 50%;
            transform: translateX(-50%);
            &:after {
                left: 50%;
                bottom: -14px;
                transform: translateX(-50%);
                border-bottom: 7px solid transparent;
                border-top: 9px solid ${theme.colors.gray[800]};
                border-right: 7px solid transparent;
                border-left: 7px solid transparent;
            }
        `
    }
  }}
`

const LeftAndRight = styled.div`
  ${commonStyle};
  animation: ${floatSide} 2s ease infinite;

  ${({ direction }) => {
    switch (direction) {
      case 'right':
        return `
            right: 150px;
            
            &:after {
                top: 50%;
                transform: translateY(-50%);
                right: -15px;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-right: 7px solid transparent;
                border-left: 9px solid ${theme.colors.gray[800]};;
            }
        `
    }
  }}
`

export default NoteAdSpeechBubble
