import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 용도지역
 */
const NoteAdColumnZoneUse = ({ half }) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const usage = watch('article.usage') || ''

  const adOptions = useSelector((state) => state.noteAd.adOptions)
  const [list, setList] = useState(null)

  useEffect(() => {
    if (!adOptions) return
    setList(adOptions.use_areas)
  }, [adOptions])

  const onChangeSelect = (e) => {
    onUpdate('article.usage', e.value)
  }

  return (
    <ColumnSet half={half}>
      <ColumnTitle>용도지역</ColumnTitle>
      <ColumnVal gap={15}>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: usage || '선택',
            value: usage,
          }}
          isSearchable
          options={getSimpleSelectboxOptions(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('article.usage')}
          onChange={onChangeSelect}
        />
        <ValidateWarn>{errors?.article?.usage?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnZoneUse
