import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputRemoveAppendWidth,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptionsForObject } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { options } from '../../options'
import SelectBox from '../../elements/SelectBox'
import styled from 'styled-components'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 계약기간
 */
const NoteAdColumnCotractDateInfo = ({ desc = false }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const list = options['계약기간 구분']
  const rentPeriodType = list[watch('trade.rentPeriodType')] || ''
  const tradeType = watch('trade.type')

  //단기임대 일 때만 보여줌
  if (tradeType != 'B3') return
  return (
    <ColumnSet>
      <ColumnTitle>계약기간 구분</ColumnTitle>
      <ColumnVal direction="column" gap={10}>
        <InputWrapper>
          <div>
            <InputText
              width={InputRemoveAppendWidth}
              type="text"
              {...register('trade.rentPeriod')}
              onBlur={(e) => {
                onUpdate('trade.rentPeriod', e.target.value)
              }}
              fixAppendWidth
              append={'개월'}
            />
          </div>
          <SelectBox
            minWidth={InputWidth}
            width={InputWidth}
            value={{
              label: rentPeriodType || '선택',
              value: rentPeriodType,
            }}
            isSearchable
            options={getSimpleSelectboxOptionsForObject(list)}
            placeholder="선택"
            className="basic-single"
            classNamePrefix="select"
            onChange={(e) => {
              onUpdate('trade.rentPeriodType', e.value)
            }}
          />
          <ValidateWarn>
            <div>{errors?.trade?.rentPeriodType?.message}</div>
            <div>{errors?.trade?.rentPeriod?.message}</div>
          </ValidateWarn>
        </InputWrapper>
        {desc && (
          <FormDesc>
            <li>24개월 이상은 월세로 등록해주세요.</li>
          </FormDesc>
        )}
      </ColumnVal>
    </ColumnSet>
  )
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`

export default NoteAdColumnCotractDateInfo
