import NoteAdFormFloatingButton from './NoteAdFormFloatingButton'
import NoteAdFormType from './NoteAdFormType'

/**
 * 광고 폼
 */
const NoteAdForm = () => {
  return (
    <>
      <NoteAdFormType />
      <NoteAdFormFloatingButton />
    </>
  )
}

export default NoteAdForm
