import theme from 'lib/styles/theme'
import styled from 'styled-components'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Swipe from 'react-easy-swipe'
import { AutoSizer } from 'react-virtualized'
import NoteListMobileItem from './NoteListMobileItem'
import { FixedSizeList as List } from 'react-window'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import NoteListMobileContactModal from './NoteListMobileContactModal'
import Scrollbars from 'react-custom-scrollbars-2'
import { ScrollStyle } from '../style/NoteStyle'
import useIsMobile from 'lib/hooks/useIsMobile'
import MapContext from 'contexts/map'
import useAxios from 'lib/hooks/useAxios'
import { getPolygon } from 'lib/api/note/noteApi'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { message } from 'antd'

/**
 * 매물목록 모바일 버전
 */
const NoteListMobile = ({
  mode = 'basic',
  data,
  type,
  hasFolder,
  onSwipeStart,
  onSwipeMove,
  onSwipeEnd,
}) => {
  const sliceData = mode == 'card' ? data : data.slice(1)
  const isMobile = useIsMobile()
  const history = useHistory()
  const listRef = useRef({})

  const {
    state: { map, viewState },
  } = useContext(MapContext)

  const [visibleCallModal, setVisibleCallModal] = useState(false)
  const [contactArr, setContactArr] = useState([])
  const [polygons, setPolygons] = useState([])
  const [polygon, setPolygon] = useState(null)
  const [onRequestPolygon, , dataPolygon] = useAxios(getPolygon)

  /**
   * 전화걸기
   */
  const onClickPhone = useCallback((e, contacts) => {
    e.stopPropagation()
    setContactArr(contacts)
    setVisibleCallModal(true)
  }, [])

  /**
   * 전화 모달 닫기
   */
  const onCloseCallModal = useCallback(() => {
    setVisibleCallModal(false)
  }, [])

  /**
   * 3뎁스 탭으로 이동
   */
  const moveDetailTab = useCallback(
    ({ e, channel_srl, address_srl, note_srl, tab, folder_srl }) => {
      e.stopPropagation()

      if (mode == 'basic') {
        history.push({
          pathname: `/note/v2/notes/${channel_srl}/${address_srl}/${note_srl}`,
          state: {
            activeTab: tab,
          },
        })
      } else if (mode == 'card') {
        history.push({
          pathname: `/note/${channel_srl}/${folder_srl}/${note_srl}`,
          state: {
            activeTab: tab,
          },
        })
      }
    },
    [mode],
  )

  /**
   * 3뎁스 사진 탭으로 이동
   */
  const onClickPicture = useCallback(
    (e, channel_srl, address_srl, note_srl, folder_srl) => {
      moveDetailTab({
        e,
        channel_srl,
        address_srl,
        note_srl,
        folder_srl,
        tab: 2,
      })
    },
    [],
  )

  /**
   * 클릭시 3뎁스로 이동
   * @param {*} channel_srl
   * @param {*} address_srl
   * @param {*} note_srl
   */
  const onClick = useCallback(
    (e, channel_srl, address_srl, note_srl, folder_srl, lat, lng) => {
      e.stopPropagation()
      if (mode == 'basic') {
        history.push(
          `/note/v2/notes/${channel_srl}/${address_srl}/${note_srl}`,
        )
      } else if (mode == 'card') {
        //폴리곤 호출
        onRequestPolygon({ address_srl: address_srl })

        // 중심점 이동
        if (lat && lng) {
          const latlng = new kakao.maps.LatLng(lat, lng)
          map.setCenter(latlng)

          if (!polygon) {
            // 카드 모드에서 매물 클릭시 3뎁스 안 열리는 버그 수정
            history.push(
              `/note/${channel_srl}/${folder_srl}/${note_srl}`,
            )
          }
          polygon?.setMap(null)
        } else {
          message.info('지도에서 위치를 찾을 수 없는 매물입니다.')
        }

        history.push(`/note/${channel_srl}/${folder_srl}/${note_srl}`)
      }
    },
    [map],
  )

  /**
   * 폴리곤 호출
   */
  useEffect(() => {
    if (!dataPolygon || !dataPolygon?.result) return

    handleSetPolygon(dataPolygon.result)
  }, [dataPolygon])

  /**
   * 주소 필지 폴리곤 세팅
   * @param {*} e
   * @param {*} item
   */
  const handleSetPolygon = (item) => {
    // 폴리곤 세팅
    const shape = item.shape?.coordinates || []
    const polygonPath = shape?.map((part) => {
      return part.map((point) => {
        return new kakao.maps.LatLng(point[0], point[1])
      })
    })
    // 지도에 표시할 다각형을 생성합니다
    const resultPolygon = new kakao.maps.Polygon({
      path: polygonPath, // 그려질 다각형의 좌표 배열입니다
      strokeWeight: 1, // 선의 두께입니다
      strokeColor: theme.colors.buildingPolygonColor, // 선의 색깔입니다
      strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      strokeStyle: 'solid', // 선의 스타일입니다
      fillColor: theme.colors.buildingPolygonColor, // 채우기 색깔입니다
      fillOpacity: 0.5, // 채우기 불투명도 입니다
      zIndex: 1,
    })

    setPolygon(resultPolygon)
  }

  useEffect(() => {
    if (!polygon) return
    polygon.setMap(map)

    return () => {
      if (!polygon) return

      polygon.setMap(null)
    }
  }, [polygon])

  useEffect(() => {
    if (mode != 'card' || !viewState) return

    if (!polygon) return

    polygon.setMap(null)
  }, [viewState])

  /**
   * 리스트 아이템
   * @param {*} param0
   * @returns
   */
  const Row = ({ index, style }) => {
    const item = sliceData[index]

    return (
      <NoteListMobileItem
        type="note"
        mode={mode}
        item={item}
        key={index}
        style={style}
        onClick={onClick}
        onClickPicture={onClickPicture}
        onClickPhone={onClickPhone}
        moveDetailTab={moveDetailTab}
      />
    )
  }

  /**
   * 전체 높이 얻기
   * @returns
   */
  const getHeight = () => {
    if (isMobile) {
      if (mode == 'card') {
        if (hasFolder) {
          return 306
        } else {
          return 180
        }
      } else {
        return 112
      }
    } else {
      if (mode == 'card') {
        if (hasFolder) {
          return 250
        } else {
          return 120
        }
      } else {
        return 112
      }
    }
  }

  return (
    <>
      <Wrapper
        mode={mode}
        height={getHeight()}
        as={type == 'card' ? 'div' : Swipe}
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
      >
        <StyledAutoSizer
          className="autosizer"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {({ height, width }) => (
            <List
              itemCount={sliceData.length}
              itemSize={132}
              ref={listRef}
              itemData={sliceData}
              width={width}
              height={height}
            >
              {Row}
            </List>
          )}
        </StyledAutoSizer>
      </Wrapper>
      {visibleCallModal && (
        <NoteListMobileContactModal
          visible={visibleCallModal}
          onClose={onCloseCallModal}
          data={contactArr}
        />
      )}
    </>
  )
}

const StyledAutoSizer = styled(AutoSizer)`
  div {
    ${ScrollStyle}
  }
`

const Wrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${theme.colors.gray[100]};

  ${({ height }) =>
    height &&
    `
    height: calc(100svh - ${height}px);
  `}
`

export default React.memo(NoteListMobile)
