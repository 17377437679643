import React, { Suspense, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import ChannelSelect from '../channel/ChannelSelect'
import NoteSetting from '../NoteSetting'
import Spinner from 'components/common/Loading/Spinner'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { initPropertyParams } from 'modules/notev2'
import { onlyUnique } from 'utils'
import useAxios from 'lib/hooks/useAxios'
import { getImagesFilters } from 'lib/api/note/imageApi'
import useChannelInfo from 'containers/notev2/hooks/useChannelInfo'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { FilterScrollWrapper, MobileCover } from '../style/NoteStyle'
import useIsMobile from 'lib/hooks/useIsMobile'
import MobileChannelHeader from '../channel/MobileChannelHeader'
import { useDispatch } from 'react-redux'
import Swipe from 'react-easy-swipe'
import NoteFilterWrapper from '../style/NoteFilterWrapper'

const NoteLeftSide = ({
  propertyTypes,
  setPropertyTypes,
  onSwipeStart,
  onSwipeMove,
  onSwipeEnd,
}) => {
  const channels = useSelector((state) => state.channel.channels)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const history = useHistory()
  const [onRequestFilters, , dataFilters] = useAxios(getImagesFilters)
  const { _getChannel, status, channelResult } = useChannelInfo()
  const [propertyFilters, setPropertyFilters] = useState(null)
  const isMobile = useIsMobile()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!channelInfo) return
    onRequestFilters({ channel_srl: channelInfo.channel_srl })
  }, [channelInfo])

  useEffect(() => {
    if (!dataFilters || !dataFilters.result) return

    setPropertyFilters(dataFilters.result?.property || {})
  }, [dataFilters])

  const onChannelChange = (channelSrl, folderSrl) => {
    const targetChannel = channels.find(
      ({ channel_srl: channels_srl }) => {
        return channels_srl == channelSrl
      },
    )
    if (!targetChannel) {
      message.error('잘못된 요청입니다.')
      return
    }

    _getChannel({ channelSrl })

    localStorage.setItem('recentFolderSrl', folderSrl) //카드모드 호환 위해 채널 바꿀 때 기본 폴더로 설정
  }

  useEffect(() => {
    if (!channelResult) {
      return
    }

    if (!channelResult?.channel_srl) {
      message.error('잘못된 요청입니다.')
      return
    }

    localStorage.setItem('recentChannelSrl', channelResult.channel_srl)
    history.push(`/note/v2/pictures/${channelResult.channel_srl}`)
  }, [channelResult])

  const NoteFilter = useMemo(
    () => React.lazy(() => import('../NoteFilter')),
    [],
  )

  /**
   * item click
   */
  const onClickItem = ({ data, label, key }) => {
    const code = data[key].code

    //이미 있으면 삭제
    const newParams = Object.assign({}, initPropertyParams())
    if (propertyTypes[label].includes(code)) {
      newParams[label] = propertyTypes[label].filter(
        (item) => item != code,
      )
    } else {
      newParams[label] = [...newParams[label], ...[code]].filter(
        onlyUnique,
      )
    }
    setPropertyTypes(newParams)
  }

  /**
   * 모든 매물 클릭시 필터 초기화
   */
  const onClickAllNotes = () => {
    setPropertyTypes(initPropertyParams())
  }

  /**
   * 대분류 눌렀을 때 동작
   */
  const onClickPanel = (label, activeProperties) => {
    const target = propertyFilters[label]

    const openCollapes = Object.keys(propertyFilters).filter((item) => {
      return activeProperties.includes(item)
    })

    //닫힘 -> 열림 동작일때만 and 클릭한 항목이 선택되지 않은 상태인 경우만 필터링 조회
    if (
      !openCollapes.includes(label) &&
      propertyTypes &&
      propertyTypes[label] &&
      propertyTypes[label].length == 0
    ) {
      if (propertyFilters[label]) {
        const firstKey = Object.keys(target)[0]

        if (!propertyFilters[label]?.[firstKey]) return

        const selectAllParam = {
          [label]: [propertyFilters[label][firstKey]?.code],
        }
        const newData = Object.assign(
          {},
          initPropertyParams(),
          selectAllParam,
        )

        setPropertyTypes(newData)
      }
    }
  }

  return (
    <>
      {isMobile && <MobileChannelHeader onChange={onChannelChange} />}
      <Wrapper
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
      >
        <NoteFilterWrapper>
          <Scrollbars>
            <FilterScrollWrapper>
              {!isMobile && (
                <ChannelWrapper>
                  <ChannelSelect fixed onChange={onChannelChange} />
                </ChannelWrapper>
              )}
              <Suspense
                fallback={
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                }
              >
                <NoteFilter
                  allTitle="모든 사진"
                  onClickPanel={onClickPanel}
                  onClickItem={onClickItem}
                  resetItem={onClickAllNotes}
                  propertyTarget={propertyTypes}
                  propertyFilters={propertyFilters}
                />
              </Suspense>
              <NoteSetting />
            </FilterScrollWrapper>
          </Scrollbars>
        </NoteFilterWrapper>
        <MobileCover className="m-cover" />
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Swipe)`
  width: 100%;
  height: 100%;
`
const ChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
  text-align: center;
`

export default NoteLeftSide
