import theme from 'lib/styles/theme'
import styled from 'styled-components'

const StyledListItemIcon = styled.button`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 1.5rem;
  height: 1.5rem;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  color: ${theme.colors.gray[600]};
  font-size: 1rem;

  background-color: white;
  border: none;
  outline: none;
  appearance: none;
  border-radius: ${theme.borderRadius[1]};

  transition: 0.2s ease;

  &:hover {
    background-color: ${theme.colors.gray[100]};
  }
`

const ListItemIcon = ({ children, onClick }) => {
  return (
    <StyledListItemIcon onClick={onClick}>
      {children}
    </StyledListItemIcon>
  )
}

export default ListItemIcon
