import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import { useEffect } from 'react'

const NoteAdColumnVerificationTypeDescT = ({ type }) => {
  return (
    <>
      <Info
        className="fas fa-question-circle"
        data-tooltip-id="verification-tooltip-t"
      />
      <Tooltip
        id="verification-tooltip-t"
        border={`1px solid ${theme.colors.gray[300]}`}
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.03)`,
          zIndex: 9999,
          padding: '18px 16px',
          width: '340px',
        }}
      >
        <>
          <Title>전화확인</Title>
          <Desc>
            <Item>
              중개사가 매물 정보를 등록하면 부동산매물검증센터에서
              매도/임대 의뢰인에게 전화 및 등기부 등본 조회를 통해
              매도/임대 의뢰 여부, 가격, 주소, 소유자 등을 확인하는
              방식입니다.
            </Item>
          </Desc>
        </>
      </Tooltip>
    </>
  )
}

const Info = styled.i`
  margin-left: 2px;
  color: ${theme.colors.gray[400]};
  z-index: 999;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};
`

const Desc = styled.div`
  margin-top: 10px;

  ${({ nomargin }) => nomargin && `margin: 0;`};
`

const Item = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};

  &::before {
    content: '\f00c';
    ${({ multiple }) => multiple && `content: '\f00c'`};
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
    color: ${theme.colors.blue[400]};
  }
`
export default NoteAdColumnVerificationTypeDescT
