import { message } from 'antd'
import NoteAdSubmitButton from 'components/jmapnotev2/NoteAd/NoteAdSubmitButton'
import { getAd } from 'lib/api/notev2/adList'
import useAdOptions from 'lib/hooks/notev2/useAdOptions'
import useAdRegister from 'lib/hooks/notev2/useAdRegister'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import useAxios from 'lib/hooks/useAxios'
import { merge } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 광고 매물 등록 로직
 */
const NoteAdLinkContainer = ({ children }) => {
  const methods = useForm()
  const {
    getValues,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    setFocus,
  } = methods

  const { isBoss } = useIsAllInOne()
  const location = useLocation()
  const history = useHistory()

  const propertyType = watch('article.category1')

  const { getAdOptions } = useAdOptions()

  const planInfo = useSelector((state) => state.auth.planInfo)

  const [onRequestAd, , dataAd] = useAxios(getAd)

  const matchUrl = useRouteMatch({
    path: ['/note/v2/ad/link/:adSrl'],
  })

  const {
    preregister,
    approvalReq,
    validationError,
    paymentModal,
    loading,
    isProcessing,
  } = useAdRegister(matchUrl?.params?.adSrl)

  useEffect(() => {
    //url 직접 접근 막음
    if (
      !matchUrl.isExact ||
      !location?.state ||
      location?.state?.ad_srl != matchUrl?.params?.adSrl
    ) {
      message.error('잘못된 접근입니다. 광고관리 페이지로 이동합니다.')
      history.replace('/note/v2/ad')
      return
    }

    getAdOptions() //옵션 호출

    //광고 등록화면에서 새로고침 시 데이터 손실 방지
    onRequestAd({
      ad_srl: matchUrl?.params?.adSrl,
      plan_srl: planInfo.allinone_plan_srl,
    })
  }, [])

  /**
   * ad_srl로 광고 데이터 존재 유무 조회
   * 있으면 location state의 데이터(convert api의 결과)가 누락되지 않게 merge하여 사용
   */
  useEffect(() => {
    if (!dataAd) return

    const newData = dataAd?.result //광고 매물 개별 조회 api 결과
    const locationStateData = location.state //convert api 결과
    let bindData

    if (newData) {
      bindData = merge(locationStateData, newData)
    } else {
      bindData = locationStateData
    }

    reset({
      ...bindData,
    })
  }, [dataAd])

  /**
   * 광고 등록 하기
   * @param {*} pData
   */
  const onSubmit = (pData) => {
    try {
      if (!pData?.verification?.oagree) {
        setFocus('verification.oagree')
        message.error('개인정보 수집 및 이용 동의를 확인해주세요.')
        return
      }

      if (
        pData?.verification?.type == 'S' &&
        pData?.verification?.agree_confirm != 'Y'
      ) {
        setFocus('verification.agree_confirm')
        message.error(
          '현장확인의 경우 확인사항과 이용 약관을 확인해주세요.',
        )
        return
      }

      if (isBoss) {
        preregister({ recycle: false })
      } else {
        //대표 아닌 사람
        approvalReq()
      }
    } catch (e) {
      message.error(
        '광고 등록 중 오류가 발생했습니다. 관리자에게 문의해 주세요.',
      )
      console.log('에러났음', e)
    }
  }

  return children({
    isProcessing,
    paymentModal,
    methods,
    validationError,
    loading,
    onSubmit: (e) => {
      clearErrors()
      handleSubmit(onSubmit)(e)
    },
    SubmitButton: propertyType && <NoteAdSubmitButton isInsert />,
  })
}

export default React.memo(NoteAdLinkContainer)
