import { useSelector } from 'react-redux'
import NoteApplyContainer from 'containers/note/note/NoteApplyContainer'

const HeaderButtonNoteApply = ({ land }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { note } = useSelector((state) => state.summary.summaryInfo)

  if (loginInfo?.member_type !== 'realestate') return null

  return <NoteApplyContainer note={note} land={land} />
}

export default HeaderButtonNoteApply
