import { useCallback, useState, useEffect, useContext } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Skeleton, Empty, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { getSizeReview } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'
import SEOHead from 'components/common/SEOHead'
import SummarySizeReview3DModel from 'components/summary/SummarySizeReview/SummarySizeReview3DModel'
import SummarySizeReviewRevenue from 'components/summary/SummarySizeReview/SummarySizeReviewRevenue'
import SummarySizeReviewFooter from 'components/summary/SummarySizeReview/SummarySizeReviewFooter'
import SummarySizeReviewArea from 'components/summary/SummarySizeReview/SummarySizeReviewArea'
import SummarySizeReviewCost from 'components/summary/SummarySizeReview/SummarySizeReviewCost'
import SummarySizeReviewRent from 'components/summary/SummarySizeReview/SummarySizeReviewRent'
import { CustomUl } from 'components/summary/SummarySizeReview/SummarySizeReviewStyle'
import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import { Modal } from 'components/design'
import { Confirm } from 'components/design'
import { setIsCallSizeReview, setIsCombination } from 'modules/summary'
import SummarySizeReviewNoti from 'components/summary/SummarySizeReview/SummarySizeReviewNoti'

const SkeletonWrap = styled.div`
  padding: 1rem;
`

const SummarySizeReview = () => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const [sizeReviewData, setSizeReviewData] = useState(null)
  const [isInfoHide, setIsInfoHide] = useState(true)

  const [onRequest, loading, data] = useAxios(getSizeReview)

  const { state, actions } = useContext(MapContext)

  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  const isCombination = useSelector(
    (state) => state.summary.isCombination,
  )

  const source = axios.CancelToken.source()

  useEffect(() => {
    //   if (filterData.searchValue) dispatch(setSearchValue(null))

    getSizeReviewData()

    //unmount시 axios 요청 취소
    return () => {
      source.cancel()
    }
  }, [])

  useUpdateEffect(() => {
    getSizeReviewData()
  }, [params?.filterId])

  useEffect(() => {
    if (!data) return

    if (data?.result?.status.code != '00') {
      isCombination
        ? ConfirmNotService(
            data?.result?.status.code,
            data?.result?.status.description,
          )
        : ConfirmNotService()

      setSizeReviewData('')

      return
    }
    setSizeReviewData(data)
  }, [data])

  const getSizeReviewData = async () => {
    dispatch(setIsCallSizeReview(false))

    const addressId = isCombination
      ? getCombinationAddrId()
      : currentAddressId

    const payload = {
      address_ids: addressId,
      building_types: getBuildingTypesName(params?.filterId || 0),
      is_production: true,
    }

    await onRequest(payload, source.token).finally(() => {
      dispatch(setIsCallSizeReview(true))
    })
  }

  const getCombinationAddrId = () => {
    const addrIdArr = state.combinationPolygons.map(({ id }) => id)
    return addrIdArr.length > 0
      ? currentAddressId + ',' + addrIdArr.toString()
      : currentAddressId
  }

  const getBuildingTypesName = (buildingType = 0) => {
    const result = {
      0: '다가구주택',
      1: '다가구주택,근린생활시설',
      2: '근린생활시설',
    }

    return result[buildingType]
  }

  const onClickClose = useCallback(() => {
    source.cancel()
    history.replace(`/${params?.id}`)
    dispatch(setIsCallSizeReview(true))
    dispatch(setIsCombination(false))
    actions.setCombinationPolygons([])
    actions.setCombinationStandardPolygons([])
  }, [])

  const ConfirmNotService = (code, msg) => {
    const errMsg =
      '선택하신 토지가 수익성 분석 조건에 부합하지 않습니다. 다른 토지를 선택 해 주세요. 이용에 불편을 드려 죄송합니다.'

    const noCombineMsg =
      msg && msg == '하나의 필지로 합쳐지지 않습니다.'
        ? '하나의 필지로 합쳐지지 않습니다.'
        : errMsg

    Confirm.info({
      title: '수익성 분석에 실패하였습니다',
      content: noCombineMsg
        ? code == '99'
          ? errMsg
          : noCombineMsg
        : errMsg,
      onOk: () => {
        onClickClose()
        //노후주택 대수선에서 왔을 때 실패하면 뒤로가기
        if (location.state?.fromNohu) {
          history.goBack()
        }
      },
      onCancel: () => {
        onClickClose()
        //노후주택 대수선에서 왔을 때 실패하면 뒤로가기
        if (location.state?.fromNohu) {
          history.goBack()
        }
      },
      cancelHide: true,
    })
  }

  return (
    <>
      <SEOHead />
      <ExternalLayer title="수익성 분석" onClose={onClickClose}>
        {loading ? (
          <SkeletonWrap>
            <Empty
              image={
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        color: theme.colors.gray[500],
                        fontSize: '2rem',
                        marginTop: '2.5rem',
                      }}
                      spin
                    />
                  }
                />
              }
              description={
                <span>
                  선택하신 토지의 수익성을 분석 중입니다. <br />
                  최대 1분이 소요됩니다.
                </span>
              }
            />
            <br />
            <Skeleton active />
          </SkeletonWrap>
        ) : (
          <>
            {sizeReviewData &&
              sizeReviewData?.result?.buildings.length > 0 && (
                <>
                  <SummarySizeReviewNoti />
                  <SummarySizeReview3DModel data={sizeReviewData} />
                  <SummarySizeReviewArea data={sizeReviewData} />
                  <SummarySizeReviewCost
                    data={sizeReviewData}
                    setIsInfoHide={setIsInfoHide}
                  />
                  <SummarySizeReviewRent
                    data={sizeReviewData}
                    setIsInfoHide={setIsInfoHide}
                  />
                  <SummarySizeReviewRevenue
                    data={sizeReviewData}
                    setIsInfoHide={setIsInfoHide}
                  />
                  <SummarySizeReviewFooter type="size" />
                  <Modal
                    title="수익성 분석"
                    visible={!isInfoHide}
                    onOk={() => {
                      setIsInfoHide(true)
                    }}
                    onCancel={() => {
                      setIsInfoHide(true)
                    }}
                    okText="확인"
                    cancelText="취소"
                    cancelButtonProps={{ style: { display: 'none' } }}
                    footer={null}
                  >
                    <CustomUl>
                      <li>
                        부동산 매입 비용은 JOOTEK 추정가를
                        참고하였습니다.
                      </li>
                      <li>
                        임대료는 실거래가 데이터 및 자체 수집 데이터를
                        분석하여 계산하였습니다.
                      </li>
                      <li>
                        해당 수치들은 빅데이터 분석을 통한 통계적 추정
                        값이며 실제 거래금액과는 차이가 있을 수
                        있습니다.
                      </li>
                      <li>
                        참고용으로 제공되는 정보이므로 이를 이용함으로
                        발생하는 어떤 손실에 대해서도 (주)주택은 책임이
                        없습니다.
                      </li>
                    </CustomUl>
                  </Modal>
                </>
              )}
          </>
        )}
      </ExternalLayer>
    </>
  )
}

export default SummarySizeReview
