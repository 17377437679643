//동, 호가 존재하는 경우
export const isDonghoAreaUse = [
  '아파트',
  '오피스텔',
  '아파트 분양권',
  '오피스텔 분양권',
  '원룸',
  '1.5룸',
  '투룸',
  '쓰리룸+',
  '다세대주택',
  '사무실',
  '상가',
  '지식산업센터',
]

//연면적 사용하는 경우
export const isFloorAreaUse = [
  '상가건물',
  '공장',
  '창고',
  '다가구주택',
  '단독주택',
  '고시원',
]

export const isLandAreaUse = ['토지', '기타']

export const newContactObj = {
  type: '',
  numbers: [{ label: '', number: '' }],
  note_contact_srl: '',
  contact_srl: '',
  alias: '',
  isNewContact: true, //신규로 추가된 연락처는 자동으로 포커스가 가도록 하기 위한 flag
}

export const eachSmallRowHeight = 20

/**
 * 그리드 맨 앞 색상 바 group
 */
export const colorGroup = {
  cyan: ['아파트', '아파트 분양권'],
  purple: ['오피스텔', '오피스텔 분양권'],
  pink: [
    '상가건물',
    '상가',
    '사무실',
    '상가점포',
    '지식산업센터',
    '대형사무실',
    '중소형사무실',
    '단지내상가',
    '일반상가',
    '복합상가',
  ],
  green: [
    '원룸',
    '1.5룸',
    '투룸',
    '쓰리룸+',
    '일반원룸',
    '원룸형 아파트',
    '도시형생활주택',
  ],
  blue: [
    '다세대주택',
    '다가구주택',
    '상가주택',
    '빌라',
    '연립',
    '빌라/연립',
    '기타',
  ],
  red: [
    '단독주택',
    '주택',
    '단독',
    '다가구',
    '전원주택',
    '상가주택',
    '한옥주택',
  ], //주택: 계약서에만 사용
  orange: ['토지', '도로', '하천', '기타'],
  gray: [
    '공장/창고',
    '공장',
    '창고',
    '기타',
    '고시원',
    '건물',
    '직접입력',
    '숙박/콘도',
    '여관/모텔',
    '콘도',
    '펜션',
    '빌딩',
    '상업시설',
    '빌딩/건물',
    '레저/스포츠/위락',
    '특수시설',
    '',
    null,
  ], // 건물, 직접입력: 계약서에서만 사용
}

export const noteSheetColor = {
  cyan: {
    line: '#40A4FF',
    fill: '#DFF0FF',
  },
  purple: {
    line: '#7C42F8',
    fill: '#EAE0FF',
  },
  pink: {
    line: '#E84CB9',
    fill: '#FFF2FD',
  },
  red: {
    line: '#EE553D',
    fill: '#FFE9E5',
  },
  orange: {
    line: '#E0A40C',
    fill: '#FFF9ED',
  },
  blue: {
    line: '#5C76FF',
    fill: '#EDF2FE',
  },
  green: {
    line: '#27a074',
    fill: '#CEF6E3',
  },
  gray: {
    line: '#4E5968',
    fill: '#F2F4F6',
  },
}

export const contractDefaultValue = {
  contract_srl: 'new',
  channel_srl: null,
  note_srl: null,
  file_srl: null,
  property_type: '주택',
  contract_type: '매매',
  address: '',
  total_price: null,
  contract_date: '',
  balance_date: '',
  conditions: '',
  payment_schedule: {
    contract_price: null,
    loan: null,
    loan_condition: '',
    deposit: null,
    hwansan_deposit: null,
    jungdogeum: [],
    jangeum: [],
    rent_price: null,
    rent_maewol: '',
    rent_type: '',
    rent_vat: '',
    receiver: '', // 영수자
    // 분양권 관련
    presale_base_price: null,
    presale_paid: null,
    presale_to_pay: null,
    presale_premium: null,
    presale_jeongsan_price: null,
  },
  flag: 'Y',
  extra_vars: {
    land_type: '',
    daejigwon_type: '',
    daejigwon_ratio: '',
    land_area: null,
    struct_type: '',
    building_property_type: '',
    building_area: null,
    raws: [],
    realestate_reward: null,
    vat: '',
    not_print: 'N',
    seller_addr: '',
    seller_number: '',
    seller_name: '',
    seller_agent_addr: '',
    seller_agent_name: '',
    buyer_addr: '',
    buyer_number: '',
    buyer_name: '',
    buyer_agent_addr: '',
    buyer_agent_name: '',
    realestate_addr: '',
    realestate_id: '',
    company_name: '',
    realestate_number: '',
    realestate_name: '',
    // 분양권 관련
    presale_area: null,
    exclusive_area: null,
    daejigwon_area: null,
    exclusive_type: '',
  },
  list_order: null,
  land_type: '',
  daejigwon_type: '',
  daejigwon_ratio: '',
  land_area: null,
  struct_type: '',
  building_property_type: '',
  building_area: null,
  raws: '',
  realestate_reward: null,
  vat: '',
  not_print: 'N',
  seller_addr: '',
  seller_number: '',
  seller_name: '',
  seller_agent_addr: '',
  seller_agent_name: '',
  buyer_addr: '',
  buyer_number: '',
  buyer_name: '',
  buyer_agent_addr: '',
  buyer_agent_name: '',
  realestate_addr: '',
  realestate_id: '',
  company_name: '',
  realestate_number: '',
  realestate_name: '',
  d_descript_type: '주거용 건축물',
  d_property_type: [], // 중개대상물 유형
  d_evidence: [], // 근거자료
  d_address: '', // 소재지
  d_land_type: '', // 토지 공부상 지목
  d_land_type_actual: '', // 토지 실제 이용상태
  d_land_area: '',
  d_exclusive_area: '', // 전용면적(제곱미터)
  d_land_share: '', // 대지지분(제곱미터)
  d_ccd: '', // 준공일 : CCD (Construction Complete Date : 공사완료일)
  d_building_type: '', // 건축물대장상 용도
  d_building_type_actual: '', // 건축물대장상 실제 용도
  d_struct_type: '',
  d_direction: '',
  d_direction_set: '',
  d_is_earthquake_res: '', // 내진설겨 적용 여부(Y/N)
  d_earthquake_res: '', // 내진능력
  d_is_violation_building: '', // 위반건축물여부(Y/N)
  d_violation_building: '', // 위반여부
  d_land_right_owner: '', // 토지 권리 소유권
  d_building_right_owner: '', // 건물 권리 소유권
  d_land_right_other: '', // 토지 소유권 외
  d_building_right_other: '', // 건물 소유권 외
  d_zone_use: '', // 용도지역
  d_use_disrict: '', // 용도지구
  d_use_area: '', // 용도구역
  d_coverage_ratio: '', // 건폐율 상한(%)
  d_floor_area_ratio: '', // 용적률 상한(%)
  d_plan_facility: '', // 도시(군)계획시설
  d_permission_type: '', // 허가신고구역 여부
  d_speculative_type: '', // 투기지역여부
  d_planning_area: '', // 지구단위계획
  d_transaction_regulation: '', // 그 밖의 이용제한 및 거래규제사항
  d_paved_road: '', // 도로와의 관계(포장/비호장)
  d_access: '', // 접근성(용이함/불편함)
  d_parking: '', // 주차장
  d_parking_other: '', //주차장 그밖의 주차장 입력칸
  d_bus_name: '', // 버스 정류장 이름
  d_bus_way: '', // 버스(도보/차량)
  d_bus_time: '',
  d_subway_name: '', // 지하철 정류장 이름
  d_subway_way: '', // 지하철(도보/차량)
  d_subway_time: '',
  d_elementary_name: '', // 초등학교 이름
  d_elementary_way: '', // 초등학교(도보/차량)
  d_elementary_time: '',
  d_middle_name: '', // 중학교 이름
  d_middle_way: '', // 중학교(도보/차량)
  d_middle_time: '',
  d_high_name: '', // 고학교 이름
  d_high_way: '', // 고학교(도보/차량)
  d_high_time: '',
  d_store_name: '', // 백화점 이름
  d_store_way: '', // 백화점(도보/차량)
  d_store_time: '',
  d_medical_name: '', // 의료시설 이름
  d_medical_way: '', // 의료시설(도보/차량)
  d_medical_time: '',
  d_is_security: '', // 경비실
  d_management: '', // 관리주체
  d_is_biseonhosiseol: '', // 비선호시설
  d_biseonhosiseol: '', // 비선호시설 종류 및 위치
  d_estimated_amount: '', // 거래예정금액
  d_unit_price: '', // 개별공시지가
  d_building_price: '', // 건물공시가격
  d_acquisition_tax: '', // 취득세(%)
  d_countryside_tax: '', // 농어촌특별세(%)
  d_local_education_tax: '', // 지방교육세(%)
  d_is_sudopason: '', // 수도 파손여부
  d_sudopasonspot: '', // 수도 파손위치
  d_sudoyongsulyang: '', // 수도 용수량
  d_sudoyongsulyang_spot: '', // 부족한 용수량 위치
  d_is_electricity_supply: '', // 전기공급상태
  d_electricity_replaced: '', // 전기 교체할 부분
  d_gas_system: '', // 가스공급방식(도시가스,기타)
  d_gas_etc: '', // 기타 가스공급방식
  d_has_firedetector: '', // 단독경보형감지기
  d_firedetector_cnt: '', // 단독경보형감지기 개수
  d_heating_system: '', // 난방방식(중앙,개별)
  d_heating_jagdong: '', // 난방 시설작동
  d_heating_type: '', // 난방종류(도시가스,기름,프로판가스,연탄,기타)
  d_heating_type_etc: '', // 기타 난방종류
  d_lift: '', // 승강기(양호,불량,없음)
  d_baesu: '', // 배수여부
  d_baesu_suseon: '', // 배수 수선 필요한 곳
  d_etc_siseolmul: '', //배수 그 밖의 시설물
  d_byeogmyeon_gyunyeol: '', // 벽면균열여부
  d_byeogmyeon_gyunyeol_spot: '', // 벽면균열 위치
  d_byeogmyeon_nusu: '', // 벽면 누수여부
  d_byeogmyeon_nusu_spot: '', // 벽면 누수 위치
  d_dobae: '', // 도배 여부
  d_iljolyang: '', // 일조량(풍부함,보통임,불충분)
  d_iljolyang_reason: '', // 일조량 불충분한 이유
  d_soeum: '',
  d_jindong: '',
  d_junggaebosu: '',
  d_silbi: '',
  d_total_price: '',
  d_maedoin_address: '',
  d_maedoin_name: '',
  d_maedoin_birth: '',
  d_maedoin_number: '',
  d_maesuin_address: '',
  d_maesuin_name: '',
  d_maesuin_birth: '',
  d_maesuin_number: '',
  d_junggae_deunglognum1: '',
  d_junggae_name1: '',
  d_junggae_company_name1: '',
  d_junggae_sosog1: '',
  d_junggae_address1: '',
  d_junggae_number1: '',
  d_junggae_deunglognum2: '',
  d_junggae_name2: '',
  d_junggae_company_name2: '',
  d_junggae_sosog2: '',
  d_junggae_address2: '',
  d_junggae_number2: '',
  //조항
  main_articles: [], //조항 기본값
  assignment_date: '',
  duration_day: '',
  duration_month: '',
  d_heating_yeonhan: '', // 개별공급인 경우 사용연한
  d_heating_unknown: '', // 시설작동 사용연한 확인불가
  d_minganrent_deunglog: '', // 민간임대 등록
  d_minganrent_other: '', // 민간임대 등록 그 밖의 유형
  d_minganrent_period: '', // 민간임대 의무기간
  d_minganrent_date: '', // 민간임대 개시일
  d_minganrent_mideunglog: '', // 민간임대 미등록(Y/N)
  d_renewal_right: '', // 계약갱신요구권행사여부
  d_is_submit_dagagu: '', // 다가구주택 확인서류 제출여부
  d_badagmyeon: '', // 바닥면 여부
  d_badagmyeon_spot: '', // 바닥면 수리 필요 위치
  d_junggaebosu_vat_ratio1: '', // 중개보수 부가가치세: 공인중개사
  d_junggaebosu_vat_ratio2: '', // 중개보수 부가가치세: 공동중개사
  d_junggaebosu_payment: '', // 중개보수 지급시기
  included_firstday: '', //초일불산입 여부

  d_road_touch_width: null, //도로에 접함 수치 가로
  d_road_touch_height: null, //도로에 접함 수치 세로
  d_badagmyeon: '', // 바닥면 여부
  d_badagmyeon_spot: '', // 바닥면 수리 필요 위치
  d_other_rights: '', //실제 권리관계 또는 공시되지 않은 물건의 권리사항 textarea

  d_junggaebosu_cal: '', // 산출내역 중개보수
  d_silbi_cal: '', //산출내역 실비
  gyobu_date: '', //교부일자
  d_evidence_other: '', //근거자료 그밖의 자료
  d_request_condition: '',
  d_junggaebosu_cal_ratio: 0,
}

export const descript_type = [
  '주거용 건축물',
  '비주거용 건축물',
  '토지',
]
