import { Card, CardBody } from 'components/design'
import Spinner from './Spinner'

const Loading = ({ type = 'skeleton' }) => {
  return (
    <Card noMargin>
      <CardBody>
        <Spinner />
      </CardBody>
    </Card>
  )
}

export default Loading
