import { useContext, useEffect, useState } from 'react'
import MapContext from 'contexts/map'
import { getLayerBuildingAge } from 'lib/api/layerApi'
import useAxios from 'lib/hooks/useAxios'
import { notification } from 'antd'

const MapLayerBuildingAge = () => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { layerState, filterState, viewState, map } = state
  const [onRequest, , data, , ,] = useAxios(getLayerBuildingAge)
  // 폴리곤 배열 선언
  const [polygons, setPolygons] = useState([])

  const legends = [
    '#5CA7FF',
    '#5ca8ffae',
    '#5ca8ff49',
    '#ff696957',
    '#ff6969b1',
    '#FF6969',
  ]
  const legendPips = ['0', '20', '40~']

  const nowYear = new Date().getFullYear()
  let min = nowYear
  let max = min - 40

  const openNotification = (message) => {
    notification['info']({
      message: '알림',
      description: message,
    })
  }

  // useEffect(() => {
  //   if (Object.keys(map).length === 0) return

  //   layerState.buildingAgeAcitve &&
  //     map.getLevel() > 2 &&
  //     openNotification('지도를 줌인해 주세요!')
  // }, [layerState.buildingAgeAcitve])

  useEffect(() => {
    if (Object.keys(viewState).length === 0) {
      return
    }
    if (viewState?.level > 2) {
      if (polygons.length > 0) {
        polygons?.map((polygon) => {
          polygon.setMap(null)
        })
      }
      return
    }

    if (layerState.buildingAgeAcitve) {
      const payload = {
        swlat: viewState.swLatlng.getLat(),
        swlng: viewState.swLatlng.getLng(),
        nelat: viewState.neLatlng.getLat(),
        nelng: viewState.neLatlng.getLng(),
        level: viewState.level,
      }
      onRequest(payload, null, false)
    } else {
      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [layerState, viewState, map])

  useEffect(() => {
    // 결과물이 없을 때는 스킵한다.
    if (!data?.result || data.result?.length === 0) {
      // 기존 폴리곤 삭제
      polygons?.map((polygon) => {
        polygon.setMap(null)
      })
      return
    }

    const addrs = data.result

    let range = (max - min) / 5
    let points = legends.map((color, i) => {
      return { year: min + range * i, color: color }
    })
    // console.log('points', points)

    // 폴리곤 배열
    let tempPolygons = []

    Object.keys(addrs).map((key) => {
      let addr = addrs[key]
      // 노후도별 색깔 표시
      const year = addr.year

      let color = points
        .map((element) => {
          if (year >= element.year) {
            return element.color
          } else {
            return null
          }
        })
        .filter((item) => item !== null)[0]

      const shpaePoints = addr.shape.coordinates

      const polygonPath = shpaePoints.map((part) => {
        return part.map((point) => {
          return new kakao.maps.LatLng(point[0], point[1])
        })
      })

      // 지도에 표시할 다각형을 생성합니다
      const polygon = new kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 1, // 선의 두께입니다
        strokeColor: color, // 선의 색깔입니다
        strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다
        fillColor: color, // 채우기 색깔입니다
        fillOpacity: 0.8, // 채우기 불투명도 입니다
        zIndex: 3,
      })

      // 하나씩 생성된 건물 폴리곤을 건물 폴리곤 배열에 넣기
      tempPolygons.push(polygon)
    })

    setPolygons(tempPolygons)
  }, [data])

  useEffect(() => {
    if (polygons.length === 0) return

    polygons.map((polygon) => {
      polygon.setMap(map)
    })

    return () => {
      if (polygons.length === 0) return

      // 기존 폴리곤 삭제
      polygons.map((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [polygons])

  return (
    <>
      {/* <MapLayerIcon
        type="buildingAgeAcitve"
        title={
          <>
            건물
            <br />
            노후도
          </>
        }
      >
        <i className="fas fa-house-damage"></i>
      </MapLayerIcon> */}
      {/* {layerState.buildingAgeAcitve && (
        <MapLayerLegend legends={legends} pips={legendPips} />
      )} */}
    </>
  )
  // return (
  //   <>
  //     <MapLayerListItem
  //       type="buildingAgeAcitve"
  //       title="노후도"
  //       desc="주변 건축물의 평균 건축연도를 기준으로 노후도를 표시합니다."
  //       isLast
  //     ></MapLayerListItem>

  //     {layerState.buildingAgeAcitve && (
  //       <MapLayerLegend legends={legends} pips={legendPips} />
  //     )}
  //   </>
  // )
}

export default MapLayerBuildingAge
