import { useSelector } from 'react-redux'

/**
 * 매물노트 플랜 정보
 * @return hasPlan 요금제 보유 여부(프리 요금제가 아닌지) 확인
 */
function useNoteHasPlan() {
  const planInfo = useSelector((state) => state.auth.planInfo)

  return {
    hasPlan: planInfo?.plan_srl && planInfo?.payment_name != '프리',
    isUseAllInOne: planInfo?.use_allinone == 'Y', //올인원을 사용하고 있는지 여부(포함된 직원도 속함)
  }
}

export default useNoteHasPlan
