import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Modal } from 'components/design'
import { List } from 'components/design'
import { Button } from 'components/design'

import NoteFormContactSelectItem from './NoteFormContactSelectItem'
import NoteFormContactSearch from './NoteFormContactSearch'
import ContactSearchContainer from 'containers/note/contact/ContactSearchContainer'
import NoteContactSave from 'components/jmapnote/contact/NoteContactSave'

const StyledListWrap = styled.div`
  margin: 0 -1.25rem;
`

const StyledButtonWrap = styled.div`
  padding: 0.5rem 0 1rem;
`

const NoteFormContactSelect = ({
  myContacts,
  contractSrls,
  visible,
  setVisible,
  onSubmit,
}) => {
  const [selectedContactSrls, setSelectedContactSrls] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [saveModalVisible, setSaveModalVisible] = useState(false)

  useEffect(() => {
    if (visible) {
      setSelectedContactSrls(contractSrls || [])
    }
  }, [visible])

  const handleChange = (item) => {
    setSelectedContactSrls((prev) => {
      if (!Array.isArray(prev)) return prev

      const findItem = prev.find(
        (e) =>
          e?.contact_srl.toString() === item.contact_srl.toString(),
      )

      // 배열에 추가
      if (!findItem) {
        return [
          ...prev,
          {
            ...item,
            contact_srl: item.contact_srl,
            type: '',
          },
        ]
      }

      // 배열에서 삭제
      return prev.filter((e) => {
        return e?.contact_srl.toString() !== item.contact_srl.toString()
      })
    })
  }

  const handleSubmit = () => {
    onSubmit(selectedContactSrls)
    setVisible(false)
  }

  const isChecked = (contactSrl) => {
    if (!Array.isArray(selectedContactSrls)) return

    return selectedContactSrls?.find(
      (e) => e?.contact_srl.toString() === contactSrl.toString(),
    )
  }

  return (
    <>
      <Modal
        visible={visible}
        title="연락처 선택"
        onCancel={() => setVisible(false)}
        onOk={() => handleSubmit()}
        okText="저장"
        expandedSubmitButton
        okButtonProps={{
          color: 'blue',
        }}
      >
        <NoteFormContactSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />

        <StyledButtonWrap>
          <Button
            color="blue"
            plain
            expand
            onClick={() => setSaveModalVisible(true)}
          >
            <i className="far fa-plus"></i>
            <span>연락처 추가</span>
          </Button>
        </StyledButtonWrap>

        <StyledListWrap>
          <ContactSearchContainer
            searchQuery={searchQuery}
            contacts={myContacts}
          >
            {({ searchResults }) => (
              <List noMargin>
                {searchResults.map((item, i) => (
                  <NoteFormContactSelectItem
                    key={i}
                    item={item}
                    actived={isChecked(item.contact_srl)}
                    onChange={handleChange}
                  />
                ))}
              </List>
            )}
          </ContactSearchContainer>
        </StyledListWrap>
      </Modal>

      <NoteContactSave
        visible={saveModalVisible}
        setVisible={setSaveModalVisible}
      />
    </>
  )
}

export default NoteFormContactSelect
