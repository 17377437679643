import { createContext, useState } from 'react'
import moment from 'moment'
import 'moment/locale/ko'

const ContractContext = createContext()

export const ContractProvider = ({ children }) => {
  const [contract, setContract] = useState({})

  const value = {
    state: {
      contract,
    },
    actions: {
      setContract,
    },
  }

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  )
}

export default ContractContext
