import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SEOHead from 'components/common/SEOHead'
import NotePageType from './NotePageType'
import NoteContainer from 'containers/notev2/NoteContainer'
import NotFound from 'pages/NotFound'
import NoteBottomMenu from './NoteMenu/NoteBottomMenu'
import NoteWelcome from 'components/jmapnotev2/NoteAd/common/NoteWelcome'
import NoteAdNotPermitRegiModal from 'components/jmapnotev2/NoteAd/common/NoteAdNotPermitRegiModal'
import NoteAuthModal from 'components/jmapnotev2/NoteAd/common/NoteAuth'

const NotePage = () => {
  return (
    <>
      <SEOHead />
      <NoteWelcome />
      <NoteAuthModal />
      <NoteAdNotPermitRegiModal />
      {/* 내용물 */}
      <NoteContainer>
        {({ popup }) => (
          <>
            {/* 왼쪽 사이드 메뉴 */}
            {/* <NoteSideMenu /> */}
            {/* <NoteLayout> */}
            <Switch>
              <Route
                path="/note/v2/:pageType/:channelSrl?"
                component={NotePageType}
              />
              <Route path="*" component={NotFound} />
            </Switch>
            {/* </NoteLayout> */}

            <NoteBottomMenu />
            {popup}
          </>
        )}
      </NoteContainer>
    </>
  )
}

export default React.memo(NotePage)
