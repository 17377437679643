import NoteMode from 'components/jmapnote/NoteMode'
import { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { media } from 'lib/styles/theme'
import { useDispatch } from 'react-redux'
import {
  setHeaderContent,
  setHeaderSubContent,
  setNoteListSearchParams,
  setSelectedAddressStore,
} from 'modules/notev2'
import NoteMapMode from './NoteMapMode'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteGridDescription from './NoteGridDescription'
import NoteGridFontSizeController from './NoteGridFontSizeController'
import NoteListExcelDown from './NoteListExcelDown'
import NoteListHeaderTitle from './NoteListHeaderTitle'
import NoteListHeaderTitleEdit from './NoteListHeaderTitleEdit'
import NoteListHeaderSearch from './NoteListHeaderSearch'
import propertyList from '../propertyData'
import { CommonPadding, WarnText } from '../style/NoteStyle'
import NoteListMobileAdd from './NoteListMobileAdd'

const NoteListHeader = (
  {
    search,
    setResizable,
    resetSearch,
    resetLayer,
    clearSearch,
    onSearch,
    searchFnWithParam,
    searchFn,
    bindSearch,
    flip,
  },
  ref,
) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const { topRef, listLayerRef } = ref
  const [title, setTitle] = useState('모든 매물')
  const [subtitle, setSubtitle] = useState('')
  const [selectedAddress, setSelectedAddress] = useState('')
  const notePropertyFilter = useSelector(
    (state) => state.notev2.notePropertyFilter,
  )
  const { q, property_types } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )

  useEffect(() => {
    const filterEntries = Object.entries(property_types)
    const selectedFilterEntry = filterEntries.find(
      (item) => item[1].length > 0,
    )
    if (selectedFilterEntry) {
      //타이틀
      setTitle(selectedFilterEntry[0])
      dispatch(setHeaderContent(selectedFilterEntry[0]))

      if (!selectedFilterEntry[0]) return

      //서브타이틀
      if (!notePropertyFilter[selectedFilterEntry[0]]) {
        setSubtitle('')
        return
      }

      const matchSubtitle = Object.entries(
        notePropertyFilter[selectedFilterEntry[0]],
      ).find((item) => {
        return (
          JSON.stringify(item[1].code) ==
          JSON.stringify(selectedFilterEntry[1][0])
        )
      })

      if (matchSubtitle) {
        setSubtitle(matchSubtitle[0])
        dispatch(setHeaderSubContent(matchSubtitle[0]))
        setSelectedAddress(matchSubtitle[1])
        dispatch(setSelectedAddressStore(matchSubtitle[1]))
      } else {
        //필터된 항목의 데이터를 다 삭제한 경우 모든 매물로 초기화
        const properties = propertyList.map(({ label }) => label)
        const result = {}
        properties.forEach((key) => (result[key] = []))
        dispatch(
          setNoteListSearchParams({ property_types: result, q: '' }),
        )
        searchFnWithParam({
          propertyFilter: result,
          q: '',
        }) //검색

        setTitle('모든 매물')
        dispatch(setHeaderContent('모든매물'))
        dispatch(setHeaderSubContent(''))
        setSubtitle('')
        setSelectedAddress('')
        dispatch(setSelectedAddressStore(''))
      }
    } else {
      setTitle('모든 매물')
      dispatch(setHeaderContent('모든매물'))
      dispatch(setHeaderSubContent(''))
      setSubtitle('')
    }
  }, [property_types, q, notePropertyFilter])

  return (
    <PcHeaderWrapper>
      <Wrapper ref={topRef}>
        {/* 모바일 아닐때 헤더 */}
        {!isMobile && (
          <TitleWrapper>
            <NoteListHeaderTitle
              flip={flip}
              title={title}
              subtitle={subtitle}
              resetSearch={resetSearch}
              searchFnWithParam={searchFnWithParam}
            />
            <NoteListHeaderTitleEdit
              title={title}
              subtitle={subtitle}
              selectedAddress={selectedAddress}
            />
          </TitleWrapper>
        )}
        <MobileWrapper>
          {/* 모바일 일 때 검색 */}
          {isMobile && (
            <NoteListHeaderSearch
              onSearch={onSearch}
              bindSearch={bindSearch}
              search={search}
              clearSearch={clearSearch}
            />
          )}
          {/* 타이틀 오른쪽 끝 위치 */}
          <RightWrapper>
            {/* 모바일 일 때 엑셀 다운, 폰트 조절 컴포넌트 위치 */}
            {isMobile && (
              <>
                <NoteListExcelDown />
                <NoteMapMode
                  ref={listLayerRef}
                  setResizable={setResizable}
                  resetLayer={resetLayer}
                />
                <NoteListMobileAdd mode="sheet" search={searchFn} />
              </>
            )}
            {/* 매물노트 모드, 시트펴기/접기는 자리 고정 */}

            {!isMobile && (
              <>
                <NoteMode />
                <NoteMapMode
                  ref={listLayerRef}
                  setResizable={setResizable}
                  resetLayer={resetLayer}
                />
              </>
            )}
          </RightWrapper>
        </MobileWrapper>
      </Wrapper>
      <HeaderWrap>
        {/* 모바일 아닐 때 검색, 엑셀 다운, 폰트조절 등 위치 */}
        {!isMobile && (
          <>
            <NoteListHeaderSearch
              onSearch={onSearch}
              bindSearch={bindSearch}
              search={search}
              clearSearch={clearSearch}
            />
            <HeaderBottom>
              <NoteGridDescription />
              <HeaderBottomRight>
                <NoteListExcelDown />
                <NoteGridFontSizeController />
              </HeaderBottomRight>
            </HeaderBottom>
          </>
        )}
      </HeaderWrap>
      {/* brokertest 계정 경고문 */}
      {loginInfo?.member_srl == '9006' && (
        <WarnText>
          공용 테스트 아이디로 접속하셨습니다. 개인정보를 입력하실 경우
          다른 사용자에게 노출될 수 있으니, 주의하여 입력해 주시기
          바랍니다.
        </WarnText>
      )}
    </PcHeaderWrapper>
  )
}

const PcHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const HeaderBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 2072px;
  margin-bottom: 12px;
  line-height: 100%;
`

const HeaderBottomRight = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 8px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 4px 8px;

  ${media.mediumM`
    align-items: flex-end;

    svg {
      margin-bottom: 8px;
      margin-left: 8px;
    }
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${CommonPadding};
  padding-bottom: 54px;
  gap: 14px 0;

  ${media.smallPc`
      padding: 24px;
  `}

  ${media.mediumS`
    padding: 10px 12px;
  `}
`

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0 40px;

  ${media.smallPc`
    padding: 0 24px;
  `}

  ${media.mediumS`
    padding: 0 20px;
  `}
`

const MobileWrapper = styled.div`
  display: flex;
  align-items: center;

  ${media.mediumS`
    justify-content: space-between;
    width: 100%;
    gap: 12px;
  `}
`
const RightWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export default forwardRef(NoteListHeader)
