import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import RadioButton from '../../elements/RadioButton'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useEffect } from 'react'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 융자금
 */
const NoteAdColumnLoan = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
    getValues,
  } = useFormContext()

  const tradeType = watch('trade.type')
  const loanCode = watch('trade.loanCode')
  const loan = watch('trade.loan')

  useEffect(() => {
    if (loanCode == 'on') {
      onUpdate('trade.loanCode', null)
    }
  }, [loanCode])

  return (
    <ColumnSet>
      <ColumnTitle>융자금</ColumnTitle>
      <ColumnVal gap={10}>
        <div>
          <InputText
            disabled={loanCode == null || loanCode == 0}
            key={`note_ad_trade_loan`}
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="number"
            value={loan || ''}
            {...register('trade.loan')}
            onBlur={(e) => {
              onUpdate('trade.loan', e.target.value.replaceAll(',', ''))
            }}
            append={'만원'}
          />
        </div>

        {/* 전세/월세/단기임대인 경우 필수 */}
        <RadioButton
          size="small"
          fit
          label="없음"
          name="loan_code"
          value={0}
          checked={loanCode == 0}
          {...register('trade.loanCode')}
          onChange={(e) => {
            onUpdate('trade.loan', null)
            onUpdate('trade.loanCode', 0)
          }}
        />
        <RadioButton
          size="small"
          fit
          label="시세대비 30% 미만"
          name="loan_code"
          value={1}
          checked={loanCode == 1}
          {...register('trade.loanCode')}
          onChange={(e) => {
            onUpdate('trade.loanCode', 1)
          }}
        />
        <RadioButton
          size="small"
          fit
          label="시세대비 30% 이상"
          name="loan_code"
          value={2}
          checked={loanCode == 2}
          {...register('trade.loanCode')}
          onChange={(e) => {
            onUpdate('trade.loanCode', 2)
          }}
        />
        <RadioButton
          size="small"
          fit
          label="표시안함"
          name="loan_code"
          checked={loanCode == null || loanCode == 'on'}
          {...register('trade.loanCode')}
          onChange={(e) => {
            onUpdate('trade.loan', null)
            onUpdate('trade.loanCode', null)
          }}
        />
        <ValidateWarn>
          <div>{errors?.trade?.loan?.message}</div>
          <div>{errors?.trade?.loanCode?.message}</div>
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnLoan
