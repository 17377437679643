import styled from 'styled-components'
import { TypeBlock } from '../List/CustomContactCellItem'

const CustomDocsSellerBuyerCell = ({ value }) => {
  if (value && value.length > 0) {
    if (value[0].name || value[0].number) {
      return `${value[0].name} ${value[0].number}`
    } else if (value[0].agent_name || value[0].agent_number) {
      return (
        <Wrapper>
          <TypeBlock blockcolor="#FFD1F5">대</TypeBlock>
          {value[0].agent_name} {value[0].agent_number}
        </Wrapper>
      )
    }
  } else {
    return <></>
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export default CustomDocsSellerBuyerCell
