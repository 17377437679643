import { useState } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import FolderList from 'components/jmapnote/folder/FolderList'
import FolderSave from 'components/jmapnote/folder/FolderSave'

const NoteListHeader = ({ _getFoldersLoading, folders }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      {folders?.length > 1 && (
        <StyledHeader>
          <FolderList loading={_getFoldersLoading} folders={folders} />
          <StyledHeaderContainer />
        </StyledHeader>
      )}
      <FolderSave visible={visible} setVisible={setVisible} />
    </>
  )
}

const StyledHeader = styled.div`
  padding: 14px 16px;
  margin: 18px 14px 12px 14px;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 2px, rgb(0 0 0 / 5%) 0 6px 32px;
  border-radius: 16px;
  background-color: ${theme.colors.base.white};
  overflow: hidden;

  ${media.mediumS`
    padding: 12px 14px;
  `}
`
const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`
export default NoteListHeader
