import theme from 'lib/styles/theme'
import styled from 'styled-components'

const TesterPaymentText = () => {
  return <TesterText>* 실제 결제가 안되는 테스터 계정입니다</TesterText>
}

const TesterText = styled.div`
  width: 100%;
  color: ${theme.colors.tint.danger};
  font-size: 14px;
  font-weight: 600;
`
export default TesterPaymentText
