import theme from 'lib/styles/theme'
import styled, { css } from 'styled-components'

/**
 * 계약서 수정 폼 스타일 모음
 */

/**
 * 테이블간 거리 스타일
 */
export const TableSpace = css`
  margin-bottom: 6px;
`

/**
 * 섹션간 거리 스타일
 */
export const SectionSpace = css`
  margin-bottom: 16px;
`

/**
 * 확인설명서 테이블 헤더 width 모음
 */
export const headerWidth = 88 //1단계 헤더 넓이
export const subHeaderWidth = 95 //2단계
export const subHeaderWidthSm = 80 //2단계 작은 버전
export const innerSubHeaderWidth = 130 // 2-3단계 서브헤더

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 100%;
  font-weight: 700;

  ${({ mb }) =>
    mb &&
    `
      margin-bottom: 24px;
  `}

  ${({ center }) =>
    center &&
    `
    justify-content: center; 
  `}

  .select__control {
    border: none;
    font-size: 32px;
  }

  .select__indicator {
    background-color: ${theme.colors.gray[100]};
    border-radius: 6px;
    svg {
      fill: ${theme.colors.gray[600]};
    }
  }

  .select__menu-list {
    font-size: 18px;
  }
`

export const Table = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid ${theme.colors.gray[300]};
`

export const StyledTable = styled(Table)`
  ${TableSpace}

  ${({ sectionspace }) => sectionspace && `${SectionSpace}`}
`

export const InnerTable = styled(Table)`
  height: 100%;
  border: none;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  ${({ noborderbottom }) => noborderbottom && `border-bottom: none;`}
  height: 30px;

  ${({ height }) => {
    height && `height: ${height}px;`
  }}
  ${({ rowspan = 1, height }) =>
    rowspan &&
    `
      height: ${rowspan * (height ? height : 30)}px; 
      flex-direction: column;
      flex-wrap: wrap;
      align-items: initial;
    `}

  ${({ heightpercent }) =>
    heightpercent && `height: ${heightpercent}%;`}
`
export const RowSpanInner = styled.div`
  display: flex;
  // width: calc(100% - 112px);
  position: relative;
  //flex: 0 0 ${({ rowspan }) => 100 / rowspan}%;
  height: ${({ rowspan }) => 100 / rowspan}%;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  ${({ noborderbottom }) => noborderbottom && `border-bottom: none;`}
  ${({ minuswidth = 112 }) => `width: calc(100% - ${minuswidth}px);`}
  ${({ fullwidth }) => fullwidth && `width: 100%;`}
  ${({ rowspanheightpercent }) =>
    rowspanheightpercent && `height: ${rowspanheightpercent}%;`}
`

export const RowSpan = styled.div`
  display: flex;
  width: 100%;
  height: ${({ rowspan }) => rowspan * 30};
  flex-direction: column;
  flex-wrap: wrap;
  align-items: initial;
`

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 112px;
  height: 100%;
  background-color: ${theme.colors.gray[100]};
  border-right: 1px solid ${theme.colors.gray[200]};
  font-weight: 400;
  line-height: 120%;
  text-align: center;

  ${({ width }) => width && `width: ${width}px;`}
  ${({ direction }) =>
    direction == 'column' && `flex-direction: column;`}
`

export const TableSubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 3px;
  height: 100%;
  border-right: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.gray[50]};
  line-height: 130%;
  font-weight: 400;
  text-align: center;
  ${({ width }) => width && `width: ${width}px;`}
  ${({ noborderright }) => noborderright && `border-right: none;`}
  ${({ disabled }) => disabled && `color: ${theme.colors.gray[500]};`}
`

export const TableSubHeaderFullWidth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.gray[50]};
`

export const TableContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 3px;
  border-right: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.base.white};
  height: 100%;
  line-height: 140%;

  ${({ minuswidth = 112 }) => `width: calc(100% - ${minuswidth}px);`}
  ${({ fullwidth }) => fullwidth && `width: 100%;`}
  ${({ noborderright }) => noborderright && `border-right: none;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ inheritwidth }) => inheritwidth && `width: inherit;`}
  ${({ basic }) => basic && `width: calc(100% - 112px);`}
  ${({ spacebetween }) =>
    spacebetween && `justify-content: space-between;`}
  ${({ expandright }) => expandright && `padding-right: 0;`}
  ${({ expandleft }) => expandleft && `padding-left: 0;`}
  ${({ nopadding }) => nopadding && `padding: 0;`}
`

export const InnerTableBlock = styled.div`
  display: flex;
  height: 100%;
  border-bottom: 1px solid ${theme.colors.gray[200]};

  border-right: 1px solid ${theme.colors.gray[200]};

  ${({ noborderright }) => noborderright && `border-right: none;`}
  ${({ noborderbottom }) => noborderbottom && `border-bottom: none;`}
  ${({ widthpercent }) =>
    widthpercent &&
    `
        width: ${widthpercent}%;
  `}
  ${({ width }) =>
    width &&
    `
        width: ${width};
  `}
`

export const InnerTableContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3px;
  height: 100%;
  ${({ spacebetween }) =>
    spacebetween &&
    `
      justify-content: space-between;
  `}
  width: ${({ headerwidth }) => `calc(100% - ${headerwidth}px);`};

  ${({ pl }) =>
    pl &&
    `
    padding-left: 8px;
  `}

  ${({ checkboxes }) =>
    checkboxes &&
    `
      & > label {
        margin-right: 16px;
      }
    `}

  ${({ fullWidth }) =>
    fullWidth && `width: 100%; margin-right: 0 !important;`}
`

export const TableTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  margin-right: 4px;
  margin-bottom: 8px;

  ${({ marginTop }) => marginTop && `margin-top: 20px;`}
`

export const TableSubTitle = styled.div`
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 27px;
`

/**
 * 권리관계-민간-등록, 비선호시설, 도배, 환경조건에서 사용하는 공통 라디오 버튼 레이아웃
 */
export const RadioButtonsWrap = styled.div`
  display: flex;
  min-width: 210px;
  margin-right: 0;
  & > label {
    &:first-child {
      min-width: 102px;
    }
    &:nth-of-type(2) {
      min-width: 86px;
    }
  }
  ${({ width }) => width && `min-width: ${width}px;`}
`

/**
 * 건축물, 도배에서 사용하는 공통 라디오 버튼 레이아웃
 */
export const StyledRadioButtonsWrap = styled(RadioButtonsWrap)`
  & > label {
    &:first-child {
      min-width: 102px;
    }
  }
`

export const AmongSpan = styled.span`
  min-width: 47px;

  ${({ width }) => width && `min-width: ${width}px;`}
`

export const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: 4px solid transparent;
  outline: 1px solid ${theme.colors.gray[300]};
  font-size: 15px;
  box-sizing: border-box;
  line-height: 120%;
  white-space: pre-wrap;

  ${({ height }) => height && `height: ${height}px;`}
  ${({ fullheight }) =>
    fullheight &&
    `
    height: 97%;
  `}
  ${({ fullwidth }) =>
    fullwidth &&
    `
    width: 100%;
  `}

  &:focus,
  &:focus-within {
    outline-color: ${theme.colors.blue[400]};
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${theme.colors.gray[300]};
    border: 3px solid transparent;
    min-height: 50px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }
`

export const InfoBlock = styled.div`
  width: 100%;
  padding: 16px 20px;

  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  ${({ mb }) => mb && `margin-bottom: 20px;`}
  ${({ color }) => {
    switch (color) {
      case 'red':
        return `
          color: ${theme.colors.tint.danger};
          background-color: ${theme.colors.red[50]};
          border: 1px solid ${theme.colors.red[400]};
        `
      case 'gray':
        return `
          color: ${theme.colors.gray[600]};
          background-color: ${theme.colors.gray[50]};
          border: 1px solid ${theme.colors.gray[400]};
        `
      case 'blue':
        return `
          color: ${theme.colors.blue[600]};
          background-color: ${theme.colors.blue[50]};
          border: 1px solid ${theme.colors.blue[400]};

          a {
            color: ${theme.colors.blue[700]};
            text-decoration: underline;
            text-underline-position: under;
          }
        `
    }
  }}
`

export const SkipCover = styled.div`
  padding: 1px 8px;
  height: 24px;
  width: 100px;
  background-color: ${theme.colors.gray[100]};
  margin-right: 8px;
  font-size: 14px;

  width: ${({ width }) => width && `${width}px`};
`
