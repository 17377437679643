import MapContext from 'contexts/map'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const MapSpecificFavoriteMarkerCustomOverlay = ({
  payload,
  children,
  overlayRef,
  onInit,
  addressSrl,
}) => {
  const {
    state: { map },
  } = useContext(MapContext)
  const { kakao } = window

  const openedMarker = useSelector((state) => state.note.openedMarker)

  const { addressSrl: currentAddressSrl } = useNoteMatch()

  const createMarker = (payload) => {
    const mergedPayload = {
      ...payload,
      map,
      content: overlayRef.current,
    }
    // 마커 세팅
    const marker = new kakao.maps.CustomOverlay(mergedPayload)
    marker.setMap(map)
    return marker
  }

  useEffect(() => {
    const marker = createMarker(payload)
    if (onInit) onInit(marker)

    marker.setZIndex(9997)

    if (addressSrl == currentAddressSrl) marker.setZIndex(11)

    return () => {
      // unmount될 때 지도상에서 제거
      marker.setMap(null)
    }
  }, [payload, openedMarker])

  return <div>{children}</div>
}

export default MapSpecificFavoriteMarkerCustomOverlay
