import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { useEffect, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { changeOrderAdImage, deleteAdFile } from 'lib/api/notev2/adForm'
import useAxios from 'lib/hooks/useAxios'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 상세정보 매물사진
 */
const NoteAdColumnPicturesItem = ({
  thumbnail,
  url,
  moveItem,
  index,
  file_srl,
  fileList,
  deletedFileSrl,
  setDeletedFileSrl,
  setFileList,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )
  const ref = useRef(null)
  const { watch } = useFormContext()

  const [onRequestDelete, , dataDelete] = useAxios(deleteAdFile, {
    resetError: true,
  }) //사진 삭제
  const [onRequestChangeOrder, , dataChangeOrder] = useAxios(
    changeOrderAdImage,
    {
      resetError: true,
    },
  ) //순서 변경 api

  const ad_srl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == ad_srl && adFormControl?.isDisabledAll //읽기 전용 여부

  /**
   * 사진 drop 동작
   */
  const [{ handlerId }, drop] = useDrop({
    accept: 'adPictures',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const hoverClientX =
        (monitor.getClientOffset()?.x || 0) - hoverBoundingRect.left

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }

      moveItem(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  /**
   * 사진 drag 동작
   */
  const [{ isDragging }, drag] = useDrag({
    type: 'adPictures',
    item: () => {
      return { index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      if (fileList.length == 0) return

      //드래그 끝났으니 순서 변경 api 호출
      onRequestChangeOrder({
        ad_srl,
        file_srls: fileList.map(({ file_srl }) => file_srl),
        plan_srl: planInfo.allinone_plan_srl,
      })
    },
  })

  drag(drop(ref))

  /**
   * 사진 삭제
   */
  const onClickDelete = () => {
    onRequestDelete({
      ad_srl,
      file_srl,
      type: 'photo',
      plan_srl: planInfo.allinone_plan_srl,
    })
  }

  /**
   * 사진 삭제 결과
   */
  useEffect(() => {
    if (!dataDelete) return
    setFileList((prev) =>
      prev.filter(({ file_srl: arrFileSrl }) => arrFileSrl != file_srl),
    )
    setDeletedFileSrl((prev) => [...prev, ...[file_srl]])
  }, [dataDelete])

  /**
   * 사진 삭제된건 안보여줌 (list api 호출 안하고 이렇게 처리함)
   */
  if (deletedFileSrl.includes(file_srl)) return

  return (
    <ImgWrapper
      ref={ref}
      isDragging={isDragging}
      first={index == 0 ? 'Y' : 'N'}
    >
      <ImgHandler ref={drag} data-handler-id={handlerId}>
        <img src={thumbnail || url} alt="매물 사진" />
      </ImgHandler>
      {!isDisabled && (
        <Delete onClick={onClickDelete}>
          <i className="fal fa-times"></i>
        </Delete>
      )}
    </ImgWrapper>
  )
}

const ImgWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: calc(20% - 4px);
  height: 100px;
  cursor: grab;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ first }) =>
    first == 'Y' &&
    `
    &::after {
      content: '대표 이미지';
      display:flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 26px;
      background-color: ${theme.colors.gray[600]}E6;
      color: ${theme.colors.base.white};
      font-size: 12px;
      font-weight: 700;
      line-height: 180%; 
    }
    `}

  ${media.mediumM`
    width: calc(25% - 8px);
  `}
`

const ImgHandler = styled.div`
  width: 100%;
  height: 100%;
`

const Delete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${theme.colors.base.white};
  cursor: pointer;
`

export default NoteAdColumnPicturesItem
