import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  AddressCellState: false,
  firstStepState: false,
  threeStepState: false,
  fourStepState: false,
  fiveStepState: false,
  threeDepthChannelSrl: 0,
  threeDepthAddressSrl: 0,
  threeDepthNoteSrl: 0,
  onBoardingStart: false, //웰컴모달 시작하기 누르면 true가된다.
  threeDepthLoaded: false,
  threeDepthStart: false,
  gongbuStepState: false,
  contractStepState: false,
  onBoardingState: false,
}

export const onBoardingSlice = createSlice({
  name: 'onBoarding',
  initialState,
  reducers: {
    setAddressCellState(state, action) {
      state.AddressCellState = action.payload
    },
    setThreeStepState(state, action) {
      state.threeStepState = action.payload
    },
    setFourStepState(state, action) {
      state.fourStepState = action.payload
    },
    setFiveStepState(state, action) {
      state.fiveStepState = action.payload
    },
    setFirstStepState(state, action) {
      state.firstStepState = action.payload
    },
    setThreeDepthChannelSrl(state, action) {
      state.threeDepthChannelSrl = action.payload
    },
    setThreeDepthAddressSrl(state, action) {
      state.threeDepthAddressSrl = action.payload
    },
    setThreeDepthNoteSrl(state, action) {
      state.threeDepthNoteSrl = action.payload
    },
    setOnBoardingStart(state, action) {
      state.onBoardingStart = action.payload
    },
    setThreeDepthLoaded(state, action) {
      state.threeDepthLoaded = action.payload
    },
    setThreeDepthStart(state, action) {
      state.threeDepthStart = action.payload
    },
    setGongbuStepState(state, action) {
      state.gongbuStepState = action.payload
    },
    setContractStepState(state, action) {
      state.contractStepState = action.payload
    },
    setOnBoardingState(state, action) {
      state.onBoardingState = action.payload
    },
  },
})

export const {
  setAddressCellState,
  setThreeStepState,
  setFourStepState,
  setFiveStepState,
  setFirstStepState,
  setThreeDepthChannelSrl,
  setThreeDepthAddressSrl,
  setThreeDepthNoteSrl,
  setOnBoardingStart,
  setThreeDepthLoaded,
  setThreeDepthStart,
  setGongbuStepState,
  setContractStepState,
  setOnBoardingState,
} = onBoardingSlice.actions

export default onBoardingSlice.reducer
