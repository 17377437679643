import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import NoteAccountAllInOneMembersList from './NoteAccountAllInOneMembersList'
import NoteAccountAllInOneMembersListContainer from 'containers/notev2/NoteAccountAllInOneMembersListContainer'
import NoteAccountAllInOneMeberInvite from './NoteAccountAllInOneMeberInvite'
import NoteAccountAllInOneMemberSearch from '../NoteAccountAllInOneMemberSearch'

/**
 * 계정관리
 * 올인원 멤버관리
 */
const NoteAccountAllInOneMembers = () => {
  return (
    <Wrapper>
      <NoteAccountAllInOneMembersListContainer>
        {({ data, search, bindSearchInput }) => {
          return (
            <>
              <SearchWrap>
                {/* <NoteAccountAllInOneMemberSearch
                  bindSearch={bindSearchInput}
                /> */}
                <div></div>
                <NoteAccountAllInOneMeberInvite search={search} />
              </SearchWrap>
              <NoteAccountAllInOneMembersList
                search={search}
                data={data}
              />
            </>
          )
        }}
      </NoteAccountAllInOneMembersListContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  background-color: ${theme.colors.base.white};
  padding: 0 40px;

  ${media.mediumS`
    padding: 0;
  `}
`

const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  button {
    flex-shrink: 0;
  }
`

export default NoteAccountAllInOneMembers
