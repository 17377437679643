import styled from 'styled-components'
import theme from 'lib/styles/theme'
import NoteDocsLayerLandUseLayout from '../NoteDocs/Layer/NoteDocsLayerLandUseLayout'
import NoteDocsLayerDeunggibuCommon from '../NoteDocs/Layer/NoteDocsLayerDeunggibuCommon'
import Scrollbars from 'react-custom-scrollbars-2'

const NoteDetailGongbuDoc = ({ type, data, onClose }) => {
  return (
    <DocLayer visible={data}>
      <Scrollbars>
        {data && (
          <LayerWrapper>
            <Header>
              <i onClick={onClose} className="far fa-chevron-left"></i>
              <span>{type}</span>
            </Header>

            {type == '등기부등본' && (
              <NoteDocsLayerDeunggibuCommon
                targetData={data}
                onClose={onClose}
                goToDeunggibuIssuance={onClose}
                useTo="note"
              />
            )}
            {type == '토지이용계획원' && (
              <NoteDocsLayerLandUseLayout data={data} useTo="note" />
            )}
          </LayerWrapper>
        )}
      </Scrollbars>
    </DocLayer>
  )
}

const DocLayer = styled.div`
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  left: 100%;
  transition: left 0.5s cubic-bezier(0.1, 1, 0.3, 1);
  background-color: ${theme.colors.base.white};
  ${({ visible }) =>
    visible &&
    `
      left: 0px; 
      width: 100%;
      height: calc(100svh - 280px);
    `}
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  span {
    font-size: 16px;
    line-height: 100%;
    font-weight: 500;
  }

  i {
    cursor: pointer;
    font-size: 24px;
    font-weight: 100;
    color: ${theme.colors.gray[600]};
    padding-right: 12px;
  }
`

const LayerWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 36px 30px;
  padding-bottom: 100px;
`

export default NoteDetailGongbuDoc
