/*eslint react-hooks/exhaustive-deps: "off"*/
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory, withRouter, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import styled from 'styled-components'

import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'

import LoginForm from 'components/login/LoginForm'
import { Tag } from 'components/design'
import { Modal } from 'components/design'
import useQueryString from 'lib/hooks/useQueryString'
import { useSelector } from 'react-redux'
import useAxios from 'lib/hooks/useAxios'
import { getNotes } from 'lib/api/note/noteApi'
import { moveAddress } from 'lib/api/note/addressApi'

const StyledListItem = styled.article`
  list-style: none;
  position: relative;
  padding: 11px 0 0.875rem;
  margin: 0;

  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 700;
`

const ItemMain = styled.div``

const ItemTitle = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;

  color: ${theme.colors.gray[900]};
  &:hover {
    color: ${theme.colors.gray[900]};
  }
`
const ItemArticle = styled.div`
  flex: 1;
`

const ItemAddress = styled.div`
  margin-right: 0.5rem;
`
const ItemBadge = styled.div`
  min-width: 18px;
  height: 18px;
  padding-left: 4px;
  padding-right: 4px;

  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 18px;

  background-color: ${theme.colors.tint.primary};
  border-radius: 100%;
`

const ItemSub = styled.div`
  display: flex;
  align-items: center;

  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 400;
`
const ItemTag = styled.div`
  margin-right: 0.5rem;
`

const ItemButtons = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: flex;

  & > li {
    margin-right: 0.75rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

const ItemButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;

  color: ${theme.colors.gray[500]};
  font-size: 1.25rem;

  background: none;
  outline: none;
  appearance: none;

  &:hover {
    color: ${theme.colors.gray[600]};
  }
`

const FavoriteItem = ({ favorite }) => {
  const { kakao } = window
  const { state, actions } = useContext(MapContext)
  const { map, folderDatas } = state
  const summaryData = useSelector((state) => state.summary.summaryInfo)

  const history = useHistory()
  const query = useQueryString()

  const addressSrlForUpdate = query.addressSrl
  const isUpdate = query.isUpdate

  const [notesData, setNotesData] = useState([]) // 매물조회 데이터
  // 폴더 선택 폼 열기
  const [addressSrlMoveToFolder, setAddressSrlMoveToFolder] = useState()
  const { register, handleSubmit } = useForm()

  const [isUpdateNoteList, setIsUpdateNoteList] = useState(false)

  // 로그인 컴포넌트 열고 닫기
  const [isVisibleLogin, setIsVisibleLogin] = useState(false)

  const [isShowBtnEdit, setIsShowBtnEdit] = useState(false) // 수정, 삭제 버튼UI 활성화 여부
  const [isOpenDeleteFavoriteModal, setIsOpenDeleteFavoriteModal] =
    useState(false)
  const noteMenuRef = useRef(null)

  const [onRequest, , data, ,] = useAxios(getNotes)
  const [onRequestClick, , dataClick, ,] = useAxios(getNotes)
  const [onRequestMoveAddress, , dataMoveAddress, ,] =
    useAxios(moveAddress)

  /**
   * 더보기 dropdown메뉴 열린 상태에서 바깥쪽 클릭하면 닫히도록
   */
  useEffect(() => {
    /**
     * Close if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        noteMenuRef.current &&
        !noteMenuRef.current.contains(event.target)
      ) {
        if (isShowBtnEdit) {
          setIsShowBtnEdit(false)
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [noteMenuRef, isShowBtnEdit])

  // 매물목록 활성화된 상태에서 정렬한 경우 다시 목록 열기
  useEffect(() => {
    if (state.visibleNotes.length > 0) {
      const visibleNotes = state.visibleNotes

      visibleNotes.map((addressSrl) => {
        if (addressSrl === favorite.address_srl) {
          // 매물 데이터 불러오기
          onRequest({
            address_srl: addressSrl,
            _rx_csrf_token: state.loginInfo.csrf_token,
          })
        }
      })
    }
  }, [state.visibleNotes])

  useEffect(() => {
    if (!data) return

    setNotesData(data.result)

    actions.setFavorite(data.result)

    // 매물목록 열기
    actions.setFavoriteDatas((prevState) => {
      return prevState.map((item) => {
        if (item.address_srl === addressSrl) {
          return { ...item, isVisible: true }
        } else {
          return item
        }
      })
    })
  }, [data])

  // 매물노트2depth에서 수정한 결과 반영하기 위함
  useEffect(() => {
    if (
      isUpdate === 'true' &&
      parseInt(addressSrlForUpdate) === favorite.address_srl
    ) {
      setIsUpdateNoteList(true)
    }

    return () => setIsUpdateNoteList(false)
  }, [isUpdate, addressSrlForUpdate, favorite])

  // 업데이트 해야하는 매물목록조회 메서드 호출
  useEffect(() => {
    if (isUpdateNoteList) {
      onClickFavorite()
    }
  }, [isUpdateNoteList, addressSrlForUpdate])

  /**
   * 매물 데이터 조회 및 선택된 매물의 address_srl 세팅
   */
  const onClickFavorite = useCallback(() => {
    // 초기화
    setNotesData([])

    // 매물 데이터 불러오기
    onRequestClick({
      address_srl: favorite.address_srl,
      _rx_csrf_token: state.loginInfo.csrf_token,
    })
  }, [map, kakao, actions, favorite])

  useEffect(() => {
    if (!dataClick) return

    const result = dataClick.result
    // 주소 세팅
    const notesData = result.map((note) => {
      return {
        ...note,
        address_name: favorite.address_jibun || favorite.address_doro,
      }
    })
    // 매물목록 데이터 세팅
    setNotesData(notesData)

    // 매물목록 활성화여부 isVisible 세팅
    actions.setFavoriteDatas((prevState) => {
      return prevState.map((favorite) => {
        if (favorite.address_srl === result[0].address_srl) {
          // 매물목록 업데이트해야 하는 경우엔 무조건 true
          if (parseInt(addressSrlForUpdate) === result[0].address_srl) {
            return { ...favorite, isVisible: true }
            // 그렇지 않으면, 열고 닫기 제대로 되도록..
          } else {
            if (favorite.isVisible) {
              return { ...favorite, isVisible: false }
            } else {
              return { ...favorite, isVisible: true }
            }
          }
        } else {
          return favorite
        }
      })
    })
  }, [dataClick])

  /**
   * 즐겨찾기 다른 폴더로 이동
   */
  const onSubmit = useCallback(
    (data) => {
      // console.log('submit data:', data)

      let formData = new FormData()
      // 정보 세팅
      formData.append('folder_srl', data.folder_srl)
      formData.append('address_srl', addressSrlMoveToFolder)
      // CSRF 토큰 세팅
      formData.append('_rx_csrf_token', state.loginInfo.csrf_token)

      onRequestMoveAddress(formData)
    },
    [addressSrlMoveToFolder, state.loginInfo],
  )

  useEffect(() => {
    if (!dataMoveAddress) return

    if (dataMoveAddress.message === 'success') {
      // 폴더 선택 모달 닫기
      setAddressSrlMoveToFolder(null)

      actions.setFolderDatas((prevState) => {
        return prevState.map((item) => {
          // console.log('item: ', item)
          // 기존 폴더의 address_count -1
          if (item.folder_srl === favorite.folder_srl) {
            return { ...item, address_count: item.address_count }
          }
          // 이사갈 폴더의 address_count +1
          if (
            item.folder_srl === parseInt(dataMoveAddress.folder_srl)
          ) {
            return {
              ...item,
              address_count: item.address_count + 1,
            }
          }
          return item
        })
      })

      // 즐겨찾기 목록 업데이트
      actions.setNeedToUpdateFavorites(true)
      // actions.setFavoriteDatas((prevState) => {
      //   return prevState.filter(
      //     (item) => item.address_srl !== addressSrlMoveToFolder,
      //   );
      // });
    } else {
      if (dataMoveAddress.error === -3) {
        // 로그인창 열기
        setIsVisibleLogin(true)
      }
    }
  }, [dataMoveAddress])

  /**
   * 즐겨찾기 삭제
   */
  const handleDeleteFavorite = useCallback(() => {
    // console.log('즐겨찾기 삭제')
    axios
      .post('/jmapnote/api/address/remove', {
        address_srl: favorite.address_srl,
        _rx_csrf_token: state.loginInfo.csrf_token, // CSRF 토큰 세팅
      })
      .then((response) => {
        if (response.data.message === 'success') {
          // 즐겨찾기 모달창 닫기
          setIsOpenDeleteFavoriteModal(false)

          // 즐겨찾기 목록 업데이트
          actions.setFavoriteDatas((prevState) => {
            return prevState.filter(
              ({ address_srl }) => address_srl !== favorite.address_srl,
            )
          })

          actions.setNeedToUpdateFavorites(true)

          // 캐싱된 summaryData에서 favorite에 null 세팅
          let clickedData = summaryData[favorite.address_id]
          clickedData = { ...clickedData, favorite: null }
          actions.setSummaryData({
            ...summaryData,
            [favorite.address_id]: clickedData,
          })
        }
      })
  }, [favorite.address_srl, state.loginInfo, summaryData])

  return (
    <>
      <StyledListItem>
        <ItemMain>
          <ItemArticle>
            <ItemTitle to={`/${favorite.address_id}`}>
              <ItemAddress>{favorite.address_jibun}</ItemAddress>

              {favorite.note_count !== 0 &&
                state.folderDatas &&
                'realestate' === state.loginInfo.member_type && (
                  <ItemBadge>{favorite.note_count}</ItemBadge>
                )}
            </ItemTitle>

            <ItemSub>
              {favorite.property_type && (
                <ItemTag>
                  {'토지' === favorite.property_type ? (
                    <Tag color="gold" size="small">
                      {favorite.property_type}
                    </Tag>
                  ) : '단독' === favorite.property_type ? (
                    <Tag color="red" size="small">
                      {favorite.property_type}
                    </Tag>
                  ) : (
                    <Tag color="blue" size="small">
                      {favorite.property_type}
                    </Tag>
                  )}
                </ItemTag>
              )}
              <span className="address_doro content">
                {favorite.address_doro
                  ? favorite.address_doro
                  : '도로명주소 없음'}
              </span>
            </ItemSub>

            {/* 부동산중개업자한테만 보이도록 */}
            {state.folderDatas &&
              'realestate' === state.loginInfo.member_type && (
                <ItemButtons>
                  <li>
                    <ItemButton
                      className="btn_edit btn_note"
                      onClick={(event) => {
                        // 현재 이벤트 이후의 전파를 막습니다.
                        event.stopPropagation()
                        setIsShowBtnEdit((checked) => !checked)
                      }}
                    >
                      <i className="fal fa-ellipsis-v"></i>
                    </ItemButton>
                    <div
                      className="ui_edit"
                      hidden={!isShowBtnEdit}
                      ref={noteMenuRef}
                    >
                      <div
                        className="ue delete"
                        onClick={(event) => {
                          // 현재 이벤트 이후의 전파를 막습니다.
                          event.stopPropagation()

                          // 매물이 없으면 바로 삭제
                          if (favorite.property_type === '') {
                            handleDeleteFavorite()
                          } else {
                            // 매물이 있으면 알림창 오픈
                            setIsOpenDeleteFavoriteModal(true)
                          }
                        }}
                      >
                        <i className="fal fa-trash-alt"></i>삭제
                      </div>
                    </div>
                  </li>
                </ItemButtons>
              )}
          </ItemArticle>
        </ItemMain>
      </StyledListItem>

      {/* 즐겨찾기 삭제 컴포넌트 모달 */}
      <Modal
        title="즐겨찾기를 삭제하시겠습니까?"
        visible={isOpenDeleteFavoriteModal}
        onOk={handleDeleteFavorite}
        onCancel={() => setIsOpenDeleteFavoriteModal(false)}
        okText="삭제"
        cancelText="취소"
        width={420}
        okButtonProps={{ color: 'danger' }}
      >
        <span>
          즐겨찾기 삭제 시 해당 즐겨찾기에 대한 매물 또한 삭제됩니다.
        </span>
      </Modal>

      {/* 로그인 컴포넌트 모달 */}
      <LoginForm
        visible={isVisibleLogin}
        onCancel={() => setIsVisibleLogin(false)}
      />
    </>
  )
}

export default withRouter(FavoriteItem)
