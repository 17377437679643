import { useState, useCallback, useEffect } from 'react'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Axios from 'axios'
import { version } from 'Constants'
import { setNeedToLogin, setLoginVisible } from 'modules/auth'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import { useHistory } from 'react-router'
import { setText, setTimestamp } from 'modules/notice'
import { flushSync } from 'react-dom'
import useLog from './useLog'
import {
  setAuthModalVisible,
  setNotPermitRegiModalVisible,
} from 'modules/noteAd'
import { setVisibleNotPermitPlusModal } from 'modules/noteDongho'

export default function useAxios(promiseCreater, options = {}) {
  const source = Axios.CancelToken.source()
  const {
    noErrorMessage = false,
    resetError = false,
    redirect = true,
  } = options
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [errorDetail, setErrorDetail] = useState(null)
  const [recall, setRecall] = useState('n')

  const dispatch = useDispatch()
  const { onRequest, dataLoginInfo } = useUserLoader(false)

  const history = useHistory()
  const { loggingError } = useLog()

  const onRequestAPI = useCallback(
    async (params, cancelToken, isCsrfToken = true) => {
      if (!promiseCreater) return

      if (resetError) setError(null)

      setLoading(true)

      try {
        const injectedParams = {
          _rx_csrf_token: loginInfo?.csrf_token,
          v: version,
          ...params,
        }
        const response = await promiseCreater(
          // GET요청에는 CSRF 토큰 넣지 않기
          isCsrfToken ? injectedParams : { v: version, ...params },

          {
            cancelToken,
          },
        )

        if (response?.data?.notification) {
          dispatch(setText(response?.data?.notification.content))
          dispatch(setTimestamp(response?.data?.notification.timestamp))
        }

        if (response?.data?.refresh) {
          window.location.reload()
        }

        if (
          response?.data?.error &&
          response?.data?.errorDetail != 'ERR_CSRF_CHECK_FAILED'
        ) {
          setError(response?.data?.message)
          setErrorDetail(response?.data?.errorDetail)
          throw response
        }

        if (response?.data?.errorDetail == 'ERR_CSRF_CHECK_FAILED') {
          dispatch(setNeedToLogin(true))
        }

        // redirect_url이 있는 경우 해당 경로로 이동
        if (redirect && response?.data?.redirect_url) {
          const url = response?.data?.redirect_url
          const redirectUrl = url?.replace('map/', '')
          message.info(
            '접근 권한이 없거나 대상이 유효하지 않습니다. 기본 채널로 이동합니다.',
          )
          if (redirectUrl) {
            history.replace(redirectUrl)
          }
        }

        flushSync(() => {
          setData(response?.data)
        })
      } catch (e) {
        // if (e?.message && !e?.message?.includes('503')) {
        // message.error(e?.message)
        // }

        setError(e?.data?.message)
        setErrorDetail(e?.data?.errorDetail)

        loggingError(e, e?.config?.url)
        if (typeof error !== 'string') {
          // Log.warn('이 에러는 string이 아닙니다.')
          return
        }
      }
      // 초기화
      setLoading(false)
      setError(null)
      setErrorDetail(null)
    },
    [promiseCreater, loginInfo],
  )

  useEffect(() => {
    if (noErrorMessage) return
    if (!error || error.includes('503')) return
    if (error === '요청한 기능을 실행할 수 있는 권한이 없습니다.') {
      onRequest()
      dispatch(setLoginVisible(true))
      return
    }
    if (errorDetail === 'ERR_ROUTE_NOT_FOUND') {
      history.push('/')
      return
    }
    if (error == 'ERR_INVALID_REALTOR') {
      setError(null)
      dispatch(setAuthModalVisible(true))
      return
    }
    if (error == 'NOT_PERMITTED_REGISTER') {
      setError(null)
      dispatch(setNotPermitRegiModalVisible(true))
      return
    }
    if (error == 'NOT_PERMITTED_PLUS') {
      //동호관리 플러스단지 추가 불가
      setError(null)
      dispatch(setVisibleNotPermitPlusModal(true))
      return
    }
    if (error === '유효하지 않은 좌표값입니다.') return

    message.error(error)
  }, [error])

  useEffect(() => {}, [recall])

  const onReset = () => {
    setLoading(false)
    setData(null)
    setError(null)
    setErrorDetail(null)
  }

  return [onRequestAPI, loading, data, error, onReset, source]
}
