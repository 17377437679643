import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import { Button, Tab, TabItem } from 'components/design'
import SideMenuJootekContent from './SideMenuJootekContent'
import SideMenuMapContent from './SideMenuMapContent'

const SideTabMenu = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState(1)

  const onChangeTab = (value) => {
    setActiveTab(value)
  }

  return (
    <>
      <Tab size="large" noContainer>
        <TabItem
          expand
          color="primary"
          active={activeTab === 1}
          onClick={() => onChangeTab(1)}
        >
          지도
        </TabItem>
        <TabItem
          color="primary"
          expand
          active={activeTab === 2}
          onClick={() => onChangeTab(2)}
        >
          주택
        </TabItem>
      </Tab>
      {activeTab == 1 && (
        <SideMenuMapContent onClose={onClose}></SideMenuMapContent>
      )}
      {activeTab == 2 && <SideMenuJootekContent onClose={onClose} />}
    </>
  )
}

export default SideTabMenu
