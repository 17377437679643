import SummaryBuilding3d from './SummaryBuilding3d'
import SummaryBuildingInfo from './notApt/SummaryBuildingInfo'
import SummaryBuildingLandInfo from './notApt/SummaryBuildingLandInfo'

/**
 * 건물정보 - 공동주택 아닌 경우 보여줄 데이터
 */
const SummaryBuildingNotApt = () => {
  return (
    <>
      <SummaryBuilding3d />
      <SummaryBuildingInfo />
      <SummaryBuildingLandInfo />
    </>
  )
}

export default SummaryBuildingNotApt
