import { useState } from 'react'
import { getPropertyTypes } from 'lib/utils/propertyType'

import { useDispatch, useSelector } from 'react-redux'
import {
  setFilterChannel,
  setFilterStatus,
  setFilterContractTypes,
  setFilterPropertyTypes,
  setFilterSalePrice,
  setFilterRent,
  setFilterArea,
  setFilterMoveDate,
  setFilterFloorCnt,
  setFilterExtraOptions,
} from 'modules/note'
import { useMemo } from 'react'
import { floorCntList } from 'Constants'

export default function useNoteFilter() {
  const status = 0
  const propertyTypes = getPropertyTypes()
  const contractTypes = ['전세', '월세', '매매', '분양권']
  const floorCnt = floorCntList
  const extraOptions = [
    '주차가능',
    '엘레베이터',
    '반려동물',
    '전세대출가능',
    '풀옵션',
    '공실',
  ]

  const dispatch = useDispatch()
  const filterStates = useSelector((state) => state.note.filterStates)

  const setChannel = (value) => {
    dispatch(setFilterChannel(value))
  }
  const setStatus = (value) => {
    dispatch(setFilterStatus(value))
  }
  const setPropertyTypes = (value) => {
    dispatch(setFilterPropertyTypes(value))
  }
  const setContractTypes = (value) => {
    dispatch(setFilterContractTypes(value))
  }
  const setSalePrice = (value) => {
    dispatch(setFilterSalePrice(value))
  }
  const setRent = (value) => {
    dispatch(setFilterRent(value))
  }
  const setArea = (value) => {
    dispatch(setFilterArea(value))
  }
  const setMoveDate = (value) => {
    dispatch(setFilterMoveDate(value))
  }
  const setFloorCnt = (value) => {
    dispatch(setFilterFloorCnt(value))
  }
  const setExtraOptions = (value) => {
    dispatch(setFilterExtraOptions(value))
  }

  const getComputedIsAllChecked = (propertyTypes, contractTypes) => {}

  const isAllChecked = useMemo(
    () => getComputedIsAllChecked(propertyTypes, contractTypes),
    [propertyTypes, contractTypes],
  )

  const month = new Date().getMonth() + 1

  const payload = {
    initialState: {
      filter_channel: '',
      status: 0,
      property_types: [],
      contract_types: [],
      sale_price: [0, 200],
      rent: [0, 200],
      area: [0, 70],
      move_date: [month, month + 11],
      floor_cnt: '',
      extra_options: [],
    },
    filterState: filterStates,
    selectedChannel: filterStates.filter_channel,
    setSelectedChannel: setChannel,
    status: status,
    selectedStatus: filterStates.status,
    setSelectedStatus: setStatus,
    propertyTypes: propertyTypes,
    selectedPropertyTypes: filterStates.property_types,
    setSelectedPropertyTypes: setPropertyTypes,
    contractTypes: contractTypes,
    selectedContractTypes: filterStates.contract_types,
    setSelectedContractTypes: setContractTypes,
    selectedSalePrice: filterStates.sale_price,
    setSelectedSaleprice: setSalePrice,
    selectedRent: filterStates.rent,
    setSelectedRent: setRent,
    setSelectedArea: setArea,
    setSelectedMoveDate: setMoveDate,
    floorCnt: floorCnt,
    selectedFloorCnt: filterStates.floor_cnt,
    setSelectedFloorCnt: setFloorCnt,
    extraOptions: extraOptions,
    selectedExtraOptions: filterStates.extra_options,
    setSelectedExtraOptions: setExtraOptions,
    isAllChecked: isAllChecked,
  }

  const handleReset = () => {
    payload.setSelectedChannel('')
    payload.setSelectedStatus(0)
    payload.setSelectedPropertyTypes([])
    payload.setSelectedContractTypes([])
    payload.setSelectedSaleprice([0, 200])
    payload.setSelectedRent([0, 200])
    payload.setSelectedArea([0, 70])
    payload.setSelectedMoveDate([month, month + 11])
    payload.setSelectedFloorCnt('')
    payload.setSelectedExtraOptions([])
  }

  return {
    ...payload,
    handleReset,
  }
}
