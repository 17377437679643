import styled from 'styled-components'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
} from '../../form/NoteAdFormStyle'
import theme, { media } from 'lib/styles/theme'
import { useSelector } from 'react-redux'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 중개업소 정보 기본 정보
 */
const NoteAdColumnStoreInfo = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  return (
    <ColumnSet>
      <ColumnTitle>기본정보</ColumnTitle>
      <ColumnVal gap={12}>
        <Row>
          <Pairs>
            <Title>중개업소명</Title>
            <Value>{loginInfo?.company_name}</Value>
          </Pairs>
          <Pairs>
            <Title>중개업소 대표전화</Title>
            <Value>
              {loginInfo?.company_phone &&
                loginInfo?.company_phone.length > 0 &&
                loginInfo?.company_phone.join('-')}
            </Value>
          </Pairs>
        </Row>
        <Row>
          <Pairs>
            <Title>중개업소 주소</Title>
            <Value>{loginInfo?.company_address}</Value>
          </Pairs>
          <Pairs>
            <Title>중개사 등록번호</Title>
            <Value>{loginInfo?.realtor_number}</Value>
          </Pairs>
        </Row>
        <Row>
          <Pairs>
            <Title>대표</Title>
            <Value>{loginInfo?.company_ceo}</Value>
          </Pairs>
          <Pairs>
            <Title>사업자등록번호</Title>
            <Value>{loginInfo?.company_number}</Value>
          </Pairs>
        </Row>
        <Row>
          <Pairs>
            <Title>대표 휴대폰 번호</Title>
            <Value>{loginInfo?.phone_number}</Value>
          </Pairs>
        </Row>
      </ColumnVal>
    </ColumnSet>
  )
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;

  ${media.mediumM`
    flex-direction: column;
    gap: 12px;
  `}
`

const Pairs = styled.div`
  display: flex;
  gap: 20px;
  width: calc(50% - 10px);

  ${media.mediumM`
    max-width: unset;
    width: 100%;
  `}
`

const Title = styled.div`
  max-width: 107px;
  flex-basis: 30%;
  color: ${theme.colors.gray[600]};
  flex-shrink: 0;

  ${media.mediumM`
    max-width: 100px;
    width: 100px;
    flex-basis: unset; 
  `}

  ${media.mediumS`
    max-width: 70px;
    width: 70px;
  `}
`

const Value = styled.div``

export default NoteAdColumnStoreInfo
