import { Tag } from 'components/design'
import MapContext from 'contexts/map'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getComputedArea } from 'utils'

const SumamryMultiHousingTag = ({ data, selectedDong, selectedHo }) => {
  const [areaInfo, setAreaInfo] = useState(null)
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  useEffect(() => {
    if (!data || data.length == 0) return
    setAreaInfo(
      data.find(
        ({ dong_name, ho_name }) =>
          dong_name == selectedDong && ho_name == selectedHo,
      ),
    )
  }, [])

  return (
    areaInfo?.exclusive_area && (
      <Wrapper>
        <Tag size="small" label="전용면적">
          {getComputedArea(areaInfo?.exclusive_area, isSquareMeter)}
        </Tag>
      </Wrapper>
    )
  )
}

const Wrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 12px;
`

export default SumamryMultiHousingTag
