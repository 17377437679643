import { Tag } from 'components/design'
import SummaryRpDetailTableItem from 'components/summary/SummaryRpDetail/SummaryRpDetailTableItem'
import moment from 'moment'
import { useMemo } from 'react'

const SummaryRpDetailTableItemRentContainer = ({ data, oriData }) => {
  const depositDiff = useMemo(() => {
    const currIndex = oriData.findIndex(({ id }) => data.id == id)
    if (oriData[currIndex + 1]) {
      return (
        Number(data?.deposit || 0) -
        Number(oriData[currIndex + 1].deposit || 0)
      )
    } else {
      return ''
    }
  }, [data, oriData])

  const rentDiff = useMemo(() => {
    const currIndex = oriData.findIndex(({ id }) => data.id == id)

    if (oriData[currIndex + 1]) {
      return (
        Number(data?.rent || 0) -
        Number(oriData[currIndex + 1].rent || 0)
      )
    } else {
      return ''
    }
  }, [data, oriData])

  const getTags = () => {
    const contract_type = data?.contract_type
    const rent_start = data?.rent_start
    const rent_end = data?.rent_end
    const renewal_right_used = data?.renewal_right_used
    const rent_date =
      rent_start && rent_end ? (
        <>
          {moment(rent_start, 'YYYYMMDD').format('YY.MM')} ~{' '}
          {moment(rent_end, 'YYYYMMDD').format('YY.MM')}
        </>
      ) : null

    if (renewal_right_used == 'Y') {
      return <GangsinYogu rent_date={rent_date} />
    } else if (contract_type == '갱신') {
      return <Gangsin rent_date={rent_date} />
    } else if (contract_type == '신규' && rent_start && rent_end) {
      return <NewTag rent_date={rent_date} />
    } else {
      return null
    }
  }

  const NewTag = ({ rent_date }) => {
    if (rent_date) {
      return (
        <Tag label="신규" size="small" color="primary">
          {rent_date}
        </Tag>
      )
    } else {
      return (
        <Tag size="small" color="primary">
          신규
        </Tag>
      )
    }
  }
  const GangsinYogu = ({ rent_date }) => {
    if (rent_date) {
      return (
        <Tag size="small" label="갱신요구권" color="purple">
          {rent_date}
        </Tag>
      )
    } else {
      return (
        <Tag size="small" color="purple">
          갱신요구권
        </Tag>
      )
    }
  }
  const Gangsin = ({ rent_date }) => {
    if (rent_date) {
      return (
        <Tag label="갱신" size="small" color="purple">
          {rent_date}
        </Tag>
      )
    } else {
      return (
        <Tag size="small" color="purple">
          rent_date
        </Tag>
      )
    }
  }

  return (
    <SummaryRpDetailTableItem
      date={data?.contract_date}
      type={data?.type}
      price={data?.deposit}
      rent_price={data?.rent}
      tag={getTags()}
      office_loc={data?.office_loc}
      rent_start={data?.rent_start}
      rent_end={data?.rent_end}
      previousDiff={{
        deposit: depositDiff,
        rent: rentDiff,
      }}
    />
  )
}

export default SummaryRpDetailTableItemRentContainer
