import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isVisible: false,
  text: '',
  timestamp: '',
  height: 0,
}

export const NoticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setIsVisible(state, actions) {
      state.isVisible = actions.payload
    },
    setText(state, actions) {
      state.text = actions.payload
    },
    setTimestamp(state, actions) {
      state.timestamp = actions.payload
    },
    setHeight(state, actions) {
      state.height = actions.payload
    },
  },
})

export const {
  setIsVisible,
  setText,
  setTimestamp,
  setHeight,
} = NoticeSlice.actions

export default NoticeSlice.reducer
