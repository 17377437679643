import { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Tooltip } from 'antd'
import { billionFormate } from 'utils/index'
import { getAddressName } from 'lib/utils'
import MapContext from 'contexts/map'

import { Tag } from 'components/design'

const StyledListItem = styled.li`
  border-bottom: 1px solid ${theme.colors.gray[100]};
  margin: 0 -1.25rem;
`

const StyledItem = styled.article`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.875rem 0.5rem 0.875rem 1.25rem;
  border-left: 2px solid transparent;

  color: ${theme.colors.gray[900]};
  /* transition: 0.2s ease; */

  &:hover {
    color: ${theme.colors.gray[900]};
    background-color: ${theme.colors.gray[50]};
  }

  ${({ isSelected }) =>
    isSelected &&
    `
      border-left-color: ${theme.colors.primary[400]};
      color: ${theme.colors.gray[900]};
      background-color: ${theme.colors.gray[50]};
  `}
`

const Body = styled.div`
  flex: 1;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.375rem;
`
const PropertyType = styled.div`
  margin-right: 0.75rem;
`
const TotalPrice = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 0.375rem;
`
const UnitProce = styled.span`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 500;
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -0.25rem;

  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
`
const MetaItem = styled.span`
  padding: 0 0.25rem;
`

const Distance = styled(Link)`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 100px;
  padding-left: 1.25rem;

  color: ${theme.colors.gray[700]};
  font-size: 0.875rem;

  border-left: 1px solid ${theme.colors.gray[200]};

  &:hover {
    color: ${theme.colors.gray[600]};
    background-color: ${theme.colors.gray[200]};
    border-radius: ${theme.borderRadius[1]};
    border-left-color: transparent;
  }
`
const DistanceValue = styled.div`
  flex: 1;

  ${({ isSelected }) =>
    isSelected &&
    `
      color: ${theme.colors.primary[400]};
      font-weight: 700;
  `}
`
const ItemChevron = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2rem;
  margin-left: 0.25rem;

  color: ${theme.colors.gray[500]};
  font-size: 1.25rem;
  border-radius: ${theme.borderRadius[1]};
`

const SummaryNearbyItem = ({ item, isSelected, onClickItem }) => {
  const { state } = useContext(MapContext)
  const unit_price =
    Math.round(item.price / item.land_area / 10000) * 10000

  const getTagColor = (propertyType) => {
    switch (propertyType) {
      case '토지':
        return 'orange'
      case '단독':
        return 'red'
      default:
        return 'blue'
    }
  }

  const getTagIcon = (propertyType) => {
    switch (propertyType) {
      case '토지':
        return <i className="far fa-expand"></i>
      case '단독':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        )
      default:
        return <i className="far fa-store-alt"></i>
    }
  }

  const getDistance = (distance) => {
    if (distance > 1000) {
      const value = distance / 1000
      return `${value.toFixed(1)}km`
    }

    return Math.round(item.distance) + 'm'
  }

  return (
    <StyledListItem>
      <StyledItem
        isSelected={isSelected}
        onClick={() =>
          onClickItem(item.address_id, item.centerpoint.coordinates)
        }
        onMouseOver={() =>
          onClickItem(item.address_id, item.centerpoint.coordinates)
        }
      >
        <PropertyType>
          <Tag
            size="small"
            color={getTagColor(item.property_type)}
            label={getTagIcon(item.property_type)}
            icon
          >
            {item.property_type}
          </Tag>
        </PropertyType>

        <Body>
          <Title>
            <TotalPrice>{billionFormate(item.price)}</TotalPrice>
            <UnitProce>
              (
              {state.isSquareMeter
                ? `㎡당 ${billionFormate(
                    Math.round(unit_price / 10000) * 10000,
                  )}`
                : `평당 ${billionFormate(
                    Math.round((unit_price * 3.305) / 10000) * 10000,
                  )}`}
              )
            </UnitProce>
          </Title>

          <Meta>
            <MetaItem>
              {'’' +
                item.contract_date.substring(2, 4) +
                '.' +
                item.contract_date.substring(4, 6)}
            </MetaItem>
            <MetaItem> · </MetaItem>
            <MetaItem>{item.address_name}</MetaItem>
          </Meta>
        </Body>

        <Tooltip title="해당 주소 자세히보기">
          <Distance to={`/${item.address_id}`}>
            <DistanceValue isSelected={isSelected}>
              {getDistance(item.distance)}
            </DistanceValue>

            <ItemChevron>
              <i className="fal fa-angle-right fa-lg"></i>
            </ItemChevron>
          </Distance>
        </Tooltip>
      </StyledItem>
    </StyledListItem>
  )
}

export default SummaryNearbyItem
