import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { setLoginVisible } from 'modules/auth'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import BaseNavItem from './BaseNavItem'
import { ReactComponent as Icon } from 'assets/icon/nav/noty.svg'

const StyledBadge = styled.div`
  position: absolute;
  top: 2px;
  left: 50%;
  z-index: 1;

  display: flex;

  min-width: 1.25rem;
  height: 1.25rem;
  padding: 0 0.25rem;

  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  line-height: calc(1.25rem - 4px);

  background-color: ${theme.colors.primary[400]};
  border: 2px solid white;
  border-radius: 2rem;
`

const BaseNavNoty = () => {
  const dispatch = useDispatch()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const notifications = useSelector(
    (state) => state.notification.notifications,
  )

  const getNotReadedNoties = (notifications) => {
    return notifications.filter((o) => o.readed !== 'Y')
  }
  const notReadedNoties = useMemo(
    () => getNotReadedNoties(notifications),
    [notifications],
  )

  const onClickLogin = () => {
    dispatch(setLoginVisible(true))
  }

  if (!loginInfo?.member_srl)
    return (
      <BaseNavItem
        icon={<i className="fal fa-sign-in"></i>}
        text="로그인"
        onClick={onClickLogin}
      />
    )

  return (
    <BaseNavItem
      to="/notifications"
      as={NavLink}
      text="알림"
      icon={
        <>
          {notReadedNoties.length > 0 && (
            <StyledBadge>
              {
                notReadedNoties.filter((item) => item.readed === 'N')
                  .length
              }
            </StyledBadge>
          )}
          <i className="fal fa-bell"></i>
        </>
      }
    />
  )
}

export default BaseNavNoty
