import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loginVisible: false,
  loginInfo: null,
  planInfo: null,
  needToLogin: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginVisible(state, action) {
      state.loginVisible = action.payload
    },
    setLoginInfo(state, action) {
      state.loginInfo = action.payload
    },
    setCsrfToken(state, action) {
      state.loginInfo.csrf_token = action.payload
    },
    setNeedToLogin(state, action) {
      state.needToLogin = action.payload
    },
    setPlanInfo(state, action) {
      state.planInfo = action.payload
    },
  },
})

export const {
  setLoginVisible,
  setLoginInfo,
  setCsrfToken,
  setNeedToLogin,
  setPlanInfo,
} = authSlice.actions

export default authSlice.reducer
