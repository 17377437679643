import { useMemo } from 'react'
import MapFilterItem from './MapFilterItem'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'

const MapFilterBuildingSelector = ({
  togglePropertyType,
  value,
  options,
  title = '건물 종류',
}) => {
  const PropertyTypeSelectItem = ({
    value,
    children,
    togglePropertyType,
    color,
    filterItem,
  }) => {
    const computeActive = (filterItem) => {
      if (value === 'all' && filterItem.length === 0) {
        return true
      }

      if (filterItem?.find((item) => item === value)) {
        return true
      }

      return false
    }

    const active = useMemo(
      () => computeActive(filterItem),
      [filterItem],
    )

    return (
      <StyledButtonWrap>
        <StyledButton
          className={active && 'active'}
          onClick={() => togglePropertyType(value)}
        >
          <ButtonText>{children}</ButtonText>
          {color && <ButtonColor backgroundcolor={color || ''} />}
        </StyledButton>
      </StyledButtonWrap>
    )
  }

  const colors = (value) => {
    const result = {
      토지: theme.colors.yellow[200],
      단독주택: '#ee553d',
      단독: '#ee553d',
      다가구주택: '#247bfc',
      다가구: '#247bfc',
      다세대주택: '#5c76ff',
      연립주택: '#5c76ff',
      연립다세대: '#5c76ff',
      아파트: '#40a4ff',
      오피스텔: '#7c42f8',
      '상가·사무실': '#e84cb9',
      상업업무용: '#e84cb9',
      숙박: '#ca5db3',
      공장: theme.colors.gray[900],
      농업: '#60C16F',
      창고: theme.colors.gray[600],
      공장창고: theme.colors.gray[600],
      기타: theme.colors.gray[600],
    }

    return result[value]
  }

  return (
    <MapFilterItem
      title={title}
      // titleValue={getSelectedItemText(value)}
    >
      <PropertySelect>
        <PropertyTypeSelectItem
          value="all"
          togglePropertyType={togglePropertyType}
          filterItem={value}
        >
          전체
        </PropertyTypeSelectItem>
        {options.map((building_type, i) => {
          return (
            <PropertyTypeSelectItem
              key={i}
              value={building_type}
              togglePropertyType={togglePropertyType}
              filterItem={value}
              color={() => colors(building_type)}
            >
              {building_type}
            </PropertyTypeSelectItem>
          )
        })}
      </PropertySelect>
    </MapFilterItem>
  )
}

const PropertySelect = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px;
`

const StyledButtonWrap = styled.div`
  max-width: 33.3333%;
  flex-basis: 33.3333%;
  padding: 4px;
`

const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 8px 8px 14px;
  margin: 0;

  color: ${theme.colors.gray[600]};
  font-size: 13px;
  line-height: 1;

  background-color: ${theme.colors.base.white};
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: ${theme.borderRadius[1]};

  outline: none;
  appearance: none;

  & > i {
    margin-right: 8px;
  }

  &.active {
    font-weight: 700;
    color: ${theme.colors.primary[500]};
    background-color: ${theme.colors.primary[50]};
    border-color: ${theme.colors.primary[100]};
  }
`

const ButtonText = styled.div`
  flex: 1;
  text-align: left;
`
const ButtonColor = styled.div`
  width: 12px;
  height: 12px;

  background-color: ${({ backgroundcolor }) =>
    backgroundcolor && backgroundcolor};
  border-radius: ${theme.borderRadius[0]};
`
export default MapFilterBuildingSelector
