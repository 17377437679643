import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: 34px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  ${({ noborderbottom }) => noborderbottom && `border-bottom: none;`}
`

export const Table = styled.div`
  font-size: 14px;
  font-weight: 400;
  border: 1px solid ${theme.colors.gray[200]};
  ${({ mb }) => mb && `margin-bottom: 10px;`}

  ${({ hasMargin }) => hasMargin && `margin-bottom: 24px;`}
`

export const TableWrapper = styled.div`
  padding-bottom: 40px;
  position: relative;
`

export const Title = styled.div`
  line-height: 100%;
  font-weight: 500;
`
export const TableTitle = styled(Title)`
  font-size: 16px;
  margin-bottom: 12px;
  line-height: 120%;
  ${({ simple }) =>
    simple == 'Y' &&
    `
    margin-bottom: 40px;
  `}
`

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${theme.colors.gray[50]};
  min-width: 127px;
  width: 127px;
  line-height: 130%;
  border-right: 1px solid ${theme.colors.gray[200]};
  padding: 11px 4px;
  word-break: keep-all;
  ${({ half }) => half && `width: 50%;`}
  ${({ sm }) =>
    sm &&
    `
    width: 80px;
    min-width: 80px;
  `};
  ${({ white }) => white && 'background-color: white;'}
  ${({ wide }) => wide && `width: 100%;`}

  ${media.mediumS`
    min-width: 80px;
    width: 80px;
    padding-left: 4px;
    padding-right: 4px;
    word-break: break-word;

    ${({ sm }) =>
      sm &&
      `
      min-width: 70px;
      width: 70px;
    `};
  `}
`

export const TableValue = styled.div`
  padding: 11px 12px;
  word-break: keep-all;
  line-height: 130%;
  white-space: pre-wrap;
  margin: auto 0;

  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ half }) => half && `width: 50%;`}
  ${({ wide }) => wide && `width: 100%;`}
  ${({ borderright }) =>
    borderright &&
    ` border-right: 1px solid ${theme.colors.gray[200]};`};
  ${({ gray }) => gray && `background-color: ${theme.colors.gray[50]};`}

  & > img {
    width: 100%;
    height: 100%;
  }

  ${media.mediumS`
    padding: 8px;
    width: calc(100% - 80px);
    position: relative;
  `}
`

export const SelectLabel = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    line-height: 100%;
  }

  & > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    padding-top: 12px;
    color: ${theme.colors.gray[600]};
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};

  .basic-single {
    width: 100%;
    font-weight: 500 !important;

    .select__single-value {
      white-space: pre-line;
    }

    .select__control {
      padding: 10px 4px;
    }

    .select__control,
    .select__value-container {
      height: auto;
      background-color: transparent;
    }
  }
`

export const SelectBottomHr = styled.hr`
  width: 100%;
  height: 1px;
  border: 0;
  background-color: ${theme.colors.gray[200]};
  margin-top: 0;
  margin-bottom: 28px;
`
