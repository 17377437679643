import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 네이버 아이디 cNaverId
 */
const NoteAdColumnNaverId = ({ isDisabled, half }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onUpdate } = useAdUpdate()
  const verificationType = watch('verification.type')
  const ownerType = watch('verification.ownerType')

  if (verificationType == 'O' || verificationType == 'M') return null

  return (
    <ColumnSet half={half}>
      <ColumnTitle>네이버 아이디</ColumnTitle>
      <ColumnVal direction="column">
        <div>
          <InputText
            width={InputWidth}
            type="text"
            placeholder="소유자 아이디"
            {...register('verification.cNaverId')}
            onBlur={(e) => {
              onUpdate('verification.cNaverId', e.target.value)
            }}
            disabled={isDisabled}
          />
        </div>
        <FormDesc>
          <li>전화번호가 유선번호일 때는 필수입니다.</li>
          {verificationType == 'N' && (
            <li>
              소유자(의뢰인)명, 핸드폰번호로 인증실패시에 후순위로 사용
            </li>
          )}
        </FormDesc>

        <ValidateWarn>
          {errors?.verification?.cNaverId?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default NoteAdColumnNaverId
