import SummaryBannerHasBuilding from 'components/summary/SummaryBanner/SummaryBannerHasBuilding'
import SummaryBannerNotHasBuilding from 'components/summary/SummaryBanner/SummaryBannerNotHasBuilding'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/pagination/pagination.scss' // Pagination module
import styled from 'styled-components'

const SummaryBannerContainer = () => {
  return (
    <StyledSwiper pagination={true} modules={[Pagination]}>
      <SwiperSlide>
        <SummaryBannerHasBuilding />
      </SwiperSlide>
      <SwiperSlide>
        <SummaryBannerNotHasBuilding />
      </SwiperSlide>
    </StyledSwiper>
  )
}

const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
    bottom: -3px;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 2px !important;
  }
`

export default SummaryBannerContainer
