import { message } from 'antd'
import { requestBillingInfo } from 'lib/api/notev2/paymentApi'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { numberWithCommas } from 'utils'

/**
 * 결제관리 메인 왼쪽 블럭 요금제 부분 정보
 */
const NotePaymentInfoContainer = ({ children }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const history = useHistory()
  const [billingResult, setBillingResult] = useState(null)
  const [onRequest, , dataBillingInfo] = useAxios(requestBillingInfo)
  const { hasPlan } = useNoteHasPlan()

  const { isAllInOne, isEmp, isBoss } = useIsAllInOne()

  /**
   * 요금제 변경 페이지 이동
   */
  const onClickChangePayment = () => {
    history.push('/note/v2/payment/plan')
  }

  /**
   * 카드 변경하기
   */
  const handleChangeCard = () => {
    // if (!hasPlan) {
    //   message.error('구독중인 요금제가 없습니다.')
    //   return
    // }

    const param = {
      order_name: planInfo?.payment_name,
      amount: planInfo?.price,
      type: '카드변경',
    }

    if (isAllInOne) {
      //올인원 요금제일때 추가 파라미터 세팅
      onRequest(
        Object.assign({}, param, {
          channel_srl: planInfo?.channel_srl,
          month: planInfo?.months,
        }),
      )
    } else {
      onRequest(param)
    }
  }

  /**
   * 카드변경 API 반환값이 돌아오면 카드등록창 띄우기 위해 정보 세팅
   */
  useEffect(() => {
    if (!dataBillingInfo) return

    setBillingResult(dataBillingInfo?.result)
  }, [dataBillingInfo])

  /**
   * 멤버 관리로 이동
   */
  const onClickMemberManage = () => {
    history.push('/note/v2/memberMng')
  }

  return children({
    billingResult,
    isAllInOne,
    isEmp,
    isBoss,
    plan: {
      price: `￦${numberWithCommas(planInfo?.price || 0)}`,
      plan_name: planInfo?.plan_srl ? planInfo?.payment_name : '프리',
      is_free: planInfo?.payment_name == '프리',
      deunggibu_cnt: planInfo?.default_count || 0,
      has_billingkey: planInfo?.has_billingkey,
      is_expected_cancel: planInfo?.is_expected_cancel,
      card_info: planInfo?.extra_vars?.card || '',
      has_error: planInfo?.error,
      next_payment_date: planInfo?.next_payment_date || '',
      default_count: planInfo?.default_count || 0,
      allinone_member_count: planInfo?.allinone_member_count || 0,
      regdate: planInfo?.regdate || '',
      ceo_company_name: planInfo?.ceo_company_name || '',
      remain_count: planInfo?.remain_count || 0,
      months: planInfo?.months,
      start_date: planInfo?.start_date || '',
    },
    onClickChangePayment,
    handleChangeCard,
    setBillingResult,
    onClickMemberManage,
  })
}

export default NotePaymentInfoContainer
