import styled from 'styled-components'
import logoSize from 'assets/images/logo/jmap-sizereview-footer-logo.png'
import logoNohu from 'assets/images/logo/jmap-nohu-footer-logo.png'
import theme from 'lib/styles/theme'
import { getJootekServiceURL } from 'utils'
import moment from 'moment'

const StyledFooter = styled.div`
  padding: 20px;

  color: ${theme.colors.gray[500]};
  font-size: 12px;
  line-height: 1.5;
  background-color: ${theme.colors.gray[100]};
`
const StyledFooterText = styled.div`
  margin-bottom: 24px;
`

const StyledLogo = styled.img`
  user-select: none;
  /* width: 68px; */
  height: 16px;
  margin-bottom: 14px;
`

const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

const StyledMenuLink = styled.a`
  color: ${theme.colors.gray[500]};
  font-size: 12px;
  font-weight: 700;

  &.strong {
    color: ${theme.colors.gray[600]};
  }

  &:hover {
    color: ${theme.colors.gray[600]};
  }
`

const StyledMenuDivider = styled.div`
  margin: 0 8px;
  height: 12px;
  border-right: 1px solid ${theme.colors.gray[300]};
`

const SummarySizeReviewFooter = ({ type }) => {
  const thisYear = moment().format('YYYY')
  return (
    <StyledFooter>
      <StyledLogo
        src={type == 'size' ? logoSize : logoNohu}
        alt="JOOTEK 지도"
      />

      <StyledFooterText>
        (주)주택의{' '}
        {type == 'size' ? '수익성분석' : '노후주택 대수선 계획'}에서
        제공되는 정보는 법적 효력이 없으며, 조례 해석 및 데이터 분석을
        통해 계산된 결과입니다. 따라서 어떠한 경우에도 본 자료가
        책임소재에 대한 증빙자료로써 활용될 수 없습니다. 이는 서비스
        개발 시점 현행 법규를 기준으로 개발되었으며, 실제 허가 가능한
        건축의 형태로 보장하는 것은 아닙니다. 실제 해당 지자체의 법규
        해석 및 상황에 따라 건축 가능한 규모 및 건축 가능 여부가 달라질
        수 있습니다 정확한 건축 규모 검토를 위해서는 건축사·시공사 등
        건축 전문가와 상담 하세요.
      </StyledFooterText>

      <StyledMenu>
        <StyledMenuLink href={getJootekServiceURL('/terms')}>
          이용약관
        </StyledMenuLink>
        <StyledMenuDivider />
        <StyledMenuLink
          href={getJootekServiceURL('/privacy')}
          className="strong"
        >
          개인정보취급방침
        </StyledMenuLink>
        <StyledMenuDivider />
        <StyledMenuLink
          href="https://forms.gle/mp6oU3PDaJqDfM2N7"
          target="_blank"
        >
          불편신고
        </StyledMenuLink>
      </StyledMenu>

      <StyledFooterText>© {thisYear} JOOTEK INC.</StyledFooterText>
    </StyledFooter>
  )
}

export default SummarySizeReviewFooter
