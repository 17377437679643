import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 주택부동산
 * 1뎁스 헤더 영역
 * @param address 현재 위치 주소(ㅇㅇ동)
 */
const BdsContentHeader = ({ address }) => {
  const list = useSelector((state) => state.bds.list)
  return (
    <Header>
      <Location>{address}</Location>
      <Count>
        전체
        <CountNumber>{list.length}</CountNumber>건
      </Count>
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`

const Location = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
`

const Count = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`

const CountNumber = styled.span`
  margin-left: 4px;
  color: ${theme.colors.gray[900]};
  font-weight: 600;
`

export default BdsContentHeader
