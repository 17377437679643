import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { MaxWidthWrapper } from 'components/jmapnotev2/style/NoteStyle'
import NoteMainWrapper from 'components/jmapnotev2/style/NoteMainWrapper'
import NotePaymentAuthContent from './NotePaymentAuthContent'
import zIndex from 'lib/styles/zIndex'

/**
 * 본인인증이 필요해요! 페이지(?)
 * @param onClose 이전 화면으로 가기 func
 * @param callPaymentModal 결제 function
 * @param month 결제 월 수
 * @param price 결제 금액
 * @returns
 */
const NotePaymentAuth = ({
  onClose,
  callPaymentModal,
  month,
  price,
}) => {
  return (
    <StyledNoteMainWrapper gray single singleM>
      <Scrollbars autoHide>
        <StyledMaxWidthWrapper>
          <NotePaymentAuthContent
            paying
            widthLimit
            onClose={onClose}
            callPaymentModal={callPaymentModal}
            month={month}
            price={price}
          />
        </StyledMaxWidthWrapper>
      </Scrollbars>
    </StyledNoteMainWrapper>
  )
}

const StyledNoteMainWrapper = styled(NoteMainWrapper)`
  height: 100%;
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  display: flex;
  background-color: ${theme.colors.base.white};
  padding: 60px 40px 100px 40px;
  margin: 0 auto;
  height: auto;
  min-height: 100%;

  ${media.mediumM`
    width: 100%;
    padding: 60px 20px 100px 20px;
  `}
`

export default NotePaymentAuth
