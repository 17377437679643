import { setSelectedAdSrl } from 'modules/bds'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const BdsContentListContainer = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  /**
   * 매물 상세
   */
  const onClick = (id) => {
    dispatch(setSelectedAdSrl(id))
    history.push(`/bds/${id}`)
  }

  return children({
    onClick,
  })
}

export default BdsContentListContainer
