import axios from 'axios'

export const getAddresses = async (
  { _rx_csrf_token, channelSrl, folderSrl, noteSort },
  options,
) =>
  await axios.post(
    '/jmapnote/api/address/getAddresses',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      noteSort: noteSort,
    },
    options,
  )

export const getMarkers = async (
  { swlng, nelng, swlat, nelat, level },
  options,
) =>
  await axios.get(
    '/jmapnote/api/address/getNoteMarkers',
    {
      swlng,
      nelng,
      swlat,
      nelat,
      level,
    },
    options,
  )

export const saveAddress = async (
  { _rx_csrf_token, channelSrl, folderSrl, addressId },
  options,
) =>
  await axios.post(
    '/jmapnote/api/address/save',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_id: addressId,
    },
    options,
  )

export const removeAddress = async (
  { _rx_csrf_token, channelSrl, folderSrl, addressSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/address/remove',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
    },
    options,
  )

export const moveAddress = async (
  {
    _rx_csrf_token,
    toChannelSrl,
    fromChannelSrl,
    toFolderSrl,
    fromFolderSrl,
    addressSrl,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/address/move',
    {
      _rx_csrf_token,
      to_channel_srl: toChannelSrl,
      from_channel_srl: fromChannelSrl,
      to_folder_srl: toFolderSrl,
      from_folder_srl: fromFolderSrl,
      address_srl: addressSrl,
    },
    options,
  )

export const copyAddress = async (
  {
    _rx_csrf_token,
    toChannelSrl,
    fromChannelSrl,
    toFolderSrl,
    fromFolderSrl,
    addressSrl,
    noteSrl,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/address/copy',
    {
      _rx_csrf_token,
      to_channel_srl: toChannelSrl,
      from_channel_srl: fromChannelSrl,
      to_folder_srl: toFolderSrl,
      from_folder_srl: fromFolderSrl,
      address_srl: addressSrl,
      note_srl: noteSrl,
    },
    options,
  )

export const sortAddress = async (
  { _rx_csrf_token, channelSrl, folderSrl, addressSrls },
  options,
) =>
  await axios.post(
    '/jmapnote/api/address/sort',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srls: addressSrls,
    },
    options,
  )

export const modifyAddress = async (
  {
    _rx_csrf_token,
    channelSrl,
    folderSrl,
    addressSrl,
    addressId,
    alias,
    property_type,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/address/modifyMeta',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      address_srl: addressSrl,
      address_id: addressId,
      alias,
      property_type,
    },
    options,
  )
