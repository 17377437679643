import BdsContentListContainer from 'containers/bds/BdsContentListContainer'
import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { getArea, getPrice } from 'lib/utils/bds'
import moment from 'moment'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import { getFormatedArea } from 'utils'

/**
 * 주택 부동산
 * 목록
 * @param list 매물 목록
 */
const BdsContentList = ({ list }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const match = useRouteMatch('/bds/:id')
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  return (
    <BdsContentListContainer>
      {({ onClick }) => {
        return (
          <Wrapper>
            {list.map(
              (
                {
                  regdate,
                  member_srl,
                  building_name,
                  address_jibun,
                  property_type,
                  image,
                  contract_type,
                  sale_price,
                  rent,
                  deposit,
                  jeonse_deposit,
                  direction,
                  feature,
                  floor1,
                  floor2,
                  land_area,
                  exclusive_area,
                  contract_area,
                  alias,
                  ad_srl,
                  realtor,
                },
                i,
              ) => {
                const area = getArea({
                  property_type,
                  land_area,
                  exclusive_area,
                  contract_area,
                })
                return (
                  <Item
                    active={
                      match?.isExact && match?.params?.id == ad_srl
                    }
                    onClick={() => onClick(ad_srl)}
                    key={`bds_list_${i}`}
                  >
                    <BadgeWrapper>
                      <Regdate>
                        등록{' '}
                        {moment(regdate, 'YYYYMMDDHHmmss').format(
                          'YY.MM.DD',
                        )}
                      </Regdate>
                      {member_srl == loginInfo.member_srl && (
                        <MyNote>내매물</MyNote>
                      )}
                    </BadgeWrapper>
                    <Name>
                      {building_name || alias || address_jibun}
                    </Name>
                    {contract_type ? (
                      <Price>
                        <PriceTag type={contract_type}>
                          {contract_type}
                        </PriceTag>
                        {getPrice({
                          contract_type,
                          sale_price,
                          rent,
                          deposit,
                          jeonse_deposit,
                        })}
                      </Price>
                    ) : (
                      <Price>가격 정보 없음</Price>
                    )}
                    <Desc>
                      {property_type && (
                        <DescItem>{property_type}</DescItem>
                      )}
                      {area && (
                        <DescItem>
                          {getFormatedArea(area, isSquareMeter)}
                        </DescItem>
                      )}
                      {(floor1 || floor2) && (
                        <DescItem>
                          {floor1 ? <>{floor1}층</> : <>&ndash;</>}
                          {floor2 ? `/${floor2}층` : ''}
                        </DescItem>
                      )}
                      {direction && <DescItem>{direction}</DescItem>}
                    </Desc>
                    <Company>
                      {realtor?.rname || <>상호명 없음</>}
                    </Company>
                    {feature && <Memo>{feature}</Memo>}
                    {image && <Img src={image?.thumbnail} />}
                  </Item>
                )
              },
            )}
          </Wrapper>
        )
      }}
    </BdsContentListContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 -20px;
  padding: 24px 20px;
  border-bottom: 1px solid ${theme.colors.gray[200]};

  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.gray[50]};
  `}
`

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 6px;
  font-size: 19px;
  font-weight: 700;
  line-height: 130%;
`

const PriceTag = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;

  ${({ type }) => {
    switch (type) {
      case '매매':
        return `
          color: ${theme.colors.summary.rp.blue};
        `
      case '전세':
        return `
          color: #1C9C40;
        `
      case '월세':
        return `
            color: ${theme.colors.summary.rp.red};
        `
    }
  }}
`

const BadgeWrapper = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: 8px;
`

const Regdate = styled.div`
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[500]};
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 4px;
`

const MyNote = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.purple[100]};
  background-color: ${theme.colors.purple[100]};
  color: ${theme.colors.purple[500]};
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
`

const DescItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`

const Desc = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  ${DescItem} {
    &:first-child {
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        display: inline-block;
        height: 11px;
        width: 1px;
        background-color: #e4e4e4;
        margin-left: 5px;
      }
    }
  }
`

const Company = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #484d56;
`

const Memo = styled.div`
  width: 100%;
  padding: 6px 10px;
  background-color: #f7f8fa;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
  color: #484d56;
`

const Img = styled.img`
  position: absolute;
  top: 30px;
  right: 20px;
  width: 84px;
  height: 84px;

  border-radius: 4px;
`

export default BdsContentList
