import { useCallback, useState } from 'react'

/**
 * 더보기 동작
 */
export default function useViewMore(
  defalutMoreCnt = 3,
  increaseCnt = 5,
) {
  const [viewCnt, setViewCnt] = useState(defalutMoreCnt)
  const [viewMore, setViewMore] = useState(false)
  const [isOverflow, setIsOverflow] = useState(false)

  /**
   * 더보기 상태로 만들기
   */
  const updateViewMore = useCallback(() => {
    setViewMore(true)
    setIsOverflow(false)
  }, [])

  /**
   * 접기 상태로 만들기
   */
  const updateViewOverFlow = useCallback(
    (data) => {
      if (!data) return

      if (viewCnt >= data.length) {
        setViewMore(false)
        setIsOverflow(true)
      }
    },
    [viewCnt],
  )

  /**
   * 보여지는 개수 초기화
   */
  const resetViewCnt = useCallback(() => {
    setViewCnt(defalutMoreCnt)
    setIsOverflow(false)
  }, [])

  /**
   * 더보기
   */
  const onClickMore = useCallback(() => {
    setViewCnt((prev) => prev + increaseCnt)
  }, [])

  /**
   * 접기
   */
  const onClickFold = () => {
    resetViewCnt()
    updateViewMore()
  }

  return {
    viewCnt,
    viewMore,
    isOverflow,
    setViewMore,
    updateViewOverFlow,
    updateViewMore,
    resetViewCnt,
    onClickMore,
    onClickFold,
  }
}
