import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { AccountItemRow, AccountLabel } from '../account/NoteAccount'
import NoteAccountUserName from '../account/NoteAccountUserName'
import { FormProvider } from 'react-hook-form'
import NoteAccountAddress from '../account/NoteAccountAddress'
import NoteAccountAddressDetail from '../account/NoteAccountAddressDetail'
import NoteAccountRealtorPosition from '../account/NoteAccountRealtorPosition'
import NoteAccountRealtorNumber from '../account/NoteAccountRealtorNumber'
import NoteAccountCompanyName from '../account/NoteAccountCompanyName'
import NotePaymentAuthFormContainer from 'containers/notev2/NotePaymentAuthFormContainer'
import NotePaymentAuthFormButtons from './NotePaymentAuthFormButtons'
import NoteAccountCompanyNumber from '../account/NoteAccountCompanyNumber'
import NoteAccountCompanyCeo from '../account/NoteAccountCompanyCeo'
import NoteAccountRealtorCompanyPhone from '../account/NoteAccountRealtorCompanyPhone'

/**
 * 본인인증 폼
 * @param cancelAuth 이전 화면으로 가기(닫기)
 * @param paying 올인원 결제중 여부
 * @param callPaymentModal 결제 function
 * @param month 결제 월 수
 * @param price 결제 금액
 *
 */
const NotePaymentAuthForm = ({
  cancelAuth,
  paying,
  widthLimit,
  callPaymentModal,
  month,
  price,
  onClose,
}) => {
  return (
    <NotePaymentAuthFormContainer
      paying={paying}
      callPaymentModal={callPaymentModal}
      month={month}
      price={price}
      cancelAuth={cancelAuth}
      onClose={onClose}
    >
      {({
        onSubmit,
        methods,
        setSearchedAddrCode,
        relatorNumberOptions,
        loadingSpinner,
      }) => {
        return (
          <>
            <Wrapper limit={widthLimit}>
              <Title>본인인증 정보</Title>
              <FormProvider {...methods}>
                <form onSubmit={onSubmit} method="POST">
                  <AccountItemRow>
                    <AccountLabel>이름</AccountLabel>
                    <NoteAccountUserName />
                  </AccountItemRow>
                  <AccountItemRow flexstart>
                    <AccountLabel required="Y">
                      회사 소재지
                    </AccountLabel>
                    <NoteAccountAddress
                      setSearchedAddrCode={setSearchedAddrCode}
                      setRealtorMessage={
                        relatorNumberOptions.setRealtorMessage
                      }
                      setIsValidRealtorNumber={
                        relatorNumberOptions.setIsValidRealtorNumber
                      }
                    />
                  </AccountItemRow>
                  <AccountItemRow>
                    <AccountLabel>상세주소</AccountLabel>
                    <NoteAccountAddressDetail />
                  </AccountItemRow>
                  <AccountItemRow>
                    <AccountLabel required="Y">상호</AccountLabel>
                    <NoteAccountCompanyName />
                  </AccountItemRow>
                  <AccountItemRow>
                    <AccountLabel required="Y">
                      대표자 성명
                    </AccountLabel>
                    <NoteAccountCompanyCeo />
                  </AccountItemRow>
                  <AccountItemRow>
                    <AccountLabel required="Y">
                      중개업등록번호
                    </AccountLabel>
                    <NoteAccountRealtorNumber
                      canEdit
                      {...relatorNumberOptions}
                    />
                  </AccountItemRow>
                  <AccountItemRow>
                    <AccountLabel required="Y">
                      사업자등록번호
                    </AccountLabel>
                    <NoteAccountCompanyNumber />
                  </AccountItemRow>
                  <AccountItemRow>
                    <AccountLabel required="Y">직위</AccountLabel>
                    <NoteAccountRealtorPosition canEdit />
                  </AccountItemRow>
                  <AccountItemRow>
                    <AccountLabel required="Y">
                      사무실 전화번호
                    </AccountLabel>
                    <NoteAccountRealtorCompanyPhone canEdit />
                  </AccountItemRow>
                  <NotePaymentAuthFormButtons onClose={cancelAuth} />
                </form>
              </FormProvider>
            </Wrapper>
            {loadingSpinner}
          </>
        )
      }}
    </NotePaymentAuthFormContainer>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 0 12px;
  background-color: ${theme.colors.base.white};
  border-radius: 12px;
  max-width: 100%;

  ${({ limit }) =>
    limit &&
    `
    width: 590px;
    border: 1px solid ${theme.colors.gray[200]};
    padding: 32px;
  `}

  ${media.mediumS`
  
  ${({ limit }) =>
    limit &&
    `
      padding: 20px 16px;
    `}
  `}
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  margin-bottom: 30px;
`

export default NotePaymentAuthForm
