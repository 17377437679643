import useIsMobile from 'lib/hooks/useIsMobile'
import theme, { media } from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const NoteLeftSideChannel = () => {
  const isMobile = useIsMobile()
  const selectedStatus = useSelector(
    (state) => state.noteAd.status.selectedStatus,
  ) //선택된 상태 값 필터
  return (
    <Wrapper>
      {isMobile && selectedStatus ? (
        <>
          <Title>광고 이력</Title>&gt;
          <Selected>{selectedStatus} </Selected>
        </>
      ) : (
        '광고 이력'
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 20px;
  line-height: 130%;
  font-weight: 700;
  font-size: 18px;

  ${media.mediumS`
    margin-top: 0;
    margin-bottom: 0;
  `}
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  ${media.mediumS`
    color: ${theme.colors.gray[500]};
    margin-top: 0;
    margin-bottom: 0;
  `}
`

const Selected = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.colors.gray[900]};
`

export default NoteLeftSideChannel
