const USER_GBN = window.USER_GBN

const KAKAO_MAPS_API_KEY = '8af13dea33b75cb8be788a51dc078376' // kakao api key
const KAKAO_MAPS_API_URL =
  '//dapi.kakao.com/v2/maps/sdk.js?appkey=' +
  KAKAO_MAPS_API_KEY +
  '&autoload=false&libraries=services'

const API_HOST = ''

const main_url = 'https://jootek.com/'

const BASE_PATH = '/map'

const version = '20240903.1'

const excelDownloadUrl =
  'https://assets.jootek.com/jmapnote/jmapnote_template_210712.xlsx'
const excelDownloadUrlVer2 =
  'https://assets.jootek.com/jmapnote/jmapnote_template_231020.xlsx'

const baseCenterPoint = [36.51798643389395, 127.2365939069053]

/**
 * 내부 테스트를 위해 새로운 기능에 접근 가능한 그룹
 */
const accessibleGroups = ['admin', 'staff', 'tester']
const accessibleStaffGroups = ['admin', 'staff']

/**
 * 드래그 가능한 Item type
 */
const ItemTypes = {
  favorite: 'favorite',
  noteFolder: 'noteFolder',
}

const landTypeList = {
  대: '대',
  전: '전',
  답: '답',
  임야: '임야',
  공장: '공장용지',
  창고: '창고용지',
  주차장: '주차장',
  주유소: '주유소용지',
  과수원: '과수원',
  양어장: '양어장',
  목장: '목장용지',
  광천지: '광천지',
  학교: '학교용지',
  체육: '체육용지',
  수도: '수도용지',
  철도: '철도용지',
  종교: '종교용지',
  제방: '제방',
  // 도로: '도로',
  // 하천: '하천',
  구거: '구거',
  유지: '유지',
  염전: '염전',
  공원: '공원',
  유원지: '유원지',
  사적지: '사적지',
  잡종지: '잡종지',
  묘지: '묘지',
}

const landTypeListForNote = [
  '토지',
  '답',
  '전',
  '임야',
  '잡종지',
  '과수원',
  '구거',
  '유지',
]

const useAreaList = {
  '1종전용': '제1종전용주거지역',
  '2종전용': '제2종전용주거지역',
  '1종일반': '제1종일반주거지역',
  '2종일반': '제2종일반주거지역',
  '3종일반': '제3종일반주거지역',
  준주거: '준주거지역',
  중심상업: '중심상업지역',
  일반상업: '일반상업지역',
  근린상업: '근린상업지역',
  유통상업: '유통상업지역',
  보전관리: '보전관리지역',
  생산관리: '생산관리지역',
  계획관리: '계획관리지역',
  전용공업: '전용공업지역',
  일반공업: '일반공업지역',
  준공업: '준공업지역',
  보전녹지: '보전녹지지역',
  생산녹지: '생산녹지지역',
  자연녹지: '자연녹지지역',
  농림지역: '농림지역',
  환경보전: '자연환경보전지역',
  개발제한: '개발제한구역',
}

const floorCntList = [
  '1층',
  '2층',
  '3층',
  '4층',
  '5층 ~',
  '10층 ~',
  '15층 ~',
  '20층 ~',
  '25층 ~',
  '30층 ~',
  '40층 ~',
  '50층 ~',
]

export {
  USER_GBN,
  KAKAO_MAPS_API_KEY,
  KAKAO_MAPS_API_URL,
  main_url,
  API_HOST,
  BASE_PATH,
  version,
  accessibleGroups,
  accessibleStaffGroups,
  ItemTypes,
  landTypeList,
  landTypeListForNote,
  useAreaList,
  excelDownloadUrl,
  excelDownloadUrlVer2,
  floorCntList,
  baseCenterPoint,
}
