import { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { getFormatedAreaSecondDigit, getFormatedAreaRound } from 'utils'
import { Tooltip } from 'antd'
import {
  HelpCardHeader,
  Title,
  Price,
  HelpIcon,
  CustomUl,
} from './SummarySizeReviewStyle'
import useIsMobile from 'lib/hooks/useIsMobile'
import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'
import { CardHeader, CardBody, Modal, Card } from 'components/design'
import styled from 'styled-components'
import { getAddressName } from 'lib/utils'

const AreaInfoWrap = styled.div`
  flex-basis: ${({ size }) => (size === 100 ? `100` : `50`)}%;
`

const AreaInfoTitle = styled.div`
  display: flex;
  align-items: baseline;
  color: #868e96;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${theme.colors.gray[500]};

  line-height: 1.8;
  margin-bottom: 2px;

  & > div {
    margin-left: 8.5px;
    font-size: 0.75rem;
  }
`

const AreaInfoContent = styled.div`
  color: #212529;
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.8;

  span:nth-of-type(1) {
    &:after {
      content: ' / ';
    }
  }
`

const ConstructionSizeWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-top: 0.7rem;
    margin-bottom: 0.5rem;
  }
`

const AreaSmallText = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  color: ${theme.colors.gray[500]};

  span:nth-of-type(1) {
    &:after {
      content: ' / ';
    }
  }
`

const SummarySizeReviewArea = ({ data }) => {
  const {
    state,
    state: { isSquareMeter },
  } = useContext(MapContext)

  const land = useSelector((state) => state.summary.summaryInfo.land)
  const isCombination = useSelector(
    (state) => state.summary.isCombination,
  )

  const [isSizeInfoHide, setIsSizeInfoHide] = useState(true)
  const [isTotalFloorAreaHide, setIsTotalFloorAreaHide] = useState(true)
  const [isRealTotalFloorAreaHide, setIsRealTotalFloorAreaHide] =
    useState(true)
  const isMobile = useIsMobile()

  return (
    <>
      <Card>
        <CardHeader>
          <HelpCardHeader>
            <Title>
              {isCombination && state.combinationPolygons.length > 0 ? (
                <Tooltip
                  placement="topLeft"
                  title={
                    isCombination &&
                    state.combinationStandardPolygons.length > 0 &&
                    state.combinationPolygons.length > 0 && (
                      <ul>
                        <li>
                          {state.combinationStandardPolygons[0].address}
                        </li>
                        {state.combinationPolygons.map(
                          ({ address }, addressIndex) => (
                            <li key={addressIndex}>{address}</li>
                          ),
                        )}
                      </ul>
                    )
                  }
                >
                  합필 시
                </Tooltip>
              ) : (
                '여기에는'
              )}
              <Price>
                {getFormatedAreaRound(
                  data?.result.law_info.max_total_floor_area,
                  isSquareMeter,
                )}
              </Price>
              까지 지을 수 있어요
            </Title>
            <HelpIcon onClick={() => setIsSizeInfoHide(false)}>
              <i className="fas fa-info-circle"></i>
            </HelpIcon>
          </HelpCardHeader>
        </CardHeader>
        <CardBody>
          <ConstructionSizeWrap>
            {isMobile && (
              <AreaInfoWrap size={100}>
                <AreaInfoTitle>주소</AreaInfoTitle>
                <AreaInfoContent>
                  {isCombination &&
                  state.combinationStandardPolygons.length > 0 &&
                  state.combinationPolygons.length > 0
                    ? getAddressName(land) +
                      ` 외 ${state.combinationPolygons.length}`
                    : getAddressName(land)}
                </AreaInfoContent>
              </AreaInfoWrap>
            )}

            <AreaInfoWrap>
              <AreaInfoTitle>대지면적</AreaInfoTitle>
              <AreaInfoContent>
                {getFormatedAreaSecondDigit(
                  data.result.buildings[0].land_area,
                  isSquareMeter,
                )}
              </AreaInfoContent>
            </AreaInfoWrap>
            <AreaInfoWrap>
              <AreaInfoTitle>용도지역</AreaInfoTitle>
              <AreaInfoContent>{land.zone_use1}</AreaInfoContent>
            </AreaInfoWrap>
            <AreaInfoWrap>
              <AreaInfoTitle>건축면적</AreaInfoTitle>
              <AreaInfoContent>
                {getFormatedAreaSecondDigit(
                  data.result.buildings[0].construct_area,
                  isSquareMeter,
                )}
                <AreaSmallText>
                  (
                  <span>
                    {data.result.buildings[0].coverage_ratio}%
                  </span>
                  <span>{data.result.law_info.max_cov_ratio}%</span>)
                </AreaSmallText>
              </AreaInfoContent>
            </AreaInfoWrap>
            <AreaInfoWrap>
              <AreaInfoTitle>
                용적률 산정 연면적
                <HelpIcon
                  onClick={() => setIsTotalFloorAreaHide(false)}
                >
                  <i className="fas fa-info-circle"></i>
                </HelpIcon>
              </AreaInfoTitle>
              <AreaInfoContent>
                {getFormatedAreaSecondDigit(
                  data.result.buildings[0].total_floor_area,
                  isSquareMeter,
                )}
                <AreaSmallText>
                  (
                  <span>
                    {data.result.buildings[0].total_floor_ratio}%
                  </span>
                  <span>{data.result.law_info.max_floor_ratio}%</span>)
                </AreaSmallText>
              </AreaInfoContent>
            </AreaInfoWrap>
            <AreaInfoWrap>
              <AreaInfoTitle>
                실제공사면적
                <HelpIcon
                  onClick={() => setIsRealTotalFloorAreaHide(false)}
                >
                  <i className="fas fa-info-circle"></i>
                </HelpIcon>
              </AreaInfoTitle>
              <AreaInfoContent>
                {getFormatedAreaSecondDigit(
                  data.result.buildings[0].real_total_floor_area,
                  isSquareMeter,
                )}
              </AreaInfoContent>
            </AreaInfoWrap>
            <AreaInfoWrap>
              <AreaInfoTitle>층수 / 주차대수</AreaInfoTitle>
              <AreaInfoContent>
                <span>{data.result.buildings[0].floor_count}층</span>
                <span>{data.result.buildings[0].parking_count}대</span>
              </AreaInfoContent>
            </AreaInfoWrap>
          </ConstructionSizeWrap>
        </CardBody>
      </Card>
      <Modal
        title="예상 건축 규모"
        visible={!isSizeInfoHide}
        onOk={() => setIsSizeInfoHide(true)}
        onCancel={() => setIsSizeInfoHide(true)}
        okText="확인"
        cancelText="취소"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        <CustomUl>
          <li>
            법정 건폐율, 용적률은 지자체별 「도시 · 군 계획조례」를
            참고하였습니다.
          </li>
          <li>
            주차대수는 「주차장법 시행령」을 기반으로 하되, 일부 지역은
            지자체별 「부설주차장 설치 기준」을 참고하였습니다.
          </li>
          <li>
            허용 건축물 및 건축물별 최대 층수는 「국토의 계획 및 이용에
            관한 법률 시행령」을 참고하였습니다.
          </li>
          <li>
            이는 서비스 개발 시점 현행 법규를 기준으로 개발되었으며,
            실제 허가 가능한 건축의 형태로 보장하는 것은 아닙니다. 실제
            해당 지자체의 법규 해석 및 상황에 따라 건축 가능한 규모 및
            건축 가능 여부가 달라질 수 있습니다.
          </li>
          <li>
            참고용으로 제공되는 정보이므로 이를 이용함으로 발생하는 어떤
            손실에 대해서도 (주)주택은 책임이 없습니다.
          </li>
        </CustomUl>
      </Modal>
      <Modal
        title="용적률산정연면적이란?"
        visible={!isTotalFloorAreaHide}
        onOk={() => setIsTotalFloorAreaHide(true)}
        onCancel={() => setIsTotalFloorAreaHide(true)}
        okText="확인"
        cancelText="취소"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        <CustomUl>
          <li>
            모든 층의 바닥 넓이의 합에서 용적률 산정시 제외되는 면적을
            뺀 나머지 면적을 의미합니다.
          </li>
          <li>
            용적률 산정시 제외되는 면적에는 지하층, 주차장시설,
            주민공동시설 등이 있습니다.
          </li>
        </CustomUl>
      </Modal>
      <Modal
        title="실제공사면적이란?"
        visible={!isRealTotalFloorAreaHide}
        onOk={() => setIsRealTotalFloorAreaHide(true)}
        onCancel={() => setIsRealTotalFloorAreaHide(true)}
        okText="확인"
        cancelText="취소"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        <CustomUl>
          <li>
            서비스 면적 (발코니 등)을 포함한 모든 층의 면적의 합을
            말합니다.
          </li>
          <li>
            서비스 면적은 용적률 산정시 제외되므로 실제공사면적은 법적
            용적률 면적보다 더 넓게 나올 수 있습니다.
          </li>
        </CustomUl>
      </Modal>
    </>
  )
}

export default SummarySizeReviewArea
