import { Button, Modal } from 'components/design'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 결제 실패시 취소 confirm 모달
 * *
 * @returns
 */
const NotePaymentFailCancelConfirm = ({
  visible = false,
  onOk,
  onCancel,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  return (
    <Modal
      title="결제를 취소 하시겠어요?"
      visible={visible}
      width={450}
      hiddenClose
      footer={
        visible ? (
          <ButtonWrapper>
            <Button onClick={onCancel} color="gray">
              {planInfo?.payment_name} 계속 유지
            </Button>
            <Button onClick={onOk} color="danger">
              결제 취소
            </Button>
          </ButtonWrapper>
        ) : null
      }
    >
      {visible && (
        <>
          프리 요금제로 변경하시면 이미 구입하신 등기부등본은 나중에
          유료 요금제로 다시 변경해야 사용하실 수 있어요.
          <br />
          또한 친구 초대 이벤트 링크를 공유한 경우 더이상 친구 초대
          이벤트 참여로 등기열람권을 지급받을 수 없어요.
        </>
      )}
    </Modal>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 0 16px 24px 20px;
`

export default NotePaymentFailCancelConfirm
