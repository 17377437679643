import NotePaymentInfoContainer from 'containers/notev2/NotePaymentInfoContainer'
import NotePaymentInfoBlockCommon from './NotePaymentInfoBlockCommon'
import BillingPage from './Billing'
import NotePaymentInfoBlockAllInOneMember from './NotePaymentInfoBlockAllInOneMember'
import NotePaymentInfoBlockAllInOneBoss from './NotePaymentInfoBlockAllInOneBoss'
import { ContentTitle } from '../style/NotePaymentStyle'
import styled from 'styled-components'
import { media } from 'lib/styles/theme'

/**
 * 결제관리
 * 요금제 정보 블럭
 */
const NotePaymentInfo = ({ hideButtons }) => {
  return (
    <NotePaymentInfoContainer>
      {({
        billingResult,
        isAllInOne,
        isEmp,
        isBoss,
        plan,
        onClickChangePayment,
        handleChangeCard,
        setBillingResult,
        onClickMemberManage,
      }) => {
        return (
          <Wrapper>
            {/* 올인원 아닌 기본 형태 */}
            {!isAllInOne && (
              <NotePaymentInfoBlockCommon
                hideButtons={hideButtons}
                plan={plan}
                onClickChangePayment={onClickChangePayment}
                handleChangeCard={handleChangeCard}
              />
            )}

            {/* 올인원 대표 */}
            {isBoss && (
              <>
                <ContentTitle>이용 현황</ContentTitle>
                <NotePaymentInfoBlockAllInOneBoss
                  hideButtons={hideButtons}
                  plan={plan}
                  onClickChangePayment={onClickChangePayment}
                  handleChangeCard={handleChangeCard}
                  onClickMemberManage={onClickMemberManage}
                />
              </>
            )}

            {/* 올인원 직원 */}
            {isEmp && (
              <>
                <ContentTitle>이용 현황</ContentTitle>
                <NotePaymentInfoBlockAllInOneMember
                  hideButtons={hideButtons}
                  plan={plan}
                  onClickChangePayment={onClickChangePayment}
                  handleChangeCard={handleChangeCard}
                />
              </>
            )}

            {billingResult && (
              <BillingPage
                customerKey={billingResult.customer_key}
                orderId={billingResult.order_id}
                orderName={`카드변경`}
                type="카드변경"
                setBillingResult={setBillingResult}
              />
            )}
          </Wrapper>
        )
      }}
    </NotePaymentInfoContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${media.mediumM`
    width: 100%;
  `}
`

export default NotePaymentInfo
