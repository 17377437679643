import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

const FloatingButton = ({ children, ...props }) => {
  return (
    <StyledButton full={() => isLayerSmallThanWindow()} {...props}>
      {children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  left: ${theme.base.container}px;
  z-index: 30;

  width: 3rem;
  height: 3rem;

  color: ${theme.colors.base.white};
  font-size: 1rem;
  background-color: ${({ color }) =>
    color == 'blue'
      ? theme.colors.blue[400]
      : theme.colors.primary[400]};
  border-radius: 100%;

  box-shadow: ${theme.shadow['card-2']};

  ${media.mediumM`
    bottom: ${theme.base.bottomMenuHeight + 16}px;
    right: auto;
  `}

  ${media.mediumS`
    bottom: ${theme.base.bottomMenuHeight + 16}px;
    bottom: calc(${
      theme.base.bottomMenuHeight
    }px + env(safe-area-inset-bottom) + 1rem);
    left: auto;
    right: 1rem;
`}
`
export default FloatingButton
