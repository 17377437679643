import styled from 'styled-components'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { deleteProperty, onlyUnique } from 'utils'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import { message } from 'antd'
import priceFormat from 'lib/utils/priceFormat'
import {
  CustomSingleCellInputWrapper,
  CustomSingleCellStyle,
} from 'components/jmapnotev2/style/NoteStyle'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import {
  addEndCursorKeyEvent,
  addSelectAllKeyEvent,
  addStartCursorKeyEvent,
  getPricePerHeight,
} from 'lib/utils/notev2/gridUtil'
import theme from 'lib/styles/theme'
import { NumericFormat } from 'react-number-format'
import { ActiveCellStyle } from 'components/jmapnotev2/style/NoteGridStyle'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  setThreeDepthChannelSrl,
  setThreeDepthAddressSrl,
  setThreeDepthNoteSrl,
} from 'modules/onBoarding'

const CustomPriceCellItem = ({
  name,
  value: pValue,
  isEditing,
  contractType,
  setIsFocusPriceItem,
  node,
  oriContractType,
  nextValueHas,
}) => {
  const fiveStepState = useSelector(
    (state) => state.onBoarding.fiveStepState,
  )
  const fourStepState = useSelector(
    (state) => state.onBoarding.fourStepState,
  )
  const [onRequestModifyNote, isErrorModifyNote] = useModifyNote()

  const wrapperRef = useRef(null)

  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
  } = useCustomSingleCell(node?.data?.status)

  const dispatch = useDispatch()
  const onBlur = useCallback(
    (value) => {
      const filterStateData = deleteProperty(node.data, 'state')
      const contract_type = oriContractType || ''

      if (value && value != 0) {
        const filteredContractType = contract_type
          .split(',')
          .concat(contractType)
          .filter((item) => item)

        const newData = Object.assign(
          {},
          {
            contract_type: filteredContractType
              .filter(onlyUnique)
              .toString(),
            [name]: String(value).replaceAll(',', ''),
          },
        )

        //하우스토리 102동 301호라는 데이터를 임의로 전세 2억을 만들어준다
        const fakeData = Object.assign({}, filterStateData, {
          contract_type: '전세',
        })
        fakeData.rent = '0'
        fakeData.deposit = '0'
        fakeData.sale_price = '0'
        fakeData.jeonse_deposit = '20000'
        dispatch(setThreeDepthChannelSrl(fakeData.channel_srl))
        dispatch(setThreeDepthAddressSrl(fakeData.address_srl))
        dispatch(setThreeDepthNoteSrl(fakeData.note_srl))

        onRequestModifyNote({
          data: node.data,
          updateData: fiveStepState ? fakeData : newData,
          currentRowNode: node,
          presale: name == 'presale_base_price',
        })
      } else {
        //값이 없을때는 삭제
        const filteredContractType = contract_type
          .split(',')
          .filter((item) => item != contractType)

        const newData = Object.assign(
          {},
          {
            contract_type: filteredContractType
              .filter(onlyUnique)
              .toString(),
            [name]: 0,
          },
        )

        onRequestModifyNote({
          data: node.data,
          updateData: newData,
          currentRowNode: node,
          presale: name == 'presale_base_price',
        })
      }

      setIsEditable(false)
    },
    [pValue, node.data, contractType, oriContractType, fiveStepState],
  )

  useEffect(() => {
    if (isErrorModifyNote) {
      message.error('가격 저장 중 오류가 발생했습니다.')
    }
  }, [isErrorModifyNote])

  const onFocus = useCallback(() => {
    setIsFocusPriceItem(true)
  }, [])

  const perHeight = useMemo(
    () => getPricePerHeight(oriContractType, isEditing),
    [oriContractType, isEditing],
  )

  const onKeyDownInput = (e) => {
    addSelectAllKeyEvent(e)
    addStartCursorKeyEvent(e)
    addEndCursorKeyEvent(e)
  }

  useEffect(() => {
    if (fiveStepState && !fourStepState) {
      //다음단계로 넘어가면서 blur처리된다
      onBlur(20000)
    }
  }, [fiveStepState, fourStepState])

  return (
    <Wrapper
      perHeight={perHeight}
      ref={wrapperRef}
      tabIndex={0}
      onDoubleClick={onDoubleClickSingleCell}
      onKeyDown={onKeyDownSingleCell}
      isVisible={
        isEditing ||
        (pValue &&
          pValue != 0 &&
          oriContractType.includes(contractType))
      }
      onFocus={onFocus}
      fullheight={name == 'deposit' || name == 'rent'}
      isDeposit={name == 'deposit'}
      border={nextValueHas}
      name={name}
    >
      {isEditable ? (
        <CustomSingleCellInputWrapper>
          <PriceListItem
            autoFocus={true}
            type="text"
            name={name}
            onBlur={(e) => onBlur(e.target.value)}
            defaultValue={
              oriContractType.includes(contractType)
                ? isEditing
                  ? !pValue || pValue == 0
                    ? ''
                    : pValue
                  : priceFormat(
                      String(pValue).replaceAll(',', '') * 10000,
                      true,
                    ) == '0원'
                  ? ''
                  : priceFormat(
                      String(pValue).replaceAll(',', '') * 10000,
                      true,
                    )
                : ''
            }
            thousandSeparator={true}
            inputMode="decimal"
            onKeyDown={onKeyDownInput}
            onFocus={(e) => {
              if (e.target?.value == 0) {
                e.target?.select()
              }
            }}
          />
          <PriceTag isVisible={isEditing}>만</PriceTag>
        </CustomSingleCellInputWrapper>
      ) : (
        <PriceView isDeposit={name == 'deposit'}>
          {oriContractType.includes(contractType) && (
            <>
              {priceFormat(
                String(pValue).replaceAll(',', '') * 10000,
                true,
              ) == '0원'
                ? ''
                : priceFormat(
                    String(pValue).replaceAll(',', '') * 10000,
                    true,
                  )}
            </>
          )}
        </PriceView>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ perHeight }) => perHeight};
  text-align: center;
  position: absolute;
  opacity: 0;
  bottom: 0;
  background-color: transparent;
  line-height: 100%;
  z-index: -1;
  text-align: center;
  ${({ isVisible }) =>
    isVisible &&
    ` 
      display: flex;
      position: relative; 
      opacity: 1; 
      z-index: 1; 
  `};

  ${({ fullheight }) =>
    fullheight &&
    ` 
      position: relative; 
      width: calc(50% - 2.5px); 
      height: 100%; 
      text-align: left;
    `};

  ${({ isDeposit }) => isDeposit && 'justify-content: flex-end;'};

  ${CustomSingleCellStyle};

  ${({ border }) =>
    border && ` border-bottom: 1px solid ${theme.colors.gray[900]}1A;`};
`

const PriceView = styled.div`
  width: 100%;
  height: auto;
  // margin: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isDeposit }) => isDeposit && 'width: auto;'}
`

const PriceListItem = styled(NumericFormat)`
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 15px;
  font-size: 14px;
  line-height: 100%;
  background-color: transparent;

  ${CustomSingleCellStyle}
  ${ActiveCellStyle}
`

const PriceTag = styled.div`
  display: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  z-index: 1;
  line-height: 100%;
  ${({ isVisible }) => isVisible && `display: block;`}
`

export default CustomPriceCellItem
