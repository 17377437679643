import theme from 'lib/styles/theme'
import React from 'react'
import styled from 'styled-components'

const BdsSearchList = ({ data, visibleSearch, onClickItem }, ref) => {
  return (
    <Dropdown ref={ref}>
      {visibleSearch &&
        data.map((item, i) => {
          return (
            <DropdownItem key={i} onClick={() => onClickItem(item)}>
              <Title>{item?.title}</Title>
              {item?.subTitle && <SubTitle>{item.subTitle}</SubTitle>}
            </DropdownItem>
          )
        })}
    </Dropdown>
  )
}

const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${theme.colors.base.white};
  box-shadow: 0 4px 2px -2px ${theme.colors.gray[100]};

  z-index: 2;
`

const DropdownItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
`
const SubTitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
`

export default React.forwardRef(BdsSearchList)
