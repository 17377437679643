import { Dropdown, message } from 'antd'
import { Confirm, Modal } from 'components/design'
import { copyNote, removeNote } from 'lib/api/note/noteApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import Share from 'components/summary/share/SummaryShare'
import NoteDetailCopy from './NoteDetailCopy'
import { useSelector } from 'react-redux'
import NoteDetailMove from './NoteDetailMove'
import {
  removeClickedMarker,
  removeOpenedMarker,
  resetSpecificOpenedMarker,
} from 'modules/note'
import { useDispatch } from 'react-redux'
import { ReactComponent as MoreIcon } from 'assets/icon/more.svg'

/**
 * 매물 3뎁스 헤더
 * 삭제, 복사, 이동, 공유
 */
const NoteDetailFormModifyControl = ({ onClose, searchFn }) => {
  const dispatch = useDispatch()
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const channels = useSelector((state) => state.channel.channels)
  const [onRequestCopySelf, loading, dataCopySelf, error, reset] =
    useAxios(copyNote)
  const [onRequestDelete, , dataDelete, errorDelete, onReset] =
    useAxios(removeNote, { noErrorMessage: true })
  // 공유 모달창
  const [shareModal, setShareModal] = useState(false)
  // 매물 복제 모달창 활성화 여부
  const [visibleMove, setVisibleMove] = useState(false)
  // 매물 복사 모달창 활성화 여부
  const [visibleCopy, setVisibleCopy] = useState(false)

  const match = useRouteMatch(
    '/note/v2/notes/:channelSrl/:addressSrl/:noteSrl',
  )
  const { watch, getValues } = useFormContext()

  const channelSrl = watch('channel_srl')
  const folderSrl = watch('folder_srl')
  const noteSrl = watch('note_srl')
  const addressSrl = watch('address_srl')
  const encryptedKey = getValues('encrypted_key')
  const addressId = watch('address_id')

  /**
   * 매물 삭제 시 에러메시지 표시
   */
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState('')

  const onClickMenu = (e) => {
    switch (e.key) {
      case '1': //공유
        if (!encryptedKey) {
          return
        }
        setShareModal(true)
        break
      case '2': //이동
        //채널 1개면 이동하기 막음
        if (channels.length == 1) {
          message.info('채널이 하나뿐인 경우 매물 이동을 할 수 없어요')
          return
        }
        setVisibleMove(true)
        break
      case '3': //복사
        //채널 1개면 현재 채널에 복제
        if (channels.length == 1) {
          if (!channelSrl) {
            message.error('매물 복사 중 오류가 발생했습니다')
            return
          }

          onRequestCopySelf({
            toChannelSrl: channelSrl,
            fromChannelSrl: channelSrl,
            addressSrl,
            noteSrl,
          })
          return
        }
        setVisibleCopy(true)
        break
      case '4': //삭제
        // url에도 없고 form context에도 없는 경우
        if (
          (!match?.params?.channelSrl || !match?.params?.noteSrl) &&
          (!channelSrl || !noteSrl)
        ) {
          message.error('잘못된 접근입니다')
          return
        }

        Confirm.info({
          title: '알림',
          content: '매물을 삭제하시겠습니까?',
          onOk: () =>
            onRequestDelete({
              channelSrl: match?.params?.channelSrl || channelSrl,
              addressSrl:
                match?.params?.addressSrl || addressSrl || null,
              noteSrl: match?.params?.noteSrl || noteSrl,
            }),
          okButtonProps: { color: 'danger' },
          okText: '삭제',
        })
        break
    }
  }

  useEffect(() => {
    if (!dataDelete) return

    if (dataDelete.error == 0) {
      message.success('매물이 삭제되었습니다')
      dispatch(resetSpecificOpenedMarker())
      dispatch(removeOpenedMarker(addressSrl))
      dispatch(removeClickedMarker(addressSrl))
      onClose()
    }
  }, [dataDelete])

  /**
   * 매물 삭제 시 에러메시지 표시
   */
  useEffect(() => {
    if (!errorDelete) return

    setIsModalVisible(true)
    setDeleteMessage(errorDelete)
    onReset()
  }, [errorDelete])

  const onOk = () => {
    setIsModalVisible(false)
    setDeleteMessage('')
  }

  useEffect(() => {
    if (!dataCopySelf || !dataCopySelf.result) return

    message.info('매물이 복사됐어요')
    searchFn && searchFn()
  }, [dataCopySelf])

  const items =
    channelInfo?.member_type == 'readonly'
      ? [
          {
            label: '공유',
            key: '1',
            icon: (
              <div>
                <i className="far fa-share-alt"></i>
              </div>
            ),
          },
        ]
      : [
          {
            label: '공유',
            key: '1',
            icon: (
              <div>
                <i className="far fa-share-alt"></i>
              </div>
            ),
          },
          {
            label: '이동',
            key: '2',
            icon: (
              <div>
                <i className="far fa-truck-moving"></i>
              </div>
            ),
          },
          {
            label: '복사',
            key: '3',
            icon: (
              <div>
                <i className="far fa-copy"></i>
              </div>
            ),
          },
          {
            label: '삭제',
            icon: (
              <div>
                <i className="far fa-trash-alt"></i>
              </div>
            ),
            key: '4',
            danger: true,
          },
        ]

  const mapMenu = [
    {
      label: '공유',
      key: '1',
      icon: (
        <div>
          <i className="far fa-share-alt"></i>
        </div>
      ),
    },
    {
      label: '삭제',
      icon: (
        <div>
          <i className="far fa-trash-alt"></i>
        </div>
      ),
      key: '4',
      danger: true,
    },
  ]

  const menuProps = {
    items: match?.isExact ? items : mapMenu,
    onClick: onClickMenu,
  }

  const mapViewFunction = () => {
    history.replace(`/${addressId}`)
  }

  return (
    <Wrapper>
      <Dropdown menu={menuProps} trigger={['click']}>
        <MoreIcon onClick={(e) => e.preventDefault()} />
      </Dropdown>
      <Modal
        title="공유"
        visible={shareModal}
        onOk={() => setShareModal(false)}
        onCancel={() => setShareModal(false)}
        okText="확인"
        cancelText="취소"
        footer={null}
        width={380}
      >
        <Share
          path={`https://${window.location.host}/map/share/${noteSrl}/${encryptedKey}`}
        />
      </Modal>
      {/* 매물 이동 모달창 */}
      <NoteDetailMove
        visible={visibleMove}
        addressSrl={addressSrl}
        folderSlr={folderSrl}
        channelSrl={channelSrl}
        noteSrl={match?.params?.noteSrl || noteSrl}
        setVisible={setVisibleMove}
        onClose={onClose}
        searchFn={searchFn}
      />
      {/* 매물 복사 모달창 */}
      <NoteDetailCopy
        visible={visibleCopy}
        addressSrl={addressSrl}
        folderSlr={folderSrl}
        channelSrl={channelSrl}
        noteSrl={match?.params?.noteSrl || noteSrl}
        setVisible={setVisibleCopy}
        onClose={onClose}
        searchFn={searchFn}
      />
      <Modal
        title="알림"
        visible={isModalVisible}
        onOk={onOk}
        onCancel={() => setIsModalVisible(false)}
        okText="확인"
        cancelText="취소"
        okButtonProps={{ color: 'blue' }}
      >
        <span>{deleteMessage}</span>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  z-index: 1;
  cursor: pointer;
  border-radius: 6px;

  svg {
    height: 100%;
    margin: 0 5px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`

export default NoteDetailFormModifyControl
