import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setFolders } from 'modules/note'
import { getFolders } from 'lib/api/note/folderApi'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useAxios from 'lib/hooks/useAxios'

export default function useFolders() {
  const { channelSrl: matchChannelSrl } = useNoteMatch()
  const folders = useSelector((state) => state.note.folders)
  const dispatch = useDispatch()

  const [onRequest, loading, data, error, reset, source] =
    useAxios(getFolders)

  const _getFolders = useCallback(
    async (
      { channelSrl } = { channelSrl: matchChannelSrl },
      cancelToken,
    ) => {
      await onRequest({ channelSrl }, cancelToken)
    },
    [onRequest, matchChannelSrl],
  )

  useEffect(() => {
    if (!data?.result) return
    // console.log(data.result)
    dispatch(setFolders(data.result))
  }, [data])

  return {
    _getFolders,
    loading,
    data,
    folders,
    reset,
    _getFoldersSource: source,
  }
}
