import { getHoDetail } from 'lib/api/notev2/dongho'
import useAxios from 'lib/hooks/useAxios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 동호관리
 * 호 상세 모달 로직
 */
const NoteDonghoHoDetailContainer = ({ children }) => {
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  const [isVisibleHoDetail, setIsVisibleHoDetail] = useState(false)
  const [onRequest, , data] = useAxios(getHoDetail)

  const [hoData, setHoData] = useState(null)

  const onClickHo = useCallback(
    (e) => {
      const blockTarget = e.target

      if (
        blockTarget &&
        blockTarget.classList.contains('dongho-block')
      ) {
        const buildingDonghoId = blockTarget.getAttribute(
          'data-buildingdonghoid',
        )
        const isEmpty = blockTarget.classList.contains('block-empty')
        if (isEmpty) return
        setIsVisibleHoDetail(true)
        onRequest({
          building_dongho_id: buildingDonghoId,
          jdongho_danji_id: selectedDanji.id,
        })
      }
    },
    [selectedDanji.id],
  )

  const onCloseHo = useCallback(() => {
    setIsVisibleHoDetail(false)
  }, [])

  useEffect(() => {
    if (!data) return

    setHoData(data?.result || null)
  }, [data])

  return children({
    isVisibleHoDetail,
    onClickHo,
    onCloseHo,
    hoData,
  })
}

export default React.memo(NoteDonghoHoDetailContainer)
