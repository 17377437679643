import { Modal } from 'components/design'

/**
 * 네이버 매물 등록 전
 * 유효하지 않은 주소 모달
 * @returns
 */
const NoteDetailAddressRequiredModal = ({
  visible = false,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      title="주소를 확인해 주세요."
      visible={visible}
      onCancel={onCancel}
      width={450}
      onOk={onOk}
      okButtonProps={{ color: 'blue' }}
      cancelHide
      hiddenClose
    >
      주소를 정확히 입력하지 않으면 광고를 등록할 수 없습니다. 광고를
      등록할 주소가 맞는지 다시 한 번 확인해 주세요.
    </Modal>
  )
}

export default NoteDetailAddressRequiredModal
