import { useCallback, useEffect, useRef, useState } from 'react'
import useAxios from 'lib/hooks/useAxios'
import {
  requestDeunggibu,
  requestLandUsePlan,
} from 'lib/api/notev2/contractApi'
import NoteDetailGongbuIssuanceModal from 'components/jmapnotev2/detail/NoteDetailGongbuIssuanceModal'
import { getPaymentInfo, getPlan } from 'lib/api/notev2/paymentApi'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import NoteDetailGongbuPreparingModal from 'components/jmapnotev2/detail/NoteDetailGongbuPreparingModal'
import useDeunggiRepeatedCall from 'lib/hooks/notev2/useDeunggiRepeatedCall'
import { getListing } from 'lib/api/notev2/listApi'
import NoteDetailGongbuEmergencyModal from 'components/jmapnotev2/detail/NoteDetailGongbuEmergencyModal'

/**
 * 매물 3뎁스 공부문서 탭
 * 발급 로직
 */
const NoteDetailGongbuIssuanceContainer = ({ children }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const emergencyAmount = 800
  const emergencyCount = 1

  const { watch, setValue } = useFormContext()
  const note_srl = watch('note_srl')
  const channel_srl = watch('channel_srl')
  const address_jibun = watch('address_jibun')
  const address_id = watch('address_id')
  const dong = watch('dong')
  const ho = watch('ho')
  const property_type = watch('property_type')
  const is_group_building = watch('is_group_building')

  const [onRequestPaymentInfo, , dataPaymentInfo] =
    useAxios(getPaymentInfo) //등기부 구입 요금제 확인

  const [onRequestGetPlan, loadingPlan, dataGetPlan] = useAxios(getPlan)
  const [
    onRequestDeunggibu,
    ,
    dataDeunggibu,
    errorDeunggibu,
    resetDeunggibu,
  ] = useAxios(requestDeunggibu, { resetError: true })
  const [onRequestInfo, , dataInfo] = useAxios(getListing)

  const [isFailModalVisible, setIsFailModalVisible] = useState(false)
  const [customDongHo, setCustomDongHo] = useState('')
  const [customAddress, setCustomAddress] = useState('')

  const [uniqueAddress, setUniqueAddress] = useState(null)
  const [validOnly, setValidOnly] = useState(true)
  const [paymentResult, setPaymentResult] = useState(null) //요금제 정보

  const [subType, setSubType] = useState('building')

  const [isEmergencyModalVisible, setIsEmergencyModalVisible] =
    useState(false) //등기부 등본 충전 모달 visible

  //complete 상태가 없을 때 들어오는곳이기 때문에 그리드 공부문서 셀 데이터 업데이트
  function refreshInfo() {
    onRequestInfo({ channel_srl, note_srl })
  }

  //공부문서 재조회 hook
  const { docs, setHasPendingDoc, searchGongbu, loadingGoungbu } =
    useDeunggiRepeatedCall({
      endCallbackFn: refreshInfo,
      noteSrl: note_srl,
      channelSrl: channel_srl,
    })

  const [isIssuanceModalVisible, setIsIssuanceModalVisible] =
    useState(false)
  const buttonRef = useRef(null)

  const [onRequestLandUsePlan, , dataLandUsePlan] =
    useAxios(requestLandUsePlan)

  useEffect(() => {
    setCustomAddress(address_jibun)
  }, [address_jibun])

  useEffect(() => {
    //리렌더링이 되면서 다시 다른 steps가 올라온다

    // 동호수 input 디폴트값 세팅
    let result = ''
    if (dong) {
      result = dong == '구분없음' ? '' : (dong || '') + ' '
    }
    if (ho) {
      result += ho || ''
    }
    setCustomDongHo(result)
  }, [dong, ho])

  useEffect(() => {
    if (!dataInfo || !dataInfo?.result) return

    setValue('deunggibu_counts', dataInfo.result.deunggibu_counts)
  }, [dataInfo])

  /**
   * 어떤 등기부등본을 발급할까요? 모달
   */
  const onClickDeunggibu = () => {
    setIsIssuanceModalVisible(true)
  }

  /**
   * 토지이용계획원 발급 신청
   */
  const onClickLandUsePlan = () => {
    buttonRef.current.disabled = true

    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.disabled = false
      }
    }, 3000)

    setHasPendingDoc(false)
    onRequestLandUsePlan({
      channel_srl,
      note_srl,
      sub_type: '토지',
      address: address_jibun,
      address_id: address_id,
    })
  }

  useEffect(() => {
    if (!dataLandUsePlan) return

    searchGongbu()
  }, [dataLandUsePlan])

  useEffect(() => {
    if (!dataDeunggibu) return

    setIsIssuanceModalVisible(false)
    setUniqueAddress(null)

    //목록 재조회
    searchGongbu()
  }, [dataDeunggibu])

  /**
   * 등기부등본 발급
   */
  const onClickDeunggibuIssuance = () => {
    //list 재조회 멈춤
    setHasPendingDoc(false)

    //전세 사기 피해자인 경우 바로 등기부 발급 모달 띄움
    if (loginInfo.emergency == 'Y') {
      onRequestPaymentInfo({
        order_name: '등기부등본',
        amount: emergencyAmount,
        count: emergencyCount,
        type: '구입',
      })
    } else {
      //요금제 조회 -> 유효 판단 -> 등기부 발급
      onRequestGetPlan()
    }
  }

  //요금제 조회 완료 후 등기부 발급 할지 말지 결정
  useEffect(() => {
    if (!dataGetPlan) return

    if (!dataGetPlan?.result) {
      setIsFailModalVisible(true)
      return
    }

    onRequestDeunggibu({
      channel_srl,
      note_srl,
      sub_type: subType,
      valid_only: validOnly,
      address: customAddress,
      detail: customDongHo,
      unique_no: uniqueAddress?.UniqueNo || null,
    })
  }, [dataGetPlan])

  useEffect(() => {
    if (!dataPaymentInfo) return

    if (dataPaymentInfo.error != 0) {
      setPaymentResult(null)
      return
    }
    setIsEmergencyModalVisible(true)
    setPaymentResult(dataPaymentInfo?.result)
  }, [dataPaymentInfo])

  const onChangeCustomDongHo = (e) => {
    if (!e.target.value) return
    setCustomDongHo(e.target.value)
  }

  /**
   * 등기부등본 - 등기사항증명서 종류 radio onchange
   */
  const onChangeValidOnly = useCallback(
    (value) => {
      setValidOnly(value)
    },
    [validOnly],
  )

  /**
   * 등기부등본 - 부동산 구분 radio onchange
   */
  const onChangeSubType = useCallback(
    (value) => {
      setSubType(value)
    },
    [subType],
  )

  /**
   * 등기부 모달창 닫을 시 부동산 구분 초기화
   */
  useEffect(() => {
    // 부동산 구분 초기화
    if (property_type === '토지') {
      setSubType('land')
      onChangeSubType('land')
    } else if (is_group_building == 'Y') {
      setSubType('apt')
      onChangeSubType('apt')
    } else {
      setSubType('building')
      onChangeSubType('building')
    }
  }, [isIssuanceModalVisible, property_type])

  const onCloseEmergencyModal = () => {
    setIsEmergencyModalVisible(false)
    setPaymentResult(false)
  }

  return children({
    docs,
    searchGongbu,
    loadingGoungbu,
    isIssuanceModalVisible,
    setIsIssuanceModalVisible,
    buttonRef,
    onClickDeunggibu,
    onClickLandUsePlan,
    //등기부 발급 모달
    issuanceModal: (
      <NoteDetailGongbuIssuanceModal
        visible={isIssuanceModalVisible}
        setVisible={setIsIssuanceModalVisible}
        onOk={onClickDeunggibuIssuance}
        customAddress={customAddress}
        setCustomAddress={setCustomAddress}
        validOnly={validOnly}
        onChangeValidOnly={onChangeValidOnly}
        subType={subType}
        onChangeSubType={onChangeSubType}
        customDongHo={customDongHo}
        onChangeCustomDongHo={onChangeCustomDongHo}
        setUniqueAddress={setUniqueAddress}
        uniqueAddress={uniqueAddress}
      />
    ),
    //요금제 없음 모달
    noPlanModal: (
      <NoteDetailGongbuPreparingModal
        visible={isFailModalVisible}
        onClose={() => {
          setIsFailModalVisible(false)
          resetDeunggibu()
        }}
      />
    ),
    //전세 피해 계정 전용 등기부 1건 발급 모달
    emergencyModal: (
      <>
        {loginInfo.emergency == 'Y' && (
          <NoteDetailGongbuEmergencyModal
            subType={subType}
            uniqueAddress={uniqueAddress}
            onClose={onCloseEmergencyModal}
            visible={isEmergencyModalVisible}
            paymentResult={paymentResult}
            emergencyAmount={emergencyAmount}
            emergencyCount={emergencyCount}
            validOnly={validOnly}
            customAddress={customAddress}
            customDongHo={customDongHo}
          />
        )}
      </>
    ),
  })
}

export default NoteDetailGongbuIssuanceContainer
