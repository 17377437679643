import styled from 'styled-components'
import { MaxWidthWrapper } from 'components/jmapnotev2/style/NoteStyle'
import theme, { media } from 'lib/styles/theme'
import Scrollbars from 'react-custom-scrollbars-2'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useEffect } from 'react'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import NoteAccountAllInOneMembers from 'components/jmapnotev2/account/NoteAccountAllInOneMembers'
import MobileChannelHeader from 'components/jmapnotev2/channel/MobileChannelHeader'
import NoteMainWrapper from 'components/jmapnotev2/style/NoteMainWrapper'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import NotFound from 'pages/NotFound'

/**
 * 올인원 멤버 관리
 * @returns
 */
const NoteMemberMng = () => {
  const { isOwner } = useIsAllInOne()
  const isMobile = useIsMobile()

  const { onRequest } = useUserLoader(false)

  useEffect(() => {
    onRequest()
  }, [])

  if (!isOwner) return <NotFound />

  return (
    <>
      {isMobile && <MobileChannelHeader channel={false} />}
      <NoteMainWrapper singleM single gray>
        <Scrollbars
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />
          )}
        >
          <StyledMaxWidthWrapper>
            <Title>멤버관리</Title>
            <NoteAccountAllInOneMembers />
          </StyledMaxWidthWrapper>
        </Scrollbars>
      </NoteMainWrapper>
    </>
  )
}
const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  margin: 0 auto;
  height: 100%;
  background-color: ${theme.colors.base.white};
  ${media.smallPc`
    width: 100%;
  `}

  ${media.mediumS`
    padding: 30px 0;
  `}
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  padding-left: 40px;
  margin-bottom: 36px;

  ${media.mediumS`
    padding-left: 20px;
    margin-bottom: 20px;
  `}
`

export default NoteMemberMng
