import { useCallback } from 'react'
import styled from 'styled-components'
import { ReactComponent as ListIconActive } from 'assets/icon/note/menu/list-active.svg'
import { ReactComponent as ListIcon } from 'assets/icon/note/menu/list.svg'
import { ReactComponent as DocIconActive } from 'assets/icon/note/menu/doc-active.svg'
import { ReactComponent as DocIcon } from 'assets/icon/note/menu/doc.svg'
import { ReactComponent as PicturesIcon } from 'assets/icon/note/menu/pictures.svg'
import { ReactComponent as CustomerIcon } from 'assets/icon/note/menu/customer.svg'
import { ReactComponent as CalendarIcon } from 'assets/icon/note/menu/calendar.svg'
import { ReactComponent as AdIcon } from 'assets/icon/note/menu/ad.svg'
import { ReactComponent as AdIconActive } from 'assets/icon/note/menu/ad-active.svg'
import { ReactComponent as DonghoIcon } from 'assets/icon/note/menu/dongho.svg'
import { ReactComponent as DonghoIconActive } from 'assets/icon/note/menu/dongho-active.svg'
import { ReactComponent as DashboardIconActive } from 'assets/icon/note/menu/dashboard-active.svg'
import { ReactComponent as DashboardIcon } from 'assets/icon/note/menu/dashboard.svg'
import CalendarIconActive from 'assets/icon/note/menu/calendar-active.png'
import CustomerIconActive from 'assets/icon/note/menu/customer-active.png'
import PicturesIconActive from 'assets/icon/note/menu/pictures-active.png'
import { ReactComponent as NoteIcon } from 'assets/icon/note.svg'
import NoteSideMenuItem from './NoteSideMenuItem'
import { useSelector } from 'react-redux'
import NoteLinkContainer from 'containers/note/NoteLinkContainer'
import SideMenuBasicItem from './SideMenuBasicItem'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import { useDispatch } from 'react-redux'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

const SideMenuNote = ({ type, onClose }) => {
  const dispatch = useDispatch()
  const { hasPlan, isUseAllInOne } = useNoteHasPlan()
  const { isAllInOne } = useIsAllInOne()
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  const channels = useSelector((state) => state.channel.channels)
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const getChannelSrl = () => {
    const recentChannelSrl = localStorage.getItem('recentChannelSrl')

    const myChannels = channels.filter(({ member_srl }) => {
      return member_srl == loginInfo.member_srl
    })

    return myChannels.length > 0
      ? recentChannelSrl || myChannels[0].channel_srl
      : ''
  }

  const getFolderSrl = () => {
    const recentFolderSrl = localStorage.getItem('recentFolderSrl')

    const myChannels = channels.filter(({ member_srl }) => {
      return member_srl == loginInfo.member_srl
    })

    return myChannels.length > 0
      ? recentFolderSrl || myChannels[0].folder_srl
      : ''
  }

  /**
   * card 버전 매물목록 url 조합하는 함수
   */
  const getCardVerNoteListPath = () => {
    let path = '/note'

    const channelSrl = getChannelSrl()
    const folderSrl = getFolderSrl()

    if (channelSrl) path += `/${channelSrl}`
    if (folderSrl) path += `/${folderSrl}`

    return path
  }

  /**
   * card 모드일 경우 링크 return
   * @returns string
   */
  const getCardModeTo = (label) => {
    const channelSrl = getChannelSrl()
    const folderSrl = getFolderSrl()
    const result = {
      매물관리: getCardVerNoteListPath(channelSrl, folderSrl),
      고객관리: `/note/${channelSrl}/contact`,
      일정관리: `/note/${channelSrl}/calendar`,
    }

    return result[label]
  }

  const getSheetModeTo = useCallback(
    (label) => {
      if (!channels || channels.length == 0) return ''

      const channelSrl = getChannelSrl()

      const selectedDanjiId = selectedDanji?.id

      const result = {
        매물관리: `/note/v2/notes/${channelSrl}`,
        계약관리: `/note/v2/docs/${channelSrl}`,
        사진관리: `/note/v2/pictures/${channelSrl}`,
        광고관리: `/note/v2/ad`,
        동호관리: `/note/v2/dongho${
          selectedDanjiId ? `/${selectedDanjiId}` : ''
        }`,
      }

      return result[label]
    },
    [channels, selectedDanji],
  )
  return (
    <Wrapper type={type}>
      {/* 채널 있으면 */}
      {channels && channels.length > 0 ? (
        <>
          {(hasPlan || isUseAllInOne) && (
            <NoteSideMenuItem
              type={type}
              toCard={'/note/v2/dashboard'}
              toSheet={'/note/v2/dashboard'}
              icon={<DashboardIcon className="normalIcon" />}
              activeIcon={
                <DashboardIconActive className="activeIcon" />
              }
              label={'대시보드'}
              onClose={onClose}
              onlySheet={false}
            />
          )}
          <NoteSideMenuItem
            type={type}
            toCard={getCardModeTo('매물관리')}
            toSheet={getSheetModeTo('매물관리')}
            icon={<ListIcon className="normalIcon" />}
            activeIcon={<ListIconActive className="activeIcon" />}
            label={'매물관리'}
            onlySheet={false}
            onClose={onClose}
          />
          <NoteSideMenuItem
            type={type}
            toCard={`note/v2/docs`}
            toSheet={getSheetModeTo('계약관리')}
            icon={<DocIcon className="normalIcon" />}
            activeIcon={<DocIconActive className="activeIcon" />}
            label={'계약관리'}
            onlySheet={true}
            onClose={onClose}
          />
          {isAllInOne && (
            <NoteSideMenuItem
              type={type}
              toCard={`/note/v2/ad`}
              toSheet={getSheetModeTo('광고관리')}
              icon={<AdIcon className="normalIcon" />}
              activeIcon={<AdIconActive className="activeIcon" />}
              label={'광고관리'}
              onlySheet={true}
              onClose={onClose}
            />
          )}
          <NoteSideMenuItem
            type={type}
            toCard={`/note/v2/dongho`}
            toSheet={getSheetModeTo('동호관리')}
            icon={<DonghoIcon className="normalIcon" />}
            activeIcon={<DonghoIconActive className="activeIcon" />}
            label={'동호관리'}
            onlySheet={true}
            onClose={onClose}
          />
          <NoteSideMenuItem
            type={type}
            toCard={`/note/v2/pictures`}
            toSheet={getSheetModeTo('사진관리')}
            icon={<PicturesIcon className="normalIcon" />}
            activeIcon={
              <img src={PicturesIconActive} className="activeIcon" />
            }
            label={'사진관리'}
            onlySheet={true}
            onClose={onClose}
          />
          {!hasPlan && (
            <NoteSideMenuItem
              type={type}
              toCard={getCardModeTo('일정관리')}
              toSheet="/note/v2/calendar"
              icon={<CalendarIcon className="normalIcon" />}
              activeIcon={
                <img src={CalendarIconActive} className="activeIcon" />
              }
              label={'일정관리'}
              onlySheet={false}
              onClose={onClose}
            />
          )}
          <NoteSideMenuItem
            type={type}
            toCard={getCardModeTo('고객관리')}
            toSheet="/note/v2/customer"
            icon={<CustomerIcon className="normalIcon" />}
            activeIcon={
              <img src={CustomerIconActive} className="activeIcon" />
            }
            label={'고객관리'}
            onlySheet={false}
            onClose={onClose}
          />
        </>
      ) : (
        type != 'hamburger' && (
          <NoteLinkContainer>
            {/* 채널 없으면 */}
            {({ popup, active, onClick }) => (
              <>
                <SideMenuBasicItem
                  to={'/'}
                  onClick={onClick}
                  icon={
                    <NoteIcon
                      width={24}
                      height={24}
                      className="normalIcon"
                    />
                  }
                  label={'매물노트'}
                />
                {popup}
              </>
            )}
          </NoteLinkContainer>
        )
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ type }) =>
    type == 'hamburger' &&
    `
      display: flex;
      flex-wrap: wrap;
`}
`

export default SideMenuNote
