import { Select } from 'components/design'
import MapFilterItem from 'components/map/MapFilter/MapFilterItem'
import { useSelector } from 'react-redux'
import Slider from 'components/common/Slider/Slider'

const NoteFilterPrice = ({
  selectedSalePrice,
  setSelectedSaleprice,

  selectedRent,
  setSelectedRent,
}) => {
  const filterSalePrice = useSelector(
    (state) => state.note.filterStates.sale_price,
  )
  const filterRent = useSelector(
    (state) => state.note.filterStates.rent,
  )

  return (
    <>
      <MapFilterItem
        title="매매가/전세가/보증금"
        noBorder={true}
        titleValue={
          filterSalePrice.toString() === '0,200'
            ? '전체'
            : filterSalePrice
                .map((value, index) => {
                  if (index === 0 && value === 0) return ''
                  if (value > 0 && value < 10) return value + '천'
                  if (value >= 10 && value < 200)
                    return value / 10 + '억'
                  if (index === 1 && value === 200) return ''
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <Slider
          range={{ min: 0, max: 200 }}
          start={filterSalePrice}
          step={5}
          margin={5}
          connect
          behaviour="snap"
          pips={{
            mode: 'positions',
            values: [0, 50, 100],
            density: 20,
            format: {
              to: (value) => {
                if (value === 0) return '5천 이하'
                if (value > 0 && value < 10) return value + '천'
                if (value >= 10 && value < 200) return value / 10 + '억'
                if (value === 200) return '20억 이상'
              },
            },
          }}
          onEnd={(render, handle, value, un, percent) => {
            setSelectedSaleprice(value.map((item) => item))
          }}
        />
      </MapFilterItem>
      <MapFilterItem
        title="월세"
        titleValue={
          filterRent.toString() === '0,200'
            ? '전체'
            : filterRent
                .map((value, index) => {
                  if (index === 0 && value === 0) return ''
                  if (index === 1 && value === 200) return ''

                  return Math.floor(value) + '만'
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <Slider
          range={{ min: 0, max: 200 }}
          start={filterRent}
          step={5}
          margin={10}
          connect
          behaviour="snap"
          pips={{
            mode: 'positions',
            values: [0, 50, 100],
            density: 20,
            format: {
              to: (value) => {
                if (value === 0) return '10만 이하'
                if (value === 200) return '2백 이상'
                return value + '만'
              },
            },
          }}
          onEnd={(render, handle, value, un, percent) => {
            setSelectedRent(value.map((item) => item))
          }}
        />
      </MapFilterItem>
    </>
  )
}

export default NoteFilterPrice
