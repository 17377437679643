import InviteAuthContainer from 'containers/auth/InviteAuthContainer'
import RewardAuthModalContainer from 'containers/auth/RewardAuthModalContainer'
import HomePage from 'pages/HomePage'

const InviteAuth = () => {
  return (
    <RewardAuthModalContainer fromRewardModal>
      {({ type, isEndRewardSign, authModal, setType }) => (
        <InviteAuthContainer setType={setType}>
          <HomePage />
          {/* 회원가입이나 로그인 끝나면 ㅇㅇㅇ님 환영합니다 모달 */}
          {/* //랜딩 지금 시작하기 버튼 -> 회원가입 or 로그인 모달
            //로그인 모달
            //회원가입 모달 */}
          {!isEndRewardSign && type && authModal}
        </InviteAuthContainer>
      )}
    </RewardAuthModalContainer>
  )
}

export default InviteAuth
