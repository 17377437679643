import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'components/design'
import SummaryMultiHousing from 'components/summary/SummaryMultiHousing/SummaryMultiHousing'
import { getHousingPrice } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import { getCustomPurpose } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 개별주택가격
 
 * * 렌더링 여부
 * * 공동주택아님: O (토지 제외)
 */
const SummaryMultiHousingContainer = () => {
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  const [onRequest, loading, data] = useAxios(getHousingPrice)

  const [priceData, setPriceData] = useState([])

  useEffect(() => {
    if (!currentAddressId) return

    onRequest({
      address_id: currentAddressId,
    })
  }, [])

  useEffect(() => {
    if (!data || !data.result) return
    setPriceData(data.result.filter(({ price }) => price >= 0))
  }, [data])

  return (
    <Card>
      <CardHeader>
        <CardTitle>개별주택가격</CardTitle>
      </CardHeader>
      <CardBody>
        <SummaryMultiHousing data={priceData} loading={loading} />
      </CardBody>
    </Card>
  )
}

export default SummaryMultiHousingContainer
