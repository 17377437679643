import { useRouteMatch } from 'react-router'

export default function useNoteMatch() {
  const match = useRouteMatch({
    path: [
      '/note/v2/notes/:channelSrl',
      '/note/v2/notes/:pageType/:channelSrl?',
      '/note/:channelSrl/calendar',
      '/note/:channelSrl/contact',
      '/note/:channelSrl/map',

      '/note/:channelSrl/:folderSrl/settings/excel',
      '/note/:channelSrl/:folderSrl/settings/folder',
      '/note/:channelSrl/:folderSrl/settings/invite',
      '/note/:channelSrl/:folderSrl/settings',

      '/note/:channelSrl/:folderSrl/:addressSrl/modify',

      '/note/:channelSrl/:folderSrl/:addressSrl/:noteSrl/modify',
      '/note/:channelSrl/:folderSrl/:addressSrl/:noteSrl/save',
      '/note/:channelSrl/:folderSrl/:addressSrl/:noteSrl',
      '/note/:channelSrl/:folderSrl/:addressSrl',
      '/note/:channelSrl/:folderSrl',
      '/note/:channelSrl',
      '/note',
      '/share/:noteSrl/:encryptedKey',
    ],
  })

  const isNoteExternal = useRouteMatch({
    path: [
      '/note/:channelSrl/:folderSrl/settings',
      '/note/:channelSrl/:folderSrl/:addressSrl/modify',
      '/note/:channelSrl/:folderSrl/:addressSrl/:noteSrl/modify',
      '/note/:channelSrl/:folderSrl/:addressSrl/:noteSrl/save',
      '/note/:channelSrl/:folderSrl/:addressSrl/:noteSrl',
      '/note/:channelSrl/:folderSrl/:addressSrl',
    ],
  })

  const channelSrl = match?.params?.channelSrl || ''
  const folderSrl = match?.params?.folderSrl || ''
  const addressSrl = match?.params?.addressSrl || 0
  const noteSrl = match?.params?.noteSrl || ''

  return {
    isJMapNote: match,
    channelSrl,
    folderSrl,
    addressSrl,
    noteSrl,
    isNoteExternal,
  }
}
