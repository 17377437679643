import axios from 'axios'

export const getContactList = async (
  { _rx_csrf_token, orderBy, q },
  options,
) =>
  await axios.post(
    '/jmapnote/api/contact/list',
    {
      _rx_csrf_token,
      orderBy,
      q,
    },
    options,
  )

export const getContacts = async ({ _rx_csrf_token }, options) =>
  await axios.post(
    '/jmapnote/api/contact/getContacts',
    {
      _rx_csrf_token,
    },
    options,
  )

export const getContact = async (
  { _rx_csrf_token, contactSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/contact/getContact',
    {
      _rx_csrf_token,
      contract_srl: contactSrl,
    },
    options,
  )

export const saveContact = async (
  { _rx_csrf_token, type, numbers, alias, memo },
  options,
) =>
  await axios.post(
    '/jmapnote/api/contact/save',
    {
      _rx_csrf_token,
      type,
      numbers,
      alias,
      memo,
    },
    options,
  )

export const modifyContact = async (
  { _rx_csrf_token, contactSrl, type, numbers, alias, memo },
  options,
) =>
  await axios.post(
    '/jmapnote/api/contact/modify',
    {
      _rx_csrf_token,
      contact_srl: contactSrl,
      type,
      numbers,
      alias,
      memo,
    },
    options,
  )

export const removeContact = async (
  { _rx_csrf_token, contactSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/contact/remove',
    {
      _rx_csrf_token,
      contact_srl: contactSrl,
    },
    options,
  )

export const syncContact = async (params, options) =>
  await axios.post(
    '/jmapnote/api/contact/syncContact',
    {
      ...params,
    },
    options,
  )
