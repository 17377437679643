import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 층노출방식
 * 건물 전체, 빌딩
 */
const NoteAdColumnFloorExposure = ({ half }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  const floorType = watch('article.floorType')

  return (
    <ColumnSet half={half}>
      <ColumnTitle>층노출방식</ColumnTitle>
      <ColumnVal direction="column">
        <RadioWrapper>
          <RadioButton
            size="small"
            label="노출(층노출)"
            name="article_floor_agree"
            value={4}
            checked={floorType == 4}
            {...register('article.floorType')}
            onChange={() => {
              onUpdate('article.floorType', 4)
            }}
          />
          <RadioButton
            size="small"
            label="미노출(고/중/저 노출)"
            name="article_floor_agree"
            value={2}
            checked={floorType == 2}
            {...register('article.floorType')}
            onChange={() => {
              onUpdate('article.floorType', 2)
            }}
          />
        </RadioWrapper>
        <FormDesc>
          <li>
            [주의] 표시·광고 명시사항에 따라 꼭 중개의뢰인이
            층노출동의여부를 확인해서 선택해 주세요.
          </li>
          <li>
            [주의] 주택을 제외한 건축물의 경우 층노출이 필수이며,
            누락(저중고노출)시 과태료 대상이 될 수 있습니다.
          </li>
        </FormDesc>
        <ValidateWarn>
          {errors?.article?.floorType?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const RadioWrapper = styled.div`
  display: flex;
`

export default NoteAdColumnFloorExposure
