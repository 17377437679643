import useNoteFilter from 'containers/note/hooks/useNoteFilter'
import MapContext from 'contexts/map'
import { getOptions } from 'lib/api/layerApi'
import useAxios from 'lib/hooks/useAxios'
import { merge } from 'lodash'
import { setOptions } from 'modules/filter'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const MapFilterRememberContainer = ({ children }) => {
  const {
    state: { filterState },
    actions: { setFilterState },
  } = useContext(MapContext)

  const noteFilterProps = useNoteFilter()
  const filterArea = useSelector(
    (state) => state.note.filterStates.area,
  )
  const filterMoveDate = useSelector(
    (state) => state.note.filterStates.move_date,
  )
  const filterSalePrice = useSelector(
    (state) => state.note.filterStates.sale_price,
  )
  const filterRent = useSelector(
    (state) => state.note.filterStates.rent,
  )
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const [onRequest, , data] = useAxios(getOptions)

  const dispatch = useDispatch()

  /**
   * 매물 필터 기억해 둔 것 세팅
   */
  useEffect(() => {
    onRequest()

    const storedFilterState = localStorage.getItem('filterState')

    if (!storedFilterState) return
    const parsedData = JSON.parse(storedFilterState)
    setFilterState(merge(filterState, parsedData))

    if (loginInfo?.member_type === 'realestate') {
      const storedNoteFilter = JSON.parse(
        localStorage.getItem('noteFilterState'),
      )
      if (
        !storedNoteFilter ||
        Object.keys(storedNoteFilter).length == 0
      )
        return

      const {
        contractTypes,
        selectedFloorCnt,
        selectedChannel,
        selectedContractTypes,
        selectedExtraOptions,
        selectedPropertyTypes,
        selectedRent,
        selectedSalePrice,
        selectedStatus,
        filterArea,
        filterMoveDate,
        filterSalePrice,
        filterRent,
      } = storedNoteFilter

      noteFilterProps.setSelectedContractTypes(contractTypes)
      noteFilterProps.setSelectedFloorCnt(selectedFloorCnt)
      noteFilterProps.setSelectedChannel(selectedChannel)
      noteFilterProps.setSelectedContractTypes(selectedContractTypes)
      noteFilterProps.setSelectedExtraOptions(selectedExtraOptions)
      noteFilterProps.setSelectedPropertyTypes(selectedPropertyTypes)
      noteFilterProps.setSelectedRent(selectedRent)
      noteFilterProps.setSelectedSaleprice(selectedSalePrice)
      noteFilterProps.setSelectedStatus(selectedStatus)

      if (filterSalePrice) {
        noteFilterProps.setSelectedSaleprice(filterSalePrice)
      }

      if (filterRent) {
        noteFilterProps.setSelectedRent(filterRent)
      }

      if (filterMoveDate) {
        noteFilterProps.setSelectedMoveDate(filterMoveDate)
      }

      if (filterArea) {
        noteFilterProps.setSelectedArea(filterArea)
      }
    }
  }, [])

  /**
   * 필터를 선택할 때 마다 localstorage에 저장
   */
  useEffect(() => {
    localStorage.setItem('filterState', JSON.stringify(filterState))
  }, [filterState])

  /**
   * 중개사의 경우 매물 필터 기억
   */
  useEffect(() => {
    if (loginInfo?.member_type === 'realestate') {
      const picked = (({
        contractTypes,
        selectedFloorCnt,
        propertyTypes,
        selectedChannel,
        selectedContractTypes,
        selectedExtraOptions,
        selectedPropertyTypes,
        selectedRent,
        selectedSalePrice,
        selectedStatus,
      }) => ({
        contractTypes,
        selectedFloorCnt,
        propertyTypes,
        selectedChannel,
        selectedContractTypes,
        selectedExtraOptions,
        selectedPropertyTypes,
        selectedRent,
        selectedSalePrice,
        selectedStatus,
      }))(noteFilterProps)

      const filterOtherObj = {
        filterArea: filterArea,
        filterMoveDate: filterMoveDate,
        filterSalePrice: filterSalePrice,
        filterRent: filterRent,
      }

      const resultObj = Object.assign({}, picked, filterOtherObj)

      localStorage.setItem('noteFilterState', JSON.stringify(resultObj))
    }
  }, [noteFilterProps, filterArea])

  useEffect(() => {
    if (!data || !data?.result) return

    dispatch(setOptions(data?.result))
  }, [data])

  return children
}

export default MapFilterRememberContainer
