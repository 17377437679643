import { useCallback, useState, useEffect } from 'react'
import useAxios from '../useAxios'
import { editContract } from 'lib/api/notev2/contractApi'
import { useSelector } from 'react-redux'
import { groupBy } from 'lodash'
import { blockColors } from 'lib/utils/notev2/dongho'

/**
 * 동호관리 블럭 색상
 */

function useDonghoColors() {
  const activeTab = useSelector((state) => state.noteDongho.activeTab)

  /**
   * 범위 안의 랜덤 숫자
   * @param {*} min
   * @param {*} max
   * @returns
   */
  function randomNumber(min, max) {
    return parseInt(Math.random() * (max - min + 1), 10) + min
  }

  /**
   * 랜덤 컬러
   */
  function getRandomColor() {
    var h = randomNumber(80, 360) // hue
    var s = randomNumber(55, 70) // saturation
    var l = randomNumber(65, 90) // lightness
    return hslToHex(h, s, l)
  }

  /**
   * hsl을 hex code로
   * @param {*} h
   * @param {*} s
   * @param {*} l
   * @returns
   */
  function hslToHex(h, s, l) {
    l /= 100
    const a = (s * Math.min(l, 1 - l)) / 100
    const f = (n) => {
      const k = (n + h / 30) % 12
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0') // convert to Hex and prefix "0" if needed
    }
    return `#${f(0)}${f(8)}${f(4)}`
  }

  const split = (left, right, parts) => {
    var result = [],
      delta = (right - left) / (parts - 1)

    while (left.toFixed(6) < right) {
      result.push(left)
      left += delta
    }
    result.push(right)
    return result
  }

  /**
   * 탭 별 각 블럭 색상 얻기
   */
  const getColors = ({ data }) => {
    const colors = blockColors[activeTab]
    if (activeTab == 2) {
      const formattedData = data.filter(
        (item) => item && item != 'null' && item != 'undefined',
      )

      const rangeOfPrice = split(
        Number(formattedData[0]),
        Number(formattedData[formattedData.length - 1]),
        colors.length,
      )

      return rangeOfPrice.map((value, i) => {
        return {
          value,
          color: colors[i],
        }
      })
    } else if (activeTab == 4) {
      const formattedData = data
        .filter((item) => item && item != 'null' && item != 'undefined')
        .map(Number)
        .sort((a, b) => a - b)

      const rangeOfPrice = split(
        Number(formattedData[0]),
        Number(formattedData[formattedData.length - 1]),
        colors.length - 1,
      )

      return rangeOfPrice.map((value, i) => {
        return {
          value,
          color: colors[i],
        }
      })
    }
  }
  return { getColors }
}

export default useDonghoColors
