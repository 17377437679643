import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  Row,
  InnerTableContent,
  InnerTableBlock,
  innerSubHeaderWidth,
  StyledTable,
  AmongSpan,
  RadioButtonsWrap,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { useFormContext } from 'react-hook-form'

const NoteDocsBasic6 = ({ onUpdate }) => {
  const { watch, setValue } = useFormContext()

  const d_descript_type = watch('d_descript_type')
  const d_is_biseonhosiseol = watch('d_is_biseonhosiseol')
  const d_biseonhosiseol = watch('d_biseonhosiseol')

  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑦'
    } else if (d_descript_type == '비주거용 건축물') {
      return '⑥'
    } else if (d_descript_type == '토지') {
      return '⑤'
    }
  }

  return (
    <StyledTable>
      <Row height={20} rowspan={2} noborderbottom>
        <TableHeader width={innerSubHeaderWidth}>
          {getNumber()} 비선호시설
          <br />
          &#40;1km 이내&#41;
        </TableHeader>
        <RowSpanInner
          rowspanheightpercent={100}
          minuswidth={innerSubHeaderWidth}
          noborderbottom
        >
          <InnerTableBlock
            noborderbottom
            noborderright
            widthpercent={100}
          >
            <InnerTableContent checkboxes fullWidth>
              <RadioButtonsWrap>
                <RadioButton
                  label="없음"
                  checked={
                    d_is_biseonhosiseol == '없음' ||
                    d_is_biseonhosiseol == ''
                  }
                  name="d_is_biseonhosiseol"
                  onChange={(e) =>
                    onUpdate('d_is_biseonhosiseol', '없음')
                  }
                />
                <RadioButton
                  label="있음"
                  checked={d_is_biseonhosiseol == '있음'}
                  name="d_is_biseonhosiseol"
                  onChange={(e) =>
                    onUpdate('d_is_biseonhosiseol', '있음')
                  }
                />
              </RadioButtonsWrap>
              <AmongSpan width={100}>&#40; 종류 및 위치: </AmongSpan>
              <ColorInput
                type="text"
                defaultValue={d_biseonhosiseol}
                onBlur={(e) =>
                  onUpdate('d_biseonhosiseol', e.target.value)
                }
                disabled={d_is_biseonhosiseol != '있음'}
                fullWidth
              />
              &#41;
            </InnerTableContent>
          </InnerTableBlock>
        </RowSpanInner>
      </Row>
    </StyledTable>
  )
}

export default NoteDocsBasic6
