import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { SearchInput } from './style/NoteStyle'

/**
 * 계정관리
 * 올인원 멤버 관리 탭
 * 검색창
 */
const NoteAccountAllInOneMemberSearch = ({ bindSearch }) => {
  return (
    <InputWrap>
      <SearchIcon className="fas fa-search"></SearchIcon>
      <SearchInput
        small
        type="text"
        placeholder="멤버 검색"
        {...bindSearch}
      />
    </InputWrap>
  )
}

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 34px;

  ${media.mediumS`
    margin-left: 20px;
  `}
`

const SearchIcon = styled.i`
  color: ${theme.colors.gray[700]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`
export default NoteAccountAllInOneMemberSearch
