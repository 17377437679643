import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { ReactComponent as AdIng } from 'assets/icon/note/ad/ad-ing.svg'
import { ReactComponent as ApprovalDelegationColor } from 'assets/icon/note/ad/approval-delegation-color.svg'
import { ReactComponent as Fail } from 'assets/icon/note/ad/fail.svg'
import { ReactComponent as FailCancel } from 'assets/icon/note/ad/fail_cancel.svg'
import { ReactComponent as Report } from 'assets/icon/note/ad/report.svg'
import { ReactComponent as WillEnd } from 'assets/icon/note/ad/will-end.svg'
import { ReactComponent as Same } from 'assets/icon/note/ad/same.svg'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  setSelectedStatus,
  setShowIntroduceService,
} from 'modules/noteAd'
import { Skeleton } from 'antd'
import { bp, breakPoint } from './breakPoints'
import NoteAdSpeechBubble from './list/NoteAdSpeechBubble'
import useIsMobile from 'lib/hooks/useIsMobile'

const NoteAdStatus = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const statusData = useSelector((state) => state.noteAd.status.datas) //상태 값 데이터들
  const selectedStatus = useSelector(
    (state) => state.noteAd.status.selectedStatus,
  ) //선택된 상태 값 필터
  const showIntroduceService = useSelector(
    (state) => state.noteAd.showIntroduceService,
  ) //서비스 소개 말풍선
  /**
   * 필터 클릭 이벤트
   */
  const onClickStatus = (value) => {
    if (value == selectedStatus) {
      dispatch(setSelectedStatus(null))
    } else {
      dispatch(setSelectedStatus(value))
    }
  }

  const onBubbleClose = () => {
    dispatch(
      setShowIntroduceService({
        status: false,
      }),
    )
  }

  //TODO: 로딩
  return (
    <Wrapper>
      {showIntroduceService.status && (
        <NoteAdSpeechBubble
          text="현황판을 통해 주요 업무를 바로 확인할 수 있어요 👀"
          direction="bottom"
          onClose={onBubbleClose}
        />
      )}
      <>
        {statusData ? (
          <>
            <Block
              toppos
              visibleM
              active={selectedStatus == '승인대기'}
              onClick={() => onClickStatus('승인대기')}
              defaultblue
            >
              <Icon color="blue">
                <ApprovalDelegationColor width={22} height={22} />
              </Icon>
              <Text grow active={selectedStatus == '승인대기'}>
                <Title>승인대기</Title>
                <Count>{statusData['승인대기']}</Count>
              </Text>
            </Block>
            <Block
              toppos
              hideM
              active={selectedStatus == '광고중'}
              onClick={() => onClickStatus('광고중')}
            >
              <Icon color="primary">
                <AdIng />
              </Icon>
              <Text grow active={selectedStatus == '광고중'}>
                <Title>광고 중</Title>
                <Count>{statusData['광고중']}</Count>
              </Text>
            </Block>
            <Block
              toppos
              hideM
              active={selectedStatus == '종료예정'}
              onClick={() => onClickStatus('종료예정')}
            >
              <Icon color="gray">
                <WillEnd />
              </Icon>
              <Text grow active={selectedStatus == '종료예정'}>
                <Title>종료예정</Title>
                <Count>{statusData['종료예정']}</Count>
              </Text>
            </Block>
            <Block
              toppos
              hideM
              active={selectedStatus == '검증취소'}
              onClick={() => onClickStatus('검증취소')}
            >
              <Icon color="gray">
                <FailCancel />
              </Icon>
              <Text grow active={selectedStatus == '검증취소'}>
                <Title>검증취소</Title>
                <Count>{statusData['검증취소']}</Count>
              </Text>
            </Block>
            <Block
              bottompos
              hideM
              active={selectedStatus == '동일주소매물'}
              onClick={() => onClickStatus('동일주소매물')}
            >
              <Icon color="blue">
                <Same />
              </Icon>
              <Text grow active={selectedStatus == '동일주소매물'}>
                <Title>동일주소매물</Title>
                <Count>{statusData['동일주소매물']}</Count>
              </Text>
            </Block>
            <MultiBlock
              active={
                selectedStatus == '검증1차실패' ||
                selectedStatus == '검증최종실패'
              }
            >
              <Icon color="red">
                <Fail />
              </Icon>
              <MultiBlockContents>
                <Text
                  active={selectedStatus == '검증1차실패'}
                  onClick={() => onClickStatus('검증1차실패')}
                >
                  <Title black>검증1차실패</Title>
                  <Count active={selectedStatus == '검증1차실패'} red>
                    {statusData['검증1차실패']}
                  </Count>
                </Text>
                <Divider />
                <Text
                  active={selectedStatus == '검증최종실패'}
                  onClick={() => onClickStatus('검증최종실패')}
                >
                  <Title black>검증최종실패</Title>
                  <Count active={selectedStatus == '검증최종실패'} red>
                    {statusData['검증최종실패']}
                  </Count>
                </Text>
              </MultiBlockContents>
            </MultiBlock>
            <Block
              bottompos
              visibleM
              active={selectedStatus == '신고매물'}
              onClick={() => onClickStatus('신고매물')}
            >
              <Icon color="red">
                <Report />
              </Icon>
              <Text grow active={selectedStatus == '신고매물'}>
                <Title>신고매물</Title>
                <Count>{statusData['신고매물']}</Count>
              </Text>
            </Block>
          </>
        ) : (
          <Skeleton active />
        )}
      </>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 45px;
  position: relative;

  ${media.xxLarge`
    margin-bottom: 20px;
  `}

  ${breakPoint(bp[2])} {
    flex-wrap: wrap;
  }

  ${media.mediumM`
    flex-wrap: wrap;
    margin-bottom: 20px;
  `}
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
  flex-shrink: 0;
  word-break: keep-all;

  ${({ black }) =>
    black &&
    `
      color: ${theme.colors.gray[900]};
    `}
`

const Count = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  line-height: 100%;
  font-variant-numeric: tabular-nums;

  &::after {
    content: '\f105';
    position: absolute;
    right: 0;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 100;
    font-size: 22px;
    color: #d9d9d9e6 !important;
  }

  ${({ red }) =>
    red &&
    `
      color: ${theme.colors.tint.danger};
  `}

  ${({ active }) =>
    active &&
    `
      &::after {
        color: ${theme.colors.gray[900]} !important;
      }
  `}

  ${breakPoint(bp[2])} {
    font-size: 20px;
  }

  ${media.mediumM`
    gap: 11px;
    justify-content: flex-start;
    align-items: center;
  `}
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  max-width: 44px;
  max-height: 44px;
  border-radius: 50%;
  flex-shrink: 0;

  ${({ color }) => {
    switch (color) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary[50]};
        `
      case 'blue':
        return `
          background-color: ${theme.colors.blue[50]};
        `
      case 'blue-plain':
        return `
          background-color: ${theme.colors.blue[50]};
        `
      case 'gray':
        return `
          background-color: ${theme.colors.gray[150]};
          svg {
             fill: ${theme.colors.gray[800]};
          }
        `
      case 'red':
        return `
          background-color: ${theme.colors.red[50]};
        `
    }
  }}

  ${breakPoint(bp[2])} {
    width: 36px;
    height: 36px;
    max-width: 36px;
    max-height: 36px;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  ${({ hover }) => (hover ? `` : `flex-grow: 1;`)};

  ${breakPoint(bp[2])} {
    gap: 6px;
  }

  ${breakPoint(bp[4])} {
    width: 100%;
  }
`

const Block = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
  width: calc(calc(100% / 8) - 8.6px);
  padding: 28px 20px;
  position: relative;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.07);
  background-color: ${theme.colors.base.white};
  cursor: pointer;
  transition: 0.3s;

  @media (hover: hover) {
    &:hover {
      border: 2px solid ${theme.colors.blue[400]};
    }
  }

  ${({ active }) =>
    active &&
    `
      border: 2px solid ${theme.colors.blue[400]};
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
  `}

  ${breakPoint(bp[1])} {
    padding: 28px 16px;
    width: calc(calc(calc(100% - 180px) / 6) - 6px);
  }

  ${breakPoint(bp[2])} {
    align-items: center;
    padding: 22px 16px;
    max-height: 86px;

    ${({ toppos }) =>
      toppos &&
      `
      width: calc(calc(100% / 4) - 9px);
    `};

    ${({ bottompos }) =>
      bottompos &&
      `
      width: calc(calc(100% / 3) - 8px);
    `};
  }

  ${breakPoint(bp[4])} {
    ${Icon} {
      display: none;
    }
  }

  ${breakPoint(bp[5])} {
    ${({ hideM }) => hideM && `display: none;`};

    ${({ visibleM, toppos, bottompos }) =>
      visibleM &&
      `
        ${toppos && `width: 100%;`};
        ${bottompos && `width: calc(50% - 6px);`};
        padding: 20px 16px;

        ${Text} {
          ${breakPoint(bp[5])} {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            padding-right: 48px;
          }
        }
    `}
  }
`

const MultiBlock = styled(Block)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  width: calc(calc(calc(100% / 8) * 2) - 8.6px);
  position: relative;
  padding-left: 24px;
  padding-right: 24px;

  @media (hover: hover) {
    &:hover {
      border: 2px solid ${theme.colors.red[400]};
    }
  }

  ${({ active }) =>
    active &&
    `
      border: 2px solid ${theme.colors.red[400]};
    `}

  ${breakPoint(bp[1])} {
    gap: 14px;
    flex-grow: 1;
    padding-left: 16px;
    padding-right: 16px;
  }

  ${breakPoint(bp[2])} {
    width: calc(calc(100% / 3) - 8px);
    padding-top: 18px;
    padding-bottom: 18px;
  }

  ${breakPoint(bp[5])} {
    width: calc(50% - 6px);
    padding-left: 16px;
    padding-right: 16px;
  }
`

const MultiBlockContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 24px;
  flex-grow: 1;
  position: relative;
  height: 100%;

  ${breakPoint(bp[1])} {
    gap: 18px;
  }

  ${breakPoint(bp[2])} {
    gap: 4px;
    flex-direction: column;

    ${Text} {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      width: 100%;

      ${Title} {
        flex-shrink: 0;
      }

      ${Count} {
        &::after {
          display: none;
        }
      }
    }
  }
`

const Divider = styled.div`
  width: 1px;
  height: 100%;
  border-right: 1px dashed ${theme.colors.gray[900]}33;

  ${breakPoint(bp[2])} {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed ${theme.colors.gray[900]}33;
  }
`

export default NoteAdStatus
