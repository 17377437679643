import styled from 'styled-components'
import theme from 'lib/styles/theme'

import { FaUser } from 'react-icons/fa'

const StyledWrap = styled.div`
  flex: 0 0 auto;
  display: inline-flex;
  align-items: flex-start;
  height: 100%;
`
const StyledThumbnail = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  color: ${theme.colors.blue[400]};
  font-size: 1.25rem;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  background-color: ${theme.colors.blue[50]};

  ${({ color }) => {
    if (!color) return

    switch (color) {
      case 'gray':
        return `
          color: ${theme.colors.gray[400]};
          background-color: ${theme.colors.gray[200]};
        `
    }
  }}

  ${({ src }) =>
    src &&
    `
    background-image: url(${src});
  `}

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const ListItemThumbnail = ({ src, color, children }) => {
  return (
    <StyledWrap>
      <StyledThumbnail src={src} color={color}>
        {!src && (children || <FaUser />)}
      </StyledThumbnail>
    </StyledWrap>
  )
}

export default ListItemThumbnail
