import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { getFolder } from 'lib/api/note/folderApi'
import useAxios from 'lib/hooks/useAxios'
import FirstUserPopup from 'components/jmapnote/channel/FirstUserPopup'
import { message } from 'antd'

const NoteContainer = ({ history, children }) => {
  const { channelSrl, folderSrl } = useNoteMatch()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const dispatch = useDispatch()
  const location = useLocation()

  const [onRequest, loading, data, error, reset] = useAxios(getFolder)
  const channels = useSelector((state) => state.channel.channels)
  const folders = useSelector((state) => state.note.folders)

  const [firstUserPopupVisible, setFirstUserPopupVisibie] =
    useState(false)

  /**
   * @description 비로그인 상태에서 접근 또는 부동산 중개업자가 아닐 경우 지도 메인으로 replace 시킴.
   *
   */
  useEffect(() => {
    if (
      loginInfo &&
      (!loginInfo?.member_srl ||
        loginInfo?.member_type !== 'realestate')
    ) {
      history.replace('/')
      return
    }
  }, [loginInfo])

  /**
   * @description 마지막으로 접속한 채널과 폴더 srl을 기록을 위한 getFolder() 요청
   */
  useEffect(() => {
    if (!channelSrl || !folderSrl) {
      if (
        location.pathname.includes('calendar') ||
        location.pathname.includes('contact') ||
        channelSrl
      )
        return

      handleRedirectDefault()

      return
    }

    onRequest({ channelSrl, folderSrl })
  }, [channelSrl, folderSrl])

  /**
   * @description 마지막으로 접속한 채널과 폴더 srl을 기록
   */
  useEffect(() => {
    if (data?.message === 'success') {
      localStorage.setItem('recentChannelSrl', channelSrl)
      localStorage.setItem('recentFolderSrl', folderSrl)
    }
  }, [data])

  /**
   * @description 폴더를 불러올 수 없을 경우
   * 최근 접속한 채널/폴더 정보를 가장 최상위의 것으로 초기화함.
   */
  useEffect(() => {
    if (!error) return

    localStorage.setItem('recentChannelSrl', channels?.[0].channel_srl)
    localStorage.setItem('recentFolderSrl', folders?.[0]?.folder_srl)
  }, [error])

  /**
   *  @description 중개사로 로그인 했지만, 폴더와 채널 srl이 없을 경우 기본 폴더와 채널로 redirect 시킨다
   *  @exampleUrl /map/note
   */
  const handleRedirectDefault = () => {
    // 사용자가 생성한 채널이 없을 경우 채널 생성 팝업 출력
    if (channels.length === 0) {
      setFirstUserPopupVisibie(true)
      return
    }
    if (localStorage.getItem('notemode') == 'sheet') {
      localStorage.setItem('notemode', 'sheet')
      if (channels[0] && channels[0].channel_srl) {
        history.push(`/note/v2/notes/${channels[0].channel_srl}`)
      } else {
        history.push('/note/v2/dashboard')
      }
    } else {
      // localStorage에 저장된 최근 접속한 채널srl과 folderSrl을 불러옵니다.
      const recentChannelSrl = localStorage.getItem('recentChannelSrl')
      const recentFolderSrl = localStorage.getItem('recentFolderSrl')

      // 최근 접속한 채널/폴더 정보가 있을 경우 유효성 체크 후 이동시킵니다.
      if (
        recentChannelSrl &&
        recentFolderSrl &&
        recentChannelSrl !== 'undefined' &&
        recentFolderSrl !== 'undefined'
      ) {
        const authorizedChannelSrl = channels.filter(
          ({ channel_srl }) =>
            channel_srl === parseInt(recentChannelSrl),
        )[0]?.channel_srl

        if (authorizedChannelSrl) {
          moveToFirstChannel(authorizedChannelSrl, recentFolderSrl)
          return
        }
      }

      /**
       * 최근 접속한 채널/폴더 정보가 없을 경우 채널 목록에서
       * 로그인한 회원의 member_srl값과 동일한 채널의 폴더 목록으로 이동
       */
      const myChannel = channels?.filter(
        ({ member_srl }) =>
          member_srl === parseInt(loginInfo.member_srl),
      )

      if (myChannel.length == 0) {
        setFirstUserPopupVisibie(true)
        return
      }

      moveToFirstChannel(
        myChannel[0].channel_srl,
        myChannel[0].folder_srl,
      )
    }
  }

  /**
   * @param {string} channelSrl
   * @param {string} folderSrl
   * @description 인자로 받은 channelSrl, folderSrl을 바탕으로
   * 링크를 생성하고 이동시킵니다.
   */
  const moveToFirstChannel = (channelSrl, folderSrl) => {
    if (!channelSrl || !folderSrl || !Number.isInteger(channelSrl)) {
      message.error(
        '일시적인 문제가 발생했습니다. 관리자에게 문의하십시오.',
      )
      return
    }
    localStorage.setItem('notemode', 'card')
    history.push(`/note/${channelSrl}/${folderSrl}`)
  }

  if (!loginInfo?.member_srl) return null

  return children({
    popup: (
      <FirstUserPopup
        visible={firstUserPopupVisible}
        setVisible={setFirstUserPopupVisibie}
        onSuccess={moveToFirstChannel}
      />
    ),
  })
}

export default NoteContainer
