import { useEffect, useContext, useRef, useState, useMemo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

import BaseContext from 'contexts/baseContext'
import theme, { media } from 'lib/styles/theme'

import styled from 'styled-components'
import useIsMobile from 'lib/hooks/useIsMobile'
import SwipeableContainer from './SwipeableContainer'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isLayerSmallThanWindow } from 'utils'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { setIsOpenNoteExternal } from 'modules/summary'
import FoldBtn from 'components/design/Fold/FoldBtn'
import SummaryFoldBtn from './SummaryFoldBtn'

const Container = ({
  external,
  children,
  header,
  swipeable,
  onChange,
  offset = theme.base.summaryHeaderHeight,
  white,
  bottomMenu,
  absolute,
  topSlot,
  noBottom,
  noTopHeader = false,
  shift,
  highest = false,
  foldBtn = true,
}) => {
  const isMobile = useIsMobile()

  const params = useParams()
  const dispatch = useDispatch()

  const { addressSrl } = useNoteMatch()

  const isOpenNoteExternal = useSelector(
    (state) => state.summary.isOpenNoteExternal,
  )

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)

  const [isOpenExternal, setIsOpenExternal] = useState(false)

  const {
    state: { isFold },
    actions: { setIsScrolled },
  } = useContext(BaseContext)
  const scrollRef = useRef()

  useEffect(() => {
    return () => {
      // unmount 시 isScrollTop 상태를 초기화
      setIsScrolled(false)
      dispatch(setIsOpenNoteExternal(false))
    }
  }, [])

  useEffect(() => {
    setIsOpenExternal(params.external || addressSrl || params.asset_srl)
  }, [params, addressSrl])

  const handleScroll = (e) => {
    if (!scrollRef?.current) return
    if (scrollRef?.current?.getScrollTop() <= 0) {
      setIsScrolled(false)
      return
    }
    setIsScrolled(true)
  }

  // swieable 모드 + 모바일 상태
  if (swipeable && isMobile) {
    return (
      <SwipeableContainer
        external={external}
        header={header}
        onChange={onChange}
        offset={offset}
        bottomMenu={bottomMenu}
        topSlot={topSlot}
      >
        {children}
      </SwipeableContainer>
    )
  }

  // 아니라면 컨테이너만
  return (
    <>
      <Wrapper
        highest={highest}
        external={external}
        absolute={absolute}
        bottomMenu={bottomMenu}
        white={white}
        isFold={isFold}
        noTopHeader={noTopHeader}
        full={
          (isOpenNoteExternal || isOpenExternal) &&
          isLayerSmallThanWindow()
        }
        isNoticeBarVisible={isNoticeBarVisible}
        noticeBarHeight={height}
        noBottom={noBottom}
        shift={shift}
      >
        {topSlot}
        <Scrollbars ref={scrollRef} onScroll={handleScroll} autoHide>
          {header && (
            <div className="base-container-header">{header}</div>
          )}
          {children}
        </Scrollbars>
      </Wrapper>
      {foldBtn && !isMobile && <SummaryFoldBtn shift={shift} />}
    </>
  )
}

const Wrapper = styled.article`
  position: fixed;
  z-index: 13;
  width: ${({ full }) => (full ? `50%` : `${theme.base.container}px`)};
  height: 100%;
  left: 64px;
  padding-top: ${theme.base.headerHeight}px;
  background-color: ${({ white }) =>
    white ? theme.colors.base.white : theme.colors.gray[100]};
  box-shadow: rgba(0, 0, 0, 0.1) 1px 0 1px, rgba(0, 0, 0, 0.2) 0 0 16px;

  ${({ isNoticeBarVisible, noticeBarHeight }) =>
    isNoticeBarVisible
      ? `top: ${noticeBarHeight}px !important; 
    padding-bottom: ${noticeBarHeight}px;`
      : `top: 0; 
        padding-bottom: 0;`}

  ${media.mediumM`
    left: 0;
    ${({ isNoticeBarVisible, noticeBarHeight }) =>
      isNoticeBarVisible
        ? `top: ${noticeBarHeight}px; 
      padding-bottom: ${
        noticeBarHeight + theme.base.bottomMenuHeight
      }px;`
        : `top: 0px; 
          padding-bottom: 0;`}
  `}

  ${media.mediumS`
    width: 100%;

    padding-bottom: ${({ noBottom }) =>
      noBottom ? 0 : `${theme.base.bottomMenuHeight}`}px;

    ${({ highest }) =>
      highest &&
      `
        z-index: 13;
      `}
  `}

  ${({ shift }) =>
    shift &&
    `
    left: ${theme.base.noteSidebarWidth}px;
  `}

  ${({ isFold }) =>
    isFold &&
    `
		  width: 0;
		`}
    
  ${({ absolute }) =>
    absolute &&
    `
      padding-top: 0;
  `}

  ${({ external }) =>
    external &&
    `
    ${media.mediumS`
      display: none;
    `}
  `}

  ${({ bottomMenu }) =>
    bottomMenu &&
    `
  `}

  ${({ highest }) =>
    highest &&
    `
    z-index: 15;
  `}

  
  ${({ noTopHeader }) => noTopHeader && `top: 0;`}
`

export default Container
