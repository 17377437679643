import React, { Suspense, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import ChannelSelect from '../channel/ChannelSelect'
import NoteSetting from '../NoteSetting'
import Spinner from 'components/common/Loading/Spinner'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import {
  initPropertyParams,
  setNoteListSearchParams,
} from 'modules/notev2'
import { onlyUnique } from 'utils'
import propertyList from '../propertyData'
import useChannelInfo from 'containers/notev2/hooks/useChannelInfo'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { FilterScrollWrapper } from '../style/NoteStyle'
import useIsMobile from 'lib/hooks/useIsMobile'
import MobileChannelHeader from '../channel/MobileChannelHeader'
import Swipe from 'react-easy-swipe'
import { media } from 'lib/styles/theme'
import NoteFilterWrapper from '../style/NoteFilterWrapper'
import { useNoteMarker } from 'lib/hooks/useNoteMarker'
import { resetSpecificOpenedMarker } from 'modules/note'

const NoteLeftSide = ({
  onSwipeStart,
  onSwipeMove,
  onSwipeEnd,
  filtersCount,
  searchFnWithParam,
}) => {
  const dispatch = useDispatch()
  const channels = useSelector((state) => state.channel.channels)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const history = useHistory()
  const { q, property_types } = useSelector(
    (state) => state.notev2.noteListSearchParams,
  )
  const propertyFilters = useSelector(
    (state) => state.notev2.notePropertyFilter,
  )
  const isShowMap = useSelector(
    (state) => state.notev2.noteListIsShowMap,
  )
  const isMobile = useIsMobile()

  const { _getChannel, channelResult } = useChannelInfo()
  const { searchNoteMarkers } = useNoteMarker()

  useEffect(() => {
    dispatch(
      setNoteListSearchParams({
        property_types: initPropertyParams(),
        q: q,
      }),
    )
  }, [])

  const onChannelChange = (channelSrl, folderSrl) => {
    dispatch(resetSpecificOpenedMarker()) //매물 마커 강조 초기화
    dispatch(
      setNoteListSearchParams({
        property_types: initPropertyParams(),
        q: '',
      }),
    ) //필터 초기화

    const targetChannel = channels.find(
      ({ channel_srl: channels_srl }) => {
        return channels_srl == channelSrl
      },
    )
    if (!targetChannel) {
      message.error('잘못된 요청입니다.')
      return
    }

    _getChannel({ channelSrl })

    localStorage.setItem('recentFolderSrl', folderSrl) //카드모드 호환 위해 채널 바꿀 때 기본 폴더로 설정
  }

  useEffect(() => {
    if (!channelResult) {
      return
    }

    if (!channelResult?.channel_srl) {
      message.error('잘못된 요청입니다.')
      return
    }

    localStorage.setItem('recentChannelSrl', channelResult.channel_srl)
    history.push(`/note/v2/notes/${channelResult.channel_srl}`)
  }, [channelResult])

  const NoteFilter = useMemo(
    () => React.lazy(() => import('../NoteFilter')),
    [],
  )

  /**
   * 모든 매물 클릭시 필터 초기화
   */
  const onClickAllNotes = () => {
    const properties = propertyList.map(({ label }) => label)
    const result = {}
    properties.forEach((key) => (result[key] = []))
    dispatch(setNoteListSearchParams({ property_types: result, q: '' }))
    searchFnWithParam({
      propertyFilter: result,
      q: '',
    }) //검색
    setTimeout(() => {
      searchNoteMarkers('')
    })
  }

  /**
   * 대분류 눌렀을 때 동작
   */
  const onClickPanel = useCallback(
    (e, label, activeProperties) => {
      dispatch(
        setNoteListSearchParams({
          q: '',
        }),
      )

      const target = propertyFilters[label]

      const openCollapes = Object.keys(propertyFilters).filter(
        (item) => {
          return activeProperties.includes(item)
        },
      )

      //대분류에 해당하는 데이터 없을 때
      if (!propertyFilters[label]) {
        dispatch(
          setNoteListSearchParams({
            property_types: Object.assign({}, initPropertyParams(), {
              [label]: [['empty']],
            }),
          }),
        )
        return
      }

      //닫힘 -> 열림 동작일때만 and 클릭한 항목이 선택되지 않은 상태인 경우만 필터링 조회
      if (
        !openCollapes.includes(label) &&
        property_types[label].length == 0
      ) {
        if (propertyFilters[label]) {
          const firstKey = Object.keys(target)[0]

          if (!propertyFilters[label]?.[firstKey]) return

          const selectAllParam = {
            [label]: [propertyFilters[label][firstKey]?.code],
          }
          const newData = Object.assign(
            {},
            initPropertyParams(),
            selectAllParam,
          )
          dispatch(
            setNoteListSearchParams({
              property_types: newData,
            }),
          )
          setTimeout(() => {
            searchNoteMarkers(label)
          })
          searchFnWithParam({
            propertyFilter: newData,
            q: '',
          }) //검색
        }
      }
    },
    [propertyFilters, property_types, channelInfo, q],
  )

  /**
   * 소분류 클릭시 동작
   */
  const onClickItem = useCallback(
    ({ e, data, label, key }) => {
      const code = data[key].code

      const newParams = Object.assign({}, initPropertyParams())
      newParams[label] = [...newParams[label], ...[code]].filter(
        onlyUnique,
      )
      dispatch(
        setNoteListSearchParams({ property_types: newParams, q: '' }),
      )
      searchFnWithParam({
        propertyFilter: newParams,
        q: '',
      }) //검색
      setTimeout(() => {
        searchNoteMarkers(label)
      })
    },
    [property_types, channelInfo, q],
  )

  return (
    <>
      {isMobile && <MobileChannelHeader onChange={onChannelChange} />}
      <Wrapper
        ishidden={isShowMap ? 'Y' : 'N'}
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
        allowMouseEvents={false}
      >
        <NoteFilterWrapper>
          <Scrollbars autoHide>
            <FilterScrollWrapper>
              <>
                {!isMobile && (
                  <ChannelWrapper>
                    <ChannelSelect fixed onChange={onChannelChange} />
                  </ChannelWrapper>
                )}
                <Suspense
                  fallback={
                    <SpinnerWrapper>
                      <Spinner />
                    </SpinnerWrapper>
                  }
                >
                  <NoteFilter
                    filtersCount={filtersCount}
                    allTitle="모든 매물"
                    onClickPanel={onClickPanel}
                    onClickItem={onClickItem}
                    resetItem={onClickAllNotes}
                    propertyTarget={property_types}
                    propertyFilters={propertyFilters}
                  />
                </Suspense>
                <NoteSetting />
              </>
            </FilterScrollWrapper>
          </Scrollbars>
        </NoteFilterWrapper>
        {isMobile && <Cover />}
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Swipe)`
  width: 100%;
  height: 100%;
  ${media.mediumS`
    ${({ ishidden }) => ishidden == 'Y' && `display: none;`};
  `}
`

const ChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
  text-align: center;
`

const Cover = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 15;
  height: 100%;
  background: transparent;
`
export default React.memo(NoteLeftSide)
