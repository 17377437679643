import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidemenuVisible: false,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSidemenuVisible(state, action) {
      state.sidemenuVisible = action.payload
    },
  },
})

export const { setSidemenuVisible } = layoutSlice.actions

export default layoutSlice.reducer
