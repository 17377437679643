import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import TopHeaderItem from './TopHeaderItem'

const StyledBadge = styled.div`
  display: flex;
  position: absolute;
  right: -9px;
  top: -6px;
  font-size: 10px;
  line-height: 12px;
  background: #f44336;
  text-align: center;
  border-radius: 100%;
  border: 1px solid white;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  color: ${theme.colors.base.white};
  background-color: ${theme.colors.sub.red};
`

const TopHeaderNoty = ({ onClick, noMargin }) => {
  const dispatch = useDispatch()
  const notifications = useSelector(
    (state) => state.notification.notifications,
  )

  const getNotReadedNoties = (notifications) => {
    return notifications.filter((o) => o.readed !== 'Y')
  }
  const notReadedNoties = useMemo(
    () => getNotReadedNoties(notifications),
    [notifications],
  )

  return (
    <TopHeaderItem
      noMargin={noMargin}
      isLink={true}
      to="/notifications"
      as={NavLink}
      tooltip="알림"
      icon={
        <>
          {notReadedNoties.length > 0 && (
            <StyledBadge>
              {
                notReadedNoties.filter((item) => item.readed === 'N')
                  .length
              }
            </StyledBadge>
          )}
          <i className="far fa-bell"></i>
        </>
      }
      onClick={onClick}
    />
  )
}

export default TopHeaderNoty
