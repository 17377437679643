import { forwardRef } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledTextarea = styled.textarea`
  padding: 1rem;
  width: 100%;
  min-height: 150px;

  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  font-weight: 400;
  /* vertical-align: auto; */
  border-radius: ${theme.borderRadius[1]};
  background-color: ${theme.colors.gray[50]};
  border: 1px solid ${theme.colors.gray[100]};

  box-sizing: border-box;
  appearance: none;
  outline: none !important;
  box-shadow: none;

  &[disabled] {
    color: ${theme.colors.gray[600]};
    cursor: not-allowed;
  }
  &::placeholder {
    color: ${theme.colors.gray[500]};
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    background-color: ${theme.colors.base.white};
    border-color: ${theme.colors.primary[400]};
    ${({ border }) =>
      border == 'blue' &&
      `
      border-color: ${theme.colors.blue[400]};
    `}
  }
`

const Textarea = forwardRef((props, ref) => {
  return <StyledTextarea {...props} ref={ref} />
})

export default Textarea
