import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Confirm } from 'components/design'
import { ListItem } from 'components/design'
import { ListItemDesc } from 'components/design'
import { ListItemIcon } from 'components/design'
import { ListItemLabel } from 'components/design'
import { ListItemMenu } from 'components/design'
import theme from 'lib/styles/theme'
import moment from 'moment'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Tag } from 'components/design'
import { useState } from 'react'

import CalendarModify from 'components/jmapnote/calendar/CalendarModify'
import NoteScheduleModify from './NoteScheduleModify'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'

const NoteScheduleItem = ({
  readOnly,
  sheet,
  item,
  myContacts,
  onRemove,
  onRefresh,
}) => {
  const { hasPlan } = useNoteHasPlan()
  const history = useHistory()
  const { channelSrl } = useNoteMatch()
  const [modifyVisible, setModifyVisible] = useState(false)

  const tagColors = {
    상담: 'green',
    계약일: 'orange',
    입주가능: 'blue',
    입주예정: 'blue',
    중도금일: 'purple',
    잔금일: 'pink',
    계약만기: 'red',
    매물방문: 'green',
    기타: '',
  }

  const moveToCalendar = (date) => {
    history.push({
      pathname: `/note/${channelSrl}/calendar`,
      search: `?date=${date}`,
    })
  }

  const onRemoveItem = () => {
    Confirm.error({
      title: '이 일정을 삭제하시겠습니까?',
      // content:
      //   '해당 사용자를 삭제하면 다시 초대하기 전까지 채널에 대한 접근 권한을 잃게 됩니다.',
      okText: '삭제',
      cancelText: '취소',
      onOk: () => onRemove(item.calendar_srl),
    })
  }

  const onSuccessModify = () => {
    setModifyVisible(false)
    onRefresh()
  }

  return (
    <>
      <StyledListItem
        sheet={sheet}
        noContainer={sheet}
        hover
        padding="14px"
        append={
          !readOnly && (
            <>
              <ListItemIcon onClick={() => setModifyVisible(true)}>
                <i className="far fa-edit"></i>
              </ListItemIcon>

              {!hasPlan && (
                <ListItemIcon onClick={() => moveToCalendar(item.date)}>
                  <i className="far fa-calendar-check"></i>
                </ListItemIcon>
              )}
              <ListItemIcon onClick={onRemoveItem}>
                <i className="far fa-trash-alt"></i>
              </ListItemIcon>
            </>
          )
        }
      >
        <Date>
          <TypeIcon color={tagColors[item.type] || 'blue'} />
          <span>
            {moment(item.date).format('YYYY년 MMM Do (dddd)')}
          </span>
        </Date>
        <ListItemLabel>{item.type}</ListItemLabel>
        {item.content && <ListItemDesc>{item.content}</ListItemDesc>}
        {item?.contacts?.length > 0 && (
          <ListItemDesc>
            <TagList>
              {item.contacts.map(
                (item, i) =>
                  item.alias && (
                    <TagWrap key={i}>
                      <Tag>
                        {item.alias}&nbsp;({item.numbers?.[0]?.number})
                      </Tag>
                    </TagWrap>
                  ),
              )}
            </TagList>
          </ListItemDesc>
        )}
      </StyledListItem>

      <NoteScheduleModify
        visible={modifyVisible}
        setVisible={setModifyVisible}
        myContacts={myContacts}
        defaultValue={item}
        calendarSrl={item?.calendar_srl}
        onSuccess={onSuccessModify}
      />
    </>
  )
}

const StyledListItem = styled(ListItem)`
  ${({ sheet }) =>
    sheet &&
    `
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
  `}
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;
`
const TagWrap = styled.div`
  padding: 0.25rem;
`

const Date = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
`

const TypeIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border-radius: 4px;

  background-color: ${({ color }) => {
    switch (color) {
      case 'blue':
        return '#425FF8'
      case 'green':
        return '#16b661'
      case 'red':
        return '#D03232'
      case 'orange':
        return '#F88E42'
      case 'purple':
        return '#9142f8'
      case 'pink':
        return '#c54ae4'
      default:
        return '#425FF8'
    }
  }};
`

export default NoteScheduleItem
