import { message } from 'antd'
import { Button, Checkbox, Empty } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { useSelector } from 'react-redux'
import useAxios from 'lib/hooks/useAxios'
import { getAdFilters, getAdListings } from 'lib/api/notev2/adList'

/**
 * 올인원 전용
 * 요금제 해지(환불 예정) confirm 모달 step1
 * @returns
 */
const NotePaymentAllInOneCancelConfirmStep1 = ({ setStep, toFree }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const routerHistory = useHistory()
  const [isChecked, setIsChecked] = useState(false)
  const [onRequestFilters, , dataFilters] = useAxios(getAdFilters)
  const [onRequestList, , dataList] = useAxios(getAdListings)
  const [adList, setAdList] = useState([])

  //광고 상태별 개수
  useEffect(() => {
    onRequestFilters({
      plan_srl: planInfo.allinone_plan_srl,
    })

    onRequestList({
      plan_srl: planInfo.allinone_plan_srl,
      count: 5,
      page: 1,
      filters: ['검증중', '광고중'],
    })
  }, [])

  const onClickCheck = () => {
    setIsChecked(!isChecked)
  }

  const goAdList = () => {
    routerHistory.push(`/note/v2/ad`)
  }

  const goBack = () => {
    routerHistory.goBack()
  }

  const goNext = () => {
    if (!isChecked) {
      message.warning(
        `${
          toFree ? '해지' : '변경'
        } 신청 안내를 읽고 확인에 체크 해주세요.`,
      )
      return
    }
    setStep(2)
  }

  return (
    <Wrapper>
      <Title>
        올인원 {toFree ? '해지' : '변경'} 신청을 하시기 전에
        <br />
        안내사항을 꼭 확인해 주세요.
      </Title>
      <Block>
        <BlockTitle>
          {moment(planInfo.next_payment_date, 'YYYYMMDDHHmmss').format(
            'YYYY년 MM월 DD일',
          )}{' '}
          이후
        </BlockTitle>
        <BlockItem>
          모든 네이버 매물은 <span>광고 게시가 중단</span>됩니다.
        </BlockItem>
        <BlockItem>
          검증 중이던 매물은 <span>자동으로 검증 취소</span>됩니다.
        </BlockItem>
        <BlockItem>
          환불로 인해 광고 기간을 채우지 못하거나 검증 비용이 소요된
          부분에 대해서는 책임지지 않습니다.
        </BlockItem>
      </Block>
      <AdListWrapper>
        <AdListTitle>
          <div>
            현재 광고중
            <span>{dataFilters?.result?.['광고중'] || 0}건</span>
          </div>
          <div>
            검증중 <span>{dataFilters?.result?.['검증중'] || 0}건</span>
          </div>
        </AdListTitle>
        <AdList>
          {dataList?.result && dataList?.result.length > 0 ? (
            dataList.result.map(({ contract_type, address }) => {
              return (
                <AdListItem>
                  <AdListItemTitle>
                    <span>{contract_type}</span>
                    <span>{address}</span>
                  </AdListItemTitle>
                  {/* <AdListItemDate>~24.24.24 까지</AdListItemDate> */}
                </AdListItem>
              )
            })
          ) : (
            <Empty>광고중이거나 검증중인 데이터가 없습니다.</Empty>
          )}
        </AdList>
        <ListButton>
          <Button color="blue" plain onClick={goAdList}>
            전체 광고이력 보러가기
          </Button>
        </ListButton>
      </AdListWrapper>
      <StyledCheckbox
        checked={isChecked}
        onChange={onClickCheck}
        color="blue"
      >
        위 {toFree ? '해지' : '변경'} 신청 안내를 읽고 확인하였습니다.
      </StyledCheckbox>
      <ButtonWrapper>
        <Button color="gray" size="xlarge" onClick={goBack}>
          취소
        </Button>
        <Button color="blue" size="xlarge" onClick={goNext}>
          다음
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 553px;
`

export const Title = styled.div`
  margin-bottom: 32px;
  color: ${theme.colors.gray[800]};
  font-size: 30px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;

  ${media.mediumS`
    font-size: 24px;
  `}
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 1px solid ${theme.colors.gray[150]};

  ${media.mediumS`
    padding: 24px 16px;
  `}
`

export const BlockItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${theme.colors.gray[600]};

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    color: ${theme.colors.gray[900]};
  }

  &::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
    color: ${theme.colors.blue[400]};
  }
`

const AdListWrapper = styled.div`
  padding: 28px 24px;
  margin-bottom: 32px;
  border-radius: 12px;
  background: ${theme.colors.gray[50]};

  ${media.mediumS`
    position: relative;
    margin: 0 -20px 30px -20px;
    padding: 28px 16px;    
    width: 100vw;
  `}
`

const AdListTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;

  & > div {
    display: flex;
    align-items: center;

    &:first-child {
      span {
        color: ${theme.colors.primary[500]};
      }
      &::after {
        content: '·';
        color: #d9d9d9;
        font-size: 24px;
        line-height: 100%;
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    &:last-child {
      span {
        color: ${theme.colors.blue[500]};
      }
    }

    span {
      margin-left: 2px;
      font-size: 18px;
      font-weight: 700;
      line-height: 130%;
    }
  }

  &::before {
    content: '\f06a';
    font-family: 'Font Awesome 5 Pro';
    color: #e3e5e6;
    margin-right: 6px;
  }
`

const AdList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 18px 24px;
  margin-bottom: 12px;
  background-color: ${theme.colors.base.white};
`

const AdListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const AdListItemTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  width: 100%;

  & > span {
    &:first-child {
      color: ${theme.colors.gray[400]};
      flex-shrink: 0;
    }

    &:last-child {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 18px;
  align-self: center;
`

export const StyledCheckbox = styled(Checkbox)`
  align-self: center;
`

const ListButton = styled.div`
  width: 100%;
  text-align: center;
`

const BlockTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
`

export default NotePaymentAllInOneCancelConfirmStep1
