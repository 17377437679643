import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  legalPlanning: null,
}

export const summaryOtherSlice = createSlice({
  name: 'summaryOther',
  initialState,
  reducers: {
    setSummaryLegalPlanning(state, actions) {
      state.legalPlanning = actions.payload
    },
  },
})

export const { setSummaryLegalPlanning } = summaryOtherSlice.actions

export default summaryOtherSlice.reducer
