import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { deleteAdFile } from 'lib/api/notev2/adForm'
import useAxios from 'lib/hooks/useAxios'
import { ReactComponent as PDFIcon } from 'assets/icon/note/ad/file-pdf.svg'
import { useSelector } from 'react-redux'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 검증참고파일 referenceFileUrl
 */
const NoteAdColumnVerificationFileItem = ({
  file_srl,
  thumbnail,
  source_filename,
  mime_type,
  uploaded_filename,
}) => {
  const { watch } = useFormContext()

  const planInfo = useSelector((state) => state.auth.planInfo)

  const [onRequestDelete, , dataDelete] = useAxios(deleteAdFile, {
    resetError: true,
  }) //파일 삭제
  const [deletedFileSrl, setDeletedFileSrl] = useState([])

  const adSrl = watch('ad_srl')

  /**
   * 파일 삭제
   */
  const onClickFileDelete = () => {
    onRequestDelete({
      ad_srl: adSrl,
      file_srl: file_srl,
      type: 'reference',
      plan_srl: planInfo.allinone_plan_srl,
    })
  }

  useEffect(() => {
    if (!dataDelete) return

    if (dataDelete?.error != 0) return

    setDeletedFileSrl((prev) => [...prev, ...[file_srl]])
  }, [dataDelete])

  if (deletedFileSrl.includes(file_srl)) return null

  return (
    <>
      <FileItem>
        <i className="fal fa-times" onClick={onClickFileDelete}></i>
        {/* 파일 확장자에 따라 파일 이미지 보여주기 */}
        {mime_type == 'application/pdf' ? (
          <PDFIcon />
        ) : thumbnail || uploaded_filename ? (
          <img src={thumbnail || uploaded_filename} />
        ) : null}
        {source_filename}
      </FileItem>
    </>
  )
}

const FileItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;

  img {
    width: 21px;
    height: 21px;
    object-fit: contain;
    border-radius: 2px;
  }

  i {
    cursor: pointer;
  }
`

export default NoteAdColumnVerificationFileItem
