import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  InnerTableContent,
  InnerTableBlock,
  innerSubHeaderWidth,
  StyledTable,
  headerWidth,
  AmongSpan,
  RadioButtonsWrap,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { useFormContext } from 'react-hook-form'

const NoteDocsDetail12 = ({ onUpdate }) => {
  const { watch } = useFormContext()

  const d_iljolyang = watch('d_iljolyang')
  const d_iljolyang_reason = watch('d_iljolyang_reason')
  const d_soeum = watch('d_soeum')
  const d_jindong = watch('d_jindong')
  const d_descript_type = watch('d_descript_type')

  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑬'
    } else {
      return '⑫'
    }
  }

  return (
    <StyledTable sectionspace={d_descript_type != '주거용 건축물'}>
      <Row height={33} rowspan={2} noborderbottom>
        <TableHeader width={headerWidth}>
          {getNumber()} 환경
          <br />
          조건
        </TableHeader>
        <RowSpanInner
          rowspanheightpercent={50}
          rowspan={2}
          minuswidth={headerWidth}
        >
          <TableSubHeader width={headerWidth}>일조량</TableSubHeader>
          <InnerTableContent
            headerwidth={headerWidth}
            checkboxes
            fullWidth
          >
            <RadioButtonsWrap width={300}>
              <RadioButton
                label="풍부함"
                checked={d_iljolyang == '풍부함'}
                name="d_iljolyang"
                onChange={(e) => onUpdate('d_iljolyang', '풍부함')}
              />
              <RadioButton
                label="보통임"
                checked={d_iljolyang == '보통임' || d_iljolyang === ''}
                name="d_iljolyang"
                onChange={(e) => onUpdate('d_iljolyang', '보통임')}
              />
              <RadioButton
                label="불충분"
                checked={d_iljolyang == '불충분'}
                name="d_iljolyang"
                onChange={(e) => onUpdate('d_iljolyang', '불충분')}
              />
            </RadioButtonsWrap>
            <AmongSpan>&#40; 이유: </AmongSpan>
            <ColorInput
              type="text"
              defaultValue={d_iljolyang_reason}
              disabled={d_iljolyang != '불충분'}
              onBlur={(e) =>
                onUpdate('d_iljolyang_reason', e.target.value)
              }
              fullWidth
            />
            &#41;
          </InnerTableContent>
        </RowSpanInner>
        <RowSpanInner
          rowspanheightpercent={50}
          rowspan={2}
          minuswidth={headerWidth}
          noborderbottom
        >
          <InnerTableBlock widthpercent={50} noborderbottom>
            <TableSubHeader width={headerWidth}>소음</TableSubHeader>
            <InnerTableContent checkboxes headerwidth={headerWidth}>
              <RadioButtonsWrap width={300}>
                <RadioButton
                  label="아주 작음"
                  checked={d_soeum == '아주 작음'}
                  name="d_soeum"
                  onChange={(e) => onUpdate('d_soeum', '아주 작음')}
                />
                <RadioButton
                  label="보통임"
                  checked={d_soeum == '보통임' || d_soeum == ''}
                  name="d_soeum"
                  onChange={(e) => onUpdate('d_soeum', '보통임')}
                />
                <RadioButton
                  label="심한 편임"
                  checked={d_soeum == '심한 편임'}
                  name="d_soeum"
                  onChange={(e) => onUpdate('d_soeum', '심한 편임')}
                />
              </RadioButtonsWrap>
            </InnerTableContent>
          </InnerTableBlock>
          <InnerTableBlock
            widthpercent={50}
            noborderbottom
            noborderright
          >
            <TableSubHeader width={headerWidth}>진동</TableSubHeader>
            <InnerTableContent checkboxes headerwidth={headerWidth}>
              <RadioButtonsWrap>
                <RadioButton
                  label="아주 작음"
                  checked={d_jindong == '아주 작음'}
                  name="d_jindong"
                  onChange={(e) => onUpdate('d_jindong', '아주 작음')}
                />
                <RadioButton
                  label="보통임"
                  checked={d_jindong == '보통임' || d_jindong == ''}
                  name="d_jindong"
                  onChange={(e) => onUpdate('d_jindong', '보통임')}
                />
                <RadioButton
                  label="심한 편임"
                  checked={d_jindong == '심한 편임'}
                  name="d_jindong"
                  onChange={(e) => onUpdate('d_jindong', '심한 편임')}
                />
              </RadioButtonsWrap>
            </InnerTableContent>
          </InnerTableBlock>
        </RowSpanInner>
      </Row>
    </StyledTable>
  )
}

export default NoteDocsDetail12
