import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NoteDashboardNotiContainer = ({ children }) => {
  const history = useHistory()
  const notifications = useSelector(
    (state) => state.notification.notifications,
  )

  const data = notifications?.slice(0, 5)

  const onClickMore = () => {
    history.push('/notifications')
  }

  return children({
    data,
    onClickMore,
  })
}

export default NoteDashboardNotiContainer
