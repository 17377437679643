import moment from 'moment'

export const getIsServiceSizeReview = ({
  buildingInfo,
  sido,
  land_area,
  zone,
}) => {
  if (!buildingInfo) return false

  const isServiceDefault =
    getIsServiceSido(sido) == '' &&
    getIsServiceLandArea(land_area) == '' &&
    getIsServiceZone(zone[0], zone[1]) == ''

  if (isServiceDefault) {
    return buildingInfo?.result?.length > 0
      ? getIsBuildYear(buildingInfo) == ''
        ? { isService: true }
        : { isService: false, msg: getIsBuildYear(buildingInfo) }
      : { isService: true }
  } else {
    const svcMsgArr = [
      getIsServiceSido(sido),
      getIsServiceLandArea(land_area),
      getIsServiceZone(zone[0], zone[1]),
    ]
    const result = svcMsgArr.filter((str) => str != '')

    return { isService: false, msg: result[0] }
  }
}

const getIsServiceSido = (sido) => {
  const serviceSido = [
    '서울특별시',
    '경기도',
    '세종특별자치시',
    '부산광역시',
    '대전광역시',
    '대구광역시',
    '인천광역시',
    '광주광역시',
    '울산광역시',
  ]

  return serviceSido.includes(sido)
    ? ''
    : '현재 서비스를 제공하는 지역이 아닙니다.'
}

const getIsServiceLandArea = (area) => {
  const areaNumber = parseFloat(area)

  return areaNumber >= 50 && 1000 >= areaNumber
    ? ''
    : '토지 면적이 50㎡ 이상 1000㎡ 이하인 토지에만 서비스를 제공하고 있습니다.'
}

const getIsServiceZone = (zone, zone2) => {
  const serviceZone = [
    '제1종일반주거지역',
    '제1종전용주거지역',
    '제2종일반주거지역',
    '제2종전용주거지역',
    '제3종일반주거지역',
  ]
  return (
    serviceZone.includes(zone)
      ? serviceZone.includes(zone)
      : serviceZone.includes(zone2)
  )
    ? ''
    : '용도지역이 적합하지 않습니다.'
}

const getIsBuildYear = (building) => {
  Array.prototype.hasMax = function (attrib) {
    const checker = (o, i) => typeof o === 'object' && o[i]
    return (
      (this.length &&
        this.reduce(function (prev, curr) {
          const prevOk = checker(prev, attrib)
          const currOk = checker(curr, attrib)
          if (!prevOk && !currOk) return {}
          if (!prevOk) return curr
          if (!currOk) return prev
          return prev[attrib] > curr[attrib] ? prev : curr
        })) ||
      null
    )
  }

  const { approval_date } = building.result.hasMax('approval_date')
  const { begin_construction_date } = building.result.hasMax(
    'begin_construction_date',
  )
  const { permission_date } = building.result.hasMax('permission_date')

  //사용승인일, 착공시작일, 허가일 순으로 비교
  const buildDate = approval_date
    ? approval_date
    : begin_construction_date
    ? begin_construction_date
    : permission_date

  const result = !buildDate
    ? true
    : calcIsBuildYear(buildDate.substr(0, 4))

  return result
    ? ''
    : '건물이 지어진 토지인 경우, 건물 준공년도가 20년 이상 지난 경우에만 서비스를 제공하고 있습니다.'
}

const calcIsBuildYear = (date) => {
  const currYear = moment(new Date()).format('YYYY')
  const diff = currYear - date

  return diff >= 19
}

/**
 * m, km 변환
 * @param {*} distance
 * @returns
 */
export const getDistance = (distance) => {
  let convertDistance
  let unit
  if (distance >= 1000) {
    convertDistance = (distance / 1000).toFixed(2)
    unit = 'km'
  } else {
    convertDistance = Math.round(distance)
    unit = 'm'
  }

  return `${parseFloat(convertDistance)}${unit}`
}
