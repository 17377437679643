import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import useIsMobile from 'lib/hooks/useIsMobile'

import BaseNavHome from './BaseNavHome'
import BaseNavSearch from './BaseNavSearch'
import BaseNavNote from './BaseNavNote'
import BaseNavMore from './BaseNavMore'
import BaseNavFavorite from './BaseNavFavorite'
import BaseNavNoty from './BaseNavNoty'
import useIsTablet from 'lib/hooks/useIsTablet'
import { useContext } from 'react'
import BaseContext from 'contexts/baseContext'
import BaseGoBack from './BaseGoBack'
import useIsIOSApp from 'lib/hooks/useIsIOSApp'

const BaseNav = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isIOSApp = useIsIOSApp()

  const {
    actions: { setIsFold },
  } = useContext(BaseContext)
  const hiddenPages = useRouteMatch({
    path: ['/note/v2'],
  })
  const visiblePages = useRouteMatch({
    path: ['/search', '/notifications'],
  })

  const memberType =
    JSON.parse(localStorage.getItem('memberType')) || ''

  if (isMobile) {
    if (!visiblePages && hiddenPages) return null
  }
  if (isTablet) {
    if (!visiblePages && hiddenPages) return null
  }

  return (
    <Nav>
      <NavList onClick={() => setIsFold(false)}>
        {/* IOS 앱일때 뒤로가기 버튼 생성 */}
        {isIOSApp && <BaseGoBack />}

        <BaseNavHome />
        <BaseNavSearch />

        {!memberType && loginInfo?.member_type === 'realestate' && (
          <BaseNavNote />
        )}
        {!memberType && loginInfo?.member_type !== 'realestate' && (
          <BaseNavFavorite />
        )}

        {memberType && memberType === 'realestate' && <BaseNavNote />}
        {!memberType ||
          (memberType !== 'realestate' && <BaseNavFavorite />)}

        <BaseNavNoty />
        <BaseNavMore />
      </NavList>
    </Nav>
  )
}

const Nav = styled.nav`
  display: none;
  position: fixed;

  ${media.mediumM`
    display: block;
    top: auto;
    bottom: 0;
    left: 0;
    z-index: 70;
    width: 100%;
    height: calc(
      ${theme.base.bottomMenuHeight}px + env(safe-area-inset-bottom)
    );
    padding-bottom: env(safe-area-inset-bottom);
    background-color: ${theme.colors.base.white};
    border-top: 1px solid ${theme.colors.gray[200]};
  `}
`

const NavList = styled.ul`
  list-style: none;

  ${media.mediumM`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
  `}
`

export default BaseNav
