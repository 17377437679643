/**
 * 누른 키가 enter key인지 여부(그리드용)
 * @param {*} params
 * @returns
 */
export const suppressEnter = (params) => {
  const KEY_ENTER = 'Enter'
  const key = params.event.key
  const suppress = key === KEY_ENTER
  return suppress
}
/**
 * 누른 키가 방향키인지 여부
 * @param {*} params
 * @returns
 */
export const suppressArrow = (params) => {
  const keyCode = params.event.keyCode
  return (
    keyCode == 39 || keyCode == 37 || keyCode == 38 || keyCode == 40
  )
}

/**
 * 누른 키가 커서 제어키(Home, End)키 인지 여부
 * @param {*} params
 * @returns
 */
export const suppressTextCursorControl = (params) => {
  const keyCode = params.event.keyCode
  return keyCode == 35 || keyCode == 36
}

/**
 * 누른 키가 오,왼 방향키인지 여부(그리드용)
 * @param {*} params
 * @returns
 */
export const suppressSideArrowGrid = (event) => {
  const keyCode = event.keyCode
  return keyCode == 39 || keyCode == 37
}

/**
 * 누른 키가 커서 제어키(Home, End)키 인지 여부(그리드용)
 * @param {*} params
 * @returns
 */
export const suppressTextCursorControlGrid = (event) => {
  const keyCode = event.keyCode
  return keyCode == 35 || keyCode == 36
}

/**
 * 누른 키가 방향키인지 여부(그리드용)
 * @param {*} params
 * @returns
 */
export const suppressArrowGrid = (event) => {
  const keyCode = event.keyCode
  return (
    keyCode == 39 ||
    keyCode == 37 ||
    keyCode == 38 ||
    keyCode == 40 ||
    keyCode == 27
  )
}

export const isPressEditKey = (event) => {
  const keyCode = event.keyCode
  const charStr = String.fromCharCode(keyCode)

  return (
    /[a-z0-9]/i.test(charStr) ||
    [
      192, 187, 189, 188, 190, 191, 219, 220, 221, 96, 8, 32, 13,
    ].includes(keyCode)
  )
}
