import NoteDashboardDeunggibuContainer from 'containers/notev2/NoteDashboardDeunggibuContainer'
import NoteDashboardBlock from 'components/jmapnotev2/NoteDashboard/NoteDashboardBlock'
import NoteDashboardDeunggibuTable from 'components/jmapnotev2/NoteDashboard/NoteDashboardDeunggibuTable'
import styled from 'styled-components'
import PagingWithNumber from 'components/design/Paging/PagingWithNumber'
import { Empty } from 'components/design'

const NoteDashboardDeunggibu = () => {
  return (
    <NoteDashboardDeunggibuContainer>
      {({ limitedData, list, currentPage, setCurrentPage, count }) => {
        return (
          <NoteDashboardBlock title="공부문서">
            {list && list.length > 0 ? (
              <>
                <NoteDashboardDeunggibuTable data={limitedData} />
                <PagingWithNumber
                  data={list}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  perPage={count}
                />
              </>
            ) : (
              <Empty>발급받은 공부문서가 없습니다.</Empty>
            )}
          </NoteDashboardBlock>
        )
      }}
    </NoteDashboardDeunggibuContainer>
  )
}

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 20px;
`

export default NoteDashboardDeunggibu
