import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 가격정보 - 채권금액
 */
const NoteAdColumnPresaleDebenture = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const price = watch('isale.bond')

  return (
    <ColumnSet half>
      <ColumnTitle>채권금액</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="number"
            defaultValue={price}
            {...register('isale.bond')}
            onBlur={(e) => {
              onUpdate('isale.bond', e.target.value.replaceAll(',', ''))
            }}
            append={'만원'}
          />
        </div>
        <ValidateWarn>{errors?.isale?.bond?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnPresaleDebenture
