import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsContractSign = ({ onUpdate }) => {
  const { watch, register } = useFormContext()
  const sign_date = watch('sign_date')

  return (
    <Wrapper>
      <span>
        ※ 본 계약을 증명하기 위하여 계약당사자가 이의없음을 확인하고
        각자 서명 · 날인한다.
      </span>
      <ColorInput
        type="date"
        value={sign_date}
        selected={sign_date ? moment(sign_date).toDate() : ''}
        onChange={(e) =>
          onUpdate('sign_date', e ? moment(e).format('YYYY-MM-DD') : '')
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;

  & > span {
    margin-right: 16px;
  }
`

export default NoteDocsContractSign
