import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { procMemberLogout } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import TopHeaderNoty from '../TopHeader/TopHeaderNoty'

const SideMenuLogout = ({ closeFn }) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const [onRequest, loading, data, , ,] = useAxios(procMemberLogout)

  const handleLogout = useCallback(() => {
    onRequest({
      _rx_csrf_token: loginInfo.csrf_token, // CSRF 토큰 세팅
    })
  }, [loginInfo])

  useEffect(() => {
    if (data?.message !== 'success') return

    window.location.reload() // 새로고침
  }, [data])

  if (!loginInfo?.member_srl) return null

  return (
    <Wrapper>
      <TopHeaderNoty noMargin onClick={closeFn} />
      <StyledButton size="small" color="default" onClick={handleLogout}>
        <i className="fal fa-sign-out"></i>
      </StyledButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
`

const StyledButton = styled.div`
  cursor: pointer;
  color: ${theme.colors.gray[600]};
`

export default SideMenuLogout
