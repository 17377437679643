import { List } from 'components/design'
import ChannelSelectItem from './ChannelSelectItem'

const ChannelSelectList = ({
  title,
  selectedChannelSrl,
  channels,
  onChange,
}) => {
  return (
    <List title={title}>
      {channels.map((item, i) => (
        <ChannelSelectItem
          item={item}
          key={i}
          channelSrl={selectedChannelSrl}
          onClick={onChange}
        />
      ))}
    </List>
  )
}

export default ChannelSelectList
