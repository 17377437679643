import { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { groupBy } from 'lodash-es'
import theme, { media } from 'lib/styles/theme'
import {
  Tab,
  TabItem,
  List,
  ListItem,
  Tag,
  Modal,
} from 'components/design'
import { buildingPurposeColors } from 'utils'
import MapContext from 'contexts/map'
import useIsMobile from 'lib/hooks/useIsMobile'
import styled from 'styled-components'
import { setSelectedAptArea } from 'modules/summary'

const SelectArea = ({
  selectedArea,
  setSelectedArea,
  visible,
  setVisible,
  maxAreas,
  setMaxAreas,
  areaTypes,
}) => {
  const dispatch = useDispatch()

  const [infoVisible, setInfoVisible] = useState(false)
  const [selectedPurposes, setSelectedPurposes] = useState()
  const [purposeTitleArr, setPurposeTitleArr] = useState([])
  const [selectedTabData, setSelectedTabData] = useState([])

  const [areaItems, setAreaItems] = useState([])

  const [visibleDong, setVisibleDong] = useState([])

  const isMobile = useIsMobile()

  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  useEffect(() => {
    if (!selectedArea) return

    const result = areaTypes.map((areaItem) => {
      return {
        ...areaItem,
        purpose_type: areaItem.purpose == '기타' ? '상가' : '주택',
      }
    })

    setAreaItems(result)

    setPurposeTitleArr(
      Object.keys(groupBy(result, 'purpose_type')).sort(
        compStringReverse,
      ),
    )

    const initPurpose = result.filter((item) => {
      return item.id === selectedArea.id
    })[0]?.purpose_type

    setSelectedTabData(groupBy(result, 'purpose_type')[initPurpose])
    setSelectedPurposes(initPurpose)
  }, [selectedArea])

  useEffect(() => {
    setVisibleDong([])
  }, [visible])

  function compStringReverse(a, b) {
    if (a > b) return -1
    if (b > a) return 1
    return 0
  }

  const getPropertyTypeColor = (type) => {
    return type
      ? typeof buildingPurposeColors[type] !== 'undefined'
        ? buildingPurposeColors[type]
        : null
      : null
  }

  const handleVisibleDongArea = (id) => {
    visibleDong.indexOf(id) === -1
      ? setVisibleDong([...visibleDong, id])
      : setVisibleDong(visibleDong.filter((item) => item !== id))
  }

  const onClickAreaItem = (area) => {
    //기본 세대수 내림차순 정렬 면적 3개에 포함되어있는 면적인지 여부
    const isItemOfMaxArea =
      maxAreas.filter(
        ({ exclusive_area_type, supply_area_py, purpose }) =>
          area.exclusive_area_type == exclusive_area_type &&
          area.supply_area_py == supply_area_py &&
          area.purpose == purpose,
      ).length > 0

    //기본 세대수 내림차순 3개에 포함되지 않으면 선택한것 + 세대수 많은거 2개 보여줌
    if (!isItemOfMaxArea) {
      const filteredAreaArr = [area].concat(
        [...areaTypes]
          .filter(({ id }) => area.id != id)
          .sort(
            (a, b) =>
              parseFloat(b.house_count) - parseFloat(a.house_count),
          )
          .slice(0, 2),
      )

      setMaxAreas(filteredAreaArr)
    }

    setSelectedArea(area)
    dispatch(setSelectedAptArea(area))
    setVisible(false)
  }

  const getSelectedItem = (purpose) => {
    setSelectedPurposes(purpose)
    setSelectedTabData(groupBy(areaItems, 'purpose_type')[purpose])
  }

  return (
    <div>
      <Modal
        visible={visible}
        title={
          <ModalTitle>
            <span>면적 선택</span>
            <HelpIcon onClick={() => setInfoVisible(true)}>
              <i className="fas fa-info-circle" />
            </HelpIcon>
          </ModalTitle>
        }
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <CustomTab className="tab" size="large" noContainer>
          {purposeTitleArr.map((purpose, index) => {
            return (
              <TabItem
                key={index}
                onClick={() => getSelectedItem(purpose)}
                active={selectedPurposes === purpose}
                color="primary"
                expand
              >
                {purpose}
              </TabItem>
            )
          })}
        </CustomTab>
        <CustomList>
          {selectedTabData &&
            selectedTabData.length > 0 &&
            selectedTabData.map((area, index) => {
              return (
                <List key={index} noMargin>
                  <ListItem
                    dense={isMobile && true}
                    noContainer
                    actived={selectedArea?.id === area?.id}
                    onClick={() => onClickAreaItem(area)}
                  >
                    <ListWrap>
                      <ListTop>
                        <TitleWrap
                          actived={selectedArea?.id === area?.id}
                        >
                          <TitleAreaWrap>
                            <Area>
                              {' '}
                              {isSquareMeter
                                ? `${(
                                    area?.supply_area_py * 3.3057
                                  ).toFixed(2)}㎡`
                                : `${area?.supply_area_py}평`}
                            </Area>
                          </TitleAreaWrap>
                          <TitleTagWrap>
                            <Tag
                              color={
                                getPropertyTypeColor(area.purpose)
                                  ? getPropertyTypeColor(area.purpose)
                                  : 'pink'
                              }
                              label={area.purpose || '건물없음'}
                              labelTooltip="건물 타입"
                              tooltip="연면적"
                            ></Tag>
                            <Tag
                              color={
                                selectedArea?.id === area?.id
                                  ? 'primary'
                                  : 'gray'
                              }
                              onClick={(e) => {
                                e.stopPropagation()
                                area.dong_list &&
                                  handleVisibleDongArea(area.id)
                              }}
                              actived
                            >
                              {area.house_count}세대
                              {area.dong_list &&
                                (visibleDong.indexOf(area.id) > -1 ? (
                                  <i className="fa fa-angle-up"></i>
                                ) : (
                                  <i className="fa fa-angle-down"></i>
                                ))}
                            </Tag>
                          </TitleTagWrap>
                        </TitleWrap>
                        <DongWrap>
                          <DongList
                            visible={
                              visibleDong.indexOf(area.id) > -1 &&
                              area.dong_list
                            }
                          >
                            {area.dong_list &&
                              area.dong_list
                                .split(',')
                                .map((dong, dongIndex, oriDongArr) => (
                                  <span key={dongIndex}>
                                    {dong}
                                    {dongIndex ===
                                      oriDongArr.length - 1 || ', '}
                                  </span>
                                ))}
                          </DongList>
                        </DongWrap>
                      </ListTop>
                      <ListBottom>
                        <AreaWrap>
                          전용면적:&nbsp;{' '}
                          {area.min_exclusive_area && (
                            <span>
                              {isSquareMeter
                                ? area.min_exclusive_area + '㎡'
                                : Math.floor(
                                    area.min_exclusive_area / 3.3057,
                                  ) + '평'}{' '}
                              ~{' '}
                            </span>
                          )}
                          {isSquareMeter
                            ? area.max_exclusive_area + '㎡'
                            : Math.ceil(
                                area.max_exclusive_area / 3.3057,
                              ) + '평'}
                        </AreaWrap>
                      </ListBottom>
                    </ListWrap>
                  </ListItem>
                </List>
              )
            })}
        </CustomList>
      </Modal>
      <Modal
        title="집합 건물 면적 정보"
        visible={infoVisible}
        onOk={() => setInfoVisible(false)}
        onCancel={() => setInfoVisible(false)}
        okText="확인"
        cancelText="취소"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        <CustomUl>
          <li>
            집합 건물의 면적 정보는 2009년 4월 1일에 개정한 「주택공급에
            관한 규칙 8조 7항」을 참고하여 전용 면적 기준으로 제공하되
            공급면적 및 평형을 같이 제공을 하고 있습니다.
          </li>
          <li>
            평형종류는 아파트 및 연립다세대 주택은 공급면적, 오피스텔 및
            그외 집합 건물은 계약 면적을 기준으로 보여줍니다.
          </li>
          <li>
            1980년 전후로 건축된 집합건물의 경우 실제 면적 정보와 차이가
            있을 수 있습니다.
          </li>
          <li>
            집합 건물의 면적 정보는 「건축물대장」을 재가공하여 제공하는
            데이터로 실제 정보와 다를 수 있으며 이를 이용함으로 발생하는
            어떤 손실에 대해서도 (주) 주택은 책임이 없습니다.
          </li>
        </CustomUl>
      </Modal>
    </div>
  )
}

const CustomTab = styled(Tab)`
  position: fixed;
  align-items: center;
  background-color: ${theme.colors.base.white};
  display: flex;
  user-select: none;
  width: calc(100% - 2.5rem);
  top: 68px;
  z-index: 14;
`

const CustomList = styled.div`
  margin-top: 55px;
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;

  ${media.small`
    flex-direction:column;
  `}

  & > span:nth-child(1) {
    ${({ actived }) =>
      actived &&
      `
      color: ${theme.colors.primary[500]};
  `}
  }

  i {
    margin-left: 4px;
  }
`

const TitleAreaWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;

  ${media.small`
    width: 100%;
  `}
`

const TitleTagWrap = styled.div`
  display: flex;
  gap: 4px;

  ${media.small`
    width: 100%;
    margin-top: 5px;

    & > div:nth-child(1) {
      margin-left: 0;
    }
  `}
`

const ListWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const ListTop = styled.div`
  display: flex;
  flex-direction: column;
`

const ListBottom = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.gray[500]};
`

const DongWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 4px;

  i {
    margin-left: 6px;
  }
`

const DongList = styled.div`
  display: none;
  background-color: ${theme.colors.gray[100]};
  word-break: keep-all;
  border-radius: 10px;
  padding: 8px 12px;
  margin-top: 2px;
  font-size: 0.8rem;
  color: ${theme.colors.gray[700]} !important;

  ${({ visible }) =>
    visible &&
    `
    display:block;
  `}
`

const AreaWrap = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.85rem;
`

const Area = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
`

const AreaPy = styled.span`
  margin-left: 4px;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${theme.colors.gray[600]};

  ${({ actived }) =>
    actived &&
    `
      color: ${theme.colors.primary[500]};
  `}
`

const HelpIcon = styled.div`
  flex-direction: row-reverse;
  cursor: pointer;
  top: 6px;
  right: 0;
  display: flex;
  align-items: center;

  color: ${theme.colors.gray[500]};
  font-size: 1rem;

  & > i {
    color: ${theme.colors.gray[400]};
    margin-left: 10px;
  }
`

const ModalTitle = styled.div`
  display: flex;
`

const CustomUl = styled.ul`
  li {
    list-style-type: disc;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
`

export default SelectArea
