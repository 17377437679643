import Axios from 'axios'

export const saveFavorite = async ({
  _rx_csrf_token,
  address_id,
  address_jibun,
  address_doro,
  property_type,
}) =>
  await Axios.post('/map/api/saveFavorite', {
    address_id,
    address_jibun,
    address_doro,
    property_type,
    _rx_csrf_token,
  })

export const removeFavorite = async ({ _rx_csrf_token, favorite_id }) =>
  await Axios.post('/map/api/removeFavorite', {
    favorite_id,
    _rx_csrf_token,
  })

export const getFavoriteList = async ({ _rx_csrf_token }) =>
  await Axios.post('/map/api/getFavoritesForIndex', {
    _rx_csrf_token,
  })
