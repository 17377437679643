import Container from 'components/base/Container/Container'
import useIsMobile from 'lib/hooks/useIsMobile'
import theme, { media } from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const NoteLayout = ({ children, white }) => {
  const isMobile = useIsMobile()

  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )

  const height = useSelector((state) => state.notice.height)

  return (
    <Container highest noTopHeader={isMobile} absolute noBottom shift>
      <StyledWrap
        white={white}
        isNoticeBarVisible={isNoticeBarVisible}
        noticeBarHeight={height}
      >
        {children}
      </StyledWrap>
    </Container>
  )
}

const StyledWrap = styled.div`
  position: relative;
  width: 100%;
  //height: 100%;
  padding-bottom: 0;
  background: ${({ white }) =>
    white
      ? `${theme.colors.base.white}`
      : `linear-gradient(
    ${theme.colors.base.white},
    ${theme.colors.gray[100]} 20%
  )`};

  ${media.mediumM`
    padding-top : 0;
  `}

  ${media.mediumS`
    padding-top: ${theme.base.topHeaderHeightM}px;
    padding-bottom: ${({ isNoticeBarVisible, noticeBarHeight }) =>
      isNoticeBarVisible
        ? `${noticeBarHeight + theme.base.bottomMenuHeight}px`
        : `${theme.base.bottomMenuHeight}px`};
    top: ${({ isNoticeBarVisible, noticeBarHeight }) =>
      isNoticeBarVisible ? `${noticeBarHeight}px` : `0`};
    height: calc(100% - 56px);

    background: ${({ white }) =>
      white
        ? `${theme.colors.base.white}`
        : `linear-gradient(
        ${theme.colors.base.white} 10rem,
        ${theme.colors.gray[100]} auto
      )`};
  `};
`

export default NoteLayout
