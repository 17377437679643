import AppContainer from './AppContainer'
import { SectionWrapper, SubTitle, Title } from './LandingStyle'
import styled, { keyframes } from 'styled-components'
import LogoSrc from 'assets/images/note/landing/logo.png'
import MapImgSrc from 'assets/images/note/landing/manage-map.png'
import Capture1Src from 'assets/images/note/landing/manage-capture-1.png'
import Capture2Src from 'assets/images/note/landing/manage-capture-2.png'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useRef } from 'react'

const LandingCharacter1 = () => {
  const willAnimatesRef = useRef([])
  const wrapperRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            willAnimatesRef.current.forEach((elem) => {
              elem.classList.add('animate')
            })
          }
        })
      },
      {
        threshold: 0.2,
      },
    )

    observer.observe(wrapperRef.current)
  }, [])

  return (
    <SectionWrapper ref={wrapperRef}>
      <AppContainer>
        <LogoImg src={LogoSrc} />
        <Title>
          세상에 없던 <br />
          편리한 매물관리
        </Title>
        <SubTitle>
          아직도 수첩이나 엑셀로 매물을 관리하시나요?
          <br />
          지도 위에 간편하게 영업망을 펼쳐 보세요.
        </SubTitle>
        <Content>
          <ContentTop>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[0] = el)}
              order="1"
            >
              <ContentText order="1">
                <div>
                  <span>지도에서 </span>
                  <span>직관적으로</span>
                </div>
                매물의 위치가 어디였는지 걱정마세요
                <br />
                <span>지도에서 직접보면서</span> 확인할 수 있어요
              </ContentText>
              <ContentImg order="1" src={MapImgSrc} />
            </ContentWrapper>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[1] = el)}
              order="2"
            >
              <ContentText order="2">
                <div>
                  <span>모바일로 어디서나</span>✨
                </div>
                어디서든 매물을 바로 관리할 수<br />
                있도록 <span>손쉽게</span> 사용하세요
              </ContentText>
              <ContentImg order="2" src={Capture1Src} />
            </ContentWrapper>
          </ContentTop>
          <ContentWrapper
            ref={(el) => (willAnimatesRef.current[2] = el)}
            order="3"
          >
            <svg
              width="46"
              height="47"
              viewBox="0 0 46 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.0900269"
                width="45.9897"
                height="45.9897"
                rx="0.7"
                fill="#83A1C5"
              />
              <rect
                x="2.87439"
                y="31.708"
                width="14.3718"
                height="11.4974"
                rx="0.7"
                fill="#F9FAFB"
              />
              <rect
                x="20.1205"
                y="31.708"
                width="22.9949"
                height="11.4974"
                rx="0.7"
                fill="#F9FAFB"
              />
              <rect
                x="2.87439"
                y="14.4618"
                width="14.3718"
                height="14.3718"
                rx="0.7"
                fill="#F9FAFB"
              />
              <rect
                x="20.1205"
                y="14.4618"
                width="22.9949"
                height="14.3718"
                rx="0.7"
                fill="#F9FAFB"
              />
            </svg>
            <ContentText order="3">
              <div>
                <strong>시트모드</strong>
              </div>
              <div>
                중개사에게 익숙한 시트모드를 통해
                <br />
                빠르게 매물 정보를 입력, 분류하고 <br />
                편리하게 매물을 관리할 수 있어요
              </div>
            </ContentText>
            <ContentImg order="3" src={Capture2Src} />
          </ContentWrapper>
        </Content>
      </AppContainer>
    </SectionWrapper>
  )
}

const floatAnimation = keyframes`
 	from {
		opacity: 0;
		transform: translateY(10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`

const LogoImg = styled.img`
  width: 142px;
  height: 54px;
  object-fit: contain;

  ${media.mediumS`
        width: 120px;
        height: auto;
        object-fit: contain;           
    `}
`

const Content = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;

  ${media.mediumS`
      margin-top: 34px;        
  `}
`

const ContentTop = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  height: auto;

  ${media.mediumM`
        flex-direction: column;
        height: auto;   
    `}
`

const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  padding: 45px 40px;
  font-weight: 700;
  opacity: 0;
  min-height: 500px;

  ${media.mediumS`
      padding: 32px 28px;   
  `}

  &.animate {
    animation: ${floatAnimation} 0.8s ease 0.1s 1 normal backwards;
    opacity: 1;
  }

  ${({ order }) => {
    switch (order) {
      case '1':
        return `
            display: flex;
            flex-direction: column;
            width: 55%;
            background: #e9ebff;
            color: #7b8fd9;

            @media (max-width: ${theme.bp.mediumM}px) {
                width: 100%;
                height: 500px;  
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                height: auto;   
                min-height: 420px;  
            }
        `
      case '2':
        return `
            position: relative;
            background-color: #f5eaff;
            width: 45%;
            animation-delay: 0.3s !important;

            
            @media (max-width: ${theme.bp.mediumM}px) {
                width: 100%;
                height: 580px;  
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                height: 450px;   
                min-height: unset;  
            }
        `
      case '3':
        return `
            position: relative;
            width: 100%;
            height: 450px;
            background: linear-gradient(
                172.02deg,
                #f1f7ff 0%,
                #d5deff 100%
            );
            animation-delay: 0.5s !important;

            svg {
                margin-bottom: 10px;

                @media (max-width: ${theme.bp.mediumM}px) {
                    width: 32px; 
                    margin-bottom: 0;
                }
                
                @media (max-width: ${theme.bp.mediumS}px) {
                    width: 28px; 
                    margin-bottom: -6px;
                }
            }

            @media (max-width: ${theme.bp.mediumM}px) {
                height: 590px;  
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                height: 420px;
                min-height: unset;  
            };
        `
    }
  }};
`

const ContentText = styled.div`
  font-size: 28px;
  line-height: 140%;

  ${media.mediumM`
    font-size: 26px;
  `}

  ${({ order }) => {
    switch (order) {
      case '1':
        return `
        span {
            &:first-child {
                font-weight: 600;
                color: #2c459c;
            }

            &:nth-child(2) {
                font-weight: 800;
                color: #0d2370;
            }
            &:last-child {
                font-weight: 800;
                color: #224ade;
            }
        }
        `
      case '2':
        return `
            color: #8d96a1;
            font-weight: 700;

            span {
                &:first-child {
                    background: linear-gradient(
                        90deg,
                        #ffa842 27.11%,
                        rgba(255, 16, 159, 0.46) 60.93%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }

                &:last-child {
                    color: #28476d;
                    font-weight: 800;
                }
            }
        `
      case '3':
        return `
            color: #83a1c5;
            font-weight: 700;
            z-index: 10;
            position: inherit;

            strong {
                font-weight: 800;
                color: #0e4b75;
            }

            & > div {
                &:nth-child(2) {
                    @media (max-width: ${theme.bp.mediumS}px) {
                        font-size: 18px;
                    }
                }
            }

        `
    }
  }};

  ${media.mediumM`
      word-break: keep-all;
  `}

  ${media.mediumS`
      font-size: 20px;
  `}
`

const ContentImg = styled.img`
  ${({ order, type }) => {
    switch (order) {
      case '1':
        return `
            max-width: 90%;
            height: auto;
            position: absolute;
            left: 0;
            bottom: 0;
            max-height: 65%;
          `
      case '2':
        return `
            max-width: 403px;
            height: auto;
            max-height: 65%;
            object-fit: contain;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: ${theme.bp.mediumM}px) {
                left: 50%;
                transform: translateX(-50%);
                max-height: 66%;
            }
        `
      case '3':
        return `
            max-width: 50%;
            height: auto;
            position: absolute;
            bottom: 0;
            right: 0;

            @media (max-width: ${theme.bp.mediumM}px) {
                max-width: 75%;
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                max-width: 90%;
                max-height: 50%;
            }
        `
    }
  }};
`

export default LandingCharacter1
