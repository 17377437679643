import styled from 'styled-components'
import DeleteButton from 'components/jmapnotev2/NoteAd/list/actionButtons/DeleteButton'
import CompleteButton from 'components/jmapnotev2/NoteAd/list/actionButtons/CompleteButton'
import EndButton from 'components/jmapnotev2/NoteAd/list/actionButtons/EndButton'
import UpdateButton from 'components/jmapnotev2/NoteAd/list/actionButtons/UpdateButton'
import CancelButton from 'components/jmapnotev2/NoteAd/list/actionButtons/CancelButton'
import NormalButton from 'components/jmapnotev2/NoteAd/list/actionButtons/NormalButton'
import { setUpdateStatusFromList } from 'modules/noteAd'
import { useDispatch } from 'react-redux'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import { useSelector } from 'react-redux'

/**
 * 광고관리 그리드
 * 가격
 */
const CustomAdButtonsCell = ({ data, replaceSearch, input }) => {
  const { isBoss } = useIsAllInOne()
  const dispatch = useDispatch()
  const selectedStatus = useSelector(
    (state) => state.noteAd.status.selectedStatus,
  ) //선택된 최상단 상태 값 필터

  /**
   * 상태값 업데이트
   */
  const updateStatus = () => {
    dispatch(setUpdateStatusFromList(true))
  }

  /**
   * 상태값 업데이트 + 목록 재조회
   */
  const refresh = (adSrl, type) => {
    replaceSearch(adSrl, type, input, selectedStatus)
    updateStatus()
  }

  /**
   * 네이버 광고 버튼 - 대표
   * @param {*} status
   * @param {*} adSrl
   * @returns
   */
  const getAdButtonBoss = (
    status,
    adSrl,
    contract_type,
    price1,
    price2,
  ) => {
    const result = {
      작성중: (
        <DeleteButton
          callbackFn={() => refresh(adSrl, 'delete')}
          adSrl={adSrl}
        />
      ),
      검증1차실패: (
        <CancelButton callbackFn={() => refresh(adSrl)} adSrl={adSrl} />
      ),
      광고중: (
        <>
          <CompleteButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
          />
          <EndButton callbackFn={() => refresh(adSrl)} adSrl={adSrl} />
          <UpdateButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
          />
        </>
      ),
      종료예정: (
        <>
          <CompleteButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
          />
          <EndButton callbackFn={() => refresh(adSrl)} adSrl={adSrl} />
          <UpdateButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
          />
        </>
      ),
      광고종료: (
        <DeleteButton
          callbackFn={() => refresh(adSrl, 'delete')}
          adSrl={adSrl}
        />
      ),
      신고매물: (
        <>
          <EndButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
            type="신고매물"
          />
          <NormalButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
            type="신고매물"
          />
          <CompleteButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
            type="신고매물"
          />
        </>
      ),
      동일주소매물: (
        <>
          <EndButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
            type="동일주소매물"
          />
          <NormalButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
            type="동일주소매물"
          />
          <CompleteButton
            callbackFn={() => refresh(adSrl)}
            adSrl={adSrl}
            type="동일주소매물"
            contractType={contract_type}
          />
        </>
      ),
    }

    return result[status]
  }

  /**
   * 네이버 광고 버튼 - 직원
   * @param {*} status
   * @param {*} adSrl
   * @returns
   */
  const getAdButtonStaff = (status, adSrl) => {
    const result = {
      작성중: (
        <DeleteButton
          callbackFn={() => refresh(adSrl, 'delete')}
          adSrl={adSrl}
        />
      ),
      승인대기: (
        <DeleteButton
          callbackFn={() => refresh(adSrl, 'delete')}
          adSrl={adSrl}
        />
      ),
      승인거절: (
        <DeleteButton
          callbackFn={() => refresh(adSrl, 'delete')}
          adSrl={adSrl}
        />
      ),
      광고종료: (
        <DeleteButton
          callbackFn={() => refresh(adSrl, 'delete')}
          adSrl={adSrl}
        />
      ),
    }

    return result?.[status] || null
  }

  if (data.ad_srl == '신규') return <></>
  return (
    <Wrapper tabIndex={-1}>
      {isBoss
        ? getAdButtonBoss(data.status, data.ad_srl, data.contract_type)
        : getAdButtonStaff(
            data.status,
            data.ad_srl,
            data.contract_type,
          )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  padding: 0 6px;
`

export default CustomAdButtonsCell
