import { useState } from 'react'
import { PasswordDesc, StyledInput } from './SignupForm'

/**
 * 회원가입 패스워드
 * @params register, errors - useForm 내장 함수
 * @returns
 */
const SignupPassword = ({ register, errors }) => {
  const [passwordMsg, setPasswordMsg] = useState('') //비밀번호 형식 안 맞음 메시지

  const onChangePassword = (e) => {
    const value = e.target.value
    if (
      !value.match(/[a-z]/i) ||
      !value.match(/[0-9]/) ||
      value.length < 6
    ) {
      setPasswordMsg(
        '비밀번호는 6글자 이상이어야 하며 영문과 숫자를 포함해야 합니다. 안전을 위해 특수문자도 사용하시면 더욱 좋습니다.',
      )
    } else {
      setPasswordMsg('')
    }
  }

  return (
    <>
      <StyledInput
        type="password"
        className={errors?.password && 'error'}
        {...register('password', {
          required: true,
          onChange: onChangePassword,
        })}
      />
      {passwordMsg && (
        <PasswordDesc>
          비밀번호는 6글자 이상이어야 하며 영문과 숫자를 포함해야
          합니다. 안전을 위해 특수문자도 사용하시면 더욱 좋습니다.
        </PasswordDesc>
      )}
    </>
  )
}

export default SignupPassword
