import {
  Label,
  LabelIcon,
  Row,
  Value,
} from 'components/summary/style/SummaryStyle'
import MapContext from 'contexts/map'
import { useContext } from 'react'
import { getComputedArea } from 'utils'

const SummaryLobInfoFeatures = ({ data }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  return (
    <>
      {!!data['structure_code_name'] && (
        <Row>
          <LabelIcon className="far fa-cubes" />
          <Label>주구조</Label>
          <Value>{data['structure_code_name']}</Value>
        </Row>
      )}
      {!!data['roof_code_name'] && (
        <Row>
          <LabelIcon className="fal fa-chimney" />
          <Label>지붕</Label>
          <Value>
            {data['roof_code_name']?.replace(/[\(\)]/g, '')}
          </Value>
        </Row>
      )}
      {!!data['passenger_elevator'] && (
        <Row>
          <LabelIcon className="fal fa-sort-circle-up" />
          <Label>승강기 수</Label>
          <Value>{data['passenger_elevator']}대</Value>
        </Row>
      )}
      {!!data['emergency_elevator'] && (
        <Row>
          <LabelIcon className="fal fa-sort-circle-up" />
          <Label>비상용 승강기 수</Label>
          <Value>{data['emergency_elevator']}대</Value>
        </Row>
      )}
      {!!(
        data['in_self_park_count'] ||
        data['out_self_park_count'] ||
        data['in_mech_park_count'] ||
        data['out_mech_park_count']
      ) && (
        <Row>
          <LabelIcon className="fal fa-parking" />
          <Label>주차장</Label>
          <Value parking>
            {!!data['in_self_park_count'] && (
              <span>옥내 자주식 {data['in_self_park_count']}대</span>
            )}
            {!!data['out_self_park_count'] && (
              <span>옥외 자주식 {data['out_self_park_count']}대</span>
            )}
            {!!data['in_mech_park_count'] && (
              <span>옥내 기계식 {data['in_mech_park_count']}대</span>
            )}
            {!!data['out_mech_park_count'] && (
              <span>옥외 기계식 {data['out_mech_park_count']}대</span>
            )}
          </Value>
        </Row>
      )}
      {!!data['sub_building_area'] && (
        <Row>
          <LabelIcon className="fal fa-clone" />
          <Label>부속 건축물 면적</Label>
          <Value>
            {getComputedArea(data['sub_building_area'], isSquareMeter)}
          </Value>
        </Row>
      )}
      {!!data['sub_building_count'] && (
        <Row>
          <LabelIcon className="fal fa-send-backward" />
          <Label>부속 건축물 수</Label>
          <Value>{data['sub_building_count']}개</Value>
        </Row>
      )}
      {!!data['is_earthquake_res'] && (
        <Row primary="Y">
          <LabelIcon className="fal fa-file-signature" />
          <Label>내진 설계</Label>
          <Value>
            {data['is_earthquake_res'] === 1 && '적용'}
            {data['earthquake_res'] && <>({data['earthquake_res']})</>}
          </Value>
        </Row>
      )}
      {!!data['energy_eff_rating'] && (
        <Row>
          <LabelIcon className="fal fa-lightbulb-on" />
          <Label>에너지효율</Label>
          <Value>{data['energy_eff_rating']}등급</Value>
        </Row>
      )}
      {!!data['energy_saving_rate'] &&
        data['energy_saving_rate'] !== '0.000' && (
          <Row>
            <LabelIcon className="fal fa-seedling" />
            <Label>에너지 절감율</Label>
            <Value>{data['energy_saving_rate']}</Value>
          </Row>
        )}
      {!!data['energy_epi_score'] && (
        <Row>
          <LabelIcon className="fal fa-list-ol" />
          <Label>에너지 EPI</Label>
          <Value>{data['energy_epi_score']}점</Value>
        </Row>
      )}
      {!!data['intelligent_building_grade'] && (
        <Row>
          <LabelIcon className="far fa-robot" />
          <Label>지능형 건축물 등급</Label>
          <Value>
            {data['intelligent_building_grade']}
            등급
          </Value>
        </Row>
      )}
      {!!data['intelligent_building_score'] && (
        <Row>
          <LabelIcon className="fal fa-clipboard-list-check" />
          <Label>지능형 건축물 인증점수</Label>
          <Value>{data['intelligent_building_score']}점</Value>
        </Row>
      )}
      {!!data['sewage_purif'] && (
        <Row>
          <LabelIcon className="fal fa-expand" />
          <Label>오수정화시설 형식</Label>
          <Value>{data['sewage_purif']}</Value>
        </Row>
      )}
    </>
  )
}

export default SummaryLobInfoFeatures
