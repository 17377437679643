import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Dropdown, Menu } from 'antd'
import { rgba } from 'polished'

const StyledMenuButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  padding: 0;
  margin-right: -0.5rem;

  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;

  color: ${theme.colors.gray[900]};

  &:hover {
    color: ${theme.colors.gray[800]};
  }

  ${({ inverse }) =>
    inverse &&
    `
    color: ${theme.colors.base.white};
    &:hover {
      color: ${rgba('white', 0.8)}
    }
  `}

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const HeaderMore = ({ inverse, onClickMenu, children, menus }) => {
  return (
    <Dropdown menu={menus} trigger={['click']}>
      <StyledMenuButton
        inverse={inverse}
        onClick={(e) => e.preventDefault()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
          />
        </svg>
      </StyledMenuButton>
    </Dropdown>
  )
}

HeaderMore.Item = Menu.Item

export default HeaderMore
