import { Button, Checkbox, Modal } from 'components/design'
import theme from 'lib/styles/theme'
import { useState } from 'react'
import styled from 'styled-components'
import SignupForm from './SignupForm'
import { message } from 'antd'
import { getJootekServiceURL } from 'utils'

/**
 * 회원가입 약관 동의 modal
 * @param {boolean} visible modal visible 여부
 * @param {function} onCancel 닫기 함수
 * @param {function} loginCallback 초대 리워드 회원가입 완료 여부 set state
 * @param {string} memberType 회원가입 타입
 * @returns
 */
const SignupAgree = ({
  visible,
  onCancel,
  loginCallback,
  memberType,
}) => {
  const [isSignupFormVisible, setIsSignupFormVisible] = useState(false)

  const [isAgreeUse, setIsAgreeUse] = useState(false)
  const [isAgreePersonal, setIsAgreePersonal] = useState(false)

  const checkAll = () => {
    if (isAgreeUse && isAgreePersonal) {
      setIsAgreeUse(false)
      setIsAgreePersonal(false)
    } else {
      setIsAgreeUse(true)
      setIsAgreePersonal(true)
    }
  }

  const onClickNext = () => {
    if (!isAgreeUse || !isAgreePersonal) {
      message.warning(
        '필수 약관에 모두 동의하지 않으시면 가입을 진행할 수 없습니다.',
      )
      return
    }
    setIsSignupFormVisible(true)
  }

  /**
   * 약관 동의, 회원 가입 폼 모두 닫기
   */
  const closeAll = () => {
    onCancel()
    setIsSignupFormVisible(false)
  }

  /**
   * 이용약관 링크
   */
  const onClickTerms = () => {
    window.open(getJootekServiceURL('/terms'))
  }

  /**
   * 개인정보취급방침 링크
   */
  const onClickPrivacy = () => {
    window.open(getJootekServiceURL('/privacy'))
  }

  return (
    <>
      <Modal
        title=""
        visible={visible}
        onCancel={onCancel}
        width={450}
        footer={null}
      >
        <Header>회원가입</Header>
        <Desc>
          {memberType == 'individual' ? '일반회원' : '부동산 중개사'}
        </Desc>
        <AgreeAll>
          <Checkbox
            checked={isAgreeUse && isAgreePersonal}
            value=""
            onChange={checkAll}
          >
            모든 약관에 동의합니다.
          </Checkbox>
        </AgreeAll>
        <Wrapper>
          <AgreeItem>
            <Checkbox
              checked={isAgreeUse}
              value="Y"
              onChange={() => setIsAgreeUse(!isAgreeUse)}
            >
              이용약관 <span>(필수)</span>
            </Checkbox>
            <i
              onClick={onClickTerms}
              className="far fa-angle-right"
            ></i>
          </AgreeItem>
          <AgreeItem>
            <Checkbox
              checked={isAgreePersonal}
              value="Y"
              onChange={() => setIsAgreePersonal(!isAgreePersonal)}
            >
              개인정보취급방침 <span>(필수)</span>
            </Checkbox>
            <i
              onClick={onClickPrivacy}
              className="far fa-angle-right"
            ></i>
          </AgreeItem>
        </Wrapper>
        <Button
          onClick={onClickNext}
          color="primary"
          disabled={!isAgreeUse || !isAgreePersonal}
          expand
        >
          다음
        </Button>
      </Modal>
      <SignupForm
        visible={isSignupFormVisible}
        onCancel={() => setIsSignupFormVisible(false)}
        loginCallback={() => {
          closeAll()
          loginCallback && loginCallback()
        }}
        memberType={memberType}
      />
    </>
  )
}

const Wrapper = styled.div`
  margin-bottom: 32px;
`

export const Header = styled.div`
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 32px;
  font-weight: 400;
`

export const Desc = styled.div`
  margin-bottom: 8px;
  font-weight: 400;
  color: ${theme.colors.gray[600]};
  font-size: 16px;
  text-align: center;
`

const AgreeAll = styled.div`
  margin-top: 40px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.gray[300]};
`

const AgreeItem = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 16px;

  span {
    color: ${theme.colors.primary[400]};
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    color: ${theme.colors.gray[700]};
    cursor: pointer;
    padding: 10px 10px 10px 20px;
  }
`

export default SignupAgree
