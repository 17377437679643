import { useSelector } from 'react-redux'
import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
} from '../../form/NoteAdFormStyle'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useFormContext } from 'react-hook-form'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 세부주소
 */
const NoteAdColumnAddressDetail = ({ half }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == adSrl &&
    adFormControl?.disabledColumns.includes('article.address4')

  return (
    <ColumnSet half={half}>
      <ColumnTitle>세부주소</ColumnTitle>
      <ColumnVal>
        <InputText
          type="text"
          {...register('article.address4')}
          placeholder="세부주소"
          onBlur={(e) => {
            onUpdate('article.address4', e.target.value)
          }}
          disabled={isDisabled}
        />
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnAddressDetail
