import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 매물유형
 */
const NoteAdColumnPropertyType = () => {
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  const propertyType = watch('article.category1')
  const category2 = watch('article.category2')

  const adOptions = useSelector((state) => state.noteAd.adOptions)

  const [propertyTypeList, setPropertyTypeList] = useState(null)
  const [listSub, setListSub] = useState(null)

  useEffect(() => {
    if (!adOptions) return
    setPropertyTypeList(adOptions.property_type)
    setListSub({
      주택: ['단독', '다가구', '전원주택', '상가주택', '한옥주택'],
      다가구주택: ['다가구주택', '상가주택'],
      단독주택: ['단독주택', '전원주택', '한옥주택'],
      다세대주택: ['빌라', '연립', '기타'],
      원룸: [
        '일반원룸',
        '오피스텔',
        '원룸형 아파트',
        '도시형생활주택',
        '기타',
      ],
      '빌라/연립': ['빌라', '연립', '기타'],
      사무실: ['대형사무실', '중소형사무실', '오피스텔'],
      상가점포: ['단지내상가', '일반상가', '복합상가'],
      지식산업센터: ['지식산업센터'],
      '숙박/콘도': ['여관/모텔', '콘도', '펜션', '기타'],
      '빌딩/건물': [
        '빌딩',
        '상업시설',
        '레저/스포츠/위락',
        '특수시설',
        '기타',
      ],
      상가건물: ['상가건물'],
      기타: ['기타'],
      '공장/창고': ['공장', '창고', '기타'],
      토지: [
        ...Object.values(adOptions['land_types']),
        ...['도로', '하천', '기타'],
      ],
    })
  }, [adOptions])

  return (
    <ColumnSet>
      <ColumnTitle required>매물유형</ColumnTitle>
      <ColumnVal gap={15}>
        <SelectBox
          disabled
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: propertyType || '선택',
            value: propertyType,
          }}
          isSearchable
          options={getSimpleSelectboxOptions(propertyTypeList)}
          placeholder="선택"
          {...register('article.category1')}
          className="basic-single2"
          classNamePrefix="selecta"
          onChange={(e) => {
            onUpdate('property_type', e.value)
            onUpdate('article.category1', e.value)
          }}
        />
        {listSub?.[propertyType] && (
          <SelectBox
            minWidth={InputWidth}
            width={InputWidth}
            value={{
              label: category2 || '선택',
              value: category2,
            }}
            isSearchable
            options={getSimpleSelectboxOptions(listSub[propertyType])}
            placeholder="선택"
            className="basic-single"
            classNamePrefix="select"
            {...register('article.category2')}
            onChange={(e) => {
              onUpdate('article.category2', e.value)
            }}
          />
        )}
        <ValidateWarn>
          <div>{errors?.article?.category1?.message}</div>
          <div>{errors?.article?.category2?.message}</div>
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnPropertyType
