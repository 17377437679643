import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledSubtitle = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  font-weight: 700;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`

const CardSubtitle = ({ children }) => {
  return <StyledSubtitle>{children}</StyledSubtitle>
}

export default CardSubtitle
