import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import Checkbox from '../../elements/Checkbox'
import React from 'react'
import { feeOptions } from '../../optionsFee'
import { useFormContext } from 'react-hook-form'
import useSave from '../../useSave'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비 부과 정보 - 포함 항목
 */
const NoteAdColumnFeeIncludes = ({ title }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onChangeCheckbox } = useSave()

  const includeCodeTypes =
    watch('administrationCostInfo.etcFeeDetails.includeCodeTypes') || []

  return (
    <ColumnSet>
      <ColumnTitle required>포함 항목</ColumnTitle>
      <ColumnVal gap={12}>
        {Object.entries(feeOptions['포함항목']).map(
          ([key, value], i) => {
            return (
              <React.Fragment key={value}>
                <Checkbox
                  id={`etcFeeDetailsIncludeCodeTypes${i}`}
                  color="blue"
                  value={key}
                  {...register(
                    'administrationCostInfo.etcFeeDetails.includeCodeTypes',
                  )}
                  onChange={(checked, e) => {
                    onChangeCheckbox({
                      originValue: includeCodeTypes,
                      key: 'administrationCostInfo.etcFeeDetails.includeCodeTypes',
                      value: e,
                    })
                  }}
                >
                  {value}
                </Checkbox>
              </React.Fragment>
            )
          },
        )}
        <ValidateWarn>
          {
            errors?.administrationCostInfo?.etcFeeDetails
              ?.includeCodeTypes?.message
          }
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeeIncludes
