import React, { useRef } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { Button, Checkbox } from 'components/design'
import styled from 'styled-components'
import tenLogo from 'assets/images/note/ad/ten_logo.png'
import kisoLogo from 'assets/images/note/ad/kiso_logo.png'
import { ReactComponent as NaverLogo } from 'assets/images/note/ad/naver_logo.svg'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import { options } from '../../options'
import priceFormat from 'lib/utils/priceFormat'
import { useSelector } from 'react-redux'
import { numberWithCommas } from 'utils'

const NoteAdPromotionFile = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const targetRef = useRef(null)
  const { watch } = useFormContext()

  const address = watch('address')
  const tradeType = watch('trade.type')
  const price1 = watch('trade.price1')
  const price2 = watch('trade.price2')
  const oname = watch('verification.oname')

  const tradeOption = options['거래구분']

  function objectFlip(obj) {
    return Object.keys(obj).reduce((ret, key) => {
      ret[obj[key]] = key
      return ret
    }, {})
  }

  const tradeTypeKor = objectFlip(tradeOption)[tradeType]

  /**
   * pdf 만들기
   * canvas로 변환한 후 pdf로 변환함
   */
  const makePdf = () => {
    html2canvas(targetRef.current, {
      scale: 2,
    }).then((canvas) => {
      const imgWidth = 208
      const pageHeight = 295
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      let heightLeft = imgHeight
      let position = 0
      heightLeft -= pageHeight
      const doc = new jsPDF('p', 'mm')

      doc.addImage(
        canvas,
        'PNG',
        0,
        position,
        imgWidth,
        imgHeight,
        '',
        'FAST',
      )
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        doc.addPage()

        doc.addImage(
          canvas,
          'PNG',
          0,
          position,
          imgWidth,
          imgHeight,
          '',
          'FAST',
        )
        heightLeft -= pageHeight
      }
      doc.save(`매물홍보 확인서_${address}.pdf`)
    })
  }

  const onClick = async (e) => {
    e.preventDefault()
    makePdf()
  }

  return (
    <>
      <Button color="blue" size="small" onClick={onClick}>
        홍보확인서 다운
      </Button>
      <Paper ref={targetRef}>
        <ImgWrapper>
          <img width={54} height={18} src={tenLogo} />
          <img width={130} height={24} src={kisoLogo} />
        </ImgWrapper>
        <Title>매물홍보 확인서</Title>
        <TitleDesc>
          고객님의 매물이 정확히 홍보될 수 있도록 아래와 같이 매물정보를
          확인하고자 합니다.
          <br /> 기입해주신 고객님의 이름과 상세주소는 내부 확인용으로만
          사용되며 외부에 노출되지 않습니다.
          <br />
          <span>
            **홍보확인서는 매물 의뢰인이 직접 작성해야 합니다.
          </span>
        </TitleDesc>
        <TableTitle>매물정보</TableTitle>
        <Table>
          <TableRow>
            <TableLabel>매물번호</TableLabel>
            <TableContent border></TableContent>
            <TableLabel>
              <div>등기부등본</div>
              <div>고유번호</div>
              <span>(선택적기재)</span>
            </TableLabel>
            <TableContent></TableContent>
          </TableRow>
          <TableRow>
            <TableLabel>소재지</TableLabel>
            <TableContentWrapper>
              <TableContentRow>
                <TablePrepend>
                  <img width={40} height={14} src={tenLogo} />
                </TablePrepend>
                <TableContent prepend>{address}</TableContent>
              </TableContentRow>
              <TableContentRow>
                <TablePrepend>
                  <NaverLogo />
                </TablePrepend>
                <TableContent prepend>{address}</TableContent>
              </TableContentRow>
            </TableContentWrapper>
          </TableRow>
          <TableRow>
            <TableLabel>거래유형</TableLabel>
            <TableContent border>{tradeTypeKor}</TableContent>
            <TableLabel>가격</TableLabel>
            <TableContent>
              {tradeTypeKor == '매매' || tradeTypeKor == '전세'
                ? `${numberWithCommas(price1)}만원`
                : `${price1 ? `${numberWithCommas(price1)}만원` : ''}${
                    price2 ? `/${numberWithCommas(price2)}만원` : ''
                  }`}
            </TableContent>
          </TableRow>
          <TableRow>
            <TableLabel>
              <div>등기부상</div>
              <div>소유자명</div>
            </TableLabel>
            <TableContent border>{oname}</TableContent>
            <TableLabel>
              <div>소유자와의</div>
              <div>관계</div>
            </TableLabel>
            <TableContent></TableContent>
          </TableRow>
        </Table>
        <Desc>
          <DescItem>
            <DescTitle>
              &lt;개인정보 수집 및 이용에 대한 동의(필수)&gt;
            </DescTitle>
            &ndash; 소유자명, 소유자와의 관계, 소재지, 거래유형, 가격은
            부동산매물 확인 및 홍보, 허위매물 등록 방지를 위해 수집되며,
            개인정보 보유 및 이용기간 달성 후 지체없이 파기합니다.
          </DescItem>
          <DescItem>
            <DescTitle>
              &lt;주택 개인정보 제공에 대한 동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는자 :(주)주택
            <br />
            &ndash; 제공 목적:(주)주택 사이트에 매물정보 홍보, 허위매물
            등록 방지
            <br />
            &ndash; 제공 항목: 소유자명,소유자와의 관계, 소재지, 거래
            유형, 가격 <br />
            &ndash; 제공받는 자의 개인정보 보유 및 이용 기간: 30일까지
          </DescItem>
          <DescItem>
            <DescTitle>
              &lt;주택-텐컴즈 개인정보 제공에 대한 동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는자 :(주)텐컴즈
            <br />
            &ndash; 제공 목적:(주)텐컴즈에 매물정보 홍보, 허위매물 등록
            방지
            <br />
            &ndash; 제공 항목: 소유자명,소유자와의 관계, 소재지,거래
            유형, 가격 <br />
            &ndash; 제공받는 자의 개인정보 보유 및 이용 기간: 30일까지
            <br />
          </DescItem>
          <DescItem>
            <DescTitle>
              &lt;텐컴즈-네이버 개인정보 제공에 대한 동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는 자: 네이버 <br />
            &ndash;제공 목적: 네이버 부동산에 매물정보 홍보, 허위매물
            등록 방지
            <br />
            &ndash; 제공 항목 소유자명, 소유자와의 관계, 소재지,
            거래유형, 가격 <br />
            &ndash;제공받는 자의 개인정보 보유 및 이용 기간 제공 후
            60일간 보관
            <br />
          </DescItem>
          <DescItem>
            <DescTitle>
              &lt;네이버-한국부동산원 개인정보 제공에 대한
              동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는자:한국부동산원 제공 목적: 허위매물 등록
            방지
            <br />
            * 한국부동산원법에 따라 시장동향과 관련 통계의 조사 관리를
            위한 목적으로도 활용될 수 있음
            <br />
            &ndash; 제공항목: 소재지,거래유형,가격 <br />
            &ndash;제공받는 자의 개인정보 보유 및 이용 기간 제공 후
            1년간 보관
            <br />
          </DescItem>
          <br />
          <br />
          <br />
          <br />
          <br />
          <DescItem>
            <DescTitle>
              &lt;네이버-한국인터넷자율정책기구 개인정보 제공에 대한
              동의(필수)&gt;
            </DescTitle>
            &ndash; 제공받는 자:한국인터넷자율정책기구
            <br />
            &ndash;제공 목적: 허위매물 여부확인-제공항목 소재지,
            거래유형,가격
            <br />
            &ndash; 제공받는 자의 개인정보 보유 및 이용기간: 제공 후
            1년간 보관
            <br />
          </DescItem>
        </Desc>
        <Agree>
          * 정보주체는 본 개인정보 수집 및 이용과 개인정보 제공에 대한
          동의를 거부할 수 있으며, 거부하실 경우 매물 확인 및 홍보
          업무가 제한됩니다. 개인정보 수집 및 이용, (주)텐컴즈 및
          네이버, 한국부동산원, 한국인터넷자율정책기구로의 개인정보
          제공에 대하여 모두
          <AgreeCheckboxes>
            <Checkbox size="small">동의</Checkbox>
            <Checkbox size="small">미동의</Checkbox>
          </AgreeCheckboxes>
        </Agree>
        <AgreeSign>
          본인은 상기 개인정보 수집 및 제공에 대한 동의를 통해 네이버
          부동산과 (주) 텐컴즈_에 매물정보를 노출하는 것에 동의합니다.
          <SignArea />
        </AgreeSign>
        <SignPencilWrapper>
          매물홍보 의뢰인 이름
          <SignPencil append />
          서명
          <SignPencil />
        </SignPencilWrapper>
        <TableTitle>중개업소 입력정보</TableTitle>
        <Table>
          <TableRow small>
            <TableLabel>중개업소명</TableLabel>
            <TableContent>{loginInfo?.company_name}</TableContent>
          </TableRow>
          <TableRow small>
            <TableLabel>중개업소 연락처</TableLabel>
            <TableContent border>
              {loginInfo?.company_phone &&
                loginInfo?.company_phone.length > 0 &&
                loginInfo?.company_phone.join('-')}
            </TableContent>
            <TableLabel>등록된 매물번호</TableLabel>
            <TableContent></TableContent>
          </TableRow>
        </Table>
        <Desc>
          본 확인서는 매물확인 및 관리를 위해 노출 기간 동안 보관되며
          이용자에 의한 확인 요청 시 자료로 활용될 수 있습니다.
        </Desc>
      </Paper>
    </>
  )
}

const Paper = styled.div`
  position: fixed;
  z-index: -100;
  //position: relative;
  width: 595px;
  padding: 30px 50px;
  background-color: white;
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 22px;
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
`

const TitleDesc = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 160%;
  span {
    font-size: 11px;
    color: ${theme.colors.red[400]};
    font-weight: 600;
  }
`

const TableTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
`

const Table = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid ${theme.colors.gray[600]};
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  border-bottom: 1px solid ${theme.colors.gray[600]};
  overflow: hidden;

  ${({ small }) =>
    small &&
    `
    height: 24px;
  `}

  &:last-child {
    border-bottom: none;
  }
`

const TableLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: inherit;
  font-size: 10px;
  font-weight: 400;
  line-height: 100%;
  background-color: ${theme.colors.gray[100]};
  border-right: 1px solid ${theme.colors.gray[600]};
  flex-shrink: 0;

  span {
    font-size: 9px;
    color: ${theme.colors.gray[400]};
  }
`

const TableContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 0 8px;
  font-size: 10px;
  border: 3px solid ${theme.colors.base.white};
  background-color: ${theme.colors.blue[50]};

  ${({ border }) =>
    border &&
    `
    border-right: 1px solid ${theme.colors.gray[600]};
  `}
`

const TableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TableContentRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
  &:first-child {
    border-bottom: 1px solid ${theme.colors.gray[600]};
  }
`

const TablePrepend = styled.div`
  width: 50px;
  margin-left: 10px;
`

const Desc = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 160%;
`

const DescTitle = styled.div`
  font-weight: 500;
`

const DescItem = styled.div`
  margin-bottom: 10px;
`

const Agree = styled(Desc)`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  padding: 8px 12px 24px 12px;
  line-height: 130%;
  border: 1px solid ${theme.colors.gray[600]};

  .checkbox {
    width: 12px;
    height: 12px;
    border-radius: 0;
    border: 1px solid ${theme.colors.gray[600]};
  }
`

const AgreeCheckboxes = styled.div`
  display: flex;
  gap: 12px;
  position: absolute;
  right: 12px;
  bottom: 6px;
`

const AgreeSign = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
  margin-bottom: 24px;
  font-size: 11px;
`

const SignArea = styled.div`
  width: 150px;
  height: 30px;
  background-color: ${theme.colors.gray[300]};
`

const SignPencilWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 24px;
  font-size: 12px;
`

const SignPencil = styled.div`
  width: 150px;
  height: 24px;
  border-bottom: 1px solid ${theme.colors.gray[600]};

  ${({ append }) =>
    append &&
    `
    position: relative;
    &::after {
        content: '(정자로 기입해주세요)';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -16px;
        width: max-content;
        font-weight: 400;
        line-height: 100%;
        font-size: 10px;
        color: ${theme.colors.gray[600]};
    }
  `}
`

export default NoteAdPromotionFile
