import { Button } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'
import theme from 'lib/styles/theme'
import { setMapVisible } from 'modules/map'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

/**
 * 홈 - 지도로 탐색하기 float 버튼
 */
const HomeMapVisibleButton = () => {
  const isMobile = useIsMobile()
  const dispatch = useDispatch()

  const onClickVisible = () => {
    dispatch(setMapVisible(true))
  }

  if (!isMobile) return null

  return (
    <Wrapper>
      <Block>
        <ButtonWrap>
          <Button
            color="primary"
            size="large"
            round
            onClick={onClickVisible}
          >
            <i className="fad fa-map"></i>
            <span>지도로 탐색하기</span>
          </Button>
        </ButtonWrap>
      </Block>

      <SafeArea />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: ${theme.base.bottomMenuHeight}px;
  z-index: 13;

  width: 100%;
`

const Block = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
`

const SafeArea = styled.div`
  height: env(safe-area-inset-bottom);
  background-color: white;
`

const ButtonWrap = styled.div`
  box-shadow: ${theme.shadow['button-1']};
  border-radius: 500px;
`

export default HomeMapVisibleButton
