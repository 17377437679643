import { useContext, useState } from 'react'
import {
  formatSido,
  getAddressName,
  getPrimaryBuilding,
  hasLandAddress,
} from 'lib/utils'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useRouteMatch, withRouter } from 'react-router-dom'
import priceFormat from 'lib/utils/priceFormat'
import useRoadName from 'components/summary/header/hooks/useRoadName'
import { getComputedArea, getDisplayAddress } from 'utils'
import MapContext from 'contexts/map'

/**
 * 지도 및 매물노트 SEO 처리
 * @param {*} param0
 * @returns
 */
const SEOHead = ({
  match,
  note = null,
  addressInfo = null,
  housingComplex = null,
}) => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const [title, setTitle] = useState('주택지도')
  const [description, setDescription] = useState(
    '건축과 부동산 정보를 한눈에! 토지/단독주택/다가구/아파트/오피스텔 실거래가, 추정가, 건축물대장, 학군, 수익률을 지도에서 손쉽게 찾아보세요.',
  )
  const matchHousingComplex = useRouteMatch('/housingComplex/:id')

  const roadName = useRoadName(summaryInfo?.land)
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const engReg = /[^a-z|A-Z]/g
  const korReg = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g

  const isApt = useSelector((state) => state.summary.isApt)
  const hasBuilding = summaryInfo?.building?.result.length > 0

  const address = hasLandAddress(
    summaryInfo?.land?.num1,
    summaryInfo?.land?.num2,
  )
    ? getAddressName(summaryInfo?.land)
    : roadName

  const buildingPurposeNm = hasBuilding
    ? getPrimaryBuilding(summaryInfo?.building)?.purpose_code_name
    : ''

  const getSummaryAptDescription = () => {
    const address = `${
      summaryInfo.land?.sido ? formatSido(summaryInfo.land?.sido) : ''
    } ${summaryInfo.land?.sigungu ? summaryInfo.land?.sigungu : ''} ${
      summaryInfo.land?.ilbangu ? summaryInfo.land?.ilbangu : ''
    } ${summaryInfo.land?.eupmyeondong}`

    return `${address} ${
      summaryInfo?.building?.result[0]?.building_name
    } ${
      getPrimaryBuilding(summaryInfo?.building)?.purpose_code_name
    } 시세, 매매 전세 월세 실거래가, 평형 정보, 단지 정보, 입지조건, 소유자 통계 정보`
  }

  const getSummaryDescription = () => {
    return `${address} ${buildingPurposeNm}(${
      summaryInfo.land?.land_type
    } ${getComputedArea(summaryInfo?.land?.land_area, isSquareMeter)} ${
      hasBuilding
        ? `연면적 ${getComputedArea(
            summaryInfo?.building.result[0].total_floor_area,
            isSquareMeter,
          )}`
        : summaryInfo?.land?.zone_use1
    }) 시세, 실거래가, ${
      hasBuilding ? '건축물대장, ' : '추정가, 규제정보, '
    }소유자, 입지조건, 설계/시공사 정보`
  }

  useEffect(() => {
    //매물노트
    if (match.path.indexOf('note') !== -1) {
      if (!addressInfo) {
        //매물노트 메인
        setTitle('주택 매물노트')
      } else {
        if (note) {
          setTitle(
            `${addressInfo.address_jibun} ${note.property_type} ${
              note.contract_type
            } ${
              (note.sale_price
                ? priceFormat(note.sale_price * 10000)
                : priceFormat(note.deposit * 10000)) +
              (note.rent &&
              ['매매', '전세', '분양권'].indexOf(note.contract_type) ===
                -1
                ? '/' + priceFormat(note.rent * 10000)
                : '')
            } - 주택 매물노트`,
          )
        } else {
          setTitle(`${addressInfo.address_jibun} - 주택 매물노트`)
        }
      }

      //일정관리
      if (match.path.indexOf('calendar') !== -1) {
        setTitle(`일정관리 - 주택 매물노트`)
      }
      //고객관리
      if (match.path.indexOf('contact') !== -1) {
        setTitle(`고객관리 - 주택 매물노트`)
      }

      setDescription('아직도 수첩 쓰세요? 주택 매물노트 하세요!')
    }

    // 매물노트 공유
    if (match.path.indexOf('share') !== -1 && note) {
      // console.log('note seo')
      setTitle(
        `${note.address_jibun} ${note.property_type} ${
          note.contract_type
        } ${
          (note.sale_price
            ? priceFormat(note.sale_price * 10000)
            : priceFormat(note.deposit * 10000)) +
          (note.rent &&
          ['매매', '전세', '분양권'].indexOf(note.contract_type) === -1
            ? '/' + priceFormat(note.rent * 10000)
            : '')
        } - 주택 매물노트`,
      )
      setDescription('아직도 수첩 쓰세요? 주택 매물노트 하세요!')
    }

    if (!summaryInfo?.land) return

    // 필지 클릭 시
    if (match.path.indexOf('/:id') !== -1) {
      setTitle(
        `${getDisplayAddress(
          summaryInfo?.land,
          summaryInfo?.building,
          roadName,
        )} - 주택지도`,
      )

      setDescription(
        isApt ? getSummaryAptDescription() : getSummaryDescription(),
      )
    }

    // 2depth 컴포넌트
    if (match.params?.external) {
      if (match.params.external === 'nearby') {
        setTitle(
          `${getAddressName(
            summaryInfo.land,
          )} 주변 유사거래 - 주택지도`,
        )
        setDescription(
          `${address} ${buildingPurposeNm}의 실제 거래내역을 주택지도에서 한눈에 둘러보세요.`,
        )
      }
      if (match.params.external === 'lob') {
        setTitle(
          `${getAddressName(summaryInfo.land)} 건축물대장 - 주택지도`,
        )
        setDescription(
          `${address} ${buildingPurposeNm}의 건축물대장을 주택지도에서 손쉽게 열람하세요.`,
        )
      }
      if (match.params.external === 'legal') {
        setTitle(
          `${getAddressName(summaryInfo.land)} 규제 정보 - 주택지도`,
        )
        setDescription(
          `${address} ${buildingPurposeNm}에 적용되는 규제를 주택지도에서 정확히 확인하세요.`,
        )
      }
    }
  }, [match, summaryInfo, note, isApt])

  //주택단지 seo 세팅
  useEffect(() => {
    if (
      matchHousingComplex &&
      housingComplex &&
      housingComplex.danji_code
    ) {
      setTitle(
        `${housingComplex.danji_name.replace(
          korReg,
          '',
        )} 전원주택단지 ${housingComplex.danji_name.replace(
          engReg,
          '',
        )} - ${housingComplex.address_name} - 주택지도`,
      )
      setDescription(
        `${
          housingComplex.address_name
        } ${housingComplex.danji_name.replace(
          korReg,
          '',
        )} 전원주택단지의 시세, 최근 실거래가, 주택 분포, 노후도, 거래회전율, 주거 환경, 설계/시공사 정보`,
      )
    }
  }, [matchHousingComplex])

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="keywords"
        content="주택지도, 주택, 주택 추정가, 실거래가, JOOTEK, JOOTEK 추정가, JOOTEK 지도, 부동산 시세, 부동산 유사거래, 건축물대장, 규제정보, 입지정보, 재건축, 건축주"
      />
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
    </Helmet>
  )
}

export default withRouter(SEOHead)
