import { Tooltip } from 'antd'
import theme, { media } from 'lib/styles/theme'
import { forwardRef } from 'react'
import styled from 'styled-components'

const TopHeaderItem = (
  {
    to,
    href,
    exact,
    onClick,
    target,
    as,
    icon,
    tooltip,
    active,
    newBadge,
    isLink,
    noMargin,
  },
  ref,
) => {
  return (
    <div ref={ref}>
      <Tooltip title={tooltip}>
        {isLink ? (
          <NavItem
            to={to}
            exact={exact}
            href={href}
            target={target}
            as={as}
            onClick={onClick}
            className={active && 'active'}
            nomargin={noMargin ? 'Y' : 'N'}
          >
            {newBadge && <NewBadge />}
            {icon}
          </NavItem>
        ) : (
          <NavItem onClick={onClick} as="div">
            {newBadge && <NewBadge />}
            {icon}
          </NavItem>
        )}
      </Tooltip>
    </div>
  )
}

const NavItem = styled.a`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  line-height: 1.2;
  transition: 0.2s ease;
  padding: 4px;
  margin: 10px 0;

  ${({ nomargin }) => nomargin == 'Y' && `margin: 0;`};

  ${media.smallPc`
    padding: 0 0.5rem;
  `}

  ${media.mediumM`
    padding-right: 0;
    ${({ nomargin }) =>
      nomargin != 'Y' &&
      `
        margin-right: 8px;
      `};
  `}

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  & > i {
    color: ${theme.colors.gray[900]};
    font-size: 1.2rem;
  }

  & > svg {
    fill: ${theme.colors.gray[900]};
    width: 20px;
    height: 20px;

    path {
      fill: inherit;
    }
  }

  & > span {
    margin-top: 10px;
  }

  &.active {
    color: ${theme.colors.primary[500]};
    font-weight: 600;

    & > i {
      color: ${theme.colors.primary[400]};
    }
    & > svg {
      fill: ${theme.colors.primary[400]};
    }
  }
`

const NewBadge = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.875rem;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${theme.colors.primary[400]};
`

export default forwardRef(TopHeaderItem)
