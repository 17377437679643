import { setActiveTab } from 'modules/noteDongho'
import { useDispatch } from 'react-redux'

/**
 * 동호관리 헤더 로직 모음(탭 등)
 */
const NoteDonghoHeaderContainer = ({ children }) => {
  const dispatch = useDispatch()

  const onClickTab = (val) => {
    dispatch(setActiveTab(val))
  }

  return children({
    onClickTab,
  })
}

export default NoteDonghoHeaderContainer
