import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'
import { CardHeader, CardBody, Button, Empty } from 'components/design'

import styled from 'styled-components'
import {
  HelpCardHeader,
  Title,
} from 'components/summary/SummarySizeReview/SummarySizeReviewStyle'
import { getNohuMasters } from 'lib/api/summaryApi'
import { useSelector } from 'react-redux'

const MasterListItem = styled.a`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;

  padding: 1.4rem 1.2rem 0.8rem 1.2rem;
  margin-bottom: 1rem;

  border: none;
  border-radius: 8px;
  box-shadow: none;
  background-color: ${theme.colors.gray[50]};
  transition: 0.2s ease;
  cursor: pointer;

  color: #212529;
  text-decoration: none;

  &:hover {
    background-color: ${theme.colors.gray[100]};
  }
`

const MasterListItemBody = styled.div`
  padding: 0 0 0 calc(64px + 1rem);
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ProfileImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 1rem;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background-color: ${theme.colors.gray[200]};
  border: 3px solid white;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    max-width: none;
  }
`

const MetaData = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.3rem;
  color: #868e96;
  font-size: 0.75rem;
  line-height: 150%;

  & > span {
    &:not(:first-child) {
      &::before {
        content: '・';
        margin: 0 2px;
      }
    }
  }
`

const ListItemTitle = styled.div`
  color: #212529;
  font-weight: 700;
`

const CompanyIntro = styled.div`
  color: #495057;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.8;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const ListItemTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
  padding-bottom: 0;
  margin-bottom: 0;
`

const TagItem = styled.span`
  padding: 3px;

  & > span {
    display: inline-block;
    padding: 6px 10px;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    border-radius: 4px;
    color: #25bc58;
    background-color: #e9faef;
  }
`

const ThumbnailWrap = styled.div`
  display: flex;
  overflow: hidden;
  margin-top: 0.7rem;
  width: 100%;
`

const ThumbnailListItem = styled.div`
  display: flex;
  width: 25%;
  padding-left: 3px;
  padding-right: 3px;

  img {
    border-radius: 6px;
    overflow: hidden;
  }
`

const SummaryNohuMasters = () => {
  const history = useHistory()

  const [partnersData, setPartnersData] = useState([]) // 입점사 목록

  const [onRequest, loading, data] = useAxios(getNohuMasters)
  const {
    state: { map, viewState },
  } = useContext(MapContext)
  const centerPoint = useSelector((state) => state.summary.centerPoint)

  useEffect(() => {
    if (Object.keys(map).length === 0) return

    if (!centerPoint?.coordinates) return

    onRequest(
      {
        lat: centerPoint?.coordinates[0],
        lng: centerPoint?.coordinates[1],
        count: 3,
      },
      null,
      false,
    )
  }, [map, centerPoint])

  useEffect(() => {
    if (!data?.result) {
      setPartnersData([])
      return
    }

    setPartnersData(data.result)
  }, [data])

  const getPeriod = (career_period) => {
    if (career_period?.toString().includes('년')) return career_period

    if (!career_period) return
    const periods = career_period?.split(',')

    if (!Array.isArray(periods)) return career_period

    if (periods?.length < 2) return `${periods[0]}년`

    return `${periods[0]}~${periods[1]}년`
  }

  return (
    <>
      <CardHeader>
        <HelpCardHeader>
          <Title>건축 전문가를 둘러볼까요?</Title>
        </HelpCardHeader>
      </CardHeader>
      <CardBody>
        <>
          {partnersData && partnersData.length > 0 ? (
            partnersData.map(
              (
                {
                  portfolio_url,
                  profile_image,
                  company_address,
                  area_name,
                  business_years,
                  company_name,
                  description,
                  career_period,
                  business_area,
                  project_cnt,
                  project_thumbnails,
                  profile_thumbnail,
                  jootekfolio_srl,
                  end_year,
                  start_year,
                },
                index,
              ) => {
                return (
                  <MasterListItem
                    target="_blank"
                    key={index}
                    href={`/folio/${jootekfolio_srl}`}
                  >
                    <MasterListItemBody>
                      <ProfileImage>
                        {profile_image ? (
                          <img
                            src={profile_thumbnail}
                            alt="프로필 이미지"
                          />
                        ) : (
                          <img src="/layouts/jootek/assets/images/ic_profile_default.png" />
                        )}
                      </ProfileImage>
                      <MetaData>
                        {area_name && <span>{area_name}</span>}
                        <span>
                          {end_year - start_year > 0
                            ? end_year - start_year + `년차`
                            : ''}
                        </span>
                        <span>
                          프로젝트 {project_cnt ? project_cnt : 0}개
                        </span>
                      </MetaData>
                      <ListItemTitle>
                        {company_name && company_name}
                      </ListItemTitle>
                      <CompanyIntro>
                        {description ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: description,
                            }}
                          ></div>
                        ) : (
                          '-'
                        )}
                      </CompanyIntro>

                      <ListItemTag>
                        {business_area &&
                          business_area
                            .slice(0, 4)
                            .map((ba_item, ba_index) => {
                              return (
                                <TagItem key={ba_index}>
                                  <span>{ba_item}</span>
                                </TagItem>
                              )
                            })}
                      </ListItemTag>
                    </MasterListItemBody>
                    <ThumbnailWrap>
                      {project_thumbnails &&
                        project_thumbnails.map((imgUrl, imgIndex) => {
                          return (
                            <ThumbnailListItem key={imgIndex}>
                              <img src={imgUrl} alt="프로젝트 썸네일" />
                            </ThumbnailListItem>
                          )
                        })}
                    </ThumbnailWrap>
                  </MasterListItem>
                )
              },
            )
          ) : (
            <Empty>현재 지역에 건축 전문가 목록이 없어요</Empty>
          )}
        </>
        {/* <Button
          onClick={() => history.push('/smartfolio')}
          type="button"
          size="xlarge"
          color="primary"
          expand
        >
          건축 전문가 둘러보기
        </Button> */}
      </CardBody>
    </>
  )
}

export default SummaryNohuMasters
