import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NoteDetailNoteConverter from 'components/jmapnotev2/detail/NoteDetailNoteConverter'
import { useSelector } from 'react-redux'
import { useContext, useEffect, useRef, useState } from 'react'
import MapContext from 'contexts/map'
import NoteSideMenuItem from './NoteSideMenuItem'
import { setSidemenuVisible } from 'modules/layout'
import { useDispatch } from 'react-redux'
import TopHeaderSearch from '../TopHeader/TopHeaderSearch'
import TopHeaderNoty from '../TopHeader/TopHeaderNoty'
import TopHeaderProfile from '../TopHeader/TopHeaderProfile'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import MapHelpMenu from 'components/map/MapHelpMenu'
import AccountMenu from './AccountMenu'
import useIsSideMenuMaxHeight from './useIsSideMenuMaxHeight'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const SideMenuBottom = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const isSideMenuMaxHeight = useIsSideMenuMaxHeight()
  const location = useLocation()
  const memberType =
    JSON.parse(localStorage.getItem('memberType')) || ''

  const {
    state: { isSquareMeter },
    actions,
  } = useContext(MapContext)

  const [visible, setVisible] = useState(false)
  const [isVisibleSmallMenu, setIsVisibleSmallMenu] = useState(false)
  const [visibleSmallMenuPos, setVisibleSmallMenuPos] = useState(0)

  const ref = useRef()

  useOnClickOutside(ref, (e) => {
    setIsVisibleSmallMenu(false)
  })

  useEffect(() => {
    setIsVisibleSmallMenu(false)
  }, [location?.pathname])

  const onClickMenuButton = (e) => {
    setIsVisibleSmallMenu(!isVisibleSmallMenu)

    const targetBottomPos = e.target.getBoundingClientRect().bottom
    const bottomPos =
      window.innerHeight - targetBottomPos < 128 ? 20 : targetBottomPos

    setVisibleSmallMenuPos(bottomPos)
  }

  return (
    <Bottom>
      {isSideMenuMaxHeight && (
        <MenusButton onClick={onClickMenuButton}>
          <i className="fal fa-ellipsis-h"></i>
        </MenusButton>
      )}
      <Menus
        ref={ref}
        visible={isVisibleSmallMenu}
        bottom={visibleSmallMenuPos}
        calcbottom={loginInfo?.member_type == 'realestate'}
      >
        {memberType &&
          loginInfo?.member_type == 'realestate' &&
          loginInfo.emergency != 'Y' && (
            <AccountMenu
              to={'/note/v2/account'}
              label={'계정관리'}
              onlySheet={false}
            />
          )}

        {/* 앱이 아닌경우, 중개사 회원인 경우, emergency(전세 피해) 회원이 아닌 경우만 결제관리 메뉴 보임 */}
        {!navigator.userAgent.match(
          /(com\.jootek\.mobile|rhymixpush|RXPUSH)/,
        ) &&
          memberType &&
          loginInfo?.member_type == 'realestate' &&
          loginInfo.emergency != 'Y' && (
            <NoteSideMenuItem
              toCard={'/note/v2/payment'}
              toSheet={'/note/v2/payment'}
              label={'결제관리'}
              onlySheet={false}
            />
          )}
        <ClientMenu
          visible={visible}
          onClick={() => setVisible((state) => !state)}
        >
          고객센터
        </ClientMenu>
      </Menus>

      <MapHelpMenu
        visible={visible}
        onClose={() => setVisible(false)}
      />
      <Hr />
      <ConverterWrapper>
        <NoteDetailNoteConverter color="black" />
      </ConverterWrapper>
      <TopHeaderSearch />
      <TopHeaderNoty />
      <TopHeaderProfile />
    </Bottom>
  )
}

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
  @media screen and (max-height: 1000px) {
    margin-bottom: 10px;
  }
`

const MenusButton = styled.div`
  font-size: 30px;
  color: ${theme.colors.gray[600]};
`

const Menus = styled.div`
  @media screen and (max-height: 1000px) {
    display: none;
    position: fixed;
    background-color: ${theme.colors.base.white};
    left: 70px;
    ${({ bottom, calcbottom }) =>
      calcbottom &&
      (bottom == 20
        ? `bottom: 20px;`
        : `bottom: calc(100% - ${bottom}px - 80px);`)};
    padding: 16px 10px;
    box-shadow: ${theme.shadow['button-1']};
    border-radius: 4px;
    ${({ visible }) => visible && `display: block;`}
  }
`

const ClientMenu = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[900]};

  ${({ visible }) =>
    visible && `color: ${theme.colors.blue[500]}; font-weight:700;`}
`

const Hr = styled.hr`
  background-color: ${theme.colors.gray[200]};
  height: 1px;
  border: 0;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
`

const ConverterWrapper = styled.div`
  margin-bottom: 10px;
`

export default SideMenuBottom
