import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'
import useContacts from 'containers/note/contact/hooks/useContacts'
import NoteFormContact from 'components/jmapnote/noteForm/NoteFormContact/NoteFormContact'

const NoteScheduleContactContainer = ({
  defaultValue,
  contact_srls = [],
  onChange,
  myContacts,
}) => {
  return (
    <NoteFormContact
      contact_srls={contact_srls}
      myContacts={myContacts}
      onChange={onChange}
      noContainer
    />
  )
}

export default NoteScheduleContactContainer
