import theme, { media } from 'lib/styles/theme'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

/**
 * 광고 폼 floating 버튼
 */
const NoteAdFormFloatingButton = () => {
  const history = useHistory()

  const onClickGoAdList = () => {
    history.push('/note/v2/ad')
  }

  const onClickGoBack = () => {
    history.goBack()
  }

  return (
    <Wrapper windowwidth={window.innerWidth}>
      <Button onClick={onClickGoAdList} blue>
        광고
        <br />
        목록
      </Button>
      <Button onClick={onClickGoBack}>
        뒤로
        <br />
        가기
      </Button>
    </Wrapper>
  )
}

const getSideSingleWidth = (windowwidth) =>
  (windowwidth - theme.base.navWidth - 1180) / 2

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: ${({ windowwidth }) =>
    getSideSingleWidth(windowwidth) > 80
      ? `${
          getSideSingleWidth(windowwidth) +
          theme.base.navWidth +
          1180 +
          20
        }px`
      : `initial; right: 20px;`};
  bottom: 30px;
  gap: 8px;

  ${media.smallPc`
    left: initial;
    right: 20px;
  `}

  ${media.mediumM`
    bottom: ${theme.base.bottomMenuHeight + 20}px;
  `}
  
  ${media.mediumS`
    bottom: ${theme.base.bottomMenuHeight + 14}px;
  `}
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background-color: ${theme.colors.base.white};
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  color: ${theme.colors.gray[600]};
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  ${({ blue }) =>
    blue &&
    `
        background-color: ${theme.colors.blue[400]};
        color: ${theme.colors.base.white};
    `}

  ${media.mediumS`
    width: 48px;
    height: 48px;
  `}
`

export default NoteAdFormFloatingButton
