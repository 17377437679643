import {
  MainWrapper,
  MaxWidthWrapper,
} from 'components/jmapnotev2/style/NoteStyle'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NotePaymentResult from './NotePaymentResult'

const NotePaymentFailPage = () => {
  return (
    <MainWrapper noside gray>
      <InnerWrapper>
        <NotePaymentResult isError />
      </InnerWrapper>
    </MainWrapper>
  )
}

const InnerWrapper = styled(MaxWidthWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 0;
  text-align: center;
  background-color: ${theme.colors.base.white};

  .ant-spin-text {
    margin-top: 10px;
  }
`
export default NotePaymentFailPage
