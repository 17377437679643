import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useEffect } from 'react'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 주차가능 여부
 * @valueType 주차 가능여부(parkingPsbl이 매물 종류에 따라 다름) - text, boolean
 */
const NoteAdColumnParking = ({
  half = true,
  required,
  valueType = 'boolean',
}) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const parkingPsbl = watch(`article.parkingPsbl`)
  const oneroomParking = watch(`oneroomInfo.oneroomFeature.parking`)

  const isCheckedParkingPsbl =
    valueType == 'boolean' ? parkingPsbl === true : parkingPsbl === 'Y'

  const isNotCheckedParkingPsbl =
    valueType == 'boolean' ? parkingPsbl === false : parkingPsbl === 'N'

  return (
    <ColumnSet half={half}>
      <ColumnTitle required={required}>주차가능</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          fit
          label="예"
          name="floor_parking_psbl"
          value={true}
          checked={isCheckedParkingPsbl || oneroomParking === 'Y'}
          onChange={(e) => {
            onUpdate('article.parkingPsbl', JSON.parse(e.target.value))

            //원룸특징 주차가능도 업데이트
            onUpdate(
              'oneroomInfo.oneroomFeature.parking',
              JSON.parse(e.target.value) ? 'Y' : 'N',
            )
          }}
        />
        <RadioButton
          fit
          label="아니오"
          name="floor_parking_psbl"
          value={false}
          checked={isNotCheckedParkingPsbl || oneroomParking === 'N'}
          onChange={(e) => {
            onUpdate('article.parkingPsbl', JSON.parse(e.target.value))

            //원룸특징 주차가능도 업데이트
            onUpdate(
              'oneroomInfo.oneroomFeature.parking',
              JSON.parse(e.target.value) ? 'Y' : 'N',
            )
          }}
        />
        <ValidateWarn>
          <div>{errors?.article?.parkingPsbl?.message}</div>
          <div>
            {errors?.oneroomInfo?.oneroomFeature?.parking?.message}
          </div>
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnParking
