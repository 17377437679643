import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import React from 'react'
import { options } from '../../options'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 분양구분
 */
const NoteAdColumnPresaleType = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  const list = options['분양 구분']
  const tradeType = watch('trade.type')

  return (
    <ColumnSet>
      {/* 거래타입 매매일 때 필수 */}
      <ColumnTitle required={tradeType == 'A1'}>분양구분</ColumnTitle>
      <ColumnVal>
        {Object.entries(list).map(([key, value], i) => {
          return (
            <React.Fragment key={i}>
              <RadioButton
                size="small"
                fit
                label={value}
                {...register('isale.isaleType')}
                value={key}
                onChange={(e) => {
                  onUpdate('isale.isaleType', e.target.value)
                }}
                name="i_sale_type"
              />
            </React.Fragment>
          )
        })}
        <ValidateWarn>{errors?.isale?.isaleType?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnPresaleType
