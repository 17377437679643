import styled from 'styled-components'
import { Skeleton, Empty } from 'antd'
import {
  Card,
  CardHeader,
  CardBody,
  Segment,
  SegmentItem,
  CardTitle,
} from 'components/design'
import SummaryLrGraph from './SummaryLrGraph'

const SegmentWrap = styled.div`
  margin-bottom: 1rem;
`

const LoadingWrap = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

// Lr = Location Requirements
const SummaryLr = ({
  tabs,
  activedTab,
  setActivedTab,
  centerPoint,
  data,
  loading,
  originData,
  limitDistance,
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          가까운 편의시설을 <br />
          찾아봤어요
        </CardTitle>
      </CardHeader>

      <CardBody>
        <SegmentWrap>
          <Segment expand>
            {tabs.map((item, i) => (
              <SegmentItem
                key={i}
                active={activedTab === i}
                onClick={() => setActivedTab(i)}
                expand
              >
                {item}
              </SegmentItem>
            ))}
          </Segment>
        </SegmentWrap>

        {loading && (
          <LoadingWrap>
            <Skeleton />
          </LoadingWrap>
        )}

        {!loading && data?.length > 0 ? (
          <>
            <SummaryLrGraph
              data={data}
              centerPoint={centerPoint}
              limitDistance={limitDistance}
            />
            {/* <SummaryLrPannelWrap
              data={originData}
              activedTab={activedTab}
            /> */}
          </>
        ) : (
          !loading && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'데이터를 불러오지 못했습니다.'}
            />
          )
        )}
      </CardBody>
    </Card>
  )
}

export default SummaryLr
