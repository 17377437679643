import theme, { media } from 'lib/styles/theme'
import moment from 'moment'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import NoteDonghoGoMapButton from './NoteDonghoGoMapButton'

/**
 * 동호관리
 * 상단 단지 정보
 * 이름, 건축년도 등..
 */
const NoteDonghoHeaderInfo = () => {
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )

  const extra_vars = selectedDanji?.extra_vars

  return (
    <div>
      <Title>
        {extra_vars?.apt_name}
        <NoteDonghoGoMapButton background />
      </Title>
      <Desc>
        {(extra_vars?.road_address || extra_vars?.address) && (
          <DescItem>
            {extra_vars.road_address || extra_vars.address}
          </DescItem>
        )}
        {extra_vars?.approval_date && (
          <DescItem>
            {moment(extra_vars.approval_date, 'YYYY-MM-DD').format(
              'YYYY',
            )}
            년
          </DescItem>
        )}
        {extra_vars?.ho_count && (
          <DescItem>{extra_vars.ho_count}세대</DescItem>
        )}
      </Desc>
    </div>
  )
}

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 28px;
  font-weight: 700;
  line-height: 130%;

  ${media.mediumM`
    display: none;
  `}
`

const Desc = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${theme.colors.gray[600]};

  ${media.mediumM`
    display: none;
  `}
`

const DescItem = styled.span`
  &:not(:last-child) {
    &::after {
      content: '·';
      margin: 0 8px;
      line-height: 100%;
    }
  }
`

export default NoteDonghoHeaderInfo
