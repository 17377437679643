import { useEffect, useState, useContext, useMemo } from 'react'
import MapContext from 'contexts/map'
import { billionFormate, dateFormat } from 'utils/index'
import { useSelector } from 'react-redux'
import {
  Card,
  CardMore,
  CardHeader,
  CardTitle,
  Empty,
} from 'components/design'
import SummaryRealPriceTable from 'components/summary/SummaryRealPrice/SummaryRealPriceTable'
import SummaryNearbyButton from 'components/summary/SummaryGraph/SummaryNearbyButton'
import styled from 'styled-components'

const SummaryRealPrice = ({ table, landArea, currentAddressId }) => {
  const { state } = useContext(MapContext)

  const [tableData, setTableData] = useState([]) // 실거래가
  const [currentPage, setCurrentPage] = useState(0)

  // 최근 년도
  let max = new Date().getFullYear()

  useEffect(() => {
    if (!table?.real_price) return

    // 실거래가 데이블 데이터 셋팅
    let dataForTable = []

    // @INFO: 배열인지 확인
    // if (Array.isArray(table?.real_price)) {

    // addresses테이블의 대지면적을 초기값으로 저장

    dataForTable = table?.real_price?.map((item, index) => {
      if (item.land_area) {
        // 실거래가 테이블의 land_area가 있는 경우 그 값을 세팅
        landArea = item.land_area
      }
      let unit_price = 0

      if (landArea > 0) {
        unit_price = Math.round(item.price / landArea)
      }

      // 연립다세대/오피스텔/아파트인 경우에는 전용면적으로 나눕니다.
      if (
        '아파트' === item.property_type ||
        '연립다세대' === item.property_type ||
        '오피스텔' === item.property_type
      ) {
        unit_price = Math.round(item.price / item.exclusive_area)
      }

      return {
        key: index,
        type: item.property_type.includes('상업업무용')
          ? '상가'
          : item.property_type,
        year: dateFormat(item.contract_date).substring(2),
        unit_price: state.isSquareMeter
          ? billionFormate(Math.round(unit_price / 10000) * 10000)
          : billionFormate(
              Math.round((unit_price * 3.305) / 10000) * 10000,
            ),
        price: billionFormate(item.price),
        land_area: item.land_area,
        total_floor_area: item.total_floor_area,
        exclusive_area: item.exclusive_area,
        land_of_use_area: item.land_of_use_area,
        floor_number: item.floor_number,
        is_share: item.is_share,
        canceled_date: item.canceled_date,
      }
    })
    // }

    setTableData(dataForTable)
  }, [table, currentAddressId, max, state.isSquareMeter])

  const limit = 5

  const getTableData = (tableData, currentPage) => {
    // 5개씩 보여주는 로직
    const listCount = currentPage * limit
    // 페이지네이션
    // return tableData.slice(listCount, listCount + limit)

    // 점진적
    return tableData.slice(0, listCount + limit)
  }

  const computedTableData = useMemo(
    () => getTableData(tableData, currentPage),
    [tableData, currentPage],
  )

  const onClickMore = () => {
    setCurrentPage((prev) => prev + 1)
  }

  return (
    <Card relative>
      <CardHeader>
        <CardTitle>최근 실거래</CardTitle>
      </CardHeader>
      <SummaryNearbyButton />
      {tableData.length > 0 ? (
        <>
          <SummaryRealPriceTable dataSource={computedTableData} />
          <CardMoreWrapper>
            <CardMore
              visible={
                tableData.length > limit &&
                tableData.length / limit > currentPage + 1
              }
              onClick={onClickMore}
            />
          </CardMoreWrapper>
        </>
      ) : (
        <Empty>확인 가능한 실거래 내역이 없습니다.</Empty>
      )}
    </Card>
  )
}

const CardMoreWrapper = styled.div`
  padding: 20px;
  margin-top: -16px;
`

export default SummaryRealPrice
