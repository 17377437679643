import theme from 'lib/styles/theme'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import { Button, Tag } from 'components/design'
import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import { options } from './options'
import priceFormat from 'lib/utils/priceFormat'
import { numberWithCommas } from 'utils'
import { FormDesc } from './form/NoteAdFormStyle'
import { useSelector } from 'react-redux'
import TesterPaymentText from '../TesterPaymentText'

const NoteAdVerificationPayment = ({
  paymentInfo,
  onClose,
  onOk,
  price = 0,
}) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const modalRoot = document.getElementById('modal')
  const { watch } = useFormContext()

  const verificationTypeCode = watch('verification.type')

  const verificationType = useMemo(
    () => options['검증방식'][verificationTypeCode],
    [verificationTypeCode],
  )

  return ReactDOM.createPortal(
    <Wrapper>
      <Dimmer />
      <Content>
        <Title>
          아래와 같이 결제가 진행됩니다.
          <br />
          광고를 등록할까요?
        </Title>
        <Block>
          <BlockTitle>결제금액</BlockTitle>
          <Prices>
            <PriceWrapper>
              <PriceTitle>{verificationType}</PriceTitle>
              <Price>
                {numberWithCommas(
                  paymentInfo?.amount_verification || 0,
                )}
                원
              </Price>
            </PriceWrapper>
          </Prices>
        </Block>
        <Block>
          <BlockTitle>결제수단</BlockTitle>
          <Prices>
            <PriceWrapper>
              <PriceTitle>
                <Tag color="gray" size="small">
                  등록카드
                </Tag>
              </PriceTitle>
              <Price>
                {paymentInfo?.card?.issuerName || ''}{' '}
                {paymentInfo?.card?.number || ''}
              </Price>
            </PriceWrapper>
          </Prices>
        </Block>
        <Block gray>
          <Prices>
            <TotalPrice>
              <PriceTitle>최종 결제금액</PriceTitle>
              <Price large blue>
                {numberWithCommas(
                  paymentInfo?.amount_verification || 0,
                )}
                원
              </Price>
            </TotalPrice>
          </Prices>
        </Block>
        {loginInfo?.other_groups.includes('tester') && (
          <TesterPaymentText />
        )}
        <ButtonWrapper>
          <Button onClick={onClose} color="gray">
            취소
          </Button>
          <Button color="blue" onClick={onOk}>
            등록하기
          </Button>
        </ButtonWrapper>
      </Content>
    </Wrapper>,
    modalRoot,
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 426px;
  padding: 36px;
  box-shadow: ${theme.shadow['button-1']};
  z-index: 99999999;
  background-color: ${theme.colors.base.white};

  img {
    width: 80px;
  }
`

const Title = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  color: ${theme.colors.gray[800]};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[150]};

  ${({ gray }) =>
    gray &&
    `
    background-color: ${theme.colors.gray[100]}
  `}
`

const BlockTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
`

const Prices = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const TotalPrice = styled(PriceWrapper)`
  div {
    color: ${theme.colors.gray[900]} !important;
  }
`

const PriceTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  color: ${theme.colors.gray[600]};
`

const Price = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;

  ${({ blue }) => blue && `color: ${theme.colors.blue[400]}`};
  ${({ large }) => large && `font-size: 18px;`};
`

const Dimmer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.5);
`

export default NoteAdVerificationPayment
