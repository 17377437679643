import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptionsForObject } from 'lib/utils/notev2/util'
import { options } from '../../options'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 이동통신사
 */
const NoteAdColumnMobileCarrier = ({ isDisabled }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const otelecom = watch('verification.otelecom')

  const list = options['이동통신사']
  const val = list[otelecom]

  return (
    <ColumnSet half>
      <ColumnTitle required>
        소유자
        <br />
        이동통신사
      </ColumnTitle>
      <ColumnVal>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: val || '선택',
            value: val,
          }}
          isSearchable
          options={getSimpleSelectboxOptionsForObject(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('verification.otelecom')}
          onChange={(e) => {
            onUpdate('verification.otelecom', e.value)
          }}
          disabled={isDisabled}
        />
        <ValidateWarn>
          {errors?.verification?.otelecom?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnMobileCarrier
