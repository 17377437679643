import styled from 'styled-components'
import BdsDetailInfo from './BdsDetailInfo'
import BdsDetailIntroduce from './BdsDetailIntroduce'
import BdsDetailRp from './BdsDetailRp'
import BdsDetailSchool from './BdsDetailSchool'
import BdsDetailRealestate from './BdsDetailRealestate'
import theme from 'lib/styles/theme'

/**
 * 부동산 2뎁스
 * 탭 아래 섹션들
 */
const BdsDetailSection = ({ data, tabs }) => {
  return (
    <Wrapper>
      <BdsDetailInfo data={data} tabs={tabs} />
      <BdsDetailIntroduce data={data} tabs={tabs} />
      <BdsDetailRp data={data} tabs={tabs} />
      <BdsDetailSchool data={data} tabs={tabs} />
      <BdsDetailRealestate data={data} />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default BdsDetailSection
