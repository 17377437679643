import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useFormContext } from 'react-hook-form'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 공실여부
 */
const NoteAdColumnGongsil = ({ isDisabled }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const empty = watch('verification.empty')

  return (
    <ColumnSet>
      <ColumnTitle required>공실여부</ColumnTitle>
      <ColumnVal>
        <RadioWrapper>
          <RadioButton
            size="small"
            fit
            label="공실"
            {...register('verification.empty')}
            value={true}
            onChange={(e) => {
              onUpdate('verification.empty', JSON.parse(e.target.value))
            }}
            checked={empty == true}
            name="verification_gongsil"
            disabled={isDisabled}
          />
          <RadioButton
            size="small"
            fit
            {...register('verification.empty')}
            value={false}
            label="거주인 있음"
            onChange={(e) => {
              onUpdate('verification.empty', JSON.parse(e.target.value))
            }}
            checked={empty == false}
            name="verification_gongsil"
            disabled={isDisabled}
          />
        </RadioWrapper>
        <FormDesc>
          <li>거주인이 있는 경우 거주인의 허락이 반드시 필요합니다.</li>
        </FormDesc>
        <ValidateWarn>
          {errors?.verification?.empty?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export default NoteAdColumnGongsil
