import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getChannel } from 'lib/api/note/channelApi'
import Log from 'lib/utils/Log'
import useAxios from 'lib/hooks/useAxios'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import {
  setSelectedChannelInfo as dispatchSetSelectedChannelInfo,
  setChannelInfo,
} from 'modules/channel'

export default function useChannelInfo() {
  const dispatch = useDispatch()
  const params = useParams()
  const [onRequest, loading, data, error] = useAxios(getChannel)
  const [status, setStatus] = useState(null)
  const channels = useSelector((state) => state.channel.channels)

  const _getChannel = useCallback(
    async ({ channelSrl }, cancelToken) => {
      await onRequest({ channelSrl }, cancelToken)
    },
    [onRequest],
  )

  /**
   * @WARN: 채널 정보를 불러올 수 없을 때는 result가 null이거나
   * error를 반환해야 합니다.
   */
  useEffect(() => {
    if (!data) return

    Log.success('useChannelInfo', data)

    if (!data?.result) {
      dispatch(setChannelInfo(null))
      setStatus(null)
      return
    }

    if (data.error != 0) {
      dispatch(setChannelInfo(null))
      setStatus('error')
      return
    }

    const channelSrl = params?.channelSrl

    const selectedChannel = channels.find(({ channel_srl }) => {
      return channelSrl == channel_srl
    })
    dispatch(setChannelInfo(selectedChannel))
    dispatch(dispatchSetSelectedChannelInfo(selectedChannel))
    //dispatch(setChannelInfo(data.result))
    setStatus('success')
  }, [data])

  return { _getChannel, loading, status, channelResult: data?.result }
}
