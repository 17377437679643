import styled from 'styled-components'
import { ReactComponent as AllIcon } from 'assets/icon/note/filter/all.svg'
import theme from 'lib/styles/theme'
import { numberWithCommas } from 'utils'
import Collapsible from 'react-collapsible'
import {
  PropertyListItem,
  PropertyListLabel,
} from 'components/jmapnotev2/NoteFilter'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setSelectedStatus } from 'modules/noteAd'
import { ReactComponent as WritingIcon } from 'assets/icon/note/ad/filters/writing.svg'
import { ReactComponent as ApprovalWaitingIcon } from 'assets/icon/note/ad/filters/approval_waiting.svg'
import { ReactComponent as ApprovalRejectIcon } from 'assets/icon/note/ad/filters/approval_reject.svg'
import { ReactComponent as WaitingIcon } from 'assets/icon/note/ad/filters/waiting.svg'
import { ReactComponent as SendFailIcon } from 'assets/icon/note/ad/filters/send_fail.svg'
import { ReactComponent as VerificatingIcon } from 'assets/icon/note/ad/filters/verificating.svg'
import { ReactComponent as VeriCancelIcon } from 'assets/icon/note/ad/filters/verification_cancel.svg'
import { ReactComponent as VeriFail1Icon } from 'assets/icon/note/ad/filters/verification_fail_1.svg'
import { ReactComponent as VeriFailIcon } from 'assets/icon/note/ad/filters/verification_fail.svg'
import { ReactComponent as AdingIcon } from 'assets/icon/note/ad/filters/advertising.svg'
import { ReactComponent as WillEndIcon } from 'assets/icon/note/ad/filters/will_end.svg'
import { ReactComponent as EndIcon } from 'assets/icon/note/ad/filters/end.svg'
import { ReactComponent as SameIcon } from 'assets/icon/note/ad/filters/same.svg'
import { ReactComponent as ReportIcon } from 'assets/icon/note/ad/filters/report.svg'

import { ReactComponent as WritingActiveIcon } from 'assets/icon/note/ad/filters/active/writing.svg'
import { ReactComponent as ApprovalWaitingActiveIcon } from 'assets/icon/note/ad/filters/active/approval_waiting.svg'
import { ReactComponent as ApprovalRejectActiveIcon } from 'assets/icon/note/ad/filters/active/approval_reject.svg'
import { ReactComponent as WaitingActiveIcon } from 'assets/icon/note/ad/filters/active/waiting.svg'
import { ReactComponent as SendFailActiveIcon } from 'assets/icon/note/ad/filters/active/send_fail.svg'
import { ReactComponent as VerificatingActiveIcon } from 'assets/icon/note/ad/filters/active/verificating.svg'
import { ReactComponent as VeriCancelActiveIcon } from 'assets/icon/note/ad/filters/active/verification_cancel.svg'
import { ReactComponent as VeriFail1ActiveIcon } from 'assets/icon/note/ad/filters/active/verification_fail_1.svg'
import { ReactComponent as VeriFailActiveIcon } from 'assets/icon/note/ad/filters/active/verification_fail.svg'
import { ReactComponent as AdingActiveIcon } from 'assets/icon/note/ad/filters/active/advertising.svg'
import { ReactComponent as WillEndActiveIcon } from 'assets/icon/note/ad/filters/active/will_end.svg'
import { ReactComponent as EndActiveIcon } from 'assets/icon/note/ad/filters/active/end.svg'
import { ReactComponent as SameActiveIcon } from 'assets/icon/note/ad/filters/active/same.svg'
import { ReactComponent as ReportActiveIcon } from 'assets/icon/note/ad/filters/active/report.svg'

const icons = {
  작성중: {
    basic: <WritingIcon />,
    active: <WritingActiveIcon />,
  },
  승인대기: {
    basic: <ApprovalWaitingIcon />,
    active: <ApprovalWaitingActiveIcon />,
  },
  승인거절: {
    basic: <ApprovalRejectIcon />,
    active: <ApprovalRejectActiveIcon />,
  },
  대기중: {
    basic: <WaitingIcon />,
    active: <WaitingActiveIcon />,
  },
  전송중: {
    basic: <WaitingIcon />,
    active: <WaitingActiveIcon />,
  },
  전송실패: {
    basic: <SendFailIcon />,
    active: <SendFailActiveIcon />,
  },
  검증중: {
    basic: <VerificatingIcon />,
    active: <VerificatingActiveIcon />,
  },
  검증취소: {
    basic: <VeriCancelIcon />,
    active: <VeriCancelActiveIcon />,
  },
  검증1차실패: {
    basic: <VeriFail1Icon />,
    active: <VeriFail1ActiveIcon />,
  },
  검증최종실패: {
    basic: <VeriFailIcon />,
    active: <VeriFailActiveIcon />,
  },
  광고중: {
    basic: <AdingIcon />,
    active: <AdingActiveIcon />,
  },
  종료예정: {
    basic: <WillEndIcon />,
    active: <WillEndActiveIcon />,
  },
  광고종료: {
    basic: <EndIcon />,
    active: <EndActiveIcon />,
  },
  동일주소매물: {
    basic: <SameIcon />,
    active: <SameActiveIcon />,
  },
  신고매물: {
    basic: <ReportIcon />,
    active: <ReportActiveIcon />,
  },
}

const NoteAdFilter = () => {
  const dispatch = useDispatch()
  const statusData = useSelector((state) => state.noteAd.status.datas) //상태 값 데이터들
  const selectedStatus = useSelector(
    (state) => state.noteAd.status.selectedStatus,
  ) //선택된 상태 값 필터

  const onClickFilter = (value) => {
    dispatch(setSelectedStatus(value))
  }

  const onClickAllFilter = () => {
    dispatch(setSelectedStatus('전체'))
  }

  const isSelectAll = !selectedStatus || selectedStatus == '전체'

  return (
    <Wrapper>
      {statusData && (
        <>
          <PropertyListItem
            small
            all
            active={isSelectAll}
            onClick={onClickAllFilter}
          >
            <AllIcon />
            전체
            <PropertyListCount active={isSelectAll}>
              {numberWithCommas(statusData?.['전체'] || 0)}
            </PropertyListCount>
          </PropertyListItem>
          {Object.keys(statusData).map((type, i) => {
            if (type && type != '전체')
              return (
                <PropertyListItem
                  key={i}
                  padding
                  small
                  active={type == selectedStatus}
                  className="note-ad-left-filter-pannel"
                  onClick={(e) => onClickFilter(type)}
                >
                  {type == selectedStatus
                    ? icons[type]?.active || null
                    : icons[type]?.basic || null}
                  <PropertyListLabel>{type}</PropertyListLabel>
                  {statusData?.[type] ? (
                    <PropertyListCount active={type == selectedStatus}>
                      {statusData[type]}
                    </PropertyListCount>
                  ) : null}
                </PropertyListItem>
              )
          })}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const PropertyListCount = styled.span`
  margin-left: 8px;
  padding: 2px 4px;
  background-color: ${theme.colors.gray[300]};
  border-radius: 2px;
  font-size: 8px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[900]};

  ${({ active }) =>
    active &&
    `
      background-color: ${theme.colors.base.white};
    `}
`

export default NoteAdFilter
