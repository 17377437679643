import { useMemo } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { useFormContext } from 'react-hook-form'

import { Select } from 'components/design'
import { ListItemDivider } from 'components/design'
import { ListItemMenu } from 'components/design'

import NoteContactItemBase from 'components/jmapnote/contact/NoteContactItemBase'
import axios from 'axios'
import { useSelector } from 'react-redux'

const NoteFormContactItem = ({
  myContacts,
  contacts,
  item,
  srl,
  noContainer,
  onChange,
}) => {
  // const { watch, setValue } = useFormContext()
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const getContactInfo = (contacts, srl, myContacts) => {
    const findInContacts = contacts.find(
      (e) => e.contact_srl?.toString() === srl?.toString(),
    )
    const findInMyContacts = myContacts.find(
      (e) => e.contact_srl?.toString() === srl?.toString(),
    )

    return findInContacts || findInMyContacts
  }
  const contactInfo = useMemo(
    () => getContactInfo(contacts, srl, myContacts),
    [contacts, srl, myContacts],
  )

  const onClickMenu = ({ key }) => {
    switch (key) {
      case '1':
        onRemove()
        break
    }
  }

  const onModifyType = (value) => {
    const modifiedContactSrls = contacts.map((item) => {
      if (item?.contact_srl?.toString() === srl?.toString()) {
        return {
          ...item,
          note_contact_srl: item.note_contact_srl,
          calendar_contact_srl: item.calendar_contact_srl,
          contact_srl: item.contact_srl,
          alias: item.alias,
          numbers: item.numbers,
          type: value,
        }
      }
      return item
    })

    onChange(modifiedContactSrls)
  }

  const onRemove = () => {
    const removedContactSrls = contacts.filter(
      (e) => e.contact_srl?.toString() !== srl?.toString(),
    )
    onChange(removedContactSrls)
  }

  const items = [
    {
      label: '이 연락처를 삭제',
      key: '1',
      icon: <DeleteOutlined />,
      danger: true,
    },
  ]

  const menuProps = {
    items,
    onClick: onClickMenu,
  }

  return (
    <NoteContactItemBase
      alias={contactInfo?.alias}
      numbers={contactInfo?.numbers}
      memo={contactInfo?.memo}
      noContainer={noContainer}
      append={
        <ListItemMenu
          menus={menuProps}
          onClickMenu={onClickMenu}
          top
        ></ListItemMenu>
      }
    >
      <ListItemDivider xs />

      <Select
        value={item?.type}
        small
        onChange={(e) => onModifyType(e.target.value)}
      >
        <option value="">분류 선택</option>
        <option value="집주인">집주인</option>
        <option value="세입자">세입자</option>
        <option value="관심인">관심인</option>
        <option value="기타">기타</option>
      </Select>
    </NoteContactItemBase>
  )
}

export default NoteFormContactItem
