import useAxios from '../useAxios'
import {
  approvalRequest,
  preregisterAd,
  recycleListing,
  registerAd,
} from 'lib/api/notev2/adSubmit'
import { useEffect, useRef, useState } from 'react'
import NoteAdVerificationPayment from 'components/jmapnotev2/NoteAd/NoteAdVerificationPayment'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import { message } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import { setAdFormControl } from 'modules/noteAd'
import { useSelector } from 'react-redux'

/**
 * 광고관리
 * 광고 등록 시 검증 + 결제
 * @param adSrl
 * @param recycleCallback 재광고시 callback function
 */
function useAdRegister(adSrl, recycleCallback) {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const [onRequestPreregister, , dataPreregister, errorPreregister] =
    useAxios(preregisterAd, {
      resetError: true,
    }) //대표 매물 전송 버튼
  const [onRequestRegister, , dataRegister, errorRegister] = useAxios(
    registerAd,
    {
      resetError: true,
    },
  ) //결제 모달에서 호출
  const [onRequestRecycle, , dataRecycle, errorRecycle] = useAxios(
    recycleListing,
    {
      resetError: true,
    },
  ) //결제 모달에서 호출 - 검증최종실패, 광고종료에서는 재광고 요청
  const [onRequestApprovalReq, , dataApprovalReq, errorApprovalReq] =
    useAxios(approvalRequest, {
      resetError: true,
    }) //직원 - 승인 요청

  const [isRecycle, setIsRecycle] = useState(false) //검증최종실패, 광고종료 여부(재광고 요청 해야함)
  const [validationError, setValidationError] = useState(null) // 컬럼 검증 결과
  const [paymentInfo, setPaymentInfo] = useState(null) // 결제 정보
  const [paymentModalVisible, setPaymentModalVisible] = useState(false) // 결제 모달
  const [isLoading, setIsLoading] = useState(false) // 로딩
  const [isProcessing, setIsProcessing] = useState(false) // 결제 후 5분 걸린다는 모달

  const dispatch = useDispatch()

  const history = useHistory()

  let timeoutFn = useRef(null)

  /**
   * 결제 모달 띄우기 전 검증 + 결제 정보 호출
   * @param recycle 검증최종실패, 광고종료 여부(재광고 요청 해야함)
   */
  const preregister = ({ recycle = false }) => {
    if (!adSrl) {
      message.error('잘못된 접근입니다.')
      return
    }

    setIsRecycle(recycle)
    setIsLoading(true)
    onRequestPreregister({
      ad_srl: adSrl,
      plan_srl: planInfo.allinone_plan_srl,
    })
  }

  useEffect(() => {
    if (!dataPreregister) return

    if (!dataPreregister?.result) {
      setIsLoading(false)
      setPaymentModalVisible(false)
      return
    }

    const result = dataPreregister.result
    if (result.errors && result.errors.length > 0) {
      setPaymentModalVisible(false)
      setValidationError(result.errors)
      setIsLoading(false)
      setPaymentInfo(null)
      return
    }

    setIsLoading(false)
    setPaymentInfo({
      card: result.card,
      amount_verification: result.amount_verification, //검증 비용
      total_price: result.total_prcie, //최종 결제금액
    })
    setPaymentModalVisible(true)

    return () => {
      setValidationError(null)
      setPaymentModalVisible(false)
      setPaymentInfo(null)
      setIsLoading(false)
    }
  }, [dataPreregister])

  useEffect(() => {
    if (!errorPreregister) return
    setIsLoading(false)
  }, [errorPreregister])

  /**
   * 직원 - 대표에게 승인 요청
   */
  const approvalReq = () => {
    setIsLoading(true)
    onRequestApprovalReq({
      ad_srl: adSrl,
      plan_srl: planInfo.allinone_plan_srl,
    })
  }

  useEffect(() => {
    if (!dataApprovalReq) return

    const result = dataApprovalReq?.result

    if (result.errors && result.errors.length > 0) {
      setValidationError(result.errors)
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    history.replace(`/note/v2/ad`)

    return () => {
      setValidationError(null)
      setIsLoading(false)
    }
  }, [dataApprovalReq])

  useEffect(() => {
    if (!errorApprovalReq) return
    setIsLoading(false)
  }, [errorApprovalReq])

  /**
   * 결제 모달 등록하기 버튼
   */
  const onSubmitModal = () => {
    if (!adSrl) {
      message.error('잘못된 접근입니다.')
      return
    }
    setIsLoading(true)

    if (isRecycle) {
      onRequestRecycle({
        ad_srl: adSrl,
        plan_srl: planInfo.allinone_plan_srl,
        amount_verification: paymentInfo.amount_verification,
        point_balance: paymentInfo.point_balance,
        total_price: paymentInfo.total_price,
      })
    } else {
      onRequestRegister({
        ad_srl: adSrl,
        plan_srl: planInfo.allinone_plan_srl,
        amount_verification: paymentInfo.amount_verification,
      })
    }
  }

  useEffect(() => {
    if (!dataRegister) return

    if (dataRegister.errors && dataRegister.errors.length > 0) {
      setPaymentModalVisible(false)
      setValidationError(dataRegister.errors)
      setIsLoading(false)
      setPaymentInfo(null)
      return
    }

    setIsLoading(false)

    dispatch(
      setAdFormControl({
        adSrl: adSrl,
        isDisabledAll: true,
      }),
    )

    setIsProcessing(true)
    setPaymentModalVisible(false)
    setIsRecycle(false)

    timeoutFn.current = setTimeout(function () {
      setIsProcessing(false)
      history.push('/note/v2/ad')
    }, 5000)

    return () => {
      if (!timeoutFn?.current) return
      clearTimeout(timeoutFn.current)
    }
  }, [dataRegister])

  useEffect(() => {
    if (!errorRegister) return
    setIsLoading(false)
  }, [errorRegister])

  useEffect(() => {
    if (!errorRecycle) return
    setIsLoading(false)
  }, [errorRecycle])

  /**
   * 재광고 요청 성공 후 callback
   */
  useEffect(() => {
    if (!dataRecycle) return

    if (dataRecycle?.error == 0) {
      setIsLoading(false)

      setIsProcessing(true)
      setPaymentModalVisible(false)
      setIsRecycle(false)

      message.success('재광고 요청이 완료되었습니다.')
    }

    if (recycleCallback) {
      recycleCallback()
    } else {
      history.push('/note/v2/ad')
    }
  }, [dataRecycle])

  /**
   * 결제 모달 닫기
   */
  const onCloseSubmitModal = () => {
    setPaymentModalVisible(false)
  }

  return {
    preregister, //결제 모달 띄우기 전 검증
    approvalReq, //직원 - 대표에게 검증 요청
    validationError, // 컬럼 검증 결과
    paymentModal: paymentModalVisible ? (
      <NoteAdVerificationPayment
        paymentInfo={paymentInfo}
        onOk={onSubmitModal}
        onClose={onCloseSubmitModal}
      />
    ) : null, // 결제 모달
    loading: isLoading ? <FullScreenLoading /> : null, // api 처리 중 로딩
    setLoading: setIsLoading,
    isProcessing,
  }
}

export default useAdRegister
