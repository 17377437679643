import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import useAxios from 'lib/hooks/useAxios'
import { getNote, getOptions } from 'lib/api/note/noteApi'
import { getContacts } from 'lib/api/note/contactApi'
import { setNoteContacts, setNoteOptions } from 'modules/notev2'
import { useDispatch } from 'react-redux'
import useModifyNoteDetailForm from 'lib/hooks/notev2/useModifyNoteDetailForm'
import usePreventLeave from 'lib/hooks/usePreventLeave'
import { setClickedMarker } from 'modules/note'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import useChannels from 'containers/note/channel/hooks/useChannels'
import { useSelector } from 'react-redux'

const MapFavoriteNoteDetailContainer = ({
  children,
  visible,
  params,
  onClose,
}) => {
  const { enablePrevent, disablePrevent } = usePreventLeave()

  const channels = useSelector((state) => state.channel.channels)
  const { _getChannels: getChannels } = useChannels()
  const dispatch = useDispatch()
  const [onRequestNote, loadingNote, dataNote] = useAxios(getNote)
  const methods = useForm()
  const [onRequestGetOption, loadingOptions, dataOptions] =
    useAxios(getOptions)
  const [onRequestGetContacts, loadingContacts, dataContacts] =
    useAxios(getContacts)

  const [onModifyNote, , onModifyAll] = useModifyNoteDetailForm()

  const defaultValues = useRef({
    direction_criteria: '거실',
    contact_srls: [
      {
        contract_srl: '',
        member_srl: '',
        type: '구분없음',
        numbers: [
          {
            number: '',
            label: '휴대전화',
          },
        ],
        alias: '',
        memo: '',
      },
    ],
  })

  useEffect(() => {
    enablePrevent()

    return () => {
      disablePrevent()
    }
  }, [])

  useEffect(() => {
    const preventGoBack = () => {
      onClose()
    }

    history.pushState(null, '', location.href)
    window.addEventListener('popstate', preventGoBack)

    return () => window.removeEventListener('popstate', preventGoBack)
  }, [])

  useEffect(() => {
    if (!visible) return

    onRequestGetOption()
    onRequestGetContacts()
    getChannels()
    onRequestNote({
      channelSrl: params.channel_srl,
      noteSrl: params.note_srl,
      addressSrl: params.address_srl,
    })
  }, [visible])

  useEffect(() => {
    if (!dataNote) return
    methods.reset(
      Object.assign(
        {},
        dataNote?.result?.extra_vars,
        dataNote.result,
        defaultValues.current,
        {
          contact_srls: JSON.stringify(dataNote.result.contacts),
        },
      ),
    )
  }, [dataNote])

  useEffect(() => {
    if (!dataOptions || !dataOptions.options) return

    dispatch(setNoteOptions(dataOptions.options))
  }, [dataOptions])

  useEffect(() => {
    if (!dataContacts || !dataContacts.result) return

    dispatch(setNoteContacts(dataContacts.result))
  }, [dataContacts])

  const onCloseLayer = () => {
    if (!visible) return
    //닫기 전 데이터 한번 더 저장

    //읽기 전용이면 return
    const channelSrl = methods.getValues().channel_srl
    const isReadonly =
      channels.find(
        ({ channel_srl: myChannelSrl }) => myChannelSrl == channelSrl,
      )?.member_type == 'readonly'

    if (isReadonly) {
      dispatch(setClickedMarker([]))
      onClose()
      return
    }

    if (methods) {
      onModifyAll({
        data: methods.getValues(),
        updateData: methods.getValues(),
      })
    }

    dispatch(setClickedMarker([]))
    onClose()
  }

  return children({
    methods,
    loading: loadingNote || loadingOptions || loadingContacts,
    onCloseLayer,
  })
}

export default MapFavoriteNoteDetailContainer
