import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import theme from 'lib/styles/theme'
import { setSelectedDong } from 'modules/noteDongho'
import { useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

/**
 * 동호관리 모바일
 * 동 리스트 dropdown
 */
const NoteDonghoHeaderDongListMobile = ({ visible, onClose }) => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const dongs = useSelector(
    (state) => state.noteDongho.selectedDanjiDongs,
  )
  const selectedDong = useSelector(
    (state) => state.noteDongho.selectedDong,
  )
  const selectedDanjiDongsDot = useSelector(
    (state) => state.noteDongho.selectedDanjiDongsDot,
  )

  const ref = useRef()

  useOnClickOutside(ref, (e) => {
    onClose()
  })

  /**
   * 동 선택
   */
  const onClick = (dong) => {
    if (dong) {
      history.replace(`/note/v2/dongho/${params?.id}/${dong}`)
    } else {
      dispatch(setSelectedDong(null))
      history.replace(`/note/v2/dongho/${params?.id}`)
    }
    onClose()
  }

  return (
    visible && (
      <Wrapper ref={ref}>
        <Scrollbars autoHide>
          {dongs?.map((dong, i) => {
            const hasState = selectedDanjiDongsDot?.[dong] > 0
            return (
              <Item
                key={`dong_dropdown_${i}`}
                onClick={() => onClick(dong)}
                active={dong == selectedDong}
                dot={hasState}
              >
                {dong}
                {hasState ? <Dot /> : ''}
              </Item>
            )
          })}
        </Scrollbars>
      </Wrapper>
    )
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 176px;
  height: 270px;
  padding-top: 12px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: ${theme.colors.base.white};
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.gray[100]};
  `}

  ${({ dot }) =>
    dot &&
    `
    font-weight: 600;
  `}
`

const Dot = styled.div`
  position: relative;
  top: -6px;
  left: 3px;
  width: 6px;
  height: 6px;
  background-color: ${theme.colors.blue[400]};
  border-radius: 50%;
`

export default NoteDonghoHeaderDongListMobile
