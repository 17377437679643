import { Modal } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NoteListMobileContactModal = ({ visible, onClose, data }) => {
  return (
    <Modal
      hiddenHeader
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Wrapper>
        <Header>
          <HeaderText>전화걸기</HeaderText>
          <CloseButton onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </CloseButton>
        </Header>

        {data?.length > 0 ? (
          <List>
            {data.map(({ alias, numbers, type }) => {
              return (
                <ListItem href={`tel:${numbers?.[0]?.number}`}>
                  <Title>{type || '지정되지 않음'}</Title>
                  <Info>
                    <Name>{alias}</Name>
                    <Number>{numbers?.[0]?.number}</Number>
                  </Info>
                  <Icon className="fas fa-phone"></Icon>
                </ListItem>
              )
            })}
          </List>
        ) : (
          <>매물에 등록된 연락처가 없습니다.</>
        )}
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  width: calc(100% + 40px);
  height: 100%;
  margin: -20px -20px;
  padding: 40px 20px 20px 20px;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${theme.colors.gray[50]};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: ${theme.colors.gray[800]};
`

const HeaderText = styled.div`
  margin-top: 2px;
`

const CloseButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;

  color: $color-gray-700;
  font-size: 1rem;

  background-color: $color-gray-100;
  border-radius: 8px;

  transition: background-color 250ms;

  &:hover {
    background-color: $color-gray-200;
  }

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ListItem = styled.a`
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  padding: 12px;
  background-color: ${theme.colors.base.white};
  border-radius: 8px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  color: ${theme.colors.gray[800]};
`

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Name = styled.div`
  font-size: 14px;
  color: ${theme.colors.gray[600]};
  font-weight: 500;
  line-height: 130%;
`

const Number = styled.div`
  color: ${theme.colors.blue[400]};
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
`

const Icon = styled.i`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`

export default NoteListMobileContactModal
