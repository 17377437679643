import styled from 'styled-components'
import SummaryAptRpItem from './SummaryAptRpItem'
import { Empty } from 'components/design'
import theme from 'lib/styles/theme'

const SummaryAptRpTable = ({
  data,
  onClickItem,
  activeItem,
  clickable,
}) => {
  return (
    <TableWrapper>
      {data.length > 0 ? (
        <StyledTable>
          <thead>
            <tr>
              <th className="date">계약일</th>
              <th className="center">가격</th>
              <th className="center">타입</th>
              <th className="center">층수</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <SummaryAptRpItem
                key={i}
                date={item.date}
                contract_type={item.contract_type}
                renewal_right_used={item.renewal_right_used}
                type={item.type}
                price={item.price}
                area_type={item.area_type}
                floor={item.floor}
                canceled_date={item.canceled_date}
                price_id={item.price_id}
                activeItem={activeItem}
                clickable={clickable}
                onClickItem={onClickItem}
              />
            ))}
          </tbody>
        </StyledTable>
      ) : (
        <Empty>조건에 맞는 실거래 내역이 없습니다.</Empty>
      )}
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  padding: 14px 16px 8px 16px;
`
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    &:first-child {
      width: 70px;
    }
  }

  th {
    padding: 6px;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    color: ${theme.colors.gray[600]};
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    &.center {
      text-align: center;
    }

    &.date {
      padding-right: 0;
    }
  }

  td {
    padding: 9px 6px;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    font-size: 13px;
    word-break: keep-all;

    &.center {
      text-align: center;
    }

    &.date {
      padding-right: 0;
      color: ${theme.colors.gray[700]};
    }
  }
`

export default SummaryAptRpTable
