import { useEffect, useState } from 'react'
import { Form } from 'components/design'
import { FormItem } from 'components/design'
import { FormDivider } from 'components/design'
import { Modal } from 'components/design'
import { Select } from 'components/design'
import CalendarNoteSelect from './CalendarNoteSelect'
import CalendarNoteSelectItem from './CalendarNoteSelectItem'
import moment from 'moment'
import { Input } from 'components/design'
import { Checkbox } from 'components/design'
import styled from 'styled-components'
import { Textarea } from 'components/design'
import useChannels from 'containers/note/channel/hooks/useChannels'
import Axios from 'axios'
import NoteScheduleContactContainer from 'containers/note/noteForm/NoteScheduleContactContainer'
import useContacts from 'containers/note/contact/hooks/useContacts'
import { notification } from 'antd'
import theme from 'lib/styles/theme'

const NoteCalendarForm = ({
  defaultValue,
  title,
  visible,
  setVisible,
  onSubmit,
  currentDate, // save 시 필요
}) => {
  const {
    _getChannels,
    loading: _getChannelsLoading,
    channels,
    reset: _getChannelsReset,
    _getChannelsSource,
  } = useChannels()

  const [type, setType] = useState('')
  const [content, setContent] = useState('')
  const [channelSrl, setChannelSrl] = useState('')
  const [folderSrl, setFolderSrl] = useState('')
  const [addressSrl, setAddressSrl] = useState('')
  const [noteSrl, setNoteSrl] = useState('')
  const [date, setDate] = useState(new Date())
  const [noteData, setNoteData] = useState(null)
  const [contactSrls, setContactSrls] = useState([])

  const [isCustomType, setIsCustomType] = useState(false)
  const [customTypeChecked, setCustomTypeChecked] = useState(false)

  const types = [
    '상담',
    '계약일',
    '입주가능',
    '입주예정',
    '중도금일',
    '잔금일',
    '계약만기',
    '매물방문',
    '기타',
    '직접입력',
  ]

  const { _getContacts, loading, contacts: myContacts } = useContacts()

  /**
   * @description 사용자 자신의 연락처 목록을 불러옴
   */
  useEffect(() => {
    _getContacts()
  }, [_getContacts])

  useEffect(() => {
    const source = Axios.CancelToken.source()
    _getChannelsReset()
    _getChannels(source.token)

    return () => {
      source.cancel()
    }
  }, [])

  useEffect(() => {
    if (!defaultValue) {
      setType('')
      setContent('')
      setChannelSrl(channels?.[0]?.channel_srl || '')
      setFolderSrl('')
      setAddressSrl('')
      setNoteSrl('')
      setDate(currentDate || new Date())
      setContactSrls([])
      setIsCustomType(false)
      setCustomTypeChecked(false)

      return
    }

    setType(defaultValue.type || '')
    setContent(defaultValue.content || '')
    setChannelSrl(
      defaultValue.channel_srl || channels?.[0]?.channel_srl || '',
    )
    setFolderSrl(defaultValue.folder_srl || '')
    setAddressSrl(defaultValue.address_srl || '')
    setNoteSrl(defaultValue.note_srl || '')
    setNoteData(defaultValue || null)
    setDate(defaultValue.date || new Date())
    setContactSrls(defaultValue?.contacts || [])
  }, [visible, defaultValue])

  useEffect(() => {
    if (type === '직접입력') {
      setIsCustomType(true)
      setCustomTypeChecked(true)
      setType('')
      return
    }

    if (!type && !customTypeChecked) {
      setIsCustomType(false)
      return
    }

    // 정해진 타입과 일치하는지 확인
    const findTypes = types.find((e) => e === type)

    if (!findTypes) {
      setIsCustomType(true)
      return
    }

    setIsCustomType(false)
  }, [type])

  useEffect(() => {
    setCustomTypeChecked(isCustomType)
  }, [isCustomType])

  // 일정 추가
  const onOk = () => {
    if (!channelSrl) {
      notification.error({
        message: '채널을 선택해주세요.',
      })
      return
    }
    if (!type) {
      notification.error({
        message: '타입을 선택해주세요.',
      })
      return
    }

    onSubmit({
      channelSrl,
      folderSrl,
      addressSrl,
      noteSrl,
      type,
      date: moment(date).format('YYYY-MM-DD'),
      content,
      contact_srls: contactSrls,
    })
  }

  const onCancel = () => {
    setVisible(false)
  }

  const onChangeCustomTypeChecked = () => {
    setIsCustomType(false)
    setCustomTypeChecked(false)
    setType('')
  }

  const handleRemoveNote = () => {
    setFolderSrl('')
    setAddressSrl('')
    setNoteSrl('')
  }

  const onChangeChannel = (e) => {
    setChannelSrl(e.target.value)
    setFolderSrl('')
    setAddressSrl('')
    setNoteSrl('')
    setNoteData(null)
  }

  return (
    <Modal
      okButtonProps={{
        color: 'blue',
      }}
      visible={visible}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form>
        <FormItem isRequired noContainer>
          <Title>
            채널 선택
            <Required color="blue">필수</Required>
          </Title>
          <Select
            border="blue"
            color="blue"
            value={channelSrl}
            onChange={(e) => onChangeChannel(e)}
            expand
          >
            <option value="">채널 선택</option>
            {channels.map((item, i) => (
              <option key={i} value={item.channel_srl}>
                {item?.name}
              </option>
            ))}
          </Select>
        </FormItem>

        <FormItem isRequired noContainer>
          <Title>
            타입
            <Required color="blue">필수</Required>
          </Title>
          {isCustomType ? (
            <StyledCustomForm>
              <StyledInputWrap>
                <Input
                  color="blue"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  placeholder="타입"
                  expand
                />
              </StyledInputWrap>
              <Checkbox
                color="blue"
                checked={customTypeChecked}
                onChange={onChangeCustomTypeChecked}
              >
                직접입력
              </Checkbox>
            </StyledCustomForm>
          ) : (
            <Select
              color="blue"
              value={type}
              onChange={(e) => setType(e.target.value)}
              expand
              border="blue"
            >
              <option value="">타입 선택</option>
              {types.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          )}
        </FormItem>

        <FormItem noContainer>
          <Title>메모</Title>
          <StyledTextarea
            border="blue"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="일정에 대한 설명을 작성해보세요."
          />
        </FormItem>

        <FormItem disabled={!channelSrl} noContainer noMargin>
          <Title>매물 연동</Title>
          <SubTitle>
            일정에 매물을 연동해보세요. 매물 내에서 일정을 확인할 수
            있으며, 일정에 연동된 매물로 바로 이동할 수 있습니다.
          </SubTitle>

          {noteSrl && noteData ? (
            <>
              <CalendarNoteSelectItem
                item={noteData}
                outline
                onRemove={handleRemoveNote}
              />
              <FormDivider noBorder small />
            </>
          ) : null}

          <CalendarNoteSelect
            channelSrl={channelSrl}
            setChannelSrl={setChannelSrl}
            folderSrl={folderSrl}
            setFolderSrl={setFolderSrl}
            addressSrl={addressSrl}
            setAddressSrl={setAddressSrl}
            noteSrl={noteSrl}
            setNoteSrl={setNoteSrl}
            noteData={noteData}
            setNoteData={setNoteData}
          />
        </FormItem>

        <NoteScheduleContactContainer
          contact_srls={contactSrls}
          myContacts={myContacts}
          onChange={setContactSrls}
        />
      </Form>
    </Modal>
  )
}

const StyledCustomForm = styled.div`
  display: flex;
  align-items: center;
`
const StyledInputWrap = styled.div`
  flex: 1;
  margin-right: 1rem;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  color: ${theme.colors.gray[800]};
  font-size: 14px;
  font-weight: 700;
`

const Required = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 17px;
  background-color: ${theme.colors.primary[400]};
  color: ${theme.colors.base.white};
  font-size: 11px;
  font-weight: 600;
  border-radius: 4px;
  line-height: 100%;

  ${({ color }) =>
    color == 'blue' &&
    `
    background-color: ${theme.colors.blue[400]};
  `}
`

const SubTitle = styled.div`
  margin-bottom: 8px;
  color: ${theme.colors.gray[500]};
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
`

const StyledTextarea = styled(Textarea)`
  background-color: #f4f6fb;
`

export default NoteCalendarForm
