import { Button } from 'components/design'
import SummaryLegalHistory from './SummaryLegalHistory'

const SummaryLegalHistoryInfo = ({ move, luris }) => {
  return (
    <>
      {move && <SummaryLegalHistory move={move} />}
      {luris && (
        <a href={luris} target="_blank">
          <Button expand color="gray">
            토지e음
          </Button>
        </a>
      )}
    </>
  )
}

export default SummaryLegalHistoryInfo
