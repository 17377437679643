import { Select } from 'components/design'
import MapFilterItem from 'components/map/MapFilter/MapFilterItem'
import NoteFilterSelect from './NoteFilterSelect'

import Slider from 'components/common/Slider/Slider'
import { useSelector } from 'react-redux'
import { Checkbox } from 'components/design'
import styled from 'styled-components'

const StyledCheckboxWrap = styled.div`
  flex-basis: 50%;
  max-width: 50%;
  padding: 0.25rem 0.25rem 1rem;
`

const NoteFilterExtra = ({
  setSelectedMoveDate,

  floorCnt,
  selectedFloorCnt,
  setSelectedFloorCnt,

  extraOptions,
  selectedExtraOptions,
  setSelectedExtraOptions,
}) => {
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1
  const filterMoveDate = useSelector(
    (state) => state.note.filterStates.move_date,
  )

  const formatYear = (value) => {
    return '’' + value.toString().substr(2, 2)
  }

  return (
    <>
      <MapFilterItem
        title="입주 가능 시기"
        titleValue={
          filterMoveDate.toString() === `${month},${month + 11}`
            ? '전체'
            : filterMoveDate
                .map((value, index) => {
                  const date = parseInt(value)
                  if (date > 12 && date < month + 11) {
                    return `${formatYear(year + 1)}.${date - 12}`
                  } else if (date === month + 11) {
                    return ''
                  } else {
                    return '' + formatYear(year) + '.' + date
                  }
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <Slider
          range={{ min: month, max: month + 11 }}
          start={filterMoveDate}
          margin={1}
          step={1}
          connect
          behaviour="snap"
          pips={{
            mode: 'positions',
            values: [0, 20, 40, 60, 80, 100],
            density: 12,
            format: {
              to: (value) => {
                const date = parseInt(value)
                if (date === month) {
                  return formatYear(year) + '.' + date
                } else if (date > 12 && date < month + 11) {
                  return `${formatYear(year + 1)}.${date - 12}`
                } else if (date === month + 11) {
                  return `${formatYear(year + 1)}.${date - 12}~`
                } else {
                  return formatYear(year) + '.' + date
                }
              },
            },
          }}
          onChange={(render, handle, value, un, percent) => {
            setSelectedMoveDate(value)
          }}
        />
      </MapFilterItem>
      <MapFilterItem title="층수">
        <NoteFilterSelect>
          <Select
            value={selectedFloorCnt}
            onChange={(e) => setSelectedFloorCnt(e.target.value)}
          >
            <option value="">층수 선택</option>
            {floorCnt.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </NoteFilterSelect>
      </MapFilterItem>

      <MapFilterItem title="기타옵션">
        <NoteFilterSelect>
          {extraOptions.map((item, i) => (
            <StyledCheckboxWrap key={i}>
              <Checkbox
                value={item}
                multiValues={selectedExtraOptions}
                onMultipleChange={(value) =>
                  setSelectedExtraOptions(value)
                }
              >
                {item}
              </Checkbox>
            </StyledCheckboxWrap>
          ))}
        </NoteFilterSelect>
      </MapFilterItem>
    </>
  )
}

export default NoteFilterExtra
