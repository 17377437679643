import { message } from 'antd'
import axios from 'axios'
import MapContext from 'contexts/map'
import useAxios from 'lib/hooks/useAxios'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import { getAddress } from 'lib/utils'
import { setSearchAptFilter } from 'modules/bds'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const BdsSearchContainer = ({ children, clearSearch }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { state } = useContext(MapContext)
  const { map, viewState } = state
  const [address, setAddress] = useState([])
  const [places, setPlaces] = useState([])
  const [visibleSearch, setVisibleSearch] = useState(false)

  const ref = useRef()
  useOnClickOutside(ref, () => setVisibleSearch(false))

  // @METHOD: 주소 검색
  const searchAddress = useCallback(
    (value) => {
      // map이 로드 안되어 있으면 return
      if (Object.keys(map).length === 0) return

      resultClear()

      const geocoder = new kakao.maps.services.Geocoder()

      //주소 검색 임시 api 세팅
      axios
        .get(`/map/api/searchAddress?query=${value}`)
        .then((response) => {
          const status = response.status
          const result = response.data.documents
          // 정상적으로 검색이 완료됐으면
          if (status === 200 && result) {
            if (result.length > 0) {
              // 카카오 장소 검색
              searchPlaces(value, result)
            } else {
              // 우리 db에 등록된 주소가 없다면 카카오의 '키워드로 장소검색하고 목록으로 표출하기' 라이브러리를 사용합니다.
              searchPlaces(value)
            }
          } else if (status !== 200) {
            message.error(
              '검색 결과 중 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
            )
          }
        })
    },
    [map],
  )

  // @METHOD: 키워드 검색
  const searchPlaces = useCallback(
    (keyword, result = null) => {
      // console.log('searchPlaces: ', keyword)
      let places = new kakao.maps.services.Places()

      if (!keyword.replace(/^\s+|\s+$/g, '')) {
        alert('키워드를 입력해주세요!')
        return false
      }

      //주소 검색 임시 api 세팅
      axios
        .get(`/map/api/searchKeyword?query=${keyword}`)
        .then((response) => {
          const status = response.status
          const data = response.data.documents

          if (status === 200 && data && data.length > 0) {
            if (result) setAddress(result)
            else setPlaces(data)
          } else if (
            status === 200 &&
            data &&
            data.length == 0 &&
            result
          ) {
            //ZERO_RESULT
            // DB 주소 검색
            const latlng = new kakao.maps.LatLng(
              result[0]?.y,
              result[0]?.x,
            )
            // setClickPosition
            getAddress(latlng.getLat(), latlng.getLng()).then(
              (response) => {
                if (
                  response?.data.result &&
                  response.data.result.shape
                ) {
                  // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                  map.setCenter(latlng)

                  // zoom level 확대합니다.
                  map.setLevel(2)

                  const result = response.data.result
                  // const address = getAddressName(result)

                  //setSearchVisible(false)

                  // history 설정
                  //history.push(`/${result.id}`)
                }
              },
            )
            return
          } else if (status !== 200) {
            message.error(
              '검색 결과 중 오류가 발생했습니다. 잠시후 다시 시도해 주세요.',
            )
            return
          }

          //setIsLoading(false)
          //setIsSearched(true)
        })
    },
    [map],
  )

  const onSearch = (e) => {
    if (e.key == 'Enter') {
      searchAddress(e.target.value)
    }
  }

  const resultClear = () => {
    setAddress([])
    setPlaces([])
  }

  /**
   * 장소 클릭 시 해당 토지로 이동
   */
  const onClickItem = useCallback(
    (item) => {
      history.push('/bds')
      clearSearch()
      setVisibleSearch(false)

      map.setCenter(new kakao.maps.LatLng(item?.y, item?.x))
      if (item?.category_name?.includes('아파트')) {
        dispatch(setSearchAptFilter(item))
      } else {
        dispatch(setSearchAptFilter(null))
      }
    },
    [map],
  )

  /**
   * 주소 검색 결과 만들기
   * @param {*} address
   * @param {*} places
   * @returns
   */
  const computedSearchResult = useCallback((address, places) => {
    const transpiledAddress = address.map((item) => {
      const result = {
        title: item.address_name,
        subTitle: item?.road_address?.address_name,
        category_name: item?.category_name,
        place_name: item?.place_name,
        address_name: item?.address_name,
        x: item.x,
        y: item.y,
      }

      return result
    })

    const transpiledPlaces = places.map((item) => {
      const result = {
        title: item.place_name,
        subTitle: item.address_name,
        category_name: item.category_name,
        place_name: item.place_name,
        address_name: item.address_name,
        x: item.x,
        y: item.y,
      }

      return result
    })

    return [...transpiledAddress, ...transpiledPlaces]
  }, [])

  /**
   * 최종 검색 결과
   */
  const searchResults = useMemo(
    () => computedSearchResult(address, places),
    [address, places],
  )

  useEffect(() => {
    if (!searchResults || searchResults.length == 0) {
      setVisibleSearch(false)
      return
    }

    setVisibleSearch(true)
  }, [searchResults])

  return children({
    onSearch,
    searchResults,
    visibleSearch,
    onClickItem,
    ref,
  })
}

export default React.memo(BdsSearchContainer)
