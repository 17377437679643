import { useContext } from 'react'
import styled from 'styled-components'

import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'
import { getComputedArea, getJootekServiceURL } from 'utils/index'
import useIsMobile from 'lib/hooks/useIsMobile'

const StyledListItem = styled.li`
  position: relative;
  flex-basis: 50%;
  max-width: 50%;
  height: auto;
  padding: 0.5rem;
`

const StyledItem = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['card-1']};
  border-radius: ${theme.borderRadius[1]};

  overflow: hidden;
  appearance: none;
  transition: 0.2s ease;

  &:hover {
    color: ${theme.colors.gray[900]};
    box-shadow: ${theme.shadow['card-2']};
    transform: translateY(-4px);
  }
`

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60.6%;

  background-size: cover;
  background-position: center;
`
const PremodelBadge = styled.a`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;

  display: block;
  padding: 0 0.25rem;
  color: ${theme.colors.base.white};
  font-size: 0.75rem;
  font-weight: 700;
  background-color: ${theme.colors.primary[400]};
  border-radius: ${theme.borderRadius[0]};

  &:hover {
    color: ${theme.colors.base.white};
    background-color: ${theme.colors.primary[500]};
  }
`

const ItemBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
`
const ItemBodyDivider = styled.div`
  flex: 1;
`

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 0.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
const Description = styled.div``
const Meta = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
`
const MetaItem = styled.span`
  &:after {
    content: ' · ';
  }
  &:last-child:after {
    content: '';
  }
`

const Author = styled.div`
  margin-bottom: 1rem;
  color: ${theme.colors.gray[700]};
  font-size: 0.75rem;
`
const AuthorProfileImage = styled.div``
const AuthorName = styled.div``

const SummaryProjectItem = ({ item, description }) => {
  const { state } = useContext(MapContext)
  const isMobile = useIsMobile()

  return (
    <StyledListItem>
      {'Y' === item.is_premodel && (
        <PremodelBadge
          href={getJootekServiceURL(
            `/estimate/dispJestimateStart?project_srl=${item.project_srl}`,
          )}
        >
          가견적
        </PremodelBadge>
      )}

      <StyledItem
        href={getJootekServiceURL(`/projects/${item.project_srl}`)}
        target={isMobile ? '_self' : '_blank'}
      >
        <Thumbnail style={{ backgroundImage: `url(${item.src})` }} />

        <ItemBody>
          <Title
            dangerouslySetInnerHTML={{
              __html: item.title ? item.title : '',
            }}
          />

          <Author>
            <AuthorProfileImage
              style={{
                backgroundImage: `url(${item.profile.profile_image})`,
              }}
            />
            <AuthorName>by {item.profile.company_name}</AuthorName>
          </Author>

          <Description>{description}</Description>

          <ItemBodyDivider />

          <Meta>
            <MetaItem>{item.building_type}</MetaItem>
            {item.land_area && (
              <MetaItem>
                {getComputedArea(item.land_area, state.isSquareMeter)}
              </MetaItem>
            )}
          </Meta>
        </ItemBody>
      </StyledItem>
    </StyledListItem>
  )
}

export default SummaryProjectItem
