import { Modal } from 'components/design'
import { numberWithCommas } from 'utils'

/**
 * 결제 취소 완료 모달
 * * 결제일로부터 7일 이내 이면서 등기부등본 사용 내역이 없는 경우 = 결제 취소로 인한 환불 가능
 * @returns
 */
const NotePaymentCancelDone = ({
  visible = false,
  paymentName,
  amount,
  onOk,
  isAllInOne,
}) => {
  return (
    <Modal
      title={
        isAllInOne
          ? '올인원 요금제 해지가 정상 처리되었습니다.'
          : '결제가 취소되었습니다.'
      }
      visible={visible}
      width={450}
      onOk={onOk}
      okButtonProps={{ color: 'blue' }}
      cancelHide
      hiddenClose
      footer={!visible && null}
    >
      {paymentName} 요금 {numberWithCommas(amount)}원이 정상 환불 처리
      되었습니다.
      <br />
      결제한 수단으로 환불되며, 신용카드로 결제한 경우 환불은 영업일
      기준 3~5일 정도 소요됩니다.
      <br />
      <br />
      즉시 프리요금제로 요금제가 자동 전환되며, 남은 등기부등본은 사용이
      제한됩니다.
      <br />
      <br />
      또한 친구 초대 이벤트 링크를 공유한 경우 더이상 이벤트를 통해
      친구를 초대할 수 없으며, 이벤트로 등기열람권을 지급받을 수
      없습니다.
    </Modal>
  )
}

export default NotePaymentCancelDone
