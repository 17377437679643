import { useFormContext } from 'react-hook-form'
import NoteAdFormApt from './NoteAdFormApt'
import NoteAdFormOfficetels from './NoteAdFormOfficetels'
import NoteAdFormPresale from './NoteAdFormPresale'
import NoteAdFormRoom from './NoteAdFormRoom'
import NoteAdFormResidentialBuilding from './NoteAdFormResidentialBuilding'
import NoteAdFormStore from './NoteAdFormStore'
import NoteAdFormBuilding from './NoteAdFormBuilding'
import NoteAdFormLand from './NoteAdFormLand'
import NoteAdFormReBuilding from './NoteAdFormReBuilding'
import NoteAdFormOnlyPropertyType from './NoteAdFormOnlyPropertyType'
import { building, residential, room, store } from './propertyTypes'

/**
 * 광고 폼
 * 매물 종류별 form type
 */
const NoteAdFormType = () => {
  const { watch } = useFormContext()
  const propertyType = watch('article.category1')

  if (!propertyType) return <NoteAdFormOnlyPropertyType />

  if (propertyType == '아파트') {
    return <NoteAdFormApt />
  } else if (propertyType == '아파트 분양권') {
    return <NoteAdFormPresale />
  } else if (propertyType == '오피스텔') {
    return <NoteAdFormOfficetels />
  } else if (propertyType == '오피스텔 분양권') {
    return <NoteAdFormPresale />
  } else if (room.includes(propertyType)) {
    //주거형의 방 또는 일부
    return <NoteAdFormRoom />
  } else if (residential.includes(propertyType)) {
    //주거형의 건물 전체
    return <NoteAdFormResidentialBuilding />
  } else if (store.includes(propertyType)) {
    //사무실, 상가, 아파트형공장
    return <NoteAdFormStore />
  } else if (building.includes(propertyType)) {
    //건물(빌딩,숙박,공장,창고)
    return <NoteAdFormBuilding />
  } else if (propertyType == '토지') {
    //토지
    return <NoteAdFormLand />
  } else if (propertyType == '재개발') {
    //재개발
    return <NoteAdFormReBuilding />
  }
}

export default NoteAdFormType
