import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import { Select } from 'components/design'

import { getFolders } from 'lib/api/note/folderApi'
import { useSelector } from 'react-redux'

const Divider = styled.div`
  flex: 1;
`

const ButtonWrap = styled.div`
  /* margin-bottom: 1rem; */
`

const NoteApplyFolders = ({ channelSrl, folderSrl, onChange }) => {
  const [folders, setFolders] = useState([])
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  useEffect(() => {
    if (!channelSrl || channelSrl === 'undefined') return
    const fetchData = async () => {
      const { data } = await getFolders({
        csrfToken: loginInfo?.csrf_token,
        channelSrl,
      })

      if (!data) {
        setFolders([])
        return
      }

      setFolders(data?.result)
    }

    fetchData()
  }, [channelSrl])

  // const findFolderNameBySrl = (folderSrl, folders) => {
  //   console.log(folderSrl, folders);
  //   return folders?.find((e) => e.folder_srl === folderSrl)?.name;
  // };

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  // const selectedFolder = useMemo(
  //   () => findFolderBySrl(folderSrl, folders),
  //   [folderSrl, folders],
  // );

  return (
    // <Dropdown
    //   trigger={['click']}
    //   getPopupContainer={() => document.getElementById('modal')}
    //   overlay={
    //     <Menu>
    //       {folders?.map((item, i) => (
    //         <Menu.Item
    //           key={i}
    //           onClick={() => onChange(item.folder_srl)}
    //         >
    //           {item?.name}
    //         </Menu.Item>
    //       ))}
    //     </Menu>
    //   }
    // >
    //   <ButtonWrap>
    //     <Button
    //       expand
    //       alignLeft
    //       size="xlarge"
    //       color={folderSrl ? 'primary-light' : null}
    //       onClick={(e) => e.preventDefault()}
    //     >
    //       <span>
    //         {findFolderNameBySrl(folderSrl, folders)?.name ||
    //           '폴더 선택'}
    //       </span>
    //       <Divider />
    //       <i className="fal fa-chevron-down"></i>
    //     </Button>
    //   </ButtonWrap>
    // </Dropdown>
    <>
      <Select expand value={folderSrl} onChange={handleChange}>
        <option value="">폴더 선택</option>
        {folders?.map((item, i) => (
          <option key={i} value={item.folder_srl}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  )
}

export default NoteApplyFolders
