import { media } from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * 매물노트 -대시보드
 * 퀵링크 리스트 아이템
 */
const NoteDashboardQuickLinkListItem = ({ icon, text, src }) => {
  return (
    <Wrapper href={src}>
      <Icon>
        <img src={icon} />
      </Icon>
      <Text>{text}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: calc(20% - 20px);

  ${media.mediumS`
    width: calc(25% - 20px);
  `}
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  img {
    max-width: 50px;
    object-fit: contain;
  }
`

const Text = styled.div`
  text-align: center;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 125%;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export default NoteDashboardQuickLinkListItem
