import styled from 'styled-components'
import AppContainer from './AppContainer'
import theme, { media } from 'lib/styles/theme'

const LandingPriceWarning = () => {
  return (
    <Wrapper>
      <AppContainer>
        <Title>
          <i className="fas fa-info-circle"></i> 유의사항 &#40;필독&#41;
        </Title>
        <List>
          <li>
            베이직 이용권 결제 시, 첫 달 무료 혜택이 제공되며 부동산
            회원 대상입니다.
          </li>
          <li>
            초대받은 친구가 초대 링크를 통해 카드 등록 후 다음 결제일에
            정상 결제 시, 소개한 회원과 초대받은 친구 모두 등기부등본
            발급권 4개를 지급받습니다.
          </li>
          <li>
            첫 달 무료 혜택 기간이 종료된 이후부터는 등록하신 신용카드로
            정상 금액이 결제됩니다.
          </li>
          <li>
            정기 결제를 원치 않으시면 ‘매물노트 &#62; 결제관리’ 메뉴에서
            프리 요금제로 변경하시면 언제든지 정기 결제 해지가
            가능합니다.
          </li>
          <li>
            유효기간 내 사용하지 않은 등기부등본 발급권은 이월 및
            환불되지 않습니다.
          </li>
          <li>
            친구 초대 리워드가 지급된 후에는 소개받은 사용자는
            결제일로부터 30일 이내에 환불되지 않습니다.
          </li>
        </List>
      </AppContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 120px;
  background: rgba(255, 255, 255, 0.7);
`

const Title = styled.div`
  font-size: 22px;
  color: ${theme.colors.tint.danger};
  font-weight: 600;

  ${media.mediumM`
    font-size: 20px;
`}

  ${media.mediumS`
    font-size: 18px;
`}
`

const List = styled.ul`
  margin-top: 12px;
  padding-left: 20px;

  li {
    list-style-type: disc;
    font-weight: 400;
    line-height: 160%;
    font-size: 16px;
    word-break: keep-all;

    ${media.mediumS`
        font-size: 14px;
    `}

    &::marker {
      font-size: 14px;
    }
  }
`

export default LandingPriceWarning
