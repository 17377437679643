import { useEffect, useState } from 'react'
import {
  deunggibuPayWithCard,
  getPaymentInfo,
} from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import theme, { media } from 'lib/styles/theme'
import { numberWithCommas } from 'utils'
import {
  Block,
  ContentTitle,
} from 'components/jmapnotev2/style/NotePaymentStyle'
import { Button } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import styled from 'styled-components'
import useIsAllInOne from 'lib/hooks/notev2/useIsAllInOne'
import NotePaymentDeunggibuPriceSum from './NotePaymentDeunggibuPriceSum'
import { useSelector } from 'react-redux'
import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import { message } from 'antd'
import NotePaymentDeunggibuPaymentModal from './NotePaymentDeunggibuPaymentModal'
import NotePaymentDeunggibuCheckoutModal from './NotePaymentDeunggibuCheckoutModal'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import { useDispatch } from 'react-redux'
import { setRefreshPayments } from 'modules/notev2'

/**
 * 등기부등본 요금제 표
 */
const NotePaymentDeunggibuPriceList = () => {
  const dispatch = useDispatch()
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [amount, setAmount] = useState(0)
  const [count, setCount] = useState(10)
  const [paymentResult, setPaymentResult] = useState(null)
  const [isCheckoutModalVisible, setIsCheckoutModalVisible] = useState()
  const [isPaymentConfirmVisible, setIsPaymentConfirmVisible] =
    useState(false)

  const { isAllInOne, isBoss } = useIsAllInOne()
  const { hasPlan } = useNoteHasPlan()

  const [onRequestPaymentInfo, , dataPaymentInfo] = useAxios(
    getPaymentInfo,
    { resetError: true },
  )

  const [onRequestPayWithCard, , dataPayWithCard] = useAxios(
    deunggibuPayWithCard,
    { resetError: true },
  ) //등록된 카드로 구매
  const { onRequest: refreshUserInfo } = useUserLoader(false)

  useEffect(() => {
    const perPrice = hasPlan ? 800 : 1000

    setAmount(perPrice * 10)
  }, [planInfo])

  const getPrice = (count) => {
    const perPrice = hasPlan ? 800 : 1000
    return count * perPrice
  }

  useEffect(() => {
    if (!dataPaymentInfo) return

    setPaymentResult(dataPaymentInfo?.result)
    setIsCheckoutModalVisible(true)
  }, [dataPaymentInfo])

  /**
   * 결제 확인 모달창
   */
  const onClickPayment = () => {
    setIsPaymentConfirmVisible(true)
  }

  /**
   * 결제하기
   */
  const onOkPayment = () => {
    if (planInfo?.has_billingkey) {
      //등록된 카드 정보 있으면 바로 구매
      onRequestPayWithCard({
        order_name: '등기부등본',
        amount: amount,
        count: count,
        type: '구입',
      })
    } else {
      //토스 결제
      onRequestPaymentInfo({
        order_name: '등기부등본',
        amount: amount,
        count: count,
        type: '구입',
      })
    }
  }

  /**
   * 결제 확인 모달 닫기
   */
  const onClosePaymentModal = () => {
    setIsPaymentConfirmVisible(false)
  }

  /**
   * 결제 모달 닫기
   */
  const onCloseModal = () => {
    setIsCheckoutModalVisible(false)
    setPaymentResult(null)
  }

  /**
   * 요금제 라디오 선택
   * @param {*} count
   * @param {*} amount
   */
  const onChangeRadio = (count, amount) => {
    setCount(count)
    setAmount(amount)
  }

  useEffect(() => {
    if (!dataPayWithCard) return
    if (dataPayWithCard?.error != 0) {
      message.error('등기부등본 구입 중 오류가 발생했습니다.')
      return
    }

    message.success('등기부등본 구입이 완료되었습니다.')
    dispatch(setRefreshPayments(true))
    refreshUserInfo()
    setIsPaymentConfirmVisible(false)
  }, [dataPayWithCard])

  return (
    <Wrapper>
      <ContentTitle>등기부등본 다운로드권 구매하기</ContentTitle>
      <Block>
        <Row gray>
          <RadioButton
            label="등기부등본 5건"
            checked={count == 5}
            name="type"
            onChange={() => onChangeRadio(5, getPrice(5))}
          />
          <Label isactive={count == 5}>
            {numberWithCommas(getPrice(5))}원
          </Label>
        </Row>
        <Row>
          <RadioButton
            label="등기부등본 10건"
            checked={count == 10}
            name="type"
            onChange={() => onChangeRadio(10, getPrice(10))}
          />
          <Label isactive={count == 10}>
            {numberWithCommas(getPrice(10))}원
          </Label>
        </Row>
        <Row gray>
          <RadioButton
            label="등기부등본 20건"
            checked={count == 20}
            name="type"
            onChange={() => onChangeRadio(20, getPrice(20))}
          />
          <Label isactive={count == 20}>
            {numberWithCommas(getPrice(20))}원
          </Label>
        </Row>
        <Row>
          <RadioButton
            label="등기부등본 50건"
            checked={count == 50}
            name="type"
            onChange={() => onChangeRadio(50, getPrice(50))}
          />
          <Label isactive={count == 50}>
            {numberWithCommas(getPrice(50))}원
          </Label>
        </Row>
        <Row gray>
          <RadioButton
            label="등기부등본 100건"
            checked={count == 100}
            name="type"
            onChange={() => onChangeRadio(100, getPrice(100))}
          />
          <Label isactive={count == 100}>
            {numberWithCommas(getPrice(100))}원
          </Label>
        </Row>
        {isBoss && (
          <NotePaymentDeunggibuPriceSum deunggibuPrice={amount} />
        )}
        <ButtonWrapper>
          <Button color="black" onClick={onClickPayment}>
            결제하기
          </Button>
        </ButtonWrapper>
      </Block>
      {/* 결제 전 확인 모달 */}
      <NotePaymentDeunggibuPaymentModal
        amount={amount}
        count={count}
        visible={isPaymentConfirmVisible}
        onCancel={onClosePaymentModal}
        onOk={onOkPayment}
      />
      {/* 토스 결제 모달 */}
      <NotePaymentDeunggibuCheckoutModal
        paymentResult={paymentResult}
        amount={amount}
        count={count}
        visible={isCheckoutModalVisible}
        onCancel={onCloseModal}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${media.mediumM`
    width: 100%;
    margin-top: 16px;
  `}
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;

  ${({ gray }) =>
    gray &&
    `
        background-color: ${theme.colors.gray[50]};
        border-top: 1px solid ${theme.colors.gray[100]};
        border-bottom: 1px solid ${theme.colors.gray[100]};
    `}
`

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 20px;
`

const Label = styled.span`
  font-weight: 400;
  color: ${theme.colors.gray[600]};
  ${({ isactive }) =>
    isactive &&
    `
    color: ${theme.colors.blue[400]};
    font-weight: 700;
`}
`

export default NotePaymentDeunggibuPriceList
