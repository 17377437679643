import useIsMobile from 'lib/hooks/useIsMobile'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import theme from 'lib/styles/theme'
import { getGridFontSize } from 'lib/utils/notev2/gridUtil'
import { setGridFontSize } from 'modules/notev2'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

/**
 * 매물노트 그리드 폰트 사이즈 조절
 */
const NoteGridFontSizeController = ({ endpos }) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const gridFontSize = useSelector((state) => state.notev2.gridFontSize)

  const ref = useRef()

  const [isMobilePopVisible, setIsMobilePopVisible] = useState(false)

  useOnClickOutside(ref, (e) => {
    setIsMobilePopVisible(false)
  })
  const onClick = (value) => {
    dispatch(setGridFontSize(value))
    localStorage.setItem('noteGridFontSize', value)

    if (isMobile) {
      setIsMobilePopVisible(false)
    }
  }

  const onClickMobile = () => {
    setIsMobilePopVisible(true)
  }

  return isMobile ? (
    <MobileWrapper>
      <Item
        ismobile={isMobile}
        size={getGridFontSize('m')}
        onClick={onClickMobile}
      >
        가
      </Item>
      {isMobilePopVisible && (
        <MobilePop endpos={endpos ? 'Y' : 'N'} ref={ref}>
          <Background>
            <Item
              active={gridFontSize == 's'}
              size={getGridFontSize('s')}
              onClick={() => onClick('s')}
            >
              가
            </Item>
            <Item
              active={gridFontSize == 'm'}
              size={getGridFontSize('m')}
              onClick={() => onClick('m')}
            >
              가
            </Item>
            <Item
              active={gridFontSize == 'l'}
              size={getGridFontSize('l')}
              onClick={() => onClick('l')}
            >
              가
            </Item>
          </Background>
        </MobilePop>
      )}
    </MobileWrapper>
  ) : (
    <Background>
      <Item
        active={gridFontSize == 's'}
        size={getGridFontSize('s')}
        onClick={() => onClick('s')}
      >
        가
      </Item>
      <Item
        active={gridFontSize == 'm'}
        size={getGridFontSize('m')}
        onClick={() => onClick('m')}
      >
        가
      </Item>
      <Item
        active={gridFontSize == 'l'}
        size={getGridFontSize('l')}
        onClick={() => onClick('l')}
      >
        가
      </Item>
    </Background>
  )
}

const MobileWrapper = styled.div`
  position: relative;
`

const MobilePop = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 1;
  transform: translate(-50%);
  box-shadow: ${theme.shadow['button-1']};

  ${({ endpos }) =>
    endpos == 'Y' &&
    `
    left: initial;
    transform: none;
    right: 0;
  `};
`

const Background = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 28px;
  padding: 6px;
  outline: none;
  border-radius: 6px;
  border: none;
  background-color: ${theme.colors.gray[100]};
  box-sizing: border-box;
  overflow: hidden;
`

const Item = styled.div`
  text-align: center;
  font-size: ${({ size }) => `${size + 1}px`};
  font-weight: 500;
  padding: 4px 5px;
  line-height: 100%;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
        color: ${theme.colors.blue[400]};
        box-shadow: ${theme.shadow['button-1']};
        background-color: ${theme.colors.base.white};
        border-radius: 4px;
    `};

  ${({ ismobile }) =>
    ismobile &&
    `
      color: ${theme.colors.gray[600]};
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    `};
`

export default NoteGridFontSizeController
