import { feeOptions } from 'components/jmapnotev2/NoteAd/optionsFee'
import { Sum } from './BdsDetailFeeModalType1'
import styled from 'styled-components'

/**
 * 주택부동산 상세
 * 관리비 모달
 * 확인불가
 */
const BdsDetailFeeModalType3 = ({ data }) => {
  const options = feeOptions['확인불가세부내역']

  return (
    <>
      <Title>{options[data?.unableCheckDetails.detailCodeType]}</Title>
    </>
  )
}

const Title = styled(Sum)`
  font-weight: 700;
  justify-content: center;
`

export default BdsDetailFeeModalType3
