import { message } from 'antd'
import axios from 'axios'
import {
  ListItemDesc,
  ListItemDivider,
  ListItemLabel,
  Tag,
} from 'components/design'
import NoteUploadExcelInput from 'components/jmapnote/noteList/NoteUploadExcelInput'
import { useRef, useState } from 'react'
import styled from 'styled-components'

const NoteCustomerFile = () => {
  const [fileName, setFileName] = useState('')

  const inputRef = useRef(null)

  const onChangeFile = (files) => {
    if (!files || files.length === 0) return

    // 파라미터 세팅
    const formData = new FormData()
    formData.append('fileToUpload', files?.[0])

    // API 요청
    axios
      .post('/jmapnote/api/contact/upload', formData)
      .then((resp) => {
        if (resp.data.error) {
          message.error(resp.data.message)
          return
        }

        // console.log('resp: ', resp)
        if (resp.data?.result) {
          // TODO: 고객관리 목록 업데이트
          message.info('연락처가 등록되었습니다.')
        }
        if (!resp.data) {
          message.info('데이터가 없습니다.')
        }
      })
      .catch((error) => {
        console.log(error)
        message.error('서버 오류')
      })
    setFileName('')
  }

  return (
    <Wrapper>
      <ListItemLabel>연락처 파일 업로드</ListItemLabel>
      <ListItemDesc>
        파일에 저장된 연락처를 대량으로 생성합니다.
      </ListItemDesc>
      <Tag>.vcf 파일만 가능</Tag>

      <ListItemDivider xs />
      <NoteUploadExcelInput
        fileName={fileName}
        setFileName={setFileName}
        onChange={onChangeFile}
        inputRef={inputRef}
        accept={'.vcf'}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 40px 0;
`

export default NoteCustomerFile
