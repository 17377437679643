import { rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'
import theme from './theme'
import { media } from './theme'
import palette from './palette'
import gongbuImage from 'assets/images/features/gongbuImage.png'
import mobileGongbuImage from 'assets/images/features/mobilegongbuImage.png'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  table,
  input,
  textarea,
  select,
  button {
    font-family: ${theme.font.family};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }


  html,
  body {
    padding: 0;
    margin: 0;
    min-height: 100%;
    // overflow-x: hidden;

    // @INFO: 모든 스크롤은 Container 컴포넌트의 자체 스크롤을
    //        사용하기 때문에 모바일에서의 ScrollBehavior 문제를
    //        막기 위해 스크롤을 막습니다.
    &.core {
      overflow: hidden;
    }
    line-height: 1.5715;
    font-feature-settings: 'tnum', "tnum";
    color: ${theme.colors.gray[900]};
    font-size: 1rem;
    font-variant: inherit;

    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color: ${theme.colors.gray[900]};
  }

  .core #root {
    min-height: 100%;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  button {
    border: 0;
    outline: 0;
  }
  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }

  figure{
    margin-bottom: 0;
  }

  a {
    color: ${theme.colors.gray[900]}; 
    text-decoration: none; 
    outline: none
  }

  a:hover, a:active {
    text-decoration: none;
  }

  a:hover {
    color: ${theme.colors.primary[400]};
  }

  .custom-scroll {
    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${rgba(theme.colors.gray[400], 0.5)};
      border-radius: 20px;
      border: 3px solid transparent;
      background-clip: padding-box;
      transition: 250ms;
    }

    &--hover {
      background-clip: padding-box;
      transition: background-color 0.4s;

      &:hover {
        background-color: ${rgba(theme.colors.gray[400], 0.5)};
      }
      &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }
      &::-webkit-scrollbar-track {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: inherit;
      }
    }
  }
  

  figure {
    margin: 0;
  }

  #modal {
    position: relative;
    z-index: 1000;
  }

  #modal-dialog {
    position: relative;
    z-index: 13;
  }


  #roadview-root {
    -webkit-overflow-scrolling: touch;
  }

  .pswp--click-to-zoom {
    &:not(&.pswp--zoom-allowed) {
      .pswp__img {
        cursor: default !important;
      }
    }
  }

  /**
    photoswipe
  */
  .pswp {
    &.pswp-note-picture {
      width: calc(100% - 400px);
      font-size: 16px;
      box-sizing: border-box;
      height: 100%;
      left: 0;
      top: 0;
      overflow: visible;
      touch-action: none;
      z-index: 1500;
      outline: none;
      display: block;
      position: fixed;
      opacity: 1;
      
      .pswp__img {
        left: -200px;
      }
      
      ${media.smallPc`
        width: 100%;
        .pswp__img{
          left: 0px;
        }
      `}
    }

    .pswp__img {
      object-fit: contain;
    }

  }
  .pswp__sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    margin-right: -400px;
    height: 100%;
    background: #191F28;
    padding: 20px;
    color: #ffffff;
    line-height: 150%;
    overflow-y: auto;

    &.pswp__hide-on-close
    {
      opacity: 1;
      pointer-events: auto;
    }

    &-subtitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 180%;
      color: ${theme.colors.gray[600]};
      margin-bottom: 4px;
      
      ${media.mediumS`
        font-size: 12px;
      `}
    }

    &-title {
      font-size: 18px;
      line-height: 170%;
      font-weight: 700;
      margin-bottom: 10px;
      color: ${theme.colors.base.white};
      cursor: pointer;

      ${media.mediumS`
        font-size: 16px;
      `}
      &:hover{
        color: ${theme.colors.blue[400]};
      }

      &::after {
        content: '\f105';
        margin-left: 8px;
        font-family: 'Font Awesome 5 Pro';
      }
    }
    
    &-memo {
      font-size: 14px;
      font-weight: 400;
      line-height: 180%;
      color: ${theme.colors.gray[600]};
    }

    ${media.smallPc`
      position: fixed;
      width: 100%;
      left: 0px;
      height: 150px;
      bottom: 0;
      top: initial;
    `}
  }

  .pswp__scroll-wrap{
    position:relative;
  }


  .ant-dropdown-menu {
    font-family: ${theme.font.family} !important;
    .ant-dropdown-menu-title-content{
      font-size: 14px;
    }
  }

  .ant-message {
    span {
      font-family: ${theme.font.family} !important;
      font-weight: 500;
    }
  }


//onboardingTooltip



body .contract-tooltip{
  top:75px !important;
  left:240px !important;
  width:298px;
  height:129px;
  background:${palette.colors.base.white};
  border-radius:14px;
  ${media.smallPc`
  left: 65px !important;
`}
}

body .contract-tooltip .introjs-tooltip-header{
  display:none;
}

body .contract-tooltip .introjs-arrow{
  top:-18px !important;
  border-bottom-color:${
    palette.colors.base.white
  };  left:260px !important;
  border-width:10px;
  ${media.smallPc`
  left: 260px !important;
`}
}

body .contract-tooltip .introjs-tooltiptext{
  font-family:Pretendard;
  padding:24px 20px 0 20px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  color:${palette.colors.gray[900]};
}

body .contract-tooltip .introjs-tooltipbuttons{
  border:none;
  padding:16px 24px 10px 24px;
}

  body .contract-tooltip .introjs-tooltipbuttons a{
    width:250px;
    height:42px;
    font-weight:700;
    color:${palette.colors.base.white};
    background:#20cfe7 !important;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:0;
    text-shadow:none !important;
  }
  

body .contract-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border:none !important;
  border-radius:8px;
}

body .contract-tooltip .introjs-skipbutton{
  display:none !important;
}

body .contract-tooltip .introjs-prevbutton{
  display:none !important;
}

body .contract-tab-hightlight-class{
  border: 6px solid #00F8DA !important;
}



body .full-information-tooltip{
  top: 85px !important;
  width:298px;
  height:129px;
  background:${palette.colors.base.white};
  border-radius:12px;
  left:465px !important;
  ${media.mediumS`
  left:12px !important;
  `}
}

body .full-information-tooltip .introjs-tooltip-header{
  display:none;
}

body .full-information-tooltip .introjs-arrow{
  border-width:10px;
  top:-19px !important;
  border-bottom-color:${palette.colors.base.white};
  left:20px !important;
  ${media.mediumS`
    left:200px !important;
  `}
}

body .full-information-tooltip .introjs-tooltiptext{
  font-family:Pretendard;
  padding:24px 20px 0 20px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  color:${palette.colors.gray[900]};
}

body .full-information-tooltip .introjs-tooltiptext .color{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:#20cfe7;
}

body .full-information-tooltip .introjs-tooltipbuttons{
  border:0px;
  padding:16px 24px 10px 24px;
}

body .full-information-tooltip .introjs-tooltipbuttons a{
  width:250px;
  height:42px;
  font-weight:700;
  color:${palette.colors.base.white};
  background:#20cfe7 !important;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  text-shadow:none !important;
}

body .full-information-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border:none !important;
  border-radius:8px;
}

body .full-information-tooltip .introjs-skipbutton{
  display:none !important;
}

body .full-information-tooltip .introjs-prevbutton{
  display:none !important;
}




body .gongbu-tooltip{
  top:75px !important;
  left:100px !important;
  width:298px;
  height:216px;
  background:${palette.colors.base.white};
    border-radius:14px;
${media.mediumS`
  left:45px !important;
`}
}

body .gongbu-tooltip .introjs-tooltip-header{
  padding:20px 24px 0 24px !important;
}

body .gongbu-tooltip .introjs-tooltip-title{
  width:250px;
  height:62px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  font-family:Pretendard;
  padding:0 35px 0 35px;
}

body .gongbu-tooltip .introjs-arrow{
  border-width:10px;
  border-bottom-color:${palette.colors.base.white};  
  left:260px !important;
  top:-18px !important;
  ${media.mediumS`
  border-bottom-color:${palette.colors.base.white};
  left:190px !important;
`}
}

body .gongbu-tooltip .introjs-tooltiptext{
  width:250px;
  height:40px;
  font-family:Pretendard;
  padding:16px 24px 0 24px;
  font-size:14px;
  font-weight:600;
  color:${palette.colors.gray[900]};
}

body .gongbu-tooltip .introjs-tooltiptext .color{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:#20cfe7;
}

body .gongbu-tooltip .introjs-tooltipbuttons{
  border:none;
  padding:26px 24px 10px 24px;
}

body .gongbu-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border-radius:8px;
  border:none !important;
}

body .gongbu-tooltip .introjs-tooltipbuttons a{
  width:250px;
  height:42px;
  font-weight:700;
  color:${palette.colors.base.white};
  background:#20cfe7 !important;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  text-shadow:none !important;
}

body .gongbu-tooltip .introjs-skipbutton{
  display:none !important;
}

body .gongbu-tooltip .introjs-prevbutton{
 display:none !important;
}



body .introjs-fixedTooltip{
  border-radius:12px;
  box-shadow:transparent 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
  border: 1px solid black;
  background-image: linear-gradient(to right, #00d8f8, #5a87ff), linear-gradient(0, 0);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

body .threedepth-hightlight-class{
  height:650px !important;
  top:300px !important;
  border: 6px solid #00F8DA;
  ${media.mediumS`
    top:290px !important;
  `}
}

body .first-highlightClass{
  border-radius:0;
  border:none;
  height:48px !important;
}

body .address-highlightClass{
  border-radius:0;
  border:none;
}

body .open-highlightClass{
  border-radius:0;
  border:none;
  ${media.mediumS`
  height:50px !important;
  `}
}

body .full-information-highlightClass{
  height:69px !important;
  border-radius:0;
  border:none;
${media.mediumS`
  height:50px !important;
  left:182px !important;
  width:400px !important;
`}
}

body .price-highlightClass{
  height:68px !important;
  left:944px !important;
  border-radius:0;
  border:none;
${media.mediumS`
  left:183px !important;
  width:210px !important;
`}

}

body .price-tooltip{
  top: 85px !important;
  width:298px;
  height:129px;
  background:${palette.colors.base.white};
  border-radius:12px;
  ${media.mediumS`
  left:-260px !important;
  `}
}

body .price-tooltip .introjs-tooltip-header{
  display:none;
}

body .price-tooltip .introjs-arrow{
  border-width:10px;
  top:-19px !important;
  border-bottom-color:${palette.colors.base.white};
  left:20px !important;
  ${media.mediumS`
  left:230px !important;
  width:10px !important;
  `}
}

body .price-tooltip .introjs-tooltiptext{
  font-family:Pretendard;
  padding:24px 20px 0 20px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  color:${palette.colors.gray[900]};
}

body .price-tooltip .introjs-tooltiptext .color{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:#20cfe7;
}

body .price-tooltip .introjs-tooltipbuttons{
  border:none;
  padding:16px 24px 10px 24px;
}

body .price-tooltip .introjs-tooltipbuttons a{
  width:250px;
  height:42px;
  font-weight:700;
  color:${palette.colors.base.white};
  background:#20cfe7 !important;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  text-shadow:none !important;
}


body .price-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border:none !important;
  border-radius:8px;
}

body .price-tooltip .introjs-skipbutton{
  display:none !important;
}

body .price-tooltip .introjs-prevbutton{
  display:none !important;
}



body .open-button-tooltip{
  top: 85px !important;
  left:205px !important;
  font-size:16px;
  width:298px;
  height:185px;
  background:${palette.colors.base.white};
  border-radius:12px;
  ${media.mediumS`
  left:12px !important;
  `}
}

body .open-button-tooltip .introjs-tooltip-header{
  padding:20px 24px 0 24px !important;
}

body .open-button-tooltip .introjs-tooltip-title{
  width:250px;
  height:31px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  font-family:Pretendard;
  padding:0;
}

body .open-button-tooltip .introjs-arrow{
  border-width:10px;
  top:-19px !important;
  border-bottom-color:${palette.colors.base.white};
  left:120px !important;
}

body .open-button-tooltip .introjs-tooltiptext{
  font-family:Pretendard;
  padding:16px 20px 0 20px;
  font-size:14px;
  font-weight:600;
  color:${palette.colors.gray[900]};
}

body .open-button-tooltip .introjs-tooltiptext .color{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:#20cfe7;
}

body .open-button-tooltip .introjs-tooltipbuttons{
  border:0px;
  padding:16px 24px 10px 24px;
}

body .open-button-tooltip .introjs-tooltipbuttons a{
  width:250px;
  height:42px;
  font-weight:700;
  color:${palette.colors.base.white};
  background:#20cfe7 !important;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  text-shadow:none !important;
}

body .open-button-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border:none !important;
  border-radius:8px;
}

body .open-button-tooltip .introjs-skipbutton{
  display:none !important;
}

body .open-button-tooltip .introjs-prevbutton{
  display:none !important;
}

body .first-tooltip{
  top: 70px !important;
  font-size:16px;
  width:298px;
  height:185px;
  background:${palette.colors.base.white};
  border-radius:12px;
  ${media.mediumS`
  left:12px !important;
  `}
}

body .first-tooltip .introjs-tooltip-header{
  padding:20px 24px 0 24px !important;
}

body .first-tooltip .introjs-tooltip-title{
  width:250px;
  height:31px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  font-family:Pretendard;
  padding:0;
}

body .first-tooltip .introjs-arrow{
  border-width:10px;
  border-bottom-color:${palette.colors.base.white};
  top:-19px !important;
  left:20px !important;
}

body .first-tooltip .introjs-tooltiptext{
  font-family:Pretendard;
  padding:16px 20px 0 20px;
  font-size:14px;
  font-weight:600;
  color:${palette.colors.gray[900]};
}

body .first-tooltip .introjs-tooltiptext .color{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:#20cfe7;
}

body .first-tooltip .introjs-tooltipbuttons{
  border:0px;
  padding:16px 24px 10px 24px;
}

body .first-tooltip .introjs-tooltipbuttons a{
  width:250px;
  height:42px;
  font-weight:700;
  color:${palette.colors.base.white};
  background:#20cfe7 !important;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  text-shadow:none !important;
}

body .first-tooltip .introjs-tooltipbuttons .introjs-button{
  outline:none !important;
  border:0px solid gray !important;
}

body .first-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border:none !important;
  border-radius:8px;
}

body .first-tooltip .introjs-skipbutton{
  display:none !important;
}

body .first-tooltip .introjs-prevbutton{
  opacity:0;
}

body .address-tooltip{
  top: 70px !important;
  width:298px;
  height:129px;
  background:${palette.colors.base.white};
  border-radius:12px;
  ${media.mediumS`
  left:12px !important;
  `}
}

body .address-tooltip .introjs-tooltip-header{
  display:none;
}

body .address-tooltip .introjs-arrow{
  border-width:10px;
  top:-19px !important;
  border-bottom-color:${palette.colors.base.white};
  left:20px !important;
}

body .address-tooltip .introjs-tooltiptext{
  font-family:Pretendard;
  padding:24px 20px 0 20px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  color:${palette.colors.gray[900]};
}

body .address-tooltip .introjs-tooltiptext .color{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:#20cfe7;
}

body .address-tooltip .introjs-tooltipbuttons{
  border:0px;
  padding:16px 24px 10px 24px;
}
body .address-tooltip .introjs-tooltipbuttons a{
  width:250px;
  height:42px;
  font-weight:700;
  color:${palette.colors.base.white};
  background:#20cfe7 !important;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  text-shadow:none !important;
}


body .address-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border:none !important;
  border-radius:8px;
}

body .address-tooltip .introjs-skipbutton{
  display:none !important;
}

body .address-tooltip .introjs-prevbutton{
  display:none !important;
}

body .sheet-last-tooltip{
  top:0px !important;
  left:-320px !important;
  font-size:16px;
  width:298px;
  height:242px;
  background:${theme.colors.base.white};
  border-radius:12px;
  ${media.mediumS`
  left:20px !important;
  top:120px !important;
`}
}

body .sheet-last-tooltip .introjs-tooltip-title{
  width:250px;
  height:62px;
  font-size:18px;
  font-family:Pretendard;
  font-weight:700;
  margin-top:10px;
  text-align:center;
}

body .sheet-last-tooltip .introjs-arrow{
  border-width:10px;
  border-bottom-color:transparent;
  border-left-color:${theme.colors.base.white};
  top:30px !important;
  left:298px !important;
  ${media.mediumS`
  border-bottom-color:${theme.colors.base.white};
  border-left-color:transparent;
  left:250px !important;
  top:-20px !important;
`}
}

body .sheet-last-tooltip .introjs-tooltiptext .color{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:#20cfe7;
}

body .sheet-last-tooltip .introjs-tooltiptext{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:${palette.colors.gray[900]};
  padding:0 20px 0 20px;
  margin-top:16px;
}

body .sheet-last-tooltip .introjs-tooltiptext .last-button{
  font-size:14px;
  font-family:Pretendard;
  color: ${palette.colors.gray[700]} !important;
  font-weight:700;
  border-radius:8px;
  border:none;
  background-color: ${palette.colors.gray[100]} !important;
  width:121px;
  height:42px;
  position:absolute;
  top:180px;
  left:24px;
  cursor:pointer;
}

body .sheet-last-tooltip .introjs-tooltipbuttons{
  border:0px;
  margin-top:6px;
  padding:10px 24px 10px 24px;
}

body .sheet-last-tooltip .introjs-tooltipbuttons a{
  width:121px;
  height:42px;
  padding:0;
  display:flex;
  justify-content:center;
  align-items:center;
  text-shadow:none !important;
}

body .sheet-last-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:700;
  border-radius:8px;
  border:none;
  background-color: #20cfe7 !important;
  color: ${palette.colors.base.white} !important;
  font-size:14px !important;
}

body .sheet-last-tooltip .introjs-skipbutton{
  font-family:Pretendard;
  color: transparent !important;
  font-weight:700;
  border-radius:8px;
  border:none;
  background-color: transparent !important;
  width:121px;
  height:42px;
  position:absolute;
  top:180px;
  left:24px;
  cursor:pointer;
  z-index:100;
}

body .sheet-last-tooltip .introjs-prevbutton{
  font-size:14px;
  font-family:Pretendard;
  color: ${palette.colors.gray[700]} !important;
  font-weight:700;
  border-radius:8px;
  border:none;
  background-color: ${palette.colors.gray[100]} !important;
}

body .sheet-last-tooltip .introjs-tooltipbuttons .introjs-prevbutton{
  cursor:pointer;
}

body .sheet-last-hightlight-class{
  border: 6px solid #00F8DA;
}


body .threedepth-tooltip{
  top:60px !important;
  left:-320px !important;
  font-size:16px;
  width:298px;
  height:185px;
  background:${palette.colors.base.white};
  border-radius:12px;
  ${media.mediumS`
    left:20px !important;
    top:-130px !important;
    `}
}
body .threedepth-tooltip .introjs-tooltip-header{
  padding:20px 24px 0 24px !important;
}

body .threedepth-tooltip .introjs-tooltip-title{
  width:250px;
  height:31px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  font-family:Pretendard;
  padding:0;
}

body .threedepth-tooltip .introjs-arrow{
  height:0 !important;
  border-width:10px;
  border-top-color:transparent;
  border-bottom-color:transparent;
  border-left-color:${palette.colors.base.white};
  top:30px !important;
  left:298px !important;
  ${media.mediumS`
  left:200px !important;
  top:185px !important;
  border-left-color:transparent;
  border-top-color:${palette.colors.base.white};
`}
}

body .threedepth-tooltip .introjs-tooltiptext{
  font-family:Pretendard;
  padding:16px 24px 0 24px;
  width:250px !important;
  height:40px !important;
  font-size:14px;
  font-weight:600;
  color:${palette.colors.gray[900]};
}

body .threedepth-tooltip .introjs-tooltipbuttons{
  border:0px;
  padding:26px 24px 10px 24px;
}

body .threedepth-tooltip .introjs-tooltipbuttons a{
  width:250px;
  height:42px;
  font-weight:700;
  color:${palette.colors.base.white};
  background:#20cfe7 !important;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  text-shadow:none !important;
}

body .threedepth-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border:none !important;
  border-radius:8px;
}

body .threedepth-tooltip .introjs-skipbutton{
  display:none !important;
}

body .threedepth-tooltip .introjs-prevbutton{
  display:none !important;
}



body .gongbu-next-tooltip{
  left:-320px !important;
  top:60px !important;
  width:298px;
  height:216px;
  background:${palette.colors.base.white};
  border-radius:14px;
  ${media.mediumS`
  left:30px !important;
  top:-230px !important;
`}
}

body .gongbu-next-tooltip .introjs-tooltip-header{
  padding:20px 24px 0 24px !important;
}

body .gongbu-next-tooltip .introjs-tooltip-title{
  width:250px;
  height:62px;
  text-align:center;
  font-size:18px;
  font-weight:700;
  font-family:Pretendard;
  padding:0 5px 0 5px;
}

body .gongbu-next-tooltip .introjs-arrow{
  border-width:10px;
  border-bottom-color:transparent;
  border-top-color:transparent;
  height:0 !important;
  border-left-color:${palette.colors.base.white};
  top:30px !important;
  left:298px !important;
  ${media.mediumS`
  border-bottom-color:transparent;
  border-left-color:transparent;
  border-top-color:${palette.colors.base.white};
  left:140px !important;
  top:215px !important;
`}
}

body .gongbu-next-tooltip .introjs-tooltiptext{
  width:300px;
  height:40px;
  font-family:Pretendard;
  padding:16px 24px 0 24px;
  font-size:14px;
  font-weight:600;
  color:${palette.colors.gray[900]};
}

body .gongbu-next-tooltip .introjs-tooltiptext .color{
  font-family:Pretendard;
  font-size:14px;
  font-weight:600;
  color:#20cfe7;
}

body .gongbu-next-tooltip .introjs-tooltipbuttons{
  border:none;
  padding:30px 24px 10px 24px;
}

body .gongbu-next-tooltip .introjs-nextbutton{
  font-family:Pretendard;
  font-weight:600;
  border-radius:8px;
  border:none !important;
}

body .gongbu-next-tooltip .introjs-tooltipbuttons a{
  width:250px;
  height:42px;
  font-weight:700;
  color:${palette.colors.base.white};
  background:#20cfe7 !important;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  text-shadow:none !important;
}


body .gongbu-next-tooltip .introjs-skipbutton{
  display:none !important;
}

body .gongbu-next-tooltip .introjs-prevbutton{
  display:none !important;
}

body .gongbu-next-hightlight-class{
  border: 6px solid #00F8DA;
  background: url(${gongbuImage}) no-repeat center;
  width:525px !important;
  height:475px !important;
  ${media.mediumS`
  background: url(${mobileGongbuImage}) no-repeat center;
  width:330px !important;
  height:400px !important;
  `}
}

body .gongbu-hightlight-class{
  border: 6px solid #00F8DA;
}

body .introjs-tooltip .introjs-tooltipbuttons .customButton{
  cursor:pointer;
}

.blink {
    &-circle {
      background: ${theme.colors.primary[500]}4D;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      animation: zoom 1.8s linear infinite;
      z-index: 99;

      &--2 {
        animation-delay: 1.25s;
        z-index: 100;
      }

      &--3 {
        animation-delay: 1.25s;
        z-index: 101;
      }
    }

    &-center {
      position: relative;
      z-index: 1000;
    }
  }
  @keyframes zoom {
    0% {
      transform: translate(-50%, -50%) scale(0.2);
      opacity: 0;
    }
    20% {
      opacity: 0.9;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }

  .ant-custom-zoom-noti {
    .ant-message-notice-content {
      background-color: ${theme.colors.base.black} !important;
      opacity: 0.7;
    }

    .ant-message-custom-content.ant-message-info {
      color: ${theme.colors.base.white} !important;
    }
  }


`

export default GlobalStyle
