import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const Wrap = styled.div`
  margin-bottom: 2rem;

  ${({ padding }) =>
    padding &&
    `
    padding-left: ${padding};
    padding-right: ${padding};
  `}

  ${({ noMargin }) =>
    noMargin &&
    `
    margin-bottom: 0;
  `}
`
const Title = styled.div`
  color: ${theme.colors.gray[500]};
  font-size: 0.75rem;
  font-weight: 700;

  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  margin-left: 1.25rem;

  ${({ padding }) =>
    padding &&
    `
    margin-left: 0;
  `}
  ${({ noContainer }) =>
    noContainer &&
    `
    margin-left: 0;
  `}
`

const StyledList = styled.ul`
  /* display: flex; */
  padding: 0;
  /* flex-direction: column; */

  ${({ grid }) =>
    grid &&
    `
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 1rem 1.25rem 0;
    margin: -0.5rem;
  `}
`

const List = ({
  title,
  children,
  grid = false,
  padding,
  noMargin = false,
  noContainer = false,
  as,
}) => {
  return (
    <Wrap as={as} padding={padding} noMargin={noMargin}>
      {title && (
        <Title noContainer={noContainer} padding={padding}>
          {title}
        </Title>
      )}

      <StyledList grid={grid}>{children}</StyledList>
    </Wrap>
  )
}

List.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  grid: PropTypes.bool,
  noMargin: PropTypes.bool,
  noContainer: PropTypes.bool,
}

export default List
