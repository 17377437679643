import { useState, useCallback } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'
import RealPriceTableContainer from 'components/summary/SummaryChart/RealPriceTableContainer'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const SummaryRealPurchase = ({ match }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { building } = useSelector((state) => state.summary.summaryInfo)

  const history = useHistory()
  const params = useParams()

  // 실거래가 external 컴포넌트를 렌더링한 상태에서, 공동주택이 아닌 필지를 클릭할 경우에 nearby로 주소 바꿈
  useEffect(() => {
    if (building?.result?.[0]?.purpose_code_name !== '공동주택') {
      history.replace(`/${params?.id}/nearby`)
    }
  }, [building])

  const onClickClose = useCallback(() => {
    history.push(`/${params?.id}`)
  }, [])

  return (
    <ExternalLayer
      title="실거래가"
      onClose={onClickClose}
      withCard={isLoading}
      padding
    >
      <RealPriceTableContainer />
    </ExternalLayer>
  )
}

export default SummaryRealPurchase
