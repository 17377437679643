import styled from 'styled-components'
import AppContainer from './AppContainer'
import {
  SectionWrapper,
  SubTitle,
  Title,
  WindowBar,
  WindowWrapper,
} from './LandingStyle'
import ContractLogoSrc from 'assets/images/note/landing/contract-logo.png'
import MacBarImgSrc from 'assets/images/note/landing/macbar.png'
import { media } from 'lib/styles/theme'

const LandingContract = () => {
  return (
    <SectionWrapper>
      <AppContainer>
        <TitleWrapper>
          <LogoImg src={ContractLogoSrc} />
          <Title>
            계약서, 확인설명서?
            <br />
            이제 1분이면 작성 끝.
          </Title>
          <SubTitle>
            네 놀라셔도 됩니다
            <br />
            저희도 만들고 나서 놀랬어요
          </SubTitle>
        </TitleWrapper>
        <StyledContent>
          <WindowWrapper>
            <WindowBar>
              <img src={MacBarImgSrc} />
            </WindowBar>
            <video
              src="https://assets.jootek.com/jmapnote/auto-fill.720p.mp4"
              alt="계약서 영상"
              playsInline
              muted
              autoPlay
              loop
            />
          </WindowWrapper>
        </StyledContent>
      </AppContainer>
    </SectionWrapper>
  )
}

const LogoImg = styled.img`
  max-width: 142px;
  height: 54px;
  object-fit: contain;

  ${media.mediumS`
    width: 90px;
    height: auto;           
  `}
`

const StyledContent = styled.div`
  margin-top: 80px;

  ${media.mediumM`
    margin-top: 60px;
  `}

  ${media.mediumS`
    margin-top: 40px;
  `}
`

const TitleWrapper = styled.div`
  ${media.mediumS`
    text-align: center;
  `}
`

export default LandingContract
