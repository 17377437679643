import { Skeleton } from 'antd'
import styled from 'styled-components'

const NoteDonghoSkeleton = () => {
  return (
    <SkeletonWrapper>
      <Skeleton active />
    </SkeletonWrapper>
  )
}
const SkeletonWrapper = styled.div`
  padding: 40px 45px;
`

export default NoteDonghoSkeleton
