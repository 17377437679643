import styled from 'styled-components'
import {
  MainWrapperStyle,
  SwipeableLeftStyle,
  SwipeableMainWrapper,
} from './NoteStyle'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import theme, { media } from 'lib/styles/theme'

/**
 * 매물노트 wrapper 공통 정의
 * @param {*} param0
 * @param {*} ref
 * @param earlyMobile 태블릿 사이즈부터 모바일 레이아웃
 * @returns
 */
const NoteMainWrapper = (
  {
    isSwipeable,
    children,
    column,
    single,
    singleM,
    className,
    gray,
    allinone,
    earlyMobile,
  },
  ref,
) => {
  const isNoticeBarVisible = useSelector(
    (state) => state.notice.isVisible,
  )
  const height = useSelector((state) => state.notice.height)
  const isVisibleBottomNav = useSelector(
    (state) => state.notev2.isVisibleBottomNav,
  )
  return (
    <Wrapper
      earlyMobile={earlyMobile}
      className={className}
      ref={ref}
      isSwipeable={isSwipeable ? 'Y' : 'N'}
      single={single ? 'Y' : 'N'}
      singleM={singleM ? 'Y' : 'N'}
      isnoticebarvisible={isNoticeBarVisible ? 'Y' : 'N'}
      noticebarheight={height}
      column={column}
      visiblebottomnav={isVisibleBottomNav}
      gray={gray ? 'Y' : 'N'}
      isallinone={allinone ? 'Y' : 'N'}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ isnoticebarvisible, noticebarheight }) =>
    isnoticebarvisible == 'Y'
      ? `top: ${noticebarheight}px !important;
        height: calc(100% - ${noticebarheight}px) !important;`
      : `top: 0;`};

  ${MainWrapperStyle}
  ${SwipeableMainWrapper}

  ${({ single }) =>
    single == 'Y'
      ? `left: ${theme.base.navWidth}px; 
      width: calc(100% - ${theme.base.navWidth}px);
    `
      : SwipeableLeftStyle};

  ${({ isSwipeable }) =>
    isSwipeable == 'Y' && `${SwipeableMainWrapper}`};

  ${media.mediumM`
    ${({ single }) =>
      single == 'Y' &&
      `left: 0 !important; 
        width: 100%;
      `}
  `}

  ${media.mediumS`
    ${({ isnoticebarvisible, noticebarheight, isVisibleBottomNav }) =>
      isnoticebarvisible == 'Y'
        ? `top: ${noticebarheight}px !important;
          height: calc(100% - ${noticebarheight}px - ${
            isVisibleBottomNav ? theme.base.bottomMenuHeight : 0
          }px) !important;`
        : `top: 0;`};
    ${({ singleM }) => singleM == 'Y' && `left: 0; width: 100%;`}
  `}
`

export default forwardRef(NoteMainWrapper)
