import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import NoteCalendar from 'components/jmapnote/calendar/NoteCalendar'
import theme, { media } from 'lib/styles/theme'
import { CalendarProvider } from 'contexts/calendarContext'
import CalendarHeader from 'components/jmapnote/calendar/CalendarHeader'
import useIsMobile from 'lib/hooks/useIsMobile'
import { MaxWidthWrapper } from 'components/jmapnotev2/style/NoteStyle'
import CalendarContainer from 'containers/note/calendar/CalendarContainer'
import NoteCalendarHeader from 'components/jmapnote/calendar/NoteCalendarHeader'
import NoteCalendarScheduleDay from 'components/jmapnote/calendar/NoteCalendarScheduleDay'
import MobileChannelHeader from 'components/jmapnotev2/channel/MobileChannelHeader'
import NoteMainWrapper from 'components/jmapnotev2/style/NoteMainWrapper'
import NoteCalendarSaveButton from './NoteCalendarSaveButton'
import { useSelector } from 'react-redux'

const NoteCalendarPage = () => {
  const isMobile = useIsMobile()
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  return (
    <>
      {isMobile && <MobileChannelHeader channel={false} />}
      <NoteMainWrapper gray single singleM>
        <Scrollbars autoHide>
          <StyledMaxWidthWrapper>
            <CalendarProvider>
              {isMobile && <CalendarHeader />}
              <CalendarContainer>
                {({ currentDate, setCurrentDate, viewMode }) => {
                  return (
                    <>
                      <NoteCalendarHeader
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                      />
                      <NoteCalendar
                        currentDate={currentDate}
                        viewMode={viewMode}
                      />
                      <NoteCalendarScheduleDay type="basic" />
                      {channelInfo?.member_type != 'readonly' && (
                        <NoteCalendarSaveButton
                          position="bottom"
                          currentDate={currentDate}
                        />
                      )}
                    </>
                  )
                }}
              </CalendarContainer>
            </CalendarProvider>
          </StyledMaxWidthWrapper>
        </Scrollbars>
      </NoteMainWrapper>
    </>
  )
}

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  background-color: ${theme.colors.base.white};
  padding: 24px 40px 100px 40px;
  margin: 0 auto;
  min-height: 100%;

  ${media.smallPc`
    width: 100%;
    padding: 0;
  `}

  ${media.mediumS`
    padding-top: 20px;
  `}
`
export default NoteCalendarPage
