import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NoteInviteRewardNoti = ({ minHeight, gray }) => {
  return (
    <List height={minHeight} gray={gray}>
      <li>해당 이벤트는 기간 동안 무제한으로 친구 추천이 가능해요.</li>
      <li>
        등기 열람권은 추천 링크를 통해 가입한 친구가 7일 이내에 "베이직"
        이상의 유료 요금제를 신청하면 30일 후 지급됩니다. 이 기간 중
        구독을 취소할 경우 지급되지 않으며, 지급된 열람권은 "베이직"
        이상의 유료 요금제를 구독하는 동안에만 사용할 수 있습니다.
      </li>
    </List>
  )
}

const List = styled.ul`
  min-height: ${({ height }) =>
    height
      ? `
        ${height}px
    `
      : `unset`};
  margin-top: 12px;
  padding-left: 20px;

  li {
    color: ${theme.colors.base.white};
    ${({ gray }) =>
      gray == 'Y' &&
      `
      color: ${theme.colors.gray[600]};
    `}
    list-style-type: disc;
    font-size: 12px;

    &::marker {
      font-size: 14px;
    }
  }
`

export default NoteInviteRewardNoti
