import { LoadingOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import NoteFormUploadInput from './NoteFormUploadInput'
import NoteFormPhotoItem from './NoteFormPhotoItem'
import Spinner from 'components/common/Loading/Spinner'
import { Gallery } from 'react-photoswipe-gallery'

const NoteDetailPhoto = () => {
  const { register, setValue, watch } = useFormContext()
  const images = watch('images')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('images')

    let parsedImages = images
    if (Array.isArray(images) && Array.isArray(images).length > 0) {
      parsedImages = JSON.parse(images)
    }

    setValue('images', parsedImages || [])
  }, [register])

  return (
    <>
      {loading && (
        <SpinWrapper>
          <Spinner
            spinning={loading}
            indicator={
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            }
          />
        </SpinWrapper>
      )}
      <StyledPhotoUploadHeader>
        <StyledPhotoUploadHeaderText>
          총 {images?.length || '0'}개의 사진
        </StyledPhotoUploadHeaderText>
        <NoteFormUploadInput setLoading={setLoading} />
      </StyledPhotoUploadHeader>
      <Gallery
        withCaption
        options={{
          history: false,
          barsSize: { top: 0, bottom: 0 },
          clickToCloseNonZoomable: false,
          closeOnScroll: false,
          closeOnVerticalDrag: false,
          captionEl: false,
          shareEl: false,
          closeEl: false,
          showHideAnimationType: 'fade',
          mainClass: 'pswp-note-detail',
        }}
      >
        <ImageWrapper>
          {Array.isArray(images) &&
            images.map((item, i) => (
              <NoteFormPhotoItem item={item} key={i} />
            ))}
        </ImageWrapper>
      </Gallery>
    </>
  )
}

const SpinWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 50%);
  z-index: 9999999;

  & > div {
    position: absolute;
    top: 50%;
    margin: auto 0;
    width: 100%;
    transform: translateY(-50%);
  }
`

const StyledPhotoUploadHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: -0.25rem;
  margin-bottom: 40px;
`

const StyledPhotoUploadHeaderText = styled.div`
  flex: 1;
  font-size: 1rem;
  font-weight: 700;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: -1px;
  gap: 2px;
`

export default NoteDetailPhoto
