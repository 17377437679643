import { forwardRef } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const StyledForm = styled.form``

const Form = forwardRef((props, ref) => {
  return <StyledForm {...props} ref={ref} />
})

export default Form
