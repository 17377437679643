import { getContacts } from 'lib/api/note/contactApi'
import useAxios from 'lib/hooks/useAxios'
import { setContacts } from 'modules/contact'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function useContacts() {
  const contacts = useSelector((state) => state.contact.contacts)
  const dispatch = useDispatch()

  const [onRequest, loading, data, error, reset] = useAxios(getContacts)

  const _getContacts = useCallback(() => {
    onRequest()
  }, [onRequest])

  useEffect(() => {
    if (!data?.result) return
    dispatch(setContacts(data.result))
  }, [data])

  return { _getContacts, loading, data, contacts, reset }
}
