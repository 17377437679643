import { StyledSelectView } from 'components/jmapnotev2/style/NoteGridStyle'
import { getPropertyFilter } from 'lib/api/notev2/filterApi'
import useAxios from 'lib/hooks/useAxios'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import { setNotePropertyFilter } from 'modules/notev2'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import NoteDirectionSelector from 'components/jmapnotev2/elements/NoteDirectionSelector'
import theme from 'lib/styles/theme'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import { eachSmallRowHeight } from 'lib/utils/notev2/note2Constants'
import useModifyAd from 'lib/hooks/notev2/useModifyAd'

/**
 * 그리드 방향 셀
 * @param type 광고면 ad
 */
const CustomDirectionCell = ({ node, type }) => {
  const noteOptions = useSelector((state) => state.notev2.noteOptions)

  const [options, setOptions] = useState()
  const [onRequestModifyNote] = useModifyNote()
  const [onModifyAd] = useModifyAd()
  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    isReversePosition,
  } = useCustomSingleCell(node?.data?.status, node?.rowIndex, 180)

  const [onRequestFilter, , dataFilter, ,] = useAxios(getPropertyFilter)
  const ref = useRef()

  const dispatch = useDispatch()

  const viewRef = useRef(null)

  /**
   * 바깥 클릭하면 모두 초기화(조회 상태로)
   */
  useOnClickOutside(ref, () => {
    setIsEditable(false)
  })

  useEffect(() => {
    //수정모드가 변경되면 방향키 제어를 위해 그리드 데이터의 state도 업데이트 해준다
    updateGridDatas({
      currentRowNode: node,
      newData: {
        ...node.data,
        state: {
          ...node.data.state,
          isEditingDirection: isEditable,
        },
      },
    })

    //수정 모드가 되면 자동 포커스
    if (isEditable) {
      ref.current?.focus()
    }
  }, [isEditable])

  useEffect(() => {
    if (!noteOptions) return

    setOptions(getSimpleSelectboxOptions(noteOptions.directions))
  }, [noteOptions])

  /**
   * selectbox change
   */
  const onChange = useCallback(
    (e) => {
      //종류 데이터 업데이트

      if (type == 'ad') {
        onModifyAd({
          data: node.data,
          updateData: { direction: e.name },
          currentRowNode: node,
        })
      } else {
        onRequestModifyNote({
          data: node.data,
          updateData: { direction: e.name },
          currentRowNode: node,
        })
      }

      //수정모드 해제
      setIsEditable(false)

      //왼쪽 필터 refresh
      onRequestFilter({ channel_srl: node.data.channel_srl })
    },
    [node, options],
  )

  /**
   * 필터 refresh 결과
   */
  useEffect(() => {
    if (!dataFilter || !dataFilter.result) return

    dispatch(setNotePropertyFilter(dataFilter.result?.property || {}))
  }, [dataFilter])

  /**
   * 탭, 엔터, esc 키 동작
   */
  const onKeyDownSelect = useCallback(
    (e) => {
      if (e.keyCode == '27' || e.keyCode == '13') {
        setIsEditable(false)
        viewRef.current?.focus()
      }
    },
    [isEditable, viewRef],
  )

  if (
    (type == 'ad' && node.data.ad_srl == '신규') ||
    (type != 'ad' && node.data.note_srl == '신규')
  )
    return <></>

  return (
    <Wrapper tabIndex={-1}>
      <StyledSelectView
        ref={viewRef}
        tabIndex={0}
        onDoubleClick={onDoubleClickSingleCell}
        onKeyDown={onKeyDownSingleCell}
        onClick={() => setIsEditable(false)}
      >
        {node.data.direction}
      </StyledSelectView>

      {isEditable && (
        <EditableWrapper
          ref={ref}
          rowheight={node.data.rowHeight}
          reverse={isReversePosition ? 'Y' : 'N'}
        >
          <NoteDirectionSelector
            value={node.data.direction}
            onClick={onChange}
            onKeyDown={onKeyDownSelect}
          />
        </EditableWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const EditableWrapper = styled.div`
  position: absolute;
  top: ${({ rowheight }) =>
    rowheight
      ? `calc(${rowheight}px + 4px);`
      : `${eachSmallRowHeight * 2 + 4}px`};
  left: 50%;
  transform: translateX(-50%);
  background-color: ${theme.colors.base.white};
  border-radius: 10px;
  box-shadow: ${theme.shadow['card-2']};

  ${({ reverse }) =>
    reverse == 'Y' &&
    `
      top: -176px;
  `}

  & > div {
    margin-left: 0;
  }
`

export default CustomDirectionCell
