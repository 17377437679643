import { Switch, Route } from 'react-router-dom'
// import loadable from '@loadable/component';

// const SummaryNearBy = loadable(() => import('./SummaryNearBy'));
// const SummaryLob = loadable(() => import('./SummaryLob'));
// const SummaryLegal = loadable(() => import('./SummaryLegal'));
import SummaryNearBy from './SummaryNearBy'
import SummaryRealPurchase from './SummaryRealPurchase'
import SummarySizeReview from './SummarySizeReview'
import SummaryNohu from './SummaryNohu'

const SummaryExternal = ({ match }) => {
  if (match.params?.external === 'nearby')
    return (
      <div>
        <SummaryNearBy />
      </div>
    )
  else if (match.params?.external === 'realPurchase')
    return <SummaryRealPurchase />
  else if (match.params?.external === 'sizeReview')
    return <SummarySizeReview />
  else if (match.params?.external === 'nohu') return <SummaryNohu />
  else return <></>
}

export default SummaryExternal
