import styled from 'styled-components'
import NoteAdNotice from './notice/NoteAdNotice'
import NoteAdNotification from './notification/NoteAdNotification'
import { media } from 'lib/styles/theme'
import { bp, breakPoint } from './breakPoints'
import NoteAdNoticeFlat from './notice/NoteAdNoticeFlat'

/**
 * 광고 메인 페이지 상태값 아래 알림 영역
 */
const NoteAdNoti = () => {
  const isFlip = window.innerWidth <= bp[4] || window.innerHeight < 900
  return (
    <Wrapper>
      <NoteAdNotification isFlip={isFlip} />
      {isFlip ? <NoteAdNoticeFlat /> : <NoteAdNotice />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 36px;
  margin-bottom: 45px;
  width: 100%;

  ${media.xxLarge`
    gap: 20px;
  `};

  ${media.xLarge`
    flex-direction: column;
  `};

  ${breakPoint(bp[4])} {
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }
`

export default NoteAdNoti
