import MapContext from 'contexts/map'
import {
  isDonghoAreaUse,
  isFloorAreaUse,
  isLandAreaUse,
} from 'lib/utils/notev2/note2Constants'
import { useContext, useMemo } from 'react'
import { Icon, Wrapper, Item } from './NoteListMobilePrice'

const NoteListMobileArea = ({ data }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const area = (
    property_type,
    land_area,
    exclusive_area,
    contract_area,
    building_area,
    total_floor_area,
    dong,
    ho,
  ) => {
    const unit = isSquareMeter ? '㎡' : '평'
    if (isDonghoAreaUse.includes(property_type)) {
      const formatContract = isSquareMeter
        ? Math.floor(parseFloat(contract_area))
        : Math.floor(parseFloat(contract_area / 3.3057)) || ''
      const formatExclusive = isSquareMeter
        ? Math.floor(parseFloat(exclusive_area))
        : Math.floor(parseFloat(exclusive_area / 3.3057)) || ''

      return formatContract
        ? `${formatContract || ''}${formatContract ? unit : ''}/${
            formatExclusive || ''
          }${formatExclusive ? unit : ''}`
        : ''
    } else if (isFloorAreaUse.includes(property_type)) {
      if (
        (property_type == '다가구주택' ||
          property_type == '단독주택') &&
        (dong || ho)
      ) {
        const formatContract = isSquareMeter
          ? Math.floor(parseFloat(contract_area)) || ''
          : Math.floor(parseFloat(contract_area / 3.3057)) || ''

        return formatContract
          ? `${formatContract}${formatContract ? unit : ''}`
          : ''
      } else {
        const formatTotalFloor = isSquareMeter
          ? Math.floor(parseFloat(total_floor_area)) || ''
          : Math.floor(parseFloat(total_floor_area / 3.3057)) || ''
        const formatBuilding = isSquareMeter
          ? Math.floor(parseFloat(building_area)) || ''
          : Math.floor(parseFloat(building_area / 3.3057)) || ''

        return formatTotalFloor
          ? `${formatTotalFloor}${
              formatTotalFloor ? unit : ''
            }/${formatBuilding}${formatBuilding ? unit : ''}`
          : formatBuilding
          ? `건물 ${formatBuilding}${formatBuilding ? unit : ''}`
          : ''
      }
    } else if (isLandAreaUse.includes(property_type)) {
      const formatLand = isSquareMeter
        ? Math.floor(parseFloat(land_area)) || ''
        : Math.floor(parseFloat(land_area / 3.3057)) || ''
      const formatBuilding = isSquareMeter
        ? Math.floor(parseFloat(building_area)) || ''
        : Math.floor(parseFloat(building_area / 3.3057)) || ''

      if (property_type == '기타') {
        return `${formatLand}${
          formatLand ? unit : ''
        }/${formatBuilding}${formatBuilding ? unit : ''}`
      } else {
        return `${formatLand}${formatLand ? unit : ''}`
      }
    } else {
      const formatTotalFloor = isSquareMeter
        ? Math.floor(parseFloat(total_floor_area)) || ''
        : Math.floor(parseFloat(total_floor_area / 3.3057)) || ''
      return `${formatTotalFloor}${formatTotalFloor ? unit : ''}`
    }
  }

  const areaDatas = useMemo(
    () =>
      area(
        data.property_type,
        data.land_area,
        data.exclusive_area,
        data.contract_area,
        data.building_area,
        data.total_floor_area,
        data.dong,
        data.ho,
      ),
    [
      data.property_type,
      data.land_area,
      data.exclusive_area,
      data.contract_area,
      data.building_area,
      data.total_floor_area,
      data.dong,
      data.ho,
    ],
  )

  return (
    <Wrapper area>
      <Icon className="fas fa-thumbtack" />
      {areaDatas?.replaceAll('/', '') ? (
        <Item>
          {areaDatas.replace(/\/\s*$/, '').replace(/\/\s*^/, '')}
        </Item>
      ) : (
        '면적 정보 없음'
      )}
    </Wrapper>
  )
}

export default NoteListMobileArea
