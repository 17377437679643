import { getMaxRowHeight } from './gridData'
import { focusNextCell } from './gridFocus'
import { colorGroup, eachSmallRowHeight } from './note2Constants'

/**
 * 그리드의 주소를 초기화
 * @param {*} targetNodeData
 * @param {*} isAddrReset 주소까지 reset 할 것인지 여부
 */
export const resetGridDataAddresses = (targetNodeData, isAddrReset) => {
  updateGridDatas({
    currentRowNode: targetNodeData,
    newData: {
      addr: isAddrReset ? '' : targetNodeData.data.addr,
    },
  })
}
/**
 * 그리드 데이터 업데이트
 */
export const updateGridDatas = ({
  gridApi,
  newData,
  currentRowKey,
  currentRowNode,
}) => {
  const rowNode = currentRowNode
    ? currentRowNode
    : gridApi.getRowNode(currentRowKey)

  rowNode.setData(Object.assign({}, rowNode.data, newData))
}

//Ctrl + A 키
export const addSelectAllKeyEvent = (e) => {
  if (e.keyCode == 65 && e.ctrlKey) {
    e.target.select()
  }
}

//Home 키
export const addStartCursorKeyEvent = (e) => {
  if (e.keyCode == 36) {
    e.target.setSelectionRange(0, 0)
  }
}

//End 키
export const addEndCursorKeyEvent = (e) => {
  if (e.keyCode == 35) {
    e.target.setSelectionRange(
      e.target.value.length,
      e.target.value.length,
    )
  }
}

export const getPricePerHeight = (contract_type, isEditingPrice) => {
  const count = contract_type ? contract_type?.split(',').length : 0

  if (isEditingPrice) {
    return '33%'
  } else if (count == 1) {
    return '100%'
  } else if (count == 2) {
    return '50%'
  } else if (count == 3) {
    return '33%'
  } else {
    return '33%'
  }
}

/**
 * 가격 셀 모두 보여주기
 * @param {*} gridApi
 * @param {*} currentRowNode
 */
export const showAllTypesPrice = (gridApi, currentRowNode) => {
  const rowHeight = getMaxRowHeight({
    contract_type: currentRowNode.data.contract_type,
    contacts: currentRowNode.data.contacts,
  })

  updateGridDatas({
    currentRowNode,
    newData: {
      state: {
        ...currentRowNode.data.state,
        isContractTypeShow: true,
      },
    },
  })

  ///if (currentRowNode.data.rowHeight == eachSmallRowHeight * 3) return
  setTimeout(() => {
    currentRowNode.setRowHeight(
      rowHeight < eachSmallRowHeight * 3
        ? eachSmallRowHeight * 3
        : rowHeight,
    )
    updateGridDatas({
      currentRowNode,
      newData: {
        rowHeight: eachSmallRowHeight * 3,
      },
    })
    gridApi.onRowHeightChanged()
  }, 10)
}

export const cellPrependLabelColorClass = {
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-disabled custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) => params.data.status == 2,
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-cyan custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) => colorGroup['cyan'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-purple custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) =>
      colorGroup['purple'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-pink custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) => colorGroup['pink'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-green custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) => colorGroup['green'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-blue custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) => colorGroup['blue'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-red custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) => colorGroup['red'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-orange custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) =>
      colorGroup['orange'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-gray custom-no-padding-cell custom-cell-overflow-visible custom-event-suppress-arrow-keys custom-multiple-cell':
    (params) => colorGroup['gray'].includes(params.data.property_type),
}

export const rowClassRules = {
  //중개완료 class
  'custom-row-complete': function (params) {
    return params.data.status == 2
  },
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-disabled':
    (params) => params.data.status == 2,
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-cyan':
    (params) => colorGroup['cyan'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-purple':
    (params) =>
      colorGroup['purple'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-pink':
    (params) => colorGroup['pink'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-green':
    (params) => colorGroup['green'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-blue':
    (params) => colorGroup['blue'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-red':
    (params) => colorGroup['red'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-orange':
    (params) =>
      colorGroup['orange'].includes(params.data.property_type),
  'custom-cell-prepend-label-color custom-cell-prepend-label-color-gray':
    (params) => colorGroup['gray'].includes(params.data.property_type),
}

/**
 * 그리드 폰트 사이즈 변수
 */
export const gridFontSize = {
  s: 11,
  m: 13,
  l: 15,
}

/**
 * 그리드 폰트 사이즈
 * 그리드는 내부에서 폰트 사이즈 +1을 해주기 때문에, 12, 14, 16 사이즈라고 보면 된다
 * @param {*} val
 * @returns
 */
export const getGridFontSize = (val = 'm') => {
  return gridFontSize[val] || gridFontSize['m']
}

/**
 * 폰트 사이즈에 따라 그리드 셀 넓이 추가할 px
 * @param {*} val 폰트 사이즈(s, m, l)
 * @returns
 */
export const additionalCellWidth = (val) => {
  const result = {
    s: -10,
    m: 0,
    l: 10,
  }
  return result[val]
}
