import axios from 'axios'

/**
 * 부동산 리스트
 * | address_id | int | 마커 필터
  | realtor_number | string | 공인중개사 필터
  | contract_types | array | 거래유형 필터
  | property_types | array | 매물유형 필터
  | sale_prcie | array | 매매가/전세가/보증금 필터
  | rent | array | 월세 필터
 */
export const getBdsList = async (
  {
    _rx_csrf_token,
    latitude,
    longitude,
    count,
    page,
    address_id,
    level,
    realtor_number,
    contract_types,
    property_types,
    sale_price,
    deposit,
    rent,
    filter_area,
    swlat,
    nelat,
    swlng,
    nelng,
    address_name,
    category_name,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/realestate/list',
    {
      _rx_csrf_token,
      latitude,
      longitude,
      count,
      page,
      address_id,
      realtor_number,
      contract_types,
      property_types,
      sale_price,
      deposit,
      rent,
      filter_area,
      level,
      swlat,
      nelat,
      swlng,
      nelng,
      address_name,
      category_name,
    },
    options,
  )

/**
 * 부동산 개별 매물 정보
 */
export const getBdsDetail = async (
  { _rx_csrf_token, ad_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/realestate/view',
    {
      _rx_csrf_token,
      ad_srl,
    },
    options,
  )

/**
 * 주택부동산 마커
 * | level | int | 줌 레벨
  | swlat | float | 지도 영역
  | nelat | float | 지도 영역
  | swlng | float | 지도 영역
  | nelng | float | 지도 영역
 */
export const getMarkersRealestate = async (
  {
    _rx_csrf_token,
    level,
    swlat,
    nelat,
    swlng,
    nelng,
    contract_types,
    property_types,
    sale_price,
    deposit,
    rent,
    filter_area,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/realestate/markers',
    {
      _rx_csrf_token,
      level,
      swlat,
      nelat,
      swlng,
      nelng,
      contract_types,
      property_types,
      sale_price,
      deposit,
      rent,
      filter_area,
    },
    options,
  )
