import theme from 'lib/styles/theme'
import styled from 'styled-components'
import {
  Block,
  ButtonCard,
  Info,
  InfoTitle,
  InfoWrap,
} from 'components/jmapnotev2/style/NotePaymentStyle'
import moment from 'moment'
import { allInOnePrice } from 'lib/utils/notev2/util'
import { numberWithCommas } from 'utils'

/**
 * 왼쪽 요금제 정보 블럭
 * 올인원 요금제 직원
 * 자신의 요금제 정보
 */
const NotePaymentInfoBlockAllInOneMemberOwn = ({
  plan: {
    plan_name,
    has_billingkey,
    card_info,
    has_error,
    months,
    regdate,
    next_payment_date,
    start_date,
  },
  handleChangeCard,
}) => {
  const price = {
    프리: 0,
    베이직: 4900,
    올인원1: allInOnePrice[1],
    올인원6: allInOnePrice[6],
  }

  return (
    <Block fit>
      <TitleWrapper>
        <Title>{plan_name}</Title>
        <Price>
          ₩
          {numberWithCommas(
            price[
              plan_name == '올인원'
                ? `${plan_name}${months}`
                : plan_name
            ],
          )}
        </Price>
      </TitleWrapper>
      <InfoWrap hideButtons>
        {plan_name != '프리' && (
          <Info>
            <InfoTitle>이용기간</InfoTitle>
            {moment(start_date, 'YYYYMMDDHHmmss').format(
              'YYYY.MM.DD',
            )}{' '}
            &#126;{' '}
            {moment(next_payment_date, 'YYYYMMDDHHmmss').format(
              'YYYY.MM.DD',
            )}
          </Info>
        )}
        <Info>
          <InfoTitle>자동결제</InfoTitle>
          {has_billingkey ? (
            <>
              <span>
                {card_info?.issuerName} {card_info?.number?.slice(-4)}
              </span>
              <ButtonCard onClick={handleChangeCard} color="blue">
                카드 변경
              </ButtonCard>
            </>
          ) : (
            <>&ndash;</>
          )}

          {has_error && <FailMsg>결제 실패</FailMsg>}
        </Info>
      </InfoWrap>
    </Block>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px dashed ${theme.colors.gray[300]};
`

const Title = styled.div`
  margin-top: 6px;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;
`

const Price = styled.div`
  text-decoration: line-through;
  color: ${theme.colors.gray[400]};
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
`

export default NotePaymentInfoBlockAllInOneMemberOwn
