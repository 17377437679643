import Container from 'components/base/Container/Container'
import BdsContent from 'components/bds/BdsContent'
import BdsSearch from 'components/bds/BdsSearch'
import BdsContentContainer from 'containers/bds/BdsContentContainer'
import { useState } from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom/cjs/react-router-dom.min'
import BdsDetailPage from './BdsDetailPage'
import BdsHeaderBack from 'components/bds/detail/BdsHeaderBack'
import BdsDetailRealestate from 'components/bds/detail/BdsDetailRealestate'

const BdsPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container swipeable onChange={(e) => setIsOpen(e)}>
      <BdsContentContainer>
        {({
          loading,
          address,
          list,
          clearSearch,
          realtorModeData,
          bindInput,
          onSearch,
          loadTargetRef,
          refreshSearch,
        }) => {
          return (
            <>
              {realtorModeData ? (
                <>
                  <BdsDetailRealestate
                    gather
                    realtorMode={realtorModeData}
                    data={{ realtor: realtorModeData }}
                  />
                  <BdsHeaderBack refreshSearch={refreshSearch} />
                </>
              ) : (
                <BdsSearch
                  bindInput={bindInput}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                />
              )}
              <BdsContent
                list={list}
                address={address}
                ref={loadTargetRef}
                loading={loading}
              />
            </>
          )
        }}
      </BdsContentContainer>
      <Switch>
        <Route path="/bds/:id" component={BdsDetailPage} exact />
      </Switch>
    </Container>
  )
}

export default BdsPage
