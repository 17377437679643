import styled from 'styled-components'
import { Section, Title } from './BdsDetailStyle'
import { ReactComponent as FloorIcon } from 'assets/icon/bds/floor.svg'
import { ReactComponent as HouseholdIcon } from 'assets/icon/bds/household.svg'
import { ReactComponent as DirectionIcon } from 'assets/icon/bds/direction.svg'
import { ReactComponent as NaverIcon } from 'assets/icon/bds/naver.svg'
import theme from 'lib/styles/theme'
import { getArea } from 'lib/utils/bds'
import moment from 'moment'
import { feeOptions } from 'components/jmapnotev2/NoteAd/optionsFee'
import BdsDetailFeeModal from './BdsDetailFeeModal'
import { useState } from 'react'

/**
 * 부동산 2뎁스
 * 매물 정보 섹션
 */
const BdsDetailInfo = ({ data, tabs }) => {
  const feeType =
    feeOptions['부과타입']?.[
      data?.serialized_object?.administrationCostInfo?.chargeCodeType
    ]
  const [isFeeModalVisible, setIsFeeModalVisible] = useState(false)

  const onClickOpenFeeModal = () => {
    setIsFeeModalVisible(true)
  }

  const onCloseModal = () => {
    setIsFeeModalVisible(false)
  }

  /**
   * 네이버 평면도 보러가기
   */
  const onClickNaver = () => {
    //TODO:
    alert('todo')
  }

  return (
    <>
      <Section ref={tabs[0].element}>
        <Title>매물 정보</Title>
        <BlockWrapper>
          <Block>
            <FloorIcon />
            <div>
              {data?.floor1 || <>&ndash;</>}층(총
              {data?.floor2 || <>&ndash;</>}층)
            </div>
          </Block>
          <Block>
            <HouseholdIcon />
            {data?.household || <>&ndash;</>}세대
          </Block>
          <Block>
            <DirectionIcon />
            {data?.direction || <>&ndash;</>}
          </Block>
        </BlockWrapper>
        <Table>
          <Row>
            <TableTitle>주소</TableTitle>
            <TableValue>
              <div>
                {data?.address_jibun} {data?.dong}동
              </div>
            </TableValue>
          </Row>
          <Row>
            <TableTitle>
              {data?.property_type == '토지'
                ? '대지면적'
                : '공급/전용면적'}
            </TableTitle>
            <TableValue>
              {getArea({
                property_type: data?.property_type,
                land_area: data?.land_area,
                exclusive_area: data?.exclusive_area,
                contract_area: data?.contract_area,
              }) || <>&ndash;</>}
            </TableValue>
          </Row>
          <Row>
            <TableTitle>방수 / 욕실수</TableTitle>
            <TableValue>
              {data?.room || <>&ndash;</>}/
              {data?.restroom || <>&ndash;</>}
            </TableValue>
          </Row>
          <Row>
            <TableTitle>
              관리비
              {feeType && feeType != '04' && (
                <i
                  className="fas fa-info-circle"
                  onClick={onClickOpenFeeModal}
                ></i>
              )}
            </TableTitle>
            <TableValue>{feeType || <>&ndash;</>}</TableValue>
          </Row>
          <Row>
            <TableTitle>입주일</TableTitle>
            <TableValue>
              {data?.moveinYmd ? (
                moment(data?.moveinYmd).format('YYYY.MM.DD') ==
                'Invalid date' ? (
                  <>&ndash;</>
                ) : (
                  moment(data?.moveinYmd).format('YYYY.MM.DD')
                )
              ) : (
                <>&ndash;</>
              )}
            </TableValue>
          </Row>
        </Table>
        <NaverButton onClick={onClickNaver}>
          <NaverIcon />
          네이버 평면도 보러가기
        </NaverButton>
      </Section>
      <BdsDetailFeeModal
        visible={isFeeModalVisible}
        onCancel={onCloseModal}
        administrationCostInfo={
          data?.serialized_object?.administrationCostInfo
        }
      />
    </>
  )
}

const BlockWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 12px;
`

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  height: 91px;
  border-radius: 8px;
  background: #f6f9fe;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`

const Table = styled.div`
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.gray[200]};
  overflow: hidden;
`

const Row = styled.div`
  display: flex;
  height: 42px;
  border-bottom: 1px solid ${theme.colors.gray[200]};

  &:last-child {
    border-bottom: none;
  }
`

const TableTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 12px;
  width: 110px;
  color: ${theme.colors.gray[600]};
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  border-right: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.gray[50]};
  flex-shrink: 0;

  i {
    color: ${theme.colors.gray[400]};
    cursor: pointer;
  }
`

const TableValue = styled.div`
  padding-left: 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  & > div {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 120%;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

const NaverButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  height: 40px;
  background-color: ${theme.colors.gray[100]};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 180%;
`

export default BdsDetailInfo
