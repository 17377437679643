import styled from 'styled-components'
import theme from 'lib/styles/theme'
import useSummarySchoolAmemities from 'lib/hooks/useSummarySchoolAmemities'
import useIsMobile from 'lib/hooks/useIsMobile'

const SummaryAmenitiesListItem = ({
  name,
  distance,
  icon,
  prevMarker,
  setPrevMarker,
  centerpoint: targetCenterPoint,
  iconSrc,
  onClickSetActiveValue,
  activeValue,
  resetActiveValue,
}) => {
  const { onClick } = useSummarySchoolAmemities()
  const isMobile = useIsMobile()

  const onClickItem = () => {
    if (isMobile) return
    onClickSetActiveValue(name)
    onClick({
      prevMarker,
      setPrevMarker,
      targetCenterPoint,
      iconSrc,
      activeValue,
      name,
      resetActiveValue,
    })
  }

  return (
    <Item active={activeValue == name} onClick={onClickItem}>
      <Title>
        {icon}
        <Name>{name}</Name>
      </Title>
      <Distance>{distance}</Distance>
    </Item>
  )
}

export const Title = styled.div`
  display: flex;
  align-items: center;
`

export const Name = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: ${theme.colors.gray[800]};
`

export const Distance = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${theme.colors.gray[500]};
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 8px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  ${({ active }) =>
    active &&
    `
      background-color: ${theme.colors.primary[50]};
      ${Name} {
        position: relative;
        font-weight: 600;

          &::after {
          content: '\f601';
          position: absolute;
          right: -24px;
          margin-left: 8px;
          font-weight: 400;
          font-family: 'Font Awesome 5 Pro';
          color: ${theme.colors.gray[600]};
        }
      }

    

      ${Distance} {
        font-weight: 600;
        color: ${theme.colors.gray[900]};
      }
  `}

  &:hover {
    background-color: ${theme.colors.gray[100]};
  }
`

export default SummaryAmenitiesListItem
