import { useContext } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import SegmentContext from './SegmentContext'

const StyledSegmentItem = styled.li`
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1.5rem;
  margin-right: 0.25rem;

  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  list-style: none;
  text-decoration: none;

  border-radius: 9px;

  transition: 0.2s ease;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${theme.colors.gray[700]};
  }
  &:active {
  }
  &:focus {
    outline: none !important;
  }
  &[disabled] {
    color: ${theme.colors.gray[600]};
    cursor: not-allowed;
  }

  &.active {
    color: ${({ color }) =>
      color == 'blue'
        ? theme.colors.blue[500]
        : theme.colors.primary[500]};
    font-weight: 700;
    background-color: ${theme.colors.base.white};
    box-shadow: rgba(0, 0, 0, 0.05) 0 1px 1px,
      rgba(0, 0, 0, 0.1) 0 6px 20px;
  }

  ${({ expand }) =>
    expand &&
    `
    flex: 1;
  `}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
        padding: 0 1rem;
        `
      case 'medium':
        return `
        padding: 0 1rem;
        `
    }
  }}
`

const SegmentItem = ({ children, active, onClick, expand, color }) => {
  const {
    state: { sizeState },
  } = useContext(SegmentContext)

  return (
    <StyledSegmentItem
      className={active && 'active'}
      onClick={onClick}
      expand={expand}
      size={sizeState}
      color={color}
    >
      {children}
    </StyledSegmentItem>
  )
}

export default SegmentItem
