import { FormDesc } from 'components/jmapnotev2/NoteAd/form/NoteAdFormStyle'
import styled from 'styled-components'

const NoteAdPriceDesc = () => {
  return (
    <StyledFormDesc>
      <li>
        매물이 노출된 후에는 건물형태, 거래정보, 주소(동/호 정보 포함),
        면적 정보, '최초등록가' ± 20%를 벗어나는 가격(월세 제외)은
        수정이 불가합니다.
      </li>
    </StyledFormDesc>
  )
}

const StyledFormDesc = styled(FormDesc)`
  margin-bottom: 20px;
`

export default NoteAdPriceDesc
