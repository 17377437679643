import styled, { css } from 'styled-components'
import AppContainer from './AppContainer'
import { SubTitle, Title } from './LandingStyle'
import theme, { media } from 'lib/styles/theme'
import Step1Img from 'assets/images/note/landing/step-1.png'
import Step2Img from 'assets/images/note/landing/step-2.png'
import Step3Img from 'assets/images/note/landing/step-3.png'
import Step4Img from 'assets/images/note/landing/step-4.png'
import { forwardRef, useEffect, useRef } from 'react'
import { throttle } from 'lodash-es'
import useIsMobile from 'lib/hooks/useIsMobile'

const LandingStep = ({}, ref) => {
  const itemsRef = useRef([])
  const imagesRef = useRef([])
  const stickyRef = useRef(null)
  const wrapperRef = useRef(null)

  const pcStickyWrapperRef = useRef(null)
  const pcStickyContainerRef = useRef(null)
  const mStickyWrapperRef = useRef(null)
  const mStickyContainerRef = useRef(null)

  const isMobile = useIsMobile()

  useEffect(() => {
    scrollingSections()
    //setStickySection()
  }, [])

  const scrollingSections = function () {
    if (!ref?.current) return
    if (
      wrapperRef.current.offsetHeight + 1000 <
      ref.current.scrollTop
    ) {
      return
    }

    const fourthMethodHeight = stickyRef.current.offsetHeight / 4 - 300
    const methodTop = stickyRef.current.getBoundingClientRect().top

    ref.current.addEventListener(
      'scroll',
      throttle(function () {
        if (!ref?.current) return
        const scrollTop = ref.current.scrollTop

        if (scrollTop > methodTop) {
          itemsRef.current.forEach((elem) => {
            elem.classList.remove('active')
          })
          imagesRef.current.forEach((elem) => {
            elem.classList.remove('active')
          })
          itemsRef.current[0].classList.add('active')
          imagesRef.current[0].classList.add('active')
        }
        if (scrollTop > methodTop + fourthMethodHeight) {
          itemsRef.current.forEach((elem) => {
            elem.classList.remove('active')
          })
          imagesRef.current.forEach((elem) => {
            elem.classList.remove('active')
          })
          itemsRef.current[1].classList.add('active')
          imagesRef.current[1].classList.add('active')
        }
        if (scrollTop > methodTop + fourthMethodHeight * 2) {
          itemsRef.current.forEach((elem) => {
            elem.classList.remove('active')
          })
          imagesRef.current.forEach((elem) => {
            elem.classList.remove('active')
          })
          itemsRef.current[2].classList.add('active')
          imagesRef.current[2].classList.add('active')
        }
        if (scrollTop > methodTop + fourthMethodHeight * 3) {
          itemsRef.current.forEach((elem) => {
            elem.classList.remove('active')
          })
          imagesRef.current.forEach((elem) => {
            elem.classList.remove('active')
          })
          itemsRef.current[3].classList.add('active')
          imagesRef.current[3].classList.add('active')
        }
      }, 100),
    )
  }

  /**
   * jquery식 offset top 얻기
   * @param {*} el
   * @returns
   */
  function getOffsetTop(el) {
    if (!el.getClientRects().length) {
      return 0
    }

    let rect = el.getBoundingClientRect()

    return rect.top + ref.current.scrollTop
  }

  /**
   * jquery식 height 얻기
   * @param {*} el
   * @returns
   */
  function getHeight(el) {
    var c = window.getComputedStyle(el)
    var border =
        parseFloat(c.borderTopWidth) + parseFloat(c.borderBottomWidth),
      padding = parseFloat(c.paddingTop) + parseFloat(c.paddingBottom)
    var scrollBar = el.offsetHeight - el.clientHeight - border

    if (c.boxSizing == 'border-box') {
      return el.offsetHeight - border - padding
    } else {
      return el.offsetHeight - border - padding - scrollBar
    }
  }

  /**
   * step 클릭 이벤트
   * @param {*} e
   * @param {*} order
   */
  const onClickStep = (e, order) => {
    itemsRef.current.forEach((elem) => {
      elem.classList.remove('active')
    })
    e.currentTarget.classList.add('active')

    const fourthMethodHeight = getHeight(stickyRef.current) / 4 - 300
    const methodTop = getOffsetTop(stickyRef.current)

    const scrollTop =
      order == 1
        ? methodTop
        : methodTop + fourthMethodHeight * (order - 1)

    if (isMobile) {
      ref.current.scrollTo({ top: scrollTop + 230 })
    } else {
      ref.current.scrollTo({ top: scrollTop + 100 })
    }
  }

  /**
   * pc와 모바일 sticky 하는 영역 변경
   */
  const setStickySection = () => {
    const wrapperClass = 'sticky-container-wrapper'
    const containerClass = 'sticky-container'

    if (window.innerWidth > 490) {
      pcStickyWrapperRef.current.classList.add(wrapperClass)
      pcStickyContainerRef.current.classList.add(containerClass)
    } else {
      pcStickyWrapperRef.current.classList.remove(wrapperClass)
      pcStickyContainerRef.current.classList.remove(containerClass)
      pcStickyContainerRef.current.classList.add('sticky-wrapper')
      mStickyWrapperRef.current.classList.add(wrapperClass)
      mStickyContainerRef.current.classList.add(containerClass)
    }
  }

  return (
    <Wrapper ref={wrapperRef}>
      <AppContainer>
        <StickyWrapper ref={stickyRef}>
          <StickyContainerWrapper
            className="sticky-container-wrapper"
            ref={pcStickyWrapperRef}
            id="pc-sticky-wrapper"
          >
            <StickyContainer
              className="sticky-container"
              ref={pcStickyContainerRef}
              id="pc-sticky"
            >
              <TitleWrapper>
                <StyledTitle>
                  복잡한 중개업무, <br />
                  원클릭으로 관리하세요
                </StyledTitle>
                <StyledSubTitle>
                  복잡한 매물 관리부터 각종 문서 발급, 광고, 계약서
                  작성까지
                  <br />
                  <span>매물노트</span>에서 <span>One-click</span> 으로
                  손쉽게 관리하세요
                </StyledSubTitle>
              </TitleWrapper>
              <Content
                id="mobile-sticky-wrapper"
                ref={mStickyWrapperRef}
              >
                <ContentWrapper
                  id="mobile-sticky"
                  ref={mStickyContainerRef}
                >
                  <Bar />
                  <ContentStep>
                    <ContentStepBy
                      ref={(el) => (itemsRef.current[0] = el)}
                      className="section-step-content-step-by-1 active"
                      onClick={(e) => onClickStep(e, 1)}
                    >
                      <ContentStepByTitle>매물</ContentStepByTitle>
                      <ContentStepByTitleSub>
                        주소만 입력하면
                        <br />
                        나머지는 자동으로 완성
                      </ContentStepByTitleSub>
                    </ContentStepBy>
                    <ContentStepBy
                      ref={(el) => (itemsRef.current[1] = el)}
                      className="section-step-content-step-by-2"
                      onClick={(e) => onClickStep(e, 2)}
                    >
                      <ContentStepByTitle>등본</ContentStepByTitle>
                      <ContentStepByTitleSub>
                        공부서류도 이젠
                        <br />
                        원클릭 발급
                      </ContentStepByTitleSub>
                    </ContentStepBy>
                    <ContentStepBy
                      ref={(el) => (itemsRef.current[2] = el)}
                      className="section-step-content-step-by-3"
                      onClick={(e) => onClickStep(e, 3)}
                    >
                      <ContentStepByTitle>광고</ContentStepByTitle>
                      <ContentStepByTitleSub>
                        클릭 한번으로
                        <br />
                        플랫폼 매물 광고
                      </ContentStepByTitleSub>
                    </ContentStepBy>
                    <ContentStepBy
                      ref={(el) => (itemsRef.current[3] = el)}
                      className="section-step-content-step-by-4"
                      onClick={(e) => onClickStep(e, 4)}
                    >
                      <ContentStepByTitle>계약</ContentStepByTitle>
                      <ContentStepByTitleSub>
                        원클릭 계약서,
                        <br />
                        확인설명서 작성
                      </ContentStepByTitleSub>
                    </ContentStepBy>
                  </ContentStep>
                  <ImageWrapper>
                    <img
                      src={Step1Img}
                      ref={(el) => (imagesRef.current[0] = el)}
                      className="section-step-content-image-by section-step-content-image-by-1 active"
                    />
                    <img
                      src={Step2Img}
                      ref={(el) => (imagesRef.current[1] = el)}
                      className="section-step-content-image-by section-step-content-image-by-2"
                    />
                    <img
                      src={Step3Img}
                      ref={(el) => (imagesRef.current[2] = el)}
                      className="section-step-content-image-by section-step-content-image-by-3"
                    />
                    <img
                      src={Step4Img}
                      ref={(el) => (imagesRef.current[3] = el)}
                      className="section-step-content-image-by section-step-content-image-by-4"
                    />
                  </ImageWrapper>
                </ContentWrapper>
              </Content>
            </StickyContainer>
          </StickyContainerWrapper>
        </StickyWrapper>
      </AppContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 0;
`

const StickyWrapperStyle = css`
  position: relative;
  width: 100%;
  height: 400vh;
  @media screen and (max-height: 540px) {
    height: auto;
  }
`

const StickyContainerWrapperStyle = css`
  width: 100% !important;
  height: 100vh !important;
  position: sticky !important;
  top: 0px !important;

  ${media.mediumS`
    position: initial !important;
  `}
`

const StickyContainerStyle = css`
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100% !important;

  ${media.mediumS`
    gap: 0 !important;
    top: initial !important;
    left: initial !important;
    transform: none !important;
  `}
`

const StickyContainer = styled.div`
  ${StickyContainerStyle}
  ${media.mediumS`
    ${StickyWrapperStyle}
  `}
`

const StickyContainerWrapper = styled.div`
  ${StickyContainerWrapperStyle}
`

const StickyWrapper = styled.div`
  ${StickyWrapperStyle}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledTitle = styled(Title)`
  br {
    display: none;
  }

  ${media.mediumM`
    br {
    display: block;
    }
      
    text-align: center;
  `}
`

const StyledSubTitle = styled(SubTitle)`
  margin-top: 20px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: ${theme.colors.gray[500]};
  line-height: 1.5;

  span {
    color: ${theme.colors.gray[900]};
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 120px;
  position: relative;
  height: 560px;
  max-height: 560px;

  ${media.mediumM`
      flex-direction: column;
			gap: 60px;
  `}

  ${media.mediumS`
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 0px;
    max-height: 100vh;
  `}

  @media screen and (max-height: 540px) {
    position: initial;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 120px;
  position: relative;
  height: 560px;
  width: 100%;
  max-height: 560px;

  ${media.mediumS`
    flex-direction: column;
      gap: 0 !important;
      max-height: 500px;
      position: absolute;
      top: calc(50% - 45px);
      left: 50%;
      transform: translate(-50%, -50%);
  `}

  @media screen and (max-height: 540px) {
    height: auto;
  }
`

const Bar = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  background-color: #bfbfbf;
  height: 377px;

  ${media.mediumM`
      display: none;
  `}
`

const ContentStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 220px;
  margin-top: -47px;

  ${media.mediumM`	
    gap: 35px;
    margin-top: 0;
    border-left: 1px solid #bfbfbf;
  `}
`

const ContentStepBy = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 48px;
  opacity: 0.7;
  transition: opacity 0.8s cubic-bezier(0.33, 1, 0.68, 1);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 21px;
    width: 9px;
    height: 9px;
    border: 1px solid #7a7a7a;
    border-radius: 100%;
    margin-left: -53px;
    background-color: ${theme.colors.base.white};
    opacity: 1;

    ${media.mediumM`	
      margin-left: -70px;
    `}

    ${media.mediumS`	
      top: 0;
    `}
  }

  ${media.mediumM`	
      margin-left: 64px;
  `}

  &.active {
    opacity: 1;
    &::before {
      width: 11px;
      height: 11px;
      background-color: ${theme.colors.gray[900]};
      border: none;
    }
  }
`

const ContentStepByTitle = styled.div`
  font-size: 36px;
  font-weight: 500;
  line-height: 1.5;
  color: ${theme.colors.gray[400]};

  .active & {
    font-weight: 700;
    color: ${theme.colors.gray[900]};
  }

  ${media.mediumM`
      font-size: 30px;
  `}

  ${media.mediumS`
    margin-top: -12px;
  `}
`

const ContentStepByTitleSub = styled.div`
  display: none;

  .active & {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 12px;

    ${media.mediumM`
      font-size: 16px;
      margin-top: 8px;
    `}

    ${media.mediumS`
      color: ${theme.colors.gray[600]};
    `}
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  height: inherit;

  ${media.mediumM`
      width: 100%;
  `}

  ${media.mediumS`
      height: 300px;
      overflow: visible;
  `}

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s;

    ${media.mediumS`
      height: 300px;
    `}

    &.active {
      opacity: 1;
    }
  }
`

export default forwardRef(LandingStep)
