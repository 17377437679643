import { forwardRef, useCallback, useEffect } from 'react'
import useSaveAddressForSheet from 'lib/hooks/notev2/useSaveAddressForSheet'
import useModifyAddress from 'lib/hooks/notev2/useModifyAddress'
import { useSelector } from 'react-redux'
import { focusNextCell } from 'lib/utils/notev2/gridFocus'
import { message } from 'antd'

const CustomAddressCellListContainer = (
  {
    children,
    gridColumnApi,
    rowData,
    node,
    addresses,
    resetCellState,
    setInput,
    addressInput,
  },
  ref,
) => {
  const { gridRef, prevValue } = ref
  const { onSaveAddressForSheet, isError } = useSaveAddressForSheet()
  const [modifyAddress] = useModifyAddress()

  const channelInfo = useSelector((state) => state.channel.channelInfo)

  //저장중 에러 발생시
  useEffect(() => {
    if (!isError) return
    if (channelInfo?.member_type == 'readonly') return //읽기 권한 수정 불가

    message?.error('매물 저장에 실패했어요. 다시 시도해주세요.')

    //address 다 초기화
    resetCellState() //주소 검색 리스트 초기화하기
  }, [isError])

  /**
   * dropdown item 선택 행위시(클릭, 스페이스바, 엔터) 주소 저장
   */
  const onSaveAddress = useCallback(
    (validData) => {
      const address_id = validData.address_id
      if (rowData.note_srl == '신규') {
        //주소 추가
        onSaveAddressForSheet({
          channel_srl: channelInfo.channel_srl,
          address_id: address_id, //선택한 addressid
          currentRowNode: node,
          callbackFn: afterSaveAddr,
          validAddressInfo: validData,
        })
      } else {
        //주소 수정
        modifyAddress({
          channel_srl: rowData.channel_srl,
          address_srl: rowData.address_srl,
          note_srl: rowData.note_srl,
          address_id: address_id, //선택한 addressid
          currentRowNode: node,
          callbackFn: afterSaveAddr,
          validAddressInfo: validData,
          input_addr: addressInput,
        })
      }
    },
    [rowData, channelInfo, node, addresses],
  )

  const afterSaveAddr = () => {
    // * 주소 리스트 초기화는 노트 저장이 완전히 끝난 후 함!

    resetCellState() //주소 검색 리스트 초기화하기
    prevValue.current = `${node.data?.eupmyeondong || ''}${
      node.data?.display_address ? ` ${node.data.display_address}` : ''
    }`
    setInput(
      `${node.data?.eupmyeondong || ''}${
        node.data?.display_address
          ? ` ${node.data.display_address}`
          : ''
      }`,
    )
    focusNextCell(
      gridRef.current.api,
      gridColumnApi,
      node.data.row_key,
      'contract_type',
    )
  }

  return children({ onSaveAddress })
}

export default forwardRef(CustomAddressCellListContainer)
