import MapContext from 'contexts/map'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'
import useModifyNote from 'lib/hooks/notev2/useModifyNote'
import {
  isDonghoAreaUse,
  isFloorAreaUse,
  isLandAreaUse,
} from 'lib/utils/notev2/note2Constants'
import {
  CustomSingleCellInputWrapper,
  CustomSingleCellStyle,
  PlaceHolder,
} from 'components/jmapnotev2/style/NoteStyle'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import { numberWithCommas } from 'utils'
import {
  addEndCursorKeyEvent,
  addSelectAllKeyEvent,
  addStartCursorKeyEvent,
} from 'lib/utils/notev2/gridUtil'
import { ActiveCellStyle } from 'components/jmapnotev2/style/NoteGridStyle'
import { useSelector } from 'react-redux'
import useModifyAd from 'lib/hooks/notev2/useModifyAd'

/**
 * 그리드 셀 면적(1번째것)
 * @param type 광고면 ad
 */
const CustomAreaCell = ({
  node,
  contractInput,
  property_type,
  contract_area,
  dong,
  ho,
  total_floor_area,
  land_area,
  type,
}) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)
  const [onRequestModifyNote, isErrorModifyNote] = useModifyNote()
  const [onModifyAd] = useModifyAd()

  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
  } = useCustomSingleCell(node?.data?.status)

  const [area1, setArea1] = useState({
    area: 0,
    name: '',
    placeholder: '',
  })

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    setArea1(getInitArea1())
  }, [
    property_type,
    contract_area,
    dong,
    ho,
    total_floor_area,
    land_area,
  ])

  /**
   * 매물종류에 따라 첫번째 면적값(전용면적 or 연면적 or 대지면적) 세팅
   */
  const getInitArea1 = () => {
    let area
    let name
    let placeholder

    if (isDonghoAreaUse.includes(property_type)) {
      area = contract_area ? contract_area : 0
      name = 'contract_area'
      placeholder = ['오피스텔', '상가'].includes(property_type)
        ? '계약'
        : '공급'
    } else if (isFloorAreaUse.includes(property_type)) {
      //연면적
      //단독,다가구인 경우 동,호 데이터가 있으면 전용,연면적 보여줌
      if (
        (property_type == '다가구주택' ||
          property_type == '단독주택') &&
        (dong || ho)
      ) {
        area = contract_area ? contract_area : 0
        name = 'contract_area'
        placeholder = '공급'
      } else {
        area = total_floor_area ? total_floor_area : 0
        name = 'total_floor_area'
        placeholder = '연면적'
      }
    } else if (isLandAreaUse.includes(property_type)) {
      //대지
      area = land_area ? land_area : 0
      name = 'land_area'
      placeholder = '대지면적'
    } else if (!property_type) {
      //값이 없을때(사용자 임의의 주소)일 때 연면적
      area = total_floor_area ? total_floor_area : 0
      name = 'total_floor_area'
      placeholder = '연면적'
    }

    setIsLoading(false)
    return {
      area: area,
      name: name,
      placeholder: placeholder,
    }
  }

  const onChangeArea1 = useCallback(
    (e) => {
      setArea1((prev) => {
        return {
          ...prev,
          area: isSquareMeter
            ? Math.round(e.floatValue)
            : Math.round(e.floatValue * 3.3057),
        }
      })
    },

    [isSquareMeter],
  )

  const onBlurArea1 = useCallback(
    (e) => {
      const value = e.target.value
        .replaceAll(',', '')
        .replaceAll('평', '')
        .replaceAll('㎡', '')
      const valueParam = isSquareMeter
        ? value
        : ((value || 0) * 3.3057 * 100) / 100

      const name = e.target.name
      const newData = { [name]: valueParam }

      if (type == 'ad') {
        //TODO: 광고관리용 업뎃 api
        onModifyAd({
          data: node.data,
          updateData: newData,
          currentRowNode: node,
        })
      } else {
        onRequestModifyNote({
          data: node.data,
          updateData: newData,
          currentRowNode: node,
        })
      }

      setIsEditable(false)
    },
    [isSquareMeter, node.data],
  )

  const hasContractArea = useMemo(() => {
    return (
      isDonghoAreaUse.includes(property_type) ||
      ((property_type == '다가구주택' || property_type == '단독주택') &&
        (dong || ho))
    )
  }, [property_type])

  const onKeyDownInput = (e) => {
    addSelectAllKeyEvent(e)
    addStartCursorKeyEvent(e)
    addEndCursorKeyEvent(e)
  }

  const threeStepState = useSelector(
    (state) => state.onBoarding.threeStepState,
  )

  if (
    (type == 'ad' && node.data.ad_srl == '신규') ||
    (type != 'ad' && node.data.note_srl == '신규')
  )
    return <></>

  return (
    <Wrapper>
      {isLoading ? (
        <></>
      ) : isEditable ? (
        <>
          {/* 전용면적, 연면적, 대지면적 */}
          <CustomSingleCellInputWrapper
            ishalf={hasContractArea ? 'Y' : 'N'}
          >
            <AreaInput
              tabIndex={0}
              type="text"
              value={
                Number(area1.area)
                  ? isSquareMeter
                    ? Math.round(Number(area1.area))
                    : Math.round(Number(area1.area) / 3.3057)
                  : ''
              }
              onKeyDown={onKeyDownInput}
              name={area1.name}
              placeholder={area1.placeholder}
              onBlur={onBlurArea1}
              onValueChange={onChangeArea1}
              thousandSeparator={true}
              autoFocus={true}
            />
            <Append tabIndex={-1} ishalf={hasContractArea ? 'Y' : 'N'}>
              {isSquareMeter ? '㎡' : '평'}
            </Append>
          </CustomSingleCellInputWrapper>
          {hasContractArea && contractInput}
        </>
      ) : (
        <>
          {/* 조회모드 */}
          <FrontArea
            tabIndex={0}
            onDoubleClick={onDoubleClickSingleCell}
            onKeyDown={onKeyDownSingleCell}
            ishalf={hasContractArea ? 'Y' : 'N'}
          >
            <TextWrapper ishalf={hasContractArea ? 'Y' : 'N'}>
              {Number(area1.area) ? (
                isSquareMeter ? (
                  numberWithCommas(Math.round(Number(area1.area)))
                ) : (
                  numberWithCommas(
                    Math.round(Number(area1.area) / 3.3057),
                  )
                )
              ) : (
                <PlaceHolder>{area1.placeholder}</PlaceHolder>
              )}
              {Number(area1.area) ? (isSquareMeter ? '㎡' : '평') : ''}
            </TextWrapper>
          </FrontArea>
          {/* 전용, 계약면적 */}
          {hasContractArea && contractInput}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  ${({ threeStepState }) =>
    threeStepState == true &&
    `
  padding: 0;
  border: 5px solid transparent;
  background-image: linear-gradient(#fff, #fff),linear-gradient(to right, #00d8f8, #5a87ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
   `}
`

const FrontArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 2px;
  overflow: hidden;

  ${CustomSingleCellStyle}
  ${({ ishalf }) =>
    ishalf == 'Y' && `width: 50%;  justify-content: flex-end;`}
`

export const AreaItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ ishalf }) => ishalf == 'Y' && `width: 50%;`}
`

export const AreaInput = styled(NumericFormat)`
  width: 100%;
  height: 100%;
  padding: 0px 18px 0px 5px;
  font-size: 14px;
  line-height: 100%;
  background-color: transparent;

  ${CustomSingleCellStyle}
  ${ActiveCellStyle}

  &:placeholder {
    font-size: 14px;
  }
`

export const TextWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Append = styled.div`
  position: absolute;
  font-size: 14px;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
  right: ${({ ishalf }) => (ishalf == 'Y' ? 'calc(50% + 7px)' : '5px')};
`

export default React.memo(CustomAreaCell)
