import { accessibleStaffGroups } from 'Constants'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import styled from 'styled-components'
import { Button } from 'components/design'
import { useSelector } from 'react-redux'

const SummaryBuildingNohu = () => {
  const params = useParams()
  const history = useHistory()
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const checkShowNohuButton = () => {
    if (!building?.result || building.result.length == 0) {
      return false
    } else {
      //1. 단독주택, 다가구주택만
      const { building_name, purpose_code_name } = building?.result[0]
      const purpose = building_name + ' ' + purpose_code_name
      const conditions = ['단독주택', '다가구주택']

      //2. 20년 이상 건물만 가능
      if (!building.result[0].approval_date) return true

      const appDate = moment(building.result[0].approval_date)
      const now = moment(Date.now())
      const diff = appDate.diff(now, 'year')

      return (
        conditions.some((el) => purpose.includes(el)) &&
        Math.abs(diff) > 19
      )
    }
  }

  const onClickNohu = () => {
    history.push(`/${params?.id}/nohu`)
  }

  return (
    <>
      {loginInfo?.other_groups?.some((item) =>
        accessibleStaffGroups.includes(item),
      ) &&
        checkShowNohuButton() && (
          <NohuButton
            onClick={onClickNohu}
            expand
            plain
            color="primary"
            size="large"
          >
            노후주택 대수선 계획하기
          </NohuButton>
        )}
      {/* <a
      href={getJootekServiceURL('/estimate')}
      target={isMobile ? '_self' : '_blank'}
    >
      <Button size="large" color="primary" expand>
        <span>건축 예상 비용 확인하기</span>
      </Button>
    </a> */}
    </>
  )
}

const NohuButton = styled(Button)`
  margin-top: 20px;
`

export default SummaryBuildingNohu
