import Slider from 'components/common/Slider/Slider'
import MapFilterItem from './MapFilterItem'
import NoteFilterSelect from 'components/jmapnote/noteFilter/NoteFilterSelect'
import NoteFilterSelectOption from 'components/jmapnote/noteFilter/NoteFilterSelectOption'
import { getSelectedItemText } from 'lib/utils/filter'
import useMapFilter from 'containers/map/hooks/useMapFilter'
import { useSelector } from 'react-redux'
import MapFilterLandType from './MapFilterLandType'
import { Input } from 'components/design'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

const MapFilterLand = ({}) => {
  const { filterState, handleEndSliderLayer, setFilterState } =
    useMapFilter()

  const filterOptions = useSelector((state) => state.filter.options)

  const onChangeUseArea = (value) => {
    return setFilterState((prev) => {
      return {
        ...prev,
        land: {
          ...prev.land,
          use_area: value,
        },
      }
    })
  }

  const onChangeLandType = (value) => {
    return setFilterState((prev) => {
      return {
        ...prev,
        land: {
          ...prev.land,
          land_type: value,
        },
      }
    })
  }

  const useAreaList = filterOptions.use_area
  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value)
  }

  // const onBlurUnitPrices =(e)=>{
  //   setFilterState((prev) => {
  //     //co
  //     return {
  //       ...prev,
  //       land: {
  //         ...prev.land,
  //         unit_prices: value,
  //       },
  //     }
  //   })
  // }

  return (
    <>
      <MapFilterItem
        title="대지면적"
        titleValue={
          filterState.land.land_area.toString() === '0,300'
            ? '전체'
            : filterState.land.land_area
                .map((value, index) => {
                  if (index === 0 && value === 0) return ''
                  if (index === 1 && value === 300) return ''

                  return Math.floor(value) + '평'
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <Slider
          range={{ min: 0, max: 300 }}
          start={filterState.land.land_area}
          step={10}
          margin={10}
          connect
          behaviour="snap"
          pips={{
            mode: 'positions',
            values: [0, 25, 50, 75, 100],
            density: 10,
            format: {
              to: (value) => {
                if (value === 0) return '~10평'
                if (value === 300) return '300평~'
                return value + '평'
              },
            },
          }}
          onEnd={handleEndSliderLayer('land', 'land_area')}
        />
      </MapFilterItem>
      {/* <MapFilterItem
        title="개별공시지가"
        titleValue={
          filterState.land.unit_prices.toString() === '0,50'
            ? '전체'
            : filterState.land.unit_prices
                .map((value, index) => {
                  if (index === 0 && value === 0) return ''
                  if (index === 1 && value === 50) return ''

                  if (value > 0 && value < 10) return value + '백'
                  if (value >= 10 && value < 50)
                    return value / 10 + '천'
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <UnitPricesWrapper>
          <StyledInputWrapper>
            <StyledInput onBlur={onBlurUnitPrices}/>
            <Append>원</Append>
          </StyledInputWrapper>
          ~
          <StyledInputWrapper>
            <StyledInput  onBlur={onBlurUnitPrices} />
            <Append>원</Append>
          </StyledInputWrapper>
        </UnitPricesWrapper>
         <Slider
          range={{ min: 0, max: 50 }}
          start={filterState.land.unit_prices}
          step={5}
          margin={5}
          connect
          behaviour="snap"
          pips={{
            mode: 'positions',
            values: [0, 50, 100],
            density: 10,
            format: {
              to: (value) => {
                if (value === 0) return '1백 이하'
                if (value === 50) return '5천 이상'

                if (value > 0 && value < 10) return value + '백'
                if (value >= 10 && value < 50) return value / 10 + '천'
              },
            },
          }}
          onEnd={handleEndSliderLayer('land', 'unit_prices')}
        /> 
      </MapFilterItem> */}
      <MapFilterLandType
        onChangeLandType={onChangeLandType}
        land_type={filterState.land.land_type}
      />
      {/* <MapFilterItem
        title="지목"
        titleValue={getSelectedItemText(filterState.land.land_type)}
      >
        <NoteFilterSelect>
          <NoteFilterSelectOption
            value="all"
            column={4}
            center={true}
            group={filterState.land.land_type || []}
            onChangeGroup={onChangeLandType}
          >
            전체
          </NoteFilterSelectOption>
          {landTypeList.map((item, i) => {
            return (
              <NoteFilterSelectOption
                key={i}
                value={item}
                column={4}
                center={true}
                group={filterState.land.land_type || []}
                onChangeGroup={onChangeLandType}
              >
                {item.length > 4 ? (
                  <>
                    {item.slice(0, 3)}
                    <br />
                    {item.slice(3, 5)}
                  </>
                ) : (
                  item
                )}
              </NoteFilterSelectOption>
            )
          })}
        </NoteFilterSelect>
      </MapFilterItem> */}
      <MapFilterItem
        title="용도지역"
        titleValue={getSelectedItemText(filterState.land.use_area)}
      >
        <NoteFilterSelect>
          <NoteFilterSelectOption
            value="all"
            column={3}
            center={true}
            group={filterState.land.use_area || []}
            onChangeGroup={onChangeUseArea}
          >
            전체
          </NoteFilterSelectOption>
          {Object.values(useAreaList).map((item, i) => {
            return (
              <NoteFilterSelectOption
                key={i}
                value={getKeyByValue(useAreaList, item)}
                //value={item}
                column={3}
                center={true}
                group={filterState.land.use_area || []}
                onChangeGroup={onChangeUseArea}
              >
                {item}
              </NoteFilterSelectOption>
            )
          })}
        </NoteFilterSelect>
      </MapFilterItem>
    </>
  )
}

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  position: relative;
  display: inline-flex;
  align-items: center;
  appearance: none;
  box-sizing: border-box;
`

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  font-weight: 500;
  border-radius: 0;
  background-color: transparent;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border: none;
  box-shadow: none;

  ${({ width }) =>
    width &&
    `
  width:${width}px;
  `}

  ${({ height }) =>
    height &&
    `
  height:${height}px;
  `}
color: ${theme.colors.gray[900]};

  &::placeholder {
    font-weight: 400;
    color: ${theme.colors.gray[500]};
  }

  ${media.mediumS`
  width: 100%;
  padding: 0 4px 0 6px;
`}
`

const Append = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  color: ${theme.colors.gray[400]};
  font-weight: 400;
  white-space: nowrap;

  ${media.mediumS`
    padding-left: 0;
    padding-right: 4px;
    font-size: 13px;
  `}
`

const UnitPricesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export default MapFilterLand
