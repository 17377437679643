import MapFilterItem from 'components/map/MapFilter/MapFilterItem'
import Slider from 'components/common/Slider/Slider'
import { useSelector } from 'react-redux'

const NoteFilterArea = ({ setSelectedArea }) => {
  const filterArea = useSelector(
    (state) => state.note.filterStates.area,
  )
  return (
    <>
      <MapFilterItem
        title="면적"
        titleValue={
          filterArea.toString() === '0,70'
            ? '전체'
            : filterArea
                .map((value, index) => {
                  const area = value
                  if (index === 0 && area === 0) return ''
                  if (index === 1 && area === 70) return ''

                  return Math.floor(area) + '평'
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <Slider
          range={{ min: 0, max: 70 }}
          start={filterArea}
          step={5}
          margin={10}
          connect
          behaviour="snap"
          pips={{
            mode: 'positions',
            values: [0, 50, 100],
            density: 7,
            format: {
              to: (value) => {
                if (value === 0) return '10평 이하'
                if (value === 70) return value + '평 이상'
                return value + '평'
              },
            },
          }}
          // onChange={(render, handle, value, un, percent) => {
          //   console.log('handle: ', handle)
          //   console.log('value: ', value)
          // }}
          onEnd={(render, handle, value, un, percent) => {
            setSelectedArea(value.map((item) => item))
          }}
        />
        {/* <br />
        <NoteFilterSelect>
          {[
            '~10평',
            '10평대',
            '20평대',
            '30평대',
            '40평대',
            '50평대',
            '60평대',
            '70평~',
          ].map((item, i) => {
            return (
              <NoteFilterSelectOption
                key={i}
                value={item}
                group={filterArea}
                center={true}
                // color={getColors(item)}
                onChangeGroup={(value) => console.log(value)}
              >
                {item}
              </NoteFilterSelectOption>
            )
          })}
        </NoteFilterSelect> */}
      </MapFilterItem>
    </>
  )
}

export default NoteFilterArea
