import { useEffect, useState } from 'react'
import { billionFormate, numberWithCommas } from 'utils'
import theme from 'lib/styles/theme'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'components/design'
import styled from 'styled-components'
import SummaryHcPriceTable from './SummaryHcPriceTable'

const SummaryHcPrice = ({ data, id }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const getPrice = (price) => {
    const calcPrice = price * 3.3058

    return numberWithCommas(billionFormate(calcPrice))
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [data])

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          평균가격<SubTitle>(평당)</SubTitle>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <PriceWrap>
          <PriceItem>
            <PriceTitle>토지 추정가</PriceTitle>
            <PriceValue>
              {data?.estimate_price_mean
                ? `${getPrice(data?.estimate_price_mean)}원`
                : '-'}
            </PriceValue>
          </PriceItem>
          <PriceItem>
            <PriceTitle>개별공시지가</PriceTitle>
            <PriceValue>
              {data?.unit_price_mean
                ? `${getPrice(data?.unit_price_mean)}원`
                : '-'}
            </PriceValue>
          </PriceItem>
        </PriceWrap>
        <SummaryHcPriceTable
          id={id}
          data={data?.purchases}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CardBody>
    </Card>
  )
}

const SubTitle = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  margin-left: 3px;
`

const PriceItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const PriceTitle = styled.div`
  font-size: 1rem;
  width: 30%;
`

const PriceValue = styled.div`
  font-size: 1.5rem;
  color: ${theme.colors.primary[500]};
  font-weight: 700;
`

const PriceWrap = styled.div`
  margin-bottom: 1.5rem;
`

export default SummaryHcPrice
