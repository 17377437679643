import theme from 'lib/styles/theme'
import styled from 'styled-components'

const DotIcon = ({ icon, title, content, dot = true }) => {
  return (
    <Wrapper>
      <IconListIcon dot={dot}>{icon}</IconListIcon>
      <IconListLabel>{title}</IconListLabel>
      <IconListValue>{content}</IconListValue>
    </Wrapper>
  )
}

const Wrapper = styled.li`
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  text-align: center;

  &:last-child {
    border-right: none;
  }
`

const IconListIcon = styled.div`
  position: relative;
  color: ${theme.colors.gray[700]};
  font-size: 1.5rem;
  height: 100%;

  padding-top: 3px;

  & > i,
  svg {
    position: relative;
    z-index: 1;
  }

  & > svg {
    width: 32px;
    height: 32px;
  }

  &::before {
    ${({ dot }) =>
      dot &&
      `
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 0.25rem;

      width: 0.75rem;
      height: 0.75rem;
      border-radius: 100%;
      background-color: ${theme.colors.primary[200]};
    `}
  }
`

const IconListLabel = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
`

const IconListValue = styled.div`
  font-weight: 600;
  white-space: pre-line;
  font-size: 0.875;
  span {
    display: block;
  }
`

export default DotIcon
