import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { Button } from 'components/design'
import { HiOutlineChevronUp } from 'react-icons/hi'

const CardFold = ({ className, visible = true, onClick = null }) => {
  if (!visible) return <Empty />

  return (
    <StyledMore expand className={className} onClick={onClick}>
      <HiOutlineChevronUp />
      접기
    </StyledMore>
  )
}

const StyledMore = styled(Button)`
  margin-top: 16px;
  font-weight: 500;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    color: ${theme.colors.gray[500]};
    transform: 0.2s ease;
  }

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
`

const Empty = styled.div`
  padding-bottom: 2rem;
`

export default CardFold
