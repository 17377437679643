import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { removeFavorite } from 'lib/api/favoriteApi'

import { setSummaryInfo } from 'modules/summary'
import useAxios from 'lib/hooks/useAxios'

export default function useRemoveFavorite() {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const dispatch = useDispatch()

  const [onRequest, loading, data, error, reset] =
    useAxios(removeFavorite)

  useEffect(() => {
    if (data?.message === 'success') {
      dispatch(
        setSummaryInfo({
          ...summaryInfo,
          favorite: null,
        }),
      )
    }
  }, [data])

  const _removeFavorite = useCallback(() => {
    onRequest({ favorite_id: summaryInfo.favorite?.favorite_id })
  }, [onRequest, summaryInfo?.favorite])

  return { _removeFavorite }
}
