import { useContext } from 'react'
import { ListItem } from 'components/design'
import { Toggle } from 'components/design'
import { ListItemLabel } from 'components/design'
import { ListItemDesc } from 'components/design'
import MapContext from 'contexts/map'

const MapLayerListItem = ({
  type,
  title,
  desc,
  onChange,
  isLast,
  children,
  renderless,
}) => {
  const {
    state: { layerState },
    actions: { setLayerState },
  } = useContext(MapContext)

  const toggleLayerState = (type) => {
    if (onChange) {
      onChange()
      return
    }

    setLayerState((prev) => {
      const result = { ...prev }
      result[type] = result[type] ? false : true
      return result
    })
  }

  if (renderless)
    return children({
      checked: layerState[type],
      handleChange: () => toggleLayerState(type),
    })

  return (
    <ListItem
      noContainer
      hiddenDivider
      margin={isLast ? '0' : true}
      prepend={
        <Toggle
          checked={layerState[type]}
          onChange={() => toggleLayerState(type)}
        />
      }
    >
      <ListItemLabel small onClick={() => toggleLayerState(type)}>
        {title}
        {children}
      </ListItemLabel>
      <ListItemDesc xs onClick={() => toggleLayerState(type)}>
        {desc}
      </ListItemDesc>
    </ListItem>
  )
}

export default MapLayerListItem
