import theme from 'lib/styles/theme'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { ko } from 'date-fns/esm/locale'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as CalendarIcon } from 'assets/icon/note/detail/calendar.svg'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { getYear, getMonth } from 'date-fns' // getYear, getMonth

import 'react-datepicker/dist/react-datepicker.css'
import { StyledInput } from './NoteInput'

const NoteInputDate = (
  {
    width,
    height,
    prepend,
    placeholder,
    value,
    onChange,
    onBlur,
    datasetKey = '',
    tail = false,
    type = 'number',
    defaultValue,
    selected,
    readOnly,
    expireDate,
    disabled,
    white,
  },
  ref,
) => {
  const [isOpen, setIsOpen] = useState(false)

  const years = _.range(
    1990,
    expireDate ? getYear(new Date()) + 50 : getYear(new Date()) + 1,
    1,
  ) // 수정
  const months = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ]

  const onChangeDate = useCallback((date, e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault()
    }

    setIsOpen(false)
    onChange(date)
  }, [])

  const onClickClearDate = useCallback((e) => {
    e.preventDefault()

    setIsOpen(false)

    function getParents(elem) {
      var parents = []
      while (
        elem.parentNode &&
        elem.parentNode.nodeName.toLowerCase() != 'body'
      ) {
        elem = elem.parentNode
        parents.push(elem)
      }
      return parents
    }
    const parents = getParents(e.target)

    const labelTag = parents.find((elem) => {
      return elem.tagName == 'LABEL'
    })

    if (
      !labelTag ||
      !labelTag.querySelector('.react-datepicker__close-icon')
    )
      return

    labelTag.querySelector('.react-datepicker__close-icon').click()
  }, [])

  /**
   * 다음 element focus
   * @param {*} e
   */
  const focusNextElement = (e) => {
    const focussableElements =
      '#fixed-layer button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'
    if (document.activeElement) {
      var focussable = Array.prototype.filter.call(
        document.querySelectorAll(focussableElements),
        function (element) {
          return (
            element.offsetWidth > 0 ||
            element.offsetHeight > 0 ||
            element === document.activeElement
          )
        },
      )
      const index = focussable.indexOf(e.target)

      if (index > -1) {
        var nextElement = focussable[index + 1] || focussable[0]
        nextElement.focus()
      }
    }
  }

  /**
   * 오른쪽 화살표 마지막 focus blur
   */
  const onDatePickerEndFocus = useCallback((e) => {
    if (e.key == 'Tab') {
      focusNextElement(e)
      setIsOpen(false)
    }
  }, [])

  return (
    <CalendarWrapper tabIndex={-1}>
      {disabled ? (
        <StyledInput defaultValue={value} disabled={disabled} />
      ) : (
        <>
          <StyledInput
            white={white}
            ref={ref}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <DatePickerCustomHeader
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Arrow
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <i className="fa fa-angle-left" />
                </Arrow>
                <DatePickerSelectWrapper>
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(value)
                    }
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </DatePickerSelectWrapper>

                <Arrow
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  onKeyDown={onDatePickerEndFocus}
                >
                  <i className="fa fa-angle-right" />
                </Arrow>
              </DatePickerCustomHeader>
            )}
            // className={className}
            open={isOpen}
            onFocus={() => setIsOpen(true)}
            width={width}
            height={height}
            value={value}
            onBlur={onBlur}
            placeholderText={placeholder}
            type={type}
            tail={tail ? 1 : 0}
            defaultValue={defaultValue}
            data-key={datasetKey}
            hasprepend={prepend ? 'Y' : 'N'}
            as={DatePicker}
            locale={ko}
            dateFormat="yyyy.MM.dd"
            shouldCloseOnSelect={true}
            onChange={onChangeDate}
            selected={selected || new Date()}
            readOnly={readOnly}
            onClickOutside={() => setIsOpen(false)}
            isClearable
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onClick={() => setIsOpen(true)}
          >
            <ResetCalendar
              size="xsmall"
              color="gray"
              onClick={onClickClearDate}
              tabIndex={-1}
            >
              날짜 지우기
            </ResetCalendar>
          </StyledInput>
          <CalendarIcon width={14} height={16} tabIndex={-1} />
        </>
      )}
    </CalendarWrapper>
  )
}

const CalendarWrapper = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 8px;
  svg {
    flex-shrink: 0;
  }

  .react-datepicker__close-icon {
    display: none;
  }

  .react-datepicker {
    border: 1px solid ${theme.colors.gray[300]};
  }

  .react-datepicker__header {
    background-color: ${theme.colors.gray[50]};
    border: 1px solid ${theme.colors.gray[200]};
    padding-top: 1px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`

const DatePickerCustomHeader = styled.div`
  display: flex;
  justify-content: space-between !important;

  select {
    padding: 4px;
    font-size: 14px;
    border: 1px solid ${theme.colors.gray[200]};
    border-radius: 8px;
  }

  button {
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
    color: ${theme.colors.gray[600]};

    &:focus,
    &:focus-within {
      outline: 2px solid ${theme.colors.gray[900]};
      border-radius: 8px;
    }
  }
`

const DatePickerSelectWrapper = styled.div`
  display: flex;
  gap: 6px;
`

const ResetCalendar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  font-size: 0.14px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  text-align: right;
  float: right;
  clear: both;
  margin-bottom: 10px;
  font-size: 12px;
  margin-right: -6px;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
  height: 24px;
  padding-left: 8px;
  font-size: 12px;
  padding-right: 8px;
  &:focus,
  &:focus-within {
    border: 1px solid;
  }
`

const Arrow = styled.div`
  align-self: center;
  font-size: 16px;
  color: ${theme.colors.gray[700]};
  padding: 0 4px;
  cursor: pointer;
`

export default forwardRef(NoteInputDate)
