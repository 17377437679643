import theme from 'lib/styles/theme'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import HomeQuickMenuItem from './HomeQuickMenuItem'
import HomeNoteLink from './HomeNoteLink'
import HomeFavoriteLink from './HomeFavoriteLink'
import { ReactComponent as HousingComlexIcon } from 'assets/icon/home-housing-complex.svg'
import MapContext from 'contexts/map'
import { useContext, useEffect } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { getLayerHousingComplexByCenterpoint } from 'lib/api/layerApi'
import { notification } from 'antd'
import HomeDonghoLink from './HomeDonghoLink'
import { useSelector } from 'react-redux'
import { accessibleStaffGroups } from 'Constants'

const HomeQuickMenu = () => {
  const history = useHistory()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const { state, actions } = useContext(MapContext)
  const memberType =
    JSON.parse(localStorage.getItem('memberType')) || ''

  const { layerState, viewState, map } = state

  const [onRequestCenter, , dataCenter, , ,] = useAxios(
    getLayerHousingComplexByCenterpoint,
  )

  useEffect(() => {
    if (Object.keys(map).length === 0) return
    if (!dataCenter || !dataCenter.result) return
    if (Object.keys(dataCenter.result).length === 0) {
      notification.info({
        message: `알림`,
        description:
          '현재 주택단지는 대전 / 세종 지역에서만 서비스 되고 있습니다.',
      })
      //실거래가 마커 끄기
      actions.setLayerState((prev) => {
        return {
          ...prev,
          realPriceActive: true,
        }
      })
      return
    }

    const result = dataCenter.result

    const nearDanjiCenter = result[Object.keys(result)[0]].centerpoint

    const locPosition = new kakao.maps.LatLng(
      nearDanjiCenter.coordinates[0],
      nearDanjiCenter.coordinates[1],
    )

    map.panTo(locPosition)
  }, [dataCenter])

  return (
    <Wrapper>
      {memberType === 'realestate' && <HomeNoteLink />}
      {process.env.NODE_ENV === 'development' && (
        <HomeQuickMenuItem
          as="button"
          newBadge="true"
          onClick={() => {
            //가장 가까운 단지로 이동
            if (!layerState.housingComplexActive) {
              onRequestCenter({
                latitude: map.getCenter().getLat(),
                longitude: map.getCenter().getLng(),
              })
            }

            //실거래가 마커 끄기
            actions.setLayerState((prev) => {
              return {
                ...prev,
                realPriceActive: false,
                housingComplexActive: true,
              }
            })
          }}
          icon={<HousingComlexIcon />}
        >
          주택단지
        </HomeQuickMenuItem>
      )}
      {loginInfo?.member_type === 'realestate' && (
        <HomeDonghoLink as="button" />
      )}
      <HomeFavoriteLink
        as="button"
        newBadge={
          loginInfo?.member_type === 'realestate' ? 'true' : 'false'
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
`

export default HomeQuickMenu
