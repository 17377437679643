import { getDistance } from 'lib/utils/summary'
import SummarySchoolListItem from './SummarySchoolListItem'
import { ReactComponent as ListIcon } from 'assets/icon/amenities/kindergarten_info.svg'
import { Wrapper } from '../SummaryAmenities/SummaryAmenitiesList'
import { useState } from 'react'
import { CardMore } from 'components/design'

const SummarySchoolList = ({
  data,
  prevMarker,
  setPrevMarker,
  activeValue,
  onClickSetActiveValue,
  resetActiveValue,
}) => {
  const [viewCnt, setViewCnt] = useState(4)
  const viewMore = data.length > 4 && viewCnt < data.length

  /**
   * 더보기
   */
  const onClickMore = () => {
    setViewCnt((prev) => prev + 4)
  }

  return (
    <Wrapper viewcnt={viewCnt} viewmore={viewMore ? 'Y' : 'N'}>
      {data.map((item, i) => (
        <SummarySchoolListItem
          key={i}
          icon={<ListIcon />}
          name={item.name || item.school_name}
          distance={getDistance(item.distance)}
          centerpoint={item.centerpoint}
          prevMarker={prevMarker}
          setPrevMarker={setPrevMarker}
          activeValue={activeValue}
          onClickSetActiveValue={onClickSetActiveValue}
          resetActiveValue={resetActiveValue}
        />
      ))}
      {viewMore && <CardMore onClick={onClickMore} />}
    </Wrapper>
  )
}

export default SummarySchoolList
