import axios from 'axios'

export const getChannels = async ({ _rx_csrf_token }, options) =>
  await axios.post(
    `/jmapnote/api/index`,
    {
      _rx_csrf_token,
    },
    options,
  )
export const getChannel = async (
  { _rx_csrf_token, channelSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/channel/getChannel',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
    },
    options,
  )

export const saveChannel = async (
  { _rx_csrf_token, name, description },
  options,
) =>
  await axios.post(
    '/jmapnote/api/channel/save',
    {
      _rx_csrf_token,
      name,
      description,
    },
    options,
  )

export const modifyChannel = async (
  { _rx_csrf_token, channelSrl, name, description },
  options,
) =>
  await axios.post(
    '/jmapnote/api/channel/modify',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      name,
      description,
    },
    options,
  )

export const removeChannel = async (
  { _rx_csrf_token, channelSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/channel/remove',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
    },
    options,
  )

export const getChannelUsers = async (
  { _rx_csrf_token, channelSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/channelUser/getChannelUsers',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
    },
    options,
  )

export const saveChannelUser = async (
  { _rx_csrf_token, channelSrl, phoneNumber, memberType },
  options,
) =>
  await axios.post(
    '/jmapnote/api/channelUser/save',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      phone_number: phoneNumber,
      member_type: memberType,
    },
    options,
  )

export const modifyChannelUser = async (
  { _rx_csrf_token, channelSrl, memberSrl, phoneNumber, memberType },
  options,
) =>
  await axios.post(
    '/jmapnote/api/channelUser/modify',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      member_srl: memberSrl,
      phone_number: phoneNumber,
      member_type: memberType,
    },
    options,
  )

export const modifyChannelUserStatus = async (
  { _rx_csrf_token, channelSrl, status, notify },
  options,
) =>
  await axios.post(
    '/jmapnote/api/channelUser/modifyStatus',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      status,
      notify,
    },
    options,
  )
