import { useEffect, useRef } from 'react'

const Addr3dshape = ({ data }) => {
  const shapeRef = useRef(null)

  useEffect(() => {
    if (shapeRef.current) {
      window.addr3dshape(shapeRef.current, data)
    }
  }, [shapeRef, data])

  return (
    <canvas
      className="shape"
      ref={shapeRef}
      style={{ width: '100%', height: '270px' }}
    ></canvas>
  )
}

export default Addr3dshape
