import axios from 'axios'

export const getBuildingLandByNoteSrl = async (
  { _rx_csrf_token, note_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/getBuildingLandByNoteSrl',
    {
      _rx_csrf_token,
      note_srl,
    },
    options,
  )
