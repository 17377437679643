import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const NoteDashboardWelcomeTitle = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  return (
    <>
      <PositionTag>
        {loginInfo?.realtor_position == '대표'
          ? '대표중개사'
          : loginInfo?.realtor_position}
      </PositionTag>
      <Title>{loginInfo?.user_name}님 환영합니다</Title>
    </>
  )
}

const PositionTag = styled.div`
  width: fit-content;
  padding: 8px;
  border-radius: 999px;
  background-color: ${theme.colors.blue[50]};
  color: ${theme.colors.blue[400]};
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 6px;
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 30px;
`

export default NoteDashboardWelcomeTitle
