import {
  Card,
  CardBody,
  CardHeader,
  CardMore,
  CardTitle,
  Empty,
} from 'components/design'
import CardFold from 'components/design/Card/CardFold'
import SummaryGovLandPriceList from 'components/summary/SummaryGovLandPrice/SummaryGovLandPriceList'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import useViewMore from 'lib/hooks/useViewMore'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 최근 공시지가
 *
 * * 렌더링 여부
 * * 공동주택: O
 * * 공동주택 아님 : O
 */
const SummaryGovLandPriceContainer = () => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const [data, setData] = useState(null)

  const {
    viewCnt,
    viewMore,
    isOverflow,
    setViewMore,
    updateViewOverFlow,
    onClickMore,
    onClickFold,
  } = useViewMore()

  useEffect(() => {
    if (!summaryInfo || !summaryInfo?.prices) return

    const address_history = summaryInfo.prices?.chart?.address_history
    setData(address_history)
    setViewMore(address_history.length > 3)
  }, [summaryInfo.prices])

  useUpdateEffect(() => {
    updateViewOverFlow(data)
  }, [viewCnt])

  return (
    <Card>
      <CardHeader>
        <CardTitle>최근 공시지가</CardTitle>
      </CardHeader>
      <CardBody>
        {!data ||
          (data.length == 0 && (
            <Empty>공시지가 데이터가 없습니다</Empty>
          ))}
        {data && data.length > 0 && (
          <>
            <SummaryGovLandPriceList data={data} viewCnt={viewCnt} />
            {viewMore && <CardMore onClick={onClickMore} />}
            {isOverflow && <CardFold onClick={onClickFold} />}
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default SummaryGovLandPriceContainer
