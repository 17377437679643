import { useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 1뎁스 summary 탭 class 변경 감지 & active
 * @param {any} tab
 */
function useMutationObserve(tabs, className, isSummary) {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const [activeIdx, setActiveIdx] = useState(0)

  const observeSection = () => {
    if (isSummary && !summaryInfo) return
    //class 변경 감지
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.attributeName == 'class') {
          let activeTarget =
            document.getElementsByClassName(className)?.[0]
          if (!activeTarget) return

          const index = Array.from(tabs).findIndex(
            ({ element }) => element.current == activeTarget,
          )

          setActiveIdx(index)
        }
      })
    })

    //등록한 탭 dom target에 대해서만 감시함
    Array.from(tabs).forEach((item) => {
      const target = item.element.current
      if (!target) return
      observer.observe(target, { attributes: true })
    })
  }

  return { observeSection, activeIdx }
}

export default useMutationObserve
