import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { Button } from 'components/design'
import { Dropdown } from 'components/design'
import { DropdownDialog } from 'components/design'
import { DropdownHeader } from 'components/design'
import ChannelSaveModal from 'components/jmapnote/channel/ChannelSave'
import ChannelSelectList from 'components/jmapnote/channel/ChannelSelectList'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useNoteMarker } from 'lib/hooks/useNoteMarker'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom'

const ChannelSelect = ({
  onChange,
  topPos,
  hideCreate = false,
  fixed,
}) => {
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const channels = useSelector((state) => state.channel.channels)
  const match = useRouteMatch('/note/v2/notes/:channelSrl')

  const [saveVisible, setSaveVisible] = useState(false)
  const [channelSrl, setChannelSrl] = useState(
    match?.params.channelSrl ? match.params.channelSrl : null,
  )

  const [visible, setVisible] = useState(false)

  const { searchNoteMarkers } = useNoteMarker()

  useEffect(() => {
    if (!channelInfo || channelInfo.length == 0) return
    setChannelSrl(channelInfo.channel_srl)
  }, [channelInfo])

  const isMobile = useIsMobile()

  /**
   * 드롭다운 on off
   */
  const toggleDropdown = (e) => {
    setVisible((prev) => !prev)
  }

  /**
   * 채널 클릭 이벤트
   * @param {*} channelSrl
   */
  const onChangeChannel = (channelSrl, folderSrl) => {
    setVisible(false)
    onChange(channelSrl, folderSrl)
    searchNoteMarkers()
  }

  /**
   * 선택된 채널 정보
   * @param {String} channelSrl
   * @param {Array} channels
   * @returns
   */
  const getSelectedChannelInfo = (channelSrl, channels) => {
    return channels?.find((e) => channelSrl == e?.channel_srl)
  }
  const selectedChannelInfo = useMemo(
    () => getSelectedChannelInfo(channelSrl, channels),
    [channelSrl, channels],
  )

  /**
   * 내가 생성한 채널 목록
   * @param {Array} channels
   * @param {Object || null} loginInfo
   * @returns
   */
  const getMyChannels = (channels, loginInfo) => {
    return channels.filter((e) => e.member_srl === loginInfo.member_srl)
  }
  const myChannels = useMemo(
    () => getMyChannels(channels, loginInfo),
    [channels, loginInfo],
  )

  /**
   * 초대된 채널목록
   * @param {Array} channels
   * @param {Object || null} loginInfo
   * @returns
   */
  const getInvitedChannels = (channels, loginInfo) => {
    return channels.filter((e) => e.member_srl !== loginInfo.member_srl)
  }
  const invitedChannels = useMemo(
    () => getInvitedChannels(channels, loginInfo),
    [channels, loginInfo],
  )

  const ref = useRef()
  useOnClickOutside(ref, () => {
    if (!isMobile) {
      setVisible(false)
    }
  })

  return (
    <StyledSelectWrap ref={ref}>
      <Dropdown
        topPos={topPos}
        visible={visible}
        onClose={() => setVisible(false)}
        fixed={fixed}
        overlay={
          <DropdownDialog
            width={250}
            height={400}
            header={<DropdownHeader>채널 선택</DropdownHeader>}
            footer={
              hideCreate ? null : (
                <Button
                  expand
                  color="primary"
                  plain
                  onClick={() => setSaveVisible(true)}
                >
                  채널 생성하기
                </Button>
              )
            }
          >
            {myChannels.length > 0 && (
              <ChannelSelectList
                title="내 채널"
                channels={myChannels}
                selectedChannelSrl={channelSrl}
                onChange={onChangeChannel}
                loginInfo={loginInfo}
              />
            )}

            {invitedChannels.length > 0 && (
              <ChannelSelectList
                title="초대된 채널"
                channels={invitedChannels}
                selectedChannelSrl={channelSrl}
                onChange={onChangeChannel}
              />
            )}
          </DropdownDialog>
        }
      >
        <StyledSelectButton
          className="channel-select-label"
          onClick={toggleDropdown}
          active={visible}
        >
          <SelectedChannelName>
            {selectedChannelInfo?.name}
          </SelectedChannelName>
          {selectedChannelInfo?.name && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )}
        </StyledSelectButton>
      </Dropdown>

      <ChannelSaveModal
        visible={saveVisible}
        setVisible={setSaveVisible}
      />
    </StyledSelectWrap>
  )
}

const StyledSelectWrap = styled.div`
  position: relative;
`
const StyledSelectButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  color: ${theme.colors.gray[900]};
  font-size: 1.125rem;
  font-weight: 700;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;

  & > svg {
    width: 18px;
    height: 18px;
    margin-left: 0.25rem;
    transition: 0.2s ease;
  }

  ${({ active }) =>
    active &&
    `
    border-color: ${theme.colors.gray[400]};
    & > svg {
      transform: rotate(180deg);
    }
  `}

  ${media.mediumS`
    font-size: 1.125rem;
  `}
`

const SelectedChannelName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
`

export default ChannelSelect
