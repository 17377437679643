import { useContext } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import MapContext from 'contexts/map'
import { getComputedArea } from 'utils'

import { Tag } from 'components/design'
import { ListItem } from 'components/design'
import PropertyType from 'components/jmapnote/noteList/PropertyType'
import { ListItemLabel } from 'components/design'
import { ListItemDivider } from 'components/design'

const StyledListItem = styled.li`
  padding: 0.75rem 1rem;
  margin-bottom: 0.75rem;
  background-color: ${theme.colors.base.white};
  /* box-shadow: ${theme.shadow['card-1']}; */
  border-radius: ${theme.borderRadius[1]};
  border: 1px solid ${theme.colors.gray[200]};

  /* border-bottom: 1px solid ${theme.colors.gray[200]}; */

  &:first-child {
    /* padding-top: 0; */
  }

  &:last-child {
    margin-bottom: 0;
    /* border-bottom: none; */
  }
`

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`
const Price = styled.div`
  font-weight: 700;
  margin-left: 0.5rem;
`
const UnitPrice = styled.span`
  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
  margin-left: 0.25rem;
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  color: ${theme.colors.gray[600]};
  font-size: 0.75rem;
`

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
`
const MetaItemLabel = styled.div`
  color: ${theme.colors.gray[800]};
  font-weight: 700;
  margin-right: 0.25rem;
`
const MetaItemValue = styled.div``

const RealPriceTableItem = ({ item }) => {
  const { state } = useContext(MapContext)

  return (
    <ListItem
      prepend={<PropertyType propertyType={item.type} />}
      noContainer
      dense
    >
      <ListItemLabel>
        <span>{item.price}</span>
        <UnitPrice>
          ({state.isSquareMeter ? '㎡당' : '평당'} {item.unit_price})
        </UnitPrice>
      </ListItemLabel>

      <ListItemDivider xs />

      <Meta>
        <MetaItem>
          <MetaItemLabel>거래일</MetaItemLabel>
          <MetaItemValue>{item.year}</MetaItemValue>
        </MetaItem>

        <MetaItem>
          <MetaItemLabel>
            {item.land_area ? '대지면적' : '대지권면적'}
          </MetaItemLabel>
          <MetaItemValue>
            {getComputedArea(
              item.land_area || item.land_of_use_area || 0,
              state.isSquareMeter,
            )}
          </MetaItemValue>
        </MetaItem>

        {item.type !== '토지' && (
          <MetaItem>
            <MetaItemLabel>
              {item.exclusive_area ? '전용면적' : '연면적'}
            </MetaItemLabel>
            <MetaItemValue>
              {getComputedArea(
                item.exclusive_area || item.total_floor_area || 0,
                state.isSquareMeter,
              )}
            </MetaItemValue>
          </MetaItem>
        )}
      </Meta>
    </ListItem>
  )
}

export default RealPriceTableItem
