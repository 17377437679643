import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'components/design'
import SummaryMultiHousing from 'components/summary/SummaryMultiHousing/SummaryMultiHousing'
import { getHousingPrice } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 공동주택공시가격
 

 * * 렌더링 여부
 * * 공동주택: O
 */
const SummaryMultiHousingAptContainer = ({
  dongHos,
  selectedDong,
  selectedHo,
}) => {
  const currentAddressId = useSelector(
    (state) => state.summary.currentAddressId,
  )

  const [onRequest, loading, data] = useAxios(getHousingPrice)

  const [priceData, setPriceData] = useState([])

  useUpdateEffect(() => {
    if (!currentAddressId) return
    if (!selectedHo) return
    onRequest({
      address_id: currentAddressId,
      dong_name: selectedDong == '구분없음' ? '' : selectedDong,
      ho_name: selectedHo,
    })
  }, [selectedDong, selectedHo])

  useEffect(() => {
    if (!data || !data.result) return
    setPriceData(data.result.filter(({ price }) => price >= 0))
  }, [data])

  return (
    <Card>
      <CardHeader>
        <CardTitle>공동주택공시가격</CardTitle>
      </CardHeader>
      <CardBody>
        {dongHos}
        <SummaryMultiHousing
          data={priceData}
          loading={loading}
          selectedDong={selectedDong}
          selectedHo={selectedHo}
        />
      </CardBody>
    </Card>
  )
}

export default SummaryMultiHousingAptContainer
