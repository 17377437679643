import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { useContext } from 'react'
import styled from 'styled-components'

const SummaryRpTableTitle = ({ selectedArea }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  return (
    <div className="card-title">
      {selectedArea && (
        <AreaText>
          {isSquareMeter
            ? `${(selectedArea?.supply_area_py * 3.305).toFixed(2)}㎡`
            : `${selectedArea?.supply_area_py}평`}
        </AreaText>
      )}

      <RecentPriceText>최근 실거래</RecentPriceText>
    </div>
  )
}

const RecentPriceText = styled.div`
  display: inline-block;
`

const AreaText = styled.div`
  color: ${theme.colors.primary[500]};
`
export default SummaryRpTableTitle
