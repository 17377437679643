import { Modal } from 'components/design'
import theme from 'lib/styles/theme'
import moment from 'moment'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 해지 완료 안내 (알림발송)
 * 결제일로부터 7일 초과 이거나 등기부등본 사용 내역이 있는 경우 = 환불 불가 BUT 다음 결제일까지 사용 가능
 * @returns
 */
const NotePaymentCancelScheduleDone = ({
  visible = false,
  date,
  onOk,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  return (
    <Modal
      title={`${planInfo?.payment_name} 요금제 해지가 정상 처리되었습니다.`}
      visible={visible}
      onOk={onOk}
      width={450}
      okButtonProps={{ color: 'blue' }}
      cancelHide
      hiddenClose
      footer={!visible && null}
    >
      {visible && (
        <>
          <strong>
            {moment(
              planInfo.next_payment_date,
              'YYYYMMDDHHmmss',
            ).format('YYYY년 MM월 DD일')}
          </strong>{' '}
          까지만 사용하실 수 있으며, 다음 결제일에 더 이상 요금이
          청구되지 않습니다.
          <br />
          <br />
          이용 기간 만료시 프리요금제로 요금제가 자동 전환되며, 남은
          등기부등본은 사용이 제한됩니다.
        </>
      )}
    </Modal>
  )
}

const Emphasis = styled.span`
  color: ${theme.colors.tint.danger};
  ${({ bold }) => bold && `font-weight: 700;`}
`

export default NotePaymentCancelScheduleDone
