import { CookiesProvider } from 'react-cookie'
import { BaseProvider } from 'contexts/baseContext'
import { MapProvider } from 'contexts/map'
import { SearchProvider } from 'contexts/searchContext'
import { NoteProvider } from 'contexts/noteContext'

function GlobalProvider({ children }) {
  return (
    <CookiesProvider>
      <BaseProvider>
        <MapProvider>
          <SearchProvider>
            <NoteProvider>{children}</NoteProvider>
          </SearchProvider>
        </MapProvider>
      </BaseProvider>
    </CookiesProvider>
  )
}

export default GlobalProvider
