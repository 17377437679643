import theme from 'lib/styles/theme'
import priceFormat from 'lib/utils/priceFormat'
import styled from 'styled-components'

const SummaryAptPriceAvg = ({ selectedArea }) => {
  return (
    (selectedArea?.rent_mean || selectedArea?.purchase_mean) && (
      <MeanHeader>
        <StyledSubtitle>
          최근 6개월 기준 평균
          {selectedArea.purpose === '상가·사무실' ? ' 단가' : ''}
        </StyledSubtitle>
        <MeanPrice>
          <SalePrice>
            {selectedArea.purchase_mean && (
              <div>
                매매 {priceFormat(selectedArea.purchase_mean * 10000)}
              </div>
            )}
          </SalePrice>
          {selectedArea.rent_mean && (
            <div>
              전세 {priceFormat(selectedArea.rent_mean * 10000)}
            </div>
          )}
        </MeanPrice>
      </MeanHeader>
    )
  )
}

const MeanHeader = styled.div`
  font-feature-settings: normal;
  padding: 20px;
  margin: 0 20px;
  background-color: ${theme.colors.primary[50]}CC;
  border-radius: ${theme.borderRadius[2]};
`

const StyledSubtitle = styled.div`
  color: #8ac39d;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 150%;
`

const MeanPrice = styled.div`
  color: ${theme.colors.primary[500]};
  font-size: 20px;
  font-weight: 800;
  line-height: 130%;
`

const SalePrice = styled.div`
  color: ${theme.colors.summary.rp.blue};
`

export default SummaryAptPriceAvg
