/*eslint react-hooks/exhaustive-deps: "off"*/
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { Spin, Skeleton } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { getNearBy } from 'lib/api/summaryApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'
import ExternalLayer from 'components/common/ExternalLayer/ExternalLayer'

import SummaryNearbyItem from 'components/summary/SummaryNearby/SummaryNearbyItem'
import SummaryNearbyMarker from 'components/summary/SummaryNearby/SummaryNearbyMarker'
import './SummaryNearBy.scss'
import { useSelector } from 'react-redux'

import SEOHead from 'components/common/SEOHead'
import { CardMore, Select, Empty } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'

const SpinWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: ${theme.colors.gray[200]};
`

const SummaryNearBy = () => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { clickPosition } = state

  const { building } = useSelector((state) => state.summary.summaryInfo)

  const history = useHistory()
  const params = useParams()

  const [nearbyDatas, setNearbyDatas] = useState([]) // 주변 유사거래 데이터
  const [nearbyMap, setNearbyMap] = useState() // 주변 유사거래 지도
  const [isLoading, setIsLoading] = useState(false) // 주변 유사거래 지도 로딩 완료 여부
  const [selectedItem, setSelectedItem] = useState()

  const [loadingList, setLoadingList] = useState(false) // 목록 로딩
  const [offset, setOffset] = useState(10) // 목록 페이징 처리
  const [order, setOrder] = useState('date') // 정렬

  const mapNearby = useRef(null) // 주변 유사거래 지도

  const [onRequest, loading, data] = useAxios(getNearBy)

  const isMobile = useIsMobile()
  /**
   * 주변 유사거래 데이터 불러오기
   */
  useEffect(() => {
    // undefiend 에러 방지
    if (Object.keys(clickPosition).length === 0) return
    if (!clickPosition.address_id) return

    setLoadingList(true)

    onRequest(
      {
        address_id: clickPosition.address_id,
        lat: clickPosition.centerpoint?.[0],

        lng: clickPosition.centerpoint?.[1],
        offset: offset,
        order: order,
      },
      null,
      false,
    )
  }, [building, clickPosition, offset, order])

  useEffect(() => {
    if (!data) return

    setNearbyDatas(data.nearby)

    setLoadingList(false)
    handleSetNearbyMap()

    return () => {
      setNearbyDatas([])
    }
  }, [data])

  /**
   * nearby-map 세팅
   * 메인지도에서 클릭한 폴리곤 세팅
   */
  const handleSetNearbyMap = () => {
    // map-nearby html태그가 로드되기 전에 실행되어 생기는 이슈
    // : `new kakao.maps.Maps()`에서 첫 번째 파라미터에 null 넘어가서 에러 발생 -> 간단하게 timeout처리
    setTimeout(() => {
      if (Object.keys(clickPosition).length > 0) {
        // 카카오 지도 설정
        kakao.maps.load(() => {
          let center = new kakao.maps.LatLng(
            clickPosition?.centerpoint?.[0],
            clickPosition?.centerpoint?.[1],
          )
          // if(cookies.clickPosition) {
          // 	center = new kakao.maps.LatLng(cookies.clickPosition?.centerpoint?.[0], cookies.clickPosition?.centerpoint?.[1])
          // }
          let el = mapNearby.current
          if (!el) return

          let nearbyMap = new kakao.maps.Map(el, {
            center: center,
          })
          setNearbyMap(nearbyMap)

          // zoom level 세팅
          nearbyMap.setLevel(5)

          // maptype에 해당하는 지도타입을 지도에 추가합니다
          nearbyMap.addOverlayMapTypeId(
            kakao.maps.MapTypeId.USE_DISTRICT,
          ) // 지적편집도 지도타입

          setIsLoading(true)
        })
      }
    }, 500)
  }

  const onClickItem = useCallback((address, coordinates) => {
    setSelectedItem(address)

    // 중심이동
    if (nearbyMap && Object.keys(nearbyMap).length > 0) {
      nearbyMap.setCenter(
        new kakao.maps.LatLng(coordinates[0], coordinates[1]),
      )
      nearbyMap.setLevel(5)
    }
  })

  /**
   * 닫기버튼 클릭 시
   */
  const onClickClose = useCallback(() => {
    history.location.pathname != `/${params?.id}`
      ? history.push(`/${params?.id}`)
      : history.goBack()
  }, [])

  const onMore = () => {
    setOffset((prevState) => prevState + 10)
  }

  return (
    <>
      <SEOHead />
      <ExternalLayer
        noTopHeader={isMobile}
        title="주변 유사거래"
        onClose={onClickClose}
      >
        <div className="summary-nearby">
          <div className="summary-nearby__map">
            <div className="summary-nearby__map-container">
              <div id="map-nearby" ref={mapNearby}>
                {/* 중점 마커 */}
                {isLoading && (
                  <SummaryNearbyMarker
                    map={nearbyMap}
                    position={
                      new kakao.maps.LatLng(
                        clickPosition?.centerpoint?.[0],
                        clickPosition?.centerpoint?.[1],
                      )
                    }
                    isCenter
                  />
                )}

                {/* 주변 유사거래 마커 */}
                {isLoading &&
                  nearbyDatas?.length > 0 &&
                  nearbyDatas.map((item, i) => {
                    return (
                      <SummaryNearbyMarker
                        key={i}
                        map={nearbyMap}
                        position={
                          new kakao.maps.LatLng(
                            item?.centerpoint?.coordinates?.[0],
                            item?.centerpoint?.coordinates?.[1],
                          )
                        }
                        propertyType={item.property_type}
                        isSelected={selectedItem === item.address_id}
                        onClickItem={onClickItem}
                      />
                    )
                  })}
              </div>

              {!isLoading && (
                <SpinWrap>
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          color: theme.colors.gray[500],
                          fontSize: '2rem',
                        }}
                        spin
                      />
                    }
                  />
                </SpinWrap>
              )}
            </div>
          </div>

          <div className="summary-nearby__order">
            <Select onChange={(e) => setOrder(e.target.value)}>
              <option value="date">최근순</option>
              <option value="distance">거리순</option>
              <option value="unit_price">가격순(평당)</option>
              <option value="total_price">가격순(총액)</option>
              <option value="similarity">유사도순</option>
            </Select>
          </div>

          <div className="summary-nearby__container custom-scroll">
            {nearbyDatas?.length > 0 ? (
              <ul className="summary-nearby-list">
                {nearbyDatas?.map((item, i) => (
                  <SummaryNearbyItem
                    nearbyMap={nearbyMap}
                    item={item}
                    key={i}
                    isSelected={selectedItem === item.address_id}
                    onClickItem={onClickItem}
                  />
                ))}

                {<CardMore onClick={onMore} />}
              </ul>
            ) : (
              <Empty>주변 유사거래 정보가 없습니다.</Empty>
            )}
            {loadingList && <Skeleton active />}
          </div>
        </div>
      </ExternalLayer>
    </>
  )
}

export default SummaryNearBy
