import styled from 'styled-components'
import { Section, Title } from './BdsFilterStyle'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { initBdsFilter, setArea } from 'modules/bds'
import { useSelector } from 'react-redux'
import theme from 'lib/styles/theme'
import { isEqual, max, min } from 'lodash'
import { getFormatedArea } from 'utils'
import NoteDetailNoteConverter from 'components/jmapnotev2/detail/NoteDetailNoteConverter'
import MapContext from 'contexts/map'

const items = [
  33, 66, 99, 132, 165, 198, 331, 661, 992, 1322, 1653, 1983, 2314,
  2645, 2975, 3306,
]

/**
 * 면적 검색 필터
 */
const BdsContentFilterArea = () => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const selectedFilter = useSelector(
    (state) => state.bds.selectedFilter,
  )
  const [selectedCount, setSelectedCount] = useState(2)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedFilter?.area) return
    if (
      items.filter((item) => isEqual(selectedFilter.area, item))
        .length > 0
    ) {
      setSelectedCount(1)
    } else {
      setSelectedCount(2)
    }
  }, [])

  const isInclude = (item, i) => {
    return (
      !isEqual(selectedFilter.area, initBdsFilter.area) &&
      (i == 0
        ? selectedFilter.area[0] == null
        : i == items.length - 1
        ? selectedFilter.area[1] == null
        : selectedFilter.area[1] == null
        ? selectedFilter.area[0] <= item
        : selectedFilter.area[0] == null
        ? selectedFilter.area[1] >= item
        : selectedFilter.area[0] != null &&
          selectedFilter.area[0] <= item &&
          selectedFilter.area[1] >= item)
    )
  }

  /**
   * 면적 블럭 클릭
   * @param {Array(2)} item
   */
  const onClick = (item, i) => {
    const selectedArea = selectedFilter.area
    const concatArr = selectedArea.concat(item)

    if (selectedCount == 1) {
      console.log(1)
      if (selectedArea[1] == null) {
        dispatch(setArea([item, null]))
      } else if (i == items.length - 1) {
        dispatch(setArea([selectedArea[0], null]))
      }
      //  else if (selectedArea[0] > item) {
      //   dispatch(setArea([item, items?.[i + 1] - 1 || null]))
      // }
      else {
        if (selectedArea[0] == item) {
          onClickAll()
          setSelectedCount(2)
        } else {
          dispatch(
            setArea([selectedArea[0], item].sort((a, b) => a - b)),
          )
        }
      }
      setSelectedCount(2)
    } else if (selectedCount == 2) {
      console.log(1)
      if (i == 0) {
        dispatch(setArea([null, item]))
        setSelectedCount(1)
      } else if (i == items.length - 1) {
        dispatch(setArea([item, null]))
        setSelectedCount(1)
      } else {
        if (selectedArea[0] == item) {
          // onClickAll()
          // setSelectedCount(2)
          dispatch(setArea([item, items[i + 1] - 1 || null]))
          setSelectedCount(1)
        } else {
          dispatch(setArea([item, items[i + 1] - 1 || null]))
          setSelectedCount(1)
        }
      }
    }
  }

  /**
   * 전체 선택
   */
  const onClickAll = () => {
    dispatch(setArea(initBdsFilter.area))
    setSelectedCount(2)
  }

  return (
    <Section>
      <Title narrow>
        면적
        <NoteDetailNoteConverter color="black" />
      </Title>
      <Wrapper>
        <Block
          active={isEqual(selectedFilter.area, initBdsFilter.area)}
          onClick={onClickAll}
        >
          전체
        </Block>
        {items.map((item, i) => {
          return (
            <Block
              key={`bds_filter_area_${i}`}
              active={isInclude(item, i)}
              onClick={() => onClick(item, i)}
            >
              {i == 0
                ? `~${getFormatedArea(item, isSquareMeter)}`
                : i == items.length - 1
                ? `${getFormatedArea(item, isSquareMeter)}~`
                : getFormatedArea(item, isSquareMeter)}
            </Block>
          )
        })}
      </Wrapper>
    </Section>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 6px;
`

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 180%;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.blue[400]};
    color: ${theme.colors.base.white};
  `}
`

export default BdsContentFilterArea
