import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import Checkbox from '../../elements/Checkbox'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비
 */
const NoteAdColumnFee = ({ half }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const mnexYn = watch('trade.mnexYn')
  const mnex = watch('trade.mnex')

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>월 관리비</ColumnTitle>
      <ColumnVal>
        <Wrapper>
          <PriceWrapper>
            <div>
              <InputText
                defaultValue={mnex}
                width={InputRemoveAppendWidth}
                type="number"
                {...register('trade.mnex')}
                onBlur={(e) => {
                  onUpdate(
                    'trade.mnex',
                    e.target.value.replaceAll(',', ''),
                  )
                  onUpdate('trade.mnexYn', 'Y')
                }}
                append={'원'}
                fixAppendWidth
              />
            </div>
            <Checkbox
              id="trade_mnexYn"
              checked={mnexYn == 'N'}
              value="N"
              onChange={(e) => {
                if (e) {
                  onUpdate('trade.mnex', 0)
                }
                onUpdate('trade.mnexYn', e ? 'N' : 'Y')
              }}
            >
              관리비 없음
            </Checkbox>
          </PriceWrapper>
          <ValidateWarn>
            <div>{errors?.trade?.mnex?.message}</div>
            <div>{errors?.trade?.mnexYn?.message}</div>
          </ValidateWarn>
          <FormDesc>
            <li>
              0원으로 등록시 관리비 없음으로 노출되며, 허위매물 대상이
              될 수 있습니다.
            </li>
          </FormDesc>
        </Wrapper>
      </ColumnVal>
    </ColumnSet>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const PriceWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export default NoteAdColumnFee
