import { Modal } from 'components/design'
import theme from 'lib/styles/theme'
import { useState } from 'react'
import styled from 'styled-components'
import SignupAgree from './SignupAgree'
import { message } from 'antd'

/**
 * 회원가입 가입 유형 선택 modal
 * @param {boolean} visible modal visible 여부
 * @param {function} onCancel 닫기 함수
 * @returns
 */
const SignupSelectType = ({ visible, onCancel }) => {
  const [isAgreeFormVisible, setIsAgreeFormVisible] = useState(false)

  const [memberType, setMemberType] = useState('')

  const onClick = (value) => {
    if (value == 'business') {
      message.info('건축 전문가 회원은 주택 홈에서 회원 가입해 주세요.')
      setMemberType(value)
      return
    }
    setMemberType(value)
    onCancel()
    setIsAgreeFormVisible(true)
  }

  return (
    <>
      <Modal
        title=""
        visible={visible}
        onCancel={onCancel}
        width={500}
        footer={null}
        customSize
      >
        <Header>회원가입</Header>
        <Desc>가입 유형을 선택해 주세요.</Desc>
        <BlockWrapper>
          <Block onClick={() => onClick('individual')}>
            <Text>
              <Title>일반회원</Title>
              <TitleDesc>건축 계획을 시작하는 예비건축주</TitleDesc>
            </Text>
            <img src="/modules/member/skins/jooteg/images/signup-individual.png" />
            <Arrow>
              <i className="far fa-chevron-right"></i>
            </Arrow>
          </Block>
          <Block onClick={() => onClick('business')}>
            <Text>
              <Title>건축 전문가</Title>
              <TitleDesc>건축사, 건설사 등 건축 관련 종사자</TitleDesc>
            </Text>
            <img src="/modules/member/skins/jooteg/images/signup-business.png" />
            <Arrow>
              <i className="far fa-chevron-right"></i>
            </Arrow>
          </Block>
          <Block onClick={() => onClick('realestate')}>
            <Text>
              <Title>부동산 중개사</Title>
              <TitleDesc>공인중개사 및 관련업계 종사자</TitleDesc>
            </Text>
            <img src="/modules/member/skins/jooteg/images/signup-realestate.png" />
            <Arrow>
              <i className="far fa-chevron-right"></i>
            </Arrow>
          </Block>
        </BlockWrapper>
      </Modal>
      <SignupAgree
        visible={isAgreeFormVisible}
        onCancel={() => setIsAgreeFormVisible(false)}
        memberType={memberType}
      />
    </>
  )
}

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 10px;
`

export const Header = styled.div`
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-weight: 500;
`

export const Desc = styled.div`
  margin-bottom: 8px;
  font-weight: 400;
  color: ${theme.colors.gray[600]};
  font-size: 16px;
  text-align: center;
`

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: 28px;
  color: ${theme.colors.gray[300]};
  font-size: 16px;
  border-radius: 100%;
  border: 2px solid ${theme.colors.gray[200]};
`

const Block = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  padding: 20px 24px;
  border: 1px solid #f2f4f6;
  border-radius: 8px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 32px,
    rgba(0, 0, 0, 0.05) 0 1px 2px;
  color: #191f28;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8;
  text-decoration: none;
  appearance: none;
  cursor: pointer;

  img {
    display: block;
    width: auto;
    height: 62px;
    margin-left: 24px;
  }

  &:hover {
    border-color: ${theme.colors.primary[400]};

    ${Arrow} {
      background-color: ${theme.colors.primary[400]};
      color: ${theme.colors.base.white};
      border-color: ${theme.colors.base.white};
    }
  }
`

const Text = styled.div`
  flex: 1;
`

const Title = styled.div`
  font-weight: 700;
`

const TitleDesc = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 14;
  font-weight: 500;
`

export default SignupSelectType
