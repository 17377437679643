import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function useChannelPermission() {
  const [permission, setPermission] = useState('readOnly')
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  useEffect(() => {
    if (!channelInfo) return
    const memberType = channelInfo?.member_type || ''

    return setPermission(memberType)
  }, [channelInfo])

  return permission
}
