import { Button, Modal, ModalFooter } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import { searchDeunggibu } from 'lib/api/note/noteApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { ScrollStyle } from '../style/NoteStyle'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import Loading from 'components/common/Loading/Loading'
import { message } from 'antd'
import NoteDetailGongbuIssuanceNoti from './NoteDetailGongbuIssuanceNoti'

/**
 * 어떤 등기부등본을 발급할까요? 모달
 * @param {*} param0
 * @returns
 */
const NoteDetailGongbuIssuanceModal = ({
  visible,
  setVisible,
  onOk,
  setCustomAddress,
  validOnly,
  onChangeValidOnly,
  subType,
  onChangeSubType,
  customDongHo,
  onChangeCustomDongHo,
  setUniqueAddress,
  uniqueAddress,
  addrReadonly,
  customAddress,
}) => {
  const { watch } = useFormContext()
  const address_jibun = watch('address_jibun')

  const [onRequest, , data] = useAxios(searchDeunggibu)

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])

  const ref = useRef()
  useOnClickOutside(ref, (e) => {
    if (!ref) return
    setList([])
    setUniqueAddress(null)
  })

  /**
   * 부동산 구분 바뀔 때 마다 검색결과 값 등 초기화
   */
  useEffect(() => {
    setList([])
    setUniqueAddress(null)
  }, [subType])

  const onClickSearch = () => {
    setLoading(true)
    onRequest({
      sub_type: subType,
      address: customAddress,
      dongho: customDongHo,
    })
  }

  useEffect(() => {
    if (!data || !data.result) return

    if (data.result.length == 0) {
      message.info('주소 검색 결과가 없습니다.')
    }

    setList(data.result)
    setLoading(false)
  }, [data])

  const onClickListItem = (obj) => {
    setUniqueAddress(obj)
    setList([])
  }

  const onClose = () => {
    setVisible(false)
    setList([])
    setUniqueAddress(null)
  }

  const Footer = ({ isDisabled }) => {
    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <ModalFooter>
          <ButtonWrapper hover={isDisabled ? 'Y' : 'N'}>
            <Button disabled={isDisabled} onClick={onOk} color="blue">
              발급
            </Button>
          </ButtonWrapper>
        </ModalFooter>
      </div>
    )
  }

  return (
    <Modal
      title="문서 발급"
      visible={visible}
      onCancel={onClose}
      cancelHide
      okButtonProps={{ color: 'blue' }}
      // onOk={onOk}
      //   okText="발급"
      footer={
        <Footer isDisabled={subType != 'land' && !uniqueAddress} />
      }
    >
      <ModalTitle>어떤 등기부등본을 발급할까요?</ModalTitle>
      <ModalSection>
        <ModalSubTitle>부동산 구분</ModalSubTitle>
        <ModalContent>
          <RadioButton
            label="건물"
            checked={subType === 'building'}
            name="building"
            onChange={() => onChangeSubType('building')}
          />
          <RadioButton
            label="토지"
            checked={subType === 'land'}
            name="land"
            onChange={() => onChangeSubType('land')}
          />
          <RadioButton
            label="집합건물"
            checked={subType === 'apt'}
            name="apt"
            onChange={() => onChangeSubType('apt')}
          />
        </ModalContent>
      </ModalSection>
      <ModalSection>
        <ModalSubTitle>
          주소
          {subType != 'land' && <NoteDetailGongbuIssuanceNoti />}
        </ModalSubTitle>
        <ModalContent column="Y">
          <SearchWrapper>
            <StyledInput
              disabled={addrReadonly}
              type="text"
              defaultValue={customAddress || address_jibun}
              onChange={(e) => setCustomAddress(e.target.value)}
            />
            {(subType === 'building' || subType === 'land') && (
              <Button
                color="blue"
                onClick={onClickSearch}
                disabled={subType === 'land'}
              >
                검색
              </Button>
            )}
          </SearchWrapper>
          {subType === 'apt' && (
            <>
              <SearchWrapper>
                <StyledInput
                  type="text"
                  placeholder="동, 호수 또는 층수 입력"
                  defaultValue={customDongHo}
                  onChange={(e) =>
                    !addrReadonly && onChangeCustomDongHo(e)
                  }
                  disabled={addrReadonly}
                />
                <Button color="blue" onClick={onClickSearch}>
                  검색
                </Button>
              </SearchWrapper>
            </>
          )}
          {uniqueAddress && (
            <SelectedAddress>
              <SelectedAddressGubun>
                [{uniqueAddress.Gubun}]
              </SelectedAddressGubun>
              <SelectedAddressAddr>
                {uniqueAddress.BudongsanSojaejibeon}
              </SelectedAddressAddr>
            </SelectedAddress>
          )}
        </ModalContent>
      </ModalSection>
      <ModalSection noborder>
        <ModalSubTitle>등기사항증명서 종류</ModalSubTitle>
        <ModalContent>
          <RadioButton
            label="유효부분만 발급"
            checked={validOnly}
            name="valid_only"
            onChange={() => onChangeValidOnly(true)}
          />
          <RadioButton
            label="말소포함 발급"
            checked={!validOnly}
            name="valid_only"
            onChange={() => onChangeValidOnly(false)}
          />
        </ModalContent>
      </ModalSection>
      {(loading || list.length > 0) && (
        <DropDownList
          subtype={subType}
          loading={loading ? 'Y' : 'N'}
          ref={ref}
        >
          {loading && <Loading />}
          {list.length > 0 &&
            list.map((item, i) => {
              return (
                <DropDownListItem
                  key={i}
                  onClick={() => onClickListItem(item)}
                >
                  <GubunText>{item?.Gubun || ''}</GubunText>
                  <Address>{item?.BudongsanSojaejibeon || ''}</Address>
                </DropDownListItem>
              )
            })}
        </DropDownList>
      )}
    </Modal>
  )
}

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
`

const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray[100]};
  padding: 16px 0;

  ${({ noborder }) => noborder && `border-bottom: none;`}
`
const ModalSubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
`

const ModalContent = styled.div`
  display: flex;

  input {
    width: 80%;
    outline: none;

    &:focus {
      border: 1px solid ${theme.colors.blue[500]};
    }
  }

  ${({ column }) =>
    column == 'Y' &&
    `
    flex-direction: column;
    gap: 8px;
    position: relative;
  `}
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`

const StyledInput = styled.input`
  width: 360px;
  height: 32px;
  max-width: 100%;
  padding-left: 12px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
`

const DropDownList = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 366px;
  width: calc(100% - 40px);
  height: 198px;
  padding: 13px 0;
  overflow: auto;
  box-shadow: ${theme.shadow['card-2']};
  border-radius: 8px;
  background: ${theme.colors.base.white};
  z-index: 1;

  ${({ loading }) =>
    loading == 'Y' &&
    `
      align-items: center;
      justify-content: center;
    `};

  ${({ subtype }) =>
    subtype == 'building' &&
    `
      top: 318px;
  `};
  ${ScrollStyle}
`

const DropDownListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  line-height: 130%;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.gray[100]};
  }
`

const GubunText = styled.div`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-right: 8px;
`

const Address = styled.div`
  color: ${theme.colors.gray[600]};
`

const SelectedAddress = styled.div`
  background-color: ${theme.colors.gray[100]};
  border: 1px solid ${theme.colors.gray[100]};
  border-radius: 8px;
  padding: 14px 16px;
`

const SelectedAddressGubun = styled.div`
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 2px;
`

const SelectedAddressAddr = styled.div`
  font-weight: 400;
  line-height: 140%;
`

const ButtonWrapper = styled.div`
  ${({ hover }) =>
    hover == 'Y' &&
    `
      &:hover {
        &::before {
          content: '먼저 주소를 검색해 주세요.';
          font-size: 14px;
          text-align: center;
          position: absolute;
          display: block;
          bottom: calc(100% + 10px);
          background: rgba(39, 39, 39, 1);
          border-radius: 4px;
          padding: 10px;
          color: #ffffff;
          z-index: 1;
          right: -37px;
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          right: 40px;
          bottom: calc(100% + 6px);
          border: 1px solid black;
          border-color: rgba(39, 39, 39, 1) transparent transparent
            transparent;
          border-width: 4px 6px 0;
          z-index: 1;
        }
      }
    `}
`

export default NoteDetailGongbuIssuanceModal
