import { useRef } from 'react'

function useMoveScroll(name) {
  const element = useRef(null)

  const onMoveToElement = () => {
    const headerOffset = 49
    const elementPosition = element.current.offsetTop

    document.getElementById('bds_scroll').children[0].scrollTo({
      top: elementPosition - headerOffset,
      behavior: 'smooth',
    })
  }
  return { element, onMoveToElement, name }
}

export default useMoveScroll
