import { Checkbox, Modal } from 'components/design'
import NoteWelcomeContainer from 'containers/notev2/NoteWelcomeContainer'
import styled from 'styled-components'
import WelcomeImage from 'assets/images/features/welcomemodal.png'
import theme from 'lib/styles/theme'
import useIsMobile from 'lib/hooks/useIsMobile'
import { media } from 'lib/styles/theme'
import { symbol } from 'prop-types'
import { useState } from 'react'
import { getJootekServiceURL } from 'utils'
import { useDispatch } from 'react-redux'
import {
  setOnBoardingStart,
  setOnBoardingState,
} from 'modules/onBoarding'

const NoteWelcome = () => {
  const isMobile = useIsMobile()

  const [checkClick, setCheckClick] = useState(false)

  const dispatch = useDispatch()

  const onBoardingStarting = () => {
    dispatch(setOnBoardingStart(true))
    dispatch(setOnBoardingState(true))
  }

  return (
    <NoteWelcomeContainer>
      {({ isVisible, setIsVisible }) => (
        <Modal
          full="true"
          welcomeModalCustom={true}
          visible={isVisible}
          onOk={() => setIsVisible(false)}
          onCancel={() => setIsVisible(false)}
          okText="확인"
          cancelText="취소"
          footer={null}
          width={isMobile ? 315 : 640}
          customSize={true}
        >
          <ModalContentWrapper>
            <LeftWrapper>
              <TitleWrapper>
                {'안녕하세요\n매물노트에 오신 걸 환영합니다!'}
              </TitleWrapper>
              <MobileImageWrapper>
                <img
                  width={isMobile ? 120 : 236}
                  height={isMobile ? 120 : 204}
                  src={WelcomeImage}
                  alt=""
                />
              </MobileImageWrapper>
              <ContentWrapper>
                매물노트는 효율적인 중개 업무를 위한 최고의
                파트너입니다. 이제는 번거로운 업무에 시간을 낭비하지
                마세요.
              </ContentWrapper>
              <MobileContentWrapper>
                {
                  '매물노트는 효율적인 중개 업무를 위한\n 최고의 파트너입니다. 이제는 번거로운 업무에 시간을 낭비하지 마세요.'
                }
              </MobileContentWrapper>
              <div></div>
            </LeftWrapper>
            <RightWrapper>
              <ImageWrapper>
                <img
                  width={isMobile ? 120 : 236}
                  height={isMobile ? 120 : 204}
                  src={WelcomeImage}
                  alt=""
                />
              </ImageWrapper>
              <CloseWrapper>
                <StartButton
                  onClick={() => {
                    setIsVisible(false)
                    onBoardingStarting()
                  }}
                >
                  매물노트 시작하기
                </StartButton>
              </CloseWrapper>
              <MobileButtonWrapper>
                <StartButton
                  onClick={() => {
                    setIsVisible(false)
                    onBoardingStarting()
                  }}
                >
                  매물노트 시작하기
                </StartButton>
              </MobileButtonWrapper>
            </RightWrapper>
          </ModalContentWrapper>
        </Modal>
      )}
    </NoteWelcomeContainer>
  )
}

const MobileImageWrapper = styled.div`
  text-align: center;
  display: none;
  ${media.mediumS`
  margin-top:25px;

display:block;  `}
`

const StartButton = styled.button`
  cursor: pointer;
  width: 133px;
  height: 38px;
  border-radius: 8px;
  font-weight: 700;

  background-color: ${theme.colors.blue[400]};
  color: white;

  ${media.mediumS`
  width:288px;
  `}
`

const SignUpButton = styled.button`
  cursor: pointer;
  width: 81px;
  border-radius: 8px;
  font-weight: 700;

  height: 38px;
  color: white;
  background-color: ${theme.colors.blue[400]};
  &:active {
    color: white;
  }
`

const ModalContentWrapper = styled.div`
  padding-top: 20px;
  height: 386px;
  display: flex;
  justify-content: space-between;
  ${media.mediumS`
  text-align:center;
  display:block;
  height:300px;
  // padding:20px;
  `}
`

const RightWrapper = styled.div`
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.mediumS`
  margin-top: 10px;

  display:block;
  `}
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.mediumS`

  display:block;
  `}
`

const CloseWrapper = styled.div`
  margin-left: 115px;

  display: flex;
  justify-content: right;
  width: 150px;
  // height: 20px;
  ${media.mediumS`
display:none;
  `}
`

const ContentWrapper = styled.div`
  font-size: 16px;
  width: 290px;
  height: 86px;
  ${media.mediumS`
  display:none;
  margin-top:15px;
 text-align:center;
 font-size:16px;

width:100%;
  `}
`

const MobileContentWrapper = styled.div`
  display: none;

  ${media.mediumS`

  white-space: pre-wrap;

  margin-top:25px;
 text-align:center;
 font-size:16px;
 display:block;

width:100%;
  `}
`

const TitleWrapper = styled.div`
  white-space: pre-wrap;
  width: 300px;
  height: 56px;
  font-size: 22px;
  font-weight: 700;
  ${media.mediumS`
  font-size: 18px;
  text-align:left;

  `}
`

const MobileButtonWrapper = styled.div`
  display: none;
  ${media.mediumS`
  gap: 8px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  margin-top: 51px; 
  `};
`

const ImageWrapper = styled.div`
  width: 236px;
  height: 204px;
  ${media.mediumS`
  width:120px;
  height:120px;
  display:none;
  `}
`

export default NoteWelcome
