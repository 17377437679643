import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import ImgSrc from 'assets/images/deunggi.png'

/**
 * 매물노트 - 대시보드
 * 최상단 등기부 배너
 */
const NoteDashboardDeunggiBanner = () => {
  return (
    <Wrapper background="#3357d5">
      <Tag background="#a4ff95" color="#257bf4">
        EVENT
      </Tag>
      <ContentWrapper>
        <Text>
          친구 초대하고,
          <br />
          <strong>등기열람권 4개</strong>
          받아 보세요!
          <br />
        </Text>
        <Desc>
          초대받은 친구가 유료 플랜을 구입하면 등기열람권 4개를 드려요!
        </Desc>
      </ContentWrapper>

      <Img src={ImgSrc} />
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ background }) => `background-color: ${background};`}
  border-radius: 12px;
  user-select: none;

  ${media.smallPc`
    flex-direction: column;
    width: 100%;
  `}

  ${media.mediumM`
    width: 100%;
  `}

  ${media.mediumS`
    height: 72px;
  `}
`

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 6px 8px;
  ${({ background }) => `background-color: ${background};`}
  ${({ color }) => `color: ${color};`}
  border-radius: 999px;
  font-size: 12px;
  font-weight: 900;
  line-height: 140%;
`

const Text = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: ${theme.colors.base.white};
  line-height: 125%;

  strong {
    color: #a4ff95;
    font-weight: 700;
    margin-right: 6px;
  }

  ${media.smallPc`
    font-size: 26px;
  `}

  ${media.mediumS`
    font-size: 16px;
  `}
`
const ContentWrapper = styled.div`
  position: absolute;
  top: 35px;
  left: 36px;

  ${media.mediumS`
    top: 16px;
    left: 16px;
  `}
`

const Img = styled.img`
  position: absolute;
  width: 245px;
  height: auto;
  right: 50px;
  bottom: 0px;

  ${media.smallPc`
    width: 200px;
    right: 60px;
    bottom: 10px;
  `}

  ${media.mediumS`
    display: none;
  `}
`

const Desc = styled.div`
  margin-top: 10px;
  opacity: 0.8;
  color: ${theme.colors.base.white};
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  ${media.mediumS`
    display: none;
  `}
`

export default NoteDashboardDeunggiBanner
