import { useCallback, useState, useEffect, useContext } from 'react'
import useAxios from '../useAxios'
import { saveAddressForSheet } from 'lib/api/notev2/listApi'
import { useSelector } from 'react-redux'
import {
  setNoteListInfo,
  setResetNotePropertyFilter,
} from 'modules/notev2'
import { useDispatch } from 'react-redux'
import { updateAfterAddressChange } from 'lib/utils/notev2/gridData'
import moment from 'moment'
import { useNoteMarker } from '../useNoteMarker'
import { getPropertyFilter } from 'lib/api/notev2/filterApi'
import useSetActiveMarker from './useSetActiveMarker'

/**
 * 시트 전용
 * 유효한 주소 신규 저장 hook
 * 유효하지 않은 주소는 useSaveNotes hook 사용
 * @returns 주소 저장 함수, 에러 여부 반환
 */
function useSaveAddressForSheet(mode) {
  const { count } = useSelector((state) => state.notev2.noteListInfo)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [onRequest, , data, error] = useAxios(saveAddressForSheet)
  const [isError, setIsError] = useState(false)
  const [rowNode, setRowNode] = useState(null)
  const [validAddress, setValidAddress] = useState(null)
  const [callbackFn, setCallbackFn] = useState(null)

  const { openAddedMarker } = useSetActiveMarker()

  const { searchNoteMarkers } = useNoteMarker()

  const [currentChannel, setCurrentChannel] = useState()

  const dispatch = useDispatch()
  const [onRequestFilter, , dataFtiler, ,] = useAxios(getPropertyFilter)

  /**
   * 주소 저장
   * @param channel_srl 채널 srl
   * @param address_id 주소 id
   * @param validAddressInfo 유효한 주소 getValidAddresses api 리턴 값
   * @param currentRowNode 그리드 현재 row node 값
   * @param callbackFn 콜백함수
   */
  const onSaveAddressForSheet = useCallback(
    ({
      channel_srl,
      address_id,
      folder_srl,
      validAddressInfo,
      currentRowNode,
      callbackFn,
    }) => {
      if (channelInfo?.member_type == 'readonly') return
      if (validAddressInfo) {
        setValidAddress(validAddressInfo)
      }

      if (currentRowNode) {
        setRowNode(currentRowNode)
      }

      if (callbackFn) {
        setCallbackFn(() => callbackFn)
      }

      setCurrentChannel(channel_srl)

      onRequest({
        channel_srl,
        folder_srl: mode == 'card' ? folder_srl : null,
        address_id,
        display_address: validAddressInfo.display_address,
        exclusive_area: validAddressInfo.exclusive_area,
        contract_area: validAddressInfo.contract_area,
        dong: validAddressInfo?.dong ? validAddressInfo.dong : '',
        ho: validAddressInfo?.ho ? validAddressInfo.ho : '',
        property_type: validAddressInfo?.property_type,
      })
    },
    [],
  )

  /**
   * 에러 발생 시
   */
  useEffect(() => {
    if (error) {
      //@ONLYTEST:
      console.error('save address 에러')
      setRowNode(null)
      setCallbackFn(null)
      setIsError(true)
    }
  }, [error])

  /**
   * 주소 저장 결과
   * 주소 저장 결과로 address_srl과 주소 정보(result)를 얻을 수 있다
   */
  useEffect(() => {
    if (!data) return
    if (data.error != 0) {
      //@ONLYTEST:
      console.error('save address 에러')
      setRowNode(null)
      setCallbackFn(null)
      setIsError(true)

      return
    }

    //매물 개수 count +1
    dispatch(setNoteListInfo({ count: count + 1 }))

    //필터 refresh
    onRequestFilter({
      channel_srl: data.result.channel_srl || currentChannel,
    })

    //노트 마커 refresh
    searchNoteMarkers()

    if (rowNode) {
      //그리드 데이터 업데이트 데이터 생성
      const updataData = Object.assign({}, validAddress, {
        type: 'insert',
        regdate: moment().format('YYYY-MM-DD HH:mm:ss'),
        dong: validAddress.dong || '',
        ho: validAddress.ho || '',
        land_type: validAddress.land_type,
        note_srl: data.result.note_srl, //노트 srl
        address_srl: data.result.address_srl,
        address_jibun: validAddress.full_address,
        extra_vars: data.result?.extra_vars || null,
        centerpoint:
          validAddress.latitude && validAddress.longitude
            ? [validAddress.latitude, validAddress.longitude]
            : null,
        latitude: data.result?.latitude || '',
        longitude: data.result?.longitude || '',
        direction:
          data.result?.direction ||
          data.result?.extra_vars?.direction ||
          '',
      })

      //그리드 데이터 업데이트
      updateAfterAddressChange({
        currentRowNode: rowNode,
        ...updataData,
      })

      //작업 끝났으니 state들 초기화 하기
      setRowNode(null)
      setValidAddress(null)

      if (callbackFn) {
        callbackFn()
        setCallbackFn(null)
      }
      //방금 추가한 매물은 필터 상관없이 무조건 active
      openAddedMarker(data.result)
    } else {
      console.log('ㅇㅇㅇ')
      if (callbackFn) {
        callbackFn(data.result.note_srl)
        setCallbackFn(null)
      }
    }
  }, [data])

  //필터 호출 후 redux 전역 필터 state에 dispatch
  useEffect(() => {
    if (!dataFtiler) return

    dispatch(
      setResetNotePropertyFilter(dataFtiler.result?.property || {}),
    )
  }, [dataFtiler])

  return {
    onSaveAddressForSheet,
    isError,
    data,
  }
}

export default useSaveAddressForSheet
