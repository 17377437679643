import { ButtonGroup } from 'components/design'
import styled from 'styled-components'
import HeaderButtonFavorite from './HeaderButtonFavorite'
import HeaderButtonNoteApply from './HeaderButtonNoteApply'
import HeaderButtonShare from './HeaderButtonShare'

const StyledButtonGroup = styled.div`
  /* display: flex; */
  margin-top: 1.125rem;

  ${({ custom }) =>
    custom == true &&
    `
    margin-top:0px;
  `}
`

const HeaderButtonGroup = ({ land, custom }) => {
  return (
    <StyledButtonGroup custom={custom}>
      <HeaderButtonNoteApply land={land} />
    </StyledButtonGroup>
  )
}

export default HeaderButtonGroup
