import { Tooltip } from 'antd'
import Axios from 'axios'
import FloatingButton from 'components/design/Button/FloatingButton'
import BaseContext from 'contexts/baseContext'
import { useContext, useEffect, useState } from 'react'
import useCalendarSave from './hooks/useCalendarSave'
import useGetCalendars from './hooks/useGetCalendars'
import NoteCalendarForm from './NoteCalendarForm'

const NoteCalendarSaveButton = ({ currentDate }) => {
  const [visible, setVisible] = useState(false)

  const {
    state: { isFold },
  } = useContext(BaseContext)

  const onClickButton = () => {
    setVisible(true)
  }
  const { _getCalendars } = useGetCalendars()
  const { _saveCalendar, _saveData } = useCalendarSave()

  useEffect(() => {
    const source = Axios.CancelToken.source()
    if (_saveData?.message !== 'success') return
    setVisible(false)
    _getCalendars(source.token)

    return () => {
      source.cancel()
    }
  }, [_saveData])

  const handleSubmit = (values) => {
    _saveCalendar({
      ...values,
      contact_srls: JSON.stringify(values.contact_srls),
    })
  }

  return (
    <>
      {!isFold && (
        <Tooltip title="이 날짜에 일정 추가">
          <FloatingButton
            color="blue"
            id="note_dashboard_calendar_add_button"
            onClick={onClickButton}
          >
            <i className="far fa-plus"></i>
          </FloatingButton>
        </Tooltip>
      )}
      <NoteCalendarForm
        title="선택한 날짜에 일정 추가"
        visible={visible}
        setVisible={setVisible}
        currentDate={currentDate}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default NoteCalendarSaveButton
