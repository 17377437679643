import { notification } from 'antd'
import { modifyCalendar } from 'lib/api/note/calendarApi'
import useAxios from 'lib/hooks/useAxios'
import { useEffect } from 'react'
import NoteCalendarForm from './NoteCalendarForm'

const CalendarModify = ({
  visible,
  setVisible,
  defaultValue,
  onSuccess,
  calendarSrl,
}) => {
  const [onRequest, loading, data, error] = useAxios(modifyCalendar)

  const handleSubmit = (values) => {
    console.log('handleSubmit: ', values)

    onRequest({
      ...values,
      calendarSrl,
      contact_srls: JSON.stringify(values.contact_srls),
    })
  }

  useEffect(() => {
    if (!data?.result) return
    console.log(data)
    onSuccess()
  }, [data])

  useEffect(() => {
    if (!error) return
    notification.error({ message: error })
  }, [error])

  return (
    <NoteCalendarForm
      visible={visible}
      setVisible={setVisible}
      title="일정 수정하기"
      defaultValue={defaultValue}
      onSubmit={handleSubmit}
    />
  )
}

export default CalendarModify
