import { useContext, useEffect } from 'react'
import Slider from 'components/common/Slider/Slider'

import MapContext from 'contexts/map'
import MapFilterItem from './MapFilterItem'
import useMapFilter from 'containers/map/hooks/useMapFilter'

const MapFilterPrice = () => {
  const { state } = useContext(MapContext)
  const { viewState } = state

  const { filterState, handleEndSlider } = useMapFilter()

  const nowYear = new Date().getFullYear()

  return (
    <>
      {viewState.level <= 4 && (
        <>
          <MapFilterItem
            title="경매 및 거래연도"
            titleValue={
              filterState.real_prices.contract_date.toString() ===
              `2006,${nowYear}`
                ? '전체'
                : filterState.real_prices.contract_date
                    .map((value, index) => {
                      return Math.floor(value) + '년'
                    })
                    .toString()
                    .replace(',', ' ~ ')
            }
          >
            <Slider
              range={{ min: 2006, max: nowYear }}
              start={filterState.real_prices.contract_date}
              margin={1}
              step={1}
              connect
              behaviour="snap"
              pips={{
                mode: 'positions',
                values: [0, 100],
                density: 11,
                format: {
                  to: (value) => {
                    return value + '년'
                  },
                },
              }}
              onEnd={handleEndSlider('contract_date')}
            />
          </MapFilterItem>
          <MapFilterItem
            title="총액"
            titleValue={
              filterState.real_prices.real_price.toString() === '0,50'
                ? '전체'
                : filterState.real_prices.real_price
                    .map((value, index) => {
                      if (index === 0 && value === 0) return ''
                      if (index === 1 && value === 50) return ''

                      return value + '억'
                    })
                    .toString()
                    .replace(',', ' ~ ')
            }
          >
            <Slider
              range={{ min: 0, max: 50 }}
              start={filterState.real_prices.real_price}
              step={5}
              margin={5}
              connect
              behaviour="snap"
              pips={{
                mode: 'positions',
                values: [0, 50, 100],
                density: 10,
                format: {
                  to: (value) => {
                    if (value === 0) return '1억 이하'
                    if (value === 50) return '50억 이상'
                    return value + '억'
                  },
                },
              }}
              onEnd={handleEndSlider('real_price')}
            />
          </MapFilterItem>
          <MapFilterItem
            title="토지 면적당 단가"
            titleValue={
              filterState.real_prices.real_lunit_price.toString() ===
              '0,50'
                ? '전체'
                : filterState.real_prices.real_lunit_price
                    .map((value, index) => {
                      if (index === 0 && value === 0) return ''
                      if (index === 1 && value === 50) return ''

                      if (value > 0 && value < 10) return value + '백'
                      if (value >= 10 && value < 50)
                        return value / 10 + '천'
                    })
                    .toString()
                    .replace(',', ' ~ ')
            }
          >
            <Slider
              range={{ min: 0, max: 50 }}
              start={filterState.real_prices.real_lunit_price}
              step={5}
              margin={5}
              connect
              behaviour="snap"
              pips={{
                mode: 'positions',
                values: [0, 50, 100],
                density: 10,
                format: {
                  to: (value) => {
                    if (value === 0) return '1백 이하'
                    if (value === 50) return '5천 이상'

                    if (value > 0 && value < 10) return value + '백'
                    if (value >= 10 && value < 50)
                      return value / 10 + '천'
                  },
                },
              }}
              onEnd={handleEndSlider('real_lunit_price')}
            />
          </MapFilterItem>
          <MapFilterItem
            title="건물 면적당 단가 (연면적 기준)"
            titleValue={
              filterState.real_prices.real_bunit_price.toString() ===
              '0,50'
                ? '전체'
                : filterState.real_prices.real_bunit_price
                    .map((value, index) => {
                      if (index === 0 && value === 0) return ''
                      if (index === 1 && value === 50) return ''

                      if (value > 0 && value < 10) return value + '백'
                      if (value >= 10 && value < 50)
                        return value / 10 + '천'
                    })
                    .toString()
                    .replace(',', ' ~ ')
            }
          >
            <Slider
              range={{ min: 0, max: 50 }}
              start={filterState.real_prices.real_bunit_price}
              step={5}
              margin={5}
              connect
              behaviour="snap"
              pips={{
                mode: 'positions',
                values: [0, 50, 100],
                density: 10,
                format: {
                  to: (value) => {
                    if (value === 0) return '1백 이하'
                    if (value === 50) return '5천 이상'

                    if (value > 0 && value < 10) return value + '백'
                    if (value >= 10 && value < 50)
                      return value / 10 + '천'
                  },
                },
              }}
              onEnd={handleEndSlider('real_bunit_price')}
            />
          </MapFilterItem>
        </>
      )}
    </>
  )
}

export default MapFilterPrice
