import { Button } from 'components/design'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NoteDetailAdListViewButton = ({ adSrl, expand = false }) => {
  const history = useHistory()

  const onClickWrite = () => {
    history.push(`/note/v2/ad/${adSrl}`)
  }

  return (
    <Button
      expand={expand}
      type="button"
      size="small"
      onClick={onClickWrite}
    >
      광고보기
    </Button>
  )
}

export default NoteDetailAdListViewButton
