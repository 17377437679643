import React, { useCallback, useState } from 'react'
import { inputPriceFormat } from 'utils'

export function useInput(initialValue, option) {
  const [value, setValue] = useState(initialValue || '')

  const reset = useCallback(() => {
    setValue(initialValue)
  }, [initialValue])

  const settingValue = (e) => {
    setValue(e)
  }

  const onChange = useCallback((e) => {
    if (option?.numberOnly) {
      setValue(inputPriceFormat(e.target.value.replace(/[^0-9]/g, '')))
    } else {
      setValue(e.target.value || '')
    }
  }, [])

  const bind = {
    value,
    onChange: onChange,
    maxLength: option?.maxLength,
  }

  return [value, bind, reset, settingValue]
}
