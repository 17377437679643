import MapContext from 'contexts/map'
import { getBdsList } from 'lib/api/bds/bdsApi'
import useAxios from 'lib/hooks/useAxios'
import { useInput } from 'lib/hooks/useInput'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { isEqual } from 'lodash'
import {
  resetMarkerClickFilter,
  setAlreadyCenter,
  setFilterSearchFlag,
  setList,
  setRealtorInfo,
  setSearchAptFilter,
  setSelectedAdSrl,
} from 'modules/bds'
import { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 주택부동산 목록
 */
const BdsContentContainer = ({ children }) => {
  const history = useHistory()
  const selectedFilter = useSelector(
    (state) => state.bds.selectedFilter,
  ) //선택된 필터
  const filterSearchFlag = useSelector(
    (state) => state.bds.filterSearchFlag,
  ) //필터 선택될 때 바뀌는 flag
  const markerClickFilter = useSelector(
    (state) => state.bds.markerClickFilter,
  ) //마커를 클릭했을 때 세팅되는 정보
  const selectedRealtorInfo = useSelector(
    (state) => state.bds.selectedRealtorInfo,
  ) // 중개사 모아보기 선택했을 때 정보
  const searchAptFilter = useSelector(
    (state) => state.bds.searchAptFilter,
  ) //검색 목록에서 아파트 클릭했을 때 세팅되는 정보
  const alreadyCenter = useSelector((state) => state.bds.alreadyCenter) //로딩 초기 center point 이동이 완료되었는지 여부
  const selectedAdSrl = useSelector((state) => state.bds.selectedAdSrl) // 광고 목록에서 선택한 광고

  const list = useSelector((state) => state.bds.list) //광고 목록
  const dispatch = useDispatch()
  const count = 10

  const [onRequest, , data] = useAxios(getBdsList) //목록 호출
  const { state, actions } = useContext(MapContext)
  const { map, viewState } = state

  const [input, bindInput, , settingValue] = useInput('') //검색창
  const [address, setAddress] = useState('') //주소(목록 타이틀)
  const [stopLoad, setStopLoad] = useState(false) //데이터 더 이상 없으면 로딩 멈춤(무한 스크롤)
  const { ref: loadTargetRef, inView, entry } = useInView()
  const [firstLoad, setFirstLoad] = useState(false) //목록 중복 방지를 위한 flag
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  const [cookies, setCookie, removeCookie] = useCookies([
    'bdsLastCenterpoint',
  ]) //마지막으로 본 곳 centerpoint

  useEffect(() => {
    return () => {
      dispatch(setList([]))
      setFirstLoad(true)
      setStopLoad(false)
    }
  }, [])

  /**
   * 주택 부동산 centerpoint 쿠키 불러와서 세팅
   */
  useEffect(() => {
    if (Object.keys(map).length == 0) return
    if (!cookies?.bdsLastCenterpoint) return
    if (alreadyCenter) return

    const parseCenter = cookies.bdsLastCenterpoint

    dispatch(setAlreadyCenter(true))
    map.setCenter(new kakao.maps.LatLng(parseCenter.Ma, parseCenter.La))
  }, [map])

  useEffect(() => {
    if (Object.keys(map).length == 0) return
    if (!viewState || Object.keys(viewState).length == 0) return
    if (markerClickFilter?.address_id || searchAptFilter || firstLoad)
      return
    if (selectedAdSrl) {
      dispatch(setSelectedAdSrl(null))
      return
    }
    if (selectedRealtorInfo) {
      history.replace('/bds')
    }
    dispatch(setRealtorInfo(null))
    setStopLoad(false)
    //  dispatch(setList([]))

    const currentBounds = {
      swLatlng: map.getBounds().getSouthWest(),
      neLatlng: map.getBounds().getNorthEast(),
    }

    if (
      !isEqual(currentBounds.swLatlng, viewState.swLatlng) ||
      !isEqual(currentBounds.neLatlng, viewState.neLatlng)
    )
      return

    setPage(1)
    search(1)
    //주택 부동산 centerpoint 쿠키에 저장

    setCookie('bdsLastCenterpoint', JSON.stringify(map.getCenter()))
  }, [viewState])

  /**
   * 마커 클릭 시
   */
  useUpdateEffect(() => {
    if (!markerClickFilter?.address_id || !markerClickFilter?.level)
      return

    //마커의 중심점으로 이동
    map.setCenter(
      new kakao.maps.LatLng(
        markerClickFilter.centerpoint[0],
        markerClickFilter.centerpoint[1],
      ),
    )
    setStopLoad(false)
    setPage(1)
    search(1)
    dispatch(setList([]))
  }, [markerClickFilter])

  /**
   * 필터 선택시 동작
   */
  useUpdateEffect(() => {
    if (Object.keys(map).length == 0) return
    if (!filterSearchFlag) {
      return
    }

    dispatch(setFilterSearchFlag(false))
    localStorage.setItem('bdsFilter', JSON.stringify(selectedFilter))
    setStopLoad(false)
    setPage(1)
    search(1)
    dispatch(setList([]))
  }, [filterSearchFlag])

  /**
   * 아파트 검색시
   */
  useUpdateEffect(() => {
    if (firstLoad || !searchAptFilter || Object.keys(map).length == 0)
      return
    setStopLoad(false)
    setPage(1)
    dispatch(setList([]))
    search(1)
    //주택 부동산 centerpoint 쿠키에 저장
    //  setCookie('bdsLastCenterpoint', JSON.stringify(map.getCenter()))
  }, [searchAptFilter])

  /**
   * 중개사 모아보기 클릭
   */
  useUpdateEffect(() => {
    if (!selectedRealtorInfo) return
    setStopLoad(false)
    dispatch(setList([]))
    search(1)
  }, [selectedRealtorInfo])

  /**
   * 무한스크롤 observer ref 감지
   */
  useEffect(() => {
    if (Object.keys(map).length == 0) return
    if (stopLoad) return
    // inView가 true 일때만 실행한다.

    if (inView) {
      setPage((prev) => prev + 1)
      search(page + 1)
    }
  }, [inView])

  /**
   * 검색
   */
  const search = (pPage) => {
    setLoading(true)
    if (pPage) {
      setPage(pPage)
    }

    let addedFilters = {
      contract_types: selectedFilter.contractType,
      property_types: Object.values(selectedFilter.propertyType).flat(),
      filter_area: selectedFilter.area,
      sale_price: selectedFilter.price.sale_price,
      deposit: selectedFilter.price.deposit,
      rent: selectedFilter.price.rent,
    }

    //중개사 모드일때는 위치 파라미터 없음
    const viewStateParams = selectedRealtorInfo
      ? {}
      : {
          swlat: viewState.swLatlng.getLat(),
          swlng: viewState.swLatlng.getLng(),
          nelat: viewState.neLatlng.getLat(),
          nelng: viewState.neLatlng.getLng(),
          level: viewState.level,
        }

    if (selectedRealtorInfo) {
      //중개사 모아보기 모드
      addedFilters = Object.assign({}, addedFilters, {
        realtor_number: selectedRealtorInfo.realtorNumber,
      })
    } else if (searchAptFilter) {
      addedFilters = Object.assign({}, addedFilters, searchAptFilter)
    } else if (markerClickFilter) {
      addedFilters = Object.assign({}, addedFilters, markerClickFilter)
    } else {
      addedFilters = Object.assign({}, addedFilters, {
        latitude: map.getCenter().getLat(),
        longitude: map.getCenter().getLng(),
      })
    }

    onRequest({
      count: count,
      page: pPage || page,
      ...addedFilters,
      ...viewStateParams,
    })
  }

  useEffect(() => {
    if (!data || !data?.result) {
      setLoading(false)
      setStopLoad(true)
      return
    }

    if (data?.result.length == 0) {
      setLoading(false)
      setStopLoad(true)
      return
    }

    if (data.page == 1) {
      dispatch(setList(data.result))
    } else {
      dispatch(setList([...list, ...data.result]))
    }

    setAddress(data?.address || '')

    if (firstLoad) {
      setFirstLoad(false)
    }

    dispatch(setFilterSearchFlag(false))
    dispatch(setSearchAptFilter(null))
    dispatch(resetMarkerClickFilter())
    setLoading(false)
  }, [data])

  /**
   * input 초기화
   */
  const resetSearch = () => {
    settingValue('')
  }

  /**
   * 중개사 모아보기 > 뒤로가기 초기화 후 검색
   */
  const refreshSearch = () => {
    setStopLoad(false)
    history.replace('/bds')
    dispatch(setList([]))
    setPage(1)
    search(1)
  }

  return children({
    loading,
    page,
    address,
    list,
    input,
    realtorModeData: selectedRealtorInfo,
    loadTargetRef,
    bindInput,
    onSearch: search,
    clearSearch: resetSearch,
    refreshSearch,
  })
}

export default BdsContentContainer
