import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { getBuildingChart } from 'lib/api/chartApi'
import useAxios from 'lib/hooks/useAxios'
import SummaryAptGraphContainer from 'containers/summary/SummaryAptGraphContainer'
import SummaryAptHeader from 'components/summary/SummaryApt/SummaryAptHeader'
import { Card, Empty } from 'components/design'
import styled from 'styled-components'
import { setSelectedAptArea } from 'modules/summary'
import { Skeleton } from 'antd'
import SummaryAptPriceAvg from 'components/summary/SummaryApt/SummaryAptPriceAvg'
import SummaryAptRPTableContainer from './SummaryAptRPTableContainer'

/**
 * 공동주택 실거래 그래프 & 테이블
 * @returns
 */
const SummaryAptRpContainer = ({
  selectedArea,
  setSelectedArea,
  addressId,
  building,
  areaTypes,
  noTitle,
}) => {
  const dispatch = useDispatch()
  const [onRequest, loading, data] = useAxios(getBuildingChart)

  // 선택된 평형과 거래 유형에 해당하는 실거래 데이터
  const [chartData, setChartData] = useState([])

  useUpdateEffect(() => {
    let params = {
      address_id: addressId,
      range: '전체',
    }

    if (selectedArea) {
      params.address_id = selectedArea.address_id
      params.supply_area_py = selectedArea.supply_area_py
      params.purpose = selectedArea.purpose
    }

    onRequest(params, null, false)
  }, [selectedArea])

  useEffect(() => {
    // 세대수가 가장 많은 면적을 우선으로 보여줌 (redux summary - areaTypes)
    const max = Math.max(...areaTypes.map((item) => item.house_count))

    const maxArea = areaTypes.filter((item) => {
      return parseInt(item.house_count) === max
    })?.[0]
    setSelectedArea(maxArea)
    dispatch(setSelectedAptArea(maxArea))
  }, [areaTypes])

  useEffect(() => {
    if (data?.message !== 'success' || !data?.result) return
    setChartData(data.result.data)
  }, [data])

  const isEmpthPurchaseData =
    chartData.filter(({ type }) => type === '매매' || type === '전세')
      ?.length === 0

  return (
    <Card>
      <SummaryAptHeader
        noTitle={noTitle}
        areaTypes={areaTypes}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
      />
      {/* 6개월 평균 */}
      <SummaryAptPriceAvg selectedArea={selectedArea} />
      {!loading && !isEmpthPurchaseData && (
        <SummaryAptGraphContainer chartData={chartData} />
      )}
      {!loading && isEmpthPurchaseData && (
        <Empty>매매/전세 데이터가 없습니다.</Empty>
      )}
      {/* 최근 실거래 테이블 */}
      {!loading && (
        <SummaryAptRPTableContainer
          addressId={addressId}
          data={chartData}
          selectedArea={selectedArea}
          isCommercial={
            selectedArea?.purpose === '상가·사무실' ||
            ['상가·사무실', '숙박'].includes(
              building?.result?.[0]?.custom_purpose,
            )
          }
        />
      )}
      {loading && (
        <SkeletonWrap>
          <Skeleton active />
        </SkeletonWrap>
      )}
    </Card>
  )
}

const SkeletonWrap = styled.div`
  padding: 20px;
`

export default SummaryAptRpContainer
