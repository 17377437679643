import Slider from 'components/common/Slider/Slider'
import MapFilterItem from './MapFilterItem'
import useMapFilter from 'containers/map/hooks/useMapFilter'

const MapFilterBuilding = () => {
  const nowYear = new Date().getFullYear()
  const { filterState, handleEndSliderLayer } = useMapFilter()

  return (
    <>
      <MapFilterItem
        title="건축연도"
        titleValue={
          filterState.building.construction_year.toString() ===
          `${nowYear - 41},${nowYear}`
            ? '전체'
            : filterState.building.construction_year
                .map((value, index) => {
                  return Math.floor(value) + '년'
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <Slider
          range={{ min: nowYear - 41, max: nowYear }}
          start={filterState.building.construction_year}
          margin={2}
          step={2}
          connect
          behaviour="snap"
          pips={{
            mode: 'positions',
            values: [0, 25, 50, 75, 100],
            density: 10,
            format: {
              to: (value) => {
                return value === nowYear - 41
                  ? `~${value}년`
                  : parseInt(value) + '년'
              },
            },
          }}
          onEnd={handleEndSliderLayer('building', 'construction_year')}
        />
      </MapFilterItem>

      <MapFilterItem
        title="연면적"
        titleValue={
          filterState.building.total_floor_area.toString() === '0,360'
            ? '전체'
            : filterState.building.total_floor_area
                .map((value, index) => {
                  if (index === 0 && value === 0) return ''
                  if (index === 1 && value === 360) return ''

                  return Math.floor(value) + '평'
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <Slider
          range={{ min: 0, max: 360 }}
          start={filterState.building.total_floor_area}
          step={10}
          margin={10}
          connect
          behaviour="snap"
          pips={{
            mode: 'positions',
            values: [0, 25, 50, 75, 100],
            density: 10,
            format: {
              to: (value) => {
                if (value === 0) return '~10평'
                if (value === 360) return '360평~'
                return value + '평'
              },
            },
          }}
          onEnd={handleEndSliderLayer('building', 'total_floor_area')}
        />
      </MapFilterItem>

      <MapFilterItem
        title="층수"
        titleValue={
          filterState.building.floor_number.toString() === '1,7'
            ? '전체'
            : filterState.building.floor_number
                .map((value, index) => {
                  if (index === 1 && value === 7) return ''

                  return Math.floor(value) + '층'
                })
                .toString()
                .replace(',', ' ~ ')
        }
      >
        <Slider
          range={{ min: 1, max: 7 }}
          start={filterState.building.floor_number}
          margin={1}
          step={1}
          connect
          behaviour="snap"
          pips={{
            // mode: 'steps',
            // values: 7,
            // density: 14.28,
            mode: 'positions',
            values: [0, 50, 100],
            density: 20,
            format: {
              to: (value) => {
                if (value === 7) return value + '층~'
                return value + '층'
              },
            },
          }}
          onEnd={handleEndSliderLayer('building', 'floor_number')}
        />
      </MapFilterItem>
      {/* TODO: 개별주택가격 */}
      {/* <MapFilterItem
        title="개별주택가격"
        // titleValue={
        //   filterState.floor_number.toString() === '1,7'
        //     ? '전체'
        //     : filterState.floor_number
        //         .map((value, index) => {
        //           if (index === 1 && value === 7) return ''

        //           return Math.floor(value) + '층'
        //         })
        //         .toString()
        //         .replace(',', ' ~ ')
        // }
      >
        <Slider
          range={{ min: 1, max: 7 }}
          start={filterState.building.floor_number}
          margin={1}
          step={1}
          connect
          behaviour="snap"
          pips={{
            // mode: 'steps',
            // values: 7,
            // density: 14.28,
            mode: 'positions',
            values: [0, 50, 100],
            density: 20,
            format: {
              to: (value) => {
                if (value === 7) return value + '층~'
                return value + '층'
              },
            },
          }}
               onEnd={handleEndSliderLayer('building', 'TODO:')}
        />
      </MapFilterItem> */}
    </>
  )
}

export default MapFilterBuilding
