import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SearchContext from 'contexts/searchContext'
import MapContainer from 'components/map/MapContainer'
import BaseNav from 'components/base/BaseNav/BaseNav'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoticeBar from 'components/base/NoticeBar/NoticeBar'
import MapHcRoadViewContainer from 'components/map/MapControl/MapHcRoadViewContainer'
import SideMenu from '../SideMenu/SideMenu'
import SideProfileMenu from '../SideMenu/SideProfileMenu'
import SummaryTopHeader from 'components/summary/header/SummaryTopHeader'
import MapType from '../../map/MapControl/MapType'
import MapFilterGroup from 'components/map/MapFilter/MapFilterGroup'
import MapLayerGroup from 'components/map/MapControl/MapLayerGroup'
import MayLayerHide from 'components/map/MayLayerHide'
import { media } from 'lib/styles/theme'
import MapControlUnit from 'components/map/MapControl/MapControlUnit'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const Layout = ({ children }) => {
  const isMobile = useIsMobile()
  const {
    state: { searchVisible },
  } = useContext(SearchContext)

  const { isVisible: isRoadVieWVisible } = useSelector(
    (state) => state.summary.roadView,
  )

  const { isJMapNote } = useNoteMatch()
  const isNoteListUrl = useRouteMatch('/note/v2/notes/:channelSrl')

  return (
    <>
      <NoticeBar />
      <SideProfileMenu />
      {/* <TopHeader /> */}
      <>
        <SideMenu />
        <BaseNav />
        <SummaryTopHeader />
        {/* {!isTablet && !isMobile && <Filter />} */}
        <MayLayerHide />
        <MapType />
        <CenterFloatGroup>
          <MapFilterGroup />
          <MapLayerGroup />
          <MapControlUnit />
        </CenterFloatGroup>

        {/* <TopHeaderFilterLayer /> */}

        <MapHcRoadViewContainer />
        <StyledMain visible={!isRoadVieWVisible}>
          {searchVisible ? (
            <>{!isMobile && <MapContainer />}</>
          ) : (
            <>
              {children}
              <MapContainer
                hide={
                  localStorage.getItem('notemode') != 'card' &&
                  isJMapNote &&
                  !isNoteListUrl
                }
              />
            </>
          )}
        </StyledMain>
        <SideMenu />
      </>
    </>
  )
}

const CenterFloatGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 10px;
  top: 45%;
  transform: translateY(-45%);
  width: 45px;
  z-index: 13;
  border-radius: 6px;

  ${media.mediumS`
      width: 48px;
  `}

  @media screen and (max-height: 580px) {
    top: 50%;
    transform: translateY(-50%);
  }
`

const StyledMain = styled.main`
  display: flex;
  width: 100%;
  min-height: 100%;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

export default React.memo(Layout)
