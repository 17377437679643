import styled from 'styled-components'
import axios from 'axios'
import { DeleteOutlined } from '@ant-design/icons'

import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import useChannelUsers from 'containers/note/invite/hooks/useChannelUsers'

import { Tag } from 'components/design'

import { ListItem } from 'components/design'
import { ListItemThumbnail } from 'components/design'
import { ListItemLabel } from 'components/design'
import { ListItemDesc } from 'components/design'
import { ListItemMenu } from 'components/design'
import { ListItemDivider } from 'components/design'

import InviteModifyType from './InviteModifyType'
import { Confirm } from 'components/design'

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
const TagWrap = styled.div`
  margin-right: 0.5rem;
`

const InviteListItem = ({ item }) => {
  const { _getChannelUsers } = useChannelUsers(item?.channel_srl)

  const memberTypeNames = {
    owner: '소유자',
    readonly: '읽기만 가능',
  }

  const handleRemoveUser = () => {
    Confirm.error({
      title: '정말로 해당 사용자를 삭제하시겠습니까?',
      content:
        '해당 사용자를 삭제하면 다시 초대하기 전까지 채널에 대한 접근 권한을 잃게 됩니다.',
      okText: '삭제',
      cancelText: '취소',

      onOk: () => onRemoveUser(),
    })
  }

  const onRemoveUser = () => {
    axios
      .post('/jmapnote/api/channelUser/remove', {
        channel_srl: item.channel_srl,
        member_srl: item.member_srl,
      })
      .then(({ data }) => {
        if (!data) return

        _getChannelUsers()
      })
  }

  const items = [
    {
      label: '이 사용자 삭제',
      key: '1',
      icon: <DeleteOutlined />,
    },
  ]

  const menuProps = {
    items,
    onClick: handleRemoveUser,
  }

  return (
    <ListItem
      prepend={<ListItemThumbnail src={item?.profile_image} />}
      append={
        item.member_type !== 'owner' && (
          <ListItemMenu menus={menuProps} top></ListItemMenu>
        )
      }
    >
      <ListItemLabel small>
        <LabelWrap>
          {item.member_type === 'owner' && (
            <TagWrap>
              <Tag size="small" color="primary">
                소유자
                {/* {memberTypeNames[item.member_type]} */}
              </Tag>
            </TagWrap>
          )}
          {item.user_name}
        </LabelWrap>
      </ListItemLabel>
      <ListItemDesc small>{item.phone_number}</ListItemDesc>

      {item.member_type !== 'owner' && (
        <>
          <ListItemDivider xs />
          <InviteModifyType
            memberSrl={item.member_srl}
            originMemberType={item?.member_type}
            channelSrl={item.channel_srl}
          />
        </>
      )}
    </ListItem>
  )
}

export default InviteListItem
