export const feeOptions = {
  부과타입: {
    '01': '정액관리비',
    '02': '기타부과',
    '03': '확인불가',
    '04': '의뢰인 미고지',
  },
  부과기준: {
    '01': '직전월관리비',
    '02': '최근 3개월 관리비 평균',
    '03': '최근 1년 관리비 평균',
    '04': '기타',
  },
  정액관리비세부내역: {
    '01': '공용관리비',
    11: '전기료',
    12: '수도료',
    13: '가스사용료',
    14: '난방비',
    15: '인터넷 사용료',
    16: 'TV사용료',
    99: '기타관리비',
  },
  금액타입: {
    '01': '정액',
    '02': '실비',
  },
  기타부과세부내역: {
    '01': '관리규약 등에 따라 2부과',
    '02': '공용관리비는 면적/세대별로 부과하고 사용료는 사용량에 따른 부과',
    '03': '전체 사용량을 세대수로 나누어 부과',
    '04': '세대별 사용량(별도 계량기)에 따라 부과',
    '06': '정액관리비가 10만원 미만인 경우',
    98: '정액관리비이지만 임대인이 세부내역 미고지하여 기타관리비로 취급',
    99: '기타-직접입력',
  },
  포함항목: {
    11: '전기료',
    12: '수도료',
    13: '가스사용료',
    14: '난방비',
    15: '인터넷 사용료',
    16: 'TV사용료',
  },
  확인불가세부내역: {
    '01': '건축법 시행령 별표1의 제1호 가목의 단독주택',
    '02': '오피스텔 제외 상가 건물에 해당하는 경우',
    '03': '미등기건물 신축건물 등 관리비 내역이 확인불가한 경우',
  },
}
