import NoteDashboardBlock from './NoteDashboardBlock'
import CalendarContainer from 'containers/note/calendar/CalendarContainer'
import NoteCalendarHeader from 'components/jmapnote/calendar/NoteCalendarHeader'
import NoteCalendar from 'components/jmapnote/calendar/NoteCalendar'
import { CalendarProvider } from 'contexts/calendarContext'
import NoteCalendarScheduleDay from 'components/jmapnote/calendar/NoteCalendarScheduleDay'
import NoteCalendarSaveButton from 'pages/jmapnotev2/NoteCalendar/NoteCalendarSaveButton'

const NoteDashboardCalendar = () => {
  return (
    <NoteDashboardBlock title="일정관리">
      <CalendarProvider>
        <CalendarContainer>
          {({ list, currentDate, setCurrentDate, viewMode }) => {
            return (
              <>
                <NoteCalendarHeader
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                />
                <NoteCalendar
                  currentDate={currentDate}
                  viewMode={viewMode}
                />
                <NoteCalendarScheduleDay type="dashboard" />
                <NoteCalendarSaveButton currentDate={currentDate} />
              </>
            )
          }}
        </CalendarContainer>
      </CalendarProvider>
    </NoteDashboardBlock>
  )
}

export default NoteDashboardCalendar
