import { useSelector } from 'react-redux'
import { forwardRef, useContext, useEffect } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { getSummaryLegal } from 'lib/api/summaryApi'
import SummaryLand from 'components/summary/SummaryLand/SummaryLand'
import SummaryLegal from 'components/summary/SummaryLegal/SummaryLegal'
import { setSummaryLegalPlanning } from 'modules/summaryOther'
import { useDispatch } from 'react-redux'

/**
 * 토지, 규제 정보
 * 공통 데이터 공급 컨테이너
 *
 * * 렌더링 여부
 * * 공동주택: △ (토지정보는 X)
 * * 공동주택 아님 : O
 */
const SummaryLandLegalContainer = ({}, ref) => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)
  const isApt = useSelector((state) => state.summary.isApt)
  const dispatch = useDispatch()

  const {
    apt: aptTabRef,
    basicLand: basicLandTabRef,
    basicLegal: basicLegalTabRef,
  } = ref
  const [onRequest, loading, data] = useAxios(getSummaryLegal)

  useEffect(() => {
    if (!summaryInfo?.land?.id) return

    onRequest({
      address_id: summaryInfo?.land?.id,
      lat: summaryInfo?.land?.latitude,
      lng: summaryInfo?.land?.longitude,
    })
  }, [summaryInfo?.land?.id])

  useEffect(() => {
    dispatch(setSummaryLegalPlanning(data?.planning))
  }, [data])

  return (
    <div
      className="summary-tab-active-pass"
      ref={isApt ? aptTabRef : null}
    >
      {/* 토지 정보 */}
      {!isApt && (
        <div ref={basicLandTabRef}>
          <SummaryLand data={data} loading={loading} />
        </div>
      )}
      {/* 규제 정보 */}
      <SummaryLegal
        ref={basicLegalTabRef}
        data={data}
        loading={loading}
      />
    </div>
  )
}

export default forwardRef(SummaryLandLegalContainer)
