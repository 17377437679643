import { DropdownProvider } from './DropdownContext'
import DropdownDialogWrap from './DropdownDialogWrap'

const Dropdown = ({
  visible,
  children,
  overlay,
  onClose,
  dialogProps,
  topPos,
  fixed,
}) => {
  return (
    <DropdownProvider visible={visible}>
      {children}
      <DropdownDialogWrap
        fixed={fixed}
        visible={visible}
        onClose={onClose}
        topPos={topPos}
        {...dialogProps}
      >
        {overlay}
      </DropdownDialogWrap>
    </DropdownProvider>
  )
}

export default Dropdown
