import { useEffect, useState, useCallback, useContext } from 'react'
import axios from 'axios'
import { Modal, notification } from 'antd'
import arrayMove from 'array-move'

import MapContext from 'contexts/map'
import { API_HOST } from 'Constants'
import LoginForm from 'components/login/LoginForm'

import FavoriteSortableContainer from './FavoriteSortableContainer'

import './FavoriteList.scss'
import { Empty } from 'components/design'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'

const FavoriteList = () => {
  const { state, actions } = useContext(MapContext)
  const { favoriteDatas, folderDatas, loginInfo } = state

  const [isOpenFormAddFolder, setIsOpenFormAddFolder] = useState(false) // 폴더 만들기 폼 활성화 여부
  const [folderName, setFolderName] = useState('') // 폴더명

  // 로그인 컴포넌트 열고 닫기
  const [isVisibleLogin, setIsVisibleLogin] = useState(false)
  // 폴더 정렬 이벤트 발생 여부
  const [isFolderSorted, setIsFolderSorted] = useState(false)
  // 즐겨찾기 정렬 이벤트 발생 여부
  const [isFavoriteSorted, setIsFavoriteSorted] = useState(false)

  /**
   * 새 폴더 추가하기
   */
  const handleAddFolder = useCallback(() => {
    if (folderName !== '') {
      axios
        .post('/jmapnote/api/folder/save', {
          name: folderName,
          _rx_csrf_token: loginInfo.csrf_token, // CSRF 토큰 세팅
        })
        .then((response) => {
          if (response.data.message === 'success') {
            setIsOpenFormAddFolder(false)
            // 폴더 목록 업데이트
            axios
              .post(`${API_HOST}/jmapnote/api/folder/getFolders`, {
                _rx_csrf_token: loginInfo.csrf_token,
              })
              .then((response) => {
                if (response.data) {
                  const result = response.data.result
                  actions.setFolderDatas(result)

                  if (response.data.error === -3) {
                    // 로그인창 열기
                    setIsVisibleLogin(true)
                  }
                }
              })
          } else if (response.data.error === -2) {
            openNotification('topLeft', response.data.message)
            setFolderName('')
          }
        })
    } else {
      console.log('폴더명을 입력해 주세요.')
    }
  }, [folderName, loginInfo])

  const openNotification = (placement, description) => {
    notification.warning({
      message: `등록할 수 없습니다!`,
      description: description,
      placement,
    })
  }

  /**
   * 폴더 UI 정렬
   */
  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    actions.setFolderDatas((items) => {
      return arrayMove(items, oldIndex, newIndex)
    })
    // 폴더 정렬 요청 이벤트 발생시키기
    setIsFolderSorted(true)
  }, [])

  /**
   * 폴더 정렬 요청 보내기
   */
  useEffect(() => {
    // folder_srl목록화해서 보내서 list_order 각각 업데이트
    if (isFolderSorted && folderDatas && folderDatas.length > 0) {
      const folderSrlsStr = folderDatas
        .map(({ folder_srl }) => folder_srl)
        .toString()

      if (folderSrlsStr.length > 0) {
        axios
          .post('/jmapnote/api/folder/sort', {
            folder_srls: folderSrlsStr,
            _rx_csrf_token: loginInfo.csrf_token, // CSRF 토큰 세팅
          })
          .then((response) => {
            if (response.data.error === -3) {
              setIsVisibleLogin(true)
            }
            if (response.data.message === 'succeess') {
              // 초기화
              setIsFolderSorted(false)
            }
          })
      }
    }
  }, [isFolderSorted, folderDatas, loginInfo])

  /**
   * 즐겨찾기 UI 정렬
   */
  const handleSortAddress = (value) => {
    console.log('value: ', value)
    actions.setFavoriteDatas(value)
    setIsFavoriteSorted(true)
  }

  /**
   * 즐겨찾기 정렬 요청 보내기
   */
  useEffect(() => {
    // folder_srl목록화해서 보내서 list_order 각각 업데이트
    if (isFavoriteSorted && favoriteDatas && favoriteDatas.length > 0) {
      const addressSrlsStr = favoriteDatas
        .map(({ favorite_id }) => favorite_id)
        .toString()

      // console.log('addressSrlsStr: ', addressSrlsStr)

      // 즐겨찾기 정렬
      if (addressSrlsStr !== '') {
        axios
          .post('/jmap/api/sortFavorite', {
            isFavorite: true,
            address_srls: addressSrlsStr,
            _rx_csrf_token: loginInfo.csrf_token, // CSRF 토큰 세팅
          })
          .then((response) => {
            if (response.data.error === -3) {
              setIsVisibleLogin(true)
            }
            if (response.data.message === 'success') {
              // 초기화
              setIsFavoriteSorted(false)
            }
          })
      }
    }
  }, [isFavoriteSorted, loginInfo])

  return (
    <>
      <div className="widget_wrapper favorite">
        <FavoriteSortableContainer
          items={favoriteDatas}
          updateItems={handleSortAddress}
        />

        {favoriteDatas.length === 0 && (
          <Empty>아직 즐겨찾기된 주소가 없어요!</Empty>
        )}

        <Modal
          title="새로운 폴더 만들기"
          visible={isOpenFormAddFolder}
          onOk={handleAddFolder}
          onCancel={() => {
            setIsOpenFormAddFolder(false)
            setFolderName('')
          }}
          okText="확인"
          cancelText="취소"
          width={360}
        >
          <div>
            <input
              type="text"
              className="map-input"
              placeholder="새로운 폴더 이름 입력"
              autoFocus
              onChange={(e) => setFolderName(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddFolder()
                  setFolderName('')
                }
              }}
            />
          </div>
        </Modal>

        {/* 로그인 컴포넌트 모달 */}
        <LoginForm
          visible={isVisibleLogin}
          onCancel={() => setIsVisibleLogin(false)}
        />

        <Modal
          title="저장 실패 - 유효하지 않은 입력값"
          visible={state.validationMessage !== ''}
          onOk={() => actions.setValidationMessage('')}
          onCancel={() => actions.setValidationMessage('')}
          okText="확인"
          cancelText="취소"
        >
          {state.validationMessage}
        </Modal>
      </div>
    </>
  )
}

export default FavoriteList
