import { useContext, useEffect, useState } from 'react'
import MapContext from 'contexts/map'
import MapFavoriteMarkerItem from './MapFavoriteMarkerItem'
import { useSelector } from 'react-redux'
import theme from 'lib/styles/theme'
import useAxios from 'lib/hooks/useAxios'
import { getPolygon } from 'lib/api/note/noteApi'
import { getNoteMarker } from 'lib/api/layerApi'
import MapSpecificFavoriteMarkerItem from './MapSpecificFavoriteMarkerItem'

const MapSpecificFavoriteMarkerContainer = ({
  polygon,
  setPolygon,
  resetPolygon,
}) => {
  const { state } = useContext(MapContext)
  const { map, viewState } = state
  const specificOpenedMarker = useSelector(
    (state) => state.note.specificOpenedMarker,
  )
  const [marker, setMarker] = useState(null)
  const [onRequest, , data, , ,] = useAxios(getNoteMarker)
  const [onRequestPolygon, , dataPolygon, , ,] = useAxios(getPolygon)

  const [resetMarkerFlag, setResetMarkerFlag] = useState(false)

  useEffect(() => {
    if (!specificOpenedMarker?.note_srl) return

    if (specificOpenedMarker) {
      onRequest({
        channel_srl: specificOpenedMarker.channel_srl,
        note_srl: specificOpenedMarker.note_srl,
      })
    } else {
      if (marker) {
        setResetMarkerFlag(true)
      }
    }
  }, [specificOpenedMarker])

  useEffect(() => {
    if (resetMarkerFlag) {
      setMarker(null)
    }
  }, [resetMarkerFlag])

  useEffect(() => {
    if (!data || !data?.result) return

    setTimeout(() => {
      setMarker(data.result)
    }, 1)
  }, [data])

  useEffect(() => {
    if (!polygon) return

    polygon.setMap(map)

    return () => {
      // 기존 폴리곤 삭제
      polygon?.setMap(null)
    }
  }, [polygon])

  useEffect(() => {
    if (!dataPolygon || !dataPolygon.result) return
    handleSetPolygon(null, dataPolygon.result)
  }, [dataPolygon])

  // 주소 필지 폴리곤 세팅
  const handleSetPolygon = (e = null, item) => {
    e?.stopPropagation()
    // 폴리곤 세팅
    const shape = item.shape?.coordinates || []
    const polygonPath = shape?.map((part) => {
      return part.map((point) => {
        return new kakao.maps.LatLng(point[0], point[1])
      })
    })
    // 지도에 표시할 다각형을 생성합니다
    const polygon = new kakao.maps.Polygon({
      path: polygonPath, // 그려질 다각형의 좌표 배열입니다
      strokeWeight: 1, // 선의 두께입니다
      strokeColor: theme.colors.buildingPolygonColor, // 선의 색깔입니다
      strokeOpacity: 0.9, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      strokeStyle: 'solid', // 선의 스타일입니다
      fillColor: theme.colors.buildingPolygonColor, // 채우기 색깔입니다
      fillOpacity: 0.5, // 채우기 불투명도 입니다
      zIndex: 1,
    })

    setPolygon(polygon)
  }

  const onClickMarker = (e) => {
    e?.stopPropagation()
    onRequestPolygon({
      address_srl: specificOpenedMarker.address_srl,
    })
  }

  if (marker && viewState.level >= 4 && specificOpenedMarker)
    return <MapSpecificFavoriteMarkerItem item={marker} />

  if (marker && viewState.level < 4 && specificOpenedMarker)
    return (
      <>
        <div
          onClick={(e) => onClickMarker(e, marker)}
          key={marker.address_id}
        >
          <MapFavoriteMarkerItem
            resetPolygon={resetPolygon}
            item={marker}
            isSpecific
            resetMarkerFlag={resetMarkerFlag}
            setResetMarkerFlag={setResetMarkerFlag}
          />
        </div>
      </>
    )
}

export default MapSpecificFavoriteMarkerContainer
