import { message, Skeleton, Tooltip } from 'antd'
import { Button, Checkbox } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  Block,
  BlockItem,
  ButtonWrapper,
  StyledCheckbox,
  Title,
  Wrapper,
} from './NotePaymentAllInOneCancelConfirmStep1'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { numberWithCommas } from 'utils'
import NotePaymentAllInOneCancelBubble from './NotePaymentAllInOneCancelBubble'
import useAxios from 'lib/hooks/useAxios'
import { getAllinoneRefundInvoice } from 'lib/api/notev2/paymentApi'
import NotePaymentInvoiceLayout from 'containers/notev2/NotePaymentInvoiceLayout'

/**
 * 올인원 전용
 * 요금제 해지 confirm 모달 step 2
 * @param cancelFn 취소
 * @param toFree 올인원 -> 프리인지
 * @param isRefundable "프리"로 변경할 때 환불 가능 상태인지
 */
const NotePaymentAllInOneCancelConfirmStep2 = ({
  cancelFn,
  toFree,
  isRefundable,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const routerHistory = useHistory()
  const [isChecked, setIsChecked] = useState(false)
  const [onRequestInvoice, , dataInvoice] = useAxios(
    getAllinoneRefundInvoice,
  )
  const [invoice, setInvoice] = useState(null)
  const [totalPrice, setTotalPrice] = useState(null)
  const [card, setCard] = useState(null)
  const [loading, setLoading] = useState(false)

  /**
   * 뒤로가기 제어 추가함
   */
  useEffect(() => {
    history.pushState(null, '', location.href)
    window.addEventListener('popstate', goBack)

    return () => window.removeEventListener('popstate', goBack)
  }, [])

  const onClickCheck = () => {
    setIsChecked(!isChecked)
  }

  const goBack = () => {
    routerHistory.goBack()
  }

  const goNext = () => {
    if (!isChecked) {
      message.warning('해지신청 안내를 읽고 확인에 체크 해주세요.')
      return
    }
    cancelFn()
  }

  useEffect(() => {
    setLoading(true)
    onRequestInvoice({
      plan_srl: planInfo.allinone_plan_srl,
      next_plan: toFree ? 'free' : 'basic',
    })
  }, [])

  useEffect(() => {
    if (!dataInvoice) return
    setLoading(false)
    setInvoice(dataInvoice?.invoice)
    setTotalPrice(dataInvoice?.total_price)
    setCard(dataInvoice?.card?.card)
  }, [dataInvoice])

  return (
    <Wrapper>
      <Title>
        {toFree ? (
          <>
            해지 시 광고 중이던
            <br />
            광고 매물은 복구가 불가해요.
            <SubTitle>정말 해지하시겠습니까?</SubTitle>
          </>
        ) : (
          <>
            베이직으로 변경하면
            <br />
            광고는 복구가 불가해요.
            <SubTitle>정말 변경하시겠습니까?</SubTitle>
          </>
        )}
      </Title>
      <Block>
        <BlockTitle>
          {moment(planInfo.next_payment_date, 'YYYYMMDDHHmmss').format(
            'YYYY년 MM월 DD일',
          )}{' '}
          이후
        </BlockTitle>
        <BlockItem>
          모든 네이버 매물은 <span>광고 게시가 중단</span>됩니다.
        </BlockItem>
        <BlockItem>
          검증 중이던 매물은 <span>자동으로 검증 취소</span>됩니다.
        </BlockItem>
        <BlockItem>
          환불로 인해 광고 기간을 채우지 못하거나 검증 비용이 소요된
          부분에 대해서는 책임지지 않습니다.
        </BlockItem>
      </Block>
      <PaymentBlock>
        <PaymentBlockSection border>
          <PaymentBlockTitle>현재 요금제</PaymentBlockTitle>
          <PaymentBlockPrice>
            <PriceTitle>올인원</PriceTitle>
            <PriceValue>
              {numberWithCommas(planInfo?.price)}원&#47;
              {planInfo?.months}개월
            </PriceValue>
          </PaymentBlockPrice>
        </PaymentBlockSection>
        <PaymentBlockSection>
          <PaymentBlockTitle>새로운 요금제</PaymentBlockTitle>
          <PaymentBlockPrice>
            <PriceTitle>{toFree ? '프리' : '베이직'}</PriceTitle>
            <PriceValue>
              {toFree ? 0 : '9,900'}원<>&#47;월별</>
            </PriceValue>
          </PaymentBlockPrice>
        </PaymentBlockSection>
      </PaymentBlock>
      {isRefundable || (!isRefundable && planInfo?.months == 6) ? (
        // 환불 가능 상태
        loading ? (
          <Skeleton active />
        ) : (
          <InvoiceWrapper>
            {invoice ? (
              <NotePaymentInvoiceLayout
                additionalText={
                  '올인원을 해지하시면 영업일 2일 이내 결제하신 카드로 환불 됩니다.'
                }
                totalPrice={totalPrice}
                card={card}
                data={invoice}
                visiblePeriod
                isRefund
              />
            ) : (
              <>
                환불 데이터를 불러오던 중 오류가 발생했습니다.
                관리자에게 문의해주세요.
              </>
            )}
          </InvoiceWrapper>
        )
      ) : (
        // 환불 불가 상태
        <RefundBlock>
          <RefundBlockTitle>환불금액</RefundBlockTitle>
          <RefundBlockPrice red>
            환불불가
            <NotePaymentAllInOneCancelBubble />
          </RefundBlockPrice>
        </RefundBlock>
      )}
      <StyledCheckbox
        checked={isChecked}
        onChange={onClickCheck}
        color="blue"
      >
        위 {toFree ? '해지' : '변경'} 신청 안내를 읽고 확인하였습니다.
      </StyledCheckbox>
      <ButtonWrapper>
        <Button color="gray" size="xlarge" onClick={goBack}>
          취소
        </Button>
        <Button
          color={toFree ? 'danger' : 'blue'}
          size="xlarge"
          onClick={goNext}
          disabled={
            (!isRefundable && planInfo?.months == 1) ||
            (isRefundable && !invoice)
          }
        >
          {toFree ? '해지' : '결제'}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

const PaymentBlock = styled.div`
  display: flex;
  padding: 32px 0;
  margin-bottom: 12px;
  border-radius: 12px;
  background: #f9fafb;
`

const PaymentBlockSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 50%;

  ${({ border }) =>
    border &&
    `
    border-right: 1px solid ${theme.colors.gray[200]};
  `}
`

const PaymentBlockTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
`

const PaymentBlockPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

const RefundBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
  padding: 18px 24px;
  border-radius: 8px;
  background-color: ${theme.colors.gray[100]};
`

const RefundBlockTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  color: ${theme.colors.gray[400]};

  span {
    color: ${theme.colors.gray[600]};
  }
`

const RefundBlockPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;

  ${({ red }) =>
    red &&
    `
  
    color: ${theme.colors.tint.danger};
  `}
`

const SubTitle = styled.div`
  margin-top: 6px;
  color: ${theme.colors.gray[600]};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
`

const InvoiceWrapper = styled.div`
  margin-bottom: 32px;
  padding: 18px 24px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[150]};
`

const PriceTitle = styled.div`
  color: ${theme.colors.blue[600]};
  font-size: 22px;
  font-weight: 800;
  line-height: 130%;
`

const PriceValue = styled.div`
  color: ${theme.colors.gray[400]};
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
`

const BlockTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
`

export default NotePaymentAllInOneCancelConfirmStep2
