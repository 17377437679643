import { Button } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'
import styled from 'styled-components'
import { getJootekServiceURL } from 'utils'

const SummaryProjectButton = () => {
  const isMobile = useIsMobile()

  return (
    <a
      href={getJootekServiceURL('/projects')}
      target={isMobile ? '_self' : '_blank'}
    >
      <StyledButton
        alignLeft
        size="xlarge"
        color="primary"
        plain
        expand
      >
        <span>JOOTEK 추천 건축모델</span>
        <i className="fal fa-angle-right" />
      </StyledButton>
    </a>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;

  i {
    font-size: 24px;
  }
`
export default SummaryProjectButton
