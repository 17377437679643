import useAxios from 'lib/hooks/useAxios'
import { useEffect, useState } from 'react'
import { AdButton } from './ButtonStyle'
import { message } from 'antd'
import { nupdateListing } from 'lib/api/notev2/adSubmit'
import { useSelector } from 'react-redux'
import { Modal } from 'components/design'

/**
 * 광고 목록 버튼
 * 수정요청
 * @param adSrl
 * @param callbackFn 삭제 후 목록 재조회
 * @param setUpdatedAdSrl 재조회를 위해 저장하는 ad_srl
 * @param forForm 폼에서 사용하는지 여부
 */
const UpdateButton = ({
  adSrl,
  callbackFn,
  setUpdatedAdSrl,
  forForm,
}) => {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState(false)

  const [onRequestUpdate, , dataUpdate] = useAxios(nupdateListing, {
    resetError: true,
  }) //수정하기

  /**
   * 광고중, 종료 예정시 수정하기 버튼
   */
  const onClickUpdate = (adSrl) => {
    setIsConfirmModalVisible(true)
  }

  /**
   * confirm 모달에서 확인 눌렀을때
   */
  const onOk = () => {
    onRequestUpdate({
      ad_srl: adSrl,
      plan_srl: planInfo.allinone_plan_srl,
    })
    setUpdatedAdSrl && setUpdatedAdSrl(adSrl)
  }

  /**
   * 수정 요청 완료
   */
  useEffect(() => {
    if (!dataUpdate) return

    if (dataUpdate?.result?.errormsg) {
      message.error(
        dataUpdate?.result?.errormsg || '요청이 실패하였습니다.',
      )
    } else if (dataUpdate?.result == 'error') {
      message.error(
        dataUpdate?.result?.errormsg ||
          '광고 전송에 필요한 항목 검증에 실패하였습니다.',
      )
      return
    } else if (dataUpdate?.result?.result == 'success') {
      message.success('수정 요청이 완료되었습니다.')
      callbackFn && callbackFn()
    } else {
      message.error('요청중 오류가 발생했습니다.')
      return
    }
  }, [dataUpdate])

  return (
    <>
      <AdButton
        expand
        color={forForm ? 'blue' : 'default'}
        forForm={forForm}
        size={forForm ? 'xlarge' : 'small'}
        onClick={() => onClickUpdate(adSrl)}
      >
        수정요청
      </AdButton>

      <Modal
        overflowVisible
        title={'알림'}
        visible={isConfirmModalVisible}
        onOk={onOk}
        onCancel={() => setIsConfirmModalVisible(false)}
        okText="수정요청"
        cancelText="취소"
        okButtonProps={{ color: 'blue' }}
      >
        이 매물을 수정요청 하시겠습니까?
      </Modal>
    </>
  )
}

export default UpdateButton
