import { Empty, Modal } from 'components/design'
import CheckoutPage from './Checkout'

/**
 * 등기부 결제 - 토스로 결제
 */
const NotePaymentDeunggibuCheckoutModal = ({
  visible,
  onCancel,
  paymentResult,
  amount,
  count,
}) => {
  return (
    <Modal
      title="결제 주문서"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {paymentResult ? (
        <CheckoutPage
          amount={amount}
          count={count}
          customerKey={paymentResult.customer_key}
          customerName={paymentResult.customer_name}
          orderId={paymentResult.order_id}
          customerEmail={paymentResult.customer_email}
          orderName={`등기부등본`}
          type="등기부등본"
        />
      ) : (
        <Empty>결제 정보가 없습니다. 다시 시도해주세요</Empty>
      )}
    </Modal>
  )
}

export default NotePaymentDeunggibuCheckoutModal
