import SummarySchoolAmenityContainer from 'containers/summary/SummarySchoolAmenityContainer'
import { Section, Title } from './BdsDetailStyle'
import { useMemo } from 'react'
import styled from 'styled-components'

const BdsDetailSchool = ({ data, tabs }) => {
  const isApt = useMemo(
    () =>
      ['아파트', '오피스텔', '연립주택', '다세대주택'].includes(
        data?.property_type || '',
      ),
    [data?.property_type],
  )

  return (
    <>
      <Wrapper ref={tabs[3].element}></Wrapper>
      <SummarySchoolAmenityContainer
        addressId={data.address_id}
        latitude={data.latitude}
        longitude={data.longitude}
        isApt={isApt}
      />
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 1px;
`

export default BdsDetailSchool
