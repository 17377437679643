import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from 'Constants'
import styled from 'styled-components'

import theme from 'lib/styles/theme'
import FavoriteItem from './FavoriteItem'

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  list-style: none;

  & > a,
  & > article {
    flex: 1;
  }
`

export const ListItemHandle = styled.div`
  cursor: grab;
  flex: 0 0 auto;
  color: ${theme.colors.gray[400]};
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: ${theme.colors.gray[500]};
  }
`

const FavoriteSortableElement = ({ id, item, index, moveItem }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.favorite,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.favorite,
    item: {
      address_srl: item.address_srl,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const draggingStyle = {
    opacity: 0.5,
    backgroundColor: theme.colors.gray[100],
  }
  // drag(drop(ref));
  preview(drop(ref))

  return (
    <StyledListItem ref={ref} style={isDragging ? draggingStyle : {}}>
      <ListItemHandle ref={drag} data-handler-id={handlerId}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </ListItemHandle>
      <FavoriteItem favorite={item} />
    </StyledListItem>
  )
}

export default FavoriteSortableElement
