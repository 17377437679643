import { useSelector } from 'react-redux'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import { Button } from 'components/design'
import { AccountInput } from './NoteAccount'

/**
 * 계정관리 - 중개업등록번호 검증
 * @returns
 */
const NoteAccountRealtorNumber = ({
  disabled,
  isValidRealtorNumber,
  realtorMessage,
  searchedAddrCode,
  canEdit,
}) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  // 회원정보 폼
  const { register, watch } = useFormContext()

  const realtor_number = watch('realtor_number')

  return disabled ? (
    <div>
      <div>{realtor_number}</div>
      {isValidRealtorNumber && (
        <Desc ok>본인인증이 완료되었습니다.</Desc>
      )}
    </div>
  ) : (
    <>
      <RealtorNumberWrapper>
        <RealtorSearch>
          <AccountInput
            name="realtor_number"
            defaultValue={loginInfo?.realtor_number}
            design={'box'}
            readOnly={!canEdit && (disabled || isValidRealtorNumber)}
            {...register('realtor_number')}
          />
          {/* {!disabled && !isValidRealtorNumber && (
            <StyledSearchButton
              color="blue"
              onClick={onClickRealtorNumber}
            >
              검색
            </StyledSearchButton>
          )} */}
        </RealtorSearch>
        <>
          {realtorMessage && <Desc>{realtorMessage}</Desc>}
          {isValidRealtorNumber && (
            <Desc ok>본인인증이 완료되었습니다.</Desc>
          )}
          {/* {!isValidRealtorNumber && loginInfo?.validated && (
            <Desc>
              회사 소재지가 변경되었습니다. 중개업번호를 다시
              인증해주세요.
            </Desc>
          )}
          {!loginInfo?.validated &&
            !isValidRealtorNumber &&
            !realtorMessage && (
              <Desc basic>
                소재지 입력 후, 검색 버튼을 눌러 중개업등록번호를
                인증해주세요.
              </Desc>
            )} */}
        </>
      </RealtorNumberWrapper>
    </>
  )
}

const Desc = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: ${theme.colors.tint.danger};

  ${({ ok }) => ok && `color: ${theme.colors.primary[500]}`};
  ${({ basic }) => basic && `color: ${theme.colors.gray[700]}`};
`

const RealtorNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const RealtorSearch = styled.div`
  display: flex;
  align-items: center;
`

export default NoteAccountRealtorNumber
