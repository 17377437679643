import { useEffect, useState } from 'react'

import NotificationList from 'components/notification/NotificationList'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenFilterType } from 'modules/map'
import useIsMobile from 'lib/hooks/useIsMobile'

const NotificationContainer = ({ listData }) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [data, setData] = useState([])

  /**
   * @description 알림 정보가 user 정보 API에 포함되어 있기
   * 때문에 알림 페이지 접속 시 user 정보를 불러오도록 함.
   * - useUserLoader는 인자인 immediate를 false로 보내주지 않는 한
   * 실행만 해도 자동으로 user 정보를 불러오도록 되어 있음.
   */
  useUserLoader()

  const notifications = useSelector(
    (state) => state.notification.notifications,
  )

  useEffect(() => {
    if (isMobile) {
      dispatch(setOpenFilterType(null))
    }
    if (!notifications) return

    if (listData) {
      //특정 알림 데이터가 있으면 그것만 보여줌
      const specificNotiData = listData.map(({ notify }) => notify)

      setData(
        notifications.filter(({ notify }) =>
          specificNotiData.includes(notify),
        ),
      )
    } else {
      setData(notifications)
    }
  }, [notifications, listData])

  return (
    <>
      <NotificationList notifications={data} />
    </>
  )
}

export default NotificationContainer
