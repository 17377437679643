import { updateAdListing } from 'lib/api/notev2/adForm'
import { useFormContext } from 'react-hook-form'
import useAxios from '../useAxios'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 광고 form 개별 업데이트 hook
 */
function useAdUpdate() {
  const planInfo = useSelector((state) => state.auth.planInfo)
  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const { setValue, watch } = useFormContext()

  const [onRequestSave, , dataSave] = useAxios(updateAdListing, {
    resetError: true,
  })

  const adSrl = watch('ad_srl')

  const initSaveValue = { key: '', value: null }

  const isDisabled =
    adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll //읽기 전용 여부

  const [currentData, setCurrentData] = useState(initSaveValue)

  /**
   * 개별 컬럼 업데이트
   * @param key 컬럼 이름
   * @param value 값
   * @param multiple 업데이트할 파라미터가 복수인지
   */
  const onUpdate = (key, value, multiple = false) => {
    if (isDisabled) return
    if (multiple) {
      //TODO:
    } else {
      const param = { [key]: value }
      //   setValue(key, value)
      // setCurrentData({ key, value })
      setValue(key, value)
      onRequestSave({
        ad_srl: adSrl,
        update_packets: param,
        plan_srl: planInfo.allinone_plan_srl,
      })
    }
  }

  //저장 성공 시 setValue
  // useEffect(() => {
  //   if (!dataSave) return

  //   if (dataSave.error == 0) {
  //     setValue(currentData.key, currentData.value)
  //     setCurrentData(initSaveValue)
  //   }
  // }, [dataSave])

  return { onUpdate }
}
export default useAdUpdate
