import styled from 'styled-components'
import SummarySchoolListItem from './SummarySchoolListItem'
import { ReactComponent as SchoolIcon } from 'assets/icon/amenities/school_info.svg'
import { ReactComponent as FlagIcon } from 'assets/icon/amenities/flag.svg'
import { getDistance } from 'lib/utils/summary'
import {
  Distance,
  Item,
  Name,
  Title,
} from '../SummaryAmenities/SummaryAmenitiesListItem'
import theme from 'lib/styles/theme'

/**
 * 학군 데이터 모두 진학률 0일 때 List Item
 * is_district Y/N(배정 여부)에 따라 다르게 보여줌
 */
const SummarySchoolDistrictListItem = ({
  isDistrict,
  establishedKind,
  classStudentCnt,
  schoolName,
  distance,
}) => {
  return isDistrict == 'Y' ? (
    <Block>
      <District>
        <FlagIcon /> <span>배정</span>
        <span>{establishedKind}</span>
        <span>학급당 {classStudentCnt}명</span>
      </District>
      <BlockTitle>{schoolName}</BlockTitle>
      <StyledDistance>{getDistance(distance)}</StyledDistance>
    </Block>
  ) : (
    <>
      <StyledItem>
        <TitleWrapper>
          <StyledTitle>
            <SchoolIcon />
            <Name>{schoolName}</Name>
          </StyledTitle>
          <Info>
            {establishedKind} &#124; 학급당 {classStudentCnt}명
          </Info>
        </TitleWrapper>
        <Distance>{getDistance(distance)}</Distance>
      </StyledItem>
    </>
  )
}

const Block = styled.div`
  position: relative;
  padding: 12px 20px;
  margin-bottom: 4px;
  background-color: ${theme.colors.gray[50]};
  border-radius: 8px;
`

const BlockTitle = styled.div`
  line-height: 150%;
  font-size: 14px;
  font-weight: 600;
`

const District = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  color: $color-gray-600;
  line-height: 150%;

  svg {
    margin-right: 5px;
  }

  span {
    color: ${theme.colors.gray[600]};

    &:not(:nth-of-type(1)) {
      &::before {
        content: '|';
        padding: 0 3px;
        display: inline;
      }
    }
  }
`

const Info = styled.div`
  margin-top: 4px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: ${theme.colors.gray[500]};
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled(Title)`
  align-items: flex-start;
`

const StyledItem = styled(Item)`
  height: auto;

  &:not(&:last-child) {
    margin-bottom: 12px;
  }
`

const StyledDistance = styled(Distance)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
`

export default SummarySchoolDistrictListItem
