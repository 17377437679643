import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'

const NoteAdColumnVerificationTypeDescN = ({ type }) => {
  return (
    <>
      <Info
        className="fas fa-question-circle"
        data-tooltip-id="verification-tooltip-n"
      />
      <Tooltip
        id="verification-tooltip-n"
        border={`1px solid ${theme.colors.gray[300]}`}
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.03)`,
          zIndex: 9999,
          padding: '18px 16px',
          width: '340px',
        }}
      >
        <>
          <Title>신홍보확인서(집주인)</Title>
          <Desc>
            <Item>
              신홍보확인서 확인이란?
              <ItemDotInfo>
                신홍보확인서를 통해 등록한 매물의 진정성, 거래가능 여부
                등을 확인하는 매물검증방법
              </ItemDotInfo>
            </Item>
            <Item>
              의뢰인에게 매물 검증, 개인정보 제공, 매물 등록에 대한
              동의를 받아야 합니다.
            </Item>
            <Item>
              홍보확인서양식으로 제출하지 않고 공인중개사가 매물 등록시
              입력한 값으로 제출됩니다.
            </Item>
            <Item>
              매물의 소유자에 따라 아래와 같이 선택하여 등록합니다.
              <ItemDotInfo>
                소유자구분 :
                <strong>
                  개인매물, 법인매물, 외국인매물, 위임장매물
                </strong>
              </ItemDotInfo>
              <ItemInfo>개인매물 : 의뢰인이 소유자인 매물</ItemInfo>
              <ItemInfo>
                외국인매물 : 외국인 소유자가 의뢰한 매물
              </ItemInfo>
              <ItemInfo>
                법인매물 : 법인(회사, 종교, 종친) 매물{' '}
              </ItemInfo>
              <ItemInfo>
                위임장매물 : 소유자가 의뢰인이 아닌 매물{' '}
              </ItemInfo>
              <ItemDotInfo>
                위임장 첨부를 해야 하며 위임장은 날인(서명시 실패)으로만
                가능합니다.
              </ItemDotInfo>
              <ItemDotInfo>
                매물 의뢰인은 공인중개업소가 될 수 없습니다.
              </ItemDotInfo>
              <ItemDotInfo>
                위임자와 위임받는자 주소(지번포함)는 모두 기재하여야
                합니다.
              </ItemDotInfo>
            </Item>
            <Item>
              실명확인 검증
              <ItemDotInfo>
                의뢰인의 이름과 핸드폰번호로 네이버 회원정보를 통해 실명
                확인
              </ItemDotInfo>
              <ItemInfo>
                네이버아이디를 기재한 경우에는 아이디와 이름으로 확인
              </ItemInfo>
              <ItemDotInfo>
                의뢰인이 네이버 회원이 아닌 경우 신홍보확인서 등록불가.
              </ItemDotInfo>
              <ItemDotInfo>
                소유주가 네이버 회원이 아닐경우 위임장 매물 또는
                모바일v2 검증이 가능합니다.
              </ItemDotInfo>
            </Item>
          </Desc>
        </>
      </Tooltip>
    </>
  )
}

const Info = styled.i`
  margin-left: 2px;
  color: ${theme.colors.gray[400]};
  z-index: 999;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};
`

const Item = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.gray[900]};

  &::before {
    content: '\f00c';
    ${({ multiple }) => multiple && `content: '\f00c'`};
    font-family: 'Font Awesome 5 Pro';
    margin-right: 4px;
    color: ${theme.colors.blue[400]};
  }
`

const ItemInfo = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;

  &::before {
    content: '\f05a';
    font-family: 'Font Awesome 5 Pro';
    color: ${theme.colors.blue[400]};
    margin-left: 18px;
    margin-right: 4px;
    font-weight: 400;
  }
`

const ItemDotInfo = styled.div`
  margin-left: 18px;
  color: ${theme.colors.gray[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  &::before {
    content: '·';
    margin-right: 4px;
  }
`

const Desc = styled.div`
  margin-top: 10px;

  ${({ nomargin }) => nomargin && `margin: 0;`};
`
export default NoteAdColumnVerificationTypeDescN
