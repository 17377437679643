import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useChannelInfo from './hooks/useChannelInfo'

const ChannelContainer = ({ children }) => {
  const params = useParams()
  const channels = useSelector((state) => state.channel.channels)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [selectedChannelInfo, setSelectedChannelInfo] = useState(null)

  const { _getChannel, status } = useChannelInfo()

  useEffect(() => {
    const channelSrl = params?.channelSrl
    if (
      channelInfo?.channel_srl == channelSrl ||
      !channels ||
      channels.length == 0
    )
      return

    const selectedChannel = channels.find(({ channel_srl }) => {
      return channelSrl == channel_srl
    })

    if (!selectedChannel) {
      return
    }

    setSelectedChannelInfo(selectedChannel)
    // dispatch(setChannelInfo(selectedChannel))
    // dispatch(dispatchSetSelectedChannelInfo(selectedChannel))
    _getChannel({ channelSrl: selectedChannel?.channel_srl })
  }, [params?.channelSrl])

  useEffect(() => {
    if (!status || !selectedChannelInfo) return

    if (status == 'success') {
      localStorage.setItem(
        'recentChannelSrl',
        selectedChannelInfo.channel_srl,
      )
      localStorage.setItem(
        'recentFolderSrl',
        selectedChannelInfo.folder_srl,
      )
    }
  }, [status])

  return children
}

export default ChannelContainer
