import { useEffect, useRef, useState } from 'react'
import {
  PaymentWidgetInstance,
  loadPaymentWidget,
  ANONYMOUS,
} from '@tosspayments/payment-widget-sdk'
import { Button } from 'components/design'
import styled from 'styled-components'
import moment from 'moment'
import theme from 'lib/styles/theme'

const clientKeyByDev = 'test_ck_GKNbdOvk5rkAZb7ELmz3n07xlzmj'
const clientKeyByProcduction = 'live_ck_4Gv6LjeKD8a1pqkQ2oN8wYxAdXy1'

/**
 * 결제 모달
 * @param {*} emergencyInfo emergecy = "Y" 인 경우면 필요
 * emergencyInfo : {
    note_srl,
    channel_srl,
    address_srl,
    sub_type,
    valid_only,
    address,
    detail,
    unique_no,
  },
 * @returns 
 */
export default function CheckoutPage({
  customerKey,
  amount,
  count,
  orderId,
  customerName,
  customerEmail,
  orderName,
  type,
  expireDate,
  isEmergency = false,
  emergencyInfo,
}) {
  const paymentWidgetRef = useRef(null)
  const paymentMethodsWidgetRef = useRef(null)
  const [price, setPrice] = useState(amount)

  useEffect(() => {
    ;(async () => {
      const clientKey =
        process.env.NODE_ENV === 'development'
          ? clientKeyByDev
          : clientKeyByProcduction
      // ------  결제위젯 초기화 ------
      // 비회원 결제에는 customerKey 대신 ANONYMOUS를 사용하세요.
      const paymentWidget = await loadPaymentWidget(
        clientKey,
        customerKey,
      ) // 회원 결제
      // const paymentWidget = await loadPaymentWidget(clientKey, ANONYMOUS)  // 비회원 결제

      // ------  결제위젯 렌더링 ------
      // 결제수단 UI를 렌더링할 위치를 지정합니다. `#payment-method`와 같은 CSS 선택자와 결제 금액 객체를 추가하세요.
      // DOM이 생성된 이후에 렌더링 메서드를 호출하세요.
      // https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        '#payment-widget',
        price,
      )

      // ------  이용약관 렌더링 ------
      // 이용약관 UI를 렌더링할 위치를 지정합니다. `#agreement`와 같은 CSS 선택자를 추가하세요.
      // https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자
      paymentWidget.renderAgreement('#agreement')

      paymentWidgetRef.current = paymentWidget
      paymentMethodsWidgetRef.current = paymentMethodsWidget
    })()
  }, [])

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current

    if (paymentMethodsWidget == null) {
      return
    }

    // ------ 금액 업데이트 ------
    // 새로운 결제 금액을 넣어주세요.
    // https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
    paymentMethodsWidget.updateAmount(
      price,
      paymentMethodsWidget.UPDATE_REASON.COUPON,
    )
  }, [price])

  return (
    <div>
      <Order>
        {orderName} {count}개
      </Order>
      {type != '등기부등본' && (
        <Date>
          {type == '신규' && (
            <>
              제공기간:{' '}
              {`${moment().format('MM월 DD일')}부터 ${moment()
                .add(1, 'month')
                .format('MM월 DD일')}까지`}
            </>
          )}
          {type == '갱신' && (
            <>
              제공기간:{' '}
              {`${moment(expireDate, 'YYYYMMDDHHmmss').format(
                'MM월 DD일',
              )}부터 ${moment(expireDate, 'YYYYMMDDHHmmss')
                .add(1, 'month')
                .format('MM월 DD일')}까지`}
            </>
          )}
        </Date>
      )}
      <Price>{`${price.toLocaleString()}원`}</Price>
      <WidgetWrapper>
        <div id="payment-widget" />
      </WidgetWrapper>
      <WidgetWrapper>
        <div id="agreement" />
      </WidgetWrapper>
      <ButtonWrapper>
        <Button
          color="blue"
          onClick={async () => {
            const paymentWidget = paymentWidgetRef.current

            try {
              // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
              // 더 많은 결제 정보 파라미터는 결제위젯 SDK에서 확인하세요.
              // https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
              await paymentWidget?.requestPayment({
                orderId: orderId,
                orderName: orderName,
                customerName: customerName,
                customerEmail: customerEmail,
                successUrl: isEmergency
                  ? `${
                      window.location.origin
                    }/jmapnote/api/payment/success?channel_srl=${
                      emergencyInfo.channel_srl
                    }&address_srl=${
                      emergencyInfo.address_srl
                    }&note_srl=${emergencyInfo.note_srl}&sub_type=${
                      emergencyInfo.sub_type
                    }&valid_only=${
                      emergencyInfo.valid_only ? 'Y' : 'N'
                    }&address=${emergencyInfo.address}&detail=${
                      emergencyInfo.detail
                    }&unique_no=${emergencyInfo.unique_no}`
                  : `${window.location.origin}/jmapnote/api/payment/success`,
                failUrl: `${window.location.origin}/jmapnote/api/payment/fail`,
              })
            } catch (error) {
              // 에러 처리하기
              console.error(error)
            }
          }}
        >
          결제하기
        </Button>
      </ButtonWrapper>
    </div>
  )
}

const Price = styled.div`
  font-size: 30px;
  font-weight: 700;
`

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
`

const WidgetWrapper = styled.div`
  margin-left: -20px;
  margin-right: -20px;
`

const Order = styled.div`
  font-size: 16px;
  font-weight: 500;
`

const Date = styled.div`
  font-size: 14px;
  color: ${theme.colors.gray[600]};
  margin-bottom: 10px;
  font-weight: 400;
`
