import React, { useCallback, useState } from 'react'
import FixedLayer from 'components/common/FixedLayer/FixedLayer'
import NoteDocsLayerTab from './NoteDocsLayerTab'
import styled from 'styled-components'
import NoteDocsLayerDeunggibu from './NoteDocsLayerDeunggibu'
import Scrollbars from 'react-custom-scrollbars-2'
import { LayerWrapper } from '../../style/NoteStyle'
import NoteDocsLayerBuildingAndLand from './NoteDocsLayerBuildingAndLand'
import NoteDocsLayerLandUse from './NoteDocsLayerLandUse'
import NoteDocsLayerNoteDetail from './NoteDocsLayerNoteDetail'
import NoteDocsLayerControl from './NoteDocsLayerControl'
import { useSelector } from 'react-redux'

const NoteDocsLayer = ({ visible, onClose }) => {
  const [activeTab, setActiveTab] = useState(1)
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const onChangeTab = useCallback((tabIndex) => {
    setActiveTab(tabIndex)
  }, [])

  return (
    <FixedLayer onClose={onClose} visible={visible} hasDimmer={false}>
      {channelInfo?.member_type != 'readonly' && (
        <NoteDocsLayerControl />
      )}

      <TabWrapper>
        <NoteDocsLayerTab
          activeTab={activeTab}
          onChangeTab={onChangeTab}
        />
        <Scrollbars autoHide>
          <LayerWrapper>
            {activeTab == 1 && <NoteDocsLayerDeunggibu />}
            {activeTab == 2 && (
              <NoteDocsLayerBuildingAndLand type="docs" />
            )}
            {activeTab == 3 && <NoteDocsLayerLandUse />}
            {activeTab == 4 && <NoteDocsLayerNoteDetail />}
          </LayerWrapper>
        </Scrollbars>
      </TabWrapper>
    </FixedLayer>
  )
}

const TabWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 57px;
`

export default React.memo(NoteDocsLayer)
