import { BottomBlock, ContractWrapper } from '../../style/NoteStyle'
import NoteDocsDescHeader from './NoteDocsDescHeader'
import Loading from 'components/common/Loading/Loading'
import NoteDocsPay from './Pay/NoteDocsPay'
import { useFormContext } from 'react-hook-form'
import { TableTitle } from 'components/jmapnotev2/style/NoteDocsStyle'
import NoteDocsBasic1 from './Basic/NoteDocsBasic1'
import NoteDocsBasic2 from './Basic/NoteDocsBasic2'
import NoteDocsBasic3 from './Basic/NoteDocsBasic3'
import NoteDocsBasic4 from './Basic/NoteDocsBasic4'
import NoteDocsBasic5 from './Basic/NoteDocsBasic5'
import NoteDocsBasic6 from './Basic/NoteDocsBasic6'
import NoteDocsBasic7 from './Basic/NoteDocsBasic7'
import NoteDocsBasic8 from './Basic/NoteDocsBasic8'
import NoteDocsDetail9 from './Detail/NoteDocsDetail9'
import NoteDocsDetail10 from './Detail/NoteDocsDetail10'
import NoteDocsDetail11 from './Detail/NoteDocsDetail11'
import NoteDocsDetail12 from './Detail/NoteDocsDetail12'
import NoteDocsBasic4Residential from './Basic/NoteDocsBasic4Residential'
import NoteDocsDetail14 from './Detail/NoteDocsDetail14'

/**
 * 확인설명서 작성폼
 * @returns
 */
const NoteDocsDescription = ({
  onUpdate,
  onUpdateCheckbox,
  isBuildingGongbuLoading,
  onUpdateMultiple,
}) => {
  const { watch, setValue } = useFormContext()

  const d_descript_type = watch('d_descript_type')

  if (isBuildingGongbuLoading) {
    return <Loading />
  }

  if (d_descript_type == '주거용 건축물') {
    return (
      <div id="print-map-note-description">
        <div id="print-map-note-description-1">
          <NoteDocsDescHeader
            onUpdate={onUpdate}
            onUpdateCheckbox={onUpdateCheckbox}
          />
          <TableTitle>I. 개업공인중개사 기본 확인사항</TableTitle>
          <NoteDocsBasic1 onUpdate={onUpdate} />
          <NoteDocsBasic2 onUpdate={onUpdate} />
          <NoteDocsBasic3 onUpdate={onUpdate} />
          <NoteDocsBasic4Residential
            onUpdate={onUpdate}
            onUpdateMultiple={onUpdateMultiple}
          />
        </div>
        <div id="print-map-note-description-2">
          <NoteDocsBasic4 onUpdate={onUpdate} />
          <NoteDocsBasic5
            onUpdate={onUpdate}
            onUpdateCheckbox={onUpdateCheckbox}
          />
          <NoteDocsBasic6 onUpdate={onUpdate} />
          <NoteDocsBasic7 onUpdate={onUpdate} />
          <NoteDocsBasic8 onUpdate={onUpdate} />
          <TableTitle>II. 개업공인중개사 세부 확인사항</TableTitle>
          <NoteDocsDetail9 onUpdate={onUpdate} />
          <NoteDocsDetail10 onUpdate={onUpdate} />
        </div>
        <div id="print-map-note-description-3">
          <NoteDocsDetail11 onUpdate={onUpdate} />
          <NoteDocsDetail12 onUpdate={onUpdate} />
          <NoteDocsDetail14 onUpdate={onUpdate} />
          <NoteDocsPay onUpdate={onUpdate} />
        </div>
        <BottomBlock />
      </div>
    )
  } else if (d_descript_type == '비주거용 건축물') {
    return (
      <div id="print-map-note-description">
        <div id="print-map-note-description-1">
          <NoteDocsDescHeader
            onUpdate={onUpdate}
            onUpdateCheckbox={onUpdateCheckbox}
          />
          <TableTitle>I. 개업공인중개사 기본 확인사항</TableTitle>
          <NoteDocsBasic1 onUpdate={onUpdate} />
          <NoteDocsBasic2 onUpdate={onUpdate} />
          <NoteDocsBasic3 onUpdate={onUpdate} />
        </div>
        <div id="print-map-note-description-2">
          <NoteDocsBasic4 onUpdate={onUpdate} />
          <NoteDocsBasic5 onUpdate={onUpdate} />
          <NoteDocsBasic7 onUpdate={onUpdate} />
          <NoteDocsBasic8 onUpdate={onUpdate} />
          <TableTitle>II. 개업공인중개사 세부 확인사항</TableTitle>
          <NoteDocsDetail9 onUpdate={onUpdate} />
          <NoteDocsDetail10 onUpdate={onUpdate} />
          <NoteDocsDetail11 onUpdate={onUpdate} />
        </div>
        <div id="print-map-note-description-3">
          <NoteDocsPay onUpdate={onUpdate} />
        </div>
        <BottomBlock />
      </div>
    )
  } else if (d_descript_type == '토지') {
    return (
      <div id="print-map-note-description">
        <div id="print-map-note-description-1">
          <NoteDocsDescHeader
            onUpdate={onUpdate}
            onUpdateCheckbox={onUpdateCheckbox}
          />
          <TableTitle>I. 개업공인중개사 기본 확인사항</TableTitle>
          <NoteDocsBasic1 onUpdate={onUpdate} />
          <NoteDocsBasic2 onUpdate={onUpdate} />
          <NoteDocsBasic3 onUpdate={onUpdate} />
        </div>
        <div id="print-map-note-description-2">
          <NoteDocsBasic4 onUpdate={onUpdate} />
          <NoteDocsBasic6 onUpdate={onUpdate} />
          <NoteDocsBasic7 onUpdate={onUpdate} />
          <NoteDocsBasic8 onUpdate={onUpdate} />
          <TableTitle>II. 개업공인중개사 세부 확인사항</TableTitle>
          <NoteDocsDetail9 onUpdate={onUpdate} />
          <NoteDocsPay onUpdate={onUpdate} />
        </div>
        <BottomBlock />
      </div>
    )
  }
}

export default NoteDocsDescription
