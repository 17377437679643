import { useFormContext } from 'react-hook-form'
import NoteAdColumnFeeDetailReason from './NoteAdColumnFeeDetailReason'
import NoteAdColumnFeeDetailType from './NoteAdColumnFeeDetailType'
import NoteAdColumnFeeEtc from './NoteAdColumnFeeEtc'
import NoteAdColumnFeeEtcTaxImposeReason from './NoteAdColumnFeeEtcTaxImposeReason'
import NoteAdColumnFeeImposeCriteria from './NoteAdColumnFeeImposeCriteria'
import NoteAdColumnFeeImposeType from './NoteAdColumnFeeImposeType'
import NoteAdColumnFeeIncludes from './NoteAdColumnFeeIncludes'
import NoteAdColumnFeePrice from './NoteAdColumnFeePrice'
import NoteAdColumnFeeUnableVerifyReason from './NoteAdColumnFeeUnableVerifyReason'
import { feeOptions } from '../../optionsFee'
import NoteAdColumnFeeImposeReason from './NoteAdColumnFeeImposeReason'

/**
 * 관리비 부과 정보
 */
const NoteAdColumnFee = () => {
  const { watch } = useFormContext()
  const list = feeOptions['부과타입']

  const chargeCodeType = watch('administrationCostInfo.chargeCodeType')
  const detailCodeType = watch('fixedFeeDetails.detailCodeType')
  const chargeCriteriaCode = watch(
    'administrationCostInfo.chargeCriteriaCode',
  )

  return (
    <>
      <NoteAdColumnFeeImposeType />
      <NoteAdColumnFeeImposeCriteria />
      {chargeCriteriaCode == '04' && <NoteAdColumnFeeImposeReason />}
      {list[chargeCodeType] == '정액관리비' && (
        <>
          <NoteAdColumnFeeDetailType />
          <NoteAdColumnFeeEtc />
        </>
      )}

      {list[chargeCodeType] == '기타부과' && (
        <>
          <NoteAdColumnFeeEtcTaxImposeReason />
          <NoteAdColumnFeeDetailReason
            required={detailCodeType == 99}
          />
          <NoteAdColumnFeePrice required />
          <NoteAdColumnFeeIncludes />
        </>
      )}

      {list[chargeCodeType] == '확인불가' && (
        <>
          <NoteAdColumnFeeUnableVerifyReason />
        </>
      )}

      {list[chargeCodeType] == '의뢰인 미고지' && (
        <>
          <NoteAdColumnFeeEtcTaxImposeReason type2 />
          <NoteAdColumnFeeIncludes />
          <NoteAdColumnFeePrice required />
        </>
      )}
    </>
  )
}

export default NoteAdColumnFee
