import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { ListItem } from 'components/design'
import { ListItemLabel } from 'components/design'
import { ListItemDesc } from 'components/design'

const CheckIcon = styled.div`
  display: flex;
  margin-right: 0.25rem;

  color: ${theme.colors.primary[400]};

  & > svg {
    width: 1rem;
    height: 1rem;
  }
`

const ChannelSelectItem = ({ item, onClick, channelSrl }) => {
  const isActive = () => {
    return channelSrl == item?.channel_srl?.toString()
  }

  return (
    <ListItem
      actived={isActive()}
      onClick={() => onClick(item?.channel_srl, item?.folder_srl)}
      append={
        isActive() && (
          <CheckIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </CheckIcon>
        )
      }
      dense
    >
      <ListItemLabel small>
        <span>{item?.name}</span>
      </ListItemLabel>
      <ListItemDesc small>{item?.nick_name}</ListItemDesc>
    </ListItem>
  )
}

export default ChannelSelectItem
