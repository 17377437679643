import styled from 'styled-components'
import { Tab } from 'components/design'
import { TabItem } from 'components/design'
import { useHistory, useLocation } from 'react-router'
import useIsMobile from 'lib/hooks/useIsMobile'
import { media } from 'lib/styles/theme'
import { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

const NoteDocsEditTab = ({ tab }) => {
  const routerhistory = useHistory()
  const location = useLocation()
  const isMobile = useIsMobile()
  const match = useRouteMatch(
    '/note/v2/docs/:channelSrl/:noteSrl/edit/:contractSrl?',
  )

  const onClickItem = (value) => {
    routerhistory.push({
      pathname: `${location.pathname.split('?tab=')[0]}`,
      search: `?tab=${value}`,
      state: location?.state,
    })
  }

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('popstate', goBack)
    }
  }, [isMobile])

  const goBack = () => {
    routerhistory.replace({
      pathname: `/note/v2/docs/${match.params.channelSrl}`,
      state: {
        flipLayer: true,
      },
    })

    window.removeEventListener('popstate', goBack)
  }

  const onClickBack = () => {
    routerhistory.go(-1)
  }

  return (
    <Wrapper>
      {isMobile && (
        <i onClick={onClickBack} className="far fa-chevron-left"></i>
      )}
      <StyeldTab size="large" noContainer>
        <TabItem
          active={tab === 'default'}
          onClick={() => onClickItem('default')}
        >
          계약서
        </TabItem>
        <TabItem
          active={tab === 'description'}
          onClick={() => onClickItem('description')}
        >
          확인설명서
        </TabItem>

        <TabItem
          active={tab === 'consent'}
          onClick={() => onClickItem('consent')}
        >
          개인정보동의서
        </TabItem>
      </StyeldTab>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;

  i {
    height: 50px;
    padding: 20px;
  }

  ${media.mediumS`
    margin-left: -20px;
  `}
`

const StyeldTab = styled(Tab)`
  margin-top: 20px;

  ${media.mediumS`
    margin-top: 0;
  `}
`

export default NoteDocsEditTab
