import styled from 'styled-components'
import { MaxWidthWrapper } from '../style/NoteStyle'
import theme, { media } from 'lib/styles/theme'
import NoteMainWrapper from '../style/NoteMainWrapper'
import Scrollbars from 'react-custom-scrollbars-2'
import NoteAdForm from './form/NoteAdForm'
import { FormProvider } from 'react-hook-form'
import NoteAdLinkContainer from 'containers/notev2/NoteAdLinkContainer'
import NoteAdValidateContainer from 'containers/notev2/NoteAdValidateContainer'
import NoteAdProcessing from './NoteAdProcessing'
import useIsMobile from 'lib/hooks/useIsMobile'
import MobileChannelHeader from '../channel/MobileChannelHeader'
import NoteAdFormTitle from './form/NoteAdFormTitle'
import { useRef } from 'react'

/**
 * 네이버 매물 연동 등록(새로 등록 시 router)
 */
const NoteAdLink = () => {
  const ref = useRef(null)
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile && <MobileChannelHeader channel={false} />}
      <NoteMainWrapper gray single singleM>
        <Scrollbars ref={ref} autoHide>
          <StyledMaxWidthWrapper>
            <NoteAdLinkContainer>
              {({
                paymentModal,
                isProcessing,
                methods,
                onSubmit,
                SubmitButton,
                validationError,
                loading,
              }) => (
                <FormProvider {...methods}>
                  <form onSubmit={onSubmit}>
                    <NoteAdFormTitle ref={ref} />
                    <EmptyBlock />
                    <NoteAdValidateContainer
                      validationError={validationError}
                    >
                      {() => (
                        <>
                          <NoteAdForm />
                          {SubmitButton}
                        </>
                      )}
                    </NoteAdValidateContainer>
                  </form>
                  {isProcessing && <NoteAdProcessing />}
                  {paymentModal}
                  {loading}
                </FormProvider>
              )}
            </NoteAdLinkContainer>
          </StyledMaxWidthWrapper>
        </Scrollbars>
      </NoteMainWrapper>
    </>
  )
}

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  background-color: ${theme.colors.base.white};
  padding: 24px 40px 100px 40px;
  margin: 0 auto;
  min-height: 100%;

  ${media.smallPc`
    width: 100%;
    padding: 24px 20px 100px 20px;
  `}

  ${media.mediumS`
    width: 100%;
    padding: 20px;
  `}
`

const EmptyBlock = styled.div`
  width: 100%;
  height: 18px;
`

export default NoteAdLink
