import { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { CSSTransition } from 'react-transition-group'
import { media } from 'lib/styles/theme'
import { Button } from 'components/design'

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;

  &.enter {
    opacity: 0;

    .modal-dialog {
      transform: translateY(-1rem);
    }
  }

  &.enter-active {
    opacity: 1;
    transition: opacity 0.2s ease;
    .modal-dialog {
      transform: translateY(0);
      transition: transform 0.3s ease;
    }
  }

  &.exit {
    opacity: 1;
    .modal-dialog {
      transform: translateY(0);
    }
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 0.2s ease;

    .modal-dialog {
      transform: translateY(-1rem);
      transition: transform 0.3s ease;
    }
  }
`

const ModalContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  max-width: 450px;
  width: 100%;
  max-height: 100%;
  padding: 1rem;

  ${({ full }) =>
    full &&
    `  
    max-width: none;
    padding: 0;
  `}

  ${({ fullheight }) =>
    fullheight &&
    `  
    height: 792px;
    max-height: 100%;
  `}

  ${({ customSize, width }) =>
    customSize &&
    `   
      width: ${width}px;
      max-width: 98%;
  `}
`

const ModalDialog = styled.div`
  width: 100%;
  background-color: ${theme.colors.base.white};
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 30px;
  will-change: transform;

  ${({ flex }) =>
    flex &&
    `
    display: flex;
    flex-direction: column;
    height: 100%;
  `}

  ${({ fullheight }) =>
    fullheight &&
    `  
    height: 100%;
  `}


  ${({ welcomeModalCustom }) => welcomeModalCustom && `height:410px;`}
  ${media.mediumS`
  ${({ welcomeModalCustom }) => welcomeModalCustom && `height:440px;`}
`}
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25rem 1rem 1.5rem 1.25rem;
  border-bottom: ${theme.colors.gray[200]};

  ${({ full }) =>
    full &&
    `  
      position: relative;
      padding: 0 !important;
  `}
`

const ModalTitle = styled.div`
  flex: 1;
  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 700;

  ${({ full }) =>
    full &&
    `  
      display: none !important;
  `}

  ${({ welcomeModalCustom }) =>
    welcomeModalCustom &&
    `text-align: center;   font-size: 23px;
  `}


  ${media.mediumS`
  ${({ welcomeModalCustom }) =>
    welcomeModalCustom &&
    `text-align: center;   font-size: 18px;
`}
  `}
`

const CloseButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;

  color: $color-gray-700;
  font-size: 1rem;

  background-color: $color-gray-100;
  border-radius: 8px;

  transition: background-color 250ms;

  &:hover {
    background-color: $color-gray-200;
  }

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  ${({ full }) =>
    full &&
    `  
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 1;
  `}
  ${media.mediumS`
    width:24px;
    height:24px;

`}
`

const ModalBody = styled.div`
  padding: 0 1.25rem 1.5rem;
  max-height: calc((100vh - 300px) - env(safe-area-inset-bottom));
  overflow-y: auto; // All other browsers
  overflow-y: overlay; // Chrome & Safari
  @media (max-height: $modal-expand-height) {
    max-height: calc((100vh - 200px) - env(safe-area-inset-bottom));
  }

  ${({ overflowvisible }) =>
    overflowvisible &&
    `
    overflow-y: visible;
  `}

  ${({ full }) =>
    full &&
    `
      max-height: calc(100vh - 120px);
      padding: 0;
  `}

  
  ${({ fullheight }) =>
    fullheight &&
    `
      max-height: 100% !important;
  `}

  ${({ welcomeModalCustom }) =>
    welcomeModalCustom &&
    `
    max-height: none;

    width: 100%;
    height: 650px;
    padding: 0 30px;
    `}

    
  ${({ flex }) =>
    flex &&
    `  
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `}
  
  ${({ hiddenHeader }) =>
    hiddenHeader &&
    `  
    border-radius: ${theme.borderRadius[2]};
  `}
  
  
  ${media.mediumS`
    ${({ fullheight }) =>
      fullheight &&
      `
        max-height: calc(100% - 68px) !important;
      `}
  `}
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.4rem 1.25rem 0.95rem;

  ${({ full }) =>
    full &&
    `
      padding: 0;
  `}

  & > button {
    margin-right: 0.75rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

const Dimmer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

const ExpandedButtonWrapper = styled.div`
  display: flex;
`

const ExpandedSubmitButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;

  color: ${theme.colors.base.white};
  font-size: 1rem;
  font-weight: 700;
  background-color: ${theme.colors.primary[500]};
  border-radius: 0 0 ${theme.borderRadius[2]} ${theme.borderRadius[2]};

  ${({ disabled }) =>
    disabled &&
    `
    // color: ${theme.colors.gray[600]};
    background-color: ${theme.colors.gray[400]};
  `}

  ${({ hasOtherButton }) =>
    hasOtherButton &&
    `
    width: 65%;
    border-radius: 0 0 ${theme.borderRadius[2]} 0;
  `}

  ${({ color }) => {
    switch (color) {
      case 'blue':
        return `
          background-color: ${theme.colors.blue[500]};
        `
    }
  }}
`

const ExpandedOtherButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 60px;

  color: ${theme.colors.base.white};
  font-size: 1rem;
  font-weight: 700;
  background-color: ${theme.colors.gray[300]};
  border-radius: 0 0 0 ${theme.borderRadius[2]};

  ${({ disabled }) =>
    disabled &&
    `
    // color: ${theme.colors.gray[600]};
    background-color: ${theme.colors.gray[400]};
  `}
`

const Modal = ({
  welcomeModalCustom,
  title,
  visible,
  onOk,
  onCancel,
  okText = '확인',
  cancelText = '취소',
  cancelHide = false,
  width = 450,
  full = false,
  children,
  cancelButtonProps = {
    color: 'gray',
  },
  okButtonProps = {
    color: 'primary',
  },
  footer,
  expandedSubmitButton,
  expandedOtherButton,
  expandedOtherText,
  onExpandedOtherButtonClick,
  hiddenClose,
  customSize = false,
  hiddenHeader = false,
  overflowVisible,
  fullheight,
  flex,
}) => {
  const okButtonRef = useRef(null)
  const modalRoot = document.getElementById('modal')

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 27 && visible) {
        //Do whatever when esc is pressed
        onCancel()
      }
    },
    [visible],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [visible])

  // submit 버튼 자동 focus
  useEffect(() => {
    if (!okButtonRef?.current) return
    okButtonRef?.current?.focus()
  }, [visible, footer, okButtonRef])

  return ReactDOM.createPortal(
    <CSSTransition in={visible} timeout={300} unmountOnExit>
      <ModalWrapper>
        <ModalContainer
          full={full}
          fullheight={fullheight}
          customSize={customSize}
          width={width}
        >
          <ModalDialog
            welcomeModalCustom={welcomeModalCustom}
            width={width}
            className="modal-dialog"
            flex={flex}
            fullheight={fullheight}
          >
            {!hiddenHeader && (
              <ModalHeader
                welcomeModalCustom={welcomeModalCustom}
                full={full}
              >
                <ModalTitle
                  welcomeModalCustom={welcomeModalCustom}
                  full={full}
                >
                  {title}
                </ModalTitle>
                {!hiddenClose && (
                  <CloseButton full={full} onClick={onCancel}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </CloseButton>
                )}
              </ModalHeader>
            )}

            <ModalBody
              fullheight={fullheight}
              flex={flex}
              overflowvisible={overflowVisible}
              welcomeModalCustom={welcomeModalCustom}
              hiddenHeader={hiddenHeader}
            >
              {children}
            </ModalBody>

            {!expandedSubmitButton &&
              (footer ? (
                footer
              ) : footer !== null ? (
                <ModalFooter full={full}>
                  {/* cancleButton */}
                  {!cancelHide && (
                    <Button {...cancelButtonProps} onClick={onCancel}>
                      {cancelText}
                    </Button>
                  )}

                  {/* okButton */}
                  <Button
                    color="black"
                    {...okButtonProps}
                    onClick={onOk}
                    ref={okButtonRef}
                  >
                    {okText}
                  </Button>
                </ModalFooter>
              ) : (
                <></>
              ))}

            {expandedSubmitButton && (
              <ExpandedButtonWrapper>
                {expandedOtherButton && (
                  <ExpandedOtherButton
                    color="gray"
                    onClick={onExpandedOtherButtonClick}
                  >
                    {expandedOtherText}
                  </ExpandedOtherButton>
                )}
                <ExpandedSubmitButton
                  {...okButtonProps}
                  hasOtherButton={expandedOtherButton}
                  onClick={onOk}
                >
                  {okText}
                </ExpandedSubmitButton>
              </ExpandedButtonWrapper>
            )}
          </ModalDialog>
        </ModalContainer>

        <Dimmer
          className="modal-dialog-dimmer"
          onClick={onCancel}
        ></Dimmer>
      </ModalWrapper>
    </CSSTransition>,
    modalRoot,
  )
}

export default Modal
