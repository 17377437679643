import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SummaryAptRpContainer from 'containers/summary/SummaryAptRpContainer'
import SummaryPyUnitPriceContainer from './SummaryPyUnitPriceContainer'

const SummaryAptPriceContainer = ({
  areaTypes,
  building,
  addressId,
  visiblePy,
  noTitle,
}) => {
  // 선택된 평형
  const [selectedArea, setSelectedArea] = useState(null)

  useEffect(() => {
    // 세대수가 가장 많은 면적을 우선으로 보여줌 (redux summary - area_types)
    const max = Math.max(...areaTypes.map((item) => item.house_count))

    const maxArea = areaTypes.filter((item) => {
      return parseInt(item.house_count) === max
    })?.[0]
    setSelectedArea(maxArea)
  }, [areaTypes])

  return (
    <>
      <SummaryAptRpContainer
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        areaTypes={areaTypes}
        building={building}
        addressId={addressId}
        noTitle={noTitle}
      />

      {selectedArea && visiblePy && (
        <SummaryPyUnitPriceContainer selectedArea={selectedArea} />
      )}
    </>
  )
}

export default SummaryAptPriceContainer
