import { Button, Modal } from 'components/design'
import styled from 'styled-components'

/**
 * 결제 취소 confirm 모달
 * 이벤트 요금제 결제 취소 모달
 * * 결제일로부터 7일 이내 이면서 등기부등본 사용 내역이 없는 경우 = 결제 취소로 인한 환불 가능
 * @returns
 */
const NotePaymentEventCancelConfirm = ({
  visible = false,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      title="프리 요금제로 변경하시겠습니까?"
      visible={visible}
      width={450}
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{
        color: 'blue',
      }}
      hiddenClose
    >
      {visible && (
        <>
          프리 요금제로 변경하시면 이미 구입하신 등기부등본은 나중에
          유료 요금제로 다시 변경해야 사용하실 수 있어요.
          <br />
          <br />
          또한 친구 초대 이벤트 링크를 공유한 경우 더이상 친구 초대
          이벤트 참여로 등기열람권을 지급받을 수 없어요.
        </>
      )}
    </Modal>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 0 16px 24px 20px;
`

export default NotePaymentEventCancelConfirm
