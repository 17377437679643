import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

export const ButtonCard = styled.button`
  border: none;
  background-color: white;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;

  ${({ color }) =>
    color === 'blue'
      ? `color: ${theme.colors.blue[400]};`
      : `color: ${theme.colors.tint.danger};`}
`
export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 8px;
  margin-bottom: 66px;

  ${({ hideButtons }) => hideButtons && `margin-bottom: 0;`}
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
`

export const InfoTitle = styled.div`
  width: 100px;
  color: ${theme.colors.gray[600]};
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  ${({ half }) => half && `width: 50%;`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: 42px;`}
  padding: 30px;
  border: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.base.white};
  border-radius: 12px;
  overflow: hidden;
  ${({ nopadding }) => nopadding && `padding: 0;`}
  ${({ fit }) => fit && `height: fit-content;`}
  
  ${media.mediumM`
    width: 100%;
  `}

  ${media.mediumS`
    padding: 20px;
  `}
`

export const ContentTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
`

export const BlockTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;

  /* ${({ marginTop }) => marginTop && `margin-top: 42px;`} */
  ${({ small }) =>
    small && `font-weight: 600; line-height: 130%; font-size: 16px;`}
`
