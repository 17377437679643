import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { getFullUrl, getJootekServiceURL } from 'utils'
import { ReactComponent as Logo } from 'assets/icon/home-logo.svg'

const SideMenuJootekContent = ({ onClose }) => {
  const plan = [
    {
      href: '/ideas',
      src: '/layouts/jootek/pages/new-main/images/menu-icons/idea.svg',
      title: '아이디어',
    },
    {
      href: '/projects',
      src: '/layouts/jootek/pages/new-main/images/menu-icons/project.svg',
      title: '프로젝트',
    },
    {
      href: '/estimate',
      src: '/layouts/jootek/pages/new-main/images/menu-icons/estimate.svg',
      title: '가견적',
    },
    {
      href: '/wiki',
      src: '/layouts/jootek/pages/new-main/images/menu-icons/wiki.svg',
      title: '자재위키',
    },
  ]
  const position = [
    {
      href: '/danji',
      src: '/layouts/jootek/pages/new-main/images/menu-icons/danji.svg',
      title: '단지',
    },
    {
      href: '/map/home',
      src: '/layouts/jootek/pages/new-main/images/menu-icons/map.svg',
      title: '지도',
    },
  ]
  const masters = [
    {
      href: '/masters',
      src: '/layouts/jootek/pages/new-main/images/menu-icons/masters.svg',
      title: '전문가',
    },
    {
      href: '/folio',
      src: '/layouts/jootek/pages/new-main/images/menu-icons/folio.svg',
      title: '폴리오',
    },
  ]

  return (
    <MenuWrapper>
      <ContentWrapper>
        <TittleWrapper>건축계획</TittleWrapper>
        <Menus>
          <MenuLink href={getFullUrl('/')}>
            <Logo />
            <Title>주택 홈</Title>
          </MenuLink>
          {plan.map(({ href, src, title }, index) => {
            return (
              <MenuLink key={index} href={getFullUrl(href)}>
                <Img src={src} alt={title} />
                <Title>{title}</Title>
              </MenuLink>
            )
          })}
        </Menus>
      </ContentWrapper>
      <ContentWrapper>
        <TittleWrapper>입지</TittleWrapper>
        <Menus>
          {position.map(({ href, src, title }, index) => {
            return (
              <MenuLink
                key={index}
                href={
                  href === '/map'
                    ? getFullUrl(href, true)
                    : getFullUrl(href)
                }
              >
                <Img src={src} alt={title} />
                <Title>{title}</Title>
              </MenuLink>
            )
          })}
        </Menus>
      </ContentWrapper>
      <ContentWrapper>
        <TittleWrapper>전문가</TittleWrapper>
        <Menus>
          {masters.map(({ href, src, title }, index) => {
            return (
              <MenuLink key={index} href={getFullUrl(href)}>
                <Img src={src} alt={title} />
                <Title>{title}</Title>
              </MenuLink>
            )
          })}
        </Menus>
      </ContentWrapper>
    </MenuWrapper>
  )
}

const MenuWrapper = styled.div`
  width: 100%;
  min-height: 434.69px;
  padding: 28px 20px 0 20px;
`

const TittleWrapper = styled.div`
  line-height: 150%;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`

const ContentWrapper = styled.div`
  margin-bottom: 38px;
`

const Menus = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Img = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 11.5px 0;
  width: 50%;
`

const MenuLink = styled.a`
  display: flex;
  align-items: center;
  width: 50%;
  font-weight: 500;
  line-height: 180%;
  color: ${theme.colors.gray[900]};

  &:hover {
    color: ${theme.colors.gray[900]};
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
`

export default SideMenuJootekContent
