import React, { useCallback } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { SheetButton } from 'components/jmapnotev2/style/NoteStyle'
import { useSelector } from 'react-redux'

const CustomImageCell = ({ value, data, gridfontsize }) => {
  const history = useHistory()
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const onClickWrite = useCallback(() => {
    if (!data.channel_srl || !data.note_srl) {
      message.error('채널 또는 매물 정보가 유효하지 않습니다.')
      return
    }
    history.push({
      pathname: `/note/v2/notes/${data.channel_srl}/${
        data.address_srl || '0'
      }/${data.note_srl}`,
      state: {
        activeTab: 2,
      },
    })
  }, [data])

  if (data.note_srl == '신규') return <></>

  return (
    <Wrapper>
      {value > 0 && (
        <StyledLink onClick={onClickWrite}>{value}장</StyledLink>
      )}
      {!value && channelInfo?.member_type != 'readonly' && (
        <SheetButton
          disabled={data.status == 2}
          gridfontsize={gridfontsize}
          onClick={onClickWrite}
        >
          업로드
        </SheetButton>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const StyledLink = styled.div`
  color: ${theme.colors.blue[400]};
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`

export default React.memo(CustomImageCell)
