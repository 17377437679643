import theme from 'lib/styles/theme'
import styled from 'styled-components'

const NoteDashboardPictureDate = ({
  date,
  hasPrev,
  hasNext,
  onClickPrev,
  onClickNext,
}) => {
  return (
    <Wrapper>
      <DatePick>
        <Icon
          onClick={hasPrev ? onClickPrev : null}
          active={hasPrev}
          className="fa fa-angle-left"
        ></Icon>
        <Date>{date}</Date>
        <Icon
          onClick={hasNext ? onClickNext : null}
          active={hasNext}
          className="fa fa-angle-right"
        ></Icon>
      </DatePick>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`

const DatePick = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 313px;
`

const Icon = styled.i`
  font-size: 22px;
  color: ${theme.colors.gray[400]};

  ${({ active }) => active && `color: ${theme.colors.gray[900]};`}
`

const Date = styled.div`
  font-weight: 600;
  line-height: 100%;
`

export default NoteDashboardPictureDate
