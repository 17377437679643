import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import BlueTextBox from '../../elements/BlueTextBox'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useState } from 'react'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증정보 - 검증 참고란 registerUniqueNo
 */
const NoteAdColumnVerificationRef = ({ isDisabled }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const registerUniqueNo = watch('verification.registerUniqueNo')
  const verificationType = watch('verification.type')

  const [inputCount, setInputCount] = useState(
    registerUniqueNo?.length || 0,
  )

  const onTextareaHandler = (e) => {
    setInputCount(e.target.value.length)
  }

  return (
    <ColumnSet>
      <ColumnTitle>검증 참고란</ColumnTitle>
      <ColumnVal>
        <BlueTextBox
          maxLength="100"
          placeholder="특수문자 사용 가능. 100자 이내로 작성해 주세요."
          type="text"
          {...register('verification.registerUniqueNo')}
          onBlur={(e) => {
            onUpdate('verification.registerUniqueNo', e.target.value)
          }}
          onChange={onTextareaHandler}
          disabled={isDisabled}
        />
        <Count>{inputCount} / 100</Count>
        <ValidateWarn>
          {errors?.verification?.registerUniqueNo?.message}
        </ValidateWarn>
        {verificationType == 'N' && (
          <FormDesc>
            <li>
              소유자가 서로 상이한 묶음매물의 대표 "소유자”는 네이버
              실명인증이 진행되며, 대표 소유자 외의 다른 소유자는 모두
              검증참고란에 기재해주세요.
            </li>
            <li>
              묶음매물(소유자동일/상이 모두)의 등기부등본 첨부는
              선택사항 이므로 정확한 서류를 첨부해주세요.
            </li>
          </FormDesc>
        )}
      </ColumnVal>
    </ColumnSet>
  )
}

const Count = styled.div`
  margin-left: 6px;
  font-size: 12px;
  color: ${theme.colors.blue[900]};
`

export default NoteAdColumnVerificationRef
