import axios from 'axios'

export const getPropertyFilter = async (
  { _rx_csrf_token, channel_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/getPropertyFilter',
    {
      _rx_csrf_token,
      channel_srl,
    },
    options,
  )
