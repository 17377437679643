import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비 금액
 */
const NoteAdColumnFeePrice = ({ required }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const value = watch(
    'administrationCostInfo.etcFeeDetails.etcFeeAmount',
  )

  return (
    <ColumnSet>
      <ColumnTitle required={required}>금액</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            width={InputRemoveAppendWidth}
            type="number"
            defaultValue={value}
            {...register(
              'administrationCostInfo.etcFeeDetails.etcFeeAmount',
            )}
            onBlur={(e) => {
              onUpdate(
                'administrationCostInfo.etcFeeDetails.etcFeeAmount',
                e.target.value.replaceAll(',', ''),
              )
            }}
            fixAppendWidth
            append={'원'}
          />
        </div>
        <ValidateWarn>
          {
            errors?.administrationCostInfo?.etcFeeDetails?.etcFeeAmount
              ?.message
          }
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeePrice
