import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NoteCalendarDay from './NoteCalendarDay'

const NoteCalendarWeek = ({ currentDate, week, weekFormat }) => {
  const days = () => {
    const currentDateMonth = currentDate.clone().get('month')
    // const firstDateOfWeek = weekFormat
    //   .clone()
    //   .startOf('week')
    //   .get('date')
    const _days = []

    for (let i = 0; i < 7; i++) {
      const dateFormat = weekFormat.clone().day(i)
      const date = dateFormat.get('date')
      const day = dateFormat.get('day')
      const month = dateFormat.get('month')

      _days.push({
        dateFormat,
        date,
        day,
        currentDateMonth,
        month,
      })
    }

    return _days
  }

  return (
    <StyledWeek>
      {days().map((item, i) => (
        <NoteCalendarDay
          key={`dashboard_calendar_day_${i}`}
          day={i}
          dateFormat={item.dateFormat}
          date={item.date}
          currentDateMonth={item.currentDateMonth}
          month={item.month}
        />
      ))}
    </StyledWeek>
  )
}

const StyledWeek = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray[200]};
  }
`

export default NoteCalendarWeek
