import theme from 'lib/styles/theme'
import styled from 'styled-components'
import moment from 'moment'
import { Button } from 'components/design'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { numberWithCommas } from 'utils'

const NotePaymentResult = ({
  isError,
  isInValid,
  isSuccess,
  result,
}) => {
  const history = useHistory()

  const onClickPaymentMain = () => {
    history.push('/note/v2/payment')
  }

  const onClickReceipt = (url) => {
    if (!url) return
    window.open(url, '_blank')
  }

  if (isError) {
    return (
      <CenterWrapper>
        <Title>결제에 실패하였습니다.</Title>
        <Desc mb>
          잠시 후에 다시 한번 이용해 주시기 바랍니다. <br/>동일한 문제가
          지속적으로 발생할 경우, 카카오록 문의하기(JOOTEK - 주택)로
          문의해주시기 바랍니다. 감사합니다.
        </Desc>
        <Button
          size="xlarge"
          color="black"
          onClick={onClickPaymentMain}
        >
          이전 화면으로 돌아가기
        </Button>
      </CenterWrapper>
    )
  }

  if (isInValid) {
    return (
      <CenterWrapper>
        <Title>유효하지 않은 결제 정보입니다.</Title>
        <Desc mb>
          동일한 문제가 지속적으로 발생할 경우, 카카오록 문의하기(JOOTEK
          - 주택)로 문의해주시기 바랍니다. 감사합니다.
        </Desc>
        <Button
          size="xlarge"
          color="black"
          onClick={onClickPaymentMain}
        >
          이전 화면으로 돌아가기
        </Button>
      </CenterWrapper>
    )
  }

  if (isSuccess) {
    return (
      <CenterWrapper>
        <Title>
          <i className="fas fa-check-circle"></i>결제가 정상적으로
          완료되었습니다.
        </Title>
        <SuccessDesc>
          <span>
            주문내역은 <strong>결제관리</strong>에서 확인가능합니다.
          </span>
          <span>주문번호: {result.payment_srl}</span>
        </SuccessDesc>
        <Table>
          <thead>
            <tr>
              <th>주문번호</th>
              <th>구매상품</th>
              <th>결제금액</th>
              <th>결제일시</th>
              <th>영수증</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{result.payment_srl}</td>
              <td>{result.order_name}</td>
              <td>{numberWithCommas(result.amount)}원</td>
              <td>
                {result.regdate
                  ? moment(result.regdate, 'YYYYMMDDHHmmss').format(
                      'YYYY.MM.DD HH:mm',
                    )
                  : ''}
              </td>
              <td>
                <Button
                  size="small"
                  onClick={() =>
                    onClickReceipt(result.extra_vars?.receipt?.url)
                  }
                >
                  영수증보기
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Button
          size="xlarge"
          color="black"
          onClick={onClickPaymentMain}
        >
          결제관리 화면으로 가기
        </Button>
      </CenterWrapper>
    )
  }
}

const CenterWrapper = styled.div``

const Title = styled.div`
  font-size: 42px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 32px;
  text-align: center;

  i {
    margin-right: 18px;
    color: ${theme.colors.primary[500]};
  }
`

const Desc = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${theme.colors.gray[600]};
  line-height: 150%;

  ${({ mb }) => mb && `margin-bottom: 60px;`}

  strong {
    color: ${theme.colors.gray[900]};
  }
`

const SuccessDesc = styled(Desc)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  span {
    line-height: 180%;
  }
`

const Table = styled.table`
  width: 735px;
  margin: auto;
  margin-bottom: 60px;

  tr {
    height: 49px;
  }

  tbody > tr {
    background-color: ${theme.colors.gray[50]};
  }
`

export default NotePaymentResult
