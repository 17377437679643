import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NotePaymentInfoMiniBlockAllInOneMember from './NotePaymentInfoMiniBlockAllInOneMember'
import NotePaymentInfoBlockAllInOneMemberDesc from './NotePaymentInfoBlockAllInOneMemberDesc'
import NotePaymentInfoBlockAllInOneMemberOwn from './NotePaymentInfoBlockAllInOneMemberOwn'

/**
 * 왼쪽 요금제 정보 블럭
 * 올인원 요금제 직원
 */
const NotePaymentInfoBlockAllInOneMember = ({
  plan,
  handleChangeCard,
}) => {
  return (
    <Wrapper>
      <NotePaymentInfoBlockAllInOneMemberDesc
        ceoCompanyName={plan.ceo_company_name}
      />
      <NotePaymentInfoBlockAllInOneMemberOwn
        plan={plan}
        handleChangeCard={handleChangeCard}
      />
      <NotePaymentInfoMiniBlockAllInOneMember />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

export default NotePaymentInfoBlockAllInOneMember
