import { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.css'
import './Slider.scss'

// const handleSize = '20px';

// const StyledSlider = styled.div`
//   position: relative;
//   height: ${handleSize};
//   margin: 10px 6px 30px;
//   padding: 8px 0;
// `;

// const SliderRail = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 4px;
//   background-color: ${theme.colors.gray[200]};
//   border-radius: 2px;
// `;

// const SliderTrack = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 4px;
//   background-color: ${theme.colors.tint.primary};
//   border-radius: 2px;
// `;

// const SliderHandleContainer = styled.div`
//   position: absolute;
//   top: -0;
//   left: 10px;
//   width: calc(100% - ${handleSize});
//   height: ${handleSize};
// `;

// const SliderHandle = styled.div`
//   cursor: pointer;

//   position: absolute;
//   top: 0;
//   left: 0;

//   transform: translateX(-50%);

//   width: ${handleSize};
//   height: ${handleSize};
//   background-color: ${theme.colors.base.white};
//   border-radius: 100%;
//   border: 1px solid ${theme.colors.gray[300]};

//   &:active {
//     border-color: ${theme.colors.tint.primary};
//   }
// `;

// const SliderMark = styled.div`
//   position: absolute;
//   top: ${handleSize};
//   left: 0;
//   width: 100%;
//   height: 24px;

//   color: ${theme.colors.gray[700]};
//   font-size: 0.875rem;
// `;

// const SliderMarkText = styled.div`
//   position: absolute;
//   transform: translateX(-50%);
// `;

const Slider = (props) => (
  <div className="map-app-slider">
    <Nouislider {...props} />
  </div>
)

export default Slider
