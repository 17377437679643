import { message } from 'antd'
import { Checkbox, Empty, Modal } from 'components/design'
import useUserLoader from 'containers/base/hooks/useUserLoader'
import {
  getRefundableDeunggibuInfo,
  refundDeunggibu,
} from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import { setRefreshPayments } from 'modules/notev2'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 등기부등본 환불 모달
 */
const NotePaymentDeunggibuRefundModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch()
  const planInfo = useSelector((state) => state.auth.planInfo)
  const [selectedPayment, setSelectedPayment] = useState([])
  const [refundableList, setRefundableList] = useState([])
  const [onRequestList, , dataList] = useAxios(
    getRefundableDeunggibuInfo,
    { resetError: true },
  ) //환불 가능 등기부등본
  const [onRequestRefund, , dataRefund] = useAxios(refundDeunggibu, {
    resetError: true,
  }) //환불 가능 등기부등본
  const { onRequest: refreshUserInfo } = useUserLoader(false)

  useEffect(() => {
    if (!visible) return

    onRequestList({
      plan_srl: planInfo.allinone_plan_srl,
    })
  }, [visible])

  useEffect(() => {
    if (!dataList) return
    setRefundableList(dataList?.result?.refundable_payments || [])
  }, [dataList])

  /**
   * 환불 확인
   */
  const onOk = () => {
    if (refundableList.length == 0) {
      onCancel()
      return
    }
    if (selectedPayment.length == 0) {
      message.info('환불할 등기부등본을 선택해주세요.')
      return
    }

    onRequestRefund({
      plan_srl: planInfo?.allinone_plan_srl || planInfo?.plan_srl,
      amount_refund: selectedPayment
        .map(({ amount }) => amount)
        .reduce((a, b) => a + b, 0),
      payment_srls: selectedPayment
        .map(({ payment_srl }) => payment_srl)
        .filter((item) => item),
    })
  }

  useEffect(() => {
    if (!dataRefund) return

    if (dataRefund?.error == 0) {
      message.success('등기부등본 환불이 완료되었습니다.')
      //유저 정보 리로드
      refreshUserInfo()
      onCancel()
      dispatch(setRefreshPayments(true))
    }
  }, [dataRefund])

  /**
   * 환불 항목 클릭
   * @param {*} payment
   */
  const onChangeCheckbox = (payment) => {
    const srls = selectedPayment.map(({ payment_srl }) => payment_srl)
    if (srls.includes(payment.payment_srl)) {
      setSelectedPayment(
        selectedPayment.filter(
          ({ payment_srl }) => payment_srl != payment.payment_srl,
        ),
      )
    } else {
      setSelectedPayment([...new Set([...selectedPayment, payment])])
    }
  }

  return (
    <Modal
      visible={visible}
      title="등기부등본 환불"
      onOk={onOk}
      onCancel={onCancel}
      okText={refundableList.length > 0 ? '선택 항목 환불' : '확인'}
      okButtonProps={{ color: 'blue' }}
      expand
      cancelHide
    >
      <List>
        {refundableList.length == 0 && (
          <Empty>환불 가능한 등기부등본이 없습니다.</Empty>
        )}
        {refundableList.map((item, i) => {
          const isSelected = selectedPayment
            .map(({ payment_srl }) => payment_srl)
            .includes(item.payment_srl)
          return (
            <ListItem selected={isSelected} key={i}>
              <Checkbox
                checked={isSelected}
                onChange={() => onChangeCheckbox(item)}
                color="blue"
              >
                <Wrapper>
                  <Date>
                    {item.paid_date
                      ? moment(item.paid_date, 'YYYYMMDDHHmmss').format(
                          'YYYY.MM.DD HH:mm',
                        )
                      : ''}
                  </Date>
                  <Count>{item.count}개</Count>
                  <Amount>{numberWithCommas(item.amount)}원</Amount>
                </Wrapper>
              </Checkbox>
            </ListItem>
          )
        })}
      </List>
    </Modal>
  )
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid ${theme.colors.gray[200]};
  padding: 10px 12px;
  border-radius: 8px;

  ${({ selected }) =>
    selected &&
    `
      border: 1px solid ${theme.colors.blue[400]};
  `}
`

const Date = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const Count = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${theme.colors.gray[600]};
`

const Amount = styled.span`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 6px;
  font-weight: 700;
  font-size: 14px;
`

const Wrapper = styled.div``

export default NotePaymentDeunggibuRefundModal
