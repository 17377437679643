import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import theme from 'lib/styles/theme'
import { setSelectedDong } from 'modules/noteDongho'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const NoteDonghoHeaderDongSelectorMobile = ({ openDropdown }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const dispatch = useDispatch()
  const dongs = useSelector(
    (state) => state.noteDongho.selectedDanjiDongs,
  )
  const selectedDong = useSelector(
    (state) => state.noteDongho.selectedDong,
  )
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )

  useEffect(() => {
    if (!isMobile && !isTablet) return
    if (selectedDong) return
    dispatch(setSelectedDong(dongs[0]))
  }, [isMobile, isTablet])

  return (
    <Dong onClick={openDropdown}>
      <DongTitle>
        {selectedDong}
        <DownIcon className="fa fa-caret-down" />
      </DongTitle>
      <Household>
        {selectedDanji?.households?.[selectedDong]}세대
      </Household>
    </Dong>
  )
}

const Dong = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

const DownIcon = styled.i`
  font-size: 20px;
`

const DongTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
`

const Household = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[600]};
`

export default NoteDonghoHeaderDongSelectorMobile
