import { useState } from 'react'
import styled from 'styled-components'
import { Tag } from 'components/design'

import theme from 'lib/styles/theme'

const Item = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.75rem 0.75rem;
  border-top: 1px solid ${theme.colors.gray[200]};

  transition: 0.2s ease;

  .app-tag {
    margin: -6px 0;
  }

  &:first-child {
    border-top: none;
  }

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }

  &.active {
    background-color: ${theme.colors.gray[100]};
    border-radius: ${theme.borderRadius[2]};
    border-top: none;

    color: ${theme.colors.gray[900]};
    font-weight: 700;

    & + li {
      border-top: none;
    }
  }
`
const TagWrap = styled.div`
  margin-right: 0.5rem;
`
const ItemText = styled.div`
  flex: 1;
`

const SummaryLobSelect = ({
  item,
  count,
  active,
  onClick,
  getBuildingName,
}) => {
  return (
    <Item onClick={onClick} className={active ? 'active' : ''}>
      <TagWrap>
        {item?.isTotal ? (
          <Tag color="black">총괄</Tag>
        ) : (
          <Tag color={item?.is_sub_building === 0 ? 'black' : ''}>
            {item?.is_sub_building === 0 ? '주' : '부'}
            {count + 1}
          </Tag>
        )}
      </TagWrap>

      <ItemText>{getBuildingName(item)}</ItemText>
    </Item>
  )
}

export default SummaryLobSelect
