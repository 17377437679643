import { ReactComponent as Icon } from 'assets/icon/map-control/visibility.svg'
import { ReactComponent as IconOn } from 'assets/icon/map-control/visibility-on.svg'
import { ReactComponent as IconOff } from 'assets/icon/map-control/visibility-off.svg'
import MapContext from 'contexts/map'
import theme, { media } from 'lib/styles/theme'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useSelector } from 'react-redux'
import BaseContext from 'contexts/baseContext'

const MayLayerHide = () => {
  const {
    state: { isFold },
  } = useContext(BaseContext)

  const {
    state: { layerState },
    actions,
  } = useContext(MapContext)
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const isMobile = useIsMobile()
  const [isOpen, setIsOpen] = useState(false)

  const onClickItem = (e, key) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    actions.setLayerState((prev) => {
      return {
        ...prev,
        [key]: layerState[[key]] ? false : true,
      }
    })
  }

  const onClickOpen = (e) => {
    if (!isMobile) return
    setIsOpen((prev) => !prev)
  }

  return (
    <Wrapper
      isfold={isFold}
      ismobile={isMobile}
      active={isMobile ? isOpen : true}
      onClick={onClickOpen}
    >
      <IconOn />
      <span>숨김</span>
      <List visible={isMobile ? isOpen : true}>
        <ListItem
          onClick={(e) => onClickItem(e, 'realPriceActive')}
          active={!layerState.realPriceActive}
        >
          {!layerState.realPriceActive ? <IconOff /> : <IconOn />}
          실거래가
        </ListItem>
        {loginInfo?.member_type === 'realestate' && (
          <ListItem
            onClick={(e) => onClickItem(e, 'favorite')}
            active={!layerState.favorite}
          >
            {!layerState.favorite ? <IconOff /> : <IconOn />}내 매물
          </ListItem>
        )}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: fixed;
  top: 10px;
  left: ${theme.base.navWidth + theme.base.container + 16}px;
  width: 75px;
  height: 36px;
  background-color: ${theme.colors.base.white};
  border-radius: 24px;
  font-weight: 600;
  font-size: 13px;
  z-index: 13;
  box-shadow: ${theme.shadow.float};
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  ${({ isfold }) =>
    isfold &&
    `
     left: ${theme.base.navWidth + 16}px;
  `}

  ${({ active }) =>
    active &&
    `
     background-color: ${theme.colors.gray[700]};
        color: ${theme.colors.base.white};
  `}


  ${media.mediumM`
    left: 10px !important;
  `}

  ${media.mediumS`
    left: 10px !important;
    top: initial;
    bottom: ${theme.base.navWidth + 10}px;
  `}
`

const List = styled.div`
  display: none;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: -87px;
  top: 2px;
  ${({ visible }) => visible && `display: flex;`}

  ${media.mediumS`
    top: initial;
    bottom: 0;
  `}
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 32px;
  padding: 0 8px;
  box-shadow: ${theme.shadow.float};
  background-color: ${theme.colors.base.white};
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  color: ${theme.colors.gray[900]};

  ${({ active }) =>
    active &&
    `
        background-color: ${theme.colors.gray[700]};
        color: ${theme.colors.base.white};
    `};
`

export default MayLayerHide
