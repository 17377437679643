import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import React from 'react'
import { options } from '../../options'
import Checkbox from '../../elements/Checkbox'
import useSave from '../../useSave'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 거래 정보 - 관리비 세부내역타입(아파트, 상가용)
 */
const NoteAdColumnFeeDetailType = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const list = options['관리비 포함 내역']
  const { onChangeCheckbox } = useSave()

  const mnexItems = watch('etcInfo.mnexItems')

  return (
    <ColumnSet>
      <ColumnTitle required>관리비 포함 내역</ColumnTitle>
      <ColumnVal gap={16}>
        {list.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <Checkbox
                id={`etcInfo_mnexItems${i}`}
                color="blue"
                value={item}
                {...register('etcInfo.mnexItems')}
                onChange={(checked, e) => {
                  onChangeCheckbox({
                    originValue: mnexItems,
                    key: 'etcInfo.mnexItems',
                    value: e,
                  })
                }}
              >
                {item}
              </Checkbox>
            </React.Fragment>
          )
        })}
        <ValidateWarn>
          {errors?.etcInfo?.mnexItems?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeeDetailType
