import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 욕실 개수
 */
const NoteAdColumnBathroomCount = ({ required = true }) => {
  const {
    register,
    watch,
    formState: { errors },
    control,
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  return (
    <ColumnSet half>
      <ColumnTitle>욕실 개수</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="text"
            {...register('article.restroom')}
            onBlur={(e) => {
              onUpdate('article.restroom', e.target.value)
            }}
            append={'개'}
          />
        </div>
        <ValidateWarn>
          {errors?.article?.restroom?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnBathroomCount
