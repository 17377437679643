import theme from 'lib/styles/theme'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { PropertyListItem, PropertyListLabel } from './NoteFilter'
import propertyList from './propertyData'
import { numberWithCommas } from 'utils'
import Collapsible from 'react-collapsible'

const NotePropertyList = ({
  onClickPanel,
  onClickItem,
  propertyTarget,
  propertyFilters,
}) => {
  const subMoreCount = 10
  const [activeProperties, setActiveProperties] = useState([])
  const [subItemMoreKeys, setSubItemMoreKeys] = useState([])

  const getPropertyCount = useCallback(
    (value) => {
      if (!propertyFilters[value]) return null

      return numberWithCommas(
        propertyFilters[value]['전체']?.count || 0,
      )
    },
    [propertyFilters],
  )

  /**
   * 유형별 아이템들 active
   * @param {Number} i
   */
  const onClickPropertyPanel = (e, i, label) => {
    e.preventDefault()
    setActiveProperties((prev) =>
      prev.includes(label)
        ? prev.filter((prevIdx) => prevIdx != label)
        : [...prev, label],
    )

    setTimeout(() => {
      onClickPanel(e, label, activeProperties)
    }, 100)
  }

  /**
   * 매물들 필터 열었을 때 더보기
   * @param {string} label
   */
  const onClickSubItemMore = useCallback((label) => {
    setSubItemMoreKeys((prev) =>
      prev.includes(label)
        ? prev.filter((prevLabel) => prevLabel != label)
        : [...prev, label],
    )
  }, [])

  return (
    <>
      {propertyList.map(({ label, icon, border }, i, ori) => {
        return (
          <div key={i}>
            <Collapsible
              transitionTime={100}
              easing="cubic-bezier(0.16, 1, 0.3, 1)"
              key={i}
              trigger={
                <PropertyListItem
                  className="note-left-filter-pannel"
                  onClick={(e) => onClickPropertyPanel(e, i, label)}
                >
                  {icon}
                  <PropertyListLabel>{label}</PropertyListLabel>
                  {propertyFilters && getPropertyCount(label) > 0 && (
                    <PropertyListCount>
                      {getPropertyCount(label)}
                    </PropertyListCount>
                  )}
                </PropertyListItem>
              }
            >
              {propertyFilters && propertyFilters[label] && (
                <SubdivisionList border={border}>
                  <SubdivisionListItemWrapper
                    subMoreCount={subMoreCount}
                    isExpanded={subItemMoreKeys.includes(label)}
                  >
                    {Object.keys(propertyFilters[label]).map(
                      (propertyItem, i) => {
                        return (
                          <SubdivisionListItem
                            className="subdivision-item"
                            key={i}
                            active={propertyTarget[label].includes(
                              propertyFilters[label][propertyItem].code,
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              onClickItem({
                                e: e,
                                data: propertyFilters[label],
                                key: propertyItem,
                                label: label,
                              })
                            }}
                          >
                            <SubdivisionLabel>
                              <span>{propertyItem}</span>
                            </SubdivisionLabel>
                            <PropertyListCount>
                              {numberWithCommas(
                                propertyFilters[label][propertyItem]
                                  .count || 0,
                              )}
                            </PropertyListCount>
                          </SubdivisionListItem>
                        )
                      },
                    )}
                  </SubdivisionListItemWrapper>
                  {Object.keys(propertyFilters[label]).length >=
                    subMoreCount && (
                    <SubdivisionMore
                      onClick={() => onClickSubItemMore(label)}
                    >
                      {subItemMoreKeys.includes(label) ? (
                        <>
                          접기
                          <i className="fal fa-angle-up"></i>
                        </>
                      ) : (
                        <>
                          더보기
                          <i className="fal fa-angle-down"></i>
                        </>
                      )}
                    </SubdivisionMore>
                  )}
                </SubdivisionList>
              )}
            </Collapsible>
          </div>
        )
      })}
    </>
  )
}

const SubdivisionList = styled.div`
  margin-bottom: 12px;
  border-bottom: 1px solid ${theme.colors.gray[200]};

  ${({ border }) =>
    border &&
    `border-bottom: none;
  `};
`

const SubdivisionListItemWrapper = styled.div`
  & > div {
    ${({ subMoreCount }) =>
      `
      &:nth-child(n + ${subMoreCount}) {
        display: none;
      }
    `}

    ${({ isExpanded }) =>
      isExpanded &&
      `
        display: flex !important; 
    `}
  }
`

const PropertyListCount = styled.span`
  margin-left: 8px;
  padding: 2px 4px;
  background-color: ${theme.colors.gray[300]};
  border-radius: 2px;
  font-size: 8px;
  font-weight: 500;
  line-height: 100%;
  color: ${theme.colors.gray[900]};
`

const SubdivisionListItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 24px;
  color: ${theme.colors.gray[900]};
  border-radius: 4px;

  ${({ active }) =>
    active &&
    `
    background-color: #cce5ff;
    & > div {
      font-weight: 600;
    }
    ${PropertyListCount} {
      background-color: ${theme.colors.base.white};
    }
  `}

  &:hover {
    background-color: #cce5ff;
    & > div {
      font-weight: 600;
    }
    ${PropertyListCount} {
      background-color: ${theme.colors.base.white};
    }
  }
`

const SubdivisionLabel = styled.div`
  font-size: 13px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 140%;
  word-wrap: break-word;
  white-space: nowrap;
`

const SubdivisionMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.gray[600]};
  cursor: pointer;

  i {
    margin-left: 8px;
    font-size: 16px;
  }
`

export default React.memo(NotePropertyList)
