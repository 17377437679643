import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 애완동물
 */
const NoteAdColumnPet = ({ half = true }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const pet = watch(`oneroomInfo.oneroomFeature.pet`)

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>애완동물</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          fit
          label="가능"
          name="oneroom_pet"
          value="Y"
          checked={pet === 'Y'}
          {...register('oneroomInfo.oneroomFeature.pet')}
          onChange={(e) => {
            onUpdate('oneroomInfo.oneroomFeature.pet', e.target.value)
          }}
        />
        <RadioButton
          fit
          label="불가"
          name="oneroom_pet"
          value="N"
          {...register('oneroomInfo.oneroomFeature.pet')}
          checked={pet === 'N'}
          onChange={(e) => {
            onUpdate('oneroomInfo.oneroomFeature.pet', e.target.value)
          }}
        />
        <ValidateWarn>
          {errors?.oneroomInfo?.oneroomFeature?.pet?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnPet
