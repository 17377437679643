import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setRoadView } from 'modules/housingComplex'
import MapWalker from 'lib/utils/mapWalker'
import theme from 'lib/styles/theme'
import { MiniMap } from 'components/map/MapControl/MapRoadViewContainer'
import MapRoadView from 'components/map/MapControl/MapRoadView'
import { notification } from 'antd'

const MapHcRoadViewContainer = () => {
  const { kakao } = window
  const roadviewRef = useRef(null)

  const dispatch = useDispatch()

  const { isVisible } = useSelector(
    (state) => state.housingComplex.roadView,
  )
  const centerpoint = useSelector(
    (state) => state.housingComplex.centerpoint,
  )
  const data = useSelector((state) => state.housingComplex.data)

  let tempPolygons = []
  let tempCustomOverlays = []

  const engReg = /[^a-z|A-Z]/g
  const korReg = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g

  useEffect(() => {
    if (!isVisible) return

    if (!centerpoint || Object.keys(centerpoint).length === 0) return

    const roadviewContainer = roadviewRef.current //로드뷰를 표시할 div
    const roadviewObj = new kakao.maps.Roadview(roadviewContainer) //로드뷰 객체
    const roadviewClient = new kakao.maps.RoadviewClient() //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체
    const position = new kakao.maps.LatLng(
      centerpoint.coordinates?.[0],
      centerpoint.coordinates?.[1],
    )

    // 특정 위치의 좌표와 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄운다.
    roadviewClient.getNearestPanoId(position, 100, function (panoId) {
      if (panoId) {
        roadviewObj.setPanoId(panoId, position) //panoId와 중심좌표를 통해 로드뷰 실행
      } else {
        dispatch(setRoadView({ isVisible: false, isSupport: false }))
        notification.info({
          message: '알림',
          description: '로드뷰가 지원되지 않는 위치입니다.',
        })
      }
    })

    kakao.maps.event.addListener(roadviewObj, 'init', function () {
      getRoadviewCenterPoint(roadviewObj)
      initMiniMap(roadviewObj)
    })
  }, [isVisible, roadviewRef])

  // 로드뷰의 방향 세팅
  const getRoadviewCenterPoint = (roadview) => {
    // 로드뷰의 좌표와 clickPosition의 좌표를 비교하여 0~360도 사이의 각도를 계산한다
    let roadviewPosition = roadview.getPosition()
    let direction =
      Math.round(
        (Math.atan2(
          roadviewPosition.getLng() - centerpoint.coordinates?.[1],
          roadviewPosition.getLat() - centerpoint.coordinates?.[0],
        ) *
          180) /
          Math.PI +
          540,
      ) % 360

    // 계산한 각도로 동동이 방향(pan)을 돌려준다
    let viewpointWithPanoId = roadview.getViewpointWithPanoId()
    roadview.setViewpoint({
      ...viewpointWithPanoId,
      pan: direction,
    })
  }

  /**
   * 로드뷰 미니맵 초기화
   */
  const initMiniMap = (roadviewObj) => {
    const mapCenter = new kakao.maps.LatLng(
      centerpoint.coordinates?.[0],
      centerpoint.coordinates?.[1],
    )

    const miniMapContainer = document.getElementById(
      'roadview-miniMap-hc',
    )

    const options = {
      center: mapCenter,
      level: 2,
    }

    //지도 생성 및 객체 리턴
    const miniMap = new kakao.maps.Map(miniMapContainer, options)

    //지도 레벨 제한
    miniMap.setMinLevel(2)
    miniMap.setMaxLevel(2)

    //로드뷰 도로 영역 색칠
    miniMap.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW)

    setMiniMapPolygons(miniMap)
    setMiniMapWalker(roadviewObj, miniMap, mapCenter)
  }

  /**
   * 로드뷰 미니맵 폴리곤 세팅
   * @param {*} miniMap 미니맵 객체
   * @returns void
   */
  const setMiniMapPolygons = (miniMap) => {
    Object.keys(data).map((key) => {
      let addr = data[key]

      const shpaePoints = addr.shape.coordinates

      const polygonPath = shpaePoints.map((part) => {
        return part.map((point) => {
          return new kakao.maps.LatLng(point[0], point[1])
        })
      })

      const color = theme.colors.purple[600]
      // 지도에 표시할 다각형을 생성합니다
      const polygon = new kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 3, // 선의 두께입니다
        strokeColor: color, // 선의 색깔입니다
        strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'shortdash', // 선의 스타일입니다
        fillColor: color + '80', // 채우기 색깔입니다
        fillOpacity: 0.2, // 채우기 불투명도 입니다
      })

      polygon.danji = addr.id

      // 하나씩 생성된 건물 폴리곤을 건물 폴리곤 배열에 넣기
      tempPolygons.push(polygon)
      polygon.setZIndex(99999)

      //주택단지 텍스트 표시
      const danji_name = `${addr.danji_name.replace(
        korReg,
        '',
      )} 전원주택단지 ${addr.danji_name.replace(engReg, '')}`

      const customOverlay = new kakao.maps.CustomOverlay({
        position: new kakao.maps.LatLng(
          addr.centerpoint.coordinates[0],
          addr.centerpoint.coordinates[1],
        ),
        content: `<span style='color: ${theme.colors.purple[700]}; font-weight: 700; font-size: 0.875rem
        '>${danji_name}</span>`,
      })
      tempCustomOverlays.push(customOverlay)
    })

    setInMap(miniMap, tempPolygons, tempCustomOverlays)
  }

  const setInMap = (map, polygons, customOverlays) => {
    if (polygons.length === 0) return

    polygons?.map((polygon) => {
      polygon.setMap(map)
    })

    if (customOverlays.length === 0) return

    customOverlays.map((customOverlay) => {
      customOverlay.setMap(map)
    })
  }

  /**
   * 맵 위에 동동이 올리기
   * @param {*} roadview 로드뷰 객체
   * @param {*} miniMap 미니맵 객체
   * @param {*} mapCenter 맵 센터 포인트
   * @returns void
   */
  const setMiniMapWalker = (roadview, miniMap, mapCenter) => {
    let mapWalker = null

    // 로드뷰가 초기화 되었을때 map walker를 생성한다.
    mapWalker = new MapWalker(mapCenter)
    mapWalker.setMap(miniMap)

    // 로드뷰를 상,하,좌,우,줌인,줌아웃을 할 경우 발생한다.
    // 로드뷰를 조작할때 발생하는 값을 받아 map walker의 상태를 변경해 준다.
    kakao.maps.event.addListener(
      roadview,
      'viewpoint_changed',
      function () {
        // 이벤트가 발생할 때마다 로드뷰의 viewpoint값을 읽어, map walker에 반영
        const viewpoint = roadview.getViewpoint()
        mapWalker.setAngle(viewpoint.pan)
      },
    )

    // 로드뷰내의 화살표나 점프를 하였을 경우 발생한다.
    // position값이 바뀔 때마다 map walker의 상태를 변경해 준다.
    kakao.maps.event.addListener(
      roadview,
      'position_changed',
      function () {
        const position = roadview.getPosition()
        mapWalker.setPosition(position)
        miniMap.setCenter(position)
      },
    )

    //미니맵 지도 클릭하면 클릭한 위치 로드뷰를 보여줌(로드뷰가 불가한 지역이라면 가장 가까운 곳으로 강제 이동시킨다)
    kakao.maps.event.addListener(
      miniMap,
      'click',
      function (mouseEvent) {
        const position = mouseEvent.latLng
        const roadviewClient = new kakao.maps.RoadviewClient()

        roadviewClient.getNearestPanoId(
          position,
          50,
          function (panoId) {
            if (panoId) {
              roadview.setPanoId(panoId, position)
            } else {
              dispatch(
                setRoadView({ isVisible: false, isSupport: false }),
              )
            }
          },
        )

        mapWalker.setPosition(position)
        miniMap.setCenter(position)
      },
    )
  }

  const roadviewRoot = document.getElementById('roadview-root')

  return ReactDOM.createPortal(
    <MapRoadView
      isVisible={isVisible}
      onClick={() => dispatch(setRoadView({ isVisible: false }))}
    >
      <div id="roadview" ref={roadviewRef} />
      <MiniMap id="roadview-miniMap-hc" />
    </MapRoadView>,
    roadviewRoot,
  )
}

export default MapHcRoadViewContainer
