import NoteDonghoHoDetailNoteInfo from './NoteDonghoHoDetailNoteInfo'
import NoteDonghoHoDetailMemo from './NoteDonghoHoDetailMemo'
import NoteDonghoHoDetailButtons from './NoteDonghoHoDetailButtons'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/pagination/pagination.scss' // Pagination module
import 'swiper/modules/navigation/navigation.scss' // Pagination module
import { Navigation, Pagination } from 'swiper'
import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { ReactComponent as PrevIcon } from 'assets/icon/note/dongho/prev-arrow.svg'
import { ReactComponent as NextIcon } from 'assets/icon/note/dongho/next-arrow.svg'

/**
 * 동호관리
 * 호실 정보 (매물 or 광고)
 * @param data 매물 데이터(notes)
 * @param ads 광고 데이터
 * @param addressId
 * @param type 'ad' 광고인지
 */
const NoteDonghoHoNotes = ({ data, ads, addressId, type }) => {
  const swiperRef = useRef(null)

  const [currentPage, setCurrentPage] = useState(1)

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return
    swiperRef.current.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return
    swiperRef.current.slideNext()
  }, [])

  /**
   * 페이지 변경 동작
   */
  const onChangeSlide = (sw) => {
    const currIdx = sw.activeIndex
    setCurrentPage(currIdx + 1)
  }

  const swipeable = data.length > 1

  return (
    <>
      <StyledSwiper
        allowTouchMove={false}
        modules={[Navigation, Pagination]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        pagination={swipeable}
        onSlideChange={onChangeSlide}
      >
        {data.map((item, i) => {
          return (
            <StyledSwiperSlide
              padding={swipeable ? 'Y' : 'N'}
              key={i}
              data-regdate={item.regdate}
            >
              <NoteDonghoHoDetailNoteInfo
                type={type}
                matchAd={
                  type != 'ad' &&
                  ads.find(({ note_srl }) => note_srl == item.note_srl)
                }
                data={item}
              />
              {/* 매물용 메모와 버튼은 매물일때만 보여줌 */}
              {type != 'ad' && (
                <>
                  <NoteDonghoHoDetailMemo
                    type="update"
                    dongName={item.dong_name}
                    hoName={item.ho_name}
                    memo={item.memo}
                    noteSrl={item.note_srl}
                    addressSrl={item.address_srl}
                    channelSrl={item.channel_srl}
                    addressId={addressId}
                  />
                  <NoteDonghoHoDetailButtons
                    absolute
                    data={item}
                    ads={ads}
                  />
                </>
              )}
            </StyledSwiperSlide>
          )
        })}
      </StyledSwiper>
      {swipeable && (
        <>
          <Arrow onClick={handlePrev} disabled={currentPage == 1}>
            <PrevIcon />
          </Arrow>
          <Arrow
            direction="next"
            onClick={handleNext}
            disabled={currentPage == data.length}
          >
            <NextIcon />
          </Arrow>
        </>
      )}
    </>
  )
}

const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullets {
    bottom: 66px;
    .swiper-pagination-bullet {
      background-color: ${theme.colors.gray[600]} !important;
    }
  }
`

const StyledSwiperSlide = styled(SwiperSlide)`
  padding-bottom: ${({ padding }) =>
    padding == 'Y' ? `108px` : `54px`};
`

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
  bottom: 60px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  opacity: 0.4;
  background: ${theme.colors.gray[600]};
  z-index: 1;
  cursor: pointer;

  ${({ direction }) =>
    direction == 'next' &&
    `
    left: initial;
    right: 20px;

  `}

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.2;
  `}
`

export default NoteDonghoHoNotes
