import { Input } from 'components/design'
import styled from 'styled-components'

const StyledInputWrap = styled.div`
  margin-top: 4px;
  margin-bottom: 0.75rem;
`

const NoteFormContactSearch = ({ searchQuery, setSearchQuery }) => {
  const handleChangeQuery = (e) => {
    setSearchQuery(e.target.value)
  }

  return (
    <StyledInputWrap>
      <Input
        color={
          localStorage.getItem('notemode') == 'sheet'
            ? 'blue'
            : 'primary'
        }
        type="text"
        value={searchQuery}
        placeholder="연락처 이름 또는 전화번호"
        expand
        onChange={handleChangeQuery}
      />
    </StyledInputWrap>
  )
}

export default NoteFormContactSearch
