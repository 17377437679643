import axios from 'axios'
import {
  Button,
  Empty,
  List,
  ListItem,
  ListItemDesc,
  ListItemLabel,
} from 'components/design'
import NoteSearchAddressModal from 'components/jmapnotev2/elements/NoteSearchAddressModal'
import { useState } from 'react'
import styled from 'styled-components'
import { FlexWrapper, StyledInput } from './SignupForm'

/**
 * 회원가입 주소 폼 & 검색
 * @params register, errors, setValue - useForm 내장 함수
 * @params setSearchedAddrCode 중개업 등록번호 검색을 위해 저장할 b_code, h_code
 * @returns
 */
const SignupAddress = ({
  register,
  errors,
  setValue,
  setSearchedAddrCode,
}) => {
  const [searchModalVisible, setSearchModalVisible] = useState(false) //주소 검색 모달
  const [query, setQuery] = useState('') //주소 검색 input
  const [isEndSearch, setIsEndSearch] = useState(false) //주소 검색 완료 여부
  const [searchResults, setSearchResults] = useState([]) //주소 list

  /**
   * 주소 검색 api
   */
  const searchAddress = () => {
    axios
      .get(`/map/api/searchAddress?query=${query}`)
      .then((response) => {
        const result = response.data.documents
        setIsEndSearch(true)
        setSearchResults(result)
      })
  }

  const onClickItem = (item) => {
    setValue('company_address_num', item.road_address?.zone_no || '') //우편번호
    setValue(
      'company_address_road',
      item.road_address?.address_name || '',
    ) //도로명
    setValue('company_address_name', item.address?.address_name || '') //지번
    setSearchedAddrCode &&
      setSearchedAddrCode({
        b_code: item.address?.b_code,
        h_code: item.address?.h_code,
      })
  }

  const handleCancel = () => {
    setSearchModalVisible(false)
  }

  return (
    <>
      <CompanyAddress>
        <FlexWrapper>
          {/* 우편번호*/}
          <StyledInput
            {...register('company_address_num')}
            className={errors?.company_address_num && 'error'}
            readOnly
          />
          <Button onClick={() => setSearchModalVisible(true)}>
            검색
          </Button>
        </FlexWrapper>
        {/* 도로명 */}
        <StyledInput
          {...register('company_address_road')}
          className={errors?.company_address_road && 'error'}
          readOnly
        />
        {/* 지번 */}
        <StyledInput
          type="hidden"
          {...register('company_address_name')}
          readOnly
        />
      </CompanyAddress>

      <NoteSearchAddressModal
        visible={searchModalVisible}
        searchAddress={searchAddress}
        handleCancel={handleCancel}
        query={query}
        setQuery={setQuery}
        list={
          isEndSearch && searchResults.length == 0 ? (
            <Empty>검색결과가 없습니다.</Empty>
          ) : (
            searchResults &&
            searchResults.length > 0 && (
              <List noContainer>
                {searchResults.map((item, i) => (
                  <ListItem
                    key={i}
                    onClick={() => {
                      onClickItem(item)
                      handleCancel()
                    }}
                    noContainer
                    dense
                  >
                    <ListItemLabel>{item.address_name}</ListItemLabel>
                    {item.road_address && (
                      <ListItemDesc>
                        {item.road_address.road_name}{' '}
                        {item.road_address.main_building_no}{' '}
                        {item.road_address?.building_name &&
                          `(${item.road_address?.building_name})`}
                      </ListItemDesc>
                    )}
                  </ListItem>
                ))}
              </List>
            )
          )
        }
      />
    </>
  )
}

const CompanyAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default SignupAddress
