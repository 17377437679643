import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ChannelSelectContainer from 'containers/note/channel/ChannelSelectContainer'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { Button } from 'components/design'
import { HeaderDivider } from 'components/common/ExternalLayer/ExternalLayerHeader'
import HeaderIcon from 'components/common/Header/HeaderIcon'
import Header from 'components/common/Header/Header'
import BaseHeaderNoty from 'components/base/BaseHeader/BaseHeaderNoty'
import BaseHeaderNav from 'components/base/BaseHeader/BaseHeaderNav'
import useChannelPermission from 'containers/note/channel/hooks/useChannelPermission'
import useIsMobile from 'lib/hooks/useIsMobile'
import { Tooltip } from 'antd'
import NoteMode from '../NoteMode'
import theme from 'lib/styles/theme'

const ChannelHeader = () => {
  const { channelSrl, folderSrl } = useNoteMatch()
  const history = useHistory()
  const permission = useChannelPermission()
  const isMobile = useIsMobile()

  const onClickSettings = () => {
    history.push(`/note/${channelSrl}/${folderSrl}/settings`)
  }

  /**
   * 채널 변경
   * @param {Number} channelSrl
   * @param {Number} folderSrl
   */
  const handleChangeChannel = (channelSrl, folderSrl) => {
    history.push(`/note/${channelSrl}/${folderSrl}`)
  }

  if (isMobile)
    return (
      <Header fixed border noTopHeader>
        <ChannelSelectContainer onChange={handleChangeChannel} />
        <HeaderDivider />
        {permission !== 'readonly' && (
          <HeaderIcon onClick={onClickSettings}>
            <i className="far fa-cog"></i>
          </HeaderIcon>
        )}
        <BaseHeaderNoty />
        <BaseHeaderNav />
      </Header>
    )

  const root = document.getElementById('root')

  return (
    <StyledChannelHeader className="channel-header">
      <ChannelSelectContainer onChange={handleChangeChannel} />
      <NoteMode />
      {permission !== 'readonly' && (
        <SettingWrapper>
          <Tooltip title="설정">
            <SettingIcon
              className="far fa-cog"
              onClick={onClickSettings}
            ></SettingIcon>
          </Tooltip>
        </SettingWrapper>
      )}
    </StyledChannelHeader>
  )
}

const StyledChannelHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 20px;
  background-color: ${theme.colors.base.white};
  border-bottom: 2px solid ${theme.colors.gray[100]};
`
const SettingWrapper = styled.div`
  margin-left: 10px;
`

const SettingIcon = styled.i`
  padding: 6px;
  cursor: pointer;
`

export default ChannelHeader
