import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 원룸 특징
 */
const NoteAdColumnFeature = ({ half = true }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const fullOption = watch(`oneroomInfo.oneroomFeature.fullOption`)

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>원룸 특징</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          fit
          label="풀옵션이에요"
          name="oneroom_full_option"
          value="Y"
          checked={fullOption === 'Y'}
          {...register('oneroomInfo.oneroomFeature.fullOption')}
          onChange={(e) => {
            onUpdate(
              'oneroomInfo.oneroomFeature.fullOption',
              e.target.value,
            )
          }}
        />
        <RadioButton
          fit
          label="아니오"
          name="oneroom_full_option"
          value="N"
          {...register('oneroomInfo.oneroomFeature.fullOption')}
          checked={fullOption === 'N'}
          onChange={(e) => {
            onUpdate(
              'oneroomInfo.oneroomFeature.fullOption',
              e.target.value,
            )
          }}
        />
        <ValidateWarn>
          {errors?.oneroomInfo?.oneroomFeature?.fullOption?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeature
