import AppContainer from './AppContainer'
import { SectionWrapper, SubTitle, Title } from './LandingStyle'
import styled, { keyframes } from 'styled-components'
import LogoSrc from 'assets/images/note/landing/ad-logo.png'
import WriteSrc from 'assets/images/note/landing/write-ad.png'
import WriteingSrc from 'assets/images/note/landing/writing-ad.png'
import AdsSrc from 'assets/images/note/landing/ads.png'
import theme, { media } from 'lib/styles/theme'
import { useEffect, useRef } from 'react'
import StarIconSrc from 'assets/icon/note/dongho/star.png'
import { ReactComponent as AdIcon } from 'assets/images/note/landing/ad-icon.svg'

const LandingAd = () => {
  const willAnimatesRef = useRef([])
  const wrapperRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            willAnimatesRef.current.forEach((elem) => {
              elem.classList.add('animate')
            })
          }
        })
      },
      {
        threshold: 0.2,
      },
    )

    observer.observe(wrapperRef.current)
  }, [])

  return (
    <SectionWrapper ref={wrapperRef}>
      <AppContainer>
        <Content>
          <ContentTop>
            <TitleWrapper>
              <LogoImg src={LogoSrc} />
              <Title>
                내 매물 그대로,
                <br />
                광고 등록을 손쉽게.
              </Title>
              <SubTitle>
                번거로운 이중 입력 없이, 매물 관리에서 입력한 <br />
                정보 그대로 네이버 광고로 등록하세요.
              </SubTitle>
            </TitleWrapper>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[0] = el)}
              order="1"
            >
              <ContentText order="1">
                <Black>등기부등본</Black>
                <GradientText>
                  발급 원스톱
                  <img src={StarIconSrc} />
                </GradientText>
                <br />
                광고를 작성하는 중이어도
                <br />
                등기부등본을 발급할 수 있어요!
              </ContentText>
              <ContentImg order="1" src={WriteingSrc} />
            </ContentWrapper>
            <ContentWrapper
              ref={(el) => (willAnimatesRef.current[1] = el)}
              order="2"
            >
              <ContentText order="2">
                <span className="first_text">
                  한 눈에 보는 광고 관리
                </span>
                <br />
                검증 중, 검증 실패, 광고중 등<br />
                <span className="second_text">
                  내 광고의 현재 상태를 실시간
                </span>
                으로 <br />
                파악하고 대응 할 수 있어요.
                <br />
              </ContentText>
              <ContentImg order="2" src={AdsSrc} />
            </ContentWrapper>
          </ContentTop>
          <ContentWrapper
            ref={(el) => (willAnimatesRef.current[2] = el)}
            order="3"
          >
            <ContentText order="3">
              <AdIcon />
              <div>
                <strong>단 한번의 입력으로</strong>
              </div>
              작성한 매물 정보가 자동으로 <br />
              광고 등록 폼에 반영되어 <span>중복 작업 없이</span>
              <br />
              <span>편리하게 광고</span>를 <span>등록</span>할 수 있어요
            </ContentText>
            <ContentImg order="3" src={WriteSrc} />
          </ContentWrapper>
        </Content>
      </AppContainer>
    </SectionWrapper>
  )
}

const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 20px;

  ${media.mediumM`
    position: relative;
    margin-bottom: 30px;
  `}
`

const floatAnimation = keyframes`
 	from {
		opacity: 0;
		transform: translateY(10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`

const LogoImg = styled.img`
  width: 142px;
  height: 54px;
  object-fit: contain;

  ${media.mediumS`
        width: 120px;
        height: auto;
        object-fit: contain;           
    `}
`

const Content = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;

  ${media.mediumS`
      margin-top: 34px;        
  `}
`

const ContentTop = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  height: auto;

  ${media.mediumM`
        flex-direction: column;
        height: auto;   
    `}
`

const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  padding: 45px 40px;
  font-weight: 700;
  opacity: 0;

  ${media.mediumS`
      padding: 32px 28px;   
  `}

  &.animate {
    animation: ${floatAnimation} 0.8s ease 0.1s 1 normal backwards;
    opacity: 1;
  }

  ${({ order }) => {
    switch (order) {
      case '1':
        return `
            display: flex;
            flex-direction: column;
            width: 50%;
            background-color: #FCF8F2;
            color: #B5B8BA;
            align-self: flex-end;
            height: 550px;

            @media (max-width: ${theme.bp.mediumM}px) {
                width: 100%;
                height: 500px;  
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                height: auto;   
                min-height: 420px;  
            }
        `
      case '2':
        return `
            position: relative;
            background: linear-gradient(0deg, #E2E5FF 0%, #E2EFFF 100%);
            width: 50%;
            animation-delay: 0.3s !important;
            height: 980px;  
            color: #87AEDF;

            @media (max-width: ${theme.bp.mediumM}px) {
                width: 100%;
                height: 700px;  
            }

            @media (max-width: ${theme.bp.mediumS}px) {
                height: 450px;   
                min-height: unset;  
            }
        `
      case '3':
        return `
            position: relative;
            width: 100%;
            height: 435px;
            background: linear-gradient(180deg, #EEF7FC 0%, #DEEFF8 100%);
            animation-delay: 0.5s !important;

            @media (max-width: ${theme.bp.mediumS}px) {
              height: 360px;
            }

            svg {
                margin-bottom: 10px;

                @media (max-width: ${theme.bp.mediumM}px) {
                    width: 32px; 
                    margin-bottom: 0;
                }
                
                @media (max-width: ${theme.bp.mediumS}px) {
                    width: 28px; 
                    margin-bottom: -6px;
                }
            }
        `
    }
  }};
`

const ContentText = styled.div`
  font-size: 28px;
  line-height: 140%;

  ${media.mediumM`
    font-size: 26px;
  `}

  ${({ order }) => {
    switch (order) {
      case '1':
        return `
        `
      case '2':
        return `
            color: #87AEDF;
            font-weight: 700;

            .first_text {
              font-weight: 800;
              color: #10509F;
            }

            .second_text {
              color: #4784D0;
              font-weight: 800;
            }
        `
      case '3':
        return `
            position: absolute;
            top: 43%;
            transform: translateY(-43%);
            left: 40px;
            color: #8FAFC2;
            font-weight: 700;
            z-index: 10;

            strong {
              font-weight: 800;
              color: #056195;
            }

            span {
              color: #4890BD;
            }
            
            @media (max-width: ${theme.bp.mediumM}px) {
              position: relative;
              top: initial;
              transform: none;
              left: 0;
            }

            @media (max-width: ${theme.bp.mediumS}px) {
              svg {
                display: none;
              }
            }

        `
    }
  }};

  ${media.mediumM`
      word-break: keep-all;
  `}

  ${media.mediumS`
      font-size: 20px;
  `}
`

const ContentImg = styled.img`
  ${({ order, type }) => {
    switch (order) {
      case '1':
        return `
            width: auto;
            height: auto;
            position: absolute;
            right: -23px;
            bottom: -23px;
            max-height: 65%;

          `
      case '2':
        return `
            height: 770px;
            position: absolute;
            bottom: -11px;
            left: 52%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);

            @media (max-width: ${theme.bp.mediumM}px) {
                left: 50%;
                transform: translateX(-50%);
                max-height: 75%;
            }
                
            @media (max-width: ${theme.bp.mediumS}px) {
              bottom: -50px;
            }
        `
      case '3':
        return `
            max-width: 52%;
            height: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            
            @media (max-width: ${theme.bp.mediumM}px) {
              position: absolute;
              top: initial;
              transform: none;
              max-width: 80%;
              right: 0;
            }

            @media (max-width: ${theme.bp.mediumS}px) {
              max-width: 90%;
              bottom: 0;
            }
        `
    }
  }};
`

const GradientText = styled.span`
  background: linear-gradient(
    92deg,
    #ffa842 48.32%,
    rgba(255, 16, 159, 0.46) 96.01%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  line-height: 41.6px;
  letter-spacing: -0.32px;
`

const Black = styled.span`
  color: ${theme.colors.base.black};
  margin-right: 4px;
`

export default LandingAd
