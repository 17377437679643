import theme from 'lib/styles/theme'
import { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 광고관리 폼 전용 체크박스
 * 선택지가 array가 아닌 단일 항목이라면 checked 옵션을 수동으로 추가해주세요.
 * @param id unique한 id는 필수
 * @returns
 */
const Checkbox = (
  { children, id, onChange, checked, disabled, ...rest },
  ref,
) => {
  const { watch } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll

  return (
    <Wrapper>
      <input
        ref={ref}
        type="checkbox"
        id={id}
        onChange={(e) => {
          if (onChange) onChange(!checked, e.target.value, e)
        }}
        checked={checked}
        disabled={disabled || isDisabled}
        {...rest}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
      <Label htmlFor={id}>{children}</Label>
    </Wrapper>
  )
}

const Label = styled.label`
  margin-left: 6px;
  font-size: 14px;
  cursor: pointer;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  input[type='checkbox'] {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 0;
    border-radius: ${theme.borderRadius[1]};
    border: 2px solid ${theme.colors.gray[200]};
    background-color: ${theme.colors.base.white};
    transition: 0.2s ease-in-out;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:disabled {
      background-color: ${theme.colors.gray[200]};
    }
  }

  svg {
    display: none;
  }

  input[type='checkbox']:checked {
    border: 2px solid ${theme.colors.gray[200]};
    background-color: ${theme.colors.blue[400]};
    opacity: 1;
    outline: 0;

    & ~ ${Label} {
      color: ${theme.colors.blue[400]};
      font-weight: 700;
    }

    & ~ svg {
      display: block;
      position: absolute;
      color: white;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 2px;
      cursor: pointer;
      pointer-events: none;
      transform: translateY(-50%);
    }
  }
`

export default forwardRef(Checkbox)
