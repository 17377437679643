import { useSelector } from 'react-redux'
import { forwardRef, useEffect, useState } from 'react'
import useAxios from 'lib/hooks/useAxios'
import { getLocationAmenities } from 'lib/api/summaryApi'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Empty,
} from 'components/design'
import SummaryAmenitiesTab from 'components/summary/SummaryAmenities/SummaryAmenitiesTab'
import SummaryAmenitiesList from 'components/summary/SummaryAmenities/SummaryAmenitiesList'
import {
  Icons,
  IconsSrc,
} from 'components/summary/SummaryAmenities/SummaryAmenitiesIcon'
import { Skeleton } from 'antd'

/**
 * 교통, 편의시설
 * 공통 데이터 공급 컨테이너
 *
 *
 * * 렌더링 여부
 * * 공동주택: O
 * * 공동주택 아님 : O
 */
const SummaryAmenitiesContainer = (
  {
    prevMarker,
    setPrevMarker,
    activeValue,
    onClickSetActiveValue,
    resetActiveValue,
    noTitle,
    addressId,
    latitude,
    longitude,
    isApt,
  },
  ref,
) => {
  const { apt: aptTabRef, basic: basicRef } = ref
  const [onRequest, loading, data] = useAxios(getLocationAmenities)
  const [activeTab, setActiveTab] = useState('transportation')
  const [currentData, setCurrentData] = useState([])

  useEffect(() => {
    return () => {
      if (prevMarker) {
        setPrevMarker(null)
        prevMarker.setMap(null)
      }
    }
  }, [addressId])

  useEffect(() => {
    if (!addressId) return

    onRequest({
      address_id: addressId,
      latitude: latitude,
      longitude: longitude,
    })
  }, [addressId])

  useEffect(() => {
    if (!data || !data.result) return
    setCurrentData(data.result[[activeTab]])
  }, [data, activeTab])

  const onChangeTab = (val) => {
    setActiveTab(val)
  }

  return (
    <div ref={isApt ? aptTabRef : basicRef}>
      <Card>
        {!noTitle && (
          <CardHeader>
            <CardTitle>교통·편의시설</CardTitle>
          </CardHeader>
        )}
        <CardBody>
          <>
            <SummaryAmenitiesTab
              activeTab={activeTab}
              onChangeTab={onChangeTab}
            />
            {loading && (
              <Skeleton style={{ marginTop: '20px' }} active />
            )}
            {!loading && currentData.length == 0 && (
              <Empty mt>데이터가 없습니다.</Empty>
            )}
            {data && (
              <>
                <SummaryAmenitiesList
                  activeTab={activeTab}
                  data={currentData}
                  iconCollection={Icons(activeTab)}
                  iconSrcCollection={IconsSrc(activeTab)}
                  prevMarker={prevMarker}
                  setPrevMarker={setPrevMarker}
                  activeValue={activeValue}
                  onClickSetActiveValue={onClickSetActiveValue}
                  resetActiveValue={resetActiveValue}
                />
              </>
            )}
          </>
        </CardBody>
      </Card>
    </div>
  )
}

export default forwardRef(SummaryAmenitiesContainer)
