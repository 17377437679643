import { Empty } from 'components/design'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { dateFormat, numberWithCommas } from 'utils'

/**
 * 공동 주택 단지 정보
 */
const AptUnitInfo = () => {
  const { building } = useSelector((state) => state.summary.summaryInfo)
  const selectedArea = useSelector(
    (state) => state.summary.selectedAptArea,
  )
  const { apt } = building

  window.selectedArea = selectedArea

  return (
    Object.keys(building.apt)?.length > 0 && (
      <AptUnitInfoWrap>
        <ul>
          <li>
            <Label>사용승인일</Label>
            {dateFormat(building?.result?.[0]?.approval_date) || '-'}
          </li>{' '}
          {apt?.dong_count > 0 && (
            <li className="cnt">
              <Label>세대수</Label>
              {numberWithCommas(apt?.ho_count)}세대(총{apt?.dong_count}
              동)
            </li>
          )}
          {apt?.top_floor > 0 && (
            <li className="cnt">
              <Label>최고 층수</Label>
              {apt?.top_floor}층
            </li>
          )}
          {apt?.total_parking_count > 0 && (
            <li>
              <Label>총 주차대수</Label>
              {numberWithCommas(Math.round(apt?.total_parking_count))}대
              {apt?.ho_count && (
                <>
                  (세대당{' '}
                  {`${parseFloat(
                    apt?.total_parking_count / apt?.ho_count,
                  ).toFixed(2)}대`}
                  )
                </>
              )}
            </li>
          )}
          {apt?.vehicle_count > 0 && (
            <li>
              <Row primary="Y">
                <Label>총 차량 등록수</Label>
                <span>
                  {numberWithCommas(Math.round(apt?.vehicle_count))}대
                  {apt?.ho_count && (
                    <>
                      (세대당{' '}
                      {`${parseFloat(
                        apt?.vehicle_count / apt?.ho_count,
                      ).toFixed(2)}대`}
                      )
                    </>
                  )}
                </span>
              </Row>
            </li>
          )}
          {!!(
            apt?.electric_charger_simple?.fast ||
            apt?.electric_charger_simple?.normal ||
            apt?.electric_charger_simple?.very_fast
          ) && (
            <li>
              <Row primary="Y">
                <Label>전기차 주차대수</Label>
                <span>
                  {apt?.electric_charger_simple?.fast > 0 && (
                    <>
                      급속&nbsp;
                      {numberWithCommas(
                        apt?.electric_charger_simple?.fast,
                      )}
                      대
                    </>
                  )}
                </span>
                <span>
                  {apt?.electric_charger_simple?.normal > 0 && (
                    <>
                      {apt?.electric_charger_simple?.fast > 0 && ', '}
                      완속&nbsp;
                      {numberWithCommas(
                        apt?.electric_charger_simple?.normal,
                      )}
                      대
                    </>
                  )}
                </span>
                <span>
                  {apt?.electric_charger_simple?.very_fast > 0 && (
                    <>
                      {(apt?.electric_charger_simple?.normal > 0 ||
                        apt?.electric_charger_simple?.fast > 0) &&
                        ', '}
                      초급속
                      {numberWithCommas(
                        apt?.electric_charger_simple?.very_fast,
                      )}
                      대
                    </>
                  )}
                </span>
              </Row>
            </li>
          )}
          {apt?.corverage_ratio && (
            <li>
              <Label>건폐율</Label>
              {apt?.corverage_ratio?.toFixed(1)}%
            </li>
          )}
          {apt?.floor_area_ratio && (
            <li>
              <Label>용적률</Label>
              {apt?.floor_area_ratio?.toFixed(1)}%
            </li>
          )}
          {apt?.developer && (
            <li>
              <Label>시행사</Label>
              {apt?.developer}
            </li>
          )}
          {apt?.constructor && (
            <li>
              <Label>시공사</Label>
              {apt?.constructor}
            </li>
          )}
          {apt?.heating_type && (
            <li>
              <Label>난방</Label>
              {apt?.heating_type}
            </li>
          )}
          {apt?.cctv_count && (
            <li>
              <Label>CCTV 대수</Label>
              {apt?.cctv_count}대
            </li>
          )}
          {apt?.mgt_office_number && (
            <li>
              <Label>관리사무소</Label>
              {apt?.mgt_office_number}
            </li>
          )}
          {apt?.is_earthquake_res && (
            <li>
              <Row primary="Y">
                <Label>내진설계</Label>
                <span>적용 {apt?.earthquake_res} (거주동 기준)</span>
              </Row>
            </li>
          )}
        </ul>

        {/* FIXME: 관리비 데이터 정리한 후 작업 */}
        {/* {apt?.mgt_fee && selectedArea?.supply_area > 0 && (
        <FeeContainer>
          <FeeItem>
            <FeeLabel>관리비 평균</FeeLabel>
            <Fee>
              {Math.round(
                (apt?.mgt_fee * selectedArea.supply_area) / 10000,
              )}
              만원
            </Fee>
          </FeeItem>
          <FeeItem>
            {apt?.mgt_fee_s && (
              <>
                <FeeLabel>여름 평균</FeeLabel>
                <Fee>
                  {Math.round(
                    (apt?.mgt_fee_s * selectedArea.supply_area) / 10000,
                  )}
                  만원
                </Fee>
              </>
            )}
          </FeeItem>
          <FeeItem>
            {apt?.mgt_fee_w && (
              <>
                <FeeLabel>겨울 평균</FeeLabel>
                <Fee>
                  {Math.round(
                    (apt?.mgt_fee_w * selectedArea.supply_area) / 10000,
                  )}
                  만원
                </Fee>
              </>
            )}
          </FeeItem>
        </FeeContainer>
      )} */}
      </AptUnitInfoWrap>
    )
  )
}

const Row = styled.div`
  ${({ primary }) =>
    primary == 'Y' &&
    `
      span {
        color: ${theme.colors.primary[500]};
      }
  `}
`
const AptUnitInfoWrap = styled.div`
  // margin-bottom: 1.5rem;

  & > ul {
    font-size: 0.875rem;
    li {
      padding: 0.25rem 0;
      max-width: 350px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      font-weight: 500;
    }
  }
`
const Label = styled.div`
  display: inline-block;
  width: 120px;
  color: ${theme.colors.gray[500]};
  font-weight: 600;
`

const FeeContainer = styled.div`
  width: 100%;
  padding: 0.875rem 1rem;
  margin: 1rem 0 0;

  color: ${theme.colors.gray[900]};
  font-size: 0.875rem;
  background-color: ${theme.colors.gray[50]};
  border-radius: ${theme.borderRadius[1]};

  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  background: ${theme.colors.gray[100]};
`
const FeeItem = styled.div`
  max-width: 33.33333%;
  flex-basis: 33.33333%;
  text-align: center;
`
const Fee = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

const FeeLabel = styled.div`
  display: inline-block;
  color: ${theme.colors.gray[500]};
`

export default AptUnitInfo
