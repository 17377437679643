import styled from 'styled-components'
import { ReactComponent as PaymentIcon } from 'assets/icon/payment-active.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'

const SideMenuNoteAccountAndPay = ({ onClose }) => {
  const memberType =
    JSON.parse(localStorage.getItem('memberType')) || ''
  const loginInfo = useSelector((state) => state.auth.loginInfo)

  const history = useHistory()

  const movePage = (path) => {
    history.push(`${path}`)
    onClose()
  }

  return (
    <>
      <Hr />
      <Wrapper>
        <MenuItem onClick={() => movePage('/note/v2/account')}>
          <i className="fas fa-user-cog"></i>
          <span>계정관리</span>
        </MenuItem>
        {!navigator.userAgent.match(
          /(com\.jootek\.mobile|rhymixpush|RXPUSH)/,
        ) &&
          memberType &&
          loginInfo?.member_type == 'realestate' &&
          loginInfo.emergency != 'Y' && (
            <MenuItem onClick={() => movePage('/note/v2/payment')}>
              <PaymentIcon />
              <span>결제관리</span>
            </MenuItem>
          )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Hr = styled.hr`
  background: ${theme.colors.gray[200]};
  height: 1px;
  border: 0;
  margin: 10px 0;
`

const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 11.5px 0;
  width: 50%;
  span {
    margin-left: 12px;
    font-weight: 500;
    line-height: 180%;
  }

  i {
    color: ${theme.colors.blue[400]};
    font-size: 18px;
  }
`
export default SideMenuNoteAccountAndPay
