import { useContext } from 'react'
import { getComputedArea } from 'utils/index'
import MapContext from 'contexts/map'
import {
  Label,
  LabelIcon,
  Row,
  RowWrapper,
  Value,
} from 'components/summary/style/SummaryStyle'

const SummaryLobInfo = ({ data, features }) => {
  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  return (
    <RowWrapper>
      {data.building_name && (
        <Row>
          <LabelIcon className="fal fa-house" />
          <Label>건물이름</Label>
          <Value>{data.building_name}</Value>
        </Row>
      )}
      {data.purpose_code_name && (
        <Row>
          <LabelIcon className="fal fa-house-user" />
          <Label>주용도</Label>
          <Value>{data.purpose_code_name}</Value>
        </Row>
      )}
      {data.other_purpose && (
        <Row>
          <LabelIcon className="fal fa-home-lg-alt" />
          <Label>기타용도</Label>
          <Value>{data.other_purpose}</Value>
        </Row>
      )}
      {data.land_area && (
        <Row>
          <LabelIcon className="fal fa-expand" />
          <Label>대지면적</Label>
          <Value>
            {getComputedArea(data.land_area, isSquareMeter)}
          </Value>
        </Row>
      )}
      {data.building_area && (
        <Row>
          <LabelIcon className="fal fa-expand" />
          <Label>건축면적</Label>
          <Value>
            {getComputedArea(data.building_area, isSquareMeter)}
          </Value>
        </Row>
      )}
      {data.total_floor_area && (
        <Row>
          <LabelIcon className="fal fa-clone" />
          <Label>연면적</Label>
          <Value>
            {getComputedArea(data.total_floor_area, isSquareMeter)}
          </Value>
        </Row>
      )}
      {data.floor_count && data.basement_count && (
        <>
          <Row>
            <LabelIcon className="fal fa-layer-group" />
            <Label>규모</Label>
            <Value>
              {data.floor_count && `${data.floor_count}층`}
              {data.basement_count
                ? '\n지하 ' + data.basement_count + '층'
                : ''}
            </Value>
          </Row>
        </>
      )}
      {data.building_height && (
        <Row>
          <LabelIcon className="fal fa-sort-size-up-alt" />
          <Label>높이</Label>
          <Value>{Math.floor(data.building_height)}m</Value>
        </Row>
      )}
      {features}
    </RowWrapper>
  )
}

export default SummaryLobInfo
