import { useEffect, useState } from 'react'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Label,
  Text,
} from 'recharts'

const SummaryHcCharacterTabBuildingAge = ({
  data: originData,
  building_age_mean,
}) => {
  const COLORS = [
    theme.colors.age.younger,
    theme.colors.age.younger + 'ad',
    theme.colors.age.younger + '4a',
    theme.colors.age.older + '57',
    theme.colors.age.older + 'b0',
  ]

  const [data, setData] = useState()
  const [yearAvg, setYearAvg] = useState(0)
  const [maxActiveIdx, setMaxActiveIdx] = useState(0)

  useEffect(() => {
    const parseData = Object.entries({ ...originData }).map(
      ([key, value]) => ({
        name: key,
        value,
      }),
    )

    const maxValue = Math.max(...parseData.map((o) => o.value))
    const index = parseData.findIndex(({ value }) => value == maxValue)
    const sum = parseData.reduce((accum, item) => accum + item.value, 0)

    setMaxActiveIdx(index)
    setYearAvg(Math.round(sum / parseData.length))
    setData(parseData)
  }, [])

  const renderCusomizedLegend = ({ payload }) => {
    return (
      <CustomLegend>
        {payload.map((entry, i) => {
          const { payload, color } = entry
          const active = _.includes(false, payload.name)

          const year = payload.name.replace('년', '')
          const startYear = year === 10 ? 0 : year - 9

          return (
            <LegendItem key={i}>
              <LegendSquare color={color} />
              <LegendText>
                {year == 99 ? (
                  '40년 이상'
                ) : (
                  <>
                    {startYear} ~ {payload.name}
                  </>
                )}
              </LegendText>
            </LegendItem>
          )
        })}
      </CustomLegend>
    )
  }

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    let radius = innerRadius + (outerRadius - innerRadius) * 0.5
    let x = cx + radius * Math.cos(-midAngle * (Math.PI / 180))
    let y = cy + radius * Math.sin(-midAngle * (Math.PI / 180))

    return (
      <g style={{ pointerEvents: 'none' }}>
        {percent != 0 && (
          <GraphText
            x={x}
            y={y}
            fill="white"
            fontSize="12"
            dominantBaseline="central"
            textAnchor="middle"
          >
            {Math.round(percent * 100)}%
          </GraphText>
        )}
      </g>
    )
  }

  const renderActiveShape = (props) => {
    const { cx, cy, startAngle, endAngle, fill } = props

    return (
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={38}
        outerRadius={82}
        startAngle={startAngle}
        endAngle={endAngle}
        strokeWidth={0}
        fill={fill}
      />
    )
  }

  return (
    <ChartWrap>
      <ResponsiveContainer width="100%" height={310}>
        <PieChart data={data} width="100%" height={310}>
          <Tooltip />
          <Pie
            data={data}
            animationBegin={0}
            animationEasing={'ease-in-out'}
            animationDuration={500}
            innerRadius={43}
            outerRadius={75}
            paddingAngle={0}
            startAngle={90}
            endAngle={480}
            strokeWidth={0}
            dataKey="value"
            label={renderCustomizedLabel}
            labelPosition={50}
            labelLine={false}
            activeIndex={maxActiveIdx}
            activeShape={renderActiveShape}
          >
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label
              content={(props) => {
                const {
                  viewBox: { cx, cy },
                } = props
                const positioningProps = {
                  x: cx,
                  y: cy,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                }

                const presentationProps = {
                  fill:
                    building_age_mean > 30
                      ? theme.colors.age.older
                      : theme.colors.age.younger,
                  fontWeight: 700,
                }

                return (
                  <Text {...presentationProps} {...positioningProps}>
                    {`평균 ${Math.round(building_age_mean)}년`}
                  </Text>
                )
              }}
            />
          </Pie>
          <Legend
            verticalAlign="bottom"
            height={36}
            content={renderCusomizedLegend}
          />
        </PieChart>
      </ResponsiveContainer>
    </ChartWrap>
  )
}

const CustomLabel = styled(Label)`
  color: red;
`

const ChartWrap = styled.div`
  g {
    pointer-events: none;
  }

  .recharts-legend-wrapper {
    height: auto !important;
    bottom: 0 !important;
  }
`

const LegendSquare = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.3rem;
  flex-basis: calc(50% - 2.3rem);
  margin-bottom: 0.5rem;
`

const LegendText = styled.span`
  color: ${theme.colors.gray[600]};
  font-size: 0.813rem;
  margin-left: 1rem;
  font-variant-numeric: tabular-nums lining-nums;
`

const CustomLegend = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`

const GraphText = styled.text`
  font-weight: 500;
  fill: ${theme.colors.base.white};
  font-size: 0.75rem;
  pointer-events: none;
`
export default SummaryHcCharacterTabBuildingAge
