import styled from 'styled-components'

const SummaryLegalDevInfo = ({ development }) => {
  return development.map((item, idx) => {
    return (
      <Wrapper key={idx}>
        <Development>
          <Subject>{item.sj}</Subject>
          <Cn>{item.cn}</Cn>
        </Development>
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  font-size: 13px;
`

const Development = styled.div`
  border-top: 1px solid #e9ecef;
`

const Subject = styled.div`
  border-bottom: 1px solid #e9ecef;
  background: #f8f9fa;
  padding: 3px 12px;
`

const Cn = styled.div`
  font-size: 12px;
  padding: 8px 12px;
`

export default SummaryLegalDevInfo
