import SummaryHeader from 'components/summary/header/SummaryHeader'
import { useSelector } from 'react-redux'

const SummaryHeaderContainer = ({ isOpen }) => {
  // const { summaryInfo } = useSummary()
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)

  if (!summaryInfo?.land || !summaryInfo?.building) return null

  return (
    <SummaryHeader
      isOpen={isOpen}
      land={summaryInfo?.land}
      building={summaryInfo?.building}
    />
  )
}

export default SummaryHeaderContainer
