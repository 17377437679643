export const columnCentered = {
  headerClass: 'text-center',
  cellStyle: {
    textAlign: 'center',
  },
}
export const columnAlignLeft = {
  cellStyle: {
    justifyContent: 'start',
    display: 'block',
  },
}

export const columnRowSpan = (params) => {
  const contact = params.data.testContact
  const size = contact.length
  return size
}
