import SchoolIcon from 'assets/icon/map-marker/school.svg'
import {
  Distance,
  Item,
  Name,
  Title,
} from '../SummaryAmenities/SummaryAmenitiesListItem'
import useSummarySchoolAmemities from 'lib/hooks/useSummarySchoolAmemities'
import useIsMobile from 'lib/hooks/useIsMobile'

const SummarySchoolListItem = ({
  name,
  distance,
  icon,
  centerpoint: targetCenterPoint,
  prevMarker,
  setPrevMarker,
  onClickSetActiveValue,
  activeValue,
  resetActiveValue,
}) => {
  const { onClick } = useSummarySchoolAmemities()
  const isMobile = useIsMobile()

  const onClickItem = () => {
    if (isMobile) return
    onClickSetActiveValue(name)
    onClick({
      prevMarker,
      setPrevMarker,
      targetCenterPoint,
      iconSrc: SchoolIcon,
      activeValue,
      name,
      resetActiveValue,
    })
  }

  return (
    <Item active={activeValue == name} onClick={onClickItem}>
      <Title>
        {icon}
        <Name>{name}</Name>
      </Title>
      <Distance>{distance}</Distance>
    </Item>
  )
}

export default SummarySchoolListItem
