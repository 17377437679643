import { useContext } from 'react'
import { isEqual } from 'lodash'
import MapContext from 'contexts/map'
import {
  funcMakePolygon,
  setCombinationBuildingPolygons,
} from 'lib/utils/index'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { getAddressName } from 'lib/utils'
import theme from 'lib/styles/theme'

const SummarySizeReviewPolygon = ({ target, map }) => {
  const { state } = useContext(MapContext)

  /**
   * 지도에 다격형 표시 및 다격형 관련 이벤트 설정
   */
  useUpdateEffect(() => {
    const { combinationStandardPolygons } = state
    // 토지 폴리곤 지도 위에 표시
    if (combinationStandardPolygons.length === 0) return

    combinationStandardPolygons?.map(({ land, building }) => {
      land.length > 0 &&
        land.map((landItem) => {
          landItem.setMap(map)
        })

      building.length > 0 &&
        building.map((buildingItem) => {
          buildingItem.setMap(map)
        })
      return true
    })

    // 폴리곤 초기화
    return () => {
      if (combinationStandardPolygons.length === 0) return

      combinationStandardPolygons.map(({ land, building }) => {
        land.length > 0 &&
          land.map((landItem) => {
            landItem.setMap(null)
          })
        building.length > 0 &&
          building.map((buildingItem) => {
            buildingItem.setMap(null)
          })
        return true
      })
    }
  }, [state.combinationStandardPolygons])

  /**
   * 합필 폴리곤
   * 지도에 다격형 표시 및 다격형 관련 이벤트 설정
   */
  useUpdateEffect(() => {
    const { combinationPolygons, combinationStandardPolygons } = state

    if (combinationPolygons.length === 0) return
    if (combinationStandardPolygons.length === 0) return

    combinationPolygons?.map(({ land, building }) => {
      //기준 토지는 선택되지 않도록 함
      if (
        isEqual(
          combinationStandardPolygons[0].land[0].getPath(),
          land[0].getPath(),
        )
      )
        return

      land.length > 0 &&
        land.map((landItem) => {
          landItem.setMap(map)
        })

      building.length > 0 &&
        building.map((buildingItem) => {
          buildingItem.setMap(map)
        })
      return true
    })

    // 폴리곤 초기화
    return () => {
      if (combinationPolygons.length === 0) return

      combinationPolygons.map(({ land, building }) => {
        land.length > 0 &&
          land.map((landItem) => {
            landItem.setMap(null)
          })
        building.length > 0 &&
          building.map((buildingItem) => {
            buildingItem.setMap(null)
          })
        return true
      })
    }
  }, [state.combinationPolygons])

  return <></>
}

/**
 * 클릭한 지점의 토지 정보 세팅하기
 * - 대구 등의 지역은 카카오 폴리곤 세팅
 * - ContextAPI 설정 및 history 설정
 *
 * @param {*} response 클릭한 지점의 address 정보
 * @param {*} kakao
 * @param {*} actions
 */
export const setCombinationStandardPolygon = (
  result,
  kakao,
  state,
  actions,
) => {
  // 토지 폴리곤 세팅
  const landPolygons = funcMakePolygon(result, kakao)

  // 부속 토지 다각형을 생성합니다
  result.sub_addresses?.map((sub) => {
    sub.shape?.coordinates?.map((part) => {
      return part.map((point) => {
        return new kakao.maps.Coords(point[0], point[1]).toLatLng()
      })
    })
  })

  // 건물 폴리곤 세팅
  const buildingPolygons = result.buildings
    ? setCombinationBuildingPolygons(result, kakao, actions)
    : []
  actions.setCombinationStandardPolygons([
    {
      land: landPolygons,
      building: buildingPolygons,
      id: result.id,
      address: getAddressName(result),
    },
  ])
}

//합필 폴리곤 세팅
export const setCombinationAddressPolygon = (
  response,
  kakao,
  state,
  actions,
) => {
  const result = response?.data?.result || response.result
  // 토지 폴리곤 세팅
  const landPolygons = funcMakePolygon(
    result,
    kakao,
    false,
    theme.colors.combinationPolygonStrokeColor,
  )

  // 부속 토지 다각형을 생성합니다
  result.sub_addresses?.map((sub) => {
    sub.shape?.coordinates?.map((part) => {
      return part.map((point) => {
        return new kakao.maps.Coords(point[0], point[1]).toLatLng()
      })
    })
  })

  // 건물 폴리곤 세팅
  const buildingPolygons = result.buildings
    ? setCombinationBuildingPolygons(result, kakao, actions)
    : []

  actions.setCombinationPolygons([
    ...state.combinationPolygons,
    {
      land: landPolygons,
      building: buildingPolygons,
      id: result.id,
      address: getAddressName(result),
    },
  ])
}

export default SummarySizeReviewPolygon
