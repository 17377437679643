import { Checkbox } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  TableContent,
  InnerTable,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  innerSubHeaderWidth,
  StyledTable,
  RadioButtonsWrap,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

/**
 * 주거용 건축물
 * 임대차 확인사항
 */
const NoteDocsBasic4Residential = ({ onUpdate, onUpdateMultiple }) => {
  const { watch, setValue } = useFormContext()

  const d_property_type = watch('d_property_type')
  const d_descript_type = watch('d_descript_type')
  const d_hwagjeongilja_buyeo = watch('d_hwagjeongilja_buyeo')
  const d_explained_tenantrights_hwagjeong = watch(
    'd_explained_tenantrights_hwagjeong',
  )
  const d_segeum_chenab = watch('d_segeum_chenab')
  const d_explained_tenantrights_chenab = watch(
    'd_explained_tenantrights_chenab',
  )
  const d_minganrent_deunglog = watch('d_minganrent_deunglog')
  const d_minganrent_other = watch('d_minganrent_other')
  const d_minganrent_period = watch('d_minganrent_period')
  const d_minganrent_date = watch('d_minganrent_date')
  const d_minganrent_mideunglog = watch('d_minganrent_mideunglog')
  const d_renewal_right = watch('d_renewal_right')
  const d_is_submit_dagagu = watch('d_is_submit_dagagu')
  const d_jeonibsedae_hwaginseo = watch('d_jeonibsedae_hwaginseo')
  const d_soaegimchain = watch('d_soaegimchain')
  const d_choeuseonbyeonjegeum = watch('d_choeuseonbyeonjegeum')
  const d_explained_bojeunggeum_bojeung = watch(
    'd_explained_bojeunggeum_bojeung',
  )

  const disabled = !d_property_type.includes('임대')

  const eachRowHeight = [11, 11, 11, 11, 45, 11]

  useUpdateEffect(() => {
    if (!d_property_type) return

    if (!d_property_type.includes('임대')) {
      onUpdateMultiple({
        d_hwagjeongilja_buyeo: '',
        d_explained_tenantrights_hwagjeong: '',
        d_segeum_chenab: '',
        d_explained_tenantrights_chenab: '',
        d_jeonibsedae_hwaginseo: '',
        d_soaegimchain: '',
        d_choeuseonbyeonjegeum: '',
        d_minganrent_mideunglog: '',
        d_minganrent_deunglog: '',
        d_minganrent_other: '',
        d_minganrent_period: '',
        d_minganrent_date: '',
        d_explained_bojeunggeum_bojeung: '',
        d_renewal_right: '',
      })
    }
  }, [d_property_type])

  return (
    <>
      <Description>
        * 그 밖에 추가적인 소유권 정보를 확인해 주세요.
      </Description>
      <StyledTable>
        <Row height={31} rowspan={10} noborderbottom>
          <TableHeader width={headerWidth}>
            ④ 임대차
            <br />
            확인사항
          </TableHeader>
          <RowSpanInner
            rowspanheightpercent={eachRowHeight[0]}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth + innerSubHeaderWidth}>
              확정일자 부여현황 정보
            </TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTableContent
                headerwidth={headerWidth}
                checkboxes
                spacebetween
              >
                <RadioWrapper>
                  <RadioButton
                    label="임대인 자료 제출"
                    checked={
                      d_hwagjeongilja_buyeo == '임대인 자료 제출'
                    }
                    name="d_hwagjeongilja_buyeo"
                    onChange={(e) =>
                      onUpdate(
                        'd_hwagjeongilja_buyeo',
                        '임대인 자료 제출',
                      )
                    }
                    disabled={disabled}
                  />
                  <RadioButton
                    label="열람 동의"
                    checked={d_hwagjeongilja_buyeo == '열람 동의'}
                    name="d_hwagjeongilja_buyeo"
                    onChange={(e) =>
                      onUpdate('d_hwagjeongilja_buyeo', '열람 동의')
                    }
                    disabled={disabled}
                  />
                </RadioWrapper>
                <EndPositionCheckbox>
                  <Checkbox
                    color="blue"
                    checked={
                      d_explained_tenantrights_hwagjeong == 'Y'
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      onUpdate(
                        'd_explained_tenantrights_hwagjeong',
                        e ? 'Y' : 'N',
                      )
                    }
                    readOnly={disabled}
                  >
                    임차인 권리 설명
                  </Checkbox>
                </EndPositionCheckbox>
              </InnerTableContent>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={eachRowHeight[1]}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth + innerSubHeaderWidth}>
              국세 및 지방세 체납정보
            </TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTableContent
                headerwidth={headerWidth}
                checkboxes
                spacebetween
              >
                <RadioWrapper>
                  <RadioButton
                    label="임대인 자료 제출"
                    checked={d_segeum_chenab == '임대인 자료 제출'}
                    name="d_segeum_chenab"
                    onChange={(e) =>
                      onUpdate('d_segeum_chenab', '임대인 자료 제출')
                    }
                    disabled={disabled}
                  />
                  <RadioButton
                    label="열람 동의"
                    checked={d_segeum_chenab == '열람 동의'}
                    name="d_segeum_chenab"
                    onChange={(e) =>
                      onUpdate('d_segeum_chenab', '열람 동의')
                    }
                    disabled={disabled}
                  />
                </RadioWrapper>
                <EndPositionCheckbox>
                  <Checkbox
                    color="blue"
                    checked={
                      d_explained_tenantrights_chenab == 'Y'
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      onUpdate(
                        'd_explained_tenantrights_chenab',
                        e ? 'Y' : 'N',
                      )
                    }
                    readOnly={disabled}
                  >
                    임차인 권리 설명
                  </Checkbox>
                </EndPositionCheckbox>
              </InnerTableContent>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={eachRowHeight[2]}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth + innerSubHeaderWidth}>
              전입세대 확인서
            </TableSubHeader>
            <TableContent fullwidth nopadding noborderright>
              <InnerTableContent checkboxes>
                <RadioButton
                  label="확인(확인서류 첨부)"
                  checked={d_jeonibsedae_hwaginseo == '확인'}
                  name="d_jeonibsedae_hwaginseo"
                  onChange={(e) =>
                    onUpdate('d_jeonibsedae_hwaginseo', '확인')
                  }
                  disabled={disabled}
                />
                <RadioButton
                  label="미확인(열람·교부 신청방법 설명)"
                  checked={d_jeonibsedae_hwaginseo == '미확인'}
                  name="d_jeonibsedae_hwaginseo"
                  onChange={(e) =>
                    onUpdate('d_jeonibsedae_hwaginseo', '미확인')
                  }
                  disabled={disabled}
                />
                <RadioButton
                  label="해당 없음"
                  checked={d_jeonibsedae_hwaginseo == '해당없음'}
                  name="d_jeonibsedae_hwaginseo"
                  onChange={(e) =>
                    onUpdate('d_jeonibsedae_hwaginseo', '해당없음')
                  }
                  disabled={disabled}
                />
              </InnerTableContent>
            </TableContent>
          </RowSpanInner>
          <RowSpanInner
            rowspanheightpercent={eachRowHeight[3]}
            minuswidth={headerWidth}
          >
            <TableSubHeader width={headerWidth + innerSubHeaderWidth}>
              최우선변제금
            </TableSubHeader>
            <InnerTableContent pl>
              <InputWrapper>
                {disabled ? (
                  <>&nbsp;&ndash;&nbsp;만원 이하</>
                ) : (
                  <ColorInput
                    width={170}
                    type="number"
                    defaultValue={d_soaegimchain}
                    onBlur={(e) =>
                      onUpdate(
                        'd_soaegimchain',
                        String(e.target.value)?.replaceAll(',', ''),
                      )
                    }
                    append={'만원 이하'}
                    disabled={disabled}
                  />
                )}
              </InputWrapper>
              <InputWrapper>
                최우선변제금액:
                {disabled ? (
                  <>&nbsp;&ndash;&nbsp;만원 이하</>
                ) : (
                  <ColorInput
                    width={170}
                    type="number"
                    defaultValue={d_choeuseonbyeonjegeum}
                    onBlur={(e) =>
                      onUpdate(
                        'd_choeuseonbyeonjegeum',
                        String(e.target.value)?.replaceAll(',', ''),
                      )
                    }
                    append={'만원 이하'}
                    disabled={disabled}
                  />
                )}
              </InputWrapper>
            </InnerTableContent>
          </RowSpanInner>
          <>
            <RowSpanInner
              rowspanheightpercent={eachRowHeight[4]}
              rowspan={2}
              minuswidth={headerWidth}
            >
              <StyledTableSubHeader
                noborderright
                width={headerWidth + innerSubHeaderWidth}
              >
                <TableSubHeader width={headerWidth}>
                  민간 임대
                  <br />
                  등록 여부
                </TableSubHeader>
                <StlyedTableSubHeaderVertical>
                  <TableSubHeaderCustomHeight height={66}>
                    등록
                  </TableSubHeaderCustomHeight>
                  <TableSubHeaderCustomHeight white height={34}>
                    <Checkbox
                      color="blue"
                      checked={
                        d_minganrent_mideunglog == 'Y' ? true : false
                      }
                      onChange={(e) =>
                        onUpdate(
                          'd_minganrent_mideunglog',
                          e ? 'Y' : 'N',
                        )
                      }
                      readOnly={disabled}
                    >
                      미등록
                    </Checkbox>
                  </TableSubHeaderCustomHeight>
                </StlyedTableSubHeaderVertical>
              </StyledTableSubHeader>
              <TableContent fullwidth nopadding noborderright>
                <InnerTable>
                  <DividedSectionWrapper>
                    <DividedSection>
                      <Row heightpercent={67}>
                        <InnerTableContent checkboxes>
                          <StyledRadioButtonsWrap width={460}>
                            <RadioFlexWrap>
                              <RadioButton
                                label="장기일반민간임대주택"
                                checked={
                                  d_minganrent_deunglog ==
                                  '장기일반민간임대주택'
                                }
                                name="d_minganrent_deunglog"
                                onChange={(e) =>
                                  onUpdate(
                                    'd_minganrent_deunglog',
                                    '장기일반민간임대주택',
                                  )
                                }
                                disabled={disabled}
                              />
                              <RadioButton
                                label="공공지원민간임대주택"
                                checked={
                                  d_minganrent_deunglog ==
                                  '공공지원민간임대주택'
                                }
                                name="d_minganrent_deunglog"
                                onChange={(e) =>
                                  onUpdate(
                                    'd_minganrent_deunglog',
                                    '공공지원민간임대주택',
                                  )
                                }
                                disabled={disabled}
                              />
                            </RadioFlexWrap>
                            <RadioFlexWrap>
                              <RadioButton
                                label="그 밖의 유형"
                                checked={
                                  d_minganrent_deunglog ==
                                  '그 밖의 유형'
                                }
                                name="d_minganrent_deunglog"
                                onChange={(e) =>
                                  onUpdate(
                                    'd_minganrent_deunglog',
                                    '그 밖의 유형',
                                  )
                                }
                                disabled={disabled}
                              />
                              {!disabled && (
                                <ColorInput
                                  type="text"
                                  defaultValue={d_minganrent_other}
                                  disabled={
                                    d_minganrent_deunglog !=
                                    '그 밖의 유형'
                                  }
                                  onBlur={(e) =>
                                    onUpdate(
                                      'd_minganrent_other',
                                      e.target.value,
                                    )
                                  }
                                />
                              )}
                            </RadioFlexWrap>
                          </StyledRadioButtonsWrap>
                        </InnerTableContent>
                      </Row>
                      <Row heightpercent={33} noborderbottom>
                        <InnerTableBlock
                          widthpercent={50}
                          noborderbottom
                        >
                          <Row heightpercent={100}>
                            <TableSubHeader width={innerSubHeaderWidth}>
                              임대의무기간
                            </TableSubHeader>
                            <InnerTableContent
                              headerwidth={innerSubHeaderWidth}
                            >
                              {!disabled && (
                                <ColorInput
                                  type="text"
                                  defaultValue={d_minganrent_period}
                                  onBlur={(e) =>
                                    onUpdate(
                                      'd_minganrent_period',
                                      e.target.value,
                                    )
                                  }
                                  fullWidth
                                  disabled={disabled}
                                />
                              )}
                            </InnerTableContent>
                          </Row>
                        </InnerTableBlock>
                        <InnerTableBlock
                          widthpercent={50}
                          noborderright
                          noborderbottom
                        >
                          <Row heightpercent={100}>
                            <TableSubHeader width={innerSubHeaderWidth}>
                              임대개시일
                            </TableSubHeader>
                            <InnerTableContent
                              headerwidth={innerSubHeaderWidth}
                            >
                              {!disabled && (
                                <ColorInput
                                  type="text"
                                  defaultValue={d_minganrent_date}
                                  onBlur={(e) =>
                                    onUpdate(
                                      'd_minganrent_date',
                                      e.target.value,
                                    )
                                  }
                                  fullWidth
                                  disabled={disabled}
                                />
                              )}
                            </InnerTableContent>
                          </Row>
                        </InnerTableBlock>
                      </Row>
                    </DividedSection>
                    <DividedSection right>
                      <Checkbox
                        color="blue"
                        checked={
                          d_explained_bojeunggeum_bojeung == 'Y'
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          onUpdate(
                            'd_explained_bojeunggeum_bojeung',
                            e ? 'Y' : 'N',
                          )
                        }
                        readOnly={disabled}
                      >
                        임대보증금 보증 설명
                      </Checkbox>
                    </DividedSection>
                  </DividedSectionWrapper>
                  <Row heightpercent={36} noborderbottom></Row>
                </InnerTable>
              </TableContent>
            </RowSpanInner>
            <RowSpanInner
              rowspanheightpercent={eachRowHeight[5]}
              minuswidth={headerWidth}
              noborderbottom={d_descript_type == '비주거용 건축물'}
            >
              <TableSubHeader width={headerWidth + innerSubHeaderWidth}>
                계약갱신 요구권 행사 여부
              </TableSubHeader>
              <TableContent fullwidth nopadding noborderright>
                <InnerTableContent checkboxes>
                  <RadioButton
                    label="확인&#40;확인서류 첨부&#41;"
                    checked={d_renewal_right == '확인'}
                    name="d_renewal_right"
                    onChange={(e) =>
                      onUpdate('d_renewal_right', '확인')
                    }
                    disabled={disabled}
                  />
                  <RadioButton
                    label="미확인"
                    checked={d_renewal_right == '미확인'}
                    name="d_renewal_right"
                    onChange={(e) =>
                      onUpdate('d_renewal_right', '미확인')
                    }
                    disabled={disabled}
                  />
                  <RadioButton
                    label="해당없음"
                    checked={d_renewal_right == '해당없음'}
                    name="d_renewal_right"
                    onChange={(e) =>
                      onUpdate('d_renewal_right', '해당없음')
                    }
                    disabled={disabled}
                  />
                </InnerTableContent>
              </TableContent>
            </RowSpanInner>
            {/* {d_descript_type == '주거용 건축물' && (
              <RowSpanInner
                rowspanheightpercent={eachRowHeight[6]}
                minuswidth={headerWidth + innerSubHeaderWidth}
                noborderbottom
              >
                <TableSubHeader
                  width={headerWidth + innerSubHeaderWidth}
                >
                  다가구주택 확인서류 제출여부
                </TableSubHeader>
                <TableContent fullwidth nopadding noborderright>
                  <InnerTableContent checkboxes>
                    <RadioButton
                      label="확인&#40;확인서류 첨부&#41;"
                      checked={d_is_submit_dagagu == '확인'}
                      name="d_is_submit_dagagu"
                      onChange={(e) =>
                        onUpdate('d_is_submit_dagagu', '확인')
                      }
                    />
                    <RadioButton
                      label="미확인"
                      checked={d_is_submit_dagagu == '미확인'}
                      name="d_is_submit_dagagu"
                      onChange={(e) =>
                        onUpdate('d_is_submit_dagagu', '미확인')
                      }
                    />
                    <RadioButton
                      label="해당없음"
                      checked={d_is_submit_dagagu == '해당없음'}
                      name="d_is_submit_dagagu"
                      onChange={(e) =>
                        onUpdate('d_is_submit_dagagu', '해당없음')
                      }
                    />
                  </InnerTableContent>
                </TableContent>
              </RowSpanInner>
            )} */}
          </>
        </Row>
      </StyledTable>
      <AddedDesc>
        <SignSection border>
          개업공인중개사가 &#34;④ 임대차 확인사항&#34;을 임대인 및
          임차인에게 설명하였음을 확인함
        </SignSection>
        <SignSection>
          <RowSpanInner rowspanheightpercent={25} fullwidth>
            <TableSubHeader
              width={innerSubHeaderWidth}
              disabled={disabled}
            >
              임대인
            </TableSubHeader>
            <SignArea
              fullwidth
              nopadding
              noborderright
              disabled={disabled}
            >
              &#40;서명 또는 날인&#41;
            </SignArea>
          </RowSpanInner>
          <RowSpanInner rowspanheightpercent={25} fullwidth>
            <TableSubHeader
              width={innerSubHeaderWidth}
              disabled={disabled}
            >
              임차인
            </TableSubHeader>
            <SignArea
              fullwidth
              nopadding
              noborderright
              disabled={disabled}
            >
              &#40;서명 또는 날인&#41;
            </SignArea>
          </RowSpanInner>
          <RowSpanInner rowspanheightpercent={25} fullwidth>
            <TableSubHeader
              width={innerSubHeaderWidth}
              disabled={disabled}
            >
              개업공인중개사
            </TableSubHeader>
            <SignArea
              fullwidth
              nopadding
              noborderright
              disabled={disabled}
            >
              &#40;서명 또는 날인&#41;
            </SignArea>
          </RowSpanInner>
          <RowSpanInner
            noborderbottom
            rowspanheightpercent={25}
            fullwidth
          >
            <TableSubHeader
              width={innerSubHeaderWidth}
              disabled={disabled}
            >
              개업공인중개사
            </TableSubHeader>
            <SignArea
              fullwidth
              nopadding
              noborderright
              disabled={disabled}
            >
              &#40;서명 또는 날인&#41;
            </SignArea>
          </RowSpanInner>
        </SignSection>
      </AddedDesc>
      <AddedDesc full>
        <div>
          <div>
            &#8251; 민간임대주택의 임대사업자는 「민간임대주택에 관한
            특별법」 제49조에 따라 임대보증금에 대한 보증에 가입해야
            합니다.
          </div>
          <div>
            &#8251; 임차인은 주택도시보증공사&#40;HUG&#41; 등이 운영하는
            전세보증금반환보증에 가입할 것을 권고합니다.
          </div>
          <div>
            &#8251; 임대차 계약 후 「부동산 거래신고 등에 관한 법률」
            제6조의2에 따라 30일 이내 신고해야 합니다&#40;신고 시
            확정일자 자동부여&#41;.
          </div>
          <div>
            &#8251; 최우선변제금은 근저당권 등 선순위 담보물권 설정
            당시의 소액임차인범위 및 최우선변제금액을 기준으로 합니다.
          </div>
        </div>
      </AddedDesc>
    </>
  )
}

const TableSubHeaderCustomHeight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ height }) => `height: ${height}%`};
  ${({ white }) =>
    white
      ? `
      background-color: ${theme.colors.base.white};
      border-right: none;
    `
      : `
      border-right: 1px solid ${theme.colors.gray[200]};
    `}
`

const StyledTableSubHeader = styled(TableSubHeader)`
  padding: 0;
  justify-content: flex-start;
`

const StlyedTableSubHeaderVertical = styled(TableSubHeader)`
  padding: 0;
  flex-direction: column;
  flex: 1;
  border-right: none;

  & > div {
    width: 100%;
    &:first-child {
      border-bottom: 1px solid ${theme.colors.gray[200]};
    }
  }
`

const Description = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 130%;
  font-weight: 500;
  color: ${theme.colors.tint.danger};
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  &:first-child {
    margin-right: 12px;
  }
`

const AddedDesc = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: -7px;
  margin-bottom: 6px;
  border-top: 1px solid ${theme.colors.base.white};
  border-right: 2px solid ${theme.colors.gray[300]};
  border-left: 2px solid ${theme.colors.gray[300]};
  border-bottom: 2px solid ${theme.colors.gray[300]};
  z-index: 1;

  ${({ full }) =>
    full &&
    `
    padding-top: 2px;
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 130%;
    padding-left: 8px;
  `}
`

const SignSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: inherit;
  justify-content: center;

  ${({ border }) =>
    border &&
    `
      line-height: 130%;
      padding-left: 8px;
      border-right: 1px solid ${theme.colors.gray[200]};
    `}
`

const StyledTableHeader = styled(TableHeader)`
  height: inherit;
  flex-shrink: 0;
`

const SignArea = styled.div`
  flex-grow: 1;
  text-align: right;
  padding-right: 8px;

  ${({ disabled }) =>
    disabled &&
    `
    color: ${theme.colors.gray[500]};
  `}
`

const DividedSection = styled.div`
  width: 70%;
  height: 100%;

  ${({ right }) =>
    right &&
    `
    width: 30%;
    border-left: 1px solid ${theme.colors.gray[200]};
    border-bottom: 1px solid ${theme.colors.gray[200]};
    align-content: center;
    padding-left: 8px;
  `}
`

const RadioFlexWrap = styled.div`
  display: flex;
  align-items: center;
`

const StyledRadioButtonsWrap = styled(RadioButtonsWrap)`
  flex-direction: column;
  gap: -4px;
`

const DividedSectionWrapper = styled.div`
  display: flex;
  position: relative;
  height: calc(100 / 3 * 2%);
`

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`

const EndPositionCheckbox = styled.div`
  align-content: center;
  height: 100%;
  padding-left: 8px;
  border-left: 1px solid ${theme.colors.gray[200]};
`

export default NoteDocsBasic4Residential
