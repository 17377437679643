import { Button } from 'components/design'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import { Block } from 'components/jmapnotev2/style/NotePaymentStyle'
import moment from 'moment'
import NotePaymentInfoMiniBlockAllInOneBoss from './NotePaymentInfoMiniBlockAllInOneBoss'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import TesterPaymentText from '../TesterPaymentText'

/**
 * 왼쪽 요금제 정보 블럭
 * 올인원 요금제 대표
 */
const NotePaymentInfoBlockAllInOneBoss = ({
  plan: {
    price,
    plan_name,
    is_free,
    has_billingkey,
    is_expected_cancel,
    card_info,
    has_error,
    next_payment_date,
    allinone_member_count,
    regdate,
    start_date,
    months,
  },
  hideButtons,
  onClickChangePayment,
  handleChangeCard,
  onClickMemberManage,
}) => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  return (
    <Wrapper>
      <Block>
        <BlockTitleAllInOne>
          <span>{plan_name}</span>
          <Price>
            {price}
            <span>{months == 6 ? '/6개월' : '/월'}</span>
          </Price>
        </BlockTitleAllInOne>

        <InfoWrap hideButtons={hideButtons}>
          <Info>
            <InfoTitle>이용기간</InfoTitle>
            <span>
              {moment(start_date, 'YYYYMMDDHHmmss').format(
                'YYYY.MM.DD',
              )}
              &nbsp;&#126;&nbsp;
              {moment(next_payment_date, 'YYYYMMDDHHmmss').format(
                'YYYY.MM.DD',
              )}
            </span>
          </Info>
          {!loginInfo?.other_groups.includes('tester') && (
            <>
              {!is_free && (
                <Info>
                  <InfoTitle>다음 결제 예정일</InfoTitle>
                  <span>
                    {next_payment_date ? (
                      moment(
                        next_payment_date,
                        'YYYYMMDDHHmmss',
                      ).format('YYYY.MM.DD')
                    ) : (
                      <>&ndash;</>
                    )}
                  </span>
                  <Tooltip title="요금제를 해지하시려면 프리 요금제로 변경해주세요.">
                    <CancelText onClick={onClickChangePayment}>
                      요금제 해지
                    </CancelText>
                  </Tooltip>
                  {is_expected_cancel && <FailMsg>해지 예정</FailMsg>}
                </Info>
              )}
              <Info>
                <InfoTitle>멤버</InfoTitle>
                <span>총 {allinone_member_count}명</span>
                <ButtonCard onClick={onClickMemberManage} color="blue">
                  멤버 관리
                </ButtonCard>
              </Info>
            </>
          )}
          <Info>
            <InfoTitle>자동결제</InfoTitle>
            {has_billingkey && (
              <>
                <span>
                  {card_info?.issuerName} {card_info?.number?.slice(-4)}
                </span>
                {loginInfo?.other_groups.includes('tester') && (
                  <ButtonCard onClick={handleChangeCard} color="blue">
                    카드 변경
                  </ButtonCard>
                )}
              </>
            )}
            {!has_billingkey &&
              (is_expected_cancel ? (
                '미등록'
              ) : (
                <ButtonCard onClick={onClickChangePayment} color="blue">
                  카드 등록
                </ButtonCard>
              ))}
            {has_error && <FailMsg>결제 실패</FailMsg>}
          </Info>
        </InfoWrap>
        {loginInfo?.other_groups.includes('tester') ? (
          <TesterPaymentText />
        ) : (
          <ButtonWrapper hideButtons={hideButtons}>
            <Button color="gray" onClick={onClickChangePayment}>
              {is_expected_cancel ? '다시 시작하기' : `요금제 변경하기`}
            </Button>
          </ButtonWrapper>
        )}
      </Block>
      {/* 요금제 블럭 아래 작은 정보 블럭들 */}
      <NotePaymentInfoMiniBlockAllInOneBoss />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ButtonCard = styled.button`
  border: none;
  background-color: white;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;

  ${({ color }) =>
    color === 'blue'
      ? `color: ${theme.colors.blue[400]};`
      : `color: ${theme.colors.tint.danger};`}
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  text-align: right;
  padding-top: 12px;
  border-top: 1px solid ${theme.colors.gray[200]};

  ${({ hideButtons }) => hideButtons && `display: none;`}

  & > button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  ${({ hideButtons }) => hideButtons && `margin-bottom: 0;`}
`

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
`

const InfoTitle = styled.div`
  width: 100px;
  color: ${theme.colors.gray[600]};
`

const FailMsg = styled.div`
  color: ${theme.colors.tint.danger};
`

const BlockTitleAllInOne = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px dashed ${theme.colors.base.black}1A;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;
`

const Price = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;

  span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    color: ${theme.colors.gray[600]};
  }
`

const CancelText = styled.div`
  color: ${theme.colors.gray[400]};
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: underline;
  cursor: pointer;
`

export default NotePaymentInfoBlockAllInOneBoss
