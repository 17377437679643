import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { dateFormat } from 'utils'
import { Help } from './SummaryLegalCourtInfoContainer'
import moment from 'moment'

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    font-size: 0.875rem;

    &:last-child {
      border-bottom: none;
    }
  }
`
const Icon = styled.div`
  flex: 0 0 auto;
  min-width: 20px;
  margin-right: 0.75rem;

  color: ${theme.colors.gray[700]};
  font-size: 1rem;
`
const Label = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 9rem;
  color: ${theme.colors.gray[600]};
  font-weight: 400;
  margin-right: 1.5rem;
`
const Value = styled.div`
  flex: 1;
  color: ${theme.colors.gray[900]};
  /* font-weight: 700; */
`

export const HelpIcon = styled.div`
  width: 5%;
  flex-direction: row-reverse;
  cursor: pointer;
  right: 0;
  display: flex;
  align-items: center;
  margin-left: 0.7rem;
  margin-top: 2.5px;
  font-size: 0.8rem;

  & > i {
    color: ${theme.colors.gray[400]};
  }
`



const SummaryLegalCourtInfo = ({
  court,
  setBuildingAreaHide,
  setFloorAreaHide,
}) => {

  const courtdata = court.zone || court.usearea
  return (
    <StyledList>
      {court && courtdata&& (
        <>
          <li>
            <Label>
              <Icon>
                <i className="fal fa-object-group"></i>
              </Icon>
              법정 건폐율
              <HelpIcon onClick={() => setBuildingAreaHide(false)}>
                <i className="fas fa-info-circle"></i>
              </HelpIcon>
            </Label>
            <Value>
              {courtdata[0] && `${Math.round(courtdata[0])}%`}
            </Value>
          </li>
          <li>
            <Label>
              <Icon>
                <i className="fal fa-layer-group"></i>
              </Icon>
              법정 용적률
              <HelpIcon onClick={() => setFloorAreaHide(false)}>
                <i className="fas fa-info-circle"></i>
              </HelpIcon>
            </Label>
            <Value>
              {courtdata[1] && `${Math.round(courtdata[1])}%`}
            </Value>
          </li>

          <li>
            <Label>
              <Icon>
              <i class="fal fa-calendar-alt"/>
              </Icon>
              최종 유지 일자
            </Label>
            <Value>
            {courtdata[2]&&moment(courtdata[2]).format('YYYY.MM.DD')}
            </Value>
          </li>
          {courtdata[2] && 
            <Help>
              <i className="fal fa-info-circle" />택지지구단위 기준 단위 입니다.
            </Help>
          }
        </>
      )}
    </StyledList>
  )
}

export default SummaryLegalCourtInfo
