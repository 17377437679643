import theme from 'lib/styles/theme'

export const propertyTypes = {
  residential: [
    '원룸',
    '1.5룸',
    '투룸',
    '쓰리룸+',
    // '빌라',
    '아파트',
    '오피스텔',
    '아파트 분양권',
    '오피스텔 분양권',
    '다세대주택',
  ],
  commercial: ['상가', '사무실'],
  building: ['상가건물', '단독주택', '다가구주택'],
  etc: ['지식산업센터', '고시원', '공장', '창고', '토지', '기타'],
}

/**
 * 선택된 매물 유형이 특정 유형과 일치하는지 검증,
 * 매물 유형이 기타일 경우 모든 것을 무시하고 true를 반환하여
 * 언제나 모든 항목이 보여지도록 하는 역할.
 * @param {String} selectedType
 * @param {String} propertyType
 * @returns {Boolean}
 */
export const validType = (selectedType, targetType) => {
  // 매물 유형이 기타일 경우 모든 항목을 사용할 것으로 간주, 전부 true를 반환한다.
  if (selectedType === '기타') return true

  return selectedType === targetType
}

/**
 * 값이 특정 카테고리에 속하는지 체크합니다.
 * @param {String} category
 * @param {String} value
 * @returns {Boolean}
 */
export const validCategory = (category, value) => {
  // 매물 유형이 기타일 경우 모든 항목을 사용할 것으로 간주, 전부 true를 반환한다.
  if (value === '기타') return true

  const findType = propertyTypes[category].find((e) => e === value)

  if (!findType) return false
  return true
}

export const getPropertyTypes = () => {
  let results = []

  for (const [key, values] of Object.entries(propertyTypes)) {
    results = [...results, ...values]
  }

  return results
}

const tagColors = {
  원룸: 'green',
  '1.5룸': 'green',
  투룸: 'green',
  '쓰리룸+': 'green',
  빌라: 'blue',
  단독: 'red',
  단독주택: 'red',
  다가구주택: 'blue',
  다가구: 'blue',
  아파트: 'cyan',
  '아파트 분양권': 'cyan',
  오피스텔: 'purple',
  '오피스텔 분양권': 'purple',
  다세대주택: 'blue',
  다세대: 'blue',
  상가: 'pink',
  '상가·사무실': 'pink',
  상가건물: 'pink',
  사무실: 'blue',
  공장: 'black',
  토지: 'orange',
  기타: 'black',
}

export const getTagColors = (type) => {
  if (!tagColors[type]) return tagColors['기타']
  return tagColors[type]
}

const icons = {
  단독: 'fa-house',
  다가구주택: 'fa-hotel',
  빌라: 'fa-hotel',
  다가구: 'fa-hotel',
  아파트: 'fa-building',
  오피스텔: 'fa-building',
  다세대주택: 'fa-hotel',
  다세대: 'fa-hotel',
  상가: 'fa-store',
  공장: 'fa-industry-alt',
  토지: 'fa-expand',
  기타: 'fa-house',
  원룸: 'fa-hotel',
  '1.5룸': 'fa-hotel',
  투룸: 'fa-hotel',
  '쓰리룸+': 'fa-hotel',
  분양권: 'ballot-check',
}

export const getIcons = (type) => {
  if (!icons[type]) return icons['기타']
  return icons[type]
}

export const colors = {
  red: '#e1585d',
  blue: '#405eff',
  cyan: '#40a4ff',
  purple: '#7c42f8',
  pink: '#ca5db3',
  black: theme.colors.gray[700],
  orange: '#e99b30',
  green: '#60C16F',
}

export const getColors = (type) => {
  const ColorName = getTagColors(type)
  return colors[ColorName]
}
