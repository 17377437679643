import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  CustomSingleCellInputWrapper,
  SheetButton,
} from 'components/jmapnotev2/style/NoteStyle'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import theme from 'lib/styles/theme'

const CustomGongbuDocCell = ({ data, gridfontsize }) => {
  const history = useHistory()

  const onClickWrite = useCallback(() => {
    if (!data?.channel_srl || !data.note_srl) return

    const { channel_srl, note_srl, address_srl } = data

    if (!channel_srl || !note_srl) {
      message.error('채널 또는 매물정보가 잘못되었습니다.')
      return
    }

    history.push({
      pathname: `/note/v2/notes/${channel_srl}/${
        address_srl || '0'
      }/${note_srl}`,
      state: {
        activeTab: 3,
      },
    })
  }, [data])

  const simplifyType = {
    등기부등본: '등기',
    토지이용계획원: '이용',
    '토지·건축물대장': '대장',
  }

  if (data.note_srl == '신규') return <></>

  return (
    <Wrapper>
      {data.deunggibu_counts ? (
        data.deunggibu_counts?.map((item, i) => (
          <Count onClick={onClickWrite} key={i}>
            {simplifyType[item.doc_type]}
            {item.count}
          </Count>
        ))
      ) : (
        <SheetButton
          disabled={data.status == 2}
          onClick={onClickWrite}
          gridfontsize={gridfontsize}
        >
          등기발급
        </SheetButton>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 2px);
  height: 100%;
`

const Count = styled.div`
  color: ${theme.colors.blue[400]};
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
  padding-right: 4px;
`

export default React.memo(CustomGongbuDocCell)
