import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
} from '../../form/NoteAdFormStyle'
import { Button, Modal } from 'components/design'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import { uploadFiles } from 'lib/api/notev2/adForm'
import useAxios from 'lib/hooks/useAxios'
import { message } from 'antd'
import NoteAdColumnVerificationFileItem from './NoteAdColumnVerificationFileItem'
import { options } from '../../options'
import NoteAdVerificationFile from './NoteAdVerificationFile'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 검증참고파일 referenceFileUrl
 */
const NoteAdColumnVerificationFile = ({ isDisabled: pIsDisabled }) => {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const { register, setValue, watch } = useFormContext()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { ref, ...rest } = register('verification.referenceFileUrl')
  const inputRef = useRef()

  const [fileList, setFileList] = useState([])
  const [onRequest, , data] = useAxios(uploadFiles)

  const adSrl = watch('ad_srl')
  const referenceFiles = watch('referenceFiles')
  const verificationTypeCode = watch('verification.type')

  const verificationType = useMemo(
    () => options['검증방식'][verificationTypeCode],
    [verificationTypeCode],
  )

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const isDisabled =
    pIsDisabled ||
    (adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll) //읽기 전용 여부

  useEffect(() => {
    if (!referenceFiles) return

    setFileList(referenceFiles)
  }, [referenceFiles])

  /**
   * 업로드 버튼 클릭 시 input type="file" 태그 클릭
   */
  const onClickUpload = () => {
    inputRef?.current?.click()
  }

  /**
   * 파일 업로드
   * @param {*} e
   */
  const onChangeInput = (e) => {
    if (!e.target.files?.[0]) {
      return
    }

    if (e.target.files?.[0]?.size > 1048576 * 10) {
      message.error(
        '파일 크기가 허용되는 한도 10MB를 초과하여 파일을 업로드할 수 없습니다.',
      )
      return
    }

    const uploadFiles = Array.from(e.target.files)

    uploadFiles.forEach((item, i) => {
      if (
        [
          'application/pdf',
          'image/png',
          'image/jpeg',
          'image/gif',
        ].includes(item.type)
      ) {
        //이미지 파일 처리
        onRequest({
          ad_srl: adSrl,
          fileToUpload: e.target.files[i],
          type: 'reference',
          plan_srl: planInfo.allinone_plan_srl,
        })
      } else {
        message.info('지원하지 않는 파일 형식입니다.')
        return
      }
    })
  }

  useEffect(() => {
    if (!data) return

    setFileList((prev) => [
      ...prev,
      ...[
        {
          ad_srl: data.ad_srl,
          url: data.url,
          thumbnail: data.thumbnail,
          source_filename: data.source_filename,
          mime_type: data.mime_type,
          uploaded_filename: data.uploaded_filename,
          file_srl: data.file_srl,
        },
      ],
    ])
  }, [data])

  /**
   * 위임장 모달 닫기
   */
  const onCloseWrite = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <ColumnSet>
        <ColumnTitle>검증참고파일</ColumnTitle>
        <ColumnVal gap={12}>
          {!isDisabled && (
            <ButtonWrapper>
              <NoteAdVerificationFile />
              <Button size="small" color="blue" onClick={onClickUpload}>
                <i className="far fa-paperclip"></i> 위임장 첨부하기
              </Button>
            </ButtonWrapper>
          )}
          {/* 파일 */}
          <FileWrapper>
            {fileList.map(
              (
                {
                  file_srl,
                  thumbnail,
                  source_filename,
                  mime_type,
                  uploaded_filename,
                },
                i,
              ) => {
                return (
                  <NoteAdColumnVerificationFileItem
                    key={i}
                    file_srl={file_srl}
                    thumbnail={thumbnail}
                    source_filename={source_filename}
                    mime_type={mime_type}
                    uploaded_filename={uploaded_filename}
                  />
                )
              },
            )}
            {!isDisabled && (
              <PlusImg onClick={onClickUpload}>
                <i className="far fa-plus"></i>
              </PlusImg>
            )}
          </FileWrapper>
          <InputFileWrap>
            <input
              multiple
              style={{ display: 'none' }}
              type="file"
              name="profile_image"
              accept="image/jpeg, image/gif, image/png, application/pdf"
              {...rest}
              onChange={onChangeInput}
              ref={(e) => {
                ref(e)
                inputRef.current = e // you can still assign to ref
              }}
            />
          </InputFileWrap>
          <Desc multiple>
            <li>
              <strong>개인매물</strong> : 분양계약서, 조합원계약서,
              지역주택조합가입계약서(+동호수확인서), 권리의무승계계약서
              <br />
              전원동의 서류(별도양식없음,사인인정) 등
            </li>
            {verificationType != '모바일확인' && (
              <li>
                <strong>법인매물</strong> : 명함(실물명함), 재직증명서
              </li>
            )}
            <li>
              <strong>외국인매물</strong> : 여권, 외국인등록증 등
              (매도인상의 이름과 소유자명이 크게 상이한 경우 첨부)
            </li>
            <li>
              <strong>위임장매물</strong> : 위임장 첨부시 날인 필수
            </li>
            <br />
            <li>
              <strong>
                사진등록 첨부 불가시 이메일로도 가능하며 반드시
                네이버매물번호가 기재 되어 있어야 합니다.
              </strong>
            </li>
            <li>
              <strong>검증서류 접수 이메일 주소</strong> :
              vpn020@confirms.kr
              <br />
              <span>
                서류 첨부 시 주민번호뒷자리는 검정색 사인펜으로
                지워주세요. 포스트잇 가림 시 글씨비침 (불일치빈번함)
              </span>
            </li>
            <li>
              <strong>
                법인매물, 위임장매물 신홍보로 등록은 가능하나 집주인,
                소유자 마크 제외
              </strong>
            </li>
          </Desc>
        </ColumnVal>
      </ColumnSet>
      <Modal
        footer={false}
        onCancel={onCloseWrite}
        visible={isModalVisible}
      >
        위임장 모달
      </Modal>
    </>
  )
}

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FileItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;

  img {
    width: 21px;
    height: 21px;
    object-fit: contain;
    border-radius: 2px;
  }

  i {
    cursor: pointer;
  }
`

const InputFileWrap = styled.div`
  display: flex;
  width: 100%;
`

const PlusImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 100px;
  background-color: ${theme.colors.gray[100]};
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  width: 100%;

  i {
    margin-right: 4px;
  }
`

const Desc = styled.ul`
  margin-top: 7px;
  list-style-type: disc;

  ${({ nomargin }) => nomargin && `margin: 0;`};

  li {
    line-height: 130%;
    font-size: 14px;
    color: ${theme.colors.gray[600]};

    &::before {
      content: '\f05a';
      ${({ multiple }) => multiple && `content: '\f00c'`};
      font-family: 'Font Awesome 5 Pro';
      margin-right: 4px;
      color: ${theme.colors.blue[400]};
    }

    span {
      color: ${theme.colors.gray[600]};
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;

      &::before {
        content: '\f05a';
        font-family: 'Font Awesome 5 Pro';
        color: ${theme.colors.blue[400]};
        margin-left: 18px;
        margin-right: 4px;
      }
    }
  }
`

const DownloadButton = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 8px;
  outline: none;
  appearance: none;
  transition: 0.2s ease;
  color: ${theme.colors.base.white};
  background-color: ${theme.colors.blue[400]};
  border-color: ${theme.colors.blue[400]};
  font-weight: 600;
  height: 30px;

  &:hover {
    color: ${theme.colors.base.white};
  }
`

export default NoteAdColumnVerificationFile
