import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const NoteDirectionSelector = ({ onClick, value, onKeyDown }) => {
  const [activeIndex, setActiveindex] = useState()

  const [data, setData] = useState([])

  const options = useSelector((state) => state.notev2.noteOptions)

  useEffect(() => {
    if (!options || Object.keys(options).length == 0) return

    const newData = [
      '북',
      '북서',
      '서',
      '남서',
      '남',
      '남동',
      '동',
      '북동',
    ].map((item) => {
      return { name: item, value: 1 }
    })

    setData(newData)

    const index = newData?.findIndex(({ name }) => name == value)

    setActiveindex(index)
  }, [options, value])

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = useCallback(
    ({ cx, cy, midAngle, innerRadius, outerRadius, name, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5
      const x = cx + radius * Math.cos(-midAngle * RADIAN)
      const y = cy + radius * Math.sin(-midAngle * RADIAN)

      return (
        <Text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          isactive={index == activeIndex}
        >
          {name}
        </Text>
      )
    },
    [activeIndex],
  )

  return (
    <Wrapper tabIndex={-1} onKeyDown={onKeyDown && onKeyDown}>
      <StyledResponsiveContainer
        width="100%"
        height="100%"
        tabIndex={-1}
      >
        <PieChart tabIndex={-1}>
          <Pie
            tabIndex={-1}
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            dataKey="value"
            border
            innerRadius={24}
            onClick={onClick}
            isAnimationActive={false}
            startAngle={-292}
            stroke={theme.colors.gray[200]}
          >
            {data?.map((entry, index) => (
              <Cell
                className={index === activeIndex ? 'active' : ''}
                tabIndex={0}
                style={{ outline: 'none' }}
                cursor="pointer"
                key={`cell-${index}`}
                fill={
                  index === activeIndex
                    ? theme.colors.blue[500]
                    : '#f4f6fb'
                }
                onKeyDown={(e) => {
                  if (e.key == 'Enter') {
                    onClick(entry, index)
                  }
                }}
              />
            ))}
          </Pie>
        </PieChart>
      </StyledResponsiveContainer>
    </Wrapper>
  )
}

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  scale: 0.85;
`

const Wrapper = styled.div`
  position: relative;
  width: 170px;
  height: 170px;
  margin-left: -18px;

  ${media.mediumS`
    width: 180px;
    height: 180px;
    margin-top: -16px;
  `}

  svg {
    &.recharts-surface {
      overflow: visible;
    }
  }
  path {
    &:focus-visible {
      fill: ${theme.colors.gray[300]};
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      &.active {
        fill: ${theme.colors.blue[500]};
      }
    }
  }
`

const Text = styled.text`
  z-index: 99999999;
  pointer-events: none;
  font-size: 15px;
  fill: ${theme.colors.gray[600]};
  ${({ isactive }) => isactive && `fill: ${theme.colors.base.white};`};
`

export default NoteDirectionSelector
