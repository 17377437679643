import useUserLoader from 'containers/base/hooks/useUserLoader'
import { useEffect } from 'react'

const NotePaymentContainer = ({ children }) => {
  const { onRequest: onRequestUserLoader } = useUserLoader(false)

  useEffect(() => {
    console.log('사용자 정보 갱신')
    //사용자 정보 갱신(플랜을 가져오기 위함)
    onRequestUserLoader()
  }, [])

  return children
}

export default NotePaymentContainer
