import { getListings } from 'lib/api/notev2/listApi'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const NoteListContainer = ({ children }) => {
  const [onRequest, , data] = useAxios(getListings)
  const [list, setList] = useState([])
  const { channelSrl, folderSrl, addressSrl } = useNoteMatch()
  const noteSearchQuery = useSelector(
    (state) => state.note.noteSearchQuery,
  )

  const searchFn = (q, pFolderSrl) => {
    onRequest({
      channel_srl: channelSrl,
      q: q || noteSearchQuery,
      folder_srl: pFolderSrl || folderSrl,
    })
  }

  useEffect(() => {
    searchFn()
  }, [])

  useUpdateEffect(() => {
    if (!folderSrl) return

    searchFn(null, folderSrl)
  }, [folderSrl])

  useEffect(() => {
    if (!data) return
    setList(data?.result || [])
  }, [data])

  return children({ list, searchFn, noteSearchQuery })
}

export default NoteListContainer
