import NonMemberImg1 from 'assets/images/features/non-member-banner1.png'
import NonMemberImg2 from 'assets/images/features/non-member-banner2.png'
import NonMemberImg3 from 'assets/images/features/non-member-banner3.png'
import NonMemberHoverImg1 from 'assets/images/features/non-member-banner1-icon.png'
import NonMemberHoverImg2 from 'assets/images/features/non-member-banner2-icon.png'
import NonMemberHoverImg3 from 'assets/images/features/non-member-banner3-icon.png'
import styled from 'styled-components'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'

import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/pagination/pagination.scss' // Pagination module

const HomeFeatureNonMemberSlide = () => {
  return (
    <StyledSwiper pagination={true} modules={[Pagination]}>
      <SwiperSlide>
        <ImageWrapper>
          <img src={NonMemberImg1} />
          <HoverImage src={NonMemberHoverImg1} type="1" />
        </ImageWrapper>
      </SwiperSlide>
      <SwiperSlide>
        <ImageWrapper>
          <img src={NonMemberImg2} />
          <HoverImage src={NonMemberHoverImg2} type="2" />
        </ImageWrapper>
      </SwiperSlide>
      <SwiperSlide>
        <ImageWrapper>
          <img src={NonMemberImg3} />
          <HoverImage src={NonMemberHoverImg3} type="3" />
        </ImageWrapper>
      </SwiperSlide>
    </StyledSwiper>
  )
}

const StyledSwiper = styled(Swiper)`
  margin-bottom: 16px;
`

const HoverImage = styled.img`
  position: absolute;
  height: auto;
  transition: scale 0.2s;

  ${({ type }) => {
    switch (type) {
      case '1':
        return `
          width: 125px;
          right: 20px;
          bottom: 40px;
        `
      case '2':
        return `
          width: 170px;
          right: 0;
          bottom: 35px;
        `
      case '3':
        return `
          width: 190px;
          right: 0;
          bottom: 20px;
        `
    }
  }}
`

const ImageWrapper = styled.div`
  position: relative;

  &:hover {
    ${HoverImage} {
      scale: 1.1;
    }
  }
  img {
    &:first-child {
      width: 100%;
      height: auto;
    }
  }
`

export default HomeFeatureNonMemberSlide
