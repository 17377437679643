import axios from 'axios'

/**
 * 계약서 목록 가져오기
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getContracts = async (
  { _rx_csrf_token, channel_srl, note_srl, filters, q },
  options,
) =>
  await axios.post(
    '/jmapnote/getContracts',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
      filters,
      q,
    },
    options,
  )

/**
 * 계약서 목록 1개 가져오기
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getContract = async (
  { _rx_csrf_token, contract_srl, channel_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/getContract',
    {
      _rx_csrf_token,
      channel_srl,
      contract_srl,
    },
    options,
  )

/**
 * 공부문서 목록 가져오기
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getGongbuList = async (
  { _rx_csrf_token, channel_srl, note_srl, status },
  options,
) =>
  await axios.post(
    '/jmapnote/api/doc/gongbu/list',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
      status,
    },
    options,
  )

/**
 * 등기부등본 발급 신청
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const requestDeunggibu = async (
  {
    _rx_csrf_token,
    channel_srl,
    note_srl,
    address,
    sub_type,
    valid_only,
    detail,
    unique_no,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/doc/deunggibu/request',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
      address,
      sub_type,
      valid_only,
      detail,
      unique_no,
    },
    options,
  )

/**
 * 토지이용계획원 발급 신청
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const requestLandUsePlan = async (
  {
    _rx_csrf_token,
    channel_srl,
    note_srl,
    address,
    address_id,
    sub_type,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/doc/landuseplan/request',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
      address,
      address_id,
      sub_type,
    },
    options,
  )

/**
 * 건축물대장 조회
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getBuildingDj = async (
  {
    _rx_csrf_token,
    channel_srl,
    note_srl,
    sub_type,
    address,
    address_id,
    dong,
    ho,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/api/doc/buildingdj/view',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
      sub_type,
      address,
      address_id,
      dong,
      ho,
    },
    options,
  )

/**
 * 계약서 작성폼에 디폴트값으로 채워줄 공부문서 가져오기
 * @param {*} param0
 * @param {*} options
 * @returns
 */
export const getBaseDataForContract = async (
  { _rx_csrf_token, channel_srl, note_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/doc/gongbu',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
    },
    options,
  )

/**
 * 계약서 수정
 * @params channel_srl, contract_srl, note_srl 등
 * @param {*} options
 * @returns
 */
export const editContract = async (
  { _rx_csrf_token, channel_srl, contract_srl, note_srl, form },
  options,
) =>
  await axios.post(
    '/jmapnote/editContract',
    {
      _rx_csrf_token,
      channel_srl,
      contract_srl,
      note_srl,
      ...form,
    },
    options,
  )

/**
 * 매물 종류, 타입에 따라 계약서 항목 가져오기
 * @params property_type, contract_type
 * @param {*} options
 * @returns
 */
export const getSpec = async (
  { _rx_csrf_token, property_type, contract_type },
  options,
) =>
  await axios.post(
    '/jmapnote/getSpec',
    {
      _rx_csrf_token,
      property_type,
      contract_type,
    },
    options,
  )

/**
 * 계약서 3뎁스의 토지, 건축물 대장 가져오기
 * @params channel_srl
 * @params contract_srl
 * @param {*} options
 * @returns
 */
export const getBuildingLandRegister = async (
  { _rx_csrf_token, channel_srl, contract_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/getBuildingLandRegister',
    {
      _rx_csrf_token,
      channel_srl,
      contract_srl,
    },
    options,
  )

/**
 * 공부문서 삭제
 * @params channel_srl, note_srl, doc_srl
 * @param {*} options
 * @returns
 */
export const deleteDoc = async (
  { _rx_csrf_token, channel_srl, note_srl, doc_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/doc/delete',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
      doc_srl,
    },
    options,
  )

/**
 * 계약서 삭제
 * @params contract_srl, channel_srl
 * @param {*} options
 * @returns
 */
export const deleteContract = async (
  { _rx_csrf_token, contract_srl, channel_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/deleteContract',
    {
      _rx_csrf_token,
      contract_srl,
      channel_srl,
    },
    options,
  )

/**
 * 계약서 상태변경
 * @params contract_srl, channel_srl, status
 * @returns
 */
export const updateContractStatus = async (
  { _rx_csrf_token, contract_srl, channel_srl, status },
  options,
) =>
  await axios.post(
    '/jmapnote/updateContractStatus',
    {
      _rx_csrf_token,
      contract_srl,
      channel_srl,
      status,
    },
    options,
  )

/**
 * 등기부등본 발급(신청,완료) 목록 반환
 * @params contract_srl, channel_srl, status
 * @returns
 */
export const getDeunggibuList = async (
  { _rx_csrf_token, channel_srl, note_srl, doc_types, status },
  options,
) =>
  await axios.post(
    '/jmapnote/getDeunggibuList',
    {
      _rx_csrf_token,
      channel_srl,
      note_srl,
      doc_types,
      status,
    },
    options,
  )

/**
 * 계약서 필터 목록
 */
export const getContractFilters = async (
  { _rx_csrf_token, channel_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/getContractFilters',
    {
      _rx_csrf_token,
      channel_srl,
    },
    options,
  )

/**
 * 계약서 사본생성
 */
export const copyContract = async (
  { _rx_csrf_token, channel_srl, contract_srl },
  options,
) =>
  await axios.post(
    '/jmapnote/copyContract',
    {
      _rx_csrf_token,
      channel_srl,
      contract_srl,
    },
    options,
  )
copyContract

/**
 * 중개보수 요율 불러오기 api
 */
export const getBrokerRate = async (
  {
    _rx_csrf_token,
    channel_srl,
    contract_srl,
    total_price,
    base_amount,
    rate,
    property_type,
    contract_type,
  },
  options,
) =>
  await axios.post(
    '/jmapnote/getBrokerRate',
    {
      _rx_csrf_token,
      channel_srl,
      contract_srl,
      total_price,
      base_amount,
      rate,
      property_type,
      contract_type,
    },
    options,
  )
