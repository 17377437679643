import { useSelector } from 'react-redux'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import useRoveFocus from 'lib/hooks/useRoveFocus'
import NoteDropSelectorItem from 'components/jmapnotev2/elements/NoteDropSelectorItem'

/**
 * 광고관리 그리드
 * 구분 selector
 */
const NoteContractTypeSelector = ({
  visible,
  setIsVisible,
  value,
  onChange,
  onClose,
  closeCallbackFn,
  mobileFullWidth,
}) => {
  const data = ['매매', '전세', '월세']
  const [focus, setCurrentFocus] = useRoveFocus(3)

  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    setIsVisible(false)
  })

  const onClickItem = useCallback((name) => {
    onChange(name)
    setIsVisible(false)
    closeCallbackFn && closeCallbackFn()
  }, [])

  return (
    <Wrapper
      ref={ref}
      visible={visible}
      mobilefullWidth={mobileFullWidth ? 'Y' : 'N'}
    >
      <List>
        {data.map((name, i) => {
          return (
            <NoteDropSelectorItem
              focus={focus === i}
              key={i}
              active={name == value}
              setCurrentFocus={setCurrentFocus}
              onClick={onClickItem}
              name={name}
              value={value}
              i={i}
              onClose={onClose}
              expand
            />
          )
        })}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  display: none;
  width: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 10px;
  padding: 12px;
  box-shadow: ${theme.shadow['card-2']};
  background-color: ${theme.colors.base.white};

  ${({ visible }) => visible && `display: block;`}

  ${media.mediumS`
    height: 250px;
    overflow: scroll;
    width: 230px;
    ${({ mobilefullWidth }) => mobilefullWidth == 'Y' && `width: 100%;`}
  `}
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export default React.memo(NoteContractTypeSelector)
