import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  removeClickedMarker,
  removeOpenedMarker,
  setClickedMarker,
  setManuallyCloseMarker,
} from 'modules/note'
import { numberWithCommas } from 'utils'
import { propertyTypes } from 'lib/utils/propertyType'
import priceFormat from 'lib/utils/priceFormat'
import theme from 'lib/styles/theme'
import MapContext from 'contexts/map'
import { rgba } from 'polished'
import styled, { keyframes } from 'styled-components'

const MapFavoriteMarkerMini = ({ item, onClick }) => {
  const { state } = useContext(MapContext)
  const { viewState, layer } = state
  const dispatch = useDispatch()

  const prevMarker = useRef(null)

  const openedMarker = useSelector((state) => state.note.openedMarker) //열림 마커
  const specificOpenedMarker = useSelector(
    (state) => state.note.specificOpenedMarker,
  )
  const [isMouseOverCloseButton, setIsMouseOverCloseButton] =
    useState(false)

  const converterArea = (item) => {
    // 유형이 없으면 반환
    if (!item?.property_type) {
      return null
    }
    // 결과값 선언
    let result
    // 토지 - 대지면적
    if (item.property_type === '토지') {
      result = item?.land_area
    }
    // 상가건물, 단독주택, 다가구주택, 공장, 기타 - 연면적
    if (
      propertyTypes.building.includes(item.property_type) ||
      ['공장', '기타'].includes(item.property_type)
    ) {
      result = item?.extra_vars?.total_floor_area
    }
    // 원룸, 1.5룸, 투룸, 쓰리룸+, 빌라, 아파트, 오피스텔, 상가, 사무실 - 전용면적
    if (
      propertyTypes.residential.includes(item.property_type) ||
      propertyTypes.commercial.includes(item.property_type)
    ) {
      result = item?.exclusive_area
    }
    // 값 없으면 반환
    if (!result) {
      return null
    }
    // 평제곱미터
    if (!state.isSquareMeter) {
      result = result / 3.305
    }

    return result
  }

  const getContractPrice = (item) => {
    switch (item.contract_type) {
      case '전세':
        return priceFormat(item?.jeonse_deposit * 10000)
      case '월세':
        return (
          <>
            {priceFormat(item?.deposit * 10000)}
            {item?.rent
              ? `/${priceFormat(item?.rent * 10000)}`
              : undefined}
          </>
        )
      case '매매':
        return priceFormat(item?.sale_price * 10000)
      case '단기임대':
        return priceFormat(item?.deposit * 10000)
      case '분양권':
        return priceFormat(item?.sale_price * 10000)
    }
  }

  const truncateName = (name) =>
    name.length > 8 ? `${name.substring(0, 8)}...` : name

  const onClickClose = (e) => {
    e.stopPropagation()
    dispatch(setManuallyCloseMarker(item.address_srl)) //수동으로 닫은 마커 세팅
    dispatch(removeOpenedMarker(item.address_srl)) //열린 마커에서 삭제
    dispatch(removeClickedMarker(item.address_srl))
  }

  const getContractTypeColor = (type) => {
    switch (type) {
      case '월세':
        return '#5c7cfa'
      case '전세':
        return '#fcc419'
      case '매매':
        return '#e1585d'
      case '단기임대':
        return '#9873fd'
      case '분양권':
        return '#e273fd'
      default:
        return theme.colors.base.white
    }
  }

  const isOpened = useMemo(
    () =>
      openedMarker && item && openedMarker.includes(item.address_srl),
    [item, openedMarker],
  )

  return (
    <>
      <StyledMapMarker
        hasText={viewState.level === 1}
        onClick={onClick}
        isCloseButtonHover={isMouseOverCloseButton}
        isskyview={layer && layer.includes('HYBRID')}
      >
        {/* 열림 마커 상태 확인 */}
        {isOpened ? (
          <SaleInfo>
            <Header>
              <div>
                <StyledContractTypeIcon
                  color={getContractTypeColor(item.contract_type || '')}
                />
                <Title>
                  {truncateName(
                    item?.building_name || item?.address_jibun || '',
                  )}{' '}
                  {converterArea(item) && converterArea(item) > 0
                    ? `(${numberWithCommas(
                        parseInt(converterArea(item)),
                      )}` + `${state.isSquareMeter ? '㎡' : '평'})`
                    : ''}
                </Title>
              </div>
            </Header>
            <Body>
              <span>
                {item?.floor_count ? `${item?.floor_count}층` : ''}
              </span>
              <Slash
                isVisible={
                  item?.floor_count &&
                  item?.floor_count > 0 &&
                  item?.contract_type
                }
              />
              <span>
                {item?.contract_type} {getContractPrice(item)}
              </span>
            </Body>
            <CloseButton
              isskyview={layer && layer.includes('HYBRID')}
              onMouseOver={() => setIsMouseOverCloseButton(true)}
              onMouseOut={() => setIsMouseOverCloseButton(false)}
              onClick={(e) => onClickClose(e)}
            >
              <i className="fa fa-times" />
            </CloseButton>
          </SaleInfo>
        ) : (
          <StyledContractTypeIcon
            color={getContractTypeColor(item.contract_type || '')}
          />
        )}
      </StyledMapMarker>
    </>
  )
}

const SaleInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  font-size: 13px;
  font-weight: 700;
`

const Body = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-left: 11px;
`

const Slash = styled.span`
  &:after {
    ${({ isVisible }) =>
      isVisible &&
      `
      content: '/';
      margin-left: 2px;
      margin-right: 2px;
    `}
  }
`

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6.4px;
  right: -6px;
  width: 16px;
  height: 16px;
  background: ${theme.colors.gray[700]};
  border-radius: 100%;
  color: ${theme.colors.gray[400]};

  ${({ isskyview }) =>
    isskyview &&
    `
      background: ${theme.colors.gray[900]};
      color: ${theme.colors.gray[200]};
      border: 1px solid ${theme.colors.gray[200]};
  `}

  &:hover {
    color: ${theme.colors.gray[900]} !important;
  }

  & > i {
    font-weight: 500;
  }
`

const StyledMapMarker = styled.div`
  cursor: pointer;
  pointer-events: all;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: ${({ hasText }) => (hasText ? '6px' : '6px 7px')};
  background-color: ${theme.colors.gray[700]};
  border: 1px solid ${theme.colors.gray[700]};
  border-radius: 16px;
  box-shadow: 0px 4px 16px ${rgba('black', 0.3)};
  color: ${theme.colors.base.white};
  font-size: 10px;
  text-align: left;
  transition: none;

  ${({ isskyview }) =>
    isskyview &&
    `
      background-color: ${theme.colors.gray[900]};
      border: 1px solid ${theme.colors.gray[50]};
      box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.3) ;
  `}

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${theme.colors.gray[700]} transparent transparent
      transparent;

    ${({ isskyview }) =>
      isskyview &&
      `
        border-color: ${theme.colors.gray[900]} transparent transparent transparent;
    `}

    &:hover {
      background-color: ${theme.colors.gray[800]};
    }
  }

  &:hover {
    background-color: ${theme.colors.gray[800]};
  }
`

const StyledContractTypeIcon = styled.div`
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: ${theme.colors.base.white};
  border-radius: 100%;

  &:not(:last-child) {
    margin-right: 4px;
  }

  background-color: ${({ color }) => color && color};
`

export default React.memo(MapFavoriteMarkerMini)
