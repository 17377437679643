import useNoteHasPlan from 'lib/hooks/notev2/useNoteHasPlan'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import theme, { media } from 'lib/styles/theme'
import { tab } from 'lib/utils/notev2/dongho'
import { useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

const tooltipStyle = {
  backgroundColor: theme.colors.gray[700],
  borderRadius: '4px',
  boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.03)`,
  zIndex: 9999,
  padding: '6px 10px',
  fontWeight: 400,
}

const NoteDonghoHeaderTab = ({ onClickTab }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const { hasPlan } = useNoteHasPlan()
  const activeTab = useSelector((state) => state.noteDongho.activeTab)
  const selectedDanji = useSelector(
    (state) => state.noteDongho.selectedDanji,
  )
  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value)
  }

  const isNoPaidDanji = selectedDanji.is_paid == 'N'
  return (
    <Wrapper>
      {Object.values(tab).map((text, i) => {
        const value = getKeyByValue(tab, text)
        const isPaidTab = ['3', '4'].includes(value)
        const isDisabled = isPaidTab && !hasPlan

        return (
          <TabItem
            key={i}
            active={activeTab == value}
            onClick={() => {
              if (isPaidTab) {
                if (!hasPlan || isNoPaidDanji) return
                onClickTab(value)
              } else {
                onClickTab(value)
              }
            }}
            disabled={isDisabled || (isNoPaidDanji && isPaidTab)}
          >
            {text}
            {(isDisabled || (isNoPaidDanji && isPaidTab)) && (
              <>
                <Tooltip
                  id="note-dongho-danji-tab-3"
                  opacity={1}
                  style={tooltipStyle}
                  place={isMobile || isTablet ? 'bottom' : 'top'}
                >
                  {isNoPaidDanji
                    ? `플러스 단지로 등록하면 ${text}를 볼 수 있어요`
                    : `유료회원이 되면 ${text}를 볼 수 있어요`}
                </Tooltip>
                <i
                  className="fas fa-info-circle"
                  data-tooltip-id="note-dongho-danji-tab-3"
                />
              </>
            )}
          </TabItem>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: 44px;
  align-self: flex-end;

  ${media.mediumM`
    width: 100%;
    margin-bottom: 12px;
  `}
`

const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 110px;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  color: ${theme.colors.gray[400]};
  background-color: ${theme.colors.gray[100]};
  border-right: 1px solid ${theme.colors.gray[150]};
  cursor: pointer;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  i {
    line-height: 100%;
  }

  ${({ active }) =>
    active &&
    `
    color: ${theme.colors.base.white};
    background-color: ${theme.colors.blue[400]};
    box-shadow: 0px 4px 12px 0px rgba(97, 141, 255, 0.40);
  `};

  ${({ disabled }) =>
    disabled &&
    `
    color: #DDE1E5;
  `};

  ${media.mediumM`
    border-radius: 0 !important;
    width: 25%;
  `}
`

export default NoteDonghoHeaderTab
