import theme from 'lib/styles/theme'
import { getPricePerHeight } from 'lib/utils/notev2/gridUtil'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

const CustomContractTypeCell = ({
  contract_type,
  isContractTypeShow,
  sale_price,
  jeonse_deposit,
  rent,
  deposit,
  note_srl,
  loan,
  property_type,
  presale_base_price,
  params,
}) => {
  const [hasSale, setHasSale] = useState(false)
  const [hasJeonse, setHasJeonse] = useState(false)
  const [hasRent, setHasRent] = useState(false)

  useEffect(() => {
    setHasDatas()
  }, [, contract_type, sale_price, jeonse_deposit, rent, deposit, loan])

  const isPresale = useMemo(
    () => ['아파트 분양권', '오피스텔 분양권'].includes(property_type),
    [property_type],
  )

  /**
   * 아무 이벤트 안일어난 초기상태에는 가지고있는것만 보여준다
   * @param {*} data
   */
  const setHasDatas = useCallback(() => {
    if (!contract_type) {
      setHasSale(false)
      setHasJeonse(false)
      setHasRent(false)
      return
    }
    setHasSale(
      contract_type.indexOf('매매') > -1 &&
        (isPresale
          ? presale_base_price && presale_base_price != 0
          : sale_price && sale_price != 0),
    )
    setHasJeonse(
      contract_type.indexOf('전세') > -1 &&
        jeonse_deposit &&
        jeonse_deposit != 0,
    )
    setHasRent(
      contract_type.indexOf('월세') > -1 &&
        ((rent && rent != 0) || (deposit && deposit != 0)),
    )
  }, [
    contract_type,
    sale_price,
    jeonse_deposit,
    rent,
    deposit,
    loan,
    presale_base_price,
    isPresale,
  ])

  const perHeight = useMemo(
    () => getPricePerHeight(contract_type, isContractTypeShow),
    [contract_type, isContractTypeShow],
  )

  /**
   * 각 구분을 클릭하면 해당하는 가격 셀에 focus 시킴
   */
  const focusOnPriceCellItem = (e, name) => {
    if (!isContractTypeShow && (!hasSale || !hasJeonse || !hasRent))
      //가격 셀이 확장 된 상태가 아니면 동작 안함. 단, 매매 전세 월세 3가지 값이 모두 있을 때는 동작함
      return

    const getSiblings = (t) =>
      [...t.parentElement.children].filter((e) => e != t)
    const parentSilblings = getSiblings(e.target.parentNode.parentNode)
    const currRowPriceCell = parentSilblings.find((elem) =>
      elem.classList.contains('custom-class-price-cell'),
    )

    const priceTarget = currRowPriceCell.querySelector(
      `div[name='${name}']`,
    )

    if (!priceTarget) return

    const evt = new Event('dblclick', {
      //해당 가격 영역 더블클릭 트리거, input foucs 잡히도록 함
      bubbles: true,
      cancelable: false,
    })

    priceTarget.dispatchEvent(evt)
  }

  if (note_srl == '신규') return <></>

  return (
    <Wrapper isVisible={isContractTypeShow || contract_type}>
      <ContractListItem
        onClick={(e) => focusOnPriceCellItem(e, 'sale_price')}
        perHeight={perHeight}
        isVisible={
          isPresale
            ? isContractTypeShow || (hasSale && presale_base_price)
            : isContractTypeShow || hasSale
        }
        border={isContractTypeShow || hasJeonse || hasRent}
      >
        매매
      </ContractListItem>
      <ContractListItem
        onClick={(e) => focusOnPriceCellItem(e, 'jeonse_deposit')}
        perHeight={perHeight}
        isVisible={isContractTypeShow || hasJeonse}
        border={isContractTypeShow || hasRent}
      >
        전세
      </ContractListItem>
      <ContractListItem
        onClick={(e) => focusOnPriceCellItem(e, 'deposit')}
        perHeight={perHeight}
        isVisible={isContractTypeShow || hasRent}
      >
        월세
      </ContractListItem>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  ${({ isVisible }) => isVisible && `opacity: 1;`}
`

const ContractListItem = styled.div`
  line-height: 100%;
  display: none;
  height: ${({ perHeight }) => perHeight};
  width: 100%;

  ${({ border }) =>
    border && `border-bottom: 1px solid ${theme.colors.gray[900]}1A;`};
  ${({ isVisible }) =>
    isVisible &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`

export default React.memo(CustomContractTypeCell)
