import { Tab, TabItem } from 'components/design'
import styled from 'styled-components'

const NoteDetailGongbuTab = ({ activeTab, onClickTab }) => {
  return (
    <StyledTab noContainer>
      <TabItem active={activeTab == 1} onClick={() => onClickTab(1)}>
        문서발급
      </TabItem>
      <TabItem active={activeTab == 2} onClick={() => onClickTab(2)}>
        건축물/토지대장
      </TabItem>
    </StyledTab>
  )
}

const StyledTab = styled(Tab)`
  margin-top: -20px;
  margin-bottom: 16px;
`

export default NoteDetailGongbuTab
