import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import { rgba } from 'polished'
import Scrollbars from 'react-custom-scrollbars-2'

const StyledDialog = styled.div`
  display: flex;
  flex-direction: column;

  background-color: white;
  border-radius: ${theme.borderRadius[2]};
  box-shadow: ${rgba('black', 0.05)} 0 2px 12px,
    ${rgba('black', 0.1)} 2px 8px 48px;

  ${({ width }) => `
    width: ${width}px;
  `}

  ${media.mediumS`
    width: 100%;
    max-height: 100vh;
    border-radius: ${theme.borderRadius[2]} ${theme.borderRadius[2]} 0 0;
    padding-bottom: env(safe-area-inset-bottom);
  `}

  ${({ bottomMenu }) =>
    bottomMenu &&
    media.mediumS`
    padding-bottom: calc(${theme.base.bottomMenuHeight} + env(safe-area-inset-bottom));
  `}
`

const StyleFooter = styled.div`
  padding: 0.75rem;
`

const StyledScrollbarsWrap = styled.div`
  flex: 1;
`

const DropdownDialog = ({
  children,
  header,
  footer,
  width = 180,
  minHeight = 200,
  maxHeight = 400,
  bottomMenu,
}) => {
  return (
    <StyledDialog width={width} bottomMenu={bottomMenu}>
      {header}

      <StyledScrollbarsWrap>
        <Scrollbars
          autoHide
          autoHeight
          autoHeightMin={minHeight}
          autoHeightMax={maxHeight}
        >
          {children}
        </Scrollbars>
      </StyledScrollbarsWrap>

      {footer && <StyleFooter>{footer}</StyleFooter>}
    </StyledDialog>
  )
}

export default DropdownDialog
