import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Row,
  Table,
  TableContent,
  TableSubHeader,
  TableHeader,
  TableTitle,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import styled from 'styled-components'
import ColorSelectBox from 'components/jmapnotev2/elements/ColorSelectBox'
import { useSelector } from 'react-redux'
import {
  getOnlyRemoveCommaNumber,
  getSelectedValue,
  getSimpleSelectboxOptions,
} from 'lib/utils/notev2/util'
import Spinner from 'components/common/Loading/Spinner'
import { numberWithCommas } from 'utils'
import theme from 'lib/styles/theme'

const NoteDocsContractDisplay = ({
  onUpdate,
  isBuildingGongbuLoading,
  indicationSpec,
}) => {
  const { watch } = useFormContext()
  const [landTypes, setLandTypes] = useState([])
  const [structures, setStructures] = useState([])
  //const [purposes, setPurposes] = useState([])
  const options = useSelector((state) => state.notev2.noteOptions)
  const property_type = watch('property_type')

  const address = watch('address')
  const land_type = watch('land_type')
  const daejigwon_type = watch('daejigwon_type')
  const daejigwon_ratio = watch('daejigwon_ratio')
  const struct_type = watch('struct_type')
  const land_area = watch('land_area')
  const building_property_type = watch('building_property_type')
  const total_floor_area = watch('total_floor_area')
  const exclusive_area = watch('exclusive_area')
  const rent_part = watch('rent_part')
  const rent_area = watch('rent_area')
  const ho = watch('ho')

  const indications = useMemo(
    () => indicationSpec.map((item) => item.name),
    [indicationSpec],
  )

  useEffect(() => {
    if (!options || Object.keys(options).length == 0) return

    const land_types = options.land_types
    const structure = options.structure
    // const purpose = options.purpose

    const landTypeList = getSimpleSelectboxOptions(land_types)
    const structureList = getSimpleSelectboxOptions(structure)
    //const purposeList = getSimpleSelectboxOptions(purpose)

    setLandTypes(landTypeList)
    setStructures(structureList)
    // setPurposes(purposeList)
  }, [options])

  return (
    <Wrapper>
      <TableTitle>1. 부동산의 표시</TableTitle>
      <Table>
        {isBuildingGongbuLoading && (
          <LoadingWrapper>
            <StyledSpinner />
            <Dimmer />
          </LoadingWrapper>
        )}
        <Row>
          <TableHeader>소재지</TableHeader>
          <TableContent basic noborderright>
            <ColorInput
              fullWidth
              type="text"
              defaultValue={address}
              onBlur={(e) => onUpdate('address', e.target.value)}
              minWidth={300}
            />
          </TableContent>
        </Row>
        <Row>
          <TableHeader>토지</TableHeader>
          <TableContent
            noborderright
            expandleft
            expandright
            spacebetween
          >
            <LandData>
              {indications.includes('land_type') && (
                <>
                  <TableSubHeader width={70}>지목</TableSubHeader>
                  <TableContent width={110} noborderright>
                    {landTypes && (
                      <ColorSelectBox
                        width={80}
                        options={landTypes}
                        value={getSelectedValue(land_type, landTypes)}
                        onChange={(e) => onUpdate('land_type', e.value)}
                        fullWidth
                      />
                    )}
                  </TableContent>
                </>
              )}
            </LandData>
            <AreaData>
              {indications.includes('daejigwon_type') && (
                <>
                  <TableSubHeader width={110}>
                    대지권 종류
                  </TableSubHeader>
                  <TableContent width={167}>
                    <ColorInput
                      type="text"
                      defaultValue={daejigwon_type}
                      onBlur={(e) =>
                        onUpdate('daejigwon_type', e.target.value)
                      }
                    />
                  </TableContent>
                </>
              )}
              {indications.includes('daejigwon_ratio') && (
                <>
                  <TableSubHeader width={110}>
                    대지권 비율
                  </TableSubHeader>
                  <TableContent width={160}>
                    <ColorInput
                      type="text"
                      key={daejigwon_ratio || ''}
                      defaultValue={daejigwon_ratio}
                      onBlur={(e) =>
                        onUpdate('daejigwon_ratio', e.target.value)
                      }
                    />
                  </TableContent>
                </>
              )}
              {indications.includes('land_area') && (
                <RightArea>
                  <TableSubHeader width={75}>토지면적</TableSubHeader>
                  <TableContent width={120} noborderright>
                    <ColorInput
                      type="text"
                      key={land_area || ''}
                      defaultValue={
                        land_area
                          ? numberWithCommas(
                              Math.round(parseFloat(land_area) * 1000) /
                                1000,
                            )
                          : ''
                      }
                      onBlur={(e) =>
                        onUpdate(
                          'land_area',
                          getOnlyRemoveCommaNumber(e.target.value),
                        )
                      }
                      append={'㎡'}
                    />
                  </TableContent>
                </RightArea>
              )}
            </AreaData>
          </TableContent>
        </Row>
        <Row
          noborderbottom={
            !(
              indications.includes('rent_part') ||
              indications.includes('rent_area')
            )
          }
        >
          <TableHeader>건물</TableHeader>
          <TableContent expandleft expandright spacebetween>
            <BuildingData>
              {indications.includes('building_structure') && (
                <>
                  <TableSubHeader width={70}>구조</TableSubHeader>
                  <TableContent width={220}>
                    {structures && (
                      <ColorSelectBox
                        options={structures}
                        value={getSelectedValue(
                          struct_type,
                          structures,
                        )}
                        onChange={(e) =>
                          onUpdate('struct_type', e.value)
                        }
                        fullWidth
                      />
                    )}
                  </TableContent>
                </>
              )}
              {indications.includes('building_purpose') && (
                <>
                  <TableSubHeader width={70}>용도</TableSubHeader>
                  <TableContent width={374} noborderright>
                    <ColorInput
                      type="text"
                      defaultValue={building_property_type}
                      key={building_property_type || ''}
                      onBlur={(e) =>
                        onUpdate(
                          'building_property_type',
                          e.target.value,
                        )
                      }
                      fullWidth
                    />
                  </TableContent>
                </>
              )}
            </BuildingData>
            <AreaData expand>
              {!['아파트', '오피스텔', '다세대주택'].includes(
                property_type,
              ) &&
                indications.includes('total_floor_area') && (
                  <RightArea>
                    <TableSubHeader width={75}>
                      {ho ? '전용면적' : '연면적'}
                    </TableSubHeader>
                    <TableContent width={130} noborderright>
                      <ColorInput
                        type="number"
                        key={total_floor_area || ''}
                        defaultValue={
                          total_floor_area && total_floor_area > 0
                            ? numberWithCommas(
                                Math.round(
                                  parseFloat(total_floor_area) * 1000,
                                ) / 1000,
                              )
                            : ''
                        }
                        onBlur={(e) =>
                          onUpdate(
                            'total_floor_area',
                            getOnlyRemoveCommaNumber(e.target.value),
                          )
                        }
                        append={'㎡'}
                      />
                    </TableContent>
                  </RightArea>
                )}
              {['아파트', '오피스텔', '다세대주택'].includes(
                property_type,
              ) &&
                indications.includes('exclusive_area') && (
                  <RightArea>
                    <TableSubHeader width={75}>
                      {ho ? '전용면적' : '연면적'}
                    </TableSubHeader>
                    <TableContent width={130} noborderright>
                      <ColorInput
                        type="number"
                        key={exclusive_area || ''}
                        defaultValue={
                          exclusive_area && exclusive_area > 0
                            ? numberWithCommas(
                                Math.round(
                                  parseFloat(exclusive_area) * 1000,
                                ) / 1000,
                              )
                            : ''
                        }
                        onBlur={(e) =>
                          onUpdate(
                            'exclusive_area',
                            getOnlyRemoveCommaNumber(e.target.value),
                          )
                        }
                        append={'㎡'}
                      />
                    </TableContent>
                  </RightArea>
                )}
            </AreaData>
          </TableContent>
        </Row>
        {(indications.includes('rent_part') ||
          indications.includes('rent_area')) && (
          <Row noborderbottom>
            <TableHeader>임대할부분</TableHeader>
            <TableContent noborderright expandright>
              {indications.includes('rent_part') && (
                <TableContent>
                  <ColorInput
                    fullWidth
                    type="text"
                    defaultValue={rent_part}
                    key={rent_part || ''}
                    onBlur={(e) =>
                      onUpdate('rent_part', e.target.value)
                    }
                  />
                </TableContent>
              )}
              {indications.includes('rent_area') && (
                <RightArea>
                  <TableSubHeader width={75}>
                    {ho ? '전용면적' : '연면적'}
                  </TableSubHeader>
                  <TableContent width={120} noborderright>
                    <ColorInput
                      type="number"
                      defaultValue={
                        rent_area > 0
                          ? numberWithCommas(
                              Math.round(rent_area * 1000) / 1000,
                            )
                          : ''
                      }
                      key={rent_area || ''}
                      onBlur={(e) =>
                        onUpdate(
                          'rent_area',
                          getOnlyRemoveCommaNumber(e.target.value),
                        )
                      }
                      append={'㎡'}
                      fullWidth
                    />
                  </TableContent>
                </RightArea>
              )}
            </TableContent>
          </Row>
        )}
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Dimmer = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 50;
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
`

const LandData = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const BuildingData = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100% - 195px);
`

const AreaData = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 1px solid ${theme.colors.gray[200]};

  ${({ expand }) => expand && `width: calc(100% - 180px)`};
`

const RightArea = styled.div`
  width: 195px;
  display: flex;
  align-items: center;
  height: 100%;
`

export default NoteDocsContractDisplay
