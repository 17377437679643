import { useCallback, useEffect } from 'react'

import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { getAddresses } from 'lib/api/note/addressApi'
import { useDispatch, useSelector } from 'react-redux'
import { setAddresses } from 'modules/note'
import useAxios from 'lib/hooks/useAxios'

export default function useAddresses() {
  const params = useNoteMatch()

  const noteSort = useSelector((state) => state.note.noteSort)
  const addresses = useSelector((state) => state.note.addresses)
  const dispatch = useDispatch()

  const [onRequest, loading, data, error, reset, source] =
    useAxios(getAddresses)

  const _getAddresses = useCallback(
    async (
      obj = {
        channelSrl: params?.channelSrl || null,
        folderSrl: params?.folderSrl || null,
        noteSort: noteSort,
      },
      cancelToken,
    ) => {
      if (!obj?.channelSrl || !obj?.folderSrl) {
        return
      }
      // await clear()
      // 요청 전 state를 초기화
      // console.log('초기화')
      await onRequest(obj, cancelToken)
    },
    [onRequest, params, noteSort],
  )

  const clear = async () => {
    await reset()
    await dispatch(setAddresses([]))
  }

  useEffect(() => {
    if (!data?.result?.addresses) return
    dispatch(setAddresses([...data.result.addresses]))
  }, [data])

  return {
    loading,
    _getAddresses,
    data,
    addresses,
    error,
    reset,
    _getAddressesSorce: source,
  }
}
