import styled from 'styled-components'
import theme from 'lib/styles/theme'

const Wrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  display: flex;
  justify-content: flex-end;
  width: 70px;
  height: 70px;
`
const StyledCloseButton = styled.button`
  cursor: pointer;
  flex: 0 0 auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  padding: 0;

  color: ${theme.colors.gray[600]};
  background-color: ${theme.colors.gray[100]};
  border-radius: 6px;

  transition: 0.2s ease;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    color: ${theme.colors.gray[700]};
    background-color: ${theme.colors.gray[200]};
  }
  &:active {
    color: ${theme.colors.gray[800]};
    background-color: ${theme.colors.gray[300]};
  }
`

const SummaryHeaderClose = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <StyledCloseButton>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </StyledCloseButton>
    </Wrapper>
  )
}

export default SummaryHeaderClose
