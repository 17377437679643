import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsEndSignModalVisible } from 'modules/inviteReward'
import NoteInviteJoinWelcomeModal from 'components/jmapnotev2/NoteInviteReward/NoteInviteJoinWelcomeModal'
import { useHistory, useLocation } from 'react-router-dom'

const RewardEndSignModalContainer = () => {
  const isEndSignModalVisible = useSelector(
    (state) => state.inviteReward.isEndSignModalVisible,
  )
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const onClose = () => {
    if (location?.pathname == '/inviteAuth') {
      history.replace('/home')
    }
    dispatch(setIsEndSignModalVisible(false))
  }

  return (
    <NoteInviteJoinWelcomeModal
      visible={isEndSignModalVisible}
      onClose={onClose}
    />
  )
}

export default React.memo(RewardEndSignModalContainer)
