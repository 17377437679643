import theme from 'lib/styles/theme'
import { useEffect, useState } from 'react'

/**
 * @name useIsMobile
 * @description window resize 이벤트를 감지하여 모바일 여부를
 * 반환합니다. (css media query와 1:1로 대응되는 js 기반 반응형 작업을 위해 사용됨)
 * @returns true / false
 */
export default function useIsSideMenuMaxHeight() {
  const [isSideMenuMaxHeight, setIsSideMenuMaxHeight] = useState(false)

  const handleResize = () => {
    if (window.innerHeight <= 1000) {
      setIsSideMenuMaxHeight(true)
      return
    }

    setIsSideMenuMaxHeight(false)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isSideMenuMaxHeight
}
