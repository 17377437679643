import {
  Row,
  StyledTable,
  TextArea,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const NoteDocsDetail9 = ({ onUpdate }) => {
  const { watch } = useFormContext()
  const d_other_rights = watch('d_other_rights')

  const d_descript_type = watch('d_descript_type')
  const getNumber = () => {
    if (d_descript_type == '주거용 건축물') {
      return '⑩'
    } else {
      return '⑧'
    }
  }
  return (
    <StyledTable sectionspace={d_descript_type == '토지'}>
      <Row height={50} rowspan={2} noborderbottom>
        <Header>
          {getNumber()} 실제 권리관계 또는 공시되지 않은 물건의 권리사항
        </Header>
        <Content>
          <TextArea
            defaultValue={d_other_rights}
            onBlur={(e) => onUpdate('d_other_rights', e.target.value)}
          />
        </Content>
      </Row>
    </StyledTable>
  )
}

const Header = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray[100]};
  margin-bottom: 10px;
  padding: 5px 10px;
`

const Content = styled.div`
  width: 100%;
  padding: 0 10px;
`

export default NoteDocsDetail9
