import { createContext, useState } from 'react';

const ExternalLayerContext = createContext();

export const ExternalLayerProvider = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const value = {
    state: {
      isScrolled,
    },
    actions: {
      setIsScrolled,
    },
  };

  return (
    <ExternalLayerContext.Provider value={value}>
      {children}
    </ExternalLayerContext.Provider>
  );
};

export default ExternalLayerContext;
