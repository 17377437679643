import { createContext, useState } from 'react';

const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [inverseState, setInverseState] = useState(false);
  const [sizeState, setSizeState] = useState('small');

  const value = {
    state: {
      inverseState,
      sizeState,
    },
    actions: {
      setInverseState,
      setSizeState,
    },
  };

  return (
    <TabContext.Provider value={value}>{children}</TabContext.Provider>
  );
};

export default TabContext;
