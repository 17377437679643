import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 해당층수
 */
const NoteAdColumnFloor = ({ required = false }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>해당 층수</ColumnTitle>
      <ColumnVal>
        <div>
          <InputText
            width={InputRemoveAppendWidth}
            type="text"
            {...register('article.floor1')}
            onBlur={(e) => {
              onUpdate('article.floor1', e.target.value)
            }}
            append={'층'}
          />
        </div>
        <ValidateWarn>{errors?.article?.floor1?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFloor
