import { Tab, TabItem } from 'components/design'
import useIsMobile from 'lib/hooks/useIsMobile'

const NoteDocsLayerTab = ({ activeTab, onChangeTab }) => {
  const isMobile = useIsMobile()
  return (
    <Tab noContainer>
      <TabItem
        expand
        active={activeTab === 1}
        onClick={() => onChangeTab(1)}
      >
        등기부등본
      </TabItem>
      <TabItem
        expand
        active={activeTab === 2}
        onClick={() => onChangeTab(2)}
      >
        {isMobile ? '대장' : '토지·건축물대장'}
      </TabItem>
      <TabItem
        expand
        active={activeTab === 3}
        onClick={() => onChangeTab(3)}
      >
        {isMobile ? '토지이용' : '토지이용계획원'}
      </TabItem>
      <TabItem
        expand
        active={activeTab === 4}
        onClick={() => onChangeTab(4)}
      >
        매물정보
      </TabItem>
      {/* <TabItem
        expand
        active={activeTab === 5}
        onClick={() => onChangeTab(5)}
      >
        특약불러오기
      </TabItem>
      <TabItem
        expand
        active={activeTab === 5}
        onClick={() => onChangeTab(5)}
      >
        공동 중개사
      </TabItem> */}
    </Tab>
  )
}

export default NoteDocsLayerTab
