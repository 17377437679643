import theme, { media } from 'lib/styles/theme'
import { setListSortParams } from 'modules/noteAd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const orderTypes = ['asc', 'desc']

const StylingHeaderComponent = ({ top, bottom, sortable, name }) => {
  const listSortParams = useSelector(
    (state) => state.noteAd.listSortParams,
  )
  const dispatch = useDispatch()

  const [orderType, setOrderType] = useState('')

  useEffect(() => {
    if (listSortParams?.index == name) {
      setOrderType(listSortParams?.type)
    } else {
      setOrderType('')
    }
  }, [listSortParams])

  const onClickSort = () => {
    let type
    const currIdx = orderTypes.findIndex((value) => value == orderType)

    if (currIdx == 1) {
      type = orderTypes[0]
      setOrderType(orderTypes[0])
    } else {
      type = orderTypes[currIdx + 1]
      setOrderType(orderTypes[currIdx + 1])
    }

    dispatch(
      setListSortParams({
        index: name,
        type: type,
      }),
    )
  }

  return (
    <StyledColumn onClick={onClickSort}>
      <HeaderWrapper name={name}>
        <TitleWrapper>
          {top}
          <br />
          <span>{bottom}</span>
        </TitleWrapper>
        {sortable &&
          (orderType ? (
            orderType == 'asc' ? (
              <i className="fas fa-caret-up"></i>
            ) : (
              <i className="fas fa-caret-down"></i>
            )
          ) : (
            <i className="fas fa-sort"></i>
          ))}
      </HeaderWrapper>
    </StyledColumn>
  )
}

const StyledColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: visible;
  width: 100%;
  cursor: pointer;

  span {
    color: ${theme.colors.gray[600]};
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
  }

  i {
    color: ${theme.colors.gray[400]};
    margin-left: 4px;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  ${media.mediumS`
  ${({ name }) => name == '주소' && `margin-left: 30px;`}
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const List = styled.div`
  display: none;
  flex-direction: column;
  position: fixed;
  z-index: 101;
  left: 65%;
  top: 0;
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['card-2']};
  font-size: 12px;
  ${({ visible }) => visible && `display: flex;`}
`

const ListItem = styled.div`
  padding: 8px 12px;

  &:hover {
    background-color: ${theme.colors.blue[50]};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray[200]};
  }
`

export default StylingHeaderComponent
