import theme, { media } from 'lib/styles/theme'
import styled, { css } from 'styled-components'

const NoteDashboardBlock = ({ title, children }) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {children}
    </Wrapper>
  )
}

const BlockStyle = css`
  position: relative;
  padding: 20px;
  border-radius: 12px;
  background-color: ${theme.colors.base.white};
  box-shadow: 0px 1px 2px 0px #edf0f7;

  ${media.mediumS`
    width: calc(100% + 40px);
    margin: 0 -20px;
    border-radius: 0;
    padding: 20px 16px;
  `}
`

const Wrapper = styled.div`
  ${BlockStyle}
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 24px;
`

export default NoteDashboardBlock
