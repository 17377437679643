import styled from 'styled-components'
import SideMenuLogout from './SideMenuLogout'
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg'

const HeaderWrapper = styled.div`
  background-color: white;
  top: 0;
  right: 0;
  width: 100%;
  padding: 1rem 1.25rem 1.5rem 1.25rem;
  height: 56px;
  position: sticky;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    font-size: 20px;
    font-weight: 300;
  }
  a,
  svg {
    cursor: pointer;
  }
`

const SideHeaderMenu = ({ onClose }) => {
  return (
    <HeaderWrapper>
      <ContentWrapper>
        <CloseIcon onClick={onClose} />
        <SideMenuLogout closeFn={onClose} />
      </ContentWrapper>
    </HeaderWrapper>
  )
}

export default SideHeaderMenu
