import { message } from 'antd'
import { Button } from 'components/design'
import { confirmVerificationSMS } from 'lib/api/auth/authApi'
import { sendVerificationSMS } from 'lib/api/notev2/account'
import useAxios from 'lib/hooks/useAxios'
import { useInput } from 'lib/hooks/useInput'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PasswordDesc, StyledInput, StyledSelect } from './SignupForm'

/**
 * 전화번호
 * @param tel 전화번호 state
 * @param setTel 전화번호 set state
 * @param isVerifiedTel 인증 코드가 유효한지 여부
 * @param setIsVerifiedTel 인증 코드가 유효한지 여부 set state
 * @returns
 */
const SignupTel = ({
  tel,
  setTel,
  isVerifiedTel,
  setIsVerifiedTel,
}) => {
  const [telConfirmCode, bindTelConfirmCode, resetConfirmCode] =
    useInput('')

  const [alreadySignMsg, setAlreadySignMsg] = useState('') //이미 등록된 전화번호 일 때 메시지
  const [isDoneSendSms, setIsDoneSendSms] = useState(false) //인증 코드 잘 보내졌는지 여부
  const [wrongCodeMsg, setWrongCodeMsg] = useState('') // 인증 코드 틀렸을 때 메시지
  const [onRequestSendSms, , dataSendSms, errorSms] = useAxios(
    sendVerificationSMS,
    { resetError: true },
  ) //인증 코드 전송
  const [onRequestConfirmSms, , dataConfirmSms, errorConfirmSms] =
    useAxios(confirmVerificationSMS, { resetError: true }) //인증코드 확인

  /**
   * 전화번호 변경
   * @param {*} e change event
   * @param {*} i index
   */
  const onChangeTel = (e, i) => {
    const newData = [...tel]
    newData[i] = e.target.value

    setTel(newData)
  }

  /**
   * 인증 코드 전송
   * @returns
   */
  const sendSms = () => {
    setIsDoneSendSms(false) //인증 코드 전송 완료 여부 false
    setWrongCodeMsg('') // 잘못된 인증 코드 메시지 reset
    resetConfirmCode('') //인증 코드 입력창 reset

    const emptyTelCnt = tel.filter((val) => !val)
    if (emptyTelCnt.length > 0) {
      message.warning('전화번호를 입력해 주세요.')
      return
    }

    //sms 보내기 api
    onRequestSendSms({
      phone_number: tel.join(''),
    })
  }

  /**
   * 인증 코드 잘 전송 되었을 때
   */
  useEffect(() => {
    if (!dataSendSms) return

    setAlreadySignMsg(false)
    message.info(dataSendSms?.message)
    setIsDoneSendSms(true)
  }, [dataSendSms])

  /**
   * 인증 코드 전송 오류 발생
   */
  useEffect(() => {
    if (!errorSms) return

    setAlreadySignMsg(errorSms)
  }, [errorSms])

  /**
   * 인증 코드 유효 체크 버튼
   */
  const confirmSms = () => {
    setIsVerifiedTel(false) // 인증 코드 유효 여부 false
    setWrongCodeMsg('') // 인증 코드 틀렸음 메시지 reset

    //인증 코드 확인 api
    onRequestConfirmSms({
      code: telConfirmCode,
    })
  }

  /**
   * 인증 코드 잘 확인 했음
   */
  useEffect(() => {
    if (!dataConfirmSms) return
    setIsVerifiedTel(true) // 인증 코드 유효 여부 true
    message.info('인증이 완료되었습니다.')
  }, [dataConfirmSms])

  /**
   * 인증 코드 에러났음
   */
  useEffect(() => {
    if (!errorConfirmSms) return

    setIsVerifiedTel(false) // 인증 코드 유효 여부
    setWrongCodeMsg(errorConfirmSms) //인증 코드 에러 메세지
  }, [errorConfirmSms])

  return (
    <>
      <TelWrapper>
        <StyledSelect onChange={(e) => onChangeTel(e, 0)}>
          <option value="010">010</option>
          <option value="011">011</option>
          <option value="016">016</option>
          <option value="017">017</option>
          <option value="018">018</option>
          <option value="019">019</option>
        </StyledSelect>
        <StyledInput tel="Y" onChange={(e) => onChangeTel(e, 1)} />
        <StyledInput tel="Y" onChange={(e) => onChangeTel(e, 2)} />
      </TelWrapper>
      {alreadySignMsg && (
        <PasswordDesc mb>{alreadySignMsg}</PasswordDesc>
      )}
      <Button
        color="primary"
        onClick={sendSms}
        expand
        disabled={isVerifiedTel}
        plain
      >
        인증코드 전송
      </Button>

      {!isVerifiedTel && isDoneSendSms && (
        <>
          <TelCodeWrapper>
            <StyledInput {...bindTelConfirmCode} type="text" />
            <Button plain color="primary" onClick={confirmSms} expand>
              인증완료
            </Button>
          </TelCodeWrapper>
          {wrongCodeMsg && (
            <PasswordDesc mb>{wrongCodeMsg}</PasswordDesc>
          )}
        </>
      )}
    </>
  )
}

const TelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 11px;
  margin-bottom: 10px;
`

const TelCodeWrapper = styled(TelWrapper)`
  margin-top: 6px;
  margin-bottom: 0;
`
export default SignupTel
