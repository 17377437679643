import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import RadioButton from '../../elements/RadioButton'
import { Checkbox } from 'components/design'
import { useFormContext } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import { useSelector } from 'react-redux'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 방향/방향기준
 */
const NoteAdColumnDirection = ({
  required = false,
  onlyDirection,
  noOption = false,
}) => {
  const adOptions = useSelector((state) => state.noteAd.adOptions)

  const { onUpdate } = useAdUpdate()
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const direction = watch('article.direction') || ''

  const [directionList, setDirectionList] = useState(null)
  const [baseList, setBaseList] = useState(null)

  useEffect(() => {
    if (!adOptions) return
    setDirectionList(adOptions.direction)
    setBaseList(adOptions.directionBase)
  }, [adOptions])

  const onChangeSelect = (e) => {
    onUpdate('article.direction', e.value)
  }

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>
        {onlyDirection ? '방향' : '방향/방향기준'}
      </ColumnTitle>
      <ColumnVal gap="0 12">
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: direction || '선택',
            value: direction,
          }}
          isSearchable
          options={getSimpleSelectboxOptions(directionList)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('article.direction')}
          onChange={onChangeSelect}
        />
        {!noOption && (
          <>
            {onlyDirection ? (
              <Checkbox checked={false}>주된 출입구 기준</Checkbox>
            ) : (
              <>
                {baseList &&
                  Object.entries(baseList).map(([key, value]) => {
                    return (
                      <React.Fragment key={value}>
                        <RadioButton
                          size="small"
                          fit
                          label={value}
                          value={key}
                          name="direction_base"
                          {...register('article.directionBase')}
                          onChange={(e) => {
                            onUpdate(
                              'article.directionBase',
                              e.target.value,
                            )
                          }}
                        />
                      </React.Fragment>
                    )
                  })}
              </>
            )}
          </>
        )}
        <ValidateWarn>
          <div>{errors?.article?.direction?.message}</div>
          <div>{errors?.article?.directionBase?.message}</div>
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnDirection
