import { useContext } from 'react'
import SummaryGraph from 'components/summary/SummaryGraph/SummaryGraph'
import { useMemo } from 'react'
import { Card } from 'components/design'
import MapContext from 'contexts/map'
import moment from 'moment'

const SummaryRealPriceGraph = ({ prices, landArea }) => {
  const { state } = useContext(MapContext)

  /**
   * @description 실거래가, 공시지가를 불러옴
   * - chage_zone은 용도 변경 이력입니다. 용도 이력을
   * 그래프에서 표현하는 기능이 추가적으로 개발되어야 합니다.
   */
  const { purchases, address_history } = prices.chart

  // 추정가를 불러옴
  const estimatePrice = prices.estimate_price

  /**
   * 차트 관련 데이터들을 차트에서 활용하기 쉽도록 변환해줍니다.
   * @param {array} address_history
   * @param {array} purchases
   * @param {object} state
   * @returns {array}
   */
  const getChartData = (address_history, purchases, state) => {
    /**
     * 년도별 실거래가, 공시지가, 추정가를 정리하기 위해
     * 모든 년도에 존재하는 address_history(공시지가)를 기준으로
     * 매핑해줍니다.
     */
    const mappedData = address_history.map((item, index) => {
      // 년도마다의 년도, 실거래가, 공시지가, 추정가를 객체에 담습니다.
      const result = {
        name: item.year,
        purchase: null,
        addressHistory: item?.total_price || null,
        addressHistoryUnit: item?.unit_price || null,
        estimatePrice: null,
        yet: item.yet,
      }
      if (index === address_history.length - 1) {
        // 해당 년도의 추정가를 isTotal, isSquareMeter 여부에 따라 포매팅하여 저장합니다.
        const getEstimatePrice = () => {
          const originEstimatePrice =
            estimatePrice?.estimate_price * 10000 || null

          if (!originEstimatePrice) return

          if (!state.isTotal) {
            const estimatePricePer =
              originEstimatePrice /
              (estimatePrice?.land_area || landArea || 1)

            return state.isSquareMeter
              ? estimatePricePer
              : estimatePricePer * 3.305
          }

          return originEstimatePrice
        }

        result.estimatePrice = Math.round(getEstimatePrice())
      }

      // isTotal이 false일 경우 면적당 공시지가를 계산하여 저장합니다.
      if (!state.isTotal) {
        result.addressHistory = state.isSquareMeter
          ? item.unit_price
          : item.unit_price * 3.305
      }

      /**
       * 해당 년도에 실거래 기록이 없는 경우 실거래가를 제외한
       * 데이터를 반환하고 끝냅니다.
       */
      if (!purchases || purchases === 0) return result

      /**
       * 로직 관리의 용이성을 위해 실거래가 계산 과정을 내부 함수로 분리합니다.
       * @param  {array} purchases
       * @returns {number, null}
       */
      const getPurchase = (purchases) => {
        // 년도가 일치하는 실거래 목록을 구합니다.
        const matchedPurchases = purchases.filter(
          (e) => e.contract_date === item.year,
        )
        // 지분 구분 세팅
        if (matchedPurchases[0]?.is_share === 1) {
          result.is_share = 1
        }
        // 면적당으로 계산해야할 경우 처리하여 반환합니다.
        const mappedPurchases = matchedPurchases.map((e) => {
          const parsedPrice = parseInt(e.price)
          if (!state.isTotal) {
            const pricePer =
              parsedPrice / (e.land_area || landArea || 1)
            return state.isSquareMeter ? pricePer : pricePer * 3.305
          }

          return parsedPrice
        })

        mappedPurchases.sort(function (a, b) {
          return a.contract_date - b.contract_date
        })
        // 최신 실거래가 반환
        if (mappedPurchases[0] > 0) return mappedPurchases[0]
        return null
      }
      result.purchase = getPurchase(purchases)
      result.data_date = item.data_date

      return result
    })

    return mappedData.sort((a, b) => {
      return moment(a.data_date) - moment(b.data_date)
    })
  }
  const computedChartData = useMemo(
    () => getChartData(address_history, purchases, state),
    [address_history, purchases, state],
  )

  return (
    <Card>
      {/* <SummaryGraphHeader /> */}

      {computedChartData.length > 0 && (
        <SummaryGraph
          data={computedChartData}
          isTotal={state.isTotal}
          isSquareMeter={state.isSquareMeter}
          landArea={landArea}
          prices={prices}
        />
      )}
    </Card>
  )
}

export default SummaryRealPriceGraph
