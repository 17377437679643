import { ReactComponent as Icon } from 'assets/icon/map-control/note.svg'
import { ReactComponent as IconActive } from 'assets/icon/map-control/note-active.svg'
import { LayerGroupItem } from '../MapControl/MapControlStyle'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setOpenFilterType } from 'modules/map'
import useNoteFilter from 'containers/note/hooks/useNoteFilter'
import NoteFilterContainer from 'containers/note/filter/NoteFilterContainer'
import MapFilterFloating from './MapFilterFloating'
import MapContext from 'contexts/map'
import { useContext, useEffect, useMemo, useState } from 'react'

/**
 * 필터 내 매물
 */
const MapFilterGroupNote = () => {
  const openFilterType = useSelector(
    (state) => state.map.openFilterType,
  )

  const {
    state: { layerState },
    actions: { setLayerState },
  } = useContext(MapContext)
  const dispatch = useDispatch()
  const noteFilterProps = useNoteFilter()
  const [isActive, setIsActive] = useState(false) //건물 레이어 active시 옆에 점 찍어주는 state

  /**
   * 오른쪽 float 내 매물 버튼(?) 누름 동작
   */
  const onClick = () => {
    dispatch(
      setOpenFilterType(openFilterType == 'note' ? null : 'note'),
    )
  }

  /**
   * 마커 on/off
   */
  const toggleLayer = () => {
    setLayerState((prev) => {
      return {
        ...prev,
        favorite: layerState['favorite'] ? false : true,
      }
    })
  }

  /**
   * 매물 전체 초기화
   */
  const handleReset = () => {
    noteFilterProps.handleReset()
  }

  /**
   * 레이어 on/off event
   */
  useEffect(() => {
    if (!layerState.favorite) {
      setIsActive(false)
    } else {
      setIsActive(true)
    }
  }, [layerState.favorite])

  const isButtonActive = useMemo(
    () => openFilterType == 'note',
    [openFilterType],
  )

  const month = new Date().getMonth() + 1
  const initValues = {
    filter_channel: '',
    status: 0,
    property_types: [],
    contract_types: ['단기임대'],
    sale_price: [0, 200],
    rent: [0, 200],
    area: [0, 70],
    move_date: [month, month + 11],
    floor_cnt: '',
    extra_options: [],
  }

  return (
    <>
      <LayerGroupItem
        dotactive={isActive}
        onClick={onClick}
        active={isButtonActive}
      >
        {isButtonActive ? <IconActive /> : <Icon />}
        <span>내매물</span>
      </LayerGroupItem>
      <MapFilterFloating
        title="내 매물"
        checkAllText="선택"
        isLayerOn={layerState?.favorite}
        toggleLayer={toggleLayer}
        visible={isButtonActive}
        render={<NoteFilterContainer {...noteFilterProps} />}
        onChangeSelectAll={handleReset}
        isSelectAll={
          JSON.stringify(noteFilterProps.initialState) ==
            JSON.stringify(noteFilterProps.filterState) ||
          JSON.stringify(initValues) ==
            JSON.stringify(noteFilterProps.filterState)
        }
      />
    </>
  )
}

export default MapFilterGroupNote
