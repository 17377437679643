import MapFilterGroupRealPrice from './MapFilterGroupRealPrice'
import MapFilterGroupNote from './MapFilterGroupNote'
import MapFilterGroupBuilding from './MapFilterGroupBuilding'
import MapFilterGroupLand from './MapFilterGroupLand'
import MapFilterRememberContainer from 'containers/map/MapFilterRememberContainer'
import MapFilterZoomNoti from 'containers/map/MapFilterZoomNoti'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useSelector } from 'react-redux'

/**
 * 실거래 쪽 필터 그룹
 * 실거래, 내매물, 건물, 토지
 */
const MapFilterGroup = () => {
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  return (
    <Wrapper>
      <MapFilterRememberContainer>
        <MapFilterGroupRealPrice />
        {loginInfo?.member_type === 'realestate' && (
          <MapFilterGroupNote />
        )}

        <MapFilterGroupBuilding />
        <MapFilterGroupLand />
      </MapFilterRememberContainer>
      <MapFilterZoomNoti />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: 4px;
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadow['float']};
  overflow: hidden;
`

export default MapFilterGroup
