import { notification } from 'antd'
import { modifyCalendar } from 'lib/api/note/calendarApi'
import useAxios from 'lib/hooks/useAxios'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import { useEffect } from 'react'
import NoteScheduleForm from './NoteScheduleForm'

const NoteScheduleModify = ({
  visible,
  setVisible,
  myContacts,
  defaultValue,
  calendarSrl,
  onSuccess,
}) => {
  const { channelSrl, folderSrl, addressSrl, noteSrl } = useNoteMatch()
  const [onRequest, loading, data, error] = useAxios(modifyCalendar)

  const onModify = (values) => {
    onRequest({
      ...values,
      channelSrl,
      folderSrl,
      addressSrl,
      noteSrl,
      calendarSrl,
      contact_srls: values.contact_srls,
    })
  }

  useEffect(() => {
    if (!data?.result) return
    onSuccess()
  }, [data])

  useEffect(() => {
    if (!error) return
    notification.error({ message: error })
  }, [error])

  return (
    <NoteScheduleForm
      title="일정 수정"
      visible={visible}
      myContacts={myContacts}
      defaultValue={defaultValue}
      setVisible={setVisible}
      onSubmit={onModify}
    />
  )
}

export default NoteScheduleModify
