import { Gallery } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import NoteDashboardPictureItem from './NoteDashboardPictureItem'
import styled from 'styled-components'

const NoteDashboardPictures = ({ data }) => {
  return (
    <Wrapper>
      <Gallery
        withCaption
        options={{
          history: false,
          barsSize: { top: 0, bottom: 0 },
          clickToCloseNonZoomable: false,
          closeOnScroll: false,
          closeOnVerticalDrag: false,
          captionEl: false,
          shareEl: false,
          closeEl: false,
          showHideAnimationType: 'fade',
          mainClass: 'pswp-note-dashboard',
        }}
      >
        <ImageWrapper>
          {Array.isArray(data) &&
            data.map((item, i) => (
              <NoteDashboardPictureItem item={item} key={i} />
            ))}
        </ImageWrapper>
      </Gallery>
    </Wrapper>
  )
}

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: -1px;
  gap: 2px;
  max-width: 497px;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 184px;
`

export default NoteDashboardPictures
