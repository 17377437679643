import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'

/**
 * 요금제 유의사항
 */
const NotePaymentNotice = () => {
  return (
    <Precautions>
      <PrecautionsTitle>
        <i className="fas fa-info-circle"></i> 유의사항(필독)
      </PrecautionsTitle>
      <PrecautionsList>
        <li>
          베이직 이용권 결제 시, 첫 달 무료 혜택이 제공되며 부동산 회원
          대상입니다.
        </li>
        <li>
          다음 달 정기 결제일부터 등록하신 카드로 정상 금액이
          결제됩니다.
        </li>
        <li>
          <strong>
            정기 결제를 원치 않으시면 ‘매물노트 &#62; 결제관리’ 메뉴에서
            프리 요금제로 변경하시면 언제든지 정기 결제 해지가
            가능합니다.
          </strong>
        </li>
        <li>
          첫 달 무료 혜택을 사용한 회원은 동일 혹은 유사한 혜택을 받을
          수 없습니다.
        </li>
        <li>
          유효기간 내 사용하지 않은 등기부등본 발급권은 이월 및 환불되지
          않습니다.
        </li>
      </PrecautionsList>
    </Precautions>
  )
}

const Precautions = styled.div`
  border-radius: 12px;
  background-color: ${theme.colors.gray[50]};
  margin-top: 120px;
  padding: 20px;

  ${media.mediumS`
    margin-top: 80px;
  `}
`

const PrecautionsTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.tint.danger};
  font-weight: 600;
  font-size: 18px;

  i {
    margin-right: 3px;
  }
`

const PrecautionsList = styled.ul`
  margin-top: 12px;
  padding-left: 20px;
  li {
    list-style-type: disc;
    font-weight: 400;
    line-height: 160%;
    font-size: 16px;
    word-break: keep-all;

    &::marker {
      font-size: 14px;
    }
  }
`
export default NotePaymentNotice
