import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NoteInviteRewardPayInfo from './NoteInviteRewardPayInfo'
import NoteInviteRewardList from './NoteInviteRewardList'
import NoteInviteRewardShare from './NoteInviteRewardShare'
import NoteInviteRewardNoti from './NoteInviteRewardNoti'

const NoteInviteReward = ({
  referCode,
  isLoading,
  invitedList,
  rewardedCount,
  expectedRewardedCount,
}) => {
  return (
    <>
      <Title>
        친구 초대하고
        <br />
        <span>등기열람권 4개 받자</span>
      </Title>
      <Desc>
        초대받은 친구가 유료 플랜을 구입하면 친구와 회원님 모두
        등기열람권 4개를 드려요!
      </Desc>
      <NoteInviteRewardShare
        isLoading={isLoading}
        referCode={referCode}
      />
      <NoteInviteRewardPayInfo
        rewardedCount={rewardedCount}
        expectedRewardedCount={expectedRewardedCount}
      />
      <NoteInviteRewardList invitedList={invitedList} />
      <NoteInviteRewardNoti minHeight={140} />
    </>
  )
}

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: ${theme.colors.base.white};
  line-height: 120%;

  span {
    color: #73ff76;
    font-weight: 700;
  }
`

const Desc = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${theme.colors.base.white};
  word-break: keep-all;
  margin-top: 28px;
  margin-bottom: 17px;
`

export default NoteInviteReward
