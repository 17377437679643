import { useSelector } from 'react-redux'
import SummaryBuildingArchitect from './SummaryBuildingArchitect'
import AptUnitInfo from './ApartUnitInfo'
import {
  CardHeader,
  Card,
  CardBody,
  CardTitle,
} from 'components/design'
import SummaryBuildingNotApt from './SummaryBuildingNotApt'
import { forwardRef } from 'react'
import SummaryBuildingLandInfo from './notApt/SummaryBuildingLandInfo'
import SummaryBuildingNohu from './SummaryBuildingNohu'

/**
 * 건물 정보
 *
 * * 렌더링 여부
 * * 공동주택: O
 * * 공동주택 아님 : O
 */
const SummaryBuilding = ({}, ref) => {
  const { building } = useSelector((state) => state.summary.summaryInfo)
  const isApt = useSelector((state) => state.summary.isApt)

  const { basic: basicTabRef } = ref

  return (
    building?.result &&
    Object.keys(building.result).length > 0 && (
      <div ref={basicTabRef}>
        <Card>
          <CardHeader>
            <CardTitle>건물 정보</CardTitle>
          </CardHeader>
          <CardBody>
            {building?.result?.length > 0 &&
              (isApt ? (
                <>
                  <AptUnitInfo />
                  <SummaryBuildingLandInfo />
                </>
              ) : (
                <SummaryBuildingNotApt />
              ))}
            <SummaryBuildingArchitect building={building} />

            {!isApt && <SummaryBuildingNohu />}
          </CardBody>
        </Card>
      </div>
    )
  )
}

export default forwardRef(SummaryBuilding)
