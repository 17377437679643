import { useState } from 'react'
import SummaryAptRP from 'components/summary/SummaryApt/SummaryAptRP'
import { useMemo } from 'react'
import priceFormat from 'lib/utils/priceFormat'

/**
 * @name SummaryAptRPTableContainer
 * @description 최근 실거래 표 - 아파트 실거래가 목록을 출력합니다.
 * @param {array} data
 * @param {boolean} isCommercial
 */
const SummaryAptRPTableContainer = ({
  data = [],
  isCommercial = false,
  selectedArea,
  addressId,
}) => {
  const [currentType, setCurrentType] = useState('전체')
  const [currentPage, setCurrentPage] = useState(1)

  const limit = 5

  const handleChangeCurrentTab = (type) => {
    setCurrentType(type)
    setCurrentPage(1) // tab을 변경할 때마다 페이지네이션 초기화
  }

  const getPRData = (data, currentType, currentPage) => {
    const mappedData = data.map((e, i) => {
      /**
       * contract_date 자체는 Date()나 moment.js가 읽을 수 없는
       * 규격이기 때문에 year, moth, day를 기준으로 쪼개서 읽을 수 있는 값으로 만들어 줍니다.
       */
      const year = e.contract_date.slice(0, 4)
      const month = e.contract_date.slice(4, 6)
      const day = e.contract_date.slice(6, 8)

      // 활용하기 유용한 형태로 변환합니다.
      const value = {
        key: i,
        date: `${year}.${month}.${day}`,
        type: e.type,
        area_type: e.exclusive_area_type,
        floor: e.floor_number ? e.floor_number + '층' : '-',
        area: e.area,
        contract_type: e.contract_type,
        renewal_right_used: e.renewal_right_used,
        canceled_date: e.canceled_date,
        price_id: e.price_id,
      }
      // priceFormat()을 사용하여 포맷팅한 가격을 추가합니다.
      if (e.type === '매매') {
        value.price = priceFormat(e.price)
      } else if (e.type === '전세') {
        value.price = priceFormat(e.deposit)
      } else if (e.type === '월세') {
        value.price = `${priceFormat(e.deposit)}/${priceFormat(e.rent)}`
      }
      // string인 면적을 number 타입으로 변환하여 추가합니다.
      if (e.exclusive_area) {
        value.area = parseFloat(e.exclusive_area)
      }

      return value
    })

    const filteredData = mappedData.filter((e) => {
      if (currentType === '전체') return true
      return e.type === currentType
    })

    // 최근 순으로 정렬 (lodash orderBy 등을 사용해도 됩니다만, 참조에 주의하세요.)
    filteredData.sort(function (a, b) {
      return a.date < b.date ? 1 : a.date > b.date ? -1 : 0
    })

    return filteredData
  }

  const computedPRData = useMemo(
    () => getPRData(data, currentType, currentPage),
    [data, currentType, currentPage],
  )

  // limit 만큼만 목록을 출력하도록 합니다.
  const getLimitedData = (data, currentPage) => {
    return data.slice(
      currentPage * 5 - 5 < 0 ? 0 : currentPage * 5 - 5,
      currentPage === 0 ? limit : currentPage * limit,
    )
  }

  const limitedData = useMemo(
    () => getLimitedData(computedPRData, currentPage),
    [computedPRData, currentPage],
  )

  return (
    <SummaryAptRP
      currentType={currentType}
      onChangeCurrentTab={handleChangeCurrentTab}
      originData={computedPRData}
      data={limitedData}
      isCommercial={isCommercial}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      selectedArea={selectedArea}
      addressId={addressId}
    />
  )
}

export default SummaryAptRPTableContainer
