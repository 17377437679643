import { createSlice } from '@reduxjs/toolkit'
import { contractFilterItem, propertyFilterItem } from 'lib/utils/bds'

/**
 * 필터 기본값
 */
export const initBdsFilter = {
  contractType: contractFilterItem, //거래유형
  propertyType: propertyFilterItem, //매물유형
  price: {
    sale_price: [0, 1000000], //0~100억
    deposit: [0, 100000], //0~10억
    rent: [0, 200], //0~200만
  }, //가격
  area: [], //면적
}

const initialState = {
  selectedFilter: initBdsFilter, //필터
  searchAptFilter: null, //검색 > 아파트 선택시 세팅
  selectedAdSrl: null, //광고 목록에서 선택된 광고
  list: [], //광고 목록
  selectedRealtorInfo: null, //값이 있으면 중개사 모아보기 모드
  filterSearchFlag: false, //필터를 선택했을 때 변경되는 flag
  markerClickFilter: {
    //마커 클릭시 세팅되는 정보
    address_id: null,
    level: null,
    centerpoint: null,
  },
  alreadyCenter: false, //로딩 초기 center point 이동이 완료되었는지 여부
}

export const bdsSlice = createSlice({
  name: 'bds',
  initialState,
  reducers: {
    resetContractType(state, actions) {
      state.selectedFilter.contractType = initBdsFilter.contractType
    },
    setContractType(state, actions) {
      state.selectedFilter.contractType = actions.payload
      //setLocal(state.selectedFilter, 'contractType', actions.payload)
    },
    setPropertyType(state, actions) {
      const updateVal = {
        ...state.selectedFilter.propertyType,
        ...actions.payload,
      }
      state.selectedFilter.propertyType = updateVal
      //   setLocal(state.selectedFilter, 'propertyType', updateVal)
    },
    setPrice(state, actions) {
      state.selectedFilter.price = actions.payload
      //   setLocal(state.selectedFilter, 'price', actions.payload)
    },
    setArea(state, actions) {
      state.selectedFilter.area = actions.payload
      //   setLocal(state.selectedFilter, 'area', actions.payload)
    },
    setSelectedFilter(state, actions) {
      state.selectedFilter = actions.payload
    },
    setList(state, actions) {
      state.list = actions.payload
    },
    setRealtorInfo(state, actions) {
      state.selectedRealtorInfo = actions.payload
    },
    setSalePriceMin(state, actions) {
      const copy = [...state.selectedFilter.price.sale_price]
      copy[0] = actions.payload
      state.selectedFilter.price.sale_price = copy
    },
    setSalePriceMax(state, actions) {
      const copy = [...state.selectedFilter.price.sale_price]
      copy[1] = actions.payload
      state.selectedFilter.price.sale_price = copy
    },
    setSalePrice(state, actions) {
      state.selectedFilter.price.sale_price = actions.payload
    },
    setDepositPrice(state, actions) {
      state.selectedFilter.price.deposit = actions.payload
    },
    setRentPrice(state, actions) {
      state.selectedFilter.price.rent = actions.payload
    },
    setSearchAptFilter(state, actions) {
      state.searchAptFilter = actions.payload
    },
    setFilterSearchFlag(state, actions) {
      state.filterSearchFlag = actions.payload
    },
    resetFilter(state, actions) {
      state.selectedFilter = initBdsFilter
    },
    resetMarkerClickFilter(state, actions) {
      state.markerClickFilter = {
        address_id: null,
        level: null,
      }
    },
    setMarkerClickFilter(state, actions) {
      state.markerClickFilter = actions.payload
    },
    setSelectedAdSrl(state, actions) {
      state.selectedAdSrl = actions.payload
    },
    setAlreadyCenter(state, actions) {
      state.alreadyCenter = actions.payload
    },
  },
})

export const {
  resetContractType,
  setContractType,
  setPropertyType,
  setPrice,
  setArea,
  setSelectedFilter,
  setList,
  setRealtorInfo,
  setSalePriceMin,
  setSalePriceMax,
  setSalePrice,
  setDepositPrice,
  setRentPrice,
  setSearchAptFilter,
  setFilterSearchFlag,
  resetFilter,
  resetMarkerClickFilter,
  setMarkerClickFilter,
  setSelectedAdSrl,
  setAlreadyCenter,
} = bdsSlice.actions

export default bdsSlice.reducer
