import styled from 'styled-components'

const StyledCardTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`

const CardTitle = ({ children, as, className }) => {
  return (
    <StyledCardTitle as={as} className={className}>
      {children}
    </StyledCardTitle>
  )
}

export default CardTitle
