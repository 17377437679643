import NotePaymentPayFail from 'components/jmapnotev2/NotePayment/NotePaymentPayFail'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const NotePaymentFailContainer = () => {
  const isClosedStorage = localStorage.getItem('paymentErrMsgClosed')

  const planInfo = useSelector((state) => state.auth.planInfo)

  const matchUrl = useRouteMatch({
    path: ['/note/v2/payment'],
  })

  return (
    <>
      {!matchUrl?.isExact &&
        !JSON.parse(isClosedStorage) &&
        planInfo?.error && <NotePaymentPayFail fixed />}
    </>
  )
}

export default NotePaymentFailContainer
