import styled from 'styled-components'
import { MaxWidthWrapper } from '../style/NoteStyle'
import theme, { media } from 'lib/styles/theme'
import NoteMainWrapper from '../style/NoteMainWrapper'
import Scrollbars from 'react-custom-scrollbars-2'
import NoteAdForm from './form/NoteAdForm'
import { FormProvider } from 'react-hook-form'
import NoteAdSubmitButton from './NoteAdSubmitButton'
import NoteAdValidateContainer from 'containers/notev2/NoteAdValidateContainer'
import NoteAdEditContainer from 'containers/notev2/NoteAdEditContainer'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'
import useIsMobile from 'lib/hooks/useIsMobile'
import NoteAdProcessing from './NoteAdProcessing'
import NoteAdFormTitle from './form/NoteAdFormTitle'
import NoteAdFormStatus from './form/NoteAdFormStatus'
import NoteAdDescBottom from './columns/NoteAdDescBottom'
import { useRef } from 'react'

/**
 * 네이버 매물 폼
 * (연동 X, 확인, 수정용)
 */
const NoteAdEdit = () => {
  const ref = useRef(null)
  const isMobile = useIsMobile()

  return (
    <>
      <NoteMainWrapper gray single singleM>
        <Scrollbars ref={ref} autoHide>
          <StyledMaxWidthWrapper>
            <NoteAdEditContainer>
              {({
                isProcessing,
                methods,
                onSubmit,
                validationError,
                formRef,
                loading,
                paymentModal,
              }) => (
                <FormProvider {...methods}>
                  <form onSubmit={onSubmit} ref={formRef}>
                    <NoteAdFormTitle ref={ref} />
                    <NoteAdFormStatus />
                    <NoteAdValidateContainer
                      validationError={validationError}
                    >
                      {() => (
                        <>
                          {loading && <FullScreenLoading />}
                          <NoteAdForm />
                          <NoteAdDescBottom />
                          <NoteAdSubmitButton />
                        </>
                      )}
                    </NoteAdValidateContainer>
                    {paymentModal}
                    {isProcessing && <NoteAdProcessing />}
                  </form>
                </FormProvider>
              )}
            </NoteAdEditContainer>
          </StyledMaxWidthWrapper>
        </Scrollbars>
      </NoteMainWrapper>
    </>
  )
}

const Title = styled.div`
  margin-bottom: 28px;
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  background-color: ${theme.colors.base.white};
  padding: 24px 40px 100px 40px;
  margin: 0 auto;
  min-height: 100%;

  ${media.smallPc`
    width: 100%;
    padding: 24px 20px 100px 20px;
  `}

  ${media.mediumS`
    width: 100%;
    padding: 20px;
  `}
`
export default NoteAdEdit
