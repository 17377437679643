import { getAdListings } from 'lib/api/notev2/adList'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { assign, map, mapKeys } from 'lodash'
import { useInput } from 'lib/hooks/useInput'
import {
  setListSortParams,
  setShowIntroduceService,
} from 'modules/noteAd'
import { useDispatch } from 'react-redux'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import useQueryString from 'lib/hooks/useQueryString'

//맨 위 신규 매물 주소 입력 row 추가
const newItem = [
  {
    row_key: 'addNewLine',
    ad_srl: '신규',
  },
]
/**
 * 광고 목록 container
 * @param {*} param0
 * @returns
 */
const NoteAdListContainer = ({ children }) => {
  const query = useQueryString()
  const history = useHistory()

  const location = useLocation()
  const dispatch = useDispatch()
  const [onRequest, , data, errorListings] = useAxios(getAdListings)
  const [onRequestReset, , dataReset, errorResetListings] =
    useAxios(getAdListings)
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const planInfo = useSelector((state) => state.auth.planInfo)
  const selectedStatus = useSelector(
    (state) => state.noteAd.status.selectedStatus,
  ) //선택된 최상단 상태 값 필터
  const listSortParams = useSelector(
    (state) => state.noteAd.listSortParams,
  ) //정렬 state

  const [updatedAdSrl, setUpdatedAdSrl] = useState(null) //방금 네이버 광고 컬럼에서 update 동작한 광고 srl
  const [deletedAdSrl, setDeletedAdSrl] = useState(null) //삭제 동작을 한 광고 srl
  const [input, bindInput, resetInput, setInput] = useInput(
    query?.search_keyword || '',
  ) //검색창

  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  const authModalVisible = useSelector(
    (state) => state.noteAd.authModalVisible,
  ) //본인인증 모달

  useEffect(() => {
    if (location?.state?.activeStatus) return
    search()
    dispatch(
      setShowIntroduceService({
        regi: false,
        status: false,
      }),
    )
    dispatch(
      setListSortParams({
        index: '',
        type: '',
      }),
    )
  }, [])

  /**
   * 목록 조회
   * @param {*} filters 필터
   * @param {*} isReset reset 후 검색 여부
   * @param {*} isResetWithInput 검색어까지 reset 후 검색 여부
   */
  function search(
    filters = null,
    isReset = false,
    isResetWithInput = false,
  ) {
    if (!planInfo?.allinone_plan_srl) return

    //검색 조건 초기로 초기화
    if (isReset) {
      setTableData([])
    }

    if (isResetWithInput) {
      setTableData([])
      setInput('')
    }

    setLoading(true)

    onRequest({
      plan_srl: planInfo.allinone_plan_srl,
      sort_index: listSortParams?.index || '',
      sort_type: listSortParams?.type || '',
      filters: filters || getFilterStatus() || null,
      search_keyword: isResetWithInput ? '' : input,
    })
  }

  /**
   * 목록 가져온 뒤
   * @param {*} e
   * @returns
   */
  useEffect(() => {
    if (!data) return
    if (data?.error != 0 || !data?.result) {
      setLoading(false)
      return
    }

    const list = data?.result || []

    if (list.length == 0) {
      setLoading(false)
      return
    }

    setTableData(list)
    setLoading(false)
  }, [data])

  /**
   * 네이버 광고 컬럼 버튼 처리 후 검색
   */
  const replaceSearch = (adSrl, type, input, pSelectedStatus) => {
    if (!adSrl) return

    if (type == 'delete') {
      setDeletedAdSrl(adSrl)
    } else {
      setUpdatedAdSrl(adSrl)
    }

    onRequestReset({
      plan_srl: planInfo.allinone_plan_srl,
      sort_index: listSortParams?.index || '',
      sort_type: listSortParams?.type || '',
      search_keyword: input,
      filters: pSelectedStatus ? [pSelectedStatus] : null,
    })
  }

  /**
   * 네이버 광고 컬럼 버튼 검색 후
   * @param {*} e
   * @returns
   */
  useEffect(() => {
    if (!dataReset) return

    if (!dataReset) {
      return
    }

    if (dataReset?.error != 0 || !dataReset?.result) {
      setLoading(false)
      return
    }

    if (dataReset.result.length == 0) {
      search(null, true)
      return
    }

    setTableData(dataReset?.result || [])
    setLoading(false)
    setUpdatedAdSrl(null)
  }, [dataReset])

  /**
   * object array merge
   * @param {*} origin 원래 데이터
   * @param {*} newData 업데이트할 데이터
   * @returns
   */
  const setSpecificObjectValues = (origin, newData) => {
    return map(
      assign(
        mapKeys(origin, (v) => v.ad_srl),
        mapKeys(newData, (v) => v.ad_srl),
      ),
    ).reverse()
  }

  /**
   * 조회 에러 시 로딩 해제
   */
  useEffect(() => {
    if (!errorListings) return
    setLoading(false)
  }, [errorListings])

  /**
   * 조회 에러 시 로딩 해제
   */
  useEffect(() => {
    if (!errorResetListings) return

    setLoading(false)
  }, [errorResetListings])

  /**
   * 본인인증 후 callback
   */
  useUpdateEffect(() => {
    if (authModalVisible || !loginInfo?.validated) {
    }

    search()
  }, [authModalVisible, loginInfo?.validated])

  /**
   * 필터 파라미터 값 얻기
   */
  function getFilterStatus() {
    if (!selectedStatus) return null
    if (selectedStatus == '전체') {
      return null
    } else {
      return [selectedStatus]
    }
  }

  /**
   * 필터 상태값 업데이트 시 재조회
   */
  useUpdateEffect(() => {
    if (!selectedStatus) {
      setTableData([])
      search(null, true)
      return
    }
    setTableData([])
    search(getFilterStatus())
  }, [selectedStatus])

  const resetSearch = () => {
    setInput('')
    history.replace(`/note/v2/ad`)
  }

  useEffect(() => {
    if (!listSortParams?.index) return
    search()
  }, [listSortParams])

  const onClickMobileList = (adSrl) => {
    history.push(`/note/v2/ad/${adSrl}`)
  }

  return children({
    input,
    bindInput,
    resetSearch,
    loading,
    tableData:
      [
        ...newItem,
        ...tableData.map((tableItem) => {
          return { ...tableItem, row_key: tableItem.ad_srl }
        }),
      ] || [],
    search,
    replaceSearch,
    onClickMobileList,
  })
}

export default React.memo(NoteAdListContainer)
