import { useContext } from 'react'

import MapContext from 'contexts/map'
import { getComputedArea } from 'utils'
import { Tag } from 'components/design'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import theme from 'lib/styles/theme'

const SummaryRealPriceItem = ({ item }) => {
  const { state } = useContext(MapContext)

  const isCanceled = item.canceled_date

  return (
    <StyledTr canceled={isCanceled ? 'Y' : 'N'}>
      <td className="type">{item.type}</td>
      <td className="date">{item.year}</td>
      <td className="price">
        {item.is_share === 1 ? (
          <StyledTag size="small" color="danger" tooltip="지분 거래">
            지분
          </StyledTag>
        ) : (
          ''
        )}
        {item.price}
      </td>
      {(item.land_area || item.land_of_use_area) && (
        <td className="area">
          {getComputedArea(
            item.land_area || item.land_of_use_area || 0,
            state.isSquareMeter,
          )}
        </td>
      )}
      {!item.land_area && !item.land_of_use_area && (
        <td className="area">-</td>
      )}
      {item.type !== '토지' && (
        <td className="area">
          {getComputedArea(
            item.exclusive_area || item.total_floor_area || 0,
            state.isSquareMeter,
          )}
        </td>
      )}
      {item.type === '토지' && <td className="area">-</td>}
    </StyledTr>
  )
}

const StyledTr = styled.tr`
  position: relative;
  ${({ canceled }) =>
    canceled == 'Y' &&
    `
     td {
        color: ${theme.colors.gray[400]} !important;
    }
    
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 1px;
        height: 1px;
        border: 0;
        border-top: 1px solid ${theme.colors.gray[400]};
    }
  `}
`

const StyledTag = styled(Tag)`
  margin-right: 4px;
`

export default SummaryRealPriceItem
