import { useContext, useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import useNoteMatch from 'pages/jmapnote/hooks/useNoteMatch'
import CalendarContext from 'contexts/calendarContext'

import { Card } from 'components/design'
import { CardBody } from 'components/design'
import ErrorView from 'components/common/ErrorView/ErrorView'
import useGetCalendars from 'components/jmapnote/calendar/hooks/useGetCalendars'
import useQueryString from 'lib/hooks/useQueryString'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Axios from 'axios'

const CalendarContainer = ({ children }) => {
  const history = useHistory()
  const { date } = useQueryString(history.location.pathname)
  const [list, setList] = useState([])

  const {
    state: { currentDate, viewMode },
    actions: { setSchedules, setCurrentDate },
  } = useContext(CalendarContext)

  const {
    _getCalendars,
    _getCalendarsLoading,
    _getCalendarsData,
    _getCalendarsError,
    _getCalendarsSource,
  } = useGetCalendars()

  const { channelSrl } = useNoteMatch()

  useEffect(() => {
    if (!_getCalendarsData) return

    //이번 년도, 지난달 28,29,30,31일
    const prevMonth = _getCalendarsData?.result?.filter(({ date }) => {
      return (
        moment(date).year() == moment().year() &&
        moment(date).month() == moment().month() - 1 &&
        [28, 29, 30, 31].includes(moment(date).date())
      )
    })

    //이번 년도, 이번 달 것만
    const currentMonth = _getCalendarsData?.result?.filter(
      ({ date }) => {
        return (
          moment(date).year() == moment().year() &&
          moment(date).month() == moment().month()
        )
      },
    )
    setList([...currentMonth, ...prevMonth] || [])
  }, [_getCalendarsData])

  useEffect(() => {
    const source = Axios.CancelToken.source()
    _getCalendars(source.token)
    return () => {
      source.cancel()
    }
  }, [channelSrl])

  useEffect(() => {
    if (date) {
      setCurrentDate(moment(date))
    } else {
      setCurrentDate(moment().clone())
    }
  }, [])

  if (_getCalendarsLoading)
    return (
      <Card noMargin>
        <CardBody>
          <Skeleton />
        </CardBody>
      </Card>
    )
  if (_getCalendarsError)
    return (
      <ErrorView error={_getCalendarsError} fetchData={_getCalendars} />
    )
  if (!_getCalendarsData) return <></>

  return children({
    list,
    currentDate,
    setCurrentDate,
    viewMode,
    currentDate,
  })
}

export default CalendarContainer
