import { Button } from 'components/design'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'

/**
 * 올인원 멤버 초대
 * input 입력칸 아래 결제 버튼
 */
const NotePaymentPersonNumberSelectButton = ({
  month,
  sumPerson,
  finalAmount,
  onClickPayment,
  isAllValidated,
}) => {
  return isAllValidated ? (
    <StyledButton onClick={onClickPayment} color="blue">
      <Info>
        {month}개월 / {sumPerson || 1}명
      </Info>
      <Price>{numberWithCommas(finalAmount)}원 결제하기</Price>
    </StyledButton>
  ) : (
    <NoSelect>
      {sumPerson == 0
        ? '초대할 멤버의 전화번호를 입력해주세요.'
        : '전화번호 유효성 검증을 해주세요.'}
    </NoSelect>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 333px;
  height: 56px;
  margin-top: 78px;
  box-shadow: ${theme.shadow['button-1']};

  ${media.mediumS`
    width: 100%;
  `}
`

const Info = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  color: ${theme.colors.base.white};

  i {
    margin-left: 4px;
  }
`

const Price = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  color: ${theme.colors.base.white};
`

const NoSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 78px;
  width: 333px;
  height: 56px;
  background-color: ${theme.colors.gray[400]};
  color: ${theme.colors.base.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 180%;
  border-radius: 8px;

  ${media.mediumS`
    margin-top: 24px;
  `}
`

export default NotePaymentPersonNumberSelectButton
