import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NoteAccountAllInOneMembersListItem from './NoteAccountAllInOneMembersListItem'
import NotePaymentAllInOneMemberDelete from '../NotePayment/NotePaymentAllInOneMemberDelete'
import NoteAccountAllInOneMemberDeleteContainer from 'containers/notev2/NoteAccountAllInOneMemberDeleteContainer'
import FullScreenLoading from 'components/common/Loading/FullScreenLoading'

/**
 * 계정관리 올인원 멤버 리스트
 * 테이블, 삭제
 */
const NoteAccountAllInOneMembersList = ({ data, search }) => {
  return (
    <Wrapper>
      <Table>
        <thead>
          <tr>
            <th className="number">NO.</th>
            <th className="name">이름</th>
            <th className="position">직위</th>
            <th className="phone">휴대폰번호</th>
            <th className="date">이용기간</th>
            <th className="delete"></th>
          </tr>
        </thead>
        <tbody>
          <NoteAccountAllInOneMemberDeleteContainer search={search}>
            {({
              loading,
              invoice,
              card,
              totalPrice,
              isDeleteModalVisible,
              onOpenDeleteModal,
              onCancelDeleteModal,
              onDelete,
            }) => {
              return (
                <>
                  {loading && <FullScreenLoading highLevel />}
                  {data.map((item, i) => {
                    return (
                      <NoteAccountAllInOneMembersListItem
                        idx={i}
                        key={i}
                        item={item}
                        onOpenDeleteModal={onOpenDeleteModal}
                      />
                    )
                  })}
                  <NotePaymentAllInOneMemberDelete
                    onOk={onDelete}
                    visible={isDeleteModalVisible}
                    onCancel={onCancelDeleteModal}
                    invoice={invoice}
                    totalPrice={totalPrice}
                    card={card}
                  />
                </>
              )
            }}
          </NoteAccountAllInOneMemberDeleteContainer>
        </tbody>
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 40px;
    text-align: center;
  }

  thead {
    font-size: 14px;
    background-color: ${theme.colors.gray[150]};
    font-weight: 500;
    line-height: 100%;
    th {
      i {
        margin-left: 4px;
        cursor: pointer;
      }

      &.number {
        min-width: 40px;
      }

      &.name {
        min-width: 100px;
      }

      &.position {
        min-width: 120px;
      }

      &.phone {
        min-width: 180px;
      }

      &.date {
        min-width: 220px;
      }

      &.delete {
        min-width: 60px;
      }
    }
  }

  tbody {
    font-size: 14px;
    font-weight: 500;
  }
`

const EditText = styled.div`
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
  color: ${theme.colors.tint.danger};
`

export default NoteAccountAllInOneMembersList
