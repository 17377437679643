import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import theme from 'lib/styles/theme'

const Spinner = ({ className }) => {
  return <Spin indicator={antIcon} className={className} />
}

const antIcon = (
  <LoadingOutlined
    style={{ color: theme.colors.gray[400], fontSize: 24 }}
    spin
  />
)

export default Spinner
