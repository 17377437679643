import React, { useCallback, useEffect, useState } from 'react'
import { Button, Empty } from 'components/design'
import { getPayments } from 'lib/api/notev2/paymentApi'
import useAxios from 'lib/hooks/useAxios'
import theme from 'lib/styles/theme'
import styled from 'styled-components'
import moment from 'moment'
import { numberWithCommas } from 'utils'
import PagingWithNumber from 'components/design/Paging/PagingWithNumber'
import { Skeleton } from 'antd'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import { useSelector } from 'react-redux'
import { setRefreshPayments } from 'modules/notev2'
import { useDispatch } from 'react-redux'

/**
 * 결제관리 메인
 * 결제내역
 */
const NotePaymentList = () => {
  const dispatch = useDispatch()
  const limit = 5
  const [currentPage, setCurrentPage] = useState(1)

  const [paymentList, setPaymentList] = useState([])
  const [onRequestPayments, loading, dataPayments] =
    useAxios(getPayments)

  const refreshPayments = useSelector(
    (state) => state.notev2.refreshPayments,
  )

  useEffect(() => {
    onRequestPayments({
      status: ['settled', 'canceled'],
    })
  }, [])

  useEffect(() => {
    if (!dataPayments) return
    setPaymentList(dataPayments.result)
  }, [dataPayments])

  const onClickReceipt = (url) => {
    window.open(url, '_blank')
  }

  const getLimitedData = useCallback(
    (data) => {
      return data.slice(
        currentPage * 5 - 5 < 0 ? 0 : currentPage * 5 - 5,
        currentPage === 0 ? limit : currentPage * limit,
      )
    },
    [currentPage],
  )

  useUpdateEffect(() => {
    if (!refreshPayments) return

    onRequestPayments({
      status: ['settled', 'canceled'],
    })
    dispatch(setRefreshPayments(false))
  }, [refreshPayments])

  return (
    <Wrapper>
      {loading ? (
        <Skeleton />
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th>구매상품</th>
              <th>결제내용</th>
              <th>결제금액</th>
              <th>결제수단</th>
              <th>할부기간</th>
              <th>결제일시</th>
              <th>영수증</th>
            </tr>
          </thead>
          <tbody>
            {paymentList && paymentList.length > 0 ? (
              getLimitedData(paymentList).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.order_name}</td>
                    <td>{item.type}</td>
                    <td
                      className={
                        item.status === 'canceled' ? 'canceled' : ''
                      }
                    >
                      {item.status === 'canceled' ? <>&ndash;</> : ''}
                      {numberWithCommas(item.amount)}원
                    </td>
                    <td>
                      {item?.card?.cardType}
                      카드
                    </td>
                    {item.status !== 'canceled' && (
                      <td>
                        {item.extra_vars?.card &&
                          (item.extra_vars?.card
                            ?.installmentPlanMonths === 0
                            ? '일시불'
                            : item.extra_vars?.card
                                ?.installmentPlanMonths + '개월')}
                      </td>
                    )}
                    {item.status === 'canceled' && <td>일시불</td>}
                    <td>
                      {moment(item.paid_date, 'YYYYMMDDHHmmss').format(
                        'YYYY.MM.DD HH:mm',
                      )}
                    </td>
                    <td>
                      {item?.extra_vars?.receipt?.url && (
                        <Button
                          size="small"
                          onClick={() =>
                            onClickReceipt(
                              item?.extra_vars?.receipt?.url,
                            )
                          }
                        >
                          영수증보기
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan="7">
                  <Empty>결제 내역이 없습니다</Empty>
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      )}

      {paymentList.length > 0 && (
        <PagingWrapper>
          <PagingWithNumber
            data={paymentList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={5}
          />
        </PagingWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding: 24px 30px 24px 30px;
  overflow: auto;
`

const PagingWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  max-width: 100%;
`

const StyledTable = styled.table`
  width: 100%;
  min-width: 1000px;
  border: 1px solid ${theme.colors.gray[200]};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;
  border-collapse: collapse;
  border: none;
  margin-bottom: 16px;

  thead {
    height: 49px;
  }

  tbody {
    tr {
      border-top: 1px solid ${theme.colors.gray[100]};

      &:last-child {
        border-bottom: 1px solid ${theme.colors.gray[100]};
      }
    }

    td {
      &.canceled {
        color: ${theme.colors.tint.danger};
      }
    }
  }

  tr {
    height: 49px;
    &:nth-child(even) {
      background-color: ${theme.colors.gray[50]};
    }
  }
`
export default React.memo(NotePaymentList)
