import { Checkbox } from 'components/design'
import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  TableContent,
  InnerTable,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  innerSubHeaderWidth,
  TableSubHeaderFullWidth,
  StyledTable,
  TextArea,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { handleFocusNumber } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'

const NoteDocsBasic3 = ({ onUpdate }) => {
  const { watch } = useFormContext()

  const d_zone_use = watch('d_zone_use')
  const d_use_disrict = watch('d_use_disrict')
  const d_use_area = watch('d_use_area')
  const d_coverage_ratio = watch('d_coverage_ratio')
  const d_floor_area_ratio = watch('d_floor_area_ratio')

  const d_plan_facility = watch('d_plan_facility')
  const d_permission_type = watch('d_permission_type')
  const d_speculative_type = watch('d_speculative_type')
  const d_planning_area = watch('d_planning_area')
  const d_transaction_regulation = watch('d_transaction_regulation')

  return (
    <StyledTable>
      <Row height={41} rowspan={9} noborderbottom>
        <TableHeader width={headerWidth}>
          ③ 토지이용
          <br />
          계획,
          <br />
          공법상 이용
          <br />
          제한 및 거래
          <br />
          규제에 관한
          <br />
          사항&#40;토지&#41;
        </TableHeader>
        <RowSpanInner
          rowspanheightpercent={23}
          rowspan={3}
          minuswidth={headerWidth}
        >
          <TableSubHeader width={headerWidth}>지역·지구</TableSubHeader>
          <TableContent fullwidth nopadding noborderright>
            <InnerTable>
              <Row heightpercent={100} noborderbottom>
                <InnerTableBlock widthpercent={55} noborderbottom>
                  <Row rowspan={3} heightpercent={100} noborderbottom>
                    <RowSpanInner fullwidth rowspan={3}>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        용도지역
                      </TableSubHeader>
                      <InnerTableContent
                        headerwidth={innerSubHeaderWidth}
                      >
                        <ColorInput
                          type="text"
                          defaultValue={d_zone_use}
                          onBlur={(e) =>
                            onUpdate('d_zone_use', e.target.value)
                          }
                          fullWidth
                        />
                      </InnerTableContent>
                    </RowSpanInner>
                    <RowSpanInner fullwidth rowspan={3}>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        용도지구
                      </TableSubHeader>
                      <InnerTableContent
                        headerwidth={innerSubHeaderWidth}
                      >
                        <ColorInput
                          type="text"
                          defaultValue={d_use_disrict}
                          onBlur={(e) =>
                            onUpdate('d_use_disrict', e.target.value)
                          }
                          fullWidth
                        />
                      </InnerTableContent>
                    </RowSpanInner>
                    <RowSpanInner noborderbottom fullwidth rowspan={3}>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        용도구역
                      </TableSubHeader>
                      <InnerTableContent
                        headerwidth={innerSubHeaderWidth}
                      >
                        <ColorInput
                          type="text"
                          defaultValue={d_use_area}
                          onBlur={(e) =>
                            onUpdate('d_use_area', e.target.value)
                          }
                          fullWidth
                        />
                      </InnerTableContent>
                    </RowSpanInner>
                  </Row>
                </InnerTableBlock>
                <InnerTableBlock
                  widthpercent={45}
                  noborderbottom
                  noborderright
                >
                  <Row rowspan={3} heightpercent={100} noborderbottom>
                    <RowSpanInner fullwidth rowspan={3} noborderbottom>
                      <InnerTableBlock widthpercent={50}>
                        <TableSubHeaderFullWidth>
                          건폐율 상한
                        </TableSubHeaderFullWidth>
                      </InnerTableBlock>
                      <InnerTableBlock widthpercent={50} noborderright>
                        <TableSubHeaderFullWidth>
                          용적률 상한
                        </TableSubHeaderFullWidth>
                      </InnerTableBlock>
                    </RowSpanInner>
                    <RowSpanInner
                      fullwidth
                      noborderbottom
                      rowspanheightpercent={(100 / 3) * 2}
                    >
                      <InnerTableContent>
                        <ColorInput
                          type="number"
                          onClick={handleFocusNumber}
                          append="%"
                          defaultValue={d_coverage_ratio}
                          onBlur={(e) =>
                            onUpdate('d_coverage_ratio', e.target.value)
                          }
                        />
                      </InnerTableContent>
                      <InnerTableContent>
                        <ColorInput
                          type="number"
                          append="%"
                          onClick={handleFocusNumber}
                          defaultValue={d_floor_area_ratio}
                          onBlur={(e) =>
                            onUpdate(
                              'd_floor_area_ratio',
                              e.target.value,
                            )
                          }
                        />
                      </InnerTableContent>
                    </RowSpanInner>
                  </Row>
                </InnerTableBlock>
              </Row>
            </InnerTable>
          </TableContent>
        </RowSpanInner>
        <RowSpanInner
          rowspanheightpercent={35}
          rowspan={2}
          minuswidth={headerWidth}
        >
          <TableSubHeader width={innerSubHeaderWidth}>
            도시·군 계획시설
          </TableSubHeader>
          <TableContent fullwidth nopadding noborderright>
            <InnerTable>
              <Row heightpercent={100} noborderbottom>
                <InnerTableBlock widthpercent={35} noborderbottom>
                  <InnerTableContent headerwidth={0}>
                    <TextArea
                      fullwidth
                      fullheight
                      defaultValue={d_plan_facility}
                      onBlur={(e) =>
                        onUpdate('d_plan_facility', e.target.value)
                      }
                    />
                  </InnerTableContent>
                </InnerTableBlock>
                <InnerTableBlock
                  widthpercent={65}
                  noborderright
                  noborderbottom
                >
                  <Row rowspan={2} heightpercent={100} noborderbottom>
                    <RowSpanInner fullwidth rowspan={2}>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        허가·신고
                        <br />
                        구역 여부
                      </TableSubHeader>
                      <InnerTableContent
                        headerwidth={innerSubHeaderWidth}
                        pl
                      >
                        <Checkbox
                          color="blue"
                          checked={
                            d_permission_type == 'Y' ? true : false
                          }
                          onChange={(e) =>
                            onUpdate('d_permission_type', e ? 'Y' : 'N')
                          }
                        >
                          토지거래허가구역
                        </Checkbox>
                      </InnerTableContent>
                    </RowSpanInner>
                    <RowSpanInner noborderbottom fullwidth rowspan={2}>
                      <TableSubHeader width={innerSubHeaderWidth}>
                        투기지역 여부
                      </TableSubHeader>
                      <InnerTableContent
                        checkboxes
                        headerwidth={innerSubHeaderWidth}
                      >
                        <RadioButton
                          label="토지투기지역"
                          checked={d_speculative_type == '토지투기지역'}
                          name="d_speculative_type"
                          onChange={(e) =>
                            onUpdate(
                              'd_speculative_type',
                              '토지투기지역',
                            )
                          }
                        />
                        <RadioButton
                          label="주택투기지역"
                          checked={d_speculative_type == '주택투기지역'}
                          name="d_speculative_type"
                          onChange={(e) =>
                            onUpdate(
                              'd_speculative_type',
                              '주택투기지역',
                            )
                          }
                        />
                        <RadioButton
                          label="투기과열지구"
                          checked={d_speculative_type == '투기과열지구'}
                          name="d_speculative_type"
                          onChange={(e) =>
                            onUpdate(
                              'd_speculative_type',
                              '투기과열지구',
                            )
                          }
                        />
                      </InnerTableContent>
                    </RowSpanInner>
                  </Row>
                </InnerTableBlock>
              </Row>
            </InnerTable>
          </TableContent>
        </RowSpanInner>
        <RowSpanInner
          rowspanheightpercent={42}
          minuswidth={headerWidth}
          noborderbottom
        >
          <InnerTableBlock widthpercent={50} noborderbottom>
            <TableSubHeader width={innerSubHeaderWidth}>
              지구단위계획구역,
              <br />그 밖의 도시 · 군관리계획
            </TableSubHeader>
            <InnerTableContent headerwidth={innerSubHeaderWidth}>
              <TextArea
                fullwidth
                fullheight
                defaultValue={d_planning_area}
                onBlur={(e) =>
                  onUpdate('d_planning_area', e.target.value)
                }
              />
            </InnerTableContent>
          </InnerTableBlock>
          <InnerTableBlock
            widthpercent={50}
            noborderbottom
            noborderright
          >
            <TableSubHeader width={innerSubHeaderWidth}>
              그 밖의 이용제한
              <br />및 거래규제사항
            </TableSubHeader>
            <InnerTableContent headerwidth={innerSubHeaderWidth}>
              <TextArea
                fullwidth
                fullheight
                defaultValue={d_transaction_regulation}
                onBlur={(e) =>
                  onUpdate('d_transaction_regulation', e.target.value)
                }
              />
            </InnerTableContent>
          </InnerTableBlock>
        </RowSpanInner>
      </Row>
    </StyledTable>
  )
}

export default NoteDocsBasic3
