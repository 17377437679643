import useAdValidate from 'lib/hooks/notev2/useAdValidate'
import { useEffect } from 'react'

/**
 *
 * @param {*} validationError submit api의 필수값 validate 결과
 * @returns
 */
const NoteAdValidateContainer = ({ children, validationError }) => {
  const { validate } = useAdValidate()

  useEffect(() => {
    if (!validationError) return
    validate(validationError)
  }, [validationError])

  return children({})
}

export default NoteAdValidateContainer
