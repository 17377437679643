import RadioButton from 'components/design/Radio/Radio'
import ColorInput from 'components/jmapnotev2/elements/ColorInput'
import { Checkbox } from 'components/design'
import {
  RowSpanInner,
  TableHeader,
  TableSubHeader,
  Row,
  InnerTableContent,
  InnerTableBlock,
  headerWidth,
  StyledTable,
  RadioButtonsWrap,
} from 'components/jmapnotev2/style/NoteDocsStyle'
import { convertToKoreanNumber } from 'lib/utils/notev2/util'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { useMemo } from 'react'

const gwanlibies = [
  '전기료',
  '수도료',
  '가스사용료',
  '난방비',
  '인터넷 사용료',
  'TV 수신료',
]

const NoteDocsBasic5 = ({ onUpdate, onUpdateCheckbox }) => {
  const { watch, setValue } = useFormContext()

  const d_descript_type = watch('d_descript_type')
  const d_is_security = watch('d_is_security')
  const d_management = watch('d_management')
  const d_gwanlibi_eumaeg = watch('d_gwanlibi_eumaeg')
  const d_gwanlibi_poham_bimog = watch('d_gwanlibi_poham_bimog') || []
  const d_gwanlibi_extra_bimog = watch('d_gwanlibi_extra_bimog')
  const d_gwanlibi_bugwabangsig = watch('d_gwanlibi_bugwabangsig')
  const d_gwanlibi_extra_bugwabangsig = watch(
    'd_gwanlibi_extra_bugwabangsig',
  )

  const isResidentialBuilding = useMemo(
    () => d_descript_type == '주거용 건축물',
    [d_descript_type],
  )

  const getNumber = () => {
    if (isResidentialBuilding) {
      return '⑥'
    } else {
      return '⑤'
    }
  }

  return (
    <StyledTable>
      <Row
        height={isResidentialBuilding ? 100 : 20}
        rowspan={2}
        noborderbottom={!isResidentialBuilding}
      >
        <TableHeader width={headerWidth}>
          {getNumber()} 관리에
          <br />
          관한사항
        </TableHeader>
        <RowSpanInner
          rowspanheightpercent={isResidentialBuilding ? 20 : 100}
          minuswidth={headerWidth}
          noborderbottom={!isResidentialBuilding}
        >
          <InnerTableBlock widthpercent={40} noborderbottom>
            <TableSubHeader width={headerWidth}>경비실</TableSubHeader>
            <InnerTableContent checkboxes headerwidth={headerWidth}>
              <RadioButton
                label="있음"
                checked={d_is_security == '있음' || d_is_security > 0}
                name="d_is_security"
                onChange={(e) => onUpdate('d_is_security', '있음')}
              />
              <RadioButton
                label="없음"
                checked={d_is_security == '없음' || d_is_security == ''}
                name="d_is_security"
                onChange={(e) => onUpdate('d_is_security', '없음')}
              />
            </InnerTableContent>
          </InnerTableBlock>
          <InnerTableBlock
            widthpercent={60}
            noborderbottom
            noborderright
          >
            <TableSubHeader width={headerWidth}>
              관리주체
            </TableSubHeader>
            <InnerTableContent checkboxes headerwidth={headerWidth}>
              <RadioButton
                label="위탁관리"
                checked={d_management == '위탁관리'}
                name="d_management"
                onChange={(e) => onUpdate('d_management', '위탁관리')}
              />
              <RadioButton
                label="자체관리"
                checked={d_management == '자체관리'}
                name="d_management"
                onChange={(e) => onUpdate('d_management', '자체관리')}
              />
              <RadioButton
                label="그 밖의 유형"
                checked={d_management == '그 밖의 유형'}
                name="d_management"
                onChange={(e) =>
                  onUpdate('d_management', '그 밖의 유형')
                }
              />
            </InnerTableContent>
          </InnerTableBlock>
        </RowSpanInner>
        {isResidentialBuilding && (
          <RowSpanInner
            rowspanheightpercent={80}
            minuswidth={headerWidth}
            noborderbottom
          >
            <InnerTableBlock
              widthpercent={100}
              noborderbottom
              noborderright
            >
              <TableSubHeader width={headerWidth}>
                관리비
              </TableSubHeader>
              <InnerTableContent checkboxes headerwidth={headerWidth}>
                <ContentWrapper>
                  <FeeContent>
                    <FeeContentTitle>관리비 금액 &#58;</FeeContentTitle>
                    금&nbsp;
                    <ParseKorean>
                      {convertToKoreanNumber(
                        String(d_gwanlibi_eumaeg)?.replaceAll(',', ''),
                      )}
                    </ParseKorean>
                    원정 &nbsp; &#40;₩
                    <ColorInput
                      width={170}
                      type="number"
                      defaultValue={d_gwanlibi_eumaeg}
                      onBlur={(e) =>
                        onUpdate(
                          'd_gwanlibi_eumaeg',
                          String(e.target.value)?.replaceAll(',', ''),
                        )
                      }
                      prepend="\"
                    />
                    &#41;
                  </FeeContent>
                  <FeeContent>
                    <FeeContentTitle>
                      관리비 포함 비목 &#58;
                    </FeeContentTitle>
                    <Checkboxes>
                      {gwanlibies.map((gwanlibi, i) => {
                        return (
                          <Checkbox
                            key={`gwanlibi_${i}`}
                            color="blue"
                            checked={
                              d_gwanlibi_poham_bimog.includes(gwanlibi)
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              onUpdateCheckbox({
                                oriData: d_gwanlibi_poham_bimog,
                                key: 'd_gwanlibi_poham_bimog',
                                value: gwanlibi,
                              })
                            }}
                          >
                            {gwanlibi}
                          </Checkbox>
                        )
                      })}
                      <OtherGwanlibi>
                        <Checkbox
                          color="blue"
                          checked={
                            d_gwanlibi_poham_bimog.includes(
                              '그 밖의 비목',
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            onUpdateCheckbox({
                              oriData: d_gwanlibi_poham_bimog,
                              key: 'd_gwanlibi_poham_bimog',
                              value: '그 밖의 비목',
                            })
                          }}
                        >
                          그 밖의 비목
                        </Checkbox>
                        <ColorInput
                          type="text"
                          defaultValue={d_gwanlibi_extra_bimog}
                          disabled={
                            !d_gwanlibi_poham_bimog.includes(
                              '그 밖의 비목',
                            )
                          }
                          onBlur={(e) =>
                            onUpdate(
                              'd_gwanlibi_extra_bimog',
                              e.target.value,
                            )
                          }
                        />
                      </OtherGwanlibi>
                    </Checkboxes>
                  </FeeContent>
                  <FeeContent>
                    <FeeContentTitle>
                      관리비 부과방식 &#58;
                    </FeeContentTitle>
                    <Checkboxes radio>
                      <RadioButton
                        label="임대인이 직접 부과"
                        checked={
                          d_gwanlibi_bugwabangsig ==
                          '임대인이 직접 부과'
                        }
                        name="d_gwanlibi_bugwabangsig"
                        onChange={(e) =>
                          onUpdate(
                            'd_gwanlibi_bugwabangsig',
                            '임대인이 직접 부과',
                          )
                        }
                      />
                      <RadioButton
                        label="관리규약에 따라 부과"
                        checked={
                          d_gwanlibi_bugwabangsig ==
                          '관리규약에 따라 부과'
                        }
                        name="d_gwanlibi_bugwabangsig"
                        onChange={(e) =>
                          onUpdate(
                            'd_gwanlibi_bugwabangsig',
                            '관리규약에 따라 부과',
                          )
                        }
                      />

                      <OtherGwanlibi>
                        <RadioButton
                          label="그 밖의 부과 방식"
                          checked={
                            d_gwanlibi_bugwabangsig ==
                            '그 밖의 부과 방식'
                          }
                          name="d_gwanlibi_bugwabangsig"
                          onChange={(e) =>
                            onUpdate(
                              'd_gwanlibi_bugwabangsig',
                              '그 밖의 부과 방식',
                            )
                          }
                        />
                        <ColorInput
                          type="text"
                          defaultValue={d_gwanlibi_extra_bugwabangsig}
                          disabled={
                            d_gwanlibi_bugwabangsig !=
                            '그 밖의 부과 방식'
                          }
                          onBlur={(e) =>
                            onUpdate(
                              'd_gwanlibi_extra_bugwabangsig',
                              e.target.value,
                            )
                          }
                        />
                        &#41;
                      </OtherGwanlibi>
                    </Checkboxes>
                  </FeeContent>
                </ContentWrapper>
              </InnerTableContent>
            </InnerTableBlock>
          </RowSpanInner>
        )}
      </Row>
    </StyledTable>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 8px;
  margin-left: 8px;
`

const FeeContent = styled.div`
  display: flex;
  align-items: flex-start;
`

const ParseKorean = styled.div`
  min-width: 120px;
  text-align: right;
  font-weight: 600;
`

const OtherGwanlibi = styled.div`
  display: flex;
  align-items: center;
  line-height: 100%;
`

const Checkboxes = styled.div`
  display: flex;
  gap: 4px 12px;
  flex-wrap: wrap;

  ${({ radio }) =>
    radio &&
    `
      gap: 0 12px;
  `}
`

const FeeContentTitle = styled.div`
  min-width: 120px;
  flex-shrink: 0;
`

export default NoteDocsBasic5
