import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import RadioButton from '../../elements/RadioButton'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 주차대수(주거형의 방 또는 일부 타입)
 */
const NoteAdColumnParkingForRoom = ({ options = false, desc }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const { onUpdate } = useAdUpdate()

  const parkingPsbl = watch(`article.parkingPsbl`)
  return (
    <ColumnSet half>
      <ColumnTitle>주차대수</ColumnTitle>
      <ColumnVal gap={10}>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="text"
            {...register('article.parking')}
            onChange={(e) => {
              setValue('article.parking', e.target.value)
            }}
            append={'대'}
            onBlur={(e) => {
              onUpdate('article.parking', e.target.value)
            }}
          />
        </div>
        {options && (
          <>
            <RadioButton
              fit
              label="주차가능"
              name="article_parking_psbl_for_room"
              value={true}
              checked={parkingPsbl === true}
              {...register('article.parkingPsbl')}
              onChange={(e) => {
                onUpdate(
                  'article.parkingPsbl',
                  JSON.parse(e.target.value),
                )
              }}
            />
            <RadioButton
              fit
              label="주차불가"
              name="article_parking_psbl_for_room"
              value={false}
              {...register('article.parkingPsbl')}
              checked={parkingPsbl === false}
              onChange={(e) => {
                onUpdate(
                  'article.parkingPsbl',
                  JSON.parse(e.target.value),
                )
              }}
            />
            <ValidateWarn>
              {errors?.article?.parkingPsbl?.message}
            </ValidateWarn>
          </>
        )}
        {desc && <Desc>* 중개사법 주차대수 (최대 5자리)</Desc>}
      </ColumnVal>
    </ColumnSet>
  )
}

const Desc = styled.div`
  color: ${theme.colors.tint.danger};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
`

export default NoteAdColumnParkingForRoom
