import {
  HelpCardHeader,
  Title,
  Price,
  HelpIcon,
  InfoWrap,
  BuildingPriceInfo,
  Hr,
} from './SummarySizeReviewStyle'

import { priceFormatMilion } from 'lib/utils/priceFormat'
import { Card, CardHeader } from 'components/design'
import { CardBody } from 'components/design'
import theme from 'lib/styles/theme'
import useIsMobile from 'lib/hooks/useIsMobile'

const SummarySizeReviewCost = ({ data, setIsInfoHide }) => {
  const isMobile = useIsMobile()

  return (
    <Card>
      <CardHeader>
        <HelpCardHeader>
          <Title>
            매입·신축 비용은{' '}
            <Price margin={false}>
              {priceFormatMilion(
                (data.result.buildings[0].investment_info
                  .construct_cost +
                  data.result.buildings[0].investment_info
                    .purchase_price) *
                  10000,
              )}
              원
            </Price>
            {isMobile && '이고'}
            , <br />
            실투자금은
            <Price>
              {priceFormatMilion(
                (data.result.buildings[0].investment_info
                  .construct_cost +
                  data.result.buildings[0].investment_info
                    .purchase_price -
                  data.result.buildings[0].investment_info
                    .deposit_sum) *
                  10000,
              )}
              원
            </Price>
            의 <br />
            비용이 들어요
          </Title>
          <HelpIcon onClick={() => setIsInfoHide(false)}>
            <i className="fas fa-info-circle"></i>
          </HelpIcon>
        </HelpCardHeader>
      </CardHeader>
      <CardBody>
        <InfoWrap background={theme.colors.gray[50]}>
          <BuildingPriceInfo>
            <div>예상 토지 매입비용</div>
            <div>
              <strong>
                {priceFormatMilion(
                  data.result.buildings[0].investment_info
                    .purchase_price * 10000,
                )}
                원
              </strong>
            </div>
          </BuildingPriceInfo>
          <BuildingPriceInfo>
            <div>예상 건축 비용</div>
            <div>
              <strong>
                {priceFormatMilion(
                  data.result.buildings[0].investment_info
                    .construct_cost * 10000,
                )}
                원
              </strong>
            </div>
          </BuildingPriceInfo>
          <Hr />
          <BuildingPriceInfo>
            <div>총 사업비용</div>
            <div>
              <strong>
                {priceFormatMilion(
                  (data.result.buildings[0].investment_info
                    .construct_cost +
                    data.result.buildings[0].investment_info
                      .purchase_price) *
                    10000,
                )}
                원
              </strong>
            </div>
          </BuildingPriceInfo>
          <BuildingPriceInfo>
            <div>보증금</div>
            <div>
              <strong>
                -
                {priceFormatMilion(
                  data.result.buildings[0].investment_info.deposit_sum *
                    10000,
                )}
                원
              </strong>
            </div>
          </BuildingPriceInfo>
          <Hr />
          <BuildingPriceInfo>
            <div>실투자금</div>
            <div>
              <strong>
                {priceFormatMilion(
                  (data.result.buildings[0].investment_info
                    .construct_cost +
                    data.result.buildings[0].investment_info
                      .purchase_price -
                    data.result.buildings[0].investment_info
                      .deposit_sum) *
                    10000,
                )}
                원
              </strong>
            </div>
          </BuildingPriceInfo>
        </InfoWrap>
      </CardBody>
    </Card>
  )
}

export default SummarySizeReviewCost
