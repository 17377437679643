import { useSelector } from 'react-redux'
import { MetaItem } from './SummaryHeaderMeta'
import { Tag } from 'components/design'

/**
 * summary 최상단 태그 - 소유자 정보
 */
const SummaryHeaderMetaOwner = () => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)

  return (
    <>
      {summaryInfo?.owner_type &&
        summaryInfo.owner_type.map((str, i) => (
          <MetaItem key={i}>
            <Tag size="small" tooltip="소유자">
              {str}
            </Tag>
          </MetaItem>
        ))}
    </>
  )
}

export default SummaryHeaderMetaOwner
