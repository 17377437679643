import theme, { media } from 'lib/styles/theme'
import React, { useCallback } from 'react'
import styled from 'styled-components'

/**
 *  검색 input
 * @param searchType keydown | enter
 */
const NoteListHeaderSearch = ({
  onSearch,
  bindSearch,
  search,
  clearSearch,
  size,
  searchType = 'keydown',
  noMaxWidth,
  fullWidth,
}) => {
  const onKeyDown = useCallback(
    (e) => {
      if (searchType == 'keydown') {
        onSearch(e)
      } else {
        if (e.key == 'Enter') {
          onSearch()
        }
      }
    },
    [searchType, onSearch],
  )

  return (
    <InputWrap
      size={size}
      noMaxWidth={noMaxWidth}
      fullWidth={fullWidth}
    >
      <SearchIcon className="fa fa-search"></SearchIcon>
      <SearchInput
        onKeyDown={onKeyDown}
        type="text"
        placeholder="검색"
        {...bindSearch}
      />
      {search && (
        <InputClearButton
          onClick={clearSearch}
          className="fal fa-times"
        />
      )}
    </InputWrap>
  )
}

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 360px;
  height: 34px;
  max-width: 100%;

  ${({ size }) => size && size == 'large' && `height: 38px;`};

  ${({ fullWidth }) => fullWidth && `width: 100%;`};

  ${media.mediumS`
    max-width: 60%;
    ${({ noMaxWidth }) =>
      noMaxWidth &&
      `
      max-width: none;
    `}
    margin-bottom: 0px;
  `}
`

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  // max-width: 100%;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  padding-left: 36px;
  outline: none;
  font-size: 14px;

  ${media.mediumS`
    padding-left: 32px;
  `}

  &:focus {
    outline: 1px solid ${theme.colors.blue[400]};
  }
`

const SearchIcon = styled.i`
  color: ${theme.colors.gray[700]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;

  ${media.mediumS`
    font-size: 14px;
  `}
`

const InputClearButton = styled.i`
  color: ${theme.colors.gray[600]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  border-radius: 100%;
  background-color: ${theme.colors.gray[200]};
  width: 16px;
  height: 16px;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default React.memo(NoteListHeaderSearch)
