import { isPressEditKey } from 'lib/utils/notev2/suppressKey'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * 그리드 셀 키보드 조작, 더블클릭 조작 함수를 모아둔 hook
 * 조회모드에서 사용함
 * rowIndex, limitHeight 파라미터는 선택 레이어가 그리드의 하단에서 열릴 때 화면에서 넘치는지 확인하여 레이어가 위쪽으로 뜨도록 함
 * @param status: 매물 상태 (중개 가능, 완료)
 * @param rowIndex: 현재 row index
 * @param limitHeight dropdown 컨테이너의 높이
 */

function useCustomSingleCell(status, rowIndex, limitHeight = 200) {
  const [isEditable, setIsEditable] = useState(false)
  const channelInfo = useSelector((state) => state.channel.channelInfo)
  const [isReversePosition, setIsReversePosition] = useState(false) //선택 레이어가 위쪽으로 포지셔닝 하는지 여부

  /**
   * 조회 상태에서(셀 수정이 아닌 상태임) 더블클릭하면 수정중 상태로 변경
   * 중개완료인 경우 동작하지 않음
   */
  const onDoubleClickSingleCell = useCallback(() => {
    if (channelInfo?.member_type == 'readonly') return
    if (status == 2) return
    setIsEditable(true)
    calcReverse()
  }, [status])

  /**
   *조회 상태에서(셀 수정이 아닌 상태임) 
   focus가 잡힌 상태에서 수정 key 감지시 수정중 상태로
   수정 key - 스페이스바, 엔터, 문자열 키, 숫자열 키 등
   */
  const onKeyDownSingleCell = useCallback(
    (e) => {
      if (channelInfo?.member_type == 'readonly') return
      if (status == 2) return

      if (isPressEditKey(e)) {
        if (!isEditable) setIsEditable(true)
      }

      calcReverse()
    },
    [status],
  )

  /**
   * 선택 레이어(ex. 주소, 종류 등)가 열릴 때 그리드를 넘어 가려지는지 확인 후 위로 띄움
   */
  const calcReverse = useCallback(() => {
    if (!rowIndex) return

    const target = Array.from(
      document.getElementsByClassName('ag-row'),
    ).find((elem) => {
      return elem.getAttribute('row-index') == rowIndex
    }) //현재 행의 y축 position을 얻기 위해 쓰는 방법

    const topPos = target.getBoundingClientRect().top //현재 row의 top position
    const rowHeight = target.clientHeight //현재 row의 height
    const pos = topPos + rowHeight //현재 row의 끝(?) position (네모의 아랫변 position)
    const diff = window.innerHeight - pos //윈도우 최하단의 위치와 현재 row의 위치의 차이

    //윈도우 최하단의 위치와 현재 row의 위치의 차이를 구해서, dropdown보다 좁으면 위로 띄우는 flag를 true로 만든다
    if (diff < limitHeight) {
      setIsReversePosition(true)
    }
  }, [rowIndex, limitHeight])

  return {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    isReversePosition,
  }
}

export default useCustomSingleCell
