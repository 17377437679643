import theme from 'lib/styles/theme'
import styled from 'styled-components'
import BdsContentHeader from './BdsContentHeader'
import BdsContentFilter from './filter/BdsContentFilter'
import BdsContentList from './BdsContentList'
import { forwardRef } from 'react'
import { Skeleton } from 'antd'
import { Empty } from 'components/design'

/**
 * 주택부동산
 * 1뎁스 내용
 * @param address 현재 위치 주소(ㅇㅇ동)
 * @param list 매물 목록
 */
const BdsContent = ({ address, list, loading }, ref) => {
  return (
    <Wrapper>
      <BdsContentHeader address={address} />
      <BdsContentFilter />
      {list.length > 0 ? (
        <BdsContentList list={list} />
      ) : (
        <EmptyWrapper>
          <Empty>광고중인 매물이 없습니다.</Empty>
        </EmptyWrapper>
      )}
      <div ref={ref} />

      {loading && <Skeleton active />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 24px 20px;
  background-color: ${theme.colors.base.white};
`

const EmptyWrapper = styled.div`
  margin-top: 20px;
`

export default forwardRef(BdsContent)
