import NoteCalendarNav from './NoteCalendarNav'

const NoteCalendarHeader = ({ currentDate, setCurrentDate }) => {
  return (
    <NoteCalendarNav
      currentDate={currentDate}
      setCurrentDate={setCurrentDate}
    />
  )
}
export default NoteCalendarHeader
