import theme from 'lib/styles/theme'
import styled from 'styled-components'
import NoteAdNoticeList from './NoteAdNoticeList'
import {
  More,
  Title,
  TitleWrapper,
} from '../notification/NoteAdNotification'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 광고 메인 공지사항
 */
const NoteAdNotice = () => {
  const history = useHistory()

  const onClickMore = () => {
    history.push('/note/v2/notice')
  }

  return (
    <Block>
      <TitleWrapper>
        <Title>공지사항</Title>
        <More onClick={onClickMore}>더보기</More>
      </TitleWrapper>
      <NoteAdNoticeList />
    </Block>
  )
}

const Block = styled.div`
  flex-grow: 1;
  padding: 20px 24px;
  border-radius: 12px;
  background-color: ${theme.colors.base.white};
`

export default NoteAdNotice
