import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import ChannelContainer from 'containers/note/channel/ChannelContainer'
import CalendarPage from 'pages/jmapnote/CalendarPage'
import ContactPage from './ContactPage'
import NoteListPage from './noteList/NoteListPage'

const NoteRouterWrap = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - ${theme.base.noteTabHeight}px);
`

const NoteChannelPage = ({ history }) => {
  return (
    <>
      <ChannelContainer>
        {() => (
          <>
            <Switch>
              <Route
                path="/note/:channelSrl/calendar"
                component={CalendarPage}
              />

              <Route
                path="/note/:channelSrl/contact"
                component={ContactPage}
              />

              <Route
                path={[`/note/:channelSrl/:folderSrl`]}
                component={NoteListPage}
              />
            </Switch>
          </>
        )}
      </ChannelContainer>
    </>
  )
}

export default NoteChannelPage
