import styled from 'styled-components'
import NoteAdColumnPropertyType from '../columns/basic/NoteAdColumnPropertyType'
import { Table, TableWrapper, Title } from './NoteAdFormStyle'
import NoteAdColumnAddress from '../columns/basic/NoteAdColumnAddress'
import NoteAdColumnFloor from '../columns/basic/NoteAdColumnFloor'
import NoteAdColumnTotalFloor from '../columns/basic/NoteAdColumnTotalFloor'
import NoteAdColumnFloorAgree from '../columns/basic/NoteAdColumnFloorAgree'
import NoteAdColumnRoomCount from '../columns/basic/NoteAdColumnRoomCount'
import NoteAdColumnBathroomCount from '../columns/basic/NoteAdColumnBathroomCount'
import NoteAdColumnDirection from '../columns/basic/NoteAdColumnDirection'
import NoteAdColumnDoor from '../columns/basic/NoteAdColumnDoor'
import NoteAdColumnContractType from '../columns/price/NoteAdColumnContractType'
import NoteAdColumnSalePrice from '../columns/price/NoteAdColumnSalePrice'
import NoteAdColumnDeposit from '../columns/price/NoteAdColumnDeposit'
import NoteAdColumnRent from '../columns/price/NoteAdColumnRent'
import NoteAdColumnConvertJeonse from '../columns/price/NoteAdColumnConvertJeonse'
import NoteAdColumnLoan from '../columns/price/NoteAdColumnLoan'
import NoteAdColumnAlreadyDeposit from '../columns/price/NoteAdColumnAlreadyDeposit'
import NoteAdColumnAlreadyRent from '../columns/price/NoteAdColumnAlreadyRent'
import NoteAdColumnCotractDateInfo from '../columns/price/NoteAdColumnCotractDateInfo'
import NoteAdColumnLivingFacility from '../columns/facility/NoteAdColumnLivingFacility'
import NoteAdColumnSecureFacility from '../columns/facility/NoteAdColumnSecureFacility'
import NoteAdColumnEtcFacility from '../columns/facility/NoteAdColumnEtcFacility'
import NoteAdColumnMoveDate from '../columns/detail/NoteAdColumnMoveDate'
import NoteAdColumnCharacter from '../columns/detail/NoteAdColumnCharacter'
import NoteAdColumnDetail from '../columns/detail/NoteAdColumnDetail'
import NoteAdColumnPictures from '../columns/detail/NoteAdColumnPictures'
import theme from 'lib/styles/theme'
import NoteAdColumnStoreInfo from '../columns/store/NoteAdColumnStoreInfo'
import NoteAdColumnContact from '../columns/store/NoteAdColumnContact'
import NoteAdColumnBuildingName from '../columns/basic/NoteAdColumnBuildingName'
import NoteAdColumnLandShare from '../columns/basic/NoteAdColumnLandShare'
import NoteAdColumnContractArea from '../columns/basic/NoteAdColumnContractArea'
import NoteAdColumnExclusiveArea from '../columns/basic/NoteAdColumnExclusiveArea'
import NoteAdColumnHousehold from '../columns/basic/NoteAdColumnHousehold'
import NoteAdColumnParkingForRoom from '../columns/basic/NoteAdColumnParkingForRoom'
import NoteAdColumnAirConditioningFacility from '../columns/facility/NoteAdColumnAirConditioningFacility'
import NoteAdColumnHeatingFuel from '../columns/facility/NoteAdColumnHeatingFuel'
import NoteAdColumnHeatingType from '../columns/facility/NoteAdColumnHeatingType'
import NoteAdColumnVerification from '../columns/verification/NoteAdColumnVerification'
import NoteAdColumnAddressDetail from '../columns/basic/NoteAdColumnAddressDetail'
import NoteAdColumnFee from '../columns/fee/NoteAdColumnFee'
import NoteAdColumnBuildingUseDaejang from '../columns/basic/NoteAdColumnBuildingUseDaejang'
import NoteAdColumnOneRoom from '../columns/basic/oneroom/NoteAdColumnOneRoom'
import NoteAdPriceDesc from '../columns/price/NoteAdPriceDesc'
import NoteAdStoreDesc from '../columns/store/NoteAdStoreDesc'
import NoteAdColumnBuildingDate from '../columns/basic/NoteAdColumnBuildingDate'

/**
 * 광고관리 매물등록 테이블
 * 주거형의 방 또는 일부
 */
const NoteAdFormRoom = () => {
  return (
    <Wrapper>
      <Title>1. 기본정보</Title>
      <TableWrapper>
        <Table lastcount={2}>
          <NoteAdColumnPropertyType />
          <NoteAdColumnAddress />
          <NoteAdColumnAddressDetail half />
          <NoteAdColumnBuildingUseDaejang
            half
            columnKey="article.lawUsageCode"
            required
          />
          <NoteAdColumnBuildingDate />
          <NoteAdColumnLandShare half={false} />
          <NoteAdColumnContractArea required />
          <NoteAdColumnExclusiveArea
            columnKey="article.spc2"
            required
          />
          <NoteAdColumnFloor required />
          <NoteAdColumnTotalFloor required />
          <NoteAdColumnFloorAgree half={false} />
          <NoteAdColumnOneRoom parking />
          <NoteAdColumnRoomCount multiple required openSelect options />
          <NoteAdColumnBathroomCount required={false} />
          <NoteAdColumnDirection required />
          <NoteAdColumnDoor />
          <NoteAdColumnHousehold />
          <NoteAdColumnParkingForRoom />
        </Table>
      </TableWrapper>
      <Title attachDesc>2. 거래정보</Title>
      <NoteAdPriceDesc />
      <TableWrapper>
        <Table lastcount={2}>
          <NoteAdColumnContractType />
          <NoteAdColumnSalePrice required />
          <NoteAdColumnDeposit />
          <NoteAdColumnRent />
          <NoteAdColumnConvertJeonse />
          <NoteAdColumnLoan />
          <NoteAdColumnAlreadyDeposit />
          <NoteAdColumnAlreadyRent />
        </Table>
      </TableWrapper>
      <Title>3. 관리비 부과 정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnFee />
        </Table>
      </TableWrapper>
      <Title>4. 시설정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnHeatingType half />
          <NoteAdColumnHeatingFuel half />
          <NoteAdColumnAirConditioningFacility />
          <NoteAdColumnLivingFacility />
          <NoteAdColumnSecureFacility />
          <NoteAdColumnEtcFacility />
        </Table>
      </TableWrapper>
      <Title>5. 상세정보</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnMoveDate />
          <NoteAdColumnCotractDateInfo />
          <NoteAdColumnCharacter />
          <NoteAdColumnDetail />
          <NoteAdColumnPictures />
        </Table>
      </TableWrapper>
      <Title attachDesc>6. 중개업소 정보</Title>
      <NoteAdStoreDesc />
      <TableWrapper>
        <Table>
          <NoteAdColumnStoreInfo />
          <NoteAdColumnContact />
        </Table>
      </TableWrapper>
      <Title>7. 검증 방식</Title>
      <TableWrapper>
        <Table>
          <NoteAdColumnVerification />
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const Desc = styled.div`
  display: flex;
  margin-left: 4px;
  margin-bottom: 6px;
  color: ${theme.colors.tint.danger};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;

  &::before {
    content: '·';
    font-size: 30px;
    margin-right: 7px;
  }
`

export default NoteAdFormRoom
