import React, { useRef } from 'react'
import useRoveFocus from 'lib/hooks/useRoveFocus'
import CustomAddressCellListItem from './CustomAddressCellListItem'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import useOnClickOutside from 'lib/hooks/useOnClickOutside'
import { ScrollStyle } from 'components/jmapnotev2/style/NoteStyle'

const CustomAddressCellList = ({
  addresses,
  onSaveAddress,
  escapeFn,
  setIsEditable,
  small = false,
  isReversePosition,
}) => {
  const [focus] = useRoveFocus(addresses.length)
  const ref = useRef()

  /**
   * 바깥 클릭하면 모두 초기화(조회 상태로)
   */
  useOnClickOutside(ref, () => {
    escapeFn()
    setIsEditable(false)
  })

  return (
    addresses && (
      <StyledList
        small={small}
        ref={ref}
        reverse={isReversePosition ? 'Y' : 'N'}
      >
        {addresses.map((validData, index) => {
          return (
            <CustomAddressCellListItem
              key={validData.address_id + index}
              focus={focus === index}
              validData={validData}
              addressSaveFn={onSaveAddress}
              escapeFn={escapeFn}
            />
          )
        })}
      </StyledList>
    )
  )
}

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  opacity: 1;
  position: absolute;
  top: calc(100% + 4px);
  height: 200px;
  overflow-y: overlay;
  min-width: 100%;
  background-color: ${theme.colors.base.white};
  z-index: 1;
  border: 1px solid ${theme.colors.base.white};
  z-index: 99999999999;
  margin-bottom: 30px;
  margin-left: -1px;
  width: calc(100% + 6px);
  left: -2px;
  border-radius: ${theme.colors.noteSheet.dropdown.radius}px;
  box-shadow: ${theme.shadow['card-2']};
  padding: ${theme.colors.noteSheet.dropdown.padding}px;

  ${({ visible }) => visible && `opacity: 1;`}
  ${({ small }) => small && `top: 22px;`}
  ${({ reverse }) => reverse == 'Y' && `top: -210px;`}

  .ant-menu-item {
    height: 20px;
    font-size: 12px;
    line-height: initial;
  }

  ${media.mediumS`
      width: calc(100vw - 2px);
  `}

  ${ScrollStyle}
`

export default React.memo(CustomAddressCellList)
