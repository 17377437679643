import { useCallback, useContext } from 'react'
import update from 'immutability-helper'

import FavoriteSortableElement from './FavoriteSortableElement'
import MapContext from 'contexts/map'

const FavoriteSortableContainer = ({ items, updateItems }) => {
  const { state } = useContext(MapContext)
  const { loginInfo } = state

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dargItem = items[dragIndex]

      updateItems(
        update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dargItem],
          ],
        }),
      )
    },
    [items],
  )
  const renderItem = (item, index) => {
    return (
      <FavoriteSortableElement
        key={item.address_srl || item.favorite_id}
        index={index}
        id={item.address_srl || item.favorite_id}
        item={item}
        moveItem={moveItem}
      />
    )
  }
  return (
    <>
      <ul className="favorite-list">
        {items.map((item, i) => {
          if (
            loginInfo?.member_type === 'realestate' &&
            item.folder_srl !== 0
          )
            return

          return renderItem(item, i)
        })}
      </ul>
    </>
  )
}

export default FavoriteSortableContainer
