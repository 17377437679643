import { Skeleton } from 'antd'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'components/design'
import SummaryLandCanvas from 'components/summary/SummaryLand/SummaryLandCanvas'
import SummaryLandInfo from 'components/summary/SummaryLand/SummaryLandInfo.'
import { useSelector } from 'react-redux'
import SummaryLandOwnerTimeLine from './SummaryLandOwnerTimeLine'

const SummaryLand = ({ data, loading }) => {
  const summaryInfo = useSelector((state) => state.summary.summaryInfo)

  return (
    <Card>
      <CardHeader>
        <CardTitle>토지 정보</CardTitle>
      </CardHeader>
      <CardBody>
        {loading && <Skeleton active />}
        {data && (
          <>
            <SummaryLandCanvas />
            <SummaryLandInfo data={data} />
          </>
        )}
        {(summaryInfo?.owners?.data ||
          summaryInfo?.owners?.data.length > 0) && (
          <SummaryLandOwnerTimeLine data={summaryInfo?.owners?.data} />
        )}
      </CardBody>
    </Card>
  )
}

export default SummaryLand
