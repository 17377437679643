import { useCallback, useState, useEffect } from 'react'

function useRoveFocus(size, currFocus = 0) {
  const [currentFocus, setCurrentFocus] = useState(currFocus)

  const handleKeyDown = useCallback(
    (e) => {
      if ((e.shiftKey && e.keyCode === 9) || e.keyCode === 37) {
        // Up arrow
        e.preventDefault()
        setCurrentFocus(
          currentFocus === 0 ? size - 1 : currentFocus - 1,
        )
      } else if (e.keyCode === 9 || e.keyCode === 39) {
        // Down arrow
        e.preventDefault()
        setCurrentFocus(
          currentFocus === size - 1 ? 0 : currentFocus + 1,
        )
      } else if (e.keyCode == 40) {
        //아래 방향키
        e.preventDefault()
        const getPos = () => {
          if (currentFocus == 19) {
            return 2
          } else if (currentFocus == 17) {
            return 0
          } else {
            return currentFocus + 3 > size
              ? currentFocus + 3 - size
              : currentFocus + 3
          }
        }
        setCurrentFocus(getPos())
      } else if (e.keyCode == 38) {
        //위 방향키
        e.preventDefault()
        const getPos = () => {
          if (currentFocus == 0) {
            return 17
          } else if (currentFocus == 1) {
            return 18
          } else if (currentFocus == 2) {
            return 19
          } else {
            return currentFocus - 3 < 0
              ? currentFocus - 3 + size
              : currentFocus - 3
          }
        }

        setCurrentFocus(getPos())
      }
    },
    [size, currentFocus, setCurrentFocus],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [handleKeyDown])

  return [currentFocus, setCurrentFocus]
}

export default useRoveFocus
