import styled from 'styled-components'
import { message } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 광고관리 그리드
 * 광고번호들
 */
const CustomNoteSrlCell = ({ node }) => {
  const history = useHistory()
  /**
   * 매물번호 클릭시 3뎁스로
   * @param {*} note_srl
   */
  const onClickNoteSrl = () => {
    if (!node.data?.channel_srl || !node.data?.note_srl) {
      message.error('잘못된 요청입니다.')
      return
    }

    history.push(
      `/note/v2/notes/${node.data.channel_srl}/${
        node.data.address_srl || '0'
      }/${node.data.note_srl}`,
    )
  }

  if (node.data.ad_srl == '신규' || !node.data?.note_srl) return <></>

  return (
    <Wrapper tabIndex={-1}>
      <NoteSrl onClick={onClickNoteSrl}>{node.data?.note_srl}</NoteSrl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  position: relative;
  z-index: 1;
  cursor: pointer;
  ${({ isVisible }) => isVisible && `opacity: 1;`}
`

const NoteSrl = styled.div`
  text-decoration: underline;
  text-underline-position: under;
`

export default CustomNoteSrlCell
