import MapContext from 'contexts/map'
import theme from 'lib/styles/theme'
import { rgba } from 'polished'
import { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'

const MapLocPositionMarker = ({ coords }) => {
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map } = state

  const markerRef = useRef()

  let marker = {}

  useEffect(() => {
    if (Object.keys(marker).length > 0) {
      marker.setMap(null)
    }

    if (!coords?.latitude) {
      // console.log('coords 인식 안됨', coords)
      return
    }

    // console.log('coords 인식됨!')

    const lat = coords?.latitude // 위도
    const lon = coords?.longitude // 경도
    const center = new kakao.maps.LatLng(lat, lon)

    const payload = {
      map: map,
      position: center,
      clickable: false,
      content: markerRef.current,
    }

    // 마커를 생성합니다
    marker = new kakao.maps.CustomOverlay(payload)
    marker.setMap(map)

    return () => {
      // unmount될 때 지도상에서 제거
      marker.setMap(null)
    }
  }, [coords])

  return (
    <div>
      <div ref={markerRef}>
        <Wrapper>
          <Dot />
        </Wrapper>
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-color: ${rgba(theme.colors.primary[400], 0.1)};
  border-radius: 100%;
`

const Dot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100%;
  background-color: ${theme.colors.primary[400]};
  border: 3px solid white;
  box-shadow: ${rgba(theme.colors.primary[400], 1)} 0 0 0.5rem;
`

export default MapLocPositionMarker
