import { getAddressName } from 'lib/utils'

import HomeAddressCard from './HomeAddressCard'

const HomeRecentAddressItem = ({ item }) => {
  return (
    <HomeAddressCard
      to={`/${item.id}`}
      address={getAddressName(item)}
      roadName={
        item.road_name
          ? `${item.road_name} ${item.road_num1}`
          : '도로명주소 없음'
      }
      propertyType={
        item.property_type?.includes('상업업무용')
          ? '상가'
          : item.property_type
      }
      totalFloorArea={
        item.property_type === '토지'
          ? item.land_area
          : item.total_floor_area
      }
    />
  )
}

export default HomeRecentAddressItem
