import { getSelectedItemText } from 'lib/utils/filter'
import MapFilterItem from './MapFilterItem'
import NoteFilterSelect from 'components/jmapnote/noteFilter/NoteFilterSelect'
import NoteFilterSelectOption from 'components/jmapnote/noteFilter/NoteFilterSelectOption'
import { useSelector } from 'react-redux'

const MapFilterLandType = ({ onChangeLandType, land_type }) => {
  const filterOptions = useSelector((state) => state.filter.options)
  const landTypeList = filterOptions.land_type

  return (
    <MapFilterItem
      title="지목"
      titleValue={getSelectedItemText(land_type)}
    >
      <NoteFilterSelect>
        <NoteFilterSelectOption
          value="all"
          column={4}
          center={true}
          group={land_type || []}
          onChangeGroup={onChangeLandType}
        >
          전체
        </NoteFilterSelectOption>
        {landTypeList.map((item, i) => {
          return (
            <NoteFilterSelectOption
              key={i}
              value={item}
              column={4}
              center={true}
              group={land_type || []}
              onChangeGroup={onChangeLandType}
            >
              {item.length > 4 ? (
                <>
                  {item.slice(0, 3)}
                  <br />
                  {item.slice(3, 5)}
                </>
              ) : (
                item
              )}
            </NoteFilterSelectOption>
          )
        })}
      </NoteFilterSelect>
    </MapFilterItem>
  )
}

export default MapFilterLandType
