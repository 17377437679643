import { useEffect, useRef, useState } from 'react'
import { Button } from 'components/design'
import { Card } from 'components/design'
import { CardBody } from 'components/design'

import NoteContactList from 'components/jmapnote/contact/NoteContactList'
import { Skeleton, message } from 'antd'
import NoteContactSave from 'components/jmapnote/contact/NoteContactSave'
import useContacts from './hooks/useContacts'
import styled from 'styled-components'
import theme, { media } from 'lib/styles/theme'
import NoteUploadExcelInput from 'components/jmapnote/noteList/NoteUploadExcelInput'
import axios from 'axios'
import { ListItemLabel } from 'components/design'
import { ListItemDivider } from 'components/design'
import { ListItemDesc } from 'components/design'
import { Tag } from 'components/design'

import ContactSync from 'components/jmapnote/contact/ContactSync'
import useIsMobile from 'lib/hooks/useIsMobile'
import useIsTablet from 'lib/hooks/useIsTablet'
import { useSelector } from 'react-redux'
import { accessibleGroups } from 'Constants'

const ButtonWrap = styled.div`
  padding: 1rem;

  ${media.mediumS`
    /* padding-top: 0; */
  `}
`

const ContactContainer = () => {
  const [fileName, setFileName] = useState('')

  const inputRef = useRef()

  const { _getContacts, loading, contacts } = useContacts()

  const [visible, setVisible] = useState(false)

  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const isVisible =
    (isMobile || isTablet) &&
    loginInfo?.other_groups?.some((item) =>
      accessibleGroups.includes(item),
    )

  useEffect(() => {
    _getContacts()
  }, [_getContacts])

  if (loading)
    return (
      <Card noMargin>
        <CardBody>
          <Skeleton />
        </CardBody>
      </Card>
    )

  const reset = () => {
    inputRef.current.value = ''
  }

  const onChangeFile = (files) => {
    if (!files || files.length === 0) return

    // 파라미터 세팅
    const formData = new FormData()
    formData.append('fileToUpload', files?.[0])

    // API 요청
    axios
      .post('/jmapnote/api/contact/upload', formData)
      .then((resp) => {
        if (resp.data.error) {
          message.error(resp.data.message)
          return
        }

        // console.log('resp: ', resp)
        if (resp.data?.result) {
          _getContacts()
          message.info('연락처가 등록되었습니다.')
        }
        if (!resp.data) {
          message.info('데이터가 없습니다.')
        }
      })
      .catch((error) => {
        console.log(error)
        message.error('서버 오류')
      })
    reset()
    setFileName('')
  }

  return (
    <>
      <ButtonWrap>
        <Button
          color="blue"
          expand
          plain
          onClick={() => setVisible(true)}
        >
          <span>연락처 추가</span>
          <i className="far fa-plus"></i>
        </Button>
      </ButtonWrap>

      <NoteContactSave visible={visible} setVisible={setVisible} />

      <NoteContactList data={contacts} />

      {isVisible && (
        <ButtonWrap>
          <ListItemLabel>연락처 동기화</ListItemLabel>
          <ContactSync />
        </ButtonWrap>
      )}

      <ButtonWrap>
        <ListItemLabel>연락처 파일 업로드</ListItemLabel>
        <ListItemDesc>
          파일에 저장된 연락처를 대량으로 생성합니다.
        </ListItemDesc>
        <Tag>.vcf 파일만 가능</Tag>

        <ListItemDivider xs />
        <NoteUploadExcelInput
          fileName={fileName}
          setFileName={setFileName}
          onChange={onChangeFile}
          inputRef={inputRef}
          accept={'.vcf'}
        />
      </ButtonWrap>
    </>
  )
}

export default ContactContainer
