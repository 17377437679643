import { getDonghoDanjis } from 'lib/api/notev2/dongho'
import useAxios from 'lib/hooks/useAxios'
import useUpdateEffect from 'lib/hooks/useUpdateEffect'
import {
  resetSelectedDanji,
  setHasAddedList,
  setIsSelectFirst,
} from 'modules/noteDongho'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'

/**
 * 동호관리
 * 왼쪽 목록 로직 모음
 */
const NoteDonghoDanjiListContainer = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const isSelectFirst = useSelector(
    (state) => state.noteDongho.isSelectFirst,
  )
  const [onRequest, , data] = useAxios(getDonghoDanjis) //단지 목록 조회
  const [onRequestRefresh, , dataRefresh] = useAxios(getDonghoDanjis) //단지 목록 조회(새로고침용)
  const [paidList, setPaidList] = useState([])
  const [normalList, setNormalList] = useState([])
  const [firstSelectedDanji, setFirstSelectedDanji] = useState(null)
  const [loading, setLoading] = useState(false)
  const [stopLoad, setStopLoad] = useState(false) //데이터 더 이상 없으면 로딩 멈춤(무한 스크롤)
  const { ref: loadTargetRef, inView, entry } = useInView()
  const [page, setPage] = useState(1) //현재 페이지

  const count = 20

  /**
   * 무한스크롤 observer ref 감지
   */
  useEffect(() => {
    if (stopLoad) return
    // inView가 true 일때만 실행한다.

    if (inView) {
      setPage((prev) => prev + 1)
      search(page + 1)
    }
  }, [inView])

  /**
   * 단지 목록 조회
   */
  useEffect(() => {
    search()
    setLoading(true)

    return () => {
      dispatch(resetSelectedDanji())
      setPage(1)
    }
  }, [])

  /**
   * 첫 접속시 처음 단지 클릭
   */
  useUpdateEffect(() => {
    if (isSelectFirst && (params?.id || params?.dongho)) return

    history.replace(`/note/v2/dongho/${firstSelectedDanji.id}`)

    dispatch(setIsSelectFirst(true))
  }, [firstSelectedDanji])

  /**
   * 단지 검색
   */
  const search = (pPage) => {
    onRequest({
      page: pPage || page,
      count,
    })
  }

  /**
   * 단지 검색(새로고침)
   */
  const searchRefresh = () => {
    if (page == 1) {
      setPaidList([])
    }

    if (entry?.target) {
      entry.target.style.display = 'none'
    }
    setNormalList([])
    setPage(1)
    onRequestRefresh({
      page: 1,
      count,
    })
  }

  /**
   * 단지 검색 후
   */
  useEffect(() => {
    if (!data) return
    if (data?.error != 0 || !data?.result) {
      setStopLoad(true)
      setLoading(false)
      return
    }
    if (data.result.length == 0) {
      dispatch(setHasAddedList(false))

      setStopLoad(true)
      setLoading(false)
      return
    }

    setDatas(data, false, data?.page)
    setLoading(false)
  }, [data])

  /**
   * 단지 검색 후(새로고침)
   */
  useEffect(() => {
    if (!dataRefresh) return
    if (dataRefresh?.result?.length == 0) {
      dispatch(setHasAddedList(false))
      return
    }

    setStopLoad(false)
    setDatas(dataRefresh, true, dataRefresh?.page)
    if (entry?.target) {
      entry.target.style.display = 'block'
    }
  }, [dataRefresh])

  /**
   * 목록에서 단지 클릭
   * @param {*} danjiObj
   */
  const onClickDanji = (danjiObj) => {
    history.replace(`/note/v2/dongho/${danjiObj.id}`)
  }

  /**
   * api 호출후 보여줄 데이터 세팅
   */
  const setDatas = (data, isRefresh, page) => {
    const result = data?.result || []
    let paid

    if (page == 1) {
      paid = result.filter(({ is_paid }) => is_paid == 'Y')
      setPaidList(result.filter(({ is_paid }) => is_paid == 'Y'))
    }

    const normal = result.filter(({ is_paid }) => is_paid == 'N') //무료 단지

    setNormalList((prev) => [...prev, ...normal])

    if (!isRefresh && page == 1) {
      if (params?.id || params?.dong) return
      if (paid.length > 0) {
        setFirstSelectedDanji(paid[0])
      } else if (normal.length > 0) {
        setFirstSelectedDanji(normal[0])
      }
    }
  }

  return children({
    paidList,
    normalList,
    onClickDanji,
    searchRefresh: searchRefresh,
    loading,
    loadTargetRef,
  })
}

export default NoteDonghoDanjiListContainer
