import theme, { media } from 'lib/styles/theme'
import { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const BlueTextBox = ({ height, padding, ...rest }, ref) => {
  const { watch } = useFormContext()

  const adFormControl = useSelector(
    (state) => state.noteAd.adFormControl,
  )

  const adSrl = watch('ad_srl')

  const isDisabled =
    adFormControl?.adSrl == adSrl && adFormControl?.isDisabledAll //읽기 전용 여부

  return (
    <StyledInput
      {...rest}
      ref={ref}
      height={height}
      padding={padding}
      disabled={isDisabled || rest.disabled}
      type="text"
    />
  )
}

const StyledInput = styled.input`
  width: 70%;
  height: 24px;
  padding: 0 14px;
  background-color: ${theme.colors.blue[50]};
  border: none;
  border-radius: 0;
  outline: none;
  resize: none;

  ${({ height }) =>
    height &&
    `
        height: ${height}px;
  `}

  ${({ padding }) =>
    padding &&
    `
    padding: ${padding};
  `}


  &::placeholder {
    font-size: 14px;
    color: ${theme.colors.gray[400]};
  }

  &:disabled {
    background-color: ${theme.colors.gray[100]};
  }

  ${media.mediumS`
    width: 100%;
    margin-top: 40px;
  `}
`
export default forwardRef(BlueTextBox)
