import Header from 'components/common/Header/Header'
import HeaderBack from 'components/common/Header/HeaderBack'
import useIsMobile from 'lib/hooks/useIsMobile'
import useQueryString from 'lib/hooks/useQueryString'
import { useHistory } from 'react-router-dom'
import HeaderButtonShare from './HeaderButtonShare'

const SummaryHcTopHeader = ({ isOpen }) => {
  const isMobile = useIsMobile()
  const history = useHistory()
  const query = useQueryString()

  const onClose = () => {
    if (query?.back) {
      history.go(-1)
      return
    }

    history.push('/')
    return
  }

  if (isMobile && !isOpen) return null

  return (
    <Header fixed append={<HeaderButtonShare />}>
      <HeaderBack onClose={onClose} />
    </Header>
  )
}

export default SummaryHcTopHeader
