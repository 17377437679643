import HomeQuickMenuItem from './HomeQuickMenuItem'
import NoteLinkContainer from 'containers/note/NoteLinkContainer'
import theme from 'lib/styles/theme'

const HomeNoteLink = () => {
  return (
    <NoteLinkContainer>
      {({ popup, onClick }) => (
        <>
          <HomeQuickMenuItem
            onClick={onClick}
            color={theme.colors.primary[500]}
            as="div"
            icon={
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M44 24.093V24C44 28.793 42.3238 32.3877 38.9715 32.3877L35.6192 32.3877C33.7677 32.3877 32.2668 33.8732 32.2668 35.7056V38.4085C32.2668 41.5821 29.5112 43.9676 24 44C35.0562 43.9393 44 35.0498 44 24.093Z"
                  fill="black"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 10.6667C4 6.98477 6.98477 4 10.6667 4H37.3333C41.0152 4 44 6.98477 44 10.6667V24C44 24.0863 43.9995 24.1725 43.9984 24.2585C43.9967 24.3875 43.9939 24.5162 43.9898 24.6446C43.8461 29.134 42.1828 32.4268 39 32.4267H35.6667C33.8257 32.4267 32.3333 33.9191 32.3333 35.7601V38.4756C32.3333 40.8878 30.7649 42.8468 27.6281 43.6717C26.4515 43.8873 25.2389 44 24 44H10.6667C6.98477 44 4 41.0152 4 37.3333V10.6667ZM12.3333 14C12.3333 13.0795 13.0795 12.3333 14 12.3333H34C34.9205 12.3333 35.6667 13.0795 35.6667 14C35.6667 14.9205 34.9205 15.6667 34 15.6667H14C13.0795 15.6667 12.3333 14.9205 12.3333 14ZM12.3333 22.3333C12.3333 21.4129 13.0795 20.6667 14 20.6667H34C34.9205 20.6667 35.6667 21.4129 35.6667 22.3333C35.6667 23.2538 34.9205 24 34 24H14C13.0795 24 12.3333 23.2538 12.3333 22.3333ZM14 29C13.0795 29 12.3333 29.7462 12.3333 30.6667C12.3333 31.5871 13.0795 32.3333 14 32.3333H25.6667C26.5871 32.3333 27.3333 31.5871 27.3333 30.6667C27.3333 29.7462 26.5871 29 25.6667 29H14Z"
                  fill="black"
                />
              </svg>
            }
          >
            매물노트
          </HomeQuickMenuItem>

          {popup}
        </>
      )}
    </NoteLinkContainer>
  )
}

export default HomeNoteLink
