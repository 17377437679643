import React from 'react'
import { NavLink } from 'react-router-dom'
import SideNavItem from './SideNavItem'

const SideMenuBasicItem = ({
  to,
  icon,
  activeIcon,
  label,
  onClick,
  active,
  hasWrapper,
}) => {
  return hasWrapper ? (
    <SideNavItem
      onClick={onClick}
      to={to}
      icon={icon}
      activeIcon={activeIcon}
      active={active}
      label={label}
    />
  ) : (
    <SideNavItem
      onClick={onClick}
      to={to}
      icon={icon}
      as={NavLink}
      activeIcon={activeIcon}
      active={active}
      label={label}
    />
  )
}

export default React.memo(SideMenuBasicItem)
