import theme, { media } from 'lib/styles/theme'
import styled, { keyframes } from 'styled-components'
import StarIconSrc from 'assets/icon/note/dongho/star.png'
import { useSelector } from 'react-redux'
import useIsMobile from 'lib/hooks/useIsMobile'

const NoteDonghoSpeechBubble = ({ onClose }) => {
  const isMobile = useIsMobile()
  const isVisibleHeaderChannel = useSelector(
    (state) => state.notev2.isVisibleHeaderChannel,
  )
  return (
    ((isMobile && isVisibleHeaderChannel) || !isMobile) && (
      <Bubble id="dongho-info-bubble" onClick={onClose}>
        관심있는 단지를 추가해보세요
        <img src={StarIconSrc} />
      </Bubble>
    )
  )
}

const floatUpdown = keyframes`
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-12px);}
	60% {transform: translateY(-5px);}
`

const Bubble = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  position: fixed;
  width: max-content;
  border-radius: 15px;
  padding: 12px 16px;
  z-index: 100;
  background: rgba(107, 87, 230, 0.9);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.18);
  color: ${theme.colors.base.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
  top: 56px;
  left: ${theme.base.navWidth + theme.base.note.filterWidth}px;
  cursor: pointer;
  animation: ${floatUpdown} 2s ease infinite;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(107, 87, 230, 0.9);
    border-left: 6px solid transparent;
    left: -14px;
  }

  ${media.mediumM`
    top: ${theme.base.headerHeight + 20}px;
    left: ${theme.base.note.filterWidth}px;
  `}

  ${media.mediumS`
    left: 23px;
    top: 39px;
    font-size: 14px;
    padding: 10px 14px;

    &::after {
        left: 50%;
        bottom: -9px;
        transform: translateX(-50%);
        border-bottom: 2px solid transparent;
        border-top: 7px solid rgba(107, 87, 230, 0.9);
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
    }
  `}

  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`

export default NoteDonghoSpeechBubble
