import InputText from '../../elements/InputText'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputRemoveAppendWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import RadioButton from '../../elements/RadioButton'
import { options as listOptions } from '../../options'
import React from 'react'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 방 개수
 */
const NoteAdColumnRoomCount = ({
  required = false,
  multiple = false,
  options,
}) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const oneroomType = watch('oneroomInfo.oneroomType')
  const shape = watch('article.shape')
  const room = watch('article.room')
  const category1 = watch('article.category1')

  const list = listOptions['방거실형태']

  return (
    <ColumnSet half>
      <ColumnTitle required={required}>방 개수</ColumnTitle>
      <ColumnVal gap={multiple && 16}>
        <div>
          <InputText
            fixAppendWidth
            width={InputRemoveAppendWidth}
            type="text"
            {...register('article.room')}
            onBlur={(e) => {
              onUpdate('article.room', e.target.value)
            }}
            append={'개'}
          />
        </div>
        {/* <NoteAdColumnRoomStructure /> */}
        {category1 != '원룸' &&
          room == 1 &&
          options &&
          list.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <RadioButton
                  size="small"
                  fit
                  label={item}
                  {...register('article.shape')}
                  value={item}
                  checked={item == oneroomType || item == shape}
                  onChange={(e) => {
                    onUpdate('article.shape', e.target.value)

                    //원룸특징의 원룸구조도 업데이트
                    onUpdate('oneroomInfo.oneroomType', e.target.value)
                  }}
                  name="article_room_shape"
                />
              </React.Fragment>
            )
          })}
        <ValidateWarn>{errors?.article?.room?.message}</ValidateWarn>
        <ValidateWarn>{errors?.article?.duplex?.message}</ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

const CheckWrapper = styled.div`
  display: flex;

  input[type='checkbox'] {
    margin-left: 8px;
  }
`

export default NoteAdColumnRoomCount
