import { useContext } from 'react'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import CardContext from './context/CardContext'
import PropTypes from 'prop-types'

const CardHeader = ({
  children,
  subtitle = null,
  top = false,
  className,
}) => {
  const context = useContext(CardContext) || null
  const sizeState = context?.state?.sizeState || 'medium'

  return (
    <StyledCardHeader size={sizeState} className={className} top={top}>
      {children}
      {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
    </StyledCardHeader>
  )
}

const StyledCardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.25rem 0;

  color: ${theme.colors.gray[900]};
  font-size: 1rem;
  font-weight: 700;

  .card-title {
    flex: 1;
    word-break: keep-all;
  }

  ${({ top }) =>
    top &&
    `
    align-items: flex-start;
  `}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          padding: 1rem 1rem 0;
        `
      case 'medium':
        return `
          padding: 1.5rem 1.25rem 0;
        `
      case 'large':
        return `
          font-size: 1.25rem;
          padding: 1.875rem 1.5rem 0;
        `
    }
  }}
`

const StyledSubtitle = styled.div`
  flex: 0 0 auto;
  margin-left: 0.5rem;
  color: ${theme.colors.gray[600]};
  font-size: 0.875rem;
  font-weight: 400;
`

CardHeader.propTypes = {
  children: PropTypes.any,
  subtitle: PropTypes.any,
  top: PropTypes.bool,
}

export default CardHeader
