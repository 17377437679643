import { useEffect } from 'react'
import styled from 'styled-components'

import Container from 'components/base/Container/Container'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { message } from 'antd'

const NoteChannelInvitePage = ({ match }) => {
  const history = useHistory()
  const channels = useSelector((state) => state.channel.channels)
  const channelSrl = match?.params?.channelSrl

  useEffect(() => {
    const findChannelInfo = channels.find(
      (e) => e.channel_srl.toString() === channelSrl,
    )
    if (!findChannelInfo) {
      // message.error('채널을 찾을 수 없습니다.')
      history.replace('/notifications')
      return
    }
    const folderSrl = findChannelInfo?.folder_srl

    history.replace(`/note/${channelSrl}/${folderSrl}`)
  })

  return (
    <Container>
      <StyledDiv>채널로 이동합니다.</StyledDiv>
    </Container>
  )
}

const StyledDiv = styled.div`
  padding-left: 1.25rem;
`

export default NoteChannelInvitePage
