import { Skeleton } from 'antd'
import SumamryMultiHousingTag from './SummaryMultiHousingTag'
import SummaryMultiHousingGraph from './SummaryMultiHousingGraph'
import SummaryMultiHousingList from './SummaryMultiHousingList'
import { Empty } from 'components/design'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const SummaryMultiHousing = ({
  data,
  loading,
  selectedDong,
  selectedHo,
}) => {
  const isApt = useSelector((state) => state.summary.isApt)
  return (
    <>
      {data && data.length != 0 && loading && (
        <Wrapper>
          <Skeleton active />
        </Wrapper>
      )}
      {(!data || data.length == 0) && !loading && (
        <Wrapper>
          <Empty>데이터가 없습니다.</Empty>
        </Wrapper>
      )}
      {data && data.length > 0 && !loading && (
        <>
          {isApt && (
            <SumamryMultiHousingTag
              data={data}
              selectedDong={selectedDong}
              selectedHo={selectedHo}
            />
          )}
          <SummaryMultiHousingGraph data={data} />
          <SummaryMultiHousingList data={data} />
        </>
      )}
    </>
  )
}

const Wrapper = styled.div`
  margin-top: 24px;
`

export default SummaryMultiHousing
