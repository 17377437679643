import SchoolIcon from 'assets/icon/map-marker/school.svg'
import useIsMobile from 'lib/hooks/useIsMobile'
import useSummarySchoolAmemities from 'lib/hooks/useSummarySchoolAmemities'
import theme from 'lib/styles/theme'
import { getDistance } from 'lib/utils/summary'
import styled from 'styled-components'

const SummarySchoolTableItem = ({
  item: {
    school_name,
    class_student_cnt,
    admission_rate,
    distance,
    centerpoint: targetCenterPoint,
  },
  prevMarker,
  setPrevMarker,
  onClickSetActiveValue,
  activeValue,
  resetActiveValue,
}) => {
  const { onClick } = useSummarySchoolAmemities()
  const isMobile = useIsMobile()

  const onClickItem = () => {
    if (isMobile) return
    onClickSetActiveValue(school_name)
    onClick({
      prevMarker,
      setPrevMarker,
      targetCenterPoint,
      iconSrc: SchoolIcon,
      activeValue,
      name: school_name,
      resetActiveValue,
    })
  }

  return (
    <StyledTr active={activeValue == school_name} onClick={onClickItem}>
      <td>{school_name}</td>
      <td className="center">{class_student_cnt}명</td>
      <td className="center">
        {parseFloat((admission_rate * 100).toFixed(2))}%
      </td>
      <td className="right">{getDistance(distance)}</td>
    </StyledTr>
  )
}

const StyledTr = styled.tr`
  cursor: pointer;

  td {
    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }
  &:hover {
    background-color: ${theme.colors.gray[100]};
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.primary[50]};
    font-weight: 600;
  `}
`

export default SummarySchoolTableItem
