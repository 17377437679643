import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import { feeOptions } from '../../optionsFee'
import React from 'react'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 관리비 부과 정보 - 확인불가사유
 */
const NoteAdColumnFeeUnableVerifyReason = () => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const list = feeOptions['확인불가세부내역']

  return (
    <ColumnSet>
      <ColumnTitle required>세부내역타입</ColumnTitle>
      <ColumnVal gap={12}>
        {Object.entries(list).map(([key, value], i) => {
          return (
            <React.Fragment key={i}>
              <RadioButton
                size="small"
                fit
                label={value}
                {...register(
                  'administrationCostInfo.unableCheckDetails.detailCodeType',
                )}
                value={key}
                onChange={(e) => {
                  onUpdate(
                    'administrationCostInfo.unableCheckDetails.detailCodeType',
                    e.target.value,
                  )
                }}
                name="fee_etc_fee_details_unable"
              />
            </React.Fragment>
          )
        })}
        <ValidateWarn>
          {
            errors?.administrationCostInfo?.unableCheckDetails
              ?.detailCodeType?.message
          }
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnFeeUnableVerifyReason
