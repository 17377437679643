import { Tag } from 'components/design'
import styled from 'styled-components'

const TagColors = {
  가축사육제한구역: 'red',
  상업구역: 'purple',
  도시지역: 'blue',
  광장: 'blue',
  교육환경보호구역: 'orange',
  어린이공원: 'green',
  개발행위허가제한지역: 'red',
}

const SummaryLegalRawTags = ({ others }) => {
  return (
    <Wrapper>
      {others.map((item, idx) => (
        <Tag key={idx} color={TagColors?.[item]} className="name">
          {item}
        </Tag>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export default SummaryLegalRawTags
