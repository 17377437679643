import axios from 'axios'

export const getFolders = async (
  { _rx_csrf_token, channelSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/folder/getFolders',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
    },
    options,
  )

export const getFolder = async (
  { _rx_csrf_token, channelSrl, folderSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/folder/getFolder',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
    },
    options,
  )

export const saveFolder = async (
  { _rx_csrf_token, channelSrl, name, icon },
  options,
) =>
  await axios.post(
    '/jmapnote/api/folder/save',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      name,
      icon,
    },
    options,
  )

export const modifyFolder = async (
  { _rx_csrf_token, channelSrl, folderSrl, name, icon },
  options,
) =>
  await axios.post(
    '/jmapnote/api/folder/modify',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
      name,
      icon,
    },
    options,
  )

export const removeFolder = async (
  { _rx_csrf_token, channelSrl, folderSrl },
  options,
) =>
  await axios.post(
    '/jmapnote/api/folder/remove',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srl: folderSrl,
    },
    options,
  )

export const sortFolders = async (
  { _rx_csrf_token, channelSrl, folderSrlArr },
  options,
) =>
  await axios.post(
    '/jmapnote/api/folder/sort',
    {
      _rx_csrf_token,
      channel_srl: channelSrl,
      folder_srls: folderSrlArr,
    },
    options,
  )
