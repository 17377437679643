import styled from 'styled-components'
import { SectionWrapper } from './LandingStyle'
import theme, { media } from 'lib/styles/theme'
import ImgSrc from 'assets/images/note/landing/deunggi.png'

const LandingBanner = ({ onClickStart }) => {
  return (
    <SectionWrapper size="small">
      <Wrapper>
        <LeftWrapper>
          <Text>
            지금 가입하고,
            <br />
            <span>등기열람권 1개</span>를<br />
            받아 보세요!
          </Text>
          <Button onClick={onClickStart}>
            회원가입 하러 가기
            <i className="fal fa-angle-double-right"></i>
          </Button>
        </LeftWrapper>
        <Img src={ImgSrc} />
      </Wrapper>
    </SectionWrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 90%;
  max-width: 1390px;
  height: 708px;
  margin: 0 auto;
  background: linear-gradient(103deg, #4581f1 0.38%, #6bd2f1 102.87%);
  border-radius: 20px;

  ${media.smallPc`
    height: 500px;
  `}

  ${media.mediumM`
    height: 350px;
  `}

  
  ${media.mediumS`
    height: 300px;
  `}
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 75px;
  position: absolute;
  left: 76px;
  top: 50%;
  transform: translateY(-50%);

  ${media.smallPc`
    gap: 40px;
  `}

  ${media.mediumM`
    left: 40px;
  `}

  ${media.mediumS`
    left: 24px;
  `}
`

const Text = styled.div`
  font-size: 56px;
  line-height: 140%;
  font-weight: 800;
  text-align: left;
  color: ${theme.colors.base.white};

  span {
    color: #a4ff95;
  }

  ${media.smallPc`
    font-size: 46px;
  `}

  ${media.mediumM`
    font-size: 40px;
`}
    
    ${media.mediumS`
    font-size: 30px;
`}
`

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  padding: 12px 38px;
  border-radius: 45px;
  color: #4f95f1;
  background-color: ${theme.colors.base.white};
  font-size: 40px;
  font-weight: 600;
  line-height: 140%;

  i {
    font-size: 56px;
  }

  ${media.smallPc`
    font-size: 28px;
    padding: 10px 28px;
    
    i {
        font-size: 40px;
    }
  `}

  ${media.mediumM`
    font-size: 22px;

    i {
        font-size: 34px;
    }
`}

    ${media.mediumS`
        gap: 18px;
        font-size: 18px;

        i {
            font-size: 28px;
        }
    `}
`

const Img = styled.img`
  position: absolute;
  right: 25px;
  bottom: 0;
  width: 60%;

  ${media.mediumM`
    width: 55%;
    right: 10px;
    `}

  ${media.mediumS`
        display: none;
    `}
`

export default LandingBanner
