import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import ImgSrc from 'assets/images/dashbord_dongho_banner.png'
import { Tag, Wrapper } from './NoteDashboardDeunggiBanner'

/**
 * 매물노트 - 대시보드
 * 최상단 등기부 배너
 */
const NoteDashboardDonghoBanner = () => {
  return (
    <Wrapper background="#6048f0">
      <Tag background="#F2FF60" color="#6B57E6">
        OPEN
      </Tag>
      <ContentWrapper>
        <Text>
          동호관리 <span>오픈!</span>
          <br />
        </Text>
        <Desc>
          <span>점유형태</span> 정보,{' '}
          <span>계약 만기가 다가오는 세대</span>를 <br />
          선점할 수 있는 기회를 놓치지 마세요.
        </Desc>
      </ContentWrapper>

      <Img src={ImgSrc} />
    </Wrapper>
  )
}

const Text = styled.div`
  font-size: 26px;
  font-weight: 800;
  color: ${theme.colors.base.white};
  line-height: 125%;

  strong {
    color: #f2ff60;
    font-weight: 700;
    margin-left: 6px;
  }

  ${media.smallPc`
    font-size: 26px;
  `}

  ${media.mediumS`
    font-size: 16px;
  `}
`
const ContentWrapper = styled.div`
  position: absolute;
  top: 35px;
  left: 36px;

  ${media.mediumS`
    top: 10px;
    left: 16px;
  `}
`

const Img = styled.img`
  position: absolute;
  width: 280px;
  height: auto;
  right: 60px;
  bottom: 0px;

  ${media.smallPc`
    width: 200px;
    right: 60px;
    bottom: 10px;
  `}

  ${media.mediumS`
    display: none;
  `}
`

const Desc = styled.div`
  margin-top: 10px;
  color: ${theme.colors.base.white};
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  span {
    color: #f2ff60;
  }

  ${media.mediumS`
    margin-top: 6px;
    font-size: 12px;
    line-height: 120%;
  `}
`

export default NoteDashboardDonghoBanner
