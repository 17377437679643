import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import MapContext from 'contexts/map'
import CustomOverlay from './MapFavoriteCustomOverlay'
import AddressViewMobile from 'components/jmapnote/addressView/AddressViewMobile'
import MapFavoriteMarkerMini from './MapFavoriteMarkerMini'
import useIsMobile from 'lib/hooks/useIsMobile'
import { getNotes } from 'lib/api/note/noteApi'
import useAxios from 'lib/hooks/useAxios'
import BaseContext from 'contexts/baseContext'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  removeManuallyCloseMarker,
  setClickedMarker,
  setOpenedMarker,
  resetSpecificOpenedMarker,
} from 'modules/note'
import MapFavoriteNoteDetail from './MapFavoriteNoteDetail'
import { useNoteMarker } from 'lib/hooks/useNoteMarker'

const MapFavoriteMarkerItem = ({
  item,
  isSpecific = false,
  resetPolygon,
  resetMarkerFlag = false,
  setResetMarkerFlag,
}) => {
  const { searchNoteMarkers } = useNoteMarker()
  const isMobile = useIsMobile()
  const { kakao } = window
  const { state } = useContext(MapContext)
  const { map, viewState } = state
  const {
    actions: { setIsFold },
  } = useContext(BaseContext)

  const dispatch = useDispatch()
  const openedMarker = useSelector((state) => state.note.openedMarker)
  const favoriteData = item
  const [onRequest, loading, data, error, reset, source] =
    useAxios(getNotes)
  const [notes, setNotes] = useState([])

  const history = useHistory()

  const markerRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [payload, setPayload] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isVisibleNoteDetail, setIsVisibleNoteDetail] = useState(false)
  const [mobileData, setMobileData] = useState()
  const [Markerclick, setMarkerClick] = useState(false)
  const path = `/note/${favoriteData.channel_srl}/${favoriteData.folder_srl}/${favoriteData.address_srl}`
  const specificOpenedMarker = useSelector(
    (state) => state.note.specificOpenedMarker,
  )
  const isOpened = useMemo(
    () =>
      openedMarker && item && openedMarker.includes(item.address_srl),
    [item, openedMarker],
  )

  const match = useRouteMatch({
    path,
  })

  useEffect(() => {
    return () => {
      setVisible(false)
    }
  }, [])

  useEffect(() => {
    if (!match && viewState.level > 1 && !visible) {
      setIsExpanded(false)
      return
    }
    setIsExpanded(true)
  }, [history.location, viewState, visible])

  useEffect(() => {
    const originPayload = getPayload()
    setPayload(originPayload)
  }, [item, isExpanded])

  const getPayload = useCallback(() => {
    const position = [item.latitude, item.longitude]

    return {
      position: new kakao.maps.LatLng(...position),
      yAnchor: 1,
    }
  }, [item])

  const onInit = useCallback(
    (marker) => {
      if (isExpanded) {
        marker.setZIndex(2)
      } else {
        marker.setZIndex(1)
      }
    },
    [isExpanded],
  )

  /**
   * 매물마커 클릭
   */
  const onClickMarker = () => {
    setMarkerClick(true)

    if (!isSpecific) {
      dispatch(resetSpecificOpenedMarker())
    }

    if (!openedMarker.includes(item.address_srl)) {
      dispatch(setOpenedMarker(item.address_srl))
    } else {
      if (isMobile) {
        const { position } = getPayload()
        map.panTo(position)
        setVisible(true)
        // 해당 주소에 속한 매물 조회 및 세팅
        onRequest(
          {
            channel_srl: favoriteData.channel_srl,
            folder_srl: favoriteData.folder_srl,
            address_srl: favoriteData.address_srl,
          },
          source.token,
        )
      } else {
        if (item.address_srl) {
          // 매물 마커 클릭 시 중점 이동
          map.setCenter(payload.position)
        }
        // Summary 패널 활성화
        setIsFold(false)

        // 3뎁스
        setIsVisibleNoteDetail(true)
      }
    }

    dispatch(setClickedMarker(item.address_srl))
    dispatch(removeManuallyCloseMarker(item.address_srl))
  }

  useEffect(() => {
    if (data?.message !== 'success') return

    setNotes(data.result)
  }, [data])

  // 매물이 없는 주소는 표시하지 않는다. 필터의 적용 대상이 주소가 아닌 매물이기 때문.
  if (item?.note_count === 0) return null

  return (
    <>
      <CustomOverlay
        overlayRef={markerRef}
        payload={payload}
        onInit={onInit}
        addressSrl={item.address_srl}
        ZIndex={isOpened ? 10000 : 9999}
        draggable
        resetMarkerFlag={resetMarkerFlag}
        setResetMarkerFlag={setResetMarkerFlag}
      >
        <MarkerWrapper
          className={isSpecific && `note-marker-bounce`}
          hide={
            !isSpecific &&
            specificOpenedMarker?.address_id == item.address_id
          }
          ref={markerRef}
        >
          <MapFavoriteMarkerMini
            item={item}
            contractType={item?.contract_type || ''}
            onClick={onClickMarker}
          />
        </MarkerWrapper>
      </CustomOverlay>

      {/* 모바일) 주소목록 */}
      <AddressViewMobile
        Markerclick={Markerclick}
        setMobileData={setMobileData}
        setIsVisibleNoteDetail={setIsVisibleNoteDetail}
        visible={visible}
        channelSrl={item?.channel_srl}
        folderSrl={item?.folder_srl}
        addressSrl={item?.address_srl}
        notes={notes}
        onClose={() => setVisible(false)}
      />
      {/* pc) 매물상세 */}
      <MapFavoriteNoteDetail
        visible={isVisibleNoteDetail}
        data={isMobile ? mobileData : favoriteData}
        onClose={() => {
          resetPolygon && resetPolygon()
          searchNoteMarkers()
          setIsVisibleNoteDetail(false)
        }}
      />
    </>
  )
}
const bounceAnimation = keyframes`
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`

const MarkerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ hide }) =>
    hide &&
    `
      visibility: hidden;
    `};
  &.note-marker-bounce {
    animation: ${bounceAnimation} 2s ease 5;
  }
`

export default React.memo(MapFavoriteMarkerItem)
