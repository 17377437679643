import theme, { media } from 'lib/styles/theme'
import NotificationPage from 'pages/NotificationPage'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import { forwardRef } from 'react'

const NoteAdNotificationSlide = (
  { listData, visible = false },
  ref,
) => {
  const root = document.getElementById('fixed-layer')

  return ReactDOM.createPortal(
    <Wrapper visible={visible} ref={ref}>
      <NotificationPage
        isNote
        mobileHeader={false}
        foldBtn={false}
        listData={listData}
      />
    </Wrapper>,
    root,
  )
}

const Wrapper = styled.div`
  & > article {
    z-index: 16;
    left: -${theme.base.navWidth + theme.base.container}px;
    transition: left 0.2s cubic-bezier(0.22, 1, 0.36, 1);

    ${({ visible }) =>
      visible &&
      `
        left: ${theme.base.navWidth}px;
    `}

    ${media.mediumS`
      left: -100%;
      //top: ${theme.base.headerHeight}px;
      padding-top: 0;
      height: calc(100vh + ${theme.base.headerHeight}px);
      ${({ visible }) =>
        visible &&
        `
          left: 0;
      `}
    `}
  }
`

export default forwardRef(NoteAdNotificationSlide)
