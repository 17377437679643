import { message } from 'antd'
import { modifyChannelUserStatus } from 'lib/api/note/channelApi'
import useAxios from 'lib/hooks/useAxios'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function useInviteAccept(targetUrl = '') {
  const [isAccepted, setIsAccepted] = useState(false)
  const channels = useSelector((state) => state.channel.channels)

  const [onRequest, loading, data] = useAxios(modifyChannelUserStatus)
  const [status, setStatus] = useState(1)

  const regText = '/map/note/v2/notes/'
  const isInvite = targetUrl.includes(regText)

  const getInviteChannelSrl = () => {
    if (!isInvite) return ''

    return targetUrl.replace(regText, '')
  }

  useEffect(() => {
    if (!isInvite) return

    if (
      channels.find(
        (e) => e.channel_srl.toString() === getInviteChannelSrl(),
      )
    ) {
      setIsAccepted(true)
    } else {
      setIsAccepted(false)
    }
  }, [targetUrl])

  /**
   * modifyChannelUserStatus 응답값이 정상인 경우에 화면 이동
   */
  useEffect(() => {
    // console.log('status: ', status)
    // 수정이 정상적으로 처리된 경우에만 이동
    if (data?.message === 'success') {
      if (parseInt(status) === 2) {
        setIsAccepted(true)
        window.location.href = targetUrl?.replace('amp;', '')
      } else if (parseInt(status) === 3) {
        message.info('채널 초대를 거절했습니다.')
        window.location.reload() // 새로고침
      }
    }
  }, [data, status])

  const handleChangeStatus = (status, notify) => {
    const channelSrl = getInviteChannelSrl()
    if (!channelSrl || channelSrl === '') return
    onRequest({ channelSrl: channelSrl, status, notify })
    setStatus(status)
  }

  return {
    isInvite,
    isAccepted,
    handleChangeStatus,
    getInviteChannelSrl,
  }
}
