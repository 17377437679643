import PropTypes from 'prop-types'
import theme from 'lib/styles/theme'
import styled from 'styled-components'

const StyledDivider = styled.div`
  margin-top: 1.25rem;

  ${({ small }) =>
    small &&
    `
    margin-top: 0.875rem;
  `}

  ${({ xs }) =>
    xs &&
    `
    margin-top: 0.75rem;
  `}

  ${({ border }) =>
    border &&
    `
    margin-top: 0;
    border-bottom: 1px solid ${theme.colors.gray[100]};
  `}
`

const ListItemDivider = ({
  small = false,
  xs = false,
  border = false,
}) => <StyledDivider small={small} xs={xs} border={border} />

ListItemDivider.propTypes = {
  small: PropTypes.bool,
  xs: PropTypes.bool,
  border: PropTypes.bool,
}

export default ListItemDivider
