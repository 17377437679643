import { useSelector } from 'react-redux'

/**
 * 올인원 요금제인지 확인
 */
function useIsAllInOne() {
  const planInfo = useSelector((state) => state.auth.planInfo)

  const isPlanAllInOne = planInfo?.use_allinone == 'Y'
  const isOwner = planInfo?.is_plan_owner == 'Y'
  const isEmp = planInfo?.is_plan_owner == 'N'

  const isAllInOne = isOwner ? isPlanAllInOne : isPlanAllInOne && isEmp

  return {
    isAllInOne, //올인원 플랜 여부
    isBoss: isOwner && isAllInOne, //직위가 대표인가
    isEmp: isEmp && isAllInOne, //올인원의 오너가 아님 = 직원임
    isOwner: isOwner && isAllInOne, //올인원의 오너임
  }
}

export default useIsAllInOne
