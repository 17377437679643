import NotePropertySelector from 'components/jmapnotev2/elements/NotePropertySelector'
import {
  ActiveCellStyle,
  StyledSelectView,
} from 'components/jmapnotev2/style/NoteGridStyle'
import useCustomSingleCell from 'lib/hooks/notev2/useCustomSingleCell'
import useModifyAd from 'lib/hooks/notev2/useModifyAd'
import { updateGridDatas } from 'lib/utils/notev2/gridUtil'
import { getSimpleSelectboxOptions } from 'lib/utils/notev2/util'
import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * 광고관리 그리드
 * 매물종류
 */
const CustomPropertyTypeCell = ({ node, data }) => {
  const noteOptions = useSelector((state) => state.notev2.noteOptions)
  const [onModify] = useModifyAd()

  const options = useRef(null)
  const {
    isEditable,
    setIsEditable,
    onDoubleClickSingleCell,
    onKeyDownSingleCell,
    isReversePosition,
  } = useCustomSingleCell(node?.data?.status, node?.rowIndex, 300)

  const selectRef = useRef(null)
  const viewRef = useRef(null)

  useEffect(() => {
    //수정모드가 변경되면 방향키 제어를 위해 그리드 데이터의 state도 업데이트 해준다
    updateGridDatas({
      currentRowNode: node,
      newData: {
        ...node.data,
        state: {
          ...data.state,
          isEditingPropertyType: isEditable,
        },
      },
    })

    //수정 모드가 되면 자동 포커스
    if (isEditable) {
      selectRef.current?.focus()
    }
  }, [isEditable])

  useEffect(() => {
    if (!noteOptions) return

    options.current = getSimpleSelectboxOptions(noteOptions.note_types)
  }, [noteOptions])

  /**
   * selectbox change
   */
  const onChange = useCallback(
    (name) => {
      //      TODO: 데이터 업데이트
      onModify({
        data: node.data,
        updateData: { property_type: name },
        currentRowNode: node,
      })
      //수정모드 해제
      setIsEditable(false)
      viewRef.current.focus()
    },
    [node],
  )

  const onClose = () => {
    setIsEditable(false)
    viewRef.current?.focus()
  }

  const getShortName = () => {
    if (data.property_type == '오피스텔 분양권') {
      return '분양권(오)'
    } else if (data.property_type == '아파트 분양권') {
      return '분양권(아)'
    }
    return data.property_type
  }
  if (data.ad_srl == '신규') return <></>

  return (
    <Wrapper tabIndex={-1}>
      <StyledSelectView
        ref={viewRef}
        tabIndex={0}
        onDoubleClick={onDoubleClickSingleCell}
        onKeyDown={onKeyDownSingleCell}
        onClick={() => setIsEditable(false)}
      >
        {getShortName()}
      </StyledSelectView>
      {isEditable && data.already_created != 'Y' && (
        <SelectorWrapper>
          {data.property_type}

          <SelectorBlockWrapper reverse={isReversePosition ? 'Y' : 'N'}>
            <NotePropertySelector
              visible={true}
              setIsVisible={setIsEditable}
              value={data.property_type}
              onChange={onChange}
              onClose={onClose}
            />
          </SelectorBlockWrapper>
        </SelectorWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${({ threeStepState }) =>
    threeStepState == true &&
    `
  padding:0;
  border: 5px solid transparent;
  background-image: linear-gradient(#fff, #fff),linear-gradient(to right, #00d8f8, #5a87ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
   `}
`

const SelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${ActiveCellStyle}
`

const SelectorBlockWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: -43px;
  top: 2px;

  ${({ reverse }) =>
    reverse == 'Y' &&
    `
    top: -320px;
  `}
`

export default React.memo(CustomPropertyTypeCell)
