import { forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  setContractStepState,
  setGongbuStepState,
  setOnBoardingStart,
  setThreeDepthStart,
} from 'modules/onBoarding'
import OnBoarding from '../OnBoarding/OnBoarding'
import NoteDetailGongbuIssuance from './NoteDetailGongbuIssuance'
import NoteDetailGongbuTab from './NoteDetailGongbuTab'
import NoteDocsLayerBuildingAndLand from '../NoteDocs/Layer/NoteDocsLayerBuildingAndLand'
import { useFormContext } from 'react-hook-form'

const NoteDetailGongbu = ({ onChangeTab }, ref) => {
  const dispatch = useDispatch()

  const { watch } = useFormContext()
  const noteSrl = watch('note_srl')
  const dong = watch('dong')
  const [activeTab, setActiveTab] = useState(1)

  ////온보딩 관련 변수
  const [stepsEnabled, setStepsEnalbed] = useState(false)
  const [steps, setSteps] = useState([])

  const threeDepthChannelSrl = useSelector(
    (state) => state.onBoarding.threeDepthChannelSrl,
  )
  const gongbuStepState = useSelector(
    (state) => state.onBoarding.gongbuStepState,
  )
  //
  //온보딩 관련 함수
  const onExit = () => {
    setStepsEnalbed(false)
    dispatch(setOnBoardingStart(false))
    dispatch(setGongbuStepState(false))
  }

  useEffect(() => {
    if (threeDepthChannelSrl != 0 && gongbuStepState) {
      const delay = setTimeout(() => {
        setStepsEnalbed(true)
      }, 100)
    }
  }, [gongbuStepState])

  const onClickTab = (val) => {
    setActiveTab(val)
  }

  return (
    <Wrapper className="gongbu-class">
      <OnBoarding
        enabled={stepsEnabled}
        steps={steps}
        setSteps={setSteps}
        onExit={onExit}
        options={{
          hidePrev: true,
          nextLabel: '확인',
          prevLabel: '이전',
          doneLabel: '확인',
          showBullets: false,
          buttonClass: 'customButton',
        }}
        onComplete={() => {
          onChangeTab(4)
          dispatch(setGongbuStepState(false))

          const delay = setTimeout(() => {
            setStepsEnalbed(false)
            dispatch(setContractStepState(true))

            dispatch(setThreeDepthStart(true))
          }, 100)
        }}
      ></OnBoarding>
      <NoteDetailGongbuTab
        activeTab={activeTab}
        onClickTab={onClickTab}
      />
      {activeTab == 1 && <NoteDetailGongbuIssuance scrollRef={ref} />}
      {activeTab == 2 && (
        <NoteDocsLayerBuildingAndLand
          noteSrl={noteSrl}
          type="note"
          dong={dong}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  font-size: 14px;
  padding-bottom: 100px;
`

export default forwardRef(NoteDetailGongbu)
