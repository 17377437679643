import RadioButton from '../../elements/RadioButton'
import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 기본정보 - 큰길가 여부
 */
const NoteAdColumnMainRoad = ({ half = true }) => {
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const mainRoad = watch(`oneroomInfo.oneroomFeature.mainRoad`)

  return (
    <ColumnSet half={half}>
      <ColumnTitle required>큰 길가</ColumnTitle>
      <ColumnVal gap={12}>
        <RadioButton
          fit
          label="예"
          name="oneroom_main_road"
          value="Y"
          checked={mainRoad === 'Y'}
          {...register('oneroomInfo.oneroomFeature.mainRoad')}
          onChange={(e) => {
            onUpdate(
              'oneroomInfo.oneroomFeature.mainRoad',
              e.target.value,
            )
          }}
        />
        <RadioButton
          fit
          label="아니오"
          name="oneroom_main_road"
          value="N"
          {...register('oneroomInfo.oneroomFeature.mainRoad')}
          checked={mainRoad === 'N'}
          onChange={(e) => {
            onUpdate(
              'oneroomInfo.oneroomFeature.mainRoad',
              e.target.value,
            )
          }}
        />
        <ValidateWarn>
          {errors?.oneroomInfo?.oneroomFeature?.mainRoad?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnMainRoad
