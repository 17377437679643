import { message } from 'antd'
import { Button } from 'components/design'
import {
  Row,
  Table,
  TableHeader,
  TableValue,
  TableWrapper,
  TableTitle,
} from 'components/jmapnotev2/style/NoteLayerStyle'
import MapContext from 'contexts/map'
import {
  isDonghoAreaUse,
  isFloorAreaUse,
  isLandAreaUse,
} from 'lib/utils/notev2/note2Constants'
import priceFormat from 'lib/utils/priceFormat'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

const NoteDocsLayerNoteDetailInfo = () => {
  const { setValue, getValues, watch } = useFormContext()
  const channelInfo = useSelector((state) => state.channel.channelInfo)

  const history = useHistory()

  const building_name = watch('building_name')
  const dong = watch('dong')
  const ho = watch('ho')
  const property_type = watch('property_type')

  const contract_type = watch('contract_type')
  const jeonse_deposit = watch('jeonse_deposit')
  const sale_price = watch('sale_price')
  const deposit_value = watch('deposit')
  const rent_value = watch('rent')

  const lending = watch('lending')
  const lending_mss = watch('lending_mss')
  const lending_insurance = watch('lending_insurance')

  const total_floor_count = watch('total_floor_count')
  const floor_types = watch('floor_types')
  const contacts = watch('contacts')
  const move_date = watch('move_date')
  const direction = watch('direction')
  const direction_criteria = watch('direction_criteria')
  const room_count = watch('room_count')
  const bathroom_count = watch('bathroom_count')
  const lessee_contract_type = watch('lessee_contract_type')
  const note_srl = watch('note_srl')
  const address_srl = watch('address_srl')
  const channel_srl = watch('channel_srl')

  const office_name = watch('office_name')
  const room_options = watch('room_options')

  const parking = watch('parking') == 'Y' ? '주차가능' : ''
  const elevator = watch('elevator') == 'Y' ? '엘리베이터' : ''
  const animal = watch('animal') == 'Y' ? '반려동물' : ''
  const balcony = watch('balcony') == 'Y' ? '베란다' : ''
  const optionsArr = [parking, elevator, animal, balcony]
  const support =
    optionsArr.filter((item) => item).length > 0
      ? optionsArr.filter((item) => item).toString()
      : ''

  const land_area = parseFloat(watch('land_area') || 0)
  const exclusive_area = parseFloat(watch('exclusive_area') || 0)
  const contract_area = parseFloat(watch('contract_area') || 0)
  const building_area = parseFloat(watch('building_area') || 0)
  const total_floor_area = parseFloat(watch('total_floor_area') || 0)

  const {
    state: { isSquareMeter },
  } = useContext(MapContext)

  const areaText = () => {
    if (isDonghoAreaUse.includes(property_type)) {
      return `공급/${property_type == '오피스텔' ? '계약' : '전용'}`
    } else if (isFloorAreaUse.includes(property_type)) {
      if (
        (property_type == '다가구주택' ||
          property_type == '단독주택') &&
        (dong || ho)
      ) {
        return '공급/건축'
      } else {
        return '연면적/건축'
      }
    } else if (isLandAreaUse.includes(property_type)) {
      if (property_type == '기타') {
        return '대지/건축'
      } else {
        return '대지'
      }
    } else {
      return '연면적'
    }
  }

  const area = () => {
    const unit = isSquareMeter ? '㎡' : '평'
    if (isDonghoAreaUse.includes(property_type)) {
      const formatExclusive = isSquareMeter
        ? parseFloat(exclusive_area)
        : parseFloat((exclusive_area / 3.3057)?.toFixed(2)) || ''
      const formatContract = isSquareMeter
        ? parseFloat(contract_area)
        : parseFloat((contract_area / 3.3057)?.toFixed(2)) || ''

      return formatExclusive
        ? `${formatExclusive || ''}${formatExclusive ? unit : ''}/${
            formatContract || ''
          }${formatContract ? unit : ''}`
        : formatContract
        ? `${property_type == '오피스텔' ? '계약' : '전용'} ${
            formatContract || ''
          }${formatContract ? unit : ''}`
        : ''
    } else if (isFloorAreaUse.includes(property_type)) {
      if (
        (property_type == '다가구주택' ||
          property_type == '단독주택') &&
        (dong || ho)
      ) {
        const formatExclusive = isSquareMeter
          ? parseFloat(exclusive_area) || ''
          : parseFloat((exclusive_area / 3.3057)?.toFixed(2)) || ''
        const formatContract = isSquareMeter
          ? parseFloat(contract_area) || ''
          : parseFloat((contract_area / 3.3057)?.toFixed(2)) || ''

        return formatExclusive
          ? `${formatExclusive}${
              formatExclusive ? unit : ''
            }/${formatContract}${formatContract ? unit : ''}`
          : formatContract
          ? `전용 ${formatContract}${formatContract ? unit : ''}`
          : ''
      } else {
        const formatTotalFloor = isSquareMeter
          ? parseFloat(total_floor_area) || ''
          : parseFloat((total_floor_area / 3.3057)?.toFixed(2)) || ''
        const formatBuilding = isSquareMeter
          ? parseFloat(building_area) || ''
          : parseFloat((building_area / 3.3057)?.toFixed(2)) || ''

        return formatTotalFloor
          ? `${formatTotalFloor}${
              formatTotalFloor ? unit : ''
            }/${formatBuilding}${formatBuilding ? unit : ''}`
          : formatBuilding
          ? `건물 ${formatBuilding}${formatBuilding ? unit : ''}`
          : ''
      }
    } else if (isLandAreaUse.includes(property_type)) {
      const formatLand = isSquareMeter
        ? parseFloat(land_area) || ''
        : parseFloat((land_area / 3.3057)?.toFixed(2)) || ''
      const formatBuilding = isSquareMeter
        ? parseFloat(building_area) || ''
        : parseFloat((building_area / 3.3057)?.toFixed(2)) || ''

      if (property_type == '기타') {
        return `${formatLand}${
          formatLand ? unit : ''
        }/${formatBuilding}${formatBuilding ? unit : ''}`
      } else {
        return `${formatLand}${formatLand ? unit : ''}`
      }
    } else {
      const formatTotalFloor = isSquareMeter
        ? parseFloat(total_floor_area) || ''
        : parseFloat((total_floor_area / 3.3057)?.toFixed(2)) || ''
      return `${formatTotalFloor}${formatTotalFloor ? unit : ''}`
    }
  }

  const onClickEdit = () => {
    if (!channel_srl || !note_srl) {
      message.error('채널 또는 매물정보가 잘못되었습니다.')
      return
    }
    history.push(
      `/note/v2/notes/${channel_srl}/${address_srl}/${note_srl}`,
    )
  }

  return (
    <TableWrapper>
      <HeaderWrapper>
        <span>매물정보</span>
        {channelInfo?.member_type != 'readonly' && (
          <Button color="blue" onClick={onClickEdit}>
            매물 수정하러 가기
          </Button>
        )}
      </HeaderWrapper>
      <Table>
        <Row>
          <TableHeader>매물번호</TableHeader>
          <TableValue>{note_srl}</TableValue>
        </Row>
        <Row>
          <TableHeader>매물이름</TableHeader>
          <TableValue>{building_name}</TableValue>
        </Row>
        <Row>
          <TableHeader>매물유형</TableHeader>
          <TableValue>{property_type}</TableValue>
        </Row>
        <Row>
          <TableHeader>동/호</TableHeader>
          <TableValue>
            {dong ? `${dong}` : ''}
            {ho ? `${dong && ho ? '/' : ''}${ho}` : ''}
          </TableValue>
        </Row>
        <Row>
          <TableHeader>거래정보</TableHeader>
          <TableValue>
            {contract_type?.includes('매매') && (
              <>
                매매:&nbsp;
                {priceFormat(sale_price * 10000).replace('원', '')}원
                <br />
              </>
            )}
            {contract_type?.includes('전세') && (
              <>
                전세:&nbsp;
                {priceFormat(jeonse_deposit * 10000).replace('원', '')}
                원
                <br />
              </>
            )}
            {contract_type?.includes('월세') &&
              `보증금: ${priceFormat(
                deposit_value * 10000,
              )}원, 월세: ${priceFormat(rent_value).replace(
                '원',
                '',
              )}원`}
          </TableValue>
        </Row>
        {contract_type?.includes('전세') && (
          <Row>
            <TableHeader>전세대출</TableHeader>
            <TableValue>
              {lending && '전세대출 가능'}
              {lending_mss && ', 중소기업 청년전세대출 가능'}
              {lending_insurance && ', 보증보험 가입 가능'}
            </TableValue>
          </Row>
        )}
        <Row>
          <TableHeader>{areaText()}</TableHeader>
          <TableValue>{area()}</TableValue>
        </Row>
        <Row>
          <TableHeader>층수</TableHeader>
          <TableValue>
            {floor_types
              ? floor_types.includes('층')
                ? `해당층수: ${floor_types},`
                : `해당층수: ${floor_types}층,`
              : ''}
            {total_floor_count
              ? `전체층수: ${total_floor_count}층`
              : ''}
          </TableValue>
        </Row>
        <Row>
          <TableHeader>연락처</TableHeader>
          <TableValue>
            {contacts &&
              contacts.length > 0 &&
              contacts.map(({ alias, numbers, type }) => {
                return (
                  <div>
                    {type == '집주인' && (
                      <span>
                        임대인: {alias}, {numbers[0].number}
                        <br />
                      </span>
                    )}
                    {type == '매수인' &&
                      `임차인: ${alias}, ${numbers[0].number}`}
                  </div>
                )
              })}
          </TableValue>
        </Row>
        <Row>
          <TableHeader>입주가능일</TableHeader>
          <TableValue>
            {(move_date && move_date != '0000-00-00') || ''}
          </TableValue>
        </Row>
        <Row>
          <TableHeader>방향</TableHeader>
          <TableValue>
            {direction && `${direction}, `}
            {direction_criteria} 기준
          </TableValue>
        </Row>
        <Row>
          <TableHeader>방/욕실</TableHeader>
          <TableValue>
            {room_count ? `방수: ${room_count}개, ` : ''}
            {bathroom_count ? `욕실수: ${bathroom_count}개` : ''}
          </TableValue>
        </Row>
        <Row>
          <TableHeader>
            기존 세입자
            <br />
            계약 정보
          </TableHeader>
          <TableValue>{lessee_contract_type}</TableValue>
        </Row>
        <Row>
          <TableHeader>지원사항</TableHeader>
          <TableValue>{support}</TableValue>
        </Row>
        <Row>
          <TableHeader>추가옵션</TableHeader>
          <TableValue>{room_options}</TableValue>
        </Row>
        <Row noborderbottom>
          <TableHeader>받은 공인중개사사무소</TableHeader>
          <TableValue>{office_name}</TableValue>
        </Row>
      </Table>
    </TableWrapper>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }
`

export default NoteDocsLayerNoteDetailInfo
