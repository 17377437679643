import Axios from 'axios'

/**
 * 주택 단지 실거래
 * @param {object} payload
 * @returns
 */
export const getHousingComplexRealpurchases = async (payload) =>
  await Axios.get('/map/api/getHousingComplexRealpurchases', {
    params: payload,
  })
