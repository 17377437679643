import styled from 'styled-components'
import SummaryJeonyouListItem from './SummaryJeonyouListItem'
import theme, { media } from 'lib/styles/theme'
import { Empty } from 'components/design'
import { useEffect } from 'react'

const SummaryJeonyouList = ({ data, title, isSelected }) => {
  return (
    <Wrapper mt={title == '공용부분' ? 26 : 42}>
      <Title>{title}</Title>
      <StyledTable>
        <thead>
          <tr>
            <th className="center">구분</th>
            <th className="center">층별</th>
            <th>용도</th>
            <th className="right">면적</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map(
              (
                {
                  excluded_shared_sep_code_name,
                  floor_num_name,
                  purpose_code_name,
                  area,
                },
                i,
              ) => {
                return (
                  <SummaryJeonyouListItem
                    key={i}
                    division={excluded_shared_sep_code_name}
                    floor={floor_num_name}
                    purpose={purpose_code_name}
                    area={area}
                  />
                )
              },
            )
          ) : isSelected ? (
            <tr>
              <EmptyTd colSpan={4}>
                <Empty>데이터가 없습니다</Empty>
              </EmptyTd>
            </tr>
          ) : (
            <tr>
              <EmptyTd colSpan={4}>
                동 또는 호를 먼저 선택해 주세요
              </EmptyTd>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ mt }) =>
    mt &&
    `
    margin-top: ${mt}px;
  `}
`

export const Title = styled.div`
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 700;
  line-height: 170%;
`

const StyledTable = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: ${theme.colors.gray[100]};
  }

  th {
    padding: 8px;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    color: ${theme.colors.gray[600]};
    font-size: 14px;
    font-weight: 500;

    &:last-child {
      padding-right: 10px;
    }
  }

  th,
  td {
    text-align: left;
    padding: 7px 4px;
    border-bottom: 1px solid ${theme.colors.gray[100]};
    font-size: 13px;

    &:first-child,
    &:nth-child(2) {
      width: 70px;

      ${media.mediumS`
        width: auto;
        max-width: 50px;
      `}
    }

    &:nth-child(3) {
      width: 140px;
      ${media.mediumS`
        width: auto;
        max-width: 90px;
      `}
    }

    &:nth-child(4) {
      word-break: keep-all;

      ${media.mediumS`
        max-width: 50px;
      `}
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }
`

const EmptyTd = styled.td`
  text-align: center !important;
`

export default SummaryJeonyouList
