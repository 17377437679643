import { useSelector } from 'react-redux'
import { LayerGroupItem } from '../MapControl/MapControlStyle'
import { ReactComponent as Icon } from 'assets/icon/map-control/rp.svg'
import { ReactComponent as IconActive } from 'assets/icon/map-control/rp-active.svg'
import { useDispatch } from 'react-redux'
import { setOpenFilterType } from 'modules/map'
import MapFilterFloating from './MapFilterFloating'
import MapFilterRealPriceContainer from 'containers/map/MapFilterRealPriceContainer'
import MapContext, { initialFilterState } from 'contexts/map'
import { useContext, useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'

const nowYear = new Date().getFullYear()

/**
 * 필터 실거래
 */
const MapFilterGroupRealPrice = () => {
  const openFilterType = useSelector(
    (state) => state.map.openFilterType,
  )
  const {
    state: { filterState, layerState },
    actions: { setLayerState, setFilterState },
  } = useContext(MapContext)

  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false) //건물 레이어 active시 옆에 점 찍어주는 state

  /**
   * 필터 초기값
   */
  const initValues = {
    // 실거래가
    price_property_type: [], //건물종류
    real_price: [0, 50], //총액
    real_lunit_price: [0, 50], //토지 면적당 단가
    real_bunit_price: [0, 50], //건물 면적당 단가
    land_type: [], //지목
    // 경매
    auction_date: [2006, nowYear],
    appraisal_price: [0, 50], // 감정가
    winning_bid: [0, 50], // 낙찰가
    contract_date: [2006, nowYear],
  }

  /**
   * 오른쪽 float 실거래 버튼(?) 누름 동작
   */
  const onClick = () => {
    dispatch(
      setOpenFilterType(
        openFilterType == 'realprice' ? null : 'realprice',
      ),
    )
  }

  /**
   * 레이어 on/off event
   */
  useEffect(() => {
    if (!layerState.realPriceActive) {
      setIsActive(false)
    } else {
      setIsActive(true)
    }
  }, [layerState.realPriceActive])

  /**
   * 마커 on/off
   */
  const toggleLayer = () => {
    setLayerState((prev) => {
      return {
        ...prev,
        realPriceActive: layerState['realPriceActive'] ? false : true,
      }
    })
  }

  /**
   * 전체 선택 필터 리셋
   * TODO: initialState const인데 왜 덮어쓰기 되는지 확인 후 하드 코딩 제거
   */
  const resetFilter = () => {
    const nowYear = new Date().getFullYear()
    setFilterState((prev) => {
      return {
        ...prev,
        real_prices: initValues,
      }
    })
  }

  const isButtonActive = useMemo(
    () => openFilterType == 'realprice',
    [openFilterType],
  )

  return (
    <>
      <LayerGroupItem
        dotactive={isActive}
        onClick={onClick}
        active={isButtonActive}
      >
        {isButtonActive ? <IconActive /> : <Icon />}
        <span>실거래</span>
      </LayerGroupItem>
      <MapFilterFloating
        title="실거래"
        isLayerOn={layerState?.realPriceActive}
        toggleLayer={toggleLayer}
        visible={isButtonActive}
        render={<MapFilterRealPriceContainer />}
        onChangeSelectAll={resetFilter}
        isSelectAll={isEqual(initValues, filterState.real_prices)}
      />
    </>
  )
}

export default MapFilterGroupRealPrice
