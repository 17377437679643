import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  InputWidth,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import { getSimpleSelectboxOptionsForObject } from 'lib/utils/notev2/util'
import { options } from '../../options'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import SelectBox from '../../elements/SelectBox'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 검증 방식 - 성별
 */
const NoteAdColumnSex = ({ isDisabled }) => {
  const { onUpdate } = useAdUpdate()

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const osex = watch('verification.osex')

  const list = options['성별']
  const val = list[osex]

  return (
    <ColumnSet half>
      <ColumnTitle required>소유자 성별</ColumnTitle>
      <ColumnVal>
        <SelectBox
          minWidth={InputWidth}
          width={InputWidth}
          value={{
            label: val || '선택',
            value: val,
          }}
          isSearchable
          options={getSimpleSelectboxOptionsForObject(list)}
          placeholder="선택"
          className="basic-single"
          classNamePrefix="select"
          {...register('verification.osex')}
          onChange={(e) => {
            onUpdate('verification.osex', e.value)
          }}
          disabled={isDisabled}
        />

        <ValidateWarn>
          {errors?.verification?.osex?.message}
        </ValidateWarn>
      </ColumnVal>
    </ColumnSet>
  )
}

export default NoteAdColumnSex
