import styled from 'styled-components'
import { ReactComponent as AddIcon } from 'assets/icon/note/detail/add.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon/note/detail/delete.svg'
import theme, { media } from 'lib/styles/theme'

const EditButton = ({ type, onClick, className, size = 'medium' }) => {
  return (
    <Wrapper
      type={type}
      onClick={onClick}
      className={className}
      size={size}
    >
      {size == 'medium' ? (
        type == 'add' ? (
          <AddIcon />
        ) : (
          <DeleteIcon />
        )
      ) : type == 'add' ? (
        <i className="fa fa-plus fa-xs" />
      ) : (
        <i className="fa fa-minus fa-xs" />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;

  ${({ type }) =>
    type == 'add'
      ? `background-color: ${theme.colors.blue[400]};`
      : `background-color: ${theme.colors.blue[50]}; 
        i {
          color: ${theme.colors.blue[400]} !important;
        }
      `};

  ${({ size }) => {
    switch (size) {
      case 'medium':
        return `width: 28px;
                  height: 28px;
                  border-radius: 8px;
                  `
      case 'small':
        return `min-width: 16px;
                  width: 16px;
                  height: 16px;
                  border-radius: 4px;
                  i {
                    color: ${theme.colors.base.white};
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 10px;
                  }`
    }
  }}

  ${media.mediumS`
    ${({ size }) => {
      switch (size) {
        case 'medium':
          return `
                min-width: 26px;
                width: 26px;
                height: 26px;
                border-radius: 8px;
                  `
        case 'small':
          return `min-width: 16px;
                  width: 16px;
                  height: 16px;
                  border-radius: 4px;
                  i {
                    color: ${theme.colors.base.white};
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 10px;
                  }`
      }
    }}
  `};

  cursor: pointer;
`

export default EditButton
