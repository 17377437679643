import BaseNavItem from './BaseNavItem'
import { useDispatch } from 'react-redux'
import { setSidemenuVisible } from 'modules/layout'
import { ReactComponent as Icon } from 'assets/icon/nav/hamburger.svg'

const BaseNavMore = () => {
  const dispatch = useDispatch()

  const onOpen = () => {
    dispatch(setSidemenuVisible(true))
  }

  return (
    <>
      <BaseNavItem
        as="div"
        onClick={onOpen}
        icon={<Icon />}
        text="더보기"
      />
    </>
  )
}

export default BaseNavMore
