import { BrowserRouter } from 'react-router-dom'
import GlobalProvider from './GlobalProvider'
import Layout from './components/base/Layout/Layout'
import Router from 'Router'
import Core from 'containers/base/Core'
import GlobalStyle from 'lib/styles/globalStyle'
import { useContext, useEffect } from 'react'
import { getLoginInfo } from 'lib/api/auth/authApi'
import {
  setCsrfToken,
  setLoginInfo,
  setLoginVisible,
} from 'modules/auth'
import { useDispatch, useSelector } from 'react-redux'
import MapContext from 'contexts/map'
import ErrorFallbackComponent from 'components/error/ErrorFallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'
import useLog from 'lib/hooks/useLog'

function App() {
  const needToLogin = useSelector((state) => state.auth.needToLogin)
  const { state } = useContext(MapContext)

  const { loggingError } = useLog()
  const dispatch = useDispatch()

  const getBaseName = () => {
    return '/map'
  }

  //세션 만료 재로그인
  useEffect(() => {
    if (needToLogin == true) {
      async function onCsrfCheckFailed() {
        const res = await getLoginInfo()
        const newCsrf = res?.data?.member_info?.csrf_token

        //csrf 토큰만 다시 세팅
        dispatch(setCsrfToken(newCsrf))
        setLoginInfo({
          ...state.loginInfo,
          csrf_token: newCsrf,
        })

        dispatch(setLoginVisible(true))
      }

      onCsrfCheckFailed()
    }
  }, [needToLogin])

  return (
    <ErrorBoundary
      fallbackRender={ErrorFallbackComponent}
      onError={loggingError}
    >
      <GlobalProvider>
        <GlobalStyle />
        <BrowserRouter basename={getBaseName()}>
          <Core>
            <Layout>
              <Router />
            </Layout>
          </Core>
        </BrowserRouter>
      </GlobalProvider>
    </ErrorBoundary>
  )
}

export default App
