import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import theme from 'lib/styles/theme'
import HeaderIcon from 'components/common/Header/HeaderIcon'
import { useMemo } from 'react'

const StyledBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;

  min-width: 1.25rem;
  height: 1.25rem;
  padding: 0 0.25rem;

  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  line-height: calc(1.25rem - 4px);

  background-color: ${theme.colors.primary[400]};
  border: 2px solid white;
  border-radius: 2rem;
`

const BaseHeaderNoty = () => {
  const history = useHistory()
  const loginInfo = useSelector((state) => state.auth.loginInfo)
  const notifications = useSelector(
    (state) => state.notification.notifications,
  )

  const getNotReadedNoties = (notifications) => {
    return notifications.filter((o) => o.readed !== 'Y')
  }
  const notReadedNoties = useMemo(
    () => getNotReadedNoties(notifications),
    [notifications],
  )

  const onClickIcon = () => {
    history.push('/notifications')
  }

  if (!loginInfo?.member_srl) return null

  return (
    <Tooltip title="알림">
      <HeaderIcon onClick={() => onClickIcon()}>
        {notReadedNoties.length > 0 && (
          <StyledBadge>
            {
              notReadedNoties.filter((item) => item.readed === 'N')
                .length
            }
          </StyledBadge>
        )}
        <i className="far fa-bell"></i>
      </HeaderIcon>
    </Tooltip>
  )
}

export default BaseHeaderNoty
