import {
  ColumnSet,
  ColumnTitle,
  ColumnVal,
  FormDesc,
  ValidateWarn,
} from '../../form/NoteAdFormStyle'
import theme, { media } from 'lib/styles/theme'
import styled from 'styled-components'
import { Button } from 'components/design'
import { useFormContext } from 'react-hook-form'
import useAdUpdate from 'lib/hooks/notev2/useAdUpdate'
import BlueTextBox from '../../elements/BlueTextBox'
import { useState } from 'react'
import NoteAdCharacterGuide from './NoteAdCharacterGuide'

/**
 * 광고관리 매물 등록 테이블 컬럼
 * 상세정보 매물특징
 */
const NoteAdColumnCharacter = () => {
  const [guideModalVisible, setGuideModalVisible] = useState(false)
  const { onUpdate } = useAdUpdate()
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const feature = watch('etcInfo.feature')

  const [inputCount, setInputCount] = useState(feature?.length || 0)

  const onClickGuideModal = () => {
    setGuideModalVisible(true)
  }

  const onCancelGuideModal = () => {
    setGuideModalVisible(false)
  }

  const onTextareaHandler = (e) => {
    setInputCount(e.target.value.length)
  }

  return (
    <>
      <ColumnSet>
        <ColumnTitle required>매물특징</ColumnTitle>
        <ColumnVal>
          <InputWrapper>
            <TextCountWrapper>
              <BlueTextBox
                maxLength="40"
                placeholder="매물 목록에 나오는 문구에요. 40자 이내로 작성해주세요. "
                type="text"
                {...register('etcInfo.feature')}
                onBlur={(e) => {
                  onUpdate('etcInfo.feature', e.target.value)
                }}
                onChange={onTextareaHandler}
              />
              <Count>{inputCount} / 40</Count>
            </TextCountWrapper>
            <Button
              size="small"
              color="gray"
              onClick={onClickGuideModal}
            >
              <HelpIcon className="fad fa-question-circle" /> 매물 특징
              가이드
            </Button>
          </InputWrapper>
          <FormDesc>
            <li>
              한글 영어 숫자 ㎡ 이외에 특수문자는 입력할 수 없습니다.
              평형, 평, 특수문자, 매물과 관련 없는 정보는 삭제처리
              됩니다.
            </li>
          </FormDesc>

          <ValidateWarn>
            {errors?.etcInfo?.feature?.message}
          </ValidateWarn>
        </ColumnVal>
      </ColumnSet>
      <NoteAdCharacterGuide
        visible={guideModalVisible}
        onCancel={onCancelGuideModal}
      />
    </>
  )
}

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${media.mediumS`
    position: relative;
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  `}
`

const HelpIcon = styled.i`
  margin-right: 5px;
  color: ${theme.colors.gray[500]};
`

const TextCountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 80%;
`

const Count = styled.div`
  font-size: 12px;
  color: ${theme.colors.blue[900]};
`

export default NoteAdColumnCharacter
